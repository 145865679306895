const geoLocation = {
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0007823067885,
        "objectid": 1,
        "shape_leng": 0.116357453189,
        "location_id": 1,
        "zone": "Newark Airport",
        "borough": "EWR"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -74.18445299999996,
                40.694995999999904
              ],
              [
                -74.18563199999994,
                40.69164799999987
              ],
              [
                -74.18591099999998,
                40.69144999999988
              ],
              [
                -74.18636999999995,
                40.69118899999991
              ],
              [
                -74.18723399999999,
                40.69059499999986
              ],
              [
                -74.18779199999994,
                40.690189999999866
              ],
              [
                -74.18870099999998,
                40.689442999999876
              ],
              [
                -74.18898899999994,
                40.68904699999991
              ],
              [
                -74.18922299999997,
                40.68862399999989
              ],
              [
                -74.18934899999994,
                40.68826399999989
              ],
              [
                -74.18940299999997,
                40.68809299999987
              ],
              [
                -74.18941199999995,
                40.687939999999855
              ],
              [
                -74.18939399999994,
                40.68783199999991
              ],
              [
                -74.18939399999994,
                40.68773299999988
              ],
              [
                -74.18947499999996,
                40.68748999999985
              ],
              [
                -74.18951999999996,
                40.6872019999999
              ],
              [
                -74.18955599999998,
                40.68689599999987
              ],
              [
                -74.18962799999997,
                40.686103999999915
              ],
              [
                -74.18976299999997,
                40.68503299999986
              ],
              [
                -74.189781,
                40.68481699999991
              ],
              [
                -74.189871,
                40.684078999999876
              ],
              [
                -74.18993399999994,
                40.68370999999992
              ],
              [
                -74.18995199999995,
                40.683520999999885
              ],
              [
                -74.18998799999997,
                40.68317899999989
              ],
              [
                -74.18996999999996,
                40.68295399999989
              ],
              [
                -74.18999699999995,
                40.68262999999992
              ],
              [
                -74.18999699999995,
                40.6822519999999
              ],
              [
                -74.18996999999996,
                40.6818019999999
              ],
              [
                -74.18991599999998,
                40.68154099999988
              ],
              [
                -74.18983499999996,
                40.68131599999987
              ],
              [
                -74.189781,
                40.681198999999886
              ],
              [
                -74.18977199999995,
                40.68113599999987
              ],
              [
                -74.18969999999996,
                40.680793999999885
              ],
              [
                -74.18949299999997,
                40.680091999999895
              ],
              [
                -74.18935799999997,
                40.67975899999988
              ],
              [
                -74.18905199999995,
                40.67933599999985
              ],
              [
                -74.18877299999997,
                40.67885899999989
              ],
              [
                -74.18852099999998,
                40.67848099999987
              ],
              [
                -74.18819699999995,
                40.67792299999992
              ],
              [
                -74.18795399999995,
                40.67761699999989
              ],
              [
                -74.18767499999996,
                40.67729299999991
              ],
              [
                -74.18733299999997,
                40.67674399999987
              ],
              [
                -74.18670299999997,
                40.67578999999992
              ],
              [
                -74.18594699999994,
                40.674664999999905
              ],
              [
                -74.18547899999999,
                40.67390899999987
              ],
              [
                -74.18513699999994,
                40.673458999999866
              ],
              [
                -74.18503799999996,
                40.67335999999989
              ],
              [
                -74.18492099999997,
                40.673269999999896
              ],
              [
                -74.18458799999996,
                40.67302699999988
              ],
              [
                -74.18437199999994,
                40.67290999999989
              ],
              [
                -74.18384999999995,
                40.672648999999886
              ],
              [
                -74.18213999999996,
                40.67202799999989
              ],
              [
                -74.18133899999998,
                40.67170399999986
              ],
              [
                -74.18030399999998,
                40.67129899999986
              ],
              [
                -74.18010599999997,
                40.671262999999904
              ],
              [
                -74.17997999999994,
                40.671208999999884
              ],
              [
                -74.17979099999997,
                40.67115499999989
              ],
              [
                -74.17933199999999,
                40.67101099999992
              ],
              [
                -74.17912499999994,
                40.67099299999991
              ],
              [
                -74.17888199999999,
                40.671001999999895
              ],
              [
                -74.17862999999994,
                40.671037999999896
              ],
              [
                -74.17802699999999,
                40.671208999999884
              ],
              [
                -74.17778399999997,
                40.671316999999874
              ],
              [
                -74.17754999999994,
                40.67142499999989
              ],
              [
                -74.17734299999995,
                40.67155999999988
              ],
              [
                -74.17709099999996,
                40.671784999999886
              ],
              [
                -74.17697399999997,
                40.6719379999999
              ],
              [
                -74.17678499999994,
                40.67218099999991
              ],
              [
                -74.17663199999998,
                40.67239699999989
              ],
              [
                -74.17641599999996,
                40.672621999999876
              ],
              [
                -74.17630799999995,
                40.67276599999986
              ],
              [
                -74.17604699999998,
                40.673044999999895
              ],
              [
                -74.17538999999995,
                40.67375599999987
              ],
              [
                -74.17500299999995,
                40.6741699999999
              ],
              [
                -74.17484999999994,
                40.67432299999992
              ],
              [
                -74.17437299999995,
                40.674817999999895
              ],
              [
                -74.17423799999995,
                40.67493499999991
              ],
              [
                -74.17358999999993,
                40.67571799999991
              ],
              [
                -74.17329299999994,
                40.67601499999989
              ],
              [
                -74.17322999999999,
                40.6760779999999
              ],
              [
                -74.17314899999997,
                40.67619499999991
              ],
              [
                -74.17283399999997,
                40.67656399999988
              ],
              [
                -74.17200599999995,
                40.67773399999988
              ],
              [
                -74.17193399999996,
                40.67782399999988
              ],
              [
                -74.17160999999999,
                40.678255999999884
              ],
              [
                -74.17151999999999,
                40.678381999999885
              ],
              [
                -74.17144799999994,
                40.67847199999989
              ],
              [
                -74.17093499999999,
                40.679290999999864
              ],
              [
                -74.17067399999996,
                40.679650999999886
              ],
              [
                -74.17051199999997,
                40.67999299999987
              ],
              [
                -74.17041299999994,
                40.6801549999999
              ],
              [
                -74.17005299999994,
                40.68066799999987
              ],
              [
                -74.16995399999996,
                40.680874999999915
              ],
              [
                -74.16981899999996,
                40.681018999999885
              ],
              [
                -74.16947699999997,
                40.68155899999991
              ],
              [
                -74.16929699999997,
                40.68178399999989
              ],
              [
                -74.16916199999997,
                40.68199999999991
              ],
              [
                -74.16886499999998,
                40.68239599999987
              ],
              [
                -74.16848699999997,
                40.68299899999991
              ],
              [
                -74.16822599999995,
                40.68334999999991
              ],
              [
                -74.16816299999994,
                40.683475999999885
              ],
              [
                -74.16804599999995,
                40.68360199999991
              ],
              [
                -74.16791099999995,
                40.68379099999988
              ],
              [
                -74.16781199999997,
                40.6839439999999
              ],
              [
                -74.16738899999996,
                40.684546999999924
              ],
              [
                -74.16695699999997,
                40.68523099999988
              ],
              [
                -74.16674099999994,
                40.685491999999925
              ],
              [
                -74.16650699999997,
                40.68588799999986
              ],
              [
                -74.16639899999996,
                40.686022999999864
              ],
              [
                -74.16604799999999,
                40.68655399999988
              ],
              [
                -74.16589499999998,
                40.6868419999999
              ],
              [
                -74.16582299999999,
                40.68703999999986
              ],
              [
                -74.16580499999998,
                40.687156999999914
              ],
              [
                -74.16582299999999,
                40.68748999999987
              ],
              [
                -74.16583199999997,
                40.68757999999987
              ],
              [
                -74.16587699999997,
                40.68787699999991
              ],
              [
                -74.16576899999995,
                40.68802999999986
              ],
              [
                -74.16560699999997,
                40.68842599999988
              ],
              [
                -74.16548099999994,
                40.68863299999987
              ],
              [
                -74.16542699999997,
                40.6887589999999
              ],
              [
                -74.16528299999999,
                40.68891199999991
              ],
              [
                -74.16499499999998,
                40.689388999999885
              ],
              [
                -74.16491399999995,
                40.689586999999904
              ],
              [
                -74.16479699999996,
                40.689757999999884
              ],
              [
                -74.16467099999994,
                40.68988399999989
              ],
              [
                -74.16450899999995,
                40.68998299999986
              ],
              [
                -74.16436499999998,
                40.69003699999991
              ],
              [
                -74.16422999999998,
                40.69005499999988
              ],
              [
                -74.16417599999994,
                40.690081999999904
              ],
              [
                -74.16410399999995,
                40.690081999999904
              ],
              [
                -74.16380699999996,
                40.69004599999989
              ],
              [
                -74.16352799999999,
                40.69000999999986
              ],
              [
                -74.16341099999994,
                40.69000099999988
              ],
              [
                -74.16331199999996,
                40.68999199999993
              ],
              [
                -74.16322199999996,
                40.68998299999989
              ],
              [
                -74.16309599999994,
                40.689928999999886
              ],
              [
                -74.16306899999995,
                40.68989299999988
              ],
              [
                -74.16295199999996,
                40.689874999999866
              ],
              [
                -74.16292499999997,
                40.689874999999866
              ],
              [
                -74.16290699999996,
                40.68987499999987
              ],
              [
                -74.16279899999995,
                40.69002799999989
              ],
              [
                -74.16262799999998,
                40.69028899999989
              ],
              [
                -74.16057599999993,
                40.693222999999875
              ],
              [
                -74.16014399999995,
                40.69410499999988
              ],
              [
                -74.15958599999993,
                40.69476199999984
              ],
              [
                -74.15951399999994,
                40.69488799999988
              ],
              [
                -74.15829899999994,
                40.696705999999885
              ],
              [
                -74.15762399999994,
                40.69767799999991
              ],
              [
                -74.15758799999998,
                40.69781299999988
              ],
              [
                -74.15754299999998,
                40.6979389999999
              ],
              [
                -74.15745299999998,
                40.69809199999988
              ],
              [
                -74.15635499999996,
                40.69975699999986
              ],
              [
                -74.15600399999994,
                40.70026099999991
              ],
              [
                -74.15575199999995,
                40.7006659999999
              ],
              [
                -74.15544599999998,
                40.70108899999988
              ],
              [
                -74.15513999999996,
                40.70155699999987
              ],
              [
                -74.15386199999995,
                40.7038429999999
              ],
              [
                -74.15359199999995,
                40.70437399999987
              ],
              [
                -74.15306999999996,
                40.7052829999999
              ],
              [
                -74.15317799999997,
                40.70531899999989
              ],
              [
                -74.15323199999995,
                40.70524699999987
              ],
              [
                -74.15376299999997,
                40.705408999999875
              ],
              [
                -74.15387999999996,
                40.705327999999895
              ],
              [
                -74.15401499999997,
                40.70538199999988
              ],
              [
                -74.15409599999998,
                40.70544499999989
              ],
              [
                -74.15459099999998,
                40.705651999999894
              ],
              [
                -74.15827199999995,
                40.70687599999993
              ],
              [
                -74.15891999999997,
                40.70710999999989
              ],
              [
                -74.15904599999999,
                40.707145999999916
              ],
              [
                -74.15938799999998,
                40.707262999999905
              ],
              [
                -74.160063,
                40.7074879999999
              ],
              [
                -74.16033299999998,
                40.70756899999987
              ],
              [
                -74.16057599999993,
                40.70760499999988
              ],
              [
                -74.16081899999995,
                40.70764099999991
              ],
              [
                -74.16233999999997,
                40.707721999999876
              ],
              [
                -74.16401399999995,
                40.70777599999992
              ],
              [
                -74.16442799999999,
                40.70779399999987
              ],
              [
                -74.16681299999993,
                40.70785699999989
              ],
              [
                -74.16888299999994,
                40.7079379999999
              ],
              [
                -74.16994499999998,
                40.707973999999886
              ],
              [
                -74.17120499999999,
                40.70795599999986
              ],
              [
                -74.17133999999999,
                40.707964999999895
              ],
              [
                -74.17163699999998,
                40.70795599999986
              ],
              [
                -74.17188899999996,
                40.707910999999854
              ],
              [
                -74.17275299999994,
                40.707802999999906
              ],
              [
                -74.17347299999994,
                40.707748999999865
              ],
              [
                -74.17388699999998,
                40.70773099999992
              ],
              [
                -74.17417499999993,
                40.70766799999991
              ],
              [
                -74.17475999999994,
                40.707595999999924
              ],
              [
                -74.17515599999996,
                40.707514999999894
              ],
              [
                -74.17538099999996,
                40.707469999999915
              ],
              [
                -74.17565999999994,
                40.70737999999988
              ],
              [
                -74.17589399999997,
                40.707262999999905
              ],
              [
                -74.17599299999995,
                40.70719099999991
              ],
              [
                -74.17608299999995,
                40.70710999999989
              ],
              [
                -74.17628999999994,
                40.70689399999988
              ],
              [
                -74.17642499999994,
                40.706695999999916
              ],
              [
                -74.17664999999994,
                40.70641699999988
              ],
              [
                -74.17689299999995,
                40.70619199999988
              ],
              [
                -74.17699199999998,
                40.70613799999988
              ],
              [
                -74.17709099999996,
                40.706092999999896
              ],
              [
                -74.17776599999996,
                40.70589499999988
              ],
              [
                -74.17831499999994,
                40.70572399999987
              ],
              [
                -74.17887299999995,
                40.70554399999987
              ],
              [
                -74.179467,
                40.70534599999992
              ],
              [
                -74.18080799999996,
                40.7047879999999
              ],
              [
                -74.18132099999997,
                40.70460799999991
              ],
              [
                -74.18157299999996,
                40.70449999999988
              ],
              [
                -74.18180699999994,
                40.7043919999999
              ],
              [
                -74.18203199999994,
                40.70425699999987
              ],
              [
                -74.18220299999996,
                40.704139999999896
              ],
              [
                -74.18242799999996,
                40.70395099999992
              ],
              [
                -74.18264399999998,
                40.70373499999988
              ],
              [
                -74.18284199999994,
                40.70346499999989
              ],
              [
                -74.18299499999995,
                40.70315899999985
              ],
              [
                -74.18309399999998,
                40.7028529999999
              ],
              [
                -74.18313899999998,
                40.7026279999999
              ],
              [
                -74.18316599999997,
                40.702384999999886
              ],
              [
                -74.18315699999994,
                40.701214999999884
              ],
              [
                -74.18327399999998,
                40.70008999999988
              ],
              [
                -74.18350799999996,
                40.69870399999992
              ],
              [
                -74.18354399999998,
                40.69855999999988
              ],
              [
                -74.18356199999994,
                40.698451999999875
              ],
              [
                -74.18363399999998,
                40.6983259999999
              ],
              [
                -74.18375099999997,
                40.69779499999988
              ],
              [
                -74.18391299999996,
                40.69750699999986
              ],
              [
                -74.18402099999997,
                40.697074999999884
              ],
              [
                -74.18428199999994,
                40.6962109999999
              ],
              [
                -74.18438099999997,
                40.69587799999989
              ],
              [
                -74.18449799999996,
                40.69518499999987
              ],
              [
                -74.18448899999999,
                40.69509499999987
              ],
              [
                -74.18445299999996,
                40.694995999999904
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.00486634037837,
        "objectid": 2,
        "shape_leng": 0.43346966679,
        "location_id": 2,
        "zone": "Jamaica Bay",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.82337597260663,
                40.63898704717672
              ],
              [
                -73.82419327293157,
                40.639953274487794
              ],
              [
                -73.82457379890309,
                40.63935387323921
              ],
              [
                -73.82413577690048,
                40.63878799670455
              ],
              [
                -73.82443032497055,
                40.6362165210408
              ],
              [
                -73.82454362871778,
                40.63614256138235
              ],
              [
                -73.82466283191333,
                40.63607419492457
              ],
              [
                -73.82478745957022,
                40.636011694076004
              ],
              [
                -73.8249170150923,
                40.635955307873
              ],
              [
                -73.82505098225157,
                40.63590526098733
              ],
              [
                -73.82518882724635,
                40.63586175283105
              ],
              [
                -73.82533000082624,
                40.635824956762335
              ],
              [
                -73.8254739404821,
                40.63579501939495
              ],
              [
                -73.82562007268534,
                40.63577206001384
              ],
              [
                -73.82576781517317,
                40.63575617010003
              ],
              [
                -73.82591657926902,
                40.63574741296666
              ],
              [
                -73.82606577222559,
                40.63574582350641
              ],
              [
                -73.82621479958787,
                40.6357514080522
              ],
              [
                -73.82636306756064,
                40.635764144352805
              ],
              [
                -73.8265099853732,
                40.63578398166053
              ],
              [
                -73.82665496763364,
                40.635810840934084
              ],
              [
                -73.82706379083446,
                40.635811456523534
              ],
              [
                -73.82808567353285,
                40.63587972683653
              ],
              [
                -73.82866782109734,
                40.63661471844798
              ],
              [
                -73.82938003995758,
                40.63707492736802
              ],
              [
                -73.8322165881916,
                40.63892849022077
              ],
              [
                -73.83281266635599,
                40.63888193721898
              ],
              [
                -73.83289974361197,
                40.6388626850669
              ],
              [
                -73.83294956256923,
                40.6388234942368
              ],
              [
                -73.83340607979225,
                40.63850135589897
              ],
              [
                -73.83309423850194,
                40.63743998965629
              ],
              [
                -73.83290683934878,
                40.636928660849264
              ],
              [
                -73.83263580697174,
                40.6363293744819
              ],
              [
                -73.83245895783561,
                40.63579410645499
              ],
              [
                -73.83249094435719,
                40.635578551835536
              ],
              [
                -73.8323134547505,
                40.635298808974646
              ],
              [
                -73.83212076365058,
                40.635125437347135
              ],
              [
                -73.83195787786688,
                40.63497888056588
              ],
              [
                -73.83190631367316,
                40.63464342618994
              ],
              [
                -73.8319116731582,
                40.634576218435946
              ],
              [
                -73.83193878472683,
                40.634236225428445
              ],
              [
                -73.83168397540554,
                40.633978117774724
              ],
              [
                -73.8315519507211,
                40.63384438316006
              ],
              [
                -73.83133112864137,
                40.633617691427716
              ],
              [
                -73.83128625882125,
                40.63341587766314
              ],
              [
                -73.83124958033935,
                40.633213105648565
              ],
              [
                -73.83121194934962,
                40.6331229377483
              ],
              [
                -73.83118215143037,
                40.633031085681694
              ],
              [
                -73.83116031452592,
                40.6329379439361
              ],
              [
                -73.83114653238748,
                40.63284391253631
              ],
              [
                -73.83114086417227,
                40.63274939532593
              ],
              [
                -73.83114333418938,
                40.632654798233716
              ],
              [
                -73.83115393179625,
                40.632560527529506
              ],
              [
                -73.8311726114447,
                40.632466988079756
              ],
              [
                -73.83119929287734,
                40.63237458160877
              ],
              [
                -73.83129788233452,
                40.6322231315103
              ],
              [
                -73.8313882023895,
                40.63206871707372
              ],
              [
                -73.83147009989457,
                40.6319116002259
              ],
              [
                -73.83154343599053,
                40.6317520474762
              ],
              [
                -73.83160808634373,
                40.63159032946403
              ],
              [
                -73.83166394135613,
                40.63142672049938
              ],
              [
                -73.83171090635092,
                40.63126149809776
              ],
              [
                -73.83189666398192,
                40.63057239405204
              ],
              [
                -73.83183334645541,
                40.63032330422834
              ],
              [
                -73.83188649602349,
                40.63002792793848
              ],
              [
                -73.83196080768154,
                40.62965272987937
              ],
              [
                -73.83219207032313,
                40.62938156907504
              ],
              [
                -73.83236036671222,
                40.62914225774722
              ],
              [
                -73.83266355800859,
                40.62902359237243
              ],
              [
                -73.83268559314394,
                40.6290149678853
              ],
              [
                -73.83289548956779,
                40.628903478732965
              ],
              [
                -73.83288560857211,
                40.62866390752473
              ],
              [
                -73.83287721797437,
                40.628592073991946
              ],
              [
                -73.83286508734435,
                40.6284882026479
              ],
              [
                -73.83286528731682,
                40.628408350262575
              ],
              [
                -73.83304100907748,
                40.628261271060616
              ],
              [
                -73.83464377224975,
                40.62742707942435
              ],
              [
                -73.83342230809954,
                40.626781983462095
              ],
              [
                -73.83514355859735,
                40.625487082796646
              ],
              [
                -73.83534733990399,
                40.62369577884394
              ],
              [
                -73.83499413194808,
                40.622694310213376
              ],
              [
                -73.83500804316691,
                40.622691694612286
              ],
              [
                -73.83502020554216,
                40.62268224198472
              ],
              [
                -73.83502173941585,
                40.62267348828023
              ],
              [
                -73.83500259350858,
                40.62265240876773
              ],
              [
                -73.83498020634333,
                40.62263008890535
              ],
              [
                -73.8349690567791,
                40.622593347517174
              ],
              [
                -73.83497120947139,
                40.622567741614176
              ],
              [
                -73.83497320567402,
                40.62253277580579
              ],
              [
                -73.83489935765645,
                40.62246028951774
              ],
              [
                -73.8348497664996,
                40.62242632062673
              ],
              [
                -73.83484340044038,
                40.622378908297776
              ],
              [
                -73.83485615308435,
                40.62234947551252
              ],
              [
                -73.83488283700238,
                40.622323684211274
              ],
              [
                -73.83487846094775,
                40.622301857434074
              ],
              [
                -73.83485757192346,
                40.622270810596916
              ],
              [
                -73.83482124702645,
                40.62224797713946
              ],
              [
                -73.83478255229284,
                40.622235158334746
              ],
              [
                -73.83476098931946,
                40.62221473285872
              ],
              [
                -73.8347597397065,
                40.62218165680579
              ],
              [
                -73.83476020875995,
                40.62215231560311
              ],
              [
                -73.83472608413201,
                40.62211323613151
              ],
              [
                -73.8346921164322,
                40.62208351605375
              ],
              [
                -73.83466547391477,
                40.62204875549979
              ],
              [
                -73.8346451380572,
                40.6219977273897
              ],
              [
                -73.83464866926714,
                40.62195398041147
              ],
              [
                -73.8346676730216,
                40.62190202129716
              ],
              [
                -73.834706371938,
                40.621854290620504
              ],
              [
                -73.8347526375955,
                40.62182023816353
              ],
              [
                -73.83479301877968,
                40.6217762439309
              ],
              [
                -73.83480411594866,
                40.621746203823186
              ],
              [
                -73.83480360476744,
                40.62170560694276
              ],
              [
                -73.8347735869859,
                40.621666505909815
              ],
              [
                -73.83475426084244,
                40.621629835169095
              ],
              [
                -73.8347497083017,
                40.62159238855887
              ],
              [
                -73.83478297712946,
                40.62156905116116
              ],
              [
                -73.83481229773928,
                40.62155509540437
              ],
              [
                -73.8348400277696,
                40.621543662018155
              ],
              [
                -73.83486110670388,
                40.621526647054374
              ],
              [
                -73.8348617532606,
                40.62151289602442
              ],
              [
                -73.83485302732605,
                40.62147239774434
              ],
              [
                -73.83486007514385,
                40.62144861103434
              ],
              [
                -73.83486860012296,
                40.62140983929493
              ],
              [
                -73.83487573996095,
                40.621392309759415
              ],
              [
                -73.83485905038059,
                40.62136747339013
              ],
              [
                -73.83480605640716,
                40.6213260326488
              ],
              [
                -73.83477206223073,
                40.621293211422895
              ],
              [
                -73.83475117363801,
                40.62126213775867
              ],
              [
                -73.83477106595734,
                40.621215174948134
              ],
              [
                -73.83479426014556,
                40.62117255353668
              ],
              [
                -73.8348018333844,
                40.62112568442332
              ],
              [
                -73.83484210233186,
                40.62106920004676
              ],
              [
                -73.83488425945177,
                40.621035169107174
              ],
              [
                -73.8349325067799,
                40.62102980425339
              ],
              [
                -73.83497579529411,
                40.62101949048852
              ],
              [
                -73.83498716099349,
                40.62101129988884
              ],
              [
                -73.83487095582524,
                40.620747446445165
              ],
              [
                -73.83494291247693,
                40.62043414290279
              ],
              [
                -73.8349766806337,
                40.62038456541267
              ],
              [
                -73.83501945449156,
                40.62033368338847
              ],
              [
                -73.83505992869569,
                40.62029591875945
              ],
              [
                -73.83511109381281,
                40.620260583712735
              ],
              [
                -73.83517947408095,
                40.62022697676222
              ],
              [
                -73.83522806192099,
                40.62019965339755
              ],
              [
                -73.83525703590811,
                40.620180122834086
              ],
              [
                -73.83530504599715,
                40.62015364999168
              ],
              [
                -73.83531201853388,
                40.620145371813855
              ],
              [
                -73.83529889198519,
                40.620136980909336
              ],
              [
                -73.8352833721993,
                40.620134240681075
              ],
              [
                -73.83524713409838,
                40.6201344633323
              ],
              [
                -73.83521267286356,
                40.62012988546007
              ],
              [
                -73.8351892113884,
                40.620119668738376
              ],
              [
                -73.83514945421035,
                40.62008626121422
              ],
              [
                -73.83511140979228,
                40.62005969316263
              ],
              [
                -73.83506194119016,
                40.62003508370845
              ],
              [
                -73.83503648675614,
                40.62003029806967
              ],
              [
                -73.83499593255537,
                40.62001305741371
              ],
              [
                -73.8349903869734,
                40.62001266514207
              ],
              [
                -73.83498473196929,
                40.62001224590833
              ],
              [
                -73.83497889815727,
                40.62001185271982
              ],
              [
                -73.8349729909799,
                40.6200114324535
              ],
              [
                -73.83496669339895,
                40.62001098699957
              ],
              [
                -73.83495682336319,
                40.62000929636612
              ],
              [
                -73.83495138570291,
                40.62000838222684
              ],
              [
                -73.83495037894772,
                40.620008216602905
              ],
              [
                -73.83494652564518,
                40.62000754145676
              ],
              [
                -73.83494497840654,
                40.62000713951085
              ],
              [
                -73.83493007474962,
                40.620003136447444
              ],
              [
                -73.8349251798322,
                40.62000181196984
              ],
              [
                -73.8349241199416,
                40.62000152933402
              ],
              [
                -73.83492035598105,
                40.62000002203071
              ],
              [
                -73.83491553445205,
                40.61999809321308
              ],
              [
                -73.83491071255162,
                40.61999613692002
              ],
              [
                -73.83490585430438,
                40.61999420922202
              ],
              [
                -73.8349006027422,
                40.619992100194985
              ],
              [
                -73.8348958868922,
                40.619990023265096
              ],
              [
                -73.83489070539565,
                40.6199877645673
              ],
              [
                -73.83489020092962,
                40.61998754421212
              ],
              [
                -73.83488973311268,
                40.61998735088171
              ],
              [
                -73.83488448117431,
                40.61998503880824
              ],
              [
                -73.83487911894214,
                40.6199826969234
              ],
              [
                -73.83486925121078,
                40.61997838881105
              ],
              [
                -73.83486605747945,
                40.61997691465816
              ],
              [
                -73.83486220641196,
                40.61997507066187
              ],
              [
                -73.8348581422215,
                40.619973170907116
              ],
              [
                -73.83485469539761,
                40.619971534389684
              ],
              [
                -73.83485393281136,
                40.61997105081036
              ],
              [
                -73.83484968839795,
                40.61996832739093
              ],
              [
                -73.83484537325917,
                40.61996557639494
              ],
              [
                -73.83484126464018,
                40.619962973288594
              ],
              [
                -73.8348409850447,
                40.61996277034422
              ],
              [
                -73.83483652603833,
                40.61995996368912
              ],
              [
                -73.83483199380255,
                40.61995707517439
              ],
              [
                -73.8348278341032,
                40.61995441335898
              ],
              [
                -73.83482746235862,
                40.619954133386436
              ],
              [
                -73.83482318391319,
                40.619950859248895
              ],
              [
                -73.83481894145231,
                40.6199476143131
              ],
              [
                -73.83481552717186,
                40.61994499963883
              ],
              [
                -73.83481484319879,
                40.61994428833264
              ],
              [
                -73.83481143130801,
                40.61994079698195
              ],
              [
                -73.83480812350955,
                40.619937442987016
              ],
              [
                -73.83480546312809,
                40.61993473557952
              ],
              [
                -73.83480503427865,
                40.61993425465737
              ],
              [
                -73.83480223326198,
                40.619930984820066
              ],
              [
                -73.83479943094243,
                40.61992779740506
              ],
              [
                -73.83479670104234,
                40.61992466387096
              ],
              [
                -73.83479652145589,
                40.61992447145701
              ],
              [
                -73.83479476431347,
                40.619921559453964
              ],
              [
                -73.83479297066026,
                40.61991856447164
              ],
              [
                -73.83479124855907,
                40.619915707468415
              ],
              [
                -73.8347898237671,
                40.619913358003075
              ],
              [
                -73.83478963457533,
                40.61991296035216
              ],
              [
                -73.83478424558758,
                40.6199022456552
              ],
              [
                -73.83478386448336,
                40.61990103956814
              ],
              [
                -73.83478275512489,
                40.619897826608415
              ],
              [
                -73.83478171846966,
                40.61989464173061
              ],
              [
                -73.8347767126989,
                40.61987983953903
              ],
              [
                -73.83477534387069,
                40.619875754384886
              ],
              [
                -73.8347752830575,
                40.61987536395477
              ],
              [
                -73.83477203469464,
                40.619854396202214
              ],
              [
                -73.83477206006914,
                40.6198441454682
              ],
              [
                -73.83477310957899,
                40.61983627495178
              ],
              [
                -73.83477320071925,
                40.619835604798844
              ],
              [
                -73.83477427491415,
                40.619831528684536
              ],
              [
                -73.83477546438883,
                40.61982706674877
              ],
              [
                -73.8347756193006,
                40.61982683544624
              ],
              [
                -73.83477872965373,
                40.61982148803197
              ],
              [
                -73.83477996605193,
                40.61981938614613
              ],
              [
                -73.83478202158658,
                40.61981641545874
              ],
              [
                -73.83479346785425,
                40.619799761088196
              ],
              [
                -73.83479853640046,
                40.61979461701979
              ],
              [
                -73.83480021058838,
                40.61979293626083
              ],
              [
                -73.83480160512084,
                40.619791876632924
              ],
              [
                -73.83480499749777,
                40.619789302397336
              ],
              [
                -73.83480807158676,
                40.619786968778634
              ],
              [
                -73.8348167235161,
                40.61978157890006
              ],
              [
                -73.83481921278555,
                40.61978004539738
              ],
              [
                -73.83482042054403,
                40.61977929894786
              ],
              [
                -73.83482184592403,
                40.61977856705095
              ],
              [
                -73.83482458695994,
                40.61977719859099
              ],
              [
                -73.83482718297658,
                40.61977588420204
              ],
              [
                -73.83482967163266,
                40.619774598306385
              ],
              [
                -73.83483208731447,
                40.61977339405987
              ],
              [
                -73.83483605545379,
                40.619771368638254
              ],
              [
                -73.83483778657,
                40.61977049277034
              ],
              [
                -73.83483958986685,
                40.6197695895313
              ],
              [
                -73.83484139316472,
                40.61976868578971
              ],
              [
                -73.834843232584,
                40.619767755797824
              ],
              [
                -73.83484543326422,
                40.61976663299639
              ],
              [
                -73.83484766933802,
                40.6197655114185
              ],
              [
                -73.83484986957967,
                40.61976438811365
              ],
              [
                -73.83485160003306,
                40.619763513417276
              ],
              [
                -73.83485325984896,
                40.619762664418914
              ],
              [
                -73.8348540914557,
                40.619762264213854
              ],
              [
                -73.83486307480958,
                40.61975629733511
              ],
              [
                -73.83486981541657,
                40.61975032773673
              ],
              [
                -73.83487206295995,
                40.6197477689426
              ],
              [
                -73.834874311164,
                40.6197452096468
              ],
              [
                -73.83487640579283,
                40.619739586358236
              ],
              [
                -73.83487656803999,
                40.619739233607476
              ],
              [
                -73.83487713717255,
                40.619735442236205
              ],
              [
                -73.83487735609071,
                40.619734043850194
              ],
              [
                -73.83487770655925,
                40.619731548169995
              ],
              [
                -73.8348777264198,
                40.61972965210417
              ],
              [
                -73.83487772981483,
                40.619728279710685
              ],
              [
                -73.83487774135509,
                40.61972361471197
              ],
              [
                -73.83487772768987,
                40.619723006394096
              ],
              [
                -73.83487767295904,
                40.61972237891904
              ],
              [
                -73.83487756799781,
                40.61972117105099
              ],
              [
                -73.83487746188544,
                40.61972007291285
              ],
              [
                -73.83487735717769,
                40.619719029223766
              ],
              [
                -73.83487700728578,
                40.61971515963651
              ],
              [
                -73.83487690090587,
                40.619714169721746
              ],
              [
                -73.8348766377702,
                40.61971104626632
              ],
              [
                -73.83487636955081,
                40.61971024442188
              ],
              [
                -73.83487608523482,
                40.61970928390425
              ],
              [
                -73.83487483468761,
                40.619705109460575
              ],
              [
                -73.83487458329964,
                40.61970425821918
              ],
              [
                -73.83487333383293,
                40.61970000252549
              ],
              [
                -73.83487308349402,
                40.61969926051453
              ],
              [
                -73.83487179751529,
                40.61969492251164
              ],
              [
                -73.83487026126434,
                40.61968981552563
              ],
              [
                -73.83486996756153,
                40.61968882802232
              ],
              [
                -73.83486955322564,
                40.619684791483515
              ],
              [
                -73.8348694830632,
                40.61968426986476
              ],
              [
                -73.83486895523896,
                40.6196792730536
              ],
              [
                -73.83486891960808,
                40.619678834411296
              ],
              [
                -73.83486887055768,
                40.61967857684858
              ],
              [
                -73.83486864373661,
                40.619673838464784
              ],
              [
                -73.83486860929064,
                40.61967345410346
              ],
              [
                -73.8348683682949,
                40.61966840225224
              ],
              [
                -73.83486833459253,
                40.61966807284161
              ],
              [
                -73.8348680582627,
                40.61966299564283
              ],
              [
                -73.83486805894447,
                40.61966272005848
              ],
              [
                -73.83486780155245,
                40.61965807494431
              ],
              [
                -73.83487052091054,
                40.61964307142605
              ],
              [
                -73.83487120962234,
                40.619639288938124
              ],
              [
                -73.83487154262298,
                40.61963807734342
              ],
              [
                -73.83487154282814,
                40.61963799441679
              ],
              [
                -73.83487317748829,
                40.61963184911697
              ],
              [
                -73.83487336078586,
                40.619631163181005
              ],
              [
                -73.83487347349391,
                40.61963075038367
              ],
              [
                -73.83487477131926,
                40.61962825505904
              ],
              [
                -73.83487480869455,
                40.61962825511274
              ],
              [
                -73.83487719740646,
                40.61962373073483
              ],
              [
                -73.83487723359994,
                40.619623675334644
              ],
              [
                -73.83487797702384,
                40.619622216218914
              ],
              [
                -73.83487998296161,
                40.61961920474899
              ],
              [
                -73.83487998302958,
                40.61961917727436
              ],
              [
                -73.8348824800685,
                40.61961538867216
              ],
              [
                -73.83488294676292,
                40.61961479042589
              ],
              [
                -73.83488648749562,
                40.61961054161263
              ],
              [
                -73.83488652215,
                40.61961048621024
              ],
              [
                -73.83488655959442,
                40.619610458286644
              ],
              [
                -73.83488659512682,
                40.6196104033881
              ],
              [
                -73.83488684804733,
                40.619610101863955
              ],
              [
                -73.83488782426414,
                40.61960892302357
              ],
              [
                -73.83488810107427,
                40.61960856273094
              ],
              [
                -73.83488922400447,
                40.619607711285646
              ],
              [
                -73.83488922611875,
                40.61960685655521
              ],
              [
                -73.8348914696521,
                40.61960600554671
              ],
              [
                -73.83489197374509,
                40.619606020007964
              ],
              [
                -73.83489259047218,
                40.619606007156484
              ],
              [
                -73.83489371362325,
                40.61960515520877
              ],
              [
                -73.8348948346632,
                40.61960515681886
              ],
              [
                -73.83489694687438,
                40.61960459997019
              ],
              [
                -73.83489819967585,
                40.61960430742083
              ],
              [
                -73.83490105473129,
                40.61960430331226
              ],
              [
                -73.83490130690464,
                40.61960430367443
              ],
              [
                -73.83490141551309,
                40.6196043038304
              ],
              [
                -73.8349014869658,
                40.61960430393302
              ],
              [
                -73.8349015619159,
                40.61960431224962
              ],
              [
                -73.83490307284829,
                40.619603922569006
              ],
              [
                -73.83490492692965,
                40.61960346234883
              ],
              [
                -73.83491650941178,
                40.61960388741827
              ],
              [
                -73.83492846599323,
                40.6196043508853
              ],
              [
                -73.83492954791916,
                40.619604344229906
              ],
              [
                -73.83493182823328,
                40.61960435571321
              ],
              [
                -73.83493407154735,
                40.6196035042009
              ],
              [
                -73.8349366472464,
                40.61960284532165
              ],
              [
                -73.83493743655649,
                40.61960265597441
              ],
              [
                -73.8349396811896,
                40.619601804463855
              ],
              [
                -73.83494173227031,
                40.61960062934427
              ],
              [
                -73.83494616931696,
                40.61959808307396
              ],
              [
                -73.83495093009276,
                40.61959531763773
              ],
              [
                -73.83495565555567,
                40.6195926071001
              ],
              [
                -73.83496034584253,
                40.619589896009245
              ],
              [
                -73.83496499971633,
                40.61958724015046
              ],
              [
                -73.83496957971555,
                40.61958458535822
              ],
              [
                -73.83497223516518,
                40.619583059797186
              ],
              [
                -73.83497398161872,
                40.61958184688059
              ],
              [
                -73.83497823933318,
                40.61957891553738
              ],
              [
                -73.83498202816111,
                40.61957631338561
              ],
              [
                -73.83498581617671,
                40.6195736839255
              ],
              [
                -73.83498967769185,
                40.61957102692837
              ],
              [
                -73.83499353773405,
                40.61956834312433
              ],
              [
                -73.83499693894599,
                40.61956601164536
              ],
              [
                -73.83499739945185,
                40.61956560387044
              ],
              [
                -73.83500462000539,
                40.6195589981747
              ],
              [
                -73.83500797686946,
                40.61955592933623
              ],
              [
                -73.83500817335559,
                40.619555777669376
              ],
              [
                -73.8350112281038,
                40.61955272263716
              ],
              [
                -73.83501176920392,
                40.61955217442089
              ],
              [
                -73.83501285170816,
                40.619551132938355
              ],
              [
                -73.83501335800345,
                40.61955061214686
              ],
              [
                -73.83501389947668,
                40.619550090903324
              ],
              [
                -73.83501444057265,
                40.619549544362265
              ],
              [
                -73.83501501883251,
                40.619548994524045
              ],
              [
                -73.83501617306052,
                40.61954784341286
              ],
              [
                -73.83501678750642,
                40.61954724102233
              ],
              [
                -73.8350174383641,
                40.61954658373457
              ],
              [
                -73.83501812440285,
                40.619545924822
              ],
              [
                -73.83503401904368,
                40.61953019034733
              ],
              [
                -73.83504749952071,
                40.61951825112878
              ],
              [
                -73.83505029146416,
                40.61951638266211
              ],
              [
                -73.83505108598817,
                40.61951586228417
              ],
              [
                -73.83505195181418,
                40.619515314030984
              ],
              [
                -73.83505285389694,
                40.61951468457826
              ],
              [
                -73.8350538626772,
                40.619514027803866
              ],
              [
                -73.8350549463464,
                40.619513315182154
              ],
              [
                -73.83505648194368,
                40.61951228473612
              ],
              [
                -73.83506107884065,
                40.619510083968294
              ],
              [
                -73.83506259282973,
                40.61950934566193
              ],
              [
                -73.8350643232077,
                40.61950849776681
              ],
              [
                -73.83506630662427,
                40.61950756680533
              ],
              [
                -73.83506854261785,
                40.61950647269773
              ],
              [
                -73.83506882681762,
                40.61950632433968
              ],
              [
                -73.8350720393931,
                40.619505325449445
              ],
              [
                -73.83507654554161,
                40.61950390406364
              ],
              [
                -73.8350822889517,
                40.61950207166105
              ],
              [
                -73.83508234969527,
                40.61950204594868
              ],
              [
                -73.83509013557159,
                40.61949958623132
              ],
              [
                -73.83509575041911,
                40.61949782115782
              ],
              [
                -73.83509911353333,
                40.61949782598211
              ],
              [
                -73.83510247642765,
                40.61949783080596
              ],
              [
                -73.83510471762443,
                40.61949783402078
              ],
              [
                -73.83510807841144,
                40.61949869189965
              ],
              [
                -73.83510843704316,
                40.61949876160357
              ],
              [
                -73.83511143853501,
                40.619499551452805
              ],
              [
                -73.83511367982302,
                40.619500407725766
              ],
              [
                -73.83511725433775,
                40.61950223322397
              ],
              [
                -73.83512039717206,
                40.61950383411642
              ],
              [
                -73.83512498779872,
                40.61950666289585
              ],
              [
                -73.83512599216328,
                40.61950725939971
              ],
              [
                -73.83513109800161,
                40.61951183189057
              ],
              [
                -73.83513270384385,
                40.6195132483068
              ],
              [
                -73.83513451176393,
                40.619515186530826
              ],
              [
                -73.83513659621009,
                40.61951741279902
              ],
              [
                -73.83513829404646,
                40.61951923610766
              ],
              [
                -73.83514069003044,
                40.61952244052232
              ],
              [
                -73.83514276321685,
                40.61952522012284
              ],
              [
                -73.83514377633992,
                40.61952716658858
              ],
              [
                -73.83514499386496,
                40.619529494811275
              ],
              [
                -73.83514570968407,
                40.619531643393415
              ],
              [
                -73.83514610712336,
                40.61953291366635
              ],
              [
                -73.83514688852236,
                40.61953592696026
              ],
              [
                -73.83514795827978,
                40.61954004568952
              ],
              [
                -73.83514832854563,
                40.61954145815573
              ],
              [
                -73.83514863265604,
                40.61954410839969
              ],
              [
                -73.83514909177528,
                40.619548089549575
              ],
              [
                -73.83514942572108,
                40.61955085509625
              ],
              [
                -73.83514915406101,
                40.61955206845503
              ],
              [
                -73.83514824336785,
                40.61955618434435
              ],
              [
                -73.83514733267062,
                40.61956030190894
              ],
              [
                -73.83514715832642,
                40.619561102615606
              ],
              [
                -73.83514642197723,
                40.619564417798145
              ],
              [
                -73.83514551135299,
                40.61956850571018
              ],
              [
                -73.83514489049413,
                40.61957134896137
              ],
              [
                -73.83514423938809,
                40.61957273031027
              ],
              [
                -73.8351392553219,
                40.61958329944254
              ],
              [
                -73.83513580587247,
                40.619589407129915
              ],
              [
                -73.83513348964644,
                40.61959352100328
              ],
              [
                -73.83513117502804,
                40.619597606901735
              ],
              [
                -73.83512882201632,
                40.61960174869952
              ],
              [
                -73.8351265079198,
                40.6196058900507
              ],
              [
                -73.8351257453126,
                40.619607196018976
              ],
              [
                -73.83512339906771,
                40.619609755179
              ],
              [
                -73.83511986031176,
                40.61961356591393
              ],
              [
                -73.83511787957178,
                40.61961572654381
              ],
              [
                -73.83511563653995,
                40.61961762159892
              ],
              [
                -73.83511087117782,
                40.61962162273002
              ],
              [
                -73.83510776823056,
                40.61962425384718
              ],
              [
                -73.83510571089066,
                40.61962564959659
              ],
              [
                -73.83509986472068,
                40.61962964917692
              ],
              [
                -73.8350938384386,
                40.61963375772765
              ],
              [
                -73.8350881028403,
                40.619637674036454
              ],
              [
                -73.83508222142333,
                40.61964170104018
              ],
              [
                -73.83507655587347,
                40.6196455624994
              ],
              [
                -73.83507612289257,
                40.619645863765754
              ],
              [
                -73.83506995210881,
                40.619650083012125
              ],
              [
                -73.83506468473206,
                40.61965367113189
              ],
              [
                -73.8350628545967,
                40.61965493938185
              ],
              [
                -73.8350599580499,
                40.619656875213074
              ],
              [
                -73.83505566434684,
                40.61965977853024
              ],
              [
                -73.8350527483464,
                40.619661757220676
              ],
              [
                -73.83505187585239,
                40.61966260081804
              ],
              [
                -73.83504866200768,
                40.61966588949364
              ],
              [
                -73.83504600631076,
                40.619668582063824
              ],
              [
                -73.83504541136438,
                40.619669122496774
              ],
              [
                -73.8350420905473,
                40.6196721935658
              ],
              [
                -73.8350388417635,
                40.61967520761084
              ],
              [
                -73.83503562771511,
                40.61967822237579
              ],
              [
                -73.83503295469997,
                40.619680716231215
              ],
              [
                -73.83503035670653,
                40.619683100462844
              ],
              [
                -73.83501872969266,
                40.61969392626034
              ],
              [
                -73.8350146512536,
                40.619697707234984
              ],
              [
                -73.83501421668167,
                40.619698118230254
              ],
              [
                -73.83501013817067,
                40.61970192818702
              ],
              [
                -73.83500988633324,
                40.619702147456245
              ],
              [
                -73.83500887401333,
                40.61970307863788
              ],
              [
                -73.83500475739861,
                40.619706916014515
              ],
              [
                -73.83500447068994,
                40.61970718951313
              ],
              [
                -73.83500038938872,
                40.61971097148828
              ],
              [
                -73.83500010062926,
                40.61971127413395
              ],
              [
                -73.83499605765442,
                40.61971502701392
              ],
              [
                -73.83499573129843,
                40.619715330108114
              ],
              [
                -73.83499168832026,
                40.61971908416061
              ],
              [
                -73.8349913280428,
                40.61971941300567
              ],
              [
                -73.83499095388885,
                40.61971975221769
              ],
              [
                -73.83498764366566,
                40.61972324932688
              ],
              [
                -73.83498732003198,
                40.6197236073746
              ],
              [
                -73.83498695749064,
                40.619723962518485
              ],
              [
                -73.83498663238724,
                40.61972429258673
              ],
              [
                -73.83498291263044,
                40.619728210611235
              ],
              [
                -73.83498258899671,
                40.61972856865894
              ],
              [
                -73.83497886776435,
                40.61973246088167
              ],
              [
                -73.83497850647574,
                40.61973284299951
              ],
              [
                -73.834974859619,
                40.61973670902685
              ],
              [
                -73.83497446314935,
                40.61973709276949
              ],
              [
                -73.834970814756,
                40.61974095762178
              ],
              [
                -73.83497072365827,
                40.61974107777688
              ],
              [
                -73.83497048872262,
                40.619741396917654
              ],
              [
                -73.83496781315462,
                40.61974545481973
              ],
              [
                -73.83496513817671,
                40.619749540699814
              ],
              [
                -73.83496284723834,
                40.61975302385905
              ],
              [
                -73.83496249817028,
                40.61975370955706
              ],
              [
                -73.83496028948841,
                40.619758069514056
              ],
              [
                -73.83496003675234,
                40.61975856319457
              ],
              [
                -73.83495779121424,
                40.61976297972344
              ],
              [
                -73.83495757467031,
                40.61976341850631
              ],
              [
                -73.83495721478977,
                40.619764120104044
              ],
              [
                -73.83495605003769,
                40.619768191734494
              ],
              [
                -73.8349559415767,
                40.61976857639302
              ],
              [
                -73.83495466899879,
                40.619773131525065
              ],
              [
                -73.83495382319218,
                40.619776073796984
              ],
              [
                -73.83495375978538,
                40.619776642969875
              ],
              [
                -73.83495342746937,
                40.61978015641562
              ],
              [
                -73.8349530577307,
                40.619783777863944
              ],
              [
                -73.83495267403251,
                40.619787176478376
              ],
              [
                -73.83495272588691,
                40.619787455991435
              ],
              [
                -73.83495311224588,
                40.619791161452135
              ],
              [
                -73.8349534982628,
                40.619795005291415
              ],
              [
                -73.83495392079347,
                40.61979893093737
              ],
              [
                -73.83495434339326,
                40.619802828605906
              ],
              [
                -73.83495473079734,
                40.619806644972435
              ],
              [
                -73.83495511701948,
                40.619810405885126
              ],
              [
                -73.83495550518768,
                40.619814002119426
              ],
              [
                -73.83495585537514,
                40.61981748756254
              ],
              [
                -73.83495620861927,
                40.61982089259598
              ],
              [
                -73.83495815063702,
                40.61983928773374
              ],
              [
                -73.8349920065259,
                40.61986722071551
              ],
              [
                -73.83501268056406,
                40.61988267613292
              ],
              [
                -73.83504962373301,
                40.61988863103023
              ],
              [
                -73.83508243198399,
                40.61989150465224
              ],
              [
                -73.83511766376013,
                40.61989062255555
              ],
              [
                -73.83514645819189,
                40.61989977659646
              ],
              [
                -73.8351961685202,
                40.619943104722374
              ],
              [
                -73.83520875852072,
                40.61995017859354
              ],
              [
                -73.83522937910507,
                40.61995819493326
              ],
              [
                -73.83526739330783,
                40.619953172108715
              ],
              [
                -73.83531699866491,
                40.61993724046734
              ],
              [
                -73.83538308531075,
                40.61994200013588
              ],
              [
                -73.83549391596809,
                40.619959753532186
              ],
              [
                -73.83551236293482,
                40.61995810614527
              ],
              [
                -73.83553327031747,
                40.619937631837715
              ],
              [
                -73.8355369686483,
                40.61991268752325
              ],
              [
                -73.83554696400545,
                40.61989126496711
              ],
              [
                -73.83556282642931,
                40.61985673059368
              ],
              [
                -73.83560626485747,
                40.61982849387305
              ],
              [
                -73.83563870292694,
                40.61980589568313
              ],
              [
                -73.83564923064895,
                40.6197879872992
              ],
              [
                -73.8356673493013,
                40.61975875307178
              ],
              [
                -73.83570733216744,
                40.61972916695197
              ],
              [
                -73.83571454012318,
                40.619698984488906
              ],
              [
                -73.83572583140196,
                40.61966265954252
              ],
              [
                -73.83574706558578,
                40.61961152632981
              ],
              [
                -73.83574513689678,
                40.61957556780853
              ],
              [
                -73.83572870016224,
                40.61955051178868
              ],
              [
                -73.835714468994,
                40.61953726100864
              ],
              [
                -73.83565958049579,
                40.61950495916943
              ],
              [
                -73.83560872508038,
                40.61947310115986
              ],
              [
                -73.83557218292678,
                40.619445710661246
              ],
              [
                -73.83554976450334,
                40.619427424622074
              ],
              [
                -73.8355207781153,
                40.61937899354337
              ],
              [
                -73.83550310305235,
                40.6193156732439
              ],
              [
                -73.83549481789157,
                40.61930119625488
              ],
              [
                -73.83549488479503,
                40.619274049766794
              ],
              [
                -73.83549383449827,
                40.61920350812297
              ],
              [
                -73.83550350266252,
                40.61910989580283
              ],
              [
                -73.83549183618312,
                40.6190058524099
              ],
              [
                -73.8354611268023,
                40.61892620767173
              ],
              [
                -73.83543510318425,
                40.61888911566155
              ],
              [
                -73.83541435759753,
                40.618873249601855
              ],
              [
                -73.83538885612354,
                40.618857842794284
              ],
              [
                -73.83535629616418,
                40.618828398826714
              ],
              [
                -73.8353355951704,
                40.61879447150265
              ],
              [
                -73.83533027499944,
                40.61877546876064
              ],
              [
                -73.8353404200944,
                40.618751055704635
              ],
              [
                -73.83536182798973,
                40.6187320912454
              ],
              [
                -73.83535650781197,
                40.61871309017983
              ],
              [
                -73.83534349182173,
                40.61870357464657
              ],
              [
                -73.8353150144414,
                40.61869719337912
              ],
              [
                -73.83525802361955,
                40.618700295611234
              ],
              [
                -73.83521290614456,
                40.61870794381804
              ],
              [
                -73.83520566728862,
                40.618707404216615
              ],
              [
                -73.83516730584809,
                40.61873634197401
              ],
              [
                -73.83515004883148,
                40.61873763417459
              ],
              [
                -73.8351220063932,
                40.61873015666993
              ],
              [
                -73.83508817813791,
                40.61870326344785
              ],
              [
                -73.83503367077738,
                40.61864817839471
              ],
              [
                -73.83497585229192,
                40.618591856217904
              ],
              [
                -73.83496079783816,
                40.618532930696645
              ],
              [
                -73.83496138913816,
                40.618468950331085
              ],
              [
                -73.83497341954912,
                40.61838113409344
              ],
              [
                -73.8349682566798,
                40.61831341308498
              ],
              [
                -73.83498947570031,
                40.618268208168644
              ],
              [
                -73.83500412587884,
                40.61821423958555
              ],
              [
                -73.83500397387171,
                40.61817345172101
              ],
              [
                -73.83501865754488,
                40.61813518284089
              ],
              [
                -73.83503172539164,
                40.61810879665894
              ],
              [
                -73.83505386643535,
                40.61808428993669
              ],
              [
                -73.83508428232517,
                40.618078596373294
              ],
              [
                -73.83510899173635,
                40.61807852259006
              ],
              [
                -73.83512875541668,
                40.6180828877639
              ],
              [
                -73.83514848023427,
                40.61807468166042
              ],
              [
                -73.83515913111307,
                40.618064622904704
              ],
              [
                -73.83516892325414,
                40.618037024292306
              ],
              [
                -73.83516472778436,
                40.61801505989839
              ],
              [
                -73.83514734483559,
                40.61799442268988
              ],
              [
                -73.83511105765169,
                40.6179712863045
              ],
              [
                -73.83508222781771,
                40.617961939542305
              ],
              [
                -73.8350509052779,
                40.61795635072771
              ],
              [
                -73.83501307295018,
                40.617960824748
              ],
              [
                -73.83497854383712,
                40.61796966830181
              ],
              [
                -73.83496699909188,
                40.61796218646273
              ],
              [
                -73.83496600704768,
                40.61795901756522
              ],
              [
                -73.83496031607699,
                40.617940849237
              ],
              [
                -73.83495035743844,
                40.61791830026055
              ],
              [
                -73.83494283780966,
                40.617885709738324
              ],
              [
                -73.83495416881976,
                40.61783362863527
              ],
              [
                -73.83496222645587,
                40.617780308811774
              ],
              [
                -73.8349818113314,
                40.617740730024934
              ],
              [
                -73.83503079738357,
                40.617624614188166
              ],
              [
                -73.83506760060499,
                40.61755555184745
              ],
              [
                -73.8351060716087,
                40.61749651113463
              ],
              [
                -73.83515679945094,
                40.617418632078284
              ],
              [
                -73.83520018779365,
                40.61735205002422
              ],
              [
                -73.83527378301078,
                40.61724722195532
              ],
              [
                -73.83543187671067,
                40.61705630065517
              ],
              [
                -73.83554316307831,
                40.61693185274675
              ],
              [
                -73.83558888700725,
                40.61688072107752
              ],
              [
                -73.83574510959821,
                40.616717848584244
              ],
              [
                -73.83594162529523,
                40.61654526191713
              ],
              [
                -73.8361498330899,
                40.61639228980907
              ],
              [
                -73.83621028429495,
                40.61634338021524
              ],
              [
                -73.83633821743527,
                40.6162437078502
              ],
              [
                -73.83644029720081,
                40.616170402327306
              ],
              [
                -73.83646539870269,
                40.616156714051925
              ],
              [
                -73.83653038538397,
                40.616121410088425
              ],
              [
                -73.8365605989011,
                40.61611238369183
              ],
              [
                -73.83661044646236,
                40.61611305900015
              ],
              [
                -73.83674770660578,
                40.6161318351782
              ],
              [
                -73.83682027132109,
                40.61615230405996
              ],
              [
                -73.83688206247315,
                40.61615969401999
              ],
              [
                -73.83690989149997,
                40.61615094961781
              ],
              [
                -73.83695526084304,
                40.6161127514912
              ],
              [
                -73.83701342650974,
                40.616041003171304
              ],
              [
                -73.83704926990326,
                40.61599469381101
              ],
              [
                -73.83717327566708,
                40.61585074017479
              ],
              [
                -73.83721865108845,
                40.615810264393254
              ],
              [
                -73.83724993001131,
                40.615789310912994
              ],
              [
                -73.83729967326363,
                40.61577414773579
              ],
              [
                -73.83742299815893,
                40.615761778476774
              ],
              [
                -73.83749594902788,
                40.61575647496065
              ],
              [
                -73.83762220640159,
                40.61573730141596
              ],
              [
                -73.83767959707981,
                40.615717125850004
              ],
              [
                -73.83771154036306,
                40.615704325318845
              ],
              [
                -73.8377251129955,
                40.615692486933575
              ],
              [
                -73.83775556201697,
                40.61564304180019
              ],
              [
                -73.83778783177006,
                40.61559991222926
              ],
              [
                -73.83779167484144,
                40.61555965034287
              ],
              [
                -73.8377855655564,
                40.61553933132381
              ],
              [
                -73.83776702143372,
                40.61552223141875
              ],
              [
                -73.83774137083236,
                40.61550923917904
              ],
              [
                -73.83770572740917,
                40.615502629926624
              ],
              [
                -73.83761124299842,
                40.61549088655009
              ],
              [
                -73.83757377957497,
                40.61547793253399
              ],
              [
                -73.83753077231013,
                40.615448309727185
              ],
              [
                -73.83750808709809,
                40.61543076583835
              ],
              [
                -73.83746760280816,
                40.61541558474025
              ],
              [
                -73.83742250503579,
                40.61541670120903
              ],
              [
                -73.83738923070878,
                40.615413250620804
              ],
              [
                -73.83736778999722,
                40.615402955273666
              ],
              [
                -73.83733296587165,
                40.61535607975402
              ],
              [
                -73.83729720614221,
                40.615338159980894
              ],
              [
                -73.83727813973981,
                40.61532877380782
              ],
              [
                -73.83726283632416,
                40.61534104878436
              ],
              [
                -73.83726642394453,
                40.61534689979378
              ],
              [
                -73.83724567284065,
                40.615349258878105
              ],
              [
                -73.83721530341752,
                40.615336754552786
              ],
              [
                -73.83717716917288,
                40.61531795352673
              ],
              [
                -73.8371224799956,
                40.61530870901979
              ],
              [
                -73.83707080859173,
                40.61530171834586
              ],
              [
                -73.83702921808582,
                40.61529740498504
              ],
              [
                -73.83697872940103,
                40.61529313377217
              ],
              [
                -73.83691998426377,
                40.61529250094957
              ],
              [
                -73.83688967112587,
                40.61528677736104
              ],
              [
                -73.83686341804385,
                40.615270160916445
              ],
              [
                -73.83684841155214,
                40.615249882493586
              ],
              [
                -73.83679106388888,
                40.615208107596644
              ],
              [
                -73.83673971952419,
                40.61517081523142
              ],
              [
                -73.83667370236468,
                40.61515392380227
              ],
              [
                -73.8366362635533,
                40.61514552697838
              ],
              [
                -73.83660663290642,
                40.61515470684256
              ],
              [
                -73.83659202032008,
                40.615179663833004
              ],
              [
                -73.83660293659774,
                40.61520855451133
              ],
              [
                -73.83660620420999,
                40.61524245801393
              ],
              [
                -73.83658509337694,
                40.61527286721724
              ],
              [
                -73.83654842492393,
                40.61528840551515
              ],
              [
                -73.83650810164664,
                40.615295842085764
              ],
              [
                -73.83647071166415,
                40.61529647509992
              ],
              [
                -73.83642080855896,
                40.61528902058307
              ],
              [
                -73.8363821447316,
                40.6152801816666
              ],
              [
                -73.83632259460951,
                40.615255586365365
              ],
              [
                -73.83628443659825,
                40.615232255019855
              ],
              [
                -73.83625868102742,
                40.615203425933615
              ],
              [
                -73.836256190453,
                40.61519123497351
              ],
              [
                -73.8362555309456,
                40.61518083231513
              ],
              [
                -73.83630165045997,
                40.61515978999668
              ],
              [
                -73.83634298945309,
                40.61513473338826
              ],
              [
                -73.83634403495043,
                40.615119337102726
              ],
              [
                -73.83631971425355,
                40.61512262323767
              ],
              [
                -73.83629664347177,
                40.61513087956021
              ],
              [
                -73.8362729621882,
                40.61513779075455
              ],
              [
                -73.83625516805921,
                40.61513787463743
              ],
              [
                -73.83623192892556,
                40.615125765525484
              ],
              [
                -73.83619025265256,
                40.61511239276597
              ],
              [
                -73.83614816967186,
                40.61511801504228
              ],
              [
                -73.83614289101911,
                40.61512618712993
              ],
              [
                -73.83615370058651,
                40.61513926713336
              ],
              [
                -73.83621145405357,
                40.61516204947468
              ],
              [
                -73.83622104005504,
                40.615174661159486
              ],
              [
                -73.83622173494702,
                40.6151850655438
              ],
              [
                -73.83620170280982,
                40.61520235666929
              ],
              [
                -73.83615138042872,
                40.61521842487933
              ],
              [
                -73.83610164292601,
                40.6152313083341
              ],
              [
                -73.83604642983012,
                40.61522977594597
              ],
              [
                -73.83598591313452,
                40.6152318849171
              ],
              [
                -73.83590450244563,
                40.61522051395143
              ],
              [
                -73.83585572623771,
                40.615208999052946
              ],
              [
                -73.83579322194048,
                40.61518439875596
              ],
              [
                -73.83574792728224,
                40.6151629251546
              ],
              [
                -73.83565778576532,
                40.61509867837635
              ],
              [
                -73.83558894560753,
                40.61502891644493
              ],
              [
                -73.83551649420114,
                40.614948746412146
              ],
              [
                -73.83546600150866,
                40.61487346627951
              ],
              [
                -73.83542777742117,
                40.614774599832806
              ],
              [
                -73.83542479353247,
                40.61477237228094
              ],
              [
                -73.83542879838902,
                40.61475470150783
              ],
              [
                -73.83543987928485,
                40.61473067291296
              ],
              [
                -73.83545814612324,
                40.61471384545859
              ],
              [
                -73.83547876382073,
                40.61469339938508
              ],
              [
                -73.83546955939202,
                40.61465726407046
              ],
              [
                -73.83545449273417,
                40.61463245806862
              ],
              [
                -73.83542288683323,
                40.614610454435784
              ],
              [
                -73.83538891957515,
                40.61459659837788
              ],
              [
                -73.83532281744199,
                40.61457067572313
              ],
              [
                -73.83525501050032,
                40.61454974496719
              ],
              [
                -73.83520623957126,
                40.61453597904437
              ],
              [
                -73.83517134812571,
                40.61454663430034
              ],
              [
                -73.83512098542279,
                40.61453569210867
              ],
              [
                -73.83506620220827,
                40.61452021523822
              ],
              [
                -73.83504177385295,
                40.614509393650174
              ],
              [
                -73.83501220878382,
                40.61449233293343
              ],
              [
                -73.83496190251225,
                40.61447290875004
              ],
              [
                -73.83490183563694,
                40.614468157475116
              ],
              [
                -73.83484247863916,
                40.614467933827406
              ],
              [
                -73.83472391325719,
                40.61445052666858
              ],
              [
                -73.8346032336605,
                40.61441390158446
              ],
              [
                -73.83445946628927,
                40.6143924504213
              ],
              [
                -73.83434168122284,
                40.61436543719744
              ],
              [
                -73.83417849166878,
                40.614361990030574
              ],
              [
                -73.83410581152141,
                40.61435944275289
              ],
              [
                -73.83406504547996,
                40.61435647443239
              ],
              [
                -73.83405492385512,
                40.614361887934145
              ],
              [
                -73.83401888200429,
                40.61438116642064
              ],
              [
                -73.8333110544027,
                40.61449331081882
              ],
              [
                -73.8324609196554,
                40.614709339811256
              ],
              [
                -73.8320297090652,
                40.61489770036319
              ],
              [
                -73.83170324361234,
                40.61495690244545
              ],
              [
                -73.8314812223769,
                40.61500631122815
              ],
              [
                -73.83091996001403,
                40.61500548703347
              ],
              [
                -73.83054575285917,
                40.61493306078065
              ],
              [
                -73.83009815098022,
                40.614805340060045
              ],
              [
                -73.82964171605141,
                40.61464551612489
              ],
              [
                -73.82932878428791,
                40.61451574332958
              ],
              [
                -73.82900287951898,
                40.61435611007713
              ],
              [
                -73.82845503182496,
                40.614216039151735
              ],
              [
                -73.82807653538532,
                40.614205527127346
              ],
              [
                -73.82773709441845,
                40.61423485924233
              ],
              [
                -73.82750222609539,
                40.61420466672021
              ],
              [
                -73.82724153726997,
                40.61406502057191
              ],
              [
                -73.8273200321807,
                40.61399551069022
              ],
              [
                -73.82758123538642,
                40.61393622150347
              ],
              [
                -73.82769886106766,
                40.613876718272174
              ],
              [
                -73.82787525471795,
                40.61369762859841
              ],
              [
                -73.82772568489919,
                40.61359824867005
              ],
              [
                -73.82767204227477,
                40.613624148862804
              ],
              [
                -73.82758192904869,
                40.61366765928358
              ],
              [
                -73.82747779182999,
                40.61355808935558
              ],
              [
                -73.82747351895246,
                40.61355236332681
              ],
              [
                -73.82737373432043,
                40.61341867797081
              ],
              [
                -73.82725636632989,
                40.613378714124536
              ],
              [
                -73.82715194745356,
                40.61337855715479
              ],
              [
                -73.82703834217757,
                40.613395002152615
              ],
              [
                -73.82673157371032,
                40.61366495612393
              ],
              [
                -73.82662889857586,
                40.61374580085558
              ],
              [
                -73.82635390865977,
                40.61378615702355
              ],
              [
                -73.82619778134861,
                40.61389435100908
              ],
              [
                -73.82619749647598,
                40.614003765427896
              ],
              [
                -73.82609287085624,
                40.614083182229585
              ],
              [
                -73.82593603373292,
                40.61416251820927
              ],
              [
                -73.82575322105271,
                40.614192081540075
              ],
              [
                -73.82555758997881,
                40.61413210471074
              ],
              [
                -73.82540161085667,
                40.61388319788559
              ],
              [
                -73.82525834646223,
                40.613763619289685
              ],
              [
                -73.82520683961239,
                40.61349497719592
              ],
              [
                -73.824907237176,
                40.61326574495243
              ],
              [
                -73.82468584347531,
                40.61307641875663
              ],
              [
                -73.82460797477792,
                40.61290720498259
              ],
              [
                -73.82473943706756,
                40.61254932058941
              ],
              [
                -73.82467451637949,
                40.612419914122576
              ],
              [
                -73.82458320331664,
                40.61239988154396
              ],
              [
                -73.82444821239913,
                40.61234112665787
              ],
              [
                -73.82436121280683,
                40.61243932957915
              ],
              [
                -73.8243870281721,
                40.61254878272031
              ],
              [
                -73.82432166316987,
                40.612588470367264
              ],
              [
                -73.82424356049394,
                40.61250877658137
              ],
              [
                -73.82410048459042,
                40.61231956879448
              ],
              [
                -73.82403572267913,
                40.612130480906735
              ],
              [
                -73.82397101271293,
                40.61192149929154
              ],
              [
                -73.8238246859766,
                40.61149866495508
              ],
              [
                -73.82379847999995,
                40.61212942099992
              ],
              [
                -73.82234961099986,
                40.61426002599995
              ],
              [
                -73.82176857999997,
                40.61476625499992
              ],
              [
                -73.82170208100001,
                40.614824190999855
              ],
              [
                -73.82163921899993,
                40.61487061499985
              ],
              [
                -73.8215706029998,
                40.61490868999993
              ],
              [
                -73.82141601599992,
                40.61505476299994
              ],
              [
                -73.82075892599994,
                40.61523267899992
              ],
              [
                -73.81768666599999,
                40.614932997999844
              ],
              [
                -73.81652681189121,
                40.61494700958008
              ],
              [
                -73.81711893622217,
                40.615282192345454
              ],
              [
                -73.81716846137088,
                40.615843197722256
              ],
              [
                -73.81751717814184,
                40.616396411661874
              ],
              [
                -73.81760298632462,
                40.61698383258147
              ],
              [
                -73.81775726750763,
                40.617307148815776
              ],
              [
                -73.81777429834523,
                40.61750112169565
              ],
              [
                -73.81764711074565,
                40.61761194496033
              ],
              [
                -73.81744645138826,
                40.618110896996356
              ],
              [
                -73.81744426110988,
                40.618911755911675
              ],
              [
                -73.81718053878453,
                40.619267274493204
              ],
              [
                -73.81682455918782,
                40.61946156485393
              ],
              [
                -73.81639131315181,
                40.61962195550726
              ],
              [
                -73.81543174704264,
                40.62011653124222
              ],
              [
                -73.81557294001615,
                40.62073484077254
              ],
              [
                -73.81571468044605,
                40.62148955151693
              ],
              [
                -73.81653074259579,
                40.62200251874799
              ],
              [
                -73.81691065059802,
                40.62186964709535
              ],
              [
                -73.8169700782087,
                40.621491557101066
              ],
              [
                -73.81778718310667,
                40.62162633177609
              ],
              [
                -73.81778633288076,
                40.621937777634756
              ],
              [
                -73.81825303631376,
                40.62209423858475
              ],
              [
                -73.81889389047457,
                40.622629158032645
              ],
              [
                -73.81891708039825,
                40.62309026716333
              ],
              [
                -73.81795284720685,
                40.62409328011746
              ],
              [
                -73.8167299805857,
                40.623871514072576
              ],
              [
                -73.81664198730377,
                40.62393220944394
              ],
              [
                -73.8160864347078,
                40.62431541030937
              ],
              [
                -73.81611483429543,
                40.624604655173044
              ],
              [
                -73.81710538785215,
                40.625384851107945
              ],
              [
                -73.81769796593258,
                40.62596162249622
              ],
              [
                -73.81800752228978,
                40.62647634385674
              ],
              [
                -73.81796414839563,
                40.627105565696795
              ],
              [
                -73.81947843088551,
                40.62762863286645
              ],
              [
                -73.81973384601805,
                40.6277123040871
              ],
              [
                -73.81991014068153,
                40.62784065311699
              ],
              [
                -73.8199971542669,
                40.62805115103772
              ],
              [
                -73.8200841811797,
                40.6282616395263
              ],
              [
                -73.8202234085928,
                40.62844358255744
              ],
              [
                -73.82059122816396,
                40.62892422501729
              ],
              [
                -73.82081417085445,
                40.629390439641234
              ],
              [
                -73.82107505533321,
                40.62973377456623
              ],
              [
                -73.8218460425281,
                40.63204018843732
              ],
              [
                -73.82183935101227,
                40.63226757853259
              ],
              [
                -73.82184137993583,
                40.6324950205089
              ],
              [
                -73.82185212765629,
                40.63272232029806
              ],
              [
                -73.82187158509224,
                40.63294928395111
              ],
              [
                -73.82189973572993,
                40.63317571780408
              ],
              [
                -73.82193655563644,
                40.63340142864301
              ],
              [
                -73.82198201348177,
                40.63362622386902
              ],
              [
                -73.82203607056276,
                40.63384991166226
              ],
              [
                -73.82209868083864,
                40.6340723011465
              ],
              [
                -73.82216979096668,
                40.634293202551085
              ],
              [
                -73.82224934035098,
                40.63451242737345
              ],
              [
                -73.8223372611914,
                40.63472978853998
              ],
              [
                -73.82243347854202,
                40.63494510056532
              ],
              [
                -73.82253791037438,
                40.6351581797112
              ],
              [
                -73.82265046764824,
                40.63536884414309
              ],
              [
                -73.82277105438692,
                40.63557691408512
              ],
              [
                -73.82337597260663,
                40.63898704717672
              ]
            ]
          ],
          [
            [
              [
                -73.84721834832125,
                40.63196568670605
              ],
              [
                -73.84504859278633,
                40.63317483899966
              ],
              [
                -73.84352007697278,
                40.63650368532793
              ],
              [
                -73.84667914840234,
                40.63873171175714
              ],
              [
                -73.8561374187591,
                40.63508154601468
              ],
              [
                -73.8585583634798,
                40.630458288195605
              ],
              [
                -73.84858996643929,
                40.63013198680654
              ],
              [
                -73.84721834832125,
                40.63196568670605
              ]
            ]
          ],
          [
            [
              [
                -73.79577948747226,
                40.63215847659964
              ],
              [
                -73.79356608423642,
                40.63310162341088
              ],
              [
                -73.79356534109542,
                40.633101939376644
              ],
              [
                -73.79079284299529,
                40.634282043102445
              ],
              [
                -73.7908111127525,
                40.63431035575526
              ],
              [
                -73.79119486069375,
                40.63414821967486
              ],
              [
                -73.79364942874943,
                40.63310209031549
              ],
              [
                -73.79365017276974,
                40.63310177435078
              ],
              [
                -73.79581864007575,
                40.63217769654574
              ],
              [
                -73.7980553716909,
                40.63122445859815
              ],
              [
                -73.79812347917935,
                40.63131768162914
              ],
              [
                -73.79827615415297,
                40.63125217936582
              ],
              [
                -73.79811872226199,
                40.63103750926111
              ],
              [
                -73.79796829437942,
                40.63110130747366
              ],
              [
                -73.7980375183472,
                40.6311962398075
              ],
              [
                -73.79577948747226,
                40.63215847659964
              ]
            ]
          ],
          [
            [
              [
                -73.83623731597828,
                40.63111086123173
              ],
              [
                -73.83765174362401,
                40.63283973295598
              ],
              [
                -73.842423745765,
                40.63153254924424
              ],
              [
                -73.84445711462237,
                40.62984779443999
              ],
              [
                -73.84110205447425,
                40.62455643651514
              ],
              [
                -73.83623731597828,
                40.63111086123173
              ]
            ]
          ],
          [
            [
              [
                -73.81307233892618,
                40.629261099642655
              ],
              [
                -73.81391733219806,
                40.630776392218124
              ],
              [
                -73.81506788441455,
                40.63116405857793
              ],
              [
                -73.81637181200166,
                40.63137272824914
              ],
              [
                -73.8173057692762,
                40.630621973797844
              ],
              [
                -73.81720935727391,
                40.62910635115234
              ],
              [
                -73.81627026043981,
                40.62714580591711
              ],
              [
                -73.81382899493154,
                40.62613507764413
              ],
              [
                -73.81383288662272,
                40.625926233997596
              ],
              [
                -73.81679073502552,
                40.62634891812117
              ],
              [
                -73.81612703184858,
                40.62534899918069
              ],
              [
                -73.81518203155235,
                40.62474417114342
              ],
              [
                -73.81418278559646,
                40.6236946315581
              ],
              [
                -73.81449543302412,
                40.622394945305274
              ],
              [
                -73.81370354000288,
                40.62138674458606
              ],
              [
                -73.81288213914506,
                40.61891091965568
              ],
              [
                -73.81211344524337,
                40.617925546835444
              ],
              [
                -73.81200717773355,
                40.61717937148332
              ],
              [
                -73.81089990481605,
                40.61621847134097
              ],
              [
                -73.8102406650499,
                40.61648764065421
              ],
              [
                -73.81109451157876,
                40.61791025228185
              ],
              [
                -73.81150542539497,
                40.619796810951435
              ],
              [
                -73.81171923661802,
                40.6200942341405
              ],
              [
                -73.81171450418677,
                40.620703573736186
              ],
              [
                -73.81210045334294,
                40.621100596814905
              ],
              [
                -73.81185181296344,
                40.622097618373914
              ],
              [
                -73.81135581995851,
                40.622937515040675
              ],
              [
                -73.81129432207656,
                40.62470377900041
              ],
              [
                -73.81258556038132,
                40.62837191618374
              ],
              [
                -73.81352502122874,
                40.62730617268597
              ],
              [
                -73.81307233892618,
                40.629261099642655
              ]
            ]
          ],
          [
            [
              [
                -73.84734350666976,
                40.62909473971626
              ],
              [
                -73.84846868196166,
                40.62964333075321
              ],
              [
                -73.85071696108574,
                40.62800299210945
              ],
              [
                -73.85436931062769,
                40.62881767654389
              ],
              [
                -73.8539654419115,
                40.62761094389787
              ],
              [
                -73.8561347984124,
                40.62828091127289
              ],
              [
                -73.8565055097758,
                40.6290973537266
              ],
              [
                -73.85786309317031,
                40.628444902667404
              ],
              [
                -73.8576777902821,
                40.62798375894847
              ],
              [
                -73.85861338934723,
                40.627962022328056
              ],
              [
                -73.86008391106512,
                40.6256745170403
              ],
              [
                -73.86041082270874,
                40.62516691297755
              ],
              [
                -73.86137632465496,
                40.625979153993065
              ],
              [
                -73.86334955670225,
                40.62592490877777
              ],
              [
                -73.86347880947005,
                40.62338860931582
              ],
              [
                -73.86102883383384,
                40.624138986994076
              ],
              [
                -73.86147000631671,
                40.62123924718384
              ],
              [
                -73.86151825485233,
                40.61904921839923
              ],
              [
                -73.86343910789503,
                40.61894217513062
              ],
              [
                -73.86404106276487,
                40.61920759671404
              ],
              [
                -73.86364988794934,
                40.61787877364239
              ],
              [
                -73.86107132214268,
                40.61771207937058
              ],
              [
                -73.85947697626024,
                40.61866521683819
              ],
              [
                -73.85665510191748,
                40.61999364918834
              ],
              [
                -73.84847781581286,
                40.62241878479054
              ],
              [
                -73.8461475400832,
                40.62222360960776
              ],
              [
                -73.84358010755503,
                40.62317816418441
              ],
              [
                -73.84504991718353,
                40.62541106188362
              ],
              [
                -73.8472783772798,
                40.626621669791945
              ],
              [
                -73.84984630167418,
                40.62622449074592
              ],
              [
                -73.84734350666976,
                40.62909473971626
              ]
            ]
          ],
          [
            [
              [
                -73.87084603194215,
                40.616967426457606
              ],
              [
                -73.86626127665735,
                40.61845634802395
              ],
              [
                -73.86606533106142,
                40.62149332674912
              ],
              [
                -73.86498193495926,
                40.62566070604103
              ],
              [
                -73.86684384142526,
                40.62782924286026
              ],
              [
                -73.87481386865049,
                40.62358718918367
              ],
              [
                -73.8782477932424,
                40.61544460833155
              ],
              [
                -73.87084603194215,
                40.616967426457606
              ]
            ]
          ],
          [
            [
              [
                -73.79783533398513,
                40.62740871657153
              ],
              [
                -73.80092841565032,
                40.627524104895656
              ],
              [
                -73.8001117714566,
                40.626927441987085
              ],
              [
                -73.80251577946906,
                40.626329137692316
              ],
              [
                -73.80271345029576,
                40.62593478734805
              ],
              [
                -73.8051624884695,
                40.624656224185955
              ],
              [
                -73.80557147888442,
                40.6249690015738
              ],
              [
                -73.80698502381061,
                40.62387199882998
              ],
              [
                -73.80768917829106,
                40.62227766042472
              ],
              [
                -73.80728331329274,
                40.62166643562196
              ],
              [
                -73.80764116896117,
                40.62119965469225
              ],
              [
                -73.80565945060563,
                40.617600255343724
              ],
              [
                -73.80575302222282,
                40.61669485753828
              ],
              [
                -73.80476592616557,
                40.61580099137086
              ],
              [
                -73.80466968986522,
                40.614301827300864
              ],
              [
                -73.80508906012292,
                40.61343256041943
              ],
              [
                -73.8033982096119,
                40.613172104348585
              ],
              [
                -73.80270343867807,
                40.61358065232174
              ],
              [
                -73.80128049504093,
                40.615879839747556
              ],
              [
                -73.80096292242341,
                40.61777236939703
              ],
              [
                -73.80172498640164,
                40.61996700873169
              ],
              [
                -73.79992057632683,
                40.618575240166
              ],
              [
                -73.79951085673736,
                40.62117553302097
              ],
              [
                -73.79809093107173,
                40.62306294824432
              ],
              [
                -73.7936292748135,
                40.62515647619495
              ],
              [
                -73.79403741688607,
                40.62563519595493
              ],
              [
                -73.79698347650404,
                40.62486468974474
              ],
              [
                -73.79921493427757,
                40.6240847129885
              ],
              [
                -73.79566256726108,
                40.626114004327796
              ],
              [
                -73.79744863612672,
                40.62711047626097
              ],
              [
                -73.79783848156961,
                40.62701346630368
              ],
              [
                -73.79783533398513,
                40.62740871657153
              ]
            ]
          ],
          [
            [
              [
                -73.77348616522502,
                40.625085118163526
              ],
              [
                -73.77348560409436,
                40.62511314810922
              ],
              [
                -73.77383258726536,
                40.624944848253456
              ],
              [
                -73.77381024409397,
                40.624925596730954
              ],
              [
                -73.77348616522502,
                40.625085118163526
              ]
            ]
          ],
          [
            [
              [
                -73.77172760292548,
                40.62358502801516
              ],
              [
                -73.77173897590608,
                40.6236057260367
              ],
              [
                -73.77181316781842,
                40.623515235145355
              ],
              [
                -73.77185569638743,
                40.623535820446456
              ],
              [
                -73.77198776448255,
                40.62359758246465
              ],
              [
                -73.77199904490341,
                40.623577104443584
              ],
              [
                -73.77182442403671,
                40.62350159111614
              ],
              [
                -73.77181882771771,
                40.62349901798297
              ],
              [
                -73.77182107912482,
                40.62349645992333
              ],
              [
                -73.77199669270388,
                40.62328155948759
              ],
              [
                -73.77215360357187,
                40.623352851738204
              ],
              [
                -73.77217018334778,
                40.62335963116915
              ],
              [
                -73.77218482383529,
                40.62334001519293
              ],
              [
                -73.77215464477041,
                40.623326795120775
              ],
              [
                -73.7720046057531,
                40.62326192901904
              ],
              [
                -73.77215434472936,
                40.62307345553245
              ],
              [
                -73.77215455823358,
                40.62307319410721
              ],
              [
                -73.7721689786699,
                40.62305554733859
              ],
              [
                -73.7723626016271,
                40.623147326004336
              ],
              [
                -73.7724031363917,
                40.62309530229877
              ],
              [
                -73.77220950703806,
                40.623005232487
              ],
              [
                -73.77236152244429,
                40.62280651245471
              ],
              [
                -73.77241188430918,
                40.622830529320346
              ],
              [
                -73.772425392748,
                40.622814327664635
              ],
              [
                -73.77237278964559,
                40.62278945112993
              ],
              [
                -73.77254616993126,
                40.62257112983642
              ],
              [
                -73.7725965330078,
                40.62259514545177
              ],
              [
                -73.7726089222986,
                40.62257808682594
              ],
              [
                -73.77255744039574,
                40.62255321426784
              ],
              [
                -73.77272857395504,
                40.62233574030543
              ],
              [
                -73.77278118331373,
                40.62235890655195
              ],
              [
                -73.7727935624733,
                40.62234440991183
              ],
              [
                -73.77274432125824,
                40.622320396599655
              ],
              [
                -73.77291322087405,
                40.622099502187915
              ],
              [
                -73.77296470321912,
                40.622124374589085
              ],
              [
                -73.7729815822394,
                40.62210561649268
              ],
              [
                -73.77292561377531,
                40.62208158997259
              ],
              [
                -73.77309787515313,
                40.62186155498596
              ],
              [
                -73.77315159799728,
                40.621886431726466
              ],
              [
                -73.77316286325559,
                40.62187022505424
              ],
              [
                -73.77310914042,
                40.621845348318956
              ],
              [
                -73.77328363248037,
                40.621627881154836
              ],
              [
                -73.7733362347926,
                40.62165275560001
              ],
              [
                -73.77334974791421,
                40.6216348443745
              ],
              [
                -73.77329266580485,
                40.621608253307166
              ],
              [
                -73.77346603422149,
                40.62139163720942
              ],
              [
                -73.77352087855441,
                40.62141651548623
              ],
              [
                -73.77353102837378,
                40.62139859761321
              ],
              [
                -73.77347730578079,
                40.62137372104882
              ],
              [
                -73.77350067294029,
                40.62134390917439
              ],
              [
                -73.77364843599236,
                40.62115539246868
              ],
              [
                -73.77370215913446,
                40.6211802684523
              ],
              [
                -73.7737167938199,
                40.621161506331575
              ],
              [
                -73.7736608298803,
                40.621136625440286
              ],
              [
                -73.77383308349965,
                40.62091744405923
              ],
              [
                -73.77388568146198,
                40.62094317298174
              ],
              [
                -73.77389694638454,
                40.62092696573332
              ],
              [
                -73.77384546527352,
                40.62090209425039
              ],
              [
                -73.77401659017951,
                40.62068461933783
              ],
              [
                -73.77407143925994,
                40.62070778889978
              ],
              [
                -73.77408383225163,
                40.62068987655831
              ],
              [
                -73.77402897875453,
                40.620667560052944
              ],
              [
                -73.77420347429072,
                40.62044752778313
              ],
              [
                -73.77425720928375,
                40.62046898727483
              ],
              [
                -73.77426958798797,
                40.62045449164608
              ],
              [
                -73.77421473327385,
                40.62043302878822
              ],
              [
                -73.7743858730955,
                40.620210428793975
              ],
              [
                -73.774438476271,
                40.620234448006876
              ],
              [
                -73.77445085909793,
                40.620219098134356
              ],
              [
                -73.77439825015809,
                40.62019678670988
              ],
              [
                -73.77457162552267,
                40.61997589861759
              ],
              [
                -73.77462198893456,
                40.61999905929051
              ],
              [
                -73.77463437522347,
                40.61998285399991
              ],
              [
                -73.77458288591141,
                40.619960545524215
              ],
              [
                -73.77475625738923,
                40.6197405112075
              ],
              [
                -73.77480885456588,
                40.61976623803492
              ],
              [
                -73.77482012770623,
                40.6197474693547
              ],
              [
                -73.77476640595854,
                40.61972259439369
              ],
              [
                -73.77493976825987,
                40.61950426656821
              ],
              [
                -73.77499461579019,
                40.619528289922876
              ],
              [
                -73.77500588175907,
                40.619511230009586
              ],
              [
                -73.77495328183132,
                40.61948635632548
              ],
              [
                -73.77506460979983,
                40.6193432401131
              ],
              [
                -73.77506473853114,
                40.61934307518109
              ],
              [
                -73.77511877728904,
                40.61927399379187
              ],
              [
                -73.77515794285772,
                40.619293716067645
              ],
              [
                -73.77518045935565,
                40.61926471788485
              ],
              [
                -73.77511891254431,
                40.61923384748737
              ],
              [
                -73.77503335134442,
                40.619343013761736
              ],
              [
                -73.7750332232728,
                40.61934317869501
              ],
              [
                -73.77456049454022,
                40.619951959126276
              ],
              [
                -73.77411466469974,
                40.620522520213164
              ],
              [
                -73.77363729197724,
                40.621135724360485
              ],
              [
                -73.77347337495445,
                40.62134402610003
              ],
              [
                -73.77308562752837,
                40.62183676009296
              ],
              [
                -73.77272418290725,
                40.62230839938325
              ],
              [
                -73.77218824028016,
                40.622995793795134
              ],
              [
                -73.7721490467794,
                40.62297672432935
              ],
              [
                -73.77207296880073,
                40.62293919036049
              ],
              [
                -73.7720313143135,
                40.62299121290298
              ],
              [
                -73.77214546970639,
                40.623046105200444
              ],
              [
                -73.7719866984392,
                40.62325335218967
              ],
              [
                -73.77188149343576,
                40.62320360205058
              ],
              [
                -73.77187021304596,
                40.62322407888819
              ],
              [
                -73.77197318324889,
                40.623272116308364
              ],
              [
                -73.7718065553057,
                40.623481909990694
              ],
              [
                -73.77180205088992,
                40.623487880338125
              ],
              [
                -73.7716990840563,
                40.623438989211046
              ],
              [
                -73.77168780941253,
                40.623457758247994
              ],
              [
                -73.77178742171202,
                40.623504081873136
              ],
              [
                -73.77172760292548,
                40.62358502801516
              ]
            ]
          ],
          [
            [
              [
                -73.79162058913477,
                40.62231334348309
              ],
              [
                -73.79187800516166,
                40.622361047834765
              ],
              [
                -73.79226351352034,
                40.62236908907301
              ],
              [
                -73.79260056336351,
                40.62246272158056
              ],
              [
                -73.79288358054498,
                40.622559220696914
              ],
              [
                -73.79339000917749,
                40.62275300952429
              ],
              [
                -73.79352550682152,
                40.62277100975674
              ],
              [
                -73.7936621898034,
                40.62278270172928
              ],
              [
                -73.7937995527352,
                40.62278804221028
              ],
              [
                -73.79393708771487,
                40.622787011453454
              ],
              [
                -73.79407428620424,
                40.62277961327007
              ],
              [
                -73.79421064090884,
                40.62276587501484
              ],
              [
                -73.79434564765546,
                40.62274584748536
              ],
              [
                -73.7944083285481,
                40.6227214295587
              ],
              [
                -73.79446657752885,
                40.62269130652243
              ],
              [
                -73.79451950481986,
                40.62265593852227
              ],
              [
                -73.79456630193827,
                40.62261586582284
              ],
              [
                -73.7946062540462,
                40.62257170055432
              ],
              [
                -73.79463875087076,
                40.622524117361976
              ],
              [
                -73.79466329602539,
                40.622473843099364
              ],
              [
                -73.79467951459112,
                40.62242164572645
              ],
              [
                -73.79468715884306,
                40.622368322576925
              ],
              [
                -73.79468611203347,
                40.62231468817971
              ],
              [
                -73.79469349867145,
                40.62229615045645
              ],
              [
                -73.79469703112095,
                40.62227696502235
              ],
              [
                -73.79469701293509,
                40.62226136198848
              ],
              [
                -73.79469287035404,
                40.62224030856273
              ],
              [
                -73.79468409207296,
                40.622220097900176
              ],
              [
                -73.79467093351647,
                40.62220131806769
              ],
              [
                -73.79465377756067,
                40.62218451549909
              ],
              [
                -73.7946331233919,
                40.62217017909578
              ],
              [
                -73.79460957198225,
                40.622158726001054
              ],
              [
                -73.79458380860342,
                40.62215048946315
              ],
              [
                -73.79454713555883,
                40.62214079982292
              ],
              [
                -73.79451306906626,
                40.62212662440958
              ],
              [
                -73.79448254640309,
                40.62210835323431
              ],
              [
                -73.79445640734178,
                40.62208648899569
              ],
              [
                -73.79443537104669,
                40.62206163324824
              ],
              [
                -73.79442001628661,
                40.62203446985182
              ],
              [
                -73.7944107655114,
                40.622005746156184
              ],
              [
                -73.79442386285946,
                40.62194964635316
              ],
              [
                -73.79443425229591,
                40.621893218400594
              ],
              [
                -73.79444015482304,
                40.62185157331521
              ],
              [
                -73.79444985028816,
                40.621838200689716
              ],
              [
                -73.79446208584234,
                40.62182609763951
              ],
              [
                -73.79447658061319,
                40.62181554199396
              ],
              [
                -73.79449300186825,
                40.62180677606106
              ],
              [
                -73.79451097265147,
                40.62180000106544
              ],
              [
                -73.79453008043838,
                40.62179537252888
              ],
              [
                -73.7945498866044,
                40.62179299670095
              ],
              [
                -73.79457205740796,
                40.62179305726193
              ],
              [
                -73.79458992636214,
                40.62179332726087
              ],
              [
                -73.79460748245779,
                40.62179587828246
              ],
              [
                -73.79462422777566,
                40.62180063797562
              ],
              [
                -73.79463968739142,
                40.621807471347736
              ],
              [
                -73.79465342284485,
                40.62181618459349
              ],
              [
                -73.7946650445754,
                40.62182653059095
              ],
              [
                -73.79467422297094,
                40.621838215910586
              ],
              [
                -73.7946806977154,
                40.62185090913754
              ],
              [
                -73.79468428517262,
                40.62186425027077
              ],
              [
                -73.7946998425511,
                40.62187501854031
              ],
              [
                -73.79471744928763,
                40.62188376469572
              ],
              [
                -73.79473665729232,
                40.6218902661478
              ],
              [
                -73.79475697772168,
                40.62189435743464
              ],
              [
                -73.79477789342168,
                40.62189593443298
              ],
              [
                -73.79479887208713,
                40.621894957008195
              ],
              [
                -73.79481937981173,
                40.62189145003592
              ],
              [
                -73.7948388946738,
                40.621885502768336
              ],
              [
                -73.79485692002076,
                40.62187726656349
              ],
              [
                -73.79487299710861,
                40.62186695103255
              ],
              [
                -73.79488671677727,
                40.62185481870541
              ],
              [
                -73.79493837689395,
                40.62177167815794
              ],
              [
                -73.7950189085003,
                40.62175262209944
              ],
              [
                -73.7950962193355,
                40.62172696487349
              ],
              [
                -73.79516938062133,
                40.62169501471601
              ],
              [
                -73.79523751343378,
                40.62165715546329
              ],
              [
                -73.7952305015144,
                40.621633596629096
              ],
              [
                -73.79521891255901,
                40.621611111345615
              ],
              [
                -73.79520301100747,
                40.62159021267855
              ],
              [
                -73.79518315970257,
                40.62157137749002
              ],
              [
                -73.7951388093929,
                40.62155346646536
              ],
              [
                -73.79509847534585,
                40.621530700789585
              ],
              [
                -73.79506306561068,
                40.62150359299404
              ],
              [
                -73.79503337737094,
                40.62147275336469
              ],
              [
                -73.79501007899763,
                40.62143887620254
              ],
              [
                -73.79500514352377,
                40.621405760017005
              ],
              [
                -73.7950050657438,
                40.62137243103301
              ],
              [
                -73.79501188737623,
                40.62133074366215
              ],
              [
                -73.79502629419169,
                40.621290193120814
              ],
              [
                -73.79504800293363,
                40.62125157664581
              ],
              [
                -73.79507658678963,
                40.621215653448544
              ],
              [
                -73.79511148378306,
                40.621183129788584
              ],
              [
                -73.79515200782234,
                40.62115464508866
              ],
              [
                -73.79519736218977,
                40.62113075936381
              ],
              [
                -73.79521361356514,
                40.621069587539004
              ],
              [
                -73.79524207751014,
                40.62104002328583
              ],
              [
                -73.79527626988178,
                40.62101422309463
              ],
              [
                -73.79531534579557,
                40.62099282447684
              ],
              [
                -73.79535833969949,
                40.62097635618212
              ],
              [
                -73.79540418923348,
                40.62096522513399
              ],
              [
                -73.79545176147752,
                40.62095970637506
              ],
              [
                -73.79549988094514,
                40.62095993627105
              ],
              [
                -73.79551348247503,
                40.62095459289188
              ],
              [
                -73.79552579101806,
                40.62094766446797
              ],
              [
                -73.79553649126696,
                40.62093932848453
              ],
              [
                -73.79554530911442,
                40.620929798484546
              ],
              [
                -73.7955506277687,
                40.62092187797619
              ],
              [
                -73.79555463970607,
                40.62091352252508
              ],
              [
                -73.79553742958896,
                40.62087569380565
              ],
              [
                -73.7955172338287,
                40.62083873209171
              ],
              [
                -73.79549708296938,
                40.62080707886347
              ],
              [
                -73.79547474221752,
                40.620776290894604
              ],
              [
                -73.7954917359122,
                40.62075602931513
              ],
              [
                -73.79557680732991,
                40.62052658531257
              ],
              [
                -73.79555957594641,
                40.62035048679241
              ],
              [
                -73.79559534308251,
                40.620211013607275
              ],
              [
                -73.79593052903355,
                40.61986154232403
              ],
              [
                -73.79592429747481,
                40.619797882709285
              ],
              [
                -73.79596932719024,
                40.61978082573557
              ],
              [
                -73.7959703456468,
                40.619780655155594
              ],
              [
                -73.79602718518987,
                40.619771137162004
              ],
              [
                -73.79615920936664,
                40.61967100171485
              ],
              [
                -73.79618830890925,
                40.61960985302919
              ],
              [
                -73.79615312712686,
                40.61955838278801
              ],
              [
                -73.79612434843372,
                40.61951426669914
              ],
              [
                -73.79615340374144,
                40.61946780657347
              ],
              [
                -73.79623393627779,
                40.61939695614389
              ],
              [
                -73.79629197255308,
                40.61932851423564
              ],
              [
                -73.79625038803628,
                40.61926968869612
              ],
              [
                -73.79623255834962,
                40.61924798081692
              ],
              [
                -73.79620983600925,
                40.61921189355172
              ],
              [
                -73.79619376920586,
                40.61917378592504
              ],
              [
                -73.79618465680534,
                40.61913436682055
              ],
              [
                -73.79618266830488,
                40.619094369517356
              ],
              [
                -73.79618784068263,
                40.619054538048566
              ],
              [
                -73.79620007770853,
                40.61901561336169
              ],
              [
                -73.79621915173756,
                40.61897831953448
              ],
              [
                -73.79628362735241,
                40.61890499283249
              ],
              [
                -73.79628373194903,
                40.618870720884715
              ],
              [
                -73.79622603236346,
                40.618829002505706
              ],
              [
                -73.79623256238474,
                40.61879474144485
              ],
              [
                -73.79628721338942,
                40.618782598113334
              ],
              [
                -73.79631300438106,
                40.61875326789157
              ],
              [
                -73.7962938552147,
                40.618711617845875
              ],
              [
                -73.79634215632429,
                40.618674983523206
              ],
              [
                -73.79635192027044,
                40.618633383030996
              ],
              [
                -73.79632956787496,
                40.61858927954574
              ],
              [
                -73.7963362531319,
                40.61850361138954
              ],
              [
                -73.7963556790422,
                40.61845468453535
              ],
              [
                -73.7964103073449,
                40.618449885756306
              ],
              [
                -73.796455245125,
                40.61846220602791
              ],
              [
                -73.79650023582766,
                40.61845738896819
              ],
              [
                -73.79651316930017,
                40.61843048370957
              ],
              [
                -73.79655815251495,
                40.61842811505436
              ],
              [
                -73.79660959004038,
                40.61841596638524
              ],
              [
                -73.79665464913575,
                40.61838911794599
              ],
              [
                -73.79670932209949,
                40.618369629969294
              ],
              [
                -73.79676073741287,
                40.618364824671666
              ],
              [
                -73.79678892958304,
                40.618369400147465
              ],
              [
                -73.79682174691476,
                40.61837472544337
              ],
              [
                -73.7968570552419,
                40.61838457909241
              ],
              [
                -73.79689881105922,
                40.61838710064378
              ],
              [
                -73.79697270043967,
                40.61838723116663
              ],
              [
                -73.79700484778857,
                40.61837994395792
              ],
              [
                -73.7970049966818,
                40.618330984124846
              ],
              [
                -73.79698912798531,
                40.61826730754102
              ],
              [
                -73.79697639665079,
                40.61822811671141
              ],
              [
                -73.79692502482435,
                40.61821823422007
              ],
              [
                -73.79690098172009,
                40.61819271142593
              ],
              [
                -73.79687931758346,
                40.61816598032531
              ],
              [
                -73.79685530183275,
                40.618130335621814
              ],
              [
                -73.79683551278102,
                40.618093213468185
              ],
              [
                -73.79681506826151,
                40.61803887028155
              ],
              [
                -73.79680366406913,
                40.617983010023146
              ],
              [
                -73.79679432022967,
                40.617958550305296
              ],
              [
                -73.79678918081787,
                40.6179333781625
              ],
              [
                -73.79678900569206,
                40.61789943088137
              ],
              [
                -73.79679645333708,
                40.617865961080696
              ],
              [
                -73.79681130421892,
                40.61783395529965
              ],
              [
                -73.79683312059072,
                40.61780435692334
              ],
              [
                -73.7968355083033,
                40.61779669175437
              ],
              [
                -73.79683621008719,
                40.61778883177156
              ],
              [
                -73.79683520685202,
                40.61778099079728
              ],
              [
                -73.79683252589022,
                40.61777338213689
              ],
              [
                -73.79682824013456,
                40.61776621277585
              ],
              [
                -73.7968224661755,
                40.617759677748744
              ],
              [
                -73.79681536108711,
                40.61775395483375
              ],
              [
                -73.79680711815642,
                40.617749199716755
              ],
              [
                -73.7967979616229,
                40.61774554175527
              ],
              [
                -73.79679087006494,
                40.61773620910838
              ],
              [
                -73.79678593722032,
                40.617726101146225
              ],
              [
                -73.79678330335601,
                40.61771550529432
              ],
              [
                -73.79678304336664,
                40.61770472285115
              ],
              [
                -73.7967851646435,
                40.61769406042087
              ],
              [
                -73.7967896068661,
                40.617683821195136
              ],
              [
                -73.79679624371698,
                40.61767429633122
              ],
              [
                -73.79680488647283,
                40.617665756673006
              ],
              [
                -73.79681528937235,
                40.61765844504975
              ],
              [
                -73.79682715660415,
                40.61765256937057
              ],
              [
                -73.79684885740897,
                40.61766454118811
              ],
              [
                -73.79687285440359,
                40.6176736183098
              ],
              [
                -73.79689848885276,
                40.617679551561444
              ],
              [
                -73.79692505707091,
                40.617682178070865
              ],
              [
                -73.7969518297396,
                40.61768142573818
              ],
              [
                -73.7969780719283,
                40.61767731521557
              ],
              [
                -73.79701554121124,
                40.617667705994194
              ],
              [
                -73.79705076574594,
                40.61765402789129
              ],
              [
                -73.79708298940882,
                40.61763657451954
              ],
              [
                -73.79711152049389,
                40.61761572053033
              ],
              [
                -73.79713574656108,
                40.617591913572056
              ],
              [
                -73.79715072031405,
                40.617589923471726
              ],
              [
                -73.79716591970326,
                40.61758983871034
              ],
              [
                -73.79718093007504,
                40.61759166160028
              ],
              [
                -73.7971953419327,
                40.6175953424115
              ],
              [
                -73.79720876210737,
                40.617600780728
              ],
              [
                -73.79722082448347,
                40.617607828187424
              ],
              [
                -73.79722884309027,
                40.6176141097702
              ],
              [
                -73.79723698492384,
                40.61760436943579
              ],
              [
                -73.7972429814662,
                40.61759376347242
              ],
              [
                -73.7972466788679,
                40.617582563992634
              ],
              [
                -73.79724798226711,
                40.61757105833647
              ],
              [
                -73.79724685822482,
                40.61755954169916
              ],
              [
                -73.79724333558096,
                40.61754830955737
              ],
              [
                -73.79723859239994,
                40.61753931321156
              ],
              [
                -73.79723230812465,
                40.61753088314315
              ],
              [
                -73.7971950402285,
                40.61748271879481
              ],
              [
                -73.79716194894681,
                40.61743281446217
              ],
              [
                -73.79713317353263,
                40.6173813801645
              ],
              [
                -73.79714541720053,
                40.617352563049764
              ],
              [
                -73.7971622569712,
                40.61732512572865
              ],
              [
                -73.79723481710543,
                40.61728036799297
              ],
              [
                -73.7973025665911,
                40.61723142723817
              ],
              [
                -73.79736509127339,
                40.617178602648906
              ],
              [
                -73.79737437783018,
                40.61717539776419
              ],
              [
                -73.79738307443444,
                40.61717134414275
              ],
              [
                -73.79739398850936,
                40.617164378082954
              ],
              [
                -73.7974031676439,
                40.617156085387116
              ],
              [
                -73.79741033826045,
                40.61714671321377
              ],
              [
                -73.79741528664454,
                40.617136540894286
              ],
              [
                -73.79741786531413,
                40.6171258716079
              ],
              [
                -73.79741799741426,
                40.61711502334536
              ],
              [
                -73.79741567900955,
                40.61710431943164
              ],
              [
                -73.79741097919928,
                40.61709407888927
              ],
              [
                -73.79740500561536,
                40.6170857203192
              ],
              [
                -73.79739744399028,
                40.61707814508095
              ],
              [
                -73.79738846778457,
                40.617071526947086
              ],
              [
                -73.79737828290916,
                40.617066017734686
              ],
              [
                -73.79740304786137,
                40.61701748579914
              ],
              [
                -73.79742164092688,
                40.61696737851773
              ],
              [
                -73.79743334153846,
                40.61691923278799
              ],
              [
                -73.79743257606707,
                40.61690481155978
              ],
              [
                -73.79742873273153,
                40.61689067880913
              ],
              [
                -73.7974219145618,
                40.616877213390644
              ],
              [
                -73.79741285659374,
                40.616865378467175
              ],
              [
                -73.79740150151996,
                40.616854756014526
              ],
              [
                -73.79738812362802,
                40.61684560262253
              ],
              [
                -73.79737304606743,
                40.61683813939531
              ],
              [
                -73.79735663304317,
                40.61683254661029
              ],
              [
                -73.79733928101942,
                40.61682895936337
              ],
              [
                -73.79732140914075,
                40.61682746430596
              ],
              [
                -73.79730344910976,
                40.61682809755179
              ],
              [
                -73.7972858347583,
                40.61683084380452
              ],
              [
                -73.79726824114533,
                40.616804379918754
              ],
              [
                -73.79725677124432,
                40.61677603056553
              ],
              [
                -73.79725175517643,
                40.616746611701004
              ],
              [
                -73.79725333730626,
                40.61671697006295
              ],
              [
                -73.79726147208783,
                40.61668795880017
              ],
              [
                -73.79727592537725,
                40.61666041291678
              ],
              [
                -73.79729628117188,
                40.61663512523925
              ],
              [
                -73.79732195358513,
                40.61661282359747
              ],
              [
                -73.79735220370958,
                40.61659414987635
              ],
              [
                -73.79738616088451,
                40.61657964154207
              ],
              [
                -73.79742284775487,
                40.61656971617214
              ],
              [
                -73.7974865779239,
                40.61657159119655
              ],
              [
                -73.79755006077956,
                40.61656692375328
              ],
              [
                -73.79761766866868,
                40.61652369911771
              ],
              [
                -73.79770623653,
                40.616473902508254
              ],
              [
                -73.79779911547128,
                40.616428897404745
              ],
              [
                -73.79789585988114,
                40.61638889972726
              ],
              [
                -73.79799600560548,
                40.61635410137157
              ],
              [
                -73.7980422573597,
                40.616340123748245
              ],
              [
                -73.79805227042077,
                40.61633408567414
              ],
              [
                -73.7980609038916,
                40.61632691654107
              ],
              [
                -73.79806794033756,
                40.61631879690427
              ],
              [
                -73.79807320254595,
                40.61630993125736
              ],
              [
                -73.79807655798858,
                40.61630054288194
              ],
              [
                -73.79807792215901,
                40.61629086822487
              ],
              [
                -73.79807726070204,
                40.61628115094272
              ],
              [
                -73.79807459027694,
                40.61627163576555
              ],
              [
                -73.7981168619278,
                40.61623602981713
              ],
              [
                -73.79815224732067,
                40.616196301744026
              ],
              [
                -73.798180063007,
                40.61615321888528
              ],
              [
                -73.79819977174908,
                40.61610761337566
              ],
              [
                -73.79821099289485,
                40.61606036607211
              ],
              [
                -73.7982135097299,
                40.61601238954078
              ],
              [
                -73.79821336662795,
                40.61599842785603
              ],
              [
                -73.7982102070556,
                40.61598467491259
              ],
              [
                -73.79820411736088,
                40.615971506557294
              ],
              [
                -73.79819526396729,
                40.615959282660796
              ],
              [
                -73.79818388882569,
                40.615948337283086
              ],
              [
                -73.79817030280194,
                40.615938969543954
              ],
              [
                -73.79815487718109,
                40.61593143544886
              ],
              [
                -73.79813803352123,
                40.61592594089244
              ],
              [
                -73.79812023213249,
                40.61592263603208
              ],
              [
                -73.79810195949871,
                40.61592161118436
              ],
              [
                -73.79808371498052,
                40.61592289435658
              ],
              [
                -73.7980659971718,
                40.61592645048177
              ],
              [
                -73.79806456056252,
                40.61588873824279
              ],
              [
                -73.7980702376693,
                40.615851258973024
              ],
              [
                -73.79808291105947,
                40.61581478786384
              ],
              [
                -73.79810231859612,
                40.6157800792528
              ],
              [
                -73.79812948535047,
                40.6157463350454
              ],
              [
                -73.79816300611748,
                40.61571609028359
              ],
              [
                -73.79816866238929,
                40.61571538603761
              ],
              [
                -73.79820740884634,
                40.615712793192124
              ],
              [
                -73.79824626970642,
                40.61571404500148
              ],
              [
                -73.79829052975519,
                40.61572026239391
              ],
              [
                -73.7983330696275,
                40.61573145891767
              ],
              [
                -73.79837293055456,
                40.61574738222481
              ],
              [
                -73.79840921414502,
                40.61576767343402
              ],
              [
                -73.7984411026312,
                40.615791875219585
              ],
              [
                -73.79846787730132,
                40.61581944211858
              ],
              [
                -73.79846423671493,
                40.61582715618435
              ],
              [
                -73.79846227472295,
                40.61583521645853
              ],
              [
                -73.79846204259528,
                40.61584341231714
              ],
              [
                -73.798463546398,
                40.61585152959327
              ],
              [
                -73.79846674683574,
                40.615859356173395
              ],
              [
                -73.79847147216802,
                40.61586657665371
              ],
              [
                -73.79847764045894,
                40.61587313464398
              ],
              [
                -73.79848509556415,
                40.61587886413418
              ],
              [
                -73.79849364876466,
                40.61588362008753
              ],
              [
                -73.79850308354331,
                40.6158872821111
              ],
              [
                -73.79851316106756,
                40.615889757503936
              ],
              [
                -73.79852362623315,
                40.615890983603514
              ],
              [
                -73.79853421412332,
                40.6158909293723
              ],
              [
                -73.79854465671518,
                40.615889596183095
              ],
              [
                -73.79855468966309,
                40.61588701778434
              ],
              [
                -73.79856405899156,
                40.61588325944612
              ],
              [
                -73.79858179535773,
                40.61584531289664
              ],
              [
                -73.79860752880491,
                40.61581012668706
              ],
              [
                -73.79864054950353,
                40.615778671374734
              ],
              [
                -73.7986799466203,
                40.615751814604934
              ],
              [
                -73.79872463344243,
                40.61573029717817
              ],
              [
                -73.79877337735275,
                40.61571471261736
              ],
              [
                -73.7987596034431,
                40.615665809649045
              ],
              [
                -73.79874476833574,
                40.61561429404402
              ],
              [
                -73.79874685574764,
                40.61559865553288
              ],
              [
                -73.79875228542326,
                40.61558349066319
              ],
              [
                -73.79876090988529,
                40.61556921132597
              ],
              [
                -73.79877249488439,
                40.61555620535976
              ],
              [
                -73.7987867257609,
                40.61554482601728
              ],
              [
                -73.79880321599168,
                40.6155353823705
              ],
              [
                -73.79882151768798,
                40.61552813091638
              ],
              [
                -73.79884113376117,
                40.61552326861022
              ],
              [
                -73.79886153142321,
                40.61552092751572
              ],
              [
                -73.798882156658,
                40.61552117121894
              ],
              [
                -73.7989024492685,
                40.61552399310073
              ],
              [
                -73.79891001234513,
                40.615530979236844
              ],
              [
                -73.79893881229017,
                40.61555239464188
              ],
              [
                -73.79897187441813,
                40.61556987336724
              ],
              [
                -73.79900828287818,
                40.61558293123431
              ],
              [
                -73.79902535487066,
                40.61556767192166
              ],
              [
                -73.79903903505591,
                40.615550542484
              ],
              [
                -73.7990489771576,
                40.615531976509764
              ],
              [
                -73.79905492951963,
                40.61551244394934
              ],
              [
                -73.79905674147689,
                40.61549243921921
              ],
              [
                -73.79903991670028,
                40.6154752484791
              ],
              [
                -73.7990259290895,
                40.61545663968881
              ],
              [
                -73.79901498196996,
                40.615436883350206
              ],
              [
                -73.79900623216678,
                40.61541265401811
              ],
              [
                -73.79900205053465,
                40.61538772682639
              ],
              [
                -73.79900252053618,
                40.61536259933242
              ],
              [
                -73.79901522475771,
                40.615352506088335
              ],
              [
                -73.79902991524453,
                40.615344123060886
              ],
              [
                -73.79904620025569,
                40.61533767379418
              ],
              [
                -73.79906364553027,
                40.615333330265955
              ],
              [
                -73.79909594590342,
                40.61527463368829
              ],
              [
                -73.79912189571496,
                40.61519144628432
              ],
              [
                -73.79917031100383,
                40.61511564261109
              ],
              [
                -73.79928524480067,
                40.61504858803874
              ],
              [
                -73.79929581592357,
                40.61504242030886
              ],
              [
                -73.79940224366943,
                40.61490306923504
              ],
              [
                -73.79939159947278,
                40.61486201971181
              ],
              [
                -73.79937683876149,
                40.614805104676854
              ],
              [
                -73.79936741505661,
                40.61473409616844
              ],
              [
                -73.79944032859643,
                40.61466299362397
              ],
              [
                -73.79945762700558,
                40.61464612461835
              ],
              [
                -73.79949960873981,
                40.61457275684785
              ],
              [
                -73.79947688999992,
                40.61451366178858
              ],
              [
                -73.79945817122422,
                40.61446497258375
              ],
              [
                -73.79946148689586,
                40.614430704547196
              ],
              [
                -73.79950266109694,
                40.61440840132189
              ],
              [
                -73.79950653347994,
                40.61440630390767
              ],
              [
                -73.79951021374198,
                40.61437755693212
              ],
              [
                -73.79951311218878,
                40.614354907498125
              ],
              [
                -73.79950678346574,
                40.61432307158209
              ],
              [
                -73.79944259530328,
                40.61430337542756
              ],
              [
                -73.79947799745693,
                40.614281405240085
              ],
              [
                -73.79950698933646,
                40.614254527145995
              ],
              [
                -73.79947510832075,
                40.614173686542635
              ],
              [
                -73.79951767033212,
                40.61390692658455
              ],
              [
                -73.79959903264584,
                40.613845207246555
              ],
              [
                -73.7996367993324,
                40.61381655754867
              ],
              [
                -73.799685101531,
                40.613777474043054
              ],
              [
                -73.7996595570501,
                40.61372602046493
              ],
              [
                -73.7996502507532,
                40.61361584331302
              ],
              [
                -73.79959252486924,
                40.61358391830638
              ],
              [
                -73.79953794488276,
                40.613574031379144
              ],
              [
                -73.79947157403302,
                40.61356969956874
              ],
              [
                -73.79942232263285,
                40.6135664856972
              ],
              [
                -73.79939200146262,
                40.6135474925859
              ],
              [
                -73.7993517832537,
                40.61352229868277
              ],
              [
                -73.79930387847645,
                40.61342918985625
              ],
              [
                -73.79931694077327,
                40.61335822059154
              ],
              [
                -73.7993748959427,
                40.613314257854434
              ],
              [
                -73.7994681113871,
                40.61329483582883
              ],
              [
                -73.79947902190645,
                40.61328836008457
              ],
              [
                -73.799542126579,
                40.61325089985135
              ],
              [
                -73.79960319061051,
                40.61324121507318
              ],
              [
                -73.7996867102713,
                40.61324136065052
              ],
              [
                -73.79972220057732,
                40.6131900134303
              ],
              [
                -73.79978007368867,
                40.61317297851171
              ],
              [
                -73.79987003947546,
                40.61316579119975
              ],
              [
                -73.7999343527159,
                40.61314387003032
              ],
              [
                -73.79999243799428,
                40.61305584330252
              ],
              [
                -73.80000872033963,
                40.61298243061677
              ],
              [
                -73.80001213766509,
                40.61291389175864
              ],
              [
                -73.80001550298633,
                40.61286248972253
              ],
              [
                -73.8000573663292,
                40.612828290398596
              ],
              [
                -73.80006848765422,
                40.61280166319349
              ],
              [
                -73.80007677854819,
                40.612781811815076
              ],
              [
                -73.80008978990148,
                40.61272797760892
              ],
              [
                -73.80008992919086,
                40.61268146499957
              ],
              [
                -73.80007074363093,
                40.612652055692585
              ],
              [
                -73.80006058206881,
                40.61264427738756
              ],
              [
                -73.80001625835416,
                40.61261034477478
              ],
              [
                -73.79999067071367,
                40.61257358033082
              ],
              [
                -73.80004543342156,
                40.61252610380154
              ],
              [
                -73.80005830484173,
                40.61251494494672
              ],
              [
                -73.80007125684408,
                40.61248069532333
              ],
              [
                -73.80007134482238,
                40.61245131953741
              ],
              [
                -73.80019080019999,
                40.612250789542124
              ],
              [
                -73.80021346073205,
                40.61219207587894
              ],
              [
                -73.80019433453432,
                40.61214308251086
              ],
              [
                -73.80019944719878,
                40.61213616188073
              ],
              [
                -73.80023948919161,
                40.61208196016918
              ],
              [
                -73.80035197001881,
                40.612065019851826
              ],
              [
                -73.80041950019094,
                40.61204065672905
              ],
              [
                -73.8004774226873,
                40.61200648463071
              ],
              [
                -73.80056088361394,
                40.61202621415962
              ],
              [
                -73.80062192312624,
                40.61202387228098
              ],
              [
                -73.80067977373251,
                40.612014179682696
              ],
              [
                -73.80071198359919,
                40.611984859592084
              ],
              [
                -73.80073775474803,
                40.61196042387409
              ],
              [
                -73.80080125919478,
                40.611933904481596
              ],
              [
                -73.80074112012176,
                40.611909021812934
              ],
              [
                -73.80069947118977,
                40.611872229680465
              ],
              [
                -73.80065460219349,
                40.61183787921729
              ],
              [
                -73.8006033005496,
                40.611805965851936
              ],
              [
                -73.80056162959758,
                40.611776517615894
              ],
              [
                -73.80057138324905,
                40.61173736566872
              ],
              [
                -73.80057144723526,
                40.61171594698154
              ],
              [
                -73.8005714929456,
                40.611700645932906
              ],
              [
                -73.8005395167722,
                40.61165163032446
              ],
              [
                -73.80052668996203,
                40.61164426408017
              ],
              [
                -73.80049472403927,
                40.61164318147944
              ],
              [
                -73.800436695706,
                40.61166124361109
              ],
              [
                -73.80039810464088,
                40.611675864574025
              ],
              [
                -73.80034349615052,
                40.61167576973235
              ],
              [
                -73.80030502354086,
                40.61165122317155
              ],
              [
                -73.80025700070239,
                40.6115972828881
              ],
              [
                -73.80017340117132,
                40.611624066255
              ],
              [
                -73.80007703500371,
                40.611623898684364
              ],
              [
                -73.8000159585105,
                40.61163848040783
              ],
              [
                -73.79988394133282,
                40.611743514879436
              ],
              [
                -73.79988381658069,
                40.6117851308024
              ],
              [
                -73.79978086995465,
                40.6118363605969
              ],
              [
                -73.7997262371791,
                40.611868790745774
              ],
              [
                -73.79970685649201,
                40.611880295561754
              ],
              [
                -73.7996105031139,
                40.61187523140935
              ],
              [
                -73.79944972926401,
                40.61192880785067
              ],
              [
                -73.79939184177391,
                40.61195073879188
              ],
              [
                -73.79938235953564,
                40.61198821297142
              ],
              [
                -73.79937883934393,
                40.61200212516797
              ],
              [
                -73.79932094418534,
                40.612026505004486
              ],
              [
                -73.79917318059519,
                40.6120262469318
              ],
              [
                -73.79911239018124,
                40.6119453551912
              ],
              [
                -73.79912554232706,
                40.61184500898044
              ],
              [
                -73.7991259695635,
                40.6117030255618
              ],
              [
                -73.79912946875938,
                40.61160755768692
              ],
              [
                -73.79903982792398,
                40.61150703230239
              ],
              [
                -73.79898529394339,
                40.611482457719156
              ],
              [
                -73.79886957949954,
                40.61150673452405
              ],
              [
                -73.79876353846713,
                40.61151878962434
              ],
              [
                -73.79864139333537,
                40.61154550388557
              ],
              [
                -73.79852584119676,
                40.61151592428682
              ],
              [
                -73.79848744997311,
                40.611464449087414
              ],
              [
                -73.79848442127276,
                40.61140324294632
              ],
              [
                -73.79850705499888,
                40.61135432249593
              ],
              [
                -73.79839061713035,
                40.611316369093494
              ],
              [
                -73.79837869240349,
                40.61131248126357
              ],
              [
                -73.79836345563528,
                40.61131132153457
              ],
              [
                -73.7982470203558,
                40.61130245846462
              ],
              [
                -73.79808315944648,
                40.61131441143469
              ],
              [
                -73.79794184478439,
                40.61130681744336
              ],
              [
                -73.79771064649509,
                40.61127948261544
              ],
              [
                -73.79752750476399,
                40.61129384930131
              ],
              [
                -73.79745676195705,
                40.61131820439622
              ],
              [
                -73.79735739829847,
                40.61124703717324
              ],
              [
                -73.79736385663233,
                40.61121314479721
              ],
              [
                -73.79737045480938,
                40.61117851537032
              ],
              [
                -73.79736731722681,
                40.611154028935644
              ],
              [
                -73.79731593657868,
                40.61114904330363
              ],
              [
                -73.79722278233363,
                40.611148879002506
              ],
              [
                -73.7971624469114,
                40.611148772545405
              ],
              [
                -73.79711999198145,
                40.61114869761805
              ],
              [
                -73.79701077653216,
                40.61114850479514
              ],
              [
                -73.79696581925703,
                40.61114352917739
              ],
              [
                -73.79691452867704,
                40.611109165904274
              ],
              [
                -73.79691732270999,
                40.61107042681866
              ],
              [
                -73.79691788228041,
                40.6110626589664
              ],
              [
                -73.79692441928965,
                40.61102595108639
              ],
              [
                -73.79689556095903,
                40.61100876317943
              ],
              [
                -73.79679919436889,
                40.611008592858376
              ],
              [
                -73.79655209352363,
                40.610929819676294
              ],
              [
                -73.79637893337657,
                40.610831592281286
              ],
              [
                -73.79624422255733,
                40.61076525650764
              ],
              [
                -73.79603232973153,
                40.61072816075956
              ],
              [
                -73.79579178957168,
                40.6106053324849
              ],
              [
                -73.79566026938924,
                40.61054634685171
              ],
              [
                -73.79555747865007,
                40.61054616406761
              ],
              [
                -73.79537441438853,
                40.61053604538014
              ],
              [
                -73.79529736025292,
                40.61052366797633
              ],
              [
                -73.79523965260137,
                40.610486845252964
              ],
              [
                -73.79514006718176,
                40.61048911507523
              ],
              [
                -73.79508550143615,
                40.61048901780996
              ],
              [
                -73.79505655097407,
                40.61048896619428
              ],
              [
                -73.79494752459134,
                40.61042757140562
              ],
              [
                -73.79487372764495,
                40.610400511721046
              ],
              [
                -73.79480633875043,
                40.61037836048123
              ],
              [
                -73.79476157163172,
                40.61031218299261
              ],
              [
                -73.79476169204892,
                40.610273014828095
              ],
              [
                -73.79478325170489,
                40.6102621164994
              ],
              [
                -73.79480995093127,
                40.610248621254094
              ],
              [
                -73.79475872103917,
                40.61019467293104
              ],
              [
                -73.79467868056405,
                40.6101088487307
              ],
              [
                -73.79467600571685,
                40.61010685454051
              ],
              [
                -73.79455369214612,
                40.61001560132704
              ],
              [
                -73.79452178213785,
                40.609946999451914
              ],
              [
                -73.79452510633077,
                40.60991028495337
              ],
              [
                -73.7944674687868,
                40.609851429330284
              ],
              [
                -73.79446116506642,
                40.609812249676345
              ],
              [
                -73.79446700696276,
                40.609809866127186
              ],
              [
                -73.79454478656235,
                40.609778126510626
              ],
              [
                -73.79454166514711,
                40.60974874497967
              ],
              [
                -73.79443270053278,
                40.60966776511181
              ],
              [
                -73.79433651754034,
                40.609608841147214
              ],
              [
                -73.7942466746913,
                40.6095768559605
              ],
              [
                -73.79418557615642,
                40.60959877853625
              ],
              [
                -73.7941544822748,
                40.6095924833882
              ],
              [
                -73.79406359128787,
                40.60957408030571
              ],
              [
                -73.79397714240278,
                40.60948334807852
              ],
              [
                -73.79389688394807,
                40.609468517220755
              ],
              [
                -73.7938456491542,
                40.6094170157455
              ],
              [
                -73.79383944411542,
                40.609346012506236
              ],
              [
                -73.7938799776454,
                40.609308174461006
              ],
              [
                -73.79388132265457,
                40.60930691922945
              ],
              [
                -73.79394239014111,
                40.609294788017834
              ],
              [
                -73.79394893616826,
                40.609255632541334
              ],
              [
                -73.7938527911191,
                40.60918446635633
              ],
              [
                -73.79381432923432,
                40.60915746918647
              ],
              [
                -73.79377580688372,
                40.60915005608764
              ],
              [
                -73.79372780624465,
                40.60909121804704
              ],
              [
                -73.79372793482922,
                40.6090496021196
              ],
              [
                -73.79365104095642,
                40.60898581496915
              ],
              [
                -73.79355791222609,
                40.60897830475483
              ],
              [
                -73.79351938111787,
                40.6089733393267
              ],
              [
                -73.79340051851256,
                40.60897802195192
              ],
              [
                -73.79330754229287,
                40.60892154898024
              ],
              [
                -73.79329482918058,
                40.60887746218557
              ],
              [
                -73.7932854153539,
                40.60885680763679
              ],
              [
                -73.7932724972324,
                40.608828461873294
              ],
              [
                -73.79321161830435,
                40.60877939120709
              ],
              [
                -73.79311408510226,
                40.60877147197761
              ],
              [
                -73.79305747543663,
                40.608766874079976
              ],
              [
                -73.7929965663598,
                40.60872759550929
              ],
              [
                -73.79293361676645,
                40.60868158407414
              ],
              [
                -73.79292606044487,
                40.6086760605845
              ],
              [
                -73.792920727321,
                40.60867516323761
              ],
              [
                -73.79274948626171,
                40.60864636687806
              ],
              [
                -73.79214499541325,
                40.608749484996686
              ],
              [
                -73.7919771311963,
                40.60872949173802
              ],
              [
                -73.7917598105094,
                40.608757186369736
              ],
              [
                -73.7915832433979,
                40.6087250414358
              ],
              [
                -73.7914741007453,
                40.608702812011714
              ],
              [
                -73.79141980894755,
                40.608602343748615
              ],
              [
                -73.79133325013774,
                40.60854833043536
              ],
              [
                -73.79126583521303,
                40.608535968322805
              ],
              [
                -73.79117287672476,
                40.60847459910881
              ],
              [
                -73.79107360039092,
                40.60837894565772
              ],
              [
                -73.791115556099,
                40.60831537327887
              ],
              [
                -73.79115751304454,
                40.608251801389216
              ],
              [
                -73.79128604191624,
                40.60823734570321
              ],
              [
                -73.79140813105519,
                40.6082277755758
              ],
              [
                -73.7915097486129,
                40.608155516350855
              ],
              [
                -73.79151114690586,
                40.60815452141683
              ],
              [
                -73.79159895008117,
                40.607809510119296
              ],
              [
                -73.79156369380654,
                40.60776587244002
              ],
              [
                -73.7914796291121,
                40.60766182537742
              ],
              [
                -73.79147416230792,
                40.607655058827774
              ],
              [
                -73.79139721011913,
                40.60761085524994
              ],
              [
                -73.79139192215438,
                40.60766166622427
              ],
              [
                -73.79139058757958,
                40.60767449136725
              ],
              [
                -73.79133899604432,
                40.60773804585382
              ],
              [
                -73.79118798343292,
                40.60775245958023
              ],
              [
                -73.79104723143563,
                40.607747327333854
              ],
              [
                -73.79104666876955,
                40.60774730654251
              ],
              [
                -73.79098892075747,
                40.607725169572966
              ],
              [
                -73.79098584610871,
                40.60768110004131
              ],
              [
                -73.79091191580129,
                40.60769810132761
              ],
              [
                -73.79087659100047,
                40.607695589287495
              ],
              [
                -73.79088916294998,
                40.607783741214334
              ],
              [
                -73.79089225900364,
                40.60782046679719
              ],
              [
                -73.79083984839724,
                40.60787640669499
              ],
              [
                -73.79085658903483,
                40.60792811514515
              ],
              [
                -73.7908659950587,
                40.608001572159225
              ],
              [
                -73.79085621325443,
                40.608048067245385
              ],
              [
                -73.79077575139577,
                40.60809932990773
              ],
              [
                -73.79072104674592,
                40.60813105354399
              ],
              [
                -73.79071592085809,
                40.6081304766207
              ],
              [
                -73.79054444426578,
                40.60811114765306
              ],
              [
                -73.79038020614568,
                40.608245490172976
              ],
              [
                -73.79046997488345,
                40.608299510226956
              ],
              [
                -73.79048250009374,
                40.6084023500878
              ],
              [
                -73.7904812468437,
                40.6084092339349
              ],
              [
                -73.79047269491804,
                40.60845618791435
              ],
              [
                -73.79039866370154,
                40.608505014261475
              ],
              [
                -73.79029670742352,
                40.60851625044823
              ],
              [
                -73.79028941414941,
                40.608517054517776
              ],
              [
                -73.7901736401271,
                40.608560907831645
              ],
              [
                -73.79007706133318,
                40.60862927633312
              ],
              [
                -73.79001264272644,
                40.60868546330695
              ],
              [
                -73.79001236552747,
                40.608773590842034
              ],
              [
                -73.7900957887504,
                40.60880312018514
              ],
              [
                -73.79011167083347,
                40.60885945333057
              ],
              [
                -73.79006660916056,
                40.608888746992584
              ],
              [
                -73.78999907091941,
                40.608915551799875
              ],
              [
                -73.78998612257973,
                40.60894735304491
              ],
              [
                -73.78995389362336,
                40.60898156632591
              ],
              [
                -73.78984776252041,
                40.60902298899239
              ],
              [
                -73.78964852690936,
                40.60904955272556
              ],
              [
                -73.78960657372537,
                40.609071069314716
              ],
              [
                -73.78953913741638,
                40.609105656619995
              ],
              [
                -73.78948118868125,
                40.60914716666045
              ],
              [
                -73.78939130309898,
                40.609129866744816
              ],
              [
                -73.78938805806573,
                40.60913008528823
              ],
              [
                -73.78928528024618,
                40.60913701641446
              ],
              [
                -73.78916957340999,
                40.609158836202404
              ],
              [
                -73.78901840238645,
                40.60922220694456
              ],
              [
                -73.78903435482209,
                40.60925650839919
              ],
              [
                -73.78910162466049,
                40.609315384936835
              ],
              [
                -73.78911746868255,
                40.60938395768333
              ],
              [
                -73.78911184344186,
                40.609413459520624
              ],
              [
                -73.78909787128777,
                40.60948673874685
              ],
              [
                -73.78908160165909,
                40.60955280596736
              ],
              [
                -73.78899165373358,
                40.60955508982054
              ],
              [
                -73.78897525486543,
                40.60955358796784
              ],
              [
                -73.78893706313524,
                40.60955009287068
              ],
              [
                -73.7888662029074,
                40.609611163587566
              ],
              [
                -73.78884041217606,
                40.60964049216583
              ],
              [
                -73.7888050709401,
                40.60964287450448
              ],
              [
                -73.78876011660967,
                40.60963789567892
              ],
              [
                -73.78875680409138,
                40.60966971449305
              ],
              [
                -73.78879514030761,
                40.60973588201698
              ],
              [
                -73.78876926429393,
                40.60979213860528
              ],
              [
                -73.7887080088047,
                40.60986301813369
              ],
              [
                -73.78870777636307,
                40.60993645876802
              ],
              [
                -73.78871402336283,
                40.609992774892014
              ],
              [
                -73.78865910627547,
                40.610090594226804
              ],
              [
                -73.78859475483434,
                40.61012474855983
              ],
              [
                -73.78853725349592,
                40.610024274213146
              ],
              [
                -73.78837012007149,
                40.610055790335636
              ],
              [
                -73.78836030518765,
                40.61011207640171
              ],
              [
                -73.78832480067742,
                40.61016586788918
              ],
              [
                -73.78829264835399,
                40.61017560060223
              ],
              [
                -73.78824140912991,
                40.61012654656824
              ],
              [
                -73.78785894330079,
                40.610194384978335
              ],
              [
                -73.78787485581836,
                40.61024092722477
              ],
              [
                -73.78790602838673,
                40.610276750110984
              ],
              [
                -73.78791326214538,
                40.61028506219789
              ],
              [
                -73.78783925815156,
                40.61032409384312
              ],
              [
                -73.78770742637307,
                40.61036546826494
              ],
              [
                -73.7876144287936,
                40.610316334640224
              ],
              [
                -73.78759578527854,
                40.610281761814086
              ],
              [
                -73.78757610833092,
                40.61024527171079
              ],
              [
                -73.78741544568004,
                40.6102621115996
              ],
              [
                -73.7872610742468,
                40.61032057789604
              ],
              [
                -73.78719984810371,
                40.6103816649134
              ],
              [
                -73.78723178914876,
                40.610438028181576
              ],
              [
                -73.78722999351474,
                40.61047392407473
              ],
              [
                -73.78722835948798,
                40.610506566677444
              ],
              [
                -73.78719602603874,
                40.61057260389081
              ],
              [
                -73.78721512862111,
                40.61062649494941
              ],
              [
                -73.78719897412161,
                40.610655842169685
              ],
              [
                -73.78710253758436,
                40.61067769555467
              ],
              [
                -73.78684518697295,
                40.61079472358822
              ],
              [
                -73.78682445265207,
                40.6107883309109
              ],
              [
                -73.78646972800934,
                40.610678968981624
              ],
              [
                -73.78621843179218,
                40.610911064582744
              ],
              [
                -73.78577809315796,
                40.61099347707071
              ],
              [
                -73.78553984649244,
                40.611161946880635
              ],
              [
                -73.78551080380369,
                40.611203508345326
              ],
              [
                -73.78544973958921,
                40.61121318664409
              ],
              [
                -73.7852242315061,
                40.61141595124299
              ],
              [
                -73.78521116182439,
                40.61148447099249
              ],
              [
                -73.78516282917359,
                40.6115308935415
              ],
              [
                -73.78507251590146,
                40.61164578191832
              ],
              [
                -73.78506792206908,
                40.611684101720336
              ],
              [
                -73.78505931114388,
                40.61175591822392
              ],
              [
                -73.78509129264211,
                40.61180004195303
              ],
              [
                -73.78507185385064,
                40.611851414697206
              ],
              [
                -73.78501066349975,
                40.61190026040854
              ],
              [
                -73.78497523353337,
                40.61192957008814
              ],
              [
                -73.78485638084535,
                40.61192934769009
              ],
              [
                -73.7847728543081,
                40.61193163909378
              ],
              [
                -73.78475981475187,
                40.61199036775516
              ],
              [
                -73.78472760664935,
                40.612017235618886
              ],
              [
                -73.78464086652882,
                40.612019520908184
              ],
              [
                -73.7845799363939,
                40.61198758300819
              ],
              [
                -73.78458789348959,
                40.61195962067107
              ],
              [
                -73.78458968364842,
                40.61195332910705
              ],
              [
                -73.78447437567272,
                40.61185029594852
              ],
              [
                -73.78436963663604,
                40.6118877889443
              ],
              [
                -73.78422271946083,
                40.61194038033935
              ],
              [
                -73.78394373669919,
                40.612040245791384
              ],
              [
                -73.78382814992248,
                40.612022891547255
              ],
              [
                -73.7837605485183,
                40.61193510310077
              ],
              [
                -73.78371299347518,
                40.61187334670785
              ],
              [
                -73.78355221437404,
                40.61192445198088
              ],
              [
                -73.7835263575679,
                40.61197336343408
              ],
              [
                -73.78345861352133,
                40.612061364865625
              ],
              [
                -73.78335575780689,
                40.61208075524395
              ],
              [
                -73.78330429802993,
                40.6121002429373
              ],
              [
                -73.78322696610738,
                40.61217353650432
              ],
              [
                -73.78315942025627,
                40.612200337783364
              ],
              [
                -73.78302450498049,
                40.6122000831892
              ],
              [
                -73.78296005311707,
                40.612263609630624
              ],
              [
                -73.78292121972007,
                40.61235166533052
              ],
              [
                -73.7829146271154,
                40.612403060797575
              ],
              [
                -73.7828790134917,
                40.61248867479038
              ],
              [
                -73.78278570606834,
                40.61253501137743
              ],
              [
                -73.78272796594388,
                40.61251042134019
              ],
              [
                -73.78261557467621,
                40.612497968636845
              ],
              [
                -73.78239023652235,
                40.6126444243652
              ],
              [
                -73.7823933773236,
                40.61266646227556
              ],
              [
                -73.78231954307886,
                40.612651634497674
              ],
              [
                -73.78197506772605,
                40.61288354264365
              ],
              [
                -73.78177836610467,
                40.61311328429783
              ],
              [
                -73.78160136846203,
                40.61321086842279
              ],
              [
                -73.78137959932594,
                40.61324716678575
              ],
              [
                -73.7812605110188,
                40.613317933505044
              ],
              [
                -73.78127952806561,
                40.61339630628866
              ],
              [
                -73.78122166520576,
                40.61340843637569
              ],
              [
                -73.78119576665524,
                40.61346958792765
              ],
              [
                -73.78119226512999,
                40.61355771028799
              ],
              [
                -73.78115999778085,
                40.613601712787116
              ],
              [
                -73.78105720259778,
                40.613601517036116
              ],
              [
                -73.78098980054021,
                40.61358425171302
              ],
              [
                -73.78090621621647,
                40.613603676618716
              ],
              [
                -73.78088360834359,
                40.61364035463625
              ],
              [
                -73.78092185869777,
                40.61373100434156
              ],
              [
                -73.78100199089187,
                40.61378501389626
              ],
              [
                -73.7810403451562,
                40.613813496770575
              ],
              [
                -73.78112055120368,
                40.61387581600957
              ],
              [
                -73.78114590422712,
                40.613981129052654
              ],
              [
                -73.78114906072878,
                40.613998271982446
              ],
              [
                -73.78097249377586,
                40.61396366301034
              ],
              [
                -73.78085046476025,
                40.613951190222224
              ],
              [
                -73.78070887195283,
                40.614026809005864
              ],
              [
                -73.7807535478932,
                40.614117470519204
              ],
              [
                -73.78076621936054,
                40.6141713515442
              ],
              [
                -73.78075878329832,
                40.61417785676907
              ],
              [
                -73.78072433857183,
                40.61420799159635
              ],
              [
                -73.78076582541503,
                40.61429130303216
              ],
              [
                -73.78080415768376,
                40.61435747319251
              ],
              [
                -73.7808103408408,
                40.61443092653004
              ],
              [
                -73.7807937650353,
                40.61458756709245
              ],
              [
                -73.78085789171314,
                40.61462440929852
              ],
              [
                -73.78098597748865,
                40.61474950353707
              ],
              [
                -73.78106300976259,
                40.614769233967756
              ],
              [
                -73.78111067894662,
                40.61476240829905
              ],
              [
                -73.78113050286747,
                40.6147595705718
              ],
              [
                -73.78115934985756,
                40.61477920968273
              ],
              [
                -73.78114968573595,
                40.61480474370714
              ],
              [
                -73.78113990717638,
                40.614830580573596
              ],
              [
                -73.78115269211109,
                40.61485018910313
              ],
              [
                -73.78120399415778,
                40.61487966338073
              ],
              [
                -73.78122023166848,
                40.61488130926985
              ],
              [
                -73.78127785533971,
                40.61488714843479
              ],
              [
                -73.78141597273658,
                40.61489230670212
              ],
              [
                -73.78144149492914,
                40.61494621158036
              ],
              [
                -73.78147617328904,
                40.61501039771566
              ],
              [
                -73.78149577687141,
                40.61504668348637
              ],
              [
                -73.78158240022272,
                40.6150811212792
              ],
              [
                -73.78164014281379,
                40.6151057102113
              ],
              [
                -73.78171700912468,
                40.61517684879662
              ],
              [
                -73.7818100802744,
                40.61520395366908
              ],
              [
                -73.78184222128019,
                40.615199119631036
              ],
              [
                -73.78187903708229,
                40.61516485585312
              ],
              [
                -73.78186828033726,
                40.615089008115866
              ],
              [
                -73.78189090283789,
                40.61504743389118
              ],
              [
                -73.78197744644285,
                40.615106350463286
              ],
              [
                -73.78199656877105,
                40.61515289959363
              ],
              [
                -73.78205109149752,
                40.61517993165954
              ],
              [
                -73.7821313541849,
                40.61519477128253
              ],
              [
                -73.78221175978906,
                40.61516554770508
              ],
              [
                -73.78229527471422,
                40.61516815367755
              ],
              [
                -73.78231752089329,
                40.61524163684286
              ],
              [
                -73.78228181716396,
                40.61535417796198
              ],
              [
                -73.78230713854042,
                40.615436560082706
              ],
              [
                -73.7823104265697,
                40.61544725736528
              ],
              [
                -73.78238409631444,
                40.61551349272969
              ],
              [
                -73.78248993127501,
                40.615567549886805
              ],
              [
                -73.7825669578421,
                40.61558972756902
              ],
              [
                -73.78275635553686,
                40.615631701809015
              ],
              [
                -73.78298118565476,
                40.61564436669286
              ],
              [
                -73.7829884433602,
                40.61564124591981
              ],
              [
                -73.78310021264994,
                40.61559318344573
              ],
              [
                -73.78322554530014,
                40.615578730723286
              ],
              [
                -73.78326717962132,
                40.61561797810338
              ],
              [
                -73.78327820490809,
                40.61570128109888
              ],
              [
                -73.78333396543637,
                40.61582618521766
              ],
              [
                -73.78344597109685,
                40.61595859086472
              ],
              [
                -73.78353260458238,
                40.61599057725003
              ],
              [
                -73.7835543329955,
                40.615996299924284
              ],
              [
                -73.7836449441638,
                40.61602016468612
              ],
              [
                -73.7836600298764,
                40.61610922673385
              ],
              [
                -73.78366070504741,
                40.616113220062005
              ],
              [
                -73.78366473079498,
                40.616114250907856
              ],
              [
                -73.78370885300865,
                40.61612555086567
              ],
              [
                -73.78381802921345,
                40.616140444209
              ],
              [
                -73.78390756529659,
                40.61626790874861
              ],
              [
                -73.78390327278183,
                40.61636910751812
              ],
              [
                -73.78390060938848,
                40.61643191346047
              ],
              [
                -73.78396793585149,
                40.61647365614667
              ],
              [
                -73.78405446729549,
                40.616537468502656
              ],
              [
                -73.78406670589821,
                40.61654371889476
              ],
              [
                -73.78412181771587,
                40.61657186598544
              ],
              [
                -73.78415704405519,
                40.61660620428706
              ],
              [
                -73.78415237624955,
                40.61663524595187
              ],
              [
                -73.78414720948078,
                40.61666738664297
              ],
              [
                -73.78418536377005,
                40.61678985942237
              ],
              [
                -73.78426867510038,
                40.61685611283726
              ],
              [
                -73.78438089785311,
                40.616922419251615
              ],
              [
                -73.78446087067887,
                40.617027835129704
              ],
              [
                -73.78445326249802,
                40.61708854087404
              ],
              [
                -73.78444768097869,
                40.61713307462089
              ],
              [
                -73.78442813736525,
                40.61721627074391
              ],
              [
                -73.78437346044376,
                40.617235752376715
              ],
              [
                -73.78433998512607,
                40.617304797615795
              ],
              [
                -73.78433788632171,
                40.61730912548489
              ],
              [
                -73.78429919287694,
                40.617353117964186
              ],
              [
                -73.78429575194136,
                40.61742410357805
              ],
              [
                -73.78432120689804,
                40.61750004012531
              ],
              [
                -73.78440144915797,
                40.61752222260187
              ],
              [
                -73.78445273734563,
                40.61755659093149
              ],
              [
                -73.78445579298352,
                40.61760555728037
              ],
              [
                -73.78438813142866,
                40.61766663070499
              ],
              [
                -73.78434624252294,
                40.617705720992674
              ],
              [
                -73.78435569021109,
                40.61776449058741
              ],
              [
                -73.7844101694661,
                40.61780620957504
              ],
              [
                -73.78453859900476,
                40.61782848281917
              ],
              [
                -73.7845818498871,
                40.617855050601264
              ],
              [
                -73.78455126699085,
                40.61788481116399
              ],
              [
                -73.78449655136116,
                40.61791653247602
              ],
              [
                -73.78450284231191,
                40.617958161405724
              ],
              [
                -73.78451882678748,
                40.61798267058927
              ],
              [
                -73.78458950259879,
                40.61798280304865
              ],
              [
                -73.78465054000075,
                40.61798291740891
              ],
              [
                -73.78467752923336,
                40.61797939255476
              ],
              [
                -73.7847244609295,
                40.61797326360713
              ],
              [
                -73.78478547486449,
                40.61798072183546
              ],
              [
                -73.78481106475007,
                40.61801504289332
              ],
              [
                -73.78481093058895,
                40.6180566587725
              ],
              [
                -73.78478291732561,
                40.61811635181473
              ],
              [
                -73.78477534795478,
                40.618132480937554
              ],
              [
                -73.78476856019043,
                40.61824507642238
              ],
              [
                -73.78478111816777,
                40.61833567667867
              ],
              [
                -73.78488368265464,
                40.61840930965986
              ],
              [
                -73.7850632794476,
                40.618505118554204
              ],
              [
                -73.78515920006002,
                40.61864728248332
              ],
              [
                -73.78514920785406,
                40.61875742472632
              ],
              [
                -73.78516495572417,
                40.618855375549586
              ],
              [
                -73.78517115109868,
                40.618926379687885
              ],
              [
                -73.7851545601093,
                40.61909036591641
              ],
              [
                -73.78518330139579,
                40.61914427645058
              ],
              [
                -73.78520870265943,
                40.619237347917775
              ],
              [
                -73.78516032368114,
                40.61929600937436
              ],
              [
                -73.78514372469022,
                40.619462444523876
              ],
              [
                -73.78517524443522,
                40.61965100037343
              ],
              [
                -73.78512331439762,
                40.61981492104341
              ],
              [
                -73.78507784902251,
                40.61996661358056
              ],
              [
                -73.78521543076681,
                40.620063493744425
              ],
              [
                -73.78518924603694,
                40.620138423587036
              ],
              [
                -73.78515414205441,
                40.62021120464334
              ],
              [
                -73.78511041542829,
                40.62028122189329
              ],
              [
                -73.78505843563585,
                40.62034788367028
              ],
              [
                -73.78499864189892,
                40.62041062666032
              ],
              [
                -73.78493153947468,
                40.620468920663214
              ],
              [
                -73.7848576953853,
                40.6205222730728
              ],
              [
                -73.78477773362741,
                40.62057023304006
              ],
              [
                -73.78469232989883,
                40.62061239528354
              ],
              [
                -73.78459705327867,
                40.6206446793915
              ],
              [
                -73.78450583289406,
                40.62068316956704
              ],
              [
                -73.78441936332376,
                40.62072757273768
              ],
              [
                -73.7843383029774,
                40.620777550807404
              ],
              [
                -73.78426326908115,
                40.62083272323078
              ],
              [
                -73.78419483298926,
                40.62089266995511
              ],
              [
                -73.78949653224412,
                40.622746646107935
              ],
              [
                -73.78967870292792,
                40.622614822524326
              ],
              [
                -73.78981035453673,
                40.62257211483744
              ],
              [
                -73.78988553708297,
                40.6225627086335
              ],
              [
                -73.79005044260067,
                40.622563009909435
              ],
              [
                -73.79007341842517,
                40.62256305186657
              ],
              [
                -73.79017983899541,
                40.6225775625242
              ],
              [
                -73.79030507811355,
                40.622582563102306
              ],
              [
                -73.79048062923107,
                40.62252084743856
              ],
              [
                -73.79059984430631,
                40.62244948577123
              ],
              [
                -73.79075818793622,
                40.62232836832426
              ],
              [
                -73.79142802632143,
                40.622277658347954
              ],
              [
                -73.79162058913477,
                40.62231334348309
              ]
            ]
          ],
          [
            [
              [
                -73.76670827781236,
                40.614910866185554
              ],
              [
                -73.7667193110134,
                40.61500363286469
              ],
              [
                -73.76745926975256,
                40.620511322031255
              ],
              [
                -73.76873454755717,
                40.62090086822024
              ],
              [
                -73.77397692018825,
                40.61600357567147
              ],
              [
                -73.76825288003378,
                40.61487772516933
              ],
              [
                -73.76670827781236,
                40.614910866185554
              ]
            ]
          ],
          [
            [
              [
                -73.84304421296773,
                40.617737696390435
              ],
              [
                -73.84614804513843,
                40.61772842036632
              ],
              [
                -73.84639522711922,
                40.61603681480176
              ],
              [
                -73.84731105470064,
                40.61343846192685
              ],
              [
                -73.84816769367677,
                40.61253422829745
              ],
              [
                -73.84779013405834,
                40.6092353852628
              ],
              [
                -73.85124092910449,
                40.60771961341498
              ],
              [
                -73.85179402215192,
                40.60689716858368
              ],
              [
                -73.8471480968766,
                40.603614242191995
              ],
              [
                -73.8407944240835,
                40.60564326638265
              ],
              [
                -73.83825667290532,
                40.61384721621642
              ],
              [
                -73.8409994253963,
                40.613029126216134
              ],
              [
                -73.84454193473682,
                40.614867921675135
              ],
              [
                -73.84400850201834,
                40.61704136615355
              ],
              [
                -73.84283573528793,
                40.6166094361636
              ],
              [
                -73.84304421296773,
                40.617737696390435
              ]
            ]
          ],
          [
            [
              [
                -73.85523067631884,
                40.616131453358
              ],
              [
                -73.85858056782772,
                40.61691708722186
              ],
              [
                -73.85648360749232,
                40.61319069486381
              ],
              [
                -73.85802093146347,
                40.61264381691059
              ],
              [
                -73.85924855452187,
                40.61125483087425
              ],
              [
                -73.85901755318278,
                40.60947575392508
              ],
              [
                -73.85704052727723,
                40.608299488875986
              ],
              [
                -73.85708638173246,
                40.60646824293355
              ],
              [
                -73.8551559228834,
                40.606120513001486
              ],
              [
                -73.85010857555366,
                40.61021219334599
              ],
              [
                -73.84973986306498,
                40.61515605814331
              ],
              [
                -73.85263195046835,
                40.61509408190896
              ],
              [
                -73.85376845962072,
                40.61408759651678
              ],
              [
                -73.85236704384849,
                40.61315598907671
              ],
              [
                -73.85530325465119,
                40.61314541357281
              ],
              [
                -73.85523067631884,
                40.616131453358
              ]
            ]
          ],
          [
            [
              [
                -73.814090115668,
                40.61446692009976
              ],
              [
                -73.81408062432926,
                40.6145584092363
              ],
              [
                -73.81420926754464,
                40.614703443269974
              ],
              [
                -73.81444032345223,
                40.61474903867064
              ],
              [
                -73.81458844285291,
                40.61462961822811
              ],
              [
                -73.81471812627856,
                40.61449608855193
              ],
              [
                -73.81495837543912,
                40.61446128234239
              ],
              [
                -73.8150691571859,
                40.61448257634697
              ],
              [
                -73.81523555167247,
                40.61443357256184
              ],
              [
                -73.81538349309263,
                40.614377499655504
              ],
              [
                -73.81554994675568,
                40.61430737811123
              ],
              [
                -73.81569763477397,
                40.61434280988536
              ],
              [
                -73.81582696674197,
                40.614335978434866
              ],
              [
                -73.81598412428653,
                40.61428695826425
              ],
              [
                -73.8161412617739,
                40.61424497658888
              ],
              [
                -73.81623366684653,
                40.61423104619864
              ],
              [
                -73.81643150389779,
                40.614282334964926
              ],
              [
                -73.81615626666235,
                40.61294211575494
              ],
              [
                -73.81598763622685,
                40.613012934891216
              ],
              [
                -73.81588601679647,
                40.61301981091097
              ],
              [
                -73.81578420222384,
                40.61309707529883
              ],
              [
                -73.81571906149262,
                40.61327294163629
              ],
              [
                -73.81560806899891,
                40.613329073751565
              ],
              [
                -73.81546948317194,
                40.61334292900841
              ],
              [
                -73.81532195326875,
                40.6132511869091
              ],
              [
                -73.81527590714306,
                40.61320184104877
              ],
              [
                -73.81528551351116,
                40.61306811885985
              ],
              [
                -73.81523956494507,
                40.61298357925724
              ],
              [
                -73.81503638494628,
                40.61297621374942
              ],
              [
                -73.81487213107738,
                40.61311332049704
              ],
              [
                -73.81485122699522,
                40.61313077018466
              ],
              [
                -73.81479563255313,
                40.61319402998131
              ],
              [
                -73.81487519159298,
                40.61336984895393
              ],
              [
                -73.81499776068877,
                40.61358149086308
              ],
              [
                -73.815191435543,
                40.61368738556275
              ],
              [
                -73.81536653934614,
                40.6138284438187
              ],
              [
                -73.81535703015994,
                40.613926971274886
              ],
              [
                -73.81549526637583,
                40.61403981513316
              ],
              [
                -73.81535634872849,
                40.61417332968294
              ],
              [
                -73.81504193462983,
                40.61430656181428
              ],
              [
                -73.814857222891,
                40.614299226214776
              ],
              [
                -73.81486681073602,
                40.61417254228434
              ],
              [
                -73.81486725972125,
                40.61401064975279
              ],
              [
                -73.8146826267648,
                40.61397515785498
              ],
              [
                -73.81441441488526,
                40.614101424008155
              ],
              [
                -73.81416449897701,
                40.61429106964076
              ],
              [
                -73.81413268075418,
                40.61436629043909
              ],
              [
                -73.814090115668,
                40.61446692009976
              ]
            ]
          ],
          [
            [
              [
                -73.81496830662113,
                40.61042065495561
              ],
              [
                -73.81490459780065,
                40.61048445009514
              ],
              [
                -73.81483444241965,
                40.61054418800062
              ],
              [
                -73.81475828360638,
                40.61059949133035
              ],
              [
                -73.81467660241397,
                40.61065001075347
              ],
              [
                -73.81458991478212,
                40.610695427156486
              ],
              [
                -73.81449876827786,
                40.6107354536593
              ],
              [
                -73.81450261566708,
                40.61105847350542
              ],
              [
                -73.814613646295,
                40.61093407380672
              ],
              [
                -73.81484699639005,
                40.61081905690232
              ],
              [
                -73.8150387432527,
                40.61072453111434
              ],
              [
                -73.81526445493755,
                40.61049963818177
              ],
              [
                -73.8154036618984,
                40.61038480201548
              ],
              [
                -73.81559858789666,
                40.610672516164726
              ],
              [
                -73.81520720849484,
                40.60981673379668
              ],
              [
                -73.81520567535078,
                40.60989689473105
              ],
              [
                -73.81519579458158,
                40.60997670994407
              ],
              [
                -73.81517762806351,
                40.61005567508305
              ],
              [
                -73.8151512905161,
                40.61013329136652
              ],
              [
                -73.8151169482724,
                40.61020906853107
              ],
              [
                -73.81507481822894,
                40.610282527928845
              ],
              [
                -73.8150251664767,
                40.61035320555015
              ],
              [
                -73.81496830662113,
                40.61042065495561
              ]
            ]
          ],
          [
            [
              [
                -73.86966442958492,
                40.60654012341292
              ],
              [
                -73.87076008541273,
                40.60870540090799
              ],
              [
                -73.8710387011171,
                40.60972358252655
              ],
              [
                -73.87180082867997,
                40.61028041358032
              ],
              [
                -73.87300122724774,
                40.61055545845987
              ],
              [
                -73.87268959311653,
                40.60917799830844
              ],
              [
                -73.87245579811875,
                40.60818553034854
              ],
              [
                -73.87105656646135,
                40.60628508849425
              ],
              [
                -73.87109330768071,
                40.604694173994694
              ],
              [
                -73.86966442958492,
                40.60654012341292
              ]
            ]
          ],
          [
            [
              [
                -73.79420172624032,
                40.60773067561337
              ],
              [
                -73.79529977914471,
                40.608229878318475
              ],
              [
                -73.79559832739619,
                40.60872535630223
              ],
              [
                -73.79609453981675,
                40.60882648394272
              ],
              [
                -73.79630654215427,
                40.60933803253818
              ],
              [
                -73.79878198680099,
                40.61033327568911
              ],
              [
                -73.80159206744115,
                40.61023096250127
              ],
              [
                -73.80419695590167,
                40.608744199035584
              ],
              [
                -73.80520676583323,
                40.60673953810017
              ],
              [
                -73.80428530678597,
                40.60451927953058
              ],
              [
                -73.8018028511679,
                40.604211431452256
              ],
              [
                -73.8012001966788,
                40.60391220065208
              ],
              [
                -73.79696327795033,
                40.604117848767785
              ],
              [
                -73.79424799033224,
                40.60590038003164
              ],
              [
                -73.79509002319158,
                40.60727106010561
              ],
              [
                -73.79399754952593,
                40.60608015346263
              ],
              [
                -73.79352152715475,
                40.60632759139594
              ],
              [
                -73.79420172624032,
                40.60773067561337
              ]
            ]
          ],
          [
            [
              [
                -73.83244207358175,
                40.605929449536745
              ],
              [
                -73.83045815392721,
                40.606669201403214
              ],
              [
                -73.83032947905777,
                40.60848787991651
              ],
              [
                -73.83067824786619,
                40.60878967826978
              ],
              [
                -73.83140871257932,
                40.6094217558132
              ],
              [
                -73.83252192275347,
                40.61038499360383
              ],
              [
                -73.83380311515586,
                40.60955059185807
              ],
              [
                -73.8337327597319,
                40.609286033073396
              ],
              [
                -73.83428342685944,
                40.609185681853944
              ],
              [
                -73.83610156628845,
                40.607362793823306
              ],
              [
                -73.83587601623029,
                40.605636907545566
              ],
              [
                -73.83454144109655,
                40.60547738376589
              ],
              [
                -73.83244207358175,
                40.605929449536745
              ]
            ]
          ],
          [
            [
              [
                -73.82607472604538,
                40.60843779954612
              ],
              [
                -73.82688326414805,
                40.60959667806941
              ],
              [
                -73.82805077260065,
                40.610046296865285
              ],
              [
                -73.82771104235977,
                40.60839866813071
              ],
              [
                -73.82661051008785,
                40.60819620584166
              ],
              [
                -73.82607472604538,
                40.60843779954612
              ]
            ]
          ],
          [
            [
              [
                -73.86702399475149,
                40.60806817876943
              ],
              [
                -73.86777501561137,
                40.6085394887783
              ],
              [
                -73.86868468826752,
                40.60824115818392
              ],
              [
                -73.86874317559169,
                40.60703517927931
              ],
              [
                -73.86702399475149,
                40.60806817876943
              ]
            ]
          ],
          [
            [
              [
                -73.82718282107048,
                40.60791977809118
              ],
              [
                -73.82815085191196,
                40.60795028983292
              ],
              [
                -73.82866060090204,
                40.606256158374734
              ],
              [
                -73.82861671457653,
                40.60583848413761
              ],
              [
                -73.8269052458468,
                40.606423897977294
              ],
              [
                -73.82649618795769,
                40.6062244637177
              ],
              [
                -73.82590092720766,
                40.60646593064944
              ],
              [
                -73.82598212776448,
                40.60715799759937
              ],
              [
                -73.82639117752838,
                40.60735743365122
              ],
              [
                -73.82617361009859,
                40.60755411178232
              ],
              [
                -73.82629763015808,
                40.60781707222973
              ],
              [
                -73.82679537497144,
                40.607720440593134
              ],
              [
                -73.82718282107048,
                40.60791977809118
              ]
            ]
          ],
          [
            [
              [
                -73.81569926225602,
                40.6064570673649
              ],
              [
                -73.81571005865177,
                40.60645862779001
              ],
              [
                -73.81574174081904,
                40.606465245117725
              ],
              [
                -73.81576751589343,
                40.60637159210937
              ],
              [
                -73.81572726392088,
                40.6063542622779
              ],
              [
                -73.81570815854799,
                40.606439294195916
              ],
              [
                -73.81552536723665,
                40.60640738028194
              ],
              [
                -73.81558381764519,
                40.60617290276205
              ],
              [
                -73.81552816722274,
                40.606163069945154
              ],
              [
                -73.81546066483351,
                40.606428137007434
              ],
              [
                -73.8155168973555,
                40.60643588521717
              ],
              [
                -73.81552126462859,
                40.60641964237777
              ],
              [
                -73.81569926225602,
                40.6064570673649
              ]
            ]
          ],
          [
            [
              [
                -73.81339665223344,
                40.60436407224534
              ],
              [
                -73.8142869369858,
                40.60445566622167
              ],
              [
                -73.81501471568424,
                40.602056291563535
              ],
              [
                -73.81444738136224,
                40.60054316885366
              ],
              [
                -73.81329372961244,
                40.59981659270371
              ],
              [
                -73.81305369627488,
                40.59950371201523
              ],
              [
                -73.81297362359823,
                40.59945515849489
              ],
              [
                -73.81272920416659,
                40.599247262073256
              ],
              [
                -73.81261044089611,
                40.59912775679625
              ],
              [
                -73.81253246018585,
                40.59902647427727
              ],
              [
                -73.81239671422856,
                40.59889656680437
              ],
              [
                -73.81228818953024,
                40.59876670363496
              ],
              [
                -73.8122678748163,
                40.59873035775287
              ],
              [
                -73.81224417176405,
                40.59868881978417
              ],
              [
                -73.81222386439053,
                40.59864988071624
              ],
              [
                -73.81221034899123,
                40.59861614029552
              ],
              [
                -73.812224046748,
                40.598585037698946
              ],
              [
                -73.81222073287758,
                40.59855390847778
              ],
              [
                -73.81205766969288,
                40.59845767362346
              ],
              [
                -73.8119387483076,
                40.59839523079278
              ],
              [
                -73.81178922582542,
                40.59832495396749
              ],
              [
                -73.81172811474971,
                40.59827557421149
              ],
              [
                -73.81157550345522,
                40.59814119925956
              ],
              [
                -73.81150861997385,
                40.59821618352717
              ],
              [
                -73.81153417375009,
                40.598270068095545
              ],
              [
                -73.81152379592731,
                40.598329707176575
              ],
              [
                -73.81148965192016,
                40.598371150421166
              ],
              [
                -73.81141798216156,
                40.59844625092304
              ],
              [
                -73.81138719612315,
                40.59850326213732
              ],
              [
                -73.81131560541287,
                40.59854983208716
              ],
              [
                -73.81122022555856,
                40.59858598697425
              ],
              [
                -73.81117974133485,
                40.59858489283772
              ],
              [
                -73.8111181504355,
                40.5985832253909
              ],
              [
                -73.81104335601387,
                40.59855975903599
              ],
              [
                -73.8109616972456,
                40.59855703088099
              ],
              [
                -73.81090036686123,
                40.59858546109986
              ],
              [
                -73.81085937905794,
                40.59863986152541
              ],
              [
                -73.81078441560808,
                40.598676050823755
              ],
              [
                -73.8106856684498,
                40.59869923159593
              ],
              [
                -73.81058684830285,
                40.59874834959015
              ],
              [
                -73.8104880810805,
                40.59877931173958
              ],
              [
                -73.81040964716155,
                40.59883883796755
              ],
              [
                -73.8104163272087,
                40.598882942597136
              ],
              [
                -73.81038898814394,
                40.598924396739946
              ],
              [
                -73.81033106684643,
                40.59895023791682
              ],
              [
                -73.81029011586071,
                40.59899167007234
              ],
              [
                -73.81030695226997,
                40.5990539473201
              ],
              [
                -73.81033056583131,
                40.599126610519775
              ],
              [
                -73.81034740297027,
                40.59918888826433
              ],
              [
                -73.81035740538844,
                40.59926152899451
              ],
              [
                -73.81033001323586,
                40.59932113988606
              ],
              [
                -73.81025503399489,
                40.599362515359445
              ],
              [
                -73.81025479075838,
                40.59944810730723
              ],
              [
                -73.81025462859844,
                40.599505169275474
              ],
              [
                -73.81028510566179,
                40.59955709360473
              ],
              [
                -73.81030198708322,
                40.599603808435084
              ],
              [
                -73.81030183970356,
                40.59965568220466
              ],
              [
                -73.81029493857315,
                40.59968938965007
              ],
              [
                -73.81026778270056,
                40.599666001924916
              ],
              [
                -73.81022695590293,
                40.59966334015218
              ],
              [
                -73.81024048657294,
                40.599691893612174
              ],
              [
                -73.81024380045234,
                40.59972302406112
              ],
              [
                -73.81024025805883,
                40.59977229836463
              ],
              [
                -73.81022311058264,
                40.59981895701237
              ],
              [
                -73.81018896434578,
                40.599860400365955
              ],
              [
                -73.81013110919791,
                40.59986289865573
              ],
              [
                -73.81010053621723,
                40.59984469179265
              ],
              [
                -73.81005295429873,
                40.59982386412303
              ],
              [
                -73.80997465251733,
                40.59983670280389
              ],
              [
                -73.80998813232418,
                40.59988341255727
              ],
              [
                -73.81004927959846,
                40.59991982516422
              ],
              [
                -73.81010697922734,
                40.59997179570272
              ],
              [
                -73.8101443079841,
                40.60000816889966
              ],
              [
                -73.81014417523036,
                40.60005485564212
              ],
              [
                -73.81006930440077,
                40.60005732629201
              ],
              [
                -73.81000132948452,
                40.60002868281523
              ],
              [
                -73.80997059406056,
                40.6000675373802
              ],
              [
                -73.81001471187018,
                40.600109109564016
              ],
              [
                -73.81000088663791,
                40.60018430528835
              ],
              [
                -73.80999055209632,
                40.60022838130372
              ],
              [
                -73.80993943621954,
                40.60025423403637
              ],
              [
                -73.80988145450942,
                40.600300825092816
              ],
              [
                -73.80985064502396,
                40.600365616758594
              ],
              [
                -73.80986053496292,
                40.6004771638921
              ],
              [
                -73.80989100609672,
                40.60053168217047
              ],
              [
                -73.80992817216713,
                40.60062511756396
              ],
              [
                -73.80993821798249,
                40.600682196910604
              ],
              [
                -73.80997877227131,
                40.600780824938454
              ],
              [
                -73.81002618357127,
                40.60086130844198
              ],
              [
                -73.81004293231338,
                40.60095471054518
              ],
              [
                -73.81003591240686,
                40.60102991701339
              ],
              [
                -73.81003914574595,
                40.60108957743949
              ],
              [
                -73.80999459024659,
                40.601201034379464
              ],
              [
                -73.8099433702624,
                40.60126319912934
              ],
              [
                -73.80991258166577,
                40.60132020993271
              ],
              [
                -73.80994982919876,
                40.60138511416558
              ],
              [
                -73.8100244640379,
                40.60146564385276
              ],
              [
                -73.81003453242589,
                40.60151494013756
              ],
              [
                -73.8100342593758,
                40.60161090846039
              ],
              [
                -73.80999658353151,
                40.601696439012215
              ],
              [
                -73.80992146111767,
                40.60178709534227
              ],
              [
                -73.80986333857152,
                40.601895790007845
              ],
              [
                -73.81053636758658,
                40.60249598881469
              ],
              [
                -73.81085365186647,
                40.603223713014344
              ],
              [
                -73.81184130264785,
                40.60313466508579
              ],
              [
                -73.81339665223344,
                40.60436407224534
              ]
            ]
          ],
          [
            [
              [
                -73.86702905185577,
                40.60374338885395
              ],
              [
                -73.86702607287029,
                40.60417158400821
              ],
              [
                -73.86793505372694,
                40.60396113545295
              ],
              [
                -73.8683080846461,
                40.603139161146146
              ],
              [
                -73.8676819415421,
                40.603070766677334
              ],
              [
                -73.86702905185577,
                40.60374338885395
              ]
            ]
          ],
          [
            [
              [
                -73.85243335807012,
                40.597333397795275
              ],
              [
                -73.85200481909777,
                40.59774843827431
              ],
              [
                -73.85153122893772,
                40.598433936220474
              ],
              [
                -73.85076748001559,
                40.598839443837406
              ],
              [
                -73.85176045305586,
                40.59969751381258
              ],
              [
                -73.8520453763197,
                40.60020662280097
              ],
              [
                -73.85257851635212,
                40.600570688348604
              ],
              [
                -73.85276838155842,
                40.60094885402592
              ],
              [
                -73.85372090340685,
                40.60140066517853
              ],
              [
                -73.85427387360807,
                40.60143043669703
              ],
              [
                -73.85471200729276,
                40.60164902532504
              ],
              [
                -73.85482595782968,
                40.60186719535364
              ],
              [
                -73.85905914327755,
                40.602061431573944
              ],
              [
                -73.86048884213515,
                40.60233934739461
              ],
              [
                -73.86078169166855,
                40.60260840025627
              ],
              [
                -73.86112814224546,
                40.60255033107426
              ],
              [
                -73.86117495608606,
                40.60248664842695
              ],
              [
                -73.86154081260408,
                40.60238035986937
              ],
              [
                -73.86187189847233,
                40.602164325815906
              ],
              [
                -73.86297506260962,
                40.6010182726156
              ],
              [
                -73.86335999147606,
                40.600322202771196
              ],
              [
                -73.86328663134141,
                40.59981004216325
              ],
              [
                -73.86274820792643,
                40.59907120592458
              ],
              [
                -73.86177276370987,
                40.59829917857115
              ],
              [
                -73.86141485166917,
                40.5977536963777
              ],
              [
                -73.86054977985192,
                40.59706302019168
              ],
              [
                -73.86044420534076,
                40.59647190559629
              ],
              [
                -73.85778119968528,
                40.59611781491794
              ],
              [
                -73.85716132350703,
                40.59604369786467
              ],
              [
                -73.85673364394975,
                40.59607576435409
              ],
              [
                -73.85595300562048,
                40.59618887618967
              ],
              [
                -73.8549264858144,
                40.596301664373286
              ],
              [
                -73.85465863090106,
                40.596578388927576
              ],
              [
                -73.85436974704125,
                40.59669211040233
              ],
              [
                -73.853995595994,
                40.59668348634672
              ],
              [
                -73.85365316421074,
                40.59683787744519
              ],
              [
                -73.85286117431974,
                40.597244308232646
              ],
              [
                -73.85269029351811,
                40.597170750463306
              ],
              [
                -73.85243335807012,
                40.597333397795275
              ]
            ]
          ],
          [
            [
              [
                -73.87019491489939,
                40.598996405901886
              ],
              [
                -73.87026280564918,
                40.60168122225861
              ],
              [
                -73.87049411914771,
                40.602604446204595
              ],
              [
                -73.87108141811476,
                40.59890114747009
              ],
              [
                -73.87019491489939,
                40.598996405901886
              ]
            ]
          ],
          [
            [
              [
                -73.8231174097477,
                40.60007949252535
              ],
              [
                -73.82318015964296,
                40.60008384902813
              ],
              [
                -73.82318408196348,
                40.60006321191324
              ],
              [
                -73.82317376718535,
                40.60006286885789
              ],
              [
                -73.82317337306215,
                40.60004910637509
              ],
              [
                -73.82316692767215,
                40.60004811439995
              ],
              [
                -73.82317356168161,
                40.599978005155776
              ],
              [
                -73.82315293179705,
                40.599976662824794
              ],
              [
                -73.82314760323396,
                40.600040875702874
              ],
              [
                -73.82313384374159,
                40.600042165446055
              ],
              [
                -73.82313294399587,
                40.600057236353436
              ],
              [
                -73.82311789977163,
                40.600056884841536
              ],
              [
                -73.8231174097477,
                40.60007949252535
              ]
            ]
          ],
          [
            [
              [
                -73.82292757811202,
                40.6000614281631
              ],
              [
                -73.82304007849375,
                40.60006807478575
              ],
              [
                -73.82304012567126,
                40.600050274348874
              ],
              [
                -73.82300403883895,
                40.600048600441774
              ],
              [
                -73.82303195183424,
                40.59993051694214
              ],
              [
                -73.8230043654241,
                40.599925619198075
              ],
              [
                -73.822978576463,
                40.60004370722482
              ],
              [
                -73.8229361266004,
                40.60004040516013
              ],
              [
                -73.82292757811202,
                40.6000614281631
              ]
            ]
          ],
          [
            [
              [
                -73.82445629476594,
                40.59911583868228
              ],
              [
                -73.82447690960616,
                40.599120009823345
              ],
              [
                -73.82452624858544,
                40.59912902722324
              ],
              [
                -73.82453084258555,
                40.59911949829181
              ],
              [
                -73.82448805568868,
                40.59911116118602
              ],
              [
                -73.82450339642126,
                40.59907154194362
              ],
              [
                -73.82450563123234,
                40.599065769777305
              ],
              [
                -73.82454404027547,
                40.59896657959944
              ],
              [
                -73.82454580594191,
                40.598962020467106
              ],
              [
                -73.82456317472412,
                40.59891716231478
              ],
              [
                -73.82456502381463,
                40.59891238753149
              ],
              [
                -73.82458200685116,
                40.59886853061357
              ],
              [
                -73.82458387696438,
                40.59886370040986
              ],
              [
                -73.82460174601977,
                40.59881755354045
              ],
              [
                -73.82460303016,
                40.598814235903426
              ],
              [
                -73.82462028423028,
                40.59876967677444
              ],
              [
                -73.82462212690153,
                40.59876491622056
              ],
              [
                -73.824638945229,
                40.598721482891946
              ],
              [
                -73.82464109016925,
                40.59871594445675
              ],
              [
                -73.82465836742638,
                40.598671324376454
              ],
              [
                -73.82466068784454,
                40.598665332706396
              ],
              [
                -73.82468019919393,
                40.59861494162449
              ],
              [
                -73.82468179156804,
                40.598610832209786
              ],
              [
                -73.82468323745927,
                40.59860709767021
              ],
              [
                -73.82470216485918,
                40.59856103800641
              ],
              [
                -73.82470430756192,
                40.59855576191771
              ],
              [
                -73.82471455960446,
                40.59853052097607
              ],
              [
                -73.8247324778652,
                40.598486409140925
              ],
              [
                -73.82474646665528,
                40.59845196999209
              ],
              [
                -73.82474806935706,
                40.59844802594381
              ],
              [
                -73.82476908857363,
                40.59839627730859
              ],
              [
                -73.82477088992478,
                40.598391843874204
              ],
              [
                -73.82474907564067,
                40.598389453972615
              ],
              [
                -73.82474761015331,
                40.598400155364956
              ],
              [
                -73.82474292407973,
                40.59839899042229
              ],
              [
                -73.82472773089512,
                40.598444221491945
              ],
              [
                -73.82471777465584,
                40.59846931294721
              ],
              [
                -73.82470658714921,
                40.598497508688
              ],
              [
                -73.82470460364472,
                40.59850250658537
              ],
              [
                -73.82469739000635,
                40.598520685934545
              ],
              [
                -73.82468443801113,
                40.598553328229684
              ],
              [
                -73.8246632500266,
                40.598601758167135
              ],
              [
                -73.8246517839916,
                40.59862995900573
              ],
              [
                -73.82463881301405,
                40.59866186229396
              ],
              [
                -73.82463684641318,
                40.59866669670713
              ],
              [
                -73.82461901353119,
                40.59871056004336
              ],
              [
                -73.824617199115,
                40.59871502361059
              ],
              [
                -73.82461363488147,
                40.598723790015384
              ],
              [
                -73.82453702420634,
                40.59891221796511
              ],
              [
                -73.82452271276402,
                40.59895126399057
              ],
              [
                -73.82434640845658,
                40.59893433752881
              ],
              [
                -73.82427026010049,
                40.59892593292703
              ],
              [
                -73.82418471900705,
                40.59891282190699
              ],
              [
                -73.82410071394857,
                40.59889732688411
              ],
              [
                -73.82405944857535,
                40.59888942233036
              ],
              [
                -73.82395871079126,
                40.598876896838526
              ],
              [
                -73.82395853199995,
                40.59888785399993
              ],
              [
                -73.82406984190722,
                40.598922481127985
              ],
              [
                -73.82407791355021,
                40.59890920383302
              ],
              [
                -73.82412749012028,
                40.59891622247149
              ],
              [
                -73.82417340595445,
                40.59892573150956
              ],
              [
                -73.82422585197662,
                40.59893354607242
              ],
              [
                -73.82433824076554,
                40.59894743204931
              ],
              [
                -73.82435472080273,
                40.59894946809496
              ],
              [
                -73.82436255896116,
                40.598950436332714
              ],
              [
                -73.8245173365546,
                40.59896580873304
              ],
              [
                -73.82450212274327,
                40.59900866216074
              ],
              [
                -73.82446713343744,
                40.5991074648586
              ],
              [
                -73.82439431638863,
                40.599087873285136
              ],
              [
                -73.82439004315057,
                40.59909811302643
              ],
              [
                -73.82445629476594,
                40.59911583868228
              ]
            ]
          ],
          [
            [
              [
                -73.86952707548932,
                40.598713750354406
              ],
              [
                -73.87020899836229,
                40.59695423278937
              ],
              [
                -73.86967070711478,
                40.59668856362212
              ],
              [
                -73.86952707548932,
                40.598713750354406
              ]
            ]
          ],
          [
            [
              [
                -73.83668274106707,
                40.594946697015814
              ],
              [
                -73.83660044560382,
                40.59499476553554
              ],
              [
                -73.83662377269687,
                40.59506285287462
              ],
              [
                -73.83666574166003,
                40.59509335783977
              ],
              [
                -73.83671758013152,
                40.59513103920253
              ],
              [
                -73.83685364242194,
                40.59521361223885
              ],
              [
                -73.83695207059688,
                40.59531403945314
              ],
              [
                -73.83701776530886,
                40.59534995062072
              ],
              [
                -73.8370786293353,
                40.59543958010495
              ],
              [
                -73.8371911115814,
                40.59555793677658
              ],
              [
                -73.83734112299042,
                40.59570141859261
              ],
              [
                -73.83743435500348,
                40.59580044301385
              ],
              [
                -73.83759018910307,
                40.59587256952913
              ],
              [
                -73.83772796402187,
                40.59591148290798
              ],
              [
                -73.83788751406561,
                40.59594766126472
              ],
              [
                -73.83804339683329,
                40.59600042802676
              ],
              [
                -73.8381920292834,
                40.596050418288996
              ],
              [
                -73.83834418982916,
                40.5961418976427
              ],
              [
                -73.83847829729166,
                40.59619739892337
              ],
              [
                -73.83871038533843,
                40.596244740207155
              ],
              [
                -73.83892079765629,
                40.596253332232386
              ],
              [
                -73.83902964248091,
                40.596253484748665
              ],
              [
                -73.83918213035949,
                40.59620944818108
              ],
              [
                -73.8393926765489,
                40.59616272699144
              ],
              [
                -73.83951254398808,
                40.59610481650751
              ],
              [
                -73.83970857749765,
                40.596058074973236
              ],
              [
                -73.83988646171511,
                40.59601407289271
              ],
              [
                -73.84006799452713,
                40.595961779722536
              ],
              [
                -73.84031973891214,
                40.595870355647605
              ],
              [
                -73.84043404351122,
                40.59579931254817
              ],
              [
                -73.84059825592088,
                40.59573020401426
              ],
              [
                -73.84078351504536,
                40.59563642989195
              ],
              [
                -73.84091435785372,
                40.59553981457471
              ],
              [
                -73.84096797936242,
                40.595467455578024
              ],
              [
                -73.84104539054934,
                40.595362996136465
              ],
              [
                -73.84114504755935,
                40.59535740225562
              ],
              [
                -73.8412377057435,
                40.59535219934971
              ],
              [
                -73.84131049188757,
                40.59525457172557
              ],
              [
                -73.84131074866141,
                40.595150410619965
              ],
              [
                -73.84152874189412,
                40.59502072749177
              ],
              [
                -73.84164143508538,
                40.594926851890634
              ],
              [
                -73.84179387366368,
                40.59490217082855
              ],
              [
                -73.84187111474984,
                40.594906117756175
              ],
              [
                -73.84196074544143,
                40.594910697509945
              ],
              [
                -73.8421060387757,
                40.594838991065316
              ],
              [
                -73.84216429081826,
                40.59475333770384
              ],
              [
                -73.84218630991286,
                40.59471020698653
              ],
              [
                -73.84220803023986,
                40.594667662710265
              ],
              [
                -73.84231495048506,
                40.59463024852525
              ],
              [
                -73.8424604916988,
                40.59471583843024
              ],
              [
                -73.84255616699606,
                40.59473451506944
              ],
              [
                -73.84264325365281,
                40.59472910303238
              ],
              [
                -73.84271954116224,
                40.59468772364997
              ],
              [
                -73.84283218742793,
                40.594613207027884
              ],
              [
                -73.84283967082496,
                40.594516419558296
              ],
              [
                -73.84291602237035,
                40.594447384028406
              ],
              [
                -73.843046580238,
                40.59446968720599
              ],
              [
                -73.84313364052116,
                40.59447533740426
              ],
              [
                -73.84324615524353,
                40.59445613123201
              ],
              [
                -73.84331153839406,
                40.594423032977005
              ],
              [
                -73.84335158303408,
                40.59436500978682
              ],
              [
                -73.84336623167985,
                40.59430695129887
              ],
              [
                -73.8434424400089,
                40.59429875875416
              ],
              [
                -73.8435260073294,
                40.59424632611092
              ],
              [
                -73.84358788589665,
                40.59416067579146
              ],
              [
                -73.84361712370776,
                40.59406945037142
              ],
              [
                -73.84361726620142,
                40.59400860653123
              ],
              [
                -73.843606530162,
                40.59394498281135
              ],
              [
                -73.84363933259718,
                40.59388141817311
              ],
              [
                -73.84371192494658,
                40.59386768971073
              ],
              [
                -73.84373384895089,
                40.59380134416975
              ],
              [
                -73.84374124012379,
                40.59374327576204
              ],
              [
                -73.84376315736597,
                40.59367969645284
              ],
              [
                -73.84375969765586,
                40.59360778607443
              ],
              [
                -73.84378161417668,
                40.593544206760015
              ],
              [
                -73.84382165075462,
                40.59348894846581
              ],
              [
                -73.84385894083933,
                40.59342531196059
              ],
              [
                -73.84380073754829,
                40.59345030499145
              ],
              [
                -73.84372313493482,
                40.59348243500604
              ],
              [
                -73.8436504238803,
                40.59348603112927
              ],
              [
                -73.84355660708614,
                40.59348894427195
              ],
              [
                -73.84345911349182,
                40.59349122074316
              ],
              [
                -73.84340242979927,
                40.59349274375279
              ],
              [
                -73.84330729609289,
                40.59348724139782
              ],
              [
                -73.84326567535612,
                40.593484011627936
              ],
              [
                -73.84320943161804,
                40.593474501146204
              ],
              [
                -73.84314526308786,
                40.59345747148239
              ],
              [
                -73.8430878848422,
                40.59345426838658
              ],
              [
                -73.84304660524084,
                40.593451964100964
              ],
              [
                -73.84300206150367,
                40.59343765386186
              ],
              [
                -73.84293744603643,
                40.593416892756395
              ],
              [
                -73.8428867995623,
                40.59340791061614
              ],
              [
                -73.84282990630474,
                40.59338495431444
              ],
              [
                -73.84279664017407,
                40.59335222916295
              ],
              [
                -73.84272271259046,
                40.59331989247711
              ],
              [
                -73.84269009181736,
                40.59330366614928
              ],
              [
                -73.84265231583105,
                40.5932848745776
              ],
              [
                -73.84257613724341,
                40.593217417679995
              ],
              [
                -73.84255328808544,
                40.59318713590553
              ],
              [
                -73.84245517820459,
                40.59319864272924
              ],
              [
                -73.8424024335184,
                40.593150217363934
              ],
              [
                -73.84230728725517,
                40.59315008683454
              ],
              [
                -73.84220507443722,
                40.593158004692825
              ],
              [
                -73.84216295246586,
                40.593088102614125
              ],
              [
                -73.84213139591954,
                40.5930209015384
              ],
              [
                -73.84206458684868,
                40.59295902534946
              ],
              [
                -73.84200478923243,
                40.592913275604324
              ],
              [
                -73.84193426610223,
                40.592931983304624
              ],
              [
                -73.84190980778224,
                40.59284330124291
              ],
              [
                -73.84187475901565,
                40.592762663945344
              ],
              [
                -73.84184022507651,
                40.59271336809901
              ],
              [
                -73.84168567500943,
                40.5926380914938
              ],
              [
                -73.84142831850568,
                40.59255327679586
              ],
              [
                -73.84143751160617,
                40.59249874471394
              ],
              [
                -73.84116650852377,
                40.592446064371934
              ],
              [
                -73.84131169366367,
                40.5924422186197
              ],
              [
                -73.84134359949014,
                40.59236167359896
              ],
              [
                -73.84141426957216,
                40.59228118257598
              ],
              [
                -73.8414637761373,
                40.5922087199808
              ],
              [
                -73.84135823835017,
                40.592133357919636
              ],
              [
                -73.84122784858577,
                40.59213586333136
              ],
              [
                -73.84116420777472,
                40.59222442380351
              ],
              [
                -73.84111823141744,
                40.59229420397727
              ],
              [
                -73.84098756626545,
                40.59241222051886
              ],
              [
                -73.8409450429299,
                40.59251155543276
              ],
              [
                -73.84093419484857,
                40.59262705102479
              ],
              [
                -73.84094103158732,
                40.59271570890763
              ],
              [
                -73.84093026245439,
                40.59279896857427
              ],
              [
                -73.84081016081028,
                40.59291968620369
              ],
              [
                -73.84059858452798,
                40.59297849129277
              ],
              [
                -73.84039433124552,
                40.59292179539158
              ],
              [
                -73.84023583948621,
                40.59288665361502
              ],
              [
                -73.84006318617527,
                40.5928783540975
              ],
              [
                -73.8398907019092,
                40.59280021149386
              ],
              [
                -73.83977816861827,
                40.59270334805111
              ],
              [
                -73.83961625566648,
                40.59262521910227
              ],
              [
                -73.83948958143614,
                40.59254713982214
              ],
              [
                -73.83943345735968,
                40.592439609473615
              ],
              [
                -73.83946895572447,
                40.592332205957945
              ],
              [
                -73.83960308258222,
                40.592241060505806
              ],
              [
                -73.83977954149731,
                40.59213116756471
              ],
              [
                -73.83992068316992,
                40.59205346069026
              ],
              [
                -73.83999494116478,
                40.59194611217134
              ],
              [
                -73.83996340087272,
                40.59187353901191
              ],
              [
                -73.83984000695787,
                40.591897543281654
              ],
              [
                -73.8397304844814,
                40.59201558736428
              ],
              [
                -73.83962809824376,
                40.59209603359914
              ],
              [
                -73.8394975403891,
                40.5921683822255
              ],
              [
                -73.83946303818477,
                40.59225656848909
              ],
              [
                -73.83866743647685,
                40.59153325175093
              ],
              [
                -73.8386927270338,
                40.591427450960076
              ],
              [
                -73.83870659463203,
                40.59136944304526
              ],
              [
                -73.83870346633576,
                40.59120557380161
              ],
              [
                -73.83866857222813,
                40.59106315132317
              ],
              [
                -73.83865480129,
                40.59092881716831
              ],
              [
                -73.83859154196814,
                40.59085888457954
              ],
              [
                -73.83846841001579,
                40.59077543528392
              ],
              [
                -73.83829578946876,
                40.59073788784503
              ],
              [
                -73.83825709155998,
                40.59072947047191
              ],
              [
                -73.83815153368617,
                40.59066485076517
              ],
              [
                -73.83809538729548,
                40.59056806455161
              ],
              [
                -73.8381061416056,
                40.59049286328023
              ],
              [
                -73.8378035391423,
                40.590309768152174
              ],
              [
                -73.83766267949973,
                40.59027196102248
              ],
              [
                -73.83755360541679,
                40.590204650294964
              ],
              [
                -73.83744455749473,
                40.59012659247737
              ],
              [
                -73.8374128965695,
                40.59010505698781
              ],
              [
                -73.83735312278405,
                40.59005124666217
              ],
              [
                -73.8373179828233,
                40.59001090203083
              ],
              [
                -73.83731456482138,
                40.589967916303245
              ],
              [
                -73.83725123513788,
                40.58992753240636
              ],
              [
                -73.83721603048043,
                40.58991405051864
              ],
              [
                -73.83724433877009,
                40.589865737558476
              ],
              [
                -73.83725148391838,
                40.58982545346189
              ],
              [
                -73.83725161485465,
                40.589771727912854
              ],
              [
                -73.83724816918232,
                40.58973948698858
              ],
              [
                -73.83719541135855,
                40.58969911736292
              ],
              [
                -73.83707912070494,
                40.589701639209345
              ],
              [
                -73.83704731566715,
                40.58973920232521
              ],
              [
                -73.83693453583638,
                40.58974710100795
              ],
              [
                -73.83689939038598,
                40.589709442911115
              ],
              [
                -73.83684663235576,
                40.58966907379476
              ],
              [
                -73.83675859162032,
                40.589647458559035
              ],
              [
                -73.83668114048537,
                40.58961779845868
              ],
              [
                -73.83664249161001,
                40.58957207712939
              ],
              [
                -73.83659313295577,
                40.58958275229538
              ],
              [
                -73.8365296600677,
                40.58960146503252
              ],
              [
                -73.83646274185438,
                40.58958793846142
              ],
              [
                -73.83640642113407,
                40.58956368142386
              ],
              [
                -73.83632197535155,
                40.58951252260318
              ],
              [
                -73.83626573378614,
                40.58945602906099
              ],
              [
                -73.83620230045881,
                40.5894586246562
              ],
              [
                -73.83612483726172,
                40.589434338009504
              ],
              [
                -73.83607198232245,
                40.589434262616834
              ],
              [
                -73.83600508350207,
                40.589412676459816
              ],
              [
                -73.83594705454765,
                40.589366927736194
              ],
              [
                -73.83583637795317,
                40.58936512553157
              ],
              [
                -73.83576901366463,
                40.589404281622485
              ],
              [
                -73.8356985057161,
                40.58941761117125
              ],
              [
                -73.83561393634105,
                40.58941749021539
              ],
              [
                -73.8354800532563,
                40.58940923992457
              ],
              [
                -73.83536721419101,
                40.58944131485624
              ],
              [
                -73.83522266937197,
                40.58947065603458
              ],
              [
                -73.83512404459877,
                40.589454396538436
              ],
              [
                -73.83511708989475,
                40.589416778340826
              ],
              [
                -73.8350148680017,
                40.58943006413598
              ],
              [
                -73.83493035837527,
                40.589405765392776
              ],
              [
                -73.83484966329664,
                40.589349049395246
              ],
              [
                -73.83470507858037,
                40.58930873440548
              ],
              [
                -73.83458883487054,
                40.58929244960502
              ],
              [
                -73.83448611494346,
                40.58934312397328
              ],
              [
                -73.83427132532549,
                40.58933962327684
              ],
              [
                -73.83409883714177,
                40.589372331795744
              ],
              [
                -73.8339931251685,
                40.58937217911907
              ],
              [
                -73.8339371220968,
                40.58933417485337
              ],
              [
                -73.83384520036506,
                40.58933267559814
              ],
              [
                -73.8336903586853,
                40.589330150905695
              ],
              [
                -73.83359681259313,
                40.58932862547892
              ],
              [
                -73.83347866645357,
                40.58936874871088
              ],
              [
                -73.83344322834957,
                40.589449286964715
              ],
              [
                -73.83340774259358,
                40.58954862815873
              ],
              [
                -73.83329476213193,
                40.58963711276134
              ],
              [
                -73.83323112660207,
                40.58972029503289
              ],
              [
                -73.833227415183,
                40.58979550608341
              ],
              [
                -73.8333280477052,
                40.589836595828686
              ],
              [
                -73.83339288407699,
                40.589854845039156
              ],
              [
                -73.83353381408156,
                40.58986310863996
              ],
              [
                -73.83363953352385,
                40.58986057507664
              ],
              [
                -73.8337487303853,
                40.58987685064007
              ],
              [
                -73.83382965577461,
                40.58992532126434
              ],
              [
                -73.8339460607168,
                40.589877135977325
              ],
              [
                -73.83400263455297,
                40.589799315785065
              ],
              [
                -73.83405563128245,
                40.58974297942083
              ],
              [
                -73.83418252598175,
                40.58972704458634
              ],
              [
                -73.83430585060808,
                40.58972990865642
              ],
              [
                -73.83436564163769,
                40.58977566225632
              ],
              [
                -73.8344571724885,
                40.58981071637507
              ],
              [
                -73.83454181557454,
                40.58978128874666
              ],
              [
                -73.83466524585045,
                40.58974117219388
              ],
              [
                -73.8348134040974,
                40.589676914192914
              ],
              [
                -73.83491913647808,
                40.589669006797095
              ],
              [
                -73.83495422767699,
                40.58972815561801
              ],
              [
                -73.83488359302017,
                40.589792525728036
              ],
              [
                -73.83485519070757,
                40.58987844619106
              ],
              [
                -73.83483035177443,
                40.58994825424876
              ],
              [
                -73.83478435641427,
                40.59002340455732
              ],
              [
                -73.83463980875666,
                40.59005274667419
              ],
              [
                -73.83450948849541,
                40.59002838168921
              ],
              [
                -73.83439339168011,
                40.58995299799172
              ],
              [
                -73.83423133832333,
                40.58993664680043
              ],
              [
                -73.83411510118847,
                40.58991767502784
              ],
              [
                -73.83400232738633,
                40.58992288484003
              ],
              [
                -73.83387892890823,
                40.58994956987915
              ],
              [
                -73.8337730080737,
                40.5900326913076
              ],
              [
                -73.83367775384623,
                40.590078221033835
              ],
              [
                -73.83358956500702,
                40.59011570156727
              ],
              [
                -73.83354043983051,
                40.59003235463162
              ],
              [
                -73.83342772609485,
                40.59001338777054
              ],
              [
                -73.83328307099791,
                40.59008570891765
              ],
              [
                -73.83315739581462,
                40.590099758314516
              ],
              [
                -73.83298352367508,
                40.59009601825906
              ],
              [
                -73.83284962070249,
                40.59009582355293
              ],
              [
                -73.83274377975108,
                40.59014670910927
              ],
              [
                -73.83262739367129,
                40.59018683386004
              ],
              [
                -73.83253569485043,
                40.59021893579835
              ],
              [
                -73.83246148069871,
                40.59030479057109
              ],
              [
                -73.83245774684436,
                40.59038805973487
              ],
              [
                -73.8324822588798,
                40.59044988105836
              ],
              [
                -73.83249968850069,
                40.59052512290002
              ],
              [
                -73.83249597709224,
                40.59060033392233
              ],
              [
                -73.83247465948892,
                40.59067014660305
              ],
              [
                -73.83247096606243,
                40.59073729947654
              ],
              [
                -73.83248832294223,
                40.590842089578416
              ],
              [
                -73.8325197333496,
                40.590963018852676
              ],
              [
                -73.83253006957867,
                40.591057054370296
              ],
              [
                -73.83260052601841,
                40.591065216882285
              ],
              [
                -73.83260741887503,
                40.59112701082999
              ],
              [
                -73.83260021625141,
                40.59118878591861
              ],
              [
                -73.83256476938875,
                40.59127200937697
              ],
              [
                -73.83256110970056,
                40.59132572977664
              ],
              [
                -73.8326495671695,
                40.591343717459424
              ],
              [
                -73.83268085970118,
                40.59135008157467
              ],
              [
                -73.83277600359992,
                40.591350220075036
              ],
              [
                -73.83281812203546,
                40.59141743844208
              ],
              [
                -73.83283563342253,
                40.591460444804795
              ],
              [
                -73.83287440201715,
                40.591457815195426
              ],
              [
                -73.83292381035615,
                40.591428338680586
              ],
              [
                -73.8329944009465,
                40.59138277323608
              ],
              [
                -73.83305089657865,
                40.591337187773185
              ],
              [
                -73.8331144130332,
                40.59130235849669
              ],
              [
                -73.83320601241849,
                40.59131055016864
              ],
              [
                -73.83328348277699,
                40.59133215278573
              ],
              [
                -73.83335740358869,
                40.59136449555021
              ],
              [
                -73.83343130283038,
                40.59140489758094
              ],
              [
                -73.83349484594545,
                40.59135932211597
              ],
              [
                -73.83360056172245,
                40.591359475252
              ],
              [
                -73.83376258339992,
                40.59138925980156
              ],
              [
                -73.83388588500924,
                40.591402868961204
              ],
              [
                -73.83403034912305,
                40.59140845038403
              ],
              [
                -73.83406576840979,
                40.591335971784574
              ],
              [
                -73.8341504267253,
                40.591301171794086
              ],
              [
                -73.83420674164263,
                40.59132811592587
              ],
              [
                -73.83422777067327,
                40.5913738138227
              ],
              [
                -73.83409020087223,
                40.59143002868278
              ],
              [
                -73.83398778268769,
                40.59152121353043
              ],
              [
                -73.83392421303131,
                40.591577534575556
              ],
              [
                -73.833994523274,
                40.59164479442239
              ],
              [
                -73.83407541783313,
                40.591706695160696
              ],
              [
                -73.83410693316273,
                40.5917873297582
              ],
              [
                -73.83414197115772,
                40.59186796943094
              ],
              [
                -73.83422638358282,
                40.59193256281543
              ],
              [
                -73.83412045489972,
                40.59201837121284
              ],
              [
                -73.83408153119412,
                40.5920827860903
              ],
              [
                -73.83396870763654,
                40.59210680049318
              ],
              [
                -73.83395473218756,
                40.592058427245924
              ],
              [
                -73.83399721888573,
                40.59197789953869
              ],
              [
                -73.83393396278849,
                40.59190796439203
              ],
              [
                -73.83387770851942,
                40.59185684234878
              ],
              [
                -73.83378258395454,
                40.59184864661736
              ],
              [
                -73.83370144153317,
                40.59188613742611
              ],
              [
                -73.83369071615022,
                40.59194790630625
              ],
              [
                -73.83367997692342,
                40.59201504901109
              ],
              [
                -73.83375728840898,
                40.59210112214921
              ],
              [
                -73.8336726955662,
                40.59210905841047
              ],
              [
                -73.83355999167891,
                40.592084719028946
              ],
              [
                -73.83344030034272,
                40.592036192044176
              ],
              [
                -73.83330658794601,
                40.591958095059766
              ],
              [
                -73.83322927027885,
                40.59187470777638
              ],
              [
                -73.83319072245835,
                40.59178869055791
              ],
              [
                -73.83321910088924,
                40.59171351532739
              ],
              [
                -73.83324747991823,
                40.59163833891747
              ],
              [
                -73.83314190531755,
                40.59158177393057
              ],
              [
                -73.83307487165115,
                40.59161391211072
              ],
              [
                -73.83305697727148,
                40.591724024246
              ],
              [
                -73.83304972127199,
                40.59180728930483
              ],
              [
                -73.83309890085683,
                40.591869145318185
              ],
              [
                -73.83306701801895,
                40.59193625658478
              ],
              [
                -73.83298585493124,
                40.5919818067063
              ],
              [
                -73.8328801186626,
                40.59198971114879
              ],
              [
                -73.83281672314727,
                40.591976188085646
              ],
              [
                -73.8328063117296,
                40.59191170135954
              ],
              [
                -73.83276840016067,
                40.591892922751676
              ],
              [
                -73.83274648102432,
                40.59188206475375
              ],
              [
                -73.83267596985205,
                40.59189539412006
              ],
              [
                -73.83260892825395,
                40.59193021801814
              ],
              [
                -73.83262993706295,
                40.591983974349
              ],
              [
                -73.83263966877404,
                40.592042862938094
              ],
              [
                -73.83260558943994,
                40.59208255751103
              ],
              [
                -73.8325749810638,
                40.592118209113956
              ],
              [
                -73.83262065649224,
                40.592172001885004
              ],
              [
                -73.83265939228929,
                40.59218280364819
              ],
              [
                -73.83271223681659,
                40.59218825443818
              ],
              [
                -73.83279328686963,
                40.59218837240357
              ],
              [
                -73.83312434323057,
                40.59226407007009
              ],
              [
                -73.83312766467343,
                40.59234466298055
              ],
              [
                -73.83316620690034,
                40.59243336671119
              ],
              [
                -73.83313425607417,
                40.59252734092463
              ],
              [
                -73.83307051685698,
                40.59265081882316
              ],
              [
                -73.83303504965276,
                40.592742101730245
              ],
              [
                -73.8330381717085,
                40.592903283940714
              ],
              [
                -73.83308377330324,
                40.59298662645063
              ],
              [
                -73.83314367447207,
                40.59298939894405
              ],
              [
                -73.83322124739877,
                40.59297070686739
              ],
              [
                -73.83323193224741,
                40.592925055995806
              ],
              [
                -73.83324963971388,
                40.59289015898435
              ],
              [
                -73.83329545012083,
                40.5928902254518
              ],
              [
                -73.833362250994,
                40.59295210791205
              ],
              [
                -73.833481783144,
                40.59306510580862
              ],
              [
                -73.83349924180611,
                40.59312960217402
              ],
              [
                -73.83352017803828,
                40.5932129077613
              ],
              [
                -73.83361163952554,
                40.593277512302265
              ],
              [
                -73.8337313334338,
                40.59332603809802
              ],
              [
                -73.83385114672676,
                40.593326211396125
              ],
              [
                -73.83387239778443,
                40.59328326224798
              ],
              [
                -73.83401323492436,
                40.59333181881706
              ],
              [
                -73.83410834072814,
                40.59334807417262
              ],
              [
                -73.83422463139402,
                40.593348241995784
              ],
              [
                -73.83431256331623,
                40.59341552588089
              ],
              [
                -73.8343018578103,
                40.593469236163735
              ],
              [
                -73.83433345400043,
                40.59351763526879
              ],
              [
                -73.83430516205212,
                40.593557889352795
              ],
              [
                -73.83431911140642,
                40.59361700835926
              ],
              [
                -73.83433212684878,
                40.59366076774679
              ],
              [
                -73.8348693335679,
                40.59389628051196
              ],
              [
                -73.83496333298632,
                40.59388566970454
              ],
              [
                -73.83511348532718,
                40.59396826505298
              ],
              [
                -73.83516024257754,
                40.5940614577799
              ],
              [
                -73.83514512951908,
                40.59409770037923
              ],
              [
                -73.83513188170217,
                40.59412947010124
              ],
              [
                -73.83505672124198,
                40.594122198847565
              ],
              [
                -73.83511720319443,
                40.59436584359023
              ],
              [
                -73.83527199389948,
                40.594473517514494
              ],
              [
                -73.83541276835358,
                40.59454893562656
              ],
              [
                -73.83548957637792,
                40.59445475446703
              ],
              [
                -73.83559157397217,
                40.5944453211032
              ],
              [
                -73.83565342677645,
                40.59447730803854
              ],
              [
                -73.83576520416723,
                40.5945351125751
              ],
              [
                -73.83581100477463,
                40.59453372437039
              ],
              [
                -73.83587798121873,
                40.594531692254336
              ],
              [
                -73.83599100458636,
                40.59442798314017
              ],
              [
                -73.83624456552002,
                40.59449639775504
              ],
              [
                -73.83647454514829,
                40.59460059573058
              ],
              [
                -73.83670920760002,
                40.59471196329248
              ],
              [
                -73.83677470021847,
                40.59483025309939
              ],
              [
                -73.83678395742554,
                40.59488757440814
              ],
              [
                -73.83668274106707,
                40.594946697015814
              ]
            ]
          ],
          [
            [
              [
                -73.86706149472118,
                40.5820879767934
              ],
              [
                -73.86651827430362,
                40.5823005382856
              ],
              [
                -73.8661228348405,
                40.58263867613857
              ],
              [
                -73.86584278235404,
                40.58285154128587
              ],
              [
                -73.86557925292553,
                40.5830268024549
              ],
              [
                -73.86503589278036,
                40.58330206008566
              ],
              [
                -73.86449245335685,
                40.58361493817877
              ],
              [
                -73.86383442889513,
                40.58365178217413
              ],
              [
                -73.86327471071941,
                40.58387684853184
              ],
              [
                -73.86317563293608,
                40.58406484086035
              ],
              [
                -73.86282948605064,
                40.58441556714237
              ],
              [
                -73.86249965640731,
                40.584829015379185
              ],
              [
                -73.86218624579826,
                40.58525502227703
              ],
              [
                -73.86192220398715,
                40.58566854822555
              ],
              [
                -73.86167447867456,
                40.58614479499366
              ],
              [
                -73.86159140175833,
                40.58654599627411
              ],
              [
                -73.8615082436248,
                40.58698481999755
              ],
              [
                -73.86137584125022,
                40.587373420032854
              ],
              [
                -73.86125299377363,
                40.58798808592125
              ],
              [
                -73.86149401111527,
                40.58854247360196
              ],
              [
                -73.86193216221744,
                40.588898945374616
              ],
              [
                -73.86231374617181,
                40.58951874680793
              ],
              [
                -73.86272348498059,
                40.590074510753404
              ],
              [
                -73.86274156028652,
                40.590366407099964
              ],
              [
                -73.86327273492819,
                40.59090807569255
              ],
              [
                -73.86371118798182,
                40.59112928198305
              ],
              [
                -73.86430826692539,
                40.59141474432639
              ],
              [
                -73.86476222962085,
                40.59149221858283
              ],
              [
                -73.86635052164085,
                40.591892676564676
              ],
              [
                -73.86690268173955,
                40.59147303544147
              ],
              [
                -73.8675927577521,
                40.59100579169067
              ],
              [
                -73.86849671647202,
                40.59008985028954
              ],
              [
                -73.86981356482104,
                40.58946092678817
              ],
              [
                -73.87115922675414,
                40.5868738879673
              ],
              [
                -73.87099791647937,
                40.586061798365336
              ],
              [
                -73.87084743947547,
                40.58542105463095
              ],
              [
                -73.87061497794427,
                40.58491535106038
              ],
              [
                -73.87051388095846,
                40.584082327403195
              ],
              [
                -73.8705059791835,
                40.583341953013736
              ],
              [
                -73.87034778401303,
                40.58306413703524
              ],
              [
                -73.87000313642912,
                40.582636614815954
              ],
              [
                -73.86968631043702,
                40.58230878579189
              ],
              [
                -73.86925758051196,
                40.58190964107345
              ],
              [
                -73.86896292210412,
                40.58169379478867
              ],
              [
                -73.86814728191008,
                40.581988903219305
              ],
              [
                -73.86768677970458,
                40.58196329228613
              ],
              [
                -73.86706149472118,
                40.5820879767934
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000314414156821,
        "objectid": 3,
        "shape_leng": 0.0843411059012,
        "location_id": 3,
        "zone": "Allerton/Pelham Gardens",
        "borough": "Bronx"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.84792614099985,
                40.87134223399991
              ],
              [
                -73.84859676099994,
                40.87167078499988
              ],
              [
                -73.84987645199996,
                40.87210188099989
              ],
              [
                -73.85078983299995,
                40.872396291999905
              ],
              [
                -73.85108463399987,
                40.872492790999964
              ],
              [
                -73.85170987299989,
                40.872697458999944
              ],
              [
                -73.85265751899979,
                40.87299218699998
              ],
              [
                -73.85357628499997,
                40.87327602099987
              ],
              [
                -73.85363638799987,
                40.87330059899996
              ],
              [
                -73.854508051,
                40.87358094199987
              ],
              [
                -73.85551758400004,
                40.873903955999886
              ],
              [
                -73.85577052399987,
                40.87398371299992
              ],
              [
                -73.85651292399993,
                40.87421779299995
              ],
              [
                -73.85655118199992,
                40.87303042899989
              ],
              [
                -73.85661382499993,
                40.87125016699991
              ],
              [
                -73.85754854399994,
                40.87126631799993
              ],
              [
                -73.85848731699983,
                40.87128405999989
              ],
              [
                -73.85942680499996,
                40.87130069699991
              ],
              [
                -73.86036598199989,
                40.87131862199991
              ],
              [
                -73.86137924099984,
                40.871336511999914
              ],
              [
                -73.8614338389999,
                40.86960346799988
              ],
              [
                -73.86149826099988,
                40.86778221599998
              ],
              [
                -73.86150246599986,
                40.8675514329999
              ],
              [
                -73.8615423979999,
                40.86615362299991
              ],
              [
                -73.86156274099987,
                40.86554946399989
              ],
              [
                -73.86055100799985,
                40.86552969499993
              ],
              [
                -73.85961281199982,
                40.86551271799987
              ],
              [
                -73.85890956799982,
                40.8646369259999
              ],
              [
                -73.85871220299993,
                40.86425255299985
              ],
              [
                -73.85844789699988,
                40.86376138999989
              ],
              [
                -73.85806098599983,
                40.86304235999992
              ],
              [
                -73.85780378799993,
                40.86303621699989
              ],
              [
                -73.85771028399981,
                40.862278229999895
              ],
              [
                -73.85770552799988,
                40.862241284999925
              ],
              [
                -73.85769842199993,
                40.862186080999926
              ],
              [
                -73.85769181699992,
                40.8621347729999
              ],
              [
                -73.85767885599986,
                40.86203407799992
              ],
              [
                -73.857670848,
                40.86197187299995
              ],
              [
                -73.85760926699989,
                40.86149344099991
              ],
              [
                -73.85759960699981,
                40.86141838899994
              ],
              [
                -73.85756682699994,
                40.8611637229999
              ],
              [
                -73.85752991799988,
                40.86090316799989
              ],
              [
                -73.85752224299985,
                40.86084898099988
              ],
              [
                -73.85747885699989,
                40.86054270099991
              ],
              [
                -73.85747219599985,
                40.860495676999925
              ],
              [
                -73.85746244199993,
                40.860426811999915
              ],
              [
                -73.85739481999991,
                40.85998410299992
              ],
              [
                -73.85735319699984,
                40.859711605999934
              ],
              [
                -73.8566377469999,
                40.8581191719999
              ],
              [
                -73.85601437899996,
                40.858158179999904
              ],
              [
                -73.85585355899978,
                40.858171764999966
              ],
              [
                -73.85405423899992,
                40.85832373099985
              ],
              [
                -73.85310947900003,
                40.8584097979999
              ],
              [
                -73.85217337899977,
                40.858499155999965
              ],
              [
                -73.8512286699999,
                40.8585758439999
              ],
              [
                -73.85028234799984,
                40.85865726599997
              ],
              [
                -73.84930893499991,
                40.85871750299992
              ],
              [
                -73.84833709099988,
                40.85870184799994
              ],
              [
                -73.84739746999989,
                40.85868254599995
              ],
              [
                -73.84645854099996,
                40.85863637399989
              ],
              [
                -73.84552460599988,
                40.85853848699988
              ],
              [
                -73.84458712899988,
                40.858445809999914
              ],
              [
                -73.8432955319999,
                40.858290375999914
              ],
              [
                -73.84191078799992,
                40.858091531999925
              ],
              [
                -73.84097144699983,
                40.857949078999916
              ],
              [
                -73.84004614299985,
                40.85780626899989
              ],
              [
                -73.83911116599994,
                40.85765790099987
              ],
              [
                -73.838176221,
                40.85750772399996
              ],
              [
                -73.83724089099992,
                40.85735732299986
              ],
              [
                -73.83681918499988,
                40.85727680899989
              ],
              [
                -73.83628873399995,
                40.85747692099994
              ],
              [
                -73.83533372599987,
                40.85783130099991
              ],
              [
                -73.83437708299991,
                40.85819084899987
              ],
              [
                -73.83348699499999,
                40.85852949499987
              ],
              [
                -73.83308051999994,
                40.85867901899998
              ],
              [
                -73.83190625000003,
                40.8590701989999
              ],
              [
                -73.83103340899994,
                40.85934303399997
              ],
              [
                -73.83017269399993,
                40.85963375399996
              ],
              [
                -73.83002075599987,
                40.85968150899989
              ],
              [
                -73.829874887,
                40.85974041999991
              ],
              [
                -73.82973719699993,
                40.859809734999885
              ],
              [
                -73.82960951799994,
                40.85988842099988
              ],
              [
                -73.82945155499975,
                40.86000056899992
              ],
              [
                -73.8288197809999,
                40.860449112999916
              ],
              [
                -73.82847346699994,
                40.86073135299991
              ],
              [
                -73.82834347399992,
                40.860892025999895
              ],
              [
                -73.82841963500002,
                40.86093193999992
              ],
              [
                -73.82911929099997,
                40.861509976999855
              ],
              [
                -73.82924089599999,
                40.86163186799991
              ],
              [
                -73.82950892699994,
                40.86184121299986
              ],
              [
                -73.82972614799992,
                40.86195274499995
              ],
              [
                -73.83012015999996,
                40.862049281999894
              ],
              [
                -73.83089329699999,
                40.86221284899995
              ],
              [
                -73.83151964200005,
                40.86259766899986
              ],
              [
                -73.83162007899985,
                40.8626593749999
              ],
              [
                -73.83578436699997,
                40.86501601099997
              ],
              [
                -73.83618601399989,
                40.86522409899994
              ],
              [
                -73.83678824299999,
                40.86554590599999
              ],
              [
                -73.83697021099982,
                40.86564313599992
              ],
              [
                -73.83784380199984,
                40.86609918799994
              ],
              [
                -73.83875223499997,
                40.86657277699996
              ],
              [
                -73.83922085399998,
                40.866820839999924
              ],
              [
                -73.83954475699979,
                40.86698673799988
              ],
              [
                -73.83974233399985,
                40.8670879309999
              ],
              [
                -73.84064364799987,
                40.867554175999935
              ],
              [
                -73.84122362600002,
                40.867858150999915
              ],
              [
                -73.84185186499998,
                40.86819192599989
              ],
              [
                -73.842406294,
                40.86847474399992
              ],
              [
                -73.84280443499989,
                40.868680752999886
              ],
              [
                -73.84315715499991,
                40.868863265999934
              ],
              [
                -73.84358581499981,
                40.86908763699991
              ],
              [
                -73.84423146999998,
                40.86942034999993
              ],
              [
                -73.84442133700003,
                40.86952229899991
              ],
              [
                -73.84487737499975,
                40.86976050699997
              ],
              [
                -73.845172567,
                40.86991469799994
              ],
              [
                -73.84578147499997,
                40.870227695999894
              ],
              [
                -73.84608957099984,
                40.870389771999946
              ],
              [
                -73.84640766399981,
                40.8705543009999
              ],
              [
                -73.84699273699992,
                40.87085288799987
              ],
              [
                -73.84725144800001,
                40.870987051999926
              ],
              [
                -73.84792614099985,
                40.87134223399991
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000111871946192,
        "objectid": 4,
        "shape_leng": 0.0435665270921,
        "location_id": 4,
        "zone": "Alphabet City",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.97177410965318,
                40.72582128133706
              ],
              [
                -73.97176755410212,
                40.725833303454564
              ],
              [
                -73.97173843379353,
                40.725999667555534
              ],
              [
                -73.97171894361502,
                40.72612199325951
              ],
              [
                -73.97169587451613,
                40.72624182930235
              ],
              [
                -73.97166972701174,
                40.7263923271543
              ],
              [
                -73.97162697285862,
                40.72662845299843
              ],
              [
                -73.97179602099986,
                40.72671367999989
              ],
              [
                -73.97185671300005,
                40.7267586849999
              ],
              [
                -73.97191244199995,
                40.72679100899989
              ],
              [
                -73.97197685099988,
                40.72683035599993
              ],
              [
                -73.97205015299988,
                40.72688094599988
              ],
              [
                -73.97329796799995,
                40.72742800799989
              ],
              [
                -73.97569651100005,
                40.728475052999926
              ],
              [
                -73.97580243799992,
                40.72851847999988
              ],
              [
                -73.9761329,
                40.72863827199994
              ],
              [
                -73.97615204099999,
                40.728645209999854
              ],
              [
                -73.976269971,
                40.7286892589999
              ],
              [
                -73.97690436400002,
                40.72895782399994
              ],
              [
                -73.97739386799988,
                40.72916504799993
              ],
              [
                -73.97802697999985,
                40.729433059999984
              ],
              [
                -73.97805542899995,
                40.72938308599991
              ],
              [
                -73.97853582899992,
                40.728746084999905
              ],
              [
                -73.97899861999981,
                40.72810574699989
              ],
              [
                -73.97946913000003,
                40.727467676999936
              ],
              [
                -73.97990650199998,
                40.72686577299988
              ],
              [
                -73.9803329819999,
                40.72628033599989
              ],
              [
                -73.98076713900005,
                40.725685594999916
              ],
              [
                -73.9812112959998,
                40.72507279999992
              ],
              [
                -73.98163663899999,
                40.72449348099987
              ],
              [
                -73.9820824239999,
                40.72388204399992
              ],
              [
                -73.98252449499985,
                40.72327797699997
              ],
              [
                -73.9829641439999,
                40.72267132499997
              ],
              [
                -73.98344363599992,
                40.722018506999895
              ],
              [
                -73.98377990999991,
                40.72153595399992
              ],
              [
                -73.9838238729998,
                40.72147287199987
              ],
              [
                -73.98300308699991,
                40.721222680999894
              ],
              [
                -73.98216615299982,
                40.72096885499996
              ],
              [
                -73.98130824199984,
                40.7207087859999
              ],
              [
                -73.97966021699982,
                40.72021282999992
              ],
              [
                -73.97875234999982,
                40.71993370599993
              ],
              [
                -73.978031227,
                40.71963834799988
              ],
              [
                -73.97722379199992,
                40.71930977499993
              ],
              [
                -73.97674495699998,
                40.71921523399993
              ],
              [
                -73.97648182399995,
                40.719159627999915
              ],
              [
                -73.97635525199995,
                40.71913288399984
              ],
              [
                -73.97609249599991,
                40.71907870199988
              ],
              [
                -73.97551012699985,
                40.71895861299991
              ],
              [
                -73.97545167499995,
                40.71894655899996
              ],
              [
                -73.97509520199993,
                40.71888074599987
              ],
              [
                -73.97497644799995,
                40.71887065199991
              ],
              [
                -73.97491298899988,
                40.718862442999864
              ],
              [
                -73.97485919999995,
                40.71885547999994
              ],
              [
                -73.97474239999998,
                40.71884079399995
              ],
              [
                -73.97468687499985,
                40.71884170199993
              ],
              [
                -73.97464720799998,
                40.718842349999896
              ],
              [
                -73.97347975574512,
                40.71886142911286
              ],
              [
                -73.97332646360798,
                40.7193611047339
              ],
              [
                -73.9732107544226,
                40.7197848577304
              ],
              [
                -73.97312959090407,
                40.72009119241493
              ],
              [
                -73.9730466728251,
                40.72043622983333
              ],
              [
                -73.97288971396678,
                40.721088297219346
              ],
              [
                -73.97251011180381,
                40.7226968860067
              ],
              [
                -73.97239945748277,
                40.72314881636663
              ],
              [
                -73.97236115744644,
                40.72331206047645
              ],
              [
                -73.97232507731592,
                40.723463499327295
              ],
              [
                -73.97226499868246,
                40.72371445733361
              ],
              [
                -73.97223344676958,
                40.723846779926305
              ],
              [
                -73.9722169237619,
                40.723930054646765
              ],
              [
                -73.97216585996632,
                40.724131963273145
              ],
              [
                -73.97215609001734,
                40.7241701782781
              ],
              [
                -73.97209751290906,
                40.72442341523472
              ],
              [
                -73.97208400057282,
                40.72446790554567
              ],
              [
                -73.97208098151926,
                40.72449756793038
              ],
              [
                -73.97207196960339,
                40.724536350858095
              ],
              [
                -73.97206595867397,
                40.72455915832874
              ],
              [
                -73.97203666966882,
                40.724684070020096
              ],
              [
                -73.97200511841213,
                40.72484148970817
              ],
              [
                -73.97196154778591,
                40.725039978810884
              ],
              [
                -73.97193975456328,
                40.725158038610054
              ],
              [
                -73.97193374501082,
                40.72517629035117
              ],
              [
                -73.97191120732053,
                40.72529037069992
              ],
              [
                -73.97188490951473,
                40.725424405065766
              ],
              [
                -73.97187437695213,
                40.72550311607975
              ],
              [
                -73.97185935830923,
                40.72558296903031
              ],
              [
                -73.97181650587731,
                40.72581496675257
              ],
              [
                -73.97179142961485,
                40.72581422114911
              ],
              [
                -73.97177410965318,
                40.72582128133706
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000497957489363,
        "objectid": 5,
        "shape_leng": 0.0921464898574,
        "location_id": 5,
        "zone": "Arden Heights",
        "borough": "Staten Island"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -74.17421738099989,
                40.5625680859999
              ],
              [
                -74.17488750399997,
                40.562826418999876
              ],
              [
                -74.17539949099991,
                40.562964661999914
              ],
              [
                -74.17659366099997,
                40.563241328999894
              ],
              [
                -74.17758036699996,
                40.56375543999989
              ],
              [
                -74.17794544199997,
                40.56398336699991
              ],
              [
                -74.18139492499988,
                40.56523797799992
              ],
              [
                -74.1815955649999,
                40.56531796099987
              ],
              [
                -74.18180816599994,
                40.56538457899992
              ],
              [
                -74.18203038699987,
                40.56543640499994
              ],
              [
                -74.18225953199997,
                40.565472349999936
              ],
              [
                -74.18241339499998,
                40.56548514599992
              ],
              [
                -74.18249259899989,
                40.56549172899984
              ],
              [
                -74.18272650699997,
                40.56549428299995
              ],
              [
                -74.18295815999996,
                40.56548019999992
              ],
              [
                -74.18318459699992,
                40.56545009399986
              ],
              [
                -74.18340311699991,
                40.56540496199994
              ],
              [
                -74.18369189099995,
                40.565319067999916
              ],
              [
                -74.18397454599993,
                40.56522036799989
              ],
              [
                -74.18397488799997,
                40.565220240999935
              ],
              [
                -74.18397521799992,
                40.56522011499992
              ],
              [
                -74.18425030599994,
                40.565108989999906
              ],
              [
                -74.18425062399993,
                40.56510885399991
              ],
              [
                -74.18425093099988,
                40.56510871899992
              ],
              [
                -74.18451749399999,
                40.56498549599995
              ],
              [
                -74.18451768299992,
                40.564985404999916
              ],
              [
                -74.18451788299991,
                40.5649853049999
              ],
              [
                -74.18477472999997,
                40.56485049699993
              ],
              [
                -74.18502026899988,
                40.564705027999906
              ],
              [
                -74.18502052799994,
                40.56470486499994
              ],
              [
                -74.18502077599994,
                40.564704710999884
              ],
              [
                -74.18525395999991,
                40.5645493549999
              ],
              [
                -74.18576667699995,
                40.56444512799989
              ],
              [
                -74.18576687799995,
                40.564445082999946
              ],
              [
                -74.18576707899994,
                40.564445037999924
              ],
              [
                -74.18627446199993,
                40.564325526999866
              ],
              [
                -74.18677554199998,
                40.56419095799989
              ],
              [
                -74.18726897599993,
                40.56404169399985
              ],
              [
                -74.18909923999989,
                40.56368927199991
              ],
              [
                -74.1892907519999,
                40.56364274199991
              ],
              [
                -74.18947754599994,
                40.56358098999991
              ],
              [
                -74.18965655100001,
                40.563504491999915
              ],
              [
                -74.18982471199989,
                40.56341428799995
              ],
              [
                -74.18982482999994,
                40.563414223999885
              ],
              [
                -74.18982494799998,
                40.56341415199991
              ],
              [
                -74.18997973099991,
                40.56331160499996
              ],
              [
                -74.19011908599994,
                40.56319841199997
              ],
              [
                -74.19024124699997,
                40.563076684999935
              ],
              [
                -74.19032832899994,
                40.56296424499989
              ],
              [
                -74.19043537599994,
                40.56285950699993
              ],
              [
                -74.19056041299997,
                40.56276545599992
              ],
              [
                -74.1907004609999,
                40.5626846349999
              ],
              [
                -74.19085177899994,
                40.562618932999946
              ],
              [
                -74.19101021699996,
                40.562569401999916
              ],
              [
                -74.19123302399998,
                40.5625375759999
              ],
              [
                -74.19146034299997,
                40.5625194289999
              ],
              [
                -74.19168998299995,
                40.56251540899991
              ],
              [
                -74.19191967499992,
                40.56252570499986
              ],
              [
                -74.19214711699996,
                40.5625502149999
              ],
              [
                -74.19237007799995,
                40.5625885469999
              ],
              [
                -74.19258646299993,
                40.562640053999885
              ],
              [
                -74.192684759,
                40.56265171699996
              ],
              [
                -74.19278405499998,
                40.56265632499992
              ],
              [
                -74.1928834799999,
                40.56265383999989
              ],
              [
                -74.19298216699998,
                40.562644281999894
              ],
              [
                -74.19307925099996,
                40.5626277349999
              ],
              [
                -74.19317388399995,
                40.56260434399992
              ],
              [
                -74.19341094699992,
                40.562485039999935
              ],
              [
                -74.19364051199993,
                40.562357497999876
              ],
              [
                -74.19364075899988,
                40.56235735399986
              ],
              [
                -74.19364100699988,
                40.562357199999944
              ],
              [
                -74.19386239999993,
                40.56222177399992
              ],
              [
                -74.19386255299989,
                40.562221684999955
              ],
              [
                -74.19386270699994,
                40.56222158499991
              ],
              [
                -74.19407564499997,
                40.56207847399989
              ],
              [
                -74.19769481199992,
                40.558887053999854
              ],
              [
                -74.19946683299995,
                40.557181180999905
              ],
              [
                -74.20005953999994,
                40.55657794099987
              ],
              [
                -74.20018273999989,
                40.55645443399991
              ],
              [
                -74.20031905499991,
                40.556338225999916
              ],
              [
                -74.20046730799992,
                40.55623049099989
              ],
              [
                -74.20062607299995,
                40.55613226099993
              ],
              [
                -74.20079377199987,
                40.55604436599988
              ],
              [
                -74.20091501299993,
                40.55600526099987
              ],
              [
                -74.20103876099996,
                40.55597102699993
              ],
              [
                -74.20116467999998,
                40.55594175599992
              ],
              [
                -74.20129242599992,
                40.55591752899991
              ],
              [
                -74.20142165399993,
                40.55589840999992
              ],
              [
                -74.20155201099992,
                40.55588445199986
              ],
              [
                -74.20168314599995,
                40.55587569299991
              ],
              [
                -74.20181469999991,
                40.5558721559999
              ],
              [
                -74.2019463179999,
                40.55587385199995
              ],
              [
                -74.20207763999998,
                40.55588077499989
              ],
              [
                -74.2022083119999,
                40.55589290699987
              ],
              [
                -74.20233797799995,
                40.55591021399987
              ],
              [
                -74.20247541199996,
                40.55592731199989
              ],
              [
                -74.20261207199997,
                40.55594770599989
              ],
              [
                -74.2027478209999,
                40.55597137599992
              ],
              [
                -74.20288252399993,
                40.555998296999846
              ],
              [
                -74.20301604799995,
                40.556028442999875
              ],
              [
                -74.20314825999996,
                40.556061784999876
              ],
              [
                -74.20318032199998,
                40.555991904999914
              ],
              [
                -74.20320742499993,
                40.55592081499991
              ],
              [
                -74.20322948999994,
                40.5558487179999
              ],
              [
                -74.20324645499988,
                40.55577582299988
              ],
              [
                -74.20325826899995,
                40.55570233899991
              ],
              [
                -74.2032648999999,
                40.55562847699989
              ],
              [
                -74.20291038499992,
                40.55494466699989
              ],
              [
                -74.20262828599995,
                40.55434351199996
              ],
              [
                -74.20234825099999,
                40.55375861799991
              ],
              [
                -74.20216129599993,
                40.55335994099991
              ],
              [
                -74.20185089099988,
                40.552689430999926
              ],
              [
                -74.20097039299995,
                40.5507901389999
              ],
              [
                -74.20018262899993,
                40.54890664599988
              ],
              [
                -74.20012791899997,
                40.548759873999856
              ],
              [
                -74.19985031599995,
                40.5481324189999
              ],
              [
                -74.19912044799996,
                40.54670870699987
              ],
              [
                -74.19860457899992,
                40.545679908999915
              ],
              [
                -74.19840857099992,
                40.54530644699991
              ],
              [
                -74.19730759199997,
                40.543262044999885
              ],
              [
                -74.19702175199988,
                40.542526848999934
              ],
              [
                -74.19671334599992,
                40.541846973999895
              ],
              [
                -74.19640185999997,
                40.54117319999994
              ],
              [
                -74.19609298399995,
                40.540497735999914
              ],
              [
                -74.19576408099995,
                40.53983810199987
              ],
              [
                -74.19542518899992,
                40.53917847399987
              ],
              [
                -74.19507682399993,
                40.53848646999991
              ],
              [
                -74.19476262699999,
                40.53783140799991
              ],
              [
                -74.19468316799997,
                40.537661891999946
              ],
              [
                -74.19444077699994,
                40.537156592999914
              ],
              [
                -74.19409661699994,
                40.5365072449999
              ],
              [
                -74.19377169299999,
                40.53583384699993
              ],
              [
                -74.19135323799996,
                40.536664858999856
              ],
              [
                -74.19030442599997,
                40.53724645299995
              ],
              [
                -74.189510226,
                40.53778857999992
              ],
              [
                -74.18903145199995,
                40.53815934199989
              ],
              [
                -74.18826921099989,
                40.53886614199993
              ],
              [
                -74.18770381799999,
                40.53952694799991
              ],
              [
                -74.18738120699996,
                40.53989224299987
              ],
              [
                -74.1870889679999,
                40.54024712299989
              ],
              [
                -74.18657647099997,
                40.54085328399988
              ],
              [
                -74.1855985999999,
                40.541957592999935
              ],
              [
                -74.18369101699987,
                40.54417358399989
              ],
              [
                -74.18285329599989,
                40.545145824999906
              ],
              [
                -74.18206740899993,
                40.54604483899989
              ],
              [
                -74.18128532999992,
                40.54695757299989
              ],
              [
                -74.18018524699995,
                40.54837960899992
              ],
              [
                -74.17991198599996,
                40.54883445299993
              ],
              [
                -74.1793348139999,
                40.54988835199988
              ],
              [
                -74.17874485199994,
                40.550995868999905
              ],
              [
                -74.17815548499992,
                40.552112789999896
              ],
              [
                -74.17746276899993,
                40.55341114599985
              ],
              [
                -74.17661991899996,
                40.55499100699994
              ],
              [
                -74.1761165979999,
                40.55593754099987
              ],
              [
                -74.17579913099998,
                40.55651243799986
              ],
              [
                -74.17559957599995,
                40.55685071199992
              ],
              [
                -74.17547297999988,
                40.557065311999914
              ],
              [
                -74.17512402999996,
                40.557620037999925
              ],
              [
                -74.1750629909999,
                40.55771126399991
              ],
              [
                -74.17475740499992,
                40.55816798199986
              ],
              [
                -74.17437344399991,
                40.55870866599984
              ],
              [
                -74.17358785199997,
                40.55969842799993
              ],
              [
                -74.17321541599998,
                40.5600776619999
              ],
              [
                -74.17245326199999,
                40.56088825699991
              ],
              [
                -74.17222993499992,
                40.56113585799992
              ],
              [
                -74.17185166499996,
                40.56152092299992
              ],
              [
                -74.17191921399996,
                40.56155171899995
              ],
              [
                -74.17225507699992,
                40.56170483699991
              ],
              [
                -74.17348507999998,
                40.56226748999995
              ],
              [
                -74.17421738099989,
                40.5625680859999
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000606460984581,
        "objectid": 6,
        "shape_leng": 0.150490542523,
        "location_id": 6,
        "zone": "Arrochar/Fort Wadsworth",
        "borough": "Staten Island"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -74.06367318899999,
                40.60219816599994
              ],
              [
                -74.0642173399999,
                40.60235301499994
              ],
              [
                -74.06472638299991,
                40.60249786699991
              ],
              [
                -74.06550663199994,
                40.60271988099993
              ],
              [
                -74.06571018099996,
                40.602793822999935
              ],
              [
                -74.0689811829999,
                40.60398191899989
              ],
              [
                -74.0691403099999,
                40.60403971899989
              ],
              [
                -74.07172956299995,
                40.605058089999936
              ],
              [
                -74.07206682799995,
                40.605186346999865
              ],
              [
                -74.07242904799989,
                40.60530270199994
              ],
              [
                -74.07268510599995,
                40.60538461299992
              ],
              [
                -74.07298106899998,
                40.60546992999985
              ],
              [
                -74.07335207399997,
                40.60556304399994
              ],
              [
                -74.07358326599991,
                40.60562106699993
              ],
              [
                -74.0739996529999,
                40.605725567999855
              ],
              [
                -74.0747894209999,
                40.60587021199996
              ],
              [
                -74.07543239699991,
                40.6059879669999
              ],
              [
                -74.07551713999996,
                40.60599795099994
              ],
              [
                -74.07606892799994,
                40.606062951999874
              ],
              [
                -74.07656734699997,
                40.606121663999936
              ],
              [
                -74.07778089599998,
                40.60617100899986
              ],
              [
                -74.0815547009999,
                40.60629029699988
              ],
              [
                -74.0828094989999,
                40.60641097899991
              ],
              [
                -74.08341933399991,
                40.60652250899992
              ],
              [
                -74.08355131599996,
                40.60655198599987
              ],
              [
                -74.08406781699996,
                40.60668148499985
              ],
              [
                -74.084900025,
                40.60689013199992
              ],
              [
                -74.08542756299994,
                40.60702238999989
              ],
              [
                -74.08635759599996,
                40.60740005899989
              ],
              [
                -74.0871642159999,
                40.60772760199992
              ],
              [
                -74.08796268699999,
                40.608051827999915
              ],
              [
                -74.08874638699992,
                40.60837004599988
              ],
              [
                -74.08949370099997,
                40.60868693299991
              ],
              [
                -74.09045495899996,
                40.60909452599991
              ],
              [
                -74.09055711299992,
                40.609001150999894
              ],
              [
                -74.09080114099994,
                40.6087780889999
              ],
              [
                -74.09096036099993,
                40.60862968299989
              ],
              [
                -74.0914097369999,
                40.60822629799987
              ],
              [
                -74.09149702999991,
                40.608051967999856
              ],
              [
                -74.09160388599999,
                40.60788297299996
              ],
              [
                -74.09172937299998,
                40.60772119199992
              ],
              [
                -74.09187212199998,
                40.60756836799993
              ],
              [
                -74.09203043499996,
                40.60742603999993
              ],
              [
                -74.09239912899989,
                40.6070722889999
              ],
              [
                -74.09249749399994,
                40.606943849999894
              ],
              [
                -74.09258024999993,
                40.60680678599988
              ],
              [
                -74.09264555599998,
                40.606662838999924
              ],
              [
                -74.0926455799999,
                40.60666278599994
              ],
              [
                -74.09264560299997,
                40.606662721999925
              ],
              [
                -74.09269213899992,
                40.60651384699985
              ],
              [
                -74.09271908099993,
                40.60636222199992
              ],
              [
                -74.09271909299994,
                40.606362122999876
              ],
              [
                -74.092719093,
                40.6063620239999
              ],
              [
                -74.09272620699986,
                40.606209856999946
              ],
              [
                -74.092713803,
                40.60605936299993
              ],
              [
                -74.09271379199996,
                40.6060592729999
              ],
              [
                -74.0927137679999,
                40.606059182999914
              ],
              [
                -74.09268272599991,
                40.60591244899992
              ],
              [
                -74.09262561899992,
                40.60545055399992
              ],
              [
                -74.09258665899993,
                40.60514053599992
              ],
              [
                -74.09244145299986,
                40.604119585999875
              ],
              [
                -74.09260921099994,
                40.603230122999875
              ],
              [
                -74.09272060299999,
                40.60249806099995
              ],
              [
                -74.09282191499993,
                40.601809565999865
              ],
              [
                -74.09285575199992,
                40.60172180699987
              ],
              [
                -74.09288063499999,
                40.6016323259999
              ],
              [
                -74.09289641899991,
                40.601541650999884
              ],
              [
                -74.09290300799996,
                40.60145031999985
              ],
              [
                -74.09290036399996,
                40.60135887199993
              ],
              [
                -74.09288850399999,
                40.601267850999896
              ],
              [
                -74.0928674959999,
                40.601177792999934
              ],
              [
                -74.09283746599989,
                40.601089233999865
              ],
              [
                -74.09263886099997,
                40.60084710199989
              ],
              [
                -74.0924525679999,
                40.60059897899987
              ],
              [
                -74.09227904299993,
                40.600345409999875
              ],
              [
                -74.09211869899993,
                40.600086968999946
              ],
              [
                -74.09197184899992,
                40.599824262999945
              ],
              [
                -74.091798925,
                40.5993182099999
              ],
              [
                -74.09172051499995,
                40.59922085699985
              ],
              [
                -74.0916133049999,
                40.59926156199986
              ],
              [
                -74.09147742399989,
                40.59940848999992
              ],
              [
                -74.09083323299996,
                40.59998852399984
              ],
              [
                -74.09060390399996,
                40.60019882399991
              ],
              [
                -74.09036073899993,
                40.60040036399992
              ],
              [
                -74.09010463499996,
                40.600592348999896
              ],
              [
                -74.08983655899988,
                40.60077409499996
              ],
              [
                -74.08947630599992,
                40.60090432199992
              ],
              [
                -74.08920169499996,
                40.600963789999916
              ],
              [
                -74.0883067039999,
                40.60110777999993
              ],
              [
                -74.08666235099996,
                40.6012335389999
              ],
              [
                -74.08647535899998,
                40.60126896199992
              ],
              [
                -74.08625420399993,
                40.60132418899987
              ],
              [
                -74.085837971,
                40.60142777299994
              ],
              [
                -74.08520894199994,
                40.60160095799992
              ],
              [
                -74.08457153399995,
                40.601822479999974
              ],
              [
                -74.08432821299992,
                40.60187268299991
              ],
              [
                -74.08383802499996,
                40.60192576699993
              ],
              [
                -74.082815161,
                40.60197418199989
              ],
              [
                -74.08218729299988,
                40.60194100799988
              ],
              [
                -74.08184498199992,
                40.601535196999926
              ],
              [
                -74.0813965889999,
                40.60092495199991
              ],
              [
                -74.081318208,
                40.600805328999904
              ],
              [
                -74.08125785899989,
                40.600678847999866
              ],
              [
                -74.081216903,
                40.600547931999905
              ],
              [
                -74.08119595999997,
                40.60041517299986
              ],
              [
                -74.08116420899995,
                40.600206553999946
              ],
              [
                -74.08130343799998,
                40.59938765599993
              ],
              [
                -74.08144216599995,
                40.599159219999905
              ],
              [
                -74.0814942209999,
                40.599081990999935
              ],
              [
                -74.08160410699992,
                40.59902445899997
              ],
              [
                -74.08199990199994,
                40.598682437999884
              ],
              [
                -74.0823092379999,
                40.59845665499995
              ],
              [
                -74.08248303399992,
                40.598329807999924
              ],
              [
                -74.08360775099996,
                40.597912413999936
              ],
              [
                -74.08469129699996,
                40.59750713199992
              ],
              [
                -74.08476803199989,
                40.59746356299997
              ],
              [
                -74.08476811499993,
                40.59746351699994
              ],
              [
                -74.08476818599993,
                40.59746346299988
              ],
              [
                -74.08497132899997,
                40.597325109999865
              ],
              [
                -74.0851581859999,
                40.59717283699994
              ],
              [
                -74.08515824499993,
                40.597172791999874
              ],
              [
                -74.08515829199987,
                40.59717273799993
              ],
              [
                -74.08532650099991,
                40.59700832199998
              ],
              [
                -74.08547413999999,
                40.59683381699989
              ],
              [
                -74.08585350599992,
                40.59594098099991
              ],
              [
                -74.08587511899997,
                40.59589012099985
              ],
              [
                -74.08578780799998,
                40.59588407999997
              ],
              [
                -74.08491019899996,
                40.59570472799985
              ],
              [
                -74.08406968699997,
                40.59549626999994
              ],
              [
                -74.08376862299998,
                40.595520956999934
              ],
              [
                -74.08330235099992,
                40.594716327999876
              ],
              [
                -74.0832075799999,
                40.594533734999864
              ],
              [
                -74.08304163599998,
                40.594229720999884
              ],
              [
                -74.08239591899998,
                40.59319116199994
              ],
              [
                -74.08177066499995,
                40.59221445099991
              ],
              [
                -74.08173342999991,
                40.59215628599987
              ],
              [
                -74.08029682099989,
                40.59266864199986
              ],
              [
                -74.07936282299995,
                40.59299407399992
              ],
              [
                -74.0784669379999,
                40.59330001199991
              ],
              [
                -74.07888191699986,
                40.59394279099994
              ],
              [
                -74.07802261099992,
                40.594254823999975
              ],
              [
                -74.07714922399995,
                40.59453408099989
              ],
              [
                -74.07626202999994,
                40.5948225699999
              ],
              [
                -74.07539899099994,
                40.59510414799992
              ],
              [
                -74.07453730499996,
                40.59538677999991
              ],
              [
                -74.073679591,
                40.59566839599995
              ],
              [
                -74.07281699299993,
                40.595952214999926
              ],
              [
                -74.0711863499999,
                40.59648999199993
              ],
              [
                -74.07043976099992,
                40.59673474699988
              ],
              [
                -74.06970478499989,
                40.59537635999994
              ],
              [
                -74.06964532799996,
                40.59526401099989
              ],
              [
                -74.06831810799993,
                40.59278576599994
              ],
              [
                -74.06814585499988,
                40.592455763999965
              ],
              [
                -74.06795227299993,
                40.59208368299988
              ],
              [
                -74.06751477499988,
                40.591334642999925
              ],
              [
                -74.06728410699992,
                40.59098620299988
              ],
              [
                -74.06666723999993,
                40.59038276199989
              ],
              [
                -74.06662559499993,
                40.59034201999989
              ],
              [
                -74.06651182899995,
                40.59034492199989
              ],
              [
                -74.0658293969999,
                40.590059164999914
              ],
              [
                -74.06577682899997,
                40.5900213909999
              ],
              [
                -74.06573690899995,
                40.589969516999936
              ],
              [
                -74.06571834999994,
                40.58990810299986
              ],
              [
                -74.06572536499986,
                40.58984529599992
              ],
              [
                -74.06575530299992,
                40.58978965399993
              ],
              [
                -74.06580036099992,
                40.5897466099999
              ],
              [
                -74.06563611899992,
                40.589628104999896
              ],
              [
                -74.06503332378131,
                40.58899099843518
              ],
              [
                -74.06388709125392,
                40.59020746620928
              ],
              [
                -74.06215274432542,
                40.59202504054203
              ],
              [
                -74.06133778103231,
                40.5927154190645
              ],
              [
                -74.06055353637058,
                40.593379755423555
              ],
              [
                -74.05984505851372,
                40.59378053194152
              ],
              [
                -74.05976660687638,
                40.59383525751018
              ],
              [
                -74.05966255766188,
                40.593703175710345
              ],
              [
                -74.05964120596147,
                40.59370938839297
              ],
              [
                -74.05974734759447,
                40.59384861156726
              ],
              [
                -74.05972690577232,
                40.593908216712684
              ],
              [
                -74.05719369904648,
                40.59639507881198
              ],
              [
                -74.05681139913331,
                40.59672692304163
              ],
              [
                -74.05447422333906,
                40.5987555395484
              ],
              [
                -74.05433644573098,
                40.5987604455301
              ],
              [
                -74.0543115048126,
                40.59877750576014
              ],
              [
                -74.05434118619358,
                40.59881917533659
              ],
              [
                -74.05309724728706,
                40.59973106712562
              ],
              [
                -74.0530228827237,
                40.599778683597606
              ],
              [
                -74.05291816194962,
                40.59984625717821
              ],
              [
                -74.05287153843383,
                40.59986725546714
              ],
              [
                -74.05279416485439,
                40.599873464654806
              ],
              [
                -74.0527173663352,
                40.599854472448875
              ],
              [
                -74.05260660501526,
                40.59981457537062
              ],
              [
                -74.05249178950837,
                40.5997368430716
              ],
              [
                -74.05242827330757,
                40.59972815401489
              ],
              [
                -74.0523724578026,
                40.599718782418186
              ],
              [
                -74.05232494851855,
                40.59971490532035
              ],
              [
                -74.05229099291411,
                40.59972044052658
              ],
              [
                -74.0522677927031,
                40.59973493984095
              ],
              [
                -74.05224531733288,
                40.59974801415286
              ],
              [
                -74.05222826954189,
                40.599776102303636
              ],
              [
                -74.05222983028327,
                40.59982388359393
              ],
              [
                -74.05225411910509,
                40.599851167410186
              ],
              [
                -74.05240319632722,
                40.59986949892358
              ],
              [
                -74.05300717979287,
                40.60016041760982
              ],
              [
                -74.05319033527633,
                40.600281156191386
              ],
              [
                -74.05324525405089,
                40.60032279174719
              ],
              [
                -74.05329479798284,
                40.60036817591045
              ],
              [
                -74.05333852878636,
                40.60041690720497
              ],
              [
                -74.05337605959711,
                40.60046855454402
              ],
              [
                -74.0534070583946,
                40.600522661043925
              ],
              [
                -74.05342168437245,
                40.60058456965285
              ],
              [
                -74.05342893884374,
                40.60064723050092
              ],
              [
                -74.05342876287239,
                40.60071013470403
              ],
              [
                -74.05342115786627,
                40.60077277140068
              ],
              [
                -74.05340618556725,
                40.60083463190021
              ],
              [
                -74.0533839675499,
                40.60089521381433
              ],
              [
                -74.05335468423449,
                40.60095402513733
              ],
              [
                -74.05331857342287,
                40.60101058824162
              ],
              [
                -74.05309560984993,
                40.60121113138266
              ],
              [
                -74.0530674364756,
                40.60127284446127
              ],
              [
                -74.05304754163845,
                40.60133640300341
              ],
              [
                -74.05303612675755,
                40.60140116360679
              ],
              [
                -74.05303330741077,
                40.60146647069907
              ],
              [
                -74.05303911216359,
                40.60153166317351
              ],
              [
                -74.05304707455683,
                40.60162256567726
              ],
              [
                -74.05306303371839,
                40.60171285483406
              ],
              [
                -74.05308691752415,
                40.60180212242051
              ],
              [
                -74.05311861801995,
                40.6018899648305
              ],
              [
                -74.05315799190798,
                40.60197598489976
              ],
              [
                -74.05320486119456,
                40.60205979370229
              ],
              [
                -74.05353964480109,
                40.603633557567264
              ],
              [
                -74.05356753570281,
                40.603696466325864
              ],
              [
                -74.05359133556057,
                40.60376168855161
              ],
              [
                -74.0536712685173,
                40.60400478590508
              ],
              [
                -74.05369607985057,
                40.60414810383786
              ],
              [
                -74.05375474319875,
                40.60427693142138
              ],
              [
                -74.05378152765509,
                40.60436142291276
              ],
              [
                -74.05385099498751,
                40.604530274454824
              ],
              [
                -74.0540203995905,
                40.60493472091635
              ],
              [
                -74.05367256354405,
                40.605188343322304
              ],
              [
                -74.05360729740819,
                40.605238809417735
              ],
              [
                -74.05371769396106,
                40.60560458157247
              ],
              [
                -74.05381069805436,
                40.605912718852984
              ],
              [
                -74.055059740958,
                40.60626542045283
              ],
              [
                -74.05512022975005,
                40.60630536171678
              ],
              [
                -74.05515251060757,
                40.60632904589771
              ],
              [
                -74.05516855690021,
                40.60636554730313
              ],
              [
                -74.05517955854631,
                40.606402334922386
              ],
              [
                -74.05518218103546,
                40.60647456683434
              ],
              [
                -74.05517250950514,
                40.606490767349584
              ],
              [
                -74.05510403532153,
                40.60658841067159
              ],
              [
                -74.05505591012927,
                40.60656880569464
              ],
              [
                -74.0550195433675,
                40.6066206629666
              ],
              [
                -74.05505194098775,
                40.60663386047028
              ],
              [
                -74.05503373135794,
                40.60665982664601
              ],
              [
                -74.05499916476879,
                40.60664574478401
              ],
              [
                -74.05498867839447,
                40.60666069691175
              ],
              [
                -74.05516001122312,
                40.60673049135086
              ],
              [
                -74.05528719697126,
                40.606538890078745
              ],
              [
                -74.05537932434086,
                40.60649422352233
              ],
              [
                -74.05543198004166,
                40.60648249104071
              ],
              [
                -74.05552126122357,
                40.6064828925687
              ],
              [
                -74.0560104141088,
                40.606780566131995
              ],
              [
                -74.05640693287154,
                40.60718873802623
              ],
              [
                -74.05649565936757,
                40.6072476482562
              ],
              [
                -74.05665999852928,
                40.607555930875925
              ],
              [
                -74.05674934830238,
                40.607798757335814
              ],
              [
                -74.05677183367786,
                40.607899470387395
              ],
              [
                -74.05685812390232,
                40.608056357772554
              ],
              [
                -74.0578901689999,
                40.607580753999905
              ],
              [
                -74.05778710399987,
                40.60744959099992
              ],
              [
                -74.05801067099993,
                40.60736879199994
              ],
              [
                -74.06042867199996,
                40.6063527299999
              ],
              [
                -74.06046429699992,
                40.606405823999864
              ],
              [
                -74.0651709129999,
                40.6042135079999
              ],
              [
                -74.06383152699986,
                40.602588967999964
              ],
              [
                -74.06350565699998,
                40.60215005399992
              ],
              [
                -74.06367318899999,
                40.60219816599994
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000389787989274,
        "objectid": 7,
        "shape_leng": 0.107417171123,
        "location_id": 7,
        "zone": "Astoria",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.90413637799996,
                40.767520316999864
              ],
              [
                -73.90418895299992,
                40.767530284999964
              ],
              [
                -73.90427336799988,
                40.767545104999925
              ],
              [
                -73.90446527099982,
                40.767583778999914
              ],
              [
                -73.90490866399992,
                40.76774556099988
              ],
              [
                -73.90531717799979,
                40.76789864999992
              ],
              [
                -73.9055484799998,
                40.76797657999988
              ],
              [
                -73.90577352999988,
                40.768028588999904
              ],
              [
                -73.90611305099988,
                40.7680677049999
              ],
              [
                -73.90708728999994,
                40.76823623099996
              ],
              [
                -73.90803079099996,
                40.768384733999916
              ],
              [
                -73.90900867299995,
                40.76854754699986
              ],
              [
                -73.909980063,
                40.768697234999884
              ],
              [
                -73.91094174899979,
                40.76885247299993
              ],
              [
                -73.91194026099988,
                40.76901015299997
              ],
              [
                -73.91291678,
                40.769161296999926
              ],
              [
                -73.91388850199984,
                40.769323929999906
              ],
              [
                -73.91487376500001,
                40.76947041699985
              ],
              [
                -73.915848706,
                40.76963357499988
              ],
              [
                -73.91643979099993,
                40.76972971399988
              ],
              [
                -73.91682128799984,
                40.76979176099995
              ],
              [
                -73.91759120499997,
                40.769900870999926
              ],
              [
                -73.91811686499992,
                40.77002848599989
              ],
              [
                -73.91828990899992,
                40.76987856799984
              ],
              [
                -73.91995596899991,
                40.768440064999865
              ],
              [
                -73.91911161599978,
                40.76802545099991
              ],
              [
                -73.91849336999996,
                40.76773465699996
              ],
              [
                -73.918906013,
                40.76722677599987
              ],
              [
                -73.91977764799998,
                40.766152183999914
              ],
              [
                -73.92058727399994,
                40.76652069899995
              ],
              [
                -73.92139066099993,
                40.76689410499988
              ],
              [
                -73.92218946199995,
                40.76726735699988
              ],
              [
                -73.92353865299998,
                40.765603497999905
              ],
              [
                -73.92425288799978,
                40.76472456399996
              ],
              [
                -73.92506062099999,
                40.76509731499993
              ],
              [
                -73.92643792699984,
                40.765732814999936
              ],
              [
                -73.92855636599988,
                40.76671423899995
              ],
              [
                -73.9298564789999,
                40.767326551999865
              ],
              [
                -73.93202371699994,
                40.7682564959999
              ],
              [
                -73.93344468499993,
                40.768817789999915
              ],
              [
                -73.93422865800001,
                40.76785468299989
              ],
              [
                -73.93493511099992,
                40.76701302399987
              ],
              [
                -73.93594942700003,
                40.767578224999966
              ],
              [
                -73.93618379699991,
                40.767691800999934
              ],
              [
                -73.93709253699996,
                40.76736039299991
              ],
              [
                -73.93836125899992,
                40.766910754999884
              ],
              [
                -73.93858976899998,
                40.766817358999916
              ],
              [
                -73.93846096299997,
                40.766768302999935
              ],
              [
                -73.93763535399994,
                40.766474871999904
              ],
              [
                -73.93682006599995,
                40.766182735999905
              ],
              [
                -73.93600532399995,
                40.76588489499989
              ],
              [
                -73.93700478599986,
                40.76423995399997
              ],
              [
                -73.93687031099991,
                40.7641779169999
              ],
              [
                -73.93684249199987,
                40.764165082999924
              ],
              [
                -73.9362557499999,
                40.763894396999916
              ],
              [
                -73.9362163929999,
                40.76387581299984
              ],
              [
                -73.93619184999997,
                40.76386422399987
              ],
              [
                -73.93616861799997,
                40.76385325299988
              ],
              [
                -73.93614654199985,
                40.76384282899991
              ],
              [
                -73.93541372899996,
                40.763496787999884
              ],
              [
                -73.93398092100001,
                40.762825981999875
              ],
              [
                -73.93404264799992,
                40.762761377999915
              ],
              [
                -73.9341081699999,
                40.76269280199993
              ],
              [
                -73.93429712599992,
                40.76249503399991
              ],
              [
                -73.9343717929999,
                40.76241688499991
              ],
              [
                -73.93457323599993,
                40.762206044999935
              ],
              [
                -73.93463877499988,
                40.76213744999994
              ],
              [
                -73.93472503099987,
                40.762047169999896
              ],
              [
                -73.93480791700001,
                40.7619604159999
              ],
              [
                -73.93538955299994,
                40.76135538399994
              ],
              [
                -73.93543323399999,
                40.76130585199992
              ],
              [
                -73.93546712899983,
                40.761270327999945
              ],
              [
                -73.93550408999991,
                40.76123158999995
              ],
              [
                -73.93556528699992,
                40.7611674509999
              ],
              [
                -73.93560854899978,
                40.761122109999846
              ],
              [
                -73.93566314299997,
                40.76106489099993
              ],
              [
                -73.93569960399994,
                40.76102667699991
              ],
              [
                -73.93577489099982,
                40.76094777199988
              ],
              [
                -73.93581185199994,
                40.7609090339999
              ],
              [
                -73.93584407599987,
                40.76087525999995
              ],
              [
                -73.93589256999995,
                40.7608244349999
              ],
              [
                -73.93593276099996,
                40.76078231199991
              ],
              [
                -73.9360170199998,
                40.7606940019999
              ],
              [
                -73.93605584499984,
                40.76065330999995
              ],
              [
                -73.93679091699993,
                40.759882880999925
              ],
              [
                -73.93588175099988,
                40.75945697999988
              ],
              [
                -73.93579879199996,
                40.759418044999904
              ],
              [
                -73.93505987399978,
                40.75907122899988
              ],
              [
                -73.9350341019999,
                40.75905925799991
              ],
              [
                -73.93496053299995,
                40.75902508599996
              ],
              [
                -73.93425020699995,
                40.758695133999936
              ],
              [
                -73.93335306599997,
                40.75827433399996
              ],
              [
                -73.93215200299993,
                40.757710384999925
              ],
              [
                -73.93135128699987,
                40.75733408499986
              ],
              [
                -73.93055267399994,
                40.756958942999916
              ],
              [
                -73.92975052199998,
                40.75658057699989
              ],
              [
                -73.92895587499993,
                40.75621258399986
              ],
              [
                -73.92815372599988,
                40.755835855999905
              ],
              [
                -73.92735228500001,
                40.75546007599992
              ],
              [
                -73.92655413100005,
                40.755085634999936
              ],
              [
                -73.92575429299997,
                40.75471089899986
              ],
              [
                -73.92495400099993,
                40.75433675199992
              ],
              [
                -73.92415699999985,
                40.753961141999866
              ],
              [
                -73.92562743799995,
                40.75213724499991
              ],
              [
                -73.9244174849999,
                40.75240430199992
              ],
              [
                -73.92296643399989,
                40.75272880199985
              ],
              [
                -73.92204020499989,
                40.752937627999906
              ],
              [
                -73.92161909499991,
                40.75302765399987
              ],
              [
                -73.92047926000001,
                40.753285236999936
              ],
              [
                -73.920276847,
                40.75333811799994
              ],
              [
                -73.91892348099987,
                40.75363612399991
              ],
              [
                -73.91763192900005,
                40.75385784899993
              ],
              [
                -73.91650534599997,
                40.75389159099996
              ],
              [
                -73.91547326299991,
                40.7537918179999
              ],
              [
                -73.91468429799998,
                40.75368006899987
              ],
              [
                -73.91446450799998,
                40.75367641199993
              ],
              [
                -73.9135418349999,
                40.7534687139999
              ],
              [
                -73.91256954199997,
                40.75330421499995
              ],
              [
                -73.91067474899997,
                40.752995076999895
              ],
              [
                -73.91023124699991,
                40.75297652299987
              ],
              [
                -73.90995439699994,
                40.75321782299993
              ],
              [
                -73.9096931489999,
                40.75346973299989
              ],
              [
                -73.90944851199998,
                40.75373135399992
              ],
              [
                -73.90922902699994,
                40.75399264199997
              ],
              [
                -73.90922139799983,
                40.75400172299991
              ],
              [
                -73.90917451199998,
                40.75406416699993
              ],
              [
                -73.90901259099992,
                40.75427981499996
              ],
              [
                -73.90879316499999,
                40.754685615999946
              ],
              [
                -73.90876818500003,
                40.75473181499993
              ],
              [
                -73.908736451,
                40.754790499999935
              ],
              [
                -73.90871371100003,
                40.75483255499992
              ],
              [
                -73.90868067299994,
                40.75489365399992
              ],
              [
                -73.90865671500002,
                40.75493796099992
              ],
              [
                -73.90862474299999,
                40.7549970909999
              ],
              [
                -73.90860265599997,
                40.7550379379999
              ],
              [
                -73.90854932099995,
                40.755136570999916
              ],
              [
                -73.90853125399995,
                40.75516998399991
              ],
              [
                -73.908509019,
                40.75521110499989
              ],
              [
                -73.90846544599992,
                40.75529168599991
              ],
              [
                -73.90840546799983,
                40.75545239999991
              ],
              [
                -73.90835663299995,
                40.75558325799991
              ],
              [
                -73.90830736999996,
                40.75571526199991
              ],
              [
                -73.90827537899996,
                40.7558009829999
              ],
              [
                -73.90824210899991,
                40.75589012999991
              ],
              [
                -73.90820623799995,
                40.75598624799987
              ],
              [
                -73.90817188199993,
                40.75607830499997
              ],
              [
                -73.90813601099995,
                40.75617442399993
              ],
              [
                -73.90810013899984,
                40.75627054199988
              ],
              [
                -73.90806081699996,
                40.75637590799997
              ],
              [
                -73.90802408399996,
                40.75647433199986
              ],
              [
                -73.90798755699986,
                40.756572205999916
              ],
              [
                -73.9079488889999,
                40.75667581499994
              ],
              [
                -73.90782817499984,
                40.75699926499987
              ],
              [
                -73.9074261499998,
                40.75810005299993
              ],
              [
                -73.90631505099988,
                40.75984387599992
              ],
              [
                -73.90699741899994,
                40.760142990999945
              ],
              [
                -73.90740334999987,
                40.7603339789999
              ],
              [
                -73.90779405499987,
                40.76051779999991
              ],
              [
                -73.90753042700004,
                40.76084467799991
              ],
              [
                -73.907419729,
                40.76098192599994
              ],
              [
                -73.90703976399989,
                40.76145304399993
              ],
              [
                -73.90677808699982,
                40.761777481999886
              ],
              [
                -73.90651032099997,
                40.76210946999992
              ],
              [
                -73.90625691699982,
                40.76242520699992
              ],
              [
                -73.90606811199996,
                40.762660444999916
              ],
              [
                -73.90566075299986,
                40.763167987999914
              ],
              [
                -73.90519286699994,
                40.76375094099996
              ],
              [
                -73.90451229899999,
                40.76458906399989
              ],
              [
                -73.90370771099997,
                40.765597148999866
              ],
              [
                -73.90361058299999,
                40.765726577999956
              ],
              [
                -73.90356915599997,
                40.76578178899994
              ],
              [
                -73.90351227799994,
                40.76584945999989
              ],
              [
                -73.90341888199997,
                40.765957306999944
              ],
              [
                -73.90255919500005,
                40.766940786999896
              ],
              [
                -73.90217951699996,
                40.76731584199997
              ],
              [
                -73.90202516599992,
                40.7674167839999
              ],
              [
                -73.90226637199994,
                40.76745694399991
              ],
              [
                -73.90251104099987,
                40.76748594099992
              ],
              [
                -73.90275780099982,
                40.767503546999855
              ],
              [
                -73.9030052879999,
                40.767509662999885
              ],
              [
                -73.90325211599989,
                40.767504322999905
              ],
              [
                -73.90413637799996,
                40.767520316999864
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000026587716279,
        "objectid": 8,
        "shape_leng": 0.0275906911574,
        "location_id": 8,
        "zone": "Astoria Park",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.92334041500001,
                40.775128911999936
              ],
              [
                -73.923277837,
                40.775177622999884
              ],
              [
                -73.92320981399997,
                40.77523476899993
              ],
              [
                -73.92314698499983,
                40.77528580399994
              ],
              [
                -73.92302571499998,
                40.77538430899993
              ],
              [
                -73.92375761299998,
                40.77654701199991
              ],
              [
                -73.92331041499993,
                40.776883294999934
              ],
              [
                -73.92264888599983,
                40.777422650999874
              ],
              [
                -73.92200155699992,
                40.777954590999855
              ],
              [
                -73.92139486599993,
                40.77845444399985
              ],
              [
                -73.92078680499985,
                40.778952266999944
              ],
              [
                -73.92012731100007,
                40.77948895199992
              ],
              [
                -73.91939411500002,
                40.78009035599997
              ],
              [
                -73.9190647659999,
                40.78035956399993
              ],
              [
                -73.91861823099985,
                40.780732867999866
              ],
              [
                -73.91796581399997,
                40.78125629099988
              ],
              [
                -73.91975969999989,
                40.782514177999886
              ],
              [
                -73.91996458796496,
                40.78263506876824
              ],
              [
                -73.92097562099832,
                40.7817676912532
              ],
              [
                -73.92209965499714,
                40.78085187481845
              ],
              [
                -73.9226301381542,
                40.78056478536395
              ],
              [
                -73.92295652695056,
                40.78044100806101
              ],
              [
                -73.92318694398986,
                40.78024839306262
              ],
              [
                -73.92423363003755,
                40.77910119388272
              ],
              [
                -73.92481615523091,
                40.77875769181163
              ],
              [
                -73.92557304281429,
                40.77845885141435
              ],
              [
                -73.9256507618914,
                40.778430811916465
              ],
              [
                -73.92574521623307,
                40.77840537091916
              ],
              [
                -73.9258220122297,
                40.77838590613155
              ],
              [
                -73.92631833401951,
                40.77824177509243
              ],
              [
                -73.92708229009109,
                40.77771978109685
              ],
              [
                -73.92827730816353,
                40.77689897516218
              ],
              [
                -73.92782147400004,
                40.77669752699993
              ],
              [
                -73.92737641899994,
                40.77646661099989
              ],
              [
                -73.92658081899984,
                40.776039258999916
              ],
              [
                -73.92602208199996,
                40.77573039899992
              ],
              [
                -73.92531479799985,
                40.77534385099991
              ],
              [
                -73.92523246199983,
                40.775298853999885
              ],
              [
                -73.92398142899997,
                40.774615111999886
              ],
              [
                -73.92334041500001,
                40.775128911999936
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000338443803197,
        "objectid": 9,
        "shape_leng": 0.0997840924705,
        "location_id": 9,
        "zone": "Auburndale",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.78502434699996,
                40.76103651599987
              ],
              [
                -73.78516838299994,
                40.76143281799994
              ],
              [
                -73.785251708,
                40.76175695899991
              ],
              [
                -73.78527359199997,
                40.76184210599993
              ],
              [
                -73.78530175399992,
                40.761978503999956
              ],
              [
                -73.78535911599988,
                40.7622563179999
              ],
              [
                -73.78542241999986,
                40.76251310799995
              ],
              [
                -73.78546383099984,
                40.762591924999896
              ],
              [
                -73.78571993599994,
                40.762921464999856
              ],
              [
                -73.78595810599988,
                40.76310224399987
              ],
              [
                -73.78714131699988,
                40.76370782099992
              ],
              [
                -73.78733166699999,
                40.76380781299988
              ],
              [
                -73.78807523699997,
                40.76419948999994
              ],
              [
                -73.78822763099993,
                40.764314395999854
              ],
              [
                -73.78836856600003,
                40.76443842899987
              ],
              [
                -73.78849669799996,
                40.7645705529999
              ],
              [
                -73.7886108559999,
                40.764709593999946
              ],
              [
                -73.78871017999994,
                40.764854251999964
              ],
              [
                -73.78963649799978,
                40.76614161099991
              ],
              [
                -73.78990502999987,
                40.7665115969999
              ],
              [
                -73.79021960799982,
                40.76695117499986
              ],
              [
                -73.79059858199989,
                40.76741868799999
              ],
              [
                -73.79137684499982,
                40.7683242969999
              ],
              [
                -73.79149870499994,
                40.7684473909999
              ],
              [
                -73.79190204099987,
                40.768936211999915
              ],
              [
                -73.79235243699989,
                40.769449637999884
              ],
              [
                -73.79238243799978,
                40.7694838389999
              ],
              [
                -73.79317944599984,
                40.770403177999896
              ],
              [
                -73.79321182299991,
                40.770336488999874
              ],
              [
                -73.793422277,
                40.768599907999906
              ],
              [
                -73.79361279599983,
                40.76722335799997
              ],
              [
                -73.7939591469999,
                40.7646953199999
              ],
              [
                -73.79302551399985,
                40.76462119199988
              ],
              [
                -73.79322483899982,
                40.763142007999946
              ],
              [
                -73.79334546300005,
                40.76229534299991
              ],
              [
                -73.79343673799991,
                40.76159683099995
              ],
              [
                -73.793438002,
                40.7615135269999
              ],
              [
                -73.79344299999985,
                40.76118533699985
              ],
              [
                -73.79344033499987,
                40.76082252699996
              ],
              [
                -73.79343991199988,
                40.76076412799995
              ],
              [
                -73.79255337999997,
                40.760840293999934
              ],
              [
                -73.791654662,
                40.76091206299987
              ],
              [
                -73.79076004599987,
                40.76098576899991
              ],
              [
                -73.79053450999997,
                40.759373037999886
              ],
              [
                -73.79031517,
                40.75782345099988
              ],
              [
                -73.79122151599987,
                40.757798765999944
              ],
              [
                -73.79201171599978,
                40.757780149999924
              ],
              [
                -73.79212059099993,
                40.75777758199993
              ],
              [
                -73.79303015799997,
                40.75775619399999
              ],
              [
                -73.79402473999977,
                40.757735968999945
              ],
              [
                -73.79493246199993,
                40.757798029999954
              ],
              [
                -73.79474720599988,
                40.75562818799993
              ],
              [
                -73.79457726999995,
                40.75356301099991
              ],
              [
                -73.794627692,
                40.75270267499999
              ],
              [
                -73.79465583899983,
                40.75198814499992
              ],
              [
                -73.79468324699988,
                40.75127504499995
              ],
              [
                -73.7947190109999,
                40.75064761699992
              ],
              [
                -73.79566545000002,
                40.75023619999991
              ],
              [
                -73.79644617199999,
                40.749859216999916
              ],
              [
                -73.79729524699995,
                40.74943505299989
              ],
              [
                -73.79704716099987,
                40.749332317999915
              ],
              [
                -73.796581036,
                40.74897739799995
              ],
              [
                -73.79598269399985,
                40.74853189299994
              ],
              [
                -73.79472264899997,
                40.74759932999991
              ],
              [
                -73.7946756879998,
                40.74721192799996
              ],
              [
                -73.79457976099988,
                40.74640268299992
              ],
              [
                -73.79452019299998,
                40.745900158999916
              ],
              [
                -73.79448123999998,
                40.745669693999915
              ],
              [
                -73.79448121599987,
                40.745669531999944
              ],
              [
                -73.79448120499993,
                40.745669369999895
              ],
              [
                -73.79445869199989,
                40.74543695399997
              ],
              [
                -73.79445282799998,
                40.74520375699995
              ],
              [
                -73.79445281699995,
                40.74520343199987
              ],
              [
                -73.79445282999983,
                40.7452030899999
              ],
              [
                -73.7944636839999,
                40.74497002199992
              ],
              [
                -73.7944911469999,
                40.744737937999965
              ],
              [
                -73.79449117099993,
                40.74473774799992
              ],
              [
                -73.7944912069999,
                40.744737567999955
              ],
              [
                -73.79453507400001,
                40.74450763799989
              ],
              [
                -73.79467327199987,
                40.74424460699987
              ],
              [
                -73.79467340799984,
                40.74424434599993
              ],
              [
                -73.79467356599999,
                40.74424409499991
              ],
              [
                -73.79483295499978,
                40.74398694199992
              ],
              [
                -73.79501285899983,
                40.743737044999875
              ],
              [
                -73.79501298499999,
                40.743736880999926
              ],
              [
                -73.79501312,
                40.74373670699995
              ],
              [
                -73.7952125779999,
                40.74349556899989
              ],
              [
                -73.79543059299992,
                40.74326428999993
              ],
              [
                -73.79566566899987,
                40.74304423599989
              ],
              [
                -73.79589302899994,
                40.74280385699993
              ],
              [
                -73.7958931919999,
                40.74280368699995
              ],
              [
                -73.79589335199982,
                40.742803526999886
              ],
              [
                -73.79613624999985,
                40.74257152399986
              ],
              [
                -73.79613631199986,
                40.74257146699995
              ],
              [
                -73.79613638599983,
                40.74257141099988
              ],
              [
                -73.79639414999991,
                40.74234849999988
              ],
              [
                -73.79639423599997,
                40.742348424999925
              ],
              [
                -73.796394323,
                40.742348348999904
              ],
              [
                -73.79666581899986,
                40.742135522999895
              ],
              [
                -73.79666601799985,
                40.74213537199991
              ],
              [
                -73.79666622899994,
                40.74213522099994
              ],
              [
                -73.79695065300002,
                40.741933034999896
              ],
              [
                -73.79723084999992,
                40.74169550699992
              ],
              [
                -73.79726795099995,
                40.74056448599986
              ],
              [
                -73.79727885499992,
                40.74044812399994
              ],
              [
                -73.79727887899985,
                40.740447861999925
              ],
              [
                -73.79727886799998,
                40.740447600999886
              ],
              [
                -73.79727517699983,
                40.74033034899987
              ],
              [
                -73.79727517699985,
                40.740330185999895
              ],
              [
                -73.79727515299997,
                40.740330033999896
              ],
              [
                -73.79725674299982,
                40.74021324199991
              ],
              [
                -73.79725670899992,
                40.74021304299991
              ],
              [
                -73.79725664999995,
                40.74021283499994
              ],
              [
                -73.79722380199985,
                40.740098277999905
              ],
              [
                -73.79722373099987,
                40.74009803499992
              ],
              [
                -73.79722362499994,
                40.7400978009999
              ],
              [
                -73.7971768669999,
                40.739986684999934
              ],
              [
                -73.79640833699986,
                40.738593419999894
              ],
              [
                -73.79629297299984,
                40.73855230599992
              ],
              [
                -73.79672497900003,
                40.738416642999894
              ],
              [
                -73.7964394009999,
                40.73843894799995
              ],
              [
                -73.79641178399993,
                40.738325325999895
              ],
              [
                -73.79640218700004,
                40.73828235799987
              ],
              [
                -73.79611584799986,
                40.73833613999988
              ],
              [
                -73.79588681699978,
                40.73837637499989
              ],
              [
                -73.79533772999987,
                40.73849367299992
              ],
              [
                -73.79440801799996,
                40.738748229999906
              ],
              [
                -73.79369341399999,
                40.738950061999894
              ],
              [
                -73.79297881699995,
                40.73915188099988
              ],
              [
                -73.79291603199995,
                40.73916776099987
              ],
              [
                -73.79285797099996,
                40.73917973299996
              ],
              [
                -73.79202817599997,
                40.73940358099988
              ],
              [
                -73.7911152649998,
                40.73964983899986
              ],
              [
                -73.79020750099997,
                40.73989470299987
              ],
              [
                -73.78934727299989,
                40.74012633799989
              ],
              [
                -73.7886511029998,
                40.74032571399992
              ],
              [
                -73.78848243499986,
                40.7403682779999
              ],
              [
                -73.78759444399994,
                40.74061795799991
              ],
              [
                -73.78673516799984,
                40.74084177599985
              ],
              [
                -73.78669085300001,
                40.74085298599988
              ],
              [
                -73.78664844699985,
                40.74086674899993
              ],
              [
                -73.78577051299995,
                40.74111067999991
              ],
              [
                -73.78484244599998,
                40.74136852999993
              ],
              [
                -73.78400944099984,
                40.741599356999856
              ],
              [
                -73.78349697199984,
                40.74174135999994
              ],
              [
                -73.7831101189999,
                40.74184830499993
              ],
              [
                -73.78216009699989,
                40.742115548999905
              ],
              [
                -73.78176526799983,
                40.74221640599995
              ],
              [
                -73.78137698899997,
                40.7423199939999
              ],
              [
                -73.78121857699983,
                40.742362250999925
              ],
              [
                -73.78100566399995,
                40.74241954099993
              ],
              [
                -73.78068584999994,
                40.74250560699994
              ],
              [
                -73.78036603499996,
                40.74259166299987
              ],
              [
                -73.77965641299991,
                40.742729309999895
              ],
              [
                -73.778599248,
                40.742908122999886
              ],
              [
                -73.77805835699986,
                40.74298648899991
              ],
              [
                -73.77579713399984,
                40.743317665999946
              ],
              [
                -73.77573836999991,
                40.743325646999935
              ],
              [
                -73.77575892599995,
                40.74336722699992
              ],
              [
                -73.77585237799993,
                40.74356469299995
              ],
              [
                -73.77596627999999,
                40.743810447999884
              ],
              [
                -73.77616785699996,
                40.74424002299992
              ],
              [
                -73.77679196999986,
                40.74554428899989
              ],
              [
                -73.77753347599982,
                40.74714834199988
              ],
              [
                -73.77831180799991,
                40.748745013999944
              ],
              [
                -73.77906558899984,
                40.75033257399993
              ],
              [
                -73.77944066999987,
                40.75112269599993
              ],
              [
                -73.77980639299983,
                40.75189500499989
              ],
              [
                -73.78009357400003,
                40.7525075039999
              ],
              [
                -73.78052037799992,
                40.753330742999886
              ],
              [
                -73.78113322699984,
                40.7540893259999
              ],
              [
                -73.78165171799995,
                40.754707752999956
              ],
              [
                -73.78212461999979,
                40.75533931199993
              ],
              [
                -73.7824813079998,
                40.75599745499987
              ],
              [
                -73.78264852999988,
                40.75633190399988
              ],
              [
                -73.78281576399985,
                40.75666634299991
              ],
              [
                -73.78303191299983,
                40.75708319099991
              ],
              [
                -73.78316157699985,
                40.75732618499991
              ],
              [
                -73.78338071499984,
                40.75775389699993
              ],
              [
                -73.78346111099987,
                40.757864063999904
              ],
              [
                -73.78382076099993,
                40.75853003399991
              ],
              [
                -73.78407792999997,
                40.75903964399988
              ],
              [
                -73.78425940899996,
                40.75938264599993
              ],
              [
                -73.78428991899993,
                40.75944029899991
              ],
              [
                -73.78430628699978,
                40.759483644999904
              ],
              [
                -73.78447529299982,
                40.75987586199995
              ],
              [
                -73.78467742500003,
                40.76025720799989
              ],
              [
                -73.78486059299999,
                40.76064436299992
              ],
              [
                -73.78502434699996,
                40.76103651599987
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0000607235737749,
        "objectid": 24,
        "shape_leng": 0.0469999619287,
        "location_id": 24,
        "zone": "Bloomingdale",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.95953658899997,
                40.7987185259999
              ],
              [
                -73.95907669099992,
                40.79935223299986
              ],
              [
                -73.95861879299989,
                40.79997702599996
              ],
              [
                -73.95833304999988,
                40.80036505399989
              ],
              [
                -73.95817297099987,
                40.800582540999876
              ],
              [
                -73.95848111500001,
                40.800670477999894
              ],
              [
                -73.95964685399987,
                40.80115642299993
              ],
              [
                -73.96107793999975,
                40.801800357999866
              ],
              [
                -73.96386126299988,
                40.80297203799993
              ],
              [
                -73.96659731599986,
                40.804122764999946
              ],
              [
                -73.96670106899995,
                40.80416847599993
              ],
              [
                -73.96680327299997,
                40.804207560999906
              ],
              [
                -73.96804849299988,
                40.804683699999906
              ],
              [
                -73.96849506699984,
                40.80483226999993
              ],
              [
                -73.96855913399985,
                40.80485358399993
              ],
              [
                -73.96860707399988,
                40.804870949999895
              ],
              [
                -73.96978722000001,
                40.80530049699997
              ],
              [
                -73.97069326499995,
                40.80563026799993
              ],
              [
                -73.97076362299994,
                40.80565737299987
              ],
              [
                -73.97084148399995,
                40.80568534399987
              ],
              [
                -73.97098767999995,
                40.805742902999974
              ],
              [
                -73.97110765876137,
                40.805790139589654
              ],
              [
                -73.9712908330033,
                40.805528707533185
              ],
              [
                -73.97144210961531,
                40.80533442683979
              ],
              [
                -73.97161113391445,
                40.80509618747434
              ],
              [
                -73.9716204956932,
                40.80504229439937
              ],
              [
                -73.97171200727101,
                40.80493169127652
              ],
              [
                -73.97179980150743,
                40.80478561548601
              ],
              [
                -73.97181849483539,
                40.80471754320449
              ],
              [
                -73.9719529243853,
                40.804619709051586
              ],
              [
                -73.97205095521862,
                40.80453037600999
              ],
              [
                -73.97217237241792,
                40.80431907695909
              ],
              [
                -73.97255429829633,
                40.80379863465013
              ],
              [
                -73.97287179090726,
                40.803356187573904
              ],
              [
                -73.97279907800002,
                40.803329158999894
              ],
              [
                -73.9726574479999,
                40.803276513999926
              ],
              [
                -73.97257779799996,
                40.803247183999915
              ],
              [
                -73.97250022199995,
                40.80321661299997
              ],
              [
                -73.97150381000002,
                40.80283773599996
              ],
              [
                -73.97032589799987,
                40.80238456099995
              ],
              [
                -73.97027400499987,
                40.802359035999885
              ],
              [
                -73.97021594799989,
                40.80233585099994
              ],
              [
                -73.97004742199995,
                40.80226500999991
              ],
              [
                -73.96858360799983,
                40.80163546999991
              ],
              [
                -73.96798415999996,
                40.80139826599985
              ],
              [
                -73.96787379699998,
                40.80135169799993
              ],
              [
                -73.96775900399992,
                40.80130351599994
              ],
              [
                -73.96571144299992,
                40.80043806999988
              ],
              [
                -73.96286980099991,
                40.799239676999946
              ],
              [
                -73.96147779999993,
                40.79865415599994
              ],
              [
                -73.96004456499999,
                40.79804123499991
              ],
              [
                -73.95953658899997,
                40.7987185259999
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000435823818081,
        "objectid": 10,
        "shape_leng": 0.0998394794152,
        "location_id": 10,
        "zone": "Baisley Park",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.78326624999988,
                40.68999429299992
              ],
              [
                -73.78409903999982,
                40.68964775499989
              ],
              [
                -73.78479240699981,
                40.68936159999986
              ],
              [
                -73.78580499899994,
                40.688996896999896
              ],
              [
                -73.78643950599998,
                40.688781709999894
              ],
              [
                -73.78698344499996,
                40.68860847699995
              ],
              [
                -73.78764209899992,
                40.68843484999997
              ],
              [
                -73.78867911999995,
                40.68818018399991
              ],
              [
                -73.78920529899993,
                40.68805321299991
              ],
              [
                -73.78962631299986,
                40.68793905899987
              ],
              [
                -73.79038468299986,
                40.68775361399988
              ],
              [
                -73.79210699399982,
                40.68704753599997
              ],
              [
                -73.79248043499994,
                40.68688962999991
              ],
              [
                -73.79317220799987,
                40.68659668599987
              ],
              [
                -73.793908417,
                40.68630469099986
              ],
              [
                -73.79437087299995,
                40.68691258199987
              ],
              [
                -73.79477837399985,
                40.687470683999955
              ],
              [
                -73.79521094899988,
                40.688094665999905
              ],
              [
                -73.79561486799987,
                40.68865689099992
              ],
              [
                -73.7966293329999,
                40.68822145199995
              ],
              [
                -73.79746057199985,
                40.6878723019999
              ],
              [
                -73.79828588299998,
                40.68752401899994
              ],
              [
                -73.79913531199985,
                40.68718195799991
              ],
              [
                -73.79995042799996,
                40.68682771899991
              ],
              [
                -73.79898209099989,
                40.68485873699989
              ],
              [
                -73.79943031399989,
                40.68473198799994
              ],
              [
                -73.79986119199985,
                40.68461014299996
              ],
              [
                -73.80077394899995,
                40.68434992799991
              ],
              [
                -73.80168572400002,
                40.68409195599986
              ],
              [
                -73.80256430899988,
                40.683842438999875
              ],
              [
                -73.80344528099988,
                40.68359185699995
              ],
              [
                -73.80432351099998,
                40.68334179499986
              ],
              [
                -73.80519209299995,
                40.68309605799989
              ],
              [
                -73.80568769599995,
                40.68295563299994
              ],
              [
                -73.80576840199991,
                40.68293239099994
              ],
              [
                -73.80551811199996,
                40.68240820999994
              ],
              [
                -73.80522682799987,
                40.68179814999995
              ],
              [
                -73.80492976299995,
                40.68117697399989
              ],
              [
                -73.80464517099982,
                40.68057988999991
              ],
              [
                -73.80428288199991,
                40.679817355999916
              ],
              [
                -73.80410226599996,
                40.67942454999991
              ],
              [
                -73.80323027699988,
                40.677681056999894
              ],
              [
                -73.8030752049999,
                40.67734560099989
              ],
              [
                -73.80267769599996,
                40.67649796699987
              ],
              [
                -73.80238906400001,
                40.67588847899992
              ],
              [
                -73.80202521799998,
                40.67511427299995
              ],
              [
                -73.80168194799982,
                40.67438384799994
              ],
              [
                -73.80142836799988,
                40.6736668339999
              ],
              [
                -73.80140210699993,
                40.673561318999894
              ],
              [
                -73.80130166499995,
                40.673121521999896
              ],
              [
                -73.801194616,
                40.67242246499994
              ],
              [
                -73.80117234299993,
                40.6722050319999
              ],
              [
                -73.80115934999986,
                40.67189388999986
              ],
              [
                -73.80115538600003,
                40.67179912099987
              ],
              [
                -73.80114238199995,
                40.67148794299991
              ],
              [
                -73.80113697299981,
                40.67135852899988
              ],
              [
                -73.8011343009999,
                40.671294461999906
              ],
              [
                -73.80113236199993,
                40.67054866799994
              ],
              [
                -73.80116000899989,
                40.66980042099991
              ],
              [
                -73.80117909099994,
                40.66910026499992
              ],
              [
                -73.80120616599989,
                40.66894442399994
              ],
              [
                -73.80125079599999,
                40.668385944999976
              ],
              [
                -73.80134286799985,
                40.667773796999896
              ],
              [
                -73.80141266299994,
                40.66735840999995
              ],
              [
                -73.80153052599994,
                40.666837158999954
              ],
              [
                -73.80155565300004,
                40.666753363999916
              ],
              [
                -73.80148383499996,
                40.666750151999935
              ],
              [
                -73.80139045299981,
                40.666746658999955
              ],
              [
                -73.80102008199994,
                40.66672907999992
              ],
              [
                -73.80027044399993,
                40.66666752699987
              ],
              [
                -73.79968140399984,
                40.66656547099993
              ],
              [
                -73.79942279899993,
                40.66652066399994
              ],
              [
                -73.79919289299993,
                40.66651807499988
              ],
              [
                -73.79896198599992,
                40.666529485999874
              ],
              [
                -73.79873226699989,
                40.66655498299989
              ],
              [
                -73.79850596999992,
                40.66659438099988
              ],
              [
                -73.79828522699991,
                40.66664725099994
              ],
              [
                -73.798072072,
                40.66671288499994
              ],
              [
                -73.79739484699991,
                40.6669398859999
              ],
              [
                -73.79711068999998,
                40.66694966899995
              ],
              [
                -73.7965798539999,
                40.666942991999896
              ],
              [
                -73.79604894599989,
                40.666942792999855
              ],
              [
                -73.79551810099989,
                40.666949071999916
              ],
              [
                -73.7949874579999,
                40.666961826999966
              ],
              [
                -73.79445715,
                40.66698105599994
              ],
              [
                -73.79392731599994,
                40.667006752999946
              ],
              [
                -73.79319502099987,
                40.66705844699985
              ],
              [
                -73.79306694299999,
                40.667067293999956
              ],
              [
                -73.79218446699987,
                40.667041888999904
              ],
              [
                -73.79129821899986,
                40.66697078499991
              ],
              [
                -73.79039544099997,
                40.666887703999905
              ],
              [
                -73.78940999399991,
                40.66684115799992
              ],
              [
                -73.78915781999983,
                40.66833809599989
              ],
              [
                -73.7888310799999,
                40.6698234859999
              ],
              [
                -73.7884762149999,
                40.67132650099996
              ],
              [
                -73.78808647399985,
                40.6730096269999
              ],
              [
                -73.78686902099984,
                40.67282392399988
              ],
              [
                -73.78573730899993,
                40.67238503499988
              ],
              [
                -73.78532371499995,
                40.673125020999926
              ],
              [
                -73.78511037999995,
                40.673478363999905
              ],
              [
                -73.78484333899993,
                40.67390332299989
              ],
              [
                -73.78434496299991,
                40.674696439999906
              ],
              [
                -73.78416807199993,
                40.674911033999926
              ],
              [
                -73.78397384099986,
                40.67511760899994
              ],
              [
                -73.78397363999976,
                40.67511780699991
              ],
              [
                -73.78397345,
                40.675117994999944
              ],
              [
                -73.78376278399993,
                40.67531538999989
              ],
              [
                -73.78376261799986,
                40.67531553299987
              ],
              [
                -73.78376243999988,
                40.67531568599992
              ],
              [
                -73.783536133,
                40.675503090999904
              ],
              [
                -73.7832954899999,
                40.67567944499991
              ],
              [
                -73.78304214600001,
                40.67584371699991
              ],
              [
                -73.782218084,
                40.676437536999906
              ],
              [
                -73.78157185499998,
                40.676901330999975
              ],
              [
                -73.78128277899985,
                40.67710972999991
              ],
              [
                -73.7809512629998,
                40.677353507999946
              ],
              [
                -73.78025859499995,
                40.67784092399991
              ],
              [
                -73.77947817299986,
                40.67836454999986
              ],
              [
                -73.7793120879999,
                40.6784368039999
              ],
              [
                -73.77841935399988,
                40.6790308799999
              ],
              [
                -73.77820864799986,
                40.67918207599993
              ],
              [
                -73.77798227199999,
                40.679321092999906
              ],
              [
                -73.7777421129999,
                40.67944657599985
              ],
              [
                -73.77749031599994,
                40.67955740299992
              ],
              [
                -73.777229236,
                40.679652720999904
              ],
              [
                -73.77636248100002,
                40.679935908999916
              ],
              [
                -73.77549588399995,
                40.680219045999934
              ],
              [
                -73.77475342099996,
                40.680467280999935
              ],
              [
                -73.77469730199986,
                40.680486045999906
              ],
              [
                -73.77408130199987,
                40.68076015399987
              ],
              [
                -73.77356539499996,
                40.68096392599992
              ],
              [
                -73.77299539399996,
                40.6812088249999
              ],
              [
                -73.77232211299984,
                40.681490967999935
              ],
              [
                -73.77220275299987,
                40.68161033899991
              ],
              [
                -73.77256209599992,
                40.682209496999846
              ],
              [
                -73.77297749099996,
                40.68293379299996
              ],
              [
                -73.77328703999997,
                40.68345473099989
              ],
              [
                -73.773499153,
                40.68348222699996
              ],
              [
                -73.77418414899984,
                40.68323792099991
              ],
              [
                -73.77441975399975,
                40.683653521999915
              ],
              [
                -73.7745579039998,
                40.683885621999906
              ],
              [
                -73.77462061100005,
                40.683990969999854
              ],
              [
                -73.77501231399985,
                40.68427557899985
              ],
              [
                -73.77567289899983,
                40.68474158899993
              ],
              [
                -73.77632402199986,
                40.6852165559999
              ],
              [
                -73.7767991239999,
                40.68596846499985
              ],
              [
                -73.77697494099996,
                40.686246713999935
              ],
              [
                -73.77699767599997,
                40.6862826889999
              ],
              [
                -73.77784971099992,
                40.68598927299988
              ],
              [
                -73.77870794599994,
                40.685700419999876
              ],
              [
                -73.77957347199998,
                40.68541503299992
              ],
              [
                -73.77966850599998,
                40.68537712299996
              ],
              [
                -73.77976509199979,
                40.68574360199992
              ],
              [
                -73.78051569899989,
                40.687036519999914
              ],
              [
                -73.78148675699995,
                40.6886971829999
              ],
              [
                -73.78234452799988,
                40.68840743699995
              ],
              [
                -73.78326624999988,
                40.68999429299992
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.00026452053504,
        "objectid": 11,
        "shape_leng": 0.0792110389596,
        "location_id": 11,
        "zone": "Bath Beach",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -74.00109809499993,
                40.60303462599993
              ],
              [
                -74.00184603099991,
                40.603431431999866
              ],
              [
                -74.00263069599994,
                40.60384644899991
              ],
              [
                -74.00340902699993,
                40.60425938099994
              ],
              [
                -74.00416249599996,
                40.604654738999926
              ],
              [
                -74.00444636799998,
                40.604801846999855
              ],
              [
                -74.00493448899992,
                40.60507440599987
              ],
              [
                -74.00442477199992,
                40.60556859699992
              ],
              [
                -74.00264412399989,
                40.60725717499991
              ],
              [
                -74.00195011999993,
                40.60781768299991
              ],
              [
                -74.00171808600001,
                40.60800297799994
              ],
              [
                -74.00130866099992,
                40.60834356899987
              ],
              [
                -74.00094408399997,
                40.608639709999885
              ],
              [
                -74.00185301899995,
                40.60913755999993
              ],
              [
                -74.00266468099994,
                40.60956996599991
              ],
              [
                -74.00342538899996,
                40.60996909299993
              ],
              [
                -74.00601438399993,
                40.61132508199995
              ],
              [
                -74.00708800599995,
                40.611907944999885
              ],
              [
                -74.00788398499994,
                40.61234007199987
              ],
              [
                -74.00830383399986,
                40.612567999999925
              ],
              [
                -74.01019858699995,
                40.61371216699994
              ],
              [
                -74.01050403599997,
                40.613896610999866
              ],
              [
                -74.01108648099991,
                40.61333904399986
              ],
              [
                -74.01171201599988,
                40.61273502899992
              ],
              [
                -74.01348255899993,
                40.61103739799989
              ],
              [
                -74.01522379399994,
                40.609356383999916
              ],
              [
                -74.01701910199995,
                40.60765441699993
              ],
              [
                -74.0162363669999,
                40.60723326599992
              ],
              [
                -74.01549854999998,
                40.606841523999925
              ],
              [
                -74.01640553099996,
                40.605975924999875
              ],
              [
                -74.01671283899998,
                40.60568263499989
              ],
              [
                -74.01728366699997,
                40.60513782499991
              ],
              [
                -74.01746711399997,
                40.60496510399988
              ],
              [
                -74.0175086849999,
                40.604850496999866
              ],
              [
                -74.01804702499993,
                40.60436573499993
              ],
              [
                -74.01811904999991,
                40.60431522299989
              ],
              [
                -74.01817646699998,
                40.604252608999936
              ],
              [
                -74.01821458599989,
                40.604181957999884
              ],
              [
                -74.01823145099998,
                40.60410847299991
              ],
              [
                -74.018660218,
                40.60364106799991
              ],
              [
                -74.01869135799996,
                40.603607923999924
              ],
              [
                -74.01871382199994,
                40.60358207499995
              ],
              [
                -74.01917685099995,
                40.603154421999875
              ],
              [
                -74.01918088999996,
                40.60314959499995
              ],
              [
                -74.01923150299992,
                40.60310217399993
              ],
              [
                -74.01939343499993,
                40.60291176099992
              ],
              [
                -74.01942760861903,
                40.60287157842528
              ],
              [
                -74.01932826647813,
                40.60281080817145
              ],
              [
                -74.01913408409737,
                40.602648553051495
              ],
              [
                -74.0191356390289,
                40.602612754504946
              ],
              [
                -74.01832347216846,
                40.602417651130494
              ],
              [
                -74.01829286301476,
                40.60244735523035
              ],
              [
                -74.01799227936888,
                40.60249195235379
              ],
              [
                -74.0175907796064,
                40.60250301899235
              ],
              [
                -74.01723483086067,
                40.602475457664205
              ],
              [
                -74.01687994353071,
                40.60244084737738
              ],
              [
                -74.0165263578993,
                40.60239921156566
              ],
              [
                -74.01617431336636,
                40.6023505784194
              ],
              [
                -74.0158240482871,
                40.60229498086631
              ],
              [
                -74.0153028898328,
                40.60219124627411
              ],
              [
                -74.01478363716735,
                40.6020821029708
              ],
              [
                -74.01394137729592,
                40.601835344373335
              ],
              [
                -74.0130216004983,
                40.60156586710369
              ],
              [
                -74.01299887033369,
                40.60155920754662
              ],
              [
                -74.01236981602695,
                40.6013492571162
              ],
              [
                -74.01206343136853,
                40.60121952230082
              ],
              [
                -74.0118728222616,
                40.601138808863034
              ],
              [
                -74.0116363253687,
                40.601042057154
              ],
              [
                -74.01140200242962,
                40.60094228021335
              ],
              [
                -74.01115036636867,
                40.60083066431848
              ],
              [
                -74.01090145053502,
                40.600715559854
              ],
              [
                -74.01065533816518,
                40.600597005317034
              ],
              [
                -74.01041238886523,
                40.60047518174622
              ],
              [
                -74.01017239979777,
                40.600349996195334
              ],
              [
                -74.00993545102718,
                40.60022149043687
              ],
              [
                -74.00977479602606,
                40.60013149053111
              ],
              [
                -74.00961563255356,
                40.60003996413903
              ],
              [
                -74.00921723904898,
                40.59984404515234
              ],
              [
                -74.00848409142559,
                40.59939145864219
              ],
              [
                -74.00774585717092,
                40.59893572292123
              ],
              [
                -74.00552621986131,
                40.5975391540707
              ],
              [
                -74.00363401947315,
                40.59608059924293
              ],
              [
                -74.00345875511843,
                40.59592468401366
              ],
              [
                -74.00328997205449,
                40.5957646679181
              ],
              [
                -74.00312783528081,
                40.59560070742998
              ],
              [
                -74.00297250329423,
                40.595432962878775
              ],
              [
                -74.0028241279343,
                40.59526159829329
              ],
              [
                -74.0026828542343,
                40.59508678124065
              ],
              [
                -74.00254882028027,
                40.59490868266267
              ],
              [
                -74.00242215707556,
                40.59472747670866
              ],
              [
                -74.00230298841333,
                40.594543340564904
              ],
              [
                -74.00219143075543,
                40.59435645428151
              ],
              [
                -74.00200394754667,
                40.594132371137796
              ],
              [
                -74.00027871999994,
                40.595702668999905
              ],
              [
                -74.00004233899988,
                40.59594037099991
              ],
              [
                -73.999997695,
                40.59596779199991
              ],
              [
                -73.99996502999996,
                40.59599430299989
              ],
              [
                -73.99951049399985,
                40.59644180899987
              ],
              [
                -73.99931921399997,
                40.596640750999924
              ],
              [
                -73.99865586599988,
                40.59726856199988
              ],
              [
                -73.99733919599988,
                40.5985516119999
              ],
              [
                -73.99559320799995,
                40.6002322219999
              ],
              [
                -73.9963651649999,
                40.600696053999904
              ],
              [
                -73.99708036399986,
                40.60112882899989
              ],
              [
                -73.99782332499991,
                40.601578178999915
              ],
              [
                -73.998563851,
                40.60202843399991
              ],
              [
                -73.99927902000003,
                40.60245801699991
              ],
              [
                -74.00002266099992,
                40.60290731599988
              ],
              [
                -74.00032353799993,
                40.60262029199992
              ],
              [
                -74.00109809499993,
                40.60303462599993
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0000415116236727,
        "objectid": 12,
        "shape_leng": 0.0366613013579,
        "location_id": 12,
        "zone": "Battery Park",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -74.01565756599994,
                40.704833087999944
              ],
              [
                -74.01565582199999,
                40.704882633999915
              ],
              [
                -74.01587396199992,
                40.70488211299991
              ],
              [
                -74.01621736899986,
                40.70485302699991
              ],
              [
                -74.01629888499991,
                40.70484641499997
              ],
              [
                -74.01637519799986,
                40.704838484999925
              ],
              [
                -74.01652040399995,
                40.7047997179999
              ],
              [
                -74.01662761199991,
                40.704799430999856
              ],
              [
                -74.01668613099999,
                40.70479933799992
              ],
              [
                -74.01668714699993,
                40.70474394199987
              ],
              [
                -74.01675906699995,
                40.70474228299986
              ],
              [
                -74.01684600699993,
                40.70463488599989
              ],
              [
                -74.01713123899995,
                40.70428253499989
              ],
              [
                -74.01757035703022,
                40.70418388099316
              ],
              [
                -74.01762657963023,
                40.70402350082161
              ],
              [
                -74.01763399890548,
                40.70402475178324
              ],
              [
                -74.01764390293607,
                40.70402224590195
              ],
              [
                -74.01765461989744,
                40.70400968256976
              ],
              [
                -74.01765378898192,
                40.70399838081256
              ],
              [
                -74.01764306532696,
                40.70398897346139
              ],
              [
                -74.01765792483437,
                40.70393266178636
              ],
              [
                -74.01766578442252,
                40.703899233718545
              ],
              [
                -74.0176742874818,
                40.70383436757153
              ],
              [
                -74.01767689629425,
                40.70377050363851
              ],
              [
                -74.01767264581422,
                40.70371179104597
              ],
              [
                -74.01768310568107,
                40.70371113122529
              ],
              [
                -74.01767132020255,
                40.70360642738378
              ],
              [
                -74.01766881307267,
                40.70359131346763
              ],
              [
                -74.01766045074616,
                40.70354997909756
              ],
              [
                -74.0176479750685,
                40.70349459987955
              ],
              [
                -74.01762601904944,
                40.703422761227074
              ],
              [
                -74.01761681217123,
                40.70342324846539
              ],
              [
                -74.0175964796706,
                40.70336138730909
              ],
              [
                -74.01757548561497,
                40.703307503056024
              ],
              [
                -74.01754038297949,
                40.70323616042989
              ],
              [
                -74.01749381162765,
                40.703154843460084
              ],
              [
                -74.01744003546047,
                40.70307751454187
              ],
              [
                -74.01739839332275,
                40.703019140804905
              ],
              [
                -74.01731837763397,
                40.702918871771594
              ],
              [
                -74.01729346221033,
                40.70288743870684
              ],
              [
                -74.01724571591123,
                40.70283845773918
              ],
              [
                -74.01725182133946,
                40.70283555726377
              ],
              [
                -74.01714508062653,
                40.70270534997616
              ],
              [
                -74.01713705889382,
                40.702711340267676
              ],
              [
                -74.01700396789508,
                40.702554535627506
              ],
              [
                -74.01689657041247,
                40.70242187995947
              ],
              [
                -74.01681859599911,
                40.70233857508047
              ],
              [
                -74.01653258703746,
                40.70203653968305
              ],
              [
                -74.01651515260784,
                40.70201729867141
              ],
              [
                -74.01639205370195,
                40.701897701008235
              ],
              [
                -74.0162444906067,
                40.70175272143678
              ],
              [
                -74.01613890635998,
                40.70164601710269
              ],
              [
                -74.01611609060468,
                40.701630801625356
              ],
              [
                -74.01591034233417,
                40.7014609484439
              ],
              [
                -74.01579351711148,
                40.701346905807576
              ],
              [
                -74.01567778184732,
                40.70125602102743
              ],
              [
                -74.0151423181209,
                40.700842892354245
              ],
              [
                -74.01502113661684,
                40.700992504013406
              ],
              [
                -74.01482834225268,
                40.70089474286969
              ],
              [
                -74.01481072560155,
                40.700903831982195
              ],
              [
                -74.014673656285,
                40.70083460953097
              ],
              [
                -74.01470265033873,
                40.700799116025635
              ],
              [
                -74.01472681782191,
                40.700774015849596
              ],
              [
                -74.01482004284685,
                40.700660619780244
              ],
              [
                -74.0148859896547,
                40.700580114435745
              ],
              [
                -74.01494199155965,
                40.700523843325755
              ],
              [
                -74.01493687947055,
                40.70052297938124
              ],
              [
                -74.01492379463954,
                40.700530338707146
              ],
              [
                -74.0149175465081,
                40.70052557571269
              ],
              [
                -74.01492322289359,
                40.70051519147152
              ],
              [
                -74.01494255505821,
                40.700506103291325
              ],
              [
                -74.01494881182721,
                40.70049441360121
              ],
              [
                -74.01497495555579,
                40.70047017772347
              ],
              [
                -74.01496757071692,
                40.70046541487965
              ],
              [
                -74.01499314575906,
                40.700436847319885
              ],
              [
                -74.0150085051725,
                40.70043770992608
              ],
              [
                -74.01502271060043,
                40.70041953605862
              ],
              [
                -74.01503579735073,
                40.70042083101055
              ],
              [
                -74.0150983227013,
                40.70033426552348
              ],
              [
                -74.0150681826931,
                40.70031825755493
              ],
              [
                -74.01499883053579,
                40.7004061205765
              ],
              [
                -74.01493032611235,
                40.70047926986344
              ],
              [
                -74.01485926659423,
                40.70055544464552
              ],
              [
                -74.01481236514043,
                40.70061084993609
              ],
              [
                -74.01477142439134,
                40.70066365190685
              ],
              [
                -74.01472424834412,
                40.70071429389424
              ],
              [
                -74.01464323234902,
                40.70081730432509
              ],
              [
                -74.01432384363005,
                40.700661304127145
              ],
              [
                -74.01439480693733,
                40.70057047909727
              ],
              [
                -74.01439689988958,
                40.70056348189321
              ],
              [
                -74.01440947394667,
                40.70055133141232
              ],
              [
                -74.01447857931363,
                40.70046208215578
              ],
              [
                -74.01448492126369,
                40.70046115359205
              ],
              [
                -74.01449246893418,
                40.700454669002845
              ],
              [
                -74.01449342549978,
                40.70044516925066
              ],
              [
                -74.01455219325993,
                40.70038298075156
              ],
              [
                -74.01460825448504,
                40.70032365711382
              ],
              [
                -74.01460863003733,
                40.70030970824581
              ],
              [
                -74.01465386124688,
                40.700242641175116
              ],
              [
                -74.01466717213702,
                40.70023938090731
              ],
              [
                -74.01467119992019,
                40.700210401699714
              ],
              [
                -74.01463294333476,
                40.700197430412395
              ],
              [
                -74.01458234409432,
                40.70028387552862
              ],
              [
                -74.01458425440417,
                40.70030846895508
              ],
              [
                -74.01457513332028,
                40.700316916331225
              ],
              [
                -74.01455999973689,
                40.70030978677959
              ],
              [
                -74.01444813150715,
                40.700447372399886
              ],
              [
                -74.01436783583218,
                40.7005461226675
              ],
              [
                -74.01431796468076,
                40.70061249730781
              ],
              [
                -74.01431530264765,
                40.70061103797418
              ],
              [
                -74.0142965168929,
                40.700629186523706
              ],
              [
                -74.01430157023238,
                40.70063221177271
              ],
              [
                -74.01428920741124,
                40.700644144765086
              ],
              [
                -74.014152387873,
                40.70057786578352
              ],
              [
                -74.01416495763299,
                40.70053905203082
              ],
              [
                -74.01417787931776,
                40.70054147355369
              ],
              [
                -74.01430981586151,
                40.70013410151565
              ],
              [
                -74.01430981311289,
                40.70012129606874
              ],
              [
                -74.014220631955,
                40.700111491868306
              ],
              [
                -74.01417467279443,
                40.70026089129383
              ],
              [
                -74.01414860637784,
                40.70032057172371
              ],
              [
                -74.0141372638859,
                40.7003949552103
              ],
              [
                -74.01412821190817,
                40.70039328205465
              ],
              [
                -74.01409144949483,
                40.70050833672693
              ],
              [
                -74.01410490300086,
                40.70051082838692
              ],
              [
                -74.0140919838548,
                40.70055161832718
              ],
              [
                -74.01395152778244,
                40.70099098222994
              ],
              [
                -74.01415893499998,
                40.701096289999896
              ],
              [
                -74.01376867799992,
                40.7011432629999
              ],
              [
                -74.01377528899992,
                40.7011827129999
              ],
              [
                -74.01378191199989,
                40.70122215499992
              ],
              [
                -74.01379120799997,
                40.70130547599995
              ],
              [
                -74.01389890399994,
                40.70227066999996
              ],
              [
                -74.014192151,
                40.70275671099996
              ],
              [
                -74.01442983199998,
                40.70315064799989
              ],
              [
                -74.014391769,
                40.70359878299993
              ],
              [
                -74.01425523899994,
                40.70442547199991
              ],
              [
                -74.01425342599994,
                40.70445756399993
              ],
              [
                -74.01425861399994,
                40.70448944299991
              ],
              [
                -74.01427065899998,
                40.70452022899989
              ],
              [
                -74.01428922899998,
                40.70454907499989
              ],
              [
                -74.014370312,
                40.70455803799994
              ],
              [
                -74.01451712299998,
                40.704605674999854
              ],
              [
                -74.0146670989999,
                40.704647212999916
              ],
              [
                -74.01478059499995,
                40.70467411299992
              ],
              [
                -74.01489542099996,
                40.70469751799991
              ],
              [
                -74.01496125199992,
                40.70475979699995
              ],
              [
                -74.01516257,
                40.704805094999884
              ],
              [
                -74.01527219799995,
                40.70483003699998
              ],
              [
                -74.01539758699995,
                40.704800148999894
              ],
              [
                -74.01565756599994,
                40.704833087999944
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000149358592917,
        "objectid": 13,
        "shape_leng": 0.0502813228631,
        "location_id": 13,
        "zone": "Battery Park City",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -74.01244109299991,
                40.7190576729999
              ],
              [
                -74.01255468899996,
                40.71906986299999
              ],
              [
                -74.01270167899996,
                40.71909177499987
              ],
              [
                -74.01303068113208,
                40.71913145590955
              ],
              [
                -74.013072377692,
                40.718938618670066
              ],
              [
                -74.01306805492322,
                40.71892219382553
              ],
              [
                -74.01321322719183,
                40.718315719895806
              ],
              [
                -74.01470270021802,
                40.71851615488417
              ],
              [
                -74.01494515549334,
                40.718543535077174
              ],
              [
                -74.01497036258002,
                40.718535850864214
              ],
              [
                -74.01498333230728,
                40.71852379143297
              ],
              [
                -74.0149898133956,
                40.718507347327694
              ],
              [
                -74.01499619731617,
                40.71847300131666
              ],
              [
                -74.01500060682406,
                40.71844923605504
              ],
              [
                -74.01555087754495,
                40.71852193318384
              ],
              [
                -74.01567062060764,
                40.718537748736196
              ],
              [
                -74.01566883666864,
                40.718554489265706
              ],
              [
                -74.01594382984199,
                40.7185892550442
              ],
              [
                -74.01594695204268,
                40.718575548906266
              ],
              [
                -74.01662996338182,
                40.71866536845022
              ],
              [
                -74.01665946969602,
                40.71866602122465
              ],
              [
                -74.0166941909593,
                40.718648843354394
              ],
              [
                -74.01671018605829,
                40.71862417605798
              ],
              [
                -74.0168629737726,
                40.717786954287845
              ],
              [
                -74.01698274294627,
                40.71713064366816
              ],
              [
                -74.01712122293634,
                40.716371793579704
              ],
              [
                -74.01726421509528,
                40.71559268236992
              ],
              [
                -74.01738223187851,
                40.71494446127923
              ],
              [
                -74.01740871360494,
                40.71480680040797
              ],
              [
                -74.01753721790737,
                40.714097542247806
              ],
              [
                -74.01761833310017,
                40.7136543239355
              ],
              [
                -74.01770687258355,
                40.713169282968664
              ],
              [
                -74.01772496219284,
                40.71307018162274
              ],
              [
                -74.01772305328981,
                40.7130671310526
              ],
              [
                -74.01772056477111,
                40.7130643328008
              ],
              [
                -74.01771755291756,
                40.713061850152904
              ],
              [
                -74.017714085846,
                40.71305973925727
              ],
              [
                -74.01771024196847,
                40.71305804785403
              ],
              [
                -74.01770610821879,
                40.713056814196534
              ],
              [
                -74.01770177808676,
                40.71305606618535
              ],
              [
                -74.0176969858353,
                40.7130558230432
              ],
              [
                -74.01769220518092,
                40.713056174320755
              ],
              [
                -74.0176875626216,
                40.71305711072315
              ],
              [
                -74.01768318100166,
                40.71305860747276
              ],
              [
                -74.01767917626104,
                40.71306062496484
              ],
              [
                -74.01767565436691,
                40.713063109815586
              ],
              [
                -74.01767270851062,
                40.713065996274715
              ],
              [
                -74.01767041664046,
                40.71306920796514
              ],
              [
                -74.01765509447189,
                40.71316106266698
              ],
              [
                -74.01764810719082,
                40.71320618595165
              ],
              [
                -74.01757889322138,
                40.71344274342788
              ],
              [
                -74.01756811041477,
                40.713523703477954
              ],
              [
                -74.01754443334657,
                40.71361122266428
              ],
              [
                -74.01691068695474,
                40.713496328512925
              ],
              [
                -74.01654114686625,
                40.713441052742326
              ],
              [
                -74.01632006627581,
                40.71340798247826
              ],
              [
                -74.01639169485804,
                40.713099997950714
              ],
              [
                -74.01647724204135,
                40.713029493073954
              ],
              [
                -74.01654112171721,
                40.71295556481935
              ],
              [
                -74.0165554889345,
                40.712929095504386
              ],
              [
                -74.0165914952051,
                40.712861563719784
              ],
              [
                -74.01661840078299,
                40.71275615002967
              ],
              [
                -74.01661817655314,
                40.71264976356907
              ],
              [
                -74.01659227582768,
                40.71254817107679
              ],
              [
                -74.01654463812528,
                40.71245603793092
              ],
              [
                -74.01662424425285,
                40.71215731899531
              ],
              [
                -74.01780037888389,
                40.71234512415861
              ],
              [
                -74.01772148946118,
                40.71278414511305
              ],
              [
                -74.01772021563546,
                40.71284473093366
              ],
              [
                -74.01773183836696,
                40.71284931234666
              ],
              [
                -74.01775292705896,
                40.712848327555946
              ],
              [
                -74.01777014254499,
                40.71283457478915
              ],
              [
                -74.01787333422654,
                40.71231514445642
              ],
              [
                -74.01792224257028,
                40.712039242700754
              ],
              [
                -74.01797853231488,
                40.71175631920802
              ],
              [
                -74.01804609687574,
                40.71134450148706
              ],
              [
                -74.01811057789173,
                40.711029628214455
              ],
              [
                -74.01814480361767,
                40.71088865018895
              ],
              [
                -74.01826409180671,
                40.71040899662683
              ],
              [
                -74.01834136609794,
                40.71010647694362
              ],
              [
                -74.01846882134515,
                40.7095895710087
              ],
              [
                -74.01863142468959,
                40.70894110005421
              ],
              [
                -74.01874689830457,
                40.70848058025358
              ],
              [
                -74.01885500911972,
                40.70804753444736
              ],
              [
                -74.01885347760366,
                40.70802201228401
              ],
              [
                -74.01883395951482,
                40.70800221914011
              ],
              [
                -74.0188319588392,
                40.70799916729172
              ],
              [
                -74.0188006306842,
                40.707980621637354
              ],
              [
                -74.01864689079073,
                40.70794681354695
              ],
              [
                -74.01865557049648,
                40.70792394888326
              ],
              [
                -74.01819029259642,
                40.70782162610999
              ],
              [
                -74.01821699384473,
                40.707751309768305
              ],
              [
                -74.01821726389763,
                40.70774220647172
              ],
              [
                -74.01840649088626,
                40.70725787215848
              ],
              [
                -74.01853033752076,
                40.706950732410355
              ],
              [
                -74.01853513269664,
                40.70691832201406
              ],
              [
                -74.01854892759579,
                40.70690827067913
              ],
              [
                -74.01856452356972,
                40.706906440109286
              ],
              [
                -74.01856933191749,
                40.70692059520576
              ],
              [
                -74.01857172609165,
                40.70693839792395
              ],
              [
                -74.01857952850992,
                40.706949814809064
              ],
              [
                -74.01859153053353,
                40.70695939491845
              ],
              [
                -74.01860294181306,
                40.7069749179781
              ],
              [
                -74.0186047441128,
                40.706989064177236
              ],
              [
                -74.01861254498962,
                40.70699728514591
              ],
              [
                -74.01862814488057,
                40.70701097846493
              ],
              [
                -74.0186479447413,
                40.70701781957846
              ],
              [
                -74.01867853372192,
                40.70701873300245
              ],
              [
                -74.0186947319076,
                40.707010967669895
              ],
              [
                -74.01872292981695,
                40.70700685750043
              ],
              [
                -74.01874212416382,
                40.70700776337878
              ],
              [
                -74.01877571598486,
                40.70699725864645
              ],
              [
                -74.01879851485624,
                40.706982188376166
              ],
              [
                -74.01881110035015,
                40.7069662117742
              ],
              [
                -74.01884889814508,
                40.70697031235022
              ],
              [
                -74.01884829724429,
                40.7069830904102
              ],
              [
                -74.01883870432944,
                40.70699496965149
              ],
              [
                -74.01882011914348,
                40.70701597288301
              ],
              [
                -74.01879432757899,
                40.70703241167227
              ],
              [
                -74.01877393087639,
                40.70704428278758
              ],
              [
                -74.01874993323987,
                40.707049762489504
              ],
              [
                -74.01876194037423,
                40.70707578586693
              ],
              [
                -74.01875054622958,
                40.70707989330999
              ],
              [
                -74.01872115249107,
                40.70708172614977
              ],
              [
                -74.01868695379919,
                40.70708400923754
              ],
              [
                -74.01864796262215,
                40.70708173802993
              ],
              [
                -74.01864437101163,
                40.707105475860516
              ],
              [
                -74.01863417023104,
                40.7071031988087
              ],
              [
                -74.01860717601117,
                40.7070954409752
              ],
              [
                -74.0185711739943,
                40.70707992238871
              ],
              [
                -74.01854718475336,
                40.707107770264166
              ],
              [
                -74.0185807840372,
                40.70712374810273
              ],
              [
                -74.01863058372689,
                40.707139715269285
              ],
              [
                -74.0186554807563,
                40.70714153912503
              ],
              [
                -74.01868966870465,
                40.70714244375395
              ],
              [
                -74.01872626987436,
                40.70714335568639
              ],
              [
                -74.0187610576787,
                40.70713786652502
              ],
              [
                -74.01879644936784,
                40.70712781984765
              ],
              [
                -74.01882943775024,
                40.70711548664006
              ],
              [
                -74.01885943006913,
                40.70710041568372
              ],
              [
                -74.01888462028889,
                40.70708580553678
              ],
              [
                -74.01890921570659,
                40.7070643424512
              ],
              [
                -74.01892660443798,
                40.70704425778585
              ],
              [
                -74.01893740557156,
                40.70703420689672
              ],
              [
                -74.01894579167332,
                40.707017770941384
              ],
              [
                -74.01895359463364,
                40.70699676946555
              ],
              [
                -74.01903338019694,
                40.7070131908761
              ],
              [
                -74.01906617140139,
                40.707013770621415
              ],
              [
                -74.01909155084701,
                40.7070044731845
              ],
              [
                -74.01910255488421,
                40.70700043764153
              ],
              [
                -74.019122759634,
                40.70697581433742
              ],
              [
                -74.0192224228398,
                40.70657308872602
              ],
              [
                -74.01934254624483,
                40.706093673029706
              ],
              [
                -74.01934155704531,
                40.706070538204024
              ],
              [
                -74.0189257238241,
                40.70477376257028
              ],
              [
                -74.01888746649365,
                40.70472914883739
              ],
              [
                -74.01886287526735,
                40.70471961588463
              ],
              [
                -74.01811296129115,
                40.70455372748718
              ],
              [
                -74.01806504689,
                40.704635960523234
              ],
              [
                -74.01805387593451,
                40.70462964047163
              ],
              [
                -74.01804288218334,
                40.70462817432251
              ],
              [
                -74.01802414308348,
                40.704645890908516
              ],
              [
                -74.01800797806544,
                40.70463851001487
              ],
              [
                -74.01799827820122,
                40.704649829833414
              ],
              [
                -74.01798922454327,
                40.704646877912715
              ],
              [
                -74.01797888053436,
                40.704640971684036
              ],
              [
                -74.01796918021806,
                40.704647367374776
              ],
              [
                -74.01794397538687,
                40.704648361727315
              ],
              [
                -74.01794138637455,
                40.70465573717841
              ],
              [
                -74.01791034926417,
                40.70465919223342
              ],
              [
                -74.01790194580029,
                40.70465180844167
              ],
              [
                -74.01783988433819,
                40.704661165086556
              ],
              [
                -74.01782889269187,
                40.70466510166719
              ],
              [
                -74.0178062672323,
                40.70466216121492
              ],
              [
                -74.01779785539696,
                40.70466707943758
              ],
              [
                -74.01778363303417,
                40.70467101601078
              ],
              [
                -74.01776682871572,
                40.70466511126367
              ],
              [
                -74.01776552858085,
                40.70467249656345
              ],
              [
                -74.01774937712388,
                40.704675451888505
              ],
              [
                -74.01773321304685,
                40.704671520004936
              ],
              [
                -74.01771834047513,
                40.704679887260674
              ],
              [
                -74.01768666268582,
                40.7046739859631
              ],
              [
                -74.01767567049036,
                40.704680875362584
              ],
              [
                -74.0176342910399,
                40.704678910417414
              ],
              [
                -74.01759804780863,
                40.704684480713354
              ],
              [
                -74.01756688836934,
                40.7046715987825
              ],
              [
                -74.01757365505411,
                40.70466799608357
              ],
              [
                -74.01757026976702,
                40.704662836429215
              ],
              [
                -74.01755808298708,
                40.70466593603326
              ],
              [
                -74.01754791723492,
                40.70466387515681
              ],
              [
                -74.01753166144591,
                40.70467109085019
              ],
              [
                -74.01748086152578,
                40.704667487696156
              ],
              [
                -74.01751639597092,
                40.70458084479339
              ],
              [
                -74.01865606299125,
                40.704440968684594
              ],
              [
                -74.01866830837966,
                40.70443524062125
              ],
              [
                -74.01844770243774,
                40.70416216823156
              ],
              [
                -74.01784441106724,
                40.7042420399379
              ],
              [
                -74.01795473982617,
                40.703975785608584
              ],
              [
                -74.01794269363918,
                40.703973176576255
              ],
              [
                -74.01791229326216,
                40.704054497150985
              ],
              [
                -74.01780821746945,
                40.70403196447961
              ],
              [
                -74.01783782825967,
                40.70396266613854
              ],
              [
                -74.01781758697946,
                40.70393792249663
              ],
              [
                -74.01779139303439,
                40.70395091258355
              ],
              [
                -74.01775054986874,
                40.70405488289344
              ],
              [
                -74.01785585682941,
                40.704078847615584
              ],
              [
                -74.017794545203,
                40.704234898101326
              ],
              [
                -74.01757035703022,
                40.70418388099316
              ],
              [
                -74.01713123899995,
                40.70428253499989
              ],
              [
                -74.01684600699993,
                40.70463488599989
              ],
              [
                -74.01675906699995,
                40.70474228299986
              ],
              [
                -74.01668714699993,
                40.70474394199987
              ],
              [
                -74.01668613099999,
                40.70479933799992
              ],
              [
                -74.01665417299996,
                40.7049638209999
              ],
              [
                -74.01662217899997,
                40.70509448299993
              ],
              [
                -74.01640305499988,
                40.70566620199991
              ],
              [
                -74.01625159599989,
                40.706072613999886
              ],
              [
                -74.01619519599998,
                40.70625848499991
              ],
              [
                -74.0160596679999,
                40.70670512999994
              ],
              [
                -74.01593869399987,
                40.70700757399988
              ],
              [
                -74.01590498299998,
                40.70709269499987
              ],
              [
                -74.01588574099995,
                40.707142175999934
              ],
              [
                -74.01586395999995,
                40.70720018299993
              ],
              [
                -74.01584985499994,
                40.70724726799988
              ],
              [
                -74.01571815199996,
                40.707585310999924
              ],
              [
                -74.01568829399997,
                40.70766382499988
              ],
              [
                -74.015643777,
                40.70775609899987
              ],
              [
                -74.01559220199994,
                40.70788324299987
              ],
              [
                -74.01534330799996,
                40.70849681699987
              ],
              [
                -74.01495415399995,
                40.70943269199994
              ],
              [
                -74.01487257099997,
                40.70968487399991
              ],
              [
                -74.01482432799997,
                40.70982336799991
              ],
              [
                -74.01466507099994,
                40.71042693799993
              ],
              [
                -74.01445559399995,
                40.71090715299987
              ],
              [
                -74.0142689369999,
                40.711467815999924
              ],
              [
                -74.01417753399997,
                40.71188266599997
              ],
              [
                -74.01404744699995,
                40.7124378149999
              ],
              [
                -74.01375388499997,
                40.71369055499988
              ],
              [
                -74.01359239999995,
                40.71449431599993
              ],
              [
                -74.01347524299993,
                40.715110710999895
              ],
              [
                -74.0133512149999,
                40.71523331199993
              ],
              [
                -74.01323384899992,
                40.715656187999855
              ],
              [
                -74.0130514639999,
                40.71645477099989
              ],
              [
                -74.01286663199994,
                40.71717610099995
              ],
              [
                -74.01281566799992,
                40.71735629799987
              ],
              [
                -74.01244109299991,
                40.7190576729999
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000148850163948,
        "objectid": 18,
        "shape_leng": 0.0697995498569,
        "location_id": 18,
        "zone": "Bedford Park",
        "borough": "Bronx"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.88513907699999,
                40.86638287399992
              ],
              [
                -73.88442861399994,
                40.86680037899987
              ],
              [
                -73.88362518100003,
                40.86725758799991
              ],
              [
                -73.88284356199999,
                40.86773622599992
              ],
              [
                -73.88188379899985,
                40.86832083599991
              ],
              [
                -73.88146889699983,
                40.86857364599993
              ],
              [
                -73.88174189599988,
                40.8695527139999
              ],
              [
                -73.88197649899989,
                40.87038728399996
              ],
              [
                -73.88222049399987,
                40.87122724799985
              ],
              [
                -73.88246984299988,
                40.87212868499994
              ],
              [
                -73.8825014509999,
                40.872293623999944
              ],
              [
                -73.8825014739999,
                40.872293749999876
              ],
              [
                -73.88250150999994,
                40.87229386699992
              ],
              [
                -73.88255385599989,
                40.87245711599995
              ],
              [
                -73.88255391499996,
                40.872457287999914
              ],
              [
                -73.88255399799999,
                40.87245745799991
              ],
              [
                -73.88262679199994,
                40.872616855999865
              ],
              [
                -73.88262687499989,
                40.872617027999944
              ],
              [
                -73.88262696899983,
                40.87261718899993
              ],
              [
                -73.88271951399993,
                40.87277078099995
              ],
              [
                -73.88283030599987,
                40.8729163669999
              ],
              [
                -73.88283042399992,
                40.87291651999991
              ],
              [
                -73.8828305549999,
                40.872916664999885
              ],
              [
                -73.882957783,
                40.87305245199985
              ],
              [
                -73.88308270399986,
                40.87321891799995
              ],
              [
                -73.88308273999985,
                40.873218971999904
              ],
              [
                -73.88308277499985,
                40.87321902499988
              ],
              [
                -73.8831904229999,
                40.87339318999993
              ],
              [
                -73.88319048200002,
                40.87339327999999
              ],
              [
                -73.88319052799997,
                40.87339337899994
              ],
              [
                -73.88327982500005,
                40.87357380999988
              ],
              [
                -73.8833499429999,
                40.87375884999992
              ],
              [
                -73.883400353,
                40.87394662599996
              ],
              [
                -73.88344008999987,
                40.87479468399987
              ],
              [
                -73.88343632799992,
                40.87538431899993
              ],
              [
                -73.88342472699996,
                40.875742665999866
              ],
              [
                -73.88341932399999,
                40.87584701799994
              ],
              [
                -73.88342964999991,
                40.875952169999906
              ],
              [
                -73.88345575699994,
                40.876055933999936
              ],
              [
                -73.88345579299984,
                40.87605609499994
              ],
              [
                -73.88345586399996,
                40.876056256999945
              ],
              [
                -73.88349725699989,
                40.8761567399999
              ],
              [
                -73.88355294399997,
                40.87625222899988
              ],
              [
                -73.88382977799998,
                40.87678284199991
              ],
              [
                -73.8846776199998,
                40.87823547699989
              ],
              [
                -73.88477074799978,
                40.87839502799989
              ],
              [
                -73.88489171499985,
                40.878606016999946
              ],
              [
                -73.88505419400002,
                40.87889287599995
              ],
              [
                -73.88527683199987,
                40.87921766899991
              ],
              [
                -73.88541068099991,
                40.87910168399994
              ],
              [
                -73.88567517999986,
                40.87887251099988
              ],
              [
                -73.88773577599993,
                40.87703388199988
              ],
              [
                -73.88808032199985,
                40.876272745999884
              ],
              [
                -73.88824333699998,
                40.87589219499995
              ],
              [
                -73.88855699899982,
                40.87519731299991
              ],
              [
                -73.88937163799983,
                40.87338229799992
              ],
              [
                -73.88863187299991,
                40.872765030999865
              ],
              [
                -73.88857987899992,
                40.872721639999924
              ],
              [
                -73.88798774400004,
                40.87222749099988
              ],
              [
                -73.88785196299986,
                40.87211417599992
              ],
              [
                -73.8879350369998,
                40.87203023199987
              ],
              [
                -73.88833546999989,
                40.871709830999905
              ],
              [
                -73.88868449900004,
                40.871495112999895
              ],
              [
                -73.88884301599997,
                40.871398066999866
              ],
              [
                -73.88950049199994,
                40.871020555999955
              ],
              [
                -73.88991744500004,
                40.870780631999864
              ],
              [
                -73.89042763899991,
                40.87048705399993
              ],
              [
                -73.89098696999984,
                40.870160714999905
              ],
              [
                -73.89119350399992,
                40.8700100329999
              ],
              [
                -73.89138946999998,
                40.869850993999904
              ],
              [
                -73.89157400099995,
                40.86968425199998
              ],
              [
                -73.89174633499988,
                40.86951054799996
              ],
              [
                -73.8919171829999,
                40.86930210999989
              ],
              [
                -73.89221258799988,
                40.86891549999995
              ],
              [
                -73.89243509099991,
                40.86862059799995
              ],
              [
                -73.8930026989999,
                40.86788106799989
              ],
              [
                -73.89368435599988,
                40.86698082099989
              ],
              [
                -73.8937613899999,
                40.866875057999955
              ],
              [
                -73.89397201799989,
                40.8666023039999
              ],
              [
                -73.89408697099991,
                40.86645343299996
              ],
              [
                -73.89431825299997,
                40.8661539259999
              ],
              [
                -73.89436438800003,
                40.866091671999854
              ],
              [
                -73.89438130299993,
                40.86606885099997
              ],
              [
                -73.89453048799989,
                40.86614380899987
              ],
              [
                -73.89536151600007,
                40.866561330999964
              ],
              [
                -73.89544263399986,
                40.86660208899987
              ],
              [
                -73.89631315699987,
                40.86703857499991
              ],
              [
                -73.8974032329998,
                40.86747418999991
              ],
              [
                -73.89797842699994,
                40.866727707999885
              ],
              [
                -73.89887282999976,
                40.86558402699991
              ],
              [
                -73.90027587299991,
                40.86378763499995
              ],
              [
                -73.90107966999994,
                40.86275638299992
              ],
              [
                -73.90028368099995,
                40.86247183699992
              ],
              [
                -73.89996177599984,
                40.86235027999989
              ],
              [
                -73.89964261700001,
                40.862210436999874
              ],
              [
                -73.89943670799998,
                40.86211691199987
              ],
              [
                -73.89841038399996,
                40.86229696699993
              ],
              [
                -73.89798099399988,
                40.86240253999994
              ],
              [
                -73.89737274799984,
                40.86243236399985
              ],
              [
                -73.897143798,
                40.862445064999925
              ],
              [
                -73.89773815399992,
                40.861351136999915
              ],
              [
                -73.89802453099999,
                40.860820460999896
              ],
              [
                -73.89822728999994,
                40.8604488639999
              ],
              [
                -73.89828973399992,
                40.86033442099993
              ],
              [
                -73.89859097199998,
                40.859766782999955
              ],
              [
                -73.89880457699982,
                40.8593642709999
              ],
              [
                -73.89883814599983,
                40.859307307999906
              ],
              [
                -73.89866157499996,
                40.859246901999924
              ],
              [
                -73.89818244699991,
                40.85908368099994
              ],
              [
                -73.89735765499988,
                40.85876273499986
              ],
              [
                -73.89636314599984,
                40.85837531899993
              ],
              [
                -73.89573924699978,
                40.858138206999875
              ],
              [
                -73.8946932639999,
                40.85942628199992
              ],
              [
                -73.89381026099987,
                40.85898656499994
              ],
              [
                -73.89321520799987,
                40.85987420599987
              ],
              [
                -73.89256392599998,
                40.86052272899994
              ],
              [
                -73.89141802399983,
                40.86166298399995
              ],
              [
                -73.89138023399983,
                40.86170058799992
              ],
              [
                -73.89177188799992,
                40.86187171599992
              ],
              [
                -73.8917325659999,
                40.861909814999905
              ],
              [
                -73.89114803099996,
                40.862476137999934
              ],
              [
                -73.89065392999989,
                40.86295483399993
              ],
              [
                -73.890161637,
                40.863476362999954
              ],
              [
                -73.88889566099989,
                40.86475415299988
              ],
              [
                -73.8881227879999,
                40.8642545709999
              ],
              [
                -73.88683316999993,
                40.86538999399989
              ],
              [
                -73.88593703500003,
                40.86592439799995
              ],
              [
                -73.88513907699999,
                40.86638287399992
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000124168267356,
        "objectid": 25,
        "shape_leng": 0.0471458199319,
        "location_id": 25,
        "zone": "Boerum Hill",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.98155298299992,
                40.689146163999936
              ],
              [
                -73.9820973789999,
                40.68935800999991
              ],
              [
                -73.98295657299988,
                40.689699730999905
              ],
              [
                -73.98372011099993,
                40.69000671899993
              ],
              [
                -73.9840703399998,
                40.69014307899987
              ],
              [
                -73.98443216299994,
                40.690292171999936
              ],
              [
                -73.98510411999995,
                40.69056114499995
              ],
              [
                -73.98536391899995,
                40.6906666129999
              ],
              [
                -73.98633134999982,
                40.69105050999988
              ],
              [
                -73.98646436299995,
                40.69110361099992
              ],
              [
                -73.98734787400004,
                40.69145766799993
              ],
              [
                -73.9882070139999,
                40.69180695999995
              ],
              [
                -73.98893356299986,
                40.69208664099989
              ],
              [
                -73.98902944799994,
                40.69212386099991
              ],
              [
                -73.98930270499983,
                40.691060225999884
              ],
              [
                -73.98930768499997,
                40.690991687999926
              ],
              [
                -73.98973690099999,
                40.6903599729999
              ],
              [
                -73.99016617899996,
                40.68972953399991
              ],
              [
                -73.99054474599986,
                40.689168104999936
              ],
              [
                -73.99070669599995,
                40.68913674399992
              ],
              [
                -73.99236366999985,
                40.689690123999924
              ],
              [
                -73.99262286099999,
                40.68916054899988
              ],
              [
                -73.99268552799995,
                40.68903324699993
              ],
              [
                -73.99293238499986,
                40.68851457599991
              ],
              [
                -73.99300451699985,
                40.68836463599994
              ],
              [
                -73.99326829999998,
                40.68782445099992
              ],
              [
                -73.99333822999985,
                40.68767879699991
              ],
              [
                -73.99360423199991,
                40.6871272299999
              ],
              [
                -73.99378736999994,
                40.68675596699996
              ],
              [
                -73.9939292959999,
                40.68645287099989
              ],
              [
                -73.99409147700004,
                40.686113438999946
              ],
              [
                -73.99425367999989,
                40.685777996999924
              ],
              [
                -73.99440802399982,
                40.685452495999925
              ],
              [
                -73.99457806799994,
                40.68510014199989
              ],
              [
                -73.99490113599975,
                40.68443023799992
              ],
              [
                -73.99262292599985,
                40.68354371199993
              ],
              [
                -73.99042374299992,
                40.68269393299994
              ],
              [
                -73.98827189199999,
                40.68186309999987
              ],
              [
                -73.98722413899985,
                40.68144730199988
              ],
              [
                -73.9876680399999,
                40.68081780999993
              ],
              [
                -73.98729306099992,
                40.68066969799988
              ],
              [
                -73.98653302999993,
                40.680372638999884
              ],
              [
                -73.98610388199985,
                40.681008521999956
              ],
              [
                -73.98413878099997,
                40.680245370999934
              ],
              [
                -73.98185715599982,
                40.67935261499986
              ],
              [
                -73.98175642299985,
                40.67931462399988
              ],
              [
                -73.98132413699994,
                40.67995210099992
              ],
              [
                -73.98088932299994,
                40.6805933489999
              ],
              [
                -73.98046155400003,
                40.681222546999926
              ],
              [
                -73.98003510099997,
                40.68185262699988
              ],
              [
                -73.97961592499983,
                40.682493556999916
              ],
              [
                -73.97918390599997,
                40.68312883799993
              ],
              [
                -73.97876022499986,
                40.68376544399991
              ],
              [
                -73.97842970699993,
                40.684355612999866
              ],
              [
                -73.97838862599984,
                40.68442334199988
              ],
              [
                -73.97804289599988,
                40.68485661499989
              ],
              [
                -73.97827416099987,
                40.68519595199996
              ],
              [
                -73.97899402899994,
                40.686156131999894
              ],
              [
                -73.97909065799993,
                40.68629386599988
              ],
              [
                -73.97936297699995,
                40.686682000999916
              ],
              [
                -73.97959037699985,
                40.687003901999965
              ],
              [
                -73.97984055799984,
                40.68734433199992
              ],
              [
                -73.98012756199996,
                40.68775000899994
              ],
              [
                -73.98064972099984,
                40.688459476999874
              ],
              [
                -73.980691182,
                40.68851581099994
              ],
              [
                -73.9808430809999,
                40.688761361999916
              ],
              [
                -73.98092822899991,
                40.68890247699988
              ],
              [
                -73.98101786899988,
                40.68893792699991
              ],
              [
                -73.98155298299992,
                40.689146163999936
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.00138177826442,
        "objectid": 14,
        "shape_leng": 0.175213698053,
        "location_id": 14,
        "zone": "Bay Ridge",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -74.03407329297129,
                40.644313932981866
              ],
              [
                -74.034538297394,
                40.64387638151011
              ],
              [
                -74.03445193181358,
                40.64382678668235
              ],
              [
                -74.03670036684926,
                40.64166499095435
              ],
              [
                -74.03672342447773,
                40.64164972932344
              ],
              [
                -74.03674294096601,
                40.64163182969994
              ],
              [
                -74.03675840317513,
                40.64161176271395
              ],
              [
                -74.03676940456552,
                40.641590055980984
              ],
              [
                -74.03677565588535,
                40.641567280229424
              ],
              [
                -74.03677699277581,
                40.64154403429451
              ],
              [
                -74.03677338009166,
                40.641520929373115
              ],
              [
                -74.03676491282464,
                40.64149857295399
              ],
              [
                -74.03675181360583,
                40.64147755284508
              ],
              [
                -74.03673442685152,
                40.64145842171885
              ],
              [
                -74.03634712296504,
                40.64114331106445
              ],
              [
                -74.03597990789547,
                40.64094005054443
              ],
              [
                -74.03571276150724,
                40.64064015404648
              ],
              [
                -74.0358416240794,
                40.64048609895574
              ],
              [
                -74.03596592750286,
                40.640329885348834
              ],
              [
                -74.03608560952524,
                40.64017159149986
              ],
              [
                -74.03620061021064,
                40.64001129672453
              ],
              [
                -74.0363441848906,
                40.6397982592404
              ],
              [
                -74.03647949179155,
                40.639582104484745
              ],
              [
                -74.03660641448076,
                40.63936301858892
              ],
              [
                -74.03672484374877,
                40.63914119020668
              ],
              [
                -74.0386880469073,
                40.63973825602953
              ],
              [
                -74.03876297920752,
                40.63958825898054
              ],
              [
                -74.03680662048858,
                40.63898422154557
              ],
              [
                -74.03708957847103,
                40.63844640994759
              ],
              [
                -74.03745424937436,
                40.637696515307255
              ],
              [
                -74.03783376084705,
                40.636916083833185
              ],
              [
                -74.03816058598187,
                40.63624398058439
              ],
              [
                -74.03843230568432,
                40.635685189142116
              ],
              [
                -74.03929388690396,
                40.63388635429643
              ],
              [
                -74.03991790625045,
                40.632534443707065
              ],
              [
                -74.04015481009853,
                40.632016901563
              ],
              [
                -74.04028911535312,
                40.63171630457623
              ],
              [
                -74.04065885393268,
                40.63088875293372
              ],
              [
                -74.04074668933455,
                40.6306921559799
              ],
              [
                -74.04081912392391,
                40.63061665083625
              ],
              [
                -74.04088410820546,
                40.6305373079888
              ],
              [
                -74.04094129340537,
                40.630454553307736
              ],
              [
                -74.04099037261425,
                40.63036883097466
              ],
              [
                -74.04103108243446,
                40.63028060109854
              ],
              [
                -74.04106320439342,
                40.630190337245
              ],
              [
                -74.04108656611493,
                40.630098523895285
              ],
              [
                -74.0411010422447,
                40.630005653845345
              ],
              [
                -74.04110655512132,
                40.62991222556074
              ],
              [
                -74.04110307519313,
                40.62981874050098
              ],
              [
                -74.04109062117575,
                40.62972570042869
              ],
              [
                -74.04127701609347,
                40.62902206057
              ],
              [
                -74.04130293385224,
                40.62886001369013
              ],
              [
                -74.04141650353655,
                40.62814996054547
              ],
              [
                -74.04142918710238,
                40.62807066515699
              ],
              [
                -74.0415662473388,
                40.62674359415526
              ],
              [
                -74.04164207726816,
                40.62600935792479
              ],
              [
                -74.04168321534894,
                40.62538354217451
              ],
              [
                -74.0417566930148,
                40.62505506019891
              ],
              [
                -74.04187652595625,
                40.624519338293865
              ],
              [
                -74.0418884929145,
                40.62440931329303
              ],
              [
                -74.04189513118017,
                40.62429902703854
              ],
              [
                -74.04189643176426,
                40.62418862930655
              ],
              [
                -74.04189123741789,
                40.62406041492981
              ],
              [
                -74.04187884456147,
                40.623932487501996
              ],
              [
                -74.04185927595628,
                40.623805081551424
              ],
              [
                -74.04183256751773,
                40.6236784306493
              ],
              [
                -74.0417987682504,
                40.62355276698133
              ],
              [
                -74.0417579401569,
                40.6234283209218
              ],
              [
                -74.04171015812464,
                40.6233053206118
              ],
              [
                -74.04170832517198,
                40.62324493419511
              ],
              [
                -74.04169490179855,
                40.62280245622836
              ],
              [
                -74.0417493234626,
                40.62245699566951
              ],
              [
                -74.04167119741669,
                40.62209180591906
              ],
              [
                -74.0415996813113,
                40.621466315183824
              ],
              [
                -74.04157984042502,
                40.62127583441747
              ],
              [
                -74.04160333814033,
                40.621109343824465
              ],
              [
                -74.04161785575602,
                40.62094225903331
              ],
              [
                -74.04162336891518,
                40.620774861150096
              ],
              [
                -74.04162219761896,
                40.62066875832983
              ],
              [
                -74.04161740773601,
                40.620562714545805
              ],
              [
                -74.04159578832967,
                40.62046384127521
              ],
              [
                -74.04156650734973,
                40.62036611976727
              ],
              [
                -74.04152966802239,
                40.62026989441518
              ],
              [
                -74.04148540020998,
                40.6201755043379
              ],
              [
                -74.04143385995191,
                40.62008328218532
              ],
              [
                -74.04137522891591,
                40.619993552965234
              ],
              [
                -74.0411277728829,
                40.61878605611304
              ],
              [
                -74.04101750654688,
                40.61830780552466
              ],
              [
                -74.04086140769245,
                40.617630765787595
              ],
              [
                -74.04072760517815,
                40.617050416395266
              ],
              [
                -74.04047612021522,
                40.61562526366403
              ],
              [
                -74.04038132338673,
                40.615341782328436
              ],
              [
                -74.04023427278453,
                40.615028256649374
              ],
              [
                -74.03982365089743,
                40.6143273886638
              ],
              [
                -74.03944150515275,
                40.613775944191865
              ],
              [
                -74.039205576474,
                40.613439512733684
              ],
              [
                -74.03892016103417,
                40.61314167158662
              ],
              [
                -74.03873050931418,
                40.612943761342144
              ],
              [
                -74.03753408681214,
                40.611840635703686
              ],
              [
                -74.03739610497087,
                40.61171686438517
              ],
              [
                -74.03726510650235,
                40.61158876198924
              ],
              [
                -74.0371413251896,
                40.611456557170285
              ],
              [
                -74.03702498192945,
                40.61132048590398
              ],
              [
                -74.03691628433882,
                40.61118079106552
              ],
              [
                -74.03681542638358,
                40.611037721996844
              ],
              [
                -74.03672258803354,
                40.61089153406036
              ],
              [
                -74.03663793494067,
                40.610742488184165
              ],
              [
                -74.03656161814445,
                40.61059085039554
              ],
              [
                -74.03649377380209,
                40.61043689134577
              ],
              [
                -74.03643452294632,
                40.610280885828004
              ],
              [
                -74.0363839712695,
                40.610123112285784
              ],
              [
                -74.03634220893493,
                40.609963852315985
              ],
              [
                -74.0363093104175,
                40.60980339016717
              ],
              [
                -74.03611335501427,
                40.60939472941204
              ],
              [
                -74.036080581669,
                40.6093468863874
              ],
              [
                -74.03603977777905,
                40.609295639001864
              ],
              [
                -74.03579462895571,
                40.609110465057874
              ],
              [
                -74.03574142195205,
                40.60906716256956
              ],
              [
                -74.03568233633708,
                40.60902854019212
              ],
              [
                -74.03561807682696,
                40.60899505856991
              ],
              [
                -74.03554940984297,
                40.608967117033416
              ],
              [
                -74.03547715437021,
                40.60894504883701
              ],
              [
                -74.03540217219016,
                40.60892911718413
              ],
              [
                -74.03535170243764,
                40.60891041067644
              ],
              [
                -74.03530371251365,
                40.608888235269525
              ],
              [
                -74.03525861177654,
                40.60886278012297
              ],
              [
                -74.03515279047686,
                40.60880003284153
              ],
              [
                -74.03505254782415,
                40.60873217339959
              ],
              [
                -74.03495830824197,
                40.608659489123674
              ],
              [
                -74.03487047073257,
                40.60858228776791
              ],
              [
                -74.03478940718658,
                40.608500896211424
              ],
              [
                -74.0347154608099,
                40.60841565907371
              ],
              [
                -74.03464894466927,
                40.60832693725557
              ],
              [
                -74.03459014036846,
                40.608235106410824
              ],
              [
                -74.03453929685496,
                40.60814055535553
              ],
              [
                -74.03449662936796,
                40.60804368442153
              ],
              [
                -74.03443004631626,
                40.6079318233239
              ],
              [
                -74.034355360413,
                40.60782297472989
              ],
              [
                -74.03427280539496,
                40.60771747923558
              ],
              [
                -74.03378350679236,
                40.60725435038858
              ],
              [
                -74.03359224082001,
                40.60708260743882
              ],
              [
                -74.0327068147988,
                40.60633106603806
              ],
              [
                -74.03213159551309,
                40.605901836264245
              ],
              [
                -74.03186039997432,
                40.60581118472869
              ],
              [
                -74.03179633794443,
                40.60578027459593
              ],
              [
                -74.03143471659999,
                40.605529466810694
              ],
              [
                -74.03101612271274,
                40.605251414115195
              ],
              [
                -74.03088511216566,
                40.60518456375306
              ],
              [
                -74.03005146547684,
                40.60475917381197
              ],
              [
                -74.02930882862765,
                40.604599064564454
              ],
              [
                -74.02514668248197,
                40.60408312422998
              ],
              [
                -74.02150770327854,
                40.603615699297556
              ],
              [
                -74.02134266985102,
                40.603602404731916
              ],
              [
                -74.0211788193127,
                40.60358233649456
              ],
              [
                -74.0210166299108,
                40.60355555316099
              ],
              [
                -74.0208565750425,
                40.60352213290627
              ],
              [
                -74.02069912187268,
                40.60348217327763
              ],
              [
                -74.02054472997047,
                40.60343579090909
              ],
              [
                -74.0203938499666,
                40.60338312118084
              ],
              [
                -74.02024692223878,
                40.603324317824466
              ],
              [
                -74.02010437562546,
                40.60325955247392
              ],
              [
                -74.01974772844801,
                40.60308480926383
              ],
              [
                -74.01942760861903,
                40.60287157842528
              ],
              [
                -74.01939343599999,
                40.60291176099992
              ],
              [
                -74.01923150399992,
                40.603102173999915
              ],
              [
                -74.01918088999996,
                40.60314959499995
              ],
              [
                -74.01917685099995,
                40.603154421999875
              ],
              [
                -74.01871382299994,
                40.60358207499994
              ],
              [
                -74.01869135799996,
                40.603607923999924
              ],
              [
                -74.01909859999992,
                40.60373226399986
              ],
              [
                -74.01912316599994,
                40.603739769999926
              ],
              [
                -74.01918971499998,
                40.60376183999993
              ],
              [
                -74.01924239499986,
                40.60377850099991
              ],
              [
                -74.0205358359999,
                40.60419641799993
              ],
              [
                -74.02129949199998,
                40.60442832299987
              ],
              [
                -74.02139467299999,
                40.60449399799991
              ],
              [
                -74.02122508399991,
                40.604738061999974
              ],
              [
                -74.02078541,
                40.605673685999875
              ],
              [
                -74.02247769499994,
                40.60667421599988
              ],
              [
                -74.02150602899994,
                40.60782209899992
              ],
              [
                -74.02430437599992,
                40.609456968999936
              ],
              [
                -74.02439534399993,
                40.60954853399993
              ],
              [
                -74.02447207599994,
                40.609650082999956
              ],
              [
                -74.02453195999996,
                40.60975959499991
              ],
              [
                -74.02457307699996,
                40.609874527999885
              ],
              [
                -74.02459441799985,
                40.60999204099988
              ],
              [
                -74.02459593799989,
                40.610109224999896
              ],
              [
                -74.0245785549999,
                40.61022330199993
              ],
              [
                -74.02478376299999,
                40.60969962899985
              ],
              [
                -74.02497932499993,
                40.609741974999906
              ],
              [
                -74.02710660599995,
                40.61024395599993
              ],
              [
                -74.02637609099996,
                40.61245060299994
              ],
              [
                -74.02653228199998,
                40.612425370999915
              ],
              [
                -74.02754297299998,
                40.61293718299988
              ],
              [
                -74.02736880500001,
                40.61323451599993
              ],
              [
                -74.02651463099991,
                40.61467032699991
              ],
              [
                -74.02624732399988,
                40.61510478099994
              ],
              [
                -74.02607013199994,
                40.61548666699989
              ],
              [
                -74.02574989099996,
                40.616058222999904
              ],
              [
                -74.02525327199992,
                40.61667845799995
              ],
              [
                -74.02462461299996,
                40.61738902399987
              ],
              [
                -74.02387367099986,
                40.618259319999886
              ],
              [
                -74.02363350499998,
                40.61853764799987
              ],
              [
                -74.02287598699992,
                40.619466147999944
              ],
              [
                -74.02226919899991,
                40.620109624999934
              ],
              [
                -74.02172925499997,
                40.62067945199991
              ],
              [
                -74.02167353899998,
                40.6207347999999
              ],
              [
                -74.02117792099988,
                40.62125219599988
              ],
              [
                -74.02090368299993,
                40.62152616599993
              ],
              [
                -74.02062330999992,
                40.62180625199992
              ],
              [
                -74.020086673,
                40.62242095199988
              ],
              [
                -74.01970491599991,
                40.6221849649999
              ],
              [
                -74.01938702899992,
                40.62289181899993
              ],
              [
                -74.01907578299992,
                40.62361467399988
              ],
              [
                -74.0189099719999,
                40.62408698799987
              ],
              [
                -74.0187518069999,
                40.62431751099996
              ],
              [
                -74.01784193399993,
                40.626590693999944
              ],
              [
                -74.01722288099991,
                40.628100688999965
              ],
              [
                -74.01696955099997,
                40.62872878599994
              ],
              [
                -74.01692644799988,
                40.628835638999924
              ],
              [
                -74.01664336499988,
                40.629576078999946
              ],
              [
                -74.01634322299998,
                40.630285595999915
              ],
              [
                -74.01604820399999,
                40.631032888999876
              ],
              [
                -74.01574804399993,
                40.63176426999993
              ],
              [
                -74.01542409800001,
                40.63251313899988
              ],
              [
                -74.01514630699995,
                40.63322193799993
              ],
              [
                -74.01560204999993,
                40.63351697799989
              ],
              [
                -74.01591473499991,
                40.63336554899992
              ],
              [
                -74.016020101,
                40.63331711399997
              ],
              [
                -74.01600191100002,
                40.633524190999886
              ],
              [
                -74.016004681,
                40.63373391199989
              ],
              [
                -74.01602896399994,
                40.63394443099988
              ],
              [
                -74.01607491199997,
                40.63415382299994
              ],
              [
                -74.0161423149999,
                40.634360157999915
              ],
              [
                -74.01623055699993,
                40.634561529999914
              ],
              [
                -74.01626768999998,
                40.63462840599987
              ],
              [
                -74.01633863299998,
                40.63475616099995
              ],
              [
                -74.01646522999985,
                40.63494240699987
              ],
              [
                -74.01650516099987,
                40.63499150699988
              ],
              [
                -74.01660869499997,
                40.63511883399988
              ],
              [
                -74.01667603,
                40.63518670499995
              ],
              [
                -74.016835401,
                40.63535542899995
              ],
              [
                -74.01705196399993,
                40.63552755399995
              ],
              [
                -74.01727269499997,
                40.635695675999926
              ],
              [
                -74.01750323299989,
                40.635856163999904
              ],
              [
                -74.01755212599994,
                40.63588725099987
              ],
              [
                -74.01799939199995,
                40.63615792199988
              ],
              [
                -74.01899546799997,
                40.636754103999905
              ],
              [
                -74.01955410699993,
                40.63709122699992
              ],
              [
                -74.01962150999992,
                40.63713106399994
              ],
              [
                -74.01976495999995,
                40.63721743499991
              ],
              [
                -74.02002934999997,
                40.63737936599991
              ],
              [
                -74.02032883799993,
                40.63757296899992
              ],
              [
                -74.020598444,
                40.63777414199989
              ],
              [
                -74.02084657599987,
                40.63798335099993
              ],
              [
                -74.02107776199989,
                40.63820262199994
              ],
              [
                -74.021114445,
                40.638235535999904
              ],
              [
                -74.02116029799993,
                40.63828079799991
              ],
              [
                -74.02129372099995,
                40.638428241999854
              ],
              [
                -74.02141038399992,
                40.63858490299987
              ],
              [
                -74.02150876399996,
                40.638749071999925
              ],
              [
                -74.02154646799997,
                40.638830162999916
              ],
              [
                -74.0216086779999,
                40.63897677999994
              ],
              [
                -74.02164685099997,
                40.63909104799992
              ],
              [
                -74.02167777199992,
                40.63919125699989
              ],
              [
                -74.02170098699993,
                40.639265459999905
              ],
              [
                -74.02170856799994,
                40.63930090299994
              ],
              [
                -74.02173880499991,
                40.63944050699995
              ],
              [
                -74.02174189,
                40.639466291999945
              ],
              [
                -74.02176040899997,
                40.63982434599988
              ],
              [
                -74.02172617299989,
                40.64011331999995
              ],
              [
                -74.0217092389999,
                40.640200097999866
              ],
              [
                -74.02166136899989,
                40.64037438299987
              ],
              [
                -74.02161540399997,
                40.64050926299993
              ],
              [
                -74.02159214600002,
                40.64056920899986
              ],
              [
                -74.02147133099999,
                40.64082961299994
              ],
              [
                -74.02126975099995,
                40.64117350099991
              ],
              [
                -74.02114153299986,
                40.64136239499993
              ],
              [
                -74.0211051969999,
                40.6414141359999
              ],
              [
                -74.02108520199992,
                40.64144035399991
              ],
              [
                -74.0210580239999,
                40.64147598299995
              ],
              [
                -74.02108051399998,
                40.64149093699993
              ],
              [
                -74.02111136799995,
                40.64145200099991
              ],
              [
                -74.0211362599999,
                40.6414339679999
              ],
              [
                -74.02120118199991,
                40.64138693999995
              ],
              [
                -74.02152001099992,
                40.64115336799988
              ],
              [
                -74.02172675999999,
                40.641004941999945
              ],
              [
                -74.02180903199998,
                40.64095040899988
              ],
              [
                -74.02222562199997,
                40.640677822999855
              ],
              [
                -74.02228790899991,
                40.640638835999894
              ],
              [
                -74.02246253899987,
                40.64053889899986
              ],
              [
                -74.02259016799994,
                40.64043910599985
              ],
              [
                -74.02310593199999,
                40.63999532599994
              ],
              [
                -74.02321780199998,
                40.63990151499989
              ],
              [
                -74.02331682999991,
                40.63979778199991
              ],
              [
                -74.02340085399995,
                40.63968573199987
              ],
              [
                -74.02346821799988,
                40.63956733499988
              ],
              [
                -74.02348203699995,
                40.63953321299996
              ],
              [
                -74.02351787199997,
                40.63944477399987
              ],
              [
                -74.02354939199988,
                40.63932033399992
              ],
              [
                -74.02392610699991,
                40.63889885799994
              ],
              [
                -74.02417094699994,
                40.63866937099987
              ],
              [
                -74.0245384189999,
                40.63832493699994
              ],
              [
                -74.02475437599998,
                40.63812141799988
              ],
              [
                -74.02695244699993,
                40.63944606499998
              ],
              [
                -74.02686129399991,
                40.6395362909999
              ],
              [
                -74.02664251499989,
                40.63974743299988
              ],
              [
                -74.02659984399993,
                40.63978656099993
              ],
              [
                -74.02646434999998,
                40.639918292999916
              ],
              [
                -74.02635007599996,
                40.640018464999905
              ],
              [
                -74.02628611999998,
                40.64007452899989
              ],
              [
                -74.02626267299999,
                40.64009438099988
              ],
              [
                -74.02662323799991,
                40.6402011359999
              ],
              [
                -74.02674292499995,
                40.640244467999935
              ],
              [
                -74.02691981299992,
                40.640308507999876
              ],
              [
                -74.02703439399988,
                40.64034998599989
              ],
              [
                -74.02742711399993,
                40.640514336999956
              ],
              [
                -74.02802775299989,
                40.640793489999915
              ],
              [
                -74.02839277799997,
                40.640963136999865
              ],
              [
                -74.02890829899998,
                40.641173410999905
              ],
              [
                -74.02889666799992,
                40.64121402199986
              ],
              [
                -74.02905968899994,
                40.64172667299992
              ],
              [
                -74.03260644399994,
                40.64369891099996
              ],
              [
                -74.03266558998958,
                40.64372034489007
              ],
              [
                -74.03284292006926,
                40.64356371017527
              ],
              [
                -74.03407329297129,
                40.644313932981866
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000925219395547,
        "objectid": 15,
        "shape_leng": 0.14433622262,
        "location_id": 15,
        "zone": "Bay Terrace/Fort Totten",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.7774039129087,
                40.79659824126783
              ],
              [
                -73.77742032900714,
                40.79660400837063
              ],
              [
                -73.77743367405546,
                40.79661026575175
              ],
              [
                -73.77744564507036,
                40.79661646620007
              ],
              [
                -73.77745764333974,
                40.79662499915688
              ],
              [
                -73.77747147920505,
                40.796633202451844
              ],
              [
                -73.77748973756474,
                40.796637379431495
              ],
              [
                -73.77750623891349,
                40.79663881665584
              ],
              [
                -73.777521697539,
                40.79664013375987
              ],
              [
                -73.77754158666976,
                40.796641820094784
              ],
              [
                -73.77756101083169,
                40.796644712047545
              ],
              [
                -73.77757812464012,
                40.79664705122795
              ],
              [
                -73.77759425962752,
                40.79664828837736
              ],
              [
                -73.77760947214591,
                40.796648975433996
              ],
              [
                -73.77762351135634,
                40.796649633253466
              ],
              [
                -73.77764012114095,
                40.79665068251132
              ],
              [
                -73.77766142646871,
                40.79665392887425
              ],
              [
                -73.77767875245776,
                40.79665676298416
              ],
              [
                -73.77769491559815,
                40.796660198778085
              ],
              [
                -73.77770874128565,
                40.796664727353715
              ],
              [
                -73.77772279891418,
                40.796670301566365
              ],
              [
                -73.77773780843233,
                40.79667527435353
              ],
              [
                -73.77775422709308,
                40.79668002419903
              ],
              [
                -73.77777223683373,
                40.79668406680165
              ],
              [
                -73.77778725197105,
                40.79668689593743
              ],
              [
                -73.77780760959772,
                40.79669041987883
              ],
              [
                -73.77782400143708,
                40.796692838880894
              ],
              [
                -73.77783898494677,
                40.796694379510505
              ],
              [
                -73.77785421688698,
                40.796696516840186
              ],
              [
                -73.77787108189364,
                40.796698972430256
              ],
              [
                -73.7778855898774,
                40.796701251092344
              ],
              [
                -73.7779050120194,
                40.79670449378134
              ],
              [
                -73.7779218631978,
                40.79670765964538
              ],
              [
                -73.77794084652679,
                40.79671101020692
              ],
              [
                -73.77795864859094,
                40.796713214458435
              ],
              [
                -73.77797479179517,
                40.7967154979345
              ],
              [
                -73.77799095770179,
                40.796717914802265
              ],
              [
                -73.77800593512394,
                40.796721482121505
              ],
              [
                -73.77801974867874,
                40.796726155545905
              ],
              [
                -73.77803334795567,
                40.7967305678864
              ],
              [
                -73.77805024184082,
                40.796735408063284
              ],
              [
                -73.77806662937581,
                40.79673872730426
              ],
              [
                -73.77808486122399,
                40.79674003208208
              ],
              [
                -73.7781028854632,
                40.79673986809647
              ],
              [
                -73.77811877759432,
                40.79673924215685
              ],
              [
                -73.7781353945516,
                40.79673814820389
              ],
              [
                -73.77815154791757,
                40.79673738606178
              ],
              [
                -73.77816768905345,
                40.79673658938364
              ],
              [
                -73.7781847461598,
                40.796734920988136
              ],
              [
                -73.7782013295322,
                40.79673279115117
              ],
              [
                -73.77822228238244,
                40.79673194877572
              ],
              [
                -73.77823373526176,
                40.79673013456458
              ],
              [
                -73.77825056887954,
                40.79672772576945
              ],
              [
                -73.77827066301263,
                40.796725125731335
              ],
              [
                -73.77829124888358,
                40.79672406736739
              ],
              [
                -73.77831089780186,
                40.79672303249396
              ],
              [
                -73.77833287754164,
                40.79672011375267
              ],
              [
                -73.77834924005742,
                40.79671684831703
              ],
              [
                -73.77836745440197,
                40.7967125514675
              ],
              [
                -73.77837960824762,
                40.79671021607783
              ],
              [
                -73.77839550549668,
                40.79670804971321
              ],
              [
                -73.77841209444436,
                40.79670470445936
              ],
              [
                -73.77843218488232,
                40.796699501710506
              ],
              [
                -73.77844831121797,
                40.79669579605253
              ],
              [
                -73.77846700036604,
                40.79669143711031
              ],
              [
                -73.77848101850053,
                40.79668803312994
              ],
              [
                -73.7784973595596,
                40.7966842553393
              ],
              [
                -73.77851818617542,
                40.79667861323066
              ],
              [
                -73.77853520981658,
                40.79667268437904
              ],
              [
                -73.77855506807938,
                40.79666583438735
              ],
              [
                -73.7785723534402,
                40.796659555234854
              ],
              [
                -73.77858658990885,
                40.79665439498893
              ],
              [
                -73.77860456300029,
                40.79664792835134
              ],
              [
                -73.77862394962096,
                40.79664044721125
              ],
              [
                -73.7786374737861,
                40.79663550136388
              ],
              [
                -73.77865313727699,
                40.79662911153307
              ],
              [
                -73.77867108995639,
                40.79661810557603
              ],
              [
                -73.77868530361943,
                40.79660919102859
              ],
              [
                -73.77868968820111,
                40.79660527281772
              ],
              [
                -73.77871478762599,
                40.796597360195996
              ],
              [
                -73.7792728777499,
                40.796637683818055
              ],
              [
                -73.77928446350832,
                40.79667469847259
              ],
              [
                -73.7793624680482,
                40.79668539253491
              ],
              [
                -73.77943301516063,
                40.79666153857653
              ],
              [
                -73.77946869192893,
                40.796608441296094
              ],
              [
                -73.77944961456502,
                40.796576238063885
              ],
              [
                -73.7798030505206,
                40.79620552893679
              ],
              [
                -73.77989837558063,
                40.79623809836545
              ],
              [
                -73.78154150032744,
                40.79565973731997
              ],
              [
                -73.78165761051014,
                40.79555862334205
              ],
              [
                -73.78172625575411,
                40.79542750602256
              ],
              [
                -73.78182459908278,
                40.795077289713454
              ],
              [
                -73.78200326103361,
                40.79497546250359
              ],
              [
                -73.78236678802342,
                40.79495961470365
              ],
              [
                -73.78286814787282,
                40.79498657863789
              ],
              [
                -73.78305415481076,
                40.79499109708873
              ],
              [
                -73.78311558348871,
                40.79499345853902
              ],
              [
                -73.7831878079246,
                40.79497343423332
              ],
              [
                -73.78325271025878,
                40.79492032976596
              ],
              [
                -73.78327193181352,
                40.7948832697364
              ],
              [
                -73.78327094437915,
                40.79486068745444
              ],
              [
                -73.78328163720504,
                40.79483570739623
              ],
              [
                -73.7832965245875,
                40.79482605743941
              ],
              [
                -73.78332528563587,
                40.79479224087094
              ],
              [
                -73.78332856842805,
                40.79476160260946
              ],
              [
                -73.78331796684242,
                40.794758356474155
              ],
              [
                -73.78328948833689,
                40.79475996026348
              ],
              [
                -73.78324789295391,
                40.79478855571322
              ],
              [
                -73.78321522572556,
                40.794825295774366
              ],
              [
                -73.78317026275752,
                40.794875865881274
              ],
              [
                -73.78314000854334,
                40.79489731287009
              ],
              [
                -73.78310976963976,
                40.79491446050581
              ],
              [
                -73.78307475337424,
                40.79491681769891
              ],
              [
                -73.78284362044447,
                40.79490185399306
              ],
              [
                -73.78265517138459,
                40.79490070120977
              ],
              [
                -73.78258165591822,
                40.79488335802936
              ],
              [
                -73.78190385421033,
                40.794901203894135
              ],
              [
                -73.7818100376292,
                40.79480185241984
              ],
              [
                -73.78152857545292,
                40.79472485577792
              ],
              [
                -73.7813176840371,
                40.79460466456844
              ],
              [
                -73.78100539667301,
                40.794384818627115
              ],
              [
                -73.78079182072646,
                40.79403591986439
              ],
              [
                -73.78070025516259,
                40.79364850590945
              ],
              [
                -73.7807243506983,
                40.79333361882421
              ],
              [
                -73.78080808737927,
                40.7933509327133
              ],
              [
                -73.78082154869789,
                40.79331942900067
              ],
              [
                -73.78164213433469,
                40.793525053377635
              ],
              [
                -73.78166011555379,
                40.79348202031304
              ],
              [
                -73.78161607678727,
                40.79347077130438
              ],
              [
                -73.78160879379377,
                40.79345161570283
              ],
              [
                -73.78162662328144,
                40.793454840007925
              ],
              [
                -73.78168372830307,
                40.793321757010794
              ],
              [
                -73.78163864257793,
                40.79330970776808
              ],
              [
                -73.78158153246125,
                40.79344438658183
              ],
              [
                -73.78158781636597,
                40.793448385429826
              ],
              [
                -73.78158881545012,
                40.79346354101255
              ],
              [
                -73.78083949014304,
                40.79327744017283
              ],
              [
                -73.78085015490862,
                40.79325247937742
              ],
              [
                -73.78077892481954,
                40.793189785863845
              ],
              [
                -73.78077503139014,
                40.79299070534053
              ],
              [
                -73.78089784791108,
                40.792193705351615
              ],
              [
                -73.78107845970229,
                40.79167732177905
              ],
              [
                -73.78123362112055,
                40.79148460246175
              ],
              [
                -73.78143260460877,
                40.79136512329363
              ],
              [
                -73.78160545881975,
                40.79121792122328
              ],
              [
                -73.7817021394188,
                40.79117525786652
              ],
              [
                -73.78174708763096,
                40.79113340664447
              ],
              [
                -73.78227330183385,
                40.79137371571794
              ],
              [
                -73.78434153059591,
                40.79174935379487
              ],
              [
                -73.78439845368956,
                40.7917189168617
              ],
              [
                -73.78440669003494,
                40.791675839332534
              ],
              [
                -73.78252265694827,
                40.79135222690715
              ],
              [
                -73.78253080663518,
                40.79127678943192
              ],
              [
                -73.78317940975151,
                40.79086646470489
              ],
              [
                -73.7838384251435,
                40.790640954671524
              ],
              [
                -73.78568101301406,
                40.79034067166
              ],
              [
                -73.78750199944683,
                40.79030881800571
              ],
              [
                -73.7879935835311,
                40.79034968837414
              ],
              [
                -73.78835429027176,
                40.79032784777118
              ],
              [
                -73.78883143352806,
                40.790357187582224
              ],
              [
                -73.78930907131766,
                40.790380069550295
              ],
              [
                -73.78988738668919,
                40.79037193097968
              ],
              [
                -73.79028202353196,
                40.79031696339975
              ],
              [
                -73.79162795617712,
                40.789486123168686
              ],
              [
                -73.79175254252795,
                40.78938643776098
              ],
              [
                -73.79179280401884,
                40.789351616549254
              ],
              [
                -73.79156371699992,
                40.789103095999884
              ],
              [
                -73.79133754899989,
                40.7888577409999
              ],
              [
                -73.79084786600005,
                40.78840689799993
              ],
              [
                -73.790653771,
                40.78824945299988
              ],
              [
                -73.79058298999988,
                40.78819290999991
              ],
              [
                -73.79063559999989,
                40.78816951899994
              ],
              [
                -73.79153624300002,
                40.78775191299995
              ],
              [
                -73.7933258969999,
                40.78693204899985
              ],
              [
                -73.79392031799995,
                40.78667573999992
              ],
              [
                -73.79454742299993,
                40.78646433199992
              ],
              [
                -73.79491977600001,
                40.78633880799987
              ],
              [
                -73.79582463099997,
                40.786130587999914
              ],
              [
                -73.79685935799988,
                40.78599452199993
              ],
              [
                -73.7978420549998,
                40.78589425899988
              ],
              [
                -73.79880021900001,
                40.7859175479999
              ],
              [
                -73.7992594699999,
                40.785934836999935
              ],
              [
                -73.79973844499978,
                40.78595286999995
              ],
              [
                -73.80067959399986,
                40.7860011019999
              ],
              [
                -73.80157320499991,
                40.78603473299989
              ],
              [
                -73.80215169199994,
                40.78605649899994
              ],
              [
                -73.80242791699987,
                40.78606827399991
              ],
              [
                -73.80242243499985,
                40.78601897999991
              ],
              [
                -73.80241070199989,
                40.78581721099992
              ],
              [
                -73.80254724699994,
                40.78467334699992
              ],
              [
                -73.80274225299985,
                40.783253667999894
              ],
              [
                -73.8028401069999,
                40.78255003699992
              ],
              [
                -73.80293830099997,
                40.781835033999926
              ],
              [
                -73.80299781999996,
                40.78141047599992
              ],
              [
                -73.80314563299987,
                40.78132556799989
              ],
              [
                -73.80324974699984,
                40.78127547899993
              ],
              [
                -73.8030331849998,
                40.78094086999989
              ],
              [
                -73.803066233,
                40.78077013599989
              ],
              [
                -73.80313481699993,
                40.78041580599991
              ],
              [
                -73.80320064599984,
                40.7798836309999
              ],
              [
                -73.8032260339999,
                40.77970292499986
              ],
              [
                -73.80332690999992,
                40.77899547999995
              ],
              [
                -73.80342253099997,
                40.77833770799986
              ],
              [
                -73.80346278599977,
                40.77800817399988
              ],
              [
                -73.8035964729998,
                40.77703047199988
              ],
              [
                -73.80369287499998,
                40.7763196879999
              ],
              [
                -73.80379022899989,
                40.775610111999924
              ],
              [
                -73.80098974099998,
                40.77538911599991
              ],
              [
                -73.7986519199999,
                40.7752005589999
              ],
              [
                -73.79853009399987,
                40.775190728999924
              ],
              [
                -73.79823295499997,
                40.77484830499985
              ],
              [
                -73.79785400499989,
                40.77441566899992
              ],
              [
                -73.79772190099987,
                40.77427237299985
              ],
              [
                -73.79757522899993,
                40.77413684199995
              ],
              [
                -73.79741530999986,
                40.77401038199986
              ],
              [
                -73.79741522800005,
                40.774010318999885
              ],
              [
                -73.79741514499982,
                40.774010264999916
              ],
              [
                -73.79730005599994,
                40.773932285999884
              ],
              [
                -73.79724339799986,
                40.773893896999894
              ],
              [
                -73.79672685099996,
                40.7736092289999
              ],
              [
                -73.79652490499979,
                40.77350327799991
              ],
              [
                -73.79571707399992,
                40.77306964299989
              ],
              [
                -73.79565702099983,
                40.77303608599992
              ],
              [
                -73.79557358499984,
                40.7730492449999
              ],
              [
                -73.79499129399991,
                40.77314334799993
              ],
              [
                -73.7936523029999,
                40.7733604389999
              ],
              [
                -73.79271259399978,
                40.773787526999875
              ],
              [
                -73.79226795299988,
                40.77399004199988
              ],
              [
                -73.79163526099994,
                40.774238655999895
              ],
              [
                -73.79034971799999,
                40.774573093999926
              ],
              [
                -73.7898420409999,
                40.7746873259999
              ],
              [
                -73.78913873699987,
                40.77484868999994
              ],
              [
                -73.78893975799984,
                40.774900591999916
              ],
              [
                -73.78826972999987,
                40.775075356999864
              ],
              [
                -73.78740991599994,
                40.77531789199993
              ],
              [
                -73.78655073399982,
                40.77555774699992
              ],
              [
                -73.78626498299998,
                40.77564607999991
              ],
              [
                -73.78569433800004,
                40.77581277499991
              ],
              [
                -73.78491489199986,
                40.7760405099999
              ],
              [
                -73.78459339299995,
                40.77614122499991
              ],
              [
                -73.78448330699993,
                40.77616825899986
              ],
              [
                -73.78440918799984,
                40.7761902189999
              ],
              [
                -73.78407645399997,
                40.77628882299991
              ],
              [
                -73.78279772899998,
                40.776666035999945
              ],
              [
                -73.78036180399994,
                40.77738704699993
              ],
              [
                -73.77947656099995,
                40.7776641699999
              ],
              [
                -73.77858771199993,
                40.77791239199996
              ],
              [
                -73.77769354899995,
                40.77816041699992
              ],
              [
                -73.77678363299988,
                40.778409583999874
              ],
              [
                -73.77583958299994,
                40.7786123719999
              ],
              [
                -73.775724408,
                40.77790043399993
              ],
              [
                -73.77553732199992,
                40.777025597999916
              ],
              [
                -73.77465548700002,
                40.777285608999925
              ],
              [
                -73.7737825709999,
                40.7775474679999
              ],
              [
                -73.772903019,
                40.777804280999874
              ],
              [
                -73.7720360819999,
                40.778069802999866
              ],
              [
                -73.77113180399985,
                40.77834080999992
              ],
              [
                -73.76934982499988,
                40.77887476899986
              ],
              [
                -73.76877614499993,
                40.779042841999924
              ],
              [
                -73.76864150099995,
                40.77908686699987
              ],
              [
                -73.7685730499999,
                40.77910542899993
              ],
              [
                -73.76833788999983,
                40.7786825249999
              ],
              [
                -73.76825889099999,
                40.778702104999915
              ],
              [
                -73.76807192199988,
                40.77874844899989
              ],
              [
                -73.76796073100003,
                40.778776008999955
              ],
              [
                -73.76782207615058,
                40.77881037615661
              ],
              [
                -73.76807788861774,
                40.77925206154375
              ],
              [
                -73.76816173051576,
                40.77939682124199
              ],
              [
                -73.767135449099,
                40.77974233714537
              ],
              [
                -73.76708815912728,
                40.77966705475085
              ],
              [
                -73.76686345436596,
                40.779740341271875
              ],
              [
                -73.76686912971435,
                40.77974902775042
              ],
              [
                -73.76675868631202,
                40.77978350558992
              ],
              [
                -73.76677570373936,
                40.77981245869834
              ],
              [
                -73.76688994703832,
                40.779779433943396
              ],
              [
                -73.7669580014477,
                40.77989958115397
              ],
              [
                -73.7669275026482,
                40.779918316052
              ],
              [
                -73.76702392346498,
                40.780086234158276
              ],
              [
                -73.7670734335662,
                40.78007042899436
              ],
              [
                -73.76699783535086,
                40.77993147052596
              ],
              [
                -73.76720729367467,
                40.77986682861191
              ],
              [
                -73.76716193687588,
                40.779782875660686
              ],
              [
                -73.76820055460033,
                40.77944417919098
              ],
              [
                -73.76823190254096,
                40.77950163764268
              ],
              [
                -73.76813458838144,
                40.77958964044202
              ],
              [
                -73.76815915444048,
                40.77963595970004
              ],
              [
                -73.76827333570864,
                40.77962028411315
              ],
              [
                -73.76833795673872,
                40.779634872200724
              ],
              [
                -73.76858058549459,
                40.779876824912165
              ],
              [
                -73.76902445493923,
                40.780450591996654
              ],
              [
                -73.76908807714565,
                40.780532832334075
              ],
              [
                -73.76930405710117,
                40.78078195798143
              ],
              [
                -73.76981014523498,
                40.78129770845182
              ],
              [
                -73.77060974454855,
                40.78207979394072
              ],
              [
                -73.77160731979487,
                40.78299626518552
              ],
              [
                -73.7720920025976,
                40.78356969320761
              ],
              [
                -73.7726172080581,
                40.7841910491489
              ],
              [
                -73.77407638297778,
                40.785917284019234
              ],
              [
                -73.77409609895109,
                40.78629656530393
              ],
              [
                -73.77426466694423,
                40.78665122914519
              ],
              [
                -73.77435661274104,
                40.786661725101865
              ],
              [
                -73.77445462503042,
                40.78667990922231
              ],
              [
                -73.77451195192242,
                40.78672886084857
              ],
              [
                -73.77456236644174,
                40.7868214973437
              ],
              [
                -73.7745858466651,
                40.786878092588886
              ],
              [
                -73.7746308254633,
                40.78691941868919
              ],
              [
                -73.77474124745507,
                40.786927235879425
              ],
              [
                -73.774806953694,
                40.78697068727887
              ],
              [
                -73.77486248240422,
                40.787079130134615
              ],
              [
                -73.7750481043881,
                40.78719259407269
              ],
              [
                -73.77587233688504,
                40.788020317622845
              ],
              [
                -73.77633728818715,
                40.78848722575377
              ],
              [
                -73.77628944855435,
                40.78851364819941
              ],
              [
                -73.77501922678299,
                40.787394474384115
              ],
              [
                -73.77444617141909,
                40.78717856502963
              ],
              [
                -73.7743241010546,
                40.7870822535723
              ],
              [
                -73.7742301148687,
                40.787065041675966
              ],
              [
                -73.7741998086814,
                40.787026425249906
              ],
              [
                -73.77420002662137,
                40.78696216313966
              ],
              [
                -73.77426439520684,
                40.78692887354648
              ],
              [
                -73.77438976901033,
                40.78685714529809
              ],
              [
                -73.77439231197853,
                40.78681496092916
              ],
              [
                -73.7743460921796,
                40.78677223413504
              ],
              [
                -73.77426836058959,
                40.78675665865473
              ],
              [
                -73.77412967826919,
                40.78676409915587
              ],
              [
                -73.77396728847471,
                40.78678177421845
              ],
              [
                -73.77379096552599,
                40.78682895783375
              ],
              [
                -73.77228340517985,
                40.78730506717221
              ],
              [
                -73.77218471602598,
                40.78731517092361
              ],
              [
                -73.77208769981934,
                40.78733223636572
              ],
              [
                -73.77199320470947,
                40.787356114306654
              ],
              [
                -73.77190205680833,
                40.7873865959972
              ],
              [
                -73.77173756350666,
                40.787463608911835
              ],
              [
                -73.77161578634065,
                40.78752556176706
              ],
              [
                -73.77149672842718,
                40.787590496346304
              ],
              [
                -73.77138051609693,
                40.787658343752696
              ],
              [
                -73.77131348065242,
                40.78769644884393
              ],
              [
                -73.77124371742936,
                40.78773161177086
              ],
              [
                -73.7711714496876,
                40.787763720001394
              ],
              [
                -73.77082315021427,
                40.78798266705989
              ],
              [
                -73.77077056142382,
                40.788057028348895
              ],
              [
                -73.77072589190864,
                40.78813432354019
              ],
              [
                -73.77068942271313,
                40.78821406636863
              ],
              [
                -73.77066138329643,
                40.78829575516882
              ],
              [
                -73.77064195008725,
                40.78837887603175
              ],
              [
                -73.77063124537418,
                40.78846290603716
              ],
              [
                -73.77087816042668,
                40.78877673022536
              ],
              [
                -73.7711276990068,
                40.789012774212765
              ],
              [
                -73.77144337542126,
                40.78934263237261
              ],
              [
                -73.77166811281884,
                40.78959191109707
              ],
              [
                -73.77171860487624,
                40.78966141348849
              ],
              [
                -73.77174554616062,
                40.78969488352967
              ],
              [
                -73.77173336228098,
                40.789737871514205
              ],
              [
                -73.77173042355776,
                40.78978178881643
              ],
              [
                -73.77173680635667,
                40.789825494472346
              ],
              [
                -73.77175234487123,
                40.789867853014314
              ],
              [
                -73.7717788839305,
                40.789902911139144
              ],
              [
                -73.77181217739995,
                40.78993446464288
              ],
              [
                -73.77185143898816,
                40.789961768318506
              ],
              [
                -73.77189574144926,
                40.78998417732687
              ],
              [
                -73.77194403848256,
                40.79000116242745
              ],
              [
                -73.77202595346799,
                40.79006369256741
              ],
              [
                -73.77210255348926,
                40.79013000015937
              ],
              [
                -73.77213292826468,
                40.79017877433443
              ],
              [
                -73.7721726631949,
                40.79025261450209
              ],
              [
                -73.77220607891066,
                40.790328241164374
              ],
              [
                -73.77223303752142,
                40.790405342296026
              ],
              [
                -73.7722534277768,
                40.790483599787535
              ],
              [
                -73.77224206821533,
                40.79057121284437
              ],
              [
                -73.77223841615907,
                40.79065920649178
              ],
              [
                -73.77224248787607,
                40.79074718949841
              ],
              [
                -73.77226051428751,
                40.79084550439468
              ],
              [
                -73.77227075371162,
                40.790944463780626
              ],
              [
                -73.77227316928341,
                40.79104371171239
              ],
              [
                -73.77225850219455,
                40.791106455944906
              ],
              [
                -73.77225126597583,
                40.79116994495613
              ],
              [
                -73.77225151846997,
                40.79123367143485
              ],
              [
                -73.77225925768091,
                40.79129712617071
              ],
              [
                -73.7722744217891,
                40.791359802123246
              ],
              [
                -73.77229688964472,
                40.79142119847369
              ],
              [
                -73.7723264817354,
                40.791480824626525
              ],
              [
                -73.77236296162013,
                40.791538204130184
              ],
              [
                -73.7724137940452,
                40.791584593294274
              ],
              [
                -73.77246875261997,
                40.791628180201066
              ],
              [
                -73.77252757063758,
                40.79166875332356
              ],
              [
                -73.77260041750584,
                40.79172620880269
              ],
              [
                -73.77266865746701,
                40.79178684357744
              ],
              [
                -73.77272012268155,
                40.79184435699736
              ],
              [
                -73.77277910650301,
                40.7918975371452
              ],
              [
                -73.77286059684761,
                40.79197709653281
              ],
              [
                -73.77287743738252,
                40.79199769332085
              ],
              [
                -73.77307986542115,
                40.7921446089333
              ],
              [
                -73.77320467172578,
                40.79224253223185
              ],
              [
                -73.77327465890835,
                40.79232249185467
              ],
              [
                -73.77330230698016,
                40.79237072035312
              ],
              [
                -73.77333601221568,
                40.792416669600115
              ],
              [
                -73.77337545326392,
                40.79245990149119
              ],
              [
                -73.77342025408296,
                40.792500003830064
              ],
              [
                -73.77349649263613,
                40.79258050701903
              ],
              [
                -73.77356729524408,
                40.79266382842165
              ],
              [
                -73.77363248188914,
                40.792749756215066
              ],
              [
                -73.77368083470303,
                40.792865756594786
              ],
              [
                -73.77374986177655,
                40.793043021100154
              ],
              [
                -73.77374710611447,
                40.79308189124953
              ],
              [
                -73.77374905422272,
                40.7931207896111
              ],
              [
                -73.77375568967855,
                40.79315938810081
              ],
              [
                -73.77382293476086,
                40.793277193667734
              ],
              [
                -73.77388293130392,
                40.793397239007625
              ],
              [
                -73.77393555059898,
                40.79351926666971
              ],
              [
                -73.77398067975606,
                40.793643014950185
              ],
              [
                -73.7740182219482,
                40.79376821845375
              ],
              [
                -73.77403999017578,
                40.7938567944477
              ],
              [
                -73.77405795992715,
                40.79394586121933
              ],
              [
                -73.7740477938349,
                40.79395906121335
              ],
              [
                -73.77404068884668,
                40.793973371203144
              ],
              [
                -73.77403684676304,
                40.79398838475069
              ],
              [
                -73.77403637671088,
                40.79400367543495
              ],
              [
                -73.7740392920429,
                40.79401880896352
              ],
              [
                -73.77404550995895,
                40.79403335550695
              ],
              [
                -73.77405485385675,
                40.79404690190765
              ],
              [
                -73.77406705834852,
                40.794059063414146
              ],
              [
                -73.77408177679733,
                40.794069494609374
              ],
              [
                -73.77409859116338,
                40.7940778992209
              ],
              [
                -73.774117023877,
                40.794084038536745
              ],
              [
                -73.77413655140262,
                40.79408773818473
              ],
              [
                -73.7741642440045,
                40.7941109472795
              ],
              [
                -73.77418705214467,
                40.79413704580953
              ],
              [
                -73.7742044616393,
                40.794165445419615
              ],
              [
                -73.77421608000793,
                40.79419550587923
              ],
              [
                -73.7742455440165,
                40.79427361246052
              ],
              [
                -73.77428246509834,
                40.794349866750565
              ],
              [
                -73.77432664447697,
                40.79442385815788
              ],
              [
                -73.77437784429105,
                40.79449518827451
              ],
              [
                -73.77443578887555,
                40.79456347302087
              ],
              [
                -73.77445962347299,
                40.79458944088831
              ],
              [
                -73.77456410783662,
                40.79469605197662
              ],
              [
                -73.77467550675657,
                40.79479853995568
              ],
              [
                -73.77479353901879,
                40.79489664607736
              ],
              [
                -73.77491790665805,
                40.79499012265496
              ],
              [
                -73.77504829571026,
                40.79507873368925
              ],
              [
                -73.77505736680486,
                40.79508742368608
              ],
              [
                -73.77506900584751,
                40.79509699970999
              ],
              [
                -73.77507955910173,
                40.795105314324736
              ],
              [
                -73.77509364674852,
                40.795116130289976
              ],
              [
                -73.77510333604457,
                40.79512427200556
              ],
              [
                -73.77511846078075,
                40.79513667428005
              ],
              [
                -73.77512785665888,
                40.79514377894077
              ],
              [
                -73.77513845803097,
                40.79515194873437
              ],
              [
                -73.7751495765414,
                40.79516116472739
              ],
              [
                -73.77515952443582,
                40.79516969392658
              ],
              [
                -73.77517458092494,
                40.79518135827803
              ],
              [
                -73.77518559691597,
                40.79518953775478
              ],
              [
                -73.77519630388718,
                40.79519770875406
              ],
              [
                -73.77520778681627,
                40.7952081584399
              ],
              [
                -73.77521822784352,
                40.79521805761426
              ],
              [
                -73.77522841161719,
                40.795227110449574
              ],
              [
                -73.77524109301233,
                40.795236662186994
              ],
              [
                -73.77524930736935,
                40.79524285604281
              ],
              [
                -73.77526177777118,
                40.79525176725157
              ],
              [
                -73.77527655563806,
                40.79526187255653
              ],
              [
                -73.77528735896209,
                40.79526952122295
              ],
              [
                -73.77530079651218,
                40.79527997470999
              ],
              [
                -73.77531105858054,
                40.795290458693394
              ],
              [
                -73.77532069352814,
                40.7953005457583
              ],
              [
                -73.7753293011994,
                40.79530940553838
              ],
              [
                -73.77533948316074,
                40.795318807985964
              ],
              [
                -73.77534953272698,
                40.79532890406518
              ],
              [
                -73.77535993733348,
                40.79533935213663
              ],
              [
                -73.77536976075993,
                40.79534963774722
              ],
              [
                -73.77538072181515,
                40.795363661057834
              ],
              [
                -73.7753918834261,
                40.79537430057539
              ],
              [
                -73.77540246897253,
                40.79538332670378
              ],
              [
                -73.77541469117227,
                40.79539218363637
              ],
              [
                -73.77542669336691,
                40.79539967212575
              ],
              [
                -73.77543937631013,
                40.7954087830843
              ],
              [
                -73.77545298499133,
                40.795421191071895
              ],
              [
                -73.77546441562504,
                40.79543645045462
              ],
              [
                -73.77546627197862,
                40.795444603181714
              ],
              [
                -73.77547684312016,
                40.79545790670384
              ],
              [
                -73.77548828146153,
                40.795460622124196
              ],
              [
                -73.77550330387253,
                40.79547170823504
              ],
              [
                -73.77551457646572,
                40.79548108648949
              ],
              [
                -73.77552296493784,
                40.79548835165516
              ],
              [
                -73.77553195267372,
                40.79550061678085
              ],
              [
                -73.77553345786802,
                40.79550413188431
              ],
              [
                -73.77553146710926,
                40.795507333272965
              ],
              [
                -73.77554058341056,
                40.795513177563215
              ],
              [
                -73.7755552874506,
                40.79552036456107
              ],
              [
                -73.77555882387453,
                40.79553299717454
              ],
              [
                -73.77556708750666,
                40.79554558354151
              ],
              [
                -73.77557656568186,
                40.79555295904641
              ],
              [
                -73.77558981591773,
                40.795562837354424
              ],
              [
                -73.77560040629238,
                40.79557070955793
              ],
              [
                -73.77561001797245,
                40.79558066152715
              ],
              [
                -73.77562134647184,
                40.795594326019796
              ],
              [
                -73.77563103732929,
                40.79560178251391
              ],
              [
                -73.77564699764488,
                40.7956125745814
              ],
              [
                -73.7756646012886,
                40.79562095731017
              ],
              [
                -73.77566926949147,
                40.795628152067046
              ],
              [
                -73.77567574597047,
                40.79563664699041
              ],
              [
                -73.77568453628999,
                40.7956443186729
              ],
              [
                -73.77569673601043,
                40.795656012575655
              ],
              [
                -73.7757093058664,
                40.79566737432418
              ],
              [
                -73.7757177588325,
                40.79567677454593
              ],
              [
                -73.77573108370488,
                40.79568898376489
              ],
              [
                -73.77574126696302,
                40.795698035380305
              ],
              [
                -73.775752639847,
                40.795709007978786
              ],
              [
                -73.77576359025652,
                40.79571929625018
              ],
              [
                -73.77577227211225,
                40.79572743477591
              ],
              [
                -73.77578634075365,
                40.7957403662975
              ],
              [
                -73.77579847271905,
                40.79575117837077
              ],
              [
                -73.77581022054922,
                40.79576039451781
              ],
              [
                -73.77582084085186,
                40.79577013198783
              ],
              [
                -73.77583110101452,
                40.79578096671864
              ],
              [
                -73.77584290844239,
                40.79579317364331
              ],
              [
                -73.77584465498843,
                40.795795328904916
              ],
              [
                -73.7758523958762,
                40.795804889561275
              ],
              [
                -73.77586326528844,
                40.79581803112736
              ],
              [
                -73.77587233417897,
                40.79582704437839
              ],
              [
                -73.77588612311689,
                40.79583893065471
              ],
              [
                -73.77589905470377,
                40.795851689745454
              ],
              [
                -73.77590880304822,
                40.79586356816308
              ],
              [
                -73.7759196649338,
                40.79587508806209
              ],
              [
                -73.77593061168318,
                40.795886086618246
              ],
              [
                -73.7759406150201,
                40.795895823045214
              ],
              [
                -73.775953774968,
                40.79590446596955
              ],
              [
                -73.77596822218737,
                40.79591418406017
              ],
              [
                -73.77597432156729,
                40.79592555967183
              ],
              [
                -73.77598539923815,
                40.795940106332054
              ],
              [
                -73.77599720655718,
                40.7959491523693
              ],
              [
                -73.77601318102688,
                40.795958990355096
              ],
              [
                -73.7760293623414,
                40.795967694090244
              ],
              [
                -73.77603866568091,
                40.79597399705351
              ],
              [
                -73.77604725794872,
                40.79598427904022
              ],
              [
                -73.77605582008007,
                40.79599598443177
              ],
              [
                -73.77606737098047,
                40.79600734432828
              ],
              [
                -73.77607828619544,
                40.796017442526825
              ],
              [
                -73.77609128562229,
                40.79602781432162
              ],
              [
                -73.77610276926637,
                40.79603774241092
              ],
              [
                -73.77611429231715,
                40.79605030876703
              ],
              [
                -73.77612628144364,
                40.79606147953481
              ],
              [
                -73.77613963747997,
                40.796071546282526
              ],
              [
                -73.77615232522406,
                40.79607955870604
              ],
              [
                -73.77616429083893,
                40.79608723584757
              ],
              [
                -73.77617885953198,
                40.796096053362724
              ],
              [
                -73.77618988034116,
                40.79610268464799
              ],
              [
                -73.77620134180472,
                40.79611207140947
              ],
              [
                -73.77621823846742,
                40.79612308148995
              ],
              [
                -73.77623536554736,
                40.7961321740159
              ],
              [
                -73.77624895989177,
                40.7961381795581
              ],
              [
                -73.77626443652422,
                40.79614465698602
              ],
              [
                -73.77628271935279,
                40.79615224820701
              ],
              [
                -73.77629750991538,
                40.796158418565
              ],
              [
                -73.77631272968647,
                40.796163995205845
              ],
              [
                -73.77632679821677,
                40.79616986713598
              ],
              [
                -73.77634039397623,
                40.79617552187176
              ],
              [
                -73.77635820000582,
                40.796183705530794
              ],
              [
                -73.7763743933529,
                40.79619213801667
              ],
              [
                -73.7763880218685,
                40.796198674999495
              ],
              [
                -73.77640490129258,
                40.796207154378926
              ],
              [
                -73.77642436508934,
                40.79621578317571
              ],
              [
                -73.77644100212493,
                40.796222596038845
              ],
              [
                -73.77645834823085,
                40.79622995975813
              ],
              [
                -73.77647569741559,
                40.796236801471444
              ],
              [
                -73.7764911641103,
                40.79624283809001
              ],
              [
                -73.77650408698263,
                40.79624748249545
              ],
              [
                -73.77651555681871,
                40.79625117840095
              ],
              [
                -73.77653362678076,
                40.796258435290916
              ],
              [
                -73.77654790396049,
                40.796265729889726
              ],
              [
                -73.77655799331602,
                40.79627096451677
              ],
              [
                -73.77657209394047,
                40.79627832142479
              ],
              [
                -73.7765878071623,
                40.79628834762485
              ],
              [
                -73.7766030466047,
                40.79629858867733
              ],
              [
                -73.77661855053992,
                40.79630792174966
              ],
              [
                -73.77663779999115,
                40.796320007318094
              ],
              [
                -73.77665423802641,
                40.79632997125394
              ],
              [
                -73.77667185244594,
                40.79634213556841
              ],
              [
                -73.77668638249598,
                40.79634851226521
              ],
              [
                -73.7767037306246,
                40.7963552907849
              ],
              [
                -73.77672082896044,
                40.796362212052244
              ],
              [
                -73.77673442631956,
                40.796367542917544
              ],
              [
                -73.77674993579649,
                40.79637483937548
              ],
              [
                -73.77676727795311,
                40.79638379721282
              ],
              [
                -73.77678418987155,
                40.79639332999942
              ],
              [
                -73.77679777685923,
                40.79640156036803
              ],
              [
                -73.77681399145276,
                40.79641092058888
              ],
              [
                -73.7768261853407,
                40.79641761673881
              ],
              [
                -73.77684188553589,
                40.79642832789034
              ],
              [
                -73.77685666384485,
                40.79643845058071
              ],
              [
                -73.77687050489574,
                40.79644520469553
              ],
              [
                -73.77688598311,
                40.79645124949161
              ],
              [
                -73.77690167683939,
                40.79645679128879
              ],
              [
                -73.77691718770845,
                40.79646388133538
              ],
              [
                -73.77692817826858,
                40.79646925152678
              ],
              [
                -73.77694225105256,
                40.79647388939686
              ],
              [
                -73.77695981862222,
                40.796478812287695
              ],
              [
                -73.77697245275725,
                40.79648169187086
              ],
              [
                -73.77699143346936,
                40.79648579025019
              ],
              [
                -73.7770085428856,
                40.796489685818536
              ],
              [
                -73.77702305739517,
                40.79649361355523
              ],
              [
                -73.77703946639896,
                40.796497598227674
              ],
              [
                -73.77705491487234,
                40.79650213169629
              ],
              [
                -73.77707133827573,
                40.79650570143153
              ],
              [
                -73.77709075818873,
                40.796509449343056
              ],
              [
                -73.77710551097655,
                40.796512990043716
              ],
              [
                -73.77712309051303,
                40.79651796670836
              ],
              [
                -73.77714079232376,
                40.79652546721039
              ],
              [
                -73.77716048389526,
                40.79653321245802
              ],
              [
                -73.77717293031017,
                40.796538703545885
              ],
              [
                -73.77718793343199,
                40.796545368734854
              ],
              [
                -73.77720481055026,
                40.796551497944655
              ],
              [
                -73.77722588125384,
                40.79655751782156
              ],
              [
                -73.77724580769197,
                40.79656247270167
              ],
              [
                -73.77726240942357,
                40.79656565694303
              ],
              [
                -73.77727883366029,
                40.79656898558112
              ],
              [
                -73.77729522076002,
                40.796572608989926
              ],
              [
                -73.77730831725226,
                40.79657527248403
              ],
              [
                -73.77732802081981,
                40.79657950739745
              ],
              [
                -73.77734722706326,
                40.79658341668249
              ],
              [
                -73.77736151739849,
                40.796587154639624
              ],
              [
                -73.77737533353579,
                40.796590638548075
              ],
              [
                -73.77738937457792,
                40.79659450183192
              ],
              [
                -73.7774039129087,
                40.79659824126783
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000472062684608,
        "objectid": 22,
        "shape_leng": 0.126170229196,
        "location_id": 22,
        "zone": "Bensonhurst West",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.99254973599997,
                40.62427426799996
              ],
              [
                -73.99398197100003,
                40.62514139899989
              ],
              [
                -73.99432497399985,
                40.625342907999894
              ],
              [
                -73.99489280599995,
                40.625461793999925
              ],
              [
                -73.99473485699997,
                40.625614740999914
              ],
              [
                -73.99464972999995,
                40.62569717899992
              ],
              [
                -73.99507814899985,
                40.62580673699993
              ],
              [
                -73.99678847599995,
                40.626826050999895
              ],
              [
                -73.99687778399999,
                40.626530782999964
              ],
              [
                -73.99701633599989,
                40.62606161499991
              ],
              [
                -73.99724067099987,
                40.62528868699994
              ],
              [
                -73.99748403999989,
                40.62451984599993
              ],
              [
                -73.99771358999998,
                40.62368026999996
              ],
              [
                -73.99932090299998,
                40.62465524699996
              ],
              [
                -73.99994665,
                40.62405062199985
              ],
              [
                -74.00052987499996,
                40.623489938999946
              ],
              [
                -74.00120222399994,
                40.62284271199991
              ],
              [
                -74.00169380199992,
                40.62237242699998
              ],
              [
                -74.00227586299998,
                40.621814273999874
              ],
              [
                -74.00285701599996,
                40.62125364299992
              ],
              [
                -74.0034355699999,
                40.620695403999925
              ],
              [
                -74.00401906899995,
                40.62013618899991
              ],
              [
                -74.00459907,
                40.619576852999955
              ],
              [
                -74.00522665999992,
                40.61897315399989
              ],
              [
                -74.0058531989999,
                40.61837047699992
              ],
              [
                -74.00643394999992,
                40.617814716999874
              ],
              [
                -74.00701531599991,
                40.61725394599992
              ],
              [
                -74.00759806899993,
                40.61669446099994
              ],
              [
                -74.00817687599991,
                40.61613318099995
              ],
              [
                -74.00876039999996,
                40.61557582299988
              ],
              [
                -74.00934164499988,
                40.615015095999865
              ],
              [
                -74.00992371999989,
                40.61445664299992
              ],
              [
                -74.01050403599997,
                40.613896610999866
              ],
              [
                -74.01019858699995,
                40.61371216699994
              ],
              [
                -74.00830383399986,
                40.612567999999925
              ],
              [
                -74.00788398499994,
                40.61234007199987
              ],
              [
                -74.00708800599995,
                40.611907944999885
              ],
              [
                -74.00601438399993,
                40.61132508199995
              ],
              [
                -74.00342538899996,
                40.60996909299993
              ],
              [
                -74.00266468099994,
                40.60956996599991
              ],
              [
                -74.00185301899995,
                40.60913755999993
              ],
              [
                -74.00094408399997,
                40.608639709999885
              ],
              [
                -74.00130866099992,
                40.60834356899987
              ],
              [
                -74.00171808600001,
                40.60800297799994
              ],
              [
                -74.00195011999993,
                40.60781768299991
              ],
              [
                -74.00264412399989,
                40.60725717499991
              ],
              [
                -74.00442477199992,
                40.60556859699992
              ],
              [
                -74.00493448899992,
                40.60507440599987
              ],
              [
                -74.00444636799998,
                40.604801846999855
              ],
              [
                -74.00416249599996,
                40.604654738999926
              ],
              [
                -74.00340902699993,
                40.60425938099994
              ],
              [
                -74.002630697,
                40.60384644899989
              ],
              [
                -74.00184603099991,
                40.603431431999866
              ],
              [
                -74.00109809599998,
                40.60303462599992
              ],
              [
                -74.00032353899996,
                40.60262029199992
              ],
              [
                -74.00002266099992,
                40.60290731599988
              ],
              [
                -73.99927902000003,
                40.60245801699991
              ],
              [
                -73.998563851,
                40.60202843399991
              ],
              [
                -73.99782332599999,
                40.60157817899993
              ],
              [
                -73.99708036399986,
                40.60112882899989
              ],
              [
                -73.9963651649999,
                40.600696053999904
              ],
              [
                -73.99559320799995,
                40.6002322219999
              ],
              [
                -73.99733919599988,
                40.5985516119999
              ],
              [
                -73.99865586599988,
                40.59726856199988
              ],
              [
                -73.99931921399997,
                40.596640750999924
              ],
              [
                -73.99951049399985,
                40.59644180899987
              ],
              [
                -73.9999650309999,
                40.59599430299989
              ],
              [
                -73.99999769599995,
                40.59596779199991
              ],
              [
                -74.00004233999991,
                40.59594037099991
              ],
              [
                -74.00027871999994,
                40.595702668999905
              ],
              [
                -74.00200394804673,
                40.594132371137796
              ],
              [
                -74.00184415135462,
                40.593977667237965
              ],
              [
                -74.00193320208321,
                40.59389469541273
              ],
              [
                -74.00164017530518,
                40.59348065580984
              ],
              [
                -74.00105748445708,
                40.59253700506231
              ],
              [
                -74.00030548953626,
                40.592804778399795
              ],
              [
                -73.99854421315825,
                40.59359676275945
              ],
              [
                -73.99838831091802,
                40.59353139180308
              ],
              [
                -73.99736802499984,
                40.59397431099995
              ],
              [
                -73.99633951299984,
                40.59362247499995
              ],
              [
                -73.99630983500005,
                40.593667751999895
              ],
              [
                -73.99629319699989,
                40.59371644199992
              ],
              [
                -73.99619345599999,
                40.593992197999896
              ],
              [
                -73.99535761899992,
                40.594403327999935
              ],
              [
                -73.99530829599992,
                40.594427586999906
              ],
              [
                -73.99549868199995,
                40.59459440699994
              ],
              [
                -73.99587725299996,
                40.59491413699993
              ],
              [
                -73.99611613099992,
                40.59511588599987
              ],
              [
                -73.99639126099986,
                40.59534580199989
              ],
              [
                -73.99669451199998,
                40.595599205999875
              ],
              [
                -73.99674706899995,
                40.59564312599992
              ],
              [
                -73.99630015799983,
                40.59606105399993
              ],
              [
                -73.99522558800007,
                40.59709332799986
              ],
              [
                -73.99336225799983,
                40.59888868899989
              ],
              [
                -73.99159842599994,
                40.60058036599989
              ],
              [
                -73.99097171199985,
                40.601184559999865
              ],
              [
                -73.99038998499982,
                40.60174340299995
              ],
              [
                -73.989807573,
                40.60230291099994
              ],
              [
                -73.9892234389999,
                40.60286567499987
              ],
              [
                -73.98869008799991,
                40.603425306999966
              ],
              [
                -73.98806312999994,
                40.60397945199985
              ],
              [
                -73.98747998199978,
                40.60453971199996
              ],
              [
                -73.9868476259999,
                40.60509723599994
              ],
              [
                -73.98864527999999,
                40.606166653999914
              ],
              [
                -73.98913191499994,
                40.60645613699991
              ],
              [
                -73.98855078899993,
                40.60700228299991
              ],
              [
                -73.98842267599987,
                40.60711914899985
              ],
              [
                -73.98796510300004,
                40.60756553599988
              ],
              [
                -73.9873779529998,
                40.608128117999875
              ],
              [
                -73.98733917699988,
                40.60816828599996
              ],
              [
                -73.98671329999988,
                40.60876626099987
              ],
              [
                -73.98612915399993,
                40.60932986399989
              ],
              [
                -73.98575987199992,
                40.60968408399991
              ],
              [
                -73.98554853199988,
                40.60988724299989
              ],
              [
                -73.98535587499991,
                40.61007201399988
              ],
              [
                -73.98496383499995,
                40.61044799399991
              ],
              [
                -73.98438244899988,
                40.611005419999884
              ],
              [
                -73.984162011,
                40.611217300999876
              ],
              [
                -73.98380893599982,
                40.611559872999855
              ],
              [
                -73.98335142399998,
                40.61199230099992
              ],
              [
                -73.98322226999993,
                40.61212220899992
              ],
              [
                -73.98265927499982,
                40.61269247099993
              ],
              [
                -73.98215550199991,
                40.613149614999934
              ],
              [
                -73.98206106199993,
                40.613238418999885
              ],
              [
                -73.98174843299985,
                40.613554623999946
              ],
              [
                -73.98143750099992,
                40.61383768799993
              ],
              [
                -73.98080506199987,
                40.61444712999986
              ],
              [
                -73.98022027499997,
                40.6150049489999
              ],
              [
                -73.97963821799982,
                40.61556454699991
              ],
              [
                -73.98003930399985,
                40.61580692999996
              ],
              [
                -73.98087912799988,
                40.61631443499988
              ],
              [
                -73.98186818299985,
                40.61691212699987
              ],
              [
                -73.98406791799982,
                40.61824073899989
              ],
              [
                -73.98627524699981,
                40.61956661699989
              ],
              [
                -73.9856935779999,
                40.62013151399989
              ],
              [
                -73.98785095499996,
                40.62143503199992
              ],
              [
                -73.99009356099988,
                40.62278895299992
              ],
              [
                -73.99254973599997,
                40.62427426799996
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.00219556576201,
        "objectid": 23,
        "shape_leng": 0.290556028962,
        "location_id": 23,
        "zone": "Bloomfield/Emerson Hill",
        "borough": "Staten Island"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -74.19568609223377,
                40.63501686464005
              ],
              [
                -74.19580605679684,
                40.63505839723762
              ],
              [
                -74.1958835533078,
                40.635066961838355
              ],
              [
                -74.19596386706435,
                40.63503051962415
              ],
              [
                -74.19602904691446,
                40.63499584880699
              ],
              [
                -74.19603260182832,
                40.63497335851836
              ],
              [
                -74.19602261159346,
                40.634910511483554
              ],
              [
                -74.19603303929244,
                40.63487718727506
              ],
              [
                -74.19609834576855,
                40.6348277486921
              ],
              [
                -74.19611983392683,
                40.634806963582236
              ],
              [
                -74.19614123036082,
                40.63479275899455
              ],
              [
                -74.19616074043309,
                40.63477810529293
              ],
              [
                -74.19619879725332,
                40.63473021017521
              ],
              [
                -74.19620359550302,
                40.634686050233725
              ],
              [
                -74.19618464614511,
                40.634625724563676
              ],
              [
                -74.19625489591702,
                40.634560796271884
              ],
              [
                -74.19633289229948,
                40.634526119318906
              ],
              [
                -74.19635291542002,
                40.634472272312514
              ],
              [
                -74.19636957213456,
                40.634417577994135
              ],
              [
                -74.19638286583896,
                40.63436288891421
              ],
              [
                -74.19639167415882,
                40.63430820799612
              ],
              [
                -74.19639381410525,
                40.634273182913496
              ],
              [
                -74.19640266897571,
                40.63423387772307
              ],
              [
                -74.19642830481342,
                40.634182583134894
              ],
              [
                -74.19646965159124,
                40.63413553368534
              ],
              [
                -74.19652335974168,
                40.634098712683645
              ],
              [
                -74.19658156160821,
                40.63406444649692
              ],
              [
                -74.19662858901309,
                40.63404301217345
              ],
              [
                -74.19670031696526,
                40.63403349463375
              ],
              [
                -74.19677318194454,
                40.63402909847444
              ],
              [
                -74.19680564860418,
                40.634012814042094
              ],
              [
                -74.19684924611059,
                40.63396917735558
              ],
              [
                -74.1968861882006,
                40.63394946798223
              ],
              [
                -74.19693997159443,
                40.63393827205497
              ],
              [
                -74.19701172021942,
                40.63393558661944
              ],
              [
                -74.1970341703711,
                40.63394408877805
              ],
              [
                -74.19707345771366,
                40.63395939675499
              ],
              [
                -74.19710932737318,
                40.63395677325026
              ],
              [
                -74.19714960710607,
                40.63392851677314
              ],
              [
                -74.19719319610093,
                40.63388231744664
              ],
              [
                -74.19723678514949,
                40.63383526387301
              ],
              [
                -74.19727252112973,
                40.6337873690232
              ],
              [
                -74.19728584361084,
                40.633742929877464
              ],
              [
                -74.19728567947706,
                40.63368740954743
              ],
              [
                -74.19726870369098,
                40.633633625851914
              ],
              [
                -74.19724948428885,
                40.633579847175056
              ],
              [
                -74.19722689857569,
                40.633525220541394
              ],
              [
                -74.19722682789016,
                40.63350130305227
              ],
              [
                -74.19726255931032,
                40.63345170042102
              ],
              [
                -74.1972871082701,
                40.633412366556634
              ],
              [
                -74.19728474265855,
                40.633370517016715
              ],
              [
                -74.19727448930175,
                40.63331501399527
              ],
              [
                -74.19728675222868,
                40.63329193108206
              ],
              [
                -74.19732704477023,
                40.63326794432946
              ],
              [
                -74.19739537162704,
                40.63324562007614
              ],
              [
                -74.19746144974295,
                40.63322158950801
              ],
              [
                -74.19748941010829,
                40.633198478608854
              ],
              [
                -74.19752514502032,
                40.633149730620595
              ],
              [
                -74.19754725355178,
                40.633117552201405
              ],
              [
                -74.19754747163212,
                40.6331172345268
              ],
              [
                -74.19756087372008,
                40.63309927316573
              ],
              [
                -74.19759109214782,
                40.633081284581166
              ],
              [
                -74.19762359861335,
                40.6330786650191
              ],
              [
                -74.19768982830382,
                40.633105885532935
              ],
              [
                -74.19770892162028,
                40.63311695702781
              ],
              [
                -74.19770944064285,
                40.63311727343526
              ],
              [
                -74.19774935751732,
                40.63314165816059
              ],
              [
                -74.1978032825518,
                40.633178294628884
              ],
              [
                -74.19785945083686,
                40.63321492603911
              ],
              [
                -74.19791561643243,
                40.6332507038661
              ],
              [
                -74.1979593894156,
                40.6332668573802
              ],
              [
                -74.19803227375793,
                40.63326929557059
              ],
              [
                -74.19809389789796,
                40.63325552139831
              ],
              [
                -74.1981599865825,
                40.63323490883927
              ],
              [
                -74.1982249336577,
                40.633207463697445
              ],
              [
                -74.19828426341229,
                40.633175756719254
              ],
              [
                -74.19834358263705,
                40.63314148770751
              ],
              [
                -74.19837451123031,
                40.63311612792367
              ],
              [
                -74.19837490082801,
                40.63311580995158
              ],
              [
                -74.19838273313529,
                40.63311067139134
              ],
              [
                -74.19840057855035,
                40.633079035920225
              ],
              [
                -74.19839937094993,
                40.633049997522576
              ],
              [
                -74.19835215694141,
                40.63300822353622
              ],
              [
                -74.19833525916698,
                40.6329809201619
              ],
              [
                -74.19834081295598,
                40.63296382798489
              ],
              [
                -74.19838103570825,
                40.63291677977717
              ],
              [
                -74.19842463212467,
                40.632873141314356
              ],
              [
                -74.1984749664375,
                40.63283290963337
              ],
              [
                -74.1984835284849,
                40.63282811893675
              ],
              [
                -74.19850080496613,
                40.63279791127553
              ],
              [
                -74.19852346205487,
                40.63278304902469
              ],
              [
                -74.19856881854885,
                40.63276814773782
              ],
              [
                -74.19869022038306,
                40.63269876093375
              ],
              [
                -74.19883067612236,
                40.632568535059754
              ],
              [
                -74.19888910947763,
                40.63251435781241
              ],
              [
                -74.19922396189709,
                40.632250661395354
              ],
              [
                -74.19958138195453,
                40.631943687757676
              ],
              [
                -74.19967512075753,
                40.6318741689966
              ],
              [
                -74.19969860102778,
                40.63185277480096
              ],
              [
                -74.19975342724327,
                40.63181595033311
              ],
              [
                -74.19980488565277,
                40.63177742339801
              ],
              [
                -74.19986082809585,
                40.63173888914794
              ],
              [
                -74.19991789471179,
                40.63170120597339
              ],
              [
                -74.19997047608236,
                40.63166353224651
              ],
              [
                -74.20002079954769,
                40.63162073620256
              ],
              [
                -74.20006776010999,
                40.631577946658005
              ],
              [
                -74.20010798593165,
                40.631532606123685
              ],
              [
                -74.2001381058405,
                40.63148215761574
              ],
              [
                -74.20015700674327,
                40.63142916660897
              ],
              [
                -74.2001726540772,
                40.631361080530986
              ],
              [
                -74.20033675656403,
                40.63140284333178
              ],
              [
                -74.20033268403984,
                40.631407607422595
              ],
              [
                -74.20032973106245,
                40.631412828712904
              ],
              [
                -74.20032798319177,
                40.63141835592081
              ],
              [
                -74.2003274910707,
                40.63142402890097
              ],
              [
                -74.20032826895854,
                40.63142968328424
              ],
              [
                -74.2003302943169,
                40.63143515524033
              ],
              [
                -74.2003335084633,
                40.63144028622456
              ],
              [
                -74.2003378182712,
                40.631444927571486
              ],
              [
                -74.200343098868,
                40.63144894480253
              ],
              [
                -74.20034919725371,
                40.63145222152195
              ],
              [
                -74.20035593673342,
                40.63145466279017
              ],
              [
                -74.20036312203683,
                40.631456197873625
              ],
              [
                -74.20037054497672,
                40.631456782294556
              ],
              [
                -74.20037799048002,
                40.631456399120054
              ],
              [
                -74.20038524282045,
                40.63145505945216
              ],
              [
                -74.20039209186776,
                40.631452802106566
              ],
              [
                -74.20039833917748,
                40.63144969248777
              ],
              [
                -74.20040380373977,
                40.63144582069403
              ],
              [
                -74.20040832722417,
                40.63144129890703
              ],
              [
                -74.2004117785669,
                40.63143625814106
              ],
              [
                -74.20041405776921,
                40.631430844447834
              ],
              [
                -74.20043739011423,
                40.63137453211302
              ],
              [
                -74.20043671652917,
                40.63136938061631
              ],
              [
                -74.20043493139015,
                40.6313643854591
              ],
              [
                -74.20043208409267,
                40.6313596848581
              ],
              [
                -74.20042825342215,
                40.63135540887985
              ],
              [
                -74.20042354537391,
                40.6313516758412
              ],
              [
                -74.2004180902205,
                40.63134858903589
              ],
              [
                -74.20041203890698,
                40.63134623387634
              ],
              [
                -74.20040555887397,
                40.631344675530165
              ],
              [
                -74.2003988294248,
                40.6313439571169
              ],
              [
                -74.20039203676454,
                40.63134409851536
              ],
              [
                -74.20038536884705,
                40.6313450958127
              ],
              [
                -74.20037901017413,
                40.63134692141381
              ],
              [
                -74.20037313669165,
                40.631349524804016
              ],
              [
                -74.20036791091931,
                40.631352833947105
              ],
              [
                -74.20036347745562,
                40.63135675727859
              ],
              [
                -74.20035346710812,
                40.63137723655129
              ],
              [
                -74.20018462159042,
                40.631328669464196
              ],
              [
                -74.20018695424578,
                40.63132149046941
              ],
              [
                -74.20018694655916,
                40.63131892795863
              ],
              [
                -74.20020248154825,
                40.63126508807041
              ],
              [
                -74.20021577082558,
                40.631210399032504
              ],
              [
                -74.20023018131249,
                40.6311557075376
              ],
              [
                -74.20024347054529,
                40.63110101849597
              ],
              [
                -74.20025788164706,
                40.63104632749832
              ],
              [
                -74.20027229016034,
                40.630990781769896
              ],
              [
                -74.20028669542474,
                40.63093438131187
              ],
              [
                -74.2003067104728,
                40.63087882631436
              ],
              [
                -74.2003222411613,
                40.63082413285406
              ],
              [
                -74.20032768364649,
                40.63076945747148
              ],
              [
                -74.20032975474884,
                40.630712223762785
              ],
              [
                -74.20033518820867,
                40.63065498637311
              ],
              [
                -74.20034398232109,
                40.63059688738854
              ],
              [
                -74.20035614844025,
                40.63054134555402
              ],
              [
                -74.20036382796738,
                40.63048495730576
              ],
              [
                -74.20037487473546,
                40.6304302721524
              ],
              [
                -74.20038928301837,
                40.630374726405535
              ],
              [
                -74.20040311643059,
                40.630292362267326
              ],
              [
                -74.20070838016382,
                40.6303238094088
              ],
              [
                -74.20070685171133,
                40.630329862151
              ],
              [
                -74.2007066548433,
                40.630336024068
              ],
              [
                -74.20070779495533,
                40.63034212629852
              ],
              [
                -74.2007102408041,
                40.63034800161688
              ],
              [
                -74.20071392536379,
                40.63035348901558
              ],
              [
                -74.20071874766295,
                40.63035843811795
              ],
              [
                -74.20072457555138,
                40.63036271329854
              ],
              [
                -74.20073124932135,
                40.63036619740011
              ],
              [
                -74.20073858608478,
                40.630368794944125
              ],
              [
                -74.20074638478476,
                40.630370434747476
              ],
              [
                -74.2007524718044,
                40.630370521840945
              ],
              [
                -74.20075849749085,
                40.63036985941536
              ],
              [
                -74.20076430389027,
                40.63036846483531
              ],
              [
                -74.20076973879723,
                40.63036637465731
              ],
              [
                -74.20077465974444,
                40.630363643672105
              ],
              [
                -74.20077843632254,
                40.63036078942091
              ],
              [
                -74.20078164644988,
                40.63035755771075
              ],
              [
                -74.20078422588257,
                40.630354013218
              ],
              [
                -74.20078612299848,
                40.63035022687842
              ],
              [
                -74.20078729983089,
                40.630346274467826
              ],
              [
                -74.20079407663107,
                40.63027515375254
              ],
              [
                -74.20079486789565,
                40.63026685432143
              ],
              [
                -74.20079439753887,
                40.63026333379349
              ],
              [
                -74.20079319764672,
                40.63025991519235
              ],
              [
                -74.20079129881583,
                40.63025668569054
              ],
              [
                -74.2007887494656,
                40.63025372763846
              ],
              [
                -74.200785614603,
                40.63025111646497
              ],
              [
                -74.20078197416555,
                40.63024891875351
              ],
              [
                -74.20077792098232,
                40.63024719054442
              ],
              [
                -74.20077355840719,
                40.63024597590625
              ],
              [
                -74.2007689976836,
                40.630245305811385
              ],
              [
                -74.20076435510724,
                40.63024519734712
              ],
              [
                -74.20075810712619,
                40.63024557002223
              ],
              [
                -74.20075201035624,
                40.63024667564219
              ],
              [
                -74.20074621200907,
                40.630248487510706
              ],
              [
                -74.20074085209063,
                40.63025096187886
              ],
              [
                -74.20073606002079,
                40.63025403900086
              ],
              [
                -74.20073195150815,
                40.63025764457701
              ],
              [
                -74.2007286257561,
                40.630261691547645
              ],
              [
                -74.20072616306808,
                40.63026608219535
              ],
              [
                -74.20072423728168,
                40.63027500781988
              ],
              [
                -74.20061725250865,
                40.630265677217714
              ],
              [
                -74.20061528535054,
                40.63026062529332
              ],
              [
                -74.20052899588633,
                40.63025393577904
              ],
              [
                -74.20052900571373,
                40.630257206431644
              ],
              [
                -74.20047491385961,
                40.630253030444706
              ],
              [
                -74.20046497314343,
                40.630252262233434
              ],
              [
                -74.20046414832257,
                40.63023769098917
              ],
              [
                -74.20043408735438,
                40.63023625624203
              ],
              [
                -74.20043452432516,
                40.63025172075749
              ],
              [
                -74.20043065947087,
                40.63025148374015
              ],
              [
                -74.20040812771613,
                40.63025010069454
              ],
              [
                -74.20041343977792,
                40.63020555986685
              ],
              [
                -74.20042223823503,
                40.63014916966209
              ],
              [
                -74.2004231933758,
                40.630093647359644
              ],
              [
                -74.20042414785866,
                40.63003812623056
              ],
              [
                -74.20042510042924,
                40.62998174919866
              ],
              [
                -74.2004249542151,
                40.629933062325385
              ],
              [
                -74.20042366560808,
                40.629877543932906
              ],
              [
                -74.20042573785118,
                40.629821165953366
              ],
              [
                -74.20043565877462,
                40.62976477328593
              ],
              [
                -74.20045119063903,
                40.62971008030162
              ],
              [
                -74.2004768171585,
                40.629657077504966
              ],
              [
                -74.20052497747263,
                40.62964076464405
              ],
              [
                -74.20057987864897,
                40.629629564549816
              ],
              [
                -74.20061121332972,
                40.62961071848003
              ],
              [
                -74.20062123354481,
                40.62958763810688
              ],
              [
                -74.20075483895435,
                40.62959463411348
              ],
              [
                -74.2008047968127,
                40.629332571444245
              ],
              [
                -74.20098625499442,
                40.62934414810755
              ],
              [
                -74.20139258099198,
                40.62624907671134
              ],
              [
                -74.20139260637572,
                40.62624889054192
              ],
              [
                -74.20141670667907,
                40.62607051389568
              ],
              [
                -74.2012161553177,
                40.62605377157217
              ],
              [
                -74.20120831355543,
                40.626111878368796
              ],
              [
                -74.20118252353812,
                40.62611021570943
              ],
              [
                -74.20117014779946,
                40.62609571627478
              ],
              [
                -74.20113189608449,
                40.62605051331071
              ],
              [
                -74.20109587274136,
                40.626001033773036
              ],
              [
                -74.20107666274832,
                40.625950672933406
              ],
              [
                -74.20106080033608,
                40.62589518001203
              ],
              [
                -74.20105950832702,
                40.62583795214059
              ],
              [
                -74.20106158505429,
                40.62578328257848
              ],
              [
                -74.20106365794909,
                40.625726904058006
              ],
              [
                -74.20106236889201,
                40.62567138564773
              ],
              [
                -74.20106108071656,
                40.6256158672357
              ],
              [
                -74.2010609057428,
                40.62555778435069
              ],
              [
                -74.2010517640208,
                40.62550057188019
              ],
              [
                -74.20103706793152,
                40.62545959800456
              ],
              [
                -74.2010100798537,
                40.625432310803724
              ],
              [
                -74.20098195802754,
                40.625400756935896
              ],
              [
                -74.20097848041523,
                40.62536232596601
              ],
              [
                -74.20098504239097,
                40.625307648059035
              ],
              [
                -74.20099607727869,
                40.62524954440204
              ],
              [
                -74.20100599469657,
                40.62519230027887
              ],
              [
                -74.20100853518737,
                40.62517392375304
              ],
              [
                -74.20101367409275,
                40.62513676618451
              ],
              [
                -74.20102247739634,
                40.62508208318256
              ],
              [
                -74.20103015573068,
                40.625025694354385
              ],
              [
                -74.20103671160895,
                40.62496930916359
              ],
              [
                -74.20104439249307,
                40.62491377506318
              ],
              [
                -74.20105655441857,
                40.624857378391575
              ],
              [
                -74.2010709587324,
                40.62480183253037
              ],
              [
                -74.20108872991592,
                40.62474628077798
              ],
              [
                -74.20110537966264,
                40.62469158571791
              ],
              [
                -74.20111978609876,
                40.62463603984578
              ],
              [
                -74.20113418927706,
                40.62457963974591
              ],
              [
                -74.20113962867143,
                40.624524109558074
              ],
              [
                -74.20114394150578,
                40.62446772777967
              ],
              [
                -74.2011448901549,
                40.62441049648282
              ],
              [
                -74.20113912157518,
                40.62435584063679
              ],
              [
                -74.20110421636812,
                40.62430550557715
              ],
              [
                -74.20108835947418,
                40.62425172210849
              ],
              [
                -74.20109042715195,
                40.62419363529245
              ],
              [
                -74.20108128389592,
                40.62413556808517
              ],
              [
                -74.20107662790329,
                40.624078346589776
              ],
              [
                -74.20109892401528,
                40.624037308010244
              ],
              [
                -74.20113576602957,
                40.62398684793748
              ],
              [
                -74.20117823304412,
                40.62394321152425
              ],
              [
                -74.20122634786158,
                40.623913232071956
              ],
              [
                -74.20127892559044,
                40.62387641076836
              ],
              [
                -74.20132924492235,
                40.62383446834302
              ],
              [
                -74.20136497069723,
                40.62378571911504
              ],
              [
                -74.20139733073567,
                40.62373612103803
              ],
              [
                -74.20141176249858,
                40.62368911688869
              ],
              [
                -74.20140831691215,
                40.623661788529624
              ],
              [
                -74.20138019476259,
                40.6236302342523
              ],
              [
                -74.20133748521867,
                40.62359357979319
              ],
              [
                -74.20133404548383,
                40.62356796088904
              ],
              [
                -74.20134408905345,
                40.6235525686443
              ],
              [
                -74.20137209129264,
                40.623544832522406
              ],
              [
                -74.20144495652622,
                40.6235447048681
              ],
              [
                -74.20151667919954,
                40.62353689041873
              ],
              [
                -74.20156258549943,
                40.62351887376552
              ],
              [
                -74.20158494435557,
                40.62349833368139
              ],
              [
                -74.20159939399505,
                40.62345730875961
              ],
              [
                -74.20161267733178,
                40.623401764786756
              ],
              [
                -74.20162484432517,
                40.623347077503716
              ],
              [
                -74.20163139695073,
                40.623289836366894
              ],
              [
                -74.20163346671013,
                40.62323260493476
              ],
              [
                -74.2016321787644,
                40.623177085843594
              ],
              [
                -74.20163201104123,
                40.623121565457346
              ],
              [
                -74.20162847442838,
                40.62306434318696
              ],
              [
                -74.20161933837996,
                40.623008838528925
              ],
              [
                -74.20160235599973,
                40.62295334762954
              ],
              [
                -74.20158537726876,
                40.622899565183864
              ],
              [
                -74.20157400062897,
                40.622844063779915
              ],
              [
                -74.2015670984474,
                40.622785994348455
              ],
              [
                -74.20156132642177,
                40.62273048378606
              ],
              [
                -74.20154553334774,
                40.62269805202311
              ],
              [
                -74.20152419915728,
                40.62268613202665
              ],
              [
                -74.20145020839273,
                40.622684552739756
              ],
              [
                -74.20137624811906,
                40.62269322412822
              ],
              [
                -74.20130229725306,
                40.622705312713016
              ],
              [
                -74.20128435897738,
                40.62270448888547
              ],
              [
                -74.20127534263133,
                40.62268827628605
              ],
              [
                -74.20128760353833,
                40.6226651919127
              ],
              [
                -74.20131102145736,
                40.62262415132081
              ],
              [
                -74.20132319150865,
                40.622571171852606
              ],
              [
                -74.20132414578988,
                40.62251564899545
              ],
              [
                -74.20131276554288,
                40.62245929518175
              ],
              [
                -74.2012946695401,
                40.622406368711246
              ],
              [
                -74.20127768566681,
                40.622350023532405
              ],
              [
                -74.20126182328333,
                40.622294530618895
              ],
              [
                -74.20124484631499,
                40.6222407486194
              ],
              [
                -74.20123122680886,
                40.62218525177478
              ],
              [
                -74.20122657597591,
                40.62212973923008
              ],
              [
                -74.20123089203481,
                40.622074210989474
              ],
              [
                -74.20124305586253,
                40.62201866901082
              ],
              [
                -74.20125970678117,
                40.621963973909246
              ],
              [
                -74.20126518935844,
                40.6219238177795
              ],
              [
                -74.20126628533909,
                40.62191527404903
              ],
              [
                -74.20128300465908,
                40.62188364055947
              ],
              [
                -74.20128966370712,
                40.621861420223055
              ],
              [
                -74.20129622163314,
                40.62180588972822
              ],
              [
                -74.20129941204375,
                40.62174865448848
              ],
              [
                -74.2013014781412,
                40.62169056765576
              ],
              [
                -74.20130810779165,
                40.621658098267204
              ],
              [
                -74.20132939502028,
                40.62165464372825
              ],
              [
                -74.20140225479615,
                40.62165280831419
              ],
              [
                -74.20142240959545,
                40.62164508475219
              ],
              [
                -74.20143578875664,
                40.6216211448395
              ],
              [
                -74.20143239998035,
                40.62161260896536
              ],
              [
                -74.20138303786867,
                40.621599883817055
              ],
              [
                -74.20133145358044,
                40.62159399488333
              ],
              [
                -74.2012888342649,
                40.62158638143176
              ],
              [
                -74.20125847310123,
                40.62155483087214
              ],
              [
                -74.20123366361713,
                40.62150618662763
              ],
              [
                -74.20123130559402,
                40.62146775368989
              ],
              [
                -74.2012311382086,
                40.62141223329329
              ],
              [
                -74.20121079614697,
                40.62135760227292
              ],
              [
                -74.20119716912667,
                40.62129954340837
              ],
              [
                -74.20117906131713,
                40.621242346112254
              ],
              [
                -74.20114977443346,
                40.621195418481655
              ],
              [
                -74.20113848207161,
                40.621168105512524
              ],
              [
                -74.2011271819975,
                40.621138230028755
              ],
              [
                -74.20112483433921,
                40.621103212152576
              ],
              [
                -74.20112138194689,
                40.62107332293821
              ],
              [
                -74.20108983459889,
                40.6210204199609
              ],
              [
                -74.20106836913772,
                40.620964936814566
              ],
              [
                -74.20102119611141,
                40.62093426882064
              ],
              [
                -74.20100089721804,
                40.62089415945369
              ],
              [
                -74.200986158669,
                40.620838664529764
              ],
              [
                -74.20099047807766,
                40.62078399101909
              ],
              [
                -74.2009826019348,
                40.6207746087449
              ],
              [
                -74.20093094529145,
                40.62074394905491
              ],
              [
                -74.20092192004205,
                40.62072517341098
              ],
              [
                -74.20092969500968,
                40.62070124284694
              ],
              [
                -74.20092966672648,
                40.620691847354756
              ],
              [
                -74.20089935755496,
                40.62067737922776
              ],
              [
                -74.20087689917773,
                40.62066460634049
              ],
              [
                -74.20087674494054,
                40.620613356236184
              ],
              [
                -74.2008733158235,
                40.6205911557263
              ],
              [
                -74.20084750825812,
                40.62058265733011
              ],
              [
                -74.20077347485226,
                40.62056655943544
              ],
              [
                -74.20071518908162,
                40.62056836900847
              ],
              [
                -74.2006412284865,
                40.62057618469373
              ],
              [
                -74.20056725122791,
                40.62057802157746
              ],
              [
                -74.20052688004162,
                40.62057296745375
              ],
              [
                -74.20045955296372,
                40.620550021963794
              ],
              [
                -74.2004370934674,
                40.62053639476104
              ],
              [
                -74.2004280019192,
                40.62049541101681
              ],
              [
                -74.20043245103147,
                40.62048429891995
              ],
              [
                -74.20047052940691,
                40.62047227562744
              ],
              [
                -74.20049514742536,
                40.6204577110937
              ],
              [
                -74.20050515347535,
                40.620430359238036
              ],
              [
                -74.2004905276734,
                40.620412448564615
              ],
              [
                -74.20046240094837,
                40.620378331033535
              ],
              [
                -74.20045783099042,
                40.62035015204182
              ],
              [
                -74.20046674061955,
                40.620330490855046
              ],
              [
                -74.20050588129898,
                40.620299672101225
              ],
              [
                -74.20053613507298,
                40.62029534783707
              ],
              [
                -74.20057989124972,
                40.62030808480546
              ],
              [
                -74.20064051727076,
                40.620339582561094
              ],
              [
                -74.20070002640486,
                40.62037193646623
              ],
              [
                -74.20077407925909,
                40.6203948700693
              ],
              [
                -74.20084920460141,
                40.62040157235659
              ],
              [
                -74.20092318554512,
                40.620400588846124
              ],
              [
                -74.20099715575674,
                40.62039618804553
              ],
              [
                -74.20105206983467,
                40.62039182169896
              ],
              [
                -74.2010699746938,
                40.620381540777615
              ],
              [
                -74.2010688259972,
                40.62037214674218
              ],
              [
                -74.20104077573242,
                40.62036365348459
              ],
              [
                -74.2010037523626,
                40.62035346912194
              ],
              [
                -74.2009868438432,
                40.620321893960096
              ],
              [
                -74.20098449202942,
                40.62028516996776
              ],
              [
                -74.20098095847078,
                40.620228800715154
              ],
              [
                -74.20098217389514,
                40.62019625184807
              ],
              [
                -74.20098303339508,
                40.620173276893695
              ],
              [
                -74.20099525859429,
                40.62013823520795
              ],
              [
                -74.20100533126885,
                40.62013309136914
              ],
              [
                -74.20102657539782,
                40.620115117536365
              ],
              [
                -74.20103548328741,
                40.62009545580519
              ],
              [
                -74.20102868350264,
                40.62007069749322
              ],
              [
                -74.20101618955653,
                40.620016052875066
              ],
              [
                -74.20099363018717,
                40.619969966997274
              ],
              [
                -74.20097552827605,
                40.61991447794746
              ],
              [
                -74.20097089773077,
                40.619865799364746
              ],
              [
                -74.2009729628065,
                40.61980685946978
              ],
              [
                -74.20096499633247,
                40.61976758159086
              ],
              [
                -74.20093569892526,
                40.619716381922544
              ],
              [
                -74.20089632653438,
                40.619670326583666
              ],
              [
                -74.20085806260202,
                40.61961999729876
              ],
              [
                -74.20082091698058,
                40.619568813491085
              ],
              [
                -74.20079832794163,
                40.61951247751583
              ],
              [
                -74.20077574921811,
                40.619459558276795
              ],
              [
                -74.20075764740828,
                40.619404069692756
              ],
              [
                -74.20075316068288,
                40.61938801381847
              ],
              [
                -74.20075311533593,
                40.619387848713856
              ],
              [
                -74.20073954753296,
                40.61934943365838
              ],
              [
                -74.20071472324886,
                40.61929481103739
              ],
              [
                -74.200707892184,
                40.61925980264958
              ],
              [
                -74.20069092927473,
                40.619210291352054
              ],
              [
                -74.20068071349013,
                40.61916845485066
              ],
              [
                -74.20068053886425,
                40.61911037192587
              ],
              [
                -74.20069049525623,
                40.619065938183596
              ],
              [
                -74.20069592745396,
                40.619008699198744
              ],
              [
                -74.2006979927439,
                40.61894975863334
              ],
              [
                -74.20071136905621,
                40.61892496457095
              ],
              [
                -74.20075835642854,
                40.618894132527224
              ],
              [
                -74.20081542374747,
                40.61886072032839
              ],
              [
                -74.2008344149703,
                40.61883933370438
              ],
              [
                -74.20083991450633,
                40.61880430260729
              ],
              [
                -74.20080841793947,
                40.61876762952104
              ],
              [
                -74.20077247709379,
                40.618744629382384
              ],
              [
                -74.20070737819523,
                40.618716555592066
              ],
              [
                -74.20063669653429,
                40.61869532553015
              ],
              [
                -74.20056712603832,
                40.61867152928309
              ],
              [
                -74.20051657149038,
                40.61863403463199
              ],
              [
                -74.20048393619727,
                40.618591382983304
              ],
              [
                -74.20044231269767,
                40.61854191414937
              ],
              [
                -74.20042313487245,
                40.618500947983264
              ],
              [
                -74.20039607974422,
                40.61845059899734
              ],
              [
                -74.20036566101716,
                40.61839940230609
              ],
              [
                -74.20035203856247,
                40.618342198554714
              ],
              [
                -74.20034850652021,
                40.61828582926974
              ],
              [
                -74.20036292502164,
                40.618234553753986
              ],
              [
                -74.20038742362465,
                40.618180698137294
              ],
              [
                -74.20041529080609,
                40.61812854728366
              ],
              [
                -74.20043867109608,
                40.61807554666407
              ],
              [
                -74.20048674969225,
                40.61803531795103
              ],
              [
                -74.20054156331808,
                40.61799763949357
              ],
              [
                -74.20055275649818,
                40.6179924942406
              ],
              [
                -74.20058410068826,
                40.61797877435722
              ],
              [
                -74.20064125204004,
                40.61797355006681
              ],
              [
                -74.20069507526787,
                40.617979433723335
              ],
              [
                -74.20070855668939,
                40.61798966046441
              ],
              [
                -74.20074009349516,
                40.61804000038052
              ],
              [
                -74.2007637976405,
                40.61809462546131
              ],
              [
                -74.20078290449888,
                40.61811167572029
              ],
              [
                -74.20081543372864,
                40.618119305979896
              ],
              [
                -74.20084680817138,
                40.61811498084625
              ],
              [
                -74.2008747874012,
                40.61810041085587
              ],
              [
                -74.20087690516628,
                40.61805940754893
              ],
              [
                -74.20089138557002,
                40.6180286322463
              ],
              [
                -74.2009002735371,
                40.61800213820937
              ],
              [
                -74.20089346564374,
                40.61797481737089
              ],
              [
                -74.20089110022624,
                40.617933820892574
              ],
              [
                -74.20087301283809,
                40.61788345734883
              ],
              [
                -74.20087858490696,
                40.61787234209638
              ],
              [
                -74.20090986705057,
                40.61783812184213
              ],
              [
                -74.20091423238277,
                40.61779882239855
              ],
              [
                -74.20090739710211,
                40.617762105058574
              ],
              [
                -74.20089938404898,
                40.61770659952502
              ],
              [
                -74.2008913696864,
                40.61765109282016
              ],
              [
                -74.20087662783489,
                40.61759474363878
              ],
              [
                -74.20087646075416,
                40.61753922321934
              ],
              [
                -74.20087629624591,
                40.61748455752935
              ],
              [
                -74.20086267588432,
                40.61742820588299
              ],
              [
                -74.20084680818461,
                40.617370150367776
              ],
              [
                -74.20084328605802,
                40.61731719833676
              ],
              [
                -74.20084535241513,
                40.617259111485595
              ],
              [
                -74.20084742522633,
                40.61720273241606
              ],
              [
                -74.20083723821054,
                40.6171694381884
              ],
              [
                -74.2008304096492,
                40.61713528402797
              ],
              [
                -74.20082804386193,
                40.617094288048285
              ],
              [
                -74.20080892377666,
                40.617072967329534
              ],
              [
                -74.20076290801349,
                40.61705340154439
              ],
              [
                -74.20069001303489,
                40.61704157142791
              ],
              [
                -74.20058986446027,
                40.617028927389455
              ],
              [
                -74.2003834842897,
                40.61642809824377
              ],
              [
                -74.20064229730261,
                40.616033237076145
              ],
              [
                -74.20244374449518,
                40.61328469393199
              ],
              [
                -74.20230179014106,
                40.61187564203906
              ],
              [
                -74.20267299169078,
                40.6098278612319
              ],
              [
                -74.20273927047037,
                40.60883221126095
              ],
              [
                -74.20281628374592,
                40.608270827967345
              ],
              [
                -74.20221849851052,
                40.60596967961645
              ],
              [
                -74.20138527472514,
                40.60463696620847
              ],
              [
                -74.20014866759907,
                40.60368335009864
              ],
              [
                -74.19918333889221,
                40.602358736987064
              ],
              [
                -74.19875958205294,
                40.60171197517623
              ],
              [
                -74.19833088558589,
                40.600656979597765
              ],
              [
                -74.19743845280497,
                40.59846063769394
              ],
              [
                -74.19741728170408,
                40.59820351882565
              ],
              [
                -74.19737568141731,
                40.59815661029335
              ],
              [
                -74.19733408580258,
                40.598111411544835
              ],
              [
                -74.1973059170801,
                40.59805935586228
              ],
              [
                -74.19729907669395,
                40.59802007571798
              ],
              [
                -74.19731244404159,
                40.59799271947669
              ],
              [
                -74.19734368335155,
                40.59794654041523
              ],
              [
                -74.19736144895344,
                40.597890990263096
              ],
              [
                -74.19737584196214,
                40.59783202796156
              ],
              [
                -74.19738360796272,
                40.59780553606761
              ],
              [
                -74.19743279194556,
                40.597764450720845
              ],
              [
                -74.19748090154937,
                40.59773874256309
              ],
              [
                -74.19749654625022,
                40.59772419571973
              ],
              [
                -74.19748864223308,
                40.59770370887348
              ],
              [
                -74.19748291970386,
                40.59766357375692
              ],
              [
                -74.197454761376,
                40.59761493418694
              ],
              [
                -74.19745235168166,
                40.59755770971274
              ],
              [
                -74.19745442542755,
                40.59750133059555
              ],
              [
                -74.19745761291965,
                40.59744238753408
              ],
              [
                -74.19747652740341,
                40.597396230279735
              ],
              [
                -74.1975145700716,
                40.59737737394277
              ],
              [
                -74.19752237639196,
                40.5973645483639
              ],
              [
                -74.19751448668937,
                40.59734918605724
              ],
              [
                -74.19751357718701,
                40.59679898603679
              ],
              [
                -74.1975184758747,
                40.596803368065736
              ],
              [
                -74.19754872963588,
                40.59680331606222
              ],
              [
                -74.19755768499311,
                40.59679988289551
              ],
              [
                -74.1975935096188,
                40.59678871911553
              ],
              [
                -74.19760471617818,
                40.596788699846066
              ],
              [
                -74.19763717716219,
                40.596776683749496
              ],
              [
                -74.19768636210588,
                40.59673645419567
              ],
              [
                -74.19772992710946,
                40.5966902544883
              ],
              [
                -74.19776795444966,
                40.59666541828953
              ],
              [
                -74.19780037992398,
                40.59664144528383
              ],
              [
                -74.19783497175446,
                40.59659269895008
              ],
              [
                -74.1978729711544,
                40.59655846619549
              ],
              [
                -74.19787520651842,
                40.59655675454946
              ],
              [
                -74.19787729703556,
                40.59655474226969
              ],
              [
                -74.19788395473405,
                40.59654833268186
              ],
              [
                -74.19791431640616,
                40.59651910477752
              ],
              [
                -74.19795002851592,
                40.59647035480372
              ],
              [
                -74.1979622670406,
                40.59644043839561
              ],
              [
                -74.1979654616297,
                40.596384057331484
              ],
              [
                -74.19799668454347,
                40.59633275472038
              ],
              [
                -74.19800105800269,
                40.59629601782259
              ],
              [
                -74.19800880011675,
                40.59626183715075
              ],
              [
                -74.19804451037392,
                40.59621223576351
              ],
              [
                -74.19808246637238,
                40.59616348187958
              ],
              [
                -74.1981204131753,
                40.596112165968066
              ],
              [
                -74.19815500349986,
                40.596062564800974
              ],
              [
                -74.19819183483902,
                40.59601295975829
              ],
              [
                -74.19822305871895,
                40.59596165540639
              ],
              [
                -74.19825315828169,
                40.59590950109701
              ],
              [
                -74.19830796614374,
                40.595876092256994
              ],
              [
                -74.19835042032791,
                40.59583331199778
              ],
              [
                -74.19840296649915,
                40.595793074654765
              ],
              [
                -74.19842750755885,
                40.59575544935358
              ],
              [
                -74.19845423987306,
                40.595701590661854
              ],
              [
                -74.19849556327543,
                40.595655394528656
              ],
              [
                -74.19852902294922,
                40.595602378432076
              ],
              [
                -74.19856137143587,
                40.595552781012394
              ],
              [
                -74.19858473171841,
                40.59549551201839
              ],
              [
                -74.19860922087038,
                40.59544080192643
              ],
              [
                -74.1986281079241,
                40.59538610318733
              ],
              [
                -74.19863022280653,
                40.59534339203769
              ],
              [
                -74.19862556263999,
                40.59528360775762
              ],
              [
                -74.19863211254362,
                40.595225513070766
              ],
              [
                -74.19865548667394,
                40.595172514367086
              ],
              [
                -74.19867324216078,
                40.595114400808036
              ],
              [
                -74.19869662340403,
                40.595063964113905
              ],
              [
                -74.1987323255373,
                40.59501179831048
              ],
              [
                -74.19876805458141,
                40.59496817478595
              ],
              [
                -74.1987714026433,
                40.59496389749132
              ],
              [
                -74.19879713413988,
                40.59495018658111
              ],
              [
                -74.19886101146592,
                40.594952638759565
              ],
              [
                -74.19887443541094,
                40.59494492776038
              ],
              [
                -74.19888888242043,
                40.59490475678912
              ],
              [
                -74.19889498982688,
                40.59487881648165
              ],
              [
                -74.19894864455006,
                40.594780007760406
              ],
              [
                -74.19902465726125,
                40.59467644801813
              ],
              [
                -74.1992108242351,
                40.59454360947803
              ],
              [
                -74.19929116182526,
                40.59446913040079
              ],
              [
                -74.19932799056618,
                40.594456243244
              ],
              [
                -74.1993621758987,
                40.59443965535484
              ],
              [
                -74.19939999862336,
                40.5944143735898
              ],
              [
                -74.19943168321554,
                40.59438458967391
              ],
              [
                -74.19945632117715,
                40.594351157615066
              ],
              [
                -74.19947085129925,
                40.59433749782494
              ],
              [
                -74.19948344880574,
                40.59432093629294
              ],
              [
                -74.19949236458696,
                40.59430303910333
              ],
              [
                -74.19949736363506,
                40.59428427800823
              ],
              [
                -74.19949831418322,
                40.594265147530855
              ],
              [
                -74.19949860722171,
                40.59425681057571
              ],
              [
                -74.19949701312818,
                40.59424855962953
              ],
              [
                -74.19949357950024,
                40.59424064105152
              ],
              [
                -74.19948840886074,
                40.59423329127684
              ],
              [
                -74.19948165559656,
                40.59422672975699
              ],
              [
                -74.19947352134893,
                40.59422115240753
              ],
              [
                -74.19946424899246,
                40.59421672575841
              ],
              [
                -74.19942322768752,
                40.59417938809712
              ],
              [
                -74.19938898826084,
                40.59413827009382
              ],
              [
                -74.19936212279688,
                40.594094082795486
              ],
              [
                -74.19942419937705,
                40.59398286307282
              ],
              [
                -74.19949325575736,
                40.59387406068025
              ],
              [
                -74.19956913046464,
                40.59376792997537
              ],
              [
                -74.19965164608844,
                40.59366471906849
              ],
              [
                -74.19974060969567,
                40.59356466924328
              ],
              [
                -74.19983581328245,
                40.59346801439224
              ],
              [
                -74.19993703426019,
                40.5933749804701
              ],
              [
                -74.20004403597565,
                40.5932857849662
              ],
              [
                -74.20015656826536,
                40.59320063639575
              ],
              [
                -74.20022540741584,
                40.59316618693115
              ],
              [
                -74.20029203254796,
                40.593129294562964
              ],
              [
                -74.20033102128065,
                40.59309539922136
              ],
              [
                -74.20036412936977,
                40.59305804595363
              ],
              [
                -74.20039083463888,
                40.593017823902315
              ],
              [
                -74.20041071589982,
                40.5929753674559
              ],
              [
                -74.2004234595963,
                40.59293134624284
              ],
              [
                -74.20042886474769,
                40.592886454570134
              ],
              [
                -74.20042684611835,
                40.592841400471926
              ],
              [
                -74.2004712682456,
                40.592811752075065
              ],
              [
                -74.2005208613197,
                40.592787328264215
              ],
              [
                -74.20057457669009,
                40.59276864548078
              ],
              [
                -74.20063127854337,
                40.59275609877191
              ],
              [
                -74.20068976791988,
                40.592749953437306
              ],
              [
                -74.200748808064,
                40.59275033941976
              ],
              [
                -74.20092540496347,
                40.59286637930489
              ],
              [
                -74.20128925984356,
                40.59252433525516
              ],
              [
                -74.20130339394389,
                40.59253148864053
              ],
              [
                -74.20172160160581,
                40.5920530216791
              ],
              [
                -74.20178248226532,
                40.59209513428536
              ],
              [
                -74.201824436096,
                40.592052956369635
              ],
              [
                -74.20175478948575,
                40.59201732481844
              ],
              [
                -74.2019436762175,
                40.59182367412003
              ],
              [
                -74.20200944557338,
                40.59186029996112
              ],
              [
                -74.20205960871606,
                40.59180885418955
              ],
              [
                -74.20202032457773,
                40.591786715019545
              ],
              [
                -74.20210009097973,
                40.59170390185628
              ],
              [
                -74.20228057909593,
                40.591516341166354
              ],
              [
                -74.20230864003041,
                40.59153252120723
              ],
              [
                -74.2023164576832,
                40.59152396510269
              ],
              [
                -74.20228727877038,
                40.591508641098294
              ],
              [
                -74.20231743569836,
                40.591476984863895
              ],
              [
                -74.20230284591744,
                40.5914693217743
              ],
              [
                -74.20200349813028,
                40.591780764795026
              ],
              [
                -74.2019893996557,
                40.59177627984607
              ],
              [
                -74.20195886491075,
                40.59174396877873
              ],
              [
                -74.20192266920789,
                40.59171525502987
              ],
              [
                -74.20188153297842,
                40.5916907101064
              ],
              [
                -74.2018582717722,
                40.591664884435644
              ],
              [
                -74.20184092914558,
                40.59163648338189
              ],
              [
                -74.20182998202394,
                40.59160628799181
              ],
              [
                -74.2018257314502,
                40.59157512865599
              ],
              [
                -74.2018282943085,
                40.591543862273426
              ],
              [
                -74.2018376001094,
                40.59151334868536
              ],
              [
                -74.20185112965837,
                40.591471388735236
              ],
              [
                -74.20187170905275,
                40.59143112837132
              ],
              [
                -74.20189898808032,
                40.59139325270468
              ],
              [
                -74.20193764229154,
                40.59136966453789
              ],
              [
                -74.20197947682975,
                40.59134947438202
              ],
              [
                -74.20202398004938,
                40.591332929165844
              ],
              [
                -74.20207060766732,
                40.59132023123974
              ],
              [
                -74.20209021838782,
                40.5913038881921
              ],
              [
                -74.20210582304722,
                40.59128520454924
              ],
              [
                -74.20211695073955,
                40.59126474413673
              ],
              [
                -74.20212098405622,
                40.5912592503675
              ],
              [
                -74.20212378169593,
                40.5912533269589
              ],
              [
                -74.20212526799746,
                40.591247134107675
              ],
              [
                -74.20212540276478,
                40.59124083929775
              ],
              [
                -74.20212418235339,
                40.59123461277042
              ],
              [
                -74.20212163976944,
                40.59122862292027
              ],
              [
                -74.20211784377658,
                40.59122303174096
              ],
              [
                -74.20211289703634,
                40.59121799044415
              ],
              [
                -74.20210693333235,
                40.59121363537016
              ],
              [
                -74.20208857970688,
                40.59117859215292
              ],
              [
                -74.20206727337371,
                40.59114453377124
              ],
              [
                -74.20202733920699,
                40.59099907929197
              ],
              [
                -74.2024549506313,
                40.59095112585197
              ],
              [
                -74.20296340195651,
                40.59123092277659
              ],
              [
                -74.20305254911503,
                40.59114054180979
              ],
              [
                -74.2025862272926,
                40.590876711143885
              ],
              [
                -74.20246775956575,
                40.59083882485734
              ],
              [
                -74.20201405533584,
                40.59089579962756
              ],
              [
                -74.20208602968194,
                40.59084971875004
              ],
              [
                -74.20215374436117,
                40.59080002731069
              ],
              [
                -74.20221688836129,
                40.590746953548624
              ],
              [
                -74.20227517166538,
                40.59069074123786
              ],
              [
                -74.20230594865892,
                40.59067128365125
              ],
              [
                -74.20234039642335,
                40.59065580926254
              ],
              [
                -74.20237763634617,
                40.590644712755164
              ],
              [
                -74.20241671860074,
                40.590638277151676
              ],
              [
                -74.20245664637172,
                40.59063666659588
              ],
              [
                -74.20249640127932,
                40.59063992216579
              ],
              [
                -74.20253496935247,
                40.59064796082629
              ],
              [
                -74.20257136688997,
                40.59066057754702
              ],
              [
                -74.20260466555042,
                40.590677450531096
              ],
              [
                -74.20263401602924,
                40.59069814942304
              ],
              [
                -74.20265866972028,
                40.59072214628492
              ],
              [
                -74.20388550456512,
                40.5897861107414
              ],
              [
                -74.20394167611974,
                40.589807396399316
              ],
              [
                -74.20369974335807,
                40.59019990647488
              ],
              [
                -74.2038588660927,
                40.59024952536167
              ],
              [
                -74.2041007534238,
                40.589842757412434
              ],
              [
                -74.20420300609156,
                40.58964297174176
              ],
              [
                -74.20440892551585,
                40.589706763811556
              ],
              [
                -74.204646089432,
                40.58928574546586
              ],
              [
                -74.20443548401086,
                40.589218398296865
              ],
              [
                -74.20446810314506,
                40.58918269561868
              ],
              [
                -74.20470529848622,
                40.58877237083056
              ],
              [
                -74.204574220731,
                40.588719139828065
              ],
              [
                -74.20432302142382,
                40.58913661747329
              ],
              [
                -74.20420368247356,
                40.58908730078406
              ],
              [
                -74.20417149979477,
                40.589038945078705
              ],
              [
                -74.20414695389636,
                40.58898805252937
              ],
              [
                -74.20413038144704,
                40.58893532120947
              ],
              [
                -74.20412200974495,
                40.58888147441251
              ],
              [
                -74.2041219536013,
                40.58882725073073
              ],
              [
                -74.20413021376582,
                40.58877339392429
              ],
              [
                -74.20415692245034,
                40.58872020425905
              ],
              [
                -74.20418131762762,
                40.588666372556624
              ],
              [
                -74.2042038580157,
                40.588660076925514
              ],
              [
                -74.20422490770723,
                40.58865127921356
              ],
              [
                -74.2042439920589,
                40.588640177798034
              ],
              [
                -74.20426068074417,
                40.58862702300179
              ],
              [
                -74.20426123461236,
                40.58859481624041
              ],
              [
                -74.20425580839121,
                40.588562873455004
              ],
              [
                -74.20425316728853,
                40.588542259195634
              ],
              [
                -74.20425516381164,
                40.588521602839954
              ],
              [
                -74.20426173977556,
                40.58850150633709
              ],
              [
                -74.20427270354543,
                40.58848255532038
              ],
              [
                -74.2042877356221,
                40.58846530204245
              ],
              [
                -74.20430639795251,
                40.58845024928142
              ],
              [
                -74.20432814669546,
                40.58843783568988
              ],
              [
                -74.20435234806935,
                40.58842842301214
              ],
              [
                -74.20437829682214,
                40.58842228554261
              ],
              [
                -74.20440523678165,
                40.5884196021332
              ],
              [
                -74.20441023417295,
                40.5884091871309
              ],
              [
                -74.20443395174978,
                40.58837281241695
              ],
              [
                -74.20449833794214,
                40.58834277618761
              ],
              [
                -74.20455143291892,
                40.588287115234415
              ],
              [
                -74.20458065694802,
                40.58821760368262
              ],
              [
                -74.20457893934689,
                40.58811502004493
              ],
              [
                -74.20456465389368,
                40.588027419694434
              ],
              [
                -74.20454212228326,
                40.58799326496621
              ],
              [
                -74.2044901874033,
                40.587969848118036
              ],
              [
                -74.2044256759391,
                40.58795927584979
              ],
              [
                -74.20439762297147,
                40.58795291489371
              ],
              [
                -74.20438633322476,
                40.587928356848664
              ],
              [
                -74.20437917821558,
                40.58788028165798
              ],
              [
                -74.20438319127821,
                40.58781722736447
              ],
              [
                -74.20438579358988,
                40.587752037899776
              ],
              [
                -74.2043826647372,
                40.58764518375069
              ],
              [
                -74.20437820037156,
                40.587560771251454
              ],
              [
                -74.20435412247495,
                40.58747960015626
              ],
              [
                -74.204292152031,
                40.5873824661778
              ],
              [
                -74.20426533002895,
                40.58732053504299
              ],
              [
                -74.20425812875469,
                40.587257499513534
              ],
              [
                -74.20425040456472,
                40.58702348818807
              ],
              [
                -74.20424585675818,
                40.58691129402538
              ],
              [
                -74.2042457129535,
                40.58686427469632
              ],
              [
                -74.20424560837007,
                40.58683007936899
              ],
              [
                -74.20423708035298,
                40.58679162499405
              ],
              [
                -74.20423706725386,
                40.58679107820001
              ],
              [
                -74.20423545614075,
                40.58671896233079
              ],
              [
                -74.2042169336982,
                40.58662068340185
              ],
              [
                -74.20420240620957,
                40.58645374030289
              ],
              [
                -74.20420228531195,
                40.586414202142414
              ],
              [
                -74.20421541115921,
                40.58635166523287
              ],
              [
                -74.20422026477975,
                40.58633509276629
              ],
              [
                -74.20423984358409,
                40.58632009772104
              ],
              [
                -74.20425381709462,
                40.586306180807014
              ],
              [
                -74.20425865426664,
                40.58628373175725
              ],
              [
                -74.20425857092111,
                40.58625648200865
              ],
              [
                -74.20425570487673,
                40.58623618316045
              ],
              [
                -74.20427713544649,
                40.586139436131056
              ],
              [
                -74.2042749428259,
                40.586110054126976
              ],
              [
                -74.20425943665474,
                40.586081229373946
              ],
              [
                -74.20422356877545,
                40.58604015115937
              ],
              [
                -74.20420542329487,
                40.5860135582314
              ],
              [
                -74.20417076912078,
                40.58596277116559
              ],
              [
                -74.20414124971829,
                40.585935040136576
              ],
              [
                -74.20411940258332,
                40.58589554107575
              ],
              [
                -74.20411999295422,
                40.58585920699764
              ],
              [
                -74.20413954560352,
                40.58583619705348
              ],
              [
                -74.2041738351581,
                40.58581957345847
              ],
              [
                -74.20424528742339,
                40.585808759236635
              ],
              [
                -74.20430757734005,
                40.58578139926478
              ],
              [
                -74.20433621964578,
                40.58575142817499
              ],
              [
                -74.20435009526344,
                40.5857049184956
              ],
              [
                -74.20435071667102,
                40.58567927159019
              ],
              [
                -74.20434224520207,
                40.58565898271226
              ],
              [
                -74.20432254891573,
                40.5856355079445
              ],
              [
                -74.20431854925825,
                40.585618453819784
              ],
              [
                -74.20431828965745,
                40.585617349254726
              ],
              [
                -74.20431867099312,
                40.585607607219956
              ],
              [
                -74.2043189388622,
                40.58560078425907
              ],
              [
                -74.20431982332869,
                40.58559826957136
              ],
              [
                -74.20432588794984,
                40.58558100271239
              ],
              [
                -74.2043412730567,
                40.585570289797026
              ],
              [
                -74.2043636722888,
                40.585561701582016
              ],
              [
                -74.20440428309311,
                40.58554933968979
              ],
              [
                -74.20441471663784,
                40.585523675327394
              ],
              [
                -74.20441040927199,
                40.585490020720066
              ],
              [
                -74.2044004957285,
                40.585456911678804
              ],
              [
                -74.2044221001769,
                40.58519629868874
              ],
              [
                -74.20441081363548,
                40.58516409524353
              ],
              [
                -74.20441210120488,
                40.585127226338344
              ],
              [
                -74.20442602919894,
                40.58509781515075
              ],
              [
                -74.20443857102863,
                40.58507428404446
              ],
              [
                -74.20444200119024,
                40.5850502328868
              ],
              [
                -74.20441939116981,
                40.584989897709114
              ],
              [
                -74.20441781684238,
                40.58493379828689
              ],
              [
                -74.20442328538282,
                40.58488837239203
              ],
              [
                -74.204424569888,
                40.584850434650846
              ],
              [
                -74.20441392548736,
                40.58480717510366
              ],
              [
                -74.20439629985478,
                40.58477301110756
              ],
              [
                -74.20436741459895,
                40.584723372637235
              ],
              [
                -74.20436167594352,
                40.58468010435888
              ],
              [
                -74.20436433899089,
                40.584634149370665
              ],
              [
                -74.20437680805169,
                40.58458657384673
              ],
              [
                -74.2043977140958,
                40.58454806711537
              ],
              [
                -74.20442145408362,
                40.58451917255599
              ],
              [
                -74.20460253447358,
                40.584378328049965
              ],
              [
                -74.20462977804404,
                40.58434942720774
              ],
              [
                -74.20463390208104,
                40.58432323880132
              ],
              [
                -74.20463030601356,
                40.58429332454314
              ],
              [
                -74.20461476082346,
                40.58425167683367
              ],
              [
                -74.20453808215798,
                40.58415670784161
              ],
              [
                -74.20448598348868,
                40.58407932556045
              ],
              [
                -74.20446835234766,
                40.5840430245725
              ],
              [
                -74.20445697556904,
                40.583989615360984
              ],
              [
                -74.2044470555811,
                40.583953833559676
              ],
              [
                -74.20441389194123,
                40.583880159329134
              ],
              [
                -74.20440188742124,
                40.58385079477768
              ],
              [
                -74.20439752718507,
                40.58380004333487
              ],
              [
                -74.20439877211804,
                40.58374874716517
              ],
              [
                -74.20440076433692,
                40.583712945841896
              ],
              [
                -74.20442721397755,
                40.58365359003326
              ],
              [
                -74.20447402884271,
                40.58360809223265
              ],
              [
                -74.20449984308463,
                40.58357011082869
              ],
              [
                -74.20451027225019,
                40.58354284236166
              ],
              [
                -74.20451503396664,
                40.583496348873894
              ],
              [
                -74.20453023988733,
                40.583426862754415
              ],
              [
                -74.20454341730591,
                40.58338195838172
              ],
              [
                -74.20458104289362,
                40.58331082941355
              ],
              [
                -74.20458940764408,
                40.58329585309735
              ],
              [
                -74.2045970827756,
                40.58328515337046
              ],
              [
                -74.20460617051464,
                40.58327765697892
              ],
              [
                -74.2046194376683,
                40.58326213901755
              ],
              [
                -74.20461938037161,
                40.583243438096225
              ],
              [
                -74.20461932144066,
                40.583224203762704
              ],
              [
                -74.2046241736578,
                40.58320656293812
              ],
              [
                -74.20463814971185,
                40.583193714808
              ],
              [
                -74.20466404415026,
                40.58318191378419
              ],
              [
                -74.20470539425226,
                40.58318290895939
              ],
              [
                -74.20472712204311,
                40.583183939085785
              ],
              [
                -74.20473901192635,
                40.58317590193005
              ],
              [
                -74.20474316697732,
                40.583159867613844
              ],
              [
                -74.2047445073956,
                40.58314009485839
              ],
              [
                -74.20474934341482,
                40.58311711169746
              ],
              [
                -74.20475561223768,
                40.58310427678488
              ],
              [
                -74.20475417505162,
                40.58309305853082
              ],
              [
                -74.20474573327542,
                40.58308238801735
              ],
              [
                -74.20473588871609,
                40.583071719500325
              ],
              [
                -74.20472604415846,
                40.58306105047994
              ],
              [
                -74.20471128061838,
                40.58304558278613
              ],
              [
                -74.20470352560267,
                40.58303063518115
              ],
              [
                -74.20470347483324,
                40.5830140714263
              ],
              [
                -74.20470483416038,
                40.58300017809808
              ],
              [
                -74.20471181593868,
                40.58299161727957
              ],
              [
                -74.20472511173142,
                40.58298518171856
              ],
              [
                -74.20473981443627,
                40.58298088015733
              ],
              [
                -74.20474959018404,
                40.58296910783596
              ],
              [
                -74.20475094130767,
                40.58295254208531
              ],
              [
                -74.20474594990972,
                40.582924766989564
              ],
              [
                -74.20472623268154,
                40.582893813790285
              ],
              [
                -74.2047163529982,
                40.582871389763234
              ],
              [
                -74.20470294422896,
                40.58284095890195
              ],
              [
                -74.20470286889555,
                40.58281638089677
              ],
              [
                -74.2047091190272,
                40.58279766940676
              ],
              [
                -74.20472240233876,
                40.58278695967866
              ],
              [
                -74.20473569721067,
                40.582780524117794
              ],
              [
                -74.20475597011422,
                40.58276445940269
              ],
              [
                -74.20476221924581,
                40.582745213156144
              ],
              [
                -74.20476216027058,
                40.582725977649154
              ],
              [
                -74.20476280082798,
                40.58270620731278
              ],
              [
                -74.20476976291052,
                40.5826912351574
              ],
              [
                -74.20477815309634,
                40.582684808332914
              ],
              [
                -74.20480198335079,
                40.582685834194976
              ],
              [
                -74.20483141014492,
                40.58268417864347
              ],
              [
                -74.20486430388429,
                40.58266969383118
              ],
              [
                -74.2048768754869,
                40.58265578050754
              ],
              [
                -74.20488662232019,
                40.58263439150842
              ],
              [
                -74.20496559453855,
                40.58256372107799
              ],
              [
                -74.20498022302412,
                40.582534843320424
              ],
              [
                -74.20498291791678,
                40.58249957364146
              ],
              [
                -74.20499119588473,
                40.582456815635084
              ],
              [
                -74.20500227903955,
                40.582414585032545
              ],
              [
                -74.2050190317369,
                40.582393183520836
              ],
              [
                -74.20503506784873,
                40.58236590499717
              ],
              [
                -74.20504263125663,
                40.58231887289907
              ],
              [
                -74.2050551441379,
                40.58228572454965
              ],
              [
                -74.20505645907878,
                40.58225740345467
              ],
              [
                -74.20506897543812,
                40.582225322262474
              ],
              [
                -74.20508854474977,
                40.58220819107532
              ],
              [
                -74.20509339544918,
                40.58219054972916
              ],
              [
                -74.20509542302041,
                40.582165967465954
              ],
              [
                -74.20509671037522,
                40.582129099045034
              ],
              [
                -74.20510505123276,
                40.582106643192574
              ],
              [
                -74.20512670482992,
                40.58208362881664
              ],
              [
                -74.20513225919491,
                40.5820665214703
              ],
              [
                -74.20513077033327,
                40.58203820588235
              ],
              [
                -74.20512510854586,
                40.58202005089318
              ],
              [
                -74.20510186514834,
                40.58198215692221
              ],
              [
                -74.20507012398778,
                40.581914890524736
              ],
              [
                -74.20507422735601,
                40.58188229076408
              ],
              [
                -74.20509095824308,
                40.581853408567596
              ],
              [
                -74.20511888390284,
                40.581818629769494
              ],
              [
                -74.20512367296969,
                40.581781219334616
              ],
              [
                -74.20512209441732,
                40.58172298223061
              ],
              [
                -74.2051080040074,
                40.58169896450181
              ],
              [
                -74.20508976059689,
                40.581691516357
              ],
              [
                -74.20507853137714,
                40.581686193370565
              ],
              [
                -74.20506822436222,
                40.581659900026324
              ],
              [
                -74.20506947324093,
                40.58163282630751
              ],
              [
                -74.20508136987225,
                40.58159946880264
              ],
              [
                -74.20511158049024,
                40.58157237186299
              ],
              [
                -74.20512231077548,
                40.581547318379506
              ],
              [
                -74.20512521495195,
                40.58152628417253
              ],
              [
                -74.20513750637808,
                40.5815045113187
              ],
              [
                -74.20516545262045,
                40.58147469989992
              ],
              [
                -74.20521287714367,
                40.581442847346715
              ],
              [
                -74.20524920561914,
                40.581404036139375
              ],
              [
                -74.20526700378377,
                40.58137387509614
              ],
              [
                -74.20527202154237,
                40.58135114237638
              ],
              [
                -74.2052693620202,
                40.58129433506051
              ],
              [
                -74.20526958813122,
                40.581258449082966
              ],
              [
                -74.20527220884874,
                40.58122913856349
              ],
              [
                -74.20528796822211,
                40.58120288159004
              ],
              [
                -74.20532229370893,
                40.58116907939564
              ],
              [
                -74.20535699520654,
                40.581146311210425
              ],
              [
                -74.20537706597383,
                40.58114039319173
              ],
              [
                -74.20539291124267,
                40.58113787151845
              ],
              [
                -74.2054067987052,
                40.58112048355696
              ],
              [
                -74.20541159080935,
                40.581091130787414
              ],
              [
                -74.2054173705693,
                40.58106948648033
              ],
              [
                -74.20544302999238,
                40.58105345690728
              ],
              [
                -74.20557469907166,
                40.581026268505866
              ],
              [
                -74.20561757191867,
                40.581008287361946
              ],
              [
                -74.20565901048455,
                40.58099448746781
              ],
              [
                -74.20569702651665,
                40.58097961010295
              ],
              [
                -74.20572403289702,
                40.58096568893219
              ],
              [
                -74.20573808837854,
                40.5809443222968
              ],
              [
                -74.20574206147629,
                40.58090714934315
              ],
              [
                -74.20574379921223,
                40.580882010882334
              ],
              [
                -74.20574262948877,
                40.58086785251191
              ],
              [
                -74.20574692749514,
                40.580855837444304
              ],
              [
                -74.20576787123194,
                40.5808334807646
              ],
              [
                -74.20577441108944,
                40.580808904644385
              ],
              [
                -74.20577197243135,
                40.58078740068533
              ],
              [
                -74.20576270054559,
                40.58076321625617
              ],
              [
                -74.20575332946562,
                40.580745845277626
              ],
              [
                -74.2057549623511,
                40.5807280428035
              ],
              [
                -74.20575734259258,
                40.58070605258599
              ],
              [
                -74.20575982767907,
                40.58067672805645
              ],
              [
                -74.20576494221854,
                40.58065581116427
              ],
              [
                -74.2057755076905,
                40.58063808215212
              ],
              [
                -74.20582069215298,
                40.58060282864584
              ],
              [
                -74.20589079815034,
                40.580555207968146
              ],
              [
                -74.2058970544074,
                40.580532316526515
              ],
              [
                -74.20591753329506,
                40.58051246237593
              ],
              [
                -74.20591861496264,
                40.580485222586766
              ],
              [
                -74.20592159850466,
                40.580469002521866
              ],
              [
                -74.20594660290547,
                40.580450872681354
              ],
              [
                -74.20598341988727,
                40.5804239333732
              ],
              [
                -74.20599255081491,
                40.580410383935906
              ],
              [
                -74.20599414424598,
                40.58039520069104
              ],
              [
                -74.20598768121006,
                40.58036684845967
              ],
              [
                -74.20598602922253,
                40.580338538196315
              ],
              [
                -74.20598907320168,
                40.580318126255
              ],
              [
                -74.20599824576975,
                40.58030169740582
              ],
              [
                -74.20601164437174,
                40.580278229412585
              ],
              [
                -74.20601270293663,
                40.58025256025642
              ],
              [
                -74.206003098644,
                40.58020374451813
              ],
              [
                -74.20598641215831,
                40.58007698896933
              ],
              [
                -74.20596154297344,
                40.58004699388278
              ],
              [
                -74.205950418,
                40.57995433999985
              ],
              [
                -74.20593287099989,
                40.57989243799992
              ],
              [
                -74.20583829199992,
                40.579841260999906
              ],
              [
                -74.20575100199994,
                40.57983323599993
              ],
              [
                -74.20568486299997,
                40.5798345309999
              ],
              [
                -74.20561948299995,
                40.57984225399984
              ],
              [
                -74.20555593099994,
                40.57985627799991
              ],
              [
                -74.20549524799992,
                40.57987637399991
              ],
              [
                -74.20543842599989,
                40.57990221299989
              ],
              [
                -74.2053863959999,
                40.57993337199986
              ],
              [
                -74.2053762709999,
                40.57995060699991
              ],
              [
                -74.20536261699995,
                40.579966361999915
              ],
              [
                -74.20534579299994,
                40.579980220999836
              ],
              [
                -74.20532624599998,
                40.579991818999915
              ],
              [
                -74.20530449199997,
                40.5800008469999
              ],
              [
                -74.20528110699988,
                40.58000706799988
              ],
              [
                -74.20525670800001,
                40.58001031599986
              ],
              [
                -74.20523194099997,
                40.58001050699994
              ],
              [
                -74.20520746099992,
                40.580007633999905
              ],
              [
                -74.20512751399997,
                40.580015766999956
              ],
              [
                -74.20504901899987,
                40.58002990699992
              ],
              [
                -74.20497275099989,
                40.580049913999886
              ],
              [
                -74.20489946199994,
                40.580075591999915
              ],
              [
                -74.20482987599989,
                40.58010668699991
              ],
              [
                -74.20476467899992,
                40.580142890999916
              ],
              [
                -74.2047045139999,
                40.580183847999876
              ],
              [
                -74.20469757199993,
                40.58020245399992
              ],
              [
                -74.20468682999994,
                40.58021997899994
              ],
              [
                -74.20467256099992,
                40.58023597499989
              ],
              [
                -74.20465513199991,
                40.58025003199991
              ],
              [
                -74.20463498599996,
                40.58026179199992
              ],
              [
                -74.2046126399999,
                40.580270952999875
              ],
              [
                -74.2045886649999,
                40.5802772819999
              ],
              [
                -74.20456367299994,
                40.58028061599989
              ],
              [
                -74.20453830399995,
                40.58028087099992
              ],
              [
                -74.20437042899988,
                40.580304787999914
              ],
              [
                -74.20420118899996,
                40.580322231999894
              ],
              [
                -74.20403100999988,
                40.58033315899993
              ],
              [
                -74.2039459509999,
                40.580344580999906
              ],
              [
                -74.20385400899987,
                40.58037563699991
              ],
              [
                -74.2037591199999,
                40.5804010189999
              ],
              [
                -74.20366188299988,
                40.580420565999894
              ],
              [
                -74.20356291499995,
                40.58043415399991
              ],
              [
                -74.20354150699988,
                40.58043609799995
              ],
              [
                -74.20350663899988,
                40.580436159999884
              ],
              [
                -74.20346962599996,
                40.58043264699992
              ],
              [
                -74.20343081399989,
                40.58041929399991
              ],
              [
                -74.20337531899993,
                40.580402016999905
              ],
              [
                -74.20332116299994,
                40.580385643999925
              ],
              [
                -74.20327432799998,
                40.580374143999904
              ],
              [
                -74.20323381699988,
                40.580370455999876
              ],
              [
                -74.20319223099993,
                40.58036874499986
              ],
              [
                -74.20314675299993,
                40.58036531099985
              ],
              [
                -74.20309425099991,
                40.58036082099994
              ],
              [
                -74.20300418199997,
                40.580350082999914
              ],
              [
                -74.20285677399994,
                40.58032983999996
              ],
              [
                -74.20281236899987,
                40.580324427999884
              ],
              [
                -74.20276536999991,
                40.58031819799994
              ],
              [
                -74.20269703699992,
                40.580304951999935
              ],
              [
                -74.20264545999996,
                40.580296560999884
              ],
              [
                -74.20260239099991,
                40.58029205299992
              ],
              [
                -74.20254855399999,
                40.58028665799987
              ],
              [
                -74.20251156099994,
                40.580279916999906
              ],
              [
                -74.20240475199986,
                40.5802571309999
              ],
              [
                -74.20230969599996,
                40.58023945699995
              ],
              [
                -74.2021713749999,
                40.58020105299987
              ],
              [
                -74.20214420399996,
                40.5801919059999
              ],
              [
                -74.20210029899994,
                40.58018503899992
              ],
              [
                -74.20204828299998,
                40.58018625699993
              ],
              [
                -74.20200397499998,
                40.58018899599994
              ],
              [
                -74.20195345799993,
                40.58018581899988
              ],
              [
                -74.20191105199993,
                40.58017458399996
              ],
              [
                -74.20187663199988,
                40.580161496999885
              ],
              [
                -74.20184248899996,
                40.58014407399991
              ],
              [
                -74.20179998599994,
                40.58012471499988
              ],
              [
                -74.20176039899995,
                40.58011715299988
              ],
              [
                -74.20173840199996,
                40.58011683599987
              ],
              [
                -74.2016748199999,
                40.5801148059999
              ],
              [
                -74.201626822,
                40.58011151499993
              ],
              [
                -74.20158593699988,
                40.580103048999874
              ],
              [
                -74.20154141399996,
                40.580082349999905
              ],
              [
                -74.20148133499991,
                40.580048555999944
              ],
              [
                -74.2014525309999,
                40.5800347179999
              ],
              [
                -74.20143434499994,
                40.580020805999894
              ],
              [
                -74.20140358699999,
                40.57999220499992
              ],
              [
                -74.20138059799989,
                40.57997327799995
              ],
              [
                -74.20135420199988,
                40.5799583649999
              ],
              [
                -74.2013187079999,
                40.579947255999855
              ],
              [
                -74.20128744299997,
                40.5799416559999
              ],
              [
                -74.20125254599999,
                40.57993721699994
              ],
              [
                -74.20121027499994,
                40.579935038999935
              ],
              [
                -74.20115662599996,
                40.57993156499993
              ],
              [
                -74.20109537699989,
                40.579927500999915
              ],
              [
                -74.20103374199996,
                40.57992585099987
              ],
              [
                -74.20094091499986,
                40.57991956299991
              ],
              [
                -74.20085107099999,
                40.57991170499991
              ],
              [
                -74.200797772,
                40.57990482699992
              ],
              [
                -74.20073234099989,
                40.5799105679999
              ],
              [
                -74.20069287199986,
                40.579918322999895
              ],
              [
                -74.20065720799997,
                40.579934002999956
              ],
              [
                -74.20062395199992,
                40.57994860899994
              ],
              [
                -74.20058554299995,
                40.579961575999945
              ],
              [
                -74.20055646999992,
                40.579966402999965
              ],
              [
                -74.2005382919999,
                40.5799668459999
              ],
              [
                -74.20051512399985,
                40.57996038199989
              ],
              [
                -74.20048848899994,
                40.579949777999914
              ],
              [
                -74.19964981299998,
                40.58226262599992
              ],
              [
                -74.1942326139999,
                40.58514680599991
              ],
              [
                -74.193324197,
                40.58503828099988
              ],
              [
                -74.19251747899993,
                40.58515898199988
              ],
              [
                -74.1923211319999,
                40.58491324199991
              ],
              [
                -74.19226048499998,
                40.58520696899992
              ],
              [
                -74.19131783599987,
                40.585704488999966
              ],
              [
                -74.19078902199995,
                40.58518836699991
              ],
              [
                -74.18971488199998,
                40.585589306999964
              ],
              [
                -74.18951740799994,
                40.58566073099989
              ],
              [
                -74.18932391199996,
                40.58573822599987
              ],
              [
                -74.18913471499995,
                40.58582166299985
              ],
              [
                -74.18895013099997,
                40.58591090399989
              ],
              [
                -74.18877046599995,
                40.58600580099985
              ],
              [
                -74.18859601799987,
                40.586106195999946
              ],
              [
                -74.1883043219999,
                40.58648008499989
              ],
              [
                -74.18819964399991,
                40.58656190399996
              ],
              [
                -74.18808950399989,
                40.586639443999914
              ],
              [
                -74.18797420199998,
                40.58671249399986
              ],
              [
                -74.18785405399997,
                40.58678085299989
              ],
              [
                -74.18772938699996,
                40.5868443349999
              ],
              [
                -74.18760054199991,
                40.58690276699989
              ],
              [
                -74.18746786899996,
                40.5869559899999
              ],
              [
                -74.18733173099997,
                40.58700385699993
              ],
              [
                -74.18719250099991,
                40.5870462399999
              ],
              [
                -74.18705055599993,
                40.587083020999934
              ],
              [
                -74.18681414999996,
                40.58710057399991
              ],
              [
                -74.18650033699991,
                40.58719476299993
              ],
              [
                -74.18636768699997,
                40.587245200999895
              ],
              [
                -74.18623192299994,
                40.5872905709999
              ],
              [
                -74.18609337999993,
                40.58733075999993
              ],
              [
                -74.18595239599988,
                40.587365671999876
              ],
              [
                -74.18580931799991,
                40.58739521799988
              ],
              [
                -74.18566449699989,
                40.58741932799991
              ],
              [
                -74.18551828799991,
                40.587437942999955
              ],
              [
                -74.185371048,
                40.58745101499987
              ],
              [
                -74.18730248699987,
                40.588488221999896
              ],
              [
                -74.18683246699995,
                40.58907136099992
              ],
              [
                -74.18468224999998,
                40.591478950999964
              ],
              [
                -74.18183084799989,
                40.594094245999884
              ],
              [
                -74.17932277499995,
                40.59676057899985
              ],
              [
                -74.17822457299992,
                40.59982806099992
              ],
              [
                -74.17582749599997,
                40.601526852999896
              ],
              [
                -74.17488959499995,
                40.60221657299994
              ],
              [
                -74.17260229799992,
                40.602971490999934
              ],
              [
                -74.17235109299999,
                40.60284533499991
              ],
              [
                -74.1720855709999,
                40.602733770999905
              ],
              [
                -74.17180796899991,
                40.60263814499993
              ],
              [
                -74.17152081799993,
                40.60255950899988
              ],
              [
                -74.17122684699991,
                40.60249861199988
              ],
              [
                -74.1709288839999,
                40.60245585699987
              ],
              [
                -74.17062978699994,
                40.60243131199998
              ],
              [
                -74.16900518099992,
                40.60227337499988
              ],
              [
                -74.16719634899995,
                40.602075311999926
              ],
              [
                -74.16762086799996,
                40.599849696999925
              ],
              [
                -74.1679964909999,
                40.597922359999885
              ],
              [
                -74.16828458099997,
                40.596433164999944
              ],
              [
                -74.16886362399997,
                40.59472808699995
              ],
              [
                -74.167784501,
                40.59418566599993
              ],
              [
                -74.166390049,
                40.593737925999925
              ],
              [
                -74.16439050499996,
                40.59348246099993
              ],
              [
                -74.16360268199993,
                40.593374198999854
              ],
              [
                -74.163507855,
                40.59327217899987
              ],
              [
                -74.16349571699993,
                40.593101060999906
              ],
              [
                -74.16350123699995,
                40.59292868299987
              ],
              [
                -74.16352460800002,
                40.592756630999865
              ],
              [
                -74.16356570299993,
                40.592586526999874
              ],
              [
                -74.16362405299995,
                40.59241993699987
              ],
              [
                -74.16363304299993,
                40.59240052699992
              ],
              [
                -74.16369888199995,
                40.59225833899993
              ],
              [
                -74.16345157099991,
                40.592416147999856
              ],
              [
                -74.16321702299987,
                40.592585959999894
              ],
              [
                -74.16299668999994,
                40.59276682799989
              ],
              [
                -74.16279187799998,
                40.59295768699992
              ],
              [
                -74.16260370599997,
                40.593157365999915
              ],
              [
                -74.16244891299996,
                40.593379085999864
              ],
              [
                -74.1623107339999,
                40.59360803399994
              ],
              [
                -74.1621900049999,
                40.5938431059999
              ],
              [
                -74.16208738699994,
                40.59408311399988
              ],
              [
                -74.16200333499994,
                40.59432685099992
              ],
              [
                -74.16194448999997,
                40.59453549599989
              ],
              [
                -74.16190818299995,
                40.59468531299989
              ],
              [
                -74.16188841099994,
                40.59483745799985
              ],
              [
                -74.16188546199997,
                40.59499033499991
              ],
              [
                -74.16188699199998,
                40.595007126999874
              ],
              [
                -74.16189940599996,
                40.595298770999946
              ],
              [
                -74.16180204399988,
                40.59530144799988
              ],
              [
                -74.16025756099997,
                40.59534393799988
              ],
              [
                -74.15989227899996,
                40.59517014099986
              ],
              [
                -74.15891672599996,
                40.59462968499985
              ],
              [
                -74.1584322029999,
                40.59435961999991
              ],
              [
                -74.1580026889999,
                40.59412020399986
              ],
              [
                -74.15795821599991,
                40.594087044999874
              ],
              [
                -74.15636568699993,
                40.5931951279999
              ],
              [
                -74.1561030049999,
                40.59304747599992
              ],
              [
                -74.15537259699995,
                40.592597337999955
              ],
              [
                -74.15450856599989,
                40.59213609399994
              ],
              [
                -74.15235208199991,
                40.59093696199996
              ],
              [
                -74.152162103,
                40.590836192999845
              ],
              [
                -74.15096968899994,
                40.59017657399992
              ],
              [
                -74.14972777099995,
                40.5894925039999
              ],
              [
                -74.14869312199986,
                40.58884235399989
              ],
              [
                -74.14858798599987,
                40.58876582799994
              ],
              [
                -74.14846882599996,
                40.58869854099988
              ],
              [
                -74.14833787899993,
                40.58864260799992
              ],
              [
                -74.1481980769999,
                40.588599680999934
              ],
              [
                -74.14805282699994,
                40.58857080699991
              ],
              [
                -74.14790576499992,
                40.58855634599993
              ],
              [
                -74.1477604,
                40.58855595599994
              ],
              [
                -74.14684720299991,
                40.58857108499991
              ],
              [
                -74.14645464799996,
                40.58855885299993
              ],
              [
                -74.14636402099993,
                40.58855786899987
              ],
              [
                -74.14583447999993,
                40.58856291899987
              ],
              [
                -74.1438732669999,
                40.588615298999926
              ],
              [
                -74.14230337999994,
                40.588725478999955
              ],
              [
                -74.14168277499992,
                40.58874118499995
              ],
              [
                -74.13864458599996,
                40.58906080499991
              ],
              [
                -74.13849744199997,
                40.58931370499991
              ],
              [
                -74.13728879499998,
                40.5907863149999
              ],
              [
                -74.13704557999992,
                40.591073817999856
              ],
              [
                -74.13681568999996,
                40.59136787899989
              ],
              [
                -74.13659957099995,
                40.59166794799989
              ],
              [
                -74.13639761399997,
                40.591973456999895
              ],
              [
                -74.13549571999992,
                40.593700467999966
              ],
              [
                -74.13544074099997,
                40.59381694299986
              ],
              [
                -74.13510176799998,
                40.59453503299995
              ],
              [
                -74.13385485799995,
                40.596357845999925
              ],
              [
                -74.1328130649999,
                40.59724097399986
              ],
              [
                -74.13205430899994,
                40.59781870599994
              ],
              [
                -74.13123935899998,
                40.59830974899993
              ],
              [
                -74.13114463199999,
                40.59840588899994
              ],
              [
                -74.13106374499998,
                40.598510423999905
              ],
              [
                -74.13099847699996,
                40.59862154199993
              ],
              [
                -74.13095007699992,
                40.59873715199991
              ],
              [
                -74.13091909399999,
                40.598855047999905
              ],
              [
                -74.13080319499994,
                40.59884208599991
              ],
              [
                -74.12982988799996,
                40.59867157699994
              ],
              [
                -74.12888881199997,
                40.59854783299995
              ],
              [
                -74.12798105699996,
                40.598424835999914
              ],
              [
                -74.12714563699991,
                40.59833036199989
              ],
              [
                -74.12545223399988,
                40.59813734999988
              ],
              [
                -74.12535321299993,
                40.59814001699987
              ],
              [
                -74.12525469300002,
                40.59814805499989
              ],
              [
                -74.12515717499994,
                40.5981614239999
              ],
              [
                -74.12506115499997,
                40.59818005599988
              ],
              [
                -74.12496712099988,
                40.5982038559999
              ],
              [
                -74.1248755499999,
                40.598232702999915
              ],
              [
                -74.12478690899994,
                40.59826644999988
              ],
              [
                -74.12470164799996,
                40.59830492599989
              ],
              [
                -74.12462019999994,
                40.59834793499992
              ],
              [
                -74.12454297999987,
                40.598395257999876
              ],
              [
                -74.12399136699995,
                40.59871481099991
              ],
              [
                -74.12322499799996,
                40.59909695299992
              ],
              [
                -74.123185791,
                40.59919511599987
              ],
              [
                -74.12264348599997,
                40.599418584999945
              ],
              [
                -74.12229424999991,
                40.599687177999904
              ],
              [
                -74.12070424299995,
                40.600705980999884
              ],
              [
                -74.12064234399993,
                40.60078271599989
              ],
              [
                -74.1206422609999,
                40.60078282499992
              ],
              [
                -74.12064219099997,
                40.600782950999914
              ],
              [
                -74.12059587499996,
                40.600866766999935
              ],
              [
                -74.12059578099999,
                40.6008669369999
              ],
              [
                -74.12059572199999,
                40.6008671179999
              ],
              [
                -74.12056678099995,
                40.6009552459999
              ],
              [
                -74.12056671099998,
                40.60095547199994
              ],
              [
                -74.12056668799994,
                40.60095567899988
              ],
              [
                -74.12055576699994,
                40.6010452919999
              ],
              [
                -74.12059546299996,
                40.60180975799988
              ],
              [
                -74.11957594399998,
                40.60181702899996
              ],
              [
                -74.11790155199996,
                40.601856453999865
              ],
              [
                -74.11777623899994,
                40.60186024799993
              ],
              [
                -74.11684368799997,
                40.601894806999894
              ],
              [
                -74.11500030999997,
                40.60207428599988
              ],
              [
                -74.11487058499988,
                40.60213665999987
              ],
              [
                -74.11511342599988,
                40.602533816999866
              ],
              [
                -74.11530272499996,
                40.603868255999906
              ],
              [
                -74.11537754999995,
                40.6039841869999
              ],
              [
                -74.11544265199991,
                40.60469494899995
              ],
              [
                -74.11554728399992,
                40.60539592799987
              ],
              [
                -74.11567482099998,
                40.60613313799994
              ],
              [
                -74.11579141499993,
                40.60686116499995
              ],
              [
                -74.1158562429999,
                40.60715118399992
              ],
              [
                -74.11587716399993,
                40.60725747099985
              ],
              [
                -74.1160100819999,
                40.6073743499999
              ],
              [
                -74.11615857899993,
                40.60748094799985
              ],
              [
                -74.11625356799993,
                40.607536440999894
              ],
              [
                -74.1163204779999,
                40.6075755289999
              ],
              [
                -74.116493189,
                40.60765675199985
              ],
              [
                -74.11706965299993,
                40.60820624999994
              ],
              [
                -74.117424665,
                40.60928900899992
              ],
              [
                -74.11834442099989,
                40.609144125999904
              ],
              [
                -74.11839688799994,
                40.60913837299989
              ],
              [
                -74.11848942399992,
                40.60912822499995
              ],
              [
                -74.1186902039999,
                40.60910620799991
              ],
              [
                -74.11883362299994,
                40.60909048099991
              ],
              [
                -74.11897246899991,
                40.60907525399995
              ],
              [
                -74.11913216599991,
                40.60905774099993
              ],
              [
                -74.120254056,
                40.6089347039999
              ],
              [
                -74.1205232869999,
                40.608905175999936
              ],
              [
                -74.12066342799993,
                40.60888980599989
              ],
              [
                -74.12079903099993,
                40.60887493299986
              ],
              [
                -74.12116879899992,
                40.60883437599989
              ],
              [
                -74.12127253299997,
                40.60936216999995
              ],
              [
                -74.1213358189999,
                40.60966649799989
              ],
              [
                -74.12135179599994,
                40.609765267999926
              ],
              [
                -74.12247214699987,
                40.60948123499992
              ],
              [
                -74.12252324799988,
                40.60946899999992
              ],
              [
                -74.12326521999991,
                40.60929134799988
              ],
              [
                -74.12350639099996,
                40.609233601999975
              ],
              [
                -74.12398126100001,
                40.609135218999896
              ],
              [
                -74.12452618799996,
                40.609022318999905
              ],
              [
                -74.1251456319999,
                40.608908470999914
              ],
              [
                -74.12520040399997,
                40.60889840499993
              ],
              [
                -74.12630497799988,
                40.608699642999966
              ],
              [
                -74.12650911499995,
                40.60866290799987
              ],
              [
                -74.12674361599996,
                40.60863191499993
              ],
              [
                -74.12742960799994,
                40.608549759999946
              ],
              [
                -74.12784267099991,
                40.60850028899994
              ],
              [
                -74.12874897499987,
                40.608391740999934
              ],
              [
                -74.12983323699997,
                40.60830394299994
              ],
              [
                -74.13094002299995,
                40.60821431199986
              ],
              [
                -74.131568734,
                40.60818117199995
              ],
              [
                -74.13210459699991,
                40.60815292399991
              ],
              [
                -74.13211741399986,
                40.608264348999946
              ],
              [
                -74.13215051199998,
                40.608551963999965
              ],
              [
                -74.13311710799991,
                40.608505831999864
              ],
              [
                -74.13404435499994,
                40.60845319899988
              ],
              [
                -74.13497712799997,
                40.60841117899987
              ],
              [
                -74.1359058139999,
                40.6083589069999
              ],
              [
                -74.1371740599999,
                40.608292697999914
              ],
              [
                -74.13810528799995,
                40.60824232499986
              ],
              [
                -74.13903904999992,
                40.608195812999924
              ],
              [
                -74.13997406999988,
                40.60814639199986
              ],
              [
                -74.14092528299996,
                40.60808963199984
              ],
              [
                -74.14188196399996,
                40.608042940999894
              ],
              [
                -74.14283290599998,
                40.607992594999885
              ],
              [
                -74.14391816399994,
                40.6079898369999
              ],
              [
                -74.1441830409999,
                40.60799797199993
              ],
              [
                -74.14444741899989,
                40.60801279699993
              ],
              [
                -74.14471100599997,
                40.60803429699994
              ],
              [
                -74.14497351299995,
                40.608062447999934
              ],
              [
                -74.14523464999989,
                40.608097218999895
              ],
              [
                -74.14549412999996,
                40.60813857199988
              ],
              [
                -74.14575166799996,
                40.60818646199987
              ],
              [
                -74.14600697999992,
                40.6082408349999
              ],
              [
                -74.14631056999997,
                40.60830708799991
              ],
              [
                -74.14661053099996,
                40.60838297899991
              ],
              [
                -74.14690609299997,
                40.608468339999895
              ],
              [
                -74.14719646699989,
                40.6085629299999
              ],
              [
                -74.14746971699995,
                40.60865584899989
              ],
              [
                -74.14773969799992,
                40.60875416499994
              ],
              [
                -74.14800622599995,
                40.60885780799989
              ],
              [
                -74.14826911999995,
                40.60896670999988
              ],
              [
                -74.14852820199994,
                40.60908079499994
              ],
              [
                -74.148783295,
                40.609199986999904
              ],
              [
                -74.14954343599997,
                40.60962717199986
              ],
              [
                -74.14998477099994,
                40.6095384179999
              ],
              [
                -74.15018666499996,
                40.60949781099991
              ],
              [
                -74.15031900699995,
                40.60959281499989
              ],
              [
                -74.15120348199993,
                40.61026499499994
              ],
              [
                -74.15317275299992,
                40.6117615219999
              ],
              [
                -74.15371834199986,
                40.612206280999864
              ],
              [
                -74.15379116699992,
                40.612255611999906
              ],
              [
                -74.15386832299995,
                40.612320627999964
              ],
              [
                -74.15474080199988,
                40.6129923179999
              ],
              [
                -74.15479265199997,
                40.61303223099987
              ],
              [
                -74.15651187899992,
                40.61435448199991
              ],
              [
                -74.157373837,
                40.6150319109999
              ],
              [
                -74.15792673899992,
                40.6154574249999
              ],
              [
                -74.1586174169999,
                40.61593931499986
              ],
              [
                -74.15912798199999,
                40.61629637499988
              ],
              [
                -74.15986704899998,
                40.61681322499988
              ],
              [
                -74.16052177399989,
                40.61722978899996
              ],
              [
                -74.16163010299996,
                40.61793493599989
              ],
              [
                -74.1623545779999,
                40.61833563799997
              ],
              [
                -74.16337391799988,
                40.61885458999993
              ],
              [
                -74.16394094899994,
                40.61914326199996
              ],
              [
                -74.16484190399989,
                40.61957240499989
              ],
              [
                -74.16570682499993,
                40.61995403599988
              ],
              [
                -74.16603193299996,
                40.62009453499998
              ],
              [
                -74.16742666699997,
                40.620677283999896
              ],
              [
                -74.16769710399988,
                40.62079027499995
              ],
              [
                -74.16808086299993,
                40.62093625599991
              ],
              [
                -74.16847311299995,
                40.621045814999896
              ],
              [
                -74.16878471999995,
                40.62112402899988
              ],
              [
                -74.16888894299989,
                40.62115114299992
              ],
              [
                -74.16923624499995,
                40.6212164329999
              ],
              [
                -74.16953656499994,
                40.621270499999916
              ],
              [
                -74.16985740699987,
                40.621312386999925
              ],
              [
                -74.17095546499992,
                40.62144218099994
              ],
              [
                -74.17181177999997,
                40.62154338399994
              ],
              [
                -74.17207112899989,
                40.62157862099992
              ],
              [
                -74.17236260999994,
                40.6216357989999
              ],
              [
                -74.17270752199991,
                40.62171153799989
              ],
              [
                -74.17326730799988,
                40.62183898399992
              ],
              [
                -74.17361243999996,
                40.621948740999855
              ],
              [
                -74.17395016799996,
                40.62207316899987
              ],
              [
                -74.17427887999996,
                40.62221179199987
              ],
              [
                -74.17459706299995,
                40.6223640109999
              ],
              [
                -74.17490328599996,
                40.622529106999885
              ],
              [
                -74.17519625699995,
                40.62270624399988
              ],
              [
                -74.17610052899997,
                40.623406241999895
              ],
              [
                -74.17775298599997,
                40.62493844999993
              ],
              [
                -74.1778150209999,
                40.624990962999966
              ],
              [
                -74.1780882019999,
                40.62526042699994
              ],
              [
                -74.17828177399998,
                40.62545136099991
              ],
              [
                -74.17888390599994,
                40.625932870999925
              ],
              [
                -74.17918377399995,
                40.62613788499989
              ],
              [
                -74.17924623499991,
                40.626180580999886
              ],
              [
                -74.1795447069999,
                40.626362353999895
              ],
              [
                -74.17993706099998,
                40.62660129699989
              ],
              [
                -74.18033424799995,
                40.626834629999905
              ],
              [
                -74.1819023569999,
                40.62775580699989
              ],
              [
                -74.18368103499995,
                40.62874067099991
              ],
              [
                -74.18649341199989,
                40.63021905099988
              ],
              [
                -74.1921598649999,
                40.633230970999946
              ],
              [
                -74.19248359599999,
                40.63340507199996
              ],
              [
                -74.1955597527974,
                40.635023986316575
              ],
              [
                -74.19557688057968,
                40.63500421857057
              ],
              [
                -74.19568609223377,
                40.63501686464005
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000871889446182,
        "objectid": 16,
        "shape_leng": 0.141291873771,
        "location_id": 16,
        "zone": "Bayside",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.7685730499999,
                40.77910542899993
              ],
              [
                -73.76864150099995,
                40.77908686699987
              ],
              [
                -73.76877614499993,
                40.779042841999924
              ],
              [
                -73.76934982499988,
                40.77887476899986
              ],
              [
                -73.77113180399985,
                40.77834080999992
              ],
              [
                -73.7720360819999,
                40.778069802999866
              ],
              [
                -73.772903019,
                40.777804280999874
              ],
              [
                -73.7737825709999,
                40.7775474679999
              ],
              [
                -73.77465548700002,
                40.777285608999925
              ],
              [
                -73.77553732199992,
                40.777025597999916
              ],
              [
                -73.775724408,
                40.77790043399993
              ],
              [
                -73.77583958299994,
                40.7786123719999
              ],
              [
                -73.7767836329999,
                40.77840958299987
              ],
              [
                -73.77769354999994,
                40.778160416999924
              ],
              [
                -73.77858771199993,
                40.77791239199996
              ],
              [
                -73.77947656099995,
                40.7776641699999
              ],
              [
                -73.78036180399994,
                40.77738704699993
              ],
              [
                -73.78279772899998,
                40.776666035999945
              ],
              [
                -73.78407645399997,
                40.77628882299991
              ],
              [
                -73.78440918799984,
                40.7761902189999
              ],
              [
                -73.78448330699993,
                40.77616825899986
              ],
              [
                -73.78459339399987,
                40.77614122499995
              ],
              [
                -73.78491489299977,
                40.776040509999845
              ],
              [
                -73.78569433899995,
                40.775812774999935
              ],
              [
                -73.78626498299998,
                40.77564607999991
              ],
              [
                -73.78655073399982,
                40.77555774699992
              ],
              [
                -73.7874099169999,
                40.77531789199993
              ],
              [
                -73.78826972999987,
                40.775075356999864
              ],
              [
                -73.78893975799984,
                40.774900591999916
              ],
              [
                -73.78913873699987,
                40.77484868999994
              ],
              [
                -73.78984203999995,
                40.77468732599993
              ],
              [
                -73.79034971799999,
                40.774573093999926
              ],
              [
                -73.79163526099994,
                40.774238655999895
              ],
              [
                -73.79226795299988,
                40.77399004199988
              ],
              [
                -73.79271259399978,
                40.773787526999875
              ],
              [
                -73.7936523029999,
                40.7733604389999
              ],
              [
                -73.79499129399991,
                40.77314334799993
              ],
              [
                -73.79557358499984,
                40.7730492449999
              ],
              [
                -73.79565702099983,
                40.77303608599992
              ],
              [
                -73.79521550699988,
                40.77267490699989
              ],
              [
                -73.79507190299978,
                40.77254212499992
              ],
              [
                -73.794936368,
                40.77240452999996
              ],
              [
                -73.79480546099995,
                40.7722568749999
              ],
              [
                -73.79415923499985,
                40.77151603799992
              ],
              [
                -73.79394905799994,
                40.7712673749999
              ],
              [
                -73.79323711899993,
                40.77046772099994
              ],
              [
                -73.79317944599987,
                40.77040317699989
              ],
              [
                -73.79238243799978,
                40.7694838389999
              ],
              [
                -73.79235243699989,
                40.769449637999884
              ],
              [
                -73.79190204099987,
                40.768936211999915
              ],
              [
                -73.79149870499994,
                40.7684473909999
              ],
              [
                -73.79137684499982,
                40.7683242969999
              ],
              [
                -73.79059858199989,
                40.76741868799999
              ],
              [
                -73.79021960799982,
                40.76695117499986
              ],
              [
                -73.78990502999987,
                40.7665115969999
              ],
              [
                -73.78963649799978,
                40.76614161099991
              ],
              [
                -73.78871017999994,
                40.764854251999964
              ],
              [
                -73.7886108559999,
                40.764709593999946
              ],
              [
                -73.78849669799996,
                40.7645705529999
              ],
              [
                -73.78836856600003,
                40.76443842899987
              ],
              [
                -73.78822763099993,
                40.764314395999854
              ],
              [
                -73.78807523699997,
                40.76419948999994
              ],
              [
                -73.78733166699999,
                40.76380781299988
              ],
              [
                -73.787141317,
                40.76370781999987
              ],
              [
                -73.78595810599988,
                40.76310224399987
              ],
              [
                -73.78571993599994,
                40.762921464999856
              ],
              [
                -73.78546383099984,
                40.762591924999896
              ],
              [
                -73.78542241999986,
                40.76251310799995
              ],
              [
                -73.78535911599988,
                40.7622563179999
              ],
              [
                -73.78530175299996,
                40.761978503999934
              ],
              [
                -73.78527359199997,
                40.76184210599993
              ],
              [
                -73.785251708,
                40.76175695899991
              ],
              [
                -73.78516838299994,
                40.76143281799994
              ],
              [
                -73.78502434699996,
                40.76103651599987
              ],
              [
                -73.78486059299999,
                40.76064436299992
              ],
              [
                -73.78467742500003,
                40.76025720799989
              ],
              [
                -73.78447529299982,
                40.75987586199995
              ],
              [
                -73.78430628699978,
                40.759483644999904
              ],
              [
                -73.78428991899993,
                40.75944029899991
              ],
              [
                -73.78425940899996,
                40.75938264599993
              ],
              [
                -73.78407792999997,
                40.75903964399988
              ],
              [
                -73.78382076099993,
                40.75853003399991
              ],
              [
                -73.78346111099987,
                40.757864063999904
              ],
              [
                -73.78338071499984,
                40.75775389699993
              ],
              [
                -73.78316157699985,
                40.75732618499991
              ],
              [
                -73.78303191299983,
                40.75708319099991
              ],
              [
                -73.78281576399985,
                40.75666634299991
              ],
              [
                -73.78264852999988,
                40.75633190399988
              ],
              [
                -73.7824813079998,
                40.75599745499987
              ],
              [
                -73.78212461999979,
                40.75533931199993
              ],
              [
                -73.78165171799995,
                40.754707752999956
              ],
              [
                -73.78113322699984,
                40.7540893259999
              ],
              [
                -73.78052037799992,
                40.753330742999886
              ],
              [
                -73.78009357400003,
                40.7525075039999
              ],
              [
                -73.77980639299983,
                40.75189500499989
              ],
              [
                -73.77944066999987,
                40.75112269599993
              ],
              [
                -73.77906558899984,
                40.75033257399993
              ],
              [
                -73.77831180799991,
                40.748745013999944
              ],
              [
                -73.77753347599982,
                40.74714834199988
              ],
              [
                -73.77679196999986,
                40.74554428899989
              ],
              [
                -73.77616785699996,
                40.74424002299992
              ],
              [
                -73.77596627999999,
                40.743810447999884
              ],
              [
                -73.77585237799993,
                40.74356469299995
              ],
              [
                -73.77575892599995,
                40.74336722699992
              ],
              [
                -73.77573836999991,
                40.743325646999935
              ],
              [
                -73.77569182399996,
                40.74333358899986
              ],
              [
                -73.77551238399978,
                40.74335684199993
              ],
              [
                -73.77486382100001,
                40.743453335999945
              ],
              [
                -73.77444330499989,
                40.74351589999993
              ],
              [
                -73.77403143100003,
                40.743567600999945
              ],
              [
                -73.77362185199992,
                40.74363046299994
              ],
              [
                -73.77321534799998,
                40.74370439599989
              ],
              [
                -73.7728126769999,
                40.74378924899988
              ],
              [
                -73.7724146099999,
                40.74388484499992
              ],
              [
                -73.77162097999992,
                40.744159731999915
              ],
              [
                -73.77156671899985,
                40.74417951599989
              ],
              [
                -73.7715340229999,
                40.744191625999854
              ],
              [
                -73.77145218799996,
                40.744221522999915
              ],
              [
                -73.77137678500003,
                40.744251035999895
              ],
              [
                -73.770389824,
                40.74473791399991
              ],
              [
                -73.76937164099995,
                40.745220308999876
              ],
              [
                -73.76857701199981,
                40.74552220399995
              ],
              [
                -73.76772991399994,
                40.74577622599993
              ],
              [
                -73.76729011899992,
                40.74590381399993
              ],
              [
                -73.76681551899995,
                40.7460300569999
              ],
              [
                -73.76635520899988,
                40.74615249699984
              ],
              [
                -73.7658920239999,
                40.74628062699991
              ],
              [
                -73.76501623199981,
                40.74652288199991
              ],
              [
                -73.76414324400004,
                40.74676404599995
              ],
              [
                -73.76297777799992,
                40.74708162199994
              ],
              [
                -73.76240045799992,
                40.7472432539999
              ],
              [
                -73.76151846699999,
                40.74749054799987
              ],
              [
                -73.76145571899991,
                40.747507951999864
              ],
              [
                -73.76138355799993,
                40.747529170999904
              ],
              [
                -73.76073612099987,
                40.747737384999944
              ],
              [
                -73.75972553599988,
                40.748188660999894
              ],
              [
                -73.75885932599981,
                40.748584227999906
              ],
              [
                -73.75845857499999,
                40.74876309399989
              ],
              [
                -73.75805782299985,
                40.74894194899989
              ],
              [
                -73.75802805599993,
                40.74895781599986
              ],
              [
                -73.75773936999988,
                40.7490634429999
              ],
              [
                -73.75744314899997,
                40.74915673299992
              ],
              [
                -73.75714080500002,
                40.749237258999884
              ],
              [
                -73.75684127399991,
                40.74932207699987
              ],
              [
                -73.7568655229999,
                40.749361488999966
              ],
              [
                -73.75698991500002,
                40.74957500999989
              ],
              [
                -73.75737714099989,
                40.75022810199991
              ],
              [
                -73.75782746199997,
                40.750758269999906
              ],
              [
                -73.75910049199996,
                40.752253820999954
              ],
              [
                -73.75948272499986,
                40.7524338129999
              ],
              [
                -73.75957000299998,
                40.75247479599991
              ],
              [
                -73.75957016899991,
                40.75247487799994
              ],
              [
                -73.759570322,
                40.75247497699996
              ],
              [
                -73.75971494599995,
                40.75256269999991
              ],
              [
                -73.75971508799988,
                40.7525627919999
              ],
              [
                -73.75971521799991,
                40.75256288999989
              ],
              [
                -73.7598465069999,
                40.75266410199988
              ],
              [
                -73.75984667299988,
                40.75266422799993
              ],
              [
                -73.75984680199994,
                40.75266435499989
              ],
              [
                -73.75996168499987,
                40.75277718499993
              ],
              [
                -73.7599618029999,
                40.75277730199993
              ],
              [
                -73.75996192099981,
                40.752777446999865
              ],
              [
                -73.76005787299981,
                40.752899403999926
              ],
              [
                -73.76005800199988,
                40.752899574999894
              ],
              [
                -73.76005811999997,
                40.75289976499995
              ],
              [
                -73.76013358599995,
                40.75302819999992
              ],
              [
                -73.76013364499983,
                40.75302828899989
              ],
              [
                -73.76013367999985,
                40.75302838899988
              ],
              [
                -73.76018801899988,
                40.75316049899986
              ],
              [
                -73.76048623699985,
                40.75383961699987
              ],
              [
                -73.76080194199993,
                40.754547729999956
              ],
              [
                -73.76090047,
                40.75468513599987
              ],
              [
                -73.76101539999992,
                40.75481609199988
              ],
              [
                -73.76114556499998,
                40.7549389489999
              ],
              [
                -73.76128938199999,
                40.75505221499991
              ],
              [
                -73.76144497499985,
                40.755154656999885
              ],
              [
                -73.7615672369999,
                40.75523235199987
              ],
              [
                -73.76167669899993,
                40.75532347699988
              ],
              [
                -73.76176970599987,
                40.755426020999884
              ],
              [
                -73.76184343999986,
                40.75553726899991
              ],
              [
                -73.76189611099981,
                40.755654002999904
              ],
              [
                -73.76192713800003,
                40.75577285299988
              ],
              [
                -73.76185572299985,
                40.756516974999926
              ],
              [
                -73.76176692899985,
                40.75727880199986
              ],
              [
                -73.7619974599999,
                40.75810219099993
              ],
              [
                -73.76239510499991,
                40.75949699799993
              ],
              [
                -73.76125540599999,
                40.75971470799991
              ],
              [
                -73.76031788499999,
                40.7599045509999
              ],
              [
                -73.75800131799996,
                40.76037986699988
              ],
              [
                -73.75848075099995,
                40.76199473699992
              ],
              [
                -73.75811452099987,
                40.76205389699988
              ],
              [
                -73.75802364199994,
                40.76211096099996
              ],
              [
                -73.75794391899996,
                40.76218407699988
              ],
              [
                -73.75788220699995,
                40.76227115299989
              ],
              [
                -73.75784418199986,
                40.76236794099986
              ],
              [
                -73.75783301499989,
                40.76246859299991
              ],
              [
                -73.75784858699993,
                40.76256679999993
              ],
              [
                -73.75788761399987,
                40.762657021999864
              ],
              [
                -73.75794474699994,
                40.76273540499989
              ],
              [
                -73.75809857499982,
                40.762984538999966
              ],
              [
                -73.75823811499988,
                40.76323876599993
              ],
              [
                -73.75836296599996,
                40.763497382999915
              ],
              [
                -73.75844568799998,
                40.76368152999987
              ],
              [
                -73.75843838499995,
                40.76392848799986
              ],
              [
                -73.75844672099987,
                40.76411514599987
              ],
              [
                -73.75851382899985,
                40.76410483099995
              ],
              [
                -73.75859320499987,
                40.764088229999906
              ],
              [
                -73.75886431399988,
                40.76327454099994
              ],
              [
                -73.759097561,
                40.7640566959999
              ],
              [
                -73.75921689799992,
                40.7643248559999
              ],
              [
                -73.75862488399996,
                40.764383148999876
              ],
              [
                -73.75856122399995,
                40.76438654599989
              ],
              [
                -73.75850831399994,
                40.76439435899995
              ],
              [
                -73.7583941619999,
                40.7644052469999
              ],
              [
                -73.75635669699987,
                40.76459955799993
              ],
              [
                -73.75601361099996,
                40.76463784199993
              ],
              [
                -73.75567226899989,
                40.764691148999866
              ],
              [
                -73.75533439,
                40.76475940999996
              ],
              [
                -73.75500165499989,
                40.764842405999936
              ],
              [
                -73.75467572399998,
                40.764939759999926
              ],
              [
                -73.75435817399999,
                40.76505094399988
              ],
              [
                -73.75405049899983,
                40.76517533199991
              ],
              [
                -73.75377036482266,
                40.76530463124142
              ],
              [
                -73.75382270603563,
                40.76533572114292
              ],
              [
                -73.75385085906869,
                40.765342129373096
              ],
              [
                -73.75388234874701,
                40.76534929289707
              ],
              [
                -73.75397498244763,
                40.76530695093848
              ],
              [
                -73.75401961912938,
                40.76530745082464
              ],
              [
                -73.75410065488441,
                40.765349353964524
              ],
              [
                -73.75425235038242,
                40.76552911246537
              ],
              [
                -73.75425553419625,
                40.765532892788315
              ],
              [
                -73.7549367434127,
                40.76609325213882
              ],
              [
                -73.75547310439798,
                40.766365580416505
              ],
              [
                -73.75588294950307,
                40.766412293462
              ],
              [
                -73.75602210333291,
                40.76646442121023
              ],
              [
                -73.75614489705069,
                40.76641051622121
              ],
              [
                -73.75616353456147,
                40.76640233347391
              ],
              [
                -73.75617702002063,
                40.766390611212856
              ],
              [
                -73.75619103618445,
                40.76637963606818
              ],
              [
                -73.75620578099213,
                40.766370411438594
              ],
              [
                -73.75622064503258,
                40.76636370615331
              ],
              [
                -73.75625559323588,
                40.76635184870081
              ],
              [
                -73.75628664411393,
                40.76634340455195
              ],
              [
                -73.75631567038226,
                40.766337881960084
              ],
              [
                -73.75634312226629,
                40.7663352029743
              ],
              [
                -73.75638758604588,
                40.76633479308037
              ],
              [
                -73.75640357156823,
                40.76633574525943
              ],
              [
                -73.75643582839564,
                40.76633764971248
              ],
              [
                -73.7564737783002,
                40.766340359930005
              ],
              [
                -73.75647876240242,
                40.76634072126506
              ],
              [
                -73.75649107536171,
                40.766342377656294
              ],
              [
                -73.75650071937817,
                40.76634450501809
              ],
              [
                -73.75650494248836,
                40.76634587358724
              ],
              [
                -73.75650846744547,
                40.766347393967415
              ],
              [
                -73.75651129245526,
                40.76634907486635
              ],
              [
                -73.75651244924389,
                40.7663499786028
              ],
              [
                -73.75651342915802,
                40.76635092652746
              ],
              [
                -73.75651424303082,
                40.76635190861167
              ],
              [
                -73.75651487825853,
                40.766352937058144
              ],
              [
                -73.75651534873262,
                40.76635400904842
              ],
              [
                -73.75651563993463,
                40.76635511801814
              ],
              [
                -73.75651577784062,
                40.766356270555846
              ],
              [
                -73.75651572652936,
                40.76635746826089
              ],
              [
                -73.7565155091402,
                40.76635871017683
              ],
              [
                -73.75651512522731,
                40.766359997643185
              ],
              [
                -73.75651468312392,
                40.76636104961195
              ],
              [
                -73.75651240090923,
                40.76636639373759
              ],
              [
                -73.75650884816422,
                40.766372835323644
              ],
              [
                -73.75649903977315,
                40.76638619525873
              ],
              [
                -73.75646019017901,
                40.7664253664105
              ],
              [
                -73.75644838018687,
                40.76643884892769
              ],
              [
                -73.75642003660344,
                40.766474647520326
              ],
              [
                -73.7564131832044,
                40.766486492556076
              ],
              [
                -73.75640903262868,
                40.76649764120293
              ],
              [
                -73.75640830611397,
                40.76650187205258
              ],
              [
                -73.75640836159586,
                40.76650604089554
              ],
              [
                -73.75640921140914,
                40.766510148930536
              ],
              [
                -73.75641084289745,
                40.76651422276768
              ],
              [
                -73.75665982851439,
                40.76665032915951
              ],
              [
                -73.75692940163003,
                40.76679768260605
              ],
              [
                -73.75703114820045,
                40.76682325434132
              ],
              [
                -73.75718430019458,
                40.76695200038971
              ],
              [
                -73.75734523174002,
                40.76706980756654
              ],
              [
                -73.7573902080387,
                40.76716667235849
              ],
              [
                -73.75752194375056,
                40.76710675613373
              ],
              [
                -73.75760683799241,
                40.7669699190069
              ],
              [
                -73.75787030567056,
                40.76694642845212
              ],
              [
                -73.75802387192715,
                40.76707532904307
              ],
              [
                -73.75842156550789,
                40.767199067448146
              ],
              [
                -73.75864264358073,
                40.767357170035446
              ],
              [
                -73.75873403546348,
                40.76754922615337
              ],
              [
                -73.75879456995813,
                40.76765389115854
              ],
              [
                -73.7588273600204,
                40.767767523872216
              ],
              [
                -73.75882204462802,
                40.767949334401074
              ],
              [
                -73.75883847871388,
                40.76803828773906
              ],
              [
                -73.75880776561003,
                40.76818265272537
              ],
              [
                -73.75893512093685,
                40.76833432851595
              ],
              [
                -73.75907352924408,
                40.76845378633802
              ],
              [
                -73.75937389428368,
                40.76863638878569
              ],
              [
                -73.75960078092824,
                40.76872325671306
              ],
              [
                -73.76001142296053,
                40.76909583162673
              ],
              [
                -73.76044056848673,
                40.769485186931476
              ],
              [
                -73.76052349507854,
                40.76961682085476
              ],
              [
                -73.76060524066253,
                40.76976593345718
              ],
              [
                -73.76085900055723,
                40.77002264272332
              ],
              [
                -73.7612630125983,
                40.770395965074215
              ],
              [
                -73.76223863110448,
                40.77122066177052
              ],
              [
                -73.7635845659027,
                40.77235821590673
              ],
              [
                -73.76417633114528,
                40.77282999044272
              ],
              [
                -73.76436067006205,
                40.7730285208478
              ],
              [
                -73.76469385424426,
                40.77334047485452
              ],
              [
                -73.76510436820205,
                40.77379903872306
              ],
              [
                -73.76572796903912,
                40.77460332809212
              ],
              [
                -73.76600398326563,
                40.77506700693254
              ],
              [
                -73.76614233293911,
                40.77546695978643
              ],
              [
                -73.76627787969171,
                40.77574118632526
              ],
              [
                -73.76632823258105,
                40.77584305433083
              ],
              [
                -73.76658827278405,
                40.776172594166
              ],
              [
                -73.76700068069775,
                40.776695213965446
              ],
              [
                -73.76709113484746,
                40.776844841109174
              ],
              [
                -73.76713717312357,
                40.776937753291556
              ],
              [
                -73.76747817366723,
                40.77765554086643
              ],
              [
                -73.7676189407549,
                40.77799469391587
              ],
              [
                -73.76760454633784,
                40.77829903466069
              ],
              [
                -73.76782207615058,
                40.77881037615661
              ],
              [
                -73.76796073100003,
                40.778776008999955
              ],
              [
                -73.76807192199988,
                40.77874844899989
              ],
              [
                -73.76825889099999,
                40.778702104999915
              ],
              [
                -73.76833788999983,
                40.7786825249999
              ],
              [
                -73.7685730499999,
                40.77910542899993
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000322957654799,
        "objectid": 17,
        "shape_leng": 0.093522632948,
        "location_id": 17,
        "zone": "Bedford",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.94306406899986,
                40.701424434999886
              ],
              [
                -73.9432268589999,
                40.70152070899991
              ],
              [
                -73.94382473799989,
                40.70186200799988
              ],
              [
                -73.94400503999998,
                40.70196179199989
              ],
              [
                -73.94431460199992,
                40.70213334499988
              ],
              [
                -73.94463910199993,
                40.70231369399994
              ],
              [
                -73.94544988199996,
                40.70276063599993
              ],
              [
                -73.945854991,
                40.702987206999886
              ],
              [
                -73.94625107799986,
                40.70320874699995
              ],
              [
                -73.94705205299991,
                40.70366394899987
              ],
              [
                -73.94753858099985,
                40.703350649999926
              ],
              [
                -73.94937873499998,
                40.70215888999992
              ],
              [
                -73.9502742399999,
                40.70157924199991
              ],
              [
                -73.95128819399989,
                40.700922364999876
              ],
              [
                -73.95192018899999,
                40.70148754899992
              ],
              [
                -73.95255052799988,
                40.70205166699994
              ],
              [
                -73.95318085199992,
                40.702616904999935
              ],
              [
                -73.95381196900003,
                40.703180979999914
              ],
              [
                -73.95572360999994,
                40.701945769999924
              ],
              [
                -73.95745736399992,
                40.70082260299994
              ],
              [
                -73.95722517499998,
                40.69999934999988
              ],
              [
                -73.95716730100004,
                40.6997078679999
              ],
              [
                -73.95701993199991,
                40.69897391399997
              ],
              [
                -73.9579593819998,
                40.69882000299991
              ],
              [
                -73.95885874699991,
                40.698677325999874
              ],
              [
                -73.96019688899986,
                40.69846272699989
              ],
              [
                -73.96105100699991,
                40.69832607899989
              ],
              [
                -73.96092543799982,
                40.697736506999895
              ],
              [
                -73.96062056499981,
                40.69632013999994
              ],
              [
                -73.96015854699986,
                40.694117308999914
              ],
              [
                -73.95931047999983,
                40.694215086999876
              ],
              [
                -73.95799732999993,
                40.69436583899993
              ],
              [
                -73.957087019,
                40.69447043999991
              ],
              [
                -73.95614239299985,
                40.69457901899991
              ],
              [
                -73.95582662799985,
                40.69299238299994
              ],
              [
                -73.95541057999996,
                40.69090829199994
              ],
              [
                -73.95635602999991,
                40.69079978199989
              ],
              [
                -73.95727249099987,
                40.69069347799987
              ],
              [
                -73.95808563499989,
                40.69060146399989
              ],
              [
                -73.95818048699985,
                40.690590729999876
              ],
              [
                -73.95857844799987,
                40.69054569399986
              ],
              [
                -73.95886713600001,
                40.69051224799996
              ],
              [
                -73.9589933049999,
                40.690497628999886
              ],
              [
                -73.95928133699987,
                40.69046425699991
              ],
              [
                -73.959348624,
                40.69045646099992
              ],
              [
                -73.95942791399997,
                40.69044727499993
              ],
              [
                -73.95957591899987,
                40.69042998799993
              ],
              [
                -73.96008336799996,
                40.69037071599989
              ],
              [
                -73.96013760799985,
                40.690364379999906
              ],
              [
                -73.96029281699998,
                40.690346249999955
              ],
              [
                -73.96023740299987,
                40.69006222199988
              ],
              [
                -73.9602230449999,
                40.6899886269999
              ],
              [
                -73.96018691899992,
                40.6898034559999
              ],
              [
                -73.96017256100002,
                40.68972986199987
              ],
              [
                -73.96012172900004,
                40.68946930699984
              ],
              [
                -73.96009714599994,
                40.68934520999992
              ],
              [
                -73.96000519799986,
                40.688881033999955
              ],
              [
                -73.959859395,
                40.68814745099991
              ],
              [
                -73.95971374799981,
                40.687415633999926
              ],
              [
                -73.95956770199984,
                40.686682554999955
              ],
              [
                -73.95684165199995,
                40.686996078999904
              ],
              [
                -73.95468418899998,
                40.68724485399986
              ],
              [
                -73.95453798699994,
                40.68651117499993
              ],
              [
                -73.95439296899998,
                40.68577971999988
              ],
              [
                -73.95424647699987,
                40.685046247999914
              ],
              [
                -73.95410042600001,
                40.684313107999856
              ],
              [
                -73.953954531,
                40.683580778999946
              ],
              [
                -73.95380893500004,
                40.682848007999944
              ],
              [
                -73.95366256199999,
                40.6821149039999
              ],
              [
                -73.95351616899988,
                40.681382599999914
              ],
              [
                -73.9533701749999,
                40.680640507999904
              ],
              [
                -73.95155682700002,
                40.680498847999935
              ],
              [
                -73.95115828499993,
                40.68047861499993
              ],
              [
                -73.94955688699993,
                40.68039040299995
              ],
              [
                -73.94773023599986,
                40.680291845999896
              ],
              [
                -73.94674916000001,
                40.68023966099991
              ],
              [
                -73.94627471000001,
                40.68021332599987
              ],
              [
                -73.94397347799995,
                40.68008812799991
              ],
              [
                -73.94326176899995,
                40.68005060699992
              ],
              [
                -73.9412086429999,
                40.6799383539999
              ],
              [
                -73.94032794,
                40.67988997499989
              ],
              [
                -73.94047634999994,
                40.68063569499987
              ],
              [
                -73.94062005399995,
                40.68137012999991
              ],
              [
                -73.94076893299986,
                40.6821008389999
              ],
              [
                -73.940913343,
                40.68283361699987
              ],
              [
                -73.94105783799992,
                40.68356687199986
              ],
              [
                -73.94120399299996,
                40.68429983899993
              ],
              [
                -73.94134827200003,
                40.68503120299989
              ],
              [
                -73.94149491799988,
                40.685764528999904
              ],
              [
                -73.94163933199995,
                40.686497269999954
              ],
              [
                -73.94178527599988,
                40.68722837199987
              ],
              [
                -73.94193245099987,
                40.68796295899991
              ],
              [
                -73.9420768489998,
                40.688697201999894
              ],
              [
                -73.94222203500003,
                40.68942797899993
              ],
              [
                -73.94236932699991,
                40.6901599449999
              ],
              [
                -73.94251587899991,
                40.690892000999916
              ],
              [
                -73.94266181699986,
                40.69162434399988
              ],
              [
                -73.94280765199987,
                40.69235779399988
              ],
              [
                -73.942951361,
                40.693090783999885
              ],
              [
                -73.94310040999994,
                40.69382302899988
              ],
              [
                -73.9431142779999,
                40.6938947199999
              ],
              [
                -73.94312826799984,
                40.6939670379999
              ],
              [
                -73.94324249099988,
                40.694557484999955
              ],
              [
                -73.943388021,
                40.69528898999993
              ],
              [
                -73.94352527499989,
                40.6960308549999
              ],
              [
                -73.94354024099975,
                40.696108141999964
              ],
              [
                -73.94355634200002,
                40.696191282999926
              ],
              [
                -73.94362121299996,
                40.69652627899993
              ],
              [
                -73.9436380689999,
                40.69661331799992
              ],
              [
                -73.94368427099982,
                40.69685190199992
              ],
              [
                -73.9437245359998,
                40.69705981199991
              ],
              [
                -73.9437430669999,
                40.69715549899994
              ],
              [
                -73.94378455600005,
                40.697369728999924
              ],
              [
                -73.94380383199999,
                40.69746926499997
              ],
              [
                -73.94391750199989,
                40.698056201999854
              ],
              [
                -73.94394947200006,
                40.698221278999895
              ],
              [
                -73.94409591299996,
                40.698972954999924
              ],
              [
                -73.94424286099989,
                40.699699279999926
              ],
              [
                -73.94438788599984,
                40.70042452299993
              ],
              [
                -73.94193078899983,
                40.7007252339999
              ],
              [
                -73.94220058700002,
                40.70089066699992
              ],
              [
                -73.94306406899986,
                40.701424434999886
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000546661094782,
        "objectid": 19,
        "shape_leng": 0.101824875452,
        "location_id": 19,
        "zone": "Bellerose",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.72339596299987,
                40.750389075999855
              ],
              [
                -73.72627213299992,
                40.74930649099986
              ],
              [
                -73.727664654,
                40.749654641999946
              ],
              [
                -73.72781151199987,
                40.74929691999996
              ],
              [
                -73.72794170099989,
                40.74893498799989
              ],
              [
                -73.72805493499999,
                40.74856952199988
              ],
              [
                -73.72815098799995,
                40.74820123199988
              ],
              [
                -73.72822968899995,
                40.74783080399989
              ],
              [
                -73.72829094199989,
                40.74745895699992
              ],
              [
                -73.72888621599986,
                40.74756663599992
              ],
              [
                -73.728962516,
                40.74763288799988
              ],
              [
                -73.72906670999978,
                40.74739095899988
              ],
              [
                -73.729145939,
                40.74727379999996
              ],
              [
                -73.72953448999986,
                40.74671639599991
              ],
              [
                -73.72964619299997,
                40.74656191399991
              ],
              [
                -73.72993169099983,
                40.74618812499995
              ],
              [
                -73.73043134700004,
                40.74558483599989
              ],
              [
                -73.73062801899988,
                40.74536415799988
              ],
              [
                -73.7310646189999,
                40.744888375999885
              ],
              [
                -73.73226918099988,
                40.743818961999864
              ],
              [
                -73.73232731699993,
                40.74377143299989
              ],
              [
                -73.73241694699988,
                40.74369457599995
              ],
              [
                -73.7336646159999,
                40.742813183999885
              ],
              [
                -73.73370171599998,
                40.74278515699992
              ],
              [
                -73.73375484899985,
                40.742747627999975
              ],
              [
                -73.7343696589998,
                40.74235159899991
              ],
              [
                -73.73539272699988,
                40.741811049999896
              ],
              [
                -73.73560786699984,
                40.741696219999895
              ],
              [
                -73.73685894199984,
                40.741050400999875
              ],
              [
                -73.7375103809998,
                40.74071544799988
              ],
              [
                -73.73816047999985,
                40.74037643499988
              ],
              [
                -73.73857175899998,
                40.74009212299993
              ],
              [
                -73.73901210399985,
                40.73974647899997
              ],
              [
                -73.73933151399986,
                40.739474474999916
              ],
              [
                -73.73972244199987,
                40.73903608999993
              ],
              [
                -73.73986995499992,
                40.7388669009999
              ],
              [
                -73.74016951799993,
                40.738481897999876
              ],
              [
                -73.74144628700002,
                40.736938745999964
              ],
              [
                -73.74151636899985,
                40.73686036099988
              ],
              [
                -73.74158944499997,
                40.736782008999946
              ],
              [
                -73.74228431199992,
                40.73608640799994
              ],
              [
                -73.742947859,
                40.73545655199991
              ],
              [
                -73.74472417799997,
                40.734011029999905
              ],
              [
                -73.74555368099982,
                40.733404317999906
              ],
              [
                -73.7461625949999,
                40.73292721999995
              ],
              [
                -73.74672140400004,
                40.73247715099991
              ],
              [
                -73.74671314399986,
                40.73236436899988
              ],
              [
                -73.74669850599986,
                40.73221018799995
              ],
              [
                -73.74667404599982,
                40.73195264999993
              ],
              [
                -73.74665689499989,
                40.73181492399991
              ],
              [
                -73.74662058899987,
                40.73167780499995
              ],
              [
                -73.74659290699987,
                40.73161070999988
              ],
              [
                -73.74656523699987,
                40.73154360499996
              ],
              [
                -73.74649162199995,
                40.73141458899992
              ],
              [
                -73.74640108899996,
                40.73129283899991
              ],
              [
                -73.74629555799991,
                40.731180177999875
              ],
              [
                -73.74555667199989,
                40.73063689799994
              ],
              [
                -73.74543152500001,
                40.73061791799989
              ],
              [
                -73.74488800699997,
                40.73048310599994
              ],
              [
                -73.74417482499997,
                40.730322763999936
              ],
              [
                -73.74411105400003,
                40.73030792499985
              ],
              [
                -73.74398330999986,
                40.73027821199989
              ],
              [
                -73.74385189999987,
                40.73024764599997
              ],
              [
                -73.7438387839998,
                40.73024459999991
              ],
              [
                -73.74376398999986,
                40.73022719799998
              ],
              [
                -73.74242143799988,
                40.72991886499995
              ],
              [
                -73.74230785999983,
                40.72989362199996
              ],
              [
                -73.74200329399991,
                40.729825922999936
              ],
              [
                -73.74080920999988,
                40.72956050799996
              ],
              [
                -73.74040469999989,
                40.72946953699989
              ],
              [
                -73.73968059999994,
                40.729306682999926
              ],
              [
                -73.73904904900003,
                40.72916463899989
              ],
              [
                -73.73840175399988,
                40.72901152399988
              ],
              [
                -73.73816144099993,
                40.72895809099992
              ],
              [
                -73.73715501999992,
                40.728732010999884
              ],
              [
                -73.73632483599988,
                40.72854566199994
              ],
              [
                -73.73545493899995,
                40.728345624999925
              ],
              [
                -73.73498719899997,
                40.72824220599991
              ],
              [
                -73.73450816499992,
                40.72813640999992
              ],
              [
                -73.73424214199997,
                40.728075379999915
              ],
              [
                -73.73361727799991,
                40.72793357399994
              ],
              [
                -73.73272577999994,
                40.727734069999904
              ],
              [
                -73.73161735999996,
                40.72744912699996
              ],
              [
                -73.7314721509999,
                40.727411794999924
              ],
              [
                -73.73144806399984,
                40.72734384999995
              ],
              [
                -73.73121918199986,
                40.72669819599987
              ],
              [
                -73.73095653299988,
                40.726014939999864
              ],
              [
                -73.73069257599995,
                40.72532826699992
              ],
              [
                -73.73043003899987,
                40.724640254999876
              ],
              [
                -73.73016587199996,
                40.723958595999896
              ],
              [
                -73.72990512499977,
                40.72327249499997
              ],
              [
                -73.72836415399992,
                40.72361435199985
              ],
              [
                -73.72818574899999,
                40.72314563699992
              ],
              [
                -73.72816233910696,
                40.7230841398913
              ],
              [
                -73.72797157476118,
                40.723159116247636
              ],
              [
                -73.72732826508961,
                40.72341196312065
              ],
              [
                -73.72647559477359,
                40.72375249619399
              ],
              [
                -73.72567196979708,
                40.72403765899656
              ],
              [
                -73.72508489078929,
                40.72419561380043
              ],
              [
                -73.72493417712293,
                40.724244170173215
              ],
              [
                -73.72473123486797,
                40.72430839576523
              ],
              [
                -73.72471792493599,
                40.72431260475056
              ],
              [
                -73.72463422510643,
                40.72433644820515
              ],
              [
                -73.72454518798916,
                40.72436003595223
              ],
              [
                -73.72419675486013,
                40.72445460193022
              ],
              [
                -73.7234275409709,
                40.72466335095062
              ],
              [
                -73.72315163514787,
                40.724733207018275
              ],
              [
                -73.72296233421183,
                40.724781127902155
              ],
              [
                -73.72295712084203,
                40.72478244880552
              ],
              [
                -73.72291170303338,
                40.7247939467331
              ],
              [
                -73.72257879302599,
                40.72487767601809
              ],
              [
                -73.72204226687052,
                40.7250126029472
              ],
              [
                -73.7211926570586,
                40.72526654619541
              ],
              [
                -73.72038627275316,
                40.72547455419105
              ],
              [
                -73.71828706789265,
                40.72604111113519
              ],
              [
                -73.71587971997315,
                40.7265419058296
              ],
              [
                -73.71496944887915,
                40.72664661018486
              ],
              [
                -73.71407555595218,
                40.726759930163915
              ],
              [
                -73.71191821920186,
                40.72704180707466
              ],
              [
                -73.7105628560812,
                40.7272188714254
              ],
              [
                -73.71049995381028,
                40.727227087206614
              ],
              [
                -73.71050351799981,
                40.72727838999988
              ],
              [
                -73.7105550639999,
                40.728020849999915
              ],
              [
                -73.7106187189999,
                40.72883987399995
              ],
              [
                -73.710655187,
                40.729382965999925
              ],
              [
                -73.71066275899989,
                40.72949571099993
              ],
              [
                -73.71075217299996,
                40.73052537499992
              ],
              [
                -73.71089324899998,
                40.730971041999936
              ],
              [
                -73.71147804899985,
                40.73250140099988
              ],
              [
                -73.71186191499989,
                40.73315683699991
              ],
              [
                -73.71208085999997,
                40.7335185259999
              ],
              [
                -73.7122862199999,
                40.73385241799987
              ],
              [
                -73.71250100899984,
                40.73420163299992
              ],
              [
                -73.71278769000006,
                40.73467038099989
              ],
              [
                -73.71283004500002,
                40.734739627999964
              ],
              [
                -73.71289825099976,
                40.73485113699989
              ],
              [
                -73.71328662099985,
                40.735473124999935
              ],
              [
                -73.71370155699985,
                40.736160121999944
              ],
              [
                -73.71436629299991,
                40.737243891999896
              ],
              [
                -73.71476982799997,
                40.7379003479999
              ],
              [
                -73.71494063099992,
                40.73823459999989
              ],
              [
                -73.71496199799985,
                40.73827640999986
              ],
              [
                -73.71511121299982,
                40.73856840999989
              ],
              [
                -73.71544946799989,
                40.73921680399994
              ],
              [
                -73.71578267699999,
                40.739895785999884
              ],
              [
                -73.71656758399989,
                40.74164908599988
              ],
              [
                -73.71740598499986,
                40.74351092999988
              ],
              [
                -73.7176030609999,
                40.743974627999876
              ],
              [
                -73.71762516399986,
                40.74402847899988
              ],
              [
                -73.71764302999979,
                40.744070413999964
              ],
              [
                -73.71823250099992,
                40.74539666299989
              ],
              [
                -73.71853063599995,
                40.746039329999924
              ],
              [
                -73.71869037499981,
                40.746392765999936
              ],
              [
                -73.71892134799995,
                40.74689486899995
              ],
              [
                -73.71894406299995,
                40.74694425599994
              ],
              [
                -73.71896368299994,
                40.74699473999995
              ],
              [
                -73.71988664100002,
                40.749369510999955
              ],
              [
                -73.72010148299994,
                40.74977568599992
              ],
              [
                -73.720144294,
                40.74976283099995
              ],
              [
                -73.72258571899978,
                40.74885045099993
              ],
              [
                -73.72339596299987,
                40.750389075999855
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000134512633032,
        "objectid": 20,
        "shape_leng": 0.0514401924362,
        "location_id": 20,
        "zone": "Belmont",
        "borough": "Bronx"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.88386792099986,
                40.8642908889999
              ],
              [
                -73.88392395799984,
                40.86441776099987
              ],
              [
                -73.88396002399999,
                40.86454971899989
              ],
              [
                -73.88397514999986,
                40.864683997999926
              ],
              [
                -73.88396922299982,
                40.86481774099986
              ],
              [
                -73.88394303099996,
                40.86494818699986
              ],
              [
                -73.88390334899987,
                40.86507380199995
              ],
              [
                -73.88384464399992,
                40.86519681299989
              ],
              [
                -73.88376749199992,
                40.86531475299996
              ],
              [
                -73.88367321300004,
                40.865425282999944
              ],
              [
                -73.88356377799998,
                40.86552635199991
              ],
              [
                -73.88344168299979,
                40.865616331999895
              ],
              [
                -73.88319816099992,
                40.86576631399988
              ],
              [
                -73.88273658899989,
                40.86604857299993
              ],
              [
                -73.88267624699999,
                40.866089140999854
              ],
              [
                -73.88271794699989,
                40.86613296599992
              ],
              [
                -73.88297537099986,
                40.8664497319999
              ],
              [
                -73.8830900319999,
                40.86659150899994
              ],
              [
                -73.88362518100003,
                40.86725758799991
              ],
              [
                -73.88442861399994,
                40.86680037899987
              ],
              [
                -73.88513907699999,
                40.86638287399992
              ],
              [
                -73.88593703500003,
                40.86592439799995
              ],
              [
                -73.8868331699999,
                40.865389992999894
              ],
              [
                -73.8881227879999,
                40.8642545709999
              ],
              [
                -73.88889566099989,
                40.86475415299988
              ],
              [
                -73.890161637,
                40.863476362999954
              ],
              [
                -73.89065392999989,
                40.86295483399993
              ],
              [
                -73.8917325659999,
                40.861909814999905
              ],
              [
                -73.89177188799992,
                40.86187171599992
              ],
              [
                -73.89138023399983,
                40.86170058799992
              ],
              [
                -73.89106280699994,
                40.86152941199992
              ],
              [
                -73.89082169799998,
                40.86139939599989
              ],
              [
                -73.89067455199995,
                40.86132004099989
              ],
              [
                -73.89036823899993,
                40.86115807599987
              ],
              [
                -73.89027555599993,
                40.86110907299996
              ],
              [
                -73.89059489199987,
                40.86071569999991
              ],
              [
                -73.89112568799987,
                40.860061824999946
              ],
              [
                -73.89112461399988,
                40.8588700059999
              ],
              [
                -73.89111943300001,
                40.85865896199989
              ],
              [
                -73.89111115400003,
                40.85838432199988
              ],
              [
                -73.89111318899985,
                40.85785099899988
              ],
              [
                -73.8911055689998,
                40.85742738299994
              ],
              [
                -73.89109710400002,
                40.85677981399989
              ],
              [
                -73.89108676599993,
                40.85659725699986
              ],
              [
                -73.8907742849999,
                40.855811939999946
              ],
              [
                -73.89044259299993,
                40.85504635099993
              ],
              [
                -73.89038953999983,
                40.85468905799998
              ],
              [
                -73.890030396,
                40.85422099199995
              ],
              [
                -73.88960978099986,
                40.85362187999994
              ],
              [
                -73.88963328299991,
                40.85317381799995
              ],
              [
                -73.88993961599995,
                40.852590762999924
              ],
              [
                -73.88987871399985,
                40.852070220999956
              ],
              [
                -73.88963044999998,
                40.85188593199995
              ],
              [
                -73.88951837699989,
                40.85180274499989
              ],
              [
                -73.88895256299988,
                40.85144078099994
              ],
              [
                -73.8887824569999,
                40.85134118499993
              ],
              [
                -73.88823974099995,
                40.85103536599996
              ],
              [
                -73.88800539899992,
                40.850976102999944
              ],
              [
                -73.88751770499984,
                40.85094946799989
              ],
              [
                -73.88643555899993,
                40.850309427999875
              ],
              [
                -73.88581418499989,
                40.84993079699989
              ],
              [
                -73.88480193499986,
                40.84930945899987
              ],
              [
                -73.88377755699985,
                40.8486821839999
              ],
              [
                -73.88322877699981,
                40.848345877999876
              ],
              [
                -73.88311982600001,
                40.848221091999925
              ],
              [
                -73.88288269199988,
                40.84907404699989
              ],
              [
                -73.88284931500006,
                40.84919252699994
              ],
              [
                -73.88255596199991,
                40.85022427099989
              ],
              [
                -73.88232256599994,
                40.85106079199989
              ],
              [
                -73.88200299099991,
                40.85218935799987
              ],
              [
                -73.8816727419998,
                40.8533496789999
              ],
              [
                -73.88103358999996,
                40.8555999519999
              ],
              [
                -73.88073042799992,
                40.85667660499991
              ],
              [
                -73.88072954099998,
                40.85680727499992
              ],
              [
                -73.88077132199986,
                40.8570397609999
              ],
              [
                -73.88079155299991,
                40.85712750799994
              ],
              [
                -73.88080810099991,
                40.857198447999885
              ],
              [
                -73.88088225100002,
                40.85750954499986
              ],
              [
                -73.88094184999993,
                40.85769449599996
              ],
              [
                -73.880941932,
                40.857694738999925
              ],
              [
                -73.88094199099993,
                40.857694990999974
              ],
              [
                -73.88098293799995,
                40.85788399199989
              ],
              [
                -73.88100464399997,
                40.85807565699992
              ],
              [
                -73.88100678999979,
                40.85826802299992
              ],
              [
                -73.88098942799995,
                40.858459358999895
              ],
              [
                -73.880965862,
                40.85854520499987
              ],
              [
                -73.88075871500001,
                40.85949442499993
              ],
              [
                -73.88060896899991,
                40.8603570929999
              ],
              [
                -73.88061726900006,
                40.860497010999936
              ],
              [
                -73.88064370299989,
                40.86063731599991
              ],
              [
                -73.88068839499986,
                40.86077599299989
              ],
              [
                -73.88075099099991,
                40.86091098799991
              ],
              [
                -73.88083055899997,
                40.861040343999946
              ],
              [
                -73.88092572600007,
                40.861162287999925
              ],
              [
                -73.88103469099984,
                40.8612752949999
              ],
              [
                -73.88200794099986,
                40.862046852999946
              ],
              [
                -73.8837937109999,
                40.86417157099989
              ],
              [
                -73.88379386499992,
                40.86417182299991
              ],
              [
                -73.88386792099986,
                40.8642908889999
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000380251345507,
        "objectid": 21,
        "shape_leng": 0.115973569062,
        "location_id": 21,
        "zone": "Bensonhurst East",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.97418385499991,
                40.60946350199992
              ],
              [
                -73.974390729,
                40.6105792129999
              ],
              [
                -73.97451543899997,
                40.61125680099988
              ],
              [
                -73.97458407499984,
                40.61161357599985
              ],
              [
                -73.97477658,
                40.6126384749999
              ],
              [
                -73.97520829399998,
                40.61288525799992
              ],
              [
                -73.977408165,
                40.61421811299988
              ],
              [
                -73.97963821799982,
                40.61556454699991
              ],
              [
                -73.98022027499997,
                40.6150049489999
              ],
              [
                -73.98080506199987,
                40.61444712999986
              ],
              [
                -73.98143750099992,
                40.61383768799993
              ],
              [
                -73.98174843299985,
                40.613554623999946
              ],
              [
                -73.98206106199993,
                40.613238418999885
              ],
              [
                -73.98215550199991,
                40.613149614999934
              ],
              [
                -73.98265927499982,
                40.61269247099993
              ],
              [
                -73.98322226999993,
                40.61212220899992
              ],
              [
                -73.98335142399998,
                40.61199230099992
              ],
              [
                -73.98380893599982,
                40.611559872999855
              ],
              [
                -73.984162011,
                40.611217300999876
              ],
              [
                -73.98438244899988,
                40.611005419999884
              ],
              [
                -73.98496383499995,
                40.61044799399991
              ],
              [
                -73.98535587499991,
                40.61007201399988
              ],
              [
                -73.98554853199988,
                40.60988724299989
              ],
              [
                -73.98575987199992,
                40.60968408399991
              ],
              [
                -73.98612915399993,
                40.60932986399989
              ],
              [
                -73.98671329999988,
                40.60876626099987
              ],
              [
                -73.98733917699988,
                40.60816828599996
              ],
              [
                -73.9873779529998,
                40.608128117999875
              ],
              [
                -73.98796510300004,
                40.60756553599988
              ],
              [
                -73.98842267599987,
                40.60711914899985
              ],
              [
                -73.98855078899993,
                40.60700228299991
              ],
              [
                -73.98913191499994,
                40.60645613699991
              ],
              [
                -73.98864527999999,
                40.606166653999914
              ],
              [
                -73.9868476259999,
                40.60509723599994
              ],
              [
                -73.98747998199978,
                40.60453971199996
              ],
              [
                -73.98806312999994,
                40.60397945199985
              ],
              [
                -73.98869008799991,
                40.603425306999966
              ],
              [
                -73.9892234389999,
                40.60286567499987
              ],
              [
                -73.989807573,
                40.60230291099994
              ],
              [
                -73.99038998499982,
                40.60174340299995
              ],
              [
                -73.99097171199985,
                40.601184559999865
              ],
              [
                -73.99159842599994,
                40.60058036599989
              ],
              [
                -73.99336225799983,
                40.59888868899989
              ],
              [
                -73.99522558800007,
                40.59709332799986
              ],
              [
                -73.99630015799983,
                40.59606105399993
              ],
              [
                -73.99674706899995,
                40.59564312599992
              ],
              [
                -73.99669451199998,
                40.595599205999875
              ],
              [
                -73.99639126099986,
                40.59534580199989
              ],
              [
                -73.99611613099992,
                40.59511588599987
              ],
              [
                -73.99587725299996,
                40.59491413699993
              ],
              [
                -73.99549868199995,
                40.59459440699994
              ],
              [
                -73.99530829599992,
                40.594427586999906
              ],
              [
                -73.99535761899992,
                40.594403327999935
              ],
              [
                -73.99619345599999,
                40.593992197999896
              ],
              [
                -73.99629319699989,
                40.59371644199992
              ],
              [
                -73.99630983500005,
                40.593667751999895
              ],
              [
                -73.99633951299984,
                40.59362247499995
              ],
              [
                -73.99736802499984,
                40.59397431099995
              ],
              [
                -73.99838831091802,
                40.59353139180308
              ],
              [
                -73.99897464310261,
                40.593306369435545
              ],
              [
                -73.9991972768818,
                40.59317814395959
              ],
              [
                -73.9994830488618,
                40.59296919994875
              ],
              [
                -73.99947019385696,
                40.59289705182673
              ],
              [
                -73.99945009678441,
                40.59282587130941
              ],
              [
                -73.99942287662074,
                40.59275608014794
              ],
              [
                -73.99938869468849,
                40.592688091910205
              ],
              [
                -73.99934775356313,
                40.59262230947899
              ],
              [
                -73.99930029587152,
                40.59255912266502
              ],
              [
                -73.99909339192615,
                40.59244649330886
              ],
              [
                -73.99907629609663,
                40.592443474281794
              ],
              [
                -73.9990600974859,
                40.59243832901494
              ],
              [
                -73.9990452476599,
                40.59243120094194
              ],
              [
                -73.99903216058368,
                40.592422288770635
              ],
              [
                -73.99902120108229,
                40.59241184094384
              ],
              [
                -73.99901267467027,
                40.59240014871339
              ],
              [
                -73.99900681903495,
                40.59238753802082
              ],
              [
                -73.99900379741118,
                40.59237436041129
              ],
              [
                -73.99900369403004,
                40.592360983233675
              ],
              [
                -73.99900651177178,
                40.592347779399724
              ],
              [
                -73.99901217208547,
                40.5923351169889
              ],
              [
                -73.99902051717862,
                40.592323348987264
              ],
              [
                -73.99903131441663,
                40.59231280344757
              ],
              [
                -73.99930612934223,
                40.59217872640601
              ],
              [
                -73.99967223219583,
                40.5919637586688
              ],
              [
                -74.00005812170888,
                40.59171484748446
              ],
              [
                -74.00023253344592,
                40.59153130855936
              ],
              [
                -74.00023075282738,
                40.591478489769656
              ],
              [
                -74.00022000419699,
                40.59142629276542
              ],
              [
                -74.00020046901544,
                40.59137559864704
              ],
              [
                -74.00017247706104,
                40.59132726314403
              ],
              [
                -74.00013650086177,
                40.591282102170034
              ],
              [
                -74.00009314771766,
                40.59124087805044
              ],
              [
                -74.00004314944917,
                40.591204286654616
              ],
              [
                -73.9999873500428,
                40.591172945649255
              ],
              [
                -73.99992669140542,
                40.59114738407373
              ],
              [
                -73.99986219746393,
                40.59112803340917
              ],
              [
                -73.99957329199391,
                40.59109256658336
              ],
              [
                -73.99916761862079,
                40.59114913581574
              ],
              [
                -73.99856404800653,
                40.591492325309545
              ],
              [
                -73.99754851058395,
                40.59191757917553
              ],
              [
                -73.99728269703776,
                40.59171481486811
              ],
              [
                -73.99740518642399,
                40.59164508498033
              ],
              [
                -73.99812869461262,
                40.59128866425791
              ],
              [
                -73.99806438267662,
                40.59120946473145
              ],
              [
                -73.99722828062708,
                40.59165447187027
              ],
              [
                -73.99718982739483,
                40.59160618140396
              ],
              [
                -73.99715819693523,
                40.59155509477776
              ],
              [
                -73.99713373485125,
                40.5915017702026
              ],
              [
                -73.99711670841539,
                40.59144679034146
              ],
              [
                -73.99710730365224,
                40.59139075594306
              ],
              [
                -73.99710562330513,
                40.59133427927691
              ],
              [
                -73.99711168571537,
                40.59127797744375
              ],
              [
                -73.99775972701352,
                40.5909524696138
              ],
              [
                -73.99835552304194,
                40.590334983147535
              ],
              [
                -73.9983963311172,
                40.59028210025493
              ],
              [
                -73.99844325936856,
                40.59024010549443
              ],
              [
                -73.99849018691934,
                40.59021677414822
              ],
              [
                -73.99853303406093,
                40.59020899816093
              ],
              [
                -73.9985942438702,
                40.59022766353233
              ],
              [
                -73.99865341212593,
                40.590291436444666
              ],
              [
                -73.99868809619367,
                40.59032565570913
              ],
              [
                -73.99873298307851,
                40.59033498792955
              ],
              [
                -73.99877800589593,
                40.59031435534189
              ],
              [
                -73.99879419437912,
                40.59028054994432
              ],
              [
                -73.99877991358242,
                40.5902323318472
              ],
              [
                -73.99874318832653,
                40.590190336045396
              ],
              [
                -73.99868197911874,
                40.59012967417432
              ],
              [
                -73.99862485126727,
                40.59008456707945
              ],
              [
                -73.99858515364288,
                40.59004738497782
              ],
              [
                -73.99855344167928,
                40.590017683146236
              ],
              [
                -73.99851059611983,
                40.58996013293428
              ],
              [
                -73.99844387079486,
                40.58986039917759
              ],
              [
                -73.9988658617368,
                40.58966697528812
              ],
              [
                -73.99883618014556,
                40.5896254895423
              ],
              [
                -73.99842061695254,
                40.589814054559604
              ],
              [
                -73.99837834882115,
                40.589770445088554
              ],
              [
                -73.99946446811052,
                40.589233269380664
              ],
              [
                -73.99923690396953,
                40.588935328269024
              ],
              [
                -73.99867293375554,
                40.58919554993961
              ],
              [
                -73.99801867061471,
                40.588425400020526
              ],
              [
                -73.99375761899992,
                40.59033184399987
              ],
              [
                -73.99330855,
                40.58958389899994
              ],
              [
                -73.99297835999997,
                40.58973003299997
              ],
              [
                -73.99291591499995,
                40.58975737899989
              ],
              [
                -73.99286067699995,
                40.58978269799996
              ],
              [
                -73.99255747799991,
                40.589921674999914
              ],
              [
                -73.99161412399994,
                40.59035644499994
              ],
              [
                -73.99153934399993,
                40.59039090299989
              ],
              [
                -73.99147342099985,
                40.590449305999925
              ],
              [
                -73.99066311999997,
                40.5911671669999
              ],
              [
                -73.99036425099996,
                40.591431931999935
              ],
              [
                -73.98962150099992,
                40.59098199299996
              ],
              [
                -73.98798856399989,
                40.592552777999856
              ],
              [
                -73.98601936799993,
                40.59444604899989
              ],
              [
                -73.98467794099993,
                40.59365864999992
              ],
              [
                -73.98463965399982,
                40.59355217599989
              ],
              [
                -73.98412888499986,
                40.59361066699993
              ],
              [
                -73.98407040399994,
                40.59361736399989
              ],
              [
                -73.98366766399984,
                40.59366348199994
              ],
              [
                -73.98354622399994,
                40.59367650999993
              ],
              [
                -73.9834412439999,
                40.59368777299988
              ],
              [
                -73.98303296899982,
                40.59373029299993
              ],
              [
                -73.98273346399986,
                40.593763698999915
              ],
              [
                -73.98179084800002,
                40.593867455999906
              ],
              [
                -73.98071099999997,
                40.59399739599998
              ],
              [
                -73.97995443499985,
                40.5940738119999
              ],
              [
                -73.98014301299985,
                40.595066175999904
              ],
              [
                -73.98035785499995,
                40.59619154099992
              ],
              [
                -73.9794258679999,
                40.59629752299988
              ],
              [
                -73.97890571399992,
                40.596352729999914
              ],
              [
                -73.9785016919999,
                40.596394852999914
              ],
              [
                -73.97750674199996,
                40.596506452999904
              ],
              [
                -73.97650934500001,
                40.59661610899985
              ],
              [
                -73.97558819299998,
                40.59671920599991
              ],
              [
                -73.97503940399993,
                40.59678002699996
              ],
              [
                -73.97492695599999,
                40.596793537999936
              ],
              [
                -73.9740839059999,
                40.596882943999965
              ],
              [
                -73.97323983499986,
                40.59697881799985
              ],
              [
                -73.97148350899987,
                40.59717158499997
              ],
              [
                -73.97163038199992,
                40.59794401699991
              ],
              [
                -73.97176105299991,
                40.59865193199991
              ],
              [
                -73.9719108049999,
                40.599423058999896
              ],
              [
                -73.97291116900001,
                40.59931580399996
              ],
              [
                -73.9725653369999,
                40.601794372999905
              ],
              [
                -73.972452617,
                40.6026170539999
              ],
              [
                -73.97235152099995,
                40.60334811899988
              ],
              [
                -73.97217742800002,
                40.60419471199986
              ],
              [
                -73.97239735499981,
                40.605651889999876
              ],
              [
                -73.97258982399998,
                40.6066715329999
              ],
              [
                -73.97299433999983,
                40.608814141999886
              ],
              [
                -73.97316643599986,
                40.60879557699993
              ],
              [
                -73.97403097799986,
                40.608702309999906
              ],
              [
                -73.97418385499991,
                40.60946350199992
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000534039927626,
        "objectid": 26,
        "shape_leng": 0.12354780707,
        "location_id": 26,
        "zone": "Borough Park",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.98331628499983,
                40.641478681999914
              ],
              [
                -73.98430293099987,
                40.64208456299986
              ],
              [
                -73.98458719499989,
                40.64226200399989
              ],
              [
                -73.985095196,
                40.642573055999925
              ],
              [
                -73.9858964449999,
                40.643067594999906
              ],
              [
                -73.98673080599988,
                40.64357399299989
              ],
              [
                -73.98749940600001,
                40.64404550999989
              ],
              [
                -73.98834986699991,
                40.64456224399992
              ],
              [
                -73.98905872499985,
                40.64411924099995
              ],
              [
                -73.98977890299996,
                40.64364488699991
              ],
              [
                -73.99049634100004,
                40.64316444099996
              ],
              [
                -73.99121277399996,
                40.642685890999935
              ],
              [
                -73.991928124,
                40.64220490399991
              ],
              [
                -73.99264456099989,
                40.64172828999991
              ],
              [
                -73.99336253699994,
                40.641247798999885
              ],
              [
                -73.99407691999981,
                40.640770401999944
              ],
              [
                -73.99433124099983,
                40.64060028799991
              ],
              [
                -73.99479539499987,
                40.640288486999914
              ],
              [
                -73.9955103369999,
                40.63980966699993
              ],
              [
                -73.99697357399992,
                40.64069410499995
              ],
              [
                -73.99917564799992,
                40.642025441999856
              ],
              [
                -73.99975690799991,
                40.6414671499999
              ],
              [
                -74.00034122099999,
                40.64090531599992
              ],
              [
                -74.00066078199991,
                40.64059930899991
              ],
              [
                -74.00092541799995,
                40.64034590199991
              ],
              [
                -74.00150771299988,
                40.63978496199995
              ],
              [
                -74.00209075399987,
                40.63922525399994
              ],
              [
                -74.00236943499988,
                40.63895767799987
              ],
              [
                -74.00267214299998,
                40.63866700999993
              ],
              [
                -74.00325273099993,
                40.63810426199991
              ],
              [
                -74.00383793099998,
                40.63754593099993
              ],
              [
                -74.00411104099992,
                40.637282960999926
              ],
              [
                -74.00442048399997,
                40.63698498599989
              ],
              [
                -74.00500346499996,
                40.636425234999905
              ],
              [
                -74.00558612999998,
                40.63586622899988
              ],
              [
                -74.00616926899994,
                40.63530350099989
              ],
              [
                -74.00641784699992,
                40.635064849999885
              ],
              [
                -74.00675122699992,
                40.63474478699988
              ],
              [
                -74.00735636699996,
                40.63416362699991
              ],
              [
                -74.00556490399993,
                40.63308317599992
              ],
              [
                -74.00630615499989,
                40.63258408799993
              ],
              [
                -74.00661914999998,
                40.63237545799997
              ],
              [
                -74.006806779,
                40.63225039099994
              ],
              [
                -74.00702302499997,
                40.63210999499996
              ],
              [
                -74.00633870699993,
                40.63169362599994
              ],
              [
                -74.00413727999998,
                40.63036396599992
              ],
              [
                -74.00368597199991,
                40.63009178099988
              ],
              [
                -74.00306153999993,
                40.6297151749999
              ],
              [
                -74.00193011399998,
                40.629032766999885
              ],
              [
                -74.00222793499994,
                40.62875116999988
              ],
              [
                -74.00251454799997,
                40.62847420999989
              ],
              [
                -74.00031369499995,
                40.627144582999925
              ],
              [
                -73.99811614300002,
                40.62581570699993
              ],
              [
                -73.99724067099987,
                40.62528868699994
              ],
              [
                -73.99701633599989,
                40.62606161499991
              ],
              [
                -73.99687778399999,
                40.626530782999964
              ],
              [
                -73.99678847599995,
                40.626826050999895
              ],
              [
                -73.99507814899985,
                40.62580673699993
              ],
              [
                -73.99464972999995,
                40.62569717899992
              ],
              [
                -73.99473485699997,
                40.625614740999914
              ],
              [
                -73.99489280599995,
                40.625461793999925
              ],
              [
                -73.99432497399985,
                40.625342907999894
              ],
              [
                -73.99398197100003,
                40.62514139899989
              ],
              [
                -73.99254973599997,
                40.62427426799996
              ],
              [
                -73.99009356099988,
                40.62278895299992
              ],
              [
                -73.98785095499996,
                40.62143503199992
              ],
              [
                -73.9856935779999,
                40.62013151399989
              ],
              [
                -73.98627524699981,
                40.61956661699989
              ],
              [
                -73.98406791799982,
                40.61824073899989
              ],
              [
                -73.98186818299985,
                40.61691212699987
              ],
              [
                -73.98087912799988,
                40.61631443499988
              ],
              [
                -73.98003930399985,
                40.61580692999996
              ],
              [
                -73.97963821799982,
                40.61556454699991
              ],
              [
                -73.977408165,
                40.61421811299988
              ],
              [
                -73.97520829399998,
                40.61288525799992
              ],
              [
                -73.97477658,
                40.6126384749999
              ],
              [
                -73.97489295799981,
                40.61318636799989
              ],
              [
                -73.97497264699993,
                40.613668109999885
              ],
              [
                -73.97517176999992,
                40.61472418599991
              ],
              [
                -73.97399319500002,
                40.61402989399994
              ],
              [
                -73.97290326899996,
                40.61415296799988
              ],
              [
                -73.97335879399992,
                40.6165417149999
              ],
              [
                -73.97444328399989,
                40.61642164299991
              ],
              [
                -73.97548096999985,
                40.616311924999906
              ],
              [
                -73.975442767,
                40.61611408999994
              ],
              [
                -73.97537335500002,
                40.615754721999885
              ],
              [
                -73.97562954199996,
                40.61594539099984
              ],
              [
                -73.97785009299986,
                40.617287605999906
              ],
              [
                -73.97752301899989,
                40.617676540999895
              ],
              [
                -73.97682433599995,
                40.6186757999999
              ],
              [
                -73.97539380499998,
                40.62076998699996
              ],
              [
                -73.97705352899999,
                40.62153252199987
              ],
              [
                -73.97695070799992,
                40.621630006999986
              ],
              [
                -73.97656583899989,
                40.62199490299991
              ],
              [
                -73.97675209599991,
                40.6230133119999
              ],
              [
                -73.97683465199992,
                40.623481041999895
              ],
              [
                -73.976943824,
                40.624052749999855
              ],
              [
                -73.97710959299997,
                40.6249482599999
              ],
              [
                -73.97719665599993,
                40.62510197899993
              ],
              [
                -73.9772614999999,
                40.62585277299993
              ],
              [
                -73.97623453700001,
                40.62597634999991
              ],
              [
                -73.97644970399986,
                40.62700825499989
              ],
              [
                -73.9765146209999,
                40.62729448999988
              ],
              [
                -73.97657775699989,
                40.627573185999914
              ],
              [
                -73.97669604399998,
                40.62835456399995
              ],
              [
                -73.97684964299985,
                40.62908858099995
              ],
              [
                -73.97699848899987,
                40.62987149599992
              ],
              [
                -73.97716511999984,
                40.630746653999886
              ],
              [
                -73.97604935699994,
                40.63127590599987
              ],
              [
                -73.97643342199997,
                40.63328912299988
              ],
              [
                -73.97655064099989,
                40.633903508999886
              ],
              [
                -73.97663655999995,
                40.63435382099991
              ],
              [
                -73.9768886119999,
                40.63567486199991
              ],
              [
                -73.97802159000001,
                40.63555270299985
              ],
              [
                -73.97911188699993,
                40.63544038599991
              ],
              [
                -73.97928591699991,
                40.63641419399992
              ],
              [
                -73.97938644699988,
                40.63693216299988
              ],
              [
                -73.9794759209999,
                40.63743136399991
              ],
              [
                -73.97955441199994,
                40.63781925899985
              ],
              [
                -73.9796770499999,
                40.6384647999999
              ],
              [
                -73.97987360300003,
                40.63951820599989
              ],
              [
                -73.97990983199979,
                40.639686357999906
              ],
              [
                -73.98000693099998,
                40.640208836999896
              ],
              [
                -73.98007428599995,
                40.640539699999906
              ],
              [
                -73.98042057899988,
                40.64238522699991
              ],
              [
                -73.98123240499983,
                40.64204491299995
              ],
              [
                -73.98204321799996,
                40.64170758199987
              ],
              [
                -73.98244454199998,
                40.6415386259999
              ],
              [
                -73.98299419199992,
                40.64132499699987
              ],
              [
                -73.98331628499983,
                40.641478681999914
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.00134088762746,
        "objectid": 27,
        "shape_leng": 0.202508808518,
        "location_id": 27,
        "zone": "Breezy Point/Fort Tilden/Riis Beach",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.86522555399998,
                40.57045847199989
              ],
              [
                -73.86633836399983,
                40.57238167499988
              ],
              [
                -73.86662812000002,
                40.5730576489999
              ],
              [
                -73.86449302899979,
                40.573542775999925
              ],
              [
                -73.86361132999997,
                40.57382305699986
              ],
              [
                -73.86340629400003,
                40.57411433099986
              ],
              [
                -73.86394267899996,
                40.574988107999914
              ],
              [
                -73.86396642099993,
                40.57502678699988
              ],
              [
                -73.86341227899987,
                40.5753164769999
              ],
              [
                -73.86333068899997,
                40.575383252999934
              ],
              [
                -73.8627506159999,
                40.575857984999864
              ],
              [
                -73.86278001199996,
                40.57589856099986
              ],
              [
                -73.86280361999994,
                40.57593303499989
              ],
              [
                -73.86288301945429,
                40.57605976112732
              ],
              [
                -73.86348661935081,
                40.57574031588714
              ],
              [
                -73.86464221607892,
                40.575119214346415
              ],
              [
                -73.86554317468638,
                40.57464212861246
              ],
              [
                -73.86663956227181,
                40.57409921893447
              ],
              [
                -73.86718075481033,
                40.57383579492641
              ],
              [
                -73.87260077649111,
                40.57117643076299
              ],
              [
                -73.87316515806762,
                40.57090189988602
              ],
              [
                -73.87392287385016,
                40.57052948404443
              ],
              [
                -73.87443047584183,
                40.570281828763505
              ],
              [
                -73.87484758565488,
                40.57007545069467
              ],
              [
                -73.87510590878087,
                40.56995068544371
              ],
              [
                -73.87518736027619,
                40.569910348535146
              ],
              [
                -73.87543006202922,
                40.569789960054095
              ],
              [
                -73.87585867341238,
                40.56958044842765
              ],
              [
                -73.87598454034396,
                40.56951979017156
              ],
              [
                -73.87608408414432,
                40.56947288941025
              ],
              [
                -73.87611451688053,
                40.569459441448856
              ],
              [
                -73.87634075072575,
                40.56935313560297
              ],
              [
                -73.87643370124523,
                40.56931436855044
              ],
              [
                -73.87656038791111,
                40.56925715914535
              ],
              [
                -73.87662125007236,
                40.569231838911215
              ],
              [
                -73.87685485878126,
                40.56913462528098
              ],
              [
                -73.8770689967848,
                40.56904844043721
              ],
              [
                -73.87731299401725,
                40.568958360861224
              ],
              [
                -73.87740017966792,
                40.56892617883126
              ],
              [
                -73.87745281794943,
                40.56891025148544
              ],
              [
                -73.87760577562246,
                40.568860894937465
              ],
              [
                -73.8776378530969,
                40.5688518421286
              ],
              [
                -73.87780808995733,
                40.56880063006316
              ],
              [
                -73.87788701981546,
                40.5687822173686
              ],
              [
                -73.87797254160711,
                40.56876163155759
              ],
              [
                -73.87819124545662,
                40.56871491982495
              ],
              [
                -73.87830322053702,
                40.56869386726816
              ],
              [
                -73.87851699995322,
                40.568659783454656
              ],
              [
                -73.87867467361708,
                40.568639939837965
              ],
              [
                -73.87867973907235,
                40.56864652894117
              ],
              [
                -73.87868134846241,
                40.568658164746836
              ],
              [
                -73.878687266263,
                40.568663448185056
              ],
              [
                -73.87869687415929,
                40.568666312871535
              ],
              [
                -73.87871454945252,
                40.568667835282824
              ],
              [
                -73.87873193236219,
                40.56866777338858
              ],
              [
                -73.8787547597571,
                40.56866724798761
              ],
              [
                -73.87877015942387,
                40.56866676852045
              ],
              [
                -73.87878564227971,
                40.568665965806346
              ],
              [
                -73.87880406603716,
                40.568664831971326
              ],
              [
                -73.87882058842108,
                40.56866404777254
              ],
              [
                -73.87883510134054,
                40.56866434252133
              ],
              [
                -73.87885136038072,
                40.5686650986443
              ],
              [
                -73.8788706904323,
                40.568665857333215
              ],
              [
                -73.87888771809112,
                40.56866604600033
              ],
              [
                -73.87890849091838,
                40.56866554587747
              ],
              [
                -73.87892860441201,
                40.56866422483003
              ],
              [
                -73.87894689779121,
                40.568663388870114
              ],
              [
                -73.87896619312761,
                40.56866354423151
              ],
              [
                -73.87898677609904,
                40.56866367464419
              ],
              [
                -73.87900319058451,
                40.568663755596766
              ],
              [
                -73.87901906194138,
                40.56866362740031
              ],
              [
                -73.87903327914871,
                40.56866368676846
              ],
              [
                -73.87903572381265,
                40.5686636987245
              ],
              [
                -73.87905289440748,
                40.56866368933005
              ],
              [
                -73.87906848035361,
                40.568664066431296
              ],
              [
                -73.87908341950936,
                40.56866421718678
              ],
              [
                -73.87909806245412,
                40.56866428637677
              ],
              [
                -73.87911696789516,
                40.568664171244464
              ],
              [
                -73.87913428067395,
                40.56866389360484
              ],
              [
                -73.87915586741869,
                40.56866352764723
              ],
              [
                -73.87917440931017,
                40.56866266494019
              ],
              [
                -73.87919931538988,
                40.56866225857311
              ],
              [
                -73.87921878815278,
                40.568662215891074
              ],
              [
                -73.87923833181624,
                40.56866232707258
              ],
              [
                -73.8792590306537,
                40.5686637443543
              ],
              [
                -73.87928170142983,
                40.56866502901087
              ],
              [
                -73.87930192895583,
                40.56866551968504
              ],
              [
                -73.87931514238035,
                40.568665838807455
              ],
              [
                -73.87933579846116,
                40.568664536352046
              ],
              [
                -73.87936348238726,
                40.568662728632674
              ],
              [
                -73.87938896798258,
                40.56866160312508
              ],
              [
                -73.87941448510396,
                40.56866248465178
              ],
              [
                -73.8794413026328,
                40.568662837808574
              ],
              [
                -73.87946829663208,
                40.56866352268562
              ],
              [
                -73.87949270626528,
                40.56866333251752
              ],
              [
                -73.87951793036875,
                40.56866272822726
              ],
              [
                -73.87954539852859,
                40.56866239751532
              ],
              [
                -73.87956929987945,
                40.568662287044795
              ],
              [
                -73.87958856272876,
                40.56866120924843
              ],
              [
                -73.87960709085901,
                40.56866078672544
              ],
              [
                -73.87962638719442,
                40.56866075367351
              ],
              [
                -73.87964545944028,
                40.568660359189444
              ],
              [
                -73.87966465098816,
                40.56865922652345
              ],
              [
                -73.87968131494341,
                40.568658262422026
              ],
              [
                -73.87969751808572,
                40.56865736920345
              ],
              [
                -73.87971548137098,
                40.56865683901985
              ],
              [
                -73.87973365556653,
                40.56865666924342
              ],
              [
                -73.87975305662172,
                40.56865679710841
              ],
              [
                -73.87975818201656,
                40.56865687501407
              ],
              [
                -73.87976961248047,
                40.56865705819399
              ],
              [
                -73.8797862389313,
                40.56865691309131
              ],
              [
                -73.87980312654076,
                40.56865679573437
              ],
              [
                -73.87982198486952,
                40.568656977969745
              ],
              [
                -73.87983861024595,
                40.56865779632221
              ],
              [
                -73.87985268664355,
                40.568658216133144
              ],
              [
                -73.87987189778725,
                40.5686590113857
              ],
              [
                -73.87989303466885,
                40.56865946554774
              ],
              [
                -73.87992664178228,
                40.56866035592182
              ],
              [
                -73.87993758735001,
                40.568660809306266
              ],
              [
                -73.87995753008515,
                40.568661954943785
              ],
              [
                -73.87997972913301,
                40.56866340164108
              ],
              [
                -73.88000225656683,
                40.56866548646285
              ],
              [
                -73.8800250074982,
                40.5686678971916
              ],
              [
                -73.8800491891564,
                40.56867011993375
              ],
              [
                -73.88007402062833,
                40.568671621966004
              ],
              [
                -73.88009809627134,
                40.568672692317186
              ],
              [
                -73.88012340249261,
                40.56867321394736
              ],
              [
                -73.88012555136847,
                40.56867315303116
              ],
              [
                -73.88014654883912,
                40.568672580378426
              ],
              [
                -73.88016450973765,
                40.568673013585915
              ],
              [
                -73.88017818326908,
                40.568673658096834
              ],
              [
                -73.88019740435365,
                40.568675407387424
              ],
              [
                -73.88021503170239,
                40.5686771017384
              ],
              [
                -73.88023367653386,
                40.56867824597458
              ],
              [
                -73.88025376300214,
                40.568678752751914
              ],
              [
                -73.88027003542994,
                40.568678914124774
              ],
              [
                -73.88028914295056,
                40.56867857334762
              ],
              [
                -73.88030893446707,
                40.568678540194504
              ],
              [
                -73.88032117991806,
                40.56867863420526
              ],
              [
                -73.88032884427633,
                40.56867869596737
              ],
              [
                -73.88034903808413,
                40.56867839367099
              ],
              [
                -73.88036700049197,
                40.56867786288211
              ],
              [
                -73.88038118428805,
                40.568677076190674
              ],
              [
                -73.88040176869173,
                40.56867567412385
              ],
              [
                -73.88041688627574,
                40.56867470009681
              ],
              [
                -73.88043366851842,
                40.56867392431299
              ],
              [
                -73.8804558326657,
                40.56867437994323
              ],
              [
                -73.88047102798268,
                40.56867588124252
              ],
              [
                -73.88049261225291,
                40.568677632942695
              ],
              [
                -73.88051295386188,
                40.5686804275594
              ],
              [
                -73.88053267017402,
                40.56868264555472
              ],
              [
                -73.88054326066376,
                40.56868399814716
              ],
              [
                -73.88055347316788,
                40.56868529656853
              ],
              [
                -73.88057433278905,
                40.56868908365677
              ],
              [
                -73.88059214722345,
                40.568692063767905
              ],
              [
                -73.88061442403837,
                40.56869549163038
              ],
              [
                -73.880636017406,
                40.56869867385001
              ],
              [
                -73.8806537174327,
                40.568699538769174
              ],
              [
                -73.88066886553068,
                40.56870054594794
              ],
              [
                -73.88068567967169,
                40.56870171568373
              ],
              [
                -73.88070327264789,
                40.568703354471666
              ],
              [
                -73.88071911660474,
                40.56870553272955
              ],
              [
                -73.88073904289372,
                40.56870950645204
              ],
              [
                -73.88076279486117,
                40.56871370930234
              ],
              [
                -73.88078794238275,
                40.56871675194455
              ],
              [
                -73.88080640764103,
                40.56871894229231
              ],
              [
                -73.88080802486357,
                40.5687191327765
              ],
              [
                -73.88083009243813,
                40.5687212719085
              ],
              [
                -73.8808512493675,
                40.568723679647164
              ],
              [
                -73.88087474315613,
                40.56872655972642
              ],
              [
                -73.8808963389228,
                40.56872877860321
              ],
              [
                -73.88091670520443,
                40.56873069079461
              ],
              [
                -73.8809338020415,
                40.56873238398265
              ],
              [
                -73.88095258591643,
                40.56873429402477
              ],
              [
                -73.88097532354678,
                40.56873766734514
              ],
              [
                -73.88100249970928,
                40.56874242369414
              ],
              [
                -73.88101724521775,
                40.56874498726414
              ],
              [
                -73.88103254369172,
                40.56874766884313
              ],
              [
                -73.881047513156,
                40.56875067458472
              ],
              [
                -73.88106053367518,
                40.56875299260695
              ],
              [
                -73.8810843924999,
                40.56875741228491
              ],
              [
                -73.88110252501521,
                40.56876006060211
              ],
              [
                -73.88111785003272,
                40.5687622739516
              ],
              [
                -73.88113397769796,
                40.56876404618598
              ],
              [
                -73.8811485478543,
                40.568765403510795
              ],
              [
                -73.88117165492478,
                40.5687671017091
              ],
              [
                -73.88118565632855,
                40.568768819132416
              ],
              [
                -73.88119962399897,
                40.56877048156943
              ],
              [
                -73.8812198791844,
                40.56877558101358
              ],
              [
                -73.8812440990778,
                40.56877574158702
              ],
              [
                -73.88124592849768,
                40.568775698412836
              ],
              [
                -73.88126673733879,
                40.56877521549129
              ],
              [
                -73.8812899899062,
                40.568774779989496
              ],
              [
                -73.88131201515033,
                40.56877405506358
              ],
              [
                -73.88133487873345,
                40.56877295389002
              ],
              [
                -73.88135827609673,
                40.56877089750583
              ],
              [
                -73.88138189843421,
                40.56876833792218
              ],
              [
                -73.88140223669862,
                40.56876649465099
              ],
              [
                -73.88142484385884,
                40.56876393233508
              ],
              [
                -73.88144694240087,
                40.56876151557572
              ],
              [
                -73.8814673627757,
                40.56875969985271
              ],
              [
                -73.88148845626256,
                40.56875826192994
              ],
              [
                -73.88150835802925,
                40.56875652641387
              ],
              [
                -73.88152923752182,
                40.56875559319765
              ],
              [
                -73.88155040199122,
                40.568754264566195
              ],
              [
                -73.88157319628651,
                40.568752090580524
              ],
              [
                -73.88159429211794,
                40.56874969906125
              ],
              [
                -73.88161264711533,
                40.56874706213031
              ],
              [
                -73.88163393421279,
                40.56874310355985
              ],
              [
                -73.88165489126098,
                40.56873856767288
              ],
              [
                -73.88167033049703,
                40.56873534254707
              ],
              [
                -73.88168919765202,
                40.56873130072423
              ],
              [
                -73.88170633904176,
                40.568726950876545
              ],
              [
                -73.88172553821391,
                40.56872199920032
              ],
              [
                -73.88173357116696,
                40.568720350769105
              ],
              [
                -73.88174004464445,
                40.568719024064336
              ],
              [
                -73.8817545281002,
                40.56871599596354
              ],
              [
                -73.88177058314974,
                40.568712004506885
              ],
              [
                -73.88178841023914,
                40.568707494524205
              ],
              [
                -73.88180828198186,
                40.56870284876547
              ],
              [
                -73.88182559907011,
                40.56869926485947
              ],
              [
                -73.8818434971748,
                40.56869484322943
              ],
              [
                -73.88187437767158,
                40.5686887162597
              ],
              [
                -73.88189261684596,
                40.56868509643526
              ],
              [
                -73.88190727884263,
                40.56870769636656
              ],
              [
                -73.88192260012147,
                40.56873129712619
              ],
              [
                -73.88192258910135,
                40.568731441525536
              ],
              [
                -73.88197021885632,
                40.568715109414015
              ],
              [
                -73.8820178499041,
                40.56869877845679
              ],
              [
                -73.88206955861435,
                40.568680298070845
              ],
              [
                -73.88212127827947,
                40.5686618181755
              ],
              [
                -73.88209692581147,
                40.56862950114764
              ],
              [
                -73.88207621474676,
                40.56860200433399
              ],
              [
                -73.88208660430972,
                40.5685980904468
              ],
              [
                -73.88209772151953,
                40.56859390339429
              ],
              [
                -73.88210953872509,
                40.568589052803596
              ],
              [
                -73.88213139393241,
                40.56858430314577
              ],
              [
                -73.88214925446407,
                40.56858072795746
              ],
              [
                -73.88216455412913,
                40.568577258866284
              ],
              [
                -73.88218129409157,
                40.56857315111918
              ],
              [
                -73.88219685896783,
                40.568566413789725
              ],
              [
                -73.88220943230279,
                40.568561077625255
              ],
              [
                -73.88222246686078,
                40.568555435017636
              ],
              [
                -73.88224221751369,
                40.5685459099225
              ],
              [
                -73.8822549694179,
                40.56853971802641
              ],
              [
                -73.8822686225277,
                40.56853237545289
              ],
              [
                -73.88228637429398,
                40.568523937412515
              ],
              [
                -73.88229819339269,
                40.56851799769324
              ],
              [
                -73.8823125284216,
                40.56851139244162
              ],
              [
                -73.88232964100602,
                40.56850413398696
              ],
              [
                -73.8823432548687,
                40.568498610067664
              ],
              [
                -73.88235766000328,
                40.5684926093297
              ],
              [
                -73.88237283472289,
                40.5684860960667
              ],
              [
                -73.88239008898215,
                40.56847859399229
              ],
              [
                -73.8824097890766,
                40.56846993343766
              ],
              [
                -73.88243093138236,
                40.568461039981706
              ],
              [
                -73.8824508571579,
                40.568451838362336
              ],
              [
                -73.88247156263513,
                40.56844211601707
              ],
              [
                -73.88247982463253,
                40.56843799134281
              ],
              [
                -73.88248871352695,
                40.5684335423016
              ],
              [
                -73.88250322795516,
                40.568425840392244
              ],
              [
                -73.88251696222004,
                40.56841866741233
              ],
              [
                -73.88252920720879,
                40.56841250260975
              ],
              [
                -73.88254516419559,
                40.56840411697355
              ],
              [
                -73.88256242076477,
                40.56839477807907
              ],
              [
                -73.88257939340104,
                40.568386366671646
              ],
              [
                -73.88259408211263,
                40.56838086191465
              ],
              [
                -73.88261213622364,
                40.568374846278374
              ],
              [
                -73.88262970640685,
                40.56836892982512
              ],
              [
                -73.882647356467,
                40.56836456276622
              ],
              [
                -73.882662866301,
                40.568361966653576
              ],
              [
                -73.882678706603,
                40.56835946150992
              ],
              [
                -73.88269533764742,
                40.56835702954451
              ],
              [
                -73.88271489474496,
                40.56835557223702
              ],
              [
                -73.88273126157868,
                40.568355975940776
              ],
              [
                -73.88274616121862,
                40.56835782811491
              ],
              [
                -73.88276408427694,
                40.56835962993068
              ],
              [
                -73.88278550605777,
                40.56835918404877
              ],
              [
                -73.88279997403697,
                40.56835801856397
              ],
              [
                -73.88282147024864,
                40.56835584350895
              ],
              [
                -73.88283837926991,
                40.56835624724784
              ],
              [
                -73.88285619540405,
                40.568358264718604
              ],
              [
                -73.88287307747646,
                40.5683605068968
              ],
              [
                -73.88288811811931,
                40.56836313418894
              ],
              [
                -73.88290250762795,
                40.568366002895935
              ],
              [
                -73.88292327300584,
                40.568370057844305
              ],
              [
                -73.88293863229666,
                40.56837237854011
              ],
              [
                -73.88295399278806,
                40.56837376391624
              ],
              [
                -73.88296051092841,
                40.5683742470124
              ],
              [
                -73.88297087726367,
                40.56837501464125
              ],
              [
                -73.88298955629156,
                40.56837668166048
              ],
              [
                -73.8829928724059,
                40.56837734209021
              ],
              [
                -73.88300768867661,
                40.56838026616839
              ],
              [
                -73.88302891606186,
                40.568382818491074
              ],
              [
                -73.88305202325523,
                40.568384021260954
              ],
              [
                -73.88307333791968,
                40.568384781765765
              ],
              [
                -73.88309129854181,
                40.56838521351029
              ],
              [
                -73.88311091520163,
                40.568383865928006
              ],
              [
                -73.88311385647962,
                40.56838317501263
              ],
              [
                -73.88313094774313,
                40.56838062601412
              ],
              [
                -73.88336927316276,
                40.56833185924825
              ],
              [
                -73.88346534749647,
                40.568478019938446
              ],
              [
                -73.88368728096324,
                40.56884340483232
              ],
              [
                -73.88390427884407,
                40.56890620425921
              ],
              [
                -73.88390845170261,
                40.568895911091715
              ],
              [
                -73.88369768110292,
                40.56883628546312
              ],
              [
                -73.88347850081561,
                40.56847311410495
              ],
              [
                -73.8836919497781,
                40.568395359722665
              ],
              [
                -73.88357670672721,
                40.56820976975422
              ],
              [
                -73.8837694847298,
                40.56814752545786
              ],
              [
                -73.8837601597834,
                40.568133851287925
              ],
              [
                -73.88378086756106,
                40.56812718595989
              ],
              [
                -73.8837965106266,
                40.568155920075945
              ],
              [
                -73.88378819346467,
                40.56815987256843
              ],
              [
                -73.88399326079195,
                40.56851179890226
              ],
              [
                -73.88402340092412,
                40.56850311561082
              ],
              [
                -73.88381937098082,
                40.568151982460606
              ],
              [
                -73.88380690303865,
                40.56815355417869
              ],
              [
                -73.88379103703075,
                40.5681239113245
              ],
              [
                -73.88397412531297,
                40.56806497313348
              ],
              [
                -73.88398465406,
                40.56808402453268
              ],
              [
                -73.88397010168924,
                40.56808955490236
              ],
              [
                -73.88403638323946,
                40.568205277024056
              ],
              [
                -73.88407171935152,
                40.568195014133465
              ],
              [
                -73.884007526758,
                40.56807295749514
              ],
              [
                -73.88399610047864,
                40.56807294595646
              ],
              [
                -73.8839890745021,
                40.5680601606942
              ],
              [
                -73.88414806727037,
                40.56800897730124
              ],
              [
                -73.88415611937222,
                40.56802242566622
              ],
              [
                -73.8841423383738,
                40.56802768627495
              ],
              [
                -73.88422623813494,
                40.56816646039564
              ],
              [
                -73.8842560545,
                40.56815713340232
              ],
              [
                -73.88425563707202,
                40.56814510250077
              ],
              [
                -73.88418572568219,
                40.56802639263764
              ],
              [
                -73.88418090236974,
                40.56802772516466
              ],
              [
                -73.88416646626057,
                40.568001951564035
              ],
              [
                -73.88445665776179,
                40.56789576340595
              ],
              [
                -73.88471750208527,
                40.568245349537776
              ],
              [
                -73.88473689799127,
                40.56826851324062
              ],
              [
                -73.88459734214324,
                40.56832600252621
              ],
              [
                -73.88458722633577,
                40.56831945752904
              ],
              [
                -73.8842987608294,
                40.56843680205307
              ],
              [
                -73.88433684774819,
                40.568487934478
              ],
              [
                -73.88462764522968,
                40.568372968610774
              ],
              [
                -73.88467738972528,
                40.56844134290076
              ],
              [
                -73.88470791077437,
                40.568429673593954
              ],
              [
                -73.88463979353489,
                40.56833718522714
              ],
              [
                -73.88475594832832,
                40.56828913918743
              ],
              [
                -73.8848870712472,
                40.56847020566866
              ],
              [
                -73.88430466693922,
                40.56866357194759
              ],
              [
                -73.88430973105297,
                40.56869611953866
              ],
              [
                -73.88489678220193,
                40.568499584876896
              ],
              [
                -73.88500002540947,
                40.56852173302438
              ],
              [
                -73.88502840887722,
                40.56853112718865
              ],
              [
                -73.88506218994054,
                40.568527063551656
              ],
              [
                -73.88514676298279,
                40.56854903138554
              ],
              [
                -73.88528929704948,
                40.56856911102693
              ],
              [
                -73.88534183019684,
                40.56859934865464
              ],
              [
                -73.88542104730786,
                40.568590909480136
              ],
              [
                -73.88552537443559,
                40.568579792687395
              ],
              [
                -73.88572202722436,
                40.56852446159178
              ],
              [
                -73.88592976749554,
                40.56848445171615
              ],
              [
                -73.88601332217256,
                40.56848022914433
              ],
              [
                -73.88629569651762,
                40.568465965910924
              ],
              [
                -73.88644241994884,
                40.56844599324057
              ],
              [
                -73.88654683936447,
                40.56847097810943
              ],
              [
                -73.88686105741907,
                40.56838329770443
              ],
              [
                -73.88687638253386,
                40.568349839847144
              ],
              [
                -73.88747378409191,
                40.56813023872383
              ],
              [
                -73.88786222508217,
                40.56803804498643
              ],
              [
                -73.88796642336445,
                40.56801876782179
              ],
              [
                -73.88805660983309,
                40.56801691893561
              ],
              [
                -73.88818867528397,
                40.568033518084455
              ],
              [
                -73.88834097846592,
                40.568066022598536
              ],
              [
                -73.88834311927033,
                40.568077740036244
              ],
              [
                -73.88834377442494,
                40.56809571544478
              ],
              [
                -73.88834467829327,
                40.56812050788143
              ],
              [
                -73.88834735692743,
                40.568135621167684
              ],
              [
                -73.88835950704319,
                40.568143674741066
              ],
              [
                -73.88836803030965,
                40.568144925425194
              ],
              [
                -73.88838304501148,
                40.5681412206875
              ],
              [
                -73.8883907733278,
                40.56812453318512
              ],
              [
                -73.88839431564136,
                40.56810343675498
              ],
              [
                -73.88839935499476,
                40.5680908259892
              ],
              [
                -73.88840180766067,
                40.56807906694457
              ],
              [
                -73.88866128999209,
                40.56796810394103
              ],
              [
                -73.88879000780409,
                40.567931334052886
              ],
              [
                -73.88891124690583,
                40.56792563456584
              ],
              [
                -73.88911103072223,
                40.56791623669089
              ],
              [
                -73.88940823061598,
                40.56792621296568
              ],
              [
                -73.88944124174323,
                40.56793689729114
              ],
              [
                -73.88959492449194,
                40.567941890234074
              ],
              [
                -73.88962915033767,
                40.56798259952053
              ],
              [
                -73.88993395087572,
                40.5680729541433
              ],
              [
                -73.8900201856573,
                40.56809098386937
              ],
              [
                -73.89006074216768,
                40.568145261794754
              ],
              [
                -73.89008675199516,
                40.56816950194033
              ],
              [
                -73.8901649592118,
                40.568228524775265
              ],
              [
                -73.89028537165312,
                40.568172375144435
              ],
              [
                -73.89050235300425,
                40.568183235403126
              ],
              [
                -73.89075729057016,
                40.56822104785993
              ],
              [
                -73.8910600526066,
                40.56830412977552
              ],
              [
                -73.89120836350693,
                40.56834482979348
              ],
              [
                -73.89146452569115,
                40.56841512401611
              ],
              [
                -73.89173272278907,
                40.56845497427873
              ],
              [
                -73.89184594142127,
                40.56847298969817
              ],
              [
                -73.89194768419188,
                40.568489179864414
              ],
              [
                -73.89214468849696,
                40.568505609866776
              ],
              [
                -73.89228381010733,
                40.56851183832509
              ],
              [
                -73.89235367291086,
                40.56852611369008
              ],
              [
                -73.89249413164768,
                40.56853081091947
              ],
              [
                -73.8925692981124,
                40.56855880577258
              ],
              [
                -73.89261013445609,
                40.56858724728668
              ],
              [
                -73.89280581743805,
                40.56851491061991
              ],
              [
                -73.89314119161189,
                40.568395398445716
              ],
              [
                -73.89326255071325,
                40.568333312338595
              ],
              [
                -73.89345467847606,
                40.56823501855795
              ],
              [
                -73.8936594633678,
                40.56810371337828
              ],
              [
                -73.89396328755053,
                40.567928148141846
              ],
              [
                -73.89413028093446,
                40.567820149652846
              ],
              [
                -73.89436419907221,
                40.56764996730551
              ],
              [
                -73.89438257495341,
                40.56764180741037
              ],
              [
                -73.8945970952024,
                40.56748211622697
              ],
              [
                -73.89479571968951,
                40.567368310224516
              ],
              [
                -73.89485403769469,
                40.567327029002755
              ],
              [
                -73.89504632135142,
                40.567190914696674
              ],
              [
                -73.89522927220416,
                40.567074772663624
              ],
              [
                -73.89531210417964,
                40.56702218855294
              ],
              [
                -73.89538025688381,
                40.566997645046385
              ],
              [
                -73.89543399793207,
                40.56701956515991
              ],
              [
                -73.89545231761564,
                40.567031818712245
              ],
              [
                -73.89549129592831,
                40.5670578919942
              ],
              [
                -73.8955701567844,
                40.5670634313859
              ],
              [
                -73.89565980661514,
                40.56705946098028
              ],
              [
                -73.89565586855373,
                40.5670108204956
              ],
              [
                -73.89564653277833,
                40.56693948102174
              ],
              [
                -73.89561861973425,
                40.566877634494226
              ],
              [
                -73.89557697279913,
                40.566815433211566
              ],
              [
                -73.89542366693381,
                40.56658650687264
              ],
              [
                -73.89539935314917,
                40.566487840856276
              ],
              [
                -73.89536175546587,
                40.56640510268941
              ],
              [
                -73.89533259419743,
                40.56634090417396
              ],
              [
                -73.8953372502706,
                40.56633210037087
              ],
              [
                -73.89543332392897,
                40.56615043598428
              ],
              [
                -73.89566630776342,
                40.56583063730831
              ],
              [
                -73.89581663166554,
                40.565624318585925
              ],
              [
                -73.89613262445299,
                40.56535842756679
              ],
              [
                -73.89614780767694,
                40.56533720689433
              ],
              [
                -73.89621164762941,
                40.56528127932281
              ],
              [
                -73.89628126208979,
                40.56523272170739
              ],
              [
                -73.89659163481625,
                40.5650400015274
              ],
              [
                -73.89704215194091,
                40.56478184744614
              ],
              [
                -73.89706323189466,
                40.564780172780644
              ],
              [
                -73.89712237369706,
                40.56475319215313
              ],
              [
                -73.89718037015771,
                40.56472672378137
              ],
              [
                -73.89726738376439,
                40.56467302206039
              ],
              [
                -73.89791693883869,
                40.56437378901525
              ],
              [
                -73.89796716368488,
                40.56437825559053
              ],
              [
                -73.89801645708715,
                40.56435914551782
              ],
              [
                -73.89832919744433,
                40.56417158181876
              ],
              [
                -73.89863709831356,
                40.56401347797091
              ],
              [
                -73.89905579734292,
                40.56381294864294
              ],
              [
                -73.89964523066722,
                40.56356206489001
              ],
              [
                -73.90006663126383,
                40.563382687386735
              ],
              [
                -73.90045365151107,
                40.56323897596529
              ],
              [
                -73.90050925255056,
                40.56322469732533
              ],
              [
                -73.90067042372952,
                40.56316335028137
              ],
              [
                -73.9008354220431,
                40.5631082377446
              ],
              [
                -73.90096031793567,
                40.563071389888286
              ],
              [
                -73.90108692673103,
                40.56303811545824
              ],
              [
                -73.90121507272005,
                40.56300846063235
              ],
              [
                -73.90136239001558,
                40.562979197063
              ],
              [
                -73.90151119979181,
                40.562954716725734
              ],
              [
                -73.90166123516516,
                40.56293506352472
              ],
              [
                -73.90175758157231,
                40.56292410693087
              ],
              [
                -73.90184842268629,
                40.56292019311837
              ],
              [
                -73.9019393619705,
                40.562922415360454
              ],
              [
                -73.90202968822216,
                40.56293075627778
              ],
              [
                -73.902118695032,
                40.562945150638875
              ],
              [
                -73.90220568630893,
                40.56296548587068
              ],
              [
                -73.9022899817226,
                40.56299160293853
              ],
              [
                -73.90237092202433,
                40.56302329758998
              ],
              [
                -73.90244787420198,
                40.5630603219515
              ],
              [
                -73.90252023643049,
                40.563102386467705
              ],
              [
                -73.90258744277786,
                40.56314916216487
              ],
              [
                -73.90264896763173,
                40.563200283224006
              ],
              [
                -73.90270432981025,
                40.56325534984151
              ],
              [
                -73.9027530963235,
                40.56331393135544
              ],
              [
                -73.90279436846555,
                40.56337471712267
              ],
              [
                -73.90282037128124,
                40.56342998660262
              ],
              [
                -73.90282692346567,
                40.56344135886544
              ],
              [
                -73.90283595660054,
                40.56345169516812
              ],
              [
                -73.9028471982753,
                40.563460683799356
              ],
              [
                -73.90286030947519,
                40.56346805368899
              ],
              [
                -73.90287489480674,
                40.56347358258343
              ],
              [
                -73.90289051442042,
                40.56347710374773
              ],
              [
                -73.90290669727533,
                40.56347851099414
              ],
              [
                -73.90292295534515,
                40.56347776188437
              ],
              [
                -73.90293879833516,
                40.5634748790093
              ],
              [
                -73.90295374846806,
                40.56346994930782
              ],
              [
                -73.90296735489308,
                40.563463121444855
              ],
              [
                -73.90297920728172,
                40.563454601328395
              ],
              [
                -73.90298894820238,
                40.56344464589919
              ],
              [
                -73.90310623198005,
                40.563389272260586
              ],
              [
                -73.9036677346663,
                40.56316093955492
              ],
              [
                -73.90391390139247,
                40.563082962101404
              ],
              [
                -73.90422374876525,
                40.56299515867549
              ],
              [
                -73.9046569315492,
                40.56290588894744
              ],
              [
                -73.90512293458974,
                40.56282800171444
              ],
              [
                -73.90549533617256,
                40.56276575778522
              ],
              [
                -73.90567017101444,
                40.56276096691355
              ],
              [
                -73.90581767638682,
                40.562747865453304
              ],
              [
                -73.90596597524814,
                40.56274193881232
              ],
              [
                -73.90611446820678,
                40.56274321094495
              ],
              [
                -73.90626255508623,
                40.56275167670948
              ],
              [
                -73.90640963735129,
                40.56276730188912
              ],
              [
                -73.90655512052625,
                40.56279002333005
              ],
              [
                -73.90669841659687,
                40.56281974919719
              ],
              [
                -73.90683894638656,
                40.56285635934457
              ],
              [
                -73.90697614189747,
                40.56289970580128
              ],
              [
                -73.9071094486046,
                40.56294961336929
              ],
              [
                -73.90723832769757,
                40.563005880331275
              ],
              [
                -73.9073622582579,
                40.56306827926591
              ],
              [
                -73.90748073936408,
                40.56313655796707
              ],
              [
                -73.90759329211627,
                40.5632104404623
              ],
              [
                -73.90769946157138,
                40.56328962812894
              ],
              [
                -73.9077988185825,
                40.56337380090028
              ],
              [
                -73.90789096153294,
                40.56346261855918
              ],
              [
                -73.90797551796078,
                40.56355572211305
              ],
              [
                -73.9079960233449,
                40.56361465720217
              ],
              [
                -73.9080096626694,
                40.56367473797644
              ],
              [
                -73.90801633051083,
                40.56373550013356
              ],
              [
                -73.90801597532175,
                40.563796474103945
              ],
              [
                -73.90800859982761,
                40.56385718868024
              ],
              [
                -73.90799426100763,
                40.563917174658165
              ],
              [
                -73.90797306965376,
                40.56397596846283
              ],
              [
                -73.907951975564,
                40.56400805646608
              ],
              [
                -73.90791411765858,
                40.56406893727102
              ],
              [
                -73.90792916599447,
                40.56411667823529
              ],
              [
                -73.9079482148663,
                40.564158323984344
              ],
              [
                -73.90797175520909,
                40.56416153010164
              ],
              [
                -73.90799528456877,
                40.564164737378164
              ],
              [
                -73.90807304358626,
                40.56408533741217
              ],
              [
                -73.9080904469524,
                40.56402656413226
              ],
              [
                -73.90821195105768,
                40.56399332454081
              ],
              [
                -73.90838328120986,
                40.56396272597695
              ],
              [
                -73.90851583574546,
                40.563939048431344
              ],
              [
                -73.9087254513683,
                40.56393129153756
              ],
              [
                -73.9089211721263,
                40.563947304767474
              ],
              [
                -73.90904934105347,
                40.56396127528049
              ],
              [
                -73.90918356455255,
                40.563977896402974
              ],
              [
                -73.9092770814906,
                40.56399712415304
              ],
              [
                -73.90939134156689,
                40.56404609551438
              ],
              [
                -73.90953155346381,
                40.564122832314986
              ],
              [
                -73.90965355123693,
                40.564212754457415
              ],
              [
                -73.90966823860984,
                40.56424051095109
              ],
              [
                -73.90969073362554,
                40.564257701782516
              ],
              [
                -73.90976258436969,
                40.564284836928145
              ],
              [
                -73.90981103571444,
                40.56432715547763
              ],
              [
                -73.90987653834947,
                40.56442096895194
              ],
              [
                -73.9098755490021,
                40.564453409409445
              ],
              [
                -73.90987714566829,
                40.56448583578527
              ],
              [
                -73.90997230349046,
                40.56474460368657
              ],
              [
                -73.91002307642118,
                40.56478020953846
              ],
              [
                -73.90996837315812,
                40.56487069925949
              ],
              [
                -73.90995051522059,
                40.56489994433498
              ],
              [
                -73.90974823471095,
                40.5652695117089
              ],
              [
                -73.909730321818,
                40.56526351832803
              ],
              [
                -73.90971684925964,
                40.56528742546118
              ],
              [
                -73.90977170611873,
                40.56530540530752
              ],
              [
                -73.90978518025257,
                40.56528064510756
              ],
              [
                -73.90976838764617,
                40.565275507349334
              ],
              [
                -73.90998387183201,
                40.56487988232135
              ],
              [
                -73.91000297018701,
                40.56484481755809
              ],
              [
                -73.91003762905791,
                40.56479371060192
              ],
              [
                -73.91009922829879,
                40.56482442599317
              ],
              [
                -73.91009279361162,
                40.56486781803594
              ],
              [
                -73.91007760883511,
                40.56491967587889
              ],
              [
                -73.91008149893018,
                40.56494246247026
              ],
              [
                -73.91010084549161,
                40.56495620165247
              ],
              [
                -73.91011762673844,
                40.56495586347528
              ],
              [
                -73.9101477299426,
                40.5649431450485
              ],
              [
                -73.91017496486596,
                40.564922723529975
              ],
              [
                -73.91031901407925,
                40.564965647483746
              ],
              [
                -73.91030675154266,
                40.56500493637165
              ],
              [
                -73.91033193473851,
                40.56501687075075
              ],
              [
                -73.91056088690934,
                40.565007350118805
              ],
              [
                -73.910798267494,
                40.56504309759577
              ],
              [
                -73.91079817683057,
                40.5651109958315
              ],
              [
                -73.91107382373276,
                40.56505300922294
              ],
              [
                -73.91117000937783,
                40.5650585139588
              ],
              [
                -73.91133796691499,
                40.56506812591158
              ],
              [
                -73.91148922796503,
                40.565114763239244
              ],
              [
                -73.91185809029383,
                40.56499543800322
              ],
              [
                -73.91187518743409,
                40.56504805597028
              ],
              [
                -73.91189239498307,
                40.56510159409272
              ],
              [
                -73.91189556223307,
                40.56511145028776
              ],
              [
                -73.91191299706968,
                40.56547960930239
              ],
              [
                -73.91192497868256,
                40.56573672188277
              ],
              [
                -73.91192551909776,
                40.56581386237723
              ],
              [
                -73.91173511047309,
                40.565813716219964
              ],
              [
                -73.9117361534006,
                40.56587265348572
              ],
              [
                -73.91197024752503,
                40.56586941753247
              ],
              [
                -73.91216181893262,
                40.56583625156802
              ],
              [
                -73.91214509452946,
                40.56577900893883
              ],
              [
                -73.9119815270415,
                40.56580963497536
              ],
              [
                -73.91195464366191,
                40.56581132267282
              ],
              [
                -73.91195522606857,
                40.565731620504174
              ],
              [
                -73.91194211877024,
                40.56547963163161
              ],
              [
                -73.9119269207045,
                40.56511232806833
              ],
              [
                -73.91192112783689,
                40.56509617526494
              ],
              [
                -73.91190258835852,
                40.56504447962287
              ],
              [
                -73.91188319663009,
                40.564990405238674
              ],
              [
                -73.91215942953983,
                40.56497148274082
              ],
              [
                -73.91220200769317,
                40.5650545698499
              ],
              [
                -73.9131318012801,
                40.56463824394596
              ],
              [
                -73.91324388491564,
                40.56465615834855
              ],
              [
                -73.913867496264,
                40.56460809614183
              ],
              [
                -73.91455336961286,
                40.56435343243379
              ],
              [
                -73.91483386530379,
                40.56424205554108
              ],
              [
                -73.91519131236099,
                40.56409066671078
              ],
              [
                -73.9155244036197,
                40.56390384306317
              ],
              [
                -73.91567986551492,
                40.56381664714167
              ],
              [
                -73.91610489437377,
                40.5635585818892
              ],
              [
                -73.91649706637092,
                40.56333720248186
              ],
              [
                -73.91680098049571,
                40.563185496799974
              ],
              [
                -73.91722576958848,
                40.56298655266716
              ],
              [
                -73.91766081900091,
                40.56282614968641
              ],
              [
                -73.91909761878105,
                40.562470161207905
              ],
              [
                -73.91962397269192,
                40.5623658432254
              ],
              [
                -73.92017877193038,
                40.56225588612692
              ],
              [
                -73.92033120142663,
                40.562226054886615
              ],
              [
                -73.92017573888782,
                40.562522547045205
              ],
              [
                -73.92016901882614,
                40.56252254237471
              ],
              [
                -73.92014433287757,
                40.56256181714762
              ],
              [
                -73.92009840148403,
                40.56257118060999
              ],
              [
                -73.9201173587936,
                40.56263952634708
              ],
              [
                -73.9202596317294,
                40.56261229356109
              ],
              [
                -73.92024067296587,
                40.56254394617108
              ],
              [
                -73.92019698482662,
                40.562550749365116
              ],
              [
                -73.92020596714332,
                40.56253281768893
              ],
              [
                -73.92020148738833,
                40.56253196151499
              ],
              [
                -73.92036810280446,
                40.5622188324676
              ],
              [
                -73.92107822881388,
                40.56207985288483
              ],
              [
                -73.92208248861198,
                40.562074447330865
              ],
              [
                -73.92278702954434,
                40.562067196476264
              ],
              [
                -73.92329484208311,
                40.56209058045154
              ],
              [
                -73.92421461302578,
                40.5620013188248
              ],
              [
                -73.92466271025597,
                40.56192245780033
              ],
              [
                -73.92510052720941,
                40.56182927793012
              ],
              [
                -73.92529896869685,
                40.561807155522494
              ],
              [
                -73.92545002935539,
                40.56176007493656
              ],
              [
                -73.92558721708254,
                40.56172522840918
              ],
              [
                -73.92568426673157,
                40.56170057590932
              ],
              [
                -73.92586523769828,
                40.561651740320634
              ],
              [
                -73.9261572327542,
                40.5615526097231
              ],
              [
                -73.92689138187725,
                40.561046425217704
              ],
              [
                -73.92707319791555,
                40.56091033406396
              ],
              [
                -73.92725985117427,
                40.56077810705852
              ],
              [
                -73.92746324997567,
                40.560642001153326
              ],
              [
                -73.92767177341338,
                40.560510483700384
              ],
              [
                -73.92788524358414,
                40.56038366688606
              ],
              [
                -73.92810347836831,
                40.560261658886496
              ],
              [
                -73.92832629158498,
                40.56014456377512
              ],
              [
                -73.92855349315126,
                40.560032481434305
              ],
              [
                -73.9287848892434,
                40.55992550746975
              ],
              [
                -73.93136701204324,
                40.558176479673165
              ],
              [
                -73.93177580884338,
                40.557899562384875
              ],
              [
                -73.93192118670594,
                40.55781931945503
              ],
              [
                -73.93207132428788,
                40.557744348339995
              ],
              [
                -73.93222589321998,
                40.55767481300277
              ],
              [
                -73.93238455544525,
                40.55761086551811
              ],
              [
                -73.93254696395779,
                40.557552645739236
              ],
              [
                -73.93271276356151,
                40.55750028099287
              ],
              [
                -73.93288159164659,
                40.55745388580028
              ],
              [
                -73.9330530789821,
                40.55741356162711
              ],
              [
                -73.93322685052414,
                40.55737939666182
              ],
              [
                -73.93340252623442,
                40.557351465622375
              ],
              [
                -73.9335797219125,
                40.55732982959334
              ],
              [
                -73.9337580500351,
                40.557314535892274
              ],
              [
                -73.9339371206029,
                40.557305617966065
              ],
              [
                -73.93411654199369,
                40.55730309531788
              ],
              [
                -73.93429592181836,
                40.55730697346468
              ],
              [
                -73.9344748677788,
                40.557317243925105
              ],
              [
                -73.93457374841151,
                40.55731232499572
              ],
              [
                -73.93468341315717,
                40.55733154308854
              ],
              [
                -73.93479457285804,
                40.557344858371856
              ],
              [
                -73.93490668202666,
                40.55735220550456
              ],
              [
                -73.93501919051636,
                40.55735354843219
              ],
              [
                -73.93513154622016,
                40.55734888056493
              ],
              [
                -73.93524319778129,
                40.55733822480906
              ],
              [
                -73.93535359729825,
                40.5573216334549
              ],
              [
                -73.93543377715005,
                40.55730566730419
              ],
              [
                -73.93551277067246,
                40.557286568479
              ],
              [
                -73.93561077696388,
                40.557257967012575
              ],
              [
                -73.9357061256084,
                40.55722454588335
              ],
              [
                -73.93579840968133,
                40.557186447726444
              ],
              [
                -73.93588723533774,
                40.557143835137
              ],
              [
                -73.93614490798154,
                40.55703891777048
              ],
              [
                -73.93639947503256,
                40.55692967968965
              ],
              [
                -73.93665081196751,
                40.556816174335744
              ],
              [
                -73.93689879584473,
                40.556698457237594
              ],
              [
                -73.9371907648797,
                40.55655224167608
              ],
              [
                -73.93747756165946,
                40.55640018828606
              ],
              [
                -73.93775898559808,
                40.55624240343239
              ],
              [
                -73.9380348398729,
                40.55607899748846
              ],
              [
                -73.93830493156165,
                40.55591008475869
              ],
              [
                -73.93848163371267,
                40.55579451747539
              ],
              [
                -73.93865563765661,
                40.55567659276616
              ],
              [
                -73.93882966460264,
                40.555553629107
              ],
              [
                -73.93899716294715,
                40.555425499728166
              ],
              [
                -73.93915786934248,
                40.55529240610875
              ],
              [
                -73.93931153112626,
                40.55515455753253
              ],
              [
                -73.93945790671738,
                40.555012170759014
              ],
              [
                -73.93959676599718,
                40.55486546968239
              ],
              [
                -73.93972789066935,
                40.55471468497929
              ],
              [
                -73.93985107460422,
                40.55456005374592
              ],
              [
                -73.93996612416265,
                40.55440181912531
              ],
              [
                -73.9400728584997,
                40.554240229924666
              ],
              [
                -73.94017110984856,
                40.55407554022406
              ],
              [
                -73.94026072378509,
                40.553908008976975
              ],
              [
                -73.94034155946935,
                40.55373789960272
              ],
              [
                -73.9404134898667,
                40.55356547957263
              ],
              [
                -73.940476401948,
                40.55339101998872
              ],
              [
                -73.94053019686582,
                40.55321479515808
              ],
              [
                -73.94057479011045,
                40.55303708216103
              ],
              [
                -73.94061011164119,
                40.55285816041558
              ],
              [
                -73.94063610599748,
                40.552678311238054
              ],
              [
                -73.94065273238446,
                40.55249781740059
              ],
              [
                -73.94065996473707,
                40.552316962686646
              ],
              [
                -73.94065779176053,
                40.55213603144504
              ],
              [
                -73.94064621694747,
                40.551955308142034
              ],
              [
                -73.94062525857242,
                40.55177507691533
              ],
              [
                -73.94059494966203,
                40.551595621126374
              ],
              [
                -73.94055533794308,
                40.55141722291513
              ],
              [
                -73.94034460376172,
                40.550466377848224
              ],
              [
                -73.94012808934745,
                40.549516289012324
              ],
              [
                -73.94012975795786,
                40.54800256129353
              ],
              [
                -73.94073681682696,
                40.54182008707755
              ],
              [
                -73.94055905861713,
                40.5418467769598
              ],
              [
                -73.9404740385729,
                40.542850072149186
              ],
              [
                -73.94026976488325,
                40.542876412588114
              ],
              [
                -73.9400670034048,
                40.54290884811146
              ],
              [
                -73.93986606648828,
                40.54294732875307
              ],
              [
                -73.93966726367522,
                40.54299179523458
              ],
              [
                -73.93947090122087,
                40.54304217905631
              ],
              [
                -73.93927728162387,
                40.543098402603
              ],
              [
                -73.93908670315919,
                40.543160379263085
              ],
              [
                -73.93889945941925,
                40.543228013562256
              ],
              [
                -73.93871583886235,
                40.543301201310186
              ],
              [
                -73.93853612436767,
                40.54337982976141
              ],
              [
                -73.93836059279961,
                40.54346377778862
              ],
              [
                -73.93818951458262,
                40.54355291606898
              ],
              [
                -73.93802315328331,
                40.54364710728359
              ],
              [
                -73.93771683410068,
                40.543803973888956
              ],
              [
                -73.93740596868945,
                40.54395554721121
              ],
              [
                -73.93709071418934,
                40.54410175061698
              ],
              [
                -73.93677122996246,
                40.54424251018703
              ],
              [
                -73.9364476775119,
                40.5443777547543
              ],
              [
                -73.93612022040107,
                40.54450741593956
              ],
              [
                -73.93578902417048,
                40.544631428186165
              ],
              [
                -73.9354542562537,
                40.54474972879316
              ],
              [
                -73.93511608589348,
                40.54486225794714
              ],
              [
                -73.9347746840552,
                40.5449689587524
              ],
              [
                -73.93443022334034,
                40.54506977725986
              ],
              [
                -73.93408287789984,
                40.545164662494585
              ],
              [
                -73.93256825181622,
                40.54568875406017
              ],
              [
                -73.93054682243256,
                40.546421980133985
              ],
              [
                -73.92887232480227,
                40.547093874203526
              ],
              [
                -73.92774080155928,
                40.54762805297529
              ],
              [
                -73.9264190939679,
                40.54815578031995
              ],
              [
                -73.91948122277842,
                40.55062481534694
              ],
              [
                -73.91866643765113,
                40.55100383757693
              ],
              [
                -73.91780653110628,
                40.551279295095256
              ],
              [
                -73.91701439187267,
                40.55162381230809
              ],
              [
                -73.91625684813305,
                40.551971065616094
              ],
              [
                -73.91173957602177,
                40.55370824775286
              ],
              [
                -73.90879730557717,
                40.55482950838893
              ],
              [
                -73.90849464059502,
                40.55497378570035
              ],
              [
                -73.90818720744909,
                40.55511207443619
              ],
              [
                -73.90787520976478,
                40.555244282989
              ],
              [
                -73.90755885419611,
                40.555370323778796
              ],
              [
                -73.90723835028673,
                40.555490113310654
              ],
              [
                -73.90691391033238,
                40.55560357223047
              ],
              [
                -73.906585749239,
                40.555710625377294
              ],
              [
                -73.90625408438149,
                40.555811201833535
              ],
              [
                -73.9059191354582,
                40.55590523497158
              ],
              [
                -73.90555306939628,
                40.55602967121492
              ],
              [
                -73.90518384701974,
                40.556148559965976
              ],
              [
                -73.90481161283611,
                40.55626185468859
              ],
              [
                -73.90443651253364,
                40.55636951103589
              ],
              [
                -73.9040586929248,
                40.55647148686814
              ],
              [
                -73.90367830188872,
                40.55656774226869
              ],
              [
                -73.90346067954327,
                40.55661905180018
              ],
              [
                -73.90324056171957,
                40.55666374218826
              ],
              [
                -73.90301829436225,
                40.556701743194935
              ],
              [
                -73.9027942267973,
                40.55673299509557
              ],
              [
                -73.90256871118143,
                40.55675744877272
              ],
              [
                -73.90234210194804,
                40.55677506579338
              ],
              [
                -73.90211475525074,
                40.556785818469656
              ],
              [
                -73.90203544556263,
                40.55678794883918
              ],
              [
                -73.90181646619372,
                40.55678949224021
              ],
              [
                -73.9015975687611,
                40.556784670172675
              ],
              [
                -73.9013790712455,
                40.556773489641394
              ],
              [
                -73.90129932513871,
                40.556768014945426
              ],
              [
                -73.90124279535341,
                40.55676142430721
              ],
              [
                -73.9011186811175,
                40.55674286675047
              ],
              [
                -73.90099617912736,
                40.5567188734147
              ],
              [
                -73.90087569826764,
                40.55668952438501
              ],
              [
                -73.90075764067583,
                40.556654917622154
              ],
              [
                -73.90068163438659,
                40.556627201777715
              ],
              [
                -73.90065887908541,
                40.55660871829087
              ],
              [
                -73.90065660661142,
                40.556598817485494
              ],
              [
                -73.90065294273536,
                40.5565850309605
              ],
              [
                -73.90064734466947,
                40.55657181588196
              ],
              [
                -73.90064338752742,
                40.55655731710083
              ],
              [
                -73.90063905316975,
                40.55653903349257
              ],
              [
                -73.90063922086148,
                40.556520208636705
              ],
              [
                -73.90063752822185,
                40.55650546202725
              ],
              [
                -73.90063179349308,
                40.55648991866656
              ],
              [
                -73.9006253594575,
                40.556476557950084
              ],
              [
                -73.90061827579478,
                40.556462990274376
              ],
              [
                -73.90061192070245,
                40.5564507539187
              ],
              [
                -73.90061118090355,
                40.55644973771218
              ],
              [
                -73.90060403979463,
                40.55643994460217
              ],
              [
                -73.90059328462958,
                40.55643367989425
              ],
              [
                -73.90058060462704,
                40.556432922907696
              ],
              [
                -73.90056883774035,
                40.55643863235962
              ],
              [
                -73.90056044243232,
                40.55644848003805
              ],
              [
                -73.90055529740901,
                40.55645497256075
              ],
              [
                -73.90055243595958,
                40.55645858169286
              ],
              [
                -73.90054673933396,
                40.55646865954759
              ],
              [
                -73.90054602978998,
                40.5564778633615
              ],
              [
                -73.90055216760872,
                40.556492482645645
              ],
              [
                -73.90055881847431,
                40.55650462862696
              ],
              [
                -73.9005620427902,
                40.55651931508015
              ],
              [
                -73.90056656114633,
                40.5565354145958
              ],
              [
                -73.90056916442192,
                40.55654500173832
              ],
              [
                -73.90056822671836,
                40.55656051670906
              ],
              [
                -73.90056628452307,
                40.556572812393014
              ],
              [
                -73.90056532353363,
                40.55658404879725
              ],
              [
                -73.90056171599474,
                40.556601933009674
              ],
              [
                -73.90056152745782,
                40.55660626584008
              ],
              [
                -73.90055342537856,
                40.556612115167844
              ],
              [
                -73.90054055315451,
                40.55661710998231
              ],
              [
                -73.90051999127944,
                40.556623129114065
              ],
              [
                -73.90051229863236,
                40.556626461824145
              ],
              [
                -73.90049893890897,
                40.556632257172254
              ],
              [
                -73.90047944554115,
                40.55664038960705
              ],
              [
                -73.90046042736351,
                40.55664742563202
              ],
              [
                -73.90044816625621,
                40.55665089979424
              ],
              [
                -73.90042800925514,
                40.55665332741929
              ],
              [
                -73.90041463921894,
                40.556654833647784
              ],
              [
                -73.90039911024817,
                40.55665708418077
              ],
              [
                -73.90038436789672,
                40.55665988371928
              ],
              [
                -73.9003679049026,
                40.556663364112524
              ],
              [
                -73.90034986166141,
                40.55666676137778
              ],
              [
                -73.90032064342567,
                40.55667085926856
              ],
              [
                -73.90029725478689,
                40.55667221740272
              ],
              [
                -73.90028590299302,
                40.556672612634706
              ],
              [
                -73.90027092129414,
                40.5566728587951
              ],
              [
                -73.90025486708207,
                40.556673085258694
              ],
              [
                -73.90023732798454,
                40.55667335382035
              ],
              [
                -73.90021788945259,
                40.55667400722068
              ],
              [
                -73.90019780082703,
                40.55667535178384
              ],
              [
                -73.90017786507306,
                40.556676992334125
              ],
              [
                -73.90015874425124,
                40.55667816634849
              ],
              [
                -73.9001399862018,
                40.556680132924114
              ],
              [
                -73.90012215906637,
                40.55668192188676
              ],
              [
                -73.90009143132299,
                40.556686062299214
              ],
              [
                -73.9000681069652,
                40.55668966735389
              ],
              [
                -73.9000445342698,
                40.556693801414646
              ],
              [
                -73.90002112804261,
                40.55669721640961
              ],
              [
                -73.89999989596792,
                40.556698820116615
              ],
              [
                -73.89998081022584,
                40.55669959071997
              ],
              [
                -73.89996296313342,
                40.55670076581086
              ],
              [
                -73.8999443462851,
                40.556703039392254
              ],
              [
                -73.89992717337739,
                40.55670254462434
              ],
              [
                -73.89991054300054,
                40.55670205032669
              ],
              [
                -73.89990525489465,
                40.55670063159811
              ],
              [
                -73.89990511327498,
                40.55670059579079
              ],
              [
                -73.89990465549945,
                40.55670002830305
              ],
              [
                -73.89990114258782,
                40.55669568018572
              ],
              [
                -73.89990042650165,
                40.55668162782292
              ],
              [
                -73.89989600818471,
                40.55666805471938
              ],
              [
                -73.89989330450702,
                40.556656345205184
              ],
              [
                -73.89989007266728,
                40.55664398856507
              ],
              [
                -73.89988436306146,
                40.5566280581949
              ],
              [
                -73.89988092664278,
                40.55661341610056
              ],
              [
                -73.89987775633037,
                40.556599872564284
              ],
              [
                -73.89987355930762,
                40.5565874063553
              ],
              [
                -73.89986697964667,
                40.556574872229575
              ],
              [
                -73.89986280526094,
                40.55656708917103
              ],
              [
                -73.89986043658175,
                40.556562689948166
              ],
              [
                -73.89985460397277,
                40.55655307032085
              ],
              [
                -73.89985134924063,
                40.55654925332931
              ],
              [
                -73.89984389934071,
                40.55654053041887
              ],
              [
                -73.89983435952054,
                40.55653354749378
              ],
              [
                -73.89982713792294,
                40.556532386243994
              ],
              [
                -73.8998153126919,
                40.55653798633839
              ],
              [
                -73.89980874945697,
                40.556545707762595
              ],
              [
                -73.89980821300148,
                40.55655688974863
              ],
              [
                -73.89980794971703,
                40.55656255303516
              ],
              [
                -73.89980743937654,
                40.55657697272704
              ],
              [
                -73.89980743284025,
                40.55657942720206
              ],
              [
                -73.899807420549,
                40.556584254733195
              ],
              [
                -73.89980740755739,
                40.556589109738674
              ],
              [
                -73.89981014077418,
                40.55660278725457
              ],
              [
                -73.89981540903364,
                40.556620218144076
              ],
              [
                -73.89981878639196,
                40.556635273652574
              ],
              [
                -73.89982422386589,
                40.55665558067517
              ],
              [
                -73.89982902357353,
                40.55667216563173
              ],
              [
                -73.89983344253605,
                40.556685738738395
              ],
              [
                -73.89983798033158,
                40.55669887117745
              ],
              [
                -73.89983906428724,
                40.55670796129038
              ],
              [
                -73.8998392362297,
                40.55670945463438
              ],
              [
                -73.89983703692896,
                40.5567126520351
              ],
              [
                -73.89981738310247,
                40.55671434654569
              ],
              [
                -73.89980106402501,
                40.556716595135896
              ],
              [
                -73.899776266027,
                40.5567203415811
              ],
              [
                -73.89975042814228,
                40.556725011543286
              ],
              [
                -73.89973007219342,
                40.55672942058341
              ],
              [
                -73.89971092077198,
                40.556733112638554
              ],
              [
                -73.89969927029304,
                40.55673493708443
              ],
              [
                -73.89968428255023,
                40.55673729605025
              ],
              [
                -73.89966750247613,
                40.55674009271175
              ],
              [
                -73.89965186792826,
                40.55674211789119
              ],
              [
                -73.89963666996877,
                40.556744044607434
              ],
              [
                -73.8996219310278,
                40.55674585595965
              ],
              [
                -73.89960629673782,
                40.55674785365834
              ],
              [
                -73.89959008378196,
                40.55674977897862
              ],
              [
                -73.89957409399733,
                40.556752136551225
              ],
              [
                -73.89955889586747,
                40.55675402757329
              ],
              [
                -73.8995428953049,
                40.55675639384373
              ],
              [
                -73.89952643464986,
                40.55675885804956
              ],
              [
                -73.8995088666736,
                40.55676107752818
              ],
              [
                -73.89949229981046,
                40.55676332636019
              ],
              [
                -73.89947468412873,
                40.55676557209404
              ],
              [
                -73.89942671038659,
                40.55677028911546
              ],
              [
                -73.89940979201283,
                40.55677185360317
              ],
              [
                -73.89939368875535,
                40.55677297970663
              ],
              [
                -73.89937802152436,
                40.556773943685776
              ],
              [
                -73.8993620690193,
                40.556775311997626
              ],
              [
                -73.89933282135017,
                40.55677729722671
              ],
              [
                -73.89930741712652,
                40.55677882192768
              ],
              [
                -73.89928589176947,
                40.55677984827262
              ],
              [
                -73.89926717190949,
                40.556780341313576
              ],
              [
                -73.89924945831343,
                40.55677934204019
              ],
              [
                -73.89922957396105,
                40.556775409923674
              ],
              [
                -73.89921479106768,
                40.5567679053494
              ],
              [
                -73.89921440127031,
                40.55676770732204
              ],
              [
                -73.89920745561433,
                40.556755866076216
              ],
              [
                -73.89920303747549,
                40.556742264968655
              ],
              [
                -73.89919887524741,
                40.5567298163562
              ],
              [
                -73.8991936285606,
                40.55671725923757
              ],
              [
                -73.89919277230246,
                40.55671555772239
              ],
              [
                -73.89918743781124,
                40.556705003681486
              ],
              [
                -73.89918229566776,
                40.55669318328314
              ],
              [
                -73.89917752317201,
                40.55667933978263
              ],
              [
                -73.89916984107614,
                40.55666573530582
              ],
              [
                -73.89915940253864,
                40.55665960526807
              ],
              [
                -73.89914364231204,
                40.556655256760095
              ],
              [
                -73.89912765821086,
                40.55665152674129
              ],
              [
                -73.89912302657942,
                40.556651466718264
              ],
              [
                -73.89911845657572,
                40.55665466872397
              ],
              [
                -73.89911559256481,
                40.55666282642276
              ],
              [
                -73.8991148690234,
                40.556664876185685
              ],
              [
                -73.89911530531842,
                40.556678064858666
              ],
              [
                -73.899116482255,
                40.55669140730439
              ],
              [
                -73.89912014069037,
                40.55670713655059
              ],
              [
                -73.89912301755606,
                40.55671999750072
              ],
              [
                -73.89912496638853,
                40.55673156195941
              ],
              [
                -73.899127252222,
                40.55674497842206
              ],
              [
                -73.89912776302342,
                40.556747954871945
              ],
              [
                -73.8991316409269,
                40.55676089778554
              ],
              [
                -73.89913619805169,
                40.55677539714582
              ],
              [
                -73.89913667895024,
                40.556785232746535
              ],
              [
                -73.8991319889622,
                40.55679355854964
              ],
              [
                -73.89911718426394,
                40.55680035379417
              ],
              [
                -73.8990952588455,
                40.55680884454962
              ],
              [
                -73.89908534721569,
                40.5568121762199
              ],
              [
                -73.89907191645871,
                40.55681661384464
              ],
              [
                -73.89905510745946,
                40.55682151557218
              ],
              [
                -73.89903742560581,
                40.55682668658795
              ],
              [
                -73.89901964668839,
                40.55683271392861
              ],
              [
                -73.899001751476,
                40.55683851499892
              ],
              [
                -73.89898051279202,
                40.556845025127714
              ],
              [
                -73.89895716970719,
                40.5568528031103
              ],
              [
                -73.89893448970109,
                40.556860023964624
              ],
              [
                -73.89891429061703,
                40.55686681443596
              ],
              [
                -73.89889380717528,
                40.556873108932294
              ],
              [
                -73.89887292268962,
                40.55688039334118
              ],
              [
                -73.89885272293415,
                40.55688718380102
              ],
              [
                -73.89883436626594,
                40.55689309316581
              ],
              [
                -73.89881272425498,
                40.55690063003408
              ],
              [
                -73.89879060968802,
                40.55690894867906
              ],
              [
                -73.89876742012228,
                40.556916997486745
              ],
              [
                -73.89874579035636,
                40.55692456065547
              ],
              [
                -73.89872169283505,
                40.556932553703625
              ],
              [
                -73.89869631793299,
                40.556941636407274
              ],
              [
                -73.89867377844706,
                40.55694914498281
              ],
              [
                -73.89866213109286,
                40.55695321020644
              ],
              [
                -73.89865213659446,
                40.55695669823902
              ],
              [
                -73.89863179528275,
                40.55696323657015
              ],
              [
                -73.89861242394521,
                40.556968901750345
              ],
              [
                -73.89859642933534,
                40.556974381102606
              ],
              [
                -73.89858221850213,
                40.55697927684581
              ],
              [
                -73.89856820836926,
                40.55698363147539
              ],
              [
                -73.89854145485809,
                40.556990721834175
              ],
              [
                -73.89851946372956,
                40.55699495247122
              ],
              [
                -73.89851450413911,
                40.556995759436205
              ],
              [
                -73.89850415960983,
                40.556997443854726
              ],
              [
                -73.89850283567749,
                40.55699765796057
              ],
              [
                -73.89848519167198,
                40.557001424019084
              ],
              [
                -73.89846539391658,
                40.557006980740866
              ],
              [
                -73.89844375413135,
                40.557014013109054
              ],
              [
                -73.89842095425452,
                40.55702184574273
              ],
              [
                -73.89839853425389,
                40.557029318686105
              ],
              [
                -73.89837620677743,
                40.55703704316942
              ],
              [
                -73.89835175395899,
                40.55704563910081
              ],
              [
                -73.89833569993478,
                40.557050875278314
              ],
              [
                -73.89833278263819,
                40.55705182728118
              ],
              [
                -73.89831103530717,
                40.557058642578106
              ],
              [
                -73.89828608894082,
                40.557065204747936
              ],
              [
                -73.89826081312597,
                40.55707116987898
              ],
              [
                -73.89824828256276,
                40.557074337825625
              ],
              [
                -73.89823432134004,
                40.55707770269116
              ],
              [
                -73.89820767867585,
                40.55708191155607
              ],
              [
                -73.8981908738412,
                40.55708428295652
              ],
              [
                -73.89817420196248,
                40.5570845915091
              ],
              [
                -73.89814966647052,
                40.55708456975323
              ],
              [
                -73.89813040714631,
                40.5570865888306
              ],
              [
                -73.89810877058365,
                40.55709060392503
              ],
              [
                -73.8980844780186,
                40.55709461615587
              ],
              [
                -73.8980659371215,
                40.557097094724284
              ],
              [
                -73.89804859220533,
                40.557097852814145
              ],
              [
                -73.89803275813095,
                40.55709855075931
              ],
              [
                -73.89801627293306,
                40.55710002160966
              ],
              [
                -73.89799514796813,
                40.55710213718018
              ],
              [
                -73.89797204009112,
                40.557103188845765
              ],
              [
                -73.89795109363446,
                40.55710390854009
              ],
              [
                -73.89792895544443,
                40.55710414032963
              ],
              [
                -73.89790819996253,
                40.55710321336679
              ],
              [
                -73.89789049233032,
                40.557101143372996
              ],
              [
                -73.8978403770865,
                40.55709063505546
              ],
              [
                -73.8978294010433,
                40.55708771630227
              ],
              [
                -73.897807315341,
                40.557084653806896
              ],
              [
                -73.89778000860085,
                40.55708243201631
              ],
              [
                -73.89776467433823,
                40.55708104981551
              ],
              [
                -73.8977478752496,
                40.557079800164864
              ],
              [
                -73.89773246916396,
                40.5570782736524
              ],
              [
                -73.89771646166685,
                40.557076611405705
              ],
              [
                -73.89769954625486,
                40.557074156099134
              ],
              [
                -73.89768375286748,
                40.557071809511456
              ],
              [
                -73.8976674504286,
                40.55706954422248
              ],
              [
                -73.89764889366454,
                40.557066889425606
              ],
              [
                -73.89762902628061,
                40.55706464675381
              ],
              [
                -73.89760880582952,
                40.557061747214036
              ],
              [
                -73.89759035599648,
                40.55705926371858
              ],
              [
                -73.89757027835604,
                40.55705562482245
              ],
              [
                -73.89754969422717,
                40.557050969402006
              ],
              [
                -73.89753033601892,
                40.55704771109849
              ],
              [
                -73.89751083763664,
                40.557043189993635
              ],
              [
                -73.8974949749068,
                40.55703966357232
              ],
              [
                -73.89747886443095,
                40.55703569665914
              ],
              [
                -73.8974521682449,
                40.5570286757713
              ],
              [
                -73.89742494255967,
                40.5570204647744
              ],
              [
                -73.89741076797287,
                40.55701628329957
              ],
              [
                -73.89739670068455,
                40.55701231769724
              ],
              [
                -73.89738317571349,
                40.55700835140521
              ],
              [
                -73.89735688619311,
                40.556998423041875
              ],
              [
                -73.8973424711101,
                40.556990088947224
              ],
              [
                -73.89733107996251,
                40.55698088422068
              ],
              [
                -73.89731702574228,
                40.55696813787505
              ],
              [
                -73.89730646248513,
                40.556957916644514
              ],
              [
                -73.89729639319526,
                40.55694871309642
              ],
              [
                -73.89728569121945,
                40.55693657369007
              ],
              [
                -73.89727249952617,
                40.55692357614541
              ],
              [
                -73.89726846764735,
                40.55691971884846
              ],
              [
                -73.89725586404428,
                40.556907667199134
              ],
              [
                -73.89723833191195,
                40.55689164872123
              ],
              [
                -73.89722312098311,
                40.556879235718824
              ],
              [
                -73.89720641046021,
                40.556866288291985
              ],
              [
                -73.89719491170374,
                40.556857525399444
              ],
              [
                -73.89718596194871,
                40.55685035012306
              ],
              [
                -73.89718544253134,
                40.55684974521011
              ],
              [
                -73.89717686335516,
                40.556839696456755
              ],
              [
                -73.8971742583602,
                40.55682868087555
              ],
              [
                -73.8971714654898,
                40.55681814325643
              ],
              [
                -73.89716856511079,
                40.55680724317384
              ],
              [
                -73.89716376799583,
                40.5567945337449
              ],
              [
                -73.89715972516333,
                40.55678239040479
              ],
              [
                -73.89715560272309,
                40.55676831185511
              ],
              [
                -73.89715174000722,
                40.55675416987645
              ],
              [
                -73.89714624648263,
                40.55674125996009
              ],
              [
                -73.8971421344785,
                40.5567273971977
              ],
              [
                -73.8971390967053,
                40.55671457944372
              ],
              [
                -73.89713692363696,
                40.55669965560804
              ],
              [
                -73.8971331754792,
                40.55668786501366
              ],
              [
                -73.89712965360629,
                40.55667490358769
              ],
              [
                -73.89712899705194,
                40.5566722097887
              ],
              [
                -73.89712552628787,
                40.5566638591667
              ],
              [
                -73.89711859133806,
                40.556658863411144
              ],
              [
                -73.89709684679295,
                40.55665631372607
              ],
              [
                -73.89707956116763,
                40.55665743308464
              ],
              [
                -73.89706459160598,
                40.55666362447431
              ],
              [
                -73.89706380003445,
                40.55669496241343
              ],
              [
                -73.89706586648651,
                40.55670237578893
              ],
              [
                -73.89706825020895,
                40.55671828019993
              ],
              [
                -73.89706961439545,
                40.556729718522305
              ],
              [
                -73.89707449198416,
                40.55674435612908
              ],
              [
                -73.89707637644929,
                40.55675498457541
              ],
              [
                -73.8970794853591,
                40.55676738725561
              ],
              [
                -73.89708396311498,
                40.55678079148345
              ],
              [
                -73.89708358566833,
                40.5567959649996
              ],
              [
                -73.89707345559772,
                40.55681113798495
              ],
              [
                -73.89706181021295,
                40.55682171811885
              ],
              [
                -73.89704812159891,
                40.5568323321039
              ],
              [
                -73.89703572008942,
                40.556842397743175
              ],
              [
                -73.8970272086246,
                40.55685712319528
              ],
              [
                -73.89702194052781,
                40.5568662679505
              ],
              [
                -73.89701959656192,
                40.55687031772735
              ],
              [
                -73.8970085611632,
                40.556882582078416
              ],
              [
                -73.89699522942666,
                40.55689179163876
              ],
              [
                -73.89697236759686,
                40.55690154264956
              ],
              [
                -73.89695825050372,
                40.55690668856569
              ],
              [
                -73.89694316598658,
                40.55691189073978
              ],
              [
                -73.89691461391156,
                40.5569214999285
              ],
              [
                -73.89690139405178,
                40.556926377087386
              ],
              [
                -73.89688782001124,
                40.556931606409684
              ],
              [
                -73.89686257314483,
                40.55694167859004
              ],
              [
                -73.89684674230215,
                40.55694826622373
              ],
              [
                -73.89684263133435,
                40.556949981890874
              ],
              [
                -73.89682509817398,
                40.55695701697258
              ],
              [
                -73.89680827343786,
                40.55696444035253
              ],
              [
                -73.89679286790525,
                40.55697054956039
              ],
              [
                -73.89677993051063,
                40.556976554602876
              ],
              [
                -73.89676232388463,
                40.55698592162548
              ],
              [
                -73.89674603923494,
                40.556995488021734
              ],
              [
                -73.8967319909397,
                40.55700228414152
              ],
              [
                -73.89672018771319,
                40.5570073525323
              ],
              [
                -73.89670686171098,
                40.55701252710642
              ],
              [
                -73.89669126886511,
                40.55701731381856
              ],
              [
                -73.89667381225632,
                40.55702141315194
              ],
              [
                -73.8966502984294,
                40.55702575949969
              ],
              [
                -73.89662413630356,
                40.557031939754296
              ],
              [
                -73.89659881984007,
                40.557040805766256
              ],
              [
                -73.89657303082267,
                40.557050859301526
              ],
              [
                -73.89654869476571,
                40.55705964493324
              ],
              [
                -73.8965351208921,
                40.55706487304129
              ],
              [
                -73.89652079133806,
                40.55706958782534
              ],
              [
                -73.89649489769143,
                40.557078399517174
              ],
              [
                -73.89647070447474,
                40.557086419481834
              ],
              [
                -73.89644593365944,
                40.55709438397092
              ],
              [
                -73.89642634856298,
                40.5571003555046
              ],
              [
                -73.89640888902028,
                40.557105472539355
              ],
              [
                -73.89639189314381,
                40.55710946402106
              ],
              [
                -73.89638072903632,
                40.557114118484435
              ],
              [
                -73.8963688745833,
                40.55712173676558
              ],
              [
                -73.89635374860089,
                40.55712996190203
              ],
              [
                -73.89633257498987,
                40.5571401726708
              ],
              [
                -73.8963028574274,
                40.55715491460955
              ],
              [
                -73.89627753339919,
                40.55716944473003
              ],
              [
                -73.89625477931412,
                40.55717828500269
              ],
              [
                -73.89623375248901,
                40.55718532621989
              ],
              [
                -73.89621316114835,
                40.55719312422436
              ],
              [
                -73.896193716343,
                40.55719967114288
              ],
              [
                -73.89617571486743,
                40.55720522021451
              ],
              [
                -73.89612955327631,
                40.55721789416281
              ],
              [
                -73.89610882361058,
                40.55722366524659
              ],
              [
                -73.89608718331205,
                40.55722941037322
              ],
              [
                -73.8960648951546,
                40.55723564778255
              ],
              [
                -73.89604177905676,
                40.55724207441693
              ],
              [
                -73.89602108451805,
                40.55724784668942
              ],
              [
                -73.89600401650891,
                40.55725227948989
              ],
              [
                -73.89598835415671,
                40.55725635454413
              ],
              [
                -73.89596128166882,
                40.55726280388153
              ],
              [
                -73.89594551358121,
                40.55726721154858
              ],
              [
                -73.89593506810212,
                40.55727173108797
              ],
              [
                -73.89591892385134,
                40.557282559496876
              ],
              [
                -73.895905849487,
                40.55729278640565
              ],
              [
                -73.89589515186486,
                40.55730109775243
              ],
              [
                -73.89587965836685,
                40.55731041277791
              ],
              [
                -73.8958669424309,
                40.55731784024725
              ],
              [
                -73.89585185203113,
                40.557326138392895
              ],
              [
                -73.89583913360062,
                40.55733530280698
              ],
              [
                -73.89583376321649,
                40.55734103399284
              ],
              [
                -73.89582644044448,
                40.55735065395468
              ],
              [
                -73.8958042376851,
                40.5573543442661
              ],
              [
                -73.89578748916927,
                40.557358390832576
              ],
              [
                -73.895766501129,
                40.55736317135099
              ],
              [
                -73.89575393356245,
                40.55736629342374
              ],
              [
                -73.89573877925031,
                40.557369926961215
              ],
              [
                -73.89572107424323,
                40.557374053399265
              ],
              [
                -73.89570692406241,
                40.557377615303764
              ],
              [
                -73.89569085963721,
                40.55738201328512
              ],
              [
                -73.89567357950362,
                40.5573859507928
              ],
              [
                -73.89565436553595,
                40.55738920285144
              ],
              [
                -73.89563232828618,
                40.55739294705717
              ],
              [
                -73.89561699906224,
                40.557395211365225
              ],
              [
                -73.89560237805892,
                40.55739785392746
              ],
              [
                -73.89558391866811,
                40.557401052380484
              ],
              [
                -73.89556565952896,
                40.55740457551816
              ],
              [
                -73.89554482506938,
                40.55740916732931
              ],
              [
                -73.8955279008104,
                40.557412366667016
              ],
              [
                -73.89551188771064,
                40.557414270149664
              ],
              [
                -73.89549445853949,
                40.5574154603394
              ],
              [
                -73.8954708187832,
                40.557416484045575
              ],
              [
                -73.89544354339156,
                40.55741805343264
              ],
              [
                -73.89543626972052,
                40.55741843380473
              ],
              [
                -73.89542036374306,
                40.55741953590894
              ],
              [
                -73.89540595765067,
                40.557420567506725
              ],
              [
                -73.89538789129564,
                40.55742146006947
              ],
              [
                -73.89537230601208,
                40.55742171710334
              ],
              [
                -73.89535719235813,
                40.5574223697679
              ],
              [
                -73.89533908996137,
                40.5574236943498
              ],
              [
                -73.89531893658403,
                40.55742354095342
              ],
              [
                -73.89530166347384,
                40.55742297571036
              ],
              [
                -73.89528651630843,
                40.5574220254089
              ],
              [
                -73.89526805469517,
                40.557419621609014
              ],
              [
                -73.89524370640552,
                40.55741317932196
              ],
              [
                -73.89523064412063,
                40.55740819712954
              ],
              [
                -73.89521720563008,
                40.557402340086334
              ],
              [
                -73.89519982761882,
                40.55739331849246
              ],
              [
                -73.89518458696945,
                40.55738503715082
              ],
              [
                -73.89517340416194,
                40.557378147998996
              ],
              [
                -73.89516164657341,
                40.55736885208907
              ],
              [
                -73.89515159156545,
                40.55735923439969
              ],
              [
                -73.89514692835664,
                40.557351414034585
              ],
              [
                -73.89514852671894,
                40.5573405191808
              ],
              [
                -73.89514753304947,
                40.55732706428157
              ],
              [
                -73.89514345051357,
                40.55731747901628
              ],
              [
                -73.89513439494031,
                40.557309951000626
              ],
              [
                -73.89512221087577,
                40.55730215124208
              ],
              [
                -73.89510797247821,
                40.557294177217564
              ],
              [
                -73.89509113472049,
                40.55728677661737
              ],
              [
                -73.89507707602817,
                40.5572779480157
              ],
              [
                -73.8950640624076,
                40.55727179431212
              ],
              [
                -73.8950457120951,
                40.55726554626917
              ],
              [
                -73.89502000255709,
                40.55726157794512
              ],
              [
                -73.89501879965948,
                40.55726121782891
              ],
              [
                -73.89501793789599,
                40.55726084646503
              ],
              [
                -73.89501734885607,
                40.5572601081249
              ],
              [
                -73.89496631694892,
                40.557133464806725
              ],
              [
                -73.89495564136901,
                40.557105205981486
              ],
              [
                -73.89494218163914,
                40.557090001722365
              ],
              [
                -73.8949181726631,
                40.55707879629831
              ],
              [
                -73.89489014015683,
                40.55708825233697
              ],
              [
                -73.89489010400668,
                40.557088333555896
              ],
              [
                -73.8948819661663,
                40.5571053905375
              ],
              [
                -73.89489756403287,
                40.55714972853696
              ],
              [
                -73.89495626172017,
                40.55728147549215
              ],
              [
                -73.89495649475006,
                40.55728302435171
              ],
              [
                -73.8949559942491,
                40.55728646479084
              ],
              [
                -73.89495517340907,
                40.5572898220097
              ],
              [
                -73.89495419117144,
                40.55729189295361
              ],
              [
                -73.89495276013942,
                40.55729301694315
              ],
              [
                -73.89495142499406,
                40.557293925409404
              ],
              [
                -73.89494848366208,
                40.55729503930591
              ],
              [
                -73.89494140837533,
                40.557296518321735
              ],
              [
                -73.89492507893114,
                40.55729678332568
              ],
              [
                -73.89490822437855,
                40.557300630976634
              ],
              [
                -73.89489400002807,
                40.55730608395836
              ],
              [
                -73.8948763610934,
                40.55731342556071
              ],
              [
                -73.89485719504914,
                40.557323340362004
              ],
              [
                -73.89484124113268,
                40.55733244022021
              ],
              [
                -73.89482859594897,
                40.557340054770414
              ],
              [
                -73.89481652964469,
                40.55734718518537
              ],
              [
                -73.8948011434219,
                40.55735592486593
              ],
              [
                -73.89477504316352,
                40.557367400041485
              ],
              [
                -73.8947661476704,
                40.55737066039972
              ],
              [
                -73.89474633523552,
                40.55737829752368
              ],
              [
                -73.89472783505299,
                40.557385475809895
              ],
              [
                -73.89471090600708,
                40.55739169156649
              ],
              [
                -73.89469513329593,
                40.55739812466174
              ],
              [
                -73.89467979669054,
                40.55740546834487
              ],
              [
                -73.89466465983713,
                40.55741317122568
              ],
              [
                -73.89464726684704,
                40.55742149457615
              ],
              [
                -73.89463210764855,
                40.55742818019064
              ],
              [
                -73.89460757906444,
                40.55743971222238
              ],
              [
                -73.89459382460936,
                40.557446065903896
              ],
              [
                -73.89457849050979,
                40.557452364356635
              ],
              [
                -73.89456551849206,
                40.55745729692667
              ],
              [
                -73.8945501135457,
                40.55746241506241
              ],
              [
                -73.89453427277226,
                40.557467695802316
              ],
              [
                -73.89452105398466,
                40.55747213241962
              ],
              [
                -73.89450707869523,
                40.557477000400205
              ],
              [
                -73.89449181506372,
                40.5574824798524
              ],
              [
                -73.89447226487341,
                40.55748855813667
              ],
              [
                -73.89445645580385,
                40.55749600890996
              ],
              [
                -73.89444046916707,
                40.557503513965194
              ],
              [
                -73.894428581734,
                40.557509022814216
              ],
              [
                -73.8944106466766,
                40.55751729185348
              ],
              [
                -73.89439432946315,
                40.55752493045479
              ],
              [
                -73.8943777294342,
                40.55753153396133
              ],
              [
                -73.89436062314624,
                40.55753741745889
              ],
              [
                -73.8943430567223,
                40.55754341679638
              ],
              [
                -73.8943238969548,
                40.557549271426936
              ],
              [
                -73.89430402797808,
                40.55755579016045
              ],
              [
                -73.89427586644453,
                40.5575647063165
              ],
              [
                -73.89426830527626,
                40.5575675538981
              ],
              [
                -73.89425270890521,
                40.55757437526174
              ],
              [
                -73.89423647214895,
                40.55758292410462
              ],
              [
                -73.89422328361127,
                40.557590980031755
              ],
              [
                -73.89421276567148,
                40.557596904588706
              ],
              [
                -73.89419667280882,
                40.557604184519406
              ],
              [
                -73.89418431266425,
                40.5576107924064
              ],
              [
                -73.89416716493481,
                40.55762062770002
              ],
              [
                -73.89415434322011,
                40.55762783860115
              ],
              [
                -73.89414340108867,
                40.55763364532239
              ],
              [
                -73.89412708245119,
                40.557641590799165
              ],
              [
                -73.89411487596625,
                40.55764797382774
              ],
              [
                -73.89409993015833,
                40.55765452453948
              ],
              [
                -73.8940831766383,
                40.557661614871385
              ],
              [
                -73.89407049888413,
                40.557667096652224
              ],
              [
                -73.8940541575225,
                40.557674401965976
              ],
              [
                -73.89404137259272,
                40.55768070269839
              ],
              [
                -73.8940258456031,
                40.55768917258963
              ],
              [
                -73.8940138143366,
                40.557695861008575
              ],
              [
                -73.89399779211266,
                40.55770386051756
              ],
              [
                -73.89398576390695,
                40.55770958547193
              ],
              [
                -73.89397085126679,
                40.557717226482254
              ],
              [
                -73.89395525321527,
                40.55772506554817
              ],
              [
                -73.89394099359602,
                40.557730490904234
              ],
              [
                -73.89392471499531,
                40.5577358782076
              ],
              [
                -73.89391154277077,
                40.557740396049745
              ],
              [
                -73.89389410537689,
                40.55774710693719
              ],
              [
                -73.89387797702273,
                40.557754422306765
              ],
              [
                -73.89387427890601,
                40.55775625635763
              ],
              [
                -73.89386234461092,
                40.557762170192056
              ],
              [
                -73.89384242170522,
                40.55777214845591
              ],
              [
                -73.8938275574881,
                40.557779293101234
              ],
              [
                -73.89381008575475,
                40.557784518456536
              ],
              [
                -73.89379029427897,
                40.55778656261639
              ],
              [
                -73.89378003300159,
                40.55778767792253
              ],
              [
                -73.8937616438375,
                40.55779093059493
              ],
              [
                -73.89374749199403,
                40.557795554563654
              ],
              [
                -73.89373488375027,
                40.557801630432934
              ],
              [
                -73.8937191808256,
                40.55780922561367
              ],
              [
                -73.89370398329113,
                40.55781738935403
              ],
              [
                -73.89369137329186,
                40.55782497750927
              ],
              [
                -73.89367995542506,
                40.557832927458584
              ],
              [
                -73.8936676264106,
                40.557842136890784
              ],
              [
                -73.89365297118835,
                40.55785126358572
              ],
              [
                -73.8936355454664,
                40.55785750670124
              ],
              [
                -73.89361865369972,
                40.55786279455059
              ],
              [
                -73.89360532636421,
                40.55786799623864
              ],
              [
                -73.89358631459312,
                40.55787667862058
              ],
              [
                -73.89356665523417,
                40.55788511547052
              ],
              [
                -73.8935595172113,
                40.55788937818751
              ],
              [
                -73.89355458792144,
                40.55789231879493
              ],
              [
                -73.89354955190151,
                40.557896762381354
              ],
              [
                -73.8935451768539,
                40.557900630109884
              ],
              [
                -73.89353242776691,
                40.55790643509798
              ],
              [
                -73.89351478690286,
                40.55791479373089
              ],
              [
                -73.89349979678227,
                40.5579268838673
              ],
              [
                -73.89348635361986,
                40.55793790223998
              ],
              [
                -73.89347065050556,
                40.55794557863864
              ],
              [
                -73.89345614580793,
                40.55794940078107
              ],
              [
                -73.8934359490259,
                40.5579532275247
              ],
              [
                -73.89342424263211,
                40.55795654012065
              ],
              [
                -73.89340847281092,
                40.557961000544836
              ],
              [
                -73.89339216208174,
                40.55796484847796
              ],
              [
                -73.89337632196164,
                40.55796917497614
              ],
              [
                -73.89335990249572,
                40.55797385174166
              ],
              [
                -73.89334402797203,
                40.55797757375554
              ],
              [
                -73.89332746870785,
                40.557981484607396
              ],
              [
                -73.89331270247462,
                40.55798580271254
              ],
              [
                -73.8932950656039,
                40.5579916306086
              ],
              [
                -73.89327827868684,
                40.557997107981656
              ],
              [
                -73.89326280142214,
                40.55800363162097
              ],
              [
                -73.893248790743,
                40.55800852588513
              ],
              [
                -73.8932276638708,
                40.55801100998073
              ],
              [
                -73.89320861760801,
                40.558011532388974
              ],
              [
                -73.89319530403395,
                40.558016193925795
              ],
              [
                -73.89318481921103,
                40.55802342498507
              ],
              [
                -73.89316803285013,
                40.558028956119564
              ],
              [
                -73.89315180317428,
                40.55803292153245
              ],
              [
                -73.89312594855188,
                40.55803879603688
              ],
              [
                -73.89310568151681,
                40.55804298334898
              ],
              [
                -73.89308984296201,
                40.55804630848277
              ],
              [
                -73.89307393396348,
                40.55804945462688
              ],
              [
                -73.89305931230531,
                40.55805207927418
              ],
              [
                -73.89304052257283,
                40.55805507832052
              ],
              [
                -73.89302303190969,
                40.55805763830521
              ],
              [
                -73.89300506972839,
                40.55806028680634
              ],
              [
                -73.89298807345267,
                40.55806383701304
              ],
              [
                -73.89297166705921,
                40.55806800042363
              ],
              [
                -73.89295657126348,
                40.55807179564995
              ],
              [
                -73.89293684750216,
                40.558075514019976
              ],
              [
                -73.8929185314161,
                40.55807791071519
              ],
              [
                -73.89290109973307,
                40.558080119426094
              ],
              [
                -73.89288343253995,
                40.558082353714354
              ],
              [
                -73.89286694672911,
                40.55808480588546
              ],
              [
                -73.89284949188885,
                40.55808748180787
              ],
              [
                -73.89283174003616,
                40.55809100431415
              ],
              [
                -73.8928155345823,
                40.558094636485116
              ],
              [
                -73.89280034483944,
                40.55809865609338
              ],
              [
                -73.8927839978274,
                40.558102486315704
              ],
              [
                -73.89275822850786,
                40.55810649654416
              ],
              [
                -73.89274005394665,
                40.55810870453696
              ],
              [
                -73.89272522039116,
                40.558110860195185
              ],
              [
                -73.89270938425692,
                40.55811265589837
              ],
              [
                -73.89268761034235,
                40.558113841603436
              ],
              [
                -73.89266882387994,
                40.55811437354665
              ],
              [
                -73.8926531657471,
                40.55811598009951
              ],
              [
                -73.89263311420149,
                40.55811807809675
              ],
              [
                -73.89261533023675,
                40.558119565051186
              ],
              [
                -73.89259676604208,
                40.558121475123855
              ],
              [
                -73.89256782772809,
                40.558121276172855
              ],
              [
                -73.8925590438621,
                40.55812069215577
              ],
              [
                -73.89254519501212,
                40.55812024713995
              ],
              [
                -73.89252745099384,
                40.558119293542525
              ],
              [
                -73.89250945943235,
                40.55811779054769
              ],
              [
                -73.89249384089474,
                40.55811621857094
              ],
              [
                -73.8924773962248,
                40.55811424827028
              ],
              [
                -73.89246225917607,
                40.55810789526906
              ],
              [
                -73.8924492860879,
                40.5580985798506
              ],
              [
                -73.89243810858285,
                40.55808890709571
              ],
              [
                -73.89242549285805,
                40.558077693727896
              ],
              [
                -73.89240849233514,
                40.558061899501595
              ],
              [
                -73.8924044341479,
                40.55805977946198
              ],
              [
                -73.8923883154821,
                40.5580537037986
              ],
              [
                -73.89237238317288,
                40.55804961889783
              ],
              [
                -73.8923581722535,
                40.55804650778437
              ],
              [
                -73.89233895484402,
                40.558043634726914
              ],
              [
                -73.8923172563169,
                40.55804193942074
              ],
              [
                -73.89229729856612,
                40.55803709366762
              ],
              [
                -73.89227848852535,
                40.55803087222673
              ],
              [
                -73.89226637138374,
                40.55802542116516
              ],
              [
                -73.89220367738248,
                40.55795112129285
              ],
              [
                -73.8921993963761,
                40.557911357569026
              ],
              [
                -73.89216471320687,
                40.55786825290236
              ],
              [
                -73.89213864396103,
                40.55787154199658
              ],
              [
                -73.89211373696473,
                40.55791654611906
              ],
              [
                -73.89208699685538,
                40.55804822513738
              ],
              [
                -73.89207391762216,
                40.55806100978695
              ],
              [
                -73.89206134338015,
                40.55806821130547
              ],
              [
                -73.8920445263986,
                40.55807821847864
              ],
              [
                -73.89203007398828,
                40.558085228249276
              ],
              [
                -73.89202118696879,
                40.558090578338486
              ],
              [
                -73.89200886207534,
                40.55809720311255
              ],
              [
                -73.89199719571869,
                40.55810463114042
              ],
              [
                -73.89198479627267,
                40.558113210748324
              ],
              [
                -73.89197200524211,
                40.55812351923029
              ],
              [
                -73.8919576292185,
                40.55813476103659
              ],
              [
                -73.89194793452896,
                40.55814255160612
              ],
              [
                -73.89193651477026,
                40.55815150924031
              ],
              [
                -73.89192354828386,
                40.558160421525
              ],
              [
                -73.89191180091986,
                40.55816672299689
              ],
              [
                -73.89189898298577,
                40.55817192496629
              ],
              [
                -73.89188170127137,
                40.55817643870762
              ],
              [
                -73.89186809765137,
                40.558177965165406
              ],
              [
                -73.89185168389997,
                40.55817860743329
              ],
              [
                -73.89182486838432,
                40.55817957244887
              ],
              [
                -73.89181734386618,
                40.55818192535524
              ],
              [
                -73.89180333059387,
                40.55818833223581
              ],
              [
                -73.89179238639566,
                40.558194689401894
              ],
              [
                -73.89178229273934,
                40.55820115374989
              ],
              [
                -73.89176760137714,
                40.55821028017018
              ],
              [
                -73.89175592486991,
                40.55821770816394
              ],
              [
                -73.89174360874942,
                40.55822593701668
              ],
              [
                -73.8917278339071,
                40.55823338694408
              ],
              [
                -73.8917141761112,
                40.558238921318086
              ],
              [
                -73.89170233654164,
                40.55824395230224
              ],
              [
                -73.89168619603952,
                40.558251764246386
              ],
              [
                -73.8916682591017,
                40.55826058938992
              ],
              [
                -73.89165446820805,
                40.55826787046567
              ],
              [
                -73.89164055934089,
                40.558275026283454
              ],
              [
                -73.89162655615469,
                40.55828236964413
              ],
              [
                -73.89161127463387,
                40.558291846797864
              ],
              [
                -73.89159799271951,
                40.55829814678933
              ],
              [
                -73.89158297770366,
                40.55830351853544
              ],
              [
                -73.89156731550995,
                40.5583071773453
              ],
              [
                -73.8915482632456,
                40.558311742639376
              ],
              [
                -73.89152846414585,
                40.558318343383455
              ],
              [
                -73.89151945556257,
                40.55832546695811
              ],
              [
                -73.89150868211284,
                40.55833566856709
              ],
              [
                -73.89149498721612,
                40.558342248099066
              ],
              [
                -73.89148184843577,
                40.55834777305137
              ],
              [
                -73.89146564052045,
                40.558352836740276
              ],
              [
                -73.89145192790853,
                40.558356254793466
              ],
              [
                -73.89143378338977,
                40.55836112834382
              ],
              [
                -73.89141887618308,
                40.55836569753457
              ],
              [
                -73.89140483105456,
                40.55836949355042
              ],
              [
                -73.89138923834852,
                40.558373765058576
              ],
              [
                -73.89137023241307,
                40.55837940306101
              ],
              [
                -73.89134873135693,
                40.55838629852463
              ],
              [
                -73.89133357396578,
                40.558391940161634
              ],
              [
                -73.89131718794901,
                40.55839818223387
              ],
              [
                -73.89130299743927,
                40.55840370734327
              ],
              [
                -73.89128423749976,
                40.558410830549214
              ],
              [
                -73.89127004696967,
                40.558416364868776
              ],
              [
                -73.89125687432869,
                40.558421890265954
              ],
              [
                -73.89124131255271,
                40.55842873755562
              ],
              [
                -73.89122525414977,
                40.558436655391546
              ],
              [
                -73.89120803770966,
                40.5584448532435
              ],
              [
                -73.89118391048598,
                40.55845508606481
              ],
              [
                -73.8911772826573,
                40.55845777299581
              ],
              [
                -73.89115908759332,
                40.55846505972317
              ],
              [
                -73.89114339681826,
                40.558471663121374
              ],
              [
                -73.89112776621204,
                40.55847787019866
              ],
              [
                -73.89111285503942,
                40.558484035235836
              ],
              [
                -73.89109520382699,
                40.55849132079333
              ],
              [
                -73.89108116693396,
                40.55849717856786
              ],
              [
                -73.89106808825646,
                40.55850284911347
              ],
              [
                -73.8910549491736,
                40.558508383901064
              ],
              [
                -73.89104144405663,
                40.55851424167258
              ],
              [
                -73.89102836401084,
                40.55851993901709
              ],
              [
                -73.89101216480755,
                40.55852755965915
              ],
              [
                -73.89099331738521,
                40.558536160515736
              ],
              [
                -73.89097938744426,
                40.558542153576006
              ],
              [
                -73.89096025963967,
                40.55854960122034
              ],
              [
                -73.89094582323652,
                40.55855461156788
              ],
              [
                -73.8909297671219,
                40.55856054932864
              ],
              [
                -73.89091301383365,
                40.55856715218893
              ],
              [
                -73.89089516097698,
                40.558574546251144
              ],
              [
                -73.89087643516281,
                40.558582237516816
              ],
              [
                -73.89085496498267,
                40.55859221261954
              ],
              [
                -73.89083940306162,
                40.55859963682764
              ],
              [
                -73.89082208107335,
                40.55860758088106
              ],
              [
                -73.890810771714,
                40.558613224934135
              ],
              [
                -73.89079589630344,
                40.558619378907174
              ],
              [
                -73.89077963004345,
                40.55862485691262
              ],
              [
                -73.89075892304187,
                40.558630187454135
              ],
              [
                -73.89073738740578,
                40.55863651517913
              ],
              [
                -73.89071751952281,
                40.55864195289293
              ],
              [
                -73.89070326159215,
                40.558646333802265
              ],
              [
                -73.89068514879413,
                40.558653755561956
              ],
              [
                -73.8906680755495,
                40.558660682587394
              ],
              [
                -73.89065244339335,
                40.5586679167243
              ],
              [
                -73.89063850105023,
                40.55867393653616
              ],
              [
                -73.89062392295904,
                40.55867919046424
              ],
              [
                -73.89061099796507,
                40.558683653883655
              ],
              [
                -73.89059425787754,
                40.55868926644073
              ],
              [
                -73.89057314703759,
                40.558696387793674
              ],
              [
                -73.8905491641333,
                40.55870489305075
              ],
              [
                -73.89051863774654,
                40.55871524645415
              ],
              [
                -73.89050235858414,
                40.558720661249225
              ],
              [
                -73.8904887144675,
                40.558725231523454
              ],
              [
                -73.89047102896177,
                40.55873097936286
              ],
              [
                -73.8904563463809,
                40.55873497166996
              ],
              [
                -73.89044251425915,
                40.55873872270573
              ],
              [
                -73.89042333184365,
                40.55874350397011
              ],
              [
                -73.89040687782477,
                40.55874757750802
              ],
              [
                -73.89039100139331,
                40.558751848107455
              ],
              [
                -73.89037454574526,
                40.55875691190846
              ],
              [
                -73.89034504805443,
                40.55876626659655
              ],
              [
                -73.89032621782145,
                40.55877179586697
              ],
              [
                -73.89031160456129,
                40.558776501396466
              ],
              [
                -73.89029615298979,
                40.55878153816775
              ],
              [
                -73.8902820358403,
                40.55878616241277
              ],
              [
                -73.89026622960283,
                40.558791550654014
              ],
              [
                -73.89025218137611,
                40.55879713825806
              ],
              [
                -73.89023464758462,
                40.558804641249424
              ],
              [
                -73.89021653553505,
                40.55881154962421
              ],
              [
                -73.89020264144463,
                40.55880928390291
              ],
              [
                -73.89019916422593,
                40.55880625532376
              ],
              [
                -73.8901910691233,
                40.55880353678308
              ],
              [
                -73.89018413011773,
                40.55880118824805
              ],
              [
                -73.89017732999743,
                40.558800138202194
              ],
              [
                -73.8901698816206,
                40.55879898701805
              ],
              [
                -73.89015182107171,
                40.55879687981028
              ],
              [
                -73.89013605771044,
                40.55879675717852
              ],
              [
                -73.89011859438818,
                40.558797865938196
              ],
              [
                -73.89010045639387,
                40.55879864116776
              ],
              [
                -73.89008167051098,
                40.55879909143648
              ],
              [
                -73.89006522538631,
                40.55879813920445
              ],
              [
                -73.89004747960237,
                40.55879763638038
              ],
              [
                -73.89003085569685,
                40.55879734052204
              ],
              [
                -73.89001495135712,
                40.558797460321856
              ],
              [
                -73.88999497422742,
                40.5587971441591
              ],
              [
                -73.88997827768068,
                40.55879720941802
              ],
              [
                -73.88996071980075,
                40.55879758076411
              ],
              [
                -73.88994362517926,
                40.558796348452475
              ],
              [
                -73.88992527143604,
                40.558793151816275
              ],
              [
                -73.88990545270576,
                40.5587896205558
              ],
              [
                -73.88988292695555,
                40.558787815940256
              ],
              [
                -73.88986547856697,
                40.55878639380159
              ],
              [
                -73.88984272640757,
                40.55878615570059
              ],
              [
                -73.88982638760736,
                40.558784933980746
              ],
              [
                -73.88981016755976,
                40.55878282965744
              ],
              [
                -73.8897884365622,
                40.55877953912548
              ],
              [
                -73.88976684651834,
                40.55877655563952
              ],
              [
                -73.88974665924715,
                40.55877433877254
              ],
              [
                -73.88973183055423,
                40.558773532957865
              ],
              [
                -73.88971251660583,
                40.55877239782641
              ],
              [
                -73.88966114737742,
                40.558694192269215
              ],
              [
                -73.88963947542902,
                40.55866435179397
              ],
              [
                -73.88959262563442,
                40.558698140938716
              ],
              [
                -73.88961883994106,
                40.5587923889739
              ],
              [
                -73.88960513997493,
                40.55880239861797
              ],
              [
                -73.88959408512527,
                40.55881106051208
              ],
              [
                -73.8895834926073,
                40.55881961362016
              ],
              [
                -73.88957202650457,
                40.558827509840135
              ],
              [
                -73.88955600226384,
                40.55883544556875
              ],
              [
                -73.88953821130872,
                40.55884100318434
              ],
              [
                -73.8895241314313,
                40.55884470009413
              ],
              [
                -73.8895032919375,
                40.55885181212978
              ],
              [
                -73.88949428876106,
                40.5588558910298
              ],
              [
                -73.88947903966285,
                40.558865954788175
              ],
              [
                -73.88946571895633,
                40.558874666591244
              ],
              [
                -73.88945256487835,
                40.55888223616689
              ],
              [
                -73.8894400605295,
                40.558889545689766
              ],
              [
                -73.88942809859229,
                40.558897467720286
              ],
              [
                -73.88941636213822,
                40.55890478685907
              ],
              [
                -73.88939953358431,
                40.55891372194484
              ],
              [
                -73.88938592166666,
                40.558920400147834
              ],
              [
                -73.88936900021129,
                40.558928128589464
              ],
              [
                -73.88934793480718,
                40.55893633887289
              ],
              [
                -73.88933571933883,
                40.55894043275878
              ],
              [
                -73.8893351879128,
                40.558940612844005
              ],
              [
                -73.88931527232134,
                40.55894555521843
              ],
              [
                -73.88929867917054,
                40.558948286214004
              ],
              [
                -73.88928182463887,
                40.55895167350491
              ],
              [
                -73.88926519408469,
                40.55895536792399
              ],
              [
                -73.88924992947923,
                40.558960838469666
              ],
              [
                -73.88923433400562,
                40.55896661276099
              ],
              [
                -73.8892171555851,
                40.55897337696538
              ],
              [
                -73.88920044973277,
                40.55897995331932
              ],
              [
                -73.88917505852869,
                40.55899032958024
              ],
              [
                -73.8891584235239,
                40.558997094300054
              ],
              [
                -73.88914307298448,
                40.55900443656024
              ],
              [
                -73.88912460287192,
                40.55901364074969
              ],
              [
                -73.88910490444813,
                40.55902350029979
              ],
              [
                -73.88908973099831,
                40.55903120408575
              ],
              [
                -73.88907860002212,
                40.55903670322718
              ],
              [
                -73.88906459552393,
                40.559044570642705
              ],
              [
                -73.88904806278256,
                40.55905256896803
              ],
              [
                -73.88903236176469,
                40.559058650044825
              ],
              [
                -73.88901342180179,
                40.559065988316874
              ],
              [
                -73.88899879608117,
                40.5590716207779
              ],
              [
                -73.88898168957054,
                40.55907751168308
              ],
              [
                -73.88896538647242,
                40.55908292623726
              ],
              [
                -73.88894827995512,
                40.5590888176401
              ],
              [
                -73.88892976811798,
                40.55909441835728
              ],
              [
                -73.88891371347253,
                40.55909981505115
              ],
              [
                -73.88889898140337,
                40.559104798553264
              ],
              [
                -73.88888522950117,
                40.55910961228872
              ],
              [
                -73.88886870236347,
                40.55911514354833
              ],
              [
                -73.88884317456629,
                40.55912266539673
              ],
              [
                -73.88882876320554,
                40.559126712708014
              ],
              [
                -73.88881184628468,
                40.559131910365224
              ],
              [
                -73.88879445734517,
                40.5591372244988
              ],
              [
                -73.88877861484137,
                40.55914250444336
              ],
              [
                -73.88876356449067,
                40.55914734387913
              ],
              [
                -73.88874756681224,
                40.55915386640597
              ],
              [
                -73.8887338823092,
                40.55916093095282
              ],
              [
                -73.88872095207053,
                40.55916841120038
              ],
              [
                -73.88870563469125,
                40.55917781539214
              ],
              [
                -73.8886823662905,
                40.55918978882083
              ],
              [
                -73.88866346050536,
                40.5591981893755
              ],
              [
                -73.88864142483695,
                40.559207506966594
              ],
              [
                -73.8886153128972,
                40.55921793601431
              ],
              [
                -73.88860163108474,
                40.55922297544663
              ],
              [
                -73.88858819830041,
                40.55922844650834
              ],
              [
                -73.88857518948606,
                40.559233764857524
              ],
              [
                -73.88856043165234,
                40.559239676071755
              ],
              [
                -73.8885450129397,
                40.55924633483123
              ],
              [
                -73.88851497721903,
                40.55925914584185
              ],
              [
                -73.88850010155922,
                40.55926484115546
              ],
              [
                -73.88848599622588,
                40.55927001468935
              ],
              [
                -73.88847432349478,
                40.55927392958597
              ],
              [
                -73.88845783242984,
                40.55927899190529
              ],
              [
                -73.88843460892723,
                40.55928547185447
              ],
              [
                -73.88841196066112,
                40.55929355411116
              ],
              [
                -73.8883934805104,
                40.55930182168098
              ],
              [
                -73.88837425445327,
                40.559310583071266
              ],
              [
                -73.88835120127615,
                40.55932146585479
              ],
              [
                -73.88834640400576,
                40.559323676774106
              ],
              [
                -73.88833477455884,
                40.55933020248467
              ],
              [
                -73.88831780453516,
                40.559338335978985
              ],
              [
                -73.8883044874921,
                40.559345103801434
              ],
              [
                -73.88828704675458,
                40.55935283208105
              ],
              [
                -73.88826990484918,
                40.5593586601896
              ],
              [
                -73.88825247898284,
                40.559364847035944
              ],
              [
                -73.88823408223281,
                40.55937221318295
              ],
              [
                -73.88821164791288,
                40.55937973806882
              ],
              [
                -73.88818845551663,
                40.55938833356595
              ],
              [
                -73.8881751281128,
                40.55939353462369
              ],
              [
                -73.88816231056653,
                40.55939766515784
              ],
              [
                -73.88814416728067,
                40.55940201449129
              ],
              [
                -73.8881272377495,
                40.5594074546186
              ],
              [
                -73.88811183072283,
                40.55941364458274
              ],
              [
                -73.88809382369216,
                40.55942122736799
              ],
              [
                -73.88807775364894,
                40.559429172227354
              ],
              [
                -73.88806295796348,
                40.55943651555388
              ],
              [
                -73.88804760878529,
                40.5594438308641
              ],
              [
                -73.8880237288675,
                40.559454100119154
              ],
              [
                -73.88800753009538,
                40.55946073057104
              ],
              [
                -73.88799632718069,
                40.55946606870868
              ],
              [
                -73.88798040932177,
                40.55947428493372
              ],
              [
                -73.8879666430407,
                40.559480646018855
              ],
              [
                -73.88795601960904,
                40.55948557142159
              ],
              [
                -73.88794172129407,
                40.55949286026788
              ],
              [
                -73.88792461201481,
                40.55950028156606
              ],
              [
                -73.88791143846906,
                40.55950474427156
              ],
              [
                -73.88789256106391,
                40.559510651379526
              ],
              [
                -73.88787524355067,
                40.55951557895216
              ],
              [
                -73.88785342476315,
                40.5595218427013
              ],
              [
                -73.88783280130949,
                40.55952629829075
              ],
              [
                -73.88781954941862,
                40.55952861786679
              ],
              [
                -73.88780158577295,
                40.559531779367056
              ],
              [
                -73.88778395211988,
                40.55953503064801
              ],
              [
                -73.88776681567525,
                40.55953729264542
              ],
              [
                -73.8877467994364,
                40.559538948550674
              ],
              [
                -73.88773229808184,
                40.559540249151304
              ],
              [
                -73.88771534057112,
                40.55954138484505
              ],
              [
                -73.88769425074301,
                40.559542850215045
              ],
              [
                -73.8876688394054,
                40.55954403141244
              ],
              [
                -73.8876609625663,
                40.55954423998937
              ],
              [
                -73.88764645226922,
                40.55954406446454
              ],
              [
                -73.88762863594863,
                40.55954347006165
              ],
              [
                -73.88761179944221,
                40.55954318403224
              ],
              [
                -73.88759312195924,
                40.559542390593236
              ],
              [
                -73.88757175204198,
                40.55954138929166
              ],
              [
                -73.88755174014868,
                40.559540459510615
              ],
              [
                -73.88753042924643,
                40.559540024175064
              ],
              [
                -73.88750700361999,
                40.55953950551237
              ],
              [
                -73.88746871260935,
                40.55953966618981
              ],
              [
                -73.88744783717925,
                40.559540078296685
              ],
              [
                -73.88743154221267,
                40.55954039554416
              ],
              [
                -73.88741472854423,
                40.55954076605785
              ],
              [
                -73.88739594207813,
                40.55954129713888
              ],
              [
                -73.88737553751075,
                40.55954162793971
              ],
              [
                -73.88735455628775,
                40.55954186050149
              ],
              [
                -73.88733151943774,
                40.55954213426557
              ],
              [
                -73.88731003010243,
                40.55954239262288
              ],
              [
                -73.88729132758631,
                40.559541987277086
              ],
              [
                -73.8872650100889,
                40.55954138447508
              ],
              [
                -73.88724694515936,
                40.55954128716528
              ],
              [
                -73.88722917637294,
                40.55954077299398
              ],
              [
                -73.88721200899738,
                40.55954039644993
              ],
              [
                -73.88719563399836,
                40.55953958778436
              ],
              [
                -73.88718163115941,
                40.55953938455414
              ],
              [
                -73.88715841708209,
                40.559539766836316
              ],
              [
                -73.88714024448572,
                40.55954047036432
              ],
              [
                -73.88712214223418,
                40.559541271628625
              ],
              [
                -73.8871069574882,
                40.559541806202056
              ],
              [
                -73.88708924527616,
                40.55954187004284
              ],
              [
                -73.88707182997231,
                40.5595421048858
              ],
              [
                -73.88706621334039,
                40.559540145629526
              ],
              [
                -73.88706563479928,
                40.55953960377159
              ],
              [
                -73.88702989018371,
                40.559458242066896
              ],
              [
                -73.88701797484295,
                40.55943110725562
              ],
              [
                -73.88701103333392,
                40.55941662869161
              ],
              [
                -73.8870058914951,
                40.559405907918084
              ],
              [
                -73.88697510801153,
                40.55940048299849
              ],
              [
                -73.88694833771328,
                40.55942501938268
              ],
              [
                -73.88695038899543,
                40.559428452414124
              ],
              [
                -73.8869526003802,
                40.559432161692335
              ],
              [
                -73.88699547207565,
                40.559514718388385
              ],
              [
                -73.88699518208136,
                40.55951773246849
              ],
              [
                -73.88699238967502,
                40.559524814560405
              ],
              [
                -73.8869889206047,
                40.559532745363036
              ],
              [
                -73.8869831680338,
                40.55954089891125
              ],
              [
                -73.88697348578656,
                40.55954931044137
              ],
              [
                -73.88696170683093,
                40.5595581541445
              ],
              [
                -73.88694127351818,
                40.55957027492403
              ],
              [
                -73.88693506601751,
                40.559573761309196
              ],
              [
                -73.88692159769376,
                40.55958180841585
              ],
              [
                -73.88690777389455,
                40.559590153876584
              ],
              [
                -73.88689517092206,
                40.559596829427264
              ],
              [
                -73.88688214219336,
                40.55960366823394
              ],
              [
                -73.88686545531702,
                40.55961152168255
              ],
              [
                -73.88684618561702,
                40.55962033052008
              ],
              [
                -73.88682510812184,
                40.55962862325619
              ],
              [
                -73.88680568666378,
                40.55963611448223
              ],
              [
                -73.88677742056535,
                40.55964703620761
              ],
              [
                -73.88676002423266,
                40.559653427075666
              ],
              [
                -73.88674273568994,
                40.55965998222653
              ],
              [
                -73.88672558815315,
                40.559666932717036
              ],
              [
                -73.88671025250878,
                40.55967327350797
              ],
              [
                -73.88669180276406,
                40.55968117025798
              ],
              [
                -73.88667740176875,
                40.55968737576484
              ],
              [
                -73.88666264554954,
                40.55969386035922
              ],
              [
                -73.88664823292167,
                40.55970005713932
              ],
              [
                -73.88663477870321,
                40.559706099395925
              ],
              [
                -73.88661008661221,
                40.5597168890761
              ],
              [
                -73.88660159386502,
                40.559721087367
              ],
              [
                -73.8865848474998,
                40.55972875190983
              ],
              [
                -73.88656470274908,
                40.559737866750815
              ],
              [
                -73.8865440262614,
                40.559746945379146
              ],
              [
                -73.88652795651807,
                40.55975287748531
              ],
              [
                -73.88650786008945,
                40.55976107999615
              ],
              [
                -73.88648678162843,
                40.55976937317197
              ],
              [
                -73.88646991785976,
                40.55977579249977
              ],
              [
                -73.88644624442207,
                40.55978559003428
              ],
              [
                -73.88643674773688,
                40.5597897055659
              ],
              [
                -73.8864200715227,
                40.55979756013299
              ],
              [
                -73.88640556342533,
                40.559804586731325
              ],
              [
                -73.88638642583061,
                40.55981262816796
              ],
              [
                -73.88636686065661,
                40.55982086853884
              ],
              [
                -73.88634885109194,
                40.55982808114206
              ],
              [
                -73.88633210557335,
                40.55983469875457
              ],
              [
                -73.88631124047652,
                40.559842404749475
              ],
              [
                -73.88628023867297,
                40.5598540455307
              ],
              [
                -73.8862589101418,
                40.55986287870088
              ],
              [
                -73.88623901467702,
                40.5598714435634
              ],
              [
                -73.88622110842529,
                40.559878854939434
              ],
              [
                -73.88620281397749,
                40.55988552477571
              ],
              [
                -73.88618585685326,
                40.55989178213553
              ],
              [
                -73.88616863902541,
                40.55989853478781
              ],
              [
                -73.88614853091195,
                40.559906710922064
              ],
              [
                -73.88611109684452,
                40.5599219190292
              ],
              [
                -73.88608492815555,
                40.55993135332302
              ],
              [
                -73.88607110952485,
                40.5599361777124
              ],
              [
                -73.88604894163463,
                40.55994435982744
              ],
              [
                -73.8860289513432,
                40.55995272704177
              ],
              [
                -73.88600862950243,
                40.55996049667945
              ],
              [
                -73.88598972144771,
                40.559966941382854
              ],
              [
                -73.88597470624889,
                40.5599719357883
              ],
              [
                -73.88594540242342,
                40.55998079767124
              ],
              [
                -73.88593359689607,
                40.55998449522671
              ],
              [
                -73.88590609226951,
                40.559992339467115
              ],
              [
                -73.88589285465979,
                40.55999623545903
              ],
              [
                -73.88586762480838,
                40.56000344081322
              ],
              [
                -73.88584691761402,
                40.56000840374611
              ],
              [
                -73.88582778528144,
                40.560013420845145
              ],
              [
                -73.885808948384,
                40.56001895439431
              ],
              [
                -73.88579419469708,
                40.56002347961752
              ],
              [
                -73.88577690695863,
                40.56002855349207
              ],
              [
                -73.88576087880901,
                40.560031534325674
              ],
              [
                -73.88574489795874,
                40.56003382950418
              ],
              [
                -73.88572526989816,
                40.560038684259126
              ],
              [
                -73.88570870779337,
                40.56004202574859
              ],
              [
                -73.8856904900261,
                40.56004503354369
              ],
              [
                -73.88567038650775,
                40.56004930916775
              ],
              [
                -73.8856508311303,
                40.560053658879696
              ],
              [
                -73.88563340220868,
                40.56005804638965
              ],
              [
                -73.88561210551558,
                40.56006256507404
              ],
              [
                -73.88559871654877,
                40.56006478223173
              ],
              [
                -73.88558241626406,
                40.56006748332943
              ],
              [
                -73.88555827758914,
                40.560072911540416
              ],
              [
                -73.88554427202199,
                40.5600764180576
              ],
              [
                -73.88551666325391,
                40.56008230401152
              ],
              [
                -73.88550302533781,
                40.56008545790459
              ],
              [
                -73.88547531123174,
                40.560091153764674
              ],
              [
                -73.88545309071588,
                40.560095365756474
              ],
              [
                -73.88543277625294,
                40.560099280939326
              ],
              [
                -73.88540837775066,
                40.56010429388806
              ],
              [
                -73.88538374267821,
                40.560108918596406
              ],
              [
                -73.8853632401859,
                40.56011244541258
              ],
              [
                -73.88534667832201,
                40.56011562551876
              ],
              [
                -73.88533015232493,
                40.56011876829939
              ],
              [
                -73.8853141363416,
                40.560121035405416
              ],
              [
                -73.88529648951729,
                40.56012220138181
              ],
              [
                -73.88527386894698,
                40.560124769303464
              ],
              [
                -73.88524858662313,
                40.56012818326791
              ],
              [
                -73.8852341912882,
                40.56013006448526
              ],
              [
                -73.88521744058869,
                40.560133324463564
              ],
              [
                -73.8851987482445,
                40.560136881204414
              ],
              [
                -73.88518128795546,
                40.56013980725111
              ],
              [
                -73.8851658160028,
                40.56014249052201
              ],
              [
                -73.88514881564575,
                40.56014530779426
              ],
              [
                -73.88513088351232,
                40.56014836788791
              ],
              [
                -73.88511277798887,
                40.56015096173726
              ],
              [
                -73.88508182676415,
                40.56015342529315
              ],
              [
                -73.88505227175698,
                40.560153810187046
              ],
              [
                -73.88502923309899,
                40.56015551637084
              ],
              [
                -73.8850127096114,
                40.56015769731917
              ],
              [
                -73.88499384958591,
                40.56016039744603
              ],
              [
                -73.88497701148718,
                40.56016056939059
              ],
              [
                -73.8849511180102,
                40.560160174555406
              ],
              [
                -73.8849443679716,
                40.560144678817345
              ],
              [
                -73.88492030518748,
                40.56008942529685
              ],
              [
                -73.88490715629744,
                40.56006638597498
              ],
              [
                -73.88489701008322,
                40.560047599056425
              ],
              [
                -73.88488243929883,
                40.560041164372485
              ],
              [
                -73.88485949479872,
                40.56004280745799
              ],
              [
                -73.88483809042465,
                40.560054717142414
              ],
              [
                -73.88482738580119,
                40.560073041718134
              ],
              [
                -73.88482639762242,
                40.56008434162391
              ],
              [
                -73.88482942339473,
                40.56008946051857
              ],
              [
                -73.88485674870846,
                40.56013566602369
              ],
              [
                -73.88488436886665,
                40.560182368041204
              ],
              [
                -73.88485800842345,
                40.56019240195548
              ],
              [
                -73.88483601878171,
                40.56019503576015
              ],
              [
                -73.8848168532933,
                40.56020328280156
              ],
              [
                -73.88480416654231,
                40.56021287858728
              ],
              [
                -73.88479112502597,
                40.560223626789124
              ],
              [
                -73.88477491440844,
                40.560229698563596
              ],
              [
                -73.88475532648981,
                40.5602366759644
              ],
              [
                -73.88474405393283,
                40.56024128412236
              ],
              [
                -73.88473148047956,
                40.56024733206578
              ],
              [
                -73.88471693288132,
                40.56025443145421
              ],
              [
                -73.88470191292048,
                40.5602617104613
              ],
              [
                -73.88468617287215,
                40.560269520650955
              ],
              [
                -73.88467004324299,
                40.56027663653824
              ],
              [
                -73.88465332350867,
                40.56028367996054
              ],
              [
                -73.88463856501663,
                40.56028950941811
              ],
              [
                -73.8846249896923,
                40.560294656540464
              ],
              [
                -73.88461097723092,
                40.56029993724601
              ],
              [
                -73.88459797973535,
                40.56030513822021
              ],
              [
                -73.88457420687197,
                40.56031500278365
              ],
              [
                -73.88455439118883,
                40.56032346540885
              ],
              [
                -73.88453390201965,
                40.56033167538847
              ],
              [
                -73.88451499964208,
                40.56033799638523
              ],
              [
                -73.88449720857403,
                40.56034352574499
              ],
              [
                -73.88447607272954,
                40.560350581788356
              ],
              [
                -73.8844540744506,
                40.56035791673504
              ],
              [
                -73.88443206430397,
                40.56036525233582
              ],
              [
                -73.88440740771493,
                40.56037345809958
              ],
              [
                -73.88439325430404,
                40.560378550333034
              ],
              [
                -73.88437913564492,
                40.560383615124856
              ],
              [
                -73.88436422437736,
                40.56038922861499
              ],
              [
                -73.88434848693704,
                40.56039511132962
              ],
              [
                -73.8843310946317,
                40.560401847112786
              ],
              [
                -73.8843137017093,
                40.5604085565906
              ],
              [
                -73.88429717297157,
                40.56041498749649
              ],
              [
                -73.88428154007914,
                40.5604211229422
              ],
              [
                -73.88426677146676,
                40.560426925369605
              ],
              [
                -73.88425168208938,
                40.56043314143822
              ],
              [
                -73.88423691281277,
                40.560438943861115
              ],
              [
                -73.88422171797328,
                40.56044491773886
              ],
              [
                -73.8842050830714,
                40.560451124034344
              ],
              [
                -73.8841840883879,
                40.560458189380356
              ],
              [
                -73.8841587684461,
                40.56046735788629
              ],
              [
                -73.8841324117759,
                40.560476228310336
              ],
              [
                -73.88410568844773,
                40.5604844868742
              ],
              [
                -73.88405726297333,
                40.560500133875976
              ],
              [
                -73.88403597455215,
                40.56050670435022
              ],
              [
                -73.88401473247602,
                40.56051327436476
              ],
              [
                -73.88399082036794,
                40.56052164329481
              ],
              [
                -73.88396907019593,
                40.5605289783993
              ],
              [
                -73.88394940861666,
                40.56053678452621
              ],
              [
                -73.88392794090461,
                40.56054463388997
              ],
              [
                -73.88390752249369,
                40.560552744484184
              ],
              [
                -73.88389070942661,
                40.5605595620382
              ],
              [
                -73.88387561981133,
                40.56056575125234
              ],
              [
                -73.88386109809427,
                40.560572022625834
              ],
              [
                -73.88384669395788,
                40.56057850939231
              ],
              [
                -73.88382328178227,
                40.56059077805145
              ],
              [
                -73.88380001160759,
                40.56060247925819
              ],
              [
                -73.88377569269934,
                40.56061408993796
              ],
              [
                -73.8837524963408,
                40.56062400869173
              ],
              [
                -73.88374036229729,
                40.560628903695545
              ],
              [
                -73.8837275769731,
                40.560634546059624
              ],
              [
                -73.88370244284773,
                40.56064566888934
              ],
              [
                -73.8836801441922,
                40.56065623637554
              ],
              [
                -73.8836593548208,
                40.56066768860375
              ],
              [
                -73.8836406196835,
                40.56067903367992
              ],
              [
                -73.88362146087441,
                40.5606895867449
              ],
              [
                -73.88359905432155,
                40.560700919885115
              ],
              [
                -73.88357654153918,
                40.56071207281866
              ],
              [
                -73.88355510425974,
                40.56072231664832
              ],
              [
                -73.88353281763692,
                40.56073288411802
              ],
              [
                -73.88351285915205,
                40.56074209410457
              ],
              [
                -73.88349337258666,
                40.56075112564447
              ],
              [
                -73.88347557387985,
                40.56076041906728
              ],
              [
                -73.88345903958856,
                40.56076918186295
              ],
              [
                -73.88344315695069,
                40.56077723432076
              ],
              [
                -73.88343539982534,
                40.560781003912375
              ],
              [
                -73.88342590281917,
                40.56078561927138
              ],
              [
                -73.88340857891116,
                40.56079383226281
              ],
              [
                -73.88339201329119,
                40.5608006499951
              ],
              [
                -73.8833739347147,
                40.56080754861339
              ],
              [
                -73.8833522544527,
                40.56081551341683
              ],
              [
                -73.88333046640616,
                40.56082419010868
              ],
              [
                -73.8833094351043,
                40.56083235542637
              ],
              [
                -73.88328688961892,
                40.56084155403376
              ],
              [
                -73.88326552705627,
                40.56085009611661
              ],
              [
                -73.88324741562234,
                40.56085592148518
              ],
              [
                -73.88323280320115,
                40.560859725641855
              ],
              [
                -73.88321840479821,
                40.5608628620725
              ],
              [
                -73.88320353254964,
                40.56086614293299
              ],
              [
                -73.88318433715789,
                40.5608710404139
              ],
              [
                -73.8831633133852,
                40.56087450499138
              ],
              [
                -73.8831443819454,
                40.56087706033119
              ],
              [
                -73.88313764957451,
                40.56087765675655
              ],
              [
                -73.88310982640823,
                40.56088034862673
              ],
              [
                -73.88309826218214,
                40.560883299120626
              ],
              [
                -73.88307447541696,
                40.56088693091246
              ],
              [
                -73.88305032489849,
                40.56088942764908
              ],
              [
                -73.88302771502269,
                40.560888198577054
              ],
              [
                -73.88300900265625,
                40.56088694549323
              ],
              [
                -73.88297874541257,
                40.56088411440747
              ],
              [
                -73.88295837856984,
                40.560883102212905
              ],
              [
                -73.88293962972233,
                40.56088223657724
              ],
              [
                -73.88291645747425,
                40.560879025701716
              ],
              [
                -73.88289644771167,
                40.56087631209161
              ],
              [
                -73.88287626049771,
                40.56087470667477
              ],
              [
                -73.88284902255931,
                40.56087360787722
              ],
              [
                -73.882826527758,
                40.560874403984336
              ],
              [
                -73.88280046617912,
                40.560875395137984
              ],
              [
                -73.88277761838575,
                40.5608755618006
              ],
              [
                -73.88276124168169,
                40.56087519260242
              ],
              [
                -73.882741879012,
                40.56087412828752
              ],
              [
                -73.88272323540367,
                40.56087437981323
              ],
              [
                -73.88270678652788,
                40.56087483126251
              ],
              [
                -73.88268796496037,
                40.560875226508976
              ],
              [
                -73.88267018015226,
                40.56087652311708
              ],
              [
                -73.88265599601885,
                40.560878210395124
              ],
              [
                -73.88263861248006,
                40.56087978684804
              ],
              [
                -73.88261752366901,
                40.56088036857243
              ],
              [
                -73.88259575058406,
                40.56088059007429
              ],
              [
                -73.88257394133495,
                40.56088076596689
              ],
              [
                -73.88255540237668,
                40.56088121425248
              ],
              [
                -73.88253888350766,
                40.56088149422307
              ],
              [
                -73.88252057000595,
                40.56088139541342
              ],
              [
                -73.88250750252946,
                40.56087951777409
              ],
              [
                -73.88248668159774,
                40.56087529030038
              ],
              [
                -73.88247328322596,
                40.56087296216574
              ],
              [
                -73.88245337703015,
                40.56082575465579
              ],
              [
                -73.88243908710649,
                40.560793816204104
              ],
              [
                -73.8824378051493,
                40.56079095096849
              ],
              [
                -73.88242584458052,
                40.56077034444328
              ],
              [
                -73.8824195896144,
                40.56076840189722
              ],
              [
                -73.88239808836536,
                40.56076171554473
              ],
              [
                -73.88238549043537,
                40.560761027331964
              ],
              [
                -73.88237441679736,
                40.5607604308098
              ],
              [
                -73.88235923039828,
                40.560767961807684
              ],
              [
                -73.882356357125,
                40.56077687346529
              ],
              [
                -73.88235271249685,
                40.56078809926085
              ],
              [
                -73.88241602369004,
                40.56090951989842
              ],
              [
                -73.8824162120251,
                40.56091009589149
              ],
              [
                -73.88241586728287,
                40.560910897001584
              ],
              [
                -73.8823851748646,
                40.56092056471894
              ],
              [
                -73.88237074147563,
                40.560923205789265
              ],
              [
                -73.88235267047222,
                40.56092634873713
              ],
              [
                -73.8823345270254,
                40.56093033813642
              ],
              [
                -73.88230928304074,
                40.56093654354631
              ],
              [
                -73.88228206413865,
                40.56094482748039
              ],
              [
                -73.88225563517344,
                40.5609535886728
              ],
              [
                -73.8822294874467,
                40.56096380112383
              ],
              [
                -73.88220615093074,
                40.560972530964655
              ],
              [
                -73.88218306409571,
                40.56098167385033
              ],
              [
                -73.8821604122492,
                40.56099065634909
              ],
              [
                -73.88214913940051,
                40.560995256043086
              ],
              [
                -73.88213620094551,
                40.56100067257775
              ],
              [
                -73.8821216897349,
                40.56100694323954
              ],
              [
                -73.88210707368597,
                40.561012997008206
              ],
              [
                -73.88208199965021,
                40.56102331925481
              ],
              [
                -73.88205880591771,
                40.56103227370215
              ],
              [
                -73.88203604916899,
                40.561041057880765
              ],
              [
                -73.88200050406658,
                40.56105644618033
              ],
              [
                -73.88198752963675,
                40.56106269143172
              ],
              [
                -73.8819745651364,
                40.56106891005447
              ],
              [
                -73.88196126979652,
                40.561075515665074
              ],
              [
                -73.88194830614006,
                40.5610817512062
              ],
              [
                -73.88193490525215,
                40.561088167396484
              ],
              [
                -73.88192107865262,
                40.56109470946489
              ],
              [
                -73.88190695714788,
                40.561100819168836
              ],
              [
                -73.88189455988073,
                40.56110711777509
              ],
              [
                -73.88188268294091,
                40.56111344556265
              ],
              [
                -73.88187025034674,
                40.561119719502976
              ],
              [
                -73.88185623325523,
                40.56112690032671
              ],
              [
                -73.88184233483787,
                40.5611342958767
              ],
              [
                -73.88182947646317,
                40.56114073137591
              ],
              [
                -73.88180537902329,
                40.56115275516264
              ],
              [
                -73.88178192122048,
                40.56116404180101
              ],
              [
                -73.88175861847829,
                40.56117471543494
              ],
              [
                -73.88174658812723,
                40.561179837002754
              ],
              [
                -73.88173260920927,
                40.561186161437924
              ],
              [
                -73.88171737737864,
                40.5611929545025
              ],
              [
                -73.88170253568676,
                40.56119961243507
              ],
              [
                -73.88168899263773,
                40.56120577598255
              ],
              [
                -73.8816634567856,
                40.56121706045621
              ],
              [
                -73.88164018844435,
                40.56122776157641
              ],
              [
                -73.88161925961303,
                40.56123803304355
              ],
              [
                -73.88159656803151,
                40.5612497607023
              ],
              [
                -73.88157549384806,
                40.56126159875125
              ],
              [
                -73.88155470392729,
                40.56127305059788
              ],
              [
                -73.88153201189783,
                40.56128477003446
              ],
              [
                -73.88151915428139,
                40.56129124001098
              ],
              [
                -73.88149581334554,
                40.561302716187775
              ],
              [
                -73.88147293606477,
                40.56131322820114
              ],
              [
                -73.88145183346805,
                40.561321248191824
              ],
              [
                -73.88143293141147,
                40.5613266946783
              ],
              [
                -73.8814120452841,
                40.561332301617135
              ],
              [
                -73.88138983671979,
                40.56133831244136
              ],
              [
                -73.88136894897033,
                40.56134482889048
              ],
              [
                -73.88134871078579,
                40.56135157954342
              ],
              [
                -73.88132655862353,
                40.56135872542872
              ],
              [
                -73.8813042410815,
                40.56136548263687
              ],
              [
                -73.88128249221315,
                40.56137213387953
              ],
              [
                -73.88126707541672,
                40.561376286757216
              ],
              [
                -73.88125385752035,
                40.5613792896356
              ],
              [
                -73.88123683590801,
                40.56138291932948
              ],
              [
                -73.88121897799168,
                40.56138595476507
              ],
              [
                -73.88120008258839,
                40.561388132712075
              ],
              [
                -73.88118575602273,
                40.56139003543981
              ],
              [
                -73.88116962349282,
                40.561391504237854
              ],
              [
                -73.88114925192755,
                40.5613938156783
              ],
              [
                -73.88112995376133,
                40.5613961815006
              ],
              [
                -73.88111436629285,
                40.56139674234027
              ],
              [
                -73.88109935810499,
                40.56139741133172
              ],
              [
                -73.88108276623433,
                40.561399014671125
              ],
              [
                -73.88106581723753,
                40.56140182485871
              ],
              [
                -73.88104769888398,
                40.56140443564482
              ],
              [
                -73.88101282281468,
                40.561409262744846
              ],
              [
                -73.88099687882973,
                40.561411055549165
              ],
              [
                -73.88097801947205,
                40.561412350781936
              ],
              [
                -73.88095900626,
                40.56141431228609
              ],
              [
                -73.88093924873355,
                40.56141583224444
              ],
              [
                -73.88092118030214,
                40.56141754275675
              ],
              [
                -73.8809059096621,
                40.56141966075196
              ],
              [
                -73.88087985492963,
                40.561423452241804
              ],
              [
                -73.88085587891946,
                40.56142721857466
              ],
              [
                -73.88084176466914,
                40.561429536451236
              ],
              [
                -73.88082570091095,
                40.56143209438401
              ],
              [
                -73.88080875189407,
                40.56143490503621
              ],
              [
                -73.88079254859147,
                40.5614372477112
              ],
              [
                -73.8807759545183,
                40.56143970624409
              ],
              [
                -73.88075795457834,
                40.56144254277009
              ],
              [
                -73.88073916280933,
                40.561445837335576
              ],
              [
                -73.8807208433218,
                40.561449005568335
              ],
              [
                -73.88070388287355,
                40.56145181619311
              ],
              [
                -73.88068750016801,
                40.56145473446718
              ],
              [
                -73.88067122541169,
                40.56145686127235
              ],
              [
                -73.88065503286174,
                40.56145920444169
              ],
              [
                -73.88062471827277,
                40.56146194572576
              ],
              [
                -73.88059874050708,
                40.56146210752924
              ],
              [
                -73.88058466554952,
                40.56146258811493
              ],
              [
                -73.88056789802401,
                40.56146290389764
              ],
              [
                -73.88055180135724,
                40.56146444568915
              ],
              [
                -73.880535067378,
                40.5614657787434
              ],
              [
                -73.88052044713967,
                40.56146716006488
              ],
              [
                -73.88050508130863,
                40.56146906162663
              ],
              [
                -73.88047833156288,
                40.561472581741526
              ],
              [
                -73.88045748805683,
                40.561474063630364
              ],
              [
                -73.88044061582691,
                40.561473721563196
              ],
              [
                -73.88041490293793,
                40.56147157034811
              ],
              [
                -73.88039813555842,
                40.561425544993895
              ],
              [
                -73.88038652381758,
                40.561401912695366
              ],
              [
                -73.88037097709187,
                40.5613797162167
              ],
              [
                -73.88035535042575,
                40.56136965886822
              ],
              [
                -73.88033427510743,
                40.5613686191683
              ],
              [
                -73.88032213845268,
                40.5613680119593
              ],
              [
                -73.88029675110954,
                40.56137495524944
              ],
              [
                -73.88028171347875,
                40.56138518804984
              ],
              [
                -73.88027653869281,
                40.56139974385116
              ],
              [
                -73.88027612406724,
                40.561400913782805
              ],
              [
                -73.88028035877844,
                40.56142982547908
              ],
              [
                -73.88028328768723,
                40.5614426247999
              ],
              [
                -73.88029125467662,
                40.561457338709715
              ],
              [
                -73.88029971929609,
                40.56147151352379
              ],
              [
                -73.88030376241444,
                40.56148194580275
              ],
              [
                -73.88030219429331,
                40.56149380394436
              ],
              [
                -73.88029945871983,
                40.56149839325516
              ],
              [
                -73.88029663880127,
                40.561503101424314
              ],
              [
                -73.88028402529329,
                40.56151140831457
              ],
              [
                -73.88026267382189,
                40.56151992354838
              ],
              [
                -73.88024009597919,
                40.561527202301725
              ],
              [
                -73.88021646533025,
                40.56153629095068
              ],
              [
                -73.88019510089887,
                40.561544796776765
              ],
              [
                -73.88017659797245,
                40.561551865162215
              ],
              [
                -73.8801561087673,
                40.561560074361054
              ],
              [
                -73.88013158004648,
                40.56156944149531
              ],
              [
                -73.88010722783451,
                40.561578249426354
              ],
              [
                -73.88008209452889,
                40.56158838119541
              ],
              [
                -73.88005879307917,
                40.56159810090573
              ],
              [
                -73.88003818600468,
                40.56160612115427
              ],
              [
                -73.88001748502982,
                40.56161395182474
              ],
              [
                -73.87999703259649,
                40.56162123442571
              ],
              [
                -73.87998694116628,
                40.561625168205325
              ],
              [
                -73.87996253102727,
                40.56163379595053
              ],
              [
                -73.87994793990492,
                40.56163899473307
              ],
              [
                -73.87993381993422,
                40.56164408645201
              ],
              [
                -73.87991978375337,
                40.561649367063076
              ],
              [
                -73.8799052272357,
                40.561654619150794
              ],
              [
                -73.87989003151243,
                40.56166059245462
              ],
              [
                -73.87987504764712,
                40.561666996864815
              ],
              [
                -73.87986099695851,
                40.5616731326935
              ],
              [
                -73.87984807048495,
                40.561678550658215
              ],
              [
                -73.87983442103652,
                40.56168448821299
              ],
              [
                -73.87982058327975,
                40.56169106570084
              ],
              [
                -73.87980665020133,
                40.56169739045226
              ],
              [
                -73.87978478609632,
                40.56170873279764
              ],
              [
                -73.87977032503349,
                40.56171939769128
              ],
              [
                -73.87975883079315,
                40.56172967869506
              ],
              [
                -73.87974563530074,
                40.56173931778465
              ],
              [
                -73.87973011928682,
                40.56174613726037
              ],
              [
                -73.87970635043087,
                40.561753091329045
              ],
              [
                -73.87968186281925,
                40.561758829210376
              ],
              [
                -73.87965868676253,
                40.56176399148785
              ],
              [
                -73.87963567395806,
                40.561770469878816
              ],
              [
                -73.87961485607637,
                40.56177717388321
              ],
              [
                -73.87959637702109,
                40.56178335948525
              ],
              [
                -73.87957863349104,
                40.561788032893034
              ],
              [
                -73.87956142278068,
                40.56179177907632
              ],
              [
                -73.87954475732901,
                40.56179488737245
              ],
              [
                -73.87954024329774,
                40.56179666565886
              ],
              [
                -73.87937898313004,
                40.56183692908744
              ],
              [
                -73.87920381335462,
                40.56191702682255
              ],
              [
                -73.87903836918255,
                40.56199864749558
              ],
              [
                -73.87893541975764,
                40.56204297968112
              ],
              [
                -73.87886213323743,
                40.56207351146075
              ],
              [
                -73.87874838460596,
                40.56212145307931
              ],
              [
                -73.87863332705665,
                40.562162250565585
              ],
              [
                -73.87849030785846,
                40.56221617598844
              ],
              [
                -73.8783713531596,
                40.5622630944845
              ],
              [
                -73.87827637443897,
                40.56230015802658
              ],
              [
                -73.87819091216764,
                40.562331361000794
              ],
              [
                -73.87810489087754,
                40.56237092892275
              ],
              [
                -73.87805690415122,
                40.56239632678013
              ],
              [
                -73.87797558561535,
                40.562427443359745
              ],
              [
                -73.87789681427573,
                40.562460598245124
              ],
              [
                -73.87780227013106,
                40.56249936530094
              ],
              [
                -73.87769976955727,
                40.56253606905335
              ],
              [
                -73.87761725904532,
                40.56256691405185
              ],
              [
                -73.87752204544438,
                40.562602824449314
              ],
              [
                -73.87740724013115,
                40.56264033554793
              ],
              [
                -73.8773836474245,
                40.562646757919154
              ],
              [
                -73.87737403170412,
                40.562649017013236
              ],
              [
                -73.87736084534804,
                40.562628263740656
              ],
              [
                -73.87734281968088,
                40.562632296370296
              ],
              [
                -73.87735635919601,
                40.56265320331182
              ],
              [
                -73.87732946042871,
                40.562660010140895
              ],
              [
                -73.877298380413,
                40.562667937460084
              ],
              [
                -73.87728427215485,
                40.56267300139095
              ],
              [
                -73.87726906383583,
                40.562679000636635
              ],
              [
                -73.87725221358002,
                40.56268579037451
              ],
              [
                -73.8772362246945,
                40.562693147951535
              ],
              [
                -73.87722076596584,
                40.56270056992197
              ],
              [
                -73.87720520197054,
                40.56270777599936
              ],
              [
                -73.87718888135463,
                40.56271550328864
              ],
              [
                -73.8771717818916,
                40.56272269833807
              ],
              [
                -73.87715607666624,
                40.562729650617484
              ],
              [
                -73.87714098654575,
                40.56273586625368
              ],
              [
                -73.87712614278729,
                40.5627424961178
              ],
              [
                -73.87711007301019,
                40.56274879312669
              ],
              [
                -73.87706415438072,
                40.56276707762033
              ],
              [
                -73.87704837900323,
                40.562772986266374
              ],
              [
                -73.8770082670926,
                40.56278647856263
              ],
              [
                -73.87699432547852,
                40.56279096616609
              ],
              [
                -73.87696544963367,
                40.56280002222659
              ],
              [
                -73.8769513298522,
                40.56280511190377
              ],
              [
                -73.876924708713,
                40.56281545817173
              ],
              [
                -73.87689802684656,
                40.56282561505956
              ],
              [
                -73.87687360302908,
                40.562835249993334
              ],
              [
                -73.876829160556,
                40.56285288059863
              ],
              [
                -73.87680946313873,
                40.56286059432861
              ],
              [
                -73.87678968296791,
                40.56286779582756
              ],
              [
                -73.8767730849189,
                40.56287213681385
              ],
              [
                -73.8767592984439,
                40.56287562490443
              ],
              [
                -73.87674369135848,
                40.56284208140835
              ],
              [
                -73.87672645978107,
                40.562844521751686
              ],
              [
                -73.8767434906596,
                40.562880615074945
              ],
              [
                -73.87673545637608,
                40.56288329798798
              ],
              [
                -73.8767132289167,
                40.562892135457105
              ],
              [
                -73.87669317480369,
                40.56290111966584
              ],
              [
                -73.87667729045369,
                40.56290914438131
              ],
              [
                -73.87666276170928,
                40.56291774635045
              ],
              [
                -73.87663304695474,
                40.56293423925047
              ],
              [
                -73.87662032765036,
                40.56294139352163
              ],
              [
                -73.87660652200594,
                40.56294942029262
              ],
              [
                -73.8765944878989,
                40.56295690131019
              ],
              [
                -73.87657784435744,
                40.562966347524764
              ],
              [
                -73.87656188792157,
                40.56297473166596
              ],
              [
                -73.87654600306143,
                40.56298325208349
              ],
              [
                -73.87653101431876,
                40.562991961095754
              ],
              [
                -73.87650201161124,
                40.56300598835245
              ],
              [
                -73.87647953341072,
                40.56301685998949
              ],
              [
                -73.87646000618406,
                40.56302776160097
              ],
              [
                -73.87644818600278,
                40.56303453083122
              ],
              [
                -73.87642834027622,
                40.56304529706564
              ],
              [
                -73.87641778562529,
                40.563050392188096
              ],
              [
                -73.87639149048142,
                40.5630626557275
              ],
              [
                -73.87637686168809,
                40.56306889923476
              ],
              [
                -73.87633039968337,
                40.56308655327291
              ],
              [
                -73.87631318582261,
                40.56309172129478
              ],
              [
                -73.87625899418441,
                40.563107169993465
              ],
              [
                -73.87623177412057,
                40.5631154343994
              ],
              [
                -73.87619188002725,
                40.56312557738811
              ],
              [
                -73.87616566871453,
                40.56313061825068
              ],
              [
                -73.87612944192581,
                40.56313676606582
              ],
              [
                -73.87610050107055,
                40.56314306597241
              ],
              [
                -73.87608271947352,
                40.563148512787194
              ],
              [
                -73.87604612941719,
                40.563070794058625
              ],
              [
                -73.87603074813882,
                40.56307447802598
              ],
              [
                -73.87606765576666,
                40.56315364740811
              ],
              [
                -73.87603797533089,
                40.56316358457966
              ],
              [
                -73.87599724911752,
                40.563176660720956
              ],
              [
                -73.87597470538526,
                40.56318430003463
              ],
              [
                -73.87595295265639,
                40.56319171487047
              ],
              [
                -73.87590988032137,
                40.563209174211536
              ],
              [
                -73.8758855622984,
                40.563218703170655
              ],
              [
                -73.87583528292512,
                40.56323797390257
              ],
              [
                -73.87580575386734,
                40.56324963103948
              ],
              [
                -73.87577452358008,
                40.56326145704173
              ],
              [
                -73.8757454201404,
                40.56327221331276
              ],
              [
                -73.87571355454236,
                40.563282742432655
              ],
              [
                -73.87568355361338,
                40.56329344377209
              ],
              [
                -73.87565650831084,
                40.56330286100389
              ],
              [
                -73.8756290273301,
                40.563311368740564
              ],
              [
                -73.87559506972467,
                40.563322750969796
              ],
              [
                -73.87555952995204,
                40.56333453538937
              ],
              [
                -73.87552758218385,
                40.56334468625361
              ],
              [
                -73.87551004856199,
                40.56335021450055
              ],
              [
                -73.8754743914437,
                40.5633611803831
              ],
              [
                -73.87545505162277,
                40.56336697672159
              ],
              [
                -73.87541872053987,
                40.56337805929456
              ],
              [
                -73.87540121346792,
                40.56338253412572
              ],
              [
                -73.87536087323856,
                40.56329585044121
              ],
              [
                -73.87534551646789,
                40.56329906659989
              ],
              [
                -73.87538638520446,
                40.56338716448029
              ],
              [
                -73.87535587961015,
                40.56339632592228
              ],
              [
                -73.87534115876268,
                40.56340130769768
              ],
              [
                -73.87530945564228,
                40.563412988649645
              ],
              [
                -73.87529181474282,
                40.56341936327524
              ],
              [
                -73.8752717516301,
                40.56342650925574
              ],
              [
                -73.87524985801689,
                40.56343364503573
              ],
              [
                -73.87522720738546,
                40.563441031451525
              ],
              [
                -73.87517848346008,
                40.5634561347724
              ],
              [
                -73.87512975898886,
                40.563471283137496
              ],
              [
                -73.87508297419413,
                40.56348490136333
              ],
              [
                -73.87504255093543,
                40.56349364684841
              ],
              [
                -73.87500676147573,
                40.563499065695446
              ],
              [
                -73.8749561458776,
                40.56350301691279
              ],
              [
                -73.87489963722413,
                40.56350693588989
              ],
              [
                -73.87484278763579,
                40.56351648246643
              ],
              [
                -73.87477351950852,
                40.56352994271124
              ],
              [
                -73.87473658078231,
                40.56353824072977
              ],
              [
                -73.87468471054004,
                40.56353074615862
              ],
              [
                -73.87466540729139,
                40.5634976665944
              ],
              [
                -73.87464481920868,
                40.56350146330548
              ],
              [
                -73.8746506447683,
                40.56351135643863
              ],
              [
                -73.87466953104926,
                40.56355873657037
              ],
              [
                -73.8746666375388,
                40.563577824112095
              ],
              [
                -73.87465199208482,
                40.56359853743912
              ],
              [
                -73.87464053117007,
                40.563609196751464
              ],
              [
                -73.87459337507963,
                40.56363116305625
              ],
              [
                -73.87455252668062,
                40.56364618474654
              ],
              [
                -73.87452022105322,
                40.563658170564594
              ],
              [
                -73.87447106976656,
                40.56367456058566
              ],
              [
                -73.87443483573853,
                40.56368431930004
              ],
              [
                -73.87437714732535,
                40.56369965456142
              ],
              [
                -73.87431230105985,
                40.56371723389802
              ],
              [
                -73.87426973237943,
                40.56373022733148
              ],
              [
                -73.8741936444117,
                40.56375361160227
              ],
              [
                -73.8741500028522,
                40.5637655322951
              ],
              [
                -73.87412371693783,
                40.56377252684616
              ],
              [
                -73.87407163775148,
                40.563787599215736
              ],
              [
                -73.87402612883245,
                40.56380039114734
              ],
              [
                -73.87399917445005,
                40.563805395016864
              ],
              [
                -73.87397732709955,
                40.56375654413272
              ],
              [
                -73.8739623468653,
                40.563760706393296
              ],
              [
                -73.87398333570002,
                40.563807476287614
              ],
              [
                -73.87392010655763,
                40.563830469397
              ],
              [
                -73.87384821426248,
                40.563858414354414
              ],
              [
                -73.87379999120962,
                40.563876668710094
              ],
              [
                -73.87375288230227,
                40.56389248470005
              ],
              [
                -73.87367333727143,
                40.56391432438933
              ],
              [
                -73.87361039032855,
                40.563932256728805
              ],
              [
                -73.87356559873493,
                40.56394629217149
              ],
              [
                -73.87352028757073,
                40.563961037853815
              ],
              [
                -73.87347711337766,
                40.563975425850586
              ],
              [
                -73.87344692115289,
                40.563987144306815
              ],
              [
                -73.87341959004098,
                40.56399718206549
              ],
              [
                -73.87333383849683,
                40.56402856991333
              ],
              [
                -73.87329889953693,
                40.564040751844466
              ],
              [
                -73.87321952830246,
                40.56406390618561
              ],
              [
                -73.87314057697263,
                40.56407806625709
              ],
              [
                -73.87307650520094,
                40.56408433430238
              ],
              [
                -73.87302519640879,
                40.56409210308157
              ],
              [
                -73.8729839745517,
                40.56409847926002
              ],
              [
                -73.87294196168004,
                40.56410425948249
              ],
              [
                -73.87288655736036,
                40.564110483232284
              ],
              [
                -73.87283461996125,
                40.56411379359597
              ],
              [
                -73.87277678060882,
                40.56411502622753
              ],
              [
                -73.8727606175667,
                40.56411396360756
              ],
              [
                -73.87274925481708,
                40.56410955604112
              ],
              [
                -73.87273512756167,
                40.56409968929783
              ],
              [
                -73.8727238972499,
                40.564087456387895
              ],
              [
                -73.87270809641154,
                40.56405759510706
              ],
              [
                -73.87268736318256,
                40.56403769755697
              ],
              [
                -73.87266787468027,
                40.56403425482678
              ],
              [
                -73.87266353398269,
                40.56402605512359
              ],
              [
                -73.87260883087148,
                40.563931033341184
              ],
              [
                -73.87256879318456,
                40.56386147776903
              ],
              [
                -73.87239869822375,
                40.56357077001371
              ],
              [
                -73.87237878047286,
                40.563575116561516
              ],
              [
                -73.87258749014757,
                40.563938637473065
              ],
              [
                -73.87264671264475,
                40.564041795506384
              ],
              [
                -73.87263971280453,
                40.56408418402674
              ],
              [
                -73.8725776351144,
                40.564097506007265
              ],
              [
                -73.87250831174484,
                40.56412128483287
              ],
              [
                -73.87232617089947,
                40.564189540250034
              ],
              [
                -73.87187304106037,
                40.564376480329514
              ],
              [
                -73.87170264487446,
                40.56445901269151
              ],
              [
                -73.87160225040432,
                40.56450287240943
              ],
              [
                -73.87144543470181,
                40.56455926799006
              ],
              [
                -73.87136289489302,
                40.564596593376976
              ],
              [
                -73.87128982771225,
                40.564626210380986
              ],
              [
                -73.87124151827355,
                40.56464577884291
              ],
              [
                -73.87116249832623,
                40.564676262426104
              ],
              [
                -73.87111211955774,
                40.56469089341755
              ],
              [
                -73.87099970074951,
                40.564736415587
              ],
              [
                -73.87091316206462,
                40.56476458551072
              ],
              [
                -73.87079074288935,
                40.56479094187673
              ],
              [
                -73.87066630743064,
                40.56481525968412
              ],
              [
                -73.87065866951325,
                40.56481436836889
              ],
              [
                -73.87064565391573,
                40.56480940973803
              ],
              [
                -73.87064094150604,
                40.56480994521735
              ],
              [
                -73.87053524869275,
                40.564626011194136
              ],
              [
                -73.87027588464693,
                40.56417465359739
              ],
              [
                -73.87025285397128,
                40.56418310191552
              ],
              [
                -73.87051611850829,
                40.56463238223506
              ],
              [
                -73.87062214815259,
                40.56481330949411
              ],
              [
                -73.8704872406497,
                40.564855428594726
              ],
              [
                -73.87039480972099,
                40.56489591950435
              ],
              [
                -73.87031973070592,
                40.56493365714443
              ],
              [
                -73.87003028797032,
                40.56506022365147
              ],
              [
                -73.86988639456341,
                40.565113425873356
              ],
              [
                -73.86968095022635,
                40.565187927537636
              ],
              [
                -73.86954872397517,
                40.56524111509826
              ],
              [
                -73.86948488954816,
                40.565270651978494
              ],
              [
                -73.86924996432191,
                40.56538476045332
              ],
              [
                -73.8691282463427,
                40.56541962586067
              ],
              [
                -73.86905175794233,
                40.56544086306031
              ],
              [
                -73.86893605282155,
                40.56547413219299
              ],
              [
                -73.8686901563823,
                40.56552808931249
              ],
              [
                -73.868623218271,
                40.56553800877494
              ],
              [
                -73.86854986356495,
                40.56554469691094
              ],
              [
                -73.86852753205115,
                40.5655454634337
              ],
              [
                -73.86840555232219,
                40.56533537549579
              ],
              [
                -73.8682511755017,
                40.56506948945963
              ],
              [
                -73.86823181879357,
                40.56507704077842
              ],
              [
                -73.86838610254688,
                40.56534185454269
              ],
              [
                -73.86848953699123,
                40.56551938546317
              ],
              [
                -73.8684869484588,
                40.565527010301565
              ],
              [
                -73.86848241215057,
                40.56552766333251
              ],
              [
                -73.86846705188553,
                40.56552062121699
              ],
              [
                -73.86845140122098,
                40.56551692031767
              ],
              [
                -73.86843272443515,
                40.56551492592719
              ],
              [
                -73.8683950442179,
                40.56551438821329
              ],
              [
                -73.86837690378205,
                40.565516014916156
              ],
              [
                -73.86836484239736,
                40.56551890991522
              ],
              [
                -73.86832677099366,
                40.56553115811216
              ],
              [
                -73.86823531210628,
                40.56556896674802
              ],
              [
                -73.86817969316189,
                40.56559372097312
              ],
              [
                -73.86809782439514,
                40.565623947214014
              ],
              [
                -73.86807455478316,
                40.565633745279015
              ],
              [
                -73.86797245198414,
                40.565685119440204
              ],
              [
                -73.8679236704754,
                40.56570957616081
              ],
              [
                -73.86788155843954,
                40.5657287727233
              ],
              [
                -73.86785317398672,
                40.5657399059368
              ],
              [
                -73.86775063505787,
                40.5657728370419
              ],
              [
                -73.86770878117876,
                40.56578742257893
              ],
              [
                -73.86768770930917,
                40.56579612460348
              ],
              [
                -73.86765236683206,
                40.565813733492824
              ],
              [
                -73.86747429909371,
                40.56589584615776
              ],
              [
                -73.86742577097618,
                40.565916871947785
              ],
              [
                -73.86738139335077,
                40.565935398105374
              ],
              [
                -73.86732622021515,
                40.56595559545228
              ],
              [
                -73.8671429879069,
                40.56602136563421
              ],
              [
                -73.86709181078301,
                40.56603859651582
              ],
              [
                -73.86702972224312,
                40.56605622921264
              ],
              [
                -73.86696357079242,
                40.566068741981205
              ],
              [
                -73.86688401059845,
                40.56608397627607
              ],
              [
                -73.86686111965143,
                40.566086471070065
              ],
              [
                -73.86679216232595,
                40.56608480628897
              ],
              [
                -73.86674197887773,
                40.56608343330635
              ],
              [
                -73.86668580404546,
                40.5660847378467
              ],
              [
                -73.86663005715053,
                40.566090111654006
              ],
              [
                -73.86654955895546,
                40.56595352538426
              ],
              [
                -73.86630941232445,
                40.56554606412483
              ],
              [
                -73.86630105072973,
                40.565552214298094
              ],
              [
                -73.86653832191413,
                40.56595727655682
              ],
              [
                -73.86661720554638,
                40.566091942910326
              ],
              [
                -73.86653974501574,
                40.566107909500026
              ],
              [
                -73.86644810921857,
                40.56613891596793
              ],
              [
                -73.86636470299644,
                40.5661813321767
              ],
              [
                -73.86586350645223,
                40.566432968038185
              ],
              [
                -73.86579602875463,
                40.566470135127446
              ],
              [
                -73.86572546406634,
                40.56650378580238
              ],
              [
                -73.86565210793115,
                40.566533777667786
              ],
              [
                -73.86557630398882,
                40.566559976593844
              ],
              [
                -73.86553757257411,
                40.56657161043734
              ],
              [
                -73.86537824806315,
                40.56660813782069
              ],
              [
                -73.86525534672059,
                40.56662474418802
              ],
              [
                -73.86508926857455,
                40.56663218475605
              ],
              [
                -73.8649546750932,
                40.56663147760088
              ],
              [
                -73.8648457640142,
                40.566637761374906
              ],
              [
                -73.86482648734001,
                40.566640845867965
              ],
              [
                -73.86475323748432,
                40.566652574352474
              ],
              [
                -73.86459949094905,
                40.566692997769515
              ],
              [
                -73.86449981054494,
                40.5667240744525
              ],
              [
                -73.86402791878817,
                40.56688561150596
              ],
              [
                -73.86387639829695,
                40.56693428525781
              ],
              [
                -73.8637303990016,
                40.56697901282819
              ],
              [
                -73.86355862677969,
                40.567027194390754
              ],
              [
                -73.86349306360215,
                40.567034311734034
              ],
              [
                -73.8634236098639,
                40.567031743533946
              ],
              [
                -73.86335482591628,
                40.56701963007009
              ],
              [
                -73.86319883665384,
                40.56697222108388
              ],
              [
                -73.86300482943778,
                40.56687595773083
              ],
              [
                -73.86295920590028,
                40.566815983072175
              ],
              [
                -73.86277353301229,
                40.56657186205274
              ],
              [
                -73.8627141083374,
                40.56650509874634
              ],
              [
                -73.86270424269793,
                40.5665019973689
              ],
              [
                -73.86269356691223,
                40.56650219217743
              ],
              [
                -73.86268391251235,
                40.56650565604354
              ],
              [
                -73.86267607982793,
                40.5665130138054
              ],
              [
                -73.86267372829568,
                40.56651956610252
              ],
              [
                -73.86286892288632,
                40.56683057177313
              ],
              [
                -73.86308767499982,
                40.567597728999914
              ],
              [
                -73.86316930199989,
                40.56773927199992
              ],
              [
                -73.86406802399988,
                40.569297978999934
              ],
              [
                -73.86410310099991,
                40.56935460999994
              ],
              [
                -73.86454176099993,
                40.56924292199992
              ],
              [
                -73.86522555399998,
                40.57045847199989
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000291203927662,
        "objectid": 28,
        "shape_leng": 0.097960782214,
        "location_id": 28,
        "zone": "Briarwood/Jamaica Hills",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.79240413399991,
                40.71619304099993
              ],
              [
                -73.79234087599984,
                40.71646287399992
              ],
              [
                -73.79226766099997,
                40.71728392699988
              ],
              [
                -73.79224175599992,
                40.71742717899984
              ],
              [
                -73.7921849289999,
                40.71761139399989
              ],
              [
                -73.79210873799991,
                40.717792114999945
              ],
              [
                -73.79210870199984,
                40.71779218799989
              ],
              [
                -73.79201376599997,
                40.717967686999955
              ],
              [
                -73.7919010649999,
                40.718136366999936
              ],
              [
                -73.79173290199986,
                40.718319928999904
              ],
              [
                -73.79162293699987,
                40.71842251499991
              ],
              [
                -73.791502837,
                40.71851882299991
              ],
              [
                -73.79137357500004,
                40.718607991999875
              ],
              [
                -73.79123627699988,
                40.71868931099989
              ],
              [
                -73.79089607099996,
                40.7188759379999
              ],
              [
                -73.79076914099981,
                40.71913418899989
              ],
              [
                -73.79073248899982,
                40.719208756999876
              ],
              [
                -73.791417731,
                40.71920231099992
              ],
              [
                -73.79155692199994,
                40.71920099499993
              ],
              [
                -73.79176986799989,
                40.71917860699989
              ],
              [
                -73.79211141299997,
                40.71913886399991
              ],
              [
                -73.79249599699979,
                40.719067435999904
              ],
              [
                -73.79261566399995,
                40.71904520999994
              ],
              [
                -73.79309042899989,
                40.71893343799996
              ],
              [
                -73.79315493500002,
                40.71891757899985
              ],
              [
                -73.79399335100001,
                40.71864514099988
              ],
              [
                -73.7944373599998,
                40.71850085799991
              ],
              [
                -73.79480794899995,
                40.71837515899991
              ],
              [
                -73.79563775099989,
                40.71807616099995
              ],
              [
                -73.79653978199998,
                40.717786748999885
              ],
              [
                -73.79741025099996,
                40.717532907999946
              ],
              [
                -73.79802996199992,
                40.71735242599988
              ],
              [
                -73.79912568499999,
                40.717034345999885
              ],
              [
                -73.79959535799995,
                40.71689904299997
              ],
              [
                -73.80004693799998,
                40.716781906999906
              ],
              [
                -73.8002167269999,
                40.71673785899986
              ],
              [
                -73.80092734399993,
                40.71660393299992
              ],
              [
                -73.80188321,
                40.71640881099992
              ],
              [
                -73.803084842,
                40.716220161999935
              ],
              [
                -73.80313952600001,
                40.716213906999954
              ],
              [
                -73.80319837999987,
                40.7162025249999
              ],
              [
                -73.80394838899987,
                40.716102911999926
              ],
              [
                -73.80464476399993,
                40.716034396999866
              ],
              [
                -73.80494148499987,
                40.715996230999956
              ],
              [
                -73.80579237899985,
                40.71594264899989
              ],
              [
                -73.80670677799995,
                40.71588523999989
              ],
              [
                -73.80780927699995,
                40.715853895999906
              ],
              [
                -73.81022434999987,
                40.71591266799988
              ],
              [
                -73.81114665699984,
                40.71590720599988
              ],
              [
                -73.81151841199983,
                40.7159049969999
              ],
              [
                -73.81291344199987,
                40.715896708999885
              ],
              [
                -73.81364203999982,
                40.71589299199993
              ],
              [
                -73.81524262499985,
                40.715856831999936
              ],
              [
                -73.81595932699985,
                40.71584062699988
              ],
              [
                -73.8162894489999,
                40.71583316699989
              ],
              [
                -73.8163608039998,
                40.71583237099991
              ],
              [
                -73.81643940199992,
                40.7158318299999
              ],
              [
                -73.81680638099989,
                40.71582792999988
              ],
              [
                -73.81720417899986,
                40.715840727999925
              ],
              [
                -73.81743591099992,
                40.71585731399992
              ],
              [
                -73.81767650099987,
                40.71588471999992
              ],
              [
                -73.817780212,
                40.71590104799987
              ],
              [
                -73.81789512299984,
                40.71591837099992
              ],
              [
                -73.81828452900005,
                40.7160037139999
              ],
              [
                -73.81852868899988,
                40.71608211599988
              ],
              [
                -73.81875295799986,
                40.71617287499986
              ],
              [
                -73.81931944099983,
                40.71640861399996
              ],
              [
                -73.81948747199984,
                40.71647859799984
              ],
              [
                -73.82018883899984,
                40.716735223999855
              ],
              [
                -73.82051599199986,
                40.716811990999894
              ],
              [
                -73.82100705300005,
                40.71687995099993
              ],
              [
                -73.8218741769999,
                40.71691711799987
              ],
              [
                -73.82346833799978,
                40.71673105099993
              ],
              [
                -73.825090876,
                40.71645279299986
              ],
              [
                -73.82524731699984,
                40.716455444999895
              ],
              [
                -73.82561290699982,
                40.71620989799991
              ],
              [
                -73.82566233799984,
                40.71615263799994
              ],
              [
                -73.82591945199992,
                40.71598987599993
              ],
              [
                -73.8258906989998,
                40.715938525999924
              ],
              [
                -73.82573653299988,
                40.71570422199993
              ],
              [
                -73.82570967299992,
                40.71566664699994
              ],
              [
                -73.82567283299986,
                40.715615109999874
              ],
              [
                -73.82559716399989,
                40.715514336999924
              ],
              [
                -73.8253709409999,
                40.71526515499992
              ],
              [
                -73.8251664649999,
                40.71502786599996
              ],
              [
                -73.82497234899996,
                40.71480260499994
              ],
              [
                -73.82471749499993,
                40.71450684999994
              ],
              [
                -73.82467827499991,
                40.7144485109999
              ],
              [
                -73.82428940599978,
                40.71387006599987
              ],
              [
                -73.82398559799991,
                40.71321593899988
              ],
              [
                -73.82385072099994,
                40.71293320599995
              ],
              [
                -73.82363860699984,
                40.71266664399989
              ],
              [
                -73.8234526099998,
                40.71245950199993
              ],
              [
                -73.82320247499996,
                40.712205947999976
              ],
              [
                -73.82288529599995,
                40.71192805799993
              ],
              [
                -73.82252678099998,
                40.71165488499987
              ],
              [
                -73.82176010399989,
                40.71099293099995
              ],
              [
                -73.82098519099996,
                40.710323844999934
              ],
              [
                -73.82081877299993,
                40.71017277699984
              ],
              [
                -73.82065280899984,
                40.7099897069999
              ],
              [
                -73.82045236499991,
                40.70977454899991
              ],
              [
                -73.82023145199997,
                40.70945750299996
              ],
              [
                -73.81994399099985,
                40.70901972299989
              ],
              [
                -73.81955363300001,
                40.7084252289999
              ],
              [
                -73.81921543099992,
                40.70785211599987
              ],
              [
                -73.81916136299994,
                40.707760331999914
              ],
              [
                -73.81910729599991,
                40.707668538999904
              ],
              [
                -73.8190501699998,
                40.70757022099986
              ],
              [
                -73.81899305699982,
                40.70747190299993
              ],
              [
                -73.81892102499984,
                40.70734990599987
              ],
              [
                -73.81861275299995,
                40.706853399999915
              ],
              [
                -73.81828566399993,
                40.70630284999987
              ],
              [
                -73.81822457299995,
                40.70617766199991
              ],
              [
                -73.81809905199992,
                40.70597371499988
              ],
              [
                -73.81738330599997,
                40.70460389799992
              ],
              [
                -73.81735529099984,
                40.704550139999846
              ],
              [
                -73.81709738799995,
                40.704029800999876
              ],
              [
                -73.8169748309999,
                40.70374317299995
              ],
              [
                -73.81669961199981,
                40.703156092999954
              ],
              [
                -73.81658976900003,
                40.70291543599996
              ],
              [
                -73.81637824499992,
                40.702451963999884
              ],
              [
                -73.81628916400003,
                40.702454064999884
              ],
              [
                -73.8159402059999,
                40.70245890199989
              ],
              [
                -73.81531593299998,
                40.7024873309999
              ],
              [
                -73.81496204599993,
                40.70249848699989
              ],
              [
                -73.81429413399998,
                40.70253110799994
              ],
              [
                -73.81398666699994,
                40.70254206699993
              ],
              [
                -73.81356470599994,
                40.70252849799991
              ],
              [
                -73.81314101299998,
                40.702499551999914
              ],
              [
                -73.81301882900003,
                40.70249515699991
              ],
              [
                -73.81193002199988,
                40.70234083599995
              ],
              [
                -73.81152856899989,
                40.70227287499991
              ],
              [
                -73.81163157099994,
                40.70305215999991
              ],
              [
                -73.81171288899998,
                40.70366220199985
              ],
              [
                -73.81177296699997,
                40.704180950999906
              ],
              [
                -73.81180202699996,
                40.70436635199991
              ],
              [
                -73.81190436299998,
                40.7051239399999
              ],
              [
                -73.81201248599982,
                40.70594494499994
              ],
              [
                -73.80934800399993,
                40.70668543899991
              ],
              [
                -73.80694856299992,
                40.70736709999989
              ],
              [
                -73.80658872800001,
                40.706633909999866
              ],
              [
                -73.80613695999992,
                40.70569149199989
              ],
              [
                -73.8056477309999,
                40.70467172799995
              ],
              [
                -73.80357251599989,
                40.705233230999966
              ],
              [
                -73.80265548599988,
                40.70548042999997
              ],
              [
                -73.80165725599993,
                40.70576147499985
              ],
              [
                -73.80222783399978,
                40.706749312999925
              ],
              [
                -73.80278721399985,
                40.707715802999864
              ],
              [
                -73.80302414799988,
                40.70813965699997
              ],
              [
                -73.80278213499996,
                40.70827267099986
              ],
              [
                -73.80255069099992,
                40.70841720299988
              ],
              [
                -73.80233119999987,
                40.708572470999904
              ],
              [
                -73.80212493399992,
                40.7087376039999
              ],
              [
                -73.80193297099994,
                40.70891159599995
              ],
              [
                -73.80159590599997,
                40.70954842499992
              ],
              [
                -73.80151152599983,
                40.70962160799997
              ],
              [
                -73.80151145499995,
                40.70962166199995
              ],
              [
                -73.80151137199988,
                40.709621715999916
              ],
              [
                -73.80141430599986,
                40.7096867729999
              ],
              [
                -73.8014141629999,
                40.7096868729999
              ],
              [
                -73.80141399799987,
                40.70968695199988
              ],
              [
                -73.80130625,
                40.709742148999965
              ],
              [
                -73.80130615599984,
                40.709742192999926
              ],
              [
                -73.80130606099976,
                40.70974222899992
              ],
              [
                -73.80119037199992,
                40.70978616299992
              ],
              [
                -73.80119015899984,
                40.709786243999915
              ],
              [
                -73.80118994599997,
                40.7097862969999
              ],
              [
                -73.80106920299994,
                40.70981821099986
              ],
              [
                -73.79985783199994,
                40.71025256299989
              ],
              [
                -73.79870150699992,
                40.71066337699988
              ],
              [
                -73.79807226500003,
                40.70963987799994
              ],
              [
                -73.79776810199988,
                40.709143187999906
              ],
              [
                -73.79731400799989,
                40.709270198999874
              ],
              [
                -73.79639418099987,
                40.70953157899987
              ],
              [
                -73.79561668999996,
                40.709753078999896
              ],
              [
                -73.79473052399993,
                40.71001246199997
              ],
              [
                -73.79389609999994,
                40.71035232899985
              ],
              [
                -73.79303800099989,
                40.71071107499989
              ],
              [
                -73.79300721999992,
                40.711549469999916
              ],
              [
                -73.79300604199986,
                40.71176522199993
              ],
              [
                -73.79302654199988,
                40.71198136399988
              ],
              [
                -73.79306872799985,
                40.71219599599993
              ],
              [
                -73.79313224999994,
                40.71240723599991
              ],
              [
                -73.79321635699988,
                40.71261327999989
              ],
              [
                -73.79350163599987,
                40.71321339799988
              ],
              [
                -73.79356328499998,
                40.71335799599988
              ],
              [
                -73.79360704399981,
                40.713508469999915
              ],
              [
                -73.79360705599984,
                40.71350852299992
              ],
              [
                -73.79360706699993,
                40.713508577999924
              ],
              [
                -73.79363166700001,
                40.713663041999894
              ],
              [
                -73.79363635499982,
                40.71381920899989
              ],
              [
                -73.79363636699993,
                40.7138193969999
              ],
              [
                -73.79363634199993,
                40.71381958599996
              ],
              [
                -73.79362091299996,
                40.713975346999895
              ],
              [
                -73.79358570299998,
                40.71412867799988
              ],
              [
                -73.7935316789999,
                40.71427716599994
              ],
              [
                -73.7935316439998,
                40.71427725599991
              ],
              [
                -73.7935315959999,
                40.714277345999925
              ],
              [
                -73.79346012399989,
                40.714419191999895
              ],
              [
                -73.79314903799985,
                40.71493342999985
              ],
              [
                -73.7926872159999,
                40.71568003299994
              ],
              [
                -73.79240413399991,
                40.71619304099993
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000147416802448,
        "objectid": 33,
        "shape_leng": 0.0532702931967,
        "location_id": 33,
        "zone": "Brooklyn Heights",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.99619227264343,
                40.70337714093203
              ],
              [
                -73.99813879899439,
                40.701518788248414
              ],
              [
                -73.99780162786317,
                40.70131939476619
              ],
              [
                -73.99776011033609,
                40.70135941487679
              ],
              [
                -73.99752763273973,
                40.701228816958704
              ],
              [
                -73.9968218908586,
                40.70099078282786
              ],
              [
                -73.99669768997464,
                40.70087697691713
              ],
              [
                -73.99724462871697,
                40.700141409897164
              ],
              [
                -73.9971758435588,
                40.70010599146384
              ],
              [
                -73.99718148446391,
                40.699994484968094
              ],
              [
                -73.99716054243916,
                40.69979281264408
              ],
              [
                -73.99736745811681,
                40.69963423442719
              ],
              [
                -73.9974989831,
                40.699638869321234
              ],
              [
                -73.9994746383893,
                40.70032277801828
              ],
              [
                -74.00001840433615,
                40.699466048073575
              ],
              [
                -73.99802102725101,
                40.69876173657377
              ],
              [
                -73.9983776068576,
                40.69806329614608
              ],
              [
                -74.00048768562506,
                40.69875777763452
              ],
              [
                -74.0010490305752,
                40.69790823669744
              ],
              [
                -73.99887117097644,
                40.69715757729578
              ],
              [
                -73.99876458027717,
                40.697120733093975
              ],
              [
                -73.99887207210413,
                40.69692378457409
              ],
              [
                -73.99893968968644,
                40.69694094051582
              ],
              [
                -73.99899604139205,
                40.696812278373024
              ],
              [
                -73.99918198950836,
                40.69688518856733
              ],
              [
                -73.99929468836199,
                40.696795125794004
              ],
              [
                -73.99947406820583,
                40.696704134461974
              ],
              [
                -73.99950317795836,
                40.69672650719374
              ],
              [
                -73.99940738376904,
                40.69688518944602
              ],
              [
                -73.99955389083655,
                40.69694523239685
              ],
              [
                -73.99958770006698,
                40.6968980571681
              ],
              [
                -73.99953135202674,
                40.69687661261937
              ],
              [
                -73.99959333515216,
                40.696782261474375
              ],
              [
                -74.00043147340423,
                40.69705246617852
              ],
              [
                -74.00048933405957,
                40.696926195088054
              ],
              [
                -73.99952373903493,
                40.69657482604084
              ],
              [
                -73.99941302433201,
                40.69635767645332
              ],
              [
                -73.9994197190206,
                40.69634693781851
              ],
              [
                -73.9994614344863,
                40.696280026604306
              ],
              [
                -73.99988214960374,
                40.69560519924566
              ],
              [
                -74.00026872377784,
                40.69496580257799
              ],
              [
                -74.00239802583795,
                40.69571165486448
              ],
              [
                -74.0026355689508,
                40.695362037018754
              ],
              [
                -74.00301393814541,
                40.69477784423956
              ],
              [
                -74.0009586845849,
                40.69406908379108
              ],
              [
                -74.00127014951309,
                40.693296061836925
              ],
              [
                -74.0015417289999,
                40.692785962999956
              ],
              [
                -74.00174362072502,
                40.69240674985267
              ],
              [
                -74.0016998939999,
                40.69238276699991
              ],
              [
                -74.00110519399988,
                40.692056594999954
              ],
              [
                -74.00031547799995,
                40.69187465199994
              ],
              [
                -74.00021419499998,
                40.691851760999896
              ],
              [
                -73.99991517399985,
                40.69176722999993
              ],
              [
                -73.99951274099993,
                40.6916514499999
              ],
              [
                -73.99936540199982,
                40.691613077999904
              ],
              [
                -73.99928819599991,
                40.69159395999992
              ],
              [
                -73.99920161899989,
                40.69157183399994
              ],
              [
                -73.99910057299985,
                40.69153697399986
              ],
              [
                -73.9984330559999,
                40.691364914999895
              ],
              [
                -73.99803048599995,
                40.691253722999896
              ],
              [
                -73.99776924399993,
                40.69119469799997
              ],
              [
                -73.99623278099982,
                40.69075777399993
              ],
              [
                -73.99436092799984,
                40.69023947999991
              ],
              [
                -73.99236366999985,
                40.689690123999924
              ],
              [
                -73.99070669599995,
                40.68913674399992
              ],
              [
                -73.99054474599986,
                40.689168104999936
              ],
              [
                -73.99016617899996,
                40.68972953399991
              ],
              [
                -73.98973690099999,
                40.6903599729999
              ],
              [
                -73.98930768499997,
                40.690991687999926
              ],
              [
                -73.98930270499983,
                40.691060225999884
              ],
              [
                -73.98902944799994,
                40.69212386099991
              ],
              [
                -73.9891054169999,
                40.69215331399994
              ],
              [
                -73.98947238599989,
                40.69229549399995
              ],
              [
                -73.99066626299994,
                40.692497176999915
              ],
              [
                -73.99097187,
                40.69254879999995
              ],
              [
                -73.99085041499994,
                40.6927982509999
              ],
              [
                -73.99072895999997,
                40.693047700999905
              ],
              [
                -73.99058794499989,
                40.69339137899992
              ],
              [
                -73.99045286599994,
                40.69372058399994
              ],
              [
                -73.99044474099998,
                40.69382825099991
              ],
              [
                -73.99066605599978,
                40.694568823999944
              ],
              [
                -73.99080803800004,
                40.69502713399987
              ],
              [
                -73.99115539099992,
                40.696196475999926
              ],
              [
                -73.99118780399986,
                40.696287414999915
              ],
              [
                -73.99120620999996,
                40.69635779799992
              ],
              [
                -73.99128413699994,
                40.69658820999992
              ],
              [
                -73.9913681219999,
                40.697013752999936
              ],
              [
                -73.99142289799993,
                40.69778099299988
              ],
              [
                -73.99143347099985,
                40.69828185899998
              ],
              [
                -73.99142848599986,
                40.6984190989999
              ],
              [
                -73.99141799499986,
                40.698556158999956
              ],
              [
                -73.99140200699999,
                40.698692910999945
              ],
              [
                -73.99138053899995,
                40.6988292269999
              ],
              [
                -73.99135360999992,
                40.698964980999925
              ],
              [
                -73.99132124499982,
                40.69910004499998
              ],
              [
                -73.99128347399983,
                40.69923429399991
              ],
              [
                -73.99124033199993,
                40.69936760199989
              ],
              [
                -73.9910295809998,
                40.699847169999956
              ],
              [
                -73.99084157899988,
                40.70028125699985
              ],
              [
                -73.99082272099989,
                40.70035475399991
              ],
              [
                -73.99081766499997,
                40.70046229299991
              ],
              [
                -73.99083516199983,
                40.700569761999915
              ],
              [
                -73.99087453800003,
                40.70067311699993
              ],
              [
                -73.99093201799984,
                40.7007662159999
              ],
              [
                -73.99126294499995,
                40.701027101999905
              ],
              [
                -73.99193644899997,
                40.701556408999856
              ],
              [
                -73.99202772899994,
                40.70162814199991
              ],
              [
                -73.99206681900003,
                40.70165885999989
              ],
              [
                -73.9921106779999,
                40.701693442999925
              ],
              [
                -73.99221871100002,
                40.70177257799987
              ],
              [
                -73.99232767899991,
                40.70185536999986
              ],
              [
                -73.99261204999985,
                40.70206112899992
              ],
              [
                -73.99286016299997,
                40.702202766999896
              ],
              [
                -73.99325388199995,
                40.70241197099989
              ],
              [
                -73.99380996599997,
                40.70267441199997
              ],
              [
                -73.99417681200005,
                40.70282465399987
              ],
              [
                -73.9944400939999,
                40.702909269999964
              ],
              [
                -73.99470923399984,
                40.702993885999895
              ],
              [
                -73.99490815999987,
                40.70304734099988
              ],
              [
                -73.99504592724139,
                40.70313183989976
              ],
              [
                -73.99506438495756,
                40.70310031332676
              ],
              [
                -73.99513826820316,
                40.702974114861405
              ],
              [
                -73.99619227264343,
                40.70337714093203
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000201673837402,
        "objectid": 29,
        "shape_leng": 0.0714083127733,
        "location_id": 29,
        "zone": "Brighton Beach",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.96004798699995,
                40.58326987199995
              ],
              [
                -73.96010509500002,
                40.58340835699995
              ],
              [
                -73.96014924099997,
                40.58357589699988
              ],
              [
                -73.96021792199998,
                40.584514661999926
              ],
              [
                -73.96022753900004,
                40.584608958999894
              ],
              [
                -73.96023124399987,
                40.58467600499993
              ],
              [
                -73.96023555999999,
                40.584746675999895
              ],
              [
                -73.9602394339998,
                40.58479187399997
              ],
              [
                -73.96024315399984,
                40.58482708099993
              ],
              [
                -73.96025825899996,
                40.585029307999946
              ],
              [
                -73.9603253929998,
                40.585812722999954
              ],
              [
                -73.96034190799985,
                40.585968365999854
              ],
              [
                -73.96034614999985,
                40.58612476999992
              ],
              [
                -73.9603380369999,
                40.58628108999995
              ],
              [
                -73.96031766399983,
                40.58643646799992
              ],
              [
                -73.96031712099983,
                40.586514723999876
              ],
              [
                -73.96034953599988,
                40.58730628599994
              ],
              [
                -73.96040710199983,
                40.58803435199996
              ],
              [
                -73.96045968399986,
                40.58872381999986
              ],
              [
                -73.96051667199988,
                40.5894529119999
              ],
              [
                -73.96057230499996,
                40.590181426999926
              ],
              [
                -73.9606241039998,
                40.59086268099987
              ],
              [
                -73.9606798409999,
                40.59159758299997
              ],
              [
                -73.96283785899992,
                40.59136011199993
              ],
              [
                -73.96377366299998,
                40.59125722899989
              ],
              [
                -73.96483485600002,
                40.59113696899988
              ],
              [
                -73.96514385199995,
                40.5911019159999
              ],
              [
                -73.96530164399988,
                40.590358229999936
              ],
              [
                -73.9654650069999,
                40.58964333699986
              ],
              [
                -73.9656408479998,
                40.58888742799993
              ],
              [
                -73.96581402699984,
                40.58813321799995
              ],
              [
                -73.96597498399979,
                40.58743213599997
              ],
              [
                -73.96615022799989,
                40.58666882399992
              ],
              [
                -73.96633012800002,
                40.58589875099992
              ],
              [
                -73.96636486799986,
                40.585751243999916
              ],
              [
                -73.96643740799989,
                40.58541469399988
              ],
              [
                -73.96649796199982,
                40.585142492999886
              ],
              [
                -73.96653588899984,
                40.584957806999974
              ],
              [
                -73.96660079799997,
                40.5846417419999
              ],
              [
                -73.96661531499983,
                40.58457103699992
              ],
              [
                -73.96670866299985,
                40.584239301999965
              ],
              [
                -73.96673011799997,
                40.5841359729999
              ],
              [
                -73.9667466419999,
                40.584055351999886
              ],
              [
                -73.96676128900002,
                40.58398439199989
              ],
              [
                -73.96677678399986,
                40.58391498999987
              ],
              [
                -73.966813755,
                40.58375771499986
              ],
              [
                -73.96683458399988,
                40.58367686199994
              ],
              [
                -73.96685766099992,
                40.58358661799986
              ],
              [
                -73.96691975199984,
                40.58335247299991
              ],
              [
                -73.96701115999986,
                40.58294762399992
              ],
              [
                -73.96707277600002,
                40.58266981199988
              ],
              [
                -73.96708956100004,
                40.58259579399988
              ],
              [
                -73.96733403999997,
                40.58151752199991
              ],
              [
                -73.9675871379998,
                40.580429058999925
              ],
              [
                -73.96759867799993,
                40.580378470999925
              ],
              [
                -73.96761555699982,
                40.58030516299993
              ],
              [
                -73.96804495099995,
                40.57843853299986
              ],
              [
                -73.96834802499988,
                40.57711322399996
              ],
              [
                -73.96852475500002,
                40.57634038099996
              ],
              [
                -73.96853234199989,
                40.57630503099995
              ],
              [
                -73.968546354,
                40.57624267099986
              ],
              [
                -73.96865108899983,
                40.57576305399993
              ],
              [
                -73.96866614099994,
                40.57569696799986
              ],
              [
                -73.96868119500002,
                40.57563088299987
              ],
              [
                -73.96873173299986,
                40.57550140999996
              ],
              [
                -73.96880187100001,
                40.5753773639999
              ],
              [
                -73.96883335399993,
                40.57533585899988
              ],
              [
                -73.9688899589999,
                40.57526123899986
              ],
              [
                -73.96883249999995,
                40.57524150099993
              ],
              [
                -73.96853345900001,
                40.57513875999993
              ],
              [
                -73.96840165999983,
                40.57508536799986
              ],
              [
                -73.96825878799997,
                40.57504548899988
              ],
              [
                -73.96810857699987,
                40.57502089899985
              ],
              [
                -73.96795533399992,
                40.57501255399995
              ],
              [
                -73.96780353599996,
                40.575020453999855
              ],
              [
                -73.96765743500002,
                40.575043681999915
              ],
              [
                -73.96756707299996,
                40.574881526999896
              ],
              [
                -73.96675354099992,
                40.574832917999906
              ],
              [
                -73.96471128899991,
                40.57478261799983
              ],
              [
                -73.96286117999982,
                40.574980140999905
              ],
              [
                -73.96103342799984,
                40.57507632399992
              ],
              [
                -73.95941778299985,
                40.575177982999925
              ],
              [
                -73.95939182223685,
                40.57391210937614
              ],
              [
                -73.95909642194573,
                40.573863820433246
              ],
              [
                -73.95907248027578,
                40.57384554800024
              ],
              [
                -73.9590260777376,
                40.57345199511836
              ],
              [
                -73.9589871897255,
                40.57343065573189
              ],
              [
                -73.95894268461842,
                40.573467167647344
              ],
              [
                -73.95897543388922,
                40.57390455247928
              ],
              [
                -73.9589663415273,
                40.57394726876846
              ],
              [
                -73.95888419181567,
                40.57399159603125
              ],
              [
                -73.95865147139088,
                40.57405152377118
              ],
              [
                -73.95843476359003,
                40.574088815548926
              ],
              [
                -73.9579441385944,
                40.574144812662254
              ],
              [
                -73.95720053498161,
                40.57418667138082
              ],
              [
                -73.9565675090591,
                40.57417077849292
              ],
              [
                -73.95647169447582,
                40.574139487242576
              ],
              [
                -73.95638980040823,
                40.57382368774354
              ],
              [
                -73.95634533454607,
                40.57379757829187
              ],
              [
                -73.95631111453274,
                40.57380017438839
              ],
              [
                -73.95629561203626,
                40.573834984654354
              ],
              [
                -73.95636904107674,
                40.57417331306189
              ],
              [
                -73.95634506681625,
                40.574207221989525
              ],
              [
                -73.95620816595128,
                40.57426547067511
              ],
              [
                -73.95569484044364,
                40.57432699626323
              ],
              [
                -73.95513705246907,
                40.57437113692578
              ],
              [
                -73.95511114426007,
                40.574371126805104
              ],
              [
                -73.954397938218,
                40.57437084590723
              ],
              [
                -73.95413360803454,
                40.574333267495184
              ],
              [
                -73.95410029594862,
                40.57428723314877
              ],
              [
                -73.95410726751373,
                40.57410198308006
              ],
              [
                -73.95408333385912,
                40.57407327218331
              ],
              [
                -73.95403199797872,
                40.574086297180465
              ],
              [
                -73.95401136297524,
                40.574237622567495
              ],
              [
                -73.95399423405074,
                40.57426631704182
              ],
              [
                -73.95393490539726,
                40.574242908652664
              ],
              [
                -73.95385051373718,
                40.57427147727182
              ],
              [
                -73.95377968074372,
                40.57428764371035
              ],
              [
                -73.95374257958343,
                40.57429723224191
              ],
              [
                -73.95352660081424,
                40.57429652370539
              ],
              [
                -73.95340408353178,
                40.57429419533951
              ],
              [
                -73.95328291809346,
                40.57429188984175
              ],
              [
                -73.95311788871602,
                40.57428779386081
              ],
              [
                -73.95295058831675,
                40.574250238545446
              ],
              [
                -73.95275899192154,
                40.574213630931816
              ],
              [
                -73.95254341027385,
                40.574223979504296
              ],
              [
                -73.95235846771321,
                40.574274685845246
              ],
              [
                -73.95242628499996,
                40.57455910699991
              ],
              [
                -73.95287451099996,
                40.576882158999915
              ],
              [
                -73.95300123199996,
                40.577476511999855
              ],
              [
                -73.95303970899995,
                40.5776795869999
              ],
              [
                -73.95309963899994,
                40.57799415599989
              ],
              [
                -73.95334153899985,
                40.579267127999884
              ],
              [
                -73.9537406319999,
                40.58135589199988
              ],
              [
                -73.9538380169999,
                40.581745551999916
              ],
              [
                -73.95392000699991,
                40.58186979399993
              ],
              [
                -73.95403970599988,
                40.58251639099995
              ],
              [
                -73.95407172899988,
                40.582702397999896
              ],
              [
                -73.95411176199985,
                40.582919917999966
              ],
              [
                -73.95412132099985,
                40.58298298499989
              ],
              [
                -73.95414287899986,
                40.58310613999991
              ],
              [
                -73.954365727,
                40.583081076999875
              ],
              [
                -73.95517441499999,
                40.582915870999955
              ],
              [
                -73.95571160099988,
                40.58280458499993
              ],
              [
                -73.95604146799998,
                40.58273625399989
              ],
              [
                -73.95645729099982,
                40.58265885099992
              ],
              [
                -73.95675618299992,
                40.58283496999994
              ],
              [
                -73.95682583599991,
                40.5828863089999
              ],
              [
                -73.95689047299997,
                40.58293397899993
              ],
              [
                -73.95695595999992,
                40.58298226299991
              ],
              [
                -73.95703134199992,
                40.58303533199994
              ],
              [
                -73.9571329139998,
                40.583045725999966
              ],
              [
                -73.95723610100002,
                40.58304227099991
              ],
              [
                -73.95733608399979,
                40.58302513399991
              ],
              [
                -73.95900739999992,
                40.58283812799991
              ],
              [
                -73.96004798699995,
                40.58326987199995
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000333975927329,
        "objectid": 31,
        "shape_leng": 0.0964245666516,
        "location_id": 31,
        "zone": "Bronx Park",
        "borough": "Bronx"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.87094462199981,
                40.85726608099992
              ],
              [
                -73.87100173199987,
                40.85727674299992
              ],
              [
                -73.87100227699989,
                40.857276842999966
              ],
              [
                -73.87100272699996,
                40.85727711299994
              ],
              [
                -73.87104905899983,
                40.857304602999946
              ],
              [
                -73.87104944999982,
                40.85730483699999
              ],
              [
                -73.87104967499978,
                40.857305152999885
              ],
              [
                -73.87107580399994,
                40.8573412649999
              ],
              [
                -73.87107594599998,
                40.85734146299997
              ],
              [
                -73.87107598099979,
                40.85734166099993
              ],
              [
                -73.87108262599999,
                40.85737703999995
              ],
              [
                -73.87103268899989,
                40.85780779699994
              ],
              [
                -73.87097845199983,
                40.858199414999945
              ],
              [
                -73.87088409199991,
                40.85889518099991
              ],
              [
                -73.87057937699994,
                40.86140455299988
              ],
              [
                -73.87046131799995,
                40.86327149799997
              ],
              [
                -73.87041916899989,
                40.86536282199993
              ],
              [
                -73.87045509099991,
                40.8666328689999
              ],
              [
                -73.87049187,
                40.86795082999989
              ],
              [
                -73.87049783499994,
                40.86817665499995
              ],
              [
                -73.87049958099983,
                40.86824277599994
              ],
              [
                -73.87051473299981,
                40.86881643199998
              ],
              [
                -73.87051629799993,
                40.868875664999926
              ],
              [
                -73.870526217,
                40.869251176999924
              ],
              [
                -73.87052897299995,
                40.86935553299992
              ],
              [
                -73.87053320099994,
                40.86951557999987
              ],
              [
                -73.87053494699997,
                40.86958170299988
              ],
              [
                -73.87053738699991,
                40.86967403099992
              ],
              [
                -73.87056192499988,
                40.87151077799995
              ],
              [
                -73.87037667199995,
                40.8732843719999
              ],
              [
                -73.8703131089999,
                40.87534062099997
              ],
              [
                -73.87031508399987,
                40.875617522999924
              ],
              [
                -73.8703168469999,
                40.87573572299988
              ],
              [
                -73.87031256699991,
                40.87583307799986
              ],
              [
                -73.87030212599987,
                40.87593016499994
              ],
              [
                -73.87028554799986,
                40.87602675899996
              ],
              [
                -73.87026287199993,
                40.87612263599991
              ],
              [
                -73.87023414999992,
                40.876217573999924
              ],
              [
                -73.87019944899986,
                40.87631135499987
              ],
              [
                -73.87018094199979,
                40.87635452999994
              ],
              [
                -73.8701541849999,
                40.876416957999886
              ],
              [
                -73.87004672699982,
                40.87667053099995
              ],
              [
                -73.86948896199988,
                40.87798946799995
              ],
              [
                -73.86943471300005,
                40.87812919899994
              ],
              [
                -73.86950315999994,
                40.878145664999884
              ],
              [
                -73.86978338299986,
                40.87821297799992
              ],
              [
                -73.86996807499997,
                40.87826435199986
              ],
              [
                -73.87005983899994,
                40.87829031699993
              ],
              [
                -73.8701497639999,
                40.87831012099989
              ],
              [
                -73.87033725199989,
                40.87835140299997
              ],
              [
                -73.87094367500002,
                40.87851076699998
              ],
              [
                -73.87160577400002,
                40.876921927999916
              ],
              [
                -73.87255250899982,
                40.87517518299991
              ],
              [
                -73.87436750799998,
                40.872701008999876
              ],
              [
                -73.87518802799977,
                40.87157682799995
              ],
              [
                -73.87619224900003,
                40.8704767109999
              ],
              [
                -73.87694351999987,
                40.86995468199993
              ],
              [
                -73.87772752999979,
                40.86955533799994
              ],
              [
                -73.87981206299987,
                40.86841664799988
              ],
              [
                -73.87991019699996,
                40.86836304099992
              ],
              [
                -73.88135802599994,
                40.86757898699991
              ],
              [
                -73.8830900319999,
                40.86659150899994
              ],
              [
                -73.88297537099986,
                40.8664497319999
              ],
              [
                -73.88271794699989,
                40.86613296599992
              ],
              [
                -73.88267624699999,
                40.866089140999854
              ],
              [
                -73.88273658899989,
                40.86604857299993
              ],
              [
                -73.88319816099992,
                40.86576631399988
              ],
              [
                -73.88344168299979,
                40.865616331999895
              ],
              [
                -73.88356377799998,
                40.86552635199991
              ],
              [
                -73.88367321300004,
                40.865425282999944
              ],
              [
                -73.88376749199992,
                40.86531475299996
              ],
              [
                -73.88384464399992,
                40.86519681299989
              ],
              [
                -73.88390334899987,
                40.86507380199995
              ],
              [
                -73.88394303099996,
                40.86494818699986
              ],
              [
                -73.88396922299982,
                40.86481774099986
              ],
              [
                -73.88397514999986,
                40.864683997999926
              ],
              [
                -73.88396002399999,
                40.86454971899989
              ],
              [
                -73.88392395799984,
                40.86441776099987
              ],
              [
                -73.88386792099986,
                40.8642908889999
              ],
              [
                -73.88379386499992,
                40.86417182299991
              ],
              [
                -73.8837937109999,
                40.86417157099989
              ],
              [
                -73.88200794099986,
                40.862046852999946
              ],
              [
                -73.88103469099984,
                40.8612752949999
              ],
              [
                -73.88092572600007,
                40.861162287999925
              ],
              [
                -73.88083055899997,
                40.861040343999946
              ],
              [
                -73.88075099099991,
                40.86091098799991
              ],
              [
                -73.88068839499986,
                40.86077599299989
              ],
              [
                -73.88064370299989,
                40.86063731599991
              ],
              [
                -73.88061726900006,
                40.860497010999936
              ],
              [
                -73.88060896899991,
                40.8603570929999
              ],
              [
                -73.88075871500001,
                40.85949442499993
              ],
              [
                -73.880965862,
                40.85854520499987
              ],
              [
                -73.88098942799995,
                40.858459358999895
              ],
              [
                -73.88100678999979,
                40.85826802299992
              ],
              [
                -73.88100464399997,
                40.85807565699992
              ],
              [
                -73.88098293799995,
                40.85788399199989
              ],
              [
                -73.88094199099993,
                40.857694990999974
              ],
              [
                -73.880941932,
                40.857694738999925
              ],
              [
                -73.88094184999993,
                40.85769449599996
              ],
              [
                -73.88088225100002,
                40.85750954499986
              ],
              [
                -73.88080810099991,
                40.857198447999885
              ],
              [
                -73.88079155299991,
                40.85712750799994
              ],
              [
                -73.88077132199986,
                40.8570397609999
              ],
              [
                -73.88072954099984,
                40.856807273999955
              ],
              [
                -73.88073042799992,
                40.85667660499991
              ],
              [
                -73.88103358999996,
                40.8555999519999
              ],
              [
                -73.8816727419998,
                40.8533496789999
              ],
              [
                -73.88200299099991,
                40.85218935799987
              ],
              [
                -73.88232256599994,
                40.85106079199989
              ],
              [
                -73.88255596199991,
                40.85022427099989
              ],
              [
                -73.88284931500006,
                40.84919252699994
              ],
              [
                -73.88288269199988,
                40.84907404699989
              ],
              [
                -73.88311982600001,
                40.848221091999925
              ],
              [
                -73.88278133699994,
                40.84807038999989
              ],
              [
                -73.88276104799992,
                40.84805784599992
              ],
              [
                -73.88284445599996,
                40.84781722599997
              ],
              [
                -73.88199829499979,
                40.84723531199989
              ],
              [
                -73.8810605989999,
                40.84659008499992
              ],
              [
                -73.88048011699989,
                40.84619371599996
              ],
              [
                -73.87919408799995,
                40.8453217339999
              ],
              [
                -73.87800756899993,
                40.844484295999926
              ],
              [
                -73.87732924099988,
                40.84398312499995
              ],
              [
                -73.878171756,
                40.84262443699987
              ],
              [
                -73.87694201699995,
                40.84233562799991
              ],
              [
                -73.87686858299995,
                40.842317105999946
              ],
              [
                -73.87679872099999,
                40.84229978699994
              ],
              [
                -73.8760850519999,
                40.84206482699988
              ],
              [
                -73.87459175399982,
                40.84152064799993
              ],
              [
                -73.87456845,
                40.84164271999994
              ],
              [
                -73.87399295499982,
                40.84256150599994
              ],
              [
                -73.87280708199992,
                40.843424263999964
              ],
              [
                -73.87190185099998,
                40.84372143299993
              ],
              [
                -73.87184610299998,
                40.84376077799993
              ],
              [
                -73.87179038499981,
                40.84380281399991
              ],
              [
                -73.87109207899995,
                40.84432478099992
              ],
              [
                -73.86944230499982,
                40.84724417999994
              ],
              [
                -73.86889243299994,
                40.8481123219999
              ],
              [
                -73.86941402799984,
                40.84878802499991
              ],
              [
                -73.86920944599997,
                40.84923837199993
              ],
              [
                -73.86823466099995,
                40.85091373699986
              ],
              [
                -73.86834552299989,
                40.851198851999875
              ],
              [
                -73.86844433299986,
                40.85148667199995
              ],
              [
                -73.86853091299999,
                40.851776755999914
              ],
              [
                -73.86860515599989,
                40.852068645999914
              ],
              [
                -73.86872263199984,
                40.85242385199994
              ],
              [
                -73.86916668699996,
                40.85368604599991
              ],
              [
                -73.86937675499983,
                40.85426572299986
              ],
              [
                -73.86981576299978,
                40.85548377399993
              ],
              [
                -73.86985960399987,
                40.85557672799994
              ],
              [
                -73.86988939599995,
                40.855673861999925
              ],
              [
                -73.86990421600002,
                40.85577329299987
              ],
              [
                -73.86990379599993,
                40.85587302199991
              ],
              [
                -73.86988842299996,
                40.85597106699988
              ],
              [
                -73.86927391299994,
                40.8566889829999
              ],
              [
                -73.86907624599999,
                40.85694556099987
              ],
              [
                -73.86887858999994,
                40.85720213799993
              ],
              [
                -73.86876752999989,
                40.85734077999995
              ],
              [
                -73.86841539699994,
                40.85778035799996
              ],
              [
                -73.86889622799993,
                40.85777277299997
              ],
              [
                -73.86954868099988,
                40.85772602999987
              ],
              [
                -73.87084887399999,
                40.85729081699992
              ],
              [
                -73.87087754099998,
                40.85727822399986
              ],
              [
                -73.87088962499988,
                40.85727291599995
              ],
              [
                -73.87088983900003,
                40.85727281799995
              ],
              [
                -73.87089011199996,
                40.85727278099993
              ],
              [
                -73.87094366199995,
                40.85726605199995
              ],
              [
                -73.87094413599989,
                40.857265988999906
              ],
              [
                -73.87094462199981,
                40.85726608099992
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000150879171971,
        "objectid": 32,
        "shape_leng": 0.05426721601,
        "location_id": 32,
        "zone": "Bronxdale",
        "borough": "Bronx"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.85882515999995,
                40.858061356999876
              ],
              [
                -73.8566377469999,
                40.8581191719999
              ],
              [
                -73.85735319699984,
                40.859711605999934
              ],
              [
                -73.85739481999991,
                40.85998410299992
              ],
              [
                -73.85746244199993,
                40.860426811999915
              ],
              [
                -73.85747219599985,
                40.860495676999925
              ],
              [
                -73.85747885699989,
                40.86054270099991
              ],
              [
                -73.85752224299985,
                40.86084898099988
              ],
              [
                -73.85752991799988,
                40.86090316799989
              ],
              [
                -73.85756682699994,
                40.8611637229999
              ],
              [
                -73.85759960699981,
                40.86141838899994
              ],
              [
                -73.85760926699989,
                40.86149344099991
              ],
              [
                -73.857670848,
                40.86197187299995
              ],
              [
                -73.85767885599986,
                40.86203407799992
              ],
              [
                -73.85769181699992,
                40.8621347729999
              ],
              [
                -73.85769842199993,
                40.862186080999926
              ],
              [
                -73.85770552799988,
                40.862241284999925
              ],
              [
                -73.85771028399981,
                40.862278229999895
              ],
              [
                -73.85780378799993,
                40.86303621699989
              ],
              [
                -73.85806098599983,
                40.86304235999992
              ],
              [
                -73.85844789699988,
                40.86376138999989
              ],
              [
                -73.85871220299993,
                40.86425255299985
              ],
              [
                -73.85890956799982,
                40.8646369259999
              ],
              [
                -73.85961281199982,
                40.86551271799987
              ],
              [
                -73.8605510079999,
                40.86552969599993
              ],
              [
                -73.86156274099987,
                40.86554946399989
              ],
              [
                -73.8615423979999,
                40.86615362299991
              ],
              [
                -73.86150246499992,
                40.86755143299994
              ],
              [
                -73.86149826099988,
                40.86778221599998
              ],
              [
                -73.8614338389999,
                40.86960346799988
              ],
              [
                -73.86137924099984,
                40.871336511999914
              ],
              [
                -73.86239212899994,
                40.87135673399991
              ],
              [
                -73.86333198300004,
                40.8713751569999
              ],
              [
                -73.86426604399986,
                40.87138964999989
              ],
              [
                -73.865208901,
                40.871407649999874
              ],
              [
                -73.86614489499986,
                40.87142298499991
              ],
              [
                -73.86715864199988,
                40.87144191299995
              ],
              [
                -73.86825887799992,
                40.87146232799989
              ],
              [
                -73.86935122399996,
                40.871483353999906
              ],
              [
                -73.87056192499988,
                40.87151077799995
              ],
              [
                -73.87053738699991,
                40.86967403099992
              ],
              [
                -73.87053494699997,
                40.86958170299988
              ],
              [
                -73.87053320099994,
                40.86951557999987
              ],
              [
                -73.87052897299995,
                40.86935553299992
              ],
              [
                -73.870526217,
                40.869251176999924
              ],
              [
                -73.87051629799993,
                40.868875664999926
              ],
              [
                -73.87051473299981,
                40.86881643199998
              ],
              [
                -73.87051057099994,
                40.86865883499998
              ],
              [
                -73.87049958099983,
                40.86824277599994
              ],
              [
                -73.87049783499994,
                40.86817665499995
              ],
              [
                -73.87049187,
                40.86795082999989
              ],
              [
                -73.87045509099991,
                40.8666328689999
              ],
              [
                -73.87041916899989,
                40.86536282199993
              ],
              [
                -73.87046131799995,
                40.86327149799997
              ],
              [
                -73.87057937699994,
                40.86140455299988
              ],
              [
                -73.87088409199991,
                40.85889518099991
              ],
              [
                -73.87097845199983,
                40.858199414999945
              ],
              [
                -73.87103268899989,
                40.85780779699994
              ],
              [
                -73.87108262599999,
                40.85737703999995
              ],
              [
                -73.87107598099979,
                40.85734166099993
              ],
              [
                -73.87107594599998,
                40.85734146299997
              ],
              [
                -73.87107580399994,
                40.8573412649999
              ],
              [
                -73.87104967499978,
                40.857305152999885
              ],
              [
                -73.87104944999982,
                40.85730483699999
              ],
              [
                -73.87104905899983,
                40.857304602999946
              ],
              [
                -73.87100272699996,
                40.85727711299994
              ],
              [
                -73.87100227699989,
                40.857276842999966
              ],
              [
                -73.87100173199987,
                40.85727674299992
              ],
              [
                -73.87094462199981,
                40.85726608099992
              ],
              [
                -73.87094413599989,
                40.857265988999906
              ],
              [
                -73.87094366199995,
                40.85726605199995
              ],
              [
                -73.87089011199996,
                40.85727278099993
              ],
              [
                -73.87088983900003,
                40.85727281799995
              ],
              [
                -73.87088962499988,
                40.85727291599995
              ],
              [
                -73.87087754099998,
                40.85727822399986
              ],
              [
                -73.87084887399999,
                40.85729081699992
              ],
              [
                -73.86954868099988,
                40.85772602999987
              ],
              [
                -73.86889622699992,
                40.85777277299994
              ],
              [
                -73.86841539699994,
                40.85778035799996
              ],
              [
                -73.8681883059999,
                40.858063833999964
              ],
              [
                -73.86820126399982,
                40.85801835599993
              ],
              [
                -73.86819723700003,
                40.857967986999896
              ],
              [
                -73.86817334099987,
                40.85791854899991
              ],
              [
                -73.86813140299985,
                40.8578765929999
              ],
              [
                -73.86807772399996,
                40.85784684199992
              ],
              [
                -73.86802029299992,
                40.85783059499988
              ],
              [
                -73.86758752499986,
                40.857830089999894
              ],
              [
                -73.8665833139999,
                40.85785535399993
              ],
              [
                -73.86564157999992,
                40.85788154599993
              ],
              [
                -73.86467028199996,
                40.857905236999976
              ],
              [
                -73.86455073499997,
                40.85790846299995
              ],
              [
                -73.86395713499984,
                40.857924478999955
              ],
              [
                -73.86387047899979,
                40.85792681599984
              ],
              [
                -73.86326398600002,
                40.8579431759999
              ],
              [
                -73.86318907699996,
                40.85794519699993
              ],
              [
                -73.8629585349999,
                40.857951413999956
              ],
              [
                -73.86287784399995,
                40.85795359099992
              ],
              [
                -73.8617906939999,
                40.857982903999925
              ],
              [
                -73.85882515999995,
                40.858061356999876
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000145862107626,
        "objectid": 30,
        "shape_leng": 0.0945097669793,
        "location_id": 30,
        "zone": "Broad Channel",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.82075892499992,
                40.61523267899992
              ],
              [
                -73.82141601599977,
                40.61505476399992
              ],
              [
                -73.82157060299986,
                40.61490869099991
              ],
              [
                -73.82163921899993,
                40.61487061499985
              ],
              [
                -73.82170208100001,
                40.614824190999855
              ],
              [
                -73.82176857999997,
                40.61476625499992
              ],
              [
                -73.82234961099986,
                40.61426002599995
              ],
              [
                -73.82379847999995,
                40.61212942099992
              ],
              [
                -73.8238246859766,
                40.61149866495508
              ],
              [
                -73.82352882101249,
                40.611324013967064
              ],
              [
                -73.82342464290727,
                40.611234333498686
              ],
              [
                -73.82334654206795,
                40.61115463895985
              ],
              [
                -73.82319489759728,
                40.610937836059286
              ],
              [
                -73.8228923332429,
                40.61033702209704
              ],
              [
                -73.82273529622739,
                40.61032811408002
              ],
              [
                -73.82253978465067,
                40.6102283448959
              ],
              [
                -73.82233079790585,
                40.61028770312731
              ],
              [
                -73.8220962121063,
                40.61015803186759
              ],
              [
                -73.82186205220738,
                40.609869212532935
              ],
              [
                -73.82184945090272,
                40.609700096907744
              ],
              [
                -73.82179804199976,
                40.609401613194315
              ],
              [
                -73.82166797819497,
                40.609232316803684
              ],
              [
                -73.8216943464972,
                40.60913289016243
              ],
              [
                -73.82182531319947,
                40.60896399709298
              ],
              [
                -73.8217733990959,
                40.608854501974754
              ],
              [
                -73.82168241117662,
                40.60871510679354
              ],
              [
                -73.82175280507491,
                40.608626103033515
              ],
              [
                -73.82176098510635,
                40.60861575979552
              ],
              [
                -73.82161460094558,
                40.608480319113895
              ],
              [
                -73.82138268112816,
                40.60854554497339
              ],
              [
                -73.82133010279306,
                40.60868471823666
              ],
              [
                -73.82119903005622,
                40.60889339821899
              ],
              [
                -73.82114637042204,
                40.609062411035616
              ],
              [
                -73.82085878502924,
                40.60923105813554
              ],
              [
                -73.82079777394567,
                40.60944279907323
              ],
              [
                -73.82075127944346,
                40.60961605984778
              ],
              [
                -73.82063420416088,
                40.60985730620809
              ],
              [
                -73.82055813686831,
                40.60995210898184
              ],
              [
                -73.82052525668642,
                40.61009650237407
              ],
              [
                -73.82048700610763,
                40.610224379146686
              ],
              [
                -73.82054872655173,
                40.6104308241045
              ],
              [
                -73.82062698209096,
                40.61052999510846
              ],
              [
                -73.82078645512786,
                40.610633417868414
              ],
              [
                -73.8210622848369,
                40.61076384818487
              ],
              [
                -73.82120543662198,
                40.610896134993695
              ],
              [
                -73.82128421076361,
                40.61109692914301
              ],
              [
                -73.82128305878275,
                40.61123260505915
              ],
              [
                -73.82126119794827,
                40.61130685698378
              ],
              [
                -73.82120960284034,
                40.61136249138747
              ],
              [
                -73.82114995198185,
                40.61139335100864
              ],
              [
                -73.82110627924509,
                40.61143206804872
              ],
              [
                -73.82098893002488,
                40.6116930062198
              ],
              [
                -73.82101391261239,
                40.6117666052224
              ],
              [
                -73.82102865614054,
                40.61181003904737
              ],
              [
                -73.82108403602686,
                40.61189658282583
              ],
              [
                -73.82104384524081,
                40.6119524624867
              ],
              [
                -73.82098143849392,
                40.611998135984095
              ],
              [
                -73.82095222030904,
                40.61210997603296
              ],
              [
                -73.82087841795061,
                40.61225734590998
              ],
              [
                -73.82082274277886,
                40.61228099237596
              ],
              [
                -73.82076043881737,
                40.61228767567876
              ],
              [
                -73.8206893336656,
                40.61225874578369
              ],
              [
                -73.82057595418243,
                40.61223314094054
              ],
              [
                -73.82050281586973,
                40.612133008178944
              ],
              [
                -73.82043403607466,
                40.612066787254314
              ],
              [
                -73.82043202517556,
                40.61198710813792
              ],
              [
                -73.82051887888557,
                40.61194994886219
              ],
              [
                -73.82045405309891,
                40.61185455716302
              ],
              [
                -73.81995150270963,
                40.61100586586921
              ],
              [
                -73.81961795381072,
                40.610479041032136
              ],
              [
                -73.819808317517,
                40.60983495886036
              ],
              [
                -73.81998227923673,
                40.60956038585392
              ],
              [
                -73.81998084881896,
                40.609534058788874
              ],
              [
                -73.81997458784697,
                40.609418859979144
              ],
              [
                -73.81994251894176,
                40.60933574808964
              ],
              [
                -73.81998318384011,
                40.60922506304476
              ],
              [
                -73.82002812520409,
                40.6090251698898
              ],
              [
                -73.82003243531851,
                40.608923655879124
              ],
              [
                -73.82029978490344,
                40.608576445236174
              ],
              [
                -73.82085990045205,
                40.60783996985354
              ],
              [
                -73.82090456163026,
                40.607781246109084
              ],
              [
                -73.82088742209929,
                40.60770675093409
              ],
              [
                -73.82089163199676,
                40.60764215379211
              ],
              [
                -73.82107550220364,
                40.6073827251142
              ],
              [
                -73.82119130511715,
                40.607279608840344
              ],
              [
                -73.82117120508813,
                40.607248814414504
              ],
              [
                -73.8212602880639,
                40.60714435605203
              ],
              [
                -73.82119987284851,
                40.60709504008171
              ],
              [
                -73.82126470321425,
                40.607002850205184
              ],
              [
                -73.82151924671516,
                40.6069109548534
              ],
              [
                -73.82167691598228,
                40.60681890892526
              ],
              [
                -73.82173772462286,
                40.606720559117484
              ],
              [
                -73.82211377790149,
                40.60647195608507
              ],
              [
                -73.82223894478628,
                40.60642305775959
              ],
              [
                -73.82224712010017,
                40.606419864076926
              ],
              [
                -73.82236000888112,
                40.606469260830025
              ],
              [
                -73.82266727165995,
                40.60611873206085
              ],
              [
                -73.8229046338875,
                40.605851277214725
              ],
              [
                -73.82278161094604,
                40.6057869581131
              ],
              [
                -73.82269456760869,
                40.605738103125375
              ],
              [
                -73.82260024311239,
                40.60568515806695
              ],
              [
                -73.82248345709922,
                40.605583458011004
              ],
              [
                -73.82246746751217,
                40.60552498291133
              ],
              [
                -73.82253633123983,
                40.605432797200464
              ],
              [
                -73.82255472623692,
                40.605362065955575
              ],
              [
                -73.82253280983203,
                40.60523898153121
              ],
              [
                -73.82254505876273,
                40.605186701243284
              ],
              [
                -73.82259374778921,
                40.60509140960176
              ],
              [
                -73.82267071602583,
                40.604986932424026
              ],
              [
                -73.82271521396389,
                40.60495008393984
              ],
              [
                -73.82276978379643,
                40.60494517875669
              ],
              [
                -73.82281614677069,
                40.60494101100075
              ],
              [
                -73.82284474420824,
                40.604811847183406
              ],
              [
                -73.82290283001802,
                40.60468726877069
              ],
              [
                -73.82281306524087,
                40.60458107100474
              ],
              [
                -73.82278089795393,
                40.60453487647327
              ],
              [
                -73.82276563488945,
                40.60420260478325
              ],
              [
                -73.82280627719283,
                40.60397517902287
              ],
              [
                -73.82287967385791,
                40.603282783012396
              ],
              [
                -73.82290201513365,
                40.60300303204292
              ],
              [
                -73.8228859758601,
                40.60296301503915
              ],
              [
                -73.82289824010462,
                40.60290458214487
              ],
              [
                -73.82292481177993,
                40.60279805612647
              ],
              [
                -73.82294484720244,
                40.60258290923388
              ],
              [
                -73.8229963972268,
                40.602418667228356
              ],
              [
                -73.82304906345898,
                40.60234491607724
              ],
              [
                -73.82323485805279,
                40.60229598003813
              ],
              [
                -73.82357799780264,
                40.60227189587525
              ],
              [
                -73.8236230300988,
                40.60203200888308
              ],
              [
                -73.82358498378176,
                40.601940032997845
              ],
              [
                -73.82351857017017,
                40.601850342157256
              ],
              [
                -73.82345423589811,
                40.6017579531873
              ],
              [
                -73.82346666942101,
                40.60163491694976
              ],
              [
                -73.8235113029713,
                40.60154577108026
              ],
              [
                -73.82360431215255,
                40.60147823411712
              ],
              [
                -73.82370544281271,
                40.60139225097998
              ],
              [
                -73.82379033117405,
                40.60134315907532
              ],
              [
                -73.82388150021875,
                40.60124921618406
              ],
              [
                -73.8239241478234,
                40.60110648398148
              ],
              [
                -73.82398900112113,
                40.601001986812236
              ],
              [
                -73.82413862984455,
                40.6008914668263
              ],
              [
                -73.8242976550786,
                40.60085210111634
              ],
              [
                -73.82430388510507,
                40.600785199829225
              ],
              [
                -73.82445218054455,
                40.60079696284419
              ],
              [
                -73.82440706593387,
                40.60059112485041
              ],
              [
                -73.82443643781227,
                40.60034498367475
              ],
              [
                -73.82331920489146,
                40.60023408893601
              ],
              [
                -73.82330902392228,
                40.600269952836015
              ],
              [
                -73.82315326995335,
                40.60025728721991
              ],
              [
                -73.82314788294842,
                40.60023932910236
              ],
              [
                -73.82261001509886,
                40.600186031842206
              ],
              [
                -73.82260631900901,
                40.60021364018275
              ],
              [
                -73.82249404104252,
                40.60020104008347
              ],
              [
                -73.82248489186438,
                40.600235544823065
              ],
              [
                -73.82234362290922,
                40.600225661203886
              ],
              [
                -73.82234555578552,
                40.60018010003153
              ],
              [
                -73.82172074219885,
                40.600125283165575
              ],
              [
                -73.82173154776704,
                40.60007648099816
              ],
              [
                -73.82159001545337,
                40.60006242989868
              ],
              [
                -73.8214763933384,
                40.60005034409147
              ],
              [
                -73.82146921755755,
                40.600024098877554
              ],
              [
                -73.82146929866911,
                40.59999372263383
              ],
              [
                -73.82158890006589,
                40.59997733908517
              ],
              [
                -73.82168859290637,
                40.599954022003764
              ],
              [
                -73.82171949113493,
                40.599915408915074
              ],
              [
                -73.82180296187538,
                40.599861689810076
              ],
              [
                -73.82190636783253,
                40.59981162996679
              ],
              [
                -73.82194441779896,
                40.59980115598919
              ],
              [
                -73.82211282468639,
                40.59982350877663
              ],
              [
                -73.82212726983464,
                40.5998345631994
              ],
              [
                -73.82214174920362,
                40.59984564479891
              ],
              [
                -73.82215981277452,
                40.59986500305416
              ],
              [
                -73.82228834165707,
                40.599896959161086
              ],
              [
                -73.82235892273734,
                40.599921922056296
              ],
              [
                -73.82255813223716,
                40.59994156021139
              ],
              [
                -73.82257638978241,
                40.599887738969485
              ],
              [
                -73.8230043654241,
                40.599925619198075
              ],
              [
                -73.82303195183424,
                40.59993051694214
              ],
              [
                -73.82315293179705,
                40.599976662824794
              ],
              [
                -73.82317356168161,
                40.599978005155776
              ],
              [
                -73.8236175937432,
                40.60003570131408
              ],
              [
                -73.82376612290612,
                40.60004007091038
              ],
              [
                -73.8237932359679,
                40.59985122420924
              ],
              [
                -73.82401358185359,
                40.59942972124062
              ],
              [
                -73.824071162039,
                40.59929225994696
              ],
              [
                -73.82411388280298,
                40.59928535009736
              ],
              [
                -73.8241656989433,
                40.5992993798335
              ],
              [
                -73.82418595699569,
                40.59921342515718
              ],
              [
                -73.82421870388677,
                40.59907448795915
              ],
              [
                -73.82410854765287,
                40.598994726807504
              ],
              [
                -73.82406984190722,
                40.598922481127985
              ],
              [
                -73.82395853199995,
                40.59888785399993
              ],
              [
                -73.82395871079126,
                40.598876896838526
              ],
              [
                -73.82403414202359,
                40.59875797949145
              ],
              [
                -73.82418848174414,
                40.598520533113046
              ],
              [
                -73.82420916849752,
                40.59846490623819
              ],
              [
                -73.8242377886617,
                40.59841136182142
              ],
              [
                -73.82427399262099,
                40.59836055396409
              ],
              [
                -73.8243173368626,
                40.59831310502026
              ],
              [
                -73.82436729168752,
                40.598269595793205
              ],
              [
                -73.82442324545605,
                40.598230557983385
              ],
              [
                -73.8244845128283,
                40.598196469931
              ],
              [
                -73.8245680498839,
                40.598170124067785
              ],
              [
                -73.82465420648664,
                40.5981492468515
              ],
              [
                -73.82474236672088,
                40.59813398677497
              ],
              [
                -73.82481284576305,
                40.59813917377151
              ],
              [
                -73.82488221787078,
                40.598149983010046
              ],
              [
                -73.82494971364855,
                40.598166295016526
              ],
              [
                -73.82501458361149,
                40.59818792878772
              ],
              [
                -73.82507610784262,
                40.59821464379464
              ],
              [
                -73.82513360448421,
                40.59824614381482
              ],
              [
                -73.82518643452747,
                40.59828207907724
              ],
              [
                -73.82527294342636,
                40.59832610393456
              ],
              [
                -73.82535480469845,
                40.59837502005476
              ],
              [
                -73.82543154384715,
                40.598428544025026
              ],
              [
                -73.82550271573429,
                40.598486365064794
              ],
              [
                -73.82556790737216,
                40.59854814792653
              ],
              [
                -73.82562673962269,
                40.598613533868544
              ],
              [
                -73.82567887179285,
                40.59868214369584
              ],
              [
                -73.82572400162758,
                40.598753578859636
              ],
              [
                -73.82576186668572,
                40.598827425613045
              ],
              [
                -73.82579224760113,
                40.59890325471299
              ],
              [
                -73.82583141989248,
                40.5989511689041
              ],
              [
                -73.82586371395628,
                40.59900197663026
              ],
              [
                -73.82588876662335,
                40.59905510558804
              ],
              [
                -73.82590629495935,
                40.5991099579143
              ],
              [
                -73.82591610285141,
                40.59916591704907
              ],
              [
                -73.82591807911876,
                40.599222353706445
              ],
              [
                -73.82591220213257,
                40.59927863191615
              ],
              [
                -73.8258985379428,
                40.599334119095445
              ],
              [
                -73.82587723990645,
                40.59938819111076
              ],
              [
                -73.82584854782284,
                40.59944023978943
              ],
              [
                -73.82587819173699,
                40.59945454595633
              ],
              [
                -73.8259105971973,
                40.599464797799115
              ],
              [
                -73.82594481984405,
                40.599470695227495
              ],
              [
                -73.8259798598142,
                40.59947206718151
              ],
              [
                -73.82601469481251,
                40.59946887314232
              ],
              [
                -73.82604830803486,
                40.59946120621747
              ],
              [
                -73.82607971900187,
                40.59944929027962
              ],
              [
                -73.82610801087331,
                40.599433473202446
              ],
              [
                -73.82613235782432,
                40.599414216286036
              ],
              [
                -73.82617637580326,
                40.5993792674223
              ],
              [
                -73.82621457216418,
                40.59934054492624
              ],
              [
                -73.82624639580335,
                40.59929860721097
              ],
              [
                -73.82627138518978,
                40.59925406192512
              ],
              [
                -73.82628918018969,
                40.59920755206958
              ],
              [
                -73.82639370886822,
                40.59915043299936
              ],
              [
                -73.82649367583141,
                40.599088752048715
              ],
              [
                -73.82658873898603,
                40.59902272078796
              ],
              [
                -73.8266785731343,
                40.59895256423353
              ],
              [
                -73.82676287102854,
                40.59887852396064
              ],
              [
                -73.82684134389942,
                40.59880085219284
              ],
              [
                -73.82684357823473,
                40.59877954295343
              ],
              [
                -73.82685032481477,
                40.59875879306266
              ],
              [
                -73.82686140304179,
                40.5987391540985
              ],
              [
                -73.82687651978097,
                40.59872114738559
              ],
              [
                -73.82689527276901,
                40.598705252067255
              ],
              [
                -73.82691716394703,
                40.59869188925295
              ],
              [
                -73.8269416120754,
                40.598681414411445
              ],
              [
                -73.82696796795742,
                40.59867410615657
              ],
              [
                -73.82699553156307,
                40.59867015805246
              ],
              [
                -73.82702356982604,
                40.598669675034905
              ],
              [
                -73.82705133886849,
                40.598672670019184
              ],
              [
                -73.82707810089018,
                40.59867906322922
              ],
              [
                -73.82710314546546,
                40.598688685754354
              ],
              [
                -73.82717084795337,
                40.59861325700669
              ],
              [
                -73.82709706289336,
                40.598519099830945
              ],
              [
                -73.8271301911274,
                40.598431372963816
              ],
              [
                -73.82722092999175,
                40.598331195036586
              ],
              [
                -73.8274268880001,
                40.59820610885799
              ],
              [
                -73.82763273197193,
                40.59812491193231
              ],
              [
                -73.82784691502562,
                40.597999838383004
              ],
              [
                -73.82784710901738,
                40.597924601113014
              ],
              [
                -73.82794610384788,
                40.59781189487175
              ],
              [
                -73.82806159687702,
                40.59768040282441
              ],
              [
                -73.82832490242488,
                40.59764317810819
              ],
              [
                -73.82857156476862,
                40.59768116475648
              ],
              [
                -73.82874408619575,
                40.59776292884545
              ],
              [
                -73.82891681736452,
                40.597763185984704
              ],
              [
                -73.82909777411142,
                40.5977634551103
              ],
              [
                -73.82921276789511,
                40.597826322957275
              ],
              [
                -73.82926994412303,
                40.59798315098794
              ],
              [
                -73.8293519571253,
                40.59807731982705
              ],
              [
                -73.82948341811623,
                40.59813394195127
              ],
              [
                -73.82961515193035,
                40.598083979149216
              ],
              [
                -73.82975522196986,
                40.597990141848385
              ],
              [
                -73.82985422922734,
                40.59787116315062
              ],
              [
                -73.8300517969121,
                40.59780875786575
              ],
              [
                -73.83026568620224,
                40.59779653504858
              ],
              [
                -73.83038922491133,
                40.59773401979212
              ],
              [
                -73.83050466559352,
                40.597621334835594
              ],
              [
                -73.83049675901624,
                40.59749592809601
              ],
              [
                -73.83062852189495,
                40.59743342586203
              ],
              [
                -73.8307600942432,
                40.597446158154845
              ],
              [
                -73.83095699282346,
                40.597647080043
              ],
              [
                -73.83108000652722,
                40.597791464827154
              ],
              [
                -73.83126070906049,
                40.5978920460556
              ],
              [
                -73.83154105970068,
                40.5979510260275
              ],
              [
                -73.83239364476839,
                40.59780335319124
              ],
              [
                -73.83215095202706,
                40.597679598145966
              ],
              [
                -73.832385102244,
                40.597605631838505
              ],
              [
                -73.83273627105066,
                40.59751697454668
              ],
              [
                -73.83291189123968,
                40.59745778303809
              ],
              [
                -73.83336087711082,
                40.59723551119928
              ],
              [
                -73.83351748173193,
                40.59698309117374
              ],
              [
                -73.83386890489666,
                40.59679039969175
              ],
              [
                -73.83420068078192,
                40.59665712408205
              ],
              [
                -73.83439612701622,
                40.59646420572125
              ],
              [
                -73.83445520590696,
                40.59622650519835
              ],
              [
                -73.83461984485115,
                40.595997454103966
              ],
              [
                -73.83468997415086,
                40.595899888214426
              ],
              [
                -73.83484682806589,
                40.595543436053404
              ],
              [
                -73.83465599507487,
                40.59516845882608
              ],
              [
                -73.83381500012784,
                40.5949474849947
              ],
              [
                -73.83305498409688,
                40.59481262907002
              ],
              [
                -73.83241209682478,
                40.594618492810554
              ],
              [
                -73.83135504801284,
                40.59463006988999
              ],
              [
                -73.83048206013673,
                40.59458593813552
              ],
              [
                -73.82972156186142,
                40.594644261205836
              ],
              [
                -73.82937044176717,
                40.59471804715625
              ],
              [
                -73.82913626075876,
                40.594806869147725
              ],
              [
                -73.82876587213534,
                40.59479145596429
              ],
              [
                -73.82835645403685,
                40.59479084506242
              ],
              [
                -73.82798591286824,
                40.594834876017856
              ],
              [
                -73.8270499051601,
                40.59490777901204
              ],
              [
                -73.82625044687596,
                40.594951158754874
              ],
              [
                -73.82589974767566,
                40.59486145713528
              ],
              [
                -73.82565454783656,
                40.594964406762166
              ],
              [
                -73.82480251246255,
                40.59508316117646
              ],
              [
                -73.82394993688561,
                40.59493279504595
              ],
              [
                -73.82367706984253,
                40.594902653152204
              ],
              [
                -73.82301423918268,
                40.59488677298898
              ],
              [
                -73.82264377296077,
                40.59490106309344
              ],
              [
                -73.82223447124294,
                40.5948558458874
              ],
              [
                -73.82166936203785,
                40.59475093918041
              ],
              [
                -73.82135758260134,
                40.59469100912113
              ],
              [
                -73.82120137599598,
                40.594779935044805
              ],
              [
                -73.82108400042337,
                40.59492836885982
              ],
              [
                -73.8209309528666,
                40.595108061805306
              ],
              [
                -73.82079778694794,
                40.59515300679664
              ],
              [
                -73.82071206789055,
                40.59517051496212
              ],
              [
                -73.82065843490554,
                40.59527145203273
              ],
              [
                -73.82061381211892,
                40.59550607512599
              ],
              [
                -73.82059544842939,
                40.5956026301113
              ],
              [
                -73.82050002971006,
                40.595668108956055
              ],
              [
                -73.82047608663389,
                40.59569238693123
              ],
              [
                -73.82045791860484,
                40.59571941893889
              ],
              [
                -73.82044605664017,
                40.59574841492437
              ],
              [
                -73.82044084709868,
                40.5957785271302
              ],
              [
                -73.8204424431122,
                40.59580887598263
              ],
              [
                -73.8204507978603,
                40.595838573953884
              ],
              [
                -73.82045130963279,
                40.59583934575528
              ],
              [
                -73.82048386960405,
                40.595895585619374
              ],
              [
                -73.82050862648863,
                40.5959540899062
              ],
              [
                -73.82052531458038,
                40.59601423203291
              ],
              [
                -73.82053375492012,
                40.596075367637795
              ],
              [
                -73.820533857754,
                40.59613684003585
              ],
              [
                -73.82052562152057,
                40.59619799173206
              ],
              [
                -73.82050913385626,
                40.596258165953
              ],
              [
                -73.82048457262269,
                40.59631671816086
              ],
              [
                -73.82045805451784,
                40.59640229006972
              ],
              [
                -73.82042450677176,
                40.596486416145495
              ],
              [
                -73.82038406369512,
                40.596568761815114
              ],
              [
                -73.82033688583628,
                40.59664899805184
              ],
              [
                -73.82028316165791,
                40.59672680602921
              ],
              [
                -73.82022310416497,
                40.59680187575785
              ],
              [
                -73.82015695397838,
                40.596873907202536
              ],
              [
                -73.81973591782804,
                40.59751684313405
              ],
              [
                -73.8192814220081,
                40.597529610938246
              ],
              [
                -73.81899346411964,
                40.59826246312184
              ],
              [
                -73.81849066110368,
                40.599389559239626
              ],
              [
                -73.81842675420909,
                40.60008406892017
              ],
              [
                -73.81831121077079,
                40.60133971235741
              ],
              [
                -73.8177203266933,
                40.60222499987045
              ],
              [
                -73.81755792378435,
                40.602785760004345
              ],
              [
                -73.81725370596759,
                40.60394032459459
              ],
              [
                -73.81730244874,
                40.60420592104617
              ],
              [
                -73.81739192103247,
                40.605158124143394
              ],
              [
                -73.81642529961537,
                40.605224123786925
              ],
              [
                -73.81609284277334,
                40.605744093120286
              ],
              [
                -73.81598548513719,
                40.605733505216165
              ],
              [
                -73.8159419971478,
                40.60592688893786
              ],
              [
                -73.8158882978922,
                40.6059290341796
              ],
              [
                -73.81589026342208,
                40.60602533922078
              ],
              [
                -73.81591913921778,
                40.6060229408015
              ],
              [
                -73.8159191720938,
                40.60606152576282
              ],
              [
                -73.8159435787941,
                40.606061564806424
              ],
              [
                -73.81597032024115,
                40.606074362226664
              ],
              [
                -73.81596093586549,
                40.60613971703266
              ],
              [
                -73.81600875994269,
                40.606144258981395
              ],
              [
                -73.81600764380624,
                40.606194852030484
              ],
              [
                -73.81595962051257,
                40.60626248317676
              ],
              [
                -73.81585416784488,
                40.60634155617889
              ],
              [
                -73.81580703895482,
                40.606366406183014
              ],
              [
                -73.81576751589343,
                40.60637159210937
              ],
              [
                -73.81574174081904,
                40.606465245117725
              ],
              [
                -73.81568836082222,
                40.60661289096192
              ],
              [
                -73.81554657007985,
                40.60677293900015
              ],
              [
                -73.81526535891507,
                40.60700061106352
              ],
              [
                -73.81520607102756,
                40.607092544035126
              ],
              [
                -73.81506129889362,
                40.607317030139924
              ],
              [
                -73.81496328852214,
                40.607640594212285
              ],
              [
                -73.8149997124251,
                40.60782541316945
              ],
              [
                -73.81486539911208,
                40.60802059874155
              ],
              [
                -73.81482785097941,
                40.60823874224764
              ],
              [
                -73.81476257619768,
                40.608463880026925
              ],
              [
                -73.81475287105245,
                40.6086327967523
              ],
              [
                -73.81477073796228,
                40.60885102889005
              ],
              [
                -73.81491794505943,
                40.609055392201455
              ],
              [
                -73.81502615390193,
                40.609352013155714
              ],
              [
                -73.81520720849484,
                40.60981673379668
              ],
              [
                -73.81559858789666,
                40.610672516164726
              ],
              [
                -73.81559405486736,
                40.61086335714752
              ],
              [
                -73.81563522523048,
                40.61119560007353
              ],
              [
                -73.81573329159819,
                40.61146398411757
              ],
              [
                -73.81581602898463,
                40.61160489311677
              ],
              [
                -73.81585258478174,
                40.61174572809621
              ],
              [
                -73.81588914214508,
                40.61188656365409
              ],
              [
                -73.81586106373182,
                40.61202025698769
              ],
              [
                -73.81595301908956,
                40.612168218936766
              ],
              [
                -73.81602636695548,
                40.61236542423196
              ],
              [
                -73.81604375315976,
                40.61275962622101
              ],
              [
                -73.81615626616234,
                40.6129421157549
              ],
              [
                -73.81643150389779,
                40.614282334964926
              ],
              [
                -73.81647790560582,
                40.61434204897534
              ],
              [
                -73.81651669585267,
                40.614404836864594
              ],
              [
                -73.8165475286072,
                40.614470136266654
              ],
              [
                -73.8165701269355,
                40.61453736407744
              ],
              [
                -73.81658428973421,
                40.61460591806853
              ],
              [
                -73.81658988963738,
                40.61467518507706
              ],
              [
                -73.8165868765815,
                40.61474454525
              ],
              [
                -73.81657527753201,
                40.61481337831561
              ],
              [
                -73.81655519586245,
                40.614881068359615
              ],
              [
                -73.81652681189118,
                40.61494701007998
              ],
              [
                -73.81768666599999,
                40.614932997999844
              ],
              [
                -73.82075892499992,
                40.61523267899992
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000173946146651,
        "objectid": 34,
        "shape_leng": 0.0657059323545,
        "location_id": 34,
        "zone": "Brooklyn Navy Yard",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.97232032119902,
                40.709082883152185
              ],
              [
                -73.97275756998387,
                40.70883127854767
              ],
              [
                -73.97105692306172,
                40.706946433816704
              ],
              [
                -73.97100438685837,
                40.70682824094664
              ],
              [
                -73.97109655489108,
                40.705850056558276
              ],
              [
                -73.9712249291223,
                40.70578990041363
              ],
              [
                -73.97170524941599,
                40.70613603268091
              ],
              [
                -73.9722179484904,
                40.70650549303291
              ],
              [
                -73.97310627091164,
                40.707135569220554
              ],
              [
                -73.9742361696854,
                40.708024386122425
              ],
              [
                -73.97462966604415,
                40.70768106076819
              ],
              [
                -73.97329876359842,
                40.706697713774595
              ],
              [
                -73.97254880057812,
                40.706204490003806
              ],
              [
                -73.97347629598461,
                40.70568628339133
              ],
              [
                -73.97439083502317,
                40.70632577414475
              ],
              [
                -73.97465569608046,
                40.70607426299424
              ],
              [
                -73.97340440827125,
                40.70530433560405
              ],
              [
                -73.97101963118519,
                40.70381561368637
              ],
              [
                -73.97093307886338,
                40.70362477204871
              ],
              [
                -73.97110342298826,
                40.70345973583978
              ],
              [
                -73.97145657354827,
                40.70350871592453
              ],
              [
                -73.97215798824955,
                40.703970208128695
              ],
              [
                -73.97231246276117,
                40.70384453953858
              ],
              [
                -73.97147483845806,
                40.703316541316525
              ],
              [
                -73.96898517428966,
                40.70174708878653
              ],
              [
                -73.96893474818414,
                40.7016158682073
              ],
              [
                -73.96924539207352,
                40.70136474188359
              ],
              [
                -73.97183169654913,
                40.702978669579714
              ],
              [
                -73.97266938433755,
                40.70350138043112
              ],
              [
                -73.97283889974949,
                40.703346428601826
              ],
              [
                -73.96948655218084,
                40.70051906125818
              ],
              [
                -73.96982556559514,
                40.70024507036795
              ],
              [
                -73.97283512788735,
                40.70272874074404
              ],
              [
                -73.9733245610394,
                40.70241265601074
              ],
              [
                -73.97250810272976,
                40.70166238646294
              ],
              [
                -73.97254221267232,
                40.70163644279681
              ],
              [
                -73.97080524432837,
                40.70009750034227
              ],
              [
                -73.97078200587988,
                40.69997866301124
              ],
              [
                -73.97097896857517,
                40.69985645765218
              ],
              [
                -73.97113824583214,
                40.69991789325807
              ],
              [
                -73.97156913894533,
                40.7003006796445
              ],
              [
                -73.97284574196057,
                40.70143472341453
              ],
              [
                -73.9728840477196,
                40.70141679597599
              ],
              [
                -73.97318676670886,
                40.70166980367368
              ],
              [
                -73.973757007132,
                40.701534138881854
              ],
              [
                -73.97298290412095,
                40.70080626574634
              ],
              [
                -73.97262103106193,
                40.7004659955762
              ],
              [
                -73.97239201397464,
                40.70016632275229
              ],
              [
                -73.97257311967198,
                40.7000820561209
              ],
              [
                -73.97292642848618,
                40.70029455342767
              ],
              [
                -73.97328853602355,
                40.700624184552254
              ],
              [
                -73.97355597076056,
                40.70086763188122
              ],
              [
                -73.97354417557611,
                40.70089229252952
              ],
              [
                -73.973762109366,
                40.70108068054629
              ],
              [
                -73.97385488106505,
                40.70108398602825
              ],
              [
                -73.973888788485,
                40.701085194895654
              ],
              [
                -73.97411513926478,
                40.70130527141927
              ],
              [
                -73.97431887009537,
                40.70119515534329
              ],
              [
                -73.9751895311087,
                40.69987852225742
              ],
              [
                -73.97535541071757,
                40.69962766690227
              ],
              [
                -73.97537649873668,
                40.699601293466394
              ],
              [
                -73.97540301541854,
                40.699577929692055
              ],
              [
                -73.97543423386269,
                40.69955821604439
              ],
              [
                -73.97546929828381,
                40.69954269292822
              ],
              [
                -73.9755072474686,
                40.699531785873795
              ],
              [
                -73.97554704112723,
                40.69952579387291
              ],
              [
                -73.97558758840916,
                40.69952488118221
              ],
              [
                -73.9756277778049,
                40.699529072821015
              ],
              [
                -73.97566650761654,
                40.69953825388533
              ],
              [
                -73.97570271615527,
                40.699552172697445
              ],
              [
                -73.97573541084542,
                40.69957044770558
              ],
              [
                -73.9757636954341,
                40.69959257794206
              ],
              [
                -73.97578679455877,
                40.69961795675656
              ],
              [
                -73.97580407500232,
                40.6996458884458
              ],
              [
                -73.97581506305183,
                40.69967560732394
              ],
              [
                -73.97581945748546,
                40.699706298712556
              ],
              [
                -73.97581713783005,
                40.699737121272676
              ],
              [
                -73.97561192264291,
                40.70003731645754
              ],
              [
                -73.97455023388345,
                40.701590344304776
              ],
              [
                -73.97540348224568,
                40.7019111475675
              ],
              [
                -73.97550083176418,
                40.70175830896567
              ],
              [
                -73.97561086931884,
                40.70179681450737
              ],
              [
                -73.97560071840401,
                40.70180646678159
              ],
              [
                -73.97559301790524,
                40.70181378876838
              ],
              [
                -73.97581234475751,
                40.70189450427652
              ],
              [
                -73.9758141788267,
                40.701895182644606
              ],
              [
                -73.97589598081227,
                40.7019004713829
              ],
              [
                -73.97600587845436,
                40.70194613378181
              ],
              [
                -73.97598092723496,
                40.70197503589961
              ],
              [
                -73.97594746888169,
                40.702011494224436
              ],
              [
                -73.97594762102841,
                40.70201199650279
              ],
              [
                -73.97532987393976,
                40.70295192433834
              ],
              [
                -73.9755345648972,
                40.703042808805726
              ],
              [
                -73.97615937292022,
                40.70217128035684
              ],
              [
                -73.97634835593101,
                40.70229910574266
              ],
              [
                -73.97664059239023,
                40.702496766094974
              ],
              [
                -73.9769274918834,
                40.70279782040038
              ],
              [
                -73.97596096344841,
                40.70471550644049
              ],
              [
                -73.97623864768494,
                40.70477564419063
              ],
              [
                -73.97683246363418,
                40.703615741871225
              ],
              [
                -73.9770551895912,
                40.703178768397734
              ],
              [
                -73.97713562625376,
                40.703005581402515
              ],
              [
                -73.97729538355674,
                40.70318930808753
              ],
              [
                -73.97736083833688,
                40.70313952051068
              ],
              [
                -73.97751215494964,
                40.7031967526635
              ],
              [
                -73.97721196339239,
                40.70421392662423
              ],
              [
                -73.97726204580005,
                40.70422009633448
              ],
              [
                -73.97733878404608,
                40.7042295507855
              ],
              [
                -73.97735570894386,
                40.70417808977399
              ],
              [
                -73.97802067326661,
                40.70429096645619
              ],
              [
                -73.97817688845535,
                40.70446844361957
              ],
              [
                -73.97817256987122,
                40.70448584183551
              ],
              [
                -73.9781619969167,
                40.70452842757676
              ],
              [
                -73.97828301996252,
                40.7046665516455
              ],
              [
                -73.97844190715928,
                40.704871395843114
              ],
              [
                -73.97853198820685,
                40.70487652342964
              ],
              [
                -73.97859313620125,
                40.70497858414278
              ],
              [
                -73.97842683362268,
                40.7055568085091
              ],
              [
                -73.97845405328272,
                40.70556037523405
              ],
              [
                -73.97841617508323,
                40.705676048465634
              ],
              [
                -73.97839127010316,
                40.70567259692237
              ],
              [
                -73.978385599052,
                40.70570533367302
              ],
              [
                -73.9784059750022,
                40.70571050704935
              ],
              [
                -73.97837423698817,
                40.70583025182305
              ],
              [
                -73.97834706969712,
                40.70582507783397
              ],
              [
                -73.97834139805158,
                40.70585953726305
              ],
              [
                -73.97836290650389,
                40.70586384893295
              ],
              [
                -73.97837419227056,
                40.705968094468325
              ],
              [
                -73.97835155258467,
                40.705965506274495
              ],
              [
                -73.97834474852871,
                40.70599996498762
              ],
              [
                -73.9783877652644,
                40.70600600388105
              ],
              [
                -73.97839229957326,
                40.70598877473573
              ],
              [
                -73.97885303360711,
                40.7060655352846
              ],
              [
                -73.9788564309653,
                40.70605950510008
              ],
              [
                -73.97839909439138,
                40.705982745202384
              ],
              [
                -73.97840136144173,
                40.70597240719434
              ],
              [
                -73.97839230543774,
                40.7059706823108
              ],
              [
                -73.97838101898172,
                40.70586643677711
              ],
              [
                -73.97841271462556,
                40.70587075035046
              ],
              [
                -73.9784217833125,
                40.70583801473866
              ],
              [
                -73.97838895552232,
                40.705830254587326
              ],
              [
                -73.97842182374887,
                40.705713093959766
              ],
              [
                -73.97844899155665,
                40.705716545920126
              ],
              [
                -73.97845692665027,
                40.70568467134956
              ],
              [
                -73.97843202250104,
                40.70567863587692
              ],
              [
                -73.97846557848953,
                40.70556222186442
              ],
              [
                -73.97864529228849,
                40.70559102159563
              ],
              [
                -73.97868440584477,
                40.70545417710785
              ],
              [
                -73.97869698190469,
                40.705456260458085
              ],
              [
                -73.97860162154,
                40.70582296852472
              ],
              [
                -73.97863906658566,
                40.70583064822175
              ],
              [
                -73.97873829486646,
                40.7054630646721
              ],
              [
                -73.9788031024613,
                40.70547222712397
              ],
              [
                -73.97892765823725,
                40.70549619916906
              ],
              [
                -73.97881909040038,
                40.705862468084476
              ],
              [
                -73.97886517755374,
                40.7058679562003
              ],
              [
                -73.97896355081056,
                40.70550219650428
              ],
              [
                -73.97912179285028,
                40.70552703403615
              ],
              [
                -73.97932762499988,
                40.70474627599989
              ],
              [
                -73.97960292799985,
                40.704176399999916
              ],
              [
                -73.97998854099983,
                40.70354105799991
              ],
              [
                -73.98022432,
                40.7030633239999
              ],
              [
                -73.9806490779999,
                40.70156087299994
              ],
              [
                -73.98074079499993,
                40.70127730999999
              ],
              [
                -73.98040482499992,
                40.700699006999855
              ],
              [
                -73.98041744299996,
                40.70051815299991
              ],
              [
                -73.98044078599999,
                40.7001836299999
              ],
              [
                -73.98046217299986,
                40.69970315799986
              ],
              [
                -73.98048077899998,
                40.6993727139999
              ],
              [
                -73.98048604199988,
                40.69927922799994
              ],
              [
                -73.98054419199988,
                40.69824651199987
              ],
              [
                -73.97808892799996,
                40.6981383249999
              ],
              [
                -73.97753101699993,
                40.69811581199988
              ],
              [
                -73.97678642599985,
                40.69808694699988
              ],
              [
                -73.97578275800007,
                40.69804772999987
              ],
              [
                -73.97519315199986,
                40.69802218699994
              ],
              [
                -73.97478615599996,
                40.69800455299992
              ],
              [
                -73.9737751339999,
                40.6979593909999
              ],
              [
                -73.97332378599988,
                40.69793965099992
              ],
              [
                -73.97279477799988,
                40.69792083799993
              ],
              [
                -73.97178445299986,
                40.69788266399995
              ],
              [
                -73.97120281199977,
                40.697857989999925
              ],
              [
                -73.97076190899996,
                40.697839283999905
              ],
              [
                -73.9697278989999,
                40.69780401399987
              ],
              [
                -73.9693945009999,
                40.696080388999846
              ],
              [
                -73.96840655900003,
                40.6960364839999
              ],
              [
                -73.96741363299996,
                40.695998628999895
              ],
              [
                -73.96641979599997,
                40.69596410599996
              ],
              [
                -73.96546721499983,
                40.695932161999956
              ],
              [
                -73.9645507049999,
                40.696107591999876
              ],
              [
                -73.96434801500007,
                40.69618376599989
              ],
              [
                -73.96415417899982,
                40.696274449999876
              ],
              [
                -73.963971858,
                40.69637865499993
              ],
              [
                -73.963803372,
                40.69649503799992
              ],
              [
                -73.96365073599986,
                40.69662194399994
              ],
              [
                -73.96347515199992,
                40.69680066799991
              ],
              [
                -73.96331636199999,
                40.69698969799986
              ],
              [
                -73.96317583599986,
                40.697187620999934
              ],
              [
                -73.96305480199987,
                40.69739287199993
              ],
              [
                -73.96295416199992,
                40.69760374899989
              ],
              [
                -73.96288219599991,
                40.69782702599988
              ],
              [
                -73.96283963699993,
                40.698038667999946
              ],
              [
                -73.96236579999989,
                40.69960558599991
              ],
              [
                -73.96217978199998,
                40.700220709999904
              ],
              [
                -73.9625725539999,
                40.700512198999895
              ],
              [
                -73.96318252099992,
                40.701000094999905
              ],
              [
                -73.96386889399994,
                40.701541888999934
              ],
              [
                -73.96451844799995,
                40.70207497799988
              ],
              [
                -73.96520438999994,
                40.70262274499987
              ],
              [
                -73.96587418600001,
                40.703157694999895
              ],
              [
                -73.96607413599992,
                40.70332015299993
              ],
              [
                -73.96653747199994,
                40.70369661299985
              ],
              [
                -73.96720961999985,
                40.70425570899993
              ],
              [
                -73.96761547799979,
                40.7049616349999
              ],
              [
                -73.96795139100001,
                40.7057312169999
              ],
              [
                -73.96838933699995,
                40.70682918699989
              ],
              [
                -73.96929296374243,
                40.707093331047766
              ],
              [
                -73.96923632685235,
                40.70599431249845
              ],
              [
                -73.96929693837701,
                40.705088331229625
              ],
              [
                -73.96833263747038,
                40.70448338458415
              ],
              [
                -73.96747674493034,
                40.70394987095623
              ],
              [
                -73.96751516691086,
                40.70343735687813
              ],
              [
                -73.96762114779945,
                40.703348505194356
              ],
              [
                -73.97018075572805,
                40.70492927056685
              ],
              [
                -73.97016894509909,
                40.705361853407325
              ],
              [
                -73.97048881848585,
                40.70540066562183
              ],
              [
                -73.9702621853228,
                40.706887504284005
              ],
              [
                -73.97054062182238,
                40.707316076353834
              ],
              [
                -73.97066975742996,
                40.70725970882148
              ],
              [
                -73.97232032119902,
                40.709082883152185
              ]
            ]
          ],
          [
            [
              [
                -73.97892920137538,
                40.705931589879214
              ],
              [
                -73.97905767006142,
                40.70595288905031
              ],
              [
                -73.97906084872884,
                40.705946028470365
              ],
              [
                -73.97893110285521,
                40.705924906230344
              ],
              [
                -73.97892920137538,
                40.705931589879214
              ]
            ]
          ],
          [
            [
              [
                -73.98237340600002,
                40.70554334999991
              ],
              [
                -73.98232944175258,
                40.705552033831964
              ],
              [
                -73.98230205183869,
                40.70573697939969
              ],
              [
                -73.98101733900947,
                40.70579750646778
              ],
              [
                -73.98102390618274,
                40.705898913893535
              ],
              [
                -73.98242287179853,
                40.70582205595455
              ],
              [
                -73.98245898620758,
                40.70554241448613
              ],
              [
                -73.98237340600002,
                40.70554334999991
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000323825195932,
        "objectid": 35,
        "shape_leng": 0.085787918592,
        "location_id": 35,
        "zone": "Brownsville",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.90105329800004,
                40.662124820999956
              ],
              [
                -73.90145114700002,
                40.663658364999954
              ],
              [
                -73.90071711999985,
                40.66376455299994
              ],
              [
                -73.90050930099981,
                40.663793574999936
              ],
              [
                -73.90041654099997,
                40.663806526999934
              ],
              [
                -73.9008513569999,
                40.66534589499991
              ],
              [
                -73.90126570400001,
                40.666880649999925
              ],
              [
                -73.90166767899989,
                40.6684135289999
              ],
              [
                -73.90198218099997,
                40.669670070999906
              ],
              [
                -73.90228822599984,
                40.67093600699995
              ],
              [
                -73.90266008399995,
                40.6721971789999
              ],
              [
                -73.90296768999997,
                40.67343939499993
              ],
              [
                -73.9032213189999,
                40.67447291399992
              ],
              [
                -73.90344272399983,
                40.67537791899986
              ],
              [
                -73.90346228899996,
                40.67545791899992
              ],
              [
                -73.90347422300006,
                40.67550668299987
              ],
              [
                -73.90374374599986,
                40.67540683399991
              ],
              [
                -73.90428786799986,
                40.67522935599987
              ],
              [
                -73.90438792800002,
                40.67520674699994
              ],
              [
                -73.90450912299991,
                40.67505211099989
              ],
              [
                -73.90524012899992,
                40.67472494599988
              ],
              [
                -73.90582626799984,
                40.6744696539999
              ],
              [
                -73.90609438799999,
                40.67435293199991
              ],
              [
                -73.90629662099994,
                40.67426533299991
              ],
              [
                -73.9069565549999,
                40.67398525899991
              ],
              [
                -73.90746843099997,
                40.673761613999915
              ],
              [
                -73.90787711599997,
                40.6735668089999
              ],
              [
                -73.90827800999982,
                40.67337939399994
              ],
              [
                -73.90832954199989,
                40.673355302999894
              ],
              [
                -73.90841931599985,
                40.6733133329999
              ],
              [
                -73.90925438099987,
                40.67294799799986
              ],
              [
                -73.90954171999984,
                40.672828802999966
              ],
              [
                -73.90989833499988,
                40.67268086899987
              ],
              [
                -73.90995832899995,
                40.67265598099992
              ],
              [
                -73.91014265099993,
                40.6725795189999
              ],
              [
                -73.91019287099996,
                40.672558684999906
              ],
              [
                -73.91037473899996,
                40.6724832379999
              ],
              [
                -73.910463053,
                40.67244660199987
              ],
              [
                -73.91104631099985,
                40.672204641999954
              ],
              [
                -73.91109867399982,
                40.67218291999989
              ],
              [
                -73.9111599549999,
                40.67215535099995
              ],
              [
                -73.91133533699998,
                40.672076452999875
              ],
              [
                -73.91221776899997,
                40.671687162999895
              ],
              [
                -73.91308574699985,
                40.67131022599989
              ],
              [
                -73.91403857199998,
                40.67089683999989
              ],
              [
                -73.91454485599989,
                40.67067547299996
              ],
              [
                -73.91461857800005,
                40.670643237999904
              ],
              [
                -73.91487246299994,
                40.6705322279999
              ],
              [
                -73.91553422600005,
                40.67024338399993
              ],
              [
                -73.91558467299986,
                40.67022136299989
              ],
              [
                -73.9157287449999,
                40.670158477999955
              ],
              [
                -73.91580361899986,
                40.670126027999906
              ],
              [
                -73.91586213199994,
                40.670100668999964
              ],
              [
                -73.9164716349998,
                40.669836508999886
              ],
              [
                -73.91652296199993,
                40.66981426399985
              ],
              [
                -73.91658459999987,
                40.66978754899991
              ],
              [
                -73.9171358899999,
                40.66954376399992
              ],
              [
                -73.917482661,
                40.6693922309999
              ],
              [
                -73.91838191199984,
                40.669002246999874
              ],
              [
                -73.91923062999996,
                40.668631364999925
              ],
              [
                -73.91999873699986,
                40.668409483999916
              ],
              [
                -73.92011464299999,
                40.668376001999924
              ],
              [
                -73.92014864299996,
                40.668229483999916
              ],
              [
                -73.9210198879999,
                40.66785269799992
              ],
              [
                -73.92187171199996,
                40.667480689999906
              ],
              [
                -73.92221564099988,
                40.6673334459999
              ],
              [
                -73.92275257199998,
                40.66709733299991
              ],
              [
                -73.92281106599998,
                40.66707176099994
              ],
              [
                -73.92303439399991,
                40.66697412599995
              ],
              [
                -73.92311978499988,
                40.666936793999945
              ],
              [
                -73.92323224599996,
                40.666887627999856
              ],
              [
                -73.92331039799996,
                40.66685345999985
              ],
              [
                -73.92424467299992,
                40.66644499999989
              ],
              [
                -73.924390994,
                40.666381422999926
              ],
              [
                -73.92546270199979,
                40.665916751999916
              ],
              [
                -73.92592201499984,
                40.66571503599991
              ],
              [
                -73.92637970499992,
                40.66551489199993
              ],
              [
                -73.92454945899979,
                40.66386259199996
              ],
              [
                -73.92272843000003,
                40.662231231999904
              ],
              [
                -73.9209090419998,
                40.66059911499987
              ],
              [
                -73.92021313099984,
                40.65997544099987
              ],
              [
                -73.92008167099986,
                40.659857632999866
              ],
              [
                -73.919986348,
                40.6601154659999
              ],
              [
                -73.91990440499988,
                40.66033711699987
              ],
              [
                -73.91974399499986,
                40.6607709739999
              ],
              [
                -73.91966280899982,
                40.66140503299994
              ],
              [
                -73.91962719199988,
                40.6617080589999
              ],
              [
                -73.9195947979998,
                40.661995678999865
              ],
              [
                -73.91945727799987,
                40.66188078499988
              ],
              [
                -73.91920450999991,
                40.661655199999856
              ],
              [
                -73.91893607399996,
                40.661416010999936
              ],
              [
                -73.91860168199995,
                40.66111804899986
              ],
              [
                -73.91829936299985,
                40.66116125699987
              ],
              [
                -73.91818493100003,
                40.66072620899997
              ],
              [
                -73.91741819199994,
                40.66005119199989
              ],
              [
                -73.91707363499995,
                40.65974535399988
              ],
              [
                -73.91572803800001,
                40.65855012799989
              ],
              [
                -73.91556629599982,
                40.658392571999876
              ],
              [
                -73.91441247700004,
                40.657362418999845
              ],
              [
                -73.91402878099997,
                40.65700370899985
              ],
              [
                -73.91377943699983,
                40.65678367899994
              ],
              [
                -73.91314356299998,
                40.65622086399992
              ],
              [
                -73.91236458899995,
                40.655513564999865
              ],
              [
                -73.91199538399984,
                40.65556155999992
              ],
              [
                -73.91108323599995,
                40.655702392999935
              ],
              [
                -73.91012255699995,
                40.65585177099993
              ],
              [
                -73.90977962099994,
                40.654466254999925
              ],
              [
                -73.90975756199991,
                40.654367116999865
              ],
              [
                -73.90974279799993,
                40.65431111199997
              ],
              [
                -73.90972827499985,
                40.65425186399989
              ],
              [
                -73.9097001379998,
                40.65415294699991
              ],
              [
                -73.90933499299997,
                40.65280859199989
              ],
              [
                -73.90855790499995,
                40.652095937999896
              ],
              [
                -73.90821606899983,
                40.65231208699989
              ],
              [
                -73.90740035699999,
                40.6528439899999
              ],
              [
                -73.90780156999992,
                40.654438948999925
              ],
              [
                -73.90782538299986,
                40.65453360399992
              ],
              [
                -73.90784346399994,
                40.65459152999989
              ],
              [
                -73.90692334299995,
                40.65473841999989
              ],
              [
                -73.90606783199999,
                40.6548638509999
              ],
              [
                -73.90556272799998,
                40.65493790999987
              ],
              [
                -73.90507059799984,
                40.655010060999935
              ],
              [
                -73.90489261799986,
                40.65503619899993
              ],
              [
                -73.90446144000003,
                40.6550995359999
              ],
              [
                -73.90414221299994,
                40.65514642099992
              ],
              [
                -73.90317858399993,
                40.655311633999915
              ],
              [
                -73.90298366699997,
                40.655380780999884
              ],
              [
                -73.90279641599982,
                40.655463210999876
              ],
              [
                -73.90261911399996,
                40.65555810999991
              ],
              [
                -73.9024538329999,
                40.65566436599986
              ],
              [
                -73.90230238299979,
                40.65578061099992
              ],
              [
                -73.901185599,
                40.6565145509999
              ],
              [
                -73.90064904799996,
                40.657041557999904
              ],
              [
                -73.900574915,
                40.65709994699991
              ],
              [
                -73.90048767199994,
                40.65714867899988
              ],
              [
                -73.9003948669999,
                40.65718420499992
              ],
              [
                -73.9002942619999,
                40.657208882999925
              ],
              [
                -73.90002679299982,
                40.657253847999876
              ],
              [
                -73.89988986899985,
                40.6572745209999
              ],
              [
                -73.89968548099989,
                40.65729793699993
              ],
              [
                -73.89948109299995,
                40.65732134299993
              ],
              [
                -73.899041804,
                40.657371657999875
              ],
              [
                -73.89891727499989,
                40.6573902249999
              ],
              [
                -73.89880698499995,
                40.65740666199987
              ],
              [
                -73.89881834799998,
                40.657453812999925
              ],
              [
                -73.89883571499976,
                40.65752594199996
              ],
              [
                -73.89921760899985,
                40.65911140399995
              ],
              [
                -73.89965233299985,
                40.66073541599994
              ],
              [
                -73.89972880499995,
                40.66070453499987
              ],
              [
                -73.89988908799995,
                40.66063980699986
              ],
              [
                -73.900146584,
                40.660653276999874
              ],
              [
                -73.90066118699986,
                40.66058615799992
              ],
              [
                -73.90105329800004,
                40.662124820999956
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000247666139269,
        "objectid": 36,
        "shape_leng": 0.0870507867063,
        "location_id": 36,
        "zone": "Bushwick North",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.91254913799993,
                40.7038734199999
              ],
              [
                -73.91329162399998,
                40.70431263899994
              ],
              [
                -73.91403097499997,
                40.7047491129999
              ],
              [
                -73.91477063799996,
                40.70518943699992
              ],
              [
                -73.91551250499982,
                40.705626444999965
              ],
              [
                -73.91589738599997,
                40.70585481699986
              ],
              [
                -73.91625251999977,
                40.706065542999916
              ],
              [
                -73.91660892400002,
                40.70627549499992
              ],
              [
                -73.91664948199995,
                40.70629938799995
              ],
              [
                -73.9169950679999,
                40.70650296499988
              ],
              [
                -73.91736520300005,
                40.70672246899992
              ],
              [
                -73.91773662899993,
                40.70694273899988
              ],
              [
                -73.91808167700003,
                40.70714732999993
              ],
              [
                -73.918102996,
                40.707159968999896
              ],
              [
                -73.91847670899989,
                40.70738156299989
              ],
              [
                -73.91884342599998,
                40.70759799799987
              ],
              [
                -73.91922162699984,
                40.70781513399986
              ],
              [
                -73.919564734,
                40.708023977999915
              ],
              [
                -73.91995952899983,
                40.708257287999885
              ],
              [
                -73.92070065899999,
                40.708694629999876
              ],
              [
                -73.92143475999987,
                40.709128765999914
              ],
              [
                -73.92189184700005,
                40.709396096999896
              ],
              [
                -73.92248241799982,
                40.709332487999895
              ],
              [
                -73.92418647499989,
                40.7090647289999
              ],
              [
                -73.92576502499996,
                40.70880376399988
              ],
              [
                -73.92740683799987,
                40.70854132199989
              ],
              [
                -73.92902566099997,
                40.70827681499986
              ],
              [
                -73.9320728499999,
                40.70778578599988
              ],
              [
                -73.93229708600003,
                40.707749446999884
              ],
              [
                -73.93391870799988,
                40.70748702599992
              ],
              [
                -73.93379054199995,
                40.707066574999935
              ],
              [
                -73.93371088099994,
                40.70679153099994
              ],
              [
                -73.93359321499992,
                40.70637065399994
              ],
              [
                -73.93351245499996,
                40.70609483499993
              ],
              [
                -73.93340041899981,
                40.7056682879999
              ],
              [
                -73.93331989699992,
                40.70539302699995
              ],
              [
                -73.93320124199991,
                40.70497168999993
              ],
              [
                -73.93312548700004,
                40.70470086199992
              ],
              [
                -73.9330107649998,
                40.70427708599988
              ],
              [
                -73.93293503499982,
                40.70399456099993
              ],
              [
                -73.93281494999994,
                40.70357711199993
              ],
              [
                -73.93269784899994,
                40.703170390999894
              ],
              [
                -73.93172871799996,
                40.7035459909999
              ],
              [
                -73.93115533899987,
                40.70377233499987
              ],
              [
                -73.93059386199991,
                40.70347330799992
              ],
              [
                -73.92984252699986,
                40.70304680999989
              ],
              [
                -73.92909071699984,
                40.70261967799992
              ],
              [
                -73.92833850299998,
                40.70219163999997
              ],
              [
                -73.92758774299998,
                40.70176258099985
              ],
              [
                -73.92683805799987,
                40.70133420999986
              ],
              [
                -73.92608301599982,
                40.700910144999945
              ],
              [
                -73.92532959999984,
                40.70048119499994
              ],
              [
                -73.92458241299997,
                40.700055251999906
              ],
              [
                -73.92383267099994,
                40.699627797999916
              ],
              [
                -73.92308169399985,
                40.6992006719999
              ],
              [
                -73.92268944899992,
                40.69897762199984
              ],
              [
                -73.92232801999992,
                40.69877209799996
              ],
              [
                -73.92175647299993,
                40.6984533169999
              ],
              [
                -73.92157402199997,
                40.69834550899992
              ],
              [
                -73.92082397399983,
                40.697917657999916
              ],
              [
                -73.9204962049999,
                40.697730838999924
              ],
              [
                -73.92043053999987,
                40.69769341199991
              ],
              [
                -73.92007372399986,
                40.69749003399989
              ],
              [
                -73.91960508499987,
                40.697222642999876
              ],
              [
                -73.91956944199978,
                40.6972023069999
              ],
              [
                -73.91932072899982,
                40.69706039699991
              ],
              [
                -73.91856440699996,
                40.69663009399995
              ],
              [
                -73.91848765699991,
                40.69658666399988
              ],
              [
                -73.91842289799997,
                40.69655001999996
              ],
              [
                -73.91835160499986,
                40.696509676999874
              ],
              [
                -73.91824631899986,
                40.6964500989999
              ],
              [
                -73.91812143899978,
                40.69637943199997
              ],
              [
                -73.91800959500003,
                40.696316141999944
              ],
              [
                -73.91781824899988,
                40.69620786299992
              ],
              [
                -73.917698671,
                40.69613974499987
              ],
              [
                -73.91760570499983,
                40.696086786999956
              ],
              [
                -73.91750809599985,
                40.696031183999914
              ],
              [
                -73.91743326099993,
                40.695988553999875
              ],
              [
                -73.917361666,
                40.69594776899986
              ],
              [
                -73.91728545099988,
                40.69590435299996
              ],
              [
                -73.91707162999998,
                40.6957825459999
              ],
              [
                -73.91691887399988,
                40.69569567199989
              ],
              [
                -73.91686074399993,
                40.69566261299992
              ],
              [
                -73.91677235199991,
                40.695612342999944
              ],
              [
                -73.91670957700003,
                40.69557664199987
              ],
              [
                -73.91661144299985,
                40.6955208309999
              ],
              [
                -73.91655004899992,
                40.6954859149999
              ],
              [
                -73.91631325699998,
                40.695351245999845
              ],
              [
                -73.91556201299993,
                40.69492212499987
              ],
              [
                -73.91506798799989,
                40.694643395999954
              ],
              [
                -73.91502132199989,
                40.69461706699989
              ],
              [
                -73.91480825299999,
                40.694496851999915
              ],
              [
                -73.91405670900002,
                40.694068467999884
              ],
              [
                -73.91331306200003,
                40.69363224199988
              ],
              [
                -73.91255469999996,
                40.69321201999985
              ],
              [
                -73.911805654,
                40.6927850819999
              ],
              [
                -73.91105301,
                40.692357245999894
              ],
              [
                -73.91029860299984,
                40.69192995199992
              ],
              [
                -73.90955061499984,
                40.69150297299994
              ],
              [
                -73.90879653399988,
                40.691074750999896
              ],
              [
                -73.9080425449999,
                40.690647001999885
              ],
              [
                -73.90729183099991,
                40.690219069999905
              ],
              [
                -73.90654394399994,
                40.68979093699993
              ],
              [
                -73.9057928679998,
                40.68936474999989
              ],
              [
                -73.90504399299995,
                40.68893224099988
              ],
              [
                -73.90482499699988,
                40.68880856999994
              ],
              [
                -73.9044739719999,
                40.68866816099992
              ],
              [
                -73.90419686399987,
                40.68858474399986
              ],
              [
                -73.904055772,
                40.68854627799987
              ],
              [
                -73.90180467199994,
                40.6907662979999
              ],
              [
                -73.90123290699994,
                40.69144227899996
              ],
              [
                -73.90148893599996,
                40.6915568839999
              ],
              [
                -73.90170525399989,
                40.69166024399991
              ],
              [
                -73.90207277399993,
                40.69199923099992
              ],
              [
                -73.9027906169999,
                40.69242104699992
              ],
              [
                -73.90354057099982,
                40.692848781999906
              ],
              [
                -73.90386600499991,
                40.693035711999904
              ],
              [
                -73.90428792799995,
                40.69327806799992
              ],
              [
                -73.90504295399994,
                40.6937040059999
              ],
              [
                -73.90579597099993,
                40.69412715499988
              ],
              [
                -73.90491424299982,
                40.69507357099999
              ],
              [
                -73.90480435399992,
                40.695148168999914
              ],
              [
                -73.9042601839999,
                40.6957003709999
              ],
              [
                -73.90466384799993,
                40.69593224699988
              ],
              [
                -73.90500484799992,
                40.696118745999904
              ],
              [
                -73.90540127699988,
                40.69635030599994
              ],
              [
                -73.90576535999983,
                40.6965485549999
              ],
              [
                -73.906116261,
                40.6967505569999
              ],
              [
                -73.90652350699987,
                40.69697614399997
              ],
              [
                -73.90684583499988,
                40.697163548999875
              ],
              [
                -73.90727452399994,
                40.69739918999991
              ],
              [
                -73.9075698519999,
                40.69757174999995
              ],
              [
                -73.9080224919999,
                40.69782540899994
              ],
              [
                -73.90877366099991,
                40.69825522699988
              ],
              [
                -73.90953273199997,
                40.69867510499995
              ],
              [
                -73.91103489599992,
                40.6995321139999
              ],
              [
                -73.91180820099987,
                40.699938002999865
              ],
              [
                -73.91067882699998,
                40.701045968999914
              ],
              [
                -73.91142242699996,
                40.70148658799998
              ],
              [
                -73.91216169799988,
                40.701923163999915
              ],
              [
                -73.912904041,
                40.70236189099993
              ],
              [
                -73.91180710099985,
                40.70343495199994
              ],
              [
                -73.91254913799993,
                40.7038734199999
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000452062644782,
        "objectid": 37,
        "shape_leng": 0.142810040466,
        "location_id": 37,
        "zone": "Bushwick South",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.93312548700004,
                40.70470086199992
              ],
              [
                -73.93320124099985,
                40.70497168999993
              ],
              [
                -73.93331989699992,
                40.70539302699995
              ],
              [
                -73.93340041899981,
                40.7056682879999
              ],
              [
                -73.93351245499996,
                40.70609483499993
              ],
              [
                -73.93359321499992,
                40.70637065399994
              ],
              [
                -73.93371087999984,
                40.70679153099998
              ],
              [
                -73.93379054199995,
                40.707066574999935
              ],
              [
                -73.93391870799988,
                40.70748702599992
              ],
              [
                -73.93229708600003,
                40.707749446999884
              ],
              [
                -73.93252310799994,
                40.70823818799993
              ],
              [
                -73.9326921559999,
                40.70863203599986
              ],
              [
                -73.93299690799994,
                40.709317159999905
              ],
              [
                -73.93329596399984,
                40.70998771099993
              ],
              [
                -73.933601465,
                40.71066910599991
              ],
              [
                -73.93389272699982,
                40.711335677999905
              ],
              [
                -73.93425709399997,
                40.712123023999936
              ],
              [
                -73.93453328999989,
                40.71275330399992
              ],
              [
                -73.93480281900003,
                40.7133724959999
              ],
              [
                -73.93724803599986,
                40.71278274099989
              ],
              [
                -73.93832001399984,
                40.71252368499994
              ],
              [
                -73.93846617699984,
                40.71248925599993
              ],
              [
                -73.94068803099992,
                40.711962047999876
              ],
              [
                -73.9404771349999,
                40.71156264299988
              ],
              [
                -73.94042251999994,
                40.711217647999945
              ],
              [
                -73.94075355899997,
                40.71118651299991
              ],
              [
                -73.94116852899994,
                40.711147482999955
              ],
              [
                -73.94159179599998,
                40.71110767099993
              ],
              [
                -73.94196964799987,
                40.7110721309999
              ],
              [
                -73.94210580699989,
                40.71105932199989
              ],
              [
                -73.94245994699992,
                40.7110258639999
              ],
              [
                -73.94283637099988,
                40.71099029799991
              ],
              [
                -73.94326978899984,
                40.71094934499994
              ],
              [
                -73.94357731299993,
                40.71092028699991
              ],
              [
                -73.94378799599994,
                40.710900378999945
              ],
              [
                -73.94368828299991,
                40.71029296399993
              ],
              [
                -73.94367685499988,
                40.71022334799989
              ],
              [
                -73.94358512999999,
                40.70966450499994
              ],
              [
                -73.94357094699997,
                40.709578093999944
              ],
              [
                -73.94355745099986,
                40.709495864999965
              ],
              [
                -73.94354434699991,
                40.70941603099987
              ],
              [
                -73.94344009799994,
                40.70878089099988
              ],
              [
                -73.94332294399995,
                40.70807019499994
              ],
              [
                -73.94320222899984,
                40.7073347509999
              ],
              [
                -73.94308587399995,
                40.70659666199991
              ],
              [
                -73.94296619399988,
                40.705888439999924
              ],
              [
                -73.94285013599988,
                40.70518202999993
              ],
              [
                -73.94273448399997,
                40.70447330599988
              ],
              [
                -73.9422114949999,
                40.7045232859999
              ],
              [
                -73.9421432489999,
                40.7045298069999
              ],
              [
                -73.9417696579999,
                40.704565507999845
              ],
              [
                -73.94168041699996,
                40.70457403599988
              ],
              [
                -73.94103009699987,
                40.704636176999905
              ],
              [
                -73.9408928719998,
                40.70391415699989
              ],
              [
                -73.94077939399978,
                40.703222468999904
              ],
              [
                -73.94076481199984,
                40.703134032999884
              ],
              [
                -73.94067555399978,
                40.70259265099993
              ],
              [
                -73.9406199669999,
                40.70225544999991
              ],
              [
                -73.94060119499999,
                40.702141580999886
              ],
              [
                -73.9405445659999,
                40.70179804499987
              ],
              [
                -73.94042057899993,
                40.70107659799989
              ],
              [
                -73.94193078899983,
                40.7007252339999
              ],
              [
                -73.94438788599984,
                40.70042452299993
              ],
              [
                -73.94424286099989,
                40.699699279999926
              ],
              [
                -73.94409591299996,
                40.698972954999924
              ],
              [
                -73.94394947299996,
                40.698221278999924
              ],
              [
                -73.94299724499987,
                40.69833009799987
              ],
              [
                -73.9429206409998,
                40.698338850999974
              ],
              [
                -73.94253735900001,
                40.69838264999991
              ],
              [
                -73.94247471499993,
                40.698389807999945
              ],
              [
                -73.94185058700002,
                40.69846112399996
              ],
              [
                -73.94176673799987,
                40.69847070399986
              ],
              [
                -73.94115035299995,
                40.69854113199994
              ],
              [
                -73.94081408799997,
                40.69858111999987
              ],
              [
                -73.94072013299994,
                40.69859229399992
              ],
              [
                -73.9405942079999,
                40.698607267999904
              ],
              [
                -73.94051277999984,
                40.69861695199995
              ],
              [
                -73.94011148799991,
                40.69866467199989
              ],
              [
                -73.94002089599995,
                40.69867544399993
              ],
              [
                -73.93960973999992,
                40.69872433499995
              ],
              [
                -73.93952025999982,
                40.69873497499989
              ],
              [
                -73.93906425899979,
                40.698789194999925
              ],
              [
                -73.93896470899988,
                40.69880103199995
              ],
              [
                -73.93856854799981,
                40.69884813599989
              ],
              [
                -73.93817718599986,
                40.69864022299987
              ],
              [
                -73.93777354599992,
                40.69840469299989
              ],
              [
                -73.93739064799986,
                40.69818579499985
              ],
              [
                -73.93675978999995,
                40.69783118899989
              ],
              [
                -73.93638762599981,
                40.69761530199985
              ],
              [
                -73.93600679799988,
                40.69740574899992
              ],
              [
                -73.93522264399988,
                40.69698654499995
              ],
              [
                -73.93479699699994,
                40.69673659199992
              ],
              [
                -73.93468892700004,
                40.696674953999946
              ],
              [
                -73.93460675199995,
                40.696628084999865
              ],
              [
                -73.93457999399985,
                40.69661282399995
              ],
              [
                -73.93448784799989,
                40.696560267999914
              ],
              [
                -73.93413397299992,
                40.696358430999936
              ],
              [
                -73.93382817199998,
                40.69618427699988
              ],
              [
                -73.93311862899988,
                40.69579115399997
              ],
              [
                -73.93236605699987,
                40.69535837699995
              ],
              [
                -73.93192890099984,
                40.69511465699991
              ],
              [
                -73.93177404599997,
                40.69502821499992
              ],
              [
                -73.93145784300005,
                40.6948554709999
              ],
              [
                -73.93114516499988,
                40.69467397499987
              ],
              [
                -73.93051146699992,
                40.694316522999905
              ],
              [
                -73.9297019239999,
                40.69386339699993
              ],
              [
                -73.92882897899982,
                40.69335047399987
              ],
              [
                -73.9288283419999,
                40.693350098999936
              ],
              [
                -73.92864268999999,
                40.6932410129999
              ],
              [
                -73.92850659699988,
                40.693165725999904
              ],
              [
                -73.92804330699992,
                40.69290417199992
              ],
              [
                -73.92781120699985,
                40.692774208999936
              ],
              [
                -73.92750685299994,
                40.69260381099993
              ],
              [
                -73.92707069499986,
                40.692346223999955
              ],
              [
                -73.92644992099981,
                40.69200176899993
              ],
              [
                -73.92631612499989,
                40.691928452999925
              ],
              [
                -73.92556064099996,
                40.69149083999987
              ],
              [
                -73.92536488899991,
                40.69136634399996
              ],
              [
                -73.92439183300002,
                40.690831748999926
              ],
              [
                -73.92429213299994,
                40.69077335699992
              ],
              [
                -73.9236437979999,
                40.69039883799992
              ],
              [
                -73.9231696669999,
                40.690123405999884
              ],
              [
                -73.922807024,
                40.68992392499995
              ],
              [
                -73.92207133799984,
                40.68948984099993
              ],
              [
                -73.92130587799981,
                40.68907164399991
              ],
              [
                -73.92121369999991,
                40.68901947699987
              ],
              [
                -73.92096103899999,
                40.688872051999915
              ],
              [
                -73.92055013100003,
                40.68864092199994
              ],
              [
                -73.9198096189999,
                40.688211240999856
              ],
              [
                -73.91906109199991,
                40.68778436899987
              ],
              [
                -73.91878323299994,
                40.687626087999924
              ],
              [
                -73.91829345799982,
                40.687356667999936
              ],
              [
                -73.91804607,
                40.68721324799989
              ],
              [
                -73.91768113899987,
                40.68700409499997
              ],
              [
                -73.91755226099991,
                40.68693022299989
              ],
              [
                -73.916798329,
                40.68649719899989
              ],
              [
                -73.9166103199999,
                40.68639135299993
              ],
              [
                -73.91604599299995,
                40.68607206899986
              ],
              [
                -73.91550033999997,
                40.68576152399992
              ],
              [
                -73.91528963499992,
                40.68564553599988
              ],
              [
                -73.91470083399992,
                40.685316944999876
              ],
              [
                -73.91453972399987,
                40.68522691799994
              ],
              [
                -73.91379700199988,
                40.684799871999864
              ],
              [
                -73.91330728099987,
                40.684520857999914
              ],
              [
                -73.91303721399987,
                40.684367809999884
              ],
              [
                -73.91227301199991,
                40.68393496499991
              ],
              [
                -73.911511929,
                40.683504989999896
              ],
              [
                -73.91076954399978,
                40.68309270099994
              ],
              [
                -73.91002460999994,
                40.68265737099987
              ],
              [
                -73.90927462899988,
                40.68223432399993
              ],
              [
                -73.90905526999994,
                40.682105574999895
              ],
              [
                -73.90886025599995,
                40.681994035999914
              ],
              [
                -73.90851642300001,
                40.681803942999906
              ],
              [
                -73.90777158799989,
                40.68137784699997
              ],
              [
                -73.90701820299994,
                40.680948279999896
              ],
              [
                -73.90671941199996,
                40.68076742199987
              ],
              [
                -73.90628287099989,
                40.68050675599993
              ],
              [
                -73.90565934199981,
                40.68014069099989
              ],
              [
                -73.905548368,
                40.68007160199991
              ],
              [
                -73.90549303499996,
                40.680037157999934
              ],
              [
                -73.9054141659999,
                40.67999264299987
              ],
              [
                -73.90479113399992,
                40.67964096499992
              ],
              [
                -73.90456891299982,
                40.67951553699992
              ],
              [
                -73.90404639799989,
                40.679220597999866
              ],
              [
                -73.90358874799992,
                40.67967480399991
              ],
              [
                -73.9026226229999,
                40.6806645029999
              ],
              [
                -73.90075235999984,
                40.67967664399993
              ],
              [
                -73.90047001100002,
                40.679535243999936
              ],
              [
                -73.89969830299987,
                40.67954409199993
              ],
              [
                -73.89889928299989,
                40.67956093399993
              ],
              [
                -73.89870944799999,
                40.6796301699999
              ],
              [
                -73.898489978,
                40.679670508999926
              ],
              [
                -73.89827418999997,
                40.6797250429999
              ],
              [
                -73.89806444599996,
                40.67979343099989
              ],
              [
                -73.89786299899986,
                40.67987501099987
              ],
              [
                -73.89767194200002,
                40.67996885399991
              ],
              [
                -73.89749312100001,
                40.6800737759999
              ],
              [
                -73.89738856299982,
                40.680162951999904
              ],
              [
                -73.89729111699982,
                40.68025667899985
              ],
              [
                -73.89720112199988,
                40.680354629999904
              ],
              [
                -73.89711889399996,
                40.68045646099994
              ],
              [
                -73.89704472199993,
                40.68056181599997
              ],
              [
                -73.89697886299997,
                40.68067032699992
              ],
              [
                -73.89692154999993,
                40.68078161299992
              ],
              [
                -73.89687298299991,
                40.68089528499995
              ],
              [
                -73.89674965999993,
                40.68149202599987
              ],
              [
                -73.89663536799998,
                40.681556494999924
              ],
              [
                -73.89658158699996,
                40.6815747179999
              ],
              [
                -73.89652633199988,
                40.68200291799991
              ],
              [
                -73.89646625099994,
                40.682336421999885
              ],
              [
                -73.8965264419999,
                40.682403298999894
              ],
              [
                -73.90116154999988,
                40.68787793499991
              ],
              [
                -73.90042465099988,
                40.68818389799984
              ],
              [
                -73.90180467199994,
                40.6907662979999
              ],
              [
                -73.904055772,
                40.68854627799987
              ],
              [
                -73.90419686399987,
                40.68858474399986
              ],
              [
                -73.9044739719999,
                40.68866816099992
              ],
              [
                -73.90482499699988,
                40.68880856999994
              ],
              [
                -73.90504399299995,
                40.68893224099988
              ],
              [
                -73.9057928679998,
                40.68936474999989
              ],
              [
                -73.90654394399994,
                40.68979093699993
              ],
              [
                -73.90729183099991,
                40.690219069999905
              ],
              [
                -73.9080425449999,
                40.690647001999885
              ],
              [
                -73.90879653399988,
                40.691074750999896
              ],
              [
                -73.90955061499984,
                40.69150297299994
              ],
              [
                -73.91029860299984,
                40.69192995199992
              ],
              [
                -73.91105301,
                40.692357245999894
              ],
              [
                -73.911805654,
                40.6927850819999
              ],
              [
                -73.91255469999996,
                40.69321201999985
              ],
              [
                -73.91331306200003,
                40.69363224199988
              ],
              [
                -73.91405670900002,
                40.694068467999884
              ],
              [
                -73.91480825299999,
                40.694496851999915
              ],
              [
                -73.91502132199989,
                40.69461706699989
              ],
              [
                -73.91506798799989,
                40.694643395999954
              ],
              [
                -73.91556201299993,
                40.69492212499987
              ],
              [
                -73.91631325699998,
                40.695351245999845
              ],
              [
                -73.91655004899992,
                40.6954859149999
              ],
              [
                -73.91661144299985,
                40.6955208309999
              ],
              [
                -73.91670957700003,
                40.69557664199987
              ],
              [
                -73.91677235199991,
                40.695612342999944
              ],
              [
                -73.91686074399993,
                40.69566261299992
              ],
              [
                -73.91691887399988,
                40.69569567199989
              ],
              [
                -73.91707162999998,
                40.6957825459999
              ],
              [
                -73.91728545099988,
                40.69590435299996
              ],
              [
                -73.917361666,
                40.69594776899986
              ],
              [
                -73.91743326099993,
                40.695988553999875
              ],
              [
                -73.91750809599985,
                40.696031183999914
              ],
              [
                -73.91760570499983,
                40.696086786999956
              ],
              [
                -73.917698671,
                40.69613974499987
              ],
              [
                -73.91781824899988,
                40.69620786299992
              ],
              [
                -73.91800959500003,
                40.696316141999944
              ],
              [
                -73.91812143899978,
                40.69637943199997
              ],
              [
                -73.91824631899986,
                40.6964500989999
              ],
              [
                -73.91835160499986,
                40.696509676999874
              ],
              [
                -73.91842289799997,
                40.69655001999996
              ],
              [
                -73.91848765699991,
                40.69658666399988
              ],
              [
                -73.91856440699996,
                40.69663009399995
              ],
              [
                -73.91932072899982,
                40.69706039699991
              ],
              [
                -73.91956944199978,
                40.6972023069999
              ],
              [
                -73.91960508499987,
                40.697222642999876
              ],
              [
                -73.92007372399986,
                40.69749003399989
              ],
              [
                -73.92043053999987,
                40.69769341199991
              ],
              [
                -73.9204962049999,
                40.697730838999924
              ],
              [
                -73.92082397399983,
                40.697917657999916
              ],
              [
                -73.92157402199997,
                40.69834550899992
              ],
              [
                -73.92175647299993,
                40.6984533169999
              ],
              [
                -73.92232801999992,
                40.69877209799996
              ],
              [
                -73.92268944899992,
                40.69897762199984
              ],
              [
                -73.92308169399985,
                40.6992006719999
              ],
              [
                -73.92383267099994,
                40.699627797999916
              ],
              [
                -73.92458241299997,
                40.700055251999906
              ],
              [
                -73.92532959999984,
                40.70048119499994
              ],
              [
                -73.92608301599982,
                40.700910144999945
              ],
              [
                -73.92683805799987,
                40.70133420999986
              ],
              [
                -73.92758774299998,
                40.70176258099985
              ],
              [
                -73.92833850299998,
                40.70219163999997
              ],
              [
                -73.92909071699984,
                40.70261967799992
              ],
              [
                -73.92984252699986,
                40.70304680999989
              ],
              [
                -73.93059386199991,
                40.70347330799992
              ],
              [
                -73.93115533899987,
                40.70377233499987
              ],
              [
                -73.93172871799996,
                40.7035459909999
              ],
              [
                -73.93269784899994,
                40.703170390999894
              ],
              [
                -73.93281494999994,
                40.70357711199993
              ],
              [
                -73.93293503499982,
                40.70399456099993
              ],
              [
                -73.93301076599988,
                40.70427708599989
              ],
              [
                -73.93312548700004,
                40.70470086199992
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000327392684821,
        "objectid": 38,
        "shape_leng": 0.0832175685234,
        "location_id": 38,
        "zone": "Cambria Heights",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.73624941199995,
                40.7025369779999
              ],
              [
                -73.73819579999989,
                40.70259533599991
              ],
              [
                -73.74072128199992,
                40.7026780189999
              ],
              [
                -73.74077675800001,
                40.702679837999916
              ],
              [
                -73.74051703299997,
                40.703387615999894
              ],
              [
                -73.74050336499984,
                40.70343355599992
              ],
              [
                -73.74055102499982,
                40.70344338099995
              ],
              [
                -73.74069300199987,
                40.70346611399988
              ],
              [
                -73.74089188600004,
                40.70348164599992
              ],
              [
                -73.74109293999989,
                40.70348106299991
              ],
              [
                -73.74129277999985,
                40.70346424899991
              ],
              [
                -73.74148805299996,
                40.70343161199993
              ],
              [
                -73.74167563399995,
                40.70338409999992
              ],
              [
                -73.74287389299987,
                40.70307494299988
              ],
              [
                -73.74330723899996,
                40.70295979699989
              ],
              [
                -73.74386175999997,
                40.702811095999955
              ],
              [
                -73.74413435499979,
                40.70274211199992
              ],
              [
                -73.74502395299994,
                40.70251829799987
              ],
              [
                -73.74590871000004,
                40.70228727399989
              ],
              [
                -73.74500119100004,
                40.70026221299988
              ],
              [
                -73.74588751799996,
                40.70003041699989
              ],
              [
                -73.74677542799994,
                40.69980088799986
              ],
              [
                -73.74767063100002,
                40.699568575999855
              ],
              [
                -73.74773012199991,
                40.69955313699991
              ],
              [
                -73.74741867999983,
                40.69885902799989
              ],
              [
                -73.74712436899999,
                40.698198034999926
              ],
              [
                -73.74681909299983,
                40.69752545199986
              ],
              [
                -73.74649367299992,
                40.69679987399994
              ],
              [
                -73.74618157999997,
                40.69612192599991
              ],
              [
                -73.74612956299988,
                40.6961357329999
              ],
              [
                -73.74523747599996,
                40.69637465899995
              ],
              [
                -73.74507479999993,
                40.69639094899986
              ],
              [
                -73.7449099489999,
                40.69639459999991
              ],
              [
                -73.74474545399995,
                40.696385464999885
              ],
              [
                -73.74458386999987,
                40.696363781999864
              ],
              [
                -73.74365178399992,
                40.696224156999904
              ],
              [
                -73.7435928609999,
                40.69621532499986
              ],
              [
                -73.74386913499988,
                40.695400117999945
              ],
              [
                -73.74415874099992,
                40.69454989999985
              ],
              [
                -73.74426648799988,
                40.694260100999934
              ],
              [
                -73.74438939,
                40.6939734339999
              ],
              [
                -73.7445271819999,
                40.693690598999865
              ],
              [
                -73.74467955599985,
                40.69341226299991
              ],
              [
                -73.74479652999989,
                40.69322037199988
              ],
              [
                -73.74484610600003,
                40.69313905699989
              ],
              [
                -73.74487951500002,
                40.69309307799993
              ],
              [
                -73.74510485099985,
                40.692782934999876
              ],
              [
                -73.74624345599987,
                40.69127944399992
              ],
              [
                -73.74728617699985,
                40.68973717299985
              ],
              [
                -73.7475517539999,
                40.689347335999855
              ],
              [
                -73.74746788899986,
                40.68932218099994
              ],
              [
                -73.74125253099989,
                40.68745783899989
              ],
              [
                -73.7375999409999,
                40.68636201199989
              ],
              [
                -73.73744163699995,
                40.68670076699989
              ],
              [
                -73.737321908,
                40.686664320999924
              ],
              [
                -73.73657845699985,
                40.68643801399987
              ],
              [
                -73.73569987899988,
                40.68617269699994
              ],
              [
                -73.73483039799987,
                40.68590609699992
              ],
              [
                -73.73451246099985,
                40.685808342999955
              ],
              [
                -73.73396025399994,
                40.68563856299993
              ],
              [
                -73.73361575199982,
                40.68553440399995
              ],
              [
                -73.73355835299988,
                40.6855170529999
              ],
              [
                -73.73350033600005,
                40.68550058499989
              ],
              [
                -73.73308454599989,
                40.6853825349999
              ],
              [
                -73.73222005799998,
                40.685121095999925
              ],
              [
                -73.73132732799995,
                40.684850543999865
              ],
              [
                -73.73045844999992,
                40.68458679499985
              ],
              [
                -73.72959149799995,
                40.68432533799995
              ],
              [
                -73.72872058399992,
                40.68406239099988
              ],
              [
                -73.72785251200004,
                40.68380044199992
              ],
              [
                -73.72718272499995,
                40.68359732999989
              ],
              [
                -73.72675245899993,
                40.68346409399994
              ],
              [
                -73.72666460799998,
                40.68343688899992
              ],
              [
                -73.72658306499989,
                40.68341482999995
              ],
              [
                -73.72586264191175,
                40.6832410809739
              ],
              [
                -73.72580147979704,
                40.684283780387226
              ],
              [
                -73.72579787921153,
                40.68435384991247
              ],
              [
                -73.725926356196,
                40.686225208128256
              ],
              [
                -73.72593196221484,
                40.686311940908546
              ],
              [
                -73.72594098579772,
                40.68639910518071
              ],
              [
                -73.7259961632074,
                40.686932054144755
              ],
              [
                -73.72616338309388,
                40.688453617849056
              ],
              [
                -73.72617495100188,
                40.68865003005495
              ],
              [
                -73.72620259721364,
                40.68898434090529
              ],
              [
                -73.7262588651076,
                40.69026892794889
              ],
              [
                -73.7262583130798,
                40.69061086216151
              ],
              [
                -73.72626254324406,
                40.69077325308489
              ],
              [
                -73.72650318512274,
                40.696880358755635
              ],
              [
                -73.72650531499247,
                40.697015963925466
              ],
              [
                -73.72650538383098,
                40.69702492404416
              ],
              [
                -73.72650541791589,
                40.697028345130626
              ],
              [
                -73.72650544889288,
                40.697032389231865
              ],
              [
                -73.7265054548457,
                40.697033793849265
              ],
              [
                -73.72650547377475,
                40.69703499178466
              ],
              [
                -73.72650548804287,
                40.697037243007145
              ],
              [
                -73.72650562109436,
                40.69705367791968
              ],
              [
                -73.72650566904106,
                40.69705938782329
              ],
              [
                -73.72650576920825,
                40.69707238185048
              ],
              [
                -73.72650617482721,
                40.69712356793277
              ],
              [
                -73.72650630194262,
                40.69713880500901
              ],
              [
                -73.72650934112171,
                40.69751670520512
              ],
              [
                -73.72651147511033,
                40.69778119231719
              ],
              [
                -73.72652452210123,
                40.69825809218731
              ],
              [
                -73.72654435079282,
                40.698982640083074
              ],
              [
                -73.72665409175166,
                40.70111265819504
              ],
              [
                -73.72673796384797,
                40.701587951893714
              ],
              [
                -73.72678346816939,
                40.70198712397415
              ],
              [
                -73.72672841210559,
                40.7021457358777
              ],
              [
                -73.72677759516957,
                40.703059291199416
              ],
              [
                -73.72713924599991,
                40.703046271999895
              ],
              [
                -73.72725657600003,
                40.703041498999916
              ],
              [
                -73.7273634669998,
                40.70303714099988
              ],
              [
                -73.7277679189999,
                40.70302063799988
              ],
              [
                -73.73036969899985,
                40.70309853299995
              ],
              [
                -73.732314832,
                40.70315970599992
              ],
              [
                -73.73245633499985,
                40.703164050999874
              ],
              [
                -73.7333187329999,
                40.70319051999997
              ],
              [
                -73.73426455399982,
                40.703219541999935
              ],
              [
                -73.73426739299985,
                40.70316624699992
              ],
              [
                -73.73430403699984,
                40.702478130999936
              ],
              [
                -73.73624941199995,
                40.7025369779999
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.00090028293862,
        "objectid": 39,
        "shape_leng": 0.132369620757,
        "location_id": 39,
        "zone": "Canarsie",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.89121853499988,
                40.64998769899988
              ],
              [
                -73.89125147199995,
                40.65002559699993
              ],
              [
                -73.89004263999982,
                40.65111858599992
              ],
              [
                -73.89000589199988,
                40.651148696999925
              ],
              [
                -73.89003782499988,
                40.65117817699991
              ],
              [
                -73.89136543599976,
                40.65236471899987
              ],
              [
                -73.89245737399975,
                40.65371093899989
              ],
              [
                -73.89354677099988,
                40.65520271399988
              ],
              [
                -73.89441113999995,
                40.65483294899989
              ],
              [
                -73.89520540399988,
                40.65448792999991
              ],
              [
                -73.89546427299999,
                40.65437757699993
              ],
              [
                -73.89558884499993,
                40.65432447199987
              ],
              [
                -73.8959444279999,
                40.654172887999934
              ],
              [
                -73.89667745499989,
                40.65385495699997
              ],
              [
                -73.89678213299993,
                40.653809555999885
              ],
              [
                -73.89741344699993,
                40.653538320999914
              ],
              [
                -73.89761013299987,
                40.653453815999924
              ],
              [
                -73.89746257699998,
                40.65332105699985
              ],
              [
                -73.89684143499989,
                40.652762191999905
              ],
              [
                -73.89676845099996,
                40.65269652599995
              ],
              [
                -73.896634179,
                40.65257571499996
              ],
              [
                -73.89654981299996,
                40.652499805999916
              ],
              [
                -73.89619634399993,
                40.652181767999906
              ],
              [
                -73.89610981499999,
                40.652103911999895
              ],
              [
                -73.895861549,
                40.65188052699987
              ],
              [
                -73.89621590699987,
                40.65164801899989
              ],
              [
                -73.89627957599997,
                40.65160624199992
              ],
              [
                -73.89655685900003,
                40.65142430299988
              ],
              [
                -73.89661390699996,
                40.65138687099991
              ],
              [
                -73.89675272299998,
                40.65129578699992
              ],
              [
                -73.89680700299976,
                40.651260169999915
              ],
              [
                -73.89721054799986,
                40.65099537899994
              ],
              [
                -73.89727504499993,
                40.650953162999876
              ],
              [
                -73.89736587999998,
                40.65089370699995
              ],
              [
                -73.89742477799994,
                40.650855011999965
              ],
              [
                -73.89757174800002,
                40.65075888699994
              ],
              [
                -73.89771516099994,
                40.65066508899989
              ],
              [
                -73.89779457099988,
                40.65061310899988
              ],
              [
                -73.89797649399998,
                40.65049403099988
              ],
              [
                -73.89872808799994,
                40.650024606999885
              ],
              [
                -73.89946894299986,
                40.649541768999924
              ],
              [
                -73.90019369799995,
                40.64908881799994
              ],
              [
                -73.90027087999994,
                40.649037770999946
              ],
              [
                -73.90056999899996,
                40.648847606999894
              ],
              [
                -73.90074707899997,
                40.6487354449999
              ],
              [
                -73.90089120800002,
                40.648641709999886
              ],
              [
                -73.90165833699982,
                40.648142211999904
              ],
              [
                -73.90235538599994,
                40.64768960199993
              ],
              [
                -73.903099438,
                40.64719961099992
              ],
              [
                -73.90398200599991,
                40.6466285679999
              ],
              [
                -73.90509580299975,
                40.64760685099992
              ],
              [
                -73.90579983699993,
                40.64826507899992
              ],
              [
                -73.90588893999983,
                40.64834514399994
              ],
              [
                -73.90637218799995,
                40.6487793819999
              ],
              [
                -73.907546414,
                40.64983425299993
              ],
              [
                -73.90761503399983,
                40.64989589399989
              ],
              [
                -73.90827570700003,
                40.65049857299989
              ],
              [
                -73.90849084299998,
                40.650586417999875
              ],
              [
                -73.90868769500001,
                40.65072148099995
              ],
              [
                -73.90944846399994,
                40.65152159899992
              ],
              [
                -73.91022992899991,
                40.65101403099989
              ],
              [
                -73.91110070199987,
                40.6504479129999
              ],
              [
                -73.91182280200003,
                40.649983422999874
              ],
              [
                -73.91253589199988,
                40.649521478999866
              ],
              [
                -73.9132529419999,
                40.649061424999935
              ],
              [
                -73.91396129299983,
                40.648600657999964
              ],
              [
                -73.91473216799982,
                40.64810117499995
              ],
              [
                -73.91550029699984,
                40.647604853999916
              ],
              [
                -73.91620934099993,
                40.64715114199988
              ],
              [
                -73.91693683599993,
                40.64668353499994
              ],
              [
                -73.91730801300002,
                40.646442904999944
              ],
              [
                -73.91764732399982,
                40.64622293599992
              ],
              [
                -73.91798513199991,
                40.64601087999991
              ],
              [
                -73.91830152299998,
                40.645812262999925
              ],
              [
                -73.91861676799982,
                40.64560209799989
              ],
              [
                -73.91892329099996,
                40.64539775299988
              ],
              [
                -73.91925419699997,
                40.64517776399987
              ],
              [
                -73.91943014999988,
                40.64506079199986
              ],
              [
                -73.91982077699998,
                40.644783831999874
              ],
              [
                -73.91995062999999,
                40.64470762999991
              ],
              [
                -73.91990782599999,
                40.64432958099992
              ],
              [
                -73.91973736999986,
                40.64277814799987
              ],
              [
                -73.91965951399997,
                40.642078236999915
              ],
              [
                -73.91958298599987,
                40.64139576199993
              ],
              [
                -73.919555766,
                40.64118495799989
              ],
              [
                -73.91953493999995,
                40.64085774099989
              ],
              [
                -73.91946588499994,
                40.64028418699997
              ],
              [
                -73.91946141199999,
                40.640239292999844
              ],
              [
                -73.91945472999996,
                40.6401731369999
              ],
              [
                -73.91941447399985,
                40.63977466499991
              ],
              [
                -73.91928984599986,
                40.638596032999914
              ],
              [
                -73.91917180399989,
                40.637469840999934
              ],
              [
                -73.9191648269999,
                40.63740334199989
              ],
              [
                -73.91905790399989,
                40.6363851099999
              ],
              [
                -73.91902770799979,
                40.636097543999895
              ],
              [
                -73.919006295,
                40.63589361799995
              ],
              [
                -73.91899154599992,
                40.635759062999945
              ],
              [
                -73.91895156599989,
                40.63539431799991
              ],
              [
                -73.91893983700001,
                40.63528732099991
              ],
              [
                -73.9189216129999,
                40.63512106099993
              ],
              [
                -73.91891212099982,
                40.63503445999985
              ],
              [
                -73.91889071099986,
                40.63483913299993
              ],
              [
                -73.91888284299993,
                40.63476735199989
              ],
              [
                -73.91886934499978,
                40.634644215999884
              ],
              [
                -73.91886199299982,
                40.634577127999904
              ],
              [
                -73.91885164499996,
                40.634482718999955
              ],
              [
                -73.91876282599999,
                40.6336375289999
              ],
              [
                -73.9186298239999,
                40.632346872999904
              ],
              [
                -73.91860649599991,
                40.63209041499988
              ],
              [
                -73.91716071492723,
                40.631730125998445
              ],
              [
                -73.91709961988298,
                40.631786254915475
              ],
              [
                -73.91703186677645,
                40.63183775753898
              ],
              [
                -73.91695805613352,
                40.63188417731137
              ],
              [
                -73.91687884225442,
                40.63192510273311
              ],
              [
                -73.91679492734241,
                40.63196017100999
              ],
              [
                -73.91670705527756,
                40.63198907126988
              ],
              [
                -73.9166160050226,
                40.63201154731792
              ],
              [
                -73.91652258371712,
                40.63202739990858
              ],
              [
                -73.91642761952173,
                40.632036488511595
              ],
              [
                -73.91633195427576,
                40.63203873255834
              ],
              [
                -73.9139951001166,
                40.630944892076315
              ],
              [
                -73.91316650880005,
                40.630557023372226
              ],
              [
                -73.9123646154884,
                40.63018164237542
              ],
              [
                -73.9115321792163,
                40.62979195228684
              ],
              [
                -73.91122829462807,
                40.62964968889374
              ],
              [
                -73.91071335177084,
                40.62940723528353
              ],
              [
                -73.9099107442635,
                40.62902932898019
              ],
              [
                -73.90908063035498,
                40.62863846124713
              ],
              [
                -73.9082831586814,
                40.628262953645525
              ],
              [
                -73.90826288994353,
                40.62825340039839
              ],
              [
                -73.90825201141737,
                40.628248276562815
              ],
              [
                -73.90746333160071,
                40.62787689951586
              ],
              [
                -73.90663673204166,
                40.62748765499165
              ],
              [
                -73.90594908886752,
                40.62716383772389
              ],
              [
                -73.90579604091181,
                40.62709176652206
              ],
              [
                -73.90529160821043,
                40.626854221315796
              ],
              [
                -73.90506354457798,
                40.626746817816915
              ],
              [
                -73.90505511040647,
                40.626742839079775
              ],
              [
                -73.90504862589897,
                40.62673978989416
              ],
              [
                -73.90501149169361,
                40.62672230556913
              ],
              [
                -73.9049352622391,
                40.62668641368551
              ],
              [
                -73.9049063484711,
                40.62667279096034
              ],
              [
                -73.90489270645807,
                40.626666367846944
              ],
              [
                -73.90483487851195,
                40.626639133927185
              ],
              [
                -73.90460520923169,
                40.62653098000287
              ],
              [
                -73.90454477156378,
                40.62650251846565
              ],
              [
                -73.9044869797309,
                40.626475292106406
              ],
              [
                -73.90412793207628,
                40.626306200134245
              ],
              [
                -73.90370369782693,
                40.626217857957435
              ],
              [
                -73.90279518238806,
                40.62602866456062
              ],
              [
                -73.90192494672036,
                40.62584743465487
              ],
              [
                -73.90114685492415,
                40.62568538759804
              ],
              [
                -73.89826103011845,
                40.62298915992179
              ],
              [
                -73.8963900618076,
                40.622817393464615
              ],
              [
                -73.89632454431379,
                40.62279224427039
              ],
              [
                -73.8962617667812,
                40.62276896998143
              ],
              [
                -73.89619315402952,
                40.62277172420758
              ],
              [
                -73.89612385854737,
                40.62277450525075
              ],
              [
                -73.89607154396427,
                40.622774457969435
              ],
              [
                -73.89602450367005,
                40.622746511826406
              ],
              [
                -73.89596702487455,
                40.622702612328396
              ],
              [
                -73.89596185641412,
                40.62266274499435
              ],
              [
                -73.89597194082525,
                40.62265438253494
              ],
              [
                -73.8960194773396,
                40.622614963109555
              ],
              [
                -73.89599336881395,
                40.62258304936638
              ],
              [
                -73.89594111031164,
                40.62254712710863
              ],
              [
                -73.89577374598754,
                40.62251907138479
              ],
              [
                -73.8956010701693,
                40.62254283280268
              ],
              [
                -73.89547023904039,
                40.622570616355254
              ],
              [
                -73.89537198313622,
                40.62264418475256
              ],
              [
                -73.89526775982362,
                40.622712820130396
              ],
              [
                -73.89515799486826,
                40.622776242092975
              ],
              [
                -73.89504313667864,
                40.62283419154178
              ],
              [
                -73.89492365447542,
                40.62288643173476
              ],
              [
                -73.89480003637225,
                40.62293274925357
              ],
              [
                -73.89456448045492,
                40.623020230132475
              ],
              [
                -73.89359082527815,
                40.62339005080736
              ],
              [
                -73.89351237165282,
                40.6233780191171
              ],
              [
                -73.89329777596994,
                40.62344159921931
              ],
              [
                -73.89320886543258,
                40.62342557109937
              ],
              [
                -73.89313041733622,
                40.623409553641906
              ],
              [
                -73.89308340975172,
                40.62336167592056
              ],
              [
                -73.8930055886431,
                40.62335646909599
              ],
              [
                -73.89284102387963,
                40.62344337791269
              ],
              [
                -73.89268116187884,
                40.623535241730146
              ],
              [
                -73.8925262594703,
                40.62363191297455
              ],
              [
                -73.89237656551936,
                40.623733236347746
              ],
              [
                -73.89223232052697,
                40.62383904907804
              ],
              [
                -73.8920937562442,
                40.62394918118082
              ],
              [
                -73.89196109529959,
                40.62406345573155
              ],
              [
                -73.89183455084178,
                40.6241816891503
              ],
              [
                -73.89171432619564,
                40.62430369149586
              ],
              [
                -73.89160061453713,
                40.62442926677171
              ],
              [
                -73.89149359858217,
                40.6245582132401
              ],
              [
                -73.89139211472015,
                40.62460903974803
              ],
              [
                -73.89129564685899,
                40.624665251725176
              ],
              [
                -73.89120468634033,
                40.62472656287357
              ],
              [
                -73.89111969645955,
                40.62479266092348
              ],
              [
                -73.89104111010586,
                40.62486320922371
              ],
              [
                -73.89096932755896,
                40.624937848455794
              ],
              [
                -73.89090471444746,
                40.625016198463854
              ],
              [
                -73.89084759988879,
                40.6250978601912
              ],
              [
                -73.89079827481083,
                40.62518241771171
              ],
              [
                -73.89075699047119,
                40.62526944034895
              ],
              [
                -73.89072395717571,
                40.62535848486902
              ],
              [
                -73.89069934320733,
                40.62544909773821
              ],
              [
                -73.89068327396869,
                40.62554081743287
              ],
              [
                -73.89070332629487,
                40.625611872847884
              ],
              [
                -73.89071445380763,
                40.62568405608368
              ],
              [
                -73.89071655638924,
                40.62575671787128
              ],
              [
                -73.8907096150978,
                40.62582920463516
              ],
              [
                -73.89069369233992,
                40.625900864372056
              ],
              [
                -73.89066893130891,
                40.62597105251558
              ],
              [
                -73.89063555469917,
                40.62603913773377
              ],
              [
                -73.89059386270354,
                40.626104507608865
              ],
              [
                -73.89054423031341,
                40.626166574144925
              ],
              [
                -73.89048710394742,
                40.62622477905808
              ],
              [
                -73.89042299743556,
                40.62627859879778
              ],
              [
                -73.8882846126343,
                40.62789246022349
              ],
              [
                -73.88816892280941,
                40.62797571936738
              ],
              [
                -73.8880486855124,
                40.628055150544114
              ],
              [
                -73.88792411875023,
                40.628130609723776
              ],
              [
                -73.88779544838252,
                40.62820196007809
              ],
              [
                -73.8876629077119,
                40.62826907222885
              ],
              [
                -73.88752673706195,
                40.62833182448246
              ],
              [
                -73.887373346267,
                40.62839556526335
              ],
              [
                -73.88721621281638,
                40.62845377422153
              ],
              [
                -73.88705568015071,
                40.628506324128594
              ],
              [
                -73.8868920991431,
                40.62855310012493
              ],
              [
                -73.88672582733146,
                40.62859399997053
              ],
              [
                -73.8865572281382,
                40.628628934269535
              ],
              [
                -73.88638667007315,
                40.62865782666461
              ],
              [
                -73.88621452593078,
                40.62868061400438
              ],
              [
                -73.88604117197292,
                40.62869724648154
              ],
              [
                -73.88572166971262,
                40.62844671572563
              ],
              [
                -73.88512954023768,
                40.628827258671386
              ],
              [
                -73.88369231571497,
                40.62755937391406
              ],
              [
                -73.88300026358979,
                40.628026194386194
              ],
              [
                -73.88263248433402,
                40.628274273126905
              ],
              [
                -73.88404726149369,
                40.6295601185465
              ],
              [
                -73.88345412923461,
                40.62990766993492
              ],
              [
                -73.88376932796366,
                40.63022176134234
              ],
              [
                -73.88375501660256,
                40.63055643801253
              ],
              [
                -73.88364451728225,
                40.6309433121386
              ],
              [
                -73.88345265635674,
                40.63115577211293
              ],
              [
                -73.88322181279625,
                40.63144231258995
              ],
              [
                -73.88301362103512,
                40.6316261495354
              ],
              [
                -73.88272684944738,
                40.63176710490025
              ],
              [
                -73.88256945182803,
                40.63181830588503
              ],
              [
                -73.88215899502383,
                40.63200621538491
              ],
              [
                -73.88208583089636,
                40.63207890480774
              ],
              [
                -73.88197891997052,
                40.63217295899951
              ],
              [
                -73.88178214769638,
                40.63224980097282
              ],
              [
                -73.88163037328907,
                40.6322967272663
              ],
              [
                -73.88162197738069,
                40.63229710561399
              ],
              [
                -73.88144495909098,
                40.632305096116504
              ],
              [
                -73.88127068104548,
                40.63236911916103
              ],
              [
                -73.88093320122287,
                40.63258706179292
              ],
              [
                -73.88037629489094,
                40.63298026236324
              ],
              [
                -73.8802328219404,
                40.633226890521485
              ],
              [
                -73.88018468342864,
                40.63330963859056
              ],
              [
                -73.87998767503844,
                40.63351488348096
              ],
              [
                -73.87971206395956,
                40.63369008532765
              ],
              [
                -73.87936895387004,
                40.633908015784016
              ],
              [
                -73.87918335624654,
                40.63401482552619
              ],
              [
                -73.87890455278942,
                40.63411588241607
              ],
              [
                -73.8787277618307,
                40.63425138864696
              ],
              [
                -73.87856542917646,
                40.63433946507627
              ],
              [
                -73.87833349586315,
                40.634480417945234
              ],
              [
                -73.87820590489773,
                40.63457294366254
              ],
              [
                -73.8781371779872,
                40.63469473189087
              ],
              [
                -73.87793889634258,
                40.63501957113924
              ],
              [
                -73.877493816927,
                40.63539042397704
              ],
              [
                -73.87714434884276,
                40.63597649800373
              ],
              [
                -73.87783675028,
                40.63690231029442
              ],
              [
                -73.8784670212284,
                40.637489830663995
              ],
              [
                -73.87863488118161,
                40.63755178164712
              ],
              [
                -73.8786115360817,
                40.63764882997629
              ],
              [
                -73.87843722479572,
                40.637948690637224
              ],
              [
                -73.87859391192085,
                40.638217012182864
              ],
              [
                -73.87863863904012,
                40.63829360613005
              ],
              [
                -73.87870039087672,
                40.63833772487285
              ],
              [
                -73.87876518091636,
                40.63836855709629
              ],
              [
                -73.87887362624322,
                40.6384394051234
              ],
              [
                -73.87896686085557,
                40.63845954384777
              ],
              [
                -73.87905060875187,
                40.6384359485621
              ],
              [
                -73.87907715621357,
                40.63830298104856
              ],
              [
                -73.87911787849026,
                40.63826658722027
              ],
              [
                -73.87935480009125,
                40.638179387910526
              ],
              [
                -73.87945522184175,
                40.63819224570854
              ],
              [
                -73.8795388800853,
                40.63821783916026
              ],
              [
                -73.8797498301519,
                40.638422235815135
              ],
              [
                -73.88055123891112,
                40.63915881986857
              ],
              [
                -73.88076685499172,
                40.63958164757545
              ],
              [
                -73.88078889712776,
                40.639757433320376
              ],
              [
                -73.88126533390094,
                40.640218772635464
              ],
              [
                -73.8819016777496,
                40.640834939061385
              ],
              [
                -73.88251634823919,
                40.641350710775676
              ],
              [
                -73.88311261912271,
                40.641874465488016
              ],
              [
                -73.88373578781892,
                40.64245034909424
              ],
              [
                -73.88438270996289,
                40.642910669950616
              ],
              [
                -73.88461734294579,
                40.643074178695855
              ],
              [
                -73.88463356426676,
                40.64308692194502
              ],
              [
                -73.88468354514835,
                40.64311628805958
              ],
              [
                -73.88469181436612,
                40.64312114697232
              ],
              [
                -73.884733266714,
                40.64314339661294
              ],
              [
                -73.88475150350376,
                40.643149539566245
              ],
              [
                -73.88479938783794,
                40.64316567145599
              ],
              [
                -73.88483222472676,
                40.643180195730864
              ],
              [
                -73.88486325515501,
                40.64319392276234
              ],
              [
                -73.88491387257027,
                40.64322068903581
              ],
              [
                -73.88491396903086,
                40.64322074123379
              ],
              [
                -73.88492151268088,
                40.64322472926059
              ],
              [
                -73.88492244965363,
                40.64322597141716
              ],
              [
                -73.88493829484842,
                40.64324695581227
              ],
              [
                -73.88495508542839,
                40.643264054489244
              ],
              [
                -73.88502120740554,
                40.643286329205054
              ],
              [
                -73.88506827422987,
                40.643303459305194
              ],
              [
                -73.88509819735403,
                40.64332027740767
              ],
              [
                -73.88512764875115,
                40.64333683100629
              ],
              [
                -73.88519039515114,
                40.643365081010465
              ],
              [
                -73.88525314667164,
                40.64339076796654
              ],
              [
                -73.88532376006884,
                40.64340963074777
              ],
              [
                -73.88535177774826,
                40.64341905351529
              ],
              [
                -73.8853521641245,
                40.64341933719122
              ],
              [
                -73.88537977613835,
                40.643439581722255
              ],
              [
                -73.88543581637987,
                40.64345586806235
              ],
              [
                -73.88547617787049,
                40.64346017806354
              ],
              [
                -73.8854849551704,
                40.643463024904875
              ],
              [
                -73.88551316184969,
                40.64347217290688
              ],
              [
                -73.88553890885683,
                40.64349782319592
              ],
              [
                -73.88558493934023,
                40.64351307187973
              ],
              [
                -73.88560615138455,
                40.64352009818709
              ],
              [
                -73.88562236163982,
                40.64352766632896
              ],
              [
                -73.88564648781826,
                40.643538929642915
              ],
              [
                -73.88566888045216,
                40.64355945220466
              ],
              [
                -73.8856886383046,
                40.64356969343092
              ],
              [
                -73.88570024976728,
                40.64357571220986
              ],
              [
                -73.8857275231896,
                40.64358289445717
              ],
              [
                -73.88576862162398,
                40.64359371675364
              ],
              [
                -73.88578858289038,
                40.643604758616
              ],
              [
                -73.88580110949894,
                40.64361168680072
              ],
              [
                -73.8858279911263,
                40.64363050550078
              ],
              [
                -73.88585038128603,
                40.64365188208479
              ],
              [
                -73.88586685006611,
                40.64365672542979
              ],
              [
                -73.88587918263688,
                40.64366035310168
              ],
              [
                -73.88587952270531,
                40.64366045278357
              ],
              [
                -73.88591017758976,
                40.6436604832001
              ],
              [
                -73.88595241003655,
                40.6436605250908
              ],
              [
                -73.8859908619233,
                40.64366101336716
              ],
              [
                -73.88602529679075,
                40.64366145040652
              ],
              [
                -73.8860721076197,
                40.64369549754006
              ],
              [
                -73.88607233562739,
                40.64369566412182
              ],
              [
                -73.88609803773574,
                40.643747792791665
              ],
              [
                -73.8861204115461,
                40.643778564808024
              ],
              [
                -73.88612910452176,
                40.643786530517694
              ],
              [
                -73.88613720679389,
                40.64379395720526
              ],
              [
                -73.88616522744307,
                40.64380167199119
              ],
              [
                -73.88623646791807,
                40.64381678772094
              ],
              [
                -73.88624664233414,
                40.64384014171074
              ],
              [
                -73.88623633525125,
                40.643894599053915
              ],
              [
                -73.88622602727345,
                40.6439490553898
              ],
              [
                -73.88628516767372,
                40.643993362519126
              ],
              [
                -73.88630504007673,
                40.64400594998882
              ],
              [
                -73.88633749308138,
                40.64405040258741
              ],
              [
                -73.88634638759265,
                40.64407385514964
              ],
              [
                -73.88635758725518,
                40.644103380384315
              ],
              [
                -73.88636185865222,
                40.644113184687725
              ],
              [
                -73.8863721085287,
                40.64413670710629
              ],
              [
                -73.88640742962816,
                40.644170427626754
              ],
              [
                -73.88641689065331,
                40.64417945854897
              ],
              [
                -73.88642580656825,
                40.644186874459855
              ],
              [
                -73.8864325000971,
                40.64419244033611
              ],
              [
                -73.88643332005316,
                40.64419306703229
              ],
              [
                -73.88654221594707,
                40.64423207966326
              ],
              [
                -73.88657616109464,
                40.64429436104308
              ],
              [
                -73.88655111189425,
                40.64431101312668
              ],
              [
                -73.88654888955993,
                40.64431249037849
              ],
              [
                -73.88657950376457,
                40.64433067681233
              ],
              [
                -73.88658091508064,
                40.64432978226192
              ],
              [
                -73.88658356007102,
                40.644331447088206
              ],
              [
                -73.88661733302509,
                40.64434536414791
              ],
              [
                -73.8866304837735,
                40.64435206066235
              ],
              [
                -73.88663077735512,
                40.64435221021965
              ],
              [
                -73.88663887364095,
                40.64438239276388
              ],
              [
                -73.88665201863772,
                40.64439066805882
              ],
              [
                -73.8866774568038,
                40.64441564817367
              ],
              [
                -73.88668336392223,
                40.64442144880513
              ],
              [
                -73.88668366556915,
                40.644421641257594
              ],
              [
                -73.88671024836563,
                40.644438558843824
              ],
              [
                -73.8867482931071,
                40.644487282777895
              ],
              [
                -73.88678523743152,
                40.64452234048387
              ],
              [
                -73.88678605503482,
                40.64452215466205
              ],
              [
                -73.88679645571422,
                40.644519789514135
              ],
              [
                -73.88679424990453,
                40.64449843349059
              ],
              [
                -73.88678419647526,
                40.644474506052816
              ],
              [
                -73.88678391809634,
                40.644474210426
              ],
              [
                -73.88677524272688,
                40.64446510240712
              ],
              [
                -73.88677895080598,
                40.64445736356093
              ],
              [
                -73.88678415535188,
                40.64444650529933
              ],
              [
                -73.88678424430306,
                40.644446318760195
              ],
              [
                -73.88680331813799,
                40.644440358276775
              ],
              [
                -73.88687171566681,
                40.64444469705514
              ],
              [
                -73.88688964143898,
                40.64445325696575
              ],
              [
                -73.88691203454515,
                40.644473777607885
              ],
              [
                -73.88691643330115,
                40.644525032083266
              ],
              [
                -73.88692500573482,
                40.6445374036129
              ],
              [
                -73.8869365699227,
                40.64455409395216
              ],
              [
                -73.88694102650793,
                40.64457032547865
              ],
              [
                -73.88695887549864,
                40.644625010420356
              ],
              [
                -73.88698457931622,
                40.64467713938986
              ],
              [
                -73.88701477169916,
                40.64472756447533
              ],
              [
                -73.8870344533698,
                40.64474402673167
              ],
              [
                -73.88706180246118,
                40.64476690230169
              ],
              [
                -73.88706756030193,
                40.64478712931492
              ],
              [
                -73.88707517897966,
                40.64481389293901
              ],
              [
                -73.88709078754081,
                40.64486772092812
              ],
              [
                -73.8870911770819,
                40.64488706934321
              ],
              [
                -73.88709182294312,
                40.644918971087044
              ],
              [
                -73.88711196189598,
                40.64494547024285
              ],
              [
                -73.88715004600363,
                40.64497113294555
              ],
              [
                -73.88716449216427,
                40.64497639025957
              ],
              [
                -73.88717357973094,
                40.644979697308905
              ],
              [
                -73.88718097101543,
                40.64499855034212
              ],
              [
                -73.8871869793498,
                40.64501387755388
              ],
              [
                -73.88718354961118,
                40.645053165817714
              ],
              [
                -73.88719205915751,
                40.64506663842955
              ],
              [
                -73.88719920587415,
                40.64507795176963
              ],
              [
                -73.88720940903947,
                40.64508422147888
              ],
              [
                -73.88724513428929,
                40.64510618297466
              ],
              [
                -73.88725293073128,
                40.645137794699345
              ],
              [
                -73.88726858280883,
                40.64516599620814
              ],
              [
                -73.88729121814026,
                40.64518616402935
              ],
              [
                -73.88730217444433,
                40.645195925929514
              ],
              [
                -73.88731487147736,
                40.64520457345944
              ],
              [
                -73.88734361665684,
                40.64522415387102
              ],
              [
                -73.88736940895883,
                40.64522417913436
              ],
              [
                -73.88742502464652,
                40.64518784422577
              ],
              [
                -73.88742554053763,
                40.64518750498301
              ],
              [
                -73.88748726875768,
                40.64515767244714
              ],
              [
                -73.88749665617937,
                40.645152870548
              ],
              [
                -73.88754899585207,
                40.64512612606049
              ],
              [
                -73.88761184035705,
                40.64509800015655
              ],
              [
                -73.88767244806121,
                40.64506645646278
              ],
              [
                -73.88773528615363,
                40.645042600283524
              ],
              [
                -73.88778239005063,
                40.64503922968088
              ],
              [
                -73.88784519285471,
                40.645035019975005
              ],
              [
                -73.887868076512,
                40.64502633365665
              ],
              [
                -73.88788096371262,
                40.64502144065254
              ],
              [
                -73.88788110148197,
                40.64502138868549
              ],
              [
                -73.88791252941961,
                40.64500348154404
              ],
              [
                -73.88792710474918,
                40.64500520470717
              ],
              [
                -73.88794876930156,
                40.64502124824208
              ],
              [
                -73.88796406442864,
                40.64503257382938
              ],
              [
                -73.88800996589524,
                40.64507703469436
              ],
              [
                -73.88804571571661,
                40.645110329267
              ],
              [
                -73.88805587182881,
                40.64511978725729
              ],
              [
                -73.88808385457098,
                40.64515056535043
              ],
              [
                -73.88808383875663,
                40.64515999367044
              ],
              [
                -73.88808383164817,
                40.64516423163145
              ],
              [
                -73.88807830661166,
                40.64516602735337
              ],
              [
                -73.88807596671336,
                40.645166790346074
              ],
              [
                -73.88807196882098,
                40.645168524395565
              ],
              [
                -73.88806812125227,
                40.64517019560056
              ],
              [
                -73.88802684907814,
                40.645158565802525
              ],
              [
                -73.88802553006325,
                40.64515819461526
              ],
              [
                -73.88802537505066,
                40.64515816799487
              ],
              [
                -73.88800647124245,
                40.64515475981581
              ],
              [
                -73.88798856532566,
                40.64513338902931
              ],
              [
                -73.88796518926894,
                40.64512657951233
              ],
              [
                -73.88796502879283,
                40.645126532113
              ],
              [
                -73.88795587366924,
                40.645114042656836
              ],
              [
                -73.8879337004715,
                40.645083793217644
              ],
              [
                -73.88792588075614,
                40.645065848992765
              ],
              [
                -73.88791690723532,
                40.645067549205415
              ],
              [
                -73.88790367351744,
                40.64507983675056
              ],
              [
                -73.88788658219785,
                40.645095705810505
              ],
              [
                -73.88784752127042,
                40.64511658683802
              ],
              [
                -73.88782597401105,
                40.64512810434692
              ],
              [
                -73.88780902508142,
                40.64513553060128
              ],
              [
                -73.88776761931854,
                40.64515367273635
              ],
              [
                -73.88775225382686,
                40.64516012115625
              ],
              [
                -73.88772497502667,
                40.64517156888821
              ],
              [
                -73.8876713055163,
                40.64519799100467
              ],
              [
                -73.88766437100682,
                40.645201404827574
              ],
              [
                -73.88764385071785,
                40.64521114683328
              ],
              [
                -73.88760152266909,
                40.645231239044094
              ],
              [
                -73.88754765490864,
                40.645255956976804
              ],
              [
                -73.88751760842834,
                40.64526524669932
              ],
              [
                -73.88751736108665,
                40.64526532335297
              ],
              [
                -73.88750164051392,
                40.64527897478158
              ],
              [
                -73.88750167617394,
                40.64527921806782
              ],
              [
                -73.88750718407012,
                40.64531656404596
              ],
              [
                -73.88749425824821,
                40.64531326750825
              ],
              [
                -73.8874937323772,
                40.64531313364105
              ],
              [
                -73.88748504890106,
                40.64530871093783
              ],
              [
                -73.88748028754614,
                40.64530628700057
              ],
              [
                -73.88748017095864,
                40.64530629844598
              ],
              [
                -73.8874544917705,
                40.645308823771735
              ],
              [
                -73.88743649431201,
                40.64531702460202
              ],
              [
                -73.88740960156501,
                40.64532928012421
              ],
              [
                -73.88741147970725,
                40.6453391428567
              ],
              [
                -73.88741627153311,
                40.645364306807366
              ],
              [
                -73.88742568126139,
                40.64537524356639
              ],
              [
                -73.88744201213828,
                40.64539422712548
              ],
              [
                -73.88746169123525,
                40.64541317659998
              ],
              [
                -73.88746776206911,
                40.64541902291841
              ],
              [
                -73.88749551943745,
                40.64545006730357
              ],
              [
                -73.88749910233261,
                40.64545407373407
              ],
              [
                -73.88751249922824,
                40.6454674551086
              ],
              [
                -73.88754276182515,
                40.64549767953528
              ],
              [
                -73.88755462162912,
                40.64551611691884
              ],
              [
                -73.88757519707431,
                40.64554810716394
              ],
              [
                -73.88758476467049,
                40.64556080127017
              ],
              [
                -73.88758479056699,
                40.64556083480118
              ],
              [
                -73.88759645566896,
                40.645576315279996
              ],
              [
                -73.88760719154664,
                40.64559243598081
              ],
              [
                -73.88762889453581,
                40.64562503343595
              ],
              [
                -73.88767480358253,
                40.64566693024833
              ],
              [
                -73.88771509535594,
                40.645712240981226
              ],
              [
                -73.88773925530427,
                40.64571404707063
              ],
              [
                -73.88774985339084,
                40.64571483860079
              ],
              [
                -73.88774684228964,
                40.64572339720428
              ],
              [
                -73.88774534679695,
                40.64572764627689
              ],
              [
                -73.88774083832425,
                40.64574130817862
              ],
              [
                -73.88776432752009,
                40.64577720598652
              ],
              [
                -73.88777108781663,
                40.645784950723964
              ],
              [
                -73.88780461952585,
                40.64582336991025
              ],
              [
                -73.88783648597834,
                40.645868922667376
              ],
              [
                -73.88783930019136,
                40.64587294542517
              ],
              [
                -73.88783954471292,
                40.6458732393411
              ],
              [
                -73.88787175450808,
                40.64591226869686
              ],
              [
                -73.88792863149854,
                40.64594803869414
              ],
              [
                -73.88792888504189,
                40.64594819926589
              ],
              [
                -73.8879624724094,
                40.64598068862193
              ],
              [
                -73.8879817286607,
                40.64600448368982
              ],
              [
                -73.88800052027007,
                40.646027705354655
              ],
              [
                -73.8880055972574,
                40.64603003123823
              ],
              [
                -73.88802853501163,
                40.64604054470852
              ],
              [
                -73.88803619812846,
                40.64603969911446
              ],
              [
                -73.88807452031926,
                40.64603546444151
              ],
              [
                -73.88810538611928,
                40.646028151034415
              ],
              [
                -73.88812836780345,
                40.64602270476086
              ],
              [
                -73.88817997592882,
                40.64600908865002
              ],
              [
                -73.88819178134528,
                40.646006853236365
              ],
              [
                -73.88821138355613,
                40.64600313992561
              ],
              [
                -73.88822564997575,
                40.646004407575376
              ],
              [
                -73.88824053610162,
                40.64600573027235
              ],
              [
                -73.8882483105607,
                40.64600997143911
              ],
              [
                -73.88825611006853,
                40.646014227874694
              ],
              [
                -73.88825622169888,
                40.646014287790784
              ],
              [
                -73.88825311510513,
                40.646015282739384
              ],
              [
                -73.88822322295583,
                40.64602486211724
              ],
              [
                -73.88822400304595,
                40.646025545888975
              ],
              [
                -73.88823030919363,
                40.64603108598578
              ],
              [
                -73.88826293740479,
                40.646059739762364
              ],
              [
                -73.88826877077497,
                40.64605511108999
              ],
              [
                -73.88826961075077,
                40.64605444547779
              ],
              [
                -73.88827491322176,
                40.64605176129484
              ],
              [
                -73.8882894131325,
                40.64604441836803
              ],
              [
                -73.8882898142075,
                40.64604421554559
              ],
              [
                -73.88828978925125,
                40.64605912136647
              ],
              [
                -73.88828977845561,
                40.6460655693589
              ],
              [
                -73.88827292415569,
                40.64608519854631
              ],
              [
                -73.88821447213182,
                40.64611135827376
              ],
              [
                -73.88821007899956,
                40.64611332480828
              ],
              [
                -73.88816403043423,
                40.64615598790062
              ],
              [
                -73.88814269992719,
                40.6461696344626
              ],
              [
                -73.88813708213601,
                40.646176461311576
              ],
              [
                -73.88809441935716,
                40.646212786549334
              ],
              [
                -73.8880882339975,
                40.646251934947095
              ],
              [
                -73.88809065494014,
                40.6462677856628
              ],
              [
                -73.88807349504613,
                40.646282684692224
              ],
              [
                -73.88806734519848,
                40.646300391323216
              ],
              [
                -73.88806627051336,
                40.64648805482484
              ],
              [
                -73.88806361998368,
                40.64651634514051
              ],
              [
                -73.88805695783927,
                40.64653402012596
              ],
              [
                -73.88805686200648,
                40.6465342762387
              ],
              [
                -73.88806001214776,
                40.646553665216935
              ],
              [
                -73.88806004381225,
                40.64655386984078
              ],
              [
                -73.88806574107578,
                40.64658895091362
              ],
              [
                -73.88808138892033,
                40.646620569814324
              ],
              [
                -73.88810266265712,
                40.64664023644676
              ],
              [
                -73.88813886515427,
                40.64664448906954
              ],
              [
                -73.88816089191931,
                40.6466466088439
              ],
              [
                -73.88818911398977,
                40.64664978240827
              ],
              [
                -73.88822784503809,
                40.646653217971306
              ],
              [
                -73.88828878739722,
                40.64665750138591
              ],
              [
                -73.8883550364737,
                40.646643953194506
              ],
              [
                -73.88835945887126,
                40.646643048980756
              ],
              [
                -73.8884301010908,
                40.646647388505336
              ],
              [
                -73.88849960118229,
                40.64666368405489
              ],
              [
                -73.88856459699522,
                40.646691081328996
              ],
              [
                -73.88859932552029,
                40.646712468820915
              ],
              [
                -73.88859943567105,
                40.646712625399196
              ],
              [
                -73.88861498719626,
                40.64673469206464
              ],
              [
                -73.88864181592983,
                40.64678511345133
              ],
              [
                -73.88864625621946,
                40.6467998495324
              ],
              [
                -73.88865520043964,
                40.64682954255805
              ],
              [
                -73.88865453631006,
                40.64685992504183
              ],
              [
                -73.88865398562774,
                40.64688506181712
              ],
              [
                -73.88864048640656,
                40.64691067406533
              ],
              [
                -73.88861562143931,
                40.64694619935449
              ],
              [
                -73.88859507057262,
                40.646958077486524
              ],
              [
                -73.88857944085456,
                40.64696384123874
              ],
              [
                -73.88856693949997,
                40.64696586962281
              ],
              [
                -73.88855801324306,
                40.646965860973445
              ],
              [
                -73.8885490830469,
                40.64696755239828
              ],
              [
                -73.88853549296564,
                40.646968149350386
              ],
              [
                -73.88864137670687,
                40.64707955315796
              ],
              [
                -73.88866601496176,
                40.6470877358058
              ],
              [
                -73.88869054474083,
                40.64709585938264
              ],
              [
                -73.88871260669775,
                40.64711270628509
              ],
              [
                -73.88872322160665,
                40.64712517949972
              ],
              [
                -73.88872914685678,
                40.64713842668082
              ],
              [
                -73.8887338175948,
                40.64714886905943
              ],
              [
                -73.88876076273776,
                40.64718067644327
              ],
              [
                -73.88878119331632,
                40.64719440589297
              ],
              [
                -73.88878146049778,
                40.64719443798172
              ],
              [
                -73.88880164237945,
                40.64719691731741
              ],
              [
                -73.88884580264049,
                40.64720755383576
              ],
              [
                -73.88886541778207,
                40.647220035735565
              ],
              [
                -73.88889569015308,
                40.64721881890855
              ],
              [
                -73.88898577577875,
                40.64716344527782
              ],
              [
                -73.88904890280085,
                40.647084989972164
              ],
              [
                -73.88904239022581,
                40.6470656653142
              ],
              [
                -73.8890448745862,
                40.647046973547155
              ],
              [
                -73.88906042485348,
                40.64704387318985
              ],
              [
                -73.88909473960057,
                40.64707132449388
              ],
              [
                -73.88913234608853,
                40.64708818630504
              ],
              [
                -73.8891871147141,
                40.64707965174989
              ],
              [
                -73.88918799274475,
                40.64707951539002
              ],
              [
                -73.88931122378477,
                40.647014584778205
              ],
              [
                -73.8893760297505,
                40.646974754423105
              ],
              [
                -73.88941387285446,
                40.64693843999413
              ],
              [
                -73.88942186350397,
                40.64691572797252
              ],
              [
                -73.88941394816807,
                40.64689300015386
              ],
              [
                -73.8893087980161,
                40.64674597772106
              ],
              [
                -73.88899920564424,
                40.64637155947533
              ],
              [
                -73.8887610824867,
                40.64606991286121
              ],
              [
                -73.88877900211294,
                40.64605604593656
              ],
              [
                -73.88879690848671,
                40.64605025805637
              ],
              [
                -73.88908093175131,
                40.64638868736344
              ],
              [
                -73.88919383109035,
                40.64652321265186
              ],
              [
                -73.88941012796784,
                40.646797572277706
              ],
              [
                -73.88948152576064,
                40.64691275506822
              ],
              [
                -73.88948944517219,
                40.646933967256125
              ],
              [
                -73.88947349934483,
                40.64695667214
              ],
              [
                -73.88935086599885,
                40.647034698864175
              ],
              [
                -73.88936576749315,
                40.64705860711186
              ],
              [
                -73.88938137787974,
                40.64708225251715
              ],
              [
                -73.88939768948204,
                40.64710562250771
              ],
              [
                -73.88941469484384,
                40.64712870434404
              ],
              [
                -73.88943238166773,
                40.64715148611932
              ],
              [
                -73.88945074227674,
                40.647173955596465
              ],
              [
                -73.88948476309218,
                40.64720186000043
              ],
              [
                -73.88951810770412,
                40.647230233494334
              ],
              [
                -73.88955076776878,
                40.64725906702426
              ],
              [
                -73.88958273010996,
                40.64728834834854
              ],
              [
                -73.88961398550197,
                40.64731806975258
              ],
              [
                -73.88964452230006,
                40.647348223687004
              ],
              [
                -73.88967433195238,
                40.64737879406147
              ],
              [
                -73.88969945435694,
                40.647399642278245
              ],
              [
                -73.88972392940431,
                40.64742093130629
              ],
              [
                -73.889747746555,
                40.64744265007879
              ],
              [
                -73.88975468482053,
                40.64744928583018
              ],
              [
                -73.8897708897705,
                40.647464788026625
              ],
              [
                -73.88979334785878,
                40.64748732939179
              ],
              [
                -73.88981510829579,
                40.647510266456365
              ],
              [
                -73.88983615944981,
                40.6475335832944
              ],
              [
                -73.88985648814152,
                40.64755726933936
              ],
              [
                -73.88986418069726,
                40.64757105943797
              ],
              [
                -73.88987253171001,
                40.647584625008356
              ],
              [
                -73.88988152889169,
                40.64759794861565
              ],
              [
                -73.88989115995334,
                40.64761101366267
              ],
              [
                -73.88990141283169,
                40.64762379936413
              ],
              [
                -73.88991227369016,
                40.64763629414708
              ],
              [
                -73.88992372739101,
                40.64764847437541
              ],
              [
                -73.88993575921668,
                40.64766032864306
              ],
              [
                -73.88994835071871,
                40.64767184068181
              ],
              [
                -73.8899614891695,
                40.64768299272135
              ],
              [
                -73.88997515369725,
                40.64769377083648
              ],
              [
                -73.88998932760883,
                40.647704160771504
              ],
              [
                -73.88999517921636,
                40.64770942977685
              ],
              [
                -73.88999979852272,
                40.64771359091057
              ],
              [
                -73.89003284746892,
                40.64774335345117
              ],
              [
                -73.89007712569526,
                40.64778205229508
              ],
              [
                -73.89009165255432,
                40.64779437482743
              ],
              [
                -73.89011209963773,
                40.647811717810754
              ],
              [
                -73.89012215504692,
                40.647820247243764
              ],
              [
                -73.89012821103123,
                40.64782523431973
              ],
              [
                -73.89016792256294,
                40.647857928567376
              ],
              [
                -73.89019194002863,
                40.64787933064155
              ],
              [
                -73.89021527583874,
                40.64790116411588
              ],
              [
                -73.89023791549238,
                40.647923419595074
              ],
              [
                -73.89025984603798,
                40.647946081152234
              ],
              [
                -73.89028105671677,
                40.647969137217814
              ],
              [
                -73.8903015330314,
                40.647992575884075
              ],
              [
                -73.8903212633527,
                40.64801637988471
              ],
              [
                -73.89034023735852,
                40.648040539493664
              ],
              [
                -73.89039118974873,
                40.64810783581657
              ],
              [
                -73.89040550565592,
                40.648128417258675
              ],
              [
                -73.89042055075866,
                40.64814869801015
              ],
              [
                -73.89043630991277,
                40.648168658287844
              ],
              [
                -73.89045277522081,
                40.64818828602207
              ],
              [
                -73.89046993285152,
                40.64820756578645
              ],
              [
                -73.89048777028857,
                40.648226485004336
              ],
              [
                -73.89050627633812,
                40.64824502942478
              ],
              [
                -73.89052543387194,
                40.64826318244592
              ],
              [
                -73.89054523278674,
                40.648280935346136
              ],
              [
                -73.89056565441582,
                40.648298270516996
              ],
              [
                -73.89059898527796,
                40.648321156454294
              ],
              [
                -73.89063179791418,
                40.64834447327477
              ],
              [
                -73.89066408045665,
                40.64836821510405
              ],
              [
                -73.89069582367858,
                40.64839237523247
              ],
              [
                -73.8907149265673,
                40.6484074221929
              ],
              [
                -73.89072701791493,
                40.648416945945236
              ],
              [
                -73.89075765503694,
                40.64844192137155
              ],
              [
                -73.89078630206957,
                40.648466090595754
              ],
              [
                -73.89078772428525,
                40.64846729027708
              ],
              [
                -73.89081721708632,
                40.648493050141354
              ],
              [
                -73.89084612619742,
                40.64851919090623
              ],
              [
                -73.89085215944324,
                40.64852483882152
              ],
              [
                -73.89087444305495,
                40.64854570385239
              ],
              [
                -73.8909130586134,
                40.648592848805116
              ],
              [
                -73.89091526317843,
                40.64859554123491
              ],
              [
                -73.89095656520465,
                40.64864515289616
              ],
              [
                -73.89099527837926,
                40.64869091329086
              ],
              [
                -73.89099834122673,
                40.648694532294506
              ],
              [
                -73.89100218065924,
                40.648698997717375
              ],
              [
                -73.8910405899315,
                40.648743675910005
              ],
              [
                -73.89108330605376,
                40.648792575696
              ],
              [
                -73.891096114747,
                40.648812499079
              ],
              [
                -73.89110820334591,
                40.64883267977256
              ],
              [
                -73.8911195591107,
                40.64885310687542
              ],
              [
                -73.8911301778888,
                40.6488737636309
              ],
              [
                -73.89114005025007,
                40.64889463277508
              ],
              [
                -73.89114916653827,
                40.64891570056145
              ],
              [
                -73.89115752325999,
                40.64893695090434
              ],
              [
                -73.89116511098338,
                40.648958367377176
              ],
              [
                -73.89117192863348,
                40.64897993423125
              ],
              [
                -73.89117796546066,
                40.649001634033844
              ],
              [
                -73.89118321862813,
                40.649023452374756
              ],
              [
                -73.89118768684251,
                40.64904537266772
              ],
              [
                -73.8911913641941,
                40.64906737664634
              ],
              [
                -73.89119550640643,
                40.64907434907657
              ],
              [
                -73.89119895343713,
                40.649081537630636
              ],
              [
                -73.89120168467845,
                40.64908890057456
              ],
              [
                -73.89120368479722,
                40.64909639902751
              ],
              [
                -73.89120494088279,
                40.64910399176508
              ],
              [
                -73.89120504911666,
                40.649105599805125
              ],
              [
                -73.89120545080615,
                40.649111635395634
              ],
              [
                -73.8912052071625,
                40.6491192848469
              ],
              [
                -73.89120421309674,
                40.64912689958
              ],
              [
                -73.89120247461622,
                40.6491344373835
              ],
              [
                -73.89119999971275,
                40.64914185303222
              ],
              [
                -73.89119680582479,
                40.64914910834608
              ],
              [
                -73.89119290665816,
                40.64915616095332
              ],
              [
                -73.89118832602998,
                40.64916297251248
              ],
              [
                -73.89118308930125,
                40.649169502337934
              ],
              [
                -73.89117082990147,
                40.64917765975775
              ],
              [
                -73.89115912248874,
                40.64918627773139
              ],
              [
                -73.89115673657176,
                40.64918821930544
              ],
              [
                -73.8911479974592,
                40.64919533048879
              ],
              [
                -73.89113748520066,
                40.649204796615074
              ],
              [
                -73.89112760862514,
                40.64921465368332
              ],
              [
                -73.89111839306872,
                40.64922487642093
              ],
              [
                -73.89110986320975,
                40.64923543804674
              ],
              [
                -73.89110204108817,
                40.649246311442404
              ],
              [
                -73.89109494390252,
                40.64925747099226
              ],
              [
                -73.89108753996057,
                40.64926925462956
              ],
              [
                -73.89107937963642,
                40.649280744710374
              ],
              [
                -73.89107048189092,
                40.64929191361045
              ],
              [
                -73.89106087184406,
                40.64930273320842
              ],
              [
                -73.89105967199393,
                40.64930394866297
              ],
              [
                -73.89105056977336,
                40.64931317789152
              ],
              [
                -73.89103960167664,
                40.64932322104733
              ],
              [
                -73.89102799486605,
                40.64933283924741
              ],
              [
                -73.89101577841615,
                40.649342007892606
              ],
              [
                -73.89100298051534,
                40.64935070556575
              ],
              [
                -73.89098963485272,
                40.64935891018469
              ],
              [
                -73.89098074859662,
                40.64936383966097
              ],
              [
                -73.89097577159515,
                40.649366601674465
              ],
              [
                -73.89096126839027,
                40.64937045096237
              ],
              [
                -73.89094649855325,
                40.64937365601643
              ],
              [
                -73.8909315102687,
                40.649376208170885
              ],
              [
                -73.89091635502108,
                40.64937809809265
              ],
              [
                -73.8909010812095,
                40.64937932046691
              ],
              [
                -73.89089128812886,
                40.64937950986543
              ],
              [
                -73.89088150684604,
                40.649379075733506
              ],
              [
                -73.89087180795605,
                40.64937802400179
              ],
              [
                -73.89086225721336,
                40.64937636210384
              ],
              [
                -73.89085292300992,
                40.64937409864865
              ],
              [
                -73.89084386800218,
                40.6493712519563
              ],
              [
                -73.89083316373392,
                40.64936803262233
              ],
              [
                -73.8908228295893,
                40.64936417736595
              ],
              [
                -73.89081292865663,
                40.64935970986858
              ],
              [
                -73.89080352489603,
                40.64935465816823
              ],
              [
                -73.89079467610247,
                40.649349054820526
              ],
              [
                -73.89078573315822,
                40.64934332456375
              ],
              [
                -73.89077742674992,
                40.64933706351061
              ],
              [
                -73.89076981092721,
                40.649330307396376
              ],
              [
                -73.89076294103755,
                40.64932310569433
              ],
              [
                -73.89075685659586,
                40.64931550518285
              ],
              [
                -73.89073969963145,
                40.64930042925473
              ],
              [
                -73.89072347249865,
                40.649284766350156
              ],
              [
                -73.8907082114337,
                40.649268550512616
              ],
              [
                -73.89069206343918,
                40.64925829432288
              ],
              [
                -73.89065757649318,
                40.64923765973555
              ],
              [
                -73.89064497244473,
                40.64923180302112
              ],
              [
                -73.89063192466081,
                40.64922653926892
              ],
              [
                -73.89061848326281,
                40.64922188561437
              ],
              [
                -73.89061166783519,
                40.64921850963346
              ],
              [
                -73.89060767237343,
                40.64921690945509
              ],
              [
                -73.8906044702013,
                40.649215626827385
              ],
              [
                -73.89059695454304,
                40.64921326406146
              ],
              [
                -73.89058918285595,
                40.64921144015786
              ],
              [
                -73.89058122439944,
                40.64921017109779
              ],
              [
                -73.89056792961276,
                40.64920863296716
              ],
              [
                -73.89121853499988,
                40.64998769899988
              ]
            ]
          ],
          [
            [
              [
                -73.88833929256269,
                40.64671022891759
              ],
              [
                -73.88830775448939,
                40.646715602760004
              ],
              [
                -73.88829183121514,
                40.646718719035654
              ],
              [
                -73.88846787961647,
                40.64689973402528
              ],
              [
                -73.88850950500931,
                40.64688116417923
              ],
              [
                -73.88853291212867,
                40.64686188223068
              ],
              [
                -73.88853297695874,
                40.6468615194269
              ],
              [
                -73.88853970368733,
                40.646824304978544
              ],
              [
                -73.88853772941454,
                40.646802871820846
              ],
              [
                -73.88853640000424,
                40.64678842689959
              ],
              [
                -73.888536179038,
                40.64678824826763
              ],
              [
                -73.88849720193863,
                40.64675678600008
              ],
              [
                -73.88845015102363,
                40.64672855253783
              ],
              [
                -73.88840531896192,
                40.6467139885153
              ],
              [
                -73.88837395798683,
                40.64671039927302
              ],
              [
                -73.88835262237468,
                40.64670795760859
              ],
              [
                -73.88833929256269,
                40.64671022891759
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000108949211644,
        "objectid": 40,
        "shape_leng": 0.0518506648762,
        "location_id": 40,
        "zone": "Carroll Gardens",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.998192111,
                40.68228732999989
              ],
              [
                -74.00004865199998,
                40.682803355999965
              ],
              [
                -73.9997255609999,
                40.683473148999944
              ],
              [
                -73.99940054699985,
                40.68414456199985
              ],
              [
                -73.99908066099994,
                40.68481599199986
              ],
              [
                -73.99986579399993,
                40.685036468999954
              ],
              [
                -74.00064737999996,
                40.685253946999914
              ],
              [
                -74.00095809999992,
                40.684579318999916
              ],
              [
                -74.00128182399993,
                40.68390963999992
              ],
              [
                -74.00160876999992,
                40.683235204999946
              ],
              [
                -74.00193169899997,
                40.68256321799994
              ],
              [
                -74.002204589,
                40.68199132399995
              ],
              [
                -74.00225221999995,
                40.68189321199991
              ],
              [
                -74.00256464499995,
                40.681228463999965
              ],
              [
                -74.00289363599995,
                40.68055940999991
              ],
              [
                -74.00329231299999,
                40.67964328799994
              ],
              [
                -74.00310560799991,
                40.679331885999936
              ],
              [
                -74.00255365299991,
                40.67865868499995
              ],
              [
                -74.00221991299993,
                40.67826032199985
              ],
              [
                -74.0021569849999,
                40.67818268099997
              ],
              [
                -74.00153194399996,
                40.67727039699989
              ],
              [
                -74.00102822999993,
                40.67641403999991
              ],
              [
                -74.0006740919999,
                40.67555578899988
              ],
              [
                -74.00083254999997,
                40.67561225999985
              ],
              [
                -74.00090355299992,
                40.67563696899988
              ],
              [
                -74.00112481699996,
                40.675697553999925
              ],
              [
                -74.00105973699986,
                40.67555567799996
              ],
              [
                -74.00077979399993,
                40.67529390099993
              ],
              [
                -74.00045011499991,
                40.674769424999916
              ],
              [
                -73.99994578200001,
                40.673926156999876
              ],
              [
                -73.99991051299993,
                40.67387272999985
              ],
              [
                -73.99983349199978,
                40.67375604999996
              ],
              [
                -73.99934534299987,
                40.673006548999915
              ],
              [
                -73.99903998999996,
                40.67250767899989
              ],
              [
                -73.99898242799982,
                40.67241358399986
              ],
              [
                -73.9986188799999,
                40.67183017199988
              ],
              [
                -73.99855090599986,
                40.67192594199986
              ],
              [
                -73.99847134099994,
                40.672059100999874
              ],
              [
                -73.99812844699997,
                40.67244074999998
              ],
              [
                -73.99805741699998,
                40.6724695449999
              ],
              [
                -73.99745115699996,
                40.67319456799989
              ],
              [
                -73.99717696100001,
                40.67352829399994
              ],
              [
                -73.99696673299994,
                40.673784159999876
              ],
              [
                -73.99666043499988,
                40.67414278099988
              ],
              [
                -73.9959621319999,
                40.674968223999926
              ],
              [
                -73.99572187999998,
                40.67522229299991
              ],
              [
                -73.99540989400003,
                40.675563096999944
              ],
              [
                -73.99520266299999,
                40.67572952499992
              ],
              [
                -73.99506451099982,
                40.675840474999845
              ],
              [
                -73.99493096199983,
                40.67590705299988
              ],
              [
                -73.99479291199987,
                40.67596807599988
              ],
              [
                -73.99465075899997,
                40.676023369999925
              ],
              [
                -73.99450491199987,
                40.67607277499994
              ],
              [
                -73.99435579199994,
                40.67611614799993
              ],
              [
                -73.99420802699993,
                40.67615242699987
              ],
              [
                -73.9940579759999,
                40.676182783999884
              ],
              [
                -73.99396082100002,
                40.67616263899986
              ],
              [
                -73.99380485099994,
                40.676176602999895
              ],
              [
                -73.99364806799993,
                40.676183498999926
              ],
              [
                -73.993491024,
                40.67618330199986
              ],
              [
                -73.9933342719999,
                40.67617601299986
              ],
              [
                -73.99317836299984,
                40.676161657999906
              ],
              [
                -73.9931237449998,
                40.67617871399996
              ],
              [
                -73.99280910199987,
                40.676148749999854
              ],
              [
                -73.99279927099997,
                40.67613190599996
              ],
              [
                -73.99256575599999,
                40.67610581999992
              ],
              [
                -73.99069861899994,
                40.67580741899994
              ],
              [
                -73.99036651399997,
                40.676218320999965
              ],
              [
                -73.98986566199996,
                40.676826601999934
              ],
              [
                -73.98940896399986,
                40.67745451699991
              ],
              [
                -73.98931805499996,
                40.67825393999993
              ],
              [
                -73.98931813699981,
                40.67834938899991
              ],
              [
                -73.98897369299986,
                40.67884987699995
              ],
              [
                -73.98850509899981,
                40.6795371569999
              ],
              [
                -73.98808029299991,
                40.68017216599986
              ],
              [
                -73.9876680399999,
                40.68081780999993
              ],
              [
                -73.98722413899985,
                40.68144730199988
              ],
              [
                -73.98827189199999,
                40.68186309999987
              ],
              [
                -73.99042374299992,
                40.68269393299994
              ],
              [
                -73.99262292599985,
                40.68354371199993
              ],
              [
                -73.99490113599975,
                40.68443023799992
              ],
              [
                -73.99523240699986,
                40.68374079099995
              ],
              [
                -73.99555742799987,
                40.68306853499997
              ],
              [
                -73.99588110299992,
                40.68239495399995
              ],
              [
                -73.9962084629999,
                40.681723424999916
              ],
              [
                -73.998192111,
                40.68228732999989
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000143093037737,
        "objectid": 41,
        "shape_leng": 0.052793109453,
        "location_id": 41,
        "zone": "Central Harlem",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.94773985499985,
                40.80959972099989
              ],
              [
                -73.94783875999985,
                40.8096412609999
              ],
              [
                -73.94792306799984,
                40.809676921999944
              ],
              [
                -73.95067975599989,
                40.8108429639999
              ],
              [
                -73.9521012519999,
                40.811442853999935
              ],
              [
                -73.9525912579999,
                40.81076533299994
              ],
              [
                -73.95307869999975,
                40.81009408499988
              ],
              [
                -73.9532765489999,
                40.80987960399989
              ],
              [
                -73.9535757779999,
                40.8094766979999
              ],
              [
                -73.954966572,
                40.81006455499988
              ],
              [
                -73.95542974899983,
                40.80942915299993
              ],
              [
                -73.95588417599986,
                40.808802248999875
              ],
              [
                -73.95634493700001,
                40.80817335399992
              ],
              [
                -73.95680543899995,
                40.807545708999946
              ],
              [
                -73.95726581599985,
                40.80691617099996
              ],
              [
                -73.95772176,
                40.8062909889999
              ],
              [
                -73.95818167299996,
                40.80559679099991
              ],
              [
                -73.95825482599987,
                40.804713877999916
              ],
              [
                -73.958203008,
                40.803894270999905
              ],
              [
                -73.95824878299987,
                40.80310648599989
              ],
              [
                -73.95868839399998,
                40.80246979399986
              ],
              [
                -73.95914741099993,
                40.80184375999991
              ],
              [
                -73.95964685399987,
                40.80115642299993
              ],
              [
                -73.95848111500001,
                40.800670477999894
              ],
              [
                -73.95817297099987,
                40.800582540999876
              ],
              [
                -73.95786499299992,
                40.8005152719999
              ],
              [
                -73.95536651699993,
                40.799466425999846
              ],
              [
                -73.95528884999997,
                40.79943440199994
              ],
              [
                -73.95521824499984,
                40.799404657999894
              ],
              [
                -73.95516503099992,
                40.79938223299992
              ],
              [
                -73.95253597599994,
                40.798274645999896
              ],
              [
                -73.95245200999996,
                40.79824023099996
              ],
              [
                -73.95237419099993,
                40.79820977099995
              ],
              [
                -73.94960357199982,
                40.797050270999925
              ],
              [
                -73.94922045699984,
                40.796909858999896
              ],
              [
                -73.94909695599983,
                40.79705785599993
              ],
              [
                -73.94879165299982,
                40.797477127999905
              ],
              [
                -73.94831125500004,
                40.79812921799992
              ],
              [
                -73.9482064149998,
                40.79827285099987
              ],
              [
                -73.94815384299983,
                40.79834487599987
              ],
              [
                -73.94810108999994,
                40.7984171479999
              ],
              [
                -73.94805130199998,
                40.79848535999989
              ],
              [
                -73.94784679099992,
                40.798765542999945
              ],
              [
                -73.94781663099997,
                40.798806862999925
              ],
              [
                -73.94775716499986,
                40.79888833199987
              ],
              [
                -73.94771056,
                40.79895218099991
              ],
              [
                -73.94741996199991,
                40.79935029499992
              ],
              [
                -73.94737599799991,
                40.799410523999924
              ],
              [
                -73.94732771499984,
                40.79947667099992
              ],
              [
                -73.94728018299999,
                40.799541788999896
              ],
              [
                -73.94697366799987,
                40.79996170299987
              ],
              [
                -73.94646133899998,
                40.80066530499988
              ],
              [
                -73.9459678969998,
                40.801342394999935
              ],
              [
                -73.94550863900001,
                40.80197023099991
              ],
              [
                -73.94505128000003,
                40.80259859899995
              ],
              [
                -73.94475976499989,
                40.803003040999954
              ],
              [
                -73.94459751299998,
                40.80322814499992
              ],
              [
                -73.94613131,
                40.80387735799992
              ],
              [
                -73.9456926129999,
                40.804514948999916
              ],
              [
                -73.94523965199991,
                40.80513601399989
              ],
              [
                -73.94478208199993,
                40.80576125599991
              ],
              [
                -73.94430194599991,
                40.80639082499993
              ],
              [
                -73.94275367199994,
                40.80574401299986
              ],
              [
                -73.9422631119999,
                40.80641573799991
              ],
              [
                -73.9417714019999,
                40.80708899999997
              ],
              [
                -73.94491601899982,
                40.80841350399989
              ],
              [
                -73.94499360699993,
                40.808446183999955
              ],
              [
                -73.94506102999992,
                40.808474499999875
              ],
              [
                -73.94773985499985,
                40.80959972099989
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0000913594688556,
        "objectid": 45,
        "shape_leng": 0.0459068626235,
        "location_id": 45,
        "zone": "Chinatown",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.99750445299988,
                40.71406913199994
              ],
              [
                -73.99709223399998,
                40.71461839799991
              ],
              [
                -73.99680607099992,
                40.71503695599991
              ],
              [
                -73.99653128199989,
                40.715491390999894
              ],
              [
                -73.99622493199992,
                40.71599579099993
              ],
              [
                -73.99614929199988,
                40.716084840999955
              ],
              [
                -73.99605872699998,
                40.7162316399999
              ],
              [
                -73.99698375299992,
                40.716540542999894
              ],
              [
                -73.99776285999995,
                40.71681392799996
              ],
              [
                -73.99858925599978,
                40.717099511999905
              ],
              [
                -73.99931241699989,
                40.71755024199989
              ],
              [
                -73.999956209,
                40.71801709499989
              ],
              [
                -74.00027443699997,
                40.71761940199987
              ],
              [
                -74.00081842499998,
                40.71692970799994
              ],
              [
                -74.00168306199991,
                40.71574412299987
              ],
              [
                -74.00211455799992,
                40.715158909999936
              ],
              [
                -74.002453932,
                40.71478981999997
              ],
              [
                -74.00272286199994,
                40.71438970899991
              ],
              [
                -74.00321983099997,
                40.714036236999895
              ],
              [
                -74.00360280399998,
                40.71364542999989
              ],
              [
                -74.00405900799997,
                40.713134789999884
              ],
              [
                -74.00421405399993,
                40.712980742999896
              ],
              [
                -74.00429113899996,
                40.71290782599993
              ],
              [
                -74.00470652299994,
                40.71257401499987
              ],
              [
                -74.00485768099996,
                40.7124525409999
              ],
              [
                -74.00498716699994,
                40.712374856999894
              ],
              [
                -74.00512712599996,
                40.71230677299997
              ],
              [
                -74.00527561999998,
                40.71224943999993
              ],
              [
                -74.00543047899994,
                40.712203694999914
              ],
              [
                -74.00558932999998,
                40.712170053999905
              ],
              [
                -74.00574044699991,
                40.71208875699989
              ],
              [
                -74.0058917509999,
                40.71200715999988
              ],
              [
                -74.00573499299995,
                40.71197868799989
              ],
              [
                -74.00543986599992,
                40.71207853299993
              ],
              [
                -74.00538198399992,
                40.7120767429999
              ],
              [
                -74.00522812799994,
                40.71207345199987
              ],
              [
                -74.00518548699988,
                40.71207123299992
              ],
              [
                -74.00510989999995,
                40.71205559399987
              ],
              [
                -74.00499805099996,
                40.71201189699988
              ],
              [
                -74.00490674599992,
                40.711972278999966
              ],
              [
                -74.00442860399994,
                40.71178622599991
              ],
              [
                -74.00418143899992,
                40.71168714199992
              ],
              [
                -74.00382358799995,
                40.71155186199991
              ],
              [
                -74.00366118299998,
                40.711487282999954
              ],
              [
                -74.00336407499992,
                40.71126941399996
              ],
              [
                -74.0033244169999,
                40.71123748299989
              ],
              [
                -74.0025796699999,
                40.71063789699985
              ],
              [
                -74.00223869799997,
                40.710363931999886
              ],
              [
                -74.00173688999996,
                40.70996016999992
              ],
              [
                -74.00155908199999,
                40.70981710799992
              ],
              [
                -74.00147140299997,
                40.709746553999885
              ],
              [
                -74.00134439899988,
                40.70964629299989
              ],
              [
                -74.00055004999997,
                40.70901791699988
              ],
              [
                -74.00026566699995,
                40.70879294899988
              ],
              [
                -74.00019103100001,
                40.70873391299988
              ],
              [
                -74.000123591,
                40.70868451899993
              ],
              [
                -74.00000765599991,
                40.70859961999992
              ],
              [
                -73.9999293639999,
                40.708536817999864
              ],
              [
                -73.99981193899987,
                40.70844261499993
              ],
              [
                -73.99956273399984,
                40.70823648299993
              ],
              [
                -73.99944281399992,
                40.70813655699987
              ],
              [
                -73.99939955100004,
                40.70810033799996
              ],
              [
                -73.99936195599989,
                40.70807228699991
              ],
              [
                -73.99928731099999,
                40.708016602999926
              ],
              [
                -73.99919451154427,
                40.707947376675726
              ],
              [
                -73.99913434918955,
                40.707982397448504
              ],
              [
                -73.99905276738558,
                40.70803254536376
              ],
              [
                -73.99898340963244,
                40.708092653316136
              ],
              [
                -73.99888130863874,
                40.708136633919786
              ],
              [
                -73.99882158420985,
                40.70817548117486
              ],
              [
                -73.99880846276638,
                40.708200134992666
              ],
              [
                -73.99875396570859,
                40.708223651529806
              ],
              [
                -73.99872197955054,
                40.708237452896164
              ],
              [
                -73.9985611462062,
                40.70831528323355
              ],
              [
                -73.9985275858529,
                40.70833152790837
              ],
              [
                -73.99828602320575,
                40.70842993146763
              ],
              [
                -73.99808351186982,
                40.708509615367085
              ],
              [
                -73.99728287195568,
                40.708731619842865
              ],
              [
                -73.99666400996595,
                40.70882690451765
              ],
              [
                -73.99601898395763,
                40.70891622384743
              ],
              [
                -73.99580385653569,
                40.70894601390752
              ],
              [
                -73.99517577369818,
                40.709034293810255
              ],
              [
                -73.9945789996373,
                40.709130972909996
              ],
              [
                -73.9943782354393,
                40.70919263919896
              ],
              [
                -73.99414063200335,
                40.709233267399895
              ],
              [
                -73.99400820164024,
                40.709237479918954
              ],
              [
                -73.99374012862212,
                40.709246120435395
              ],
              [
                -73.99284846748641,
                40.70938187763877
              ],
              [
                -73.99236116139895,
                40.70946436881143
              ],
              [
                -73.9921486897014,
                40.70949860910412
              ],
              [
                -73.99209131635959,
                40.70950822240118
              ],
              [
                -73.99203926968342,
                40.709516919162766
              ],
              [
                -73.99190824271031,
                40.709532926643355
              ],
              [
                -73.99192047499996,
                40.70955429399989
              ],
              [
                -73.9919434659999,
                40.70958905299992
              ],
              [
                -73.99197509100001,
                40.70964553499988
              ],
              [
                -73.99200595899985,
                40.70969647899993
              ],
              [
                -73.99204746299989,
                40.70977574499992
              ],
              [
                -73.9919070979999,
                40.70980199399987
              ],
              [
                -73.99183817499984,
                40.709810551999965
              ],
              [
                -73.99177662699996,
                40.709819039999914
              ],
              [
                -73.99191690599989,
                40.710597505999885
              ],
              [
                -73.99197674899987,
                40.71091126599987
              ],
              [
                -73.99200166199995,
                40.7110155229999
              ],
              [
                -73.99202215999982,
                40.71110129999989
              ],
              [
                -73.99213550299994,
                40.71157561499989
              ],
              [
                -73.99215484499997,
                40.71165655599985
              ],
              [
                -73.99216806999992,
                40.71171189899994
              ],
              [
                -73.99218322399993,
                40.71177531099993
              ],
              [
                -73.99220577900002,
                40.71196063499989
              ],
              [
                -73.99221855999997,
                40.71206565499991
              ],
              [
                -73.99224319399994,
                40.71226805499987
              ],
              [
                -73.99225369399991,
                40.712354325999854
              ],
              [
                -73.99226747499985,
                40.712467556999925
              ],
              [
                -73.99239157000001,
                40.71319080999998
              ],
              [
                -73.9924556359999,
                40.71376288199985
              ],
              [
                -73.99246827699984,
                40.71385682499988
              ],
              [
                -73.99256242199989,
                40.714388076999974
              ],
              [
                -73.99282173499992,
                40.71436522999988
              ],
              [
                -73.99301588399996,
                40.71435562499989
              ],
              [
                -73.99395033499991,
                40.71429083199993
              ],
              [
                -73.99414039099992,
                40.71426893199987
              ],
              [
                -73.99431855699987,
                40.71425088499997
              ],
              [
                -73.99434786700003,
                40.71424791599986
              ],
              [
                -73.99450033499993,
                40.71423116399996
              ],
              [
                -73.99465684999993,
                40.714219446999884
              ],
              [
                -73.99472479499984,
                40.71421571299988
              ],
              [
                -73.99484048899991,
                40.71420935199993
              ],
              [
                -73.99647830999992,
                40.7141072639999
              ],
              [
                -73.99744448599988,
                40.71406687299986
              ],
              [
                -73.99750445299988,
                40.71406913199994
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000926391677672,
        "objectid": 46,
        "shape_leng": 0.134475429879,
        "location_id": 46,
        "zone": "City Island",
        "borough": "Bronx"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.78452431937963,
                40.86047706314786
              ],
              [
                -73.78455587045978,
                40.86050314234219
              ],
              [
                -73.7845914939652,
                40.8605097253584
              ],
              [
                -73.78463757790949,
                40.86050929977011
              ],
              [
                -73.7846771354921,
                40.86050537012107
              ],
              [
                -73.78473518651981,
                40.86048946636657
              ],
              [
                -73.7847879909767,
                40.86046554650664
              ],
              [
                -73.78483815862118,
                40.8604416212028
              ],
              [
                -73.78489093019402,
                40.86042770842686
              ],
              [
                -73.78491727455626,
                40.86043376292766
              ],
              [
                -73.78494355332282,
                40.86045983118216
              ],
              [
                -73.78499095625652,
                40.86047593210825
              ],
              [
                -73.78503841008691,
                40.86047602052987
              ],
              [
                -73.78510175844113,
                40.86045212017063
              ],
              [
                -73.78512562839124,
                40.86040813020052
              ],
              [
                -73.7851521214915,
                40.86036814741715
              ],
              [
                -73.78522080164107,
                40.860326243438216
              ],
              [
                -73.78530786449265,
                40.860306389416
              ],
              [
                -73.78538694703576,
                40.860308537949194
              ],
              [
                -73.7854818874012,
                40.860298706813445
              ],
              [
                -73.78558478790842,
                40.860272877645336
              ],
              [
                -73.78567185124342,
                40.860253023349415
              ],
              [
                -73.78576157641793,
                40.86022516931569
              ],
              [
                -73.78586179482426,
                40.860213345592804
              ],
              [
                -73.78595670855401,
                40.8602115203072
              ],
              [
                -73.78604370086556,
                40.86021368306417
              ],
              [
                -73.78610698578403,
                40.860209796420236
              ],
              [
                -73.7861808787995,
                40.86018591501637
              ],
              [
                -73.7862785008828,
                40.86016207752269
              ],
              [
                -73.78640247859543,
                40.8601402896442
              ],
              [
                -73.7864973984128,
                40.86013646185005
              ],
              [
                -73.78663190867239,
                40.86011869602478
              ],
              [
                -73.78672426294726,
                40.860092847007245
              ],
              [
                -73.78679816291357,
                40.86006696264338
              ],
              [
                -73.78685886773299,
                40.860045057839564
              ],
              [
                -73.78689858015443,
                40.8599930904772
              ],
              [
                -73.7868987212506,
                40.859949056309404
              ],
              [
                -73.78691471140883,
                40.85989504422061
              ],
              [
                -73.78692012622042,
                40.8598510197918
              ],
              [
                -73.78692554523269,
                40.85980499329491
              ],
              [
                -73.78694940091926,
                40.85976500639903
              ],
              [
                -73.78698106160043,
                40.859757058065334
              ],
              [
                -73.78700212067199,
                40.859767105142815
              ],
              [
                -73.78702051604037,
                40.8597851525846
              ],
              [
                -73.7870415879457,
                40.85979119636583
              ],
              [
                -73.78705743121485,
                40.85978321931734
              ],
              [
                -73.78707858510543,
                40.85976324229673
              ],
              [
                -73.78709446746643,
                40.859743257219804
              ],
              [
                -73.787107726334,
                40.85971926164171
              ],
              [
                -73.78711182162283,
                40.859699399720206
              ],
              [
                -73.78710521132136,
                40.859681227958674
              ],
              [
                -73.78708149186802,
                40.85967918278342
              ],
              [
                -73.78706307596636,
                40.85966713902588
              ],
              [
                -73.78702108009922,
                40.8596090159042
              ],
              [
                -73.78698691781936,
                40.85957492610177
              ],
              [
                -73.78697913779823,
                40.859534880786455
              ],
              [
                -73.7870320483265,
                40.85947693335828
              ],
              [
                -73.78707963601639,
                40.859434987659185
              ],
              [
                -73.78713520321362,
                40.85937104019999
              ],
              [
                -73.7871854793933,
                40.85931308733737
              ],
              [
                -73.78718675246938,
                40.859311590516356
              ],
              [
                -73.78723311169142,
                40.85925713098206
              ],
              [
                -73.78726224420458,
                40.85921515284668
              ],
              [
                -73.7872941173816,
                40.85914115274857
              ],
              [
                -73.78732060778712,
                40.85910117062935
              ],
              [
                -73.78732864420107,
                40.85906115381899
              ],
              [
                -73.78736038283725,
                40.8590291876556
              ],
              [
                -73.7874158916261,
                40.858983253603334
              ],
              [
                -73.78743186194176,
                40.858935245614454
              ],
              [
                -73.78740560244165,
                40.8589031720644
              ],
              [
                -73.78736608944581,
                40.85889309138494
              ],
              [
                -73.78733185055222,
                40.858883020581175
              ],
              [
                -73.78729498157857,
                40.85887094284298
              ],
              [
                -73.78727504379454,
                40.858854636519254
              ],
              [
                -73.78726083742153,
                40.85883084826378
              ],
              [
                -73.78722398107531,
                40.858814767715145
              ],
              [
                -73.78716072383345,
                40.85881064872949
              ],
              [
                -73.78710536878255,
                40.858808545696235
              ],
              [
                -73.78706841636692,
                40.85882248700689
              ],
              [
                -73.78705987145722,
                40.85882528948739
              ],
              [
                -73.78700771825797,
                40.858842391033136
              ],
              [
                -73.78695485110823,
                40.8588863278604
              ],
              [
                -73.7868993290349,
                40.85893626464588
              ],
              [
                -73.78687017009231,
                40.85898625010733
              ],
              [
                -73.78683848410708,
                40.859002203650704
              ],
              [
                -73.78680410917073,
                40.85903416597232
              ],
              [
                -73.7867301085431,
                40.859092074819856
              ],
              [
                -73.78666931246262,
                40.859142000739304
              ],
              [
                -73.78662961453665,
                40.85918996507849
              ],
              [
                -73.78657148225405,
                40.85923189058588
              ],
              [
                -73.78650814623157,
                40.859251789468956
              ],
              [
                -73.78646327947388,
                40.85926771851843
              ],
              [
                -73.78642632600138,
                40.8592816612963
              ],
              [
                -73.78636034162425,
                40.85930555750373
              ],
              [
                -73.78629177967478,
                40.85931143541398
              ],
              [
                -73.78622853422603,
                40.85930331195699
              ],
              [
                -73.78616528302004,
                40.85929718985976
              ],
              [
                -73.78608881774542,
                40.85930105209852
              ],
              [
                -73.78601763836582,
                40.85930092010697
              ],
              [
                -73.78594123750362,
                40.859284765801405
              ],
              [
                -73.78588066129579,
                40.85926663873898
              ],
              [
                -73.78582168503188,
                40.859254132521066
              ],
              [
                -73.7857726886443,
                40.85923041016361
              ],
              [
                -73.78572792401741,
                40.85921431495626
              ],
              [
                -73.78569372346445,
                40.85919223396571
              ],
              [
                -73.78569113232625,
                40.859178218477496
              ],
              [
                -73.78568065813838,
                40.859156181551874
              ],
              [
                -73.78566224996798,
                40.85914213617787
              ],
              [
                -73.78564115948065,
                40.85914209699523
              ],
              [
                -73.78562002983944,
                40.85915406750844
              ],
              [
                -73.78559628481331,
                40.85916002709871
              ],
              [
                -73.78558046666774,
                40.85915999770251
              ],
              [
                -73.78556732396561,
                40.85914796467686
              ],
              [
                -73.78555685070432,
                40.85912592824412
              ],
              [
                -73.78555172614126,
                40.8590798817043
              ],
              [
                -73.78553605067312,
                40.85903581812993
              ],
              [
                -73.78552292047279,
                40.85901978164125
              ],
              [
                -73.78549921267165,
                40.859013732680594
              ],
              [
                -73.78548075336799,
                40.85901570093845
              ],
              [
                -73.78545964311272,
                40.85902166539953
              ],
              [
                -73.78543056633485,
                40.859045630873105
              ],
              [
                -73.7854147107948,
                40.859057609984625
              ],
              [
                -73.78538834843641,
                40.85905756095048
              ],
              [
                -73.78535416011117,
                40.859031477572664
              ],
              [
                -73.78535158219417,
                40.85901345811734
              ],
              [
                -73.78535426993068,
                40.858997450533984
              ],
              [
                -73.78537016554837,
                40.858973461732184
              ],
              [
                -73.78534653595938,
                40.85894339451233
              ],
              [
                -73.78533335414066,
                40.85894336998699
              ],
              [
                -73.78530432970666,
                40.85895132227249
              ],
              [
                -73.78529633019848,
                40.85897932873801
              ],
              [
                -73.785275214795,
                40.858987296236464
              ],
              [
                -73.78525939602868,
                40.85898726679502
              ],
              [
                -73.78525422152342,
                40.858957232731505
              ],
              [
                -73.78525697970875,
                40.858919208313246
              ],
              [
                -73.7852676088167,
                40.85889320831733
              ],
              [
                -73.7853019779577,
                40.85886324784616
              ],
              [
                -73.78529680189467,
                40.85883321495372
              ],
              [
                -73.78527841239523,
                40.85881316583795
              ],
              [
                -73.78523623919948,
                40.85881108476439
              ],
              [
                -73.78519664387112,
                40.85882702363741
              ],
              [
                -73.78519126744986,
                40.858859039466296
              ],
              [
                -73.78518853442692,
                40.858889057638095
              ],
              [
                -73.78518844391954,
                40.85891707932431
              ],
              [
                -73.78517519142918,
                40.858939072119874
              ],
              [
                -73.78515408763242,
                40.85894303630438
              ],
              [
                -73.78513035557019,
                40.85894499351462
              ],
              [
                -73.78510392836097,
                40.85896496035969
              ],
              [
                -73.785045897498,
                40.85897485945252
              ],
              [
                -73.78500630915828,
                40.85898879686749
              ],
              [
                -73.78497195857571,
                40.85901275122567
              ],
              [
                -73.78494557725956,
                40.85901870694156
              ],
              [
                -73.78492443458967,
                40.8590346796118
              ],
              [
                -73.7849032932319,
                40.85905065278331
              ],
              [
                -73.78487654337043,
                40.85905832709181
              ],
              [
                -73.78483197791422,
                40.85909255283886
              ],
              [
                -73.78480288859095,
                40.859120520439276
              ],
              [
                -73.78476590334343,
                40.859144471496926
              ],
              [
                -73.78472896902184,
                40.85915240837877
              ],
              [
                -73.78470517053249,
                40.859174381442344
              ],
              [
                -73.78470241202126,
                40.85921240584558
              ],
              [
                -73.78469969172261,
                40.85923842071495
              ],
              [
                -73.7846758996747,
                40.859258391708714
              ],
              [
                -73.784644122295,
                40.859302366834996
              ],
              [
                -73.78461240880978,
                40.85932632718044
              ],
              [
                -73.78460172751247,
                40.85936834077296
              ],
              [
                -73.78462528561683,
                40.85942042497647
              ],
              [
                -73.78460404682812,
                40.85946642116827
              ],
              [
                -73.7845643074365,
                40.859526393486924
              ],
              [
                -73.78455623644251,
                40.85957641773656
              ],
              [
                -73.78454815872834,
                40.859628444550694
              ],
              [
                -73.78455594466404,
                40.85966648813517
              ],
              [
                -73.78456904816244,
                40.85969053097362
              ],
              [
                -73.7845715637229,
                40.85971548228986
              ],
              [
                -73.78449516071953,
                40.85971241045508
              ],
              [
                -73.78442391602212,
                40.85973229342179
              ],
              [
                -73.78440007895895,
                40.859766274445036
              ],
              [
                -73.78438676719426,
                40.85980628169408
              ],
              [
                -73.78436807918104,
                40.859878303052135
              ],
              [
                -73.78436267137351,
                40.859920325971984
              ],
              [
                -73.78436247019211,
                40.85998237351182
              ],
              [
                -73.78435963136363,
                40.86004441729388
              ],
              [
                -73.78437534588969,
                40.860076471323275
              ],
              [
                -73.78442797497668,
                40.86010659359294
              ],
              [
                -73.78446215634142,
                40.860134679134525
              ],
              [
                -73.78446994161976,
                40.860172724395454
              ],
              [
                -73.78447503221115,
                40.8602287764298
              ],
              [
                -73.7844800977429,
                40.860292836382015
              ],
              [
                -73.78448776047776,
                40.86036891095489
              ],
              [
                -73.7844902538973,
                40.86041295004045
              ],
              [
                -73.78452431937963,
                40.86047706314786
              ]
            ]
          ],
          [
            [
              [
                -73.76783205637173,
                40.854442205742075
              ],
              [
                -73.76533243995276,
                40.85504359512487
              ],
              [
                -73.768360859188,
                40.857655363705895
              ],
              [
                -73.76836277290795,
                40.85835828885182
              ],
              [
                -73.76947305423997,
                40.859330136668596
              ],
              [
                -73.77084562380267,
                40.86010101292837
              ],
              [
                -73.77219223055407,
                40.859986638596986
              ],
              [
                -73.77235432710658,
                40.859673161980496
              ],
              [
                -73.7722368970485,
                40.8591778687732
              ],
              [
                -73.77219364526376,
                40.858992135745304
              ],
              [
                -73.77218152925143,
                40.858868346783424
              ],
              [
                -73.77218824023912,
                40.858739834638655
              ],
              [
                -73.77220755388522,
                40.85859230561814
              ],
              [
                -73.77220802669532,
                40.85845426144408
              ],
              [
                -73.77219618881765,
                40.858249549157
              ],
              [
                -73.77216552491815,
                40.85804955953898
              ],
              [
                -73.77216585105373,
                40.85795435608547
              ],
              [
                -73.77215380205806,
                40.857811526141674
              ],
              [
                -73.77216818289573,
                40.85727365139706
              ],
              [
                -73.77216934064688,
                40.856935678765595
              ],
              [
                -73.77213970639943,
                40.85643579919958
              ],
              [
                -73.77214690490916,
                40.856164481774634
              ],
              [
                -73.77217269968631,
                40.855955082859246
              ],
              [
                -73.77218644562852,
                40.85560285594478
              ],
              [
                -73.77220606901119,
                40.85536488384107
              ],
              [
                -73.7722002403433,
                40.85523634635437
              ],
              [
                -73.77218226378271,
                40.85499353996747
              ],
              [
                -73.77214547937614,
                40.85475069709279
              ],
              [
                -73.77212722589734,
                40.85458881421388
              ],
              [
                -73.77214036841814,
                40.85441271260061
              ],
              [
                -73.77219724701617,
                40.85427953840103
              ],
              [
                -73.77222913187387,
                40.854122515546315
              ],
              [
                -73.7722877176082,
                40.85401044932686
              ],
              [
                -73.77231116956705,
                40.853965589494415
              ],
              [
                -73.77233272219733,
                40.85390084467517
              ],
              [
                -73.77269448026055,
                40.85390663901334
              ],
              [
                -73.77269430639828,
                40.85395751299704
              ],
              [
                -73.77280008065787,
                40.853963076256626
              ],
              [
                -73.77279597721957,
                40.85413175870195
              ],
              [
                -73.77295817517044,
                40.85413743292338
              ],
              [
                -73.77296285334867,
                40.853800062246464
              ],
              [
                -73.77281122687661,
                40.85379708655031
              ],
              [
                -73.77280751808897,
                40.85385063127562
              ],
              [
                -73.77234917454822,
                40.853851420736646
              ],
              [
                -73.7723620067985,
                40.85376576110119
              ],
              [
                -73.77233735401518,
                40.85364194680004
              ],
              [
                -73.77231266873179,
                40.853527653312796
              ],
              [
                -73.77229293607876,
                40.85347977623899
              ],
              [
                -73.77227536313346,
                40.853437135363876
              ],
              [
                -73.77227577057597,
                40.85331813153632
              ],
              [
                -73.77229680531372,
                40.85308637825238
              ],
              [
                -73.7717171593653,
                40.853004539281784
              ],
              [
                -73.77173060411904,
                40.852476831319095
              ],
              [
                -73.77245149415468,
                40.852435896893454
              ],
              [
                -73.77245940163017,
                40.85210626708215
              ],
              [
                -73.77261479509698,
                40.8521765043353
              ],
              [
                -73.77258429583127,
                40.852214025867795
              ],
              [
                -73.77263258309878,
                40.85223803743508
              ],
              [
                -73.7726393580297,
                40.85223036227598
              ],
              [
                -73.7728907573129,
                40.85239826899823
              ],
              [
                -73.772904294762,
                40.85238719216116
              ],
              [
                -73.77264837746921,
                40.85222440262956
              ],
              [
                -73.77270601491698,
                40.85214593507689
              ],
              [
                -73.77287683950732,
                40.852191541178236
              ],
              [
                -73.77287681617626,
                40.85219837376394
              ],
              [
                -73.772945391628,
                40.852210466946836
              ],
              [
                -73.77296793744162,
                40.8521968453855
              ],
              [
                -73.77296356611284,
                40.85215925395861
              ],
              [
                -73.77292081162743,
                40.85216173175276
              ],
              [
                -73.77291739323162,
                40.85217453792541
              ],
              [
                -73.77271845211186,
                40.85212716875466
              ],
              [
                -73.77272072829567,
                40.85211948523007
              ],
              [
                -73.772671305329,
                40.85209888878226
              ],
              [
                -73.77264417003487,
                40.85213898007616
              ],
              [
                -73.77246052295433,
                40.8520595421737
              ],
              [
                -73.77246594126663,
                40.85183368399969
              ],
              [
                -73.77270912061607,
                40.851847663461555
              ],
              [
                -73.7727111749615,
                40.85182658093368
              ],
              [
                -73.77246651950135,
                40.85180956889385
              ],
              [
                -73.77246892938182,
                40.85170914738931
              ],
              [
                -73.7720496366846,
                40.85161828975743
              ],
              [
                -73.7708892022117,
                40.84975177133809
              ],
              [
                -73.77130772937919,
                40.84802877432174
              ],
              [
                -73.77157218833237,
                40.84750005858031
              ],
              [
                -73.77118378010564,
                40.84694451331219
              ],
              [
                -73.76998042657765,
                40.845951412869525
              ],
              [
                -73.76901389181107,
                40.845097342721935
              ],
              [
                -73.76789492672636,
                40.84528943351961
              ],
              [
                -73.76805636086223,
                40.845598218137475
              ],
              [
                -73.76950321562752,
                40.84721783423511
              ],
              [
                -73.76959674311463,
                40.8475945190982
              ],
              [
                -73.76860817687106,
                40.85078839537813
              ],
              [
                -73.76938529139694,
                40.85253613399676
              ],
              [
                -73.7690443898714,
                40.85329092469354
              ],
              [
                -73.7685566032706,
                40.85323832295944
              ],
              [
                -73.76823126524438,
                40.85388788880734
              ],
              [
                -73.76783205637173,
                40.854442205742075
              ]
            ]
          ],
          [
            [
              [
                -73.79018745194999,
                40.85860991738612
              ],
              [
                -73.79020122975753,
                40.85860158971046
              ],
              [
                -73.79014637788677,
                40.85855276703348
              ],
              [
                -73.79016016469429,
                40.85854165463286
              ],
              [
                -73.79013640468453,
                40.85851724995587
              ],
              [
                -73.79012538798082,
                40.85852210234227
              ],
              [
                -73.79006133563223,
                40.85845954856133
              ],
              [
                -73.79023592738741,
                40.858154084811254
              ],
              [
                -73.7904419014552,
                40.85791653409865
              ],
              [
                -73.79068061700995,
                40.85780279032979
              ],
              [
                -73.79079067047185,
                40.857750350817845
              ],
              [
                -73.79093222800743,
                40.85756778747055
              ],
              [
                -73.79122161784531,
                40.85770139903971
              ],
              [
                -73.79121179649876,
                40.8577149922318
              ],
              [
                -73.79127850954808,
                40.85774976102998
              ],
              [
                -73.79129321642519,
                40.857737414000304
              ],
              [
                -73.79161866075827,
                40.85790134204811
              ],
              [
                -73.79140929150854,
                40.85814226029775
              ],
              [
                -73.79143695908105,
                40.858154685123104
              ],
              [
                -73.79159890915145,
                40.85796317756002
              ],
              [
                -73.7917852942325,
                40.857775425855564
              ],
              [
                -73.79175600351645,
                40.85776052352312
              ],
              [
                -73.7916366458796,
                40.85788281257089
              ],
              [
                -73.79130630096441,
                40.85772258820141
              ],
              [
                -73.79131613460314,
                40.857705282531
              ],
              [
                -73.79124452095031,
                40.857674217379795
              ],
              [
                -73.79123307405831,
                40.857686570302484
              ],
              [
                -73.79094202260366,
                40.85755274818878
              ],
              [
                -73.79129378852147,
                40.85726323869817
              ],
              [
                -73.79160393461318,
                40.85737531299246
              ],
              [
                -73.79173979972589,
                40.8571783542773
              ],
              [
                -73.79184303217382,
                40.85702870201134
              ],
              [
                -73.79209332504095,
                40.857157989947204
              ],
              [
                -73.7918728196394,
                40.857427183076766
              ],
              [
                -73.7917578139993,
                40.857374179454
              ],
              [
                -73.79174254082349,
                40.85739395000422
              ],
              [
                -73.79244336403542,
                40.857736741971934
              ],
              [
                -73.79237353813318,
                40.85782901033757
              ],
              [
                -73.79238725639992,
                40.857839475267994
              ],
              [
                -73.79239521723282,
                40.85784554775221
              ],
              [
                -73.79256970440578,
                40.857639625362246
              ],
              [
                -73.79255162803088,
                40.85763007584015
              ],
              [
                -73.79254150089487,
                40.8576247251838
              ],
              [
                -73.792465160653,
                40.857715331784
              ],
              [
                -73.79189451913076,
                40.85743712186527
              ],
              [
                -73.79211266783624,
                40.85716693187042
              ],
              [
                -73.7926355732806,
                40.85742195660254
              ],
              [
                -73.79257669621389,
                40.85748784672485
              ],
              [
                -73.79260923020631,
                40.857507704502304
              ],
              [
                -73.79268338575463,
                40.85742039359269
              ],
              [
                -73.79185432641995,
                40.85701379719813
              ],
              [
                -73.79185978715203,
                40.857004107259364
              ],
              [
                -73.79271730383812,
                40.85741901131974
              ],
              [
                -73.79281074586504,
                40.85746422142734
              ],
              [
                -73.79288403888904,
                40.85747920249364
              ],
              [
                -73.79299628478098,
                40.85706359655043
              ],
              [
                -73.79301264383005,
                40.857003026043145
              ],
              [
                -73.79295400784763,
                40.85699178335117
              ],
              [
                -73.79289753888771,
                40.85718939649052
              ],
              [
                -73.79282713237976,
                40.8574357899179
              ],
              [
                -73.79273719867784,
                40.85739366621667
              ],
              [
                -73.79222189186365,
                40.85715229842467
              ],
              [
                -73.79224490126386,
                40.85712310596905
              ],
              [
                -73.79226687280277,
                40.8571328903861
              ],
              [
                -73.7924279413047,
                40.85692854253672
              ],
              [
                -73.79237484012394,
                40.85690617253139
              ],
              [
                -73.79221469914035,
                40.857107042845776
              ],
              [
                -73.79222842636995,
                40.85711472423879
              ],
              [
                -73.79220265950248,
                40.857146000410324
              ],
              [
                -73.79187172581227,
                40.85698428896384
              ],
              [
                -73.79182143771003,
                40.85690024255173
              ],
              [
                -73.79173546476552,
                40.856866429528196
              ],
              [
                -73.79183269744581,
                40.856833125058635
              ],
              [
                -73.79183633462834,
                40.85684287530318
              ],
              [
                -73.7918822302671,
                40.85682416512516
              ],
              [
                -73.79186032552957,
                40.85679280353208
              ],
              [
                -73.79170704257358,
                40.85685184285502
              ],
              [
                -73.79178502817685,
                40.856730857945124
              ],
              [
                -73.79192219262924,
                40.85677873324112
              ],
              [
                -73.79192955714267,
                40.85676900166847
              ],
              [
                -73.79179264535283,
                40.85671985281914
              ],
              [
                -73.79182299976162,
                40.8566761075128
              ],
              [
                -73.79195628211025,
                40.85672450329607
              ],
              [
                -73.79196180591408,
                40.85671685658512
              ],
              [
                -73.79182845506858,
                40.85666814339471
              ],
              [
                -73.7918421311268,
                40.85666118333631
              ],
              [
                -73.79185937713562,
                40.85666592438058
              ],
              [
                -73.79189422958875,
                40.85656888932964
              ],
              [
                -73.7930483589582,
                40.856752353857736
              ],
              [
                -73.7930307836123,
                40.85681391795154
              ],
              [
                -73.79305685295346,
                40.85681616547445
              ],
              [
                -73.79309492152382,
                40.85668644140406
              ],
              [
                -73.79306886680173,
                40.85667979474308
              ],
              [
                -73.7930513380812,
                40.8567259611496
              ],
              [
                -73.7919018334025,
                40.85654691136797
              ],
              [
                -73.79190832251761,
                40.85652395422946
              ],
              [
                -73.79200911229961,
                40.856540144209966
              ],
              [
                -73.79201746383625,
                40.85650814112618
              ],
              [
                -73.79205778470703,
                40.85651308623457
              ],
              [
                -73.79206981995775,
                40.85647552247019
              ],
              [
                -73.79208814819948,
                40.85647764200938
              ],
              [
                -73.79209740206089,
                40.85644981691218
              ],
              [
                -73.79205892560435,
                40.85644139483645
              ],
              [
                -73.79207000927919,
                40.85641496516156
              ],
              [
                -73.79193700279743,
                40.856405157922175
              ],
              [
                -73.79198719874023,
                40.85636551214246
              ],
              [
                -73.79201050009051,
                40.85622512771837
              ],
              [
                -73.7925681412599,
                40.85625611876997
              ],
              [
                -73.79256817599392,
                40.856244981854694
              ],
              [
                -73.79263294381181,
                40.85624973902941
              ],
              [
                -73.79261793796836,
                40.85635829490761
              ],
              [
                -73.79311527696748,
                40.85640466330657
              ],
              [
                -73.79310775533048,
                40.85646497410613
              ],
              [
                -73.79314686085958,
                40.85646782847117
              ],
              [
                -73.79315700296155,
                40.85635091107476
              ],
              [
                -73.79311911983078,
                40.85634805890509
              ],
              [
                -73.7931153780054,
                40.85637218147507
              ],
              [
                -73.79268768222656,
                40.856335218720375
              ],
              [
                -73.79270523985137,
                40.85619232890572
              ],
              [
                -73.7926453206665,
                40.85620057278917
              ],
              [
                -73.79263787674974,
                40.85623582617105
              ],
              [
                -73.79256944485117,
                40.856230135837635
              ],
              [
                -73.79257074199624,
                40.856206007213785
              ],
              [
                -73.79201712228527,
                40.85617694860626
              ],
              [
                -73.79203322933732,
                40.85614052347183
              ],
              [
                -73.79211616948511,
                40.85601261529167
              ],
              [
                -73.79230720796731,
                40.856072355408216
              ],
              [
                -73.79241821541393,
                40.855995344340656
              ],
              [
                -73.7924879115736,
                40.85594699320981
              ],
              [
                -73.79249297838457,
                40.85589406348062
              ],
              [
                -73.79250306685435,
                40.855788666137045
              ],
              [
                -73.79207675667554,
                40.85564516770712
              ],
              [
                -73.79186843464552,
                40.85541675186694
              ],
              [
                -73.79129282160632,
                40.85458402597687
              ],
              [
                -73.79133811791583,
                40.854439011538304
              ],
              [
                -73.7919181173638,
                40.85422235474285
              ],
              [
                -73.79179366809404,
                40.85400482990041
              ],
              [
                -73.79182140042256,
                40.85399695489827
              ],
              [
                -73.79182612163208,
                40.85400148723032
              ],
              [
                -73.79251736175938,
                40.85392229994337
              ],
              [
                -73.79253009854688,
                40.85401884269121
              ],
              [
                -73.79256758944628,
                40.854016434943034
              ],
              [
                -73.79253714604873,
                40.85384932781285
              ],
              [
                -73.79250616578281,
                40.85385422192265
              ],
              [
                -73.79251418368892,
                40.853896308270706
              ],
              [
                -73.79181716188373,
                40.85398003873739
              ],
              [
                -73.7918178199531,
                40.853989436670055
              ],
              [
                -73.7917861117569,
                40.853993539900856
              ],
              [
                -73.79171835293752,
                40.85391732878056
              ],
              [
                -73.79158360989689,
                40.85389088823204
              ],
              [
                -73.79138477253804,
                40.85389547867386
              ],
              [
                -73.79108281745684,
                40.85396319703526
              ],
              [
                -73.79107002765942,
                40.85394495666466
              ],
              [
                -73.79244069248779,
                40.85363037509782
              ],
              [
                -73.79246226638655,
                40.853679911499874
              ],
              [
                -73.79248836752521,
                40.85367170890936
              ],
              [
                -73.7924581684481,
                40.85360070875488
              ],
              [
                -73.79129023495192,
                40.853878722994494
              ],
              [
                -73.79128417842017,
                40.853861078984096
              ],
              [
                -73.79105721254825,
                40.853918412426644
              ],
              [
                -73.79129041006202,
                40.85384457425318
              ],
              [
                -73.79127368128135,
                40.85373220788165
              ],
              [
                -73.792999480554,
                40.85332937972204
              ],
              [
                -73.7930138163248,
                40.853377802403955
              ],
              [
                -73.7930399117026,
                40.85337124928513
              ],
              [
                -73.79287610048584,
                40.8529441813284
              ],
              [
                -73.79284710092516,
                40.85295292995603
              ],
              [
                -73.79297928311027,
                40.85330294490942
              ],
              [
                -73.79255598392581,
                40.85340117854083
              ],
              [
                -73.79240931036925,
                40.85305333611478
              ],
              [
                -73.79238610589364,
                40.85306209388163
              ],
              [
                -73.79252408728894,
                40.85340992070495
              ],
              [
                -73.79126573165874,
                40.853706864233104
              ],
              [
                -73.7911143142938,
                40.85326445192463
              ],
              [
                -73.79086201986841,
                40.853319781103444
              ],
              [
                -73.79080758729921,
                40.85319906006435
              ],
              [
                -73.79283890218414,
                40.852707698373386
              ],
              [
                -73.79290086687061,
                40.85269543598237
              ],
              [
                -73.79281511966936,
                40.852494819308916
              ],
              [
                -73.79276455802382,
                40.85250833946969
              ],
              [
                -73.79283083884894,
                40.85268046076782
              ],
              [
                -73.79250954048048,
                40.85276155271436
              ],
              [
                -73.79243675473343,
                40.85258570820602
              ],
              [
                -73.79241717582832,
                40.852593097969546
              ],
              [
                -73.79248669881495,
                40.852770173952194
              ],
              [
                -73.7922518609755,
                40.85282296038217
              ],
              [
                -73.79217580837809,
                40.85264958432159
              ],
              [
                -73.79215297057404,
                40.85265696816939
              ],
              [
                -73.79222413063728,
                40.85283157209429
              ],
              [
                -73.79198928164561,
                40.85288807029797
              ],
              [
                -73.79191649946218,
                40.85271098813851
              ],
              [
                -73.7918936538656,
                40.85272084658305
              ],
              [
                -73.79196318311136,
                40.85289544822877
              ],
              [
                -73.79172995524623,
                40.85295442349013
              ],
              [
                -73.79165554436234,
                40.8527773382265
              ],
              [
                -73.79163107691939,
                40.85278471852539
              ],
              [
                -73.79170223896095,
                40.85295808594556
              ],
              [
                -73.79150327251574,
                40.85300474895966
              ],
              [
                -73.79143702716479,
                40.85282272848614
              ],
              [
                -73.7913897249528,
                40.8528362539371
              ],
              [
                -73.7914543630373,
                40.853010847713506
              ],
              [
                -73.79103033114585,
                40.85311154959278
              ],
              [
                -73.79100777755541,
                40.853028600355245
              ],
              [
                -73.79098982724432,
                40.853035992826676
              ],
              [
                -73.79100586740225,
                40.85311769126193
              ],
              [
                -73.79079150757708,
                40.853163399592205
              ],
              [
                -73.79077127595484,
                40.853118526990635
              ],
              [
                -73.79049879362734,
                40.853188722518674
              ],
              [
                -73.79042053780161,
                40.85302297821258
              ],
              [
                -73.79144640027788,
                40.85277157192411
              ],
              [
                -73.79139106468679,
                40.852628074985894
              ],
              [
                -73.79342400669049,
                40.85213630252754
              ],
              [
                -73.793478629143,
                40.85227279103698
              ],
              [
                -73.79350762831913,
                40.85226404392483
              ],
              [
                -73.79340987631169,
                40.852021883730366
              ],
              [
                -73.793383767393,
                40.85203283608551
              ],
              [
                -73.79340960300236,
                40.85210987866148
              ],
              [
                -73.79138052695514,
                40.85260203830472
              ],
              [
                -73.79124556783773,
                40.852304681410736
              ],
              [
                -73.79312894073925,
                40.851848072675836
              ],
              [
                -73.7931435368942,
                40.85187037215122
              ],
              [
                -73.7932378678764,
                40.85193117467445
              ],
              [
                -73.7932525807951,
                40.851916352733845
              ],
              [
                -73.79315339612619,
                40.85184440453376
              ],
              [
                -73.79322026048357,
                40.85182843708702
              ],
              [
                -73.79311837183832,
                40.851578295240834
              ],
              [
                -73.79308901965723,
                40.85158442975804
              ],
              [
                -73.79318284075845,
                40.85180857187128
              ],
              [
                -73.79123655971756,
                40.852280289886465
              ],
              [
                -73.79123084007696,
                40.852264806530236
              ],
              [
                -73.7913646086994,
                40.852232943085355
              ],
              [
                -73.79136187979904,
                40.85222597753385
              ],
              [
                -73.79122653385119,
                40.8522580105032
              ],
              [
                -73.79121251169283,
                40.85221517641535
              ],
              [
                -73.7913418457985,
                40.85218348288132
              ],
              [
                -73.79133456753253,
                40.85216606767367
              ],
              [
                -73.79120559068458,
                40.85219774978446
              ],
              [
                -73.79113795170926,
                40.85205585257368
              ],
              [
                -73.79017007126295,
                40.85228066115342
              ],
              [
                -73.79016350155166,
                40.85227183108284
              ],
              [
                -73.79205731457873,
                40.8518255732624
              ],
              [
                -73.79204882760328,
                40.85180328466126
              ],
              [
                -73.79180175231375,
                40.85186223510991
              ],
              [
                -73.79176780605944,
                40.851774007706055
              ],
              [
                -73.79229872412925,
                40.851623690084494
              ],
              [
                -73.79229143993996,
                40.8516078992365
              ],
              [
                -73.79249450978077,
                40.851550725092665
              ],
              [
                -73.79248359357182,
                40.85152379140689
              ],
              [
                -73.79226217075832,
                40.85158742972982
              ],
              [
                -73.79227187668211,
                40.85161064825809
              ],
              [
                -73.79172750188745,
                40.85176465395915
              ],
              [
                -73.79176994883001,
                40.85187053095137
              ],
              [
                -73.79149596814213,
                40.85193407242131
              ],
              [
                -73.79142679645518,
                40.85177617591717
              ],
              [
                -73.79152343356944,
                40.851750364292585
              ],
              [
                -73.79151738677945,
                40.85172993589084
              ],
              [
                -73.7913204511751,
                40.85177969609417
              ],
              [
                -73.79132894174195,
                40.85180012825631
              ],
              [
                -73.79139621768194,
                40.8517835456201
              ],
              [
                -73.79146293338718,
                40.85194515019928
              ],
              [
                -73.79118770764545,
                40.85201611250672
              ],
              [
                -73.7911669291687,
                40.85201607489478
              ],
              [
                -73.79110265321681,
                40.85185633106272
              ],
              [
                -73.79118338365927,
                40.851836059914085
              ],
              [
                -73.79117366731116,
                40.8518165532694
              ],
              [
                -73.79101097118439,
                40.85186080525286
              ],
              [
                -73.79102069040403,
                40.85187938483922
              ],
              [
                -73.79106594789536,
                40.851868329827596
              ],
              [
                -73.79111196150562,
                40.85200576731114
              ],
              [
                -73.79098719999038,
                40.85203523963262
              ],
              [
                -73.79099692237003,
                40.852052889803645
              ],
              [
                -73.79085380717721,
                40.85208882496841
              ],
              [
                -73.79079446716632,
                40.85191424043079
              ],
              [
                -73.79076267521064,
                40.851918823519114
              ],
              [
                -73.79081896780879,
                40.851896652302045
              ],
              [
                -73.79079463354844,
                40.85186134138582
              ],
              [
                -73.79056092524321,
                40.85194444389138
              ],
              [
                -73.79058037559301,
                40.85197788999398
              ],
              [
                -73.79074309499363,
                40.85192621249439
              ],
              [
                -73.79080610587764,
                40.85209987596775
              ],
              [
                -73.79057493410312,
                40.85215328413443
              ],
              [
                -73.7905070423804,
                40.851977756847845
              ],
              [
                -73.79045200336085,
                40.85198972095023
              ],
              [
                -73.79053212420823,
                40.852163414645126
              ],
              [
                -73.79027404235792,
                40.85222327109259
              ],
              [
                -73.79019520313294,
                40.852031017738085
              ],
              [
                -73.79016095149859,
                40.85204023643091
              ],
              [
                -73.79024346325298,
                40.85223063998675
              ],
              [
                -73.79015509260995,
                40.85225128523581
              ],
              [
                -73.79008396904106,
                40.85203452738219
              ],
              [
                -73.79025566394581,
                40.851976064929715
              ],
              [
                -73.79029928958964,
                40.85196902614942
              ],
              [
                -73.79027873037282,
                40.851899383948776
              ],
              [
                -73.79030685108064,
                40.85189665031758
              ],
              [
                -73.7902948451226,
                40.851827951412965
              ],
              [
                -73.79026673300343,
                40.85182790030749
              ],
              [
                -73.79026555282694,
                40.85181490584795
              ],
              [
                -73.79038660320028,
                40.85179934812665
              ],
              [
                -73.79038421946557,
                40.85177985423557
              ],
              [
                -73.790463705281,
                40.85176700615399
              ],
              [
                -73.7904552874152,
                40.85172337250564
              ],
              [
                -73.79023273612084,
                40.8517563771663
              ],
              [
                -73.79024838262285,
                40.85183343543782
              ],
              [
                -73.79021640272948,
                40.85183982678924
              ],
              [
                -73.79019588667279,
                40.85175290456291
              ],
              [
                -73.79091633421972,
                40.85163882596733
              ],
              [
                -73.79089217930074,
                40.85154690354887
              ],
              [
                -73.79017310700394,
                40.851662579154905
              ],
              [
                -73.79014778434487,
                40.85160740718401
              ],
              [
                -73.78972573712088,
                40.85169267056499
              ],
              [
                -73.78966490344513,
                40.85150038213208
              ],
              [
                -73.79005162420025,
                40.85143957700102
              ],
              [
                -73.790073380741,
                40.85151664642066
              ],
              [
                -73.79011618216886,
                40.851509299305945
              ],
              [
                -73.79009442491898,
                40.85143222989302
              ],
              [
                -73.79035488894863,
                40.85139094116019
              ],
              [
                -73.79038756834424,
                40.85149308842482
              ],
              [
                -73.7904108048791,
                40.851488489896646
              ],
              [
                -73.79038545954286,
                40.85138635646757
              ],
              [
                -73.79083302265286,
                40.85131385099807
              ],
              [
                -73.7908596129088,
                40.85140856209492
              ],
              [
                -73.79089018435498,
                40.8514039784428
              ],
              [
                -73.7908660390277,
                40.85130834236286
              ],
              [
                -73.79099076649098,
                40.85128907934996
              ],
              [
                -73.79098717984283,
                40.85126401529905
              ],
              [
                -73.79085756325748,
                40.85128326944466
              ],
              [
                -73.79081765352956,
                40.851148627155794
              ],
              [
                -73.79078463524452,
                40.85115413577379
              ],
              [
                -73.79082454198961,
                40.851289706318404
              ],
              [
                -73.79037943653302,
                40.851358503723944
              ],
              [
                -73.79033709518069,
                40.85122014385105
              ],
              [
                -73.79030285187707,
                40.851226578857236
              ],
              [
                -73.7903451879796,
                40.85136679305499
              ],
              [
                -73.79008961569983,
                40.85140716358974
              ],
              [
                -73.79004975343726,
                40.851257672809496
              ],
              [
                -73.79001797053547,
                40.851259470959505
              ],
              [
                -73.7900578142692,
                40.85141453021116
              ],
              [
                -73.7898108047847,
                40.851452131966944
              ],
              [
                -73.78979509883112,
                40.8513945632504
              ],
              [
                -73.78980855115411,
                40.85139180351458
              ],
              [
                -73.78979525427958,
                40.851345375002296
              ],
              [
                -73.78977935323186,
                40.85134905852415
              ],
              [
                -73.78976843228764,
                40.851323981069484
              ],
              [
                -73.78966571913696,
                40.85133864383001
              ],
              [
                -73.78968379674485,
                40.851419417941834
              ],
              [
                -73.78970213099691,
                40.851419451365594
              ],
              [
                -73.78971059211985,
                40.85144916458635
              ],
              [
                -73.78977663032417,
                40.85143721970859
              ],
              [
                -73.78978510702639,
                40.8514622938807
              ],
              [
                -73.78966276217217,
                40.851488703320925
              ],
              [
                -73.78966051794421,
                40.85147686132127
              ],
              [
                -73.78956272464195,
                40.851487336822075
              ],
              [
                -73.78951371516173,
                40.851300771713085
              ],
              [
                -73.79012439171836,
                40.851189912194556
              ],
              [
                -73.79009094385734,
                40.85099476440282
              ],
              [
                -73.79026824507254,
                40.85096108807381
              ],
              [
                -73.7902646047139,
                40.8509527298865
              ],
              [
                -73.79008640021898,
                40.85098447385347
              ],
              [
                -73.79007405920494,
                40.85093971531255
              ],
              [
                -73.79025006400954,
                40.8509127959065
              ],
              [
                -73.79024764837524,
                40.850903510522876
              ],
              [
                -73.79007093170758,
                40.85093202010673
              ],
              [
                -73.79003174223271,
                40.85076854781391
              ],
              [
                -73.78992953626673,
                40.85062612255505
              ],
              [
                -73.79102381130417,
                40.85043227542412
              ],
              [
                -73.79101737069524,
                40.85040751477936
              ],
              [
                -73.78991872482864,
                40.850611727137725
              ],
              [
                -73.79003888486419,
                40.850451288623965
              ],
              [
                -73.7900241182947,
                40.85034781958286
              ],
              [
                -73.79011588587773,
                40.8503332831734
              ],
              [
                -73.7901244688738,
                40.85036794642022
              ],
              [
                -73.79005271916627,
                40.85038266468011
              ],
              [
                -73.7900548453779,
                40.850397518036985
              ],
              [
                -73.79022659043213,
                40.85036813216589
              ],
              [
                -73.79022013429515,
                40.85034832110921
              ],
              [
                -73.79015490797059,
                40.85036140235727
              ],
              [
                -73.79014198665578,
                40.850325080607625
              ],
              [
                -73.79040506054308,
                40.85027276271398
              ],
              [
                -73.79035766981725,
                40.85014233461593
              ],
              [
                -73.79025767031703,
                40.85015865126975
              ],
              [
                -73.79028137292694,
                40.850221390189404
              ],
              [
                -73.790237870093,
                40.8502361610853
              ],
              [
                -73.79024642580437,
                40.850279073151505
              ],
              [
                -73.79020729510404,
                40.850285601432425
              ],
              [
                -73.7902008337725,
                40.85026744164815
              ],
              [
                -73.79016816439426,
                40.85029213020271
              ],
              [
                -73.79001758788853,
                40.85031633246506
              ],
              [
                -73.78986630313652,
                40.84998939070317
              ],
              [
                -73.79016710514148,
                40.849939050166554
              ],
              [
                -73.7901714089767,
                40.84995225687013
              ],
              [
                -73.79024098424524,
                40.849937534414885
              ],
              [
                -73.79023451253238,
                40.8499226743313
              ],
              [
                -73.79036275955572,
                40.8499064078614
              ],
              [
                -73.79036705822561,
                40.84992126516155
              ],
              [
                -73.79044531982218,
                40.84990820797589
              ],
              [
                -73.79044102699248,
                40.84989170175117
              ],
              [
                -73.79066056595522,
                40.84986405098366
              ],
              [
                -73.79067569819729,
                40.84988882759024
              ],
              [
                -73.79098877124652,
                40.84982834953222
              ],
              [
                -73.7909477685762,
                40.84973917964379
              ],
              [
                -73.79091297721088,
                40.84974736613829
              ],
              [
                -73.79092590534461,
                40.84978203770634
              ],
              [
                -73.79064762821827,
                40.849832679590186
              ],
              [
                -73.79064976493049,
                40.849844233237555
              ],
              [
                -73.79022586388017,
                40.84990945922681
              ],
              [
                -73.79022591071276,
                40.8498946093208
              ],
              [
                -73.79015633936457,
                40.84990768266867
              ],
              [
                -73.79016063798868,
                40.84992253997644
              ],
              [
                -73.78985836952175,
                40.84997255936187
              ],
              [
                -73.78983383329343,
                40.84985897364481
              ],
              [
                -73.7897571107061,
                40.84953146928762
              ],
              [
                -73.79024725580997,
                40.849178846989886
              ],
              [
                -73.79088710207049,
                40.84888656430167
              ],
              [
                -73.79098057835537,
                40.848999999169436
              ],
              [
                -73.7910136233899,
                40.84898520971691
              ],
              [
                -73.79091971727904,
                40.848866873015886
              ],
              [
                -73.79101783522134,
                40.848811669064396
              ],
              [
                -73.79104432321175,
                40.84874079644996
              ],
              [
                -73.7910972646854,
                40.84881552492793
              ],
              [
                -73.79111195407879,
                40.84880812703416
              ],
              [
                -73.79106000608506,
                40.84872508882858
              ],
              [
                -73.7912447526424,
                40.84855222044716
              ],
              [
                -73.79123086312458,
                40.84850103935591
              ],
              [
                -73.79131020699877,
                40.84840049699907
              ],
              [
                -73.79136288106794,
                40.84823230239035
              ],
              [
                -73.79133588063237,
                40.84808623865242
              ],
              [
                -73.79161765431445,
                40.84805292440785
              ],
              [
                -73.79160687331743,
                40.848026505666404
              ],
              [
                -73.79132975970043,
                40.848057923064225
              ],
              [
                -73.79127932953888,
                40.84766309155011
              ],
              [
                -73.79127720066779,
                40.8476464250921
              ],
              [
                -73.79126708567368,
                40.847601867354044
              ],
              [
                -73.79130570048282,
                40.847592867471676
              ],
              [
                -73.79154104882261,
                40.84753801729828
              ],
              [
                -73.79151739185973,
                40.847460429196815
              ],
              [
                -73.79141738478454,
                40.84748004656211
              ],
              [
                -73.79142962756488,
                40.84735998917436
              ],
              [
                -73.79171562526108,
                40.847295797102596
              ],
              [
                -73.79171569758651,
                40.84727269885691
              ],
              [
                -73.79174613599426,
                40.84726615386965
              ],
              [
                -73.79191997810095,
                40.84725821803076
              ],
              [
                -73.79192007100076,
                40.8472285198791
              ],
              [
                -73.79177232700852,
                40.84722825327917
              ],
              [
                -73.79169408366229,
                40.84723636206888
              ],
              [
                -73.79169837795163,
                40.84725286942954
              ],
              [
                -73.79144049688328,
                40.84730604348359
              ],
              [
                -73.79148637416937,
                40.84695880326024
              ],
              [
                -73.79138428282982,
                40.846950369625105
              ],
              [
                -73.79137658530531,
                40.846927821044446
              ],
              [
                -73.79136709858446,
                40.84688764268581
              ],
              [
                -73.79143363007182,
                40.846864797968834
              ],
              [
                -73.79180418591311,
                40.84676798999679
              ],
              [
                -73.79179343625451,
                40.8467316723293
              ],
              [
                -73.7914803472568,
                40.846802052713656
              ],
              [
                -73.79141982667437,
                40.84670129956033
              ],
              [
                -73.79138070403268,
                40.84670617747227
              ],
              [
                -73.79132451946978,
                40.846608733001716
              ],
              [
                -73.7914310833159,
                40.84657592768415
              ],
              [
                -73.79141379114441,
                40.846547847032824
              ],
              [
                -73.79132901030732,
                40.8465625431943
              ],
              [
                -73.79131170561901,
                40.84653776390074
              ],
              [
                -73.79126057441356,
                40.846549170067966
              ],
              [
                -73.79118128785345,
                40.84655567592982
              ],
              [
                -73.79112476570171,
                40.84642747233159
              ],
              [
                -73.79114478824971,
                40.846417019403795
              ],
              [
                -73.79112759246645,
                40.84635759094952
              ],
              [
                -73.79149937351545,
                40.84627741912919
              ],
              [
                -73.79149076513552,
                40.84625100496525
              ],
              [
                -73.7910642712526,
                40.84634607040145
              ],
              [
                -73.79106349089012,
                40.84634616665451
              ],
              [
                -73.79097333310666,
                40.84635731152497
              ],
              [
                -73.79094673676796,
                40.84630998184841
              ],
              [
                -73.790940734436,
                40.84630082064029
              ],
              [
                -73.79090848534602,
                40.8462516010898
              ],
              [
                -73.79096069596163,
                40.846230246274516
              ],
              [
                -73.79069785364858,
                40.845786012550846
              ],
              [
                -73.79101885992935,
                40.84569743474459
              ],
              [
                -73.79098858375902,
                40.84565283206256
              ],
              [
                -73.79085156066733,
                40.84569878164127
              ],
              [
                -73.79066589001,
                40.845741558834405
              ],
              [
                -73.7904964276791,
                40.84549275521502
              ],
              [
                -73.79019920190943,
                40.8451877778843
              ],
              [
                -73.79001969152111,
                40.844928417157284
              ],
              [
                -73.78992895494343,
                40.844924237568044
              ],
              [
                -73.78990804558647,
                40.844897610903416
              ],
              [
                -73.78959737629594,
                40.84450197311116
              ],
              [
                -73.78947182214094,
                40.84429297835204
              ],
              [
                -73.78947033356705,
                40.844289624818295
              ],
              [
                -73.78934690531923,
                40.844011496597254
              ],
              [
                -73.7893404709631,
                40.843985085058044
              ],
              [
                -73.7893470202629,
                40.84397519852208
              ],
              [
                -73.78946443345122,
                40.843945715050005
              ],
              [
                -73.78942132625365,
                40.84383674251027
              ],
              [
                -73.78936047257154,
                40.84384323084519
              ],
              [
                -73.78933021337059,
                40.84379367790621
              ],
              [
                -73.78931949144742,
                40.84374911114966
              ],
              [
                -73.78933708725715,
                40.84368149805549
              ],
              [
                -73.78974084707778,
                40.84359957047396
              ],
              [
                -73.78980449502046,
                40.84358665628239
              ],
              [
                -73.78977421223743,
                40.84351357465307
              ],
              [
                -73.78975626839882,
                40.843470340661796
              ],
              [
                -73.78990645143352,
                40.84344221745637
              ],
              [
                -73.78989919889905,
                40.84341714665902
              ],
              [
                -73.78974579972716,
                40.84344782258868
              ],
              [
                -73.78973469326743,
                40.843413247478686
              ],
              [
                -73.78988953603827,
                40.84338093446732
              ],
              [
                -73.78986183858275,
                40.84325095414663
              ],
              [
                -73.7899315290254,
                40.843240872501696
              ],
              [
                -73.78995207552958,
                40.84331329893433
              ],
              [
                -73.78999975309044,
                40.843307817243556
              ],
              [
                -73.78996596076009,
                40.8431731867722
              ],
              [
                -73.78995006820057,
                40.84317501332979
              ],
              [
                -73.78994406956207,
                40.84313973556226
              ],
              [
                -73.78995751500784,
                40.84313883280074
              ],
              [
                -73.78993093388229,
                40.84304226436365
              ],
              [
                -73.78988202767482,
                40.843049600290136
              ],
              [
                -73.78990617379496,
                40.8431433790472
              ],
              [
                -73.78991962151179,
                40.8431415480429
              ],
              [
                -73.78992928728755,
                40.84317683131769
              ],
              [
                -73.78991705673337,
                40.843179593293684
              ],
              [
                -73.7899242492441,
                40.843224154240694
              ],
              [
                -73.78985456556266,
                40.84323238006143
              ],
              [
                -73.78983522236199,
                40.84316552413694
              ],
              [
                -73.78977897832563,
                40.84317470164447
              ],
              [
                -73.78978620461135,
                40.84320812616074
              ],
              [
                -73.78965755656859,
                40.84323135456168
              ],
              [
                -73.78961660328446,
                40.84311265436494
              ],
              [
                -73.78905825501361,
                40.84323533177004
              ],
              [
                -73.78889086172579,
                40.843070064349575
              ],
              [
                -73.78884308883718,
                40.84300024632511
              ],
              [
                -73.78876233407976,
                40.842751501355096
              ],
              [
                -73.7887905351074,
                40.84272185515486
              ],
              [
                -73.78876384724369,
                40.84265962584494
              ],
              [
                -73.78871619613662,
                40.84265675331416
              ],
              [
                -73.7885278086737,
                40.8424255768948
              ],
              [
                -73.78884273257208,
                40.84236345457356
              ],
              [
                -73.78883864895133,
                40.842350395961134
              ],
              [
                -73.78852027082415,
                40.842411765450514
              ],
              [
                -73.78850993801106,
                40.8423910353269
              ],
              [
                -73.78843909037009,
                40.842402498578
              ],
              [
                -73.78838456613674,
                40.84238197837061
              ],
              [
                -73.78823199262384,
                40.8421960847721
              ],
              [
                -73.78805599070779,
                40.84186703017751
              ],
              [
                -73.78830167638179,
                40.84180394882175
              ],
              [
                -73.78829358843848,
                40.841785373136645
              ],
              [
                -73.7880484708972,
                40.84185072312647
              ],
              [
                -73.78802003550352,
                40.84178384145876
              ],
              [
                -73.78805250551622,
                40.841763893212736
              ],
              [
                -73.78811941308066,
                40.841731843119284
              ],
              [
                -73.78816020303344,
                40.84171459453315
              ],
              [
                -73.78820427907819,
                40.8416886896265
              ],
              [
                -73.78823370218421,
                40.84165904580118
              ],
              [
                -73.78825337746484,
                40.84162072123125
              ],
              [
                -73.78826000546675,
                40.84158608571319
              ],
              [
                -73.78826006848325,
                40.84156628715141
              ],
              [
                -73.7882606446571,
                40.84154196286083
              ],
              [
                -73.78837101202566,
                40.84152070556353
              ],
              [
                -73.78836617474825,
                40.84150461101653
              ],
              [
                -73.78825197587601,
                40.84152874537164
              ],
              [
                -73.7882390823508,
                40.84150437775736
              ],
              [
                -73.78819680117678,
                40.841478313104496
              ],
              [
                -73.78812391608882,
                40.84146252561776
              ],
              [
                -73.78812190151476,
                40.841462089369735
              ],
              [
                -73.78804369145313,
                40.841461945677665
              ],
              [
                -73.78795239075858,
                40.841479101375796
              ],
              [
                -73.78789698683862,
                40.841480236842195
              ],
              [
                -73.78784487499479,
                40.841471479333244
              ],
              [
                -73.78780098073766,
                40.841440463908526
              ],
              [
                -73.78761237782592,
                40.84119800529587
              ],
              [
                -73.78760640589596,
                40.84114188575643
              ],
              [
                -73.78786404049687,
                40.84108172583491
              ],
              [
                -73.78778656795106,
                40.840892222012805
              ],
              [
                -73.78757937523613,
                40.840932710498684
              ],
              [
                -73.78752379733702,
                40.84087961218516
              ],
              [
                -73.78778496347275,
                40.84084275703797
              ],
              [
                -73.78777526956972,
                40.84081675335057
              ],
              [
                -73.78795786196089,
                40.84078491614255
              ],
              [
                -73.78796596546518,
                40.840798542045825
              ],
              [
                -73.78825453283457,
                40.840745863118485
              ],
              [
                -73.78824000945202,
                40.84070128975995
              ],
              [
                -73.78820578964854,
                40.84070246476651
              ],
              [
                -73.78818800455072,
                40.84065912157286
              ],
              [
                -73.78821083495573,
                40.84065297632424
              ],
              [
                -73.78815921166512,
                40.84049077856704
              ],
              [
                -73.78809400249324,
                40.84050179629599
              ],
              [
                -73.78813910778516,
                40.840663981607676
              ],
              [
                -73.78816355627822,
                40.840661551844406
              ],
              [
                -73.78818132144568,
                40.84071108050136
              ],
              [
                -73.78792536909036,
                40.84075515855147
              ],
              [
                -73.78791889178504,
                40.84074277179642
              ],
              [
                -73.78771346922072,
                40.840780754097906
              ],
              [
                -73.78771665246705,
                40.84080427063831
              ],
              [
                -73.78749166412584,
                40.84084234167407
              ],
              [
                -73.78738672135641,
                40.8406519257166
              ],
              [
                -73.7873572424447,
                40.84059077177667
              ],
              [
                -73.78739774725157,
                40.840582316947966
              ],
              [
                -73.78763931218995,
                40.8405318952366
              ],
              [
                -73.78747062422686,
                40.84012220342881
              ],
              [
                -73.78745014326732,
                40.84007246158648
              ],
              [
                -73.78748134063115,
                40.840054545565664
              ],
              [
                -73.78749095280199,
                40.8400490260975
              ],
              [
                -73.78749162161397,
                40.839989639321495
              ],
              [
                -73.7874710857183,
                40.83994515630109
              ],
              [
                -73.78738854482546,
                40.839766356005946
              ],
              [
                -73.78733627876298,
                40.839710022100135
              ],
              [
                -73.78741374512593,
                40.83968663880645
              ],
              [
                -73.7874075874599,
                40.83967762319697
              ],
              [
                -73.78732985698998,
                40.839703100722524
              ],
              [
                -73.78730353132528,
                40.839674724501016
              ],
              [
                -73.78690015821412,
                40.83971486189476
              ],
              [
                -73.78652598496416,
                40.83972311560703
              ],
              [
                -73.78627272274821,
                40.83958830856518
              ],
              [
                -73.78614637767016,
                40.83958736625306
              ],
              [
                -73.78607898490303,
                40.83953813339062
              ],
              [
                -73.78592998544981,
                40.83942928196881
              ],
              [
                -73.7857350929868,
                40.83923423177581
              ],
              [
                -73.78568113678001,
                40.839101104369114
              ],
              [
                -73.78556031159738,
                40.838865979783584
              ],
              [
                -73.78553011511531,
                40.83879827733832
              ],
              [
                -73.78554772810212,
                40.83872571394742
              ],
              [
                -73.7854986418295,
                40.83848036679504
              ],
              [
                -73.78557796850949,
                40.83792711322539
              ],
              [
                -73.7852771294841,
                40.83775671808692
              ],
              [
                -73.78516584428648,
                40.83747133406176
              ],
              [
                -73.78565279572325,
                40.837132104726045
              ],
              [
                -73.78579367439913,
                40.8372330098709
              ],
              [
                -73.78583067964128,
                40.83720998000453
              ],
              [
                -73.78563997845447,
                40.837064434585386
              ],
              [
                -73.78512582175478,
                40.837446041569414
              ],
              [
                -73.78485009222642,
                40.83747048917846
              ],
              [
                -73.7845033396534,
                40.83705776517111
              ],
              [
                -73.78494325733283,
                40.836875049143615
              ],
              [
                -73.78498435600915,
                40.83692957218047
              ],
              [
                -73.78508226642613,
                40.83688190821302
              ],
              [
                -73.78498060328094,
                40.83674642608051
              ],
              [
                -73.78488487052006,
                40.83679244441208
              ],
              [
                -73.78491512256413,
                40.83684199857525
              ],
              [
                -73.78448456140005,
                40.837024222538496
              ],
              [
                -73.78431347112955,
                40.8368128267512
              ],
              [
                -73.78421807493936,
                40.8367549021738
              ],
              [
                -73.78410164591317,
                40.836725529358
              ],
              [
                -73.78366393608437,
                40.83681161024659
              ],
              [
                -73.7833816805932,
                40.83690927168069
              ],
              [
                -73.7830292186274,
                40.836345852344756
              ],
              [
                -73.78282260139727,
                40.836338155887844
              ],
              [
                -73.78268131435576,
                40.836483677153495
              ],
              [
                -73.78270364583639,
                40.83684280196558
              ],
              [
                -73.78279680443434,
                40.83692052345197
              ],
              [
                -73.7828873348317,
                40.837164735646695
              ],
              [
                -73.78290629190879,
                40.837320006072815
              ],
              [
                -73.78281452652847,
                40.837422391366374
              ],
              [
                -73.78274559046729,
                40.83747439359701
              ],
              [
                -73.78272760738487,
                40.83748796035137
              ],
              [
                -73.78238142556087,
                40.837324106167365
              ],
              [
                -73.7818845572027,
                40.83765027660479
              ],
              [
                -73.78200420989037,
                40.838015572307114
              ],
              [
                -73.78172154866671,
                40.83822356610978
              ],
              [
                -73.78159698419161,
                40.838477850142965
              ],
              [
                -73.78156780983515,
                40.83853740573626
              ],
              [
                -73.7813424000093,
                40.839238374844435
              ],
              [
                -73.78132522577029,
                40.839291781356586
              ],
              [
                -73.78133988849423,
                40.839469699830296
              ],
              [
                -73.78113875725964,
                40.83949427880173
              ],
              [
                -73.78113994352726,
                40.83950541689356
              ],
              [
                -73.78134125145772,
                40.8394785634429
              ],
              [
                -73.78135932010679,
                40.83971170821573
              ],
              [
                -73.78117947802396,
                40.8397458622558
              ],
              [
                -73.78118431444939,
                40.83976164869877
              ],
              [
                -73.78136267245321,
                40.83973018981634
              ],
              [
                -73.78145366531946,
                40.84013628677303
              ],
              [
                -73.78146559787315,
                40.84017515976338
              ],
              [
                -73.78148878089243,
                40.84019732392209
              ],
              [
                -73.78124907233904,
                40.84024435480524
              ],
              [
                -73.78125361834401,
                40.84025550094137
              ],
              [
                -73.78149172006931,
                40.84020622452522
              ],
              [
                -73.78151504322612,
                40.84028761446136
              ],
              [
                -73.78153441046068,
                40.84034374764454
              ],
              [
                -73.78154296060534,
                40.840386661604406
              ],
              [
                -73.7815428414098,
                40.840422959694166
              ],
              [
                -73.78152321749889,
                40.840444370318686
              ],
              [
                -73.78151347737062,
                40.840451271290306
              ],
              [
                -73.78148837696587,
                40.840469052843794
              ],
              [
                -73.78146442574841,
                40.84048550709676
              ],
              [
                -73.78145349846586,
                40.840505284574995
              ],
              [
                -73.78145342800167,
                40.84052673391018
              ],
              [
                -73.78146203707614,
                40.84055149874544
              ],
              [
                -73.78147718457494,
                40.84056967551249
              ],
              [
                -73.78148577066192,
                40.84060104025461
              ],
              [
                -73.78148570562806,
                40.84062083948604
              ],
              [
                -73.78147694069271,
                40.840643921290045
              ],
              [
                -73.7814660242275,
                40.84066039906467
              ],
              [
                -73.78144207161212,
                40.84067685330987
              ],
              [
                -73.78142028765106,
                40.84069496177369
              ],
              [
                -73.78141805071888,
                40.840714755716135
              ],
              [
                -73.78142666976504,
                40.84073622034478
              ],
              [
                -73.78144833514813,
                40.84075441114133
              ],
              [
                -73.7814808892744,
                40.840764371324674
              ],
              [
                -73.78151345014929,
                40.8407726825704
              ],
              [
                -73.78154308949141,
                40.84078478253003
              ],
              [
                -73.7815682170087,
                40.84083516109827
              ],
              [
                -73.78159396937245,
                40.84093979674803
              ],
              [
                -73.78137488194947,
                40.84097887100505
              ],
              [
                -73.78137871885639,
                40.8409871920024
              ],
              [
                -73.78159702603972,
                40.840948669593274
              ],
              [
                -73.78161069651507,
                40.84099624002564
              ],
              [
                -73.78139143568951,
                40.84103660961823
              ],
              [
                -73.78139590619041,
                40.84104786583495
              ],
              [
                -73.7816135654381,
                40.841007272069724
              ],
              [
                -73.7816485529559,
                40.84109776352881
              ],
              [
                -73.78079752923469,
                40.841256146048444
              ],
              [
                -73.78089220610157,
                40.84155308419829
              ],
              [
                -73.78092886504994,
                40.84154542526757
              ],
              [
                -73.78084223802306,
                40.84127632402818
              ],
              [
                -73.78166051213424,
                40.84112417558819
              ],
              [
                -73.78168516996307,
                40.84116663855671
              ],
              [
                -73.7817176560301,
                40.84118988354887
              ],
              [
                -73.78176234410533,
                40.84121624495401
              ],
              [
                -73.7818048644,
                40.841282785939946
              ],
              [
                -73.78182300321745,
                40.841336917003865
              ],
              [
                -73.78183909216312,
                40.84139567991729
              ],
              [
                -73.78183884886525,
                40.84146986973238
              ],
              [
                -73.78183863598309,
                40.8415347846261
              ],
              [
                -73.78185048197766,
                40.841653946394324
              ],
              [
                -73.78123754712247,
                40.841768242042036
              ],
              [
                -73.78126135401865,
                40.84183957845701
              ],
              [
                -73.78037675812158,
                40.84208307154647
              ],
              [
                -73.78037786810292,
                40.84209350522445
              ],
              [
                -73.78034466049567,
                40.8420960516831
              ],
              [
                -73.78033227924816,
                40.84203169026738
              ],
              [
                -73.78019599955293,
                40.84204621097616
              ],
              [
                -73.78029863976153,
                40.84251154046733
              ],
              [
                -73.7804257718152,
                40.84249439420688
              ],
              [
                -73.78034805158231,
                40.84210909822445
              ],
              [
                -73.78036980593723,
                40.8421082707011
              ],
              [
                -73.78037662608997,
                40.842123064012384
              ],
              [
                -73.78039480479654,
                40.84216482923703
              ],
              [
                -73.78045552576683,
                40.84215103462286
              ],
              [
                -73.78043963564978,
                40.84210927259141
              ],
              [
                -73.78050381144551,
                40.84208939837382
              ],
              [
                -73.78056292706685,
                40.842214706208765
              ],
              [
                -73.7805915762219,
                40.84220606653752
              ],
              [
                -73.7805313141506,
                40.8420807560333
              ],
              [
                -73.78058402738796,
                40.842065207046154
              ],
              [
                -73.78060581105882,
                40.84205568586572
              ],
              [
                -73.78065508284249,
                40.84204186886624
              ],
              [
                -73.78072098830444,
                40.842191532305804
              ],
              [
                -73.78076452835761,
                40.84218031288249
              ],
              [
                -73.78069632802139,
                40.842032383000685
              ],
              [
                -73.7808143539402,
                40.841998700777545
              ],
              [
                -73.78088717448651,
                40.84213446705866
              ],
              [
                -73.78092499038787,
                40.842123236198375
              ],
              [
                -73.78084643996941,
                40.841989198625804
              ],
              [
                -73.7809552932145,
                40.84195984541313
              ],
              [
                -73.78102354424689,
                40.842092995416685
              ],
              [
                -73.78105448455362,
                40.842083489356874
              ],
              [
                -73.78097935660904,
                40.84195293533549
              ],
              [
                -73.78109967064516,
                40.84192012611332
              ],
              [
                -73.78117137190374,
                40.842048065538975
              ],
              [
                -73.781197722389,
                40.842042029373
              ],
              [
                -73.78113290101273,
                40.841910626053625
              ],
              [
                -73.78124863502482,
                40.84187780798532
              ],
              [
                -73.78130775340559,
                40.84200311544593
              ],
              [
                -73.78133868571547,
                40.841996218484205
              ],
              [
                -73.7812749882927,
                40.841870902858844
              ],
              [
                -73.78132082303267,
                40.84185794850812
              ],
              [
                -73.7812992811557,
                40.841794441623534
              ],
              [
                -73.78135197332533,
                40.841784977227135
              ],
              [
                -73.7813667641349,
                40.84181282684591
              ],
              [
                -73.7814183019977,
                40.84180596942078
              ],
              [
                -73.78140696595466,
                40.84177204020149
              ],
              [
                -73.78186601221255,
                40.84168736467965
              ],
              [
                -73.78188333127875,
                40.84173381939348
              ],
              [
                -73.78202093113624,
                40.841704333914905
              ],
              [
                -73.78207235608491,
                40.84169331384538
              ],
              [
                -73.78216624418963,
                40.84168914411004
              ],
              [
                -73.78221542233346,
                40.841704017300245
              ],
              [
                -73.78223592679213,
                40.84172788348316
              ],
              [
                -73.78205377835471,
                40.84177326417951
              ],
              [
                -73.78205150835818,
                40.84176717490872
              ],
              [
                -73.78203546964845,
                40.84177062156676
              ],
              [
                -73.78203658908592,
                40.84177844890507
              ],
              [
                -73.78201252301916,
                40.8417862274759
              ],
              [
                -73.78201593435602,
                40.841793189025154
              ],
              [
                -73.78224024540252,
                40.8417358661234
              ],
              [
                -73.78226345326249,
                40.84177811598246
              ],
              [
                -73.78203410829995,
                40.841836694224455
              ],
              [
                -73.78203752533935,
                40.84184191671499
              ],
              [
                -73.78206158354132,
                40.84183674614081
              ],
              [
                -73.78206499291741,
                40.84184457780548
              ],
              [
                -73.78208103076508,
                40.841841129969175
              ],
              [
                -73.78207533518163,
                40.84183242503752
              ],
              [
                -73.78226771471576,
                40.84178617540728
              ],
              [
                -73.7823235128594,
                40.841898330454555
              ],
              [
                -73.78144003107849,
                40.842142133493404
              ],
              [
                -73.78145418008431,
                40.84217152712605
              ],
              [
                -73.78226695851775,
                40.841950494612384
              ],
              [
                -73.78227701311965,
                40.84198760897666
              ],
              [
                -73.78231163148791,
                40.84198149217099
              ],
              [
                -73.78229348197961,
                40.84193045282991
              ],
              [
                -73.78233257215871,
                40.84192284411137
              ],
              [
                -73.78237057744752,
                40.84200478690896
              ],
              [
                -73.78237240991096,
                40.84206661567217
              ],
              [
                -73.78233160993909,
                40.842095905508884
              ],
              [
                -73.78232536834764,
                40.84213762434106
              ],
              [
                -73.78232118543183,
                40.8421716201721
              ],
              [
                -73.78230643317583,
                40.84221111631256
              ],
              [
                -73.78224979212551,
                40.84222094525863
              ],
              [
                -73.78224574731087,
                40.84221320956453
              ],
              [
                -73.78162055021906,
                40.84232949488804
              ],
              [
                -73.7816286101587,
                40.84235424006779
              ],
              [
                -73.78215604531863,
                40.8422594083687
              ],
              [
                -73.78216005558019,
                40.84227796304772
              ],
              [
                -73.78222115152285,
                40.84226571462884
              ],
              [
                -73.78221716280949,
                40.84224097717735
              ],
              [
                -73.78231110910542,
                40.84222417895716
              ],
              [
                -73.78233051660672,
                40.84232245176547
              ],
              [
                -73.78215166139216,
                40.84235522911647
              ],
              [
                -73.78215975190268,
                40.8423706998425
              ],
              [
                -73.78234000558811,
                40.84234092564361
              ],
              [
                -73.78236855953504,
                40.84242849314966
              ],
              [
                -73.78058525374092,
                40.842754119441445
              ],
              [
                -73.78059735822595,
                40.84278659994098
              ],
              [
                -73.7824108248818,
                40.84245005032449
              ],
              [
                -73.7824348109359,
                40.84246612143507
              ],
              [
                -73.78229572698898,
                40.84248842384861
              ],
              [
                -73.78230783665784,
                40.842519358265335
              ],
              [
                -73.78271552022753,
                40.84244650059044
              ],
              [
                -73.78286499304913,
                40.8424781018827
              ],
              [
                -73.78029152196487,
                40.84295294475395
              ],
              [
                -73.78029175678296,
                40.842881846600356
              ],
              [
                -73.78022662418076,
                40.842883268935495
              ],
              [
                -73.78022773872677,
                40.84377818129771
              ],
              [
                -73.78029286577419,
                40.843778305365376
              ],
              [
                -73.78029552532065,
                40.84297304489391
              ],
              [
                -73.78064375993134,
                40.84291033812348
              ],
              [
                -73.7808290368131,
                40.843507297145635
              ],
              [
                -73.78085958503786,
                40.843501171869235
              ],
              [
                -73.78067429925017,
                40.84290730453772
              ],
              [
                -73.78121601119268,
                40.842804777395635
              ],
              [
                -73.78138916319325,
                40.84337698260531
              ],
              [
                -73.78142785188437,
                40.84337087428158
              ],
              [
                -73.78124656634495,
                40.84279710728944
              ],
              [
                -73.78177196927737,
                40.84270227349191
              ],
              [
                -73.7819330659365,
                40.84322808807652
              ],
              [
                -73.78196972502653,
                40.843220428815926
              ],
              [
                -73.78180048362434,
                40.84269614579364
              ],
              [
                -73.78226682874738,
                40.842612018228266
              ],
              [
                -73.78240786884527,
                40.84304814691726
              ],
              [
                -73.78244452404584,
                40.84304203441675
              ],
              [
                -73.78229941270918,
                40.84260589641337
              ],
              [
                -73.78270669982564,
                40.842532475810316
              ],
              [
                -73.78277919046205,
                40.842770636891174
              ],
              [
                -73.78281584526042,
                40.842764522596624
              ],
              [
                -73.78273319376787,
                40.84252170549844
              ],
              [
                -73.78288778301486,
                40.84249482884962
              ],
              [
                -73.78301653344668,
                40.8426053872973
              ],
              [
                -73.78312383020314,
                40.84273620299365
              ],
              [
                -73.78289925507742,
                40.84277549983599
              ],
              [
                -73.78291747085107,
                40.842806445679095
              ],
              [
                -73.78311703961026,
                40.842771272634415
              ],
              [
                -73.78313925968678,
                40.84282231949004
              ],
              [
                -73.78289487635367,
                40.84286977369323
              ],
              [
                -73.78291098678886,
                40.84292235500553
              ],
              [
                -73.78300874308394,
                40.84290244643356
              ],
              [
                -73.78321038850866,
                40.84347933886644
              ],
              [
                -73.78324501260472,
                40.84347167587895
              ],
              [
                -73.78304539815447,
                40.842896332066644
              ],
              [
                -73.78319194518468,
                40.842867864988975
              ],
              [
                -73.78347481249244,
                40.84365473810981
              ],
              [
                -73.78298304439978,
                40.843767283235266
              ],
              [
                -73.78294225812178,
                40.84379193640604
              ],
              [
                -73.78286866600375,
                40.84389071688291
              ],
              [
                -73.78284151617696,
                40.84389478720195
              ],
              [
                -73.78280661506554,
                40.84377931612206
              ],
              [
                -73.78313252568665,
                40.84369749673174
              ],
              [
                -73.78290103888799,
                40.84311797133364
              ],
              [
                -73.78287388243167,
                40.843124102362346
              ],
              [
                -73.7830918611237,
                40.8436850548112
              ],
              [
                -73.78278498635665,
                40.84375454548095
              ],
              [
                -73.78260741176439,
                40.843286404957624
              ],
              [
                -73.78257210723775,
                40.8432945824458
              ],
              [
                -73.78282243971395,
                40.843919481199485
              ],
              [
                -73.78246939706243,
                40.84399918783505
              ],
              [
                -73.78224871668732,
                40.843436168417554
              ],
              [
                -73.7822161188231,
                40.84344641111838
              ],
              [
                -73.78243679895444,
                40.844009431099934
              ],
              [
                -73.7820375758627,
                40.844103474973494
              ],
              [
                -73.78183306599355,
                40.84357551830498
              ],
              [
                -73.78180047475469,
                40.84358370068881
              ],
              [
                -73.78200770536681,
                40.84410960066973
              ],
              [
                -73.78157590302521,
                40.84420770397304
              ],
              [
                -73.78137674098357,
                40.84370448654004
              ],
              [
                -73.78135229180006,
                40.84371268255372
              ],
              [
                -73.78154602523335,
                40.84421588974769
              ],
              [
                -73.78108977797854,
                40.84432012707756
              ],
              [
                -73.7809122002328,
                40.84385610539353
              ],
              [
                -73.78087690198716,
                40.84386222048779
              ],
              [
                -73.78106261364299,
                40.84432831787671
              ],
              [
                -73.78058464728879,
                40.8444345726982
              ],
              [
                -73.78039623282005,
                40.84396640817974
              ],
              [
                -73.78036906217557,
                40.843976661193686
              ],
              [
                -73.78056017668013,
                40.84444895181869
              ],
              [
                -73.78053302714567,
                40.84445302176267
              ],
              [
                -73.7799786859016,
                40.843856389254356
              ],
              [
                -73.77992703744448,
                40.84388308135583
              ],
              [
                -73.78052736048552,
                40.84452514033816
              ],
              [
                -73.78057628971331,
                40.84450050300951
              ],
              [
                -73.78054653995335,
                40.84446953435901
              ],
              [
                -73.78057367810457,
                40.84446958598638
              ],
              [
                -73.78290650949755,
                40.84393612584013
              ],
              [
                -73.78289307603839,
                40.84389488425852
              ],
              [
                -73.78298567054594,
                40.84379407880406
              ],
              [
                -73.78348428748797,
                40.84367854599843
              ],
              [
                -73.78349593173812,
                40.84373234293595
              ],
              [
                -73.78309494705952,
                40.84386609490482
              ],
              [
                -73.78326987138819,
                40.84406939209163
              ],
              [
                -73.78301129829549,
                40.84417269034717
              ],
              [
                -73.78304608891058,
                40.8443253250351
              ],
              [
                -73.78306040951676,
                40.84437206489807
              ],
              [
                -73.78310017474654,
                40.84438313060096
              ],
              [
                -73.78315451147776,
                40.844363997809246
              ],
              [
                -73.78323416798307,
                40.84434766164698
              ],
              [
                -73.7832381891779,
                40.844347014854286
              ],
              [
                -73.78328485082511,
                40.84433951285005
              ],
              [
                -73.78332100656189,
                40.8443478248024
              ],
              [
                -73.7833354347671,
                40.844361589719796
              ],
              [
                -73.78335700996654,
                40.844402846537584
              ],
              [
                -73.7833653613534,
                40.844444722981706
              ],
              [
                -73.78297579244337,
                40.84468094802046
              ],
              [
                -73.78309714310711,
                40.8449916743321
              ],
              [
                -73.78322207613923,
                40.84506344465817
              ],
              [
                -73.78315245505728,
                40.84529598332779
              ],
              [
                -73.78310841324824,
                40.84528981990339
              ],
              [
                -73.78313318952935,
                40.84518167305947
              ],
              [
                -73.78307457446681,
                40.84505636873394
              ],
              [
                -73.78188707672663,
                40.84533388564937
              ],
              [
                -73.7818153590045,
                40.845480582880384
              ],
              [
                -73.78183975772673,
                40.84548835758404
              ],
              [
                -73.78190735927909,
                40.845355561746764
              ],
              [
                -73.78306023126578,
                40.845085708567055
              ],
              [
                -73.78309455887226,
                40.84516923547116
              ],
              [
                -73.7830594398957,
                40.84532836841477
              ],
              [
                -73.78308181330725,
                40.845333047389694
              ],
              [
                -73.78305074940779,
                40.84549682317583
              ],
              [
                -73.78250088102259,
                40.84559779830173
              ],
              [
                -73.78250690114284,
                40.8456240848009
              ],
              [
                -73.78237655393292,
                40.84565011528096
              ],
              [
                -73.78238057457457,
                40.84566557830359
              ],
              [
                -73.78181646104291,
                40.845764978051015
              ],
              [
                -73.78182047648926,
                40.84578198749807
              ],
              [
                -73.78242487155511,
                40.845676336667
              ],
              [
                -73.78242148841352,
                40.84566068102307
              ],
              [
                -73.78254177869914,
                40.84563656442655
              ],
              [
                -73.7825383977149,
                40.84562003866996
              ],
              [
                -73.78257849117324,
                40.84561315914988
              ],
              [
                -73.78257509379014,
                40.84560185056608
              ],
              [
                -73.78307455830657,
                40.84550976429197
              ],
              [
                -73.78311070737087,
                40.845308129742314
              ],
              [
                -73.78314644227363,
                40.84530994202601
              ],
              [
                -73.78309556892438,
                40.84551369086318
              ],
              [
                -73.78309442048638,
                40.84551828556462
              ],
              [
                -73.78249597258944,
                40.845661561949015
              ],
              [
                -73.78251990755469,
                40.8457581942621
              ],
              [
                -73.78257932865696,
                40.84593589302775
              ],
              [
                -73.78235702810511,
                40.84598158833272
              ],
              [
                -73.7825134511685,
                40.84640877155118
              ],
              [
                -73.78229152990997,
                40.846457790116574
              ],
              [
                -73.7823282774843,
                40.84654861127131
              ],
              [
                -73.78214232783147,
                40.84657770660868
              ],
              [
                -73.78215667396832,
                40.846607578113655
              ],
              [
                -73.78109301885183,
                40.84679849158744
              ],
              [
                -73.78109853456938,
                40.84677171143898
              ],
              [
                -73.78171494622761,
                40.84665747421469
              ],
              [
                -73.78174288903251,
                40.84641229011591
              ],
              [
                -73.78171847815004,
                40.84640812132608
              ],
              [
                -73.78168786937833,
                40.84663887521194
              ],
              [
                -73.78110675760784,
                40.84674699714117
              ],
              [
                -73.78118979824524,
                40.84625255880356
              ],
              [
                -73.78115182578563,
                40.846246304627094
              ],
              [
                -73.78106043973004,
                40.84680255185432
              ],
              [
                -73.780403269586,
                40.84693113416608
              ],
              [
                -73.7804624159846,
                40.84710023328959
              ],
              [
                -73.78067693704057,
                40.84706148579349
              ],
              [
                -73.78063668411163,
                40.846923334752375
              ],
              [
                -73.7810575836192,
                40.84684582392917
              ],
              [
                -73.7810683698102,
                40.84686645220564
              ],
              [
                -73.78113626322276,
                40.846852155377384
              ],
              [
                -73.78112548290936,
                40.846829466907394
              ],
              [
                -73.78216393435585,
                40.84663633778077
              ],
              [
                -73.78218546562233,
                40.846719715627714
              ],
              [
                -73.78221497817432,
                40.846861409589486
              ],
              [
                -73.78209539971067,
                40.84691339096235
              ],
              [
                -73.78205261928963,
                40.84699083792769
              ],
              [
                -73.7818252391237,
                40.847065517672064
              ],
              [
                -73.781844099006,
                40.84713610793705
              ],
              [
                -73.78152237195346,
                40.84731347994142
              ],
              [
                -73.78142920347912,
                40.84745634535727
              ],
              [
                -73.78150307163138,
                40.847681642963124
              ],
              [
                -73.78148431950568,
                40.847882193848584
              ],
              [
                -73.78158418081361,
                40.84803705254238
              ],
              [
                -73.78250408633544,
                40.84791327376319
              ],
              [
                -73.78263740397888,
                40.848072649245836
              ],
              [
                -73.781831743682,
                40.848246670898966
              ],
              [
                -73.78172594274021,
                40.848234105806
              ],
              [
                -73.7813714588191,
                40.848332354599485
              ],
              [
                -73.78135124158366,
                40.84829058454093
              ],
              [
                -73.7812942097643,
                40.84830284137517
              ],
              [
                -73.7811538186934,
                40.84828711851653
              ],
              [
                -73.781153783098,
                40.84829793765262
              ],
              [
                -73.78112733303398,
                40.848294796819715
              ],
              [
                -73.78110697775321,
                40.84829475819657
              ],
              [
                -73.78109878127883,
                40.84831174448807
              ],
              [
                -73.78110078567305,
                40.848321021583665
              ],
              [
                -73.78112520061113,
                40.8483241595664
              ],
              [
                -73.78114557934342,
                40.8483164696778
              ],
              [
                -73.78130228002807,
                40.84832449541756
              ],
              [
                -73.78130627517389,
                40.84834768647257
              ],
              [
                -73.78114129062631,
                40.84838292367897
              ],
              [
                -73.781090360109,
                40.84839673722949
              ],
              [
                -73.78101090695077,
                40.848418224664776
              ],
              [
                -73.78098438117183,
                40.84843826780632
              ],
              [
                -73.78097820938946,
                40.84845834791351
              ],
              [
                -73.78099244183939,
                40.848463011832294
              ],
              [
                -73.78101890213868,
                40.848463062064866
              ],
              [
                -73.78106574674733,
                40.84845387768872
              ],
              [
                -73.78131833463323,
                40.84839407797138
              ],
              [
                -73.7813182634964,
                40.84841571607577
              ],
              [
                -73.78130194972267,
                40.848424958942836
              ],
              [
                -73.78109415548866,
                40.84848020673817
              ],
              [
                -73.78107580166363,
                40.84849099161343
              ],
              [
                -73.78107575079667,
                40.848506446947276
              ],
              [
                -73.78108791694191,
                40.84852038139701
              ],
              [
                -73.78110827162968,
                40.84852042002227
              ],
              [
                -73.78112258967961,
                40.84849880844866
              ],
              [
                -73.78133652580439,
                40.84843275253867
              ],
              [
                -73.78139151751019,
                40.848422038231746
              ],
              [
                -73.78138148782878,
                40.84837719582918
              ],
              [
                -73.78152611318389,
                40.848343466137116
              ],
              [
                -73.78155442120503,
                40.848400707586315
              ],
              [
                -73.78176219386184,
                40.84835164156261
              ],
              [
                -73.78189457733075,
                40.84832716154013
              ],
              [
                -73.78267467078705,
                40.84813967294664
              ],
              [
                -73.78261910501016,
                40.84821027812582
              ],
              [
                -73.78261900912084,
                40.84823961994348
              ],
              [
                -73.78255877758846,
                40.848277325976234
              ],
              [
                -73.78251408510305,
                40.84828963129751
              ],
              [
                -73.78220384181529,
                40.848367943911796
              ],
              [
                -73.78209640639666,
                40.8483983878631
              ],
              [
                -73.78207657695802,
                40.8484224763746
              ],
              [
                -73.78207041238296,
                40.84846941249452
              ],
              [
                -73.7817970940135,
                40.84854714327129
              ],
              [
                -73.78178920235625,
                40.84852720772875
              ],
              [
                -73.78206189132574,
                40.848449183284664
              ],
              [
                -73.78202189306344,
                40.848339562922654
              ],
              [
                -73.78200212772735,
                40.84834408892633
              ],
              [
                -73.78203616713365,
                40.84843804928343
              ],
              [
                -73.78178514040063,
                40.8485098018392
              ],
              [
                -73.78149386587489,
                40.84858047582578
              ],
              [
                -73.78149337883968,
                40.84871530676673
              ],
              [
                -73.78150756375803,
                40.848719289881394
              ],
              [
                -73.78143249522284,
                40.84880000581332
              ],
              [
                -73.78145563649926,
                40.848813090897714
              ],
              [
                -73.78153956955133,
                40.84872637528167
              ],
              [
                -73.78157440440629,
                40.84873180821234
              ],
              [
                -73.78159491975414,
                40.848752947876605
              ],
              [
                -73.78150620325309,
                40.84884252817913
              ],
              [
                -73.78153019763666,
                40.84885757187962
              ],
              [
                -73.78161367535198,
                40.84877238634991
              ],
              [
                -73.78168604284517,
                40.84883788315216
              ],
              [
                -73.78160738546269,
                40.84888640853134
              ],
              [
                -73.78161594069067,
                40.84889620461115
              ],
              [
                -73.78169581560914,
                40.84884836234785
              ],
              [
                -73.7818286909823,
                40.848959205363606
              ],
              [
                -73.78184847915905,
                40.848947505217865
              ],
              [
                -73.78186608494606,
                40.848944841039334
              ],
              [
                -73.78193782713453,
                40.8489339814074
              ],
              [
                -73.78214108697831,
                40.84910066266766
              ],
              [
                -73.78201082139036,
                40.84917990738358
              ],
              [
                -73.78194967598762,
                40.84920529450143
              ],
              [
                -73.78193902433554,
                40.849194841810764
              ],
              [
                -73.78176163701295,
                40.84928724294748
              ],
              [
                -73.78176924362299,
                40.84929537236712
              ],
              [
                -73.78173101140973,
                40.84931616481153
              ],
              [
                -73.78171883268593,
                40.84930570921137
              ],
              [
                -73.78154449088599,
                40.849400434643336
              ],
              [
                -73.7815749238,
                40.84943063184538
              ],
              [
                -73.78175535569149,
                40.84934055428367
              ],
              [
                -73.78174317784173,
                40.84933009868773
              ],
              [
                -73.78178293737228,
                40.849309307955735
              ],
              [
                -73.78179206236673,
                40.849319758945455
              ],
              [
                -73.78197402498779,
                40.8492285256757
              ],
              [
                -73.78196338004864,
                40.849215753630496
              ],
              [
                -73.78201995532704,
                40.84918803900289
              ],
              [
                -73.78203367458846,
                40.84919386175839
              ],
              [
                -73.78215814152048,
                40.84911617392382
              ],
              [
                -73.78219328627868,
                40.849142790995444
              ],
              [
                -73.78207020661583,
                40.849226388719124
              ],
              [
                -73.78209456304481,
                40.849247300009694
              ],
              [
                -73.78221806474305,
                40.84916680459612
              ],
              [
                -73.78244952709214,
                40.84934115401569
              ],
              [
                -73.78303535537907,
                40.84907190812785
              ],
              [
                -73.78311715803491,
                40.84918308830608
              ],
              [
                -73.78314691693552,
                40.8492119955668
              ],
              [
                -73.7831794489828,
                40.84922236094945
              ],
              [
                -73.78322357300495,
                40.84922244387418
              ],
              [
                -73.78323644139,
                40.84922246805528
              ],
              [
                -73.78329074622592,
                40.84921432656417
              ],
              [
                -73.78333693054938,
                40.84919998765855
              ],
              [
                -73.78339395583185,
                40.84918979001324
              ],
              [
                -73.7834536807776,
                40.84918372050767
              ],
              [
                -73.78349977784661,
                40.84919617197559
              ],
              [
                -73.78353497808732,
                40.849220967420266
              ],
              [
                -73.78357825260379,
                40.849266386802206
              ],
              [
                -73.78407378345817,
                40.849902425389395
              ],
              [
                -73.78409701801215,
                40.8499514850463
              ],
              [
                -73.78415333900804,
                40.85000143719395
              ],
              [
                -73.78420047134377,
                40.8500606442406
              ],
              [
                -73.78420494271475,
                40.85009427047383
              ],
              [
                -73.78421247052574,
                40.85012674181812
              ],
              [
                -73.78425338375496,
                40.850220713839434
              ],
              [
                -73.78425785826201,
                40.85025318064228
              ],
              [
                -73.78423943051325,
                40.8502867628729
              ],
              [
                -73.78420112637873,
                40.85032958288327
              ],
              [
                -73.78412613427056,
                40.8503874026757
              ],
              [
                -73.78404198397533,
                40.85044520577757
              ],
              [
                -73.78401421301417,
                40.85047391345848
              ],
              [
                -73.78401288041832,
                40.850475289853044
              ],
              [
                -73.78400362316144,
                40.85050541275762
              ],
              [
                -73.78400879049795,
                40.850525932053486
              ],
              [
                -73.78401710573822,
                40.850584263374934
              ],
              [
                -73.78406580837313,
                40.8506252332364
              ],
              [
                -73.78403526024866,
                40.85063530404628
              ],
              [
                -73.78403070700506,
                40.85062718049514
              ],
              [
                -73.78400319779757,
                40.85063640227853
              ],
              [
                -73.78401079664634,
                40.85064684972925
              ],
              [
                -73.78395883886576,
                40.85066298131831
              ],
              [
                -73.78395428629146,
                40.850654857765434
              ],
              [
                -73.78384119307984,
                40.850692899498306
              ],
              [
                -73.78385181911865,
                40.85071146765854
              ],
              [
                -73.78401687236345,
                40.85065613489466
              ],
              [
                -73.78402142627081,
                40.85066425794507
              ],
              [
                -73.78404741235457,
                40.850653873370376
              ],
              [
                -73.78404133663162,
                40.85064458870901
              ],
              [
                -73.78407975214625,
                40.850630614908575
              ],
              [
                -73.78411913614823,
                40.850662121467856
              ],
              [
                -73.7841632949916,
                40.850696980725054
              ],
              [
                -73.78422574727558,
                40.85073998920991
              ],
              [
                -73.78426993630782,
                40.85076557351953
              ],
              [
                -73.78431265352698,
                40.850773768421654
              ],
              [
                -73.78433708793973,
                40.85077149590738
              ],
              [
                -73.78434575107664,
                40.85077005079135
              ],
              [
                -73.78439207608706,
                40.85076232540573
              ],
              [
                -73.78442108103592,
                40.85076237961208
              ],
              [
                -73.78445463288537,
                40.85077287385448
              ],
              [
                -73.78447441853888,
                40.85079145789808
              ],
              [
                -73.78448807935747,
                40.85081582682402
              ],
              [
                -73.78450494990614,
                40.8508535004636
              ],
              [
                -73.78443407549099,
                40.850861514903876
              ],
              [
                -73.78444750529482,
                40.85090481745939
              ],
              [
                -73.78451936300075,
                40.85089273502131
              ],
              [
                -73.78460077697598,
                40.851095692195194
              ],
              [
                -73.78447953199854,
                40.85107180299238
              ],
              [
                -73.78435188706086,
                40.85109835504544
              ],
              [
                -73.78414275091883,
                40.85114742417621
              ],
              [
                -73.78398791994971,
                40.851188350606826
              ],
              [
                -73.78379501276285,
                40.85125393535625
              ],
              [
                -73.78360210541598,
                40.85131951978262
              ],
              [
                -73.78349609889963,
                40.85136877997577
              ],
              [
                -73.78344495936106,
                40.851400909224445
              ],
              [
                -73.78353416916762,
                40.85152078053071
              ],
              [
                -73.7834133904305,
                40.851563419688596
              ],
              [
                -73.78337646578845,
                40.851576943248666
              ],
              [
                -73.78338158610377,
                40.85158673391434
              ],
              [
                -73.78341001772101,
                40.8515790187113
              ],
              [
                -73.78347528477201,
                40.85155691566573
              ],
              [
                -73.78347956766932,
                40.85156018388715
              ],
              [
                -73.78354267337754,
                40.85153797070417
              ],
              [
                -73.78356242001053,
                40.85156501244066
              ],
              [
                -73.78357767103407,
                40.85156967744472
              ],
              [
                -73.78381306352931,
                40.85151888329486
              ],
              [
                -73.78396112488305,
                40.851486933922956
              ],
              [
                -73.78397173451735,
                40.85150768135717
              ],
              [
                -73.78386093355508,
                40.851532861227604
              ],
              [
                -73.78387115358153,
                40.851558961670044
              ],
              [
                -73.78397932898588,
                40.851532617440434
              ],
              [
                -73.78402637080502,
                40.851609931862825
              ],
              [
                -73.78431415165997,
                40.851767545706885
              ],
              [
                -73.78426688465254,
                40.85177959007606
              ],
              [
                -73.78426432858558,
                40.8517737171013
              ],
              [
                -73.78412338226599,
                40.85180866377683
              ],
              [
                -73.78413189167978,
                40.851832806157276
              ],
              [
                -73.78427798468037,
                40.85179917293405
              ],
              [
                -73.78427457330046,
                40.85179199351652
              ],
              [
                -73.78433474208568,
                40.851776157572345
              ],
              [
                -73.78468307195402,
                40.85174934687985
              ],
              [
                -73.78480722795186,
                40.851752669023774
              ],
              [
                -73.78489888572953,
                40.85173429288914
              ],
              [
                -73.78495591460657,
                40.851723580683434
              ],
              [
                -73.78503542905987,
                40.8516835424064
              ],
              [
                -73.78510066461135,
                40.851652751934395
              ],
              [
                -73.78521881434392,
                40.85162515043416
              ],
              [
                -73.78529210719022,
                40.85162064997351
              ],
              [
                -73.7854019443249,
                40.85164558422445
              ],
              [
                -73.78545277174172,
                40.851664225836416
              ],
              [
                -73.78566404122394,
                40.8517959958209
              ],
              [
                -73.7858818650663,
                40.851788672440925
              ],
              [
                -73.78624762093361,
                40.85202281469252
              ],
              [
                -73.7863244234208,
                40.85207832931137
              ],
              [
                -73.78637622032105,
                40.852112041920556
              ],
              [
                -73.78641733510773,
                40.85214457646971
              ],
              [
                -73.78659390163132,
                40.8523083524231
              ],
              [
                -73.7867689971708,
                40.85245473539846
              ],
              [
                -73.7867765376467,
                40.852483729624616
              ],
              [
                -73.78677648933812,
                40.852498799145046
              ],
              [
                -73.7867657472106,
                40.85251616759754
              ],
              [
                -73.78675959223264,
                40.852531226332864
              ],
              [
                -73.78678232558099,
                40.85255570723554
              ],
              [
                -73.78684958987257,
                40.8525545761115
              ],
              [
                -73.78688470337548,
                40.85255464098319
              ],
              [
                -73.78691672214161,
                40.852567452044674
              ],
              [
                -73.78693805735814,
                40.85257908292886
              ],
              [
                -73.78713667028038,
                40.85273387524412
              ],
              [
                -73.78710390562176,
                40.85275327033491
              ],
              [
                -73.78710999004264,
                40.85276023782388
              ],
              [
                -73.78706106581926,
                40.85278217272938
              ],
              [
                -73.78707627655096,
                40.85279958743541
              ],
              [
                -73.78712215823363,
                40.85277417045418
              ],
              [
                -73.78712672030899,
                40.85277997402518
              ],
              [
                -73.78716496421868,
                40.85275741228193
              ],
              [
                -73.78760502319017,
                40.85311470853485
              ],
              [
                -73.78796264288077,
                40.8534747209355
              ],
              [
                -73.78800064949667,
                40.85352463632798
              ],
              [
                -73.78801424958195,
                40.8535687112082
              ],
              [
                -73.78801104438834,
                40.85361623383959
              ],
              [
                -73.78800508108596,
                40.8536648684512
              ],
              [
                -73.78799403046978,
                40.853685754615356
              ],
              [
                -73.7879679994746,
                40.85371005016556
              ],
              [
                -73.78792821707314,
                40.85373779738915
              ],
              [
                -73.78787778457543,
                40.85375393421109
              ],
              [
                -73.78781206717181,
                40.85377583738077
              ],
              [
                -73.78762411569168,
                40.85382881638006
              ],
              [
                -73.78741935244048,
                40.853887558075
              ],
              [
                -73.78732350665297,
                40.853917164311945
              ],
              [
                -73.78729251210585,
                40.853926737779155
              ],
              [
                -73.7872084560664,
                40.85395440428848
              ],
              [
                -73.7871545889612,
                40.85397968963615
              ],
              [
                -73.78713287236009,
                40.85402427087833
              ],
              [
                -73.78690734702697,
                40.854063972299144
              ],
              [
                -73.78691339069742,
                40.854083690462055
              ],
              [
                -73.78712545967669,
                40.854044378654386
              ],
              [
                -73.78714539852163,
                40.85409918836224
              ],
              [
                -73.78717271889464,
                40.854149084305526
              ],
              [
                -73.7871970601854,
                40.85417579079074
              ],
              [
                -73.78722448578456,
                40.85419322967826
              ],
              [
                -73.78726555834814,
                40.85420762787154
              ],
              [
                -73.78731450066233,
                40.854211942725605
              ],
              [
                -73.787430572486,
                40.854198246480415
              ],
              [
                -73.7875634501659,
                40.854181102936444
              ],
              [
                -73.78762757097078,
                40.85418122099096
              ],
              [
                -73.78765807500652,
                40.85419055109111
              ],
              [
                -73.78769014281566,
                40.85418829124552
              ],
              [
                -73.78777873831645,
                40.85417338531972
              ],
              [
                -73.78816813692171,
                40.85408577890348
              ],
              [
                -73.78819872490023,
                40.85412778905812
              ],
              [
                -73.78827221224095,
                40.85411296306839
              ],
              [
                -73.78832399449908,
                40.85410251564968
              ],
              [
                -73.78834514784103,
                40.85413201235013
              ],
              [
                -73.78763935178881,
                40.85431687146392
              ],
              [
                -73.78764390876884,
                40.85432383443552
              ],
              [
                -73.78755374968587,
                40.854350330528774
              ],
              [
                -73.78754919139483,
                40.85434336587592
              ],
              [
                -73.78734753146402,
                40.854385885459514
              ],
              [
                -73.78736269074899,
                40.85441953057158
              ],
              [
                -73.78756282815222,
                40.85437584889325
              ],
              [
                -73.7875567694506,
                40.85436076813227
              ],
              [
                -73.78764693594798,
                40.85433195385583
              ],
              [
                -73.78765149076932,
                40.85434007726066
              ],
              [
                -73.78787151151583,
                40.85428484016823
              ],
              [
                -73.78786849474662,
                40.85427324147087
              ],
              [
                -73.78834700683626,
                40.854153518949865
              ],
              [
                -73.78839632893784,
                40.854266418620135
              ],
              [
                -73.78840976856601,
                40.854307659487766
              ],
              [
                -73.78843136980228,
                40.854342733692654
              ],
              [
                -73.7884773265335,
                40.85440052053223
              ],
              [
                -73.7884826692924,
                40.85442732090218
              ],
              [
                -73.78848260375628,
                40.85444792872274
              ],
              [
                -73.78845805973428,
                40.85448497834859
              ],
              [
                -73.78844163730807,
                40.85452822516083
              ],
              [
                -73.78842728684886,
                40.85455467815888
              ],
              [
                -73.78841706546328,
                40.854573518907706
              ],
              [
                -73.78841692123977,
                40.85461885577656
              ],
              [
                -73.78842760041378,
                40.854674517218335
              ],
              [
                -73.78842472253514,
                40.85472603287897
              ],
              [
                -73.78839751595056,
                40.854746591925725
              ],
              [
                -73.78835401751454,
                40.854769180114204
              ],
              [
                -73.78832138879896,
                40.85478766848558
              ],
              [
                -73.78829419550173,
                40.8548041044367
              ],
              [
                -73.7882941364666,
                40.85482265139003
              ],
              [
                -73.78830763495627,
                40.85484534480936
              ],
              [
                -73.78831293199039,
                40.85488657074244
              ],
              [
                -73.7883209428071,
                40.854927802659496
              ],
              [
                -73.78833159638137,
                40.854991706398195
              ],
              [
                -73.78833961444349,
                40.855030877113705
              ],
              [
                -73.78835855433422,
                40.8550494595914
              ],
              [
                -73.78839114316482,
                40.855043336570326
              ],
              [
                -73.78843187532392,
                40.85503722914398
              ],
              [
                -73.78844808747019,
                40.85505992685202
              ],
              [
                -73.78849696186786,
                40.85505383483559
              ],
              [
                -73.78853679526938,
                40.85510189085478
              ],
              [
                -73.7885536572479,
                40.85514873611422
              ],
              [
                -73.78853250003306,
                40.855154676929416
              ],
              [
                -73.7884151118428,
                40.85518763710453
              ],
              [
                -73.78842551577243,
                40.85532985288193
              ],
              [
                -73.7883594322236,
                40.85562648914822
              ],
              [
                -73.78840780812261,
                40.85577701707246
              ],
              [
                -73.7884376714846,
                40.85577501062688
              ],
              [
                -73.78847402223268,
                40.85587324721077
              ],
              [
                -73.78846710159921,
                40.855909017262086
              ],
              [
                -73.78852086082745,
                40.85607398231431
              ],
              [
                -73.78850992512635,
                40.85609869095216
              ],
              [
                -73.78853687735143,
                40.85615850479053
              ],
              [
                -73.78858212373594,
                40.85616288821654
              ],
              [
                -73.78859643189443,
                40.85620807247319
              ],
              [
                -73.78860444893704,
                40.856247242657005
              ],
              [
                -73.78862333817503,
                40.85628231184338
              ],
              [
                -73.78865312839909,
                40.856302974186335
              ],
              [
                -73.78868841289075,
                40.85630303881861
              ],
              [
                -73.78872519415646,
                40.85630485127598
              ],
              [
                -73.78873183366792,
                40.85630517854669
              ],
              [
                -73.78875894260247,
                40.85631553290665
              ],
              [
                -73.78877003064156,
                40.85634934057
              ],
              [
                -73.78878247271498,
                40.85636294198657
              ],
              [
                -73.78881837966713,
                40.85640219554306
              ],
              [
                -73.78885616237595,
                40.85647027146297
              ],
              [
                -73.78888312226606,
                40.85652802452021
              ],
              [
                -73.78889654957185,
                40.85657338670978
              ],
              [
                -73.78888830770909,
                40.85660428310896
              ],
              [
                -73.78886926255444,
                40.85661867439667
              ],
              [
                -73.78883933406082,
                40.85664128876775
              ],
              [
                -73.78880667250745,
                40.85667008025646
              ],
              [
                -73.7887794059048,
                40.856709185830454
              ],
              [
                -73.7887955603384,
                40.85675043160423
              ],
              [
                -73.7888062667889,
                40.856797849531105
              ],
              [
                -73.78879251250656,
                40.85685552689661
              ],
              [
                -73.78877463362326,
                40.85688983820476
              ],
              [
                -73.78876247260871,
                40.856913175615745
              ],
              [
                -73.78875148515341,
                40.856954371696006
              ],
              [
                -73.78871879678134,
                40.85699140611457
              ],
              [
                -73.78871047688176,
                40.857047032711066
              ],
              [
                -73.78870485884443,
                40.857106786175954
              ],
              [
                -73.78872093476754,
                40.85717276216798
              ],
              [
                -73.78873164778054,
                40.857218118898274
              ],
              [
                -73.78875047058206,
                40.8572737956984
              ],
              [
                -73.78876389073885,
                40.85732121926359
              ],
              [
                -73.78877898544017,
                40.85740092519962
              ],
              [
                -73.78825436690734,
                40.85748379794237
              ],
              [
                -73.78825587182048,
                40.857490755786465
              ],
              [
                -73.7881718624852,
                40.85750219354377
              ],
              [
                -73.78816580032998,
                40.857488272249014
              ],
              [
                -73.78802830701825,
                40.85751468128371
              ],
              [
                -73.788043429758,
                40.85755991768708
              ],
              [
                -73.788182450876,
                40.85753351143939
              ],
              [
                -73.7881764099842,
                40.857512635102445
              ],
              [
                -73.78826195310619,
                40.85749888196358
              ],
              [
                -73.78826498050694,
                40.857507000860615
              ],
              [
                -73.78878402854569,
                40.85742069033656
              ],
              [
                -73.78878275515255,
                40.85744419476536
              ],
              [
                -73.78879167253787,
                40.857520718164864
              ],
              [
                -73.78879449753784,
                40.85759259352103
              ],
              [
                -73.78879886108632,
                40.85766099473813
              ],
              [
                -73.78879564521492,
                40.8577119942916
              ],
              [
                -73.78881535014905,
                40.85775723897932
              ],
              [
                -73.78882587932831,
                40.857807104930004
              ],
              [
                -73.78884710614865,
                40.85785351233133
              ],
              [
                -73.78886684361834,
                40.85788832386005
              ],
              [
                -73.78887898065909,
                40.85791268944161
              ],
              [
                -73.78890090345358,
                40.85799406515931
              ],
              [
                -73.78890751432796,
                40.858062280004816
              ],
              [
                -73.78891041371965,
                40.85811097205076
              ],
              [
                -73.78890874036226,
                40.85815733753702
              ],
              [
                -73.78891167199001,
                40.858195596433184
              ],
              [
                -73.78892076275636,
                40.85821763824253
              ],
              [
                -73.7889328557321,
                40.85825591389827
              ],
              [
                -73.78894954009625,
                40.85829072099739
              ],
              [
                -73.78895591927375,
                40.85831029373738
              ],
              [
                -73.7889661727637,
                40.858341756353845
              ],
              [
                -73.78897067954541,
                40.858364948535126
              ],
              [
                -73.78897973637031,
                40.85839742298204
              ],
              [
                -73.78897964442007,
                40.858426403071135
              ],
              [
                -73.7889780616097,
                40.85843756780748
              ],
              [
                -73.78897652126375,
                40.85844842253513
              ],
              [
                -73.78898385013113,
                40.85847084373988
              ],
              [
                -73.78934280432145,
                40.85856093104267
              ],
              [
                -73.78972202672374,
                40.85856245340872
              ],
              [
                -73.7899222929631,
                40.85853228938995
              ],
              [
                -73.7900459983775,
                40.85847540491209
              ],
              [
                -73.79009877156537,
                40.858531798103954
              ],
              [
                -73.79009141537634,
                40.85853874532931
              ],
              [
                -73.79012065319684,
                40.85857012058902
              ],
              [
                -73.79013351158892,
                40.85856248730969
              ],
              [
                -73.79018745194999,
                40.85860991738612
              ]
            ]
          ],
          [
            [
              [
                -73.78283291447852,
                40.855870308445745
              ],
              [
                -73.78343487501385,
                40.856362865958886
              ],
              [
                -73.78394699722006,
                40.85563043752662
              ],
              [
                -73.78367819665118,
                40.85517343750966
              ],
              [
                -73.78302371522503,
                40.85509276666496
              ],
              [
                -73.78283291447852,
                40.855870308445745
              ]
            ]
          ],
          [
            [
              [
                -73.78061730829718,
                40.855735175810025
              ],
              [
                -73.78122782332017,
                40.856087789162785
              ],
              [
                -73.78156303268149,
                40.85574479600088
              ],
              [
                -73.7815638969994,
                40.855001286433904
              ],
              [
                -73.7809047685129,
                40.8549681331623
              ],
              [
                -73.78061730829718,
                40.855735175810025
              ]
            ]
          ],
          [
            [
              [
                -73.78833349834521,
                40.834667129759346
              ],
              [
                -73.78845700015209,
                40.83530991431538
              ],
              [
                -73.78951019872322,
                40.83536404252577
              ],
              [
                -73.78931223606624,
                40.834464886553434
              ],
              [
                -73.78833349834521,
                40.834667129759346
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000263896952605,
        "objectid": 42,
        "shape_leng": 0.0927092274616,
        "location_id": 42,
        "zone": "Central Harlem North",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.93436121591056,
                40.83620061961779
              ],
              [
                -73.93460027799988,
                40.83609686799992
              ],
              [
                -73.93467393099985,
                40.836064902999915
              ],
              [
                -73.93474569399996,
                40.836092093999916
              ],
              [
                -73.9347988639999,
                40.836110115999944
              ],
              [
                -73.93485740300004,
                40.83598502599991
              ],
              [
                -73.93496274099988,
                40.83575993399989
              ],
              [
                -73.93506336299993,
                40.83567765899993
              ],
              [
                -73.93530788100004,
                40.835477723999915
              ],
              [
                -73.93572624299988,
                40.83515634699995
              ],
              [
                -73.93649557199993,
                40.83456879599991
              ],
              [
                -73.93700641599989,
                40.834193109999894
              ],
              [
                -73.93705905900006,
                40.83416598899989
              ],
              [
                -73.93729666499993,
                40.833977743999945
              ],
              [
                -73.93795718299988,
                40.83345441799987
              ],
              [
                -73.93838508999997,
                40.83311350299995
              ],
              [
                -73.93857474799998,
                40.832915085999936
              ],
              [
                -73.93862894999997,
                40.83285794199988
              ],
              [
                -73.93868316299985,
                40.832800788999904
              ],
              [
                -73.9387736029999,
                40.83266321199991
              ],
              [
                -73.93890834499989,
                40.832480356999945
              ],
              [
                -73.93894859799995,
                40.832417335999914
              ],
              [
                -73.93902897299996,
                40.83229149799995
              ],
              [
                -73.93913480799989,
                40.832097594999915
              ],
              [
                -73.93922536500001,
                40.83189966099992
              ],
              [
                -73.93927114599992,
                40.83176179299993
              ],
              [
                -73.93927116899984,
                40.83176171999991
              ],
              [
                -73.9392711939998,
                40.83176165699991
              ],
              [
                -73.93933313199983,
                40.83162629999988
              ],
              [
                -73.93941078799995,
                40.83149507599994
              ],
              [
                -73.939410835,
                40.8314949949999
              ],
              [
                -73.93950338699985,
                40.83136945999996
              ],
              [
                -73.93960960099984,
                40.83125111899992
              ],
              [
                -73.93960970799996,
                40.83125100199988
              ],
              [
                -73.93960982599997,
                40.83125089499988
              ],
              [
                -73.93972831499984,
                40.83114089899995
              ],
              [
                -73.9398046819999,
                40.83107011299992
              ],
              [
                -73.93980484699993,
                40.831069960999905
              ],
              [
                -73.93980498999994,
                40.83106978999995
              ],
              [
                -73.93987049099984,
                40.83099197699988
              ],
              [
                -73.9398705739998,
                40.83099188599995
              ],
              [
                -73.93987063299991,
                40.83099179699991
              ],
              [
                -73.93992383299992,
                40.83090827699993
              ],
              [
                -73.93992389199987,
                40.83090817699985
              ],
              [
                -73.93992395099986,
                40.83090805999994
              ],
              [
                -73.93996366299984,
                40.8308206059999
              ],
              [
                -73.93996368699993,
                40.83082053399987
              ],
              [
                -73.93998939900003,
                40.830730965999855
              ],
              [
                -73.94007905799988,
                40.83046395799989
              ],
              [
                -73.9401708989999,
                40.83038439099996
              ],
              [
                -73.93962569899988,
                40.83015583699997
              ],
              [
                -73.93899743399992,
                40.82988885299995
              ],
              [
                -73.93864026699997,
                40.82973887399988
              ],
              [
                -73.93896034899993,
                40.82898632999993
              ],
              [
                -73.93924885599988,
                40.828296350999906
              ],
              [
                -73.93968952899993,
                40.827656573999896
              ],
              [
                -73.9401395379999,
                40.82703868999988
              ],
              [
                -73.94059472699999,
                40.82641360399988
              ],
              [
                -73.94104840899992,
                40.825792629999896
              ],
              [
                -73.94150312699996,
                40.82517136699994
              ],
              [
                -73.94195569699997,
                40.82455443999992
              ],
              [
                -73.94242953999996,
                40.8239091569999
              ],
              [
                -73.94292366900005,
                40.8232605869999
              ],
              [
                -73.9435059449999,
                40.82262775299988
              ],
              [
                -73.94404986399994,
                40.82205159199989
              ],
              [
                -73.94460364599989,
                40.82146198099991
              ],
              [
                -73.94515456999994,
                40.82087626499993
              ],
              [
                -73.94607828700005,
                40.82126321599991
              ],
              [
                -73.94640289799987,
                40.820572041999945
              ],
              [
                -73.94671831999985,
                40.81989426799995
              ],
              [
                -73.94703623199983,
                40.81920837199988
              ],
              [
                -73.94734953999983,
                40.81853499799996
              ],
              [
                -73.94766641299987,
                40.817855508999905
              ],
              [
                -73.94800732899995,
                40.81711650899995
              ],
              [
                -73.94835601499989,
                40.81637430599994
              ],
              [
                -73.94867681799998,
                40.815692285999916
              ],
              [
                -73.94883491299987,
                40.815363980999955
              ],
              [
                -73.94900927799986,
                40.81503994299992
              ],
              [
                -73.94919960099985,
                40.81472085399986
              ],
              [
                -73.949405505,
                40.814407408999976
              ],
              [
                -73.94962639600001,
                40.81410043599993
              ],
              [
                -73.94962653799998,
                40.81410023899991
              ],
              [
                -73.94962668099991,
                40.81410004999989
              ],
              [
                -73.94986218999995,
                40.813799979999935
              ],
              [
                -73.95035019299995,
                40.813159388999914
              ],
              [
                -73.95092724899993,
                40.812575428999914
              ],
              [
                -73.95098203399994,
                40.81252162999989
              ],
              [
                -73.95150537299989,
                40.812007684999905
              ],
              [
                -73.9521012519999,
                40.811442853999935
              ],
              [
                -73.95067975599989,
                40.8108429639999
              ],
              [
                -73.94792306799984,
                40.809676921999944
              ],
              [
                -73.94783875999985,
                40.8096412609999
              ],
              [
                -73.94773985499985,
                40.80959972099989
              ],
              [
                -73.94506102999992,
                40.808474499999875
              ],
              [
                -73.94499360699993,
                40.808446183999955
              ],
              [
                -73.94491601899982,
                40.80841350399989
              ],
              [
                -73.9417714019999,
                40.80708899999997
              ],
              [
                -73.94015793699995,
                40.80640908999991
              ],
              [
                -73.93869430499996,
                40.80579102899988
              ],
              [
                -73.93854407899988,
                40.80572965299991
              ],
              [
                -73.9380866689998,
                40.80635301199987
              ],
              [
                -73.937634394,
                40.8069746069999
              ],
              [
                -73.93718011799989,
                40.80759899999989
              ],
              [
                -73.93671827500005,
                40.80822047899988
              ],
              [
                -73.93625935999997,
                40.80885560799991
              ],
              [
                -73.93586263399992,
                40.80943020299987
              ],
              [
                -73.93580780199986,
                40.809497637999876
              ],
              [
                -73.93740733700004,
                40.810175610999885
              ],
              [
                -73.93901753299998,
                40.81085655699991
              ],
              [
                -73.93874394499981,
                40.81123082099992
              ],
              [
                -73.93863753299985,
                40.81137638799993
              ],
              [
                -73.93838748499988,
                40.811718443999894
              ],
              [
                -73.93830924999986,
                40.81182546399992
              ],
              [
                -73.93820642699984,
                40.81196760499992
              ],
              [
                -73.9381355239999,
                40.81206561899996
              ],
              [
                -73.93763499299979,
                40.81274779999994
              ],
              [
                -73.93714398399993,
                40.81342104099985
              ],
              [
                -73.93668813299989,
                40.81404324299995
              ],
              [
                -73.936209578,
                40.81467384999995
              ],
              [
                -73.93613554399997,
                40.81478812699993
              ],
              [
                -73.93575548199986,
                40.81532558399993
              ],
              [
                -73.93487343800004,
                40.816527846999904
              ],
              [
                -73.93463790299994,
                40.81690804799997
              ],
              [
                -73.93445400100002,
                40.81718852899996
              ],
              [
                -73.934235195,
                40.81755488099992
              ],
              [
                -73.93412816799999,
                40.81773396299989
              ],
              [
                -73.93407463999996,
                40.81782651399992
              ],
              [
                -73.93408481999981,
                40.8179308619999
              ],
              [
                -73.93423576099998,
                40.81864931899987
              ],
              [
                -73.93429487799989,
                40.81890498699992
              ],
              [
                -73.934381129,
                40.81932807599992
              ],
              [
                -73.93439036800001,
                40.81958743499993
              ],
              [
                -73.93440039999986,
                40.81966778299991
              ],
              [
                -73.93431276899999,
                40.81962986599991
              ],
              [
                -73.93411671299988,
                40.81954705299995
              ],
              [
                -73.93383089287107,
                40.819521063761556
              ],
              [
                -73.93383064085585,
                40.819571464086366
              ],
              [
                -73.93383051181283,
                40.81959745323817
              ],
              [
                -73.93382990021335,
                40.81972042397129
              ],
              [
                -73.9339720967025,
                40.8197302031345
              ],
              [
                -73.93398644004239,
                40.8199684386757
              ],
              [
                -73.9339827694626,
                40.82000029618647
              ],
              [
                -73.93400066445355,
                40.82033825378207
              ],
              [
                -73.93400389615688,
                40.82039784018416
              ],
              [
                -73.93402662334591,
                40.82081680975282
              ],
              [
                -73.93403560443167,
                40.820952547282424
              ],
              [
                -73.93404599779073,
                40.821133876520214
              ],
              [
                -73.93404447476462,
                40.82117670357439
              ],
              [
                -73.93407005522698,
                40.821559320934185
              ],
              [
                -73.93407734095152,
                40.82169456177003
              ],
              [
                -73.9340743435484,
                40.8217170991102
              ],
              [
                -73.93407944723481,
                40.82180557579632
              ],
              [
                -73.93409112517361,
                40.821996037990196
              ],
              [
                -73.93409843799957,
                40.8221042273439
              ],
              [
                -73.93409986738209,
                40.82213991284364
              ],
              [
                -73.9341023336634,
                40.82220121691681
              ],
              [
                -73.93411752612668,
                40.82252433268852
              ],
              [
                -73.9341212616508,
                40.822647252741746
              ],
              [
                -73.93413373625334,
                40.82280065597675
              ],
              [
                -73.93414604382117,
                40.823020619180404
              ],
              [
                -73.9341465766699,
                40.82302903205036
              ],
              [
                -73.93416594530665,
                40.82307858087102
              ],
              [
                -73.9342183480328,
                40.82379904579008
              ],
              [
                -73.9342464289508,
                40.82416688303727
              ],
              [
                -73.93427285440853,
                40.824438952751265
              ],
              [
                -73.9342937167057,
                40.824496386917176
              ],
              [
                -73.93429530732782,
                40.824649231222814
              ],
              [
                -73.93432574289118,
                40.82523259029446
              ],
              [
                -73.93435643452145,
                40.82582093714441
              ],
              [
                -73.93436717955333,
                40.82582759866106
              ],
              [
                -73.93442844400506,
                40.82646785059143
              ],
              [
                -73.93446426549498,
                40.82684220519355
              ],
              [
                -73.93448291058755,
                40.82703709277872
              ],
              [
                -73.93442396339046,
                40.82704289397221
              ],
              [
                -73.93451590672413,
                40.82772893075849
              ],
              [
                -73.93452076528098,
                40.82776568965099
              ],
              [
                -73.9345318309276,
                40.8278494020435
              ],
              [
                -73.9345389813406,
                40.82790347267067
              ],
              [
                -73.93452175638055,
                40.827907800091346
              ],
              [
                -73.93453258880513,
                40.82796855886425
              ],
              [
                -73.9345423063815,
                40.828026870313025
              ],
              [
                -73.93454586266023,
                40.82804816146417
              ],
              [
                -73.93455810179871,
                40.82811740277773
              ],
              [
                -73.93456513010244,
                40.82816270444937
              ],
              [
                -73.93458684240736,
                40.828156719571446
              ],
              [
                -73.9345887726724,
                40.828195693979055
              ],
              [
                -73.93459763971535,
                40.82820843167856
              ],
              [
                -73.9345917047489,
                40.82822716755991
              ],
              [
                -73.93458676210527,
                40.82823840277646
              ],
              [
                -73.93459562914452,
                40.82825114985734
              ],
              [
                -73.9345867414778,
                40.828259384992435
              ],
              [
                -73.93459360040826,
                40.82830135116011
              ],
              [
                -73.93460048488097,
                40.82832833398502
              ],
              [
                -73.93459750565471,
                40.82834482035353
              ],
              [
                -73.93460834555331,
                40.82836355753901
              ],
              [
                -73.93460043185567,
                40.8283822824143
              ],
              [
                -73.93460731363069,
                40.82840102556366
              ],
              [
                -73.93460433489878,
                40.8284280027947
              ],
              [
                -73.93461219000993,
                40.82845722914913
              ],
              [
                -73.93461017716503,
                40.828502936290256
              ],
              [
                -73.93460917445479,
                40.828521674968194
              ],
              [
                -73.9346115955321,
                40.82856775518621
              ],
              [
                -73.93460192118351,
                40.8285925492986
              ],
              [
                -73.93459873640637,
                40.82860072400894
              ],
              [
                -73.93460065845267,
                40.82864718271558
              ],
              [
                -73.93460804993313,
                40.828653363018134
              ],
              [
                -73.93461052506869,
                40.82865543619221
              ],
              [
                -73.93460883576694,
                40.82866118868204
              ],
              [
                -73.93460260323826,
                40.82868240123771
              ],
              [
                -73.93461171264791,
                40.82868983525945
              ],
              [
                -73.93461640343897,
                40.82869365579325
              ],
              [
                -73.93463641852115,
                40.82890686014907
              ],
              [
                -73.93465515536495,
                40.82891285858112
              ],
              [
                -73.93465611022576,
                40.82894283638618
              ],
              [
                -73.93466495971363,
                40.82897356682644
              ],
              [
                -73.93466295225556,
                40.829014033814005
              ],
              [
                -73.9346619319834,
                40.829038760294466
              ],
              [
                -73.93464959243231,
                40.82904811940698
              ],
              [
                -73.93465447618146,
                40.829097576961054
              ],
              [
                -73.93467025222023,
                40.82911257042637
              ],
              [
                -73.93467714880197,
                40.82911632854789
              ],
              [
                -73.9346810792479,
                40.82913355828133
              ],
              [
                -73.93470080780727,
                40.82913207413399
              ],
              [
                -73.93470769402485,
                40.82915830979219
              ],
              [
                -73.93468302702506,
                40.82916578733352
              ],
              [
                -73.93466228987826,
                40.82918075994596
              ],
              [
                -73.93467597101821,
                40.82931340221053
              ],
              [
                -73.93469321669625,
                40.82932877360132
              ],
              [
                -73.93470504902284,
                40.82934077469047
              ],
              [
                -73.93471288560669,
                40.829400725944666
              ],
              [
                -73.93469412707354,
                40.82941645571865
              ],
              [
                -73.93470100252401,
                40.82944043080001
              ],
              [
                -73.9347147602574,
                40.829495144079715
              ],
              [
                -73.93472557699917,
                40.82952736927089
              ],
              [
                -73.93472260127821,
                40.829550600495594
              ],
              [
                -73.9347373734992,
                40.829574589425214
              ],
              [
                -73.93474919492763,
                40.82959708136341
              ],
              [
                -73.9347393083093,
                40.82960906144653
              ],
              [
                -73.9347437259127,
                40.82963716812018
              ],
              [
                -73.93476343288322,
                40.82965740394861
              ],
              [
                -73.93475944294418,
                40.82970012100012
              ],
              [
                -73.93477028229685,
                40.829720361694214
              ],
              [
                -73.9347811108578,
                40.82974059366992
              ],
              [
                -73.93479293827892,
                40.82975634075214
              ],
              [
                -73.934789962788,
                40.82976832407678
              ],
              [
                -73.93480080676927,
                40.82977283325494
              ],
              [
                -73.9347988190574,
                40.82979230914966
              ],
              [
                -73.93483087208486,
                40.82980843705177
              ],
              [
                -73.9348935333237,
                40.83028656383779
              ],
              [
                -73.93493515655025,
                40.83060244471324
              ],
              [
                -73.9349850562146,
                40.83102735335789
              ],
              [
                -73.93502275465511,
                40.83131999954982
              ],
              [
                -73.93506824974095,
                40.831704067625786
              ],
              [
                -73.93508783728582,
                40.83185919835002
              ],
              [
                -73.93509565184875,
                40.831942381267105
              ],
              [
                -73.93507789246162,
                40.83194162361134
              ],
              [
                -73.93511398341515,
                40.83236577683715
              ],
              [
                -73.93512878131628,
                40.83236353447693
              ],
              [
                -73.93515659173339,
                40.83268240673983
              ],
              [
                -73.93516480840945,
                40.832852883264295
              ],
              [
                -73.93516725294582,
                40.832874992778436
              ],
              [
                -73.93514455114395,
                40.83288472203645
              ],
              [
                -73.93513170518379,
                40.83290269767366
              ],
              [
                -73.93512085547357,
                40.83290419576853
              ],
              [
                -73.93512376770067,
                40.83294541299676
              ],
              [
                -73.93511783020558,
                40.83296713902453
              ],
              [
                -73.9351197936551,
                40.83298362817418
              ],
              [
                -73.93511187882297,
                40.833003100729265
              ],
              [
                -73.93511580946044,
                40.833021842172855
              ],
              [
                -73.93512171853354,
                40.83302859034758
              ],
              [
                -73.93512465984583,
                40.83305106902925
              ],
              [
                -73.93512752379401,
                40.8331424888091
              ],
              [
                -73.93513935847737,
                40.83315149100028
              ],
              [
                -73.93513243602646,
                40.83316272562037
              ],
              [
                -73.93511565892264,
                40.83317620588328
              ],
              [
                -73.93512352949845,
                40.833189699006276
              ],
              [
                -73.93512154973673,
                40.83320169277661
              ],
              [
                -73.93513040300608,
                40.83322866727349
              ],
              [
                -73.93513729127831,
                40.833241413188574
              ],
              [
                -73.93513826819031,
                40.83326014425818
              ],
              [
                -73.93512443552818,
                40.83328111868773
              ],
              [
                -73.93512935500891,
                40.83329460947995
              ],
              [
                -73.93512636041858,
                40.833326080356336
              ],
              [
                -73.93513225848749,
                40.83334482290501
              ],
              [
                -73.93512041704221,
                40.83335380357006
              ],
              [
                -73.93511743048032,
                40.83337703478394
              ],
              [
                -73.9351124510007,
                40.83342573907346
              ],
              [
                -73.9351038425559,
                40.83365765691793
              ],
              [
                -73.93509690469148,
                40.83368463190985
              ],
              [
                -73.93509687471914,
                40.833715356792226
              ],
              [
                -73.93508341363356,
                40.83386559692961
              ],
              [
                -73.93506958098223,
                40.833887317502196
              ],
              [
                -73.93507644502887,
                40.83392404328713
              ],
              [
                -73.93504212490862,
                40.8342113992345
              ],
              [
                -73.93503124118601,
                40.834248862849485
              ],
              [
                -73.93503612427708,
                40.834298320343365
              ],
              [
                -73.93503060323974,
                40.834392365341266
              ],
              [
                -73.93499389736655,
                40.834597666183
              ],
              [
                -73.93497219073525,
                40.83460814479754
              ],
              [
                -73.93497316061179,
                40.83462163504156
              ],
              [
                -73.93496624164149,
                40.83462912364824
              ],
              [
                -73.93496523015544,
                40.83465684795366
              ],
              [
                -73.93495775984093,
                40.83468276036335
              ],
              [
                -73.9349508717845,
                40.83470667336768
              ],
              [
                -73.93498833140688,
                40.83474865789363
              ],
              [
                -73.9349794120861,
                40.834789121453554
              ],
              [
                -73.93495859566912,
                40.83488277904472
              ],
              [
                -73.9349422585534,
                40.835026875417256
              ],
              [
                -73.93493620828455,
                40.83508021765153
              ],
              [
                -73.93491533578629,
                40.835230821938744
              ],
              [
                -73.9348946004199,
                40.83525404312232
              ],
              [
                -73.934869850678,
                40.835332715131045
              ],
              [
                -73.93484813571942,
                40.83535143220742
              ],
              [
                -73.93483181137556,
                40.83538552549233
              ],
              [
                -73.93481898168233,
                40.83539825039474
              ],
              [
                -73.93480514039254,
                40.835427472645925
              ],
              [
                -73.93475368707831,
                40.83556982111418
              ],
              [
                -73.934754664506,
                40.835589300341795
              ],
              [
                -73.93456051826952,
                40.83586608172803
              ],
              [
                -73.93445239398683,
                40.83598096007491
              ],
              [
                -73.93436121591056,
                40.83620061961779
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000379662912054,
        "objectid": 43,
        "shape_leng": 0.0997386183576,
        "location_id": 43,
        "zone": "Central Park",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.97255352299985,
                40.76489773199991
              ],
              [
                -73.97209852400005,
                40.76551725099991
              ],
              [
                -73.97164774299996,
                40.76614246799993
              ],
              [
                -73.97118812999996,
                40.766766592999915
              ],
              [
                -73.97072777399985,
                40.76739372199989
              ],
              [
                -73.97028165499981,
                40.768025031999905
              ],
              [
                -73.96982455299987,
                40.76864600899992
              ],
              [
                -73.96936484599993,
                40.76927065699993
              ],
              [
                -73.96890454699994,
                40.76990083199992
              ],
              [
                -73.96844155899997,
                40.77051632699992
              ],
              [
                -73.96799327799991,
                40.77114722999993
              ],
              [
                -73.96753398399983,
                40.77177618399991
              ],
              [
                -73.96703947599987,
                40.772461218999865
              ],
              [
                -73.96653616999981,
                40.7731403689999
              ],
              [
                -73.96607448999993,
                40.7737729889999
              ],
              [
                -73.96560968599992,
                40.77440720099987
              ],
              [
                -73.96514745399985,
                40.775044231999864
              ],
              [
                -73.96468735799992,
                40.77567708199995
              ],
              [
                -73.96422820200003,
                40.776310308999946
              ],
              [
                -73.9637612389999,
                40.77695096899987
              ],
              [
                -73.96372602699994,
                40.77700571799989
              ],
              [
                -73.96322991899993,
                40.7776770469999
              ],
              [
                -73.9627671559999,
                40.778313040999876
              ],
              [
                -73.96230361500002,
                40.77894704099987
              ],
              [
                -73.96183836899999,
                40.77958419199989
              ],
              [
                -73.961368727,
                40.78022974899989
              ],
              [
                -73.96091849500006,
                40.78085062899991
              ],
              [
                -73.96041638899996,
                40.78153163299988
              ],
              [
                -73.95992094300001,
                40.78221294399991
              ],
              [
                -73.95946905999989,
                40.78283332099994
              ],
              [
                -73.95900363199986,
                40.783467289999855
              ],
              [
                -73.9585581369999,
                40.78409759899993
              ],
              [
                -73.95809563799989,
                40.78471746199995
              ],
              [
                -73.95763599400006,
                40.78534345799993
              ],
              [
                -73.95716853699999,
                40.785982893999865
              ],
              [
                -73.95671863099989,
                40.786600792999934
              ],
              [
                -73.95626130899994,
                40.78722287599987
              ],
              [
                -73.9557773589999,
                40.78791392399995
              ],
              [
                -73.95527481200001,
                40.78858337899986
              ],
              [
                -73.95481723799992,
                40.789204743999974
              ],
              [
                -73.95439919199988,
                40.78977490899994
              ],
              [
                -73.95388917099996,
                40.790470498999866
              ],
              [
                -73.95343832999994,
                40.791085367999926
              ],
              [
                -73.95298394599986,
                40.79172143299998
              ],
              [
                -73.95252222700002,
                40.79234762199997
              ],
              [
                -73.95207222299993,
                40.792966160999896
              ],
              [
                -73.95160265399991,
                40.793607804999965
              ],
              [
                -73.95111102399997,
                40.79428503499988
              ],
              [
                -73.95061371799994,
                40.79496557399992
              ],
              [
                -73.95015821299991,
                40.79558773199993
              ],
              [
                -73.94969538299988,
                40.79621687199993
              ],
              [
                -73.94940516399991,
                40.79663170399987
              ],
              [
                -73.94922045699984,
                40.796909858999896
              ],
              [
                -73.94960357199982,
                40.797050270999925
              ],
              [
                -73.95237419099993,
                40.79820977099995
              ],
              [
                -73.95245200999996,
                40.79824023099996
              ],
              [
                -73.95253597599994,
                40.798274645999896
              ],
              [
                -73.95516503099992,
                40.79938223299992
              ],
              [
                -73.95521824499984,
                40.799404657999894
              ],
              [
                -73.95528884999997,
                40.79943440199994
              ],
              [
                -73.95536651699993,
                40.799466425999846
              ],
              [
                -73.95786499299992,
                40.8005152719999
              ],
              [
                -73.95817297099987,
                40.800582540999876
              ],
              [
                -73.95833304999988,
                40.80036505399989
              ],
              [
                -73.95861879299989,
                40.79997702599996
              ],
              [
                -73.95907669099992,
                40.79935223299986
              ],
              [
                -73.95953658899997,
                40.7987185259999
              ],
              [
                -73.96004456499999,
                40.79804123499991
              ],
              [
                -73.96052271699993,
                40.79736846899988
              ],
              [
                -73.96097971799988,
                40.796738643999916
              ],
              [
                -73.96144060699996,
                40.796110826999964
              ],
              [
                -73.96189985499994,
                40.795479269999916
              ],
              [
                -73.96235980199994,
                40.79485206099987
              ],
              [
                -73.96280590599986,
                40.79423581299993
              ],
              [
                -73.96371096499993,
                40.79301293399991
              ],
              [
                -73.96417598599989,
                40.792362044999884
              ],
              [
                -73.96468540699995,
                40.791664026999904
              ],
              [
                -73.96517705600003,
                40.79099034099997
              ],
              [
                -73.96563269799995,
                40.79036540399989
              ],
              [
                -73.96609500599993,
                40.78973438899991
              ],
              [
                -73.96655226699976,
                40.78910715299992
              ],
              [
                -73.96700977099991,
                40.788476789999905
              ],
              [
                -73.96744908399992,
                40.78786072099995
              ],
              [
                -73.9679269639999,
                40.78722157099988
              ],
              [
                -73.96838479399985,
                40.786595696999896
              ],
              [
                -73.96884378999985,
                40.785967388999964
              ],
              [
                -73.96933573299991,
                40.78529327999992
              ],
              [
                -73.96983225599998,
                40.784610910999916
              ],
              [
                -73.97030068199992,
                40.783975413999904
              ],
              [
                -73.97076013099995,
                40.783340137999865
              ],
              [
                -73.97122292299984,
                40.78270625599991
              ],
              [
                -73.97172305599985,
                40.78202147599997
              ],
              [
                -73.97357117499988,
                40.779477861999936
              ],
              [
                -73.97406668299993,
                40.77880541699987
              ],
              [
                -73.97453231499993,
                40.77816778499996
              ],
              [
                -73.97499744000005,
                40.77753254599988
              ],
              [
                -73.9754606719999,
                40.776898502999884
              ],
              [
                -73.97595517399988,
                40.7762197549999
              ],
              [
                -73.97645763799984,
                40.77553235199985
              ],
              [
                -73.97691168299995,
                40.774909123999905
              ],
              [
                -73.97737084999984,
                40.77428100699992
              ],
              [
                -73.97782639399986,
                40.77365619199991
              ],
              [
                -73.97828220099997,
                40.77303187899987
              ],
              [
                -73.978722468,
                40.77241889799995
              ],
              [
                -73.97919214499984,
                40.77178126499997
              ],
              [
                -73.97965233399994,
                40.77115532699992
              ],
              [
                -73.98011181599992,
                40.770528368999884
              ],
              [
                -73.980563707,
                40.769905324999904
              ],
              [
                -73.9810229009999,
                40.76927283399994
              ],
              [
                -73.98164804599995,
                40.76843632199994
              ],
              [
                -73.98154597399994,
                40.76838337499988
              ],
              [
                -73.98154591499996,
                40.76838333899988
              ],
              [
                -73.98145349899993,
                40.768320602999935
              ],
              [
                -73.98145334499989,
                40.768320494999934
              ],
              [
                -73.98145320300002,
                40.76832036999991
              ],
              [
                -73.98137263399991,
                40.768249315999924
              ],
              [
                -73.98132588999982,
                40.76818837999983
              ],
              [
                -73.98129467599998,
                40.7681188019999
              ],
              [
                -73.98128253299977,
                40.76804427399991
              ],
              [
                -73.98129085699999,
                40.76796955199996
              ],
              [
                -73.98129086899992,
                40.76796945299993
              ],
              [
                -73.9812909039998,
                40.76796936299995
              ],
              [
                -73.98131853299991,
                40.76789900199987
              ],
              [
                -73.98136213699995,
                40.76783673899992
              ],
              [
                -73.97907658799991,
                40.76687608599993
              ],
              [
                -73.97836113000002,
                40.766573710999886
              ],
              [
                -73.97623388799995,
                40.765674630999854
              ],
              [
                -73.9741922899999,
                40.764806527999916
              ],
              [
                -73.9736358469999,
                40.76456991299992
              ],
              [
                -73.97301487199995,
                40.76427887899991
              ],
              [
                -73.97255352299985,
                40.76489773199991
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.00194465649192,
        "objectid": 44,
        "shape_leng": 0.235688967594,
        "location_id": 44,
        "zone": "Charleston/Tottenville",
        "borough": "Staten Island"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -74.21220034099993,
                40.55644342999989
              ],
              [
                -74.21050789400002,
                40.557064181999934
              ],
              [
                -74.20880330699998,
                40.55730868899991
              ],
              [
                -74.20699885299997,
                40.55756748899989
              ],
              [
                -74.2056960519999,
                40.55851600599987
              ],
              [
                -74.21324031404319,
                40.55667262386913
              ],
              [
                -74.21327457291389,
                40.556624934276535
              ],
              [
                -74.21333332352269,
                40.556585957964494
              ],
              [
                -74.21339560620757,
                40.55655990370746
              ],
              [
                -74.21347392300072,
                40.55653991369645
              ],
              [
                -74.21352072182455,
                40.556533897969885
              ],
              [
                -74.21356439624225,
                40.55652928774992
              ],
              [
                -74.21359594595765,
                40.55652587997942
              ],
              [
                -74.2136290446141,
                40.5565118197531
              ],
              [
                -74.21366207475845,
                40.55648719216827
              ],
              [
                -74.213711569401,
                40.55643500351551
              ],
              [
                -74.21373503614481,
                40.55641330320351
              ],
              [
                -74.2137646731758,
                40.55639694332528
              ],
              [
                -74.21377719790834,
                40.556399062054936
              ],
              [
                -74.21379034458955,
                40.55640128835268
              ],
              [
                -74.21381634554184,
                40.556429263412554
              ],
              [
                -74.2138369925384,
                40.55647122130021
              ],
              [
                -74.21384686609589,
                40.556487670642085
              ],
              [
                -74.21386858605855,
                40.5564961264968
              ],
              [
                -74.21388755483308,
                40.55651381386745
              ],
              [
                -74.2139008816071,
                40.55654216549755
              ],
              [
                -74.21386359366392,
                40.55657448653227
              ],
              [
                -74.21386874413025,
                40.55658729585718
              ],
              [
                -74.21395537449884,
                40.5566571552995
              ],
              [
                -74.21396934069192,
                40.5566583358338
              ],
              [
                -74.21401027808201,
                40.55663081139325
              ],
              [
                -74.21405771378673,
                40.55663223210392
              ],
              [
                -74.21412277605752,
                40.556619732844226
              ],
              [
                -74.21416613986864,
                40.556588729413185
              ],
              [
                -74.21422698854253,
                40.55655731791701
              ],
              [
                -74.21428212817725,
                40.55663675207288
              ],
              [
                -74.21434447631304,
                40.55672657135455
              ],
              [
                -74.21443562607352,
                40.556700305678405
              ],
              [
                -74.21447533239655,
                40.55668886410159
              ],
              [
                -74.21449357509393,
                40.55672429854295
              ],
              [
                -74.21458359755573,
                40.5568991652
              ],
              [
                -74.21462340478293,
                40.55691336222088
              ],
              [
                -74.21499256964766,
                40.55679493279592
              ],
              [
                -74.21501817201802,
                40.55675563837995
              ],
              [
                -74.21497052999892,
                40.55665149155003
              ],
              [
                -74.21491217588893,
                40.556523927255625
              ],
              [
                -74.21474053198814,
                40.556242389132144
              ],
              [
                -74.2155682464129,
                40.55581309892443
              ],
              [
                -74.21585156387714,
                40.555658648973704
              ],
              [
                -74.21604246060625,
                40.55554411878309
              ],
              [
                -74.21624183551101,
                40.55548031523551
              ],
              [
                -74.2165473239861,
                40.555394038821106
              ],
              [
                -74.2167576874083,
                40.55530929281532
              ],
              [
                -74.21697226575563,
                40.5552309357784
              ],
              [
                -74.21719072566006,
                40.555159089440394
              ],
              [
                -74.21741272772677,
                40.55509386541668
              ],
              [
                -74.21775342391726,
                40.555005284776186
              ],
              [
                -74.2179149889178,
                40.555095043475475
              ],
              [
                -74.21807146383061,
                40.55529292882519
              ],
              [
                -74.21812855546021,
                40.55546527177832
              ],
              [
                -74.21812156459448,
                40.5555104137076
              ],
              [
                -74.21817360193401,
                40.555600523248486
              ],
              [
                -74.21826999195912,
                40.55569054965283
              ],
              [
                -74.21841519874964,
                40.55572762615413
              ],
              [
                -74.21865679372745,
                40.55603315036935
              ],
              [
                -74.21867044328168,
                40.55605041233874
              ],
              [
                -74.21870767560165,
                40.55604101620115
              ],
              [
                -74.2187503688055,
                40.556027740537246
              ],
              [
                -74.2188020251887,
                40.555955465544976
              ],
              [
                -74.21875399923744,
                40.555893469788025
              ],
              [
                -74.21881905965172,
                40.555874718986104
              ],
              [
                -74.2187974474673,
                40.555812983686295
              ],
              [
                -74.21875918633687,
                40.5556624132944
              ],
              [
                -74.2187007098444,
                40.55547612471537
              ],
              [
                -74.21863889566342,
                40.555270078066606
              ],
              [
                -74.2185676824065,
                40.555040253427286
              ],
              [
                -74.21853638146906,
                40.55493288147893
              ],
              [
                -74.21855087085385,
                40.55487364750934
              ],
              [
                -74.21856501731422,
                40.55482004237239
              ],
              [
                -74.21853272333956,
                40.554738884109376
              ],
              [
                -74.2185398175649,
                40.55470738813924
              ],
              [
                -74.21859325782798,
                40.55467421024778
              ],
              [
                -74.21878077485502,
                40.55467228928319
              ],
              [
                -74.21886973476656,
                40.55473879183282
              ],
              [
                -74.21891390920673,
                40.55477765609848
              ],
              [
                -74.2189583263338,
                40.55485758391833
              ],
              [
                -74.21899014747787,
                40.55504707931044
              ],
              [
                -74.21904407246122,
                40.55522799703139
              ],
              [
                -74.21906787202626,
                40.555352538926726
              ],
              [
                -74.21907068516887,
                40.555442015704514
              ],
              [
                -74.21907546885762,
                40.55550714053318
              ],
              [
                -74.21909202087238,
                40.555528710655224
              ],
              [
                -74.2191363295124,
                40.55553148016615
              ],
              [
                -74.21916939228602,
                40.55551736362477
              ],
              [
                -74.21917849469084,
                40.555528105387275
              ],
              [
                -74.21918905441791,
                40.555565553059594
              ],
              [
                -74.21918136219827,
                40.55565620165384
              ],
              [
                -74.2191796802851,
                40.55570297648615
              ],
              [
                -74.21918232634566,
                40.55575265322801
              ],
              [
                -74.21919443458171,
                40.555779445862484
              ],
              [
                -74.21921716370927,
                40.55579696987855
              ],
              [
                -74.21928639639636,
                40.555795382371926
              ],
              [
                -74.21930539588978,
                40.55575085204573
              ],
              [
                -74.21933701278975,
                40.55569136698107
              ],
              [
                -74.21932052662625,
                40.55561374815316
              ],
              [
                -74.21931015500938,
                40.55551218225155
              ],
              [
                -74.21929510878144,
                40.555489263849346
              ],
              [
                -74.2192522371676,
                40.555474607053796
              ],
              [
                -74.21920978196957,
                40.555455583606815
              ],
              [
                -74.21919554653131,
                40.55539464782617
              ],
              [
                -74.21923330127086,
                40.55526452631137
              ],
              [
                -74.21925824504427,
                40.555156195118784
              ],
              [
                -74.21923458499369,
                40.55511816968593
              ],
              [
                -74.21917339795966,
                40.55507143309305
              ],
              [
                -74.21912835054238,
                40.554974709799374
              ],
              [
                -74.21909005126736,
                40.554928065523654
              ],
              [
                -74.21908060899351,
                40.55484700219797
              ],
              [
                -74.21911014911431,
                40.55480129901592
              ],
              [
                -74.21917586317076,
                40.55475879414019
              ],
              [
                -74.21925552903423,
                40.55474546698497
              ],
              [
                -74.21932321285607,
                40.55475453231532
              ],
              [
                -74.21940883619042,
                40.55472532972465
              ],
              [
                -74.21948446867218,
                40.554722164800936
              ],
              [
                -74.21952987169979,
                40.55471864699885
              ],
              [
                -74.21956112618452,
                40.55470214632432
              ],
              [
                -74.21960026741088,
                40.5546547233884
              ],
              [
                -74.21967214720144,
                40.55462683585811
              ],
              [
                -74.21974356930723,
                40.5546126720724
              ],
              [
                -74.21980229197143,
                40.55462156378433
              ],
              [
                -74.21986518402704,
                40.55465078550192
              ],
              [
                -74.21992072431505,
                40.5546984378754
              ],
              [
                -74.21999334606815,
                40.554764503767466
              ],
              [
                -74.22005143156746,
                40.55484190727366
              ],
              [
                -74.22010627165115,
                40.554928813428425
              ],
              [
                -74.22014474318152,
                40.55500592293349
              ],
              [
                -74.22014369883041,
                40.555060135076836
              ],
              [
                -74.22013522631465,
                40.55506693914458
              ],
              [
                -74.22014316776006,
                40.555097674118095
              ],
              [
                -74.22016238732648,
                40.55515315834145
              ],
              [
                -74.22017936854515,
                40.55520864634992
              ],
              [
                -74.22018179076905,
                40.55526416277169
              ],
              [
                -74.22018310280139,
                40.55532224436833
              ],
              [
                -74.22017880896203,
                40.55537862790275
              ],
              [
                -74.22018456182457,
                40.55542559489575
              ],
              [
                -74.22021943697257,
                40.55547336145939
              ],
              [
                -74.22026655569555,
                40.55549889670825
              ],
              [
                -74.2202665585134,
                40.55549975144269
              ],
              [
                -74.22027782854286,
                40.55552108391514
              ],
              [
                -74.220285853057,
                40.55557744382515
              ],
              [
                -74.22029711808946,
                40.55559792106274
              ],
              [
                -74.22033301478737,
                40.555615790679816
              ],
              [
                -74.22040587158548,
                40.55563529615935
              ],
              [
                -74.22041823823064,
                40.55564979462587
              ],
              [
                -74.22042290601576,
                40.5557070145355
              ],
              [
                -74.22044538227212,
                40.55573088752429
              ],
              [
                -74.22045714043932,
                40.55574828911513
              ],
              [
                -74.22046901559654,
                40.55576586387414
              ],
              [
                -74.22049941794982,
                40.555816200309806
              ],
              [
                -74.22052870386395,
                40.55586739479367
              ],
              [
                -74.2205534015105,
                40.55588528417033
              ],
              [
                -74.22062175949124,
                40.55589882100514
              ],
              [
                -74.22069567868726,
                40.555901241526286
              ],
              [
                -74.22076962266105,
                40.55591049434238
              ],
              [
                -74.22082231656412,
                40.555928331013625
              ],
              [
                -74.22086715093401,
                40.555940202277675
              ],
              [
                -74.2209432973211,
                40.555938348007956
              ],
              [
                -74.22097904527033,
                40.555911800054425
              ],
              [
                -74.22099013412594,
                40.55587846698404
              ],
              [
                -74.22101010859353,
                40.555822907483076
              ],
              [
                -74.22102448950054,
                40.55576906654784
              ],
              [
                -74.2210399857696,
                40.5557143698967
              ],
              [
                -74.22105338275362,
                40.55570153122284
              ],
              [
                -74.22109369280587,
                40.55569974582382
              ],
              [
                -74.22115976704038,
                40.555700472841316
              ],
              [
                -74.2211832282346,
                40.55568334392007
              ],
              [
                -74.22119312650432,
                40.55562865735847
              ],
              [
                -74.22119742494193,
                40.55557398275408
              ],
              [
                -74.22120284421428,
                40.55551930599033
              ],
              [
                -74.22120713936611,
                40.55546377614885
              ],
              [
                -74.22121375057472,
                40.55543130541424
              ],
              [
                -74.22125504247906,
                40.555387663959266
              ],
              [
                -74.22129864604456,
                40.55536622468557
              ],
              [
                -74.22134677624108,
                40.55535844480242
              ],
              [
                -74.22140054953705,
                40.55536432047674
              ],
              [
                -74.22143195325388,
                40.55537877945291
              ],
              [
                -74.22145446439751,
                40.55541290352801
              ],
              [
                -74.22146243021915,
                40.5554513271694
              ],
              [
                -74.22145469372055,
                40.5554820906086
              ],
              [
                -74.22145260819535,
                40.55552907550155
              ],
              [
                -74.22147280956294,
                40.55554184876521
              ],
              [
                -74.22149377308864,
                40.555562037124
              ],
              [
                -74.22149876696064,
                40.555592055800325
              ],
              [
                -74.22154450180608,
                40.555634209189904
              ],
              [
                -74.22157006613187,
                40.55564898295767
              ],
              [
                -74.22158844541158,
                40.555646202680364
              ],
              [
                -74.22160298618641,
                40.55563621042138
              ],
              [
                -74.22165396875167,
                40.55558920370556
              ],
              [
                -74.22166724221145,
                40.55555519773824
              ],
              [
                -74.22169675758535,
                40.55550307048974
              ],
              [
                -74.22174872671057,
                40.55543934531281
              ],
              [
                -74.22179863887057,
                40.55541707075901
              ],
              [
                -74.22190184151718,
                40.55539337577119
              ],
              [
                -74.22201112709658,
                40.55540271596946
              ],
              [
                -74.2221224448784,
                40.55541688471409
              ],
              [
                -74.22219699760915,
                40.55544589044552
              ],
              [
                -74.22221727566942,
                40.55546524335244
              ],
              [
                -74.22222452335853,
                40.55546612193036
              ],
              [
                -74.22224752905595,
                40.55547030939683
              ],
              [
                -74.22232036636152,
                40.555483835379874
              ],
              [
                -74.22237077098863,
                40.55548715457684
              ],
              [
                -74.22242225993622,
                40.55547936773957
              ],
              [
                -74.22247482113684,
                40.55545705660065
              ],
              [
                -74.22251833048998,
                40.55542682203717
              ],
              [
                -74.22257486801371,
                40.555406209779626
              ],
              [
                -74.22265568834065,
                40.5554153306156
              ],
              [
                -74.22273960124298,
                40.55543418896245
              ],
              [
                -74.22281876589037,
                40.55545396330239
              ],
              [
                -74.22288310030724,
                40.55547286003057
              ],
              [
                -74.22297219698756,
                40.55550337362143
              ],
              [
                -74.22297119575062,
                40.5555372406546
              ],
              [
                -74.2230015599747,
                40.55557561902707
              ],
              [
                -74.22304228365793,
                40.55559579285772
              ],
              [
                -74.22309672573029,
                40.555636942134285
              ],
              [
                -74.22310353721308,
                40.55568306927582
              ],
              [
                -74.2231129667092,
                40.55573684009674
              ],
              [
                -74.22312986736561,
                40.55576755796204
              ],
              [
                -74.2231612894273,
                40.555787142691706
              ],
              [
                -74.2231947052717,
                40.5557955609906
              ],
              [
                -74.22378606709124,
                40.55588026800973
              ],
              [
                -74.22377438375146,
                40.555964760418526
              ],
              [
                -74.22375741210328,
                40.5559810256695
              ],
              [
                -74.22371910444983,
                40.55598110029811
              ],
              [
                -74.22347210933358,
                40.55594586956391
              ],
              [
                -74.22344265106311,
                40.556046569903536
              ],
              [
                -74.22386421498082,
                40.55609769408288
              ],
              [
                -74.2239033413449,
                40.55589706554456
              ],
              [
                -74.22606854510708,
                40.55621187928375
              ],
              [
                -74.22611420492586,
                40.55625236709394
              ],
              [
                -74.22618692147473,
                40.55622908896703
              ],
              [
                -74.22633201675346,
                40.55621570978148
              ],
              [
                -74.22637020813472,
                40.55624980149111
              ],
              [
                -74.22640494542956,
                40.55625571284586
              ],
              [
                -74.22647661167676,
                40.55625386206437
              ],
              [
                -74.22653700730443,
                40.55623067988212
              ],
              [
                -74.22657299342214,
                40.55619517437838
              ],
              [
                -74.22657942850441,
                40.55615847286758
              ],
              [
                -74.2266100107086,
                40.55615007370459
              ],
              [
                -74.2266734121504,
                40.55614828082246
              ],
              [
                -74.22673731479559,
                40.55613314664004
              ],
              [
                -74.22676949190152,
                40.556110985686836
              ],
              [
                -74.2267957140918,
                40.55610634675829
              ],
              [
                -74.2268383534228,
                40.55610709751939
              ],
              [
                -74.22687006826536,
                40.55611078705189
              ],
              [
                -74.2269023090303,
                40.55610738682401
              ],
              [
                -74.22692497792441,
                40.556101244097164
              ],
              [
                -74.22708256217496,
                40.556058539395686
              ],
              [
                -74.22724124339234,
                40.55603541174922
              ],
              [
                -74.22743682730538,
                40.55603853351637
              ],
              [
                -74.22755928210492,
                40.55608836862557
              ],
              [
                -74.227632728344,
                40.556146591495
              ],
              [
                -74.22770173052456,
                40.556183979167145
              ],
              [
                -74.2278330723504,
                40.55622874465361
              ],
              [
                -74.22797312564695,
                40.55626348895695
              ],
              [
                -74.22801360696909,
                40.55627258121724
              ],
              [
                -74.22804538468095,
                40.55629419722321
              ],
              [
                -74.22807285538782,
                40.55633500148824
              ],
              [
                -74.22810792998999,
                40.55636161422417
              ],
              [
                -74.22816154992297,
                40.556376516926626
              ],
              [
                -74.22821296402216,
                40.556385587396726
              ],
              [
                -74.228267637297,
                40.556388814775374
              ],
              [
                -74.22833214371997,
                40.55639035431146
              ],
              [
                -74.22836927678098,
                40.5563794400923
              ],
              [
                -74.22839545566364,
                40.556361876754174
              ],
              [
                -74.22841287834882,
                40.55634183009893
              ],
              [
                -74.2284412209779,
                40.55631759239245
              ],
              [
                -74.22846525096287,
                40.556311707499106
              ],
              [
                -74.22848274556276,
                40.55631250716476
              ],
              [
                -74.22854505831708,
                40.55631154871156
              ],
              [
                -74.22862814038024,
                40.55631055005349
              ],
              [
                -74.2287242997478,
                40.5562970171392
              ],
              [
                -74.22881598883029,
                40.5562551428902
              ],
              [
                -74.22890104752673,
                40.55619243379969
              ],
              [
                -74.22894905874807,
                40.55616565570325
              ],
              [
                -74.22899926815344,
                40.55614220823081
              ],
              [
                -74.22905162187638,
                40.55610708210508
              ],
              [
                -74.22907443408064,
                40.55606451002411
              ],
              [
                -74.2290830609419,
                40.556029471763054
              ],
              [
                -74.22907639765481,
                40.55599946662507
              ],
              [
                -74.2290729956406,
                40.55596361839084
              ],
              [
                -74.22909039712206,
                40.55593690078144
              ],
              [
                -74.22910541631074,
                40.55591556547958
              ],
              [
                -74.22914086703469,
                40.555872787573854
              ],
              [
                -74.22915318239791,
                40.55587824762296
              ],
              [
                -74.22916646569585,
                40.55588217159345
              ],
              [
                -74.22918039008535,
                40.55588446293385
              ],
              [
                -74.2291946129487,
                40.55588506526435
              ],
              [
                -74.22920878432424,
                40.55588396376425
              ],
              [
                -74.22922255551686,
                40.55588118553645
              ],
              [
                -74.22923558767847,
                40.55587679894113
              ],
              [
                -74.22924756014524,
                40.55587091191284
              ],
              [
                -74.22928333871313,
                40.555846280209096
              ],
              [
                -74.22931328089027,
                40.55581747283004
              ],
              [
                -74.2293365613129,
                40.55578528386583
              ],
              [
                -74.22935253825536,
                40.55575060062034
              ],
              [
                -74.22935110843282,
                40.555737426672664
              ],
              [
                -74.2293469612076,
                40.555724591707694
              ],
              [
                -74.22934020126353,
                40.55571241969942
              ],
              [
                -74.22933099923318,
                40.55570121788776
              ],
              [
                -74.22931958739098,
                40.55569126902326
              ],
              [
                -74.229308643603,
                40.555684145033204
              ],
              [
                -74.22929656579723,
                40.5556781815328
              ],
              [
                -74.22928356407323,
                40.55567348226014
              ],
              [
                -74.22926986460222,
                40.555670128961175
              ],
              [
                -74.22925226143866,
                40.55563764343743
              ],
              [
                -74.22924779391403,
                40.5556101361591
              ],
              [
                -74.22924873918484,
                40.55556677439197
              ],
              [
                -74.2292737490792,
                40.55552753391469
              ],
              [
                -74.22931954185714,
                40.55549158696269
              ],
              [
                -74.22937963425443,
                40.555481459864595
              ],
              [
                -74.22947149429736,
                40.55548961568595
              ],
              [
                -74.2296049689339,
                40.55551853287796
              ],
              [
                -74.22968040863911,
                40.55552088426538
              ],
              [
                -74.22978862412833,
                40.555516498968814
              ],
              [
                -74.2299262674491,
                40.55548620505377
              ],
              [
                -74.23004306465734,
                40.55543468860076
              ],
              [
                -74.23017519392035,
                40.55539023118725
              ],
              [
                -74.23024952153503,
                40.55538674616262
              ],
              [
                -74.23034473534743,
                40.55541657366522
              ],
              [
                -74.23042469847942,
                40.55546227587578
              ],
              [
                -74.23045979656835,
                40.55549555823949
              ],
              [
                -74.23050804631487,
                40.55553798799348
              ],
              [
                -74.23055514386452,
                40.555563741384155
              ],
              [
                -74.23060876282467,
                40.55557864294689
              ],
              [
                -74.23065576101537,
                40.55557521420865
              ],
              [
                -74.2307103619811,
                40.55555759425892
              ],
              [
                -74.23075511246024,
                40.55553665854545
              ],
              [
                -74.23078781040344,
                40.55550824217585
              ],
              [
                -74.23084007911194,
                40.55544809858915
              ],
              [
                -74.23087472791508,
                40.55535130375602
              ],
              [
                -74.23098961854346,
                40.55514725306873
              ],
              [
                -74.23106943792601,
                40.55504383345953
              ],
              [
                -74.23114456149607,
                40.554945800970614
              ],
              [
                -74.23121793211067,
                40.55486078404755
              ],
              [
                -74.23135620243775,
                40.55474173339223
              ],
              [
                -74.23150063118818,
                40.55458893807303
              ],
              [
                -74.23156232580645,
                40.55453177913725
              ],
              [
                -74.2316830032468,
                40.55441163222109
              ],
              [
                -74.2318159763953,
                40.55427609480235
              ],
              [
                -74.23188707136137,
                40.55421332002534
              ],
              [
                -74.23193331444938,
                40.554149830117154
              ],
              [
                -74.23195537304143,
                40.554078235835675
              ],
              [
                -74.2319554487189,
                40.554021520100555
              ],
              [
                -74.23196648230734,
                40.553964192022754
              ],
              [
                -74.23200263530478,
                40.553896012849336
              ],
              [
                -74.2320603723538,
                40.55380877735873
              ],
              [
                -74.2320998330062,
                40.553743336288335
              ],
              [
                -74.23212315508638,
                40.553686914492005
              ],
              [
                -74.23214645415379,
                40.55362451273828
              ],
              [
                -74.23216532632797,
                40.55357664012657
              ],
              [
                -74.23218421497464,
                40.55353389474701
              ],
              [
                -74.23220311678335,
                40.553494564445494
              ],
              [
                -74.23222314817686,
                40.553458648639754
              ],
              [
                -74.23224430607304,
                40.55342443886005
              ],
              [
                -74.2322665927849,
                40.55339279000899
              ],
              [
                -74.23229112933164,
                40.55336369797566
              ],
              [
                -74.23239598706299,
                40.55324646599035
              ],
              [
                -74.2325555310309,
                40.55307616295531
              ],
              [
                -74.23264143423904,
                40.55298288476354
              ],
              [
                -74.23269165371474,
                40.55293324007988
              ],
              [
                -74.23269611466445,
                40.55292810594972
              ],
              [
                -74.23270282250361,
                40.5529246767473
              ],
              [
                -74.23271847305597,
                40.55291695672651
              ],
              [
                -74.23273637215112,
                40.5529117953626
              ],
              [
                -74.2327889597488,
                40.552898876551694
              ],
              [
                -74.23280350017373,
                40.55289372131527
              ],
              [
                -74.23281020734505,
                40.552890292107826
              ],
              [
                -74.23281579691063,
                40.55288771823065
              ],
              [
                -74.23291523611293,
                40.55282259888439
              ],
              [
                -74.23295545476876,
                40.552795184380805
              ],
              [
                -74.23299007634827,
                40.55276863545614
              ],
              [
                -74.23318551828129,
                40.55261704975136
              ],
              [
                -74.23330947861339,
                40.55251942275054
              ],
              [
                -74.23332400080318,
                40.55250914356582
              ],
              [
                -74.23334188482497,
                40.552499710613695
              ],
              [
                -74.23335977547116,
                40.55249198712617
              ],
              [
                -74.23338102628746,
                40.55248425680714
              ],
              [
                -74.23340452091124,
                40.5524782297294
              ],
              [
                -74.23342913886752,
                40.55247305510378
              ],
              [
                -74.23345711931373,
                40.552468726200104
              ],
              [
                -74.23348622564309,
                40.55246610498369
              ],
              [
                -74.23350973947808,
                40.55246520243436
              ],
              [
                -74.23353549801199,
                40.55246600479627
              ],
              [
                -74.23356462611525,
                40.55246936234264
              ],
              [
                -74.23360048067302,
                40.552475268745255
              ],
              [
                -74.23368676337617,
                40.552491322213896
              ],
              [
                -74.23374952550594,
                40.55250571568869
              ],
              [
                -74.23377755011934,
                40.552514200518935
              ],
              [
                -74.23380333579374,
                40.55252268990046
              ],
              [
                -74.23382688696722,
                40.55253289163132
              ],
              [
                -74.23384932109046,
                40.55254309563206
              ],
              [
                -74.23389532026982,
                40.55256777284106
              ],
              [
                -74.23394020898154,
                40.55259501500708
              ],
              [
                -74.23398398867991,
                40.552625675361085
              ],
              [
                -74.23402778170795,
                40.552659753627665
              ],
              [
                -74.23407943768392,
                40.552700648245356
              ],
              [
                -74.23410528033038,
                40.55272536645318
              ],
              [
                -74.23410865482435,
                40.55272963109826
              ],
              [
                -74.23411203164893,
                40.55273474880341
              ],
              [
                -74.23412330862575,
                40.55275693415352
              ],
              [
                -74.23412668245935,
                40.552761197626616
              ],
              [
                -74.23413117753192,
                40.55276545998652
              ],
              [
                -74.23413454837392,
                40.5527688693952
              ],
              [
                -74.23413904045152,
                40.55277227685298
              ],
              [
                -74.2341502629073,
                40.552779088041795
              ],
              [
                -74.23418615061145,
                40.552794389134604
              ],
              [
                -74.23420521117521,
                40.552801183831754
              ],
              [
                -74.23422650945938,
                40.552807119221455
              ],
              [
                -74.23430159423013,
                40.55282319607221
              ],
              [
                -74.23432737110322,
                40.55282912280845
              ],
              [
                -74.23435091567323,
                40.55283676189963
              ],
              [
                -74.23437110451499,
                40.55284611731526
              ],
              [
                -74.23442608142095,
                40.55287590057867
              ],
              [
                -74.23448443889805,
                40.55291165674524
              ],
              [
                -74.2344911722997,
                40.55291591334802
              ],
              [
                -74.23451026378798,
                40.55293124980671
              ],
              [
                -74.23451362931992,
                40.552932950739624
              ],
              [
                -74.23451699485459,
                40.55293465234248
              ],
              [
                -74.23452035973143,
                40.55293635428168
              ],
              [
                -74.23452372226804,
                40.552937201652725
              ],
              [
                -74.23452708414345,
                40.552938048354854
              ],
              [
                -74.23453044368168,
                40.552938041494045
              ],
              [
                -74.23453828333646,
                40.55293802548362
              ],
              [
                -74.23456067074976,
                40.55293541721451
              ],
              [
                -74.2345685074059,
                40.55293454713789
              ],
              [
                -74.23460434130727,
                40.55293447394033
              ],
              [
                -74.23464802086423,
                40.55293609351203
              ],
              [
                -74.2346581018197,
                40.5529369271517
              ],
              [
                -74.23466818577356,
                40.55293861435206
              ],
              [
                -74.23469060684636,
                40.55294540260436
              ],
              [
                -74.2347107919558,
                40.55295304731452
              ],
              [
                -74.23476014439508,
                40.55297600901609
              ],
              [
                -74.23477472348716,
                40.55298195904458
              ],
              [
                -74.2347870569584,
                40.55298620535424
              ],
              [
                -74.23483637407898,
                40.552998916241904
              ],
              [
                -74.23486887015231,
                40.55300482961923
              ],
              [
                -74.2348845511624,
                40.55300565178593
              ],
              [
                -74.23489865800803,
                40.55300521667134
              ],
              [
                -74.236370377967,
                40.552360318234236
              ],
              [
                -74.23641559733107,
                40.55232806634516
              ],
              [
                -74.23645444051985,
                40.55229129631126
              ],
              [
                -74.23648613990993,
                40.55225073479606
              ],
              [
                -74.23651006906039,
                40.552207183390635
              ],
              [
                -74.23652575509128,
                40.5521615027716
              ],
              [
                -74.23653288802811,
                40.55211459569195
              ],
              [
                -74.23653132692615,
                40.55206738914062
              ],
              [
                -74.23652110265431,
                40.55202081602275
              ],
              [
                -74.23650241728404,
                40.551975796723184
              ],
              [
                -74.23647564009453,
                40.551933220918755
              ],
              [
                -74.23644130027341,
                40.5518939299957
              ],
              [
                -74.23640007645811,
                40.551858700422756
              ],
              [
                -74.2363527833242,
                40.55182822840691
              ],
              [
                -74.23630443796945,
                40.551747695907196
              ],
              [
                -74.23626103691412,
                40.551665541116044
              ],
              [
                -74.23622267338287,
                40.55158194053546
              ],
              [
                -74.23618942977693,
                40.55149707377276
              ],
              [
                -74.2361803753202,
                40.55146975946147
              ],
              [
                -74.23617582954002,
                40.55145097725125
              ],
              [
                -74.23617462444652,
                40.55142706190519
              ],
              [
                -74.2361767365552,
                40.55139118392413
              ],
              [
                -74.23617995866753,
                40.551351885200496
              ],
              [
                -74.2361865266174,
                40.551309162811265
              ],
              [
                -74.23619753075485,
                40.55125447330633
              ],
              [
                -74.2362096640445,
                40.55120234452641
              ],
              [
                -74.23622070827703,
                40.55115875818673
              ],
              [
                -74.23624167918048,
                40.55107244438619
              ],
              [
                -74.23624608904953,
                40.55105278884636
              ],
              [
                -74.23626812002584,
                40.55094938960761
              ],
              [
                -74.2362894598702,
                40.550855337809296
              ],
              [
                -74.23628500667785,
                40.550805533455616
              ],
              [
                -74.23628213170987,
                40.55074652574997
              ],
              [
                -74.23629187216656,
                40.55069389074656
              ],
              [
                -74.23632235506919,
                40.55064387176757
              ],
              [
                -74.23635240725108,
                40.550609814799515
              ],
              [
                -74.23638071914459,
                40.55057944389348
              ],
              [
                -74.2363957993608,
                40.55054891677278
              ],
              [
                -74.23641623448965,
                40.5505086268434
              ],
              [
                -74.2364594704942,
                40.55046627256133
              ],
              [
                -74.23650170138596,
                40.550430484847816
              ],
              [
                -74.23654610900078,
                40.55041114664288
              ],
              [
                -74.2366031550018,
                40.55040096003789
              ],
              [
                -74.23662978347146,
                40.55039620493581
              ],
              [
                -74.23669255497087,
                40.55038515995681
              ],
              [
                -74.23675493076693,
                40.55035039947159
              ],
              [
                -74.23681792735469,
                40.55032145500105
              ],
              [
                -74.23688278114177,
                40.55029792275851
              ],
              [
                -74.23693199895314,
                40.550287718631196
              ],
              [
                -74.23698467413543,
                40.55027794879077
              ],
              [
                -74.2370364339829,
                40.550267585077094
              ],
              [
                -74.23708755767899,
                40.55027124596175
              ],
              [
                -74.23713313596454,
                40.550262892265124
              ],
              [
                -74.23717117913401,
                40.550235820516654
              ],
              [
                -74.23719811959242,
                40.55021658899718
              ],
              [
                -74.23722616127561,
                40.55020769601696
              ],
              [
                -74.2372628389899,
                40.550215828903355
              ],
              [
                -74.23731918162642,
                40.55022046599174
              ],
              [
                -74.23736517465862,
                40.5502159885042
              ],
              [
                -74.23740672481237,
                40.55020983549644
              ],
              [
                -74.2374649628325,
                40.55020427319093
              ],
              [
                -74.23751715838252,
                40.550189981109085
              ],
              [
                -74.2375608091404,
                40.55016353676154
              ],
              [
                -74.23761963467327,
                40.55012346857853
              ],
              [
                -74.23766529763601,
                40.55008388982374
              ],
              [
                -74.23771157820656,
                40.550038095562854
              ],
              [
                -74.23776472013473,
                40.54997268902291
              ],
              [
                -74.23780980173328,
                40.54989965154934
              ],
              [
                -74.23781194433836,
                40.5498960519075
              ],
              [
                -74.23790310005221,
                40.549742976092844
              ],
              [
                -74.2379484587301,
                40.549684554691396
              ],
              [
                -74.23801045315936,
                40.54959421002949
              ],
              [
                -74.23810087098671,
                40.54945852675722
              ],
              [
                -74.23814886177914,
                40.54939278761241
              ],
              [
                -74.23822562357557,
                40.549309006876705
              ],
              [
                -74.238281079861,
                40.54924931059415
              ],
              [
                -74.2383301218049,
                40.54918545709211
              ],
              [
                -74.23837191304226,
                40.549141566814335
              ],
              [
                -74.23841922458453,
                40.549105462600856
              ],
              [
                -74.23847241578378,
                40.54906054025084
              ],
              [
                -74.23852526847641,
                40.54902441728221
              ],
              [
                -74.23858723148467,
                40.54899781203674
              ],
              [
                -74.23865414487864,
                40.54898163177886
              ],
              [
                -74.23870672648498,
                40.54896698971904
              ],
              [
                -74.23876882860974,
                40.54894167524375
              ],
              [
                -74.2388802047475,
                40.54885867414831
              ],
              [
                -74.23902299983374,
                40.548730473317214
              ],
              [
                -74.23906950439633,
                40.54867881010936
              ],
              [
                -74.23910193115215,
                40.54862704334345
              ],
              [
                -74.23912563695845,
                40.54858947669096
              ],
              [
                -74.23915003219383,
                40.54855837041808
              ],
              [
                -74.23918633439715,
                40.548534981931034
              ],
              [
                -74.23922270854456,
                40.54852427333402
              ],
              [
                -74.23924448067316,
                40.54852454745698
              ],
              [
                -74.23926751220235,
                40.54852864853233
              ],
              [
                -74.23928907288798,
                40.54851495422356
              ],
              [
                -74.23930273251601,
                40.54848711518695
              ],
              [
                -74.23931414637477,
                40.54845421336512
              ],
              [
                -74.23934085340967,
                40.54841678532669
              ],
              [
                -74.23939195750435,
                40.54836028483921
              ],
              [
                -74.2394274566754,
                40.54833336824826
              ],
              [
                -74.23945746357062,
                40.548314921883666
              ],
              [
                -74.23949951309932,
                40.54829345310667
              ],
              [
                -74.2395420884618,
                40.54827292758687
              ],
              [
                -74.23959313085258,
                40.54823984422176
              ],
              [
                -74.23962496620842,
                40.54821055309667
              ],
              [
                -74.23970065441213,
                40.54814472315815
              ],
              [
                -74.23975764471815,
                40.54810346588541
              ],
              [
                -74.23979131363883,
                40.54807536219499
              ],
              [
                -74.23981864192723,
                40.54805578178585
              ],
              [
                -74.23984372828757,
                40.54804316962738
              ],
              [
                -74.23987753787303,
                40.54802838999228
              ],
              [
                -74.23991253323271,
                40.548004757063424
              ],
              [
                -74.23994072994778,
                40.54797731796285
              ],
              [
                -74.23996167162653,
                40.54795780799162
              ],
              [
                -74.23998281879149,
                40.54794023641819
              ],
              [
                -74.24003491309954,
                40.54790903973106
              ],
              [
                -74.24012539879432,
                40.54785965621242
              ],
              [
                -74.2402039240967,
                40.547802152137876
              ],
              [
                -74.24023730218572,
                40.54777858665622
              ],
              [
                -74.24027644117172,
                40.54774575907881
              ],
              [
                -74.24031300590508,
                40.54771774081376
              ],
              [
                -74.24035341572257,
                40.54768795675831
              ],
              [
                -74.24039182810051,
                40.54766331017379
              ],
              [
                -74.24045847559482,
                40.54762548430011
              ],
              [
                -74.24057633072829,
                40.54756929849615
              ],
              [
                -74.24063341370834,
                40.54754326727556
              ],
              [
                -74.24072363579138,
                40.54750723132931
              ],
              [
                -74.24076977132052,
                40.54748918353886
              ],
              [
                -74.24082703907891,
                40.54745456103449
              ],
              [
                -74.24086739059267,
                40.54742905933522
              ],
              [
                -74.24093642651823,
                40.547394988563624
              ],
              [
                -74.24097422534163,
                40.547380086647756
              ],
              [
                -74.24101667065474,
                40.547366109285115
              ],
              [
                -74.24107266988072,
                40.54733933846189
              ],
              [
                -74.24112113404507,
                40.54730833066991
              ],
              [
                -74.24117275175594,
                40.547283738350906
              ],
              [
                -74.24122936796708,
                40.54725812060806
              ],
              [
                -74.24128029509164,
                40.54723163475298
              ],
              [
                -74.24133944836846,
                40.5472112811313
              ],
              [
                -74.24140253504106,
                40.5471841400858
              ],
              [
                -74.24145107546943,
                40.54716476933669
              ],
              [
                -74.24150964965952,
                40.547133217426044
              ],
              [
                -74.24160691885201,
                40.54709601318317
              ],
              [
                -74.24169335448103,
                40.547068355605575
              ],
              [
                -74.24172117606939,
                40.54705051065866
              ],
              [
                -74.24179850544287,
                40.54700162778558
              ],
              [
                -74.24185443321967,
                40.5469849583204
              ],
              [
                -74.24190290276809,
                40.54697568895227
              ],
              [
                -74.24196459767579,
                40.546971522990894
              ],
              [
                -74.24204967635751,
                40.54695597326009
              ],
              [
                -74.24211830776133,
                40.546949845386756
              ],
              [
                -74.2422031846343,
                40.54694867830012
              ],
              [
                -74.24222961262026,
                40.54695380913411
              ],
              [
                -74.24229800775315,
                40.54696132400164
              ],
              [
                -74.2423172680376,
                40.54696401276844
              ],
              [
                -74.24266546073754,
                40.54701079971152
              ],
              [
                -74.24293585929026,
                40.54711321924521
              ],
              [
                -74.24358447651343,
                40.547623990878584
              ],
              [
                -74.24335033310243,
                40.5478036175284
              ],
              [
                -74.24334063308854,
                40.54779871503061
              ],
              [
                -74.24329878677618,
                40.5478283445317
              ],
              [
                -74.24336346552776,
                40.547865135235824
              ],
              [
                -74.2434020856379,
                40.54783551137688
              ],
              [
                -74.24336648769979,
                40.54780850751905
              ],
              [
                -74.24360202725677,
                40.54763781110733
              ],
              [
                -74.24363807144752,
                40.547666195235344
              ],
              [
                -74.24618422383882,
                40.54563362585427
              ],
              [
                -74.24761962158414,
                40.543509361402414
              ],
              [
                -74.24770573967564,
                40.54352432645975
              ],
              [
                -74.24803463735688,
                40.54309324044144
              ],
              [
                -74.24792244709678,
                40.543049119271366
              ],
              [
                -74.24767778039599,
                40.54312540330282
              ],
              [
                -74.2468625313222,
                40.54251010144366
              ],
              [
                -74.24677815425639,
                40.54242325599929
              ],
              [
                -74.24676193481058,
                40.542366799685
              ],
              [
                -74.24664837060041,
                40.54229751360104
              ],
              [
                -74.24662220136648,
                40.54227783118102
              ],
              [
                -74.24659287581972,
                40.542262435271795
              ],
              [
                -74.24657486530842,
                40.54225014754584
              ],
              [
                -74.24653391966409,
                40.54224013504272
              ],
              [
                -74.2465138936479,
                40.542234331050516
              ],
              [
                -74.24648614815786,
                40.542213392655654
              ],
              [
                -74.24646660694735,
                40.54219273920428
              ],
              [
                -74.24640677149628,
                40.54212419161222
              ],
              [
                -74.24635676349392,
                40.54206635625874
              ],
              [
                -74.24632094551905,
                40.542022103147715
              ],
              [
                -74.2462602701756,
                40.54193061177822
              ],
              [
                -74.24621414027527,
                40.54184134029696
              ],
              [
                -74.24619797483028,
                40.54180914959797
              ],
              [
                -74.24614764964227,
                40.54168464315558
              ],
              [
                -74.24610313832198,
                40.54153712143066
              ],
              [
                -74.24608152745897,
                40.54146237133474
              ],
              [
                -74.24606137187132,
                40.54140156236369
              ],
              [
                -74.24604749879235,
                40.541366512677705
              ],
              [
                -74.24599284790567,
                40.54127893215784
              ],
              [
                -74.24584508121524,
                40.54108307675139
              ],
              [
                -74.24583169314688,
                40.54106282063416
              ],
              [
                -74.24582363793573,
                40.54105394518426
              ],
              [
                -74.24580741667182,
                40.54104558076123
              ],
              [
                -74.24578384879587,
                40.54103531182544
              ],
              [
                -74.24575714989656,
                40.5410244434605
              ],
              [
                -74.2457307294372,
                40.54101159930661
              ],
              [
                -74.24570589382073,
                40.54099905314306
              ],
              [
                -74.24568077627616,
                40.54098848510945
              ],
              [
                -74.24563856476077,
                40.54097622371919
              ],
              [
                -74.24562047939826,
                40.540969538061745
              ],
              [
                -74.2456077070552,
                40.540959766346376
              ],
              [
                -74.24560205698324,
                40.54094959457726
              ],
              [
                -74.24559250992388,
                40.54092675074653
              ],
              [
                -74.24551910321269,
                40.540757606928054
              ],
              [
                -74.24547647854257,
                40.54063300079163
              ],
              [
                -74.24545378722226,
                40.54051850790558
              ],
              [
                -74.24542969776729,
                40.54038576486477
              ],
              [
                -74.24541276701893,
                40.54021356393067
              ],
              [
                -74.2454218831116,
                40.539972301430694
              ],
              [
                -74.24542566851937,
                40.539896015276945
              ],
              [
                -74.24542908073474,
                40.53984547469635
              ],
              [
                -74.24543254761085,
                40.539800013026664
              ],
              [
                -74.24542947177183,
                40.53977561925903
              ],
              [
                -74.2454232990036,
                40.53975062598475
              ],
              [
                -74.2454172398465,
                40.53971704283383
              ],
              [
                -74.24540252861892,
                40.539688693405225
              ],
              [
                -74.245380975995,
                40.539658792853935
              ],
              [
                -74.24536604154534,
                40.53963823926825
              ],
              [
                -74.24536803597358,
                40.539622891372794
              ],
              [
                -74.24538703564416,
                40.5396041855044
              ],
              [
                -74.24540210010908,
                40.53959155449746
              ],
              [
                -74.24539945360863,
                40.5395761347652
              ],
              [
                -74.2453961907736,
                40.53955953559394
              ],
              [
                -74.24538824752977,
                40.53954209603954
              ],
              [
                -74.24537245551109,
                40.53952313448881
              ],
              [
                -74.24535392271066,
                40.539502423238325
              ],
              [
                -74.24533072729713,
                40.53948589463462
              ],
              [
                -74.2453161460591,
                40.53947349115842
              ],
              [
                -74.24530622799543,
                40.53945693367856
              ],
              [
                -74.24530650812298,
                40.539444829489995
              ],
              [
                -74.24533131467827,
                40.53940088562488
              ],
              [
                -74.24527100769255,
                40.539241870189834
              ],
              [
                -74.24523157838199,
                40.539144485218735
              ],
              [
                -74.24518150342622,
                40.5390579380785
              ],
              [
                -74.24514012678458,
                40.53897886632853
              ],
              [
                -74.24504955187172,
                40.538875607288645
              ],
              [
                -74.24500274436116,
                40.53881641839824
              ],
              [
                -74.24497182440861,
                40.538773199632864
              ],
              [
                -74.24494469027066,
                40.538741855065204
              ],
              [
                -74.2449002703978,
                40.5386961678669
              ],
              [
                -74.24483866257475,
                40.53864321162583
              ],
              [
                -74.24480511077405,
                40.53861437088652
              ],
              [
                -74.244781548778,
                40.53860075463676
              ],
              [
                -74.24476246594462,
                40.538587128833754
              ],
              [
                -74.244742256741,
                40.53857179594525
              ],
              [
                -74.24472651957181,
                40.538554747388716
              ],
              [
                -74.24471526089539,
                40.538537687609605
              ],
              [
                -74.24470736323296,
                40.5385206206661
              ],
              [
                -74.24469942831634,
                40.53849415767438
              ],
              [
                -74.2446982773697,
                40.538485619914695
              ],
              [
                -74.24469938140453,
                40.538481345528744
              ],
              [
                -74.24469936577053,
                40.538477075707036
              ],
              [
                -74.2447104398981,
                40.53844373929485
              ],
              [
                -74.2447170786649,
                40.538422371514315
              ],
              [
                -74.24472039993405,
                40.53841211306407
              ],
              [
                -74.24472038116701,
                40.53840698800471
              ],
              [
                -74.24471924206769,
                40.53840186583577
              ],
              [
                -74.24471810829422,
                40.53839759839728
              ],
              [
                -74.24471697231894,
                40.538393329288006
              ],
              [
                -74.24470909749752,
                40.538383095370655
              ],
              [
                -74.24470122267977,
                40.53837286178786
              ],
              [
                -74.24467763731083,
                40.538352412659194
              ],
              [
                -74.24465294390939,
                40.538335382005464
              ],
              [
                -74.2445934680118,
                40.53829792438103
              ],
              [
                -74.24456877240652,
                40.53828004014484
              ],
              [
                -74.24450588133932,
                40.53822721545689
              ],
              [
                -74.24449913627772,
                40.53821954217152
              ],
              [
                -74.24449238967783,
                40.538211868218866
              ],
              [
                -74.24448112799658,
                40.538193954854066
              ],
              [
                -74.24447097502853,
                40.53817262166844
              ],
              [
                -74.24445852742087,
                40.53813677448637
              ],
              [
                -74.24444832584695,
                40.53810262865368
              ],
              [
                -74.24444259671773,
                40.53806676599291
              ],
              [
                -74.2444413433647,
                40.538030039061326
              ],
              [
                -74.24444344211136,
                40.537991596682474
              ],
              [
                -74.24445001843112,
                40.53795314527304
              ],
              [
                -74.24446107385107,
                40.53791468432655
              ],
              [
                -74.2444754911651,
                40.53787707096217
              ],
              [
                -74.24449326969713,
                40.53784030182943
              ],
              [
                -74.24449660594615,
                40.537834316559035
              ],
              [
                -74.24450106630034,
                40.53782918246389
              ],
              [
                -74.24451222079375,
                40.53781805427171
              ],
              [
                -74.24452673493828,
                40.53780691892313
              ],
              [
                -74.24457587413374,
                40.53777350194995
              ],
              [
                -74.24458926480835,
                40.53776151474761
              ],
              [
                -74.24459372136184,
                40.53775552541253
              ],
              [
                -74.24459817857529,
                40.53774953657848
              ],
              [
                -74.24468497417264,
                40.53760414338848
              ],
              [
                -74.24477620246827,
                40.53744678032727
              ],
              [
                -74.24478733358518,
                40.53742882025492
              ],
              [
                -74.24480069599326,
                40.537409145438
              ],
              [
                -74.24484083543769,
                40.53736378835734
              ],
              [
                -74.2448854472566,
                40.53731756865712
              ],
              [
                -74.24490441459518,
                40.53729958968117
              ],
              [
                -74.24492227413629,
                40.537285031529706
              ],
              [
                -74.24494125711304,
                40.53727132454766
              ],
              [
                -74.24496024612203,
                40.53725932535773
              ],
              [
                -74.24497924473512,
                40.53724988819152
              ],
              [
                -74.24499824648248,
                40.53724130743251
              ],
              [
                -74.24508434623642,
                40.537212081219835
              ],
              [
                -74.24517157497509,
                40.537185416254246
              ],
              [
                -74.24520736973872,
                40.53717679792458
              ],
              [
                -74.24522190891928,
                40.53717249534176
              ],
              [
                -74.24524202938359,
                40.53716391047757
              ],
              [
                -74.24526133913146,
                40.53715221311303
              ],
              [
                -74.24529455875826,
                40.537118337963975
              ],
              [
                -74.24534240123023,
                40.53705948171078
              ],
              [
                -74.2453529824317,
                40.537031810498895
              ],
              [
                -74.24535957048438,
                40.53701335061724
              ],
              [
                -74.24536014307083,
                40.537002919098086
              ],
              [
                -74.24535885752975,
                40.53698554823874
              ],
              [
                -74.24535342151941,
                40.53695522841724
              ],
              [
                -74.24535239770356,
                40.53694090203701
              ],
              [
                -74.24534807468926,
                40.53692952086083
              ],
              [
                -74.24534237855728,
                40.5369166877819
              ],
              [
                -74.24533651176053,
                40.536905994434626
              ],
              [
                -74.2453204639931,
                40.53688580056195
              ],
              [
                -74.24529835540645,
                40.53686103448748
              ],
              [
                -74.24525506330733,
                40.536808727716156
              ],
              [
                -74.24524710649037,
                40.53679724389539
              ],
              [
                -74.24523301385155,
                40.53678061407116
              ],
              [
                -74.24521393085165,
                40.53676640751344
              ],
              [
                -74.24518862824804,
                40.53675378215508
              ],
              [
                -74.2451719678184,
                40.536744692836514
              ],
              [
                -74.2451678287269,
                40.53674243397901
              ],
              [
                -74.24513716051963,
                40.536728584192375
              ],
              [
                -74.2451085251395,
                40.53671890123792
              ],
              [
                -74.24508662785045,
                40.53671249704461
              ],
              [
                -74.24506311067748,
                40.53670620670757
              ],
              [
                -74.24500424061648,
                40.53668297336992
              ],
              [
                -74.24498370121022,
                40.536674041738905
              ],
              [
                -74.24495564760971,
                40.53666647140767
              ],
              [
                -74.24493693114462,
                40.53666387691708
              ],
              [
                -74.24491981038628,
                40.536665175449244
              ],
              [
                -74.24490555051072,
                40.5366711895624
              ],
              [
                -74.2448864243409,
                40.53668484524545
              ],
              [
                -74.24485718066998,
                40.536705521266484
              ],
              [
                -74.24483927779454,
                40.536719420770524
              ],
              [
                -74.2448285534017,
                40.53672830886312
              ],
              [
                -74.24481636013888,
                40.536739095194484
              ],
              [
                -74.2448009435216,
                40.53675345546139
              ],
              [
                -74.24479242459034,
                40.536764974441624
              ],
              [
                -74.24478634953924,
                40.53677635100323
              ],
              [
                -74.24478134347447,
                40.53679469707652
              ],
              [
                -74.24477092868717,
                40.53681917675573
              ],
              [
                -74.24475859495932,
                40.53684072136588
              ],
              [
                -74.24475018625944,
                40.53685284506122
              ],
              [
                -74.24472875618339,
                40.53687580944771
              ],
              [
                -74.2447035632394,
                40.53689287991006
              ],
              [
                -74.24467423232045,
                40.536900380951046
              ],
              [
                -74.2445875391072,
                40.53693292707281
              ],
              [
                -74.24456599437316,
                40.536934182371624
              ],
              [
                -74.24455422515392,
                40.53693269614596
              ],
              [
                -74.24451809478832,
                40.53692039458782
              ],
              [
                -74.24450047479363,
                40.536912856874814
              ],
              [
                -74.24448207754243,
                40.53690929916622
              ],
              [
                -74.24445168170107,
                40.53691046188259
              ],
              [
                -74.24442018769184,
                40.53691654060488
              ],
              [
                -74.24438549472539,
                40.53692284576037
              ],
              [
                -74.24434400016689,
                40.53692921982802
              ],
              [
                -74.24432494105498,
                40.53693153796757
              ],
              [
                -74.24428706720578,
                40.53693400653993
              ],
              [
                -74.24425651926258,
                40.5369333298733
              ],
              [
                -74.24422130941734,
                40.53692618755007
              ],
              [
                -74.24420135328856,
                40.53691936793862
              ],
              [
                -74.24416391650671,
                40.53689367312391
              ],
              [
                -74.24414782538688,
                40.53688140991704
              ],
              [
                -74.2441304943149,
                40.536864346573694
              ],
              [
                -74.2441234626623,
                40.536850062107675
              ],
              [
                -74.24411142926039,
                40.53682565819567
              ],
              [
                -74.24410176970596,
                40.536801112027725
              ],
              [
                -74.24408936949494,
                40.53676507137154
              ],
              [
                -74.24408173920084,
                40.53674469357536
              ],
              [
                -74.24406472844545,
                40.536706796258045
              ],
              [
                -74.2440454174462,
                40.53666964548596
              ],
              [
                -74.24402676361241,
                40.536635182181925
              ],
              [
                -74.24401049848484,
                40.53660458509813
              ],
              [
                -74.2439978112284,
                40.53657807016859
              ],
              [
                -74.2439846719087,
                40.53654669629947
              ],
              [
                -74.24397902720064,
                40.53651791445383
              ],
              [
                -74.24397853958942,
                40.53649255385095
              ],
              [
                -74.24397655106576,
                40.53646049855919
              ],
              [
                -74.2439781094152,
                40.53644388960205
              ],
              [
                -74.2439810016812,
                40.53640820075141
              ],
              [
                -74.24398566896939,
                40.536386204268005
              ],
              [
                -74.24399742170681,
                40.536363177658345
              ],
              [
                -74.24401667277039,
                40.53633481014676
              ],
              [
                -74.24403478981334,
                40.536310809887986
              ],
              [
                -74.24405447420418,
                40.53628271653439
              ],
              [
                -74.24410856024127,
                40.53623736574279
              ],
              [
                -74.24412154393605,
                40.53622649605055
              ],
              [
                -74.24412742566432,
                40.536221268982885
              ],
              [
                -74.24412917042217,
                40.53620647063417
              ],
              [
                -74.24412495988494,
                40.53619629572903
              ],
              [
                -74.24411643737697,
                40.53618731041338
              ],
              [
                -74.24410657006207,
                40.5361828089009
              ],
              [
                -74.24410512126268,
                40.53618214772186
              ],
              [
                -74.24409007631027,
                40.53618025779554
              ],
              [
                -74.24407177057255,
                40.5361819157287
              ],
              [
                -74.24405365117141,
                40.53618541375559
              ],
              [
                -74.2440318597577,
                40.53618820489822
              ],
              [
                -74.24401106699915,
                40.53618877061383
              ],
              [
                -74.24399257458947,
                40.53618861775649
              ],
              [
                -74.24395963908158,
                40.536184102762945
              ],
              [
                -74.24389476550634,
                40.536181414514985
              ],
              [
                -74.24386744479503,
                40.53617716315568
              ],
              [
                -74.24384799441026,
                40.5361712482474
              ],
              [
                -74.24382561145471,
                40.53615995964942
              ],
              [
                -74.24378788666591,
                40.536143762975385
              ],
              [
                -74.2437529283142,
                40.536127120647656
              ],
              [
                -74.24370362672205,
                40.53610331800133
              ],
              [
                -74.24368184908076,
                40.53609019011083
              ],
              [
                -74.2436634809121,
                40.53607472027231
              ],
              [
                -74.24365012038768,
                40.536061381851795
              ],
              [
                -74.24364417849382,
                40.536050111601604
              ],
              [
                -74.24363519206028,
                40.53603226251382
              ],
              [
                -74.24362902598611,
                40.53601852450829
              ],
              [
                -74.24361438955523,
                40.536000383674654
              ],
              [
                -74.2435977918341,
                40.535986640493775
              ],
              [
                -74.24358815483109,
                40.53597861913009
              ],
              [
                -74.24356451801341,
                40.53595854917024
              ],
              [
                -74.2435434797697,
                40.53594077992129
              ],
              [
                -74.24352601537693,
                40.53592649007294
              ],
              [
                -74.243509230276,
                40.535910907621734
              ],
              [
                -74.24349756433072,
                40.535898717363125
              ],
              [
                -74.24347756002385,
                40.535878749413996
              ],
              [
                -74.24345994865153,
                40.535863223657884
              ],
              [
                -74.24343986400719,
                40.53585061214692
              ],
              [
                -74.24342147422972,
                40.535839123348126
              ],
              [
                -74.24339700266204,
                40.53582707113404
              ],
              [
                -74.24337631463281,
                40.53581693163438
              ],
              [
                -74.24336545657813,
                40.53580866472734
              ],
              [
                -74.24335015493348,
                40.53579576965194
              ],
              [
                -74.24334122131087,
                40.5357825035674
              ],
              [
                -74.24332702417657,
                40.53575670494126
              ],
              [
                -74.24330785790092,
                40.53572932362692
              ],
              [
                -74.24329169749858,
                40.53570761941968
              ],
              [
                -74.24327127497509,
                40.535671293782364
              ],
              [
                -74.24326787177671,
                40.53565593055105
              ],
              [
                -74.24326671547482,
                40.53564465131399
              ],
              [
                -74.24326514821085,
                40.53562917429231
              ],
              [
                -74.24326300040916,
                40.53562184970851
              ],
              [
                -74.24325746299479,
                40.53561296756952
              ],
              [
                -74.24324891409961,
                40.53560675487901
              ],
              [
                -74.24323948336036,
                40.53560518398754
              ],
              [
                -74.24321874959297,
                40.53560218119803
              ],
              [
                -74.24319384356232,
                40.5355990493648
              ],
              [
                -74.24318048317238,
                40.53559559285711
              ],
              [
                -74.2431647098309,
                40.535591233441856
              ],
              [
                -74.24314182828353,
                40.53558090588536
              ],
              [
                -74.2431294223359,
                40.53557278061423
              ],
              [
                -74.24311505623017,
                40.53555947123332
              ],
              [
                -74.24310053967928,
                40.53554476278485
              ],
              [
                -74.24309258925146,
                40.53553495260093
              ],
              [
                -74.24306922463569,
                40.535510490814644
              ],
              [
                -74.24305122739912,
                40.53549809495174
              ],
              [
                -74.24303042250862,
                40.53548518333862
              ],
              [
                -74.24301549236591,
                40.535475443376235
              ],
              [
                -74.24300563383345,
                40.53546503444941
              ],
              [
                -74.24300083073786,
                40.53545011276852
              ],
              [
                -74.24299991637959,
                40.535436335133284
              ],
              [
                -74.24299898376137,
                40.53542719797057
              ],
              [
                -74.24299170999932,
                40.53541524480202
              ],
              [
                -74.24298366574534,
                40.53540933358432
              ],
              [
                -74.2429642070392,
                40.53540056331801
              ],
              [
                -74.24293260577885,
                40.53538652175484
              ],
              [
                -74.24290036605781,
                40.535375336760076
              ],
              [
                -74.24288545359322,
                40.53537020752436
              ],
              [
                -74.24287082219773,
                40.53536326718737
              ],
              [
                -74.24285776172391,
                40.5353534403217
              ],
              [
                -74.24284936584533,
                40.53533940459772
              ],
              [
                -74.24284699248214,
                40.535329995395706
              ],
              [
                -74.24284899763335,
                40.53531747476564
              ],
              [
                -74.24285585200917,
                40.53530291273569
              ],
              [
                -74.24286096382339,
                40.53529373363567
              ],
              [
                -74.24286242837516,
                40.53528091276109
              ],
              [
                -74.24285891554203,
                40.53526483556585
              ],
              [
                -74.24284499756185,
                40.535246528431365
              ],
              [
                -74.24283261641799,
                40.53523450597588
              ],
              [
                -74.24282052968051,
                40.535225309474825
              ],
              [
                -74.24280937879699,
                40.535216054535645
              ],
              [
                -74.24280270365348,
                40.53520053285709
              ],
              [
                -74.24279612716542,
                40.53517257700573
              ],
              [
                -74.24279405511268,
                40.53515677067429
              ],
              [
                -74.24278977364749,
                40.53514676222781
              ],
              [
                -74.24278130109536,
                40.53513203928223
              ],
              [
                -74.24276764529407,
                40.535116560507966
              ],
              [
                -74.24275634772646,
                40.53510590648936
              ],
              [
                -74.24274328722593,
                40.53509605381096
              ],
              [
                -74.2427319143922,
                40.53508468576559
              ],
              [
                -74.24271970002911,
                40.53506947835351
              ],
              [
                -74.24271165713901,
                40.53505437135167
              ],
              [
                -74.24270423424771,
                40.53504099229081
              ],
              [
                -74.24269465731116,
                40.5350288255119
              ],
              [
                -74.24268242294441,
                40.535018202616705
              ],
              [
                -74.24267503288957,
                40.535014045549126
              ],
              [
                -74.24263737229158,
                40.53498618286158
              ],
              [
                -74.2426264124883,
                40.53496984631143
              ],
              [
                -74.24261556299494,
                40.53495419623562
              ],
              [
                -74.24260331267001,
                40.534938988384425
              ],
              [
                -74.24259345306332,
                40.534928606899406
              ],
              [
                -74.24258619672483,
                40.53492123764424
              ],
              [
                -74.242577236085,
                40.5349107988057
              ],
              [
                -74.24257216705958,
                40.534902246684446
              ],
              [
                -74.24257151644906,
                40.534891295737
              ],
              [
                -74.24257356089763,
                40.534879488541804
              ],
              [
                -74.24257702716523,
                40.53486334113916
              ],
              [
                -74.2425798731027,
                40.53485468903217
              ],
              [
                -74.24258240637627,
                40.534838600058514
              ],
              [
                -74.24258473066882,
                40.53482500723165
              ],
              [
                -74.24258604604269,
                40.5348107598161
              ],
              [
                -74.24258647897008,
                40.53480117999623
              ],
              [
                -74.2425855295742,
                40.534787402431206
              ],
              [
                -74.24258024253018,
                40.53476754110567
              ],
              [
                -74.24256127113148,
                40.53475448782331
              ],
              [
                -74.24254649044343,
                40.53474617534858
              ],
              [
                -74.2425416636521,
                40.534744346057884
              ],
              [
                -74.24253258828735,
                40.534741675188435
              ],
              [
                -74.24251134992089,
                40.534738344474874
              ],
              [
                -74.2424874171602,
                40.5347358698341
              ],
              [
                -74.24246309099698,
                40.534733779664535
              ],
              [
                -74.24243453514485,
                40.53473617417267
              ],
              [
                -74.24240487331872,
                40.53474112149674
              ],
              [
                -74.24238253031105,
                40.534740345757385
              ],
              [
                -74.24236610201426,
                40.534734232563345
              ],
              [
                -74.2423552326689,
                40.53472319278325
              ],
              [
                -74.2423437314585,
                40.53470581497256
              ],
              [
                -74.2423293469989,
                40.53468789408062
              ],
              [
                -74.24232111615434,
                40.53467989711757
              ],
              [
                -74.24230272455779,
                40.534667885615065
              ],
              [
                -74.24228246325006,
                40.53465598896073
              ],
              [
                -74.24226480041055,
                40.53464639187509
              ],
              [
                -74.24224858034931,
                40.53463780681127
              ],
              [
                -74.24222686064839,
                40.53462034064931
              ],
              [
                -74.24220899621434,
                40.53460473398257
              ],
              [
                -74.24219707950309,
                40.53459235196281
              ],
              [
                -74.2421726750232,
                40.53454881562928
              ],
              [
                -74.24215801441201,
                40.53451428864594
              ],
              [
                -74.24214917360845,
                40.53448683275353
              ],
              [
                -74.24214184326112,
                40.534469554685444
              ],
              [
                -74.2421333557727,
                40.534450221612246
              ],
              [
                -74.24212310699538,
                40.53443171616903
              ],
              [
                -74.24211323214699,
                40.53441672238595
              ],
              [
                -74.24209753548908,
                40.53440385528397
              ],
              [
                -74.24208466577947,
                40.534396114523425
              ],
              [
                -74.24206500251829,
                40.53438064665697
              ],
              [
                -74.24205318205567,
                40.53436505352504
              ],
              [
                -74.24204570286014,
                40.53434637705037
              ],
              [
                -74.24204056270793,
                40.53432791577956
              ],
              [
                -74.24203508673898,
                40.534305913281365
              ],
              [
                -74.24202759488945,
                40.53427343146121
              ],
              [
                -74.24202357895064,
                40.53425702543218
              ],
              [
                -74.24201801113493,
                40.5342297266423
              ],
              [
                -74.24199960338497,
                40.534163175490924
              ],
              [
                -74.24199354944761,
                40.53413096455008
              ],
              [
                -74.24199017398918,
                40.53410248038077
              ],
              [
                -74.24198749133386,
                40.53406721561944
              ],
              [
                -74.24198995216875,
                40.53404121636184
              ],
              [
                -74.2419915502286,
                40.534015987553786
              ],
              [
                -74.24199307588103,
                40.5339900457198
              ],
              [
                -74.24199085844428,
                40.533963617727224
              ],
              [
                -74.24198920997154,
                40.5339159161509
              ],
              [
                -74.24199689844576,
                40.53387294405589
              ],
              [
                -74.24200030699468,
                40.53386072237175
              ],
              [
                -74.24200254007258,
                40.53384180528582
              ],
              [
                -74.24200316414962,
                40.53382514236715
              ],
              [
                -74.24200867165067,
                40.533806385239544
              ],
              [
                -74.24201178363337,
                40.53379133659616
              ],
              [
                -74.24201538293059,
                40.533772005341945
              ],
              [
                -74.24201329191379,
                40.53376078284408
              ],
              [
                -74.24199835147876,
                40.533728041133706
              ],
              [
                -74.24198641905005,
                40.5337110215386
              ],
              [
                -74.24197061665065,
                40.53367902330005
              ],
              [
                -74.24196506450933,
                40.53366555681744
              ],
              [
                -74.24196285953305,
                40.53365293584444
              ],
              [
                -74.24196458870446,
                40.53363371843315
              ],
              [
                -74.2419705278025,
                40.53361457557941
              ],
              [
                -74.24197674772846,
                40.533593673228
              ],
              [
                -74.24198757151012,
                40.53357248725974
              ],
              [
                -74.24199777336811,
                40.5335587970896
              ],
              [
                -74.24202006073192,
                40.533534433187874
              ],
              [
                -74.24202074376123,
                40.53353368841401
              ],
              [
                -74.24205252795593,
                40.53349922767833
              ],
              [
                -74.24207044355147,
                40.533479400837216
              ],
              [
                -74.24209589720769,
                40.53346512899832
              ],
              [
                -74.24211947619509,
                40.53345947872178
              ],
              [
                -74.24213691846244,
                40.53345774066159
              ],
              [
                -74.24215971270046,
                40.533453547371536
              ],
              [
                -74.24218127234485,
                40.53344658505237
              ],
              [
                -74.24220533806516,
                40.5334366807957
              ],
              [
                -74.24222972576077,
                40.53342565004783
              ],
              [
                -74.24224602776077,
                40.533417243972124
              ],
              [
                -74.24226378911351,
                40.53340449794598
              ],
              [
                -74.2422820033816,
                40.53338749651771
              ],
              [
                -74.24217799663231,
                40.53257613847663
              ],
              [
                -74.24181061621528,
                40.53256267542149
              ],
              [
                -74.24166784213212,
                40.53192331944759
              ],
              [
                -74.24171924066692,
                40.53160972765347
              ],
              [
                -74.24153092332183,
                40.531470074297445
              ],
              [
                -74.24150791145638,
                40.53127325293335
              ],
              [
                -74.24150791783288,
                40.531242812241864
              ],
              [
                -74.24150594015053,
                40.53122310858367
              ],
              [
                -74.24150339439726,
                40.53117543685631
              ],
              [
                -74.24149964476034,
                40.53114343966333
              ],
              [
                -74.24150032655452,
                40.53112285204652
              ],
              [
                -74.24150247553192,
                40.53108022032329
              ],
              [
                -74.24150733322897,
                40.531041342795135
              ],
              [
                -74.24152427275666,
                40.53098012558668
              ],
              [
                -74.24153508696172,
                40.53092671714012
              ],
              [
                -74.24154928903585,
                40.53087489126385
              ],
              [
                -74.241569160089,
                40.53082873643653
              ],
              [
                -74.24158841240589,
                40.530780826179644
              ],
              [
                -74.24159605351099,
                40.53076480722383
              ],
              [
                -74.24160715747745,
                40.530741837666255
              ],
              [
                -74.24162417534374,
                40.53071284398609
              ],
              [
                -74.24164097038334,
                40.53068173720387
              ],
              [
                -74.24165729913027,
                40.53065101571626
              ],
              [
                -74.24167218669886,
                40.530619336638445
              ],
              [
                -74.24168110124019,
                40.530597551266595
              ],
              [
                -74.24168936071904,
                40.53057403818787
              ],
              [
                -74.24171160461849,
                40.53053729256419
              ],
              [
                -74.24172854514237,
                40.5305168095949
              ],
              [
                -74.2417475434774,
                40.53049835125539
              ],
              [
                -74.2417670305463,
                40.530475582488684
              ],
              [
                -74.24178702312778,
                40.53045314268971
              ],
              [
                -74.24180708875609,
                40.530431416247104
              ],
              [
                -74.24181772165426,
                40.53041731288743
              ],
              [
                -74.24182920013061,
                40.53039788227689
              ],
              [
                -74.2418432069506,
                40.53036233473484
              ],
              [
                -74.2418671379307,
                40.53031488231866
              ],
              [
                -74.24190634667363,
                40.53024455907002
              ],
              [
                -74.2419404209108,
                40.53018728571757
              ],
              [
                -74.24199108185135,
                40.530110597765265
              ],
              [
                -74.24202464913051,
                40.53006221866066
              ],
              [
                -74.24223421655796,
                40.52983500311577
              ],
              [
                -74.24224528137324,
                40.529822779504954
              ],
              [
                -74.24224637250255,
                40.52981657621738
              ],
              [
                -74.24224565508311,
                40.529808002834564
              ],
              [
                -74.2422381043392,
                40.52979484246955
              ],
              [
                -74.24222830140566,
                40.52978363741721
              ],
              [
                -74.24220131578326,
                40.529759741301056
              ],
              [
                -74.24218939305197,
                40.5297424900076
              ],
              [
                -74.24217641185439,
                40.52972221667963
              ],
              [
                -74.24216769875873,
                40.52970480817036
              ],
              [
                -74.24216656926593,
                40.52969133454264
              ],
              [
                -74.24217254400455,
                40.52966644612745
              ],
              [
                -74.24217260166839,
                40.52959019151157
              ],
              [
                -74.24218040993692,
                40.52950064607407
              ],
              [
                -74.2421880176231,
                40.52943755166017
              ],
              [
                -74.24219764536582,
                40.52940818095633
              ],
              [
                -74.2422111306362,
                40.529376777503245
              ],
              [
                -74.24225490734621,
                40.52931253256033
              ],
              [
                -74.24228365805864,
                40.529261320374324
              ],
              [
                -74.24230892358077,
                40.52919736476676
              ],
              [
                -74.24233695655916,
                40.52913757935195
              ],
              [
                -74.24236058312694,
                40.529082928335306
              ],
              [
                -74.24236464310113,
                40.52905444270884
              ],
              [
                -74.24237543220742,
                40.5290293196263
              ],
              [
                -74.24239489550051,
                40.529001927451425
              ],
              [
                -74.24242054279586,
                40.5289809984536
              ],
              [
                -74.24244489204952,
                40.528963822536085
              ],
              [
                -74.24245541025238,
                40.52895469954592
              ],
              [
                -74.24245684392416,
                40.52894294590664
              ],
              [
                -74.24245581741143,
                40.528930697383636
              ],
              [
                -74.24244956541193,
                40.528913784949204
              ],
              [
                -74.24243402108462,
                40.52889179048973
              ],
              [
                -74.24241987628413,
                40.52886726905353
              ],
              [
                -74.24241293926971,
                40.52883255556883
              ],
              [
                -74.24242002837842,
                40.528734436227545
              ],
              [
                -74.24242781388115,
                40.52871191889021
              ],
              [
                -74.2424592642582,
                40.52868332817097
              ],
              [
                -74.24248405744738,
                40.52866182499104
              ],
              [
                -74.24249023516836,
                40.52863938559633
              ],
              [
                -74.24249931304354,
                40.528584215777414
              ],
              [
                -74.24253332154946,
                40.52852844211371
              ],
              [
                -74.24253509556485,
                40.52851113662018
              ],
              [
                -74.24252416256847,
                40.528486458470624
              ],
              [
                -74.24250919866977,
                40.52845213755198
              ],
              [
                -74.2425102899773,
                40.5284459357685
              ],
              [
                -74.24255062166955,
                40.5284175239479
              ],
              [
                -74.24256732309529,
                40.5284148646243
              ],
              [
                -74.24259362148119,
                40.52842096128822
              ],
              [
                -74.24261267872569,
                40.52841757123615
              ],
              [
                -74.24261257701788,
                40.52841634680076
              ],
              [
                -74.24262640726322,
                40.528408292677625
              ],
              [
                -74.24264218517268,
                40.52839460992399
              ],
              [
                -74.24266345955331,
                40.528369588623185
              ],
              [
                -74.24270218581978,
                40.52834125871439
              ],
              [
                -74.24275675859761,
                40.528309693100056
              ],
              [
                -74.24276120556748,
                40.52830572728832
              ],
              [
                -74.2428867138121,
                40.528200130826015
              ],
              [
                -74.24288723774357,
                40.52804910117791
              ],
              [
                -74.24285856207011,
                40.528031988594975
              ],
              [
                -74.24284226646175,
                40.52800297456182
              ],
              [
                -74.24285076542611,
                40.527960130174236
              ],
              [
                -74.2428574567956,
                40.52794381560392
              ],
              [
                -74.2428726178986,
                40.52792278286968
              ],
              [
                -74.24290983683017,
                40.52786685302815
              ],
              [
                -74.24293971177052,
                40.52780021406223
              ],
              [
                -74.24294799832856,
                40.52772601705562
              ],
              [
                -74.24294563760438,
                40.52769784587819
              ],
              [
                -74.24292862564661,
                40.527667928706016
              ],
              [
                -74.242895150673,
                40.52763389612436
              ],
              [
                -74.24287940175411,
                40.527609452185295
              ],
              [
                -74.24287584567885,
                40.52758625886605
              ],
              [
                -74.24285089448672,
                40.52754714456318
              ],
              [
                -74.24283446093035,
                40.52750548917092
              ],
              [
                -74.24282696789423,
                40.527462364647704
              ],
              [
                -74.2428286081863,
                40.52741888075689
              ],
              [
                -74.2428393395779,
                40.52737615650703
              ],
              [
                -74.24285888589208,
                40.52733529135661
              ],
              [
                -74.24288674411305,
                40.52729733692015
              ],
              [
                -74.24293518423968,
                40.52724462159064
              ],
              [
                -74.24297630398283,
                40.52718840203788
              ],
              [
                -74.24300967202622,
                40.527129267989515
              ],
              [
                -74.24303493837022,
                40.52706783974404
              ],
              [
                -74.24305183800163,
                40.52700476166362
              ],
              [
                -74.24306019367343,
                40.52694069541479
              ],
              [
                -74.24305991776228,
                40.526876313027735
              ],
              [
                -74.2430510131873,
                40.52681228984684
              ],
              [
                -74.24307389513913,
                40.52669417771936
              ],
              [
                -74.24333883883844,
                40.52585380517989
              ],
              [
                -74.24396835839076,
                40.52499893358653
              ],
              [
                -74.24396761706937,
                40.524950954487224
              ],
              [
                -74.24396500758859,
                40.52490536633781
              ],
              [
                -74.24360793545554,
                40.52484483934404
              ],
              [
                -74.24348882392479,
                40.52486125895079
              ],
              [
                -74.24340768923547,
                40.524845387088156
              ],
              [
                -74.24338504510442,
                40.524834807612024
              ],
              [
                -74.24335792685855,
                40.524818913820425
              ],
              [
                -74.24332687887491,
                40.5247946017169
              ],
              [
                -74.24330095332317,
                40.52477372904667
              ],
              [
                -74.24326436709377,
                40.524712177001724
              ],
              [
                -74.24317524593046,
                40.52459107967432
              ],
              [
                -74.24311293970743,
                40.524511104412284
              ],
              [
                -74.24308072550264,
                40.52445364269708
              ],
              [
                -74.24303990307526,
                40.5243799987951
              ],
              [
                -74.24302432341914,
                40.524338328485776
              ],
              [
                -74.24298985090235,
                40.524253920665664
              ],
              [
                -74.24294001416733,
                40.52418809487348
              ],
              [
                -74.24290564933676,
                40.52413381404532
              ],
              [
                -74.24286227003466,
                40.52408735084123
              ],
              [
                -74.24281304883496,
                40.52402887377362
              ],
              [
                -74.24280157009575,
                40.52400729628662
              ],
              [
                -74.24280252193645,
                40.52398019347293
              ],
              [
                -74.24280658251452,
                40.52395170899014
              ],
              [
                -74.24280678281418,
                40.52392525778574
              ],
              [
                -74.24280882716648,
                40.52389195100273
              ],
              [
                -74.24281449076011,
                40.52386338790746
              ],
              [
                -74.24281641236698,
                40.5238479661431
              ],
              [
                -74.2428183465929,
                40.52383245271266
              ],
              [
                -74.24281673693896,
                40.52380362991615
              ],
              [
                -74.24279784366254,
                40.52376089237593
              ],
              [
                -74.24276471147398,
                40.52372130788957
              ],
              [
                -74.24275890301233,
                40.52370006921583
              ],
              [
                -74.2427575681127,
                40.52368414487768
              ],
              [
                -74.24276517879032,
                40.523649952984734
              ],
              [
                -74.24276575861535,
                40.52363762634376
              ],
              [
                -74.24276237450053,
                40.52362610804578
              ],
              [
                -74.24275345819947,
                40.523606250032415
              ],
              [
                -74.242744234157,
                40.523582717711534
              ],
              [
                -74.24274023652956,
                40.52356385012517
              ],
              [
                -74.24275122404825,
                40.52351227512357
              ],
              [
                -74.24275355918745,
                40.52336703698905
              ],
              [
                -74.24273957613273,
                40.52326748653359
              ],
              [
                -74.24272886354078,
                40.52322940931134
              ],
              [
                -74.2427106539165,
                40.52317754423211
              ],
              [
                -74.24269383331622,
                40.52315161267548
              ],
              [
                -74.2426579383821,
                40.52313222884207
              ],
              [
                -74.24261666939863,
                40.523098937889515
              ],
              [
                -74.24261397838424,
                40.52308093752448
              ],
              [
                -74.2426212927105,
                40.52302533933612
              ],
              [
                -74.24262345539675,
                40.52294713519765
              ],
              [
                -74.24265487812146,
                40.52288393777587
              ],
              [
                -74.24269227372879,
                40.522840930770386
              ],
              [
                -74.24274849039318,
                40.52280007810464
              ],
              [
                -74.24276246783715,
                40.52272678912754
              ],
              [
                -74.24273215954128,
                40.52270077737195
              ],
              [
                -74.24271469863487,
                40.52265675986605
              ],
              [
                -74.24270806228716,
                40.52257272615962
              ],
              [
                -74.24264266046022,
                40.52241135944538
              ],
              [
                -74.24259082520992,
                40.522251637320856
              ],
              [
                -74.24258375639438,
                40.52219864900051
              ],
              [
                -74.24259440562305,
                40.52205564138241
              ],
              [
                -74.24281484743372,
                40.52163121487261
              ],
              [
                -74.2429043393599,
                40.52142442574137
              ],
              [
                -74.24293235036517,
                40.52122716113278
              ],
              [
                -74.24189065727207,
                40.520977549491654
              ],
              [
                -74.2415721732834,
                40.52094713835037
              ],
              [
                -74.2413837903956,
                40.52090227902593
              ],
              [
                -74.24133219575513,
                40.520894517580224
              ],
              [
                -74.24123956765649,
                40.5209478378477
              ],
              [
                -74.24121924614649,
                40.521032488187025
              ],
              [
                -74.24117293552197,
                40.521060131431994
              ],
              [
                -74.24097377890219,
                40.52088739946692
              ],
              [
                -74.24092192194361,
                40.5208068368255
              ],
              [
                -74.24093977908043,
                40.52075367378462
              ],
              [
                -74.2408465963753,
                40.520653519664386
              ],
              [
                -74.2407328454876,
                40.520569151653746
              ],
              [
                -74.24055584307511,
                40.520447528900064
              ],
              [
                -74.24033380813592,
                40.52040852891316
              ],
              [
                -74.24003142061723,
                40.520313119018425
              ],
              [
                -74.23992701129993,
                40.52018125023725
              ],
              [
                -74.23991937852388,
                40.52004699652994
              ],
              [
                -74.2400965083332,
                40.51986823103432
              ],
              [
                -74.24018802896548,
                40.519663807980336
              ],
              [
                -74.24024433791301,
                40.51951749846853
              ],
              [
                -74.24039560067511,
                40.51927909989127
              ],
              [
                -74.24049069687692,
                40.5191916289701
              ],
              [
                -74.24059645807856,
                40.51920981244947
              ],
              [
                -74.24075928637673,
                40.51931965712868
              ],
              [
                -74.2408696197996,
                40.5194940796585
              ],
              [
                -74.24103122918532,
                40.519542512687934
              ],
              [
                -74.24109075921592,
                40.519345603828924
              ],
              [
                -74.2412596867521,
                40.51918046982297
              ],
              [
                -74.24186964584052,
                40.518742121386765
              ],
              [
                -74.24209123122782,
                40.518485317498246
              ],
              [
                -74.24232477833276,
                40.51832652502175
              ],
              [
                -74.24263711942852,
                40.518242768834334
              ],
              [
                -74.24282337422682,
                40.518308183295815
              ],
              [
                -74.24301946478842,
                40.51830792476936
              ],
              [
                -74.24365228511596,
                40.518294614623166
              ],
              [
                -74.24382126609419,
                40.51832938469257
              ],
              [
                -74.24399957376158,
                40.518441159390015
              ],
              [
                -74.24419318643788,
                40.518507646784464
              ],
              [
                -74.24429835217606,
                40.518515003107694
              ],
              [
                -74.24431751097606,
                40.518513227043705
              ],
              [
                -74.24440812395468,
                40.51847737885722
              ],
              [
                -74.24451292617638,
                40.51841704504164
              ],
              [
                -74.24460054529645,
                40.518339562924695
              ],
              [
                -74.24469200084191,
                40.51825867000793
              ],
              [
                -74.24477399945269,
                40.51820832032108
              ],
              [
                -74.24486088052586,
                40.518155324562485
              ],
              [
                -74.24492775667768,
                40.518120021149294
              ],
              [
                -74.24508804460534,
                40.51806683940415
              ],
              [
                -74.24519792215143,
                40.518037784047856
              ],
              [
                -74.24524560494234,
                40.518034064010976
              ],
              [
                -74.2453056843134,
                40.51802937476492
              ],
              [
                -74.24537078493631,
                40.518039913035246
              ],
              [
                -74.24549055670978,
                40.518060023004196
              ],
              [
                -74.24552667154192,
                40.518080863004094
              ],
              [
                -74.24556587385842,
                40.51809049526678
              ],
              [
                -74.24556879842257,
                40.51808986210908
              ],
              [
                -74.2456347316994,
                40.518075580745766
              ],
              [
                -74.24566427146003,
                40.51804946914109
              ],
              [
                -74.24571276694213,
                40.51798442162144
              ],
              [
                -74.24559938749574,
                40.5178572955057
              ],
              [
                -74.24566989001377,
                40.51778970805127
              ],
              [
                -74.24520861528356,
                40.51728974362411
              ],
              [
                -74.24595276991943,
                40.516931993147885
              ],
              [
                -74.24598176886698,
                40.516943242361634
              ],
              [
                -74.24634969801765,
                40.51675337193499
              ],
              [
                -74.24653682834257,
                40.51695996037955
              ],
              [
                -74.24656439920257,
                40.516945792199216
              ],
              [
                -74.24637736435442,
                40.51673909335886
              ],
              [
                -74.24642373950073,
                40.516715162017974
              ],
              [
                -74.24644391386606,
                40.51670254516732
              ],
              [
                -74.24647899705323,
                40.51668732721085
              ],
              [
                -74.24666521689264,
                40.51689161720227
              ],
              [
                -74.24668162673821,
                40.516885006512
              ],
              [
                -74.24669527621076,
                40.516901496569204
              ],
              [
                -74.24668258602559,
                40.51690695637092
              ],
              [
                -74.24696892776888,
                40.51719824208417
              ],
              [
                -74.246995569966,
                40.51718208519555
              ],
              [
                -74.24671033904563,
                40.51689398125467
              ],
              [
                -74.24670706721054,
                40.51689543206918
              ],
              [
                -74.2466932357773,
                40.5168818628046
              ],
              [
                -74.24669769426788,
                40.51687938448536
              ],
              [
                -74.24651040267673,
                40.516669855306006
              ],
              [
                -74.24659339236202,
                40.51658682659807
              ],
              [
                -74.24674997194062,
                40.5164956132405
              ],
              [
                -74.24659873437135,
                40.516358202326835
              ],
              [
                -74.24652992417231,
                40.51637770064298
              ],
              [
                -74.24630085106863,
                40.51614061103398
              ],
              [
                -74.24625286283556,
                40.5161533984428
              ],
              [
                -74.24623549606487,
                40.51615405657118
              ],
              [
                -74.24618298246288,
                40.51615926634782
              ],
              [
                -74.2461746405067,
                40.51615923046235
              ],
              [
                -74.24615993200383,
                40.516157604791346
              ],
              [
                -74.24614748095286,
                40.51615375951751
              ],
              [
                -74.24613667051254,
                40.51614674003258
              ],
              [
                -74.24612857679293,
                40.5161373925737
              ],
              [
                -74.24612462197246,
                40.516127369298324
              ],
              [
                -74.24612560876693,
                40.516116596447006
              ],
              [
                -74.24644039651318,
                40.515965028508525
              ],
              [
                -74.24664175789378,
                40.51612193607668
              ],
              [
                -74.24687147835442,
                40.51603688177654
              ],
              [
                -74.24693048008466,
                40.516066454417754
              ],
              [
                -74.24751811835522,
                40.51573586477858
              ],
              [
                -74.24822171694589,
                40.5164860197475
              ],
              [
                -74.24891910653054,
                40.51720843369239
              ],
              [
                -74.24848720326882,
                40.51744974618363
              ],
              [
                -74.24851449056031,
                40.517475626265046
              ],
              [
                -74.24940770789283,
                40.51698083142366
              ],
              [
                -74.24937814038087,
                40.51695149632222
              ],
              [
                -74.24894624665603,
                40.517194539786644
              ],
              [
                -74.2482842208389,
                40.51648694955366
              ],
              [
                -74.24860080823045,
                40.516313331917786
              ],
              [
                -74.24857496383808,
                40.51628869599917
              ],
              [
                -74.24851245601478,
                40.51622571840381
              ],
              [
                -74.24855013363099,
                40.51619574727787
              ],
              [
                -74.24854589291671,
                40.51616991714839
              ],
              [
                -74.24873695634659,
                40.51636491694886
              ],
              [
                -74.24875958315138,
                40.51635622113422
              ],
              [
                -74.24862466940564,
                40.516215751555514
              ],
              [
                -74.24868189274336,
                40.51618172785627
              ],
              [
                -74.24882966609398,
                40.516312836002854
              ],
              [
                -74.24884323664601,
                40.516305889562055
              ],
              [
                -74.24870679818524,
                40.516176485625
              ],
              [
                -74.24871583641298,
                40.51616954901986
              ],
              [
                -74.24864764632096,
                40.516112628496785
              ],
              [
                -74.24903055962491,
                40.515881236130525
              ],
              [
                -74.24905586508787,
                40.51590409611533
              ],
              [
                -74.24943096899091,
                40.51568788559744
              ],
              [
                -74.2494642033825,
                40.515725217684285
              ],
              [
                -74.24948229759761,
                40.51571653157732
              ],
              [
                -74.24989861903732,
                40.51616006240433
              ],
              [
                -74.24994158808036,
                40.516137488190985
              ],
              [
                -74.24951393678684,
                40.51569398097641
              ],
              [
                -74.24953303060417,
                40.515682164214226
              ],
              [
                -74.24950047123725,
                40.51564648856689
              ],
              [
                -74.24962270300641,
                40.515574730442026
              ],
              [
                -74.24963220109247,
                40.515545377951014
              ],
              [
                -74.24952480927368,
                40.51540995825117
              ],
              [
                -74.24921528676096,
                40.51508486129925
              ],
              [
                -74.2492561503189,
                40.51506119508572
              ],
              [
                -74.24933775243697,
                40.51514081437928
              ],
              [
                -74.2493572627287,
                40.515128870925054
              ],
              [
                -74.24927734323958,
                40.51505009620042
              ],
              [
                -74.24935660923893,
                40.5150059281937
              ],
              [
                -74.24984374594052,
                40.51551805020889
              ],
              [
                -74.25045139325624,
                40.51612945938236
              ],
              [
                -74.25039487478166,
                40.51616416863538
              ],
              [
                -74.25041535921383,
                40.516188335025916
              ],
              [
                -74.25056007272197,
                40.516106741535
              ],
              [
                -74.25053507045652,
                40.51608604354206
              ],
              [
                -74.25047627306124,
                40.516117299299445
              ],
              [
                -74.24988824874988,
                40.51552154519295
              ],
              [
                -74.25015626516773,
                40.51536118819946
              ],
              [
                -74.25025220967808,
                40.515455092115616
              ],
              [
                -74.250261753469,
                40.51545069338577
              ],
              [
                -74.25027518309636,
                40.51546306675981
              ],
              [
                -74.25019601571452,
                40.5155099310939
              ],
              [
                -74.25045509923827,
                40.51577200710897
              ],
              [
                -74.25055526413547,
                40.5157163424289
              ],
              [
                -74.25029330638937,
                40.51545281320831
              ],
              [
                -74.25028567520496,
                40.51545720726753
              ],
              [
                -74.25027416157674,
                40.51544556031963
              ],
              [
                -74.25028083612378,
                40.51544116717457
              ],
              [
                -74.25017086663702,
                40.51533399691467
              ],
              [
                -74.2501813105854,
                40.51532776528284
              ],
              [
                -74.25004011503235,
                40.515182490677724
              ],
              [
                -74.2500458775657,
                40.51510851049426
              ],
              [
                -74.25011262464746,
                40.51503074559172
              ],
              [
                -74.25019262447161,
                40.51501312455233
              ],
              [
                -74.25022073120984,
                40.51497813745148
              ],
              [
                -74.25025503032282,
                40.51494653038555
              ],
              [
                -74.25029483429407,
                40.51491893690185
              ],
              [
                -74.25031121729425,
                40.51491335701858
              ],
              [
                -74.2503263145185,
                40.514905960297504
              ],
              [
                -74.25033978259643,
                40.51489691496935
              ],
              [
                -74.25035131521172,
                40.51488642676049
              ],
              [
                -74.25036065006782,
                40.51487473421425
              ],
              [
                -74.25036757485495,
                40.51486210326496
              ],
              [
                -74.25037193207766,
                40.51484882119001
              ],
              [
                -74.25042455464683,
                40.51480106616999
              ],
              [
                -74.25048238999003,
                40.51475696224807
              ],
              [
                -74.25054500497247,
                40.51471683971556
              ],
              [
                -74.25061193066762,
                40.514680999046774
              ],
              [
                -74.25068266586877,
                40.514649708649145
              ],
              [
                -74.25075668084278,
                40.51462320285307
              ],
              [
                -74.25087970770176,
                40.514570791642846
              ],
              [
                -74.25095004454057,
                40.5145275898585
              ],
              [
                -74.25102475404668,
                40.51448889929356
              ],
              [
                -74.25110334075717,
                40.51445497653478
              ],
              [
                -74.25118528349849,
                40.5144260465497
              ],
              [
                -74.25127003884319,
                40.51440230119469
              ],
              [
                -74.25128410719968,
                40.51440545343558
              ],
              [
                -74.25129867650337,
                40.51440671057315
              ],
              [
                -74.25131331188783,
                40.51440603508408
              ],
              [
                -74.25132757651421,
                40.5144034471305
              ],
              [
                -74.25134104461047,
                40.51439902395792
              ],
              [
                -74.25135331417916,
                40.51439289758977
              ],
              [
                -74.25136401899758,
                40.514385250886605
              ],
              [
                -74.25137283954724,
                40.51437631208785
              ],
              [
                -74.25137951255292,
                40.51436634799983
              ],
              [
                -74.25138383883895,
                40.5143556560311
              ],
              [
                -74.25138568927517,
                40.51434455531665
              ],
              [
                -74.25141450948402,
                40.514297981775854
              ],
              [
                -74.2514493247853,
                40.51425385835226
              ],
              [
                -74.25148978164565,
                40.514212633084355
              ],
              [
                -74.25153546924818,
                40.51417472458121
              ],
              [
                -74.25158592366496,
                40.51414051777254
              ],
              [
                -74.25163453762609,
                40.514117340289246
              ],
              [
                -74.25168048931724,
                40.51409118993783
              ],
              [
                -74.25172346740699,
                40.51406224389354
              ],
              [
                -74.25175583198892,
                40.51403693838163
              ],
              [
                -74.2517859200255,
                40.5140100462964
              ],
              [
                -74.25179186478277,
                40.51400480544838
              ],
              [
                -74.2521259139705,
                40.51371037979995
              ],
              [
                -74.25214378894988,
                40.51355347081553
              ],
              [
                -74.25208955294612,
                40.5134605297993
              ],
              [
                -74.251994679988,
                40.5133474300147
              ],
              [
                -74.2519293126925,
                40.51320944549543
              ],
              [
                -74.25195197488479,
                40.51299700254722
              ],
              [
                -74.25216570187291,
                40.51278281361988
              ],
              [
                -74.25243270667265,
                40.51259432885168
              ],
              [
                -74.25307524977121,
                40.51214073504988
              ],
              [
                -74.25308950870547,
                40.51212196137835
              ],
              [
                -74.25309931949843,
                40.51210159428354
              ],
              [
                -74.25310438912015,
                40.51208024210097
              ],
              [
                -74.25310456615327,
                40.512058542588186
              ],
              [
                -74.25309984531484,
                40.512037143876796
              ],
              [
                -74.25309036761324,
                40.51201668511298
              ],
              [
                -74.25307641613706,
                40.51199777736774
              ],
              [
                -74.25305800309056,
                40.511982616030025
              ],
              [
                -74.25303646570178,
                40.51196123510422
              ],
              [
                -74.25301928828888,
                40.51193766666828
              ],
              [
                -74.253006845369,
                40.51191242458865
              ],
              [
                -74.25299940823136,
                40.51188605922221
              ],
              [
                -74.25299713902285,
                40.511859145416125
              ],
              [
                -74.25300008721311,
                40.51183226997471
              ],
              [
                -74.25303207962352,
                40.511468227940405
              ],
              [
                -74.25303635381549,
                40.511433160458736
              ],
              [
                -74.2530619950522,
                40.51141937810189
              ],
              [
                -74.25309286964242,
                40.511410765935445
              ],
              [
                -74.25317151924685,
                40.51146606218066
              ],
              [
                -74.25350211252186,
                40.511700917851876
              ],
              [
                -74.2535268507671,
                40.511677766031795
              ],
              [
                -74.25349517785072,
                40.51165588724195
              ],
              [
                -74.25353195022859,
                40.5116227214742
              ],
              [
                -74.2535239695038,
                40.51161898908778
              ],
              [
                -74.25348740701011,
                40.511649445244004
              ],
              [
                -74.25345907659538,
                40.51163043841861
              ],
              [
                -74.25349300571601,
                40.51159899228276
              ],
              [
                -74.2534860296364,
                40.51159519786611
              ],
              [
                -74.25343350448411,
                40.511636688346016
              ],
              [
                -74.25318020396979,
                40.51145816973165
              ],
              [
                -74.25308195034367,
                40.51139283939298
              ],
              [
                -74.25302037923967,
                40.51128514610703
              ],
              [
                -74.25300847277559,
                40.511193989705326
              ],
              [
                -74.2530377993434,
                40.51113607723536
              ],
              [
                -74.25306274811025,
                40.51106765345777
              ],
              [
                -74.2532580688566,
                40.51108868938079
              ],
              [
                -74.25326008268306,
                40.51107864379327
              ],
              [
                -74.25306394553081,
                40.51105525736969
              ],
              [
                -74.25312730843176,
                40.510712581045375
              ],
              [
                -74.25314984243136,
                40.510388496541765
              ],
              [
                -74.25319261503304,
                40.51024176573134
              ],
              [
                -74.25328327353373,
                40.51000017430845
              ],
              [
                -74.25331579805132,
                40.50986150454089
              ],
              [
                -74.25347835886951,
                40.50967270210219
              ],
              [
                -74.25350297868137,
                40.50960201174755
              ],
              [
                -74.25360711255456,
                40.50948090044585
              ],
              [
                -74.25364812518256,
                40.509470530436246
              ],
              [
                -74.25366393827429,
                40.50947795815997
              ],
              [
                -74.25366810379255,
                40.50947991476317
              ],
              [
                -74.25412462467563,
                40.50969365976767
              ],
              [
                -74.25455333637498,
                40.509895973684095
              ],
              [
                -74.25450038789252,
                40.5099631967047
              ],
              [
                -74.25452621732619,
                40.509973015886104
              ],
              [
                -74.25464651262905,
                40.50982624592433
              ],
              [
                -74.25462336202806,
                40.50981460210892
              ],
              [
                -74.25456815512877,
                40.509880110297466
              ],
              [
                -74.25434937126049,
                40.50978095473889
              ],
              [
                -74.25433256547628,
                40.50977376100487
              ],
              [
                -74.25413324931496,
                40.50968292755785
              ],
              [
                -74.25369339837492,
                40.509476988373876
              ],
              [
                -74.25367604695235,
                40.509467390170705
              ],
              [
                -74.25370535508549,
                40.509434413729934
              ],
              [
                -74.25368911881061,
                40.509425596439094
              ],
              [
                -74.25373355115248,
                40.50937689274578
              ],
              [
                -74.25389169939272,
                40.50942393251997
              ],
              [
                -74.25402069505333,
                40.50927297578743
              ],
              [
                -74.25429083557015,
                40.5093915568429
              ],
              [
                -74.25428177080046,
                40.509397124218005
              ],
              [
                -74.25431454169312,
                40.50941369464892
              ],
              [
                -74.2543887773701,
                40.50934418413456
              ],
              [
                -74.25434874440127,
                40.50932901714465
              ],
              [
                -74.25430713012449,
                40.50937626384592
              ],
              [
                -74.25403804567854,
                40.509252671645676
              ],
              [
                -74.25411121318568,
                40.509167047180746
              ],
              [
                -74.25397681140788,
                40.50902020696289
              ],
              [
                -74.25398428084348,
                40.508990553255906
              ],
              [
                -74.25399478574111,
                40.50896808987818
              ],
              [
                -74.25400832609722,
                40.50895281683775
              ],
              [
                -74.25401623457127,
                40.50894787669566
              ],
              [
                -74.25402490190875,
                40.50894473413955
              ],
              [
                -74.25403489413355,
                40.50894453995541
              ],
              [
                -74.2540475777605,
                40.5089483572472
              ],
              [
                -74.25410113834869,
                40.5089773378551
              ],
              [
                -74.25412213945084,
                40.50898476688353
              ],
              [
                -74.25434064057384,
                40.50877080313455
              ],
              [
                -74.25450422784878,
                40.50880836852237
              ],
              [
                -74.25455923943342,
                40.50874601472694
              ],
              [
                -74.25461876022267,
                40.50868612216258
              ],
              [
                -74.25468450878448,
                40.50872585538437
              ],
              [
                -74.25471558491711,
                40.50873762919246
              ],
              [
                -74.25489963820436,
                40.50877727590248
              ],
              [
                -74.25489648202563,
                40.50878703523463
              ],
              [
                -74.2549729037097,
                40.50880566213323
              ],
              [
                -74.2549813000948,
                40.50877117277574
              ],
              [
                -74.25490412260378,
                40.508756367941416
              ],
              [
                -74.25490187176642,
                40.50876438344175
              ],
              [
                -74.25473104757664,
                40.50872401077878
              ],
              [
                -74.25470272483841,
                40.50871606313869
              ],
              [
                -74.25462856709898,
                40.50867482572703
              ],
              [
                -74.25489888207028,
                40.50843368580243
              ],
              [
                -74.25504293715964,
                40.50839740737785
              ],
              [
                -74.25524817753178,
                40.508243230713006
              ],
              [
                -74.25545272937914,
                40.50803277630559
              ],
              [
                -74.25552913160817,
                40.50786714123414
              ],
              [
                -74.25553185854751,
                40.50786122885186
              ],
              [
                -74.25554269163935,
                40.507837742496164
              ],
              [
                -74.25559136315209,
                40.50771295072906
              ],
              [
                -74.25558915990914,
                40.50764532014473
              ],
              [
                -74.2555776815809,
                40.507578238363045
              ],
              [
                -74.25555705464842,
                40.507512444405016
              ],
              [
                -74.25552750637893,
                40.50744866310194
              ],
              [
                -74.25547276441225,
                40.50730432375964
              ],
              [
                -74.2554253090784,
                40.507158489298156
              ],
              [
                -74.25538521003818,
                40.5070113739394
              ],
              [
                -74.25535252614577,
                40.50686319378521
              ],
              [
                -74.25532532868485,
                40.506700187309534
              ],
              [
                -74.2553071037761,
                40.50653645411903
              ],
              [
                -74.25533004608194,
                40.50596510120405
              ],
              [
                -74.25535850504895,
                40.50517012777076
              ],
              [
                -74.25536873039187,
                40.50455316256856
              ],
              [
                -74.25536566202192,
                40.50444818086975
              ],
              [
                -74.25535477268829,
                40.50434350253876
              ],
              [
                -74.25533609771456,
                40.504239466782785
              ],
              [
                -74.25530969765195,
                40.504136410724634
              ],
              [
                -74.25527565808395,
                40.50403466831163
              ],
              [
                -74.25523408934774,
                40.50393456923248
              ],
              [
                -74.25472027611785,
                40.50296520166217
              ],
              [
                -74.25427407656716,
                40.50216118050972
              ],
              [
                -74.25423514827004,
                40.502139528246815
              ],
              [
                -74.25414310455724,
                40.50204555182022
              ],
              [
                -74.25405791537396,
                40.501947881744094
              ],
              [
                -74.25397983449942,
                40.501846809013095
              ],
              [
                -74.2539090945309,
                40.501742634758166
              ],
              [
                -74.25384590619183,
                40.50163566934947
              ],
              [
                -74.25379045770413,
                40.50152623147128
              ],
              [
                -74.25374291422749,
                40.50141464717275
              ],
              [
                -74.25370341736796,
                40.501301248896226
              ],
              [
                -74.25357745759506,
                40.500958857967355
              ],
              [
                -74.25344867216407,
                40.50061708025489
              ],
              [
                -74.25340575133804,
                40.50051414578479
              ],
              [
                -74.25335538022304,
                40.50041319788582
              ],
              [
                -74.25329771551169,
                40.50031455051638
              ],
              [
                -74.25323293657812,
                40.50021851047876
              ],
              [
                -74.25316124492004,
                40.5001253764648
              ],
              [
                -74.25308286353142,
                40.500035438127114
              ],
              [
                -74.25299803620874,
                40.499948975178405
              ],
              [
                -74.25290702679237,
                40.49986625652177
              ],
              [
                -74.25281011834669,
                40.49978753941442
              ],
              [
                -74.2527076122783,
                40.49971306866778
              ],
              [
                -74.25259982739979,
                40.499643075886304
              ],
              [
                -74.25242564997141,
                40.49957766881967
              ],
              [
                -74.25225360988088,
                40.499509044716525
              ],
              [
                -74.25208380907416,
                40.49943724424436
              ],
              [
                -74.252074841391,
                40.499433209856335
              ],
              [
                -74.25200034554481,
                40.49940041958595
              ],
              [
                -74.25191210542215,
                40.49936321333001
              ],
              [
                -74.25172949863416,
                40.49929161551245
              ],
              [
                -74.25155650484206,
                40.49923017418809
              ],
              [
                -74.25147052929267,
                40.49919119562997
              ],
              [
                -74.25138828217871,
                40.499147781048116
              ],
              [
                -74.25131015560194,
                40.499100137419504
              ],
              [
                -74.25123652201721,
                40.4990484918825
              ],
              [
                -74.25116773245702,
                40.4989930906537
              ],
              [
                -74.251104114858,
                40.49893419785413
              ],
              [
                -74.25106157647164,
                40.4988306029857
              ],
              [
                -74.2510279779697,
                40.498725111052515
              ],
              [
                -74.25100346233354,
                40.49861817111828
              ],
              [
                -74.2509881338781,
                40.49851023840835
              ],
              [
                -74.25098698642542,
                40.49849804296945
              ],
              [
                -74.25099121823624,
                40.498428800060296
              ],
              [
                -74.25098792093397,
                40.49835952754913
              ],
              [
                -74.25097711721169,
                40.49829070170703
              ],
              [
                -74.25095888137142,
                40.49822279573273
              ],
              [
                -74.25093333881192,
                40.4981562764991
              ],
              [
                -74.25090066516684,
                40.498091601343454
              ],
              [
                -74.25086108509564,
                40.498029214923285
              ],
              [
                -74.25085489422248,
                40.498006163595136
              ],
              [
                -74.25068311794705,
                40.49784756275831
              ],
              [
                -74.25050715142565,
                40.49769166223741
              ],
              [
                -74.2503270675016,
                40.497538526543714
              ],
              [
                -74.250100267188,
                40.497354244535046
              ],
              [
                -74.2499336255057,
                40.4972089772461
              ],
              [
                -74.24968820467004,
                40.49699021215355
              ],
              [
                -74.2495597836633,
                40.49685685437291
              ],
              [
                -74.24917156868965,
                40.4965672970534
              ],
              [
                -74.24903761862493,
                40.49650027195716
              ],
              [
                -74.24887842613946,
                40.49643271979045
              ],
              [
                -74.24871501543038,
                40.49637131790576
              ],
              [
                -74.24854779387996,
                40.49631621937368
              ],
              [
                -74.24837717836708,
                40.496267561550304
              ],
              [
                -74.24820359422863,
                40.49622546573579
              ],
              [
                -74.24802747419955,
                40.49619003687112
              ],
              [
                -74.24784925733391,
                40.49616136327729
              ],
              [
                -74.24766938791153,
                40.49613951643462
              ],
              [
                -74.24748831433008,
                40.49612455080527
              ],
              [
                -74.24730648798806,
                40.49611650369693
              ],
              [
                -74.24712436215972,
                40.496115395170364
              ],
              [
                -74.24694239086584,
                40.49612122798881
              ],
              [
                -74.2467610277419,
                40.49613398761175
              ],
              [
                -74.24660328568625,
                40.49623003278636
              ],
              [
                -74.24645131588674,
                40.496331357051396
              ],
              [
                -74.24630542124939,
                40.496437758465184
              ],
              [
                -74.24616589257572,
                40.4965490249669
              ],
              [
                -74.24603300798422,
                40.49666493479789
              ],
              [
                -74.24590703235593,
                40.49678525694413
              ],
              [
                -74.24582465194058,
                40.49683570338224
              ],
              [
                -74.24565949545182,
                40.49694255110184
              ],
              [
                -74.24548938982635,
                40.49704477386729
              ],
              [
                -74.24531455716688,
                40.49714223819553
              ],
              [
                -74.24513522575204,
                40.497234816816444
              ],
              [
                -74.24495162973764,
                40.497322388839386
              ],
              [
                -74.24476400885132,
                40.4974048399108
              ],
              [
                -74.2446607989485,
                40.49744998729183
              ],
              [
                -74.24455328231618,
                40.497488838279466
              ],
              [
                -74.2444421167878,
                40.497521155164165
              ],
              [
                -74.24432798252589,
                40.49754674021474
              ],
              [
                -74.24421157785997,
                40.49756543688878
              ],
              [
                -74.24409361501311,
                40.49757713079004
              ],
              [
                -74.24397481574327,
                40.49758175036925
              ],
              [
                -74.2438559069274,
                40.49757926736139
              ],
              [
                -74.24370785133765,
                40.497573403749776
              ],
              [
                -74.24355959879793,
                40.49757279451684
              ],
              [
                -74.24341146909926,
                40.49757744097689
              ],
              [
                -74.24326378176856,
                40.49758733310723
              ],
              [
                -74.24311685537805,
                40.49760244956965
              ],
              [
                -74.24297100685976,
                40.49762275775669
              ],
              [
                -74.24287797904488,
                40.49763312072611
              ],
              [
                -74.24278408346595,
                40.4976367072458
              ],
              [
                -74.24269016522427,
                40.49763348503525
              ],
              [
                -74.24259706962503,
                40.497623483096014
              ],
              [
                -74.24250563456835,
                40.497606791449726
              ],
              [
                -74.24241668300726,
                40.497583560328465
              ],
              [
                -74.24233101554053,
                40.497553998821914
              ],
              [
                -74.2422959425381,
                40.49753288938479
              ],
              [
                -74.24225688738737,
                40.49751634916923
              ],
              [
                -74.24221486197615,
                40.49750480671827
              ],
              [
                -74.2421709551481,
                40.497498561086594
              ],
              [
                -74.24212630449081,
                40.497497774093134
              ],
              [
                -74.24208206686333,
                40.49750246612825
              ],
              [
                -74.2420393884239,
                40.49751251562544
              ],
              [
                -74.24199937493485,
                40.497527662211
              ],
              [
                -74.24187688116483,
                40.49756118693905
              ],
              [
                -74.24175158601285,
                40.49758800822129
              ],
              [
                -74.24162411809742,
                40.49760799149187
              ],
              [
                -74.24149511694067,
                40.49762103649198
              ],
              [
                -74.24136522975829,
                40.49762707777318
              ],
              [
                -74.24123510821227,
                40.49762608502554
              ],
              [
                -74.24110540514047,
                40.497618063229794
              ],
              [
                -74.24105816616475,
                40.49763131601914
              ],
              [
                -74.24100907146406,
                40.497639769128426
              ],
              [
                -74.24095895398746,
                40.497643279140284
              ],
              [
                -74.24090866403729,
                40.49764178650347
              ],
              [
                -74.24085905484203,
                40.497635316542414
              ],
              [
                -74.24081096808003,
                40.4976239790273
              ],
              [
                -74.24072398281064,
                40.49761370204587
              ],
              [
                -74.24063614005212,
                40.49760943006092
              ],
              [
                -74.24054814968598,
                40.49761119759537
              ],
              [
                -74.24046072278644,
                40.4976189903655
              ],
              [
                -74.24037456587457,
                40.49763274539565
              ],
              [
                -74.24031317342877,
                40.49763959876344
              ],
              [
                -74.24025112808033,
                40.497639637089094
              ],
              [
                -74.24018972118647,
                40.497632859575205
              ],
              [
                -74.24013023081552,
                40.49761940728271
              ],
              [
                -74.24007389514546,
                40.49759956019565
              ],
              [
                -74.2400451582977,
                40.497585739339115
              ],
              [
                -74.24001396511112,
                40.49757548134176
              ],
              [
                -74.23998106772962,
                40.49756903354879
              ],
              [
                -74.23994725938827,
                40.4975665514317
              ],
              [
                -74.23991335528727,
                40.49756809484061
              ],
              [
                -74.23988017293566,
                40.497573626560055
              ],
              [
                -74.23984851243979,
                40.49758301320702
              ],
              [
                -74.23981913721121,
                40.49759602844687
              ],
              [
                -74.23976289569347,
                40.4976264984457
              ],
              [
                -74.23971237574322,
                40.49766233705474
              ],
              [
                -74.2396684504129,
                40.497702924941784
              ],
              [
                -74.23944024302581,
                40.497938579618044
              ],
              [
                -74.23895150706927,
                40.49844338406652
              ],
              [
                -74.238513216357,
                40.49889607636883
              ],
              [
                -74.23844104903398,
                40.49893585618122
              ],
              [
                -74.23809649099027,
                40.49912578383293
              ],
              [
                -74.23804363965198,
                40.49914858304881
              ],
              [
                -74.23798737378735,
                40.499165980841035
              ],
              [
                -74.23792863579281,
                40.49917768581352
              ],
              [
                -74.23786840947194,
                40.499183501919156
              ],
              [
                -74.23781982777892,
                40.49918384554615
              ],
              [
                -74.23777146009982,
                40.49918035138809
              ],
              [
                -74.23774805247368,
                40.49910084537648
              ],
              [
                -74.23766770359761,
                40.49912325493375
              ],
              [
                -74.23768862641342,
                40.49917275625364
              ],
              [
                -74.23759977511978,
                40.49928505304476
              ],
              [
                -74.23750471509992,
                40.49939434702927
              ],
              [
                -74.23740362019552,
                40.499500438300515
              ],
              [
                -74.23729667529008,
                40.499603132808225
              ],
              [
                -74.23718407596924,
                40.499702242714584
              ],
              [
                -74.23706602816397,
                40.49979758673749
              ],
              [
                -74.23700550824262,
                40.499855065773005
              ],
              [
                -74.23693873586555,
                40.49990835789935
              ],
              [
                -74.2368662059753,
                40.499957068083674
              ],
              [
                -74.23678845619574,
                40.5000008352563
              ],
              [
                -74.23670606284715,
                40.5000393349876
              ],
              [
                -74.23661963667443,
                40.50007228189297
              ],
              [
                -74.23652981831931,
                40.50009943174851
              ],
              [
                -74.23647263236712,
                40.5001123997492
              ],
              [
                -74.2364287410083,
                40.50007651086883
              ],
              [
                -74.23635177807365,
                40.5001236719496
              ],
              [
                -74.23625245135796,
                40.50018365226724
              ],
              [
                -74.23610536564176,
                40.500266216723226
              ],
              [
                -74.23595390999242,
                40.500344041464494
              ],
              [
                -74.23587771910799,
                40.50035851618023
              ],
              [
                -74.23580342653713,
                40.500377906471954
              ],
              [
                -74.23573157542357,
                40.50040207058007
              ],
              [
                -74.23566269106378,
                40.50043083184394
              ],
              [
                -74.2355972770665,
                40.500463979993775
              ],
              [
                -74.23552002834256,
                40.500506967683684
              ],
              [
                -74.23543854418718,
                40.50054512760353
              ],
              [
                -74.23535334118338,
                40.50057821782871
              ],
              [
                -74.23526495949417,
                40.50060602857461
              ],
              [
                -74.23517395943664,
                40.500628383527115
              ],
              [
                -74.23508091792974,
                40.50064514096061
              ],
              [
                -74.23501360836578,
                40.500653615099154
              ],
              [
                -74.23494577701936,
                40.50065915779635
              ],
              [
                -74.23441378278534,
                40.50077409423288
              ],
              [
                -74.23359904119728,
                40.50091253160123
              ],
              [
                -74.23321049636206,
                40.5010956471732
              ],
              [
                -74.23314424618417,
                40.50107932001994
              ],
              [
                -74.23304744169111,
                40.501101772830395
              ],
              [
                -74.23300896759184,
                40.50111918841883
              ],
              [
                -74.2327259017227,
                40.5012524573491
              ],
              [
                -74.23243813174554,
                40.50137972107159
              ],
              [
                -74.23214587623688,
                40.501500882911124
              ],
              [
                -74.23184935718473,
                40.50161585082739
              ],
              [
                -74.2315487998192,
                40.50172453748478
              ],
              [
                -74.23124443244176,
                40.501826860318886
              ],
              [
                -74.23123357692766,
                40.50181755087982
              ],
              [
                -74.23122469229227,
                40.50181644230333
              ],
              [
                -74.2312232711201,
                40.50181685411036
              ],
              [
                -74.23120353968633,
                40.501822855627815
              ],
              [
                -74.2311805719308,
                40.50183722714867
              ],
              [
                -74.23114494958575,
                40.50185121588201
              ],
              [
                -74.23095331081439,
                40.501886954478714
              ],
              [
                -74.23088112029114,
                40.501901208711786
              ],
              [
                -74.2308523804173,
                40.50190801937489
              ],
              [
                -74.23084851211847,
                40.50190886380689
              ],
              [
                -74.23082342089151,
                40.50191433374283
              ],
              [
                -74.23079425749847,
                40.50192014659034
              ],
              [
                -74.23076490778703,
                40.50192545536911
              ],
              [
                -74.23073539346244,
                40.50193025333399
              ],
              [
                -74.23070573318452,
                40.501934541787655
              ],
              [
                -74.23067594493172,
                40.501938315332694
              ],
              [
                -74.23064604933,
                40.50194157258755
              ],
              [
                -74.23061606414724,
                40.50194431066832
              ],
              [
                -74.23058600979103,
                40.5019465285285
              ],
              [
                -74.23057002023408,
                40.50194699925312
              ],
              [
                -74.23055401780135,
                40.50194685465975
              ],
              [
                -74.23053804483887,
                40.50194609131261
              ],
              [
                -74.23052214460141,
                40.50194471465344
              ],
              [
                -74.23050635571904,
                40.50194272560978
              ],
              [
                -74.23049071815454,
                40.50194012962992
              ],
              [
                -74.23047527187609,
                40.501936933837634
              ],
              [
                -74.23046005729444,
                40.50193314636069
              ],
              [
                -74.23044929215258,
                40.50192997299455
              ],
              [
                -74.23043886991823,
                40.501926190633455
              ],
              [
                -74.23042885080227,
                40.501921821270734
              ],
              [
                -74.2304192906361,
                40.50191688992387
              ],
              [
                -74.23041024635407,
                40.50191142328385
              ],
              [
                -74.23040176854838,
                40.50190545442037
              ],
              [
                -74.23039390320254,
                40.50189901657971
              ],
              [
                -74.2303894844148,
                40.5018907998428
              ],
              [
                -74.23038426772325,
                40.501882862306545
              ],
              [
                -74.2303805628857,
                40.50187815357698
              ],
              [
                -74.23036582286555,
                40.50185558825123
              ],
              [
                -74.2303628392935,
                40.50184937615704
              ],
              [
                -74.23035422834207,
                40.50183144768081
              ],
              [
                -74.23034718449794,
                40.50181545841367
              ],
              [
                -74.23034304643087,
                40.501807307956355
              ],
              [
                -74.23033892500185,
                40.501804178371984
              ],
              [
                -74.23033358226388,
                40.50180481767264
              ],
              [
                -74.23032989415533,
                40.501808276383215
              ],
              [
                -74.23032743945022,
                40.50181173211535
              ],
              [
                -74.23033285397625,
                40.50183180571128
              ],
              [
                -74.23033607342072,
                40.50185131829552
              ],
              [
                -74.23033663156602,
                40.50185470549133
              ],
              [
                -74.23033265625148,
                40.50186016292164
              ],
              [
                -74.23033039937631,
                40.50186326258836
              ],
              [
                -74.23032228170659,
                40.501865187060595
              ],
              [
                -74.2303144167312,
                40.501867646286954
              ],
              [
                -74.23030686452938,
                40.50187062423134
              ],
              [
                -74.2302996869051,
                40.50187409597532
              ],
              [
                -74.23028347096967,
                40.50188211489214
              ],
              [
                -74.23026685720333,
                40.501889644409026
              ],
              [
                -74.2302498690459,
                40.50189667174638
              ],
              [
                -74.23023253608234,
                40.50190318427989
              ],
              [
                -74.2302148848441,
                40.50190917491978
              ],
              [
                -74.23019694119272,
                40.50191463305972
              ],
              [
                -74.23017873406381,
                40.50191954875715
              ],
              [
                -74.23016029131371,
                40.50192391709752
              ],
              [
                -74.22998637564557,
                40.50199690273843
              ],
              [
                -74.22996239099061,
                40.50200342588136
              ],
              [
                -74.22993870875453,
                40.50201058570158
              ],
              [
                -74.22991536204442,
                40.50201837291859
              ],
              [
                -74.22989238527848,
                40.50202677640676
              ],
              [
                -74.22984406719716,
                40.50204480572096
              ],
              [
                -74.229795465952,
                40.50206238157272
              ],
              [
                -74.22974658812029,
                40.502079501770424
              ],
              [
                -74.22973131345135,
                40.50208287995639
              ],
              [
                -74.22971584237062,
                40.50208568758759
              ],
              [
                -74.22970021195388,
                40.50208792023397
              ],
              [
                -74.229684458175,
                40.50208957229493
              ],
              [
                -74.22966861789233,
                40.5020906400109
              ],
              [
                -74.22963939213257,
                40.50209150867983
              ],
              [
                -74.22961022163645,
                40.50209313212579
              ],
              [
                -74.22958114326791,
                40.50209550859965
              ],
              [
                -74.22953936378703,
                40.502101795518115
              ],
              [
                -74.2294974050212,
                40.50210738769353
              ],
              [
                -74.22945528716917,
                40.50211228692818
              ],
              [
                -74.2294384115576,
                40.502115057792956
              ],
              [
                -74.22942138198772,
                40.50211722132797
              ],
              [
                -74.22941755167608,
                40.502117568236
              ],
              [
                -74.22938787284336,
                40.50212033503606
              ],
              [
                -74.22935829308656,
                40.50212370608246
              ],
              [
                -74.22932883346786,
                40.50212767965797
              ],
              [
                -74.22929952338401,
                40.502132252353334
              ],
              [
                -74.2292703849778,
                40.50213741759058
              ],
              [
                -74.22924144633903,
                40.50214317481132
              ],
              [
                -74.22922144946841,
                40.50214884329824
              ],
              [
                -74.22920120186964,
                40.50215397316742
              ],
              [
                -74.22918073095957,
                40.50215855950568
              ],
              [
                -74.22916006173955,
                40.5021625967343
              ],
              [
                -74.22913921811272,
                40.502166079277025
              ],
              [
                -74.2291182279392,
                40.502169003225
              ],
              [
                -74.22909711797962,
                40.502171364168646
              ],
              [
                -74.22907591039723,
                40.502173161058785
              ],
              [
                -74.22905463305723,
                40.50217439149412
              ],
              [
                -74.22900325374319,
                40.50218160422415
              ],
              [
                -74.22895205756525,
                40.50218955387006
              ],
              [
                -74.22890106798002,
                40.50219823318143
              ],
              [
                -74.22886006514685,
                40.502202488831685
              ],
              [
                -74.22881893708691,
                40.502206002889466
              ],
              [
                -74.22877770924977,
                40.502208772288284
              ],
              [
                -74.22873640620917,
                40.50221079463369
              ],
              [
                -74.22860528683556,
                40.50222488929053
              ],
              [
                -74.22854942780755,
                40.50223459924777
              ],
              [
                -74.2284938457047,
                40.50224519955648
              ],
              [
                -74.22843856574725,
                40.50225668530872
              ],
              [
                -74.22842283627269,
                40.502257570358175
              ],
              [
                -74.22840707060399,
                40.502257882688895
              ],
              [
                -74.22839130144581,
                40.50225762324074
              ],
              [
                -74.22837556698457,
                40.50225679143533
              ],
              [
                -74.2283599010297,
                40.50225539038834
              ],
              [
                -74.2283443424341,
                40.502253422032936
              ],
              [
                -74.22832892150001,
                40.50225089066464
              ],
              [
                -74.228313695154,
                40.50224891343502
              ],
              [
                -74.22829835803543,
                40.5022475319967
              ],
              [
                -74.22828294593444,
                40.502246751304384
              ],
              [
                -74.22826750275328,
                40.50224657395113
              ],
              [
                -74.22825206733614,
                40.50224699935704
              ],
              [
                -74.22823668093693,
                40.50224802576499
              ],
              [
                -74.22822138349655,
                40.50224965259281
              ],
              [
                -74.22820429822288,
                40.50225127091178
              ],
              [
                -74.22807670514588,
                40.502244222896415
              ],
              [
                -74.22794877997727,
                40.50224374463546
              ],
              [
                -74.22782110258426,
                40.50224983829664
              ],
              [
                -74.22769425171127,
                40.50226247625823
              ],
              [
                -74.22756880235707,
                40.50228160123398
              ],
              [
                -74.227431805054,
                40.50229293598351
              ],
              [
                -74.22729413510996,
                40.50229749945574
              ],
              [
                -74.22715636630399,
                40.502295272631336
              ],
              [
                -74.22701907282793,
                40.50228626479098
              ],
              [
                -74.2268828268918,
                40.502270513477725
              ],
              [
                -74.2267481963388,
                40.50224808433943
              ],
              [
                -74.22661574227789,
                40.50221907085599
              ],
              [
                -74.22648601674535,
                40.502183593949056
              ],
              [
                -74.226359560403,
                40.50214180147847
              ],
              [
                -74.22623690028469,
                40.50209386762525
              ],
              [
                -74.22611854759943,
                40.50203999216611
              ],
              [
                -74.22591092670548,
                40.501969983786225
              ],
              [
                -74.22570064658578,
                40.50190476436038
              ],
              [
                -74.22548789675064,
                40.50184439266374
              ],
              [
                -74.22527286893484,
                40.50178892310292
              ],
              [
                -74.22520703860451,
                40.50177404094029
              ],
              [
                -74.22513921519668,
                40.50176590112838
              ],
              [
                -74.22507057837701,
                40.501764645244286
              ],
              [
                -74.22500232195851,
                40.50177029513202
              ],
              [
                -74.22493563313823,
                40.50178275252199
              ],
              [
                -74.2248716718487,
                40.50180180074029
              ],
              [
                -74.2248115505849,
                40.50182710847738
              ],
              [
                -74.22475631505407,
                40.50185823555003
              ],
              [
                -74.22444440868637,
                40.501962743254694
              ],
              [
                -74.22432670677236,
                40.50202203875754
              ],
              [
                -74.22421217259708,
                40.50208484512533
              ],
              [
                -74.22410098578254,
                40.50215106386502
              ],
              [
                -74.22399332070259,
                40.50222059113186
              ],
              [
                -74.2239180481101,
                40.50224773415632
              ],
              [
                -74.22384005373509,
                40.502269952850824
              ],
              [
                -74.22375988929225,
                40.50228709004475
              ],
              [
                -74.22367812184865,
                40.50229902451279
              ],
              [
                -74.22359532981133,
                40.50230567183279
              ],
              [
                -74.22351209883621,
                40.50230698498269
              ],
              [
                -74.22342901768423,
                40.50230295467355
              ],
              [
                -74.22339445635099,
                40.50230568182113
              ],
              [
                -74.22336089192405,
                40.50231253827547
              ],
              [
                -74.22332916560721,
                40.50232335219783
              ],
              [
                -74.22330007253782,
                40.5023378525658
              ],
              [
                -74.22327434185924,
                40.5023556759657
              ],
              [
                -74.22325261844665,
                40.502376375700514
              ],
              [
                -74.22320926914568,
                40.502410464516764
              ],
              [
                -74.2231597130021,
                40.5024392054374
              ],
              [
                -74.2231050708459,
                40.50246194841403
              ],
              [
                -74.22304657854346,
                40.502478179056766
              ],
              [
                -74.2229855590458,
                40.50248753026786
              ],
              [
                -74.22291062035362,
                40.502505910665775
              ],
              [
                -74.2228336895983,
                40.50251862120146
              ],
              [
                -74.22275550111316,
                40.502525540547666
              ],
              [
                -74.2227210401853,
                40.50252722753434
              ],
              [
                -74.22268651324718,
                40.502526788858574
              ],
              [
                -74.2226481858538,
                40.50252379471869
              ],
              [
                -74.22261036634667,
                40.50251818226515
              ],
              [
                -74.22257824500184,
                40.50251123682859
              ],
              [
                -74.2225469303907,
                40.50250240175186
              ],
              [
                -74.22250931569815,
                40.50249973388721
              ],
              [
                -74.22247162604596,
                40.50250169050246
              ],
              [
                -74.22243483089085,
                40.50250822126949
              ],
              [
                -74.22239987668146,
                40.502519158203114
              ],
              [
                -74.22236766251469,
                40.502534219982635
              ],
              [
                -74.22233901700922,
                40.50255301918697
              ],
              [
                -74.22228634770266,
                40.50257423410402
              ],
              [
                -74.2222303278366,
                40.502589657353354
              ],
              [
                -74.22217204406495,
                40.50259898975985
              ],
              [
                -74.22211262695768,
                40.502602050296346
              ],
              [
                -74.22205322906996,
                40.50259877959542
              ],
              [
                -74.22199500258405,
                40.50258924110122
              ],
              [
                -74.22193907695905,
                40.50257361983842
              ],
              [
                -74.22159300278173,
                40.50250043822094
              ],
              [
                -74.22188242299993,
                40.502674840999866
              ],
              [
                -74.22321582499997,
                40.508658610999866
              ],
              [
                -74.22290251399997,
                40.50893653899991
              ],
              [
                -74.22663407199991,
                40.517251009999946
              ],
              [
                -74.22690993299994,
                40.51917139799991
              ],
              [
                -74.226710219,
                40.51912029999991
              ],
              [
                -74.22651508599992,
                40.5190560909999
              ],
              [
                -74.22632671899993,
                40.518979159999944
              ],
              [
                -74.22614720799999,
                40.51889020799993
              ],
              [
                -74.22597849399989,
                40.51879018499991
              ],
              [
                -74.22582224199992,
                40.51868027699985
              ],
              [
                -74.22567982799994,
                40.51856182999992
              ],
              [
                -74.22549932099996,
                40.51850518799994
              ],
              [
                -74.22531657499994,
                40.518452893999886
              ],
              [
                -74.22513176899994,
                40.51840500099993
              ],
              [
                -74.22494508699998,
                40.518361553999945
              ],
              [
                -74.22475670999995,
                40.51832259699992
              ],
              [
                -74.22456682599996,
                40.5182881689999
              ],
              [
                -74.22437561999999,
                40.51825830199993
              ],
              [
                -74.22418327999995,
                40.51823302699989
              ],
              [
                -74.22398999699992,
                40.518212367999894
              ],
              [
                -74.22379595899992,
                40.5181963459999
              ],
              [
                -74.223650643,
                40.518218936999844
              ],
              [
                -74.22350680499993,
                40.518246492999886
              ],
              [
                -74.2233647369999,
                40.51827895999992
              ],
              [
                -74.22322472599994,
                40.5183162709999
              ],
              [
                -74.22308705699992,
                40.51835835099989
              ],
              [
                -74.22295200699995,
                40.51840511399991
              ],
              [
                -74.22281984999994,
                40.518456466999915
              ],
              [
                -74.22269085399991,
                40.518512303999934
              ],
              [
                -74.22013810199992,
                40.5196218829999
              ],
              [
                -74.21802828299992,
                40.52066372699995
              ],
              [
                -74.21760570099997,
                40.520871686999925
              ],
              [
                -74.21735501099992,
                40.52093004899991
              ],
              [
                -74.21450845999988,
                40.52115497999994
              ],
              [
                -74.21354593399995,
                40.521235715999886
              ],
              [
                -74.2136147569999,
                40.52153163599995
              ],
              [
                -74.21381535699992,
                40.52249465099986
              ],
              [
                -74.21390912599999,
                40.523014630999945
              ],
              [
                -74.21398779899988,
                40.52345242299989
              ],
              [
                -74.21409806799986,
                40.52409719599991
              ],
              [
                -74.2156723039999,
                40.523957627999906
              ],
              [
                -74.215596992,
                40.52406106999996
              ],
              [
                -74.21559935899988,
                40.5243739019999
              ],
              [
                -74.21560783599998,
                40.52549473899997
              ],
              [
                -74.21564470499992,
                40.52598184799991
              ],
              [
                -74.21574341099993,
                40.52646400499992
              ],
              [
                -74.21586638599997,
                40.52664794199994
              ],
              [
                -74.2161939549999,
                40.52735009899994
              ],
              [
                -74.21648646999992,
                40.52792562699994
              ],
              [
                -74.21661081199987,
                40.52817658599992
              ],
              [
                -74.21666369900001,
                40.52828331599992
              ],
              [
                -74.21672062699997,
                40.52840575299991
              ],
              [
                -74.21684516999989,
                40.528642086999916
              ],
              [
                -74.21701386699988,
                40.52912208499992
              ],
              [
                -74.21728324499995,
                40.52987328299985
              ],
              [
                -74.21752355299995,
                40.530643798999925
              ],
              [
                -74.21775551799992,
                40.53144884799992
              ],
              [
                -74.21800676499996,
                40.53229858999993
              ],
              [
                -74.2182685219999,
                40.534525247999966
              ],
              [
                -74.21829305299998,
                40.534765002999976
              ],
              [
                -74.2183151519999,
                40.535312078999915
              ],
              [
                -74.21828207299988,
                40.53548704299991
              ],
              [
                -74.21819886099995,
                40.53585901999993
              ],
              [
                -74.21806225299997,
                40.536496512999854
              ],
              [
                -74.21791053499996,
                40.53702886099995
              ],
              [
                -74.21786779799996,
                40.53736657899988
              ],
              [
                -74.21781643899992,
                40.537655948999934
              ],
              [
                -74.21779984299988,
                40.53774949499996
              ],
              [
                -74.21773895399996,
                40.538030206999935
              ],
              [
                -74.21770976099997,
                40.53831199299994
              ],
              [
                -74.21767785799992,
                40.5401315469999
              ],
              [
                -74.21775655499991,
                40.5409867929999
              ],
              [
                -74.21776941599992,
                40.54112662999992
              ],
              [
                -74.21800676099997,
                40.54182852099994
              ],
              [
                -74.21803946399996,
                40.54191335999996
              ],
              [
                -74.21817195699992,
                40.54225028499995
              ],
              [
                -74.21844432399992,
                40.54296409499994
              ],
              [
                -74.21873688699995,
                40.54366800399986
              ],
              [
                -74.21888584299994,
                40.544070039999895
              ],
              [
                -74.21899304699997,
                40.54430991699995
              ],
              [
                -74.21929684599996,
                40.54505270699986
              ],
              [
                -74.21954309299988,
                40.54569938399987
              ],
              [
                -74.21981449699993,
                40.546438396999925
              ],
              [
                -74.21987141799997,
                40.54657677199991
              ],
              [
                -74.21997344699999,
                40.546834415999854
              ],
              [
                -74.22008210999998,
                40.54713827099988
              ],
              [
                -74.22036701999991,
                40.54783386999986
              ],
              [
                -74.2205699459999,
                40.54839063799991
              ],
              [
                -74.22060624599999,
                40.54848651999988
              ],
              [
                -74.22064536199997,
                40.54859150899989
              ],
              [
                -74.22083496899992,
                40.549091739999895
              ],
              [
                -74.22087752099993,
                40.54920399899988
              ],
              [
                -74.22109340299987,
                40.5497735559999
              ],
              [
                -74.22131562699998,
                40.550253073999926
              ],
              [
                -74.2212741239999,
                40.550336848999926
              ],
              [
                -74.22121805099987,
                40.55041641999993
              ],
              [
                -74.2211486979999,
                40.55048965799989
              ],
              [
                -74.22106803199998,
                40.550554733999945
              ],
              [
                -74.21931406599998,
                40.552055134999875
              ],
              [
                -74.219115764,
                40.55223231399988
              ],
              [
                -74.21877630399993,
                40.552473628999955
              ],
              [
                -74.21778377699997,
                40.55304918799994
              ],
              [
                -74.21777232699998,
                40.5532448849999
              ],
              [
                -74.21783047799995,
                40.553438685999886
              ],
              [
                -74.2178705549999,
                40.5536361769999
              ],
              [
                -74.21789204399995,
                40.55383585499987
              ],
              [
                -74.21789469299988,
                40.554036199999906
              ],
              [
                -74.21787855399997,
                40.55423566199992
              ],
              [
                -74.21784397899994,
                40.55443271799991
              ],
              [
                -74.21721338099994,
                40.55471485899989
              ],
              [
                -74.21525777199992,
                40.555437837999925
              ],
              [
                -74.21509339699995,
                40.55549893299997
              ],
              [
                -74.21491900899998,
                40.555545456999916
              ],
              [
                -74.21473815899992,
                40.555576114999965
              ],
              [
                -74.21473802999996,
                40.55557613299987
              ],
              [
                -74.21473791099994,
                40.55557614399985
              ],
              [
                -74.21455430499994,
                40.5555903089999
              ],
              [
                -74.21455422199988,
                40.55559031799988
              ],
              [
                -74.21455413999996,
                40.55559031799992
              ],
              [
                -74.21437138899988,
                40.5555880659999
              ],
              [
                -74.213501756,
                40.55566761799985
              ],
              [
                -74.21220034099993,
                40.55644342999989
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000163198117339,
        "objectid": 47,
        "shape_leng": 0.0898275563294,
        "location_id": 47,
        "zone": "Claremont/Bathgate",
        "borough": "Bronx"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.89090203399996,
                40.854116885999886
              ],
              [
                -73.8906903199999,
                40.854353261999954
              ],
              [
                -73.89038953999983,
                40.85468905799998
              ],
              [
                -73.89044259299993,
                40.85504635099993
              ],
              [
                -73.8907742849999,
                40.855811939999946
              ],
              [
                -73.89108676599993,
                40.85659725699986
              ],
              [
                -73.89109710400002,
                40.85677981399989
              ],
              [
                -73.8911055689998,
                40.85742738299994
              ],
              [
                -73.89111318899985,
                40.85785099899988
              ],
              [
                -73.89111115400003,
                40.85838432199988
              ],
              [
                -73.89111943300001,
                40.85865896199989
              ],
              [
                -73.89112461399988,
                40.8588700059999
              ],
              [
                -73.89112568799987,
                40.860061824999946
              ],
              [
                -73.89059489199987,
                40.86071569999991
              ],
              [
                -73.89027555599993,
                40.86110907299996
              ],
              [
                -73.89036823899993,
                40.86115807599987
              ],
              [
                -73.89067455199995,
                40.86132004099989
              ],
              [
                -73.89082169799998,
                40.86139939599989
              ],
              [
                -73.89106280699994,
                40.86152941199992
              ],
              [
                -73.89106059999996,
                40.861471814999945
              ],
              [
                -73.8911537559998,
                40.86091361599997
              ],
              [
                -73.89174394999986,
                40.860177146999945
              ],
              [
                -73.89225707599982,
                40.85940997599995
              ],
              [
                -73.8929935429999,
                40.85838611999987
              ],
              [
                -73.89441355099994,
                40.85643377599997
              ],
              [
                -73.89477596099992,
                40.85592952599996
              ],
              [
                -73.89549889799987,
                40.85474976599995
              ],
              [
                -73.8956088329998,
                40.854570369999884
              ],
              [
                -73.89662446799987,
                40.8527875899999
              ],
              [
                -73.89708706499987,
                40.85196827699987
              ],
              [
                -73.89808598699996,
                40.85021312399993
              ],
              [
                -73.89887482599984,
                40.84881118399989
              ],
              [
                -73.89967790699988,
                40.84742465799994
              ],
              [
                -73.90020067899994,
                40.84647356099991
              ],
              [
                -73.90088731499989,
                40.845241359999925
              ],
              [
                -73.90126635699991,
                40.84456221799993
              ],
              [
                -73.9013172989999,
                40.844473940999926
              ],
              [
                -73.901344046,
                40.84442875099995
              ],
              [
                -73.90268810099988,
                40.84458147199991
              ],
              [
                -73.90338651199983,
                40.84465529599993
              ],
              [
                -73.9041010739999,
                40.84473082199992
              ],
              [
                -73.90483121999989,
                40.84480034199988
              ],
              [
                -73.90483494099993,
                40.84475261899998
              ],
              [
                -73.90484219199982,
                40.844665447999944
              ],
              [
                -73.90484645099987,
                40.84458935999987
              ],
              [
                -73.90493735799986,
                40.84337489399997
              ],
              [
                -73.9050151499998,
                40.84240469799989
              ],
              [
                -73.90503176699985,
                40.842262944999916
              ],
              [
                -73.90515655599995,
                40.84104021299991
              ],
              [
                -73.90519207999992,
                40.84070758199993
              ],
              [
                -73.90527212299999,
                40.8404855589999
              ],
              [
                -73.90536832399985,
                40.84026688999993
              ],
              [
                -73.90548026199987,
                40.84005259199994
              ],
              [
                -73.90560739499988,
                40.8398436189999
              ],
              [
                -73.90538357999992,
                40.8397689829999
              ],
              [
                -73.90597940399996,
                40.83850519599985
              ],
              [
                -73.90625475300006,
                40.83806527999993
              ],
              [
                -73.90635674699993,
                40.837902326999924
              ],
              [
                -73.90646169600002,
                40.83773464999995
              ],
              [
                -73.90652353999998,
                40.83763584299988
              ],
              [
                -73.90679878899991,
                40.837196073999976
              ],
              [
                -73.90688497199996,
                40.837058376999885
              ],
              [
                -73.906975505,
                40.836913727999885
              ],
              [
                -73.90713954499992,
                40.83665163499993
              ],
              [
                -73.90721294199983,
                40.83653436399994
              ],
              [
                -73.90727112599994,
                40.83644139899997
              ],
              [
                -73.90733306999991,
                40.83634242699987
              ],
              [
                -73.90740200499981,
                40.836232282999966
              ],
              [
                -73.90757269399987,
                40.83592860399989
              ],
              [
                -73.90762665999985,
                40.83583258899993
              ],
              [
                -73.9077182169999,
                40.835669695999854
              ],
              [
                -73.90783293099987,
                40.83546559899987
              ],
              [
                -73.90797135199996,
                40.8352193239999
              ],
              [
                -73.90799132699986,
                40.8351837829999
              ],
              [
                -73.90810870199985,
                40.83497494999988
              ],
              [
                -73.90823980199985,
                40.83474169399996
              ],
              [
                -73.90837656,
                40.834498370999924
              ],
              [
                -73.90852151699984,
                40.83424045599991
              ],
              [
                -73.90857547999998,
                40.834144441999904
              ],
              [
                -73.90869131200002,
                40.833938346999894
              ],
              [
                -73.90882575999996,
                40.8336988109999
              ],
              [
                -73.90889804099996,
                40.833570034999894
              ],
              [
                -73.90910597799993,
                40.83319956599989
              ],
              [
                -73.909177781,
                40.83307163899987
              ],
              [
                -73.90923681499991,
                40.83296646099989
              ],
              [
                -73.90928354899982,
                40.83288319599993
              ],
              [
                -73.9093741529999,
                40.832721771999914
              ],
              [
                -73.90942257599983,
                40.83263549599992
              ],
              [
                -73.90946039499991,
                40.83256811599989
              ],
              [
                -73.90951895699989,
                40.83246377699988
              ],
              [
                -73.90962130699984,
                40.8322814219999
              ],
              [
                -73.91049010699992,
                40.830737372999884
              ],
              [
                -73.90971373799982,
                40.83047001399991
              ],
              [
                -73.909144659,
                40.83028572999997
              ],
              [
                -73.908959903,
                40.83022623299989
              ],
              [
                -73.90772165099985,
                40.829823367999914
              ],
              [
                -73.907527588,
                40.82976078199988
              ],
              [
                -73.90747341000002,
                40.829743308999916
              ],
              [
                -73.90657083599997,
                40.829452219999936
              ],
              [
                -73.90553116799988,
                40.8291041329999
              ],
              [
                -73.9045062189998,
                40.8306249629999
              ],
              [
                -73.90397692599986,
                40.83141130499994
              ],
              [
                -73.90367608499997,
                40.83185824799992
              ],
              [
                -73.90343501499996,
                40.83221638099998
              ],
              [
                -73.90185605899981,
                40.83462212499989
              ],
              [
                -73.90134101199988,
                40.835441323999866
              ],
              [
                -73.90122534999992,
                40.83549991099993
              ],
              [
                -73.90056610899994,
                40.83659250799992
              ],
              [
                -73.90038944699995,
                40.83690623099993
              ],
              [
                -73.90035730100003,
                40.83696331799991
              ],
              [
                -73.90030702699991,
                40.83705259399993
              ],
              [
                -73.9002588729999,
                40.83713810599989
              ],
              [
                -73.90022284799983,
                40.83720207899991
              ],
              [
                -73.90019045299977,
                40.837259606999964
              ],
              [
                -73.90014268099992,
                40.8373444399999
              ],
              [
                -73.90004236899989,
                40.8375225749999
              ],
              [
                -73.89996792899998,
                40.83765476299991
              ],
              [
                -73.89903034699984,
                40.839330476999876
              ],
              [
                -73.8982696629998,
                40.840679946999956
              ],
              [
                -73.89749111599987,
                40.84206708899993
              ],
              [
                -73.89666161699988,
                40.84352460699992
              ],
              [
                -73.89642177099995,
                40.84338795899992
              ],
              [
                -73.8961725309998,
                40.8432605479999
              ],
              [
                -73.89591487099987,
                40.84314291899991
              ],
              [
                -73.89564979700005,
                40.84303553999991
              ],
              [
                -73.89537836199995,
                40.842938778999965
              ],
              [
                -73.894847588,
                40.84364636299996
              ],
              [
                -73.89481942600001,
                40.84368377099986
              ],
              [
                -73.89479022099985,
                40.84372032199992
              ],
              [
                -73.89464252699995,
                40.843904139999964
              ],
              [
                -73.89398467599996,
                40.84470395899991
              ],
              [
                -73.89290102299996,
                40.84626832999992
              ],
              [
                -73.89337309899987,
                40.84640119399996
              ],
              [
                -73.89360906599997,
                40.84644525599989
              ],
              [
                -73.89384985999999,
                40.84647439399989
              ],
              [
                -73.8940930149999,
                40.846488222999945
              ],
              [
                -73.89433599299984,
                40.84648668099986
              ],
              [
                -73.89535848999982,
                40.84651803199993
              ],
              [
                -73.89617712999998,
                40.8466583209999
              ],
              [
                -73.89611702199986,
                40.84677851899995
              ],
              [
                -73.89557755999984,
                40.847854641999895
              ],
              [
                -73.89494627999981,
                40.84910485299988
              ],
              [
                -73.89407258999991,
                40.85071318999995
              ],
              [
                -73.89478764999993,
                40.85108166699989
              ],
              [
                -73.89431774099977,
                40.851891310999854
              ],
              [
                -73.89307140900002,
                40.8532499869999
              ],
              [
                -73.89216687399981,
                40.85276946199989
              ],
              [
                -73.89090203399996,
                40.854116885999886
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0000942538425377,
        "objectid": 48,
        "shape_leng": 0.0437467441431,
        "location_id": 48,
        "zone": "Clinton East",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.99117738199989,
                40.75532984899993
              ],
              [
                -73.99072636299991,
                40.75595040599996
              ],
              [
                -73.99027621399986,
                40.756567564999926
              ],
              [
                -73.98979085399995,
                40.75723313199991
              ],
              [
                -73.98929727499991,
                40.75790663699991
              ],
              [
                -73.98884253899993,
                40.75853349899993
              ],
              [
                -73.98839044599993,
                40.75916418599997
              ],
              [
                -73.98792640699999,
                40.75979027699988
              ],
              [
                -73.987475247,
                40.76040478699993
              ],
              [
                -73.98701514599995,
                40.76103736699991
              ],
              [
                -73.98656138499996,
                40.76166012199991
              ],
              [
                -73.98610406199981,
                40.76228995299988
              ],
              [
                -73.98564677799999,
                40.76291487399988
              ],
              [
                -73.98519152199997,
                40.76354367499996
              ],
              [
                -73.98473393799985,
                40.76416910499991
              ],
              [
                -73.98428230099987,
                40.76479181499995
              ],
              [
                -73.98382185700001,
                40.76541847499991
              ],
              [
                -73.98336657899995,
                40.766045241999926
              ],
              [
                -73.98287713499988,
                40.766715594999866
              ],
              [
                -73.98236545099999,
                40.7673921529999
              ],
              [
                -73.98521720299992,
                40.76859462999991
              ],
              [
                -73.98806289599996,
                40.769790799999925
              ],
              [
                -73.98855898599986,
                40.7691158169999
              ],
              [
                -73.98905077199991,
                40.768440091999906
              ],
              [
                -73.98950498200001,
                40.76781538199989
              ],
              [
                -73.98996382699985,
                40.7671874749999
              ],
              [
                -73.99042167999981,
                40.766561257999854
              ],
              [
                -73.99087599499985,
                40.7659342209999
              ],
              [
                -73.99133264599988,
                40.765309269999925
              ],
              [
                -73.99178834099983,
                40.76468386799991
              ],
              [
                -73.99224402800002,
                40.764056778999894
              ],
              [
                -73.9927014349998,
                40.76343080599987
              ],
              [
                -73.99315623899986,
                40.76280636099991
              ],
              [
                -73.99360930599991,
                40.76218343599989
              ],
              [
                -73.99406959,
                40.761552584999926
              ],
              [
                -73.9945259329999,
                40.760927044999924
              ],
              [
                -73.99498358099996,
                40.760300161999986
              ],
              [
                -73.99547450199992,
                40.7596268569999
              ],
              [
                -73.99594236599988,
                40.75898097899996
              ],
              [
                -73.99640941599995,
                40.75833915899992
              ],
              [
                -73.99663529299984,
                40.75801292199992
              ],
              [
                -73.996800897,
                40.757789159999945
              ],
              [
                -73.99685244899985,
                40.75773270899988
              ],
              [
                -73.99692177899989,
                40.757637581999894
              ],
              [
                -73.997233738,
                40.7572095429999
              ],
              [
                -73.99730671499998,
                40.757110506999894
              ],
              [
                -73.99577488199998,
                40.75645451499986
              ],
              [
                -73.99511286399995,
                40.756175773999864
              ],
              [
                -73.99500601599988,
                40.756136713999865
              ],
              [
                -73.99446824699999,
                40.75590886199993
              ],
              [
                -73.99163271299996,
                40.75470680499996
              ],
              [
                -73.99117738199989,
                40.75532984899993
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000203185525471,
        "objectid": 49,
        "shape_leng": 0.0752900183427,
        "location_id": 49,
        "zone": "Clinton Hill",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.96062056499981,
                40.69632013999994
              ],
              [
                -73.96092543799982,
                40.697736506999895
              ],
              [
                -73.96105100699991,
                40.69832607899989
              ],
              [
                -73.96189837799996,
                40.698188332999955
              ],
              [
                -73.96214289299981,
                40.69814945699991
              ],
              [
                -73.96233072899996,
                40.698119593999884
              ],
              [
                -73.96235903300003,
                40.698115091999966
              ],
              [
                -73.9623976909999,
                40.69810894499991
              ],
              [
                -73.96248295899996,
                40.698095383999956
              ],
              [
                -73.96283963699993,
                40.698038667999946
              ],
              [
                -73.96288219599991,
                40.69782702599988
              ],
              [
                -73.96295416199992,
                40.69760374899989
              ],
              [
                -73.96305480199987,
                40.69739287199993
              ],
              [
                -73.96317583599986,
                40.697187620999934
              ],
              [
                -73.96331636199999,
                40.69698969799986
              ],
              [
                -73.96347515199992,
                40.69680066799991
              ],
              [
                -73.96365073599986,
                40.69662194399994
              ],
              [
                -73.963803372,
                40.69649503799992
              ],
              [
                -73.963971858,
                40.69637865499993
              ],
              [
                -73.96415417899982,
                40.696274449999876
              ],
              [
                -73.96434801500007,
                40.69618376599989
              ],
              [
                -73.9645507049999,
                40.696107591999876
              ],
              [
                -73.96546721499983,
                40.695932161999956
              ],
              [
                -73.96641979599997,
                40.69596410599996
              ],
              [
                -73.96741363299996,
                40.695998628999895
              ],
              [
                -73.96840655900003,
                40.6960364839999
              ],
              [
                -73.9693945009999,
                40.696080388999846
              ],
              [
                -73.96937861700003,
                40.69599133399992
              ],
              [
                -73.9693719569999,
                40.69593775999996
              ],
              [
                -73.96935567900005,
                40.695854728999954
              ],
              [
                -73.9693409769999,
                40.69576842999993
              ],
              [
                -73.96880989600005,
                40.69312038199996
              ],
              [
                -73.96983118499983,
                40.69313424399997
              ],
              [
                -73.96952115199994,
                40.69156355899989
              ],
              [
                -73.96910362499993,
                40.68946752199987
              ],
              [
                -73.97013154199992,
                40.68950630799986
              ],
              [
                -73.9697831419999,
                40.68775161199994
              ],
              [
                -73.96948939899981,
                40.68628830699986
              ],
              [
                -73.96906202000002,
                40.68415736099991
              ],
              [
                -73.96867398499984,
                40.68208599399989
              ],
              [
                -73.96868961400001,
                40.68200525799989
              ],
              [
                -73.96753196799997,
                40.681768783999935
              ],
              [
                -73.96657247099982,
                40.681564813999955
              ],
              [
                -73.965510434,
                40.68134401999992
              ],
              [
                -73.96447930699999,
                40.681131147999956
              ],
              [
                -73.96442184899986,
                40.68111928799995
              ],
              [
                -73.96329205499984,
                40.680881585999884
              ],
              [
                -73.96134655899994,
                40.6804635239999
              ],
              [
                -73.95829216799997,
                40.67983113299993
              ],
              [
                -73.9559586239999,
                40.679346246999906
              ],
              [
                -73.95529361599996,
                40.67920315099984
              ],
              [
                -73.95530907499989,
                40.67927313499995
              ],
              [
                -73.95543681599985,
                40.679957854999934
              ],
              [
                -73.95436836300001,
                40.68007510699993
              ],
              [
                -73.95328187399996,
                40.68020003099985
              ],
              [
                -73.9533701749999,
                40.680640507999904
              ],
              [
                -73.95351616899988,
                40.681382599999914
              ],
              [
                -73.95366256199999,
                40.6821149039999
              ],
              [
                -73.95380893500004,
                40.682848007999944
              ],
              [
                -73.953954531,
                40.683580778999946
              ],
              [
                -73.95410042600001,
                40.684313107999856
              ],
              [
                -73.95424647699987,
                40.685046247999914
              ],
              [
                -73.95439296899998,
                40.68577971999988
              ],
              [
                -73.95453798699994,
                40.68651117499993
              ],
              [
                -73.95468418899998,
                40.68724485399986
              ],
              [
                -73.95684165199995,
                40.686996078999904
              ],
              [
                -73.95956770199984,
                40.686682554999955
              ],
              [
                -73.95971374799981,
                40.687415633999926
              ],
              [
                -73.959859395,
                40.68814745099991
              ],
              [
                -73.96000519799986,
                40.688881033999955
              ],
              [
                -73.96009714599994,
                40.68934520999992
              ],
              [
                -73.96012172900004,
                40.68946930699984
              ],
              [
                -73.96017256100002,
                40.68972986199987
              ],
              [
                -73.96018691899992,
                40.6898034559999
              ],
              [
                -73.9602230449999,
                40.6899886269999
              ],
              [
                -73.96023740299987,
                40.69006222199988
              ],
              [
                -73.96029281699998,
                40.690346249999955
              ],
              [
                -73.96013760799985,
                40.690364379999906
              ],
              [
                -73.96008336799996,
                40.69037071599989
              ],
              [
                -73.95957591899987,
                40.69042998799993
              ],
              [
                -73.95942791399997,
                40.69044727499993
              ],
              [
                -73.959348624,
                40.69045646099992
              ],
              [
                -73.95928133699987,
                40.69046425699991
              ],
              [
                -73.9589933049999,
                40.690497628999886
              ],
              [
                -73.95886713600001,
                40.69051224799996
              ],
              [
                -73.95857844799987,
                40.69054569399986
              ],
              [
                -73.95818048699985,
                40.690590729999876
              ],
              [
                -73.95808563499989,
                40.69060146399989
              ],
              [
                -73.95727249099987,
                40.69069347799987
              ],
              [
                -73.95635602999991,
                40.69079978199989
              ],
              [
                -73.95541057999996,
                40.69090829199994
              ],
              [
                -73.95582662799985,
                40.69299238299994
              ],
              [
                -73.95614239299985,
                40.69457901899991
              ],
              [
                -73.957087019,
                40.69447043999991
              ],
              [
                -73.95799732999993,
                40.69436583899993
              ],
              [
                -73.95931047999983,
                40.694215086999876
              ],
              [
                -73.96015854699986,
                40.694117308999914
              ],
              [
                -73.96062056499981,
                40.69632013999994
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000173249283326,
        "objectid": 50,
        "shape_leng": 0.0557479867536,
        "location_id": 50,
        "zone": "Clinton West",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.99667705784736,
                40.77351005394221
              ],
              [
                -73.99668544431172,
                40.77350368804627
              ],
              [
                -73.99656159477641,
                40.773410941950964
              ],
              [
                -73.99614329133087,
                40.77324754156346
              ],
              [
                -73.9962297200193,
                40.77312897504538
              ],
              [
                -73.99635558854986,
                40.77317889215508
              ],
              [
                -73.99644894667618,
                40.77305684199677
              ],
              [
                -73.99554492520829,
                40.77267481382618
              ],
              [
                -73.99545514995584,
                40.772802675029084
              ],
              [
                -73.99444501293378,
                40.77237712658827
              ],
              [
                -73.99477407930827,
                40.77192954753019
              ],
              [
                -73.99690946902788,
                40.77283294565996
              ],
              [
                -73.99711218538603,
                40.77255323669952
              ],
              [
                -73.99497688494725,
                40.77165877492328
              ],
              [
                -73.99497830771192,
                40.77164524947328
              ],
              [
                -73.99500915438713,
                40.771607339944175
              ],
              [
                -73.99492582120642,
                40.77151604240548
              ],
              [
                -73.99492385794417,
                40.771485605937436
              ],
              [
                -73.99493501658995,
                40.77146814657798
              ],
              [
                -73.99495733353699,
                40.77144919069773
              ],
              [
                -73.99499014482059,
                40.77144070980649
              ],
              [
                -73.99512395556874,
                40.77145073826707
              ],
              [
                -73.99530566365368,
                40.77119600490955
              ],
              [
                -73.99562306904355,
                40.771336957512865
              ],
              [
                -73.99562982240303,
                40.771325292628056
              ],
              [
                -73.99575123412814,
                40.77135428277978
              ],
              [
                -73.99572993314403,
                40.7713788187938
              ],
              [
                -73.99582805886249,
                40.77142166722245
              ],
              [
                -73.99579117748758,
                40.7714755188204
              ],
              [
                -73.99585437252816,
                40.77149918074849
              ],
              [
                -73.99589260894868,
                40.77144708752727
              ],
              [
                -73.99589990100937,
                40.771450298582096
              ],
              [
                -73.99600175749052,
                40.77130746533058
              ],
              [
                -73.99592803670667,
                40.77127510283766
              ],
              [
                -73.9959128360384,
                40.771297855623835
              ],
              [
                -73.99581687579034,
                40.77125651641555
              ],
              [
                -73.99579774020464,
                40.77128256276402
              ],
              [
                -73.99570859069954,
                40.77122581253301
              ],
              [
                -73.99575648212907,
                40.77116066846575
              ],
              [
                -73.99542474436292,
                40.77103161516796
              ],
              [
                -73.99576733283615,
                40.770554596456435
              ],
              [
                -73.99590077425023,
                40.770613123500894
              ],
              [
                -73.99601693196188,
                40.77045484888618
              ],
              [
                -73.99588004351683,
                40.77040057901225
              ],
              [
                -73.99631327022085,
                40.76979883633644
              ],
              [
                -73.9987620691431,
                40.770825056709214
              ],
              [
                -73.9990268283291,
                40.7704632729821
              ],
              [
                -73.9965712643956,
                40.769424568273905
              ],
              [
                -73.99689311312365,
                40.76897962881938
              ],
              [
                -73.99701071413831,
                40.76902879065363
              ],
              [
                -73.99715035477142,
                40.7688473877134
              ],
              [
                -73.99702628821062,
                40.76879551562383
              ],
              [
                -73.99707176039315,
                40.76873264357681
              ],
              [
                -73.99947253734007,
                40.76976421496914
              ],
              [
                -73.99951552161598,
                40.76976756583779
              ],
              [
                -73.99955518930786,
                40.769750807740046
              ],
              [
                -73.99973590247859,
                40.76950617733153
              ],
              [
                -73.999728191901,
                40.769473506633716
              ],
              [
                -73.9964571540504,
                40.768098815795625
              ],
              [
                -73.99681598463503,
                40.76760062185919
              ],
              [
                -73.99715588949206,
                40.76712869617279
              ],
              [
                -74.00038586489072,
                40.76850419542925
              ],
              [
                -74.000417653932,
                40.76851247996449
              ],
              [
                -74.00045489336712,
                40.768504195177414
              ],
              [
                -74.00064950530414,
                40.7682452361056
              ],
              [
                -74.00065016786206,
                40.768220374289214
              ],
              [
                -74.00062837395299,
                40.76820311105873
              ],
              [
                -73.99737808447954,
                40.766823386379585
              ],
              [
                -73.99772134825697,
                40.76634912055583
              ],
              [
                -73.9980667590535,
                40.76587188113573
              ],
              [
                -73.99814818647361,
                40.76590560707495
              ],
              [
                -73.99815678438965,
                40.7658940353741
              ],
              [
                -74.00129391014984,
                40.767241458241585
              ],
              [
                -74.00131469635994,
                40.76725112004994
              ],
              [
                -74.0013643461298,
                40.76724936447365
              ],
              [
                -74.00139437159504,
                40.76723004773296
              ],
              [
                -74.00156180575469,
                40.766996499368275
              ],
              [
                -74.00157335385272,
                40.766977192210646
              ],
              [
                -74.00154217871193,
                40.76694470253266
              ],
              [
                -73.99828160709528,
                40.765567775437326
              ],
              [
                -73.99827090308345,
                40.76555223341328
              ],
              [
                -73.99840202518257,
                40.76535090908762
              ],
              [
                -73.9984383037147,
                40.76531621383388
              ],
              [
                -73.99848276468678,
                40.76529753698438
              ],
              [
                -73.99858924105146,
                40.765276178806765
              ],
              [
                -73.99863371556073,
                40.76526016874122
              ],
              [
                -73.99866764711366,
                40.76523703534287
              ],
              [
                -73.99868870694561,
                40.765208570449786
              ],
              [
                -73.99869573037279,
                40.7651747656796
              ],
              [
                -73.99868637477196,
                40.7651373942677
              ],
              [
                -73.99866530473436,
                40.765104480747205
              ],
              [
                -73.99863487876338,
                40.765085794737736
              ],
              [
                -73.99859978707626,
                40.76507334028592
              ],
              [
                -73.99857638530527,
                40.76499860727085
              ],
              [
                -73.99888473174974,
                40.76456937704858
              ],
              [
                -73.9991083256454,
                40.76466249985752
              ],
              [
                -73.99919997246725,
                40.764701914982496
              ],
              [
                -74.00202454330339,
                40.76589326657038
              ],
              [
                -74.00205713831559,
                40.76587739041855
              ],
              [
                -74.00228802371483,
                40.76556154429785
              ],
              [
                -74.00227436642368,
                40.76552518109951
              ],
              [
                -73.99915098087908,
                40.76420525958266
              ],
              [
                -73.99943330724624,
                40.76382391019702
              ],
              [
                -73.99944642217294,
                40.76380619457846
              ],
              [
                -73.99951767096813,
                40.76370627918036
              ],
              [
                -73.99962263458193,
                40.763559081210495
              ],
              [
                -73.99963853555366,
                40.76356907099079
              ],
              [
                -73.99992712367724,
                40.76340652847929
              ],
              [
                -74.00073105253733,
                40.76375528636147
              ],
              [
                -74.00106364220507,
                40.76388820826289
              ],
              [
                -74.00308898015679,
                40.764744381986716
              ],
              [
                -74.00310088163066,
                40.7647457481358
              ],
              [
                -74.00311291631381,
                40.76474559615368
              ],
              [
                -74.00312475155104,
                40.76474393024101
              ],
              [
                -74.00313606019984,
                40.76474079644635
              ],
              [
                -74.00314652967363,
                40.764736281391826
              ],
              [
                -74.00315587058185,
                40.764730509880025
              ],
              [
                -74.00322275585351,
                40.76463803767021
              ],
              [
                -74.00316194577034,
                40.764612610454556
              ],
              [
                -74.00313458336218,
                40.76464959820679
              ],
              [
                -74.00306161234062,
                40.76466578265338
              ],
              [
                -74.00294809515721,
                40.76461683643389
              ],
              [
                -74.00314976988996,
                40.764332888184576
              ],
              [
                -74.0009730802201,
                40.763420271725614
              ],
              [
                -74.00138603698596,
                40.76285009500188
              ],
              [
                -74.00361214797076,
                40.76377704395488
              ],
              [
                -74.00375735018837,
                40.763582241820245
              ],
              [
                -74.00153309083484,
                40.76264459806374
              ],
              [
                -74.00133748499991,
                40.76220852199986
              ],
              [
                -74.00127796299991,
                40.76207582499988
              ],
              [
                -74.00115054199988,
                40.762025811999884
              ],
              [
                -74.00103587499999,
                40.76196835199992
              ],
              [
                -73.99831474500002,
                40.76082320899992
              ],
              [
                -73.99623223099987,
                40.7599460319999
              ],
              [
                -73.99547450199992,
                40.7596268569999
              ],
              [
                -73.99498358099996,
                40.760300161999986
              ],
              [
                -73.9945259329999,
                40.760927044999924
              ],
              [
                -73.99406959,
                40.761552584999926
              ],
              [
                -73.99360930599991,
                40.76218343599989
              ],
              [
                -73.99315623899986,
                40.76280636099991
              ],
              [
                -73.9927014349998,
                40.76343080599987
              ],
              [
                -73.99224402800002,
                40.764056778999894
              ],
              [
                -73.9917883399999,
                40.76468386799994
              ],
              [
                -73.99133264599988,
                40.765309269999925
              ],
              [
                -73.99087599499985,
                40.7659342209999
              ],
              [
                -73.99042167999981,
                40.766561257999854
              ],
              [
                -73.98996382699985,
                40.7671874749999
              ],
              [
                -73.98950498199986,
                40.76781538299985
              ],
              [
                -73.98905077199991,
                40.768440091999906
              ],
              [
                -73.98855898599986,
                40.7691158169999
              ],
              [
                -73.98806289599996,
                40.769790799999925
              ],
              [
                -73.99059644699987,
                40.770854809999946
              ],
              [
                -73.990913076,
                40.77098775899989
              ],
              [
                -73.99365606199987,
                40.772145961999854
              ],
              [
                -73.99378189799992,
                40.77219909099988
              ],
              [
                -73.99387445099994,
                40.77223816799995
              ],
              [
                -73.9935026219999,
                40.772751632999885
              ],
              [
                -73.9935908639999,
                40.77279007999994
              ],
              [
                -73.99372534699988,
                40.772864197999915
              ],
              [
                -73.9937873699999,
                40.77290384599992
              ],
              [
                -73.99383108201548,
                40.77293178742534
              ],
              [
                -73.99416328456306,
                40.772487191523865
              ],
              [
                -73.9942497922864,
                40.7725264851381
              ],
              [
                -73.99434191991617,
                40.772524781943375
              ],
              [
                -73.99667705784736,
                40.77351005394221
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000395756553505,
        "objectid": 51,
        "shape_leng": 0.0953613442277,
        "location_id": 51,
        "zone": "Co-Op City",
        "borough": "Bronx"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.82091617199997,
                40.86891693399989
              ],
              [
                -73.82097601199978,
                40.868949786999956
              ],
              [
                -73.82103881099985,
                40.8689760799999
              ],
              [
                -73.82111615299983,
                40.86900813699992
              ],
              [
                -73.821266938,
                40.86907160599991
              ],
              [
                -73.82329599399986,
                40.87309741799988
              ],
              [
                -73.82110268599995,
                40.87896924499993
              ],
              [
                -73.81992586499995,
                40.88211933299989
              ],
              [
                -73.82023958299996,
                40.88549105099987
              ],
              [
                -73.82027432999985,
                40.885550699999925
              ],
              [
                -73.82031167699992,
                40.885609739999914
              ],
              [
                -73.82077408699996,
                40.886710602999884
              ],
              [
                -73.82382288900004,
                40.8877472369999
              ],
              [
                -73.82385099099994,
                40.88776831599996
              ],
              [
                -73.82387140599998,
                40.887783627999966
              ],
              [
                -73.82403093399992,
                40.88769579499992
              ],
              [
                -73.82448935999992,
                40.887422519999916
              ],
              [
                -73.82529579999982,
                40.88697637999996
              ],
              [
                -73.82611278899988,
                40.886666035999895
              ],
              [
                -73.82672826599993,
                40.88644534299988
              ],
              [
                -73.82700490399988,
                40.88634614699994
              ],
              [
                -73.82777310699997,
                40.886047017999886
              ],
              [
                -73.82791367899995,
                40.88599054299989
              ],
              [
                -73.82953327299994,
                40.885303333999936
              ],
              [
                -73.83033698199985,
                40.884969491999925
              ],
              [
                -73.83065359099987,
                40.884852938999906
              ],
              [
                -73.83130254299985,
                40.88471142299994
              ],
              [
                -73.83233699299984,
                40.88454919399989
              ],
              [
                -73.83256211199996,
                40.88451947199998
              ],
              [
                -73.83256233799996,
                40.88451944499996
              ],
              [
                -73.83256256300001,
                40.884519400999864
              ],
              [
                -73.83278516199996,
                40.88447693199991
              ],
              [
                -73.83278538799992,
                40.8844768879999
              ],
              [
                -73.83278561399996,
                40.884476833999955
              ],
              [
                -73.83300396199994,
                40.88442179699993
              ],
              [
                -73.83300425899994,
                40.88442172499991
              ],
              [
                -73.83300454299992,
                40.884421634999974
              ],
              [
                -73.83321703599982,
                40.88435437999989
              ],
              [
                -73.83321711999992,
                40.884354352999914
              ],
              [
                -73.83321720299985,
                40.884354316999904
              ],
              [
                -73.8334219759999,
                40.884275494999876
              ],
              [
                -73.83342222600004,
                40.884275395999914
              ],
              [
                -73.8334224639999,
                40.884275288999916
              ],
              [
                -73.83361799799997,
                40.88418563099992
              ],
              [
                -73.83404698799986,
                40.883990319999974
              ],
              [
                -73.83434542099987,
                40.88385001999995
              ],
              [
                -73.83475583399996,
                40.883556293999916
              ],
              [
                -73.83560954999997,
                40.882945278999934
              ],
              [
                -73.83621837399996,
                40.882393011999916
              ],
              [
                -73.83664134299984,
                40.88212002499992
              ],
              [
                -73.8369874379998,
                40.881939082999935
              ],
              [
                -73.83782792499997,
                40.88160813999987
              ],
              [
                -73.83874273699996,
                40.88126596799992
              ],
              [
                -73.83965170899998,
                40.88092595799992
              ],
              [
                -73.84046213299986,
                40.880622867999875
              ],
              [
                -73.84127784699999,
                40.8803175479999
              ],
              [
                -73.84201454899987,
                40.879872259999935
              ],
              [
                -73.84273785199989,
                40.879424902999894
              ],
              [
                -73.84324113799987,
                40.87910891299992
              ],
              [
                -73.84239559299994,
                40.87875039299991
              ],
              [
                -73.84228241899993,
                40.87870197299992
              ],
              [
                -73.84114053199991,
                40.878215735999945
              ],
              [
                -73.8401480179999,
                40.8777884259999
              ],
              [
                -73.839711891,
                40.87761159499991
              ],
              [
                -73.8395648579999,
                40.87754981599994
              ],
              [
                -73.83925549199988,
                40.87741982299989
              ],
              [
                -73.83855197999986,
                40.87711952799991
              ],
              [
                -73.8381484219999,
                40.876953298999915
              ],
              [
                -73.83841016399981,
                40.87686761499992
              ],
              [
                -73.83924788999988,
                40.87661873799992
              ],
              [
                -73.8383540939998,
                40.87492195799993
              ],
              [
                -73.83746027699992,
                40.87322264999988
              ],
              [
                -73.83829221499984,
                40.872951338999954
              ],
              [
                -73.83740181399989,
                40.87110892899994
              ],
              [
                -73.83655628099997,
                40.86935217599991
              ],
              [
                -73.83565766499987,
                40.86751275599992
              ],
              [
                -73.8348179389999,
                40.86778172199995
              ],
              [
                -73.8339124609999,
                40.868075988999884
              ],
              [
                -73.83354911599997,
                40.868193637999916
              ],
              [
                -73.83347966699989,
                40.86821612199987
              ],
              [
                -73.83315960399985,
                40.86831975599994
              ],
              [
                -73.83312947400005,
                40.86826747399992
              ],
              [
                -73.83255828799996,
                40.867287772999916
              ],
              [
                -73.83175316499981,
                40.8659067679999
              ],
              [
                -73.83054182799988,
                40.86373143499994
              ],
              [
                -73.83022388499991,
                40.863429527999884
              ],
              [
                -73.82954731199996,
                40.86216408699993
              ],
              [
                -73.82840758999997,
                40.860948300999894
              ],
              [
                -73.82834347399992,
                40.860892025999895
              ],
              [
                -73.82826796599981,
                40.860837332999935
              ],
              [
                -73.82799444999982,
                40.860840185999905
              ],
              [
                -73.82787032399982,
                40.860678212999915
              ],
              [
                -73.82759107999996,
                40.86007855099994
              ],
              [
                -73.82741458099993,
                40.85995987299993
              ],
              [
                -73.82732348799999,
                40.85968688699988
              ],
              [
                -73.82720794099991,
                40.85941373899988
              ],
              [
                -73.82714556299992,
                40.85923239499993
              ],
              [
                -73.82699653199998,
                40.859087785999876
              ],
              [
                -73.82632571099997,
                40.85929799699995
              ],
              [
                -73.82444012099991,
                40.85950138399989
              ],
              [
                -73.82450575099989,
                40.85976402099996
              ],
              [
                -73.82037552999996,
                40.86103300499991
              ],
              [
                -73.81884300999991,
                40.8625682099999
              ],
              [
                -73.81761651299989,
                40.86362634299998
              ],
              [
                -73.8168449589999,
                40.86430679299993
              ],
              [
                -73.81699760299986,
                40.86445903099996
              ],
              [
                -73.81641455899984,
                40.86515149799992
              ],
              [
                -73.81578764099999,
                40.86575806099991
              ],
              [
                -73.81938761399987,
                40.86798690199988
              ],
              [
                -73.81940759199995,
                40.867999270999874
              ],
              [
                -73.82091617199997,
                40.86891693399989
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0000452027509603,
        "objectid": 52,
        "shape_leng": 0.0289076710093,
        "location_id": 52,
        "zone": "Cobble Hill",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.99588110299992,
                40.68239495399995
              ],
              [
                -73.99555742799987,
                40.68306853499997
              ],
              [
                -73.99523240699986,
                40.68374079099995
              ],
              [
                -73.99490113599975,
                40.68443023799992
              ],
              [
                -73.99457806799994,
                40.68510014199989
              ],
              [
                -73.99440802399982,
                40.685452495999925
              ],
              [
                -73.99425367999989,
                40.685777996999924
              ],
              [
                -73.99409147700004,
                40.686113438999946
              ],
              [
                -73.9939292959999,
                40.68645287099989
              ],
              [
                -73.99378736999994,
                40.68675596699996
              ],
              [
                -73.99360423199991,
                40.6871272299999
              ],
              [
                -73.99333822999985,
                40.68767879699991
              ],
              [
                -73.99326829999998,
                40.68782445099992
              ],
              [
                -73.99300451699985,
                40.68836463599994
              ],
              [
                -73.99293238499986,
                40.68851457599991
              ],
              [
                -73.99268552799995,
                40.68903324699993
              ],
              [
                -73.99262286099999,
                40.68916054899988
              ],
              [
                -73.99236366999985,
                40.689690123999924
              ],
              [
                -73.99436092799984,
                40.69023947999991
              ],
              [
                -73.99623278099982,
                40.69075777399993
              ],
              [
                -73.99776924399993,
                40.69119469799997
              ],
              [
                -73.99808645299991,
                40.690535077999854
              ],
              [
                -73.99841229299983,
                40.68985608799994
              ],
              [
                -73.99874207699997,
                40.68917701399995
              ],
              [
                -73.99906961899994,
                40.68849807499985
              ],
              [
                -73.99937882999994,
                40.68785319299997
              ],
              [
                -73.99963768399985,
                40.687314767999965
              ],
              [
                -73.99969626699995,
                40.68719803399989
              ],
              [
                -74.00064737999996,
                40.685253946999914
              ],
              [
                -73.99986579399993,
                40.685036468999954
              ],
              [
                -73.99908066099994,
                40.68481599199986
              ],
              [
                -73.99940054699985,
                40.68414456199985
              ],
              [
                -73.9997255609999,
                40.683473148999944
              ],
              [
                -74.00004865199998,
                40.682803355999965
              ],
              [
                -73.998192111,
                40.68228732999989
              ],
              [
                -73.9962084629999,
                40.681723424999916
              ],
              [
                -73.99588110299992,
                40.68239495399995
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000947530980821,
        "objectid": 53,
        "shape_leng": 0.161500913385,
        "location_id": 53,
        "zone": "College Point",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.83839013354552,
                40.798304872075995
              ],
              [
                -73.83787044050948,
                40.79844190861319
              ],
              [
                -73.83789967503593,
                40.79850897118662
              ],
              [
                -73.8384291582917,
                40.79837567115335
              ],
              [
                -73.83841944083812,
                40.798342147138015
              ],
              [
                -73.83920948324085,
                40.79784431435393
              ],
              [
                -73.83923388155388,
                40.79788530644824
              ],
              [
                -73.84041517975831,
                40.79767843725872
              ],
              [
                -73.84040055225613,
                40.79764862952207
              ],
              [
                -73.84043977575945,
                40.79763751324533
              ],
              [
                -73.84013930364449,
                40.79687918783606
              ],
              [
                -73.84059982294625,
                40.79680680321051
              ],
              [
                -73.84129943545976,
                40.79621966932997
              ],
              [
                -73.8413057246617,
                40.79620095620876
              ],
              [
                -73.8412920482614,
                40.79616745712962
              ],
              [
                -73.84130710746976,
                40.796138076853396
              ],
              [
                -73.84134129989054,
                40.79609535875629
              ],
              [
                -73.84144315649657,
                40.79604513413277
              ],
              [
                -73.84148780606058,
                40.79601448800372
              ],
              [
                -73.84149409342267,
                40.79598188920637
              ],
              [
                -73.84136133643683,
                40.79585660057752
              ],
              [
                -73.84135034620307,
                40.79573990748323
              ],
              [
                -73.84136287271946,
                40.795619950695176
              ],
              [
                -73.8414273671699,
                40.79549043036311
              ],
              [
                -73.84148925424489,
                40.795436558457354
              ],
              [
                -73.84153823334024,
                40.79541881301257
              ],
              [
                -73.84158721174394,
                40.79540106922123
              ],
              [
                -73.84165397120607,
                40.79539590970684
              ],
              [
                -73.8416869879848,
                40.79540867089491
              ],
              [
                -73.8417820928015,
                40.79553692460987
              ],
              [
                -73.84183741226605,
                40.79558859863672
              ],
              [
                -73.84190480251937,
                40.7956217942407
              ],
              [
                -73.84197864967462,
                40.795640311050825
              ],
              [
                -73.84203334901652,
                40.79563868313395
              ],
              [
                -73.84208804901434,
                40.79563705636471
              ],
              [
                -73.8421674734115,
                40.79561294016361
              ],
              [
                -73.8428667525936,
                40.79692222907191
              ],
              [
                -73.84292069025287,
                40.796903686184
              ],
              [
                -73.84225959368331,
                40.79558496893736
              ],
              [
                -73.8428087524122,
                40.79541822241309
              ],
              [
                -73.84320653636648,
                40.796192903595745
              ],
              [
                -73.8432849719419,
                40.79617439268503
              ],
              [
                -73.84289254581853,
                40.79539277833716
              ],
              [
                -73.84284865322397,
                40.79521340167169
              ],
              [
                -73.84286053658465,
                40.79519887517494
              ],
              [
                -73.84289545847199,
                40.79518278540822
              ],
              [
                -73.84293426865992,
                40.795191149897065
              ],
              [
                -73.84304363919044,
                40.79521474001689
              ],
              [
                -73.8431105900181,
                40.79526369123992
              ],
              [
                -73.84314830001613,
                40.79538678733097
              ],
              [
                -73.84317961314706,
                40.79543387303219
              ],
              [
                -73.84323462158838,
                40.79547660430574
              ],
              [
                -73.8433063238691,
                40.795500933488924
              ],
              [
                -73.84335118053633,
                40.79547252924369
              ],
              [
                -73.84336415641455,
                40.79534609866879
              ],
              [
                -73.84335588803987,
                40.795234353799444
              ],
              [
                -73.84332414153785,
                40.79506905019074
              ],
              [
                -73.84333760876113,
                40.79502138696036
              ],
              [
                -73.84338511407093,
                40.79498491861969
              ],
              [
                -73.8434326202129,
                40.79494844908789
              ],
              [
                -73.84354668938991,
                40.79491394401049
              ],
              [
                -73.84373233041337,
                40.79488235365058
              ],
              [
                -73.84379857938124,
                40.79487735637718
              ],
              [
                -73.84382499651181,
                40.79487536227074
              ],
              [
                -73.84390523936045,
                40.794869306206444
              ],
              [
                -73.84407887373855,
                40.794875060841726
              ],
              [
                -73.84422311865038,
                40.794876985073586
              ],
              [
                -73.84438471988366,
                40.794870341529595
              ],
              [
                -73.84451391906175,
                40.79488179016882
              ],
              [
                -73.8445991504444,
                40.79491154987514
              ],
              [
                -73.84466734418736,
                40.79495665817198
              ],
              [
                -73.84480116912152,
                40.79505767606762
              ],
              [
                -73.8448730254321,
                40.79509683700134
              ],
              [
                -73.84488892176356,
                40.79510909661496
              ],
              [
                -73.84495114910605,
                40.795202497921444
              ],
              [
                -73.84498447620877,
                40.79526520941121
              ],
              [
                -73.84506393886154,
                40.79532545177626
              ],
              [
                -73.84520216815449,
                40.795398019954966
              ],
              [
                -73.84528519601372,
                40.79544339037916
              ],
              [
                -73.84537463759747,
                40.79554835538661
              ],
              [
                -73.8454367018999,
                40.79564636850852
              ],
              [
                -73.84551678745503,
                40.795764856222256
              ],
              [
                -73.84557627918223,
                40.79580748365859
              ],
              [
                -73.84561606324512,
                40.795845718962276
              ],
              [
                -73.84562722402833,
                40.79589181188618
              ],
              [
                -73.84559498485325,
                40.79598311567545
              ],
              [
                -73.8455916712241,
                40.79601181902574
              ],
              [
                -73.8456210638299,
                40.796029481712544
              ],
              [
                -73.84567461137927,
                40.796029093896934
              ],
              [
                -73.84570427007124,
                40.796028881644546
              ],
              [
                -73.84588460454623,
                40.79601358879331
              ],
              [
                -73.84597412890419,
                40.796017301873185
              ],
              [
                -73.84604010531895,
                40.7960379217384
              ],
              [
                -73.84612011376143,
                40.79609316530998
              ],
              [
                -73.84620432632532,
                40.79613943809978
              ],
              [
                -73.84624528406336,
                40.796161950667056
              ],
              [
                -73.84632264317928,
                40.79616408876088
              ],
              [
                -73.84637823258807,
                40.79614151613582
              ],
              [
                -73.84644727612637,
                40.796101679341035
              ],
              [
                -73.84651631916125,
                40.79606183379271
              ],
              [
                -73.84660914258136,
                40.79602723440848
              ],
              [
                -73.84673086658061,
                40.796001479344746
              ],
              [
                -73.84677146173631,
                40.795992890189915
              ],
              [
                -73.84685831176914,
                40.79597954211447
              ],
              [
                -73.84712646747435,
                40.79593506179959
              ],
              [
                -73.84729839674371,
                40.795900350307335
              ],
              [
                -73.84745840378464,
                40.79586126478076
              ],
              [
                -73.84763762072186,
                40.79582581476201
              ],
              [
                -73.84781967180619,
                40.79578469546817
              ],
              [
                -73.84792364797372,
                40.795753514196456
              ],
              [
                -73.84808903411276,
                40.79568851740229
              ],
              [
                -73.84823442276506,
                40.79563184132044
              ],
              [
                -73.84840067725122,
                40.79556462149243
              ],
              [
                -73.84855988738981,
                40.79549506021838
              ],
              [
                -73.84870999361334,
                40.79541648128986
              ],
              [
                -73.84881376432953,
                40.79528731529211
              ],
              [
                -73.84884566489967,
                40.79522916657755
              ],
              [
                -73.84887654563339,
                40.79513404169125
              ],
              [
                -73.84887162206815,
                40.795027488024886
              ],
              [
                -73.84884690961489,
                40.794905230392054
              ],
              [
                -73.84878457368544,
                40.794700915276955
              ],
              [
                -73.84877122000401,
                40.79461737604404
              ],
              [
                -73.84877745470259,
                40.79457974348268
              ],
              [
                -73.8487837015379,
                40.794542102225336
              ],
              [
                -73.84896271217512,
                40.794408477406584
              ],
              [
                -73.84903163032307,
                40.79433808463525
              ],
              [
                -73.84907814795721,
                40.79414538448089
              ],
              [
                -73.84901721175498,
                40.79392484379938
              ],
              [
                -73.84896194839715,
                40.79380491495361
              ],
              [
                -73.84890147425774,
                40.793668283513504
              ],
              [
                -73.84887965495555,
                40.793654882539485
              ],
              [
                -73.84885848370783,
                40.793591099022926
              ],
              [
                -73.8488489472309,
                40.7935016856583
              ],
              [
                -73.84886198397842,
                40.79346863578048
              ],
              [
                -73.84890131445043,
                40.793435387178214
              ],
              [
                -73.84893051832798,
                40.79341069705516
              ],
              [
                -73.84898342755147,
                40.79339361180351
              ],
              [
                -73.84906160835088,
                40.79336261029853
              ],
              [
                -73.84913455725516,
                40.79336270580536
              ],
              [
                -73.8492203438544,
                40.79336616002198
              ],
              [
                -73.8493365487603,
                40.79337149956624
              ],
              [
                -73.84948233898469,
                40.793418600803406
              ],
              [
                -73.84962251839083,
                40.79346569570809
              ],
              [
                -73.8497346793857,
                40.793495694820585
              ],
              [
                -73.8498408564086,
                40.79354448520947
              ],
              [
                -73.85000721695765,
                40.79360091123408
              ],
              [
                -73.85016769642245,
                40.793643066105226
              ],
              [
                -73.8503263813649,
                40.79367766269843
              ],
              [
                -73.85049082789963,
                40.793737272705165
              ],
              [
                -73.85062808294123,
                40.79377521705116
              ],
              [
                -73.85079807258425,
                40.79380961136817
              ],
              [
                -73.85097034152753,
                40.79385794832398
              ],
              [
                -73.85112011660962,
                40.79392412203351
              ],
              [
                -73.8512917816512,
                40.79398321871115
              ],
              [
                -73.85143723745655,
                40.79402591832599
              ],
              [
                -73.85159077157229,
                40.79408653158045
              ],
              [
                -73.85168072178335,
                40.79414255807323
              ],
              [
                -73.85181651299672,
                40.79423690787667
              ],
              [
                -73.85189730713525,
                40.79428665681592
              ],
              [
                -73.85193160677206,
                40.79430978954521
              ],
              [
                -73.85200288947318,
                40.79436318108858
              ],
              [
                -73.85214062585973,
                40.794446492588484
              ],
              [
                -73.85225478631884,
                40.79451254658353
              ],
              [
                -73.85233601016024,
                40.794545365664405
              ],
              [
                -73.85242651222973,
                40.79460975882105
              ],
              [
                -73.85249076582929,
                40.794674974705615
              ],
              [
                -73.85253153207259,
                40.794790057555886
              ],
              [
                -73.85255611510601,
                40.7948935649726
              ],
              [
                -73.85258042693633,
                40.794921584003866
              ],
              [
                -73.85259261251832,
                40.79493563836851
              ],
              [
                -73.8526309825624,
                40.79494485424474
              ],
              [
                -73.85265879996285,
                40.794919712443615
              ],
              [
                -73.85265711034053,
                40.794875089776454
              ],
              [
                -73.85262310783055,
                40.79475015592459
              ],
              [
                -73.85262416811536,
                40.7946134426322
              ],
              [
                -73.85263725293453,
                40.79454229280629
              ],
              [
                -73.85268508272915,
                40.794474970277534
              ],
              [
                -73.852766271984,
                40.794389247829315
              ],
              [
                -73.85282872033453,
                40.79435526049111
              ],
              [
                -73.85290227105678,
                40.79431522857123
              ],
              [
                -73.85304034260227,
                40.794263553963894
              ],
              [
                -73.85321701027607,
                40.794220581361756
              ],
              [
                -73.85338820350945,
                40.79418311461311
              ],
              [
                -73.85351764622185,
                40.79414337736203
              ],
              [
                -73.85353237176378,
                40.794138856811834
              ],
              [
                -73.85358451638857,
                40.79410782853745
              ],
              [
                -73.85368561848587,
                40.79401839408178
              ],
              [
                -73.85371825553295,
                40.793962036128015
              ],
              [
                -73.85373351593732,
                40.793935375091095
              ],
              [
                -73.85374051410696,
                40.79384049684196
              ],
              [
                -73.85372635874646,
                40.7937199835051
              ],
              [
                -73.85371089772221,
                40.79359540618731
              ],
              [
                -73.85368396814027,
                40.793453722878766
              ],
              [
                -73.8536504217716,
                40.79333593064633
              ],
              [
                -73.8536365935801,
                40.793207294907084
              ],
              [
                -73.8536315280857,
                40.79314525253357
              ],
              [
                -73.85363080166015,
                40.79309818271064
              ],
              [
                -73.85363607087064,
                40.793024249617865
              ],
              [
                -73.85364967670063,
                40.79292081669766
              ],
              [
                -73.85365784517334,
                40.79286462703543
              ],
              [
                -73.85366602731114,
                40.79282949112687
              ],
              [
                -73.85367235728862,
                40.792811759410796
              ],
              [
                -73.85369038565968,
                40.79277081768012
              ],
              [
                -73.85369739407174,
                40.792752095911716
              ],
              [
                -73.85373126537303,
                40.79264103597808
              ],
              [
                -73.8537726775182,
                40.79249457678897
              ],
              [
                -73.85377595819327,
                40.792479191242236
              ],
              [
                -73.8537779734007,
                40.79246239989308
              ],
              [
                -73.85377854910695,
                40.79242127400703
              ],
              [
                -73.85377760787473,
                40.79238053384989
              ],
              [
                -73.85377521952759,
                40.79235706387969
              ],
              [
                -73.85377222418454,
                40.792345210011746
              ],
              [
                -73.853769898906,
                40.79233577890684
              ],
              [
                -73.85376299952767,
                40.792320948019636
              ],
              [
                -73.85376137210825,
                40.79231744266621
              ],
              [
                -73.85375336392234,
                40.79230565363567
              ],
              [
                -73.85374337894176,
                40.792293772805145
              ],
              [
                -73.85371695117264,
                40.79226911070588
              ],
              [
                -73.8536655370685,
                40.79222770321816
              ],
              [
                -73.85357001852788,
                40.79214460965117
              ],
              [
                -73.85346170233008,
                40.79205180973618
              ],
              [
                -73.85342445523895,
                40.79202232566936
              ],
              [
                -73.85335450891063,
                40.79197048428282
              ],
              [
                -73.85320119873275,
                40.79185858275661
              ],
              [
                -73.85317478813853,
                40.79183739653493
              ],
              [
                -73.85314657162884,
                40.791813010953646
              ],
              [
                -73.85306080953109,
                40.79173345914619
              ],
              [
                -73.85297910559555,
                40.7916568213595
              ],
              [
                -73.85290948021424,
                40.79158884423955
              ],
              [
                -73.85288469081824,
                40.79156296763564
              ],
              [
                -73.852858796491,
                40.791533785514275
              ],
              [
                -73.85283502179291,
                40.791504695551815
              ],
              [
                -73.8527918796173,
                40.7914518894217
              ],
              [
                -73.852762579209,
                40.79141382308165
              ],
              [
                -73.85273431336955,
                40.79137402132106
              ],
              [
                -73.85270703591516,
                40.791332437174304
              ],
              [
                -73.85268071109522,
                40.79128899001694
              ],
              [
                -73.85266418431857,
                40.791255795028036
              ],
              [
                -73.85265299090051,
                40.79122229927847
              ],
              [
                -73.85264742419872,
                40.79118940644143
              ],
              [
                -73.85264759969778,
                40.791157717578535
              ],
              [
                -73.85265359064718,
                40.79112231759108
              ],
              [
                -73.85265830693045,
                40.79110642061036
              ],
              [
                -73.85266414518988,
                40.79109170477687
              ],
              [
                -73.85267114137163,
                40.79107816192768
              ],
              [
                -73.8526792733986,
                40.79106580778187
              ],
              [
                -73.85268853906085,
                40.791054644849
              ],
              [
                -73.85269895180701,
                40.79104467163847
              ],
              [
                -73.852720981704,
                40.79102720261027
              ],
              [
                -73.85273595696937,
                40.79101692078134
              ],
              [
                -73.85274514158607,
                40.79101060206984
              ],
              [
                -73.8527712656627,
                40.790994984391645
              ],
              [
                -73.85279912994584,
                40.7909804673935
              ],
              [
                -73.85283622037684,
                40.79096246926646
              ],
              [
                -73.85300596376405,
                40.7908801362715
              ],
              [
                -73.85309376971468,
                40.79083784371317
              ],
              [
                -73.85312868602469,
                40.790822120897474
              ],
              [
                -73.85316791455791,
                40.790806232186505
              ],
              [
                -73.85327445626994,
                40.79076805080775
              ],
              [
                -73.8533068131879,
                40.79075803360746
              ],
              [
                -73.85337851301512,
                40.79073919665925
              ],
              [
                -73.85340954040993,
                40.79073004233699
              ],
              [
                -73.85347749436237,
                40.79070629240607
              ],
              [
                -73.85355197936214,
                40.79067681416449
              ],
              [
                -73.85362955320052,
                40.79064295482212
              ],
              [
                -73.85383120135553,
                40.79055003583008
              ],
              [
                -73.85389890575122,
                40.79052143145544
              ],
              [
                -73.8540697206796,
                40.790452327389076
              ],
              [
                -73.85415926148362,
                40.79041271946752
              ],
              [
                -73.85442371474154,
                40.790287722071255
              ],
              [
                -73.85443480521755,
                40.790282440938476
              ],
              [
                -73.85444728241936,
                40.79027652261423
              ],
              [
                -73.85445222779767,
                40.79027417881428
              ],
              [
                -73.85445942840997,
                40.79027006309863
              ],
              [
                -73.85446599251512,
                40.790265010614874
              ],
              [
                -73.8544719407123,
                40.79025897531954
              ],
              [
                -73.8544773471365,
                40.79025192329883
              ],
              [
                -73.85448648377765,
                40.79023505850079
              ],
              [
                -73.85449618458735,
                40.79020926313646
              ],
              [
                -73.85450297303252,
                40.79018264389068
              ],
              [
                -73.85450773724963,
                40.79014387398984
              ],
              [
                -73.85451476467469,
                40.79006760170977
              ],
              [
                -73.85451938179,
                40.78997156811299
              ],
              [
                -73.85452124363728,
                40.789905609038165
              ],
              [
                -73.8545234573319,
                40.7898742202651
              ],
              [
                -73.8545254129648,
                40.789863084644026
              ],
              [
                -73.85453150689433,
                40.7898395617269
              ],
              [
                -73.85454851361663,
                40.78976139224731
              ],
              [
                -73.85456120188782,
                40.78966970120305
              ],
              [
                -73.8545792407035,
                40.78959649495239
              ],
              [
                -73.85459364262289,
                40.78954447405569
              ],
              [
                -73.85460743543418,
                40.78950066297288
              ],
              [
                -73.85461486312076,
                40.789484472306896
              ],
              [
                -73.85463754730239,
                40.78944903077787
              ],
              [
                -73.8546457583267,
                40.789432642412564
              ],
              [
                -73.85466678497424,
                40.78936366406562
              ],
              [
                -73.8546740230833,
                40.789347670836165
              ],
              [
                -73.85469330882114,
                40.789311216005146
              ],
              [
                -73.8547006703475,
                40.78929330543443
              ],
              [
                -73.8547134155788,
                40.78925082651861
              ],
              [
                -73.8547271694004,
                40.78919784905344
              ],
              [
                -73.85474513677647,
                40.789119258584606
              ],
              [
                -73.85475092459623,
                40.78910019378737
              ],
              [
                -73.85476700102882,
                40.789056944927935
              ],
              [
                -73.8547725437133,
                40.789036374605345
              ],
              [
                -73.85477486760239,
                40.78901401842672
              ],
              [
                -73.85477488753459,
                40.788955872242866
              ],
              [
                -73.85478828752535,
                40.788841633480914
              ],
              [
                -73.85479015014025,
                40.78880257276636
              ],
              [
                -73.85479170822225,
                40.7887130458245
              ],
              [
                -73.85479289872485,
                40.788699684328606
              ],
              [
                -73.8547935555014,
                40.7886916255108
              ],
              [
                -73.85479536031538,
                40.78867916507016
              ],
              [
                -73.85480065341628,
                40.78864244012699
              ],
              [
                -73.85480194733184,
                40.78861915472593
              ],
              [
                -73.85480083624125,
                40.788607626575704
              ],
              [
                -73.85479768615775,
                40.788595871875145
              ],
              [
                -73.8547924992481,
                40.78858380770185
              ],
              [
                -73.85478518134681,
                40.788571254517144
              ],
              [
                -73.85476549772548,
                40.788545807907305
              ],
              [
                -73.85472744729312,
                40.788505210938496
              ],
              [
                -73.85469341458857,
                40.7884702736738
              ],
              [
                -73.85466212641388,
                40.78844194035955
              ],
              [
                -73.85463110180122,
                40.78841798363432
              ],
              [
                -73.85459973691678,
                40.78839795242853
              ],
              [
                -73.85456828189716,
                40.788381253347474
              ],
              [
                -73.85453503358329,
                40.788366182853956
              ],
              [
                -73.85450027715643,
                40.788352856061344
              ],
              [
                -73.85446440284554,
                40.78834142892451
              ],
              [
                -73.85441611713868,
                40.78832777007358
              ],
              [
                -73.85438364788577,
                40.788320128763026
              ],
              [
                -73.85435363507841,
                40.788315336468706
              ],
              [
                -73.85432601017287,
                40.78831359045082
              ],
              [
                -73.85424119113252,
                40.788313419551415
              ],
              [
                -73.85415345566268,
                40.78831452445786
              ],
              [
                -73.85401770764051,
                40.78831756723785
              ],
              [
                -73.85386496770758,
                40.78832686549009
              ],
              [
                -73.85369958159862,
                40.78833865014911
              ],
              [
                -73.85367261904089,
                40.78834211887525
              ],
              [
                -73.85361442297669,
                40.78835306713739
              ],
              [
                -73.85359051889232,
                40.78835591820988
              ],
              [
                -73.85353771951807,
                40.788358291801345
              ],
              [
                -73.85346961745066,
                40.78835850276176
              ],
              [
                -73.8532896704259,
                40.78835371647665
              ],
              [
                -73.8532509376304,
                40.78835436105499
              ],
              [
                -73.8531642037784,
                40.78835820351326
              ],
              [
                -73.8531259333357,
                40.78835847974777
              ],
              [
                -73.85306663884121,
                40.78835654815511
              ],
              [
                -73.8529729685171,
                40.78835183732921
              ],
              [
                -73.85288990261189,
                40.7883466700547
              ],
              [
                -73.8528617928357,
                40.78834431980798
              ],
              [
                -73.85284201138356,
                40.78834255597354
              ],
              [
                -73.85284230133867,
                40.78821137173993
              ],
              [
                -73.85285256044187,
                40.78820079505671
              ],
              [
                -73.85287292575686,
                40.78817980298654
              ],
              [
                -73.85293742640218,
                40.78812567459657
              ],
              [
                -73.85299455222662,
                40.788063640119375
              ],
              [
                -73.85301986377856,
                40.788040223035985
              ],
              [
                -73.85304318488666,
                40.78802164841081
              ],
              [
                -73.85313945328275,
                40.78795526940675
              ],
              [
                -73.85322865679291,
                40.7878902230359
              ],
              [
                -73.85325391367482,
                40.78787544243503
              ],
              [
                -73.85328433106342,
                40.78786264787823
              ],
              [
                -73.85332033752428,
                40.78785160001044
              ],
              [
                -73.85336457538442,
                40.787841480476246
              ],
              [
                -73.8534518446134,
                40.78782611229884
              ],
              [
                -73.85354068080385,
                40.78781480652712
              ],
              [
                -73.85371524447409,
                40.787798890843035
              ],
              [
                -73.85393152201209,
                40.78777359140016
              ],
              [
                -73.85401805617188,
                40.78776425411684
              ],
              [
                -73.85416877913472,
                40.787749622955054
              ],
              [
                -73.85428498197075,
                40.78773911749593
              ],
              [
                -73.85429883286434,
                40.78773858552389
              ],
              [
                -73.85431409235855,
                40.78773918177169
              ],
              [
                -73.85437741367018,
                40.78774676257946
              ],
              [
                -73.85439211484702,
                40.787747770894
              ],
              [
                -73.85440532437035,
                40.78774783264652
              ],
              [
                -73.8544158710025,
                40.78774703682185
              ],
              [
                -73.85442754479743,
                40.78774524112081
              ],
              [
                -73.85444998385327,
                40.78774013681833
              ],
              [
                -73.8544674801146,
                40.7877361785098
              ],
              [
                -73.8544845470797,
                40.78773329935781
              ],
              [
                -73.85449877839754,
                40.787732263925705
              ],
              [
                -73.85451097917964,
                40.78773329704505
              ],
              [
                -73.85453886969691,
                40.78773835734163
              ],
              [
                -73.85457032389033,
                40.78774439427808
              ],
              [
                -73.85468022861875,
                40.78776934208012
              ],
              [
                -73.85470705318119,
                40.78777443717722
              ],
              [
                -73.85473138125592,
                40.78777797013025
              ],
              [
                -73.85476457332538,
                40.78778001121077
              ],
              [
                -73.85480173164524,
                40.787778535894965
              ],
              [
                -73.85483685607232,
                40.78777470779179
              ],
              [
                -73.85491542031168,
                40.78776363177172
              ],
              [
                -73.8549497141386,
                40.78776024401786
              ],
              [
                -73.85512749399334,
                40.78774797743824
              ],
              [
                -73.8552218638764,
                40.787741531710054
              ],
              [
                -73.8552279423134,
                40.7877408454555
              ],
              [
                -73.855234071033,
                40.78773963892992
              ],
              [
                -73.85524681590034,
                40.78773555760208
              ],
              [
                -73.85525882990034,
                40.78773015073168
              ],
              [
                -73.8552896094324,
                40.787714304458305
              ],
              [
                -73.85529901270421,
                40.787710399018664
              ],
              [
                -73.85530780984412,
                40.78770760987654
              ],
              [
                -73.85534234055955,
                40.787699151644865
              ],
              [
                -73.85537111936169,
                40.78769366813679
              ],
              [
                -73.85539747579985,
                40.787690540339725
              ],
              [
                -73.85542196652793,
                40.78768971584816
              ],
              [
                -73.85556149088127,
                40.78769294344054
              ],
              [
                -73.85560310030938,
                40.787692796895385
              ],
              [
                -73.85573914362435,
                40.78768956432037
              ],
              [
                -73.85580406250807,
                40.78768710554238
              ],
              [
                -73.85589212531865,
                40.787682128110426
              ],
              [
                -73.8559298294174,
                40.78768039227655
              ],
              [
                -73.85595002350544,
                40.78767760744772
              ],
              [
                -73.8559586891305,
                40.78767517877378
              ],
              [
                -73.85596586474206,
                40.78767205434683
              ],
              [
                -73.85596893750498,
                40.787670211052124
              ],
              [
                -73.85597165404891,
                40.78766818052126
              ],
              [
                -73.85597404083201,
                40.787665958599305
              ],
              [
                -73.85597608420362,
                40.78766353806568
              ],
              [
                -73.8560160340422,
                40.78760921644484
              ],
              [
                -73.85603321264523,
                40.78758738271038
              ],
              [
                -73.8560569353297,
                40.78755305807725
              ],
              [
                -73.85606648062318,
                40.78754388558647
              ],
              [
                -73.85607509299993,
                40.78753875381505
              ],
              [
                -73.85608104623329,
                40.78753581665557
              ],
              [
                -73.85609772992429,
                40.78752950703866
              ],
              [
                -73.85613932084576,
                40.78751645613108
              ],
              [
                -73.85614910324811,
                40.78751260487093
              ],
              [
                -73.85615699040497,
                40.78750872544568
              ],
              [
                -73.85616413290576,
                40.787503978816645
              ],
              [
                -73.85616696948826,
                40.787501471486266
              ],
              [
                -73.85616935713371,
                40.787498844484844
              ],
              [
                -73.85617128128915,
                40.787496100474876
              ],
              [
                -73.85617275786663,
                40.787493219875685
              ],
              [
                -73.85617378287428,
                40.78749021390659
              ],
              [
                -73.85617436960048,
                40.78748705326704
              ],
              [
                -73.85617604394366,
                40.78746322889014
              ],
              [
                -73.8561760427645,
                40.787436736087855
              ],
              [
                -73.85617045836483,
                40.787356485124846
              ],
              [
                -73.85616275026324,
                40.7872660655662
              ],
              [
                -73.85616371236824,
                40.78723789804133
              ],
              [
                -73.85616904420905,
                40.787210385465734
              ],
              [
                -73.85617856602606,
                40.78718439975185
              ],
              [
                -73.8561920416777,
                40.78716059010005
              ],
              [
                -73.85620020315073,
                40.78715009995904
              ],
              [
                -73.85621071201784,
                40.787139181368126
              ],
              [
                -73.85625338291673,
                40.78710253866144
              ],
              [
                -73.85626918661896,
                40.787086934785336
              ],
              [
                -73.85628495146457,
                40.787066558227245
              ],
              [
                -73.85632138571486,
                40.7870107630821
              ],
              [
                -73.85633435604609,
                40.786995560740955
              ],
              [
                -73.85634817318284,
                40.78698311524667
              ],
              [
                -73.85635589754584,
                40.78697773038883
              ],
              [
                -73.85636465148082,
                40.78697283481516
              ],
              [
                -73.8563852262836,
                40.78696449390209
              ],
              [
                -73.85640981200986,
                40.786958122217996
              ],
              [
                -73.85643833805504,
                40.786953754182555
              ],
              [
                -73.85644134734402,
                40.78695352339555
              ],
              [
                -73.85644453537552,
                40.78695350106511
              ],
              [
                -73.8564516069265,
                40.786954023171276
              ],
              [
                -73.85647634701824,
                40.78695844767236
              ],
              [
                -73.85648559735232,
                40.786959458815865
              ],
              [
                -73.8564903374551,
                40.786959456509706
              ],
              [
                -73.8564946154943,
                40.78695898371913
              ],
              [
                -73.85649853926243,
                40.78695803454766
              ],
              [
                -73.85650037645205,
                40.78695738030319
              ],
              [
                -73.85650214336668,
                40.7869566073632
              ],
              [
                -73.85650746918343,
                40.78695359753187
              ],
              [
                -73.85651235931141,
                40.78694994704324
              ],
              [
                -73.85651686958872,
                40.78694562095424
              ],
              [
                -73.85652107510892,
                40.7869405473222
              ],
              [
                -73.8565428156595,
                40.786902114070855
              ],
              [
                -73.8565517820311,
                40.78688678978821
              ],
              [
                -73.85656012485876,
                40.786869115352545
              ],
              [
                -73.85658367206895,
                40.7868057044605
              ],
              [
                -73.85659430490371,
                40.78678148497369
              ],
              [
                -73.8566082989491,
                40.7867579799755
              ],
              [
                -73.85662488072505,
                40.78673849595679
              ],
              [
                -73.85663457884486,
                40.78673011516583
              ],
              [
                -73.85663891840045,
                40.78672716776114
              ],
              [
                -73.85664594534656,
                40.786722394656586
              ],
              [
                -73.85665906408536,
                40.78671528779341
              ],
              [
                -73.85667405292824,
                40.78670871581779
              ],
              [
                -73.85670911174444,
                40.78669716832916
              ],
              [
                -73.8567614699094,
                40.78668437433237
              ],
              [
                -73.85681133765485,
                40.78667261369716
              ],
              [
                -73.85684155844486,
                40.78666805819638
              ],
              [
                -73.85686648756545,
                40.786667458929365
              ],
              [
                -73.85691545961245,
                40.78667023046878
              ],
              [
                -73.85693580067614,
                40.78667048020513
              ],
              [
                -73.85693993619978,
                40.78667016151201
              ],
              [
                -73.85694418001007,
                40.786669401523774
              ],
              [
                -73.85694857751135,
                40.78666819979412
              ],
              [
                -73.8569521826025,
                40.7866669170036
              ],
              [
                -73.8569625005109,
                40.786661896006294
              ],
              [
                -73.85698165442462,
                40.78665231152472
              ],
              [
                -73.85699174536678,
                40.78664828986718
              ],
              [
                -73.85699693804672,
                40.786647007872645
              ],
              [
                -73.85700184401013,
                40.786646428793645
              ],
              [
                -73.85700423681998,
                40.78664639607966
              ],
              [
                -73.8570065822965,
                40.78664654339674
              ],
              [
                -73.85700889280255,
                40.786646861378806
              ],
              [
                -73.85701118969901,
                40.78664735876353
              ],
              [
                -73.85703042955764,
                40.78665296658985
              ],
              [
                -73.85705112356236,
                40.78666036790085
              ],
              [
                -73.85712345763554,
                40.786691092132244
              ],
              [
                -73.85715356205523,
                40.786702348793725
              ],
              [
                -73.85716818589594,
                40.78670633861099
              ],
              [
                -73.8572031450027,
                40.7867132881668
              ],
              [
                -73.85721807696163,
                40.786717080176885
              ],
              [
                -73.85723247119613,
                40.78672269067732
              ],
              [
                -73.85723836454146,
                40.78672603943954
              ],
              [
                -73.85724349816154,
                40.78672979066156
              ],
              [
                -73.85728559591055,
                40.78676704282163
              ],
              [
                -73.85729374398953,
                40.78677411293459
              ],
              [
                -73.85734834299556,
                40.78682149372409
              ],
              [
                -73.85736136582724,
                40.78683114439631
              ],
              [
                -73.85737743400954,
                40.78684060182576
              ],
              [
                -73.85744739357587,
                40.786874394827336
              ],
              [
                -73.85746576865716,
                40.786885870763506
              ],
              [
                -73.85750542076828,
                40.78691415988884
              ],
              [
                -73.85752521760811,
                40.78692579236686
              ],
              [
                -73.8575358112561,
                40.78693055112215
              ],
              [
                -73.85754734103337,
                40.78693456336776
              ],
              [
                -73.85757378493405,
                40.78694053834411
              ],
              [
                -73.85760255605203,
                40.786943869791116
              ],
              [
                -73.85768482601809,
                40.78694943843554
              ],
              [
                -73.85770909350568,
                40.786953304396256
              ],
              [
                -73.85772986169724,
                40.78695886725275
              ],
              [
                -73.85780599688667,
                40.78698547261065
              ],
              [
                -73.85786314840233,
                40.78700855171837
              ],
              [
                -73.85792172009276,
                40.78703739424952
              ],
              [
                -73.85794568998246,
                40.7870477083486
              ],
              [
                -73.85796202844763,
                40.787053067520425
              ],
              [
                -73.85797998052438,
                40.787057394041554
              ],
              [
                -73.85799961604485,
                40.787060705252316
              ],
              [
                -73.85802109164295,
                40.78706303669271
              ],
              [
                -73.8580358282388,
                40.78706372075975
              ],
              [
                -73.85804551953424,
                40.7870641652476
              ],
              [
                -73.8580690623594,
                40.78706349214436
              ],
              [
                -73.85809131675386,
                40.78706102459311
              ],
              [
                -73.85811203509817,
                40.787056809029266
              ],
              [
                -73.85812817158494,
                40.7870515610583
              ],
              [
                -73.85814354176146,
                40.7870443229452
              ],
              [
                -73.85815754110041,
                40.78703537974478
              ],
              [
                -73.85816956419353,
                40.78702511987387
              ],
              [
                -73.85820537379047,
                40.78698865730483
              ],
              [
                -73.85823412406135,
                40.78695729288554
              ],
              [
                -73.85825574781519,
                40.786928430564906
              ],
              [
                -73.85826152049127,
                40.786922025964856
              ],
              [
                -73.85826743102712,
                40.78691671061674
              ],
              [
                -73.85832309838246,
                40.78687465396306
              ],
              [
                -73.85838745384751,
                40.78682496276902
              ],
              [
                -73.8583918434028,
                40.78682199726703
              ],
              [
                -73.85839718127706,
                40.786819076319276
              ],
              [
                -73.85842092224304,
                40.78680830931207
              ],
              [
                -73.85842561959295,
                40.78680560385147
              ],
              [
                -73.85842953643257,
                40.78680281668462
              ],
              [
                -73.85844506383575,
                40.78678813992627
              ],
              [
                -73.85847124228764,
                40.78675704183654
              ],
              [
                -73.85848303366855,
                40.78674469176868
              ],
              [
                -73.85854493188523,
                40.78669090901093
              ],
              [
                -73.8585672513092,
                40.78666969367949
              ],
              [
                -73.8586094910695,
                40.78662369377923
              ],
              [
                -73.85865887626714,
                40.78656559120967
              ],
              [
                -73.85867223053741,
                40.78654812798552
              ],
              [
                -73.85868283350074,
                40.78653116647522
              ],
              [
                -73.85869073576778,
                40.786514642914504
              ],
              [
                -73.85869595921666,
                40.7864985311968
              ],
              [
                -73.85869681117974,
                40.78649319153127
              ],
              [
                -73.85869684685179,
                40.786487599409384
              ],
              [
                -73.85869445579392,
                40.786475593784566
              ],
              [
                -73.85868879765647,
                40.78646233960763
              ],
              [
                -73.85867919947988,
                40.786446307885846
              ],
              [
                -73.85866900182882,
                40.78643327497071
              ],
              [
                -73.8586413032543,
                40.78640518987496
              ],
              [
                -73.85863031307774,
                40.78639192965725
              ],
              [
                -73.8586081570016,
                40.786358800963264
              ],
              [
                -73.85859127699716,
                40.7863296299931
              ],
              [
                -73.8585784455328,
                40.786302275608115
              ],
              [
                -73.85856942617816,
                40.786276185691
              ],
              [
                -73.85856645703221,
                40.786262620864846
              ],
              [
                -73.85856492361424,
                40.78624832822732
              ],
              [
                -73.85856623298424,
                40.78621774857181
              ],
              [
                -73.85857233568788,
                40.786183455064155
              ],
              [
                -73.8585764279673,
                40.78617016895421
              ],
              [
                -73.85858152390757,
                40.78615826197238
              ],
              [
                -73.85858453141702,
                40.786153141227835
              ],
              [
                -73.85858840508548,
                40.78614814015386
              ],
              [
                -73.85859310695238,
                40.786143282827744
              ],
              [
                -73.85859860229465,
                40.786138625159964
              ],
              [
                -73.8586117707735,
                40.786130050277556
              ],
              [
                -73.85862705727051,
                40.78612296343964
              ],
              [
                -73.85863807102098,
                40.786119285861986
              ],
              [
                -73.85865077887459,
                40.78611609501214
              ],
              [
                -73.85865102736396,
                40.78611605142519
              ],
              [
                -73.85871030047439,
                40.78610475868033
              ],
              [
                -73.85875663984856,
                40.786092065121885
              ],
              [
                -73.85880488978569,
                40.786077345990286
              ],
              [
                -73.85885571609528,
                40.78606040676397
              ],
              [
                -73.8589107413936,
                40.78604072691422
              ],
              [
                -73.8589540346854,
                40.786022832070934
              ],
              [
                -73.85897116148841,
                40.786013983229495
              ],
              [
                -73.85898579987948,
                40.78600494287451
              ],
              [
                -73.8589981662399,
                40.7859955298419
              ],
              [
                -73.85900818594031,
                40.78598580703046
              ],
              [
                -73.8590158599168,
                40.78597574931314
              ],
              [
                -73.85902122503792,
                40.78596532808885
              ],
              [
                -73.85902784022804,
                40.78594671422237
              ],
              [
                -73.85903137893663,
                40.78593146385328
              ],
              [
                -73.85903226093974,
                40.78591774945821
              ],
              [
                -73.85903169431462,
                40.78591138263151
              ],
              [
                -73.85903045064073,
                40.785905311999144
              ],
              [
                -73.85902672912445,
                40.78589531103742
              ],
              [
                -73.85901586237468,
                40.785873623137356
              ],
              [
                -73.85901262561131,
                40.785864001040416
              ],
              [
                -73.85900765755157,
                40.78583794358627
              ],
              [
                -73.8590075486969,
                40.785833144017126
              ],
              [
                -73.85910322798064,
                40.78587950876995
              ],
              [
                -73.85912461223712,
                40.78585015966327
              ],
              [
                -73.85901247467498,
                40.785801218356866
              ],
              [
                -73.85901500687321,
                40.78579675522681
              ],
              [
                -73.85901993539109,
                40.785790899038545
              ],
              [
                -73.85902273779875,
                40.785788416221834
              ],
              [
                -73.85902600066773,
                40.78578612410958
              ],
              [
                -73.85903403038795,
                40.78578198265144
              ],
              [
                -73.85904279128074,
                40.78577873432746
              ],
              [
                -73.85904595729141,
                40.78577773857549
              ],
              [
                -73.85906659710821,
                40.7857712532768
              ],
              [
                -73.859073094663,
                40.78576863174325
              ],
              [
                -73.85907851498793,
                40.78576585577383
              ],
              [
                -73.85910597960674,
                40.78574843722467
              ],
              [
                -73.85911539050561,
                40.785740794157654
              ],
              [
                -73.85912285858095,
                40.78573313179498
              ],
              [
                -73.85912804012771,
                40.78572580805354
              ],
              [
                -73.85913188308693,
                40.78571758122148
              ],
              [
                -73.85913431982947,
                40.7857086430327
              ],
              [
                -73.85913505673093,
                40.785701412028104
              ],
              [
                -73.85913527518866,
                40.785699206655366
              ],
              [
                -73.85913472666259,
                40.78568949018005
              ],
              [
                -73.8591326727759,
                40.785679878913506
              ],
              [
                -73.85912918731835,
                40.785670707159134
              ],
              [
                -73.85912438142353,
                40.78566226403373
              ],
              [
                -73.85910485381038,
                40.78563645883565
              ],
              [
                -73.8590848266754,
                40.785616325267036
              ],
              [
                -73.85906403880534,
                40.785596984164904
              ],
              [
                -73.85905705909373,
                40.785592284246945
              ],
              [
                -73.85905354569631,
                40.78559048759551
              ],
              [
                -73.85904993483406,
                40.785589025205574
              ],
              [
                -73.85904444062069,
                40.78558745999673
              ],
              [
                -73.85903817556573,
                40.78558635386963
              ],
              [
                -73.85902162583585,
                40.78558534338305
              ],
              [
                -73.85901770625782,
                40.78558540995414
              ],
              [
                -73.85901335620464,
                40.785585801500304
              ],
              [
                -73.85899522435685,
                40.785588462572214
              ],
              [
                -73.8589912421843,
                40.78558873713243
              ],
              [
                -73.85898760509338,
                40.785588732682236
              ],
              [
                -73.85898271378164,
                40.785588266672306
              ],
              [
                -73.85897777442442,
                40.785587279096866
              ],
              [
                -73.85897274386161,
                40.78558575181011
              ],
              [
                -73.85896755907882,
                40.78558367485106
              ],
              [
                -73.85895721464993,
                40.785578267705134
              ],
              [
                -73.8589403429962,
                40.785567647386635
              ],
              [
                -73.85899048805017,
                40.7854915994347
              ],
              [
                -73.85908214610843,
                40.785521985402546
              ],
              [
                -73.85906638595809,
                40.78546668826561
              ],
              [
                -73.85939048227354,
                40.7855354549592
              ],
              [
                -73.85939317223568,
                40.785524316794614
              ],
              [
                -73.85941715122297,
                40.78552839731539
              ],
              [
                -73.85941579536208,
                40.7855395376104
              ],
              [
                -73.8595236950597,
                40.785561954252664
              ],
              [
                -73.85952909197228,
                40.78553056053404
              ],
              [
                -73.85924002857145,
                40.78546942944456
              ],
              [
                -73.85923734418844,
                40.785478543067555
              ],
              [
                -73.85921602303392,
                40.7854775036624
              ],
              [
                -73.85921738129012,
                40.78546535001113
              ],
              [
                -73.8591104979856,
                40.78544258520096
              ],
              [
                -73.85910536075212,
                40.785412937974904
              ],
              [
                -73.85912145674305,
                40.78541295765199
              ],
              [
                -73.85903628973827,
                40.785092722779524
              ],
              [
                -73.8589906592535,
                40.78510490134055
              ],
              [
                -73.8590811990642,
                40.78542106357173
              ],
              [
                -73.85909193046099,
                40.78542107669319
              ],
              [
                -73.85909453895707,
                40.785439185864135
              ],
              [
                -73.85905644466132,
                40.78543181356221
              ],
              [
                -73.85899156050911,
                40.785204169142396
              ],
              [
                -73.85852698593591,
                40.785228939174594
              ],
              [
                -73.85852665393895,
                40.78521766614742
              ],
              [
                -73.85852327431452,
                40.785208007674804
              ],
              [
                -73.85851907436073,
                40.7851999016585
              ],
              [
                -73.85851353347383,
                40.785192524741674
              ],
              [
                -73.8585039039529,
                40.78518305124956
              ],
              [
                -73.85847639475126,
                40.78516071698647
              ],
              [
                -73.85846495883229,
                40.78514968445889
              ],
              [
                -73.85841272034448,
                40.78508992460955
              ],
              [
                -73.85834010817545,
                40.78500481152161
              ],
              [
                -73.85831062791249,
                40.784972703035855
              ],
              [
                -73.85827576991517,
                40.78493830103915
              ],
              [
                -73.85816525317925,
                40.784839057018765
              ],
              [
                -73.85813170195247,
                40.78481040622703
              ],
              [
                -73.85804860814078,
                40.784742889318544
              ],
              [
                -73.85800821599177,
                40.78470690216522
              ],
              [
                -73.85798576369062,
                40.78468741105699
              ],
              [
                -73.85797685542525,
                40.78467816456815
              ],
              [
                -73.85797327315859,
                40.784673146619006
              ],
              [
                -73.85797081502555,
                40.78466829305747
              ],
              [
                -73.85796943206935,
                40.78466351335941
              ],
              [
                -73.85796911579062,
                40.78465876245011
              ],
              [
                -73.85796917696919,
                40.78465818607044
              ],
              [
                -73.85796930885482,
                40.78465762904325
              ],
              [
                -73.85796951145142,
                40.78465708969348
              ],
              [
                -73.85796977329701,
                40.784656568509426
              ],
              [
                -73.85797010587352,
                40.78465605562129
              ],
              [
                -73.85797051068255,
                40.78465557029636
              ],
              [
                -73.85797153240988,
                40.784654663567544
              ],
              [
                -73.8579728131553,
                40.78465383790521
              ],
              [
                -73.85797435379807,
                40.78465309431558
              ],
              [
                -73.85797615343813,
                40.78465244167644
              ],
              [
                -73.85797817966105,
                40.78465188698374
              ],
              [
                -73.85798309391498,
                40.784651067976895
              ],
              [
                -73.85798864693247,
                40.78465074546465
              ],
              [
                -73.85799433965465,
                40.784650953342414
              ],
              [
                -73.85800470789609,
                40.78465118792153
              ],
              [
                -73.85794201573734,
                40.784516124506425
              ],
              [
                -73.85793785344377,
                40.784507155590646
              ],
              [
                -73.85793550046684,
                40.78450208755775
              ],
              [
                -73.8578466600017,
                40.78450904406461
              ],
              [
                -73.85783684133779,
                40.78448481564441
              ],
              [
                -73.85783174883879,
                40.784466309860214
              ],
              [
                -73.85783033335551,
                40.78444972661103
              ],
              [
                -73.85783105450373,
                40.78444208833942
              ],
              [
                -73.8578319424332,
                40.784438194635314
              ],
              [
                -73.85783272297559,
                40.784434841570814
              ],
              [
                -73.85783635535135,
                40.78442391535011
              ],
              [
                -73.85784993964482,
                40.78438315908842
              ],
              [
                -73.85785479002129,
                40.78436858281669
              ],
              [
                -73.857856785958,
                40.78436259574054
              ],
              [
                -73.857880693497,
                40.784297789016236
              ],
              [
                -73.85788269478857,
                40.78429404109152
              ],
              [
                -73.85788555791412,
                40.78429046544059
              ],
              [
                -73.8578892976976,
                40.78428703527752
              ],
              [
                -73.85789394771577,
                40.78428371496085
              ],
              [
                -73.85790564851207,
                40.78427750983771
              ],
              [
                -73.85792468298948,
                40.78426969981441
              ],
              [
                -73.857928959443,
                40.78426823169991
              ],
              [
                -73.85793355654516,
                40.784267088477364
              ],
              [
                -73.8579439287294,
                40.78426575687127
              ],
              [
                -73.85795493700509,
                40.7842655524895
              ],
              [
                -73.85798583738342,
                40.784266498559816
              ],
              [
                -73.85799502501644,
                40.78426594883852
              ],
              [
                -73.8580030190051,
                40.78426465768562
              ],
              [
                -73.85802503979681,
                40.78425884889897
              ],
              [
                -73.85804623764804,
                40.784251473568894
              ],
              [
                -73.8580657708648,
                40.784242836393226
              ],
              [
                -73.8580831293917,
                40.784233168265
              ],
              [
                -73.85809779066977,
                40.784222776118234
              ],
              [
                -73.85810975652801,
                40.784211732830144
              ],
              [
                -73.85811884686251,
                40.78420016013823
              ],
              [
                -73.85812230968615,
                40.78419420116462
              ],
              [
                -73.85812502709221,
                40.78418815181475
              ],
              [
                -73.85813380512944,
                40.784162608117875
              ],
              [
                -73.85813635063349,
                40.784155208635866
              ],
              [
                -73.85814972842107,
                40.78410864682302
              ],
              [
                -73.85817002215364,
                40.78404870153351
              ],
              [
                -73.85817325802043,
                40.784032261215046
              ],
              [
                -73.85817378865498,
                40.78401740135329
              ],
              [
                -73.85817248535963,
                40.784006781482184
              ],
              [
                -73.85816966828361,
                40.783995063461205
              ],
              [
                -73.85815907631199,
                40.78393343388456
              ],
              [
                -73.85813601750543,
                40.78385775769632
              ],
              [
                -73.85811509504617,
                40.783787778993975
              ],
              [
                -73.85810938899668,
                40.78376903809658
              ],
              [
                -73.85810281002682,
                40.783753056106455
              ],
              [
                -73.85809385126109,
                40.78373735949678
              ],
              [
                -73.85809293159618,
                40.78373619674264
              ],
              [
                -73.85808318103317,
                40.783724078841665
              ],
              [
                -73.85802960947828,
                40.78370307933287
              ],
              [
                -73.85797317515247,
                40.78368698496619
              ],
              [
                -73.85791465221827,
                40.78367601652253
              ],
              [
                -73.8578548434883,
                40.78367032446611
              ],
              [
                -73.85777187429395,
                40.78365613500528
              ],
              [
                -73.85769080619144,
                40.78363660261761
              ],
              [
                -73.85761223352816,
                40.78361187050412
              ],
              [
                -73.85757561010641,
                40.78355295044696
              ],
              [
                -73.85753435811876,
                40.78349582500975
              ],
              [
                -73.8575267060602,
                40.78348423551788
              ],
              [
                -73.85751733577959,
                40.78347265210975
              ],
              [
                -73.85749351368149,
                40.78344952457449
              ],
              [
                -73.85746304507938,
                40.78342661447072
              ],
              [
                -73.85744261237805,
                40.78341393716907
              ],
              [
                -73.85741998550928,
                40.78340204083335
              ],
              [
                -73.85739520262219,
                40.783390915960595
              ],
              [
                -73.85736824871026,
                40.78338057174475
              ],
              [
                -73.85734052316816,
                40.78337127058618
              ],
              [
                -73.85731850031053,
                40.78336539919497
              ],
              [
                -73.85729827323259,
                40.783361898833775
              ],
              [
                -73.85727946259598,
                40.78336078528364
              ],
              [
                -73.85717784525652,
                40.783363208125216
              ],
              [
                -73.85712873299349,
                40.783366002386124
              ],
              [
                -73.8570807328061,
                40.783369842024115
              ],
              [
                -73.85698714612556,
                40.78337878469753
              ],
              [
                -73.85693056185812,
                40.783385630084105
              ],
              [
                -73.856916424825,
                40.7833873417309
              ],
              [
                -73.85691574879351,
                40.78338737707694
              ],
              [
                -73.85690977849755,
                40.78338767539816
              ],
              [
                -73.85690380601619,
                40.783387965005055
              ],
              [
                -73.85689057554147,
                40.78338718331959
              ],
              [
                -73.85687720455233,
                40.78338502305834
              ],
              [
                -73.85686419228801,
                40.783381567766526
              ],
              [
                -73.85685209384219,
                40.78337695969255
              ],
              [
                -73.85684129014666,
                40.78337136348521
              ],
              [
                -73.85683209884915,
                40.78336495795387
              ],
              [
                -73.85682476802232,
                40.78335788881941
              ],
              [
                -73.85680365242803,
                40.78333241445576
              ],
              [
                -73.856783191625,
                40.78330561795276
              ],
              [
                -73.85674405554418,
                40.783247802497335
              ],
              [
                -73.85672676824508,
                40.78322101763507
              ],
              [
                -73.85671872229025,
                40.78320553665177
              ],
              [
                -73.8567166980192,
                40.783199464350965
              ],
              [
                -73.85671074167976,
                40.78318160961588
              ],
              [
                -73.85670377431896,
                40.78314924541216
              ],
              [
                -73.85670256831024,
                40.78309712187021
              ],
              [
                -73.8566813904766,
                40.782939969174365
              ],
              [
                -73.85665341005878,
                40.78293790765001
              ],
              [
                -73.85664951090587,
                40.78289231927643
              ],
              [
                -73.85660286912328,
                40.782892261248804
              ],
              [
                -73.85660942954789,
                40.78293987965895
              ],
              [
                -73.85661875947618,
                40.78293887790556
              ],
              [
                -73.85662133635174,
                40.78297939950119
              ],
              [
                -73.8566533206465,
                40.78297943929009
              ],
              [
                -73.85667159264416,
                40.78309526904827
              ],
              [
                -73.85667690893332,
                40.7831500881621
              ],
              [
                -73.85643993662929,
                40.78315751797215
              ],
              [
                -73.8564520666175,
                40.783322990816075
              ],
              [
                -73.85643317343842,
                40.78333236797428
              ],
              [
                -73.85641761444457,
                40.78334009262387
              ],
              [
                -73.85638100310932,
                40.78335828248909
              ],
              [
                -73.85635641010697,
                40.78336843755573
              ],
              [
                -73.85633400316311,
                40.78337616186691
              ],
              [
                -73.85631107802644,
                40.7833821667161
              ],
              [
                -73.85628930749792,
                40.78338574120036
              ],
              [
                -73.85626839522845,
                40.78338694978328
              ],
              [
                -73.8562481864888,
                40.78338579863812
              ],
              [
                -73.85623171362438,
                40.78338294171484
              ],
              [
                -73.8562139408563,
                40.78337804706226
              ],
              [
                -73.85614261963912,
                40.78335207787737
              ],
              [
                -73.85613060095004,
                40.78334903769837
              ],
              [
                -73.8561272379995,
                40.78334818699362
              ],
              [
                -73.85593296680115,
                40.78331787949883
              ],
              [
                -73.85592656549973,
                40.783196317504526
              ],
              [
                -73.85587458930368,
                40.78319827873433
              ],
              [
                -73.85587567996764,
                40.78330970432568
              ],
              [
                -73.8558186269766,
                40.78319415696082
              ],
              [
                -73.85573467582252,
                40.783192025676854
              ],
              [
                -73.85573475263911,
                40.78315657201522
              ],
              [
                -73.85561617015411,
                40.78314629374278
              ],
              [
                -73.85545062834929,
                40.78314307913829
              ],
              [
                -73.8553884517444,
                40.78207571631064
              ],
              [
                -73.8553794260627,
                40.78205276939833
              ],
              [
                -73.85536631186146,
                40.78203115839585
              ],
              [
                -73.85534961480477,
                40.78201175858746
              ],
              [
                -73.85533642950311,
                40.78200066591794
              ],
              [
                -73.85532050466512,
                40.781990722048334
              ],
              [
                -73.85530228006235,
                40.78198218987457
              ],
              [
                -73.85528242786589,
                40.78197538434999
              ],
              [
                -73.85525344295303,
                40.78196782839603
              ],
              [
                -73.85522450332166,
                40.78196174922935
              ],
              [
                -73.85519577265883,
                40.78195717553487
              ],
              [
                -73.85516739460483,
                40.781954131617
              ],
              [
                -73.85505842171234,
                40.781946358795544
              ],
              [
                -73.85496441517024,
                40.78194193561819
              ],
              [
                -73.85490018569638,
                40.78194016242126
              ],
              [
                -73.85485429206548,
                40.78194004146991
              ],
              [
                -73.8548062259648,
                40.781941772103394
              ],
              [
                -73.85474428264793,
                40.78194594517099
              ],
              [
                -73.85470961107612,
                40.78194948550511
              ],
              [
                -73.85463146933225,
                40.78196035435502
              ],
              [
                -73.85459641854935,
                40.78196425435653
              ],
              [
                -73.85449826596835,
                40.781970740358986
              ],
              [
                -73.85437833322167,
                40.78197457865841
              ],
              [
                -73.85435233041154,
                40.78197423068179
              ],
              [
                -73.85429401467086,
                40.78197115758686
              ],
              [
                -73.85426817724941,
                40.781971169812834
              ],
              [
                -73.8542402730513,
                40.78197377388685
              ],
              [
                -73.85417157081446,
                40.78198584295779
              ],
              [
                -73.85413969536411,
                40.78198915329551
              ],
              [
                -73.85405570899185,
                40.781991036328705
              ],
              [
                -73.85395650777669,
                40.78198984787203
              ],
              [
                -73.85380512238734,
                40.781982659050755
              ],
              [
                -73.85375796075665,
                40.78198246418577
              ],
              [
                -73.85371711005834,
                40.781983465738875
              ],
              [
                -73.8536797409504,
                40.781985742044256
              ],
              [
                -73.85364506982407,
                40.781989345215784
              ],
              [
                -73.85346812122447,
                40.78201330752581
              ],
              [
                -73.85331933850043,
                40.78203460406848
              ],
              [
                -73.85329196150529,
                40.78203946800102
              ],
              [
                -73.85326692782857,
                40.78204537960014
              ],
              [
                -73.85324407205734,
                40.78205239243221
              ],
              [
                -73.85322330950031,
                40.78206053386503
              ],
              [
                -73.85314993069177,
                40.782323936276164
              ],
              [
                -73.85297395843433,
                40.78232944780926
              ],
              [
                -73.85285529539563,
                40.78233194392956
              ],
              [
                -73.85277635625364,
                40.78233360799108
              ],
              [
                -73.8526005387797,
                40.78233259168672
              ],
              [
                -73.85253458158648,
                40.78233528001999
              ],
              [
                -73.8523815719042,
                40.78234440474127
              ],
              [
                -73.85234426450937,
                40.782345374663755
              ],
              [
                -73.85228097618968,
                40.782346302562715
              ],
              [
                -73.85216039867626,
                40.782341590575186
              ],
              [
                -73.8520964483179,
                40.78234201293649
              ],
              [
                -73.85201582564918,
                40.78234443136425
              ],
              [
                -73.85192054727274,
                40.782347351741436
              ],
              [
                -73.85168735535527,
                40.78236274850468
              ],
              [
                -73.85123047734257,
                40.782402547333746
              ],
              [
                -73.85122501973319,
                40.78239545228261
              ],
              [
                -73.85121791474403,
                40.78238872617335
              ],
              [
                -73.85121184750511,
                40.78238420654224
              ],
              [
                -73.85120513920205,
                40.782380055476935
              ],
              [
                -73.85119028608574,
                40.78237322839522
              ],
              [
                -73.85118245021988,
                40.78237067054824
              ],
              [
                -73.85117451639574,
                40.78236875101387
              ],
              [
                -73.85116661764656,
                40.78236750732233
              ],
              [
                -73.85115888224064,
                40.78236693963933
              ],
              [
                -73.85105865211817,
                40.782364882258186
              ],
              [
                -73.85095974363315,
                40.78236442938334
              ],
              [
                -73.85085191062834,
                40.78236610903307
              ],
              [
                -73.85080255677515,
                40.78236568563749
              ],
              [
                -73.85064452756707,
                40.78235741190033
              ],
              [
                -73.85057294395939,
                40.78235455459715
              ],
              [
                -73.85052785662293,
                40.782353577750825
              ],
              [
                -73.85051869908605,
                40.78235337707155
              ],
              [
                -73.85050121243168,
                40.78235387589483
              ],
              [
                -73.85048366289533,
                40.78235568166829
              ],
              [
                -73.85046237692144,
                40.78235969761348
              ],
              [
                -73.85044211739617,
                40.78236554175273
              ],
              [
                -73.85040198724082,
                40.7823892365787
              ],
              [
                -73.85038574038333,
                40.78239145798389
              ],
              [
                -73.85037136170378,
                40.78239499588286
              ],
              [
                -73.8503591841537,
                40.7823997795102
              ],
              [
                -73.85035396591742,
                40.78240261849053
              ],
              [
                -73.85034933834744,
                40.782405745879025
              ],
              [
                -73.85034477980166,
                40.78240963911629
              ],
              [
                -73.8503404094519,
                40.78241432532851
              ],
              [
                -73.85032509149033,
                40.78243539489009
              ],
              [
                -73.85032112499816,
                40.78244003656178
              ],
              [
                -73.85031711167846,
                40.7824439398875
              ],
              [
                -73.85030798571955,
                40.782450862920975
              ],
              [
                -73.85029868703998,
                40.78245589235582
              ],
              [
                -73.85029412126644,
                40.7824576062418
              ],
              [
                -73.85028961793518,
                40.782458798199265
              ],
              [
                -73.85028520882396,
                40.78245945017648
              ],
              [
                -73.85028089657797,
                40.78245956200967
              ],
              [
                -73.85027889487543,
                40.78245940629069
              ],
              [
                -73.85027696382406,
                40.78245909687505
              ],
              [
                -73.85027510454267,
                40.78245862622546
              ],
              [
                -73.85027330557234,
                40.782457993657154
              ],
              [
                -73.85027156691282,
                40.782457199337365
              ],
              [
                -73.85026987468241,
                40.78245624190818
              ],
              [
                -73.85026662248302,
                40.782453825649796
              ],
              [
                -73.85026365512806,
                40.78245087669524
              ],
              [
                -73.85026066567171,
                40.78244717133412
              ],
              [
                -73.85024983944788,
                40.78243044391332
              ],
              [
                -73.8502445600841,
                40.78242380957484
              ],
              [
                -73.85024128462477,
                40.78242076137932
              ],
              [
                -73.8502378544331,
                40.782418406370724
              ],
              [
                -73.85023606851233,
                40.78241745987581
              ],
              [
                -73.85023422072196,
                40.782416682333846
              ],
              [
                -73.85023230404347,
                40.7824160584907
              ],
              [
                -73.8502303385557,
                40.782415578320965
              ],
              [
                -73.85020961297208,
                40.78241285003427
              ],
              [
                -73.85017560390794,
                40.78241117596823
              ],
              [
                -73.84999442110552,
                40.78240473663234
              ],
              [
                -73.8499344172467,
                40.7823994885058
              ],
              [
                -73.84982764974079,
                40.782385247459494
              ],
              [
                -73.84978655217171,
                40.782380556957676
              ],
              [
                -73.84974246546523,
                40.7823772129673
              ],
              [
                -73.84964463464058,
                40.78237219479521
              ],
              [
                -73.84960336728695,
                40.78236881824452
              ],
              [
                -73.84956490053888,
                40.7823638509942
              ],
              [
                -73.84952319204757,
                40.78235653832871
              ],
              [
                -73.84947755621798,
                40.782346861758846
              ],
              [
                -73.84941366539036,
                40.78233176733062
              ],
              [
                -73.84936730007063,
                40.782319567834136
              ],
              [
                -73.84932904044368,
                40.782307045206025
              ],
              [
                -73.84929600721517,
                40.782293260729084
              ],
              [
                -73.8492676449515,
                40.78227794145227
              ],
              [
                -73.84926345684903,
                40.782275109483194
              ],
              [
                -73.84925954248891,
                40.782271979173956
              ],
              [
                -73.8492525818285,
                40.78226477501405
              ],
              [
                -73.84924675980236,
                40.78225636532168
              ],
              [
                -73.84924212637715,
                40.78224677780399
              ],
              [
                -73.84923866768676,
                40.782236004066824
              ],
              [
                -73.84923644337586,
                40.78222417737107
              ],
              [
                -73.84923580368731,
                40.78219794504835
              ],
              [
                -73.84923742986847,
                40.78218591699853
              ],
              [
                -73.84924064542695,
                40.78217363739409
              ],
              [
                -73.84925186203198,
                40.78214824899513
              ],
              [
                -73.84926950224772,
                40.782121661807885
              ],
              [
                -73.84929392410037,
                40.78209333669786
              ],
              [
                -73.84931176686678,
                40.782077151262975
              ],
              [
                -73.84933420378347,
                40.7820614137642
              ],
              [
                -73.84936186667622,
                40.78204555510282
              ],
              [
                -73.8494227221584,
                40.78201476570604
              ],
              [
                -73.84944812889454,
                40.78200084134151
              ],
              [
                -73.84945388296063,
                40.78199710281962
              ],
              [
                -73.84947294903944,
                40.78198472698115
              ],
              [
                -73.84949266315807,
                40.7819684089368
              ],
              [
                -73.84950332753195,
                40.781957265494775
              ],
              [
                -73.84951298514834,
                40.78194514908879
              ],
              [
                -73.84952161585517,
                40.78193210257941
              ],
              [
                -73.84952913459368,
                40.78191821799483
              ],
              [
                -73.84954069379472,
                40.7818882275846
              ],
              [
                -73.84954725821561,
                40.78185630494598
              ],
              [
                -73.84954774265991,
                40.7818410783428
              ],
              [
                -73.84954561024456,
                40.781825335024706
              ],
              [
                -73.84954094279978,
                40.78180963228848
              ],
              [
                -73.84953389183205,
                40.78179451277944
              ],
              [
                -73.84952476609249,
                40.78178056960574
              ],
              [
                -73.8495137890027,
                40.78176812001698
              ],
              [
                -73.84950124349734,
                40.78175747765476
              ],
              [
                -73.84948737902812,
                40.78174885091145
              ],
              [
                -73.8494738402559,
                40.78174258351931
              ],
              [
                -73.84945880658724,
                40.781737187986074
              ],
              [
                -73.84944211373582,
                40.78173260881317
              ],
              [
                -73.84942345201131,
                40.781728766522896
              ],
              [
                -73.84938705771674,
                40.781723721340434
              ],
              [
                -73.84929760029648,
                40.78171510447715
              ],
              [
                -73.84928876450584,
                40.781713786390114
              ],
              [
                -73.8492746246153,
                40.78171166930422
              ],
              [
                -73.84925485088841,
                40.781707502208036
              ],
              [
                -73.84923492578879,
                40.78170156835328
              ],
              [
                -73.84921772841386,
                40.78169429651935
              ],
              [
                -73.8492029285044,
                40.781685542203455
              ],
              [
                -73.84919040545033,
                40.781675233044986
              ],
              [
                -73.84918838580919,
                40.78167288923217
              ],
              [
                -73.84918667463242,
                40.78167009551429
              ],
              [
                -73.84918531851869,
                40.78166690656572
              ],
              [
                -73.84918433205542,
                40.78166340130998
              ],
              [
                -73.84918361402742,
                40.78165580961948
              ],
              [
                -73.84918460308101,
                40.78164824629693
              ],
              [
                -73.84918567739616,
                40.78164487993636
              ],
              [
                -73.84918709418461,
                40.7816418115495
              ],
              [
                -73.84918882990345,
                40.78163912118267
              ],
              [
                -73.84919084924894,
                40.78163682704974
              ],
              [
                -73.84919312672957,
                40.781634993782426
              ],
              [
                -73.84919434894228,
                40.781634247715296
              ],
              [
                -73.84919562929343,
                40.781633618656954
              ],
              [
                -73.84919696998567,
                40.781633107113144
              ],
              [
                -73.84919835691278,
                40.781632713735306
              ],
              [
                -73.84919977948272,
                40.781632444372924
              ],
              [
                -73.84920125952722,
                40.78163229319128
              ],
              [
                -73.84928815389694,
                40.781628156909555
              ],
              [
                -73.84936169911538,
                40.781627370879846
              ],
              [
                -73.84945107629284,
                40.7816301974027
              ],
              [
                -73.84949053395457,
                40.78163144675126
              ],
              [
                -73.8495464630235,
                40.781631393972475
              ],
              [
                -73.8495843534843,
                40.78162942659468
              ],
              [
                -73.84965318361573,
                40.78162387984903
              ],
              [
                -73.84973620036537,
                40.78162044027016
              ],
              [
                -73.84976687720905,
                40.781618083645604
              ],
              [
                -73.84979896935606,
                40.78161291258536
              ],
              [
                -73.84981313500577,
                40.78160927446724
              ],
              [
                -73.84982629433665,
                40.781604861067926
              ],
              [
                -73.84983172551624,
                40.78160247252979
              ],
              [
                -73.84983660014265,
                40.78159962491627
              ],
              [
                -73.84984092044256,
                40.7815963083463
              ],
              [
                -73.84984470805739,
                40.78159250358273
              ],
              [
                -73.84988728063747,
                40.7811215113169
              ],
              [
                -73.84991855289967,
                40.781120744575325
              ],
              [
                -73.85093646396534,
                40.78111957515603
              ],
              [
                -73.85093970945037,
                40.78108116289207
              ],
              [
                -73.84991219996311,
                40.78108750458773
              ],
              [
                -73.84988932081794,
                40.78108913479838
              ],
              [
                -73.84988800830871,
                40.78107744549927
              ],
              [
                -73.84988560815704,
                40.78106957972739
              ],
              [
                -73.84988263236191,
                40.781059851828736
              ],
              [
                -73.84985673695407,
                40.78100695824421
              ],
              [
                -73.8498498080579,
                40.780990486812726
              ],
              [
                -73.84982676964914,
                40.78092633202067
              ],
              [
                -73.84981488309079,
                40.78088682136889
              ],
              [
                -73.84981173168417,
                40.78087073363306
              ],
              [
                -73.84981008898778,
                40.78085272165433
              ],
              [
                -73.84981067388031,
                40.780798305751105
              ],
              [
                -73.84981214796522,
                40.78077040025503
              ],
              [
                -73.84981943042992,
                40.78069253380164
              ],
              [
                -73.84981943123964,
                40.78069217479532
              ],
              [
                -73.84981911467304,
                40.78066505031482
              ],
              [
                -73.84981746562704,
                40.78065561999311
              ],
              [
                -73.84981503152129,
                40.780641713034626
              ],
              [
                -73.8498082942797,
                40.78062481097365
              ],
              [
                -73.84980707998362,
                40.78062176545094
              ],
              [
                -73.84980162766685,
                40.780613042155764
              ],
              [
                -73.84979518947942,
                40.78060513610534
              ],
              [
                -73.84979287208388,
                40.780602899968606
              ],
              [
                -73.84979031790787,
                40.780600896886384
              ],
              [
                -73.84978747936445,
                40.78059911942538
              ],
              [
                -73.84978438993299,
                40.78059757617311
              ],
              [
                -73.8497810051181,
                40.78059625702007
              ],
              [
                -73.84977733487823,
                40.780595143886416
              ],
              [
                -73.84976904639166,
                40.780593557670585
              ],
              [
                -73.84976094548882,
                40.780592835628276
              ],
              [
                -73.84975146751532,
                40.78059264267861
              ],
              [
                -73.84970842754518,
                40.78059377498105
              ],
              [
                -73.84969977836884,
                40.78059400745033
              ],
              [
                -73.8496921628949,
                40.780593420559455
              ],
              [
                -73.84968532984298,
                40.78059242190543
              ],
              [
                -73.84967914925255,
                40.780590981667125
              ],
              [
                -73.84967357421606,
                40.780589084370966
              ],
              [
                -73.84966171544059,
                40.78058370071067
              ],
              [
                -73.84965044093985,
                40.78057732924294
              ],
              [
                -73.84964012579178,
                40.78057017450349
              ],
              [
                -73.84963103378945,
                40.78056243619226
              ],
              [
                -73.84962343481696,
                40.78055434903005
              ],
              [
                -73.84961740029898,
                40.780546002066416
              ],
              [
                -73.8496130358922,
                40.780537549730376
              ],
              [
                -73.84961037731607,
                40.780529082030384
              ],
              [
                -73.84960315614029,
                40.780490530998115
              ],
              [
                -73.84959707959024,
                40.780448108602435
              ],
              [
                -73.84959129206665,
                40.78038808349629
              ],
              [
                -73.84958618687178,
                40.780335081952984
              ],
              [
                -73.84958612528546,
                40.78031502771828
              ],
              [
                -73.8495907270451,
                40.78027117820336
              ],
              [
                -73.84959131415296,
                40.78025225075464
              ],
              [
                -73.84958872905418,
                40.780232725119845
              ],
              [
                -73.84958596479899,
                40.78022402190731
              ],
              [
                -73.8495821916791,
                40.78021587708098
              ],
              [
                -73.84957495568457,
                40.78020439464494
              ],
              [
                -73.8495653283726,
                40.78019228873936
              ],
              [
                -73.84952637351847,
                40.78015206641298
              ],
              [
                -73.84951236065771,
                40.7801355242267
              ],
              [
                -73.84949574651277,
                40.78011190001433
              ],
              [
                -73.84949102749152,
                40.780102888344686
              ],
              [
                -73.84948794336292,
                40.78009442996938
              ],
              [
                -73.84948719303776,
                40.78009097879955
              ],
              [
                -73.84948686941622,
                40.78008751126687
              ],
              [
                -73.8494874774256,
                40.780080506475315
              ],
              [
                -73.84948977929692,
                40.780073315429796
              ],
              [
                -73.84949381208321,
                40.780065828114246
              ],
              [
                -73.8497371105003,
                40.780063764913095
              ],
              [
                -73.84999800412726,
                40.78006341795454
              ],
              [
                -73.85036580861214,
                40.780057505868655
              ],
              [
                -73.85036786060351,
                40.780079873703535
              ],
              [
                -73.85041830321092,
                40.78007834233692
              ],
              [
                -73.85042045570425,
                40.780055978787765
              ],
              [
                -73.85047510549936,
                40.7800528543279
              ],
              [
                -73.85048578225796,
                40.77997778253564
              ],
              [
                -73.85162492646164,
                40.7799584865749
              ],
              [
                -73.85162266134178,
                40.78003197177817
              ],
              [
                -73.85167099162219,
                40.780035228878134
              ],
              [
                -73.85169028085423,
                40.779867508478176
              ],
              [
                -73.85163563328629,
                40.77986903549033
              ],
              [
                -73.85162286781565,
                40.77993931296461
              ],
              [
                -73.85048371588393,
                40.779961803775365
              ],
              [
                -73.85048178019531,
                40.779888312638995
              ],
              [
                -73.85045656031697,
                40.7798882799137
              ],
              [
                -73.85044992795433,
                40.78003365069638
              ],
              [
                -73.85041840010895,
                40.78003520780309
              ],
              [
                -73.8504163523557,
                40.78001124077503
              ],
              [
                -73.85037642446964,
                40.78000959208553
              ],
              [
                -73.85037426478351,
                40.78003515050537
              ],
              [
                -73.85031473957734,
                40.78004153600408
              ],
              [
                -73.85031476156011,
                40.78003175706058
              ],
              [
                -73.84974018040857,
                40.780040450022014
              ],
              [
                -73.84973465207516,
                40.7799963919555
              ],
              [
                -73.84955315550077,
                40.779990415384006
              ],
              [
                -73.84955450077001,
                40.77998279003448
              ],
              [
                -73.84955451960579,
                40.779979232987905
              ],
              [
                -73.84955456302885,
                40.7799708411844
              ],
              [
                -73.84955331323854,
                40.77996244668727
              ],
              [
                -73.84954857704788,
                40.77993031060005
              ],
              [
                -73.84954849514146,
                40.7799297889858
              ],
              [
                -73.84954767585884,
                40.779914622660506
              ],
              [
                -73.84955001985372,
                40.77989990239761
              ],
              [
                -73.8495525938666,
                40.77989340341915
              ],
              [
                -73.8495561490703,
                40.77988737512835
              ],
              [
                -73.84955945235377,
                40.7798831113909
              ],
              [
                -73.8495632520746,
                40.77987908216709
              ],
              [
                -73.84956744174248,
                40.779875404753476
              ],
              [
                -73.84957193877196,
                40.77987215040808
              ],
              [
                -73.84957661227023,
                40.77986941009375
              ],
              [
                -73.84958143974038,
                40.77986719148755
              ],
              [
                -73.84958633626894,
                40.77986552362805
              ],
              [
                -73.84959129058164,
                40.77986442174514
              ],
              [
                -73.84960016522162,
                40.77986336233685
              ],
              [
                -73.84961020050447,
                40.77986294288282
              ],
              [
                -73.84961851526822,
                40.77986309814072
              ],
              [
                -73.84965393555235,
                40.779863756496745
              ],
              [
                -73.84966424252009,
                40.77986296833341
              ],
              [
                -73.8496734158966,
                40.77986136920672
              ],
              [
                -73.84968940703446,
                40.779856769542235
              ],
              [
                -73.84970553216176,
                40.77985031570941
              ],
              [
                -73.8497217813201,
                40.77984202528526
              ],
              [
                -73.8497381878465,
                40.77983187150904
              ],
              [
                -73.84975449350075,
                40.77981895242141
              ],
              [
                -73.84977014020234,
                40.779804078118424
              ],
              [
                -73.84979408627251,
                40.77978132685575
              ],
              [
                -73.84979509438978,
                40.779780607977166
              ],
              [
                -73.84979622113285,
                40.77977996061912
              ],
              [
                -73.84979887674888,
                40.77977889308925
              ],
              [
                -73.84980207764751,
                40.77977809581888
              ],
              [
                -73.84980586942713,
                40.779777578080996
              ],
              [
                -73.84981375969693,
                40.7797772551562
              ],
              [
                -73.84983225116706,
                40.779777594370024
              ],
              [
                -73.8498363029474,
                40.779777393258385
              ],
              [
                -73.84983952619352,
                40.779776947317224
              ],
              [
                -73.84984040336778,
                40.77977673218471
              ],
              [
                -73.84984112663375,
                40.779776552534464
              ],
              [
                -73.8498424903832,
                40.77977605910582
              ],
              [
                -73.84984362844325,
                40.77977547491927
              ],
              [
                -73.84982102259761,
                40.77965133930386
              ],
              [
                -73.84997493056258,
                40.779641290896265
              ],
              [
                -73.85235751917273,
                40.779496739045214
              ],
              [
                -73.85236232095829,
                40.779530104739464
              ],
              [
                -73.85238832778414,
                40.7795289023869
              ],
              [
                -73.85235661936242,
                40.77916933052226
              ],
              [
                -73.85232086242218,
                40.77916928470371
              ],
              [
                -73.85235267881299,
                40.77948067283335
              ],
              [
                -73.85000701949745,
                40.77961725471423
              ],
              [
                -73.85000542456552,
                40.779603661787675
              ],
              [
                -73.84998643275294,
                40.77960582076214
              ],
              [
                -73.84987537319611,
                40.77961461194829
              ],
              [
                -73.8498785983377,
                40.779625736165144
              ],
              [
                -73.84981720066014,
                40.77963034805418
              ],
              [
                -73.84979304347623,
                40.77949768469487
              ],
              [
                -73.85003696878474,
                40.77944943201958
              ],
              [
                -73.85012275250425,
                40.77942830422235
              ],
              [
                -73.85011125881357,
                40.779399735201125
              ],
              [
                -73.85014017315828,
                40.77939098692571
              ],
              [
                -73.85015741651672,
                40.7794327419909
              ],
              [
                -73.85073555596264,
                40.77932367038114
              ],
              [
                -73.85074415423085,
                40.77935443134819
              ],
              [
                -73.85094937054778,
                40.7793261428947
              ],
              [
                -73.85094655574714,
                40.77929319249281
              ],
              [
                -73.85103904766675,
                40.77928013352065
              ],
              [
                -73.85105055581147,
                40.77930211402046
              ],
              [
                -73.85107946016522,
                40.779297758688315
              ],
              [
                -73.8510507916471,
                40.77919668391865
              ],
              [
                -73.8510276713222,
                40.77919885046023
              ],
              [
                -73.85101339619412,
                40.77912195667439
              ],
              [
                -73.85120998157538,
                40.779076085337444
              ],
              [
                -73.85120426185314,
                40.7790497196379
              ],
              [
                -73.85148755480326,
                40.77899297861758
              ],
              [
                -73.85147606060222,
                40.77896440973063
              ],
              [
                -73.85119275794374,
                40.77902554507495
              ],
              [
                -73.85118700387086,
                40.779014554831726
              ],
              [
                -73.85094705724252,
                40.77906915543026
              ],
              [
                -73.85096718956474,
                40.77911091411254
              ],
              [
                -73.85065788414983,
                40.77916981875145
              ],
              [
                -73.85067227797757,
                40.77919399711243
              ],
              [
                -73.85011148129979,
                40.7793008963792
              ],
              [
                -73.85012866957865,
                40.77936681108512
              ],
              [
                -73.85009398131915,
                40.77937335558598
              ],
              [
                -73.85009114089188,
                40.77935138794181
              ],
              [
                -73.85007007124759,
                40.77934793010841
              ],
              [
                -73.8497385127623,
                40.77940144669318
              ],
              [
                -73.8497540443524,
                40.779477201424385
              ],
              [
                -73.849751900693,
                40.77947700161922
              ],
              [
                -73.84974869047639,
                40.77947705120936
              ],
              [
                -73.84974262512041,
                40.77947713275956
              ],
              [
                -73.84973187730172,
                40.77947808268722
              ],
              [
                -73.84968773448676,
                40.77948502148875
              ],
              [
                -73.84966912057455,
                40.7794868257497
              ],
              [
                -73.84965952458613,
                40.779486848913706
              ],
              [
                -73.84965096095709,
                40.77948605439123
              ],
              [
                -73.84964324176299,
                40.77948441446294
              ],
              [
                -73.8496396893305,
                40.7794832751767
              ],
              [
                -73.84963631633588,
                40.77948192035157
              ],
              [
                -73.84962460342139,
                40.77947566406813
              ],
              [
                -73.84961378096662,
                40.77946779835428
              ],
              [
                -73.84960400544776,
                40.779458419406716
              ],
              [
                -73.84959547717891,
                40.77944772851765
              ],
              [
                -73.84958833734376,
                40.77943585972487
              ],
              [
                -73.8495828564969,
                40.77942323671887
              ],
              [
                -73.84957921443754,
                40.77941026414162
              ],
              [
                -73.84957754850187,
                40.7793974127518
              ],
              [
                -73.84957155453695,
                40.77919283670707
              ],
              [
                -73.84957279225395,
                40.779190668865965
              ],
              [
                -73.84957448948224,
                40.779188689923345
              ],
              [
                -73.8495766621079,
                40.779186891691445
              ],
              [
                -73.84957931896626,
                40.779185265302736
              ],
              [
                -73.84958246208258,
                40.77918379216453
              ],
              [
                -73.84958612583421,
                40.77918247349434
              ],
              [
                -73.84959302348445,
                40.77918066383928
              ],
              [
                -73.84959577388823,
                40.77918016233934
              ],
              [
                -73.84960160472559,
                40.77917910716696
              ],
              [
                -73.84964409727885,
                40.77917366107632
              ],
              [
                -73.84965398205614,
                40.77917143164276
              ],
              [
                -73.84965811870529,
                40.779170131009934
              ],
              [
                -73.84966179483854,
                40.77916869592322
              ],
              [
                -73.84973108396828,
                40.779142220586884
              ],
              [
                -73.84975923714767,
                40.779128740992924
              ],
              [
                -73.84977161159078,
                40.77912054485202
              ],
              [
                -73.84978114316354,
                40.779111930042816
              ],
              [
                -73.84978489696313,
                40.77910742313584
              ],
              [
                -73.8497879996364,
                40.779102772648265
              ],
              [
                -73.84979044990959,
                40.77909795713514
              ],
              [
                -73.8497922389795,
                40.779092971223974
              ],
              [
                -73.84979610479625,
                40.77907534402303
              ],
              [
                -73.84980088112201,
                40.778793284827636
              ],
              [
                -73.84993080856658,
                40.77879720785134
              ],
              [
                -73.84993353296176,
                40.77876912338104
              ],
              [
                -73.84980132097789,
                40.77876730703298
              ],
              [
                -73.84980157152407,
                40.7787525659411
              ],
              [
                -73.8497999937689,
                40.77874838579522
              ],
              [
                -73.84979758647495,
                40.77874441213235
              ],
              [
                -73.84979436044361,
                40.77874064379384
              ],
              [
                -73.84979030575784,
                40.77873708076647
              ],
              [
                -73.84978540855205,
                40.77873371482377
              ],
              [
                -73.84977311348453,
                40.77872756702233
              ],
              [
                -73.84975786860362,
                40.77872230577251
              ],
              [
                -73.84973917397001,
                40.77871768951932
              ],
              [
                -73.84969513318683,
                40.77871069719228
              ],
              [
                -73.849686879789,
                40.77870938977827
              ],
              [
                -73.84960429240051,
                40.7787021144363
              ],
              [
                -73.84957720816625,
                40.77869873929359
              ],
              [
                -73.8495368533371,
                40.77869103973357
              ],
              [
                -73.84950513864881,
                40.77868183198595
              ],
              [
                -73.8494795769649,
                40.77867039747487
              ],
              [
                -73.84946904846156,
                40.778663783211705
              ],
              [
                -73.84945995577684,
                40.77865656806612
              ],
              [
                -73.84945566507523,
                40.778652230080105
              ],
              [
                -73.84945203895039,
                40.77864754283362
              ],
              [
                -73.84944905360406,
                40.77864250512285
              ],
              [
                -73.84944670731139,
                40.77863709985818
              ],
              [
                -73.84944397412765,
                40.778625218719164
              ],
              [
                -73.8494438267176,
                40.778611854336
              ],
              [
                -73.8494461945274,
                40.778597215353415
              ],
              [
                -73.84945106324766,
                40.77858090421403
              ],
              [
                -73.84945846130667,
                40.778562822784885
              ],
              [
                -73.84946927977728,
                40.77854069455069
              ],
              [
                -73.84947124090881,
                40.778537589511885
              ],
              [
                -73.84947388931542,
                40.77853462207157
              ],
              [
                -73.84947722420407,
                40.77853175319544
              ],
              [
                -73.84948131861695,
                40.77852894025979
              ],
              [
                -73.84951133367666,
                40.77851424792635
              ],
              [
                -73.84952033715504,
                40.77850885580932
              ],
              [
                -73.84952383818543,
                40.77850599653018
              ],
              [
                -73.84952657007254,
                40.77850304678794
              ],
              [
                -73.84952858977923,
                40.77849996041986
              ],
              [
                -73.84952990130999,
                40.77849672067873
              ],
              [
                -73.8495343034803,
                40.778477473709735
              ],
              [
                -73.84953664690558,
                40.778457620289885
              ],
              [
                -73.84974445808719,
                40.77846481410773
              ],
              [
                -73.84974741420471,
                40.778414054006106
              ],
              [
                -73.84969626130186,
                40.77841506766977
              ],
              [
                -73.8496919692504,
                40.77842802269303
              ],
              [
                -73.8495358659634,
                40.778425885557084
              ],
              [
                -73.84953505641919,
                40.77841695203263
              ],
              [
                -73.84953114505714,
                40.77839659523964
              ],
              [
                -73.84952520807873,
                40.77837657889388
              ],
              [
                -73.84951731632304,
                40.778357153370855
              ],
              [
                -73.84950757331885,
                40.7783385362538
              ],
              [
                -73.84949436325333,
                40.77831779842351
              ],
              [
                -73.84947844499678,
                40.778296066139994
              ],
              [
                -73.84943535665232,
                40.77824556318453
              ],
              [
                -73.84939585408341,
                40.778196362050124
              ],
              [
                -73.84937825751246,
                40.77817763581692
              ],
              [
                -73.84936761626928,
                40.77816868072814
              ],
              [
                -73.84935671111596,
                40.77816133689004
              ],
              [
                -73.84934534071354,
                40.77815546800863
              ],
              [
                -73.8493333857531,
                40.77815101361854
              ],
              [
                -73.8493120634367,
                40.77814545571613
              ],
              [
                -73.84928764656797,
                40.77814096626154
              ],
              [
                -73.84923662799216,
                40.778135009824275
              ],
              [
                -73.84920561102659,
                40.77813138569846
              ],
              [
                -73.84917605388847,
                40.7781268708982
              ],
              [
                -73.84911406661877,
                40.77811469640718
              ],
              [
                -73.8490659137323,
                40.77810355721967
              ],
              [
                -73.84904969603313,
                40.77809841270682
              ],
              [
                -73.84903388635963,
                40.77809137635637
              ],
              [
                -73.84901882664829,
                40.77808258615509
              ],
              [
                -73.84900491920531,
                40.778072283364835
              ],
              [
                -73.84899380740195,
                40.778061922754446
              ],
              [
                -73.84899254202304,
                40.77806074087802
              ],
              [
                -73.84898207469953,
                40.778048300609925
              ],
              [
                -73.84897383480374,
                40.778035349458925
              ],
              [
                -73.84896802544691,
                40.77802221269157
              ],
              [
                -73.84896008358999,
                40.77799309754688
              ],
              [
                -73.84895559325399,
                40.777961673731355
              ],
              [
                -73.84895450913376,
                40.77792770447223
              ],
              [
                -73.84895680884702,
                40.77789056185412
              ],
              [
                -73.84897165987937,
                40.77777261678307
              ],
              [
                -73.84897404925638,
                40.77767465408786
              ],
              [
                -73.84897771676305,
                40.77763491828797
              ],
              [
                -73.84899941431672,
                40.77752205117339
              ],
              [
                -73.84901842052128,
                40.77740463203712
              ],
              [
                -73.84903491312333,
                40.777288443429185
              ],
              [
                -73.84903813840937,
                40.77700475346812
              ],
              [
                -73.84911636597812,
                40.775787518869464
              ],
              [
                -73.84911614425795,
                40.77578626632447
              ],
              [
                -73.84900348408753,
                40.77568014817956
              ],
              [
                -73.84893873474758,
                40.77556035848066
              ],
              [
                -73.84897287691919,
                40.77545132951898
              ],
              [
                -73.84901442828632,
                40.77534379717124
              ],
              [
                -73.84903349588858,
                40.77530039479502
              ],
              [
                -73.84906220320683,
                40.77524019861196
              ],
              [
                -73.84909325000882,
                40.775180677260146
              ],
              [
                -73.84913166855965,
                40.77509341475703
              ],
              [
                -73.84916308193993,
                40.77500454716513
              ],
              [
                -73.84918737541369,
                40.77491439914948
              ],
              [
                -73.8492044602571,
                40.77482330005167
              ],
              [
                -73.84921427408379,
                40.77473158268647
              ],
              [
                -73.84921678107064,
                40.77463958212611
              ],
              [
                -73.84921197208924,
                40.77454763447593
              ],
              [
                -73.84912365290268,
                40.774260228454885
              ],
              [
                -73.84896112400044,
                40.7737426847533
              ],
              [
                -73.84897619867189,
                40.773561667957
              ],
              [
                -73.84918580437129,
                40.773104720587774
              ],
              [
                -73.84957160955892,
                40.77256110383076
              ],
              [
                -73.84972769544878,
                40.77241320129405
              ],
              [
                -73.84983958377667,
                40.77223100286741
              ],
              [
                -73.85004065546363,
                40.77222460055493
              ],
              [
                -73.85003738572694,
                40.772181073933574
              ],
              [
                -73.84986902411924,
                40.772183062008295
              ],
              [
                -73.84994620808664,
                40.772057372616196
              ],
              [
                -73.849962928372,
                40.77199648468658
              ],
              [
                -73.84995162517303,
                40.77195829887714
              ],
              [
                -73.85019486668942,
                40.77197227426243
              ],
              [
                -73.85019734544954,
                40.77196132649058
              ],
              [
                -73.85029717573791,
                40.77196635402256
              ],
              [
                -73.85029707789941,
                40.77200987726779
              ],
              [
                -73.85033410569142,
                40.77201760626068
              ],
              [
                -73.85036146530206,
                40.77183074564788
              ],
              [
                -73.85125374913325,
                40.77189078614855
              ],
              [
                -73.85124007806625,
                40.771980375036954
              ],
              [
                -73.85127037620715,
                40.771985534743536
              ],
              [
                -73.85130108374476,
                40.771806360797704
              ],
              [
                -73.85126742588308,
                40.771798636465164
              ],
              [
                -73.85126052461455,
                40.771872872816786
              ],
              [
                -73.85036823526043,
                40.771815394333125
              ],
              [
                -73.8503784815698,
                40.77175140188951
              ],
              [
                -73.85033808818524,
                40.77174366853876
              ],
              [
                -73.85031415825226,
                40.77190237084045
              ],
              [
                -73.8502906085098,
                40.771892099056686
              ],
              [
                -73.85028379823378,
                40.771925372392815
              ],
              [
                -73.85031075024499,
                40.77192028681627
              ],
              [
                -73.85030397450446,
                40.77193819958954
              ],
              [
                -73.85020240095857,
                40.771945534876906
              ],
              [
                -73.85020188336162,
                40.771937392784736
              ],
              [
                -73.84994241882967,
                40.77192719325984
              ],
              [
                -73.84994046677184,
                40.771920597906885
              ],
              [
                -73.84993252885828,
                40.77189377858083
              ],
              [
                -73.84982268798642,
                40.77185829087457
              ],
              [
                -73.84980501475297,
                40.771842589265155
              ],
              [
                -73.8497730365678,
                40.77180479915259
              ],
              [
                -73.84973804497628,
                40.77174241985013
              ],
              [
                -73.84973230426057,
                40.7717300945253
              ],
              [
                -73.84960979016878,
                40.771484204461025
              ],
              [
                -73.8495760947736,
                40.771438916609654
              ],
              [
                -73.84949402711861,
                40.771328616815396
              ],
              [
                -73.84933814775343,
                40.771187619866005
              ],
              [
                -73.84912991114923,
                40.771063788377575
              ],
              [
                -73.84890527065537,
                40.77098225068579
              ],
              [
                -73.84882636338835,
                40.770913141615985
              ],
              [
                -73.84874746796304,
                40.77084403300933
              ],
              [
                -73.8486715286884,
                40.77076157290255
              ],
              [
                -73.84864527048879,
                40.7707192413199
              ],
              [
                -73.84862191287661,
                40.77068804462914
              ],
              [
                -73.84861027612754,
                40.770659086569864
              ],
              [
                -73.8485979041902,
                40.7706354051098
              ],
              [
                -73.84859368539874,
                40.77062392002509
              ],
              [
                -73.84858696848256,
                40.770605638316596
              ],
              [
                -73.84858418501082,
                40.77054330264631
              ],
              [
                -73.84858664304565,
                40.770504249904974
              ],
              [
                -73.84861273687663,
                40.77042603035469
              ],
              [
                -73.8486255096204,
                40.770387757427166
              ],
              [
                -73.84872671824314,
                40.77010618908876
              ],
              [
                -73.84899274432657,
                40.77002868007275
              ],
              [
                -73.84907551195148,
                40.77019356377016
              ],
              [
                -73.8497619875757,
                40.769961156547325
              ],
              [
                -73.84977407793218,
                40.76993601294297
              ],
              [
                -73.84975004147577,
                40.769922257882556
              ],
              [
                -73.84969586733,
                40.769931335979635
              ],
              [
                -73.84964193972178,
                40.76983062462382
              ],
              [
                -73.84969012358812,
                40.76981010191443
              ],
              [
                -73.84970521295284,
                40.76978953551773
              ],
              [
                -73.84963028831294,
                40.76966134932508
              ],
              [
                -73.84960321683452,
                40.769659027095685
              ],
              [
                -73.84955506042947,
                40.76966811298154
              ],
              [
                -73.84949207284846,
                40.7695834015085
              ],
              [
                -73.84955529811103,
                40.769562898502194
              ],
              [
                -73.84955532911304,
                40.76954917482334
              ],
              [
                -73.84953434085783,
                40.76951712501355
              ],
              [
                -73.84950427178477,
                40.769510223882264
              ],
              [
                -73.848817810295,
                40.76973805463504
              ],
              [
                -73.84892724343374,
                40.76991478836154
              ],
              [
                -73.8487299918909,
                40.769989541779154
              ],
              [
                -73.84860101425126,
                40.76981782588792
              ],
              [
                -73.84860729245335,
                40.76970346891756
              ],
              [
                -73.84865863960181,
                40.76961433219835
              ],
              [
                -73.8487178873391,
                40.76954777089411
              ],
              [
                -73.84884133186712,
                40.769464105141275
              ],
              [
                -73.84892276167722,
                40.76940717390536
              ],
              [
                -73.8489225578029,
                40.769324534965435
              ],
              [
                -73.84901287079057,
                40.76921530435127
              ],
              [
                -73.84883522381716,
                40.768752302094015
              ],
              [
                -73.84866634360748,
                40.76862335350159
              ],
              [
                -73.84860939647602,
                40.76858643516744
              ],
              [
                -73.8485483108146,
                40.768553562604424
              ],
              [
                -73.84848358226597,
                40.76852500253367
              ],
              [
                -73.84841573602712,
                40.76850098668508
              ],
              [
                -73.8483453225899,
                40.76848170991812
              ],
              [
                -73.84832720628944,
                40.76846643874889
              ],
              [
                -73.84831243929806,
                40.768449208817756
              ],
              [
                -73.84830138199058,
                40.7684304406085
              ],
              [
                -73.84829430420871,
                40.768410592144626
              ],
              [
                -73.84829137867685,
                40.76839014781252
              ],
              [
                -73.84833089177016,
                40.76833211504437
              ],
              [
                -73.84837586137671,
                40.768276425377714
              ],
              [
                -73.84842604843061,
                40.768223374851715
              ],
              [
                -73.84848118613255,
                40.76817324547557
              ],
              [
                -73.84852060615141,
                40.76813541216273
              ],
              [
                -73.84856697139021,
                40.7680909071991
              ],
              [
                -73.8485178978279,
                40.7680141479867
              ],
              [
                -73.84850405385016,
                40.76799249900586
              ],
              [
                -73.84849386891709,
                40.76797657316442
              ],
              [
                -73.84842038279727,
                40.76791076681383
              ],
              [
                -73.8483741186844,
                40.76786934596774
              ],
              [
                -73.84828222856984,
                40.76774537878625
              ],
              [
                -73.84821324018463,
                40.767634597848
              ],
              [
                -73.84809411442576,
                40.76737876897699
              ],
              [
                -73.84804248817565,
                40.76723778076487
              ],
              [
                -73.84799048271634,
                40.7671171613372
              ],
              [
                -73.84792234568383,
                40.767012496032976
              ],
              [
                -73.84786167715994,
                40.76685023340278
              ],
              [
                -73.84780575104425,
                40.76677294817163
              ],
              [
                -73.8476935357496,
                40.76670591213375
              ],
              [
                -73.84738263097066,
                40.766419412623954
              ],
              [
                -73.84723067976141,
                40.76631315809567
              ],
              [
                -73.84712188593454,
                40.76629056396761
              ],
              [
                -73.84701309197985,
                40.76626796152773
              ],
              [
                -73.84684964636887,
                40.7662620347584
              ],
              [
                -73.84666768544618,
                40.76628375673098
              ],
              [
                -73.84652170418624,
                40.76629580063175
              ],
              [
                -73.84620024037935,
                40.76619634316636
              ],
              [
                -73.84614072149937,
                40.76611570431364
              ],
              [
                -73.84602715981245,
                40.76604487155385
              ],
              [
                -73.84593023292821,
                40.76601257632095
              ],
              [
                -73.84528163541583,
                40.76566611948253
              ],
              [
                -73.84491332846012,
                40.76552838817853
              ],
              [
                -73.84448895884195,
                40.765459602583896
              ],
              [
                -73.84426568006505,
                40.76546921500887
              ],
              [
                -73.84407906365587,
                40.76549676136358
              ],
              [
                -73.84394748335806,
                40.76549488148575
              ],
              [
                -73.8437063352798,
                40.765679968345346
              ],
              [
                -73.84359974376382,
                40.7656434804411
              ],
              [
                -73.84346820695714,
                40.76565307141732
              ],
              [
                -73.84336969753039,
                40.76572529284771
              ],
              [
                -73.84295915637364,
                40.765854722424905
              ],
              [
                -73.8426819600242,
                40.765830607164375
              ],
              [
                -73.84253623494851,
                40.765862920695376
              ],
              [
                -73.84251137775342,
                40.76579766885277
              ],
              [
                -73.84252754583213,
                40.76579296554705
              ],
              [
                -73.84252261658963,
                40.765775004881924
              ],
              [
                -73.84247287859779,
                40.76578533237702
              ],
              [
                -73.84248152879428,
                40.76580613233626
              ],
              [
                -73.84249272360755,
                40.76580236758494
              ],
              [
                -73.84251347571919,
                40.76586796746762
              ],
              [
                -73.84231828009945,
                40.76591125238224
              ],
              [
                -73.84228991176651,
                40.76589941989144
              ],
              [
                -73.84227261595431,
                40.76585592824656
              ],
              [
                -73.84232609735666,
                40.76583899337526
              ],
              [
                -73.84232856912517,
                40.7658446651673
              ],
              [
                -73.84236463453715,
                40.765835266052655
              ],
              [
                -73.84235723067178,
                40.765812576589134
              ],
              [
                -73.842317444969,
                40.76581913757782
              ],
              [
                -73.8423186823166,
                40.76582102929956
              ],
              [
                -73.84190583063506,
                40.76591873694828
              ],
              [
                -73.84190335600029,
                40.76591400814701
              ],
              [
                -73.84186231494718,
                40.76592529148889
              ],
              [
                -73.84187344475318,
                40.76594893027434
              ],
              [
                -73.84191572852082,
                40.76593765030904
              ],
              [
                -73.8419157397382,
                40.76593292441631
              ],
              [
                -73.84196546583135,
                40.765927323053496
              ],
              [
                -73.84198401916458,
                40.76596514588186
              ],
              [
                -73.84196444921889,
                40.76598971084912
              ],
              [
                -73.84174481256957,
                40.76603841406753
              ],
              [
                -73.8417130964574,
                40.765958160228685
              ],
              [
                -73.84166211638194,
                40.76596848399208
              ],
              [
                -73.84167323806332,
                40.76599495749284
              ],
              [
                -73.8417012582299,
                40.76598832628327
              ],
              [
                -73.84172286075056,
                40.7660432805295
              ],
              [
                -73.84144364387917,
                40.766105192987204
              ],
              [
                -73.84122723929276,
                40.76614622161618
              ],
              [
                -73.84122309104404,
                40.76609544731743
              ],
              [
                -73.84123676821112,
                40.76609357613727
              ],
              [
                -73.84123679749322,
                40.766081292334725
              ],
              [
                -73.84120075497815,
                40.76608124266235
              ],
              [
                -73.84119574495959,
                40.76609729965605
              ],
              [
                -73.84120337400219,
                40.76609747652462
              ],
              [
                -73.84120530561162,
                40.76615038087749
              ],
              [
                -73.84105643008813,
                40.7661786050842
              ],
              [
                -73.84105761922727,
                40.7661689243929
              ],
              [
                -73.84108497073595,
                40.7661651820664
              ],
              [
                -73.8410788870798,
                40.76611036771657
              ],
              [
                -73.84100802893788,
                40.76611688421589
              ],
              [
                -73.84102191922433,
                40.76618514928136
              ],
              [
                -73.84088883538394,
                40.76621037925818
              ],
              [
                -73.84086500211306,
                40.766159208867364
              ],
              [
                -73.84087122017897,
                40.766157327427656
              ],
              [
                -73.84086007103146,
                40.766142193980635
              ],
              [
                -73.840836443574,
                40.76614783092416
              ],
              [
                -73.8408401364553,
                40.76616295457573
              ],
              [
                -73.84084759497334,
                40.76616202020004
              ],
              [
                -73.84086515071182,
                40.76621486974827
              ],
              [
                -73.84057222218038,
                40.76627040438149
              ],
              [
                -73.84031170315292,
                40.76629479789843
              ],
              [
                -73.84027438049095,
                40.76637967295619
              ],
              [
                -73.84027020920483,
                40.76646466650519
              ],
              [
                -73.84022374446803,
                40.76652924030131
              ],
              [
                -73.840113137722,
                40.76656666461222
              ],
              [
                -73.84000758970934,
                40.76662342046595
              ],
              [
                -73.83990190913454,
                40.76682415841404
              ],
              [
                -73.8398843082266,
                40.766952204208515
              ],
              [
                -73.8399402231328,
                40.76700188164961
              ],
              [
                -73.83993922177474,
                40.76706427714744
              ],
              [
                -73.83986957818284,
                40.76708653570916
              ],
              [
                -73.83978750472959,
                40.76705148541017
              ],
              [
                -73.83975387982034,
                40.767120445440895
              ],
              [
                -73.83968175000263,
                40.76710489116111
              ],
              [
                -73.83959072609815,
                40.76711082471904
              ],
              [
                -73.8394980579582,
                40.767017016984724
              ],
              [
                -73.83951000594936,
                40.766974160215085
              ],
              [
                -73.8395605054185,
                40.76691684117708
              ],
              [
                -73.83969375990986,
                40.76682480595994
              ],
              [
                -73.83973386806854,
                40.76673441465324
              ],
              [
                -73.83973778802071,
                40.76665087171897
              ],
              [
                -73.83969772549081,
                40.76639267704533
              ],
              [
                -73.83954371555879,
                40.766211721721625
              ],
              [
                -73.8395267864783,
                40.76613954972556
              ],
              [
                -73.83925621702686,
                40.766034209638754
              ],
              [
                -73.83913189281712,
                40.765991105784146
              ],
              [
                -73.83871102200001,
                40.76666838599988
              ],
              [
                -73.83866554599996,
                40.76674036599996
              ],
              [
                -73.83805766700006,
                40.76759634399986
              ],
              [
                -73.83746794599992,
                40.76830671799997
              ],
              [
                -73.83711212099998,
                40.768642871999894
              ],
              [
                -73.83678033699985,
                40.76896614799993
              ],
              [
                -73.83614052499993,
                40.76952737399995
              ],
              [
                -73.83536623599981,
                40.77012144099995
              ],
              [
                -73.83511636199991,
                40.77028363399989
              ],
              [
                -73.83433960199982,
                40.770787819999896
              ],
              [
                -73.83338983199998,
                40.77134538399991
              ],
              [
                -73.83209910199999,
                40.77210429399991
              ],
              [
                -73.83161455499989,
                40.77241020899988
              ],
              [
                -73.83150226899997,
                40.77248795599992
              ],
              [
                -73.83090463599999,
                40.7729830689999
              ],
              [
                -73.83000011099983,
                40.77373240699996
              ],
              [
                -73.82908256199991,
                40.77479432099991
              ],
              [
                -73.828793895,
                40.77524292899992
              ],
              [
                -73.82830621299996,
                40.776000787999905
              ],
              [
                -73.82789985099988,
                40.77668223299992
              ],
              [
                -73.82665895999983,
                40.778818600999884
              ],
              [
                -73.82642473899999,
                40.779228734999954
              ],
              [
                -73.82616437200001,
                40.77968464499987
              ],
              [
                -73.82576928399986,
                40.78046316399985
              ],
              [
                -73.82557907999986,
                40.78083309199999
              ],
              [
                -73.82513740299993,
                40.78169970599994
              ],
              [
                -73.82528484199993,
                40.78169729199988
              ],
              [
                -73.82569679399981,
                40.78169053099991
              ],
              [
                -73.82678476000001,
                40.78166766899995
              ],
              [
                -73.82770726099993,
                40.781667916999886
              ],
              [
                -73.83218249399988,
                40.78165887099988
              ],
              [
                -73.83669385900005,
                40.78164957499991
              ],
              [
                -73.83777640299981,
                40.78165237399991
              ],
              [
                -73.83869921299996,
                40.78167695699988
              ],
              [
                -73.83862028899992,
                40.78322714299991
              ],
              [
                -73.83853062500003,
                40.78470030299992
              ],
              [
                -73.83845605499975,
                40.78582446799989
              ],
              [
                -73.83834963799995,
                40.785850305999936
              ],
              [
                -73.83824429099994,
                40.78778591599996
              ],
              [
                -73.83916328899986,
                40.78782010599988
              ],
              [
                -73.84008157499997,
                40.78785153099995
              ],
              [
                -73.83999984499995,
                40.78925199699993
              ],
              [
                -73.83992306199994,
                40.79064990199992
              ],
              [
                -73.83900524099994,
                40.79061732199994
              ],
              [
                -73.83895676099984,
                40.791438430999946
              ],
              [
                -73.83987372999988,
                40.791470541999914
              ],
              [
                -73.84081532599994,
                40.79150488499992
              ],
              [
                -73.8407608009999,
                40.79250035099991
              ],
              [
                -73.84067828300006,
                40.79390789199993
              ],
              [
                -73.84050805399978,
                40.793851798999874
              ],
              [
                -73.84050794799997,
                40.79385176199988
              ],
              [
                -73.84034364899992,
                40.79378386199988
              ],
              [
                -73.84018738599987,
                40.79370475199994
              ],
              [
                -73.84018731600003,
                40.79370471599992
              ],
              [
                -73.84018725699998,
                40.79370467999993
              ],
              [
                -73.84004094899993,
                40.79361525499989
              ],
              [
                -73.83990622699984,
                40.79351658899994
              ],
              [
                -73.83978455899991,
                40.793410080999934
              ],
              [
                -73.83966987799987,
                40.79330068999995
              ],
              [
                -73.8396698189999,
                40.79330063699999
              ],
              [
                -73.83953626099989,
                40.79320132299989
              ],
              [
                -73.8395361909999,
                40.793201277999934
              ],
              [
                -73.83938616999993,
                40.79311469299992
              ],
              [
                -73.83922303099996,
                40.793043045999916
              ],
              [
                -73.83905088399997,
                40.79298788499989
              ],
              [
                -73.83905076599997,
                40.79298784899993
              ],
              [
                -73.83905064799991,
                40.79298782099989
              ],
              [
                -73.83887362400004,
                40.792949805999854
              ],
              [
                -73.83818069199988,
                40.79274850199993
              ],
              [
                -73.83663288911686,
                40.79251637576928
              ],
              [
                -73.83686308614463,
                40.79306679805601
              ],
              [
                -73.83712604698766,
                40.79369555002054
              ],
              [
                -73.83707146788825,
                40.79379892222571
              ],
              [
                -73.83706498890116,
                40.79385109821308
              ],
              [
                -73.8370840678496,
                40.793903461985295
              ],
              [
                -73.83717497419711,
                40.794023700013284
              ],
              [
                -73.83722791411225,
                40.794111384246534
              ],
              [
                -73.83730248787447,
                40.79421958581836
              ],
              [
                -73.83736225294896,
                40.79426352412382
              ],
              [
                -73.83840083088981,
                40.796755258653434
              ],
              [
                -73.83843368293606,
                40.796786758946396
              ],
              [
                -73.83903676806116,
                40.79705247704045
              ],
              [
                -73.83997099590027,
                40.796905641892224
              ],
              [
                -73.84025860809128,
                40.7975888584321
              ],
              [
                -73.84022425799824,
                40.79761115131156
              ],
              [
                -73.83919004727183,
                40.79777726644771
              ],
              [
                -73.83839013354552,
                40.798304872075995
              ]
            ]
          ],
          [
            [
              [
                -73.8678840747076,
                40.78736103669457
              ],
              [
                -73.86809912257888,
                40.7874586618184
              ],
              [
                -73.86823784768373,
                40.78727964308481
              ],
              [
                -73.86817127125168,
                40.78725619565451
              ],
              [
                -73.8692349173292,
                40.78596991495815
              ],
              [
                -73.86918113468879,
                40.785943489784934
              ],
              [
                -73.86813032000016,
                40.78723277784419
              ],
              [
                -73.8680330487346,
                40.78718202995269
              ],
              [
                -73.8678840747076,
                40.78736103669457
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000132452130536,
        "objectid": 54,
        "shape_leng": 0.0762192448554,
        "location_id": 54,
        "zone": "Columbia Street",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -74.00174362072502,
                40.69240674985267
              ],
              [
                -74.0015417289999,
                40.692785962999956
              ],
              [
                -74.00127014951309,
                40.693296061836925
              ],
              [
                -74.0034621809253,
                40.694057015074726
              ],
              [
                -74.00374561906422,
                40.69361620298103
              ],
              [
                -74.00400795971088,
                40.69320819963236
              ],
              [
                -74.00174362072502,
                40.69240674985267
              ]
            ]
          ],
          [
            [
              [
                -74.00174362072502,
                40.69240674985267
              ],
              [
                -74.00181765040728,
                40.69222196248141
              ],
              [
                -74.00196938526074,
                40.691843208317536
              ],
              [
                -74.00440941259976,
                40.692528800389354
              ],
              [
                -74.00479415294552,
                40.69176162037444
              ],
              [
                -74.00069905841875,
                40.690611858419096
              ],
              [
                -74.0009615685494,
                40.69012876835004
              ],
              [
                -74.00185304524615,
                40.69038798674182
              ],
              [
                -74.00196321992853,
                40.69013637946073
              ],
              [
                -74.00297565436571,
                40.69042657592072
              ],
              [
                -74.0053420962491,
                40.6910916029778
              ],
              [
                -74.00575900670344,
                40.69023640001616
              ],
              [
                -74.00340921624516,
                40.68961326116011
              ],
              [
                -74.00382038919723,
                40.68892964469926
              ],
              [
                -74.00636461225261,
                40.689669668636974
              ],
              [
                -74.00678438439806,
                40.688826879958285
              ],
              [
                -74.00459270521766,
                40.68821522298663
              ],
              [
                -74.00500373315064,
                40.687605985400886
              ],
              [
                -74.00742012154092,
                40.68820629043599
              ],
              [
                -74.00783293766679,
                40.687385055162764
              ],
              [
                -74.00562986330395,
                40.686784206043626
              ],
              [
                -74.00566680246698,
                40.686698078833786
              ],
              [
                -74.00588224183221,
                40.6861957688936
              ],
              [
                -74.0064414683233,
                40.68628849023208
              ],
              [
                -74.00701988682643,
                40.68652641719403
              ],
              [
                -74.00754474403941,
                40.68631824459358
              ],
              [
                -74.0077170754789,
                40.686264429009206
              ],
              [
                -74.00753083199993,
                40.68607448699991
              ],
              [
                -74.0071710969999,
                40.6863570099999
              ],
              [
                -74.00549714699999,
                40.68584772699993
              ],
              [
                -74.00485241699997,
                40.68566813099993
              ],
              [
                -74.0051790759999,
                40.684997224999904
              ],
              [
                -74.0054980969999,
                40.68432595799989
              ],
              [
                -74.00581925399995,
                40.683656554999914
              ],
              [
                -74.00596041099988,
                40.683362870999936
              ],
              [
                -74.00567492299993,
                40.682929927999965
              ],
              [
                -74.00560068499993,
                40.68281599899994
              ],
              [
                -74.00503641499995,
                40.681910287999884
              ],
              [
                -74.00456896599997,
                40.681149975999865
              ],
              [
                -74.00447389899988,
                40.680993011999846
              ],
              [
                -74.00378241799994,
                40.68008600199992
              ],
              [
                -74.00371762999994,
                40.68002103199994
              ],
              [
                -74.00345372099991,
                40.6797898869999
              ],
              [
                -74.0033891689999,
                40.679721305999905
              ],
              [
                -74.00329231299999,
                40.67964328799994
              ],
              [
                -74.00289363599995,
                40.68055940999991
              ],
              [
                -74.00256464499995,
                40.681228463999965
              ],
              [
                -74.00225222099995,
                40.681893211999906
              ],
              [
                -74.002204589,
                40.68199132399995
              ],
              [
                -74.00193169899997,
                40.68256321799994
              ],
              [
                -74.00160876999992,
                40.683235204999946
              ],
              [
                -74.00128182399993,
                40.68390963999992
              ],
              [
                -74.0009581009999,
                40.68457931899991
              ],
              [
                -74.0006473809999,
                40.68525394699991
              ],
              [
                -73.99969626699995,
                40.68719803399989
              ],
              [
                -73.99963768500004,
                40.687314767999965
              ],
              [
                -73.99937882999994,
                40.68785319299997
              ],
              [
                -73.99906961899994,
                40.68849807499985
              ],
              [
                -73.99874207699997,
                40.68917701399995
              ],
              [
                -73.99841229299983,
                40.68985608799994
              ],
              [
                -73.99808645399993,
                40.690535077999876
              ],
              [
                -73.99776924399993,
                40.69119469799997
              ],
              [
                -73.99803048599995,
                40.691253722999896
              ],
              [
                -73.9984330559999,
                40.691364914999895
              ],
              [
                -73.99910057299985,
                40.69153697399986
              ],
              [
                -73.99920161899989,
                40.69157183399994
              ],
              [
                -73.99928819599991,
                40.69159395999992
              ],
              [
                -73.99936540199982,
                40.691613077999904
              ],
              [
                -73.99951274099993,
                40.6916514499999
              ],
              [
                -73.99991517399985,
                40.69176722999993
              ],
              [
                -74.00021419499998,
                40.691851760999896
              ],
              [
                -74.00031547799995,
                40.69187465199994
              ],
              [
                -74.00110519399988,
                40.692056594999954
              ],
              [
                -74.0016998939999,
                40.69238276699991
              ],
              [
                -74.00174362072502,
                40.69240674985267
              ]
            ]
          ],
          [
            [
              [
                -74.010928413,
                40.684491472999845
              ],
              [
                -74.0086007239999,
                40.68590956499989
              ],
              [
                -74.00851294299997,
                40.68596254899994
              ],
              [
                -74.00842516199995,
                40.68601553199992
              ],
              [
                -74.00816320571415,
                40.686173644858464
              ],
              [
                -74.00835328548824,
                40.6864001294722
              ],
              [
                -74.00859803329703,
                40.686252564084974
              ],
              [
                -74.00869559889541,
                40.6861933180126
              ],
              [
                -74.00878590130924,
                40.686146602298834
              ],
              [
                -74.01217596614636,
                40.684095185628465
              ],
              [
                -74.01193259955522,
                40.68388774907754
              ],
              [
                -74.010928413,
                40.684491472999845
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000736274713586,
        "objectid": 55,
        "shape_leng": 0.149956807524,
        "location_id": 55,
        "zone": "Coney Island",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.98671582299981,
                40.58456849999991
              ],
              [
                -73.98701022999991,
                40.58462626699987
              ],
              [
                -73.98710120299997,
                40.58464459199989
              ],
              [
                -73.98687540199994,
                40.584251587999866
              ],
              [
                -73.98683590499999,
                40.58418633099989
              ],
              [
                -73.98679577199978,
                40.58411362799992
              ],
              [
                -73.98660338299985,
                40.58376512099989
              ],
              [
                -73.98654457099988,
                40.583658590999946
              ],
              [
                -73.98662350499995,
                40.58367393699994
              ],
              [
                -73.98730893199986,
                40.58359445299993
              ],
              [
                -73.98829948299982,
                40.58346674699988
              ],
              [
                -73.98852399299993,
                40.58314353499986
              ],
              [
                -73.98874850099989,
                40.58282032299994
              ],
              [
                -73.98888884999998,
                40.58256733499994
              ],
              [
                -73.98928724999996,
                40.58195758299994
              ],
              [
                -73.98971762499994,
                40.58083822699989
              ],
              [
                -73.98995504960881,
                40.58066166976562
              ],
              [
                -73.9896996939193,
                40.580421107402984
              ],
              [
                -73.98954195208073,
                40.58041536536176
              ],
              [
                -73.9891363620069,
                40.580214877714866
              ],
              [
                -73.98890353923805,
                40.58000867868471
              ],
              [
                -73.98811730541621,
                40.57967968595251
              ],
              [
                -73.98800973376738,
                40.579670687155776
              ],
              [
                -73.98774679739302,
                40.57985965559591
              ],
              [
                -73.98757400228179,
                40.580031451227434
              ],
              [
                -73.98745816143226,
                40.58037825761141
              ],
              [
                -73.98740641500886,
                40.58049420468865
              ],
              [
                -73.98734721381531,
                40.58060805952954
              ],
              [
                -73.98728070259058,
                40.58071954370164
              ],
              [
                -73.98720704395298,
                40.580828384568434
              ],
              [
                -73.98712641800256,
                40.58093431595643
              ],
              [
                -73.98703902188055,
                40.581037078806155
              ],
              [
                -73.98694506928851,
                40.58113642180556
              ],
              [
                -73.98684478996456,
                40.581232102005174
              ],
              [
                -73.98604509916208,
                40.58172068515159
              ],
              [
                -73.98592418776619,
                40.581267437612176
              ],
              [
                -73.98591295341917,
                40.58122700200037
              ],
              [
                -73.98588567394853,
                40.581144424564805
              ],
              [
                -73.98616526744581,
                40.58095022879234
              ],
              [
                -73.98678109583868,
                40.5802939376777
              ],
              [
                -73.987021385777,
                40.580022764875224
              ],
              [
                -73.98694774757386,
                40.57998955776978
              ],
              [
                -73.98687788391226,
                40.57995190688341
              ],
              [
                -73.98681225500573,
                40.57991006023784
              ],
              [
                -73.9867512931689,
                40.57986429349332
              ],
              [
                -73.98669539997061,
                40.57981490813252
              ],
              [
                -73.98738302206559,
                40.5791375607141
              ],
              [
                -73.98739064388411,
                40.57908698306363
              ],
              [
                -73.98748785269989,
                40.579064017527266
              ],
              [
                -73.98758653958215,
                40.57904070176424
              ],
              [
                -73.98759745387363,
                40.57906643764765
              ],
              [
                -73.98746186967524,
                40.57910287834869
              ],
              [
                -73.98744159488432,
                40.579107291373404
              ],
              [
                -73.9874515182491,
                40.57913151319841
              ],
              [
                -73.9875128581447,
                40.57911569169754
              ],
              [
                -73.98786647687928,
                40.579026351107835
              ],
              [
                -73.98785456901827,
                40.579003643742816
              ],
              [
                -73.98761631521326,
                40.57906416865274
              ],
              [
                -73.98760837934643,
                40.57903313614564
              ],
              [
                -73.98798324056253,
                40.57894533574088
              ],
              [
                -73.98814208290418,
                40.57886596371379
              ],
              [
                -73.9884283376944,
                40.57881584261956
              ],
              [
                -73.98899093556328,
                40.578835099968835
              ],
              [
                -73.98944797186188,
                40.57905581569152
              ],
              [
                -73.98957956138615,
                40.57919294169363
              ],
              [
                -73.98969501304418,
                40.57921326907601
              ],
              [
                -73.99036206466565,
                40.57942458012044
              ],
              [
                -73.99069770945255,
                40.57937305159976
              ],
              [
                -73.99107141296983,
                40.57933144531963
              ],
              [
                -73.99115062563098,
                40.57930085055747
              ],
              [
                -73.99123288813318,
                40.579275360563095
              ],
              [
                -73.99131763577279,
                40.579255150315596
              ],
              [
                -73.99140428678798,
                40.57924035855055
              ],
              [
                -73.99149224635235,
                40.57923108680759
              ],
              [
                -73.9915809106584,
                40.57922739873351
              ],
              [
                -73.9916642362242,
                40.579229040197816
              ],
              [
                -73.99174714246814,
                40.579235614184704
              ],
              [
                -73.9918291279026,
                40.57924708092878
              ],
              [
                -73.9921546480924,
                40.57932062593542
              ],
              [
                -73.99776522704225,
                40.580588033875834
              ],
              [
                -73.99780862456937,
                40.58059580091589
              ],
              [
                -73.99784972512147,
                40.58060895978559
              ],
              [
                -73.99788738519247,
                40.580627144377196
              ],
              [
                -73.99792055699606,
                40.58064984875662
              ],
              [
                -73.99794831761601,
                40.58067644123904
              ],
              [
                -73.99796880098621,
                40.58070434765646
              ],
              [
                -73.99798330546042,
                40.5807343538841
              ],
              [
                -73.99799147376537,
                40.58076572082806
              ],
              [
                -73.99799310469567,
                40.58079767587739
              ],
              [
                -73.9979881580698,
                40.58082943193382
              ],
              [
                -73.99797675572093,
                40.580860206799585
              ],
              [
                -73.99794615384133,
                40.580933239811536
              ],
              [
                -73.99791969306348,
                40.58100720729048
              ],
              [
                -73.99789742220707,
                40.58108197280602
              ],
              [
                -73.99790087804635,
                40.58113484485748
              ],
              [
                -73.9979118449124,
                40.58118711799343
              ],
              [
                -73.99793019307933,
                40.5812381737973
              ],
              [
                -73.99795570549585,
                40.581287408252415
              ],
              [
                -73.99798808035105,
                40.58133423888847
              ],
              [
                -73.99802693464606,
                40.58137811167257
              ],
              [
                -73.9980718087242,
                40.58141850756346
              ],
              [
                -73.99812217170776,
                40.581454948653295
              ],
              [
                -73.9982194649592,
                40.581474377214306
              ],
              [
                -73.99831839136024,
                40.58148820431907
              ],
              [
                -73.9984183969461,
                40.58149635253897
              ],
              [
                -73.99851892170828,
                40.581498776245944
              ],
              [
                -73.99861940273051,
                40.58149546186773
              ],
              [
                -73.99871927734135,
                40.581486427964094
              ],
              [
                -73.99881798626606,
                40.581471725122775
              ],
              [
                -73.99891497675803,
                40.58145143567646
              ],
              [
                -73.99900970569459,
                40.58142567324118
              ],
              [
                -73.99918928335447,
                40.58139024745273
              ],
              [
                -73.99936820504236,
                40.581352942365946
              ],
              [
                -73.99952302656166,
                40.58131902610888
              ],
              [
                -73.99964299332305,
                40.58129380695348
              ],
              [
                -73.99976473870005,
                40.58127415783463
              ],
              [
                -73.99988781824608,
                40.581260150483836
              ],
              [
                -74.00001178264505,
                40.581251836036216
              ],
              [
                -74.00013617935197,
                40.58124924484477
              ],
              [
                -74.00026055424323,
                40.581252386368746
              ],
              [
                -74.00038445327529,
                40.5812612491398
              ],
              [
                -74.00050742414106,
                40.58127580080334
              ],
              [
                -74.00062901792148,
                40.58129598823697
              ],
              [
                -74.00073378767112,
                40.58131818388558
              ],
              [
                -74.00083687503617,
                40.58134456441586
              ],
              [
                -74.00124032633678,
                40.58142626537587
              ],
              [
                -74.0018599131742,
                40.58138751901811
              ],
              [
                -74.00220582876804,
                40.58136588602034
              ],
              [
                -74.00294374157795,
                40.58143283552103
              ],
              [
                -74.0039802361642,
                40.58172129566388
              ],
              [
                -74.00483627576769,
                40.58185484358028
              ],
              [
                -74.00604734235806,
                40.58198365303582
              ],
              [
                -74.00647984834727,
                40.58192825746382
              ],
              [
                -74.00745366008165,
                40.58180352284798
              ],
              [
                -74.00762456723098,
                40.58176779027861
              ],
              [
                -74.00779292169176,
                40.581725597620476
              ],
              [
                -74.00795830367427,
                40.58167705008141
              ],
              [
                -74.00812030080365,
                40.581622268715506
              ],
              [
                -74.00827850914793,
                40.58156139011996
              ],
              [
                -74.00843253422542,
                40.58149456609561
              ],
              [
                -74.00858199198898,
                40.58142196326759
              ],
              [
                -74.0087265097835,
                40.58134376266979
              ],
              [
                -74.00886572727528,
                40.58126015929335
              ],
              [
                -74.00888804655423,
                40.58124593565999
              ],
              [
                -74.00898674257299,
                40.58117734009006
              ],
              [
                -74.00908914150955,
                40.58111197592423
              ],
              [
                -74.00919506149998,
                40.581049959244936
              ],
              [
                -74.00930431442846,
                40.580991400189426
              ],
              [
                -74.00944620591711,
                40.5809227954353
              ],
              [
                -74.00959266683995,
                40.58086002089339
              ],
              [
                -74.00974328549282,
                40.58080325301871
              ],
              [
                -74.00989763848804,
                40.58075265138162
              ],
              [
                -74.01005529194393,
                40.58070835821917
              ],
              [
                -74.01021580270462,
                40.580670498035765
              ],
              [
                -74.01037871958451,
                40.580639177252806
              ],
              [
                -74.01054358463756,
                40.580614483910004
              ],
              [
                -74.01070388953003,
                40.58054068610742
              ],
              [
                -74.01086035571247,
                40.58046224526471
              ],
              [
                -74.01101275197246,
                40.58037927730176
              ],
              [
                -74.0111737229432,
                40.58028397882523
              ],
              [
                -74.01132932483026,
                40.580183630224795
              ],
              [
                -74.0114792853013,
                40.58007840714522
              ],
              [
                -74.01162334190214,
                40.57996849376229
              ],
              [
                -74.01176124251562,
                40.57985408246062
              ],
              [
                -74.01189274580408,
                40.57973537349643
              ],
              [
                -74.01201762162991,
                40.57961257464747
              ],
              [
                -74.01213565146023,
                40.57948590084882
              ],
              [
                -74.0122466287476,
                40.57935557381623
              ],
              [
                -74.01235035929233,
                40.579221821659054
              ],
              [
                -74.01274982470373,
                40.57855731173525
              ],
              [
                -74.01279422354547,
                40.578425008846196
              ],
              [
                -74.01284465365903,
                40.578293968594366
              ],
              [
                -74.01290105351399,
                40.57816435077231
              ],
              [
                -74.0129633543014,
                40.578036313437025
              ],
              [
                -74.0130314800179,
                40.577910012717226
              ],
              [
                -74.01303113457197,
                40.57785701811857
              ],
              [
                -74.01302222952468,
                40.577804459633505
              ],
              [
                -74.0129770052135,
                40.5777213292327
              ],
              [
                -74.0129242497425,
                40.57764082044216
              ],
              [
                -74.01286422267526,
                40.57756332933401
              ],
              [
                -74.01279721934527,
                40.577489237133136
              ],
              [
                -74.01245951831017,
                40.57668177066748
              ],
              [
                -74.01245056117921,
                40.57647475322235
              ],
              [
                -74.01243228719036,
                40.57626809234315
              ],
              [
                -74.01240471798019,
                40.57606203174
              ],
              [
                -74.01236788614587,
                40.57585681441279
              ],
              [
                -74.01232183520665,
                40.575652682365096
              ],
              [
                -74.01201540191126,
                40.574885052474166
              ],
              [
                -74.01190196490126,
                40.57479016650752
              ],
              [
                -74.01115668729254,
                40.57416676185886
              ],
              [
                -74.01071306846103,
                40.57402909731148
              ],
              [
                -74.01007410785421,
                40.57383080951765
              ],
              [
                -74.00942893690326,
                40.57379726841015
              ],
              [
                -74.00870865730187,
                40.57375981858765
              ],
              [
                -74.00666915632601,
                40.57339364837534
              ],
              [
                -74.0048224779998,
                40.57289022780212
              ],
              [
                -74.0047141012138,
                40.57287659512936
              ],
              [
                -74.00460744381672,
                40.57285657447204
              ],
              [
                -74.00450315234521,
                40.572830287191984
              ],
              [
                -74.00440185899215,
                40.572797892637816
              ],
              [
                -74.0043041777745,
                40.5727595871793
              ],
              [
                -74.0042107008097,
                40.57271560301657
              ],
              [
                -74.00412199472655,
                40.572666206772126
              ],
              [
                -74.00403859723095,
                40.57261169787529
              ],
              [
                -74.00396101384514,
                40.572552406746055
              ],
              [
                -74.00388971484409,
                40.57248869279238
              ],
              [
                -74.00388734496535,
                40.57245649855174
              ],
              [
                -74.00387896999834,
                40.57242489236713
              ],
              [
                -74.00386258296655,
                40.572390834656275
              ],
              [
                -74.0038392373756,
                40.57235922511668
              ],
              [
                -74.00380953899871,
                40.572330883942286
              ],
              [
                -74.00377425844584,
                40.57230654651929
              ],
              [
                -74.0037343111684,
                40.57228684434587
              ],
              [
                -74.00369073370504,
                40.57227228864539
              ],
              [
                -74.00364465678643,
                40.57226325710293
              ],
              [
                -74.00359727599627,
                40.57225998406482
              ],
              [
                -74.00354982074944,
                40.572262554458256
              ],
              [
                -74.00351575170326,
                40.572268106676184
              ],
              [
                -74.00348277706472,
                40.572276679383364
              ],
              [
                -74.0034513556506,
                40.57228815329691
              ],
              [
                -74.00336282594905,
                40.57228096314858
              ],
              [
                -74.00327571790024,
                40.57226693151758
              ],
              [
                -74.00319093834194,
                40.57224620448044
              ],
              [
                -74.00310936987007,
                40.57221899781597
              ],
              [
                -74.00303186164808,
                40.572185594759056
              ],
              [
                -74.00295922056691,
                40.57214634305124
              ],
              [
                -74.0028922028448,
                40.57210165132107
              ],
              [
                -74.00283150615377,
                40.57205198482918
              ],
              [
                -74.00277776235734,
                40.571997860624464
              ],
              [
                -74.00273153093207,
                40.57193984216144
              ],
              [
                -74.00266323324675,
                40.571894805291336
              ],
              [
                -74.00259949630394,
                40.57184603577701
              ],
              [
                -74.00254067002723,
                40.571793801377076
              ],
              [
                -74.00248707737698,
                40.57173838887243
              ],
              [
                -74.00234852717965,
                40.57110596794083
              ],
              [
                -74.00222854320391,
                40.57060986276879
              ],
              [
                -74.00220084969976,
                40.57030375439167
              ],
              [
                -74.00208547639728,
                40.569585983985
              ],
              [
                -74.00198396888365,
                40.5695965418238
              ],
              [
                -74.00205780931616,
                40.57015246290181
              ],
              [
                -74.002011670539,
                40.570208758524196
              ],
              [
                -74.0018778651766,
                40.57024746431582
              ],
              [
                -74.0016656216866,
                40.57030728168834
              ],
              [
                -74.00135400834894,
                40.57036486470278
              ],
              [
                -74.00120987388024,
                40.57038630953778
              ],
              [
                -74.00106438634509,
                40.57040154143544
              ],
              [
                -74.00091800593184,
                40.57041051221619
              ],
              [
                -74.00077119565371,
                40.57041319350455
              ],
              [
                -74.00056867395234,
                40.570441917960515
              ],
              [
                -74.00036483202332,
                40.570464570619556
              ],
              [
                -74.00015998281083,
                40.57048111670439
              ],
              [
                -73.99995444080645,
                40.57049153081294
              ],
              [
                -73.9997485215654,
                40.57049579695693
              ],
              [
                -73.99971929823451,
                40.570473797740014
              ],
              [
                -73.99972950792677,
                40.57045044112287
              ],
              [
                -73.9997329117931,
                40.57041151211029
              ],
              [
                -73.99970321419985,
                40.570386497522605
              ],
              [
                -73.99965850686432,
                40.57038260250282
              ],
              [
                -73.9996413990163,
                40.570445223004704
              ],
              [
                -73.9996140257524,
                40.570466096601464
              ],
              [
                -73.99922738028934,
                40.57048957710794
              ],
              [
                -73.99899128811825,
                40.570489575277385
              ],
              [
                -73.99863242815918,
                40.570508006690254
              ],
              [
                -73.99846777900147,
                40.570497396973
              ],
              [
                -73.99829669781617,
                40.57047391227484
              ],
              [
                -73.99818720620966,
                40.57046086506931
              ],
              [
                -73.997995595213,
                40.570447815853115
              ],
              [
                -73.99784504412378,
                40.57041389391208
              ],
              [
                -73.9976671213519,
                40.57037475116617
              ],
              [
                -73.99751999355462,
                40.57032517356224
              ],
              [
                -73.99744814048574,
                40.570286034393014
              ],
              [
                -73.99741966158959,
                40.570140854445874
              ],
              [
                -73.99736944882595,
                40.570137307542865
              ],
              [
                -73.99735576071078,
                40.57019731968318
              ],
              [
                -73.99735575750893,
                40.570278204147925
              ],
              [
                -73.99731811772298,
                40.57032256042305
              ],
              [
                -73.99719493825835,
                40.570348649043794
              ],
              [
                -73.99704438661118,
                40.57035647342445
              ],
              [
                -73.99699640776534,
                40.57036269138141
              ],
              [
                -73.99684901717619,
                40.57036696546883
              ],
              [
                -73.99665089870697,
                40.57037211675966
              ],
              [
                -73.99640796358747,
                40.570374718693586
              ],
              [
                -73.99600078794127,
                40.57039297082444
              ],
              [
                -73.99576469677095,
                40.57039296236246
              ],
              [
                -73.99551492045956,
                40.57035381411385
              ],
              [
                -73.99529301203255,
                40.570321282713856
              ],
              [
                -73.9952617292692,
                40.570228562336936
              ],
              [
                -73.99524805213261,
                40.57009810177865
              ],
              [
                -73.99518726085735,
                40.57002187763312
              ],
              [
                -73.9951351400427,
                40.57006678761193
              ],
              [
                -73.99515224064955,
                40.570178982743506
              ],
              [
                -73.99513854436809,
                40.57031466120667
              ],
              [
                -73.99493763229952,
                40.57035467451238
              ],
              [
                -73.99480664237873,
                40.57036422070031
              ],
              [
                -73.99468360322813,
                40.57036133741585
              ],
              [
                -73.99440631046578,
                40.570390294471544
              ],
              [
                -73.99401966538295,
                40.57041114867889
              ],
              [
                -73.9936750974706,
                40.570395135670054
              ],
              [
                -73.99362618034408,
                40.57039286361033
              ],
              [
                -73.9932977052498,
                40.57038240748573
              ],
              [
                -73.99315741886727,
                40.570379789438825
              ],
              [
                -73.99305289944259,
                40.570339025558845
              ],
              [
                -73.99302740713195,
                40.570278022968125
              ],
              [
                -73.99301372686757,
                40.570212792475736
              ],
              [
                -73.99298098072161,
                40.57019677330923
              ],
              [
                -73.99294529319607,
                40.57023627108529
              ],
              [
                -73.99296923014857,
                40.57036673204229
              ],
              [
                -73.99272854897059,
                40.570433788037406
              ],
              [
                -73.99252782858784,
                40.57046846400287
              ],
              [
                -73.99229545219589,
                40.570491069671604
              ],
              [
                -73.9922061931928,
                40.570499752070326
              ],
              [
                -73.99189139756308,
                40.570533649998794
              ],
              [
                -73.99156291512742,
                40.570577983489144
              ],
              [
                -73.99119337523611,
                40.5706118749683
              ],
              [
                -73.99086374684879,
                40.570602016242326
              ],
              [
                -73.99082728598098,
                40.57042137437878
              ],
              [
                -73.99079264766938,
                40.57024864875552
              ],
              [
                -73.99071781592744,
                40.57025959565118
              ],
              [
                -73.99074174672937,
                40.570403103606914
              ],
              [
                -73.99077310323929,
                40.57059942183784
              ],
              [
                -73.9905261484993,
                40.57065878712539
              ],
              [
                -73.99026267527424,
                40.57070834011726
              ],
              [
                -73.99014629531219,
                40.570726079786404
              ],
              [
                -73.98993761163376,
                40.57075788712605
              ],
              [
                -73.9896465753043,
                40.57079215540961
              ],
              [
                -73.98935934175859,
                40.570830892495096
              ],
              [
                -73.98914719716011,
                40.570843919183126
              ],
              [
                -73.98892820617891,
                40.5708752082258
              ],
              [
                -73.98883582208126,
                40.5708778085579
              ],
              [
                -73.98872145848834,
                40.57088369208262
              ],
              [
                -73.98869554520904,
                40.57081517428703
              ],
              [
                -73.98866476412249,
                40.57072906724002
              ],
              [
                -73.9886339755543,
                40.57069514448042
              ],
              [
                -73.98857772038879,
                40.57069790950494
              ],
              [
                -73.9886202548146,
                40.570896051314094
              ],
              [
                -73.98803716678532,
                40.57098510876872
              ],
              [
                -73.98777508425758,
                40.5710185968119
              ],
              [
                -73.98752187630423,
                40.571047270923586
              ],
              [
                -73.98726866835315,
                40.571075944476966
              ],
              [
                -73.98686148425026,
                40.5711072087355
              ],
              [
                -73.98654668594091,
                40.57113326486579
              ],
              [
                -73.98650905821334,
                40.57108107733054
              ],
              [
                -73.98646799852705,
                40.57107846272514
              ],
              [
                -73.9864508758885,
                40.57114890891037
              ],
              [
                -73.98593538175733,
                40.571178188922346
              ],
              [
                -73.98572931754805,
                40.57119588998151
              ],
              [
                -73.98375790448641,
                40.571396227250865
              ],
              [
                -73.98348842146662,
                40.57015603054909
              ],
              [
                -73.9838394948926,
                40.570112467157976
              ],
              [
                -73.98382125394879,
                40.57004578998432
              ],
              [
                -73.9834852186614,
                40.57008901506274
              ],
              [
                -73.98336058039273,
                40.56952999448667
              ],
              [
                -73.98326996675307,
                40.569541894356284
              ],
              [
                -73.98339168436635,
                40.570094685019434
              ],
              [
                -73.98304328883707,
                40.57014586098803
              ],
              [
                -73.98305809245075,
                40.57020965626845
              ],
              [
                -73.98340440737672,
                40.57016554451072
              ],
              [
                -73.98367270153267,
                40.57140705768738
              ],
              [
                -73.98308044496547,
                40.571508531433324
              ],
              [
                -73.98282723201832,
                40.571542413574626
              ],
              [
                -73.98249190051142,
                40.57156584591296
              ],
              [
                -73.98225580253013,
                40.57156841828967
              ],
              [
                -73.98217710691102,
                40.57156057845449
              ],
              [
                -73.98214632133059,
                40.57152143608207
              ],
              [
                -73.98209504346248,
                40.571346611478496
              ],
              [
                -73.9820266291875,
                40.57127615208919
              ],
              [
                -73.9819479404238,
                40.57123700215744
              ],
              [
                -73.98157155423429,
                40.5712499884248
              ],
              [
                -73.98154417427375,
                40.571273466986995
              ],
              [
                -73.98155785470236,
                40.571294342868484
              ],
              [
                -73.98159891057547,
                40.57130739548411
              ],
              [
                -73.98169656909477,
                40.571311371820634
              ],
              [
                -73.98192054501716,
                40.57132049258674
              ],
              [
                -73.9819923913309,
                40.5713518133093
              ],
              [
                -73.98201633411738,
                40.57138834604918
              ],
              [
                -73.98203685076774,
                40.57143792428011
              ],
              [
                -73.9820407585251,
                40.57148037052244
              ],
              [
                -73.98184180651329,
                40.57146920355464
              ],
              [
                -73.98163648890623,
                40.57152657358946
              ],
              [
                -73.98133877923644,
                40.57161002034813
              ],
              [
                -73.9810444940945,
                40.57168042036584
              ],
              [
                -73.98064890861849,
                40.571737732454054
              ],
              [
                -73.9803259222601,
                40.57173770221858
              ],
              [
                -73.98010351551893,
                40.57172722648448
              ],
              [
                -73.98000771572241,
                40.571698509185964
              ],
              [
                -73.97998743713075,
                40.57158360582357
              ],
              [
                -73.97995988926644,
                40.57144019050048
              ],
              [
                -73.97975464745674,
                40.57124968258197
              ],
              [
                -73.9795835711477,
                40.5712261688436
              ],
              [
                -73.97944328367615,
                40.57122092594798
              ],
              [
                -73.97939067136882,
                40.57126206346414
              ],
              [
                -73.97939114149196,
                40.57126899515512
              ],
              [
                -73.97939317076805,
                40.571275761419024
              ],
              [
                -73.97939669850376,
                40.571282159882365
              ],
              [
                -73.97940161918756,
                40.57128799917218
              ],
              [
                -73.97940778564593,
                40.57129310463993
              ],
              [
                -73.97941501344556,
                40.571297323585156
              ],
              [
                -73.9794230864085,
                40.57130052982251
              ],
              [
                -73.97943176307876,
                40.57130262745593
              ],
              [
                -73.97944078394436,
                40.57130355374673
              ],
              [
                -73.97944987919773,
                40.571303280990314
              ],
              [
                -73.97945877680687,
                40.57130181734474
              ],
              [
                -73.9794672106511,
                40.57129920658623
              ],
              [
                -73.97966565809048,
                40.5713331610994
              ],
              [
                -73.97972382225471,
                40.57134621741238
              ],
              [
                -73.97980592543583,
                40.57140363454865
              ],
              [
                -73.97986064824686,
                40.5714819205131
              ],
              [
                -73.979846944694,
                40.571539319610174
              ],
              [
                -73.97976481057256,
                40.57158627158869
              ],
              [
                -73.9795218304509,
                40.5717140787342
              ],
              [
                -73.97945681046643,
                40.57174276846096
              ],
              [
                -73.97910001556873,
                40.57184069801799
              ],
              [
                -73.97897339381906,
                40.57186422111465
              ],
              [
                -73.97884498357945,
                40.57188119517076
              ],
              [
                -73.97871536670108,
                40.57189154327378
              ],
              [
                -73.97858513050289,
                40.571895218534415
              ],
              [
                -73.97845486511183,
                40.571892204299225
              ],
              [
                -73.97832516078613,
                40.571882514226346
              ],
              [
                -73.97819660524176,
                40.57186619222339
              ],
              [
                -73.97806978098814,
                40.57184331224862
              ],
              [
                -73.97799924492543,
                40.571830476810476
              ],
              [
                -73.97793080193595,
                40.57181220403647
              ],
              [
                -73.97788258903813,
                40.57179554497028
              ],
              [
                -73.97783614406792,
                40.57177618437464
              ],
              [
                -73.97780796518646,
                40.57175620072628
              ],
              [
                -73.9777840795185,
                40.57173319377248
              ],
              [
                -73.97776503791167,
                40.57170769410005
              ],
              [
                -73.97775127949755,
                40.57168028978255
              ],
              [
                -73.97773918874084,
                40.57153371211849
              ],
              [
                -73.97770502384324,
                40.571377153447095
              ],
              [
                -73.97769819728057,
                40.5713249683295
              ],
              [
                -73.97771533072488,
                40.57125191384226
              ],
              [
                -73.97807599798213,
                40.571229570128686
              ],
              [
                -73.978047249339,
                40.57119979395959
              ],
              [
                -73.97767087523813,
                40.57117102007902
              ],
              [
                -73.97759877204504,
                40.57119103051838
              ],
              [
                -73.97756820091203,
                40.57124666665904
              ],
              [
                -73.97766384588131,
                40.571729387799046
              ],
              [
                -73.97730110158642,
                40.5718623859464
              ],
              [
                -73.97684256247973,
                40.571948398935305
              ],
              [
                -73.97621978857089,
                40.572016110127436
              ],
              [
                -73.97592552091535,
                40.57201865743752
              ],
              [
                -73.97567918029272,
                40.57195598449737
              ],
              [
                -73.97560269948812,
                40.571859283103585
              ],
              [
                -73.97551718820384,
                40.57169431528036
              ],
              [
                -73.9755088303412,
                40.57152449244049
              ],
              [
                -73.97548432668431,
                40.57145497834906
              ],
              [
                -73.97545696559193,
                40.571421051617605
              ],
              [
                -73.97540564094207,
                40.57142104064055
              ],
              [
                -73.97540561019994,
                40.571504534869035
              ],
              [
                -73.9754708190279,
                40.571969003816925
              ],
              [
                -73.97528564967088,
                40.572049832668604
              ],
              [
                -73.97484762696547,
                40.572164541765844
              ],
              [
                -73.97440961940832,
                40.57223750300214
              ],
              [
                -73.97400678258738,
                40.572274362546466
              ],
              [
                -73.9737320997119,
                40.57228692509402
              ],
              [
                -73.97344805325683,
                40.5722721069693
              ],
              [
                -73.97342758666748,
                40.572234670767834
              ],
              [
                -73.97339000577036,
                40.572088546788436
              ],
              [
                -73.97336611476469,
                40.57193720809319
              ],
              [
                -73.97334904033113,
                40.571851099622315
              ],
              [
                -73.97334306976626,
                40.57174057042443
              ],
              [
                -73.97332515756229,
                40.571676279132475
              ],
              [
                -73.97329095478177,
                40.571642350296564
              ],
              [
                -73.97324990115135,
                40.57162668661163
              ],
              [
                -73.97321224564611,
                40.57166842470265
              ],
              [
                -73.97323268078627,
                40.57190586596701
              ],
              [
                -73.97336256224074,
                40.57226335704354
              ],
              [
                -73.9733450222981,
                40.572321214006074
              ],
              [
                -73.97314693344347,
                40.57241463999529
              ],
              [
                -73.97293476162697,
                40.5724746026479
              ],
              [
                -73.97249673747078,
                40.5725762567474
              ],
              [
                -73.97183632056803,
                40.57262828035426
              ],
              [
                -73.97160706452378,
                40.572628223919835
              ],
              [
                -73.9715179092025,
                40.572581682404405
              ],
              [
                -73.97145657076729,
                40.57247946274589
              ],
              [
                -73.97122069416346,
                40.571962782201624
              ],
              [
                -73.97118306355702,
                40.571941899084166
              ],
              [
                -73.97113515153814,
                40.57196015213582
              ],
              [
                -73.97114248794517,
                40.572050631514536
              ],
              [
                -73.97137785791334,
                40.57251075309887
              ],
              [
                -73.9713504458748,
                40.572599460045254
              ],
              [
                -73.9713298962632,
                40.572643810384115
              ],
              [
                -73.97108691689401,
                40.572727244215606
              ],
              [
                -73.97085363074368,
                40.57277051527984
              ],
              [
                -73.97021089387842,
                40.57285226198176
              ],
              [
                -73.96996110201732,
                40.57286002458749
              ],
              [
                -73.96975580336873,
                40.572846924690396
              ],
              [
                -73.96955895151744,
                40.572808580460766
              ],
              [
                -73.96928385228033,
                40.57230147645186
              ],
              [
                -73.96924621215572,
                40.57230407565452
              ],
              [
                -73.96921197952125,
                40.57233537642347
              ],
              [
                -73.96946838633161,
                40.572823365705524
              ],
              [
                -73.96945126202378,
                40.572857280363216
              ],
              [
                -73.96920229198774,
                40.572957418516594
              ],
              [
                -73.96906454391251,
                40.57298763701027
              ],
              [
                -73.96897214113847,
                40.573021532304686
              ],
              [
                -73.96884893944646,
                40.573058027445015
              ],
              [
                -73.96856335985457,
                40.57312594655183
              ],
              [
                -73.96839037828869,
                40.573154442940094
              ],
              [
                -73.96818506098592,
                40.573177868546125
              ],
              [
                -73.96779155223932,
                40.57318819631818
              ],
              [
                -73.96762388104095,
                40.57319858543998
              ],
              [
                -73.96753149753678,
                40.5731907328873
              ],
              [
                -73.96744946374342,
                40.57300806491296
              ],
              [
                -73.96742628474536,
                40.57298510896918
              ],
              [
                -73.96739472238792,
                40.572995002688415
              ],
              [
                -73.96736390080565,
                40.57304456895217
              ],
              [
                -73.96743567793125,
                40.57320896967127
              ],
              [
                -73.96738975478867,
                40.573258999904446
              ],
              [
                -73.96720294103956,
                40.573326317397424
              ],
              [
                -73.96602239542973,
                40.57338859483254
              ],
              [
                -73.96508478576706,
                40.57346919863789
              ],
              [
                -73.96453618454449,
                40.57351319942493
              ],
              [
                -73.96398292380015,
                40.57355757223531
              ],
              [
                -73.96305899786316,
                40.57363555351794
              ],
              [
                -73.96267607127344,
                40.57366693903902
              ],
              [
                -73.96210769550765,
                40.57371352001862
              ],
              [
                -73.96090885893602,
                40.573865028946386
              ],
              [
                -73.96081416941384,
                40.573861809938755
              ],
              [
                -73.96068755363642,
                40.57387742198219
              ],
              [
                -73.9604890840351,
                40.573885181544064
              ],
              [
                -73.96021532299316,
                40.573911179120955
              ],
              [
                -73.95982864911235,
                40.57392930963578
              ],
              [
                -73.95939182223685,
                40.57391210937614
              ],
              [
                -73.95941778299985,
                40.575177982999925
              ],
              [
                -73.96103342799984,
                40.57507632399992
              ],
              [
                -73.96286117999982,
                40.574980140999905
              ],
              [
                -73.96471128899991,
                40.57478261799983
              ],
              [
                -73.96675354099992,
                40.574832917999906
              ],
              [
                -73.96756707299996,
                40.574881526999896
              ],
              [
                -73.96765743500002,
                40.575043681999915
              ],
              [
                -73.96780353599996,
                40.575020453999855
              ],
              [
                -73.96795533399992,
                40.57501255399995
              ],
              [
                -73.96810857699987,
                40.57502089899985
              ],
              [
                -73.9682587889998,
                40.57504548899988
              ],
              [
                -73.96840165999983,
                40.57508536799986
              ],
              [
                -73.96853345900001,
                40.57513875999993
              ],
              [
                -73.96883249999995,
                40.57524150099993
              ],
              [
                -73.9688899589999,
                40.57526123899986
              ],
              [
                -73.96883335399993,
                40.57533585899988
              ],
              [
                -73.96880187199986,
                40.57537736399993
              ],
              [
                -73.96873173200001,
                40.5755014099999
              ],
              [
                -73.96868119599986,
                40.57563088299987
              ],
              [
                -73.96866614199996,
                40.575696967999924
              ],
              [
                -73.96865108899983,
                40.57576305399993
              ],
              [
                -73.96854635499984,
                40.57624267099993
              ],
              [
                -73.96853234099981,
                40.57630503099995
              ],
              [
                -73.96852475500002,
                40.57634038099996
              ],
              [
                -73.96834802499998,
                40.577113222999856
              ],
              [
                -73.96804495099995,
                40.57843853299986
              ],
              [
                -73.96761555699982,
                40.58030516299993
              ],
              [
                -73.96759867799989,
                40.580378469999914
              ],
              [
                -73.9675871379998,
                40.580429058999925
              ],
              [
                -73.96733403999997,
                40.58151752199991
              ],
              [
                -73.96708956100004,
                40.58259579399988
              ],
              [
                -73.96707277600002,
                40.58266981199988
              ],
              [
                -73.96701115999986,
                40.58294762399992
              ],
              [
                -73.96691975199984,
                40.58335247299991
              ],
              [
                -73.96685766099992,
                40.58358661799986
              ],
              [
                -73.96683458399988,
                40.58367686199994
              ],
              [
                -73.966813755,
                40.58375771499986
              ],
              [
                -73.96677678399986,
                40.58391498999987
              ],
              [
                -73.96676128900002,
                40.58398439199989
              ],
              [
                -73.9667466419999,
                40.584055351999886
              ],
              [
                -73.96673011799997,
                40.5841359729999
              ],
              [
                -73.96670866299985,
                40.584239301999965
              ],
              [
                -73.96661531499983,
                40.58457103699992
              ],
              [
                -73.96670868800001,
                40.58456908299991
              ],
              [
                -73.96684429799988,
                40.584569328999876
              ],
              [
                -73.96781584999998,
                40.58453905099991
              ],
              [
                -73.96899979299997,
                40.584063502999896
              ],
              [
                -73.9698544559998,
                40.58396698199992
              ],
              [
                -73.970055463,
                40.583947729999856
              ],
              [
                -73.97045373499985,
                40.583892278999876
              ],
              [
                -73.970846422,
                40.583820214999946
              ],
              [
                -73.97174499599997,
                40.58366274899992
              ],
              [
                -73.97308506499995,
                40.5834298259999
              ],
              [
                -73.97449712999993,
                40.5831533899999
              ],
              [
                -73.97461091899993,
                40.58312987599992
              ],
              [
                -73.97468194399983,
                40.58312411799989
              ],
              [
                -73.97742525299988,
                40.58290659299994
              ],
              [
                -73.98006567999988,
                40.58331786399986
              ],
              [
                -73.98044790899985,
                40.58341064299993
              ],
              [
                -73.98175452099981,
                40.58358376399985
              ],
              [
                -73.98281157699994,
                40.58379238799989
              ],
              [
                -73.983875933,
                40.58397574499992
              ],
              [
                -73.98482192899988,
                40.58416852299991
              ],
              [
                -73.98492730999999,
                40.58459595299991
              ],
              [
                -73.98548157999991,
                40.584519676999925
              ],
              [
                -73.9858605929999,
                40.58452571299991
              ],
              [
                -73.98627573699999,
                40.58447998099991
              ],
              [
                -73.98671582299981,
                40.58456849999991
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000180907844436,
        "objectid": 56,
        "shape_leng": 0.0568478126677,
        "location_id": 56,
        "zone": "Corona",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.85360568399982,
                40.74749682799991
              ],
              [
                -73.8537828699998,
                40.74780299099994
              ],
              [
                -73.85382450799997,
                40.74785403999988
              ],
              [
                -73.85405453299994,
                40.748158316999906
              ],
              [
                -73.85436928099993,
                40.74873892999988
              ],
              [
                -73.85442575299999,
                40.748851725999884
              ],
              [
                -73.85734765199993,
                40.747971434999904
              ],
              [
                -73.86027336099988,
                40.74710172799993
              ],
              [
                -73.86032027699984,
                40.747195698999896
              ],
              [
                -73.86253100999993,
                40.746533801999895
              ],
              [
                -73.86407236699985,
                40.7460736519999
              ],
              [
                -73.8660885059999,
                40.745473952999944
              ],
              [
                -73.86790963899986,
                40.7449337159999
              ],
              [
                -73.86787595099983,
                40.7448153059999
              ],
              [
                -73.86785097499994,
                40.74471654499991
              ],
              [
                -73.8678269109999,
                40.74462945699988
              ],
              [
                -73.86768504899995,
                40.744071717999944
              ],
              [
                -73.86749135099993,
                40.74330204699994
              ],
              [
                -73.8674716919999,
                40.74209572299991
              ],
              [
                -73.8675572959999,
                40.741677696999886
              ],
              [
                -73.86756253399983,
                40.740941888999956
              ],
              [
                -73.86731779499995,
                40.74026134499993
              ],
              [
                -73.867146698,
                40.73983722399993
              ],
              [
                -73.86703973799995,
                40.73957218999992
              ],
              [
                -73.86676012199987,
                40.73889036999985
              ],
              [
                -73.8664801159999,
                40.73821096399996
              ],
              [
                -73.86609463699996,
                40.73725142999992
              ],
              [
                -73.86582955999995,
                40.736643223999906
              ],
              [
                -73.86550061599992,
                40.73598933199985
              ],
              [
                -73.86515865399998,
                40.73532501399992
              ],
              [
                -73.86481505400002,
                40.73466021599994
              ],
              [
                -73.86462193299995,
                40.73428275699993
              ],
              [
                -73.862840003,
                40.73479949499993
              ],
              [
                -73.86106394499986,
                40.73531449099991
              ],
              [
                -73.85942834899983,
                40.73578873199989
              ],
              [
                -73.85886555799988,
                40.735939138999875
              ],
              [
                -73.85877141199995,
                40.73578528999992
              ],
              [
                -73.85872465799989,
                40.73570864399988
              ],
              [
                -73.85862016399996,
                40.735739906999925
              ],
              [
                -73.85801767699988,
                40.73590708399996
              ],
              [
                -73.8566154109999,
                40.73633083699988
              ],
              [
                -73.85628567699989,
                40.73642604299987
              ],
              [
                -73.85567427699985,
                40.73658665799997
              ],
              [
                -73.85505083399991,
                40.736720220999885
              ],
              [
                -73.85415418099988,
                40.736877812999886
              ],
              [
                -73.85340136499995,
                40.73700275799993
              ],
              [
                -73.85244443499992,
                40.737202311999916
              ],
              [
                -73.85187067499986,
                40.73734292899995
              ],
              [
                -73.85130352699998,
                40.73751172799992
              ],
              [
                -73.8508760629999,
                40.737653859999924
              ],
              [
                -73.8507921649999,
                40.73768175599992
              ],
              [
                -73.84995440299978,
                40.737960303999884
              ],
              [
                -73.84956453799992,
                40.738106091999896
              ],
              [
                -73.84859447999987,
                40.738501170999896
              ],
              [
                -73.84801295299997,
                40.73876334399993
              ],
              [
                -73.84750820299992,
                40.73900780699996
              ],
              [
                -73.8475557979999,
                40.7390639149999
              ],
              [
                -73.84767033700003,
                40.7391952349999
              ],
              [
                -73.84775740199994,
                40.739299088999864
              ],
              [
                -73.84777844199982,
                40.7393448349999
              ],
              [
                -73.84823627999982,
                40.739290400999955
              ],
              [
                -73.84794174099986,
                40.73981640499991
              ],
              [
                -73.84780164699998,
                40.74004967699989
              ],
              [
                -73.84795885999989,
                40.74009701599994
              ],
              [
                -73.84892344899993,
                40.74039191499986
              ],
              [
                -73.8493530509999,
                40.74052324999996
              ],
              [
                -73.84965207399982,
                40.74061466399994
              ],
              [
                -73.84984431299986,
                40.74066554999991
              ],
              [
                -73.8499642599999,
                40.74070177199998
              ],
              [
                -73.85011556599989,
                40.74072356299996
              ],
              [
                -73.85020444599992,
                40.740902033999895
              ],
              [
                -73.85051160899988,
                40.74151885699997
              ],
              [
                -73.850869175,
                40.742180411999875
              ],
              [
                -73.85120929199985,
                40.74284402099991
              ],
              [
                -73.85135102799988,
                40.743119056999895
              ],
              [
                -73.85155107299987,
                40.74350722699996
              ],
              [
                -73.85189537499991,
                40.74417973899995
              ],
              [
                -73.85224097499999,
                40.744840965999856
              ],
              [
                -73.85257733899984,
                40.74550564699991
              ],
              [
                -73.8529235349999,
                40.74618194799996
              ],
              [
                -73.853254932,
                40.74682354999988
              ],
              [
                -73.85360568399982,
                40.74749682799991
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0000180259807917,
        "objectid": 57,
        "shape_leng": 0.0192705048557,
        "location_id": 56,
        "zone": "Corona",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.8513110499998,
                40.749843375999895
              ],
              [
                -73.85086439699991,
                40.749995641999874
              ],
              [
                -73.85098691399993,
                40.750239297999904
              ],
              [
                -73.85118150899993,
                40.75062629599987
              ],
              [
                -73.85151883499987,
                40.751297136999916
              ],
              [
                -73.85171508699987,
                40.751687413999875
              ],
              [
                -73.85185614699996,
                40.75196793299993
              ],
              [
                -73.85220450599996,
                40.752660681999934
              ],
              [
                -73.85246329699996,
                40.75312740899989
              ],
              [
                -73.8526172539999,
                40.75340506499992
              ],
              [
                -73.85300762299991,
                40.75455456499989
              ],
              [
                -73.85302119999993,
                40.75461800199988
              ],
              [
                -73.85312635499994,
                40.7551093429999
              ],
              [
                -73.85323754899999,
                40.75509560099989
              ],
              [
                -73.85383081399982,
                40.75495833699992
              ],
              [
                -73.85478630699988,
                40.75470744899992
              ],
              [
                -73.85579909099987,
                40.75440952799994
              ],
              [
                -73.85538540499991,
                40.753571059999935
              ],
              [
                -73.85497762399996,
                40.75278221699993
              ],
              [
                -73.85455552299993,
                40.75196191899991
              ],
              [
                -73.855834956,
                40.7515772719999
              ],
              [
                -73.85547890700003,
                40.750891233999866
              ],
              [
                -73.85513928899988,
                40.75022639499992
              ],
              [
                -73.85479286699997,
                40.74956323099989
              ],
              [
                -73.8544701179998,
                40.748939347999936
              ],
              [
                -73.85442575299999,
                40.748851725999884
              ],
              [
                -73.8513110499998,
                40.749843375999895
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000204980931361,
        "objectid": 58,
        "shape_leng": 0.0598554094851,
        "location_id": 58,
        "zone": "Country Club",
        "borough": "Bronx"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.8156138206772,
                40.848189557582444
              ],
              [
                -73.81550118820577,
                40.848311195490474
              ],
              [
                -73.81548477457868,
                40.848445168728446
              ],
              [
                -73.81541233179877,
                40.8485364159901
              ],
              [
                -73.81527578737355,
                40.848603196910375
              ],
              [
                -73.81526754603259,
                40.84868236537186
              ],
              [
                -73.81526739378965,
                40.848737184126605
              ],
              [
                -73.81530729613678,
                40.84881033800621
              ],
              [
                -73.81530585173346,
                40.84881790341616
              ],
              [
                -73.81529101730443,
                40.84889558409481
              ],
              [
                -73.8152265266922,
                40.84901120779714
              ],
              [
                -73.81517828216496,
                40.84905376734075
              ],
              [
                -73.81507165904237,
                40.84913361979451
              ],
              [
                -73.81630119199998,
                40.84857217199994
              ],
              [
                -73.81726322699988,
                40.84842656699996
              ],
              [
                -73.81820226899997,
                40.848311031999906
              ],
              [
                -73.81915009199982,
                40.84817017999989
              ],
              [
                -73.82014043700003,
                40.848020598999895
              ],
              [
                -73.82048405599983,
                40.848501025999965
              ],
              [
                -73.82110355699993,
                40.84819897999993
              ],
              [
                -73.82203826799994,
                40.847690368999906
              ],
              [
                -73.82301297099994,
                40.847161737999905
              ],
              [
                -73.82395955500006,
                40.8466412419999
              ],
              [
                -73.82443760499989,
                40.84637787399989
              ],
              [
                -73.82495697699986,
                40.846105444999864
              ],
              [
                -73.82508489299991,
                40.84469769799991
              ],
              [
                -73.82507950099988,
                40.84430400299992
              ],
              [
                -73.82513836199983,
                40.84334359799992
              ],
              [
                -73.82517998999998,
                40.842700058999895
              ],
              [
                -73.82558155599993,
                40.842818658999946
              ],
              [
                -73.82567803799994,
                40.8428471519999
              ],
              [
                -73.82580705599993,
                40.84288069299988
              ],
              [
                -73.82624132999996,
                40.84299359399991
              ],
              [
                -73.82608382099987,
                40.841371456999894
              ],
              [
                -73.82602634299997,
                40.84102446399987
              ],
              [
                -73.825986869,
                40.84067575199989
              ],
              [
                -73.82596550399984,
                40.840326137999924
              ],
              [
                -73.82596226799994,
                40.83997641699988
              ],
              [
                -73.82590798500001,
                40.838744817999896
              ],
              [
                -73.82588663799989,
                40.83812148899992
              ],
              [
                -73.8258498599999,
                40.83706086699989
              ],
              [
                -73.82580066799997,
                40.83685202199997
              ],
              [
                -73.82573525099993,
                40.83664539499995
              ],
              [
                -73.82565389199993,
                40.836442047999874
              ],
              [
                -73.82555705999988,
                40.836243043999914
              ],
              [
                -73.82549108799996,
                40.83610415299994
              ],
              [
                -73.82544296399993,
                40.83595917099997
              ],
              [
                -73.82541397499978,
                40.83581014899989
              ],
              [
                -73.82540482599977,
                40.835659274999955
              ],
              [
                -73.8254156899999,
                40.83550885499988
              ],
              [
                -73.82544607399976,
                40.83536112899986
              ],
              [
                -73.8254949649998,
                40.83521822399992
              ],
              [
                -73.82560350399983,
                40.83503014699991
              ],
              [
                -73.82573143499985,
                40.83484818899991
              ],
              [
                -73.8258777559998,
                40.83467404699993
              ],
              [
                -73.82604118599988,
                40.83450929499993
              ],
              [
                -73.82622087800003,
                40.834354707999864
              ],
              [
                -73.82612415599982,
                40.83421416499987
              ],
              [
                -73.82601252600003,
                40.83402077799985
              ],
              [
                -73.82515883900005,
                40.83461447199995
              ],
              [
                -73.82489104199995,
                40.8342277519999
              ],
              [
                -73.82463346500003,
                40.833855807999925
              ],
              [
                -73.82379383199985,
                40.83430014699991
              ],
              [
                -73.82368336699984,
                40.83431888899992
              ],
              [
                -73.8232749899999,
                40.8343881879999
              ],
              [
                -73.82319485499993,
                40.83440178799989
              ],
              [
                -73.82309210499987,
                40.834421378999934
              ],
              [
                -73.82265928699987,
                40.8344698719999
              ],
              [
                -73.822580515,
                40.8344787019999
              ],
              [
                -73.82223130099996,
                40.8345560129999
              ],
              [
                -73.82150930799995,
                40.83473357299988
              ],
              [
                -73.82062699699992,
                40.83492251499994
              ],
              [
                -73.81977578099998,
                40.83513283999992
              ],
              [
                -73.81890004099992,
                40.83534343599992
              ],
              [
                -73.81804583499988,
                40.8355423629999
              ],
              [
                -73.8172043459999,
                40.83574058099987
              ],
              [
                -73.81606068217324,
                40.836030196228414
              ],
              [
                -73.81609280117425,
                40.83611886413311
              ],
              [
                -73.81614023718535,
                40.836217765478125
              ],
              [
                -73.81618326520262,
                40.83634301401465
              ],
              [
                -73.81620466896722,
                40.83644516702766
              ],
              [
                -73.81620014053696,
                40.836514338093224
              ],
              [
                -73.81616966141667,
                40.83655711423162
              ],
              [
                -73.81616090485221,
                40.836586747032165
              ],
              [
                -73.81619549444878,
                40.83662633267886
              ],
              [
                -73.81623447512567,
                40.83664616063875
              ],
              [
                -73.8162993988223,
                40.83669567616342
              ],
              [
                -73.8163425982448,
                40.83675833484838
              ],
              [
                -73.81636407644693,
                40.836834136387075
              ],
              [
                -73.81638980002965,
                40.83694288512829
              ],
              [
                -73.81639818381184,
                40.83704831256697
              ],
              [
                -73.81641525209483,
                40.837150459128736
              ],
              [
                -73.81642365412327,
                40.837249298199886
              ],
              [
                -73.81642765539806,
                40.83737118912982
              ],
              [
                -73.81644033122897,
                40.83749309503561
              ],
              [
                -73.81644869632308,
                40.83760511031885
              ],
              [
                -73.8164789029114,
                40.837661159370924
              ],
              [
                -73.81647878477862,
                40.837703983888524
              ],
              [
                -73.81645257929428,
                40.83776982592475
              ],
              [
                -73.81640906870993,
                40.83781916909941
              ],
              [
                -73.81635253674234,
                40.83787178705994
              ],
              [
                -73.81632225026149,
                40.83789469232285
              ],
              [
                -73.81630907303996,
                40.8379046587926
              ],
              [
                -73.81628730390062,
                40.83793427254661
              ],
              [
                -73.81628721293391,
                40.837967214520134
              ],
              [
                -73.81630880926807,
                40.838000190365356
              ],
              [
                -73.81634147251413,
                40.83803170124039
              ],
              [
                -73.81633894250302,
                40.83808259306101
              ],
              [
                -73.81629965234724,
                40.838174768285
              ],
              [
                -73.81626470640903,
                40.838263655717135
              ],
              [
                -73.81619078912547,
                40.83832942150597
              ],
              [
                -73.81612981830266,
                40.838418267418014
              ],
              [
                -73.81606022905474,
                40.838487333713715
              ],
              [
                -73.81596456850167,
                40.8385728305169
              ],
              [
                -73.8158863476013,
                40.838625412462164
              ],
              [
                -73.81584721151334,
                40.838661586293284
              ],
              [
                -73.81579944386067,
                40.83868127468255
              ],
              [
                -73.81573871986129,
                40.83868117768852
              ],
              [
                -73.81566927530216,
                40.83869753887237
              ],
              [
                -73.81559976613778,
                40.83873695709624
              ],
              [
                -73.81554757935396,
                40.83878628623576
              ],
              [
                -73.81552154585934,
                40.83878953879445
              ],
              [
                -73.81548685525908,
                40.83878618910912
              ],
              [
                -73.8154782077358,
                40.838776292690945
              ],
              [
                -73.8154769186815,
                40.838776128130306
              ],
              [
                -73.8154521927371,
                40.83877295737675
              ],
              [
                -73.81542613964203,
                40.83878279776939
              ],
              [
                -73.81540002409541,
                40.838815698591674
              ],
              [
                -73.81538843489142,
                40.838852315394114
              ],
              [
                -73.81478277740266,
                40.83907648795766
              ],
              [
                -73.81469650157487,
                40.8394510589777
              ],
              [
                -73.81471999083577,
                40.83957187959459
              ],
              [
                -73.81446962061504,
                40.83960599799444
              ],
              [
                -73.81447097519408,
                40.839612253681175
              ],
              [
                -73.81438722825912,
                40.83962254229256
              ],
              [
                -73.81438450995489,
                40.839613156567296
              ],
              [
                -73.81430900133682,
                40.839622416379285
              ],
              [
                -73.81431578800081,
                40.83964952650949
              ],
              [
                -73.81438717009922,
                40.83964338772789
              ],
              [
                -73.81438444888595,
                40.839635044332994
              ],
              [
                -73.81447095193839,
                40.83962059265944
              ],
              [
                -73.81447505522941,
                40.83962580976234
              ],
              [
                -73.81472319684482,
                40.839588886600026
              ],
              [
                -73.81472761705194,
                40.83961266621054
              ],
              [
                -73.8147302657413,
                40.83964706668479
              ],
              [
                -73.81473018446566,
                40.839676251098346
              ],
              [
                -73.81471914179329,
                40.839699164396485
              ],
              [
                -73.81470537675676,
                40.839713733132
              ],
              [
                -73.81469983843601,
                40.83973144359535
              ],
              [
                -73.81469567415562,
                40.839748113763505
              ],
              [
                -73.81469838960713,
                40.839758541476456
              ],
              [
                -73.81470935814201,
                40.83976272776755
              ],
              [
                -73.8147203367438,
                40.839762745403654
              ],
              [
                -73.81473269673526,
                40.8397596390901
              ],
              [
                -73.81474506385649,
                40.83975444727889
              ],
              [
                -73.81475878782422,
                40.839754469320766
              ],
              [
                -73.81476701970094,
                40.839755524373636
              ],
              [
                -73.81477935155439,
                40.839762840187554
              ],
              [
                -73.8147889293362,
                40.83977327891801
              ],
              [
                -73.81479697880752,
                40.83978795740684
              ],
              [
                -73.81436188587786,
                40.83985180818457
              ],
              [
                -73.81436596502486,
                40.83986536376494
              ],
              [
                -73.81480646696015,
                40.839804367389014
              ],
              [
                -73.81497336213258,
                40.84007688469778
              ],
              [
                -73.81500569765576,
                40.84029373486581
              ],
              [
                -73.81504912628199,
                40.84051761955123
              ],
              [
                -73.81497347937896,
                40.840528201509166
              ],
              [
                -73.8149707522553,
                40.840521943627536
              ],
              [
                -73.81490758787837,
                40.84053330696266
              ],
              [
                -73.8149089431792,
                40.84053956264441
              ],
              [
                -73.81490069938222,
                40.840542676755014
              ],
              [
                -73.81490477086118,
                40.84055936014459
              ],
              [
                -73.81491163259714,
                40.840559371155706
              ],
              [
                -73.8149157299578,
                40.84056667373987
              ],
              [
                -73.81498025770709,
                40.84055843942445
              ],
              [
                -73.81497890551917,
                40.84055114124613
              ],
              [
                -73.81505399109437,
                40.840538085896554
              ],
              [
                -73.8150663842936,
                40.840590686889264
              ],
              [
                -73.81506678156754,
                40.84061885703701
              ],
              [
                -73.81507398877265,
                40.84065917086743
              ],
              [
                -73.81509033516018,
                40.8407036696935
              ],
              [
                -73.81509998662794,
                40.84072432982889
              ],
              [
                -73.81510852634034,
                40.84074261067954
              ],
              [
                -73.81512854729566,
                40.840781556270414
              ],
              [
                -73.81514858030347,
                40.84081633153237
              ],
              [
                -73.8151831980253,
                40.84087058573759
              ],
              [
                -73.81520503680377,
                40.84091370289619
              ],
              [
                -73.81522135566156,
                40.84096792894298
              ],
              [
                -73.81523404887245,
                40.84100964149654
              ],
              [
                -73.81524671254658,
                40.84106247173637
              ],
              [
                -73.81523378640593,
                40.8411041427476
              ],
              [
                -73.81522089631062,
                40.84113330713769
              ],
              [
                -73.81521713938884,
                40.841168044271726
              ],
              [
                -73.81522026179319,
                40.84120267649966
              ],
              [
                -73.81473345817533,
                40.8413757283108
              ],
              [
                -73.81476263162953,
                40.8414132987143
              ],
              [
                -73.81480293388552,
                40.84139807535181
              ],
              [
                -73.8147974828056,
                40.841384170142895
              ],
              [
                -73.8152321123264,
                40.84123377337665
              ],
              [
                -73.81526263397656,
                40.84125983942298
              ],
              [
                -73.81528083654403,
                40.84129461172708
              ],
              [
                -73.81528985873143,
                40.841340487564366
              ],
              [
                -73.81529153823838,
                40.841394690152946
              ],
              [
                -73.81530238614381,
                40.84144195885927
              ],
              [
                -73.81532969699884,
                40.841492032142156
              ],
              [
                -73.81537535736693,
                40.841524069665674
              ],
              [
                -73.81542649444837,
                40.841560284603574
              ],
              [
                -73.81547217396636,
                40.84158537238329
              ],
              [
                -73.81552506814646,
                40.84164799590016
              ],
              [
                -73.81556869858584,
                40.84175229562151
              ],
              [
                -73.81558492163327,
                40.84184126460356
              ],
              [
                -73.81559206830936,
                40.84190381376301
              ],
              [
                -73.81557540624675,
                40.841973273921255
              ],
              [
                -73.81555879401647,
                40.84202466853028
              ],
              [
                -73.81556419521851,
                40.842056640419955
              ],
              [
                -73.81556406036422,
                40.842105280978515
              ],
              [
                -73.81554743972904,
                40.842159453120026
              ],
              [
                -73.81552354975133,
                40.84219554851017
              ],
              [
                -73.8155051742541,
                40.84222331387149
              ],
              [
                -73.81546968074403,
                40.84225954263418
              ],
              [
                -73.81546847090182,
                40.84226077803131
              ],
              [
                -73.81543361658642,
                40.84229129674325
              ],
              [
                -73.81540790011591,
                40.84232599873667
              ],
              [
                -73.81540599274848,
                40.84235379026757
              ],
              [
                -73.81541503896409,
                40.8423913277834
              ],
              [
                -73.81544243533584,
                40.84241082719334
              ],
              [
                -73.81550698344903,
                40.84242060831806
              ],
              [
                -73.8156179809096,
                40.84245835926558
              ],
              [
                -73.81570207846407,
                40.84251972168958
              ],
              [
                -73.81516936817916,
                40.84255492332517
              ],
              [
                -73.81517113664873,
                40.84257716162385
              ],
              [
                -73.81572300131998,
                40.84253656598691
              ],
              [
                -73.815867045438,
                40.84278860976364
              ],
              [
                -73.81587589832016,
                40.84282820931972
              ],
              [
                -73.81590994535827,
                40.84286902912584
              ],
              [
                -73.8159387775396,
                40.842891933120235
              ],
              [
                -73.81596837571966,
                40.84291544711158
              ],
              [
                -73.81602683823571,
                40.84295074621414
              ],
              [
                -73.81610484559108,
                40.842976813710486
              ],
              [
                -73.81615112347605,
                40.84300653420625
              ],
              [
                -73.8162021785278,
                40.84307332313071
              ],
              [
                -73.81623130431188,
                40.843128958695296
              ],
              [
                -73.81627260471049,
                40.843193878224916
              ],
              [
                -73.81630900985745,
                40.84326435023261
              ],
              [
                -73.81635030529999,
                40.8433311228753
              ],
              [
                -73.81636235714114,
                40.84338487806814
              ],
              [
                -73.81635489948351,
                40.84343489740633
              ],
              [
                -73.81635233182159,
                40.843481218060745
              ],
              [
                -73.81635809445422,
                40.84349591640998
              ],
              [
                -73.81636686950458,
                40.843518300865114
              ],
              [
                -73.81639116165071,
                40.84355725134012
              ],
              [
                -73.81642277916197,
                40.84359436132307
              ],
              [
                -73.816437307343,
                40.843635150571146
              ],
              [
                -73.8164445195421,
                40.843674073852036
              ],
              [
                -73.81648830094261,
                40.84372417472247
              ],
              [
                -73.81651503368255,
                40.843763129555185
              ],
              [
                -73.81655885162657,
                40.84380025890587
              ],
              [
                -73.81657668436155,
                40.84381076823534
              ],
              [
                -73.81661244498649,
                40.84383184466544
              ],
              [
                -73.81661236142155,
                40.84386215716094
              ],
              [
                -73.81661234792885,
                40.84386705152038
              ],
              [
                -73.81660732931886,
                40.84391707357562
              ],
              [
                -73.81662677325095,
                40.84394489856023
              ],
              [
                -73.81665600190942,
                40.84396347568799
              ],
              [
                -73.81667877696744,
                40.843971021986526
              ],
              [
                -73.81669068165954,
                40.843974965825225
              ],
              [
                -73.81711169357992,
                40.84391242832391
              ],
              [
                -73.81713055270801,
                40.84390960408764
              ],
              [
                -73.81714976960329,
                40.84390925737281
              ],
              [
                -73.81716878406533,
                40.84391139828629
              ],
              [
                -73.81718704179603,
                40.84391596441751
              ],
              [
                -73.81723673532649,
                40.843954896219806
              ],
              [
                -73.81728029492882,
                40.8439978562816
              ],
              [
                -73.81731715864584,
                40.844044290392766
              ],
              [
                -73.81733703579171,
                40.84408082946184
              ],
              [
                -73.81736232764281,
                40.844115384571715
              ],
              [
                -73.81739269690169,
                40.844147494877205
              ],
              [
                -73.81742868454596,
                40.84417743296862
              ],
              [
                -73.81746907981335,
                40.84420393077191
              ],
              [
                -73.81751331632168,
                40.844226616758284
              ],
              [
                -73.8175607738279,
                40.844245172844296
              ],
              [
                -73.81759643820345,
                40.84425411569278
              ],
              [
                -73.81763281314862,
                40.844261218793584
              ],
              [
                -73.8176899451903,
                40.84426849557082
              ],
              [
                -73.81774776234208,
                40.84427124056504
              ],
              [
                -73.81780564298111,
                40.84426942426333
              ],
              [
                -73.81786296480249,
                40.84426306619358
              ],
              [
                -73.81792732610218,
                40.84425024734845
              ],
              [
                -73.81797529226337,
                40.84422200999296
              ],
              [
                -73.81801695603708,
                40.84418849707869
              ],
              [
                -73.81805132226046,
                40.8441505090891
              ],
              [
                -73.81806674880681,
                40.84413840430329
              ],
              [
                -73.81808444164687,
                40.84412824361817
              ],
              [
                -73.81810398044887,
                40.84412026842221
              ],
              [
                -73.81812490102688,
                40.844114668182925
              ],
              [
                -73.81814670636902,
                40.84411157594584
              ],
              [
                -73.81816887844329,
                40.844111065173344
              ],
              [
                -73.818190890506,
                40.844113147999956
              ],
              [
                -73.81819826067786,
                40.844133884922925
              ],
              [
                -73.81820127234383,
                40.844155241780335
              ],
              [
                -73.81819985147386,
                40.84417669362955
              ],
              [
                -73.81819403298859,
                40.84419771319239
              ],
              [
                -73.81818794999266,
                40.84420696054203
              ],
              [
                -73.81818395309016,
                40.844216841843895
              ],
              [
                -73.81818215119185,
                40.844227087848004
              ],
              [
                -73.81818259339703,
                40.844237419367
              ],
              [
                -73.81818526765763,
                40.84424755488286
              ],
              [
                -73.8181901011048,
                40.84425721821861
              ],
              [
                -73.81819696203605,
                40.844266146063305
              ],
              [
                -73.81820566350187,
                40.84427409514651
              ],
              [
                -73.81821596840095,
                40.844280848867804
              ],
              [
                -73.81822615561835,
                40.84429151223402
              ],
              [
                -73.81823389266115,
                40.84430330352895
              ],
              [
                -73.81823896847384,
                40.8443159011047
              ],
              [
                -73.81824124459442,
                40.84432896131916
              ],
              [
                -73.81824065893247,
                40.84434212790992
              ],
              [
                -73.81823722746202,
                40.84435504171264
              ],
              [
                -73.81783277820627,
                40.84437074682535
              ],
              [
                -73.81750072974424,
                40.844367444910375
              ],
              [
                -73.81741119253728,
                40.8443342889862
              ],
              [
                -73.81731841759297,
                40.84430674088586
              ],
              [
                -73.81722301274397,
                40.84428498109465
              ],
              [
                -73.81718636615088,
                40.84428408789741
              ],
              [
                -73.81715021150434,
                40.844279456285854
              ],
              [
                -73.81711520705454,
                40.8442711705859
              ],
              [
                -73.81710807906538,
                40.8442707969741
              ],
              [
                -73.81710112979529,
                40.84426953582733
              ],
              [
                -73.81709454952792,
                40.84426742167806
              ],
              [
                -73.8170885184427,
                40.84426451241532
              ],
              [
                -73.81708320168126,
                40.84426088770006
              ],
              [
                -73.81707874482646,
                40.84425664678347
              ],
              [
                -73.81707526991408,
                40.84425190578929
              ],
              [
                -73.81707287209356,
                40.8442467945344
              ],
              [
                -73.81707161702109,
                40.84424145297411
              ],
              [
                -73.81707153906245,
                40.84423602736941
              ],
              [
                -73.81707264035197,
                40.844230666283316
              ],
              [
                -73.81707489073452,
                40.844225516511436
              ],
              [
                -73.81707822858992,
                40.84422071906346
              ],
              [
                -73.81708835296621,
                40.84420468951921
              ],
              [
                -73.81709507751613,
                40.844187660670585
              ],
              [
                -73.81709823916935,
                40.84417004547454
              ],
              [
                -73.81708912170254,
                40.84415158871505
              ],
              [
                -73.81707609416168,
                40.84413453831602
              ],
              [
                -73.81705951844758,
                40.84411936792764
              ],
              [
                -73.81703985502557,
                40.84410649897402
              ],
              [
                -73.81701765013457,
                40.844096288946766
              ],
              [
                -73.8169935206138,
                40.8440890214737
              ],
              [
                -73.81696813676572,
                40.84408489844079
              ],
              [
                -73.8169422037372,
                40.84408403438296
              ],
              [
                -73.81691644193094,
                40.84408645330338
              ],
              [
                -73.81689156699254,
                40.84409208800588
              ],
              [
                -73.81686826993231,
                40.8441007819621
              ],
              [
                -73.81684719792796,
                40.84411229365962
              ],
              [
                -73.8168289363478,
                40.84412630331069
              ],
              [
                -73.81680085141551,
                40.84413453675419
              ],
              [
                -73.81677136427729,
                40.8441391447604
              ],
              [
                -73.81674128042832,
                40.844140001453525
              ],
              [
                -73.81671142166353,
                40.84413708343122
              ],
              [
                -73.8166826036293,
                40.84413047040477
              ],
              [
                -73.81665561354282,
                40.84412034302082
              ],
              [
                -73.8166311886855,
                40.844106977927275
              ],
              [
                -73.81660999626493,
                40.84409074021556
              ],
              [
                -73.8165978903427,
                40.84409207815316
              ],
              [
                -73.81658617249595,
                40.84409474684975
              ],
              [
                -73.81657509495034,
                40.844098688861834
              ],
              [
                -73.81656489614977,
                40.84410381933774
              ],
              [
                -73.81655579562262,
                40.84411002784446
              ],
              [
                -73.8165480357962,
                40.84411713039859
              ],
              [
                -73.81654171788186,
                40.84412501409629
              ],
              [
                -73.81653796627933,
                40.84412709597242
              ],
              [
                -73.8165338374184,
                40.844128714517204
              ],
              [
                -73.8165294297045,
                40.844129831154724
              ],
              [
                -73.81652484819097,
                40.84413041927109
              ],
              [
                -73.81652020207204,
                40.8441304648495
              ],
              [
                -73.81651560208203,
                40.84412996680365
              ],
              [
                -73.81651115785635,
                40.84412893700376
              ],
              [
                -73.8165069753176,
                40.84412739999382
              ],
              [
                -73.81650287268272,
                40.844125216557565
              ],
              [
                -73.8164993037987,
                40.84412254657507
              ],
              [
                -73.81649636754568,
                40.844119464021105
              ],
              [
                -73.81649414527524,
                40.84411605430112
              ],
              [
                -73.81649269855801,
                40.8441124118852
              ],
              [
                -73.81649206747677,
                40.84410863769031
              ],
              [
                -73.81649226951575,
                40.844104836284345
              ],
              [
                -73.81649329907742,
                40.8441011129896
              ],
              [
                -73.8164941089401,
                40.84408391064677
              ],
              [
                -73.8164911993952,
                40.84406683971258
              ],
              [
                -73.81648464895692,
                40.844050360831815
              ],
              [
                -73.81647463438595,
                40.84403491867298
              ],
              [
                -73.81646142591855,
                40.84402092992899
              ],
              [
                -73.81644537997586,
                40.84400877207358
              ],
              [
                -73.81641400672652,
                40.84399648266724
              ],
              [
                -73.81638078456292,
                40.84398741885601
              ],
              [
                -73.81634628559065,
                40.84398173672405
              ],
              [
                -73.81631110390217,
                40.84397953412081
              ],
              [
                -73.81625900426044,
                40.84398680153721
              ],
              [
                -73.81620880249945,
                40.8439996384538
              ],
              [
                -73.81616152537532,
                40.84401778232297
              ],
              [
                -73.81611813982926,
                40.844040862055756
              ],
              [
                -73.8160795332132,
                40.84406840561157
              ],
              [
                -73.81604649513972,
                40.84409984965285
              ],
              [
                -73.81601970133298,
                40.8441345510654
              ],
              [
                -73.81599969980863,
                40.84417180011269
              ],
              [
                -73.81598496513935,
                40.84419354861376
              ],
              [
                -73.81597501914739,
                40.844216811381706
              ],
              [
                -73.81597011983966,
                40.844240984973766
              ],
              [
                -73.81597039431108,
                40.8442654423198
              ],
              [
                -73.81597583544774,
                40.8442895489882
              ],
              [
                -73.81598630210995,
                40.84431267964334
              ],
              [
                -73.81600028898751,
                40.844332762858855
              ],
              [
                -73.81601806476836,
                40.84435103667713
              ],
              [
                -73.81602007557042,
                40.844359267508544
              ],
              [
                -73.81602019111287,
                40.844367638330915
              ],
              [
                -73.81601840794703,
                40.84437589936285
              ],
              [
                -73.81601477928106,
                40.84438380409934
              ],
              [
                -73.8160094133921,
                40.844391116666415
              ],
              [
                -73.81600247039512,
                40.84439761886071
              ],
              [
                -73.81599415746581,
                40.84440311665946
              ],
              [
                -73.81598472265817,
                40.844407446010834
              ],
              [
                -73.81597444750203,
                40.84441047772891
              ],
              [
                -73.81596363860406,
                40.84441212134858
              ],
              [
                -73.81595261849685,
                40.844412327824735
              ],
              [
                -73.81594171601607,
                40.844411090996395
              ],
              [
                -73.81587930562611,
                40.84440977839468
              ],
              [
                -73.81581729631598,
                40.844404255607856
              ],
              [
                -73.81575617959831,
                40.84439456641176
              ],
              [
                -73.81572663977093,
                40.84439220931233
              ],
              [
                -73.81569792948075,
                40.8443864281129
              ],
              [
                -73.81567073272595,
                40.84437736054546
              ],
              [
                -73.81565687237841,
                40.844371707904166
              ],
              [
                -73.81564400690353,
                40.84436483141949
              ],
              [
                -73.81563232274601,
                40.844356830744815
              ],
              [
                -73.81562226066892,
                40.8443480879866
              ],
              [
                -73.81561361207982,
                40.84433851260517
              ],
              [
                -73.8156064953604,
                40.844328235668634
              ],
              [
                -73.81560039113367,
                40.84431588730953
              ],
              [
                -73.81559649706655,
                40.84430303329218
              ],
              [
                -73.8155948819365,
                40.844289900650196
              ],
              [
                -73.81559557426921,
                40.84427672133808
              ],
              [
                -73.81558475951772,
                40.844252913713
              ],
              [
                -73.81557050239195,
                40.84423017542359
              ],
              [
                -73.8155538550995,
                40.84420973962539
              ],
              [
                -73.81553442761394,
                40.84419077171556
              ],
              [
                -73.81548639348827,
                40.844164962371345
              ],
              [
                -73.81544124858463,
                40.844136302679125
              ],
              [
                -73.81539614036446,
                40.84410241829302
              ],
              [
                -73.81535265351195,
                40.844063313924096
              ],
              [
                -73.81529647350618,
                40.844005526807486
              ],
              [
                -73.81524625798409,
                40.84394465545534
              ],
              [
                -73.81520229918517,
                40.84388105414047
              ],
              [
                -73.81516485293174,
                40.843815093023615
              ],
              [
                -73.81513413714164,
                40.8437471559981
              ],
              [
                -73.81511033055865,
                40.84367763845598
              ],
              [
                -73.8150935717148,
                40.843606944986675
              ],
              [
                -73.81508016493551,
                40.84359379162279
              ],
              [
                -73.81505372586119,
                40.84354864208285
              ],
              [
                -73.81502021835243,
                40.84350628513858
              ],
              [
                -73.81498014487664,
                40.84346735594504
              ],
              [
                -73.81493410635808,
                40.84343243825603
              ],
              [
                -73.81488279316403,
                40.84340205567115
              ],
              [
                -73.81482697475403,
                40.84337666378397
              ],
              [
                -73.81476748814121,
                40.8433566433511
              ],
              [
                -73.81470522534168,
                40.84334229458282
              ],
              [
                -73.81464226888777,
                40.843318448413825
              ],
              [
                -73.81458365727987,
                40.84328889749743
              ],
              [
                -73.8145302823009,
                40.84325409145745
              ],
              [
                -73.81447435420355,
                40.84318544130675
              ],
              [
                -73.81443311693066,
                40.8430982852661
              ],
              [
                -73.81437458252545,
                40.84308892599942
              ],
              [
                -73.81435973064592,
                40.84310064777257
              ],
              [
                -73.81434771587207,
                40.84311411475723
              ],
              [
                -73.81433888727611,
                40.84312893569123
              ],
              [
                -73.81433350136118,
                40.84314467997544
              ],
              [
                -73.8143317146095,
                40.84316089018348
              ],
              [
                -73.81433357893496,
                40.84317709535262
              ],
              [
                -73.81433904017473,
                40.84319282466564
              ],
              [
                -73.81434793966353,
                40.843207621130354
              ],
              [
                -73.81433902344891,
                40.84325447849231
              ],
              [
                -73.8143219248619,
                40.84330000706271
              ],
              [
                -73.81429695190002,
                40.84334338669055
              ],
              [
                -73.81426455441184,
                40.84338383593459
              ],
              [
                -73.8142253159947,
                40.843420626139995
              ],
              [
                -73.81417994348193,
                40.843453094564694
              ],
              [
                -73.81412925421093,
                40.84348065631906
              ],
              [
                -73.81407416129895,
                40.843502814901846
              ],
              [
                -73.81401565719491,
                40.843519171144855
              ],
              [
                -73.81395479579975,
                40.843529430404004
              ],
              [
                -73.81389267348071,
                40.84353340786712
              ],
              [
                -73.81383291767408,
                40.843526339254254
              ],
              [
                -73.8137724611528,
                40.843525162014394
              ],
              [
                -73.81371230684837,
                40.843529895677236
              ],
              [
                -73.81365345267933,
                40.843540461714525
              ],
              [
                -73.813596874996,
                40.84355668484284
              ],
              [
                -73.81354351238565,
                40.84357829593178
              ],
              [
                -73.81348189734598,
                40.843611083960184
              ],
              [
                -73.81344191745198,
                40.843653956779896
              ],
              [
                -73.81340958738963,
                40.843700401288686
              ],
              [
                -73.8133854519607,
                40.8437496348626
              ],
              [
                -73.81336991788451,
                40.84380082787791
              ],
              [
                -73.81336324694246,
                40.84385311769115
              ],
              [
                -73.81336555156658,
                40.84390562317465
              ],
              [
                -73.81337679294293,
                40.84395745956461
              ],
              [
                -73.81339678166464,
                40.844007753369944
              ],
              [
                -73.81341603573895,
                40.84406308117255
              ],
              [
                -73.81342078460055,
                40.84412370037606
              ],
              [
                -73.81343074980109,
                40.844187440444145
              ],
              [
                -73.81345592741259,
                40.844242626879904
              ],
              [
                -73.81349521226797,
                40.84428217748507
              ],
              [
                -73.81353615806097,
                40.84433235060964
              ],
              [
                -73.81353932708109,
                40.84434009802115
              ],
              [
                -73.81355528835343,
                40.8443791048104
              ],
              [
                -73.81358891945233,
                40.844397618968216
              ],
              [
                -73.81365179646245,
                40.84443683825209
              ],
              [
                -73.81372273148042,
                40.844451988439126
              ],
              [
                -73.81381176562526,
                40.844461915921144
              ],
              [
                -73.81383041777484,
                40.844460234433924
              ],
              [
                -73.81388191058579,
                40.844454808335996
              ],
              [
                -73.81393763054011,
                40.84446392067516
              ],
              [
                -73.81399066794313,
                40.84447977264607
              ],
              [
                -73.81403961944139,
                40.84450194481131
              ],
              [
                -73.8140831897909,
                40.84452985050305
              ],
              [
                -73.81412022612626,
                40.84456275134531
              ],
              [
                -73.81414974846555,
                40.844599776790766
              ],
              [
                -73.81417097564206,
                40.844639947154725
              ],
              [
                -73.81418334597302,
                40.84468219953713
              ],
              [
                -73.81418653212441,
                40.8447254159458
              ],
              [
                -73.81403795661885,
                40.844760857781395
              ],
              [
                -73.81396104947149,
                40.84477705546289
              ],
              [
                -73.81395108050033,
                40.844760197864176
              ],
              [
                -73.8138938118485,
                40.84477314504454
              ],
              [
                -73.81389591464291,
                40.844788902559266
              ],
              [
                -73.81369162177985,
                40.84482910503367
              ],
              [
                -73.81369979287803,
                40.844848456863396
              ],
              [
                -73.81373976204523,
                40.844841306152404
              ],
              [
                -73.81374940735262,
                40.844867067648444
              ],
              [
                -73.8138669488304,
                40.84484233215543
              ],
              [
                -73.81386204925668,
                40.84481838155541
              ],
              [
                -73.81411591388144,
                40.84476412450858
              ],
              [
                -73.81418274982286,
                40.844741569047976
              ],
              [
                -73.81416729040971,
                40.84478090602282
              ],
              [
                -73.81414462049185,
                40.84481817326274
              ],
              [
                -73.81411520102003,
                40.84485261298849
              ],
              [
                -73.814079630191,
                40.84488352491332
              ],
              [
                -73.81403863128376,
                40.84491028048288
              ],
              [
                -73.81399303795402,
                40.844932335656196
              ],
              [
                -73.81394377728165,
                40.84494924196862
              ],
              [
                -73.81389185092073,
                40.84496065565071
              ],
              [
                -73.81383957780265,
                40.84496631314284
              ],
              [
                -73.81378890814463,
                40.84497759136735
              ],
              [
                -73.81378135146424,
                40.844993971756715
              ],
              [
                -73.81377048313631,
                40.84500924033168
              ],
              [
                -73.81375657593014,
                40.845023013884074
              ],
              [
                -73.8137399788844,
                40.84503494672788
              ],
              [
                -73.81372110854787,
                40.84504473937449
              ],
              [
                -73.81370043852485,
                40.84505214604949
              ],
              [
                -73.81367848758785,
                40.845056980861045
              ],
              [
                -73.8136558066588,
                40.8450591224657
              ],
              [
                -73.81363296498053,
                40.84505851711379
              ],
              [
                -73.81359095648516,
                40.84504822935522
              ],
              [
                -73.81354761947729,
                40.845041869203634
              ],
              [
                -73.81354668877825,
                40.844972682812774
              ],
              [
                -73.81344958137997,
                40.844960824851476
              ],
              [
                -73.8133517176982,
                40.84495336998055
              ],
              [
                -73.81325344361194,
                40.844950344547854
              ],
              [
                -73.81312081806666,
                40.84493574256625
              ],
              [
                -73.8129874745271,
                40.844925589376054
              ],
              [
                -73.8128536716289,
                40.8449199046705
              ],
              [
                -73.8127196688978,
                40.844918699475926
              ],
              [
                -73.81270327725996,
                40.84492081312166
              ],
              [
                -73.81268758824365,
                40.84492499240301
              ],
              [
                -73.81267304979318,
                40.84493111799549
              ],
              [
                -73.81266007700297,
                40.84493901500451
              ],
              [
                -73.81264904026584,
                40.84494845795871
              ],
              [
                -73.81264025469774,
                40.84495917724791
              ],
              [
                -73.81263397114111,
                40.84497086682043
              ],
              [
                -73.81254909120709,
                40.84495227514607
              ],
              [
                -73.81264095809888,
                40.84468166226034
              ],
              [
                -73.81267984609725,
                40.84469003159224
              ],
              [
                -73.81268023984991,
                40.84469011616124
              ],
              [
                -73.81265744462624,
                40.84475144920646
              ],
              [
                -73.81309338317085,
                40.8448482762971
              ],
              [
                -73.81311795023566,
                40.84478760356964
              ],
              [
                -73.8130647312539,
                40.844772889142
              ],
              [
                -73.81307041826864,
                40.84475069087712
              ],
              [
                -73.8130361971365,
                40.8447432844631
              ],
              [
                -73.81304510205834,
                40.84471754979041
              ],
              [
                -73.8130928806672,
                40.84473104823435
              ],
              [
                -73.81311318152709,
                40.8446839549562
              ],
              [
                -73.8129721379385,
                40.844650185373624
              ],
              [
                -73.81296220789088,
                40.84467253925256
              ],
              [
                -73.8129117569329,
                40.84465804769341
              ],
              [
                -73.81290225785118,
                40.84468144409781
              ],
              [
                -73.81291074426947,
                40.84468373602106
              ],
              [
                -73.81289731370842,
                40.84471323145966
              ],
              [
                -73.81264889336668,
                40.844659467667384
              ],
              [
                -73.81269786567088,
                40.844502898199494
              ],
              [
                -73.81270267989628,
                40.84448750871943
              ],
              [
                -73.81270337280483,
                40.844485312432425
              ],
              [
                -73.81271240439833,
                40.844486782880324
              ],
              [
                -73.8127149339389,
                40.84448719876142
              ],
              [
                -73.8127440865003,
                40.84449191263888
              ],
              [
                -73.81276183239135,
                40.84449480309046
              ],
              [
                -73.81276135037977,
                40.844496606708994
              ],
              [
                -73.81275955824992,
                40.84450334094461
              ],
              [
                -73.81297181218781,
                40.8445474519353
              ],
              [
                -73.81298325218074,
                40.84454982704336
              ],
              [
                -73.81299120304948,
                40.84452250755803
              ],
              [
                -73.81280554651427,
                40.844485864264946
              ],
              [
                -73.81280551188065,
                40.84448586420874
              ],
              [
                -73.81277607099767,
                40.84448005192655
              ],
              [
                -73.81276862682175,
                40.84447858573546
              ],
              [
                -73.81276123529805,
                40.84447330443814
              ],
              [
                -73.81279995233871,
                40.84435487689028
              ],
              [
                -73.81282121937906,
                40.84425961554686
              ],
              [
                -73.81278096800882,
                40.84425543198108
              ],
              [
                -73.81277881686847,
                40.84427513668231
              ],
              [
                -73.81277841251041,
                40.84427733176299
              ],
              [
                -73.81277790078785,
                40.84427958161733
              ],
              [
                -73.8127769894225,
                40.84428235115249
              ],
              [
                -73.81277618716038,
                40.84428468345949
              ],
              [
                -73.81272816252338,
                40.844469123795186
              ],
              [
                -73.8127269832818,
                40.844468938609516
              ],
              [
                -73.81272387652409,
                40.844468412565874
              ],
              [
                -73.81272384166978,
                40.844468412509286
              ],
              [
                -73.81271596620674,
                40.84446710962056
              ],
              [
                -73.81271242301725,
                40.84446653629644
              ],
              [
                -73.81271381182701,
                40.8444618637945
              ],
              [
                -73.81278360787154,
                40.84422949986578
              ],
              [
                -73.81278360795028,
                40.8442294718895
              ],
              [
                -73.81278395303981,
                40.844228348196104
              ],
              [
                -73.81277306108588,
                40.84422651790778
              ],
              [
                -73.81266141349049,
                40.84420764950083
              ],
              [
                -73.8126482067927,
                40.84424569285329
              ],
              [
                -73.81262135209091,
                40.84432313360275
              ],
              [
                -73.81260320565703,
                40.84437544582214
              ],
              [
                -73.81260112783652,
                40.84438139792434
              ],
              [
                -73.81252704775312,
                40.84459492708956
              ],
              [
                -73.81251753679987,
                40.84462241253634
              ],
              [
                -73.81251553354934,
                40.84462820008202
              ],
              [
                -73.81244786509014,
                40.84482326774509
              ],
              [
                -73.81243398195343,
                40.8448632081838
              ],
              [
                -73.81243223218368,
                40.844868282487724
              ],
              [
                -73.81234277106647,
                40.845126183374724
              ],
              [
                -73.81232956124433,
                40.845159027573864
              ],
              [
                -73.81231936981116,
                40.84518431783333
              ],
              [
                -73.81231816792577,
                40.84518730651574
              ],
              [
                -73.81224107628773,
                40.84537890034358
              ],
              [
                -73.81223386948285,
                40.84539678352257
              ],
              [
                -73.81169971823665,
                40.846681700813654
              ],
              [
                -73.81184149221674,
                40.84671412757344
              ],
              [
                -73.81187766058915,
                40.84661655663379
              ],
              [
                -73.81230255202037,
                40.84671279219771
              ],
              [
                -73.81242358654634,
                40.84675536693242
              ],
              [
                -73.8124362506067,
                40.846737712075566
              ],
              [
                -73.81230236497042,
                40.84668940706113
              ],
              [
                -73.8118845884477,
                40.84659496706123
              ],
              [
                -73.81191568266973,
                40.84652738919134
              ],
              [
                -73.8120266503986,
                40.8465509818762
              ],
              [
                -73.81203501629656,
                40.84653260540141
              ],
              [
                -73.81202172362966,
                40.84652942842734
              ],
              [
                -73.81203383713486,
                40.84650169799507
              ],
              [
                -73.81196127339025,
                40.84648442527991
              ],
              [
                -73.81195504551972,
                40.8464758248354
              ],
              [
                -73.81217481496283,
                40.84594604165193
              ],
              [
                -73.81218860345625,
                40.8459521030058
              ],
              [
                -73.81245544053235,
                40.84526432796375
              ],
              [
                -73.81253844344073,
                40.84528373015122
              ],
              [
                -73.81254866464359,
                40.84526069010319
              ],
              [
                -73.8124400843122,
                40.845237074320195
              ],
              [
                -73.81254457815619,
                40.844979598008244
              ],
              [
                -73.8126285322481,
                40.84499655013244
              ],
              [
                -73.81262983160204,
                40.845011434760245
              ],
              [
                -73.81263431789743,
                40.845025957807046
              ],
              [
                -73.81264187041519,
                40.84503972847399
              ],
              [
                -73.81265228592719,
                40.8450523762078
              ],
              [
                -73.81271815525626,
                40.84508199258822
              ],
              [
                -73.81278677056557,
                40.845107764018586
              ],
              [
                -73.8128577433794,
                40.845129544589135
              ],
              [
                -73.81284896543414,
                40.84516302844033
              ],
              [
                -73.81261378787293,
                40.845109507865835
              ],
              [
                -73.81260675421473,
                40.8451295602829
              ],
              [
                -73.81284143551395,
                40.84518017067741
              ],
              [
                -73.8128187370873,
                40.84523916158629
              ],
              [
                -73.81281480700316,
                40.84524937768874
              ],
              [
                -73.81279160895893,
                40.84530966826944
              ],
              [
                -73.81278779244019,
                40.84531958368211
              ],
              [
                -73.81276260918139,
                40.845385035965634
              ],
              [
                -73.81275913732,
                40.84539405886649
              ],
              [
                -73.81273369555522,
                40.84546018215936
              ],
              [
                -73.81273003634698,
                40.8454696920823
              ],
              [
                -73.81270737483655,
                40.84552858603021
              ],
              [
                -73.81270305301986,
                40.84553876949518
              ],
              [
                -73.81268166510716,
                40.84558916500479
              ],
              [
                -73.81267740391031,
                40.84559920533425
              ],
              [
                -73.81265211763224,
                40.8456587843688
              ],
              [
                -73.81264753175402,
                40.84566958841404
              ],
              [
                -73.8126206686779,
                40.84573288173383
              ],
              [
                -73.8126158658126,
                40.8457441997237
              ],
              [
                -73.81259307324952,
                40.845806709773306
              ],
              [
                -73.8125890919327,
                40.8458176250289
              ],
              [
                -73.81257091004653,
                40.845867489982936
              ],
              [
                -73.81256795450659,
                40.84587559467994
              ],
              [
                -73.8125481027327,
                40.845930037858956
              ],
              [
                -73.81254490240872,
                40.845938814598576
              ],
              [
                -73.81252247454867,
                40.84600032225913
              ],
              [
                -73.8125193042311,
                40.8460090167925
              ],
              [
                -73.81250124152136,
                40.84605780927202
              ],
              [
                -73.81249769032014,
                40.8460674056371
              ],
              [
                -73.8124794586283,
                40.846116656183504
              ],
              [
                -73.81246537830961,
                40.846154694230144
              ],
              [
                -73.81245518041634,
                40.846182240029286
              ],
              [
                -73.81245152428279,
                40.84619211782991
              ],
              [
                -73.8124437463115,
                40.84621312692523
              ],
              [
                -73.8124274491956,
                40.846257153023316
              ],
              [
                -73.81240460541035,
                40.8463235422993
              ],
              [
                -73.81240162310021,
                40.846332207482845
              ],
              [
                -73.81239225664594,
                40.84635942912889
              ],
              [
                -73.81232960133006,
                40.8465415118325
              ],
              [
                -73.81228170265241,
                40.84653122414932
              ],
              [
                -73.81227790900483,
                40.846543458279285
              ],
              [
                -73.8124095770743,
                40.846570544319746
              ],
              [
                -73.81241445328128,
                40.84655938074876
              ],
              [
                -73.81234003852444,
                40.846544521124954
              ],
              [
                -73.81236589325664,
                40.846476026630356
              ],
              [
                -73.81244657871599,
                40.8462515223213
              ],
              [
                -73.81246665727525,
                40.846195654572554
              ],
              [
                -73.812469820348,
                40.846187031394344
              ],
              [
                -73.81248886322167,
                40.84613512255843
              ],
              [
                -73.81251579437537,
                40.84606170959747
              ],
              [
                -73.81258526774707,
                40.845880039579335
              ],
              [
                -73.81258924696179,
                40.845869871979865
              ],
              [
                -73.81260846487879,
                40.84582075804066
              ],
              [
                -73.81263653799114,
                40.84574901292879
              ],
              [
                -73.81269357395503,
                40.84560324714131
              ],
              [
                -73.81275096195384,
                40.84546508915683
              ],
              [
                -73.81283121824966,
                40.845252970233965
              ],
              [
                -73.81285536957633,
                40.845186285461466
              ],
              [
                -73.8129934380874,
                40.84522477118415
              ],
              [
                -73.81315597044244,
                40.84521210037634
              ],
              [
                -73.81314914269447,
                40.845199582814956
              ],
              [
                -73.81299499392401,
                40.84521129724105
              ],
              [
                -73.81286150958843,
                40.845174987722444
              ],
              [
                -73.8128770359758,
                40.845134667945906
              ],
              [
                -73.81293647420665,
                40.84514842232433
              ],
              [
                -73.8129969072876,
                40.84515939555485
              ],
              [
                -73.81303217715568,
                40.84515972594982
              ],
              [
                -73.81306697644588,
                40.845164099074076
              ],
              [
                -73.81310050626846,
                40.84517241453326
              ],
              [
                -73.81313199687555,
                40.84518448142888
              ],
              [
                -73.8131607253336,
                40.84520002274004
              ],
              [
                -73.81318603211814,
                40.8452186816831
              ],
              [
                -73.8132073362552,
                40.84524002990253
              ],
              [
                -73.81324326350918,
                40.845235908352564
              ],
              [
                -73.81321883271909,
                40.84530004019003
              ],
              [
                -73.81321733224652,
                40.84530355425585
              ],
              [
                -73.81321337703977,
                40.84531346728018
              ],
              [
                -73.81320083160338,
                40.84534490411173
              ],
              [
                -73.81319008790805,
                40.84537182875387
              ],
              [
                -73.81316934230622,
                40.84542381787774
              ],
              [
                -73.81316632973468,
                40.84543136866384
              ],
              [
                -73.81315189449639,
                40.84546754586951
              ],
              [
                -73.81314023855164,
                40.845496754893226
              ],
              [
                -73.8131199180934,
                40.84554768024855
              ],
              [
                -73.81311515692454,
                40.84555911090309
              ],
              [
                -73.81308968175213,
                40.84562026897556
              ],
              [
                -73.81304250986348,
                40.845733520641566
              ],
              [
                -73.81302130291374,
                40.84578443197658
              ],
              [
                -73.81301735339504,
                40.84579308572516
              ],
              [
                -73.81298556073538,
                40.84586275597756
              ],
              [
                -73.81298050490136,
                40.845873834682926
              ],
              [
                -73.81294861826635,
                40.845943712166594
              ],
              [
                -73.81294340748332,
                40.84595513052506
              ],
              [
                -73.81291695576553,
                40.846013094970935
              ],
              [
                -73.81291272697042,
                40.84602236357809
              ],
              [
                -73.81281302458174,
                40.846232678405585
              ],
              [
                -73.81278637726436,
                40.846288889698705
              ],
              [
                -73.81273118509971,
                40.84627639345535
              ],
              [
                -73.81272457676761,
                40.84628661994713
              ],
              [
                -73.81284939958523,
                40.84631879949822
              ],
              [
                -73.81285506329952,
                40.84631005254604
              ],
              [
                -73.81280034773182,
                40.84629500504941
              ],
              [
                -73.81282910435463,
                40.84623593117618
              ],
              [
                -73.812834714828,
                40.846224073218984
              ],
              [
                -73.8128616248963,
                40.84616720698679
              ],
              [
                -73.81286634522233,
                40.8461572298828
              ],
              [
                -73.81289475100886,
                40.84609719969872
              ],
              [
                -73.8128995185849,
                40.84608712617609
              ],
              [
                -73.81292791787256,
                40.84602711071475
              ],
              [
                -73.81293267079396,
                40.84601706732123
              ],
              [
                -73.81296109021649,
                40.84595700916516
              ],
              [
                -73.81296525153213,
                40.84594764931379
              ],
              [
                -73.81297222057104,
                40.845931976854125
              ],
              [
                -73.81303214849963,
                40.84579720147919
              ],
              [
                -73.81305592038038,
                40.84574048758393
              ],
              [
                -73.81306571530193,
                40.84571712180477
              ],
              [
                -73.81307003010966,
                40.84570682624162
              ],
              [
                -73.81310021214391,
                40.84563482259397
              ],
              [
                -73.81310414849683,
                40.84562543004051
              ],
              [
                -73.81312894063261,
                40.8455645216417
              ],
              [
                -73.81315836336442,
                40.84549223296162
              ],
              [
                -73.8131813263456,
                40.845435817428005
              ],
              [
                -73.81318496869365,
                40.84542686732862
              ],
              [
                -73.81320532266803,
                40.84537685920887
              ],
              [
                -73.81323306389241,
                40.84530870462136
              ],
              [
                -73.81323858414319,
                40.845293648966866
              ],
              [
                -73.81325997724689,
                40.845233990458226
              ],
              [
                -73.81346166413532,
                40.84521084953066
              ],
              [
                -73.81361858267053,
                40.84527504238851
              ],
              [
                -73.81360874968259,
                40.845294529304766
              ],
              [
                -73.81360529679492,
                40.84530146843104
              ],
              [
                -73.81360307935833,
                40.8453059375701
              ],
              [
                -73.81359235780202,
                40.845327383916846
              ],
              [
                -73.81359057586107,
                40.84533097660405
              ],
              [
                -73.81358334364413,
                40.8453454641151
              ],
              [
                -73.81358150186045,
                40.84534490562921
              ],
              [
                -73.81357718780484,
                40.84535418233245
              ],
              [
                -73.81355519045732,
                40.84540149809662
              ],
              [
                -73.8135513216049,
                40.84540981761643
              ],
              [
                -73.81352888367317,
                40.84545807950861
              ],
              [
                -73.81352545669,
                40.84546545122117
              ],
              [
                -73.81350354028108,
                40.84551259137301
              ],
              [
                -73.81349880426079,
                40.845522787432344
              ],
              [
                -73.81348768163402,
                40.84554673241013
              ],
              [
                -73.8134714600873,
                40.84558165737287
              ],
              [
                -73.81346215335952,
                40.845601698458296
              ],
              [
                -73.81343964459093,
                40.8456501583979
              ],
              [
                -73.81336591663198,
                40.84580169873155
              ],
              [
                -73.81336084994268,
                40.84581022336596
              ],
              [
                -73.81334354640917,
                40.84583933649325
              ],
              [
                -73.81333800338913,
                40.84584866229296
              ],
              [
                -73.81331213394002,
                40.845892188380766
              ],
              [
                -73.81330787049271,
                40.845899363232085
              ],
              [
                -73.81328359296052,
                40.84593743814766
              ],
              [
                -73.81321888080062,
                40.84591364264858
              ],
              [
                -73.81321295244251,
                40.84592293984184
              ],
              [
                -73.81328932692855,
                40.84595102273163
              ],
              [
                -73.81331925050839,
                40.845904095438385
              ],
              [
                -73.81333092964161,
                40.845886902761166
              ],
              [
                -73.81335311605325,
                40.84585424368401
              ],
              [
                -73.8133590558502,
                40.845845499331034
              ],
              [
                -73.8134017466251,
                40.845760946691016
              ],
              [
                -73.8134056557891,
                40.84575250478186
              ],
              [
                -73.81342525440728,
                40.845710185088585
              ],
              [
                -73.81345137381052,
                40.84565378114233
              ],
              [
                -73.81345513687894,
                40.84564614160247
              ],
              [
                -73.8134852598153,
                40.84558498593946
              ],
              [
                -73.8135169818933,
                40.84552058590417
              ],
              [
                -73.81351835900998,
                40.84551779014539
              ],
              [
                -73.8135984047367,
                40.84534902639254
              ],
              [
                -73.81360627346879,
                40.84533285629323
              ],
              [
                -73.8136099439493,
                40.845325259484426
              ],
              [
                -73.81361456155729,
                40.845315742706
              ],
              [
                -73.81361921535247,
                40.84530614373162
              ],
              [
                -73.81363258232699,
                40.84528076903137
              ],
              [
                -73.8138054459058,
                40.84535148296824
              ],
              [
                -73.8138425886934,
                40.84539939043909
              ],
              [
                -73.81371114891479,
                40.84546210844935
              ],
              [
                -73.81372892648774,
                40.84548506817224
              ],
              [
                -73.813856677988,
                40.84541756288903
              ],
              [
                -73.81390206940699,
                40.84547610835491
              ],
              [
                -73.81394279128921,
                40.84551158911122
              ],
              [
                -73.81367220100483,
                40.84564653246912
              ],
              [
                -73.81368589093518,
                40.8456590622548
              ],
              [
                -73.81395424463112,
                40.845521567553575
              ],
              [
                -73.81403198219364,
                40.84558929786788
              ],
              [
                -73.81402255974176,
                40.8456179666301
              ],
              [
                -73.81397419150323,
                40.84563555470811
              ],
              [
                -73.81346409683638,
                40.84594950610961
              ],
              [
                -73.8134832683883,
                40.8459651709471
              ],
              [
                -73.81397964395012,
                40.845649113005585
              ],
              [
                -73.81401812695707,
                40.845631456887766
              ],
              [
                -73.81400589013697,
                40.84566869163601
              ],
              [
                -73.81381880576468,
                40.84580248822437
              ],
              [
                -73.81314460200237,
                40.84616856213872
              ],
              [
                -73.81318865072497,
                40.8462295364752
              ],
              [
                -73.81390002991992,
                40.84586580865546
              ],
              [
                -73.81399042132384,
                40.84582691919379
              ],
              [
                -73.81420573129112,
                40.84601018088845
              ],
              [
                -73.8150262760428,
                40.845499995058404
              ],
              [
                -73.81508593855773,
                40.84559500341857
              ],
              [
                -73.8148629624093,
                40.845743562822
              ],
              [
                -73.81480534287803,
                40.84577794558428
              ],
              [
                -73.81475299202867,
                40.84581753085871
              ],
              [
                -73.81468170770279,
                40.84586721536336
              ],
              [
                -73.81460449034037,
                40.84590867307147
              ],
              [
                -73.81465507970387,
                40.84596849742508
              ],
              [
                -73.8144235257982,
                40.84618782507239
              ],
              [
                -73.81446907674172,
                40.846234151701985
              ],
              [
                -73.81465695793413,
                40.84620554530145
              ],
              [
                -73.81490858203199,
                40.846468764879
              ],
              [
                -73.81484315852317,
                40.84650508930117
              ],
              [
                -73.81458182304954,
                40.846653268772705
              ],
              [
                -73.8144309479936,
                40.84673965478645
              ],
              [
                -73.81442363291603,
                40.846743841837934
              ],
              [
                -73.81431749077636,
                40.846804615226965
              ],
              [
                -73.81428325967708,
                40.84682421385871
              ],
              [
                -73.81412248680294,
                40.84691626611061
              ],
              [
                -73.8141126142839,
                40.84692191921119
              ],
              [
                -73.81408421730941,
                40.84693817754443
              ],
              [
                -73.81403390211042,
                40.84696698560397
              ],
              [
                -73.81400644417953,
                40.846982707009985
              ],
              [
                -73.81395483717435,
                40.84701225458043
              ],
              [
                -73.8139064166056,
                40.847039978242044
              ],
              [
                -73.8138789424857,
                40.84705570830289
              ],
              [
                -73.81385116806617,
                40.84707161025455
              ],
              [
                -73.81380002008125,
                40.847100894981125
              ],
              [
                -73.8137731809535,
                40.847116262179576
              ],
              [
                -73.81372091416893,
                40.8471461873514
              ],
              [
                -73.81369588380107,
                40.847160518804515
              ],
              [
                -73.8136447278293,
                40.84719024319919
              ],
              [
                -73.8136274532093,
                40.84718958339062
              ],
              [
                -73.81358305958231,
                40.8471407102681
              ],
              [
                -73.81356372861671,
                40.84715141127853
              ],
              [
                -73.81361194639693,
                40.84720259571633
              ],
              [
                -73.81368634738199,
                40.84727432550905
              ],
              [
                -73.81370542127051,
                40.84726491500233
              ],
              [
                -73.81365654726848,
                40.84721570515366
              ],
              [
                -73.81365740839362,
                40.84720527717644
              ],
              [
                -73.81370805578399,
                40.84717629257802
              ],
              [
                -73.81373354969317,
                40.84716163134603
              ],
              [
                -73.81378699193705,
                40.8471308984231
              ],
              [
                -73.81381218552613,
                40.84711640856846
              ],
              [
                -73.81386532718723,
                40.84708584851123
              ],
              [
                -73.81389036650836,
                40.84707144784832
              ],
              [
                -73.81392771742684,
                40.847049968716775
              ],
              [
                -73.8139672085705,
                40.847027257368424
              ],
              [
                -73.81402010393954,
                40.84699683789761
              ],
              [
                -73.81404710378914,
                40.846981311250225
              ],
              [
                -73.81409776493562,
                40.846952176568365
              ],
              [
                -73.81412394940415,
                40.846937116818296
              ],
              [
                -73.81416157394456,
                40.846915479909036
              ],
              [
                -73.8142011119944,
                40.84689274108202
              ],
              [
                -73.8142426258526,
                40.8468688674331
              ],
              [
                -73.8142787652347,
                40.8468480839736
              ],
              [
                -73.81433272712044,
                40.84681705076067
              ],
              [
                -73.81435726288592,
                40.84680294067716
              ],
              [
                -73.8144095316957,
                40.84677288069777
              ],
              [
                -73.81443423469617,
                40.84675867437311
              ],
              [
                -73.81447890592031,
                40.84673298372796
              ],
              [
                -73.81451301364092,
                40.8467133689247
              ],
              [
                -73.81456579118257,
                40.846683016022794
              ],
              [
                -73.81458976583284,
                40.846669227806295
              ],
              [
                -73.8147021413662,
                40.846603315666634
              ],
              [
                -73.8148468378496,
                40.84652123412636
              ],
              [
                -73.81492265739294,
                40.846483488692186
              ],
              [
                -73.81496569829835,
                40.846520173779176
              ],
              [
                -73.81499863613446,
                40.84653949889562
              ],
              [
                -73.81503922076097,
                40.84654727225552
              ],
              [
                -73.81507222380488,
                40.84654347161616
              ],
              [
                -73.81511795800365,
                40.84652427263894
              ],
              [
                -73.81516370934222,
                40.846499292755496
              ],
              [
                -73.81551442131553,
                40.84632255239668
              ],
              [
                -73.81557285082476,
                40.84630144567658
              ],
              [
                -73.81561094027825,
                40.84629379828986
              ],
              [
                -73.81561559185766,
                40.84629327601445
              ],
              [
                -73.81566171153669,
                40.84628809801732
              ],
              [
                -73.81570353189448,
                40.84629134042384
              ],
              [
                -73.81569104659448,
                40.84631082662619
              ],
              [
                -73.81568288414337,
                40.846307825124846
              ],
              [
                -73.81558313973727,
                40.846423627606036
              ],
              [
                -73.81555508085557,
                40.84640893914352
              ],
              [
                -73.81547456779656,
                40.84649683598924
              ],
              [
                -73.8155040927001,
                40.84651260985806
              ],
              [
                -73.81543413390233,
                40.846590726085694
              ],
              [
                -73.8153287782355,
                40.84666566380006
              ],
              [
                -73.81515846332752,
                40.84677136764705
              ],
              [
                -73.81496489547806,
                40.84688983746206
              ],
              [
                -73.8148473509641,
                40.84696357162561
              ],
              [
                -73.81460847368038,
                40.847127137241564
              ],
              [
                -73.81457540884595,
                40.84712481699561
              ],
              [
                -73.81453370023934,
                40.8470908993939
              ],
              [
                -73.81451106769596,
                40.847106078026734
              ],
              [
                -73.81464594697542,
                40.84721961352901
              ],
              [
                -73.81466741414532,
                40.84720400648179
              ],
              [
                -73.81462363819276,
                40.84716768999988
              ],
              [
                -73.8146272778874,
                40.84714279476088
              ],
              [
                -73.81486160038685,
                40.84698411349446
              ],
              [
                -73.8149346545324,
                40.84693500918082
              ],
              [
                -73.81503963145569,
                40.84687195469736
              ],
              [
                -73.81533644376374,
                40.84669088909081
              ],
              [
                -73.81545867615472,
                40.84660302877153
              ],
              [
                -73.81568770320885,
                40.84634184034868
              ],
              [
                -73.81568488630352,
                40.84634080490549
              ],
              [
                -73.81571903834562,
                40.846293443498816
              ],
              [
                -73.81577586619423,
                40.84630562530891
              ],
              [
                -73.81584432887755,
                40.8463269331039
              ],
              [
                -73.81602685114903,
                40.846400458770034
              ],
              [
                -73.81607246774338,
                40.846423657352936
              ],
              [
                -73.81610537477145,
                40.8464545444374
              ],
              [
                -73.81611797359875,
                40.84648732650394
              ],
              [
                -73.81613006048687,
                40.84652798288453
              ],
              [
                -73.81614872235644,
                40.846648845282765
              ],
              [
                -73.81616622859919,
                40.84684416216906
              ],
              [
                -73.81617026811975,
                40.84701547486435
              ],
              [
                -73.81614705574094,
                40.8472518403908
              ],
              [
                -73.81612862993478,
                40.84738885611984
              ],
              [
                -73.81609670609068,
                40.8475121465354
              ],
              [
                -73.8160558923281,
                40.8475874556508
              ],
              [
                -73.81597449927862,
                40.847652422096665
              ],
              [
                -73.81595734753984,
                40.84765673360196
              ],
              [
                -73.81587968574792,
                40.84767625352132
              ],
              [
                -73.81580298434562,
                40.84767613107148
              ],
              [
                -73.81575340325438,
                40.847658921065964
              ],
              [
                -73.81569476839675,
                40.84765197550432
              ],
              [
                -73.81564516681594,
                40.84764161781635
              ],
              [
                -73.81560451391982,
                40.84765868316219
              ],
              [
                -73.81560442844047,
                40.84768951881168
              ],
              [
                -73.81563145161934,
                40.847706692829895
              ],
              [
                -73.8156945975249,
                40.847713645630954
              ],
              [
                -73.81577576231183,
                40.84773090610564
              ],
              [
                -73.81586136315966,
                40.84777558185126
              ],
              [
                -73.81589732510677,
                40.84782360519032
              ],
              [
                -73.81589711649299,
                40.84789897954558
              ],
              [
                -73.81590281420543,
                40.84801312198427
              ],
              [
                -73.81586274203565,
                40.84809250102881
              ],
              [
                -73.8157983885727,
                40.84815939778306
              ],
              [
                -73.81579073434887,
                40.848164221136976
              ],
              [
                -73.81575017833134,
                40.848189775457044
              ],
              [
                -73.81568607715445,
                40.84816530989142
              ],
              [
                -73.8156138206772,
                40.848189557582444
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0000628765230648,
        "objectid": 59,
        "shape_leng": 0.0377948070893,
        "location_id": 59,
        "zone": "Crotona Park",
        "borough": "Bronx"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.88941724899998,
                40.839422732999864
              ],
              [
                -73.89017468599991,
                40.83987366699987
              ],
              [
                -73.8921681559998,
                40.84104963999991
              ],
              [
                -73.89317098900001,
                40.841637343999906
              ],
              [
                -73.89410150499991,
                40.84219030299994
              ],
              [
                -73.89484862799985,
                40.842628259999934
              ],
              [
                -73.89493579999981,
                40.84267935899995
              ],
              [
                -73.89501415799995,
                40.84272529099996
              ],
              [
                -73.89509807299986,
                40.84277448099987
              ],
              [
                -73.89527784899985,
                40.84287986099988
              ],
              [
                -73.89537836199995,
                40.842938778999965
              ],
              [
                -73.89564979700005,
                40.84303553999991
              ],
              [
                -73.89591487099987,
                40.84314291899991
              ],
              [
                -73.8961725309998,
                40.8432605479999
              ],
              [
                -73.89642177099995,
                40.84338795899992
              ],
              [
                -73.89666161699988,
                40.84352460699992
              ],
              [
                -73.89749111599987,
                40.84206708899993
              ],
              [
                -73.8982696629998,
                40.840679946999956
              ],
              [
                -73.89903034699984,
                40.839330476999876
              ],
              [
                -73.89996792899998,
                40.83765476299991
              ],
              [
                -73.90004236899996,
                40.83752257599984
              ],
              [
                -73.90014268099999,
                40.83734444099986
              ],
              [
                -73.90019045299977,
                40.837259606999964
              ],
              [
                -73.90022284799983,
                40.83720207899991
              ],
              [
                -73.9002588729999,
                40.83713810599989
              ],
              [
                -73.90030702699991,
                40.83705259399993
              ],
              [
                -73.90035730100003,
                40.83696331799991
              ],
              [
                -73.9003894469999,
                40.83690622999995
              ],
              [
                -73.90056610899994,
                40.83659250799992
              ],
              [
                -73.90122534999992,
                40.83549991099993
              ],
              [
                -73.9012157839999,
                40.83541014099991
              ],
              [
                -73.89963414999981,
                40.835016222999926
              ],
              [
                -73.89845304899993,
                40.83472024099995
              ],
              [
                -73.89809341399993,
                40.834628802999916
              ],
              [
                -73.89697220299995,
                40.8343452539999
              ],
              [
                -73.89679566,
                40.83440893499992
              ],
              [
                -73.89662258200005,
                40.8344778859999
              ],
              [
                -73.89645324200002,
                40.83455199899986
              ],
              [
                -73.89628790799986,
                40.83463115699991
              ],
              [
                -73.8961268389998,
                40.83471523499991
              ],
              [
                -73.89597028899992,
                40.834804100999925
              ],
              [
                -73.89581850399996,
                40.83489761499992
              ],
              [
                -73.89567172399995,
                40.834995628999884
              ],
              [
                -73.89553017999992,
                40.83509798999991
              ],
              [
                -73.89539409500006,
                40.8352045349999
              ],
              [
                -73.89526368299991,
                40.83531509799996
              ],
              [
                -73.89513914999998,
                40.83542950399993
              ],
              [
                -73.89501173599986,
                40.83559855499988
              ],
              [
                -73.89486311099988,
                40.83576065299992
              ],
              [
                -73.89469434299991,
                40.83591357399994
              ],
              [
                -73.89450698899992,
                40.8360551939999
              ],
              [
                -73.89430313999998,
                40.83618364399992
              ],
              [
                -73.89408527499981,
                40.8362973469999
              ],
              [
                -73.89385624299995,
                40.8363951019999
              ],
              [
                -73.89361903299995,
                40.8364761079999
              ],
              [
                -73.89337676299994,
                40.836540007999886
              ],
              [
                -73.8914808639999,
                40.836687063999946
              ],
              [
                -73.89129045299998,
                40.83669941999994
              ],
              [
                -73.89110124799988,
                40.83672748199994
              ],
              [
                -73.89091660699987,
                40.83677103399993
              ],
              [
                -73.8907397549999,
                40.836829308999945
              ],
              [
                -73.89057363299983,
                40.83690104799993
              ],
              [
                -73.88874067899994,
                40.83796845899992
              ],
              [
                -73.88841187199996,
                40.838553390999905
              ],
              [
                -73.88730373200002,
                40.838127190999955
              ],
              [
                -73.88659802299989,
                40.83927904999991
              ],
              [
                -73.88754251799996,
                40.83964065799996
              ],
              [
                -73.88813752199992,
                40.83986200199991
              ],
              [
                -73.88869040799986,
                40.83915802599989
              ],
              [
                -73.88941724899998,
                40.839422732999864
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000161833284912,
        "objectid": 60,
        "shape_leng": 0.0786482878226,
        "location_id": 60,
        "zone": "Crotona Park East",
        "borough": "Bronx"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.87823856799989,
                40.839985917999854
              ],
              [
                -73.87831009099979,
                40.839989350999915
              ],
              [
                -73.87841924999982,
                40.83999458999992
              ],
              [
                -73.87850461299986,
                40.84000559399992
              ],
              [
                -73.8786598319999,
                40.84001990399986
              ],
              [
                -73.87978300299987,
                40.84014582599986
              ],
              [
                -73.87992249999996,
                40.840154029999916
              ],
              [
                -73.880061996,
                40.84016223499986
              ],
              [
                -73.88010086899989,
                40.84006661599993
              ],
              [
                -73.88027152699999,
                40.839896239999874
              ],
              [
                -73.88030767999986,
                40.83971794399991
              ],
              [
                -73.88030769199985,
                40.8397178709999
              ],
              [
                -73.88030771499984,
                40.83971779899987
              ],
              [
                -73.88036581299994,
                40.8395405789999
              ],
              [
                -73.88036583800002,
                40.839540506999924
              ],
              [
                -73.88036587299996,
                40.83954043499988
              ],
              [
                -73.88044586500001,
                40.83936659699989
              ],
              [
                -73.88044592400003,
                40.839366479999946
              ],
              [
                -73.880445995,
                40.839366353999885
              ],
              [
                -73.88054735399987,
                40.839198110999895
              ],
              [
                -73.88066887899986,
                40.83903768799994
              ],
              [
                -73.88066892699995,
                40.839037624999904
              ],
              [
                -73.88066897399986,
                40.83903757099991
              ],
              [
                -73.88080875199994,
                40.83888725199994
              ],
              [
                -73.88080895400005,
                40.83888703599989
              ],
              [
                -73.88080919100001,
                40.83888681999986
              ],
              [
                -73.880965322,
                40.838748079999895
              ],
              [
                -73.88113550299995,
                40.838622141999906
              ],
              [
                -73.88135107299986,
                40.83840917199989
              ],
              [
                -73.88138310599982,
                40.83837771399989
              ],
              [
                -73.88154924699987,
                40.838182756999885
              ],
              [
                -73.88160324799993,
                40.83809985899994
              ],
              [
                -73.88166303799993,
                40.83802861899994
              ],
              [
                -73.88169674899984,
                40.83798711299989
              ],
              [
                -73.88172047499994,
                40.83795364899991
              ],
              [
                -73.88182513299996,
                40.837787064999915
              ],
              [
                -73.88191969599985,
                40.83761693299992
              ],
              [
                -73.88200384100001,
                40.837443781999895
              ],
              [
                -73.88203937499988,
                40.83752459299991
              ],
              [
                -73.88206752699983,
                40.83765562499989
              ],
              [
                -73.88211370499987,
                40.83778515499997
              ],
              [
                -73.88217759099985,
                40.83791103699992
              ],
              [
                -73.88225831200006,
                40.83803121799985
              ],
              [
                -73.88235442499995,
                40.83814382399988
              ],
              [
                -73.88246404800005,
                40.83824724099991
              ],
              [
                -73.88285365099989,
                40.83849533499994
              ],
              [
                -73.88279558600004,
                40.838549945999944
              ],
              [
                -73.88274769899992,
                40.8385923409999
              ],
              [
                -73.88313873999988,
                40.838810671999944
              ],
              [
                -73.88351820399988,
                40.8390223659999
              ],
              [
                -73.88403342700002,
                40.839304640999934
              ],
              [
                -73.88412724499999,
                40.83935591899995
              ],
              [
                -73.88418143199976,
                40.839335306999935
              ],
              [
                -73.88435324499996,
                40.83927764999994
              ],
              [
                -73.88464898099988,
                40.83917463299989
              ],
              [
                -73.88469619299984,
                40.83920338699989
              ],
              [
                -73.88583496499986,
                40.83989692699992
              ],
              [
                -73.88618432099992,
                40.84010504399987
              ],
              [
                -73.88659802299989,
                40.83927904999991
              ],
              [
                -73.88730373200002,
                40.838127190999955
              ],
              [
                -73.88841187199996,
                40.838553390999905
              ],
              [
                -73.88874067899994,
                40.83796845899992
              ],
              [
                -73.89057363299983,
                40.83690104799993
              ],
              [
                -73.8907397549999,
                40.836829308999945
              ],
              [
                -73.89091660700001,
                40.83677103499993
              ],
              [
                -73.89110124799988,
                40.83672748199994
              ],
              [
                -73.89129045299998,
                40.83669941999994
              ],
              [
                -73.8914808639999,
                40.836687063999946
              ],
              [
                -73.89337676299994,
                40.836540007999886
              ],
              [
                -73.89361903299995,
                40.8364761079999
              ],
              [
                -73.89385624299995,
                40.8363951019999
              ],
              [
                -73.89408527499981,
                40.8362973469999
              ],
              [
                -73.89430313999998,
                40.83618364399992
              ],
              [
                -73.89450698899992,
                40.8360551939999
              ],
              [
                -73.89469434299991,
                40.83591357399994
              ],
              [
                -73.89486311099988,
                40.83576065299992
              ],
              [
                -73.89501173599986,
                40.83559855499988
              ],
              [
                -73.89513914999998,
                40.83542950399993
              ],
              [
                -73.89526368299991,
                40.83531509799996
              ],
              [
                -73.89539409500006,
                40.8352045349999
              ],
              [
                -73.89553017999992,
                40.83509798999991
              ],
              [
                -73.89567172399995,
                40.834995628999884
              ],
              [
                -73.89581850399996,
                40.83489761499992
              ],
              [
                -73.89597028899992,
                40.834804100999925
              ],
              [
                -73.8961268389998,
                40.83471523499991
              ],
              [
                -73.89628790799986,
                40.83463115699991
              ],
              [
                -73.89645324200002,
                40.83455199899986
              ],
              [
                -73.89662258200005,
                40.8344778859999
              ],
              [
                -73.89679566,
                40.83440893499992
              ],
              [
                -73.89697220299995,
                40.8343452539999
              ],
              [
                -73.89678082699997,
                40.834126789999914
              ],
              [
                -73.8963019999999,
                40.8335432179999
              ],
              [
                -73.89647296199996,
                40.83304756799988
              ],
              [
                -73.89669216099999,
                40.83196614399989
              ],
              [
                -73.89690017899987,
                40.8310333359999
              ],
              [
                -73.89717220999982,
                40.83036883299998
              ],
              [
                -73.89669632499995,
                40.83041450399989
              ],
              [
                -73.89560633499985,
                40.83052438299989
              ],
              [
                -73.89485843399979,
                40.830601857999895
              ],
              [
                -73.894692052,
                40.8306190939999
              ],
              [
                -73.893360418,
                40.83015253099996
              ],
              [
                -73.8928855849999,
                40.83014757099991
              ],
              [
                -73.89187594399993,
                40.83013750599992
              ],
              [
                -73.89087299799995,
                40.8301360029999
              ],
              [
                -73.8899110649999,
                40.83012419199988
              ],
              [
                -73.88898205299992,
                40.829814800999934
              ],
              [
                -73.88805361099989,
                40.82950305199992
              ],
              [
                -73.8876130789999,
                40.82933741899991
              ],
              [
                -73.88755325099989,
                40.8292963429999
              ],
              [
                -73.88601262699991,
                40.82823853099994
              ],
              [
                -73.88609624199987,
                40.827655776999904
              ],
              [
                -73.88615037899991,
                40.82730567799995
              ],
              [
                -73.88635032500001,
                40.82601262299995
              ],
              [
                -73.88647552899988,
                40.82528542399992
              ],
              [
                -73.88710697199997,
                40.82330587099989
              ],
              [
                -73.88706248999988,
                40.823190274999945
              ],
              [
                -73.88753429599986,
                40.822509338999964
              ],
              [
                -73.88719405099981,
                40.822593528999946
              ],
              [
                -73.88709505499988,
                40.82260272499986
              ],
              [
                -73.88698003699992,
                40.82261725799987
              ],
              [
                -73.88658010099988,
                40.822668116999886
              ],
              [
                -73.88508162399985,
                40.82286844499986
              ],
              [
                -73.88456358400003,
                40.822937066999906
              ],
              [
                -73.8843985489999,
                40.82296557599989
              ],
              [
                -73.88465102199984,
                40.82382289799994
              ],
              [
                -73.8846216589999,
                40.824956205999904
              ],
              [
                -73.88461335999997,
                40.825276502999905
              ],
              [
                -73.88460938899989,
                40.82530353599988
              ],
              [
                -73.88438705399992,
                40.826817283999965
              ],
              [
                -73.88378414099992,
                40.828274291999946
              ],
              [
                -73.8835201879999,
                40.82926018099995
              ],
              [
                -73.88357411800004,
                40.829478645999934
              ],
              [
                -73.88390094800003,
                40.829802815999884
              ],
              [
                -73.88384720899988,
                40.82987251899992
              ],
              [
                -73.88251168699992,
                40.8315543069999
              ],
              [
                -73.88057338999994,
                40.83403109299997
              ],
              [
                -73.88007189299988,
                40.83467187699994
              ],
              [
                -73.88005188499987,
                40.8347020589999
              ],
              [
                -73.87940077099982,
                40.83447236499995
              ],
              [
                -73.87925239499992,
                40.834424789999915
              ],
              [
                -73.87916928999984,
                40.83452626099989
              ],
              [
                -73.87906181199985,
                40.834639270999894
              ],
              [
                -73.87892705599994,
                40.83478096399988
              ],
              [
                -73.87866481499992,
                40.83502565899989
              ],
              [
                -73.87838370399994,
                40.83525903199989
              ],
              [
                -73.87808502000001,
                40.835479842999916
              ],
              [
                -73.87801301899998,
                40.83553007199991
              ],
              [
                -73.87794605999989,
                40.83557678399991
              ],
              [
                -73.87783304799999,
                40.83565562199993
              ],
              [
                -73.87756524599996,
                40.8358187519999
              ],
              [
                -73.87747843299982,
                40.83586472899987
              ],
              [
                -73.87746425099982,
                40.835872232999876
              ],
              [
                -73.87734872299994,
                40.83593341499995
              ],
              [
                -73.87728333599982,
                40.83596804299989
              ],
              [
                -73.87698921499998,
                40.83610249599993
              ],
              [
                -73.87673323499986,
                40.836205617999944
              ],
              [
                -73.87655099199999,
                40.83634482899989
              ],
              [
                -73.8764548759999,
                40.83638732799987
              ],
              [
                -73.87628029499993,
                40.836461531999895
              ],
              [
                -73.87615142899986,
                40.83649857399996
              ],
              [
                -73.87609408699988,
                40.83652621199991
              ],
              [
                -73.87584064899983,
                40.836612485999915
              ],
              [
                -73.87565111799996,
                40.836659548999904
              ],
              [
                -73.87545635000001,
                40.83669198199993
              ],
              [
                -73.87525928600002,
                40.83670942699994
              ],
              [
                -73.87498772399994,
                40.83677297799987
              ],
              [
                -73.87490147299992,
                40.83680809399989
              ],
              [
                -73.87482228199985,
                40.836859479999866
              ],
              [
                -73.87475779999978,
                40.83692560499988
              ],
              [
                -73.8747145209998,
                40.83700225399988
              ],
              [
                -73.87469588499998,
                40.83708331399992
              ],
              [
                -73.87470145099985,
                40.83716231099988
              ],
              [
                -73.87472739999993,
                40.83723397399998
              ],
              [
                -73.874908511,
                40.837359609999986
              ],
              [
                -73.87471801499991,
                40.83731259599988
              ],
              [
                -73.87454332199997,
                40.83726133799989
              ],
              [
                -73.87437386800003,
                40.837242521999904
              ],
              [
                -73.87430028599992,
                40.8372424419999
              ],
              [
                -73.87419768599986,
                40.837250794999896
              ],
              [
                -73.87407495699992,
                40.8372896169999
              ],
              [
                -73.87401051299989,
                40.83731000499993
              ],
              [
                -73.87379590199993,
                40.83744513299991
              ],
              [
                -73.87367306200004,
                40.837541539999926
              ],
              [
                -73.87353901799992,
                40.83766841799995
              ],
              [
                -73.87347510499988,
                40.8377463399999
              ],
              [
                -73.87322928299983,
                40.83815590199992
              ],
              [
                -73.87314714699997,
                40.83826389899994
              ],
              [
                -73.87312728200003,
                40.83833509599989
              ],
              [
                -73.87298042899991,
                40.83895248499995
              ],
              [
                -73.87277204699981,
                40.83975128199996
              ],
              [
                -73.87283453899995,
                40.83975486299994
              ],
              [
                -73.87359713599987,
                40.83979855499992
              ],
              [
                -73.87561285199983,
                40.83990252099989
              ],
              [
                -73.87747229399999,
                40.839983546999925
              ],
              [
                -73.87791380399995,
                40.8399703279999
              ],
              [
                -73.87823856799989,
                40.839985917999854
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000510772092295,
        "objectid": 61,
        "shape_leng": 0.117310808097,
        "location_id": 61,
        "zone": "Crown Heights North",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.92916130699992,
                40.68343632399992
              ],
              [
                -73.93208669799994,
                40.6831003499999
              ],
              [
                -73.93500821699992,
                40.68276376799985
              ],
              [
                -73.93486257200001,
                40.68203056899991
              ],
              [
                -73.93471441099992,
                40.68129738399991
              ],
              [
                -73.93457098299989,
                40.68056476199989
              ],
              [
                -73.93450838999993,
                40.68022169299993
              ],
              [
                -73.93439530999997,
                40.67983537999992
              ],
              [
                -73.93435066499993,
                40.67956340799992
              ],
              [
                -73.93487210899985,
                40.679593087999855
              ],
              [
                -73.93739763699983,
                40.679729899999934
              ],
              [
                -73.93843613299987,
                40.67978585199994
              ],
              [
                -73.94032794,
                40.67988997499989
              ],
              [
                -73.9412086429999,
                40.6799383539999
              ],
              [
                -73.94326176899995,
                40.68005060699992
              ],
              [
                -73.94397347799995,
                40.68008812799991
              ],
              [
                -73.94627471000001,
                40.68021332599987
              ],
              [
                -73.94674916000001,
                40.68023966099991
              ],
              [
                -73.94773023599986,
                40.680291845999896
              ],
              [
                -73.94955688699993,
                40.68039040299995
              ],
              [
                -73.95115828499993,
                40.68047861499993
              ],
              [
                -73.95155682700002,
                40.680498847999935
              ],
              [
                -73.9533701749999,
                40.680640507999904
              ],
              [
                -73.95328187399996,
                40.68020003099985
              ],
              [
                -73.95436836300001,
                40.68007510699993
              ],
              [
                -73.95543681599985,
                40.679957854999934
              ],
              [
                -73.95530907499989,
                40.67927313499995
              ],
              [
                -73.95529361599996,
                40.67920315099984
              ],
              [
                -73.9559586239999,
                40.679346246999906
              ],
              [
                -73.95829216799997,
                40.67983113299993
              ],
              [
                -73.96134655899994,
                40.6804635239999
              ],
              [
                -73.9614104629999,
                40.680413152999925
              ],
              [
                -73.96153961999995,
                40.680086254999935
              ],
              [
                -73.96167998599987,
                40.679702205999924
              ],
              [
                -73.96195644399998,
                40.6789338429999
              ],
              [
                -73.96222841199993,
                40.67816620799993
              ],
              [
                -73.9625463539998,
                40.67728661699993
              ],
              [
                -73.9628644419999,
                40.67640534099995
              ],
              [
                -73.96318238899983,
                40.675529503999904
              ],
              [
                -73.96007428300001,
                40.67487807099994
              ],
              [
                -73.96039301899985,
                40.67399860199987
              ],
              [
                -73.96070974499996,
                40.67312139399996
              ],
              [
                -73.96102898299978,
                40.67224318399995
              ],
              [
                -73.96126442099991,
                40.6715635909999
              ],
              [
                -73.96131877999991,
                40.671406675999876
              ],
              [
                -73.95904725399988,
                40.67088226799995
              ],
              [
                -73.957973172,
                40.67065727399989
              ],
              [
                -73.95526963699997,
                40.6701249049999
              ],
              [
                -73.95327019699985,
                40.67000720499991
              ],
              [
                -73.95160495999986,
                40.66992371899992
              ],
              [
                -73.9505004649999,
                40.66986833099987
              ],
              [
                -73.9477299629999,
                40.66971344199991
              ],
              [
                -73.94775110299997,
                40.669495265999934
              ],
              [
                -73.94781857499984,
                40.668783753999904
              ],
              [
                -73.94504645700006,
                40.66861461599996
              ],
              [
                -73.94228420399995,
                40.66846142699991
              ],
              [
                -73.93951520799992,
                40.66829561599992
              ],
              [
                -73.93674946999998,
                40.668127370999926
              ],
              [
                -73.9368252179999,
                40.66731699399985
              ],
              [
                -73.93686802199987,
                40.66684982899991
              ],
              [
                -73.93690681099987,
                40.66642658999991
              ],
              [
                -73.93694519299983,
                40.66598129699993
              ],
              [
                -73.93698362399992,
                40.665535507999955
              ],
              [
                -73.93422426299996,
                40.665377742999915
              ],
              [
                -73.93145549999988,
                40.665233817999884
              ],
              [
                -73.92930683899975,
                40.66511700699991
              ],
              [
                -73.92868404399991,
                40.665086022999944
              ],
              [
                -73.92869200600002,
                40.66496229699995
              ],
              [
                -73.92872204599985,
                40.66449556899988
              ],
              [
                -73.928228724,
                40.66470986499991
              ],
              [
                -73.92771188899987,
                40.66493432799992
              ],
              [
                -73.92739349999995,
                40.665074715999936
              ],
              [
                -73.92637970499992,
                40.66551489199993
              ],
              [
                -73.92592201499984,
                40.66571503599991
              ],
              [
                -73.92546270199979,
                40.665916751999916
              ],
              [
                -73.924390994,
                40.666381422999926
              ],
              [
                -73.92424467199987,
                40.66644499999989
              ],
              [
                -73.92331039799996,
                40.66685345999985
              ],
              [
                -73.92323224599996,
                40.666887627999856
              ],
              [
                -73.92311978499988,
                40.666936793999945
              ],
              [
                -73.92303439399991,
                40.66697412599995
              ],
              [
                -73.92281106599998,
                40.66707176099994
              ],
              [
                -73.92275257199998,
                40.66709733299991
              ],
              [
                -73.9227271419999,
                40.66736505599987
              ],
              [
                -73.92265529999987,
                40.66812711499991
              ],
              [
                -73.92263210799995,
                40.66837321099994
              ],
              [
                -73.92261354299985,
                40.66856410699991
              ],
              [
                -73.92254432199985,
                40.66927581099986
              ],
              [
                -73.92246696799994,
                40.6701169299999
              ],
              [
                -73.92245355399993,
                40.67026114899993
              ],
              [
                -73.92238401799992,
                40.67100876299986
              ],
              [
                -73.922299504,
                40.67190026199993
              ],
              [
                -73.92221444299993,
                40.67279163299992
              ],
              [
                -73.92213153399979,
                40.673683888999946
              ],
              [
                -73.92204843299987,
                40.67457439699991
              ],
              [
                -73.92197495299997,
                40.675348546999935
              ],
              [
                -73.92190090500002,
                40.676131620999904
              ],
              [
                -73.92183232600001,
                40.676849041999894
              ],
              [
                -73.92182360999988,
                40.676938357999894
              ],
              [
                -73.92181538999996,
                40.67701838999991
              ],
              [
                -73.92171159899995,
                40.67811571399989
              ],
              [
                -73.92164666399992,
                40.67886992299992
              ],
              [
                -73.92183500099996,
                40.67894555899992
              ],
              [
                -73.92197287199998,
                40.67907846999987
              ],
              [
                -73.9219999079998,
                40.679191413999895
              ],
              [
                -73.92201534599985,
                40.67925589999992
              ],
              [
                -73.92203214100005,
                40.67932605899988
              ],
              [
                -73.92205453499997,
                40.67941960399992
              ],
              [
                -73.92208787199982,
                40.679558857999915
              ],
              [
                -73.92211149999984,
                40.67965755699987
              ],
              [
                -73.92213242899994,
                40.67974497799986
              ],
              [
                -73.92215222999988,
                40.679844634999895
              ],
              [
                -73.92217715699984,
                40.6799700849999
              ],
              [
                -73.92221626999996,
                40.68016693499992
              ],
              [
                -73.92223681799994,
                40.680270342999904
              ],
              [
                -73.92225617599988,
                40.68036776399987
              ],
              [
                -73.92227774199988,
                40.6804762929999
              ],
              [
                -73.92231261899987,
                40.68064847299997
              ],
              [
                -73.92233849599998,
                40.680776217999885
              ],
              [
                -73.92235458599998,
                40.68085564499992
              ],
              [
                -73.92236962399983,
                40.68092987999992
              ],
              [
                -73.92238256000003,
                40.68099373699995
              ],
              [
                -73.92240197099996,
                40.68108955299994
              ],
              [
                -73.92242652099985,
                40.68121074499994
              ],
              [
                -73.92249266199997,
                40.681616108999926
              ],
              [
                -73.92250799299985,
                40.68171006999997
              ],
              [
                -73.9225447959999,
                40.68193561299989
              ],
              [
                -73.92267012199994,
                40.68268151199991
              ],
              [
                -73.92281374299995,
                40.68341442999991
              ],
              [
                -73.92609461699992,
                40.68303870999989
              ],
              [
                -73.92900883999985,
                40.68270290499991
              ],
              [
                -73.92916130699992,
                40.68343632399992
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000158238169004,
        "objectid": 62,
        "shape_leng": 0.0822593359838,
        "location_id": 62,
        "zone": "Crown Heights South",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.92916752199997,
                40.66429953199991
              ],
              [
                -73.92905235499991,
                40.66435100699988
              ],
              [
                -73.92872204599985,
                40.66449556899988
              ],
              [
                -73.92869200600002,
                40.66496229699995
              ],
              [
                -73.92868404499993,
                40.66508602299991
              ],
              [
                -73.92930683899975,
                40.66511700699991
              ],
              [
                -73.93145549999988,
                40.665233817999884
              ],
              [
                -73.93422426299996,
                40.665377742999915
              ],
              [
                -73.93698362399992,
                40.665535507999955
              ],
              [
                -73.93694519299983,
                40.66598129699993
              ],
              [
                -73.93690681099987,
                40.66642658999991
              ],
              [
                -73.93686802199987,
                40.66684982899991
              ],
              [
                -73.9368252179999,
                40.66731699399985
              ],
              [
                -73.93674946999998,
                40.668127370999926
              ],
              [
                -73.93951520799992,
                40.66829561599992
              ],
              [
                -73.94228420399995,
                40.66846142699991
              ],
              [
                -73.94504645700006,
                40.66861461599996
              ],
              [
                -73.94781857499984,
                40.668783753999904
              ],
              [
                -73.94775110299997,
                40.669495265999934
              ],
              [
                -73.9477299629999,
                40.66971344199991
              ],
              [
                -73.9505004649999,
                40.66986833099987
              ],
              [
                -73.95160495999986,
                40.66992371899992
              ],
              [
                -73.95327019699985,
                40.67000720499991
              ],
              [
                -73.95526963699997,
                40.6701249049999
              ],
              [
                -73.957973172,
                40.67065727399989
              ],
              [
                -73.95904725399988,
                40.67088226799995
              ],
              [
                -73.96131877999991,
                40.671406675999876
              ],
              [
                -73.96258784899996,
                40.671711596999906
              ],
              [
                -73.96254108199987,
                40.67146011899995
              ],
              [
                -73.96252326599993,
                40.67137973899987
              ],
              [
                -73.96219447199988,
                40.669641029999866
              ],
              [
                -73.96211021099995,
                40.66919569699998
              ],
              [
                -73.96199657599993,
                40.668662869999906
              ],
              [
                -73.96180658699991,
                40.66768122699988
              ],
              [
                -73.96161627599987,
                40.66669521599989
              ],
              [
                -73.96125791699995,
                40.664801822999955
              ],
              [
                -73.96110886899996,
                40.664062323999914
              ],
              [
                -73.96095595499996,
                40.66328505499992
              ],
              [
                -73.96061544799986,
                40.663302749999936
              ],
              [
                -73.96033682899981,
                40.664108697999964
              ],
              [
                -73.95934422499984,
                40.664175646999915
              ],
              [
                -73.95787061899992,
                40.66427335799993
              ],
              [
                -73.95729172699991,
                40.66430263299986
              ],
              [
                -73.95720935499996,
                40.66351739699992
              ],
              [
                -73.95384851099975,
                40.66372780899991
              ],
              [
                -73.9509741009999,
                40.66390486799992
              ],
              [
                -73.9494063899999,
                40.66400530799991
              ],
              [
                -73.94816604199978,
                40.664081821999964
              ],
              [
                -73.94546293699983,
                40.6642261039999
              ],
              [
                -73.94489227700002,
                40.66417414399994
              ],
              [
                -73.94408198699985,
                40.664128572999914
              ],
              [
                -73.94269628999989,
                40.664055128999955
              ],
              [
                -73.94282892199992,
                40.6628474859999
              ],
              [
                -73.94002976999997,
                40.66302074299991
              ],
              [
                -73.93726377600001,
                40.66319183299991
              ],
              [
                -73.93455236699992,
                40.6633622859999
              ],
              [
                -73.93437459299999,
                40.663372810999896
              ],
              [
                -73.93160743400001,
                40.66355672299988
              ],
              [
                -73.93068465499985,
                40.663620476999924
              ],
              [
                -73.92987364799984,
                40.66399077299993
              ],
              [
                -73.9294035289999,
                40.664199845999846
              ],
              [
                -73.92916752199997,
                40.66429953199991
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000353190910142,
        "objectid": 63,
        "shape_leng": 0.119100721883,
        "location_id": 63,
        "zone": "Cypress Hills",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.86650407899992,
                40.68447405199987
              ],
              [
                -73.86659848299999,
                40.68526955899993
              ],
              [
                -73.86687058599995,
                40.686330590999944
              ],
              [
                -73.86701802999985,
                40.686830348999884
              ],
              [
                -73.86728057399986,
                40.68774928499991
              ],
              [
                -73.867460889,
                40.68841501799992
              ],
              [
                -73.86762340399984,
                40.6891453419999
              ],
              [
                -73.86776832199985,
                40.68980328899988
              ],
              [
                -73.867909485,
                40.69047626099993
              ],
              [
                -73.86805885299988,
                40.69118395799991
              ],
              [
                -73.86859478099983,
                40.693884374999854
              ],
              [
                -73.86868454299989,
                40.69403469199989
              ],
              [
                -73.8684248969999,
                40.69471811899994
              ],
              [
                -73.86891704399994,
                40.69515042299987
              ],
              [
                -73.870042929,
                40.694941484999966
              ],
              [
                -73.87402053199992,
                40.69419129499994
              ],
              [
                -73.87939315099985,
                40.69120963199989
              ],
              [
                -73.87950641199996,
                40.691146768999886
              ],
              [
                -73.87956139399984,
                40.691104780999936
              ],
              [
                -73.88118296199987,
                40.68986626999992
              ],
              [
                -73.88226665399998,
                40.689038550999896
              ],
              [
                -73.88377698600002,
                40.68786341099992
              ],
              [
                -73.88452250999984,
                40.68668474899988
              ],
              [
                -73.88728392899995,
                40.68562247499988
              ],
              [
                -73.88808341799984,
                40.68529363199984
              ],
              [
                -73.8890889529999,
                40.68464055299995
              ],
              [
                -73.88962787599998,
                40.6842364539999
              ],
              [
                -73.89015418699982,
                40.68500398999987
              ],
              [
                -73.8916601069998,
                40.6840441779999
              ],
              [
                -73.89252316799995,
                40.68342453299988
              ],
              [
                -73.89274142399994,
                40.68367018599988
              ],
              [
                -73.89404051299982,
                40.68513229799986
              ],
              [
                -73.89414204200001,
                40.6852465669999
              ],
              [
                -73.89417463299992,
                40.6852832479999
              ],
              [
                -73.89504926899988,
                40.68436259899988
              ],
              [
                -73.89530504899982,
                40.684080519999966
              ],
              [
                -73.89554859399983,
                40.68379189199992
              ],
              [
                -73.89561476499993,
                40.683707421999955
              ],
              [
                -73.89577945499994,
                40.6834971829999
              ],
              [
                -73.89599727299998,
                40.68319687899986
              ],
              [
                -73.89620169599988,
                40.68289148199994
              ],
              [
                -73.89628634699976,
                40.6827583829999
              ],
              [
                -73.89635898799989,
                40.68262086699985
              ],
              [
                -73.896367991,
                40.682599756999885
              ],
              [
                -73.89641909099993,
                40.68247987599993
              ],
              [
                -73.89643010399995,
                40.68244635899989
              ],
              [
                -73.89646625099994,
                40.682336421999885
              ],
              [
                -73.89652633199988,
                40.68200291799991
              ],
              [
                -73.89658158699996,
                40.6815747179999
              ],
              [
                -73.89663536799998,
                40.681556494999924
              ],
              [
                -73.89674965999993,
                40.68149202599987
              ],
              [
                -73.89687298299991,
                40.68089528499995
              ],
              [
                -73.89692154999993,
                40.68078161299992
              ],
              [
                -73.89697886299997,
                40.68067032699992
              ],
              [
                -73.89704472199993,
                40.68056181599997
              ],
              [
                -73.89711889399996,
                40.68045646099994
              ],
              [
                -73.89720112199988,
                40.680354629999904
              ],
              [
                -73.89729111699982,
                40.68025667899985
              ],
              [
                -73.89738856299982,
                40.680162951999904
              ],
              [
                -73.89749312100001,
                40.6800737759999
              ],
              [
                -73.89767194200002,
                40.67996885399991
              ],
              [
                -73.89786299899986,
                40.67987501099987
              ],
              [
                -73.89806444599996,
                40.67979343099989
              ],
              [
                -73.89827418999997,
                40.6797250429999
              ],
              [
                -73.898489978,
                40.679670508999926
              ],
              [
                -73.89870944799999,
                40.6796301699999
              ],
              [
                -73.89889928299989,
                40.67956093399993
              ],
              [
                -73.89969830299987,
                40.67954409199993
              ],
              [
                -73.90047001100002,
                40.679535243999936
              ],
              [
                -73.90075235999984,
                40.67967664399993
              ],
              [
                -73.9026226229999,
                40.6806645029999
              ],
              [
                -73.90358874799992,
                40.67967480399991
              ],
              [
                -73.90404639799989,
                40.679220597999866
              ],
              [
                -73.90402950499993,
                40.679213539999914
              ],
              [
                -73.90400588299991,
                40.6792036709999
              ],
              [
                -73.903630796,
                40.679046955999915
              ],
              [
                -73.90333723699995,
                40.678924302999874
              ],
              [
                -73.90333666199992,
                40.6789239429999
              ],
              [
                -73.90324287399993,
                40.678865050999896
              ],
              [
                -73.9031631289999,
                40.6788149779999
              ],
              [
                -73.90287079599995,
                40.6786314149999
              ],
              [
                -73.9023946,
                40.67833239799992
              ],
              [
                -73.90239451899986,
                40.67833234699989
              ],
              [
                -73.90236198199986,
                40.67831191499991
              ],
              [
                -73.90199214699999,
                40.678079679999925
              ],
              [
                -73.90198538099985,
                40.678075431999936
              ],
              [
                -73.90187352500004,
                40.67800519199989
              ],
              [
                -73.90144574099996,
                40.67773656199995
              ],
              [
                -73.90142169599987,
                40.67772146199992
              ],
              [
                -73.90140356899998,
                40.67770996299991
              ],
              [
                -73.90136992499991,
                40.6776886199999
              ],
              [
                -73.90099766899989,
                40.67745246799996
              ],
              [
                -73.9005649959999,
                40.6771779849999
              ],
              [
                -73.90014864399993,
                40.676949315999906
              ],
              [
                -73.9000462609999,
                40.67689308999995
              ],
              [
                -73.89992296499994,
                40.676949272999856
              ],
              [
                -73.89912562799992,
                40.677312637999925
              ],
              [
                -73.89883201499994,
                40.67744643999987
              ],
              [
                -73.89836229199989,
                40.677658275999875
              ],
              [
                -73.898199615,
                40.677761731999944
              ],
              [
                -73.89783505699992,
                40.67799357599994
              ],
              [
                -73.89775655500002,
                40.67806072099995
              ],
              [
                -73.89761600299984,
                40.67811198799991
              ],
              [
                -73.89747545199997,
                40.67816325499988
              ],
              [
                -73.89695521899982,
                40.67840924299985
              ],
              [
                -73.8965367649999,
                40.67859358099987
              ],
              [
                -73.89621773699986,
                40.67735818399991
              ],
              [
                -73.89531770399991,
                40.67749603799995
              ],
              [
                -73.8943888149999,
                40.677629888999896
              ],
              [
                -73.89348405399988,
                40.677762060999896
              ],
              [
                -73.89257905399982,
                40.67790012399996
              ],
              [
                -73.89167121099989,
                40.678031720999876
              ],
              [
                -73.89077319200003,
                40.67816458899992
              ],
              [
                -73.88987439199998,
                40.67830214999987
              ],
              [
                -73.88900569799982,
                40.678557043999916
              ],
              [
                -73.8881563449999,
                40.678827834999936
              ],
              [
                -73.88730460899997,
                40.679097536999976
              ],
              [
                -73.88643790499991,
                40.67937347599991
              ],
              [
                -73.88555502399997,
                40.67965659699994
              ],
              [
                -73.88467046600006,
                40.67994028499991
              ],
              [
                -73.88489254699988,
                40.68081111599984
              ],
              [
                -73.88397193799985,
                40.680947114999874
              ],
              [
                -73.88303083299995,
                40.68108517499994
              ],
              [
                -73.88210725299986,
                40.68121749099988
              ],
              [
                -73.88116730300004,
                40.68135366299988
              ],
              [
                -73.88109834200004,
                40.68107447699987
              ],
              [
                -73.88063900599983,
                40.679176984999856
              ],
              [
                -73.88061377799993,
                40.67911003199989
              ],
              [
                -73.88015110700003,
                40.679242062999926
              ],
              [
                -73.87974063299994,
                40.679360960999894
              ],
              [
                -73.87926692499995,
                40.67949108499991
              ],
              [
                -73.87862924200002,
                40.67967653299996
              ],
              [
                -73.87838855899997,
                40.67973905399991
              ],
              [
                -73.877426862,
                40.68001443699991
              ],
              [
                -73.87688955199985,
                40.68016499699994
              ],
              [
                -73.87657495299996,
                40.68016700199996
              ],
              [
                -73.8755670759999,
                40.68016946499994
              ],
              [
                -73.87531627599998,
                40.68011098399988
              ],
              [
                -73.87507136399991,
                40.68003514599991
              ],
              [
                -73.87483538999999,
                40.67994256999988
              ],
              [
                -73.87461126400001,
                40.67983425399998
              ],
              [
                -73.87440161999989,
                40.67971160899988
              ],
              [
                -73.87420866699985,
                40.67957633999987
              ],
              [
                -73.87404657399992,
                40.67942964799988
              ],
              [
                -73.87389692799991,
                40.67927472199993
              ],
              [
                -73.87376075799996,
                40.6791124969999
              ],
              [
                -73.87363895999985,
                40.678944030999894
              ],
              [
                -73.87353219499991,
                40.67877042099988
              ],
              [
                -73.87342767099986,
                40.67861884699991
              ],
              [
                -73.87331183100002,
                40.678471800999894
              ],
              [
                -73.87318521799988,
                40.678330001999946
              ],
              [
                -73.87304845699995,
                40.678194106999904
              ],
              [
                -73.87257015500002,
                40.677866609999896
              ],
              [
                -73.87231018000001,
                40.67775574699989
              ],
              [
                -73.87204099299996,
                40.67765741899996
              ],
              [
                -73.87176417499987,
                40.67757222799988
              ],
              [
                -73.87148141800003,
                40.67750066299993
              ],
              [
                -73.870458215,
                40.67722236799989
              ],
              [
                -73.86977606799982,
                40.67702720899994
              ],
              [
                -73.86942312899993,
                40.67691222799995
              ],
              [
                -73.86838105899999,
                40.67653059899986
              ],
              [
                -73.86725717699991,
                40.67611268399987
              ],
              [
                -73.86627349499993,
                40.675764044999944
              ],
              [
                -73.86521514199991,
                40.675386573999944
              ],
              [
                -73.86455313199994,
                40.67515112199992
              ],
              [
                -73.86413333499986,
                40.67499725199989
              ],
              [
                -73.86310051,
                40.674647212999936
              ],
              [
                -73.86176409699985,
                40.67423225999992
              ],
              [
                -73.86106294999989,
                40.67434293499984
              ],
              [
                -73.86131863599985,
                40.6750212689999
              ],
              [
                -73.86139775899983,
                40.67533658999988
              ],
              [
                -73.86171238699991,
                40.676610595999854
              ],
              [
                -73.86202843099984,
                40.677886113999875
              ],
              [
                -73.86225943099993,
                40.67881678599989
              ],
              [
                -73.86234580499983,
                40.67916478599995
              ],
              [
                -73.86296470699986,
                40.67911281599991
              ],
              [
                -73.86328640800004,
                40.679077196999856
              ],
              [
                -73.86330964200002,
                40.679167969999874
              ],
              [
                -73.86334943599995,
                40.6793516399999
              ],
              [
                -73.86378674500001,
                40.68111374699991
              ],
              [
                -73.86399008800004,
                40.681928575999954
              ],
              [
                -73.86410096700003,
                40.682372849999894
              ],
              [
                -73.8649940449999,
                40.68212896999992
              ],
              [
                -73.86602666799992,
                40.68191805199995
              ],
              [
                -73.86629002999987,
                40.68311500699992
              ],
              [
                -73.86630263900003,
                40.68318669399985
              ],
              [
                -73.86631496899999,
                40.683248942999924
              ],
              [
                -73.86650407899992,
                40.68447405199987
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.00105790284614,
        "objectid": 64,
        "shape_leng": 0.18445188474,
        "location_id": 64,
        "zone": "Douglaston",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.74412314584049,
                40.77863865777707
              ],
              [
                -73.7452105881078,
                40.77932655009351
              ],
              [
                -73.74706767699601,
                40.7805455321257
              ],
              [
                -73.75029101900083,
                40.78248292020052
              ],
              [
                -73.75080593606877,
                40.78289337883397
              ],
              [
                -73.75245962687079,
                40.7817810905684
              ],
              [
                -73.75274786895811,
                40.78151808427811
              ],
              [
                -73.75299839296423,
                40.7812894895221
              ],
              [
                -73.75300812549725,
                40.780866075188506
              ],
              [
                -73.75353040031737,
                40.78023400238808
              ],
              [
                -73.75390659784934,
                40.77977871026392
              ],
              [
                -73.75393275467012,
                40.77976261058041
              ],
              [
                -73.75402624009541,
                40.77960387939476
              ],
              [
                -73.75412964083071,
                40.77938414323761
              ],
              [
                -73.75413143029364,
                40.77914672865191
              ],
              [
                -73.75411171878478,
                40.77902456898705
              ],
              [
                -73.75412244438803,
                40.77897174125879
              ],
              [
                -73.75423069482916,
                40.77871529279238
              ],
              [
                -73.754248170366,
                40.77867296925754
              ],
              [
                -73.75426250967827,
                40.778632963288175
              ],
              [
                -73.75427379768243,
                40.778594959447695
              ],
              [
                -73.75428214069518,
                40.778558516029946
              ],
              [
                -73.75428865261564,
                40.778517935169106
              ],
              [
                -73.754300063634,
                40.778417615914705
              ],
              [
                -73.75430183540871,
                40.778406111489865
              ],
              [
                -73.75437336549648,
                40.77826269398068
              ],
              [
                -73.7544419628204,
                40.77812515653839
              ],
              [
                -73.75464981317504,
                40.777992106294526
              ],
              [
                -73.75448452828304,
                40.777904467981585
              ],
              [
                -73.75455791105837,
                40.77777659066429
              ],
              [
                -73.75457883060362,
                40.77775777932316
              ],
              [
                -73.75460152243082,
                40.77773986165084
              ],
              [
                -73.75464068611555,
                40.77771048072843
              ],
              [
                -73.75466391587001,
                40.77769477151286
              ],
              [
                -73.75468724014247,
                40.77768181762751
              ],
              [
                -73.754710055367,
                40.77767238421958
              ],
              [
                -73.75478633943224,
                40.777647846029474
              ],
              [
                -73.75481217058518,
                40.77763599592199
              ],
              [
                -73.75542214211723,
                40.777653516414986
              ],
              [
                -73.7554200211087,
                40.77770088125018
              ],
              [
                -73.75551737581792,
                40.77770404836418
              ],
              [
                -73.75551781604716,
                40.777584144019926
              ],
              [
                -73.75542434578475,
                40.77758394567129
              ],
              [
                -73.75541831287357,
                40.77763574482788
              ],
              [
                -73.75481322893425,
                40.777619032305594
              ],
              [
                -73.75481517188274,
                40.7776130205783
              ],
              [
                -73.754849296987,
                40.777507474691795
              ],
              [
                -73.75484180870602,
                40.77748504846198
              ],
              [
                -73.75483577692417,
                40.777466986201695
              ],
              [
                -73.75489438325314,
                40.77720167735624
              ],
              [
                -73.75489145178453,
                40.77719031412209
              ],
              [
                -73.75489082616622,
                40.777178744208726
              ],
              [
                -73.7548925209316,
                40.77716723641656
              ],
              [
                -73.75489649670558,
                40.777156058102484
              ],
              [
                -73.75490266111939,
                40.77714546896862
              ],
              [
                -73.75491087095614,
                40.77713571502861
              ],
              [
                -73.75492093547855,
                40.77712702289251
              ],
              [
                -73.75494005840592,
                40.777113909617135
              ],
              [
                -73.75495610373531,
                40.77709859610115
              ],
              [
                -73.75496863967113,
                40.777081494448325
              ],
              [
                -73.75497732886075,
                40.777063064882405
              ],
              [
                -73.75498193747218,
                40.77704380336285
              ],
              [
                -73.7549398382652,
                40.77702473832618
              ],
              [
                -73.75491970109897,
                40.77701572527611
              ],
              [
                -73.75490167563905,
                40.777004420176
              ],
              [
                -73.75488725186452,
                40.77699214601451
              ],
              [
                -73.75487533188723,
                40.77697840659673
              ],
              [
                -73.75486616959907,
                40.77696349456987
              ],
              [
                -73.75485996015284,
                40.776947727557314
              ],
              [
                -73.75485683580645,
                40.77693144139309
              ],
              [
                -73.75485685860569,
                40.77690984805279
              ],
              [
                -73.75486088379957,
                40.77688847260642
              ],
              [
                -73.75486883106046,
                40.77686774160921
              ],
              [
                -73.75488054179407,
                40.77684806875529
              ],
              [
                -73.75489578230594,
                40.776829846623706
              ],
              [
                -73.7549385058147,
                40.77676498578116
              ],
              [
                -73.7549684849082,
                40.7767074476952
              ],
              [
                -73.75497549376992,
                40.776698447181914
              ],
              [
                -73.75502286717963,
                40.77663761615085
              ],
              [
                -73.75506630223659,
                40.77660067868262
              ],
              [
                -73.75510694138278,
                40.77658842240476
              ],
              [
                -73.75517697141768,
                40.77654367371668
              ],
              [
                -73.75518575394315,
                40.77649807083022
              ],
              [
                -73.75516154144688,
                40.77645893085605
              ],
              [
                -73.75513664233078,
                40.77640719547497
              ],
              [
                -73.75509684051448,
                40.77637732374282
              ],
              [
                -73.75501053125387,
                40.77631254606013
              ],
              [
                -73.7549295876917,
                40.776246542710915
              ],
              [
                -73.75488688128334,
                40.77618932212071
              ],
              [
                -73.7548569144569,
                40.776139412150236
              ],
              [
                -73.75485431476889,
                40.77611060485996
              ],
              [
                -73.75483825982116,
                40.77606119707094
              ],
              [
                -73.75481957405265,
                40.77599121013415
              ],
              [
                -73.75480082727547,
                40.77593768284009
              ],
              [
                -73.75480097120251,
                40.775898595282
              ],
              [
                -73.75475233563776,
                40.77587791923645
              ],
              [
                -73.754709121297,
                40.77585519732233
              ],
              [
                -73.7546604244237,
                40.77583628448718
              ],
              [
                -73.75457996749098,
                40.77571897604083
              ],
              [
                -73.754571582022,
                40.775706749044225
              ],
              [
                -73.75454560558667,
                40.77568395070163
              ],
              [
                -73.75448302120184,
                40.775629023427626
              ],
              [
                -73.75442248917767,
                40.77554919354541
              ],
              [
                -73.75433349266498,
                40.77541954835595
              ],
              [
                -73.75424149999385,
                40.77526683036529
              ],
              [
                -73.75427404182139,
                40.7751842356672
              ],
              [
                -73.7541895302817,
                40.77512995803968
              ],
              [
                -73.75418344182957,
                40.77511851809306
              ],
              [
                -73.75414415458538,
                40.77504467904683
              ],
              [
                -73.7541211549045,
                40.774917483321616
              ],
              [
                -73.75410406104969,
                40.77471047414805
              ],
              [
                -73.75402061841987,
                40.77451085549081
              ],
              [
                -73.75400596724323,
                40.77446940655343
              ],
              [
                -73.75398027625992,
                40.77439672509204
              ],
              [
                -73.75395573939913,
                40.77430620907518
              ],
              [
                -73.75391367176762,
                40.77427246809169
              ],
              [
                -73.75383615718921,
                40.77422433743276
              ],
              [
                -73.75380768158753,
                40.77414573010844
              ],
              [
                -73.75375277248573,
                40.77405556092615
              ],
              [
                -73.75367969121629,
                40.77397297882969
              ],
              [
                -73.75363972748997,
                40.7738668796444
              ],
              [
                -73.7536224771741,
                40.7738222384132
              ],
              [
                -73.75356167498403,
                40.77366488473272
              ],
              [
                -73.75347847944079,
                40.77348991346416
              ],
              [
                -73.7533923720637,
                40.773272201808666
              ],
              [
                -73.75336691999294,
                40.773207847763366
              ],
              [
                -73.75338994029593,
                40.773056448326535
              ],
              [
                -73.75340985812166,
                40.7729591748167
              ],
              [
                -73.75345192598736,
                40.77286268480925
              ],
              [
                -73.75350609831754,
                40.77276955713254
              ],
              [
                -73.75357000906136,
                40.77269461612926
              ],
              [
                -73.75363974518132,
                40.7726316345095
              ],
              [
                -73.75372221291418,
                40.77257021795102
              ],
              [
                -73.7538086391264,
                40.772504431493566
              ],
              [
                -73.75387756050716,
                40.772454887276105
              ],
              [
                -73.75392578468146,
                40.772419465504065
              ],
              [
                -73.75397521229507,
                40.772383121369145
              ],
              [
                -73.75403331561613,
                40.77233837754103
              ],
              [
                -73.75419859120392,
                40.772201766666036
              ],
              [
                -73.75429600282432,
                40.77213033703644
              ],
              [
                -73.75438074757537,
                40.772041271642195
              ],
              [
                -73.75470604943379,
                40.77187073775201
              ],
              [
                -73.75485465862842,
                40.77179791526068
              ],
              [
                -73.75501054255479,
                40.771737511519824
              ],
              [
                -73.75505920386043,
                40.77171917469339
              ],
              [
                -73.7550805011998,
                40.77183567146362
              ],
              [
                -73.75507498743053,
                40.77183607855887
              ],
              [
                -73.7550738686906,
                40.77184026684918
              ],
              [
                -73.75511523562321,
                40.77183490648697
              ],
              [
                -73.7551141490279,
                40.771830713509175
              ],
              [
                -73.75510753193235,
                40.77183111826108
              ],
              [
                -73.75508267713634,
                40.77171322899481
              ],
              [
                -73.75525555666594,
                40.77165743870149
              ],
              [
                -73.75542423703571,
                40.77164213534985
              ],
              [
                -73.75550146510624,
                40.77159968041306
              ],
              [
                -73.7555733264932,
                40.7715291259227
              ],
              [
                -73.7555614028682,
                40.77144869926293
              ],
              [
                -73.75550243961897,
                40.771282509092494
              ],
              [
                -73.75542237803558,
                40.771186687391356
              ],
              [
                -73.75540541388642,
                40.77115189018926
              ],
              [
                -73.75534216255899,
                40.77102215324634
              ],
              [
                -73.75539741111736,
                40.77100546716244
              ],
              [
                -73.75540180261751,
                40.77101727887619
              ],
              [
                -73.75553498728895,
                40.77098552649437
              ],
              [
                -73.75551295786343,
                40.77094501395632
              ],
              [
                -73.75539975859014,
                40.77097006430211
              ],
              [
                -73.75540415008778,
                40.770981876183406
              ],
              [
                -73.75533279459194,
                40.770998154653235
              ],
              [
                -73.75520665378215,
                40.770675053601934
              ],
              [
                -73.75490125042909,
                40.770080558437456
              ],
              [
                -73.75487228166313,
                40.770028017196985
              ],
              [
                -73.75481298260993,
                40.769961267166636
              ],
              [
                -73.75478053634816,
                40.769924469489084
              ],
              [
                -73.75476377580713,
                40.76989966354961
              ],
              [
                -73.75475485442077,
                40.769881708192834
              ],
              [
                -73.75475122821963,
                40.76987215750775
              ],
              [
                -73.75474706538547,
                40.76986119159111
              ],
              [
                -73.75472685555812,
                40.76980291978894
              ],
              [
                -73.75472483218735,
                40.76979708338938
              ],
              [
                -73.75472038781855,
                40.76978340730228
              ],
              [
                -73.75470593650574,
                40.769741522945246
              ],
              [
                -73.7547019742048,
                40.769728643298386
              ],
              [
                -73.75469594529733,
                40.769709044463255
              ],
              [
                -73.75469044476876,
                40.76967742908768
              ],
              [
                -73.7546863132379,
                40.76964510966561
              ],
              [
                -73.75468498470264,
                40.7696347108263
              ],
              [
                -73.75468418709654,
                40.76962739493064
              ],
              [
                -73.75468172347436,
                40.76960480865059
              ],
              [
                -73.75468068508523,
                40.76958174393441
              ],
              [
                -73.75468301130525,
                40.76956039582006
              ],
              [
                -73.75469444943913,
                40.76950490045574
              ],
              [
                -73.75469914166756,
                40.7694510984908
              ],
              [
                -73.7546992140291,
                40.76943145283766
              ],
              [
                -73.7546981632071,
                40.769412660014815
              ],
              [
                -73.75469736216037,
                40.76940669722155
              ],
              [
                -73.75468613309415,
                40.769322948032375
              ],
              [
                -73.75468202222558,
                40.76930279223348
              ],
              [
                -73.75467619953538,
                40.76927424106259
              ],
              [
                -73.75467563707237,
                40.769261714427074
              ],
              [
                -73.75467516247359,
                40.7692511768507
              ],
              [
                -73.75467633797275,
                40.76923665968261
              ],
              [
                -73.75467753501032,
                40.76922867776093
              ],
              [
                -73.75467864238544,
                40.76922128852384
              ],
              [
                -73.75468757418822,
                40.76919361460783
              ],
              [
                -73.75469682582235,
                40.769164953301846
              ],
              [
                -73.75472073687521,
                40.76907873671095
              ],
              [
                -73.75472987183133,
                40.76903861032416
              ],
              [
                -73.7547333705779,
                40.76900359807562
              ],
              [
                -73.75473311585843,
                40.769001801986654
              ],
              [
                -73.75473227688181,
                40.76899590763074
              ],
              [
                -73.75473175595867,
                40.7689922626574
              ],
              [
                -73.75473118097804,
                40.76898821835379
              ],
              [
                -73.75471446004602,
                40.768952308342115
              ],
              [
                -73.75471342579756,
                40.76894965687942
              ],
              [
                -73.754711122394,
                40.768943759573375
              ],
              [
                -73.75471113632251,
                40.768939977864335
              ],
              [
                -73.75471115070579,
                40.76893607268948
              ],
              [
                -73.75471143383741,
                40.768859199997834
              ],
              [
                -73.75471296984011,
                40.76884132502884
              ],
              [
                -73.75471495394928,
                40.76881820744063
              ],
              [
                -73.75471580642598,
                40.76880769195444
              ],
              [
                -73.75471613163991,
                40.76880368910325
              ],
              [
                -73.75471586026373,
                40.76880306549521
              ],
              [
                -73.75471501686157,
                40.76880112592305
              ],
              [
                -73.7547150243261,
                40.76879909920572
              ],
              [
                -73.7547150263036,
                40.768798562288225
              ],
              [
                -73.75471391687849,
                40.76879514389752
              ],
              [
                -73.75471167921705,
                40.76879257715399
              ],
              [
                -73.7547072041168,
                40.76878744316482
              ],
              [
                -73.7547051792433,
                40.768786151581835
              ],
              [
                -73.75470047988182,
                40.76878315692828
              ],
              [
                -73.75469375453984,
                40.76877887236429
              ],
              [
                -73.75469052084651,
                40.76877745792353
              ],
              [
                -73.75468590272664,
                40.768775438948126
              ],
              [
                -73.75468005191348,
                40.76877415513362
              ],
              [
                -73.75467804330147,
                40.76877371327814
              ],
              [
                -73.75467639031449,
                40.7687733958143
              ],
              [
                -73.75467355401318,
                40.76877285067542
              ],
              [
                -73.75467018424933,
                40.76877284350028
              ],
              [
                -73.75460276521305,
                40.768775261905965
              ],
              [
                -73.75447054668635,
                40.76878396817526
              ],
              [
                -73.75446454713332,
                40.76878435996597
              ],
              [
                -73.75446451187187,
                40.76878436156604
              ],
              [
                -73.75446197302739,
                40.76878435833345
              ],
              [
                -73.75445218896665,
                40.768784337482295
              ],
              [
                -73.75444320350958,
                40.76878346361306
              ],
              [
                -73.75444246049993,
                40.768783462029525
              ],
              [
                -73.7544387095356,
                40.76878345403517
              ],
              [
                -73.7544263612727,
                40.76878086523446
              ],
              [
                -73.75441513992966,
                40.76877742461781
              ],
              [
                -73.75441292701873,
                40.76877621605693
              ],
              [
                -73.75440729062186,
                40.76877313646869
              ],
              [
                -73.7544039271558,
                40.768771421536016
              ],
              [
                -73.75440056618083,
                40.768768851889384
              ],
              [
                -73.75439832787339,
                40.76876628463585
              ],
              [
                -73.75439716604434,
                40.76876495149782
              ],
              [
                -73.75439609110637,
                40.768763717888106
              ],
              [
                -73.75439497568617,
                40.76876115302812
              ],
              [
                -73.75439497799368,
                40.76876052732227
              ],
              [
                -73.75439497838661,
                40.76876042077634
              ],
              [
                -73.75439497883637,
                40.76876029881814
              ],
              [
                -73.75439498828825,
                40.768757735853434
              ],
              [
                -73.75439432480357,
                40.76875517078406
              ],
              [
                -73.7543938826934,
                40.76875346274863
              ],
              [
                -73.75439438120902,
                40.768751228340335
              ],
              [
                -73.75439502293871,
                40.768748340047125
              ],
              [
                -73.75439547239391,
                40.768744710375145
              ],
              [
                -73.75439618245464,
                40.768738947811386
              ],
              [
                -73.75440071107668,
                40.76872956108202
              ],
              [
                -73.75440748905719,
                40.76871932610308
              ],
              [
                -73.75441538875721,
                40.768709947061325
              ],
              [
                -73.75441753972149,
                40.76870799577653
              ],
              [
                -73.75442666048079,
                40.76869972165973
              ],
              [
                -73.7544379299309,
                40.76869035097133
              ],
              [
                -73.75444506541723,
                40.76868540471995
              ],
              [
                -73.75445144581661,
                40.76868098339417
              ],
              [
                -73.75445332042013,
                40.76867994001487
              ],
              [
                -73.75447283530931,
                40.76866907061462
              ],
              [
                -73.7544891892807,
                40.76866107492287
              ],
              [
                -73.7544919691384,
                40.768659715004326
              ],
              [
                -73.75450546904293,
                40.76865461930753
              ],
              [
                -73.75450838321262,
                40.768654183079896
              ],
              [
                -73.75451109013949,
                40.768653778240434
              ],
              [
                -73.7545276689697,
                40.768649877030185
              ],
              [
                -73.75452908086366,
                40.76864954465059
              ],
              [
                -73.75453694839094,
                40.76864870836834
              ],
              [
                -73.75453734347198,
                40.76864870921003
              ],
              [
                -73.754538071276,
                40.76864871076057
              ],
              [
                -73.75454548351453,
                40.768648724876286
              ],
              [
                -73.75454705986645,
                40.76864872990984
              ],
              [
                -73.75455307361956,
                40.768649145119234
              ],
              [
                -73.75458524725445,
                40.76865137273348
              ],
              [
                -73.75459423181064,
                40.76865224759475
              ],
              [
                -73.75460658949802,
                40.76865227391533
              ],
              [
                -73.75461670478812,
                40.76865143973458
              ],
              [
                -73.75463798636613,
                40.76864763513515
              ],
              [
                -73.75464031279178,
                40.768647219095826
              ],
              [
                -73.75464656395906,
                40.7686453346698
              ],
              [
                -73.75466280690638,
                40.76864043359798
              ],
              [
                -73.75466855538552,
                40.76863815022539
              ],
              [
                -73.75468418375833,
                40.768631938623926
              ],
              [
                -73.7546923613104,
                40.768626915000965
              ],
              [
                -73.75470219963016,
                40.76862087283545
              ],
              [
                -73.75471684573888,
                40.76860980037276
              ],
              [
                -73.75472181615622,
                40.768604886853495
              ],
              [
                -73.75473150442745,
                40.76859531073351
              ],
              [
                -73.75474392479494,
                40.76857825384548
              ],
              [
                -73.75475315314952,
                40.768562586158325
              ],
              [
                -73.7547574915997,
                40.768555220214914
              ],
              [
                -73.75476068535333,
                40.76854827014798
              ],
              [
                -73.75476768394783,
                40.768533035794846
              ],
              [
                -73.75477459400017,
                40.768506719555276
              ],
              [
                -73.75478135898942,
                40.768480960701346
              ],
              [
                -73.75479042712017,
                40.76845877221112
              ],
              [
                -73.75479269036948,
                40.76845450678228
              ],
              [
                -73.75479533576191,
                40.76845184070275
              ],
              [
                -73.75479607269864,
                40.76845109677903
              ],
              [
                -73.75480058171519,
                40.768446834956706
              ],
              [
                -73.75480345733583,
                40.76844411827147
              ],
              [
                -73.75480509138609,
                40.76844257481101
              ],
              [
                -73.75484000164228,
                40.76842044080652
              ],
              [
                -73.75485002766149,
                40.76841361835499
              ],
              [
                -73.75485126381925,
                40.76841277782529
              ],
              [
                -73.75485577282537,
                40.76840851717354
              ],
              [
                -73.75485915829324,
                40.76840425295842
              ],
              [
                -73.75486084218902,
                40.76840107973981
              ],
              [
                -73.75486142307676,
                40.768399988034055
              ],
              [
                -73.75486256146147,
                40.768395718535245
              ],
              [
                -73.75486370005845,
                40.76839145121472
              ],
              [
                -73.7548637114065,
                40.76838758992499
              ],
              [
                -73.7548637157806,
                40.76838717982988
              ],
              [
                -73.7548632480079,
                40.76838537725672
              ],
              [
                -73.75486260862132,
                40.76838290622345
              ],
              [
                -73.7548624413177,
                40.768382264074205
              ],
              [
                -73.75486150013704,
                40.768378633451896
              ],
              [
                -73.75485605431479,
                40.76837029647758
              ],
              [
                -73.75485479639369,
                40.768368369761454
              ],
              [
                -73.75483802115824,
                40.7683478340406
              ],
              [
                -73.75483474618359,
                40.768342315121274
              ],
              [
                -73.75483243914007,
                40.768338426952276
              ],
              [
                -73.75483123007663,
                40.76833657656436
              ],
              [
                -73.75482908672076,
                40.768333295356385
              ],
              [
                -73.75482798293194,
                40.76832816687008
              ],
              [
                -73.75482798602786,
                40.76832732589455
              ],
              [
                -73.7548279986502,
                40.768323897160414
              ],
              [
                -73.75482801691055,
                40.768318936912685
              ],
              [
                -73.75482801752048,
                40.76831877123047
              ],
              [
                -73.75483258063379,
                40.76829999085431
              ],
              [
                -73.75484682303404,
                40.76826142661568
              ],
              [
                -73.75484960110137,
                40.76825390205139
              ],
              [
                -73.75485129960616,
                40.76824753732013
              ],
              [
                -73.75485529629613,
                40.76823256109786
              ],
              [
                -73.75485653531162,
                40.768226094370526
              ],
              [
                -73.754859878268,
                40.76820865361769
              ],
              [
                -73.7548627188635,
                40.7681803689903
              ],
              [
                -73.75486571084737,
                40.768150584319166
              ],
              [
                -73.75487060374897,
                40.768129242830305
              ],
              [
                -73.75487256100014,
                40.76812070533028
              ],
              [
                -73.75488164924215,
                40.7680933902343
              ],
              [
                -73.75489184864901,
                40.768069496881004
              ],
              [
                -73.7549042883991,
                40.768047315050715
              ],
              [
                -73.75491896445862,
                40.768027700961234
              ],
              [
                -73.75492457714904,
                40.76802110465698
              ],
              [
                -73.75492911896265,
                40.76801576419739
              ],
              [
                -73.75495617473564,
                40.767991051469664
              ],
              [
                -73.75499605461913,
                40.76784780377672
              ],
              [
                -73.7550760338338,
                40.76766374830883
              ],
              [
                -73.75507210849031,
                40.76753310678743
              ],
              [
                -73.75499536929894,
                40.76742938253471
              ],
              [
                -73.75499531479272,
                40.767429105162726
              ],
              [
                -73.75498535207142,
                40.76738893930633
              ],
              [
                -73.75497199993241,
                40.76735389170879
              ],
              [
                -73.75496084794494,
                40.7673316585225
              ],
              [
                -73.754945267647,
                40.76730393529235
              ],
              [
                -73.75492624631949,
                40.767270087181146
              ],
              [
                -73.75491614552455,
                40.767247640452226
              ],
              [
                -73.7548939308534,
                40.76719826891798
              ],
              [
                -73.75488893674114,
                40.7671882191101
              ],
              [
                -73.75488500979354,
                40.76718031356405
              ],
              [
                -73.75487271796067,
                40.76716234936266
              ],
              [
                -73.75483357366191,
                40.76711443378522
              ],
              [
                -73.75481902377297,
                40.767099883147864
              ],
              [
                -73.75481634567821,
                40.76709756139691
              ],
              [
                -73.75480222531259,
                40.767085326550436
              ],
              [
                -73.75473151276402,
                40.76703126304617
              ],
              [
                -73.75472380440347,
                40.767025369644536
              ],
              [
                -73.75470700774542,
                40.76701081303696
              ],
              [
                -73.75469245300037,
                40.766997115919565
              ],
              [
                -73.75466224335983,
                40.76696373965117
              ],
              [
                -73.75463428056361,
                40.766930368159684
              ],
              [
                -73.75460968591979,
                40.76689700433747
              ],
              [
                -73.75458846135213,
                40.76686450022993
              ],
              [
                -73.75454235546341,
                40.766789273815945
              ],
              [
                -73.75453709317965,
                40.766780685758185
              ],
              [
                -73.75453245940645,
                40.766772669969086
              ],
              [
                -73.75450248682485,
                40.76672082036146
              ],
              [
                -73.75445786021571,
                40.76664274408993
              ],
              [
                -73.754427699876,
                40.766589977357235
              ],
              [
                -73.75436854227796,
                40.76648564449461
              ],
              [
                -73.75435290758911,
                40.76646084037819
              ],
              [
                -73.75435199344946,
                40.766459690538554
              ],
              [
                -73.75433389504231,
                40.766436884781754
              ],
              [
                -73.75431151258437,
                40.76641377453746
              ],
              [
                -73.75428802400359,
                40.76639425537017
              ],
              [
                -73.75428575309054,
                40.766392366525565
              ],
              [
                -73.75427678686661,
                40.766386367717004
              ],
              [
                -73.75427395377056,
                40.76638474336994
              ],
              [
                -73.75426781969234,
                40.766381224127784
              ],
              [
                -73.75422631000248,
                40.766364907474944
              ],
              [
                -73.75421846070479,
                40.76636147302515
              ],
              [
                -73.75421061235566,
                40.766357184862485
              ],
              [
                -73.75420482305684,
                40.766353144340236
              ],
              [
                -73.75418483942393,
                40.766339193499206
              ],
              [
                -73.75416058406073,
                40.76632064599768
              ],
              [
                -73.75416019515129,
                40.76632034981886
              ],
              [
                -73.75415172628783,
                40.76631207437326
              ],
              [
                -73.7541400449881,
                40.766300661506726
              ],
              [
                -73.75413696709579,
                40.766297128508526
              ],
              [
                -73.75412214720791,
                40.766280123278335
              ],
              [
                -73.75411097902656,
                40.766262163065676
              ],
              [
                -73.75410302014075,
                40.76624383528004
              ],
              [
                -73.75410206777033,
                40.76624164351027
              ],
              [
                -73.75409438750937,
                40.76621886983374
              ],
              [
                -73.75409428749936,
                40.76621856488986
              ],
              [
                -73.7540765503842,
                40.76615446561802
              ],
              [
                -73.75406990996136,
                40.76613770902589
              ],
              [
                -73.7540687588374,
                40.76613480383936
              ],
              [
                -73.75405983454806,
                40.76611770246045
              ],
              [
                -73.75403639591704,
                40.76607665185295
              ],
              [
                -73.75402298892796,
                40.76605612487067
              ],
              [
                -73.75400845052455,
                40.76603815745189
              ],
              [
                -73.75399278071055,
                40.766022748423524
              ],
              [
                -73.75397933654801,
                40.766012470285034
              ],
              [
                -73.7539457200029,
                40.765989335978176
              ],
              [
                -73.75393115954603,
                40.76597734718356
              ],
              [
                -73.75388303331701,
                40.76592855787087
              ],
              [
                -73.75388209534087,
                40.76592760180446
              ],
              [
                -73.7538796747829,
                40.76592513349339
              ],
              [
                -73.75387692703067,
                40.76592355706362
              ],
              [
                -73.75387519046983,
                40.76592256193282
              ],
              [
                -73.75386677763164,
                40.76591733439259
              ],
              [
                -73.75385725748721,
                40.765911419470896
              ],
              [
                -73.753852845085,
                40.76590852859031
              ],
              [
                -73.75384941236669,
                40.765906278245616
              ],
              [
                -73.75383791548944,
                40.76589543549867
              ],
              [
                -73.75381582683036,
                40.76587460296937
              ],
              [
                -73.75378449274986,
                40.76584036431508
              ],
              [
                -73.75375655148282,
                40.76580272725989
              ],
              [
                -73.7537546208494,
                40.76579963528729
              ],
              [
                -73.75373198134588,
                40.765763384278856
              ],
              [
                -73.7537314575656,
                40.76576095067618
              ],
              [
                -73.7537308774602,
                40.76575825678467
              ],
              [
                -73.75372941146722,
                40.765754882015074
              ],
              [
                -73.75372864897498,
                40.765753127054154
              ],
              [
                -73.75372650076784,
                40.76572664491774
              ],
              [
                -73.75372586129843,
                40.76572266530189
              ],
              [
                -73.75372540254051,
                40.76571980866649
              ],
              [
                -73.75372317471823,
                40.76571467893723
              ],
              [
                -73.75370946721739,
                40.7656906742646
              ],
              [
                -73.75370754683831,
                40.76568731326578
              ],
              [
                -73.75369525780206,
                40.76566934893933
              ],
              [
                -73.75369215758916,
                40.76566698018959
              ],
              [
                -73.75369077667018,
                40.76566592383144
              ],
              [
                -73.75368517388588,
                40.765661640433045
              ],
              [
                -73.75367357800295,
                40.76565417244393
              ],
              [
                -73.75366387999607,
                40.765647928271214
              ],
              [
                -73.75366115798265,
                40.76564636730375
              ],
              [
                -73.7536593957098,
                40.765645356702194
              ],
              [
                -73.75365491524836,
                40.76564192991895
              ],
              [
                -73.75363589047707,
                40.76562224392221
              ],
              [
                -73.75361887109621,
                40.765599259763185
              ],
              [
                -73.75361689198796,
                40.76559658817567
              ],
              [
                -73.75359676377177,
                40.76557005769978
              ],
              [
                -73.75357662436602,
                40.765547806322985
              ],
              [
                -73.75355758134413,
                40.76553324473846
              ],
              [
                -73.75351274922001,
                40.765504961846815
              ],
              [
                -73.7535052704541,
                40.765498787241235
              ],
              [
                -73.75350496885727,
                40.765498536814036
              ],
              [
                -73.75350270270359,
                40.765496924047376
              ],
              [
                -73.75350218209316,
                40.765496554040325
              ],
              [
                -73.75349594446142,
                40.765492113977274
              ],
              [
                -73.75348713687973,
                40.765483131298055
              ],
              [
                -73.75347915705994,
                40.76547499522476
              ],
              [
                -73.75347120459557,
                40.765467088720534
              ],
              [
                -73.75346796389704,
                40.7654638671222
              ],
              [
                -73.75346684422311,
                40.765463011681874
              ],
              [
                -73.75345277791402,
                40.76544728653656
              ],
              [
                -73.75342969954757,
                40.76541716951095
              ],
              [
                -73.75341078980225,
                40.765387042137355
              ],
              [
                -73.75339682847972,
                40.76535743094706
              ],
              [
                -73.75338853072753,
                40.7653339455862
              ],
              [
                -73.75332826529235,
                40.7652671140945
              ],
              [
                -73.75326677589696,
                40.765186692385626
              ],
              [
                -73.75323764786424,
                40.76516072229637
              ],
              [
                -73.75321270194533,
                40.76513234667674
              ],
              [
                -73.75319227856545,
                40.765101952761405
              ],
              [
                -73.75314599997928,
                40.76503781195537
              ],
              [
                -73.75311373500153,
                40.76495318302931
              ],
              [
                -73.75307921070036,
                40.76487196427668
              ],
              [
                -73.75304571089994,
                40.76481722743512
              ],
              [
                -73.75298298856781,
                40.764766697677715
              ],
              [
                -73.75289327855934,
                40.7647229444119
              ],
              [
                -73.75281605035713,
                40.76464590422409
              ],
              [
                -73.75274102673858,
                40.76457997404059
              ],
              [
                -73.75266249865089,
                40.76455076377029
              ],
              [
                -73.75256704187835,
                40.76454201880534
              ],
              [
                -73.75251092750104,
                40.76452737691872
              ],
              [
                -73.7524785175615,
                40.76448203746583
              ],
              [
                -73.7524484210661,
                40.7644187655857
              ],
              [
                -73.75240147997198,
                40.76435374973257
              ],
              [
                -73.75234326679305,
                40.764298958896
              ],
              [
                -73.75225349976847,
                40.764270578401764
              ],
              [
                -73.75221773780626,
                40.7642209609137
              ],
              [
                -73.75222243034136,
                40.764167159004664
              ],
              [
                -73.75219219823434,
                40.76414061642908
              ],
              [
                -73.7521574815898,
                40.76411150121127
              ],
              [
                -73.75207014373261,
                40.76403443876416
              ],
              [
                -73.75197615773142,
                40.763932593321684
              ],
              [
                -73.7518283443928,
                40.763805860565974
              ],
              [
                -73.7516952345571,
                40.76365182736872
              ],
              [
                -73.75165082833455,
                40.76356157863645
              ],
              [
                -73.75165063764818,
                40.76356119174193
              ],
              [
                -73.75164899665232,
                40.76355787419641
              ],
              [
                -73.75163837349352,
                40.763536395011045
              ],
              [
                -73.75163834488852,
                40.763536325761045
              ],
              [
                -73.75163833199954,
                40.76353629608098
              ],
              [
                -73.75163503200241,
                40.7635286998513
              ],
              [
                -73.75162121796569,
                40.763492759268495
              ],
              [
                -73.75160045302414,
                40.763439191808985
              ],
              [
                -73.75156024509342,
                40.76331573008711
              ],
              [
                -73.75156943251743,
                40.76316082612466
              ],
              [
                -73.75153545083482,
                40.763038559211196
              ],
              [
                -73.75150995624048,
                40.762949040571364
              ],
              [
                -73.751420487896,
                40.76276169436682
              ],
              [
                -73.75132609064228,
                40.762629469068926
              ],
              [
                -73.75123100279916,
                40.76252249564248
              ],
              [
                -73.75110899242155,
                40.76240606640948
              ],
              [
                -73.75098356953296,
                40.76230073499874
              ],
              [
                -73.75084802828285,
                40.76219794405175
              ],
              [
                -73.75063847963764,
                40.76205911882313
              ],
              [
                -73.750570493653,
                40.7619154748215
              ],
              [
                -73.75046183009748,
                40.761834949415785
              ],
              [
                -73.75032282701994,
                40.76175692013944
              ],
              [
                -73.75018846125835,
                40.7616404648094
              ],
              [
                -73.75004042130583,
                40.76157608273963
              ],
              [
                -73.74979792102468,
                40.76153797410411
              ],
              [
                -73.74966102560238,
                40.7614975308269
              ],
              [
                -73.74947027461442,
                40.76143988860852
              ],
              [
                -73.74917394462378,
                40.76137774482793
              ],
              [
                -73.74917158863074,
                40.761406781424604
              ],
              [
                -73.74921977977574,
                40.7614367811098
              ],
              [
                -73.7492847562694,
                40.76148390115628
              ],
              [
                -73.74933054315699,
                40.76155574870995
              ],
              [
                -73.7493392273061,
                40.761636058201745
              ],
              [
                -73.74933672650815,
                40.76170352992408
              ],
              [
                -73.74938249132593,
                40.76178135642719
              ],
              [
                -73.74936203349068,
                40.76184452034025
              ],
              [
                -73.7492900714412,
                40.761862300150554
              ],
              [
                -73.74913505044145,
                40.761861962688094
              ],
              [
                -73.74902175447068,
                40.761819009230486
              ],
              [
                -73.7488690635061,
                40.761796468669914
              ],
              [
                -73.74872212469545,
                40.76173806599188
              ],
              [
                -73.74858298910182,
                40.761695909285656
              ],
              [
                -73.74847532850517,
                40.76164784144278
              ],
              [
                -73.74837456439464,
                40.76155878963078
              ],
              [
                -73.74825832786304,
                40.76140222574667
              ],
              [
                -73.74823056733841,
                40.76131675001059
              ],
              [
                -73.74821974793956,
                40.76120739621182
              ],
              [
                -73.74823023192417,
                40.76110833840965
              ],
              [
                -73.74824062871396,
                40.76103234077612
              ],
              [
                -73.74821281435773,
                40.76096138510759
              ],
              [
                -73.7482084978379,
                40.760914398721546
              ],
              [
                -73.7482389574376,
                40.76088029909271
              ],
              [
                -73.7482454950954,
                40.760850388450386
              ],
              [
                -73.74824810096803,
                40.760838465418395
              ],
              [
                -73.74815865904617,
                40.76072637728022
              ],
              [
                -73.74803440272188,
                40.76061164876987
              ],
              [
                -73.74790688416107,
                40.76046872647574
              ],
              [
                -73.74774348406547,
                40.760308643293214
              ],
              [
                -73.74765057016263,
                40.76022387943766
              ],
              [
                -73.74760477048451,
                40.76015630091755
              ],
              [
                -73.74754670154863,
                40.76006563412159
              ],
              [
                -73.7474971489094,
                40.75998161666269
              ],
              [
                -73.74749424153497,
                40.75997668685211
              ],
              [
                -73.74736655364313,
                40.75987903444041
              ],
              [
                -73.74708983971603,
                40.75968282611382
              ],
              [
                -73.74691154237047,
                40.75960129137661
              ],
              [
                -73.74672422976337,
                40.75952742182279
              ],
              [
                -73.74655378098826,
                40.75945017403582
              ],
              [
                -73.74635893029055,
                40.759290872701186
              ],
              [
                -73.74625836714088,
                40.75915057154628
              ],
              [
                -73.74616224371887,
                40.75902479927689
              ],
              [
                -73.74610201562085,
                40.75891191914624
              ],
              [
                -73.7460621494217,
                40.7587615004492
              ],
              [
                -73.74609973183355,
                40.758626628049555
              ],
              [
                -73.74619442433067,
                40.758538859618106
              ],
              [
                -73.74627011032776,
                40.75842713197699
              ],
              [
                -73.74629290506739,
                40.75834091284919
              ],
              [
                -73.74630110309532,
                40.75825295380464
              ],
              [
                -73.74630710944605,
                40.75815046956326
              ],
              [
                -73.74631196705487,
                40.758054814022756
              ],
              [
                -73.74625592955526,
                40.75802137911002
              ],
              [
                -73.74623013011656,
                40.75801192755458
              ],
              [
                -73.74622788360375,
                40.7580119226057
              ],
              [
                -73.74622339361433,
                40.75801105799373
              ],
              [
                -73.74621778416946,
                40.75800933786992
              ],
              [
                -73.74620768688935,
                40.75800589891986
              ],
              [
                -73.74620320407233,
                40.758003325551485
              ],
              [
                -73.74619984721016,
                40.75799990195314
              ],
              [
                -73.74619648775423,
                40.75799733256717
              ],
              [
                -73.74619312957276,
                40.75799390846309
              ],
              [
                -73.74619089673813,
                40.757990487508565
              ],
              [
                -73.74618866302863,
                40.75798706487667
              ],
              [
                -73.74618532398014,
                40.75797937156635
              ],
              [
                -73.74617871872881,
                40.757943481356044
              ],
              [
                -73.74617537880789,
                40.757935786535754
              ],
              [
                -73.74617203822314,
                40.75792809271902
              ],
              [
                -73.74616086056976,
                40.757913547217726
              ],
              [
                -73.7461463202189,
                40.7578972860362
              ],
              [
                -73.74610490620208,
                40.75785875829844
              ],
              [
                -73.74607581187085,
                40.75782965309686
              ],
              [
                -73.74606237384283,
                40.75781851914108
              ],
              [
                -73.74605453182134,
                40.757813376879874
              ],
              [
                -73.74604668174588,
                40.75780994286928
              ],
              [
                -73.7460421911072,
                40.75780907942148
              ],
              [
                -73.74603882515608,
                40.757808217782866
              ],
              [
                -73.74603433126282,
                40.75780820787569
              ],
              [
                -73.74602983803098,
                40.75780819796981
              ],
              [
                -73.746024222978,
                40.75780818559047
              ],
              [
                -73.74601859854921,
                40.7578098809565
              ],
              [
                -73.74601297697997,
                40.75781157750126
              ],
              [
                -73.74600622658913,
                40.75781412510505
              ],
              [
                -73.74599384573716,
                40.75782093171985
              ],
              [
                -73.74597807807304,
                40.75783199944328
              ],
              [
                -73.74592738556296,
                40.75786947107444
              ],
              [
                -73.74589923219948,
                40.7578882001035
              ],
              [
                -73.74585196056832,
                40.7579128661413
              ],
              [
                -73.74584183531493,
                40.75791711355346
              ],
              [
                -73.74583058928901,
                40.7579205054465
              ],
              [
                -73.74581822381136,
                40.757923042325366
              ],
              [
                -73.74580474105058,
                40.75792386729598
              ],
              [
                -73.74579238861907,
                40.7579229853177
              ],
              [
                -73.74578003900936,
                40.757921248623624
              ],
              [
                -73.74576881906094,
                40.75791780715734
              ],
              [
                -73.74576433167874,
                40.75791608948766
              ],
              [
                -73.74575984954205,
                40.75791351710864
              ],
              [
                -73.74573295575978,
                40.75789637439206
              ],
              [
                -73.74570608133453,
                40.757874107240035
              ],
              [
                -73.7456792232361,
                40.757847568691545
              ],
              [
                -73.74564231883859,
                40.75780478048872
              ],
              [
                -73.7456266711447,
                40.75778424587238
              ],
              [
                -73.7456110278219,
                40.75776285771527
              ],
              [
                -73.74558197157725,
                40.757723502355866
              ],
              [
                -73.7455663154504,
                40.75770553137226
              ],
              [
                -73.74555064800546,
                40.757690121675985
              ],
              [
                -73.74550361788224,
                40.757651580812144
              ],
              [
                -73.74547897707495,
                40.7576327357487
              ],
              [
                -73.74542635286744,
                40.757588203300145
              ],
              [
                -73.74536924923648,
                40.75754024472286
              ],
              [
                -73.74536588983982,
                40.75753767430739
              ],
              [
                -73.74536140249367,
                40.75753595662189
              ],
              [
                -73.7453569203959,
                40.757533384729555
              ],
              [
                -73.74535130665119,
                40.75753251759941
              ],
              [
                -73.74533783768733,
                40.75752992584024
              ],
              [
                -73.74529853606852,
                40.75752642341802
              ],
              [
                -73.7452929229859,
                40.75752555628655
              ],
              [
                -73.74528731383276,
                40.75752383444245
              ],
              [
                -73.74528282626937,
                40.75752211675349
              ],
              [
                -73.74527946383118,
                40.75752040037956
              ],
              [
                -73.74525817665554,
                40.75750583360744
              ],
              [
                -73.74524025695877,
                40.757492127826865
              ],
              [
                -73.7452234567032,
                40.75747927756499
              ],
              [
                -73.74521003022959,
                40.757465581717
              ],
              [
                -73.74519549607847,
                40.757447613163535
              ],
              [
                -73.74516307822353,
                40.75740654249359
              ],
              [
                -73.745148540614,
                40.757389426971976
              ],
              [
                -73.745125039926,
                40.757366312424764
              ],
              [
                -73.74512056306563,
                40.75736203176307
              ],
              [
                -73.74510712943892,
                40.75735004415604
              ],
              [
                -73.74509033710218,
                40.75733548612527
              ],
              [
                -73.74506008019742,
                40.75731662804068
              ],
              [
                -73.74504887346743,
                40.75730976932468
              ],
              [
                -73.74499506563797,
                40.75728146386926
              ],
              [
                -73.7449883390321,
                40.75727803277557
              ],
              [
                -73.74497388924861,
                40.75726953047292
              ],
              [
                -73.74495919877508,
                40.75726088540534
              ],
              [
                -73.74491884704905,
                40.75723858772495
              ],
              [
                -73.74491660449456,
                40.75723772854086
              ],
              [
                -73.74487290409643,
                40.75720944423588
              ],
              [
                -73.74486618338649,
                40.75720430487898
              ],
              [
                -73.74486058801497,
                40.75719916751142
              ],
              [
                -73.74485052430722,
                40.75718718683517
              ],
              [
                -73.7448215019215,
                40.75713929084582
              ],
              [
                -73.74480809130256,
                40.757121323558344
              ],
              [
                -73.7447745258561,
                40.75708708297568
              ],
              [
                -73.74473870826282,
                40.75705369278365
              ],
              [
                -73.74471631220506,
                40.75703570557417
              ],
              [
                -73.74471071138478,
                40.75703142173539
              ],
              [
                -73.74469950605639,
                40.75702456416071
              ],
              [
                -73.74465468364045,
                40.75699627778754
              ],
              [
                -73.74464235954294,
                40.75698770878811
              ],
              [
                -73.74463451750675,
                40.756982566931576
              ],
              [
                -73.74462490978154,
                40.75697521768243
              ],
              [
                -73.74462443683731,
                40.75697485712175
              ],
              [
                -73.74461548062342,
                40.75696714913381
              ],
              [
                -73.7446009329801,
                40.75695259717941
              ],
              [
                -73.7445897571554,
                40.756938051526475
              ],
              [
                -73.74458195244759,
                40.756922660301306
              ],
              [
                -73.74457639642843,
                40.7569072723864
              ],
              [
                -73.74457308423662,
                40.756891891121754
              ],
              [
                -73.7445710218452,
                40.75684405366969
              ],
              [
                -73.74456887603034,
                40.756817569649364
              ],
              [
                -73.74456566802672,
                40.75677571002285
              ],
              [
                -73.744560185375,
                40.756740677424126
              ],
              [
                -73.74455908496117,
                40.756734695287655
              ],
              [
                -73.74455137593789,
                40.756694533948654
              ],
              [
                -73.7445502656967,
                40.75669111478053
              ],
              [
                -73.74454039038095,
                40.75663044806492
              ],
              [
                -73.74455292838134,
                40.75658264466303
              ],
              [
                -73.74455864030777,
                40.75655788583254
              ],
              [
                -73.74460137007021,
                40.75654602168571
              ],
              [
                -73.74460585993965,
                40.756546886361086
              ],
              [
                -73.74461483813911,
                40.75654861520518
              ],
              [
                -73.74462606107197,
                40.75655120257331
              ],
              [
                -73.74462653500422,
                40.7565513046425
              ],
              [
                -73.74465411927862,
                40.75655724279255
              ],
              [
                -73.74465748646857,
                40.75655810497731
              ],
              [
                -73.74468877720241,
                40.756600027516434
              ],
              [
                -73.74468986758575,
                40.756608571614734
              ],
              [
                -73.74469001514127,
                40.7566132407421
              ],
              [
                -73.74469086913759,
                40.75664017706768
              ],
              [
                -73.74465596716064,
                40.75666145382896
              ],
              [
                -73.74464918475132,
                40.75667254296405
              ],
              [
                -73.74464465907351,
                40.756681074110936
              ],
              [
                -73.74464352524502,
                40.75668363408522
              ],
              [
                -73.74464011025232,
                40.75669558440013
              ],
              [
                -73.74463894040585,
                40.75670753952428
              ],
              [
                -73.7446421825129,
                40.75674085921853
              ],
              [
                -73.74464542068948,
                40.756775032619544
              ],
              [
                -73.74464533555502,
                40.75679724026634
              ],
              [
                -73.7446453028102,
                40.756805781875045
              ],
              [
                -73.7446452569718,
                40.756817738988104
              ],
              [
                -73.74464410896948,
                40.75682457035799
              ],
              [
                -73.74464409259474,
                40.756828841748664
              ],
              [
                -73.74464294894216,
                40.756833963686155
              ],
              [
                -73.74464180595058,
                40.75683908562514
              ],
              [
                -73.7446406799881,
                40.75683993785017
              ],
              [
                -73.74462936531677,
                40.75686126638481
              ],
              [
                -73.74462822254384,
                40.75686638832413
              ],
              [
                -73.74462820289574,
                40.75687151325582
              ],
              [
                -73.74462819307168,
                40.75687407572171
              ],
              [
                -73.74462817997272,
                40.75687749239859
              ],
              [
                -73.74463152687576,
                40.75688347800659
              ],
              [
                -73.74463376024595,
                40.75688690117124
              ],
              [
                -73.74463599057209,
                40.75689117586708
              ],
              [
                -73.74464494262489,
                40.75689973739263
              ],
              [
                -73.7446762773942,
                40.756930556364274
              ],
              [
                -73.74472217336258,
                40.75697165704377
              ],
              [
                -73.74474344954679,
                40.75698878704991
              ],
              [
                -73.7447681003836,
                40.757005070813264
              ],
              [
                -73.74477145951205,
                40.757007640743375
              ],
              [
                -73.74477594221253,
                40.75701021266016
              ],
              [
                -73.74478155285772,
                40.7570119328573
              ],
              [
                -73.74478716195661,
                40.75701365422342
              ],
              [
                -73.74479277587083,
                40.757014521381635
              ],
              [
                -73.74481186189641,
                40.757017126151005
              ],
              [
                -73.74483543956013,
                40.75701974086647
              ],
              [
                -73.74485003174925,
                40.7570223356753
              ],
              [
                -73.74485676618775,
                40.757024058357956
              ],
              [
                -73.74486237136128,
                40.75702663275743
              ],
              [
                -73.7448646115155,
                40.75702834665821
              ],
              [
                -73.74486572898313,
                40.7570300574023
              ],
              [
                -73.7448679682607,
                40.757031770128414
              ],
              [
                -73.74486908179121,
                40.75703433625488
              ],
              [
                -73.74487355184948,
                40.7570403253482
              ],
              [
                -73.74487577237984,
                40.75704716300772
              ],
              [
                -73.74487575929355,
                40.757050579684545
              ],
              [
                -73.7448812604461,
                40.75708048666214
              ],
              [
                -73.74488348163936,
                40.75708732432286
              ],
              [
                -73.74488682530038,
                40.757094165139506
              ],
              [
                -73.74489352243867,
                40.7571052841391
              ],
              [
                -73.74490803614141,
                40.75712837883433
              ],
              [
                -73.74491360993711,
                40.75713949484265
              ],
              [
                -73.74492365424828,
                40.75715659927284
              ],
              [
                -73.7449303481282,
                40.75716857248129
              ],
              [
                -73.74493593369215,
                40.75717627180868
              ],
              [
                -73.74494264524719,
                40.75718397530416
              ],
              [
                -73.74495943751793,
                40.75719853218404
              ],
              [
                -73.74498072779814,
                40.75721224547092
              ],
              [
                -73.74498184854157,
                40.757213102170354
              ],
              [
                -73.74499417705718,
                40.757219963878754
              ],
              [
                -73.74500848856957,
                40.757228384622664
              ],
              [
                -73.74502331642866,
                40.75723711006568
              ],
              [
                -73.74502892382331,
                40.75723968613715
              ],
              [
                -73.7450390130659,
                40.757244833442634
              ],
              [
                -73.74507601527569,
                40.7572619970184
              ],
              [
                -73.74508610540423,
                40.75726714499181
              ],
              [
                -73.74508946871022,
                40.757268860200774
              ],
              [
                -73.74509394882874,
                40.75727228732277
              ],
              [
                -73.74512866796508,
                40.757298842230995
              ],
              [
                -73.74515554196519,
                40.75732110951953
              ],
              [
                -73.74519135509507,
                40.75735535546363
              ],
              [
                -73.74522713431325,
                40.75739899554475
              ],
              [
                -73.74523383674908,
                40.75740840676913
              ],
              [
                -73.74525169762303,
                40.75743748684236
              ],
              [
                -73.74526176625159,
                40.75744861277299
              ],
              [
                -73.74527295907737,
                40.757458888645495
              ],
              [
                -73.74528752286861,
                40.75746916979259
              ],
              [
                -73.7453043316149,
                40.75747945640404
              ],
              [
                -73.74532563749915,
                40.75748889990955
              ],
              [
                -73.74537387044661,
                40.75750694411011
              ],
              [
                -73.74538396629083,
                40.75751038195786
              ],
              [
                -73.74542993623295,
                40.757532691386714
              ],
              [
                -73.74544674827708,
                40.7575421249389
              ],
              [
                -73.74546131211113,
                40.757552406566525
              ],
              [
                -73.74548705882032,
                40.757575525503874
              ],
              [
                -73.74552062382129,
                40.757610619422145
              ],
              [
                -73.74553069357489,
                40.75762089278826
              ],
              [
                -73.74554859793469,
                40.75763886874296
              ],
              [
                -73.74563031826345,
                40.757711652410876
              ],
              [
                -73.74567957544278,
                40.75775532210832
              ],
              [
                -73.74569748120079,
                40.75777329804245
              ],
              [
                -73.7457444715135,
                40.75782208935701
              ],
              [
                -73.74575006788852,
                40.75782722618044
              ],
              [
                -73.74576014295548,
                40.75783664481693
              ],
              [
                -73.74577582220617,
                40.75784863663218
              ],
              [
                -73.74577918555481,
                40.757850352993536
              ],
              [
                -73.74578142620531,
                40.75785206570455
              ],
              [
                -73.74578479215516,
                40.75785292735082
              ],
              [
                -73.7457892814692,
                40.75785379147576
              ],
              [
                -73.74579265266253,
                40.757853798915036
              ],
              [
                -73.7457971445757,
                40.757853808827264
              ],
              [
                -73.74580726225128,
                40.75785212271662
              ],
              [
                -73.74581738010093,
                40.75784958255485
              ],
              [
                -73.74582750623101,
                40.75784533414149
              ],
              [
                -73.74583763539502,
                40.75784023252097
              ],
              [
                -73.74584552065286,
                40.75783427072537
              ],
              [
                -73.74587709276122,
                40.75780273614511
              ],
              [
                -73.74588385596999,
                40.75779677187182
              ],
              [
                -73.74589061547654,
                40.757791662310744
              ],
              [
                -73.74594916861929,
                40.7577542080311
              ],
              [
                -73.74598069133656,
                40.75773548641947
              ],
              [
                -73.74600094895115,
                40.75772528164094
              ],
              [
                -73.74605833716052,
                40.75769892890425
              ],
              [
                -73.74607183962367,
                40.75769298064832
              ],
              [
                -73.74609771771479,
                40.757681933521056
              ],
              [
                -73.74610334252881,
                40.757679382258424
              ],
              [
                -73.74611008616621,
                40.75767854407576
              ],
              [
                -73.74613143454941,
                40.75767688335828
              ],
              [
                -73.74613929851625,
                40.757676900688345
              ],
              [
                -73.74614828083048,
                40.75767777352784
              ],
              [
                -73.74615501338599,
                40.75767949612997
              ],
              [
                -73.74615725922665,
                40.75767950107879
              ],
              [
                -73.7461684791486,
                40.75768294418255
              ],
              [
                -73.7461752084484,
                40.75768552149715
              ],
              [
                -73.74618193736195,
                40.75768895185608
              ],
              [
                -73.7461953829455,
                40.757697523160154
              ],
              [
                -73.7462054625862,
                40.757705232326245
              ],
              [
                -73.74620770191623,
                40.75770694619883
              ],
              [
                -73.7462099395368,
                40.75770951361565
              ],
              [
                -73.7462188906345,
                40.75771892806061
              ],
              [
                -73.74623789033056,
                40.75774374023383
              ],
              [
                -73.74626136313661,
                40.7577745414491
              ],
              [
                -73.74627700539382,
                40.75779678540849
              ],
              [
                -73.74628034510107,
                40.757804479723
              ],
              [
                -73.74628369241998,
                40.75781046511508
              ],
              [
                -73.74628480122651,
                40.757813884262504
              ],
              [
                -73.74628478821236,
                40.75781730093923
              ],
              [
                -73.74628363711001,
                40.75782498586535
              ],
              [
                -73.74628135718677,
                40.757833522523505
              ],
              [
                -73.74627682644942,
                40.75784376316329
              ],
              [
                -73.74625308746172,
                40.75788300039029
              ],
              [
                -73.74623725253436,
                40.75791115303519
              ],
              [
                -73.74623499433008,
                40.75791456476601
              ],
              [
                -73.74623498131781,
                40.757917980269944
              ],
              [
                -73.74623497480721,
                40.75791968919461
              ],
              [
                -73.74623496830107,
                40.75792139694663
              ],
              [
                -73.74623496178856,
                40.757923106373816
              ],
              [
                -73.7462360784208,
                40.75792481827516
              ],
              [
                -73.7462371874519,
                40.7579282362508
              ],
              [
                -73.74624054497137,
                40.757931660352156
              ],
              [
                -73.74624390337233,
                40.757935084455255
              ],
              [
                -73.74624838205932,
                40.75793850985343
              ],
              [
                -73.74626743586921,
                40.757949655989066
              ],
              [
                -73.74631454146692,
                40.757968550858195
              ],
              [
                -73.74633023584065,
                40.75797712659559
              ],
              [
                -73.74637157591067,
                40.758035301609446
              ],
              [
                -73.74641167584906,
                40.758124220706435
              ],
              [
                -73.74642482955997,
                40.75820966527988
              ],
              [
                -73.7464134955851,
                40.7582671133484
              ],
              [
                -73.74641337628168,
                40.7582677218775
              ],
              [
                -73.7464030940344,
                40.758312968449296
              ],
              [
                -73.74638144167064,
                40.75839406564263
              ],
              [
                -73.74641486447456,
                40.75846674189883
              ],
              [
                -73.74635621902131,
                40.758528110630884
              ],
              [
                -73.74630325887233,
                40.75857155662324
              ],
              [
                -73.74632333540838,
                40.75860918308185
              ],
              [
                -73.74634096546987,
                40.7586989079362
              ],
              [
                -73.74632597925213,
                40.75879966345884
              ],
              [
                -73.74629189268191,
                40.75890123331028
              ],
              [
                -73.74628600098946,
                40.75897382351047
              ],
              [
                -73.74628582855765,
                40.75901909233278
              ],
              [
                -73.74628904491358,
                40.75905924481752
              ],
              [
                -73.74642546310518,
                40.75922354214357
              ],
              [
                -73.74655981867929,
                40.75933914768842
              ],
              [
                -73.74673016390221,
                40.759443729714256
              ],
              [
                -73.74695558696763,
                40.75953903440672
              ],
              [
                -73.7471698249882,
                40.759622357402
              ],
              [
                -73.74728967123532,
                40.759714014147775
              ],
              [
                -73.7474611750098,
                40.759810054941745
              ],
              [
                -73.74753513475062,
                40.759857196187326
              ],
              [
                -73.7476583327047,
                40.75995398486198
              ],
              [
                -73.74773327425393,
                40.76003870885871
              ],
              [
                -73.74778567833137,
                40.760142176259045
              ],
              [
                -73.7478976470288,
                40.76023808603648
              ],
              [
                -73.74796573496857,
                40.76029609985873
              ],
              [
                -73.74822812598559,
                40.76050136976692
              ],
              [
                -73.7484004868574,
                40.76076813730873
              ],
              [
                -73.7484570766551,
                40.76083194257801
              ],
              [
                -73.74853166386495,
                40.76088214056184
              ],
              [
                -73.74861225072561,
                40.760927803075994
              ],
              [
                -73.74868090837376,
                40.76096434231707
              ],
              [
                -73.74914665740043,
                40.76119279108927
              ],
              [
                -73.74949376072769,
                40.76124411676728
              ],
              [
                -73.74998548162718,
                40.76133664570978
              ],
              [
                -73.75019289977786,
                40.761428553573815
              ],
              [
                -73.75041128338685,
                40.7615121726565
              ],
              [
                -73.75057468214737,
                40.7616788158593
              ],
              [
                -73.75072728692017,
                40.76174242208791
              ],
              [
                -73.75082187737034,
                40.76168112774134
              ],
              [
                -73.75090170424691,
                40.76166250926076
              ],
              [
                -73.7509735123674,
                40.76168572586614
              ],
              [
                -73.75107432252548,
                40.76176367133622
              ],
              [
                -73.7510842820423,
                40.761803838736846
              ],
              [
                -73.75104033492728,
                40.76184047245043
              ],
              [
                -73.75105245896142,
                40.761902850669735
              ],
              [
                -73.75107037762622,
                40.761917410250106
              ],
              [
                -73.75117340232892,
                40.76200390091512
              ],
              [
                -73.75128533569809,
                40.76211176666061
              ],
              [
                -73.75139722117518,
                40.76223244243897
              ],
              [
                -73.75150345347899,
                40.76236250230447
              ],
              [
                -73.75160082700452,
                40.76257386182552
              ],
              [
                -73.75178042777567,
                40.76289650166451
              ],
              [
                -73.75181884878003,
                40.76299772753365
              ],
              [
                -73.75189013131875,
                40.763204381551034
              ],
              [
                -73.75195275558251,
                40.763361263698265
              ],
              [
                -73.75200401945519,
                40.76350287108586
              ],
              [
                -73.75204563186178,
                40.76362496379624
              ],
              [
                -73.75212233558226,
                40.76372769809092
              ],
              [
                -73.75219261536506,
                40.76383041851508
              ],
              [
                -73.75226304070037,
                40.76389406601198
              ],
              [
                -73.75236834738628,
                40.763949379623185
              ],
              [
                -73.7524937694181,
                40.76403620602586
              ],
              [
                -73.75264128126541,
                40.76410001719451
              ],
              [
                -73.7526925441715,
                40.764134317548
              ],
              [
                -73.75264732366146,
                40.764202599394764
              ],
              [
                -73.75285898250387,
                40.76429096965374
              ],
              [
                -73.75297443068116,
                40.764340060750264
              ],
              [
                -73.75311540991821,
                40.76443316353336
              ],
              [
                -73.75321775669782,
                40.764550604056566
              ],
              [
                -73.75330736474879,
                40.76463871315542
              ],
              [
                -73.75340994726432,
                40.76469265965659
              ],
              [
                -73.75346745383098,
                40.76477581534768
              ],
              [
                -73.75355704488845,
                40.764868807103625
              ],
              [
                -73.75367229455681,
                40.7649716227856
              ],
              [
                -73.75375190107101,
                40.76503043842624
              ],
              [
                -73.75380441445209,
                40.765068227844544
              ],
              [
                -73.75380733759788,
                40.7651153324621
              ],
              [
                -73.75377036482259,
                40.76530463174142
              ],
              [
                -73.75405049899983,
                40.76517533199991
              ],
              [
                -73.75435817399999,
                40.76505094399988
              ],
              [
                -73.75467572399998,
                40.764939759999926
              ],
              [
                -73.75500165499989,
                40.764842405999936
              ],
              [
                -73.75533439,
                40.76475940999996
              ],
              [
                -73.75567226899989,
                40.764691148999866
              ],
              [
                -73.75601361099996,
                40.76463784199993
              ],
              [
                -73.75635669699987,
                40.76459955799993
              ],
              [
                -73.7583941619999,
                40.7644052469999
              ],
              [
                -73.75850831399994,
                40.76439435899995
              ],
              [
                -73.75856122399995,
                40.76438654599989
              ],
              [
                -73.75862488400004,
                40.76438314999996
              ],
              [
                -73.75921689799992,
                40.7643248559999
              ],
              [
                -73.759097561,
                40.7640566959999
              ],
              [
                -73.75886431399988,
                40.76327454099994
              ],
              [
                -73.75859320499987,
                40.764088229999906
              ],
              [
                -73.75851382899985,
                40.76410483099995
              ],
              [
                -73.75844672099987,
                40.76411514599987
              ],
              [
                -73.75843838499995,
                40.76392848799986
              ],
              [
                -73.75844568799998,
                40.76368152999987
              ],
              [
                -73.75836296599996,
                40.763497382999915
              ],
              [
                -73.75823811499988,
                40.76323876599993
              ],
              [
                -73.75809857499982,
                40.762984538999966
              ],
              [
                -73.75794474699994,
                40.76273540499989
              ],
              [
                -73.75788761399987,
                40.762657021999864
              ],
              [
                -73.75784858699993,
                40.76256679999993
              ],
              [
                -73.75784470399994,
                40.7625423109999
              ],
              [
                -73.7578330149999,
                40.76246859399991
              ],
              [
                -73.75784418199986,
                40.76236794099986
              ],
              [
                -73.75788220699995,
                40.76227115299989
              ],
              [
                -73.75792673399992,
                40.7622083249999
              ],
              [
                -73.75794391899996,
                40.76218407699988
              ],
              [
                -73.75802364199994,
                40.76211096099996
              ],
              [
                -73.75811452099987,
                40.76205389699988
              ],
              [
                -73.75848075099995,
                40.76199473699992
              ],
              [
                -73.75800131799996,
                40.76037986699988
              ],
              [
                -73.75682062199996,
                40.75973480599985
              ],
              [
                -73.75625606399994,
                40.758430469999944
              ],
              [
                -73.75583713299987,
                40.757742061999934
              ],
              [
                -73.7555734369999,
                40.75828095199988
              ],
              [
                -73.75551718999986,
                40.75832147399992
              ],
              [
                -73.75539137399987,
                40.758389247999915
              ],
              [
                -73.75525632799999,
                40.75844214599992
              ],
              [
                -73.75511648399994,
                40.75847949799994
              ],
              [
                -73.75497607999992,
                40.75850155999996
              ],
              [
                -73.75422838200002,
                40.75852489199992
              ],
              [
                -73.75404744899984,
                40.75851238599994
              ],
              [
                -73.7538672559999,
                40.75848565299992
              ],
              [
                -73.75369069100002,
                40.75844478799994
              ],
              [
                -73.75352058499996,
                40.758390329999905
              ],
              [
                -73.75335958499983,
                40.75832324899994
              ],
              [
                -73.75320997199995,
                40.75824487299992
              ],
              [
                -73.752771716,
                40.75887302799991
              ],
              [
                -73.75230259399993,
                40.75945519899993
              ],
              [
                -73.75115582999983,
                40.7590697689999
              ],
              [
                -73.75057710699984,
                40.75801489499991
              ],
              [
                -73.75004764499985,
                40.758404657999975
              ],
              [
                -73.74809587899985,
                40.75589954999988
              ],
              [
                -73.74711113899991,
                40.754173285999926
              ],
              [
                -73.74774116499985,
                40.75384994499991
              ],
              [
                -73.74760569999985,
                40.75350787799989
              ],
              [
                -73.74737514999993,
                40.75295961899998
              ],
              [
                -73.7470988539999,
                40.752346828999904
              ],
              [
                -73.74687468099984,
                40.75208864699992
              ],
              [
                -73.74684219699992,
                40.75205807499987
              ],
              [
                -73.746807946,
                40.75202841799993
              ],
              [
                -73.74667146099986,
                40.75193218699994
              ],
              [
                -73.74652681399978,
                40.75184264599989
              ],
              [
                -73.74637489399997,
                40.75176036499988
              ],
              [
                -73.746216657,
                40.75168585099987
              ],
              [
                -73.74577948799998,
                40.75156080499994
              ],
              [
                -73.74507911699986,
                40.75027389699994
              ],
              [
                -73.74409668799983,
                40.748206641999936
              ],
              [
                -73.74384322099985,
                40.74769009599988
              ],
              [
                -73.74625984399985,
                40.74671147499993
              ],
              [
                -73.74593060399998,
                40.74571565899988
              ],
              [
                -73.74477587099986,
                40.74328109699994
              ],
              [
                -73.74567621100002,
                40.74302729899991
              ],
              [
                -73.74671975299987,
                40.743123647999944
              ],
              [
                -73.74784861300004,
                40.743296077999936
              ],
              [
                -73.74887697299994,
                40.74328837499997
              ],
              [
                -73.74979834499989,
                40.74314244299993
              ],
              [
                -73.75072790300001,
                40.74299784699989
              ],
              [
                -73.75163327499985,
                40.74280675999993
              ],
              [
                -73.75253331799986,
                40.74261133299993
              ],
              [
                -73.75347299999997,
                40.74240484399992
              ],
              [
                -73.75138967499991,
                40.74019415999989
              ],
              [
                -73.75126422199988,
                40.74012536999989
              ],
              [
                -73.75126399699991,
                40.74012524299995
              ],
              [
                -73.75126376099992,
                40.740125142999936
              ],
              [
                -73.75112759899997,
                40.740067314999884
              ],
              [
                -73.7511273519999,
                40.74006720699986
              ],
              [
                -73.75112706799992,
                40.74006711599992
              ],
              [
                -73.75098241899988,
                40.74002149899996
              ],
              [
                -73.75098230100001,
                40.74002146299989
              ],
              [
                -73.75098218299992,
                40.74002143499993
              ],
              [
                -73.75083193299997,
                40.739988952999965
              ],
              [
                -73.75083176700001,
                40.739988916999906
              ],
              [
                -73.75083158999993,
                40.73998889799994
              ],
              [
                -73.75067885099993,
                40.73996996299993
              ],
              [
                -73.7505254029999,
                40.73995378099994
              ],
              [
                -73.75052516599997,
                40.73995375399993
              ],
              [
                -73.75052495299994,
                40.739953708999934
              ],
              [
                -73.75037372900003,
                40.7399224929999
              ],
              [
                -73.75037358699991,
                40.73992246699987
              ],
              [
                -73.75037344499992,
                40.73992242099994
              ],
              [
                -73.75022795699996,
                40.739876530999865
              ],
              [
                -73.7502278629999,
                40.73987649399994
              ],
              [
                -73.75009192199988,
                40.73981711699987
              ],
              [
                -73.75009170899982,
                40.739817026999944
              ],
              [
                -73.75009150899989,
                40.73981690899993
              ],
              [
                -73.74996804299987,
                40.739745752999966
              ],
              [
                -73.74657796099996,
                40.74034659199985
              ],
              [
                -73.74396193599988,
                40.74078912599991
              ],
              [
                -73.74385264199996,
                40.74009394899984
              ],
              [
                -73.74371941299982,
                40.73938314999984
              ],
              [
                -73.74360280299992,
                40.73901260899993
              ],
              [
                -73.74346956799998,
                40.738870103999915
              ],
              [
                -73.74306820799991,
                40.73844081799991
              ],
              [
                -73.74222678199988,
                40.73861567199989
              ],
              [
                -73.74155202599987,
                40.73876204099989
              ],
              [
                -73.74142000699983,
                40.738783807999916
              ],
              [
                -73.74128113899988,
                40.73878869199998
              ],
              [
                -73.74114115799996,
                40.73877533799986
              ],
              [
                -73.74100638499996,
                40.73874396799991
              ],
              [
                -73.74100625499993,
                40.73874393099989
              ],
              [
                -73.74100612499988,
                40.73874389499987
              ],
              [
                -73.7408821769999,
                40.738696239999925
              ],
              [
                -73.740773448,
                40.7386352729999
              ],
              [
                -73.74055585499997,
                40.7385871829999
              ],
              [
                -73.74029081599987,
                40.738516669999996
              ],
              [
                -73.74016951799993,
                40.738481897999876
              ],
              [
                -73.73986995499992,
                40.7388669009999
              ],
              [
                -73.73972244199987,
                40.73903608999993
              ],
              [
                -73.73933151399986,
                40.739474474999916
              ],
              [
                -73.73901210399985,
                40.73974647899997
              ],
              [
                -73.73857175899998,
                40.74009212299993
              ],
              [
                -73.73816047999985,
                40.74037643499988
              ],
              [
                -73.7375103809998,
                40.74071544799988
              ],
              [
                -73.73685894199984,
                40.741050400999875
              ],
              [
                -73.73560786699984,
                40.741696219999895
              ],
              [
                -73.73539272699988,
                40.741811049999896
              ],
              [
                -73.7343696589998,
                40.74235159899991
              ],
              [
                -73.73375484899985,
                40.742747627999975
              ],
              [
                -73.73370171499998,
                40.7427851569999
              ],
              [
                -73.7336646159999,
                40.742813183999885
              ],
              [
                -73.73241694699988,
                40.74369457599995
              ],
              [
                -73.73232731699993,
                40.74377143299989
              ],
              [
                -73.73226918099995,
                40.74381896299997
              ],
              [
                -73.7310646189999,
                40.744888375999885
              ],
              [
                -73.73062801899988,
                40.74536415799988
              ],
              [
                -73.73043134700004,
                40.74558483599989
              ],
              [
                -73.72993169099983,
                40.74618812499995
              ],
              [
                -73.72964619299997,
                40.74656191399991
              ],
              [
                -73.72953448999986,
                40.74671639599991
              ],
              [
                -73.729145939,
                40.74727379999996
              ],
              [
                -73.72906670999978,
                40.74739095899988
              ],
              [
                -73.728962516,
                40.74763288799988
              ],
              [
                -73.72871952799996,
                40.74819708099995
              ],
              [
                -73.72862207399999,
                40.7485120919999
              ],
              [
                -73.72849178499999,
                40.749044706999904
              ],
              [
                -73.72837798399998,
                40.7493811669999
              ],
              [
                -73.7282538099999,
                40.74969372899996
              ],
              [
                -73.72805355599995,
                40.750082150999916
              ],
              [
                -73.72788401199996,
                40.75038748299989
              ],
              [
                -73.72771356199995,
                40.750626894999904
              ],
              [
                -73.72737889099986,
                40.75102956699988
              ],
              [
                -73.72709389599997,
                40.75134160399988
              ],
              [
                -73.726777395,
                40.75162309199987
              ],
              [
                -73.7266652989999,
                40.75171358799987
              ],
              [
                -73.7264147219999,
                40.75191587799986
              ],
              [
                -73.72602966399984,
                40.752174354999916
              ],
              [
                -73.72582988899984,
                40.752291720999935
              ],
              [
                -73.72551247899985,
                40.752478197999906
              ],
              [
                -73.72509445399984,
                40.75270759199993
              ],
              [
                -73.72506848099985,
                40.75272184399989
              ],
              [
                -73.72475823099984,
                40.7529081479999
              ],
              [
                -73.72384451899983,
                40.75345681199988
              ],
              [
                -73.7238269889999,
                40.75346908899998
              ],
              [
                -73.72351867299997,
                40.75372143699984
              ],
              [
                -73.72344135499989,
                40.75379084499987
              ],
              [
                -73.72326673499998,
                40.75394759899994
              ],
              [
                -73.72299301299984,
                40.75421767099992
              ],
              [
                -73.72279703199989,
                40.75443353799988
              ],
              [
                -73.72264543599995,
                40.754600523999954
              ],
              [
                -73.72241094799992,
                40.75492018899994
              ],
              [
                -73.72207071399988,
                40.75549670299993
              ],
              [
                -73.72180289199981,
                40.756069897999915
              ],
              [
                -73.72176339599983,
                40.75613846699986
              ],
              [
                -73.72161357099998,
                40.756398594999894
              ],
              [
                -73.72159631499989,
                40.756427503999916
              ],
              [
                -73.72145870299994,
                40.756658080999955
              ],
              [
                -73.72121176099995,
                40.7569792089999
              ],
              [
                -73.72111001499998,
                40.75711152599996
              ],
              [
                -73.72096511799994,
                40.75729378899992
              ],
              [
                -73.7206331939999,
                40.75761683499996
              ],
              [
                -73.72031595299998,
                40.75789725799994
              ],
              [
                -73.71998297099998,
                40.758160820999876
              ],
              [
                -73.71967732299989,
                40.758372498999904
              ],
              [
                -73.71956460799996,
                40.758450559999915
              ],
              [
                -73.71917909599986,
                40.758666436999924
              ],
              [
                -73.7189705039999,
                40.75878323799991
              ],
              [
                -73.71877787700002,
                40.75887169199989
              ],
              [
                -73.71831971899985,
                40.7590820759999
              ],
              [
                -73.71767237099996,
                40.759311211999886
              ],
              [
                -73.71685440399995,
                40.75952551499989
              ],
              [
                -73.71613842699988,
                40.759658278999964
              ],
              [
                -73.71612528199985,
                40.75966071399991
              ],
              [
                -73.71498213999979,
                40.759782864999885
              ],
              [
                -73.71455685899994,
                40.759808807999896
              ],
              [
                -73.71426287799991,
                40.75982674699991
              ],
              [
                -73.71351828186042,
                40.75983773091619
              ],
              [
                -73.71365557006698,
                40.75993557094456
              ],
              [
                -73.71386982697975,
                40.76006061549926
              ],
              [
                -73.71413538009266,
                40.76024270987026
              ],
              [
                -73.71657585309137,
                40.7617818117619
              ],
              [
                -73.72021435708591,
                40.76419260579632
              ],
              [
                -73.72063083099366,
                40.76447640375275
              ],
              [
                -73.7207126990016,
                40.76453219025292
              ],
              [
                -73.72075222623495,
                40.76458735257299
              ],
              [
                -73.72080904877663,
                40.764647715919004
              ],
              [
                -73.7209758841712,
                40.76476015914438
              ],
              [
                -73.72114272775374,
                40.76487260693402
              ],
              [
                -73.722484384059,
                40.765527210177886
              ],
              [
                -73.72305924186259,
                40.76614440615705
              ],
              [
                -73.72356002293202,
                40.76612876183406
              ],
              [
                -73.72554599082781,
                40.76749535591531
              ],
              [
                -73.72658720816285,
                40.76811459879865
              ],
              [
                -73.7277187531248,
                40.76879613592288
              ],
              [
                -73.72870722109356,
                40.769461484046296
              ],
              [
                -73.72970531807846,
                40.770053446074
              ],
              [
                -73.73030460922762,
                40.770410605760915
              ],
              [
                -73.73113636491937,
                40.77086955609971
              ],
              [
                -73.73191230278991,
                40.7713532077885
              ],
              [
                -73.73304215822819,
                40.771954434183826
              ],
              [
                -73.73389261595659,
                40.772527178879685
              ],
              [
                -73.73688222993059,
                40.77433096117151
              ],
              [
                -73.73770283611138,
                40.77479358586612
              ],
              [
                -73.73886227381915,
                40.775573157950646
              ],
              [
                -73.73912362001862,
                40.775704143036336
              ],
              [
                -73.73937598893414,
                40.77601366983492
              ],
              [
                -73.74007973985933,
                40.7762087951981
              ],
              [
                -73.74036629409531,
                40.776389704224734
              ],
              [
                -73.74075991577695,
                40.77669471074994
              ],
              [
                -73.74153532911313,
                40.77711063108628
              ],
              [
                -73.74412314584049,
                40.77863865777707
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000081803882541,
        "objectid": 65,
        "shape_leng": 0.0446070683658,
        "location_id": 65,
        "zone": "Downtown Brooklyn/MetroTech",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.98712491499988,
                40.70063447999997
              ],
              [
                -73.98717933299986,
                40.70063698699994
              ],
              [
                -73.98774282899991,
                40.700662955999924
              ],
              [
                -73.98866705299986,
                40.70070499699995
              ],
              [
                -73.98881846999987,
                40.70071191899989
              ],
              [
                -73.98972975399985,
                40.70073504399989
              ],
              [
                -73.98997709599989,
                40.70073828399994
              ],
              [
                -73.99013277299997,
                40.70073937799991
              ],
              [
                -73.99025489399993,
                40.70074725899986
              ],
              [
                -73.99038407800006,
                40.70074858499992
              ],
              [
                -73.99087002799989,
                40.70076967799992
              ],
              [
                -73.99093201799984,
                40.7007662159999
              ],
              [
                -73.99087453800003,
                40.70067311699993
              ],
              [
                -73.99083516199983,
                40.700569761999915
              ],
              [
                -73.99081766499997,
                40.70046229299991
              ],
              [
                -73.99082272099989,
                40.70035475399991
              ],
              [
                -73.99084157899988,
                40.70028125699985
              ],
              [
                -73.9910295809998,
                40.699847169999956
              ],
              [
                -73.99124033199993,
                40.69936760199989
              ],
              [
                -73.99128347399983,
                40.69923429399991
              ],
              [
                -73.99132124499982,
                40.69910004499998
              ],
              [
                -73.99135360999992,
                40.698964980999925
              ],
              [
                -73.99138053899995,
                40.6988292269999
              ],
              [
                -73.99140200699999,
                40.698692910999945
              ],
              [
                -73.99141799499986,
                40.698556158999956
              ],
              [
                -73.99142848599986,
                40.6984190989999
              ],
              [
                -73.99143347099985,
                40.69828185899998
              ],
              [
                -73.99142289799993,
                40.69778099299988
              ],
              [
                -73.9913681219999,
                40.697013752999936
              ],
              [
                -73.99128413800005,
                40.69658820999992
              ],
              [
                -73.99120620999996,
                40.69635779799992
              ],
              [
                -73.99118780399986,
                40.696287414999915
              ],
              [
                -73.99115539099992,
                40.696196475999926
              ],
              [
                -73.99080803800004,
                40.69502713399987
              ],
              [
                -73.99066605599978,
                40.694568823999944
              ],
              [
                -73.99044474099998,
                40.69382825099991
              ],
              [
                -73.99045286499987,
                40.693720583999934
              ],
              [
                -73.99058794499989,
                40.69339137899992
              ],
              [
                -73.99072895999997,
                40.693047700999905
              ],
              [
                -73.99085041499994,
                40.6927982509999
              ],
              [
                -73.99097186899994,
                40.69254879999995
              ],
              [
                -73.99066626299994,
                40.692497176999915
              ],
              [
                -73.98947238599989,
                40.69229549399995
              ],
              [
                -73.9891054169999,
                40.69215331399994
              ],
              [
                -73.98902944799994,
                40.69212386099991
              ],
              [
                -73.98893356299986,
                40.69208664099989
              ],
              [
                -73.9882070139999,
                40.69180695999995
              ],
              [
                -73.98734787400004,
                40.69145766799993
              ],
              [
                -73.98646436299995,
                40.69110361099992
              ],
              [
                -73.98633134999982,
                40.69105050999988
              ],
              [
                -73.98536391899995,
                40.6906666129999
              ],
              [
                -73.98510411999995,
                40.69056114499995
              ],
              [
                -73.98443216299994,
                40.690292171999936
              ],
              [
                -73.9840703399998,
                40.69014307899987
              ],
              [
                -73.98372011099993,
                40.69000671899993
              ],
              [
                -73.9835048569999,
                40.69000570599995
              ],
              [
                -73.98313467299995,
                40.69000227699989
              ],
              [
                -73.98276720099986,
                40.68998648099994
              ],
              [
                -73.98157420299987,
                40.68993518299988
              ],
              [
                -73.98147568199991,
                40.689930943999876
              ],
              [
                -73.9815011859999,
                40.689988154999874
              ],
              [
                -73.98213180199994,
                40.69122723699989
              ],
              [
                -73.98206215699987,
                40.69131209099992
              ],
              [
                -73.98178907299996,
                40.691644867999905
              ],
              [
                -73.98179025899985,
                40.692025415999886
              ],
              [
                -73.9817588899999,
                40.692955172999895
              ],
              [
                -73.98253983699992,
                40.693002615999944
              ],
              [
                -73.98251122999989,
                40.693614333999925
              ],
              [
                -73.98242892099991,
                40.69361085299994
              ],
              [
                -73.98234987399988,
                40.69360788799986
              ],
              [
                -73.9822668969999,
                40.693604775999866
              ],
              [
                -73.9817531689998,
                40.69358338499997
              ],
              [
                -73.98154756999999,
                40.69357778999994
              ],
              [
                -73.98147150299984,
                40.69357493499995
              ],
              [
                -73.98098333199991,
                40.69355661699993
              ],
              [
                -73.98011299200002,
                40.69352388099992
              ],
              [
                -73.97916718399999,
                40.693488322999926
              ],
              [
                -73.97921799199985,
                40.69362565099989
              ],
              [
                -73.97921806199986,
                40.69362583099996
              ],
              [
                -73.97921815699976,
                40.69362600299993
              ],
              [
                -73.97928950399996,
                40.69375974199996
              ],
              [
                -73.97928957399992,
                40.693759866999926
              ],
              [
                -73.9792896699999,
                40.693759993999876
              ],
              [
                -73.97938094200002,
                40.6938877569999
              ],
              [
                -73.97949049699993,
                40.69400690499993
              ],
              [
                -73.97949061399991,
                40.69400703099989
              ],
              [
                -73.9794907449999,
                40.6940071389999
              ],
              [
                -73.97961626399996,
                40.69411542099993
              ],
              [
                -73.97961635799989,
                40.6941155019999
              ],
              [
                -73.97961647699987,
                40.694115582999935
              ],
              [
                -73.97975539199989,
                40.69421147699993
              ],
              [
                -73.9798623699999,
                40.69429009199996
              ],
              [
                -73.97995651799985,
                40.69438029499986
              ],
              [
                -73.9800347509999,
                40.69448012199993
              ],
              [
                -73.98009475099997,
                40.69458700399985
              ],
              [
                -73.98013518099992,
                40.694698016999936
              ],
              [
                -73.9801557449999,
                40.69481011799997
              ],
              [
                -73.98021990699989,
                40.6952871029999
              ],
              [
                -73.98024270299999,
                40.69545652899992
              ],
              [
                -73.9802635679999,
                40.69563447299992
              ],
              [
                -73.98031019799991,
                40.69590599499989
              ],
              [
                -73.9803568289999,
                40.69617751699992
              ],
              [
                -73.98037063399991,
                40.69626304999988
              ],
              [
                -73.98038723500001,
                40.696382911999926
              ],
              [
                -73.98039561699981,
                40.6964434899999
              ],
              [
                -73.98040269799995,
                40.696502104999915
              ],
              [
                -73.9804096149999,
                40.69655934399993
              ],
              [
                -73.98050926199988,
                40.69744627499991
              ],
              [
                -73.9820190099999,
                40.6974965799999
              ],
              [
                -73.98210986499997,
                40.69749936799996
              ],
              [
                -73.98220436299987,
                40.69750337199987
              ],
              [
                -73.98227354299986,
                40.697506299999915
              ],
              [
                -73.98311793999989,
                40.697542042999935
              ],
              [
                -73.98305797599978,
                40.69829607699995
              ],
              [
                -73.98305147099988,
                40.69837793399991
              ],
              [
                -73.98314891699987,
                40.698381656999906
              ],
              [
                -73.98399562199991,
                40.69841397099991
              ],
              [
                -73.98496067199994,
                40.69844496199991
              ],
              [
                -73.98537892999997,
                40.69844056799991
              ],
              [
                -73.98557305399999,
                40.698447039999955
              ],
              [
                -73.98571222099984,
                40.6984492459999
              ],
              [
                -73.98584009799997,
                40.69845759999988
              ],
              [
                -73.98615985099988,
                40.69847850399993
              ],
              [
                -73.98696892299994,
                40.69850361399997
              ],
              [
                -73.98694749699983,
                40.698734673999866
              ],
              [
                -73.98686354799999,
                40.69922012199989
              ],
              [
                -73.98681513199986,
                40.69993433099993
              ],
              [
                -73.98680917799996,
                40.70007095599988
              ],
              [
                -73.98679868199991,
                40.700192297999926
              ],
              [
                -73.98679814299999,
                40.700405223999915
              ],
              [
                -73.98679382899992,
                40.7006332229999
              ],
              [
                -73.98692304599989,
                40.70063356299991
              ],
              [
                -73.98705765100006,
                40.70063138099993
              ],
              [
                -73.98712491499988,
                40.70063447999997
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000323532461947,
        "objectid": 72,
        "shape_leng": 0.0952020695166,
        "location_id": 72,
        "zone": "East Flatbush/Remsen Village",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.92021313099984,
                40.65997544099987
              ],
              [
                -73.9209090419998,
                40.66059911499987
              ],
              [
                -73.92272843000003,
                40.662231231999904
              ],
              [
                -73.92454945899979,
                40.66386259199996
              ],
              [
                -73.92637970499992,
                40.66551489199993
              ],
              [
                -73.92739349999984,
                40.66507471699989
              ],
              [
                -73.92771188899987,
                40.66493432799992
              ],
              [
                -73.928228724,
                40.66470986499991
              ],
              [
                -73.92872204599985,
                40.66449556899988
              ],
              [
                -73.92905235499991,
                40.66435100699988
              ],
              [
                -73.92916752199997,
                40.66429953199991
              ],
              [
                -73.9294035289999,
                40.664199845999846
              ],
              [
                -73.92987364799984,
                40.66399077299993
              ],
              [
                -73.93068465499985,
                40.663620476999924
              ],
              [
                -73.92854068399994,
                40.66168068599989
              ],
              [
                -73.92828249199994,
                40.6614470669999
              ],
              [
                -73.92646424299983,
                40.65981436199997
              ],
              [
                -73.92725505399994,
                40.65933756399987
              ],
              [
                -73.92819257899993,
                40.66017717399992
              ],
              [
                -73.92834017599986,
                40.6600600359999
              ],
              [
                -73.92817215400001,
                40.658477344999945
              ],
              [
                -73.92799465199988,
                40.65680210099989
              ],
              [
                -73.92896419499984,
                40.65674261299992
              ],
              [
                -73.92989802399997,
                40.65668443599994
              ],
              [
                -73.93090354899984,
                40.65661035599994
              ],
              [
                -73.93190743899989,
                40.656537652999944
              ],
              [
                -73.93177153199984,
                40.655273641999905
              ],
              [
                -73.93160868699992,
                40.65374163899989
              ],
              [
                -73.9313911669999,
                40.6516896079999
              ],
              [
                -73.93038144299994,
                40.65173405399986
              ],
              [
                -73.93020593200004,
                40.65010866699994
              ],
              [
                -73.92999915499996,
                40.64818106399987
              ],
              [
                -73.9298024389999,
                40.646320076999885
              ],
              [
                -73.92974213599982,
                40.645743383999914
              ],
              [
                -73.92959986299991,
                40.64443271899993
              ],
              [
                -73.9293545129999,
                40.64213431899986
              ],
              [
                -73.92910623199991,
                40.6397951059999
              ],
              [
                -73.92809818199994,
                40.63985661599988
              ],
              [
                -73.92761438900001,
                40.639887640999866
              ],
              [
                -73.92716497399995,
                40.639916461999874
              ],
              [
                -73.92622007399989,
                40.6399760949999
              ],
              [
                -73.92594627400003,
                40.6399981619999
              ],
              [
                -73.92574389499987,
                40.64001446599985
              ],
              [
                -73.92570388299987,
                40.64036094999992
              ],
              [
                -73.92562125699988,
                40.640811219999925
              ],
              [
                -73.9255828079999,
                40.641070030999856
              ],
              [
                -73.92547887299986,
                40.64158704199992
              ],
              [
                -73.92538233099985,
                40.64238453199988
              ],
              [
                -73.92518618999996,
                40.64239729099993
              ],
              [
                -73.92454228399995,
                40.64243917699988
              ],
              [
                -73.92356525899983,
                40.6424992979999
              ],
              [
                -73.92341500199991,
                40.640923605999916
              ],
              [
                -73.92337418899983,
                40.64049554399992
              ],
              [
                -73.92335409199994,
                40.64017632399992
              ],
              [
                -73.92332654599986,
                40.639900215999916
              ],
              [
                -73.92154948099987,
                40.64134469199992
              ],
              [
                -73.92025802399995,
                40.642366298999875
              ],
              [
                -73.91973736999986,
                40.64277814799987
              ],
              [
                -73.91990782599999,
                40.64432958099992
              ],
              [
                -73.91995062999999,
                40.64470762999991
              ],
              [
                -73.91982077699998,
                40.644783831999874
              ],
              [
                -73.91943014999988,
                40.64506079199986
              ],
              [
                -73.91925419699997,
                40.64517776399987
              ],
              [
                -73.91892329099996,
                40.64539775299988
              ],
              [
                -73.91861676799982,
                40.64560209799989
              ],
              [
                -73.91830152299998,
                40.645812262999925
              ],
              [
                -73.91798513199991,
                40.64601087999991
              ],
              [
                -73.91764732399982,
                40.64622293599992
              ],
              [
                -73.91730801300002,
                40.646442904999944
              ],
              [
                -73.91693683599993,
                40.64668353499994
              ],
              [
                -73.91620934099993,
                40.64715114199988
              ],
              [
                -73.91550029699984,
                40.647604853999916
              ],
              [
                -73.91473216799982,
                40.64810117499995
              ],
              [
                -73.91396129299983,
                40.648600657999964
              ],
              [
                -73.9132529419999,
                40.649061424999935
              ],
              [
                -73.91253589199988,
                40.649521478999866
              ],
              [
                -73.91182280200003,
                40.649983422999874
              ],
              [
                -73.91110070199987,
                40.6504479129999
              ],
              [
                -73.91022992899991,
                40.65101403099989
              ],
              [
                -73.90944846399994,
                40.65152159899992
              ],
              [
                -73.90855790499995,
                40.652095937999896
              ],
              [
                -73.90933499299997,
                40.65280859199989
              ],
              [
                -73.9097001379998,
                40.65415294699991
              ],
              [
                -73.90972827499985,
                40.65425186399989
              ],
              [
                -73.90974279799993,
                40.65431111199997
              ],
              [
                -73.90975756199991,
                40.654367116999865
              ],
              [
                -73.90977962099994,
                40.654466254999925
              ],
              [
                -73.91012255699995,
                40.65585177099993
              ],
              [
                -73.91108323599995,
                40.655702392999935
              ],
              [
                -73.91199538399984,
                40.65556155999992
              ],
              [
                -73.91236458899995,
                40.655513564999865
              ],
              [
                -73.91314356299998,
                40.65622086399992
              ],
              [
                -73.91377943699983,
                40.65678367899994
              ],
              [
                -73.91402878099997,
                40.65700370899985
              ],
              [
                -73.91441247700004,
                40.657362418999845
              ],
              [
                -73.91556629599982,
                40.658392571999876
              ],
              [
                -73.91572803800001,
                40.65855012799989
              ],
              [
                -73.91707363499995,
                40.65974535399988
              ],
              [
                -73.91741819199994,
                40.66005119199989
              ],
              [
                -73.91818493100003,
                40.66072620899997
              ],
              [
                -73.91829936299985,
                40.66116125699987
              ],
              [
                -73.91860168199995,
                40.66111804899986
              ],
              [
                -73.91893607399996,
                40.661416010999936
              ],
              [
                -73.91920450999991,
                40.661655199999856
              ],
              [
                -73.91945727799987,
                40.66188078499988
              ],
              [
                -73.9195947979998,
                40.661995678999865
              ],
              [
                -73.91962719199988,
                40.6617080589999
              ],
              [
                -73.91966280899982,
                40.66140503299994
              ],
              [
                -73.91974399499988,
                40.660770972999885
              ],
              [
                -73.91990440499988,
                40.66033711699987
              ],
              [
                -73.919986348,
                40.6601154659999
              ],
              [
                -73.92008167099986,
                40.659857632999866
              ],
              [
                -73.92021313099984,
                40.65997544099987
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000108378855948,
        "objectid": 66,
        "shape_leng": 0.0546334593634,
        "location_id": 66,
        "zone": "DUMBO/Vinegar Hill",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.97906084869959,
                40.7059460290057
              ],
              [
                -73.9790576702781,
                40.70595288914234
              ],
              [
                -73.97905429658508,
                40.70596017019387
              ],
              [
                -73.97918606171815,
                40.70595876205001
              ],
              [
                -73.97921742108453,
                40.70590046607558
              ],
              [
                -73.97927661710943,
                40.705911077051084
              ],
              [
                -73.97928362082712,
                40.70578387584555
              ],
              [
                -73.97933238122667,
                40.705760033226255
              ],
              [
                -73.97955873010817,
                40.705765374298394
              ],
              [
                -73.97956222047333,
                40.70573887396689
              ],
              [
                -73.98023778333459,
                40.705760192228695
              ],
              [
                -73.98022034622862,
                40.70584764123357
              ],
              [
                -73.98073921101802,
                40.70585303034286
              ],
              [
                -73.98090634668807,
                40.70590605921021
              ],
              [
                -73.98092510305972,
                40.705369255100244
              ],
              [
                -73.98232944175258,
                40.705552033831964
              ],
              [
                -73.98237340600002,
                40.70554334999991
              ],
              [
                -73.98245898620758,
                40.70554241448613
              ],
              [
                -73.98245956886106,
                40.705508797112664
              ],
              [
                -73.98274859333847,
                40.705532691119664
              ],
              [
                -73.98274508079307,
                40.70555270993459
              ],
              [
                -73.98274161892988,
                40.70557244126629
              ],
              [
                -73.98283552150644,
                40.70555580719898
              ],
              [
                -73.98308986582964,
                40.70551154191292
              ],
              [
                -73.98308639129395,
                40.70547709085114
              ],
              [
                -73.98326398719114,
                40.70548506633027
              ],
              [
                -73.98336819889046,
                40.70546106503822
              ],
              [
                -73.98340650315147,
                40.70546637083022
              ],
              [
                -73.98342627521598,
                40.705577221174664
              ],
              [
                -73.98343806260941,
                40.705644094923855
              ],
              [
                -73.9840214979145,
                40.70559863478225
              ],
              [
                -73.98474329943133,
                40.705542907266924
              ],
              [
                -73.98643429826662,
                40.70534860741954
              ],
              [
                -73.9864291160866,
                40.70506917993066
              ],
              [
                -73.98652973012088,
                40.70497909917378
              ],
              [
                -73.9866227794053,
                40.704978609454166
              ],
              [
                -73.98663993140147,
                40.70501477965987
              ],
              [
                -73.98666390146528,
                40.70504863092953
              ],
              [
                -73.98669416601165,
                40.705079423826106
              ],
              [
                -73.98673006395875,
                40.70510648571844
              ],
              [
                -73.9867708111665,
                40.70512922547279
              ],
              [
                -73.98681551756646,
                40.70514714636731
              ],
              [
                -73.98686320660372,
                40.70515985694144
              ],
              [
                -73.98726969301137,
                40.70517572069417
              ],
              [
                -73.98795676491503,
                40.70513421741247
              ],
              [
                -73.98828999111284,
                40.70507065950019
              ],
              [
                -73.98830332208532,
                40.704540468955045
              ],
              [
                -73.9884074480715,
                40.70455450084082
              ],
              [
                -73.98869723304759,
                40.70456457877682
              ],
              [
                -73.98898897757785,
                40.70469035678166
              ],
              [
                -73.98906989652578,
                40.704705914698266
              ],
              [
                -73.98920046436149,
                40.7047238969888
              ],
              [
                -73.98934734717507,
                40.70472969170268
              ],
              [
                -73.98949358971352,
                40.70480903812361
              ],
              [
                -73.99009111393421,
                40.7049434632891
              ],
              [
                -73.99022721196006,
                40.7049340444871
              ],
              [
                -73.99028496035659,
                40.70486642939217
              ],
              [
                -73.9903076849381,
                40.704839880601995
              ],
              [
                -73.99054617998553,
                40.70441616750172
              ],
              [
                -73.99071473114778,
                40.70435137954314
              ],
              [
                -73.99094755350794,
                40.704032442139194
              ],
              [
                -73.99141794782705,
                40.70400408902074
              ],
              [
                -73.99186159189317,
                40.70408664210434
              ],
              [
                -73.99195715431195,
                40.704200677853294
              ],
              [
                -73.99212100639093,
                40.70439620157789
              ],
              [
                -73.99212703535072,
                40.704689642033514
              ],
              [
                -73.99230833787269,
                40.704690001742705
              ],
              [
                -73.99251210682041,
                40.70469411255763
              ],
              [
                -73.9926913557499,
                40.70462984994533
              ],
              [
                -73.99280104228988,
                40.704634023927845
              ],
              [
                -73.99350881403605,
                40.70462350527399
              ],
              [
                -73.99362643916744,
                40.70459524465741
              ],
              [
                -73.99358606975355,
                40.70450911821809
              ],
              [
                -73.99368792224479,
                40.70447791421154
              ],
              [
                -73.9936933612927,
                40.704476128837804
              ],
              [
                -73.993774572078,
                40.704449457408806
              ],
              [
                -73.9937815542649,
                40.704468944543734
              ],
              [
                -73.99380687248414,
                40.70453961321409
              ],
              [
                -73.99386341031877,
                40.704556007433
              ],
              [
                -73.99456978950866,
                40.7043762640165
              ],
              [
                -73.99463603408383,
                40.70433440342858
              ],
              [
                -73.99470229068396,
                40.704292531913545
              ],
              [
                -73.99477614353454,
                40.70424586105244
              ],
              [
                -73.99469807470796,
                40.70416470079729
              ],
              [
                -73.99477286162535,
                40.70410454169924
              ],
              [
                -73.99479231299986,
                40.704088894707155
              ],
              [
                -73.99479231460045,
                40.704068405633734
              ],
              [
                -73.99477158125163,
                40.70404936037442
              ],
              [
                -73.99471424235146,
                40.70399668906091
              ],
              [
                -73.99476934191567,
                40.70395289495363
              ],
              [
                -73.99483637435264,
                40.70398633908107
              ],
              [
                -73.99488204726403,
                40.70400865389512
              ],
              [
                -73.99502925916443,
                40.703808200868046
              ],
              [
                -73.99492964609256,
                40.70377131508382
              ],
              [
                -73.99491043069509,
                40.703797315958745
              ],
              [
                -73.99488239902435,
                40.703788025837355
              ],
              [
                -73.99499542533941,
                40.70365056321164
              ],
              [
                -73.99485765933736,
                40.70356613852609
              ],
              [
                -73.99466290343602,
                40.703430608056536
              ],
              [
                -73.99480038274874,
                40.70329195814199
              ],
              [
                -73.9949820331283,
                40.703381715535215
              ],
              [
                -73.99529346700928,
                40.70355406589795
              ],
              [
                -73.99544564312069,
                40.703275305471806
              ],
              [
                -73.99504592724139,
                40.70313183989976
              ],
              [
                -73.99490815999987,
                40.70304734099988
              ],
              [
                -73.99470923300002,
                40.70299388599993
              ],
              [
                -73.9944400939999,
                40.702909269999964
              ],
              [
                -73.99417681200005,
                40.70282465399987
              ],
              [
                -73.99380996599997,
                40.70267441199997
              ],
              [
                -73.99325388199995,
                40.70241197099989
              ],
              [
                -73.99286016199979,
                40.70220276699991
              ],
              [
                -73.99261204999985,
                40.70206112899992
              ],
              [
                -73.99232767899991,
                40.70185536999986
              ],
              [
                -73.99221871100002,
                40.70177257799987
              ],
              [
                -73.9921106779999,
                40.701693442999925
              ],
              [
                -73.99206681900003,
                40.70165885999989
              ],
              [
                -73.99202772899994,
                40.70162814199991
              ],
              [
                -73.99193644899997,
                40.701556408999856
              ],
              [
                -73.99126294499995,
                40.701027101999905
              ],
              [
                -73.99093201799984,
                40.7007662159999
              ],
              [
                -73.99087002799989,
                40.70076967799992
              ],
              [
                -73.99038407800006,
                40.70074858499992
              ],
              [
                -73.99025489399993,
                40.70074725899986
              ],
              [
                -73.99013277299997,
                40.70073937799991
              ],
              [
                -73.98997709599989,
                40.70073828399994
              ],
              [
                -73.98972975399985,
                40.70073504399989
              ],
              [
                -73.98881846899985,
                40.70071191899997
              ],
              [
                -73.98866705299986,
                40.70070499699995
              ],
              [
                -73.98774282899991,
                40.700662955999924
              ],
              [
                -73.98717933299986,
                40.70063698699994
              ],
              [
                -73.98712491499988,
                40.70063447999997
              ],
              [
                -73.98705765100006,
                40.70063138099993
              ],
              [
                -73.98692304599989,
                40.70063356299991
              ],
              [
                -73.98679382799996,
                40.700633222999954
              ],
              [
                -73.98679814199983,
                40.70040522399989
              ],
              [
                -73.98679868199991,
                40.700192297999926
              ],
              [
                -73.98680917799996,
                40.70007095599988
              ],
              [
                -73.98681513199986,
                40.69993433099993
              ],
              [
                -73.98686354799999,
                40.69922012199989
              ],
              [
                -73.98694749699983,
                40.698734673999866
              ],
              [
                -73.98696892299994,
                40.69850361399997
              ],
              [
                -73.98615984999995,
                40.69847850399993
              ],
              [
                -73.98584009799997,
                40.69845759999988
              ],
              [
                -73.98571222099984,
                40.6984492459999
              ],
              [
                -73.98557305399999,
                40.698447039999955
              ],
              [
                -73.98537892999997,
                40.69844056799991
              ],
              [
                -73.98496067199994,
                40.69844496199991
              ],
              [
                -73.98399562199991,
                40.69841397099991
              ],
              [
                -73.98314891699982,
                40.698381657999924
              ],
              [
                -73.98305147099988,
                40.69837793399991
              ],
              [
                -73.98305797499985,
                40.69829607699988
              ],
              [
                -73.983117941,
                40.69754204299993
              ],
              [
                -73.98227354299996,
                40.69750630099993
              ],
              [
                -73.98220436399998,
                40.697503371999865
              ],
              [
                -73.98210986499997,
                40.69749936799996
              ],
              [
                -73.98201901099986,
                40.697496579999886
              ],
              [
                -73.98050926199988,
                40.69744627499991
              ],
              [
                -73.98054419199988,
                40.69824651199987
              ],
              [
                -73.98048604199988,
                40.69927922799994
              ],
              [
                -73.98048077899998,
                40.6993727139999
              ],
              [
                -73.98046217199987,
                40.69970315799986
              ],
              [
                -73.98044078599986,
                40.7001836309999
              ],
              [
                -73.98041744299996,
                40.70051815299991
              ],
              [
                -73.98040482499984,
                40.700699005999915
              ],
              [
                -73.98074079499993,
                40.70127730999999
              ],
              [
                -73.9806490779999,
                40.70156087299994
              ],
              [
                -73.98022432,
                40.7030633239999
              ],
              [
                -73.97998854,
                40.703541057999935
              ],
              [
                -73.97960292799985,
                40.704176399999916
              ],
              [
                -73.97932762499988,
                40.70474627599989
              ],
              [
                -73.97912179285028,
                40.70552703403615
              ],
              [
                -73.97924508744678,
                40.70554838913701
              ],
              [
                -73.97906084869959,
                40.7059460290057
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000394282272487,
        "objectid": 67,
        "shape_leng": 0.0997470781551,
        "location_id": 67,
        "zone": "Dyker Heights",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -74.0109702639999,
                40.6306818689999
              ],
              [
                -74.01317645999991,
                40.63201376599993
              ],
              [
                -74.01514630699995,
                40.63322193799993
              ],
              [
                -74.01542409899997,
                40.632513138999904
              ],
              [
                -74.01574804399995,
                40.63176426899987
              ],
              [
                -74.01604820399999,
                40.631032888999876
              ],
              [
                -74.01634322399995,
                40.63028559599993
              ],
              [
                -74.01664336499988,
                40.629576078999946
              ],
              [
                -74.01692644799988,
                40.628835638999924
              ],
              [
                -74.01696955099997,
                40.62872878599994
              ],
              [
                -74.01722288099991,
                40.628100688999965
              ],
              [
                -74.01784193399993,
                40.626590693999944
              ],
              [
                -74.0187518069999,
                40.62431751099996
              ],
              [
                -74.0189099719999,
                40.62408698799987
              ],
              [
                -74.01907578299992,
                40.62361467399988
              ],
              [
                -74.01938702899992,
                40.62289181899993
              ],
              [
                -74.01970491599991,
                40.6221849649999
              ],
              [
                -74.020086673,
                40.62242095199988
              ],
              [
                -74.02062330999992,
                40.62180625199992
              ],
              [
                -74.02090368299993,
                40.62152616599993
              ],
              [
                -74.02117792099988,
                40.62125219599988
              ],
              [
                -74.02167353899998,
                40.6207347999999
              ],
              [
                -74.02172925499997,
                40.62067945199991
              ],
              [
                -74.02226919899991,
                40.620109624999934
              ],
              [
                -74.02287598699992,
                40.619466147999944
              ],
              [
                -74.02363350499998,
                40.61853764799987
              ],
              [
                -74.02387367099986,
                40.618259319999886
              ],
              [
                -74.02462461299996,
                40.61738902399987
              ],
              [
                -74.02525327199992,
                40.61667845799995
              ],
              [
                -74.02574989099996,
                40.616058222999904
              ],
              [
                -74.02607013199994,
                40.61548666699989
              ],
              [
                -74.02624732399988,
                40.61510478099994
              ],
              [
                -74.02651463099991,
                40.61467032699991
              ],
              [
                -74.02736880500001,
                40.61323451599993
              ],
              [
                -74.02754297299998,
                40.61293718299988
              ],
              [
                -74.02653228199998,
                40.612425370999915
              ],
              [
                -74.02637609099996,
                40.61245060299994
              ],
              [
                -74.02710660599995,
                40.61024395599993
              ],
              [
                -74.02497932499993,
                40.609741974999906
              ],
              [
                -74.02478376299999,
                40.60969962899985
              ],
              [
                -74.0245785549999,
                40.61022330199993
              ],
              [
                -74.02459593799989,
                40.610109224999896
              ],
              [
                -74.02459441699996,
                40.60999204099989
              ],
              [
                -74.02457307699996,
                40.609874527999885
              ],
              [
                -74.02453195999996,
                40.60975959499991
              ],
              [
                -74.02447207599994,
                40.609650082999956
              ],
              [
                -74.02439534399993,
                40.60954853399993
              ],
              [
                -74.02430437599996,
                40.609456969999925
              ],
              [
                -74.02150602899994,
                40.60782209899992
              ],
              [
                -74.02247769499994,
                40.60667421599988
              ],
              [
                -74.02078540899996,
                40.6056736859999
              ],
              [
                -74.02122508399991,
                40.604738061999974
              ],
              [
                -74.02139467199993,
                40.604493997999946
              ],
              [
                -74.02129949199998,
                40.60442832299987
              ],
              [
                -74.0205358359999,
                40.60419641799993
              ],
              [
                -74.01924239499986,
                40.60377850099991
              ],
              [
                -74.01918971499998,
                40.60376183999993
              ],
              [
                -74.01912316599994,
                40.603739769999926
              ],
              [
                -74.01909859999992,
                40.60373226399986
              ],
              [
                -74.01869135699988,
                40.60360792399995
              ],
              [
                -74.018660218,
                40.60364106799991
              ],
              [
                -74.01823145099998,
                40.60410847299991
              ],
              [
                -74.01821458599989,
                40.604181957999884
              ],
              [
                -74.01817646699998,
                40.604252608999936
              ],
              [
                -74.01811904999991,
                40.60431522299989
              ],
              [
                -74.01804702599995,
                40.60436573499989
              ],
              [
                -74.01750868599993,
                40.604850496999866
              ],
              [
                -74.01746711399991,
                40.60496510499992
              ],
              [
                -74.0172836679999,
                40.60513782499993
              ],
              [
                -74.01671283899998,
                40.60568263499989
              ],
              [
                -74.01640553099996,
                40.605975924999875
              ],
              [
                -74.01549854999998,
                40.606841523999925
              ],
              [
                -74.0162363669999,
                40.60723326599992
              ],
              [
                -74.01701910199995,
                40.60765441699993
              ],
              [
                -74.01522379399994,
                40.609356383999916
              ],
              [
                -74.01348255899993,
                40.61103739799989
              ],
              [
                -74.01171201499994,
                40.61273502899994
              ],
              [
                -74.01108648099991,
                40.61333904399986
              ],
              [
                -74.01050403599997,
                40.613896610999866
              ],
              [
                -74.00992371999989,
                40.61445664299992
              ],
              [
                -74.00934164499988,
                40.615015095999865
              ],
              [
                -74.00876039999996,
                40.61557582299988
              ],
              [
                -74.00817687599991,
                40.61613318099995
              ],
              [
                -74.00759806899993,
                40.61669446099994
              ],
              [
                -74.00701531599991,
                40.61725394599992
              ],
              [
                -74.00643394999992,
                40.617814716999874
              ],
              [
                -74.0058531989999,
                40.61837047699992
              ],
              [
                -74.00522665999992,
                40.61897315399989
              ],
              [
                -74.00459907,
                40.619576852999955
              ],
              [
                -74.00401906899995,
                40.62013618899991
              ],
              [
                -74.0034355699999,
                40.620695403999925
              ],
              [
                -74.00285701599996,
                40.62125364299992
              ],
              [
                -74.00227586299998,
                40.621814273999874
              ],
              [
                -74.00169380199992,
                40.62237242699998
              ],
              [
                -74.00120222399994,
                40.62284271199991
              ],
              [
                -74.00052987499996,
                40.623489938999946
              ],
              [
                -73.99994665,
                40.62405062199985
              ],
              [
                -73.99932090299998,
                40.62465524699996
              ],
              [
                -73.99771358999998,
                40.62368026999996
              ],
              [
                -73.99748403999989,
                40.62451984599993
              ],
              [
                -73.99724067099987,
                40.62528868699994
              ],
              [
                -73.99811614300002,
                40.62581570699993
              ],
              [
                -74.00031369499995,
                40.627144582999925
              ],
              [
                -74.00251454799997,
                40.62847420999989
              ],
              [
                -74.00222793499994,
                40.62875116999988
              ],
              [
                -74.00193011399998,
                40.629032766999885
              ],
              [
                -74.00306153999993,
                40.6297151749999
              ],
              [
                -74.00368597199991,
                40.63009178099988
              ],
              [
                -74.00413727999998,
                40.63036396599992
              ],
              [
                -74.00633870699993,
                40.63169362599994
              ],
              [
                -74.00702302499997,
                40.63210999499996
              ],
              [
                -74.00773968299988,
                40.63163008999992
              ],
              [
                -74.0084560119999,
                40.631150269999914
              ],
              [
                -74.00922358699998,
                40.630633933999924
              ],
              [
                -74.01000340799995,
                40.63011308899991
              ],
              [
                -74.0109702639999,
                40.6306818689999
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000111073378655,
        "objectid": 68,
        "shape_leng": 0.0493373795569,
        "location_id": 68,
        "zone": "East Chelsea",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -74.00201937799989,
                40.740444088999894
              ],
              [
                -74.00155531499993,
                40.741080025999935
              ],
              [
                -74.00112917399991,
                40.74166809799989
              ],
              [
                -74.00070275499993,
                40.7422536109999
              ],
              [
                -74.0002751799999,
                40.74283879799987
              ],
              [
                -73.9998482359998,
                40.743425018999915
              ],
              [
                -73.99942208999991,
                40.74400957299992
              ],
              [
                -73.99897237399988,
                40.74463005499994
              ],
              [
                -73.99848652399989,
                40.745294163999894
              ],
              [
                -73.99800822000003,
                40.74595130199988
              ],
              [
                -73.99755164599995,
                40.74658089999985
              ],
              [
                -73.99709902899994,
                40.74720510199991
              ],
              [
                -73.99664704399989,
                40.74782272899996
              ],
              [
                -73.99619655499993,
                40.74843932799997
              ],
              [
                -73.99574855499995,
                40.749052195999866
              ],
              [
                -73.99529821499979,
                40.74967168299992
              ],
              [
                -73.99484363799989,
                40.75029330899991
              ],
              [
                -73.99437946899982,
                40.75093038999993
              ],
              [
                -73.99394825699984,
                40.75152222199995
              ],
              [
                -73.99346417699985,
                40.752190054999886
              ],
              [
                -73.99297937799993,
                40.75285340299992
              ],
              [
                -73.99252855999994,
                40.75347391299987
              ],
              [
                -73.99207900099996,
                40.75409111599987
              ],
              [
                -73.99163271299996,
                40.75470680499996
              ],
              [
                -73.99446824699999,
                40.75590886199993
              ],
              [
                -73.99500601599988,
                40.756136713999865
              ],
              [
                -73.99511286399995,
                40.756175773999864
              ],
              [
                -73.99577488199998,
                40.75645451499986
              ],
              [
                -73.99730671499998,
                40.757110506999894
              ],
              [
                -73.99775836599989,
                40.75648752899987
              ],
              [
                -73.99820870599997,
                40.75586865599991
              ],
              [
                -73.99866067199993,
                40.75525176199991
              ],
              [
                -73.99914292099987,
                40.754584933999865
              ],
              [
                -73.99963228999997,
                40.75391808599989
              ],
              [
                -73.99992459199981,
                40.75351627999993
              ],
              [
                -74.00001529900001,
                40.75339158699997
              ],
              [
                -74.00052667499992,
                40.75268862399987
              ],
              [
                -74.00086139300001,
                40.75221483899993
              ],
              [
                -74.000974548,
                40.75205466499991
              ],
              [
                -74.00142970699987,
                40.75144572099988
              ],
              [
                -74.00188266699993,
                40.7508279309999
              ],
              [
                -74.00233168599986,
                40.75020825799992
              ],
              [
                -74.002431475,
                40.7500727519999
              ],
              [
                -74.002458683,
                40.75003580599991
              ],
              [
                -74.00278526999995,
                40.74959231999986
              ],
              [
                -74.00312806199996,
                40.74912185999996
              ],
              [
                -74.00316116799988,
                40.749076422999906
              ],
              [
                -74.00323561099998,
                40.748974254999936
              ],
              [
                -74.00368344599993,
                40.74835499999994
              ],
              [
                -74.0039029229999,
                40.74805366099988
              ],
              [
                -74.004168307,
                40.7476892929999
              ],
              [
                -74.00465274299988,
                40.74702295299991
              ],
              [
                -74.0051071349999,
                40.74640277299988
              ],
              [
                -74.00553403999999,
                40.74581556899989
              ],
              [
                -74.00595977599997,
                40.745230984999935
              ],
              [
                -74.00638477199996,
                40.74464590299987
              ],
              [
                -74.00681149999996,
                40.74406132399992
              ],
              [
                -74.00696604399992,
                40.74384701399987
              ],
              [
                -74.00723774599994,
                40.743470231999865
              ],
              [
                -74.00770461499994,
                40.74283381299992
              ],
              [
                -74.00789568799995,
                40.74257005099985
              ],
              [
                -74.00820401899988,
                40.74214751799986
              ],
              [
                -74.00779116099989,
                40.74197084499985
              ],
              [
                -74.00697077699994,
                40.7416271269999
              ],
              [
                -74.00536591799992,
                40.74094529499997
              ],
              [
                -74.00515808199998,
                40.74085808099991
              ],
              [
                -74.00310733899988,
                40.73999752099994
              ],
              [
                -74.00252381399994,
                40.739752643999886
              ],
              [
                -74.00201937799989,
                40.740444088999894
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000291799754395,
        "objectid": 73,
        "shape_leng": 0.0853020209129,
        "location_id": 73,
        "zone": "East Flushing",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.79566545000002,
                40.75023619999991
              ],
              [
                -73.7947190109999,
                40.75064761699992
              ],
              [
                -73.79468324699988,
                40.75127504499995
              ],
              [
                -73.79465583899983,
                40.75198814499992
              ],
              [
                -73.794627692,
                40.75270267499999
              ],
              [
                -73.79457726999995,
                40.75356301099991
              ],
              [
                -73.79474720599988,
                40.75562818799993
              ],
              [
                -73.79493246199993,
                40.757798029999954
              ],
              [
                -73.79580437699985,
                40.75809121699992
              ],
              [
                -73.79621926899993,
                40.75824167999989
              ],
              [
                -73.79670467899993,
                40.75841771199986
              ],
              [
                -73.79760259799991,
                40.758744178999905
              ],
              [
                -73.798501602,
                40.75907103599995
              ],
              [
                -73.79940088899988,
                40.759397218999936
              ],
              [
                -73.80030038999979,
                40.759702298999926
              ],
              [
                -73.80125017499996,
                40.75982823699995
              ],
              [
                -73.80215291900004,
                40.75998721799987
              ],
              [
                -73.80307328899983,
                40.760126247999885
              ],
              [
                -73.80399971299993,
                40.76027048799995
              ],
              [
                -73.804916685,
                40.760440411999895
              ],
              [
                -73.80584005399999,
                40.76061539099988
              ],
              [
                -73.80676048599993,
                40.7607877729999
              ],
              [
                -73.80768023699983,
                40.76095995899988
              ],
              [
                -73.80859484099997,
                40.76113246199993
              ],
              [
                -73.80951372599986,
                40.76129991099989
              ],
              [
                -73.81044348399985,
                40.761377438999894
              ],
              [
                -73.81104963199984,
                40.761417020999964
              ],
              [
                -73.81226778099976,
                40.76113002199986
              ],
              [
                -73.8131322989999,
                40.76089834899995
              ],
              [
                -73.81399882899984,
                40.760665033999906
              ],
              [
                -73.8150873259998,
                40.76037161099987
              ],
              [
                -73.8172882139999,
                40.75977554699992
              ],
              [
                -73.82063593299985,
                40.758872267999905
              ],
              [
                -73.82004500599989,
                40.75848322799986
              ],
              [
                -73.819232864,
                40.75794681199992
              ],
              [
                -73.81856914499988,
                40.75751884899991
              ],
              [
                -73.81789245699991,
                40.75708633799991
              ],
              [
                -73.81893695399998,
                40.75640957799994
              ],
              [
                -73.81926771999996,
                40.75619984799989
              ],
              [
                -73.81961754399985,
                40.75596557899992
              ],
              [
                -73.82030067699999,
                40.75551497999992
              ],
              [
                -73.8184078289999,
                40.75383042199998
              ],
              [
                -73.819092321,
                40.753384819999916
              ],
              [
                -73.81977819399994,
                40.7529368949999
              ],
              [
                -73.820849288,
                40.75224154499988
              ],
              [
                -73.81998860999985,
                40.751428620999896
              ],
              [
                -73.81941130799996,
                40.75088775499989
              ],
              [
                -73.81911566899987,
                40.750621072999856
              ],
              [
                -73.81883698299988,
                40.75035215799988
              ],
              [
                -73.81826477299987,
                40.749826989999875
              ],
              [
                -73.81779541099995,
                40.74939201499992
              ],
              [
                -73.81768650999989,
                40.74928821199994
              ],
              [
                -73.81710925599998,
                40.748760543999936
              ],
              [
                -73.81654271199996,
                40.74826004199988
              ],
              [
                -73.81598751399984,
                40.747769935999905
              ],
              [
                -73.8153731739999,
                40.747218337999925
              ],
              [
                -73.81477152699999,
                40.74668237099996
              ],
              [
                -73.81416172099998,
                40.74613857999986
              ],
              [
                -73.81341871899997,
                40.746584445999936
              ],
              [
                -73.8131629729999,
                40.74673791899988
              ],
              [
                -73.81235411899996,
                40.7472232829999
              ],
              [
                -73.81161754599998,
                40.74766484299989
              ],
              [
                -73.80976035399992,
                40.7487792219999
              ],
              [
                -73.80750599299991,
                40.750136586999936
              ],
              [
                -73.8072707609999,
                40.75011897499993
              ],
              [
                -73.80633253899988,
                40.75004766599994
              ],
              [
                -73.8053995369999,
                40.7499727289999
              ],
              [
                -73.80446953099978,
                40.749899274999926
              ],
              [
                -73.80353070499987,
                40.74982541299988
              ],
              [
                -73.80295670699991,
                40.74977052699988
              ],
              [
                -73.80287666499987,
                40.74976514899987
              ],
              [
                -73.80280784099993,
                40.74906666099989
              ],
              [
                -73.80277033699984,
                40.748847537999936
              ],
              [
                -73.802749109,
                40.748606750999954
              ],
              [
                -73.80274770699985,
                40.74836447599992
              ],
              [
                -73.80276629299996,
                40.74812214499989
              ],
              [
                -73.80280481399987,
                40.74788116399994
              ],
              [
                -73.80286300399993,
                40.74764296999995
              ],
              [
                -73.80294034199993,
                40.74740891699993
              ],
              [
                -73.80302898599999,
                40.747194800999935
              ],
              [
                -73.8033521419999,
                40.74643196499987
              ],
              [
                -73.80327809299982,
                40.746439510999934
              ],
              [
                -73.80017472200002,
                40.74675557799992
              ],
              [
                -73.79921673599986,
                40.74685628199988
              ],
              [
                -73.79826235399989,
                40.746965655999894
              ],
              [
                -73.7946756879998,
                40.74721192799996
              ],
              [
                -73.79472264899997,
                40.74759932999991
              ],
              [
                -73.79598269399985,
                40.74853189299994
              ],
              [
                -73.796581036,
                40.74897739799995
              ],
              [
                -73.79704716099987,
                40.749332317999915
              ],
              [
                -73.79729524799988,
                40.74943505299992
              ],
              [
                -73.79644617199999,
                40.749859216999916
              ],
              [
                -73.79566545000002,
                40.75023619999991
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.00019905160381,
        "objectid": 69,
        "shape_leng": 0.0789896450483,
        "location_id": 69,
        "zone": "East Concourse/Concourse Village",
        "borough": "Bronx"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.91179361999995,
                40.8264281559999
              ],
              [
                -73.91169430299983,
                40.82775871599993
              ],
              [
                -73.91030770399989,
                40.828772484999895
              ],
              [
                -73.90948178399991,
                40.829592118999855
              ],
              [
                -73.908959903,
                40.83022623299989
              ],
              [
                -73.909144659,
                40.83028572999997
              ],
              [
                -73.90971373799982,
                40.83047001399991
              ],
              [
                -73.91049010699992,
                40.830737372999884
              ],
              [
                -73.90962130699984,
                40.8322814219999
              ],
              [
                -73.90951895699989,
                40.83246377699988
              ],
              [
                -73.90946039499991,
                40.83256811599989
              ],
              [
                -73.90942257599983,
                40.83263549599992
              ],
              [
                -73.9093741529999,
                40.832721771999914
              ],
              [
                -73.90928354899982,
                40.83288319599993
              ],
              [
                -73.90923681499991,
                40.83296646099989
              ],
              [
                -73.909177781,
                40.83307163899987
              ],
              [
                -73.90910597799993,
                40.83319956599989
              ],
              [
                -73.90889804099996,
                40.833570034999894
              ],
              [
                -73.90882575999996,
                40.8336988109999
              ],
              [
                -73.90869131200002,
                40.833938346999894
              ],
              [
                -73.90857547999998,
                40.834144441999904
              ],
              [
                -73.90852151699984,
                40.83424045599991
              ],
              [
                -73.90837656,
                40.834498370999924
              ],
              [
                -73.90823980199985,
                40.83474169399996
              ],
              [
                -73.90810870199985,
                40.83497494999988
              ],
              [
                -73.90799132699986,
                40.8351837829999
              ],
              [
                -73.90797135199996,
                40.8352193239999
              ],
              [
                -73.90783293099987,
                40.83546559899987
              ],
              [
                -73.9077182169999,
                40.835669695999854
              ],
              [
                -73.90762665999985,
                40.83583258899993
              ],
              [
                -73.90757269399987,
                40.83592860399989
              ],
              [
                -73.90740200499981,
                40.836232282999966
              ],
              [
                -73.90733306999991,
                40.83634242699987
              ],
              [
                -73.90727112599994,
                40.83644139899997
              ],
              [
                -73.90721294199983,
                40.83653436399994
              ],
              [
                -73.90713954499992,
                40.83665163499993
              ],
              [
                -73.906975505,
                40.836913727999885
              ],
              [
                -73.90688497199996,
                40.837058376999885
              ],
              [
                -73.90679878899991,
                40.837196073999976
              ],
              [
                -73.90652353999998,
                40.83763584299988
              ],
              [
                -73.90646169600002,
                40.83773464999995
              ],
              [
                -73.90635674699993,
                40.837902326999924
              ],
              [
                -73.90625475300006,
                40.83806527999993
              ],
              [
                -73.90597940399996,
                40.83850519599985
              ],
              [
                -73.90538358099985,
                40.839768982999914
              ],
              [
                -73.90560739499988,
                40.8398436189999
              ],
              [
                -73.90548026199987,
                40.84005259199994
              ],
              [
                -73.90536832399985,
                40.84026688999993
              ],
              [
                -73.90527212299999,
                40.8404855589999
              ],
              [
                -73.90519207999992,
                40.84070758199993
              ],
              [
                -73.90515655599995,
                40.84104021299991
              ],
              [
                -73.90503176699985,
                40.842262944999916
              ],
              [
                -73.9050151499998,
                40.84240469799989
              ],
              [
                -73.90514893999988,
                40.84254719499995
              ],
              [
                -73.9058672189999,
                40.84257998699996
              ],
              [
                -73.90596061799985,
                40.84262870399992
              ],
              [
                -73.90684546599987,
                40.842667510999966
              ],
              [
                -73.90774413799994,
                40.84270215599994
              ],
              [
                -73.90865715699992,
                40.842719369999905
              ],
              [
                -73.90958727299986,
                40.84275637499993
              ],
              [
                -73.91046008499987,
                40.84279164299989
              ],
              [
                -73.91043026399994,
                40.8431077299999
              ],
              [
                -73.91096872799983,
                40.84313621399989
              ],
              [
                -73.91175379899997,
                40.84318035699991
              ],
              [
                -73.91193751499992,
                40.84320238799992
              ],
              [
                -73.91197045599988,
                40.84310439399994
              ],
              [
                -73.91222180499983,
                40.8423565909999
              ],
              [
                -73.91240381699996,
                40.84181649699989
              ],
              [
                -73.91246930299995,
                40.841620256999924
              ],
              [
                -73.9126225619999,
                40.84115715899992
              ],
              [
                -73.91269769199985,
                40.840930128999894
              ],
              [
                -73.9127782319999,
                40.840691064999945
              ],
              [
                -73.91296000899999,
                40.84019622899994
              ],
              [
                -73.91313156299994,
                40.83981053999986
              ],
              [
                -73.91371372699993,
                40.83880893499992
              ],
              [
                -73.91377104999991,
                40.83872164799992
              ],
              [
                -73.91383125899985,
                40.838639044999866
              ],
              [
                -73.91423754099988,
                40.83810879599992
              ],
              [
                -73.91477300600005,
                40.8375141009999
              ],
              [
                -73.9153159669999,
                40.83694742399997
              ],
              [
                -73.91587067499994,
                40.83639359399992
              ],
              [
                -73.91647433399987,
                40.83577903099991
              ],
              [
                -73.91667316899981,
                40.835567404999956
              ],
              [
                -73.91720735700002,
                40.835021513999884
              ],
              [
                -73.9176438849999,
                40.834575406999946
              ],
              [
                -73.91767974800003,
                40.834538755999894
              ],
              [
                -73.91770299099984,
                40.83451500899989
              ],
              [
                -73.91773115000001,
                40.834486230999886
              ],
              [
                -73.91778665899993,
                40.83442951199994
              ],
              [
                -73.91839346699996,
                40.83380941199988
              ],
              [
                -73.91903136700002,
                40.833087348999875
              ],
              [
                -73.919499471,
                40.832479491999905
              ],
              [
                -73.91986089399985,
                40.83194722699992
              ],
              [
                -73.9204611219999,
                40.83091482099995
              ],
              [
                -73.92117904700004,
                40.82962895599993
              ],
              [
                -73.9213893,
                40.82923701399988
              ],
              [
                -73.92174660200004,
                40.82860240899991
              ],
              [
                -73.92183738200004,
                40.82843621099994
              ],
              [
                -73.92211960699996,
                40.82792587599993
              ],
              [
                -73.922233014,
                40.827720809999924
              ],
              [
                -73.92264951599996,
                40.82697240999992
              ],
              [
                -73.92267670999986,
                40.826849995999936
              ],
              [
                -73.92273725099987,
                40.82671824999995
              ],
              [
                -73.9228330809999,
                40.82650710299994
              ],
              [
                -73.9229413879998,
                40.82629934999995
              ],
              [
                -73.92306184099985,
                40.82609561099993
              ],
              [
                -73.92347379199991,
                40.825413219999916
              ],
              [
                -73.92431310200004,
                40.823994097999915
              ],
              [
                -73.92437413499995,
                40.823820682999916
              ],
              [
                -73.92442266399996,
                40.823644865999924
              ],
              [
                -73.92445847599993,
                40.823467428999955
              ],
              [
                -73.92456530199985,
                40.82314545499991
              ],
              [
                -73.92469079500003,
                40.82282671599991
              ],
              [
                -73.9248346779998,
                40.82251216799988
              ],
              [
                -73.92499654899987,
                40.82220270099993
              ],
              [
                -73.92517593199985,
                40.82189919699996
              ],
              [
                -73.92537221999999,
                40.82160252099991
              ],
              [
                -73.92573567299983,
                40.82117423599991
              ],
              [
                -73.92565452999989,
                40.82112523299986
              ],
              [
                -73.92610404099997,
                40.82074597099995
              ],
              [
                -73.92628127099981,
                40.82053716099996
              ],
              [
                -73.92634345699997,
                40.8205785519999
              ],
              [
                -73.926624664,
                40.82002639399996
              ],
              [
                -73.92687781699988,
                40.81947887199991
              ],
              [
                -73.92731653099999,
                40.81855963399993
              ],
              [
                -73.92724662700006,
                40.818542984999965
              ],
              [
                -73.92635859099991,
                40.81832069199992
              ],
              [
                -73.92582070699982,
                40.81818033699992
              ],
              [
                -73.92538538499986,
                40.818065414999865
              ],
              [
                -73.92518486499998,
                40.81801266899989
              ],
              [
                -73.92454726099986,
                40.81871262399987
              ],
              [
                -73.92402557199983,
                40.8192853299999
              ],
              [
                -73.92265261199995,
                40.82049431499993
              ],
              [
                -73.92194764099997,
                40.82103154499987
              ],
              [
                -73.92114408999996,
                40.82156300199989
              ],
              [
                -73.92066539199996,
                40.82183689399994
              ],
              [
                -73.92054346999996,
                40.82193385699987
              ],
              [
                -73.92048603299985,
                40.82197953699993
              ],
              [
                -73.92038676299994,
                40.82205848399988
              ],
              [
                -73.91993471699983,
                40.82235220199986
              ],
              [
                -73.91980424599993,
                40.822436974999896
              ],
              [
                -73.91944303599995,
                40.822671667999884
              ],
              [
                -73.91938041799983,
                40.82271235199995
              ],
              [
                -73.91929142000006,
                40.82277017699989
              ],
              [
                -73.91888836899983,
                40.823029773999934
              ],
              [
                -73.91875570599991,
                40.8231152179999
              ],
              [
                -73.91833693999992,
                40.823384931999904
              ],
              [
                -73.91788272099983,
                40.8236787769999
              ],
              [
                -73.91782151699984,
                40.823718370999885
              ],
              [
                -73.91756599399989,
                40.823883671999944
              ],
              [
                -73.91746772799985,
                40.8239461069999
              ],
              [
                -73.9174045909999,
                40.82398622199988
              ],
              [
                -73.9173178239999,
                40.82404134999992
              ],
              [
                -73.91725010299993,
                40.824084376999956
              ],
              [
                -73.91719007399992,
                40.82412251699993
              ],
              [
                -73.917126938,
                40.82416263099988
              ],
              [
                -73.9168919579999,
                40.824311925999915
              ],
              [
                -73.91677400599986,
                40.82438708699987
              ],
              [
                -73.91608782899984,
                40.824837851999895
              ],
              [
                -73.91630917499997,
                40.82490286699987
              ],
              [
                -73.91661368499985,
                40.82499230799993
              ],
              [
                -73.91642451899982,
                40.825114912999936
              ],
              [
                -73.9163666169999,
                40.82515243999989
              ],
              [
                -73.91630388,
                40.825193101999936
              ],
              [
                -73.91624748099997,
                40.82522965499989
              ],
              [
                -73.91618778499992,
                40.825268345999895
              ],
              [
                -73.91613011899993,
                40.825305720999964
              ],
              [
                -73.91592284999992,
                40.8254400559999
              ],
              [
                -73.91545061499994,
                40.825747778999855
              ],
              [
                -73.91541108199998,
                40.82577390899994
              ],
              [
                -73.91527590199993,
                40.82565539499991
              ],
              [
                -73.91475121099991,
                40.82520047299994
              ],
              [
                -73.91351191699981,
                40.824804943999915
              ],
              [
                -73.91316674499997,
                40.82545645499987
              ],
              [
                -73.91192435199989,
                40.82504975899994
              ],
              [
                -73.91130974499993,
                40.824848557999914
              ],
              [
                -73.91179361999995,
                40.8264281559999
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000195458476728,
        "objectid": 70,
        "shape_leng": 0.0638403183367,
        "location_id": 70,
        "zone": "East Elmhurst",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.85912375899987,
                40.76127549899995
              ],
              [
                -73.8591354529999,
                40.76141844099988
              ],
              [
                -73.859666663,
                40.76190191299991
              ],
              [
                -73.85992937999985,
                40.76220030099995
              ],
              [
                -73.8610972439999,
                40.763664476999935
              ],
              [
                -73.86180888799991,
                40.764544707999896
              ],
              [
                -73.86286118599996,
                40.76584623599989
              ],
              [
                -73.86321805300003,
                40.76625081599989
              ],
              [
                -73.86340214900001,
                40.76645551199989
              ],
              [
                -73.86353063400003,
                40.76657622399993
              ],
              [
                -73.86404975699998,
                40.76706394799991
              ],
              [
                -73.86458486999996,
                40.76756668999991
              ],
              [
                -73.86509294099989,
                40.76801531499993
              ],
              [
                -73.8655058869999,
                40.76834576799996
              ],
              [
                -73.86606875999985,
                40.768796181999946
              ],
              [
                -73.86728193799998,
                40.769650002999896
              ],
              [
                -73.868098735,
                40.77017200899994
              ],
              [
                -73.8688938269999,
                40.77064902199992
              ],
              [
                -73.869437198,
                40.77092448199991
              ],
              [
                -73.86962970499988,
                40.77100636599993
              ],
              [
                -73.87016128299994,
                40.77120898499992
              ],
              [
                -73.87071447499991,
                40.771390689999926
              ],
              [
                -73.87143357299982,
                40.77157646699987
              ],
              [
                -73.87173533999989,
                40.7716225859999
              ],
              [
                -73.87223577699984,
                40.77169565899994
              ],
              [
                -73.87272561699997,
                40.7717366429999
              ],
              [
                -73.8732794149999,
                40.77176740099993
              ],
              [
                -73.87380524599989,
                40.77176797799989
              ],
              [
                -73.87426330499983,
                40.771753387999894
              ],
              [
                -73.87475943399997,
                40.771719986999926
              ],
              [
                -73.87517573299982,
                40.77167671199991
              ],
              [
                -73.87601656500001,
                40.77155104399987
              ],
              [
                -73.87608949699984,
                40.77153690299987
              ],
              [
                -73.87604778699992,
                40.77144359299995
              ],
              [
                -73.87600801500002,
                40.7713084929999
              ],
              [
                -73.87598378999998,
                40.77117614699994
              ],
              [
                -73.87597362699988,
                40.77104276199987
              ],
              [
                -73.87597756999998,
                40.77090960899993
              ],
              [
                -73.87600937699986,
                40.77048155299986
              ],
              [
                -73.87602197499997,
                40.77031200099994
              ],
              [
                -73.87601415599995,
                40.76981697599996
              ],
              [
                -73.87638792400001,
                40.76899955799996
              ],
              [
                -73.87625062099983,
                40.76816607499991
              ],
              [
                -73.87624365599994,
                40.76812831899985
              ],
              [
                -73.87623300399997,
                40.76808593799989
              ],
              [
                -73.87613975699989,
                40.76769550599995
              ],
              [
                -73.87586503899996,
                40.7660516539999
              ],
              [
                -73.875535095,
                40.76423051299992
              ],
              [
                -73.87532637000001,
                40.76305660499987
              ],
              [
                -73.87532089499993,
                40.763008007999886
              ],
              [
                -73.87531552900002,
                40.762944974999876
              ],
              [
                -73.87499575099986,
                40.761264321999924
              ],
              [
                -73.87549091099996,
                40.76135159499989
              ],
              [
                -73.87601371899996,
                40.76144373199992
              ],
              [
                -73.87580654599999,
                40.76030783599993
              ],
              [
                -73.87551080399989,
                40.758679635999975
              ],
              [
                -73.87513874300004,
                40.756633815999905
              ],
              [
                -73.87420803099994,
                40.75673156299994
              ],
              [
                -73.87365721799983,
                40.756803485999946
              ],
              [
                -73.87311054799987,
                40.75685088199991
              ],
              [
                -73.87221856399994,
                40.75694322399988
              ],
              [
                -73.87132395899997,
                40.75703633999996
              ],
              [
                -73.87041371399981,
                40.757132411999876
              ],
              [
                -73.86950314799998,
                40.7572280189999
              ],
              [
                -73.86848374899982,
                40.75733493699992
              ],
              [
                -73.86746336500002,
                40.75744153999991
              ],
              [
                -73.86656997600001,
                40.75753552099993
              ],
              [
                -73.86567436999994,
                40.7576295829999
              ],
              [
                -73.86461956599983,
                40.75769371599994
              ],
              [
                -73.86371499900002,
                40.757725670999875
              ],
              [
                -73.86280704199999,
                40.75775898399999
              ],
              [
                -73.8619061719999,
                40.75779486399995
              ],
              [
                -73.86099882399996,
                40.75782499299989
              ],
              [
                -73.8604616219999,
                40.75784373199993
              ],
              [
                -73.85992746199989,
                40.757864101999886
              ],
              [
                -73.85951332399986,
                40.75787858999996
              ],
              [
                -73.85888932400003,
                40.75789947599986
              ],
              [
                -73.85856138999993,
                40.75791243699991
              ],
              [
                -73.85775351199999,
                40.75802727499996
              ],
              [
                -73.85695186799988,
                40.758133126999894
              ],
              [
                -73.85560819899995,
                40.75830541099986
              ],
              [
                -73.85540366099997,
                40.75834030899992
              ],
              [
                -73.85530200199993,
                40.7582928419999
              ],
              [
                -73.85505441,
                40.758330370999936
              ],
              [
                -73.85490872699997,
                40.758336448999884
              ],
              [
                -73.85476880299996,
                40.7583281299999
              ],
              [
                -73.85462918699993,
                40.75830695499997
              ],
              [
                -73.85449593299987,
                40.75827863899985
              ],
              [
                -73.8545797199998,
                40.75838761999991
              ],
              [
                -73.85463995799994,
                40.75846615799992
              ],
              [
                -73.85500920899997,
                40.758879225999905
              ],
              [
                -73.855067026,
                40.758943277999954
              ],
              [
                -73.85525140099982,
                40.759147529999915
              ],
              [
                -73.85553311699984,
                40.759342590999935
              ],
              [
                -73.85576997499993,
                40.75953834299993
              ],
              [
                -73.85603980899994,
                40.75972578699986
              ],
              [
                -73.85638216199997,
                40.7599066399999
              ],
              [
                -73.85655895700003,
                40.75998156099995
              ],
              [
                -73.856705981,
                40.760043865999926
              ],
              [
                -73.85734910199996,
                40.76030063399989
              ],
              [
                -73.85754427999994,
                40.76037765399996
              ],
              [
                -73.85773604799981,
                40.760459477999866
              ],
              [
                -73.857924202,
                40.76054601799995
              ],
              [
                -73.85810853999996,
                40.76063718199991
              ],
              [
                -73.85828886699981,
                40.76073287299992
              ],
              [
                -73.8584649899998,
                40.760832988999915
              ],
              [
                -73.858636721,
                40.76093742299989
              ],
              [
                -73.85880387699986,
                40.761046063999935
              ],
              [
                -73.8589662809999,
                40.761158795999904
              ],
              [
                -73.85912375899987,
                40.76127549899995
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000382572587364,
        "objectid": 71,
        "shape_leng": 0.0931694551828,
        "location_id": 71,
        "zone": "East Flatbush/Farragut",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.93177153199984,
                40.655273641999905
              ],
              [
                -73.931907438,
                40.656537652999916
              ],
              [
                -73.93284657399983,
                40.6564784819999
              ],
              [
                -73.93381012099996,
                40.65642299299988
              ],
              [
                -73.93478437399983,
                40.65636039499992
              ],
              [
                -73.93571813299981,
                40.65630329699991
              ],
              [
                -73.936179278,
                40.656273971999916
              ],
              [
                -73.936684695,
                40.65624182299995
              ],
              [
                -73.93712063899989,
                40.656215902999904
              ],
              [
                -73.93765923499997,
                40.65618387599989
              ],
              [
                -73.93859348999989,
                40.65612237899993
              ],
              [
                -73.93955925399985,
                40.65606275299992
              ],
              [
                -73.94053214699989,
                40.65600153899994
              ],
              [
                -73.94146260999986,
                40.65594424899995
              ],
              [
                -73.94240147899986,
                40.655884810999964
              ],
              [
                -73.94335488899993,
                40.655828334999896
              ],
              [
                -73.94446083699984,
                40.655760165999936
              ],
              [
                -73.94487284399997,
                40.655734761999966
              ],
              [
                -73.94507909299995,
                40.65572204699989
              ],
              [
                -73.94715896299984,
                40.65559378699993
              ],
              [
                -73.94703257599998,
                40.6543387139999
              ],
              [
                -73.94606690599983,
                40.65441026199991
              ],
              [
                -73.94590468799986,
                40.65285464999991
              ],
              [
                -73.94570606199984,
                40.651006783999904
              ],
              [
                -73.94667414499983,
                40.65094811899995
              ],
              [
                -73.94647882399988,
                40.64910075099988
              ],
              [
                -73.94625972599981,
                40.64700621499989
              ],
              [
                -73.94607216799986,
                40.645311811999896
              ],
              [
                -73.94598813699993,
                40.644533796999916
              ],
              [
                -73.94586879199989,
                40.6434237439999
              ],
              [
                -73.94685570599987,
                40.64336214099991
              ],
              [
                -73.94778994699976,
                40.64330573599991
              ],
              [
                -73.94754496799997,
                40.641005475999904
              ],
              [
                -73.94744296799982,
                40.64004766499991
              ],
              [
                -73.94841343400003,
                40.639987463999944
              ],
              [
                -73.94826499699982,
                40.63860718999994
              ],
              [
                -73.94715150299994,
                40.63867640599994
              ],
              [
                -73.94638150299986,
                40.63872624999992
              ],
              [
                -73.94537272499997,
                40.63878695499988
              ],
              [
                -73.9451233509999,
                40.636448278999886
              ],
              [
                -73.94488042799988,
                40.63417695399994
              ],
              [
                -73.94462885499995,
                40.63183773499993
              ],
              [
                -73.94365994499996,
                40.631899426999915
              ],
              [
                -73.94272877999995,
                40.631957293999896
              ],
              [
                -73.94175680799997,
                40.63201662699988
              ],
              [
                -73.94078821499988,
                40.6320758459999
              ],
              [
                -73.94032359799989,
                40.63210526799983
              ],
              [
                -73.93985458399987,
                40.63213497499991
              ],
              [
                -73.93892456999993,
                40.63219308999988
              ],
              [
                -73.93799799799994,
                40.63224680599991
              ],
              [
                -73.93759273599986,
                40.63226785699987
              ],
              [
                -73.9374083309999,
                40.63228068499995
              ],
              [
                -73.93701746200003,
                40.63231097899991
              ],
              [
                -73.93703669099992,
                40.63250924999988
              ],
              [
                -73.93706492900004,
                40.632771166999916
              ],
              [
                -73.9372670019998,
                40.634650624999885
              ],
              [
                -73.93629596800004,
                40.634709525999874
              ],
              [
                -73.93535985000001,
                40.634767311999916
              ],
              [
                -73.93439440299989,
                40.634826802999925
              ],
              [
                -73.93342687599988,
                40.634884050999915
              ],
              [
                -73.93249995699995,
                40.63494706899993
              ],
              [
                -73.93223554799995,
                40.634963338999924
              ],
              [
                -73.9315273509998,
                40.63500796799989
              ],
              [
                -73.93055333099983,
                40.63506291499989
              ],
              [
                -73.92962453299995,
                40.63512131599988
              ],
              [
                -73.92861283799981,
                40.63518764599998
              ],
              [
                -73.92766858599995,
                40.635245022999925
              ],
              [
                -73.92744822299986,
                40.63525840999988
              ],
              [
                -73.92712769599991,
                40.635667455999936
              ],
              [
                -73.9269145769998,
                40.6359394399999
              ],
              [
                -73.92682085699984,
                40.63606066199992
              ],
              [
                -73.92674443099993,
                40.636173105999916
              ],
              [
                -73.92668615699995,
                40.636257087999915
              ],
              [
                -73.92652490899992,
                40.6364927229999
              ],
              [
                -73.9262739919998,
                40.637215327999876
              ],
              [
                -73.92616377799996,
                40.63757296099988
              ],
              [
                -73.9261268489999,
                40.63774352299989
              ],
              [
                -73.9260813459999,
                40.63816294599991
              ],
              [
                -73.92606338099993,
                40.63852406099988
              ],
              [
                -73.92604638799985,
                40.638603341999904
              ],
              [
                -73.92596691799999,
                40.63880208899997
              ],
              [
                -73.92590483099994,
                40.63900510599991
              ],
              [
                -73.92586068599987,
                40.63921107199993
              ],
              [
                -73.925834756,
                40.63941858799992
              ],
              [
                -73.92582711299985,
                40.639626269999894
              ],
              [
                -73.92574389399985,
                40.64001446599986
              ],
              [
                -73.92594627399988,
                40.639998162999866
              ],
              [
                -73.92622007399989,
                40.6399760949999
              ],
              [
                -73.92716497399995,
                40.639916461999874
              ],
              [
                -73.92761438900001,
                40.639887640999866
              ],
              [
                -73.92809818199994,
                40.63985661599988
              ],
              [
                -73.92910623099985,
                40.63979510599994
              ],
              [
                -73.9293545129999,
                40.64213431899986
              ],
              [
                -73.92959986299991,
                40.64443271899993
              ],
              [
                -73.92974213599982,
                40.645743383999914
              ],
              [
                -73.9298024389999,
                40.646320076999885
              ],
              [
                -73.92999915499996,
                40.64818106399987
              ],
              [
                -73.93020593200004,
                40.65010866699994
              ],
              [
                -73.93038144399998,
                40.65173405399986
              ],
              [
                -73.9313911669999,
                40.6516896079999
              ],
              [
                -73.93160868699992,
                40.65374163899989
              ],
              [
                -73.93177153199984,
                40.655273641999905
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000107893068218,
        "objectid": 79,
        "shape_leng": 0.0426249113144,
        "location_id": 79,
        "zone": "East Village",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.98377990999991,
                40.72153595399992
              ],
              [
                -73.98344363599992,
                40.722018506999895
              ],
              [
                -73.9829641439999,
                40.72267132499997
              ],
              [
                -73.98252449499985,
                40.72327797699997
              ],
              [
                -73.9820824239999,
                40.72388204399992
              ],
              [
                -73.98163663899999,
                40.72449348099987
              ],
              [
                -73.9812112959998,
                40.72507279999992
              ],
              [
                -73.98076713900005,
                40.725685594999916
              ],
              [
                -73.9803329819999,
                40.72628033599989
              ],
              [
                -73.97990650199998,
                40.72686577299988
              ],
              [
                -73.979469131,
                40.72746767699987
              ],
              [
                -73.97899862099985,
                40.72810574699991
              ],
              [
                -73.97853582999998,
                40.72874608499993
              ],
              [
                -73.97805542899995,
                40.72938308599991
              ],
              [
                -73.97802697999985,
                40.729433059999984
              ],
              [
                -73.97926849199995,
                40.72998142799986
              ],
              [
                -73.98034007599992,
                40.73044246099994
              ],
              [
                -73.98082000799998,
                40.73063915899997
              ],
              [
                -73.98150053399984,
                40.73091807699994
              ],
              [
                -73.98246876199994,
                40.731314883999914
              ],
              [
                -73.98255629299985,
                40.7313501339999
              ],
              [
                -73.98494102799992,
                40.73234760399986
              ],
              [
                -73.98718049699994,
                40.73329214799988
              ],
              [
                -73.98877931699997,
                40.733965399999924
              ],
              [
                -73.98990295999991,
                40.7344347899999
              ],
              [
                -73.98986852099996,
                40.73352711499992
              ],
              [
                -73.99002687699983,
                40.73275628399994
              ],
              [
                -73.99021190999987,
                40.731996333999895
              ],
              [
                -73.99039009999987,
                40.731288523999865
              ],
              [
                -73.99050558599988,
                40.73056495699996
              ],
              [
                -73.99072530399991,
                40.729878686999896
              ],
              [
                -73.99075728499993,
                40.7297408309999
              ],
              [
                -73.99093053499995,
                40.729142295999914
              ],
              [
                -73.99112256699988,
                40.72856796599994
              ],
              [
                -73.99135869199992,
                40.72778246999996
              ],
              [
                -73.99136388099987,
                40.72755978299991
              ],
              [
                -73.99154832699986,
                40.72708947099994
              ],
              [
                -73.99179379299984,
                40.72639145899994
              ],
              [
                -73.9920616679999,
                40.72564244299993
              ],
              [
                -73.99220971099992,
                40.72523128999992
              ],
              [
                -73.99232642199998,
                40.72490823199993
              ],
              [
                -73.99257987399992,
                40.724201554999915
              ],
              [
                -73.99260322199994,
                40.72413644999988
              ],
              [
                -73.99250385799984,
                40.72410592499995
              ],
              [
                -73.99135472799998,
                40.723754618999855
              ],
              [
                -73.99127307099994,
                40.7237298129999
              ],
              [
                -73.99102410399988,
                40.72364943199991
              ],
              [
                -73.99027361600001,
                40.72342601799987
              ],
              [
                -73.98939218099996,
                40.723174057999884
              ],
              [
                -73.98875683199988,
                40.72297036599986
              ],
              [
                -73.98863862799996,
                40.72293371999991
              ],
              [
                -73.98854361399987,
                40.722903617999904
              ],
              [
                -73.98786491500006,
                40.72269708899991
              ],
              [
                -73.98713584799998,
                40.72246981099991
              ],
              [
                -73.98628574000003,
                40.722241919999874
              ],
              [
                -73.98551686199984,
                40.72198522499987
              ],
              [
                -73.98467780299995,
                40.72173060499992
              ],
              [
                -73.9838238729998,
                40.72147287199987
              ],
              [
                -73.98377990999991,
                40.72153595399992
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.00040732245622,
        "objectid": 80,
        "shape_leng": 0.117212621448,
        "location_id": 80,
        "zone": "East Williamsburg",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.93174841322003,
                40.728053727696675
              ],
              [
                -73.93188125492881,
                40.72809538279412
              ],
              [
                -73.93196085293658,
                40.728125624359585
              ],
              [
                -73.93200420453815,
                40.72814508289344
              ],
              [
                -73.93226852175046,
                40.728174658015654
              ],
              [
                -73.93255438599991,
                40.727862889999905
              ],
              [
                -73.93412337399995,
                40.726855470999915
              ],
              [
                -73.93539740399996,
                40.725906866999956
              ],
              [
                -73.93676382399988,
                40.72483891899985
              ],
              [
                -73.93765314599989,
                40.72426555499987
              ],
              [
                -73.9384885709999,
                40.723808905999945
              ],
              [
                -73.93891183799992,
                40.72357882499994
              ],
              [
                -73.93931199899997,
                40.72334789399991
              ],
              [
                -73.94022123699996,
                40.72289150499995
              ],
              [
                -73.9410941879999,
                40.72230924499994
              ],
              [
                -73.94150380799985,
                40.7219909789999
              ],
              [
                -73.94195633599989,
                40.721652318999894
              ],
              [
                -73.94281683699987,
                40.721143620999904
              ],
              [
                -73.94367547999985,
                40.72065997499991
              ],
              [
                -73.94440536699992,
                40.72024041199988
              ],
              [
                -73.94560358899994,
                40.71956728599989
              ],
              [
                -73.94679211599993,
                40.7188216079999
              ],
              [
                -73.94721341799986,
                40.718560985999865
              ],
              [
                -73.94832167599995,
                40.71786451199997
              ],
              [
                -73.94866397500004,
                40.717682770999915
              ],
              [
                -73.94904281699998,
                40.717428518999924
              ],
              [
                -73.94954034299991,
                40.717121995999946
              ],
              [
                -73.9498379229999,
                40.716938665999876
              ],
              [
                -73.95004619199985,
                40.71680522799992
              ],
              [
                -73.95114433699997,
                40.71603452099991
              ],
              [
                -73.95182429600005,
                40.71547596899994
              ],
              [
                -73.95210878499995,
                40.71525889399989
              ],
              [
                -73.952753813,
                40.71473599099993
              ],
              [
                -73.95335294599992,
                40.71418365499992
              ],
              [
                -73.95348472199983,
                40.7140702079999
              ],
              [
                -73.95325272999993,
                40.71406562999987
              ],
              [
                -73.95316497599985,
                40.71406509799991
              ],
              [
                -73.9530314939999,
                40.714062846999916
              ],
              [
                -73.9529104979999,
                40.714060986999876
              ],
              [
                -73.95162366299994,
                40.714066360999894
              ],
              [
                -73.94937231899985,
                40.71406687799987
              ],
              [
                -73.94924526199999,
                40.7133203089999
              ],
              [
                -73.94913082999982,
                40.71261242099997
              ],
              [
                -73.94901358399997,
                40.71190375599989
              ],
              [
                -73.94889233899987,
                40.71116753499994
              ],
              [
                -73.94877149699997,
                40.71043225899995
              ],
              [
                -73.94865533499987,
                40.709724982999894
              ],
              [
                -73.95087492699996,
                40.7095131149999
              ],
              [
                -73.95082963899989,
                40.70914359799985
              ],
              [
                -73.95076027199995,
                40.70880285999993
              ],
              [
                -73.95065919499986,
                40.70816998299993
              ],
              [
                -73.95053010000007,
                40.707390984999954
              ],
              [
                -73.95040588999977,
                40.70665274599989
              ],
              [
                -73.95028918399986,
                40.70591343899986
              ],
              [
                -73.95023693799996,
                40.70547324599989
              ],
              [
                -73.94983788699977,
                40.70522902199989
              ],
              [
                -73.94947068299989,
                40.7050190189999
              ],
              [
                -73.949253357,
                40.70489593099995
              ],
              [
                -73.94866498899982,
                40.704565073999945
              ],
              [
                -73.94854962699999,
                40.704500430999936
              ],
              [
                -73.948140661,
                40.70427125399989
              ],
              [
                -73.94785367299988,
                40.70411138299988
              ],
              [
                -73.94705205299991,
                40.70366394899987
              ],
              [
                -73.94625107799986,
                40.70320874699995
              ],
              [
                -73.945854991,
                40.702987206999886
              ],
              [
                -73.94544988199996,
                40.70276063599993
              ],
              [
                -73.94463910199993,
                40.70231369399994
              ],
              [
                -73.94431460199992,
                40.70213334499988
              ],
              [
                -73.94400503999998,
                40.70196179199989
              ],
              [
                -73.94382473799989,
                40.70186200799988
              ],
              [
                -73.94322685999988,
                40.70152070899991
              ],
              [
                -73.94306406899986,
                40.701424434999886
              ],
              [
                -73.94220058700002,
                40.70089066699992
              ],
              [
                -73.94193078899983,
                40.7007252339999
              ],
              [
                -73.94042057899993,
                40.70107659799989
              ],
              [
                -73.9405445659999,
                40.70179804499987
              ],
              [
                -73.94060119499999,
                40.702141580999886
              ],
              [
                -73.9406199669999,
                40.70225544999991
              ],
              [
                -73.94067555499998,
                40.70259265099991
              ],
              [
                -73.94076481300007,
                40.70313403299987
              ],
              [
                -73.94077939399978,
                40.703222468999904
              ],
              [
                -73.9408928719998,
                40.70391415699989
              ],
              [
                -73.94103009799993,
                40.70463617699997
              ],
              [
                -73.94168041699996,
                40.70457403599988
              ],
              [
                -73.9417696579999,
                40.704565507999845
              ],
              [
                -73.94214324999996,
                40.70452980699987
              ],
              [
                -73.9422114949999,
                40.7045232859999
              ],
              [
                -73.94273448399997,
                40.70447330599988
              ],
              [
                -73.94285013599988,
                40.70518202999993
              ],
              [
                -73.94296619399988,
                40.705888439999924
              ],
              [
                -73.94308587399995,
                40.70659666199991
              ],
              [
                -73.94320222899984,
                40.7073347509999
              ],
              [
                -73.94332294399995,
                40.70807019499994
              ],
              [
                -73.94344009799994,
                40.70878089099988
              ],
              [
                -73.94354434699991,
                40.70941603099987
              ],
              [
                -73.94355745099986,
                40.709495864999965
              ],
              [
                -73.94357094699997,
                40.709578093999944
              ],
              [
                -73.94358512999999,
                40.70966450499994
              ],
              [
                -73.94367685499988,
                40.71022334799989
              ],
              [
                -73.94368828299991,
                40.71029296399993
              ],
              [
                -73.94378799599994,
                40.710900378999945
              ],
              [
                -73.94357731299993,
                40.71092028699991
              ],
              [
                -73.94326978899984,
                40.71094934499994
              ],
              [
                -73.94283637099988,
                40.71099029799991
              ],
              [
                -73.94245994699992,
                40.7110258639999
              ],
              [
                -73.94210580699989,
                40.71105932199989
              ],
              [
                -73.94196964799987,
                40.7110721309999
              ],
              [
                -73.94159179599998,
                40.71110767099993
              ],
              [
                -73.94116852899994,
                40.711147482999955
              ],
              [
                -73.94075355899997,
                40.71118651299991
              ],
              [
                -73.94042251999994,
                40.711217647999945
              ],
              [
                -73.9404771349999,
                40.71156264299988
              ],
              [
                -73.94068803099992,
                40.711962047999876
              ],
              [
                -73.93846617699984,
                40.71248925599993
              ],
              [
                -73.93832001399984,
                40.71252368499994
              ],
              [
                -73.93724803599986,
                40.71278274099989
              ],
              [
                -73.93480281900003,
                40.7133724959999
              ],
              [
                -73.93453329099987,
                40.71275330399992
              ],
              [
                -73.93425709399997,
                40.712123023999936
              ],
              [
                -73.93389272699982,
                40.711335677999905
              ],
              [
                -73.933601465,
                40.71066910599991
              ],
              [
                -73.93329596399984,
                40.70998771099993
              ],
              [
                -73.93299690799994,
                40.709317159999905
              ],
              [
                -73.93269215700002,
                40.708632035999905
              ],
              [
                -73.93252310899986,
                40.70823818799995
              ],
              [
                -73.93229708600003,
                40.707749446999884
              ],
              [
                -73.9320728499999,
                40.70778578599988
              ],
              [
                -73.92902566099997,
                40.70827681499986
              ],
              [
                -73.92740683799987,
                40.70854132199989
              ],
              [
                -73.92576502499996,
                40.70880376399988
              ],
              [
                -73.92418647499989,
                40.7090647289999
              ],
              [
                -73.92248241799982,
                40.709332487999895
              ],
              [
                -73.92189184699987,
                40.70939609599985
              ],
              [
                -73.92074519699985,
                40.71052968599989
              ],
              [
                -73.92109442099995,
                40.71072126499991
              ],
              [
                -73.92122073500002,
                40.71079055899986
              ],
              [
                -73.92154601699998,
                40.71104328399984
              ],
              [
                -73.92168663599989,
                40.71189431699987
              ],
              [
                -73.92223294699991,
                40.712854424999904
              ],
              [
                -73.92313936299986,
                40.713394727999905
              ],
              [
                -73.92404011299993,
                40.71400831299994
              ],
              [
                -73.92405909718518,
                40.71411155982241
              ],
              [
                -73.92433589757124,
                40.71412259346646
              ],
              [
                -73.92433637463594,
                40.71414008175541
              ],
              [
                -73.9243369910776,
                40.71420158735123
              ],
              [
                -73.92433779606827,
                40.71425370871095
              ],
              [
                -73.92434751117983,
                40.71430705300941
              ],
              [
                -73.92436718832793,
                40.7143308294748
              ],
              [
                -73.92439028050491,
                40.71434917913236
              ],
              [
                -73.92440910864588,
                40.71434991286799
              ],
              [
                -73.92441453117704,
                40.714348538518855
              ],
              [
                -73.9244191096702,
                40.71434961252492
              ],
              [
                -73.92443153174062,
                40.71436477617636
              ],
              [
                -73.92448221734742,
                40.71452345230062
              ],
              [
                -73.92451226161313,
                40.71462077218059
              ],
              [
                -73.9245258166989,
                40.71469041784695
              ],
              [
                -73.92455568744005,
                40.71475365373279
              ],
              [
                -73.92461501505854,
                40.71482708365424
              ],
              [
                -73.92466013078389,
                40.71486688040726
              ],
              [
                -73.92469009338394,
                40.714880957032996
              ],
              [
                -73.92472981629032,
                40.71493796647527
              ],
              [
                -73.92476155049336,
                40.71497597144838
              ],
              [
                -73.92476493340077,
                40.71498865277085
              ],
              [
                -73.92476257206151,
                40.71500407627395
              ],
              [
                -73.92476128782185,
                40.71501947417911
              ],
              [
                -73.92476629284465,
                40.71503204900921
              ],
              [
                -73.92478745137134,
                40.71507559354602
              ],
              [
                -73.92485230935999,
                40.71519860069832
              ],
              [
                -73.92488553507611,
                40.71526718765261
              ],
              [
                -73.92483287559033,
                40.71528291204137
              ],
              [
                -73.92459408281036,
                40.71553437585544
              ],
              [
                -73.92431329961565,
                40.715847056576735
              ],
              [
                -73.92359001003088,
                40.71602814919749
              ],
              [
                -73.92361145155542,
                40.716103508171265
              ],
              [
                -73.92347144070784,
                40.71614666662387
              ],
              [
                -73.9234316927592,
                40.71617252109859
              ],
              [
                -73.92340491085959,
                40.716192404479855
              ],
              [
                -73.92338579746806,
                40.71621278917321
              ],
              [
                -73.92336627583238,
                40.716217305606335
              ],
              [
                -73.92333477379717,
                40.71622532676395
              ],
              [
                -73.92331244916073,
                40.71622884989675
              ],
              [
                -73.92326039798908,
                40.716249734135324
              ],
              [
                -73.92305500014334,
                40.71634266479336
              ],
              [
                -73.92307579911487,
                40.716389234766446
              ],
              [
                -73.92310361124972,
                40.71645153249399
              ],
              [
                -73.92311149603759,
                40.71646918823156
              ],
              [
                -73.923117181684,
                40.71647618845655
              ],
              [
                -73.92313445722395,
                40.716479468945344
              ],
              [
                -73.92316776085262,
                40.716478915386126
              ],
              [
                -73.92323157113597,
                40.71669943915755
              ],
              [
                -73.92328653237342,
                40.71683400310812
              ],
              [
                -73.92335355684713,
                40.71696589948907
              ],
              [
                -73.92336526384003,
                40.71697452587305
              ],
              [
                -73.92337052250669,
                40.71698144505282
              ],
              [
                -73.92337834167141,
                40.716994246299244
              ],
              [
                -73.92338539779374,
                40.71700294966363
              ],
              [
                -73.92339299433871,
                40.71701539074552
              ],
              [
                -73.92340130597819,
                40.717032083298584
              ],
              [
                -73.92340522164903,
                40.71704449445611
              ],
              [
                -73.923410699233,
                40.717056925248095
              ],
              [
                -73.92341861434718,
                40.7170690157387
              ],
              [
                -73.92342891999935,
                40.717081999229585
              ],
              [
                -73.92344002043036,
                40.71709292970288
              ],
              [
                -73.92345233852708,
                40.7171058964369
              ],
              [
                -73.92346278696195,
                40.717117646016206
              ],
              [
                -73.92347481994251,
                40.71713080085855
              ],
              [
                -73.92348498545175,
                40.7171421721395
              ],
              [
                -73.92349611939407,
                40.71715461539784
              ],
              [
                -73.92351052402459,
                40.71717381467611
              ],
              [
                -73.92351902168116,
                40.71718501413589
              ],
              [
                -73.92352727087955,
                40.71719515047171
              ],
              [
                -73.92353534366401,
                40.717204566491326
              ],
              [
                -73.92354950298376,
                40.71722055092756
              ],
              [
                -73.92355875481299,
                40.71723477608636
              ],
              [
                -73.9235660685184,
                40.7172452714784
              ],
              [
                -73.92357604244324,
                40.717258425947975
              ],
              [
                -73.92358786233967,
                40.71727158063685
              ],
              [
                -73.92360155242615,
                40.71728473773811
              ],
              [
                -73.92361499494635,
                40.71729682250124
              ],
              [
                -73.92362699414979,
                40.71730857309421
              ],
              [
                -73.92363989262059,
                40.71732065749333
              ],
              [
                -73.92366000842217,
                40.71733551941523
              ],
              [
                -73.92367370384753,
                40.71734403836097
              ],
              [
                -73.9236873979865,
                40.71735310679241
              ],
              [
                -73.92370971501525,
                40.71736731362656
              ],
              [
                -73.9237283193837,
                40.7173782765184
              ],
              [
                -73.92374875470074,
                40.7173921758591
              ],
              [
                -73.92377013710404,
                40.71740599440512
              ],
              [
                -73.92378242354737,
                40.71741459365532
              ],
              [
                -73.92379521885265,
                40.71742355392657
              ],
              [
                -73.92380657161958,
                40.717431494845535
              ],
              [
                -73.92381472136069,
                40.71743663242616
              ],
              [
                -73.92383238966089,
                40.717448477883465
              ],
              [
                -73.92385189177753,
                40.717460755430274
              ],
              [
                -73.92387326148531,
                40.71747454664161
              ],
              [
                -73.92389417064558,
                40.7174890594181
              ],
              [
                -73.92390367881583,
                40.71749562069653
              ],
              [
                -73.9239313285829,
                40.71751413595133
              ],
              [
                -73.92394037469352,
                40.71752081385513
              ],
              [
                -73.92395450324652,
                40.71753361051829
              ],
              [
                -73.92396563780986,
                40.71754641558886
              ],
              [
                -73.92397727997053,
                40.71755992092113
              ],
              [
                -73.92398912454873,
                40.71757201446884
              ],
              [
                -73.92400123172588,
                40.71758340440814
              ],
              [
                -73.92401456906461,
                40.71759477706518
              ],
              [
                -73.92403042353494,
                40.71760939353006
              ],
              [
                -73.92404826607323,
                40.71762433680912
              ],
              [
                -73.92406646435992,
                40.7176385671579
              ],
              [
                -73.9240842361937,
                40.71765219513093
              ],
              [
                -73.92410280094333,
                40.717667137202426
              ],
              [
                -73.9241199951009,
                40.71767936057827
              ],
              [
                -73.92413531121534,
                40.717689582275256
              ],
              [
                -73.92415062601867,
                40.71769999277199
              ],
              [
                -73.92416598865648,
                40.717709971916534
              ],
              [
                -73.92418084168771,
                40.7177202470784
              ],
              [
                -73.92419082355931,
                40.71772697114596
              ],
              [
                -73.92420571235455,
                40.71773697627477
              ],
              [
                -73.9242219623098,
                40.71774774806305
              ],
              [
                -73.9242335769337,
                40.71775456383365
              ],
              [
                -73.9242456522157,
                40.71776173070737
              ],
              [
                -73.92426006927353,
                40.717770880796486
              ],
              [
                -73.92427585871782,
                40.71778060757632
              ],
              [
                -73.92429305607753,
                40.717790065394034
              ],
              [
                -73.92431306786708,
                40.717802154896944
              ],
              [
                -73.92433303151248,
                40.71781399072887
              ],
              [
                -73.92435070091092,
                40.71782523183707
              ],
              [
                -73.92436927002785,
                40.71783642009241
              ],
              [
                -73.9243902055647,
                40.717848256552855
              ],
              [
                -73.92440877531868,
                40.71785889481144
              ],
              [
                -73.92442924671535,
                40.71787229884444
              ],
              [
                -73.92445062958913,
                40.71788537964225
              ],
              [
                -73.92446061312032,
                40.71789087956812
              ],
              [
                -73.92447781046084,
                40.71790098551972
              ],
              [
                -73.9244926653784,
                40.717909675837646
              ],
              [
                -73.92450755701465,
                40.717917637268215
              ],
              [
                -73.92452081662728,
                40.71792448139508
              ],
              [
                -73.9245380167141,
                40.717932371796
              ],
              [
                -73.92455290971758,
                40.71793913473411
              ],
              [
                -73.92456824137071,
                40.71794488190683
              ],
              [
                -73.92458407107215,
                40.71795024074147
              ],
              [
                -73.92458693486758,
                40.71795070264624
              ],
              [
                -73.92460339476072,
                40.71795330607338
              ],
              [
                -73.9246255503998,
                40.71795384326465
              ],
              [
                -73.92464513863976,
                40.71795310891736
              ],
              [
                -73.92466004074598,
                40.71795144323361
              ],
              [
                -73.92467591434051,
                40.71794992259175
              ],
              [
                -73.92469362102345,
                40.71794812488464
              ],
              [
                -73.92471227577259,
                40.717946155912
              ],
              [
                -73.92472906134887,
                40.71794405068779
              ],
              [
                -73.92475077183727,
                40.717940443953374
              ],
              [
                -73.92477173579029,
                40.717936947127406
              ],
              [
                -73.92479461917726,
                40.71793177393681
              ],
              [
                -73.92482214344714,
                40.717925092512104
              ],
              [
                -73.92483730903177,
                40.71792192342346
              ],
              [
                -73.92486299711757,
                40.71791650059257
              ],
              [
                -73.92488911247905,
                40.71791154744499
              ],
              [
                -73.924902393685,
                40.717909422318826
              ],
              [
                -73.92492621081297,
                40.71790485414831
              ],
              [
                -73.92494252442464,
                40.71790088484829
              ],
              [
                -73.92495489653578,
                40.71789814597476
              ],
              [
                -73.9249747370717,
                40.7178938995342
              ],
              [
                -73.9249974068021,
                40.71788955508726
              ],
              [
                -73.92514606059815,
                40.71785561237676
              ],
              [
                -73.92594091108849,
                40.71767415165965
              ],
              [
                -73.92595163943948,
                40.717691070569906
              ],
              [
                -73.92809688744708,
                40.717129034669306
              ],
              [
                -73.92812006106152,
                40.71712843649421
              ],
              [
                -73.92814336621645,
                40.717126524817886
              ],
              [
                -73.9281665195066,
                40.7171233328014
              ],
              [
                -73.9281892718893,
                40.71711888022515
              ],
              [
                -73.92821135004561,
                40.71711323979312
              ],
              [
                -73.9282325304494,
                40.71710645375078
              ],
              [
                -73.92825263489712,
                40.71709859586682
              ],
              [
                -73.92827145107027,
                40.71708972883213
              ],
              [
                -73.92832241942101,
                40.7170624562005
              ],
              [
                -73.92843622401524,
                40.71699883426711
              ],
              [
                -73.92848910920299,
                40.716971041911755
              ],
              [
                -73.92857581108358,
                40.71692983368453
              ],
              [
                -73.92861009191238,
                40.71691233129565
              ],
              [
                -73.92864365422388,
                40.716893243646275
              ],
              [
                -73.92867728796146,
                40.71687240872567
              ],
              [
                -73.92871111320488,
                40.716849745859804
              ],
              [
                -73.92874531987793,
                40.716825128348454
              ],
              [
                -73.92876805027393,
                40.71680733061646
              ],
              [
                -73.9287905809,
                40.71678793857015
              ],
              [
                -73.92883566172434,
                40.7167437780346
              ],
              [
                -73.92885446710963,
                40.71672257421461
              ],
              [
                -73.92887072694849,
                40.716701449064075
              ],
              [
                -73.92888441854586,
                40.71668042166825
              ],
              [
                -73.92889551897622,
                40.71665951948846
              ],
              [
                -73.9289025104377,
                40.71664010793749
              ],
              [
                -73.92890601265002,
                40.71661895731207
              ],
              [
                -73.92890598986219,
                40.716596354731784
              ],
              [
                -73.9289024285406,
                40.71657281231789
              ],
              [
                -73.92843881814578,
                40.71553094957575
              ],
              [
                -73.92925366567535,
                40.715328802539794
              ],
              [
                -73.92927167066954,
                40.71531421697877
              ],
              [
                -73.92931412285716,
                40.71527983468949
              ],
              [
                -73.9299027990129,
                40.71512496347697
              ],
              [
                -73.9299166552831,
                40.71514981699939
              ],
              [
                -73.93085190134036,
                40.71490298559277
              ],
              [
                -73.93105231901723,
                40.71461022392334
              ],
              [
                -73.93095073591746,
                40.71436381947845
              ],
              [
                -73.9307716654745,
                40.71392946758245
              ],
              [
                -73.93042263899005,
                40.71319163126945
              ],
              [
                -73.93042294313065,
                40.71317255867104
              ],
              [
                -73.93042448583893,
                40.71316958700425
              ],
              [
                -73.93042631105753,
                40.71316678789339
              ],
              [
                -73.93043058814568,
                40.713161549060736
              ],
              [
                -73.93043603849989,
                40.713156086450766
              ],
              [
                -73.93046418241934,
                40.71313167232028
              ],
              [
                -73.93047361522203,
                40.71311996285386
              ],
              [
                -73.93049150439325,
                40.71309041971448
              ],
              [
                -73.93050113783234,
                40.713077790811234
              ],
              [
                -73.93050607875219,
                40.713073137548186
              ],
              [
                -73.93051205952919,
                40.71306888245433
              ],
              [
                -73.9305190693687,
                40.71306503188887
              ],
              [
                -73.9305271320743,
                40.71306156090454
              ],
              [
                -73.93054642776369,
                40.71305574646064
              ],
              [
                -73.9305711431028,
                40.71305109691458
              ],
              [
                -73.93066788646628,
                40.713021266893655
              ],
              [
                -73.93173436851761,
                40.71274929223798
              ],
              [
                -73.93177226424528,
                40.71272495661109
              ],
              [
                -73.93179285963701,
                40.71271003780264
              ],
              [
                -73.93180970336466,
                40.71269477484576
              ],
              [
                -73.93181633870033,
                40.71268716098756
              ],
              [
                -73.93182187286679,
                40.712679465226174
              ],
              [
                -73.93183415746144,
                40.71265537457735
              ],
              [
                -73.93184249493441,
                40.71262723799059
              ],
              [
                -73.93184794872995,
                40.712593491059096
              ],
              [
                -73.93184868877432,
                40.712564774183285
              ],
              [
                -73.93184635118098,
                40.71254724913816
              ],
              [
                -73.93184122321138,
                40.71252826277801
              ],
              [
                -73.93183334985444,
                40.712507527987725
              ],
              [
                -73.93182042077258,
                40.712478983611106
              ],
              [
                -73.93161032734847,
                40.71203802318303
              ],
              [
                -73.93086409444767,
                40.71221819268689
              ],
              [
                -73.93085528765455,
                40.712220663782006
              ],
              [
                -73.93084682544475,
                40.71222140637214
              ],
              [
                -73.93083369272601,
                40.71221792246414
              ],
              [
                -73.9308256373487,
                40.71221423370464
              ],
              [
                -73.93081894298919,
                40.712210349085794
              ],
              [
                -73.93081258043564,
                40.71220580594958
              ],
              [
                -73.93080080544333,
                40.71219469685591
              ],
              [
                -73.930790204141,
                40.712180822260486
              ],
              [
                -73.93078037522965,
                40.71216370664792
              ],
              [
                -73.93077894737037,
                40.71216042795544
              ],
              [
                -73.9307778853388,
                40.712157013953664
              ],
              [
                -73.93077687548653,
                40.712149782544756
              ],
              [
                -73.93077736917618,
                40.712142064536366
              ],
              [
                -73.9307793659171,
                40.712133907170696
              ],
              [
                -73.93078286503948,
                40.71212531932618
              ],
              [
                -73.93078780933577,
                40.71211647921726
              ],
              [
                -73.93080159026496,
                40.71209889079764
              ],
              [
                -73.93080841450525,
                40.71209238429651
              ],
              [
                -73.93081637309481,
                40.71208622106957
              ],
              [
                -73.9308357102634,
                40.71207493198576
              ],
              [
                -73.93085966173909,
                40.71206494115675
              ],
              [
                -73.93088881912188,
                40.71205600685817
              ],
              [
                -73.93154613746852,
                40.711855351751026
              ],
              [
                -73.93115836965862,
                40.711001048544844
              ],
              [
                -73.93103036450829,
                40.710688548704475
              ],
              [
                -73.9309979955702,
                40.71059932449671
              ],
              [
                -73.93093021730881,
                40.710421171754376
              ],
              [
                -73.93090318414211,
                40.710355886650596
              ],
              [
                -73.93087163508754,
                40.710287546149786
              ],
              [
                -73.93085266627386,
                40.71025176672247
              ],
              [
                -73.93080914645333,
                40.71017853827858
              ],
              [
                -73.930791487333,
                40.71014664005744
              ],
              [
                -73.93076957459168,
                40.71009825209841
              ],
              [
                -73.93074537263652,
                40.7100313020978
              ],
              [
                -73.93074145900518,
                40.710016009212055
              ],
              [
                -73.93073947816461,
                40.709998538129256
              ],
              [
                -73.93074036930459,
                40.70993797930223
              ],
              [
                -73.93073890171183,
                40.709915357740414
              ],
              [
                -73.93073348083954,
                40.70989336336667
              ],
              [
                -73.93072912419363,
                40.70988365305337
              ],
              [
                -73.93072360705274,
                40.709874645320326
              ],
              [
                -73.93071575895901,
                40.70986497746762
              ],
              [
                -73.93070567535537,
                40.7098551735768
              ],
              [
                -73.93065009956263,
                40.7098127901007
              ],
              [
                -73.93063814682539,
                40.70980176866385
              ],
              [
                -73.93062904674879,
                40.709791038264015
              ],
              [
                -73.93060170783275,
                40.7097467525867
              ],
              [
                -73.93037671406199,
                40.70922233953024
              ],
              [
                -73.93025038849073,
                40.708927912919975
              ],
              [
                -73.93025651835315,
                40.70891654288407
              ],
              [
                -73.93026509729741,
                40.70890609372026
              ],
              [
                -73.93026628094589,
                40.70890492409292
              ],
              [
                -73.93028879813227,
                40.70888572873883
              ],
              [
                -73.93035053503768,
                40.708840137758
              ],
              [
                -73.93036285425627,
                40.70882846505405
              ],
              [
                -73.93037287805068,
                40.70881587324679
              ],
              [
                -73.9303802173509,
                40.70880284776286
              ],
              [
                -73.93038466900703,
                40.70878976558311
              ],
              [
                -73.93038574076616,
                40.708782319149
              ],
              [
                -73.93038563133163,
                40.70877453878897
              ],
              [
                -73.9303818492876,
                40.708757768713866
              ],
              [
                -73.93037720999881,
                40.70874751861006
              ],
              [
                -73.93037347956368,
                40.708739303504935
              ],
              [
                -73.9303609456579,
                40.7087177385352
              ],
              [
                -73.93050100083194,
                40.70868122503959
              ],
              [
                -73.93050203180053,
                40.7086902949094
              ],
              [
                -73.93050712316408,
                40.70871122513414
              ],
              [
                -73.93051585863094,
                40.70873176236183
              ],
              [
                -73.93052572285508,
                40.708747608338385
              ],
              [
                -73.93052794395754,
                40.708751183869595
              ],
              [
                -73.93054296635056,
                40.70876887089745
              ],
              [
                -73.93057213306344,
                40.708794868260725
              ],
              [
                -73.93064180374176,
                40.70884694166527
              ],
              [
                -73.93067043926465,
                40.70887107393901
              ],
              [
                -73.93069593784345,
                40.708896313011245
              ],
              [
                -73.93071882196693,
                40.70892173947503
              ],
              [
                -73.93073903156917,
                40.708947316942016
              ],
              [
                -73.93075653104769,
                40.708972982737215
              ],
              [
                -73.93075999154773,
                40.70897937849044
              ],
              [
                -73.93076270533346,
                40.70898637705964
              ],
              [
                -73.93076602623842,
                40.70900254307666
              ],
              [
                -73.93076683651668,
                40.709019167445526
              ],
              [
                -73.93076600066632,
                40.70906065402331
              ],
              [
                -73.93076857210804,
                40.70908113257449
              ],
              [
                -73.93077199333129,
                40.709091013034765
              ],
              [
                -73.930776931098,
                40.709099480980846
              ],
              [
                -73.93078002706979,
                40.70910330128766
              ],
              [
                -73.93078352647346,
                40.709106798007056
              ],
              [
                -73.93078694295173,
                40.709109590392615
              ],
              [
                -73.93078745172905,
                40.709110005160596
              ],
              [
                -73.9307918037048,
                40.70911293481055
              ],
              [
                -73.93079490181064,
                40.70911470106856
              ],
              [
                -73.93093227547743,
                40.709165311254075
              ],
              [
                -73.93102978770145,
                40.70935851220728
              ],
              [
                -73.93126572703261,
                40.709924443552936
              ],
              [
                -73.93151092590583,
                40.710469808394244
              ],
              [
                -73.93166398860761,
                40.71081024398526
              ],
              [
                -73.9319010192085,
                40.71133847142828
              ],
              [
                -73.93328349320237,
                40.7109787074579
              ],
              [
                -73.93338484615303,
                40.71123372774566
              ],
              [
                -73.93203262980515,
                40.71159022307427
              ],
              [
                -73.93264693956343,
                40.71300073028624
              ],
              [
                -73.93264917373008,
                40.71301581505501
              ],
              [
                -73.93264823497748,
                40.7130310430457
              ],
              [
                -73.93264747285893,
                40.71303487863322
              ],
              [
                -73.93264621458813,
                40.71303927229799
              ],
              [
                -73.93264437548959,
                40.71304370180868
              ],
              [
                -73.9326390524247,
                40.71305258616936
              ],
              [
                -73.93263159981665,
                40.7130613711127
              ],
              [
                -73.93262226609968,
                40.713069749371755
              ],
              [
                -73.93261675980561,
                40.71307367214419
              ],
              [
                -73.93261051935492,
                40.71307728824736
              ],
              [
                -73.9325956361949,
                40.71308370067655
              ],
              [
                -73.93257765480163,
                40.71308911115325
              ],
              [
                -73.93255041730532,
                40.713095371261986
              ],
              [
                -73.9324516315665,
                40.71312171743929
              ],
              [
                -73.93197184127631,
                40.7132532609102
              ],
              [
                -73.93164527391656,
                40.71334279375817
              ],
              [
                -73.93159144262145,
                40.713384815917905
              ],
              [
                -73.9315560427113,
                40.71339947275473
              ],
              [
                -73.93145301733047,
                40.713438844463575
              ],
              [
                -73.93124067059496,
                40.713724810884706
              ],
              [
                -73.93148254530634,
                40.714221748840004
              ],
              [
                -73.93154203825726,
                40.714359606502086
              ],
              [
                -73.93155662007531,
                40.71439338442301
              ],
              [
                -73.93159048356532,
                40.71439949248126
              ],
              [
                -73.93175357182274,
                40.714741675505685
              ],
              [
                -73.93213686921216,
                40.71467904647472
              ],
              [
                -73.93214128153325,
                40.714681291591674
              ],
              [
                -73.932149986012,
                40.714687464730225
              ],
              [
                -73.93215361559824,
                40.714691042229894
              ],
              [
                -73.93215665337218,
                40.71469483448544
              ],
              [
                -73.93217367921343,
                40.714722139519424
              ],
              [
                -73.9321969843879,
                40.71476644888617
              ],
              [
                -73.93221519306171,
                40.71480561350932
              ],
              [
                -73.93223001508791,
                40.7148469655458
              ],
              [
                -73.93223397469464,
                40.71486442837482
              ],
              [
                -73.9322353635064,
                40.71488285313794
              ],
              [
                -73.93223469045103,
                40.71489346121944
              ],
              [
                -73.93223414916336,
                40.71490201583286
              ],
              [
                -73.93223037717549,
                40.71492154524628
              ],
              [
                -73.93222529811959,
                40.71493579746084
              ],
              [
                -73.93221363282309,
                40.71495510661935
              ],
              [
                -73.93220607307306,
                40.71496398092937
              ],
              [
                -73.93219753146339,
                40.71497210749034
              ],
              [
                -73.9321557326611,
                40.71500605917621
              ],
              [
                -73.93212999514387,
                40.71502560378398
              ],
              [
                -73.93210478113056,
                40.715042599269246
              ],
              [
                -73.93208047053639,
                40.715056497540985
              ],
              [
                -73.9320446165215,
                40.71507417192053
              ],
              [
                -73.93198814917388,
                40.71510013641414
              ],
              [
                -73.93190482995988,
                40.7151375838835
              ],
              [
                -73.9318324282947,
                40.71516852832981
              ],
              [
                -73.93179267229117,
                40.71518297509124
              ],
              [
                -73.93175008954245,
                40.7151945849367
              ],
              [
                -73.93170534538301,
                40.71520316761237
              ],
              [
                -73.93165926717428,
                40.715208552394266
              ],
              [
                -73.9315843812832,
                40.71521425275003
              ],
              [
                -73.93155892431572,
                40.715214850767445
              ],
              [
                -73.93153596540606,
                40.71521416080974
              ],
              [
                -73.93153325506185,
                40.715213988487854
              ],
              [
                -73.93151298432782,
                40.71521190462473
              ],
              [
                -73.93149304610854,
                40.71520848107516
              ],
              [
                -73.93029962426964,
                40.71550787636393
              ],
              [
                -73.92957543323405,
                40.7156762736554
              ],
              [
                -73.9295592038559,
                40.715679649111
              ],
              [
                -73.92954313880661,
                40.715684196683405
              ],
              [
                -73.92952747418936,
                40.71568984129773
              ],
              [
                -73.92951247231095,
                40.71569650521456
              ],
              [
                -73.92949834532271,
                40.71570406928501
              ],
              [
                -73.92948531923271,
                40.71571242793816
              ],
              [
                -73.92947356921455,
                40.715721452621146
              ],
              [
                -73.92946325081381,
                40.71573104475624
              ],
              [
                -73.92944067813087,
                40.71575657861639
              ],
              [
                -73.92941981817356,
                40.71578500369685
              ],
              [
                -73.92940052893961,
                40.7158165187663
              ],
              [
                -73.92938250209322,
                40.71585162805965
              ],
              [
                -73.92936131668621,
                40.71589831554491
              ],
              [
                -73.9293448342123,
                40.715938917983024
              ],
              [
                -73.9293317763477,
                40.71597656097954
              ],
              [
                -73.9293218797775,
                40.71601207044922
              ],
              [
                -73.92931651965557,
                40.7160438378059
              ],
              [
                -73.92931572636294,
                40.71607810126978
              ],
              [
                -73.92931999766078,
                40.716112008832646
              ],
              [
                -73.9295016940007,
                40.71649978980536
              ],
              [
                -73.92949731486091,
                40.716522137729534
              ],
              [
                -73.92949639794345,
                40.71652681971331
              ],
              [
                -73.92949464975426,
                40.716535769271715
              ],
              [
                -73.92949347200953,
                40.71656326993933
              ],
              [
                -73.92949390595588,
                40.71656703436778
              ],
              [
                -73.92949561546526,
                40.71657342087128
              ],
              [
                -73.92949846168197,
                40.71657965377863
              ],
              [
                -73.92950234927692,
                40.71658551842918
              ],
              [
                -73.92950714859491,
                40.716590780875954
              ],
              [
                -73.9296861770915,
                40.71697307523372
              ],
              [
                -73.92969285538712,
                40.716981725376904
              ],
              [
                -73.92969847812826,
                40.716991030240365
              ],
              [
                -73.92970612734644,
                40.71701080158016
              ],
              [
                -73.92970798609383,
                40.71702080692028
              ],
              [
                -73.92970848430281,
                40.7170306673565
              ],
              [
                -73.92970762282195,
                40.717040203803165
              ],
              [
                -73.92970539915328,
                40.71704927938918
              ],
              [
                -73.92969572853036,
                40.717073929091114
              ],
              [
                -73.92968295619124,
                40.71709844304385
              ],
              [
                -73.92966723720504,
                40.71712254843958
              ],
              [
                -73.92964876146102,
                40.71714594216955
              ],
              [
                -73.92963940071803,
                40.71715649885374
              ],
              [
                -73.92960578835351,
                40.71719018455503
              ],
              [
                -73.92956856905528,
                40.71722153556257
              ],
              [
                -73.92920294211369,
                40.717401098006235
              ],
              [
                -73.92918539042974,
                40.71741165011861
              ],
              [
                -73.92916989772768,
                40.71742355510232
              ],
              [
                -73.92915700695038,
                40.717436414577925
              ],
              [
                -73.92914701545092,
                40.7174499159568
              ],
              [
                -73.92914530784584,
                40.7174531394639
              ],
              [
                -73.92914397867989,
                40.71745661399173
              ],
              [
                -73.92914245456932,
                40.71746451965398
              ],
              [
                -73.92914297144002,
                40.71749071598783
              ],
              [
                -73.92914231209284,
                40.71749933299893
              ],
              [
                -73.92914020897827,
                40.71750676236067
              ],
              [
                -73.92913856054392,
                40.71751003800504
              ],
              [
                -73.92913648610188,
                40.71751307131063
              ],
              [
                -73.9291243277067,
                40.71752729272453
              ],
              [
                -73.92911003942831,
                40.71754160111234
              ],
              [
                -73.92909346674548,
                40.7175561612246
              ],
              [
                -73.92907419357621,
                40.71757133281935
              ],
              [
                -73.92898221166264,
                40.717636571237165
              ],
              [
                -73.92896850706042,
                40.71764754015082
              ],
              [
                -73.92889108105969,
                40.717713311164225
              ],
              [
                -73.92887965179995,
                40.71772031862358
              ],
              [
                -73.92886642560443,
                40.71772634410138
              ],
              [
                -73.92885202896086,
                40.71773110771185
              ],
              [
                -73.92883713697681,
                40.71773436746044
              ],
              [
                -73.92882252966149,
                40.717735997681196
              ],
              [
                -73.92880855316628,
                40.71773597144914
              ],
              [
                -73.9287956187773,
                40.71773429739506
              ],
              [
                -73.92878963057198,
                40.717732852122325
              ],
              [
                -73.92878398825955,
                40.717731002485756
              ],
              [
                -73.92877998940898,
                40.71772931636664
              ],
              [
                -73.92877608561962,
                40.717727270793475
              ],
              [
                -73.92876845782247,
                40.717722042250244
              ],
              [
                -73.92874207976142,
                40.71769646885916
              ],
              [
                -73.9287353864659,
                40.71769118761855
              ],
              [
                -73.92872866939294,
                40.7176871410362
              ],
              [
                -73.92869826813596,
                40.71767238068161
              ],
              [
                -73.92866774684487,
                40.71765931310398
              ],
              [
                -73.9286372006141,
                40.71764795729271
              ],
              [
                -73.92860667565203,
                40.717638338237926
              ],
              [
                -73.9285917078938,
                40.71763506050505
              ],
              [
                -73.92857425221365,
                40.71763313166124
              ],
              [
                -73.92851353839693,
                40.71763209434512
              ],
              [
                -73.9284915378775,
                40.717630496380316
              ],
              [
                -73.92848064026589,
                40.71762873242133
              ],
              [
                -73.92847083084905,
                40.71762628680021
              ],
              [
                -73.92846194563565,
                40.71762312038171
              ],
              [
                -73.92845389020685,
                40.717619198094184
              ],
              [
                -73.92845165582267,
                40.717617773229456
              ],
              [
                -73.92844953863248,
                40.717616088268166
              ],
              [
                -73.92844561386065,
                40.71761186331317
              ],
              [
                -73.92843568965912,
                40.717595953982475
              ],
              [
                -73.92843171853902,
                40.71759080894033
              ],
              [
                -73.92842937757847,
                40.717588574351375
              ],
              [
                -73.92842688160763,
                40.71758675412747
              ],
              [
                -73.92842419720883,
                40.71758530268078
              ],
              [
                -73.92842127419077,
                40.71758421059831
              ],
              [
                -73.92840375075583,
                40.71758019296097
              ],
              [
                -73.92839153834066,
                40.717578753680584
              ],
              [
                -73.92837930101507,
                40.71757836729012
              ],
              [
                -73.92638366600744,
                40.71808480802873
              ],
              [
                -73.92615790234161,
                40.71809946813578
              ],
              [
                -73.92508677520757,
                40.718384219204275
              ],
              [
                -73.92499204426545,
                40.718384788263776
              ],
              [
                -73.92493181378975,
                40.71840582874942
              ],
              [
                -73.9248641350909,
                40.71844044541351
              ],
              [
                -73.9248155567726,
                40.718486835905985
              ],
              [
                -73.92477183253615,
                40.71856461085537
              ],
              [
                -73.92470227807098,
                40.71868830410057
              ],
              [
                -73.9246900485712,
                40.71872233555582
              ],
              [
                -73.92462954199867,
                40.718986974876245
              ],
              [
                -73.92457948088894,
                40.71916252333471
              ],
              [
                -73.92455355876741,
                40.71927913119412
              ],
              [
                -73.92453854985803,
                40.719342580204575
              ],
              [
                -73.92452083048202,
                40.71955356651187
              ],
              [
                -73.92452018596838,
                40.719568370774205
              ],
              [
                -73.92451999415132,
                40.71961273042429
              ],
              [
                -73.92453432562169,
                40.71966615777251
              ],
              [
                -73.92460885647311,
                40.7197896928349
              ],
              [
                -73.92467622369253,
                40.719885695480855
              ],
              [
                -73.92474503108099,
                40.71998524724633
              ],
              [
                -73.92471098441669,
                40.71999119597938
              ],
              [
                -73.92470891655738,
                40.719998875566354
              ],
              [
                -73.92481537158021,
                40.72022555855077
              ],
              [
                -73.92481798943687,
                40.72026513747535
              ],
              [
                -73.92561470516611,
                40.72006166221244
              ],
              [
                -73.92720275963465,
                40.723652947186146
              ],
              [
                -73.92779650104072,
                40.7249256692162
              ],
              [
                -73.92780584783978,
                40.72504980016611
              ],
              [
                -73.92785895385158,
                40.72575459949976
              ],
              [
                -73.92786431997051,
                40.725826679058244
              ],
              [
                -73.92787172405019,
                40.725854051197636
              ],
              [
                -73.9278825766526,
                40.72586648412292
              ],
              [
                -73.92790171856065,
                40.725875780441555
              ],
              [
                -73.92792754362857,
                40.72587905684713
              ],
              [
                -73.92793912625204,
                40.725883729045734
              ],
              [
                -73.92795514073464,
                40.72591651751365
              ],
              [
                -73.9280542953431,
                40.72607234016259
              ],
              [
                -73.92813074743837,
                40.7261726868244
              ],
              [
                -73.92815870725636,
                40.726205230757685
              ],
              [
                -73.92833738013012,
                40.72641758269857
              ],
              [
                -73.92849418540158,
                40.726614603307866
              ],
              [
                -73.92886953254995,
                40.72700263162834
              ],
              [
                -73.92926898893549,
                40.72736521532119
              ],
              [
                -73.92924333948385,
                40.72739794231863
              ],
              [
                -73.92937588603782,
                40.72747996953887
              ],
              [
                -73.92942476304782,
                40.727511752515085
              ],
              [
                -73.9294975342307,
                40.727559064581975
              ],
              [
                -73.92958020690885,
                40.72761282070866
              ],
              [
                -73.92961163522367,
                40.72761121943942
              ],
              [
                -73.92962734303951,
                40.72760005400567
              ],
              [
                -73.92962400635007,
                40.72757757457619
              ],
              [
                -73.92963224605802,
                40.72756459428362
              ],
              [
                -73.92965484905174,
                40.72754765255141
              ],
              [
                -73.92966308042121,
                40.72754339771126
              ],
              [
                -73.92983531275382,
                40.727607889326656
              ],
              [
                -73.92991812728,
                40.727638898356346
              ],
              [
                -73.93032635559956,
                40.72779514224887
              ],
              [
                -73.93072254234549,
                40.727841072689614
              ],
              [
                -73.93080479131739,
                40.72784883926169
              ],
              [
                -73.93120770151332,
                40.72794133862442
              ],
              [
                -73.93157356186826,
                40.72801829506091
              ],
              [
                -73.93166858749639,
                40.72803828280952
              ],
              [
                -73.93168317826739,
                40.72803172609697
              ],
              [
                -73.93174841322003,
                40.728053727696675
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000295038418204,
        "objectid": 74,
        "shape_leng": 0.11029093625,
        "location_id": 74,
        "zone": "East Harlem North",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.93380589881859,
                40.816512493247096
              ],
              [
                -73.93378997399186,
                40.81715137370172
              ],
              [
                -73.93379334625767,
                40.81777660857971
              ],
              [
                -73.93380019473052,
                40.81834975887406
              ],
              [
                -73.9338261413771,
                40.819356429750336
              ],
              [
                -73.93382777421705,
                40.819415494918886
              ],
              [
                -73.9338294812938,
                40.81947330955996
              ],
              [
                -73.93383089287107,
                40.819521063761556
              ],
              [
                -73.93411671299988,
                40.81954705299995
              ],
              [
                -73.93431276899999,
                40.81962986599991
              ],
              [
                -73.93440039999986,
                40.81966778299991
              ],
              [
                -73.93439036800001,
                40.81958743499993
              ],
              [
                -73.934381129,
                40.81932807599992
              ],
              [
                -73.93429487799989,
                40.81890498699992
              ],
              [
                -73.93423576099998,
                40.81864931899987
              ],
              [
                -73.93408481999981,
                40.8179308619999
              ],
              [
                -73.93407463999996,
                40.81782651399992
              ],
              [
                -73.93412816799999,
                40.81773396299989
              ],
              [
                -73.934235195,
                40.81755488099992
              ],
              [
                -73.93445400100002,
                40.81718852899996
              ],
              [
                -73.93463790299994,
                40.81690804799997
              ],
              [
                -73.93487343800004,
                40.816527846999904
              ],
              [
                -73.93575548199986,
                40.81532558399993
              ],
              [
                -73.93613554399997,
                40.81478812699993
              ],
              [
                -73.936209578,
                40.81467384999995
              ],
              [
                -73.93668813299989,
                40.81404324299995
              ],
              [
                -73.93714398399993,
                40.81342104099985
              ],
              [
                -73.93763499299979,
                40.81274779999994
              ],
              [
                -73.9381355239999,
                40.81206561899996
              ],
              [
                -73.93820642699984,
                40.81196760499992
              ],
              [
                -73.93830924999986,
                40.81182546399992
              ],
              [
                -73.93838748499988,
                40.811718443999894
              ],
              [
                -73.93863753299989,
                40.81137638899993
              ],
              [
                -73.93874394499981,
                40.81123082099992
              ],
              [
                -73.93901753299998,
                40.81085655699991
              ],
              [
                -73.93740733700004,
                40.810175610999885
              ],
              [
                -73.93580780199986,
                40.809497637999876
              ],
              [
                -73.93586263399992,
                40.80943020299987
              ],
              [
                -73.93625935999997,
                40.80885560799991
              ],
              [
                -73.93671827500005,
                40.80822047899988
              ],
              [
                -73.93718011799989,
                40.80759899999989
              ],
              [
                -73.937634394,
                40.8069746069999
              ],
              [
                -73.9380866689998,
                40.80635301199987
              ],
              [
                -73.93854407899988,
                40.80572965299991
              ],
              [
                -73.93869430499996,
                40.80579102899988
              ],
              [
                -73.94015793699995,
                40.80640908999991
              ],
              [
                -73.9417714019999,
                40.80708899999997
              ],
              [
                -73.9422631119999,
                40.80641573799991
              ],
              [
                -73.94275367199994,
                40.80574401299986
              ],
              [
                -73.94430194599991,
                40.80639082499993
              ],
              [
                -73.94478208199993,
                40.80576125599991
              ],
              [
                -73.94523965199991,
                40.80513601399989
              ],
              [
                -73.9456926129999,
                40.804514948999916
              ],
              [
                -73.94613131,
                40.80387735799992
              ],
              [
                -73.94459751299998,
                40.80322814499992
              ],
              [
                -73.94505128000003,
                40.80259859899995
              ],
              [
                -73.94550863900001,
                40.80197023099991
              ],
              [
                -73.9459678969998,
                40.801342394999935
              ],
              [
                -73.94646133899998,
                40.80066530499988
              ],
              [
                -73.94697366799987,
                40.79996170299987
              ],
              [
                -73.94728018299999,
                40.799541788999896
              ],
              [
                -73.94732771499984,
                40.79947667099992
              ],
              [
                -73.94737599799991,
                40.799410523999924
              ],
              [
                -73.94741996199991,
                40.79935029499992
              ],
              [
                -73.94771056,
                40.79895218099991
              ],
              [
                -73.94775716499986,
                40.79888833199987
              ],
              [
                -73.94781663099997,
                40.798806862999925
              ],
              [
                -73.94784679099992,
                40.798765542999945
              ],
              [
                -73.94805130199998,
                40.79848535999989
              ],
              [
                -73.94810108999994,
                40.7984171479999
              ],
              [
                -73.94815384299983,
                40.79834487599987
              ],
              [
                -73.9482064149998,
                40.79827285099987
              ],
              [
                -73.94831125500004,
                40.79812921799992
              ],
              [
                -73.94722520899997,
                40.797671685999944
              ],
              [
                -73.9467003099999,
                40.797450543999915
              ],
              [
                -73.9456668469999,
                40.79700834399995
              ],
              [
                -73.94556506799988,
                40.79696479499997
              ],
              [
                -73.94522698899986,
                40.79682013299991
              ],
              [
                -73.94509614599993,
                40.79676348299992
              ],
              [
                -73.9449594289999,
                40.79670574099989
              ],
              [
                -73.94421313799992,
                40.796385857999944
              ],
              [
                -73.94415121699986,
                40.79635931699992
              ],
              [
                -73.9434832399999,
                40.796072993999935
              ],
              [
                -73.94287776099992,
                40.79582167099993
              ],
              [
                -73.94275347999998,
                40.7957700849999
              ],
              [
                -73.9424792319999,
                40.79565624799993
              ],
              [
                -73.94232710899988,
                40.79559310299991
              ],
              [
                -73.94187604699994,
                40.79540586899984
              ],
              [
                -73.94234676599999,
                40.79476079799988
              ],
              [
                -73.94281682199988,
                40.79413407699997
              ],
              [
                -73.94327251199996,
                40.79348810299995
              ],
              [
                -73.94103496899987,
                40.79254666899993
              ],
              [
                -73.93864883299987,
                40.79155775499987
              ],
              [
                -73.93819346899994,
                40.79218819499989
              ],
              [
                -73.9377361209998,
                40.79281580399994
              ],
              [
                -73.93679209299992,
                40.792403928999924
              ],
              [
                -73.93555312799995,
                40.79184222599987
              ],
              [
                -73.93542842699998,
                40.79199687999987
              ],
              [
                -73.93529038099987,
                40.79187313699988
              ],
              [
                -73.93524060799987,
                40.79183387499989
              ],
              [
                -73.93519360499981,
                40.79179799899988
              ],
              [
                -73.93513822199999,
                40.79175283499989
              ],
              [
                -73.93508265899982,
                40.79171004399995
              ],
              [
                -73.93505300002766,
                40.79168720295447
              ],
              [
                -73.9347379410771,
                40.791901569649944
              ],
              [
                -73.93450968573121,
                40.79205197655582
              ],
              [
                -73.93433968650804,
                40.79216855838269
              ],
              [
                -73.93427161550181,
                40.79221786711484
              ],
              [
                -73.93406949343559,
                40.79235873425467
              ],
              [
                -73.93388747605492,
                40.79248981386153
              ],
              [
                -73.93372762154044,
                40.79260172681524
              ],
              [
                -73.93348475349612,
                40.792774284831964
              ],
              [
                -73.93327130499016,
                40.79289947490209
              ],
              [
                -73.93324587394584,
                40.7929142965317
              ],
              [
                -73.93310683207811,
                40.792995328209464
              ],
              [
                -73.93287712655086,
                40.793130251367806
              ],
              [
                -73.93267239130893,
                40.79324823277293
              ],
              [
                -73.9325385265489,
                40.79331668213542
              ],
              [
                -73.93250920949691,
                40.79333190154454
              ],
              [
                -73.93235128869077,
                40.7934138451512
              ],
              [
                -73.93207886021688,
                40.79356650933645
              ],
              [
                -73.93201001457182,
                40.79360548616981
              ],
              [
                -73.93187500171737,
                40.7936819218437
              ],
              [
                -73.93179292520253,
                40.793727962293566
              ],
              [
                -73.93165821105735,
                40.79380174043914
              ],
              [
                -73.93142758557968,
                40.79392798838291
              ],
              [
                -73.93111408045347,
                40.79409650134391
              ],
              [
                -73.93041980479964,
                40.79447087234853
              ],
              [
                -73.93019664392844,
                40.794593224854886
              ],
              [
                -73.9301077102813,
                40.79471299106365
              ],
              [
                -73.93006881035544,
                40.79470082860544
              ],
              [
                -73.92992039501047,
                40.79490967283261
              ],
              [
                -73.92993735334552,
                40.79491878628442
              ],
              [
                -73.9299163612059,
                40.79494911213701
              ],
              [
                -73.92990139788401,
                40.794946824362476
              ],
              [
                -73.9298874065193,
                40.7949672926039
              ],
              [
                -73.9298325404253,
                40.794949061140784
              ],
              [
                -73.92980955349977,
                40.794983166296745
              ],
              [
                -73.92985144395278,
                40.79500140093118
              ],
              [
                -73.92984244753445,
                40.79501503785587
              ],
              [
                -73.9298748577827,
                40.795035156225694
              ],
              [
                -73.92974594691611,
                40.79520874823406
              ],
              [
                -73.92968294251123,
                40.79529623376318
              ],
              [
                -73.92953058056904,
                40.795507798349284
              ],
              [
                -73.92930773275864,
                40.79581025745309
              ],
              [
                -73.92927936741167,
                40.795853139520645
              ],
              [
                -73.9290364017962,
                40.79676259410133
              ],
              [
                -73.92897121002336,
                40.79825886057973
              ],
              [
                -73.92894396946946,
                40.79891158893545
              ],
              [
                -73.92891942515779,
                40.799458186491485
              ],
              [
                -73.92890152344123,
                40.799855215165266
              ],
              [
                -73.92893763668584,
                40.80017084377763
              ],
              [
                -73.92895348657567,
                40.800309368484676
              ],
              [
                -73.92898618762995,
                40.80060212311935
              ],
              [
                -73.92899994866313,
                40.800710097692665
              ],
              [
                -73.92900788120616,
                40.80077234912531
              ],
              [
                -73.92901644642036,
                40.80083071554217
              ],
              [
                -73.92902123584638,
                40.80089557256171
              ],
              [
                -73.92902671406671,
                40.80096986031424
              ],
              [
                -73.92903490240303,
                40.80108090385195
              ],
              [
                -73.92911838814219,
                40.8011130032263
              ],
              [
                -73.92920817595964,
                40.80114752061126
              ],
              [
                -73.92927487688024,
                40.801373190232695
              ],
              [
                -73.92930220396845,
                40.80149580134897
              ],
              [
                -73.92933126971816,
                40.80159885399087
              ],
              [
                -73.92935435998545,
                40.80167125043788
              ],
              [
                -73.92943654118756,
                40.801861711857406
              ],
              [
                -73.92945366333494,
                40.80190736883599
              ],
              [
                -73.92952043928469,
                40.802053479615516
              ],
              [
                -73.9295769383949,
                40.80218002521574
              ],
              [
                -73.92969251214573,
                40.80244028594793
              ],
              [
                -73.92978668000165,
                40.80266074871747
              ],
              [
                -73.92988948082964,
                40.802830357458994
              ],
              [
                -73.92991008609906,
                40.80286455074737
              ],
              [
                -73.92993459708443,
                40.802905224255376
              ],
              [
                -73.93003768593684,
                40.80307629267641
              ],
              [
                -73.92996684404639,
                40.80317620551488
              ],
              [
                -73.92996159942555,
                40.803183595030795
              ],
              [
                -73.93001261302975,
                40.803287498428695
              ],
              [
                -73.93005719833698,
                40.80336412151402
              ],
              [
                -73.93018061288154,
                40.80357619510721
              ],
              [
                -73.93018908730323,
                40.80358540003116
              ],
              [
                -73.9302592352763,
                40.803707901774544
              ],
              [
                -73.93027617112581,
                40.80373645606578
              ],
              [
                -73.93030641012538,
                40.80378803563389
              ],
              [
                -73.93033061473649,
                40.80382119403906
              ],
              [
                -73.93035118741409,
                40.803849751521746
              ],
              [
                -73.93040321487011,
                40.803922524216716
              ],
              [
                -73.93044072678737,
                40.80397594555043
              ],
              [
                -73.93054055322638,
                40.804109049736816
              ],
              [
                -73.93057080892787,
                40.80414497555939
              ],
              [
                -73.93057080064615,
                40.804154183553884
              ],
              [
                -73.93060831208301,
                40.80420853141014
              ],
              [
                -73.93063009780597,
                40.80423156175633
              ],
              [
                -73.93063735551432,
                40.80424722097881
              ],
              [
                -73.93068575499123,
                40.80431170072461
              ],
              [
                -73.93069422131265,
                40.80432735999997
              ],
              [
                -73.93070875470727,
                40.80434209583947
              ],
              [
                -73.93071479334765,
                40.8043568347806
              ],
              [
                -73.93080494630988,
                40.804475197603566
              ],
              [
                -73.93082673180042,
                40.80451111776967
              ],
              [
                -73.9309082463483,
                40.80462041055984
              ],
              [
                -73.93093322065235,
                40.804653894125565
              ],
              [
                -73.93095741258308,
                40.80468797946864
              ],
              [
                -73.9309743588213,
                40.80470640728931
              ],
              [
                -73.93097556926416,
                40.804716534383466
              ],
              [
                -73.93102699955034,
                40.80478331306253
              ],
              [
                -73.93103304094328,
                40.80479528916923
              ],
              [
                -73.9310729715702,
                40.80484779169008
              ],
              [
                -73.93109717576777,
                40.804881877512585
              ],
              [
                -73.93111339209408,
                40.80490502354581
              ],
              [
                -73.93112621780075,
                40.804923329268384
              ],
              [
                -73.93115889451225,
                40.80496753829942
              ],
              [
                -73.93126904139163,
                40.80509097997354
              ],
              [
                -73.93130474200962,
                40.80513197562959
              ],
              [
                -73.93139067303517,
                40.80523330439408
              ],
              [
                -73.93140883156653,
                40.80524712392974
              ],
              [
                -73.93143182674825,
                40.80528212682835
              ],
              [
                -73.93144877545446,
                40.80529779058802
              ],
              [
                -73.93146693969662,
                40.80530609218759
              ],
              [
                -73.93147540101658,
                40.805328196076914
              ],
              [
                -73.9314959803404,
                40.805351225035174
              ],
              [
                -73.93154417434337,
                40.80539778332666
              ],
              [
                -73.932390171191,
                40.806299141471094
              ],
              [
                -73.93263088267565,
                40.806552933906765
              ],
              [
                -73.9329328832928,
                40.806871022133876
              ],
              [
                -73.93308471749718,
                40.8070269147021
              ],
              [
                -73.93317413539488,
                40.80714539094985
              ],
              [
                -73.93318395639754,
                40.80715619829966
              ],
              [
                -73.93325708526282,
                40.80723665117303
              ],
              [
                -73.93332852361837,
                40.80731894646301
              ],
              [
                -73.93356119311476,
                40.80760067738665
              ],
              [
                -73.93412073484292,
                40.808656451977456
              ],
              [
                -73.9341353262405,
                40.8087363905142
              ],
              [
                -73.93419639195767,
                40.809031851582375
              ],
              [
                -73.9342139101698,
                40.809059173792676
              ],
              [
                -73.93433864584743,
                40.80956221383131
              ],
              [
                -73.93434080971474,
                40.80992758274954
              ],
              [
                -73.93431440507302,
                40.81032226491122
              ],
              [
                -73.93430417398088,
                40.810481665612656
              ],
              [
                -73.93428227801395,
                40.81082305050867
              ],
              [
                -73.93419558749154,
                40.81139317769285
              ],
              [
                -73.93413711416075,
                40.811712370924496
              ],
              [
                -73.93409306691906,
                40.81196840253071
              ],
              [
                -73.93403843917875,
                40.81234088007091
              ],
              [
                -73.93400770204929,
                40.81260849869726
              ],
              [
                -73.93398178986803,
                40.812814635545315
              ],
              [
                -73.933929846002,
                40.81334769063374
              ],
              [
                -73.93387232548957,
                40.8140632642607
              ],
              [
                -73.93386927846709,
                40.81410117481657
              ],
              [
                -73.93386510261887,
                40.814153157955616
              ],
              [
                -73.93386383534269,
                40.81418806900028
              ],
              [
                -73.9338623097275,
                40.81424929682303
              ],
              [
                -73.93385652638806,
                40.81448147441923
              ],
              [
                -73.93401476726973,
                40.81448375262478
              ],
              [
                -73.93399121978341,
                40.814528386434176
              ],
              [
                -73.93396620950318,
                40.814565211251484
              ],
              [
                -73.9339764506598,
                40.81460093082527
              ],
              [
                -73.93398523963945,
                40.814629959140966
              ],
              [
                -73.93398224833994,
                40.81468242057032
              ],
              [
                -73.93398075656609,
                40.81470474252187
              ],
              [
                -73.93399543936422,
                40.81471925787889
              ],
              [
                -73.93398954202252,
                40.81473822765622
              ],
              [
                -73.93397776416138,
                40.814758311119135
              ],
              [
                -73.93399097511455,
                40.81477506811654
              ],
              [
                -73.93397332647235,
                40.81478733186024
              ],
              [
                -73.93398210661385,
                40.814825293078385
              ],
              [
                -73.93397473519484,
                40.81484872821333
              ],
              [
                -73.93398352753833,
                40.81487439749633
              ],
              [
                -73.93398203685818,
                40.81489561278114
              ],
              [
                -73.93398936930922,
                40.81491235703092
              ],
              [
                -73.93398565957851,
                40.81494751042226
              ],
              [
                -73.9339694135804,
                40.81503902711456
              ],
              [
                -73.9339635051222,
                40.81506916406496
              ],
              [
                -73.93396788302533,
                40.815100422954416
              ],
              [
                -73.93396344530967,
                40.815129443023594
              ],
              [
                -73.93394725313905,
                40.815155097995664
              ],
              [
                -73.93394870283134,
                40.81517519791028
              ],
              [
                -73.93394426378258,
                40.81520532581688
              ],
              [
                -73.93395452158198,
                40.81523547202672
              ],
              [
                -73.93395302092101,
                40.81526672687829
              ],
              [
                -73.9339485843048,
                40.815294621684686
              ],
              [
                -73.93394855439136,
                40.81532476149812
              ],
              [
                -73.93394853112638,
                40.81534820251395
              ],
              [
                -73.93395659090885,
                40.81537220417373
              ],
              [
                -73.93394628434712,
                40.81539005487055
              ],
              [
                -73.93394921255528,
                40.81540122322665
              ],
              [
                -73.93393892276251,
                40.81541572428048
              ],
              [
                -73.93394623227614,
                40.815442517976344
              ],
              [
                -73.93393445709587,
                40.81547153450901
              ],
              [
                -73.93395353730935,
                40.815486052389055
              ],
              [
                -73.93395205657892,
                40.81549721872188
              ],
              [
                -73.93394175021196,
                40.81551618496342
              ],
              [
                -73.93394173470415,
                40.81553180845374
              ],
              [
                -73.93395054151384,
                40.81554298000987
              ],
              [
                -73.93394905857511,
                40.815556369558514
              ],
              [
                -73.93394170146216,
                40.81556529841819
              ],
              [
                -73.93394609933729,
                40.815576457564624
              ],
              [
                -73.93394608825352,
                40.815587624739145
              ],
              [
                -73.93382900884613,
                40.81558536018751
              ],
              [
                -73.93380589881859,
                40.816512493247096
              ]
            ]
          ],
          [
            [
              [
                -73.92688606022001,
                40.800840648368855
              ],
              [
                -73.92704640297914,
                40.800651743031324
              ],
              [
                -73.92699531303118,
                40.800636176104284
              ],
              [
                -73.92686908679453,
                40.80078367679929
              ],
              [
                -73.92683162740748,
                40.80076552901468
              ],
              [
                -73.92684528429592,
                40.80073964671283
              ],
              [
                -73.9265524180424,
                40.80060482504616
              ],
              [
                -73.9265095488364,
                40.80065703126224
              ],
              [
                -73.92688606022001,
                40.800840648368855
              ]
            ]
          ],
          [
            [
              [
                -73.92666565490181,
                40.80018271401784
              ],
              [
                -73.92716933022471,
                40.80040585369613
              ],
              [
                -73.92706696628478,
                40.80054301212579
              ],
              [
                -73.92711464230625,
                40.80056375622957
              ],
              [
                -73.9272477078927,
                40.800392957368906
              ],
              [
                -73.92669572614771,
                40.800146977076245
              ],
              [
                -73.92666565490181,
                40.80018271401784
              ]
            ]
          ],
          [
            [
              [
                -73.92672091168967,
                40.800414210943586
              ],
              [
                -73.92672526216784,
                40.800407339325346
              ],
              [
                -73.92673683953086,
                40.80041147148191
              ],
              [
                -73.92674263892977,
                40.80040349897277
              ],
              [
                -73.9265535167681,
                40.80031517296066
              ],
              [
                -73.92653846445016,
                40.80033389815394
              ],
              [
                -73.92672091168967,
                40.800414210943586
              ]
            ]
          ],
          [
            [
              [
                -73.92718514789182,
                40.79734989689012
              ],
              [
                -73.9272029978516,
                40.7973867478999
              ],
              [
                -73.92736675660652,
                40.79734682034476
              ],
              [
                -73.92738263624496,
                40.797377063580484
              ],
              [
                -73.92770694127063,
                40.797298663882124
              ],
              [
                -73.92767122620857,
                40.797217014266245
              ],
              [
                -73.92734692252739,
                40.79729390212043
              ],
              [
                -73.9273548544178,
                40.797316580786315
              ],
              [
                -73.92718514789182,
                40.79734989689012
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000143637804933,
        "objectid": 85,
        "shape_leng": 0.0574751473562,
        "location_id": 85,
        "zone": "Erasmus",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.94715896299984,
                40.65559378699993
              ],
              [
                -73.95006664899988,
                40.65542299699989
              ],
              [
                -73.94993008899999,
                40.65415032699993
              ],
              [
                -73.94976875000005,
                40.65264232299987
              ],
              [
                -73.94966851199989,
                40.6516975199999
              ],
              [
                -73.94957113100006,
                40.65080789999991
              ],
              [
                -73.95137845899984,
                40.65071574499989
              ],
              [
                -73.95246901399993,
                40.650669454999914
              ],
              [
                -73.95295084000004,
                40.65065555999994
              ],
              [
                -73.95480071999985,
                40.65056930299989
              ],
              [
                -73.9558251839998,
                40.6505298079999
              ],
              [
                -73.9587078989999,
                40.65038727299989
              ],
              [
                -73.95825517200004,
                40.64853244899996
              ],
              [
                -73.95815003599986,
                40.64776767399996
              ],
              [
                -73.95814068499989,
                40.64750340399995
              ],
              [
                -73.95810672200003,
                40.64651800899988
              ],
              [
                -73.95803664499991,
                40.64530249999989
              ],
              [
                -73.95802176499988,
                40.645069961999894
              ],
              [
                -73.95797764699991,
                40.64457625599988
              ],
              [
                -73.95791873699986,
                40.64414326499991
              ],
              [
                -73.95789394399986,
                40.64401556199991
              ],
              [
                -73.95763644599988,
                40.64268949899991
              ],
              [
                -73.95746359899987,
                40.64237166999987
              ],
              [
                -73.95707031,
                40.64196067099995
              ],
              [
                -73.95571599999984,
                40.64058252599992
              ],
              [
                -73.95563820699982,
                40.64050392599989
              ],
              [
                -73.95476763299989,
                40.63962444699996
              ],
              [
                -73.95427358399985,
                40.6391155389999
              ],
              [
                -73.95404358599993,
                40.6388775489999
              ],
              [
                -73.95374853799996,
                40.63858633799993
              ],
              [
                -73.9536000469999,
                40.63842234699991
              ],
              [
                -73.95307699899985,
                40.63844626099989
              ],
              [
                -73.95213852299987,
                40.63844349599993
              ],
              [
                -73.95116311199983,
                40.638448182999866
              ],
              [
                -73.95017361500003,
                40.638510596999886
              ],
              [
                -73.94923295699995,
                40.63854767299995
              ],
              [
                -73.94826499600005,
                40.63860718999994
              ],
              [
                -73.94841343400003,
                40.639987463999944
              ],
              [
                -73.94744296799982,
                40.64004766499991
              ],
              [
                -73.94754496799997,
                40.641005475999904
              ],
              [
                -73.94778994699976,
                40.64330573599991
              ],
              [
                -73.94685570699994,
                40.64336214099994
              ],
              [
                -73.94586879199989,
                40.6434237439999
              ],
              [
                -73.94598813699993,
                40.644533796999916
              ],
              [
                -73.94607216799986,
                40.645311811999896
              ],
              [
                -73.94625972599981,
                40.64700621499989
              ],
              [
                -73.94647882399988,
                40.64910075099988
              ],
              [
                -73.94667414499983,
                40.65094811899995
              ],
              [
                -73.94570606199984,
                40.651006783999904
              ],
              [
                -73.94590468799986,
                40.65285464999991
              ],
              [
                -73.94606690599983,
                40.65441026199991
              ],
              [
                -73.94703257599998,
                40.6543387139999
              ],
              [
                -73.94715896299984,
                40.65559378699993
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000623278815249,
        "objectid": 86,
        "shape_leng": 0.134245282582,
        "location_id": 86,
        "zone": "Far Rockaway",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.76669071688973,
                40.61422662157114
              ],
              [
                -73.7667529623521,
                40.614261005953615
              ],
              [
                -73.76681903151112,
                40.614290961923935
              ],
              [
                -73.76685925777913,
                40.61429732034634
              ],
              [
                -73.76690019985251,
                40.61430185212442
              ],
              [
                -73.76694157398309,
                40.61430453808635
              ],
              [
                -73.76698308457676,
                40.61430535183292
              ],
              [
                -73.76704379234955,
                40.614304025080266
              ],
              [
                -73.76710253635196,
                40.614300415580836
              ],
              [
                -73.76715921956162,
                40.61429454290923
              ],
              [
                -73.76721373576882,
                40.614286413387404
              ],
              [
                -73.76726342522326,
                40.61427650864116
              ],
              [
                -73.76737638307308,
                40.61424982066475
              ],
              [
                -73.7674275279154,
                40.614239190374846
              ],
              [
                -73.7675186061643,
                40.61422445276445
              ],
              [
                -73.76754530082158,
                40.61422113170201
              ],
              [
                -73.7675607760041,
                40.61421920708899
              ],
              [
                -73.76760132093918,
                40.61421530856811
              ],
              [
                -73.76763170757864,
                40.61421381147214
              ],
              [
                -73.76766549195821,
                40.614214042266255
              ],
              [
                -73.76778765465387,
                40.614221403090404
              ],
              [
                -73.76783729461303,
                40.61422240416597
              ],
              [
                -73.76786697167924,
                40.6142211406185
              ],
              [
                -73.76789952592243,
                40.61421818864729
              ],
              [
                -73.76803145576746,
                40.61419976068117
              ],
              [
                -73.76808937643767,
                40.61419447472638
              ],
              [
                -73.76811864665672,
                40.61419476714476
              ],
              [
                -73.76815131971195,
                40.614198489046444
              ],
              [
                -73.76818285049659,
                40.61420436172429
              ],
              [
                -73.76825921241463,
                40.61422113931471
              ],
              [
                -73.76829854249856,
                40.614227243462224
              ],
              [
                -73.76831847311404,
                40.61422867848521
              ],
              [
                -73.76833706074913,
                40.61422865345921
              ],
              [
                -73.76835465017773,
                40.61422714847353
              ],
              [
                -73.76837140539077,
                40.614224165701835
              ],
              [
                -73.76841416033392,
                40.61421360802995
              ],
              [
                -73.76846044441163,
                40.61420061738405
              ],
              [
                -73.76858268472621,
                40.614161762166184
              ],
              [
                -73.76887037118318,
                40.614065832110256
              ],
              [
                -73.76891513219671,
                40.61405241319664
              ],
              [
                -73.76896496255547,
                40.6140393388436
              ],
              [
                -73.76902068965084,
                40.61402631317463
              ],
              [
                -73.76909892905645,
                40.61400944987172
              ],
              [
                -73.76915158682361,
                40.613998812472765
              ],
              [
                -73.76920000707541,
                40.61399018393143
              ],
              [
                -73.76924563215687,
                40.613983314179094
              ],
              [
                -73.76928901812339,
                40.61397811587946
              ],
              [
                -73.76933085131219,
                40.61397452507587
              ],
              [
                -73.76937109456867,
                40.61397254337086
              ],
              [
                -73.76940997385498,
                40.613972180600925
              ],
              [
                -73.76944759498394,
                40.61397341704376
              ],
              [
                -73.76946947006188,
                40.61397598290579
              ],
              [
                -73.76949266011108,
                40.61398137846054
              ],
              [
                -73.76951917147099,
                40.61399014801286
              ],
              [
                -73.76957481264446,
                40.614012224020456
              ],
              [
                -73.76959938519454,
                40.61402089082527
              ],
              [
                -73.76962599613113,
                40.61402782442917
              ],
              [
                -73.76963849147283,
                40.61402992934977
              ],
              [
                -73.76965068224214,
                40.61403117088186
              ],
              [
                -73.76985886892095,
                40.61404303316777
              ],
              [
                -73.77002788909296,
                40.6140592555852
              ],
              [
                -73.770069074328,
                40.614062013086375
              ],
              [
                -73.77010609275642,
                40.614063176237885
              ],
              [
                -73.77019814694472,
                40.614063306531236
              ],
              [
                -73.77038968748185,
                40.614044682072375
              ],
              [
                -73.77064057511912,
                40.61402770242353
              ],
              [
                -73.77068292565424,
                40.61403005467908
              ],
              [
                -73.77072528617398,
                40.61402780926033
              ],
              [
                -73.77076680152705,
                40.61402101149677
              ],
              [
                -73.77080663362396,
                40.61400979861785
              ],
              [
                -73.77084397835702,
                40.61399439698316
              ],
              [
                -73.77086678706019,
                40.61398452537609
              ],
              [
                -73.77089122605437,
                40.61397726480555
              ],
              [
                -73.77091678395117,
                40.61397276719949
              ],
              [
                -73.7709429259498,
                40.61397112667061
              ],
              [
                -73.771031821726,
                40.61394532343613
              ],
              [
                -73.77112286825097,
                40.61392432219383
              ],
              [
                -73.771215619475,
                40.613908225831494
              ],
              [
                -73.77127396321097,
                40.61390072517808
              ],
              [
                -73.7713326800468,
                40.613895166806046
              ],
              [
                -73.77141220326567,
                40.613890766362616
              ],
              [
                -73.77149192825127,
                40.613889927376434
              ],
              [
                -73.77151470107658,
                40.613894582888705
              ],
              [
                -73.77154415104286,
                40.613902848196794
              ],
              [
                -73.77157232045141,
                40.613913391685294
              ],
              [
                -73.77160464827602,
                40.61391985236654
              ],
              [
                -73.771654144762,
                40.61392471064514
              ],
              [
                -73.77170403355123,
                40.61392373058756
              ],
              [
                -73.77179004380021,
                40.61391961497739
              ],
              [
                -73.77187500460022,
                40.6139086087822
              ],
              [
                -73.771957968065,
                40.613890834795654
              ],
              [
                -73.77202844410407,
                40.61387053478953
              ],
              [
                -73.77209575329097,
                40.613844737679834
              ],
              [
                -73.77215915970974,
                40.6138137255174
              ],
              [
                -73.7722179701178,
                40.6137778373709
              ],
              [
                -73.77231668973992,
                40.613731107767585
              ],
              [
                -73.77241812289252,
                40.613687884674846
              ],
              [
                -73.77252205525942,
                40.61364825941569
              ],
              [
                -73.77262826724544,
                40.61361231571145
              ],
              [
                -73.77271121766803,
                40.61359813848016
              ],
              [
                -73.77279542050721,
                40.613589226885345
              ],
              [
                -73.7728803012121,
                40.61358564173448
              ],
              [
                -73.77296528060725,
                40.61358740749043
              ],
              [
                -73.77304977884341,
                40.613594512104854
              ],
              [
                -73.7731332193545,
                40.61360690709986
              ],
              [
                -73.77317696868322,
                40.61361842478523
              ],
              [
                -73.77321979995166,
                40.613631797089205
              ],
              [
                -73.77326127524387,
                40.61365136745162
              ],
              [
                -73.77329896191252,
                40.61367497196102
              ],
              [
                -73.773329647541,
                40.61368443071146
              ],
              [
                -73.77335608646888,
                40.61368855280565
              ],
              [
                -73.77336232912107,
                40.61368901911916
              ],
              [
                -73.77337035980797,
                40.61368919578096
              ],
              [
                -73.77338277882686,
                40.6136870541096
              ],
              [
                -73.77339803484178,
                40.61368296748421
              ],
              [
                -73.77341596206944,
                40.61367629561392
              ],
              [
                -73.77342509849096,
                40.61367201531982
              ],
              [
                -73.77343386792192,
                40.6136667877601
              ],
              [
                -73.77345031409877,
                40.61365351012634
              ],
              [
                -73.77345045782228,
                40.61365339514933
              ],
              [
                -73.77346587818103,
                40.61363546956445
              ],
              [
                -73.773481748665,
                40.61361161063556
              ],
              [
                -73.7735141321426,
                40.61355476753436
              ],
              [
                -73.7735466529197,
                40.613490419876555
              ],
              [
                -73.7735758160344,
                40.6134272535469
              ],
              [
                -73.77364588445435,
                40.613268611302004
              ],
              [
                -73.77368650717207,
                40.613184559160445
              ],
              [
                -73.77371081639753,
                40.61313984431637
              ],
              [
                -73.7737736886651,
                40.613030478346076
              ],
              [
                -73.77380379155755,
                40.612971799407745
              ],
              [
                -73.77382494541348,
                40.612924600233484
              ],
              [
                -73.77384604655711,
                40.61287380911937
              ],
              [
                -73.77389100971483,
                40.61275422573868
              ],
              [
                -73.77392543466452,
                40.61265675142032
              ],
              [
                -73.77394965118485,
                40.61258425777536
              ],
              [
                -73.7739697707448,
                40.61251841821368
              ],
              [
                -73.77397767570854,
                40.61248913519638
              ],
              [
                -73.77398615369667,
                40.61245779570801
              ],
              [
                -73.77400830908317,
                40.61235765948454
              ],
              [
                -73.7740276516538,
                40.61224473020023
              ],
              [
                -73.77403016171108,
                40.612218812626736
              ],
              [
                -73.77403265331083,
                40.61215119826687
              ],
              [
                -73.77403522797262,
                40.61213158629512
              ],
              [
                -73.77403932881545,
                40.61211414884066
              ],
              [
                -73.77404497735554,
                40.612098958653604
              ],
              [
                -73.77405335830696,
                40.61208262542263
              ],
              [
                -73.77408950538367,
                40.612027405707956
              ],
              [
                -73.77410192392666,
                40.61200510278894
              ],
              [
                -73.7741301655297,
                40.611944897259505
              ],
              [
                -73.77414786993228,
                40.611903603526855
              ],
              [
                -73.774161726854,
                40.61186561965128
              ],
              [
                -73.77417171308002,
                40.611830597461996
              ],
              [
                -73.77417184679693,
                40.6118296699464
              ],
              [
                -73.77417180232338,
                40.61182871527586
              ],
              [
                -73.77417159328917,
                40.61182773330987
              ],
              [
                -73.77417119391467,
                40.61182674158831
              ],
              [
                -73.77417063107842,
                40.61182572274074
              ],
              [
                -73.77416988911098,
                40.611824694829814
              ],
              [
                -73.77416789935114,
                40.61182263767905
              ],
              [
                -73.77416525980846,
                40.61182057003742
              ],
              [
                -73.77416205233125,
                40.611818600792404
              ],
              [
                -73.77415446562193,
                40.61181519977987
              ],
              [
                -73.77415045182457,
                40.61181394028044
              ],
              [
                -73.77414652034192,
                40.611813041633795
              ],
              [
                -73.77414282314955,
                40.61181254736078
              ],
              [
                -73.77413949166653,
                40.61181246894439
              ],
              [
                -73.77413801402106,
                40.6118125829765
              ],
              [
                -73.77413664243689,
                40.611812796562006
              ],
              [
                -73.77413541249396,
                40.61181311915227
              ],
              [
                -73.77413430026861,
                40.61181353964375
              ],
              [
                -73.77413332974614,
                40.61181405104705
              ],
              [
                -73.77413250067724,
                40.61181466207327
              ],
              [
                -73.77413182408637,
                40.611815362859836
              ],
              [
                -73.7741312788372,
                40.61181616324955
              ],
              [
                -73.77411806047176,
                40.611842855715935
              ],
              [
                -73.77409284355926,
                40.61190221412751
              ],
              [
                -73.7740809475436,
                40.61192569396221
              ],
              [
                -73.77405276935937,
                40.61197332182517
              ],
              [
                -73.77401764033394,
                40.612028472162585
              ],
              [
                -73.77400930728072,
                40.612043188825524
              ],
              [
                -73.77398539252552,
                40.612091364666306
              ],
              [
                -73.77397078374364,
                40.61211334428959
              ],
              [
                -73.77396659812177,
                40.61211770422278
              ],
              [
                -73.7739624744208,
                40.612121046368436
              ],
              [
                -73.7739584015895,
                40.61212338846286
              ],
              [
                -73.7739563775416,
                40.612124185440955
              ],
              [
                -73.77395435588404,
                40.61212473096199
              ],
              [
                -73.77395234585146,
                40.612125024541434
              ],
              [
                -73.7739503249933,
                40.61212507434425
              ],
              [
                -73.77394831660732,
                40.6121248815888
              ],
              [
                -73.77394630995796,
                40.61212443569971
              ],
              [
                -73.77394430327868,
                40.612123739018806
              ],
              [
                -73.77394229745471,
                40.612122789705175
              ],
              [
                -73.77393827753895,
                40.612120151753686
              ],
              [
                -73.7739326342091,
                40.612115035361
              ],
              [
                -73.7739278681188,
                40.6121091718366
              ],
              [
                -73.7739239440798,
                40.612102500800724
              ],
              [
                -73.77392076775064,
                40.61209490245193
              ],
              [
                -73.77391703488719,
                40.61208027079029
              ],
              [
                -73.77391081130263,
                40.6120410496114
              ],
              [
                -73.77388591019262,
                40.611937197679545
              ],
              [
                -73.77385497769873,
                40.61177928316286
              ],
              [
                -73.77384727397728,
                40.61174793881712
              ],
              [
                -73.77382415413824,
                40.61167302439312
              ],
              [
                -73.77381536140189,
                40.61163869704946
              ],
              [
                -73.77381102073075,
                40.61161168141207
              ],
              [
                -73.77380884252658,
                40.61158168130349
              ],
              [
                -73.77380863014407,
                40.61154996353017
              ],
              [
                -73.77381081335125,
                40.611460275881086
              ],
              [
                -73.77380978495356,
                40.61143258324071
              ],
              [
                -73.77380720929449,
                40.61140784143566
              ],
              [
                -73.77379900757467,
                40.611352342874035
              ],
              [
                -73.77379138951497,
                40.61131637102398
              ],
              [
                -73.77378104179124,
                40.61128430946837
              ],
              [
                -73.7737676775312,
                40.611256222475426
              ],
              [
                -73.7737449292238,
                40.61122178640465
              ],
              [
                -73.77370915833684,
                40.611177356686206
              ],
              [
                -73.77367760714131,
                40.61114270508343
              ],
              [
                -73.77360460444338,
                40.611069582447385
              ],
              [
                -73.77357751691721,
                40.611039478987905
              ],
              [
                -73.77354839797641,
                40.611002185676384
              ],
              [
                -73.77351890708198,
                40.61095946065268
              ],
              [
                -73.77349222861592,
                40.6109170832587
              ],
              [
                -73.77342761373566,
                40.61080980202038
              ],
              [
                -73.77340971200924,
                40.610782699154804
              ],
              [
                -73.77339019496708,
                40.610753148008406
              ],
              [
                -73.77306601457155,
                40.61029673598038
              ],
              [
                -73.7729999267002,
                40.610199383907386
              ],
              [
                -73.77297042983133,
                40.61015862300998
              ],
              [
                -73.77296895866235,
                40.61015697211622
              ],
              [
                -73.77296717978257,
                40.610155437550205
              ],
              [
                -73.77296508104023,
                40.61015403738112
              ],
              [
                -73.77296268686533,
                40.610152762945724
              ],
              [
                -73.77295702145891,
                40.61015065361514
              ],
              [
                -73.77295028088521,
                40.6101491273411
              ],
              [
                -73.77294255492997,
                40.61014821998455
              ],
              [
                -73.7729341192848,
                40.610147987039575
              ],
              [
                -73.77291637813553,
                40.61014952810843
              ],
              [
                -73.77290788845032,
                40.61015122298471
              ],
              [
                -73.772900070499,
                40.61015344958592
              ],
              [
                -73.77289320683816,
                40.610156119344985
              ],
              [
                -73.77288751313765,
                40.610159161320624
              ],
              [
                -73.77288316631983,
                40.61016245691658
              ],
              [
                -73.77288150513638,
                40.61016419175308
              ],
              [
                -73.77288018741419,
                40.61016596328696
              ],
              [
                -73.77287923617226,
                40.61016778999218
              ],
              [
                -73.77287865131598,
                40.610169635011914
              ],
              [
                -73.77287840879376,
                40.61017152510352
              ],
              [
                -73.77287854345656,
                40.61017342532194
              ],
              [
                -73.772884997904,
                40.610200643531165
              ],
              [
                -73.77289590086995,
                40.610229373604305
              ],
              [
                -73.77291139315624,
                40.61026016565037
              ],
              [
                -73.77293272002777,
                40.61029549117772
              ],
              [
                -73.77295676540665,
                40.6103305865243
              ],
              [
                -73.773019690345,
                40.610416279523584
              ],
              [
                -73.77304946279641,
                40.610463119592424
              ],
              [
                -73.7731307266893,
                40.610618368630945
              ],
              [
                -73.77316210701994,
                40.61067196629813
              ],
              [
                -73.77319873884215,
                40.61073086959151
              ],
              [
                -73.77323628183373,
                40.61078552461407
              ],
              [
                -73.7733244987483,
                40.61090188452717
              ],
              [
                -73.77336131902807,
                40.61095385476212
              ],
              [
                -73.77338222622511,
                40.610987315106684
              ],
              [
                -73.77339559311453,
                40.611014563833045
              ],
              [
                -73.77340338988807,
                40.611039649157746
              ],
              [
                -73.77340520518642,
                40.61105151248575
              ],
              [
                -73.77340563790028,
                40.61106301306704
              ],
              [
                -73.77340389078967,
                40.61109044877901
              ],
              [
                -73.77340383065187,
                40.61109080935152
              ],
              [
                -73.77339979420599,
                40.61111385446016
              ],
              [
                -73.77339309446671,
                40.61113458978192
              ],
              [
                -73.773383685269,
                40.611152950391634
              ],
              [
                -73.77336952707269,
                40.61117456192883
              ],
              [
                -73.77335673062024,
                40.61119166541736
              ],
              [
                -73.77334370614358,
                40.61120638970323
              ],
              [
                -73.77333012084898,
                40.61121905109607
              ],
              [
                -73.77331668625673,
                40.611229345089896
              ],
              [
                -73.77330125585432,
                40.61123931064123
              ],
              [
                -73.77326368427092,
                40.61125874165951
              ],
              [
                -73.77317979315391,
                40.61129528186084
              ],
              [
                -73.77304604538075,
                40.61135007642812
              ],
              [
                -73.77291336710068,
                40.61140298941855
              ],
              [
                -73.77280893259515,
                40.61144094677057
              ],
              [
                -73.77277007489664,
                40.611452973464196
              ],
              [
                -73.77272806684849,
                40.61146363357612
              ],
              [
                -73.77268245759265,
                40.61147305303117
              ],
              [
                -73.77263219715753,
                40.611481445525875
              ],
              [
                -73.7725317703667,
                40.61149506030717
              ],
              [
                -73.77245013985568,
                40.611502409082604
              ],
              [
                -73.77235963567736,
                40.61150628224979
              ],
              [
                -73.77235885560256,
                40.61150631756143
              ],
              [
                -73.77227984029001,
                40.61150589149225
              ],
              [
                -73.77225630686416,
                40.61150442086574
              ],
              [
                -73.77223450234523,
                40.61150167675028
              ],
              [
                -73.77221438055132,
                40.611497664583986
              ],
              [
                -73.772195965905,
                40.61149237838507
              ],
              [
                -73.77219408886714,
                40.611491645073976
              ],
              [
                -73.77216765565773,
                40.61148122779965
              ],
              [
                -73.7721438888505,
                40.61146840254235
              ],
              [
                -73.7721252314717,
                40.61145424516313
              ],
              [
                -73.7721178953006,
                40.61144672027096
              ],
              [
                -73.77211190787796,
                40.61143890990164
              ],
              [
                -73.7720532149166,
                40.61134901093654
              ],
              [
                -73.77200909650384,
                40.611285572317094
              ],
              [
                -73.77199007357872,
                40.611254052269096
              ],
              [
                -73.77199003780456,
                40.61125397094624
              ],
              [
                -73.7719900019688,
                40.61125390771661
              ],
              [
                -73.77198997844316,
                40.611253844511275
              ],
              [
                -73.7719796331746,
                40.611231617449086
              ],
              [
                -73.77197043212044,
                40.611205979241916
              ],
              [
                -73.77194457459913,
                40.6111103555345
              ],
              [
                -73.77191837105117,
                40.61102222221602
              ],
              [
                -73.77191334262928,
                40.611010407940476
              ],
              [
                -73.77190585729396,
                40.610997857020166
              ],
              [
                -73.77187277684048,
                40.610955052578454
              ],
              [
                -73.77186131675909,
                40.610937244563864
              ],
              [
                -73.77185132437893,
                40.610915089557274
              ],
              [
                -73.77183567042678,
                40.610869501343835
              ],
              [
                -73.77182798309488,
                40.610850520569116
              ],
              [
                -73.77181070491237,
                40.61081668953281
              ],
              [
                -73.77179266153695,
                40.61078883677322
              ],
              [
                -73.77177276151261,
                40.61076529404204
              ],
              [
                -73.77177270190808,
                40.61076523897404
              ],
              [
                -73.77175068390629,
                40.610745735191124
              ],
              [
                -73.7716783827704,
                40.6106928023703
              ],
              [
                -73.77156633578339,
                40.61061469325633
              ],
              [
                -73.77149370721929,
                40.61056444466316
              ],
              [
                -73.77149340108429,
                40.61056422777398
              ],
              [
                -73.7713897690596,
                40.610492528309244
              ],
              [
                -73.77136135007882,
                40.610471696947755
              ],
              [
                -73.7712912175345,
                40.610417525963
              ],
              [
                -73.77125101879477,
                40.61038983710174
              ],
              [
                -73.77124075766046,
                40.610384160715654
              ],
              [
                -73.7712289122568,
                40.61037905814711
              ],
              [
                -73.77119969623142,
                40.61037030119035
              ],
              [
                -73.7711689452305,
                40.610364990268245
              ],
              [
                -73.77112600479279,
                40.61036150827835
              ],
              [
                -73.77111984842712,
                40.61036142414935
              ],
              [
                -73.77111357298145,
                40.61036189799136
              ],
              [
                -73.77110034057867,
                40.61036459181747
              ],
              [
                -73.7710862987221,
                40.61036941718364
              ],
              [
                -73.77104543864475,
                40.61038633820573
              ],
              [
                -73.77103212068104,
                40.61038987720768
              ],
              [
                -73.77102585543035,
                40.61039083857602
              ],
              [
                -73.77101975640439,
                40.610391276577886
              ],
              [
                -73.77100447736628,
                40.61039116488702
              ],
              [
                -73.77098868403316,
                40.610389935584955
              ],
              [
                -73.77095493259962,
                40.610384059748945
              ],
              [
                -73.77091857291383,
                40.61037396382913
              ],
              [
                -73.77086443569364,
                40.61035542348201
              ],
              [
                -73.77082244546227,
                40.610340002437646
              ],
              [
                -73.77078978749671,
                40.61032592665457
              ],
              [
                -73.77076212825757,
                40.610311308654374
              ],
              [
                -73.77073890354532,
                40.61029580086423
              ],
              [
                -73.77072621280226,
                40.61028511363573
              ],
              [
                -73.77071371980344,
                40.6102724082368
              ],
              [
                -73.77066798471779,
                40.610215520962484
              ],
              [
                -73.77064497700059,
                40.61019221508597
              ],
              [
                -73.77062780467537,
                40.61017936581401
              ],
              [
                -73.77060921844134,
                40.61016882009694
              ],
              [
                -73.77058996001047,
                40.61016101867686
              ],
              [
                -73.77058919293884,
                40.610160792656266
              ],
              [
                -73.77058023384357,
                40.6101582083894
              ],
              [
                -73.77057050360517,
                40.610156135390476
              ],
              [
                -73.77054178049336,
                40.61015201730519
              ],
              [
                -73.7705129103782,
                40.61014960956341
              ],
              [
                -73.77048443534659,
                40.61014897573583
              ],
              [
                -73.77045679265326,
                40.610150108487176
              ],
              [
                -73.77043045720318,
                40.610152982966994
              ],
              [
                -73.77040557876032,
                40.61015758020955
              ],
              [
                -73.77038237359653,
                40.61016384636807
              ],
              [
                -73.77036098383704,
                40.61017174654638
              ],
              [
                -73.77035785802019,
                40.61017331692192
              ],
              [
                -73.77035498094149,
                40.61017523692575
              ],
              [
                -73.7703523373575,
                40.61017752847407
              ],
              [
                -73.77034989420949,
                40.61018021629508
              ],
              [
                -73.77034527335127,
                40.61018728535329
              ],
              [
                -73.77033731097586,
                40.61020405439635
              ],
              [
                -73.77033324566116,
                40.61021091766513
              ],
              [
                -73.77033053985504,
                40.61021429819819
              ],
              [
                -73.77032759987875,
                40.61021712005475
              ],
              [
                -73.77032436638449,
                40.610219445604805
              ],
              [
                -73.77032080237387,
                40.61022129454294
              ],
              [
                -73.77031028441786,
                40.61022537917164
              ],
              [
                -73.77029925976277,
                40.61022876134055
              ],
              [
                -73.77028797772111,
                40.61023136816997
              ],
              [
                -73.77027667569712,
                40.61023313798062
              ],
              [
                -73.77026559974071,
                40.61023405183104
              ],
              [
                -73.77025485957076,
                40.61023410223413
              ],
              [
                -73.77024458117825,
                40.61023328022782
              ],
              [
                -73.77023486320054,
                40.610231604605076
              ],
              [
                -73.77019373076314,
                40.61022134629524
              ],
              [
                -73.77014896854337,
                40.610208099859044
              ],
              [
                -73.77001763216501,
                40.61016296452578
              ],
              [
                -73.76990145566117,
                40.610121550569865
              ],
              [
                -73.76985762894924,
                40.610104469800895
              ],
              [
                -73.76982838609898,
                40.61009216718075
              ],
              [
                -73.76981818454574,
                40.610087875418095
              ],
              [
                -73.76976745552511,
                40.61006418867175
              ],
              [
                -73.76971531021152,
                40.61003699686
              ],
              [
                -73.76966090947737,
                40.61000587377958
              ],
              [
                -73.7696019995385,
                40.60996952761247
              ],
              [
                -73.76957257646704,
                40.60994953965001
              ],
              [
                -73.7695013422237,
                40.609896248070775
              ],
              [
                -73.76946722660614,
                40.60987283021983
              ],
              [
                -73.76941724136196,
                40.6098430207667
              ],
              [
                -73.76930923097416,
                40.60978428747009
              ],
              [
                -73.76926359313588,
                40.60975796492357
              ],
              [
                -73.76921556009525,
                40.60972739402596
              ],
              [
                -73.76917976560823,
                40.60970087375623
              ],
              [
                -73.76915128441289,
                40.609674745942776
              ],
              [
                -73.76913964340078,
                40.60966166547593
              ],
              [
                -73.76912963328095,
                40.609648452249715
              ],
              [
                -73.7691085136945,
                40.60961554957389
              ],
              [
                -73.76908776343276,
                40.60957832503849
              ],
              [
                -73.76906734519808,
                40.60953713992963
              ],
              [
                -73.76903693493541,
                40.60947111456459
              ],
              [
                -73.76900078034787,
                40.609391553487406
              ],
              [
                -73.76898446840087,
                40.60935567038207
              ],
              [
                -73.76896899693551,
                40.60931607885402
              ],
              [
                -73.76895714023856,
                40.60927991805637
              ],
              [
                -73.76895228222197,
                40.6092564665492
              ],
              [
                -73.76895070639367,
                40.60923021317384
              ],
              [
                -73.76895214115504,
                40.60920131821295
              ],
              [
                -73.7689572722044,
                40.60915629339664
              ],
              [
                -73.76896647581314,
                40.60908505403272
              ],
              [
                -73.76897685578123,
                40.60901835473809
              ],
              [
                -73.76898858447721,
                40.60895510674909
              ],
              [
                -73.76900175529863,
                40.60889474534333
              ],
              [
                -73.76901324996051,
                40.60885084001261
              ],
              [
                -73.7690280579699,
                40.60880254804337
              ],
              [
                -73.76907784265943,
                40.608661346095026
              ],
              [
                -73.769117804581,
                40.608534741012946
              ],
              [
                -73.76913743491646,
                40.60848248642137
              ],
              [
                -73.76915015931363,
                40.60845536146521
              ],
              [
                -73.76916395114543,
                40.60843071507354
              ],
              [
                -73.7691790720129,
                40.60840809745204
              ],
              [
                -73.76919569817498,
                40.60838726486325
              ],
              [
                -73.76920552729723,
                40.608377307208926
              ],
              [
                -73.76921716314595,
                40.60836791105494
              ],
              [
                -73.76923055805143,
                40.60835913091973
              ],
              [
                -73.76924561573851,
                40.608351028762876
              ],
              [
                -73.76926228969427,
                40.60834364251945
              ],
              [
                -73.76928031925691,
                40.60833708675856
              ],
              [
                -73.769299481436,
                40.608331452335435
              ],
              [
                -73.76931943037117,
                40.60832682784869
              ],
              [
                -73.76933748366145,
                40.60832402646427
              ],
              [
                -73.76938718798357,
                40.60831881333692
              ],
              [
                -73.76940167237193,
                40.60831598836386
              ],
              [
                -73.76941417394845,
                40.60831222174792
              ],
              [
                -73.76943176069963,
                40.608304350470334
              ],
              [
                -73.76944853940671,
                40.60829392910921
              ],
              [
                -73.76946420453095,
                40.60828114552287
              ],
              [
                -73.76947829292341,
                40.608266378740126
              ],
              [
                -73.7695088278618,
                40.608227816200525
              ],
              [
                -73.76958957749022,
                40.60811860038658
              ],
              [
                -73.76960694482011,
                40.60809849110894
              ],
              [
                -73.76965731146738,
                40.60804511829117
              ],
              [
                -73.76965760798781,
                40.608044803762226
              ],
              [
                -73.76965766717893,
                40.60804472262901
              ],
              [
                -73.76967128998126,
                40.60802770362673
              ],
              [
                -73.7696818624239,
                40.60801162320963
              ],
              [
                -73.76968721740275,
                40.60800072826699
              ],
              [
                -73.76969151386992,
                40.60798820197773
              ],
              [
                -73.76970090617823,
                40.607943870188215
              ],
              [
                -73.76970592739838,
                40.60792694099789
              ],
              [
                -73.76975533215408,
                40.60781326778836
              ],
              [
                -73.76976677311299,
                40.60777804415704
              ],
              [
                -73.76977241260262,
                40.607746212937585
              ],
              [
                -73.7697723593546,
                40.60771716186028
              ],
              [
                -73.7697701968254,
                40.6077036404038
              ],
              [
                -73.76976659007242,
                40.607690764561326
              ],
              [
                -73.7697607528582,
                40.60767718246931
              ],
              [
                -73.7697521300489,
                40.60766260402667
              ],
              [
                -73.76970929856138,
                40.607604038495026
              ],
              [
                -73.76970211276303,
                40.607590974714796
              ],
              [
                -73.76969718031438,
                40.60757860935677
              ],
              [
                -73.76968970945994,
                40.60754896694642
              ],
              [
                -73.76968855777348,
                40.607536816733024
              ],
              [
                -73.76968900989174,
                40.60752561493523
              ],
              [
                -73.76969288938248,
                40.607507468029716
              ],
              [
                -73.7697003017839,
                40.60748912180668
              ],
              [
                -73.7697110570677,
                40.607470988677434
              ],
              [
                -73.76972484641294,
                40.60745363561276
              ],
              [
                -73.76974492340653,
                40.60743289278435
              ],
              [
                -73.76976899709642,
                40.60741074266819
              ],
              [
                -73.76985535749172,
                40.607338999527734
              ],
              [
                -73.76988784485623,
                40.607313508270565
              ],
              [
                -73.76996315560298,
                40.607259546295985
              ],
              [
                -73.769993369065,
                40.607235282805426
              ],
              [
                -73.76999878643595,
                40.60723004393906
              ],
              [
                -73.77000354472193,
                40.60722438107634
              ],
              [
                -73.77001104335504,
                40.607211724980715
              ],
              [
                -73.77001587858616,
                40.60719731672406
              ],
              [
                -73.7700180619659,
                40.60718112181868
              ],
              [
                -73.77001760359343,
                40.60716327196343
              ],
              [
                -73.77001456325434,
                40.60714376895291
              ],
              [
                -73.77001452895217,
                40.607143516919805
              ],
              [
                -73.77001450545818,
                40.607143445505145
              ],
              [
                -73.77000884974873,
                40.60712171347339
              ],
              [
                -73.77000025048235,
                40.60709673335053
              ],
              [
                -73.7700102145254,
                40.60705128558925
              ],
              [
                -73.77001285560377,
                40.60703922421975
              ],
              [
                -73.77001742079666,
                40.607006976368396
              ],
              [
                -73.77001735907474,
                40.606976844873905
              ],
              [
                -73.77001531520949,
                40.60696313485274
              ],
              [
                -73.77001192144185,
                40.606950133628445
              ],
              [
                -73.77000440332162,
                40.60692723621898
              ],
              [
                -73.76997746213935,
                40.60684515327842
              ],
              [
                -73.76995807121004,
                40.60678398710597
              ],
              [
                -73.76991551796586,
                40.60664479740757
              ],
              [
                -73.76986524728281,
                40.6064960386955
              ],
              [
                -73.76985052748687,
                40.6064436339371
              ],
              [
                -73.76983889691651,
                40.60638996615856
              ],
              [
                -73.76983037846216,
                40.60633515234233
              ],
              [
                -73.76982499357088,
                40.606279345151414
              ],
              [
                -73.76982277888568,
                40.60622268907027
              ],
              [
                -73.7698237091003,
                40.60616538106313
              ],
              [
                -73.76982781931672,
                40.60610762977468
              ],
              [
                -73.76983506067754,
                40.60604964116821
              ],
              [
                -73.76985219369494,
                40.60594001930245
              ],
              [
                -73.76986025918269,
                40.60590349221747
              ],
              [
                -73.7698696351318,
                40.60587086700765
              ],
              [
                -73.76987704256089,
                40.60584700920383
              ],
              [
                -73.76988039970524,
                40.60583993763162
              ],
              [
                -73.76988439044467,
                40.60583392075424
              ],
              [
                -73.76988816153622,
                40.605829642059106
              ],
              [
                -73.76989263053164,
                40.605825553064676
              ],
              [
                -73.76990388214686,
                40.60581772314251
              ],
              [
                -73.76995140139938,
                40.60579458458844
              ],
              [
                -73.76995154367613,
                40.605794503621354
              ],
              [
                -73.76996089553964,
                40.60578913760248
              ],
              [
                -73.76996865368979,
                40.605783669048094
              ],
              [
                -73.76998434837355,
                40.6057688685591
              ],
              [
                -73.76999819959372,
                40.60575078716462
              ],
              [
                -73.77001053824887,
                40.60572914491508
              ],
              [
                -73.77001717186208,
                40.605714353261696
              ],
              [
                -73.77002346997727,
                40.60570028231908
              ],
              [
                -73.77003224550127,
                40.60567385147461
              ],
              [
                -73.77003782936973,
                40.60564448716581
              ],
              [
                -73.77004041197118,
                40.605611542607335
              ],
              [
                -73.77004040748075,
                40.605609272572025
              ],
              [
                -73.77004034008233,
                40.605570703600186
              ],
              [
                -73.77003849335084,
                40.60555459008923
              ],
              [
                -73.77003373282179,
                40.60553707304843
              ],
              [
                -73.77000524884224,
                40.60547101725452
              ],
              [
                -73.77000011297223,
                40.605456093322296
              ],
              [
                -73.76999691052053,
                40.60544251718156
              ],
              [
                -73.76999408117246,
                40.6054166658867
              ],
              [
                -73.76999420287767,
                40.60538803833293
              ],
              [
                -73.76999690762636,
                40.60535766861426
              ],
              [
                -73.77000082323222,
                40.605327510060555
              ],
              [
                -73.77003044591852,
                40.605273922020736
              ],
              [
                -73.77006652043227,
                40.60522268280314
              ],
              [
                -73.77010872993579,
                40.60517424241473
              ],
              [
                -73.77015670371276,
                40.60512902628116
              ],
              [
                -73.77021002042594,
                40.60508743150988
              ],
              [
                -73.7702682118161,
                40.60504982340376
              ],
              [
                -73.77033076681522,
                40.60501653225207
              ],
              [
                -73.7703736633197,
                40.604989099625804
              ],
              [
                -73.77041086895497,
                40.60495720371523
              ],
              [
                -73.7704415925433,
                40.60492152279404
              ],
              [
                -73.77046518075221,
                40.604882815624244
              ],
              [
                -73.77048113198755,
                40.60484190531993
              ],
              [
                -73.77048910705818,
                40.60479966184391
              ],
              [
                -73.77048893638917,
                40.60475698350723
              ],
              [
                -73.77048062362502,
                40.60471477786668
              ],
              [
                -73.77047953587791,
                40.604595479583914
              ],
              [
                -73.7704746817832,
                40.6044762357975
              ],
              [
                -73.77047158381761,
                40.604453032116616
              ],
              [
                -73.77047105451692,
                40.60441441715326
              ],
              [
                -73.77047540249363,
                40.60431832145354
              ],
              [
                -73.7704744997586,
                40.60417177661493
              ],
              [
                -73.77047139482066,
                40.60409900893934
              ],
              [
                -73.77046357455843,
                40.60401935994732
              ],
              [
                -73.77045185260809,
                40.60393739745087
              ],
              [
                -73.77043556340162,
                40.60384686154676
              ],
              [
                -73.77041588743104,
                40.60375180813819
              ],
              [
                -73.77040862113736,
                40.603719023818094
              ],
              [
                -73.77037750437735,
                40.6035786235712
              ],
              [
                -73.77035205529455,
                40.60347217530938
              ],
              [
                -73.77033952766757,
                40.60342845736956
              ],
              [
                -73.77032633896619,
                40.603388167941795
              ],
              [
                -73.77029746555603,
                40.60330841369929
              ],
              [
                -73.7702718933096,
                40.60324447062789
              ],
              [
                -73.77024599008497,
                40.60318723578144
              ],
              [
                -73.77021903074902,
                40.60313524031782
              ],
              [
                -73.77021718414757,
                40.60313250773521
              ],
              [
                -73.77021459537006,
                40.603129620043916
              ],
              [
                -73.77020712683694,
                40.60312326562137
              ],
              [
                -73.77017429474198,
                40.60310202924763
              ],
              [
                -73.77016908515742,
                40.6030982810755
              ],
              [
                -73.77016543416676,
                40.603095113327136
              ],
              [
                -73.77016379701433,
                40.60309328196956
              ],
              [
                -73.77016274026437,
                40.60309160556473
              ],
              [
                -73.77016242403113,
                40.603090821563654
              ],
              [
                -73.7701622371373,
                40.60309007350498
              ],
              [
                -73.77016219214377,
                40.60308935203231
              ],
              [
                -73.77016227802436,
                40.6030886676779
              ],
              [
                -73.77016251656964,
                40.60308801160619
              ],
              [
                -73.77016288511354,
                40.60308739147834
              ],
              [
                -73.77016339512006,
                40.603086797432894
              ],
              [
                -73.77016404658139,
                40.60308623164771
              ],
              [
                -73.77016484016474,
                40.603085691946355
              ],
              [
                -73.77016577454368,
                40.60308518050411
              ],
              [
                -73.77016805885243,
                40.60308424858136
              ],
              [
                -73.77017334566267,
                40.603082674825664
              ],
              [
                -73.77017898589695,
                40.6030814251088
              ],
              [
                -73.77019158542402,
                40.60307987485414
              ],
              [
                -73.7702408196256,
                40.60307982067153
              ],
              [
                -73.77025152605671,
                40.603079148166906
              ],
              [
                -73.77025298890354,
                40.6030789369883
              ],
              [
                -73.77028288208662,
                40.60307244626041
              ],
              [
                -73.77031087956307,
                40.60306215633484
              ],
              [
                -73.7703361571643,
                40.60304837011899
              ],
              [
                -73.7703579707886,
                40.60303149344141
              ],
              [
                -73.7703756783063,
                40.60301202310496
              ],
              [
                -73.77038875846117,
                40.60299053226202
              ],
              [
                -73.77039682621424,
                40.60296765354261
              ],
              [
                -73.77039964407953,
                40.602944060431476
              ],
              [
                -73.77036919910219,
                40.602898054849476
              ],
              [
                -73.77033207783559,
                40.60285499141114
              ],
              [
                -73.770288763362,
                40.602815430513466
              ],
              [
                -73.77023981935425,
                40.60277988697249
              ],
              [
                -73.77018588274089,
                40.602748823325115
              ],
              [
                -73.77022211742572,
                40.60267348564586
              ],
              [
                -73.7702328460693,
                40.60261926802469
              ],
              [
                -73.77026469363346,
                40.60255504888111
              ],
              [
                -73.77027546523118,
                40.602488778921696
              ],
              [
                -73.77034443950986,
                40.60235834196639
              ],
              [
                -73.77039741390276,
                40.6022821120776
              ],
              [
                -73.77044533066837,
                40.602143597763266
              ],
              [
                -73.77050356147332,
                40.60207139551765
              ],
              [
                -73.77054596552425,
                40.602003179509126
              ],
              [
                -73.77058311147579,
                40.601930936297
              ],
              [
                -73.77060167895182,
                40.601896822434625
              ],
              [
                -73.7706175881491,
                40.60186873047897
              ],
              [
                -73.77065189914457,
                40.601854737184134
              ],
              [
                -73.7706886589729,
                40.6018949885093
              ],
              [
                -73.77071223428813,
                40.60193721985458
              ],
              [
                -73.77074383318684,
                40.60194531862101
              ],
              [
                -73.77078080648036,
                40.60192329503747
              ],
              [
                -73.77080471264298,
                40.601869103809086
              ],
              [
                -73.77083917528664,
                40.60181091609618
              ],
              [
                -73.77084198990094,
                40.60175869248782
              ],
              [
                -73.77084737913124,
                40.601724552307964
              ],
              [
                -73.77082643937688,
                40.60168232507241
              ],
              [
                -73.77077908903945,
                40.601656115444754
              ],
              [
                -73.77075283595511,
                40.60162593119097
              ],
              [
                -73.77074244654368,
                40.601581715800684
              ],
              [
                -73.77073733384827,
                40.60153550225483
              ],
              [
                -73.77086730922319,
                40.601292691303186
              ],
              [
                -73.77091239686352,
                40.601210418901815
              ],
              [
                -73.77093512613936,
                40.60115230828559
              ],
              [
                -73.77105282368589,
                40.60074930554538
              ],
              [
                -73.77110030469566,
                40.60058786329961
              ],
              [
                -73.7711110344584,
                40.60054602854739
              ],
              [
                -73.77111953843155,
                40.60050186572423
              ],
              [
                -73.77112590127966,
                40.60045504211785
              ],
              [
                -73.77113020896819,
                40.60040484589827
              ],
              [
                -73.77113028499313,
                40.6003858091471
              ],
              [
                -73.77112817318242,
                40.6003645980265
              ],
              [
                -73.77111187877777,
                40.60027562941647
              ],
              [
                -73.77111054072343,
                40.600255805977156
              ],
              [
                -73.77111126381162,
                40.600237733977686
              ],
              [
                -73.77111292431104,
                40.60022883090313
              ],
              [
                -73.77111624260783,
                40.60021905779787
              ],
              [
                -73.77113621586231,
                40.60017594426856
              ],
              [
                -73.77113891311716,
                40.600164540029304
              ],
              [
                -73.7711393089279,
                40.60015922794802
              ],
              [
                -73.77113905426295,
                40.60015407657258
              ],
              [
                -73.77113493157037,
                40.60013350978704
              ],
              [
                -73.77111914447595,
                40.600086029410456
              ],
              [
                -73.77111419742232,
                40.60006435310832
              ],
              [
                -73.77110624222676,
                40.60000025648079
              ],
              [
                -73.77109550611405,
                40.59989580947338
              ],
              [
                -73.7710871200451,
                40.59979180908827
              ],
              [
                -73.77108437410661,
                40.599696573028744
              ],
              [
                -73.77108674822033,
                40.59966934546764
              ],
              [
                -73.7710939261192,
                40.59963985912253
              ],
              [
                -73.77110581598822,
                40.599607518241484
              ],
              [
                -73.77113534274287,
                40.59954104563589
              ],
              [
                -73.7711467245462,
                40.59951232305059
              ],
              [
                -73.7711557024036,
                40.59948176876552
              ],
              [
                -73.77115986181745,
                40.59945396828773
              ],
              [
                -73.7711605243632,
                40.59942245207376
              ],
              [
                -73.77115807337863,
                40.59939324240758
              ],
              [
                -73.77115249540978,
                40.599366161513245
              ],
              [
                -73.7711437557007,
                40.59934115487388
              ],
              [
                -73.77113690037558,
                40.59932803855503
              ],
              [
                -73.77112720101427,
                40.59931407892318
              ],
              [
                -73.77109056842833,
                40.599271861682865
              ],
              [
                -73.77107908782979,
                40.59925728584282
              ],
              [
                -73.77107042772171,
                40.59924356265017
              ],
              [
                -73.77106455326746,
                40.599230467044286
              ],
              [
                -73.77105997150446,
                40.5992123394099
              ],
              [
                -73.77105828189103,
                40.59919198386323
              ],
              [
                -73.77105912334285,
                40.59917048108936
              ],
              [
                -73.77106497919539,
                40.599109770434104
              ],
              [
                -73.77106471104767,
                40.599091182644685
              ],
              [
                -73.77106245222207,
                40.599074662059564
              ],
              [
                -73.77105014425419,
                40.59902249776632
              ],
              [
                -73.77103672115965,
                40.59898153279007
              ],
              [
                -73.7710202328009,
                40.59894585748658
              ],
              [
                -73.77101070690539,
                40.5989297908334
              ],
              [
                -73.7710002428972,
                40.598914767863235
              ],
              [
                -73.77098168371994,
                40.59889409104952
              ],
              [
                -73.77095834516884,
                40.59887485333719
              ],
              [
                -73.77093086474137,
                40.59885759912507
              ],
              [
                -73.77090026970177,
                40.59884297578282
              ],
              [
                -73.77086131911726,
                40.59882870584585
              ],
              [
                -73.77082352496849,
                40.598818499960174
              ],
              [
                -73.77078720521953,
                40.59881244001286
              ],
              [
                -73.77075252398886,
                40.598810542248174
              ],
              [
                -73.77073894803341,
                40.59881121728292
              ],
              [
                -73.77072503590007,
                40.59881308010125
              ],
              [
                -73.7706956755438,
                40.59882045129777
              ],
              [
                -73.77066458708045,
                40.598832275831654
              ],
              [
                -73.77064482646136,
                40.5988414128369
              ],
              [
                -73.77061795344157,
                40.59885383212521
              ],
              [
                -73.77059633936453,
                40.59886564874631
              ],
              [
                -73.77057450087534,
                40.59888111170244
              ],
              [
                -73.77055524769148,
                40.59889729232012
              ],
              [
                -73.77051209601598,
                40.59893651399356
              ],
              [
                -73.77049004964125,
                40.59895419260177
              ],
              [
                -73.7704683455717,
                40.598968259780925
              ],
              [
                -73.7704464760719,
                40.598978851723984
              ],
              [
                -73.77043736736675,
                40.59898212012739
              ],
              [
                -73.77042764768771,
                40.5989848021283
              ],
              [
                -73.77040662910537,
                40.598988389335254
              ],
              [
                -73.77038390667956,
                40.59898949670956
              ],
              [
                -73.77036024774937,
                40.5989881078579
              ],
              [
                -73.77033634816473,
                40.59898422333275
              ],
              [
                -73.77031339088282,
                40.59897801872386
              ],
              [
                -73.7702923541327,
                40.59896976420225
              ],
              [
                -73.77028053833372,
                40.598963310122805
              ],
              [
                -73.77027408869283,
                40.59895978547217
              ],
              [
                -73.7701768242501,
                40.59889698631351
              ],
              [
                -73.77011765933023,
                40.59885549768401
              ],
              [
                -73.77007316919209,
                40.59882066602884
              ],
              [
                -73.77005175466385,
                40.59880546743722
              ],
              [
                -73.77001557170142,
                40.59878377364488
              ],
              [
                -73.76997616916023,
                40.59876378404295
              ],
              [
                -73.76993238412204,
                40.59874703640102
              ],
              [
                -73.76982828415264,
                40.59870722425543
              ],
              [
                -73.76980002538818,
                40.598693091134756
              ],
              [
                -73.76973574379937,
                40.598655554470966
              ],
              [
                -73.7697177925864,
                40.59864685537094
              ],
              [
                -73.76970071987024,
                40.598640130523705
              ],
              [
                -73.76967401111023,
                40.59863268407702
              ],
              [
                -73.76961311333139,
                40.59862099039477
              ],
              [
                -73.76958690877635,
                40.598614372870564
              ],
              [
                -73.76941553633635,
                40.5985615467694
              ],
              [
                -73.7693153972632,
                40.59853115945395
              ],
              [
                -73.76930921498162,
                40.598528905160784
              ],
              [
                -73.76930264378103,
                40.59852579417719
              ],
              [
                -73.7692709962844,
                40.598508044694874
              ],
              [
                -73.7692658644657,
                40.59850607965441
              ],
              [
                -73.76926090713974,
                40.59850469227168
              ],
              [
                -73.7692560185019,
                40.59850384413721
              ],
              [
                -73.76925114099092,
                40.59850352860201
              ],
              [
                -73.7692324467041,
                40.59850439136929
              ],
              [
                -73.76922076994283,
                40.59850571016925
              ],
              [
                -73.76918917846884,
                40.598509275920996
              ],
              [
                -73.76910411185766,
                40.598514039627396
              ],
              [
                -73.76907474247716,
                40.598513711060114
              ],
              [
                -73.76905027444222,
                40.5985104290693
              ],
              [
                -73.76903989945065,
                40.59850768753569
              ],
              [
                -73.7690308024206,
                40.59850421194153
              ],
              [
                -73.76902297252785,
                40.59850001801285
              ],
              [
                -73.76901642123492,
                40.59849509605623
              ],
              [
                -73.76900728611821,
                40.598485604222866
              ],
              [
                -73.76897990097018,
                40.59845300953767
              ],
              [
                -73.76893146064765,
                40.59846776382944
              ],
              [
                -73.76889176380759,
                40.59844309700455
              ],
              [
                -73.76890472549103,
                40.59839705641078
              ],
              [
                -73.76888469830521,
                40.59839453099987
              ],
              [
                -73.76883903289473,
                40.5983918645565
              ],
              [
                -73.7687971144413,
                40.598388996388756
              ],
              [
                -73.7687851600615,
                40.59838854966014
              ],
              [
                -73.76870119627677,
                40.598385372366586
              ],
              [
                -73.76865718894716,
                40.598381754101055
              ],
              [
                -73.76864559342032,
                40.59837999263673
              ],
              [
                -73.76863287860478,
                40.59837725624019
              ],
              [
                -73.76857915096119,
                40.59836281253829
              ],
              [
                -73.76856615207234,
                40.59836036321092
              ],
              [
                -73.76855413008346,
                40.598359006132895
              ],
              [
                -73.76853280608204,
                40.598358729313645
              ],
              [
                -73.76848525498117,
                40.59836194760225
              ],
              [
                -73.76846416625214,
                40.59836214836893
              ],
              [
                -73.76843153022395,
                40.59836018265601
              ],
              [
                -73.76839693702648,
                40.598356879620326
              ],
              [
                -73.76831805693715,
                40.598345851628416
              ],
              [
                -73.76829168302484,
                40.59834035055518
              ],
              [
                -73.76826549483131,
                40.59833244429066
              ],
              [
                -73.76823995164165,
                40.59832227984718
              ],
              [
                -73.76821563403921,
                40.59831007400694
              ],
              [
                -73.76821010481004,
                40.59830667691105
              ],
              [
                -73.76820476656758,
                40.598302775600494
              ],
              [
                -73.76819439128423,
                40.59829323680527
              ],
              [
                -73.76816672252433,
                40.59825915854686
              ],
              [
                -73.76815671757392,
                40.598248358996194
              ],
              [
                -73.76814644628057,
                40.598239828265406
              ],
              [
                -73.76814108283848,
                40.59823635075125
              ],
              [
                -73.76813551682744,
                40.598233358664366
              ],
              [
                -73.7681062491692,
                40.59822061090646
              ],
              [
                -73.76804356253508,
                40.59819727867728
              ],
              [
                -73.76801725401064,
                40.59818645491747
              ],
              [
                -73.7679735866159,
                40.59817025642905
              ],
              [
                -73.76795788671298,
                40.59816310136433
              ],
              [
                -73.76795043655434,
                40.5981585114185
              ],
              [
                -73.76794453414253,
                40.59815366358615
              ],
              [
                -73.76794006182584,
                40.59814845041089
              ],
              [
                -73.76793835881749,
                40.598145699986695
              ],
              [
                -73.7679369873995,
                40.59814284200773
              ],
              [
                -73.76793592288794,
                40.59813970638149
              ],
              [
                -73.76793526089354,
                40.59813648160468
              ],
              [
                -73.76793501234441,
                40.598133185122414
              ],
              [
                -73.76793516521563,
                40.59812979898457
              ],
              [
                -73.76793669062471,
                40.59812275107258
              ],
              [
                -73.76793987039952,
                40.5981152483074
              ],
              [
                -73.76795030970385,
                40.59809964371938
              ],
              [
                -73.76797219339382,
                40.598074627296874
              ],
              [
                -73.76797547820793,
                40.5980713022647
              ],
              [
                -73.76797967142298,
                40.59806790552088
              ],
              [
                -73.76800049037357,
                40.59805366530519
              ],
              [
                -73.76800499434478,
                40.59804938798138
              ],
              [
                -73.76800671591312,
                40.598047239537046
              ],
              [
                -73.7680081292507,
                40.59804505428412
              ],
              [
                -73.76802839158346,
                40.59800389559855
              ],
              [
                -73.76803688591761,
                40.597983245938565
              ],
              [
                -73.76806678092747,
                40.59791051677544
              ],
              [
                -73.76808948115853,
                40.59786141186908
              ],
              [
                -73.76809925802587,
                40.59784597005487
              ],
              [
                -73.76795322999997,
                40.597426056999886
              ],
              [
                -73.76776273800007,
                40.59654905699993
              ],
              [
                -73.76771811799993,
                40.596261309999925
              ],
              [
                -73.76742090699994,
                40.59630520399985
              ],
              [
                -73.76733661099996,
                40.59637765199988
              ],
              [
                -73.76726813399988,
                40.59645316599989
              ],
              [
                -73.76721443599983,
                40.596535878999845
              ],
              [
                -73.76717720099997,
                40.5966231809999
              ],
              [
                -73.76715706599988,
                40.59671223899988
              ],
              [
                -73.767127717,
                40.5968490139999
              ],
              [
                -73.76711717700005,
                40.59698813299991
              ],
              [
                -73.76712577699985,
                40.59712732699992
              ],
              [
                -73.76715321499994,
                40.597264333999874
              ],
              [
                -73.76716449199986,
                40.59742159799991
              ],
              [
                -73.76715168699985,
                40.59758390099991
              ],
              [
                -73.76711296699986,
                40.597748041999914
              ],
              [
                -73.76704742299998,
                40.597910426999896
              ],
              [
                -73.76695532899997,
                40.59806727399992
              ],
              [
                -73.76683818399978,
                40.598214847999884
              ],
              [
                -73.76669864799996,
                40.598349814999935
              ],
              [
                -73.76654034499992,
                40.598469425999895
              ],
              [
                -73.76636755699995,
                40.59857174399987
              ],
              [
                -73.76618488799977,
                40.59865569699989
              ],
              [
                -73.76599689399985,
                40.598721061999875
              ],
              [
                -73.76654204999997,
                40.599434441999904
              ],
              [
                -73.76444582499997,
                40.600435240999914
              ],
              [
                -73.76386778499985,
                40.59972731299991
              ],
              [
                -73.76336096499996,
                40.59910504299995
              ],
              [
                -73.76165797299984,
                40.599973069999876
              ],
              [
                -73.76158779199984,
                40.59990109899993
              ],
              [
                -73.76154124799989,
                40.599846560999886
              ],
              [
                -73.76128753399995,
                40.59954927099996
              ],
              [
                -73.76181486399989,
                40.59895137099994
              ],
              [
                -73.762552899,
                40.59831811799991
              ],
              [
                -73.7633344969999,
                40.59782287399992
              ],
              [
                -73.76319972899987,
                40.59714459099989
              ],
              [
                -73.76301370999988,
                40.596081549999916
              ],
              [
                -73.76299716399983,
                40.595987023999875
              ],
              [
                -73.762981598,
                40.59589946999991
              ],
              [
                -73.76277845599988,
                40.59475654399989
              ],
              [
                -73.76362979899989,
                40.59466694999985
              ],
              [
                -73.76440183099986,
                40.594571682999906
              ],
              [
                -73.76429039299992,
                40.59393964499993
              ],
              [
                -73.76414300399985,
                40.593205171999905
              ],
              [
                -73.7640494709999,
                40.59273904699988
              ],
              [
                -73.763912182,
                40.592039344999925
              ],
              [
                -73.76385620499985,
                40.59175407799996
              ],
              [
                -73.76376223423699,
                40.591439709016804
              ],
              [
                -73.76354057868238,
                40.59147225835319
              ],
              [
                -73.76347652151587,
                40.59148431951077
              ],
              [
                -73.76308430596761,
                40.59158769299509
              ],
              [
                -73.76303822435928,
                40.59159747704603
              ],
              [
                -73.76295275572143,
                40.59161117743101
              ],
              [
                -73.76276146869947,
                40.591624209177986
              ],
              [
                -73.76243967044952,
                40.59163394611224
              ],
              [
                -73.76236416229632,
                40.591637683712996
              ],
              [
                -73.76229978054906,
                40.59164087015593
              ],
              [
                -73.76172098788959,
                40.591681222340064
              ],
              [
                -73.76157030497218,
                40.591699964797584
              ],
              [
                -73.76141229292924,
                40.59172329321629
              ],
              [
                -73.76134311678325,
                40.59172663385036
              ],
              [
                -73.7612787298498,
                40.59171832330684
              ],
              [
                -73.7612089514512,
                40.59170804778962
              ],
              [
                -73.76108305835777,
                40.59169203527808
              ],
              [
                -73.76095548743733,
                40.591682710952234
              ],
              [
                -73.76084382257397,
                40.59166493654668
              ],
              [
                -73.76078573008135,
                40.591660781338454
              ],
              [
                -73.76065279969747,
                40.59164727620251
              ],
              [
                -73.76054954013325,
                40.59163800155925
              ],
              [
                -73.76041824810322,
                40.59163199116775
              ],
              [
                -73.76038205920773,
                40.591630132688444
              ],
              [
                -73.76030924413571,
                40.59162328130439
              ],
              [
                -73.76023595718786,
                40.591620057084604
              ],
              [
                -73.76016560077571,
                40.59161922556551
              ],
              [
                -73.76007031564953,
                40.59162316916147
              ],
              [
                -73.75994809255582,
                40.59161805117163
              ],
              [
                -73.75989658654491,
                40.591612045000495
              ],
              [
                -73.75979294806714,
                40.59159164523479
              ],
              [
                -73.75976734920664,
                40.59158660552718
              ],
              [
                -73.75969570621596,
                40.59157267789848
              ],
              [
                -73.75967175594619,
                40.59156760258275
              ],
              [
                -73.75960054182336,
                40.591552496045956
              ],
              [
                -73.75947486517903,
                40.59152563198178
              ],
              [
                -73.75938010754977,
                40.5915045774643
              ],
              [
                -73.75928714482238,
                40.5914932070092
              ],
              [
                -73.7592283619613,
                40.59150980685748
              ],
              [
                -73.75914462613561,
                40.59150163466769
              ],
              [
                -73.75906249579332,
                40.591493620078055
              ],
              [
                -73.75904521901246,
                40.59149193468693
              ],
              [
                -73.75892785511789,
                40.59147917052124
              ],
              [
                -73.75885981629324,
                40.59147504724865
              ],
              [
                -73.75878945264255,
                40.59146647080124
              ],
              [
                -73.75871537076522,
                40.59145097941272
              ],
              [
                -73.75856200201768,
                40.591428567610656
              ],
              [
                -73.75829781442644,
                40.591389955448335
              ],
              [
                -73.75816643522685,
                40.591378953928334
              ],
              [
                -73.75813777460367,
                40.59137949805417
              ],
              [
                -73.75811181401144,
                40.59137839818963
              ],
              [
                -73.75804097641971,
                40.591373854573675
              ],
              [
                -73.7580128027422,
                40.59136701396991
              ],
              [
                -73.75798801159917,
                40.59136273799489
              ],
              [
                -73.75795637515171,
                40.59135995234956
              ],
              [
                -73.75789189861872,
                40.59135698827765
              ],
              [
                -73.75785285721308,
                40.59135045845573
              ],
              [
                -73.7577894312456,
                40.59133456574357
              ],
              [
                -73.75773334295016,
                40.591321768671946
              ],
              [
                -73.75767587486234,
                40.591305013951896
              ],
              [
                -73.75762331559734,
                40.591292692514706
              ],
              [
                -73.7575576526475,
                40.591278468747085
              ],
              [
                -73.75751466374516,
                40.59126914831187
              ],
              [
                -73.75748350555223,
                40.59126146463549
              ],
              [
                -73.75745186056761,
                40.591251186057356
              ],
              [
                -73.75739663764747,
                40.591237290478574
              ],
              [
                -73.75733639369399,
                40.59122053039772
              ],
              [
                -73.75730257288987,
                40.59121411946993
              ],
              [
                -73.7572407067868,
                40.59120501102492
              ],
              [
                -73.7572211627071,
                40.59120323998719
              ],
              [
                -73.75720108105554,
                40.59119960454252
              ],
              [
                -73.75717655040047,
                40.59119183544119
              ],
              [
                -73.75714554578026,
                40.591184304617144
              ],
              [
                -73.75711328332213,
                40.5911748798808
              ],
              [
                -73.75706297359264,
                40.591158131775074
              ],
              [
                -73.75703419417233,
                40.59114937160005
              ],
              [
                -73.75700245101464,
                40.59113681495333
              ],
              [
                -73.75694734161964,
                40.59112124510419
              ],
              [
                -73.75691806701087,
                40.59111190738198
              ],
              [
                -73.7568249426794,
                40.591087062504144
              ],
              [
                -73.75675525782047,
                40.59107102144743
              ],
              [
                -73.75669866323629,
                40.59106086055656
              ],
              [
                -73.75667054293734,
                40.59105904546612
              ],
              [
                -73.75664012057273,
                40.591060322116604
              ],
              [
                -73.75661224706137,
                40.59105875011801
              ],
              [
                -73.75657827475325,
                40.59105541902863
              ],
              [
                -73.75654462693286,
                40.59105018748697
              ],
              [
                -73.75651228449009,
                40.59104303126979
              ],
              [
                -73.75648210550776,
                40.59103602403913
              ],
              [
                -73.75645231340866,
                40.59102935201031
              ],
              [
                -73.75642199513254,
                40.59102143478118
              ],
              [
                -73.75638878883085,
                40.591011809149556
              ],
              [
                -73.75635783011097,
                40.59100126166589
              ],
              [
                -73.75632502885645,
                40.59099079101608
              ],
              [
                -73.75629179897615,
                40.59098095003038
              ],
              [
                -73.75625999802007,
                40.59097146404656
              ],
              [
                -73.75623154401502,
                40.590964153494056
              ],
              [
                -73.75619478638879,
                40.59095315172029
              ],
              [
                -73.75616278331961,
                40.59094419618295
              ],
              [
                -73.7561318000948,
                40.59093718765597
              ],
              [
                -73.75610479724386,
                40.59092691805504
              ],
              [
                -73.75607723387715,
                40.590911811338955
              ],
              [
                -73.75605024598252,
                40.59089781371335
              ],
              [
                -73.75602036595409,
                40.59088630496383
              ],
              [
                -73.7559546362305,
                40.590874198049825
              ],
              [
                -73.75592115806575,
                40.59087110922584
              ],
              [
                -73.75586357310075,
                40.59087350888897
              ],
              [
                -73.75583628405437,
                40.59087361341052
              ],
              [
                -73.75580079510667,
                40.59087153819075
              ],
              [
                -73.75577045063802,
                40.59086727791204
              ],
              [
                -73.75571850574238,
                40.590865321314354
              ],
              [
                -73.75556957875274,
                40.59086577810024
              ],
              [
                -73.75553036586294,
                40.59086745089644
              ],
              [
                -73.7555038248681,
                40.5908697812256
              ],
              [
                -73.75541498594801,
                40.59086207358676
              ],
              [
                -73.755376897528,
                40.59085974006774
              ],
              [
                -73.75533106525006,
                40.590859120464394
              ],
              [
                -73.75528072638602,
                40.59086046307248
              ],
              [
                -73.75521122361054,
                40.59086253882333
              ],
              [
                -73.75508259331284,
                40.590868937948734
              ],
              [
                -73.75505442583602,
                40.590870192683255
              ],
              [
                -73.75501860929782,
                40.59087026073923
              ],
              [
                -73.75498975915814,
                40.59087129822395
              ],
              [
                -73.75495964643265,
                40.59087474627759
              ],
              [
                -73.75486536388713,
                40.59088870129377
              ],
              [
                -73.75483172480736,
                40.590894256856515
              ],
              [
                -73.75480303925438,
                40.590898347041005
              ],
              [
                -73.75477000716293,
                40.590902581399654
              ],
              [
                -73.75473532047647,
                40.59090675743492
              ],
              [
                -73.754676719751,
                40.59091243272865
              ],
              [
                -73.75464087599367,
                40.59091655299063
              ],
              [
                -73.754611810522,
                40.590921461202385
              ],
              [
                -73.75458831317428,
                40.590928156111346
              ],
              [
                -73.75457182984402,
                40.59093284860115
              ],
              [
                -73.75453500647647,
                40.590939929504735
              ],
              [
                -73.75447308112479,
                40.59094029175186
              ],
              [
                -73.75440305171234,
                40.59093799955587
              ],
              [
                -73.75438328246366,
                40.59093624614968
              ],
              [
                -73.75433376822224,
                40.590931871638766
              ],
              [
                -73.75426138209615,
                40.59092450402412
              ],
              [
                -73.75406364294247,
                40.59090606949452
              ],
              [
                -73.75398349406454,
                40.59090256575355
              ],
              [
                -73.75394637764788,
                40.59090268497196
              ],
              [
                -73.75390232431987,
                40.59090975840008
              ],
              [
                -73.75385060867406,
                40.590922462664544
              ],
              [
                -73.75381427976859,
                40.59092982266386
              ],
              [
                -73.75378173188352,
                40.590930797643246
              ],
              [
                -73.75374861134371,
                40.59093015137156
              ],
              [
                -73.75372199695761,
                40.59093001207567
              ],
              [
                -73.75365382039506,
                40.590934450224076
              ],
              [
                -73.75359114847551,
                40.59094208806378
              ],
              [
                -73.75355794778343,
                40.590943627496
              ],
              [
                -73.75352988157324,
                40.59094648500059
              ],
              [
                -73.75349168911401,
                40.59095322941644
              ],
              [
                -73.75345522617467,
                40.59096509221967
              ],
              [
                -73.75343228226795,
                40.59097256273838
              ],
              [
                -73.75338089874751,
                40.59099413754488
              ],
              [
                -73.7532993144495,
                40.59102111299008
              ],
              [
                -73.7532677610427,
                40.591034192322326
              ],
              [
                -73.75317176753282,
                40.59108262362464
              ],
              [
                -73.75313483211737,
                40.591091000521054
              ],
              [
                -73.75311212524532,
                40.591098057518565
              ],
              [
                -73.7530517083061,
                40.59111573930899
              ],
              [
                -73.75299279987641,
                40.59112775191379
              ],
              [
                -73.75293148208962,
                40.59113669769829
              ],
              [
                -73.75286727165079,
                40.59114163862416
              ],
              [
                -73.75284115059198,
                40.59114564370349
              ],
              [
                -73.75278463818802,
                40.5911519521024
              ],
              [
                -73.7527511645542,
                40.59115695908709
              ],
              [
                -73.75271719872373,
                40.59116105531525
              ],
              [
                -73.75267584226928,
                40.59116746764309
              ],
              [
                -73.75263919939694,
                40.59117343123855
              ],
              [
                -73.75257921234481,
                40.591183460100424
              ],
              [
                -73.75235329623182,
                40.59123572593449
              ],
              [
                -73.75223475926043,
                40.591256407796244
              ],
              [
                -73.75208039307323,
                40.591277354836976
              ],
              [
                -73.75187164604968,
                40.59130308266377
              ],
              [
                -73.7518092615259,
                40.59131892325714
              ],
              [
                -73.75175127901,
                40.59134251756634
              ],
              [
                -73.75163854884676,
                40.59138023139623
              ],
              [
                -73.75154772812445,
                40.5914220086854
              ],
              [
                -73.75147213875667,
                40.59147261601185
              ],
              [
                -73.7513584707491,
                40.59157819191071
              ],
              [
                -73.75131940156294,
                40.59161696509918
              ],
              [
                -73.7511534608213,
                40.59180828256593
              ],
              [
                -73.75103368083253,
                40.5919686499819
              ],
              [
                -73.75092985909811,
                40.59211661561273
              ],
              [
                -73.75083983764087,
                40.59224470968875
              ],
              [
                -73.75074472812084,
                40.59233727580715
              ],
              [
                -73.75064164562772,
                40.59241330843882
              ],
              [
                -73.75037372333662,
                40.59261259832038
              ],
              [
                -73.75022538179961,
                40.59270823678695
              ],
              [
                -73.74999063621222,
                40.592810305377085
              ],
              [
                -73.74980984069295,
                40.592916101401364
              ],
              [
                -73.74971695134795,
                40.59297329973449
              ],
              [
                -73.74963881698261,
                40.59301136471629
              ],
              [
                -73.74950615034876,
                40.59307241936009
              ],
              [
                -73.74938620259218,
                40.593140561804695
              ],
              [
                -73.7493317329166,
                40.59316846807457
              ],
              [
                -73.74923723499444,
                40.593229056165164
              ],
              [
                -73.74918491752474,
                40.59327851543212
              ],
              [
                -73.74895028827507,
                40.593405383116774
              ],
              [
                -73.7485123044914,
                40.59354496191743
              ],
              [
                -73.74830456677556,
                40.59358869579131
              ],
              [
                -73.74814789619522,
                40.593607732554695
              ],
              [
                -73.7480968163944,
                40.59362318914887
              ],
              [
                -73.74793001816698,
                40.59376851076364
              ],
              [
                -73.74786754387374,
                40.59379812802525
              ],
              [
                -73.74775402443677,
                40.59384325554806
              ],
              [
                -73.74765788192904,
                40.593881993330605
              ],
              [
                -73.7476028251707,
                40.593911543809455
              ],
              [
                -73.74754294839317,
                40.593938229632755
              ],
              [
                -73.74741792429444,
                40.59403588753023
              ],
              [
                -73.7473428051014,
                40.59405715550065
              ],
              [
                -73.74727767310603,
                40.59406444960137
              ],
              [
                -73.74721470033396,
                40.59407279480699
              ],
              [
                -73.74718527378874,
                40.594075449584224
              ],
              [
                -73.74715073481806,
                40.59407789441439
              ],
              [
                -73.74712916578538,
                40.59408031567385
              ],
              [
                -73.74712039646221,
                40.59408129602737
              ],
              [
                -73.74705451165403,
                40.5940815819083
              ],
              [
                -73.7469877100281,
                40.59409014305162
              ],
              [
                -73.74692257807817,
                40.594109757437536
              ],
              [
                -73.74687686045282,
                40.59416285051163
              ],
              [
                -73.7468394839836,
                40.59420899253098
              ],
              [
                -73.74675560739023,
                40.594243694148254
              ],
              [
                -73.74664920374843,
                40.59428089500873
              ],
              [
                -73.74662298522834,
                40.59428658867153
              ],
              [
                -73.74657572303371,
                40.59429685271141
              ],
              [
                -73.74642483662306,
                40.594310351461175
              ],
              [
                -73.74633803574713,
                40.594327891423674
              ],
              [
                -73.74606380498341,
                40.5943537429195
              ],
              [
                -73.74597487469721,
                40.594359696390406
              ],
              [
                -73.74588152239984,
                40.594375682763335
              ],
              [
                -73.74576700628545,
                40.594399419083096
              ],
              [
                -73.7453919908722,
                40.59448200412548
              ],
              [
                -73.74524623370854,
                40.59451959294358
              ],
              [
                -73.74514756425398,
                40.59454824576608
              ],
              [
                -73.74506981727696,
                40.59457025243548
              ],
              [
                -73.74472011812641,
                40.594633891375196
              ],
              [
                -73.74461964043492,
                40.59464731092186
              ],
              [
                -73.74451941399786,
                40.59465323866541
              ],
              [
                -73.74414761988396,
                40.59466528029152
              ],
              [
                -73.74398868331865,
                40.594664628781324
              ],
              [
                -73.74358643822328,
                40.59465040476105
              ],
              [
                -73.74353464516977,
                40.59464182422798
              ],
              [
                -73.74351563536557,
                40.59462094375596
              ],
              [
                -73.74350188978259,
                40.59460093027143
              ],
              [
                -73.74348442282039,
                40.594590516990586
              ],
              [
                -73.74346642638272,
                40.59459756333345
              ],
              [
                -73.74345571627173,
                40.59461200245116
              ],
              [
                -73.74345368532323,
                40.594636203815874
              ],
              [
                -73.74347021013651,
                40.59471074143902
              ],
              [
                -73.74346867615824,
                40.594734584393905
              ],
              [
                -73.74345292601652,
                40.594756783303794
              ],
              [
                -73.74342845401051,
                40.59477374753134
              ],
              [
                -73.74340201112331,
                40.59478428844295
              ],
              [
                -73.7433744085999,
                40.59479188108
              ],
              [
                -73.74334951603205,
                40.59479514827628
              ],
              [
                -73.74331966019673,
                40.594795982400626
              ],
              [
                -73.74328649698155,
                40.59479687178487
              ],
              [
                -73.74322060926447,
                40.594794580862654
              ],
              [
                -73.74319069326747,
                40.59479254268567
              ],
              [
                -73.74316618149479,
                40.5947890843661
              ],
              [
                -73.74313202933371,
                40.59478579329887
              ],
              [
                -73.7430994470635,
                40.59479203181844
              ],
              [
                -73.74306717345472,
                40.59480412635808
              ],
              [
                -73.74304104329525,
                40.59481910675951
              ],
              [
                -73.74300969211616,
                40.59482781672779
              ],
              [
                -73.74297445988654,
                40.594831969904114
              ],
              [
                -73.74291424530844,
                40.594832159043534
              ],
              [
                -73.74287488141269,
                40.59482601158156
              ],
              [
                -73.74283993997102,
                40.59481931344782
              ],
              [
                -73.74280966836622,
                40.594808377363236
              ],
              [
                -73.74279563385534,
                40.59480522068061
              ],
              [
                -73.74277459101098,
                40.594790810567766
              ],
              [
                -73.7427604397498,
                40.59474431144506
              ],
              [
                -73.74261837534051,
                40.5947368068089
              ],
              [
                -73.7423874258487,
                40.59472925606017
              ],
              [
                -73.74242926334367,
                40.594868517806525
              ],
              [
                -73.74232229363122,
                40.594895474134404
              ],
              [
                -73.74225988208669,
                40.59490455501748
              ],
              [
                -73.74222603097068,
                40.594905883324266
              ],
              [
                -73.74214660413497,
                40.59489483679036
              ],
              [
                -73.7420847378624,
                40.59487927162875
              ],
              [
                -73.74202229932045,
                40.59485881545237
              ],
              [
                -73.74196836844072,
                40.59483786620655
              ],
              [
                -73.74191490578423,
                40.5948181498347
              ],
              [
                -73.7418488883716,
                40.59479449776606
              ],
              [
                -73.74180151991479,
                40.59477199527085
              ],
              [
                -73.74177588782638,
                40.59475904205792
              ],
              [
                -73.74174977224075,
                40.59474256660814
              ],
              [
                -73.7417376616572,
                40.594727771793366
              ],
              [
                -73.74174014598705,
                40.59468094923735
              ],
              [
                -73.74173218981556,
                40.59466416328336
              ],
              [
                -73.74171730107162,
                40.5946498837432
              ],
              [
                -73.74169766300271,
                40.59464174405287
              ],
              [
                -73.74167638401231,
                40.59464243402206
              ],
              [
                -73.74166197274509,
                40.594654370132346
              ],
              [
                -73.74165774557973,
                40.594675225647016
              ],
              [
                -73.74165498494048,
                40.59474732500191
              ],
              [
                -73.7416408809993,
                40.59477144541601
              ],
              [
                -73.74162091692338,
                40.59478649313513
              ],
              [
                -73.74159167749859,
                40.59479568509307
              ],
              [
                -73.74156118998467,
                40.59480118137394
              ],
              [
                -73.74150058113821,
                40.59480557506032
              ],
              [
                -73.74146578067547,
                40.59480802697642
              ],
              [
                -73.74137287779187,
                40.59480814176125
              ],
              [
                -73.7413182062532,
                40.594792592881156
              ],
              [
                -73.74129498270835,
                40.59478271580659
              ],
              [
                -73.7412611561602,
                40.59476875309056
              ],
              [
                -73.74118104185605,
                40.59473063334281
              ],
              [
                -73.74115233831625,
                40.594714737780315
              ],
              [
                -73.74112633401295,
                40.59470622295994
              ],
              [
                -73.74108997034794,
                40.594697335158614
              ],
              [
                -73.74102990553375,
                40.59467729775638
              ],
              [
                -73.74096717985206,
                40.59465173387743
              ],
              [
                -73.7409401621547,
                40.594636607841124
              ],
              [
                -73.74089520274937,
                40.5946115791919
              ],
              [
                -73.74088874912545,
                40.59459718403629
              ],
              [
                -73.74090533468139,
                40.59455473449514
              ],
              [
                -73.7409156959837,
                40.59451789891997
              ],
              [
                -73.74091740970762,
                40.594505682310846
              ],
              [
                -73.7409017059638,
                40.5944882487506
              ],
              [
                -73.74086643220772,
                40.59449105173323
              ],
              [
                -73.74085331865265,
                40.59450336828138
              ],
              [
                -73.74084054278644,
                40.59454742062226
              ],
              [
                -73.74083755553994,
                40.59457433879751
              ],
              [
                -73.74083862240138,
                40.59459764797131
              ],
              [
                -73.74083151673132,
                40.59461116602116
              ],
              [
                -73.74079275823493,
                40.59465024325229
              ],
              [
                -73.74076790338214,
                40.59466251420723
              ],
              [
                -73.74073935409571,
                40.59467054535008
              ],
              [
                -73.74070773498553,
                40.594672014279574
              ],
              [
                -73.74069758978415,
                40.59469583776901
              ],
              [
                -73.7406816322751,
                40.59470719329445
              ],
              [
                -73.74065158806899,
                40.59472003049213
              ],
              [
                -73.74062832064128,
                40.594721652272085
              ],
              [
                -73.7405061595838,
                40.59469947511962
              ],
              [
                -73.740419032672,
                40.59467880098619
              ],
              [
                -73.7403919369275,
                40.59467450780171
              ],
              [
                -73.74036408761145,
                40.59466969808866
              ],
              [
                -73.74032962528412,
                40.59466121825714
              ],
              [
                -73.74027412098314,
                40.59464707173606
              ],
              [
                -73.74021122864657,
                40.5946251649163
              ],
              [
                -73.74018445146005,
                40.59461192931863
              ],
              [
                -73.74014286699474,
                40.59458153196842
              ],
              [
                -73.74013382011007,
                40.59456603190909
              ],
              [
                -73.74014139121738,
                40.59451754659307
              ],
              [
                -73.74013580364564,
                40.59451157273891
              ],
              [
                -73.74010803139174,
                40.594511266175644
              ],
              [
                -73.74008908945062,
                40.5945306571453
              ],
              [
                -73.740072950435,
                40.594579590821986
              ],
              [
                -73.74006098155643,
                40.59460121264042
              ],
              [
                -73.74004742639177,
                40.594614635973585
              ],
              [
                -73.74001746414017,
                40.59462486123152
              ],
              [
                -73.73998678749325,
                40.59463285989959
              ],
              [
                -73.73995275016908,
                40.594636709613916
              ],
              [
                -73.73995801592365,
                40.59450076878709
              ],
              [
                -73.73968123124612,
                40.59450355888254
              ],
              [
                -73.7396953946558,
                40.59458939428621
              ],
              [
                -73.73967453660494,
                40.59458634372678
              ],
              [
                -73.73964106023107,
                40.59458245037932
              ],
              [
                -73.73961965222443,
                40.59458004187891
              ],
              [
                -73.73958864884943,
                40.594568966581505
              ],
              [
                -73.73957493121861,
                40.59456474305715
              ],
              [
                -73.73957519758912,
                40.594518252587655
              ],
              [
                -73.73958740902066,
                40.59451827037693
              ],
              [
                -73.73958763297539,
                40.5944265256111
              ],
              [
                -73.73955249471965,
                40.594426474456995
              ],
              [
                -73.7395522688195,
                40.594519437999054
              ],
              [
                -73.73956502573112,
                40.59451945535183
              ],
              [
                -73.7395646533139,
                40.59456157857743
              ],
              [
                -73.7395276572985,
                40.594550187793
              ],
              [
                -73.73949465784862,
                40.59454533972552
              ],
              [
                -73.73945887454893,
                40.59454245833712
              ],
              [
                -73.73942749489994,
                40.59453720062629
              ],
              [
                -73.73939659170452,
                40.5945307092916
              ],
              [
                -73.73936472405694,
                40.59452317171435
              ],
              [
                -73.73934856041359,
                40.59451206802289
              ],
              [
                -73.73933385925235,
                40.594486018780024
              ],
              [
                -73.73932662910883,
                40.59447398009358
              ],
              [
                -73.73931941105549,
                40.59446194143418
              ],
              [
                -73.73930406142902,
                40.594453945588604
              ],
              [
                -73.73929430628124,
                40.594453005560645
              ],
              [
                -73.73928109875231,
                40.59446533930758
              ],
              [
                -73.73927780495323,
                40.59448280202721
              ],
              [
                -73.73928512794382,
                40.594507420920145
              ],
              [
                -73.73928729631761,
                40.594533009676084
              ],
              [
                -73.73927692202334,
                40.59454553044771
              ],
              [
                -73.73925589391581,
                40.59455778446144
              ],
              [
                -73.7391954400391,
                40.59458593253053
              ],
              [
                -73.73916764971513,
                40.594593001708525
              ],
              [
                -73.73910729317714,
                40.5945965563853
              ],
              [
                -73.73907212417535,
                40.59459647653462
              ],
              [
                -73.73900196778274,
                40.59458623224443
              ],
              [
                -73.73889349191411,
                40.59455720483895
              ],
              [
                -73.73883432550788,
                40.59454304930053
              ],
              [
                -73.73878644978721,
                40.594529565560244
              ],
              [
                -73.7387913253894,
                40.59444907681658
              ],
              [
                -73.73879338493006,
                40.594414457408654
              ],
              [
                -73.73881112762061,
                40.5944164355568
              ],
              [
                -73.73881662516276,
                40.59435976495602
              ],
              [
                -73.73877753514914,
                40.59435500821519
              ],
              [
                -73.7387700502699,
                40.59441215745296
              ],
              [
                -73.73878418806137,
                40.59441333800906
              ],
              [
                -73.73877815501379,
                40.59444891235183
              ],
              [
                -73.73877153079722,
                40.59452520803296
              ],
              [
                -73.73874923699084,
                40.59451800985269
              ],
              [
                -73.73867835139818,
                40.59448881573182
              ],
              [
                -73.73865814740493,
                40.594480503520955
              ],
              [
                -73.73856957886235,
                40.5944541318682
              ],
              [
                -73.73853764634308,
                40.5944481524451
              ],
              [
                -73.73852462983481,
                40.594435578016046
              ],
              [
                -73.73852246399807,
                40.59441244741772
              ],
              [
                -73.7385086654192,
                40.594391262847076
              ],
              [
                -73.73848811323195,
                40.59436637171761
              ],
              [
                -73.73847314107705,
                40.594373414277754
              ],
              [
                -73.73846105156619,
                40.594389857304975
              ],
              [
                -73.73845944615472,
                40.59444079733847
              ],
              [
                -73.73844602742476,
                40.594464593914914
              ],
              [
                -73.73842547703404,
                40.594481153379654
              ],
              [
                -73.73839885733365,
                40.594494187093204
              ],
              [
                -73.73836396686411,
                40.59450458950762
              ],
              [
                -73.73829117183323,
                40.59452564007553
              ],
              [
                -73.73825859616619,
                40.594533417676665
              ],
              [
                -73.7381686471151,
                40.5945426867503
              ],
              [
                -73.7380612247574,
                40.594546116708045
              ],
              [
                -73.73786658749287,
                40.594539441602535
              ],
              [
                -73.73784632428159,
                40.594537277966346
              ],
              [
                -73.737804303554,
                40.59452804249865
              ],
              [
                -73.73776863283119,
                40.59451761311257
              ],
              [
                -73.7377385952103,
                40.59450733277356
              ],
              [
                -73.73771202922691,
                40.59449473670992
              ],
              [
                -73.73770589774526,
                40.594491832320074
              ],
              [
                -73.73768487329951,
                40.59447281876304
              ],
              [
                -73.73767387555077,
                40.59445767474746
              ],
              [
                -73.73766540626953,
                40.59443319637158
              ],
              [
                -73.7376539685101,
                40.594418788649
              ],
              [
                -73.73764798223559,
                40.59441483987048
              ],
              [
                -73.7376468285491,
                40.59441408134138
              ],
              [
                -73.73763679389783,
                40.59441539983797
              ],
              [
                -73.73770476300949,
                40.59466942244817
              ],
              [
                -73.73773567396867,
                40.59478499423731
              ],
              [
                -73.73803749195454,
                40.59591315116881
              ],
              [
                -73.73813316409253,
                40.59728564583395
              ],
              [
                -73.73812220887619,
                40.5973861020694
              ],
              [
                -73.73822344542049,
                40.59735112738425
              ],
              [
                -73.73826706178608,
                40.59735449138663
              ],
              [
                -73.73844962513597,
                40.59698266793203
              ],
              [
                -73.73848679652468,
                40.59698768159276
              ],
              [
                -73.73852427660151,
                40.59691381296728
              ],
              [
                -73.73860200843423,
                40.59688440787326
              ],
              [
                -73.73858431403262,
                40.59686094777612
              ],
              [
                -73.73862636110987,
                40.5968598115698
              ],
              [
                -73.73883031859042,
                40.596804808797344
              ],
              [
                -73.73884642112992,
                40.59682210248287
              ],
              [
                -73.73889308019422,
                40.59679519246272
              ],
              [
                -73.73894690694097,
                40.59676316642692
              ],
              [
                -73.73912307710913,
                40.59678451979306
              ],
              [
                -73.73910683002381,
                40.596804204365206
              ],
              [
                -73.73933278706397,
                40.59691318437726
              ],
              [
                -73.73943957124747,
                40.59689740230747
              ],
              [
                -73.73945583288493,
                40.59697828258678
              ],
              [
                -73.73944497449672,
                40.59697756908488
              ],
              [
                -73.73944749042397,
                40.597028632047305
              ],
              [
                -73.7393979636384,
                40.59702954301793
              ],
              [
                -73.73934283227531,
                40.597020130123084
              ],
              [
                -73.73929610421011,
                40.597004431947255
              ],
              [
                -73.739288082363,
                40.59700173812808
              ],
              [
                -73.73923553862109,
                40.59698408506149
              ],
              [
                -73.73922317497744,
                40.59697993158167
              ],
              [
                -73.73917528803719,
                40.59695996306914
              ],
              [
                -73.73916751427616,
                40.59695672131168
              ],
              [
                -73.73911830379909,
                40.59693620194849
              ],
              [
                -73.73910946934664,
                40.596932518180445
              ],
              [
                -73.7390548498901,
                40.59690974227924
              ],
              [
                -73.73904606722695,
                40.59690608057036
              ],
              [
                -73.73897266456386,
                40.59687376554156
              ],
              [
                -73.73894704511588,
                40.59686319031951
              ],
              [
                -73.73892850726293,
                40.59687548946645
              ],
              [
                -73.73901571928859,
                40.59691402762483
              ],
              [
                -73.73907319397102,
                40.596939095806064
              ],
              [
                -73.7390818481529,
                40.59694251078459
              ],
              [
                -73.73913856349672,
                40.59696488952607
              ],
              [
                -73.73914434758687,
                40.59696717151356
              ],
              [
                -73.73920841735517,
                40.596992183229275
              ],
              [
                -73.7392166950812,
                40.59699474980928
              ],
              [
                -73.73924172058135,
                40.59700250359185
              ],
              [
                -73.73936639462225,
                40.59704113854309
              ],
              [
                -73.73939810824884,
                40.597046325656244
              ],
              [
                -73.73940649751019,
                40.59704639091866
              ],
              [
                -73.73943095850045,
                40.59704658024128
              ],
              [
                -73.73944033499653,
                40.59704665209554
              ],
              [
                -73.73945528659308,
                40.597045568737904
              ],
              [
                -73.73949652209808,
                40.59704257816211
              ],
              [
                -73.73950885867268,
                40.59704004409155
              ],
              [
                -73.73951745995797,
                40.597036125964884
              ],
              [
                -73.73954671965845,
                40.597022793742596
              ],
              [
                -73.73957401693767,
                40.59701035737162
              ],
              [
                -73.73958504738884,
                40.59700533134091
              ],
              [
                -73.73963571050848,
                40.59698224810068
              ],
              [
                -73.73968736688425,
                40.5969541787169
              ],
              [
                -73.73969333890275,
                40.59695025998227
              ],
              [
                -73.73969866310973,
                40.596946765305844
              ],
              [
                -73.73979634028939,
                40.59688267562788
              ],
              [
                -73.73990994690173,
                40.59677274452032
              ],
              [
                -73.7398966257443,
                40.596753902602096
              ],
              [
                -73.73988420556657,
                40.59673633511134
              ],
              [
                -73.73986828861136,
                40.59671382125659
              ],
              [
                -73.73986424975341,
                40.59670810956277
              ],
              [
                -73.73983784246826,
                40.59667075762007
              ],
              [
                -73.73983351575824,
                40.59666463683548
              ],
              [
                -73.73981102756485,
                40.59663283012044
              ],
              [
                -73.73980566371779,
                40.596625242272275
              ],
              [
                -73.73977984293751,
                40.596588719576665
              ],
              [
                -73.73977602333036,
                40.59658331679867
              ],
              [
                -73.73975390043238,
                40.59655202638888
              ],
              [
                -73.73974724022848,
                40.596542604835086
              ],
              [
                -73.73969621382008,
                40.59655529542011
              ],
              [
                -73.73964853372279,
                40.59656715206083
              ],
              [
                -73.73965518724205,
                40.59658510556953
              ],
              [
                -73.73970950101993,
                40.59657340836249
              ],
              [
                -73.73973369321162,
                40.59656819869529
              ],
              [
                -73.73979158236959,
                40.596646788411235
              ],
              [
                -73.73979486697488,
                40.59665124795648
              ],
              [
                -73.73988306007304,
                40.59677097418953
              ],
              [
                -73.7398504168596,
                40.59680420424125
              ],
              [
                -73.73984650947756,
                40.596808181937845
              ],
              [
                -73.73983374627073,
                40.59682117497732
              ],
              [
                -73.73981105771489,
                40.59684427004701
              ],
              [
                -73.73976010849762,
                40.59687905384429
              ],
              [
                -73.73975111242625,
                40.59688519505485
              ],
              [
                -73.739705436777,
                40.59691637748516
              ],
              [
                -73.73969757669637,
                40.5969217422504
              ],
              [
                -73.739586315604,
                40.59697634684666
              ],
              [
                -73.73957870164308,
                40.59698008226294
              ],
              [
                -73.7394966087387,
                40.597020370759104
              ],
              [
                -73.73948781808573,
                40.59697593479487
              ],
              [
                -73.73948220989597,
                40.59697762988142
              ],
              [
                -73.73946370303052,
                40.596878420092004
              ],
              [
                -73.73960988055163,
                40.59676343754034
              ],
              [
                -73.73953153596668,
                40.596535232658105
              ],
              [
                -73.73957904997508,
                40.596376337848696
              ],
              [
                -73.73981229328994,
                40.59627456218199
              ],
              [
                -73.73991492767561,
                40.59607881397325
              ],
              [
                -73.73992792985005,
                40.596061587313336
              ],
              [
                -73.74018670507758,
                40.59604738195103
              ],
              [
                -73.7402223263083,
                40.5960351366335
              ],
              [
                -73.74029022946958,
                40.59603775498193
              ],
              [
                -73.74030143748698,
                40.59606613039738
              ],
              [
                -73.74041452651389,
                40.59609226945324
              ],
              [
                -73.74044371824414,
                40.59607014906631
              ],
              [
                -73.74045917623138,
                40.59606911296055
              ],
              [
                -73.74047930089631,
                40.59606776405916
              ],
              [
                -73.74049382946058,
                40.596073960630015
              ],
              [
                -73.74050176132286,
                40.59611342145448
              ],
              [
                -73.74051142026893,
                40.596124535589944
              ],
              [
                -73.74053082459658,
                40.59612457940445
              ],
              [
                -73.74054701362383,
                40.596119685717944
              ],
              [
                -73.74057620958588,
                40.596154151635425
              ],
              [
                -73.74059766007412,
                40.59619177920585
              ],
              [
                -73.74061079345005,
                40.596231565707086
              ],
              [
                -73.74061525971155,
                40.59627245088244
              ],
              [
                -73.74061093982257,
                40.596313345195306
              ],
              [
                -73.74060380558622,
                40.59635928551453
              ],
              [
                -73.74059043582385,
                40.59640441005918
              ],
              [
                -73.74057097581999,
                40.596448228434745
              ],
              [
                -73.74054563704607,
                40.59649026444073
              ],
              [
                -73.74051469486409,
                40.59653006124563
              ],
              [
                -73.74051052013718,
                40.59653284652754
              ],
              [
                -73.74050704266466,
                40.59653614166962
              ],
              [
                -73.74050436791904,
                40.596539846729335
              ],
              [
                -73.74050257702658,
                40.596543849331276
              ],
              [
                -73.74050172430566,
                40.59654802807516
              ],
              [
                -73.74050183561943,
                40.596552256218445
              ],
              [
                -73.74050290759243,
                40.59655640552022
              ],
              [
                -73.740504907711,
                40.59656035013086
              ],
              [
                -73.74050777531151,
                40.596563970409
              ],
              [
                -73.74051142341858,
                40.59656715655039
              ],
              [
                -73.74051574138402,
                40.59656981191846
              ],
              [
                -73.74052059824265,
                40.59657185597492
              ],
              [
                -73.7405258466843,
                40.5965732267228
              ],
              [
                -73.74057141605768,
                40.59657397688533
              ],
              [
                -73.74061674572243,
                40.59657034051727
              ],
              [
                -73.74066111186983,
                40.596562375683
              ],
              [
                -73.7407038060766,
                40.596550209562075
              ],
              [
                -73.74074642948112,
                40.5965433867782
              ],
              [
                -73.74078994633359,
                40.596542033185855
              ],
              [
                -73.7408331572998,
                40.59654618609025
              ],
              [
                -73.74087487147645,
                40.596555731036496
              ],
              [
                -73.74091393921042,
                40.59657040496381
              ],
              [
                -73.74092001917201,
                40.59656890537255
              ],
              [
                -73.74092569193654,
                40.59656666272962
              ],
              [
                -73.74093080246882,
                40.59656373832592
              ],
              [
                -73.7409352110988,
                40.59656021208485
              ],
              [
                -73.7409387973399,
                40.59655618037783
              ],
              [
                -73.7409414631812,
                40.59655175339061
              ],
              [
                -73.74094313576546,
                40.596547052111745
              ],
              [
                -73.74115347299904,
                40.596540239701305
              ],
              [
                -73.74151239169112,
                40.59652861251448
              ],
              [
                -73.74155506097242,
                40.59653636656588
              ],
              [
                -73.74159574046283,
                40.59654887614068
              ],
              [
                -73.74163352359669,
                40.59656586245637
              ],
              [
                -73.74163989241653,
                40.59657159326545
              ],
              [
                -73.7416473844081,
                40.59657647142978
              ],
              [
                -73.74165580566556,
                40.59658037069414
              ],
              [
                -73.74166493823267,
                40.59658319013882
              ],
              [
                -73.74167454574275,
                40.596584856791765
              ],
              [
                -73.74168437953736,
                40.59658532751719
              ],
              [
                -73.74169418510041,
                40.59658459013182
              ],
              [
                -73.7417037086474,
                40.596582663720575
              ],
              [
                -73.7417127036924,
                40.59657959814215
              ],
              [
                -73.74173493168094,
                40.59658439361004
              ],
              [
                -73.74175588115651,
                40.59659181464368
              ],
              [
                -73.74177502220874,
                40.59660167353045
              ],
              [
                -73.7417918706702,
                40.59661372089284
              ],
              [
                -73.74180600036239,
                40.596627651996705
              ],
              [
                -73.74181686745595,
                40.596642796441266
              ],
              [
                -73.74182451436532,
                40.59665904436298
              ],
              [
                -73.74182875521423,
                40.59667600082241
              ],
              [
                -73.74183083531219,
                40.59669555145679
              ],
              [
                -73.74182849655536,
                40.596715085099284
              ],
              [
                -73.74182180790892,
                40.59673402571285
              ],
              [
                -73.74181096661387,
                40.59675181474799
              ],
              [
                -73.74179629237203,
                40.59676792761426
              ],
              [
                -73.74177821791754,
                40.59678188915041
              ],
              [
                -73.74174642646953,
                40.59678362320475
              ],
              [
                -73.74171539706398,
                40.5967891750093
              ],
              [
                -73.74168591364314,
                40.596798404300436
              ],
              [
                -73.7416587210913,
                40.59681107790495
              ],
              [
                -73.7416345064164,
                40.59682687563053
              ],
              [
                -73.74161388139287,
                40.59684539835504
              ],
              [
                -73.7415973671053,
                40.59686617811052
              ],
              [
                -73.74158538078443,
                40.59688868990546
              ],
              [
                -73.74158582379607,
                40.59689082478084
              ],
              [
                -73.74158578565496,
                40.59689298600293
              ],
              [
                -73.7415852674651,
                40.59689511102202
              ],
              [
                -73.74158428422386,
                40.59689713833607
              ],
              [
                -73.74158286438781,
                40.59689900927076
              ],
              [
                -73.74158104904993,
                40.59690066967775
              ],
              [
                -73.74157889074901,
                40.59690207150174
              ],
              [
                -73.74157645195119,
                40.596903174171295
              ],
              [
                -73.74157380323898,
                40.59690394577314
              ],
              [
                -73.74157102127162,
                40.59690436397548
              ],
              [
                -73.74156818656417,
                40.59690441667493
              ],
              [
                -73.7415653811586,
                40.59690410234626
              ],
              [
                -73.74156268624876,
                40.59690343008671
              ],
              [
                -73.74153719879638,
                40.59690417598596
              ],
              [
                -73.74151218637357,
                40.59690798319158
              ],
              [
                -73.74148827777292,
                40.59691475599329
              ],
              [
                -73.74146607403786,
                40.59692432412831
              ],
              [
                -73.74144613335383,
                40.59693644706117
              ],
              [
                -73.74144361607023,
                40.59693737326711
              ],
              [
                -73.74144092686163,
                40.596937954437045
              ],
              [
                -73.74143814642423,
                40.59693817313153
              ],
              [
                -73.74143535819077,
                40.59693802278842
              ],
              [
                -73.74143264582877,
                40.596937507918994
              ],
              [
                -73.74143009072836,
                40.596936643973045
              ],
              [
                -73.74142776956123,
                40.59693545687521
              ],
              [
                -73.74142575197932,
                40.59693398224683
              ],
              [
                -73.74142409852432,
                40.596932264337745
              ],
              [
                -73.74142285881184,
                40.59693035469747
              ],
              [
                -73.74142207004246,
                40.59692831062889
              ],
              [
                -73.74142175588474,
                40.596926193469066
              ],
              [
                -73.74142192576541,
                40.59692406674799
              ],
              [
                -73.74139847117411,
                40.59690473687482
              ],
              [
                -73.74137842496869,
                40.59688329410187
              ],
              [
                -73.74138007831561,
                40.596875579472744
              ],
              [
                -73.74138013532291,
                40.59686776270181
              ],
              [
                -73.74137859460036,
                40.596860034537784
              ],
              [
                -73.74137549374574,
                40.59685258356742
              ],
              [
                -73.74137090842818,
                40.596845591613025
              ],
              [
                -73.74136495054117,
                40.59683922929575
              ],
              [
                -73.741357765473,
                40.596833651871876
              ],
              [
                -73.74134952855663,
                40.59682899544437
              ],
              [
                -73.74133946983298,
                40.59682907417224
              ],
              [
                -73.7413295319721,
                40.59682788654153
              ],
              [
                -73.7413199874715,
                40.596825465117405
              ],
              [
                -73.74131109804405,
                40.59682187629571
              ],
              [
                -73.7413031074393,
                40.59681721848293
              ],
              [
                -73.74129623476148,
                40.59681161939718
              ],
              [
                -73.74129325683718,
                40.5968093341191
              ],
              [
                -73.7412898301138,
                40.59680744934806
              ],
              [
                -73.74128604622405,
                40.5968060154844
              ],
              [
                -73.74128200635205,
                40.59680507087047
              ],
              [
                -73.74127781852663,
                40.59680464076604
              ],
              [
                -73.74127359473346,
                40.596804736672226
              ],
              [
                -73.74126944791945,
                40.59680535602442
              ],
              [
                -73.74126548897357,
                40.596806482260895
              ],
              [
                -73.74126182376057,
                40.59680808526525
              ],
              [
                -73.74125855029123,
                40.596810122171966
              ],
              [
                -73.7412557561001,
                40.5968125385127
              ],
              [
                -73.74125351590618,
                40.596815269672824
              ],
              [
                -73.7412518896136,
                40.59681824261908
              ],
              [
                -73.74125092071091,
                40.59682137785288
              ],
              [
                -73.7412296241696,
                40.596826035773596
              ],
              [
                -73.74120768649566,
                40.59682840021409
              ],
              [
                -73.74118553063452,
                40.59682842558907
              ],
              [
                -73.7411635837387,
                40.5968261114096
              ],
              [
                -73.74112351700808,
                40.59682231092948
              ],
              [
                -73.74108315342913,
                40.5968230665761
              ],
              [
                -73.74104337975957,
                40.596828361748585
              ],
              [
                -73.74100506979715,
                40.59683808011593
              ],
              [
                -73.74098582174452,
                40.59684984955059
              ],
              [
                -73.74096938067086,
                40.59686387549446
              ],
              [
                -73.74095619595559,
                40.596879774582014
              ],
              [
                -73.74094662797329,
                40.59689711224985
              ],
              [
                -73.7409471433695,
                40.596899122023025
              ],
              [
                -73.74094807445584,
                40.59690104291308
              ],
              [
                -73.74094939718606,
                40.596902825310806
              ],
              [
                -73.74095107739952,
                40.596904423184114
              ],
              [
                -73.74095307170278,
                40.59690579526628
              ],
              [
                -73.74095532859111,
                40.59690690612187
              ],
              [
                -73.74095778977808,
                40.596907727061904
              ],
              [
                -73.74096039170094,
                40.596908236884616
              ],
              [
                -73.74096306716235,
                40.596908422423475
              ],
              [
                -73.74096574706557,
                40.59690827888668
              ],
              [
                -73.74096836219971,
                40.59690780998128
              ],
              [
                -73.74097084502581,
                40.59690702781715
              ],
              [
                -73.74097400151531,
                40.59690833141136
              ],
              [
                -73.74097739726272,
                40.59690921937725
              ],
              [
                -73.74098093885091,
                40.59690966728692
              ],
              [
                -73.74098452885066,
                40.596909662818334
              ],
              [
                -73.74098806850112,
                40.596909206094416
              ],
              [
                -73.740991460426,
                40.59690830967971
              ],
              [
                -73.74099461131338,
                40.5969069982345
              ],
              [
                -73.74099743448241,
                40.59690530783693
              ],
              [
                -73.74099985226756,
                40.596903284989516
              ],
              [
                -73.74100179815534,
                40.59690098534123
              ],
              [
                -73.74100321861472,
                40.59689847215534
              ],
              [
                -73.74100624627894,
                40.59690125652778
              ],
              [
                -73.74100863522756,
                40.596904381095676
              ],
              [
                -73.7410103210251,
                40.59690776158178
              ],
              [
                -73.74101125820096,
                40.59691130680611
              ],
              [
                -73.74101142147722,
                40.59691492114546
              ],
              [
                -73.74101080644976,
                40.59691850711215
              ],
              [
                -73.74100942970746,
                40.5969219679838
              ],
              [
                -73.74100732838409,
                40.59692521041236
              ],
              [
                -73.74100455915755,
                40.59692814694158
              ],
              [
                -73.74100119672039,
                40.59693069836588
              ],
              [
                -73.74099733176617,
                40.59693279586703
              ],
              [
                -73.74099306854177,
                40.596934382870494
              ],
              [
                -73.74098852203713,
                40.59693541657072
              ],
              [
                -73.74095784540083,
                40.59694616916218
              ],
              [
                -73.74092524512245,
                40.59695286942876
              ],
              [
                -73.7408916351951,
                40.59695532951933
              ],
              [
                -73.7408579579203,
                40.596953480461565
              ],
              [
                -73.74082515748691,
                40.59694737409662
              ],
              [
                -73.74081885749264,
                40.596949344323974
              ],
              [
                -73.74081299715759,
                40.59695198785221
              ],
              [
                -73.7408076995149,
                40.596955249182756
              ],
              [
                -73.74080307578484,
                40.59695905984605
              ],
              [
                -73.74079922303956,
                40.59696333984049
              ],
              [
                -73.74076411879872,
                40.59698024325797
              ],
              [
                -73.74072577925703,
                40.596992394996036
              ],
              [
                -73.74068530132834,
                40.59699944738718
              ],
              [
                -73.7406438431081,
                40.59700119865828
              ],
              [
                -73.7406345492715,
                40.59699759784441
              ],
              [
                -73.74062620852796,
                40.596992830086
              ],
              [
                -73.74061907043595,
                40.596987038036445
              ],
              [
                -73.74061334857008,
                40.59698039499649
              ],
              [
                -73.74060921413064,
                40.596973099728814
              ],
              [
                -73.74060679082126,
                40.59696537051088
              ],
              [
                -73.74061517411832,
                40.596948831335396
              ],
              [
                -73.74061996040307,
                40.59693148000702
              ],
              [
                -73.74062102518255,
                40.59691376785039
              ],
              [
                -73.74061834076392,
                40.59689615557484
              ],
              [
                -73.74061197697394,
                40.59687910129197
              ],
              [
                -73.74060766103945,
                40.59687426891931
              ],
              [
                -73.74060240106479,
                40.596870014599794
              ],
              [
                -73.74059632818015,
                40.59686644439246
              ],
              [
                -73.74058959378046,
                40.59686364730141
              ],
              [
                -73.74058236575246,
                40.59686169305737
              ],
              [
                -73.74057482428887,
                40.59686063037904
              ],
              [
                -73.74056715739592,
                40.59686048575864
              ],
              [
                -73.74055955620688,
                40.596861262801525
              ],
              [
                -73.7405522102172,
                40.59686294213622
              ],
              [
                -73.74051352099603,
                40.5968594677431
              ],
              [
                -73.74047615460643,
                40.59685106961344
              ],
              [
                -73.74044118144293,
                40.5968379883198
              ],
              [
                -73.74040960334438,
                40.596820598588174
              ],
              [
                -73.74038232489124,
                40.59679939856366
              ],
              [
                -73.7403601274953,
                40.5967749955401
              ],
              [
                -73.74028204054135,
                40.59648023228418
              ],
              [
                -73.74028393005828,
                40.59640997986239
              ],
              [
                -73.74033279524063,
                40.59631887911992
              ],
              [
                -73.74034900829132,
                40.596307823429576
              ],
              [
                -73.7403475207975,
                40.59627454091755
              ],
              [
                -73.74039294483362,
                40.59623643247847
              ],
              [
                -73.74039153834975,
                40.59618219567316
              ],
              [
                -73.74030905351357,
                40.59618693954036
              ],
              [
                -73.74025075069052,
                40.59621022761905
              ],
              [
                -73.74015376100509,
                40.596202612740775
              ],
              [
                -73.74011818218938,
                40.59620376463578
              ],
              [
                -73.74009714262667,
                40.59620864729175
              ],
              [
                -73.74006954777121,
                40.596235701441074
              ],
              [
                -73.74003703042294,
                40.596281233554514
              ],
              [
                -73.73990407814098,
                40.596373376738526
              ],
              [
                -73.739798757236,
                40.596428603789256
              ],
              [
                -73.73979778812388,
                40.59643390530248
              ],
              [
                -73.73979799359657,
                40.59643925574317
              ],
              [
                -73.73979936788847,
                40.59644450496629
              ],
              [
                -73.73980187243376,
                40.596449505667046
              ],
              [
                -73.73980543695004,
                40.59645411751473
              ],
              [
                -73.73980996140897,
                40.59645821109083
              ],
              [
                -73.7398628008646,
                40.59647131215955
              ],
              [
                -73.73991263544504,
                40.596490044582794
              ],
              [
                -73.73995843844962,
                40.59651402243284
              ],
              [
                -73.73999926623331,
                40.59654275171456
              ],
              [
                -73.74003427764893,
                40.596575640542596
              ],
              [
                -73.74006275137509,
                40.59661201133497
              ],
              [
                -73.74008410077897,
                40.59665111477248
              ],
              [
                -73.74010495518915,
                40.59668840176944
              ],
              [
                -73.74011773557845,
                40.59672774782453
              ],
              [
                -73.74012211431592,
                40.596768144328436
              ],
              [
                -73.74011797914022,
                40.59680855574271
              ],
              [
                -73.74010543603895,
                40.59684794614482
              ],
              [
                -73.74008480653316,
                40.59688530578333
              ],
              [
                -73.74003994106113,
                40.59694579400635
              ],
              [
                -73.7399873182102,
                40.59700253391937
              ],
              [
                -73.73992747215387,
                40.597054949536655
              ],
              [
                -73.7398610103941,
                40.597102508768735
              ],
              [
                -73.73978860759591,
                40.59714472882414
              ],
              [
                -73.73971099873867,
                40.597181181110095
              ],
              [
                -73.73962897165448,
                40.59721149558431
              ],
              [
                -73.73954335902998,
                40.59723536451154
              ],
              [
                -73.73946193303371,
                40.5972406746977
              ],
              [
                -73.73938023658849,
                40.59723907299832
              ],
              [
                -73.73929927638089,
                40.59723057914991
              ],
              [
                -73.73922005002483,
                40.597215297816014
              ],
              [
                -73.73914353376679,
                40.59719341729722
              ],
              [
                -73.73907067045685,
                40.59716520721109
              ],
              [
                -73.73900235793,
                40.59713101516901
              ],
              [
                -73.73893943794074,
                40.597091262493144
              ],
              [
                -73.738882685792,
                40.597046439024254
              ],
              [
                -73.73883280078138,
                40.59699709708528
              ],
              [
                -73.73883079556741,
                40.596992354227936
              ],
              [
                -73.73882774869459,
                40.59698794548945
              ],
              [
                -73.73882375122074,
                40.596984002627835
              ],
              [
                -73.73881892261252,
                40.59698064347764
              ],
              [
                -73.7388134071765,
                40.59697796842926
              ],
              [
                -73.7388073697443,
                40.59697605742797
              ],
              [
                -73.73880099074813,
                40.596974967585076
              ],
              [
                -73.73879446082829,
                40.59697473147135
              ],
              [
                -73.73878797513527,
                40.59697535614314
              ],
              [
                -73.7387817274975,
                40.59697682293161
              ],
              [
                -73.73877590462943,
                40.59697908800118
              ],
              [
                -73.73877068055083,
                40.59698208365874
              ],
              [
                -73.73876621138676,
                40.59698572037735
              ],
              [
                -73.73876263070068,
                40.59698988947153
              ],
              [
                -73.738760045504,
                40.596994466345315
              ],
              [
                -73.73863304587005,
                40.597217274664885
              ],
              [
                -73.73862781419399,
                40.59724144290194
              ],
              [
                -73.73862641254613,
                40.59726591462483
              ],
              [
                -73.73858466737627,
                40.59725319461562
              ],
              [
                -73.73858805052276,
                40.59724807773864
              ],
              [
                -73.73857461617719,
                40.5972446315777
              ],
              [
                -73.73873823189528,
                40.59695544041398
              ],
              [
                -73.73871968076894,
                40.596948817326414
              ],
              [
                -73.7387154873913,
                40.59695627309346
              ],
              [
                -73.73869078548499,
                40.597000187167936
              ],
              [
                -73.73868423105195,
                40.59701183770796
              ],
              [
                -73.73865954991007,
                40.59705571446355
              ],
              [
                -73.73865537843393,
                40.597063130238496
              ],
              [
                -73.73864407374766,
                40.597083226716485
              ],
              [
                -73.73862486165704,
                40.597117379063036
              ],
              [
                -73.73861389690552,
                40.59713687069042
              ],
              [
                -73.73858809179461,
                40.5971827453487
              ],
              [
                -73.73856611221646,
                40.597221819282645
              ],
              [
                -73.73856037839491,
                40.59723201366942
              ],
              [
                -73.73854295052871,
                40.59726299369351
              ],
              [
                -73.73852423807804,
                40.5972962589159
              ],
              [
                -73.73850854977917,
                40.59732414727481
              ],
              [
                -73.73848900400793,
                40.597358894240195
              ],
              [
                -73.73850355568756,
                40.59736319785865
              ],
              [
                -73.73855884632226,
                40.59726321481077
              ],
              [
                -73.7385738210246,
                40.59726837127247
              ],
              [
                -73.73857716826397,
                40.59726344429299
              ],
              [
                -73.73862744479484,
                40.5972808113883
              ],
              [
                -73.73863472627414,
                40.597313192376205
              ],
              [
                -73.73864871908607,
                40.597344265996654
              ],
              [
                -73.73852725571271,
                40.59739205956135
              ],
              [
                -73.73852716400212,
                40.59741547919856
              ],
              [
                -73.73846229498801,
                40.5974634019515
              ],
              [
                -73.7384473758534,
                40.59748119494373
              ],
              [
                -73.7384365132318,
                40.597500620612415
              ],
              [
                -73.73843000496976,
                40.59752114632383
              ],
              [
                -73.73842802952184,
                40.597542209281514
              ],
              [
                -73.73842572808496,
                40.59756058130903
              ],
              [
                -73.73841965958415,
                40.59757844780454
              ],
              [
                -73.73840997547153,
                40.597595362865185
              ],
              [
                -73.73839691743511,
                40.59761090433322
              ],
              [
                -73.73838081136934,
                40.59762468433207
              ],
              [
                -73.73836905388242,
                40.59763135578154
              ],
              [
                -73.73835606987075,
                40.5976365400366
              ],
              [
                -73.73834218030606,
                40.597640108939245
              ],
              [
                -73.73832772854637,
                40.59764197426433
              ],
              [
                -73.73831307184743,
                40.59764208989999
              ],
              [
                -73.73829857253124,
                40.597640452987456
              ],
              [
                -73.73828458902938,
                40.597637103992284
              ],
              [
                -73.73827146702239,
                40.59763212570347
              ],
              [
                -73.73825953089265,
                40.59762564118727
              ],
              [
                -73.73808925304176,
                40.59768829704554
              ],
              [
                -73.73807514912781,
                40.597817626095754
              ],
              [
                -73.73828313185064,
                40.598165568155096
              ],
              [
                -73.73830871892699,
                40.59820837383979
              ],
              [
                -73.73830554702238,
                40.598242937964805
              ],
              [
                -73.73799702694568,
                40.601601233828404
              ],
              [
                -73.73815143112287,
                40.602710440083385
              ],
              [
                -73.73922512581528,
                40.60414236691341
              ],
              [
                -73.74029138988145,
                40.60459823188923
              ],
              [
                -73.74121366909178,
                40.60551892016552
              ],
              [
                -73.74314655207264,
                40.60725986604785
              ],
              [
                -73.74332648806525,
                40.6075380629277
              ],
              [
                -73.74307247919678,
                40.60788609719336
              ],
              [
                -73.74338029298465,
                40.60817868098387
              ],
              [
                -73.74386981824344,
                40.60889248424052
              ],
              [
                -73.74456658083739,
                40.61011745296873
              ],
              [
                -73.74536751706268,
                40.611203552050156
              ],
              [
                -73.7456211250613,
                40.61174249247843
              ],
              [
                -73.7457615018933,
                40.61199165437434
              ],
              [
                -73.74685539012165,
                40.61157410052971
              ],
              [
                -73.74694207111291,
                40.611774999155706
              ],
              [
                -73.74721567892574,
                40.612178838825336
              ],
              [
                -73.74761418914572,
                40.61199801094233
              ],
              [
                -73.74799307308612,
                40.61231015798394
              ],
              [
                -73.74821585891324,
                40.61201107779471
              ],
              [
                -73.74912000690117,
                40.611794326771815
              ],
              [
                -73.75008771088383,
                40.61164055391356
              ],
              [
                -73.7534583619849,
                40.610519862703065
              ],
              [
                -73.75473218890687,
                40.61040730222277
              ],
              [
                -73.75473447491667,
                40.6104011030318
              ],
              [
                -73.75474651642602,
                40.61037253058189
              ],
              [
                -73.75477355338556,
                40.61032314456517
              ],
              [
                -73.75480743435165,
                40.61027627422274
              ],
              [
                -73.75484776254699,
                40.61023246843121
              ],
              [
                -73.7548940656952,
                40.61019224017873
              ],
              [
                -73.75494580155386,
                40.610156060557486
              ],
              [
                -73.75496110890587,
                40.610145923138475
              ],
              [
                -73.75497738866585,
                40.61013810193557
              ],
              [
                -73.75499501085996,
                40.61013220802829
              ],
              [
                -73.75501358543141,
                40.610128371874794
              ],
              [
                -73.75503270124402,
                40.61012667838594
              ],
              [
                -73.75505193518073,
                40.61012716504605
              ],
              [
                -73.75507086151059,
                40.61012982108318
              ],
              [
                -73.75509564991016,
                40.61014399205966
              ],
              [
                -73.75512326047287,
                40.610154712525734
              ],
              [
                -73.75515287474015,
                40.61016166469454
              ],
              [
                -73.75518361485531,
                40.61016464248254
              ],
              [
                -73.75521456958829,
                40.61016355761931
              ],
              [
                -73.755244821347,
                40.61015844226333
              ],
              [
                -73.75527347337746,
                40.6101494480493
              ],
              [
                -73.75529967634799,
                40.61013684159297
              ],
              [
                -73.75534825676537,
                40.61012313282276
              ],
              [
                -73.75539915499955,
                40.6101157835338
              ],
              [
                -73.75545094849186,
                40.61011499913182
              ],
              [
                -73.75550218966303,
                40.610120801540084
              ],
              [
                -73.75556369084146,
                40.61011842794625
              ],
              [
                -73.75571167394627,
                40.61030222568419
              ],
              [
                -73.75575727259817,
                40.610360677173304
              ],
              [
                -73.75571634332071,
                40.610353511900286
              ],
              [
                -73.75570173151115,
                40.61035213038864
              ],
              [
                -73.75569974469146,
                40.61035262071335
              ],
              [
                -73.75569746400103,
                40.610355804289426
              ],
              [
                -73.75569925964342,
                40.610365686657325
              ],
              [
                -73.75587412912142,
                40.610385185054824
              ],
              [
                -73.75587561216278,
                40.61038028930362
              ],
              [
                -73.75587637625526,
                40.610374824937836
              ],
              [
                -73.75587251467283,
                40.61037425785732
              ],
              [
                -73.75580892838548,
                40.610365288950305
              ],
              [
                -73.75562259040024,
                40.610128181450925
              ],
              [
                -73.75561374660208,
                40.61011984094887
              ],
              [
                -73.75560182951712,
                40.61010859598402
              ],
              [
                -73.75559789661662,
                40.61010487717541
              ],
              [
                -73.75559496353753,
                40.610099161194164
              ],
              [
                -73.75559880868329,
                40.61008163605521
              ],
              [
                -73.7556083701559,
                40.61007791963748
              ],
              [
                -73.75563040563364,
                40.610078272229565
              ],
              [
                -73.75564946697222,
                40.61008766928268
              ],
              [
                -73.75566121883598,
                40.6101116845066
              ],
              [
                -73.75566930431309,
                40.61013676328768
              ],
              [
                -73.75567967220252,
                40.61015794783541
              ],
              [
                -73.75569710325406,
                40.61017980494853
              ],
              [
                -73.75571074035422,
                40.61020220299003
              ],
              [
                -73.755727601822,
                40.61022815582461
              ],
              [
                -73.75574522332289,
                40.610246635602856
              ],
              [
                -73.75576265399067,
                40.61026566564261
              ],
              [
                -73.75577995993108,
                40.61028309952759
              ],
              [
                -73.75580362404574,
                40.610300251060885
              ],
              [
                -73.75582482776083,
                40.61031160686897
              ],
              [
                -73.75585531563948,
                40.61031766081564
              ],
              [
                -73.75588061031844,
                40.61031573233037
              ],
              [
                -73.75591508294892,
                40.61030472027326
              ],
              [
                -73.75594948952418,
                40.61029241155395
              ],
              [
                -73.75595506330369,
                40.610283723407896
              ],
              [
                -73.7559674738124,
                40.61025676995049
              ],
              [
                -73.75597162609579,
                40.610236382705594
              ],
              [
                -73.75597348586042,
                40.61021887093004
              ],
              [
                -73.75598031214558,
                40.61021320315286
              ],
              [
                -73.75598972222198,
                40.610212295351
              ],
              [
                -73.75600650505237,
                40.61022075972843
              ],
              [
                -73.7560249513726,
                40.61023979188434
              ],
              [
                -73.756039521865,
                40.610252474647545
              ],
              [
                -73.75606303287732,
                40.61025685585744
              ],
              [
                -73.756082024449,
                40.61025288935555
              ],
              [
                -73.7561160582204,
                40.610242424798784
              ],
              [
                -73.75614528566263,
                40.61023763347741
              ],
              [
                -73.7561835188916,
                40.61023230287607
              ],
              [
                -73.7561919008085,
                40.61023128482025
              ],
              [
                -73.75621022387813,
                40.61022906354289
              ],
              [
                -73.75624220788647,
                40.61022644755194
              ],
              [
                -73.75626657727713,
                40.610222049978546
              ],
              [
                -73.75629508193786,
                40.61021791464015
              ],
              [
                -73.75632916761667,
                40.61021254721898
              ],
              [
                -73.75635212357508,
                40.610210182618445
              ],
              [
                -73.75637780586109,
                40.61019900754587
              ],
              [
                -73.75640467161288,
                40.61019050070698
              ],
              [
                -73.75643362895231,
                40.61019833310153
              ],
              [
                -73.7564735551872,
                40.61019512289571
              ],
              [
                -73.75649716738332,
                40.610187977691176
              ],
              [
                -73.75652563163669,
                40.610175384980046
              ],
              [
                -73.75655062478583,
                40.6101552125715
              ],
              [
                -73.75656956469595,
                40.61016551823009
              ],
              [
                -73.75658046085303,
                40.61018140904117
              ],
              [
                -73.75659942149335,
                40.610205024296256
              ],
              [
                -73.75662364865083,
                40.61022027689952
              ],
              [
                -73.75665225558224,
                40.61022679489721
              ],
              [
                -73.75668271214545,
                40.61023174018212
              ],
              [
                -73.75672036013535,
                40.61023799618674
              ],
              [
                -73.75674660315728,
                40.61024153768482
              ],
              [
                -73.75677843192791,
                40.61024272983445
              ],
              [
                -73.75681050712097,
                40.61024754348167
              ],
              [
                -73.75683630817768,
                40.61025232625628
              ],
              [
                -73.75686929194647,
                40.61025447607805
              ],
              [
                -73.75689086851851,
                40.61025427846316
              ],
              [
                -73.75693149587428,
                40.61025376111172
              ],
              [
                -73.75695101212871,
                40.61025462143375
              ],
              [
                -73.756978170497,
                40.610253580366674
              ],
              [
                -73.75701247800151,
                40.61024904214853
              ],
              [
                -73.75704800614409,
                40.61024681221036
              ],
              [
                -73.75708351664257,
                40.61024598695957
              ],
              [
                -73.75711602497977,
                40.610248909692515
              ],
              [
                -73.75714700339951,
                40.610259791363504
              ],
              [
                -73.75718049765929,
                40.61026748941501
              ],
              [
                -73.75721192022327,
                40.61026335951094
              ],
              [
                -73.75724254710155,
                40.6102605964672
              ],
              [
                -73.7572741228047,
                40.61026307641523
              ],
              [
                -73.7572956814805,
                40.61028086215246
              ],
              [
                -73.75729703527198,
                40.6102819815958
              ],
              [
                -73.75729976071221,
                40.610283238964485
              ],
              [
                -73.75731666566746,
                40.61029105506837
              ],
              [
                -73.7573383590768,
                40.61028804814461
              ],
              [
                -73.75735905593557,
                40.61027006397637
              ],
              [
                -73.75736975048055,
                40.610270112854366
              ],
              [
                -73.75737380421911,
                40.61027629889805
              ],
              [
                -73.75738300800195,
                40.61027983058393
              ],
              [
                -73.75740623638153,
                40.61028059161272
              ],
              [
                -73.75743848238733,
                40.610277615653324
              ],
              [
                -73.75747534560298,
                40.61027203747325
              ],
              [
                -73.75749483565947,
                40.61027043060661
              ],
              [
                -73.75752779240311,
                40.61027028502671
              ],
              [
                -73.75755753440913,
                40.61027347185676
              ],
              [
                -73.7575877699896,
                40.61027748865654
              ],
              [
                -73.75762074385337,
                40.61028195266314
              ],
              [
                -73.75765998908315,
                40.610284367928585
              ],
              [
                -73.75768786959213,
                40.610286048394556
              ],
              [
                -73.75772433905365,
                40.61028798954111
              ],
              [
                -73.75775408741215,
                40.610289672755385
              ],
              [
                -73.7577894051914,
                40.610292952528305
              ],
              [
                -73.75781820510514,
                40.61029551761363
              ],
              [
                -73.7578490881041,
                40.61030013848025
              ],
              [
                -73.75788559499932,
                40.61030485360959
              ],
              [
                -73.75791022597653,
                40.610306436543176
              ],
              [
                -73.75794434716126,
                40.61030459086045
              ],
              [
                -73.75797402450189,
                40.61030240091894
              ],
              [
                -73.75800190081793,
                40.61030227080737
              ],
              [
                -73.75802880460462,
                40.610302571203235
              ],
              [
                -73.75805437268517,
                40.61030330904906
              ],
              [
                -73.75808825488171,
                40.61030541534656
              ],
              [
                -73.75812105252575,
                40.61030962737675
              ],
              [
                -73.75815889909484,
                40.61031336250973
              ],
              [
                -73.75819491433819,
                40.61031362826016
              ],
              [
                -73.7582269006985,
                40.61031053409527
              ],
              [
                -73.7582577507584,
                40.610304592208756
              ],
              [
                -73.75829125477553,
                40.6102967653235
              ],
              [
                -73.75829550033363,
                40.61029577460223
              ],
              [
                -73.75829926069888,
                40.61029509798367
              ],
              [
                -73.75832147772391,
                40.61029110974947
              ],
              [
                -73.75834929653381,
                40.61028694565507
              ],
              [
                -73.75838338936416,
                40.6102827848584
              ],
              [
                -73.75841702630292,
                40.610280802486194
              ],
              [
                -73.75844677519656,
                40.610282098528906
              ],
              [
                -73.75848022846047,
                40.61028488833537
              ],
              [
                -73.75850903056701,
                40.61028673186962
              ],
              [
                -73.75854665481931,
                40.61028963864403
              ],
              [
                -73.75858266022281,
                40.610292622085076
              ],
              [
                -73.75861193268271,
                40.61029479158903
              ],
              [
                -73.75864883787516,
                40.61029739978981
              ],
              [
                -73.75868333119982,
                40.61029999303095
              ],
              [
                -73.75871310860461,
                40.6103033789963
              ],
              [
                -73.75874562442631,
                40.610307481529865
              ],
              [
                -73.75878071065507,
                40.61031304863154
              ],
              [
                -73.75880039730679,
                40.61031920427265
              ],
              [
                -73.75883136120025,
                40.61033096649661
              ],
              [
                -73.75885114200875,
                40.61033734849159
              ],
              [
                -73.75888924674067,
                40.61033827495677
              ],
              [
                -73.75892030237395,
                40.61033419709067
              ],
              [
                -73.75895697014224,
                40.61033389571052
              ],
              [
                -73.75898721665442,
                40.610338020388916
              ],
              [
                -73.75900812829903,
                40.61034226101927
              ],
              [
                -73.75904511473067,
                40.610351667614296
              ],
              [
                -73.75907022522372,
                40.610357870765526
              ],
              [
                -73.7591044220478,
                40.61036436368269
              ],
              [
                -73.75911349111223,
                40.61036614761372
              ],
              [
                -73.75919672932062,
                40.61037038573579
              ],
              [
                -73.7592801215764,
                40.61036863836023
              ],
              [
                -73.75936292845842,
                40.61036092098092
              ],
              [
                -73.75944441573677,
                40.610347302026
              ],
              [
                -73.75946158293257,
                40.610340888624435
              ],
              [
                -73.75947705298303,
                40.61033232694856
              ],
              [
                -73.759490367694,
                40.61032187057974
              ],
              [
                -73.7595011327095,
                40.61030982921636
              ],
              [
                -73.75950902919092,
                40.61029655950143
              ],
              [
                -73.75955651968803,
                40.61021573050112
              ],
              [
                -73.75956643026323,
                40.61019956387474
              ],
              [
                -73.75957983370535,
                40.610184932968565
              ],
              [
                -73.75959633244965,
                40.61017227175257
              ],
              [
                -73.75961543712306,
                40.61016195577321
              ],
              [
                -73.75963658105789,
                40.61015429101429
              ],
              [
                -73.75965913710012,
                40.61014950482124
              ],
              [
                -73.75968243621175,
                40.610147739158045
              ],
              [
                -73.75970578731508,
                40.6101490463962
              ],
              [
                -73.75972099038579,
                40.610151598082886
              ],
              [
                -73.75973571099013,
                40.61015545765216
              ],
              [
                -73.7594911983337,
                40.61081579591091
              ],
              [
                -73.75998446974822,
                40.61087937784668
              ],
              [
                -73.76032133549324,
                40.61047700509601
              ],
              [
                -73.76056913844502,
                40.61018295125938
              ],
              [
                -73.760668143153,
                40.610067393664664
              ],
              [
                -73.76084686113951,
                40.60985879158433
              ],
              [
                -73.76110660758368,
                40.60955759317357
              ],
              [
                -73.7612613099961,
                40.60938092460838
              ],
              [
                -73.76140457135281,
                40.60924480256103
              ],
              [
                -73.76142052284622,
                40.60921530707219
              ],
              [
                -73.761468385922,
                40.609133557980094
              ],
              [
                -73.76154422819782,
                40.60903918643415
              ],
              [
                -73.76155051905889,
                40.609031365800796
              ],
              [
                -73.76178242960593,
                40.60915167005447
              ],
              [
                -73.76117344842932,
                40.609839344137896
              ],
              [
                -73.76106712691544,
                40.6099710131896
              ],
              [
                -73.76103107637613,
                40.61001265160484
              ],
              [
                -73.76087049521189,
                40.61019812235988
              ],
              [
                -73.76076730398712,
                40.610184642971014
              ],
              [
                -73.76023635756965,
                40.610820864950284
              ],
              [
                -73.76101512745583,
                40.61135971258802
              ],
              [
                -73.76172413788875,
                40.6118472427577
              ],
              [
                -73.76229944090417,
                40.61135920604587
              ],
              [
                -73.7623044835184,
                40.611360143582466
              ],
              [
                -73.76239615019813,
                40.611374840497376
              ],
              [
                -73.76246108507048,
                40.61138712328248
              ],
              [
                -73.76251873787662,
                40.61140065131079
              ],
              [
                -73.76256997841372,
                40.611415814053046
              ],
              [
                -73.76261974879228,
                40.6114329450566
              ],
              [
                -73.76266773125484,
                40.61145077613865
              ],
              [
                -73.76271397442427,
                40.61146929584219
              ],
              [
                -73.76275848988121,
                40.61148852396162
              ],
              [
                -73.76289698451858,
                40.6115534580457
              ],
              [
                -73.76294809170496,
                40.61157639203977
              ],
              [
                -73.763026155521,
                40.61160862050955
              ],
              [
                -73.76311389262129,
                40.61164213923862
              ],
              [
                -73.76315813838788,
                40.611657826914744
              ],
              [
                -73.7631892622385,
                40.61166777076176
              ],
              [
                -73.76319855273074,
                40.61167073389886
              ],
              [
                -73.76322255859911,
                40.61167742690416
              ],
              [
                -73.76323678796824,
                40.61168139449969
              ],
              [
                -73.7632734247846,
                40.61168996136212
              ],
              [
                -73.76332337442068,
                40.611699546897825
              ],
              [
                -73.7633741106811,
                40.61170750363373
              ],
              [
                -73.76342545508612,
                40.61171382416491
              ],
              [
                -73.76347721809432,
                40.6117184590145
              ],
              [
                -73.76348472026018,
                40.611718700113975
              ],
              [
                -73.7634931119177,
                40.6117183660714
              ],
              [
                -73.76352858551707,
                40.611714935510555
              ],
              [
                -73.7635366099537,
                40.61171481699001
              ],
              [
                -73.76354389811969,
                40.61171527392599
              ],
              [
                -73.76355883664321,
                40.611717888631574
              ],
              [
                -73.76357485503232,
                40.611722721134804
              ],
              [
                -73.76362356072626,
                40.61174347920746
              ],
              [
                -73.76364027088391,
                40.6117496290687
              ],
              [
                -73.76365590117706,
                40.61175362311462
              ],
              [
                -73.76366337770511,
                40.61175477366764
              ],
              [
                -73.7636707130079,
                40.611755392023454
              ],
              [
                -73.76372229932802,
                40.611756857410356
              ],
              [
                -73.76384171496271,
                40.61175768850187
              ],
              [
                -73.76399822638167,
                40.61176085527848
              ],
              [
                -73.76409546481763,
                40.61176413441494
              ],
              [
                -73.76413999058126,
                40.611767360433994
              ],
              [
                -73.7641892443344,
                40.611773359535405
              ],
              [
                -73.76435372713276,
                40.61180098289913
              ],
              [
                -73.76445501191374,
                40.611816328133095
              ],
              [
                -73.76450473059718,
                40.61182484116258
              ],
              [
                -73.76455265822071,
                40.61183494471302
              ],
              [
                -73.76459483901745,
                40.611846242016604
              ],
              [
                -73.76466588211672,
                40.61186905289796
              ],
              [
                -73.764737825289,
                40.61189484710064
              ],
              [
                -73.7648108217638,
                40.61192368792771
              ],
              [
                -73.76488511741928,
                40.61195567304717
              ],
              [
                -73.76498676806156,
                40.612001636258505
              ],
              [
                -73.76504367100014,
                40.61202689609064
              ],
              [
                -73.76505961679173,
                40.61203523061048
              ],
              [
                -73.76507307015397,
                40.612043561709164
              ],
              [
                -73.76507602880078,
                40.612045765241106
              ],
              [
                -73.76507871435258,
                40.61204817461116
              ],
              [
                -73.76508330393696,
                40.612053623675365
              ],
              [
                -73.76508687389499,
                40.61205996157751
              ],
              [
                -73.76508948257325,
                40.612067287279444
              ],
              [
                -73.76509191260185,
                40.612082088957
              ],
              [
                -73.76509365121599,
                40.61211864393701
              ],
              [
                -73.76510355561395,
                40.61217177723917
              ],
              [
                -73.76510385766348,
                40.61218664593522
              ],
              [
                -73.76510161275102,
                40.61219982575437
              ],
              [
                -73.7650995138344,
                40.61220531325073
              ],
              [
                -73.76509631619642,
                40.612210863838186
              ],
              [
                -73.76509205464984,
                40.61221639248282
              ],
              [
                -73.76508677691687,
                40.61222183126502
              ],
              [
                -73.7650533511491,
                40.6122511642134
              ],
              [
                -73.76503932155175,
                40.612261887413986
              ],
              [
                -73.76500647572327,
                40.61228427056627
              ],
              [
                -73.76499352404916,
                40.61229446523158
              ],
              [
                -73.764988994485,
                40.612298940401224
              ],
              [
                -73.76498505323232,
                40.61230396576787
              ],
              [
                -73.76497946113022,
                40.612315022507815
              ],
              [
                -73.76497795224616,
                40.61232069247532
              ],
              [
                -73.76497737641814,
                40.6123262747222
              ],
              [
                -73.76497772419994,
                40.61233164257704
              ],
              [
                -73.76497900646007,
                40.61233670609843
              ],
              [
                -73.764981866706,
                40.61234332199184
              ],
              [
                -73.76498561413864,
                40.612349641664075
              ],
              [
                -73.76499016553302,
                40.612355514335825
              ],
              [
                -73.76499541692499,
                40.6123608104616
              ],
              [
                -73.76504425370344,
                40.6124018566937
              ],
              [
                -73.76509451138658,
                40.61243838569409
              ],
              [
                -73.76510029414598,
                40.6124439266564
              ],
              [
                -73.7651052819417,
                40.61245023177143
              ],
              [
                -73.76510948711574,
                40.61245729185027
              ],
              [
                -73.76511124503968,
                40.61246131213069
              ],
              [
                -73.76511291010738,
                40.61246510722905
              ],
              [
                -73.76511737315961,
                40.612482972484045
              ],
              [
                -73.7651187061248,
                40.61250393114174
              ],
              [
                -73.7651175619944,
                40.61252327142816
              ],
              [
                -73.76511130585179,
                40.6125785417133
              ],
              [
                -73.76510843477006,
                40.612645409277974
              ],
              [
                -73.76510837815503,
                40.61269529861784
              ],
              [
                -73.76511218412732,
                40.612738782987726
              ],
              [
                -73.7651237245843,
                40.61280723785749
              ],
              [
                -73.76512924250447,
                40.61283426511108
              ],
              [
                -73.76514573378662,
                40.61289949610398
              ],
              [
                -73.76515206853225,
                40.612929362137606
              ],
              [
                -73.76515561199474,
                40.61295661922677
              ],
              [
                -73.76515707653228,
                40.6129871130944
              ],
              [
                -73.76515429478773,
                40.61308589621872
              ],
              [
                -73.76515223873422,
                40.613132682677566
              ],
              [
                -73.76515155831194,
                40.613148314136886
              ],
              [
                -73.76514592812889,
                40.6132066746086
              ],
              [
                -73.76513733345502,
                40.61326178699132
              ],
              [
                -73.76512568643251,
                40.6133140654055
              ],
              [
                -73.76512389686395,
                40.61331884488396
              ],
              [
                -73.76512108018139,
                40.613323386717155
              ],
              [
                -73.76511719002606,
                40.61332774575997
              ],
              [
                -73.76511217652426,
                40.61333197618983
              ],
              [
                -73.76509874740034,
                40.6133402342545
              ],
              [
                -73.76506594816783,
                40.61335579119238
              ],
              [
                -73.76505287604441,
                40.61336311282137
              ],
              [
                -73.765046739582,
                40.61336754031307
              ],
              [
                -73.76504185479139,
                40.613372050442294
              ],
              [
                -73.76503813998191,
                40.61337674305695
              ],
              [
                -73.7650355466568,
                40.613381655919895
              ],
              [
                -73.76502798219816,
                40.61340211775718
              ],
              [
                -73.76502224207296,
                40.61342166492647
              ],
              [
                -73.7650182993051,
                40.61344034243834
              ],
              [
                -73.76501614607245,
                40.61345818696599
              ],
              [
                -73.76501580220096,
                40.61347518749322
              ],
              [
                -73.76501724522896,
                40.61349135519837
              ],
              [
                -73.7650205004746,
                40.61350668008155
              ],
              [
                -73.76502554335005,
                40.613521152710504
              ],
              [
                -73.76504104839242,
                40.61355425272034
              ],
              [
                -73.76506031696873,
                40.613589123668405
              ],
              [
                -73.76508378272462,
                40.61362660576178
              ],
              [
                -73.76511369195244,
                40.61367026208242
              ],
              [
                -73.7651244912455,
                40.61368755610694
              ],
              [
                -73.76514898195599,
                40.613732785002355
              ],
              [
                -73.76516400829198,
                40.613754202686565
              ],
              [
                -73.76517409928843,
                40.61376462504164
              ],
              [
                -73.7651849533732,
                40.61377291345589
              ],
              [
                -73.76519073170368,
                40.61377631119865
              ],
              [
                -73.76519677155784,
                40.613779232518894
              ],
              [
                -73.76520308506144,
                40.61378166738941
              ],
              [
                -73.7652096838288,
                40.613783626388305
              ],
              [
                -73.7652255416092,
                40.61378698222855
              ],
              [
                -73.76524354206231,
                40.61378942287315
              ],
              [
                -73.7653079415949,
                40.6137937149734
              ],
              [
                -73.7653331716059,
                40.61379659421426
              ],
              [
                -73.7654573313066,
                40.613816992206736
              ],
              [
                -73.76554735771236,
                40.61382929695944
              ],
              [
                -73.76571384084899,
                40.61384767414092
              ],
              [
                -73.76577937364263,
                40.613855686939
              ],
              [
                -73.76590929681872,
                40.613870072495615
              ],
              [
                -73.76594390100304,
                40.61387557276066
              ],
              [
                -73.76597356286003,
                40.613882063104185
              ],
              [
                -73.76600277923055,
                40.61389096579561
              ],
              [
                -73.76602780510198,
                40.613901678650315
              ],
              [
                -73.76612561446997,
                40.613922330741474
              ],
              [
                -73.76622123270072,
                40.61394826613217
              ],
              [
                -73.76631416907505,
                40.613979351720964
              ],
              [
                -73.76640026141412,
                40.61402314102588
              ],
              [
                -73.76648154954675,
                40.61407197232676
              ],
              [
                -73.76655752621322,
                40.61412554090956
              ],
              [
                -73.76659603795996,
                40.6141562058029
              ],
              [
                -73.76663280474156,
                40.614188090292245
              ],
              [
                -73.76669071688973,
                40.61422662157114
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.00024056392715,
        "objectid": 75,
        "shape_leng": 0.0876638997685,
        "location_id": 75,
        "zone": "East Harlem South",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.94103496899987,
                40.79254666899993
              ],
              [
                -73.94327251199996,
                40.79348810299995
              ],
              [
                -73.94281682199988,
                40.79413407699997
              ],
              [
                -73.94234676599999,
                40.79476079799988
              ],
              [
                -73.94187604699994,
                40.79540586899984
              ],
              [
                -73.94232710899988,
                40.79559310299991
              ],
              [
                -73.9424792319999,
                40.79565624799993
              ],
              [
                -73.94275347999998,
                40.7957700849999
              ],
              [
                -73.94287776199995,
                40.7958216709999
              ],
              [
                -73.9434832399999,
                40.796072993999935
              ],
              [
                -73.94415121699986,
                40.79635931699992
              ],
              [
                -73.94421313799992,
                40.796385857999944
              ],
              [
                -73.9449594289999,
                40.79670574099989
              ],
              [
                -73.94509614699999,
                40.79676348299994
              ],
              [
                -73.94522698899986,
                40.79682013299991
              ],
              [
                -73.94556506799988,
                40.79696479499997
              ],
              [
                -73.9456668469999,
                40.79700834399995
              ],
              [
                -73.9467003099999,
                40.797450543999915
              ],
              [
                -73.94722520899997,
                40.797671685999944
              ],
              [
                -73.94831125500004,
                40.79812921799992
              ],
              [
                -73.94879165299982,
                40.797477127999905
              ],
              [
                -73.94909695599983,
                40.79705785599993
              ],
              [
                -73.94922045699984,
                40.796909858999896
              ],
              [
                -73.94940516399991,
                40.79663170399987
              ],
              [
                -73.94969538299988,
                40.79621687199993
              ],
              [
                -73.95015821299991,
                40.79558773199993
              ],
              [
                -73.95061371799994,
                40.79496557399992
              ],
              [
                -73.95111102399997,
                40.79428503499988
              ],
              [
                -73.95160265399991,
                40.793607804999965
              ],
              [
                -73.95207222299993,
                40.792966160999896
              ],
              [
                -73.95252222700002,
                40.79234762199997
              ],
              [
                -73.95298394599986,
                40.79172143299998
              ],
              [
                -73.95343832999994,
                40.791085367999926
              ],
              [
                -73.95388917099996,
                40.790470498999866
              ],
              [
                -73.95439919199988,
                40.78977490899994
              ],
              [
                -73.95481723799992,
                40.789204743999974
              ],
              [
                -73.95527481199989,
                40.78858337999988
              ],
              [
                -73.9557773589999,
                40.78791392399995
              ],
              [
                -73.95416383099995,
                40.78722686099993
              ],
              [
                -73.95266136699996,
                40.78659501999991
              ],
              [
                -73.95255145499999,
                40.78654858099986
              ],
              [
                -73.95244615099996,
                40.786502485999925
              ],
              [
                -73.95092733999991,
                40.78586544699993
              ],
              [
                -73.95033804099997,
                40.78561714999992
              ],
              [
                -73.94933170599992,
                40.78519312699994
              ],
              [
                -73.94706938199998,
                40.78423622699995
              ],
              [
                -73.94472264499986,
                40.78324757199989
              ],
              [
                -73.94464666000002,
                40.78321787299997
              ],
              [
                -73.94405439399999,
                40.78296435799991
              ],
              [
                -73.94395460199989,
                40.782915519999925
              ],
              [
                -73.94389324599985,
                40.782887726999896
              ],
              [
                -73.94383256699986,
                40.78285908899991
              ],
              [
                -73.9437600529999,
                40.782828931999894
              ],
              [
                -73.94360760400001,
                40.78286536999992
              ],
              [
                -73.94354420706223,
                40.78288052416259
              ],
              [
                -73.94355143839495,
                40.782903507985836
              ],
              [
                -73.94344105526409,
                40.78304918212819
              ],
              [
                -73.94339679077277,
                40.783109502846365
              ],
              [
                -73.94334749366392,
                40.783182032998575
              ],
              [
                -73.94331027382039,
                40.783233180946446
              ],
              [
                -73.94327003035636,
                40.78328815517935
              ],
              [
                -73.94323985739757,
                40.78332785169434
              ],
              [
                -73.94320565536934,
                40.783369842987625
              ],
              [
                -73.94316141910545,
                40.78341182999218
              ],
              [
                -73.94311516211296,
                40.783446170629645
              ],
              [
                -73.94301663136942,
                40.78351486545848
              ],
              [
                -73.94293520531122,
                40.78357210608962
              ],
              [
                -73.94286783792664,
                40.78361865580254
              ],
              [
                -73.94278137533362,
                40.783678190096595
              ],
              [
                -73.94267379818272,
                40.78375374314715
              ],
              [
                -73.9426044272356,
                40.78380183093073
              ],
              [
                -73.94257225524868,
                40.783824724155444
              ],
              [
                -73.94246970444752,
                40.783896460947055
              ],
              [
                -73.94243451137048,
                40.783917830322316
              ],
              [
                -73.94239128520978,
                40.78394072618268
              ],
              [
                -73.94231388874256,
                40.78398116626904
              ],
              [
                -73.94217918595554,
                40.78405136339483
              ],
              [
                -73.94201431753095,
                40.784137584897415
              ],
              [
                -73.9418544859221,
                40.78422074667745
              ],
              [
                -73.94167454195741,
                40.78431383063195
              ],
              [
                -73.94152576810184,
                40.784390900969875
              ],
              [
                -73.94144612018933,
                40.78443147385159
              ],
              [
                -73.94117288854788,
                40.784566076962534
              ],
              [
                -73.94113723613098,
                40.78458311400423
              ],
              [
                -73.94111082838812,
                40.784597149183135
              ],
              [
                -73.94108573710648,
                40.7846091665167
              ],
              [
                -73.9410249973189,
                40.78463522248629
              ],
              [
                -73.94083088142574,
                40.784727424556394
              ],
              [
                -73.94078868006106,
                40.784747448788664
              ],
              [
                -73.94076750825941,
                40.7847574951213
              ],
              [
                -73.94074901720532,
                40.784767518202216
              ],
              [
                -73.940721283615,
                40.78478054310244
              ],
              [
                -73.94071732676325,
                40.78478054106709
              ],
              [
                -73.94067374402164,
                40.78480359792665
              ],
              [
                -73.9406235705765,
                40.784829650411645
              ],
              [
                -73.94059054968368,
                40.78484668914218
              ],
              [
                -73.94058130200887,
                40.784853708709235
              ],
              [
                -73.94055093807704,
                40.784866742096156
              ],
              [
                -73.94053377198207,
                40.78487877160598
              ],
              [
                -73.94045320748533,
                40.78492287258088
              ],
              [
                -73.94036209351322,
                40.78497198408236
              ],
              [
                -73.94026700995506,
                40.78502210145345
              ],
              [
                -73.94023795768307,
                40.78503713468404
              ],
              [
                -73.94022475284191,
                40.7850451507884
              ],
              [
                -73.94020230181276,
                40.78505918714037
              ],
              [
                -73.94018512931,
                40.78506620256322
              ],
              [
                -73.9401494727627,
                40.78508725537949
              ],
              [
                -73.94011434323865,
                40.785103824644665
              ],
              [
                -73.94006415729082,
                40.785131029620125
              ],
              [
                -73.94004982707635,
                40.785138513728796
              ],
              [
                -73.94002473252542,
                40.78515347642582
              ],
              [
                -73.94001308386721,
                40.78515687380644
              ],
              [
                -73.93998978019029,
                40.7851704772902
              ],
              [
                -73.93995483679325,
                40.78518952279442
              ],
              [
                -73.93992974399465,
                40.78520176553125
              ],
              [
                -73.9399100328247,
                40.785211966757934
              ],
              [
                -73.93988852061734,
                40.785223527347014
              ],
              [
                -73.93988045560609,
                40.78523237601269
              ],
              [
                -73.93985267603377,
                40.78524393165021
              ],
              [
                -73.93984282390869,
                40.78525141086653
              ],
              [
                -73.93983453997872,
                40.78525397286172
              ],
              [
                -73.9398261969646,
                40.78525655241547
              ],
              [
                -73.93926642644561,
                40.78588875256448
              ],
              [
                -73.93853937331639,
                40.78671077700018
              ],
              [
                -73.93824519951414,
                40.78704106810817
              ],
              [
                -73.93819429625309,
                40.78709733029971
              ],
              [
                -73.93813356192969,
                40.78716579039842
              ],
              [
                -73.93811570089733,
                40.78718476275645
              ],
              [
                -73.93797181801379,
                40.78743187338253
              ],
              [
                -73.93795930775185,
                40.78745627992995
              ],
              [
                -73.93794590851209,
                40.787469159257824
              ],
              [
                -73.93794768976979,
                40.787477299429206
              ],
              [
                -73.9378172269292,
                40.78768846868085
              ],
              [
                -73.93777363255099,
                40.787783358062626
              ],
              [
                -73.93774122176191,
                40.78785390435086
              ],
              [
                -73.9376956302226,
                40.78795729352048
              ],
              [
                -73.93768669385376,
                40.787973560579
              ],
              [
                -73.93763482912277,
                40.788094244814715
              ],
              [
                -73.93754989162562,
                40.78828374452882
              ],
              [
                -73.93741675677381,
                40.788579762018955
              ],
              [
                -73.93739788609298,
                40.78862171984344
              ],
              [
                -73.93722890167288,
                40.78899630803707
              ],
              [
                -73.93714965461099,
                40.78916844905941
              ],
              [
                -73.9362430332504,
                40.78879577415203
              ],
              [
                -73.93622751295341,
                40.78879561294985
              ],
              [
                -73.93621859869943,
                40.78880049757431
              ],
              [
                -73.93612689279682,
                40.78892686798536
              ],
              [
                -73.93612836235276,
                40.788938530059575
              ],
              [
                -73.93614073314119,
                40.78894907341049
              ],
              [
                -73.93701479686747,
                40.78932128118495
              ],
              [
                -73.93707246262223,
                40.78934584188616
              ],
              [
                -73.93708104382307,
                40.78936639571001
              ],
              [
                -73.93705304578874,
                40.78940641675745
              ],
              [
                -73.93706168747059,
                40.78941359021348
              ],
              [
                -73.93669302210014,
                40.78991304915909
              ],
              [
                -73.93632010870607,
                40.79042595764855
              ],
              [
                -73.93602391823781,
                40.79082912812602
              ],
              [
                -73.93583106788897,
                40.791094704870254
              ],
              [
                -73.93553896695929,
                40.79131869365976
              ],
              [
                -73.93546762088405,
                40.79126365205755
              ],
              [
                -73.93519039296174,
                40.79147116012903
              ],
              [
                -73.93526500788931,
                40.791525763481154
              ],
              [
                -73.93526809778392,
                40.791528025800446
              ],
              [
                -73.93517135169378,
                40.791605207031665
              ],
              [
                -73.93510685331493,
                40.79164962938518
              ],
              [
                -73.93505300002766,
                40.79168720295447
              ],
              [
                -73.93508265899982,
                40.79171004399995
              ],
              [
                -73.93513822199999,
                40.79175283499989
              ],
              [
                -73.93519360499981,
                40.79179799899988
              ],
              [
                -73.93524060899985,
                40.79183387499989
              ],
              [
                -73.93529038199985,
                40.79187313699992
              ],
              [
                -73.93542842699998,
                40.79199687999987
              ],
              [
                -73.93555312799995,
                40.79184222599987
              ],
              [
                -73.9367920929999,
                40.792403927999956
              ],
              [
                -73.9377361209998,
                40.79281580399994
              ],
              [
                -73.93819346899994,
                40.79218819499989
              ],
              [
                -73.93864883299987,
                40.79155775499987
              ],
              [
                -73.94103496899987,
                40.79254666899993
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.00126660143241,
        "objectid": 76,
        "shape_leng": 0.241203016269,
        "location_id": 76,
        "zone": "East New York",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.88063900599983,
                40.679176984999856
              ],
              [
                -73.88109834200004,
                40.68107447699987
              ],
              [
                -73.88116730300004,
                40.68135366299988
              ],
              [
                -73.88210725299986,
                40.68121749099988
              ],
              [
                -73.88303083299995,
                40.68108517499994
              ],
              [
                -73.88397193799985,
                40.680947114999874
              ],
              [
                -73.88489254699988,
                40.68081111599984
              ],
              [
                -73.88467046600006,
                40.67994028499991
              ],
              [
                -73.88555502399997,
                40.67965659699994
              ],
              [
                -73.88643790499991,
                40.67937347599991
              ],
              [
                -73.88730460899997,
                40.679097536999976
              ],
              [
                -73.8881563449999,
                40.678827834999936
              ],
              [
                -73.88900569799982,
                40.678557043999916
              ],
              [
                -73.88987439199998,
                40.67830214999987
              ],
              [
                -73.89077319200003,
                40.67816458899992
              ],
              [
                -73.89167121099989,
                40.678031720999876
              ],
              [
                -73.89257905399982,
                40.67790012399996
              ],
              [
                -73.89348405399988,
                40.677762060999896
              ],
              [
                -73.8943888149999,
                40.677629888999896
              ],
              [
                -73.89531770399991,
                40.67749603799995
              ],
              [
                -73.89621773699986,
                40.67735818399991
              ],
              [
                -73.8965367649999,
                40.67859358099987
              ],
              [
                -73.89695521899982,
                40.67840924299985
              ],
              [
                -73.89747545199997,
                40.67816325499988
              ],
              [
                -73.89761600399984,
                40.678111987999934
              ],
              [
                -73.89775655599985,
                40.678060720999895
              ],
              [
                -73.89783505699992,
                40.67799357599994
              ],
              [
                -73.89836229199989,
                40.677658275999875
              ],
              [
                -73.89883201599999,
                40.677446439999855
              ],
              [
                -73.89912562799992,
                40.677312637999925
              ],
              [
                -73.89992296499994,
                40.676949272999856
              ],
              [
                -73.90004626199993,
                40.67689308999995
              ],
              [
                -73.9000274839998,
                40.67681911399995
              ],
              [
                -73.89974568900007,
                40.675711963999895
              ],
              [
                -73.89973501099999,
                40.675654870999914
              ],
              [
                -73.89972239699993,
                40.6756037369999
              ],
              [
                -73.89971755899985,
                40.6755642639999
              ],
              [
                -73.89970899499988,
                40.67549424999994
              ],
              [
                -73.89930675399987,
                40.67398204199993
              ],
              [
                -73.89838655899996,
                40.67412124399994
              ],
              [
                -73.89748422799983,
                40.674255484999954
              ],
              [
                -73.89650978,
                40.6743970479999
              ],
              [
                -73.89549943499999,
                40.67455074399987
              ],
              [
                -73.89459383999989,
                40.674684103999915
              ],
              [
                -73.89367036399987,
                40.6748217709999
              ],
              [
                -73.89335128299989,
                40.673581645999896
              ],
              [
                -73.89302801299988,
                40.672314491999934
              ],
              [
                -73.89271090700004,
                40.67104582799992
              ],
              [
                -73.8917995219999,
                40.67118041299997
              ],
              [
                -73.89087805100002,
                40.671318086999875
              ],
              [
                -73.89055868999999,
                40.67006023099993
              ],
              [
                -73.89016521899985,
                40.668524376999876
              ],
              [
                -73.88976988599985,
                40.66698991499991
              ],
              [
                -73.89069385399985,
                40.66685223399993
              ],
              [
                -73.89029917799994,
                40.66531175899994
              ],
              [
                -73.889906696,
                40.66377828999986
              ],
              [
                -73.88898470699996,
                40.663916614999884
              ],
              [
                -73.88810817399994,
                40.6640474119999
              ],
              [
                -73.88739217299995,
                40.6643534569999
              ],
              [
                -73.88726131299987,
                40.664413573999866
              ],
              [
                -73.88657767900006,
                40.6647080369999
              ],
              [
                -73.88641344699995,
                40.66478080699991
              ],
              [
                -73.88556364499998,
                40.66514048699993
              ],
              [
                -73.88493743699995,
                40.665407108999936
              ],
              [
                -73.88474247400003,
                40.66549489399995
              ],
              [
                -73.88412170000002,
                40.665760741999854
              ],
              [
                -73.88391264599986,
                40.66585179899994
              ],
              [
                -73.88330883399975,
                40.666112004999896
              ],
              [
                -73.88208825799985,
                40.664464146999926
              ],
              [
                -73.88185557299985,
                40.66414998799994
              ],
              [
                -73.88266944200001,
                40.66379795399993
              ],
              [
                -73.88309163,
                40.66361709199988
              ],
              [
                -73.88348677599993,
                40.663447816999906
              ],
              [
                -73.88430189299983,
                40.66309545699992
              ],
              [
                -73.88511751799982,
                40.662745639999876
              ],
              [
                -73.88593573999992,
                40.66239371299988
              ],
              [
                -73.88681373099988,
                40.66201382299992
              ],
              [
                -73.88756863499995,
                40.66168908099994
              ],
              [
                -73.88838556399988,
                40.661337738999926
              ],
              [
                -73.88919997600003,
                40.660988080999914
              ],
              [
                -73.89001492699988,
                40.66063583299995
              ],
              [
                -73.89083184199988,
                40.66028396799988
              ],
              [
                -73.89164919999989,
                40.65993257599996
              ],
              [
                -73.89246408899987,
                40.65958027499991
              ],
              [
                -73.89327894299984,
                40.65923017399993
              ],
              [
                -73.8940984499999,
                40.658877919999945
              ],
              [
                -73.89491382099995,
                40.65852644899994
              ],
              [
                -73.89571848099996,
                40.65815995999985
              ],
              [
                -73.8961675609999,
                40.657978451999924
              ],
              [
                -73.89613759299986,
                40.6578822489999
              ],
              [
                -73.89612587299993,
                40.65781466399995
              ],
              [
                -73.89704215399999,
                40.65766358899994
              ],
              [
                -73.89796594499987,
                40.65753203599994
              ],
              [
                -73.89880698499995,
                40.65740666199987
              ],
              [
                -73.89891727499989,
                40.6573902249999
              ],
              [
                -73.899041804,
                40.657371657999875
              ],
              [
                -73.89948109299995,
                40.65732134299993
              ],
              [
                -73.89968548099993,
                40.657297937999935
              ],
              [
                -73.89988986899985,
                40.6572745209999
              ],
              [
                -73.90002679299982,
                40.657253847999876
              ],
              [
                -73.9002942619999,
                40.657208882999925
              ],
              [
                -73.9003948669999,
                40.65718420499992
              ],
              [
                -73.90048767199994,
                40.65714867899988
              ],
              [
                -73.900574915,
                40.65709994699991
              ],
              [
                -73.90064904799996,
                40.657041557999904
              ],
              [
                -73.901185599,
                40.6565145509999
              ],
              [
                -73.90230238299979,
                40.65578061099992
              ],
              [
                -73.90245383299998,
                40.65566436699993
              ],
              [
                -73.90261911399985,
                40.655558110999955
              ],
              [
                -73.90279641599982,
                40.655463210999876
              ],
              [
                -73.90298366699997,
                40.655380780999884
              ],
              [
                -73.90317858399993,
                40.655311633999915
              ],
              [
                -73.90414221400002,
                40.65514642099993
              ],
              [
                -73.90446144000003,
                40.6550995359999
              ],
              [
                -73.90489261799983,
                40.65503619999988
              ],
              [
                -73.90507059799984,
                40.655010060999935
              ],
              [
                -73.90556272799998,
                40.65493790999987
              ],
              [
                -73.90606783199999,
                40.6548638509999
              ],
              [
                -73.90692334299995,
                40.65473841999989
              ],
              [
                -73.90784346399994,
                40.65459152999989
              ],
              [
                -73.90782538299986,
                40.65453360399992
              ],
              [
                -73.90780156999992,
                40.654438948999925
              ],
              [
                -73.90740035699999,
                40.6528439899999
              ],
              [
                -73.90821606899983,
                40.65231208699989
              ],
              [
                -73.90855790499995,
                40.652095937999896
              ],
              [
                -73.90944846399994,
                40.65152159899992
              ],
              [
                -73.90868769500001,
                40.65072148099995
              ],
              [
                -73.90849084299998,
                40.650586417999875
              ],
              [
                -73.90827570699999,
                40.650498573999954
              ],
              [
                -73.90761503399983,
                40.64989589399989
              ],
              [
                -73.90754641399995,
                40.64983425399987
              ],
              [
                -73.90637218799995,
                40.6487793819999
              ],
              [
                -73.90588893999983,
                40.64834514399994
              ],
              [
                -73.90579983699993,
                40.64826507899992
              ],
              [
                -73.90509580299988,
                40.64760685199993
              ],
              [
                -73.90398200599991,
                40.6466285679999
              ],
              [
                -73.903099438,
                40.64719961099992
              ],
              [
                -73.90235538599994,
                40.64768960199993
              ],
              [
                -73.90165833699982,
                40.648142211999904
              ],
              [
                -73.90089120800002,
                40.648641709999886
              ],
              [
                -73.90074707899997,
                40.6487354449999
              ],
              [
                -73.90056999899996,
                40.648847606999894
              ],
              [
                -73.90027087999994,
                40.649037770999946
              ],
              [
                -73.90019369799995,
                40.64908881799994
              ],
              [
                -73.89946894299986,
                40.649541768999924
              ],
              [
                -73.89872808799994,
                40.650024606999885
              ],
              [
                -73.89797649499998,
                40.650494030999894
              ],
              [
                -73.8977945719999,
                40.65061310899988
              ],
              [
                -73.89771516099994,
                40.65066508899989
              ],
              [
                -73.89757174800002,
                40.65075888699994
              ],
              [
                -73.89742477899996,
                40.6508550119999
              ],
              [
                -73.897365881,
                40.650893706999895
              ],
              [
                -73.89727504499993,
                40.650953162999876
              ],
              [
                -73.89721054799986,
                40.65099537899994
              ],
              [
                -73.896807004,
                40.65126016999988
              ],
              [
                -73.89675272299998,
                40.65129578599994
              ],
              [
                -73.89661390699996,
                40.65138687099991
              ],
              [
                -73.89655685999999,
                40.65142430299996
              ],
              [
                -73.89627957599997,
                40.65160624199992
              ],
              [
                -73.89621590699987,
                40.65164801899989
              ],
              [
                -73.895861549,
                40.65188052699987
              ],
              [
                -73.89610981499999,
                40.652103911999895
              ],
              [
                -73.89619634499996,
                40.652181767999906
              ],
              [
                -73.89654981299996,
                40.652499805999916
              ],
              [
                -73.896634179,
                40.65257571499996
              ],
              [
                -73.89676845099996,
                40.65269652599995
              ],
              [
                -73.89684143499989,
                40.652762191999905
              ],
              [
                -73.89746257800005,
                40.65332105699988
              ],
              [
                -73.89761013299987,
                40.653453815999924
              ],
              [
                -73.89741344699992,
                40.653538319999896
              ],
              [
                -73.89678213299993,
                40.653809555999885
              ],
              [
                -73.89667745599995,
                40.65385495699996
              ],
              [
                -73.8959444279999,
                40.654172887999934
              ],
              [
                -73.89558884499993,
                40.65432447199987
              ],
              [
                -73.89546427299999,
                40.65437757699993
              ],
              [
                -73.89520540399988,
                40.65448792999991
              ],
              [
                -73.89441113999995,
                40.65483294899989
              ],
              [
                -73.89354677099988,
                40.65520271399988
              ],
              [
                -73.89245737399975,
                40.65371093899989
              ],
              [
                -73.89136543599976,
                40.65236471899987
              ],
              [
                -73.89003782499988,
                40.65117817699991
              ],
              [
                -73.89000589199988,
                40.651148696999925
              ],
              [
                -73.88959044299983,
                40.651337545999866
              ],
              [
                -73.88876540999999,
                40.65169059099987
              ],
              [
                -73.88794902799988,
                40.652040973999924
              ],
              [
                -73.88713099000002,
                40.65239391599988
              ],
              [
                -73.88637373700001,
                40.65273440399989
              ],
              [
                -73.88628975299989,
                40.6527689009999
              ],
              [
                -73.88621116599981,
                40.65280058399992
              ],
              [
                -73.8855122199999,
                40.65309253199996
              ],
              [
                -73.88465215799991,
                40.65345165399986
              ],
              [
                -73.88278243099995,
                40.654269767999935
              ],
              [
                -73.88221364899994,
                40.65451863799996
              ],
              [
                -73.88220052999989,
                40.65444157599989
              ],
              [
                -73.88172188099986,
                40.65375382699986
              ],
              [
                -73.88141998000002,
                40.65334717599994
              ],
              [
                -73.87992598699992,
                40.651334759999905
              ],
              [
                -73.87908517399995,
                40.65020211699988
              ],
              [
                -73.87843582199996,
                40.64932734399993
              ],
              [
                -73.87798475599999,
                40.64872529299991
              ],
              [
                -73.87679877399998,
                40.64714224999995
              ],
              [
                -73.87610398000004,
                40.646214788999906
              ],
              [
                -73.87468212999995,
                40.645654467999904
              ],
              [
                -73.8745808109999,
                40.64561010599994
              ],
              [
                -73.874953578,
                40.64514158399989
              ],
              [
                -73.87530646399992,
                40.64465566899991
              ],
              [
                -73.87545861899982,
                40.644426930999906
              ],
              [
                -73.87596309099979,
                40.643644483999935
              ],
              [
                -73.87616087400002,
                40.64332052799995
              ],
              [
                -73.8761776709999,
                40.643289658999905
              ],
              [
                -73.8761993309999,
                40.643257172999874
              ],
              [
                -73.87654859999992,
                40.64263892799989
              ],
              [
                -73.87683348999987,
                40.64211858899987
              ],
              [
                -73.87709627099984,
                40.64160295499988
              ],
              [
                -73.8773424829999,
                40.64108267999991
              ],
              [
                -73.87752894599991,
                40.64071679999995
              ],
              [
                -73.87797341300002,
                40.639807117999894
              ],
              [
                -73.87841589406925,
                40.638912189353334
              ],
              [
                -73.87835076562644,
                40.63887161374519
              ],
              [
                -73.87828447826044,
                40.638859832177
              ],
              [
                -73.87816157529974,
                40.63884216355752
              ],
              [
                -73.87803757833373,
                40.638829696353355
              ],
              [
                -73.87791286639488,
                40.63882246867387
              ],
              [
                -73.87778782070119,
                40.638820502612816
              ],
              [
                -73.8776628234893,
                40.63882380417986
              ],
              [
                -73.87757233898627,
                40.638813371667645
              ],
              [
                -73.87748347522731,
                40.63879671285495
              ],
              [
                -73.87739696892775,
                40.63877396585033
              ],
              [
                -73.87731353725628,
                40.63874531923534
              ],
              [
                -73.87723387188987,
                40.63871101050159
              ],
              [
                -73.87715863327793,
                40.63867132408132
              ],
              [
                -73.87708844516781,
                40.63862658898945
              ],
              [
                -73.87702388943292,
                40.63857717609553
              ],
              [
                -73.87696550124825,
                40.63852349504838
              ],
              [
                -73.87664550484685,
                40.63829463855765
              ],
              [
                -73.87632386011742,
                40.63806712783067
              ],
              [
                -73.87613944607081,
                40.63791730222954
              ],
              [
                -73.87596175925121,
                40.6377628313934
              ],
              [
                -73.87579100112603,
                40.63760389050788
              ],
              [
                -73.87562736530136,
                40.6374406598268
              ],
              [
                -73.87547103730128,
                40.637273324467614
              ],
              [
                -73.87531326109587,
                40.63715928947522
              ],
              [
                -73.87515014178462,
                40.637049704313
              ],
              [
                -73.87498189550749,
                40.636944714169005
              ],
              [
                -73.87480874519362,
                40.63684445814243
              ],
              [
                -73.87463092026665,
                40.6367490690597
              ],
              [
                -73.8744486563403,
                40.63665867329871
              ],
              [
                -73.87426219490607,
                40.636573390621486
              ],
              [
                -73.87407178301434,
                40.63649333401509
              ],
              [
                -73.87387767294561,
                40.63641860954283
              ],
              [
                -73.87368012187775,
                40.636349316203116
              ],
              [
                -73.87347939154502,
                40.63628554579879
              ],
              [
                -73.87334087347163,
                40.63629514963033
              ],
              [
                -73.87320347198327,
                40.63631161277643
              ],
              [
                -73.87306777172167,
                40.636334865186626
              ],
              [
                -73.87293435009087,
                40.636364807922334
              ],
              [
                -73.87280377480171,
                40.63640131357759
              ],
              [
                -73.872676601456,
                40.63644422682089
              ],
              [
                -73.87255337118395,
                40.636493365056374
              ],
              [
                -73.87243460834033,
                40.63654851920012
              ],
              [
                -73.87167244300319,
                40.63708042439297
              ],
              [
                -73.87153221085434,
                40.63716552254151
              ],
              [
                -73.87138764007423,
                40.637246297761536
              ],
              [
                -73.87123896014546,
                40.63732262182698
              ],
              [
                -73.87108640707612,
                40.63739437357764
              ],
              [
                -73.87093022302477,
                40.63746143911106
              ],
              [
                -73.87077065591667,
                40.6375237119634
              ],
              [
                -73.87060795904935,
                40.637581093279124
              ],
              [
                -73.87042969307484,
                40.63763400156946
              ],
              [
                -73.87025308570794,
                40.63769004523162
              ],
              [
                -73.87018062416199,
                40.63771410885132
              ],
              [
                -73.86997059067167,
                40.637787468158095
              ],
              [
                -73.86976333670746,
                40.63786528033556
              ],
              [
                -73.86955902490303,
                40.6379474843275
              ],
              [
                -73.8692084611063,
                40.637465809261
              ],
              [
                -73.86969549818932,
                40.63714738961504
              ],
              [
                -73.86962489466698,
                40.637083191836915
              ],
              [
                -73.86961569339263,
                40.63709056489552
              ],
              [
                -73.86960972136559,
                40.63708553460873
              ],
              [
                -73.86926691693778,
                40.637311481309595
              ],
              [
                -73.8686067398553,
                40.637743362594975
              ],
              [
                -73.86799709668125,
                40.63811526520958
              ],
              [
                -73.86808857108026,
                40.63818561428028
              ],
              [
                -73.86859780095367,
                40.638349566578306
              ],
              [
                -73.86952435189357,
                40.638645701918676
              ],
              [
                -73.86977753759143,
                40.63884414565846
              ],
              [
                -73.86989583386247,
                40.63893452891954
              ],
              [
                -73.87007958407006,
                40.63921169401704
              ],
              [
                -73.87008544224433,
                40.63984418136626
              ],
              [
                -73.87043578304258,
                40.64029080737933
              ],
              [
                -73.87061752711526,
                40.64038627866386
              ],
              [
                -73.87084503422264,
                40.64055662018323
              ],
              [
                -73.87088107173643,
                40.64077187075254
              ],
              [
                -73.87189439830121,
                40.642248236727454
              ],
              [
                -73.87194880116543,
                40.64239408453872
              ],
              [
                -73.87275635704977,
                40.643248964654816
              ],
              [
                -73.87297320732566,
                40.6438392310653
              ],
              [
                -73.87309815600656,
                40.64393468404353
              ],
              [
                -73.87327031255236,
                40.64390321434489
              ],
              [
                -73.87331993047317,
                40.644008825004235
              ],
              [
                -73.87336143913527,
                40.64411644643527
              ],
              [
                -73.8733946992858,
                40.644225717692095
              ],
              [
                -73.87341959933369,
                40.644336272293806
              ],
              [
                -73.87343605572586,
                40.64444773945353
              ],
              [
                -73.87344401322652,
                40.64455974532179
              ],
              [
                -73.87344344510424,
                40.644671914240746
              ],
              [
                -73.87343435322148,
                40.64478387000366
              ],
              [
                -73.87341676802919,
                40.644895237116714
              ],
              [
                -73.87339074846517,
                40.64500564205861
              ],
              [
                -73.87335638175686,
                40.645114714533555
              ],
              [
                -73.87331378312933,
                40.645222088712686
              ],
              [
                -73.87318593330937,
                40.64544501336156
              ],
              [
                -73.87330678000762,
                40.64558267959164
              ],
              [
                -73.8733574021273,
                40.645594475914194
              ],
              [
                -73.87342337082691,
                40.64565070359492
              ],
              [
                -73.87345352818083,
                40.645752814416575
              ],
              [
                -73.87352462463141,
                40.64587122906813
              ],
              [
                -73.87360265286914,
                40.64596662919093
              ],
              [
                -73.87367284956419,
                40.646067979143226
              ],
              [
                -73.87375709027744,
                40.64616014330096
              ],
              [
                -73.87382160378151,
                40.64625653142809
              ],
              [
                -73.87386034009012,
                40.64631440660163
              ],
              [
                -73.87390127736091,
                40.64636836786121
              ],
              [
                -73.87395425313315,
                40.64644443461837
              ],
              [
                -73.87672192491897,
                40.6503232219678
              ],
              [
                -73.87672243382207,
                40.65037540309042
              ],
              [
                -73.87676193956291,
                40.65056095449516
              ],
              [
                -73.87910837392907,
                40.65370623553959
              ],
              [
                -73.87924124622316,
                40.653737186432
              ],
              [
                -73.87971979034292,
                40.65437592977636
              ],
              [
                -73.8796386550231,
                40.65449909180424
              ],
              [
                -73.87933211680554,
                40.65462201684979
              ],
              [
                -73.87926863785952,
                40.65456913073254
              ],
              [
                -73.87921658230903,
                40.65459108377299
              ],
              [
                -73.87629452461883,
                40.65063714033261
              ],
              [
                -73.87623339658143,
                40.65055427482426
              ],
              [
                -73.87352036861594,
                40.646876209404915
              ],
              [
                -73.87346558258602,
                40.64681663296924
              ],
              [
                -73.87342508856524,
                40.64678123051581
              ],
              [
                -73.87337828287791,
                40.64671520526748
              ],
              [
                -73.87257043102892,
                40.64557561235967
              ],
              [
                -73.87212202857904,
                40.645423703864466
              ],
              [
                -73.8719847673393,
                40.645445871815646
              ],
              [
                -73.87157174183598,
                40.64541496283753
              ],
              [
                -73.87119172949637,
                40.64530535631249
              ],
              [
                -73.87061492461066,
                40.64485674188673
              ],
              [
                -73.87001708273587,
                40.64407137240543
              ],
              [
                -73.86991239339439,
                40.643278202993564
              ],
              [
                -73.86867282895082,
                40.640925079203356
              ],
              [
                -73.86732291072707,
                40.639871983952844
              ],
              [
                -73.86702994403059,
                40.639851069021354
              ],
              [
                -73.86681528201245,
                40.6399635276668
              ],
              [
                -73.86617093011522,
                40.639367600569045
              ],
              [
                -73.86625535025003,
                40.63931631406574
              ],
              [
                -73.86563247391334,
                40.63878676362291
              ],
              [
                -73.86557348743777,
                40.6387695662165
              ],
              [
                -73.86548637000524,
                40.63876518348161
              ],
              [
                -73.86536256486231,
                40.63883569112216
              ],
              [
                -73.86534275703448,
                40.63890203859467
              ],
              [
                -73.86535389969112,
                40.63895129367579
              ],
              [
                -73.86598797060661,
                40.63950441118262
              ],
              [
                -73.86609210225917,
                40.639433879462636
              ],
              [
                -73.86670411973783,
                40.64002176349551
              ],
              [
                -73.86531173665126,
                40.640751191443876
              ],
              [
                -73.86509362394392,
                40.64093967569411
              ],
              [
                -73.86439393961581,
                40.64141360621635
              ],
              [
                -73.86379865777398,
                40.641799578171806
              ],
              [
                -73.86331138939109,
                40.6422991290285
              ],
              [
                -73.86289463224384,
                40.64255067968245
              ],
              [
                -73.86201301027748,
                40.64288704102004
              ],
              [
                -73.86178755735516,
                40.642925904886596
              ],
              [
                -73.86156101965909,
                40.64296091834186
              ],
              [
                -73.86130431300526,
                40.64299577184075
              ],
              [
                -73.86104653361731,
                40.643025681234036
              ],
              [
                -73.86078784559764,
                40.64305062748121
              ],
              [
                -73.8605284136282,
                40.64307059470143
              ],
              [
                -73.86026840286512,
                40.64308557018329
              ],
              [
                -73.86000090808096,
                40.64309832632755
              ],
              [
                -73.85973412126268,
                40.64311787656272
              ],
              [
                -73.85946833970695,
                40.64314419910291
              ],
              [
                -73.85920385959093,
                40.643177264615204
              ],
              [
                -73.85894097564287,
                40.64321703625278
              ],
              [
                -73.85867998081393,
                40.643263469695526
              ],
              [
                -73.85842116595126,
                40.643316513199444
              ],
              [
                -73.85816481947492,
                40.64337610765483
              ],
              [
                -73.85791122705675,
                40.64344218665107
              ],
              [
                -73.85766067130159,
                40.643514676551604
              ],
              [
                -73.8574134314329,
                40.64359349657505
              ],
              [
                -73.85716978298206,
                40.64367855888598
              ],
              [
                -73.85716786567292,
                40.64382187915259
              ],
              [
                -73.85717492317225,
                40.643965106006505
              ],
              [
                -73.85719093947807,
                40.644107913547664
              ],
              [
                -73.85721587820477,
                40.6442499768278
              ],
              [
                -73.85724968266203,
                40.64439097259036
              ],
              [
                -73.85729227598536,
                40.64453058000569
              ],
              [
                -73.85734356130943,
                40.64466848140127
              ],
              [
                -73.85740342198902,
                40.64480436298479
              ],
              [
                -73.85747172186305,
                40.644937915557776
              ],
              [
                -73.85754830556486,
                40.64506883522003
              ],
              [
                -73.85763299887402,
                40.6451968240604
              ],
              [
                -73.85772560911354,
                40.645321590835536
              ],
              [
                -73.85774598945184,
                40.6453434547353
              ],
              [
                -73.85784479859338,
                40.64545636017166
              ],
              [
                -73.85793619946534,
                40.6455728299514
              ],
              [
                -73.85801997088916,
                40.645692582282706
              ],
              [
                -73.85809591014267,
                40.645815327430086
              ],
              [
                -73.85816383344905,
                40.64594076841581
              ],
              [
                -73.85822357642378,
                40.64606860173778
              ],
              [
                -73.85827499447149,
                40.64619851810386
              ],
              [
                -73.85831796313752,
                40.646330203180305
              ],
              [
                -73.8583523784073,
                40.64646333835212
              ],
              [
                -73.85837815696055,
                40.64659760149385
              ],
              [
                -73.85839523637277,
                40.64673266774884
              ],
              [
                -73.85840357526584,
                40.646868210315425
              ],
              [
                -73.85840315341014,
                40.64700390123736
              ],
              [
                -73.85839397177297,
                40.64713941219766
              ],
              [
                -73.85837605251687,
                40.64727441531266
              ],
              [
                -73.85783469416334,
                40.64830233828765
              ],
              [
                -73.85770542077614,
                40.64874597146729
              ],
              [
                -73.85759335427862,
                40.649397285892846
              ],
              [
                -73.8575242937435,
                40.64999619023134
              ],
              [
                -73.85726047236975,
                40.6501716562602
              ],
              [
                -73.85723212115558,
                40.650200433636805
              ],
              [
                -73.85720730991866,
                40.65023104914557
              ],
              [
                -73.85718624276892,
                40.6502632509345
              ],
              [
                -73.85716559774956,
                40.650323278690045
              ],
              [
                -73.8571376518816,
                40.65038156710169
              ],
              [
                -73.85710265151224,
                40.65043760230977
              ],
              [
                -73.85706090518094,
                40.65049089031753
              ],
              [
                -73.85701278090167,
                40.650540961345556
              ],
              [
                -73.85695870295892,
                40.65058737348715
              ],
              [
                -73.85898002989568,
                40.65267545791771
              ],
              [
                -73.85975533686633,
                40.65410417609495
              ],
              [
                -73.86042178299996,
                40.65461220199989
              ],
              [
                -73.86048308199992,
                40.65465892899991
              ],
              [
                -73.86052767799987,
                40.6547059619999
              ],
              [
                -73.86056716699993,
                40.654747451999874
              ],
              [
                -73.86059601899994,
                40.65477969699992
              ],
              [
                -73.86098917376945,
                40.65520976705256
              ],
              [
                -73.86113574795394,
                40.65523695329108
              ],
              [
                -73.86128376936315,
                40.65525913099787
              ],
              [
                -73.86143294284753,
                40.65527625603836
              ],
              [
                -73.86158297119158,
                40.655288294292085
              ],
              [
                -73.86173355547587,
                40.655295221774
              ],
              [
                -73.8626411734316,
                40.65566753700771
              ],
              [
                -73.86269566402707,
                40.655714414347514
              ],
              [
                -73.86279465682357,
                40.65580682498031
              ],
              [
                -73.8628870998149,
                40.65590309017312
              ],
              [
                -73.86297273468902,
                40.656002940964
              ],
              [
                -73.86305132215273,
                40.6561060983717
              ],
              [
                -73.86312264260036,
                40.65621227417519
              ],
              [
                -73.86318649672684,
                40.656321171718204
              ],
              [
                -73.86319331078552,
                40.65632994764667
              ],
              [
                -73.86319823700312,
                40.65633942771994
              ],
              [
                -73.86320115391976,
                40.656349378199856
              ],
              [
                -73.86320198961536,
                40.6563595537496
              ],
              [
                -73.86320072348435,
                40.65636970348318
              ],
              [
                -73.86319738674325,
                40.65637957715094
              ],
              [
                -73.8631920616611,
                40.6563889313098
              ],
              [
                -73.86318487953122,
                40.656397535325375
              ],
              [
                -73.86317601743468,
                40.65640517705864
              ],
              [
                -73.86316629966998,
                40.65640565962421
              ],
              [
                -73.8631566172485,
                40.656404865552574
              ],
              [
                -73.86314725719119,
                40.656402818383
              ],
              [
                -73.8631384969637,
                40.65639957880072
              ],
              [
                -73.86313059624987,
                40.656395242838435
              ],
              [
                -73.86312378925449,
                40.6563899390293
              ],
              [
                -73.86306966718652,
                40.6563316824709
              ],
              [
                -73.86305788425207,
                40.65633120237215
              ],
              [
                -73.86304615973482,
                40.6563322157678
              ],
              [
                -73.8630348177622,
                40.65633469464217
              ],
              [
                -73.86302417188485,
                40.6563385704663
              ],
              [
                -73.86301451641084,
                40.65634373609222
              ],
              [
                -73.86300611826856,
                40.656350048715026
              ],
              [
                -73.86299920962628,
                40.65635733382082
              ],
              [
                -73.86299398147607,
                40.65636539001104
              ],
              [
                -73.86299057835193,
                40.65637399457031
              ],
              [
                -73.86299992602065,
                40.65639637133702
              ],
              [
                -73.8630130787124,
                40.65641760960362
              ],
              [
                -73.86302980520972,
                40.656437336007194
              ],
              [
                -73.86303700091668,
                40.65644160756877
              ],
              [
                -73.86304319409992,
                40.656446709656294
              ],
              [
                -73.86304822149259,
                40.656452507767035
              ],
              [
                -73.86305195056094,
                40.6564588490495
              ],
              [
                -73.86305428299774,
                40.65646556633301
              ],
              [
                -73.86305515731416,
                40.656472482534596
              ],
              [
                -73.86305455046099,
                40.65647941532709
              ],
              [
                -73.86305247843529,
                40.656486181946185
              ],
              [
                -73.86304899586064,
                40.65649260400802
              ],
              [
                -73.863044194545,
                40.656498512212096
              ],
              [
                -73.86303820106212,
                40.656503750804355
              ],
              [
                -73.86303117341409,
                40.65650818168315
              ],
              [
                -73.86300171715261,
                40.65665375737019
              ],
              [
                -73.863039376544,
                40.656743281612414
              ],
              [
                -73.86309028884169,
                40.656767038074264
              ],
              [
                -73.86313736554554,
                40.65679500870647
              ],
              [
                -73.86318000805555,
                40.65682683785396
              ],
              [
                -73.86321767415188,
                40.6568621207985
              ],
              [
                -73.86324988488909,
                40.656900408905685
              ],
              [
                -73.86327623068586,
                40.656941215328104
              ],
              [
                -73.86327051713553,
                40.65696260378152
              ],
              [
                -73.8632605695737,
                40.656983073457866
              ],
              [
                -73.86324662272614,
                40.657002141341735
              ],
              [
                -73.8632407420788,
                40.65700272691283
              ],
              [
                -73.86323508081833,
                40.6570040728301
              ],
              [
                -73.86322980713008,
                40.6570061391089
              ],
              [
                -73.8632250776853,
                40.65700886436401
              ],
              [
                -73.86322103298691,
                40.65701216763334
              ],
              [
                -73.86321779319539,
                40.65701595078288
              ],
              [
                -73.86321545455876,
                40.657020101422546
              ],
              [
                -73.86321408655384,
                40.65702449624463
              ],
              [
                -73.86321372982172,
                40.65702900468711
              ],
              [
                -73.86321439496035,
                40.6570334928127
              ],
              [
                -73.86321606220991,
                40.65703782728761
              ],
              [
                -73.86321868203981,
                40.65704187934266
              ],
              [
                -73.86315810974206,
                40.65708778410358
              ],
              [
                -73.86315398825552,
                40.65712980133424
              ],
              [
                -73.8631466155115,
                40.65713226123535
              ],
              [
                -73.86313986729341,
                40.657135602778304
              ],
              [
                -73.86313392381267,
                40.657139736727196
              ],
              [
                -73.8631289437907,
                40.65714455268455
              ],
              [
                -73.86312506021913,
                40.657149922040055
              ],
              [
                -73.86312237680909,
                40.65715570140494
              ],
              [
                -73.86312096522205,
                40.657161736440834
              ],
              [
                -73.86312086315435,
                40.657167865981705
              ],
              [
                -73.86312207333215,
                40.657173926337755
              ],
              [
                -73.86312661264536,
                40.65717711344049
              ],
              [
                -73.86313042417935,
                40.65718081242592
              ],
              [
                -73.86313340979943,
                40.65718492805701
              ],
              [
                -73.86313549263542,
                40.65718935436992
              ],
              [
                -73.86313661906057,
                40.657193977401015
              ],
              [
                -73.86313676007325,
                40.657198678122484
              ],
              [
                -73.86313591204231,
                40.65720333550579
              ],
              [
                -73.86313409680184,
                40.65720782963828
              ],
              [
                -73.86313136108826,
                40.65721204481047
              ],
              [
                -73.86312777533716,
                40.65721587249528
              ],
              [
                -73.86312343186994,
                40.65721921414202
              ],
              [
                -73.86311844251696,
                40.657221983714
              ],
              [
                -73.86310970114857,
                40.65722215260465
              ],
              [
                -73.86310103886122,
                40.657221243347905
              ],
              [
                -73.8630926834223,
                40.657219279851915
              ],
              [
                -73.86308485453102,
                40.65721631374502
              ],
              [
                -73.86307775804119,
                40.65721242301864
              ],
              [
                -73.86307158054906,
                40.65720770997594
              ],
              [
                -73.86305024819937,
                40.657218520799205
              ],
              [
                -73.8630314382944,
                40.657231772240266
              ],
              [
                -73.86301562734518,
                40.65724712860226
              ],
              [
                -73.86300321589033,
                40.65726420086451
              ],
              [
                -73.86299451835025,
                40.65728255653709
              ],
              [
                -73.86298975506234,
                40.65730173061768
              ],
              [
                -73.86299489115147,
                40.65731819000545
              ],
              [
                -73.86300267139957,
                40.65733403625385
              ],
              [
                -73.86301297536053,
                40.65734902404363
              ],
              [
                -73.86300184517782,
                40.6573984443731
              ],
              [
                -73.86300981264718,
                40.65741044979138
              ],
              [
                -73.86301524269408,
                40.65742325004282
              ],
              [
                -73.86301800429979,
                40.65743653627954
              ],
              [
                -73.86301803082985,
                40.65744998792771
              ],
              [
                -73.86301532164248,
                40.65746328042214
              ],
              [
                -73.86300994210443,
                40.65747609303757
              ],
              [
                -73.86300202201295,
                40.657488116627256
              ],
              [
                -73.86269778299766,
                40.657618309006146
              ],
              [
                -73.86317083299996,
                40.65827651199994
              ],
              [
                -73.86174758999981,
                40.65893835699988
              ],
              [
                -73.86122244999984,
                40.659220936999894
              ],
              [
                -73.8603597819999,
                40.65964582899991
              ],
              [
                -73.859386918,
                40.6597727149999
              ],
              [
                -73.85851436399994,
                40.65989894199987
              ],
              [
                -73.85761052799985,
                40.660108355999924
              ],
              [
                -73.85792219800001,
                40.66131885599989
              ],
              [
                -73.8580250429998,
                40.661721049999905
              ],
              [
                -73.85811779299999,
                40.66203657099988
              ],
              [
                -73.85842950899992,
                40.66345335999987
              ],
              [
                -73.85753563299991,
                40.66359786299995
              ],
              [
                -73.85659075099987,
                40.66374715999991
              ],
              [
                -73.8561171019999,
                40.663808401999844
              ],
              [
                -73.85568461199988,
                40.66386749199993
              ],
              [
                -73.85610973799986,
                40.66550601099988
              ],
              [
                -73.85618809699999,
                40.66584655499992
              ],
              [
                -73.85646311899993,
                40.66704176299991
              ],
              [
                -73.85684676199999,
                40.668602934999925
              ],
              [
                -73.85722693699994,
                40.67009621199988
              ],
              [
                -73.85724675799997,
                40.67017405099994
              ],
              [
                -73.85726744599997,
                40.670255293999965
              ],
              [
                -73.8572848669999,
                40.67032198899989
              ],
              [
                -73.85730947699993,
                40.67041623299989
              ],
              [
                -73.85763323199998,
                40.67165619399985
              ],
              [
                -73.85855480399997,
                40.67153293399988
              ],
              [
                -73.85947416499985,
                40.67138053199996
              ],
              [
                -73.86038937900001,
                40.67126877499987
              ],
              [
                -73.86077512799977,
                40.67280562199989
              ],
              [
                -73.86107192499996,
                40.674019813999934
              ],
              [
                -73.86106294999989,
                40.67434293499984
              ],
              [
                -73.86176409699988,
                40.67423226099992
              ],
              [
                -73.86310051,
                40.674647212999936
              ],
              [
                -73.86413333499986,
                40.67499725199989
              ],
              [
                -73.86455313199994,
                40.67515112199992
              ],
              [
                -73.86521514199991,
                40.675386573999944
              ],
              [
                -73.86627349499986,
                40.67576404599991
              ],
              [
                -73.86725717699987,
                40.67611268499993
              ],
              [
                -73.86838105899999,
                40.67653059899986
              ],
              [
                -73.86942312899993,
                40.67691222799995
              ],
              [
                -73.86977606799982,
                40.67702720899994
              ],
              [
                -73.870458215,
                40.67722236799989
              ],
              [
                -73.87148141800003,
                40.6775006639999
              ],
              [
                -73.87176417499987,
                40.67757222799988
              ],
              [
                -73.87204099299996,
                40.67765741899996
              ],
              [
                -73.87231018000001,
                40.67775574699989
              ],
              [
                -73.87257015500002,
                40.677866609999896
              ],
              [
                -73.87304845699995,
                40.678194106999904
              ],
              [
                -73.87318521799988,
                40.678330001999946
              ],
              [
                -73.87331183100002,
                40.678471800999894
              ],
              [
                -73.87342767099986,
                40.67861884699991
              ],
              [
                -73.87353219499991,
                40.67877042099988
              ],
              [
                -73.87363895999985,
                40.678944030999894
              ],
              [
                -73.87376075799996,
                40.6791124969999
              ],
              [
                -73.87389692799991,
                40.67927472199993
              ],
              [
                -73.87404657399992,
                40.67942964799988
              ],
              [
                -73.87420866699985,
                40.67957633999987
              ],
              [
                -73.87440161999989,
                40.67971160899988
              ],
              [
                -73.87461126400001,
                40.67983425399998
              ],
              [
                -73.87483538999999,
                40.67994256999988
              ],
              [
                -73.87507136399991,
                40.68003514599991
              ],
              [
                -73.87531627599998,
                40.68011098399988
              ],
              [
                -73.8755670759999,
                40.68016946499994
              ],
              [
                -73.87657495299996,
                40.68016700199996
              ],
              [
                -73.87688955199985,
                40.68016499699994
              ],
              [
                -73.877426862,
                40.68001443699991
              ],
              [
                -73.87838855899997,
                40.67973905399991
              ],
              [
                -73.87862924200002,
                40.67967653299996
              ],
              [
                -73.87926692499995,
                40.67949108499991
              ],
              [
                -73.87974063299994,
                40.679360960999894
              ],
              [
                -73.88015110700003,
                40.679242062999926
              ],
              [
                -73.88061377799993,
                40.67911003199989
              ],
              [
                -73.88063900599983,
                40.679176984999856
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000191917981315,
        "objectid": 77,
        "shape_leng": 0.075461081444,
        "location_id": 77,
        "zone": "East New York/Pennsylvania Avenue",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.8997089949999,
                40.67549425099992
              ],
              [
                -73.89971755899985,
                40.6755642639999
              ],
              [
                -73.89972239699998,
                40.67560373799996
              ],
              [
                -73.89973501099999,
                40.675654870999914
              ],
              [
                -73.89974568900007,
                40.675711963999895
              ],
              [
                -73.9000274839998,
                40.67681911399995
              ],
              [
                -73.90004626199993,
                40.67689308999995
              ],
              [
                -73.90079183699987,
                40.67657245799992
              ],
              [
                -73.90096234799994,
                40.67650221199989
              ],
              [
                -73.90245583099995,
                40.6758820509999
              ],
              [
                -73.90257716399992,
                40.67583170699993
              ],
              [
                -73.90267831799984,
                40.6757897289999
              ],
              [
                -73.90279745999987,
                40.675757492999864
              ],
              [
                -73.90291887299995,
                40.675684580999864
              ],
              [
                -73.90302076399996,
                40.67565648099992
              ],
              [
                -73.90313803899994,
                40.67562221499988
              ],
              [
                -73.90347422300006,
                40.67550668299987
              ],
              [
                -73.90346228899996,
                40.67545791899992
              ],
              [
                -73.90344272399983,
                40.67537791899986
              ],
              [
                -73.9032213189999,
                40.67447291399992
              ],
              [
                -73.90296768999997,
                40.67343939499993
              ],
              [
                -73.90266008399995,
                40.6721971789999
              ],
              [
                -73.90228822599984,
                40.67093600699995
              ],
              [
                -73.90198218099997,
                40.669670070999906
              ],
              [
                -73.90166767899989,
                40.6684135289999
              ],
              [
                -73.90126570400001,
                40.666880649999925
              ],
              [
                -73.9008513569999,
                40.66534589499991
              ],
              [
                -73.90041654099997,
                40.663806526999934
              ],
              [
                -73.90050930099981,
                40.663793574999936
              ],
              [
                -73.90071711999985,
                40.66376455299994
              ],
              [
                -73.90145114700002,
                40.663658364999954
              ],
              [
                -73.90105329800004,
                40.662124820999956
              ],
              [
                -73.90066118699986,
                40.66058615799992
              ],
              [
                -73.900146584,
                40.660653276999874
              ],
              [
                -73.89988908799995,
                40.66063980699986
              ],
              [
                -73.89972880499995,
                40.66070453499987
              ],
              [
                -73.89965233299985,
                40.66073541599994
              ],
              [
                -73.89921760899985,
                40.65911140399995
              ],
              [
                -73.89883571499976,
                40.65752594199996
              ],
              [
                -73.89881834799998,
                40.657453812999925
              ],
              [
                -73.89880698499995,
                40.65740666199987
              ],
              [
                -73.89796594499987,
                40.65753203599994
              ],
              [
                -73.89704215399999,
                40.65766358899994
              ],
              [
                -73.89612587399984,
                40.657814663999915
              ],
              [
                -73.89613759299986,
                40.6578822489999
              ],
              [
                -73.8961675609999,
                40.657978451999924
              ],
              [
                -73.89571848199988,
                40.658159959999864
              ],
              [
                -73.89491381999981,
                40.6585264489999
              ],
              [
                -73.8940984499999,
                40.658877919999945
              ],
              [
                -73.89327894299984,
                40.65923017399993
              ],
              [
                -73.89246408799995,
                40.659580274999925
              ],
              [
                -73.89164919999989,
                40.65993257599996
              ],
              [
                -73.89083184299982,
                40.660283967999895
              ],
              [
                -73.89001492699988,
                40.66063583299995
              ],
              [
                -73.88919997600003,
                40.660988080999914
              ],
              [
                -73.88838556499994,
                40.66133773799988
              ],
              [
                -73.88756863499995,
                40.66168908099994
              ],
              [
                -73.88681373099988,
                40.66201382299992
              ],
              [
                -73.88593573999992,
                40.66239371299988
              ],
              [
                -73.88511751799982,
                40.662745639999876
              ],
              [
                -73.88430189399988,
                40.66309545699991
              ],
              [
                -73.88348677599993,
                40.663447816999906
              ],
              [
                -73.88309163100001,
                40.66361709099991
              ],
              [
                -73.88266944200001,
                40.66379795399993
              ],
              [
                -73.88185557299985,
                40.66414998799994
              ],
              [
                -73.88208825799985,
                40.664464146999926
              ],
              [
                -73.88330883399975,
                40.666112004999896
              ],
              [
                -73.88391264599986,
                40.66585179899994
              ],
              [
                -73.88412170000002,
                40.665760741999854
              ],
              [
                -73.88474247400003,
                40.66549489399995
              ],
              [
                -73.88493743699995,
                40.665407108999936
              ],
              [
                -73.88556364499998,
                40.66514048699993
              ],
              [
                -73.88641344699995,
                40.66478080699991
              ],
              [
                -73.88657767900006,
                40.6647080369999
              ],
              [
                -73.88726131299987,
                40.664413573999866
              ],
              [
                -73.88739217299995,
                40.6643534569999
              ],
              [
                -73.88810817399994,
                40.6640474119999
              ],
              [
                -73.88898470699996,
                40.663916614999884
              ],
              [
                -73.889906696,
                40.66377828999986
              ],
              [
                -73.89029917799994,
                40.66531175899994
              ],
              [
                -73.89069385399985,
                40.66685223399993
              ],
              [
                -73.88976988599985,
                40.66698991499991
              ],
              [
                -73.89016521899985,
                40.668524376999876
              ],
              [
                -73.89055868999999,
                40.67006023099993
              ],
              [
                -73.89087805100002,
                40.671318086999875
              ],
              [
                -73.8917995219999,
                40.67118041299997
              ],
              [
                -73.89271090699992,
                40.67104582899995
              ],
              [
                -73.89302801299988,
                40.672314491999934
              ],
              [
                -73.89335128299989,
                40.673581645999896
              ],
              [
                -73.89367036399987,
                40.6748217709999
              ],
              [
                -73.89459383999989,
                40.674684103999915
              ],
              [
                -73.89549943499999,
                40.67455074399987
              ],
              [
                -73.89650978,
                40.6743970479999
              ],
              [
                -73.89748422799983,
                40.674255484999954
              ],
              [
                -73.89838655899996,
                40.67412124399994
              ],
              [
                -73.89930675399987,
                40.67398204199993
              ],
              [
                -73.8997089949999,
                40.67549425099992
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000191114419551,
        "objectid": 78,
        "shape_leng": 0.0935944495806,
        "location_id": 78,
        "zone": "East Tremont",
        "borough": "Bronx"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.88963328299991,
                40.85317381799995
              ],
              [
                -73.88960978099986,
                40.85362187999994
              ],
              [
                -73.890030396,
                40.85422099199995
              ],
              [
                -73.89038953999983,
                40.85468905799998
              ],
              [
                -73.8906903199999,
                40.854353261999954
              ],
              [
                -73.89090203399996,
                40.854116885999886
              ],
              [
                -73.89216687399981,
                40.85276946199989
              ],
              [
                -73.89307140900002,
                40.8532499869999
              ],
              [
                -73.89431774099977,
                40.851891310999854
              ],
              [
                -73.89478764999993,
                40.85108166699989
              ],
              [
                -73.89407258999995,
                40.85071318899992
              ],
              [
                -73.89494627999981,
                40.84910485299988
              ],
              [
                -73.89557755999984,
                40.847854641999895
              ],
              [
                -73.89611702199986,
                40.84677851899995
              ],
              [
                -73.89617712999998,
                40.8466583209999
              ],
              [
                -73.89535848999982,
                40.84651803199993
              ],
              [
                -73.89433599299984,
                40.84648668099986
              ],
              [
                -73.8940930149999,
                40.846488222999945
              ],
              [
                -73.89384985999999,
                40.84647439399989
              ],
              [
                -73.89360906599997,
                40.84644525599989
              ],
              [
                -73.89337309899987,
                40.84640119399996
              ],
              [
                -73.89290102299996,
                40.84626832999992
              ],
              [
                -73.89398467499994,
                40.844703958999965
              ],
              [
                -73.89464252699995,
                40.843904139999964
              ],
              [
                -73.89479022099985,
                40.84372032199992
              ],
              [
                -73.89481942600001,
                40.84368377099986
              ],
              [
                -73.89484758699984,
                40.84364636299994
              ],
              [
                -73.89537836099996,
                40.842938778999944
              ],
              [
                -73.89527784899985,
                40.84287986099988
              ],
              [
                -73.89509807299986,
                40.84277448099987
              ],
              [
                -73.89501415699996,
                40.84272529099994
              ],
              [
                -73.89493579899987,
                40.84267935899995
              ],
              [
                -73.89484862699986,
                40.84262825999987
              ],
              [
                -73.89410150499995,
                40.84219030199994
              ],
              [
                -73.89317098899986,
                40.841637342999924
              ],
              [
                -73.8921681559998,
                40.84104963999991
              ],
              [
                -73.89017468599997,
                40.839873667999896
              ],
              [
                -73.88941724899988,
                40.83942273399985
              ],
              [
                -73.88869040799986,
                40.83915802599989
              ],
              [
                -73.88813752199992,
                40.83986200199991
              ],
              [
                -73.88754251799996,
                40.83964065799996
              ],
              [
                -73.88659802299989,
                40.83927904999991
              ],
              [
                -73.88618432099993,
                40.84010504499991
              ],
              [
                -73.88583496499986,
                40.83989692699992
              ],
              [
                -73.88469619299984,
                40.83920338699989
              ],
              [
                -73.88464898099988,
                40.83917463299989
              ],
              [
                -73.88435324499996,
                40.83927764999994
              ],
              [
                -73.88418143199976,
                40.839335306999935
              ],
              [
                -73.88412724499999,
                40.83935591899995
              ],
              [
                -73.88403342700002,
                40.839304640999934
              ],
              [
                -73.88351820399988,
                40.8390223659999
              ],
              [
                -73.88313873999988,
                40.838810671999944
              ],
              [
                -73.88274769899992,
                40.8385923409999
              ],
              [
                -73.88279558600004,
                40.838549945999944
              ],
              [
                -73.88285365099989,
                40.83849533499994
              ],
              [
                -73.88246404800005,
                40.83824724099991
              ],
              [
                -73.88235442499995,
                40.83814382399988
              ],
              [
                -73.88225831200006,
                40.83803121799985
              ],
              [
                -73.88217759099985,
                40.83791103699992
              ],
              [
                -73.88211370499987,
                40.83778515499997
              ],
              [
                -73.88206752699983,
                40.83765562499989
              ],
              [
                -73.88203937499988,
                40.83752459299991
              ],
              [
                -73.88200384100001,
                40.837443781999895
              ],
              [
                -73.88191969599985,
                40.83761693299992
              ],
              [
                -73.88182513299996,
                40.837787064999915
              ],
              [
                -73.88172047499994,
                40.83795364899991
              ],
              [
                -73.88169674899984,
                40.83798711299989
              ],
              [
                -73.88166303799993,
                40.83802861899994
              ],
              [
                -73.88160324799993,
                40.83809985899994
              ],
              [
                -73.88154924699987,
                40.838182756999885
              ],
              [
                -73.88138310599982,
                40.83837771399989
              ],
              [
                -73.88135107299986,
                40.83840917199989
              ],
              [
                -73.88113550199986,
                40.83862214199999
              ],
              [
                -73.880965322,
                40.838748079999895
              ],
              [
                -73.88080919100001,
                40.83888681999986
              ],
              [
                -73.88080895400005,
                40.83888703599989
              ],
              [
                -73.88080875199994,
                40.83888725199994
              ],
              [
                -73.88066897399986,
                40.83903757099991
              ],
              [
                -73.88066892599986,
                40.83903762499989
              ],
              [
                -73.88066887899986,
                40.83903768799994
              ],
              [
                -73.88054735399987,
                40.839198110999895
              ],
              [
                -73.88044599399986,
                40.839366353999885
              ],
              [
                -73.88044592299987,
                40.839366479999924
              ],
              [
                -73.88044586500001,
                40.83936659699989
              ],
              [
                -73.88036587199986,
                40.839540434999904
              ],
              [
                -73.88036583800002,
                40.839540506999924
              ],
              [
                -73.88036581299994,
                40.8395405789999
              ],
              [
                -73.88030771499984,
                40.83971779899987
              ],
              [
                -73.88030769199985,
                40.8397178709999
              ],
              [
                -73.88030767899996,
                40.83971794399987
              ],
              [
                -73.88027152599983,
                40.83989623999985
              ],
              [
                -73.88010086899989,
                40.84006661599993
              ],
              [
                -73.880061996,
                40.84016223499986
              ],
              [
                -73.87992249999996,
                40.840154029999916
              ],
              [
                -73.87978300299987,
                40.84014582599986
              ],
              [
                -73.8786598319999,
                40.84001990399986
              ],
              [
                -73.87850461299986,
                40.84000559399992
              ],
              [
                -73.87841924999982,
                40.83999458999992
              ],
              [
                -73.87831009099979,
                40.839989350999915
              ],
              [
                -73.87823856699993,
                40.839985917999904
              ],
              [
                -73.87791380399995,
                40.8399703279999
              ],
              [
                -73.87747229399999,
                40.839983546999925
              ],
              [
                -73.87561285199983,
                40.83990252099989
              ],
              [
                -73.87359713599987,
                40.83979855499992
              ],
              [
                -73.8732671699999,
                40.84021994899995
              ],
              [
                -73.87280724899995,
                40.84086864499993
              ],
              [
                -73.87244734699995,
                40.84134426699993
              ],
              [
                -73.87236365199989,
                40.84147695999991
              ],
              [
                -73.87229921099987,
                40.84157999599992
              ],
              [
                -73.87223024699988,
                40.841643169999934
              ],
              [
                -73.87153346199983,
                40.842514280999886
              ],
              [
                -73.87099518100001,
                40.843111391999926
              ],
              [
                -73.87030135699996,
                40.84354869599991
              ],
              [
                -73.86959555499995,
                40.84398144199994
              ],
              [
                -73.8688925879999,
                40.844409658999936
              ],
              [
                -73.86804200399997,
                40.84481383899988
              ],
              [
                -73.86863721699987,
                40.845309842999924
              ],
              [
                -73.86874186599975,
                40.84560972899992
              ],
              [
                -73.86869009299983,
                40.84576627499992
              ],
              [
                -73.86865877499986,
                40.84592828199994
              ],
              [
                -73.86865873999994,
                40.845928452999885
              ],
              [
                -73.8686587279999,
                40.84592863299988
              ],
              [
                -73.868649152,
                40.846093754999906
              ],
              [
                -73.86864914000002,
                40.84609392599993
              ],
              [
                -73.86864915099987,
                40.84609409699992
              ],
              [
                -73.86866192800001,
                40.846259982999946
              ],
              [
                -73.86866193799993,
                40.846260152999946
              ],
              [
                -73.86866197399985,
                40.84626032399993
              ],
              [
                -73.86869709399987,
                40.84642434499988
              ],
              [
                -73.86869712999989,
                40.846424505999934
              ],
              [
                -73.86869718899982,
                40.84642466899992
              ],
              [
                -73.86875394799989,
                40.846584363999966
              ],
              [
                -73.86875397099986,
                40.84658441799991
              ],
              [
                -73.86875399499986,
                40.8465844709999
              ],
              [
                -73.86883109200001,
                40.84673752699992
              ],
              [
                -73.86892652699994,
                40.84688174099993
              ],
              [
                -73.86843808099991,
                40.84705127899987
              ],
              [
                -73.86835465899983,
                40.84708023299993
              ],
              [
                -73.86800652899981,
                40.848301965999894
              ],
              [
                -73.86785992999984,
                40.84886493099988
              ],
              [
                -73.86837388899988,
                40.848969597999925
              ],
              [
                -73.86920944599997,
                40.84923837199993
              ],
              [
                -73.86941402699996,
                40.848788024999884
              ],
              [
                -73.86889243299994,
                40.8481123219999
              ],
              [
                -73.86944230399996,
                40.84724417999992
              ],
              [
                -73.87047298799992,
                40.84542033999986
              ],
              [
                -73.87109207899995,
                40.84432478099992
              ],
              [
                -73.87179038499981,
                40.84380281399991
              ],
              [
                -73.87184610299998,
                40.84376077799993
              ],
              [
                -73.87190184999997,
                40.84372143299993
              ],
              [
                -73.87280708199992,
                40.843424263999964
              ],
              [
                -73.87399295499982,
                40.84256150599994
              ],
              [
                -73.87456845,
                40.84164271999994
              ],
              [
                -73.87459175399982,
                40.84152064799993
              ],
              [
                -73.87608505099992,
                40.842064826999916
              ],
              [
                -73.87679872099999,
                40.84229978699994
              ],
              [
                -73.87686858199996,
                40.842317105999896
              ],
              [
                -73.87694201699995,
                40.84233562799991
              ],
              [
                -73.87757279999985,
                40.84248377099988
              ],
              [
                -73.878171756,
                40.84262443699987
              ],
              [
                -73.87732924099988,
                40.84398312499995
              ],
              [
                -73.87800756899993,
                40.844484295999926
              ],
              [
                -73.87919408799995,
                40.8453217339999
              ],
              [
                -73.88048011699989,
                40.84619371599996
              ],
              [
                -73.8810605989999,
                40.84659008499992
              ],
              [
                -73.88199829499979,
                40.84723531199989
              ],
              [
                -73.88284445599996,
                40.84781722599997
              ],
              [
                -73.88276104799992,
                40.84805784599992
              ],
              [
                -73.88278133699994,
                40.84807038999989
              ],
              [
                -73.88311982600001,
                40.848221091999925
              ],
              [
                -73.88322877699981,
                40.848345877999876
              ],
              [
                -73.88377755799993,
                40.84868218399993
              ],
              [
                -73.88480193499986,
                40.84930945899987
              ],
              [
                -73.88581418499989,
                40.84993079699989
              ],
              [
                -73.88643555899993,
                40.850309427999875
              ],
              [
                -73.88751770499984,
                40.85094946799989
              ],
              [
                -73.88800539899992,
                40.850976102999944
              ],
              [
                -73.88823974099995,
                40.85103536599996
              ],
              [
                -73.8887824569999,
                40.85134118499993
              ],
              [
                -73.88895256299988,
                40.85144078099994
              ],
              [
                -73.88951837699989,
                40.85180274499989
              ],
              [
                -73.88963044999998,
                40.85188593199995
              ],
              [
                -73.88987871399985,
                40.852070220999956
              ],
              [
                -73.88993961599995,
                40.852590762999924
              ],
              [
                -73.88963328299991,
                40.85317381799995
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.00039956655019,
        "objectid": 81,
        "shape_leng": 0.197592771731,
        "location_id": 81,
        "zone": "Eastchester",
        "borough": "Bronx"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.82366848110328,
                40.889903740147645
              ],
              [
                -73.82391571097276,
                40.88997462522143
              ],
              [
                -73.82520822804308,
                40.890344349924874
              ],
              [
                -73.82722429759038,
                40.89091023377534
              ],
              [
                -73.82796933603909,
                40.89114527505284
              ],
              [
                -73.82866341175486,
                40.89136748494408
              ],
              [
                -73.82890564213675,
                40.891439450168875
              ],
              [
                -73.83007965306355,
                40.89178823291273
              ],
              [
                -73.83095773989488,
                40.89203015895004
              ],
              [
                -73.83184501081413,
                40.89235427900909
              ],
              [
                -73.83267808688781,
                40.892601826167834
              ],
              [
                -73.83356849751318,
                40.89280642617216
              ],
              [
                -73.83525790584999,
                40.89339164402785
              ],
              [
                -73.83630541779426,
                40.893599165441344
              ],
              [
                -73.83737956161106,
                40.893924741304346
              ],
              [
                -73.83645878199995,
                40.89151612599991
              ],
              [
                -73.83554853999995,
                40.88913350399991
              ],
              [
                -73.83643359099989,
                40.888935663999916
              ],
              [
                -73.83732165099995,
                40.88874196399991
              ],
              [
                -73.83822700999995,
                40.88864452499991
              ],
              [
                -73.83920549799991,
                40.88870221699993
              ],
              [
                -73.83939762699993,
                40.88872801299994
              ],
              [
                -73.839593563,
                40.888739532999914
              ],
              [
                -73.83979050699996,
                40.8887364029999
              ],
              [
                -73.83998563399987,
                40.8887186719999
              ],
              [
                -73.84017616799996,
                40.88868679899988
              ],
              [
                -73.84105278599993,
                40.88855346699991
              ],
              [
                -73.84196149599987,
                40.88845596699989
              ],
              [
                -73.84285880200002,
                40.88830687399993
              ],
              [
                -73.84313196799998,
                40.888265078999886
              ],
              [
                -73.8431922509999,
                40.88825585499997
              ],
              [
                -73.84362775199982,
                40.88818922099995
              ],
              [
                -73.84367707000004,
                40.88818167399991
              ],
              [
                -73.84376573899986,
                40.88816810699991
              ],
              [
                -73.844021975,
                40.8881291589999
              ],
              [
                -73.84407903899991,
                40.88812048499994
              ],
              [
                -73.84432835199985,
                40.88808258799987
              ],
              [
                -73.84437965699982,
                40.88807478899986
              ],
              [
                -73.84465398699977,
                40.88803308899988
              ],
              [
                -73.8452555469999,
                40.887943469999875
              ],
              [
                -73.84572953199992,
                40.887871705999935
              ],
              [
                -73.84704957999995,
                40.8876718159999
              ],
              [
                -73.84744396900003,
                40.88697853099993
              ],
              [
                -73.84748708800002,
                40.88691453799988
              ],
              [
                -73.84785777299996,
                40.88636441199988
              ],
              [
                -73.84790388199997,
                40.88629598399993
              ],
              [
                -73.84798702099991,
                40.88617211799988
              ],
              [
                -73.84802165699983,
                40.88612051499994
              ],
              [
                -73.84804918100001,
                40.88607950799993
              ],
              [
                -73.84809415299982,
                40.88601250599988
              ],
              [
                -73.84813585799989,
                40.88595036999992
              ],
              [
                -73.84817178599984,
                40.88589684199993
              ],
              [
                -73.84820835300003,
                40.8858423609999
              ],
              [
                -73.84824941299993,
                40.88578118699991
              ],
              [
                -73.84828534100001,
                40.88572765799992
              ],
              [
                -73.84832190799986,
                40.8856731779999
              ],
              [
                -73.84836417199985,
                40.885610210999886
              ],
              [
                -73.84846274099996,
                40.88546103199993
              ],
              [
                -73.84876385000003,
                40.885005316999894
              ],
              [
                -73.84882044099987,
                40.88491966699994
              ],
              [
                -73.84927742099983,
                40.88424150399993
              ],
              [
                -73.84973488199985,
                40.8835558029999
              ],
              [
                -73.85020149399993,
                40.88286986999993
              ],
              [
                -73.8506512309999,
                40.88219178999987
              ],
              [
                -73.851106036,
                40.88150897099993
              ],
              [
                -73.85156600599989,
                40.88082536399993
              ],
              [
                -73.852032805,
                40.88013997199988
              ],
              [
                -73.85248305800003,
                40.87946370499989
              ],
              [
                -73.85207011699983,
                40.87929765699988
              ],
              [
                -73.85186563799998,
                40.879058864999905
              ],
              [
                -73.85138374099995,
                40.878476222999886
              ],
              [
                -73.85091896099982,
                40.87789344099987
              ],
              [
                -73.85211217399988,
                40.87729787999995
              ],
              [
                -73.85254854099988,
                40.87709158699993
              ],
              [
                -73.85172236099986,
                40.87684337099996
              ],
              [
                -73.85093186799989,
                40.875830013999874
              ],
              [
                -73.85068955699988,
                40.87548667799994
              ],
              [
                -73.85143247999994,
                40.874987270999945
              ],
              [
                -73.85206424599991,
                40.87450364699991
              ],
              [
                -73.85215435899995,
                40.87443419899992
              ],
              [
                -73.85278034299986,
                40.87395322899988
              ],
              [
                -73.85362131899993,
                40.873312086999874
              ],
              [
                -73.85363638799987,
                40.87330059899996
              ],
              [
                -73.85357628499997,
                40.87327602099987
              ],
              [
                -73.85265751899979,
                40.87299218699998
              ],
              [
                -73.85170987299989,
                40.872697458999944
              ],
              [
                -73.85108463399987,
                40.872492790999964
              ],
              [
                -73.85078983299995,
                40.872396291999905
              ],
              [
                -73.84987645199996,
                40.87210188099989
              ],
              [
                -73.84859676099994,
                40.87167078499988
              ],
              [
                -73.84792614099985,
                40.87134223399991
              ],
              [
                -73.84725144800001,
                40.870987051999926
              ],
              [
                -73.84699273699992,
                40.87085288799987
              ],
              [
                -73.84640766399981,
                40.8705543009999
              ],
              [
                -73.84608957099984,
                40.870389771999946
              ],
              [
                -73.84578147499997,
                40.870227695999894
              ],
              [
                -73.845172567,
                40.86991469799994
              ],
              [
                -73.84487737499975,
                40.86976050699997
              ],
              [
                -73.84442133700003,
                40.86952229899991
              ],
              [
                -73.84423146999998,
                40.86942034999993
              ],
              [
                -73.84358581499981,
                40.86908763699991
              ],
              [
                -73.84315715499991,
                40.868863265999934
              ],
              [
                -73.84280443499989,
                40.868680752999886
              ],
              [
                -73.842406294,
                40.86847474399992
              ],
              [
                -73.84185186599998,
                40.868191924999934
              ],
              [
                -73.84122362499986,
                40.86785815099992
              ],
              [
                -73.84064364799995,
                40.86755417499992
              ],
              [
                -73.83974233299986,
                40.8670879309999
              ],
              [
                -73.83954475699979,
                40.86698673799988
              ],
              [
                -73.83922085399998,
                40.866820839999924
              ],
              [
                -73.83875223499997,
                40.86657277699996
              ],
              [
                -73.83784380099986,
                40.86609918799993
              ],
              [
                -73.83697021099982,
                40.86564313599992
              ],
              [
                -73.83678824299999,
                40.86554590599999
              ],
              [
                -73.83618601399989,
                40.86522409899994
              ],
              [
                -73.83578436699997,
                40.86501601099997
              ],
              [
                -73.83162007899985,
                40.8626593749999
              ],
              [
                -73.83089329699999,
                40.86221284899995
              ],
              [
                -73.83012015999996,
                40.862049281999894
              ],
              [
                -73.82972614799992,
                40.86195274499995
              ],
              [
                -73.82950892699994,
                40.86184121299986
              ],
              [
                -73.82924089599999,
                40.86163186799991
              ],
              [
                -73.82911929099997,
                40.861509976999855
              ],
              [
                -73.82841963500002,
                40.86093193999992
              ],
              [
                -73.82834347399992,
                40.860892025999895
              ],
              [
                -73.82840758999997,
                40.860948300999894
              ],
              [
                -73.82954731199996,
                40.86216408699993
              ],
              [
                -73.83022388499991,
                40.863429527999884
              ],
              [
                -73.83054182799988,
                40.86373143499994
              ],
              [
                -73.83175316499981,
                40.8659067679999
              ],
              [
                -73.83312947400005,
                40.86826747399992
              ],
              [
                -73.83315960399985,
                40.86831975599994
              ],
              [
                -73.83347966699989,
                40.86821612199987
              ],
              [
                -73.83354911599997,
                40.868193637999916
              ],
              [
                -73.8339124609999,
                40.868075988999884
              ],
              [
                -73.83481793799996,
                40.867781721999904
              ],
              [
                -73.83565766499987,
                40.86751275599992
              ],
              [
                -73.83655628099997,
                40.86935217599991
              ],
              [
                -73.83740181300006,
                40.87110892899993
              ],
              [
                -73.83829221499984,
                40.872951338999954
              ],
              [
                -73.83746027699992,
                40.87322264999988
              ],
              [
                -73.8383540939998,
                40.87492195799993
              ],
              [
                -73.83924788999988,
                40.87661873799992
              ],
              [
                -73.83841016399981,
                40.87686761499992
              ],
              [
                -73.8381484219999,
                40.876953298999915
              ],
              [
                -73.83855197999986,
                40.87711952799991
              ],
              [
                -73.83925549199988,
                40.87741982299989
              ],
              [
                -73.8395648579999,
                40.87754981599994
              ],
              [
                -73.839711891,
                40.87761159499991
              ],
              [
                -73.8401480179999,
                40.8777884259999
              ],
              [
                -73.84114053199991,
                40.878215735999945
              ],
              [
                -73.84228241899993,
                40.87870197299992
              ],
              [
                -73.84239559299994,
                40.87875039299991
              ],
              [
                -73.84324113799987,
                40.87910891299992
              ],
              [
                -73.84273785199989,
                40.879424902999894
              ],
              [
                -73.84201454899987,
                40.879872259999935
              ],
              [
                -73.84127784699999,
                40.8803175479999
              ],
              [
                -73.84046213299986,
                40.880622867999875
              ],
              [
                -73.83965170899998,
                40.88092595799992
              ],
              [
                -73.83874273699996,
                40.88126596799992
              ],
              [
                -73.83782792499997,
                40.88160813999987
              ],
              [
                -73.8369874379998,
                40.881939082999935
              ],
              [
                -73.83664134299984,
                40.88212002499992
              ],
              [
                -73.83621837399981,
                40.88239301299993
              ],
              [
                -73.83560954999997,
                40.882945278999934
              ],
              [
                -73.83475583399996,
                40.883556293999916
              ],
              [
                -73.83434541999985,
                40.883850019999905
              ],
              [
                -73.83404698799986,
                40.883990319999974
              ],
              [
                -73.83361799799997,
                40.88418563099992
              ],
              [
                -73.8334224639999,
                40.884275288999916
              ],
              [
                -73.83342222600004,
                40.884275395999914
              ],
              [
                -73.8334219759999,
                40.884275494999876
              ],
              [
                -73.83321720199997,
                40.884354316999854
              ],
              [
                -73.8332171189999,
                40.88435435299996
              ],
              [
                -73.83321703599982,
                40.88435437999989
              ],
              [
                -73.83300454299992,
                40.884421634999974
              ],
              [
                -73.83300425799992,
                40.88442172499994
              ],
              [
                -73.83300396199994,
                40.88442179699993
              ],
              [
                -73.83278561299993,
                40.88447683399989
              ],
              [
                -73.83278538799992,
                40.8844768879999
              ],
              [
                -73.83278516199996,
                40.88447693199991
              ],
              [
                -73.83256256300001,
                40.884519400999864
              ],
              [
                -73.83256233799996,
                40.88451944499996
              ],
              [
                -73.83256211099994,
                40.88451947199993
              ],
              [
                -73.83233699199998,
                40.88454919399989
              ],
              [
                -73.83130254299985,
                40.88471142299994
              ],
              [
                -73.83065358999984,
                40.88485293899992
              ],
              [
                -73.83033698199985,
                40.884969491999925
              ],
              [
                -73.82953327299994,
                40.885303333999936
              ],
              [
                -73.82791367899995,
                40.88599054299989
              ],
              [
                -73.82777310599991,
                40.88604701799995
              ],
              [
                -73.82700490399988,
                40.88634614699994
              ],
              [
                -73.82672826599993,
                40.88644534299988
              ],
              [
                -73.82611278899988,
                40.886666035999895
              ],
              [
                -73.82529579999982,
                40.88697637999996
              ],
              [
                -73.824489359,
                40.88742251999986
              ],
              [
                -73.82403093399992,
                40.88769579499992
              ],
              [
                -73.82387140599998,
                40.887783627999966
              ],
              [
                -73.82385099099994,
                40.88776831599996
              ],
              [
                -73.82382288900004,
                40.8877472369999
              ],
              [
                -73.82077408699996,
                40.886710602999884
              ],
              [
                -73.82031167699992,
                40.885609739999914
              ],
              [
                -73.82027432999985,
                40.885550699999925
              ],
              [
                -73.81983275399992,
                40.88566925399989
              ],
              [
                -73.81841703399995,
                40.886036993999916
              ],
              [
                -73.81742638899989,
                40.88629430599987
              ],
              [
                -73.81670778599998,
                40.886480950999946
              ],
              [
                -73.8154787309999,
                40.88668744599995
              ],
              [
                -73.81536464199993,
                40.886701220999896
              ],
              [
                -73.81528345499991,
                40.886711023999936
              ],
              [
                -73.81518316800005,
                40.88671965999993
              ],
              [
                -73.81485665899983,
                40.88674823199989
              ],
              [
                -73.81442762200005,
                40.886771964999895
              ],
              [
                -73.81399748399998,
                40.8867821319999
              ],
              [
                -73.813567347,
                40.886778708999906
              ],
              [
                -73.81209659199986,
                40.88671500999993
              ],
              [
                -73.811980437,
                40.886704338999955
              ],
              [
                -73.81140645199994,
                40.886635586999915
              ],
              [
                -73.81083611999995,
                40.88655115199987
              ],
              [
                -73.81027053199992,
                40.88645124299992
              ],
              [
                -73.8097107929999,
                40.88633607499994
              ],
              [
                -73.80915068099985,
                40.88621447699993
              ],
              [
                -73.8086845939999,
                40.88611329099997
              ],
              [
                -73.80834232399997,
                40.88604431399995
              ],
              [
                -73.80799398199987,
                40.885990830999944
              ],
              [
                -73.80764139499996,
                40.88595325199993
              ],
              [
                -73.80728647099995,
                40.88593181399989
              ],
              [
                -73.80693113299986,
                40.88592660899994
              ],
              [
                -73.80657730199997,
                40.885937524999896
              ],
              [
                -73.80635884999995,
                40.88594565999989
              ],
              [
                -73.80614087499991,
                40.88596540999987
              ],
              [
                -73.80592498899998,
                40.885996717999944
              ],
              [
                -73.80571277199984,
                40.88603936799991
              ],
              [
                -73.80550576700001,
                40.886092948999895
              ],
              [
                -73.80520113699998,
                40.88599995599985
              ],
              [
                -73.803286821,
                40.885415552999916
              ],
              [
                -73.80220388299996,
                40.8851211149999
              ],
              [
                -73.79937520399986,
                40.884351969999976
              ],
              [
                -73.79744825599991,
                40.88384538399988
              ],
              [
                -73.79449254399985,
                40.88306825099997
              ],
              [
                -73.79416555799986,
                40.88299321599987
              ],
              [
                -73.79322870891143,
                40.88282259495222
              ],
              [
                -73.79300652624502,
                40.88335873744268
              ],
              [
                -73.79385527171416,
                40.883643311166146
              ],
              [
                -73.79405517398023,
                40.88369996689048
              ],
              [
                -73.7948690107813,
                40.88391531791826
              ],
              [
                -73.79628181490725,
                40.8842908840126
              ],
              [
                -73.79732759309121,
                40.88454106108391
              ],
              [
                -73.79772783123815,
                40.88464812978282
              ],
              [
                -73.79930206256022,
                40.88506905492872
              ],
              [
                -73.80192449924954,
                40.885712968012086
              ],
              [
                -73.80290946460593,
                40.886030680167714
              ],
              [
                -73.8042376171258,
                40.88634168194172
              ],
              [
                -73.80452614974675,
                40.88640924817111
              ],
              [
                -73.80483870751023,
                40.886473964049
              ],
              [
                -73.8063796861458,
                40.886829379091225
              ],
              [
                -73.80755944196362,
                40.88710889180044
              ],
              [
                -73.80872106090143,
                40.887480598188404
              ],
              [
                -73.80943273224275,
                40.88763840646659
              ],
              [
                -73.80989822392435,
                40.88774054200348
              ],
              [
                -73.8112683481647,
                40.88809890623766
              ],
              [
                -73.8124290411048,
                40.888379211890204
              ],
              [
                -73.81365108415028,
                40.88867845028846
              ],
              [
                -73.81516854879645,
                40.8890377621297
              ],
              [
                -73.81524197088714,
                40.889060148794826
              ],
              [
                -73.81533047581053,
                40.88908250586605
              ],
              [
                -73.81574261419215,
                40.88919733695206
              ],
              [
                -73.81601699496034,
                40.88932383392149
              ],
              [
                -73.81661400923757,
                40.88943342775268
              ],
              [
                -73.8176062682385,
                40.8896770238968
              ],
              [
                -73.81827738067017,
                40.88984713076362
              ],
              [
                -73.8194065171428,
                40.89026043413719
              ],
              [
                -73.8194872442203,
                40.890289980180114
              ],
              [
                -73.81957464105017,
                40.89031509623035
              ],
              [
                -73.82061223573477,
                40.89061328894856
              ],
              [
                -73.8228533505322,
                40.8912068290476
              ],
              [
                -73.8233660128753,
                40.89107513585315
              ],
              [
                -73.823264898565,
                40.88998702103
              ],
              [
                -73.82248620406577,
                40.890098054202106
              ],
              [
                -73.82267708790054,
                40.88961946903566
              ],
              [
                -73.82366848110328,
                40.889903740147645
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0000672050210692,
        "objectid": 87,
        "shape_leng": 0.0369015496117,
        "location_id": 87,
        "zone": "Financial District North",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -74.00372234899996,
                40.70693234399989
              ],
              [
                -74.00392127499991,
                40.70723974499993
              ],
              [
                -74.00405660199985,
                40.707436780999934
              ],
              [
                -74.00432857299997,
                40.70782349599988
              ],
              [
                -74.00478782699994,
                40.708401892999895
              ],
              [
                -74.00505672899992,
                40.708788768999995
              ],
              [
                -74.0052621729999,
                40.70892071099991
              ],
              [
                -74.00551253,
                40.709093407999866
              ],
              [
                -74.00643549199992,
                40.7095573209999
              ],
              [
                -74.00705335899995,
                40.70987195099989
              ],
              [
                -74.00774466599994,
                40.71022077299992
              ],
              [
                -74.00906264799988,
                40.71088670399993
              ],
              [
                -74.00947357899994,
                40.71041254299995
              ],
              [
                -74.00954277299994,
                40.71032749299993
              ],
              [
                -74.00998739199997,
                40.70980567299989
              ],
              [
                -74.01054246499999,
                40.70915414799992
              ],
              [
                -74.01084638199993,
                40.708797544999925
              ],
              [
                -74.0110119849999,
                40.70859402199989
              ],
              [
                -74.01118228399991,
                40.7083855739999
              ],
              [
                -74.01124834999997,
                40.708304700999854
              ],
              [
                -74.01170249899995,
                40.70775571299986
              ],
              [
                -74.01194691399999,
                40.707451531999915
              ],
              [
                -74.01250773999998,
                40.706767065999934
              ],
              [
                -74.01240001199992,
                40.70678480799992
              ],
              [
                -74.01187330299993,
                40.706551406999864
              ],
              [
                -74.01118994199994,
                40.706278727999916
              ],
              [
                -74.0098658089999,
                40.70579642699993
              ],
              [
                -74.00999520599994,
                40.70533123899991
              ],
              [
                -74.00998418599995,
                40.70514773299991
              ],
              [
                -74.00974639299996,
                40.704775247999905
              ],
              [
                -74.00945463899993,
                40.70452785699992
              ],
              [
                -74.00907940599997,
                40.70416904299988
              ],
              [
                -74.00898541299989,
                40.704079159999864
              ],
              [
                -74.00854172499996,
                40.70369761199989
              ],
              [
                -74.00797948599994,
                40.703188755999875
              ],
              [
                -74.00785483099992,
                40.7031077899999
              ],
              [
                -74.00782878499996,
                40.703086421999934
              ],
              [
                -74.0077967509999,
                40.70306351499989
              ],
              [
                -74.00763657899991,
                40.70300436799989
              ],
              [
                -74.00760498189766,
                40.70299314132919
              ],
              [
                -74.00752714979265,
                40.70311498141609
              ],
              [
                -74.007519052838,
                40.703121820237115
              ],
              [
                -74.00722046663674,
                40.7033019773033
              ],
              [
                -74.00699466002446,
                40.70344118856669
              ],
              [
                -74.00678502191047,
                40.70356812790527
              ],
              [
                -74.0067554158402,
                40.703541489476805
              ],
              [
                -74.00655720878507,
                40.70336314833331
              ],
              [
                -74.00654880812648,
                40.70336761508583
              ],
              [
                -74.00649425191104,
                40.70330054817178
              ],
              [
                -74.00636372730409,
                40.70318779225254
              ],
              [
                -74.00623870508527,
                40.70307978930158
              ],
              [
                -74.005984303395,
                40.70286001804111
              ],
              [
                -74.00567522249852,
                40.70258407270014
              ],
              [
                -74.0055577199881,
                40.702664394651975
              ],
              [
                -74.0055354613817,
                40.70264552110693
              ],
              [
                -74.00558909594902,
                40.70261273059688
              ],
              [
                -74.00558509547798,
                40.702604050202375
              ],
              [
                -74.00559194611431,
                40.702598835921144
              ],
              [
                -74.00559022977765,
                40.702585814283836
              ],
              [
                -74.00559935100472,
                40.702580167839244
              ],
              [
                -74.0055947948583,
                40.70256802032948
              ],
              [
                -74.00560905264517,
                40.70255281855374
              ],
              [
                -74.00560163196401,
                40.70253805976986
              ],
              [
                -74.00561361797617,
                40.70253024203234
              ],
              [
                -74.00550166067457,
                40.70243086779703
              ],
              [
                -74.00549427805105,
                40.70243138195556
              ],
              [
                -74.00530412596079,
                40.70255986563306
              ],
              [
                -74.00542728826254,
                40.70266543619403
              ],
              [
                -74.00543599763346,
                40.70265955541134
              ],
              [
                -74.00547130861277,
                40.70268982908357
              ],
              [
                -74.00550531272066,
                40.70266684658551
              ],
              [
                -74.00552682615245,
                40.70268528761711
              ],
              [
                -74.0054838408244,
                40.70271432213405
              ],
              [
                -74.00588747266426,
                40.70307651522389
              ],
              [
                -74.00591217944537,
                40.70309876190139
              ],
              [
                -74.00632434161025,
                40.70346987449464
              ],
              [
                -74.00634518899977,
                40.70348864644175
              ],
              [
                -74.00659591913875,
                40.703685902451845
              ],
              [
                -74.00643225275432,
                40.703790361046245
              ],
              [
                -74.00634162385867,
                40.70384853368275
              ],
              [
                -74.00614010577931,
                40.70397866027172
              ],
              [
                -74.00601862724882,
                40.70387315289086
              ],
              [
                -74.00589012878147,
                40.70395873810608
              ],
              [
                -74.00566330640648,
                40.7041042444782
              ],
              [
                -74.00563775955965,
                40.70410583053918
              ],
              [
                -74.00560507496257,
                40.704084966963464
              ],
              [
                -74.00559242542961,
                40.70408256373169
              ],
              [
                -74.00538791847218,
                40.703949405145195
              ],
              [
                -74.0053963553808,
                40.703942183324386
              ],
              [
                -74.00535945878063,
                40.70391972568758
              ],
              [
                -74.00534891537728,
                40.70392373409765
              ],
              [
                -74.00527929789541,
                40.703875218096265
              ],
              [
                -74.00520771937106,
                40.70382133725155
              ],
              [
                -74.0051030445178,
                40.703731143473874
              ],
              [
                -74.0050337744232,
                40.7036749205554
              ],
              [
                -74.00498836312711,
                40.703637436151816
              ],
              [
                -74.00483652285266,
                40.70351292639169
              ],
              [
                -74.0048022288324,
                40.703484006775255
              ],
              [
                -74.00474855169037,
                40.703419564148824
              ],
              [
                -74.00467686257008,
                40.70334817404076
              ],
              [
                -74.00466632025643,
                40.70334817446862
              ],
              [
                -74.00465050979112,
                40.70332972316352
              ],
              [
                -74.00462626412609,
                40.703314479168114
              ],
              [
                -74.00462520935534,
                40.70330003518527
              ],
              [
                -74.00448447402036,
                40.70317690392736
              ],
              [
                -74.00444124738806,
                40.7031375983676
              ],
              [
                -74.00441805325255,
                40.703116735258334
              ],
              [
                -74.00438643514063,
                40.703088667959605
              ],
              [
                -74.00436534740585,
                40.70307021681555
              ],
              [
                -74.00434743166828,
                40.70304694729882
              ],
              [
                -74.00432634521925,
                40.703044544245884
              ],
              [
                -74.00429788566103,
                40.70301726836962
              ],
              [
                -74.004272587521,
                40.703015666411495
              ],
              [
                -74.00411763228837,
                40.70311193702744
              ],
              [
                -74.00420723493835,
                40.70319937375785
              ],
              [
                -74.00416265161378,
                40.703227768889136
              ],
              [
                -74.0041574215416,
                40.703231632087295
              ],
              [
                -74.0041813014302,
                40.703250361430186
              ],
              [
                -74.00418492090171,
                40.703248066176315
              ],
              [
                -74.0053502722475,
                40.704310079961495
              ],
              [
                -74.00526035829189,
                40.704374488410075
              ],
              [
                -74.00521160900396,
                40.70440553223528
              ],
              [
                -74.00513867768034,
                40.70433455643975
              ],
              [
                -74.00516296842157,
                40.7043200932367
              ],
              [
                -74.00500393507072,
                40.704165312544475
              ],
              [
                -74.00492580498366,
                40.70421180838466
              ],
              [
                -74.00508460380267,
                40.70436635582887
              ],
              [
                -74.00511107204132,
                40.704350605261986
              ],
              [
                -74.00518805067318,
                40.70442053569055
              ],
              [
                -74.00492037826484,
                40.70459100497329
              ],
              [
                -74.00484062824778,
                40.704639779526524
              ],
              [
                -74.00480628900739,
                40.704642069051246
              ],
              [
                -74.00396975693849,
                40.70388164511805
              ],
              [
                -74.00371987043046,
                40.70365448194469
              ],
              [
                -74.00351016434425,
                40.70378808825903
              ],
              [
                -74.00349632042315,
                40.70379676926777
              ],
              [
                -74.00459003883661,
                40.70478775294496
              ],
              [
                -74.00458622952459,
                40.70479945067928
              ],
              [
                -74.00426687037424,
                40.70501166020209
              ],
              [
                -74.00438344417219,
                40.7051132598548
              ],
              [
                -74.00394758957431,
                40.705402871900304
              ],
              [
                -74.0038332753225,
                40.705303243026385
              ],
              [
                -74.00364446183858,
                40.7054216846388
              ],
              [
                -74.00362197523796,
                40.70539889998434
              ],
              [
                -74.00364968772803,
                40.705377950512094
              ],
              [
                -74.00358383172679,
                40.70530161130755
              ],
              [
                -74.00297246475286,
                40.70476649244255
              ],
              [
                -74.00287843069457,
                40.704831357989754
              ],
              [
                -74.00355934714837,
                40.70543833815811
              ],
              [
                -74.00360802644228,
                40.70540778137037
              ],
              [
                -74.00363187169316,
                40.70542980675177
              ],
              [
                -74.00358262337747,
                40.70546112883118
              ],
              [
                -74.00332307270776,
                40.70562859522001
              ],
              [
                -74.00238670740669,
                40.70479566367449
              ],
              [
                -74.00239832748949,
                40.70479492514084
              ],
              [
                -74.00248790148628,
                40.70473376109163
              ],
              [
                -74.00248839829254,
                40.70472677235117
              ],
              [
                -74.00243071075859,
                40.704677858004395
              ],
              [
                -74.00242059318661,
                40.70468033460376
              ],
              [
                -74.00233393075767,
                40.70474260740424
              ],
              [
                -74.00233344621154,
                40.70474813530699
              ],
              [
                -74.00218562331561,
                40.70460701887393
              ],
              [
                -74.00217184941894,
                40.70460659646368
              ],
              [
                -74.00197014837809,
                40.70473115920231
              ],
              [
                -74.00197070397937,
                40.7047378684872
              ],
              [
                -74.00238385458334,
                40.705101270250836
              ],
              [
                -74.00241573476919,
                40.705129311282505
              ],
              [
                -74.00236285311735,
                40.705158966692586
              ],
              [
                -74.00268512768783,
                40.70544924811862
              ],
              [
                -74.00256965079724,
                40.705523579761746
              ],
              [
                -74.00295417200002,
                40.706027114999934
              ],
              [
                -74.00299474599991,
                40.70608024599991
              ],
              [
                -74.00302615299988,
                40.70610867499988
              ],
              [
                -74.00306957,
                40.70614280299989
              ],
              [
                -74.00316110499998,
                40.70623201399992
              ],
              [
                -74.00360537999994,
                40.70678059299992
              ],
              [
                -74.00372234899996,
                40.70693234399989
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000323601079994,
        "objectid": 82,
        "shape_leng": 0.119875649697,
        "location_id": 82,
        "zone": "Elmhurst",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.86768504899995,
                40.744071717999944
              ],
              [
                -73.8678269109999,
                40.74462945699988
              ],
              [
                -73.86785097499994,
                40.74471654499991
              ],
              [
                -73.86787595099983,
                40.7448153059999
              ],
              [
                -73.86790963899986,
                40.7449337159999
              ],
              [
                -73.86808574299982,
                40.74560058299989
              ],
              [
                -73.86826502599992,
                40.74631422299994
              ],
              [
                -73.86843750999991,
                40.74703119699994
              ],
              [
                -73.86852925199995,
                40.747588301999954
              ],
              [
                -73.8689139579999,
                40.748368876999955
              ],
              [
                -73.86919188099995,
                40.74876289399987
              ],
              [
                -73.86942458599985,
                40.74915685199993
              ],
              [
                -73.87037881299995,
                40.74906407799991
              ],
              [
                -73.871356485,
                40.74895695099991
              ],
              [
                -73.87184095900001,
                40.7489055769999
              ],
              [
                -73.87237256399987,
                40.74885155099986
              ],
              [
                -73.87277356899986,
                40.74881295799993
              ],
              [
                -73.87330310099995,
                40.74875812499991
              ],
              [
                -73.8737009829999,
                40.748717318999866
              ],
              [
                -73.87398293699992,
                40.74868566899991
              ],
              [
                -73.87462946399991,
                40.74861392799995
              ],
              [
                -73.87543236199986,
                40.74852735999987
              ],
              [
                -73.8755564309999,
                40.74851360799994
              ],
              [
                -73.87625180199986,
                40.748438849999914
              ],
              [
                -73.87647952999995,
                40.74841640199986
              ],
              [
                -73.87692458299988,
                40.74836898899989
              ],
              [
                -73.87741612499993,
                40.74831937399995
              ],
              [
                -73.87834312599989,
                40.74822265999992
              ],
              [
                -73.87845779299981,
                40.74821481099991
              ],
              [
                -73.87927779999994,
                40.748124092999944
              ],
              [
                -73.87983894900005,
                40.748061724999936
              ],
              [
                -73.88020647999986,
                40.748024979999926
              ],
              [
                -73.88113747599988,
                40.74792729299991
              ],
              [
                -73.88207112100002,
                40.747829926999884
              ],
              [
                -73.88256876999999,
                40.747777844999874
              ],
              [
                -73.8829991829999,
                40.747734401999914
              ],
              [
                -73.88393579499991,
                40.74763024299991
              ],
              [
                -73.88485538499994,
                40.747534811999856
              ],
              [
                -73.88578740499986,
                40.74743975399987
              ],
              [
                -73.88671656299988,
                40.747341462999906
              ],
              [
                -73.88764663699996,
                40.74724483899995
              ],
              [
                -73.88778326899983,
                40.74722892599994
              ],
              [
                -73.88867766199992,
                40.74713427699989
              ],
              [
                -73.88957128399994,
                40.747039053999906
              ],
              [
                -73.89050129199978,
                40.74694193899997
              ],
              [
                -73.89143128599981,
                40.74684473599993
              ],
              [
                -73.8917504879999,
                40.7468117269999
              ],
              [
                -73.89139145499985,
                40.746641003999855
              ],
              [
                -73.8911102629999,
                40.74520271899995
              ],
              [
                -73.89071647499993,
                40.74312778399993
              ],
              [
                -73.88983831799999,
                40.74325444899993
              ],
              [
                -73.88979159800003,
                40.743261185999884
              ],
              [
                -73.88974416799981,
                40.74326829099993
              ],
              [
                -73.88886955199995,
                40.74339931999985
              ],
              [
                -73.88798650799986,
                40.74354819199993
              ],
              [
                -73.887511321,
                40.74108252699992
              ],
              [
                -73.88666826999996,
                40.741178144999914
              ],
              [
                -73.88661927199986,
                40.741181715999865
              ],
              [
                -73.88572588199987,
                40.74128870599988
              ],
              [
                -73.88559037099996,
                40.74057655799991
              ],
              [
                -73.88551225099984,
                40.74016501899989
              ],
              [
                -73.88540705199999,
                40.740196431999934
              ],
              [
                -73.88461489999989,
                40.7404329349999
              ],
              [
                -73.88416972299986,
                40.74057237299994
              ],
              [
                -73.88333435099989,
                40.740834026999906
              ],
              [
                -73.88325318299987,
                40.74085945599988
              ],
              [
                -73.88282912199988,
                40.740992282999876
              ],
              [
                -73.882942868,
                40.74160954399985
              ],
              [
                -73.88186399099997,
                40.74173456799989
              ],
              [
                -73.88100387999987,
                40.74184066199995
              ],
              [
                -73.88027765499996,
                40.74129746199992
              ],
              [
                -73.88011277199999,
                40.74116823899993
              ],
              [
                -73.87999120499994,
                40.741070261999894
              ],
              [
                -73.879319796,
                40.7406020799999
              ],
              [
                -73.87910739799986,
                40.740445077999894
              ],
              [
                -73.87863239399987,
                40.74005996299993
              ],
              [
                -73.87848682299996,
                40.73993390899992
              ],
              [
                -73.87791831799989,
                40.73939495699988
              ],
              [
                -73.87743058400002,
                40.73860051599991
              ],
              [
                -73.87797778399988,
                40.73849579099993
              ],
              [
                -73.87823490600005,
                40.73844453599987
              ],
              [
                -73.87927094299985,
                40.73825836599989
              ],
              [
                -73.8793568999999,
                40.7382222559999
              ],
              [
                -73.87945154899991,
                40.73819811399992
              ],
              [
                -73.87955038800004,
                40.738187437999905
              ],
              [
                -73.87964850399992,
                40.738190439999904
              ],
              [
                -73.88128967299998,
                40.73789514699993
              ],
              [
                -73.88178451799989,
                40.73780702699985
              ],
              [
                -73.88213493800001,
                40.737739839999946
              ],
              [
                -73.88230252700001,
                40.737707700999934
              ],
              [
                -73.88203267999987,
                40.73764290299994
              ],
              [
                -73.881509822,
                40.73753989799994
              ],
              [
                -73.88051819099995,
                40.73736701099992
              ],
              [
                -73.88033059399994,
                40.737333676999896
              ],
              [
                -73.88033854099989,
                40.73726569699992
              ],
              [
                -73.88035425599993,
                40.73713112399987
              ],
              [
                -73.88051536899988,
                40.73575217199992
              ],
              [
                -73.88066188999987,
                40.73570787399995
              ],
              [
                -73.88056928399986,
                40.735644932999904
              ],
              [
                -73.88015913099983,
                40.73532715699989
              ],
              [
                -73.87955435499998,
                40.734853488999924
              ],
              [
                -73.87931157699985,
                40.73466211999989
              ],
              [
                -73.87895373100004,
                40.734380046999966
              ],
              [
                -73.87834921099993,
                40.73390580499988
              ],
              [
                -73.87771964099996,
                40.73341722599988
              ],
              [
                -73.87708276299998,
                40.73290931699992
              ],
              [
                -73.87822455999998,
                40.73210226899988
              ],
              [
                -73.87725122499988,
                40.73179415299991
              ],
              [
                -73.87752195799992,
                40.73159316899993
              ],
              [
                -73.87844215099987,
                40.73192119199992
              ],
              [
                -73.87932144399987,
                40.73220754099992
              ],
              [
                -73.8802939189999,
                40.73256164499992
              ],
              [
                -73.8810869159999,
                40.73293562999988
              ],
              [
                -73.88182222699987,
                40.73320039799993
              ],
              [
                -73.88268121899998,
                40.7335112399999
              ],
              [
                -73.883529104,
                40.733848278999844
              ],
              [
                -73.88381086999975,
                40.73355953599985
              ],
              [
                -73.88469091999995,
                40.73388646899986
              ],
              [
                -73.88484227299995,
                40.73393361599995
              ],
              [
                -73.88484243799992,
                40.733933669999935
              ],
              [
                -73.88484260399989,
                40.733933706999906
              ],
              [
                -73.88500007199991,
                40.733970027999874
              ],
              [
                -73.88500017899989,
                40.73397005699992
              ],
              [
                -73.88500028499989,
                40.73397007399993
              ],
              [
                -73.885162107,
                40.73399498199993
              ],
              [
                -73.88532593599987,
                40.73400811199988
              ],
              [
                -73.88532603099999,
                40.73400812199985
              ],
              [
                -73.88532612599988,
                40.734008121999885
              ],
              [
                -73.88548975099985,
                40.73400945499987
              ],
              [
                -73.88598467599994,
                40.7340411219999
              ],
              [
                -73.88639651899997,
                40.73406746299985
              ],
              [
                -73.88689197799985,
                40.734099162999904
              ],
              [
                -73.88718676699979,
                40.7341589399999
              ],
              [
                -73.88751426599988,
                40.734225348999864
              ],
              [
                -73.88770199299982,
                40.7342913229999
              ],
              [
                -73.8885228599999,
                40.734531159999925
              ],
              [
                -73.88847908999993,
                40.73415815399989
              ],
              [
                -73.88844349199991,
                40.733854755999864
              ],
              [
                -73.88840065599999,
                40.73344799199985
              ],
              [
                -73.88837510099995,
                40.733205358999925
              ],
              [
                -73.8883492629999,
                40.73294143199996
              ],
              [
                -73.88833156099989,
                40.73276054799993
              ],
              [
                -73.88830747899983,
                40.73251463099991
              ],
              [
                -73.88828525999989,
                40.73226053999988
              ],
              [
                -73.88825349299994,
                40.73189716199991
              ],
              [
                -73.88817834099999,
                40.7311947819999
              ],
              [
                -73.88811223499987,
                40.73059071099994
              ],
              [
                -73.88805975199999,
                40.73008722999991
              ],
              [
                -73.8875596219999,
                40.730197055999916
              ],
              [
                -73.8874512889999,
                40.729885994999904
              ],
              [
                -73.88745122999997,
                40.72988582299991
              ],
              [
                -73.88745118199992,
                40.72988564399986
              ],
              [
                -73.88736094499983,
                40.72957082699987
              ],
              [
                -73.88736093299984,
                40.72957078299989
              ],
              [
                -73.887360922,
                40.72957073799995
              ],
              [
                -73.88728902599995,
                40.72925284999991
              ],
              [
                -73.88723573099986,
                40.72893296499995
              ],
              [
                -73.88720114099984,
                40.728612069999926
              ],
              [
                -73.88719765199994,
                40.7281837119999
              ],
              [
                -73.88720051699987,
                40.72809239399991
              ],
              [
                -73.88720430099983,
                40.728010829999924
              ],
              [
                -73.88720482599992,
                40.72786882899991
              ],
              [
                -73.88720524999994,
                40.727792996999874
              ],
              [
                -73.88689134399985,
                40.727917365999915
              ],
              [
                -73.88672984899989,
                40.7279731279999
              ],
              [
                -73.88661323299988,
                40.728013392999884
              ],
              [
                -73.8865683549999,
                40.728028890999944
              ],
              [
                -73.88623765899992,
                40.72812701699997
              ],
              [
                -73.88590069999995,
                40.72821132299992
              ],
              [
                -73.88559330999979,
                40.728274440999954
              ],
              [
                -73.88500729499985,
                40.72837189499992
              ],
              [
                -73.88444917100003,
                40.728419126999945
              ],
              [
                -73.88391980299993,
                40.728463912999885
              ],
              [
                -73.88387642499987,
                40.7284675829999
              ],
              [
                -73.8835332119999,
                40.72849744799988
              ],
              [
                -73.88297543399985,
                40.728540950999914
              ],
              [
                -73.88208519900004,
                40.728610374999924
              ],
              [
                -73.88172301799989,
                40.728635253999876
              ],
              [
                -73.88130917699985,
                40.72868082699992
              ],
              [
                -73.88089793699997,
                40.7287413119999
              ],
              [
                -73.88088484699995,
                40.728743729999906
              ],
              [
                -73.88049068199993,
                40.72881654199995
              ],
              [
                -73.88008877499988,
                40.728906274999936
              ],
              [
                -73.87969354099992,
                40.729010158999884
              ],
              [
                -73.87847478499992,
                40.72946892199991
              ],
              [
                -73.87836629199992,
                40.72951588699991
              ],
              [
                -73.87789402699987,
                40.729715930999944
              ],
              [
                -73.87699724199997,
                40.73004835299989
              ],
              [
                -73.87628302399997,
                40.730313093999946
              ],
              [
                -73.8753864729999,
                40.73059067299993
              ],
              [
                -73.87509154199992,
                40.73067136299993
              ],
              [
                -73.87481299,
                40.73075339399985
              ],
              [
                -73.87415476600002,
                40.73095008599993
              ],
              [
                -73.87315527199992,
                40.731243969999866
              ],
              [
                -73.87192058399992,
                40.731613928999884
              ],
              [
                -73.87149800699996,
                40.73175058699995
              ],
              [
                -73.87134309799984,
                40.7317993569999
              ],
              [
                -73.87128940500001,
                40.7318162619999
              ],
              [
                -73.87112231399985,
                40.73187152899993
              ],
              [
                -73.87054725300004,
                40.732037017999936
              ],
              [
                -73.86955585299982,
                40.732352842999894
              ],
              [
                -73.8690483869999,
                40.73251391799993
              ],
              [
                -73.8686094929999,
                40.73265317699983
              ],
              [
                -73.86845203499989,
                40.7327133759999
              ],
              [
                -73.86835853299982,
                40.73274015899988
              ],
              [
                -73.8683180679999,
                40.73275361999993
              ],
              [
                -73.86827761499993,
                40.732767071999874
              ],
              [
                -73.86810582299985,
                40.73282625499992
              ],
              [
                -73.86792586499988,
                40.73287256899993
              ],
              [
                -73.86682801299989,
                40.733245779999926
              ],
              [
                -73.8661043939999,
                40.733491762999904
              ],
              [
                -73.86524772599986,
                40.733762248999945
              ],
              [
                -73.86491748899991,
                40.73386650999988
              ],
              [
                -73.86447086199989,
                40.73400727399998
              ],
              [
                -73.86450230199999,
                40.73407539899988
              ],
              [
                -73.864572486,
                40.734188397999965
              ],
              [
                -73.86462193199988,
                40.73428275699996
              ],
              [
                -73.8648150539999,
                40.73466021499993
              ],
              [
                -73.86515865399998,
                40.73532501399992
              ],
              [
                -73.86550061599992,
                40.73598933199985
              ],
              [
                -73.86582955999995,
                40.736643223999906
              ],
              [
                -73.86609463699996,
                40.73725142999992
              ],
              [
                -73.8664801159999,
                40.73821096399996
              ],
              [
                -73.86676012199987,
                40.73889036999985
              ],
              [
                -73.86703973799995,
                40.73957218999992
              ],
              [
                -73.867146698,
                40.73983722399993
              ],
              [
                -73.86731779499995,
                40.74026134499993
              ],
              [
                -73.86756253399983,
                40.740941888999956
              ],
              [
                -73.8675572959999,
                40.741677696999886
              ],
              [
                -73.8674716919999,
                40.74209572299991
              ],
              [
                -73.86749135099993,
                40.74330204699994
              ],
              [
                -73.86768504899995,
                40.744071717999944
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000217463718718,
        "objectid": 83,
        "shape_leng": 0.105984933269,
        "location_id": 83,
        "zone": "Elmhurst/Maspeth",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.89111026199994,
                40.745202717999874
              ],
              [
                -73.89139145499985,
                40.746641003999855
              ],
              [
                -73.8917504879999,
                40.7468117269999
              ],
              [
                -73.89240906700005,
                40.74713532799989
              ],
              [
                -73.89379708799999,
                40.74769149999987
              ],
              [
                -73.89521297399996,
                40.74829055299993
              ],
              [
                -73.89560209500003,
                40.74844971999991
              ],
              [
                -73.89583955299997,
                40.74854685699991
              ],
              [
                -73.89614389199978,
                40.74867878599984
              ],
              [
                -73.89624245599988,
                40.74871954499985
              ],
              [
                -73.89610755799994,
                40.748500847999885
              ],
              [
                -73.89583033399997,
                40.74801363999996
              ],
              [
                -73.89566359199998,
                40.747695496999896
              ],
              [
                -73.89535589999988,
                40.747108412999864
              ],
              [
                -73.89502251499994,
                40.74647229299989
              ],
              [
                -73.89496338799981,
                40.746372146999896
              ],
              [
                -73.89490427299994,
                40.74627199299995
              ],
              [
                -73.89480782,
                40.746062464999966
              ],
              [
                -73.89473484699982,
                40.745845798999945
              ],
              [
                -73.8946865809999,
                40.74562431999988
              ],
              [
                -73.89466369299986,
                40.74540049699995
              ],
              [
                -73.89466630799988,
                40.74517683999989
              ],
              [
                -73.894693961,
                40.74495583499989
              ],
              [
                -73.89474567599989,
                40.74473983299987
              ],
              [
                -73.89478949299988,
                40.74458478799992
              ],
              [
                -73.89484503999992,
                40.74443166199988
              ],
              [
                -73.8949120899999,
                40.74428123099997
              ],
              [
                -73.89499026399977,
                40.74413421299992
              ],
              [
                -73.89524819599988,
                40.74381972999992
              ],
              [
                -73.89540662199998,
                40.74365743999987
              ],
              [
                -73.89557809299986,
                40.74350275399994
              ],
              [
                -73.89576162799989,
                40.74335656899993
              ],
              [
                -73.8963812929998,
                40.74284491999988
              ],
              [
                -73.89706891399995,
                40.74228451999994
              ],
              [
                -73.897207419,
                40.74217393599989
              ],
              [
                -73.89732904499989,
                40.74207636599991
              ],
              [
                -73.89762150399993,
                40.74184176199987
              ],
              [
                -73.89818354199996,
                40.74139090199992
              ],
              [
                -73.89868042099991,
                40.740979339999946
              ],
              [
                -73.89883324999978,
                40.74085897699991
              ],
              [
                -73.89889421999989,
                40.740814239999935
              ],
              [
                -73.89896997799984,
                40.74075331499988
              ],
              [
                -73.89908954799988,
                40.740657154999944
              ],
              [
                -73.89916350300004,
                40.740598569999854
              ],
              [
                -73.89920950799993,
                40.74054548099991
              ],
              [
                -73.89974871399977,
                40.74013220399993
              ],
              [
                -73.90057825399985,
                40.73949289599989
              ],
              [
                -73.9010230649999,
                40.73926666799989
              ],
              [
                -73.90174321599993,
                40.73890039799998
              ],
              [
                -73.90268254099986,
                40.73856750299995
              ],
              [
                -73.90316328499986,
                40.738390687999924
              ],
              [
                -73.903575259,
                40.73824323399991
              ],
              [
                -73.90364752599992,
                40.73821736899992
              ],
              [
                -73.90369365299995,
                40.73819895599997
              ],
              [
                -73.90463288899997,
                40.737824162999914
              ],
              [
                -73.90486204399993,
                40.73772361199985
              ],
              [
                -73.90556671799989,
                40.7374143989999
              ],
              [
                -73.90598452499982,
                40.73721864799991
              ],
              [
                -73.90646283399997,
                40.73702598499989
              ],
              [
                -73.90672950499994,
                40.7369134849999
              ],
              [
                -73.90739546899984,
                40.736709318999914
              ],
              [
                -73.90761906599998,
                40.73665029899994
              ],
              [
                -73.90789283699988,
                40.736597387999936
              ],
              [
                -73.90890220399996,
                40.736440710999894
              ],
              [
                -73.90926002999988,
                40.73640317999986
              ],
              [
                -73.90928542399993,
                40.73633405899991
              ],
              [
                -73.90930551799991,
                40.736287284999875
              ],
              [
                -73.90932941199996,
                40.73623244499991
              ],
              [
                -73.90959716499998,
                40.7356564569999
              ],
              [
                -73.90991066500006,
                40.73499734099988
              ],
              [
                -73.91023874099996,
                40.73433921699993
              ],
              [
                -73.91068876399997,
                40.733471081999916
              ],
              [
                -73.90628903499987,
                40.73240075199989
              ],
              [
                -73.90598353299988,
                40.73326568399993
              ],
              [
                -73.90568645899998,
                40.7339158249999
              ],
              [
                -73.9060183039999,
                40.734010738999935
              ],
              [
                -73.90573589799986,
                40.73463343599989
              ],
              [
                -73.90516948899983,
                40.73585964299986
              ],
              [
                -73.90470297599984,
                40.73567990299988
              ],
              [
                -73.90455512499977,
                40.735605272999884
              ],
              [
                -73.90378660899988,
                40.735217315999876
              ],
              [
                -73.903367991,
                40.73501264999993
              ],
              [
                -73.90333476000002,
                40.73499640299988
              ],
              [
                -73.90286649499988,
                40.73476746099994
              ],
              [
                -73.9024461159998,
                40.734572838999945
              ],
              [
                -73.90194324799982,
                40.73434002499995
              ],
              [
                -73.90107970900003,
                40.73391463399987
              ],
              [
                -73.90091094899985,
                40.7341260369999
              ],
              [
                -73.9007113889999,
                40.73435872699986
              ],
              [
                -73.90062897999994,
                40.734463231999946
              ],
              [
                -73.90062893299998,
                40.734463285999894
              ],
              [
                -73.90062887299989,
                40.73446333999989
              ],
              [
                -73.90053014999994,
                40.73456130199992
              ],
              [
                -73.90053009099984,
                40.73456135699992
              ],
              [
                -73.90041661699985,
                40.73465058799992
              ],
              [
                -73.90041646300001,
                40.73465070499993
              ],
              [
                -73.90041629699991,
                40.734650803999884
              ],
              [
                -73.90029014899983,
                40.734729471999906
              ],
              [
                -73.90029003099994,
                40.73472954299993
              ],
              [
                -73.90028989999986,
                40.73472960699994
              ],
              [
                -73.90015343399983,
                40.734796404999926
              ],
              [
                -73.90000969399993,
                40.73485031999995
              ],
              [
                -73.89982513099983,
                40.734931394999926
              ],
              [
                -73.89963257999993,
                40.73500235799992
              ],
              [
                -73.89943348500005,
                40.735062589999934
              ],
              [
                -73.89922944799997,
                40.73511159899988
              ],
              [
                -73.89902209999995,
                40.73514908399993
              ],
              [
                -73.89878646099986,
                40.73517679299993
              ],
              [
                -73.89878634299994,
                40.73517681099992
              ],
              [
                -73.89878621299994,
                40.73517681999992
              ],
              [
                -73.89854787099992,
                40.7351919009999
              ],
              [
                -73.89854772799987,
                40.735191909999855
              ],
              [
                -73.8985475869999,
                40.73519190999994
              ],
              [
                -73.89830812699986,
                40.73519418399992
              ],
              [
                -73.89830799699995,
                40.73519418399997
              ],
              [
                -73.89830786699996,
                40.735194173999965
              ],
              [
                -73.89806910099988,
                40.73518361699996
              ],
              [
                -73.89806895999997,
                40.73518360799989
              ],
              [
                -73.89806882999987,
                40.73518359899987
              ],
              [
                -73.89783236899989,
                40.73516035399992
              ],
              [
                -73.89691187999993,
                40.73508660199993
              ],
              [
                -73.89651105199997,
                40.73509674199993
              ],
              [
                -73.89602931599993,
                40.73501789199986
              ],
              [
                -73.89510133499994,
                40.73502118299989
              ],
              [
                -73.89492421799993,
                40.73500772199992
              ],
              [
                -73.89474848099997,
                40.73498281499995
              ],
              [
                -73.89474827899986,
                40.734982786999915
              ],
              [
                -73.8947480899998,
                40.73498275099996
              ],
              [
                -73.89457557899992,
                40.73494655499992
              ],
              [
                -73.89457544899996,
                40.734946528999885
              ],
              [
                -73.89457531899991,
                40.73494649199996
              ],
              [
                -73.89440759699976,
                40.73489932399988
              ],
              [
                -73.89424644900001,
                40.73484181299994
              ],
              [
                -73.89415835499986,
                40.734807791999884
              ],
              [
                -73.89393780599978,
                40.73472620999993
              ],
              [
                -73.89370905699988,
                40.734657451999915
              ],
              [
                -73.89347408299996,
                40.734602177999875
              ],
              [
                -73.89323494499989,
                40.73456081199991
              ],
              [
                -73.89301088099991,
                40.73452357599995
              ],
              [
                -73.89278405699996,
                40.73449596299988
              ],
              [
                -73.89255554699984,
                40.73447812599994
              ],
              [
                -73.89232644100002,
                40.73447014099993
              ],
              [
                -73.89175016399979,
                40.73448480999986
              ],
              [
                -73.89138497899995,
                40.7345049889999
              ],
              [
                -73.89113852799989,
                40.73451296999988
              ],
              [
                -73.88993450699996,
                40.73455995599993
              ],
              [
                -73.88910318999984,
                40.73459239599988
              ],
              [
                -73.8885228599999,
                40.734531159999925
              ],
              [
                -73.88770199299982,
                40.7342913229999
              ],
              [
                -73.88751426599988,
                40.734225348999864
              ],
              [
                -73.88718676699979,
                40.7341589399999
              ],
              [
                -73.88689197799985,
                40.734099162999904
              ],
              [
                -73.88639651899997,
                40.73406746299985
              ],
              [
                -73.88598467599994,
                40.7340411219999
              ],
              [
                -73.88548975099985,
                40.73400945499987
              ],
              [
                -73.88532612599988,
                40.734008121999885
              ],
              [
                -73.88532603099999,
                40.73400812199985
              ],
              [
                -73.88532593599987,
                40.73400811199988
              ],
              [
                -73.885162107,
                40.73399498199993
              ],
              [
                -73.88500028499989,
                40.73397007399993
              ],
              [
                -73.88500017899989,
                40.73397005699992
              ],
              [
                -73.88500007199991,
                40.733970027999874
              ],
              [
                -73.88484260399989,
                40.733933706999906
              ],
              [
                -73.88484243799992,
                40.733933669999935
              ],
              [
                -73.88484227299995,
                40.73393361599995
              ],
              [
                -73.88469091999995,
                40.73388646899986
              ],
              [
                -73.88381086999975,
                40.73355953599985
              ],
              [
                -73.883529104,
                40.733848278999844
              ],
              [
                -73.88268121899998,
                40.7335112399999
              ],
              [
                -73.88182222699987,
                40.73320039799993
              ],
              [
                -73.8810869159999,
                40.73293562999988
              ],
              [
                -73.8802939189999,
                40.73256164499992
              ],
              [
                -73.87932144399987,
                40.73220754099992
              ],
              [
                -73.87844215099987,
                40.73192119199992
              ],
              [
                -73.87752195799992,
                40.73159316899993
              ],
              [
                -73.87725122499988,
                40.73179415299991
              ],
              [
                -73.87822455999998,
                40.73210226899988
              ],
              [
                -73.87708276299998,
                40.73290931699992
              ],
              [
                -73.87771964099996,
                40.73341722599988
              ],
              [
                -73.87834921099993,
                40.73390580499988
              ],
              [
                -73.87895373100004,
                40.734380046999966
              ],
              [
                -73.87931157699985,
                40.73466211999989
              ],
              [
                -73.87955435499998,
                40.734853488999924
              ],
              [
                -73.88015913099983,
                40.73532715699989
              ],
              [
                -73.88056928399986,
                40.735644932999904
              ],
              [
                -73.88066188999987,
                40.73570787399995
              ],
              [
                -73.88051536899988,
                40.73575217199992
              ],
              [
                -73.88035425599993,
                40.73713112399987
              ],
              [
                -73.88033854099989,
                40.73726569699992
              ],
              [
                -73.88033059399994,
                40.737333676999896
              ],
              [
                -73.88051819099995,
                40.73736701099992
              ],
              [
                -73.881509822,
                40.73753989799994
              ],
              [
                -73.88203268000002,
                40.73764290399986
              ],
              [
                -73.88230252700001,
                40.737707700999934
              ],
              [
                -73.88213493800001,
                40.737739839999946
              ],
              [
                -73.88178451799989,
                40.73780702699985
              ],
              [
                -73.88128967299998,
                40.73789514699993
              ],
              [
                -73.87964850399992,
                40.738190439999904
              ],
              [
                -73.87955038799986,
                40.738187436999986
              ],
              [
                -73.87945154899991,
                40.73819811399992
              ],
              [
                -73.8793568999999,
                40.7382222559999
              ],
              [
                -73.87927094299985,
                40.73825836599989
              ],
              [
                -73.87823490600005,
                40.73844453599987
              ],
              [
                -73.87797778399988,
                40.73849579099993
              ],
              [
                -73.87743058399992,
                40.738600514999916
              ],
              [
                -73.87791831799989,
                40.73939495699988
              ],
              [
                -73.87848682299996,
                40.73993390899992
              ],
              [
                -73.87863239399987,
                40.74005996299993
              ],
              [
                -73.87910739799986,
                40.740445077999894
              ],
              [
                -73.879319796,
                40.7406020799999
              ],
              [
                -73.87999120499994,
                40.741070261999894
              ],
              [
                -73.88011277199999,
                40.74116823899993
              ],
              [
                -73.88027765499996,
                40.74129746199992
              ],
              [
                -73.88100387999987,
                40.74184066199995
              ],
              [
                -73.88186399099997,
                40.74173456699988
              ],
              [
                -73.882942868,
                40.74160954399985
              ],
              [
                -73.88282912199988,
                40.740992282999876
              ],
              [
                -73.88325318299987,
                40.74085945599988
              ],
              [
                -73.88333435099989,
                40.740834026999906
              ],
              [
                -73.88416972299986,
                40.74057237299994
              ],
              [
                -73.88461489999985,
                40.740432933999934
              ],
              [
                -73.88540705199999,
                40.740196431999934
              ],
              [
                -73.88551225099984,
                40.74016501899989
              ],
              [
                -73.88559037099996,
                40.74057655699986
              ],
              [
                -73.88572588200003,
                40.7412887049999
              ],
              [
                -73.88661927199986,
                40.741181715999865
              ],
              [
                -73.88666826999996,
                40.741178144999914
              ],
              [
                -73.887511321,
                40.74108252699992
              ],
              [
                -73.88798650799986,
                40.74354819199993
              ],
              [
                -73.88886955199995,
                40.74339931999985
              ],
              [
                -73.8897441679999,
                40.743268291999904
              ],
              [
                -73.88979159800003,
                40.743261185999884
              ],
              [
                -73.88983831799999,
                40.74325444899993
              ],
              [
                -73.89071647499993,
                40.74312778399993
              ],
              [
                -73.89111026199994,
                40.745202717999874
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.00207375572052,
        "objectid": 84,
        "shape_leng": 0.233623987032,
        "location_id": 84,
        "zone": "Eltingville/Annadale/Prince's Bay",
        "borough": "Staten Island"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -74.16005732699995,
                40.52763504199989
              ],
              [
                -74.16042481199997,
                40.528118481999854
              ],
              [
                -74.16085825899998,
                40.52871854699992
              ],
              [
                -74.16134961699994,
                40.529373423999914
              ],
              [
                -74.16122606799999,
                40.5294244229999
              ],
              [
                -74.16037397400001,
                40.52978317299992
              ],
              [
                -74.15851066999988,
                40.530565689999904
              ],
              [
                -74.15730358599997,
                40.53107601399996
              ],
              [
                -74.15716510199995,
                40.53112831799989
              ],
              [
                -74.15703269699993,
                40.53119055399988
              ],
              [
                -74.15690813799995,
                40.531262027999915
              ],
              [
                -74.15679305099997,
                40.53134182899992
              ],
              [
                -74.1566887699999,
                40.53142883599987
              ],
              [
                -74.15602014099987,
                40.5321150939999
              ],
              [
                -74.15553157399992,
                40.53263083299994
              ],
              [
                -74.15502905599998,
                40.533160203999934
              ],
              [
                -74.15447193999992,
                40.53374918899989
              ],
              [
                -74.15438619499999,
                40.53383715099989
              ],
              [
                -74.15415844299997,
                40.53407479199994
              ],
              [
                -74.15397419799994,
                40.53426814799993
              ],
              [
                -74.15404693799991,
                40.53430845699992
              ],
              [
                -74.15593326499996,
                40.53535375799986
              ],
              [
                -74.15704288099992,
                40.53596707199989
              ],
              [
                -74.15721859999992,
                40.53607598699992
              ],
              [
                -74.15738901899991,
                40.53618969999996
              ],
              [
                -74.15755391099991,
                40.53630806099992
              ],
              [
                -74.15771305999992,
                40.53643091299995
              ],
              [
                -74.15786625499994,
                40.536558094999855
              ],
              [
                -74.15801329399991,
                40.536689438999915
              ],
              [
                -74.15815398399998,
                40.53682476999991
              ],
              [
                -74.1582881369999,
                40.53696391199989
              ],
              [
                -74.1584155779999,
                40.53710667899988
              ],
              [
                -74.158536138,
                40.537252883999905
              ],
              [
                -74.15914106399993,
                40.537898644999935
              ],
              [
                -74.15955749199995,
                40.53833428299991
              ],
              [
                -74.16102399199994,
                40.5398977589999
              ],
              [
                -74.16252750299994,
                40.54150270699991
              ],
              [
                -74.16359221499997,
                40.542642145999885
              ],
              [
                -74.16423140999989,
                40.543301158999924
              ],
              [
                -74.16478748999995,
                40.54380210999992
              ],
              [
                -74.16526055199988,
                40.54423960899986
              ],
              [
                -74.16540080799992,
                40.54443272299991
              ],
              [
                -74.16548821099998,
                40.54465254199989
              ],
              [
                -74.16574238499992,
                40.54539770499993
              ],
              [
                -74.16619699799993,
                40.54711041099995
              ],
              [
                -74.16623327299993,
                40.54734247899994
              ],
              [
                -74.16683255399994,
                40.547778995999906
              ],
              [
                -74.1668879359999,
                40.54783668799997
              ],
              [
                -74.16693751499993,
                40.547897373999916
              ],
              [
                -74.16698101499993,
                40.54796071499993
              ],
              [
                -74.16701819400001,
                40.54802635699987
              ],
              [
                -74.16704884299999,
                40.54809393299988
              ],
              [
                -74.16707279099998,
                40.54816306699995
              ],
              [
                -74.16708990499995,
                40.54823337199992
              ],
              [
                -74.16710008899994,
                40.548304454999865
              ],
              [
                -74.167103286,
                40.54837591999994
              ],
              [
                -74.16709947799988,
                40.54844736699987
              ],
              [
                -74.1672586319999,
                40.54916666599995
              ],
              [
                -74.16741722299999,
                40.54988134599995
              ],
              [
                -74.16757223299994,
                40.5505747339999
              ],
              [
                -74.16773525199996,
                40.551291459999916
              ],
              [
                -74.16776724799992,
                40.55143807299988
              ],
              [
                -74.16786573399993,
                40.551889426999885
              ],
              [
                -74.1678946309999,
                40.5520010059999
              ],
              [
                -74.16805222599993,
                40.55271771199993
              ],
              [
                -74.16810265799997,
                40.55294619199987
              ],
              [
                -74.16820548500002,
                40.553431902999904
              ],
              [
                -74.16836706799991,
                40.554131592999944
              ],
              [
                -74.16852396199995,
                40.5548519379999
              ],
              [
                -74.16868441299991,
                40.55556758499988
              ],
              [
                -74.16884272899996,
                40.55626910699992
              ],
              [
                -74.16900167899996,
                40.55697216699986
              ],
              [
                -74.1691669539999,
                40.55770084699986
              ],
              [
                -74.16931680999987,
                40.558409781999856
              ],
              [
                -74.16947719799997,
                40.55912667199987
              ],
              [
                -74.16973650599986,
                40.56025020099991
              ],
              [
                -74.16977469899996,
                40.56038818799988
              ],
              [
                -74.16980364499997,
                40.560527475999876
              ],
              [
                -74.16982326899996,
                40.560667706999894
              ],
              [
                -74.16983351999993,
                40.56080851799989
              ],
              [
                -74.16983437299987,
                40.56094954399989
              ],
              [
                -74.16982582400001,
                40.56109042099992
              ],
              [
                -74.17003462899996,
                40.5611099909999
              ],
              [
                -74.17024259599992,
                40.561134199999856
              ],
              [
                -74.17044954799991,
                40.5611630269999
              ],
              [
                -74.17065530999997,
                40.561196445999926
              ],
              [
                -74.17085970499996,
                40.561234430999875
              ],
              [
                -74.17106256099996,
                40.56127694699989
              ],
              [
                -74.17126370299995,
                40.561323959999875
              ],
              [
                -74.17146296099997,
                40.56137542899988
              ],
              [
                -74.17177723899994,
                40.56149241599996
              ],
              [
                -74.17185166499996,
                40.56152092299992
              ],
              [
                -74.17222993499992,
                40.56113585799992
              ],
              [
                -74.17245326199999,
                40.56088825699991
              ],
              [
                -74.17321541599998,
                40.5600776619999
              ],
              [
                -74.17358785199997,
                40.55969842799993
              ],
              [
                -74.17437344399991,
                40.55870866599984
              ],
              [
                -74.17475740499992,
                40.55816798199986
              ],
              [
                -74.1750629909999,
                40.55771126399991
              ],
              [
                -74.17512402999996,
                40.557620037999925
              ],
              [
                -74.17547297999988,
                40.557065311999914
              ],
              [
                -74.17559957599995,
                40.55685071199992
              ],
              [
                -74.17579913099998,
                40.55651243799986
              ],
              [
                -74.17611659899998,
                40.555937540999935
              ],
              [
                -74.17661991899996,
                40.55499100699994
              ],
              [
                -74.17746276899993,
                40.55341114599985
              ],
              [
                -74.17815548399993,
                40.552112789999946
              ],
              [
                -74.17874485199994,
                40.550995868999905
              ],
              [
                -74.1793348139999,
                40.54988835199988
              ],
              [
                -74.17991198599996,
                40.54883445299993
              ],
              [
                -74.18018524699995,
                40.54837960899992
              ],
              [
                -74.18128532999992,
                40.54695757299989
              ],
              [
                -74.18206740999993,
                40.54604483999989
              ],
              [
                -74.18285329599989,
                40.545145824999906
              ],
              [
                -74.18369101699987,
                40.54417358399989
              ],
              [
                -74.18559859999995,
                40.54195759399991
              ],
              [
                -74.18657647099997,
                40.54085328399988
              ],
              [
                -74.1870889679999,
                40.54024712299989
              ],
              [
                -74.18738120699996,
                40.53989224299987
              ],
              [
                -74.18770381799999,
                40.53952694799991
              ],
              [
                -74.18826921099989,
                40.53886614199993
              ],
              [
                -74.18903145199995,
                40.53815934199989
              ],
              [
                -74.189510226,
                40.53778857999992
              ],
              [
                -74.19030442599998,
                40.53724645399989
              ],
              [
                -74.19135323799996,
                40.536664858999856
              ],
              [
                -74.19377169299999,
                40.53583384699993
              ],
              [
                -74.19356025199987,
                40.53540617199995
              ],
              [
                -74.19341548699992,
                40.535120498999945
              ],
              [
                -74.19335436399993,
                40.53499987599987
              ],
              [
                -74.19329057000002,
                40.53487778199998
              ],
              [
                -74.19300900999994,
                40.53432605099988
              ],
              [
                -74.19267129299996,
                40.533633488999925
              ],
              [
                -74.19237403399998,
                40.53304061099991
              ],
              [
                -74.19174368799999,
                40.53191669699987
              ],
              [
                -74.19250928499991,
                40.53149740799989
              ],
              [
                -74.19313382999995,
                40.53119491599988
              ],
              [
                -74.1939845689999,
                40.53087104699991
              ],
              [
                -74.19453974799997,
                40.5306197419999
              ],
              [
                -74.19478532600002,
                40.53051789799984
              ],
              [
                -74.19608390199986,
                40.52991174699987
              ],
              [
                -74.19779445399999,
                40.52903208399988
              ],
              [
                -74.19909783799994,
                40.52829964499994
              ],
              [
                -74.19927554899992,
                40.52819690099989
              ],
              [
                -74.19984360499993,
                40.52785744099988
              ],
              [
                -74.2005284199999,
                40.52736440399985
              ],
              [
                -74.20071057499999,
                40.52722595199986
              ],
              [
                -74.200879328,
                40.527077231999904
              ],
              [
                -74.20103324199994,
                40.5269194149999
              ],
              [
                -74.20117111699992,
                40.52675382699987
              ],
              [
                -74.20124767199997,
                40.52662665999985
              ],
              [
                -74.20133258999991,
                40.52650262099994
              ],
              [
                -74.20142565199997,
                40.526382032999905
              ],
              [
                -74.20152661599995,
                40.526265205999934
              ],
              [
                -74.20217525999989,
                40.52606646299992
              ],
              [
                -74.20276973299997,
                40.52590721799992
              ],
              [
                -74.203687632,
                40.525782940999896
              ],
              [
                -74.20525952799987,
                40.52557617999988
              ],
              [
                -74.20546090199988,
                40.525544336999936
              ],
              [
                -74.20666645799994,
                40.525354357999966
              ],
              [
                -74.20674433099995,
                40.52534553599986
              ],
              [
                -74.20730164999993,
                40.52528242199987
              ],
              [
                -74.20742674699993,
                40.525262338999916
              ],
              [
                -74.20755306399994,
                40.52524734899988
              ],
              [
                -74.20768024599988,
                40.52523749299995
              ],
              [
                -74.20780793299994,
                40.52523279999989
              ],
              [
                -74.20793576699992,
                40.52523328199993
              ],
              [
                -74.20806338799994,
                40.525238938999905
              ],
              [
                -74.20819043499995,
                40.52524975299989
              ],
              [
                -74.20831655199999,
                40.52526569499987
              ],
              [
                -74.208441382,
                40.52528672099988
              ],
              [
                -74.20856457599997,
                40.52531276999992
              ],
              [
                -74.20864934299995,
                40.525341516999916
              ],
              [
                -74.20873731700001,
                40.52536396199985
              ],
              [
                -74.20882769699993,
                40.52537989899984
              ],
              [
                -74.2089196559999,
                40.52538918299991
              ],
              [
                -74.20901235799994,
                40.52539172999996
              ],
              [
                -74.209104955,
                40.52538751599994
              ],
              [
                -74.20919660199999,
                40.525376579999865
              ],
              [
                -74.20928646399996,
                40.52535902099987
              ],
              [
                -74.20937372099999,
                40.525334998999874
              ],
              [
                -74.20945757499996,
                40.52530473499994
              ],
              [
                -74.20953726399988,
                40.52526850299993
              ],
              [
                -74.20961205799995,
                40.52522663499996
              ],
              [
                -74.20971385199991,
                40.5251045449999
              ],
              [
                -74.20983392599999,
                40.52499086699992
              ],
              [
                -74.20983398499993,
                40.524990811999864
              ],
              [
                -74.20983404399988,
                40.52499076799997
              ],
              [
                -74.20997053199989,
                40.52488777599995
              ],
              [
                -74.2101209019999,
                40.52479737599986
              ],
              [
                -74.21028201899998,
                40.52472106699988
              ],
              [
                -74.21142518399989,
                40.524414101999874
              ],
              [
                -74.21201514099994,
                40.52431623199986
              ],
              [
                -74.21229294499989,
                40.52427685299989
              ],
              [
                -74.2135190009999,
                40.524148838999885
              ],
              [
                -74.21409806799993,
                40.524097194999925
              ],
              [
                -74.21398779899988,
                40.52345242299989
              ],
              [
                -74.21390912599999,
                40.523014630999945
              ],
              [
                -74.21381535699992,
                40.52249465099986
              ],
              [
                -74.21361475799989,
                40.52153163699992
              ],
              [
                -74.21354593499994,
                40.52123571499993
              ],
              [
                -74.21450845999988,
                40.52115497999994
              ],
              [
                -74.21735501199993,
                40.52093004899991
              ],
              [
                -74.21760570099995,
                40.52087168799987
              ],
              [
                -74.21802828299992,
                40.52066372699995
              ],
              [
                -74.22013810199992,
                40.5196218829999
              ],
              [
                -74.22269085399991,
                40.518512303999934
              ],
              [
                -74.22281984999994,
                40.51845646599993
              ],
              [
                -74.22295200699995,
                40.51840511399991
              ],
              [
                -74.22308705699992,
                40.51835835099989
              ],
              [
                -74.22322472599994,
                40.5183162709999
              ],
              [
                -74.2233647369999,
                40.51827895999992
              ],
              [
                -74.22350680499993,
                40.518246492999886
              ],
              [
                -74.223650643,
                40.518218936999844
              ],
              [
                -74.22379595899992,
                40.5181963459999
              ],
              [
                -74.22398999699992,
                40.518212367999894
              ],
              [
                -74.22418327999995,
                40.51823302699989
              ],
              [
                -74.22437561899991,
                40.51825830199988
              ],
              [
                -74.22456682499998,
                40.51828816899994
              ],
              [
                -74.22475670999995,
                40.51832259699992
              ],
              [
                -74.22494508599989,
                40.518361553999895
              ],
              [
                -74.22513176899994,
                40.51840500099993
              ],
              [
                -74.22531657499994,
                40.518452893999886
              ],
              [
                -74.22549932099996,
                40.51850518799994
              ],
              [
                -74.2256798289999,
                40.51856182999988
              ],
              [
                -74.22582224299991,
                40.51868027699989
              ],
              [
                -74.22597849399989,
                40.51879018499991
              ],
              [
                -74.22614720799999,
                40.51889020799993
              ],
              [
                -74.22632671899993,
                40.518979159999944
              ],
              [
                -74.2265150859999,
                40.51905608999989
              ],
              [
                -74.226710219,
                40.51912029999991
              ],
              [
                -74.22690993399992,
                40.51917139799984
              ],
              [
                -74.22663407199991,
                40.517251009999946
              ],
              [
                -74.22290251399993,
                40.5089365399999
              ],
              [
                -74.22321582499997,
                40.508658610999866
              ],
              [
                -74.22188242299993,
                40.502674840999866
              ],
              [
                -74.22159300278173,
                40.50250043822094
              ],
              [
                -74.22153919955196,
                40.50250347421612
              ],
              [
                -74.22148633479932,
                40.502511692052934
              ],
              [
                -74.22143526134924,
                40.5025249593466
              ],
              [
                -74.22138680334184,
                40.50254306201157
              ],
              [
                -74.22134174271483,
                40.50256570793666
              ],
              [
                -74.22134151577676,
                40.50256583904863
              ],
              [
                -74.22128970464198,
                40.50259707640795
              ],
              [
                -74.22123334742355,
                40.5026233298297
              ],
              [
                -74.22117326247195,
                40.50264421809158
              ],
              [
                -74.22111032227026,
                40.50265943787781
              ],
              [
                -74.22104544076478,
                40.50266876818359
              ],
              [
                -74.22094717949659,
                40.50269806888585
              ],
              [
                -74.22084615293313,
                40.502721251288875
              ],
              [
                -74.2207430136998,
                40.50273816563523
              ],
              [
                -74.22063842807191,
                40.50274870265868
              ],
              [
                -74.22053307166941,
                40.50275279429041
              ],
              [
                -74.2204276250922,
                40.5027504140985
              ],
              [
                -74.22032276952281,
                40.50274157745911
              ],
              [
                -74.22021918232528,
                40.502726341456416
              ],
              [
                -74.22012925217143,
                40.502719014463466
              ],
              [
                -74.22003885014124,
                40.50271698940033
              ],
              [
                -74.2199485118786,
                40.502720278265876
              ],
              [
                -74.21985877264986,
                40.50272886157314
              ],
              [
                -74.21977016417237,
                40.502742688464735
              ],
              [
                -74.21968321146456,
                40.50276167701462
              ],
              [
                -74.21959842973497,
                40.50278571471264
              ],
              [
                -74.2194076557571,
                40.50286077290877
              ],
              [
                -74.21921351124648,
                40.50293061429154
              ],
              [
                -74.21901624093961,
                40.50299515081598
              ],
              [
                -74.21881609351551,
                40.50305430112445
              ],
              [
                -74.21861332128304,
                40.50310799064943
              ],
              [
                -74.21840817986099,
                40.503156151706904
              ],
              [
                -74.21834658055985,
                40.50316646976413
              ],
              [
                -74.21828692035882,
                40.50318207330557
              ],
              [
                -74.21822997486109,
                40.50320275948046
              ],
              [
                -74.21817648437909,
                40.50322825936179
              ],
              [
                -74.21814317872034,
                40.50324771545581
              ],
              [
                -74.21811190000946,
                40.50326904190765
              ],
              [
                -74.21807755944748,
                40.50329871295204
              ],
              [
                -74.21803780248969,
                40.50332413970897
              ],
              [
                -74.21799351958558,
                40.50334475268358
              ],
              [
                -74.21794570255717,
                40.503360090196935
              ],
              [
                -74.21789542238488,
                40.50336980872707
              ],
              [
                -74.21784380521872,
                40.50337369060308
              ],
              [
                -74.21779200715531,
                40.50337164888041
              ],
              [
                -74.21774118834345,
                40.5033637292887
              ],
              [
                -74.21772516950195,
                40.503352589328806
              ],
              [
                -74.21770703354522,
                40.50334353583657
              ],
              [
                -74.2176872421334,
                40.50333679927285
              ],
              [
                -74.21766629906656,
                40.5033325511201
              ],
              [
                -74.2176447374599,
                40.50333089951688
              ],
              [
                -74.2176231061741,
                40.5033318865055
              ],
              [
                -74.21760226300273,
                40.50333541478964
              ],
              [
                -74.21758240164539,
                40.50334139266347
              ],
              [
                -74.21756401284408,
                40.50334967242369
              ],
              [
                -74.21754755095681,
                40.5033600494906
              ],
              [
                -74.21753620569285,
                40.503369536699886
              ],
              [
                -74.21752648446406,
                40.503380022023215
              ],
              [
                -74.21751853650296,
                40.50339134449908
              ],
              [
                -74.21740975778067,
                40.50356197154513
              ],
              [
                -74.21735453442754,
                40.503642896458096
              ],
              [
                -74.2164163494922,
                40.504691630366494
              ],
              [
                -74.21633137591344,
                40.504834009458094
              ],
              [
                -74.21623830990282,
                40.50497340946303
              ],
              [
                -74.21613733021583,
                40.505109562555496
              ],
              [
                -74.2160286308154,
                40.505242207146686
              ],
              [
                -74.21591242050023,
                40.505371088386966
              ],
              [
                -74.21578892250353,
                40.50549595865575
              ],
              [
                -74.21565837406435,
                40.50561657803738
              ],
              [
                -74.21552102597181,
                40.50573271478231
              ],
              [
                -74.21537714208378,
                40.50584414575235
              ],
              [
                -74.21524555459739,
                40.50595406160777
              ],
              [
                -74.21510815859857,
                40.506059755667096
              ],
              [
                -74.21496518634406,
                40.50616104924237
              ],
              [
                -74.21481687952192,
                40.506257771084194
              ],
              [
                -74.21466348884147,
                40.506349757671735
              ],
              [
                -74.2145052736106,
                40.506436853488815
              ],
              [
                -74.21434250129728,
                40.506518911287216
              ],
              [
                -74.21392935453652,
                40.50660120190602
              ],
              [
                -74.21390521220889,
                40.50660623376389
              ],
              [
                -74.2138803178839,
                40.50660819217817
              ],
              [
                -74.21385533847373,
                40.50660702468339
              ],
              [
                -74.21383094316957,
                40.5066027625566
              ],
              [
                -74.21380778551455,
                40.506595519978646
              ],
              [
                -74.21375171236187,
                40.50654706072898
              ],
              [
                -74.21364735292187,
                40.50645687177696
              ],
              [
                -74.21362937978549,
                40.506439907244435
              ],
              [
                -74.21360828614134,
                40.50642518416283
              ],
              [
                -74.21358454603869,
                40.50641303341088
              ],
              [
                -74.2135586930013,
                40.506403728058004
              ],
              [
                -74.21353130803675,
                40.50639747722785
              ],
              [
                -74.21351810019696,
                40.506435277546395
              ],
              [
                -74.21351359963718,
                40.506474248040966
              ],
              [
                -74.21351794029142,
                40.50651322910223
              ],
              [
                -74.21353099301393,
                40.50655106080436
              ],
              [
                -74.21353455155688,
                40.50656101905034
              ],
              [
                -74.21353593096521,
                40.50657128724868
              ],
              [
                -74.21353509417533,
                40.50658158950686
              ],
              [
                -74.21353206366952,
                40.5065916490171
              ],
              [
                -74.21352692087261,
                40.50660119549385
              ],
              [
                -74.21351980396362,
                40.50660997243571
              ],
              [
                -74.21345342773242,
                40.506689507683085
              ],
              [
                -74.21338169973609,
                40.50676628821538
              ],
              [
                -74.21330481575855,
                40.50684010443856
              ],
              [
                -74.21322298566018,
                40.5069107548499
              ],
              [
                -74.21313643280568,
                40.506978046587626
              ],
              [
                -74.21308608941341,
                40.50700935001109
              ],
              [
                -74.21303970639431,
                40.5070440355638
              ],
              [
                -74.21299767227997,
                40.50708181270401
              ],
              [
                -74.21296033917497,
                40.50712236499311
              ],
              [
                -74.21281403368066,
                40.50723014633256
              ],
              [
                -74.21266393606682,
                40.50733484595486
              ],
              [
                -74.21263191375205,
                40.507358609545385
              ],
              [
                -74.21260429253782,
                40.50738539835732
              ],
              [
                -74.21258155816881,
                40.50741474129021
              ],
              [
                -74.21252145049147,
                40.50747690144793
              ],
              [
                -74.212469109696,
                40.507543027976546
              ],
              [
                -74.21242498195419,
                40.50761255721916
              ],
              [
                -74.21241957281302,
                40.50762092916938
              ],
              [
                -74.21241247813637,
                40.507628532518076
              ],
              [
                -74.21240388078938,
                40.50763517128831
              ],
              [
                -74.21239400236918,
                40.50764067436548
              ],
              [
                -74.21238309749239,
                40.50764489990711
              ],
              [
                -74.21237144723335,
                40.507647738999545
              ],
              [
                -74.21235935187873,
                40.507649118464585
              ],
              [
                -74.21234712318729,
                40.50764900274668
              ],
              [
                -74.21233711418861,
                40.50764741369819
              ],
              [
                -74.21232689831143,
                40.507647118018966
              ],
              [
                -74.21231676039584,
                40.50764812395292
              ],
              [
                -74.21230698310823,
                40.50765040345259
              ],
              [
                -74.21229783906037,
                40.507653892960654
              ],
              [
                -74.21228958320752,
                40.50765849518248
              ],
              [
                -74.21228244574074,
                40.50766408179794
              ],
              [
                -74.21227662566783,
                40.507670497040706
              ],
              [
                -74.21227228526551,
                40.50767756204003
              ],
              [
                -74.21226954555374,
                40.50768507980838
              ],
              [
                -74.21226848292227,
                40.50769284073457
              ],
              [
                -74.21226912700006,
                40.507700628426775
              ],
              [
                -74.21227145982967,
                40.5077082257477
              ],
              [
                -74.21227541636684,
                40.50771542086717
              ],
              [
                -74.21228088629543,
                40.50772201316972
              ],
              [
                -74.2122877171022,
                40.50772781884722
              ],
              [
                -74.21224119637824,
                40.50780841877927
              ],
              [
                -74.21218779097335,
                40.50788649278343
              ],
              [
                -74.21212773490433,
                40.507961698713984
              ],
              [
                -74.21206129133526,
                40.508033706992975
              ],
              [
                -74.21198875142503,
                40.50810220205466
              ],
              [
                -74.21191043305252,
                40.508166883728144
              ],
              [
                -74.21190204219565,
                40.50817156041408
              ],
              [
                -74.2118927242892,
                40.50817506855932
              ],
              [
                -74.21188275583197,
                40.50817730406349
              ],
              [
                -74.21187243262713,
                40.50817820059035
              ],
              [
                -74.21186206100487,
                40.50817773153637
              ],
              [
                -74.21184952499443,
                40.50817209263544
              ],
              [
                -74.21182935119818,
                40.508165624145235
              ],
              [
                -74.21180447511732,
                40.50816644740152
              ],
              [
                -74.21179183197995,
                40.50817327349501
              ],
              [
                -74.21178477846054,
                40.5081939209598
              ],
              [
                -74.21178440618708,
                40.50819834965656
              ],
              [
                -74.21179478802503,
                40.50821802241691
              ],
              [
                -74.21181314633507,
                40.50823803897107
              ],
              [
                -74.21181694045957,
                40.50825128014591
              ],
              [
                -74.2118193208539,
                40.508262374173256
              ],
              [
                -74.21181466920432,
                40.508274913401124
              ],
              [
                -74.21180347990948,
                40.50829641620654
              ],
              [
                -74.21179480240268,
                40.50830612472214
              ],
              [
                -74.21178832276219,
                40.50831337535581
              ],
              [
                -74.21178387820076,
                40.50831708247288
              ],
              [
                -74.21169443837452,
                40.5084434921724
              ],
              [
                -74.2115985998613,
                40.50856713987463
              ],
              [
                -74.21149650880552,
                40.50868783698416
              ],
              [
                -74.21138832089028,
                40.50880539940496
              ],
              [
                -74.21137155741013,
                40.50882118680085
              ],
              [
                -74.21135666295963,
                40.50883803057888
              ],
              [
                -74.2113394556123,
                40.50886265597218
              ],
              [
                -74.2113231618442,
                40.50889646925354
              ],
              [
                -74.21130869269622,
                40.50891433301951
              ],
              [
                -74.21129092925099,
                40.50893037216114
              ],
              [
                -74.2112702602015,
                40.50894423571413
              ],
              [
                -74.21124713782135,
                40.50895562031976
              ],
              [
                -74.2112220680678,
                40.50896427686242
              ],
              [
                -74.21119250332829,
                40.50897496954096
              ],
              [
                -74.21116524905771,
                40.508988783056054
              ],
              [
                -74.21114086689144,
                40.50900543275004
              ],
              [
                -74.21111985927969,
                40.509024575519604
              ],
              [
                -74.21110649763584,
                40.509040446116686
              ],
              [
                -74.21109546625489,
                40.509057327783374
              ],
              [
                -74.21101830831057,
                40.50921847859691
              ],
              [
                -74.21093348896892,
                40.509377370534395
              ],
              [
                -74.21084112140272,
                40.50953379148834
              ],
              [
                -74.21074132886355,
                40.50968753264823
              ],
              [
                -74.21063424451708,
                40.50983838877939
              ],
              [
                -74.21052001126658,
                40.50998615849728
              ],
              [
                -74.21039878156121,
                40.510130644536105
              ],
              [
                -74.21027071719323,
                40.51027165401236
              ],
              [
                -74.21013598908237,
                40.510408998682514
              ],
              [
                -74.20999477704768,
                40.51054249519431
              ],
              [
                -74.2098765501006,
                40.5106466003708
              ],
              [
                -74.20979622955177,
                40.510712935588195
              ],
              [
                -74.20971374205877,
                40.51077770324301
              ],
              [
                -74.2093018345809,
                40.510461711572255
              ],
              [
                -74.20935837735608,
                40.510422361820346
              ],
              [
                -74.2093220046419,
                40.51039862889541
              ],
              [
                -74.2091899682503,
                40.5104990569552
              ],
              [
                -74.20922122417662,
                40.51052353035445
              ],
              [
                -74.20927776652347,
                40.51048338085089
              ],
              [
                -74.20968789397446,
                40.510797369342356
              ],
              [
                -74.20952274800162,
                40.51091768562526
              ],
              [
                -74.20935186685985,
                40.51103324257272
              ],
              [
                -74.20917548583245,
                40.51114388105673
              ],
              [
                -74.20899384778048,
                40.51124944872137
              ],
              [
                -74.2088072028073,
                40.51134980019293
              ],
              [
                -74.20861580791522,
                40.51144479728021
              ],
              [
                -74.20841992665041,
                40.511534309164546
              ],
              [
                -74.20821982874101,
                40.51161821258059
              ],
              [
                -74.20801578972448,
                40.51169639198572
              ],
              [
                -74.20780809056909,
                40.51176873971946
              ],
              [
                -74.20759701728615,
                40.51183515615182
              ],
              [
                -74.206238443888,
                40.51214565451206
              ],
              [
                -74.20535868990989,
                40.51231661021616
              ],
              [
                -74.20519613509917,
                40.51233074201974
              ],
              [
                -74.2051269144439,
                40.512311091474636
              ],
              [
                -74.20505574742559,
                40.512296025872296
              ],
              [
                -74.20498315433055,
                40.51228565535351
              ],
              [
                -74.20490966586989,
                40.51228005573488
              ],
              [
                -74.20485317338206,
                40.512285123141105
              ],
              [
                -74.20479737906909,
                40.5122935697916
              ],
              [
                -74.20474262938436,
                40.51230534323708
              ],
              [
                -74.2047020342324,
                40.512316438842035
              ],
              [
                -74.20467010671484,
                40.5123234901946
              ],
              [
                -74.20464466309228,
                40.51232810346069
              ],
              [
                -74.20447746264747,
                40.51235841779901
              ],
              [
                -74.20419746366701,
                40.512416025219316
              ],
              [
                -74.20391668552791,
                40.512473744077646
              ],
              [
                -74.20323003312072,
                40.51262093396144
              ],
              [
                -74.20268081102346,
                40.51273866175463
              ],
              [
                -74.20097738056023,
                40.513007301993035
              ],
              [
                -74.20082898254232,
                40.51303070395843
              ],
              [
                -74.20073578779197,
                40.51303896556317
              ],
              [
                -74.2006420292192,
                40.513041583590976
              ],
              [
                -74.20054829261352,
                40.51303854168508
              ],
              [
                -74.20045516362643,
                40.513029858850544
              ],
              [
                -74.20036322411362,
                40.51301558933666
              ],
              [
                -74.20027304849728,
                40.512995822296965
              ],
              [
                -74.20018520017844,
                40.51297068123256
              ],
              [
                -74.20010022801556,
                40.51294032322072
              ],
              [
                -74.20002882244746,
                40.51293188858516
              ],
              [
                -74.19995659988416,
                40.512930218452155
              ],
              [
                -74.19988465622781,
                40.51293533816418
              ],
              [
                -74.19981408314813,
                40.51294717003502
              ],
              [
                -74.19974595151908,
                40.51296553452804
              ],
              [
                -74.19970753197478,
                40.51298169765322
              ],
              [
                -74.19966799389906,
                40.512996204675694
              ],
              [
                -74.19959271674918,
                40.513010354963384
              ],
              [
                -74.19951575024025,
                40.513017455305196
              ],
              [
                -74.19943822355074,
                40.513017401531776
              ],
              [
                -74.19936127407834,
                40.51301019443216
              ],
              [
                -74.19928603075158,
                40.512995939741934
              ],
              [
                -74.19922302388592,
                40.51297803378664
              ],
              [
                -74.19916282561876,
                40.51295518234511
              ],
              [
                -74.19910610016021,
                40.51292763755455
              ],
              [
                -74.19905347339986,
                40.512895703337215
              ],
              [
                -74.19900552600113,
                40.512859732046806
              ],
              [
                -74.1989627869942,
                40.512820120580194
              ],
              [
                -74.19892572793859,
                40.51277730599833
              ],
              [
                -74.19990247925942,
                40.51272186470232
              ],
              [
                -74.19992960944485,
                40.512712946186575
              ],
              [
                -74.2000371231315,
                40.512677599829765
              ],
              [
                -74.19971168422505,
                40.51146557700451
              ],
              [
                -74.1996938708912,
                40.511445020298204
              ],
              [
                -74.19967205357709,
                40.511426850497486
              ],
              [
                -74.19964677235937,
                40.511411517384055
              ],
              [
                -74.19961865305943,
                40.511399400518776
              ],
              [
                -74.199588391753,
                40.51139079984641
              ],
              [
                -74.1995567375377,
                40.51138592827046
              ],
              [
                -74.19952447399193,
                40.51138490638349
              ],
              [
                -74.19949239977682,
                40.51138775948159
              ],
              [
                -74.19946130886673,
                40.51139441693826
              ],
              [
                -74.19933336816597,
                40.51138533824088
              ],
              [
                -74.19920651556788,
                40.511369720254436
              ],
              [
                -74.19908132418689,
                40.5113476335404
              ],
              [
                -74.19895835963109,
                40.511319177885646
              ],
              [
                -74.19883817744555,
                40.51128448185155
              ],
              [
                -74.19872132060244,
                40.511243702192886
              ],
              [
                -74.19860831704759,
                40.51119702314984
              ],
              [
                -74.1984996773149,
                40.51114465561499
              ],
              [
                -74.19839589221958,
                40.51108683618086
              ],
              [
                -74.19828668015873,
                40.51103977364202
              ],
              [
                -74.19818144044018,
                40.51098770518906
              ],
              [
                -74.19808056644499,
                40.510930825456214
              ],
              [
                -74.19798031248911,
                40.510866535003416
              ],
              [
                -74.19788563701174,
                40.510797493614284
              ],
              [
                -74.19779692577328,
                40.51072398261458
              ],
              [
                -74.19771454022825,
                40.510646301542
              ],
              [
                -74.19748914407792,
                40.51049120095594
              ],
              [
                -74.19725817895724,
                40.51034094940743
              ],
              [
                -74.19702182389827,
                40.51019566334
              ],
              [
                -74.1969208365999,
                40.51012747714548
              ],
              [
                -74.19681433917133,
                40.51006436353172
              ],
              [
                -74.19670276823794,
                40.51000658124722
              ],
              [
                -74.19658658122063,
                40.5099543671827
              ],
              [
                -74.19649936989559,
                40.50992353139787
              ],
              [
                -74.19640922029949,
                40.509898090282455
              ],
              [
                -74.19631670418124,
                40.50987820518893
              ],
              [
                -74.19622240829706,
                40.50986400223228
              ],
              [
                -74.19612693068868,
                40.509855571490036
              ],
              [
                -74.19603087689242,
                40.509852966431644
              ],
              [
                -74.19593485609805,
                40.509856203578536
              ],
              [
                -74.19521339999068,
                40.50997765295561
              ],
              [
                -74.19409643736367,
                40.510641242362226
              ],
              [
                -74.19400712105934,
                40.51080314080423
              ],
              [
                -74.19390978091913,
                40.51096232200203
              ],
              [
                -74.19380455848221,
                40.51111855440297
              ],
              [
                -74.19369160675681,
                40.51127161074229
              ],
              [
                -74.19357108999736,
                40.511421268373624
              ],
              [
                -74.19344318346744,
                40.51156730959325
              ],
              [
                -74.19330807318319,
                40.511709521956675
              ],
              [
                -74.19316595564403,
                40.51184769858796
              ],
              [
                -74.19281567384283,
                40.51219380443742
              ],
              [
                -74.19279996381384,
                40.51220932809701
              ],
              [
                -74.19220869836079,
                40.512793535174744
              ],
              [
                -74.19211369967013,
                40.51283365882028
              ],
              [
                -74.19202360017422,
                40.5128798989557
              ],
              [
                -74.1919390741988,
                40.512931909514684
              ],
              [
                -74.1918607543608,
                40.512989301243465
              ],
              [
                -74.1917892268341,
                40.513051644613746
              ],
              [
                -74.19172502696235,
                40.513118473037345
              ],
              [
                -74.19166863525211,
                40.5131892863576
              ],
              [
                -74.19162047377623,
                40.51326355459277
              ],
              [
                -74.19158090301478,
                40.51334072190168
              ],
              [
                -74.18971218498393,
                40.51532037853432
              ],
              [
                -74.18967331337697,
                40.5153389524551
              ],
              [
                -74.18963093217458,
                40.515352327542146
              ],
              [
                -74.18958622457697,
                40.51536013038885
              ],
              [
                -74.18954043873413,
                40.51536214315459
              ],
              [
                -74.18949485290001,
                40.515358309646615
              ],
              [
                -74.18945073974398,
                40.515348736889315
              ],
              [
                -74.18940933082028,
                40.51533369213587
              ],
              [
                -74.18885803269944,
                40.51508201968887
              ],
              [
                -74.18885516198492,
                40.515082003573944
              ],
              [
                -74.18885232973261,
                40.51508236137739
              ],
              [
                -74.18884961894243,
                40.51508308261371
              ],
              [
                -74.18884710905482,
                40.51508414614676
              ],
              [
                -74.18884487362294,
                40.515085520809514
              ],
              [
                -74.18884297815646,
                40.51508716631712
              ],
              [
                -74.1888414782028,
                40.51508903444723
              ],
              [
                -74.18884041771852,
                40.51509107045404
              ],
              [
                -74.18883982778155,
                40.51509321467174
              ],
              [
                -74.18883972568021,
                40.51509540426325
              ],
              [
                -74.1888401144064,
                40.51509757506203
              ],
              [
                -74.18884098256878,
                40.515099663452304
              ],
              [
                -74.18884230472543,
                40.51510160823291
              ],
              [
                -74.18884404213027,
                40.5151033524116
              ],
              [
                -74.18884614386798,
                40.51510484487468
              ],
              [
                -74.18926875557315,
                40.51534497687236
              ],
              [
                -74.18916738113884,
                40.51549745094445
              ],
              [
                -74.18906140992775,
                40.51564809361495
              ],
              [
                -74.18895089867928,
                40.515796824182246
              ],
              [
                -74.18880538221464,
                40.51598125671339
              ],
              [
                -74.18865288523577,
                40.51616237479525
              ],
              [
                -74.18849353720901,
                40.51634002459576
              ],
              [
                -74.18832747342314,
                40.51651405522783
              ],
              [
                -74.18815483487393,
                40.51668431887701
              ],
              [
                -74.18797576814546,
                40.51685067092723
              ],
              [
                -74.18779042528426,
                40.517012970083776
              ],
              [
                -74.18759896367169,
                40.517171078493256
              ],
              [
                -74.18656733191354,
                40.51811585040488
              ],
              [
                -74.18588730164078,
                40.5186361994914
              ],
              [
                -74.18546140887109,
                40.51892234394306
              ],
              [
                -74.1851629037099,
                40.519129282625485
              ],
              [
                -74.1848311647976,
                40.51934135860082
              ],
              [
                -74.18428548317002,
                40.519578353874394
              ],
              [
                -74.18417322756973,
                40.5196041141342
              ],
              [
                -74.18403696242659,
                40.51961870464546
              ],
              [
                -74.1838130349663,
                40.51975173601095
              ],
              [
                -74.18342370583406,
                40.51988972456804
              ],
              [
                -74.18325813603732,
                40.5198526566617
              ],
              [
                -74.18314810962117,
                40.519870832266164
              ],
              [
                -74.18283208173136,
                40.52008772084576
              ],
              [
                -74.18263745466407,
                40.52022874621202
              ],
              [
                -74.18233555621086,
                40.52035223806131
              ],
              [
                -74.18213362064886,
                40.520408393268674
              ],
              [
                -74.18204081284071,
                40.52043420132781
              ],
              [
                -74.18195667134623,
                40.52041960762216
              ],
              [
                -74.18192155369601,
                40.52041825301177
              ],
              [
                -74.18189434441686,
                40.520434178204255
              ],
              [
                -74.18182006847873,
                40.520477649292964
              ],
              [
                -74.18147441265003,
                40.520583356443936
              ],
              [
                -74.18115760502826,
                40.52069211651065
              ],
              [
                -74.18104081208887,
                40.520705036287154
              ],
              [
                -74.18087275843453,
                40.520682306633134
              ],
              [
                -74.18064008999073,
                40.520641986028515
              ],
              [
                -74.18049398813153,
                40.520616743326855
              ],
              [
                -74.18033122156834,
                40.520601078045196
              ],
              [
                -74.1802352477878,
                40.520598045364494
              ],
              [
                -74.18009755932276,
                40.52059826147795
              ],
              [
                -74.17998905151346,
                40.52058887857438
              ],
              [
                -74.17981016948934,
                40.520552337706015
              ],
              [
                -74.17970770292054,
                40.5204936689529
              ],
              [
                -74.17946516743717,
                40.52033326607575
              ],
              [
                -74.17943716601997,
                40.52031477241966
              ],
              [
                -74.1794335702753,
                40.520312208455735
              ],
              [
                -74.17939137556665,
                40.52027827890805
              ],
              [
                -74.17935437773546,
                40.520240984230384
              ],
              [
                -74.17932303420199,
                40.52020078552198
              ],
              [
                -74.17929773247594,
                40.52015817978565
              ],
              [
                -74.17927315681786,
                40.520008917883
              ],
              [
                -74.17926971864468,
                40.52000256831761
              ],
              [
                -74.17926490499875,
                40.519996762557405
              ],
              [
                -74.17925885840404,
                40.51999167250071
              ],
              [
                -74.17925175788962,
                40.51998744885474
              ],
              [
                -74.17924381368908,
                40.51998421667446
              ],
              [
                -74.17923526101619,
                40.51998207165875
              ],
              [
                -74.1792263531002,
                40.51998107731764
              ],
              [
                -74.17921735368851,
                40.519981263091864
              ],
              [
                -74.17920852923737,
                40.519982623480864
              ],
              [
                -74.17920014102289,
                40.519985118206094
              ],
              [
                -74.17919243740532,
                40.51998867340321
              ],
              [
                -74.17918564647486,
                40.51999318380907
              ],
              [
                -74.1791799692992,
                40.51999851587878
              ],
              [
                -74.17917557396925,
                40.52000451173923
              ],
              [
                -74.17909919646684,
                40.52002296516778
              ],
              [
                -74.17902084315703,
                40.52003571216092
              ],
              [
                -74.17894124429742,
                40.52004263391609
              ],
              [
                -74.17886114175502,
                40.520043665921776
              ],
              [
                -74.17878128209175,
                40.52003879855972
              ],
              [
                -74.17870240960569,
                40.52002807719417
              ],
              [
                -74.17862525939358,
                40.52001160174882
              ],
              [
                -74.17856035946403,
                40.51999350058565
              ],
              [
                -74.1784936298451,
                40.51997981156714
              ],
              [
                -74.17842558448912,
                40.51997064012644
              ],
              [
                -74.17837082900232,
                40.519961583533544
              ],
              [
                -74.17831876690433,
                40.51994578563497
              ],
              [
                -74.17827081273789,
                40.51992367566487
              ],
              [
                -74.17777234503342,
                40.519352398801956
              ],
              [
                -74.17770261696565,
                40.51932100548375
              ],
              [
                -74.17758814548375,
                40.51922013151401
              ],
              [
                -74.17758502488357,
                40.51921846970048
              ],
              [
                -74.1775815979258,
                40.519217207401255
              ],
              [
                -74.17757795168454,
                40.51921637668949
              ],
              [
                -74.17757417880544,
                40.51921599867234
              ],
              [
                -74.177570375152,
                40.51921608295464
              ],
              [
                -74.17756663736947,
                40.51921662739506
              ],
              [
                -74.17756306042942,
                40.519217618159864
              ],
              [
                -74.1775491391979,
                40.51923529876411
              ],
              [
                -74.17753078094204,
                40.519258613750836
              ],
              [
                -74.17755135508193,
                40.519283069546475
              ],
              [
                -74.17756158077196,
                40.51928751791759
              ],
              [
                -74.17758082600331,
                40.519295889471664
              ],
              [
                -74.17758110816587,
                40.51931360137953
              ],
              [
                -74.17760133813562,
                40.5193164949976
              ],
              [
                -74.17601245479067,
                40.520875962829095
              ],
              [
                -74.17506810745108,
                40.521494273787354
              ],
              [
                -74.17342635928968,
                40.522569159917396
              ],
              [
                -74.17278271090665,
                40.52292094996799
              ],
              [
                -74.17269667263014,
                40.52296873552358
              ],
              [
                -74.1720632017547,
                40.52332056936472
              ],
              [
                -74.17134981850703,
                40.52355339220966
              ],
              [
                -74.1712653606599,
                40.52355912147657
              ],
              [
                -74.17110263696175,
                40.523549706673684
              ],
              [
                -74.17103027207942,
                40.52352912247979
              ],
              [
                -74.17089812897197,
                40.523460343603915
              ],
              [
                -74.17080648143494,
                40.52338975028166
              ],
              [
                -74.17076227805249,
                40.52335570179818
              ],
              [
                -74.1706100526443,
                40.52321348729538
              ],
              [
                -74.1705931469583,
                40.52318574255418
              ],
              [
                -74.17057009188476,
                40.52314788073063
              ],
              [
                -74.17048859810274,
                40.52308417264991
              ],
              [
                -74.17039614222017,
                40.52305368266171
              ],
              [
                -74.17032706385987,
                40.523026957841964
              ],
              [
                -74.17022498733132,
                40.52300897256717
              ],
              [
                -74.17014586058583,
                40.52300793760333
              ],
              [
                -74.17007269489395,
                40.52302655162228
              ],
              [
                -74.1699971116146,
                40.52304577245232
              ],
              [
                -74.16983248964985,
                40.52313389981412
              ],
              [
                -74.16967960342768,
                40.523205798664726
              ],
              [
                -74.16963714493947,
                40.52322943782579
              ],
              [
                -74.1695529927631,
                40.52327628994602
              ],
              [
                -74.16944771743327,
                40.523327955346666
              ],
              [
                -74.16935136134754,
                40.523360544525
              ],
              [
                -74.16920066800394,
                40.52343810320529
              ],
              [
                -74.1689855713698,
                40.52352916214105
              ],
              [
                -74.16884431820021,
                40.52359068215663
              ],
              [
                -74.16839091003757,
                40.52389048803943
              ],
              [
                -74.16829560779412,
                40.523949868110265
              ],
              [
                -74.16755867477953,
                40.52440902876843
              ],
              [
                -74.16690462540775,
                40.52483129006192
              ],
              [
                -74.16652810000556,
                40.525081427226304
              ],
              [
                -74.16613400493428,
                40.5253150796155
              ],
              [
                -74.16592084252487,
                40.52541220547592
              ],
              [
                -74.16542588856903,
                40.52559238703552
              ],
              [
                -74.16530607172459,
                40.52564918360975
              ],
              [
                -74.1650341131794,
                40.52582754961078
              ],
              [
                -74.16474657068605,
                40.52608034550148
              ],
              [
                -74.164602248092,
                40.526152798387734
              ],
              [
                -74.16439953582679,
                40.526209094151916
              ],
              [
                -74.16407189088119,
                40.52629215125523
              ],
              [
                -74.16400488402428,
                40.52631544294325
              ],
              [
                -74.16393757258862,
                40.52633604180663
              ],
              [
                -74.16389790294477,
                40.526342121675015
              ],
              [
                -74.16386460199075,
                40.52633280710802
              ],
              [
                -74.16382940261882,
                40.526328513449315
              ],
              [
                -74.16378904863853,
                40.52633450479553
              ],
              [
                -74.1637200159487,
                40.526350032996056
              ],
              [
                -74.16367305827846,
                40.526378857404445
              ],
              [
                -74.16361738290826,
                40.52641368125647
              ],
              [
                -74.16356404014883,
                40.52645216665508
              ],
              [
                -74.16351595922495,
                40.52649539134296
              ],
              [
                -74.16346849035503,
                40.526528296935574
              ],
              [
                -74.16340865825354,
                40.526566807919906
              ],
              [
                -74.1633490917526,
                40.5266030787796
              ],
              [
                -74.16328589798596,
                40.5266384434018
              ],
              [
                -74.16322654513108,
                40.5266750471119
              ],
              [
                -74.16317181206539,
                40.52671474203094
              ],
              [
                -74.16312608537125,
                40.52676093369093
              ],
              [
                -74.1630784953535,
                40.52678672410567
              ],
              [
                -74.1630173201182,
                40.52681663075127
              ],
              [
                -74.16295750607449,
                40.52685384545741
              ],
              [
                -74.16289520141652,
                40.5268902637081
              ],
              [
                -74.16282757854665,
                40.52691804388986
              ],
              [
                -74.16276280751576,
                40.52694471328185
              ],
              [
                -74.16269855315247,
                40.52697494795893
              ],
              [
                -74.16263198944374,
                40.52700732791633
              ],
              [
                -74.16256536120572,
                40.52703685337438
              ],
              [
                -74.16250698728896,
                40.52706896148613
              ],
              [
                -74.16244039830329,
                40.52709544379206
              ],
              [
                -74.16238512563739,
                40.52712627773966
              ],
              [
                -74.16233039191978,
                40.52716606171717
              ],
              [
                -74.16227196108895,
                40.527194262810895
              ],
              [
                -74.16222577962044,
                40.527202274889866
              ],
              [
                -74.16220244797076,
                40.52719607656213
              ],
              [
                -74.16214390483917,
                40.52718045998372
              ],
              [
                -74.16209796862164,
                40.527156973755986
              ],
              [
                -74.16206636173392,
                40.527144375191064
              ],
              [
                -74.16203117512099,
                40.52713034777669
              ],
              [
                -74.1619669783145,
                40.52710417763111
              ],
              [
                -74.161897175874,
                40.527088027312104
              ],
              [
                -74.16182749073316,
                40.527071615437734
              ],
              [
                -74.16175881551293,
                40.52704801621296
              ],
              [
                -74.16169141315441,
                40.52702390351627
              ],
              [
                -74.16164743469216,
                40.52701465879783
              ],
              [
                -74.16158217423518,
                40.527013477776656
              ],
              [
                -74.16154625377834,
                40.52702377298726
              ],
              [
                -74.16152666515211,
                40.52702938182942
              ],
              [
                -74.16061775898544,
                40.52746724757214
              ],
              [
                -74.16054717701897,
                40.527487268190406
              ],
              [
                -74.16047493730298,
                40.52750451007664
              ],
              [
                -74.16040893562746,
                40.52752986340302
              ],
              [
                -74.1603409737616,
                40.52754982813112
              ],
              [
                -74.1603042631396,
                40.527555747824614
              ],
              [
                -74.16026450758767,
                40.527552533640204
              ],
              [
                -74.16025716713632,
                40.527547438061426
              ],
              [
                -74.16023231856387,
                40.52753020940202
              ],
              [
                -74.16022582487547,
                40.52752444888366
              ],
              [
                -74.16020751740504,
                40.52750820806293
              ],
              [
                -74.16019359405244,
                40.52748925315915
              ],
              [
                -74.16016839366702,
                40.52744833231474
              ],
              [
                -74.16013065929954,
                40.52740191736376
              ],
              [
                -74.16008419227262,
                40.52736090101809
              ],
              [
                -74.16005554683676,
                40.527344938114375
              ],
              [
                -74.16004022395717,
                40.52734323910653
              ],
              [
                -74.16001146160204,
                40.52734281078555
              ],
              [
                -74.15999341625401,
                40.52734733915582
              ],
              [
                -74.15994367873692,
                40.52736460798024
              ],
              [
                -74.15988277075512,
                40.52736935827443
              ],
              [
                -74.16005732699995,
                40.52763504199989
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0000572999455414,
        "objectid": 88,
        "shape_leng": 0.0352046035327,
        "location_id": 88,
        "zone": "Financial District South",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -74.00974639299996,
                40.704775247999905
              ],
              [
                -74.00998418599995,
                40.70514773299991
              ],
              [
                -74.00999520599994,
                40.70533123899991
              ],
              [
                -74.0098658089999,
                40.70579642699993
              ],
              [
                -74.01118994199994,
                40.706278727999916
              ],
              [
                -74.01187330299997,
                40.70655140599994
              ],
              [
                -74.01240001199992,
                40.70678480799992
              ],
              [
                -74.01250773999998,
                40.706767065999934
              ],
              [
                -74.01325396399993,
                40.70587051399992
              ],
              [
                -74.01330942899993,
                40.70570683099988
              ],
              [
                -74.01334484799995,
                40.70553882699991
              ],
              [
                -74.01335946199991,
                40.70536865599994
              ],
              [
                -74.01335309799998,
                40.70519857699985
              ],
              [
                -74.01332610899988,
                40.7050307879999
              ],
              [
                -74.01326979499997,
                40.704553495999974
              ],
              [
                -74.01419226999991,
                40.7045704039999
              ],
              [
                -74.01428922899998,
                40.70454907499989
              ],
              [
                -74.01427065899998,
                40.70452022899989
              ],
              [
                -74.01425861399994,
                40.70448944299991
              ],
              [
                -74.01425342599994,
                40.70445756399993
              ],
              [
                -74.01425523899994,
                40.70442547199991
              ],
              [
                -74.014391769,
                40.70359878299993
              ],
              [
                -74.01442983199998,
                40.70315064799989
              ],
              [
                -74.014192151,
                40.70275671099996
              ],
              [
                -74.01389890399994,
                40.70227066999996
              ],
              [
                -74.01379120799997,
                40.70130547599995
              ],
              [
                -74.01378191199989,
                40.70122215499992
              ],
              [
                -74.01377528899992,
                40.7011827129999
              ],
              [
                -74.01376867899998,
                40.7011432629999
              ],
              [
                -74.01415893499998,
                40.701096289999896
              ],
              [
                -74.01395152778244,
                40.70099098222994
              ],
              [
                -74.01355826979739,
                40.70093987091524
              ],
              [
                -74.01351039646312,
                40.70065037107601
              ],
              [
                -74.01357712861271,
                40.70064368123795
              ],
              [
                -74.01355283214755,
                40.70050335758483
              ],
              [
                -74.0135854179925,
                40.70050191287805
              ],
              [
                -74.01358070009412,
                40.70045691424641
              ],
              [
                -74.01352295909443,
                40.70046041520351
              ],
              [
                -74.0135236177836,
                40.700441937789115
              ],
              [
                -74.01355970574456,
                40.70043893421369
              ],
              [
                -74.01356297105794,
                40.700380013103185
              ],
              [
                -74.01353147488012,
                40.70038201609179
              ],
              [
                -74.01352950894581,
                40.70037352406205
              ],
              [
                -74.01356034122136,
                40.70036653321863
              ],
              [
                -74.01356131218263,
                40.70031009779258
              ],
              [
                -74.01353047798223,
                40.700311100173295
              ],
              [
                -74.01353310202802,
                40.700297124122834
              ],
              [
                -74.01356130915947,
                40.7002951220791
              ],
              [
                -74.01357049004972,
                40.70023220268694
              ],
              [
                -74.01361182284573,
                40.700245174325296
              ],
              [
                -74.01364265243346,
                40.70016178290469
              ],
              [
                -74.01366274984949,
                40.70013665651763
              ],
              [
                -74.01367479307396,
                40.70012582122538
              ],
              [
                -74.01361029865362,
                40.7000842982948
              ],
              [
                -74.01358669315087,
                40.70007959034501
              ],
              [
                -74.01353285018102,
                40.70010816142329
              ],
              [
                -74.01350349660855,
                40.700176459528265
              ],
              [
                -74.01348311536108,
                40.700263388116326
              ],
              [
                -74.01348068493706,
                40.70035777106837
              ],
              [
                -74.01348560832729,
                40.700478222908394
              ],
              [
                -74.0132734948308,
                40.7004968702278
              ],
              [
                -74.01322288016905,
                40.700333586085506
              ],
              [
                -74.01320736787193,
                40.700275216518826
              ],
              [
                -74.01320084197312,
                40.700239827976105
              ],
              [
                -74.01318571684631,
                40.70010323034394
              ],
              [
                -74.01317753563323,
                40.70001754746251
              ],
              [
                -74.01316691626799,
                40.699931244337535
              ],
              [
                -74.01315384897737,
                40.69984494048546
              ],
              [
                -74.01314078965082,
                40.69980333792552
              ],
              [
                -74.01311303826309,
                40.69977416588525
              ],
              [
                -74.01304370092431,
                40.69977478473583
              ],
              [
                -74.01302085702764,
                40.699793419367936
              ],
              [
                -74.0130118926586,
                40.69981825663123
              ],
              [
                -74.01302495705559,
                40.699882830206
              ],
              [
                -74.01307843485947,
                40.70010324258431
              ],
              [
                -74.01309395831619,
                40.70022369323981
              ],
              [
                -74.01311153191459,
                40.70036029060313
              ],
              [
                -74.01311155389506,
                40.700472053044614
              ],
              [
                -74.01310747847366,
                40.70050745218069
              ],
              [
                -74.01300205335563,
                40.70051602896807
              ],
              [
                -74.01290881793962,
                40.7005236116935
              ],
              [
                -74.01290474249377,
                40.70049691216282
              ],
              [
                -74.01288188403379,
                40.70044165988615
              ],
              [
                -74.01286637756563,
                40.70040751337169
              ],
              [
                -74.01283454933404,
                40.700352874734435
              ],
              [
                -74.01279619139247,
                40.70030009988461
              ],
              [
                -74.0127349915856,
                40.70021877320353
              ],
              [
                -74.01268072728439,
                40.700146755381404
              ],
              [
                -74.0126480885809,
                40.70011508828302
              ],
              [
                -74.01261382087365,
                40.7001082658111
              ],
              [
                -74.01254202780153,
                40.7001349741483
              ],
              [
                -74.01251511280113,
                40.70015049211081
              ],
              [
                -74.01251593564186,
                40.700184648480175
              ],
              [
                -74.01252899955233,
                40.700252320356995
              ],
              [
                -74.01255471870927,
                40.700359730657794
              ],
              [
                -74.01259064684221,
                40.70051185050812
              ],
              [
                -74.01259392327508,
                40.70057083347242
              ],
              [
                -74.01239160032279,
                40.70062052809406
              ],
              [
                -74.01236222440257,
                40.70057582996201
              ],
              [
                -74.01231855974255,
                40.70048828759264
              ],
              [
                -74.01225490284888,
                40.70036473434146
              ],
              [
                -74.01223694549927,
                40.70032127784431
              ],
              [
                -74.01217247961002,
                40.700233115597726
              ],
              [
                -74.01215779158525,
                40.70021137864816
              ],
              [
                -74.01212759506136,
                40.70014308650679
              ],
              [
                -74.01208842091677,
                40.70008658368941
              ],
              [
                -74.01204027930196,
                40.70004809164556
              ],
              [
                -74.01196196116912,
                40.700037545595485
              ],
              [
                -74.01193096316563,
                40.70005120965431
              ],
              [
                -74.01185672556994,
                40.70010710340836
              ],
              [
                -74.01190488098494,
                40.700226930002
              ],
              [
                -74.0119767064249,
                40.7003784242992
              ],
              [
                -74.01203856797002,
                40.70052895630967
              ],
              [
                -74.0120693217167,
                40.7005997333441
              ],
              [
                -74.01208509377949,
                40.700664514803854
              ],
              [
                -74.01208668214687,
                40.70068130873922
              ],
              [
                -74.01199159781133,
                40.70070483146649
              ],
              [
                -74.01185378549594,
                40.70073892145015
              ],
              [
                -74.01184827026111,
                40.700729925805916
              ],
              [
                -74.01183407601519,
                40.70068613447613
              ],
              [
                -74.01182619233424,
                40.70066634278202
              ],
              [
                -74.0117773082774,
                40.70057097487754
              ],
              [
                -74.01175129264924,
                40.70053078758623
              ],
              [
                -74.01172605835686,
                40.70047860442191
              ],
              [
                -74.01170321011998,
                40.70048100238686
              ],
              [
                -74.01167798034295,
                40.7004618152074
              ],
              [
                -74.01163147918638,
                40.7004582182481
              ],
              [
                -74.01161413587107,
                40.70047261846256
              ],
              [
                -74.01161019835872,
                40.70049300642146
              ],
              [
                -74.01163149614032,
                40.70055539174732
              ],
              [
                -74.01166539833068,
                40.700637560590245
              ],
              [
                -74.011682753977,
                40.70069334506205
              ],
              [
                -74.01170009248334,
                40.700721538635975
              ],
              [
                -74.0117292681748,
                40.70077011805964
              ],
              [
                -74.011502911437,
                40.70081505897416
              ],
              [
                -74.011469474374,
                40.70074892825803
              ],
              [
                -74.01143269912247,
                40.70067454111621
              ],
              [
                -74.01139508123386,
                40.70059060767383
              ],
              [
                -74.0113817187564,
                40.70057025711792
              ],
              [
                -74.01136082267426,
                40.700565810999194
              ],
              [
                -74.01133409410409,
                40.700568353682264
              ],
              [
                -74.01130986410945,
                40.70057980119102
              ],
              [
                -74.0112981643226,
                40.70059888503188
              ],
              [
                -74.01130318921149,
                40.70064784403637
              ],
              [
                -74.0113583629136,
                40.70085195863629
              ],
              [
                -74.01115033556829,
                40.70089840010094
              ],
              [
                -74.01113362222915,
                40.70086787440551
              ],
              [
                -74.01110436404349,
                40.7008106494294
              ],
              [
                -74.01107427475965,
                40.70074833789611
              ],
              [
                -74.01104377249544,
                40.70068284521962
              ],
              [
                -74.01100364429968,
                40.70058110994409
              ],
              [
                -74.01093093205073,
                40.700415152334415
              ],
              [
                -74.01083751798708,
                40.70020722317945
              ],
              [
                -74.01082511780585,
                40.70020930486715
              ],
              [
                -74.01081026034738,
                40.70023406243366
              ],
              [
                -74.01092026515036,
                40.700452650129954
              ],
              [
                -74.01090112139876,
                40.70046223399991
              ],
              [
                -74.01092027169814,
                40.70049262380238
              ],
              [
                -74.0109344927399,
                40.700487624779925
              ],
              [
                -74.01096049734583,
                40.70053884409263
              ],
              [
                -74.01093861016889,
                40.7005563341749
              ],
              [
                -74.01093752366046,
                40.7005671573749
              ],
              [
                -74.01094845841567,
                40.70057631454651
              ],
              [
                -74.01097034745736,
                40.700575484207576
              ],
              [
                -74.01099825777675,
                40.700635024239574
              ],
              [
                -74.01097965965197,
                40.70064584911186
              ],
              [
                -74.01099990958122,
                40.70067374615791
              ],
              [
                -74.01101221338783,
                40.70066874613638
              ],
              [
                -74.01101659417203,
                40.70068415386645
              ],
              [
                -74.01100072817277,
                40.70069081961717
              ],
              [
                -74.01101386500967,
                40.70071163747197
              ],
              [
                -74.01102699993733,
                40.700712878931306
              ],
              [
                -74.01103575886044,
                40.700731617687474
              ],
              [
                -74.011021525765,
                40.70074120110609
              ],
              [
                -74.01104287442345,
                40.70075743496105
              ],
              [
                -74.01106312665488,
                40.700802819827345
              ],
              [
                -74.01105929378232,
                40.700811562771484
              ],
              [
                -74.01108063438,
                40.70085194885256
              ],
              [
                -74.01109514108846,
                40.70085153299264
              ],
              [
                -74.01109514358389,
                40.7008665271816
              ],
              [
                -74.01110886351543,
                40.700901420606286
              ],
              [
                -74.0110670856897,
                40.70091494089484
              ],
              [
                -74.0110783797454,
                40.70095712031517
              ],
              [
                -74.01112868539599,
                40.70106489745045
              ],
              [
                -74.0112097577877,
                40.701044583273756
              ],
              [
                -74.01128983905525,
                40.701249982244285
              ],
              [
                -74.01112667396997,
                40.70127499637674
              ],
              [
                -74.01111949092332,
                40.701268748305765
              ],
              [
                -74.01109588602684,
                40.70127499934889
              ],
              [
                -74.01116664151876,
                40.70147720220832
              ],
              [
                -74.01083741653544,
                40.70155334480756
              ],
              [
                -74.01026710345066,
                40.701698668814146
              ],
              [
                -74.00960052263278,
                40.701865481917835
              ],
              [
                -74.00862036163882,
                40.70055216421584
              ],
              [
                -74.0083492755909,
                40.7006693483882
              ],
              [
                -74.00853611510763,
                40.70091969574509
              ],
              [
                -74.00759136204225,
                40.70132808104814
              ],
              [
                -74.00775549150644,
                40.70154800167175
              ],
              [
                -74.00869964313567,
                40.701139867542174
              ],
              [
                -74.00930907655483,
                40.701954603022514
              ],
              [
                -74.00915955533813,
                40.70199730764996
              ],
              [
                -74.009127727589,
                40.7020083244217
              ],
              [
                -74.00837395801629,
                40.70246975865777
              ],
              [
                -74.00796578643664,
                40.702718157070166
              ],
              [
                -74.00770614970409,
                40.70287516162004
              ],
              [
                -74.00770527479769,
                40.70288182540726
              ],
              [
                -74.00765840854532,
                40.70290916746316
              ],
              [
                -74.00765095550902,
                40.702919839105135
              ],
              [
                -74.00764555180645,
                40.702928174389456
              ],
              [
                -74.00760498189766,
                40.70299314132919
              ],
              [
                -74.00763657899991,
                40.70300436799989
              ],
              [
                -74.0077967509999,
                40.70306351499989
              ],
              [
                -74.00782878499996,
                40.703086421999934
              ],
              [
                -74.0078548309999,
                40.70310778899988
              ],
              [
                -74.00797948599993,
                40.70318875499992
              ],
              [
                -74.00854172499996,
                40.70369761199989
              ],
              [
                -74.00898541299989,
                40.704079159999864
              ],
              [
                -74.00945463899993,
                40.70452785699992
              ],
              [
                -74.00974639299996,
                40.704775247999905
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000447548142373,
        "objectid": 89,
        "shape_leng": 0.122794569553,
        "location_id": 89,
        "zone": "Flatbush/Ditmas Park",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.95374853799996,
                40.63858633799993
              ],
              [
                -73.95404358599993,
                40.6388775489999
              ],
              [
                -73.95427358399985,
                40.6391155389999
              ],
              [
                -73.95476763299989,
                40.63962444699996
              ],
              [
                -73.95563820699982,
                40.64050392599989
              ],
              [
                -73.95571599999984,
                40.64058252599992
              ],
              [
                -73.95707031,
                40.64196067099995
              ],
              [
                -73.95746359899987,
                40.64237166999987
              ],
              [
                -73.95763644599988,
                40.64268949899991
              ],
              [
                -73.95789394399986,
                40.64401556199991
              ],
              [
                -73.95791873699986,
                40.64414326499991
              ],
              [
                -73.95797764699991,
                40.64457625599988
              ],
              [
                -73.95802176499988,
                40.645069961999894
              ],
              [
                -73.95803664499991,
                40.64530249999989
              ],
              [
                -73.95810672200003,
                40.64651800899988
              ],
              [
                -73.95814068499989,
                40.64750340399995
              ],
              [
                -73.95815003599986,
                40.64776767399996
              ],
              [
                -73.95825517200004,
                40.64853244899996
              ],
              [
                -73.95844335399988,
                40.64930344299989
              ],
              [
                -73.9587078989999,
                40.65038727299989
              ],
              [
                -73.95892527299989,
                40.65121154999988
              ],
              [
                -73.95915000999992,
                40.65214455699985
              ],
              [
                -73.95932926499988,
                40.652928178999936
              ],
              [
                -73.959451838,
                40.65357538799988
              ],
              [
                -73.95969665899997,
                40.65483921499991
              ],
              [
                -73.95986103199981,
                40.65563478899989
              ],
              [
                -73.96069809399995,
                40.6553409239999
              ],
              [
                -73.96156461500001,
                40.655004116999905
              ],
              [
                -73.96190028499997,
                40.65487096199992
              ],
              [
                -73.964144226,
                40.653994263999905
              ],
              [
                -73.96621321999996,
                40.65318940499991
              ],
              [
                -73.96575121499994,
                40.65249922899996
              ],
              [
                -73.96524103499988,
                40.65174204699988
              ],
              [
                -73.96465438699984,
                40.650887727999915
              ],
              [
                -73.96526281999996,
                40.6506424499999
              ],
              [
                -73.96614503299999,
                40.650297679999866
              ],
              [
                -73.9670280919999,
                40.649947273999885
              ],
              [
                -73.96790948399995,
                40.6495993739999
              ],
              [
                -73.96879367199982,
                40.64924826299994
              ],
              [
                -73.96967173699989,
                40.6488979359999
              ],
              [
                -73.97069344099991,
                40.648496775999945
              ],
              [
                -73.97139631700004,
                40.64825778599986
              ],
              [
                -73.97121039100001,
                40.64762015399991
              ],
              [
                -73.97084113799984,
                40.64637857099989
              ],
              [
                -73.97076976099986,
                40.64611090699987
              ],
              [
                -73.97054554399976,
                40.645316667999914
              ],
              [
                -73.97031677099991,
                40.64453794099992
              ],
              [
                -73.9700889779999,
                40.643745839999944
              ],
              [
                -73.96988464699999,
                40.64303029699992
              ],
              [
                -73.9696861179999,
                40.642356009999915
              ],
              [
                -73.96948468100001,
                40.641689896999935
              ],
              [
                -73.9693990309999,
                40.64138244599991
              ],
              [
                -73.96891461099989,
                40.63975715999988
              ],
              [
                -73.968790992,
                40.639255932999895
              ],
              [
                -73.96872218399989,
                40.639018537999895
              ],
              [
                -73.96822343400002,
                40.637296905999946
              ],
              [
                -73.96803868499985,
                40.63665098499994
              ],
              [
                -73.96935965799996,
                40.63650554999993
              ],
              [
                -73.97029036199991,
                40.63640634099988
              ],
              [
                -73.96986869299991,
                40.634194674999904
              ],
              [
                -73.97087567699991,
                40.63371951299991
              ],
              [
                -73.97190427099986,
                40.63323009399994
              ],
              [
                -73.97216145999994,
                40.63311162099996
              ],
              [
                -73.97231136199997,
                40.633041554999885
              ],
              [
                -73.97241686999978,
                40.632992044999874
              ],
              [
                -73.97281084499981,
                40.63280454799989
              ],
              [
                -73.97320194999996,
                40.632618080999876
              ],
              [
                -73.97431883499996,
                40.63209057399993
              ],
              [
                -73.97517146299998,
                40.63168858099992
              ],
              [
                -73.97604935699994,
                40.63127590599987
              ],
              [
                -73.97716511999984,
                40.630746653999886
              ],
              [
                -73.97699848899987,
                40.62987149599992
              ],
              [
                -73.97684964299985,
                40.62908858099995
              ],
              [
                -73.97669604399998,
                40.62835456399995
              ],
              [
                -73.97657775699989,
                40.627573185999914
              ],
              [
                -73.9765146209999,
                40.62729448999988
              ],
              [
                -73.97644970399986,
                40.62700825499989
              ],
              [
                -73.97623453700001,
                40.62597634999991
              ],
              [
                -73.975635809,
                40.62603520899996
              ],
              [
                -73.97507611299997,
                40.626113766999936
              ],
              [
                -73.97414911500005,
                40.62621489799991
              ],
              [
                -73.97322001099991,
                40.62631854299984
              ],
              [
                -73.97229894799983,
                40.626431167999904
              ],
              [
                -73.97119209799985,
                40.626542695999916
              ],
              [
                -73.97108160299985,
                40.62655370899989
              ],
              [
                -73.97092126300004,
                40.62656998499993
              ],
              [
                -73.97111421999993,
                40.62758588399992
              ],
              [
                -73.97120750499982,
                40.62808971199992
              ],
              [
                -73.97136622199992,
                40.62892916199998
              ],
              [
                -73.97109232299984,
                40.628966140999886
              ],
              [
                -73.97000109799986,
                40.62911347399989
              ],
              [
                -73.96892915899984,
                40.62923162599992
              ],
              [
                -73.96800084399993,
                40.62933305799992
              ],
              [
                -73.96707656900001,
                40.629434681999946
              ],
              [
                -73.96608191399986,
                40.62954270699991
              ],
              [
                -73.9650807279999,
                40.62965467499987
              ],
              [
                -73.96414862899985,
                40.629738964999966
              ],
              [
                -73.96322149299982,
                40.62984106099988
              ],
              [
                -73.96232599399991,
                40.6299687169999
              ],
              [
                -73.96197454299985,
                40.62999504099987
              ],
              [
                -73.96182099799995,
                40.63000487699987
              ],
              [
                -73.96163639899993,
                40.63002222299983
              ],
              [
                -73.96136921599995,
                40.6300583879999
              ],
              [
                -73.96046280599994,
                40.63015566099992
              ],
              [
                -73.96040734299989,
                40.63016253099992
              ],
              [
                -73.96025855099992,
                40.62941826299991
              ],
              [
                -73.96024902900002,
                40.629370621999904
              ],
              [
                -73.96022560099996,
                40.629254500999906
              ],
              [
                -73.96014773499996,
                40.62891518499989
              ],
              [
                -73.95995565099992,
                40.62777867199989
              ],
              [
                -73.95899416299996,
                40.62788559399996
              ],
              [
                -73.95805293100001,
                40.62797111899991
              ],
              [
                -73.95684697299987,
                40.628119878999925
              ],
              [
                -73.95581207199996,
                40.62823349199989
              ],
              [
                -73.95488499199999,
                40.62833554799986
              ],
              [
                -73.95395983399986,
                40.62843938999987
              ],
              [
                -73.95303133199995,
                40.62854011499994
              ],
              [
                -73.9520690179999,
                40.62864578099989
              ],
              [
                -73.95110641899993,
                40.628750466999904
              ],
              [
                -73.95017656999988,
                40.6288527719999
              ],
              [
                -73.94925229799985,
                40.62895476499993
              ],
              [
                -73.94832386299989,
                40.62905666099993
              ],
              [
                -73.94732672199994,
                40.62916656699985
              ],
              [
                -73.9463262219999,
                40.629276641999915
              ],
              [
                -73.94540053199997,
                40.629377811999845
              ],
              [
                -73.94446784099986,
                40.6294800519999
              ],
              [
                -73.94415535299991,
                40.6295082849999
              ],
              [
                -73.94453995099987,
                40.62986656299989
              ],
              [
                -73.94480816099995,
                40.6301271669999
              ],
              [
                -73.9455109549998,
                40.63079148299987
              ],
              [
                -73.94647961799998,
                40.6317081619999
              ],
              [
                -73.94561754699987,
                40.63177742999989
              ],
              [
                -73.94462885499995,
                40.63183773499993
              ],
              [
                -73.94488042799988,
                40.63417695399994
              ],
              [
                -73.9451233509999,
                40.636448278999886
              ],
              [
                -73.94537272499997,
                40.63878695499988
              ],
              [
                -73.94638150299986,
                40.63872624999992
              ],
              [
                -73.94715150299994,
                40.63867640599994
              ],
              [
                -73.94826499699982,
                40.63860718999994
              ],
              [
                -73.94923295699995,
                40.63854767299995
              ],
              [
                -73.95017361500003,
                40.638510596999886
              ],
              [
                -73.95116311199983,
                40.638448182999866
              ],
              [
                -73.95213852299987,
                40.63844349599993
              ],
              [
                -73.95307699899985,
                40.63844626099989
              ],
              [
                -73.9536000469999,
                40.63842234699991
              ],
              [
                -73.95374853799996,
                40.63858633799993
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0000553132476305,
        "objectid": 90,
        "shape_leng": 0.0307591620819,
        "location_id": 90,
        "zone": "Flatiron",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.9963368949999,
                40.73804374699991
              ],
              [
                -73.99587409499989,
                40.73868594599992
              ],
              [
                -73.99544664199995,
                40.7392721329999
              ],
              [
                -73.99501798599992,
                40.73986001099995
              ],
              [
                -73.99459264899987,
                40.74044198099993
              ],
              [
                -73.99416492399986,
                40.7410309369999
              ],
              [
                -73.99373928799994,
                40.74161720799987
              ],
              [
                -73.99329166800001,
                40.74223330099994
              ],
              [
                -73.99280340900002,
                40.74291001999997
              ],
              [
                -73.99231888499988,
                40.74356832299989
              ],
              [
                -73.99206647199993,
                40.74391620299988
              ],
              [
                -73.9918710239999,
                40.74418556999993
              ],
              [
                -73.99141858599994,
                40.74480268199988
              ],
              [
                -73.99426258599996,
                40.74600398099987
              ],
              [
                -73.99709902899994,
                40.74720510199991
              ],
              [
                -73.99755164599995,
                40.74658089999985
              ],
              [
                -73.99800822000003,
                40.74595130199988
              ],
              [
                -73.99848652499992,
                40.74529416399991
              ],
              [
                -73.99897237399988,
                40.74463005499994
              ],
              [
                -73.99942208999991,
                40.74400957299992
              ],
              [
                -73.9998482359998,
                40.743425018999915
              ],
              [
                -74.0002751799999,
                40.74283879799987
              ],
              [
                -74.00070275499993,
                40.7422536109999
              ],
              [
                -74.00112917499989,
                40.741668097999884
              ],
              [
                -74.00155531499993,
                40.741080025999935
              ],
              [
                -74.00201937799989,
                40.740444088999894
              ],
              [
                -74.00252381499989,
                40.73975264399995
              ],
              [
                -73.99968392199985,
                40.73855224899989
              ],
              [
                -73.99683993899998,
                40.73736088899995
              ],
              [
                -73.9963368949999,
                40.73804374699991
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0000764293209892,
        "objectid": 125,
        "shape_leng": 0.0497322121757,
        "location_id": 125,
        "zone": "Hudson Sq",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -74.00269315099992,
                40.728314054999906
              ],
              [
                -74.00281726599992,
                40.72836312899994
              ],
              [
                -74.00312767499993,
                40.72839380099989
              ],
              [
                -74.00531369199996,
                40.72860449399992
              ],
              [
                -74.0070902079999,
                40.72877225299994
              ],
              [
                -74.00859272099993,
                40.728915277999924
              ],
              [
                -74.00934961999998,
                40.72898755599988
              ],
              [
                -74.01054441199987,
                40.72910009799991
              ],
              [
                -74.01067264999996,
                40.72911266599989
              ],
              [
                -74.01076791499999,
                40.728173957999914
              ],
              [
                -74.01087083299997,
                40.72818049399996
              ],
              [
                -74.01100472899994,
                40.7281932389999
              ],
              [
                -74.01138323602366,
                40.728229272183555
              ],
              [
                -74.011542018976,
                40.726447346927074
              ],
              [
                -74.01234454766038,
                40.7265194304825
              ],
              [
                -74.01341677520719,
                40.72662103121345
              ],
              [
                -74.01430671596631,
                40.72670812119669
              ],
              [
                -74.01512356549452,
                40.72679394256458
              ],
              [
                -74.01514325523297,
                40.72671052627932
              ],
              [
                -74.01517084019778,
                40.72657939015507
              ],
              [
                -74.01518864981979,
                40.72645118250504
              ],
              [
                -74.01520426531131,
                40.72636194937966
              ],
              [
                -74.01516324118417,
                40.72631971193679
              ],
              [
                -74.01477821548454,
                40.7262817064733
              ],
              [
                -74.01411595444266,
                40.72621586380551
              ],
              [
                -74.01231588417461,
                40.726033843285414
              ],
              [
                -74.0119583307484,
                40.72597759972093
              ],
              [
                -74.01165326569942,
                40.72587176185136
              ],
              [
                -74.0116541510404,
                40.72586737457336
              ],
              [
                -74.01178072408688,
                40.72523995904116
              ],
              [
                -74.0119643202455,
                40.72432986461989
              ],
              [
                -74.01159122599992,
                40.724297603999936
              ],
              [
                -74.01143933,
                40.72428446899996
              ],
              [
                -74.01135428299995,
                40.724277182999884
              ],
              [
                -74.011176661,
                40.725169424999905
              ],
              [
                -74.01103681999996,
                40.72572537699997
              ],
              [
                -74.01102457999994,
                40.72579386199993
              ],
              [
                -74.01092277299992,
                40.72579415099991
              ],
              [
                -74.0108123829999,
                40.725789802999955
              ],
              [
                -74.01004078699998,
                40.72520616399988
              ],
              [
                -74.00931760399993,
                40.7247015519999
              ],
              [
                -74.00864380599997,
                40.72422131699991
              ],
              [
                -74.00857090899997,
                40.72416935499997
              ],
              [
                -74.00830216199988,
                40.723977808999905
              ],
              [
                -74.00807835299996,
                40.72382686299988
              ],
              [
                -74.00791205999997,
                40.72369976699988
              ],
              [
                -74.00762904199998,
                40.72349770399995
              ],
              [
                -74.00629841699995,
                40.72254950399988
              ],
              [
                -74.00591289899992,
                40.7222529489999
              ],
              [
                -74.0053098609999,
                40.72182419199987
              ],
              [
                -74.00535249999994,
                40.72189013499991
              ],
              [
                -74.00538048599999,
                40.721962173999934
              ],
              [
                -74.00539186699997,
                40.72203736699992
              ],
              [
                -74.00538616799996,
                40.722112360999894
              ],
              [
                -74.00536435999994,
                40.722183951999966
              ],
              [
                -74.00505887099996,
                40.7229778639999
              ],
              [
                -74.00478574899996,
                40.7236248379999
              ],
              [
                -74.004574744,
                40.72417312999994
              ],
              [
                -74.00427457099987,
                40.72491210899993
              ],
              [
                -74.00403067899997,
                40.72551249599992
              ],
              [
                -74.003730175,
                40.726250058999945
              ],
              [
                -74.00312206399995,
                40.72687712799991
              ],
              [
                -74.00251324499993,
                40.727600926999855
              ],
              [
                -74.00221100999997,
                40.727961316999874
              ],
              [
                -74.00214891499996,
                40.728035366999904
              ],
              [
                -74.00269315099992,
                40.728314054999906
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000537330013243,
        "objectid": 91,
        "shape_leng": 0.124996624585,
        "location_id": 91,
        "zone": "Flatlands",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.91945472999996,
                40.6401731369999
              ],
              [
                -73.91946141199999,
                40.640239292999844
              ],
              [
                -73.91946588499994,
                40.64028418699997
              ],
              [
                -73.91953493999995,
                40.64085774099989
              ],
              [
                -73.919555766,
                40.64118495799989
              ],
              [
                -73.91958298599987,
                40.64139576199993
              ],
              [
                -73.91965951399997,
                40.642078236999915
              ],
              [
                -73.91973736999986,
                40.64277814799987
              ],
              [
                -73.92025802399995,
                40.642366298999875
              ],
              [
                -73.92154948099987,
                40.64134469199992
              ],
              [
                -73.92332654599986,
                40.639900215999916
              ],
              [
                -73.92335409199994,
                40.64017632399992
              ],
              [
                -73.92337418899983,
                40.64049554399992
              ],
              [
                -73.92341500199991,
                40.640923605999916
              ],
              [
                -73.92356525899983,
                40.6424992979999
              ],
              [
                -73.92454228399995,
                40.64243917699988
              ],
              [
                -73.92518618999996,
                40.64239729099993
              ],
              [
                -73.92538233099985,
                40.64238453199988
              ],
              [
                -73.92547887299986,
                40.64158704199992
              ],
              [
                -73.9255828079999,
                40.641070030999856
              ],
              [
                -73.92562125699988,
                40.640811219999925
              ],
              [
                -73.92570388299987,
                40.64036094999992
              ],
              [
                -73.92574389399985,
                40.64001446599986
              ],
              [
                -73.92582711299985,
                40.639626269999894
              ],
              [
                -73.925834756,
                40.63941858799992
              ],
              [
                -73.92586068599985,
                40.639211070999906
              ],
              [
                -73.92590483099994,
                40.63900510599991
              ],
              [
                -73.92596691799999,
                40.63880208899997
              ],
              [
                -73.92604638799999,
                40.638603340999964
              ],
              [
                -73.92606338099993,
                40.63852406099988
              ],
              [
                -73.9260813459999,
                40.63816294599991
              ],
              [
                -73.9261268489999,
                40.63774352299989
              ],
              [
                -73.92616377799996,
                40.63757296099988
              ],
              [
                -73.9262739919998,
                40.637215327999876
              ],
              [
                -73.92652490899992,
                40.6364927229999
              ],
              [
                -73.92668615699995,
                40.636257087999915
              ],
              [
                -73.92674442999991,
                40.6361731059999
              ],
              [
                -73.92682085699984,
                40.63606066199992
              ],
              [
                -73.9269145769998,
                40.6359394399999
              ],
              [
                -73.92712769499995,
                40.63566745599997
              ],
              [
                -73.92744822299986,
                40.63525840999988
              ],
              [
                -73.92766858599995,
                40.635245022999925
              ],
              [
                -73.92861283799981,
                40.63518764599998
              ],
              [
                -73.92962453299995,
                40.63512131599988
              ],
              [
                -73.93055333099983,
                40.63506291499989
              ],
              [
                -73.9315273509998,
                40.63500796799989
              ],
              [
                -73.93223554799995,
                40.634963338999924
              ],
              [
                -73.93249995699995,
                40.63494706899993
              ],
              [
                -73.93342687599988,
                40.634884050999915
              ],
              [
                -73.93439440199988,
                40.634826802999925
              ],
              [
                -73.93535985000001,
                40.634767311999916
              ],
              [
                -73.93629596699988,
                40.634709525999874
              ],
              [
                -73.9372670019998,
                40.634650624999885
              ],
              [
                -73.93706492799993,
                40.632771166999945
              ],
              [
                -73.93703669099992,
                40.63250924999988
              ],
              [
                -73.93701746200003,
                40.63231097899991
              ],
              [
                -73.9374083309999,
                40.63228068499995
              ],
              [
                -73.93759273599986,
                40.63226785699987
              ],
              [
                -73.93799799799994,
                40.63224680599991
              ],
              [
                -73.93892456999993,
                40.63219308999988
              ],
              [
                -73.93985458399987,
                40.63213497499991
              ],
              [
                -73.94032359799989,
                40.63210526799983
              ],
              [
                -73.94078821499988,
                40.6320758459999
              ],
              [
                -73.94175680799997,
                40.63201662699988
              ],
              [
                -73.94272877999995,
                40.631957293999896
              ],
              [
                -73.94365994499996,
                40.631899426999915
              ],
              [
                -73.94462885399984,
                40.631837734999884
              ],
              [
                -73.94561754699987,
                40.63177742999989
              ],
              [
                -73.94647961799998,
                40.6317081619999
              ],
              [
                -73.9455109549998,
                40.63079148299987
              ],
              [
                -73.94480816099995,
                40.6301271669999
              ],
              [
                -73.94453995099987,
                40.62986656299989
              ],
              [
                -73.94415535299991,
                40.6295082849999
              ],
              [
                -73.94446784099986,
                40.6294800519999
              ],
              [
                -73.94540053199997,
                40.629377811999845
              ],
              [
                -73.9463262219999,
                40.629276641999915
              ],
              [
                -73.94732672199994,
                40.62916656699985
              ],
              [
                -73.94687439899997,
                40.62677366799994
              ],
              [
                -73.946422661,
                40.62438556699992
              ],
              [
                -73.94542442899984,
                40.62449560999993
              ],
              [
                -73.9449703129999,
                40.6221045119999
              ],
              [
                -73.94451833400001,
                40.61971364699989
              ],
              [
                -73.94551663599992,
                40.61960561199992
              ],
              [
                -73.94651373199997,
                40.61949401899995
              ],
              [
                -73.94606699600003,
                40.617103738999894
              ],
              [
                -73.94576359499983,
                40.61550406399987
              ],
              [
                -73.94573691999982,
                40.6153634069999
              ],
              [
                -73.9448277099998,
                40.61604620899986
              ],
              [
                -73.94467599499988,
                40.61616511299997
              ],
              [
                -73.94395779499983,
                40.6168163089999
              ],
              [
                -73.94346961999989,
                40.61738500099993
              ],
              [
                -73.94325340699987,
                40.61741206399994
              ],
              [
                -73.94314510099991,
                40.61742561699991
              ],
              [
                -73.94221251499997,
                40.61752605499987
              ],
              [
                -73.94138899899984,
                40.6176174189999
              ],
              [
                -73.941282159,
                40.61762926999994
              ],
              [
                -73.94078914099985,
                40.61769678999986
              ],
              [
                -73.94045274699987,
                40.617929049999894
              ],
              [
                -73.94037010899983,
                40.617992385999884
              ],
              [
                -73.9397167159999,
                40.61840609599988
              ],
              [
                -73.93949341999998,
                40.61855079099992
              ],
              [
                -73.93900236399998,
                40.61886727099993
              ],
              [
                -73.93864732399993,
                40.61909724599993
              ],
              [
                -73.93828802399997,
                40.61932901899992
              ],
              [
                -73.93781351899986,
                40.6196520959999
              ],
              [
                -73.93757616399986,
                40.61979552899988
              ],
              [
                -73.93696272700001,
                40.620199140999944
              ],
              [
                -73.93686699599992,
                40.62025328099991
              ],
              [
                -73.93501858699992,
                40.61860011099992
              ],
              [
                -73.93320457599995,
                40.6169704419999
              ],
              [
                -73.93248727399993,
                40.61743170199987
              ],
              [
                -73.93173179199982,
                40.61792692099988
              ],
              [
                -73.93060244599984,
                40.616922262999914
              ],
              [
                -73.92989884499987,
                40.61629427199993
              ],
              [
                -73.92946094800006,
                40.61589681999993
              ],
              [
                -73.9284298539999,
                40.61497277999991
              ],
              [
                -73.92808935399992,
                40.61466788499994
              ],
              [
                -73.92776130099995,
                40.61437342499989
              ],
              [
                -73.92769203599991,
                40.61431264099985
              ],
              [
                -73.92739580799999,
                40.6140526909999
              ],
              [
                -73.92629604599998,
                40.613116798999926
              ],
              [
                -73.92629769899993,
                40.61319215499987
              ],
              [
                -73.9263159899999,
                40.61343967599986
              ],
              [
                -73.92640044599995,
                40.61447853499988
              ],
              [
                -73.9253954579999,
                40.6145405629999
              ],
              [
                -73.92446651399993,
                40.614599417999955
              ],
              [
                -73.92349786399998,
                40.61465815799991
              ],
              [
                -73.92252930300005,
                40.61471903299993
              ],
              [
                -73.92159370099995,
                40.614778183999874
              ],
              [
                -73.92062400299996,
                40.61483868199991
              ],
              [
                -73.9205269409999,
                40.61394269499988
              ],
              [
                -73.920107,
                40.614212128999895
              ],
              [
                -73.9196079709999,
                40.61453446399989
              ],
              [
                -73.91937088699989,
                40.61469022299991
              ],
              [
                -73.91893348499988,
                40.61496777999992
              ],
              [
                -73.91862838199995,
                40.615168606999916
              ],
              [
                -73.91788753399999,
                40.61564657999988
              ],
              [
                -73.91739073199989,
                40.6159663059999
              ],
              [
                -73.91719587399997,
                40.61609171599989
              ],
              [
                -73.91690302699992,
                40.61627422899993
              ],
              [
                -73.91697651699978,
                40.61693221199992
              ],
              [
                -73.9170081,
                40.6172019239999
              ],
              [
                -73.91711554399998,
                40.617925190999884
              ],
              [
                -73.91716779499997,
                40.61838796199991
              ],
              [
                -73.91717137699983,
                40.618711251999905
              ],
              [
                -73.91726440900005,
                40.61954803699994
              ],
              [
                -73.91741279799999,
                40.62095853099987
              ],
              [
                -73.91746991599989,
                40.62151242899988
              ],
              [
                -73.91751556199989,
                40.621940315999936
              ],
              [
                -73.91770927100005,
                40.6237176859999
              ],
              [
                -73.91775240199999,
                40.624164716999864
              ],
              [
                -73.91799464599991,
                40.62645772299992
              ],
              [
                -73.91816260199982,
                40.62800449499985
              ],
              [
                -73.91824254499994,
                40.62877007499988
              ],
              [
                -73.91860649599991,
                40.63209041499988
              ],
              [
                -73.9186298239999,
                40.632346872999904
              ],
              [
                -73.91876282599999,
                40.6336375289999
              ],
              [
                -73.91885164499996,
                40.634482718999955
              ],
              [
                -73.91886199299982,
                40.634577127999904
              ],
              [
                -73.91886934599994,
                40.63464421599985
              ],
              [
                -73.91888284299993,
                40.63476735199989
              ],
              [
                -73.91889071099986,
                40.63483913299993
              ],
              [
                -73.91891212099982,
                40.63503445999985
              ],
              [
                -73.9189216129999,
                40.63512106099993
              ],
              [
                -73.91893983700001,
                40.63528732099991
              ],
              [
                -73.91895156599989,
                40.63539431799991
              ],
              [
                -73.91899154599992,
                40.635759062999945
              ],
              [
                -73.919006295,
                40.63589361799995
              ],
              [
                -73.91902770799979,
                40.636097543999895
              ],
              [
                -73.91905790399989,
                40.6363851099999
              ],
              [
                -73.9191648269999,
                40.63740334199989
              ],
              [
                -73.91917180399989,
                40.637469840999934
              ],
              [
                -73.91928984599986,
                40.638596032999914
              ],
              [
                -73.91941447399985,
                40.63977466499991
              ],
              [
                -73.91945472999996,
                40.6401731369999
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000374946617289,
        "objectid": 92,
        "shape_leng": 0.117830066799,
        "location_id": 92,
        "zone": "Flushing",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.82656578899986,
                40.770641475999895
              ],
              [
                -73.82657511799988,
                40.771630352999935
              ],
              [
                -73.82657481899986,
                40.772345397999885
              ],
              [
                -73.82647720799984,
                40.77311644299987
              ],
              [
                -73.82625348499994,
                40.77452065899989
              ],
              [
                -73.82583089999997,
                40.77591527099988
              ],
              [
                -73.82486800999989,
                40.77654235499993
              ],
              [
                -73.82433101899987,
                40.776743069999924
              ],
              [
                -73.8242835999999,
                40.77698996099984
              ],
              [
                -73.82422147299988,
                40.77723522699992
              ],
              [
                -73.82414480499983,
                40.77747809299996
              ],
              [
                -73.82405384599981,
                40.7777178299999
              ],
              [
                -73.82382108999985,
                40.77836013799991
              ],
              [
                -73.82376244899996,
                40.778504551999895
              ],
              [
                -73.82354681599996,
                40.7790355379999
              ],
              [
                -73.82331286899986,
                40.77957865999988
              ],
              [
                -73.82291816299993,
                40.78053566699986
              ],
              [
                -73.82277596999982,
                40.780833776999906
              ],
              [
                -73.8226154139999,
                40.78112682399995
              ],
              [
                -73.82243697299995,
                40.78141383699989
              ],
              [
                -73.82224127699999,
                40.78169387899992
              ],
              [
                -73.82384132799994,
                40.78171688599984
              ],
              [
                -73.82462418799997,
                40.781705824999925
              ],
              [
                -73.82498993100002,
                40.7817014639999
              ],
              [
                -73.82513740299993,
                40.78169970599994
              ],
              [
                -73.82557907999986,
                40.78083309199999
              ],
              [
                -73.82576928399986,
                40.78046316399985
              ],
              [
                -73.82607916299985,
                40.77985255099992
              ],
              [
                -73.82616437200001,
                40.77968464499987
              ],
              [
                -73.82642473899999,
                40.779228734999954
              ],
              [
                -73.82665895999996,
                40.778818601999895
              ],
              [
                -73.82789985099988,
                40.77668223299992
              ],
              [
                -73.82830621299996,
                40.776000787999905
              ],
              [
                -73.828793895,
                40.77524292899992
              ],
              [
                -73.82908256199991,
                40.77479432099991
              ],
              [
                -73.83000011099983,
                40.77373240699996
              ],
              [
                -73.83090463599999,
                40.7729830689999
              ],
              [
                -73.83150226899997,
                40.77248795599992
              ],
              [
                -73.83161455499989,
                40.77241020899988
              ],
              [
                -73.83209910199999,
                40.77210429399991
              ],
              [
                -73.83338983199998,
                40.77134538399991
              ],
              [
                -73.83433960199982,
                40.770787819999896
              ],
              [
                -73.83511636199991,
                40.77028363399989
              ],
              [
                -73.83536623599981,
                40.77012144099995
              ],
              [
                -73.83614052499993,
                40.76952737399995
              ],
              [
                -73.83678033699985,
                40.76896614799993
              ],
              [
                -73.83711212099998,
                40.768642871999894
              ],
              [
                -73.83746794599983,
                40.76830671699988
              ],
              [
                -73.83805766700006,
                40.76759634399986
              ],
              [
                -73.8386015299999,
                40.76683050999986
              ],
              [
                -73.83866554599996,
                40.76674036599996
              ],
              [
                -73.83871102200001,
                40.76666838599988
              ],
              [
                -73.83913189281712,
                40.765991105784146
              ],
              [
                -73.8394581342306,
                40.76544576822929
              ],
              [
                -73.83935912299995,
                40.7654098719999
              ],
              [
                -73.83922344399986,
                40.76533629199986
              ],
              [
                -73.83908354399988,
                40.76525691299991
              ],
              [
                -73.83790429599993,
                40.764587796999876
              ],
              [
                -73.8369514729998,
                40.76283036999996
              ],
              [
                -73.83691694399995,
                40.7627666799999
              ],
              [
                -73.83689970199995,
                40.7626981179999
              ],
              [
                -73.83689455800001,
                40.762602683999944
              ],
              [
                -73.83679107299986,
                40.761166670999934
              ],
              [
                -73.83861811099983,
                40.75709081599995
              ],
              [
                -73.83867321899987,
                40.75701997899995
              ],
              [
                -73.83846752599989,
                40.756040155999905
              ],
              [
                -73.83838479599993,
                40.755640366999884
              ],
              [
                -73.83834041199985,
                40.75542546299987
              ],
              [
                -73.83827816299996,
                40.755233377999964
              ],
              [
                -73.83814233299978,
                40.75485403699987
              ],
              [
                -73.83805974199996,
                40.754666606999905
              ],
              [
                -73.83798921299994,
                40.75451504299986
              ],
              [
                -73.83778038200002,
                40.75413068299993
              ],
              [
                -73.83766909799984,
                40.75411369599991
              ],
              [
                -73.83743572399987,
                40.75408295599989
              ],
              [
                -73.83701406699998,
                40.75402742099987
              ],
              [
                -73.83599840100001,
                40.75200573699994
              ],
              [
                -73.83560230899995,
                40.75139485299991
              ],
              [
                -73.83229535699988,
                40.75194179099986
              ],
              [
                -73.83226187399998,
                40.75182272099988
              ],
              [
                -73.832247237,
                40.75170059099991
              ],
              [
                -73.83225199799976,
                40.75157800299994
              ],
              [
                -73.83227581899978,
                40.751457595999916
              ],
              [
                -73.83228373899995,
                40.751425268999895
              ],
              [
                -73.83277623900001,
                40.74931349399993
              ],
              [
                -73.83311678399998,
                40.74804727099989
              ],
              [
                -73.83308582399988,
                40.74798912499989
              ],
              [
                -73.83300302099987,
                40.74783363099988
              ],
              [
                -73.8312547989999,
                40.74810635999991
              ],
              [
                -73.83128299699986,
                40.7482212609999
              ],
              [
                -73.83129377999995,
                40.74833944999994
              ],
              [
                -73.83128640999993,
                40.74845858499988
              ],
              [
                -73.83126084299984,
                40.748576271999895
              ],
              [
                -73.83121778600005,
                40.748690122999925
              ],
              [
                -73.83115861599994,
                40.7487979979999
              ],
              [
                -73.8310920649999,
                40.74886061199991
              ],
              [
                -73.8310116789999,
                40.7489160189999
              ],
              [
                -73.83091972599982,
                40.74896171099987
              ],
              [
                -73.83081942999986,
                40.74899579199993
              ],
              [
                -73.83071466599985,
                40.74901721399987
              ],
              [
                -73.83060941400001,
                40.74902588399991
              ],
              [
                -73.82965157399984,
                40.749171992999926
              ],
              [
                -73.82943632699994,
                40.74920530899993
              ],
              [
                -73.82922511999989,
                40.749256080999906
              ],
              [
                -73.82902159099983,
                40.74932374899986
              ],
              [
                -73.82882914999982,
                40.74940718399992
              ],
              [
                -73.82865083499996,
                40.749504731999856
              ],
              [
                -73.82846651899989,
                40.74959657899998
              ],
              [
                -73.82827349999988,
                40.749678714999966
              ],
              [
                -73.82807314199995,
                40.749750465999945
              ],
              [
                -73.82786693899982,
                40.74981128499993
              ],
              [
                -73.82765641899984,
                40.749860830999886
              ],
              [
                -73.82751792599984,
                40.749874789999886
              ],
              [
                -73.82737756299996,
                40.74987437099992
              ],
              [
                -73.82723921499992,
                40.74985960299993
              ],
              [
                -73.82673694299986,
                40.74967723499986
              ],
              [
                -73.82584440999999,
                40.74934839099993
              ],
              [
                -73.82574065099986,
                40.750274963999864
              ],
              [
                -73.82569664700004,
                40.75039824999986
              ],
              [
                -73.8256669259999,
                40.750524743999904
              ],
              [
                -73.8256520129999,
                40.75065299899992
              ],
              [
                -73.82565209100001,
                40.75078150099996
              ],
              [
                -73.82566698599992,
                40.7509087479999
              ],
              [
                -73.82576557299983,
                40.750969023999914
              ],
              [
                -73.82518837299989,
                40.75128858499995
              ],
              [
                -73.82492269399982,
                40.751440294999924
              ],
              [
                -73.8246103029999,
                40.75162674799987
              ],
              [
                -73.82426493900002,
                40.75183288899994
              ],
              [
                -73.82321319899982,
                40.7507079319999
              ],
              [
                -73.82239598999983,
                40.74986422299987
              ],
              [
                -73.82131898199997,
                40.748758182999914
              ],
              [
                -73.82108427599998,
                40.748558445999954
              ],
              [
                -73.82052281799989,
                40.7483568499999
              ],
              [
                -73.81961962699988,
                40.748042042999934
              ],
              [
                -73.8187314289999,
                40.74771190099991
              ],
              [
                -73.81782025899999,
                40.74738541599988
              ],
              [
                -73.81691898700001,
                40.747204667999895
              ],
              [
                -73.81657996199982,
                40.74713778799989
              ],
              [
                -73.81575069499983,
                40.746979479999915
              ],
              [
                -73.8153731739999,
                40.747218337999925
              ],
              [
                -73.81598751399984,
                40.747769935999905
              ],
              [
                -73.81654271199996,
                40.74826004199988
              ],
              [
                -73.81710925599998,
                40.748760543999936
              ],
              [
                -73.81768650999989,
                40.74928821199994
              ],
              [
                -73.81779541099995,
                40.74939201499992
              ],
              [
                -73.81826477299987,
                40.749826989999875
              ],
              [
                -73.81883698299988,
                40.75035215799988
              ],
              [
                -73.81911566899987,
                40.750621072999856
              ],
              [
                -73.81941130799996,
                40.75088775499989
              ],
              [
                -73.81998860999985,
                40.751428620999896
              ],
              [
                -73.820849288,
                40.75224154499988
              ],
              [
                -73.81977819399994,
                40.7529368949999
              ],
              [
                -73.819092321,
                40.753384819999916
              ],
              [
                -73.8184078289999,
                40.75383042199998
              ],
              [
                -73.82030067699999,
                40.75551497999992
              ],
              [
                -73.81961754399985,
                40.75596557899992
              ],
              [
                -73.81926771999996,
                40.75619984799989
              ],
              [
                -73.81893695399998,
                40.75640957799994
              ],
              [
                -73.81789245699991,
                40.75708633799991
              ],
              [
                -73.81856914499988,
                40.75751884899991
              ],
              [
                -73.819232864,
                40.75794681199992
              ],
              [
                -73.82004500599989,
                40.75848322799986
              ],
              [
                -73.82063593299985,
                40.758872267999905
              ],
              [
                -73.82080684899987,
                40.7589417829999
              ],
              [
                -73.82116404999985,
                40.75968607499994
              ],
              [
                -73.82155809699998,
                40.76053432399992
              ],
              [
                -73.82176735399993,
                40.760979813999924
              ],
              [
                -73.8218107709998,
                40.76107223699989
              ],
              [
                -73.8220716649999,
                40.761625090999885
              ],
              [
                -73.82260068399988,
                40.76275987799987
              ],
              [
                -73.82302513300002,
                40.7636663409999
              ],
              [
                -73.82348245199985,
                40.76465179199993
              ],
              [
                -73.82372658199988,
                40.7658255159999
              ],
              [
                -73.82395032599992,
                40.76688720199996
              ],
              [
                -73.82421202499988,
                40.76816922799988
              ],
              [
                -73.82444330299992,
                40.769285513999904
              ],
              [
                -73.82466908999987,
                40.77040760799985
              ],
              [
                -73.8255648519999,
                40.770282959999925
              ],
              [
                -73.82604927499996,
                40.77021081399988
              ],
              [
                -73.82655841999986,
                40.77013708399991
              ],
              [
                -73.82656578899986,
                40.770641475999895
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000594278183978,
        "objectid": 93,
        "shape_leng": 0.170998027597,
        "location_id": 93,
        "zone": "Flushing Meadows-Corona Park",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.8569635593979,
                40.76407442786309
              ],
              [
                -73.85697078354372,
                40.76406606756879
              ],
              [
                -73.85683888701782,
                40.76400017911488
              ],
              [
                -73.85758834275414,
                40.763133551992205
              ],
              [
                -73.85760068527979,
                40.76314040113684
              ],
              [
                -73.85763896602006,
                40.76309944863466
              ],
              [
                -73.85775931084058,
                40.76315953255724
              ],
              [
                -73.85777061776874,
                40.76316517706584
              ],
              [
                -73.85784979492291,
                40.76320470688977
              ],
              [
                -73.85786245501016,
                40.76321102718345
              ],
              [
                -73.85794243473829,
                40.763250958823285
              ],
              [
                -73.85795122974932,
                40.76325534913242
              ],
              [
                -73.85803917484533,
                40.76329925703871
              ],
              [
                -73.85805097021218,
                40.76330514587176
              ],
              [
                -73.85812806185179,
                40.76334363326727
              ],
              [
                -73.85813903143266,
                40.763349110468035
              ],
              [
                -73.8582413092282,
                40.763400172809334
              ],
              [
                -73.8582525680361,
                40.763405793589826
              ],
              [
                -73.8583381131546,
                40.763448503350624
              ],
              [
                -73.8583507077488,
                40.76345478950192
              ],
              [
                -73.85839531409529,
                40.76347705912173
              ],
              [
                -73.85832948824915,
                40.763555851275136
              ],
              [
                -73.85834319308863,
                40.76356256146059
              ],
              [
                -73.85850081255238,
                40.76337643358506
              ],
              [
                -73.85848633892351,
                40.76336934637805
              ],
              [
                -73.8584153868299,
                40.76345313042265
              ],
              [
                -73.85835219792034,
                40.76342211890685
              ],
              [
                -73.85826783696172,
                40.76338071714841
              ],
              [
                -73.85825464723334,
                40.763374244804325
              ],
              [
                -73.85816421582143,
                40.76332986291929
              ],
              [
                -73.85815280980245,
                40.763324266072615
              ],
              [
                -73.85815033962187,
                40.763323053661004
              ],
              [
                -73.85805470915611,
                40.76327612040054
              ],
              [
                -73.85796457800302,
                40.76323188698831
              ],
              [
                -73.85788103167226,
                40.763190884493554
              ],
              [
                -73.85786962064152,
                40.76318528375923
              ],
              [
                -73.85777497639124,
                40.76313883512948
              ],
              [
                -73.85765473616219,
                40.76307982335119
              ],
              [
                -73.85768965370534,
                40.763036305706244
              ],
              [
                -73.85767843741588,
                40.76302774852689
              ],
              [
                -73.85773293300596,
                40.76296169164485
              ],
              [
                -73.85773856319483,
                40.76295513258302
              ],
              [
                -73.8577925082393,
                40.76289229618249
              ],
              [
                -73.85780092280253,
                40.762882493565314
              ],
              [
                -73.857859431059,
                40.762814339626146
              ],
              [
                -73.85786819502788,
                40.76280413118438
              ],
              [
                -73.85792332108373,
                40.762739915596725
              ],
              [
                -73.85793183124306,
                40.762730003860696
              ],
              [
                -73.85799432914435,
                40.762657200731454
              ],
              [
                -73.85800186588557,
                40.762648420768734
              ],
              [
                -73.85807451463484,
                40.76256379539837
              ],
              [
                -73.85808172268878,
                40.762555399832635
              ],
              [
                -73.85815675514272,
                40.76246799574389
              ],
              [
                -73.85816550201432,
                40.76245780652361
              ],
              [
                -73.85823701549468,
                40.76237450310679
              ],
              [
                -73.85824801084416,
                40.762361693691965
              ],
              [
                -73.85832178366148,
                40.76227575597448
              ],
              [
                -73.85833010330997,
                40.762266065611094
              ],
              [
                -73.85840137452446,
                40.76218304290122
              ],
              [
                -73.85840968995647,
                40.762173355877366
              ],
              [
                -73.85846562340063,
                40.76210880228018
              ],
              [
                -73.85846125776055,
                40.76210605116506
              ],
              [
                -73.85847289638566,
                40.762092199501595
              ],
              [
                -73.8584808798073,
                40.76208269986136
              ],
              [
                -73.85853208671435,
                40.76202176133262
              ],
              [
                -73.85854218676641,
                40.762026898691
              ],
              [
                -73.85861915281858,
                40.7619355969989
              ],
              [
                -73.85884371506147,
                40.76205876728172
              ],
              [
                -73.85869110268314,
                40.76222119050096
              ],
              [
                -73.85871170031803,
                40.76223376195505
              ],
              [
                -73.85883984926524,
                40.7621053222161
              ],
              [
                -73.85888722726258,
                40.76213360939854
              ],
              [
                -73.85876525911517,
                40.76226519184914
              ],
              [
                -73.85864510487932,
                40.76251439590902
              ],
              [
                -73.85867190067573,
                40.76252227000639
              ],
              [
                -73.85879204218736,
                40.762279338747646
              ],
              [
                -73.85892298182517,
                40.76212234652152
              ],
              [
                -73.85898423264058,
                40.762171474471316
              ],
              [
                -73.85899197332793,
                40.76224652370996
              ],
              [
                -73.85892056312618,
                40.762406401185224
              ],
              [
                -73.85907893363806,
                40.7625228322708
              ],
              [
                -73.85915607632674,
                40.76251963069957
              ],
              [
                -73.85922062887967,
                40.762482744481545
              ],
              [
                -73.8594133939809,
                40.7624832653393
              ],
              [
                -73.85949072467385,
                40.76256261539988
              ],
              [
                -73.85975083822206,
                40.7628778391954
              ],
              [
                -73.86001698390152,
                40.76326428551858
              ],
              [
                -73.86043337809801,
                40.76386888243775
              ],
              [
                -73.86057039699999,
                40.76383121299988
              ],
              [
                -73.86093691499987,
                40.76372861499989
              ],
              [
                -73.86109724399991,
                40.7636644779999
              ],
              [
                -73.85992937999985,
                40.76220030099995
              ],
              [
                -73.859666663,
                40.76190191299991
              ],
              [
                -73.8591354529999,
                40.76141844099988
              ],
              [
                -73.85912375899987,
                40.76127549899995
              ],
              [
                -73.8589662809999,
                40.761158795999904
              ],
              [
                -73.85880387699986,
                40.761046063999935
              ],
              [
                -73.858636721,
                40.76093742299989
              ],
              [
                -73.8584649899998,
                40.760832988999915
              ],
              [
                -73.85828886699981,
                40.76073287299992
              ],
              [
                -73.85810853999996,
                40.76063718199991
              ],
              [
                -73.857924202,
                40.76054601799995
              ],
              [
                -73.85773604799981,
                40.760459477999866
              ],
              [
                -73.85754427999994,
                40.76037765399996
              ],
              [
                -73.85734910199996,
                40.76030063399989
              ],
              [
                -73.856705981,
                40.760043865999926
              ],
              [
                -73.85655895700003,
                40.75998156099995
              ],
              [
                -73.85638216199997,
                40.7599066399999
              ],
              [
                -73.85603980899994,
                40.75972578699986
              ],
              [
                -73.85576997499993,
                40.75953834299993
              ],
              [
                -73.85553311699984,
                40.759342590999935
              ],
              [
                -73.85525140099982,
                40.759147529999915
              ],
              [
                -73.855067026,
                40.758943277999954
              ],
              [
                -73.85500920899997,
                40.758879225999905
              ],
              [
                -73.85463995799994,
                40.75846615799992
              ],
              [
                -73.8545797199998,
                40.75838761999991
              ],
              [
                -73.85449593299987,
                40.75827863899985
              ],
              [
                -73.85436796999998,
                40.758119082999876
              ],
              [
                -73.85405570299993,
                40.75760316299988
              ],
              [
                -73.85379813799992,
                40.757177611999914
              ],
              [
                -73.85377320199977,
                40.75711640099996
              ],
              [
                -73.85354137499989,
                40.75656303299997
              ],
              [
                -73.85339582599983,
                40.75614235899993
              ],
              [
                -73.8533527399998,
                40.756017825999905
              ],
              [
                -73.8531874079998,
                40.75540002899993
              ],
              [
                -73.85312635499994,
                40.7551093429999
              ],
              [
                -73.85302119999993,
                40.75461800199988
              ],
              [
                -73.85300762299985,
                40.75455456599985
              ],
              [
                -73.85261725399984,
                40.75340506599992
              ],
              [
                -73.85246329699996,
                40.75312740899989
              ],
              [
                -73.85220450599996,
                40.752660681999934
              ],
              [
                -73.85185614699996,
                40.75196793299993
              ],
              [
                -73.85171508699987,
                40.751687413999875
              ],
              [
                -73.85151883499987,
                40.751297136999916
              ],
              [
                -73.85118150899993,
                40.75062629599987
              ],
              [
                -73.85098691399993,
                40.750239297999904
              ],
              [
                -73.85086439699991,
                40.749995641999874
              ],
              [
                -73.8513110499998,
                40.749843375999895
              ],
              [
                -73.85442575299999,
                40.748851725999884
              ],
              [
                -73.85436928099993,
                40.74873892999988
              ],
              [
                -73.85405453299994,
                40.748158316999906
              ],
              [
                -73.85382450799997,
                40.74785403999988
              ],
              [
                -73.8537828699998,
                40.74780299099994
              ],
              [
                -73.85360568399982,
                40.74749682799991
              ],
              [
                -73.85325493199984,
                40.74682354899991
              ],
              [
                -73.8529235349999,
                40.74618194799996
              ],
              [
                -73.85257733899984,
                40.74550564699991
              ],
              [
                -73.85224097499999,
                40.744840965999856
              ],
              [
                -73.85189537499998,
                40.744179737999936
              ],
              [
                -73.85155107299987,
                40.74350722699996
              ],
              [
                -73.85135102799995,
                40.74311905799989
              ],
              [
                -73.85120929199985,
                40.74284402099991
              ],
              [
                -73.85099327299999,
                40.74242254299989
              ],
              [
                -73.85086917499987,
                40.74218041099995
              ],
              [
                -73.85051160899988,
                40.74151885699997
              ],
              [
                -73.85020444599992,
                40.740902033999895
              ],
              [
                -73.85011556599989,
                40.74072356299996
              ],
              [
                -73.8499642599999,
                40.74070177199998
              ],
              [
                -73.84984431299986,
                40.74066554999991
              ],
              [
                -73.84965207399982,
                40.74061466399994
              ],
              [
                -73.8493530509999,
                40.74052324999996
              ],
              [
                -73.84892344899993,
                40.74039191499986
              ],
              [
                -73.84795885999989,
                40.74009701599994
              ],
              [
                -73.84780164699998,
                40.74004967699989
              ],
              [
                -73.84794174099986,
                40.73981640499991
              ],
              [
                -73.84823627999982,
                40.739290400999955
              ],
              [
                -73.84777844199982,
                40.7393448349999
              ],
              [
                -73.84775740199994,
                40.739299088999864
              ],
              [
                -73.84767033700003,
                40.7391952349999
              ],
              [
                -73.8475557979999,
                40.7390639149999
              ],
              [
                -73.84750820299992,
                40.73900780699996
              ],
              [
                -73.84746313999992,
                40.738952030999954
              ],
              [
                -73.84732494199989,
                40.738771457999896
              ],
              [
                -73.84721361599989,
                40.73864398699987
              ],
              [
                -73.84689366199993,
                40.738671216999904
              ],
              [
                -73.84605541899985,
                40.7370991879999
              ],
              [
                -73.84590881499994,
                40.736399800999884
              ],
              [
                -73.84569341599997,
                40.7356664799999
              ],
              [
                -73.84542613099978,
                40.73494980299994
              ],
              [
                -73.84511542099983,
                40.73427740799986
              ],
              [
                -73.8449680009999,
                40.734007128999906
              ],
              [
                -73.84475302099987,
                40.733619997999945
              ],
              [
                -73.84434729799992,
                40.73297869299997
              ],
              [
                -73.84421250399983,
                40.73273304099995
              ],
              [
                -73.844060203,
                40.73249273999991
              ],
              [
                -73.84389098199985,
                40.73225885599994
              ],
              [
                -73.84370558699993,
                40.73203240499988
              ],
              [
                -73.84350489099985,
                40.73181434399993
              ],
              [
                -73.84281412599988,
                40.73115341199989
              ],
              [
                -73.84162181800001,
                40.73011794699988
              ],
              [
                -73.84146810899993,
                40.729998092999885
              ],
              [
                -73.84032703199988,
                40.728807114999896
              ],
              [
                -73.83985434299984,
                40.72817756899988
              ],
              [
                -73.8394453729999,
                40.7275367999999
              ],
              [
                -73.83906354600003,
                40.7268795339999
              ],
              [
                -73.83871473099992,
                40.72622079099989
              ],
              [
                -73.83836161400004,
                40.725561841999856
              ],
              [
                -73.83652367699997,
                40.722370553999944
              ],
              [
                -73.83603385999993,
                40.72176720199997
              ],
              [
                -73.83596312399996,
                40.72167716799987
              ],
              [
                -73.83576124499994,
                40.72136157399996
              ],
              [
                -73.83556698599992,
                40.72104319099986
              ],
              [
                -73.83538045099985,
                40.72072217999993
              ],
              [
                -73.83527502199999,
                40.72045730599992
              ],
              [
                -73.835185721,
                40.72018871899991
              ],
              [
                -73.8351128689999,
                40.71991727199992
              ],
              [
                -73.83505666300006,
                40.719643840999886
              ],
              [
                -73.83501684199989,
                40.71952679899992
              ],
              [
                -73.8349589409999,
                40.719412476999935
              ],
              [
                -73.83488359399998,
                40.719303271999934
              ],
              [
                -73.83479214399988,
                40.71920142799992
              ],
              [
                -73.83468657499992,
                40.71910892099988
              ],
              [
                -73.83457034900002,
                40.719027958999895
              ],
              [
                -73.83456936899985,
                40.719027281999935
              ],
              [
                -73.83348148399978,
                40.718333455999854
              ],
              [
                -73.8320714099999,
                40.717290595999906
              ],
              [
                -73.83072818099994,
                40.71625964099996
              ],
              [
                -73.8297961659998,
                40.715565718999876
              ],
              [
                -73.82987589099986,
                40.71542940099995
              ],
              [
                -73.8299725969999,
                40.71529812199986
              ],
              [
                -73.83008529899986,
                40.715173765999964
              ],
              [
                -73.8300853579999,
                40.71517370299991
              ],
              [
                -73.83008542999981,
                40.71517363999988
              ],
              [
                -73.83021283199997,
                40.715057778999885
              ],
              [
                -73.83035327299996,
                40.7149518069999
              ],
              [
                -73.83050460999999,
                40.71485694399992
              ],
              [
                -73.83122975599984,
                40.71462702899991
              ],
              [
                -73.83138630199987,
                40.71457739699988
              ],
              [
                -73.83145866999996,
                40.71455444899995
              ],
              [
                -73.83125888999979,
                40.714441259999944
              ],
              [
                -73.83115849800005,
                40.71439336699996
              ],
              [
                -73.83106922200002,
                40.714353955999925
              ],
              [
                -73.83103141800002,
                40.71433672599994
              ],
              [
                -73.8309675249999,
                40.714354490999895
              ],
              [
                -73.83082284299995,
                40.71438342799992
              ],
              [
                -73.83031312099993,
                40.71449974499997
              ],
              [
                -73.82995752699982,
                40.714580886999876
              ],
              [
                -73.82926493499993,
                40.71479222999991
              ],
              [
                -73.82850807699991,
                40.715044328999944
              ],
              [
                -73.82715524299999,
                40.715388003999884
              ],
              [
                -73.82700619999986,
                40.715440297999855
              ],
              [
                -73.82681044699991,
                40.715550945999965
              ],
              [
                -73.82646966099985,
                40.715695567999916
              ],
              [
                -73.82630699099998,
                40.715790723999916
              ],
              [
                -73.82627825999998,
                40.71580688099988
              ],
              [
                -73.82591945199992,
                40.71598987599993
              ],
              [
                -73.82566233799984,
                40.71615263799994
              ],
              [
                -73.82561290699982,
                40.71620989799991
              ],
              [
                -73.82524731699984,
                40.716455444999895
              ],
              [
                -73.825090876,
                40.71645279299986
              ],
              [
                -73.82346833799978,
                40.71673105099993
              ],
              [
                -73.8218741769999,
                40.71691711799987
              ],
              [
                -73.82100705300005,
                40.71687995099993
              ],
              [
                -73.82051599199986,
                40.716811990999894
              ],
              [
                -73.82018883899984,
                40.716735223999855
              ],
              [
                -73.82005442499994,
                40.71681033399992
              ],
              [
                -73.81930469499987,
                40.71713608099988
              ],
              [
                -73.81907861099985,
                40.71720434499988
              ],
              [
                -73.81887154599994,
                40.71725932899994
              ],
              [
                -73.81729568999994,
                40.71757964499995
              ],
              [
                -73.81725907799989,
                40.71763787699993
              ],
              [
                -73.81732944799997,
                40.717660456999916
              ],
              [
                -73.8191283259999,
                40.71734991899995
              ],
              [
                -73.81952343899992,
                40.71733973299991
              ],
              [
                -73.81962622499991,
                40.7173404429999
              ],
              [
                -73.82085213599987,
                40.71734887699994
              ],
              [
                -73.82170479099999,
                40.717359889999905
              ],
              [
                -73.82210623799986,
                40.71740613099991
              ],
              [
                -73.82251099399988,
                40.717437075999925
              ],
              [
                -73.82291752899988,
                40.71745256099993
              ],
              [
                -73.82332432099994,
                40.71745250999989
              ],
              [
                -73.8237298389999,
                40.717437003999855
              ],
              [
                -73.82487503799997,
                40.71732243199994
              ],
              [
                -73.82518614699985,
                40.717288189999884
              ],
              [
                -73.82531644999993,
                40.71727569999995
              ],
              [
                -73.8254192649999,
                40.717392543999935
              ],
              [
                -73.82545983199994,
                40.71799986599993
              ],
              [
                -73.82548692499986,
                40.71869066199986
              ],
              [
                -73.82547346999992,
                40.71894746799989
              ],
              [
                -73.82547345799988,
                40.71894765699991
              ],
              [
                -73.8254734689999,
                40.71894785499994
              ],
              [
                -73.82548231999995,
                40.7192061349999
              ],
              [
                -73.82548231999989,
                40.71920620699996
              ],
              [
                -73.82551374199986,
                40.71946448499993
              ],
              [
                -73.82551374199984,
                40.719464530999865
              ],
              [
                -73.82551375400003,
                40.71946457499995
              ],
              [
                -73.82556762199991,
                40.71972087099995
              ],
              [
                -73.82556765699991,
                40.71972101499993
              ],
              [
                -73.825567704,
                40.719721168999854
              ],
              [
                -73.82564366799976,
                40.71997373299991
              ],
              [
                -73.825643726,
                40.71997393899991
              ],
              [
                -73.82564380900001,
                40.71997413799995
              ],
              [
                -73.82574130299989,
                40.72022165599989
              ],
              [
                -73.82585945699992,
                40.72046263199995
              ],
              [
                -73.82672635699993,
                40.723081599999894
              ],
              [
                -73.82702267100002,
                40.72394639099993
              ],
              [
                -73.82706662699995,
                40.7241260829999
              ],
              [
                -73.82712924299985,
                40.7243036399999
              ],
              [
                -73.82712926599994,
                40.72430371199989
              ],
              [
                -73.8271293019999,
                40.72430378399993
              ],
              [
                -73.82721028599993,
                40.7244775779999
              ],
              [
                -73.8273090039999,
                40.724646076999896
              ],
              [
                -73.82742439499987,
                40.724807603999935
              ],
              [
                -73.82742443099994,
                40.72480765799986
              ],
              [
                -73.82742447699991,
                40.724807702999925
              ],
              [
                -73.8275552779999,
                40.72496094999987
              ],
              [
                -73.827704851,
                40.7250916299999
              ],
              [
                -73.8277049809999,
                40.725091747999905
              ],
              [
                -73.82770512299993,
                40.725091855999956
              ],
              [
                -73.82787086399985,
                40.72521360999989
              ],
              [
                -73.8280513119999,
                40.72532475999987
              ],
              [
                -73.82805145299996,
                40.72532484999992
              ],
              [
                -73.82805160699986,
                40.72532493099989
              ],
              [
                -73.82824491699995,
                40.72542399599991
              ],
              [
                -73.82844909199999,
                40.72550982199992
              ],
              [
                -73.82866154299994,
                40.725581422999866
              ],
              [
                -73.82887959600001,
                40.725638191999906
              ],
              [
                -73.828879714,
                40.72563822699992
              ],
              [
                -73.82887984399994,
                40.725638253999925
              ],
              [
                -73.82910097699987,
                40.72567999799992
              ],
              [
                -73.82933877699988,
                40.72573592099995
              ],
              [
                -73.82933887099998,
                40.725735938999925
              ],
              [
                -73.82957243899995,
                40.72580840699991
              ],
              [
                -73.82957252099986,
                40.72580843399988
              ],
              [
                -73.82957260399982,
                40.72580846999996
              ],
              [
                -73.82979903999995,
                40.72589722599986
              ],
              [
                -73.82979910999985,
                40.72589725399996
              ],
              [
                -73.8297991689999,
                40.72589728099996
              ],
              [
                -73.83001549299993,
                40.72600159099995
              ],
              [
                -73.83001569399997,
                40.72600168999989
              ],
              [
                -73.8300158949998,
                40.72600180799987
              ],
              [
                -73.83021940999994,
                40.72612062499991
              ],
              [
                -73.83021953999996,
                40.72612069599989
              ],
              [
                -73.83021964699995,
                40.726120777999945
              ],
              [
                -73.83040815599992,
                40.72625279999995
              ],
              [
                -73.83040820199987,
                40.726252827999915
              ],
              [
                -73.83040824999995,
                40.726252862999914
              ],
              [
                -73.83057943699991,
                40.72639619699988
              ],
              [
                -73.83057961499995,
                40.72639635099988
              ],
              [
                -73.83057976800002,
                40.72639650399987
              ],
              [
                -73.83073214599987,
                40.72654932799992
              ],
              [
                -73.83086471499999,
                40.72670963399989
              ],
              [
                -73.83140452799991,
                40.727597621999976
              ],
              [
                -73.83170465899991,
                40.72804033199986
              ],
              [
                -73.83198207999989,
                40.728329997999886
              ],
              [
                -73.83224087999997,
                40.72863052099991
              ],
              [
                -73.83248002199991,
                40.728940853999916
              ],
              [
                -73.83269855399999,
                40.72925986199991
              ],
              [
                -73.832895707,
                40.729586330999915
              ],
              [
                -73.83307087999992,
                40.72991903099995
              ],
              [
                -73.83386267199987,
                40.73055318899989
              ],
              [
                -73.83400368799985,
                40.731567709999915
              ],
              [
                -73.83448336599982,
                40.73216391599991
              ],
              [
                -73.83489939499987,
                40.73296764199992
              ],
              [
                -73.83584824699989,
                40.73383374899989
              ],
              [
                -73.8360070899999,
                40.73397874099992
              ],
              [
                -73.83606223299994,
                40.734098694999865
              ],
              [
                -73.83612778599989,
                40.73424131199993
              ],
              [
                -73.83635625099998,
                40.73500859199989
              ],
              [
                -73.83628669399984,
                40.735131366999966
              ],
              [
                -73.8361887679999,
                40.73562665299992
              ],
              [
                -73.83574733199998,
                40.73699114199996
              ],
              [
                -73.83563372699986,
                40.73711162999992
              ],
              [
                -73.83564585599999,
                40.73849101099988
              ],
              [
                -73.83627527100005,
                40.73956837599993
              ],
              [
                -73.83662419799994,
                40.739365059999905
              ],
              [
                -73.83669458799986,
                40.73954125399997
              ],
              [
                -73.83675088199985,
                40.739720533999936
              ],
              [
                -73.83679277399987,
                40.73990191199988
              ],
              [
                -73.83696272799989,
                40.740656660999896
              ],
              [
                -73.8371072069999,
                40.7413962279999
              ],
              [
                -73.83699037399995,
                40.74209330099992
              ],
              [
                -73.83690407799999,
                40.742125838999904
              ],
              [
                -73.83687304099996,
                40.742137546999864
              ],
              [
                -73.8360998919999,
                40.7424290689999
              ],
              [
                -73.83586131899989,
                40.74322798499995
              ],
              [
                -73.83590196999995,
                40.74337980599994
              ],
              [
                -73.83591564899993,
                40.74343089299988
              ],
              [
                -73.835925305,
                40.743500569999874
              ],
              [
                -73.83595071499995,
                40.743730568999936
              ],
              [
                -73.83584967799999,
                40.7441757629999
              ],
              [
                -73.83567553899992,
                40.74485051799992
              ],
              [
                -73.835569587,
                40.745150630999916
              ],
              [
                -73.83543777199996,
                40.74552397399991
              ],
              [
                -73.83506574799988,
                40.7462041089999
              ],
              [
                -73.83469042599992,
                40.74690269799993
              ],
              [
                -73.83460369599997,
                40.747020998999886
              ],
              [
                -73.834603649,
                40.747021071999875
              ],
              [
                -73.83460358999982,
                40.74702113399996
              ],
              [
                -73.83450317399992,
                40.74713383299995
              ],
              [
                -73.83450311499995,
                40.747133903999945
              ],
              [
                -73.83450304299996,
                40.7471339679999
              ],
              [
                -73.83438980999983,
                40.74723985799992
              ],
              [
                -73.83426519299998,
                40.74733759999988
              ],
              [
                -73.83426506200006,
                40.747337698999935
              ],
              [
                -73.83426490799998,
                40.74733779799994
              ],
              [
                -73.83413036799998,
                40.7474264389999
              ],
              [
                -73.83392232599984,
                40.747532001999936
              ],
              [
                -73.83392218299996,
                40.7475320749999
              ],
              [
                -73.83392204099982,
                40.74753213699994
              ],
              [
                -73.83370364199992,
                40.74762634799989
              ],
              [
                -73.83364897199982,
                40.74764609899995
              ],
              [
                -73.83300302099987,
                40.74783363099988
              ],
              [
                -73.83308582399988,
                40.74798912499989
              ],
              [
                -73.83311678399998,
                40.74804727099989
              ],
              [
                -73.83277623900001,
                40.74931349499994
              ],
              [
                -73.83228373899995,
                40.751425268999895
              ],
              [
                -73.83227581899976,
                40.751457594999906
              ],
              [
                -73.83225199799978,
                40.751578003999946
              ],
              [
                -73.832247237,
                40.75170059099991
              ],
              [
                -73.83226187399998,
                40.75182272199993
              ],
              [
                -73.83229535699988,
                40.75194179099986
              ],
              [
                -73.83560230899995,
                40.75139485299991
              ],
              [
                -73.83599840099994,
                40.75200573599994
              ],
              [
                -73.83701406699998,
                40.75402742099987
              ],
              [
                -73.83743572399987,
                40.75408295599989
              ],
              [
                -73.83766909799984,
                40.75411369599991
              ],
              [
                -73.83778038200002,
                40.75413068299993
              ],
              [
                -73.83798921299986,
                40.75451504199991
              ],
              [
                -73.83805974199996,
                40.754666606999905
              ],
              [
                -73.83814233299988,
                40.75485403799992
              ],
              [
                -73.83827816299996,
                40.755233377999964
              ],
              [
                -73.83834041199997,
                40.755425461999906
              ],
              [
                -73.83838479599993,
                40.755640366999884
              ],
              [
                -73.83846752599989,
                40.756040155999905
              ],
              [
                -73.83867321899987,
                40.75701997899995
              ],
              [
                -73.83875129700003,
                40.756994991999925
              ],
              [
                -73.83912329999988,
                40.75682806199995
              ],
              [
                -73.83978335899994,
                40.75654003599988
              ],
              [
                -73.84045537199995,
                40.756267296999965
              ],
              [
                -73.84113839099993,
                40.756010244999864
              ],
              [
                -73.84183146799998,
                40.75576926799993
              ],
              [
                -73.84253360699991,
                40.75554469899987
              ],
              [
                -73.84324381399976,
                40.755336803999974
              ],
              [
                -73.84363442799987,
                40.75615699399989
              ],
              [
                -73.84404431200002,
                40.756944772999944
              ],
              [
                -73.84444970399977,
                40.7577434839999
              ],
              [
                -73.84479027099985,
                40.75840315899996
              ],
              [
                -73.84513404100001,
                40.75906847499993
              ],
              [
                -73.8454898629999,
                40.75972867199994
              ],
              [
                -73.84585235799983,
                40.76045539799988
              ],
              [
                -73.8459114399999,
                40.76058469999988
              ],
              [
                -73.84597463499995,
                40.76071730199991
              ],
              [
                -73.84601517799996,
                40.7608024889999
              ],
              [
                -73.84606731299985,
                40.76090886399988
              ],
              [
                -73.84663046699997,
                40.76129165899989
              ],
              [
                -73.84670933714986,
                40.761345269319776
              ],
              [
                -73.84796233649803,
                40.76055108883418
              ],
              [
                -73.84821242063795,
                40.760437594507884
              ],
              [
                -73.84858834617657,
                40.76017956308828
              ],
              [
                -73.8486865565891,
                40.76013849511064
              ],
              [
                -73.84891393714967,
                40.760275786673596
              ],
              [
                -73.84914140272522,
                40.76030689063903
              ],
              [
                -73.84916662406035,
                40.76029658479041
              ],
              [
                -73.84981162475142,
                40.76127097400822
              ],
              [
                -73.85207200177854,
                40.7603928846721
              ],
              [
                -73.85197412962866,
                40.76024940738178
              ],
              [
                -73.85184046510507,
                40.760305978821485
              ],
              [
                -73.8518167929994,
                40.76035373284719
              ],
              [
                -73.84987484496149,
                40.76110381294847
              ],
              [
                -73.84930110814332,
                40.76024287534997
              ],
              [
                -73.84934906664418,
                40.7601982826528
              ],
              [
                -73.84933909178683,
                40.76018858944728
              ],
              [
                -73.84950202299183,
                40.76000672901064
              ],
              [
                -73.84970908350634,
                40.75983883795749
              ],
              [
                -73.84994041798382,
                40.75969825659541
              ],
              [
                -73.85005568532608,
                40.75964232956162
              ],
              [
                -73.8501750399337,
                40.7595916012256
              ],
              [
                -73.85029807674991,
                40.7595462437419
              ],
              [
                -73.85042437822479,
                40.75950641103758
              ],
              [
                -73.8506905306545,
                40.75944879109037
              ],
              [
                -73.8509512195169,
                40.75941958309283
              ],
              [
                -73.85121495373862,
                40.75941585413536
              ],
              [
                -73.85133438850517,
                40.759420680965206
              ],
              [
                -73.85144347566316,
                40.75941908427621
              ],
              [
                -73.85158552901191,
                40.75935542065696
              ],
              [
                -73.85171252421836,
                40.75940125851665
              ],
              [
                -73.85188711257045,
                40.75952236727888
              ],
              [
                -73.85200759608603,
                40.759572500687625
              ],
              [
                -73.85210362063093,
                40.75968282669971
              ],
              [
                -73.85277928565588,
                40.75997298911409
              ],
              [
                -73.85294927727757,
                40.76002140181005
              ],
              [
                -73.85306618709187,
                40.760038704481204
              ],
              [
                -73.8532844944673,
                40.76012651275206
              ],
              [
                -73.85372403934942,
                40.760246388634854
              ],
              [
                -73.85396489201136,
                40.76027142272703
              ],
              [
                -73.8542521990179,
                40.760250677781336
              ],
              [
                -73.8545203082371,
                40.76015934488466
              ],
              [
                -73.85466366458877,
                40.760171169562085
              ],
              [
                -73.85499808982104,
                40.7603911707674
              ],
              [
                -73.85530266029582,
                40.76064958525487
              ],
              [
                -73.85533454594008,
                40.7606581548894
              ],
              [
                -73.85526921633013,
                40.76072983580968
              ],
              [
                -73.85527660311541,
                40.7607342647623
              ],
              [
                -73.8552098725408,
                40.760809300775655
              ],
              [
                -73.85522306397422,
                40.76081695051195
              ],
              [
                -73.85529349219507,
                40.760742320360684
              ],
              [
                -73.85530351821596,
                40.76074715385175
              ],
              [
                -73.85537633453939,
                40.76066938503226
              ],
              [
                -73.8562732064007,
                40.760902408430354
              ],
              [
                -73.85747662435531,
                40.76130891668733
              ],
              [
                -73.85858526284439,
                40.76191700859695
              ],
              [
                -73.85850740232591,
                40.76200806491511
              ],
              [
                -73.85851749742233,
                40.7620149112025
              ],
              [
                -73.85845113610489,
                40.762099465008106
              ],
              [
                -73.85844323288775,
                40.76209568947986
              ],
              [
                -73.85836158883386,
                40.76218701512109
              ],
              [
                -73.85828091939456,
                40.762277251475574
              ],
              [
                -73.85827164316137,
                40.76228762835283
              ],
              [
                -73.85819433132511,
                40.7623741079845
              ],
              [
                -73.8578332359116,
                40.762202381494895
              ],
              [
                -73.85781948058784,
                40.76219584021852
              ],
              [
                -73.8577302417447,
                40.76215340057873
              ],
              [
                -73.85771566567898,
                40.76214646911337
              ],
              [
                -73.85762293989328,
                40.76210236942353
              ],
              [
                -73.85761007602207,
                40.762096253064605
              ],
              [
                -73.85751841919019,
                40.762052661880226
              ],
              [
                -73.85750467691464,
                40.762046126613114
              ],
              [
                -73.85741179997494,
                40.762001956372046
              ],
              [
                -73.85739929667307,
                40.76199600946949
              ],
              [
                -73.85730868889425,
                40.761952918978366
              ],
              [
                -73.8572941135809,
                40.761945985785
              ],
              [
                -73.85720516175633,
                40.76190368279287
              ],
              [
                -73.85719139863377,
                40.76189713707573
              ],
              [
                -73.85710534324438,
                40.76185621047228
              ],
              [
                -73.85709011185035,
                40.7618489668511
              ],
              [
                -73.85700272542273,
                40.761807405606014
              ],
              [
                -73.85699014273463,
                40.76180142237467
              ],
              [
                -73.85690054310561,
                40.76175880925623
              ],
              [
                -73.85688557543547,
                40.76175169107733
              ],
              [
                -73.85679154181352,
                40.76170696991362
              ],
              [
                -73.85677893473327,
                40.76170097456675
              ],
              [
                -73.85662772941468,
                40.761629062393226
              ],
              [
                -73.8566119581333,
                40.761649542759315
              ],
              [
                -73.85665617597306,
                40.76167070874793
              ],
              [
                -73.85667457135632,
                40.76167948956146
              ],
              [
                -73.85677632095462,
                40.76172805592267
              ],
              [
                -73.85683800035645,
                40.761757495880914
              ],
              [
                -73.85698328892109,
                40.76182684448666
              ],
              [
                -73.85700279501377,
                40.76183615483602
              ],
              [
                -73.85716303200887,
                40.76191263827355
              ],
              [
                -73.85718801666012,
                40.761924564434146
              ],
              [
                -73.85735065780416,
                40.762002192446865
              ],
              [
                -73.85737149406725,
                40.76201213779627
              ],
              [
                -73.8574600419264,
                40.762054402298325
              ],
              [
                -73.85752950976803,
                40.76208756021165
              ],
              [
                -73.85767257542702,
                40.762155845759416
              ],
              [
                -73.85768834724743,
                40.762163373759726
              ],
              [
                -73.85780788168364,
                40.76222042710024
              ],
              [
                -73.85781983894705,
                40.762226133889705
              ],
              [
                -73.85817631070508,
                40.7623962942238
              ],
              [
                -73.85814119991119,
                40.762437750876416
              ],
              [
                -73.85813288714913,
                40.762447598708945
              ],
              [
                -73.85807199853592,
                40.76251972977301
              ],
              [
                -73.8580451152925,
                40.76255157517708
              ],
              [
                -73.85797500120835,
                40.76263463209225
              ],
              [
                -73.8579670234807,
                40.76264408295384
              ],
              [
                -73.85794433387208,
                40.76267096216958
              ],
              [
                -73.85789701257401,
                40.762727018491546
              ],
              [
                -73.85784136663885,
                40.76279293749988
              ],
              [
                -73.85783497417715,
                40.762800510498884
              ],
              [
                -73.85777623039975,
                40.7628700979901
              ],
              [
                -73.85776740573347,
                40.762880552278006
              ],
              [
                -73.85771567256865,
                40.76294183508202
              ],
              [
                -73.85770844688949,
                40.762950395280726
              ],
              [
                -73.85765037151214,
                40.76301917387896
              ],
              [
                -73.85763578363799,
                40.76301232196483
              ],
              [
                -73.85760424037194,
                40.76305328178898
              ],
              [
                -73.85748453757667,
                40.76299459449141
              ],
              [
                -73.85747499963058,
                40.76298991927081
              ],
              [
                -73.85738951387344,
                40.76294800770227
              ],
              [
                -73.8573806782753,
                40.76294367543132
              ],
              [
                -73.85729174591326,
                40.76290007370879
              ],
              [
                -73.85728240762211,
                40.76289549655456
              ],
              [
                -73.85719629175131,
                40.76285327497573
              ],
              [
                -73.85718716762985,
                40.762848801777864
              ],
              [
                -73.85710529468155,
                40.762808661539985
              ],
              [
                -73.85709311210883,
                40.76280268870126
              ],
              [
                -73.85698999094755,
                40.76275213155588
              ],
              [
                -73.85690044326856,
                40.76270822738634
              ],
              [
                -73.85688788040733,
                40.76270206793208
              ],
              [
                -73.8568366081776,
                40.76267693094511
              ],
              [
                -73.85682558732012,
                40.7626715270996
              ],
              [
                -73.85677318714824,
                40.76264583651674
              ],
              [
                -73.85676092804188,
                40.762639826189165
              ],
              [
                -73.85671169399487,
                40.76261568695249
              ],
              [
                -73.856798581006,
                40.76251682329278
              ],
              [
                -73.8567823696347,
                40.76250827740659
              ],
              [
                -73.8565867538878,
                40.762722840114236
              ],
              [
                -73.85660302580746,
                40.76273141793303
              ],
              [
                -73.85669035223006,
                40.762635632858746
              ],
              [
                -73.85674350380765,
                40.76266167353797
              ],
              [
                -73.85675571120355,
                40.76266765415099
              ],
              [
                -73.85682436749566,
                40.76270129244425
              ],
              [
                -73.85682507576576,
                40.76270163909817
              ],
              [
                -73.85688415442013,
                40.76273058393781
              ],
              [
                -73.85697105684373,
                40.7627731613803
              ],
              [
                -73.85698207201663,
                40.76277855800096
              ],
              [
                -73.85708275835432,
                40.762827888020134
              ],
              [
                -73.85709111433461,
                40.76283198149715
              ],
              [
                -73.85717606828264,
                40.762873603814086
              ],
              [
                -73.85718496087118,
                40.762877960462795
              ],
              [
                -73.85737359701484,
                40.76297037851959
              ],
              [
                -73.85746422874624,
                40.76301478271995
              ],
              [
                -73.85758285470693,
                40.763072900626604
              ],
              [
                -73.85754793889828,
                40.763115565701206
              ],
              [
                -73.85755915818416,
                40.76312241178624
              ],
              [
                -73.85753555737742,
                40.763149201507154
              ],
              [
                -73.85752810581124,
                40.76315766039692
              ],
              [
                -73.85747974744922,
                40.763212554809144
              ],
              [
                -73.85747258321598,
                40.763220687374904
              ],
              [
                -73.85742519945238,
                40.763274472439754
              ],
              [
                -73.8574166225427,
                40.76328420899042
              ],
              [
                -73.85736795787706,
                40.76333944992225
              ],
              [
                -73.85735990197851,
                40.7633485937366
              ],
              [
                -73.85731353865413,
                40.76340122325121
              ],
              [
                -73.85730643291356,
                40.763409287863276
              ],
              [
                -73.85725666284321,
                40.763465783320626
              ],
              [
                -73.85724903760318,
                40.76347443965748
              ],
              [
                -73.85720087844675,
                40.763529106864745
              ],
              [
                -73.8571946426761,
                40.76353618498254
              ],
              [
                -73.8571452328759,
                40.76359227023111
              ],
              [
                -73.85713828854304,
                40.76360015443993
              ],
              [
                -73.85708959006735,
                40.7636554318984
              ],
              [
                -73.85708333616489,
                40.76366252925307
              ],
              [
                -73.85703613508508,
                40.76371611016756
              ],
              [
                -73.85702898355706,
                40.76372422680605
              ],
              [
                -73.85698123375121,
                40.76377842954334
              ],
              [
                -73.85697496767924,
                40.763785539943854
              ],
              [
                -73.85692072861958,
                40.76384710808833
              ],
              [
                -73.85691477947755,
                40.763853861546195
              ],
              [
                -73.85686149910755,
                40.76391434059381
              ],
              [
                -73.8568542717766,
                40.76392254390558
              ],
              [
                -73.8568029095629,
                40.76398084546083
              ],
              [
                -73.85668299802357,
                40.76392072858619
              ],
              [
                -73.8566748236235,
                40.76393018832615
              ],
              [
                -73.8569635593979,
                40.76407442786309
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0000625819407393,
        "objectid": 94,
        "shape_leng": 0.0498326083147,
        "location_id": 94,
        "zone": "Fordham South",
        "borough": "Bronx"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.89964261700001,
                40.862210436999874
              ],
              [
                -73.89996177599984,
                40.86235027999989
              ],
              [
                -73.90028368099995,
                40.86247183699992
              ],
              [
                -73.90107966999994,
                40.86275638299992
              ],
              [
                -73.90136090399993,
                40.8624088639999
              ],
              [
                -73.90162785099997,
                40.86205443399998
              ],
              [
                -73.90188007299994,
                40.86169362499993
              ],
              [
                -73.90211720400006,
                40.86132694799991
              ],
              [
                -73.90233887199986,
                40.86095497099996
              ],
              [
                -73.90254479399998,
                40.86057825499989
              ],
              [
                -73.90351390599986,
                40.860917509999894
              ],
              [
                -73.90408132700006,
                40.85998784799989
              ],
              [
                -73.90447464099996,
                40.859341594999876
              ],
              [
                -73.90486847199993,
                40.8586932249999
              ],
              [
                -73.90526389299995,
                40.858047177999886
              ],
              [
                -73.90565953499988,
                40.85739873399997
              ],
              [
                -73.90468371399987,
                40.857060301999915
              ],
              [
                -73.90508614099994,
                40.856410830999955
              ],
              [
                -73.90522169999987,
                40.85620359499993
              ],
              [
                -73.90557007299988,
                40.855787321999884
              ],
              [
                -73.90472622199998,
                40.85537039499997
              ],
              [
                -73.90395154299999,
                40.85497392299991
              ],
              [
                -73.90298864599995,
                40.85477310899994
              ],
              [
                -73.90208001099982,
                40.85462259899992
              ],
              [
                -73.90185996099993,
                40.85458614899994
              ],
              [
                -73.90153703400001,
                40.85499464299988
              ],
              [
                -73.90130126599995,
                40.85530348999987
              ],
              [
                -73.90116590999993,
                40.85549567499992
              ],
              [
                -73.90085477199993,
                40.8559374399999
              ],
              [
                -73.90077922699986,
                40.85604710999993
              ],
              [
                -73.90060385999978,
                40.85598723799997
              ],
              [
                -73.89967155800004,
                40.85566890799991
              ],
              [
                -73.89877598599993,
                40.85536324699995
              ],
              [
                -73.89816577499982,
                40.85514637599989
              ],
              [
                -73.89863543699985,
                40.85390697499993
              ],
              [
                -73.89831118599984,
                40.854061150999904
              ],
              [
                -73.89709926799996,
                40.855172290999874
              ],
              [
                -73.89644052599982,
                40.854907758999914
              ],
              [
                -73.8956088329998,
                40.854570369999884
              ],
              [
                -73.89549889799987,
                40.85474976599995
              ],
              [
                -73.89477596099992,
                40.85592952599996
              ],
              [
                -73.89441355099994,
                40.85643377599997
              ],
              [
                -73.8929935429999,
                40.85838611999987
              ],
              [
                -73.89225707599982,
                40.85940997599995
              ],
              [
                -73.89174394999986,
                40.860177146999945
              ],
              [
                -73.8911537559998,
                40.86091361599997
              ],
              [
                -73.89106059999996,
                40.861471814999945
              ],
              [
                -73.89106280699994,
                40.86152941199992
              ],
              [
                -73.89138023399983,
                40.86170058799992
              ],
              [
                -73.89141802399983,
                40.86166298399995
              ],
              [
                -73.89256392599998,
                40.86052272899994
              ],
              [
                -73.89321520799987,
                40.85987420599987
              ],
              [
                -73.89381026099987,
                40.85898656499994
              ],
              [
                -73.8946932639999,
                40.85942628199992
              ],
              [
                -73.89573924699978,
                40.858138206999875
              ],
              [
                -73.89636314599984,
                40.85837531899993
              ],
              [
                -73.89735765499988,
                40.85876273499986
              ],
              [
                -73.89818244699991,
                40.85908368099994
              ],
              [
                -73.89866157499996,
                40.859246901999924
              ],
              [
                -73.89883814599983,
                40.859307307999906
              ],
              [
                -73.89880457699982,
                40.8593642709999
              ],
              [
                -73.89859097199998,
                40.859766782999955
              ],
              [
                -73.89828973399992,
                40.86033442099993
              ],
              [
                -73.89822728999994,
                40.8604488639999
              ],
              [
                -73.89802453099999,
                40.860820460999896
              ],
              [
                -73.89773815399992,
                40.861351136999915
              ],
              [
                -73.897143798,
                40.862445064999925
              ],
              [
                -73.89737274799984,
                40.86243236399985
              ],
              [
                -73.89798099399988,
                40.86240253999994
              ],
              [
                -73.89841038399996,
                40.86229696699993
              ],
              [
                -73.89943670799998,
                40.86211691199987
              ],
              [
                -73.89964261700001,
                40.862210436999874
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.00057277033803,
        "objectid": 95,
        "shape_leng": 0.113629605476,
        "location_id": 95,
        "zone": "Forest Hills",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.84732494199989,
                40.738771457999896
              ],
              [
                -73.84746313999992,
                40.738952030999954
              ],
              [
                -73.84750820299992,
                40.73900780699996
              ],
              [
                -73.84801295299997,
                40.73876334399993
              ],
              [
                -73.84859447999987,
                40.738501170999896
              ],
              [
                -73.84956453799992,
                40.738106091999896
              ],
              [
                -73.84995440299978,
                40.737960303999884
              ],
              [
                -73.8507921649999,
                40.73768175599992
              ],
              [
                -73.8508760629999,
                40.737653859999924
              ],
              [
                -73.85130352699998,
                40.73751172799992
              ],
              [
                -73.85187067499986,
                40.73734292899995
              ],
              [
                -73.85244443499992,
                40.737202311999916
              ],
              [
                -73.85340136499995,
                40.73700275799993
              ],
              [
                -73.85415418099988,
                40.736877812999886
              ],
              [
                -73.85505083399991,
                40.736720220999885
              ],
              [
                -73.85567427699985,
                40.73658665799997
              ],
              [
                -73.85628567699989,
                40.73642604299987
              ],
              [
                -73.8566154109999,
                40.73633083699988
              ],
              [
                -73.85801767699988,
                40.73590708399996
              ],
              [
                -73.85862016399996,
                40.735739906999925
              ],
              [
                -73.85872465799989,
                40.73570864399988
              ],
              [
                -73.8586707819999,
                40.73561895999997
              ],
              [
                -73.8585833349998,
                40.73547194199988
              ],
              [
                -73.85834345899994,
                40.735001239999896
              ],
              [
                -73.85799293699985,
                40.73433365699993
              ],
              [
                -73.85765183100001,
                40.73366788599988
              ],
              [
                -73.85730927099998,
                40.73300411099994
              ],
              [
                -73.85704937999998,
                40.73251243399991
              ],
              [
                -73.85659673999993,
                40.731623059999954
              ],
              [
                -73.85625665099991,
                40.73095912299993
              ],
              [
                -73.85851097899999,
                40.73028680199992
              ],
              [
                -73.85837971299996,
                40.73003567799985
              ],
              [
                -73.85816500199988,
                40.72962493499991
              ],
              [
                -73.857827674,
                40.728974719999904
              ],
              [
                -73.85762923599995,
                40.728606921999884
              ],
              [
                -73.85771592500002,
                40.728505460999926
              ],
              [
                -73.85741240699991,
                40.72832247999986
              ],
              [
                -73.85775002599989,
                40.72844560099985
              ],
              [
                -73.8580876569999,
                40.72856872199988
              ],
              [
                -73.85812618599992,
                40.72850824599991
              ],
              [
                -73.85820066499991,
                40.72839135199994
              ],
              [
                -73.85860783699987,
                40.727752283999926
              ],
              [
                -73.85901374300002,
                40.7271089069999
              ],
              [
                -73.8594198799999,
                40.72646483799989
              ],
              [
                -73.857271081,
                40.72568129799989
              ],
              [
                -73.85776316799985,
                40.72490571199995
              ],
              [
                -73.85805416899993,
                40.72444540599987
              ],
              [
                -73.85960517199996,
                40.725022399999915
              ],
              [
                -73.86015205599992,
                40.72522704099989
              ],
              [
                -73.86203833799982,
                40.725956784999944
              ],
              [
                -73.85990796499992,
                40.72434623399987
              ],
              [
                -73.8586809759999,
                40.72261243999988
              ],
              [
                -73.85852214099987,
                40.72153379399993
              ],
              [
                -73.85823757099998,
                40.71961241099995
              ],
              [
                -73.85777168499992,
                40.71645629299992
              ],
              [
                -73.85744857899986,
                40.7136927999999
              ],
              [
                -73.85729287299985,
                40.711467676999874
              ],
              [
                -73.85667714399985,
                40.70769187399986
              ],
              [
                -73.85595536199988,
                40.70642977899993
              ],
              [
                -73.85593991699984,
                40.7063980339999
              ],
              [
                -73.85473240499985,
                40.70676414299989
              ],
              [
                -73.85275370599992,
                40.70739714699991
              ],
              [
                -73.85268225699998,
                40.70741814499992
              ],
              [
                -73.85110121699995,
                40.707900982999924
              ],
              [
                -73.85059051099984,
                40.708039882999856
              ],
              [
                -73.8504290539999,
                40.7080870669999
              ],
              [
                -73.84951207399992,
                40.708345660999925
              ],
              [
                -73.84933156199999,
                40.70838833399994
              ],
              [
                -73.84914591599988,
                40.708419319999905
              ],
              [
                -73.84895712599986,
                40.70843813799995
              ],
              [
                -73.84876725199985,
                40.708444578999874
              ],
              [
                -73.84857838799986,
                40.70843871999993
              ],
              [
                -73.8476241019999,
                40.708332622999855
              ],
              [
                -73.84665724099989,
                40.70831864199995
              ],
              [
                -73.84615958399999,
                40.70835081999995
              ],
              [
                -73.84567198199987,
                40.7084103659999
              ],
              [
                -73.84528898599983,
                40.708476678999936
              ],
              [
                -73.84491011200004,
                40.708560330999944
              ],
              [
                -73.84453738799996,
                40.70866105499991
              ],
              [
                -73.84417281299982,
                40.70877842099986
              ],
              [
                -73.84381825699988,
                40.708911819999905
              ],
              [
                -73.84347552199995,
                40.70906047099992
              ],
              [
                -73.84321861899991,
                40.70918746199987
              ],
              [
                -73.84314625299982,
                40.70922345599992
              ],
              [
                -73.8430466589999,
                40.7092641499999
              ],
              [
                -73.84267962199995,
                40.709414123999935
              ],
              [
                -73.84263067999991,
                40.709432976999885
              ],
              [
                -73.84133936599986,
                40.709946233999894
              ],
              [
                -73.84069154199982,
                40.71015321399994
              ],
              [
                -73.84024206299988,
                40.71026193199987
              ],
              [
                -73.84019199299999,
                40.71027682899993
              ],
              [
                -73.83978025199981,
                40.710385523999925
              ],
              [
                -73.83972439899992,
                40.71039876599992
              ],
              [
                -73.83952312699982,
                40.7104964509999
              ],
              [
                -73.83805756099987,
                40.71092087199988
              ],
              [
                -73.8377089419999,
                40.71103648399988
              ],
              [
                -73.837368179,
                40.711166928999944
              ],
              [
                -73.83703708399995,
                40.7113116139999
              ],
              [
                -73.83703688199994,
                40.711311704999915
              ],
              [
                -73.83703669299994,
                40.71131180399991
              ],
              [
                -73.83671685300001,
                40.71147007499993
              ],
              [
                -73.83671661599983,
                40.711470191999894
              ],
              [
                -73.83671639099991,
                40.711470315999854
              ],
              [
                -73.83640902599988,
                40.711641499999864
              ],
              [
                -73.83640880199984,
                40.71164162599989
              ],
              [
                -73.83640857599988,
                40.711641768999925
              ],
              [
                -73.83611476599982,
                40.711825146999956
              ],
              [
                -73.83602786500002,
                40.711749063999896
              ],
              [
                -73.83600510799992,
                40.711729671999905
              ],
              [
                -73.83505175599991,
                40.712253576999885
              ],
              [
                -73.83426226999985,
                40.71268337399986
              ],
              [
                -73.83374150300001,
                40.712969680999905
              ],
              [
                -73.833657742,
                40.71301572899994
              ],
              [
                -73.83355895899986,
                40.713068003999865
              ],
              [
                -73.83330196199995,
                40.71320007099996
              ],
              [
                -73.83317906799991,
                40.71326322499993
              ],
              [
                -73.83243372799994,
                40.71365996799988
              ],
              [
                -73.83135959999987,
                40.71423170499988
              ],
              [
                -73.83129817899982,
                40.714264132999865
              ],
              [
                -73.83115264699987,
                40.71430685599991
              ],
              [
                -73.83103141799982,
                40.71433672499988
              ],
              [
                -73.83106922200002,
                40.714353955999925
              ],
              [
                -73.83115849800005,
                40.71439336699996
              ],
              [
                -73.83125888999979,
                40.714441259999944
              ],
              [
                -73.83145866999996,
                40.71455444899995
              ],
              [
                -73.83138630199986,
                40.71457739799998
              ],
              [
                -73.83122975599984,
                40.71462702899991
              ],
              [
                -73.83050460999999,
                40.71485694399992
              ],
              [
                -73.83035327299996,
                40.7149518069999
              ],
              [
                -73.83021283199997,
                40.715057778999885
              ],
              [
                -73.83008542999981,
                40.71517363999988
              ],
              [
                -73.8300853579999,
                40.71517370299991
              ],
              [
                -73.83008529899986,
                40.715173765999964
              ],
              [
                -73.8299725969999,
                40.71529812199986
              ],
              [
                -73.82987589099986,
                40.71542940099995
              ],
              [
                -73.8297961659998,
                40.715565718999876
              ],
              [
                -73.83072818099994,
                40.71625964099996
              ],
              [
                -73.8320714099999,
                40.717290595999906
              ],
              [
                -73.83348148399978,
                40.718333455999854
              ],
              [
                -73.83456936899985,
                40.719027281999935
              ],
              [
                -73.834570349,
                40.719027959999934
              ],
              [
                -73.83468657499992,
                40.71910892099988
              ],
              [
                -73.83479214399988,
                40.71920142799992
              ],
              [
                -73.83488359399998,
                40.719303271999934
              ],
              [
                -73.8349589409999,
                40.719412476999935
              ],
              [
                -73.83501684199989,
                40.71952679899992
              ],
              [
                -73.8350566629999,
                40.71964384199991
              ],
              [
                -73.8351128689999,
                40.71991727199992
              ],
              [
                -73.835185721,
                40.72018871899991
              ],
              [
                -73.83527502199999,
                40.72045730599992
              ],
              [
                -73.83538045099985,
                40.72072217999993
              ],
              [
                -73.83556698599992,
                40.72104319099986
              ],
              [
                -73.83576124499994,
                40.72136157399996
              ],
              [
                -73.83596312399996,
                40.72167716799987
              ],
              [
                -73.83603385999993,
                40.72176720199997
              ],
              [
                -73.8365236769999,
                40.72237055299989
              ],
              [
                -73.83836161400004,
                40.725561841999856
              ],
              [
                -73.83871473099992,
                40.72622079099989
              ],
              [
                -73.83906354600003,
                40.7268795339999
              ],
              [
                -73.8394453729999,
                40.7275367999999
              ],
              [
                -73.83985434299984,
                40.72817756899988
              ],
              [
                -73.84032703199988,
                40.728807114999896
              ],
              [
                -73.84146810899993,
                40.729998092999885
              ],
              [
                -73.84162181699998,
                40.73011794699993
              ],
              [
                -73.84281412599988,
                40.73115341199989
              ],
              [
                -73.84350489099985,
                40.73181434399993
              ],
              [
                -73.84370558699993,
                40.73203240499988
              ],
              [
                -73.84389098199985,
                40.73225885599994
              ],
              [
                -73.844060203,
                40.73249273999991
              ],
              [
                -73.84421250399983,
                40.73273304099995
              ],
              [
                -73.84434729799992,
                40.73297869299997
              ],
              [
                -73.84475302099987,
                40.733619997999945
              ],
              [
                -73.8449680009999,
                40.734007128999906
              ],
              [
                -73.8451154199999,
                40.73427740799986
              ],
              [
                -73.84542613099978,
                40.73494980299994
              ],
              [
                -73.84569341599997,
                40.7356664799999
              ],
              [
                -73.84590881499994,
                40.736399800999884
              ],
              [
                -73.84605541799989,
                40.737099187999874
              ],
              [
                -73.84689366199993,
                40.738671216999904
              ],
              [
                -73.84721361599989,
                40.73864398699987
              ],
              [
                -73.84732494199989,
                40.738771457999896
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000547566253402,
        "objectid": 96,
        "shape_leng": 0.185180248095,
        "location_id": 96,
        "zone": "Forest Park/Highland Park",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.83804164799996,
                40.70832329499996
              ],
              [
                -73.83829235499985,
                40.708396616999885
              ],
              [
                -73.8383589909999,
                40.70841610699994
              ],
              [
                -73.8386826099999,
                40.70849323899989
              ],
              [
                -73.8386827869999,
                40.70849328499984
              ],
              [
                -73.83868296400001,
                40.708493320999885
              ],
              [
                -73.83901101199996,
                40.70855449199996
              ],
              [
                -73.83911666099988,
                40.70857492899989
              ],
              [
                -73.83938086799994,
                40.70862603399992
              ],
              [
                -73.83975789399979,
                40.70868851299986
              ],
              [
                -73.84069937599986,
                40.70884116899991
              ],
              [
                -73.8409080659998,
                40.70887501099991
              ],
              [
                -73.84144586899984,
                40.70895751099994
              ],
              [
                -73.84151213299992,
                40.7089693809999
              ],
              [
                -73.84159160299998,
                40.70898160199991
              ],
              [
                -73.84220736299994,
                40.70907631699986
              ],
              [
                -73.84280177899991,
                40.709169477999914
              ],
              [
                -73.84290926199998,
                40.709186319999915
              ],
              [
                -73.84296437499994,
                40.70919495899991
              ],
              [
                -73.84314625299982,
                40.70922345599992
              ],
              [
                -73.84321861899991,
                40.70918746199987
              ],
              [
                -73.84347552199995,
                40.70906047099992
              ],
              [
                -73.84381825699984,
                40.70891182099991
              ],
              [
                -73.84417281299982,
                40.70877842099986
              ],
              [
                -73.84453738799996,
                40.70866105499991
              ],
              [
                -73.84491011200004,
                40.708560330999944
              ],
              [
                -73.84528898599983,
                40.708476678999936
              ],
              [
                -73.84567198199987,
                40.7084103659999
              ],
              [
                -73.84615958399984,
                40.70835082099996
              ],
              [
                -73.84665724099989,
                40.70831864199995
              ],
              [
                -73.8476241019999,
                40.708332622999855
              ],
              [
                -73.8485783879999,
                40.708438720999894
              ],
              [
                -73.84876725200003,
                40.70844457799991
              ],
              [
                -73.84895712599986,
                40.70843813799995
              ],
              [
                -73.84914591599988,
                40.708419319999905
              ],
              [
                -73.84933156199999,
                40.70838833399994
              ],
              [
                -73.84951207399992,
                40.708345660999925
              ],
              [
                -73.8504290539999,
                40.7080870669999
              ],
              [
                -73.85059051099984,
                40.708039882999856
              ],
              [
                -73.85110121699995,
                40.707900982999924
              ],
              [
                -73.85268225699998,
                40.70741814499992
              ],
              [
                -73.85275370600007,
                40.707397147999885
              ],
              [
                -73.85473240499985,
                40.70676414299989
              ],
              [
                -73.85473254599994,
                40.706731913999924
              ],
              [
                -73.854739573,
                40.705184849999945
              ],
              [
                -73.8541126479998,
                40.70442593399987
              ],
              [
                -73.85396105499991,
                40.70426421699992
              ],
              [
                -73.85392092799985,
                40.70422140899995
              ],
              [
                -73.85389480199986,
                40.70418341899991
              ],
              [
                -73.85371219899997,
                40.70391788699991
              ],
              [
                -73.85305663899999,
                40.70313704599993
              ],
              [
                -73.85252019099998,
                40.70229172399997
              ],
              [
                -73.85518109699989,
                40.7025832669999
              ],
              [
                -73.85529275899978,
                40.702595501999944
              ],
              [
                -73.85529571500001,
                40.70199100999992
              ],
              [
                -73.85538910499996,
                40.701976915999865
              ],
              [
                -73.85630830699988,
                40.70183818199996
              ],
              [
                -73.85716688299983,
                40.70167410499993
              ],
              [
                -73.85725351500002,
                40.701212176999896
              ],
              [
                -73.85739221099975,
                40.701213978999874
              ],
              [
                -73.85827405799986,
                40.70122365899992
              ],
              [
                -73.85893224399979,
                40.7013203449999
              ],
              [
                -73.85823494999983,
                40.7028328909999
              ],
              [
                -73.85819890699996,
                40.702911073999886
              ],
              [
                -73.85824915999993,
                40.70291693499992
              ],
              [
                -73.858530295,
                40.7029497089999
              ],
              [
                -73.85874741999989,
                40.702974297999894
              ],
              [
                -73.85997492299997,
                40.703108901999904
              ],
              [
                -73.86068159399994,
                40.70318418699986
              ],
              [
                -73.86142263099983,
                40.703269757999884
              ],
              [
                -73.86241891699996,
                40.70337747399988
              ],
              [
                -73.86285374199994,
                40.703414491999915
              ],
              [
                -73.86341506899986,
                40.7033788049999
              ],
              [
                -73.86379389300001,
                40.7033337959999
              ],
              [
                -73.8647283009999,
                40.70325882199997
              ],
              [
                -73.865640216,
                40.703182589999884
              ],
              [
                -73.86653775700002,
                40.70310973799991
              ],
              [
                -73.86724652399994,
                40.703051654999896
              ],
              [
                -73.86824273999996,
                40.7029701959999
              ],
              [
                -73.8687324349999,
                40.702929097999856
              ],
              [
                -73.86903271499997,
                40.70290390099996
              ],
              [
                -73.86915596200001,
                40.702893432999865
              ],
              [
                -73.86981898499995,
                40.70283792999985
              ],
              [
                -73.87073324199999,
                40.70276203899995
              ],
              [
                -73.87164751899996,
                40.70268730999989
              ],
              [
                -73.87210385799979,
                40.70264976299988
              ],
              [
                -73.87256153500006,
                40.7026120979999
              ],
              [
                -73.87351208999992,
                40.70253545899994
              ],
              [
                -73.8744627409999,
                40.702456831999946
              ],
              [
                -73.87543139399997,
                40.70237435299989
              ],
              [
                -73.87639785199995,
                40.70229404199989
              ],
              [
                -73.87625758399984,
                40.70174876599992
              ],
              [
                -73.87621714199997,
                40.70159500499992
              ],
              [
                -73.87778919199995,
                40.70101362099994
              ],
              [
                -73.87860797499998,
                40.70070418799989
              ],
              [
                -73.87942953599993,
                40.70040484899988
              ],
              [
                -73.880246012,
                40.700093348999914
              ],
              [
                -73.88106992699986,
                40.69979988799985
              ],
              [
                -73.88182690599986,
                40.69952929899987
              ],
              [
                -73.88273014799977,
                40.700549908999925
              ],
              [
                -73.88280948499987,
                40.7006834549999
              ],
              [
                -73.88343086899987,
                40.70047443799989
              ],
              [
                -73.88384293499996,
                40.70034725999994
              ],
              [
                -73.88431619499985,
                40.700201195999924
              ],
              [
                -73.88492223099993,
                40.70010402699988
              ],
              [
                -73.88584205499986,
                40.69996547099994
              ],
              [
                -73.8867672829999,
                40.69982608499988
              ],
              [
                -73.88771297999985,
                40.699678075999906
              ],
              [
                -73.88818025800003,
                40.698857910999926
              ],
              [
                -73.88809909499986,
                40.698763295999896
              ],
              [
                -73.88788297599991,
                40.69801511999986
              ],
              [
                -73.88785023699991,
                40.69784014499988
              ],
              [
                -73.88779396299992,
                40.69766542699991
              ],
              [
                -73.88771392599995,
                40.69749369299993
              ],
              [
                -73.88761064199984,
                40.69732774499996
              ],
              [
                -73.88748536199982,
                40.69717024899991
              ],
              [
                -73.88734003299987,
                40.6970236659999
              ],
              [
                -73.88717721900004,
                40.69689008599989
              ],
              [
                -73.88699989899986,
                40.69677114399992
              ],
              [
                -73.88681137199994,
                40.69666794799986
              ],
              [
                -73.88642048199995,
                40.69645814899995
              ],
              [
                -73.88648530299996,
                40.69643991499991
              ],
              [
                -73.88865416899996,
                40.69582954899994
              ],
              [
                -73.88828642399997,
                40.69523691699992
              ],
              [
                -73.88919365299998,
                40.69493576999991
              ],
              [
                -73.89195658699997,
                40.69441056999991
              ],
              [
                -73.89245207599993,
                40.69522572799987
              ],
              [
                -73.8950769439999,
                40.694591389999886
              ],
              [
                -73.89558625499983,
                40.6939791619999
              ],
              [
                -73.89398113199998,
                40.69307596499988
              ],
              [
                -73.89357935000005,
                40.69308532899988
              ],
              [
                -73.89326209899984,
                40.69220094699995
              ],
              [
                -73.89474270999995,
                40.69263695199989
              ],
              [
                -73.89576145299986,
                40.69233462199984
              ],
              [
                -73.89695653499987,
                40.6919492069999
              ],
              [
                -73.89768631800001,
                40.69336525799991
              ],
              [
                -73.89764233499987,
                40.69340865099986
              ],
              [
                -73.89742730199981,
                40.6932889799999
              ],
              [
                -73.89691021299986,
                40.69384226999994
              ],
              [
                -73.89736265799978,
                40.69411084699991
              ],
              [
                -73.89776907799997,
                40.69404304999987
              ],
              [
                -73.89817221699985,
                40.693962657999926
              ],
              [
                -73.89857107699993,
                40.69386983399992
              ],
              [
                -73.89896466800003,
                40.69376479299993
              ],
              [
                -73.89935202799984,
                40.69364779599994
              ],
              [
                -73.89973223499982,
                40.69351918299989
              ],
              [
                -73.900030392,
                40.69343745099991
              ],
              [
                -73.9003866789998,
                40.69313995999992
              ],
              [
                -73.90103661799981,
                40.691845252999904
              ],
              [
                -73.90123290699994,
                40.69144227899996
              ],
              [
                -73.90180467199994,
                40.6907662979999
              ],
              [
                -73.90042465099988,
                40.68818389799984
              ],
              [
                -73.90116155099986,
                40.687877934999946
              ],
              [
                -73.8965264419999,
                40.682403298999894
              ],
              [
                -73.89646625099994,
                40.682336421999885
              ],
              [
                -73.89643010499985,
                40.68244635899993
              ],
              [
                -73.89641909099993,
                40.68247987599993
              ],
              [
                -73.896367991,
                40.682599756999885
              ],
              [
                -73.896358989,
                40.68262086699989
              ],
              [
                -73.89628634799988,
                40.682758382999914
              ],
              [
                -73.89620169599988,
                40.68289148199994
              ],
              [
                -73.89599727399988,
                40.68319687899991
              ],
              [
                -73.89577945499994,
                40.6834971829999
              ],
              [
                -73.89561476599985,
                40.68370742199992
              ],
              [
                -73.89554859499991,
                40.68379189199988
              ],
              [
                -73.89530504899982,
                40.684080519999966
              ],
              [
                -73.89504926899988,
                40.68436259899988
              ],
              [
                -73.89417463299992,
                40.6852832479999
              ],
              [
                -73.89414204200001,
                40.6852465669999
              ],
              [
                -73.89404051299982,
                40.68513229799986
              ],
              [
                -73.89274142399994,
                40.68367018599988
              ],
              [
                -73.89252316899987,
                40.68342453299988
              ],
              [
                -73.8916601069998,
                40.6840441779999
              ],
              [
                -73.89015418699982,
                40.68500398999987
              ],
              [
                -73.88962787599998,
                40.6842364539999
              ],
              [
                -73.8890889529999,
                40.68464055299995
              ],
              [
                -73.88808341799984,
                40.68529363199984
              ],
              [
                -73.88728392899995,
                40.68562247499988
              ],
              [
                -73.88452250999984,
                40.68668474899988
              ],
              [
                -73.88377698600002,
                40.68786341099992
              ],
              [
                -73.88226665499997,
                40.689038550999896
              ],
              [
                -73.88118296199987,
                40.68986626999992
              ],
              [
                -73.87956139399984,
                40.691104780999936
              ],
              [
                -73.87950641199996,
                40.691146768999886
              ],
              [
                -73.87939315099985,
                40.69120963199989
              ],
              [
                -73.874020533,
                40.69419129499994
              ],
              [
                -73.870042929,
                40.694941484999966
              ],
              [
                -73.86891704399994,
                40.69515042299987
              ],
              [
                -73.86842489800001,
                40.69471811999993
              ],
              [
                -73.86681433899994,
                40.69506535099989
              ],
              [
                -73.86455708899989,
                40.695520456999915
              ],
              [
                -73.86368656399996,
                40.69569141799994
              ],
              [
                -73.86236834199985,
                40.69595014299993
              ],
              [
                -73.86016364299988,
                40.69638465799991
              ],
              [
                -73.85928498399986,
                40.69655371099988
              ],
              [
                -73.8583307899999,
                40.69674371099995
              ],
              [
                -73.85744447700002,
                40.696912984999905
              ],
              [
                -73.85657185199995,
                40.69706469199983
              ],
              [
                -73.85567421900002,
                40.69717935799988
              ],
              [
                -73.85478796099983,
                40.697296622999886
              ],
              [
                -73.85283544399991,
                40.69754896199987
              ],
              [
                -73.85277238699999,
                40.697557111999885
              ],
              [
                -73.85262948599983,
                40.697652077999926
              ],
              [
                -73.8526096609999,
                40.69780503999987
              ],
              [
                -73.85260789899989,
                40.697959664999885
              ],
              [
                -73.85262446500002,
                40.698114106999896
              ],
              [
                -73.85265915099988,
                40.698266471999915
              ],
              [
                -73.8527113089999,
                40.698414961999966
              ],
              [
                -73.85328620599984,
                40.69928735699989
              ],
              [
                -73.85377512599996,
                40.6999095679999
              ],
              [
                -73.85429389599983,
                40.70044576099992
              ],
              [
                -73.85443455699989,
                40.70060295199994
              ],
              [
                -73.85410116499996,
                40.70051374799992
              ],
              [
                -73.85070842300001,
                40.70020312499988
              ],
              [
                -73.84913837299976,
                40.69828274099998
              ],
              [
                -73.84820912899981,
                40.69851840899985
              ],
              [
                -73.84789669799994,
                40.69860032899992
              ],
              [
                -73.84700405300002,
                40.698825210999914
              ],
              [
                -73.84502472899999,
                40.69935937699991
              ],
              [
                -73.844273522,
                40.69978050599992
              ],
              [
                -73.84359398099988,
                40.700172351999896
              ],
              [
                -73.84293789599988,
                40.70055442899991
              ],
              [
                -73.84275678399989,
                40.70065761499992
              ],
              [
                -73.84275664199988,
                40.70065769499993
              ],
              [
                -73.8427564879998,
                40.70065776799991
              ],
              [
                -73.8425654379999,
                40.70075056599993
              ],
              [
                -73.84256525999987,
                40.70075065499987
              ],
              [
                -73.84256506999995,
                40.70075073599988
              ],
              [
                -73.84236544299989,
                40.7008324279999
              ],
              [
                -73.84236528999996,
                40.700832489999904
              ],
              [
                -73.842365123,
                40.700832543999915
              ],
              [
                -73.84215844799995,
                40.700902563999875
              ],
              [
                -73.84156378099983,
                40.70110059799986
              ],
              [
                -73.83947815200001,
                40.70269019399994
              ],
              [
                -73.83856418299983,
                40.70329696899991
              ],
              [
                -73.83696237799982,
                40.704212451999936
              ],
              [
                -73.83733095699989,
                40.704854975999886
              ],
              [
                -73.83784595199988,
                40.70562953999993
              ],
              [
                -73.83793637399988,
                40.70574914799994
              ],
              [
                -73.83793643299985,
                40.705749219999866
              ],
              [
                -73.83793647999991,
                40.705749291999865
              ],
              [
                -73.83801010900004,
                40.70587971799994
              ],
              [
                -73.83801013199991,
                40.70587977299991
              ],
              [
                -73.83801015599994,
                40.705879826999876
              ],
              [
                -73.83806401399981,
                40.7060189869999
              ],
              [
                -73.83806406099988,
                40.70601912199989
              ],
              [
                -73.83806408500001,
                40.70601925699987
              ],
              [
                -73.8380958239999,
                40.7061644729999
              ],
              [
                -73.83809584699989,
                40.70616458099993
              ],
              [
                -73.83809585899986,
                40.70616468899991
              ],
              [
                -73.83810401799988,
                40.7063130239999
              ],
              [
                -73.83808814499992,
                40.70646092899996
              ],
              [
                -73.83808812099979,
                40.70646110899985
              ],
              [
                -73.83808807400005,
                40.706461289999936
              ],
              [
                -73.83804879199987,
                40.70660539699989
              ],
              [
                -73.83804876799985,
                40.70660549599996
              ],
              [
                -73.8380487209998,
                40.706605603999954
              ],
              [
                -73.83798763399989,
                40.70674305399993
              ],
              [
                -73.83798760999981,
                40.70674309799989
              ],
              [
                -73.83798758599987,
                40.706743142999855
              ],
              [
                -73.83790724599992,
                40.706871190999934
              ],
              [
                -73.83790716199995,
                40.706871325999906
              ],
              [
                -73.83790705599984,
                40.70687146099988
              ],
              [
                -73.83781050699982,
                40.70698817599987
              ],
              [
                -73.83714702999997,
                40.707957045999905
              ],
              [
                -73.83743384099992,
                40.708092960999934
              ],
              [
                -73.83743389999984,
                40.708092986999894
              ],
              [
                -73.83743395899987,
                40.70809301499986
              ],
              [
                -73.83773266099998,
                40.708215356999915
              ],
              [
                -73.83804164799996,
                40.70832329499996
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000163303970435,
        "objectid": 97,
        "shape_leng": 0.0624760147423,
        "location_id": 97,
        "zone": "Fort Greene",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.9693409769999,
                40.69576842999993
              ],
              [
                -73.96935567900005,
                40.695854728999954
              ],
              [
                -73.9693719569999,
                40.69593775999996
              ],
              [
                -73.96937861700003,
                40.69599133399992
              ],
              [
                -73.9693945009999,
                40.696080388999846
              ],
              [
                -73.9697278989999,
                40.69780401399987
              ],
              [
                -73.97076190899996,
                40.697839283999905
              ],
              [
                -73.97178445299986,
                40.69788266399995
              ],
              [
                -73.97279477799988,
                40.69792083799993
              ],
              [
                -73.97332378599988,
                40.69793965099992
              ],
              [
                -73.9737751339999,
                40.6979593909999
              ],
              [
                -73.97478615599996,
                40.69800455299992
              ],
              [
                -73.97578275800007,
                40.69804772999987
              ],
              [
                -73.97678642599985,
                40.69808694699988
              ],
              [
                -73.97753101699976,
                40.698115812999916
              ],
              [
                -73.97808892799996,
                40.6981383249999
              ],
              [
                -73.98054419199988,
                40.69824651199987
              ],
              [
                -73.98050926199988,
                40.69744627499991
              ],
              [
                -73.9804096149999,
                40.69655934399993
              ],
              [
                -73.9804026989999,
                40.69650210499993
              ],
              [
                -73.98039561699981,
                40.6964434899999
              ],
              [
                -73.98038723500001,
                40.696382911999926
              ],
              [
                -73.98037063399991,
                40.69626304999988
              ],
              [
                -73.9803568289999,
                40.69617751699992
              ],
              [
                -73.98031019799991,
                40.69590599499989
              ],
              [
                -73.9802635679999,
                40.69563447299992
              ],
              [
                -73.98024270299999,
                40.69545652899992
              ],
              [
                -73.98021990699989,
                40.6952871029999
              ],
              [
                -73.9801557449999,
                40.69481011799997
              ],
              [
                -73.98013518099992,
                40.694698016999936
              ],
              [
                -73.98009475099997,
                40.69458700399985
              ],
              [
                -73.9800347509999,
                40.69448012199993
              ],
              [
                -73.97995651799985,
                40.69438029499986
              ],
              [
                -73.9798623699999,
                40.69429009199996
              ],
              [
                -73.97975539199989,
                40.69421147699993
              ],
              [
                -73.97961647699987,
                40.694115582999935
              ],
              [
                -73.97961635799989,
                40.6941155019999
              ],
              [
                -73.97961626399982,
                40.69411542199986
              ],
              [
                -73.97949074499996,
                40.69400713999988
              ],
              [
                -73.97949061499999,
                40.69400703099989
              ],
              [
                -73.97949049699993,
                40.69400690499993
              ],
              [
                -73.97938094200002,
                40.6938877569999
              ],
              [
                -73.9792896699999,
                40.693759993999876
              ],
              [
                -73.97928957499983,
                40.693759866999926
              ],
              [
                -73.97928950399996,
                40.69375974199996
              ],
              [
                -73.97921815699976,
                40.69362600299993
              ],
              [
                -73.97921806199986,
                40.69362583099996
              ],
              [
                -73.97921799199992,
                40.69362565199985
              ],
              [
                -73.97916718399999,
                40.693488322999926
              ],
              [
                -73.98011299200002,
                40.69352388099992
              ],
              [
                -73.98098333199991,
                40.69355661699993
              ],
              [
                -73.98147150299984,
                40.69357493499995
              ],
              [
                -73.98154756999999,
                40.69357778999994
              ],
              [
                -73.9817531689998,
                40.69358338499997
              ],
              [
                -73.9822668969999,
                40.693604775999866
              ],
              [
                -73.98234987399988,
                40.69360788799986
              ],
              [
                -73.98242892099991,
                40.69361085299994
              ],
              [
                -73.98251122999989,
                40.693614333999925
              ],
              [
                -73.98253983699992,
                40.693002615999944
              ],
              [
                -73.9817588899999,
                40.692955172999895
              ],
              [
                -73.98179025899985,
                40.692025415999886
              ],
              [
                -73.98178907299996,
                40.691644867999905
              ],
              [
                -73.98206215699987,
                40.69131209099992
              ],
              [
                -73.98213180199994,
                40.69122723699989
              ],
              [
                -73.9815011859999,
                40.689988154999874
              ],
              [
                -73.98147568199991,
                40.689930943999876
              ],
              [
                -73.98157420399987,
                40.68993518299994
              ],
              [
                -73.98276720199986,
                40.68998648099993
              ],
              [
                -73.98313467299995,
                40.69000227699989
              ],
              [
                -73.9835048569999,
                40.69000570599995
              ],
              [
                -73.98372011099993,
                40.69000671899993
              ],
              [
                -73.98295657299988,
                40.689699730999905
              ],
              [
                -73.98209737999989,
                40.68935800999991
              ],
              [
                -73.98155298299992,
                40.689146163999936
              ],
              [
                -73.98101786899988,
                40.68893792699991
              ],
              [
                -73.98092822899991,
                40.68890247699988
              ],
              [
                -73.9808430809999,
                40.688761361999916
              ],
              [
                -73.980691182,
                40.68851581099994
              ],
              [
                -73.98064972099984,
                40.688459476999874
              ],
              [
                -73.98012756199996,
                40.68775000899994
              ],
              [
                -73.97984055899983,
                40.68734433199992
              ],
              [
                -73.97959037699985,
                40.687003901999965
              ],
              [
                -73.97936297699988,
                40.68668199999995
              ],
              [
                -73.97909065799993,
                40.68629386599988
              ],
              [
                -73.97899402899994,
                40.686156131999894
              ],
              [
                -73.97827416099987,
                40.68519595199996
              ],
              [
                -73.97804289599988,
                40.68485661499989
              ],
              [
                -73.97745722099987,
                40.68405228699987
              ],
              [
                -73.97709796699996,
                40.683869779999895
              ],
              [
                -73.97682267999997,
                40.683753837999866
              ],
              [
                -73.97676303199997,
                40.683731781999946
              ],
              [
                -73.97653928699988,
                40.68364905099987
              ],
              [
                -73.97624898299998,
                40.68355587799986
              ],
              [
                -73.97595299799993,
                40.68347469799989
              ],
              [
                -73.97483191299989,
                40.68323523699998
              ],
              [
                -73.9737559849999,
                40.68303401999989
              ],
              [
                -73.97278445899987,
                40.68285242199994
              ],
              [
                -73.97264904900005,
                40.68282745499994
              ],
              [
                -73.97186424199982,
                40.68267028699993
              ],
              [
                -73.97067424999982,
                40.6824209629999
              ],
              [
                -73.96969385699983,
                40.68221680499991
              ],
              [
                -73.96868961400001,
                40.68200525799989
              ],
              [
                -73.96867398499988,
                40.68208599299987
              ],
              [
                -73.96906202000002,
                40.68415736099991
              ],
              [
                -73.96948939899981,
                40.68628830699986
              ],
              [
                -73.9697831419999,
                40.68775161199994
              ],
              [
                -73.97013154199992,
                40.68950630799986
              ],
              [
                -73.96910362499993,
                40.68946752199987
              ],
              [
                -73.96952115199994,
                40.69156355899989
              ],
              [
                -73.96983118499983,
                40.69313424399997
              ],
              [
                -73.96880989600005,
                40.69312038199996
              ],
              [
                -73.9693409769999,
                40.69576842999993
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000485773020954,
        "objectid": 98,
        "shape_leng": 0.121661018278,
        "location_id": 98,
        "zone": "Fresh Meadows",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.76360860199983,
                40.73917476499987
              ],
              [
                -73.7643149549999,
                40.740672626999924
              ],
              [
                -73.76515128799993,
                40.74243734599991
              ],
              [
                -73.76556849699985,
                40.74325815499988
              ],
              [
                -73.76617484699997,
                40.74356727199995
              ],
              [
                -73.76633756199995,
                40.74365225899988
              ],
              [
                -73.76649109699986,
                40.74374842999987
              ],
              [
                -73.76663352600005,
                40.74385485299991
              ],
              [
                -73.7667631489999,
                40.74397036299992
              ],
              [
                -73.76687853500006,
                40.74409357899987
              ],
              [
                -73.76697863,
                40.74422296799989
              ],
              [
                -73.76761507999991,
                40.745526354999924
              ],
              [
                -73.76770614499998,
                40.74572274199992
              ],
              [
                -73.76772991399994,
                40.74577622599993
              ],
              [
                -73.76857701200005,
                40.74552220499987
              ],
              [
                -73.76937164099995,
                40.745220308999876
              ],
              [
                -73.770389824,
                40.74473791399991
              ],
              [
                -73.77137678500003,
                40.744251035999895
              ],
              [
                -73.77145218799996,
                40.744221522999915
              ],
              [
                -73.77153402299984,
                40.74419162499989
              ],
              [
                -73.77156671899985,
                40.74417951599989
              ],
              [
                -73.77162097999985,
                40.744159730999876
              ],
              [
                -73.7724146099999,
                40.74388484499992
              ],
              [
                -73.77281267599984,
                40.7437892489999
              ],
              [
                -73.77321534699996,
                40.74370439599988
              ],
              [
                -73.77362185199992,
                40.74363046299994
              ],
              [
                -73.77403143100003,
                40.743567600999945
              ],
              [
                -73.77444330499989,
                40.74351589999993
              ],
              [
                -73.77486382100001,
                40.743453335999945
              ],
              [
                -73.77551238399991,
                40.74335684299994
              ],
              [
                -73.77569182399996,
                40.74333358899986
              ],
              [
                -73.77573836999991,
                40.743325646999935
              ],
              [
                -73.77579713399984,
                40.743317665999946
              ],
              [
                -73.77805835699986,
                40.74298648899991
              ],
              [
                -73.778599248,
                40.742908122999886
              ],
              [
                -73.77965641299991,
                40.742729309999895
              ],
              [
                -73.78036603499996,
                40.74259166299987
              ],
              [
                -73.78068584999994,
                40.74250560699994
              ],
              [
                -73.78100566399995,
                40.74241954099993
              ],
              [
                -73.78121857699983,
                40.742362250999925
              ],
              [
                -73.78137698899997,
                40.7423199939999
              ],
              [
                -73.78176526799983,
                40.74221640599995
              ],
              [
                -73.78216009699989,
                40.742115548999905
              ],
              [
                -73.7831101189999,
                40.74184830499993
              ],
              [
                -73.78349697199984,
                40.74174135999994
              ],
              [
                -73.78400944099984,
                40.741599356999856
              ],
              [
                -73.78484244599998,
                40.74136852999993
              ],
              [
                -73.78577051299995,
                40.74111067999991
              ],
              [
                -73.78664844699985,
                40.74086674899993
              ],
              [
                -73.78669085300001,
                40.74085298599988
              ],
              [
                -73.78673516799984,
                40.74084177599985
              ],
              [
                -73.78759444399994,
                40.74061795799991
              ],
              [
                -73.78848243499986,
                40.7403682779999
              ],
              [
                -73.7886511029998,
                40.74032571399992
              ],
              [
                -73.78934727299989,
                40.74012633799989
              ],
              [
                -73.79020750099997,
                40.73989470299987
              ],
              [
                -73.7911152649998,
                40.73964983899986
              ],
              [
                -73.79202817599997,
                40.73940358099988
              ],
              [
                -73.79285797099996,
                40.73917973299996
              ],
              [
                -73.79291603199995,
                40.73916776099987
              ],
              [
                -73.79297881699995,
                40.73915188099988
              ],
              [
                -73.79369341399999,
                40.738950061999894
              ],
              [
                -73.79440801799996,
                40.738748229999906
              ],
              [
                -73.79533772999987,
                40.73849367299992
              ],
              [
                -73.79588681699978,
                40.73837637499989
              ],
              [
                -73.79611584799986,
                40.73833613999988
              ],
              [
                -73.79640218700004,
                40.73828235799987
              ],
              [
                -73.79639000899994,
                40.73823357999986
              ],
              [
                -73.79636108899999,
                40.73812415299987
              ],
              [
                -73.79607605199982,
                40.738144297999966
              ],
              [
                -73.79650350099979,
                40.73800383599995
              ],
              [
                -73.79651029099988,
                40.7379086369999
              ],
              [
                -73.79584803299987,
                40.73807467999991
              ],
              [
                -73.79594852799994,
                40.73781371899989
              ],
              [
                -73.79516450899986,
                40.73641140399996
              ],
              [
                -73.79458633199991,
                40.735052251999875
              ],
              [
                -73.79459579399989,
                40.73454424599992
              ],
              [
                -73.794626052,
                40.73359608899993
              ],
              [
                -73.79459486499994,
                40.7334093579999
              ],
              [
                -73.79454395199986,
                40.733224229999905
              ],
              [
                -73.79447360099982,
                40.73304250799991
              ],
              [
                -73.79438449799987,
                40.73286592899996
              ],
              [
                -73.7942776749999,
                40.73269614499986
              ],
              [
                -73.79397708699996,
                40.73229685299988
              ],
              [
                -73.79392707899994,
                40.73199969399992
              ],
              [
                -73.793909425,
                40.731717775999904
              ],
              [
                -73.79381937199997,
                40.73165738799989
              ],
              [
                -73.79384983499995,
                40.73124678299997
              ],
              [
                -73.79378938699998,
                40.730701180999866
              ],
              [
                -73.79352516299998,
                40.73005381599991
              ],
              [
                -73.79322750899982,
                40.72934789399992
              ],
              [
                -73.79283910399985,
                40.728429007999914
              ],
              [
                -73.79229974199997,
                40.72714071899992
              ],
              [
                -73.79204513299997,
                40.72655107399986
              ],
              [
                -73.79191210299997,
                40.72620816199992
              ],
              [
                -73.79176169599994,
                40.7258396519999
              ],
              [
                -73.79174472699992,
                40.725788849999944
              ],
              [
                -73.7916922309998,
                40.725792384999906
              ],
              [
                -73.79085659799982,
                40.72602748899991
              ],
              [
                -73.78998042299993,
                40.726317244999954
              ],
              [
                -73.78964444799998,
                40.72642499899994
              ],
              [
                -73.78911280699997,
                40.7266001679999
              ],
              [
                -73.78825003899982,
                40.726886972999935
              ],
              [
                -73.78738339699984,
                40.727171981999945
              ],
              [
                -73.78655584699987,
                40.727441503999906
              ],
              [
                -73.78619017499982,
                40.72756357499993
              ],
              [
                -73.78568492899991,
                40.72773019299992
              ],
              [
                -73.78482734399982,
                40.72801171499992
              ],
              [
                -73.78397695399983,
                40.72829139699994
              ],
              [
                -73.78360140199987,
                40.72841281999989
              ],
              [
                -73.78292253099985,
                40.728635948999944
              ],
              [
                -73.78205851399986,
                40.728918895999875
              ],
              [
                -73.78116054999998,
                40.72921275899992
              ],
              [
                -73.78024774699995,
                40.72945982399991
              ],
              [
                -73.77936529399982,
                40.72970186799997
              ],
              [
                -73.77848636199998,
                40.729943894999906
              ],
              [
                -73.77757506899987,
                40.73019327299987
              ],
              [
                -73.7775479359998,
                40.730136776999906
              ],
              [
                -73.77672825799982,
                40.72843008099995
              ],
              [
                -73.77596177499996,
                40.726812848999955
              ],
              [
                -73.77556110500002,
                40.725969863999865
              ],
              [
                -73.7752338719998,
                40.725237532999884
              ],
              [
                -73.77634188999991,
                40.72446564699988
              ],
              [
                -73.7764331279998,
                40.72430318199989
              ],
              [
                -73.77654571399995,
                40.72414504599986
              ],
              [
                -73.77667910999996,
                40.72399361299994
              ],
              [
                -73.77683220599997,
                40.72385121499991
              ],
              [
                -73.77700327899989,
                40.723720008999905
              ],
              [
                -73.77719009799996,
                40.723601881999905
              ],
              [
                -73.77738999299997,
                40.72349836699989
              ],
              [
                -73.77759997899993,
                40.72341057599988
              ],
              [
                -73.77781693000003,
                40.7233391429999
              ],
              [
                -73.7780376599998,
                40.723284268999855
              ],
              [
                -73.77787601899989,
                40.72303670299994
              ],
              [
                -73.77774895399996,
                40.72284210999989
              ],
              [
                -73.77757868099984,
                40.722642217999855
              ],
              [
                -73.77738048899985,
                40.72244906299987
              ],
              [
                -73.77732562899998,
                40.72239270099994
              ],
              [
                -73.77727808000003,
                40.72234633599988
              ],
              [
                -73.77600685799993,
                40.7233035689999
              ],
              [
                -73.77583631399985,
                40.72343173999993
              ],
              [
                -73.77603280499999,
                40.72336247699992
              ],
              [
                -73.7760563109999,
                40.72335593999985
              ],
              [
                -73.77587312899985,
                40.723472820999916
              ],
              [
                -73.77548473600002,
                40.72372061399989
              ],
              [
                -73.77524876600002,
                40.723843161999895
              ],
              [
                -73.77496975299987,
                40.723988060999936
              ],
              [
                -73.774371087,
                40.72426113199988
              ],
              [
                -73.77423835499987,
                40.72431652299992
              ],
              [
                -73.77372529499999,
                40.724530629999926
              ],
              [
                -73.77348555899981,
                40.72462495199988
              ],
              [
                -73.77286784099985,
                40.72486798499995
              ],
              [
                -73.77224702999986,
                40.72507707299991
              ],
              [
                -73.77165390499994,
                40.72526758999993
              ],
              [
                -73.77163268999986,
                40.72527440499992
              ],
              [
                -73.77139578199996,
                40.725350500999866
              ],
              [
                -73.76991329299986,
                40.72574839799991
              ],
              [
                -73.7697527519999,
                40.72578523199992
              ],
              [
                -73.76924074899993,
                40.72590270099988
              ],
              [
                -73.76872630299988,
                40.72599314299994
              ],
              [
                -73.76871602799994,
                40.72598984399986
              ],
              [
                -73.7687058369998,
                40.72598626699988
              ],
              [
                -73.76869575399984,
                40.72598241999985
              ],
              [
                -73.76868582499995,
                40.72597829299986
              ],
              [
                -73.76867607499993,
                40.725973915999894
              ],
              [
                -73.76866656199985,
                40.7259692859999
              ],
              [
                -73.7686572999999,
                40.72596439499989
              ],
              [
                -73.76864834499995,
                40.72595927999993
              ],
              [
                -73.76863972199986,
                40.72595394099988
              ],
              [
                -73.76863146699993,
                40.725948403999965
              ],
              [
                -73.76862361599991,
                40.725942669999874
              ],
              [
                -73.7686161789998,
                40.725936765999954
              ],
              [
                -73.76860920399996,
                40.72593070099992
              ],
              [
                -73.76860271599992,
                40.7259245189999
              ],
              [
                -73.76859671300001,
                40.72591821299991
              ],
              [
                -73.76859122999998,
                40.725911825999916
              ],
              [
                -73.76858628199993,
                40.72590537699995
              ],
              [
                -73.76858186599988,
                40.72589887499989
              ],
              [
                -73.76857799399986,
                40.725892356999914
              ],
              [
                -73.76857466699991,
                40.72588583999988
              ],
              [
                -73.768571885,
                40.72587934999991
              ],
              [
                -73.7685696449999,
                40.72587288899995
              ],
              [
                -73.76856792799998,
                40.72586650099988
              ],
              [
                -73.76856674299998,
                40.72586018599985
              ],
              [
                -73.76856605399993,
                40.72585397099989
              ],
              [
                -73.76745788000002,
                40.72613928099987
              ],
              [
                -73.76717559599999,
                40.7261826729999
              ],
              [
                -73.76660888099983,
                40.726267875999966
              ],
              [
                -73.76629387899985,
                40.72631836799996
              ],
              [
                -73.76618933199987,
                40.72632947499992
              ],
              [
                -73.76610679099996,
                40.72633693499997
              ],
              [
                -73.76568253199987,
                40.72637752399991
              ],
              [
                -73.7654693229998,
                40.726382041999926
              ],
              [
                -73.76545422799997,
                40.72638236199987
              ],
              [
                -73.76475781199981,
                40.72639712399995
              ],
              [
                -73.76386689499986,
                40.7262841589999
              ],
              [
                -73.76376765199994,
                40.726271572999934
              ],
              [
                -73.76368265699995,
                40.72625991699992
              ],
              [
                -73.76241922899995,
                40.72608659299995
              ],
              [
                -73.7620440879999,
                40.72603512499993
              ],
              [
                -73.76181677799993,
                40.72600393899992
              ],
              [
                -73.76102756099999,
                40.72589417899992
              ],
              [
                -73.76083705999994,
                40.725877042999855
              ],
              [
                -73.76017281699987,
                40.72581402099991
              ],
              [
                -73.75994854100003,
                40.72580527799992
              ],
              [
                -73.75955973699996,
                40.72580223299992
              ],
              [
                -73.75922708600002,
                40.725805977999926
              ],
              [
                -73.7587980189999,
                40.72584059599992
              ],
              [
                -73.75835433199993,
                40.72588850899994
              ],
              [
                -73.75796018499997,
                40.72595204999992
              ],
              [
                -73.75755793699986,
                40.7260324399999
              ],
              [
                -73.75721902199992,
                40.72610785499996
              ],
              [
                -73.75698318299987,
                40.726160324999896
              ],
              [
                -73.75683174999999,
                40.726194017999916
              ],
              [
                -73.75677678599992,
                40.726208335999964
              ],
              [
                -73.75669771299987,
                40.7262287109999
              ],
              [
                -73.75674739599988,
                40.72627289499993
              ],
              [
                -73.75678272799996,
                40.726305783999855
              ],
              [
                -73.75684854799977,
                40.726355811999944
              ],
              [
                -73.75787141499994,
                40.7271068509999
              ],
              [
                -73.75831675599993,
                40.72736240699991
              ],
              [
                -73.75993818299986,
                40.72815841199991
              ],
              [
                -73.76024211099985,
                40.72830761199987
              ],
              [
                -73.76114083699993,
                40.728794413999886
              ],
              [
                -73.76160814399992,
                40.72904753399989
              ],
              [
                -73.76234209799992,
                40.729538290999876
              ],
              [
                -73.76327537799995,
                40.730240540999915
              ],
              [
                -73.76381866799998,
                40.73068357399991
              ],
              [
                -73.76435071499999,
                40.731151191999885
              ],
              [
                -73.7648685299999,
                40.73166863999996
              ],
              [
                -73.765288304,
                40.73211466499992
              ],
              [
                -73.76558856199988,
                40.732467520999876
              ],
              [
                -73.76564374500005,
                40.73253312699996
              ],
              [
                -73.76555086,
                40.73254428299994
              ],
              [
                -73.76491667699993,
                40.732654672999935
              ],
              [
                -73.76434014999984,
                40.73275295499994
              ],
              [
                -73.76369979199988,
                40.73286411699993
              ],
              [
                -73.76374010499987,
                40.73291287299987
              ],
              [
                -73.76470929199999,
                40.73408485499987
              ],
              [
                -73.76510920699994,
                40.734556584999936
              ],
              [
                -73.76503749400004,
                40.73457204399989
              ],
              [
                -73.7642906499999,
                40.734802606999885
              ],
              [
                -73.76388970599983,
                40.73492638099986
              ],
              [
                -73.76349692299982,
                40.73504763299994
              ],
              [
                -73.76318167799994,
                40.73514494799992
              ],
              [
                -73.76311240499989,
                40.73516391399995
              ],
              [
                -73.76273303499985,
                40.735262162999945
              ],
              [
                -73.76252875800002,
                40.73600396099992
              ],
              [
                -73.76248538699991,
                40.73618169499989
              ],
              [
                -73.76246334599998,
                40.73636362499995
              ],
              [
                -73.76246348299985,
                40.73654750099995
              ],
              [
                -73.7624860929999,
                40.73673098099987
              ],
              [
                -73.76253086199996,
                40.73691167999987
              ],
              [
                -73.76259694699998,
                40.73708732599994
              ],
              [
                -73.76268297099983,
                40.73725583599988
              ],
              [
                -73.76360860199983,
                40.73917476499987
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.00121016463877,
        "objectid": 99,
        "shape_leng": 0.1833714893,
        "location_id": 99,
        "zone": "Freshkills Park",
        "borough": "Staten Island"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -74.16842916199994,
                40.58649792299988
              ],
              [
                -74.16801418199992,
                40.5880698669999
              ],
              [
                -74.1679917399999,
                40.58818579699995
              ],
              [
                -74.16796126199992,
                40.588300656999905
              ],
              [
                -74.16792283599987,
                40.58841412299991
              ],
              [
                -74.1678765679999,
                40.588525874999895
              ],
              [
                -74.16782312399997,
                40.58863056899987
              ],
              [
                -74.16776136599995,
                40.58873256899994
              ],
              [
                -74.1676915249999,
                40.58883149299986
              ],
              [
                -74.16761386399995,
                40.588926966999935
              ],
              [
                -74.16727661599998,
                40.58929921399986
              ],
              [
                -74.16692477899988,
                40.589663906999924
              ],
              [
                -74.1665588039999,
                40.59002054199996
              ],
              [
                -74.16617917099994,
                40.59036865799991
              ],
              [
                -74.16578641299995,
                40.59070780399986
              ],
              [
                -74.16369888299994,
                40.592258338999905
              ],
              [
                -74.16363304199993,
                40.59240052699994
              ],
              [
                -74.16362405199995,
                40.5924199369999
              ],
              [
                -74.16356570199993,
                40.592586526999874
              ],
              [
                -74.16352460699994,
                40.59275663199988
              ],
              [
                -74.16350123599995,
                40.5929286829999
              ],
              [
                -74.16349571599993,
                40.593101060999864
              ],
              [
                -74.16350785499993,
                40.59327217999994
              ],
              [
                -74.16360268199993,
                40.593374198999854
              ],
              [
                -74.16439050499996,
                40.59348246099993
              ],
              [
                -74.166390049,
                40.593737925999925
              ],
              [
                -74.16778450099996,
                40.59418566499994
              ],
              [
                -74.16886362299992,
                40.59472808699995
              ],
              [
                -74.16828458099997,
                40.596433164999944
              ],
              [
                -74.1679964909999,
                40.597922359999885
              ],
              [
                -74.16762086799996,
                40.599849696999925
              ],
              [
                -74.16719634899995,
                40.602075311999926
              ],
              [
                -74.16900518100002,
                40.6022733739999
              ],
              [
                -74.17062978699994,
                40.60243131199998
              ],
              [
                -74.1709288839999,
                40.60245585699987
              ],
              [
                -74.1712268479999,
                40.60249861199985
              ],
              [
                -74.171520817,
                40.6025595089999
              ],
              [
                -74.17180796899991,
                40.60263814499993
              ],
              [
                -74.1720855709999,
                40.602733770999905
              ],
              [
                -74.17235109299999,
                40.60284533499991
              ],
              [
                -74.17260229799992,
                40.602971490999934
              ],
              [
                -74.17488959399996,
                40.6022165729999
              ],
              [
                -74.17582749699989,
                40.601526852999925
              ],
              [
                -74.17822457199992,
                40.59982806099992
              ],
              [
                -74.17932277499995,
                40.59676057899985
              ],
              [
                -74.1803101799999,
                40.595710899999915
              ],
              [
                -74.18075192499991,
                40.59524128099991
              ],
              [
                -74.18095672799987,
                40.59502355399995
              ],
              [
                -74.18183084799989,
                40.594094245999884
              ],
              [
                -74.18468224999998,
                40.591478950999964
              ],
              [
                -74.18683246699995,
                40.58907136099992
              ],
              [
                -74.18730248699987,
                40.588488221999896
              ],
              [
                -74.185371048,
                40.58745101499987
              ],
              [
                -74.185518287,
                40.58743794299993
              ],
              [
                -74.18566449699989,
                40.58741932799991
              ],
              [
                -74.18580931799991,
                40.58739521799988
              ],
              [
                -74.18595239599988,
                40.587365671999876
              ],
              [
                -74.18609337999993,
                40.58733075999993
              ],
              [
                -74.18623192299994,
                40.5872905709999
              ],
              [
                -74.18636768699997,
                40.587245200999895
              ],
              [
                -74.18650033699991,
                40.58719476299993
              ],
              [
                -74.18681414999996,
                40.58710057399991
              ],
              [
                -74.18705055599993,
                40.587083020999934
              ],
              [
                -74.18719250099991,
                40.5870462399999
              ],
              [
                -74.18733173099997,
                40.58700385699993
              ],
              [
                -74.18746786899996,
                40.5869559899999
              ],
              [
                -74.18760054199991,
                40.58690276699989
              ],
              [
                -74.18772938699996,
                40.5868443349999
              ],
              [
                -74.18785405399997,
                40.58678085299989
              ],
              [
                -74.18797420199998,
                40.58671249399986
              ],
              [
                -74.18808950399989,
                40.586639443999914
              ],
              [
                -74.18819964399991,
                40.58656190399996
              ],
              [
                -74.1883043219999,
                40.58648008499989
              ],
              [
                -74.18859601799987,
                40.586106195999946
              ],
              [
                -74.18877046599995,
                40.58600580099985
              ],
              [
                -74.18895013099997,
                40.58591090399989
              ],
              [
                -74.18913471499995,
                40.58582166299985
              ],
              [
                -74.18932391199996,
                40.58573822599987
              ],
              [
                -74.18951740799994,
                40.58566073099989
              ],
              [
                -74.18971488199998,
                40.585589306999964
              ],
              [
                -74.19078902199995,
                40.58518836699991
              ],
              [
                -74.19131783499998,
                40.5857044889999
              ],
              [
                -74.19226048399997,
                40.585206968999955
              ],
              [
                -74.1923211319999,
                40.58491324199991
              ],
              [
                -74.19251747899993,
                40.58515898199988
              ],
              [
                -74.19332419799998,
                40.58503828099988
              ],
              [
                -74.19423261299991,
                40.585146805999884
              ],
              [
                -74.19964981299998,
                40.58226262599992
              ],
              [
                -74.20048848799996,
                40.57994977799989
              ],
              [
                -74.20051512399985,
                40.57996038199989
              ],
              [
                -74.20053829099997,
                40.57996684599992
              ],
              [
                -74.2005564709999,
                40.57996640299993
              ],
              [
                -74.20058554299995,
                40.579961575999945
              ],
              [
                -74.20062395199992,
                40.57994860899994
              ],
              [
                -74.20065720799997,
                40.579934002999956
              ],
              [
                -74.20069287199986,
                40.579918322999895
              ],
              [
                -74.20073234099989,
                40.5799105679999
              ],
              [
                -74.20079777099993,
                40.579904826999915
              ],
              [
                -74.20085107099999,
                40.57991170499991
              ],
              [
                -74.20094091399993,
                40.579919562999876
              ],
              [
                -74.20103374199996,
                40.57992585099987
              ],
              [
                -74.20109537699989,
                40.579927500999915
              ],
              [
                -74.20115662599996,
                40.57993156499993
              ],
              [
                -74.20121027499994,
                40.579935038999935
              ],
              [
                -74.20125254599999,
                40.57993721699994
              ],
              [
                -74.20128744299997,
                40.5799416559999
              ],
              [
                -74.2013187079999,
                40.579947255999855
              ],
              [
                -74.20135420199988,
                40.5799583649999
              ],
              [
                -74.20138059799989,
                40.57997327799995
              ],
              [
                -74.20140358699999,
                40.57999220499992
              ],
              [
                -74.20143434499994,
                40.580020805999894
              ],
              [
                -74.2014525309999,
                40.5800347179999
              ],
              [
                -74.20148133599993,
                40.580048555999944
              ],
              [
                -74.20154141299999,
                40.580082349999884
              ],
              [
                -74.20158593699988,
                40.580103048999874
              ],
              [
                -74.201626822,
                40.58011151499993
              ],
              [
                -74.2016748199999,
                40.5801148059999
              ],
              [
                -74.20173840099987,
                40.58011683599991
              ],
              [
                -74.20176039899995,
                40.58011715299988
              ],
              [
                -74.20179998599994,
                40.58012471499988
              ],
              [
                -74.20184248899996,
                40.58014407399991
              ],
              [
                -74.20187663199988,
                40.580161496999885
              ],
              [
                -74.20191105199993,
                40.58017458399996
              ],
              [
                -74.20195345799993,
                40.58018581899988
              ],
              [
                -74.20200397499998,
                40.58018899599994
              ],
              [
                -74.20204828299998,
                40.58018625699993
              ],
              [
                -74.20210029899994,
                40.58018503899992
              ],
              [
                -74.20214420399996,
                40.5801919059999
              ],
              [
                -74.2021713749999,
                40.58020105299987
              ],
              [
                -74.20230969599996,
                40.58023945699995
              ],
              [
                -74.20240475299995,
                40.5802571309999
              ],
              [
                -74.20251156099994,
                40.580279916999906
              ],
              [
                -74.20254855399999,
                40.58028665799987
              ],
              [
                -74.20260239099991,
                40.58029205299992
              ],
              [
                -74.20264545999996,
                40.580296560999884
              ],
              [
                -74.20269703699992,
                40.580304951999935
              ],
              [
                -74.20276536999991,
                40.58031819799994
              ],
              [
                -74.20281236899987,
                40.580324427999884
              ],
              [
                -74.20285677399994,
                40.58032983999996
              ],
              [
                -74.20300418199997,
                40.580350082999914
              ],
              [
                -74.20309425099991,
                40.58036082099994
              ],
              [
                -74.20314675199991,
                40.580365310999866
              ],
              [
                -74.20319223099993,
                40.58036874499986
              ],
              [
                -74.20323381699988,
                40.580370455999876
              ],
              [
                -74.20327432799998,
                40.580374143999904
              ],
              [
                -74.20332116299994,
                40.580385643999925
              ],
              [
                -74.20337531999998,
                40.58040201699986
              ],
              [
                -74.20343081399989,
                40.58041929399991
              ],
              [
                -74.20346962599996,
                40.58043264699992
              ],
              [
                -74.20350663899988,
                40.580436159999884
              ],
              [
                -74.20354150599995,
                40.58043609799988
              ],
              [
                -74.20356291499995,
                40.58043415399991
              ],
              [
                -74.20366188299988,
                40.580420565999894
              ],
              [
                -74.2037591199999,
                40.5804010189999
              ],
              [
                -74.20385400899987,
                40.58037563699991
              ],
              [
                -74.2039459509999,
                40.580344580999906
              ],
              [
                -74.20403100999988,
                40.58033315899993
              ],
              [
                -74.20420118899996,
                40.580322231999894
              ],
              [
                -74.20437042899988,
                40.580304787999914
              ],
              [
                -74.20453830399995,
                40.58028087099992
              ],
              [
                -74.20456367299994,
                40.58028061599989
              ],
              [
                -74.2045886649999,
                40.5802772819999
              ],
              [
                -74.20461264099993,
                40.58027095299988
              ],
              [
                -74.20463498699989,
                40.580261791999895
              ],
              [
                -74.20465513199991,
                40.58025003199991
              ],
              [
                -74.20467256199996,
                40.58023597499993
              ],
              [
                -74.20468682999994,
                40.58021997899994
              ],
              [
                -74.20469757299996,
                40.580202453999895
              ],
              [
                -74.2047045139999,
                40.580183847999876
              ],
              [
                -74.20476467899992,
                40.580142890999916
              ],
              [
                -74.20482987599989,
                40.58010668699991
              ],
              [
                -74.20489946199994,
                40.580075591999915
              ],
              [
                -74.20497275099994,
                40.58004991499991
              ],
              [
                -74.20504901799995,
                40.580029906999854
              ],
              [
                -74.20512751299994,
                40.580015766999914
              ],
              [
                -74.20520746099992,
                40.580007633999905
              ],
              [
                -74.20523194099997,
                40.58001050699994
              ],
              [
                -74.20525670699998,
                40.58001031599988
              ],
              [
                -74.20528110599997,
                40.580007067999915
              ],
              [
                -74.20530449199997,
                40.5800008469999
              ],
              [
                -74.20532624599998,
                40.579991818999915
              ],
              [
                -74.20534579299989,
                40.579980221999946
              ],
              [
                -74.20536261699995,
                40.579966361999915
              ],
              [
                -74.2053762709999,
                40.57995060699991
              ],
              [
                -74.2053863959999,
                40.57993337199986
              ],
              [
                -74.20543842599989,
                40.57990221299989
              ],
              [
                -74.20549524799992,
                40.57987637399991
              ],
              [
                -74.20555593099994,
                40.57985627799991
              ],
              [
                -74.20561948299995,
                40.57984225399984
              ],
              [
                -74.20568486299997,
                40.5798345309999
              ],
              [
                -74.20575100199994,
                40.57983323599993
              ],
              [
                -74.20583829199992,
                40.579841260999906
              ],
              [
                -74.20593287099989,
                40.57989243799992
              ],
              [
                -74.205950418,
                40.57995433999985
              ],
              [
                -74.20596154297344,
                40.58004699388278
              ],
              [
                -74.2059577108719,
                40.579310888030356
              ],
              [
                -74.20591732509392,
                40.57861664755362
              ],
              [
                -74.20593482006235,
                40.578558255465424
              ],
              [
                -74.20593715361618,
                40.57854126307646
              ],
              [
                -74.20594901152562,
                40.57851576118643
              ],
              [
                -74.20595372759396,
                40.57849694531838
              ],
              [
                -74.20596399604791,
                40.578472660030464
              ],
              [
                -74.20597744326368,
                40.57844654648416
              ],
              [
                -74.20598931391189,
                40.57842589790918
              ],
              [
                -74.20600673956517,
                40.57839977890131
              ],
              [
                -74.20601941790578,
                40.57838276913292
              ],
              [
                -74.20603132914712,
                40.57837486029522
              ],
              [
                -74.2060392303421,
                40.578356645839854
              ],
              [
                -74.20604632007586,
                40.57833357832398
              ],
              [
                -74.2060629581204,
                40.578310493681954
              ],
              [
                -74.20607561715705,
                40.57828681021901
              ],
              [
                -74.2060954249185,
                40.57825947316978
              ],
              [
                -74.20610886698275,
                40.57823214804008
              ],
              [
                -74.20613343399746,
                40.578199949605946
              ],
              [
                -74.20615112441064,
                40.57818846049044
              ],
              [
                -74.20616518055131,
                40.57817380487701
              ],
              [
                -74.20617390206725,
                40.57816347474194
              ],
              [
                -74.20617482449852,
                40.57814505551433
              ],
              [
                -74.20615951026187,
                40.57811251953037
              ],
              [
                -74.20615140873657,
                40.57806879936999
              ],
              [
                -74.20616863938997,
                40.57797279674074
              ],
              [
                -74.20618117210455,
                40.5779035275872
              ],
              [
                -74.20619853327649,
                40.57785004298181
              ],
              [
                -74.20620636100908,
                40.57780507980826
              ],
              [
                -74.20623005707404,
                40.57773943572741
              ],
              [
                -74.20626642888944,
                40.57765068727641
              ],
              [
                -74.20629493544428,
                40.577594752789295
              ],
              [
                -74.20632344195356,
                40.57753881896483
              ],
              [
                -74.20635176963042,
                40.57752778113747
              ],
              [
                -74.20639507149829,
                40.57751682295124
              ],
              [
                -74.20641889868126,
                40.577493697864526
              ],
              [
                -74.20644429360412,
                40.57746206663755
              ],
              [
                -74.20647287503958,
                40.57743042917538
              ],
              [
                -74.20650468364701,
                40.57741215077526
              ],
              [
                -74.20654610443377,
                40.577410860381455
              ],
              [
                -74.20659709323164,
                40.57741198463174
              ],
              [
                -74.20663528237779,
                40.57739612172754
              ],
              [
                -74.20666067340031,
                40.57736327502946
              ],
              [
                -74.20667013969313,
                40.577332888336535
              ],
              [
                -74.20667480992525,
                40.57729764804889
              ],
              [
                -74.20668904793008,
                40.5772648230829
              ],
              [
                -74.20671602826916,
                40.57723075808272
              ],
              [
                -74.20675099027359,
                40.577201539258965
              ],
              [
                -74.20677966547655,
                40.577200272220054
              ],
              [
                -74.20680827961803,
                40.57717956879256
              ],
              [
                -74.20681616638838,
                40.57715404262922
              ],
              [
                -74.20681606482218,
                40.577121241785214
              ],
              [
                -74.20679849745785,
                40.577107910046166
              ],
              [
                -74.20677775928132,
                40.57709944338576
              ],
              [
                -74.20674745004618,
                40.57708735050468
              ],
              [
                -74.20672986390355,
                40.57706794448752
              ],
              [
                -74.20670587775237,
                40.57704004549181
              ],
              [
                -74.20669306156194,
                40.57701698627201
              ],
              [
                -74.20669297509777,
                40.57698904594726
              ],
              [
                -74.20666586305758,
                40.57698059023145
              ],
              [
                -74.20663554593676,
                40.57696606647527
              ],
              [
                -74.20659246376154,
                40.5769454920353
              ],
              [
                -74.20654779575953,
                40.576927350117614
              ],
              [
                -74.20653125429679,
                40.576901637368906
              ],
              [
                -74.20652532907332,
                40.576875151816175
              ],
              [
                -74.2065268053306,
                40.5768374887604
              ],
              [
                -74.20653617393428,
                40.576775514812084
              ],
              [
                -74.2065550934208,
                40.57671109417131
              ],
              [
                -74.20658040532199,
                40.57665273733929
              ],
              [
                -74.20661471962362,
                40.57661046209855
              ],
              [
                -74.2066470961085,
                40.57657972560937
              ],
              [
                -74.20670274735936,
                40.5765431800168
              ],
              [
                -74.20673936541259,
                40.5765346106395
              ],
              [
                -74.20679189402031,
                40.57651872237786
              ],
              [
                -74.20682535188581,
                40.576518662147976
              ],
              [
                -74.20685407570674,
                40.57653318872325
              ],
              [
                -74.20688441149332,
                40.576553786690425
              ],
              [
                -74.20690513533927,
                40.57655739381555
              ],
              [
                -74.20692739963445,
                40.57654399035161
              ],
              [
                -74.20693688761224,
                40.57652089215409
              ],
              [
                -74.20693678598774,
                40.57648809130824
              ],
              [
                -74.20693992288872,
                40.57647229193672
              ],
              [
                -74.20698130610009,
                40.57645885402145
              ],
              [
                -74.20701468863136,
                40.57643449733458
              ],
              [
                -74.20704010235717,
                40.57640894023965
              ],
              [
                -74.20705113024823,
                40.57636883127881
              ],
              [
                -74.20707333773304,
                40.57633720397753
              ],
              [
                -74.20708917179493,
                40.576305590338734
              ],
              [
                -74.20709545812727,
                40.576277636848985
              ],
              [
                -74.20705559440268,
                40.5762667755294
              ],
              [
                -74.20702213223713,
                40.57626562040431
              ],
              [
                -74.206971117961,
                40.576255994075986
              ],
              [
                -74.20692807000994,
                40.57624635220359
              ],
              [
                -74.20688655587759,
                40.57621727105958
              ],
              [
                -74.2068737187741,
                40.5761881382641
              ],
              [
                -74.20684971914962,
                40.576155380449066
              ],
              [
                -74.20684637155793,
                40.576103148282904
              ],
              [
                -74.20683824659714,
                40.57605213964548
              ],
              [
                -74.20682054032525,
                40.57599385852469
              ],
              [
                -74.20681884607389,
                40.5759610605443
              ],
              [
                -74.20681205626771,
                40.5759220201882
              ],
              [
                -74.20683148750713,
                40.57585423004931
              ],
              [
                -74.20685311460248,
                40.575766961597125
              ],
              [
                -74.20687395306429,
                40.575682874614486
              ],
              [
                -74.20692512074686,
                40.57561209703668
              ],
              [
                -74.20697412386814,
                40.57552512720597
              ],
              [
                -74.20710601770281,
                40.57535582393275
              ],
              [
                -74.2071736424719,
                40.575315784795286
              ],
              [
                -74.20723205025898,
                40.57528280487044
              ],
              [
                -74.20728431413171,
                40.575254532872584
              ],
              [
                -74.20728723368893,
                40.57520286855747
              ],
              [
                -74.20727167599361,
                40.575151237748514
              ],
              [
                -74.20722841802986,
                40.57510435310896
              ],
              [
                -74.20720049854935,
                40.57503865531539
              ],
              [
                -74.20715404454762,
                40.574954207139406
              ],
              [
                -74.20712612520137,
                40.57488850882439
              ],
              [
                -74.20709287504637,
                40.57479947162327
              ],
              [
                -74.20707024366172,
                40.574743025322356
              ],
              [
                -74.20707313358393,
                40.574681968326786
              ],
              [
                -74.2070913697977,
                40.57460444780117
              ],
              [
                -74.20711896133153,
                40.57456447917681
              ],
              [
                -74.20715669106241,
                40.574541953079226
              ],
              [
                -74.20719267147533,
                40.57450094653864
              ],
              [
                -74.20722650206048,
                40.57448679641168
              ],
              [
                -74.20720483655512,
                40.57445161465258
              ],
              [
                -74.2071814237486,
                40.574442147205986
              ],
              [
                -74.20715858686431,
                40.57443291258794
              ],
              [
                -74.20711849533382,
                40.57441419889951
              ],
              [
                -74.20708141086799,
                40.57437199994334
              ],
              [
                -74.20708127254849,
                40.574327384941
              ],
              [
                -74.20707800410406,
                40.57426634021656
              ],
              [
                -74.20712711526238,
                40.574214592628
              ],
              [
                -74.20719165903247,
                40.574174557353345
              ],
              [
                -74.2072315961311,
                40.57414395967348
              ],
              [
                -74.20726298207298,
                40.57411302777272
              ],
              [
                -74.20727340079814,
                40.57407098541119
              ],
              [
                -74.20783559015278,
                40.572610001451686
              ],
              [
                -74.20816851102184,
                40.57071942341427
              ],
              [
                -74.20814699968129,
                40.570693225342794
              ],
              [
                -74.20812547988973,
                40.570683135968906
              ],
              [
                -74.20809988826458,
                40.57067198345028
              ],
              [
                -74.20807383412323,
                40.57066214938774
              ],
              [
                -74.20804694132815,
                40.570649324918776
              ],
              [
                -74.20802776229282,
                40.57063997294267
              ],
              [
                -74.20801360133788,
                40.57062336385578
              ],
              [
                -74.20800746847948,
                40.57060718116962
              ],
              [
                -74.20800546023749,
                40.5705864345317
              ],
              [
                -74.20800502986653,
                40.570564284652754
              ],
              [
                -74.20800811544828,
                40.57054931930025
              ],
              [
                -74.20801705381407,
                40.57051795740076
              ],
              [
                -74.20803424151269,
                40.57048957231308
              ],
              [
                -74.20805897478249,
                40.570456452558986
              ],
              [
                -74.20807647994934,
                40.57043717968815
              ],
              [
                -74.20811163891236,
                40.57041172647923
              ],
              [
                -74.20817926338377,
                40.57036225276879
              ],
              [
                -74.20821981084153,
                40.57032169329085
              ],
              [
                -74.20825567636716,
                40.57029184662073
              ],
              [
                -74.20829081802127,
                40.57027202072293
              ],
              [
                -74.20833339151348,
                40.570246581978424
              ],
              [
                -74.20837522415975,
                40.57022550721252
              ],
              [
                -74.20840671104904,
                40.57021355230793
              ],
              [
                -74.20843502844853,
                40.570197641843365
              ],
              [
                -74.20845933057952,
                40.570178251031294
              ],
              [
                -74.20847889159194,
                40.57015595903444
              ],
              [
                -74.2084931272451,
                40.5701314316672
              ],
              [
                -74.20850161235694,
                40.57010540150905
              ],
              [
                -74.20850409350268,
                40.57007864602345
              ],
              [
                -74.20855662348757,
                40.56995610343071
              ],
              [
                -74.20858691045255,
                40.56990395244669
              ],
              [
                -74.20861881832602,
                40.56986258560527
              ],
              [
                -74.20865187713905,
                40.56983332021022
              ],
              [
                -74.20868227933643,
                40.569817674466485
              ],
              [
                -74.20870130896725,
                40.569813989246406
              ],
              [
                -74.20872712118614,
                40.56981558983796
              ],
              [
                -74.20875830301402,
                40.56980773732969
              ],
              [
                -74.20877818748549,
                40.569789229156676
              ],
              [
                -74.20880496367919,
                40.569765520213934
              ],
              [
                -74.20882741271222,
                40.56975035495678
              ],
              [
                -74.20884705448222,
                40.569723585299165
              ],
              [
                -74.20885307543493,
                40.56970323469199
              ],
              [
                -74.2088830288272,
                40.56967112083321
              ],
              [
                -74.20892636328658,
                40.569624298450016
              ],
              [
                -74.20892489464278,
                40.56959177448437
              ],
              [
                -74.20891141734027,
                40.569564269111424
              ],
              [
                -74.20889013891401,
                40.5695389720649
              ],
              [
                -74.20887997055323,
                40.56947561392257
              ],
              [
                -74.20890031354503,
                40.569397514440524
              ],
              [
                -74.20889579663681,
                40.56936861919062
              ],
              [
                -74.2088908511528,
                40.569341070321585
              ],
              [
                -74.20889159336394,
                40.56930190043626
              ],
              [
                -74.20889153469763,
                40.569271899756515
              ],
              [
                -74.20889248059123,
                40.56922880561069
              ],
              [
                -74.20888640180824,
                40.5691956587736
              ],
              [
                -74.20887190552189,
                40.56917562055676
              ],
              [
                -74.20885305891247,
                40.56913407033701
              ],
              [
                -74.20884538245129,
                40.56910798109465
              ],
              [
                -74.20884309550478,
                40.56909028067201
              ],
              [
                -74.20884318638387,
                40.56906200946809
              ],
              [
                -74.20884299766729,
                40.56902462547019
              ],
              [
                -74.20884572715461,
                40.56898838843852
              ],
              [
                -74.20885566763602,
                40.56894390502939
              ],
              [
                -74.20886661112198,
                40.56890979392917
              ],
              [
                -74.20888426318028,
                40.56888009091043
              ],
              [
                -74.20891142961004,
                40.568842904774264
              ],
              [
                -74.20894236847845,
                40.56880332262293
              ],
              [
                -74.20896988848015,
                40.56876393934234
              ],
              [
                -74.2089929848352,
                40.568725689570655
              ],
              [
                -74.20901810631887,
                40.568679036484866
              ],
              [
                -74.2090342903802,
                40.56864030506823
              ],
              [
                -74.20904489974666,
                40.56860273723909
              ],
              [
                -74.20905816585409,
                40.568575018526865
              ],
              [
                -74.20909031328969,
                40.56854191135124
              ],
              [
                -74.20912500684162,
                40.568517776129305
              ],
              [
                -74.20916235741977,
                40.568491303034364
              ],
              [
                -74.20918588410103,
                40.56847520360478
              ],
              [
                -74.20921948028858,
                40.56844547099358
              ],
              [
                -74.20924168547243,
                40.56840985887977
              ],
              [
                -74.20925794317955,
                40.568371977176334
              ],
              [
                -74.20927042628895,
                40.568324305018955
              ],
              [
                -74.20926525883027,
                40.56828286664634
              ],
              [
                -74.20926886123786,
                40.56824962093699
              ],
              [
                -74.20930338344986,
                40.56815895122124
              ],
              [
                -74.20930222761174,
                40.56812338165856
              ],
              [
                -74.20930302509844,
                40.56809071635698
              ],
              [
                -74.20931088354241,
                40.56804810264815
              ],
              [
                -74.20932175268324,
                40.5680253007569
              ],
              [
                -74.2093478026972,
                40.56798817200064
              ],
              [
                -74.20938515363255,
                40.567961725132825
              ],
              [
                -74.2094237327763,
                40.56794823379797
              ],
              [
                -74.2094476662524,
                40.56794774989159
              ],
              [
                -74.20948488887286,
                40.5679495484676
              ],
              [
                -74.20951393515091,
                40.56794922158046
              ],
              [
                -74.20952755116922,
                40.56793531838229
              ],
              [
                -74.20953467232897,
                40.56792804865804
              ],
              [
                -74.20953849782619,
                40.567885221484595
              ],
              [
                -74.20955122470804,
                40.56784581076467
              ],
              [
                -74.20956174056077,
                40.5678252054795
              ],
              [
                -74.20957207113801,
                40.56779071178188
              ],
              [
                -74.2095508790996,
                40.567701462251776
              ],
              [
                -74.20954069373897,
                40.56766725241842
              ],
              [
                -74.20952991213728,
                40.56763828686199
              ],
              [
                -74.20952390733656,
                40.56760601878171
              ],
              [
                -74.20952461322831,
                40.56757815706748
              ],
              [
                -74.20952892037967,
                40.56755182704577
              ],
              [
                -74.20954125857394,
                40.56751458487445
              ],
              [
                -74.20955643852933,
                40.567488948024966
              ],
              [
                -74.20957929223879,
                40.56744243665677
              ],
              [
                -74.20959638520986,
                40.5673953867992
              ],
              [
                -74.2096095952876,
                40.567361162927064
              ],
              [
                -74.20962824988973,
                40.56731836464221
              ],
              [
                -74.20964655233293,
                40.56728992198323
              ],
              [
                -74.20967795471621,
                40.56724902232857
              ],
              [
                -74.20969971286483,
                40.567209072409554
              ],
              [
                -74.20971519072341,
                40.56717473398204
              ],
              [
                -74.20973856379582,
                40.56713340987283
              ],
              [
                -74.2097613438044,
                40.567098207219914
              ],
              [
                -74.20977691342875,
                40.56707037330835
              ],
              [
                -74.20979737148639,
                40.56702877942583
              ],
              [
                -74.20985586162145,
                40.566915010423735
              ],
              [
                -74.20988348982404,
                40.56685300947939
              ],
              [
                -74.20992198936453,
                40.566791702339145
              ],
              [
                -74.2099764118816,
                40.566700364112386
              ],
              [
                -74.2100369420801,
                40.56656040531862
              ],
              [
                -74.21011458775692,
                40.56641470625927
              ],
              [
                -74.21020935035405,
                40.566275425732
              ],
              [
                -74.21023567751136,
                40.56623522040577
              ],
              [
                -74.21025750938671,
                40.56620745889707
              ],
              [
                -74.21028473121008,
                40.56617680331779
              ],
              [
                -74.21030851498715,
                40.566139788216304
              ],
              [
                -74.21033396826797,
                40.56609662169625
              ],
              [
                -74.21036079656349,
                40.566032480055604
              ],
              [
                -74.21037558522801,
                40.56599685214434
              ],
              [
                -74.21039639550105,
                40.56595306168717
              ],
              [
                -74.21045176120732,
                40.56584209735151
              ],
              [
                -74.21047559606595,
                40.56576467669574
              ],
              [
                -74.21056232688464,
                40.56561278551076
              ],
              [
                -74.21058462277247,
                40.56557239533418
              ],
              [
                -74.2106234374394,
                40.565520200174554
              ],
              [
                -74.21063993391671,
                40.56547839443662
              ],
              [
                -74.21067733198504,
                40.56538802089522
              ],
              [
                -74.210697435188,
                40.565348624485516
              ],
              [
                -74.2107085089515,
                40.565321896011504
              ],
              [
                -74.2107179266907,
                40.56529701960465
              ],
              [
                -74.21072667690999,
                40.565273911362134
              ],
              [
                -74.21074330542251,
                40.56522817912912
              ],
              [
                -74.21075316824248,
                40.565182844124315
              ],
              [
                -74.21075976100573,
                40.56513940904712
              ],
              [
                -74.2107797307137,
                40.56506916067951
              ],
              [
                -74.21078819559088,
                40.5649686563648
              ],
              [
                -74.21081321472644,
                40.564856374954054
              ],
              [
                -74.21083712189804,
                40.564756311207475
              ],
              [
                -74.21084155693562,
                40.564732371549475
              ],
              [
                -74.21084684089459,
                40.56470853302901
              ],
              [
                -74.21085297008612,
                40.56468481039527
              ],
              [
                -74.21085993776386,
                40.56466122409924
              ],
              [
                -74.21086774089825,
                40.56463778888891
              ],
              [
                -74.21087637317956,
                40.56461452437678
              ],
              [
                -74.21089245905263,
                40.56456406156065
              ],
              [
                -74.21090755600585,
                40.56451341510195
              ],
              [
                -74.21092165530236,
                40.56446260026247
              ],
              [
                -74.21093475874352,
                40.56441163027388
              ],
              [
                -74.21096277401229,
                40.56429045015019
              ],
              [
                -74.21098362503817,
                40.564242534072946
              ],
              [
                -74.21100517978078,
                40.56419479897163
              ],
              [
                -74.2110274327638,
                40.56414725021701
              ],
              [
                -74.21105038247171,
                40.5640998951828
              ],
              [
                -74.21107402518695,
                40.56405273973875
              ],
              [
                -74.21109836004746,
                40.56400578941492
              ],
              [
                -74.21112338114351,
                40.56395905142523
              ],
              [
                -74.21114908650821,
                40.563912529458506
              ],
              [
                -74.21117547287594,
                40.5638662330698
              ],
              [
                -74.21118769931408,
                40.56383875641406
              ],
              [
                -74.21120066709557,
                40.56381147540934
              ],
              [
                -74.21121436747669,
                40.563784403976435
              ],
              [
                -74.211228798512,
                40.56375755233795
              ],
              [
                -74.2112439507954,
                40.5637309332432
              ],
              [
                -74.2112598193029,
                40.56370455608288
              ],
              [
                -74.21127639770533,
                40.56367843393571
              ],
              [
                -74.21129367571348,
                40.56365257837989
              ],
              [
                -74.21130839824029,
                40.56363046428353
              ],
              [
                -74.21132233714144,
                40.56360805744398
              ],
              [
                -74.2113354847796,
                40.56358537379079
              ],
              [
                -74.2113478306638,
                40.563562430096795
              ],
              [
                -74.21135936781704,
                40.56353924262531
              ],
              [
                -74.21137008619401,
                40.5635158299911
              ],
              [
                -74.21137997858774,
                40.56349220527486
              ],
              [
                -74.21137932828424,
                40.56345684336147
              ],
              [
                -74.2113779423426,
                40.56342149385877
              ],
              [
                -74.21137582167343,
                40.56338616648183
              ],
              [
                -74.21137296608772,
                40.56335087061269
              ],
              [
                -74.2113693778171,
                40.56331561680149
              ],
              [
                -74.21136685536173,
                40.56329507269257
              ],
              [
                -74.2113650562325,
                40.56328041426349
              ],
              [
                -74.21136074998377,
                40.563264429923144
              ],
              [
                -74.21135717556896,
                40.563248341372415
              ],
              [
                -74.21135433809455,
                40.563232165857485
              ],
              [
                -74.21135224180138,
                40.563215924814585
              ],
              [
                -74.21135088807269,
                40.56319963901492
              ],
              [
                -74.21135028004271,
                40.56318332721619
              ],
              [
                -74.21135041689111,
                40.56316700801578
              ],
              [
                -74.21135129758787,
                40.563150703194374
              ],
              [
                -74.21135292461402,
                40.56313443335366
              ],
              [
                -74.21135529340576,
                40.563118214249805
              ],
              [
                -74.21135329846459,
                40.56298819606043
              ],
              [
                -74.21135097251253,
                40.56296650367217
              ],
              [
                -74.21134784781148,
                40.56294487038395
              ],
              [
                -74.21134392485533,
                40.56292331328279
              ],
              [
                -74.21133920984406,
                40.56290184760263
              ],
              [
                -74.21133370130121,
                40.56288049261196
              ],
              [
                -74.2113274098245,
                40.56285926487675
              ],
              [
                -74.21132185719213,
                40.562838275014876
              ],
              [
                -74.21131559145635,
                40.5628174045735
              ],
              [
                -74.21130861550208,
                40.56279666276123
              ],
              [
                -74.21130093487787,
                40.562776067158566
              ],
              [
                -74.21129255643,
                40.56275562931218
              ],
              [
                -74.21128348460323,
                40.5627353651293
              ],
              [
                -74.211273723836,
                40.56271528884148
              ],
              [
                -74.21122223259422,
                40.56254783893503
              ],
              [
                -74.21121107937378,
                40.56252629612999
              ],
              [
                -74.21120066840547,
                40.56250454070353
              ],
              [
                -74.21119100455725,
                40.56248258403874
              ],
              [
                -74.2111820940348,
                40.56246044371508
              ],
              [
                -74.21117394588555,
                40.56243813295119
              ],
              [
                -74.21116656016068,
                40.562415668165016
              ],
              [
                -74.21115994766821,
                40.562393063576614
              ],
              [
                -74.21110906466804,
                40.562247545217204
              ],
              [
                -74.21110469124471,
                40.56221860420325
              ],
              [
                -74.21110105594312,
                40.56218960537483
              ],
              [
                -74.21109815988437,
                40.56216055626874
              ],
              [
                -74.21109600355396,
                40.56213147179443
              ],
              [
                -74.21109459070571,
                40.56210235847846
              ],
              [
                -74.2110939191909,
                40.56207323173792
              ],
              [
                -74.21109399057062,
                40.56204409927601
              ],
              [
                -74.21109339203943,
                40.56202096813978
              ],
              [
                -74.21109364843628,
                40.56199783275172
              ],
              [
                -74.21109475872257,
                40.561974712212326
              ],
              [
                -74.2110967214154,
                40.56195162411486
              ],
              [
                -74.21109953372239,
                40.561928588903015
              ],
              [
                -74.21110319547003,
                40.561905621319816
              ],
              [
                -74.21110770474597,
                40.56188274214254
              ],
              [
                -74.21111305501641,
                40.56185996947611
              ],
              [
                -74.21111924501375,
                40.561837319405896
              ],
              [
                -74.21112626601061,
                40.56181481104592
              ],
              [
                -74.21113411608856,
                40.561792463163336
              ],
              [
                -74.21114232317109,
                40.56175963711668
              ],
              [
                -74.2111513036203,
                40.56172692876156
              ],
              [
                -74.21116105263172,
                40.56169434748838
              ],
              [
                -74.21117156563142,
                40.561661905870096
              ],
              [
                -74.21117691796499,
                40.56164730347757
              ],
              [
                -74.21118153043909,
                40.56163255418076
              ],
              [
                -74.21118539060335,
                40.56161768011681
              ],
              [
                -74.2111884967697,
                40.56160270306774
              ],
              [
                -74.21119084176641,
                40.56158764733881
              ],
              [
                -74.21119242214161,
                40.56157253253728
              ],
              [
                -74.21119323731205,
                40.56155738245364
              ],
              [
                -74.21119328295505,
                40.561542219377195
              ],
              [
                -74.21122141090954,
                40.561495005216756
              ],
              [
                -74.21125007208866,
                40.56144797384898
              ],
              [
                -74.21127926148336,
                40.56140113918789
              ],
              [
                -74.21130897252331,
                40.56135450777871
              ],
              [
                -74.21131566584407,
                40.561339486264416
              ],
              [
                -74.21132157194674,
                40.561324275716174
              ],
              [
                -74.21132668188982,
                40.56130889642187
              ],
              [
                -74.21133098696738,
                40.56129337369453
              ],
              [
                -74.21133448308989,
                40.56127773401159
              ],
              [
                -74.21133716220588,
                40.56126200117731
              ],
              [
                -74.21133901823953,
                40.56124619865709
              ],
              [
                -74.21134005281321,
                40.561230353085314
              ],
              [
                -74.21134026248816,
                40.561214488257534
              ],
              [
                -74.21133964799989,
                40.56119862812938
              ],
              [
                -74.21133479841414,
                40.56117964850718
              ],
              [
                -74.21133096698273,
                40.56116053348975
              ],
              [
                -74.21132815797952,
                40.56114131506759
              ],
              [
                -74.21132637809602,
                40.56112202556187
              ],
              [
                -74.21132563160215,
                40.56110269579036
              ],
              [
                -74.21132596868435,
                40.5610742616054
              ],
              [
                -74.21132550948525,
                40.56104582670768
              ],
              [
                -74.21132425778843,
                40.56101740650292
              ],
              [
                -74.2113222123134,
                40.56098901238533
              ],
              [
                -74.21132186593334,
                40.560975549121075
              ],
              [
                -74.21132062144908,
                40.56096211665934
              ],
              [
                -74.21131848314432,
                40.56094874950353
              ],
              [
                -74.2113154561791,
                40.56093548165294
              ],
              [
                -74.21131155011123,
                40.56092234843847
              ],
              [
                -74.2113067742804,
                40.56090938569458
              ],
              [
                -74.21130557523796,
                40.56089712351528
              ],
              [
                -74.21130350610588,
                40.56088492877594
              ],
              [
                -74.21130057358947,
                40.560872837818394
              ],
              [
                -74.21129678768462,
                40.56086088563804
              ],
              [
                -74.21129215619294,
                40.56084910790434
              ],
              [
                -74.21128669305928,
                40.560837538097424
              ],
              [
                -74.21128041882379,
                40.56082621136097
              ],
              [
                -74.21127474743372,
                40.560819150007596
              ],
              [
                -74.21126841087215,
                40.5608124249379
              ],
              [
                -74.21126144484478,
                40.56080607411551
              ],
              [
                -74.21125388482164,
                40.56080013064597
              ],
              [
                -74.21124577263936,
                40.5607946262831
              ],
              [
                -74.21123715233051,
                40.5607895927764
              ],
              [
                -74.21122807010568,
                40.56078505584053
              ],
              [
                -74.21121857348905,
                40.5607810400149
              ],
              [
                -74.2112087172516,
                40.56077756898799
              ],
              [
                -74.21120185505099,
                40.56077292912476
              ],
              [
                -74.21119558623057,
                40.56076782545155
              ],
              [
                -74.21118996452417,
                40.56076230109241
              ],
              [
                -74.21118503686579,
                40.560756402199516
              ],
              [
                -74.21118084823422,
                40.56075018196485
              ],
              [
                -74.2111774339364,
                40.56074369125278
              ],
              [
                -74.21117482073525,
                40.56073698831465
              ],
              [
                -74.21117303560405,
                40.56073012838597
              ],
              [
                -74.21117209081682,
                40.56072317259265
              ],
              [
                -74.21117199314888,
                40.56071617955785
              ],
              [
                -74.21116870824625,
                40.560684604809055
              ],
              [
                -74.21116638706465,
                40.560652980039386
              ],
              [
                -74.21116503010131,
                40.56062132384411
              ],
              [
                -74.21116463850305,
                40.5605896521363
              ],
              [
                -74.21116521298023,
                40.56055798150037
              ],
              [
                -74.21116675116954,
                40.56052632919614
              ],
              [
                -74.2111667139708,
                40.56051296202733
              ],
              [
                -74.2111674627594,
                40.56049960715096
              ],
              [
                -74.21116899542127,
                40.560486290872966
              ],
              [
                -74.21117131028556,
                40.56047304067166
              ],
              [
                -74.21117440194196,
                40.5604598823563
              ],
              [
                -74.21117826608335,
                40.56044684340987
              ],
              [
                -74.21118289114915,
                40.560433949652975
              ],
              [
                -74.21118827150941,
                40.56042122689581
              ],
              [
                -74.21119439340815,
                40.56040870146574
              ],
              [
                -74.21120124704034,
                40.56039639867767
              ],
              [
                -74.21120881578622,
                40.56038434218381
              ],
              [
                -74.2112170869799,
                40.5603725556291
              ],
              [
                -74.21122604291112,
                40.56036106518071
              ],
              [
                -74.21126241146871,
                40.560326250677036
              ],
              [
                -74.21129944575641,
                40.56029184538611
              ],
              [
                -74.21133713481073,
                40.56025785569348
              ],
              [
                -74.21137547009187,
                40.56022429015837
              ],
              [
                -74.2113895825626,
                40.560210318800806
              ],
              [
                -74.21140309140515,
                40.5601960017649
              ],
              [
                -74.21141597822462,
                40.560181356843
              ],
              [
                -74.21142823472546,
                40.560166400301036
              ],
              [
                -74.21143984403734,
                40.56015114574012
              ],
              [
                -74.21145079479376,
                40.56013561077206
              ],
              [
                -74.21146107650561,
                40.5601198125045
              ],
              [
                -74.2114706758224,
                40.5601037662074
              ],
              [
                -74.2114795848924,
                40.56008748948621
              ],
              [
                -74.21149874511931,
                40.56005673317995
              ],
              [
                -74.21151863853386,
                40.560026248259284
              ],
              [
                -74.21153925748021,
                40.55999604529247
              ],
              [
                -74.21156059716209,
                40.559966135847496
              ],
              [
                -74.21158264815573,
                40.55993652697761
              ],
              [
                -74.21159321724679,
                40.55992284926887
              ],
              [
                -74.21160454903074,
                40.55990953084659
              ],
              [
                -74.2116166233805,
                40.55989659754715
              ],
              [
                -74.21162941665453,
                40.559884075213745
              ],
              [
                -74.21164290739024,
                40.55987198415675
              ],
              [
                -74.21165706886426,
                40.559860348046946
              ],
              [
                -74.21167187610496,
                40.559849188876534
              ],
              [
                -74.21168836796686,
                40.55983631544542
              ],
              [
                -74.21170427684127,
                40.55982302426096
              ],
              [
                -74.21171958563137,
                40.55980932691425
              ],
              [
                -74.2117342739697,
                40.55979524254178
              ],
              [
                -74.21174832586338,
                40.559780784408154
              ],
              [
                -74.21176172643322,
                40.559765970634096
              ],
              [
                -74.21177445749836,
                40.55975081716909
              ],
              [
                -74.21178650572082,
                40.559735343303515
              ],
              [
                -74.211797858413,
                40.55971956547923
              ],
              [
                -74.21180964546389,
                40.55970707513636
              ],
              [
                -74.21182081248342,
                40.55969426042456
              ],
              [
                -74.21183134501631,
                40.55968113443796
              ],
              [
                -74.21184122621727,
                40.55966771881914
              ],
              [
                -74.21185044273766,
                40.55965402934088
              ],
              [
                -74.21185898256296,
                40.559640086966695
              ],
              [
                -74.21186683213901,
                40.55962591199296
              ],
              [
                -74.21187398427489,
                40.55961152252629
              ],
              [
                -74.21188042321681,
                40.559596937861755
              ],
              [
                -74.21188419516436,
                40.5595775365965
              ],
              [
                -74.21188881181226,
                40.5595582413272
              ],
              [
                -74.2118942710297,
                40.55953907299893
              ],
              [
                -74.21190056125081,
                40.55952005575717
              ],
              [
                -74.21190768055533,
                40.559501207698666
              ],
              [
                -74.2119156180345,
                40.55948255246529
              ],
              [
                -74.21192721864445,
                40.559456663887126
              ],
              [
                -74.21193801509246,
                40.55943057206978
              ],
              [
                -74.21194799863947,
                40.559404291772175
              ],
              [
                -74.21195716802121,
                40.55937783958228
              ],
              [
                -74.21196551296323,
                40.55935123076457
              ],
              [
                -74.21196838009425,
                40.55934380405043
              ],
              [
                -74.21197053048114,
                40.559336238603834
              ],
              [
                -74.21197195436712,
                40.559328574650024
              ],
              [
                -74.21197264462788,
                40.5593208512366
              ],
              [
                -74.21197259700165,
                40.559313109751564
              ],
              [
                -74.21197181096326,
                40.559305392245754
              ],
              [
                -74.21197029168968,
                40.55929773807949
              ],
              [
                -74.21196804875582,
                40.559290187944946
              ],
              [
                -74.21196509130009,
                40.559282783539885
              ],
              [
                -74.21196143657221,
                40.55927556152188
              ],
              [
                -74.21195710358683,
                40.559268560890274
              ],
              [
                -74.21195211289572,
                40.55926182064138
              ],
              [
                -74.21194649535678,
                40.55925537405696
              ],
              [
                -74.21194027809761,
                40.5592492557656
              ],
              [
                -74.211933493293,
                40.55924349871105
              ],
              [
                -74.21192617926559,
                40.559238135156086
              ],
              [
                -74.21191336724817,
                40.559231326409744
              ],
              [
                -74.21190010067754,
                40.55922504488072
              ],
              [
                -74.21188641321727,
                40.55921930826475
              ],
              [
                -74.21187234972683,
                40.559214132227126
              ],
              [
                -74.21185794473554,
                40.55920953044113
              ],
              [
                -74.21184324003018,
                40.5592055194154
              ],
              [
                -74.21182827737273,
                40.55920210778403
              ],
              [
                -74.2118131007146,
                40.55919930199986
              ],
              [
                -74.21179774898178,
                40.559197116901
              ],
              [
                -74.21179081872265,
                40.559192726985756
              ],
              [
                -74.21178440022126,
                40.559187907416366
              ],
              [
                -74.21177853641149,
                40.55918268994475
              ],
              [
                -74.21177327156882,
                40.55917711385879
              ],
              [
                -74.2117686427182,
                40.559171217789896
              ],
              [
                -74.21176468316598,
                40.55916504540211
              ],
              [
                -74.2117614202853,
                40.55915863953271
              ],
              [
                -74.2117588808004,
                40.55915204620341
              ],
              [
                -74.21175707738473,
                40.55914531380704
              ],
              [
                -74.21175602577777,
                40.55913848771531
              ],
              [
                -74.21175573559012,
                40.559131620180054
              ],
              [
                -74.21175620455124,
                40.559124757276265
              ],
              [
                -74.21177840042363,
                40.55906509015543
              ],
              [
                -74.21179998319977,
                40.5590052859482
              ],
              [
                -74.21182095510497,
                40.55894535319475
              ],
              [
                -74.21184131197964,
                40.55888529575616
              ],
              [
                -74.21186105230399,
                40.558825118829205
              ],
              [
                -74.2118801739002,
                40.558764827779044
              ],
              [
                -74.21189867612937,
                40.55870442847091
              ],
              [
                -74.21191655681484,
                40.55864392660495
              ],
              [
                -74.21191912111586,
                40.55862115802887
              ],
              [
                -74.21192247087268,
                40.5585984494871
              ],
              [
                -74.21192660612942,
                40.55857581521948
              ],
              [
                -74.21193152100926,
                40.55855327232471
              ],
              [
                -74.21193721380439,
                40.55853083672145
              ],
              [
                -74.21194367928562,
                40.55850852198892
              ],
              [
                -74.21195091597077,
                40.55848634588817
              ],
              [
                -74.21195891467916,
                40.558464322675896
              ],
              [
                -74.21196767172765,
                40.558442468776825
              ],
              [
                -74.21197718232703,
                40.558420797937075
              ],
              [
                -74.21198743707825,
                40.558399324916394
              ],
              [
                -74.21199843164408,
                40.5583780674809
              ],
              [
                -74.21201015815413,
                40.55835703753956
              ],
              [
                -74.21202260611177,
                40.558336250021625
              ],
              [
                -74.21203576875757,
                40.55831572085473
              ],
              [
                -74.21204963603087,
                40.55829546379476
              ],
              [
                -74.21206419984549,
                40.558275491923744
              ],
              [
                -74.21207433957846,
                40.558256136019835
              ],
              [
                -74.21208523721299,
                40.55823701962404
              ],
              [
                -74.21209688644561,
                40.55821816385639
              ],
              [
                -74.21210927501949,
                40.55819958264444
              ],
              [
                -74.21212239135274,
                40.55818129494037
              ],
              [
                -74.21213622297513,
                40.558163316682226
              ],
              [
                -74.21215075917685,
                40.55814566481006
              ],
              [
                -74.2121659841867,
                40.55812835309018
              ],
              [
                -74.21218188598277,
                40.55811140030813
              ],
              [
                -74.21219844901638,
                40.55809482123492
              ],
              [
                -74.21221565883023,
                40.558078628461566
              ],
              [
                -74.21221762722816,
                40.55806724482816
              ],
              [
                -74.21222038623903,
                40.558055955896116
              ],
              [
                -74.21222392651748,
                40.5580447931786
              ],
              [
                -74.21222824092033,
                40.55803379002688
              ],
              [
                -74.21223331416364,
                40.55802297545222
              ],
              [
                -74.21223369481805,
                40.55802228251368
              ],
              [
                -74.21223913140844,
                40.55801238013971
              ],
              [
                -74.21224568001387,
                40.558002035440886
              ],
              [
                -74.2122529392056,
                40.55799197054422
              ],
              [
                -74.21226088820295,
                40.55798221279559
              ],
              [
                -74.2122695040372,
                40.55797279205742
              ],
              [
                -74.2122787628412,
                40.55796373199563
              ],
              [
                -74.21228863856409,
                40.55795506030065
              ],
              [
                -74.21229910426533,
                40.557946801146514
              ],
              [
                -74.21231012772952,
                40.557938977711714
              ],
              [
                -74.21232168091373,
                40.5579316128322
              ],
              [
                -74.21235048482772,
                40.55791300576911
              ],
              [
                -74.21235120957739,
                40.557912558128436
              ],
              [
                -74.21237984165433,
                40.55789490210993
              ],
              [
                -74.21240973253926,
                40.55787731294612
              ],
              [
                -74.21244014147611,
                40.55786024718605
              ],
              [
                -74.21245156909004,
                40.557853466033635
              ],
              [
                -74.21245767335338,
                40.55784984144866
              ],
              [
                -74.21247473304155,
                40.55783898704916
              ],
              [
                -74.21249129782066,
                40.557827696871264
              ],
              [
                -74.2125073528959,
                40.557815983153965
              ],
              [
                -74.21252287597689,
                40.55780386020303
              ],
              [
                -74.21253785107135,
                40.55779134390752
              ],
              [
                -74.21255225997128,
                40.557778444229236
              ],
              [
                -74.21256608514139,
                40.5577651814006
              ],
              [
                -74.21258187815579,
                40.557751865204494
              ],
              [
                -74.21259701696337,
                40.55773811499098
              ],
              [
                -74.21261148120676,
                40.55772394723897
              ],
              [
                -74.21262525131444,
                40.5577093831028
              ],
              [
                -74.21263830793275,
                40.55769444123361
              ],
              [
                -74.21265063396818,
                40.55767914311733
              ],
              [
                -74.21266221111956,
                40.55766350990953
              ],
              [
                -74.21267302682733,
                40.55764756000401
              ],
              [
                -74.21267718710236,
                40.55763365984381
              ],
              [
                -74.21268047879693,
                40.55761962279826
              ],
              [
                -74.21268289174363,
                40.55760548296424
              ],
              [
                -74.2126844214028,
                40.557591270088146
              ],
              [
                -74.21268506353324,
                40.55757701792894
              ],
              [
                -74.2126848169559,
                40.557562758235626
              ],
              [
                -74.21268368228385,
                40.55754852492697
              ],
              [
                -74.21268166310496,
                40.55753434816385
              ],
              [
                -74.21268237383482,
                40.55751402679703
              ],
              [
                -74.21268217382365,
                40.557493696831074
              ],
              [
                -74.21268106803171,
                40.557473385998875
              ],
              [
                -74.21267905301578,
                40.55745311519104
              ],
              [
                -74.2126761341738,
                40.55743291113687
              ],
              [
                -74.2126723150813,
                40.557412797144465
              ],
              [
                -74.21265008206916,
                40.55737010282704
              ],
              [
                -74.21264524623795,
                40.55736518903256
              ],
              [
                -74.21264100443089,
                40.55735996514138
              ],
              [
                -74.21263739090946,
                40.55735447406642
              ],
              [
                -74.21263443479033,
                40.55734875997724
              ],
              [
                -74.2126321588445,
                40.55734286604855
              ],
              [
                -74.212630581985,
                40.557336841221996
              ],
              [
                -74.21262971693675,
                40.55733073302433
              ],
              [
                -74.21262956902495,
                40.55732459099601
              ],
              [
                -74.2126301411416,
                40.557318461921156
              ],
              [
                -74.21263142895256,
                40.5573123971038
              ],
              [
                -74.21263342054272,
                40.55730644501078
              ],
              [
                -74.21263610135786,
                40.55730065110852
              ],
              [
                -74.21263944838284,
                40.5572950641257
              ],
              [
                -74.21264343799604,
                40.55728972727974
              ],
              [
                -74.21264803491319,
                40.557284683962834
              ],
              [
                -74.21265320401349,
                40.557279971973486
              ],
              [
                -74.21265890471648,
                40.557275633207304
              ],
              [
                -74.21266508978799,
                40.55727169903635
              ],
              [
                -74.21267170985122,
                40.557268202259685
              ],
              [
                -74.21268576576317,
                40.55726769817959
              ],
              [
                -74.21269974875094,
                40.55726645910472
              ],
              [
                -74.21271358840103,
                40.55726449195031
              ],
              [
                -74.21272721547317,
                40.557261805137344
              ],
              [
                -74.21274055956393,
                40.55725841284806
              ],
              [
                -74.2127443139994,
                40.55725332084801
              ],
              [
                -74.21274872592612,
                40.55724854514075
              ],
              [
                -74.21275374880909,
                40.55724413497832
              ],
              [
                -74.21275932997062,
                40.55724013410668
              ],
              [
                -74.21276541375505,
                40.55723658677329
              ],
              [
                -74.21277193658419,
                40.55723352470325
              ],
              [
                -74.21277883112765,
                40.55723098304876
              ],
              [
                -74.2127860267915,
                40.55722898693911
              ],
              [
                -74.21279345113571,
                40.55722755691052
              ],
              [
                -74.21280102614703,
                40.55722670790736
              ],
              [
                -74.21282631094371,
                40.55722820980644
              ],
              [
                -74.21285152687292,
                40.557230267782906
              ],
              [
                -74.21287665675652,
                40.557232880099
              ],
              [
                -74.2128930487575,
                40.55722713191881
              ],
              [
                -74.21290910193053,
                40.55722084708401
              ],
              [
                -74.21292478382288,
                40.55721403924271
              ],
              [
                -74.21294006307731,
                40.55720672020319
              ],
              [
                -74.21295491392854,
                40.557198904861586
              ],
              [
                -74.21296930618101,
                40.55719060536247
              ],
              [
                -74.21298321617033,
                40.557181839437604
              ],
              [
                -74.21299661597317,
                40.557172622319165
              ],
              [
                -74.21300947810742,
                40.55716297024143
              ],
              [
                -74.2130238729699,
                40.55715385688698
              ],
              [
                -74.2130377119574,
                40.55714425111
              ],
              [
                -74.21305096411395,
                40.55713417215337
              ],
              [
                -74.21306360191923,
                40.55712364318667
              ],
              [
                -74.21307559717893,
                40.55711268587348
              ],
              [
                -74.2130869251905,
                40.557101322797166
              ],
              [
                -74.21309756484123,
                40.55708957912589
              ],
              [
                -74.2131074872238,
                40.557077480950554
              ],
              [
                -74.21311550585351,
                40.5570714683029
              ],
              [
                -74.21312293480051,
                40.55706502984931
              ],
              [
                -74.21312973098384,
                40.55705819821812
              ],
              [
                -74.21313586314459,
                40.55705100994546
              ],
              [
                -74.2131412976021,
                40.55704350232929
              ],
              [
                -74.2131460060169,
                40.557035713990224
              ],
              [
                -74.213149962119,
                40.55702768614652
              ],
              [
                -74.21315314775063,
                40.55701946192402
              ],
              [
                -74.21315554408262,
                40.5570110821965
              ],
              [
                -74.2131571405387,
                40.557002594094676
              ],
              [
                -74.21315792607356,
                40.55699403865189
              ],
              [
                -74.21315790098974,
                40.55698546215116
              ],
              [
                -74.21315987847005,
                40.556971077999314
              ],
              [
                -74.21316269589218,
                40.55695677578394
              ],
              [
                -74.21316634793226,
                40.55694258224588
              ],
              [
                -74.2131708250863,
                40.556928528972215
              ],
              [
                -74.21317612319916,
                40.5569146409504
              ],
              [
                -74.21318222713029,
                40.55690094810482
              ],
              [
                -74.21318912697654,
                40.55688747517423
              ],
              [
                -74.21319680781782,
                40.55687425274996
              ],
              [
                -74.21320833788467,
                40.5568507359886
              ],
              [
                -74.21321916900892,
                40.556827027794135
              ],
              [
                -74.21322929596315,
                40.55680313886781
              ],
              [
                -74.2132387152298,
                40.556779081827386
              ],
              [
                -74.21324397315513,
                40.55676445719357
              ],
              [
                -74.21324697320209,
                40.55670469421521
              ],
              [
                -74.21324031454323,
                40.55667262386907
              ],
              [
                -74.2056960519999,
                40.55851600599987
              ],
              [
                -74.20699885299997,
                40.55756748899989
              ],
              [
                -74.20671355399996,
                40.557576935999855
              ],
              [
                -74.20642597199998,
                40.55756934899992
              ],
              [
                -74.20613872599988,
                40.55754450599988
              ],
              [
                -74.20585448599996,
                40.55750245699987
              ],
              [
                -74.20557588399996,
                40.5574435929999
              ],
              [
                -74.20530543999998,
                40.557368621999906
              ],
              [
                -74.20520041199991,
                40.55733222099988
              ],
              [
                -74.20506288299997,
                40.5572778759999
              ],
              [
                -74.20492823999997,
                40.55720548999993
              ],
              [
                -74.20398450099998,
                40.55661748499993
              ],
              [
                -74.2038442639999,
                40.5564857279999
              ],
              [
                -74.20369556299995,
                40.55634601699993
              ],
              [
                -74.20363978099991,
                40.55630271299988
              ],
              [
                -74.20358106099994,
                40.55626173299989
              ],
              [
                -74.20351956699993,
                40.55622318999985
              ],
              [
                -74.20344936699998,
                40.556183969999886
              ],
              [
                -74.20337629799997,
                40.55614792799985
              ],
              [
                -74.20330246299997,
                40.55611593799991
              ],
              [
                -74.2032263689999,
                40.556087191999886
              ],
              [
                -74.20314825999996,
                40.556061784999876
              ],
              [
                -74.20301604799995,
                40.556028442999875
              ],
              [
                -74.20288252399993,
                40.555998296999846
              ],
              [
                -74.20274781999996,
                40.55597137599993
              ],
              [
                -74.20261207099998,
                40.55594770599991
              ],
              [
                -74.20247541199996,
                40.55592731199989
              ],
              [
                -74.20233797799995,
                40.55591021399987
              ],
              [
                -74.2022083119999,
                40.55589290699987
              ],
              [
                -74.20207763999998,
                40.55588077499989
              ],
              [
                -74.2019463179999,
                40.55587385199995
              ],
              [
                -74.20181469999991,
                40.5558721559999
              ],
              [
                -74.20168314599995,
                40.55587569299991
              ],
              [
                -74.20155201099992,
                40.55588445199986
              ],
              [
                -74.20142165299998,
                40.555898409999934
              ],
              [
                -74.20129242599991,
                40.55591752799988
              ],
              [
                -74.20116467899999,
                40.555941755999896
              ],
              [
                -74.20103876099995,
                40.55597102599994
              ],
              [
                -74.20091501299991,
                40.55600525999988
              ],
              [
                -74.20079377099992,
                40.556044364999885
              ],
              [
                -74.20062607299995,
                40.55613226099993
              ],
              [
                -74.20046730799993,
                40.55623048999988
              ],
              [
                -74.20031905499991,
                40.556338225999916
              ],
              [
                -74.20018273999989,
                40.55645443399991
              ],
              [
                -74.20005953999996,
                40.55657793999989
              ],
              [
                -74.19946683299995,
                40.557181180999905
              ],
              [
                -74.19769481199992,
                40.558887053999854
              ],
              [
                -74.19407564499997,
                40.56207847399989
              ],
              [
                -74.1938627059999,
                40.562221584999854
              ],
              [
                -74.19386255299989,
                40.562221684999955
              ],
              [
                -74.19386239999993,
                40.56222177399992
              ],
              [
                -74.19364100699988,
                40.562357199999944
              ],
              [
                -74.19364075799996,
                40.562357353999914
              ],
              [
                -74.19364051199993,
                40.562357497999876
              ],
              [
                -74.193410946,
                40.56248503899995
              ],
              [
                -74.19317388399995,
                40.56260434399992
              ],
              [
                -74.19307925099996,
                40.5626277349999
              ],
              [
                -74.19298216699994,
                40.562644280999905
              ],
              [
                -74.19288347999992,
                40.5626538389999
              ],
              [
                -74.19278405399993,
                40.56265632499992
              ],
              [
                -74.192684759,
                40.56265171699996
              ],
              [
                -74.19258646199988,
                40.562640053999885
              ],
              [
                -74.19237007799995,
                40.5625885469999
              ],
              [
                -74.19214711699996,
                40.5625502149999
              ],
              [
                -74.19191967499992,
                40.56252570499986
              ],
              [
                -74.19168998299995,
                40.56251540899991
              ],
              [
                -74.19146034299997,
                40.5625194289999
              ],
              [
                -74.19123302399998,
                40.5625375759999
              ],
              [
                -74.19101021699996,
                40.562569401999916
              ],
              [
                -74.19085177899994,
                40.562618932999946
              ],
              [
                -74.1907004609999,
                40.5626846349999
              ],
              [
                -74.19056041299997,
                40.56276545599992
              ],
              [
                -74.19043537599994,
                40.56285950699993
              ],
              [
                -74.19032832899994,
                40.56296424499989
              ],
              [
                -74.19024124699997,
                40.563076684999935
              ],
              [
                -74.19011908499989,
                40.56319841199991
              ],
              [
                -74.18997973099991,
                40.56331160499996
              ],
              [
                -74.18982494799998,
                40.56341415199991
              ],
              [
                -74.18982482999994,
                40.563414223999885
              ],
              [
                -74.18982471199989,
                40.56341428799995
              ],
              [
                -74.18965654999995,
                40.56350449199993
              ],
              [
                -74.189477545,
                40.56358098999988
              ],
              [
                -74.18929075199998,
                40.56364274099997
              ],
              [
                -74.18909923999989,
                40.56368927199991
              ],
              [
                -74.18726897600001,
                40.5640416929999
              ],
              [
                -74.18677554199998,
                40.56419095799989
              ],
              [
                -74.18627446199993,
                40.564325526999866
              ],
              [
                -74.185767078,
                40.56444503799991
              ],
              [
                -74.18576687799995,
                40.564445082999946
              ],
              [
                -74.18576667699995,
                40.56444512799989
              ],
              [
                -74.18525395899995,
                40.564549354999876
              ],
              [
                -74.18502077599993,
                40.564704711999916
              ],
              [
                -74.18502052799994,
                40.56470486499994
              ],
              [
                -74.18502026899988,
                40.564705027999906
              ],
              [
                -74.18477472999997,
                40.56485049699993
              ],
              [
                -74.18451788299991,
                40.5649853049999
              ],
              [
                -74.18451768299992,
                40.564985404999916
              ],
              [
                -74.18451749399999,
                40.56498549599995
              ],
              [
                -74.18425093099988,
                40.56510871899992
              ],
              [
                -74.18425062399993,
                40.56510885399991
              ],
              [
                -74.18425030499996,
                40.565108989999906
              ],
              [
                -74.18397521799992,
                40.56522011499992
              ],
              [
                -74.18397488799997,
                40.565220240999935
              ],
              [
                -74.18397454599993,
                40.56522036799989
              ],
              [
                -74.18369189099995,
                40.565319067999916
              ],
              [
                -74.18340311699991,
                40.56540496199994
              ],
              [
                -74.18318459699992,
                40.56545009399986
              ],
              [
                -74.18295815999996,
                40.56548019999992
              ],
              [
                -74.18272650699997,
                40.56549428299995
              ],
              [
                -74.18249259899989,
                40.56549172899984
              ],
              [
                -74.18241339499998,
                40.56548514599992
              ],
              [
                -74.18225953199997,
                40.565472349999936
              ],
              [
                -74.18203038699987,
                40.56543640499994
              ],
              [
                -74.18180816599994,
                40.56538457899992
              ],
              [
                -74.1815955649999,
                40.56531796099987
              ],
              [
                -74.18139492399996,
                40.56523797899997
              ],
              [
                -74.1779454409999,
                40.56398336699991
              ],
              [
                -74.17758036699996,
                40.56375543999989
              ],
              [
                -74.17659365999994,
                40.56324132999986
              ],
              [
                -74.17539949099991,
                40.562964661999914
              ],
              [
                -74.17488750399997,
                40.562826418999876
              ],
              [
                -74.17421738099996,
                40.56256808499995
              ],
              [
                -74.17348508099991,
                40.56226748999991
              ],
              [
                -74.17225507699992,
                40.56170483699991
              ],
              [
                -74.17191921399996,
                40.56155171899995
              ],
              [
                -74.17185166499996,
                40.56152092299992
              ],
              [
                -74.17177723899994,
                40.56149241599996
              ],
              [
                -74.17146296099997,
                40.56137542899988
              ],
              [
                -74.17126370299995,
                40.561323959999875
              ],
              [
                -74.17106256099996,
                40.56127694699989
              ],
              [
                -74.17085970499996,
                40.56123442999989
              ],
              [
                -74.17065530999997,
                40.561196445999926
              ],
              [
                -74.1704495479999,
                40.56116302599992
              ],
              [
                -74.17024259599992,
                40.561134199999856
              ],
              [
                -74.17003462899996,
                40.5611099909999
              ],
              [
                -74.16982582400001,
                40.56109042099992
              ],
              [
                -74.16976493899996,
                40.56288426399995
              ],
              [
                -74.16974974799987,
                40.56332951299986
              ],
              [
                -74.16974968899994,
                40.56333125299989
              ],
              [
                -74.16974554099994,
                40.56345282199994
              ],
              [
                -74.16973775199993,
                40.56383841899987
              ],
              [
                -74.16972693399991,
                40.56437399799989
              ],
              [
                -74.16972490199993,
                40.56447115899986
              ],
              [
                -74.16969147299992,
                40.566070399999894
              ],
              [
                -74.16967801699991,
                40.566470244999906
              ],
              [
                -74.16966171099988,
                40.566903382999904
              ],
              [
                -74.16963069799989,
                40.56765163899992
              ],
              [
                -74.16962643399991,
                40.568298409999926
              ],
              [
                -74.169644733,
                40.56894526499992
              ],
              [
                -74.16968558799996,
                40.56959152099988
              ],
              [
                -74.16974893399991,
                40.57023648399991
              ],
              [
                -74.16983467899996,
                40.57087948699992
              ],
              [
                -74.16989846899996,
                40.57139322399989
              ],
              [
                -74.16994243599996,
                40.57190861999992
              ],
              [
                -74.16996647099997,
                40.5724249999999
              ],
              [
                -74.16997053699991,
                40.57294169899995
              ],
              [
                -74.16995465599994,
                40.57345803799993
              ],
              [
                -74.16991886099999,
                40.57397335499995
              ],
              [
                -74.16980912399991,
                40.57579489399991
              ],
              [
                -74.16975526299996,
                40.57668893399995
              ],
              [
                -74.16973086899992,
                40.576965756999876
              ],
              [
                -74.16970918699998,
                40.57745394499993
              ],
              [
                -74.16967337599986,
                40.57794182199995
              ],
              [
                -74.16962344999997,
                40.57842904399989
              ],
              [
                -74.16955946599997,
                40.57891524199987
              ],
              [
                -74.16948145799995,
                40.57940007499998
              ],
              [
                -74.16913807999991,
                40.58180581199991
              ],
              [
                -74.16909417599999,
                40.58211339699996
              ],
              [
                -74.16908500799998,
                40.58217764599993
              ],
              [
                -74.16907820699998,
                40.58222502299985
              ],
              [
                -74.16905498199999,
                40.58238696299989
              ],
              [
                -74.1690483779999,
                40.58243293499993
              ],
              [
                -74.16903999799996,
                40.582491778999945
              ],
              [
                -74.16901204299992,
                40.58268826899994
              ],
              [
                -74.16895369199989,
                40.583098363999916
              ],
              [
                -74.16842916199994,
                40.58649792299988
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0000374700210291,
        "objectid": 100,
        "shape_leng": 0.0248131090342,
        "location_id": 100,
        "zone": "Garment District",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.98729377099981,
                40.750451608999896
              ],
              [
                -73.98684991099998,
                40.75108167899989
              ],
              [
                -73.98639930899988,
                40.751699985999934
              ],
              [
                -73.98594653099994,
                40.7523168049999
              ],
              [
                -73.98548819099985,
                40.752935179999945
              ],
              [
                -73.98504363799995,
                40.753554148999854
              ],
              [
                -73.984595843,
                40.75417694199992
              ],
              [
                -73.98411754799999,
                40.754842052999955
              ],
              [
                -73.98640436699999,
                40.755808028999866
              ],
              [
                -73.98694873899993,
                40.756035901999915
              ],
              [
                -73.98841835499992,
                40.7566549859999
              ],
              [
                -73.98979085399995,
                40.75723313199991
              ],
              [
                -73.99027621399986,
                40.756567564999926
              ],
              [
                -73.99072636299991,
                40.75595040599996
              ],
              [
                -73.99117738199989,
                40.75532984899993
              ],
              [
                -73.99163271299996,
                40.75470680499996
              ],
              [
                -73.99207900099996,
                40.75409111599987
              ],
              [
                -73.99252855999994,
                40.75347391299987
              ],
              [
                -73.99297937799993,
                40.75285340299992
              ],
              [
                -73.99346417699985,
                40.752190054999886
              ],
              [
                -73.99211708899992,
                40.75162207799989
              ],
              [
                -73.99062219999986,
                40.75099175399994
              ],
              [
                -73.98793687499989,
                40.749854966999926
              ],
              [
                -73.98776905899994,
                40.749787028999926
              ],
              [
                -73.98729377099981,
                40.750451608999896
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000452342528877,
        "objectid": 101,
        "shape_leng": 0.101709836277,
        "location_id": 101,
        "zone": "Glen Oaks",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.70134715908382,
                40.75078058093586
              ],
              [
                -73.70162212285493,
                40.75242551794639
              ],
              [
                -73.70163345846068,
                40.75249332984641
              ],
              [
                -73.70169386980764,
                40.75253685502325
              ],
              [
                -73.70217841689814,
                40.75288593949997
              ],
              [
                -73.7027349570185,
                40.75325051690489
              ],
              [
                -73.70346781908046,
                40.753683363268976
              ],
              [
                -73.70527128308203,
                40.75477044122686
              ],
              [
                -73.70782538615794,
                40.75621640416214
              ],
              [
                -73.71054499713267,
                40.75799103416247
              ],
              [
                -73.71085768999156,
                40.758195064820384
              ],
              [
                -73.71244940715339,
                40.75924170410932
              ],
              [
                -73.71332542122421,
                40.75973018978564
              ],
              [
                -73.71351828186042,
                40.75983773091619
              ],
              [
                -73.71426287799991,
                40.75982674699991
              ],
              [
                -73.71455685899994,
                40.759808807999896
              ],
              [
                -73.71498213999979,
                40.759782864999885
              ],
              [
                -73.71612528199985,
                40.75966071399991
              ],
              [
                -73.71613842699988,
                40.759658278999964
              ],
              [
                -73.71685440399995,
                40.75952551499989
              ],
              [
                -73.71767237099996,
                40.759311211999886
              ],
              [
                -73.71831971899985,
                40.7590820759999
              ],
              [
                -73.71877787700002,
                40.75887169199989
              ],
              [
                -73.7189705039999,
                40.75878323799991
              ],
              [
                -73.71917909599986,
                40.758666436999924
              ],
              [
                -73.71956460799996,
                40.758450559999915
              ],
              [
                -73.71967732299989,
                40.758372498999904
              ],
              [
                -73.71998297099998,
                40.758160820999876
              ],
              [
                -73.72031595299998,
                40.75789725799994
              ],
              [
                -73.7206331939999,
                40.75761683499996
              ],
              [
                -73.72096511699988,
                40.757293788999945
              ],
              [
                -73.72111001499998,
                40.75711152599996
              ],
              [
                -73.72121176099988,
                40.7569792079999
              ],
              [
                -73.72145870299994,
                40.756658080999955
              ],
              [
                -73.72159631499989,
                40.756427503999916
              ],
              [
                -73.72161357099998,
                40.756398594999894
              ],
              [
                -73.72176339599983,
                40.75613846699986
              ],
              [
                -73.72180289199997,
                40.75606989699991
              ],
              [
                -73.72207071399988,
                40.75549670299993
              ],
              [
                -73.72241094799992,
                40.75492018899994
              ],
              [
                -73.72264543599995,
                40.754600523999954
              ],
              [
                -73.72279703199989,
                40.75443353799988
              ],
              [
                -73.72299301299984,
                40.75421767099992
              ],
              [
                -73.72326673399982,
                40.75394759899992
              ],
              [
                -73.72344135499989,
                40.75379084499987
              ],
              [
                -73.72351867199991,
                40.7537214379999
              ],
              [
                -73.7238269889999,
                40.75346908899998
              ],
              [
                -73.72384451899983,
                40.75345681199988
              ],
              [
                -73.72475823099984,
                40.7529081479999
              ],
              [
                -73.72506847999986,
                40.752721843999915
              ],
              [
                -73.72509445299985,
                40.75270759199992
              ],
              [
                -73.72551247899985,
                40.752478197999906
              ],
              [
                -73.72582988899984,
                40.752291720999935
              ],
              [
                -73.72602966399984,
                40.752174354999916
              ],
              [
                -73.7264147219999,
                40.75191587799986
              ],
              [
                -73.7266652989999,
                40.75171358799987
              ],
              [
                -73.726777395,
                40.75162309199987
              ],
              [
                -73.72709389599997,
                40.75134160399988
              ],
              [
                -73.72737889099986,
                40.75102956699988
              ],
              [
                -73.72771356199995,
                40.750626894999904
              ],
              [
                -73.72788401199996,
                40.75038748299989
              ],
              [
                -73.72805355599995,
                40.750082150999916
              ],
              [
                -73.7282538099999,
                40.74969372899996
              ],
              [
                -73.72837798399998,
                40.7493811669999
              ],
              [
                -73.72849178499999,
                40.749044706999904
              ],
              [
                -73.72862207399999,
                40.7485120919999
              ],
              [
                -73.72871952799996,
                40.74819708099995
              ],
              [
                -73.728962516,
                40.74763288799988
              ],
              [
                -73.72888621599986,
                40.74756663599992
              ],
              [
                -73.72829094199989,
                40.74745895699992
              ],
              [
                -73.72822968799987,
                40.7478308039999
              ],
              [
                -73.72815098799995,
                40.74820123199988
              ],
              [
                -73.72805493499999,
                40.74856952199988
              ],
              [
                -73.72794170099989,
                40.74893498799989
              ],
              [
                -73.72781151199987,
                40.74929691999996
              ],
              [
                -73.727664654,
                40.749654641999946
              ],
              [
                -73.72627213299992,
                40.74930649099986
              ],
              [
                -73.72339596299987,
                40.750389075999855
              ],
              [
                -73.72258571899978,
                40.74885045099993
              ],
              [
                -73.720144294,
                40.74976283099995
              ],
              [
                -73.72010148299994,
                40.74977568599992
              ],
              [
                -73.71988664100002,
                40.749369510999955
              ],
              [
                -73.71896368299996,
                40.74699474099987
              ],
              [
                -73.71894406299995,
                40.74694425599994
              ],
              [
                -73.71892134899986,
                40.74689486899995
              ],
              [
                -73.71869037499981,
                40.746392765999936
              ],
              [
                -73.71853063699987,
                40.746039330999956
              ],
              [
                -73.71823250200002,
                40.74539666299995
              ],
              [
                -73.71764302999979,
                40.744070413999964
              ],
              [
                -73.71762516399986,
                40.74402847899988
              ],
              [
                -73.7176030609999,
                40.743974627999876
              ],
              [
                -73.71740598499986,
                40.74351092999988
              ],
              [
                -73.71656758399989,
                40.74164908599988
              ],
              [
                -73.71578267699999,
                40.739895785999884
              ],
              [
                -73.71544946799989,
                40.73921680399994
              ],
              [
                -73.71511121299982,
                40.73856840999989
              ],
              [
                -73.71496199799985,
                40.73827640999986
              ],
              [
                -73.71494063099992,
                40.73823459999989
              ],
              [
                -73.71476982799997,
                40.7379003479999
              ],
              [
                -73.71436629299991,
                40.737243891999896
              ],
              [
                -73.71370155699985,
                40.736160121999944
              ],
              [
                -73.71328662200003,
                40.735473124999935
              ],
              [
                -73.71289825099976,
                40.73485113699989
              ],
              [
                -73.71283004500002,
                40.734739627999964
              ],
              [
                -73.71278769000006,
                40.73467038099989
              ],
              [
                -73.71250100999993,
                40.73420163299987
              ],
              [
                -73.7122862199999,
                40.73385241799987
              ],
              [
                -73.71208085999997,
                40.7335185259999
              ],
              [
                -73.71186191499989,
                40.73315683699991
              ],
              [
                -73.71147804899985,
                40.73250140099988
              ],
              [
                -73.71089324899998,
                40.730971041999936
              ],
              [
                -73.71075217299996,
                40.73052537499992
              ],
              [
                -73.71066275899989,
                40.72949571099993
              ],
              [
                -73.710655187,
                40.729382965999925
              ],
              [
                -73.7106187189999,
                40.72883987399995
              ],
              [
                -73.7105550639999,
                40.728020849999915
              ],
              [
                -73.71050351799981,
                40.72727838999988
              ],
              [
                -73.71049995381028,
                40.727227087206614
              ],
              [
                -73.71043109587094,
                40.72724106148464
              ],
              [
                -73.70856949090842,
                40.72763641821302
              ],
              [
                -73.70766217302989,
                40.727830934754685
              ],
              [
                -73.70709970121251,
                40.72874771477672
              ],
              [
                -73.70648028283007,
                40.72971129886389
              ],
              [
                -73.70584734777326,
                40.7306728541998
              ],
              [
                -73.7052216578116,
                40.73161963010229
              ],
              [
                -73.70499536283172,
                40.73197055801242
              ],
              [
                -73.70460114375578,
                40.732569747213866
              ],
              [
                -73.70394463088694,
                40.73352626210759
              ],
              [
                -73.703522490113,
                40.734207944901655
              ],
              [
                -73.70331490976334,
                40.7345632569417
              ],
              [
                -73.70268794512972,
                40.735490222643136
              ],
              [
                -73.7022808428287,
                40.73612579680929
              ],
              [
                -73.70206036378326,
                40.736442756833
              ],
              [
                -73.7014707301906,
                40.737511560982036
              ],
              [
                -73.70081147181156,
                40.738439152194765
              ],
              [
                -73.70031835188448,
                40.7389680827907
              ],
              [
                -73.70002020501624,
                40.73923654186596
              ],
              [
                -73.7000090639354,
                40.739323413081635
              ],
              [
                -73.70002140398307,
                40.73940416608862
              ],
              [
                -73.70029222220697,
                40.74101060200636
              ],
              [
                -73.70058164018724,
                40.74318384179423
              ],
              [
                -73.70077960803322,
                40.74504145390005
              ],
              [
                -73.700893400139,
                40.747057265812565
              ],
              [
                -73.70090127406783,
                40.747128543788975
              ],
              [
                -73.70117316524451,
                40.74892430181052
              ],
              [
                -73.70134715908382,
                40.75078058093586
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000296595466345,
        "objectid": 102,
        "shape_leng": 0.136900484646,
        "location_id": 102,
        "zone": "Glendale",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.85595536199988,
                40.70642977899993
              ],
              [
                -73.85667714399985,
                40.70769187399986
              ],
              [
                -73.85729287299985,
                40.711467676999874
              ],
              [
                -73.8581874529999,
                40.71159954599992
              ],
              [
                -73.85964591099994,
                40.711827343999886
              ],
              [
                -73.85975932900001,
                40.711845058999856
              ],
              [
                -73.85971313599988,
                40.71260553899993
              ],
              [
                -73.85966066799993,
                40.713294645999945
              ],
              [
                -73.85965416799995,
                40.71337996099988
              ],
              [
                -73.85978566499996,
                40.71331129599985
              ],
              [
                -73.86207872699998,
                40.71220105999993
              ],
              [
                -73.86500981999998,
                40.710776908999875
              ],
              [
                -73.86557714699987,
                40.71065921899993
              ],
              [
                -73.86661823199995,
                40.71040164899997
              ],
              [
                -73.87016462499984,
                40.70920774299988
              ],
              [
                -73.86989582499986,
                40.7085070939999
              ],
              [
                -73.86949738599989,
                40.70787206999991
              ],
              [
                -73.86911007199988,
                40.70708933699992
              ],
              [
                -73.87153400399993,
                40.70703676799997
              ],
              [
                -73.87326053099987,
                40.706922736999914
              ],
              [
                -73.87425518099995,
                40.706857030999906
              ],
              [
                -73.87482359699989,
                40.70681947799993
              ],
              [
                -73.87532966799982,
                40.70679838799992
              ],
              [
                -73.87625034900005,
                40.70674464299992
              ],
              [
                -73.87720614800001,
                40.70668883999991
              ],
              [
                -73.87814452399996,
                40.7066096259999
              ],
              [
                -73.87900684300003,
                40.7065368389999
              ],
              [
                -73.88000104599992,
                40.70649293399988
              ],
              [
                -73.88088332699985,
                40.70645397699992
              ],
              [
                -73.8817801769999,
                40.706404547999966
              ],
              [
                -73.88266573799993,
                40.70635551699991
              ],
              [
                -73.88377296899992,
                40.706294201999874
              ],
              [
                -73.88456244600002,
                40.70636218699993
              ],
              [
                -73.88553214199999,
                40.70644568299985
              ],
              [
                -73.88554946899997,
                40.70615461899994
              ],
              [
                -73.88652826799989,
                40.706225205999885
              ],
              [
                -73.8873957249999,
                40.706018129999954
              ],
              [
                -73.88826271699993,
                40.70580991299988
              ],
              [
                -73.88911917799989,
                40.70559887699992
              ],
              [
                -73.88984330199986,
                40.704972401999896
              ],
              [
                -73.89105316999985,
                40.70381641299988
              ],
              [
                -73.8912683769999,
                40.70359657599989
              ],
              [
                -73.89194157299991,
                40.702886227999905
              ],
              [
                -73.89283458099982,
                40.70191027699992
              ],
              [
                -73.89384018399996,
                40.700859725999884
              ],
              [
                -73.89420165299988,
                40.70050730899994
              ],
              [
                -73.89500322699999,
                40.69971237699987
              ],
              [
                -73.89484994999988,
                40.69858962299989
              ],
              [
                -73.89578005099996,
                40.69851096199989
              ],
              [
                -73.89558457599986,
                40.6971276419999
              ],
              [
                -73.89677249999983,
                40.696799116999884
              ],
              [
                -73.89756262999987,
                40.69648070799991
              ],
              [
                -73.89838185199996,
                40.69613599799993
              ],
              [
                -73.89888585799994,
                40.695389474999935
              ],
              [
                -73.89954093099989,
                40.69428573099987
              ],
              [
                -73.900030392,
                40.69343745099991
              ],
              [
                -73.89973223499982,
                40.69351918299989
              ],
              [
                -73.89935202799984,
                40.69364779599994
              ],
              [
                -73.89896466800003,
                40.69376479299993
              ],
              [
                -73.89857107699993,
                40.69386983399992
              ],
              [
                -73.89817221699988,
                40.693962658999936
              ],
              [
                -73.89776907799997,
                40.69404304999987
              ],
              [
                -73.89736265799978,
                40.69411084699991
              ],
              [
                -73.89691021299981,
                40.6938422709999
              ],
              [
                -73.89742730199981,
                40.6932889799999
              ],
              [
                -73.89764233499987,
                40.69340865099986
              ],
              [
                -73.89768631800001,
                40.69336525799991
              ],
              [
                -73.89695653499987,
                40.6919492069999
              ],
              [
                -73.89576145299986,
                40.69233462199984
              ],
              [
                -73.89474270999993,
                40.69263695299986
              ],
              [
                -73.89326209899984,
                40.69220094699995
              ],
              [
                -73.89357935000005,
                40.69308532899988
              ],
              [
                -73.89398113199998,
                40.69307596499988
              ],
              [
                -73.89558625499983,
                40.6939791619999
              ],
              [
                -73.8950769439999,
                40.694591389999886
              ],
              [
                -73.89245207599993,
                40.69522572799987
              ],
              [
                -73.89195658699997,
                40.69441056999991
              ],
              [
                -73.88919365299998,
                40.69493576999991
              ],
              [
                -73.88828642399997,
                40.69523691699992
              ],
              [
                -73.88865416899996,
                40.69582954899994
              ],
              [
                -73.88648530299996,
                40.69643991499991
              ],
              [
                -73.88642048199995,
                40.69645814899995
              ],
              [
                -73.88681137100004,
                40.6966679479999
              ],
              [
                -73.88699989899986,
                40.69677114399992
              ],
              [
                -73.88717721900004,
                40.69689008599989
              ],
              [
                -73.88734003299987,
                40.6970236659999
              ],
              [
                -73.88748536199982,
                40.69717024899991
              ],
              [
                -73.88761064199984,
                40.69732774499996
              ],
              [
                -73.88771392599995,
                40.69749369299993
              ],
              [
                -73.88779396299992,
                40.69766542699991
              ],
              [
                -73.88785023699991,
                40.69784014499988
              ],
              [
                -73.88788297599991,
                40.69801511999986
              ],
              [
                -73.88809909499986,
                40.698763295999896
              ],
              [
                -73.88818025800003,
                40.698857910999926
              ],
              [
                -73.88771297999985,
                40.699678075999906
              ],
              [
                -73.8867672829999,
                40.69982608499988
              ],
              [
                -73.88584205499986,
                40.699965471999896
              ],
              [
                -73.88492223099999,
                40.70010402799989
              ],
              [
                -73.88431619499985,
                40.700201195999924
              ],
              [
                -73.88384293499996,
                40.70034725999994
              ],
              [
                -73.88343086899987,
                40.70047443799989
              ],
              [
                -73.88280948499988,
                40.70068345599988
              ],
              [
                -73.88273014799977,
                40.700549908999925
              ],
              [
                -73.88182690599986,
                40.69952929899987
              ],
              [
                -73.88106992699986,
                40.69979988899989
              ],
              [
                -73.880246012,
                40.700093348999914
              ],
              [
                -73.87942953599993,
                40.70040484899988
              ],
              [
                -73.87860797499998,
                40.70070418799989
              ],
              [
                -73.8777891919999,
                40.701013621999905
              ],
              [
                -73.87621714199997,
                40.70159500499992
              ],
              [
                -73.87625758399984,
                40.70174876599992
              ],
              [
                -73.87639785199995,
                40.70229404199989
              ],
              [
                -73.87543139400003,
                40.7023743539999
              ],
              [
                -73.8744627409999,
                40.702456831999946
              ],
              [
                -73.87351208999992,
                40.70253545899994
              ],
              [
                -73.87256153500006,
                40.7026120979999
              ],
              [
                -73.87210385799979,
                40.70264976299988
              ],
              [
                -73.87164751899996,
                40.70268730999989
              ],
              [
                -73.87073324199999,
                40.70276203899995
              ],
              [
                -73.86981898499995,
                40.70283792999985
              ],
              [
                -73.86915596200001,
                40.702893432999865
              ],
              [
                -73.86903271499997,
                40.70290390099996
              ],
              [
                -73.8687324349999,
                40.702929097999856
              ],
              [
                -73.86824273999996,
                40.7029701959999
              ],
              [
                -73.86724652399994,
                40.703051654999896
              ],
              [
                -73.86653775700002,
                40.70310973799991
              ],
              [
                -73.865640216,
                40.703182589999884
              ],
              [
                -73.8647283009999,
                40.70325882199997
              ],
              [
                -73.86379389300001,
                40.7033337959999
              ],
              [
                -73.86341506899986,
                40.7033788049999
              ],
              [
                -73.86285374199994,
                40.703414491999915
              ],
              [
                -73.86241891699996,
                40.70337747399988
              ],
              [
                -73.86142263099983,
                40.703269757999884
              ],
              [
                -73.86068159399994,
                40.70318418699986
              ],
              [
                -73.85997492299997,
                40.703108901999904
              ],
              [
                -73.85874741999989,
                40.702974297999894
              ],
              [
                -73.858530295,
                40.7029497089999
              ],
              [
                -73.85824915999993,
                40.70291693499992
              ],
              [
                -73.85819890699996,
                40.702911073999886
              ],
              [
                -73.85823494999983,
                40.7028328909999
              ],
              [
                -73.85893224399979,
                40.7013203449999
              ],
              [
                -73.85827405799986,
                40.70122365899992
              ],
              [
                -73.85739221099975,
                40.701213978999874
              ],
              [
                -73.85725351500002,
                40.701212176999896
              ],
              [
                -73.85716688299983,
                40.70167410499993
              ],
              [
                -73.85630830699988,
                40.70183818199996
              ],
              [
                -73.85538910499996,
                40.701976915999865
              ],
              [
                -73.85529571500001,
                40.70199100999992
              ],
              [
                -73.85529275899978,
                40.702595501999944
              ],
              [
                -73.85518109699989,
                40.7025832669999
              ],
              [
                -73.85252019099998,
                40.70229172399997
              ],
              [
                -73.85305663899999,
                40.70313704599993
              ],
              [
                -73.85371219899997,
                40.70391788699991
              ],
              [
                -73.85389480199986,
                40.70418341899991
              ],
              [
                -73.85392092799985,
                40.70422140899995
              ],
              [
                -73.85396105499991,
                40.70426421699992
              ],
              [
                -73.8541126479998,
                40.70442593399987
              ],
              [
                -73.854739573,
                40.705184849999945
              ],
              [
                -73.85473254599994,
                40.706731913999924
              ],
              [
                -73.85473240499985,
                40.70676414299989
              ],
              [
                -73.85593991699984,
                40.7063980339999
              ],
              [
                -73.85595536199988,
                40.70642977899993
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.00000633056361314,
        "objectid": 103,
        "shape_leng": 0.0143055167343,
        "location_id": 103,
        "zone": "Governor's Island/Ellis Island/Liberty Island",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -74.04388559600675,
                40.690184824096946
              ],
              [
                -74.04460616491457,
                40.690573484475344
              ],
              [
                -74.04586027413703,
                40.69105611629163
              ],
              [
                -74.04592592783412,
                40.691082603292124
              ],
              [
                -74.04599418397756,
                40.691104955847436
              ],
              [
                -74.04604442476779,
                40.69111415216414
              ],
              [
                -74.04609551081596,
                40.691120063192265
              ],
              [
                -74.04614707204328,
                40.691122646016545
              ],
              [
                -74.04617166999705,
                40.691097981243246
              ],
              [
                -74.0469969069895,
                40.69104932615995
              ],
              [
                -74.04703534814037,
                40.691045826247255
              ],
              [
                -74.0470726967221,
                40.691038064209394
              ],
              [
                -74.04710814069992,
                40.69102620832851
              ],
              [
                -74.04714091071601,
                40.69101051633723
              ],
              [
                -74.04717029494704,
                40.69099132912251
              ],
              [
                -74.04719421009764,
                40.690966748235475
              ],
              [
                -74.0472127219085,
                40.690939599444675
              ],
              [
                -74.04722536428265,
                40.69091056537459
              ],
              [
                -74.04723182036673,
                40.69088037534203
              ],
              [
                -74.04723192804273,
                40.69084978818734
              ],
              [
                -74.04722568396599,
                40.690819572241594
              ],
              [
                -74.04721324509907,
                40.690790487168705
              ],
              [
                -74.04719492423845,
                40.69076326392416
              ],
              [
                -74.04718321406033,
                40.690747355024456
              ],
              [
                -74.04711581988163,
                40.69065580605927
              ],
              [
                -74.04711050616285,
                40.69047041292518
              ],
              [
                -74.04720029383118,
                40.69042481531181
              ],
              [
                -74.04689205500284,
                40.69005909816131
              ],
              [
                -74.04743126078736,
                40.68980389023559
              ],
              [
                -74.04758571912386,
                40.68998250691307
              ],
              [
                -74.04772962698515,
                40.689915318233105
              ],
              [
                -74.04747651481166,
                40.68961136999915
              ],
              [
                -74.04680284899291,
                40.68995325613297
              ],
              [
                -74.04627538918811,
                40.689327425448475
              ],
              [
                -74.04478399103188,
                40.68859566005775
              ],
              [
                -74.04438521705612,
                40.688516178201496
              ],
              [
                -74.04397458578079,
                40.68858240702793
              ],
              [
                -74.04364078613705,
                40.688766559785
              ],
              [
                -74.0435163722292,
                40.68919103337114
              ],
              [
                -74.04346752305135,
                40.68963699005171
              ],
              [
                -74.04269380809586,
                40.68999725905358
              ],
              [
                -74.04263929302562,
                40.68992817595552
              ],
              [
                -74.04255372018648,
                40.68996275914475
              ],
              [
                -74.04270428413375,
                40.69015520482211
              ],
              [
                -74.04278433306983,
                40.69012097684565
              ],
              [
                -74.04273533896499,
                40.690050191210034
              ],
              [
                -74.04350596056258,
                40.689687359818144
              ],
              [
                -74.04388559600675,
                40.690184824096946
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0000119205339715,
        "objectid": 104,
        "shape_leng": 0.0212208330928,
        "location_id": 103,
        "zone": "Governor's Island/Ellis Island/Liberty Island",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -74.03995040794244,
                40.7008906303213
              ],
              [
                -74.04028808718954,
                40.70064361323363
              ],
              [
                -74.04036511100502,
                40.70070416692276
              ],
              [
                -74.04034955315318,
                40.700716018827265
              ],
              [
                -74.04048062492674,
                40.70082061433504
              ],
              [
                -74.04053637716099,
                40.70078209438744
              ],
              [
                -74.04041179180665,
                40.700678484059274
              ],
              [
                -74.04039104391973,
                40.700687376104355
              ],
              [
                -74.04031250903736,
                40.70062574909618
              ],
              [
                -74.04080080623118,
                40.70026854996324
              ],
              [
                -74.0410986121853,
                40.700492943194774
              ],
              [
                -74.04296505213769,
                40.69899936216238
              ],
              [
                -74.04270395805347,
                40.69880060029328
              ],
              [
                -74.04365651081106,
                40.698064091043896
              ],
              [
                -74.04363686205573,
                40.69804823209788
              ],
              [
                -74.04367371215382,
                40.69802040416499
              ],
              [
                -74.0416605190741,
                40.696452971319864
              ],
              [
                -74.0399124888631,
                40.69770204019913
              ],
              [
                -74.04174768884,
                40.69914786378336
              ],
              [
                -74.04124261816106,
                40.69953674121738
              ],
              [
                -74.04025555058615,
                40.69880482315377
              ],
              [
                -74.0399327211647,
                40.698544422145524
              ],
              [
                -74.03934037685065,
                40.69811551441799
              ],
              [
                -74.03900043889001,
                40.69836989019551
              ],
              [
                -74.03822954422694,
                40.69836859134887
              ],
              [
                -74.03809786207383,
                40.69903950512211
              ],
              [
                -74.03771124798313,
                40.69934404017383
              ],
              [
                -74.0393827810941,
                40.70057769428059
              ],
              [
                -74.03945262906649,
                40.70053315991193
              ],
              [
                -74.03995040794244,
                40.7008906303213
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000368636392805,
        "objectid": 105,
        "shape_leng": 0.0774253398314,
        "location_id": 103,
        "zone": "Governor's Island/Ellis Island/Liberty Island",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -74.01674756096064,
                40.693343368217555
              ],
              [
                -74.01682187641964,
                40.69334558113752
              ],
              [
                -74.01690615078013,
                40.69335341225322
              ],
              [
                -74.01700668693556,
                40.693344068510434
              ],
              [
                -74.01711872274268,
                40.69332607805407
              ],
              [
                -74.01723188418137,
                40.69332043274831
              ],
              [
                -74.01742068986829,
                40.69329880082908
              ],
              [
                -74.017728751353,
                40.69328178778385
              ],
              [
                -74.01801807424182,
                40.6932657952483
              ],
              [
                -74.01830295903022,
                40.69324774729765
              ],
              [
                -74.01837960490766,
                40.693239851076015
              ],
              [
                -74.01852170583395,
                40.69322999493903
              ],
              [
                -74.0186541185004,
                40.69322505712628
              ],
              [
                -74.01875100400217,
                40.69321766716067
              ],
              [
                -74.01879710616217,
                40.69321765963996
              ],
              [
                -74.01886404103503,
                40.69321764868792
              ],
              [
                -74.01899645645297,
                40.69322254283086
              ],
              [
                -74.01917408781627,
                40.69323234622592
              ],
              [
                -74.01928712492476,
                40.693234786137076
              ],
              [
                -74.01934089597206,
                40.69323477711305
              ],
              [
                -74.01938401317452,
                40.69323476985883
              ],
              [
                -74.01945183356793,
                40.69322738318994
              ],
              [
                -74.01952625786083,
                40.693206790851846
              ],
              [
                -74.01960136832818,
                40.69319203651079
              ],
              [
                -74.01962614022352,
                40.693185638606145
              ],
              [
                -74.0196683350802,
                40.69316438792429
              ],
              [
                -74.01971454942077,
                40.69313300682719
              ],
              [
                -74.01975203020818,
                40.69309852828896
              ],
              [
                -74.01975820423938,
                40.69309283565343
              ],
              [
                -74.01979706638019,
                40.69304227473585
              ],
              [
                -74.01979706616879,
                40.693041562744504
              ],
              [
                -74.01985623976917,
                40.692906232798926
              ],
              [
                -74.02020527434266,
                40.69203392385426
              ],
              [
                -74.02046388462907,
                40.691817991104024
              ],
              [
                -74.02071619151157,
                40.691584232140485
              ],
              [
                -74.02071969701747,
                40.69158098499546
              ],
              [
                -74.02097996202453,
                40.69132439086214
              ],
              [
                -74.02121292061638,
                40.69110396565088
              ],
              [
                -74.02147636060455,
                40.690846622621194
              ],
              [
                -74.02149639768447,
                40.69082705033472
              ],
              [
                -74.02189942193753,
                40.69044347236065
              ],
              [
                -74.02225114057582,
                40.69012221857815
              ],
              [
                -74.02243973951947,
                40.68993981943944
              ],
              [
                -74.02256537904927,
                40.68982575842607
              ],
              [
                -74.0226846593962,
                40.68971747085379
              ],
              [
                -74.02313394750364,
                40.68929545426466
              ],
              [
                -74.02339034096943,
                40.689049247613426
              ],
              [
                -74.02353644452148,
                40.68891577045839
              ],
              [
                -74.02367436482531,
                40.688792309760025
              ],
              [
                -74.02381144169377,
                40.688664515802714
              ],
              [
                -74.02396924880294,
                40.6885155500981
              ],
              [
                -74.02397192277759,
                40.688513025404276
              ],
              [
                -74.02411997621049,
                40.68836824351029
              ],
              [
                -74.0241951143645,
                40.68829476558987
              ],
              [
                -74.02432308577353,
                40.68818025702191
              ],
              [
                -74.024339228089,
                40.688165810058095
              ],
              [
                -74.02445685221299,
                40.68806055942187
              ],
              [
                -74.02456390211569,
                40.68796465845378
              ],
              [
                -74.02474903514577,
                40.687787029412206
              ],
              [
                -74.02495996488949,
                40.6875682036494
              ],
              [
                -74.02498452676963,
                40.68754509996105
              ],
              [
                -74.02515426770418,
                40.68738749447694
              ],
              [
                -74.02518794246518,
                40.68735622748172
              ],
              [
                -74.02538699247003,
                40.68716690428223
              ],
              [
                -74.02557971866312,
                40.6869974566
              ],
              [
                -74.0257838705966,
                40.68679470641727
              ],
              [
                -74.02606584987552,
                40.686530566227816
              ],
              [
                -74.02617488097594,
                40.6864290722291
              ],
              [
                -74.02622025384302,
                40.68638456809348
              ],
              [
                -74.0263127037025,
                40.686295116268
              ],
              [
                -74.02637621613817,
                40.68623131880009
              ],
              [
                -74.02642577243199,
                40.68617533982957
              ],
              [
                -74.02649600144045,
                40.686055352516355
              ],
              [
                -74.02657021545328,
                40.685905384681284
              ],
              [
                -74.02660890959791,
                40.68576279806959
              ],
              [
                -74.02662178340184,
                40.6856546341114
              ],
              [
                -74.0266281908386,
                40.685526804749706
              ],
              [
                -74.02660875559273,
                40.685376858009896
              ],
              [
                -74.02657640943082,
                40.68524166303809
              ],
              [
                -74.02651822912101,
                40.68510893314847
              ],
              [
                -74.02644066624605,
                40.684951624519876
              ],
              [
                -74.026334042424,
                40.68480169772421
              ],
              [
                -74.02623238290327,
                40.68470026105375
              ],
              [
                -74.02612578192962,
                40.68459351143973
              ],
              [
                -74.02604653184764,
                40.68451415115493
              ],
              [
                -74.02588501823519,
                40.68437652684009
              ],
              [
                -74.02567183758212,
                40.68423645620504
              ],
              [
                -74.02546836768583,
                40.68415292257828
              ],
              [
                -74.02521646935898,
                40.68409398087133
              ],
              [
                -74.02499306057516,
                40.68405211253555
              ],
              [
                -74.02484185637266,
                40.684012941142406
              ],
              [
                -74.02464809241222,
                40.68397856661301
              ],
              [
                -74.02443173023701,
                40.68395403059442
              ],
              [
                -74.0242153726967,
                40.68394424346433
              ],
              [
                -74.0239473535509,
                40.68394921598667
              ],
              [
                -74.0237826733674,
                40.68396891586903
              ],
              [
                -74.02354160370115,
                40.684002983592535
              ],
              [
                -74.0234147937385,
                40.68403560313049
              ],
              [
                -74.0233977685456,
                40.68404096982173
              ],
              [
                -74.0232567831563,
                40.684085414320776
              ],
              [
                -74.02313509710321,
                40.684124156652665
              ],
              [
                -74.0230170445636,
                40.68416704426917
              ],
              [
                -74.0227589902996,
                40.68428444833225
              ],
              [
                -74.02236823557664,
                40.68377991679401
              ],
              [
                -74.02319462834667,
                40.68300049806215
              ],
              [
                -74.02305574749599,
                40.6829169454452
              ],
              [
                -74.02213250186877,
                40.683767672385194
              ],
              [
                -74.02259359581036,
                40.684359695949944
              ],
              [
                -74.02239732580504,
                40.6844489893264
              ],
              [
                -74.02233552903553,
                40.68447639360419
              ],
              [
                -74.0222407889853,
                40.68451840810193
              ],
              [
                -74.02183909525692,
                40.684696540546895
              ],
              [
                -74.02106226329632,
                40.68504102470039
              ],
              [
                -74.02061267235811,
                40.68524039019806
              ],
              [
                -74.02027819846491,
                40.68480913517446
              ],
              [
                -74.02028899992284,
                40.68478444841859
              ],
              [
                -74.02100877899288,
                40.684453539084316
              ],
              [
                -74.0209525501029,
                40.684384431647196
              ],
              [
                -74.01939842391928,
                40.685087404047145
              ],
              [
                -74.01945957389425,
                40.68515495792238
              ],
              [
                -74.02016278941748,
                40.68483301741088
              ],
              [
                -74.0202068671052,
                40.684833833077064
              ],
              [
                -74.02054367293081,
                40.68527206792596
              ],
              [
                -74.02015656652381,
                40.68544978729508
              ],
              [
                -74.01712099101067,
                40.68684333763659
              ],
              [
                -74.01677346463228,
                40.68639877544977
              ],
              [
                -74.01678113957136,
                40.686349037919165
              ],
              [
                -74.01736132076928,
                40.68574627202702
              ],
              [
                -74.0176225750659,
                40.685409783720694
              ],
              [
                -74.01753032434085,
                40.6853629879324
              ],
              [
                -74.01727291238646,
                40.685690697681224
              ],
              [
                -74.01667351529983,
                40.68630516855344
              ],
              [
                -74.01661971233223,
                40.68631980484094
              ],
              [
                -74.0151439102199,
                40.68643703404304
              ],
              [
                -74.0151516167432,
                40.68652480204175
              ],
              [
                -74.01664664084757,
                40.6864163472334
              ],
              [
                -74.0167158267198,
                40.686445593709585
              ],
              [
                -74.0170498660923,
                40.68687598791897
              ],
              [
                -74.01698146864109,
                40.68690738448148
              ],
              [
                -74.01651927578641,
                40.68711955114744
              ],
              [
                -74.01609789228392,
                40.68731459228818
              ],
              [
                -74.01604730539512,
                40.68733800629772
              ],
              [
                -74.01488097975005,
                40.68725457048857
              ],
              [
                -74.01347243916622,
                40.688012057966205
              ],
              [
                -74.01341858951315,
                40.688052198317855
              ],
              [
                -74.01333019977945,
                40.68809316604797
              ],
              [
                -74.01326486705615,
                40.688122430096655
              ],
              [
                -74.01316926402752,
                40.68815477120321
              ],
              [
                -74.01275451203071,
                40.68872055623788
              ],
              [
                -74.01270320924039,
                40.68879053981697
              ],
              [
                -74.01230688304486,
                40.68928018293753
              ],
              [
                -74.012231311469,
                40.69046133077507
              ],
              [
                -74.01214221600921,
                40.69045428780721
              ],
              [
                -74.01212769712372,
                40.6905289574932
              ],
              [
                -74.01210771369418,
                40.690528959597394
              ],
              [
                -74.0120986343193,
                40.69054693658224
              ],
              [
                -74.01205685428478,
                40.69055385448957
              ],
              [
                -74.01204959850608,
                40.69061054854669
              ],
              [
                -74.01198056761243,
                40.690606407271744
              ],
              [
                -74.01199329167372,
                40.69065065379713
              ],
              [
                -74.01198603276991,
                40.69069766811433
              ],
              [
                -74.01197151318817,
                40.69077095550945
              ],
              [
                -74.0119878628144,
                40.69077233607648
              ],
              [
                -74.0119987596657,
                40.69075021210482
              ],
              [
                -74.01205325498773,
                40.690739143903045
              ],
              [
                -74.01208232424358,
                40.690759881924535
              ],
              [
                -74.01208414396382,
                40.690780622300494
              ],
              [
                -74.01221017410666,
                40.690791696801256
              ],
              [
                -74.01219552958608,
                40.691020573667956
              ],
              [
                -74.01219500090073,
                40.691028847568795
              ],
              [
                -74.01182847957557,
                40.69101135776438
              ],
              [
                -74.01182575354963,
                40.691064816216155
              ],
              [
                -74.01218682075256,
                40.691078024270645
              ],
              [
                -74.01218355071047,
                40.691136215940624
              ],
              [
                -74.01252783209964,
                40.69114633733795
              ],
              [
                -74.01259753152567,
                40.69133433554453
              ],
              [
                -74.01201746659368,
                40.69145820805393
              ],
              [
                -74.0120341761657,
                40.69150307668568
              ],
              [
                -74.01251950752706,
                40.691396831454185
              ],
              [
                -74.01253228594773,
                40.69142898661777
              ],
              [
                -74.01255488211348,
                40.69142300124786
              ],
              [
                -74.01254505151509,
                40.69139159327292
              ],
              [
                -74.01258434935933,
                40.69138336238062
              ],
              [
                -74.01258434695997,
                40.69137064972959
              ],
              [
                -74.01260856859405,
                40.69136410462975
              ],
              [
                -74.01262511694793,
                40.69140873714777
              ],
              [
                -74.01265071760986,
                40.69139945918043
              ],
              [
                -74.0127543683677,
                40.6914764676359
              ],
              [
                -74.01281595185874,
                40.69150012672344
              ],
              [
                -74.01286547266726,
                40.69154710124849
              ],
              [
                -74.0128751983542,
                40.69155633025172
              ],
              [
                -74.01289518355655,
                40.69157528340696
              ],
              [
                -74.01289688430353,
                40.691576895500305
              ],
              [
                -74.0128598710889,
                40.691602990865555
              ],
              [
                -74.01292800393819,
                40.69167610442813
              ],
              [
                -74.01291228751674,
                40.6916907308338
              ],
              [
                -74.01318656537055,
                40.691957929417796
              ],
              [
                -74.01325528699353,
                40.691916817498964
              ],
              [
                -74.0132647611728,
                40.69192580274551
              ],
              [
                -74.01326620460138,
                40.69192718049207
              ],
              [
                -74.01327481959478,
                40.69193534679518
              ],
              [
                -74.01331621035997,
                40.69197249741609
              ],
              [
                -74.01332546520501,
                40.69198520246154
              ],
              [
                -74.0133329328365,
                40.691997636989036
              ],
              [
                -74.01333432976998,
                40.692000924552666
              ],
              [
                -74.01333515825114,
                40.692004462970765
              ],
              [
                -74.013335394941,
                40.69200820902383
              ],
              [
                -74.01333505303288,
                40.69201209955263
              ],
              [
                -74.01333264149613,
                40.69201997010239
              ],
              [
                -74.01333064247271,
                40.69202372579595
              ],
              [
                -74.01332818317637,
                40.69202720143703
              ],
              [
                -74.01332606546653,
                40.692029579231274
              ],
              [
                -74.01332367657382,
                40.692031822532485
              ],
              [
                -74.01331804474162,
                40.692035902476796
              ],
              [
                -74.01331123156653,
                40.69203949572286
              ],
              [
                -74.01330314021662,
                40.69204264868686
              ],
              [
                -74.0132851579289,
                40.69204736900615
              ],
              [
                -74.01324784563468,
                40.692054217639885
              ],
              [
                -74.01324195439297,
                40.6920554161388
              ],
              [
                -74.01322338051708,
                40.69205919065682
              ],
              [
                -74.01282826026976,
                40.6921394359441
              ],
              [
                -74.01255899015489,
                40.69219315382361
              ],
              [
                -74.01253982481636,
                40.69219697386315
              ],
              [
                -74.01246140165121,
                40.69221262441595
              ],
              [
                -74.0124292705935,
                40.69215423889232
              ],
              [
                -74.01240858613572,
                40.69213424052948
              ],
              [
                -74.01239001029667,
                40.69212308503785
              ],
              [
                -74.01235951844633,
                40.692107770280096
              ],
              [
                -74.01232430689473,
                40.69209118800497
              ],
              [
                -74.0122011411636,
                40.69204490509603
              ],
              [
                -74.01214885984382,
                40.69203794936049
              ],
              [
                -74.01214293316167,
                40.692039219676275
              ],
              [
                -74.01213732516256,
                40.69204041808883
              ],
              [
                -74.01213630770874,
                40.692040643855556
              ],
              [
                -74.011968932587,
                40.69207647455717
              ],
              [
                -74.01191118868,
                40.692088844071215
              ],
              [
                -74.01190309722556,
                40.69209058350786
              ],
              [
                -74.01186468706686,
                40.69211796262598
              ],
              [
                -74.01183900825662,
                40.69215287764775
              ],
              [
                -74.01183833471082,
                40.692153797275
              ],
              [
                -74.01183446726385,
                40.69215905602311
              ],
              [
                -74.01175749051248,
                40.69226373956153
              ],
              [
                -74.01175034252032,
                40.692316348755774
              ],
              [
                -74.01175033086638,
                40.69231641141226
              ],
              [
                -74.01175097032225,
                40.692318113592954
              ],
              [
                -74.0117837450172,
                40.69240476697541
              ],
              [
                -74.01179881230529,
                40.69244459473697
              ],
              [
                -74.0118118564723,
                40.69247933506435
              ],
              [
                -74.01184889307419,
                40.6925178732815
              ],
              [
                -74.01184920038554,
                40.69251819825303
              ],
              [
                -74.01204369179824,
                40.692601816621526
              ],
              [
                -74.01210359185653,
                40.69260946499937
              ],
              [
                -74.01211726765081,
                40.69260655461443
              ],
              [
                -74.01211815614386,
                40.69260636571759
              ],
              [
                -74.0122973149255,
                40.69256825483513
              ],
              [
                -74.01229783565219,
                40.69256814655674
              ],
              [
                -74.01233661589079,
                40.69255989385955
              ],
              [
                -74.01237989918387,
                40.69253216208628
              ],
              [
                -74.01240885439651,
                40.69249357187864
              ],
              [
                -74.0124088889432,
                40.69249352748012
              ],
              [
                -74.01252289827039,
                40.69233903241466
              ],
              [
                -74.01252307563139,
                40.69233884241937
              ],
              [
                -74.01253368614768,
                40.69232805434204
              ],
              [
                -74.01254543217101,
                40.69231865108907
              ],
              [
                -74.01255829038229,
                40.69231068878434
              ],
              [
                -74.01257261595585,
                40.69230385277201
              ],
              [
                -74.01258803080069,
                40.6922983121283
              ],
              [
                -74.01260406086601,
                40.692294240965545
              ],
              [
                -74.01272612664187,
                40.692271335581886
              ],
              [
                -74.0128533381957,
                40.69224524345787
              ],
              [
                -74.01305857042325,
                40.69220153624106
              ],
              [
                -74.01308076367873,
                40.692194878694266
              ],
              [
                -74.0131275881807,
                40.6921850043228
              ],
              [
                -74.0132564793452,
                40.692160882186194
              ],
              [
                -74.01329253788359,
                40.692152143650446
              ],
              [
                -74.01337499385887,
                40.692129144477825
              ],
              [
                -74.01341527571654,
                40.69212021493395
              ],
              [
                -74.01341855282882,
                40.692119755024166
              ],
              [
                -74.01342192440899,
                40.69211953014427
              ],
              [
                -74.0134289639662,
                40.692119825678986
              ],
              [
                -74.01343629886243,
                40.692121076587725
              ],
              [
                -74.01344381222603,
                40.69212327316733
              ],
              [
                -74.01345142020139,
                40.692126414925035
              ],
              [
                -74.01345880318475,
                40.69213036821137
              ],
              [
                -74.01346559490221,
                40.692134950631726
              ],
              [
                -74.0134656664421,
                40.69213499619091
              ],
              [
                -74.01411196509743,
                40.6927341374772
              ],
              [
                -74.01413296148374,
                40.692759534774815
              ],
              [
                -74.01415791009549,
                40.6927827688139
              ],
              [
                -74.01418643053366,
                40.69280348533486
              ],
              [
                -74.01422123084896,
                40.692822906344
              ],
              [
                -74.01515612159491,
                40.69301551489164
              ],
              [
                -74.01502946389674,
                40.693146079734866
              ],
              [
                -74.0149785911563,
                40.69311566557116
              ],
              [
                -74.0149313669649,
                40.69315577223661
              ],
              [
                -74.01512395634913,
                40.693258069299695
              ],
              [
                -74.01516209441293,
                40.69320828569578
              ],
              [
                -74.01512030609705,
                40.693187550632885
              ],
              [
                -74.01525951035647,
                40.69303681423183
              ],
              [
                -74.01530915985025,
                40.693047042891514
              ],
              [
                -74.01525474322258,
                40.693206891183976
              ],
              [
                -74.01518934209336,
                40.693198603542925
              ],
              [
                -74.01517482202624,
                40.693256680335395
              ],
              [
                -74.01538919768244,
                40.693298134665504
              ],
              [
                -74.01540916665937,
                40.693238673173134
              ],
              [
                -74.01535466389976,
                40.69322485341719
              ],
              [
                -74.01540359141636,
                40.69306649730916
              ],
              [
                -74.01674756096064,
                40.693343368217555
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000113616826567,
        "objectid": 106,
        "shape_leng": 0.0492110119798,
        "location_id": 106,
        "zone": "Gowanus",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.98610388199985,
                40.681008521999956
              ],
              [
                -73.98653302999993,
                40.680372638999884
              ],
              [
                -73.98729306099992,
                40.68066969799988
              ],
              [
                -73.9876680399999,
                40.68081780999993
              ],
              [
                -73.98808029299991,
                40.68017216599986
              ],
              [
                -73.98850509899981,
                40.6795371569999
              ],
              [
                -73.98897369299986,
                40.67884987699995
              ],
              [
                -73.98931813699981,
                40.67834938899991
              ],
              [
                -73.98931805499996,
                40.67825393999993
              ],
              [
                -73.98940896399986,
                40.67745451699991
              ],
              [
                -73.98986566199996,
                40.676826601999934
              ],
              [
                -73.99036651399997,
                40.676218320999965
              ],
              [
                -73.99069861899994,
                40.67580741899994
              ],
              [
                -73.99256575599999,
                40.67610581999992
              ],
              [
                -73.99279927099997,
                40.67613190599996
              ],
              [
                -73.99280910199987,
                40.676148749999854
              ],
              [
                -73.9931237449998,
                40.67617871399996
              ],
              [
                -73.99317836299984,
                40.676161657999906
              ],
              [
                -73.9933342719999,
                40.67617601299986
              ],
              [
                -73.993491024,
                40.67618330199986
              ],
              [
                -73.99364806799993,
                40.676183498999926
              ],
              [
                -73.99380485099994,
                40.676176602999895
              ],
              [
                -73.99396082100002,
                40.67616263899986
              ],
              [
                -73.9940579759999,
                40.676182783999884
              ],
              [
                -73.99420802699993,
                40.67615242699987
              ],
              [
                -73.99435579199994,
                40.67611614799993
              ],
              [
                -73.99450491199987,
                40.67607277499994
              ],
              [
                -73.99465075899997,
                40.676023369999925
              ],
              [
                -73.99479291199987,
                40.67596807599988
              ],
              [
                -73.99493096199983,
                40.67590705299988
              ],
              [
                -73.99506451099982,
                40.675840474999845
              ],
              [
                -73.99520266299999,
                40.67572952499992
              ],
              [
                -73.99540989400003,
                40.675563096999944
              ],
              [
                -73.99572187999998,
                40.67522229299991
              ],
              [
                -73.9959621319999,
                40.674968223999926
              ],
              [
                -73.99666043499988,
                40.67414278099988
              ],
              [
                -73.99696673299994,
                40.673784159999876
              ],
              [
                -73.99717696100001,
                40.67352829399994
              ],
              [
                -73.99745115699996,
                40.67319456799989
              ],
              [
                -73.99805741699998,
                40.6724695449999
              ],
              [
                -73.99812844699997,
                40.67244074999998
              ],
              [
                -73.99847134099994,
                40.672059100999874
              ],
              [
                -73.99855090599986,
                40.67192594199986
              ],
              [
                -73.9986188799999,
                40.67183017199988
              ],
              [
                -73.99845678099994,
                40.671568042999894
              ],
              [
                -73.99822510099992,
                40.67120286799991
              ],
              [
                -73.99798931599987,
                40.67084208199993
              ],
              [
                -73.99787130000004,
                40.670661499999945
              ],
              [
                -73.99771474299992,
                40.67041544799985
              ],
              [
                -73.99764875199986,
                40.670311734999906
              ],
              [
                -73.99757732099995,
                40.67019948399994
              ],
              [
                -73.99696465099986,
                40.66923454899992
              ],
              [
                -73.9969179149999,
                40.669153483999914
              ],
              [
                -73.99667191699996,
                40.66873916799992
              ],
              [
                -73.99659742999994,
                40.668811927999855
              ],
              [
                -73.99648142699999,
                40.668925235999936
              ],
              [
                -73.99616566099998,
                40.668435497999944
              ],
              [
                -73.99585129199981,
                40.667972495999884
              ],
              [
                -73.99507117699983,
                40.66707573199994
              ],
              [
                -73.99490350199991,
                40.66678543499988
              ],
              [
                -73.99284786,
                40.66555439199989
              ],
              [
                -73.99277080699981,
                40.665507280999876
              ],
              [
                -73.9922346229999,
                40.66613762499993
              ],
              [
                -73.99173365199998,
                40.666736594999875
              ],
              [
                -73.99123399599989,
                40.6673356529999
              ],
              [
                -73.99072558999995,
                40.6679461729999
              ],
              [
                -73.99022252199988,
                40.66854809799991
              ],
              [
                -73.98972203499986,
                40.66914780699988
              ],
              [
                -73.98921685399992,
                40.66975347499993
              ],
              [
                -73.98907079799996,
                40.66992861999994
              ],
              [
                -73.9890700769999,
                40.669929483999915
              ],
              [
                -73.988713226,
                40.67035690699995
              ],
              [
                -73.98821210799997,
                40.67096081399996
              ],
              [
                -73.98771575999984,
                40.67155538099996
              ],
              [
                -73.98744496399998,
                40.67188008599991
              ],
              [
                -73.98721100499988,
                40.67216061599988
              ],
              [
                -73.98670905399989,
                40.672766732999904
              ],
              [
                -73.98570017199982,
                40.673974832999875
              ],
              [
                -73.98520256000002,
                40.67457380999993
              ],
              [
                -73.9846987299998,
                40.67517562999993
              ],
              [
                -73.98420294599991,
                40.67577119999986
              ],
              [
                -73.98390048699991,
                40.67614865299993
              ],
              [
                -73.98347361499994,
                40.67677916099998
              ],
              [
                -73.98304811899982,
                40.67740668599986
              ],
              [
                -73.98261725399998,
                40.67804242999993
              ],
              [
                -73.98218939700001,
                40.67867580399994
              ],
              [
                -73.98175642299985,
                40.67931462399988
              ],
              [
                -73.98185715599982,
                40.67935261499986
              ],
              [
                -73.98413878099997,
                40.680245370999934
              ],
              [
                -73.98610388199985,
                40.681008521999956
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0000746112192675,
        "objectid": 107,
        "shape_leng": 0.0380413645908,
        "location_id": 107,
        "zone": "Gramercy",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.98238628799993,
                40.73141124499994
              ],
              [
                -73.98232282599996,
                40.73149788999992
              ],
              [
                -73.98196782199994,
                40.7319825609999
              ],
              [
                -73.98180339299985,
                40.73220479799991
              ],
              [
                -73.98149910399985,
                40.732618595999924
              ],
              [
                -73.98105323699987,
                40.73320848199997
              ],
              [
                -73.980644989,
                40.733779319999904
              ],
              [
                -73.98041991299985,
                40.73408982099988
              ],
              [
                -73.98020496200002,
                40.73438532199987
              ],
              [
                -73.98009050499998,
                40.734544370999906
              ],
              [
                -73.97978904000004,
                40.734961816999906
              ],
              [
                -73.97944606000004,
                40.73541451499994
              ],
              [
                -73.9793481959999,
                40.73555311199989
              ],
              [
                -73.97890917399987,
                40.73616412599992
              ],
              [
                -73.97857449799984,
                40.736677280999864
              ],
              [
                -73.97849845599995,
                40.73679095699989
              ],
              [
                -73.97805226499996,
                40.7375539539999
              ],
              [
                -73.97891574499987,
                40.737917658999876
              ],
              [
                -73.97949062699986,
                40.73815979199986
              ],
              [
                -73.98041832999985,
                40.73855053099987
              ],
              [
                -73.98266497499989,
                40.73949733299986
              ],
              [
                -73.98221134499993,
                40.7401145349999
              ],
              [
                -73.98176150799992,
                40.740733112999905
              ],
              [
                -73.98131180499988,
                40.74135092399991
              ],
              [
                -73.98085965399993,
                40.741969767999905
              ],
              [
                -73.98245520799999,
                40.74264695999995
              ],
              [
                -73.98400852,
                40.74329901799996
              ],
              [
                -73.98407485299994,
                40.7433247179999
              ],
              [
                -73.98452636699996,
                40.7427070419999
              ],
              [
                -73.98497775499983,
                40.742088956999964
              ],
              [
                -73.98542767799994,
                40.74147034899988
              ],
              [
                -73.98587913199991,
                40.74085299999996
              ],
              [
                -73.98636141699991,
                40.74018480099993
              ],
              [
                -73.9868491859999,
                40.73952182199985
              ],
              [
                -73.98703592799987,
                40.7392663789999
              ],
              [
                -73.98730154899981,
                40.73890302599993
              ],
              [
                -73.98774074499983,
                40.738312187999945
              ],
              [
                -73.98817910299996,
                40.73773074899986
              ],
              [
                -73.98859298399994,
                40.737142560999935
              ],
              [
                -73.98901559399987,
                40.73656015399992
              ],
              [
                -73.98920953899987,
                40.73630712099986
              ],
              [
                -73.98945472099987,
                40.73598724699991
              ],
              [
                -73.989874214,
                40.73540825799992
              ],
              [
                -73.98991689999981,
                40.73534934199992
              ],
              [
                -73.98994254999981,
                40.73527697099994
              ],
              [
                -73.98996152699992,
                40.73520341999989
              ],
              [
                -73.98997373899982,
                40.735129042999894
              ],
              [
                -73.9899791279999,
                40.73505420099984
              ],
              [
                -73.98997766699992,
                40.73497925499992
              ],
              [
                -73.98996936399989,
                40.73490456699995
              ],
              [
                -73.98990295999991,
                40.7344347899999
              ],
              [
                -73.98877931699997,
                40.733965399999924
              ],
              [
                -73.98718049699994,
                40.73329214799988
              ],
              [
                -73.98494102799992,
                40.73234760399986
              ],
              [
                -73.98255629299985,
                40.7313501339999
              ],
              [
                -73.98238628799993,
                40.73141124499994
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000352866009465,
        "objectid": 108,
        "shape_leng": 0.11117141227,
        "location_id": 108,
        "zone": "Gravesend",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.97148350899987,
                40.59717158499997
              ],
              [
                -73.97323983499986,
                40.59697881799985
              ],
              [
                -73.9740839059999,
                40.596882943999965
              ],
              [
                -73.97492695599999,
                40.596793537999936
              ],
              [
                -73.97503940399993,
                40.59678002699996
              ],
              [
                -73.97558819299998,
                40.59671920599991
              ],
              [
                -73.97650934500001,
                40.59661610899985
              ],
              [
                -73.97750674199996,
                40.596506452999904
              ],
              [
                -73.9785016919999,
                40.596394852999914
              ],
              [
                -73.97890571399992,
                40.596352729999914
              ],
              [
                -73.9794258679999,
                40.59629752299988
              ],
              [
                -73.98035785499995,
                40.59619154099992
              ],
              [
                -73.98014301299985,
                40.595066175999904
              ],
              [
                -73.97995443499985,
                40.5940738119999
              ],
              [
                -73.98071099999997,
                40.59399739599998
              ],
              [
                -73.98108086699995,
                40.59395289099993
              ],
              [
                -73.98179084800002,
                40.593867455999906
              ],
              [
                -73.98273346399986,
                40.593763698999915
              ],
              [
                -73.98303296899982,
                40.59373029299993
              ],
              [
                -73.9834412439999,
                40.59368777299988
              ],
              [
                -73.98354622399994,
                40.59367650999993
              ],
              [
                -73.98366766399984,
                40.59366348199994
              ],
              [
                -73.98407040399994,
                40.59361736399989
              ],
              [
                -73.98412888499986,
                40.59361066699993
              ],
              [
                -73.98463965399982,
                40.59355217599989
              ],
              [
                -73.98467794099993,
                40.59365864999992
              ],
              [
                -73.98601936799993,
                40.59444604899989
              ],
              [
                -73.98798856399989,
                40.592552777999856
              ],
              [
                -73.98962150099992,
                40.59098199299996
              ],
              [
                -73.99036425099996,
                40.591431931999935
              ],
              [
                -73.99066311999997,
                40.5911671669999
              ],
              [
                -73.99147342099985,
                40.590449305999925
              ],
              [
                -73.99153934399993,
                40.59039090299989
              ],
              [
                -73.99161412399994,
                40.59035644499994
              ],
              [
                -73.99255747799991,
                40.589921674999914
              ],
              [
                -73.99286067699995,
                40.58978269799996
              ],
              [
                -73.99291591599997,
                40.58975737899986
              ],
              [
                -73.99297835999997,
                40.58973003299997
              ],
              [
                -73.99330855,
                40.58958389899994
              ],
              [
                -73.99375761899992,
                40.59033184399987
              ],
              [
                -73.99801867061471,
                40.588425400020526
              ],
              [
                -73.9979338240619,
                40.58839928295165
              ],
              [
                -73.99788311275508,
                40.58834343796133
              ],
              [
                -74.00038207107852,
                40.587133057174704
              ],
              [
                -74.00048912050953,
                40.58704285506199
              ],
              [
                -74.00046948087478,
                40.58694304876061
              ],
              [
                -74.00042529438697,
                40.5866924315609
              ],
              [
                -74.0004327749314,
                40.58654459492157
              ],
              [
                -74.0003736889094,
                40.58647481463
              ],
              [
                -74.00031734303268,
                40.58645333502331
              ],
              [
                -74.00023845644195,
                40.58647051937003
              ],
              [
                -74.00016520656311,
                40.586504884962544
              ],
              [
                -74.000108860195,
                40.5865607290967
              ],
              [
                -74.00010322572263,
                40.58659079894539
              ],
              [
                -74.00011449538408,
                40.58695164090263
              ],
              [
                -73.99534737661207,
                40.58933138044001
              ],
              [
                -73.99490226050742,
                40.588802987503094
              ],
              [
                -73.99487677695375,
                40.58877174667522
              ],
              [
                -73.99515584195441,
                40.58862687289017
              ],
              [
                -73.99555029368085,
                40.58843358128158
              ],
              [
                -73.99555029453957,
                40.58842069392086
              ],
              [
                -73.99797893506641,
                40.58731246396774
              ],
              [
                -73.99796055106226,
                40.58727742354106
              ],
              [
                -73.99795639739294,
                40.58726950636562
              ],
              [
                -73.99777708135538,
                40.587353237551554
              ],
              [
                -73.9955277563281,
                40.58840351006565
              ],
              [
                -73.99552212251818,
                40.58839062298807
              ],
              [
                -73.99509949490815,
                40.58859250504381
              ],
              [
                -73.99505684930178,
                40.58856109833817
              ],
              [
                -73.99515490404112,
                40.588514893739465
              ],
              [
                -73.99513351269499,
                40.58848091554354
              ],
              [
                -73.99556316619993,
                40.58827707219872
              ],
              [
                -73.99555247167991,
                40.58826483929656
              ],
              [
                -73.99549007351672,
                40.58829745499158
              ],
              [
                -73.99548294316648,
                40.588285222725304
              ],
              [
                -73.99534566885501,
                40.58834501717449
              ],
              [
                -73.99533140797436,
                40.588330066781644
              ],
              [
                -73.99497484847483,
                40.58850537201591
              ],
              [
                -73.99421554608305,
                40.588868530482756
              ],
              [
                -73.9940790462178,
                40.58869344199059
              ],
              [
                -73.9956605451227,
                40.587936207343844
              ],
              [
                -73.99559857410351,
                40.58784170755412
              ],
              [
                -73.99423502922512,
                40.58843870064835
              ],
              [
                -73.9941730563837,
                40.5883828578208
              ],
              [
                -73.99623653794225,
                40.587421255879605
              ],
              [
                -73.99814530448992,
                40.586531693907126
              ],
              [
                -73.99821854784219,
                40.586510218640676
              ],
              [
                -73.99828052442537,
                40.58651021958487
              ],
              [
                -73.99863826384758,
                40.58649162946084
              ],
              [
                -73.99877633053215,
                40.58648445338663
              ],
              [
                -73.9989735281156,
                40.58642861560582
              ],
              [
                -73.99935665157753,
                40.58638136941568
              ],
              [
                -73.99956511570304,
                40.58624392009571
              ],
              [
                -73.99963423823505,
                40.586069747433356
              ],
              [
                -73.99952567990363,
                40.58575854465988
              ],
              [
                -73.9997003362606,
                40.58575424962765
              ],
              [
                -73.99970597099518,
                40.58568981985855
              ],
              [
                -73.99946933901515,
                40.58568981900934
              ],
              [
                -73.99928905009463,
                40.58555236605288
              ],
              [
                -73.9986711016601,
                40.58550701321541
              ],
              [
                -73.99828673553586,
                40.5854830629867
              ],
              [
                -73.998088991884,
                40.58547074076329
              ],
              [
                -73.99808523776004,
                40.585364721581485
              ],
              [
                -73.99826365291963,
                40.58531181562509
              ],
              [
                -73.99846084611694,
                40.58528604638742
              ],
              [
                -73.99860169877729,
                40.58523020847002
              ],
              [
                -73.99878762307108,
                40.58515289393331
              ],
              [
                -73.99898481729464,
                40.585015445530836
              ],
              [
                -73.99915383954428,
                40.58494242587021
              ],
              [
                -73.99933976309926,
                40.584860814817205
              ],
              [
                -73.99945807926105,
                40.584753431616456
              ],
              [
                -73.99953132177025,
                40.58470188733625
              ],
              [
                -73.99963273341196,
                40.58463316229419
              ],
              [
                -73.99973977980433,
                40.58457732286521
              ],
              [
                -73.99985809444573,
                40.58454296095274
              ],
              [
                -73.99998204289255,
                40.58443128159514
              ],
              [
                -74.00010035579987,
                40.58437544199798
              ],
              [
                -74.00024683907247,
                40.58426376283368
              ],
              [
                -74.00035951790473,
                40.584186445880405
              ],
              [
                -74.00043685358045,
                40.58404896948653
              ],
              [
                -74.00041739503702,
                40.58354592150335
              ],
              [
                -74.0003088081067,
                40.583177037514695
              ],
              [
                -74.00017922859408,
                40.58307395074768
              ],
              [
                -73.99781301710898,
                40.58261003108302
              ],
              [
                -73.99739612012652,
                40.5824296169996
              ],
              [
                -73.99672570402983,
                40.58225349057937
              ],
              [
                -73.99622429809615,
                40.58220193058146
              ],
              [
                -73.99605528820514,
                40.58212890385413
              ],
              [
                -73.99589671851147,
                40.582157018783946
              ],
              [
                -73.99558861741181,
                40.582219806273436
              ],
              [
                -73.99555058236467,
                40.58221169424263
              ],
              [
                -73.99551132212923,
                40.58220841465109
              ],
              [
                -73.99547188560318,
                40.582210055118026
              ],
              [
                -73.99543332639404,
                40.5822165718157
              ],
              [
                -73.9953966746708,
                40.582227790640644
              ],
              [
                -73.99536290964227,
                40.582243411864496
              ],
              [
                -73.99533293339671,
                40.58226301814178
              ],
              [
                -73.99530754680039,
                40.58228608565947
              ],
              [
                -73.99528742810297,
                40.58231199813168
              ],
              [
                -73.99527311481427,
                40.58234006326417
              ],
              [
                -73.99523926374887,
                40.58237289141351
              ],
              [
                -73.99519885117982,
                40.58240107716287
              ],
              [
                -73.99515295656298,
                40.58242386764041
              ],
              [
                -73.9951028057891,
                40.582440654087556
              ],
              [
                -73.99504973843936,
                40.58245098811911
              ],
              [
                -73.99499517200287,
                40.58245459370131
              ],
              [
                -73.99494925752198,
                40.58246152316033
              ],
              [
                -73.99490532896193,
                40.58247384111095
              ],
              [
                -73.9948644670117,
                40.58249124451883
              ],
              [
                -73.99482767692096,
                40.58251330524204
              ],
              [
                -73.99479586376927,
                40.5825394805631
              ],
              [
                -73.99476981020157,
                40.58256912654065
              ],
              [
                -73.99473180833827,
                40.58259741623284
              ],
              [
                -73.99468833671246,
                40.58262068128624
              ],
              [
                -73.9946405256929,
                40.58263831674982
              ],
              [
                -73.99458961848661,
                40.58264986405607
              ],
              [
                -73.99453693881253,
                40.582655022944856
              ],
              [
                -73.99436297403963,
                40.58270910572782
              ],
              [
                -73.99419222120069,
                40.582768843324835
              ],
              [
                -73.99402499633493,
                40.58283412517319
              ],
              [
                -73.99386160895484,
                40.582904830448165
              ],
              [
                -73.9937023614738,
                40.58298082828729
              ],
              [
                -73.99354754864491,
                40.58306197803178
              ],
              [
                -73.99339745701728,
                40.58314812948715
              ],
              [
                -73.99325236440465,
                40.583239123201075
              ],
              [
                -73.99321504197076,
                40.583245872673544
              ],
              [
                -73.99317951603533,
                40.58325690118909
              ],
              [
                -73.99314662510577,
                40.58327194844545
              ],
              [
                -73.99311714549789,
                40.58329065928717
              ],
              [
                -73.99311056904914,
                40.58330835639696
              ],
              [
                -73.99310009404569,
                40.58332492585561
              ],
              [
                -73.99308603338487,
                40.58333987271511
              ],
              [
                -73.99306880707216,
                40.583352750496296
              ],
              [
                -73.99304892967514,
                40.583363174525154
              ],
              [
                -73.99302699495362,
                40.58337083342383
              ],
              [
                -73.9929769901788,
                40.58338370500899
              ],
              [
                -73.99292971084552,
                40.58340158790911
              ],
              [
                -73.99288601634852,
                40.5834241570679
              ],
              [
                -73.99284670092435,
                40.583451002247294
              ],
              [
                -73.99281398707836,
                40.58349230172691
              ],
              [
                -73.99277367838121,
                40.583529494172915
              ],
              [
                -73.99272664752623,
                40.58356177434858
              ],
              [
                -73.99267391275022,
                40.583588443369436
              ],
              [
                -73.99097612298841,
                40.58435003081
              ],
              [
                -73.99026244714351,
                40.5846191482053
              ],
              [
                -73.99029638955085,
                40.584565996861826
              ],
              [
                -73.99032293350066,
                40.584510452484004
              ],
              [
                -73.99034179385924,
                40.58445311176603
              ],
              [
                -73.99035276803677,
                40.58439459069805
              ],
              [
                -73.99035573816278,
                40.58433551794846
              ],
              [
                -73.99035067235161,
                40.58427652811083
              ],
              [
                -73.99033762504436,
                40.58421825488589
              ],
              [
                -73.99169553014772,
                40.581858503278895
              ],
              [
                -73.99182529575866,
                40.581738520302736
              ],
              [
                -73.99185535656628,
                40.58162397854611
              ],
              [
                -73.99182532449969,
                40.58150370686699
              ],
              [
                -73.99173078128501,
                40.581486505047906
              ],
              [
                -73.99168261881185,
                40.581383427077085
              ],
              [
                -73.99174273416997,
                40.58120016258747
              ],
              [
                -73.99163007472373,
                40.58107988438188
              ],
              [
                -73.99119440796728,
                40.581028307699384
              ],
              [
                -73.98995504960881,
                40.58066166976562
              ],
              [
                -73.98971762499994,
                40.58083822699989
              ],
              [
                -73.98928724999996,
                40.58195758299994
              ],
              [
                -73.98888884999998,
                40.58256733499994
              ],
              [
                -73.98874850099989,
                40.58282032299994
              ],
              [
                -73.98852399299993,
                40.58314353499986
              ],
              [
                -73.98829948299982,
                40.58346674699988
              ],
              [
                -73.98730893199986,
                40.58359445299993
              ],
              [
                -73.98662350499995,
                40.58367393699994
              ],
              [
                -73.98654457099988,
                40.583658590999946
              ],
              [
                -73.98660338299985,
                40.58376512099989
              ],
              [
                -73.98679577199978,
                40.58411362799992
              ],
              [
                -73.98683590499999,
                40.58418633099989
              ],
              [
                -73.98687540199994,
                40.584251587999866
              ],
              [
                -73.98710120299997,
                40.58464459199989
              ],
              [
                -73.98701022999991,
                40.58462626699987
              ],
              [
                -73.98671582299981,
                40.58456849999991
              ],
              [
                -73.98627573699999,
                40.58447998099991
              ],
              [
                -73.9858605929999,
                40.58452571299991
              ],
              [
                -73.98548157999991,
                40.584519676999925
              ],
              [
                -73.98492731,
                40.58459595199986
              ],
              [
                -73.98482192899988,
                40.58416852299991
              ],
              [
                -73.983875933,
                40.58397574499992
              ],
              [
                -73.98281157699994,
                40.58379238799989
              ],
              [
                -73.98175452099993,
                40.583583762999865
              ],
              [
                -73.98044790899985,
                40.58341064299993
              ],
              [
                -73.98006567999988,
                40.58331786399986
              ],
              [
                -73.97742525299988,
                40.58290659299994
              ],
              [
                -73.97468194399983,
                40.58312411799989
              ],
              [
                -73.97461091899993,
                40.58312987599992
              ],
              [
                -73.97449712999993,
                40.5831533899999
              ],
              [
                -73.97308506499995,
                40.5834298259999
              ],
              [
                -73.97174499699994,
                40.58366274899988
              ],
              [
                -73.970846422,
                40.583820214999946
              ],
              [
                -73.97045373499985,
                40.583892278999876
              ],
              [
                -73.970055463,
                40.583947729999856
              ],
              [
                -73.96985445599992,
                40.58396698299987
              ],
              [
                -73.96899979299988,
                40.58406350399991
              ],
              [
                -73.96781584999998,
                40.58453905099991
              ],
              [
                -73.96684429799988,
                40.584569328999876
              ],
              [
                -73.96670868800001,
                40.58456908299991
              ],
              [
                -73.96661531499983,
                40.58457103699992
              ],
              [
                -73.96660079799997,
                40.5846417419999
              ],
              [
                -73.96653588899984,
                40.584957806999974
              ],
              [
                -73.96649796199982,
                40.585142492999886
              ],
              [
                -73.96643740799989,
                40.58541469399988
              ],
              [
                -73.96636486799986,
                40.585751243999916
              ],
              [
                -73.96633012800002,
                40.58589875099992
              ],
              [
                -73.96615022799989,
                40.58666882399992
              ],
              [
                -73.96597498399979,
                40.58743213599997
              ],
              [
                -73.96581402699984,
                40.58813321799995
              ],
              [
                -73.9656408479998,
                40.58888742799993
              ],
              [
                -73.9654650069999,
                40.58964333699986
              ],
              [
                -73.96530164399988,
                40.590358229999936
              ],
              [
                -73.96514385199995,
                40.5911019159999
              ],
              [
                -73.9652882789999,
                40.59108660999993
              ],
              [
                -73.96542523899981,
                40.59107142799992
              ],
              [
                -73.96651951199982,
                40.590952872999885
              ],
              [
                -73.96744527499995,
                40.59085120999989
              ],
              [
                -73.96787702199987,
                40.590803756999954
              ],
              [
                -73.96837099999982,
                40.59074946599988
              ],
              [
                -73.96929959299999,
                40.590647588999964
              ],
              [
                -73.97022337700001,
                40.590540175999934
              ],
              [
                -73.97115101799994,
                40.590442443999905
              ],
              [
                -73.97128748899985,
                40.591159501999876
              ],
              [
                -73.97141847599984,
                40.591838509999924
              ],
              [
                -73.97155530499995,
                40.59256045799991
              ],
              [
                -73.97178220299999,
                40.59375608399992
              ],
              [
                -73.97189684799982,
                40.594352510999904
              ],
              [
                -73.97176509099998,
                40.59511141099989
              ],
              [
                -73.97169554999981,
                40.59548854199992
              ],
              [
                -73.97153324499989,
                40.59636870899992
              ],
              [
                -73.97132951099988,
                40.59635243099994
              ],
              [
                -73.97148350899987,
                40.59717158499997
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.00116960076185,
        "objectid": 109,
        "shape_leng": 0.178267819599,
        "location_id": 109,
        "zone": "Great Kills",
        "borough": "Staten Island"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -74.14706472699994,
                40.5705086149999
              ],
              [
                -74.14713603399994,
                40.57051798899991
              ],
              [
                -74.1472057429999,
                40.57053278099995
              ],
              [
                -74.14789716899996,
                40.57088728599995
              ],
              [
                -74.14983771799994,
                40.569358262999856
              ],
              [
                -74.15033299399995,
                40.5689310299999
              ],
              [
                -74.15055139699999,
                40.56874813699989
              ],
              [
                -74.1507746989999,
                40.56856871799991
              ],
              [
                -74.1510028049999,
                40.568392848999935
              ],
              [
                -74.15123561999997,
                40.56822060499991
              ],
              [
                -74.15147304199998,
                40.56805205899985
              ],
              [
                -74.15171497199997,
                40.56788728399988
              ],
              [
                -74.15228114599992,
                40.56739294699985
              ],
              [
                -74.15295627699996,
                40.56677601699994
              ],
              [
                -74.15357985599994,
                40.56625318599991
              ],
              [
                -74.1541634739999,
                40.56583667599995
              ],
              [
                -74.15434186999994,
                40.565742096999905
              ],
              [
                -74.15450973599995,
                40.5656339799999
              ],
              [
                -74.154664477,
                40.56551344299989
              ],
              [
                -74.15480382999996,
                40.56538193999996
              ],
              [
                -74.15492589699993,
                40.56524125699989
              ],
              [
                -74.15502927899995,
                40.565093413999904
              ],
              [
                -74.15511310499993,
                40.56494054499992
              ],
              [
                -74.15585090399995,
                40.56377444399988
              ],
              [
                -74.15675091099989,
                40.562886974999905
              ],
              [
                -74.1568588409999,
                40.5627890939999
              ],
              [
                -74.15685888899992,
                40.562789056999925
              ],
              [
                -74.15685893499987,
                40.562789021999926
              ],
              [
                -74.15698080799989,
                40.56269986499994
              ],
              [
                -74.15698100799993,
                40.562699720999944
              ],
              [
                -74.15698121999995,
                40.56269959399989
              ],
              [
                -74.15711531399992,
                40.562620740999876
              ],
              [
                -74.15711540799994,
                40.5626206869999
              ],
              [
                -74.15711551399995,
                40.562620641999935
              ],
              [
                -74.15725961299992,
                40.56255338299994
              ],
              [
                -74.15725974199995,
                40.5625533189999
              ],
              [
                -74.15725989499994,
                40.562553263999895
              ],
              [
                -74.15741136799991,
                40.5624985659999
              ],
              [
                -74.1582578419999,
                40.562201976999845
              ],
              [
                -74.15915791699992,
                40.561935833999925
              ],
              [
                -74.1600564059999,
                40.561705499999924
              ],
              [
                -74.16096801899997,
                40.56146020199987
              ],
              [
                -74.1615313009999,
                40.56126935399991
              ],
              [
                -74.16193293199993,
                40.56111113299994
              ],
              [
                -74.16272123599997,
                40.56077419499998
              ],
              [
                -74.16377941599991,
                40.560316979999875
              ],
              [
                -74.1649414939999,
                40.56011179699992
              ],
              [
                -74.16589404599995,
                40.560283361999915
              ],
              [
                -74.16688971999996,
                40.56047660499998
              ],
              [
                -74.16982582400001,
                40.56109042099992
              ],
              [
                -74.16983437299987,
                40.56094954399989
              ],
              [
                -74.16983351999993,
                40.56080851799989
              ],
              [
                -74.16982326899996,
                40.560667706999894
              ],
              [
                -74.16980364499997,
                40.560527475999876
              ],
              [
                -74.16977469899996,
                40.56038818799988
              ],
              [
                -74.16973650699997,
                40.56025020099991
              ],
              [
                -74.16947719799997,
                40.55912667199987
              ],
              [
                -74.16931680999987,
                40.558409781999856
              ],
              [
                -74.1691669539999,
                40.55770084699986
              ],
              [
                -74.16900167899992,
                40.55697216799992
              ],
              [
                -74.16884272899996,
                40.55626910699992
              ],
              [
                -74.16868441299991,
                40.55556758499988
              ],
              [
                -74.16852396199995,
                40.5548519379999
              ],
              [
                -74.16836706799991,
                40.554131592999944
              ],
              [
                -74.16820548500002,
                40.553431902999904
              ],
              [
                -74.16810265799997,
                40.55294619199987
              ],
              [
                -74.16805222599993,
                40.55271771199993
              ],
              [
                -74.1678946309999,
                40.5520010059999
              ],
              [
                -74.16786573399993,
                40.551889426999885
              ],
              [
                -74.16776724799992,
                40.55143807299988
              ],
              [
                -74.16773525199996,
                40.551291459999916
              ],
              [
                -74.1675722339999,
                40.550574733999916
              ],
              [
                -74.16741722299999,
                40.54988134599995
              ],
              [
                -74.1672586319999,
                40.54916666599995
              ],
              [
                -74.16709947799988,
                40.54844736699987
              ],
              [
                -74.167103286,
                40.54837591999994
              ],
              [
                -74.16710008899994,
                40.548304454999865
              ],
              [
                -74.16708990499995,
                40.54823337199992
              ],
              [
                -74.16707279199991,
                40.54816306699996
              ],
              [
                -74.16704884399992,
                40.54809393299991
              ],
              [
                -74.16701819499993,
                40.548026356999884
              ],
              [
                -74.16698101599997,
                40.54796071499987
              ],
              [
                -74.16693751599995,
                40.547897373999895
              ],
              [
                -74.1668879359999,
                40.54783668799997
              ],
              [
                -74.16683255499997,
                40.54777899599993
              ],
              [
                -74.16623327399996,
                40.54734247899988
              ],
              [
                -74.16619699799993,
                40.54711041099995
              ],
              [
                -74.16574238499992,
                40.54539770499993
              ],
              [
                -74.16548821099998,
                40.54465254199989
              ],
              [
                -74.16540080799992,
                40.54443272299991
              ],
              [
                -74.16526055199988,
                40.54423960899986
              ],
              [
                -74.16478748999995,
                40.54380210999992
              ],
              [
                -74.16423140999989,
                40.543301158999924
              ],
              [
                -74.16359221499997,
                40.542642145999885
              ],
              [
                -74.16252750299994,
                40.54150270699991
              ],
              [
                -74.16102399199994,
                40.5398977589999
              ],
              [
                -74.15955749199995,
                40.53833428299991
              ],
              [
                -74.15914106399993,
                40.537898644999935
              ],
              [
                -74.158536138,
                40.537252883999905
              ],
              [
                -74.1584155779999,
                40.53710667899988
              ],
              [
                -74.15828813799993,
                40.5369639119999
              ],
              [
                -74.15815398399998,
                40.53682476999991
              ],
              [
                -74.15801329499999,
                40.53668943899986
              ],
              [
                -74.15786625499994,
                40.536558094999855
              ],
              [
                -74.15771305999992,
                40.53643091299995
              ],
              [
                -74.15755391199993,
                40.53630806099994
              ],
              [
                -74.15738901899991,
                40.53618969999996
              ],
              [
                -74.15721860099994,
                40.53607598699994
              ],
              [
                -74.15704288199998,
                40.53596707199992
              ],
              [
                -74.15593326499992,
                40.53535375699992
              ],
              [
                -74.15404693799991,
                40.53430845699992
              ],
              [
                -74.15397419799994,
                40.53426814799993
              ],
              [
                -74.15415844299997,
                40.53407479199994
              ],
              [
                -74.15438619599998,
                40.53383715099993
              ],
              [
                -74.15447193999992,
                40.53374918899989
              ],
              [
                -74.15502905699995,
                40.533160203999884
              ],
              [
                -74.15553157299992,
                40.5326308329999
              ],
              [
                -74.15602014099987,
                40.5321150939999
              ],
              [
                -74.1566887699999,
                40.53142883599987
              ],
              [
                -74.15679305199991,
                40.531341828999864
              ],
              [
                -74.15690813799995,
                40.531262027999915
              ],
              [
                -74.15703269699993,
                40.53119055399988
              ],
              [
                -74.15716510199995,
                40.53112831799989
              ],
              [
                -74.15730358599997,
                40.53107601399996
              ],
              [
                -74.15851066999988,
                40.530565689999904
              ],
              [
                -74.16037397499991,
                40.52978317299992
              ],
              [
                -74.16122606799999,
                40.5294244229999
              ],
              [
                -74.16134961699994,
                40.529373423999914
              ],
              [
                -74.16085825899998,
                40.528718545999894
              ],
              [
                -74.16042481199997,
                40.528118481999854
              ],
              [
                -74.16005732699995,
                40.52763504199989
              ],
              [
                -74.15988277075512,
                40.52736935827443
              ],
              [
                -74.15970134520207,
                40.52729599283152
              ],
              [
                -74.15939460542255,
                40.52742775214439
              ],
              [
                -74.15928316159182,
                40.527511724834895
              ],
              [
                -74.15917092406274,
                40.52766975150677
              ],
              [
                -74.15890180648944,
                40.52793590956881
              ],
              [
                -74.15867696304029,
                40.5280980880003
              ],
              [
                -74.15864056291043,
                40.5281170720973
              ],
              [
                -74.15848687127477,
                40.528197229412555
              ],
              [
                -74.15832645126979,
                40.52827842678317
              ],
              [
                -74.15797239457906,
                40.5280670858444
              ],
              [
                -74.15794886622446,
                40.52808510395375
              ],
              [
                -74.15826103107307,
                40.52830049916297
              ],
              [
                -74.15789753570576,
                40.52852881414124
              ],
              [
                -74.15760975710916,
                40.52865910433
              ],
              [
                -74.15720948462648,
                40.52884150512297
              ],
              [
                -74.1570186194626,
                40.52897765580963
              ],
              [
                -74.15676917912974,
                40.52902316325286
              ],
              [
                -74.15666771048716,
                40.528992124356016
              ],
              [
                -74.15616811130214,
                40.52859614860073
              ],
              [
                -74.15608512824117,
                40.528639386976074
              ],
              [
                -74.1564581409916,
                40.52897042748831
              ],
              [
                -74.15643045569396,
                40.529003176875214
              ],
              [
                -74.1563306074147,
                40.529113394103945
              ],
              [
                -74.15622373887356,
                40.52921970935302
              ],
              [
                -74.15611011148543,
                40.529321862528626
              ],
              [
                -74.15599000320377,
                40.52941960371781
              ],
              [
                -74.15586370784149,
                40.529512693800854
              ],
              [
                -74.15573153435155,
                40.529600905035004
              ],
              [
                -74.1555035725126,
                40.52943802633765
              ],
              [
                -74.1554708552024,
                40.529477505470844
              ],
              [
                -74.15564782339318,
                40.52964298409564
              ],
              [
                -74.1555611224612,
                40.52973681844707
              ],
              [
                -74.1554687285071,
                40.529827434561625
              ],
              [
                -74.15537084637016,
                40.52991463151942
              ],
              [
                -74.15526769306129,
                40.52999821598076
              ],
              [
                -74.15515949728098,
                40.530078002615255
              ],
              [
                -74.15504649891339,
                40.530153814512815
              ],
              [
                -74.15492894849395,
                40.530225483575634
              ],
              [
                -74.15454357374453,
                40.53006213630362
              ],
              [
                -74.15449652366432,
                40.53010216686118
              ],
              [
                -74.15469794899192,
                40.53024888010373
              ],
              [
                -74.15465480504844,
                40.53031574383482
              ],
              [
                -74.15464111999897,
                40.530334638424286
              ],
              [
                -74.15463043409935,
                40.53033908181952
              ],
              [
                -74.15460770600043,
                40.53033731131074
              ],
              [
                -74.15457237074557,
                40.530322857459055
              ],
              [
                -74.15453545404684,
                40.53030490936558
              ],
              [
                -74.1543450727081,
                40.53048064990353
              ],
              [
                -74.15434789896189,
                40.53049350557084
              ],
              [
                -74.15436288012884,
                40.53050899371879
              ],
              [
                -74.15438294913153,
                40.53052909886251
              ],
              [
                -74.15436818968881,
                40.53054544706122
              ],
              [
                -74.15430582451033,
                40.53059216483583
              ],
              [
                -74.15424379688706,
                40.53064276380238
              ],
              [
                -74.15422643744478,
                40.53065859512647
              ],
              [
                -74.15421467768634,
                40.53066536709029
              ],
              [
                -74.15420772346783,
                40.5306588963553
              ],
              [
                -74.15408340297849,
                40.530586241662235
              ],
              [
                -74.15406027841539,
                40.530584815684534
              ],
              [
                -74.15404749949809,
                40.53059456484164
              ],
              [
                -74.1540409411867,
                40.53060724815501
              ],
              [
                -74.15405094663906,
                40.53062428882272
              ],
              [
                -74.15412319527393,
                40.53069081517857
              ],
              [
                -74.15413681963841,
                40.5307053971625
              ],
              [
                -74.15413110791108,
                40.530722911256305
              ],
              [
                -74.15399336904485,
                40.53082838075022
              ],
              [
                -74.15383974058712,
                40.53095675485172
              ],
              [
                -74.15373066693097,
                40.5310539419431
              ],
              [
                -74.15365382450162,
                40.531110493514326
              ],
              [
                -74.15355245128475,
                40.53117898506993
              ],
              [
                -74.15277081604117,
                40.53181305971921
              ],
              [
                -74.15245660936695,
                40.53206794400092
              ],
              [
                -74.15242365238952,
                40.53208118599128
              ],
              [
                -74.15238852526379,
                40.53209063761971
              ],
              [
                -74.1523519720649,
                40.532096098677826
              ],
              [
                -74.15232346155292,
                40.53209750724252
              ],
              [
                -74.15227294935502,
                40.53210658334698
              ],
              [
                -74.1522249360828,
                40.532121607030575
              ],
              [
                -74.15218061639548,
                40.53214220447678
              ],
              [
                -74.15217809851231,
                40.53214360769819
              ],
              [
                -74.15215509021469,
                40.53215720613129
              ],
              [
                -74.15212949213605,
                40.53216777183452
              ],
              [
                -74.15210198442624,
                40.53217502407327
              ],
              [
                -74.15207329797543,
                40.53217877015273
              ],
              [
                -74.15204419499408,
                40.532178910538036
              ],
              [
                -74.15201531030324,
                40.53216622772632
              ],
              [
                -74.15198405158232,
                40.53215741125936
              ],
              [
                -74.15195130015276,
                40.53215270971193
              ],
              [
                -74.15191797942113,
                40.53215225564121
              ],
              [
                -74.15188502884477,
                40.532156061849584
              ],
              [
                -74.15185337744485,
                40.53216402102329
              ],
              [
                -74.15177191995262,
                40.532208128107825
              ],
              [
                -74.15169555304871,
                40.53225725208631
              ],
              [
                -74.1516898413618,
                40.5322630757924
              ],
              [
                -74.15168542329587,
                40.53226952128041
              ],
              [
                -74.15168241248837,
                40.532276422765555
              ],
              [
                -74.15168088638092,
                40.53228360273437
              ],
              [
                -74.1516808842272,
                40.53229087651052
              ],
              [
                -74.15168240608293,
                40.532298057004795
              ],
              [
                -74.15168541280488,
                40.53230495952704
              ],
              [
                -74.15168982705744,
                40.532311406536984
              ],
              [
                -74.15169553530163,
                40.53231723221055
              ],
              [
                -74.1517023907152,
                40.532322286704975
              ],
              [
                -74.1517102169699,
                40.53232644001321
              ],
              [
                -74.1517336092612,
                40.532345354942585
              ],
              [
                -74.1517577230782,
                40.53236485459966
              ],
              [
                -74.15176149363195,
                40.532368948414266
              ],
              [
                -74.15176428160906,
                40.532373477315396
              ],
              [
                -74.15176600333938,
                40.53237830538616
              ],
              [
                -74.1517666071516,
                40.53238328773113
              ],
              [
                -74.15176607492447,
                40.53238827482501
              ],
              [
                -74.1517644226307,
                40.53239311699993
              ],
              [
                -74.15176169985676,
                40.53239766893707
              ],
              [
                -74.15175798831623,
                40.53240179402803
              ],
              [
                -74.15145035861133,
                40.53259535089076
              ],
              [
                -74.15125007703935,
                40.53268303076502
              ],
              [
                -74.1511202569052,
                40.5327413825067
              ],
              [
                -74.15110392281034,
                40.53274222306205
              ],
              [
                -74.1510243472269,
                40.532706674791534
              ],
              [
                -74.15069553618272,
                40.53254583893711
              ],
              [
                -74.15048977709482,
                40.53274929017209
              ],
              [
                -74.15031544439398,
                40.53290835175499
              ],
              [
                -74.15027891302235,
                40.53289169244555
              ],
              [
                -74.15029347623546,
                40.53287635892817
              ],
              [
                -74.15026788636533,
                40.53285690093636
              ],
              [
                -74.15021506106473,
                40.532897345219766
              ],
              [
                -74.15024247803049,
                40.53291819300964
              ],
              [
                -74.1502625151295,
                40.53290285183018
              ],
              [
                -74.1503004530457,
                40.53292272186595
              ],
              [
                -74.15012879899228,
                40.533087117842996
              ],
              [
                -74.15001653727084,
                40.53317059720706
              ],
              [
                -74.1499921557097,
                40.533188727871206
              ],
              [
                -74.14992364727622,
                40.53324142954888
              ],
              [
                -74.14986154448287,
                40.53329856558139
              ],
              [
                -74.14980633822482,
                40.53335968434934
              ],
              [
                -74.1497584648889,
                40.533424302751406
              ],
              [
                -74.14971830290297,
                40.5334919100224
              ],
              [
                -74.14977824913615,
                40.533826181885516
              ],
              [
                -74.14977717489465,
                40.53388786980671
              ],
              [
                -74.14921733263964,
                40.53424158776087
              ],
              [
                -74.14870158909109,
                40.534511003059215
              ],
              [
                -74.14851051812346,
                40.534610813656755
              ],
              [
                -74.14794320046134,
                40.534923016992956
              ],
              [
                -74.14701525183797,
                40.53532447244462
              ],
              [
                -74.14669647685385,
                40.53541430854866
              ],
              [
                -74.14625512927707,
                40.535538684733424
              ],
              [
                -74.14598880766482,
                40.53538158873743
              ],
              [
                -74.14594303539546,
                40.535425011148924
              ],
              [
                -74.14593847692211,
                40.53546225538461
              ],
              [
                -74.14592700342014,
                40.53549862294349
              ],
              [
                -74.14590884942612,
                40.53553337038119
              ],
              [
                -74.14588438604362,
                40.53556578737189
              ],
              [
                -74.14585411335841,
                40.5355952112287
              ],
              [
                -74.14581865021563,
                40.53562104045176
              ],
              [
                -74.14573534090819,
                40.535667839442425
              ],
              [
                -74.14555172527938,
                40.535764891779145
              ],
              [
                -74.14536324652758,
                40.53585635689648
              ],
              [
                -74.14506861008147,
                40.5358396660901
              ],
              [
                -74.14488929110058,
                40.5358027584249
              ],
              [
                -74.14478850976617,
                40.53579095170865
              ],
              [
                -74.14474069813576,
                40.53580104421121
              ],
              [
                -74.14455507323936,
                40.53594693127202
              ],
              [
                -74.14438874490745,
                40.53635837336202
              ],
              [
                -74.14437399056834,
                40.5363948706349
              ],
              [
                -74.14429650123257,
                40.536528092654315
              ],
              [
                -74.14428108600727,
                40.5365674030764
              ],
              [
                -74.14427835032906,
                40.53663569906908
              ],
              [
                -74.14423398630267,
                40.536716057123
              ],
              [
                -74.14418283372648,
                40.53679403136108
              ],
              [
                -74.14412511128542,
                40.53686928839643
              ],
              [
                -74.14406106575684,
                40.536941506458426
              ],
              [
                -74.14399097095605,
                40.53701037676944
              ],
              [
                -74.14391512656537,
                40.53707560486414
              ],
              [
                -74.14383385685329,
                40.537136911849686
              ],
              [
                -74.14379397794472,
                40.53716606581356
              ],
              [
                -74.14374951249268,
                40.53719106134627
              ],
              [
                -74.14370120016159,
                40.53721148265441
              ],
              [
                -74.14364984461051,
                40.537226990035535
              ],
              [
                -74.14359630012335,
                40.53723732552846
              ],
              [
                -74.14354145739786,
                40.537242317205056
              ],
              [
                -74.14348622872835,
                40.5372418820298
              ],
              [
                -74.14305162613793,
                40.5372398738564
              ],
              [
                -74.1417341057238,
                40.537039511377365
              ],
              [
                -74.14158287690883,
                40.53708757795238
              ],
              [
                -74.14238963689803,
                40.537753261007346
              ],
              [
                -74.14218574841878,
                40.537917778291536
              ],
              [
                -74.14216240779166,
                40.53790214964632
              ],
              [
                -74.14217576939241,
                40.53789459909736
              ],
              [
                -74.14124113051585,
                40.53723469173408
              ],
              [
                -74.14123742490062,
                40.53721561760873
              ],
              [
                -74.14132073591662,
                40.53715352068053
              ],
              [
                -74.14129825471821,
                40.53713817794831
              ],
              [
                -74.1412032253677,
                40.53721597312263
              ],
              [
                -74.14104157916547,
                40.53734688546733
              ],
              [
                -74.14106736882466,
                40.53736564364512
              ],
              [
                -74.1411977050377,
                40.5372540241963
              ],
              [
                -74.14121865335552,
                40.53725399843248
              ],
              [
                -74.14213937737509,
                40.537921485186544
              ],
              [
                -74.14214752525183,
                40.53791658053479
              ],
              [
                -74.1421671801914,
                40.53793276106814
              ],
              [
                -74.14207893644108,
                40.53800396428513
              ],
              [
                -74.14219794712481,
                40.538091055497546
              ],
              [
                -74.14222992294863,
                40.53811445387982
              ],
              [
                -74.14220665211691,
                40.53813197269759
              ],
              [
                -74.14220052571473,
                40.53812644173447
              ],
              [
                -74.14183789388265,
                40.53842162042673
              ],
              [
                -74.14186762291457,
                40.53844409071919
              ],
              [
                -74.14221322317795,
                40.53816752905834
              ],
              [
                -74.14220824925783,
                40.53816462035977
              ],
              [
                -74.14224928389066,
                40.538129683358676
              ],
              [
                -74.14226513007988,
                40.538140353664645
              ],
              [
                -74.14176587793762,
                40.538542040999715
              ],
              [
                -74.1421518306091,
                40.538816553186884
              ],
              [
                -74.14233831577495,
                40.53894919040828
              ],
              [
                -74.14225638639385,
                40.539012914076714
              ],
              [
                -74.14223700269802,
                40.53903065424878
              ],
              [
                -74.1423035127584,
                40.53906997528961
              ],
              [
                -74.14227296511635,
                40.53910289983899
              ],
              [
                -74.14221934705722,
                40.53913924946706
              ],
              [
                -74.1421719510025,
                40.539154003918846
              ],
              [
                -74.14209894139935,
                40.53915770591602
              ],
              [
                -74.14203045817284,
                40.539175233398495
              ],
              [
                -74.14188783577478,
                40.539209747406346
              ],
              [
                -74.14176433199538,
                40.53927565642522
              ],
              [
                -74.14173698496194,
                40.53930971273437
              ],
              [
                -74.14172892651293,
                40.53933948409699
              ],
              [
                -74.1417415875668,
                40.53936550367509
              ],
              [
                -74.14176721342444,
                40.53938732786741
              ],
              [
                -74.14182589311272,
                40.53942218646412
              ],
              [
                -74.14187458672595,
                40.539462641488974
              ],
              [
                -74.14192932905125,
                40.53949978364435
              ],
              [
                -74.14198181354385,
                40.53953597330814
              ],
              [
                -74.14200459282473,
                40.53956795916438
              ],
              [
                -74.14200666356471,
                40.53962065642815
              ],
              [
                -74.14198428949713,
                40.53966199086008
              ],
              [
                -74.14195889835926,
                40.53967817719808
              ],
              [
                -74.14188967926111,
                40.53969410358076
              ],
              [
                -74.14188144756507,
                40.53969914987982
              ],
              [
                -74.14187380123579,
                40.53970478566417
              ],
              [
                -74.1418667047398,
                40.53971082317631
              ],
              [
                -74.1418601955679,
                40.53971723439248
              ],
              [
                -74.1418543050504,
                40.539723985600325
              ],
              [
                -74.14184906363768,
                40.539731042418914
              ],
              [
                -74.14184449781987,
                40.53973836678616
              ],
              [
                -74.14184063475152,
                40.53974592315214
              ],
              [
                -74.14183749015511,
                40.53975367011747
              ],
              [
                -74.14183508130014,
                40.53976157046924
              ],
              [
                -74.14183342170932,
                40.539769580800396
              ],
              [
                -74.14183251854898,
                40.539777663240315
              ],
              [
                -74.1418323787521,
                40.53978577321748
              ],
              [
                -74.1418329998135,
                40.539793868517286
              ],
              [
                -74.14183554323496,
                40.53980808852639
              ],
              [
                -74.14183880849806,
                40.539822223040765
              ],
              [
                -74.14184279226167,
                40.53983624961569
              ],
              [
                -74.14184748745377,
                40.53985014681587
              ],
              [
                -74.14185288744639,
                40.53986389555105
              ],
              [
                -74.14185898274587,
                40.53987747154069
              ],
              [
                -74.14186576585055,
                40.53989085787367
              ],
              [
                -74.14187097346594,
                40.53990076956517
              ],
              [
                -74.14187546059314,
                40.53991088636612
              ],
              [
                -74.1418792126678,
                40.53992117478841
              ],
              [
                -74.14188221952347,
                40.53993160418676
              ],
              [
                -74.14188446901966,
                40.5399421452584
              ],
              [
                -74.14188595801129,
                40.53995276483629
              ],
              [
                -74.14188667786581,
                40.53996343076529
              ],
              [
                -74.14188663005051,
                40.539974110375205
              ],
              [
                -74.14188699003844,
                40.53998066120083
              ],
              [
                -74.14188655355558,
                40.53998720982754
              ],
              [
                -74.14188532465153,
                40.53999369845249
              ],
              [
                -74.14188331243462,
                40.54000007328704
              ],
              [
                -74.14188053676908,
                40.54000627835169
              ],
              [
                -74.14187701708526,
                40.54001226034762
              ],
              [
                -74.14187278885099,
                40.54001796930702
              ],
              [
                -74.1418678848932,
                40.540023352417236
              ],
              [
                -74.14186234838103,
                40.54002836690455
              ],
              [
                -74.14185622819136,
                40.540032969318126
              ],
              [
                -74.14184957452491,
                40.54003711888604
              ],
              [
                -74.14184244637886,
                40.54004078102421
              ],
              [
                -74.1418367449673,
                40.54003928615861
              ],
              [
                -74.14183084684537,
                40.54003832997791
              ],
              [
                -74.14182484120643,
                40.5400379306328
              ],
              [
                -74.14181881523446,
                40.540038090695916
              ],
              [
                -74.14181285874655,
                40.540038811731684
              ],
              [
                -74.14180706394352,
                40.54004008039147
              ],
              [
                -74.1418015148865,
                40.54004187630013
              ],
              [
                -74.14179629277618,
                40.54004417640602
              ],
              [
                -74.14179147614927,
                40.54004694409036
              ],
              [
                -74.14178713892304,
                40.54005013888719
              ],
              [
                -74.14178334489904,
                40.54005371347423
              ],
              [
                -74.14178014842196,
                40.54005761400743
              ],
              [
                -74.14177759987497,
                40.54006178212378
              ],
              [
                -74.14177573470064,
                40.54006615579358
              ],
              [
                -74.14177458284607,
                40.540070670313284
              ],
              [
                -74.14177416085597,
                40.540075257477426
              ],
              [
                -74.14177425311995,
                40.540079724725175
              ],
              [
                -74.1417750741191,
                40.54008414617482
              ],
              [
                -74.14177661383266,
                40.54008845633472
              ],
              [
                -74.14177884532401,
                40.54009258621548
              ],
              [
                -74.14178173397711,
                40.54009646985285
              ],
              [
                -74.14178523794598,
                40.540100049165645
              ],
              [
                -74.14178930023023,
                40.54010326491823
              ],
              [
                -74.14179385529062,
                40.54010606994809
              ],
              [
                -74.14179883385654,
                40.540108417767385
              ],
              [
                -74.1418041589966,
                40.54011027379217
              ],
              [
                -74.14180974566287,
                40.54011160746948
              ],
              [
                -74.14181550751331,
                40.54011239913739
              ],
              [
                -74.14182970578898,
                40.54012418812668
              ],
              [
                -74.14184322869355,
                40.54013643580874
              ],
              [
                -74.14185605092108,
                40.54014911758806
              ],
              [
                -74.14186814079488,
                40.54016220736935
              ],
              [
                -74.14187716996324,
                40.540172398134835
              ],
              [
                -74.14188538527415,
                40.540182981925966
              ],
              [
                -74.14189276029074,
                40.54019391856842
              ],
              [
                -74.1418992661717,
                40.54020517308428
              ],
              [
                -74.14190488240092,
                40.54021670143855
              ],
              [
                -74.14190760177618,
                40.54022690117237
              ],
              [
                -74.14190960174705,
                40.54023719778986
              ],
              [
                -74.1419108785163,
                40.54024756114079
              ],
              [
                -74.14191142741133,
                40.540257962750395
              ],
              [
                -74.1419112488051,
                40.540268371457834
              ],
              [
                -74.14191034043938,
                40.54027875828301
              ],
              [
                -74.14190870883773,
                40.54028909289499
              ],
              [
                -74.14190635459853,
                40.540299347147894
              ],
              [
                -74.14190366018238,
                40.540307314865714
              ],
              [
                -74.14190179788412,
                40.540315420940374
              ],
              [
                -74.14190077967079,
                40.54032361325498
              ],
              [
                -74.14190060873084,
                40.54033184188166
              ],
              [
                -74.14190129022518,
                40.54034005521493
              ],
              [
                -74.14190281657854,
                40.54034820166462
              ],
              [
                -74.14190517802098,
                40.540356230481166
              ],
              [
                -74.14190836281273,
                40.5403640937654
              ],
              [
                -74.14191903816062,
                40.540369040471404
              ],
              [
                -74.14193008007078,
                40.540373497032526
              ],
              [
                -74.14194144744997,
                40.54037744976186
              ],
              [
                -74.14195309963941,
                40.54038088296165
              ],
              [
                -74.14196499796977,
                40.54038378696302
              ],
              [
                -74.14197510562117,
                40.54038974340041
              ],
              [
                -74.14198458886533,
                40.540396272557366
              ],
              [
                -74.1419933905225,
                40.54040333446494
              ],
              [
                -74.14200146000344,
                40.540410890318874
              ],
              [
                -74.14200874977742,
                40.54041889393968
              ],
              [
                -74.14202009267665,
                40.540431574682174
              ],
              [
                -74.14203082663339,
                40.5404445609142
              ],
              [
                -74.1420409375547,
                40.54045783439282
              ],
              [
                -74.14205041288535,
                40.54047137720771
              ],
              [
                -74.14205923897899,
                40.54048517480087
              ],
              [
                -74.14206740568822,
                40.54049920557377
              ],
              [
                -74.14207489936211,
                40.54051345279031
              ],
              [
                -74.14207493786425,
                40.54051906483826
              ],
              [
                -74.14207415018065,
                40.54052464490503
              ],
              [
                -74.14207254362798,
                40.54053012278637
              ],
              [
                -74.14207014221716,
                40.54053543043541
              ],
              [
                -74.14206697390732,
                40.54054049795718
              ],
              [
                -74.14206307962891,
                40.54054526230973
              ],
              [
                -74.1420585082226,
                40.54054966295394
              ],
              [
                -74.14205331996206,
                40.54055364553564
              ],
              [
                -74.14204757731929,
                40.54055715720534
              ],
              [
                -74.14204236545184,
                40.5405622250767
              ],
              [
                -74.14203776301363,
                40.54056762758931
              ],
              [
                -74.14203380943752,
                40.54057332029918
              ],
              [
                -74.1420305338337,
                40.540579258104835
              ],
              [
                -74.14202796289642,
                40.54058539540478
              ],
              [
                -74.14202611672216,
                40.54059168225033
              ],
              [
                -74.14202501079866,
                40.54059806970366
              ],
              [
                -74.14202465094452,
                40.5406045056554
              ],
              [
                -74.14202556716027,
                40.54061238816031
              ],
              [
                -74.14202725883361,
                40.54062019783536
              ],
              [
                -74.14202971532565,
                40.54062788845556
              ],
              [
                -74.14203292556499,
                40.540635416811774
              ],
              [
                -74.14203687057113,
                40.54064273786164
              ],
              [
                -74.14204152478366,
                40.54064981042436
              ],
              [
                -74.14204686264567,
                40.54065659516185
              ],
              [
                -74.14205285596474,
                40.54066305223675
              ],
              [
                -74.14205946666839,
                40.540669142996386
              ],
              [
                -74.14206665867121,
                40.540674833476835
              ],
              [
                -74.14207439083921,
                40.540680091227905
              ],
              [
                -74.14209473837124,
                40.54069103760447
              ],
              [
                -74.14211552890573,
                40.540701500941985
              ],
              [
                -74.14213673870714,
                40.540711472390605
              ],
              [
                -74.14215834381515,
                40.540720940587555
              ],
              [
                -74.14217078229831,
                40.54072739956056
              ],
              [
                -74.14218280538176,
                40.54073430601732
              ],
              [
                -74.14219438448305,
                40.540741643407976
              ],
              [
                -74.14220549101721,
                40.54074939417716
              ],
              [
                -74.14221610188633,
                40.54075753925522
              ],
              [
                -74.14222619465933,
                40.54076606359246
              ],
              [
                -74.14223574008284,
                40.54077494494364
              ],
              [
                -74.14224247995415,
                40.54077949626483
              ],
              [
                -74.1422487191152,
                40.54078444525273
              ],
              [
                -74.14225441994756,
                40.54078976029065
              ],
              [
                -74.14225954394676,
                40.54079540607753
              ],
              [
                -74.14226405963666,
                40.54080134797374
              ],
              [
                -74.14226793838222,
                40.54080754480223
              ],
              [
                -74.14227115484987,
                40.540813959235514
              ],
              [
                -74.14226054076296,
                40.54082628234096
              ],
              [
                -74.14224031268571,
                40.54084208690702
              ],
              [
                -74.14222442361935,
                40.540850468861166
              ],
              [
                -74.14221712687538,
                40.54086079072562
              ],
              [
                -74.14221065102096,
                40.54087144093728
              ],
              [
                -74.1422050205657,
                40.54088237942626
              ],
              [
                -74.1422002584716,
                40.54089356109865
              ],
              [
                -74.14219638266205,
                40.54090494639516
              ],
              [
                -74.1421934073191,
                40.54091649207568
              ],
              [
                -74.1421949013318,
                40.54092809638527
              ],
              [
                -74.14219724939792,
                40.5409396168773
              ],
              [
                -74.14220044485783,
                40.54095101971876
              ],
              [
                -74.14220447533502,
                40.54096226739819
              ],
              [
                -74.1422093306499,
                40.540973323071384
              ],
              [
                -74.14221499316109,
                40.54098415191382
              ],
              [
                -74.14222671276296,
                40.54099836104978
              ],
              [
                -74.14223905010718,
                40.541012265854526
              ],
              [
                -74.14225199022492,
                40.541025849258375
              ],
              [
                -74.14226551705349,
                40.541039096035504
              ],
              [
                -74.14227905630845,
                40.54105415430023
              ],
              [
                -74.14229185500412,
                40.541069585733304
              ],
              [
                -74.14230389486427,
                40.54108536723826
              ],
              [
                -74.1423151567419,
                40.54110147907079
              ],
              [
                -74.14232283461494,
                40.54111296866214
              ],
              [
                -74.14233122928576,
                40.54112416267863
              ],
              [
                -74.14234032115439,
                40.54113503500984
              ],
              [
                -74.14235009040394,
                40.54114556038317
              ],
              [
                -74.14236051260758,
                40.541155714704196
              ],
              [
                -74.142371563779,
                40.541165474213415
              ],
              [
                -74.14238055824558,
                40.541173398297595
              ],
              [
                -74.14238890927422,
                40.54118172558669
              ],
              [
                -74.14239658429312,
                40.54119042261507
              ],
              [
                -74.1424035531511,
                40.54119945825972
              ],
              [
                -74.14240979315714,
                40.541208798707636
              ],
              [
                -74.14241528096458,
                40.5412184114872
              ],
              [
                -74.14241999563218,
                40.54122825943281
              ],
              [
                -74.1424239201753,
                40.541238307216595
              ],
              [
                -74.14242704046049,
                40.54124851783215
              ],
              [
                -74.14243045094854,
                40.54125919365416
              ],
              [
                -74.14243485400583,
                40.54126965698907
              ],
              [
                -74.14244022799774,
                40.541279853918994
              ],
              [
                -74.14244654535769,
                40.54128972952814
              ],
              [
                -74.14245377216538,
                40.54129923577708
              ],
              [
                -74.14245889128624,
                40.541303288013474
              ],
              [
                -74.14246345780855,
                40.54130770966916
              ],
              [
                -74.14246742486102,
                40.541312455401886
              ],
              [
                -74.14247075501662,
                40.54131748036001
              ],
              [
                -74.14247341412687,
                40.541322732819005
              ],
              [
                -74.14247537660658,
                40.541328160206355
              ],
              [
                -74.14247662235951,
                40.541333709774804
              ],
              [
                -74.14247713962867,
                40.54133932608662
              ],
              [
                -74.14247692280713,
                40.54134495436633
              ],
              [
                -74.14247597330859,
                40.54135053681423
              ],
              [
                -74.14247428246301,
                40.54135870199038
              ],
              [
                -74.14247178257858,
                40.541366745873425
              ],
              [
                -74.1424684827766,
                40.54137462087316
              ],
              [
                -74.14246440689692,
                40.5413822818944
              ],
              [
                -74.14245957614196,
                40.54138968284
              ],
              [
                -74.14245402116491,
                40.54139678128667
              ],
              [
                -74.14244777218299,
                40.541403536319514
              ],
              [
                -74.1424408664403,
                40.54140990684704
              ],
              [
                -74.14243334426878,
                40.54141585814062
              ],
              [
                -74.14242524863116,
                40.54142135345751
              ],
              [
                -74.14241662864939,
                40.54142636073869
              ],
              [
                -74.14240753323442,
                40.541430852112924
              ],
              [
                -74.14239498834318,
                40.54144172178718
              ],
              [
                -74.14238312051093,
                40.541453023183536
              ],
              [
                -74.14237195252855,
                40.54146473382485
              ],
              [
                -74.14236150914962,
                40.54147682503301
              ],
              [
                -74.14235181338329,
                40.54148927382815
              ],
              [
                -74.14234288668823,
                40.54150205103334
              ],
              [
                -74.14233794435431,
                40.54151264775116
              ],
              [
                -74.14233373829785,
                40.54152343403803
              ],
              [
                -74.14233027811335,
                40.541534378051225
              ],
              [
                -74.14232757735208,
                40.541545449618695
              ],
              [
                -74.14232564230801,
                40.54155661355125
              ],
              [
                -74.14232447686835,
                40.5415678390187
              ],
              [
                -74.14232408777632,
                40.541579095522096
              ],
              [
                -74.14232447297829,
                40.54159034654235
              ],
              [
                -74.14235007650028,
                40.54165119478617
              ],
              [
                -74.1423520126031,
                40.54165712613905
              ],
              [
                -74.1423531667455,
                40.54166317539748
              ],
              [
                -74.14235352145613,
                40.54166928495267
              ],
              [
                -74.1423530783546,
                40.54167539047113
              ],
              [
                -74.14235183775372,
                40.54168143281411
              ],
              [
                -74.14234981467342,
                40.541687349809344
              ],
              [
                -74.14234702765678,
                40.54169308397104
              ],
              [
                -74.14234350622391,
                40.54169857629175
              ],
              [
                -74.14233928539392,
                40.54170377194569
              ],
              [
                -74.1423325923287,
                40.54171650081276
              ],
              [
                -74.14232674237348,
                40.54172946904025
              ],
              [
                -74.14232174995551,
                40.54174264544972
              ],
              [
                -74.14231762708064,
                40.541755995850174
              ],
              [
                -74.14231438531455,
                40.5417694857165
              ],
              [
                -74.14227597153985,
                40.54182615937532
              ],
              [
                -74.14226781138109,
                40.541836654700404
              ],
              [
                -74.14226032117027,
                40.54184743684433
              ],
              [
                -74.14225352084085,
                40.54185848216069
              ],
              [
                -74.14224742417292,
                40.54186976483291
              ],
              [
                -74.14224204736009,
                40.54188125803623
              ],
              [
                -74.14223740154239,
                40.541892933611805
              ],
              [
                -74.1422188826151,
                40.541932317776904
              ],
              [
                -74.14221261310068,
                40.541940191086226
              ],
              [
                -74.14220699969682,
                40.541948349053996
              ],
              [
                -74.14220206539267,
                40.54195675931871
              ],
              [
                -74.14219782944357,
                40.541965389188036
              ],
              [
                -74.14219430868631,
                40.54197420446524
              ],
              [
                -74.14219151688192,
                40.54198317028682
              ],
              [
                -74.1421894647173,
                40.54199225212865
              ],
              [
                -74.14218815870504,
                40.54200141429851
              ],
              [
                -74.14218539130584,
                40.542009569243454
              ],
              [
                -74.14218344286675,
                40.54201786105168
              ],
              [
                -74.14218232602431,
                40.54202624229641
              ],
              [
                -74.1421820474815,
                40.54203466371547
              ],
              [
                -74.14218260779379,
                40.54204307655703
              ],
              [
                -74.14218400576314,
                40.54205143374643
              ],
              [
                -74.14218623118083,
                40.542059684702195
              ],
              [
                -74.14218927208896,
                40.542067782530616
              ],
              [
                -74.14222835050174,
                40.54213767146605
              ],
              [
                -74.142235984228,
                40.542146632583425
              ],
              [
                -74.14224430585027,
                40.542155230145184
              ],
              [
                -74.1422532871971,
                40.54216343470078
              ],
              [
                -74.14226289372438,
                40.54217121479697
              ],
              [
                -74.14227308935794,
                40.5421785423336
              ],
              [
                -74.14227952634577,
                40.54218382579962
              ],
              [
                -74.14228523416807,
                40.54218958176653
              ],
              [
                -74.14229015339535,
                40.542195745976194
              ],
              [
                -74.14229423382946,
                40.54220225767781
              ],
              [
                -74.14229743316864,
                40.54220905208933
              ],
              [
                -74.14229972051788,
                40.54221605838398
              ],
              [
                -74.1423014350806,
                40.5422220015717
              ],
              [
                -74.14230242077369,
                40.54222804115482
              ],
              [
                -74.14230266760136,
                40.542234123871246
              ],
              [
                -74.1423021723808,
                40.54224019879562
              ],
              [
                -74.14230094334164,
                40.542246211973264
              ],
              [
                -74.14229898630232,
                40.54225211146238
              ],
              [
                -74.14229632246158,
                40.54225784915548
              ],
              [
                -74.1422929736675,
                40.54226337258865
              ],
              [
                -74.1422889674892,
                40.542268634483975
              ],
              [
                -74.14228358438888,
                40.54227481414055
              ],
              [
                -74.14227757028851,
                40.542280644440616
              ],
              [
                -74.14227096090445,
                40.5422860884831
              ],
              [
                -74.14226380161546,
                40.54229110935514
              ],
              [
                -74.14225730330142,
                40.54229498433866
              ],
              [
                -74.14225613979161,
                40.54229567700988
              ],
              [
                -74.14224801929021,
                40.54229976190778
              ],
              [
                -74.14223949692784,
                40.542303335832976
              ],
              [
                -74.14221216682478,
                40.542286377597726
              ],
              [
                -74.14218366887137,
                40.5422579508455
              ],
              [
                -74.14217172891641,
                40.5422468940603
              ],
              [
                -74.14215556001572,
                40.54223961294452
              ],
              [
                -74.14213710288624,
                40.54223698261742
              ],
              [
                -74.14212200021255,
                40.54224242945833
              ],
              [
                -74.14210723175339,
                40.542248385343036
              ],
              [
                -74.14207881552541,
                40.54226177785973
              ],
              [
                -74.14203941343264,
                40.54228535739979
              ],
              [
                -74.14195588443337,
                40.542326413291015
              ],
              [
                -74.1419107124004,
                40.54234466049322
              ],
              [
                -74.1418620809595,
                40.54235666475288
              ],
              [
                -74.14181145940866,
                40.54236206338591
              ],
              [
                -74.14176037717463,
                40.54236069328358
              ],
              [
                -74.14174169529748,
                40.54236120068911
              ],
              [
                -74.1417231520644,
                40.54235939482843
              ],
              [
                -74.14170523639434,
                40.54235532331576
              ],
              [
                -74.1416722624308,
                40.542335464095295
              ],
              [
                -74.14187307971923,
                40.542166658129425
              ],
              [
                -74.14185192891291,
                40.54215177993478
              ],
              [
                -74.14181267772757,
                40.54218676935904
              ],
              [
                -74.140894775834,
                40.54153709486054
              ],
              [
                -74.14089578688294,
                40.54151658389953
              ],
              [
                -74.14098048587034,
                40.54144768331348
              ],
              [
                -74.14095816832888,
                40.54143207796497
              ],
              [
                -74.14063984172124,
                40.54169454203723
              ],
              [
                -74.14066095220976,
                40.541709346456194
              ],
              [
                -74.14084633142167,
                40.5415555067284
              ],
              [
                -74.14088702790175,
                40.54158404784443
              ],
              [
                -74.14089451090716,
                40.54157111119271
              ],
              [
                -74.14177252532792,
                40.542181628520986
              ],
              [
                -74.14179719957052,
                40.54220041538236
              ],
              [
                -74.14164911303283,
                40.54232152214274
              ],
              [
                -74.14154957905978,
                40.54241061750455
              ],
              [
                -74.1413633083767,
                40.54227845136751
              ],
              [
                -74.14134854694547,
                40.54229046487098
              ],
              [
                -74.14153109439735,
                40.542426000800624
              ],
              [
                -74.14148097474445,
                40.54246770907047
              ],
              [
                -74.14129442123689,
                40.542335875885705
              ],
              [
                -74.14127657503222,
                40.54235307924103
              ],
              [
                -74.14146151585437,
                40.54248390222388
              ],
              [
                -74.14134901379589,
                40.54257752313156
              ],
              [
                -74.14133758497195,
                40.54256887136462
              ],
              [
                -74.14134320138834,
                40.54256413942905
              ],
              [
                -74.1412192420468,
                40.5424802182529
              ],
              [
                -74.14111038150885,
                40.542401813586665
              ],
              [
                -74.1410154329906,
                40.54233497158855
              ],
              [
                -74.14100375613094,
                40.542326749807884
              ],
              [
                -74.14099109268012,
                40.542317834824686
              ],
              [
                -74.14089718575627,
                40.5422517253995
              ],
              [
                -74.14082098556521,
                40.542198080377645
              ],
              [
                -74.1407910422767,
                40.54217700008028
              ],
              [
                -74.14061919911953,
                40.54205602164392
              ],
              [
                -74.14063417283317,
                40.54204226716764
              ],
              [
                -74.14059472343459,
                40.54201650761541
              ],
              [
                -74.14070353028875,
                40.54192481773058
              ],
              [
                -74.14067998093041,
                40.541908592971026
              ],
              [
                -74.1406586485676,
                40.5419262303846
              ],
              [
                -74.14062821467097,
                40.54195139082894
              ],
              [
                -74.14059741115024,
                40.541976857627915
              ],
              [
                -74.14057844981443,
                40.541992534030044
              ],
              [
                -74.14053729713807,
                40.54202655584857
              ],
              [
                -74.14049581011302,
                40.54206085532388
              ],
              [
                -74.14047503718434,
                40.542059787772274
              ],
              [
                -74.1402951165258,
                40.54193423041491
              ],
              [
                -74.14026290400692,
                40.541961511522594
              ],
              [
                -74.14040510349558,
                40.54206053639266
              ],
              [
                -74.140412617325,
                40.54209062802874
              ],
              [
                -74.14046378256693,
                40.54212744253594
              ],
              [
                -74.14054086217759,
                40.54205821787937
              ],
              [
                -74.14056897693308,
                40.54205256868892
              ],
              [
                -74.14064549734753,
                40.54210641066902
              ],
              [
                -74.14065797552682,
                40.54211519189317
              ],
              [
                -74.14074792898349,
                40.542178484146234
              ],
              [
                -74.14075996557567,
                40.54218695412683
              ],
              [
                -74.14084801466012,
                40.54224890805907
              ],
              [
                -74.1408591544129,
                40.542256746200295
              ],
              [
                -74.14094279413196,
                40.542315597280684
              ],
              [
                -74.14095350746899,
                40.54232313622446
              ],
              [
                -74.1410303146288,
                40.542377178967136
              ],
              [
                -74.14104399349495,
                40.54238680419946
              ],
              [
                -74.14111435667954,
                40.54243631322439
              ],
              [
                -74.1411269185534,
                40.54244515259516
              ],
              [
                -74.14118653657921,
                40.542487100238304
              ],
              [
                -74.14119811780873,
                40.542495249074705
              ],
              [
                -74.14125011203603,
                40.54253149847261
              ],
              [
                -74.14121958022263,
                40.54255555315844
              ],
              [
                -74.14128619376386,
                40.54259913025637
              ],
              [
                -74.14131554422718,
                40.542574506063815
              ],
              [
                -74.14133547192966,
                40.5425887924703
              ],
              [
                -74.14124949366264,
                40.54266034085642
              ],
              [
                -74.14114311299068,
                40.54258765885143
              ],
              [
                -74.14112375946439,
                40.54260461374511
              ],
              [
                -74.14122820814431,
                40.54267805353794
              ],
              [
                -74.14132653105811,
                40.542747047487325
              ],
              [
                -74.1412951184684,
                40.542768770753604
              ],
              [
                -74.14118693183593,
                40.542696321701094
              ],
              [
                -74.14117879550508,
                40.54270346648169
              ],
              [
                -74.14108436804705,
                40.54263891370385
              ],
              [
                -74.14106663614172,
                40.54265404671472
              ],
              [
                -74.1411614790294,
                40.54271867371482
              ],
              [
                -74.14107892119061,
                40.542791171851576
              ],
              [
                -74.14141073560397,
                40.54302241508036
              ],
              [
                -74.14133387500642,
                40.54308616532608
              ],
              [
                -74.14129718766546,
                40.54306128868369
              ],
              [
                -74.14130461980226,
                40.54305489177875
              ],
              [
                -74.14120934288349,
                40.54298645830763
              ],
              [
                -74.14042719207666,
                40.54242466294015
              ],
              [
                -74.14043095386094,
                40.54242174113581
              ],
              [
                -74.14048119210243,
                40.54238203801377
              ],
              [
                -74.14040656300683,
                40.54232708255206
              ],
              [
                -74.14028630244322,
                40.542422126530134
              ],
              [
                -74.14024073040927,
                40.542389329524205
              ],
              [
                -74.14020723606573,
                40.542365224298344
              ],
              [
                -74.14035874187903,
                40.542245484948694
              ],
              [
                -74.14033528229751,
                40.542228209259505
              ],
              [
                -74.14032683633252,
                40.542235057997615
              ],
              [
                -74.14028096291975,
                40.54227225901736
              ],
              [
                -74.14016510037457,
                40.5423662184335
              ],
              [
                -74.14028673369575,
                40.54245578902145
              ],
              [
                -74.14040685334767,
                40.54236085545052
              ],
              [
                -74.14043778311083,
                40.54238217504695
              ],
              [
                -74.14040897507606,
                40.54240650081821
              ],
              [
                -74.14040508359442,
                40.54240382006747
              ],
              [
                -74.14038500590046,
                40.54242077136651
              ],
              [
                -74.14128331627664,
                40.543073695783754
              ],
              [
                -74.14128807915924,
                40.543069596322695
              ],
              [
                -74.14132426794235,
                40.54309413383019
              ],
              [
                -74.14125673409733,
                40.543150146888685
              ],
              [
                -74.14110542112017,
                40.543041720587524
              ],
              [
                -74.14098421950682,
                40.54294899758736
              ],
              [
                -74.14096661369906,
                40.54296326479877
              ],
              [
                -74.14109069897191,
                40.54305205535584
              ],
              [
                -74.14104442644127,
                40.54308698562639
              ],
              [
                -74.1408723827923,
                40.54295994171922
              ],
              [
                -74.14084520303832,
                40.54293884497904
              ],
              [
                -74.14064614869828,
                40.543094214902105
              ],
              [
                -74.13977484776684,
                40.542464048803886
              ],
              [
                -74.13988550839117,
                40.54237593505093
              ],
              [
                -74.13985859891669,
                40.5423571751291
              ],
              [
                -74.13963169074167,
                40.54254109756619
              ],
              [
                -74.13965860486505,
                40.5425607111017
              ],
              [
                -74.13975584890447,
                40.542481155655224
              ],
              [
                -74.13999673966619,
                40.54265585236878
              ],
              [
                -74.1400158651306,
                40.542669723334214
              ],
              [
                -74.14005800038075,
                40.54270027938105
              ],
              [
                -74.14011692170483,
                40.5427430091187
              ],
              [
                -74.14013887318971,
                40.54275892852513
              ],
              [
                -74.14022024715962,
                40.54281794154593
              ],
              [
                -74.14023059544262,
                40.54282544582132
              ],
              [
                -74.14031895744444,
                40.54288952590017
              ],
              [
                -74.14035319257147,
                40.542914352745136
              ],
              [
                -74.14042171459113,
                40.54296404554314
              ],
              [
                -74.14043317229084,
                40.54297235493394
              ],
              [
                -74.14052328363455,
                40.54303770242722
              ],
              [
                -74.14053648767492,
                40.54304727787413
              ],
              [
                -74.14062715566955,
                40.5431130296925
              ],
              [
                -74.14030634258127,
                40.54336711011437
              ],
              [
                -74.14023345086983,
                40.54331338695041
              ],
              [
                -74.14022188806456,
                40.543305029419656
              ],
              [
                -74.14014720421117,
                40.54325105541864
              ],
              [
                -74.14005110782219,
                40.54318160424294
              ],
              [
                -74.13998476182942,
                40.54313365473928
              ],
              [
                -74.13996485006795,
                40.543119264881135
              ],
              [
                -74.13989432782361,
                40.54306829616037
              ],
              [
                -74.13987698012025,
                40.54305575973285
              ],
              [
                -74.13987173067858,
                40.543051965704905
              ],
              [
                -74.13970048414524,
                40.54292820132511
              ],
              [
                -74.13962918175764,
                40.54287667015384
              ],
              [
                -74.13961652514804,
                40.54286752281435
              ],
              [
                -74.13956044589743,
                40.54282699238843
              ],
              [
                -74.13953259730957,
                40.542806864475445
              ],
              [
                -74.13947877205686,
                40.54276849257767
              ],
              [
                -74.1395637247472,
                40.542700056127806
              ],
              [
                -74.1395368154802,
                40.542682150869766
              ],
              [
                -74.13936132804027,
                40.54282586399861
              ],
              [
                -74.13938711756549,
                40.542843770656766
              ],
              [
                -74.13945753440684,
                40.5427856020984
              ],
              [
                -74.13951023746552,
                40.54282312063042
              ],
              [
                -74.13955102413227,
                40.54285279410922
              ],
              [
                -74.13959620562682,
                40.54288566658089
              ],
              [
                -74.13960709890746,
                40.54289359242596
              ],
              [
                -74.13968218054617,
                40.54294821681452
              ],
              [
                -74.13969250376593,
                40.5429557266972
              ],
              [
                -74.13976927269164,
                40.54301157915038
              ],
              [
                -74.13977902783273,
                40.54301867692245
              ],
              [
                -74.1398541018354,
                40.543073295679186
              ],
              [
                -74.13986504774401,
                40.54308126047014
              ],
              [
                -74.13993952959298,
                40.54313544900449
              ],
              [
                -74.1399503890821,
                40.54314334905833
              ],
              [
                -74.14002606731881,
                40.54319840723553
              ],
              [
                -74.14003694970397,
                40.54320632484383
              ],
              [
                -74.14011092948785,
                40.54326014715562
              ],
              [
                -74.14012345022545,
                40.54326925674442
              ],
              [
                -74.14019685632545,
                40.54332266188051
              ],
              [
                -74.14020997730147,
                40.54333220714313
              ],
              [
                -74.14028734539116,
                40.543385071119204
              ],
              [
                -74.14012638219599,
                40.54351339341687
              ],
              [
                -74.1402138440382,
                40.543574786483035
              ],
              [
                -74.140209375695,
                40.54357906228395
              ],
              [
                -74.14029571947157,
                40.54364045715551
              ],
              [
                -74.14022641531263,
                40.54369606282558
              ],
              [
                -74.14003465964629,
                40.5435570671846
              ],
              [
                -74.14002080798198,
                40.54354708252372
              ],
              [
                -74.13996556411055,
                40.54350725674794
              ],
              [
                -74.1399522746247,
                40.543497676481486
              ],
              [
                -74.13991412345699,
                40.54347017330392
              ],
              [
                -74.13988439282308,
                40.54344874069939
              ],
              [
                -74.13985313210576,
                40.543426203748815
              ],
              [
                -74.1398181529452,
                40.54340098748385
              ],
              [
                -74.1397617742255,
                40.543360343936634
              ],
              [
                -74.13974850481078,
                40.543350777359564
              ],
              [
                -74.13969380758877,
                40.54331134716391
              ],
              [
                -74.13968226733104,
                40.543303027915805
              ],
              [
                -74.13962652307069,
                40.54326284139024
              ],
              [
                -74.13961721033611,
                40.543256127381724
              ],
              [
                -74.1395574303407,
                40.543213031206584
              ],
              [
                -74.13954690313827,
                40.543205441982884
              ],
              [
                -74.13948893219914,
                40.543163650141516
              ],
              [
                -74.13947791081843,
                40.543155704672564
              ],
              [
                -74.13945177529892,
                40.54313686363685
              ],
              [
                -74.13942485990556,
                40.54311746030542
              ],
              [
                -74.13938508033534,
                40.54308878186187
              ],
              [
                -74.1393844546519,
                40.543076869046324
              ],
              [
                -74.13943356778354,
                40.54303606758095
              ],
              [
                -74.13940810077588,
                40.54301658508782
              ],
              [
                -74.13929760323849,
                40.54310599821052
              ],
              [
                -74.13926620242248,
                40.54313029048412
              ],
              [
                -74.13923987000206,
                40.543150663097634
              ],
              [
                -74.13921993485874,
                40.5431660851379
              ],
              [
                -74.13924181634614,
                40.54318196207973
              ],
              [
                -74.13934994841898,
                40.54309608509422
              ],
              [
                -74.1394343803688,
                40.54315923245202
              ],
              [
                -74.13969436838009,
                40.54334650202359
              ],
              [
                -74.13970183036301,
                40.543351877198226
              ],
              [
                -74.13974757593654,
                40.54338482682481
              ],
              [
                -74.13978359729646,
                40.54341077311183
              ],
              [
                -74.13982300152821,
                40.54343915702091
              ],
              [
                -74.13986402298173,
                40.5434687032816
              ],
              [
                -74.13989283556387,
                40.54348945633452
              ],
              [
                -74.13994566703632,
                40.54352751027062
              ],
              [
                -74.14000281637705,
                40.54356867430941
              ],
              [
                -74.14001232514708,
                40.54357552424944
              ],
              [
                -74.14005970538267,
                40.543609651003294
              ],
              [
                -74.14008209744807,
                40.54362578013274
              ],
              [
                -74.14012372242281,
                40.543655761980226
              ],
              [
                -74.14014813068958,
                40.54367334296596
              ],
              [
                -74.14019945499791,
                40.54371031168871
              ],
              [
                -74.14021461687776,
                40.54372123189196
              ],
              [
                -74.1402435066554,
                40.54374204004764
              ],
              [
                -74.14028458104862,
                40.54377162512751
              ],
              [
                -74.14028491492418,
                40.543785259834515
              ],
              [
                -74.14023491179564,
                40.54382610831667
              ],
              [
                -74.14024817472753,
                40.54383428652249
              ],
              [
                -74.14029681353321,
                40.543793167967564
              ],
              [
                -74.1403143311892,
                40.54379395573369
              ],
              [
                -74.14032848694484,
                40.54380208727105
              ],
              [
                -74.14032945189241,
                40.543807838073484
              ],
              [
                -74.14030865805942,
                40.54382531175345
              ],
              [
                -74.14046054656262,
                40.54393201735663
              ],
              [
                -74.14039544536466,
                40.54398901669547
              ],
              [
                -74.14041765335297,
                40.54400272517297
              ],
              [
                -74.14038573073617,
                40.54403318500786
              ],
              [
                -74.14035904817948,
                40.54401661756249
              ],
              [
                -74.14031078013868,
                40.54405040205576
              ],
              [
                -74.14024719836523,
                40.543995850816316
              ],
              [
                -74.14024685174626,
                40.54399560363005
              ],
              [
                -74.14005310717077,
                40.54385885083264
              ],
              [
                -74.14001283835921,
                40.543830426844764
              ],
              [
                -74.13999383559145,
                40.54384582593514
              ],
              [
                -74.14004115970624,
                40.54388399962383
              ],
              [
                -74.1398652726434,
                40.54402684400953
              ],
              [
                -74.13970350694184,
                40.54390877718631
              ],
              [
                -74.13988897406256,
                40.54375247188493
              ],
              [
                -74.13986496495076,
                40.543736823321424
              ],
              [
                -74.1396764048069,
                40.54388899585859
              ],
              [
                -74.13902553215638,
                40.54341393868392
              ],
              [
                -74.13898355608593,
                40.54338441137294
              ],
              [
                -74.13899592102271,
                40.54337336940084
              ],
              [
                -74.13894447378158,
                40.54333946290867
              ],
              [
                -74.13906073296394,
                40.543245256310556
              ],
              [
                -74.1390367192296,
                40.54322699394063
              ],
              [
                -74.13886061078239,
                40.5433656938733
              ],
              [
                -74.13877140226839,
                40.54329133220295
              ],
              [
                -74.13893037818052,
                40.54315004000065
              ],
              [
                -74.13890636539442,
                40.5431317776023
              ],
              [
                -74.13870632397268,
                40.5432914108344
              ],
              [
                -74.13891041386134,
                40.54343226458148
              ],
              [
                -74.1389463277329,
                40.54340739868264
              ],
              [
                -74.13983697786095,
                40.54405246234075
              ],
              [
                -74.13956971698569,
                40.544272274830455
              ],
              [
                -74.13953345753629,
                40.54424691512262
              ],
              [
                -74.13947061254812,
                40.54420277740847
              ],
              [
                -74.13945826107069,
                40.54419410245184
              ],
              [
                -74.13945300079232,
                40.544190408768735
              ],
              [
                -74.13942349832641,
                40.54416968827456
              ],
              [
                -74.13931717941932,
                40.544095018943615
              ],
              [
                -74.13931136009417,
                40.54409093123117
              ],
              [
                -74.13930377144462,
                40.544085602925826
              ],
              [
                -74.13928076576917,
                40.544069444066565
              ],
              [
                -74.13922741147739,
                40.54403197178988
              ],
              [
                -74.13918578603182,
                40.54400273730637
              ],
              [
                -74.13915570620212,
                40.543981612355275
              ],
              [
                -74.13910166037837,
                40.54394365502056
              ],
              [
                -74.13908167507361,
                40.5439296180854
              ],
              [
                -74.1390334816121,
                40.54389577085168
              ],
              [
                -74.13881702344845,
                40.54374374545133
              ],
              [
                -74.13872959207069,
                40.54368233939185
              ],
              [
                -74.13853314542554,
                40.543544367340814
              ],
              [
                -74.13862033031373,
                40.54347251212417
              ],
              [
                -74.1385900530627,
                40.543450340366675
              ],
              [
                -74.13849280865536,
                40.54353074955564
              ],
              [
                -74.13845971967868,
                40.543506285991576
              ],
              [
                -74.1385559775718,
                40.54342415648043
              ],
              [
                -74.13852282835339,
                40.54340155175448
              ],
              [
                -74.13834151288822,
                40.54355182881406
              ],
              [
                -74.13837491744677,
                40.54357651886772
              ],
              [
                -74.1384467162898,
                40.543515551682574
              ],
              [
                -74.13848274781854,
                40.54353930304899
              ],
              [
                -74.13841121199921,
                40.54359832759451
              ],
              [
                -74.13844148726754,
                40.543619644662314
              ],
              [
                -74.13844819325458,
                40.543613657257
              ],
              [
                -74.13850743574893,
                40.543564898857724
              ],
              [
                -74.13850743752053,
                40.54356575359462
              ],
              [
                -74.13852649744571,
                40.54357854230606
              ],
              [
                -74.13846595774761,
                40.5436291045691
              ],
              [
                -74.13845605241477,
                40.54362389912065
              ],
              [
                -74.13843929330488,
                40.543641855612044
              ],
              [
                -74.13841801795566,
                40.54364102702419
              ],
              [
                -74.13836531286525,
                40.54360179969039
              ],
              [
                -74.13815068621103,
                40.54377203675709
              ],
              [
                -74.13827964456058,
                40.54386669457058
              ],
              [
                -74.13829305440626,
                40.54385386621483
              ],
              [
                -74.13820558623709,
                40.54378905441557
              ],
              [
                -74.1382066726708,
                40.54377367836005
              ],
              [
                -74.1382659207829,
                40.5437274814488
              ],
              [
                -74.13829833483882,
                40.543700109025494
              ],
              [
                -74.13835645886374,
                40.54365220679084
              ],
              [
                -74.13837773022571,
                40.54365047218311
              ],
              [
                -74.1384618395175,
                40.543715287902266
              ],
              [
                -74.13847431012198,
                40.5437051466693
              ],
              [
                -74.13844876639854,
                40.54368614654391
              ],
              [
                -74.13844809117643,
                40.54367865239243
              ],
              [
                -74.1384611529643,
                40.54366427629004
              ],
              [
                -74.13852347275918,
                40.54361453211016
              ],
              [
                -74.13852077238342,
                40.543603992525334
              ],
              [
                -74.1385399626842,
                40.54358792869272
              ],
              [
                -74.13882325461655,
                40.5437840548958
              ],
              [
                -74.1387971330161,
                40.543802712312335
              ],
              [
                -74.13878541161911,
                40.54380148189836
              ],
              [
                -74.13876624485728,
                40.543817037959315
              ],
              [
                -74.13874890783579,
                40.54383110849365
              ],
              [
                -74.13872636239431,
                40.54384940523604
              ],
              [
                -74.13870808505072,
                40.54386424000037
              ],
              [
                -74.13868576145339,
                40.54388235586906
              ],
              [
                -74.13862979916559,
                40.54392777269482
              ],
              [
                -74.13860340384754,
                40.54394919380778
              ],
              [
                -74.1385856815562,
                40.5439635765561
              ],
              [
                -74.1385228670691,
                40.54401455438708
              ],
              [
                -74.1385117433724,
                40.544023582940135
              ],
              [
                -74.13849606963167,
                40.544023601845794
              ],
              [
                -74.13846102593133,
                40.54399777091345
              ],
              [
                -74.13842317799855,
                40.54396987653108
              ],
              [
                -74.13841535838714,
                40.54397842731629
              ],
              [
                -74.13844151523679,
                40.5439977944383
              ],
              [
                -74.1385858115911,
                40.54410463849036
              ],
              [
                -74.13859811050895,
                40.54409608178442
              ],
              [
                -74.13853867546942,
                40.544051736900585
              ],
              [
                -74.1385375337886,
                40.544041488613864
              ],
              [
                -74.13854381696055,
                40.54403640369568
              ],
              [
                -74.1386286421722,
                40.54396773640719
              ],
              [
                -74.13865437747432,
                40.543946903446276
              ],
              [
                -74.13872431510785,
                40.54389028842588
              ],
              [
                -74.13872880510179,
                40.54388665379488
              ],
              [
                -74.13878904068672,
                40.54383789237635
              ],
              [
                -74.13881019318308,
                40.54382320841886
              ],
              [
                -74.13880325724111,
                40.54381268384516
              ],
              [
                -74.13883460585724,
                40.54379135939306
              ],
              [
                -74.13895116445453,
                40.543877450046175
              ],
              [
                -74.13907750658639,
                40.543960153165706
              ],
              [
                -74.13905058643817,
                40.54398004831456
              ],
              [
                -74.1390416557895,
                40.54397471223546
              ],
              [
                -74.13882839412233,
                40.544159241246646
              ],
              [
                -74.13880747279141,
                40.54416325744312
              ],
              [
                -74.13874595001212,
                40.544115549508945
              ],
              [
                -74.1387281244596,
                40.544130022535
              ],
              [
                -74.13874816730304,
                40.544145442256145
              ],
              [
                -74.13875374875403,
                40.54414973668163
              ],
              [
                -74.13881357204203,
                40.54419575963728
              ],
              [
                -74.13886309560387,
                40.544233859280354
              ],
              [
                -74.13887570388356,
                40.54424355793479
              ],
              [
                -74.13889733667764,
                40.5442255537703
              ],
              [
                -74.1388390213371,
                40.54418206192403
              ],
              [
                -74.13883900359002,
                40.54417352008401
              ],
              [
                -74.13889225032632,
                40.54413087320302
              ],
              [
                -74.13890291514562,
                40.54412233217857
              ],
              [
                -74.13891491404725,
                40.54411272119909
              ],
              [
                -74.13895536858136,
                40.54408031965933
              ],
              [
                -74.13896631072416,
                40.544071555980665
              ],
              [
                -74.13900724995295,
                40.54403876684108
              ],
              [
                -74.13906669135486,
                40.54399115683618
              ],
              [
                -74.13905919087385,
                40.54398533704194
              ],
              [
                -74.13908266855009,
                40.54396822488395
              ],
              [
                -74.13912214559907,
                40.54399697181448
              ],
              [
                -74.13920947206519,
                40.5440605659809
              ],
              [
                -74.13922032859912,
                40.5440667006738
              ],
              [
                -74.13919553562482,
                40.54408546512921
              ],
              [
                -74.13924591987264,
                40.544121518690986
              ],
              [
                -74.13922249824198,
                40.54414055607116
              ],
              [
                -74.13921398233327,
                40.54413466279976
              ],
              [
                -74.13920592451963,
                40.54414173279326
              ],
              [
                -74.13916396309388,
                40.544178548825236
              ],
              [
                -74.1391556516453,
                40.54418584042981
              ],
              [
                -74.13915675876315,
                40.544198110506606
              ],
              [
                -74.1390105180372,
                40.5443187899229
              ],
              [
                -74.13897193186773,
                40.54434889623473
              ],
              [
                -74.13895983828961,
                40.544349323664896
              ],
              [
                -74.13891817604893,
                40.5443200698636
              ],
              [
                -74.13890743048366,
                40.54432897553262
              ],
              [
                -74.13897454226765,
                40.54437610181971
              ],
              [
                -74.1392876729529,
                40.54411658940466
              ],
              [
                -74.13933895032284,
                40.54415259794938
              ],
              [
                -74.13942069337877,
                40.54420594353719
              ],
              [
                -74.13941007968153,
                40.544214121174704
              ],
              [
                -74.13940497441412,
                40.544218096330454
              ],
              [
                -74.13938357415316,
                40.54423475537623
              ],
              [
                -74.1393777453498,
                40.54423929246763
              ],
              [
                -74.13933178866621,
                40.544275070367064
              ],
              [
                -74.13932514949839,
                40.54428023701225
              ],
              [
                -74.13927673845099,
                40.544317925533655
              ],
              [
                -74.13926952681004,
                40.544323539170435
              ],
              [
                -74.13922660687233,
                40.5443569516712
              ],
              [
                -74.13911264176714,
                40.54444566936905
              ],
              [
                -74.13909334926191,
                40.544460688869236
              ],
              [
                -74.13904134663564,
                40.544421405376255
              ],
              [
                -74.13903265990314,
                40.544428097333046
              ],
              [
                -74.13910029753151,
                40.54447919136464
              ],
              [
                -74.13910271724939,
                40.54447732750825
              ],
              [
                -74.13914541346325,
                40.54450958112509
              ],
              [
                -74.13915108497609,
                40.54450521159891
              ],
              [
                -74.13910838810352,
                40.54447295798487
              ],
              [
                -74.13914525114242,
                40.5444450210537
              ],
              [
                -74.13915187461555,
                40.544440001529445
              ],
              [
                -74.13918807961167,
                40.54441256277895
              ],
              [
                -74.13919652383179,
                40.54440616428203
              ],
              [
                -74.13923613063527,
                40.54437614611859
              ],
              [
                -74.13924473709938,
                40.54436962445412
              ],
              [
                -74.13928593614011,
                40.544338401140664
              ],
              [
                -74.1392930274949,
                40.544333026379334
              ],
              [
                -74.13931281145453,
                40.54431803271881
              ],
              [
                -74.13931996946161,
                40.544312607950964
              ],
              [
                -74.13936463648669,
                40.54427875687849
              ],
              [
                -74.13937093607659,
                40.54427398199373
              ],
              [
                -74.13942091245107,
                40.54423610657838
              ],
              [
                -74.13941436189043,
                40.544231634439676
              ],
              [
                -74.13942231998007,
                40.54422540119846
              ],
              [
                -74.13943253002637,
                40.54421721751617
              ],
              [
                -74.13953937915736,
                40.544295140872144
              ],
              [
                -74.13903307178626,
                40.544704910299984
              ],
              [
                -74.13810812626468,
                40.54403934450325
              ],
              [
                -74.13809952422086,
                40.544033160923064
              ],
              [
                -74.13807505088938,
                40.54401556534103
              ],
              [
                -74.13804967625835,
                40.543998266193064
              ],
              [
                -74.1380100182887,
                40.54397122691454
              ],
              [
                -74.1380658961542,
                40.54391991024749
              ],
              [
                -74.138043468979,
                40.54390456296631
              ],
              [
                -74.13793218253984,
                40.54399840738909
              ],
              [
                -74.13786798524545,
                40.54405254413092
              ],
              [
                -74.13789377550259,
                40.544070451121904
              ],
              [
                -74.13797997082938,
                40.54399834997484
              ],
              [
                -74.13798990164418,
                40.54399004378927
              ],
              [
                -74.138001949898,
                40.54399832356188
              ],
              [
                -74.13804820788744,
                40.544030118796954
              ],
              [
                -74.13806166435305,
                40.5440394992188
              ],
              [
                -74.138085611978,
                40.5440563641667
              ],
              [
                -74.13816260914278,
                40.54411058868597
              ],
              [
                -74.13818669933056,
                40.54412755345665
              ],
              [
                -74.13825826576176,
                40.544177953199345
              ],
              [
                -74.13828211671824,
                40.54419475020519
              ],
              [
                -74.13835673677613,
                40.544247299809605
              ],
              [
                -74.13837770364078,
                40.544262065281515
              ],
              [
                -74.13843350741396,
                40.54430136409345
              ],
              [
                -74.13845692519634,
                40.54431785466996
              ],
              [
                -74.1384801032704,
                40.54433417766551
              ],
              [
                -74.13853352313339,
                40.54437179780327
              ],
              [
                -74.13854051357738,
                40.544376719615116
              ],
              [
                -74.1386884970685,
                40.544480934559544
              ],
              [
                -74.13874002777955,
                40.544519037862
              ],
              [
                -74.13876268535786,
                40.544536022838415
              ],
              [
                -74.13881594374128,
                40.544573350532005
              ],
              [
                -74.1388407458787,
                40.5445891931926
              ],
              [
                -74.13889454402936,
                40.54462752102231
              ],
              [
                -74.13891755659161,
                40.54464345914525
              ],
              [
                -74.13896956674542,
                40.544681879066616
              ],
              [
                -74.13899095203578,
                40.5446960197026
              ],
              [
                -74.13901965079306,
                40.54471556102991
              ],
              [
                -74.13875534703455,
                40.54493395842689
              ],
              [
                -74.13873484040181,
                40.54491782006107
              ],
              [
                -74.1387350968491,
                40.54490509918511
              ],
              [
                -74.13871819443702,
                40.54489328510631
              ],
              [
                -74.13865724010041,
                40.54485067790132
              ],
              [
                -74.13864197640305,
                40.544840009246656
              ],
              [
                -74.13858052996493,
                40.54479705832047
              ],
              [
                -74.13856268411445,
                40.54478458394825
              ],
              [
                -74.13850257186327,
                40.54474256685877
              ],
              [
                -74.13848461013943,
                40.544730011194396
              ],
              [
                -74.13842530203979,
                40.544688555325976
              ],
              [
                -74.13840669397882,
                40.54467554809705
              ],
              [
                -74.1383481216329,
                40.54463460679212
              ],
              [
                -74.13832768600864,
                40.54462032215693
              ],
              [
                -74.13827073483874,
                40.5445805135414
              ],
              [
                -74.1382488081467,
                40.54456518764461
              ],
              [
                -74.13819352235129,
                40.54452654182296
              ],
              [
                -74.13817085058372,
                40.544510694783966
              ],
              [
                -74.13811742545273,
                40.54447334940142
              ],
              [
                -74.13810064050065,
                40.544458651895795
              ],
              [
                -74.13804694766422,
                40.544504184316196
              ],
              [
                -74.1380279169008,
                40.544505916169
              ],
              [
                -74.13786250173462,
                40.544388423261594
              ],
              [
                -74.1378440051603,
                40.544375286306845
              ],
              [
                -74.13781048758284,
                40.54435147844956
              ],
              [
                -74.13773987886329,
                40.544301325392084
              ],
              [
                -74.1377049727256,
                40.544276531579214
              ],
              [
                -74.1376791831006,
                40.54425862571803
              ],
              [
                -74.13767915494255,
                40.54424495880721
              ],
              [
                -74.13777975695223,
                40.544162838482976
              ],
              [
                -74.13775620755665,
                40.5441449287782
              ],
              [
                -74.13762430745145,
                40.544253566418256
              ],
              [
                -74.13804143918792,
                40.54454775280513
              ],
              [
                -74.13809284654715,
                40.54449985777751
              ],
              [
                -74.13810404192895,
                40.544498990579456
              ],
              [
                -74.13812198137776,
                40.544510927641255
              ],
              [
                -74.1381604783657,
                40.54453836398358
              ],
              [
                -74.1381781226809,
                40.544550938173835
              ],
              [
                -74.13834296955775,
                40.54466842092398
              ],
              [
                -74.13836893908898,
                40.54468692919268
              ],
              [
                -74.13842126769558,
                40.54472422250521
              ],
              [
                -74.1384337707142,
                40.54473313243514
              ],
              [
                -74.13845820251454,
                40.544750543539486
              ],
              [
                -74.13849651239545,
                40.54477784664054
              ],
              [
                -74.13852070494373,
                40.54479508898215
              ],
              [
                -74.13856177219147,
                40.54482435570691
              ],
              [
                -74.13858503290798,
                40.54484093272434
              ],
              [
                -74.13862752649028,
                40.54487121645504
              ],
              [
                -74.13865026247518,
                40.544887419662814
              ],
              [
                -74.13868781593632,
                40.544914183495905
              ],
              [
                -74.13870784432727,
                40.54492845649669
              ],
              [
                -74.13872158725741,
                40.544929286927655
              ],
              [
                -74.13874315087111,
                40.54494542452122
              ],
              [
                -74.13869488246425,
                40.54499107030514
              ],
              [
                -74.13886951340419,
                40.54513019760729
              ],
              [
                -74.13884969845212,
                40.545196524663865
              ],
              [
                -74.13882109457529,
                40.545260957841336
              ],
              [
                -74.13878400176415,
                40.545322821324575
              ],
              [
                -74.13873880904883,
                40.545381466249246
              ],
              [
                -74.13868599042074,
                40.54543627750762
              ],
              [
                -74.13862609986093,
                40.54548668020075
              ],
              [
                -74.1385597655297,
                40.54553214566852
              ],
              [
                -74.1384876831793,
                40.54557219703495
              ],
              [
                -74.13841060885535,
                40.54560641421049
              ],
              [
                -74.13829297544638,
                40.545705858941645
              ],
              [
                -74.1382249378197,
                40.54579463735954
              ],
              [
                -74.13806608226764,
                40.54591334131473
              ],
              [
                -74.13794966050746,
                40.54600033758067
              ],
              [
                -74.13788456932015,
                40.54589401852868
              ],
              [
                -74.13754932972272,
                40.545584865897354
              ],
              [
                -74.13752877848904,
                40.54556547209198
              ],
              [
                -74.13751799428782,
                40.54557179804659
              ],
              [
                -74.13750022510571,
                40.545554216261195
              ],
              [
                -74.13750293136816,
                40.545552714965865
              ],
              [
                -74.13744723484227,
                40.54549436974272
              ],
              [
                -74.1375299444234,
                40.54544842357019
              ],
              [
                -74.13750289960466,
                40.545420093269264
              ],
              [
                -74.13748786286484,
                40.545428445564696
              ],
              [
                -74.13747166103315,
                40.54541147405328
              ],
              [
                -74.13741558802171,
                40.54544262354305
              ],
              [
                -74.13743335927994,
                40.5454612400054
              ],
              [
                -74.13742434617903,
                40.5454662480497
              ],
              [
                -74.13674888757022,
                40.54478258448059
              ],
              [
                -74.13689095294764,
                40.54469944270675
              ],
              [
                -74.13687348630864,
                40.54468259391001
              ],
              [
                -74.13683300071517,
                40.54470532373606
              ],
              [
                -74.13663388681591,
                40.54482118663931
              ],
              [
                -74.13658907071063,
                40.54484548609593
              ],
              [
                -74.13661187110704,
                40.54486207545211
              ],
              [
                -74.13671451130512,
                40.544803653968565
              ],
              [
                -74.1367337016138,
                40.54480374202387
              ],
              [
                -74.13674461047367,
                40.54481682620692
              ],
              [
                -74.13680670009056,
                40.54487962598706
              ],
              [
                -74.13681588562174,
                40.54488891782574
              ],
              [
                -74.13684118434955,
                40.54491450555559
              ],
              [
                -74.13684436011359,
                40.54491771650744
              ],
              [
                -74.13724077148176,
                40.54531865707779
              ],
              [
                -74.13724536078695,
                40.54532329971711
              ],
              [
                -74.13730652265437,
                40.54538515948499
              ],
              [
                -74.13731811323326,
                40.54539688245312
              ],
              [
                -74.13734181068475,
                40.54542085046247
              ],
              [
                -74.13735634623878,
                40.54543555177376
              ],
              [
                -74.1374154359189,
                40.54549531581418
              ],
              [
                -74.13742976155726,
                40.545509803933946
              ],
              [
                -74.13745005439412,
                40.54553225147104
              ],
              [
                -74.13746656856912,
                40.54555051929484
              ],
              [
                -74.1374795860376,
                40.545564917837936
              ],
              [
                -74.13748941991938,
                40.54555909578281
              ],
              [
                -74.13750833508846,
                40.545577557238616
              ],
              [
                -74.13719287519704,
                40.545784294700084
              ],
              [
                -74.1371728196836,
                40.54576586973615
              ],
              [
                -74.13717031824072,
                40.545756748864434
              ],
              [
                -74.13661259610625,
                40.5452511903706
              ],
              [
                -74.13660795667722,
                40.54524692772415
              ],
              [
                -74.13643917174414,
                40.54509186194526
              ],
              [
                -74.13643522519202,
                40.545088236925906
              ],
              [
                -74.13636510853374,
                40.545023819258454
              ],
              [
                -74.13628433241061,
                40.544947040589264
              ],
              [
                -74.13640287317463,
                40.54487429511146
              ],
              [
                -74.13638379801961,
                40.54485467150623
              ],
              [
                -74.1362395396176,
                40.544944531201615
              ],
              [
                -74.13611540724054,
                40.54501984567978
              ],
              [
                -74.13613448258246,
                40.54503946882655
              ],
              [
                -74.13626085280931,
                40.544963297435935
              ],
              [
                -74.1364428921272,
                40.54513067191817
              ],
              [
                -74.13650122481617,
                40.54518430605995
              ],
              [
                -74.13652810778214,
                40.54520902182314
              ],
              [
                -74.13658821554525,
                40.54526428806667
              ],
              [
                -74.13661515336315,
                40.54528905484097
              ],
              [
                -74.13666058054599,
                40.545330821842896
              ],
              [
                -74.13667927562504,
                40.545348011808215
              ],
              [
                -74.13672532379807,
                40.54539034898805
              ],
              [
                -74.13674365036569,
                40.54540719896034
              ],
              [
                -74.13679269845123,
                40.545452294945505
              ],
              [
                -74.13681049369205,
                40.54546865702209
              ],
              [
                -74.13685874691485,
                40.5455130226567
              ],
              [
                -74.13687689400858,
                40.545529707636824
              ],
              [
                -74.13692680169478,
                40.54557559244442
              ],
              [
                -74.13694478446436,
                40.54559212716765
              ],
              [
                -74.13699494197701,
                40.545638242839146
              ],
              [
                -74.1370106897442,
                40.545652721607794
              ],
              [
                -74.13706259040472,
                40.54570044027626
              ],
              [
                -74.13707821344494,
                40.54571480392378
              ],
              [
                -74.13712777420874,
                40.54576037175565
              ],
              [
                -74.1371427044082,
                40.54577409776434
              ],
              [
                -74.13715619537318,
                40.5457731072957
              ],
              [
                -74.1371798712987,
                40.5457928646606
              ],
              [
                -74.13706316637999,
                40.545864007992286
              ],
              [
                -74.13713259013673,
                40.54593097435956
              ],
              [
                -74.13709627808949,
                40.545952218659515
              ],
              [
                -74.13702342911232,
                40.54588496435272
              ],
              [
                -74.13700894772,
                40.54589465599585
              ],
              [
                -74.13706446059666,
                40.54595749648294
              ],
              [
                -74.13697129151097,
                40.545974987902724
              ],
              [
                -74.13680622121552,
                40.54597049752417
              ],
              [
                -74.13658511342636,
                40.54592318217119
              ],
              [
                -74.13651613439366,
                40.54591474813772
              ],
              [
                -74.13645912181923,
                40.54597500747673
              ],
              [
                -74.13649063050026,
                40.54607322445245
              ],
              [
                -74.13641153119026,
                40.546252350041875
              ],
              [
                -74.13585712583111,
                40.546451696357344
              ],
              [
                -74.13700629799996,
                40.54696777099985
              ],
              [
                -74.13744805299991,
                40.547305328999876
              ],
              [
                -74.13820137199997,
                40.54803495999987
              ],
              [
                -74.13861839799995,
                40.54845247299989
              ],
              [
                -74.13861798499994,
                40.548492384999875
              ],
              [
                -74.13769731599992,
                40.549383545999916
              ],
              [
                -74.13738988199998,
                40.5495923699999
              ],
              [
                -74.13607343399998,
                40.5504332999999
              ],
              [
                -74.13528075999987,
                40.55086323899991
              ],
              [
                -74.13455868199995,
                40.551255077999876
              ],
              [
                -74.13316825999992,
                40.55235617899994
              ],
              [
                -74.13321002499998,
                40.552383875999936
              ],
              [
                -74.13376761399991,
                40.55281510299994
              ],
              [
                -74.13436896499996,
                40.55330607999985
              ],
              [
                -74.13498726199998,
                40.55381088399986
              ],
              [
                -74.13609776299991,
                40.55472110499991
              ],
              [
                -74.137431255,
                40.55581758299994
              ],
              [
                -74.13754790999995,
                40.555906577999956
              ],
              [
                -74.13769985100001,
                40.556027345999944
              ],
              [
                -74.13885092699991,
                40.5569511789999
              ],
              [
                -74.13818000999993,
                40.55759386599989
              ],
              [
                -74.13739437899987,
                40.558020936999874
              ],
              [
                -74.13660029399993,
                40.55838893899992
              ],
              [
                -74.13574932499998,
                40.55885004499987
              ],
              [
                -74.13561300299993,
                40.55898002699994
              ],
              [
                -74.13549208699995,
                40.559120344999876
              ],
              [
                -74.13538846199995,
                40.559269341999915
              ],
              [
                -74.13530363499989,
                40.5594250889999
              ],
              [
                -74.13523859399992,
                40.55958551299993
              ],
              [
                -74.13519379599992,
                40.55974841799983
              ],
              [
                -74.134809734,
                40.560828617999924
              ],
              [
                -74.13396833899998,
                40.561586992999885
              ],
              [
                -74.13367116399996,
                40.561840933999925
              ],
              [
                -74.13340740099986,
                40.5621298059999
              ],
              [
                -74.13310979199996,
                40.56286769099989
              ],
              [
                -74.13264383999997,
                40.563503334999915
              ],
              [
                -74.13170009,
                40.56453574899992
              ],
              [
                -74.13240280399998,
                40.564760999999876
              ],
              [
                -74.13329816299988,
                40.56497397399989
              ],
              [
                -74.13459671299997,
                40.5652709339999
              ],
              [
                -74.13493919999993,
                40.56538743599988
              ],
              [
                -74.13527417299991,
                40.565517760999896
              ],
              [
                -74.135600208,
                40.5656614559999
              ],
              [
                -74.1359159259999,
                40.56581796099992
              ],
              [
                -74.13622008499992,
                40.56598659899989
              ],
              [
                -74.13651149199997,
                40.566166621999876
              ],
              [
                -74.13768350999999,
                40.566717866999966
              ],
              [
                -74.13889993799994,
                40.567293035999946
              ],
              [
                -74.13976413499992,
                40.56771224499989
              ],
              [
                -74.14026824299997,
                40.56795018899986
              ],
              [
                -74.1413123359999,
                40.568433347999914
              ],
              [
                -74.14151969499997,
                40.568520308999894
              ],
              [
                -74.1428731119999,
                40.56908188399994
              ],
              [
                -74.14438744999991,
                40.56970610099992
              ],
              [
                -74.14489975699995,
                40.569915169999945
              ],
              [
                -74.14559994099997,
                40.57021110699987
              ],
              [
                -74.14629083699998,
                40.570496730999906
              ],
              [
                -74.146347254,
                40.57051823399994
              ],
              [
                -74.14640641799993,
                40.5705348979999
              ],
              [
                -74.1464676189999,
                40.57054652199994
              ],
              [
                -74.14653012099988,
                40.5705529679999
              ],
              [
                -74.14659317299999,
                40.57055415699992
              ],
              [
                -74.14665601599995,
                40.57055007499991
              ],
              [
                -74.14671789599998,
                40.57054077199993
              ],
              [
                -74.14677806799996,
                40.5705263589999
              ],
              [
                -74.14684848799997,
                40.5705136709999
              ],
              [
                -74.14692022299994,
                40.57050644499988
              ],
              [
                -74.14699254699993,
                40.570504754999874
              ],
              [
                -74.14706472699994,
                40.5705086149999
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000525745098785,
        "objectid": 110,
        "shape_leng": 0.103946292913,
        "location_id": 110,
        "zone": "Great Kills Park",
        "borough": "Staten Island"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -74.11813477599993,
                40.550592692999906
              ],
              [
                -74.11876291999992,
                40.55121611399988
              ],
              [
                -74.11852157499992,
                40.55143427699991
              ],
              [
                -74.11936210999988,
                40.55214080699988
              ],
              [
                -74.1194585929999,
                40.5522219059999
              ],
              [
                -74.12076288199995,
                40.55308194999988
              ],
              [
                -74.12269895799986,
                40.554358501999936
              ],
              [
                -74.12380031599992,
                40.55508465799989
              ],
              [
                -74.12402047099992,
                40.555230827999914
              ],
              [
                -74.12572675099993,
                40.55632805699986
              ],
              [
                -74.1272522789999,
                40.55726549099991
              ],
              [
                -74.12730057799996,
                40.557295171999954
              ],
              [
                -74.12811697199996,
                40.556534435999886
              ],
              [
                -74.12896141599992,
                40.555769871999864
              ],
              [
                -74.12975602399996,
                40.55503262499995
              ],
              [
                -74.13042459599988,
                40.55443704199992
              ],
              [
                -74.13108665199998,
                40.5539737589999
              ],
              [
                -74.13177828099997,
                40.553489970999884
              ],
              [
                -74.13316825999992,
                40.55235617899994
              ],
              [
                -74.13455868199995,
                40.551255077999876
              ],
              [
                -74.13528075999987,
                40.55086323899991
              ],
              [
                -74.13607343399998,
                40.5504332999999
              ],
              [
                -74.13738988199998,
                40.5495923699999
              ],
              [
                -74.13769731599992,
                40.549383545999916
              ],
              [
                -74.13861798499991,
                40.54849238399989
              ],
              [
                -74.13861839799995,
                40.54845247299989
              ],
              [
                -74.13820137199997,
                40.54803495999987
              ],
              [
                -74.13744805299991,
                40.547305328999876
              ],
              [
                -74.13700629799996,
                40.54696777099985
              ],
              [
                -74.13585712583111,
                40.546451696357344
              ],
              [
                -74.13564130176358,
                40.54651542222417
              ],
              [
                -74.13542312360997,
                40.54657429849452
              ],
              [
                -74.13520277840065,
                40.54662827500777
              ],
              [
                -74.13498045519505,
                40.54667730545007
              ],
              [
                -74.13475634475162,
                40.54672134775148
              ],
              [
                -74.13453063936326,
                40.54676036412177
              ],
              [
                -74.13435503365305,
                40.546788934324596
              ],
              [
                -74.13417820332243,
                40.54681270650721
              ],
              [
                -74.134000373591,
                40.546831650391844
              ],
              [
                -74.13382177095171,
                40.5468457418508
              ],
              [
                -74.13364262288287,
                40.54685496293633
              ],
              [
                -74.13346993318864,
                40.54685516378519
              ],
              [
                -74.1334321265842,
                40.54685323086642
              ],
              [
                -74.13325905654519,
                40.54684725133727
              ],
              [
                -74.13308664365854,
                40.546834295093575
              ],
              [
                -74.13291537090788,
                40.54681439842987
              ],
              [
                -74.13274571808144,
                40.54678761708334
              ],
              [
                -74.13257816042828,
                40.54675402607676
              ],
              [
                -74.13200385775187,
                40.546640680766075
              ],
              [
                -74.13177588719115,
                40.54658526949439
              ],
              [
                -74.13155026188892,
                40.546524529064385
              ],
              [
                -74.13132719587999,
                40.546458517098046
              ],
              [
                -74.13110690077004,
                40.546387296218136
              ],
              [
                -74.13088958553415,
                40.54631093398849
              ],
              [
                -74.13072654082103,
                40.54625519035943
              ],
              [
                -74.1305671158249,
                40.54619365521264
              ],
              [
                -74.13041166382702,
                40.54612646491237
              ],
              [
                -74.13026052930192,
                40.546053768354206
              ],
              [
                -74.13011404715266,
                40.545975726635675
              ],
              [
                -74.12999068950306,
                40.54590416344472
              ],
              [
                -74.12987193425054,
                40.54582820480736
              ],
              [
                -74.12975805027173,
                40.54574802271361
              ],
              [
                -74.12964929541047,
                40.54566379871582
              ],
              [
                -74.12954591589445,
                40.545575723517445
              ],
              [
                -74.12944814577739,
                40.54548399654141
              ],
              [
                -74.12935620640944,
                40.54538882547806
              ],
              [
                -74.12918406740488,
                40.54522603551568
              ],
              [
                -74.12901864630722,
                40.545059241762765
              ],
              [
                -74.12886010299626,
                40.54488860547737
              ],
              [
                -74.1287085906994,
                40.544714291631124
              ],
              [
                -74.1285416323362,
                40.544531444499434
              ],
              [
                -74.12838196101643,
                40.544344848325316
              ],
              [
                -74.12822972124094,
                40.54415467204766
              ],
              [
                -74.12808505077827,
                40.54396108784557
              ],
              [
                -74.12794808054065,
                40.54376427098184
              ],
              [
                -74.12781893446551,
                40.54356439964454
              ],
              [
                -74.12798005253293,
                40.54350903021883
              ],
              [
                -74.12801054879321,
                40.54355725060721
              ],
              [
                -74.12803068497496,
                40.54355055827895
              ],
              [
                -74.12798968390734,
                40.54348434363108
              ],
              [
                -74.12775375608553,
                40.54356602518417
              ],
              [
                -74.12762994038083,
                40.54360889311554
              ],
              [
                -74.12756660762842,
                40.543499333934456
              ],
              [
                -74.1276917545049,
                40.54345570819183
              ],
              [
                -74.12792830507608,
                40.54337324705599
              ],
              [
                -74.12788626375576,
                40.54330807707473
              ],
              [
                -74.12786756770616,
                40.54331518006601
              ],
              [
                -74.12789824961531,
                40.54336617156212
              ],
              [
                -74.1277314342735,
                40.54342308480362
              ],
              [
                -74.12770001341885,
                40.5433741215351
              ],
              [
                -74.12767003295674,
                40.54338134627289
              ],
              [
                -74.1276014602687,
                40.543222782245074
              ],
              [
                -74.12754074499249,
                40.5430623648181
              ],
              [
                -74.12748797280015,
                40.542900320498774
              ],
              [
                -74.12744321814655,
                40.54273687808909
              ],
              [
                -74.1274065441645,
                40.54257226836384
              ],
              [
                -74.12739261321042,
                40.54243402977329
              ],
              [
                -74.12738789634466,
                40.54229542996432
              ],
              [
                -74.1273924056661,
                40.54215682613376
              ],
              [
                -74.1274061294962,
                40.54201857548664
              ],
              [
                -74.12742903240955,
                40.541881034315715
              ],
              [
                -74.12746105532581,
                40.54174455708288
              ],
              [
                -74.12750211566271,
                40.54160949550627
              ],
              [
                -74.12756314962428,
                40.54145397753054
              ],
              [
                -74.12763227435845,
                40.54130044275342
              ],
              [
                -74.12770937956203,
                40.541149136048986
              ],
              [
                -74.12779434220613,
                40.54100029873629
              ],
              [
                -74.12788702673318,
                40.5408541681942
              ],
              [
                -74.1279872852721,
                40.54071097748305
              ],
              [
                -74.12813326284557,
                40.54055296599051
              ],
              [
                -74.12828645569175,
                40.54039898151023
              ],
              [
                -74.12844667307078,
                40.54024921571889
              ],
              [
                -74.1286137155024,
                40.54010385504027
              ],
              [
                -74.1287873750151,
                40.53996308041394
              ],
              [
                -74.12881604599956,
                40.5399857423965
              ],
              [
                -74.13086625286705,
                40.53816441458347
              ],
              [
                -74.13116466312675,
                40.53790013586911
              ],
              [
                -74.13125546600635,
                40.53795896991141
              ],
              [
                -74.13128102022998,
                40.53797630604306
              ],
              [
                -74.13134558736793,
                40.53802010971008
              ],
              [
                -74.1313886951325,
                40.53804935472692
              ],
              [
                -74.13143339816786,
                40.53807968148695
              ],
              [
                -74.13144461494203,
                40.53808729160741
              ],
              [
                -74.1315233806718,
                40.5381407274878
              ],
              [
                -74.13153338702135,
                40.5381475165802
              ],
              [
                -74.13161107195381,
                40.53820021801323
              ],
              [
                -74.13162168805269,
                40.538207420868716
              ],
              [
                -74.13170084809326,
                40.538261123553575
              ],
              [
                -74.1317117617114,
                40.53826852810095
              ],
              [
                -74.13179350815649,
                40.53832599199172
              ],
              [
                -74.13149740072586,
                40.53858138137813
              ],
              [
                -74.13141556350926,
                40.53852680878589
              ],
              [
                -74.13122513527071,
                40.538398766272486
              ],
              [
                -74.13114477760698,
                40.53834473308612
              ],
              [
                -74.1311352951365,
                40.53833835682635
              ],
              [
                -74.13105906666092,
                40.53828898106288
              ],
              [
                -74.13113064695301,
                40.53822692152178
              ],
              [
                -74.1311196228268,
                40.53821889282786
              ],
              [
                -74.13110213313608,
                40.53823480522071
              ],
              [
                -74.13106488337263,
                40.5382661628355
              ],
              [
                -74.13104306533829,
                40.538284528614774
              ],
              [
                -74.13103263828731,
                40.538293306869754
              ],
              [
                -74.1309848364326,
                40.53833354605366
              ],
              [
                -74.13097509115991,
                40.538341749902095
              ],
              [
                -74.13095678068017,
                40.53836073805901
              ],
              [
                -74.13096668803547,
                40.53836767222717
              ],
              [
                -74.13103945369103,
                40.53830497996996
              ],
              [
                -74.13111517489904,
                40.53835546353301
              ],
              [
                -74.131125475332,
                40.53836250914887
              ],
              [
                -74.13120198444494,
                40.538414838943154
              ],
              [
                -74.13121290254976,
                40.53842230719425
              ],
              [
                -74.13129293456258,
                40.53847704667749
              ],
              [
                -74.13130326515676,
                40.53848411268165
              ],
              [
                -74.13138154351878,
                40.538537652414064
              ],
              [
                -74.13139059484412,
                40.53854384369088
              ],
              [
                -74.13147424215317,
                40.53859831488269
              ],
              [
                -74.13117436985067,
                40.53885516139857
              ],
              [
                -74.13109054543965,
                40.53880156737978
              ],
              [
                -74.13080965715949,
                40.538612221563156
              ],
              [
                -74.13073782459357,
                40.53856443328906
              ],
              [
                -74.1308109301527,
                40.53850016583939
              ],
              [
                -74.130799718963,
                40.538492490314056
              ],
              [
                -74.13077736811273,
                40.53851301527634
              ],
              [
                -74.1307248577209,
                40.53855750911845
              ],
              [
                -74.13072134858022,
                40.53856048359233
              ],
              [
                -74.13071230442803,
                40.538568145832265
              ],
              [
                -74.1306632570612,
                40.538609705599626
              ],
              [
                -74.13065428041827,
                40.53861731113298
              ],
              [
                -74.13063765737984,
                40.538635321789684
              ],
              [
                -74.13064774721937,
                40.538642142694705
              ],
              [
                -74.13071925808853,
                40.538581361914886
              ],
              [
                -74.13080381066602,
                40.5386379466279
              ],
              [
                -74.130881040585,
                40.538689629649674
              ],
              [
                -74.13089371476259,
                40.538698110994915
              ],
              [
                -74.1309722667305,
                40.53875068069337
              ],
              [
                -74.13098497193286,
                40.538759182264165
              ],
              [
                -74.13103332596896,
                40.53879154117598
              ],
              [
                -74.13107294890185,
                40.53881805636092
              ],
              [
                -74.13115328715367,
                40.53887357460631
              ],
              [
                -74.13085918854094,
                40.53913133980232
              ],
              [
                -74.13077148681744,
                40.53907240592384
              ],
              [
                -74.13075959354838,
                40.539064087855685
              ],
              [
                -74.1307244736066,
                40.539039527707544
              ],
              [
                -74.13067307625626,
                40.53900358413509
              ],
              [
                -74.13065501375159,
                40.5389909516347
              ],
              [
                -74.13042531514306,
                40.53883031223013
              ],
              [
                -74.13049601788298,
                40.538765930776215
              ],
              [
                -74.1304852829474,
                40.538758916255986
              ],
              [
                -74.13047277242568,
                40.53876979179294
              ],
              [
                -74.13046292342244,
                40.5387783547327
              ],
              [
                -74.13044784914226,
                40.53879145848406
              ],
              [
                -74.13039930643454,
                40.53883365803218
              ],
              [
                -74.13035121393885,
                40.53887546569789
              ],
              [
                -74.13034313706332,
                40.538882487347514
              ],
              [
                -74.130322448993,
                40.53890047124447
              ],
              [
                -74.13033366098274,
                40.538909000381715
              ],
              [
                -74.13040743526518,
                40.53884741626674
              ],
              [
                -74.13047733304663,
                40.53889664368452
              ],
              [
                -74.1305024944511,
                40.538914363576424
              ],
              [
                -74.1305630482502,
                40.53895701008492
              ],
              [
                -74.13057878861137,
                40.53896809441336
              ],
              [
                -74.13065254269095,
                40.539020037359236
              ],
              [
                -74.13066327325774,
                40.53902759483429
              ],
              [
                -74.1307392231052,
                40.539081083875026
              ],
              [
                -74.13075187600008,
                40.53908999447238
              ],
              [
                -74.1308390685189,
                40.5391475928925
              ],
              [
                -74.13054176521159,
                40.53940930625568
              ],
              [
                -74.130448398878,
                40.5393451134319
              ],
              [
                -74.13010505586573,
                40.539101735967925
              ],
              [
                -74.13017804327282,
                40.53904000479672
              ],
              [
                -74.13016935798473,
                40.53903287802331
              ],
              [
                -74.13000272814944,
                40.539172393658546
              ],
              [
                -74.13001148713673,
                40.53918004171496
              ],
              [
                -74.13008440018642,
                40.53911792687625
              ],
              [
                -74.13015622961893,
                40.539168776673066
              ],
              [
                -74.1304304813722,
                40.53936292030345
              ],
              [
                -74.13052164283053,
                40.5394255581197
              ],
              [
                -74.13022754297842,
                40.539683717556336
              ],
              [
                -74.13014051454833,
                40.53962916636545
              ],
              [
                -74.13012883708546,
                40.539621620972106
              ],
              [
                -74.13011984290469,
                40.53961580881986
              ],
              [
                -74.12978384857315,
                40.53939867623575
              ],
              [
                -74.12985819958882,
                40.53933524178917
              ],
              [
                -74.12984555503498,
                40.53932685696152
              ],
              [
                -74.12968375508696,
                40.539471719592434
              ],
              [
                -74.1296942734552,
                40.539478240385215
              ],
              [
                -74.12976405619064,
                40.53941522294953
              ],
              [
                -74.12984771335016,
                40.53946889602444
              ],
              [
                -74.12992916823943,
                40.539521154057276
              ],
              [
                -74.13012468138237,
                40.53964658701434
              ],
              [
                -74.13020869361581,
                40.53970266381733
              ],
              [
                -74.13005667868796,
                40.539832670852235
              ],
              [
                -74.13006392754764,
                40.53983813468063
              ],
              [
                -74.13005836130274,
                40.5398434379562
              ],
              [
                -74.1300492851502,
                40.53983850810549
              ],
              [
                -74.12993843189791,
                40.53994713555816
              ],
              [
                -74.12996934203085,
                40.539967769614165
              ],
              [
                -74.13008325390014,
                40.539859439550796
              ],
              [
                -74.13007172337215,
                40.53985113574353
              ],
              [
                -74.13007620892891,
                40.53984511951034
              ],
              [
                -74.13008136262059,
                40.539848874054925
              ],
              [
                -74.1306287248031,
                40.53936604352793
              ],
              [
                -74.13104786423696,
                40.53899765026269
              ],
              [
                -74.13117706403472,
                40.538889413059714
              ],
              [
                -74.131937314634,
                40.53823711437522
              ],
              [
                -74.13220259291258,
                40.53801008686731
              ],
              [
                -74.13246512857398,
                40.537785194732166
              ],
              [
                -74.13277513113499,
                40.537519835125615
              ],
              [
                -74.13321060585267,
                40.53714608954082
              ],
              [
                -74.13359543790433,
                40.53681717084161
              ],
              [
                -74.13386054140379,
                40.53659796249383
              ],
              [
                -74.13380823263125,
                40.53656217617863
              ],
              [
                -74.13366576406797,
                40.536687643042846
              ],
              [
                -74.13368957732476,
                40.53670342481359
              ],
              [
                -74.13368134221429,
                40.53670908436055
              ],
              [
                -74.13341004365357,
                40.53693972187095
              ],
              [
                -74.13332786488384,
                40.53688146302079
              ],
              [
                -74.13331686814696,
                40.53687408670028
              ],
              [
                -74.13329285686525,
                40.53685798057631
              ],
              [
                -74.13304651887665,
                40.53669273852
              ],
              [
                -74.13297252934821,
                40.53664328251968
              ],
              [
                -74.13304405638308,
                40.53658084455711
              ],
              [
                -74.1330350883952,
                40.53657487562589
              ],
              [
                -74.13301385446526,
                40.53659369293557
              ],
              [
                -74.13294850623681,
                40.53665150139699
              ],
              [
                -74.13289925552112,
                40.53669507088488
              ],
              [
                -74.13289203626539,
                40.53670145879365
              ],
              [
                -74.13287438280999,
                40.53671963230237
              ],
              [
                -74.13288633976343,
                40.53672604241201
              ],
              [
                -74.13295577183409,
                40.536661238721855
              ],
              [
                -74.13303088228227,
                40.5367115481735
              ],
              [
                -74.13304340527381,
                40.536720023929306
              ],
              [
                -74.13311938058938,
                40.53677144455418
              ],
              [
                -74.13312853241102,
                40.536777638426365
              ],
              [
                -74.13320844392692,
                40.5368317233527
              ],
              [
                -74.13321806916441,
                40.536838236651214
              ],
              [
                -74.13329656721403,
                40.53689136421299
              ],
              [
                -74.13330732067605,
                40.53689864197504
              ],
              [
                -74.13339187377338,
                40.53695798434754
              ],
              [
                -74.13317616358326,
                40.53714188009127
              ],
              [
                -74.13317604148283,
                40.53714198443682
              ],
              [
                -74.1330945751156,
                40.53721201792775
              ],
              [
                -74.13301721920324,
                40.53715914908029
              ],
              [
                -74.13300283995096,
                40.53714933104183
              ],
              [
                -74.13292737131076,
                40.53709779784412
              ],
              [
                -74.1329184484733,
                40.537091705884464
              ],
              [
                -74.13284007641691,
                40.537038189751655
              ],
              [
                -74.13283168966508,
                40.53703246221353
              ],
              [
                -74.13275151405894,
                40.53697771575411
              ],
              [
                -74.13274096742529,
                40.536970514597385
              ],
              [
                -74.13266726879559,
                40.536920189588244
              ],
              [
                -74.132743451717,
                40.53686135972777
              ],
              [
                -74.132735602333,
                40.53685453524314
              ],
              [
                -74.13270876886787,
                40.5368733589692
              ],
              [
                -74.13266830698964,
                40.536904594504264
              ],
              [
                -74.13265090290378,
                40.53691802959925
              ],
              [
                -74.13264197295432,
                40.536924925074736
              ],
              [
                -74.13261491537888,
                40.53694581083254
              ],
              [
                -74.13258133450967,
                40.536971734883124
              ],
              [
                -74.13255786139064,
                40.536991407757306
              ],
              [
                -74.13256683070924,
                40.53699737672413
              ],
              [
                -74.13264694004168,
                40.53693703881058
              ],
              [
                -74.13271794955475,
                40.53698609823457
              ],
              [
                -74.1327292463779,
                40.53699378771442
              ],
              [
                -74.13280917293142,
                40.53704817981583
              ],
              [
                -74.13286528600719,
                40.537086368922076
              ],
              [
                -74.13289785640718,
                40.53710853401197
              ],
              [
                -74.13291262916057,
                40.537118588160745
              ],
              [
                -74.13298060828508,
                40.53716485133182
              ],
              [
                -74.13299485796335,
                40.537174549236404
              ],
              [
                -74.13307445571547,
                40.53722912330401
              ],
              [
                -74.1327760371171,
                40.53748401091587
              ],
              [
                -74.1326908356868,
                40.53742688057589
              ],
              [
                -74.13241056494718,
                40.537236723913416
              ],
              [
                -74.1323376970766,
                40.537188120784265
              ],
              [
                -74.13239010549316,
                40.537142893370074
              ],
              [
                -74.13239022671648,
                40.53714278902639
              ],
              [
                -74.13240922528998,
                40.53712568439255
              ],
              [
                -74.13239801584261,
                40.53711886493268
              ],
              [
                -74.13231462298528,
                40.53719280589138
              ],
              [
                -74.13226527980805,
                40.5372365553061
              ],
              [
                -74.13225925203326,
                40.53724190143914
              ],
              [
                -74.13223596269246,
                40.53726255073658
              ],
              [
                -74.13224717384058,
                40.53727022595487
              ],
              [
                -74.13232205524741,
                40.53720522204013
              ],
              [
                -74.13239380632373,
                40.53725382797469
              ],
              [
                -74.13243400364236,
                40.53728139278022
              ],
              [
                -74.1324810728012,
                40.53731367131548
              ],
              [
                -74.13254236849414,
                40.53735570494655
              ],
              [
                -74.13257069197421,
                40.537375127535114
              ],
              [
                -74.13266909657912,
                40.53744260881093
              ],
              [
                -74.13275480490104,
                40.53750453647995
              ],
              [
                -74.13245302764156,
                40.53776028136395
              ],
              [
                -74.13236894558239,
                40.53770314949493
              ],
              [
                -74.132358112262,
                40.537695921158935
              ],
              [
                -74.13227873270836,
                40.53764296083436
              ],
              [
                -74.13217569640287,
                40.53757421752608
              ],
              [
                -74.13213200952623,
                40.53754507013495
              ],
              [
                -74.1320867758657,
                40.537514891347755
              ],
              [
                -74.13201821176241,
                40.53746460223468
              ],
              [
                -74.13209260140769,
                40.537404652251595
              ],
              [
                -74.13207952279657,
                40.53739503414038
              ],
              [
                -74.13205832728966,
                40.53741245017181
              ],
              [
                -74.13204846767975,
                40.53742055322292
              ],
              [
                -74.13199934255547,
                40.53746092067326
              ],
              [
                -74.13198946627011,
                40.53746903747591
              ],
              [
                -74.13197768070874,
                40.53747872154014
              ],
              [
                -74.13193499515837,
                40.53751379882872
              ],
              [
                -74.13191182124638,
                40.537532840976915
              ],
              [
                -74.13192190951362,
                40.537539662442484
              ],
              [
                -74.13199817051284,
                40.53748098494119
              ],
              [
                -74.13235106393256,
                40.53771939909941
              ],
              [
                -74.13243402654851,
                40.537776532272765
              ],
              [
                -74.13221608018895,
                40.53796470012526
              ],
              [
                -74.13221581581413,
                40.53796492609293
              ],
              [
                -74.13213560512452,
                40.53803312718265
              ],
              [
                -74.13204941374808,
                40.53797949436721
              ],
              [
                -74.13203758048124,
                40.53797163087127
              ],
              [
                -74.13203209392418,
                40.53796798435104
              ],
              [
                -74.13185740371571,
                40.53785188895488
              ],
              [
                -74.13178520730719,
                40.53780229125163
              ],
              [
                -74.13185661324943,
                40.5377426187604
              ],
              [
                -74.131846271476,
                40.5377346437995
              ],
              [
                -74.13182665649255,
                40.53774866481481
              ],
              [
                -74.13181654200082,
                40.53775717054617
              ],
              [
                -74.1317653008701,
                40.537800259339875
              ],
              [
                -74.13175700174476,
                40.53780723678193
              ],
              [
                -74.1317211833293,
                40.53783735726568
              ],
              [
                -74.13169767602062,
                40.53785712331575
              ],
              [
                -74.13168409320598,
                40.53787135316615
              ],
              [
                -74.13169530373547,
                40.53787902910877
              ],
              [
                -74.13176571749533,
                40.53781744629257
              ],
              [
                -74.13183905268437,
                40.53786826969193
              ],
              [
                -74.13185028741444,
                40.53787584892665
              ],
              [
                -74.13192776126479,
                40.53792810861168
              ],
              [
                -74.13194001141805,
                40.53793637220461
              ],
              [
                -74.1320160616874,
                40.537987672177294
              ],
              [
                -74.13202912079947,
                40.53799648064423
              ],
              [
                -74.13211244495555,
                40.53805229667768
              ],
              [
                -74.13181387201769,
                40.53830735868569
              ],
              [
                -74.13173356771327,
                40.53825184035869
              ],
              [
                -74.13136153935845,
                40.538001720428944
              ],
              [
                -74.13129269799775,
                40.53795670187077
              ],
              [
                -74.13137656776155,
                40.53788749200204
              ],
              [
                -74.13135956321447,
                40.53787521524049
              ],
              [
                -74.13135412336848,
                40.53787151485735
              ],
              [
                -74.13152564883184,
                40.53771854263832
              ],
              [
                -74.13151307747665,
                40.53771070573342
              ],
              [
                -74.13132101349746,
                40.53788461808033
              ],
              [
                -74.13133279192023,
                40.537891329942426
              ],
              [
                -74.13128337090322,
                40.53793349083359
              ],
              [
                -74.13119545689379,
                40.53787240877453
              ],
              [
                -74.13294266453627,
                40.53632203308197
              ],
              [
                -74.13307585702778,
                40.53639748804031
              ],
              [
                -74.13309062611808,
                40.53635273095691
              ],
              [
                -74.13316120141162,
                40.536374002701045
              ],
              [
                -74.1331723597528,
                40.53635519822346
              ],
              [
                -74.1329712040364,
                40.536294688577364
              ],
              [
                -74.13301207668793,
                40.53625606504284
              ],
              [
                -74.13319580101377,
                40.53630674205061
              ],
              [
                -74.13320656709256,
                40.53631136880866
              ],
              [
                -74.13336905396339,
                40.53635619522535
              ],
              [
                -74.13338312919122,
                40.5363244213335
              ],
              [
                -74.1332156758573,
                40.53627869385783
              ],
              [
                -74.13320696251593,
                40.53629063564089
              ],
              [
                -74.1330261157118,
                40.53624033644845
              ],
              [
                -74.13314683122164,
                40.53612229116586
              ],
              [
                -74.13327383658317,
                40.536008152338766
              ],
              [
                -74.13340691483263,
                40.53589811492251
              ],
              [
                -74.13353671617952,
                40.535808904406366
              ],
              [
                -74.13367116937623,
                40.53572379903906
              ],
              [
                -74.13381005153853,
                40.535642939889904
              ],
              [
                -74.13395313244354,
                40.5355664609888
              ],
              [
                -74.13410017491086,
                40.53549448910475
              ],
              [
                -74.13425629529412,
                40.53543922902523
              ],
              [
                -74.13441534878355,
                40.5353890739966
              ],
              [
                -74.13457704876932,
                40.53534411439487
              ],
              [
                -74.1347411038744,
                40.535304431234
              ],
              [
                -74.13490721847988,
                40.53527009602026
              ],
              [
                -74.1350750932569,
                40.53524117062297
              ],
              [
                -74.13801169425406,
                40.535203305981184
              ],
              [
                -74.13822429109703,
                40.53520826733012
              ],
              [
                -74.13843650025298,
                40.5352192441696
              ],
              [
                -74.13864802944258,
                40.53523622138075
              ],
              [
                -74.13885858732235,
                40.53525917558082
              ],
              [
                -74.13906788388647,
                40.53528807515453
              ],
              [
                -74.13927563086473,
                40.535322880297755
              ],
              [
                -74.13948154212021,
                40.53536354307274
              ],
              [
                -74.13995163489804,
                40.535457088527664
              ],
              [
                -74.14000321007146,
                40.53545691231959
              ],
              [
                -74.14005434565807,
                40.53545178265632
              ],
              [
                -74.14010422764461,
                40.53544178119559
              ],
              [
                -74.14015206197385,
                40.53542706714787
              ],
              [
                -74.14019708718655,
                40.535407874741914
              ],
              [
                -74.14026426520137,
                40.535348243209405
              ],
              [
                -74.14032475393472,
                40.5352845797219
              ],
              [
                -74.14037813953776,
                40.53521731986949
              ],
              [
                -74.1404240567658,
                40.535146923847456
              ],
              [
                -74.14046219147538,
                40.535073873307795
              ],
              [
                -74.14049228277464,
                40.53499866806265
              ],
              [
                -74.14051412480651,
                40.534921822665545
              ],
              [
                -74.14055444598172,
                40.53481170038201
              ],
              [
                -74.1405860719319,
                40.534699936840994
              ],
              [
                -74.1406088897138,
                40.534586931329706
              ],
              [
                -74.14062281785364,
                40.53447308757022
              ],
              [
                -74.14062780663788,
                40.5343588122774
              ],
              [
                -74.1406238382893,
                40.534244513705545
              ],
              [
                -74.1406109270312,
                40.53413060019013
              ],
              [
                -74.14027015269588,
                40.53330001719593
              ],
              [
                -74.13920399458759,
                40.531635183401406
              ],
              [
                -74.13915076013691,
                40.53152011162202
              ],
              [
                -74.13910523660833,
                40.53140312936247
              ],
              [
                -74.13906754093874,
                40.53128453722161
              ],
              [
                -74.13903776994901,
                40.531164639933024
              ],
              [
                -74.13901600009501,
                40.5310437455827
              ],
              [
                -74.13900228727267,
                40.53092216481684
              ],
              [
                -74.13899666667372,
                40.53080021004377
              ],
              [
                -74.13899915269602,
                40.53067819463106
              ],
              [
                -74.13901972353628,
                40.53059769850601
              ],
              [
                -74.1390319291496,
                40.53051621688854
              ],
              [
                -74.1390356938731,
                40.53043425508946
              ],
              [
                -74.13903099439075,
                40.53035232139545
              ],
              [
                -74.13901785987768,
                40.530270923917264
              ],
              [
                -74.13899637181729,
                40.53019056743809
              ],
              [
                -74.13896666349659,
                40.53011175028386
              ],
              [
                -74.13892891917789,
                40.53003496123282
              ],
              [
                -74.13888337295603,
                40.529960676484386
              ],
              [
                -74.13883030715276,
                40.529889356352996
              ],
              [
                -74.13874252524631,
                40.529843276954345
              ],
              [
                -74.13865060116915,
                40.52980216768873
              ],
              [
                -74.13855501758961,
                40.52976624469131
              ],
              [
                -74.13845627622914,
                40.52973569652113
              ],
              [
                -74.13835489538205,
                40.52971068352444
              ],
              [
                -74.13825140719548,
                40.52969133699387
              ],
              [
                -74.13814635487623,
                40.529677758478506
              ],
              [
                -74.13804028983962,
                40.52967001925109
              ],
              [
                -74.13793376881654,
                40.52966815993488
              ],
              [
                -74.13743797279717,
                40.52967586404655
              ],
              [
                -74.13728504251857,
                40.52974046798483
              ],
              [
                -74.1369079907634,
                40.52928191888271
              ],
              [
                -74.13618848993337,
                40.52962079668727
              ],
              [
                -74.13527548623271,
                40.53028572843679
              ],
              [
                -74.13356443498029,
                40.531511174805615
              ],
              [
                -74.1307118427266,
                40.53453507543623
              ],
              [
                -74.12762434074298,
                40.53871795356036
              ],
              [
                -74.1263818513107,
                40.540520755459255
              ],
              [
                -74.12575094543642,
                40.541305425450304
              ],
              [
                -74.12424449712773,
                40.54311038091072
              ],
              [
                -74.12245306050386,
                40.5447705456298
              ],
              [
                -74.1221635422198,
                40.54496325498783
              ],
              [
                -74.12205646983776,
                40.54502443785474
              ],
              [
                -74.12194409873757,
                40.54507981554561
              ],
              [
                -74.12182696879638,
                40.545129121997306
              ],
              [
                -74.1217056427596,
                40.54517212031502
              ],
              [
                -74.12158070353594,
                40.545208603911554
              ],
              [
                -74.1214527513976,
                40.54523839749943
              ],
              [
                -74.12132240109477,
                40.54526135793375
              ],
              [
                -74.12119027890229,
                40.54527737489941
              ],
              [
                -74.12105701960938,
                40.54528637144184
              ],
              [
                -74.12092326347042,
                40.545288304336445
              ],
              [
                -74.12078965312625,
                40.545283164296634
              ],
              [
                -74.12065683051817,
                40.54527097601802
              ],
              [
                -74.12059038294942,
                40.5452657828147
              ],
              [
                -74.12052359731865,
                40.54526665343616
              ],
              [
                -74.12046386191147,
                40.54527263089338
              ],
              [
                -74.12040531184229,
                40.545283462479695
              ],
              [
                -74.12034862290126,
                40.545299023175865
              ],
              [
                -74.1202469298642,
                40.545312423075316
              ],
              [
                -74.12014700891991,
                40.54533210745096
              ],
              [
                -74.12004952627139,
                40.545357945061454
              ],
              [
                -74.11995513186709,
                40.54538976363983
              ],
              [
                -74.11986445506753,
                40.545427351042214
              ],
              [
                -74.11977810044972,
                40.54547045666165
              ],
              [
                -74.11969664377663,
                40.545518793098964
              ],
              [
                -74.11962062815836,
                40.5455720380789
              ],
              [
                -74.11955056043125,
                40.54562983659858
              ],
              [
                -74.11948690777857,
                40.54569180329383
              ],
              [
                -74.11943009461555,
                40.54575752500887
              ],
              [
                -74.11934442053138,
                40.54585017518081
              ],
              [
                -74.11926230958805,
                40.545944684267816
              ],
              [
                -74.11916691789598,
                40.54606257031249
              ],
              [
                -74.11907706963265,
                40.54618296423323
              ],
              [
                -74.11899287798937,
                40.546305714397974
              ],
              [
                -74.1189144490349,
                40.54643066620582
              ],
              [
                -74.11882706196816,
                40.54655171122869
              ],
              [
                -74.11873310227173,
                40.54666985653102
              ],
              [
                -74.11863273497548,
                40.54678489455998
              ],
              [
                -74.11852613636927,
                40.54689662322072
              ],
              [
                -74.11841349369186,
                40.54700484623082
              ],
              [
                -74.11829500480358,
                40.54710937346596
              ],
              [
                -74.11817087783834,
                40.54721002129322
              ],
              [
                -74.11804133083706,
                40.54730661289452
              ],
              [
                -74.11791277344408,
                40.547316245514594
              ],
              [
                -74.11778540145028,
                40.54733265673845
              ],
              [
                -74.11765982832337,
                40.54735576752398
              ],
              [
                -74.11753665886785,
                40.54738546656197
              ],
              [
                -74.11741648631386,
                40.54742161081152
              ],
              [
                -74.11729988946024,
                40.547464026189054
              ],
              [
                -74.11718742988651,
                40.547512508406925
              ],
              [
                -74.1170796492492,
                40.54756682395668
              ],
              [
                -74.11697706667302,
                40.5476267112339
              ],
              [
                -74.11676361901095,
                40.54775203154288
              ],
              [
                -74.11654532176979,
                40.547872393146626
              ],
              [
                -74.11646895506243,
                40.54788781823345
              ],
              [
                -74.11639080757848,
                40.547896639989034
              ],
              [
                -74.11631185737198,
                40.5478987480049
              ],
              [
                -74.11623309254398,
                40.54789411589802
              ],
              [
                -74.11615549887546,
                40.54788280164156
              ],
              [
                -74.11608004748837,
                40.547864946839105
              ],
              [
                -74.1160076826915,
                40.547840774951965
              ],
              [
                -74.11593658853862,
                40.54781179155582
              ],
              [
                -74.11586948604845,
                40.5477777176931
              ],
              [
                -74.11580699961861,
                40.54773887042869
              ],
              [
                -74.11574971068985,
                40.547695611244805
              ],
              [
                -74.11569815233662,
                40.54764834267649
              ],
              [
                -74.11565280430547,
                40.547597504566184
              ],
              [
                -74.11561408855269,
                40.54754356997034
              ],
              [
                -74.11556568301016,
                40.547527565230894
              ],
              [
                -74.11551441533344,
                40.54751807061639
              ],
              [
                -74.11546177798424,
                40.54751536252589
              ],
              [
                -74.11540930329542,
                40.547519519794925
              ],
              [
                -74.11535851886553,
                40.54753042140077
              ],
              [
                -74.11531090308861,
                40.547547749985135
              ],
              [
                -74.11526784211863,
                40.5475710010925
              ],
              [
                -74.11523058951686,
                40.54759949785568
              ],
              [
                -74.11497175661174,
                40.547894751609995
              ],
              [
                -74.11473781165274,
                40.54799661067657
              ],
              [
                -74.11387354532663,
                40.54809147694596
              ],
              [
                -74.11374841507876,
                40.54801875805332
              ],
              [
                -74.11343842392581,
                40.54806514350483
              ],
              [
                -74.11340791195606,
                40.548069709183345
              ],
              [
                -74.11338340608125,
                40.54807337507532
              ],
              [
                -74.11395149899992,
                40.548725322999935
              ],
              [
                -74.11444772299996,
                40.549169449999944
              ],
              [
                -74.11458035199992,
                40.54927646599987
              ],
              [
                -74.11495831999993,
                40.54958143899991
              ],
              [
                -74.11656290099995,
                40.54912774199988
              ],
              [
                -74.11813477599993,
                40.550592692999906
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000208683276503,
        "objectid": 111,
        "shape_leng": 0.0599308800658,
        "location_id": 111,
        "zone": "Green-Wood Cemetery",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.99516890099986,
                40.6596695219999
              ],
              [
                -73.99575278499995,
                40.6591078299999
              ],
              [
                -73.99633560799997,
                40.658547215999896
              ],
              [
                -73.99691824199995,
                40.657989632999914
              ],
              [
                -73.99750144599993,
                40.65742847199991
              ],
              [
                -73.99809100200001,
                40.65686468799991
              ],
              [
                -73.99867041399985,
                40.65630686099992
              ],
              [
                -73.99925656999994,
                40.655745718999846
              ],
              [
                -73.99983786599982,
                40.655186157999935
              ],
              [
                -74.00041318199995,
                40.65462870199992
              ],
              [
                -74.00099519199993,
                40.65406723399986
              ],
              [
                -74.00159439599989,
                40.6534880839999
              ],
              [
                -74.00214474599989,
                40.65295610699996
              ],
              [
                -73.99779466000001,
                40.650288889999935
              ],
              [
                -73.99830602299986,
                40.649740014999935
              ],
              [
                -73.9960456529999,
                40.64834065499993
              ],
              [
                -73.99392923899993,
                40.64706931099991
              ],
              [
                -73.99180823499984,
                40.64578396899985
              ],
              [
                -73.98905872499985,
                40.64411924099995
              ],
              [
                -73.98834986699991,
                40.64456224399992
              ],
              [
                -73.98528412799996,
                40.64576793799989
              ],
              [
                -73.98307340099996,
                40.646625133999905
              ],
              [
                -73.982309398,
                40.646927403999875
              ],
              [
                -73.98180533899996,
                40.64712779899989
              ],
              [
                -73.98132887599996,
                40.64718167299989
              ],
              [
                -73.98029115499993,
                40.64729716099987
              ],
              [
                -73.9803633469999,
                40.64769374399988
              ],
              [
                -73.98071174699993,
                40.64952408699987
              ],
              [
                -73.98105378299987,
                40.6513038189999
              ],
              [
                -73.98110911899991,
                40.65159173199992
              ],
              [
                -73.98129697399993,
                40.652637506999895
              ],
              [
                -73.9814529379999,
                40.65341633599998
              ],
              [
                -73.98179568699996,
                40.655246096999925
              ],
              [
                -73.98402397199989,
                40.65659116999997
              ],
              [
                -73.98622761299997,
                40.65792048299988
              ],
              [
                -73.98843135299977,
                40.65925121199996
              ],
              [
                -73.98901286599988,
                40.658690795999895
              ],
              [
                -73.98959238099985,
                40.658134060999956
              ],
              [
                -73.990188415,
                40.657600817999906
              ],
              [
                -73.99238339499986,
                40.65889901599994
              ],
              [
                -73.99296401999992,
                40.65833740999993
              ],
              [
                -73.99516890099986,
                40.6596695219999
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000462068671429,
        "objectid": 112,
        "shape_leng": 0.107267875765,
        "location_id": 112,
        "zone": "Greenpoint",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.95439555417066,
                40.73911477252254
              ],
              [
                -73.95444125113258,
                40.73911765396997
              ],
              [
                -73.95454644819083,
                40.73912491692951
              ],
              [
                -73.95460353380118,
                40.73912784837411
              ],
              [
                -73.9546632150002,
                40.73912795313436
              ],
              [
                -73.95471533253463,
                40.73912654195908
              ],
              [
                -73.95476314020331,
                40.73912353605885
              ],
              [
                -73.9548075756685,
                40.73911884350023
              ],
              [
                -73.95492280493154,
                40.73910330066499
              ],
              [
                -73.95519660913385,
                40.7390845416936
              ],
              [
                -73.95553067150925,
                40.73903269326221
              ],
              [
                -73.9555833632283,
                40.73904007118761
              ],
              [
                -73.95561454319123,
                40.739045810433325
              ],
              [
                -73.95563004111821,
                40.73904469984196
              ],
              [
                -73.95563515530745,
                40.739044701812205
              ],
              [
                -73.95564004576131,
                40.73904431620743
              ],
              [
                -73.95677816914431,
                40.73890559317934
              ],
              [
                -73.95690347291868,
                40.73888996268369
              ],
              [
                -73.95691820009638,
                40.738888914788006
              ],
              [
                -73.9569300286511,
                40.73888707307157
              ],
              [
                -73.95694640793143,
                40.738879182002044
              ],
              [
                -73.95695851073039,
                40.73887387325899
              ],
              [
                -73.95709329518277,
                40.73880598925817
              ],
              [
                -73.95715479533308,
                40.73877632162021
              ],
              [
                -73.95741823516057,
                40.738649241332425
              ],
              [
                -73.9575470386147,
                40.73858224599518
              ],
              [
                -73.95763409607011,
                40.73853811776139
              ],
              [
                -73.95771673677051,
                40.73849332024745
              ],
              [
                -73.95776488076119,
                40.73846435936516
              ],
              [
                -73.95785444148449,
                40.7384101097698
              ],
              [
                -73.9578942469991,
                40.73838640527256
              ],
              [
                -73.95795987117343,
                40.738348391373066
              ],
              [
                -73.95815588076832,
                40.73823083041093
              ],
              [
                -73.95824213550934,
                40.73817991962025
              ],
              [
                -73.95831235513249,
                40.73813702647086
              ],
              [
                -73.95837507902779,
                40.73809694936863
              ],
              [
                -73.95843189518177,
                40.738058644372074
              ],
              [
                -73.95845868680824,
                40.738039203051024
              ],
              [
                -73.95848746915823,
                40.73801668347339
              ],
              [
                -73.95861342305074,
                40.737921202065884
              ],
              [
                -73.95864031018328,
                40.737899942069745
              ],
              [
                -73.95867800305545,
                40.73786649270285
              ],
              [
                -73.95873036313797,
                40.73783374297752
              ],
              [
                -73.9587654299909,
                40.737814808469125
              ],
              [
                -73.95877797055265,
                40.73780944591503
              ],
              [
                -73.95881432450622,
                40.73779583349642
              ],
              [
                -73.95882474477474,
                40.737790803221365
              ],
              [
                -73.95883332045653,
                40.73778546722045
              ],
              [
                -73.95888819766094,
                40.73774116402611
              ],
              [
                -73.95898856589714,
                40.7376647276903
              ],
              [
                -73.95902797086988,
                40.73763735784861
              ],
              [
                -73.95908953404917,
                40.737598009016814
              ],
              [
                -73.95910990410896,
                40.737586039787836
              ],
              [
                -73.9591637296288,
                40.73755700896849
              ],
              [
                -73.95919107556782,
                40.737539116286506
              ],
              [
                -73.95930574127573,
                40.737452068588844
              ],
              [
                -73.95936872914949,
                40.73740432887951
              ],
              [
                -73.9594013832884,
                40.7373810160273
              ],
              [
                -73.9594701001917,
                40.737334781831336
              ],
              [
                -73.95956431785012,
                40.737268671834386
              ],
              [
                -73.95958311452335,
                40.73725416310573
              ],
              [
                -73.95960239868394,
                40.73723695150611
              ],
              [
                -73.95966543236098,
                40.73717216449541
              ],
              [
                -73.95969225036389,
                40.737147257009646
              ],
              [
                -73.95970585909082,
                40.73713685201491
              ],
              [
                -73.95972243919357,
                40.73712631202643
              ],
              [
                -73.95977974555224,
                40.73709566098169
              ],
              [
                -73.95979907338204,
                40.73708439336929
              ],
              [
                -73.95981583252653,
                40.737072737871344
              ],
              [
                -73.95982931147853,
                40.73706090063319
              ],
              [
                -73.95985081417776,
                40.73703685585992
              ],
              [
                -73.9598950195283,
                40.73697905906495
              ],
              [
                -73.95991659213416,
                40.7369540503563
              ],
              [
                -73.96000106579093,
                40.736869135115505
              ],
              [
                -73.96003383184511,
                40.7368343138929
              ],
              [
                -73.96007713554904,
                40.73677856103872
              ],
              [
                -73.96009521419664,
                40.73675854873325
              ],
              [
                -73.96014731842976,
                40.73671569374604
              ],
              [
                -73.96018765307626,
                40.736675472872996
              ],
              [
                -73.96021577508543,
                40.73664518107987
              ],
              [
                -73.96022288450308,
                40.736636655602425
              ],
              [
                -73.96023863089073,
                40.73661376101383
              ],
              [
                -73.96029557044754,
                40.7365629197999
              ],
              [
                -73.9604226223433,
                40.7364445560135
              ],
              [
                -73.9604674814225,
                40.736399645855464
              ],
              [
                -73.96051155046175,
                40.736350608897524
              ],
              [
                -73.96051916717968,
                40.736342192300086
              ],
              [
                -73.96119003134035,
                40.73560279658299
              ],
              [
                -73.96157494306725,
                40.7351796149286
              ],
              [
                -73.96176208458847,
                40.73489161477911
              ],
              [
                -73.96190964676319,
                40.73475080674922
              ],
              [
                -73.96198477870479,
                40.734638033148144
              ],
              [
                -73.9620903444991,
                40.734507997873685
              ],
              [
                -73.96218552877323,
                40.73424901663274
              ],
              [
                -73.96228520236923,
                40.734047695876654
              ],
              [
                -73.96232467828575,
                40.73390776034564
              ],
              [
                -73.96235505955215,
                40.73371326112401
              ],
              [
                -73.96230837696692,
                40.73353232526897
              ],
              [
                -73.96234062194117,
                40.73338922663284
              ],
              [
                -73.9623040801106,
                40.73311409141065
              ],
              [
                -73.96235652228026,
                40.73310793337062
              ],
              [
                -73.96235748813149,
                40.73311001754874
              ],
              [
                -73.96275541965417,
                40.73307273927299
              ],
              [
                -73.96353736821686,
                40.73300047416273
              ],
              [
                -73.9635387832987,
                40.73299665450353
              ],
              [
                -73.96357449142579,
                40.732994796040735
              ],
              [
                -73.96357720532276,
                40.73299759710496
              ],
              [
                -73.96365321450872,
                40.73299217114834
              ],
              [
                -73.96365189579521,
                40.73298729001454
              ],
              [
                -73.96372106318971,
                40.7329800783661
              ],
              [
                -73.96406047861366,
                40.73294502382846
              ],
              [
                -73.96407891455769,
                40.73293296046775
              ],
              [
                -73.96408821900232,
                40.73291909954505
              ],
              [
                -73.96406507049177,
                40.732779040653625
              ],
              [
                -73.96404925210084,
                40.73276153790589
              ],
              [
                -73.96403099136643,
                40.732757938759605
              ],
              [
                -73.96366122635746,
                40.732798505981954
              ],
              [
                -73.9636772601319,
                40.732920073478425
              ],
              [
                -73.96358665220083,
                40.73292157806546
              ],
              [
                -73.9635885915715,
                40.732911845220265
              ],
              [
                -73.96357806468741,
                40.732912125681196
              ],
              [
                -73.96357459572387,
                40.732895048940684
              ],
              [
                -73.96356590964024,
                40.732894298019346
              ],
              [
                -73.9635572631168,
                40.73284899745191
              ],
              [
                -73.9635311719302,
                40.73285022101445
              ],
              [
                -73.96352136166972,
                40.732827188371246
              ],
              [
                -73.9633368536688,
                40.7328385292847
              ],
              [
                -73.96332595813487,
                40.7328308015024
              ],
              [
                -73.96284830750984,
                40.73287157545575
              ],
              [
                -73.96236012830968,
                40.732912627776855
              ],
              [
                -73.9622634042328,
                40.7329155516235
              ],
              [
                -73.96212128302159,
                40.7323737233312
              ],
              [
                -73.96190947717899,
                40.73165137399744
              ],
              [
                -73.96182557485885,
                40.731607799026314
              ],
              [
                -73.96171614512016,
                40.73156991744348
              ],
              [
                -73.96143187677843,
                40.73096517164786
              ],
              [
                -73.96130143673095,
                40.730252942924736
              ],
              [
                -73.96128413823038,
                40.73016192929754
              ],
              [
                -73.96129090052489,
                40.73016193157096
              ],
              [
                -73.96134881243212,
                40.73016195102422
              ],
              [
                -73.96121771697015,
                40.729529707315336
              ],
              [
                -73.96119502412036,
                40.72941634782028
              ],
              [
                -73.9611403009501,
                40.729416329365606
              ],
              [
                -73.96107076838537,
                40.729219509694445
              ],
              [
                -73.96106107684327,
                40.728776716825294
              ],
              [
                -73.96099640841605,
                40.72876912608595
              ],
              [
                -73.96101148031258,
                40.72851935234766
              ],
              [
                -73.96105130889325,
                40.72846638151089
              ],
              [
                -73.96114159312455,
                40.728103725404615
              ],
              [
                -73.96141826820885,
                40.72735617970478
              ],
              [
                -73.96149920105307,
                40.726751091125244
              ],
              [
                -73.9616171696375,
                40.72666142199102
              ],
              [
                -73.96165280828099,
                40.72620392623105
              ],
              [
                -73.9616151883593,
                40.725865563073995
              ],
              [
                -73.9610634351171,
                40.72584489918543
              ],
              [
                -73.96028544944221,
                40.725948122413
              ],
              [
                -73.96012497133233,
                40.72531963663686
              ],
              [
                -73.95842611843923,
                40.72540053583218
              ],
              [
                -73.9583517707884,
                40.7251980573017
              ],
              [
                -73.95827588223725,
                40.72516745704101
              ],
              [
                -73.95795096213246,
                40.72503643916838
              ],
              [
                -73.95794183489491,
                40.72464920269985
              ],
              [
                -73.95803916741664,
                40.72442744087862
              ],
              [
                -73.95814292503528,
                40.72440428420136
              ],
              [
                -73.95771989199994,
                40.7242849569999
              ],
              [
                -73.95627311499997,
                40.723389875999914
              ],
              [
                -73.95477662900004,
                40.72245950499994
              ],
              [
                -73.95418330299992,
                40.72263642799991
              ],
              [
                -73.95382461699991,
                40.722759734999904
              ],
              [
                -73.95332036799981,
                40.72293269999991
              ],
              [
                -73.95249594800003,
                40.72321773399992
              ],
              [
                -73.95164632299998,
                40.723485856999936
              ],
              [
                -73.95064594299997,
                40.72170569299989
              ],
              [
                -73.94986599500001,
                40.72217948399987
              ],
              [
                -73.94907816799986,
                40.72254028699993
              ],
              [
                -73.94887459699996,
                40.72129240699989
              ],
              [
                -73.94853260499984,
                40.71916717599987
              ],
              [
                -73.94841638599995,
                40.71845553299995
              ],
              [
                -73.94721341799986,
                40.718560985999865
              ],
              [
                -73.94679211599993,
                40.7188216079999
              ],
              [
                -73.94560358899994,
                40.71956728599989
              ],
              [
                -73.94440536699992,
                40.72024041199988
              ],
              [
                -73.94367547999985,
                40.72065997499991
              ],
              [
                -73.94281683699987,
                40.721143620999904
              ],
              [
                -73.94195633599989,
                40.721652318999894
              ],
              [
                -73.94150380799985,
                40.7219909789999
              ],
              [
                -73.9410941879999,
                40.72230924499994
              ],
              [
                -73.94022123699996,
                40.72289150499995
              ],
              [
                -73.93931199899997,
                40.72334789399991
              ],
              [
                -73.93891183799992,
                40.72357882499994
              ],
              [
                -73.9384885709999,
                40.723808905999945
              ],
              [
                -73.93765314499996,
                40.7242655549999
              ],
              [
                -73.93676382399988,
                40.72483891899985
              ],
              [
                -73.93539740399996,
                40.725906866999956
              ],
              [
                -73.93412337399995,
                40.726855470999915
              ],
              [
                -73.93255438599991,
                40.727862889999905
              ],
              [
                -73.93226852175046,
                40.728174658015654
              ],
              [
                -73.9323459884982,
                40.728182393884644
              ],
              [
                -73.932360906291,
                40.728167770367016
              ],
              [
                -73.93238700367864,
                40.72814769455616
              ],
              [
                -73.93240789866175,
                40.7281459238374
              ],
              [
                -73.93262682661627,
                40.72818061356344
              ],
              [
                -73.93291116235257,
                40.72823331594163
              ],
              [
                -73.93301658610117,
                40.72821034223369
              ],
              [
                -73.93325729805612,
                40.728240702814766
              ],
              [
                -73.93348271999301,
                40.72828156384591
              ],
              [
                -73.93348555981649,
                40.72829441478136
              ],
              [
                -73.93349948194367,
                40.7283041392015
              ],
              [
                -73.93352761652706,
                40.72830739759007
              ],
              [
                -73.93376676075171,
                40.728333939440496
              ],
              [
                -73.9341542156217,
                40.72838165362065
              ],
              [
                -73.9342287100879,
                40.7283908269011
              ],
              [
                -73.93423524033687,
                40.728395495578326
              ],
              [
                -73.93423032768017,
                40.72843131516514
              ],
              [
                -73.93425572782597,
                40.72843291447269
              ],
              [
                -73.93480845721054,
                40.72852861070382
              ],
              [
                -73.93492926391862,
                40.72854391575297
              ],
              [
                -73.93501193202125,
                40.728572427731166
              ],
              [
                -73.93527438167969,
                40.728651019073446
              ],
              [
                -73.93561453981158,
                40.72874598563435
              ],
              [
                -73.93563591233656,
                40.728751952652814
              ],
              [
                -73.93608245486588,
                40.728882711852116
              ],
              [
                -73.93655894374989,
                40.72901782626695
              ],
              [
                -73.93684001378081,
                40.72908791393823
              ],
              [
                -73.9369312479987,
                40.729108091265516
              ],
              [
                -73.93711874507937,
                40.72918993206494
              ],
              [
                -73.93729511262923,
                40.72930055678739
              ],
              [
                -73.93808900912043,
                40.729746846260504
              ],
              [
                -73.9381033496066,
                40.729728519521366
              ],
              [
                -73.93856120412507,
                40.73013364321876
              ],
              [
                -73.93891503477053,
                40.730383794733214
              ],
              [
                -73.93974607754137,
                40.73106547547206
              ],
              [
                -73.9398132262544,
                40.731152688912026
              ],
              [
                -73.93988785146198,
                40.7312956210337
              ],
              [
                -73.94036658952942,
                40.73221258691483
              ],
              [
                -73.94068491601338,
                40.73301707712448
              ],
              [
                -73.9405686893023,
                40.73305849425773
              ],
              [
                -73.94059112746399,
                40.73309176622027
              ],
              [
                -73.9406442348714,
                40.7331705114796
              ],
              [
                -73.94072562055,
                40.73329118540707
              ],
              [
                -73.94075490700678,
                40.73327780991446
              ],
              [
                -73.94081817225852,
                40.733248909440285
              ],
              [
                -73.94091758051687,
                40.73348404712137
              ],
              [
                -73.94092914681453,
                40.73351139187718
              ],
              [
                -73.94094207548785,
                40.73350865207843
              ],
              [
                -73.94103843274786,
                40.73348826020298
              ],
              [
                -73.9411353395535,
                40.733702811249984
              ],
              [
                -73.94115615809403,
                40.73374890960982
              ],
              [
                -73.94099746599917,
                40.73379048555585
              ],
              [
                -73.94114746701946,
                40.73415292753759
              ],
              [
                -73.9412980185679,
                40.73437629343364
              ],
              [
                -73.94154566832448,
                40.73473433593478
              ],
              [
                -73.94160658196857,
                40.734687702163804
              ],
              [
                -73.94169368832134,
                40.73476937861393
              ],
              [
                -73.94176306503513,
                40.73485938231849
              ],
              [
                -73.94178616641555,
                40.734894936923226
              ],
              [
                -73.94179189160984,
                40.73491289714999
              ],
              [
                -73.94173819589838,
                40.73495071791764
              ],
              [
                -73.94177231077717,
                40.734982081389916
              ],
              [
                -73.94196892130114,
                40.73511897640343
              ],
              [
                -73.94210536596876,
                40.73521903812462
              ],
              [
                -73.94212245962963,
                40.73523376140624
              ],
              [
                -73.94239443542429,
                40.735425749947595
              ],
              [
                -73.94284189605364,
                40.735617232365556
              ],
              [
                -73.94288044032209,
                40.73563294704598
              ],
              [
                -73.94294414311412,
                40.73566019179399
              ],
              [
                -73.94300691110804,
                40.73568865195108
              ],
              [
                -73.94310507092837,
                40.73573579771021
              ],
              [
                -73.94316788320035,
                40.73576750865239
              ],
              [
                -73.94321347652942,
                40.73579053001607
              ],
              [
                -73.94329968017576,
                40.73582361273334
              ],
              [
                -73.94431566023265,
                40.7362244835064
              ],
              [
                -73.94438048917405,
                40.73625197878863
              ],
              [
                -73.94442362731286,
                40.736267920827615
              ],
              [
                -73.94444758298177,
                40.736274884901825
              ],
              [
                -73.94447036793825,
                40.73627990342435
              ],
              [
                -73.94448138831775,
                40.736281492532946
              ],
              [
                -73.94449242081755,
                40.73628307377263
              ],
              [
                -73.94451542117358,
                40.73628439139539
              ],
              [
                -73.94452008735732,
                40.73628365418167
              ],
              [
                -73.94452667130408,
                40.73628117226584
              ],
              [
                -73.94453001123385,
                40.736279337114425
              ],
              [
                -73.94453322105807,
                40.73627716919284
              ],
              [
                -73.9445388600858,
                40.73627214628246
              ],
              [
                -73.94454106488915,
                40.73626952605764
              ],
              [
                -73.94454280763553,
                40.736266897401556
              ],
              [
                -73.94454404114089,
                40.736264323448886
              ],
              [
                -73.94454475257284,
                40.73626187438716
              ],
              [
                -73.9445449211989,
                40.73625958639188
              ],
              [
                -73.94454479148013,
                40.73625852471414
              ],
              [
                -73.94454454389673,
                40.736257506369206
              ],
              [
                -73.94454415356005,
                40.736256533354975
              ],
              [
                -73.94454363410208,
                40.73625563281771
              ],
              [
                -73.9445429831162,
                40.73625478599292
              ],
              [
                -73.94454220280419,
                40.73625399355208
              ],
              [
                -73.94454119610867,
                40.73625317285769
              ],
              [
                -73.94453998943426,
                40.73625235323969
              ],
              [
                -73.94453691364184,
                40.73625066761481
              ],
              [
                -73.94452280603181,
                40.736244735408626
              ],
              [
                -73.94452008496624,
                40.73624341114128
              ],
              [
                -73.94451805009484,
                40.73624217616098
              ],
              [
                -73.9445170440216,
                40.73624140220654
              ],
              [
                -73.94451626302039,
                40.736240644275654
              ],
              [
                -73.944515707992,
                40.73623987874734
              ],
              [
                -73.94451551846049,
                40.73623949987866
              ],
              [
                -73.94451537650774,
                40.73623911282414
              ],
              [
                -73.94451528167914,
                40.73623873450374
              ],
              [
                -73.94451524743039,
                40.73623833929227
              ],
              [
                -73.94451527153959,
                40.736237951145064
              ],
              [
                -73.94451534256572,
                40.7362375559844
              ],
              [
                -73.94451565068118,
                40.73623676289502
              ],
              [
                -73.94451617245115,
                40.736235944109566
              ],
              [
                -73.9445172153365,
                40.73623482804811
              ],
              [
                -73.94451850588766,
                40.73623383724821
              ],
              [
                -73.9445200696358,
                40.736232991490404
              ],
              [
                -73.9445219171835,
                40.73623225409138
              ],
              [
                -73.94452429787943,
                40.736231580106605
              ],
              [
                -73.94452715112095,
                40.736231013733864
              ],
              [
                -73.94453916861191,
                40.736229317788215
              ],
              [
                -73.94454261380297,
                40.73622855368398
              ],
              [
                -73.944544887742,
                40.736227807610895
              ],
              [
                -73.94454679443378,
                40.73622692583219
              ],
              [
                -73.94454837002635,
                40.73622587318438
              ],
              [
                -73.94454903299513,
                40.73622528833371
              ],
              [
                -73.94454962530499,
                40.736224657881706
              ],
              [
                -73.94456766241309,
                40.73620282161704
              ],
              [
                -73.94457726112469,
                40.73619118130495
              ],
              [
                -73.94461272994495,
                40.736147398742844
              ],
              [
                -73.94463300629042,
                40.7361238502514
              ],
              [
                -73.94467094742069,
                40.736082752633564
              ],
              [
                -73.94469052058979,
                40.73606413828621
              ],
              [
                -73.94473954067763,
                40.73602424327756
              ],
              [
                -73.9447592102149,
                40.736004107824215
              ],
              [
                -73.94477272080769,
                40.735985761277085
              ],
              [
                -73.944785512709,
                40.73596454331514
              ],
              [
                -73.94483653414815,
                40.735867781191224
              ],
              [
                -73.94486214046046,
                40.735810538937095
              ],
              [
                -73.94488540424965,
                40.73576637049456
              ],
              [
                -73.94492309227375,
                40.73570278659702
              ],
              [
                -73.94496124483572,
                40.735634249150806
              ],
              [
                -73.94499058532952,
                40.735570308764515
              ],
              [
                -73.94500642212093,
                40.735531441809144
              ],
              [
                -73.94502179930068,
                40.73547490611653
              ],
              [
                -73.9450278576709,
                40.735450523519155
              ],
              [
                -73.94503299031923,
                40.735428615683325
              ],
              [
                -73.94518507181313,
                40.73547915267781
              ],
              [
                -73.94525492279553,
                40.735892789003714
              ],
              [
                -73.94499910475585,
                40.73641747877059
              ],
              [
                -73.94499908889478,
                40.73643669371265
              ],
              [
                -73.94503381424778,
                40.73644872026402
              ],
              [
                -73.9450590833402,
                40.736439125685024
              ],
              [
                -73.94507806672308,
                40.736393497684865
              ],
              [
                -73.94522012115175,
                40.73644400571883
              ],
              [
                -73.94520113938772,
                40.73648723040827
              ],
              [
                -73.9452074370178,
                40.73650885051612
              ],
              [
                -73.9453589717991,
                40.73654975457588
              ],
              [
                -73.94540003127128,
                40.73653776407872
              ],
              [
                -73.94541584986361,
                40.73650174451074
              ],
              [
                -73.94554528485209,
                40.736537832854566
              ],
              [
                -73.94551998044125,
                40.7365906629182
              ],
              [
                -73.9455325990523,
                40.736605081020336
              ],
              [
                -73.94557048457217,
                40.736612303910405
              ],
              [
                -73.9458731056289,
                40.73610183125813
              ],
              [
                -73.94617195881506,
                40.73620288877423
              ],
              [
                -73.94624748296931,
                40.73616616522696
              ],
              [
                -73.94630720090619,
                40.73605751950615
              ],
              [
                -73.9464005067585,
                40.73588906853683
              ],
              [
                -73.94643051256956,
                40.735835358937784
              ],
              [
                -73.94644310074177,
                40.73581318445578
              ],
              [
                -73.94662663786625,
                40.73586558516736
              ],
              [
                -73.94692902737461,
                40.735313140683736
              ],
              [
                -73.94675678399388,
                40.73525766653476
              ],
              [
                -73.94673692340704,
                40.73529494371993
              ],
              [
                -73.94662828221188,
                40.73458478933476
              ],
              [
                -73.94659806707347,
                40.73444222434728
              ],
              [
                -73.94706532915383,
                40.734401992819265
              ],
              [
                -73.94708643463501,
                40.73454591548952
              ],
              [
                -73.94715775859925,
                40.735032297251614
              ],
              [
                -73.94718691118442,
                40.73523078210042
              ],
              [
                -73.94719983367108,
                40.73535517811412
              ],
              [
                -73.94687945162181,
                40.73593934988042
              ],
              [
                -73.94680539926372,
                40.73610022520299
              ],
              [
                -73.94663121313155,
                40.73644959458712
              ],
              [
                -73.94662099934824,
                40.736470082694524
              ],
              [
                -73.94645392267336,
                40.73680959046114
              ],
              [
                -73.94652352854791,
                40.73692685395814
              ],
              [
                -73.94658050648984,
                40.73694426897721
              ],
              [
                -73.94661589520477,
                40.73692455496032
              ],
              [
                -73.9466991905293,
                40.736957750711255
              ],
              [
                -73.94675925460247,
                40.736978723656364
              ],
              [
                -73.94677824012722,
                40.736984018896614
              ],
              [
                -73.9468215743273,
                40.736993764662856
              ],
              [
                -73.9468397774883,
                40.736999149493236
              ],
              [
                -73.94685990836236,
                40.73700738818559
              ],
              [
                -73.94691047650039,
                40.73703173433758
              ],
              [
                -73.94696827978046,
                40.73705259857922
              ],
              [
                -73.94698861180811,
                40.737061064004976
              ],
              [
                -73.94700570033963,
                40.73707061752642
              ],
              [
                -73.9470340024366,
                40.73709027972293
              ],
              [
                -73.94703617987669,
                40.73709179315311
              ],
              [
                -73.94704934942234,
                40.73709995757924
              ],
              [
                -73.9470955720832,
                40.73712486001714
              ],
              [
                -73.94713634142643,
                40.737144654732525
              ],
              [
                -73.94716178589232,
                40.73715493175969
              ],
              [
                -73.94718139793846,
                40.73716125315258
              ],
              [
                -73.94720149585581,
                40.73716644881961
              ],
              [
                -73.94754211492793,
                40.73727891654832
              ],
              [
                -73.94809172682254,
                40.73746770486471
              ],
              [
                -73.94810675303026,
                40.737433707959426
              ],
              [
                -73.9481229165706,
                40.73739715460843
              ],
              [
                -73.94840521772191,
                40.73751383459341
              ],
              [
                -73.94858408326094,
                40.73758455026463
              ],
              [
                -73.94861200384757,
                40.737595450101686
              ],
              [
                -73.94884377400736,
                40.73768589241639
              ],
              [
                -73.94925743288589,
                40.73784498780438
              ],
              [
                -73.94939701000153,
                40.73789966433983
              ],
              [
                -73.94948430551214,
                40.73793385945839
              ],
              [
                -73.9496477167887,
                40.73799773111222
              ],
              [
                -73.94996516469928,
                40.73812448107608
              ],
              [
                -73.95004848537417,
                40.738158052683225
              ],
              [
                -73.95021944060149,
                40.73822153160039
              ],
              [
                -73.95038748197699,
                40.738284837125704
              ],
              [
                -73.95046458280514,
                40.73830958079499
              ],
              [
                -73.95050973889533,
                40.738322685026716
              ],
              [
                -73.95064521733299,
                40.738364472152234
              ],
              [
                -73.95154202956655,
                40.738611258202255
              ],
              [
                -73.9517171496975,
                40.73866521896623
              ],
              [
                -73.9518126933132,
                40.738694183756
              ],
              [
                -73.9518578844407,
                40.73870829531155
              ],
              [
                -73.95187806586495,
                40.73871398590656
              ],
              [
                -73.95187864527348,
                40.73871407560779
              ],
              [
                -73.95187914324185,
                40.73871409340613
              ],
              [
                -73.95187936768745,
                40.738714075909655
              ],
              [
                -73.95187956968144,
                40.73871403980831
              ],
              [
                -73.95187975825947,
                40.73871397672965
              ],
              [
                -73.95187993583103,
                40.73871390543759
              ],
              [
                -73.95188008963592,
                40.738713806158685
              ],
              [
                -73.95188022032096,
                40.738713698661385
              ],
              [
                -73.95188033957236,
                40.73871356418746
              ],
              [
                -73.95188044628392,
                40.73871340994028
              ],
              [
                -73.95188060027678,
                40.73871305049302
              ],
              [
                -73.95188069530279,
                40.73871260859812
              ],
              [
                -73.95188068446178,
                40.73871145634695
              ],
              [
                -73.95188040058603,
                40.73870989773036
              ],
              [
                -73.95187825200313,
                40.73870205341413
              ],
              [
                -73.95187789894662,
                40.73869930700643
              ],
              [
                -73.95187803065264,
                40.738697785251695
              ],
              [
                -73.95187820863428,
                40.73869714638036
              ],
              [
                -73.95187846958474,
                40.73869659700273
              ],
              [
                -73.95187881197509,
                40.738696119528086
              ],
              [
                -73.95187902576566,
                40.738695912219725
              ],
              [
                -73.9518792503409,
                40.738695715135
              ],
              [
                -73.95187948788579,
                40.73869555273364
              ],
              [
                -73.95187976088395,
                40.73869539972867
              ],
              [
                -73.95188004444059,
                40.7386952648208
              ],
              [
                -73.95188035286634,
                40.738695156727694
              ],
              [
                -73.95188714964519,
                40.73869343974131
              ],
              [
                -73.95189451325636,
                40.738692452066324
              ],
              [
                -73.95190236352236,
                40.738692204390674
              ],
              [
                -73.95191056675478,
                40.73869269364296
              ],
              [
                -73.95192759029223,
                40.73869589681885
              ],
              [
                -73.9519358866919,
                40.73869852089709
              ],
              [
                -73.95194368602378,
                40.73870171184411
              ],
              [
                -73.95194927258065,
                40.73870482128728
              ],
              [
                -73.95195469190358,
                40.7387089390017
              ],
              [
                -73.95197285125762,
                40.73872743361655
              ],
              [
                -73.95197731225186,
                40.73873128908654
              ],
              [
                -73.9519879435442,
                40.73874762097677
              ],
              [
                -73.95200514191774,
                40.73875151743827
              ],
              [
                -73.95201751319439,
                40.738753765101656
              ],
              [
                -73.95203337473411,
                40.738757256928324
              ],
              [
                -73.95210541738054,
                40.7387709843725
              ],
              [
                -73.95214506084804,
                40.73877825742586
              ],
              [
                -73.95217731880568,
                40.73878358409279
              ],
              [
                -73.95218669424783,
                40.73878525403561
              ],
              [
                -73.95238448777513,
                40.73882039306002
              ],
              [
                -73.95255527571982,
                40.73885768188004
              ],
              [
                -73.95265702902607,
                40.73887887647445
              ],
              [
                -73.95271781388716,
                40.738891084803214
              ],
              [
                -73.95278707574712,
                40.738904174916264
              ],
              [
                -73.95286232074187,
                40.738921560300376
              ],
              [
                -73.95292355724176,
                40.73893494239519
              ],
              [
                -73.95323299037699,
                40.738992683138186
              ],
              [
                -73.95336744598872,
                40.739010514220354
              ],
              [
                -73.95351461853663,
                40.73902692697508
              ],
              [
                -73.95358089064727,
                40.73903297728495
              ],
              [
                -73.95373783285332,
                40.73904533368983
              ],
              [
                -73.953815514917,
                40.739053125863556
              ],
              [
                -73.95397240398297,
                40.73907494582791
              ],
              [
                -73.95402778116019,
                40.739083144574764
              ],
              [
                -73.9541218660032,
                40.73910076884594
              ],
              [
                -73.95415627885322,
                40.739106914853046
              ],
              [
                -73.95419351267796,
                40.739106957142994
              ],
              [
                -73.9542326293958,
                40.739107350299264
              ],
              [
                -73.95428317027596,
                40.73910737037338
              ],
              [
                -73.95439555417066,
                40.73911477252254
              ]
            ]
          ],
          [
            [
              [
                -73.96236596889428,
                40.724209061614204
              ],
              [
                -73.96239043044622,
                40.72419356718676
              ],
              [
                -73.96244340911423,
                40.724199785481346
              ],
              [
                -73.96247195112899,
                40.724174989273585
              ],
              [
                -73.96246790011047,
                40.72413157960132
              ],
              [
                -73.9620727192192,
                40.723880300201465
              ],
              [
                -73.96200744849497,
                40.723991901303805
              ],
              [
                -73.96236596889428,
                40.724209061614204
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0000579634566998,
        "objectid": 113,
        "shape_leng": 0.032745350566,
        "location_id": 113,
        "zone": "Greenwich Village North",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.99136388099987,
                40.72755978299991
              ],
              [
                -73.99135869199992,
                40.72778246999996
              ],
              [
                -73.99112256699988,
                40.72856796599994
              ],
              [
                -73.99093053599996,
                40.729142295999914
              ],
              [
                -73.99075728499993,
                40.7297408309999
              ],
              [
                -73.99072530399991,
                40.729878686999896
              ],
              [
                -73.99050558599987,
                40.73056495599995
              ],
              [
                -73.99039009999987,
                40.731288523999865
              ],
              [
                -73.99021190999987,
                40.731996333999895
              ],
              [
                -73.99002687699983,
                40.73275628399994
              ],
              [
                -73.98986852099996,
                40.73352711499992
              ],
              [
                -73.98990295999991,
                40.7344347899999
              ],
              [
                -73.9907539679999,
                40.734839154999875
              ],
              [
                -73.99119899299986,
                40.73501236899992
              ],
              [
                -73.99171498799983,
                40.73521530099993
              ],
              [
                -73.99361688399992,
                40.73600449199991
              ],
              [
                -73.99683993899998,
                40.73736088899995
              ],
              [
                -73.99734132799996,
                40.736667888999925
              ],
              [
                -73.99780442999989,
                40.736032157999894
              ],
              [
                -73.99827418999986,
                40.73539113999988
              ],
              [
                -73.99853021599989,
                40.73503789199989
              ],
              [
                -73.99871443800002,
                40.73478386099987
              ],
              [
                -73.999187803,
                40.734134833999896
              ],
              [
                -73.99955589199998,
                40.73361059599991
              ],
              [
                -73.99975771599985,
                40.73334132199989
              ],
              [
                -74.000066577,
                40.7329292359999
              ],
              [
                -73.99861225599987,
                40.73221542999989
              ],
              [
                -73.99697435499994,
                40.73140883199992
              ],
              [
                -73.99556245499996,
                40.73071521599994
              ],
              [
                -73.99606314799985,
                40.73015526099991
              ],
              [
                -73.99657214499979,
                40.729552233999904
              ],
              [
                -73.99581237099986,
                40.7291805329999
              ],
              [
                -73.99506103499998,
                40.728810771999875
              ],
              [
                -73.99425278499996,
                40.72841245699992
              ],
              [
                -73.99314656999991,
                40.727867202999896
              ],
              [
                -73.99154832699986,
                40.72708947099994
              ],
              [
                -73.99136388099987,
                40.72755978299991
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0000470207704945,
        "objectid": 114,
        "shape_leng": 0.0317270347711,
        "location_id": 114,
        "zone": "Greenwich Village South",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.99257987399992,
                40.724201554999915
              ],
              [
                -73.99232642199998,
                40.72490823199993
              ],
              [
                -73.99220971099992,
                40.72523128999992
              ],
              [
                -73.9920616679999,
                40.72564244299993
              ],
              [
                -73.99179379299984,
                40.72639145899994
              ],
              [
                -73.99154832699986,
                40.72708947099994
              ],
              [
                -73.99314656999991,
                40.727867202999896
              ],
              [
                -73.99425278499996,
                40.72841245699992
              ],
              [
                -73.99506103499998,
                40.728810771999875
              ],
              [
                -73.9958123719999,
                40.729180532999926
              ],
              [
                -73.99657214499979,
                40.729552233999904
              ],
              [
                -73.99606314799985,
                40.73015526099991
              ],
              [
                -73.99556245499996,
                40.73071521599994
              ],
              [
                -73.99697435499994,
                40.73140883199992
              ],
              [
                -73.99861225599987,
                40.73221542999989
              ],
              [
                -74.000066577,
                40.7329292359999
              ],
              [
                -74.00051066399996,
                40.73231522299996
              ],
              [
                -74.00095442299988,
                40.731706097999954
              ],
              [
                -74.00141242499994,
                40.731065255999916
              ],
              [
                -74.0016846549999,
                40.73058156199995
              ],
              [
                -74.00213899299997,
                40.72979574399988
              ],
              [
                -74.00220337299997,
                40.72965325599995
              ],
              [
                -74.00250642399995,
                40.72901638499997
              ],
              [
                -74.00281726599992,
                40.72836312899994
              ],
              [
                -74.00269315099997,
                40.72831405399993
              ],
              [
                -74.00214891499996,
                40.728035366999904
              ],
              [
                -74.00139033499991,
                40.727660001999894
              ],
              [
                -74.00063239199994,
                40.727286611999915
              ],
              [
                -73.99991579199988,
                40.726934712999864
              ],
              [
                -73.9991182469999,
                40.72654231299988
              ],
              [
                -73.99836119299998,
                40.72616746799987
              ],
              [
                -73.99761019599993,
                40.72579713799992
              ],
              [
                -73.9967717579999,
                40.72543194399992
              ],
              [
                -73.99584577599984,
                40.72520835299988
              ],
              [
                -73.99532907899994,
                40.72508373999996
              ],
              [
                -73.99490827399991,
                40.724951228999934
              ],
              [
                -73.99417930499997,
                40.724692406999914
              ],
              [
                -73.99345769099995,
                40.72443289599994
              ],
              [
                -73.99339891300001,
                40.7244125049999
              ],
              [
                -73.99267182399991,
                40.72416025399984
              ],
              [
                -73.99260322299988,
                40.724136449999946
              ],
              [
                -73.99257987399992,
                40.724201554999915
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000373168991958,
        "objectid": 115,
        "shape_leng": 0.116169413964,
        "location_id": 115,
        "zone": "Grymes Hill/Clifton",
        "borough": "Staten Island"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -74.08069514799999,
                40.62753689699988
              ],
              [
                -74.08064690599996,
                40.62762867599987
              ],
              [
                -74.08061902199996,
                40.62772455599993
              ],
              [
                -74.08061179899993,
                40.62782070099993
              ],
              [
                -74.08059556699995,
                40.6279157079999
              ],
              [
                -74.08056350699997,
                40.6280095199999
              ],
              [
                -74.0805160199999,
                40.62809988399987
              ],
              [
                -74.08045421199994,
                40.628184620999946
              ],
              [
                -74.08037988999992,
                40.62826190099992
              ],
              [
                -74.07972492199994,
                40.62895068799985
              ],
              [
                -74.08075821999995,
                40.629494042999944
              ],
              [
                -74.08173135399988,
                40.63001222599995
              ],
              [
                -74.08140615499994,
                40.63030123599988
              ],
              [
                -74.08201443099996,
                40.63089152599993
              ],
              [
                -74.08260312199998,
                40.631464807999926
              ],
              [
                -74.08344172599995,
                40.632275245999914
              ],
              [
                -74.08491321699994,
                40.63363992399992
              ],
              [
                -74.08520082199986,
                40.63376239799988
              ],
              [
                -74.08574928999991,
                40.63395555699988
              ],
              [
                -74.08714129599997,
                40.63223951599989
              ],
              [
                -74.08761781699995,
                40.63164751199986
              ],
              [
                -74.08807764899993,
                40.63108071699992
              ],
              [
                -74.08820728099995,
                40.630903924999906
              ],
              [
                -74.08835621399997,
                40.630734963999934
              ],
              [
                -74.08852313599994,
                40.63057561499994
              ],
              [
                -74.08870635899996,
                40.63042749999993
              ],
              [
                -74.08890387699991,
                40.63029199099985
              ],
              [
                -74.08902975899993,
                40.630213168999916
              ],
              [
                -74.08914567599997,
                40.630124621999904
              ],
              [
                -74.08924974899998,
                40.63002750999989
              ],
              [
                -74.08934045699996,
                40.62992328499994
              ],
              [
                -74.08941665299993,
                40.62981355199992
              ],
              [
                -74.0915809599999,
                40.6265459219999
              ],
              [
                -74.0922397139999,
                40.62574291499991
              ],
              [
                -74.09395327099995,
                40.62433390699991
              ],
              [
                -74.09533507499991,
                40.62320160699987
              ],
              [
                -74.09639991999997,
                40.622328996999954
              ],
              [
                -74.0975173439999,
                40.62141888799987
              ],
              [
                -74.09814034099995,
                40.62091145599988
              ],
              [
                -74.09967734799997,
                40.61966605399991
              ],
              [
                -74.10004426999996,
                40.61935795499992
              ],
              [
                -74.10039572599996,
                40.61903896299989
              ],
              [
                -74.10073089199992,
                40.61870975499989
              ],
              [
                -74.10104903199995,
                40.61837105999995
              ],
              [
                -74.10134946699999,
                40.618023670999875
              ],
              [
                -74.10189835099996,
                40.61745221099993
              ],
              [
                -74.10254940599991,
                40.6168536849999
              ],
              [
                -74.10350925299994,
                40.616416074999925
              ],
              [
                -74.10302593099988,
                40.615781107999936
              ],
              [
                -74.10256802199994,
                40.615187442999854
              ],
              [
                -74.10209434299992,
                40.61460049099991
              ],
              [
                -74.10127614399993,
                40.6139854019999
              ],
              [
                -74.10068375899988,
                40.61355314999987
              ],
              [
                -74.10055168499993,
                40.61346054699993
              ],
              [
                -74.09987733699994,
                40.61291865299989
              ],
              [
                -74.09935042999992,
                40.61241907699984
              ],
              [
                -74.0986215139999,
                40.611518911999894
              ],
              [
                -74.09847060999988,
                40.61135390299989
              ],
              [
                -74.09810312099995,
                40.61096151899993
              ],
              [
                -74.09787758299997,
                40.61072932199992
              ],
              [
                -74.0977696879999,
                40.61062359299991
              ],
              [
                -74.09730455699996,
                40.61062732099988
              ],
              [
                -74.09721750699994,
                40.61062801799988
              ],
              [
                -74.09592704599994,
                40.61063834699992
              ],
              [
                -74.09555829799993,
                40.61062477799989
              ],
              [
                -74.09519027099995,
                40.61059466399993
              ],
              [
                -74.09482489099999,
                40.61054805699995
              ],
              [
                -74.09446404899988,
                40.6104852189999
              ],
              [
                -74.09410959099992,
                40.610406551999894
              ],
              [
                -74.09305685599998,
                40.61011993599989
              ],
              [
                -74.09081541099992,
                40.6092365859999
              ],
              [
                -74.09045495899996,
                40.60909452599991
              ],
              [
                -74.08949370099997,
                40.60868693299991
              ],
              [
                -74.0887463859999,
                40.60837004599991
              ],
              [
                -74.08796268699999,
                40.608051827999915
              ],
              [
                -74.0871642159999,
                40.60772760199992
              ],
              [
                -74.08635759599996,
                40.60740005899989
              ],
              [
                -74.08542756199994,
                40.607022389999905
              ],
              [
                -74.084900025,
                40.60689013199992
              ],
              [
                -74.0840678159999,
                40.606681484999925
              ],
              [
                -74.08355131599996,
                40.60655198599987
              ],
              [
                -74.08341933399991,
                40.60652250899992
              ],
              [
                -74.08280949799995,
                40.606410978999904
              ],
              [
                -74.0815547009999,
                40.60629029699988
              ],
              [
                -74.08148289099995,
                40.60638202899987
              ],
              [
                -74.08136745599988,
                40.6065294999999
              ],
              [
                -74.08129735699994,
                40.606619015999904
              ],
              [
                -74.08069498099994,
                40.607388260999876
              ],
              [
                -74.08011002799992,
                40.608135238999886
              ],
              [
                -74.07985980899997,
                40.6084464359999
              ],
              [
                -74.07962112599992,
                40.6087629369999
              ],
              [
                -74.07939423999989,
                40.60908436399989
              ],
              [
                -74.07915097299991,
                40.60948761699993
              ],
              [
                -74.07841920399994,
                40.61070060799995
              ],
              [
                -74.07784324799991,
                40.611655287999895
              ],
              [
                -74.07755680899999,
                40.61213759399992
              ],
              [
                -74.07721874699989,
                40.612707982999936
              ],
              [
                -74.0768105549999,
                40.61339668199992
              ],
              [
                -74.07661374799996,
                40.6137287289999
              ],
              [
                -74.07620479299995,
                40.61441868999987
              ],
              [
                -74.07538235299992,
                40.61580988099994
              ],
              [
                -74.07527690499992,
                40.61597807099985
              ],
              [
                -74.07502569899994,
                40.61637873899985
              ],
              [
                -74.07454676799989,
                40.61694292999991
              ],
              [
                -74.07437079999995,
                40.617127704999916
              ],
              [
                -74.07418030999995,
                40.61730493399995
              ],
              [
                -74.07397615999992,
                40.6174736419999
              ],
              [
                -74.07375937799999,
                40.61763293099989
              ],
              [
                -74.0735311079999,
                40.61778201399987
              ],
              [
                -74.07329261599993,
                40.6179201889999
              ],
              [
                -74.07301605899991,
                40.61804928399993
              ],
              [
                -74.07275022399995,
                40.61819340999991
              ],
              [
                -74.07255048299993,
                40.61831840599997
              ],
              [
                -74.07249719099993,
                40.61835175599992
              ],
              [
                -74.072258849,
                40.61852327399987
              ],
              [
                -74.07203694399992,
                40.618706741999915
              ],
              [
                -74.07197737499989,
                40.61876338999991
              ],
              [
                -74.0718329299999,
                40.61890075099993
              ],
              [
                -74.07164799799989,
                40.61910377199992
              ],
              [
                -74.07151888099999,
                40.619262757999884
              ],
              [
                -74.0714060829999,
                40.61943137899991
              ],
              [
                -74.0713113039999,
                40.61960813799994
              ],
              [
                -74.07125950599996,
                40.61973410799993
              ],
              [
                -74.07123598299994,
                40.61979131499989
              ],
              [
                -74.07118111099999,
                40.61997902699991
              ],
              [
                -74.07115583099988,
                40.620121156999886
              ],
              [
                -74.07114727799993,
                40.62016924599988
              ],
              [
                -74.071134623,
                40.62035994799992
              ],
              [
                -74.07114283699993,
                40.620549106999874
              ],
              [
                -74.07116568699992,
                40.620698785999885
              ],
              [
                -74.07127025999996,
                40.620835842999924
              ],
              [
                -74.07134330099989,
                40.62093170099998
              ],
              [
                -74.0717450489999,
                40.62145893399993
              ],
              [
                -74.0720638059999,
                40.6218592669999
              ],
              [
                -74.07253551699988,
                40.6224640219999
              ],
              [
                -74.07381368799996,
                40.621797074999975
              ],
              [
                -74.0744973829999,
                40.62144277399997
              ],
              [
                -74.07461777599994,
                40.621380100999914
              ],
              [
                -74.07558011999997,
                40.62087646899994
              ],
              [
                -74.0759248129999,
                40.62069712999992
              ],
              [
                -74.07626873999996,
                40.62051818499987
              ],
              [
                -74.07695604999999,
                40.620160559999896
              ],
              [
                -74.07769140399995,
                40.61977633599982
              ],
              [
                -74.07805406299991,
                40.61958534199987
              ],
              [
                -74.07938896199995,
                40.618885794999926
              ],
              [
                -74.08000094899994,
                40.6185529519999
              ],
              [
                -74.08110332499999,
                40.61778874499987
              ],
              [
                -74.08173992099994,
                40.61731079299991
              ],
              [
                -74.08274330499991,
                40.616581570999905
              ],
              [
                -74.08323479899997,
                40.61621947699995
              ],
              [
                -74.08331319599992,
                40.61616342599991
              ],
              [
                -74.08379332799987,
                40.615809838999944
              ],
              [
                -74.08404976799996,
                40.61562731399989
              ],
              [
                -74.08464853199992,
                40.61520502499992
              ],
              [
                -74.08476230899998,
                40.6151046959999
              ],
              [
                -74.08546427599995,
                40.61458243099993
              ],
              [
                -74.08617531899993,
                40.6140616069999
              ],
              [
                -74.08628168999991,
                40.613975617999905
              ],
              [
                -74.08692973699989,
                40.61349961799993
              ],
              [
                -74.08720982799997,
                40.613306973999954
              ],
              [
                -74.08742825199987,
                40.61441850099989
              ],
              [
                -74.08747218499994,
                40.61456080299993
              ],
              [
                -74.08753556499991,
                40.614699614999886
              ],
              [
                -74.087617514,
                40.614832668999945
              ],
              [
                -74.0877165749999,
                40.614957855999904
              ],
              [
                -74.08777786599995,
                40.61501732399993
              ],
              [
                -74.087778008,
                40.61501746799991
              ],
              [
                -74.08777810199999,
                40.61501762099988
              ],
              [
                -74.08782330099994,
                40.61508769299994
              ],
              [
                -74.08782337099996,
                40.615087799999884
              ],
              [
                -74.08782341799991,
                40.615087935999924
              ],
              [
                -74.0878483839999,
                40.6151645689999
              ],
              [
                -74.08784843099991,
                40.61516472999994
              ],
              [
                -74.08784843099988,
                40.615164874999884
              ],
              [
                -74.08785147599997,
                40.61524292099995
              ],
              [
                -74.08785147699993,
                40.61524307299993
              ],
              [
                -74.08785144099994,
                40.61524323599987
              ],
              [
                -74.08783375499992,
                40.61531764999988
              ],
              [
                -74.08769173799998,
                40.61553515299992
              ],
              [
                -74.08741339199996,
                40.61592932599992
              ],
              [
                -74.08698538899989,
                40.61653298199999
              ],
              [
                -74.0865715779999,
                40.61716938599994
              ],
              [
                -74.08626756599996,
                40.61783201099995
              ],
              [
                -74.08608006299993,
                40.61851530399988
              ],
              [
                -74.08604124499995,
                40.61882017799996
              ],
              [
                -74.08600629799997,
                40.61909415099995
              ],
              [
                -74.08589883599991,
                40.61990942899995
              ],
              [
                -74.0858126919999,
                40.6203755489999
              ],
              [
                -74.08560513399996,
                40.62114273099995
              ],
              [
                -74.0855941459999,
                40.62119087199993
              ],
              [
                -74.08548866899993,
                40.621653178999956
              ],
              [
                -74.08537203099992,
                40.62220532899995
              ],
              [
                -74.0852499159999,
                40.6228008709999
              ],
              [
                -74.08496211899991,
                40.62462523299994
              ],
              [
                -74.08415419899998,
                40.626267114999905
              ],
              [
                -74.08404821100001,
                40.62644102099992
              ],
              [
                -74.08392362399994,
                40.626609354999864
              ],
              [
                -74.08378128799997,
                40.62677039999993
              ],
              [
                -74.08362236799998,
                40.62692257799988
              ],
              [
                -74.08344837699995,
                40.62706443699992
              ],
              [
                -74.08326107499997,
                40.62719474299995
              ],
              [
                -74.0830624689999,
                40.62731250399989
              ],
              [
                -74.08298808899993,
                40.62735007299989
              ],
              [
                -74.08280689099995,
                40.62740014699993
              ],
              [
                -74.08261621699997,
                40.62743470099987
              ],
              [
                -74.08241960099991,
                40.62745240299989
              ],
              [
                -74.08222094199995,
                40.62745254499992
              ],
              [
                -74.08202428299997,
                40.62743512499987
              ],
              [
                -74.08183352499998,
                40.62740084399991
              ],
              [
                -74.0816522029999,
                40.62735102899992
              ],
              [
                -74.08099625499995,
                40.62733981399989
              ],
              [
                -74.08084371499997,
                40.62737949099987
              ],
              [
                -74.08076183699995,
                40.62745285899992
              ],
              [
                -74.08069514799999,
                40.62753689699988
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000260415337217,
        "objectid": 116,
        "shape_leng": 0.0681164844265,
        "location_id": 116,
        "zone": "Hamilton Heights",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.93924885699987,
                40.82829635099988
              ],
              [
                -73.93896034899993,
                40.82898632999993
              ],
              [
                -73.93864026699997,
                40.82973887399988
              ],
              [
                -73.93899743399992,
                40.82988885299995
              ],
              [
                -73.93962569899988,
                40.83015583699997
              ],
              [
                -73.94017089799992,
                40.83038439099989
              ],
              [
                -73.94034643199987,
                40.83045784199989
              ],
              [
                -73.94136799599993,
                40.83089083799995
              ],
              [
                -73.942979662,
                40.8315679799999
              ],
              [
                -73.94571933699994,
                40.83272141299989
              ],
              [
                -73.94582395199988,
                40.83276546099989
              ],
              [
                -73.94592558100007,
                40.83280648999992
              ],
              [
                -73.94829108099988,
                40.83386118099985
              ],
              [
                -73.94890543299992,
                40.83406529899992
              ],
              [
                -73.94919448899992,
                40.8342106589999
              ],
              [
                -73.94941570599993,
                40.83435858999992
              ],
              [
                -73.94955175300004,
                40.834410715999894
              ],
              [
                -73.94957431799986,
                40.834419361999934
              ],
              [
                -73.94964119799997,
                40.83443432999993
              ],
              [
                -73.94970807799987,
                40.834449289999945
              ],
              [
                -73.94977616199992,
                40.83445596499997
              ],
              [
                -73.94985590499991,
                40.83445928699996
              ],
              [
                -73.94997855399998,
                40.83443366399996
              ],
              [
                -73.95015521048293,
                40.83439675940792
              ],
              [
                -73.95024424006594,
                40.8342674146756
              ],
              [
                -73.95042306202323,
                40.83404708748849
              ],
              [
                -73.95066373627958,
                40.83363599766629
              ],
              [
                -73.95093576231477,
                40.833293997985656
              ],
              [
                -73.95096613500132,
                40.83321670328974
              ],
              [
                -73.95099727522977,
                40.83311027766789
              ],
              [
                -73.95105224571309,
                40.83292243763382
              ],
              [
                -73.95105949632541,
                40.83289766190666
              ],
              [
                -73.95109620887554,
                40.83280509670182
              ],
              [
                -73.95119348573833,
                40.832647134665194
              ],
              [
                -73.95131641118994,
                40.83245051197062
              ],
              [
                -73.95140314324226,
                40.83232213003094
              ],
              [
                -73.9515984038694,
                40.83204708164592
              ],
              [
                -73.95169902461552,
                40.83191599677561
              ],
              [
                -73.95214990407374,
                40.8312747172501
              ],
              [
                -73.95219759081193,
                40.83122045489184
              ],
              [
                -73.95234497932466,
                40.831019858645064
              ],
              [
                -73.95297619362613,
                40.83009020327108
              ],
              [
                -73.95359128626802,
                40.829097720648726
              ],
              [
                -73.95395406407437,
                40.82858535816411
              ],
              [
                -73.95398614911781,
                40.82854351625173
              ],
              [
                -73.95402106678668,
                40.828502980304506
              ],
              [
                -73.95403271717643,
                40.82849042349065
              ],
              [
                -73.95406111804229,
                40.82846213245149
              ],
              [
                -73.95413649135837,
                40.82839340956268
              ],
              [
                -73.95416950644189,
                40.82836086924023
              ],
              [
                -73.9542165362577,
                40.828307470855506
              ],
              [
                -73.95427220906045,
                40.82823654314243
              ],
              [
                -73.95436068010201,
                40.82811788301517
              ],
              [
                -73.95446458034706,
                40.827974332149964
              ],
              [
                -73.95446469977213,
                40.827974169698734
              ],
              [
                -73.95454188062122,
                40.827861476365506
              ],
              [
                -73.95469736619506,
                40.8279183855142
              ],
              [
                -73.955039129784,
                40.82741343191182
              ],
              [
                -73.95622394360245,
                40.82787008739143
              ],
              [
                -73.95620373779566,
                40.827896986099844
              ],
              [
                -73.95620054528379,
                40.8279012265975
              ],
              [
                -73.95631784510306,
                40.827944215388555
              ],
              [
                -73.95694866420463,
                40.82700055393508
              ],
              [
                -73.95725896977147,
                40.827117309873124
              ],
              [
                -73.95899582803376,
                40.82449642742513
              ],
              [
                -73.95901612941891,
                40.82450418849924
              ],
              [
                -73.95955575493869,
                40.82369379776886
              ],
              [
                -73.95953351105088,
                40.82368501839732
              ],
              [
                -73.95954522180324,
                40.82364733759967
              ],
              [
                -73.95954472552475,
                40.82364494182927
              ],
              [
                -73.95954284439091,
                40.823639168452445
              ],
              [
                -73.95953800225304,
                40.823629334087286
              ],
              [
                -73.95953101411841,
                40.823619182180664
              ],
              [
                -73.95950897919587,
                40.823595788951415
              ],
              [
                -73.95722203099996,
                40.82322595899993
              ],
              [
                -73.95715187399986,
                40.82321376599985
              ],
              [
                -73.9570826419999,
                40.823201547999865
              ],
              [
                -73.95682824599992,
                40.8231650789999
              ],
              [
                -73.95651468499985,
                40.82312012799986
              ],
              [
                -73.95634010399982,
                40.82309232799989
              ],
              [
                -73.95576937299988,
                40.822904320999896
              ],
              [
                -73.95573074999992,
                40.82289125699989
              ],
              [
                -73.9556966079999,
                40.82287647699994
              ],
              [
                -73.953769274,
                40.822058690999896
              ],
              [
                -73.95367103499984,
                40.82201753399995
              ],
              [
                -73.95357262899981,
                40.82197589199988
              ],
              [
                -73.9529102909999,
                40.8216969469999
              ],
              [
                -73.95082880000002,
                40.82082793699989
              ],
              [
                -73.95037710599996,
                40.82144561799988
              ],
              [
                -73.94992194399984,
                40.8220723139999
              ],
              [
                -73.94854029799983,
                40.82148762299991
              ],
              [
                -73.94808289799983,
                40.82210802199994
              ],
              [
                -73.94723844699998,
                40.82175398599991
              ],
              [
                -73.94607828700005,
                40.82126321599991
              ],
              [
                -73.94515456999994,
                40.82087626499993
              ],
              [
                -73.94460364599989,
                40.82146198099991
              ],
              [
                -73.94404986399994,
                40.82205159199989
              ],
              [
                -73.9435059449999,
                40.82262775299988
              ],
              [
                -73.94292366900005,
                40.8232605869999
              ],
              [
                -73.94242953999996,
                40.8239091569999
              ],
              [
                -73.94195569699997,
                40.82455443999992
              ],
              [
                -73.94150312699996,
                40.82517136699994
              ],
              [
                -73.94104840800001,
                40.82579262999991
              ],
              [
                -73.94059472699999,
                40.82641360399988
              ],
              [
                -73.9401395379999,
                40.82703868999988
              ],
              [
                -73.93968952899993,
                40.827656573999896
              ],
              [
                -73.93924885699987,
                40.82829635099988
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.00090429427072,
        "objectid": 117,
        "shape_leng": 0.169885857987,
        "location_id": 117,
        "zone": "Hammels/Arverne",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.7763584369479,
                40.609655838242325
              ],
              [
                -73.776474930084,
                40.609695219511146
              ],
              [
                -73.77658082259629,
                40.60974596268236
              ],
              [
                -73.77666726353627,
                40.60977291227631
              ],
              [
                -73.77680331562756,
                40.60980822439456
              ],
              [
                -73.77692798047829,
                40.6098352029453
              ],
              [
                -73.77703113969525,
                40.60984827188617
              ],
              [
                -73.77713093483725,
                40.60985322109222
              ],
              [
                -73.77727898274846,
                40.60984911309185
              ],
              [
                -73.77739041698314,
                40.60983973857035
              ],
              [
                -73.77768759425464,
                40.60980458137117
              ],
              [
                -73.77798083000845,
                40.609760562693744
              ],
              [
                -73.77828921242825,
                40.60970618063097
              ],
              [
                -73.77856806939954,
                40.609643663429
              ],
              [
                -73.77869322136964,
                40.609602597384516
              ],
              [
                -73.77881487685055,
                40.6095502680001
              ],
              [
                -73.77927015444938,
                40.60930736180752
              ],
              [
                -73.77940143922393,
                40.609226260682334
              ],
              [
                -73.78024564879574,
                40.60856335375474
              ],
              [
                -73.78040832267868,
                40.608425974827945
              ],
              [
                -73.7809873307122,
                40.60790705402877
              ],
              [
                -73.78102182419175,
                40.60786652366037
              ],
              [
                -73.78127010687184,
                40.60763681319177
              ],
              [
                -73.78130436722182,
                40.60759911103063
              ],
              [
                -73.78136750281017,
                40.6075436235693
              ],
              [
                -73.78141950854503,
                40.60748841193749
              ],
              [
                -73.7814658668753,
                40.6074434837317
              ],
              [
                -73.78150652174155,
                40.60740495535023
              ],
              [
                -73.78153852900552,
                40.6073691838018
              ],
              [
                -73.78155885431538,
                40.60734336860472
              ],
              [
                -73.78157907651074,
                40.60732729722844
              ],
              [
                -73.78161663663873,
                40.60728441352481
              ],
              [
                -73.78163667536029,
                40.607270259332815
              ],
              [
                -73.78165603780593,
                40.60725338484819
              ],
              [
                -73.7816923823052,
                40.60721727839561
              ],
              [
                -73.78173055395993,
                40.60717563050876
              ],
              [
                -73.78174774113893,
                40.607159002669334
              ],
              [
                -73.78178620290736,
                40.60711875904474
              ],
              [
                -73.78180699983872,
                40.60710045488062
              ],
              [
                -73.78183306559185,
                40.6070747224023
              ],
              [
                -73.78188075607422,
                40.607023744217315
              ],
              [
                -73.78195272531626,
                40.60694405814234
              ],
              [
                -73.78198561886217,
                40.60690498446755
              ],
              [
                -73.7820356078126,
                40.60684043917709
              ],
              [
                -73.78212243600952,
                40.60674097013423
              ],
              [
                -73.7822083011732,
                40.606639409939575
              ],
              [
                -73.78225374776285,
                40.60658388025983
              ],
              [
                -73.78228947950905,
                40.60655077207295
              ],
              [
                -73.78233124579783,
                40.60651892741941
              ],
              [
                -73.78237872847606,
                40.606483987074036
              ],
              [
                -73.7824627541849,
                40.60641379679842
              ],
              [
                -73.78248626610716,
                40.60639594847149
              ],
              [
                -73.78250922760172,
                40.6063872106951
              ],
              [
                -73.7825361714401,
                40.606386028157814
              ],
              [
                -73.78255966900377,
                40.60637986832513
              ],
              [
                -73.7825863267078,
                40.60636874352882
              ],
              [
                -73.78259735072109,
                40.606354167532245
              ],
              [
                -73.78260283324343,
                40.606332104711875
              ],
              [
                -73.78260173572208,
                40.60632474658101
              ],
              [
                -73.78263012680134,
                40.606310661790616
              ],
              [
                -73.78265821587696,
                40.60629886336793
              ],
              [
                -73.78265122934431,
                40.60627821034834
              ],
              [
                -73.78268130761911,
                40.606254079475754
              ],
              [
                -73.7827129263491,
                40.60625364353649
              ],
              [
                -73.78273827896187,
                40.60623319489354
              ],
              [
                -73.78273951892068,
                40.6062154302263
              ],
              [
                -73.78274680234489,
                40.60619173320182
              ],
              [
                -73.7827745192134,
                40.60618560810544
              ],
              [
                -73.78279335180751,
                40.60617513440131
              ],
              [
                -73.78279970949166,
                40.60615639717901
              ],
              [
                -73.78280460652957,
                40.606125834300705
              ],
              [
                -73.78282806847018,
                40.606108966192195
              ],
              [
                -73.78284369490656,
                40.60610341279558
              ],
              [
                -73.78288504089629,
                40.60608769622132
              ],
              [
                -73.7828931300397,
                40.60608185734917
              ],
              [
                -73.78290564931923,
                40.60606160188001
              ],
              [
                -73.78293629446301,
                40.606052023293074
              ],
              [
                -73.78298147504987,
                40.60601269326455
              ],
              [
                -73.78303641072941,
                40.605952821904445
              ],
              [
                -73.78307003702224,
                40.60592412256307
              ],
              [
                -73.78311089073352,
                40.60588525256188
              ],
              [
                -73.78315553330835,
                40.60585530477967
              ],
              [
                -73.7831765582718,
                40.605835585674804
              ],
              [
                -73.78319201769607,
                40.605812256025004
              ],
              [
                -73.78319733341529,
                40.60578361079231
              ],
              [
                -73.7832415871083,
                40.60575276143667
              ],
              [
                -73.78325298186276,
                40.60573678016785
              ],
              [
                -73.78325148966285,
                40.60571962232742
              ],
              [
                -73.7832328529063,
                40.60570267590857
              ],
              [
                -73.78323984389571,
                40.60567825758837
              ],
              [
                -73.78325205219865,
                40.605655695282614
              ],
              [
                -73.78326977128401,
                40.60563498066414
              ],
              [
                -73.78328732604626,
                40.60561782255299
              ],
              [
                -73.78329484997334,
                40.605607850637675
              ],
              [
                -73.78330237302306,
                40.60559787754757
              ],
              [
                -73.78331677485,
                40.605587278467965
              ],
              [
                -73.78333790302254,
                40.605590443562825
              ],
              [
                -73.78335880755236,
                40.605596687257936
              ],
              [
                -73.78338792822798,
                40.60559084359666
              ],
              [
                -73.78340388940889,
                40.60557670938839
              ],
              [
                -73.78340456530181,
                40.60555774514358
              ],
              [
                -73.78339077689193,
                40.60554648965176
              ],
              [
                -73.78337962432077,
                40.605531816832475
              ],
              [
                -73.78338424455795,
                40.605517029497385
              ],
              [
                -73.78340119872027,
                40.60550624624426
              ],
              [
                -73.78344368371363,
                40.605510990827774
              ],
              [
                -73.7834718473759,
                40.605498005567284
              ],
              [
                -73.78349084244981,
                40.60549576261445
              ],
              [
                -73.78351610591254,
                40.605476682180374
              ],
              [
                -73.7835540305273,
                40.605433961030286
              ],
              [
                -73.7835581114659,
                40.605407123946655
              ],
              [
                -73.78357072106958,
                40.60539518038593
              ],
              [
                -73.78360001082207,
                40.6053807457385
              ],
              [
                -73.78362945445033,
                40.60536284518687
              ],
              [
                -73.78366696292244,
                40.60532443693426
              ],
              [
                -73.78366993164084,
                40.60530877297988
              ],
              [
                -73.78366474741357,
                40.60528619497972
              ],
              [
                -73.78366824932449,
                40.60525935730475
              ],
              [
                -73.78367444264205,
                40.605247896202506
              ],
              [
                -73.78368064738753,
                40.605236435121476
              ],
              [
                -73.78369369911347,
                40.6052193596087
              ],
              [
                -73.78370892902625,
                40.60519743251664
              ],
              [
                -73.78371775172984,
                40.60517672805844
              ],
              [
                -73.78373194492407,
                40.60515394494663
              ],
              [
                -73.78376301359896,
                40.605111705372906
              ],
              [
                -73.78378379511754,
                40.60508687219362
              ],
              [
                -73.78379507186638,
                40.60507440340834
              ],
              [
                -73.78382062196515,
                40.6050583674584
              ],
              [
                -73.783817659506,
                40.605049915045576
              ],
              [
                -73.78380917955153,
                40.605045090158725
              ],
              [
                -73.78382786824373,
                40.6050135354341
              ],
              [
                -73.78385371955581,
                40.60498069632541
              ],
              [
                -73.78387413209273,
                40.60497129810704
              ],
              [
                -73.7838997853996,
                40.60495962162028
              ],
              [
                -73.7839173477141,
                40.60493961760184
              ],
              [
                -73.78393244742743,
                40.60492534549857
              ],
              [
                -73.78393049962523,
                40.60490289153482
              ],
              [
                -73.78394980798916,
                40.60488039795406
              ],
              [
                -73.78395674485067,
                40.60486526297045
              ],
              [
                -73.78394764971192,
                40.60482442511683
              ],
              [
                -73.78393645837984,
                40.604799451052095
              ],
              [
                -73.78392399606521,
                40.604776806608314
              ],
              [
                -73.78390153621177,
                40.604750990626904
              ],
              [
                -73.78388690725316,
                40.604729305567595
              ],
              [
                -73.78389001884796,
                40.60471348104694
              ],
              [
                -73.78389101793894,
                40.604696958646514
              ],
              [
                -73.78388089200148,
                40.604685988365674
              ],
              [
                -73.78387077837404,
                40.60467501877716
              ],
              [
                -73.78380616108427,
                40.6045870601519
              ],
              [
                -73.78377702304792,
                40.604554974018534
              ],
              [
                -73.78376440568522,
                40.604536254817845
              ],
              [
                -73.78374492984211,
                40.604511796550256
              ],
              [
                -73.78370918509916,
                40.604472943008005
              ],
              [
                -73.78368085978576,
                40.60445971517306
              ],
              [
                -73.78365127613456,
                40.60445183351137
              ],
              [
                -73.78363998576066,
                40.604435702446274
              ],
              [
                -73.78363675613298,
                40.60442241428663
              ],
              [
                -73.78362777074119,
                40.60441690390853
              ],
              [
                -73.78362721014985,
                40.60440363719123
              ],
              [
                -73.78362666254831,
                40.60439036396483
              ],
              [
                -73.78360617356066,
                40.60436893672123
              ],
              [
                -73.7836050067433,
                40.60436089394237
              ],
              [
                -73.78363792382835,
                40.60432790517752
              ],
              [
                -73.78363588044574,
                40.60431341203277
              ],
              [
                -73.78362855440238,
                40.60429527974954
              ],
              [
                -73.78361728511553,
                40.60427244871115
              ],
              [
                -73.78360749285513,
                40.60425011499985
              ],
              [
                -73.78359541366827,
                40.604233162889976
              ],
              [
                -73.78357890384383,
                40.604216084327405
              ],
              [
                -73.78355968611206,
                40.604199793412896
              ],
              [
                -73.7835298312738,
                40.604166309841176
              ],
              [
                -73.78351427600708,
                40.60414247978211
              ],
              [
                -73.78350383731244,
                40.60411893879855
              ],
              [
                -73.78349844663506,
                40.60409438958107
              ],
              [
                -73.78348890697673,
                40.60407029961985
              ],
              [
                -73.78348417724956,
                40.60406425763439
              ],
              [
                -73.78348138271578,
                40.604047835811045
              ],
              [
                -73.78346451772407,
                40.604027244807426
              ],
              [
                -73.78345546639713,
                40.604020330058326
              ],
              [
                -73.78339119484156,
                40.603972238523156
              ],
              [
                -73.78337341425865,
                40.60396097550291
              ],
              [
                -73.78335044081527,
                40.60394754139181
              ],
              [
                -73.78332575086432,
                40.60393209536047
              ],
              [
                -73.78329312325307,
                40.60393035955327
              ],
              [
                -73.78327556725573,
                40.603937133511245
              ],
              [
                -73.78325548762322,
                40.60393146715378
              ],
              [
                -73.7832266271072,
                40.60391569107638
              ],
              [
                -73.78320431690399,
                40.60389851976425
              ],
              [
                -73.7831847587032,
                40.60387781558216
              ],
              [
                -73.7831644438236,
                40.60385762378231
              ],
              [
                -73.7831474349527,
                40.60383772586495
              ],
              [
                -73.78313432465106,
                40.60381474689355
              ],
              [
                -73.78312802405742,
                40.60376099285311
              ],
              [
                -73.7831363378543,
                40.6037149281873
              ],
              [
                -73.78313980170074,
                40.603670547926185
              ],
              [
                -73.78313333402781,
                40.603643196400924
              ],
              [
                -73.78313211669624,
                40.60361750109593
              ],
              [
                -73.78313663833141,
                40.60358960204613
              ],
              [
                -73.78315109265385,
                40.60354112698989
              ],
              [
                -73.78317278644302,
                40.60347158433602
              ],
              [
                -73.78320987069085,
                40.60338527586794
              ],
              [
                -73.7832283674284,
                40.603336016848885
              ],
              [
                -73.78324859465145,
                40.60329257721534
              ],
              [
                -73.78328989623121,
                40.603228150678184
              ],
              [
                -73.78332456130755,
                40.60316634970286
              ],
              [
                -73.78334197405361,
                40.60312704892585
              ],
              [
                -73.78335762780752,
                40.603076404919356
              ],
              [
                -73.78336431251098,
                40.60305909998535
              ],
              [
                -73.78337125913842,
                40.603037347298766
              ],
              [
                -73.78337317693969,
                40.60297792548422
              ],
              [
                -73.78339598604265,
                40.60292856684337
              ],
              [
                -73.78340637324833,
                40.60290286643755
              ],
              [
                -73.78342932788466,
                40.60285226482698
              ],
              [
                -73.78344576340848,
                40.60280464870275
              ],
              [
                -73.78345786484006,
                40.60276009589779
              ],
              [
                -73.78346427862317,
                40.60273515377761
              ],
              [
                -73.7834683209106,
                40.6027164671002
              ],
              [
                -73.78347079103499,
                40.60268345837029
              ],
              [
                -73.78346945172416,
                40.60265182910565
              ],
              [
                -73.78349609995502,
                40.60258469139354
              ],
              [
                -73.78350560905308,
                40.60256343874268
              ],
              [
                -73.78352073287924,
                40.60254098123989
              ],
              [
                -73.7835567842201,
                40.60249636480662
              ],
              [
                -73.78359527397528,
                40.60245364469779
              ],
              [
                -73.78364491488625,
                40.60239324975153
              ],
              [
                -73.78373615075938,
                40.60229795656542
              ],
              [
                -73.78376189431725,
                40.602276563728175
              ],
              [
                -73.78380343380033,
                40.60223681204849
              ],
              [
                -73.78382888415051,
                40.602218119556994
              ],
              [
                -73.78385119349083,
                40.602202708753694
              ],
              [
                -73.78389262255426,
                40.60217544616632
              ],
              [
                -73.78394152998196,
                40.6021407872861
              ],
              [
                -73.78398861129908,
                40.60210442251685
              ],
              [
                -73.78403910487113,
                40.6020690276031
              ],
              [
                -73.7841009961769,
                40.60202712595284
              ],
              [
                -73.78416952993348,
                40.601981003605616
              ],
              [
                -73.78422268038925,
                40.601949368441986
              ],
              [
                -73.78428141234191,
                40.60191643900972
              ],
              [
                -73.78433250516586,
                40.60188534892759
              ],
              [
                -73.78438419864632,
                40.60185854736744
              ],
              [
                -73.78442865140248,
                40.60183110145369
              ],
              [
                -73.78448233170379,
                40.60180009858293
              ],
              [
                -73.78451003112923,
                40.601787390462775
              ],
              [
                -73.78453737153366,
                40.601776274199324
              ],
              [
                -73.78456651170444,
                40.60176370379556
              ],
              [
                -73.78459545855709,
                40.60175252854368
              ],
              [
                -73.78462516662738,
                40.60174358017458
              ],
              [
                -73.78464772967796,
                40.60173703820713
              ],
              [
                -73.7846953711013,
                40.60172505143189
              ],
              [
                -73.78473444345646,
                40.60171376058757
              ],
              [
                -73.78476801116503,
                40.601701936226156
              ],
              [
                -73.78479582997821,
                40.601707121093725
              ],
              [
                -73.78481371970436,
                40.60169927483625
              ],
              [
                -73.78485964586187,
                40.601672732321376
              ],
              [
                -73.78487058620505,
                40.60167295098844
              ],
              [
                -73.78487942718563,
                40.6016793350031
              ],
              [
                -73.78488951641785,
                40.60167929893805
              ],
              [
                -73.78491612549155,
                40.601667624012286
              ],
              [
                -73.78494233197902,
                40.601648960666815
              ],
              [
                -73.78496309533641,
                40.601643694861004
              ],
              [
                -73.78499779144987,
                40.60163772669872
              ],
              [
                -73.7850259143311,
                40.601636600736285
              ],
              [
                -73.78505429032063,
                40.601637552942606
              ],
              [
                -73.78508307760494,
                40.601635688733104
              ],
              [
                -73.7851434486721,
                40.6016180175076
              ],
              [
                -73.78517278165994,
                40.60161145240966
              ],
              [
                -73.78519419789826,
                40.60160512614743
              ],
              [
                -73.7852205056038,
                40.60159910488
              ],
              [
                -73.78525301558588,
                40.6015896477717
              ],
              [
                -73.78528368751248,
                40.60158193287872
              ],
              [
                -73.78531548692725,
                40.601569178447484
              ],
              [
                -73.78536819795482,
                40.601537767595985
              ],
              [
                -73.78539358087029,
                40.601518021708465
              ],
              [
                -73.78541561873325,
                40.60149511683
              ],
              [
                -73.7854472113968,
                40.60145817304529
              ],
              [
                -73.78546138101116,
                40.60144250364773
              ],
              [
                -73.78549716081618,
                40.601403893374595
              ],
              [
                -73.78551703221468,
                40.60138224593091
              ],
              [
                -73.78553432310275,
                40.60135836988005
              ],
              [
                -73.78554281915132,
                40.60133604297962
              ],
              [
                -73.78554609407448,
                40.60131702978832
              ],
              [
                -73.78554956347622,
                40.601288516690374
              ],
              [
                -73.78556135556887,
                40.601262766607505
              ],
              [
                -73.78557197568765,
                40.60124112769142
              ],
              [
                -73.78557921545465,
                40.601216170071844
              ],
              [
                -73.7855819807166,
                40.60118652969233
              ],
              [
                -73.78557852749977,
                40.60116175851861
              ],
              [
                -73.78557493300859,
                40.60113683446171
              ],
              [
                -73.785565682718,
                40.60109234914515
              ],
              [
                -73.7855645941961,
                40.60107828166222
              ],
              [
                -73.7855634439396,
                40.601046480886176
              ],
              [
                -73.78556455167376,
                40.601017747602235
              ],
              [
                -73.78556742279173,
                40.60098826992373
              ],
              [
                -73.78558236941569,
                40.600918138415196
              ],
              [
                -73.78558774342773,
                40.60089287039914
              ],
              [
                -73.78560190733319,
                40.600867979472376
              ],
              [
                -73.78561954620693,
                40.60084602160114
              ],
              [
                -73.78563276373285,
                40.600816383628434
              ],
              [
                -73.7859185896004,
                40.600919824672765
              ],
              [
                -73.78588596247505,
                40.60095991052161
              ],
              [
                -73.78594303730421,
                40.600983932868175
              ],
              [
                -73.78598359117792,
                40.60091823794192
              ],
              [
                -73.78564205273257,
                40.600797194520894
              ],
              [
                -73.78566778500591,
                40.600761281107125
              ],
              [
                -73.78568891607705,
                40.60072968555991
              ],
              [
                -73.78570450753243,
                40.600709021039314
              ],
              [
                -73.78572362900528,
                40.60068509342244
              ],
              [
                -73.78573810856423,
                40.60066461562998
              ],
              [
                -73.78575323544425,
                40.600641454547706
              ],
              [
                -73.78577809498513,
                40.600596645836625
              ],
              [
                -73.78578006551935,
                40.60059326189304
              ],
              [
                -73.78610528242092,
                40.600711757064076
              ],
              [
                -73.78614358698641,
                40.60064861875177
              ],
              [
                -73.7860887345344,
                40.60062972570632
              ],
              [
                -73.7860650818483,
                40.60066726577754
              ],
              [
                -73.78579378813264,
                40.60056892899843
              ],
              [
                -73.78582371060969,
                40.600504643170055
              ],
              [
                -73.78585126197784,
                40.600460344561036
              ],
              [
                -73.78588400526695,
                40.600422141299944
              ],
              [
                -73.78592510437558,
                40.60036803375624
              ],
              [
                -73.78595291643495,
                40.600338088709286
              ],
              [
                -73.78597109766216,
                40.60031649181468
              ],
              [
                -73.78599381598204,
                40.600294984785414
              ],
              [
                -73.78602374866986,
                40.60028173196699
              ],
              [
                -73.78605715435523,
                40.60027257215635
              ],
              [
                -73.78607800558748,
                40.60026187600755
              ],
              [
                -73.78612739135649,
                40.60024684904556
              ],
              [
                -73.78616546425769,
                40.600233789255185
              ],
              [
                -73.78620893972153,
                40.60020463957009
              ],
              [
                -73.78623873377425,
                40.60019086341014
              ],
              [
                -73.78629157279269,
                40.6001634861503
              ],
              [
                -73.78633700069804,
                40.6001372485292
              ],
              [
                -73.7863951501854,
                40.6001155908396
              ],
              [
                -73.78640244096495,
                40.60011151582979
              ],
              [
                -73.78642400877713,
                40.600113204907935
              ],
              [
                -73.78644433473032,
                40.60010796499427
              ],
              [
                -73.78646687478977,
                40.6000975243803
              ],
              [
                -73.78647084246816,
                40.60008336695432
              ],
              [
                -73.78650050573333,
                40.600073272282394
              ],
              [
                -73.78653086817748,
                40.60006234292854
              ],
              [
                -73.78656003666514,
                40.60005156329871
              ],
              [
                -73.78658480977553,
                40.600040983068446
              ],
              [
                -73.78660540137093,
                40.600030395068515
              ],
              [
                -73.78662409463627,
                40.60001863317834
              ],
              [
                -73.78664367471622,
                40.59999890421691
              ],
              [
                -73.78666800277085,
                40.59997998334981
              ],
              [
                -73.78669090075941,
                40.59996095327426
              ],
              [
                -73.78675556871694,
                40.59991783874604
              ],
              [
                -73.78679571731941,
                40.59988341436123
              ],
              [
                -73.78681970674813,
                40.59987036441243
              ],
              [
                -73.78684530718954,
                40.59984899809233
              ],
              [
                -73.78685794744099,
                40.59982714702437
              ],
              [
                -73.7868726736499,
                40.59980683204932
              ],
              [
                -73.78689334053554,
                40.59978342929301
              ],
              [
                -73.78690879051676,
                40.59975883745111
              ],
              [
                -73.78692146983113,
                40.59973575359784
              ],
              [
                -73.78693257025918,
                40.599711261912155
              ],
              [
                -73.78694898907159,
                40.59968996800669
              ],
              [
                -73.78700008160382,
                40.59968064302488
              ],
              [
                -73.7870231292598,
                40.59966658409975
              ],
              [
                -73.78702449400723,
                40.59965732894118
              ],
              [
                -73.78700143510532,
                40.599637916094224
              ],
              [
                -73.78699255784456,
                40.59962797552007
              ],
              [
                -73.78698368124559,
                40.59961803494638
              ],
              [
                -73.7869763105808,
                40.59960673849804
              ],
              [
                -73.78696895024865,
                40.59959544206845
              ],
              [
                -73.78697627601906,
                40.599580577675724
              ],
              [
                -73.78700832765766,
                40.599532657934624
              ],
              [
                -73.78703790554067,
                40.599519185735836
              ],
              [
                -73.78706556464131,
                40.599504199359316
              ],
              [
                -73.78709163412174,
                40.599483382177766
              ],
              [
                -73.78709826803977,
                40.59947048999462
              ],
              [
                -73.78709695541514,
                40.599452099006896
              ],
              [
                -73.78710231226577,
                40.59944330089239
              ],
              [
                -73.78712482297082,
                40.59944196466069
              ],
              [
                -73.78714983363757,
                40.599445775209716
              ],
              [
                -73.78716919221347,
                40.59943985923696
              ],
              [
                -73.78719087856445,
                40.59942691316935
              ],
              [
                -73.78721550733746,
                40.59940593246331
              ],
              [
                -73.78724385944058,
                40.59936939614651
              ],
              [
                -73.78728350798691,
                40.59933238568244
              ],
              [
                -73.78732492543952,
                40.599296451505474
              ],
              [
                -73.78735136126865,
                40.59928332488851
              ],
              [
                -73.7873804101433,
                40.5992656476337
              ],
              [
                -73.78740174482711,
                40.5992586088086
              ],
              [
                -73.78743157906484,
                40.59925416096675
              ],
              [
                -73.78744993620624,
                40.59924741516292
              ],
              [
                -73.78747494755444,
                40.59922882910727
              ],
              [
                -73.78750156253537,
                40.59921484855332
              ],
              [
                -73.78751457283121,
                40.599202931599265
              ],
              [
                -73.78753410819982,
                40.59919709546848
              ],
              [
                -73.78754027623143,
                40.59919312636744
              ],
              [
                -73.78756221247787,
                40.59917902842276
              ],
              [
                -73.78758292335176,
                40.59915686065165
              ],
              [
                -73.7875940545273,
                40.59914132040214
              ],
              [
                -73.78759278945736,
                40.59913398663168
              ],
              [
                -73.78762264350814,
                40.59911941864635
              ],
              [
                -73.78764162877337,
                40.599097461778555
              ],
              [
                -73.78765085932115,
                40.599081260290006
              ],
              [
                -73.78765582919964,
                40.599056649149034
              ],
              [
                -73.78768875002692,
                40.59903250461988
              ],
              [
                -73.78772349722136,
                40.599009757804176
              ],
              [
                -73.78774204023071,
                40.59898890026985
              ],
              [
                -73.78775051756722,
                40.59897220166182
              ],
              [
                -73.78778210998513,
                40.59896809069548
              ],
              [
                -73.78780551211833,
                40.59894658440244
              ],
              [
                -73.7878231440424,
                40.598929814403846
              ],
              [
                -73.78783559420927,
                40.59891575216709
              ],
              [
                -73.78785750117561,
                40.59888494973109
              ],
              [
                -73.78787414051943,
                40.598872327434115
              ],
              [
                -73.78789223767818,
                40.59886552629758
              ],
              [
                -73.78791566941302,
                40.59884574927774
              ],
              [
                -73.78792811711247,
                40.59882477173755
              ],
              [
                -73.78796656719273,
                40.59880434727615
              ],
              [
                -73.78798201164625,
                40.598795558852814
              ],
              [
                -73.78800284383935,
                40.598779540740175
              ],
              [
                -73.78801808483836,
                40.598764773808746
              ],
              [
                -73.78803815735542,
                40.59874960952733
              ],
              [
                -73.7880581860508,
                40.59873391493017
              ],
              [
                -73.7880719963165,
                40.59871883843859
              ],
              [
                -73.788092046576,
                40.5986963438469
              ],
              [
                -73.78810693083861,
                40.59868212574317
              ],
              [
                -73.78815240499182,
                40.59865185203498
              ],
              [
                -73.78817521847108,
                40.59863306476013
              ],
              [
                -73.78819122459387,
                40.5986152815045
              ],
              [
                -73.78820372858448,
                40.59859120624724
              ],
              [
                -73.7882101727132,
                40.59856744482849
              ],
              [
                -73.78822073050371,
                40.59855368647922
              ],
              [
                -73.78826186810186,
                40.598538669702705
              ],
              [
                -73.78828847173249,
                40.5985208516789
              ],
              [
                -73.78834045693753,
                40.59850137092641
              ],
              [
                -73.7883667765844,
                40.59849082884424
              ],
              [
                -73.78841627774574,
                40.5984724491788
              ],
              [
                -73.78842878612564,
                40.59846589063771
              ],
              [
                -73.78846760804292,
                40.59844300668786
              ],
              [
                -73.78849232596227,
                40.598427293171284
              ],
              [
                -73.78850339346606,
                40.59841329197849
              ],
              [
                -73.7885112521853,
                40.59839766436521
              ],
              [
                -73.78852376561038,
                40.5983858276339
              ],
              [
                -73.78853627903102,
                40.5983739909014
              ],
              [
                -73.78855367211774,
                40.59836181233864
              ],
              [
                -73.78858394436503,
                40.59834555952459
              ],
              [
                -73.78860652033904,
                40.5983348660897
              ],
              [
                -73.78864132611197,
                40.598327005286194
              ],
              [
                -73.78867553924202,
                40.598326951269264
              ],
              [
                -73.7887047635189,
                40.598332363441244
              ],
              [
                -73.78872539542698,
                40.59833078085937
              ],
              [
                -73.78874406562063,
                40.598314649910805
              ],
              [
                -73.78876415584486,
                40.59830518707161
              ],
              [
                -73.78877241067005,
                40.59829111752411
              ],
              [
                -73.78879716014761,
                40.598276781931496
              ],
              [
                -73.78881408327982,
                40.5982639717166
              ],
              [
                -73.78883199925075,
                40.598247037905765
              ],
              [
                -73.7888443043442,
                40.598226339945896
              ],
              [
                -73.78885999736168,
                40.598206773171015
              ],
              [
                -73.78889576026538,
                40.59816135406835
              ],
              [
                -73.78897703662628,
                40.59809167597657
              ],
              [
                -73.7890682912148,
                40.5980199273826
              ],
              [
                -73.78913926451678,
                40.597928492621485
              ],
              [
                -73.78916558855904,
                40.597882758079685
              ],
              [
                -73.7891815464319,
                40.5978353565264
              ],
              [
                -73.78919080249129,
                40.5977882765636
              ],
              [
                -73.78919207479233,
                40.59771853278256
              ],
              [
                -73.78919199856605,
                40.59764897650603
              ],
              [
                -73.78918554149362,
                40.5975987330701
              ],
              [
                -73.78915664160107,
                40.597524927127594
              ],
              [
                -73.78914765407926,
                40.59750882712001
              ],
              [
                -73.7891318136952,
                40.59748208896854
              ],
              [
                -73.78911747897803,
                40.59746115161185
              ],
              [
                -73.78911067519103,
                40.59744917181234
              ],
              [
                -73.7891038727293,
                40.59743719084212
              ],
              [
                -73.78909938750327,
                40.5974211540428
              ],
              [
                -73.78910066668472,
                40.5974125113571
              ],
              [
                -73.78910195861313,
                40.59740386869476
              ],
              [
                -73.7891181658512,
                40.59738166440244
              ],
              [
                -73.78913396797209,
                40.597361214907785
              ],
              [
                -73.78913441572564,
                40.597353750439986
              ],
              [
                -73.78912107702602,
                40.597338957218675
              ],
              [
                -73.78914279977992,
                40.59732145504719
              ],
              [
                -73.78914947665892,
                40.59730958944471
              ],
              [
                -73.78915616499374,
                40.597297714481215
              ],
              [
                -73.78915978633384,
                40.597280944871976
              ],
              [
                -73.78915880590186,
                40.59725824900198
              ],
              [
                -73.78917081216848,
                40.59720896771843
              ],
              [
                -73.78918637503419,
                40.597185339907064
              ],
              [
                -73.78919799854089,
                40.59716323592127
              ],
              [
                -73.78921222323427,
                40.59714034227963
              ],
              [
                -73.78922017232334,
                40.59711123450751
              ],
              [
                -73.78922065193284,
                40.597101194329
              ],
              [
                -73.78922113376595,
                40.5970911459456
              ],
              [
                -73.7892167419102,
                40.597067669831404
              ],
              [
                -73.78922272649518,
                40.59704311476
              ],
              [
                -73.78923548177478,
                40.59701796782672
              ],
              [
                -73.78923987553891,
                40.59699196053737
              ],
              [
                -73.78924787332302,
                40.59696976747188
              ],
              [
                -73.78925638990108,
                40.59694043507512
              ],
              [
                -73.78925996090993,
                40.596916757786545
              ],
              [
                -73.7892548556676,
                40.59689097582709
              ],
              [
                -73.78924970828197,
                40.59686733632711
              ],
              [
                -73.789243478524,
                40.596846189358494
              ],
              [
                -73.7892363961816,
                40.59682951705122
              ],
              [
                -73.78922257366165,
                40.59680697822421
              ],
              [
                -73.78920315252981,
                40.59678789699025
              ],
              [
                -73.7891987448845,
                40.59678063872335
              ],
              [
                -73.78920081200863,
                40.59677351044183
              ],
              [
                -73.7892239473967,
                40.59675716361739
              ],
              [
                -73.78922928197579,
                40.5967329137645
              ],
              [
                -73.78923191354295,
                40.59671100839005
              ],
              [
                -73.78922956489518,
                40.596688194246994
              ],
              [
                -73.78923757533867,
                40.596661562338596
              ],
              [
                -73.78923712086214,
                40.596644702993665
              ],
              [
                -73.78922877308254,
                40.59662077868542
              ],
              [
                -73.78923305136935,
                40.596601686473804
              ],
              [
                -73.78924244828703,
                40.59657752532249
              ],
              [
                -73.78925362474264,
                40.596528439520206
              ],
              [
                -73.78926243909658,
                40.59650606031712
              ],
              [
                -73.78926786684313,
                40.59647804523577
              ],
              [
                -73.78926858096266,
                40.596453723748894
              ],
              [
                -73.78926249847342,
                40.59643063203088
              ],
              [
                -73.78924554412214,
                40.59640532288754
              ],
              [
                -73.78922841127229,
                40.59638358062375
              ],
              [
                -73.78921176727629,
                40.59636056837387
              ],
              [
                -73.78920558571939,
                40.59633517143004
              ],
              [
                -73.78920609254766,
                40.59630961604181
              ],
              [
                -73.78921223411778,
                40.59628390681027
              ],
              [
                -73.78920034771825,
                40.596239588818584
              ],
              [
                -73.789191401145,
                40.596218157925065
              ],
              [
                -73.78918028632464,
                40.59619502966315
              ],
              [
                -73.78917605194492,
                40.5961780836387
              ],
              [
                -73.78917376347391,
                40.59615063137687
              ],
              [
                -73.78917203908622,
                40.5961242516696
              ],
              [
                -73.78916999432185,
                40.596101960103894
              ],
              [
                -73.78916261774464,
                40.59607729876546
              ],
              [
                -73.78914969171473,
                40.59605188944356
              ],
              [
                -73.78913143742209,
                40.59602981071732
              ],
              [
                -73.78911779697158,
                40.59601718762182
              ],
              [
                -73.78910696253806,
                40.5959917278535
              ],
              [
                -73.78910870574009,
                40.59597449526334
              ],
              [
                -73.78912087264571,
                40.595952716115
              ],
              [
                -73.78913823241385,
                40.595928514633115
              ],
              [
                -73.78912428120124,
                40.595913252040326
              ],
              [
                -73.78911174911906,
                40.595901323713036
              ],
              [
                -73.78910667303099,
                40.5958774873199
              ],
              [
                -73.78911655249819,
                40.59585027249395
              ],
              [
                -73.78913005053164,
                40.595829549884144
              ],
              [
                -73.78914988006115,
                40.59580863952976
              ],
              [
                -73.78915195151714,
                40.5957926147597
              ],
              [
                -73.78916661360014,
                40.5957585560699
              ],
              [
                -73.78918997886312,
                40.5957370226188
              ],
              [
                -73.7892099907696,
                40.59571471723352
              ],
              [
                -73.78921553350067,
                40.59569134042298
              ],
              [
                -73.78921284672033,
                40.5956669051424
              ],
              [
                -73.78920272046062,
                40.59564498574594
              ],
              [
                -73.7891853582447,
                40.59562502439615
              ],
              [
                -73.78916410295061,
                40.59560693005603
              ],
              [
                -73.78914168951475,
                40.595589509401464
              ],
              [
                -73.78912313581735,
                40.59556902433601
              ],
              [
                -73.7891150378258,
                40.595544560359336
              ],
              [
                -73.78911118815091,
                40.595521409067274
              ],
              [
                -73.78911266330519,
                40.59547345891198
              ],
              [
                -73.78912042464385,
                40.59542483626864
              ],
              [
                -73.78912861078479,
                40.59538401240888
              ],
              [
                -73.78914183196389,
                40.595338253315845
              ],
              [
                -73.78918207200937,
                40.59529643533903
              ],
              [
                -73.78918593810931,
                40.595280556595384
              ],
              [
                -73.78918922141126,
                40.59523222276166
              ],
              [
                -73.78918865986203,
                40.595207935118445
              ],
              [
                -73.78918045239367,
                40.59519580732926
              ],
              [
                -73.78917366224985,
                40.59519113084521
              ],
              [
                -73.78918073556748,
                40.59518477635302
              ],
              [
                -73.78921094671544,
                40.59518354447518
              ],
              [
                -73.78924406645662,
                40.59518527951386
              ],
              [
                -73.78927385022848,
                40.59518478580918
              ],
              [
                -73.7893042029443,
                40.59518744353603
              ],
              [
                -73.78933124413724,
                40.59519872262826
              ],
              [
                -73.78935311513338,
                40.59519376497541
              ],
              [
                -73.78937617071956,
                40.59517654346756
              ],
              [
                -73.78937936325215,
                40.59516049444521
              ],
              [
                -73.78937810584742,
                40.59513606061433
              ],
              [
                -73.78938382544042,
                40.59511301633009
              ],
              [
                -73.78942487963515,
                40.59504494081973
              ],
              [
                -73.78942455099664,
                40.595021687932665
              ],
              [
                -73.78941219682909,
                40.59500223251821
              ],
              [
                -73.78940128760799,
                40.59497764597045
              ],
              [
                -73.7893951430819,
                40.59496289343582
              ],
              [
                -73.78940242709974,
                40.59494978552371
              ],
              [
                -73.78941370549539,
                40.5949467896526
              ],
              [
                -73.78942472254533,
                40.59495613940298
              ],
              [
                -73.78946035244326,
                40.59499746624783
              ],
              [
                -73.78967389675834,
                40.59486047590017
              ],
              [
                -73.78967436006263,
                40.594862632520375
              ],
              [
                -73.78967500189778,
                40.59486560299156
              ],
              [
                -73.78968593487112,
                40.594951893426824
              ],
              [
                -73.78969826580318,
                40.59495020819611
              ],
              [
                -73.78970595371298,
                40.59499976381352
              ],
              [
                -73.78969586670969,
                40.59500060009031
              ],
              [
                -73.7897141101677,
                40.59515184427499
              ],
              [
                -73.7897170605364,
                40.59517630649689
              ],
              [
                -73.78972565680681,
                40.595247570764336
              ],
              [
                -73.78972709480868,
                40.59525950184503
              ],
              [
                -73.78972957937049,
                40.59528009126765
              ],
              [
                -73.78973729471367,
                40.59534405616043
              ],
              [
                -73.78973884562713,
                40.59535691288157
              ],
              [
                -73.78974875210814,
                40.59543904082831
              ],
              [
                -73.78975038247809,
                40.59545255977183
              ],
              [
                -73.78976048903938,
                40.59553634545403
              ],
              [
                -73.78976192792679,
                40.59554827854597
              ],
              [
                -73.78977203516642,
                40.59563206741061
              ],
              [
                -73.78977347472866,
                40.59564399681793
              ],
              [
                -73.78978367321078,
                40.595728551625896
              ],
              [
                -73.78978501167339,
                40.595739645377286
              ],
              [
                -73.78979511829786,
                40.59582343859301
              ],
              [
                -73.7897966602605,
                40.595836213541794
              ],
              [
                -73.78980278325271,
                40.595886973504456
              ],
              [
                -73.78980819728906,
                40.59593185656867
              ],
              [
                -73.78981719085895,
                40.59600641317359
              ],
              [
                -73.78982485495767,
                40.596069954111954
              ],
              [
                -73.78982984108444,
                40.59611128576755
              ],
              [
                -73.78983128043242,
                40.596123220031444
              ],
              [
                -73.78984110232868,
                40.596206946879775
              ],
              [
                -73.78969763458414,
                40.59621778828497
              ],
              [
                -73.78969872360817,
                40.596228039911345
              ],
              [
                -73.78999909439095,
                40.59620894350328
              ],
              [
                -73.78999689158506,
                40.596196982046905
              ],
              [
                -73.78987360648384,
                40.59620444378454
              ],
              [
                -73.78986386560985,
                40.59612156629507
              ],
              [
                -73.78982861675051,
                40.59582163726854
              ],
              [
                -73.78981867672634,
                40.59573705981898
              ],
              [
                -73.78981216446039,
                40.59568163796591
              ],
              [
                -73.78980753381965,
                40.595642240920654
              ],
              [
                -73.78980603231723,
                40.59562946001463
              ],
              [
                -73.78979619274124,
                40.59554573530402
              ],
              [
                -73.78979476954494,
                40.59553363303614
              ],
              [
                -73.78978611238573,
                40.595459963608455
              ],
              [
                -73.78978343812518,
                40.59543721253982
              ],
              [
                -73.78977369748347,
                40.595354332356266
              ],
              [
                -73.78977218658352,
                40.59534146900757
              ],
              [
                -73.78976484966432,
                40.59527903871141
              ],
              [
                -73.78976253995151,
                40.59525938274981
              ],
              [
                -73.78976094448967,
                40.595245806239845
              ],
              [
                -73.78975402689433,
                40.59518694492446
              ],
              [
                -73.78974959344472,
                40.59514922805599
              ],
              [
                -73.78973975187641,
                40.59506548306161
              ],
              [
                -73.78973825297507,
                40.59505272192721
              ],
              [
                -73.78973173375076,
                40.594997248956325
              ],
              [
                -73.78972052634776,
                40.59499808150992
              ],
              [
                -73.78971619942226,
                40.59494853204395
              ],
              [
                -73.78972631819998,
                40.59493830092534
              ],
              [
                -73.78971317198065,
                40.59484261104306
              ],
              [
                -73.789712057399,
                40.59484090003296
              ],
              [
                -73.78968737611615,
                40.594842563835215
              ],
              [
                -73.78967352950468,
                40.59484352440504
              ],
              [
                -73.78967663059062,
                40.594825893538285
              ],
              [
                -73.78968322877786,
                40.59480883077525
              ],
              [
                -73.7896931691552,
                40.59479273670557
              ],
              [
                -73.7897062183463,
                40.594777989176116
              ],
              [
                -73.78972206998854,
                40.59476493442024
              ],
              [
                -73.78973901889819,
                40.59474366855456
              ],
              [
                -73.78975503447937,
                40.59473725030926
              ],
              [
                -73.78977512688962,
                40.59473333722032
              ],
              [
                -73.78977687995943,
                40.594738524299046
              ],
              [
                -73.78977984173702,
                40.59474728733258
              ],
              [
                -73.78981067236668,
                40.59483851791692
              ],
              [
                -73.78982301134069,
                40.594834270153264
              ],
              [
                -73.78978971292145,
                40.594743855786
              ],
              [
                -73.78978687941984,
                40.59473616232949
              ],
              [
                -73.7897851316379,
                40.59473138972904
              ],
              [
                -73.78984569560428,
                40.594719596848286
              ],
              [
                -73.78984868911779,
                40.59472812489389
              ],
              [
                -73.7898546191521,
                40.59474499492269
              ],
              [
                -73.78988243242814,
                40.59482412801082
              ],
              [
                -73.789895889111,
                40.594820736014384
              ],
              [
                -73.78986855134775,
                40.59474588334543
              ],
              [
                -73.78986144770661,
                40.59472643272148
              ],
              [
                -73.78985807338579,
                40.59471718645011
              ],
              [
                -73.7898665049066,
                40.59471554399451
              ],
              [
                -73.78990069186753,
                40.594802426427655
              ],
              [
                -73.78990643704788,
                40.59480862313335
              ],
              [
                -73.78994705523382,
                40.594807310743484
              ],
              [
                -73.79006564361859,
                40.59477991738313
              ],
              [
                -73.79011344054702,
                40.59477348142072
              ],
              [
                -73.79011998666475,
                40.59482370856218
              ],
              [
                -73.79013119491599,
                40.59482287479843
              ],
              [
                -73.7901388858189,
                40.59487157565577
              ],
              [
                -73.7901299201233,
                40.59487241401848
              ],
              [
                -73.7901343095824,
                40.594901463811155
              ],
              [
                -73.79014793843844,
                40.59500664659013
              ],
              [
                -73.79015546104264,
                40.59506471355597
              ],
              [
                -73.79018161945703,
                40.59526659573723
              ],
              [
                -73.79018263760119,
                40.59527445373374
              ],
              [
                -73.7901874489294,
                40.59531159480489
              ],
              [
                -73.79027434427995,
                40.59598223940169
              ],
              [
                -73.79028151177363,
                40.5960514161062
              ],
              [
                -73.7902505477276,
                40.59605376900566
              ],
              [
                -73.79017616944473,
                40.59606021094182
              ],
              [
                -73.79017830001315,
                40.59607131970442
              ],
              [
                -73.79025001049114,
                40.59606660381418
              ],
              [
                -73.79025042399027,
                40.59606657324087
              ],
              [
                -73.79042481472277,
                40.59605393215388
              ],
              [
                -73.7904238115932,
                40.59604197775188
              ],
              [
                -73.79031625476225,
                40.59604819893948
              ],
              [
                -73.7903077798403,
                40.595978930893175
              ],
              [
                -73.79030621838635,
                40.59596617183126
              ],
              [
                -73.79029775614858,
                40.59589700047143
              ],
              [
                -73.79029628728671,
                40.59588499144112
              ],
              [
                -73.79028761938284,
                40.59581413268065
              ],
              [
                -73.79028604655542,
                40.59580128849248
              ],
              [
                -73.79027768616686,
                40.59573294072423
              ],
              [
                -73.79027612537864,
                40.59572018333824
              ],
              [
                -73.79026766387427,
                40.59565100812256
              ],
              [
                -73.79026609106864,
                40.59563815957809
              ],
              [
                -73.79025761755744,
                40.59556889538093
              ],
              [
                -73.79025595436414,
                40.595555297310966
              ],
              [
                -73.79024831933687,
                40.59549288459689
              ],
              [
                -73.79024612515725,
                40.595474952067036
              ],
              [
                -73.7902374656807,
                40.595404172891776
              ],
              [
                -73.79023589224633,
                40.59539131831443
              ],
              [
                -73.79022732688355,
                40.59532129972691
              ],
              [
                -73.79022458719228,
                40.59529890253938
              ],
              [
                -73.79022055426886,
                40.59526589551325
              ],
              [
                -73.79021697825911,
                40.59524009494064
              ],
              [
                -73.79021521095275,
                40.595227322266
              ],
              [
                -73.79020562561757,
                40.59515808518244
              ],
              [
                -73.7902038447646,
                40.59514521866609
              ],
              [
                -73.7901994322676,
                40.595113343949095
              ],
              [
                -73.79019224695952,
                40.5950614369992
              ],
              [
                -73.7901827807702,
                40.594993045484976
              ],
              [
                -73.7901809999016,
                40.59498018667438
              ],
              [
                -73.79017141401827,
                40.59491093685303
              ],
              [
                -73.79016964649367,
                40.59489816920264
              ],
              [
                -73.79016562708783,
                40.59486912946876
              ],
              [
                -73.79015777357735,
                40.59486915851818
              ],
              [
                -73.79014961159086,
                40.59481957844359
              ],
              [
                -73.79016082074588,
                40.59481782745346
              ],
              [
                -73.7901526364882,
                40.5947682022728
              ],
              [
                -73.79021283170947,
                40.59476009535142
              ],
              [
                -73.79029571148867,
                40.59475382143422
              ],
              [
                -73.79037896334765,
                40.59475196591303
              ],
              [
                -73.79038036102303,
                40.594765085368195
              ],
              [
                -73.79038123820116,
                40.59477122709932
              ],
              [
                -73.79038342313238,
                40.5947891694952
              ],
              [
                -73.7904047210201,
                40.594786644635874
              ],
              [
                -73.79041243624242,
                40.59482765959148
              ],
              [
                -73.790402351078,
                40.594827641197135
              ],
              [
                -73.79040454942975,
                40.594841310437
              ],
              [
                -73.79031712329405,
                40.59484883921797
              ],
              [
                -73.79031932474746,
                40.59486165540377
              ],
              [
                -73.79040674421687,
                40.59485583557948
              ],
              [
                -73.79042537549705,
                40.59498911949891
              ],
              [
                -73.79053857666516,
                40.59498078405602
              ],
              [
                -73.79055065349051,
                40.595060244127374
              ],
              [
                -73.79055239375442,
                40.59507394988801
              ],
              [
                -73.7905577786379,
                40.595116355437405
              ],
              [
                -73.79056783356431,
                40.595195538914766
              ],
              [
                -73.79056812031568,
                40.595197798910334
              ],
              [
                -73.79056985972822,
                40.59521149813542
              ],
              [
                -73.79057536323519,
                40.5952548393854
              ],
              [
                -73.79057680046407,
                40.59526615444707
              ],
              [
                -73.79060063981308,
                40.59545388609338
              ],
              [
                -73.7906029478242,
                40.59547206308198
              ],
              [
                -73.79061775872772,
                40.59558870430201
              ],
              [
                -73.7906204146483,
                40.59560961684607
              ],
              [
                -73.79062786162402,
                40.59566826496697
              ],
              [
                -73.79062909576751,
                40.595677977236136
              ],
              [
                -73.79063349603543,
                40.59571263793084
              ],
              [
                -73.7906377740855,
                40.595746325557734
              ],
              [
                -73.79064137096427,
                40.59577464311962
              ],
              [
                -73.79064645458273,
                40.59581468544173
              ],
              [
                -73.7906568850775,
                40.59589682029009
              ],
              [
                -73.7906638296012,
                40.59595150558215
              ],
              [
                -73.79066544464762,
                40.59596422687495
              ],
              [
                -73.79067270897326,
                40.596021432562566
              ],
              [
                -73.79067422496254,
                40.59603336377227
              ],
              [
                -73.79068189277959,
                40.59608889858273
              ],
              [
                -73.7905933432614,
                40.596097278423244
              ],
              [
                -73.79059442974041,
                40.59610838477282
              ],
              [
                -73.79080626141678,
                40.59609339588424
              ],
              [
                -73.79080405847378,
                40.59608143327046
              ],
              [
                -73.79071439952162,
                40.59608554152329
              ],
              [
                -73.79070673101859,
                40.596030006713725
              ],
              [
                -73.79067071979917,
                40.59574464427232
              ],
              [
                -73.790669593295,
                40.595735717243926
              ],
              [
                -73.79061362295641,
                40.59529217306906
              ],
              [
                -73.79061174677369,
                40.59527730105524
              ],
              [
                -73.79061012490762,
                40.59526445024796
              ],
              [
                -73.79060878545663,
                40.59525384133498
              ],
              [
                -73.79060301364446,
                40.595208097389424
              ],
              [
                -73.7905840345544,
                40.59505769250632
              ],
              [
                -73.79057713478595,
                40.59500301264696
              ],
              [
                -73.79057552409152,
                40.594990247969385
              ],
              [
                -73.79057004196036,
                40.59495180029976
              ],
              [
                -73.79045684084385,
                40.59496013627619
              ],
              [
                -73.79043709310866,
                40.594825995086474
              ],
              [
                -73.79043261017847,
                40.59482598691113
              ],
              [
                -73.7904226974688,
                40.59477130271897
              ],
              [
                -73.79042117097244,
                40.59476274268537
              ],
              [
                -73.79042048230902,
                40.59475269500829
              ],
              [
                -73.79049786957147,
                40.5947570039402
              ],
              [
                -73.79057472642864,
                40.594765134514
              ],
              [
                -73.7906508004326,
                40.59478270208201
              ],
              [
                -73.79066531446506,
                40.594809153987356
              ],
              [
                -73.79067461362513,
                40.59483380817941
              ],
              [
                -73.79068028754959,
                40.59484537253557
              ],
              [
                -73.79068741160565,
                40.59486364892959
              ],
              [
                -73.79070327272055,
                40.59488398392605
              ],
              [
                -73.7907238134791,
                40.594900329849146
              ],
              [
                -73.79074977196845,
                40.59491399242646
              ],
              [
                -73.79077176258701,
                40.594927396981525
              ],
              [
                -73.79079527272945,
                40.594949501938
              ],
              [
                -73.79082506252028,
                40.59498107537173
              ],
              [
                -73.79083826830144,
                40.59499687651807
              ],
              [
                -73.7908553733466,
                40.595019690683856
              ],
              [
                -73.79086693228182,
                40.59504071223796
              ],
              [
                -73.79087767200046,
                40.59506296582114
              ],
              [
                -73.79089223495866,
                40.59510163371589
              ],
              [
                -73.79090027131919,
                40.59512344093681
              ],
              [
                -73.79090664934785,
                40.59514634312919
              ],
              [
                -73.79092260879435,
                40.595199540227924
              ],
              [
                -73.79093122170288,
                40.59524421278011
              ],
              [
                -73.7909435156542,
                40.595287198470544
              ],
              [
                -73.79095521567369,
                40.595331273362156
              ],
              [
                -73.7909762096514,
                40.59537989516071
              ],
              [
                -73.79098572888587,
                40.595406973545835
              ],
              [
                -73.79100581941853,
                40.595453836978855
              ],
              [
                -73.79101345609467,
                40.595478660673734
              ],
              [
                -73.79102536652763,
                40.595527157215926
              ],
              [
                -73.79102320111207,
                40.595573350594144
              ],
              [
                -73.79101069642412,
                40.59563558121417
              ],
              [
                -73.79098882223289,
                40.59569842487068
              ],
              [
                -73.79096604102041,
                40.59576357208132
              ],
              [
                -73.79094780252511,
                40.59581244634659
              ],
              [
                -73.7909327566756,
                40.59588239076158
              ],
              [
                -73.790932475687,
                40.595911837612455
              ],
              [
                -73.79093142251077,
                40.595961183406445
              ],
              [
                -73.79093189738232,
                40.595983175723916
              ],
              [
                -73.79093525469247,
                40.596005082651715
              ],
              [
                -73.79093996379385,
                40.59602946779229
              ],
              [
                -73.79094283512603,
                40.596055463067486
              ],
              [
                -73.79094119971516,
                40.59607218256967
              ],
              [
                -73.79094400255747,
                40.59612054561263
              ],
              [
                -73.79096043520504,
                40.596162027038474
              ],
              [
                -73.79096682814968,
                40.596187784963114
              ],
              [
                -73.79097335401143,
                40.59620906690359
              ],
              [
                -73.79097923693209,
                40.59623943515399
              ],
              [
                -73.79098674490581,
                40.59630153849023
              ],
              [
                -73.79099089428213,
                40.5963457896187
              ],
              [
                -73.79099262550773,
                40.596392754685276
              ],
              [
                -73.79099965333589,
                40.596423457730246
              ],
              [
                -73.79100822160366,
                40.59643780061812
              ],
              [
                -73.79102080130005,
                40.59646464080734
              ],
              [
                -73.7910361609896,
                40.59648305729745
              ],
              [
                -73.79104972413373,
                40.59649798573421
              ],
              [
                -73.791056112662,
                40.59651787189749
              ],
              [
                -73.79107086341467,
                40.596636363468754
              ],
              [
                -73.79107312725631,
                40.59668296947974
              ],
              [
                -73.79107684455165,
                40.596703291722974
              ],
              [
                -73.79108785355278,
                40.596741160877
              ],
              [
                -73.79110296641785,
                40.596789744478336
              ],
              [
                -73.79110071587499,
                40.596806319625486
              ],
              [
                -73.79107940608634,
                40.59682832544479
              ],
              [
                -73.79106696389054,
                40.596847979853464
              ],
              [
                -73.79106730800333,
                40.596874058816844
              ],
              [
                -73.79107264031752,
                40.596895205226495
              ],
              [
                -73.79109463123835,
                40.596923845534285
              ],
              [
                -73.79109553348212,
                40.59694511724296
              ],
              [
                -73.79108998321672,
                40.59697079849716
              ],
              [
                -73.79106882093532,
                40.59701335561357
              ],
              [
                -73.79106821763796,
                40.59704390857651
              ],
              [
                -73.79107551858681,
                40.59708169040689
              ],
              [
                -73.79107981022912,
                40.59714864289385
              ],
              [
                -73.79107079436692,
                40.5971983623729
              ],
              [
                -73.791055729084,
                40.5972482608309
              ],
              [
                -73.79103242414756,
                40.59729583809075
              ],
              [
                -73.79100150128556,
                40.597339205208776
              ],
              [
                -73.79096864748603,
                40.5973802895651
              ],
              [
                -73.79095604358122,
                40.59739110915793
              ],
              [
                -73.79093675560797,
                40.597397305838506
              ],
              [
                -73.79092749195192,
                40.59741268847909
              ],
              [
                -73.7909229886426,
                40.59743639980486
              ],
              [
                -73.79091651274328,
                40.597459631087204
              ],
              [
                -73.7909062908479,
                40.597479215804086
              ],
              [
                -73.79090029665444,
                40.597495865386705
              ],
              [
                -73.79088855613779,
                40.597521175847845
              ],
              [
                -73.79087622688401,
                40.597546323235164
              ],
              [
                -73.79085314627787,
                40.597582481374666
              ],
              [
                -73.79083507961937,
                40.597647809517
              ],
              [
                -73.79081330022179,
                40.59771380604236
              ],
              [
                -73.79080339747308,
                40.597752302580325
              ],
              [
                -73.79079111150178,
                40.59778570807599
              ],
              [
                -73.79075868097767,
                40.59785039572392
              ],
              [
                -73.79073876433837,
                40.59789472047222
              ],
              [
                -73.79071634863192,
                40.59796039081184
              ],
              [
                -73.79069745099893,
                40.59800396804827
              ],
              [
                -73.79067875876665,
                40.598050022581475
              ],
              [
                -73.79066404130111,
                40.59809405054189
              ],
              [
                -73.79066455579289,
                40.59811095337754
              ],
              [
                -73.79043233771182,
                40.59864908902277
              ],
              [
                -73.79041555276622,
                40.598697066208516
              ],
              [
                -73.79040940067183,
                40.59871843311062
              ],
              [
                -73.7904042964466,
                40.598737722545614
              ],
              [
                -73.79039668745611,
                40.598764174664815
              ],
              [
                -73.7903914329734,
                40.59878595767123
              ],
              [
                -73.7903874305069,
                40.598811065911356
              ],
              [
                -73.79038145956571,
                40.598831641062084
              ],
              [
                -73.79037103389119,
                40.59884839008623
              ],
              [
                -73.79035167891189,
                40.59889089541175
              ],
              [
                -73.79033669416047,
                40.598948591917704
              ],
              [
                -73.79032748499574,
                40.59900292172907
              ],
              [
                -73.79032383949522,
                40.59902786861664
              ],
              [
                -73.7903245001797,
                40.59904683534915
              ],
              [
                -73.79032939368571,
                40.599072616868334
              ],
              [
                -73.79033423877563,
                40.59908715069454
              ],
              [
                -73.79033746838687,
                40.5991159748663
              ],
              [
                -73.79033913622045,
                40.59914520730624
              ],
              [
                -73.79034006550985,
                40.59919167636005
              ],
              [
                -73.7903277629682,
                40.599211862846815
              ],
              [
                -73.79030531748317,
                40.599260151845975
              ],
              [
                -73.79030062101957,
                40.599266104985766
              ],
              [
                -73.79028210290386,
                40.599278777478744
              ],
              [
                -73.79024080346245,
                40.59930403392536
              ],
              [
                -73.7902060998847,
                40.59932046906359
              ],
              [
                -73.79017301455475,
                40.59933342068673
              ],
              [
                -73.7901352995466,
                40.599346031132235
              ],
              [
                -73.79005223846882,
                40.59937395772141
              ],
              [
                -73.79003227104491,
                40.599393111258145
              ],
              [
                -73.79001376410697,
                40.5994136634844
              ],
              [
                -73.78999622917712,
                40.59943704571902
              ],
              [
                -73.78998038603889,
                40.59945949287673
              ],
              [
                -73.78994852033259,
                40.59949413287182
              ],
              [
                -73.78992643537947,
                40.59951810955082
              ],
              [
                -73.78990943888154,
                40.59954288710539
              ],
              [
                -73.78988889381985,
                40.59956914533011
              ],
              [
                -73.78987511294126,
                40.599589624922494
              ],
              [
                -73.78983482716296,
                40.599656739683034
              ],
              [
                -73.78980602655167,
                40.599700713125365
              ],
              [
                -73.78980075832337,
                40.59971560726004
              ],
              [
                -73.78978096149109,
                40.59972164031226
              ],
              [
                -73.7897729885848,
                40.59972862394279
              ],
              [
                -73.78976540612769,
                40.59974667361867
              ],
              [
                -73.78975070067237,
                40.599760263188934
              ],
              [
                -73.78970098614832,
                40.59980139808361
              ],
              [
                -73.78967615916372,
                40.599817823659684
              ],
              [
                -73.78965485679561,
                40.599837019752776
              ],
              [
                -73.78962635344537,
                40.59985404136063
              ],
              [
                -73.7896023117366,
                40.5998729079246
              ],
              [
                -73.789577510785,
                40.599892494475945
              ],
              [
                -73.78955983471057,
                40.59991161644167
              ],
              [
                -73.78954721422738,
                40.59992726237672
              ],
              [
                -73.78954114938688,
                40.59994369480517
              ],
              [
                -73.7895346213066,
                40.59997181492665
              ],
              [
                -73.78951637510197,
                40.59998109297374
              ],
              [
                -73.78946269696266,
                40.59999749212843
              ],
              [
                -73.78939493881202,
                40.599994288782526
              ],
              [
                -73.78935965392378,
                40.60000419212756
              ],
              [
                -73.78930418649621,
                40.60000746818144
              ],
              [
                -73.7892835779177,
                40.60001988471625
              ],
              [
                -73.7892707943895,
                40.600042454823715
              ],
              [
                -73.78926404974409,
                40.60006066967892
              ],
              [
                -73.78924924498638,
                40.60008345235839
              ],
              [
                -73.78923630845084,
                40.60009857498711
              ],
              [
                -73.78921850456057,
                40.60011322982361
              ],
              [
                -73.78918828231555,
                40.60012826864018
              ],
              [
                -73.78913278615575,
                40.60015202036408
              ],
              [
                -73.78908852968223,
                40.60018551074723
              ],
              [
                -73.78898515769973,
                40.60023985757638
              ],
              [
                -73.7888676344269,
                40.600323471371816
              ],
              [
                -73.78876061567749,
                40.60038011516662
              ],
              [
                -73.78862536682287,
                40.60047958313283
              ],
              [
                -73.78850007513702,
                40.600538857801006
              ],
              [
                -73.78835483974417,
                40.60064281780449
              ],
              [
                -73.78807155049908,
                40.600854468655804
              ],
              [
                -73.78800227052682,
                40.60093739150589
              ],
              [
                -73.78792994192655,
                40.60099530305106
              ],
              [
                -73.78788971616284,
                40.601041052185266
              ],
              [
                -73.78768902814274,
                40.60133095195749
              ],
              [
                -73.78767448766595,
                40.6013530349823
              ],
              [
                -73.7876635101432,
                40.60137046458449
              ],
              [
                -73.78764964021822,
                40.60139621925107
              ],
              [
                -73.78764094701762,
                40.60141821104985
              ],
              [
                -73.78763043154157,
                40.60144198082306
              ],
              [
                -73.78761975946907,
                40.60146702386576
              ],
              [
                -73.78760873824098,
                40.60148885438454
              ],
              [
                -73.78759671822294,
                40.60151249019822
              ],
              [
                -73.78758714219506,
                40.60153078030237
              ],
              [
                -73.7875745143774,
                40.601557056094535
              ],
              [
                -73.78756566424069,
                40.60157357658297
              ],
              [
                -73.78755333656756,
                40.60160215746475
              ],
              [
                -73.78754626169652,
                40.601617786457766
              ],
              [
                -73.78753506455548,
                40.60163962535568
              ],
              [
                -73.78752185315028,
                40.601660785886764
              ],
              [
                -73.7875120146236,
                40.601676457681535
              ],
              [
                -73.78750016406788,
                40.601698829452225
              ],
              [
                -73.7874882483235,
                40.601728282744894
              ],
              [
                -73.78747574429947,
                40.601751205147124
              ],
              [
                -73.78746708758952,
                40.60176439382176
              ],
              [
                -73.7874551094419,
                40.60178748070311
              ],
              [
                -73.78744253850779,
                40.601813048437876
              ],
              [
                -73.7874305807554,
                40.601840286570294
              ],
              [
                -73.78741930529874,
                40.60186460826789
              ],
              [
                -73.78741155786297,
                40.60187952350493
              ],
              [
                -73.78739495122065,
                40.60190604823696
              ],
              [
                -73.78738061112072,
                40.601929880603265
              ],
              [
                -73.78736942151792,
                40.60194731749087
              ],
              [
                -73.7873523026373,
                40.601974349051126
              ],
              [
                -73.78734339509619,
                40.60199070858736
              ],
              [
                -73.78733315550726,
                40.60201219574944
              ],
              [
                -73.78731850516144,
                40.602040257823674
              ],
              [
                -73.7873046490465,
                40.602064972784376
              ],
              [
                -73.78729172089956,
                40.60208982884531
              ],
              [
                -73.78728010373015,
                40.602113270533565
              ],
              [
                -73.78727070288187,
                40.60213440357697
              ],
              [
                -73.7872642149558,
                40.602148243417204
              ],
              [
                -73.78724998481144,
                40.6021775453092
              ],
              [
                -73.78724040001622,
                40.602198319998735
              ],
              [
                -73.78723151311956,
                40.60221590822633
              ],
              [
                -73.78721960231034,
                40.60224216051118
              ],
              [
                -73.78721056286666,
                40.60225974728204
              ],
              [
                -73.78719464253105,
                40.60228308432941
              ],
              [
                -73.78717833739034,
                40.602306053437026
              ],
              [
                -73.78716533441545,
                40.60232270508559
              ],
              [
                -73.7871624185521,
                40.60232643157485
              ],
              [
                -73.78703494719684,
                40.6024199509996
              ],
              [
                -73.78690284497017,
                40.60250965936484
              ],
              [
                -73.78676630785824,
                40.60259542356703
              ],
              [
                -73.7866255384301,
                40.602677116354606
              ],
              [
                -73.78658983800632,
                40.60264611723691
              ],
              [
                -73.78646693278718,
                40.6027230415177
              ],
              [
                -73.78650683298203,
                40.6027632326146
              ],
              [
                -73.78647254185026,
                40.60280320410171
              ],
              [
                -73.78643305290161,
                40.60284028422953
              ],
              [
                -73.78638878260494,
                40.602874081925734
              ],
              [
                -73.78634019785912,
                40.60290424073625
              ],
              [
                -73.7862878110677,
                40.60293044258461
              ],
              [
                -73.78626804341448,
                40.60295174993144
              ],
              [
                -73.78624389553218,
                40.602975672138975
              ],
              [
                -73.78620120622985,
                40.60300977546981
              ],
              [
                -73.78617884041063,
                40.603032063377015
              ],
              [
                -73.78616855124802,
                40.603047449751955
              ],
              [
                -73.78616632596842,
                40.603067149436335
              ],
              [
                -73.78616881842778,
                40.603074726411
              ],
              [
                -73.78622201957894,
                40.603104804131334
              ],
              [
                -73.78625649061962,
                40.60311372905264
              ],
              [
                -73.78628810615058,
                40.60311409425826
              ],
              [
                -73.78631473411522,
                40.603122509862594
              ],
              [
                -73.78631438792186,
                40.603138375778244
              ],
              [
                -73.78630354951883,
                40.60315059416468
              ],
              [
                -73.78630485944038,
                40.603158727816165
              ],
              [
                -73.78635332811808,
                40.60319465903842
              ],
              [
                -73.78650335648597,
                40.603250206178984
              ],
              [
                -73.78663897364007,
                40.60325551557714
              ],
              [
                -73.78727495168467,
                40.60323794474188
              ],
              [
                -73.78730232089374,
                40.60323059209748
              ],
              [
                -73.7873431252405,
                40.60321786633621
              ],
              [
                -73.78738115026472,
                40.60320511296995
              ],
              [
                -73.78743204173526,
                40.60318782809227
              ],
              [
                -73.78747569794746,
                40.603170893854724
              ],
              [
                -73.78752715891446,
                40.603152767314434
              ],
              [
                -73.78757584187356,
                40.60313461149207
              ],
              [
                -73.78761995942553,
                40.60312319011077
              ],
              [
                -73.78766902829128,
                40.603114785015975
              ],
              [
                -73.78769792465073,
                40.60311549997263
              ],
              [
                -73.78773333842464,
                40.603125178232375
              ],
              [
                -73.78774716058876,
                40.603129553163186
              ],
              [
                -73.78776928578714,
                40.603136132701636
              ],
              [
                -73.7877915552983,
                40.60313423818632
              ],
              [
                -73.7878234143171,
                40.603124388805696
              ],
              [
                -73.7878575563353,
                40.60311117193005
              ],
              [
                -73.78789694353752,
                40.60308402492367
              ],
              [
                -73.7879432123765,
                40.60304465809412
              ],
              [
                -73.7879792848659,
                40.60301620725915
              ],
              [
                -73.78804089550735,
                40.60297690990407
              ],
              [
                -73.7880790318668,
                40.60295780228288
              ],
              [
                -73.78810890201652,
                40.602934372893976
              ],
              [
                -73.78814055596504,
                40.6029041817735
              ],
              [
                -73.78816768514831,
                40.602878606288385
              ],
              [
                -73.78820778807214,
                40.602842144677105
              ],
              [
                -73.78824663875106,
                40.602813721326704
              ],
              [
                -73.78827700707446,
                40.60279368528578
              ],
              [
                -73.78832266934042,
                40.60275727994632
              ],
              [
                -73.78836540496295,
                40.60272931939145
              ],
              [
                -73.78841829858403,
                40.60269256060773
              ],
              [
                -73.78846327872503,
                40.60266335085978
              ],
              [
                -73.78851556653747,
                40.6026295548494
              ],
              [
                -73.78857420265727,
                40.60258226157872
              ],
              [
                -73.78865330410798,
                40.60250890229268
              ],
              [
                -73.78875014623017,
                40.60243826441256
              ],
              [
                -73.78881266616573,
                40.602391432666415
              ],
              [
                -73.78885201574484,
                40.602366403172105
              ],
              [
                -73.78888972550826,
                40.60233966285552
              ],
              [
                -73.7889578756419,
                40.60228865229502
              ],
              [
                -73.78901099030347,
                40.60223918417946
              ],
              [
                -73.78907194280562,
                40.60218640697254
              ],
              [
                -73.78924402338541,
                40.602072876930876
              ],
              [
                -73.7893334495707,
                40.6020131805561
              ],
              [
                -73.789399318344,
                40.60196553557684
              ],
              [
                -73.78948529810152,
                40.601879956985485
              ],
              [
                -73.78960830868348,
                40.60177483276726
              ],
              [
                -73.78969738402583,
                40.601703267502785
              ],
              [
                -73.78974621092634,
                40.60167663888604
              ],
              [
                -73.78979682206725,
                40.601643248164336
              ],
              [
                -73.78984978803163,
                40.601602254207705
              ],
              [
                -73.78990757356117,
                40.60153969759162
              ],
              [
                -73.78993929914898,
                40.60150526928182
              ],
              [
                -73.78995755466647,
                40.601478332728476
              ],
              [
                -73.78997570071967,
                40.60145775187738
              ],
              [
                -73.78999783810993,
                40.601431278092136
              ],
              [
                -73.79008673003658,
                40.60137030374593
              ],
              [
                -73.7902546168672,
                40.60124199646004
              ],
              [
                -73.79027427221618,
                40.601228165570554
              ],
              [
                -73.79029900492381,
                40.601211085324316
              ],
              [
                -73.79032695536986,
                40.60119276150755
              ],
              [
                -73.79035235258114,
                40.601178382704184
              ],
              [
                -73.79037646383705,
                40.60116474940172
              ],
              [
                -73.79040350209186,
                40.6011540577235
              ],
              [
                -73.79043249059016,
                40.601145323325554
              ],
              [
                -73.79045247839791,
                40.60114179541207
              ],
              [
                -73.79047958817972,
                40.60114094285974
              ],
              [
                -73.79050959633192,
                40.601139340863526
              ],
              [
                -73.79053507391464,
                40.601136035872635
              ],
              [
                -73.7905634414245,
                40.601130748403925
              ],
              [
                -73.79058890078466,
                40.601124982358186
              ],
              [
                -73.7906204704403,
                40.60111599244255
              ],
              [
                -73.79064880280328,
                40.60110578672244
              ],
              [
                -73.79067067838216,
                40.601095606594036
              ],
              [
                -73.79068511656347,
                40.60108324391008
              ],
              [
                -73.79069791123399,
                40.60106719693587
              ],
              [
                -73.79070491695126,
                40.60105388204501
              ],
              [
                -73.79071831565926,
                40.60103192823717
              ],
              [
                -73.79073654738588,
                40.601008723908244
              ],
              [
                -73.79076000197975,
                40.60099361640941
              ],
              [
                -73.79078960263361,
                40.60098020644721
              ],
              [
                -73.79082205893032,
                40.60095989409465
              ],
              [
                -73.79084517544963,
                40.60094282017078
              ],
              [
                -73.79086987166565,
                40.60092081939088
              ],
              [
                -73.7908848836045,
                40.600898858614926
              ],
              [
                -73.79090083628613,
                40.60087295690701
              ],
              [
                -73.79098636367527,
                40.600778107898876
              ],
              [
                -73.79107932245792,
                40.60068741211295
              ],
              [
                -73.79116394278276,
                40.60061382242475
              ],
              [
                -73.79125345715042,
                40.60054367745676
              ],
              [
                -73.79134762453629,
                40.60047716606797
              ],
              [
                -73.79138201374109,
                40.60045635313727
              ],
              [
                -73.7914170305427,
                40.600433324081074
              ],
              [
                -73.79143789580179,
                40.60041724442809
              ],
              [
                -73.79147321564986,
                40.60039126202273
              ],
              [
                -73.79150983810042,
                40.60036699462479
              ],
              [
                -73.7915407057602,
                40.600350380921036
              ],
              [
                -73.79157418998489,
                40.60033842978015
              ],
              [
                -73.79158933627791,
                40.600334922541364
              ],
              [
                -73.79166252001741,
                40.60032255702297
              ],
              [
                -73.79169154494598,
                40.6003187438909
              ],
              [
                -73.79171766890654,
                40.60031568189015
              ],
              [
                -73.79174667556799,
                40.600309408203785
              ],
              [
                -73.791766981757,
                40.60030538609151
              ],
              [
                -73.79179857672624,
                40.60030008540291
              ],
              [
                -73.79182306911324,
                40.60029456996683
              ],
              [
                -73.79185432754552,
                40.600287303363515
              ],
              [
                -73.79188654338596,
                40.600278555848426
              ],
              [
                -73.7919135636537,
                40.60026540277282
              ],
              [
                -73.7919309831019,
                40.60025276538813
              ],
              [
                -73.79203025769763,
                40.600222005043854
              ],
              [
                -73.79203992547666,
                40.60020570197198
              ],
              [
                -73.79204593467385,
                40.600181963681315
              ],
              [
                -73.79205236431126,
                40.600169875154705
              ],
              [
                -73.79205879155799,
                40.60015777724169
              ],
              [
                -73.79208109552215,
                40.60013549628637
              ],
              [
                -73.79209106359568,
                40.6001129547833
              ],
              [
                -73.79210604552307,
                40.60009049204169
              ],
              [
                -73.79212903284483,
                40.60007885061074
              ],
              [
                -73.79213992689124,
                40.60006399954949
              ],
              [
                -73.79215987900972,
                40.600054716465436
              ],
              [
                -73.79219277190971,
                40.6000498418657
              ],
              [
                -73.79219580084778,
                40.60003536742371
              ],
              [
                -73.79219461864305,
                40.60002066137989
              ],
              [
                -73.79221194321207,
                40.600012554284184
              ],
              [
                -73.79224190801924,
                40.600010154652246
              ],
              [
                -73.7922606445012,
                40.60001427440202
              ],
              [
                -73.79226718128294,
                40.600020713491226
              ],
              [
                -73.79228420646572,
                40.60001861864866
              ],
              [
                -73.7922867200118,
                40.59999268592288
              ],
              [
                -73.79233256449508,
                40.59995439142583
              ],
              [
                -73.79235534608962,
                40.599953324214106
              ],
              [
                -73.79237472275223,
                40.59996234081977
              ],
              [
                -73.79242878573592,
                40.599995830145794
              ],
              [
                -73.79245722965986,
                40.60001065511517
              ],
              [
                -73.79248706249774,
                40.600018749068
              ],
              [
                -73.7925078288936,
                40.60002021224013
              ],
              [
                -73.79252458170204,
                40.600036663570386
              ],
              [
                -73.79253803879685,
                40.60004800532656
              ],
              [
                -73.79255899397361,
                40.60004438480932
              ],
              [
                -73.79258554038339,
                40.6000513188605
              ],
              [
                -73.7925965027121,
                40.60005223559821
              ],
              [
                -73.79261285952832,
                40.60004319908423
              ],
              [
                -73.79261890089369,
                40.60001993124494
              ],
              [
                -73.79262432704724,
                40.60001202341963
              ],
              [
                -73.79263370220625,
                40.60000871035045
              ],
              [
                -73.79266695282759,
                40.60000738805588
              ],
              [
                -73.79270311156472,
                40.600006755020644
              ],
              [
                -73.79273589624465,
                40.59999792151342
              ],
              [
                -73.79274275792098,
                40.59999232399194
              ],
              [
                -73.79275541652942,
                40.599948437645516
              ],
              [
                -73.7927720156039,
                40.599941565525704
              ],
              [
                -73.79278322675351,
                40.599942400939916
              ],
              [
                -73.79280540330504,
                40.599952223982996
              ],
              [
                -73.7928139093852,
                40.5999559963372
              ],
              [
                -73.79284525604315,
                40.59994925231597
              ],
              [
                -73.79285499477506,
                40.59994783330372
              ],
              [
                -73.79287566137772,
                40.59995800927689
              ],
              [
                -73.79289534205127,
                40.59997816086266
              ],
              [
                -73.79291325690718,
                40.599987832966825
              ],
              [
                -73.79298202592064,
                40.59998632311619
              ],
              [
                -73.79302610417544,
                40.59997987153429
              ],
              [
                -73.79303939811115,
                40.59997793018574
              ],
              [
                -73.79310013027343,
                40.59998661873064
              ],
              [
                -73.79312325093758,
                40.599985147393355
              ],
              [
                -73.79314362456479,
                40.59997575784589
              ],
              [
                -73.79316543249615,
                40.59996113323082
              ],
              [
                -73.79318874258787,
                40.59995796598391
              ],
              [
                -73.79325781619306,
                40.59996575541468
              ],
              [
                -73.79328887679628,
                40.59996740985462
              ],
              [
                -73.79330907387678,
                40.59996846559874
              ],
              [
                -73.793318747224,
                40.59996565861445
              ],
              [
                -73.79333658528581,
                40.59994967201541
              ],
              [
                -73.79334683895578,
                40.59995173967586
              ],
              [
                -73.7933590537064,
                40.599949157894514
              ],
              [
                -73.7933774308123,
                40.59992652384601
              ],
              [
                -73.7933853835357,
                40.599920407900825
              ],
              [
                -73.79341155585969,
                40.59990114197144
              ],
              [
                -73.79343111451105,
                40.59987957988232
              ],
              [
                -73.79345627135446,
                40.599867993182286
              ],
              [
                -73.79346903770168,
                40.59985846693826
              ],
              [
                -73.79348180624321,
                40.599848940696766
              ],
              [
                -73.79351553895094,
                40.59981721206468
              ],
              [
                -73.79355194144343,
                40.59978384190544
              ],
              [
                -73.79358187917697,
                40.59977195031824
              ],
              [
                -73.79367416295854,
                40.599709033607276
              ],
              [
                -73.79377223900744,
                40.59965143240252
              ],
              [
                -73.79387558152638,
                40.5995994555023
              ],
              [
                -73.79398363648939,
                40.59955338155264
              ],
              [
                -73.79409582461015,
                40.59951345755348
              ],
              [
                -73.79421154444945,
                40.59947989753474
              ],
              [
                -73.79433017563667,
                40.59945288140938
              ],
              [
                -73.79445108219672,
                40.599432554008544
              ],
              [
                -73.7945736159583,
                40.59941902430577
              ],
              [
                -73.79469712002859,
                40.599412364832304
              ],
              [
                -73.79482093231367,
                40.59941261128928
              ],
              [
                -73.79494632315618,
                40.599419655226036
              ],
              [
                -73.79507068550593,
                40.59943375695542
              ],
              [
                -73.79519333656766,
                40.59945483905394
              ],
              [
                -73.79531360293953,
                40.59948278577277
              ],
              [
                -73.7954635354574,
                40.59947267626859
              ],
              [
                -73.79546504863077,
                40.59949853112461
              ],
              [
                -73.7955463762112,
                40.59949676214905
              ],
              [
                -73.79554315704533,
                40.59946730371283
              ],
              [
                -73.79561833118453,
                40.59946221670444
              ],
              [
                -73.79561756986661,
                40.59944482979854
              ],
              [
                -73.79567352802896,
                40.599443325709686
              ],
              [
                -73.79660826359404,
                40.59938695425001
              ],
              [
                -73.79676951663444,
                40.59937733852636
              ],
              [
                -73.79677270989202,
                40.59937714163005
              ],
              [
                -73.79768899035615,
                40.599320536589296
              ],
              [
                -73.79769003439199,
                40.5993285032867
              ],
              [
                -73.79782248234716,
                40.59932182828994
              ],
              [
                -73.79811229286895,
                40.59930104024051
              ],
              [
                -73.79810708663994,
                40.599245754034996
              ],
              [
                -73.79817195788713,
                40.59922038321027
              ],
              [
                -73.79823995746294,
                40.59920032882017
              ],
              [
                -73.79831033792352,
                40.599185811299456
              ],
              [
                -73.79838232565622,
                40.59917699022275
              ],
              [
                -73.79843078589377,
                40.59917371408809
              ],
              [
                -73.79847935256511,
                40.59917588966472
              ],
              [
                -73.79852697576115,
                40.59918346992115
              ],
              [
                -73.79857262596838,
                40.599196290988324
              ],
              [
                -73.79861531632372,
                40.59921407570163
              ],
              [
                -73.79865412394807,
                40.59923643959255
              ],
              [
                -73.79868820989631,
                40.59926289919994
              ],
              [
                -73.79878847850674,
                40.599264701343905
              ],
              [
                -73.79914024196671,
                40.5992584942159
              ],
              [
                -73.79914566217283,
                40.5993205269961
              ],
              [
                -73.79912265687946,
                40.59932234552719
              ],
              [
                -73.79912877642295,
                40.599373353570385
              ],
              [
                -73.79913853303405,
                40.599373370623425
              ],
              [
                -73.79914327503613,
                40.599418798862885
              ],
              [
                -73.79918997630604,
                40.599416756033385
              ],
              [
                -73.79918842607492,
                40.599369931317916
              ],
              [
                -73.79919690825373,
                40.59936939496672
              ],
              [
                -73.79919148970744,
                40.599319509092666
              ],
              [
                -73.79916724875041,
                40.599321038997964
              ],
              [
                -73.7991604000919,
                40.599258137594596
              ],
              [
                -73.79917478119656,
                40.59925788429214
              ],
              [
                -73.79918457376515,
                40.59925814415448
              ],
              [
                -73.79997353177183,
                40.59921225227236
              ],
              [
                -73.80045131861326,
                40.59918256888036
              ],
              [
                -73.80046234043184,
                40.59927638565401
              ],
              [
                -73.80046729855626,
                40.59927604898416
              ],
              [
                -73.8004737922779,
                40.59933155912371
              ],
              [
                -73.8005098546783,
                40.59932910744505
              ],
              [
                -73.80049526371978,
                40.599204368068506
              ],
              [
                -73.80056802039566,
                40.599199423063105
              ],
              [
                -73.80056517505572,
                40.59917549389558
              ],
              [
                -73.80077654707628,
                40.59916236047825
              ],
              [
                -73.80077984407792,
                40.599191546161634
              ],
              [
                -73.8008229985188,
                40.59918872956967
              ],
              [
                -73.80085916792893,
                40.59921116465404
              ],
              [
                -73.80087055313173,
                40.59931252034549
              ],
              [
                -73.80088341549074,
                40.599311680189615
              ],
              [
                -73.80088646326415,
                40.59933881621558
              ],
              [
                -73.80087685441282,
                40.59933944355061
              ],
              [
                -73.80088285514184,
                40.59939286070252
              ],
              [
                -73.80091500623944,
                40.59939076113572
              ],
              [
                -73.80090900567352,
                40.59933735454029
              ],
              [
                -73.80089596964322,
                40.599338205790225
              ],
              [
                -73.80089306997968,
                40.599312404561616
              ],
              [
                -73.8009032734675,
                40.59931173989109
              ],
              [
                -73.80089078534671,
                40.599200571257164
              ],
              [
                -73.80091083450364,
                40.59918389943921
              ],
              [
                -73.80095155254564,
                40.59918124108186
              ],
              [
                -73.80094867862815,
                40.59915166461994
              ],
              [
                -73.80102288887112,
                40.59914230174368
              ],
              [
                -73.8012349066254,
                40.59912290165511
              ],
              [
                -73.80124572498376,
                40.59911649962689
              ],
              [
                -73.80125412491208,
                40.59911234534787
              ],
              [
                -73.80136360351148,
                40.59905041583158
              ],
              [
                -73.80147213607032,
                40.59898873821648
              ],
              [
                -73.80149524423824,
                40.5989771698412
              ],
              [
                -73.80154617023564,
                40.598954969993926
              ],
              [
                -73.80162583428712,
                40.598915250237006
              ],
              [
                -73.80165643944441,
                40.59890113759859
              ],
              [
                -73.80167092680178,
                40.598896309269506
              ],
              [
                -73.80168808986745,
                40.598893177437006
              ],
              [
                -73.80174596688464,
                40.598889729355186
              ],
              [
                -73.80176573815994,
                40.598887413356366
              ],
              [
                -73.80188884923456,
                40.598861663089274
              ],
              [
                -73.80193825657727,
                40.59885358956036
              ],
              [
                -73.80196349903323,
                40.59885133790645
              ],
              [
                -73.80198680899797,
                40.59885105306522
              ],
              [
                -73.80200819959828,
                40.59885275600165
              ],
              [
                -73.80202769328517,
                40.59885643636854
              ],
              [
                -73.8020758667759,
                40.59887051342681
              ],
              [
                -73.80209436587793,
                40.598875102257715
              ],
              [
                -73.80215058177286,
                40.598885797812066
              ],
              [
                -73.80216066276245,
                40.59888884158901
              ],
              [
                -73.80218256438734,
                40.59889744306811
              ],
              [
                -73.80219289253607,
                40.598900225418994
              ],
              [
                -73.8022009589097,
                40.59890135588739
              ],
              [
                -73.80227866522567,
                40.59891220530125
              ],
              [
                -73.80233534244981,
                40.598922848954544
              ],
              [
                -73.80235841779329,
                40.59892594957462
              ],
              [
                -73.80242371672547,
                40.59892943908573
              ],
              [
                -73.8025709047583,
                40.59893139380663
              ],
              [
                -73.80262990538368,
                40.5989356557485
              ],
              [
                -73.80265662904353,
                40.59893569343247
              ],
              [
                -73.80267116793497,
                40.59893318769594
              ],
              [
                -73.80270532053348,
                40.59892217815673
              ],
              [
                -73.8028005997594,
                40.59890723973651
              ],
              [
                -73.80283474315364,
                40.598898888837425
              ],
              [
                -73.80286443010492,
                40.598891494138385
              ],
              [
                -73.80289207302586,
                40.59888041418196
              ],
              [
                -73.8029215526787,
                40.59887297197992
              ],
              [
                -73.80295721424766,
                40.59885675528619
              ],
              [
                -73.80297943325748,
                40.598844304553005
              ],
              [
                -73.80299435528552,
                40.598827395239155
              ],
              [
                -73.80300191229124,
                40.59881664910897
              ],
              [
                -73.80300615192698,
                40.598807432700404
              ],
              [
                -73.80301149067553,
                40.59879234542622
              ],
              [
                -73.8030172458891,
                40.598782035971716
              ],
              [
                -73.8030272996374,
                40.59876772423149
              ],
              [
                -73.8030434844952,
                40.59875015717673
              ],
              [
                -73.80305665751189,
                40.598739997639534
              ],
              [
                -73.80307007829715,
                40.598731676492505
              ],
              [
                -73.80308620057197,
                40.598722728553746
              ],
              [
                -73.80310166848568,
                40.59871581011765
              ],
              [
                -73.80311386319177,
                40.59870811811631
              ],
              [
                -73.80312408419121,
                40.59869789755896
              ],
              [
                -73.80313217792676,
                40.59868855221688
              ],
              [
                -73.80316610486285,
                40.59865001788153
              ],
              [
                -73.80319234268016,
                40.59861814122889
              ],
              [
                -73.80320358175042,
                40.598604326050435
              ],
              [
                -73.80324373585117,
                40.59856659946133
              ],
              [
                -73.80326373338173,
                40.598550028165185
              ],
              [
                -73.80328484470323,
                40.59853504963296
              ],
              [
                -73.80329818618017,
                40.59852900588811
              ],
              [
                -73.80331394331519,
                40.5985216230239
              ],
              [
                -73.803326306063,
                40.59851804681927
              ],
              [
                -73.80333789837319,
                40.598518752858745
              ],
              [
                -73.80334646350379,
                40.598520524733594
              ],
              [
                -73.80335830742624,
                40.59852191740269
              ],
              [
                -73.8033723521624,
                40.598521338159856
              ],
              [
                -73.80337927223748,
                40.59851899001059
              ],
              [
                -73.80338610107484,
                40.598511426006255
              ],
              [
                -73.80338940193096,
                40.59850355307227
              ],
              [
                -73.80338906181228,
                40.598496499327396
              ],
              [
                -73.8033935514441,
                40.59848797070592
              ],
              [
                -73.80339972746978,
                40.59848243621005
              ],
              [
                -73.8034162397084,
                40.59846327880168
              ],
              [
                -73.8034235253518,
                40.598459338738834
              ],
              [
                -73.80343092465714,
                40.59845314760734
              ],
              [
                -73.80343320999688,
                40.5984477711363
              ],
              [
                -73.80343045341482,
                40.59844203087838
              ],
              [
                -73.80341482571609,
                40.59842987215662
              ],
              [
                -73.80340383015348,
                40.598421920821075
              ],
              [
                -73.80339685940257,
                40.59841677612443
              ],
              [
                -73.80339402411326,
                40.59841328481573
              ],
              [
                -73.80339557838491,
                40.5984110641896
              ],
              [
                -73.8033996626228,
                40.59840637699786
              ],
              [
                -73.80340842307415,
                40.598402494911255
              ],
              [
                -73.80341407622026,
                40.59840308155364
              ],
              [
                -73.80342415691189,
                40.59840375752378
              ],
              [
                -73.80343103518419,
                40.59840365905473
              ],
              [
                -73.80344033314654,
                40.598401149114736
              ],
              [
                -73.80344679241952,
                40.598396301804506
              ],
              [
                -73.80344747125586,
                40.59838565310981
              ],
              [
                -73.80344577173929,
                40.59837629049268
              ],
              [
                -73.8034480956325,
                40.598369761818645
              ],
              [
                -73.80345463435872,
                40.59836263590645
              ],
              [
                -73.80348529924201,
                40.59833084985262
              ],
              [
                -73.80349417737577,
                40.59832468922324
              ],
              [
                -73.80349615803692,
                40.5983236599576
              ],
              [
                -73.80351090999115,
                40.59831598870457
              ],
              [
                -73.80351417389635,
                40.59830811670884
              ],
              [
                -73.80351665263262,
                40.598298185269755
              ],
              [
                -73.8035191472182,
                40.59829459384731
              ],
              [
                -73.80352283004476,
                40.59829149799846
              ],
              [
                -73.8035270441307,
                40.59829092873385
              ],
              [
                -73.80353204588022,
                40.59829239261442
              ],
              [
                -73.80354200774552,
                40.59829647307497
              ],
              [
                -73.80354913666106,
                40.59829708803306
              ],
              [
                -73.8035585485594,
                40.59829230071363
              ],
              [
                -73.80356578073959,
                40.59828202005808
              ],
              [
                -73.80357063634332,
                40.59827190002146
              ],
              [
                -73.80357553086318,
                40.59826065408488
              ],
              [
                -73.80358067118338,
                40.59825124821056
              ],
              [
                -73.80357782268702,
                40.59824029159776
              ],
              [
                -73.80357453476083,
                40.59823205156374
              ],
              [
                -73.80357503810254,
                40.59821956359605
              ],
              [
                -73.80358075867731,
                40.59820925288091
              ],
              [
                -73.80359355082508,
                40.598194315524864
              ],
              [
                -73.80360361343291,
                40.59818865095617
              ],
              [
                -73.80361532449221,
                40.59818595363964
              ],
              [
                -73.80362454370491,
                40.59818569548285
              ],
              [
                -73.80363138229842,
                40.59818675020758
              ],
              [
                -73.80364023682611,
                40.59818808228747
              ],
              [
                -73.80364456970692,
                40.598184109690415
              ],
              [
                -73.80364610804297,
                40.5981754947644
              ],
              [
                -73.8036472836057,
                40.598167371587984
              ],
              [
                -73.8036495681198,
                40.598161967636116
              ],
              [
                -73.8036522706829,
                40.59816134100226
              ],
              [
                -73.80365698225997,
                40.598163269946276
              ],
              [
                -73.803663626871,
                40.598168853667694
              ],
              [
                -73.80367170913654,
                40.59817562025818
              ],
              [
                -73.80367896997076,
                40.59818032633879
              ],
              [
                -73.80368138795558,
                40.59817901234667
              ],
              [
                -73.80368599280031,
                40.598168205675606
              ],
              [
                -73.80368483048022,
                40.598159118392964
              ],
              [
                -73.8036833262467,
                40.59814410131905
              ],
              [
                -73.80368751590298,
                40.598127393044074
              ],
              [
                -73.80369221364157,
                40.59811982487824
              ],
              [
                -73.80369294305646,
                40.59811864638088
              ],
              [
                -73.80370714924571,
                40.598112385607
              ],
              [
                -73.8037115119717,
                40.59811096738205
              ],
              [
                -73.80376990111107,
                40.598091987991204
              ],
              [
                -73.80378259246703,
                40.59808679563806
              ],
              [
                -73.80379282787143,
                40.598084096286506
              ],
              [
                -73.80380093554906,
                40.5980822157041
              ],
              [
                -73.8038059751034,
                40.59808255367102
              ],
              [
                -73.80381154878695,
                40.5980854172205
              ],
              [
                -73.80381966983131,
                40.59809103059311
              ],
              [
                -73.80384062878422,
                40.59810306165635
              ],
              [
                -73.80384570372395,
                40.598103400184435
              ],
              [
                -73.80385331601502,
                40.59809904785899
              ],
              [
                -73.80385748008983,
                40.59809095775863
              ],
              [
                -73.80385746539623,
                40.598083464972774
              ],
              [
                -73.80385593800585,
                40.598077066421155
              ],
              [
                -73.80385182215109,
                40.598067919316996
              ],
              [
                -73.80384964249717,
                40.59806239901485
              ],
              [
                -73.80384868845704,
                40.59805624854798
              ],
              [
                -73.80385241060452,
                40.598052027794516
              ],
              [
                -73.8038565878277,
                40.59805143165021
              ],
              [
                -73.80386126093074,
                40.598054486319036
              ],
              [
                -73.80386762022485,
                40.59805941098298
              ],
              [
                -73.80387647055754,
                40.59806186783111
              ],
              [
                -73.80388273645939,
                40.59806154966371
              ],
              [
                -73.80388964463049,
                40.598051708200025
              ],
              [
                -73.80388685014027,
                40.59804711848139
              ],
              [
                -73.80388859776922,
                40.598040342881525
              ],
              [
                -73.8038984136408,
                40.59803283788846
              ],
              [
                -73.80390637589971,
                40.598019402367285
              ],
              [
                -73.8039144548259,
                40.59800253620615
              ],
              [
                -73.80391880964353,
                40.59796631117472
              ],
              [
                -73.80392075541613,
                40.597953880448486
              ],
              [
                -73.80393338922408,
                40.5979434985839
              ],
              [
                -73.803955819145,
                40.597933080825435
              ],
              [
                -73.80398037247112,
                40.597921814126465
              ],
              [
                -73.80403554415484,
                40.59789602355816
              ],
              [
                -73.80404748778118,
                40.597887673978256
              ],
              [
                -73.80405722417916,
                40.5978824469034
              ],
              [
                -73.804065864218,
                40.59787806884137
              ],
              [
                -73.80407482429868,
                40.59787352831793
              ],
              [
                -73.80408649461421,
                40.59787195785554
              ],
              [
                -73.80409956610482,
                40.5978726921396
              ],
              [
                -73.80411123700334,
                40.597871147475296
              ],
              [
                -73.80412543979935,
                40.597866012107666
              ],
              [
                -73.80414434347911,
                40.59785415977732
              ],
              [
                -73.80415718796982,
                40.597845588739474
              ],
              [
                -73.8041708939004,
                40.59783795465342
              ],
              [
                -73.8041847304747,
                40.59783441001804
              ],
              [
                -73.80419463977584,
                40.59783212236538
              ],
              [
                -73.80420311288294,
                40.59782867781846
              ],
              [
                -73.80420966950642,
                40.59782791986092
              ],
              [
                -73.80421769770996,
                40.59782831835392
              ],
              [
                -73.80422806444808,
                40.59782968043956
              ],
              [
                -73.80423260470609,
                40.59782864513479
              ],
              [
                -73.80423207114204,
                40.59782614702991
              ],
              [
                -73.80423190049063,
                40.597823182303046
              ],
              [
                -73.80423360865741,
                40.59781755839649
              ],
              [
                -73.80424035951756,
                40.59781224496843
              ],
              [
                -73.80424670540191,
                40.59780967565495
              ],
              [
                -73.80426238305888,
                40.59780456958749
              ],
              [
                -73.80427294307226,
                40.59780140300344
              ],
              [
                -73.80427924903044,
                40.597799932111606
              ],
              [
                -73.8042900591187,
                40.59779745332579
              ],
              [
                -73.80429452398953,
                40.59779754218129
              ],
              [
                -73.80429952498112,
                40.59779903283201
              ],
              [
                -73.80430791850921,
                40.597797840577215
              ],
              [
                -73.80431282695541,
                40.59779408739368
              ],
              [
                -73.80432404105332,
                40.59778889196271
              ],
              [
                -73.80433914461089,
                40.597783565275876
              ],
              [
                -73.8043484398126,
                40.597782181224154
              ],
              [
                -73.80435118081824,
                40.597780428672834
              ],
              [
                -73.80435822020667,
                40.5977746771366
              ],
              [
                -73.80436267120022,
                40.59776727488015
              ],
              [
                -73.80437866087794,
                40.59775423512657
              ],
              [
                -73.8043898349653,
                40.59775016441469
              ],
              [
                -73.80440580135847,
                40.59774574552294
              ],
              [
                -73.80441857054221,
                40.59773832944421
              ],
              [
                -73.80442085683887,
                40.597735967314875
              ],
              [
                -73.80442416889063,
                40.59773254630136
              ],
              [
                -73.80442940256617,
                40.59772835624637
              ],
              [
                -73.8044358272784,
                40.597723480844266
              ],
              [
                -73.80443910360152,
                40.597723103279556
              ],
              [
                -73.80444431269628,
                40.59772753073684
              ],
              [
                -73.8044563632081,
                40.597731887410625
              ],
              [
                -73.80446414395617,
                40.59773047396787
              ],
              [
                -73.80447756524427,
                40.59772215198698
              ],
              [
                -73.80448509590953,
                40.597720051916106
              ],
              [
                -73.80449561721734,
                40.59771801121212
              ],
              [
                -73.80451363490387,
                40.59771386970599
              ],
              [
                -73.80454475876998,
                40.59770933886864
              ],
              [
                -73.80456298434068,
                40.59770813466956
              ],
              [
                -73.80458138264058,
                40.59770656186115
              ],
              [
                -73.80459886525753,
                40.59770778942537
              ],
              [
                -73.80462188261247,
                40.59770832826936
              ],
              [
                -73.8046446977668,
                40.597705946068814
              ],
              [
                -73.80466667714964,
                40.597700708976966
              ],
              [
                -73.80466801290432,
                40.59770028907284
              ],
              [
                -73.80468018577389,
                40.597695856346014
              ],
              [
                -73.80469173344079,
                40.597690539797384
              ],
              [
                -73.80470214568697,
                40.59768311083399
              ],
              [
                -73.80471112228027,
                40.59767466319516
              ],
              [
                -73.80471849344073,
                40.59766535665723
              ],
              [
                -73.80472388986286,
                40.59765586226718
              ],
              [
                -73.80472761310365,
                40.59764591193186
              ],
              [
                -73.80472959936768,
                40.597635676144975
              ],
              [
                -73.8047298146228,
                40.59762533029136
              ],
              [
                -73.80475076026305,
                40.59755127591793
              ],
              [
                -73.80476515501009,
                40.597529461048694
              ],
              [
                -73.80484578876005,
                40.59741506485055
              ],
              [
                -73.80493314568685,
                40.59730357383229
              ],
              [
                -73.80496957533927,
                40.597269743253925
              ],
              [
                -73.8050089049317,
                40.5972378550633
              ],
              [
                -73.80502098047307,
                40.597228880888075
              ],
              [
                -73.80505481227492,
                40.59720551630663
              ],
              [
                -73.8050845721135,
                40.59717903581115
              ],
              [
                -73.80511185479908,
                40.597151047998054
              ],
              [
                -73.80508458771831,
                40.59710107810032
              ],
              [
                -73.80494117146355,
                40.596856537231545
              ],
              [
                -73.80487565214911,
                40.596748535931646
              ],
              [
                -73.80493010902488,
                40.59675715430033
              ],
              [
                -73.80495249836117,
                40.596764879399366
              ],
              [
                -73.80494572745903,
                40.596781096834874
              ],
              [
                -73.80497148353813,
                40.59678712102054
              ],
              [
                -73.8049736528738,
                40.59678016384714
              ],
              [
                -73.80501106896821,
                40.596793717493426
              ],
              [
                -73.80501035319556,
                40.596806042102045
              ],
              [
                -73.8051026419087,
                40.59681118484155
              ],
              [
                -73.80510231687563,
                40.59681915853188
              ],
              [
                -73.80513706065169,
                40.59682203580175
              ],
              [
                -73.80514962023199,
                40.59675741965358
              ],
              [
                -73.80516341154967,
                40.59675799203131
              ],
              [
                -73.80516503589428,
                40.596768479960666
              ],
              [
                -73.80515744715044,
                40.596769287821786
              ],
              [
                -73.80516310470956,
                40.5967920457562
              ],
              [
                -73.80518115407425,
                40.59679148514447
              ],
              [
                -73.80517761958872,
                40.59682868663975
              ],
              [
                -73.80517691041392,
                40.59683770590141
              ],
              [
                -73.8051751750359,
                40.596863839587606
              ],
              [
                -73.80517470455591,
                40.59687255300977
              ],
              [
                -73.80517362267638,
                40.59689827886409
              ],
              [
                -73.80517284271625,
                40.59694639606397
              ],
              [
                -73.80517300904616,
                40.5969673512835
              ],
              [
                -73.80517425241223,
                40.597008983826875
              ],
              [
                -73.80517553345443,
                40.59703318481173
              ],
              [
                -73.80517643161772,
                40.59704677197974
              ],
              [
                -73.805177951163,
                40.59706628957624
              ],
              [
                -73.80517870389916,
                40.5970748821053
              ],
              [
                -73.80518073771371,
                40.59709561214761
              ],
              [
                -73.80518167318017,
                40.59710424887903
              ],
              [
                -73.80518463880799,
                40.59712891761403
              ],
              [
                -73.80518573178917,
                40.59713720162758
              ],
              [
                -73.80518866803234,
                40.597157856303404
              ],
              [
                -73.80519068397012,
                40.59717093004491
              ],
              [
                -73.80519243494223,
                40.59719037599561
              ],
              [
                -73.8052217803191,
                40.59718883923231
              ],
              [
                -73.8052079241033,
                40.59703502156488
              ],
              [
                -73.80525143538655,
                40.59703274470249
              ],
              [
                -73.80524481796691,
                40.59695928365311
              ],
              [
                -73.80521063000919,
                40.59696015013703
              ],
              [
                -73.80520109057889,
                40.59696039196263
              ],
              [
                -73.80520078229813,
                40.5969440949982
              ],
              [
                -73.80521848902949,
                40.596944450521725
              ],
              [
                -73.80524737229516,
                40.596945031380514
              ],
              [
                -73.80524832538248,
                40.596895032613574
              ],
              [
                -73.80524338143302,
                40.59689472284927
              ],
              [
                -73.8052040792488,
                40.59689225938943
              ],
              [
                -73.80520916717902,
                40.596809613766325
              ],
              [
                -73.80520595406547,
                40.5967904506398
              ],
              [
                -73.80522538542246,
                40.596787700908955
              ],
              [
                -73.80522451261182,
                40.59676670110158
              ],
              [
                -73.80521738621708,
                40.59676677965623
              ],
              [
                -73.80521293968133,
                40.5967327793577
              ],
              [
                -73.80515688401542,
                40.59673375752353
              ],
              [
                -73.8051542690558,
                40.59672110829035
              ],
              [
                -73.80514809966373,
                40.59672153809765
              ],
              [
                -73.80513406252756,
                40.59660758897049
              ],
              [
                -73.80509892506618,
                40.596609062110836
              ],
              [
                -73.80510759382422,
                40.59669046906146
              ],
              [
                -73.80509981825776,
                40.596706492645836
              ],
              [
                -73.80509431912361,
                40.596767408140366
              ],
              [
                -73.80501624964808,
                40.59676029725896
              ],
              [
                -73.8050163175305,
                40.5967639200343
              ],
              [
                -73.8050096526091,
                40.59676326859498
              ],
              [
                -73.80495031715071,
                40.59674437643974
              ],
              [
                -73.80486462646938,
                40.5967336283515
              ],
              [
                -73.80486202466062,
                40.59673011000001
              ],
              [
                -73.80477863809365,
                40.59657258452709
              ],
              [
                -73.80477270313175,
                40.59656221943953
              ],
              [
                -73.80475097698772,
                40.59652968178393
              ],
              [
                -73.80473523397653,
                40.59648758265382
              ],
              [
                -73.8047346019017,
                40.59648179175649
              ],
              [
                -73.80473367046876,
                40.5964688676097
              ],
              [
                -73.80473137309313,
                40.59643858741981
              ],
              [
                -73.8047180211975,
                40.596406974175025
              ],
              [
                -73.80470623094205,
                40.59638667501459
              ],
              [
                -73.80469297945253,
                40.59636950734579
              ],
              [
                -73.80467800508394,
                40.596355207199416
              ],
              [
                -73.80459353615714,
                40.59628696666379
              ],
              [
                -73.8045703785302,
                40.59626352188897
              ],
              [
                -73.80449519927369,
                40.59616928994784
              ],
              [
                -73.80439804267058,
                40.5960362518511
              ],
              [
                -73.80432894848398,
                40.59599038707798
              ],
              [
                -73.80429973539145,
                40.59595333523267
              ],
              [
                -73.80429363815999,
                40.59594168958188
              ],
              [
                -73.80428103560175,
                40.59589634532498
              ],
              [
                -73.80427909056901,
                40.595878799985556
              ],
              [
                -73.80368468695728,
                40.59519779018381
              ],
              [
                -73.80366722697441,
                40.59518595437376
              ],
              [
                -73.803646332867,
                40.595176184532335
              ],
              [
                -73.80362294026634,
                40.59516891247242
              ],
              [
                -73.80359840436348,
                40.59516456670431
              ],
              [
                -73.80358534121643,
                40.59516364357046
              ],
              [
                -73.80357135342217,
                40.59516370962485
              ],
              [
                -73.80354001503517,
                40.59516683509507
              ],
              [
                -73.80349792164738,
                40.59517513809813
              ],
              [
                -73.80342683886519,
                40.59519132453131
              ],
              [
                -73.8033288308292,
                40.59518814029877
              ],
              [
                -73.80331453729063,
                40.59517956193947
              ],
              [
                -73.80331443412837,
                40.59517742793796
              ],
              [
                -73.8036515862445,
                40.595149547417314
              ],
              [
                -73.80367723202455,
                40.595134303819414
              ],
              [
                -73.8039084226487,
                40.5950048133322
              ],
              [
                -73.80389507006726,
                40.594990875367266
              ],
              [
                -73.80386069871201,
                40.59500939792123
              ],
              [
                -73.80383512197925,
                40.59502368659145
              ],
              [
                -73.80376521683071,
                40.595062737846085
              ],
              [
                -73.80375440681247,
                40.5950687765873
              ],
              [
                -73.80368318999163,
                40.595108561677286
              ],
              [
                -73.80367181134923,
                40.59511491674046
              ],
              [
                -73.80363981182515,
                40.59513279276296
              ],
              [
                -73.80360538450552,
                40.59513554792576
              ],
              [
                -73.80359362442029,
                40.59513648961429
              ],
              [
                -73.8035262195315,
                40.59514188326687
              ],
              [
                -73.80351421895661,
                40.59514284313191
              ],
              [
                -73.80345112529838,
                40.5951478933049
              ],
              [
                -73.80339410194205,
                40.59515245542811
              ],
              [
                -73.80337768921211,
                40.59515376892409
              ],
              [
                -73.80336665301185,
                40.595154652522524
              ],
              [
                -73.80335292788996,
                40.59515574997719
              ],
              [
                -73.80335137111726,
                40.595163695756774
              ],
              [
                -73.80331384838637,
                40.59516528003147
              ],
              [
                -73.80331306803032,
                40.59514906685277
              ],
              [
                -73.80321494251235,
                40.59514564845065
              ],
              [
                -73.80320801748975,
                40.59502645047776
              ],
              [
                -73.80339061688485,
                40.595020442979084
              ],
              [
                -73.80339097327904,
                40.59500651249416
              ],
              [
                -73.80320704407237,
                40.595009683617015
              ],
              [
                -73.8032037365531,
                40.594952755569096
              ],
              [
                -73.80338472349477,
                40.59494957958509
              ],
              [
                -73.80338350994701,
                40.594934486438724
              ],
              [
                -73.80320303445049,
                40.594937293391105
              ],
              [
                -73.80320010623193,
                40.5948728464242
              ],
              [
                -73.80334489744955,
                40.59487108511581
              ],
              [
                -73.8033610256465,
                40.59488688764137
              ],
              [
                -73.8033846803619,
                40.59488526035868
              ],
              [
                -73.80344626568802,
                40.59485676538735
              ],
              [
                -73.80345448960435,
                40.59485170112748
              ],
              [
                -73.80351665446385,
                40.59482221834984
              ],
              [
                -73.80352315030714,
                40.594817371096774
              ],
              [
                -73.80358444964375,
                40.59478758608685
              ],
              [
                -73.80359397066675,
                40.59478296363171
              ],
              [
                -73.80365167080724,
                40.59475314278157
              ],
              [
                -73.80371008333042,
                40.594823976771146
              ],
              [
                -73.80372454267375,
                40.59481711197995
              ],
              [
                -73.80365519313493,
                40.59473064203005
              ],
              [
                -73.80337754303827,
                40.59486904065903
              ],
              [
                -73.80335031368843,
                40.59484029875073
              ],
              [
                -73.80319675364962,
                40.594678199883234
              ],
              [
                -73.80348190694237,
                40.59451638868294
              ],
              [
                -73.80348397998934,
                40.59451618968468
              ],
              [
                -73.80348620935122,
                40.59451851059976
              ],
              [
                -73.80350896976357,
                40.59450580001115
              ],
              [
                -73.80344017041448,
                40.5944342091768
              ],
              [
                -73.8034177410978,
                40.594446734360346
              ],
              [
                -73.80347149929422,
                40.59450267265476
              ],
              [
                -73.8034328702208,
                40.59452424437818
              ],
              [
                -73.80322769006415,
                40.59463957914481
              ],
              [
                -73.8031857440441,
                40.59466300284165
              ],
              [
                -73.80311851898679,
                40.59458951790863
              ],
              [
                -73.80309543789551,
                40.594601309137836
              ],
              [
                -73.80309776811407,
                40.594563014177844
              ],
              [
                -73.80304215715385,
                40.594536398598
              ],
              [
                -73.80277681567533,
                40.59455581805204
              ],
              [
                -73.8027737033389,
                40.594481825418214
              ],
              [
                -73.80234513845657,
                40.5944959752569
              ],
              [
                -73.80233663086597,
                40.594404863358754
              ],
              [
                -73.80258875504278,
                40.59438474740673
              ],
              [
                -73.80257740915879,
                40.59432212325605
              ],
              [
                -73.80270983859059,
                40.5943064330321
              ],
              [
                -73.80270640618436,
                40.59428597779938
              ],
              [
                -73.80260727634301,
                40.59429859845109
              ],
              [
                -73.80260442850847,
                40.59428775204506
              ],
              [
                -73.80271634477464,
                40.59427361576116
              ],
              [
                -73.8027220186593,
                40.59430321509218
              ],
              [
                -73.80282824885298,
                40.59428830098164
              ],
              [
                -73.80281972468117,
                40.59426086459891
              ],
              [
                -73.8032402429577,
                40.594204054877146
              ],
              [
                -73.80325775448772,
                40.59428585206266
              ],
              [
                -73.80328793893025,
                40.59428211589177
              ],
              [
                -73.80324253170998,
                40.594087239865374
              ],
              [
                -73.80322027459164,
                40.59408901325227
              ],
              [
                -73.80323967749763,
                40.59418846225914
              ],
              [
                -73.80294044341211,
                40.59422475409602
              ],
              [
                -73.80289861369401,
                40.5942298271924
              ],
              [
                -73.80279473322307,
                40.59424242494691
              ],
              [
                -73.80273723095492,
                40.59424940019141
              ],
              [
                -73.8027275047448,
                40.59425057915393
              ],
              [
                -73.80256837601054,
                40.59427226699413
              ],
              [
                -73.80251304282993,
                40.59396685179244
              ],
              [
                -73.80265877687386,
                40.593941972371226
              ],
              [
                -73.80267008927831,
                40.59394037128322
              ],
              [
                -73.80274941413883,
                40.59392796369014
              ],
              [
                -73.80276170058654,
                40.593924718453785
              ],
              [
                -73.8028385380614,
                40.59391304818359
              ],
              [
                -73.80284963278892,
                40.593911776737876
              ],
              [
                -73.80293400176856,
                40.59389877372692
              ],
              [
                -73.802945278346,
                40.593896350147254
              ],
              [
                -73.80304191109988,
                40.593882701821116
              ],
              [
                -73.80305458988154,
                40.593881818891376
              ],
              [
                -73.80314275347314,
                40.59386698493287
              ],
              [
                -73.80316616820349,
                40.593947843192446
              ],
              [
                -73.80318082190897,
                40.59394537813095
              ],
              [
                -73.80315740064128,
                40.59386449523643
              ],
              [
                -73.80316549464914,
                40.593863132844724
              ],
              [
                -73.80313503440718,
                40.59375794307125
              ],
              [
                -73.80311672501837,
                40.593761023733585
              ],
              [
                -73.80314197343122,
                40.59384822446308
              ],
              [
                -73.80307025008247,
                40.59386029236903
              ],
              [
                -73.8030596018963,
                40.59386180299612
              ],
              [
                -73.80298475753881,
                40.59387439573126
              ],
              [
                -73.8029718298389,
                40.59387502743457
              ],
              [
                -73.80289671587268,
                40.593887666893494
              ],
              [
                -73.80288322111704,
                40.593889355396556
              ],
              [
                -73.80280727640996,
                40.593902134432064
              ],
              [
                -73.80279429173713,
                40.593901293096835
              ],
              [
                -73.80271741822474,
                40.593914228124426
              ],
              [
                -73.80270714100797,
                40.593915957814346
              ],
              [
                -73.80263394474771,
                40.59392827320882
              ],
              [
                -73.8026241357365,
                40.5939307135186
              ],
              [
                -73.80250965087272,
                40.59394812804151
              ],
              [
                -73.8024964394105,
                40.593875206143494
              ],
              [
                -73.80237735357416,
                40.59328671578617
              ],
              [
                -73.80249285356626,
                40.5932863110879
              ],
              [
                -73.80249228040066,
                40.59319232243857
              ],
              [
                -73.8024384629031,
                40.592640063835105
              ],
              [
                -73.80276364574276,
                40.59261552449286
              ],
              [
                -73.80294773193391,
                40.59260325096414
              ],
              [
                -73.80295487812258,
                40.592599219554394
              ],
              [
                -73.8029750456643,
                40.592578596217926
              ],
              [
                -73.80298178777937,
                40.59257565439695
              ],
              [
                -73.80302130187022,
                40.59257572213847
              ],
              [
                -73.80303153999354,
                40.59257718949006
              ],
              [
                -73.80304019179809,
                40.592579788800194
              ],
              [
                -73.8030519011422,
                40.59258666150369
              ],
              [
                -73.80305837855087,
                40.59259351652783
              ],
              [
                -73.80307523673538,
                40.5926133837814
              ],
              [
                -73.80328496755736,
                40.59276191647635
              ],
              [
                -73.80361739455432,
                40.59294133774379
              ],
              [
                -73.80363926449385,
                40.592923643609275
              ],
              [
                -73.80367785840274,
                40.59289186768995
              ],
              [
                -73.80367972852247,
                40.59289048306917
              ],
              [
                -73.80368177512447,
                40.59288924483574
              ],
              [
                -73.80368635419893,
                40.59288715451709
              ],
              [
                -73.8036916381217,
                40.59288556933163
              ],
              [
                -73.80369772652469,
                40.592884463147406
              ],
              [
                -73.80370931670696,
                40.592883617822984
              ],
              [
                -73.80374204069531,
                40.592883277342146
              ],
              [
                -73.80377066646705,
                40.59288263299106
              ],
              [
                -73.80378116338817,
                40.59288422686742
              ],
              [
                -73.80378816362871,
                40.5928858055581
              ],
              [
                -73.80379329848462,
                40.59288738273863
              ],
              [
                -73.80379927199998,
                40.59288865225863
              ],
              [
                -73.80380317955768,
                40.59288959542121
              ],
              [
                -73.80380503347197,
                40.59288976109022
              ],
              [
                -73.80380627268089,
                40.59289023094893
              ],
              [
                -73.80380729893427,
                40.592890700946455
              ],
              [
                -73.8038081147172,
                40.59289054971977
              ],
              [
                -73.80380873853996,
                40.59289118153406
              ],
              [
                -73.80380902231202,
                40.59289116392536
              ],
              [
                -73.80380912656838,
                40.592891957356436
              ],
              [
                -73.80380911207457,
                40.59289277638417
              ],
              [
                -73.8038087764684,
                40.59289450387976
              ],
              [
                -73.80380799040505,
                40.592896358096326
              ],
              [
                -73.80380676878127,
                40.5928983553097
              ],
              [
                -73.80380392365605,
                40.59290179955293
              ],
              [
                -73.80379981215997,
                40.59290579062863
              ],
              [
                -73.80377612618894,
                40.592926074381786
              ],
              [
                -73.80377115951049,
                40.59293173175406
              ],
              [
                -73.80376946328023,
                40.592934295244405
              ],
              [
                -73.80376827508375,
                40.59293673278252
              ],
              [
                -73.80375446436813,
                40.59296509324695
              ],
              [
                -73.80375018745143,
                40.592977360171616
              ],
              [
                -73.80374931853149,
                40.59298362681032
              ],
              [
                -73.80374969084525,
                40.59298930083849
              ],
              [
                -73.8037503456632,
                40.592991949093125
              ],
              [
                -73.80375130673907,
                40.59299448143771
              ],
              [
                -73.80375259802916,
                40.59299689741053
              ],
              [
                -73.80375419863284,
                40.59299920468214
              ],
              [
                -73.80375556435362,
                40.593000747449864
              ],
              [
                -73.80375720216702,
                40.59300220876016
              ],
              [
                -73.80376135214232,
                40.59300494423552
              ],
              [
                -73.80376605945018,
                40.59300723101292
              ],
              [
                -73.80377921433735,
                40.593012765035425
              ],
              [
                -73.80378278856868,
                40.59301466171095
              ],
              [
                -73.8037856886146,
                40.59301661168234
              ],
              [
                -73.8038305837224,
                40.593055222670266
              ],
              [
                -73.80383748195098,
                40.593059664098234
              ],
              [
                -73.8038457614602,
                40.59306379426808
              ],
              [
                -73.80388812529321,
                40.59307937362428
              ],
              [
                -73.80389765765678,
                40.59308382054931
              ],
              [
                -73.80390545276721,
                40.59308856153904
              ],
              [
                -73.80392744479627,
                40.59310606972992
              ],
              [
                -73.80394974576262,
                40.59312704463244
              ],
              [
                -73.80400505652692,
                40.59317906245954
              ],
              [
                -73.80403055905919,
                40.5932118948605
              ],
              [
                -73.80405265595694,
                40.593258173745475
              ],
              [
                -73.80405944647693,
                40.59335528065968
              ],
              [
                -73.80412568015268,
                40.59351380510314
              ],
              [
                -73.8041258636516,
                40.59351583101718
              ],
              [
                -73.8041256686377,
                40.59351773063856
              ],
              [
                -73.80412508524886,
                40.593519494569165
              ],
              [
                -73.80412464566962,
                40.59352033213829
              ],
              [
                -73.80412412334559,
                40.59352113270981
              ],
              [
                -73.8041227714809,
                40.59352265157482
              ],
              [
                -73.80412103093943,
                40.59352406272572
              ],
              [
                -73.804118889223,
                40.59352535625706
              ],
              [
                -73.80411633350981,
                40.59352655794668
              ],
              [
                -73.80411074089258,
                40.59352847584182
              ],
              [
                -73.80410356478986,
                40.59353018346806
              ],
              [
                -73.80406973970598,
                40.593535601171745
              ],
              [
                -73.80405576668511,
                40.59353883764103
              ],
              [
                -73.80404935685054,
                40.59354116743554
              ],
              [
                -73.80404429226034,
                40.59354386038237
              ],
              [
                -73.80404223259575,
                40.593545351569794
              ],
              [
                -73.80404047940992,
                40.59354695167134
              ],
              [
                -73.80403903338974,
                40.593548651474435
              ],
              [
                -73.80403789382356,
                40.59355046873564
              ],
              [
                -73.80402929189894,
                40.59357103022671
              ],
              [
                -73.8040270202504,
                40.59358072598499
              ],
              [
                -73.80402608266859,
                40.59359002596773
              ],
              [
                -73.80402978935506,
                40.59364347860633
              ],
              [
                -73.80402972598941,
                40.59366065162846
              ],
              [
                -73.80402950979618,
                40.59366212853634
              ],
              [
                -73.80402859239526,
                40.5936685207412
              ],
              [
                -73.80402625236006,
                40.593677179876735
              ],
              [
                -73.8040128584826,
                40.593712511822794
              ],
              [
                -73.80401102960299,
                40.59371998220158
              ],
              [
                -73.8040101581857,
                40.59372688008922
              ],
              [
                -73.80400883285975,
                40.59376772490987
              ],
              [
                -73.80400956805103,
                40.59381510204299
              ],
              [
                -73.80401191331643,
                40.59388163743027
              ],
              [
                -73.80401462360645,
                40.59392399388458
              ],
              [
                -73.80402722367893,
                40.59407086466389
              ],
              [
                -73.8040350912002,
                40.59416315408303
              ],
              [
                -73.80403735959105,
                40.5941754683651
              ],
              [
                -73.80404215171487,
                40.594188875558544
              ],
              [
                -73.80406481365787,
                40.59423196814821
              ],
              [
                -73.80409224487822,
                40.594288180739625
              ],
              [
                -73.80410298118355,
                40.59430496708521
              ],
              [
                -73.80411459954651,
                40.59431917045339
              ],
              [
                -73.80413054129566,
                40.59433344367825
              ],
              [
                -73.80417646705253,
                40.59436728252363
              ],
              [
                -73.80421505847822,
                40.59440071247975
              ],
              [
                -73.80423662846725,
                40.59442134515493
              ],
              [
                -73.8042607906567,
                40.59444821250132
              ],
              [
                -73.80428600162014,
                40.594479954563504
              ],
              [
                -73.80430854967291,
                40.59451385187752
              ],
              [
                -73.80431816425566,
                40.59452639444167
              ],
              [
                -73.80437012156551,
                40.5945864590204
              ],
              [
                -73.80442801227663,
                40.59465324976916
              ],
              [
                -73.80443848374023,
                40.594667838648135
              ],
              [
                -73.80446136708883,
                40.594708040139665
              ],
              [
                -73.80448329943941,
                40.594738280706395
              ],
              [
                -73.80449229719711,
                40.59474781555164
              ],
              [
                -73.80450170052839,
                40.594755593867816
              ],
              [
                -73.80450888258939,
                40.59476022504577
              ],
              [
                -73.8045174924761,
                40.5947647971688
              ],
              [
                -73.80454797933898,
                40.594778150377095
              ],
              [
                -73.8045662681746,
                40.59478561879296
              ],
              [
                -73.80461501951791,
                40.5948032075355
              ],
              [
                -73.80462863472779,
                40.594809147328355
              ],
              [
                -73.80464022928462,
                40.594815255067296
              ],
              [
                -73.8046422699629,
                40.59481658151806
              ],
              [
                -73.80464408404325,
                40.59481806187834
              ],
              [
                -73.80464474472976,
                40.59482262868673
              ],
              [
                -73.80464939025943,
                40.59482565312272
              ],
              [
                -73.80465086460816,
                40.59483050075372
              ],
              [
                -73.804651670683,
                40.594841713542394
              ],
              [
                -73.80464992395905,
                40.59486108068474
              ],
              [
                -73.80464923932395,
                40.59486492499612
              ],
              [
                -73.80464772722588,
                40.594869299304925
              ],
              [
                -73.80463873183612,
                40.594887186229585
              ],
              [
                -73.80463712460106,
                40.59489110084266
              ],
              [
                -73.80463616986167,
                40.59489449454272
              ],
              [
                -73.80463581660986,
                40.59489827575614
              ],
              [
                -73.80463598876811,
                40.59489997781494
              ],
              [
                -73.80463639744458,
                40.59490159081495
              ],
              [
                -73.804637055003,
                40.59490309551121
              ],
              [
                -73.80463794885962,
                40.5949045111486
              ],
              [
                -73.80463907835683,
                40.59490583722306
              ],
              [
                -73.80464046899166,
                40.59490707260522
              ],
              [
                -73.80467204999985,
                40.594930261630104
              ],
              [
                -73.80470909685009,
                40.59495840172423
              ],
              [
                -73.8047320298805,
                40.594973812712
              ],
              [
                -73.80478846021549,
                40.595007228890964
              ],
              [
                -73.80480294935424,
                40.595017437797395
              ],
              [
                -73.80483664402568,
                40.5950446192475
              ],
              [
                -73.80485298477258,
                40.59505633604333
              ],
              [
                -73.8049061943786,
                40.59508789063374
              ],
              [
                -73.80496564817823,
                40.595117601411715
              ],
              [
                -73.80501528397882,
                40.59513972148563
              ],
              [
                -73.80505811696959,
                40.59515714620452
              ],
              [
                -73.80510965013248,
                40.5951767124514
              ],
              [
                -73.80514501976302,
                40.59518888415321
              ],
              [
                -73.80515328334947,
                40.5951909068453
              ],
              [
                -73.80517162088512,
                40.59519387322306
              ],
              [
                -73.80517819688355,
                40.595195496677064
              ],
              [
                -73.80521822973037,
                40.595208648436525
              ],
              [
                -73.80528024608601,
                40.59523091619977
              ],
              [
                -73.80531166853439,
                40.595243414378174
              ],
              [
                -73.80533768021503,
                40.59525640731151
              ],
              [
                -73.80533967275775,
                40.59525774472517
              ],
              [
                -73.80534135686115,
                40.595259340617254
              ],
              [
                -73.80534275776517,
                40.59526120675734
              ],
              [
                -73.80534389739496,
                40.59526336110848
              ],
              [
                -73.80534545308215,
                40.59526874965365
              ],
              [
                -73.80534694039684,
                40.59528123279512
              ],
              [
                -73.80534811804199,
                40.59528640492176
              ],
              [
                -73.80534919793054,
                40.595288998936866
              ],
              [
                -73.80535057338746,
                40.59529122622767
              ],
              [
                -73.80535228099234,
                40.59529312907355
              ],
              [
                -73.8053532710781,
                40.59529395968715
              ],
              [
                -73.80535434332423,
                40.59529472728129
              ],
              [
                -73.80535555841846,
                40.59529542157186
              ],
              [
                -73.8053568321431,
                40.595295982105604
              ],
              [
                -73.80535817834652,
                40.595296408068165
              ],
              [
                -73.80535958247123,
                40.59529671736072
              ],
              [
                -73.80536107050737,
                40.59529689059375
              ],
              [
                -73.80536263102323,
                40.595296928920774
              ],
              [
                -73.80536600938741,
                40.59529663711017
              ],
              [
                -73.80536955611653,
                40.595295887223614
              ],
              [
                -73.8053736005161,
                40.59529465117119
              ],
              [
                -73.80539048986735,
                40.59528800656691
              ],
              [
                -73.80539779744554,
                40.59528573199521
              ],
              [
                -73.8054016385436,
                40.59528502649887
              ],
              [
                -73.80540512414,
                40.59528481612064
              ],
              [
                -73.80540675419151,
                40.595284900132874
              ],
              [
                -73.80540832497651,
                40.5952851098593
              ],
              [
                -73.80540982355363,
                40.595285436734265
              ],
              [
                -73.80541126393751,
                40.59528589820453
              ],
              [
                -73.80541867347472,
                40.59528913469108
              ],
              [
                -73.80542613930707,
                40.59529341615489
              ],
              [
                -73.8054590623756,
                40.59531830799487
              ],
              [
                -73.80546775369837,
                40.595323565212794
              ],
              [
                -73.80547628368342,
                40.595327397149816
              ],
              [
                -73.80554970079176,
                40.595353321468515
              ],
              [
                -73.8056496334033,
                40.59538546857758
              ],
              [
                -73.80571002345427,
                40.59540594045553
              ],
              [
                -73.80576945390293,
                40.59542780553139
              ],
              [
                -73.80577826735704,
                40.595431737940395
              ],
              [
                -73.80578720765428,
                40.5954368687348
              ],
              [
                -73.80582632109676,
                40.595465858988526
              ],
              [
                -73.80583652226585,
                40.59547204550335
              ],
              [
                -73.80584647879436,
                40.59547665548537
              ],
              [
                -73.80586588105912,
                40.595483469025886
              ],
              [
                -73.80591381395588,
                40.595498174322636
              ],
              [
                -73.8059373439846,
                40.59550736302133
              ],
              [
                -73.80599855087524,
                40.59553549139253
              ],
              [
                -73.80603347280245,
                40.59555149765476
              ],
              [
                -73.80604149807385,
                40.595554555213546
              ],
              [
                -73.80605033944218,
                40.595556757737754
              ],
              [
                -73.80606003467538,
                40.59555811685038
              ],
              [
                -73.80607074853644,
                40.595558656451075
              ],
              [
                -73.80609327973673,
                40.595557623455214
              ],
              [
                -73.80613590532047,
                40.5955527333131
              ],
              [
                -73.80618258993553,
                40.59554603129968
              ],
              [
                -73.80623717982269,
                40.59553405552983
              ],
              [
                -73.8062655672258,
                40.59552618845428
              ],
              [
                -73.80634058103588,
                40.59550280177718
              ],
              [
                -73.80637505690609,
                40.59549348524935
              ],
              [
                -73.8063864892022,
                40.595490388628114
              ],
              [
                -73.80640943777072,
                40.59548418681202
              ],
              [
                -73.80641841167686,
                40.5954817616929
              ],
              [
                -73.80648696866677,
                40.59546471391846
              ],
              [
                -73.80655072109633,
                40.59545054773366
              ],
              [
                -73.80661100431212,
                40.59543896836371
              ],
              [
                -73.80665375101495,
                40.59543319602256
              ],
              [
                -73.80666648810744,
                40.59543214593262
              ],
              [
                -73.80673869879952,
                40.595426171161684
              ],
              [
                -73.80677388004736,
                40.595422249819265
              ],
              [
                -73.80684657127279,
                40.5954134212514
              ],
              [
                -73.80686762450453,
                40.595408710857335
              ],
              [
                -73.80688549372952,
                40.5954025178326
              ],
              [
                -73.80688913728866,
                40.595400731556126
              ],
              [
                -73.80689255705727,
                40.59539858538487
              ],
              [
                -73.80689889446067,
                40.59539305817522
              ],
              [
                -73.80690468005695,
                40.5953859170628
              ],
              [
                -73.80692013891135,
                40.59536310573507
              ],
              [
                -73.80692633587272,
                40.59535689426446
              ],
              [
                -73.80692963899834,
                40.595354422888654
              ],
              [
                -73.80693313000738,
                40.595352339994626
              ],
              [
                -73.80696619886479,
                40.595334988047966
              ],
              [
                -73.80698841354582,
                40.59532481459915
              ],
              [
                -73.80703942357032,
                40.59530525021256
              ],
              [
                -73.80705494713384,
                40.595300637550224
              ],
              [
                -73.80708510301046,
                40.595294169266026
              ],
              [
                -73.80709037585275,
                40.59529303405796
              ],
              [
                -73.80710428091605,
                40.595288672312016
              ],
              [
                -73.80710728510584,
                40.59528735269798
              ],
              [
                -73.80711002967402,
                40.59528582792704
              ],
              [
                -73.80711253952029,
                40.595284075424296
              ],
              [
                -73.80711480139865,
                40.59528211560621
              ],
              [
                -73.80711685230982,
                40.59527992156267
              ],
              [
                -73.80711866673239,
                40.595277502632335
              ],
              [
                -73.80712169472778,
                40.59527189781787
              ],
              [
                -73.80712362480558,
                40.59526641697527
              ],
              [
                -73.80712512047312,
                40.59525991699093
              ],
              [
                -73.80713030001894,
                40.59522526430838
              ],
              [
                -73.80713375349453,
                40.59521593183602
              ],
              [
                -73.8071360094414,
                40.59521199180742
              ],
              [
                -73.80713865341457,
                40.595208465893094
              ],
              [
                -73.80715261007045,
                40.59519475590739
              ],
              [
                -73.80719157996631,
                40.595162871222485
              ],
              [
                -73.80720081171641,
                40.59515309762062
              ],
              [
                -73.80720750224992,
                40.59514353603544
              ],
              [
                -73.80723502281842,
                40.59509181136195
              ],
              [
                -73.80727482562536,
                40.59502556319677
              ],
              [
                -73.80730171210537,
                40.594976863196
              ],
              [
                -73.80731110851838,
                40.59496317301859
              ],
              [
                -73.80732310339948,
                40.59494953226284
              ],
              [
                -73.80733773154965,
                40.5949359140139
              ],
              [
                -73.8073551262955,
                40.594922191674435
              ],
              [
                -73.80736180027915,
                40.594917817774686
              ],
              [
                -73.80736974006633,
                40.594913553550455
              ],
              [
                -73.80740443278832,
                40.59489861796651
              ],
              [
                -73.80741856029395,
                40.59489108572202
              ],
              [
                -73.80742998350354,
                40.59488300933113
              ],
              [
                -73.80743943494979,
                40.594874532757494
              ],
              [
                -73.807446737245,
                40.59486581921311
              ],
              [
                -73.80745181821298,
                40.5948569762989
              ],
              [
                -73.80745202143406,
                40.59485639095589
              ],
              [
                -73.80745383192628,
                40.5948511348995
              ],
              [
                -73.80745484170708,
                40.59484491353607
              ],
              [
                -73.8074548738101,
                40.59483827723872
              ],
              [
                -73.80745390122084,
                40.594831143537846
              ],
              [
                -73.80744929111644,
                40.59481579009958
              ],
              [
                -73.80743911524135,
                40.5947925849379
              ],
              [
                -73.80743722690852,
                40.59477964111513
              ],
              [
                -73.80743725731814,
                40.594769096181366
              ],
              [
                -73.8074392216071,
                40.5947599686056
              ],
              [
                -73.80744093502183,
                40.594755909721016
              ],
              [
                -73.8074431545658,
                40.59475215910117
              ],
              [
                -73.80747149958299,
                40.59471377982106
              ],
              [
                -73.80750437881883,
                40.59467208752452
              ],
              [
                -73.80750844940488,
                40.594665781324274
              ],
              [
                -73.80751187239565,
                40.5946584567991
              ],
              [
                -73.80752076979171,
                40.59462930429163
              ],
              [
                -73.80752600137625,
                40.59461745329688
              ],
              [
                -73.80753338160471,
                40.59460616360671
              ],
              [
                -73.80754226042313,
                40.594595651082976
              ],
              [
                -73.80755241222474,
                40.59458618590765
              ],
              [
                -73.80756359977514,
                40.594577954813985
              ],
              [
                -73.80756932662158,
                40.59457467831049
              ],
              [
                -73.80757608116296,
                40.59457161830038
              ],
              [
                -73.80760947586984,
                40.5945602019536
              ],
              [
                -73.80761706906092,
                40.594556990055665
              ],
              [
                -73.80762339890731,
                40.59455357953011
              ],
              [
                -73.80769192235334,
                40.594506532958306
              ],
              [
                -73.80769570915835,
                40.594504432945385
              ],
              [
                -73.80770022809358,
                40.59450238625926
              ],
              [
                -73.80772188266538,
                40.59449403009765
              ],
              [
                -73.80772715937748,
                40.59449121990565
              ],
              [
                -73.80773136248801,
                40.594488184097465
              ],
              [
                -73.80773356514104,
                40.59448601659886
              ],
              [
                -73.80773542660151,
                40.5944835801469
              ],
              [
                -73.80773694668152,
                40.59448086368422
              ],
              [
                -73.80773816031667,
                40.5944777937239
              ],
              [
                -73.80773961922722,
                40.59447159120238
              ],
              [
                -73.80774194896011,
                40.59445452131798
              ],
              [
                -73.80774339247085,
                40.59444969787448
              ],
              [
                -73.8077453890278,
                40.59444560377509
              ],
              [
                -73.80775866888293,
                40.594424508547014
              ],
              [
                -73.80776973533688,
                40.594409236295284
              ],
              [
                -73.80778114829683,
                40.59439627904391
              ],
              [
                -73.80779319455691,
                40.59438541110755
              ],
              [
                -73.80785053280607,
                40.59434142593155
              ],
              [
                -73.80789218972515,
                40.594307923981425
              ],
              [
                -73.80789362345557,
                40.59430649517259
              ],
              [
                -73.80789489176581,
                40.59430496623956
              ],
              [
                -73.80789694444839,
                40.59430160232551
              ],
              [
                -73.80789837337375,
                40.59429779492294
              ],
              [
                -73.80789918943552,
                40.594293500659845
              ],
              [
                -73.80789919253861,
                40.594284171609885
              ],
              [
                -73.80789688004687,
                40.59426692324889
              ],
              [
                -73.80789459806354,
                40.594255112950734
              ],
              [
                -73.80788671364523,
                40.5942281018668
              ],
              [
                -73.80788489525852,
                40.594215113102294
              ],
              [
                -73.8078853449169,
                40.59420296594525
              ],
              [
                -73.80788627986357,
                40.59419814097961
              ],
              [
                -73.8078877096954,
                40.594194017952724
              ],
              [
                -73.80788965623965,
                40.59419057260935
              ],
              [
                -73.80789081840767,
                40.59418911654196
              ],
              [
                -73.80789210975456,
                40.59418782972779
              ],
              [
                -73.80789353092956,
                40.59418671551857
              ],
              [
                -73.80789508124975,
                40.594185782122224
              ],
              [
                -73.80789674906913,
                40.594185029016664
              ],
              [
                -73.80789855837253,
                40.59418444568758
              ],
              [
                -73.80790290865251,
                40.594183490167076
              ],
              [
                -73.80790787186201,
                40.59418276887227
              ],
              [
                -73.80792795958978,
                40.594181045733926
              ],
              [
                -73.80793410535998,
                40.594180030054716
              ],
              [
                -73.80794340991892,
                40.59417771359338
              ],
              [
                -73.80795183804565,
                40.594175619653754
              ],
              [
                -73.80796899539685,
                40.5941701285602
              ],
              [
                -73.8079703913573,
                40.59416952812132
              ],
              [
                -73.80797168046269,
                40.5941687907997
              ],
              [
                -73.80797286407389,
                40.59416790185512
              ],
              [
                -73.8079739069837,
                40.59416687647357
              ],
              [
                -73.80797484437612,
                40.594165707678016
              ],
              [
                -73.80797565026897,
                40.59416441234537
              ],
              [
                -73.80797687598292,
                40.59416141594815
              ],
              [
                -73.8079775709933,
                40.59415786866439
              ],
              [
                -73.80797768884928,
                40.59415395084574
              ],
              [
                -73.80797721674685,
                40.594149835193925
              ],
              [
                -73.80797618914224,
                40.59414584409367
              ],
              [
                -73.8079755668741,
                40.59414434818692
              ],
              [
                -73.80797473241036,
                40.594142969028724
              ],
              [
                -73.80797368470348,
                40.5941416886918
              ],
              [
                -73.80797242438499,
                40.59414051672625
              ],
              [
                -73.80797095038996,
                40.59413944157088
              ],
              [
                -73.80796926358724,
                40.59413846657759
              ],
              [
                -73.80796515805487,
                40.594136776044095
              ],
              [
                -73.80796095498302,
                40.59413560686697
              ],
              [
                -73.80795585477743,
                40.5941345991969
              ],
              [
                -73.80792712430092,
                40.594130443854525
              ],
              [
                -73.80792307563598,
                40.59412938366104
              ],
              [
                -73.80791955906287,
                40.59412816235547
              ],
              [
                -73.80791650320944,
                40.59412676122308
              ],
              [
                -73.80791388441592,
                40.59412515392201
              ],
              [
                -73.80791018390353,
                40.59412224863598
              ],
              [
                -73.80790676774026,
                40.59411897391959
              ],
              [
                -73.80790059974898,
                40.594111138132085
              ],
              [
                -73.80788434031027,
                40.59407961102293
              ],
              [
                -73.80788026060581,
                40.59407314677242
              ],
              [
                -73.8078758819427,
                40.59406769976455
              ],
              [
                -73.80787295925681,
                40.59406477718191
              ],
              [
                -73.80786953030969,
                40.594061898818154
              ],
              [
                -73.80786098223899,
                40.594056112124704
              ],
              [
                -73.80782594807337,
                40.59403798882023
              ],
              [
                -73.8078137442953,
                40.59403030493492
              ],
              [
                -73.80780844063125,
                40.59402569469581
              ],
              [
                -73.80780456820631,
                40.5940209781233
              ],
              [
                -73.80780315809604,
                40.59401855429147
              ],
              [
                -73.80780206552055,
                40.59401606632432
              ],
              [
                -73.80780131750262,
                40.59401351728245
              ],
              [
                -73.80780090001183,
                40.594010895247976
              ],
              [
                -73.80780083641612,
                40.59400420451048
              ],
              [
                -73.80780083771035,
                40.594003754863095
              ],
              [
                -73.8078019702852,
                40.59399585823754
              ],
              [
                -73.80780972642636,
                40.59396899191706
              ],
              [
                -73.80781177664778,
                40.59395853982135
              ],
              [
                -73.80781234323437,
                40.59394654598187
              ],
              [
                -73.80781142831067,
                40.59390737987551
              ],
              [
                -73.8078135430893,
                40.59389503731563
              ],
              [
                -73.80781540169887,
                40.593889537577915
              ],
              [
                -73.80781782542283,
                40.59388439076517
              ],
              [
                -73.80782939829089,
                40.593865319446685
              ],
              [
                -73.80785236547301,
                40.59383193987794
              ],
              [
                -73.8078578695703,
                40.59382394300738
              ],
              [
                -73.80788884474838,
                40.59377902280419
              ],
              [
                -73.80789358565342,
                40.5937732134307
              ],
              [
                -73.8078992259712,
                40.59376759436647
              ],
              [
                -73.80791316053892,
                40.593756972659314
              ],
              [
                -73.80793051874187,
                40.5937472494384
              ],
              [
                -73.80793600951917,
                40.59374410704812
              ],
              [
                -73.80795000725702,
                40.59373222561915
              ],
              [
                -73.80798065192212,
                40.593723334265924
              ],
              [
                -73.8080017115998,
                40.593700117680825
              ],
              [
                -73.80800324329972,
                40.59367278911918
              ],
              [
                -73.80802985692688,
                40.593669087452064
              ],
              [
                -73.80804688683963,
                40.59365031287618
              ],
              [
                -73.80802332090913,
                40.59362499663611
              ],
              [
                -73.8080228385052,
                40.59362446442616
              ],
              [
                -73.80796983416096,
                40.593566516987536
              ],
              [
                -73.80795507620142,
                40.59354694161515
              ],
              [
                -73.80793174413228,
                40.5935057486273
              ],
              [
                -73.80792061763908,
                40.593489088304686
              ],
              [
                -73.80787257345793,
                40.59342724985865
              ],
              [
                -73.80783763517655,
                40.59338012130346
              ],
              [
                -73.80783240566706,
                40.593374809072124
              ],
              [
                -73.80782611143395,
                40.59336993482546
              ],
              [
                -73.80781870474202,
                40.59336543783739
              ],
              [
                -73.80781002253813,
                40.59336123641584
              ],
              [
                -73.80779266428885,
                40.593354741743674
              ],
              [
                -73.8077494426433,
                40.593341324196615
              ],
              [
                -73.80773892789193,
                40.593337316718625
              ],
              [
                -73.80772997404883,
                40.59333319608832
              ],
              [
                -73.80765636672551,
                40.59329558278543
              ],
              [
                -73.80761324036321,
                40.593269864146905
              ],
              [
                -73.80755723037738,
                40.59322596872831
              ],
              [
                -73.80747119169196,
                40.5931691179978
              ],
              [
                -73.80742595444788,
                40.59313827185723
              ],
              [
                -73.80737427670415,
                40.5931035419522
              ],
              [
                -73.8073424062986,
                40.59308252369511
              ],
              [
                -73.80731885591649,
                40.593068508214806
              ],
              [
                -73.80731386523037,
                40.59306605910692
              ],
              [
                -73.80730837885626,
                40.593063952938444
              ],
              [
                -73.80729558299446,
                40.59306062561803
              ],
              [
                -73.80724914926087,
                40.5930548297854
              ],
              [
                -73.80720102715895,
                40.59304876975508
              ],
              [
                -73.80715353619463,
                40.59304531283429
              ],
              [
                -73.80715215498121,
                40.59304530230741
              ],
              [
                -73.80715078362202,
                40.59304538008511
              ],
              [
                -73.80714943722005,
                40.593045567301594
              ],
              [
                -73.80714808899478,
                40.593045853189935
              ],
              [
                -73.80714541698477,
                40.593046713326146
              ],
              [
                -73.80714273144689,
                40.5930479961168
              ],
              [
                -73.80713982037585,
                40.59304982919891
              ],
              [
                -73.80713670677899,
                40.59305223606514
              ],
              [
                -73.80712480334842,
                40.5930630048285
              ],
              [
                -73.80712097936073,
                40.59306582614309
              ],
              [
                -73.80711802121199,
                40.593067522944104
              ],
              [
                -73.80711509962897,
                40.59306873397032
              ],
              [
                -73.80711364593867,
                40.593069163589114
              ],
              [
                -73.80711216797057,
                40.59306947673416
              ],
              [
                -73.80711067889034,
                40.59306968063115
              ],
              [
                -73.80710917781215,
                40.59306977745651
              ],
              [
                -73.80708731483065,
                40.59306885804147
              ],
              [
                -73.80706410061508,
                40.593065082318134
              ],
              [
                -73.80703934800665,
                40.593058422497
              ],
              [
                -73.80701238174966,
                40.59304869567286
              ],
              [
                -73.80688541131894,
                40.59299251472489
              ],
              [
                -73.80669255942881,
                40.592915177751074
              ],
              [
                -73.80650324063357,
                40.59283772896338
              ],
              [
                -73.80634574672236,
                40.59277129245279
              ],
              [
                -73.80630706180499,
                40.59275497419455
              ],
              [
                -73.80625780301824,
                40.59273418817279
              ],
              [
                -73.8062101515529,
                40.59271267474632
              ],
              [
                -73.80606952150035,
                40.59264551911173
              ],
              [
                -73.80600051162031,
                40.59261592829368
              ],
              [
                -73.80579868944847,
                40.592532991239956
              ],
              [
                -73.80574710995971,
                40.59250950904214
              ],
              [
                -73.80562173943825,
                40.592447925894064
              ],
              [
                -73.80556324806898,
                40.592421019509224
              ],
              [
                -73.80551114077319,
                40.59240073998178
              ],
              [
                -73.8053846249805,
                40.59235915717559
              ],
              [
                -73.80535062910116,
                40.59234635594698
              ],
              [
                -73.80532893956504,
                40.592338196360615
              ],
              [
                -73.80527174695679,
                40.59231175909978
              ],
              [
                -73.80514561892481,
                40.59224690564197
              ],
              [
                -73.80511450986728,
                40.59223248989636
              ],
              [
                -73.80508551958042,
                40.592220409079275
              ],
              [
                -73.80496227091965,
                40.59217290532091
              ],
              [
                -73.80491764890516,
                40.592156114803736
              ],
              [
                -73.80481573709523,
                40.59211776859751
              ],
              [
                -73.80464277339345,
                40.59205596966904
              ],
              [
                -73.80462483579102,
                40.592049563494925
              ],
              [
                -73.80453792098572,
                40.59201560082394
              ],
              [
                -73.80450016953385,
                40.59199848911672
              ],
              [
                -73.80442009939034,
                40.59195838798625
              ],
              [
                -73.8043997140324,
                40.591948852344395
              ],
              [
                -73.80438421369705,
                40.59194161263281
              ],
              [
                -73.8043084328775,
                40.591909146520095
              ],
              [
                -73.80427957013401,
                40.59189833559109
              ],
              [
                -73.80421458092255,
                40.59187607209371
              ],
              [
                -73.8041875828177,
                40.59186574228069
              ],
              [
                -73.80418269897227,
                40.59186350078752
              ],
              [
                -73.80417813448955,
                40.59186094421246
              ],
              [
                -73.80417596602165,
                40.59185946324017
              ],
              [
                -73.80416569103024,
                40.59185470983277
              ],
              [
                -73.80416440502263,
                40.59184599894535
              ],
              [
                -73.8041641010062,
                40.5918448280648
              ],
              [
                -73.80416389309872,
                40.591843332843105
              ],
              [
                -73.80416282661457,
                40.591820241749005
              ],
              [
                -73.804163140454,
                40.591789587701435
              ],
              [
                -73.80416331320505,
                40.59178298782879
              ],
              [
                -73.80416445531056,
                40.59174027741094
              ],
              [
                -73.80416741727521,
                40.59170903381601
              ],
              [
                -73.80417218532634,
                40.59168615956413
              ],
              [
                -73.80419003434085,
                40.591622001650265
              ],
              [
                -73.80420358554483,
                40.59155299999552
              ],
              [
                -73.80421838603534,
                40.5914688705257
              ],
              [
                -73.80422320774693,
                40.59144799515944
              ],
              [
                -73.80423021792889,
                40.59142574558963
              ],
              [
                -73.80425229907365,
                40.59137288637641
              ],
              [
                -73.80426018397824,
                40.5913587800705
              ],
              [
                -73.80427041764679,
                40.591345442872836
              ],
              [
                -73.80427544085887,
                40.59134046189209
              ],
              [
                -73.80428263197656,
                40.591333324475805
              ],
              [
                -73.80429647253338,
                40.591322784966515
              ],
              [
                -73.80430333508215,
                40.59131887880457
              ],
              [
                -73.80431121286246,
                40.59131559674405
              ],
              [
                -73.8043197954532,
                40.59131305368404
              ],
              [
                -73.80432874341584,
                40.59131136715472
              ],
              [
                -73.80433496306853,
                40.59131334085866
              ],
              [
                -73.80433954242869,
                40.59131058408128
              ],
              [
                -73.80434913397227,
                40.59131103297449
              ],
              [
                -73.8043544708548,
                40.59131172608509
              ],
              [
                -73.80435826055427,
                40.59131258777655
              ],
              [
                -73.80435987723179,
                40.5913130775379
              ],
              [
                -73.80436184785303,
                40.59131367428321
              ],
              [
                -73.80448669213679,
                40.591360516649964
              ],
              [
                -73.80455694652761,
                40.591383806693514
              ],
              [
                -73.80463232266452,
                40.591412913991554
              ],
              [
                -73.80465046401255,
                40.591418051306626
              ],
              [
                -73.8046985518073,
                40.591426795673215
              ],
              [
                -73.80470994408527,
                40.59142950656728
              ],
              [
                -73.80471983452652,
                40.59143258548361
              ],
              [
                -73.80476400054192,
                40.591449716730416
              ],
              [
                -73.80480575004528,
                40.59146895958783
              ],
              [
                -73.80488383522498,
                40.591508508756846
              ],
              [
                -73.80503545939035,
                40.59158399515519
              ],
              [
                -73.80509962356163,
                40.591613875872234
              ],
              [
                -73.8051702731144,
                40.59164327132655
              ],
              [
                -73.80522630359638,
                40.59166325024416
              ],
              [
                -73.80523372006142,
                40.591667964033064
              ],
              [
                -73.80525481375378,
                40.5916734295888
              ],
              [
                -73.80527935890792,
                40.591682188089294
              ],
              [
                -73.80533561774497,
                40.591704670066406
              ],
              [
                -73.80535394294343,
                40.59171199568678
              ],
              [
                -73.80540889704737,
                40.59173592572224
              ],
              [
                -73.8055281962188,
                40.59179303249294
              ],
              [
                -73.80563248630229,
                40.59184041411922
              ],
              [
                -73.80565937714404,
                40.5918510496552
              ],
              [
                -73.80568650889998,
                40.591859857508474
              ],
              [
                -73.8057136358894,
                40.59186675601148
              ],
              [
                -73.80574055429526,
                40.591871701597036
              ],
              [
                -73.80575828067307,
                40.59187375666177
              ],
              [
                -73.80577447345892,
                40.59187447794066
              ],
              [
                -73.80578937244613,
                40.59187385478281
              ],
              [
                -73.80580302092355,
                40.5918718884346
              ],
              [
                -73.80580830610067,
                40.59187058259356
              ],
              [
                -73.8058134261234,
                40.59186886150206
              ],
              [
                -73.80581805080675,
                40.59186683483684
              ],
              [
                -73.80582201534749,
                40.59186457100658
              ],
              [
                -73.80582515259431,
                40.59186219051789
              ],
              [
                -73.80582749818397,
                40.59185968136868
              ],
              [
                -73.80582835307048,
                40.59185839551511
              ],
              [
                -73.80582900629432,
                40.59185708184603
              ],
              [
                -73.80582944725386,
                40.59185575843655
              ],
              [
                -73.80582967597115,
                40.59185441774795
              ],
              [
                -73.80582979202732,
                40.59183876598117
              ],
              [
                -73.80582589879394,
                40.59180418864761
              ],
              [
                -73.80582548309857,
                40.591788888297074
              ],
              [
                -73.80582955757521,
                40.59176090750591
              ],
              [
                -73.8058298721609,
                40.59175006434381
              ],
              [
                -73.80582787272633,
                40.59173503154234
              ],
              [
                -73.80581938310792,
                40.59169275599109
              ],
              [
                -73.8058091059093,
                40.59159925488235
              ],
              [
                -73.80579662785688,
                40.59151971733705
              ],
              [
                -73.80575243265774,
                40.59128896414003
              ],
              [
                -73.80573576915708,
                40.591190156946375
              ],
              [
                -73.8057325340218,
                40.59117301452976
              ],
              [
                -73.80572448497985,
                40.591130271468565
              ],
              [
                -73.80571268723433,
                40.59105180658032
              ],
              [
                -73.8057125726178,
                40.59103847872931
              ],
              [
                -73.80571725830877,
                40.591007381916114
              ],
              [
                -73.80571761728746,
                40.59099337084207
              ],
              [
                -73.80570895373269,
                40.59094235060543
              ],
              [
                -73.80570722859026,
                40.59092619229736
              ],
              [
                -73.80570748366095,
                40.590875384657444
              ],
              [
                -73.80570056008588,
                40.59081465951825
              ],
              [
                -73.80570106413037,
                40.59079981037061
              ],
              [
                -73.80570373711976,
                40.59078673098865
              ],
              [
                -73.80570857722209,
                40.59077544649821
              ],
              [
                -73.80571180498656,
                40.59077048989375
              ],
              [
                -73.80571557399898,
                40.5907659942409
              ],
              [
                -73.80571790763142,
                40.59076388195133
              ],
              [
                -73.80572071231869,
                40.59076195926416
              ],
              [
                -73.80572399023131,
                40.59076023556478
              ],
              [
                -73.80572770393,
                40.590758738264455
              ],
              [
                -73.80573192380764,
                40.59075774574914
              ],
              [
                -73.80574117844195,
                40.5907558339659
              ],
              [
                -73.80574626997947,
                40.59075571742871
              ],
              [
                -73.80574947643382,
                40.59075800979721
              ],
              [
                -73.80575824909194,
                40.59075520526966
              ],
              [
                -73.80576112051773,
                40.59075534565509
              ],
              [
                -73.80576583170394,
                40.590755965772274
              ],
              [
                -73.80576802802211,
                40.590756258138484
              ],
              [
                -73.80577408293283,
                40.59075767260708
              ],
              [
                -73.80577902839048,
                40.59075949966858
              ],
              [
                -73.805781115628,
                40.59076057488397
              ],
              [
                -73.80578293207833,
                40.590761740107745
              ],
              [
                -73.80578446296637,
                40.59076301290543
              ],
              [
                -73.80578572285154,
                40.590764374203175
              ],
              [
                -73.80578669939553,
                40.59076583503742
              ],
              [
                -73.8057874034242,
                40.590767375490245
              ],
              [
                -73.80579246867174,
                40.590788691483276
              ],
              [
                -73.80579592013916,
                40.59082461853175
              ],
              [
                -73.8058006675252,
                40.59086977044737
              ],
              [
                -73.80580204455653,
                40.59089591427579
              ],
              [
                -73.80580107848304,
                40.590907250382834
              ],
              [
                -73.80579754704986,
                40.59092330096305
              ],
              [
                -73.80579578692459,
                40.590931330367056
              ],
              [
                -73.80579442179297,
                40.590941900523816
              ],
              [
                -73.8057919964334,
                40.59100796829336
              ],
              [
                -73.80578724799274,
                40.59106888897451
              ],
              [
                -73.80578716401085,
                40.59108567497264
              ],
              [
                -73.80578861982288,
                40.59110082546803
              ],
              [
                -73.80579163929991,
                40.59111467153947
              ],
              [
                -73.80579625655068,
                40.59112734877599
              ],
              [
                -73.8058235929902,
                40.5911878021595
              ],
              [
                -73.8058447658738,
                40.59123507929866
              ],
              [
                -73.80585893055505,
                40.59124698225888
              ],
              [
                -73.80587085612899,
                40.59126121227516
              ],
              [
                -73.80587981268424,
                40.591276663089324
              ],
              [
                -73.80588536526888,
                40.591292592313096
              ],
              [
                -73.80589854177514,
                40.59135580442444
              ],
              [
                -73.80590551083067,
                40.59140890250103
              ],
              [
                -73.80591156706421,
                40.59149139128078
              ],
              [
                -73.80591806873274,
                40.59155075652201
              ],
              [
                -73.80592216636713,
                40.59158813562979
              ],
              [
                -73.80592254487078,
                40.59160406599659
              ],
              [
                -73.80592045908095,
                40.591655185664365
              ],
              [
                -73.80593086680973,
                40.591740050660434
              ],
              [
                -73.80593155065063,
                40.591760943604136
              ],
              [
                -73.80592952782479,
                40.591806732415805
              ],
              [
                -73.80592971712133,
                40.59182689493249
              ],
              [
                -73.80593270594095,
                40.59185554588024
              ],
              [
                -73.80593841309583,
                40.59188341804982
              ],
              [
                -73.80594676932562,
                40.5919102220002
              ],
              [
                -73.80595770522844,
                40.59193571670593
              ],
              [
                -73.80596648473352,
                40.59195095093232
              ],
              [
                -73.8059669094547,
                40.5919515093558
              ],
              [
                -73.80597757081334,
                40.591965683447995
              ],
              [
                -73.80599072502842,
                40.59197961028605
              ],
              [
                -73.80600567884328,
                40.59199242307285
              ],
              [
                -73.80602807459857,
                40.59200930900447
              ],
              [
                -73.80604514697389,
                40.59202060419285
              ],
              [
                -73.80606163594105,
                40.592029456648945
              ],
              [
                -73.80607784880966,
                40.59203591447011
              ],
              [
                -73.80609921205131,
                40.59204185728225
              ],
              [
                -73.80614774525729,
                40.5920526560412
              ],
              [
                -73.80616779799563,
                40.592058542349946
              ],
              [
                -73.80620694259615,
                40.59207223356183
              ],
              [
                -73.80623135421918,
                40.592082343262355
              ],
              [
                -73.80629859387237,
                40.59211511871475
              ],
              [
                -73.80638475066843,
                40.592154373550095
              ],
              [
                -73.80640686405575,
                40.592162658963474
              ],
              [
                -73.80645838139472,
                40.59217875839881
              ],
              [
                -73.80648079157945,
                40.59218678295148
              ],
              [
                -73.80653538283987,
                40.59221045936759
              ],
              [
                -73.80661166636489,
                40.59224861700998
              ],
              [
                -73.80663638290012,
                40.59225995077403
              ],
              [
                -73.80664571748989,
                40.59226335242345
              ],
              [
                -73.80665586847147,
                40.59226600843096
              ],
              [
                -73.80667911877451,
                40.59226920900125
              ],
              [
                -73.80670307474279,
                40.59226990585022
              ],
              [
                -73.80672963104533,
                40.59226936533807
              ],
              [
                -73.80675768885948,
                40.59226879986981
              ],
              [
                -73.80676740146946,
                40.59226847477742
              ],
              [
                -73.80677822541055,
                40.592267322616124
              ],
              [
                -73.80678289293694,
                40.59226643836913
              ],
              [
                -73.80679513037921,
                40.59226704412631
              ],
              [
                -73.80679788045367,
                40.59226387959142
              ],
              [
                -73.80681753486085,
                40.5922604278507
              ],
              [
                -73.80683328821836,
                40.5922584907184
              ],
              [
                -73.80684941288627,
                40.59225840909638
              ],
              [
                -73.80685667553333,
                40.59225930401777
              ],
              [
                -73.80686354627534,
                40.592260828526435
              ],
              [
                -73.80686910550764,
                40.59226263898153
              ],
              [
                -73.806874567465,
                40.59226495336959
              ],
              [
                -73.8068854411234,
                40.59227123021747
              ],
              [
                -73.80689522432588,
                40.59227864007779
              ],
              [
                -73.80691980581196,
                40.59229961002788
              ],
              [
                -73.80692764644432,
                40.59230514296949
              ],
              [
                -73.80693526505878,
                40.59230947887315
              ],
              [
                -73.80704392152812,
                40.592362630072174
              ],
              [
                -73.8071181939608,
                40.592394408993485
              ],
              [
                -73.80714453181291,
                40.59240841931032
              ],
              [
                -73.80719049471517,
                40.592437835073866
              ],
              [
                -73.80720944534748,
                40.59244896152183
              ],
              [
                -73.80722392069639,
                40.59245596576284
              ],
              [
                -73.80724077071318,
                40.59246255851193
              ],
              [
                -73.80731054866757,
                40.59248390992642
              ],
              [
                -73.80733923448065,
                40.592494908099006
              ],
              [
                -73.80735515859567,
                40.59250319283623
              ],
              [
                -73.80737138295157,
                40.59251367455595
              ],
              [
                -73.80743263373071,
                40.59256264947223
              ],
              [
                -73.80744766643767,
                40.59257316587315
              ],
              [
                -73.80746208913058,
                40.592581681951
              ],
              [
                -73.8075126476875,
                40.592606575282836
              ],
              [
                -73.80763927500526,
                40.59266331384483
              ],
              [
                -73.80767265848255,
                40.5926801833356
              ],
              [
                -73.80770160074354,
                40.59269665671573
              ],
              [
                -73.80780755431262,
                40.5927616086479
              ],
              [
                -73.80785481534912,
                40.59279160328718
              ],
              [
                -73.80788476930209,
                40.59280990689864
              ],
              [
                -73.80791189469633,
                40.59282513596061
              ],
              [
                -73.80793775175624,
                40.59283817261359
              ],
              [
                -73.8079628467709,
                40.59284926447566
              ],
              [
                -73.8080010094562,
                40.59286385323473
              ],
              [
                -73.8080442864002,
                40.59287843293372
              ],
              [
                -73.8082083398357,
                40.59292675905855
              ],
              [
                -73.80826417479943,
                40.5929451057644
              ],
              [
                -73.80827521057529,
                40.592948735781924
              ],
              [
                -73.80830432387017,
                40.59295915812369
              ],
              [
                -73.80832111121644,
                40.59296684022191
              ],
              [
                -73.80844038658456,
                40.59303387624538
              ],
              [
                -73.80851768249428,
                40.59307430382785
              ],
              [
                -73.80862030990554,
                40.59313435927286
              ],
              [
                -73.8089361547232,
                40.59330158071348
              ],
              [
                -73.80895786981318,
                40.59331320819312
              ],
              [
                -73.8090184143207,
                40.593345609720906
              ],
              [
                -73.8090436597832,
                40.59335786274377
              ],
              [
                -73.80907143592951,
                40.5933693365973
              ],
              [
                -73.80910194254542,
                40.59338010297906
              ],
              [
                -73.80913573458385,
                40.593390380262385
              ],
              [
                -73.80914442218544,
                40.59339249283304
              ],
              [
                -73.80915365567306,
                40.59339407490559
              ],
              [
                -73.80917407641935,
                40.5933956941516
              ],
              [
                -73.8091972679537,
                40.5933953369568
              ],
              [
                -73.80922880342264,
                40.59339292110055
              ],
              [
                -73.8092408596564,
                40.5933910324498
              ],
              [
                -73.8092538289374,
                40.59338740719343
              ],
              [
                -73.80930616021514,
                40.59336716817911
              ],
              [
                -73.80931797010604,
                40.59336427008053
              ],
              [
                -73.80932913773749,
                40.5933628667872
              ],
              [
                -73.80933333205287,
                40.59336278378329
              ],
              [
                -73.80933747652837,
                40.593363068926635
              ],
              [
                -73.80934158659271,
                40.59336370649518
              ],
              [
                -73.80934565917377,
                40.593364694808294
              ],
              [
                -73.80935376557314,
                40.5933677785733
              ],
              [
                -73.8093619281368,
                40.59337235897333
              ],
              [
                -73.809376154314,
                40.593383575898905
              ],
              [
                -73.80940742101458,
                40.593413722284
              ],
              [
                -73.80942207563055,
                40.593428218302066
              ],
              [
                -73.80943487840625,
                40.59344581841944
              ],
              [
                -73.80944057712485,
                40.59345633666909
              ],
              [
                -73.8094462888366,
                40.593466846060814
              ],
              [
                -73.80946639485778,
                40.593512382795176
              ],
              [
                -73.80947603911868,
                40.59353152681731
              ],
              [
                -73.80948844806676,
                40.59355060237908
              ],
              [
                -73.80950247217092,
                40.59356626770332
              ],
              [
                -73.80950671536868,
                40.59356977711207
              ],
              [
                -73.80951162078615,
                40.593572883034334
              ],
              [
                -73.80951716532438,
                40.59357559364065
              ],
              [
                -73.80952333760342,
                40.593577891991664
              ],
              [
                -73.8095374918281,
                40.593581228351184
              ],
              [
                -73.80955380210722,
                40.593582831502445
              ],
              [
                -73.8095581604756,
                40.59358279366013
              ],
              [
                -73.80957210064267,
                40.593582663640035
              ],
              [
                -73.80958983855328,
                40.593580874331316
              ],
              [
                -73.80959170493088,
                40.593580688618054
              ],
              [
                -73.80961196378863,
                40.59357698426369
              ],
              [
                -73.80961679374357,
                40.59357572691457
              ],
              [
                -73.80970128863605,
                40.59353404259566
              ],
              [
                -73.8095281973081,
                40.5930087772278
              ],
              [
                -73.80950264563126,
                40.592876159615116
              ],
              [
                -73.80950556866756,
                40.59277519735679
              ],
              [
                -73.80961620385169,
                40.592537138132826
              ],
              [
                -73.80963383143487,
                40.59249516631246
              ],
              [
                -73.80966779372882,
                40.59247033269036
              ],
              [
                -73.80970982436389,
                40.59243960833941
              ],
              [
                -73.80974319444822,
                40.59269495851413
              ],
              [
                -73.80978598807614,
                40.59269298499477
              ],
              [
                -73.80978743592274,
                40.5927113397621
              ],
              [
                -73.8098074971652,
                40.59271040950469
              ],
              [
                -73.80980266288222,
                40.59264924614468
              ],
              [
                -73.80976380498812,
                40.59264631840474
              ],
              [
                -73.80973138393709,
                40.5924041860533
              ],
              [
                -73.80977831849519,
                40.5923990277862
              ],
              [
                -73.80990340300123,
                40.59308077605167
              ],
              [
                -73.80998357571092,
                40.593081594351275
              ],
              [
                -73.80999821494291,
                40.59308174269706
              ],
              [
                -73.81006184504064,
                40.593077610897225
              ],
              [
                -73.80991130015991,
                40.59225371768248
              ],
              [
                -73.80995316806148,
                40.59223460344843
              ],
              [
                -73.8099756361539,
                40.5922265253016
              ],
              [
                -73.81002586904063,
                40.59221315913204
              ],
              [
                -73.8100780877514,
                40.59220538577175
              ],
              [
                -73.81013122661898,
                40.59220336385751
              ],
              [
                -73.81014778263884,
                40.592212493302604
              ],
              [
                -73.81016604131385,
                40.59221948613129
              ],
              [
                -73.8101838004068,
                40.592223851456055
              ],
              [
                -73.81020220578183,
                40.59222619110156
              ],
              [
                -73.81059009992056,
                40.59213657587351
              ],
              [
                -73.81079225824638,
                40.59208703249399
              ],
              [
                -73.81079464766287,
                40.59209357946655
              ],
              [
                -73.81080537904415,
                40.59209209573514
              ],
              [
                -73.81081984088483,
                40.59213267512395
              ],
              [
                -73.81081637692152,
                40.592133226122165
              ],
              [
                -73.81083565978538,
                40.592215848478865
              ],
              [
                -73.81083836170998,
                40.5922241823336
              ],
              [
                -73.81085513382493,
                40.5922759171319
              ],
              [
                -73.81085814227838,
                40.592285199540825
              ],
              [
                -73.81087408982033,
                40.592334388538475
              ],
              [
                -73.81087632857833,
                40.59234129377454
              ],
              [
                -73.81089303386865,
                40.59239282206006
              ],
              [
                -73.81089585366696,
                40.59240152115439
              ],
              [
                -73.8109087864633,
                40.59244141348822
              ],
              [
                -73.81091502170726,
                40.5924606489484
              ],
              [
                -73.81093965894601,
                40.59254684780673
              ],
              [
                -73.81096369026605,
                40.59254131647917
              ],
              [
                -73.810953210548,
                40.59251676016602
              ],
              [
                -73.8109756520523,
                40.592513639987615
              ],
              [
                -73.81097892144219,
                40.59252896193116
              ],
              [
                -73.81117764467436,
                40.59250216995335
              ],
              [
                -73.81117549363144,
                40.59249835059593
              ],
              [
                -73.81124288390839,
                40.59249105030505
              ],
              [
                -73.81123958938298,
                40.592471694046274
              ],
              [
                -73.81135829315778,
                40.5924625840449
              ],
              [
                -73.81137347255543,
                40.59268449963313
              ],
              [
                -73.81085983237149,
                40.59277312069632
              ],
              [
                -73.81083602321985,
                40.59277722886853
              ],
              [
                -73.81082523967643,
                40.5927790891237
              ],
              [
                -73.81078919953887,
                40.59278530712192
              ],
              [
                -73.81074974913311,
                40.59279211355255
              ],
              [
                -73.81075729308176,
                40.592811038230685
              ],
              [
                -73.81091016761651,
                40.59278424089125
              ],
              [
                -73.81092243628765,
                40.59278209106758
              ],
              [
                -73.81100438932782,
                40.59276772452018
              ],
              [
                -73.81101743525886,
                40.592765437585335
              ],
              [
                -73.81108958088781,
                40.5927527917948
              ],
              [
                -73.8111116956163,
                40.592748913986036
              ],
              [
                -73.81120557268075,
                40.59273245836711
              ],
              [
                -73.81121682748493,
                40.592730485767945
              ],
              [
                -73.81128202539524,
                40.5927190564346
              ],
              [
                -73.81129377931236,
                40.59271699686202
              ],
              [
                -73.8113733128964,
                40.5927030542842
              ],
              [
                -73.81137383935804,
                40.59286110160759
              ],
              [
                -73.81122673155643,
                40.59293142787692
              ],
              [
                -73.81121929327969,
                40.59292661224749
              ],
              [
                -73.81117286836944,
                40.59294333483655
              ],
              [
                -73.81116539848061,
                40.59293648735209
              ],
              [
                -73.81109997856512,
                40.5929601229667
              ],
              [
                -73.81109624136856,
                40.59295772935823
              ],
              [
                -73.8110282125314,
                40.592987425551215
              ],
              [
                -73.81104740380232,
                40.593012737332295
              ],
              [
                -73.811060600922,
                40.59300666497378
              ],
              [
                -73.81107287021051,
                40.59302233057208
              ],
              [
                -73.8113792293374,
                40.59287722194818
              ],
              [
                -73.81137658047176,
                40.59287216658149
              ],
              [
                -73.81140751227157,
                40.592871257238706
              ],
              [
                -73.81140179703911,
                40.59276603941134
              ],
              [
                -73.81139249922137,
                40.592640889056554
              ],
              [
                -73.811379683966,
                40.59246108207444
              ],
              [
                -73.81138707024905,
                40.592459860175126
              ],
              [
                -73.81138593152033,
                40.592442318454566
              ],
              [
                -73.81125929809448,
                40.59245448962915
              ],
              [
                -73.81111636738369,
                40.59247459321045
              ],
              [
                -73.81110742207213,
                40.59247612547501
              ],
              [
                -73.81110011941591,
                40.59247808093863
              ],
              [
                -73.81109769692013,
                40.59246860684041
              ],
              [
                -73.81095422671093,
                40.59248851717668
              ],
              [
                -73.81095747722595,
                40.59250180963575
              ],
              [
                -73.8109501895272,
                40.592503059144576
              ],
              [
                -73.81089235942197,
                40.59231335225502
              ],
              [
                -73.81088386616315,
                40.59228587045076
              ],
              [
                -73.81086040071834,
                40.59220609190293
              ],
              [
                -73.81083807023013,
                40.592129094702535
              ],
              [
                -73.81083023597257,
                40.59212935018824
              ],
              [
                -73.81081791874632,
                40.59209026869604
              ],
              [
                -73.81082476546278,
                40.59208781007829
              ],
              [
                -73.81082116336776,
                40.59207994851496
              ],
              [
                -73.81090890307112,
                40.5920584457527
              ],
              [
                -73.81087995835121,
                40.59199695510951
              ],
              [
                -73.81089019538994,
                40.59195661942398
              ],
              [
                -73.81093416761482,
                40.59185107823741
              ],
              [
                -73.81094534713289,
                40.591824261227686
              ],
              [
                -73.8110076658511,
                40.59176829725974
              ],
              [
                -73.81117019530946,
                40.59175817259397
              ],
              [
                -73.81132554567534,
                40.59174649614576
              ],
              [
                -73.8113613719154,
                40.59198148297537
              ],
              [
                -73.81139868245074,
                40.59210108919213
              ],
              [
                -73.81142968258294,
                40.59225500399129
              ],
              [
                -73.81143557275092,
                40.59250770046838
              ],
              [
                -73.81146628908357,
                40.59262788118394
              ],
              [
                -73.81147916226398,
                40.5927421153034
              ],
              [
                -73.8114732723109,
                40.59285357282749
              ],
              [
                -73.81148025711856,
                40.59290248300566
              ],
              [
                -73.81149423585859,
                40.59290132669676
              ],
              [
                -73.81152689347525,
                40.59288244221231
              ],
              [
                -73.81156191658248,
                40.592849954002524
              ],
              [
                -73.81157420358254,
                40.592828532702185
              ],
              [
                -73.81156051309381,
                40.59272770565676
              ],
              [
                -73.81152858438593,
                40.592492571675734
              ],
              [
                -73.81151655218235,
                40.59231481588405
              ],
              [
                -73.81153174691062,
                40.59225941328686
              ],
              [
                -73.81150436968763,
                40.592190558257776
              ],
              [
                -73.8115671565879,
                40.592148616318205
              ],
              [
                -73.81153473098952,
                40.592089317619916
              ],
              [
                -73.81151254025703,
                40.59195764194907
              ],
              [
                -73.81151303182712,
                40.591783490316764
              ],
              [
                -73.81157348240845,
                40.59168611701801
              ],
              [
                -73.81170344836342,
                40.59159374663789
              ],
              [
                -73.81183397866033,
                40.59152355729165
              ],
              [
                -73.8119428948392,
                40.59147339695091
              ],
              [
                -73.81200998568714,
                40.59145051630035
              ],
              [
                -73.81204110190583,
                40.59142913463002
              ],
              [
                -73.8121436008147,
                40.59135870088933
              ],
              [
                -73.81219867626373,
                40.5913405549767
              ],
              [
                -73.8122433822537,
                40.59132582310226
              ],
              [
                -73.81228502224809,
                40.59132612550706
              ],
              [
                -73.81233849596734,
                40.59134818671009
              ],
              [
                -73.81238893874787,
                40.591368998361745
              ],
              [
                -73.81244327084264,
                40.59138389198795
              ],
              [
                -73.81249376357003,
                40.59138693715447
              ],
              [
                -73.81254622831922,
                40.59138110560625
              ],
              [
                -73.81258705762329,
                40.591363405517136
              ],
              [
                -73.81262404792933,
                40.59133237049375
              ],
              [
                -73.81274321153676,
                40.59129618400568
              ],
              [
                -73.81281452639965,
                40.591283817899644
              ],
              [
                -73.81290225557298,
                40.59126860685095
              ],
              [
                -73.81298550919138,
                40.59126188974403
              ],
              [
                -73.81305738293007,
                40.59125608117274
              ],
              [
                -73.81312991669729,
                40.59124662634172
              ],
              [
                -73.81315702925521,
                40.591185405844314
              ],
              [
                -73.81319209060828,
                40.59112657773381
              ],
              [
                -73.81323474432585,
                40.591070740017585
              ],
              [
                -73.81355405036116,
                40.59170889619408
              ],
              [
                -73.81358856612626,
                40.59169768582362
              ],
              [
                -73.81325671566422,
                40.591046302490895
              ],
              [
                -73.81329441415484,
                40.59100931490095
              ],
              [
                -73.81335361365772,
                40.590960758282755
              ],
              [
                -73.81337671660636,
                40.59094083179959
              ],
              [
                -73.81340983344633,
                40.59091782311177
              ],
              [
                -73.81345814115188,
                40.59089083997578
              ],
              [
                -73.81352240790814,
                40.590854950540596
              ],
              [
                -73.81357398073837,
                40.590826154455165
              ],
              [
                -73.81363221410687,
                40.59079363240034
              ],
              [
                -73.81370144041946,
                40.59076327017362
              ],
              [
                -73.8137648345345,
                40.590735474723935
              ],
              [
                -73.81381576194369,
                40.59071314429661
              ],
              [
                -73.81390504662012,
                40.59067398931837
              ],
              [
                -73.81402182783656,
                40.590636014628444
              ],
              [
                -73.81405803164166,
                40.59062423925842
              ],
              [
                -73.81409310024429,
                40.59062095612935
              ],
              [
                -73.81414340154399,
                40.59061624615179
              ],
              [
                -73.81418215917384,
                40.590616308833766
              ],
              [
                -73.81421986803537,
                40.590619557058844
              ],
              [
                -73.81426069690679,
                40.590626810085645
              ],
              [
                -73.81429696620411,
                40.59063785446438
              ],
              [
                -73.8143261088946,
                40.590650877903805
              ],
              [
                -73.81435121456343,
                40.59066209537734
              ],
              [
                -73.81438262208174,
                40.59067173534666
              ],
              [
                -73.81441717033375,
                40.59067737575093
              ],
              [
                -73.81444754631242,
                40.59067981880504
              ],
              [
                -73.81448107260579,
                40.590679081360825
              ],
              [
                -73.81451879513503,
                40.59067354995022
              ],
              [
                -73.81455024927243,
                40.59066641369609
              ],
              [
                -73.81459418101943,
                40.590653715813716
              ],
              [
                -73.81470067461719,
                40.59061058988817
              ],
              [
                -73.8147478467245,
                40.5905881261723
              ],
              [
                -73.8148439398326,
                40.59051295150663
              ],
              [
                -73.81484403460317,
                40.590512852816836
              ],
              [
                -73.81488172581524,
                40.590471246906716
              ],
              [
                -73.81492392915133,
                40.59042581148732
              ],
              [
                -73.81496908451777,
                40.59037218592478
              ],
              [
                -73.81500343530371,
                40.59033420261777
              ],
              [
                -73.81504418972436,
                40.59027832738786
              ],
              [
                -73.81508349102971,
                40.590218722437186
              ],
              [
                -73.81513268300009,
                40.590118852217245
              ],
              [
                -73.81516608506067,
                40.59006968271891
              ],
              [
                -73.81518965919054,
                40.59003689604614
              ],
              [
                -73.81521320297809,
                40.59001530432236
              ],
              [
                -73.81524832374934,
                40.58998905679544
              ],
              [
                -73.81526955469286,
                40.58997885168365
              ],
              [
                -73.81582455452242,
                40.58971197137978
              ],
              [
                -73.81584523000214,
                40.58970677239824
              ],
              [
                -73.81589307115702,
                40.589702390929276
              ],
              [
                -73.81594103102283,
                40.589697992040016
              ],
              [
                -73.81596626679338,
                40.589692350189154
              ],
              [
                -73.81600226087951,
                40.58966601319696
              ],
              [
                -73.81605618762214,
                40.58962656722496
              ],
              [
                -73.81615706220046,
                40.58959018541568
              ],
              [
                -73.81624423864828,
                40.58955153855809
              ],
              [
                -73.8162594080273,
                40.5895508168062
              ],
              [
                -73.81627601811658,
                40.589559046456046
              ],
              [
                -73.81629654402977,
                40.589565049193325
              ],
              [
                -73.81631809499207,
                40.58955463750033
              ],
              [
                -73.81636027213152,
                40.58951816166719
              ],
              [
                -73.8163966008141,
                40.589473464001316
              ],
              [
                -73.81641916119337,
                40.589452616468506
              ],
              [
                -73.81644364346825,
                40.58944147163449
              ],
              [
                -73.81647294031325,
                40.58943218937385
              ],
              [
                -73.81650433814302,
                40.589428140904836
              ],
              [
                -73.81653859470231,
                40.589423720552396
              ],
              [
                -73.81657902384649,
                40.58943447499689
              ],
              [
                -73.81659271775965,
                40.58942478851083
              ],
              [
                -73.8166264962131,
                40.5894008878561
              ],
              [
                -73.81669191177083,
                40.58935459790421
              ],
              [
                -73.81674197582348,
                40.58931916984591
              ],
              [
                -73.81678888161393,
                40.589311491706496
              ],
              [
                -73.81682951096518,
                40.58930484708518
              ],
              [
                -73.81691558269158,
                40.58931169365621
              ],
              [
                -73.81700298780069,
                40.589340153781876
              ],
              [
                -73.81708178953154,
                40.58935746176736
              ],
              [
                -73.81712969614928,
                40.58936797428139
              ],
              [
                -73.81716590847275,
                40.589365798736665
              ],
              [
                -73.81720706565159,
                40.58933901122138
              ],
              [
                -73.81721886184167,
                40.58931889409227
              ],
              [
                -73.8173679813751,
                40.5892552030516
              ],
              [
                -73.8174204510319,
                40.589359333252396
              ],
              [
                -73.81751021655829,
                40.5893330450298
              ],
              [
                -73.81751220913847,
                40.58933013100016
              ],
              [
                -73.81750208580694,
                40.5893128605387
              ],
              [
                -73.81753265580444,
                40.58930032794415
              ],
              [
                -73.81760761896984,
                40.58926958598405
              ],
              [
                -73.81762330439473,
                40.589291826823356
              ],
              [
                -73.8176380045051,
                40.5892857994852
              ],
              [
                -73.81758962988171,
                40.58921724695752
              ],
              [
                -73.81746593567614,
                40.58904194281439
              ],
              [
                -73.8174387363485,
                40.58897998214497
              ],
              [
                -73.81741586147443,
                40.58891701410967
              ],
              [
                -73.81761886059695,
                40.58881278574863
              ],
              [
                -73.81791292627506,
                40.5886617997679
              ],
              [
                -73.81800184906037,
                40.58861614188699
              ],
              [
                -73.81801257327248,
                40.58861146033524
              ],
              [
                -73.81809706353732,
                40.588556896287066
              ],
              [
                -73.8181858720219,
                40.58850647500418
              ],
              [
                -73.8182786497713,
                40.58846039460181
              ],
              [
                -73.81837503223598,
                40.588418836138466
              ],
              [
                -73.81847464070607,
                40.58838196290506
              ],
              [
                -73.81855048071942,
                40.58836178455365
              ],
              [
                -73.8186248236585,
                40.588338593293805
              ],
              [
                -73.81869746373584,
                40.5883124533212
              ],
              [
                -73.81876839992097,
                40.58828335015363
              ],
              [
                -73.81883722364618,
                40.58825143495898
              ],
              [
                -73.81912916381579,
                40.588038892972584
              ],
              [
                -73.81912652299997,
                40.587996764999964
              ],
              [
                -73.81912265299988,
                40.58793502599986
              ],
              [
                -73.81909407399996,
                40.587831960999885
              ],
              [
                -73.81904410199994,
                40.587733741999934
              ],
              [
                -73.81897507799992,
                40.587644058999906
              ],
              [
                -73.81893219199988,
                40.58759907299991
              ],
              [
                -73.81890104000003,
                40.58757409799996
              ],
              [
                -73.81880351800001,
                40.587495921999896
              ],
              [
                -73.81849901800001,
                40.5873623529999
              ],
              [
                -73.81829590099994,
                40.58735573699989
              ],
              [
                -73.81812861199995,
                40.58737005199992
              ],
              [
                -73.81800188699995,
                40.587401720999935
              ],
              [
                -73.81788322399981,
                40.58744846899986
              ],
              [
                -73.81771610099989,
                40.587150957999924
              ],
              [
                -73.81739092999987,
                40.58656943199989
              ],
              [
                -73.81733678299989,
                40.586483192999964
              ],
              [
                -73.81693289499991,
                40.58582331099997
              ],
              [
                -73.81637844599986,
                40.584911004999896
              ],
              [
                -73.81576138799994,
                40.58395921499993
              ],
              [
                -73.81570076199996,
                40.58382964799994
              ],
              [
                -73.81567316399989,
                40.58377646399993
              ],
              [
                -73.81563939099986,
                40.583722207999855
              ],
              [
                -73.81550882099985,
                40.583475793999924
              ],
              [
                -73.81544517899997,
                40.58337349199993
              ],
              [
                -73.81567618499987,
                40.58330770499988
              ],
              [
                -73.81670273099988,
                40.5830153519999
              ],
              [
                -73.81751059199993,
                40.58278527099993
              ],
              [
                -73.81730795916116,
                40.58233475897001
              ],
              [
                -73.81702595651315,
                40.58241017939203
              ],
              [
                -73.81684582937444,
                40.58246342820495
              ],
              [
                -73.81655438991994,
                40.58256868458874
              ],
              [
                -73.81652734669422,
                40.582575697090924
              ],
              [
                -73.815896308824,
                40.582739308619864
              ],
              [
                -73.81544351379941,
                40.5828520849442
              ],
              [
                -73.81539400218122,
                40.58286969235861
              ],
              [
                -73.81523945963075,
                40.58292465355023
              ],
              [
                -73.81501580175151,
                40.58301698588703
              ],
              [
                -73.8148522431602,
                40.58306547678403
              ],
              [
                -73.81471054401754,
                40.58309434392088
              ],
              [
                -73.81453624637712,
                40.583148112330356
              ],
              [
                -73.81425389459,
                40.58324543395958
              ],
              [
                -73.81417259932803,
                40.5832734543754
              ],
              [
                -73.81398801425165,
                40.583331094686095
              ],
              [
                -73.8136458227654,
                40.58342805825606
              ],
              [
                -73.81352448727559,
                40.58347314904673
              ],
              [
                -73.81341809223822,
                40.58348912300502
              ],
              [
                -73.81320376356805,
                40.58350883811561
              ],
              [
                -73.81269595844536,
                40.583566322154645
              ],
              [
                -73.81216750375401,
                40.58362614107535
              ],
              [
                -73.81132481815267,
                40.58372152471487
              ],
              [
                -73.80962143091784,
                40.58381744017789
              ],
              [
                -73.80951491959115,
                40.58382343175396
              ],
              [
                -73.8094071988208,
                40.583856059634705
              ],
              [
                -73.80936210702265,
                40.58387099616531
              ],
              [
                -73.8093091344346,
                40.58389096291579
              ],
              [
                -73.80919306534241,
                40.583938984957534
              ],
              [
                -73.80914080102232,
                40.58395920477135
              ],
              [
                -73.80908759716272,
                40.583977162520895
              ],
              [
                -73.80903284025594,
                40.583992859027944
              ],
              [
                -73.80897688682195,
                40.58400618263784
              ],
              [
                -73.80892008844539,
                40.58401705754021
              ],
              [
                -73.80887034203347,
                40.58402397084169
              ],
              [
                -73.80881507288822,
                40.58402882269197
              ],
              [
                -73.80875382070491,
                40.58403182792798
              ],
              [
                -73.80866370337569,
                40.58403408345098
              ],
              [
                -73.80863348320919,
                40.584035905601766
              ],
              [
                -73.80854592324482,
                40.58404450361386
              ],
              [
                -73.8084997147581,
                40.58404475982289
              ],
              [
                -73.80834370637812,
                40.5840283168177
              ],
              [
                -73.8082897849443,
                40.584024714597234
              ],
              [
                -73.80792558260914,
                40.58400509638658
              ],
              [
                -73.80783502965463,
                40.58401495035292
              ],
              [
                -73.80758995887524,
                40.58409352531213
              ],
              [
                -73.80746677141387,
                40.58412351351577
              ],
              [
                -73.80743073987672,
                40.58413374616213
              ],
              [
                -73.80718262664281,
                40.58421883524595
              ],
              [
                -73.8069189892024,
                40.584305013120705
              ],
              [
                -73.80688379979635,
                40.584318039564884
              ],
              [
                -73.80672634779604,
                40.58439624727745
              ],
              [
                -73.80668208768715,
                40.584417235332296
              ],
              [
                -73.806637364536,
                40.584434891596
              ],
              [
                -73.80659491176878,
                40.5844474456911
              ],
              [
                -73.80651754277206,
                40.58446468676175
              ],
              [
                -73.80643157748595,
                40.58448079570721
              ],
              [
                -73.8063360357249,
                40.584496124678736
              ],
              [
                -73.8061974730921,
                40.58451602527427
              ],
              [
                -73.8060599290281,
                40.584535191296176
              ],
              [
                -73.80597104297532,
                40.584544415858616
              ],
              [
                -73.8059267115608,
                40.5845450606945
              ],
              [
                -73.80587704621392,
                40.58453571088796
              ],
              [
                -73.80586233123242,
                40.58452681532272
              ],
              [
                -73.80571961087702,
                40.58450608736719
              ],
              [
                -73.80562312964219,
                40.584511183783114
              ],
              [
                -73.80540383998208,
                40.58456159321668
              ],
              [
                -73.80524718089742,
                40.58461403466702
              ],
              [
                -73.80519393375724,
                40.58463024471364
              ],
              [
                -73.80494831722095,
                40.58469611569916
              ],
              [
                -73.80484109780319,
                40.58472572243929
              ],
              [
                -73.80476634753872,
                40.584747631311934
              ],
              [
                -73.80468776810687,
                40.58477446838441
              ],
              [
                -73.80455361072326,
                40.584826705560076
              ],
              [
                -73.80440924673873,
                40.58487933799817
              ],
              [
                -73.80431422436611,
                40.58490993835772
              ],
              [
                -73.80421036759756,
                40.58493290516728
              ],
              [
                -73.80407247759545,
                40.58495669707044
              ],
              [
                -73.80377557037241,
                40.58501469648514
              ],
              [
                -73.80371873405733,
                40.58501808419201
              ],
              [
                -73.80366299243434,
                40.58501453985067
              ],
              [
                -73.8036112870732,
                40.58500425779923
              ],
              [
                -73.80357247869416,
                40.584990431231944
              ],
              [
                -73.80354468490675,
                40.58497428275867
              ],
              [
                -73.80351488810318,
                40.584944793638485
              ],
              [
                -73.80349876666156,
                40.584924287560646
              ],
              [
                -73.80338852227408,
                40.584885629431014
              ],
              [
                -73.80330229481847,
                40.58488615648928
              ],
              [
                -73.80323770130614,
                40.58490057139446
              ],
              [
                -73.80304240416451,
                40.584976674263295
              ],
              [
                -73.80286516391382,
                40.585030283903656
              ],
              [
                -73.80265525068585,
                40.58508385675815
              ],
              [
                -73.8025397086005,
                40.585115410171596
              ],
              [
                -73.80241963073168,
                40.58515512425481
              ],
              [
                -73.80222781866699,
                40.58522258551992
              ],
              [
                -73.80203251846666,
                40.585295083772266
              ],
              [
                -73.80195743621874,
                40.5853213396556
              ],
              [
                -73.80190170596248,
                40.58533768830841
              ],
              [
                -73.80184018400165,
                40.58535171084624
              ],
              [
                -73.80177161574133,
                40.58536373177831
              ],
              [
                -73.80169093145422,
                40.585374651358855
              ],
              [
                -73.80156339177964,
                40.58538874038295
              ],
              [
                -73.80143187846255,
                40.58540075072497
              ],
              [
                -73.80133785981273,
                40.58540717178863
              ],
              [
                -73.80126813622081,
                40.58541032910152
              ],
              [
                -73.80122412486946,
                40.58541057680467
              ],
              [
                -73.80119138109775,
                40.58540675592561
              ],
              [
                -73.80116064145146,
                40.58539678798376
              ],
              [
                -73.80091511175824,
                40.58540706093311
              ],
              [
                -73.8008395667628,
                40.585429947433184
              ],
              [
                -73.80079451624748,
                40.58543770353159
              ],
              [
                -73.80075861454394,
                40.585455246485665
              ],
              [
                -73.80071108578056,
                40.58547432779182
              ],
              [
                -73.80061781447155,
                40.585507557682
              ],
              [
                -73.80054546083578,
                40.58553705958265
              ],
              [
                -73.80045350238458,
                40.58558144809817
              ],
              [
                -73.80028251237498,
                40.585633066317115
              ],
              [
                -73.80014577318751,
                40.5856673527614
              ],
              [
                -73.80007589595283,
                40.58568487426214
              ],
              [
                -73.79974084641992,
                40.58576904830181
              ],
              [
                -73.7996884641193,
                40.5857799126032
              ],
              [
                -73.79963642261944,
                40.585790705604765
              ],
              [
                -73.79949174993197,
                40.58581418097826
              ],
              [
                -73.79928381556239,
                40.58584414752272
              ],
              [
                -73.7990820019512,
                40.58586989242401
              ],
              [
                -73.79893359561237,
                40.58588564380834
              ],
              [
                -73.79881542788075,
                40.58589401901036
              ],
              [
                -73.79869486678675,
                40.58589644661038
              ],
              [
                -73.79862937227614,
                40.58587618655243
              ],
              [
                -73.79860416989281,
                40.58585500681995
              ],
              [
                -73.79846640545998,
                40.585843680652964
              ],
              [
                -73.79841218458479,
                40.58586820538177
              ],
              [
                -73.79835935100574,
                40.585899396855275
              ],
              [
                -73.79833821336442,
                40.58590927517722
              ],
              [
                -73.79831147819522,
                40.58591855781807
              ],
              [
                -73.79827724435198,
                40.58592714276107
              ],
              [
                -73.79821508276169,
                40.58594515212626
              ],
              [
                -73.79818740957423,
                40.58595599982967
              ],
              [
                -73.79812309463986,
                40.585971177627265
              ],
              [
                -73.79808945022879,
                40.58597652251827
              ],
              [
                -73.79803790681721,
                40.58598277075553
              ],
              [
                -73.79796497730955,
                40.58599143199314
              ],
              [
                -73.79787491546294,
                40.58599744170836
              ],
              [
                -73.79781087949239,
                40.58600652307737
              ],
              [
                -73.79771707099138,
                40.58602499900517
              ],
              [
                -73.797641879923,
                40.586051161915876
              ],
              [
                -73.79761231706715,
                40.58606590540912
              ],
              [
                -73.79758258715597,
                40.58607732581424
              ],
              [
                -73.79726406299116,
                40.586182738561305
              ],
              [
                -73.79721710027918,
                40.58620132370163
              ],
              [
                -73.79712944995846,
                40.58623042638213
              ],
              [
                -73.79706738813755,
                40.586250408301595
              ],
              [
                -73.7970423053881,
                40.58626018928658
              ],
              [
                -73.7969761667721,
                40.586284367278374
              ],
              [
                -73.79691111161141,
                40.586305748561806
              ],
              [
                -73.79685841157989,
                40.586327223020525
              ],
              [
                -73.7968225160063,
                40.58633828034797
              ],
              [
                -73.79675983252,
                40.586353073274196
              ],
              [
                -73.79673529350114,
                40.58635868583969
              ],
              [
                -73.79670174417555,
                40.58636727152463
              ],
              [
                -73.79663442436521,
                40.5863760944634
              ],
              [
                -73.79659741256897,
                40.586377433686245
              ],
              [
                -73.79652371849949,
                40.58637732967472
              ],
              [
                -73.7964515757576,
                40.586379271389966
              ],
              [
                -73.79643078427874,
                40.58637983129658
              ],
              [
                -73.79633505857085,
                40.586376365677
              ],
              [
                -73.7962517833779,
                40.58635312824201
              ],
              [
                -73.79622173814556,
                40.58634764565386
              ],
              [
                -73.79614753501264,
                40.586336005065384
              ],
              [
                -73.79602046510774,
                40.58631904717582
              ],
              [
                -73.79598297100075,
                40.58633541544899
              ],
              [
                -73.79595625878476,
                40.58635658482832
              ],
              [
                -73.79590852396218,
                40.586392026085434
              ],
              [
                -73.7958834929234,
                40.586404083471976
              ],
              [
                -73.79583321779106,
                40.58642068145795
              ],
              [
                -73.79576511591748,
                40.58644144352023
              ],
              [
                -73.79573362789836,
                40.586448367005296
              ],
              [
                -73.79559625132377,
                40.586468456294
              ],
              [
                -73.79554878114587,
                40.5864711989017
              ],
              [
                -73.79544358593455,
                40.58647027336698
              ],
              [
                -73.79542826118838,
                40.58647179523147
              ],
              [
                -73.79538200168786,
                40.586476584459575
              ],
              [
                -73.79534267191754,
                40.58647907192307
              ],
              [
                -73.79529022754971,
                40.58647872653354
              ],
              [
                -73.79525645329633,
                40.58647981862521
              ],
              [
                -73.79522576667671,
                40.58648314117327
              ],
              [
                -73.79506190351425,
                40.586519717306
              ],
              [
                -73.79503399443716,
                40.586522548533615
              ],
              [
                -73.79500512248676,
                40.58652378533056
              ],
              [
                -73.7949761236387,
                40.586527632343454
              ],
              [
                -73.79494319554186,
                40.586533967860845
              ],
              [
                -73.79488456535023,
                40.58655162103627
              ],
              [
                -73.7948552134114,
                40.58656660745073
              ],
              [
                -73.79482352521471,
                40.58658098691356
              ],
              [
                -73.79479420340178,
                40.58659760276119
              ],
              [
                -73.79474172935485,
                40.58663755569442
              ],
              [
                -73.79471998303308,
                40.58664917842728
              ],
              [
                -73.79468886939398,
                40.586660703166345
              ],
              [
                -73.79453736495408,
                40.586711429260994
              ],
              [
                -73.79451058376748,
                40.586720035790826
              ],
              [
                -73.79444991319517,
                40.586732822878
              ],
              [
                -73.79440078001352,
                40.58674239725301
              ],
              [
                -73.79434460522953,
                40.58675667898858
              ],
              [
                -73.79419885193848,
                40.58677319428643
              ],
              [
                -73.79414149029743,
                40.586773694805146
              ],
              [
                -73.79410918649302,
                40.58677676118776
              ],
              [
                -73.79405336467549,
                40.586794915697695
              ],
              [
                -73.7940302958186,
                40.58680280788874
              ],
              [
                -73.79400669001211,
                40.58680816790469
              ],
              [
                -73.79396902115447,
                40.58681183829106
              ],
              [
                -73.79394033243052,
                40.58681104787107
              ],
              [
                -73.793909486807,
                40.58680871498136
              ],
              [
                -73.79388370976301,
                40.58680542653921
              ],
              [
                -73.7938518333594,
                40.58680037160348
              ],
              [
                -73.79379355003898,
                40.58678572342176
              ],
              [
                -73.79376524530197,
                40.586786545451446
              ],
              [
                -73.79358367520715,
                40.58683828784179
              ],
              [
                -73.79352137558897,
                40.586888597520556
              ],
              [
                -73.79349915398709,
                40.586900966351365
              ],
              [
                -73.79347727539466,
                40.58690957206976
              ],
              [
                -73.79338308107954,
                40.58693745408561
              ],
              [
                -73.79332342680792,
                40.58694600004854
              ],
              [
                -73.79326405221966,
                40.58694802873628
              ],
              [
                -73.79319946350594,
                40.586948190773526
              ],
              [
                -73.7931376215086,
                40.58695378870107
              ],
              [
                -73.79307866251777,
                40.586962462608795
              ],
              [
                -73.79301601821497,
                40.58697159926605
              ],
              [
                -73.79293381050245,
                40.586981607976604
              ],
              [
                -73.79281029204658,
                40.586996072820405
              ],
              [
                -73.79274578763984,
                40.587003395481474
              ],
              [
                -73.79261297557468,
                40.58702484039965
              ],
              [
                -73.79251797208626,
                40.58704316501593
              ],
              [
                -73.79243962937679,
                40.58707876584059
              ],
              [
                -73.79239225941785,
                40.58709885185526
              ],
              [
                -73.7923418903835,
                40.58711530607998
              ],
              [
                -73.79230828344397,
                40.587126284380126
              ],
              [
                -73.79218465863211,
                40.587160037742436
              ],
              [
                -73.79209625186263,
                40.587187910073425
              ],
              [
                -73.79200188355018,
                40.58721073844644
              ],
              [
                -73.79197454551787,
                40.58721593046026
              ],
              [
                -73.79187055097091,
                40.587230943454436
              ],
              [
                -73.79172064744127,
                40.587259767900726
              ],
              [
                -73.79166457516907,
                40.58726995709992
              ],
              [
                -73.79153984145417,
                40.5872926236508
              ],
              [
                -73.79147720790621,
                40.58730159698543
              ],
              [
                -73.79141754078474,
                40.58730302795018
              ],
              [
                -73.79138171999988,
                40.58730488096744
              ],
              [
                -73.79134615079208,
                40.58730196234346
              ],
              [
                -73.79132452803208,
                40.587296357735376
              ],
              [
                -73.79129641503224,
                40.58728496892728
              ],
              [
                -73.79128113976063,
                40.58727429683878
              ],
              [
                -73.79114367230207,
                40.58726949006762
              ],
              [
                -73.79111283049583,
                40.587273487051164
              ],
              [
                -73.7910866172636,
                40.5872853529413
              ],
              [
                -73.79105759554307,
                40.58729633150625
              ],
              [
                -73.79103431331966,
                40.5873037001829
              ],
              [
                -73.7909970316632,
                40.587312142386644
              ],
              [
                -73.79091570414633,
                40.587342522713634
              ],
              [
                -73.79088356288405,
                40.58735003774265
              ],
              [
                -73.7908451648303,
                40.58735652295986
              ],
              [
                -73.7906377095661,
                40.58740429622775
              ],
              [
                -73.79057603970988,
                40.58741499068854
              ],
              [
                -73.79050360269464,
                40.587435228422265
              ],
              [
                -73.79040001810121,
                40.5874586695941
              ],
              [
                -73.79030455195283,
                40.58747718972551
              ],
              [
                -73.79015229641777,
                40.58749835423499
              ],
              [
                -73.79009685734076,
                40.58750739373388
              ],
              [
                -73.7899629857302,
                40.58753478548763
              ],
              [
                -73.78989734537727,
                40.587545841227
              ],
              [
                -73.78989469426456,
                40.5875462744698
              ],
              [
                -73.78983704829267,
                40.58755569077077
              ],
              [
                -73.78975824801644,
                40.587563227687696
              ],
              [
                -73.78971718205132,
                40.5875689160682
              ],
              [
                -73.78968379436958,
                40.58757481667646
              ],
              [
                -73.78963535896543,
                40.58758765059032
              ],
              [
                -73.7895975404431,
                40.58759375883978
              ],
              [
                -73.78957437415305,
                40.58759835347445
              ],
              [
                -73.78953791751881,
                40.587603312430815
              ],
              [
                -73.7894798745329,
                40.58761734360714
              ],
              [
                -73.78945065591779,
                40.58762324290702
              ],
              [
                -73.78941470793565,
                40.58762814730407
              ],
              [
                -73.78936256861486,
                40.587631762184955
              ],
              [
                -73.78934393075197,
                40.587631323432134
              ],
              [
                -73.78932570533473,
                40.587630893138964
              ],
              [
                -73.78929582562273,
                40.58762908280522
              ],
              [
                -73.78926228451017,
                40.58762355580058
              ],
              [
                -73.78923024711176,
                40.58762075826
              ],
              [
                -73.78917735702238,
                40.58760031892057
              ],
              [
                -73.78912690923757,
                40.587576758770204
              ],
              [
                -73.7891046960101,
                40.58756749598723
              ],
              [
                -73.78907590827097,
                40.58754940537708
              ],
              [
                -73.789033888718,
                40.58751663914531
              ],
              [
                -73.78901325881863,
                40.587503761110014
              ],
              [
                -73.78898283996136,
                40.58749323982922
              ],
              [
                -73.78887538807133,
                40.58747315028719
              ],
              [
                -73.78878406873122,
                40.58745028886725
              ],
              [
                -73.78860298459861,
                40.58748444605279
              ],
              [
                -73.78858894789691,
                40.58750816774748
              ],
              [
                -73.78857477507556,
                40.587526224503925
              ],
              [
                -73.7885370655059,
                40.5875648047434
              ],
              [
                -73.78849742602625,
                40.58759770081677
              ],
              [
                -73.7884747250357,
                40.58761563313568
              ],
              [
                -73.78844687378574,
                40.587633619629
              ],
              [
                -73.78842295061148,
                40.58764590359729
              ],
              [
                -73.78818270539261,
                40.58774835510363
              ],
              [
                -73.78768370715751,
                40.587922937712015
              ],
              [
                -73.7875018580916,
                40.5879665201616
              ],
              [
                -73.78737986814394,
                40.5879936613258
              ],
              [
                -73.78720687841518,
                40.58802304044105
              ],
              [
                -73.78708881251839,
                40.588038275015045
              ],
              [
                -73.78692504410577,
                40.58806459058935
              ],
              [
                -73.78684206289357,
                40.58807553117462
              ],
              [
                -73.78670594394542,
                40.58809180288192
              ],
              [
                -73.78660941617714,
                40.588105726485026
              ],
              [
                -73.78653419682246,
                40.58811629426872
              ],
              [
                -73.78645905573437,
                40.588128149447996
              ],
              [
                -73.78639182672046,
                40.58813302223642
              ],
              [
                -73.78630555217079,
                40.588147107883046
              ],
              [
                -73.78610751824587,
                40.588194773061446
              ],
              [
                -73.78602063181066,
                40.58821892589794
              ],
              [
                -73.78577545794695,
                40.588280199779064
              ],
              [
                -73.78563095491499,
                40.588310179295796
              ],
              [
                -73.7855107314079,
                40.58833488153329
              ],
              [
                -73.78537587818671,
                40.588339483063365
              ],
              [
                -73.78524236737745,
                40.58836350381885
              ],
              [
                -73.78517136143141,
                40.58837484386224
              ],
              [
                -73.78513382316405,
                40.58838182450066
              ],
              [
                -73.78505919649476,
                40.58839153601536
              ],
              [
                -73.78497718722853,
                40.588400964278634
              ],
              [
                -73.78491351080591,
                40.588407131155165
              ],
              [
                -73.78484776439457,
                40.588417769344844
              ],
              [
                -73.78474624813403,
                40.588436021568214
              ],
              [
                -73.78465258073332,
                40.58845250656502
              ],
              [
                -73.7845331104848,
                40.58847805521788
              ],
              [
                -73.78438543099911,
                40.588518319575556
              ],
              [
                -73.7840617124231,
                40.58860933172051
              ],
              [
                -73.78393636438304,
                40.58864763816582
              ],
              [
                -73.7838100709537,
                40.588678910463756
              ],
              [
                -73.78370053961723,
                40.58870244196634
              ],
              [
                -73.78350063414018,
                40.58875787134419
              ],
              [
                -73.78330722135844,
                40.588847451345195
              ],
              [
                -73.78310566264246,
                40.58892437200098
              ],
              [
                -73.7829760232428,
                40.58896705489507
              ],
              [
                -73.78288961869187,
                40.58899867107044
              ],
              [
                -73.78277341608162,
                40.58902469348824
              ],
              [
                -73.7827088491647,
                40.58903920513687
              ],
              [
                -73.7825569715204,
                40.58906584271833
              ],
              [
                -73.78252737843965,
                40.58907385545228
              ],
              [
                -73.78246331446263,
                40.58908957496757
              ],
              [
                -73.78243514633095,
                40.58909162849138
              ],
              [
                -73.78240601796996,
                40.58909135705382
              ],
              [
                -73.78234015973189,
                40.58909592483009
              ],
              [
                -73.78228054765906,
                40.589112201169236
              ],
              [
                -73.7822239170208,
                40.58913432790882
              ],
              [
                -73.78218351388836,
                40.5891467143731
              ],
              [
                -73.78214454631176,
                40.589160274578624
              ],
              [
                -73.78211351753535,
                40.589170626215925
              ],
              [
                -73.78206226147378,
                40.58918551287879
              ],
              [
                -73.78190890137239,
                40.58922427779517
              ],
              [
                -73.78178622344916,
                40.589250998424106
              ],
              [
                -73.78168370364948,
                40.58925399144243
              ],
              [
                -73.78161081064151,
                40.58924953919869
              ],
              [
                -73.78148525449765,
                40.589260583965945
              ],
              [
                -73.78142243554616,
                40.58927130634632
              ],
              [
                -73.78135755523371,
                40.58929116570024
              ],
              [
                -73.78121208011379,
                40.58934257024146
              ],
              [
                -73.78114865778063,
                40.58935719074782
              ],
              [
                -73.7810878441754,
                40.58937957901906
              ],
              [
                -73.78102983980422,
                40.58940253149113
              ],
              [
                -73.78097447293345,
                40.589420787069194
              ],
              [
                -73.78086936029906,
                40.589458022039786
              ],
              [
                -73.78084602797253,
                40.58946936956988
              ],
              [
                -73.7807538436896,
                40.58950493553285
              ],
              [
                -73.78067604037903,
                40.58952847091271
              ],
              [
                -73.78064367908992,
                40.58953746814707
              ],
              [
                -73.78057452813783,
                40.589562559239596
              ],
              [
                -73.78055284379498,
                40.589568723301205
              ],
              [
                -73.78048449570841,
                40.58958313634869
              ],
              [
                -73.78043036101892,
                40.589596621429045
              ],
              [
                -73.7803723678408,
                40.589605353323826
              ],
              [
                -73.78031021036222,
                40.58960877356822
              ],
              [
                -73.78022286069903,
                40.58961102029942
              ],
              [
                -73.78014903250914,
                40.589614256299946
              ],
              [
                -73.78008826023272,
                40.589623721038066
              ],
              [
                -73.77992945455958,
                40.58964435437392
              ],
              [
                -73.77985959718424,
                40.5896471020727
              ],
              [
                -73.77980375731784,
                40.589643762255754
              ],
              [
                -73.77951586431837,
                40.589588639029905
              ],
              [
                -73.77942325796694,
                40.58958020324829
              ],
              [
                -73.77939398598417,
                40.589591015886086
              ],
              [
                -73.77936059540092,
                40.58959328546561
              ],
              [
                -73.77928116131307,
                40.589584514170056
              ],
              [
                -73.7792490976237,
                40.58958558675223
              ],
              [
                -73.77918495356101,
                40.58959310889531
              ],
              [
                -73.77912834079973,
                40.58960594076261
              ],
              [
                -73.77897465802009,
                40.58965958734476
              ],
              [
                -73.77884539331758,
                40.589705679221986
              ],
              [
                -73.77870305555747,
                40.58975055782302
              ],
              [
                -73.77865504053747,
                40.58976360420327
              ],
              [
                -73.77854450219125,
                40.58978726338208
              ],
              [
                -73.77838680010395,
                40.589816452295594
              ],
              [
                -73.77827350862975,
                40.589843961268194
              ],
              [
                -73.77824956631362,
                40.58985379363007
              ],
              [
                -73.77814946692536,
                40.5899067311578
              ],
              [
                -73.77810144460149,
                40.58992581087995
              ],
              [
                -73.77791587910747,
                40.58997897012016
              ],
              [
                -73.77783509043981,
                40.590000499009875
              ],
              [
                -73.77775882321265,
                40.59001959651591
              ],
              [
                -73.77761352076865,
                40.59005347120863
              ],
              [
                -73.77750026725015,
                40.59007629686677
              ],
              [
                -73.7774555913146,
                40.590083378113455
              ],
              [
                -73.77735319926097,
                40.5900938153919
              ],
              [
                -73.77728844028084,
                40.59009784111593
              ],
              [
                -73.77720080214564,
                40.590101291039275
              ],
              [
                -73.77710926069993,
                40.59010242007234
              ],
              [
                -73.7770131564979,
                40.590101215869105
              ],
              [
                -73.77691114218703,
                40.59009768501646
              ],
              [
                -73.77686267144291,
                40.59009159350016
              ],
              [
                -73.77675237473704,
                40.590067137693126
              ],
              [
                -73.77669882905153,
                40.59006324194554
              ],
              [
                -73.7766624348081,
                40.59006633216661
              ],
              [
                -73.77662543841134,
                40.59007310468421
              ],
              [
                -73.77657809244383,
                40.59008743829471
              ],
              [
                -73.77653951423233,
                40.590104446965505
              ],
              [
                -73.77651625337222,
                40.59012613068266
              ],
              [
                -73.77648111993174,
                40.59016582974159
              ],
              [
                -73.77646844928363,
                40.59017495574101
              ],
              [
                -73.7764548908938,
                40.59018123250739
              ],
              [
                -73.7763506460391,
                40.59021225018257
              ],
              [
                -73.77608710709404,
                40.590274314192655
              ],
              [
                -73.77592417813304,
                40.59030830669686
              ],
              [
                -73.7758368859526,
                40.5903245987577
              ],
              [
                -73.77582980872609,
                40.5903252146957
              ],
              [
                -73.7758265854893,
                40.59032463243996
              ],
              [
                -73.77582424055556,
                40.59032276359304
              ],
              [
                -73.7758237310679,
                40.59031964671146
              ],
              [
                -73.77582466487753,
                40.59031622740645
              ],
              [
                -73.77584876326614,
                40.59028427873138
              ],
              [
                -73.77590847858993,
                40.590220465816344
              ],
              [
                -73.77592039829484,
                40.590205937251916
              ],
              [
                -73.77592993689225,
                40.59019043002462
              ],
              [
                -73.7759304282186,
                40.59018140832791
              ],
              [
                -73.7759259477778,
                40.59017858994795
              ],
              [
                -73.77591759770544,
                40.5901782942247
              ],
              [
                -73.77586029605193,
                40.59018468496815
              ],
              [
                -73.77579676083829,
                40.59019371819813
              ],
              [
                -73.77559905541047,
                40.590227399797776
              ],
              [
                -73.77547648519086,
                40.59024542262565
              ],
              [
                -73.77529299783197,
                40.590262922297356
              ],
              [
                -73.77521117634113,
                40.59026821837303
              ],
              [
                -73.77478603527493,
                40.59027136640258
              ],
              [
                -73.774675873645,
                40.590274031949086
              ],
              [
                -73.77442481913887,
                40.59028354475862
              ],
              [
                -73.77441435006315,
                40.59028444260473
              ],
              [
                -73.77438246523175,
                40.59028884634952
              ],
              [
                -73.77434868621285,
                40.59029769512073
              ],
              [
                -73.77429526133092,
                40.59031727595607
              ],
              [
                -73.77420071437766,
                40.59035848800224
              ],
              [
                -73.77415547523118,
                40.59037462586905
              ],
              [
                -73.77411550495816,
                40.590384156311714
              ],
              [
                -73.77409639395029,
                40.59038676638325
              ],
              [
                -73.77407763164722,
                40.59038796301789
              ],
              [
                -73.77404303478913,
                40.59038733611081
              ],
              [
                -73.77400459636709,
                40.59038364070018
              ],
              [
                -73.77396801908459,
                40.590378130233624
              ],
              [
                -73.77388174249228,
                40.59036294796987
              ],
              [
                -73.77383881018656,
                40.59035725307069
              ],
              [
                -73.77379843010951,
                40.5903548866368
              ],
              [
                -73.77376174702637,
                40.590356354854826
              ],
              [
                -73.77374135977973,
                40.590359250505934
              ],
              [
                -73.7737060326092,
                40.59036817678946
              ],
              [
                -73.77368113064308,
                40.590375602425
              ],
              [
                -73.77364313739031,
                40.59038690973319
              ],
              [
                -73.77360853280666,
                40.59039536916424
              ],
              [
                -73.77353992912971,
                40.5904009893
              ],
              [
                -73.77348503908469,
                40.59040324931067
              ],
              [
                -73.77315678948564,
                40.59040902184443
              ],
              [
                -73.77309963866246,
                40.590412601363965
              ],
              [
                -73.77306871342176,
                40.59041582689397
              ],
              [
                -73.77303452122213,
                40.59042092899833
              ],
              [
                -73.77289315118053,
                40.59044730501539
              ],
              [
                -73.77286232906431,
                40.59045153972608
              ],
              [
                -73.77283395784046,
                40.590454185059784
              ],
              [
                -73.77278993524673,
                40.59045633119343
              ],
              [
                -73.77274072126971,
                40.59045705359362
              ],
              [
                -73.77252453190594,
                40.59045434707629
              ],
              [
                -73.7724707070396,
                40.59045613158627
              ],
              [
                -73.77242217935688,
                40.59045995283603
              ],
              [
                -73.77228081745474,
                40.59047669280408
              ],
              [
                -73.77224111424952,
                40.5904821784512
              ],
              [
                -73.7720906007932,
                40.59050297169143
              ],
              [
                -73.77178997907545,
                40.59055066116292
              ],
              [
                -73.77168745194965,
                40.590565550594086
              ],
              [
                -73.7716369311758,
                40.5905716732657
              ],
              [
                -73.77153385684235,
                40.59058063493613
              ],
              [
                -73.7714284595353,
                40.59058518920861
              ],
              [
                -73.77133707453224,
                40.5905854575432
              ],
              [
                -73.77111441651954,
                40.590577963413
              ],
              [
                -73.77101650427375,
                40.59057321135812
              ],
              [
                -73.770982527501,
                40.590570856695
              ],
              [
                -73.77095214359046,
                40.59056773402298
              ],
              [
                -73.7709129068352,
                40.59056256741987
              ],
              [
                -73.77088579106662,
                40.59055391337879
              ],
              [
                -73.77088020413456,
                40.59055383907998
              ],
              [
                -73.77085305962974,
                40.590556955789495
              ],
              [
                -73.77082321393041,
                40.590562155867616
              ],
              [
                -73.77074679602242,
                40.590579751878856
              ],
              [
                -73.77072132001807,
                40.59058540091686
              ],
              [
                -73.77069663752131,
                40.59059042245806
              ],
              [
                -73.7705747448231,
                40.59060997196553
              ],
              [
                -73.7702442519966,
                40.59065615682652
              ],
              [
                -73.77021137888435,
                40.59066247649312
              ],
              [
                -73.77017538907467,
                40.59067149100075
              ],
              [
                -73.77002485586418,
                40.590716531277565
              ],
              [
                -73.76998923560154,
                40.59072466308158
              ],
              [
                -73.76995620052726,
                40.5907300723288
              ],
              [
                -73.76978825596215,
                40.59075090723356
              ],
              [
                -73.76945313604804,
                40.59079247029636
              ],
              [
                -73.76914741260786,
                40.590832100854804
              ],
              [
                -73.76886283140855,
                40.590862074658574
              ],
              [
                -73.7688338763409,
                40.59086377966345
              ],
              [
                -73.76872708525137,
                40.59087006895558
              ],
              [
                -73.76858695488026,
                40.590868597201954
              ],
              [
                -73.76844393841534,
                40.590857674630115
              ],
              [
                -73.76828636409597,
                40.590834347763334
              ],
              [
                -73.7682199338214,
                40.59079881505915
              ],
              [
                -73.76813345500128,
                40.590736899968306
              ],
              [
                -73.76807147481031,
                40.59072282634708
              ],
              [
                -73.76796973006509,
                40.590729482964356
              ],
              [
                -73.767762798651,
                40.590758583689066
              ],
              [
                -73.76762172035325,
                40.59078493572176
              ],
              [
                -73.7674668729756,
                40.59083875455402
              ],
              [
                -73.76745345525089,
                40.59084341823688
              ],
              [
                -73.7673542988742,
                40.59086337103326
              ],
              [
                -73.76719481614633,
                40.59087299025633
              ],
              [
                -73.76704832460794,
                40.59088297651934
              ],
              [
                -73.76674971845058,
                40.59092708264958
              ],
              [
                -73.76665068484326,
                40.590938785749806
              ],
              [
                -73.76642597471904,
                40.5909609333352
              ],
              [
                -73.76632501255979,
                40.59097365115132
              ],
              [
                -73.76622772170012,
                40.59099390262787
              ],
              [
                -73.76604736542076,
                40.59104682933076
              ],
              [
                -73.76567285203018,
                40.59112681628278
              ],
              [
                -73.76551723507008,
                40.591155606480676
              ],
              [
                -73.76541994963503,
                40.591173604845665
              ],
              [
                -73.76517139989306,
                40.59120547114957
              ],
              [
                -73.76499023204339,
                40.59123370113477
              ],
              [
                -73.76463923166975,
                40.591298770378614
              ],
              [
                -73.76428206816591,
                40.59136497918444
              ],
              [
                -73.76409879358965,
                40.59139387155286
              ],
              [
                -73.76376223423699,
                40.591439709016804
              ],
              [
                -73.76385620499985,
                40.59175407799996
              ],
              [
                -73.763912182,
                40.592039344999925
              ],
              [
                -73.7640494709999,
                40.59273904699988
              ],
              [
                -73.76414300299994,
                40.593205171999884
              ],
              [
                -73.76429039299992,
                40.59393964499993
              ],
              [
                -73.76440183099986,
                40.594571682999906
              ],
              [
                -73.76362979899989,
                40.59466694999985
              ],
              [
                -73.76277845599988,
                40.59475654399989
              ],
              [
                -73.762981598,
                40.59589946999991
              ],
              [
                -73.76299716399983,
                40.595987023999875
              ],
              [
                -73.76301370999988,
                40.596081549999916
              ],
              [
                -73.76319972899987,
                40.59714459099989
              ],
              [
                -73.7633344969999,
                40.59782287399992
              ],
              [
                -73.762552899,
                40.59831811799991
              ],
              [
                -73.7618148629999,
                40.59895137099995
              ],
              [
                -73.76128753399995,
                40.59954927099996
              ],
              [
                -73.76154124799989,
                40.599846560999886
              ],
              [
                -73.76158779199984,
                40.59990109899993
              ],
              [
                -73.76165797299984,
                40.599973069999876
              ],
              [
                -73.76336096499996,
                40.59910504299995
              ],
              [
                -73.76386778499985,
                40.59972731299991
              ],
              [
                -73.76444582499997,
                40.600435240999914
              ],
              [
                -73.76654204999997,
                40.599434441999904
              ],
              [
                -73.76599689399985,
                40.598721061999875
              ],
              [
                -73.76618488799977,
                40.59865569699989
              ],
              [
                -73.76636755699995,
                40.59857174399987
              ],
              [
                -73.76654034499992,
                40.598469425999895
              ],
              [
                -73.76669864799996,
                40.598349814999935
              ],
              [
                -73.76683818399978,
                40.598214847999884
              ],
              [
                -73.76695532899997,
                40.59806727399992
              ],
              [
                -73.76704742299998,
                40.597910426999896
              ],
              [
                -73.76711296699986,
                40.597748041999914
              ],
              [
                -73.76715168699985,
                40.59758390099991
              ],
              [
                -73.76716449199986,
                40.59742159799991
              ],
              [
                -73.76715321499994,
                40.597264333999874
              ],
              [
                -73.76712577699985,
                40.59712732699992
              ],
              [
                -73.76711717700005,
                40.59698813299991
              ],
              [
                -73.767127717,
                40.5968490139999
              ],
              [
                -73.76715706599988,
                40.59671223899988
              ],
              [
                -73.76717720099997,
                40.5966231809999
              ],
              [
                -73.76721443599983,
                40.596535878999845
              ],
              [
                -73.76726813399988,
                40.59645316599989
              ],
              [
                -73.76733661099996,
                40.59637765199988
              ],
              [
                -73.76742090699994,
                40.59630520399985
              ],
              [
                -73.76771811799993,
                40.596261309999925
              ],
              [
                -73.76776273800007,
                40.59654905699993
              ],
              [
                -73.76795322999997,
                40.597426056999886
              ],
              [
                -73.76809925802587,
                40.59784597005487
              ],
              [
                -73.76811008919918,
                40.59783295222738
              ],
              [
                -73.76811381057426,
                40.597829591555964
              ],
              [
                -73.7681180614672,
                40.59782672719414
              ],
              [
                -73.76812285388164,
                40.59782435923732
              ],
              [
                -73.76812817714196,
                40.597822487925875
              ],
              [
                -73.76813404220754,
                40.59782110441634
              ],
              [
                -73.76814045974687,
                40.59782020804663
              ],
              [
                -73.76815495718161,
                40.59781988601653
              ],
              [
                -73.76817114818597,
                40.59782142213817
              ],
              [
                -73.76818980308668,
                40.59782479178574
              ],
              [
                -73.76824081682878,
                40.597838402109524
              ],
              [
                -73.7682732797337,
                40.59784910390578
              ],
              [
                -73.76834488487442,
                40.59787660505766
              ],
              [
                -73.76837842432509,
                40.59788695776967
              ],
              [
                -73.76840476505055,
                40.59789191786995
              ],
              [
                -73.76846375279293,
                40.5978984579245
              ],
              [
                -73.76848685686616,
                40.597903097779685
              ],
              [
                -73.76853401985417,
                40.597916645184704
              ],
              [
                -73.76858501067476,
                40.5979333360099
              ],
              [
                -73.7686400308551,
                40.59795308757885
              ],
              [
                -73.76872294114966,
                40.59798446667334
              ],
              [
                -73.76877753897563,
                40.59800692701061
              ],
              [
                -73.7688975492035,
                40.59806084021624
              ],
              [
                -73.76895212674998,
                40.59808260712341
              ],
              [
                -73.76902982880999,
                40.598109500008405
              ],
              [
                -73.76910769216117,
                40.598134619210676
              ],
              [
                -73.76918555500956,
                40.598159728943294
              ],
              [
                -73.76925038618148,
                40.59818149986785
              ],
              [
                -73.76928763545249,
                40.598195631887506
              ],
              [
                -73.76933970822688,
                40.598217330109804
              ],
              [
                -73.76937573689459,
                40.59823235193847
              ],
              [
                -73.76942238175728,
                40.59824862708657
              ],
              [
                -73.76943991058556,
                40.59825305690716
              ],
              [
                -73.76945836563297,
                40.598256138178364
              ],
              [
                -73.76947725104579,
                40.598257769727475
              ],
              [
                -73.76949607048141,
                40.59825791512721
              ],
              [
                -73.76951429278408,
                40.59825659198217
              ],
              [
                -73.76953162147454,
                40.598253808183046
              ],
              [
                -73.7695477270407,
                40.59824963583339
              ],
              [
                -73.76956240787261,
                40.59824413597681
              ],
              [
                -73.76959619242577,
                40.59822799426069
              ],
              [
                -73.76961275094072,
                40.59821925592997
              ],
              [
                -73.76966704117271,
                40.59819061136191
              ],
              [
                -73.76969872152439,
                40.59817498728073
              ],
              [
                -73.7697565611052,
                40.598144872107085
              ],
              [
                -73.76977813280078,
                40.59813482990248
              ],
              [
                -73.7698027471798,
                40.59812625183336
              ],
              [
                -73.76981453611454,
                40.59812350224782
              ],
              [
                -73.76982616733864,
                40.598121724591586
              ],
              [
                -73.76985203600657,
                40.59811954282387
              ],
              [
                -73.76988103186068,
                40.598118493754555
              ],
              [
                -73.77000536315805,
                40.598118760749955
              ],
              [
                -73.77015711217174,
                40.598112804927666
              ],
              [
                -73.77022687646752,
                40.59809852314532
              ],
              [
                -73.77029837514522,
                40.598090670887245
              ],
              [
                -73.77037059124739,
                40.59808935894108
              ],
              [
                -73.7704424992162,
                40.59809460612731
              ],
              [
                -73.77046406974573,
                40.59809265800256
              ],
              [
                -73.77048575786431,
                40.59809356626493
              ],
              [
                -73.77050691790143,
                40.598097304876525
              ],
              [
                -73.77052691955654,
                40.59810376113528
              ],
              [
                -73.77054516579516,
                40.59811274383424
              ],
              [
                -73.77056111373088,
                40.59812398412913
              ],
              [
                -73.77057448291983,
                40.59813852780308
              ],
              [
                -73.77059090361828,
                40.59815112513521
              ],
              [
                -73.77060989596227,
                40.59816140998562
              ],
              [
                -73.7706309070899,
                40.598169081931076
              ],
              [
                -73.77068544592018,
                40.59816984698546
              ],
              [
                -73.77073911081987,
                40.598177298179245
              ],
              [
                -73.77079050002217,
                40.59819124121038
              ],
              [
                -73.77083827197097,
                40.59821131200746
              ],
              [
                -73.77088117884767,
                40.598236985983554
              ],
              [
                -73.77091809986165,
                40.598267592381674
              ],
              [
                -73.7709494149873,
                40.598280800095154
              ],
              [
                -73.77098294567689,
                40.59829033824227
              ],
              [
                -73.77101795592549,
                40.59829599827974
              ],
              [
                -73.77105367950502,
                40.59829765617103
              ],
              [
                -73.77108933187579,
                40.598295275246194
              ],
              [
                -73.7711052481878,
                40.59829476657704
              ],
              [
                -73.77112127437523,
                40.59829301648246
              ],
              [
                -73.77113704375897,
                40.59829007524054
              ],
              [
                -73.7711522271575,
                40.59828598994164
              ],
              [
                -73.77116651606542,
                40.598280867023306
              ],
              [
                -73.77117966319527,
                40.598274778868785
              ],
              [
                -73.77119144202022,
                40.598267832581584
              ],
              [
                -73.7711972790028,
                40.598263881286975
              ],
              [
                -73.77130116654475,
                40.59819358603528
              ],
              [
                -73.77140901159491,
                40.59811961558775
              ],
              [
                -73.77145271133202,
                40.59809224521748
              ],
              [
                -73.77152710619445,
                40.5980504287897
              ],
              [
                -73.77158534673,
                40.59801672081164
              ],
              [
                -73.77161581115801,
                40.598000644223994
              ],
              [
                -73.77164670369835,
                40.59798720656996
              ],
              [
                -73.77167614196895,
                40.597977800971925
              ],
              [
                -73.77171188221453,
                40.59796997462358
              ],
              [
                -73.77179430028143,
                40.59795560343726
              ],
              [
                -73.77190455979193,
                40.597931634559586
              ],
              [
                -73.77194871454776,
                40.59792300411048
              ],
              [
                -73.77197567509168,
                40.597919590916014
              ],
              [
                -73.77200600657046,
                40.597918336322444
              ],
              [
                -73.772036000558,
                40.597918855358934
              ],
              [
                -73.77211321788315,
                40.59792227701258
              ],
              [
                -73.77215537297705,
                40.59792157722973
              ],
              [
                -73.77224040842334,
                40.59791528886194
              ],
              [
                -73.77235384103724,
                40.597906896056074
              ],
              [
                -73.77246641203124,
                40.597894772890506
              ],
              [
                -73.77248573531337,
                40.59789269440954
              ],
              [
                -73.77252829048805,
                40.597888934178656
              ],
              [
                -73.77255072726561,
                40.59788833024759
              ],
              [
                -73.77257530877226,
                40.59788957623183
              ],
              [
                -73.77260315523904,
                40.59789267585408
              ],
              [
                -73.77265976439314,
                40.59790070279755
              ],
              [
                -73.77268672376206,
                40.597904529064
              ],
              [
                -73.77271339239246,
                40.59790647305511
              ],
              [
                -73.77273734104323,
                40.5979063941005
              ],
              [
                -73.77275707480278,
                40.597904983321584
              ],
              [
                -73.77275691437465,
                40.59790368749686
              ],
              [
                -73.772815286146,
                40.5979008220502
              ],
              [
                -73.77288812728872,
                40.59789159960686
              ],
              [
                -73.77291269298549,
                40.59788670418256
              ],
              [
                -73.77296826720625,
                40.59787288305164
              ],
              [
                -73.77299489981291,
                40.59786822556462
              ],
              [
                -73.77300592490842,
                40.59786749965713
              ],
              [
                -73.77301747820697,
                40.59786791935513
              ],
              [
                -73.77302907446409,
                40.59786947331306
              ],
              [
                -73.77304022999472,
                40.59787207932362
              ],
              [
                -73.77305043595175,
                40.5978756216245
              ],
              [
                -73.77305945949531,
                40.59787999706024
              ],
              [
                -73.77306706257957,
                40.597885081859154
              ],
              [
                -73.77307026659959,
                40.597887861975956
              ],
              [
                -73.7730730681773,
                40.597890776494424
              ],
              [
                -73.77310277082675,
                40.59792858445023
              ],
              [
                -73.77311355698103,
                40.597952178698215
              ],
              [
                -73.77312844612995,
                40.59797443810031
              ],
              [
                -73.77314716250139,
                40.597994950368395
              ],
              [
                -73.77317162925218,
                40.59801495475211
              ],
              [
                -73.77319972324258,
                40.59803196664907
              ],
              [
                -73.77323082101059,
                40.59804560852998
              ],
              [
                -73.77326423243323,
                40.59805557765264
              ],
              [
                -73.77329921604125,
                40.59806165278117
              ],
              [
                -73.77333499547422,
                40.59806369909571
              ],
              [
                -73.77338613523759,
                40.59806305172887
              ],
              [
                -73.7734369219436,
                40.59806766990313
              ],
              [
                -73.77348642674832,
                40.59807746915619
              ],
              [
                -73.77353374425257,
                40.59809227026812
              ],
              [
                -73.77358545219488,
                40.5981204731734
              ],
              [
                -73.77363244061031,
                40.598153123466695
              ],
              [
                -73.77367405492255,
                40.59818976631577
              ],
              [
                -73.77370971541684,
                40.59822989126938
              ],
              [
                -73.77371253123867,
                40.59823224692098
              ],
              [
                -73.77371486164249,
                40.59823483599077
              ],
              [
                -73.77371671887805,
                40.59823767559087
              ],
              [
                -73.77371810271825,
                40.59824076789892
              ],
              [
                -73.77371901244801,
                40.59824412933133
              ],
              [
                -73.77371949436639,
                40.59825173830759
              ],
              [
                -73.77371858470356,
                40.59825873356289
              ],
              [
                -73.77371648942128,
                40.59826692432118
              ],
              [
                -73.77370324567592,
                40.59830541449905
              ],
              [
                -73.77370149938109,
                40.59831491083325
              ],
              [
                -73.77370141351436,
                40.59832306115278
              ],
              [
                -73.77371011299202,
                40.59838456571209
              ],
              [
                -73.77371225023631,
                40.598409325955075
              ],
              [
                -73.77372538853857,
                40.59862849443556
              ],
              [
                -73.77374432839089,
                40.59867015398153
              ],
              [
                -73.77377245684356,
                40.59870508653122
              ],
              [
                -73.77379337887521,
                40.59872647954518
              ],
              [
                -73.77381454646792,
                40.59874513426274
              ],
              [
                -73.77382748458088,
                40.598754768225874
              ],
              [
                -73.77383620507035,
                40.59876126041151
              ],
              [
                -73.77385846405322,
                40.59877494699714
              ],
              [
                -73.7738680182614,
                40.59877960452776
              ],
              [
                -73.773879145257,
                40.598783876483346
              ],
              [
                -73.7739324708673,
                40.598799028411555
              ],
              [
                -73.77394465922117,
                40.5988033661098
              ],
              [
                -73.77395500546103,
                40.59880805099072
              ],
              [
                -73.7740085544746,
                40.59883740548697
              ],
              [
                -73.77405725561275,
                40.598867948431845
              ],
              [
                -73.77410258240812,
                40.598900825783346
              ],
              [
                -73.77412070066742,
                40.598916142954145
              ],
              [
                -73.77413648082494,
                40.59893126705587
              ],
              [
                -73.77413858788456,
                40.59893368496501
              ],
              [
                -73.77414040909036,
                40.598936336183854
              ],
              [
                -73.77414330739184,
                40.598942456211915
              ],
              [
                -73.77414522234422,
                40.59894923135867
              ],
              [
                -73.77414910150786,
                40.59896881717438
              ],
              [
                -73.77415118559153,
                40.59897458429225
              ],
              [
                -73.77415392103025,
                40.59897947047851
              ],
              [
                -73.77415499093769,
                40.598980841131315
              ],
              [
                -73.7741562635421,
                40.59898213143316
              ],
              [
                -73.7741577364582,
                40.59898333166253
              ],
              [
                -73.77415942170818,
                40.598984461444104
              ],
              [
                -73.77416344285437,
                40.59898650432967
              ],
              [
                -73.77416837527039,
                40.59898827894796
              ],
              [
                -73.77417887099674,
                40.59899075840607
              ],
              [
                -73.77420449275506,
                40.598995059313836
              ],
              [
                -73.7742102064766,
                40.59899645668073
              ],
              [
                -73.77421476133972,
                40.59899802415852
              ],
              [
                -73.77421874732259,
                40.59900006697305
              ],
              [
                -73.77422031496025,
                40.59900120473159
              ],
              [
                -73.77422161057754,
                40.59900244967746
              ],
              [
                -73.77422263379135,
                40.599003785056546
              ],
              [
                -73.7742233845751,
                40.59900521874304
              ],
              [
                -73.77422388710518,
                40.59900675078433
              ],
              [
                -73.77422411780901,
                40.59900839755203
              ],
              [
                -73.77422411746566,
                40.59901915882223
              ],
              [
                -73.7742228395081,
                40.59903030574869
              ],
              [
                -73.77421643846336,
                40.59905413951923
              ],
              [
                -73.77420518646193,
                40.59907998885753
              ],
              [
                -73.77418482152235,
                40.599117618568314
              ],
              [
                -73.77414205349845,
                40.59918747767375
              ],
              [
                -73.77412969411935,
                40.59921236136472
              ],
              [
                -73.77412213675348,
                40.59923228539407
              ],
              [
                -73.77411815160367,
                40.59925045805247
              ],
              [
                -73.77411776434843,
                40.59926721595789
              ],
              [
                -73.77411806616315,
                40.59926889201571
              ],
              [
                -73.77411868741596,
                40.599270478067844
              ],
              [
                -73.77411960342737,
                40.599271992829
              ],
              [
                -73.7741208394749,
                40.599273435678825
              ],
              [
                -73.77412239338658,
                40.59927479907428
              ],
              [
                -73.77412426793614,
                40.5992761076476
              ],
              [
                -73.7741290093149,
                40.59927855000033
              ],
              [
                -73.77413389337684,
                40.59928039589268
              ],
              [
                -73.77413993625021,
                40.59928221708982
              ],
              [
                -73.77416761842694,
                40.5992891154147
              ],
              [
                -73.7741733892158,
                40.59929106289547
              ],
              [
                -73.77417781177026,
                40.59929308964871
              ],
              [
                -73.77417965160431,
                40.59929420046784
              ],
              [
                -73.7741811840228,
                40.59929535574827
              ],
              [
                -73.77418241995224,
                40.59929657410738
              ],
              [
                -73.77418336140357,
                40.599297845832496
              ],
              [
                -73.77418401798765,
                40.59929918870057
              ],
              [
                -73.77418439064002,
                40.59930058596039
              ],
              [
                -73.77418446850707,
                40.59930206238519
              ],
              [
                -73.77418426304358,
                40.59930361045845
              ],
              [
                -73.77417762359391,
                40.59932786760801
              ],
              [
                -73.77416909783773,
                40.59934766174467
              ],
              [
                -73.77416455981803,
                40.59935488374407
              ],
              [
                -73.77415246595794,
                40.599371114714664
              ],
              [
                -73.77414785481132,
                40.59937929182412
              ],
              [
                -73.77414328371775,
                40.59939310621899
              ],
              [
                -73.77414097253451,
                40.5994089238521
              ],
              [
                -73.77414168902199,
                40.59945941703738
              ],
              [
                -73.77414082969473,
                40.59947584079815
              ],
              [
                -73.7741348041225,
                40.59950379033323
              ],
              [
                -73.77411412164395,
                40.59956539089598
              ],
              [
                -73.7741092441623,
                40.59959271243941
              ],
              [
                -73.77410924166621,
                40.599593450235304
              ],
              [
                -73.7741093214267,
                40.599594171774925
              ],
              [
                -73.77410973033992,
                40.599595505444974
              ],
              [
                -73.77411007173555,
                40.59959613686534
              ],
              [
                -73.77411049523876,
                40.599596731088006
              ],
              [
                -73.77411101198963,
                40.599597308573415
              ],
              [
                -73.77411161370193,
                40.599597849871834
              ],
              [
                -73.7741130859808,
                40.59959885241541
              ],
              [
                -73.77411490366404,
                40.59959975629281
              ],
              [
                -73.77411709754821,
                40.599600553858046
              ],
              [
                -73.77411967068737,
                40.59960125181844
              ],
              [
                -73.77413094827763,
                40.599602958662636
              ],
              [
                -73.77415934273652,
                40.59960503956458
              ],
              [
                -73.7741649638547,
                40.599605888426794
              ],
              [
                -73.77416922544775,
                40.59960696882382
              ],
              [
                -73.77417091211092,
                40.59960762047807
              ],
              [
                -73.77417231607093,
                40.59960835282884
              ],
              [
                -73.77417341161646,
                40.599609164652634
              ],
              [
                -73.77417384731481,
                40.59960959756784
              ],
              [
                -73.77417421236633,
                40.59961005832185
              ],
              [
                -73.77417450615069,
                40.59961053535357
              ],
              [
                -73.77417472840716,
                40.59961104089231
              ],
              [
                -73.77417488005402,
                40.599611563212804
              ],
              [
                -73.77417494940711,
                40.5996121128465
              ],
              [
                -73.77417488660656,
                40.599613264992406
              ],
              [
                -73.77417453950308,
                40.5996145159247
              ],
              [
                -73.77415219771076,
                40.59964505651564
              ],
              [
                -73.77413405495689,
                40.59967717338902
              ],
              [
                -73.77411822552256,
                40.59971688605384
              ],
              [
                -73.77410881041702,
                40.59975776603781
              ],
              [
                -73.77410594774378,
                40.59979921375171
              ],
              [
                -73.77410967950233,
                40.59984062127871
              ],
              [
                -73.77411995097057,
                40.59988138128995
              ],
              [
                -73.7741281796629,
                40.599907957105074
              ],
              [
                -73.77414111420349,
                40.59993342106675
              ],
              [
                -73.77415850996134,
                40.59995729156494
              ],
              [
                -73.77418396402193,
                40.59998249666927
              ],
              [
                -73.77421057441094,
                40.600001921249756
              ],
              [
                -73.77422291360617,
                40.60001584258894
              ],
              [
                -73.77423220250277,
                40.600031078476476
              ],
              [
                -73.77423820633749,
                40.60004724385151
              ],
              [
                -73.77424077337095,
                40.60006393016101
              ],
              [
                -73.77423983872315,
                40.600080715686474
              ],
              [
                -73.77423587883177,
                40.60008906257942
              ],
              [
                -73.77423379825738,
                40.60009779561279
              ],
              [
                -73.77423365512885,
                40.600106670797814
              ],
              [
                -73.77423545344563,
                40.60011544017397
              ],
              [
                -73.77423914296601,
                40.60012385873662
              ],
              [
                -73.77424462061073,
                40.600131691282364
              ],
              [
                -73.77425173334201,
                40.600138718980375
              ],
              [
                -73.77426028243998,
                40.6001447454859
              ],
              [
                -73.77426438292592,
                40.60014700647956
              ],
              [
                -73.7743125982149,
                40.60017550972069
              ],
              [
                -73.77435842277049,
                40.60020621890214
              ],
              [
                -73.77437018193261,
                40.60021536680437
              ],
              [
                -73.77438317080079,
                40.600223491049604
              ],
              [
                -73.77440366709074,
                40.60023317982313
              ],
              [
                -73.77441643984454,
                40.60024230431037
              ],
              [
                -73.7744270542206,
                40.60025291482401
              ],
              [
                -73.77442716608316,
                40.60025304839728
              ],
              [
                -73.77443502469914,
                40.600264373402396
              ],
              [
                -73.77444042075112,
                40.60027650679024
              ],
              [
                -73.77444333608143,
                40.60029027291195
              ],
              [
                -73.7744430835592,
                40.60030421589821
              ],
              [
                -73.77444798230009,
                40.6003166125852
              ],
              [
                -73.77445509082497,
                40.60032837109524
              ],
              [
                -73.77446427155026,
                40.60033926384417
              ],
              [
                -73.77447534678542,
                40.60034908000434
              ],
              [
                -73.77448414163506,
                40.60035522614953
              ],
              [
                -73.77449368157664,
                40.60036068859832
              ],
              [
                -73.77453337550058,
                40.600379747425514
              ],
              [
                -73.77454839430635,
                40.60038622280627
              ],
              [
                -73.77456177690955,
                40.60039452513893
              ],
              [
                -73.77457314474957,
                40.600404419571
              ],
              [
                -73.7745821762568,
                40.60041562621358
              ],
              [
                -73.77458861595133,
                40.60042782805819
              ],
              [
                -73.77459228166806,
                40.60044067994454
              ],
              [
                -73.7745930697114,
                40.60045381832405
              ],
              [
                -73.77458914133618,
                40.60047244293528
              ],
              [
                -73.77458859552834,
                40.6004962729264
              ],
              [
                -73.77459299222583,
                40.600519869765186
              ],
              [
                -73.77459931507539,
                40.60053671437618
              ],
              [
                -73.77461553550671,
                40.60056794358528
              ],
              [
                -73.77463690395614,
                40.60059731773966
              ],
              [
                -73.77466346038042,
                40.60062470311799
              ],
              [
                -73.77469604576548,
                40.60066190792818
              ],
              [
                -73.77472263330482,
                40.600701787628154
              ],
              [
                -73.77474285023148,
                40.600743783106346
              ],
              [
                -73.7747564130924,
                40.60078730558712
              ],
              [
                -73.77476313172295,
                40.60083174488498
              ],
              [
                -73.77464033629738,
                40.60083765634952
              ],
              [
                -73.77464113375633,
                40.60085079676324
              ],
              [
                -73.77476381099754,
                40.60084578537066
              ],
              [
                -73.77476252178141,
                40.60088079472821
              ],
              [
                -73.77475698466166,
                40.600915562651494
              ],
              [
                -73.77474724721814,
                40.600949790343265
              ],
              [
                -73.77462062960322,
                40.60095816519079
              ],
              [
                -73.77461755485552,
                40.600929398001256
              ],
              [
                -73.77459908825217,
                40.600930543706205
              ],
              [
                -73.77460096410347,
                40.60094808556445
              ],
              [
                -73.77452342169408,
                40.60095290104663
              ],
              [
                -73.77452504422452,
                40.60096806382276
              ],
              [
                -73.7744795424717,
                40.60097089006147
              ],
              [
                -73.77448133964737,
                40.600987703850805
              ],
              [
                -73.77460211334092,
                40.60098020375983
              ],
              [
                -73.77460398507942,
                40.60099772751659
              ],
              [
                -73.77461980021181,
                40.60099674347047
              ],
              [
                -73.77461657884193,
                40.600970651107374
              ],
              [
                -73.7747421272563,
                40.600963426437666
              ],
              [
                -73.77473418477746,
                40.60098153154964
              ],
              [
                -73.7747324443184,
                40.60098586816183
              ],
              [
                -73.77471611047916,
                40.601012320910534
              ],
              [
                -73.77471176740971,
                40.60102135313565
              ],
              [
                -73.77471054782121,
                40.60102570182557
              ],
              [
                -73.77471009679884,
                40.6010298065911
              ],
              [
                -73.77471039107347,
                40.60103372401187
              ],
              [
                -73.7747124639902,
                40.6010400982236
              ],
              [
                -73.77473248035847,
                40.60109114471695
              ],
              [
                -73.77475569154593,
                40.60113657488153
              ],
              [
                -73.77478343828261,
                40.60118050240467
              ],
              [
                -73.77481555470831,
                40.60122266468991
              ],
              [
                -73.77482792021071,
                40.60123529904385
              ],
              [
                -73.77485468998128,
                40.60126966224092
              ],
              [
                -73.77487438680038,
                40.60130669959068
              ],
              [
                -73.77488656404168,
                40.60134557129298
              ],
              [
                -73.77489094557998,
                40.60138539595331
              ],
              [
                -73.77488743205231,
                40.60142527056756
              ],
              [
                -73.77487610311192,
                40.601464290997676
              ],
              [
                -73.77485721562444,
                40.60150157247189
              ],
              [
                -73.77485245260944,
                40.60152530628267
              ],
              [
                -73.77485211519904,
                40.601537928152716
              ],
              [
                -73.77485327577887,
                40.60155052161248
              ],
              [
                -73.77485941843604,
                40.60157362382939
              ],
              [
                -73.77487057559526,
                40.601595608249866
              ],
              [
                -73.77490258024206,
                40.60161916532998
              ],
              [
                -73.77492950702354,
                40.60164616133265
              ],
              [
                -73.77495072828589,
                40.60167596699738
              ],
              [
                -73.77496574936545,
                40.60170788757113
              ],
              [
                -73.77497335885174,
                40.601736026847725
              ],
              [
                -73.77497513649519,
                40.601748179411864
              ],
              [
                -73.77511292814287,
                40.60172105484167
              ],
              [
                -73.77522740650352,
                40.60170227758096
              ],
              [
                -73.77524796667733,
                40.6016978608316
              ],
              [
                -73.77527009492584,
                40.601691275457775
              ],
              [
                -73.77529277155315,
                40.60168286457615
              ],
              [
                -73.77535990528,
                40.601657961023626
              ],
              [
                -73.77538628853988,
                40.60165052971064
              ],
              [
                -73.77541205144493,
                40.601648480912594
              ],
              [
                -73.77545258302268,
                40.6016500010345
              ],
              [
                -73.77551653577504,
                40.601657321201564
              ],
              [
                -73.77556183167896,
                40.601657598474205
              ],
              [
                -73.77559846587381,
                40.6016480528033
              ],
              [
                -73.77567846230677,
                40.60160655925803
              ],
              [
                -73.7757468338545,
                40.601561063642194
              ],
              [
                -73.77580714550358,
                40.601512453299506
              ],
              [
                -73.77590955359183,
                40.60145662273234
              ],
              [
                -73.77593904118956,
                40.60143986778643
              ],
              [
                -73.77596308986715,
                40.601424110591516
              ],
              [
                -73.77598284829236,
                40.60140875899457
              ],
              [
                -73.77599890906717,
                40.601393345907695
              ],
              [
                -73.77601141422332,
                40.601377728036205
              ],
              [
                -73.77601411201164,
                40.60136940338432
              ],
              [
                -73.77600848335044,
                40.601352931284566
              ],
              [
                -73.77600206164578,
                40.6013341075328
              ],
              [
                -73.77600256916033,
                40.60132342683074
              ],
              [
                -73.7760108211145,
                40.60131151999539
              ],
              [
                -73.7760333108812,
                40.60128149560171
              ],
              [
                -73.77607314799916,
                40.601236132985996
              ],
              [
                -73.7761000802084,
                40.601209322580054
              ],
              [
                -73.77615216857947,
                40.6011574829831
              ],
              [
                -73.77615664398309,
                40.601147603261595
              ],
              [
                -73.77615621270874,
                40.601121324723465
              ],
              [
                -73.77615613306465,
                40.601116758882455
              ],
              [
                -73.77616693234631,
                40.60110230820097
              ],
              [
                -73.77616879063572,
                40.60110161020604
              ],
              [
                -73.77619005997713,
                40.60109354620843
              ],
              [
                -73.77623171270285,
                40.60108474790965
              ],
              [
                -73.77624760401717,
                40.60107755781903
              ],
              [
                -73.7762692735167,
                40.6010558062395
              ],
              [
                -73.77628516667868,
                40.60103389246754
              ],
              [
                -73.77631290592502,
                40.600977852503604
              ],
              [
                -73.77631782727785,
                40.60094080502258
              ],
              [
                -73.77631885136725,
                40.60092064364756
              ],
              [
                -73.77649688161841,
                40.60063645142546
              ],
              [
                -73.77654724474655,
                40.60056641674832
              ],
              [
                -73.77659982572908,
                40.60049065852063
              ],
              [
                -73.77677517850812,
                40.600165126560384
              ],
              [
                -73.77693419349194,
                40.5999057502418
              ],
              [
                -73.77694612101355,
                40.59988281914574
              ],
              [
                -73.77694848707488,
                40.5998782761448
              ],
              [
                -73.77698813377805,
                40.59978709430834
              ],
              [
                -73.77701942427383,
                40.59973918407221
              ],
              [
                -73.77703664882237,
                40.599718252658896
              ],
              [
                -73.77706440746492,
                40.59969139973547
              ],
              [
                -73.7771302938322,
                40.59965359646175
              ],
              [
                -73.77714992395396,
                40.599637596074366
              ],
              [
                -73.77718611055549,
                40.599595143687075
              ],
              [
                -73.7771985062932,
                40.59957695925302
              ],
              [
                -73.7772951694435,
                40.59937579804465
              ],
              [
                -73.77730277625052,
                40.59935118307322
              ],
              [
                -73.77730957913344,
                40.599274157225544
              ],
              [
                -73.77731478794612,
                40.599246205440764
              ],
              [
                -73.77735188319646,
                40.59916508706545
              ],
              [
                -73.7773692598685,
                40.59893063243328
              ],
              [
                -73.77736943709169,
                40.598928237101994
              ],
              [
                -73.77737230109211,
                40.59891276307381
              ],
              [
                -73.7773860641275,
                40.598866970698296
              ],
              [
                -73.77740175443988,
                40.59883839137451
              ],
              [
                -73.77742174810759,
                40.59881240436143
              ],
              [
                -73.77747337927345,
                40.598761885753056
              ],
              [
                -73.77752065324304,
                40.59872484063875
              ],
              [
                -73.77762405113403,
                40.598661192682435
              ],
              [
                -73.77767796085797,
                40.598614676989925
              ],
              [
                -73.77773188259499,
                40.598568161295205
              ],
              [
                -73.77780489089353,
                40.59848408479449
              ],
              [
                -73.77781414448636,
                40.59847591701703
              ],
              [
                -73.77784156447136,
                40.59845818473893
              ],
              [
                -73.77786310256693,
                40.59844737564204
              ],
              [
                -73.7779399558411,
                40.59841494332945
              ],
              [
                -73.7779610831601,
                40.59840663564402
              ],
              [
                -73.77801915791676,
                40.59838660305958
              ],
              [
                -73.77803567464659,
                40.59837952098092
              ],
              [
                -73.77804964009295,
                40.59837210946752
              ],
              [
                -73.77806376669655,
                40.59836236641771
              ],
              [
                -73.77807747589547,
                40.598350118661216
              ],
              [
                -73.77809083914627,
                40.59833555631479
              ],
              [
                -73.77811000871459,
                40.59831192707847
              ],
              [
                -73.7781483232691,
                40.59825915581628
              ],
              [
                -73.7781547647767,
                40.598250282810874
              ],
              [
                -73.77817093945065,
                40.59823255622041
              ],
              [
                -73.7781889408424,
                40.59821559675375
              ],
              [
                -73.77820861784828,
                40.59819956997296
              ],
              [
                -73.77822973160431,
                40.59818466137418
              ],
              [
                -73.77824654067494,
                40.59817477018871
              ],
              [
                -73.7782659814658,
                40.59816546993
              ],
              [
                -73.77828844723504,
                40.598156543231674
              ],
              [
                -73.77831588651237,
                40.59814722153904
              ],
              [
                -73.77834122816843,
                40.598140211891916
              ],
              [
                -73.77840574320462,
                40.598126259795066
              ],
              [
                -73.77843466744606,
                40.59811849025201
              ],
              [
                -73.77846656618118,
                40.59810762897902
              ],
              [
                -73.77849136266016,
                40.59809713178735
              ],
              [
                -73.77851179227486,
                40.59808582353919
              ],
              [
                -73.7785281947315,
                40.59807346398388
              ],
              [
                -73.77853278398769,
                40.598068583306144
              ],
              [
                -73.77853670204476,
                40.59806270101494
              ],
              [
                -73.77854903824989,
                40.59803337676646
              ],
              [
                -73.77855757266263,
                40.59802139842022
              ],
              [
                -73.77858115374502,
                40.598000146308145
              ],
              [
                -73.77858816631479,
                40.5979944775467
              ],
              [
                -73.77860198346538,
                40.5979854808494
              ],
              [
                -73.77861593382974,
                40.5979786632984
              ],
              [
                -73.77863586418795,
                40.59797155202585
              ],
              [
                -73.77868535376176,
                40.59795736443183
              ],
              [
                -73.77871154174142,
                40.59794788778283
              ],
              [
                -73.77872496402216,
                40.59795521299959
              ],
              [
                -73.77873937571361,
                40.59796134961626
              ],
              [
                -73.7787432209162,
                40.59796352792929
              ],
              [
                -73.77874748621517,
                40.597965190882256
              ],
              [
                -73.77875205386624,
                40.59796629256877
              ],
              [
                -73.77875679777884,
                40.59796680257679
              ],
              [
                -73.77876068004736,
                40.59796677165174
              ],
              [
                -73.77876452167372,
                40.597966343462474
              ],
              [
                -73.77876825301163,
                40.597965525772075
              ],
              [
                -73.77899337992558,
                40.597786332755625
              ],
              [
                -73.77899545189929,
                40.59778491429944
              ],
              [
                -73.77899722000666,
                40.597783274179385
              ],
              [
                -73.77899883003234,
                40.59778115801704
              ],
              [
                -73.77899993434409,
                40.59777886112848
              ],
              [
                -73.77900049949349,
                40.597776453083824
              ],
              [
                -73.77900050836284,
                40.597774006819805
              ],
              [
                -73.77899996068378,
                40.59777159643082
              ],
              [
                -73.77899894756466,
                40.59776941986163
              ],
              [
                -73.77899748054092,
                40.59776739912648
              ],
              [
                -73.77899559921606,
                40.597765588777115
              ],
              [
                -73.7789933543786,
                40.59776403768567
              ],
              [
                -73.77898686516694,
                40.597753408638965
              ],
              [
                -73.7789828491783,
                40.597742091725465
              ],
              [
                -73.77898142408172,
                40.59773041853677
              ],
              [
                -73.77898263163226,
                40.59771873110344
              ],
              [
                -73.77900398445954,
                40.59768866657711
              ],
              [
                -73.77901338422856,
                40.59767570009545
              ],
              [
                -73.77902413723443,
                40.59766418484445
              ],
              [
                -73.77904364405249,
                40.59764939932355
              ],
              [
                -73.77906643551206,
                40.597638006057046
              ],
              [
                -73.77915495057023,
                40.59760103954861
              ],
              [
                -73.77931746547934,
                40.597540070048304
              ],
              [
                -73.77937174240402,
                40.59751761689244
              ],
              [
                -73.77944343306667,
                40.59748454291393
              ],
              [
                -73.7795467449914,
                40.5974328796455
              ],
              [
                -73.77959238264597,
                40.59741005749713
              ],
              [
                -73.77968112274064,
                40.5973724508844
              ],
              [
                -73.7797184031111,
                40.59735254828653
              ],
              [
                -73.77972417563883,
                40.59733937660602
              ],
              [
                -73.77972702901246,
                40.59729462584323
              ],
              [
                -73.77975178148083,
                40.597276130929465
              ],
              [
                -73.77979724995491,
                40.59725237288962
              ],
              [
                -73.77981453577354,
                40.59724086137078
              ],
              [
                -73.77988394639935,
                40.59717876744065
              ],
              [
                -73.77989338369284,
                40.59717221215326
              ],
              [
                -73.77993716845845,
                40.5971321284582
              ],
              [
                -73.77997415009615,
                40.597088242726805
              ],
              [
                -73.7800037665822,
                40.597041221923256
              ],
              [
                -73.78002556782963,
                40.59699178065659
              ],
              [
                -73.78003922252748,
                40.596940670319576
              ],
              [
                -73.78004452317522,
                40.59688866766952
              ],
              [
                -73.78004458246923,
                40.59688461205612
              ],
              [
                -73.7800476990189,
                40.596870985123076
              ],
              [
                -73.78005493695551,
                40.596861344908895
              ],
              [
                -73.78006730392137,
                40.59685824331077
              ],
              [
                -73.78008143556367,
                40.596871940612694
              ],
              [
                -73.78009116767264,
                40.596876416867374
              ],
              [
                -73.7801013698854,
                40.59687809309132
              ],
              [
                -73.78014537451874,
                40.596878474819675
              ],
              [
                -73.78015594130338,
                40.596880565702286
              ],
              [
                -73.78016314495001,
                40.59688520782647
              ],
              [
                -73.78016845573181,
                40.596897627246925
              ],
              [
                -73.78017065145609,
                40.59691234692223
              ],
              [
                -73.78017115365934,
                40.59692870146344
              ],
              [
                -73.78015983766755,
                40.596989014470054
              ],
              [
                -73.78015037078754,
                40.59705837242387
              ],
              [
                -73.78014410214381,
                40.597146756980955
              ],
              [
                -73.78014720242385,
                40.59716323342815
              ],
              [
                -73.7801521861522,
                40.5971778667985
              ],
              [
                -73.78016742223146,
                40.59720085941009
              ],
              [
                -73.78018334426079,
                40.597212569703295
              ],
              [
                -73.7802367475551,
                40.59723281645073
              ],
              [
                -73.78036553370627,
                40.597273739041945
              ],
              [
                -73.78039361984408,
                40.5972845090305
              ],
              [
                -73.78042342543422,
                40.59729213503604
              ],
              [
                -73.78045026422002,
                40.59729608747636
              ],
              [
                -73.78047753762309,
                40.597297486451694
              ],
              [
                -73.78049749982736,
                40.597296880877465
              ],
              [
                -73.78051730893493,
                40.59729490489389
              ],
              [
                -73.78054471043457,
                40.59729757008672
              ],
              [
                -73.78057109963152,
                40.5973037943738
              ],
              [
                -73.7805956871336,
                40.59731339156522
              ],
              [
                -73.78061773744298,
                40.597326074575925
              ],
              [
                -73.78093054006914,
                40.59748733362211
              ],
              [
                -73.78110523555996,
                40.597598484223674
              ],
              [
                -73.7813297399812,
                40.597753037446914
              ],
              [
                -73.78134197798609,
                40.59777887839914
              ],
              [
                -73.78134045293935,
                40.597912693023375
              ],
              [
                -73.781358744408,
                40.59796937128958
              ],
              [
                -73.7813770762337,
                40.5979850038498
              ],
              [
                -73.78138790044059,
                40.59799123811753
              ],
              [
                -73.78141092338406,
                40.59799941313285
              ],
              [
                -73.78141927498908,
                40.597999546456386
              ],
              [
                -73.7814425498352,
                40.597991935454154
              ],
              [
                -73.78145229184497,
                40.5979899727752
              ],
              [
                -73.78149557370479,
                40.59801681040708
              ],
              [
                -73.78151695888268,
                40.598041560857666
              ],
              [
                -73.78159013310601,
                40.59813269856015
              ],
              [
                -73.78178262043288,
                40.59830039204037
              ],
              [
                -73.78180869810592,
                40.59832117980023
              ],
              [
                -73.78183226542883,
                40.59834364177731
              ],
              [
                -73.7818572569845,
                40.59837293018506
              ],
              [
                -73.78187788377919,
                40.59840391984822
              ],
              [
                -73.78189405690782,
                40.59843640959525
              ],
              [
                -73.78190559187668,
                40.59847002881389
              ],
              [
                -73.78193601357016,
                40.5985311442433
              ],
              [
                -73.7819913320746,
                40.59868213228944
              ],
              [
                -73.78201130686442,
                40.59881694294629
              ],
              [
                -73.78200967919354,
                40.59886215896594
              ],
              [
                -73.78200805391967,
                40.598907313339055
              ],
              [
                -73.78200218336956,
                40.59894252559711
              ],
              [
                -73.78199231614101,
                40.59897721506158
              ],
              [
                -73.7819706785217,
                40.59902678668573
              ],
              [
                -73.78194090074186,
                40.599073841775606
              ],
              [
                -73.78190681007644,
                40.59911416534232
              ],
              [
                -73.78186674066829,
                40.599151157047686
              ],
              [
                -73.7818212474255,
                40.599184304597074
              ],
              [
                -73.78177096037318,
                40.59921314893263
              ],
              [
                -73.78171657592806,
                40.599237290590516
              ],
              [
                -73.78165884725443,
                40.59925639523394
              ],
              [
                -73.78165622085076,
                40.59925637265338
              ],
              [
                -73.78163488736332,
                40.59926259086021
              ],
              [
                -73.78160801552015,
                40.59928255054644
              ],
              [
                -73.78158957195356,
                40.59929725676646
              ],
              [
                -73.78156560000286,
                40.599319426644236
              ],
              [
                -73.78154063755586,
                40.59935143783998
              ],
              [
                -73.78152003742214,
                40.59940797847333
              ],
              [
                -73.78147774223326,
                40.59948736072948
              ],
              [
                -73.78143367080311,
                40.59954626997921
              ],
              [
                -73.78137308926598,
                40.599591973774274
              ],
              [
                -73.78131649934976,
                40.59963140779638
              ],
              [
                -73.78121212475874,
                40.59972363005979
              ],
              [
                -73.78116628337473,
                40.59977094663728
              ],
              [
                -73.78110356332841,
                40.59981658255662
              ],
              [
                -73.78101073372724,
                40.59986097311826
              ],
              [
                -73.78087073851444,
                40.599916908449245
              ],
              [
                -73.78081083231571,
                40.599947754024484
              ],
              [
                -73.7807552715078,
                40.600004183271594
              ],
              [
                -73.7807327045796,
                40.60005500205488
              ],
              [
                -73.78073981985993,
                40.60011544960967
              ],
              [
                -73.78072266528143,
                40.60016579288055
              ],
              [
                -73.78069401326346,
                40.60021683274359
              ],
              [
                -73.78061842937426,
                40.600308551696564
              ],
              [
                -73.78042062181392,
                40.60050748412534
              ],
              [
                -73.78031928742601,
                40.60060939459747
              ],
              [
                -73.78025665342628,
                40.600650374903964
              ],
              [
                -73.78017210547576,
                40.60069783284525
              ],
              [
                -73.78010900085108,
                40.60072742903375
              ],
              [
                -73.7800740054335,
                40.6007383945677
              ],
              [
                -73.78000237945831,
                40.60075107131857
              ],
              [
                -73.77996753324919,
                40.600752499977816
              ],
              [
                -73.77993422228535,
                40.600750598396125
              ],
              [
                -73.77990294207429,
                40.600745406726006
              ],
              [
                -73.77986876620307,
                40.60073691251733
              ],
              [
                -73.77983460397324,
                40.60072841664939
              ],
              [
                -73.77968480144577,
                40.60068308620623
              ],
              [
                -73.77958486504491,
                40.60064998874053
              ],
              [
                -73.77952429814611,
                40.60062993485808
              ],
              [
                -73.7794547259279,
                40.60060761290144
              ],
              [
                -73.77942452180807,
                40.60059833292931
              ],
              [
                -73.77936988436021,
                40.60058293728321
              ],
              [
                -73.77930686918678,
                40.60056730924248
              ],
              [
                -73.77924725759343,
                40.60055505555379
              ],
              [
                -73.77918989225739,
                40.600545940797375
              ],
              [
                -73.77913417165485,
                40.60053988072603
              ],
              [
                -73.77910890171088,
                40.60053958019397
              ],
              [
                -73.77898728659612,
                40.600520138265104
              ],
              [
                -73.7788669679822,
                40.60049874430104
              ],
              [
                -73.77871486318202,
                40.600467609314826
              ],
              [
                -73.77850756206627,
                40.60041861698767
              ],
              [
                -73.77847044329695,
                40.60040740589283
              ],
              [
                -73.77838774899787,
                40.60037645802565
              ],
              [
                -73.77836673971015,
                40.60037015876993
              ],
              [
                -73.77834715405771,
                40.60036563656281
              ],
              [
                -73.77830243560072,
                40.600358985823554
              ],
              [
                -73.77826431381608,
                40.600357686965275
              ],
              [
                -73.77823203524598,
                40.600361739713335
              ],
              [
                -73.77821809226897,
                40.60036577456362
              ],
              [
                -73.77820562116898,
                40.60037115450054
              ],
              [
                -73.77818086978304,
                40.600385541085785
              ],
              [
                -73.77815821309493,
                40.600402085303024
              ],
              [
                -73.7781382332667,
                40.60042034482642
              ],
              [
                -73.77812145153689,
                40.60043988994484
              ],
              [
                -73.77809077931204,
                40.600482849493716
              ],
              [
                -73.77807640267658,
                40.6005141866368
              ],
              [
                -73.77807471168266,
                40.60053260761391
              ],
              [
                -73.77808041391145,
                40.60057627752593
              ],
              [
                -73.77808016555345,
                40.60065470270799
              ],
              [
                -73.77807854866104,
                40.60067913110666
              ],
              [
                -73.77807565215485,
                40.600700774366665
              ],
              [
                -73.77808641490137,
                40.600715034263494
              ],
              [
                -73.77809991955218,
                40.60072786733309
              ],
              [
                -73.77811584657964,
                40.60073896993631
              ],
              [
                -73.77813381914017,
                40.60074807937766
              ],
              [
                -73.77815341199033,
                40.6007549801212
              ],
              [
                -73.77835992974103,
                40.600820238368264
              ],
              [
                -73.77836257860957,
                40.600821203219844
              ],
              [
                -73.77836497025906,
                40.6008225009767
              ],
              [
                -73.77836703339639,
                40.600824092953395
              ],
              [
                -73.77836870652084,
                40.600825931694644
              ],
              [
                -73.77836993975761,
                40.600827962388905
              ],
              [
                -73.77837069634516,
                40.600830124502764
              ],
              [
                -73.7783709537299,
                40.60083235358523
              ],
              [
                -73.77837070423969,
                40.60083458318918
              ],
              [
                -73.77836995531133,
                40.60083674685171
              ],
              [
                -73.77836872926964,
                40.60083878007573
              ],
              [
                -73.77836706266203,
                40.60084062225229
              ],
              [
                -73.77836500516918,
                40.60084221846766
              ],
              [
                -73.77836261812291,
                40.60084352113982
              ],
              [
                -73.77835997267935,
                40.60084449143692
              ],
              [
                -73.7783571476974,
                40.600845100435365
              ],
              [
                -73.778167453733,
                40.60080433365424
              ],
              [
                -73.77815142794938,
                40.600799345720645
              ],
              [
                -73.77813456857402,
                40.60079635347585
              ],
              [
                -73.77811730041341,
                40.60079543231548
              ],
              [
                -73.77810005857332,
                40.60079660544993
              ],
              [
                -73.77808327749685,
                40.6007998433199
              ],
              [
                -73.77806738001723,
                40.60080506434063
              ],
              [
                -73.77805276670358,
                40.600812136957984
              ],
              [
                -73.7780398057688,
                40.600820882962886
              ],
              [
                -73.77802882379062,
                40.600831081982044
              ],
              [
                -73.77796667277282,
                40.60086286097901
              ],
              [
                -73.77790720295133,
                40.60089749266477
              ],
              [
                -73.77785063938293,
                40.600934845982415
              ],
              [
                -73.7778030236484,
                40.600966552196695
              ],
              [
                -73.77775981349949,
                40.601001733910614
              ],
              [
                -73.77772144347017,
                40.601040037332275
              ],
              [
                -73.77770514895494,
                40.601058176910854
              ],
              [
                -73.77769101593326,
                40.6010773407748
              ],
              [
                -73.77767142267311,
                40.6011137956808
              ],
              [
                -73.77765961418032,
                40.60115214903176
              ],
              [
                -73.77765587911382,
                40.601189796106425
              ],
              [
                -73.77764822536692,
                40.60120835274637
              ],
              [
                -73.77763689332878,
                40.601225781957936
              ],
              [
                -73.77762215646955,
                40.60124166312658
              ],
              [
                -73.7776043704283,
                40.60125561299578
              ],
              [
                -73.77757208129815,
                40.60126253216933
              ],
              [
                -73.77754181531014,
                40.601273550340785
              ],
              [
                -73.77751446064458,
                40.60128834417351
              ],
              [
                -73.77749082004738,
                40.601306479530905
              ],
              [
                -73.77747583241022,
                40.6013220963977
              ],
              [
                -73.77746362163673,
                40.601339060011156
              ],
              [
                -73.77745439299349,
                40.60135708521122
              ],
              [
                -73.77742563726459,
                40.601391849055034
              ],
              [
                -73.77740272820665,
                40.60141668635713
              ],
              [
                -73.77736657905773,
                40.60145068432779
              ],
              [
                -73.7773363202503,
                40.601472669761044
              ],
              [
                -73.77702968356833,
                40.601666182988
              ],
              [
                -73.77699505209678,
                40.60169160073948
              ],
              [
                -73.77696014499182,
                40.601721881833875
              ],
              [
                -73.77692438118366,
                40.60175726487803
              ],
              [
                -73.77687575407889,
                40.60180963523922
              ],
              [
                -73.77684680922381,
                40.60184460932285
              ],
              [
                -73.77678810455096,
                40.60192490385658
              ],
              [
                -73.77676002871895,
                40.60195776286881
              ],
              [
                -73.77672850763598,
                40.601989274608854
              ],
              [
                -73.77670556145134,
                40.60201087837711
              ],
              [
                -73.77667042929866,
                40.60204119395947
              ],
              [
                -73.77665458669364,
                40.602054859726444
              ],
              [
                -73.77663529645488,
                40.60207150952985
              ],
              [
                -73.77660496626646,
                40.602093125731294
              ],
              [
                -73.77655976662355,
                40.602120215862925
              ],
              [
                -73.7765455725766,
                40.602128725744386
              ],
              [
                -73.77652982761255,
                40.60213815971761
              ],
              [
                -73.77651289165587,
                40.60215025609919
              ],
              [
                -73.77649883959204,
                40.602162178662304
              ],
              [
                -73.77648988688918,
                40.60217172568651
              ],
              [
                -73.7764877559585,
                40.60217697126214
              ],
              [
                -73.77648683139624,
                40.60218241712754
              ],
              [
                -73.77648714035558,
                40.60218790334617
              ],
              [
                -73.77648867376318,
                40.60219326879621
              ],
              [
                -73.77649138658535,
                40.602198355902964
              ],
              [
                -73.77649519915138,
                40.60220301526591
              ],
              [
                -73.77649999949183,
                40.60220711004682
              ],
              [
                -73.7765056466287,
                40.60221051998805
              ],
              [
                -73.77651197471418,
                40.60221314494505
              ],
              [
                -73.77651851989935,
                40.602214854985114
              ],
              [
                -73.77652534443668,
                40.6022157236029
              ],
              [
                -73.77653226347098,
                40.602215727270355
              ],
              [
                -73.77653908958678,
                40.602214865887944
              ],
              [
                -73.77654563788592,
                40.602213162788054
              ],
              [
                -73.77655173099527,
                40.602210664102216
              ],
              [
                -73.7765572038716,
                40.6022074375122
              ],
              [
                -73.77656190827142,
                40.60220357041623
              ],
              [
                -73.7765657167677,
                40.602199167561935
              ],
              [
                -73.77656852620001,
                40.602194348208926
              ],
              [
                -73.77657026047001,
                40.602189242898376
              ],
              [
                -73.77658070986051,
                40.60218931649889
              ],
              [
                -73.77659100632879,
                40.602190676080745
              ],
              [
                -73.77660087925568,
                40.60219328591058
              ],
              [
                -73.77661006915426,
                40.602197077395
              ],
              [
                -73.77661833448919,
                40.60220195088372
              ],
              [
                -73.77662545802484,
                40.60220777828807
              ],
              [
                -73.77660893153028,
                40.60226367841057
              ],
              [
                -73.77658406174028,
                40.60231775407642
              ],
              [
                -73.77655507698655,
                40.60236390233713
              ],
              [
                -73.77652001305698,
                40.60240753106775
              ],
              [
                -73.77647924254995,
                40.60244817664343
              ],
              [
                -73.77644696287877,
                40.60247506787019
              ],
              [
                -73.77643286433131,
                40.602488558879
              ],
              [
                -73.77641617364748,
                40.602500205458014
              ],
              [
                -73.77639730070666,
                40.60250972159693
              ],
              [
                -73.77637485205688,
                40.60251737804779
              ],
              [
                -73.77635102434505,
                40.602522019929225
              ],
              [
                -73.77632650874142,
                40.60252351259419
              ],
              [
                -73.77630201637034,
                40.60252181274498
              ],
              [
                -73.77627825768181,
                40.6025169696893
              ],
              [
                -73.77625592184398,
                40.60250912390944
              ],
              [
                -73.77623565675155,
                40.60249850298791
              ],
              [
                -73.77623203834975,
                40.60249616007703
              ],
              [
                -73.77622492564387,
                40.602492615875995
              ],
              [
                -73.77621025347722,
                40.602488581003094
              ],
              [
                -73.77618574390479,
                40.60248659596206
              ],
              [
                -73.77617290098054,
                40.602486868483275
              ],
              [
                -73.77616006838659,
                40.602487141023076
              ],
              [
                -73.77613377079997,
                40.602490187591954
              ],
              [
                -73.77610751118806,
                40.60249567379552
              ],
              [
                -73.77608197645206,
                40.60250345940946
              ],
              [
                -73.7760579355512,
                40.60251331742217
              ],
              [
                -73.77603607413985,
                40.60252495565892
              ],
              [
                -73.77601699635197,
                40.60253807491769
              ],
              [
                -73.77600933049506,
                40.60254459698402
              ],
              [
                -73.7759763667971,
                40.60257266531682
              ],
              [
                -73.77593843827076,
                40.602608711941684
              ],
              [
                -73.77590052116636,
                40.60264474869203
              ],
              [
                -73.7758674827302,
                40.60267423096544
              ],
              [
                -73.77577486968538,
                40.60274674980188
              ],
              [
                -73.77572593568112,
                40.60278406218195
              ],
              [
                -73.77568814362705,
                40.60281474067054
              ],
              [
                -73.77564545195459,
                40.60285830634877
              ],
              [
                -73.77561158536422,
                40.60290883133475
              ],
              [
                -73.77559843811991,
                40.60293605670616
              ],
              [
                -73.77558060876527,
                40.602992546224506
              ],
              [
                -73.7755761648483,
                40.60302104891316
              ],
              [
                -73.77557740048579,
                40.60302944439333
              ],
              [
                -73.7755806102369,
                40.6030342859014
              ],
              [
                -73.77558293018475,
                40.60303646044293
              ],
              [
                -73.77558895434596,
                40.603040299587875
              ],
              [
                -73.77559683616947,
                40.603043449122275
              ],
              [
                -73.77560642181831,
                40.603045836539806
              ],
              [
                -73.7756175817329,
                40.603047425065654
              ],
              [
                -73.77563285731395,
                40.603048067041364
              ],
              [
                -73.77565936175341,
                40.60304698393531
              ],
              [
                -73.7756891989242,
                40.603042206430636
              ],
              [
                -73.7757254638209,
                40.603030011857385
              ],
              [
                -73.77575502513491,
                40.603005665330684
              ],
              [
                -73.77577095277304,
                40.60298074286777
              ],
              [
                -73.77578265111752,
                40.60294858174001
              ],
              [
                -73.77579852667681,
                40.602875669469306
              ],
              [
                -73.77580804561218,
                40.60284516045267
              ],
              [
                -73.77581534137109,
                40.60282954182372
              ],
              [
                -73.77583413094258,
                40.6028038415657
              ],
              [
                -73.77584590981601,
                40.60279337333101
              ],
              [
                -73.77587304594192,
                40.60277655065808
              ],
              [
                -73.7759101547336,
                40.602759629315685
              ],
              [
                -73.7759507985543,
                40.602745525162575
              ],
              [
                -73.77600401563208,
                40.602734228503614
              ],
              [
                -73.77602911188818,
                40.60273393498305
              ],
              [
                -73.77604574101473,
                40.60273907369199
              ],
              [
                -73.77605391761065,
                40.60275289440223
              ],
              [
                -73.77603171304683,
                40.602933964910825
              ],
              [
                -73.77602601326558,
                40.60302704114226
              ],
              [
                -73.77602243778801,
                40.603060840314285
              ],
              [
                -73.77601557930303,
                40.60309098462737
              ],
              [
                -73.77600346476142,
                40.603123794982096
              ],
              [
                -73.77598887611843,
                40.60315477125693
              ],
              [
                -73.77594998950396,
                40.60322961939753
              ],
              [
                -73.77593307894469,
                40.60326907366644
              ],
              [
                -73.77592245363391,
                40.60330904545656
              ],
              [
                -73.7759111492324,
                40.60338546065215
              ],
              [
                -73.77590501110117,
                40.60341556196711
              ],
              [
                -73.7758950323549,
                40.60344572766289
              ],
              [
                -73.7758814058216,
                40.603471681753405
              ],
              [
                -73.7758547544727,
                40.60350560663005
              ],
              [
                -73.77577763204934,
                40.603584783314574
              ],
              [
                -73.77570186438697,
                40.60368238783436
              ],
              [
                -73.77567242794629,
                40.603725995806066
              ],
              [
                -73.7755906116769,
                40.603876161418995
              ],
              [
                -73.77555704898474,
                40.603930928325845
              ],
              [
                -73.77548575925312,
                40.60403914958894
              ],
              [
                -73.77546430298095,
                40.604070401200374
              ],
              [
                -73.77544367157964,
                40.60409578217126
              ],
              [
                -73.77540696318981,
                40.60413383850895
              ],
              [
                -73.7753760831684,
                40.60415730886782
              ],
              [
                -73.77536062624208,
                40.60416508654277
              ],
              [
                -73.77533426151207,
                40.60416991581599
              ],
              [
                -73.77530321052191,
                40.60416664976927
              ],
              [
                -73.77528421727511,
                40.60416158741617
              ],
              [
                -73.77526249036806,
                40.604153962709965
              ],
              [
                -73.77518662764915,
                40.60412173744511
              ],
              [
                -73.77515729231766,
                40.60411055860528
              ],
              [
                -73.77512871591884,
                40.60410233529263
              ],
              [
                -73.77510340854614,
                40.60409839473083
              ],
              [
                -73.77506714247414,
                40.604096378740515
              ],
              [
                -73.77503248889928,
                40.60409612210817
              ],
              [
                -73.77499969776939,
                40.60409761527279
              ],
              [
                -73.77496888359173,
                40.60410086080548
              ],
              [
                -73.77491373899659,
                40.60411251323238
              ],
              [
                -73.77487848447853,
                40.60412594254679
              ],
              [
                -73.77481959506719,
                40.604161370768445
              ],
              [
                -73.77477178552539,
                40.6042015582438
              ],
              [
                -73.77473132437919,
                40.60424535244025
              ],
              [
                -73.77470889679802,
                40.60427758356298
              ],
              [
                -73.77469363854065,
                40.60430696481727
              ],
              [
                -73.77468798964632,
                40.60432464838919
              ],
              [
                -73.77468662049279,
                40.604355776729086
              ],
              [
                -73.77469424733953,
                40.60437498337118
              ],
              [
                -73.77470340449662,
                40.60438571701909
              ],
              [
                -73.77472318614407,
                40.604398615200815
              ],
              [
                -73.77475403318755,
                40.604409778518
              ],
              [
                -73.77479068451454,
                40.60441700921838
              ],
              [
                -73.77483359721172,
                40.60442053418931
              ],
              [
                -73.77489092243013,
                40.604421042858455
              ],
              [
                -73.77492667490617,
                40.60441799699597
              ],
              [
                -73.77502292796396,
                40.60439841896688
              ],
              [
                -73.77505358255998,
                40.60439319190192
              ],
              [
                -73.77508384952773,
                40.60439080168692
              ],
              [
                -73.77511128119933,
                40.60439207129643
              ],
              [
                -73.775131682203,
                40.60439672196393
              ],
              [
                -73.77514902078904,
                40.60440550964398
              ],
              [
                -73.77516723801882,
                40.604423608828384
              ],
              [
                -73.77517968909105,
                40.604448821765295
              ],
              [
                -73.77518711261325,
                40.60450025631843
              ],
              [
                -73.77518635992388,
                40.60452057903636
              ],
              [
                -73.77517990205578,
                40.60456443168788
              ],
              [
                -73.77517084548859,
                40.60459801203556
              ],
              [
                -73.77515942093704,
                40.60462937267351
              ],
              [
                -73.77495922863096,
                40.60507968442979
              ],
              [
                -73.77490819791973,
                40.605195032667474
              ],
              [
                -73.77489825231649,
                40.60521900225543
              ],
              [
                -73.77488547725292,
                40.605257988009846
              ],
              [
                -73.7748780089713,
                40.60529359821665
              ],
              [
                -73.77487577051181,
                40.605326625978776
              ],
              [
                -73.77487934789126,
                40.60539742312147
              ],
              [
                -73.77489353184063,
                40.60550456798869
              ],
              [
                -73.77490728653336,
                40.60556725334269
              ],
              [
                -73.77491198709524,
                40.60559928566354
              ],
              [
                -73.77491282982935,
                40.605629607490506
              ],
              [
                -73.77490994287606,
                40.605662327067925
              ],
              [
                -73.77490332458554,
                40.60569812774549
              ],
              [
                -73.77489782948433,
                40.60571927010757
              ],
              [
                -73.7748923469087,
                40.605740412493844
              ],
              [
                -73.77488097814064,
                40.60576901801865
              ],
              [
                -73.77484871856116,
                40.605829434351215
              ],
              [
                -73.77483934867541,
                40.605853946348745
              ],
              [
                -73.77483419700594,
                40.60588580675087
              ],
              [
                -73.77483262353243,
                40.60595621501561
              ],
              [
                -73.77482760366993,
                40.60598385962577
              ],
              [
                -73.77481362381549,
                40.60602657104517
              ],
              [
                -73.77477666357314,
                40.606124918069874
              ],
              [
                -73.77476161442432,
                40.606169474065844
              ],
              [
                -73.77474541830286,
                40.60623500033818
              ],
              [
                -73.77473691648495,
                40.606272048423904
              ],
              [
                -73.77471597176782,
                40.606330001256225
              ],
              [
                -73.77468845087068,
                40.60637623456068
              ],
              [
                -73.77460443182908,
                40.606477260875565
              ],
              [
                -73.77458447675636,
                40.606525967320735
              ],
              [
                -73.77460149071791,
                40.606596151857886
              ],
              [
                -73.77462647119515,
                40.606742193659635
              ],
              [
                -73.77462803843726,
                40.60677873175097
              ],
              [
                -73.77462489159208,
                40.60681135146317
              ],
              [
                -73.77458166277762,
                40.60693211666177
              ],
              [
                -73.77454551613688,
                40.607122102537275
              ],
              [
                -73.77459009469509,
                40.60729765709539
              ],
              [
                -73.77462135640792,
                40.60734050109725
              ],
              [
                -73.77463538087116,
                40.607351047276005
              ],
              [
                -73.77467746725006,
                40.60737949623219
              ],
              [
                -73.77468903799695,
                40.60738910981529
              ],
              [
                -73.77470197610445,
                40.60740331756046
              ],
              [
                -73.77471733297283,
                40.607421853648106
              ],
              [
                -73.77472758702018,
                40.607436553022005
              ],
              [
                -73.77473443925516,
                40.60745064999258
              ],
              [
                -73.77473739669318,
                40.60746392965905
              ],
              [
                -73.77473660705792,
                40.60748062377299
              ],
              [
                -73.7747299843843,
                40.60751034628586
              ],
              [
                -73.77472723631891,
                40.60753006231274
              ],
              [
                -73.77472761598476,
                40.607550504679345
              ],
              [
                -73.7747310501998,
                40.60757961682421
              ],
              [
                -73.77473738535232,
                40.60760709537761
              ],
              [
                -73.77474669742094,
                40.6076314007238
              ],
              [
                -73.77476028131471,
                40.60765729876773
              ],
              [
                -73.77480631263747,
                40.60772449611469
              ],
              [
                -73.77482533208136,
                40.60774691180895
              ],
              [
                -73.77487268409385,
                40.607794191613536
              ],
              [
                -73.7748901347523,
                40.607815757697146
              ],
              [
                -73.77489995781174,
                40.60783209431494
              ],
              [
                -73.77490855452615,
                40.607850905287044
              ],
              [
                -73.77492675098519,
                40.60790717808122
              ],
              [
                -73.77492977398721,
                40.60792156624607
              ],
              [
                -73.77493055726652,
                40.60793791146084
              ],
              [
                -73.77492519696118,
                40.60800006414668
              ],
              [
                -73.7749269655384,
                40.60802584136212
              ],
              [
                -73.77493177205682,
                40.60804058265339
              ],
              [
                -73.77494598484249,
                40.608065420270584
              ],
              [
                -73.77497445618741,
                40.60809852507354
              ],
              [
                -73.77500343026048,
                40.60812609299652
              ],
              [
                -73.77504814770323,
                40.60816652365163
              ],
              [
                -73.77507352134249,
                40.60819313914055
              ],
              [
                -73.77512849738142,
                40.60826077770732
              ],
              [
                -73.77530770397057,
                40.60848724029768
              ],
              [
                -73.7753380915165,
                40.60851959219095
              ],
              [
                -73.77537471211414,
                40.60855415391018
              ],
              [
                -73.77549750814698,
                40.608658287943236
              ],
              [
                -73.7755372791863,
                40.608693621540894
              ],
              [
                -73.77557032764808,
                40.608725905692886
              ],
              [
                -73.77559808590681,
                40.608756632538984
              ],
              [
                -73.77562418082805,
                40.60879036278403
              ],
              [
                -73.77568626097025,
                40.60887880787438
              ],
              [
                -73.7757061698235,
                40.6089033676901
              ],
              [
                -73.77572596269108,
                40.60892450565263
              ],
              [
                -73.7757849079194,
                40.608982344966435
              ],
              [
                -73.77593092580906,
                40.60910696494236
              ],
              [
                -73.77596856303008,
                40.609141401631035
              ],
              [
                -73.77600550834056,
                40.6091811958934
              ],
              [
                -73.77600650791886,
                40.60918255700851
              ],
              [
                -73.77600724708519,
                40.609184026342746
              ],
              [
                -73.77600774966821,
                40.609185577640446
              ],
              [
                -73.77600799341488,
                40.60918722610351
              ],
              [
                -73.77600798764608,
                40.60918894544782
              ],
              [
                -73.77600772152827,
                40.60919075424813
              ],
              [
                -73.77600645602091,
                40.60919455235683
              ],
              [
                -73.77600417347118,
                40.60919859089884
              ],
              [
                -73.77600096931879,
                40.609202727659834
              ],
              [
                -73.7759969268967,
                40.60920682542786
              ],
              [
                -73.77599218651524,
                40.609210734202485
              ],
              [
                -73.7759813765442,
                40.60921742135167
              ],
              [
                -73.77597575572115,
                40.60921990491123
              ],
              [
                -73.77596761840643,
                40.60922224903924
              ],
              [
                -73.77596253397127,
                40.609223022497
              ],
              [
                -73.77596014736233,
                40.609223089212506
              ],
              [
                -73.77595575350477,
                40.60922264858931
              ],
              [
                -73.77595185825965,
                40.609221470635255
              ],
              [
                -73.77591974593487,
                40.60920604540948
              ],
              [
                -73.77590421500723,
                40.60919690236114
              ],
              [
                -73.77587386659512,
                40.609177274397666
              ],
              [
                -73.7758451335811,
                40.609155119377945
              ],
              [
                -73.77583121674111,
                40.60914344826377
              ],
              [
                -73.77574188419322,
                40.60906012892616
              ],
              [
                -73.77567326241287,
                40.60900202745268
              ],
              [
                -73.77565785343113,
                40.60899155124125
              ],
              [
                -73.77561965324978,
                40.60897033293901
              ],
              [
                -73.77560428285237,
                40.60895891142636
              ],
              [
                -73.7755866554815,
                40.60893724626726
              ],
              [
                -73.7755665331226,
                40.608898394404186
              ],
              [
                -73.77555991525666,
                40.60888118139149
              ],
              [
                -73.7755486543443,
                40.60883559237239
              ],
              [
                -73.77554297676075,
                40.60882325328887
              ],
              [
                -73.77553555532769,
                40.60881294578076
              ],
              [
                -73.77552127293168,
                40.60879804159957
              ],
              [
                -73.77550407441575,
                40.60878228469155
              ],
              [
                -73.77544786519,
                40.60873668961531
              ],
              [
                -73.7753648170795,
                40.60867131076614
              ],
              [
                -73.77531972207748,
                40.608634157382234
              ],
              [
                -73.77528731484858,
                40.60860505177669
              ],
              [
                -73.77527385543803,
                40.60859129323246
              ],
              [
                -73.77526040812862,
                40.608577533035074
              ],
              [
                -73.77523851986172,
                40.60855094300022
              ],
              [
                -73.7750648720794,
                40.608312225338544
              ],
              [
                -73.77505485789824,
                40.608299995181326
              ],
              [
                -73.77504484438211,
                40.60828776452203
              ],
              [
                -73.77499455012945,
                40.60823303083537
              ],
              [
                -73.7749733078393,
                40.60820776549682
              ],
              [
                -73.77493877830533,
                40.60815984441241
              ],
              [
                -73.77488854943083,
                40.60808251659783
              ],
              [
                -73.77484886546486,
                40.60802483315969
              ],
              [
                -73.77484441731436,
                40.60801594437449
              ],
              [
                -73.77483488501822,
                40.60799049571916
              ],
              [
                -73.77482802473858,
                40.607918997752186
              ],
              [
                -73.77482689131568,
                40.60789411487932
              ],
              [
                -73.77482677634974,
                40.60787925595491
              ],
              [
                -73.77482661058232,
                40.60785840825176
              ],
              [
                -73.77482414248267,
                40.60783689898707
              ],
              [
                -73.77481299320901,
                40.60782149692494
              ],
              [
                -73.77478645925184,
                40.60780261444578
              ],
              [
                -73.7747638620594,
                40.60778658499741
              ],
              [
                -73.77474504280364,
                40.60776464731214
              ],
              [
                -73.77473181602394,
                40.60774841223412
              ],
              [
                -73.77470777471912,
                40.607715756823175
              ],
              [
                -73.77466711273037,
                40.60765019097922
              ],
              [
                -73.77464611907904,
                40.60760045904279
              ],
              [
                -73.77463415783882,
                40.60754541435735
              ],
              [
                -73.77464131316107,
                40.60748378722352
              ],
              [
                -73.77464280843147,
                40.607468004179346
              ],
              [
                -73.77464146767247,
                40.607452080042975
              ],
              [
                -73.7746394828631,
                40.60744494408254
              ],
              [
                -73.77463657538321,
                40.60743822949262
              ],
              [
                -73.77463059951616,
                40.60743066185656
              ],
              [
                -73.7746211805568,
                40.607424105881
              ],
              [
                -73.77460294599074,
                40.607417648882524
              ],
              [
                -73.77458879604322,
                40.60741627067692
              ],
              [
                -73.77457386389516,
                40.60741537677115
              ],
              [
                -73.77455109867323,
                40.60741400863545
              ],
              [
                -73.77450343459158,
                40.60741384259156
              ],
              [
                -73.7744829412198,
                40.60741538738048
              ],
              [
                -73.77446245971632,
                40.60741693252404
              ],
              [
                -73.77443548153934,
                40.607421084574284
              ],
              [
                -73.77440850401454,
                40.60742523779214
              ],
              [
                -73.77438085592442,
                40.6074319642816
              ],
              [
                -73.77435307582272,
                40.60744256228131
              ],
              [
                -73.77432541481345,
                40.607456943493276
              ],
              [
                -73.77429834320833,
                40.6074748742998
              ],
              [
                -73.77427548255106,
                40.60749490162597
              ],
              [
                -73.77425539589512,
                40.6075193035672
              ],
              [
                -73.77424665012255,
                40.607533783895676
              ],
              [
                -73.77423017881631,
                40.60756545014685
              ],
              [
                -73.77422245171752,
                40.60758264377292
              ],
              [
                -73.77421620346279,
                40.60761682411601
              ],
              [
                -73.77421737753347,
                40.607636638971414
              ],
              [
                -73.77423322429085,
                40.60770256132094
              ],
              [
                -73.77424702088246,
                40.60774578625364
              ],
              [
                -73.77426287133888,
                40.60778235172991
              ],
              [
                -73.77428088383343,
                40.60781602134547
              ],
              [
                -73.77429437865158,
                40.60783662516765
              ],
              [
                -73.77430904775353,
                40.607855925233544
              ],
              [
                -73.77432745803145,
                40.607876367320884
              ],
              [
                -73.77434996612125,
                40.60789760031595
              ],
              [
                -73.77437661952841,
                40.60791987845193
              ],
              [
                -73.77439705423373,
                40.60793574072559
              ],
              [
                -73.77466217243811,
                40.608137104116345
              ],
              [
                -73.77468082407884,
                40.60815284590763
              ],
              [
                -73.7746963915617,
                40.6081687523627
              ],
              [
                -73.77470784811686,
                40.60818430112374
              ],
              [
                -73.77475339012696,
                40.60826703036626
              ],
              [
                -73.77479766202062,
                40.60836608486643
              ],
              [
                -73.77480685406117,
                40.60838013381135
              ],
              [
                -73.77481379409205,
                40.60839319561505
              ],
              [
                -73.77481441008918,
                40.60839956311232
              ],
              [
                -73.77481026628094,
                40.60840568858567
              ],
              [
                -73.77480327193533,
                40.608408988951474
              ],
              [
                -73.77479725102974,
                40.6084108315392
              ],
              [
                -73.77477056234716,
                40.608416632223786
              ],
              [
                -73.7746799841398,
                40.60844717240926
              ],
              [
                -73.77466166934929,
                40.60845091781354
              ],
              [
                -73.77464085996316,
                40.60845123703548
              ],
              [
                -73.77461557246363,
                40.60844802717402
              ],
              [
                -73.77458795569864,
                40.60844210177575
              ],
              [
                -73.77456033784036,
                40.60843617603362
              ],
              [
                -73.77453648247197,
                40.60843244560135
              ],
              [
                -73.77451194308915,
                40.608431821160444
              ],
              [
                -73.7744908031617,
                40.60843557121446
              ],
              [
                -73.77445904722425,
                40.60844792753528
              ],
              [
                -73.77442552706036,
                40.608465016780436
              ],
              [
                -73.7743300451969,
                40.608523300849534
              ],
              [
                -73.77427052824257,
                40.608565219488064
              ],
              [
                -73.77422269363439,
                40.608601128640224
              ],
              [
                -73.77416577139441,
                40.60865140098893
              ],
              [
                -73.7741336102761,
                40.60869621091816
              ],
              [
                -73.77412946283731,
                40.60870682899959
              ],
              [
                -73.77412897394223,
                40.60874659586809
              ],
              [
                -73.77417221920564,
                40.60880955501729
              ],
              [
                -73.77420239758109,
                40.60885865673877
              ],
              [
                -73.77422234411402,
                40.60889323163521
              ],
              [
                -73.77426957108304,
                40.60894965218348
              ],
              [
                -73.77441360213419,
                40.609080924632046
              ],
              [
                -73.77453359848452,
                40.609191681831604
              ],
              [
                -73.77465245646789,
                40.60929002641901
              ],
              [
                -73.77473640418869,
                40.60935819942157
              ],
              [
                -73.77480417881385,
                40.60941856747317
              ],
              [
                -73.7749233665848,
                40.60952078520296
              ],
              [
                -73.77505226664096,
                40.609609001436844
              ],
              [
                -73.77514648392243,
                40.60966655761686
              ],
              [
                -73.7752225507697,
                40.60970354714687
              ],
              [
                -73.7752644619336,
                40.609718019049446
              ],
              [
                -73.77535164745696,
                40.60973740696431
              ],
              [
                -73.77539529253983,
                40.60974197668978
              ],
              [
                -73.77543801698695,
                40.609743023631964
              ],
              [
                -73.77545862875209,
                40.60974177710562
              ],
              [
                -73.77547924055102,
                40.60974052018878
              ],
              [
                -73.77583557010675,
                40.60969112854047
              ],
              [
                -73.77595171894995,
                40.60967811732317
              ],
              [
                -73.7761407621011,
                40.60965826032538
              ],
              [
                -73.7762653832992,
                40.60964903889425
              ],
              [
                -73.7763584369479,
                40.609655838242325
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.00182693922711,
        "objectid": 118,
        "shape_leng": 0.243966217692,
        "location_id": 118,
        "zone": "Heartland Village/Todt Hill",
        "borough": "Staten Island"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -74.10834417899996,
                40.61023476699996
              ],
              [
                -74.10885268299998,
                40.61025010899993
              ],
              [
                -74.10890146199993,
                40.61025158499995
              ],
              [
                -74.10945696499992,
                40.610285438999924
              ],
              [
                -74.11000934099998,
                40.610336205999936
              ],
              [
                -74.11047956499992,
                40.6104186559999
              ],
              [
                -74.11082654399989,
                40.61050691499993
              ],
              [
                -74.11113638099991,
                40.61058605999994
              ],
              [
                -74.1126374739999,
                40.61096736599992
              ],
              [
                -74.1129530689999,
                40.6110389719999
              ],
              [
                -74.11327407999993,
                40.61109793799992
              ],
              [
                -74.11359920399994,
                40.61114391299992
              ],
              [
                -74.11392707099994,
                40.6111766829999
              ],
              [
                -74.11425629899999,
                40.61119614899992
              ],
              [
                -74.11458547799997,
                40.61120229599992
              ],
              [
                -74.11508745399988,
                40.61120943099993
              ],
              [
                -74.11566833799999,
                40.61117538399993
              ],
              [
                -74.11651105799996,
                40.61105092799989
              ],
              [
                -74.11677996799996,
                40.61099204899989
              ],
              [
                -74.11687458199991,
                40.6109718259999
              ],
              [
                -74.11738923899999,
                40.610833214999914
              ],
              [
                -74.11832650899993,
                40.61058077399987
              ],
              [
                -74.11993967899997,
                40.6101462669999
              ],
              [
                -74.1204407989999,
                40.61001806699993
              ],
              [
                -74.12135179599994,
                40.609765267999926
              ],
              [
                -74.1213358189999,
                40.60966649799989
              ],
              [
                -74.1212725339999,
                40.609362169999926
              ],
              [
                -74.12116879899992,
                40.60883437599989
              ],
              [
                -74.12079903099993,
                40.60887493299986
              ],
              [
                -74.12066342799993,
                40.60888980599989
              ],
              [
                -74.1205232869999,
                40.608905175999936
              ],
              [
                -74.120254056,
                40.6089347039999
              ],
              [
                -74.11913216599991,
                40.60905774099993
              ],
              [
                -74.11897246899991,
                40.60907525399995
              ],
              [
                -74.11883362299994,
                40.60909048099991
              ],
              [
                -74.1186902039999,
                40.60910620799991
              ],
              [
                -74.11848942399992,
                40.60912822499995
              ],
              [
                -74.11839688799994,
                40.60913837299989
              ],
              [
                -74.11834442099989,
                40.609144125999904
              ],
              [
                -74.117424665,
                40.60928900899992
              ],
              [
                -74.11706965299993,
                40.60820624999994
              ],
              [
                -74.116493189,
                40.60765675199985
              ],
              [
                -74.1163204779999,
                40.6075755289999
              ],
              [
                -74.11625356799993,
                40.607536440999894
              ],
              [
                -74.11615857899993,
                40.60748094799985
              ],
              [
                -74.1160100819999,
                40.6073743499999
              ],
              [
                -74.11587716399993,
                40.60725747099985
              ],
              [
                -74.1158562429999,
                40.60715118399992
              ],
              [
                -74.11579141499993,
                40.60686116499995
              ],
              [
                -74.11567482099998,
                40.60613313799994
              ],
              [
                -74.11554728399992,
                40.60539592799987
              ],
              [
                -74.11544265199991,
                40.60469494899995
              ],
              [
                -74.11537754999995,
                40.6039841869999
              ],
              [
                -74.11530272499996,
                40.603868255999906
              ],
              [
                -74.11511342599988,
                40.602533816999866
              ],
              [
                -74.11487058399989,
                40.602136659999935
              ],
              [
                -74.11500030999997,
                40.60207428599988
              ],
              [
                -74.11684368799997,
                40.601894806999894
              ],
              [
                -74.11777623899994,
                40.60186024799993
              ],
              [
                -74.11790155199996,
                40.601856453999865
              ],
              [
                -74.11957594399998,
                40.60181702899996
              ],
              [
                -74.12059546299996,
                40.60180975799988
              ],
              [
                -74.12055576799992,
                40.60104529199988
              ],
              [
                -74.12056668799994,
                40.60095567899988
              ],
              [
                -74.12056671099998,
                40.60095547199994
              ],
              [
                -74.12056678099995,
                40.6009552459999
              ],
              [
                -74.12059572199999,
                40.6008671179999
              ],
              [
                -74.12059578099999,
                40.6008669369999
              ],
              [
                -74.12059587499996,
                40.600866766999935
              ],
              [
                -74.12064219099997,
                40.600782950999914
              ],
              [
                -74.1206422609999,
                40.60078282499992
              ],
              [
                -74.12064234399993,
                40.60078271599989
              ],
              [
                -74.12070424299995,
                40.600705980999884
              ],
              [
                -74.12229425099991,
                40.59968717799994
              ],
              [
                -74.12264348699986,
                40.59941858499991
              ],
              [
                -74.123185791,
                40.59919511599987
              ],
              [
                -74.12322499699997,
                40.5990969529999
              ],
              [
                -74.12399136699995,
                40.59871481099991
              ],
              [
                -74.12454297999987,
                40.598395257999876
              ],
              [
                -74.12462019999994,
                40.59834793499992
              ],
              [
                -74.12470164799996,
                40.59830492599989
              ],
              [
                -74.12478690899994,
                40.59826644999988
              ],
              [
                -74.1248755499999,
                40.598232702999915
              ],
              [
                -74.12496712099988,
                40.5982038559999
              ],
              [
                -74.12506115499997,
                40.59818005599988
              ],
              [
                -74.12515717599992,
                40.59816142399991
              ],
              [
                -74.12525469399999,
                40.59814805499983
              ],
              [
                -74.12535321399994,
                40.59814001699987
              ],
              [
                -74.12545223499997,
                40.59813734999989
              ],
              [
                -74.12714563699991,
                40.59833036199989
              ],
              [
                -74.12798105699996,
                40.598424835999914
              ],
              [
                -74.12888881099992,
                40.598547832999884
              ],
              [
                -74.12982988699997,
                40.59867157699988
              ],
              [
                -74.13080319399995,
                40.59884208599994
              ],
              [
                -74.13091909399999,
                40.598855047999905
              ],
              [
                -74.13095007699992,
                40.59873715199991
              ],
              [
                -74.13099847699996,
                40.59862154199993
              ],
              [
                -74.13106374499998,
                40.598510423999905
              ],
              [
                -74.13114463199999,
                40.59840588899994
              ],
              [
                -74.13123935899998,
                40.59830974899993
              ],
              [
                -74.13205430799997,
                40.5978187059999
              ],
              [
                -74.1328130649999,
                40.59724097399986
              ],
              [
                -74.13385485799995,
                40.596357845999925
              ],
              [
                -74.13510176799998,
                40.59453503299995
              ],
              [
                -74.13544073999995,
                40.59381694299986
              ],
              [
                -74.13549571999992,
                40.593700467999966
              ],
              [
                -74.13639761399997,
                40.591973456999895
              ],
              [
                -74.13659957099995,
                40.59166794799989
              ],
              [
                -74.13681568999996,
                40.59136787899989
              ],
              [
                -74.13704557999992,
                40.591073817999856
              ],
              [
                -74.137288794,
                40.5907863149999
              ],
              [
                -74.13849744199997,
                40.58931370499991
              ],
              [
                -74.13864458599996,
                40.58906080499991
              ],
              [
                -74.14168277399988,
                40.588741184999876
              ],
              [
                -74.14230337999994,
                40.588725478999955
              ],
              [
                -74.1438732669999,
                40.588615298999926
              ],
              [
                -74.14583447999993,
                40.58856291899987
              ],
              [
                -74.14636402099993,
                40.58855786899987
              ],
              [
                -74.14645464799996,
                40.58855885299993
              ],
              [
                -74.14684720299991,
                40.58857108499991
              ],
              [
                -74.1477604,
                40.58855595599994
              ],
              [
                -74.14790576499992,
                40.58855634599993
              ],
              [
                -74.14805282699994,
                40.58857080699991
              ],
              [
                -74.1481980769999,
                40.588599680999934
              ],
              [
                -74.14833787899993,
                40.58864260799992
              ],
              [
                -74.14846882599996,
                40.58869854099988
              ],
              [
                -74.14858798599987,
                40.58876582799994
              ],
              [
                -74.14869312199986,
                40.58884235399989
              ],
              [
                -74.1497277719999,
                40.589492503999914
              ],
              [
                -74.15096968899994,
                40.59017657399992
              ],
              [
                -74.152162103,
                40.590836192999845
              ],
              [
                -74.152352083,
                40.590936961999894
              ],
              [
                -74.15450856599989,
                40.59213609399994
              ],
              [
                -74.15537259699995,
                40.592597337999955
              ],
              [
                -74.1561030049999,
                40.59304747599992
              ],
              [
                -74.15636568699993,
                40.5931951279999
              ],
              [
                -74.15795821599991,
                40.594087044999874
              ],
              [
                -74.1580026889999,
                40.59412020399986
              ],
              [
                -74.1584322029999,
                40.59435961999991
              ],
              [
                -74.15891672599996,
                40.59462968499985
              ],
              [
                -74.15989227899996,
                40.59517014099986
              ],
              [
                -74.16025756099997,
                40.59534393799988
              ],
              [
                -74.16180204399988,
                40.59530144799988
              ],
              [
                -74.16189940599996,
                40.595298770999946
              ],
              [
                -74.16188699199998,
                40.595007126999874
              ],
              [
                -74.16188546199997,
                40.59499033499991
              ],
              [
                -74.16188841099994,
                40.59483745799985
              ],
              [
                -74.161908184,
                40.59468531299996
              ],
              [
                -74.16194448999997,
                40.59453549599989
              ],
              [
                -74.16200333499994,
                40.59432685099992
              ],
              [
                -74.16208738699994,
                40.59408311399988
              ],
              [
                -74.16219000599993,
                40.59384310599989
              ],
              [
                -74.16231073399989,
                40.59360803299989
              ],
              [
                -74.16244891299996,
                40.593379085999864
              ],
              [
                -74.16260370599997,
                40.593157365999915
              ],
              [
                -74.16279187799998,
                40.59295768699992
              ],
              [
                -74.16299668999994,
                40.59276682799989
              ],
              [
                -74.16321702399988,
                40.59258595999993
              ],
              [
                -74.16345157200001,
                40.592416147999884
              ],
              [
                -74.16369888299994,
                40.592258338999905
              ],
              [
                -74.16578641299995,
                40.59070780399986
              ],
              [
                -74.16617917199996,
                40.59036865799991
              ],
              [
                -74.16655880399993,
                40.59002054099989
              ],
              [
                -74.16692477999996,
                40.589663906999945
              ],
              [
                -74.16727661599998,
                40.58929921399986
              ],
              [
                -74.16761386399995,
                40.588926966999935
              ],
              [
                -74.1676915249999,
                40.58883149299986
              ],
              [
                -74.16776136599995,
                40.58873256899994
              ],
              [
                -74.16782312399997,
                40.58863056899987
              ],
              [
                -74.1678765679999,
                40.588525874999895
              ],
              [
                -74.16792283599987,
                40.58841412299991
              ],
              [
                -74.16796126199992,
                40.588300656999905
              ],
              [
                -74.1679917399999,
                40.58818579699995
              ],
              [
                -74.16801418199992,
                40.5880698669999
              ],
              [
                -74.16842916199994,
                40.58649792299988
              ],
              [
                -74.16895369199989,
                40.583098363999916
              ],
              [
                -74.16901204299992,
                40.58268826899994
              ],
              [
                -74.16903999799996,
                40.582491778999945
              ],
              [
                -74.1690483779999,
                40.58243293499993
              ],
              [
                -74.16905498199999,
                40.58238696299989
              ],
              [
                -74.16907820699998,
                40.58222502299985
              ],
              [
                -74.16908500799998,
                40.58217764599993
              ],
              [
                -74.16909417599999,
                40.58211339699996
              ],
              [
                -74.16913807999991,
                40.58180581199991
              ],
              [
                -74.16948145799995,
                40.57940007499998
              ],
              [
                -74.16955946599997,
                40.57891524199987
              ],
              [
                -74.16962344999997,
                40.57842904399989
              ],
              [
                -74.16967337599986,
                40.57794182199995
              ],
              [
                -74.16970918699998,
                40.57745394499993
              ],
              [
                -74.16973086899992,
                40.576965756999876
              ],
              [
                -74.16975526299996,
                40.57668893399995
              ],
              [
                -74.16980912399991,
                40.57579489399991
              ],
              [
                -74.16991886099999,
                40.57397335499995
              ],
              [
                -74.16995465599994,
                40.57345803799993
              ],
              [
                -74.16997053699991,
                40.57294169899995
              ],
              [
                -74.16996647099997,
                40.5724249999999
              ],
              [
                -74.16994243599996,
                40.57190861999992
              ],
              [
                -74.16989846899996,
                40.57139322399989
              ],
              [
                -74.16983467899996,
                40.57087948699992
              ],
              [
                -74.16974893299987,
                40.57023648399992
              ],
              [
                -74.16968558799996,
                40.56959152099988
              ],
              [
                -74.16964473399992,
                40.56894526499992
              ],
              [
                -74.16962643399991,
                40.568298409999926
              ],
              [
                -74.16963069799989,
                40.56765163899992
              ],
              [
                -74.16966171099988,
                40.566903382999904
              ],
              [
                -74.16967801699991,
                40.566470244999906
              ],
              [
                -74.16969147299992,
                40.566070399999894
              ],
              [
                -74.16972490199993,
                40.56447115899986
              ],
              [
                -74.16972693399991,
                40.56437399799989
              ],
              [
                -74.16973775199993,
                40.56383841899987
              ],
              [
                -74.16974554099994,
                40.56345282199994
              ],
              [
                -74.16974968899994,
                40.56333125299989
              ],
              [
                -74.16974974799987,
                40.56332951299986
              ],
              [
                -74.16976493899996,
                40.56288426399995
              ],
              [
                -74.16982582400001,
                40.56109042099992
              ],
              [
                -74.16688971999996,
                40.56047660499998
              ],
              [
                -74.16589404599995,
                40.560283361999915
              ],
              [
                -74.1649414939999,
                40.56011179699992
              ],
              [
                -74.16377941599998,
                40.5603169789999
              ],
              [
                -74.16272123599997,
                40.56077419499998
              ],
              [
                -74.16193293199993,
                40.56111113299994
              ],
              [
                -74.1615313009999,
                40.56126935399991
              ],
              [
                -74.16096801899997,
                40.56146020199987
              ],
              [
                -74.1600564059999,
                40.561705499999924
              ],
              [
                -74.15915791699992,
                40.561935833999925
              ],
              [
                -74.1582578419999,
                40.562201976999845
              ],
              [
                -74.15741136699988,
                40.562498565999896
              ],
              [
                -74.15725989499994,
                40.562553263999895
              ],
              [
                -74.15725974199995,
                40.5625533189999
              ],
              [
                -74.15725961299992,
                40.56255338299994
              ],
              [
                -74.15711551399995,
                40.562620641999935
              ],
              [
                -74.15711540799994,
                40.5626206869999
              ],
              [
                -74.15711531399992,
                40.562620740999876
              ],
              [
                -74.15698121999995,
                40.56269959399989
              ],
              [
                -74.15698100799993,
                40.562699720999944
              ],
              [
                -74.15698080699997,
                40.56269986499997
              ],
              [
                -74.15685893499987,
                40.562789021999926
              ],
              [
                -74.15685888799997,
                40.562789056999904
              ],
              [
                -74.1568588409999,
                40.5627890939999
              ],
              [
                -74.15675091099989,
                40.562886974999905
              ],
              [
                -74.15585090399995,
                40.56377444399988
              ],
              [
                -74.15511310499993,
                40.56494054499992
              ],
              [
                -74.15502927899995,
                40.565093413999904
              ],
              [
                -74.15492589699993,
                40.56524125699989
              ],
              [
                -74.15480382999996,
                40.56538193999996
              ],
              [
                -74.154664477,
                40.56551344299989
              ],
              [
                -74.15450973599995,
                40.5656339799999
              ],
              [
                -74.15434186999994,
                40.565742096999905
              ],
              [
                -74.1541634739999,
                40.56583667599995
              ],
              [
                -74.15357985599994,
                40.56625318599991
              ],
              [
                -74.15295627699996,
                40.56677601699994
              ],
              [
                -74.15228114599995,
                40.567392945999856
              ],
              [
                -74.15171497199997,
                40.56788728399988
              ],
              [
                -74.15147304199998,
                40.56805205899985
              ],
              [
                -74.15123561899995,
                40.56822060499991
              ],
              [
                -74.1510028049999,
                40.568392848999935
              ],
              [
                -74.1507746989999,
                40.56856871799991
              ],
              [
                -74.15055139699999,
                40.56874813699989
              ],
              [
                -74.15033299399995,
                40.5689310299999
              ],
              [
                -74.14983771799994,
                40.569358262999856
              ],
              [
                -74.14789716899996,
                40.57088728599995
              ],
              [
                -74.1472057429999,
                40.57053278099995
              ],
              [
                -74.14713603399994,
                40.57051798899991
              ],
              [
                -74.14706472699994,
                40.5705086149999
              ],
              [
                -74.14699254699998,
                40.570504753999906
              ],
              [
                -74.14692022299994,
                40.57050644499988
              ],
              [
                -74.14684848799997,
                40.5705136709999
              ],
              [
                -74.14677806799996,
                40.570526357999924
              ],
              [
                -74.14671789599998,
                40.57054077199993
              ],
              [
                -74.14665601599995,
                40.57055007499991
              ],
              [
                -74.14659317299999,
                40.57055415699992
              ],
              [
                -74.14653012099988,
                40.5705529679999
              ],
              [
                -74.1464676189999,
                40.57054652199994
              ],
              [
                -74.14640641799993,
                40.5705348979999
              ],
              [
                -74.146347254,
                40.57051823399994
              ],
              [
                -74.14629083699998,
                40.570496730999906
              ],
              [
                -74.14559994099997,
                40.57021110699987
              ],
              [
                -74.14489975699995,
                40.569915169999945
              ],
              [
                -74.14438744999991,
                40.56970610099992
              ],
              [
                -74.14402153299991,
                40.57033011999989
              ],
              [
                -74.14387453599991,
                40.570606593999905
              ],
              [
                -74.1440387979999,
                40.5712994959999
              ],
              [
                -74.14418588199999,
                40.572037409999886
              ],
              [
                -74.14184764599999,
                40.57233261399994
              ],
              [
                -74.14110631299992,
                40.57242255299991
              ],
              [
                -74.14024008799997,
                40.57252737199986
              ],
              [
                -74.13846596999996,
                40.572739886999955
              ],
              [
                -74.13813873699995,
                40.572779642999926
              ],
              [
                -74.13704258199989,
                40.57290845199989
              ],
              [
                -74.13635343199988,
                40.57299130999997
              ],
              [
                -74.1357919339999,
                40.573051949999844
              ],
              [
                -74.13461855599995,
                40.5731977129999
              ],
              [
                -74.13378794099991,
                40.573296974999934
              ],
              [
                -74.1333012009999,
                40.57337268099994
              ],
              [
                -74.13295322899991,
                40.57344477499989
              ],
              [
                -74.13261022999991,
                40.573530162999894
              ],
              [
                -74.13227350399993,
                40.573628501999934
              ],
              [
                -74.13194427599997,
                40.57373938299985
              ],
              [
                -74.1309404869999,
                40.574106010999834
              ],
              [
                -74.12930379600002,
                40.57467541099988
              ],
              [
                -74.12914464399998,
                40.57473005399987
              ],
              [
                -74.12814797599995,
                40.575056538999924
              ],
              [
                -74.12784711399995,
                40.57516757599995
              ],
              [
                -74.12765358199992,
                40.575234942999884
              ],
              [
                -74.1275212319999,
                40.575410538999954
              ],
              [
                -74.12752110199995,
                40.575410709999936
              ],
              [
                -74.12752099599994,
                40.57541088199988
              ],
              [
                -74.1274030649999,
                40.57559308999994
              ],
              [
                -74.12740297099995,
                40.5755932429999
              ],
              [
                -74.12740288899992,
                40.575593395999896
              ],
              [
                -74.12730007399989,
                40.575781449999965
              ],
              [
                -74.12730004999996,
                40.57578149499989
              ],
              [
                -74.12730002599994,
                40.575781539999916
              ],
              [
                -74.12721306899995,
                40.57597415099987
              ],
              [
                -74.12721297499998,
                40.57597434899988
              ],
              [
                -74.12721289299992,
                40.57597456599993
              ],
              [
                -74.12714229799994,
                40.576170480999906
              ],
              [
                -74.1271422509999,
                40.57617061599995
              ],
              [
                -74.12714220299996,
                40.57617076799989
              ],
              [
                -74.12708814699995,
                40.57636910599995
              ],
              [
                -74.12665688899989,
                40.576284932999926
              ],
              [
                -74.12563664099993,
                40.575973443999935
              ],
              [
                -74.12513333899997,
                40.57587998799992
              ],
              [
                -74.12408417799992,
                40.575834019999924
              ],
              [
                -74.1234975639999,
                40.57580793399994
              ],
              [
                -74.12333972999994,
                40.575787011999914
              ],
              [
                -74.12333955299995,
                40.57578698599992
              ],
              [
                -74.12333936499992,
                40.57578694099992
              ],
              [
                -74.1231838069999,
                40.57575185199992
              ],
              [
                -74.12318366499987,
                40.57575181599987
              ],
              [
                -74.12318352299998,
                40.57575177099994
              ],
              [
                -74.12303336099994,
                40.575702772999875
              ],
              [
                -74.12303319599995,
                40.57570271899991
              ],
              [
                -74.12303304099993,
                40.57570264699992
              ],
              [
                -74.12289135999994,
                40.57564058099991
              ],
              [
                -74.12289127699994,
                40.57564054599994
              ],
              [
                -74.12289120499989,
                40.57564049999994
              ],
              [
                -74.12276083999994,
                40.5755667879999
              ],
              [
                -74.122760698,
                40.57556670699991
              ],
              [
                -74.12276057999999,
                40.57556662599996
              ],
              [
                -74.12264375299995,
                40.57548301999989
              ],
              [
                -74.12209602099993,
                40.57513258699987
              ],
              [
                -74.12198585999995,
                40.57511466699986
              ],
              [
                -74.12198569499998,
                40.57511464099986
              ],
              [
                -74.12198554099993,
                40.57511463099989
              ],
              [
                -74.12187198599999,
                40.57510757499989
              ],
              [
                -74.12187183299987,
                40.57510756699992
              ],
              [
                -74.12187169099991,
                40.57510757499995
              ],
              [
                -74.12175739999991,
                40.57511179399991
              ],
              [
                -74.12175724699989,
                40.57511180299991
              ],
              [
                -74.12175709299996,
                40.5751118209999
              ],
              [
                -74.121644926,
                40.57512723099996
              ],
              [
                -74.12164481999999,
                40.57512724899992
              ],
              [
                -74.12164470299997,
                40.57512727699992
              ],
              [
                -74.1215372899999,
                40.57515325299986
              ],
              [
                -74.1212571389999,
                40.575254893999904
              ],
              [
                -74.1211208019999,
                40.57530684499994
              ],
              [
                -74.11978567499995,
                40.5758155559999
              ],
              [
                -74.11910954899994,
                40.576157251999895
              ],
              [
                -74.11844855599999,
                40.57665868099992
              ],
              [
                -74.118033584,
                40.57717040899997
              ],
              [
                -74.1175761019999,
                40.57770348399993
              ],
              [
                -74.11702078499995,
                40.57819692599988
              ],
              [
                -74.11683019199994,
                40.57825471799991
              ],
              [
                -74.1166329409999,
                40.57830047599991
              ],
              [
                -74.1164310249999,
                40.5783335949999
              ],
              [
                -74.11622655900001,
                40.57835373999991
              ],
              [
                -74.11602165599997,
                40.57836082599988
              ],
              [
                -74.11588397299995,
                40.57838369499988
              ],
              [
                -74.11575021599991,
                40.5784236329999
              ],
              [
                -74.11562578099988,
                40.578479752999904
              ],
              [
                -74.11551544900001,
                40.57854987899991
              ],
              [
                -74.1154228399999,
                40.578630866999895
              ],
              [
                -74.11481941499994,
                40.579176874999895
              ],
              [
                -74.11422746699996,
                40.579733907999916
              ],
              [
                -74.11394959699996,
                40.58004141599992
              ],
              [
                -74.11367299799996,
                40.58031293699987
              ],
              [
                -74.11306954499995,
                40.58076852199988
              ],
              [
                -74.11220998099996,
                40.581266237999934
              ],
              [
                -74.11127706499995,
                40.58179072899988
              ],
              [
                -74.11028714299995,
                40.58219874799996
              ],
              [
                -74.10924835599995,
                40.58267841299991
              ],
              [
                -74.10790851599995,
                40.583685637999935
              ],
              [
                -74.10729030999991,
                40.58422229799991
              ],
              [
                -74.10649465099986,
                40.58489534299985
              ],
              [
                -74.10588393199993,
                40.58540720699988
              ],
              [
                -74.10525775499997,
                40.585921435999865
              ],
              [
                -74.10454864599998,
                40.58650064899995
              ],
              [
                -74.10392537999996,
                40.58707587399994
              ],
              [
                -74.10357323099993,
                40.587393759999884
              ],
              [
                -74.10344974399993,
                40.587505230999945
              ],
              [
                -74.10328237999991,
                40.58765630999993
              ],
              [
                -74.10317352499997,
                40.58775457099988
              ],
              [
                -74.10292541199989,
                40.5879785399999
              ],
              [
                -74.10282423899991,
                40.58806986599989
              ],
              [
                -74.1025675829999,
                40.58830154099994
              ],
              [
                -74.10246256599999,
                40.58839633599994
              ],
              [
                -74.10207990099998,
                40.588741750999866
              ],
              [
                -74.10149482299994,
                40.58934426099992
              ],
              [
                -74.10088251699995,
                40.590179215999925
              ],
              [
                -74.10089056999995,
                40.59042994899993
              ],
              [
                -74.10090957399993,
                40.59102161399988
              ],
              [
                -74.10097579699995,
                40.591832233999845
              ],
              [
                -74.10083052299994,
                40.592632305999885
              ],
              [
                -74.100817394,
                40.592668734999904
              ],
              [
                -74.10074056699987,
                40.592844566999894
              ],
              [
                -74.10074047299992,
                40.592844782999926
              ],
              [
                -74.10074036799995,
                40.592844988999914
              ],
              [
                -74.1006473839999,
                40.59301653999994
              ],
              [
                -74.10064730199993,
                40.59301670199992
              ],
              [
                -74.10064720699991,
                40.59301685499988
              ],
              [
                -74.10053851599993,
                40.59318320499992
              ],
              [
                -74.10041498999992,
                40.59334309299989
              ],
              [
                -74.09979588999995,
                40.593871365999895
              ],
              [
                -74.09949192299995,
                40.594047780999915
              ],
              [
                -74.09908179799994,
                40.59428707099988
              ],
              [
                -74.0987378739999,
                40.59448771499993
              ],
              [
                -74.09806839099988,
                40.59488151999992
              ],
              [
                -74.09798651199996,
                40.59492968699989
              ],
              [
                -74.09722072899997,
                40.59536515499987
              ],
              [
                -74.09586192699999,
                40.5959508889999
              ],
              [
                -74.09490220899986,
                40.59629562299991
              ],
              [
                -74.09427668499993,
                40.59644927099988
              ],
              [
                -74.09375453799998,
                40.5967448899999
              ],
              [
                -74.09336426799996,
                40.59703306899991
              ],
              [
                -74.09272305199994,
                40.59746426399992
              ],
              [
                -74.09228640999991,
                40.59808239999995
              ],
              [
                -74.09192827899987,
                40.598504050999935
              ],
              [
                -74.09178257499993,
                40.598719238999884
              ],
              [
                -74.09175787199996,
                40.598801421999916
              ],
              [
                -74.09173885999999,
                40.59888447899993
              ],
              [
                -74.09172558899995,
                40.59896818299989
              ],
              [
                -74.09171809699998,
                40.5990523009999
              ],
              [
                -74.09171640399988,
                40.59913660299989
              ],
              [
                -74.09172051499995,
                40.59922085699985
              ],
              [
                -74.091798925,
                40.5993182099999
              ],
              [
                -74.09197184899992,
                40.599824262999945
              ],
              [
                -74.09211869899993,
                40.600086968999946
              ],
              [
                -74.09227904299993,
                40.600345409999875
              ],
              [
                -74.0924525679999,
                40.60059897899987
              ],
              [
                -74.09263886099997,
                40.60084710199989
              ],
              [
                -74.09283746599989,
                40.601089233999865
              ],
              [
                -74.0928674959999,
                40.601177792999934
              ],
              [
                -74.09288850399999,
                40.601267850999896
              ],
              [
                -74.09290036399996,
                40.60135887199993
              ],
              [
                -74.09290300799996,
                40.60145031999985
              ],
              [
                -74.09289641899991,
                40.601541650999884
              ],
              [
                -74.09288063499999,
                40.6016323259999
              ],
              [
                -74.09285575199992,
                40.60172180699987
              ],
              [
                -74.09282191499993,
                40.601809565999865
              ],
              [
                -74.09272060299999,
                40.60249806099995
              ],
              [
                -74.09260921099994,
                40.603230122999875
              ],
              [
                -74.09244145299986,
                40.604119585999875
              ],
              [
                -74.09258665899993,
                40.60514053599992
              ],
              [
                -74.09262561899992,
                40.60545055399992
              ],
              [
                -74.09268272599991,
                40.60591244899992
              ],
              [
                -74.0927137679999,
                40.606059182999914
              ],
              [
                -74.09271379199996,
                40.6060592729999
              ],
              [
                -74.092713803,
                40.60605936299993
              ],
              [
                -74.09272620699986,
                40.606209856999946
              ],
              [
                -74.092719093,
                40.6063620239999
              ],
              [
                -74.09271909299994,
                40.606362122999876
              ],
              [
                -74.09271908099993,
                40.60636222199992
              ],
              [
                -74.09269213899992,
                40.60651384699985
              ],
              [
                -74.09264560299997,
                40.606662721999925
              ],
              [
                -74.0926455799999,
                40.60666278599994
              ],
              [
                -74.09264555599998,
                40.606662838999924
              ],
              [
                -74.09258024999993,
                40.60680678599988
              ],
              [
                -74.09249749399994,
                40.606943849999894
              ],
              [
                -74.09239912899989,
                40.6070722889999
              ],
              [
                -74.09203043499996,
                40.60742603999993
              ],
              [
                -74.09187212199998,
                40.60756836799993
              ],
              [
                -74.09172937299998,
                40.60772119199992
              ],
              [
                -74.09160388599999,
                40.60788297299996
              ],
              [
                -74.09149702999991,
                40.608051967999856
              ],
              [
                -74.0914097369999,
                40.60822629799987
              ],
              [
                -74.09096036099993,
                40.60862968299989
              ],
              [
                -74.09080114099994,
                40.6087780889999
              ],
              [
                -74.09055711299992,
                40.609001150999894
              ],
              [
                -74.09045495899996,
                40.60909452599991
              ],
              [
                -74.09081541099992,
                40.6092365859999
              ],
              [
                -74.09305685599998,
                40.61011993599989
              ],
              [
                -74.09410959099992,
                40.610406551999894
              ],
              [
                -74.09446404899988,
                40.6104852189999
              ],
              [
                -74.09482489099999,
                40.61054805699995
              ],
              [
                -74.09519027099996,
                40.61059466299988
              ],
              [
                -74.09555829799993,
                40.61062477799989
              ],
              [
                -74.09592704599994,
                40.61063834699992
              ],
              [
                -74.09721750699994,
                40.61062801799988
              ],
              [
                -74.09730455699996,
                40.61062732099988
              ],
              [
                -74.0977696879999,
                40.61062359299991
              ],
              [
                -74.0993845299999,
                40.610561389999916
              ],
              [
                -74.09946989799992,
                40.61055810099989
              ],
              [
                -74.10003616599987,
                40.61053628199991
              ],
              [
                -74.10082806899996,
                40.610506670999904
              ],
              [
                -74.10138596799996,
                40.61048580699989
              ],
              [
                -74.101654781,
                40.61047575399987
              ],
              [
                -74.10181225499998,
                40.61046986299992
              ],
              [
                -74.10239596999999,
                40.6104480279999
              ],
              [
                -74.10290182399991,
                40.610429102999895
              ],
              [
                -74.10337596999989,
                40.6104134719999
              ],
              [
                -74.10429401399988,
                40.610383201999944
              ],
              [
                -74.10549388999996,
                40.61034361699996
              ],
              [
                -74.10667509699992,
                40.61028680299993
              ],
              [
                -74.10722963899997,
                40.610252444999915
              ],
              [
                -74.10778645199997,
                40.61023505699993
              ],
              [
                -74.10834417899996,
                40.61023476699996
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000185779447534,
        "objectid": 119,
        "shape_leng": 0.0835070355744,
        "location_id": 119,
        "zone": "Highbridge",
        "borough": "Bronx"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.9247831879999,
                40.844752664999895
              ],
              [
                -73.92511859399998,
                40.84477964499992
              ],
              [
                -73.92526006699997,
                40.844793802999966
              ],
              [
                -73.92596341899984,
                40.844889743999886
              ],
              [
                -73.92661872999987,
                40.845035646999875
              ],
              [
                -73.92668910099991,
                40.84505161199993
              ],
              [
                -73.92676979799985,
                40.84507093399993
              ],
              [
                -73.92696149299991,
                40.845114494999955
              ],
              [
                -73.92706435800001,
                40.8451394499999
              ],
              [
                -73.92740093599984,
                40.8452254349999
              ],
              [
                -73.927784594,
                40.845319748999906
              ],
              [
                -73.92796538299997,
                40.845362455999904
              ],
              [
                -73.92808209386837,
                40.84539159691673
              ],
              [
                -73.92811925349231,
                40.84531720381841
              ],
              [
                -73.92827454706787,
                40.84497576115691
              ],
              [
                -73.92861574822331,
                40.844676865029925
              ],
              [
                -73.92918458673104,
                40.84320375764766
              ],
              [
                -73.92919408007222,
                40.84281841523693
              ],
              [
                -73.92938012588226,
                40.842467145624376
              ],
              [
                -73.92957870884518,
                40.842276669602526
              ],
              [
                -73.92958939508082,
                40.84208354907973
              ],
              [
                -73.92985714678215,
                40.84156744895765
              ],
              [
                -73.93036686093104,
                40.84062002230456
              ],
              [
                -73.93039907621815,
                40.84061025903065
              ],
              [
                -73.93048324521037,
                40.84046006056707
              ],
              [
                -73.93043183972385,
                40.840231705387474
              ],
              [
                -73.9305070562785,
                40.84001027406934
              ],
              [
                -73.93051572319956,
                40.839933642183894
              ],
              [
                -73.93055441793419,
                40.839888009894516
              ],
              [
                -73.93059094734627,
                40.83985868154704
              ],
              [
                -73.9306598019076,
                40.839715232299525
              ],
              [
                -73.9307157038901,
                40.83963862978712
              ],
              [
                -73.93075007893717,
                40.8396158221305
              ],
              [
                -73.93076944989443,
                40.83957017766081
              ],
              [
                -73.93081468528484,
                40.83942834514667
              ],
              [
                -73.93086414351596,
                40.83935336886758
              ],
              [
                -73.93095819766826,
                40.839254146912985
              ],
              [
                -73.93150395434233,
                40.83832068951
              ],
              [
                -73.9318666017282,
                40.837640055747805
              ],
              [
                -73.93235564218675,
                40.836993335628385
              ],
              [
                -73.9326562035398,
                40.83647347652592
              ],
              [
                -73.93299639001587,
                40.83567878918864
              ],
              [
                -73.93314306709256,
                40.83519412761651
              ],
              [
                -73.93317633641155,
                40.83494357382704
              ],
              [
                -73.9331993131559,
                40.834770396376406
              ],
              [
                -73.9334623563697,
                40.833643823071334
              ],
              [
                -73.93350048262067,
                40.83315258051676
              ],
              [
                -73.93353807924747,
                40.8330584925788
              ],
              [
                -73.93356390857384,
                40.8329916533309
              ],
              [
                -73.9335639574196,
                40.832942736021245
              ],
              [
                -73.93357044736123,
                40.83289219258061
              ],
              [
                -73.93360592036706,
                40.83278953052076
              ],
              [
                -73.9335460674697,
                40.83150945350145
              ],
              [
                -73.93347556906217,
                40.8312214105751
              ],
              [
                -73.93341936721265,
                40.83077435096889
              ],
              [
                -73.93332562839899,
                40.83002868426814
              ],
              [
                -73.93308540348333,
                40.82822567381785
              ],
              [
                -73.93307251084072,
                40.82815615251811
              ],
              [
                -73.9330372189999,
                40.8281598459999
              ],
              [
                -73.93294190900001,
                40.82816770599991
              ],
              [
                -73.93250790099984,
                40.82820039299993
              ],
              [
                -73.93208873599987,
                40.82823274499994
              ],
              [
                -73.93146631099984,
                40.82828175999986
              ],
              [
                -73.93126199199995,
                40.82829117399996
              ],
              [
                -73.93119978199985,
                40.82829838699998
              ],
              [
                -73.9311413199999,
                40.82830380799988
              ],
              [
                -73.93095939299991,
                40.82833708899993
              ],
              [
                -73.93064968200004,
                40.82841702999989
              ],
              [
                -73.93033933099994,
                40.82849555499997
              ],
              [
                -73.93003491599993,
                40.828587589999906
              ],
              [
                -73.929737931,
                40.828692722999904
              ],
              [
                -73.9294498339999,
                40.8288104039999
              ],
              [
                -73.9291719679999,
                40.828939958999854
              ],
              [
                -73.928894371,
                40.82910412899993
              ],
              [
                -73.92875620599996,
                40.829185834999855
              ],
              [
                -73.92861034599984,
                40.829280909999916
              ],
              [
                -73.92850073899987,
                40.829352349999986
              ],
              [
                -73.92825730199993,
                40.829530513999906
              ],
              [
                -73.92802724899997,
                40.82971953899989
              ],
              [
                -73.92781189699987,
                40.829918431999936
              ],
              [
                -73.92781180199987,
                40.829918521999915
              ],
              [
                -73.92781170699992,
                40.82991861999991
              ],
              [
                -73.92761206299998,
                40.830126482999916
              ],
              [
                -73.92755002799989,
                40.830199590999946
              ],
              [
                -73.92742893599986,
                40.83034231499991
              ],
              [
                -73.92726316799988,
                40.83056486699993
              ],
              [
                -73.92642918799987,
                40.83142328399987
              ],
              [
                -73.92537540099983,
                40.832628095999944
              ],
              [
                -73.92502907499991,
                40.83327258399989
              ],
              [
                -73.92439355099992,
                40.83448402799995
              ],
              [
                -73.92414918399993,
                40.834828566999946
              ],
              [
                -73.92399049499991,
                40.835052297999894
              ],
              [
                -73.92340016399989,
                40.835432763999904
              ],
              [
                -73.92237223199994,
                40.83610874299993
              ],
              [
                -73.921882894,
                40.83642570099991
              ],
              [
                -73.92036502999981,
                40.83742570699994
              ],
              [
                -73.92001799499995,
                40.83765612799991
              ],
              [
                -73.91969564899983,
                40.83787180699989
              ],
              [
                -73.91938436399987,
                40.838076523999845
              ],
              [
                -73.91930863299986,
                40.83812632299989
              ],
              [
                -73.919162653,
                40.83825371199993
              ],
              [
                -73.91901668400004,
                40.83838109299989
              ],
              [
                -73.91859196099995,
                40.83898208999996
              ],
              [
                -73.91813727499989,
                40.8396068539999
              ],
              [
                -73.91768361899987,
                40.84022549599991
              ],
              [
                -73.91729851599982,
                40.84076611799986
              ],
              [
                -73.91689176299988,
                40.841347256999946
              ],
              [
                -73.91618344399991,
                40.84233489999991
              ],
              [
                -73.91768745899992,
                40.84223933299993
              ],
              [
                -73.91794965199988,
                40.84222957099999
              ],
              [
                -73.91795571399985,
                40.843101899999944
              ],
              [
                -73.91783013899986,
                40.84320987999994
              ],
              [
                -73.91771856499989,
                40.843329573999945
              ],
              [
                -73.91762366200004,
                40.84345932099992
              ],
              [
                -73.91754763699991,
                40.84359702599992
              ],
              [
                -73.91749203599986,
                40.84374026199992
              ],
              [
                -73.91745769299983,
                40.84388636899993
              ],
              [
                -73.917444646,
                40.844032681999906
              ],
              [
                -73.91745219900001,
                40.844176629999886
              ],
              [
                -73.91749072699996,
                40.844299016999884
              ],
              [
                -73.9175114879999,
                40.8444254339999
              ],
              [
                -73.91751358199987,
                40.844553629999936
              ],
              [
                -73.91749679799989,
                40.84468119099992
              ],
              [
                -73.91746161499991,
                40.844805766999926
              ],
              [
                -73.91740919699994,
                40.84492513499992
              ],
              [
                -73.91732494799987,
                40.845057482999955
              ],
              [
                -73.91729224999992,
                40.84510993999991
              ],
              [
                -73.91838402199984,
                40.84505501299993
              ],
              [
                -73.91877179199986,
                40.84503446699997
              ],
              [
                -73.91930035199984,
                40.8449932349999
              ],
              [
                -73.92043297999987,
                40.84494079399986
              ],
              [
                -73.92150714599991,
                40.844873573999976
              ],
              [
                -73.92246996799992,
                40.844813311999914
              ],
              [
                -73.92262808499991,
                40.84480091099987
              ],
              [
                -73.9227346099999,
                40.844796838999876
              ],
              [
                -73.9235400199999,
                40.844758943999885
              ],
              [
                -73.92387743499995,
                40.84474050799987
              ],
              [
                -73.9247831879999,
                40.844752664999895
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0000931462821313,
        "objectid": 120,
        "shape_leng": 0.0832612702158,
        "location_id": 120,
        "zone": "Highbridge Park",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.92295449499989,
                40.85885076199988
              ],
              [
                -73.92295846599977,
                40.85891378099997
              ],
              [
                -73.92411747399994,
                40.86065512499988
              ],
              [
                -73.92461430599997,
                40.86140156099989
              ],
              [
                -73.924677287,
                40.86149664499993
              ],
              [
                -73.92474153399992,
                40.861593637999924
              ],
              [
                -73.92499818399988,
                40.86146373799991
              ],
              [
                -73.92496864099992,
                40.86128326999995
              ],
              [
                -73.92496778999988,
                40.861253467999916
              ],
              [
                -73.92496310699987,
                40.861089282999934
              ],
              [
                -73.92498050399993,
                40.86089251899991
              ],
              [
                -73.92502162200003,
                40.86069531099992
              ],
              [
                -73.92508669599982,
                40.86050012699994
              ],
              [
                -73.92517535599984,
                40.86030947899993
              ],
              [
                -73.92528662599987,
                40.860125796999974
              ],
              [
                -73.92541898499994,
                40.85995133099994
              ],
              [
                -73.9255703789999,
                40.859788079999895
              ],
              [
                -73.92573839899995,
                40.85963767999989
              ],
              [
                -73.9259203249999,
                40.85950140899994
              ],
              [
                -73.92611328799985,
                40.859380110999965
              ],
              [
                -73.92629594799985,
                40.85928741399989
              ],
              [
                -73.92646803499991,
                40.859178123999854
              ],
              [
                -73.9266257519999,
                40.8590533259999
              ],
              [
                -73.92676563099991,
                40.85891478399986
              ],
              [
                -73.92688470899988,
                40.85876482899988
              ],
              [
                -73.92698078699978,
                40.85860624099996
              ],
              [
                -73.92705253299991,
                40.85844209099991
              ],
              [
                -73.92709951599984,
                40.85827552099992
              ],
              [
                -73.92712222099993,
                40.858109610999854
              ],
              [
                -73.92691117499996,
                40.857508759999874
              ],
              [
                -73.92687811100001,
                40.85761400599994
              ],
              [
                -73.92682273599989,
                40.85772223799992
              ],
              [
                -73.92674232299986,
                40.857829767999874
              ],
              [
                -73.92663553999984,
                40.85793192399994
              ],
              [
                -73.92650299499998,
                40.85802341899991
              ],
              [
                -73.92634775499995,
                40.85809897099994
              ],
              [
                -73.9261752689999,
                40.85815409599992
              ],
              [
                -73.92599285799992,
                40.8581858659999
              ],
              [
                -73.92580872899991,
                40.858193419999964
              ],
              [
                -73.92563075799984,
                40.85817795199989
              ],
              [
                -73.925465479,
                40.858142410999974
              ],
              [
                -73.92531748399992,
                40.858090806999954
              ],
              [
                -73.92518924999993,
                40.85802753699994
              ],
              [
                -73.92508143699999,
                40.85795678799993
              ],
              [
                -73.92496717399985,
                40.8578110039999
              ],
              [
                -73.92486929999991,
                40.85765619099987
              ],
              [
                -73.92478952099987,
                40.8574939969999
              ],
              [
                -73.9247291889999,
                40.857326286999886
              ],
              [
                -73.92468915299989,
                40.85715512199993
              ],
              [
                -73.92466977999999,
                40.8569826109999
              ],
              [
                -73.92467094699998,
                40.85681088899992
              ],
              [
                -73.92469199999985,
                40.856641996999905
              ],
              [
                -73.92679937899987,
                40.853717144999926
              ],
              [
                -73.92723934899986,
                40.85311537199989
              ],
              [
                -73.92769565699982,
                40.8524877819999
              ],
              [
                -73.92703315699981,
                40.852200774999886
              ],
              [
                -73.927079823,
                40.85143234299991
              ],
              [
                -73.92747680599985,
                40.85069436999992
              ],
              [
                -73.92824918899996,
                40.84983985399994
              ],
              [
                -73.92842287999987,
                40.84974567999992
              ],
              [
                -73.92858520000006,
                40.84963880199997
              ],
              [
                -73.92873382199987,
                40.84952048899989
              ],
              [
                -73.92886675399986,
                40.849392312999925
              ],
              [
                -73.92898242699984,
                40.849256121999915
              ],
              [
                -73.92970731599995,
                40.84812261199989
              ],
              [
                -73.9306210789998,
                40.84848097699991
              ],
              [
                -73.93088430099981,
                40.84811893799988
              ],
              [
                -73.93116151700002,
                40.8477406009999
              ],
              [
                -73.93139355399987,
                40.84742823099986
              ],
              [
                -73.93185010299997,
                40.846802632999925
              ],
              [
                -73.93191507399995,
                40.84671052399989
              ],
              [
                -73.93198004399996,
                40.846618414999895
              ],
              [
                -73.93201002999993,
                40.84657596599988
              ],
              [
                -73.93204002799989,
                40.84653350699988
              ],
              [
                -73.93207258099989,
                40.84649567899991
              ],
              [
                -73.93210093699993,
                40.846457729999884
              ],
              [
                -73.93218663399993,
                40.84634306699996
              ],
              [
                -73.93224030199994,
                40.84626460299991
              ],
              [
                -73.93232515199996,
                40.8461539009999
              ],
              [
                -73.93277020399985,
                40.8455411059999
              ],
              [
                -73.933150441,
                40.84502194299994
              ],
              [
                -73.93323248400002,
                40.8449099219999
              ],
              [
                -73.933715538,
                40.844246374999926
              ],
              [
                -73.93421198500002,
                40.843568902999955
              ],
              [
                -73.93467106399996,
                40.84295724299987
              ],
              [
                -73.93514017399997,
                40.8423116739999
              ],
              [
                -73.93556697199989,
                40.841712527999924
              ],
              [
                -73.93561105099988,
                40.8416089949999
              ],
              [
                -73.93561108599988,
                40.841608895999876
              ],
              [
                -73.93561111099991,
                40.84160880699991
              ],
              [
                -73.93563664999986,
                40.84150019499988
              ],
              [
                -73.935636661,
                40.841500139999944
              ],
              [
                -73.93564243699991,
                40.84138944599988
              ],
              [
                -73.93564244799997,
                40.841389282999934
              ],
              [
                -73.93564242499978,
                40.841389129999904
              ],
              [
                -73.93562830199987,
                40.84127961399994
              ],
              [
                -73.93562826699996,
                40.84127936199993
              ],
              [
                -73.93562818399985,
                40.841279099999944
              ],
              [
                -73.93559506299997,
                40.841173399999924
              ],
              [
                -73.93543637099998,
                40.84075133099986
              ],
              [
                -73.93529543199986,
                40.84047481799996
              ],
              [
                -73.93519180599998,
                40.84033568799992
              ],
              [
                -73.93519169999996,
                40.84033554399989
              ],
              [
                -73.93519161599997,
                40.84033539999994
              ],
              [
                -73.93510666600004,
                40.84018372699994
              ],
              [
                -73.93504376899986,
                40.84002162099989
              ],
              [
                -73.93504374400003,
                40.84002156699993
              ],
              [
                -73.93504373300003,
                40.84002151299995
              ],
              [
                -73.93500577799999,
                40.839852009999916
              ],
              [
                -73.93500575299987,
                40.83985191099993
              ],
              [
                -73.93500574300005,
                40.83985180199986
              ],
              [
                -73.93499462399996,
                40.83967821699988
              ],
              [
                -73.93499462399987,
                40.839678135999925
              ],
              [
                -73.93499463699986,
                40.83967805499989
              ],
              [
                -73.93501119299988,
                40.83950397199993
              ],
              [
                -73.93505508799984,
                40.8393332359999
              ],
              [
                -73.93512471799994,
                40.83916963699992
              ],
              [
                -73.9351247769999,
                40.83916948499991
              ],
              [
                -73.93512487199997,
                40.83916933099994
              ],
              [
                -73.93521769499985,
                40.83901592099991
              ],
              [
                -73.93521772999993,
                40.83901586699992
              ],
              [
                -73.93521776599987,
                40.839015821999936
              ],
              [
                -73.93533051899982,
                40.83887503999995
              ],
              [
                -73.93533061499988,
                40.83887491299989
              ],
              [
                -73.93533073299984,
                40.83887479599988
              ],
              [
                -73.93545962199988,
                40.83874840399992
              ],
              [
                -73.93563711299996,
                40.83863141999997
              ],
              [
                -73.93580416699986,
                40.8385049769999
              ],
              [
                -73.93595950299988,
                40.838369897999904
              ],
              [
                -73.93610200500004,
                40.83822716899989
              ],
              [
                -73.93623075799998,
                40.838077855999884
              ],
              [
                -73.93638316899992,
                40.83785012499988
              ],
              [
                -73.93669078700003,
                40.83719324899995
              ],
              [
                -73.93702353899988,
                40.8364689469999
              ],
              [
                -73.93738281099989,
                40.83569072899993
              ],
              [
                -73.93775116199998,
                40.834890575999964
              ],
              [
                -73.93786306199998,
                40.83463774999997
              ],
              [
                -73.93789804799981,
                40.83454769599993
              ],
              [
                -73.93793968599998,
                40.834459289999856
              ],
              [
                -73.93798783999988,
                40.834372813999956
              ],
              [
                -73.93804235799993,
                40.83428854499991
              ],
              [
                -73.93810306499991,
                40.8342067539999
              ],
              [
                -73.93816976599996,
                40.83412770199994
              ],
              [
                -73.93824224799982,
                40.834051641999935
              ],
              [
                -73.93832027799995,
                40.833978817999935
              ],
              [
                -73.93840360699998,
                40.83390946299992
              ],
              [
                -73.93849196699979,
                40.83384379999993
              ],
              [
                -73.9391548789999,
                40.83327799299992
              ],
              [
                -73.93927348499993,
                40.833199648999894
              ],
              [
                -73.93927371099988,
                40.83319949599997
              ],
              [
                -73.93927389999998,
                40.83319934299994
              ],
              [
                -73.93938209499981,
                40.833110962999875
              ],
              [
                -73.93938216599997,
                40.83311089899993
              ],
              [
                -73.93938222499987,
                40.83311083599996
              ],
              [
                -73.93947800199997,
                40.83301364299994
              ],
              [
                -73.93947814499981,
                40.833013488999924
              ],
              [
                -73.9394782749999,
                40.833013327999886
              ],
              [
                -73.93955977199981,
                40.83290896699988
              ],
              [
                -73.93955986799993,
                40.83290884099993
              ],
              [
                -73.93955995000002,
                40.832908696999894
              ],
              [
                -73.93962603899988,
                40.83279885299988
              ],
              [
                -73.93962606299986,
                40.83279880799989
              ],
              [
                -73.93962608699985,
                40.832798753999896
              ],
              [
                -73.93967601899988,
                40.83268537099987
              ],
              [
                -73.94007535599995,
                40.83206722799986
              ],
              [
                -73.94016848499993,
                40.8319590559999
              ],
              [
                -73.94024600800003,
                40.83184212099991
              ],
              [
                -73.94030591799994,
                40.83171845099985
              ],
              [
                -73.94034688799985,
                40.83159040499989
              ],
              [
                -73.94036830799989,
                40.83146049199992
              ],
              [
                -73.94037033200001,
                40.83133125399993
              ],
              [
                -73.94034643299983,
                40.8304578419999
              ],
              [
                -73.9401708989999,
                40.83038439099996
              ],
              [
                -73.94007905799997,
                40.8304639569999
              ],
              [
                -73.93998939900003,
                40.830730965999855
              ],
              [
                -73.93996368699993,
                40.83082053399987
              ],
              [
                -73.93996366299984,
                40.8308206059999
              ],
              [
                -73.93992395099986,
                40.83090805999994
              ],
              [
                -73.93992389199987,
                40.83090817699985
              ],
              [
                -73.93992383199988,
                40.8309082759999
              ],
              [
                -73.93987063299983,
                40.83099179599987
              ],
              [
                -73.9398705739998,
                40.83099188599995
              ],
              [
                -73.93987048999986,
                40.83099197699993
              ],
              [
                -73.93980498999994,
                40.83106978999995
              ],
              [
                -73.93980484699988,
                40.83106995999987
              ],
              [
                -73.93980468099991,
                40.83107011299992
              ],
              [
                -73.93972831400002,
                40.83114089799994
              ],
              [
                -73.93960982599997,
                40.83125089499988
              ],
              [
                -73.93960970799996,
                40.83125100199988
              ],
              [
                -73.93960960099984,
                40.83125111899992
              ],
              [
                -73.93950338699985,
                40.83136945999996
              ],
              [
                -73.93941083399996,
                40.831494994999915
              ],
              [
                -73.93941078699996,
                40.83149507599992
              ],
              [
                -73.93933313199983,
                40.83162629999988
              ],
              [
                -73.9392711939998,
                40.83176165699991
              ],
              [
                -73.93927116899984,
                40.83176171999991
              ],
              [
                -73.93927114599987,
                40.83176179199989
              ],
              [
                -73.93922536499997,
                40.831899659999934
              ],
              [
                -73.93913480799989,
                40.832097594999915
              ],
              [
                -73.9390289729999,
                40.832291496999915
              ],
              [
                -73.93894859799995,
                40.832417335999914
              ],
              [
                -73.93890834499989,
                40.832480356999945
              ],
              [
                -73.9387736019999,
                40.83266321199991
              ],
              [
                -73.93868316299985,
                40.832800788999904
              ],
              [
                -73.93862894999997,
                40.83285794199988
              ],
              [
                -73.93857474699988,
                40.8329150849999
              ],
              [
                -73.93838508899995,
                40.8331135029999
              ],
              [
                -73.93795718299988,
                40.83345441799987
              ],
              [
                -73.93729719799981,
                40.8339773209999
              ],
              [
                -73.93729666499993,
                40.833977743999945
              ],
              [
                -73.93705905900006,
                40.83416598899989
              ],
              [
                -73.93700641599989,
                40.834193109999894
              ],
              [
                -73.93649557099988,
                40.83456879499995
              ],
              [
                -73.93572624299988,
                40.83515634699995
              ],
              [
                -73.93530787999994,
                40.83547772399988
              ],
              [
                -73.93506336299993,
                40.83567765899993
              ],
              [
                -73.93496274099988,
                40.83575993399989
              ],
              [
                -73.93485740300004,
                40.83598502599991
              ],
              [
                -73.93479886299984,
                40.8361101159999
              ],
              [
                -73.93474569399996,
                40.836092093999916
              ],
              [
                -73.93467393099985,
                40.836064902999915
              ],
              [
                -73.93460027799988,
                40.83609686799992
              ],
              [
                -73.93436121591056,
                40.83620061961779
              ],
              [
                -73.9343123461022,
                40.836318349308975
              ],
              [
                -73.9342843711121,
                40.836383690973754
              ],
              [
                -73.93419813061857,
                40.836572646434874
              ],
              [
                -73.93410248267041,
                40.83676602712268
              ],
              [
                -73.93401488559304,
                40.836934226122665
              ],
              [
                -73.93390943779711,
                40.837132723576715
              ],
              [
                -73.93377509137271,
                40.837371160788706
              ],
              [
                -73.93363822412816,
                40.83757930304679
              ],
              [
                -73.93353109087465,
                40.83776619294515
              ],
              [
                -73.93346646603968,
                40.83789121675665
              ],
              [
                -73.93341799707589,
                40.83797177345045
              ],
              [
                -73.93335677225113,
                40.83808776694384
              ],
              [
                -73.93326662858152,
                40.83825017256957
              ],
              [
                -73.93316799815203,
                40.838421587995605
              ],
              [
                -73.93309911785128,
                40.838543384623826
              ],
              [
                -73.93295967475893,
                40.83878569889997
              ],
              [
                -73.9327955073118,
                40.83912367646816
              ],
              [
                -73.93216198837912,
                40.840255026320825
              ],
              [
                -73.93183459772912,
                40.84083694824566
              ],
              [
                -73.93152929047892,
                40.84138536615741
              ],
              [
                -73.9312222988684,
                40.84193119781963
              ],
              [
                -73.93097488039662,
                40.842400432568006
              ],
              [
                -73.93089465201413,
                40.8425383401419
              ],
              [
                -73.93081019415833,
                40.842717972930494
              ],
              [
                -73.9308020456184,
                40.8427659647572
              ],
              [
                -73.93078580763273,
                40.842793033211535
              ],
              [
                -73.93077282921934,
                40.84281025121564
              ],
              [
                -73.93072736300049,
                40.8429397055029
              ],
              [
                -73.93069805828584,
                40.843023119393514
              ],
              [
                -73.93067529907344,
                40.84308525801471
              ],
              [
                -73.93062818153543,
                40.84320584088485
              ],
              [
                -73.93057942780858,
                40.84333749991762
              ],
              [
                -73.93056966892286,
                40.8433719473011
              ],
              [
                -73.93054530049922,
                40.84342854608869
              ],
              [
                -73.93049978822178,
                40.84355565022295
              ],
              [
                -73.93045266598449,
                40.8436811598807
              ],
              [
                -73.93039902075682,
                40.84382880869292
              ],
              [
                -73.93031939529575,
                40.844044140613846
              ],
              [
                -73.93027389095322,
                40.84416349199061
              ],
              [
                -73.93022352213245,
                40.844284441595754
              ],
              [
                -73.93015203635413,
                40.844449305942874
              ],
              [
                -73.93009844615622,
                40.84455511760717
              ],
              [
                -73.93004648072018,
                40.844660929727574
              ],
              [
                -73.92998963816926,
                40.844769196937264
              ],
              [
                -73.92992306713143,
                40.84489407370509
              ],
              [
                -73.92986949219896,
                40.844983883071116
              ],
              [
                -73.9298142904901,
                40.84507738364217
              ],
              [
                -73.92976558965158,
                40.845155498584745
              ],
              [
                -73.92971850477531,
                40.84523177826372
              ],
              [
                -73.92967631216537,
                40.84528959996815
              ],
              [
                -73.92961778841166,
                40.845371976856384
              ],
              [
                -73.92942845919356,
                40.84564416196216
              ],
              [
                -73.92938078797037,
                40.84571308802428
              ],
              [
                -73.92932522842061,
                40.84579501208142
              ],
              [
                -73.92928544019534,
                40.845854221201265
              ],
              [
                -73.92848994827723,
                40.846981957881525
              ],
              [
                -73.92845501928952,
                40.847031473785755
              ],
              [
                -73.92842200947663,
                40.84708268101525
              ],
              [
                -73.928391510366,
                40.84713222929974
              ],
              [
                -73.92821693262097,
                40.84738013744654
              ],
              [
                -73.92813798976522,
                40.8474916337005
              ],
              [
                -73.92797377830429,
                40.84772713626884
              ],
              [
                -73.92765334858233,
                40.848187403075315
              ],
              [
                -73.92752293332553,
                40.848379413766985
              ],
              [
                -73.92740369127668,
                40.848557312118054
              ],
              [
                -73.92725466369204,
                40.848763440050845
              ],
              [
                -73.92711679645474,
                40.848968160769864
              ],
              [
                -73.92692303519823,
                40.84925053211069
              ],
              [
                -73.9267143909258,
                40.849532892486465
              ],
              [
                -73.92649455759486,
                40.84983926318877
              ],
              [
                -73.92625607778918,
                40.85018092828886
              ],
              [
                -73.92623817249392,
                40.85020717547982
              ],
              [
                -73.92602877508632,
                40.850514127209415
              ],
              [
                -73.9258946157337,
                40.850716021158725
              ],
              [
                -73.92587970419625,
                40.850752743447984
              ],
              [
                -73.92585363309088,
                40.85077814704242
              ],
              [
                -73.92575544274924,
                40.85092631771205
              ],
              [
                -73.92573638681277,
                40.8509550715729
              ],
              [
                -73.92566267418448,
                40.851068937044985
              ],
              [
                -73.92563999034672,
                40.85109901600742
              ],
              [
                -73.92554359303259,
                40.851242951311264
              ],
              [
                -73.92553224547352,
                40.85127303036422
              ],
              [
                -73.92548973032294,
                40.8513267343892
              ],
              [
                -73.92542451604855,
                40.851422334244376
              ],
              [
                -73.92534796606829,
                40.851538348338785
              ],
              [
                -73.92529694152745,
                40.85160708720739
              ],
              [
                -73.92527707938275,
                40.85164791170491
              ],
              [
                -73.92521754031038,
                40.85173813710844
              ],
              [
                -73.92517217729034,
                40.851802592329285
              ],
              [
                -73.92515518101455,
                40.851815475919025
              ],
              [
                -73.92514666528864,
                40.85183695594673
              ],
              [
                -73.9251098027101,
                40.851892817511
              ],
              [
                -73.92507294866871,
                40.85194222070442
              ],
              [
                -73.92503326859799,
                40.851993784944625
              ],
              [
                -73.92502191229713,
                40.8520217104241
              ],
              [
                -73.92497396914037,
                40.85207090936957
              ],
              [
                -73.92467021184008,
                40.852506639577925
              ],
              [
                -73.92444065139927,
                40.852840472704436
              ],
              [
                -73.92400939220502,
                40.853436953917885
              ],
              [
                -73.92360595220674,
                40.853977198034684
              ],
              [
                -73.92341584183607,
                40.854225806362244
              ],
              [
                -73.923132985165,
                40.85458947031775
              ],
              [
                -73.92290114139594,
                40.8548855053087
              ],
              [
                -73.92281985673772,
                40.85498867651119
              ],
              [
                -73.922669296953,
                40.855179774960696
              ],
              [
                -73.92240035875272,
                40.85551005597432
              ],
              [
                -73.92227873064479,
                40.85565498884374
              ],
              [
                -73.92229611007241,
                40.855663835114505
              ],
              [
                -73.92234459387056,
                40.85568233590739
              ],
              [
                -73.92237580882698,
                40.85597834922766
              ],
              [
                -73.92237468673567,
                40.85621505323654
              ],
              [
                -73.92234465243865,
                40.85625312276869
              ],
              [
                -73.92237142612484,
                40.85628034182682
              ],
              [
                -73.92237756617774,
                40.85655807349529
              ],
              [
                -73.9223748457777,
                40.85656831197291
              ],
              [
                -73.92237001880541,
                40.85657809205398
              ],
              [
                -73.92236320797554,
                40.856587166207184
              ],
              [
                -73.92235458615987,
                40.856595304853194
              ],
              [
                -73.92234437219699,
                40.85660230078275
              ],
              [
                -73.92233282511857,
                40.85660797677499
              ],
              [
                -73.92229299113583,
                40.8566272949924
              ],
              [
                -73.92224682591336,
                40.85661396435389
              ],
              [
                -73.92221972224736,
                40.85664175431945
              ],
              [
                -73.92216714714418,
                40.856663482235554
              ],
              [
                -73.92210975280419,
                40.856720271081564
              ],
              [
                -73.92214637179917,
                40.85672634039559
              ],
              [
                -73.92216865123653,
                40.85673844640939
              ],
              [
                -73.92215747201129,
                40.85676624723815
              ],
              [
                -73.9220746492405,
                40.85677949112047
              ],
              [
                -73.92204492207946,
                40.856854159792
              ],
              [
                -73.92263334899997,
                40.856831752999895
              ],
              [
                -73.92277921199988,
                40.8568261979999
              ],
              [
                -73.92282845999992,
                40.856851408999916
              ],
              [
                -73.92295449499989,
                40.85885076199988
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000270639618955,
        "objectid": 149,
        "shape_leng": 0.0836805821837,
        "location_id": 149,
        "zone": "Madison",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.94405560899996,
                40.61199284199993
              ],
              [
                -73.94417450600004,
                40.612722537999936
              ],
              [
                -73.94430888199999,
                40.613411737999925
              ],
              [
                -73.94457366500001,
                40.61480377199996
              ],
              [
                -73.94479547299984,
                40.61588853099991
              ],
              [
                -73.9448277099998,
                40.61604620899986
              ],
              [
                -73.94573691899997,
                40.61536340699996
              ],
              [
                -73.94657366799993,
                40.61478913099988
              ],
              [
                -73.94742608799993,
                40.61422460099989
              ],
              [
                -73.94772682600002,
                40.61416971599985
              ],
              [
                -73.94834517399987,
                40.61413649599991
              ],
              [
                -73.94930527399987,
                40.614030734999886
              ],
              [
                -73.95026514899993,
                40.61392435199983
              ],
              [
                -73.94999598099999,
                40.612491460999884
              ],
              [
                -73.94996296699989,
                40.61231572699991
              ],
              [
                -73.94993049299987,
                40.61214465099991
              ],
              [
                -73.94978380499995,
                40.61137174999987
              ],
              [
                -73.95070512899999,
                40.61125045199992
              ],
              [
                -73.95163234999984,
                40.61112353899988
              ],
              [
                -73.95256553099985,
                40.61098718399993
              ],
              [
                -73.95258786900004,
                40.611112239999905
              ],
              [
                -73.9535988889999,
                40.61094986499993
              ],
              [
                -73.954795527,
                40.61082076599994
              ],
              [
                -73.95574066999986,
                40.6107148269999
              ],
              [
                -73.95670471399993,
                40.61061187799986
              ],
              [
                -73.95766801399986,
                40.61050371499993
              ],
              [
                -73.95804731899992,
                40.61046276199994
              ],
              [
                -73.95859278499987,
                40.61040303099988
              ],
              [
                -73.95828056999996,
                40.60874735399996
              ],
              [
                -73.95772401299996,
                40.60579983299993
              ],
              [
                -73.95753057699993,
                40.60482330899992
              ],
              [
                -73.957283474,
                40.60346964199993
              ],
              [
                -73.95683001699993,
                40.601087348999975
              ],
              [
                -73.95775745999987,
                40.60098588899995
              ],
              [
                -73.95733246399982,
                40.59873091399985
              ],
              [
                -73.956927197,
                40.59661207599988
              ],
              [
                -73.95600127899989,
                40.59671338599991
              ],
              [
                -73.95545313399988,
                40.59677338399992
              ],
              [
                -73.95507631399984,
                40.59681539099995
              ],
              [
                -73.95411036199994,
                40.596920738999856
              ],
              [
                -73.95314947399989,
                40.597027918999906
              ],
              [
                -73.95220370799984,
                40.59713035099988
              ],
              [
                -73.9510058549999,
                40.597262618999906
              ],
              [
                -73.94997334599992,
                40.59737744199987
              ],
              [
                -73.94904681599999,
                40.59747936199991
              ],
              [
                -73.94855032699991,
                40.59753296399993
              ],
              [
                -73.948120131,
                40.597579399999866
              ],
              [
                -73.94719207000004,
                40.597682086999946
              ],
              [
                -73.94622941199994,
                40.59778899199995
              ],
              [
                -73.94526519199995,
                40.5978936269999
              ],
              [
                -73.94479667499994,
                40.59794473299995
              ],
              [
                -73.94433922599985,
                40.597994625999945
              ],
              [
                -73.94387596499999,
                40.59804616299987
              ],
              [
                -73.94341159099987,
                40.598097814999896
              ],
              [
                -73.94248473299992,
                40.598200364999855
              ],
              [
                -73.94278945999999,
                40.599794549999956
              ],
              [
                -73.94283742399996,
                40.60004569399995
              ],
              [
                -73.94288954099984,
                40.600318649999934
              ],
              [
                -73.94189057599992,
                40.600427418999885
              ],
              [
                -73.9411660979999,
                40.60050801599989
              ],
              [
                -73.94089263599993,
                40.60053783599993
              ],
              [
                -73.93996699299997,
                40.600639447999875
              ],
              [
                -73.93952543799986,
                40.600688096999896
              ],
              [
                -73.93904134699991,
                40.60074143199989
              ],
              [
                -73.93811308699988,
                40.60084375699994
              ],
              [
                -73.93715062999982,
                40.600948865999925
              ],
              [
                -73.93619686399984,
                40.60105506999985
              ],
              [
                -73.93525981999983,
                40.6011423479999
              ],
              [
                -73.93447158899997,
                40.601231511999906
              ],
              [
                -73.93503274599996,
                40.60173378999984
              ],
              [
                -73.93426266699994,
                40.60223071099988
              ],
              [
                -73.9360940729999,
                40.6038731619999
              ],
              [
                -73.93791557899978,
                40.60549790099989
              ],
              [
                -73.9384349359999,
                40.605973862999896
              ],
              [
                -73.93972613899992,
                40.60713291499996
              ],
              [
                -73.93939269099997,
                40.60735090899986
              ],
              [
                -73.93933968999978,
                40.60738555999993
              ],
              [
                -73.93901246899993,
                40.607599476999965
              ],
              [
                -73.93829679599983,
                40.60806269399995
              ],
              [
                -73.93753749399987,
                40.60855738999986
              ],
              [
                -73.93934703399997,
                40.610187140999884
              ],
              [
                -73.94011528799989,
                40.60969107799988
              ],
              [
                -73.94082897899993,
                40.609229317999926
              ],
              [
                -73.9415475769999,
                40.608767735999905
              ],
              [
                -73.94187229999983,
                40.60855546699988
              ],
              [
                -73.94231175899985,
                40.608268196999866
              ],
              [
                -73.94354698,
                40.60934655499992
              ],
              [
                -73.94364091199994,
                40.6098588169999
              ],
              [
                -73.94369953899981,
                40.61021403899987
              ],
              [
                -73.94380031999991,
                40.610719118999924
              ],
              [
                -73.94405560899996,
                40.61199284199993
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000332568036088,
        "objectid": 150,
        "shape_leng": 0.089331491183,
        "location_id": 150,
        "zone": "Manhattan Beach",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.9301907789999,
                40.58508559299986
              ],
              [
                -73.93119181099983,
                40.58541372599994
              ],
              [
                -73.93126944999997,
                40.58543639299992
              ],
              [
                -73.93131673999989,
                40.58567129699988
              ],
              [
                -73.9313279159999,
                40.585737850999884
              ],
              [
                -73.93134323699987,
                40.585805633999875
              ],
              [
                -73.93139947599984,
                40.586079894999905
              ],
              [
                -73.93145727300002,
                40.58610127199996
              ],
              [
                -73.93210850499989,
                40.586286203999876
              ],
              [
                -73.93244497799995,
                40.58636028999989
              ],
              [
                -73.93344399399984,
                40.58653743799994
              ],
              [
                -73.9344471859999,
                40.58665207499994
              ],
              [
                -73.93477406299994,
                40.58663996999996
              ],
              [
                -73.93541271099994,
                40.586586054999934
              ],
              [
                -73.935885376,
                40.586532089999935
              ],
              [
                -73.93633646899995,
                40.58648058799985
              ],
              [
                -73.936775802,
                40.586398593999945
              ],
              [
                -73.93724717299983,
                40.586310629999936
              ],
              [
                -73.93816896699991,
                40.58614987199995
              ],
              [
                -73.93908601799997,
                40.5859919689999
              ],
              [
                -73.93914504099992,
                40.58597982499988
              ],
              [
                -73.93920635200004,
                40.58597168899993
              ],
              [
                -73.94105998299987,
                40.58566108399993
              ],
              [
                -73.94191610399989,
                40.58552017299993
              ],
              [
                -73.94291014799995,
                40.58540563799992
              ],
              [
                -73.94386629099992,
                40.5852983619999
              ],
              [
                -73.94493086699988,
                40.58517250599994
              ],
              [
                -73.94555221999997,
                40.585139491999946
              ],
              [
                -73.9466911319999,
                40.585073071999915
              ],
              [
                -73.94763574499989,
                40.584998861999956
              ],
              [
                -73.94866230599997,
                40.58489385599993
              ],
              [
                -73.94986974599985,
                40.58485639599989
              ],
              [
                -73.95123865700003,
                40.5849246329999
              ],
              [
                -73.95287969899992,
                40.58519841999987
              ],
              [
                -73.95289540899982,
                40.58529875299989
              ],
              [
                -73.95300767399999,
                40.58527458399993
              ],
              [
                -73.95312439499999,
                40.585261132999904
              ],
              [
                -73.95324286999993,
                40.58525898299989
              ],
              [
                -73.9533602969999,
                40.585268180999904
              ],
              [
                -73.95347394899987,
                40.58528824599992
              ],
              [
                -73.95357366099992,
                40.58530918699991
              ],
              [
                -73.953671153,
                40.585348497999895
              ],
              [
                -73.95375778800003,
                40.58540595999989
              ],
              [
                -73.95382536099993,
                40.585478261999874
              ],
              [
                -73.95386831199988,
                40.58555947099997
              ],
              [
                -73.95388521699984,
                40.58564255099988
              ],
              [
                -73.95387860700004,
                40.58572124599991
              ],
              [
                -73.95398040799992,
                40.5857118479999
              ],
              [
                -73.95439984999999,
                40.58567624799993
              ],
              [
                -73.95485543099988,
                40.585624776999914
              ],
              [
                -73.95576287300001,
                40.585500097999905
              ],
              [
                -73.95626771099987,
                40.58546620499987
              ],
              [
                -73.95641613599993,
                40.58539901099992
              ],
              [
                -73.95657432099985,
                40.58534434599988
              ],
              [
                -73.95673945299994,
                40.58530330799991
              ],
              [
                -73.95690846199993,
                40.58527652799989
              ],
              [
                -73.95757916399988,
                40.5852008269999
              ],
              [
                -73.95761659699984,
                40.58519750799996
              ],
              [
                -73.95765292099998,
                40.58519361399986
              ],
              [
                -73.95777933699982,
                40.585183528999906
              ],
              [
                -73.95880175500001,
                40.58518715099991
              ],
              [
                -73.95917287700001,
                40.585139105999865
              ],
              [
                -73.96007463299998,
                40.58505031599989
              ],
              [
                -73.96021742199999,
                40.585036254999906
              ],
              [
                -73.96025825899996,
                40.585029307999946
              ],
              [
                -73.96024315399984,
                40.58482708099993
              ],
              [
                -73.9602394339998,
                40.58479187399997
              ],
              [
                -73.96023555999999,
                40.584746675999895
              ],
              [
                -73.96023124399987,
                40.58467600499993
              ],
              [
                -73.96022753900004,
                40.584608958999894
              ],
              [
                -73.96021792199998,
                40.584514661999926
              ],
              [
                -73.96014924099997,
                40.58357589699988
              ],
              [
                -73.96010509500002,
                40.58340835699995
              ],
              [
                -73.96004798699995,
                40.58326987199995
              ],
              [
                -73.95900739999992,
                40.58283812799991
              ],
              [
                -73.95733608399979,
                40.58302513399991
              ],
              [
                -73.95723610100002,
                40.58304227099991
              ],
              [
                -73.9571329139998,
                40.583045725999966
              ],
              [
                -73.95703134199992,
                40.58303533199994
              ],
              [
                -73.95695595999992,
                40.58298226299991
              ],
              [
                -73.95689047299997,
                40.58293397899993
              ],
              [
                -73.95682583599991,
                40.5828863089999
              ],
              [
                -73.95675618299992,
                40.58283496999994
              ],
              [
                -73.95645729099982,
                40.58265885099992
              ],
              [
                -73.95604146799998,
                40.58273625399989
              ],
              [
                -73.95571160099988,
                40.58280458499993
              ],
              [
                -73.95517441499999,
                40.582915870999955
              ],
              [
                -73.95436572599993,
                40.583081077999964
              ],
              [
                -73.95414287899986,
                40.58310613999991
              ],
              [
                -73.95412132099985,
                40.58298298499989
              ],
              [
                -73.95411176199985,
                40.582919917999966
              ],
              [
                -73.95407172899988,
                40.582702397999896
              ],
              [
                -73.95403970599988,
                40.58251639099995
              ],
              [
                -73.95392000699991,
                40.58186979399993
              ],
              [
                -73.9538380169999,
                40.581745551999916
              ],
              [
                -73.9537406319999,
                40.58135589199988
              ],
              [
                -73.95334153899985,
                40.579267127999884
              ],
              [
                -73.95309963899994,
                40.57799415599989
              ],
              [
                -73.95303970899995,
                40.5776795869999
              ],
              [
                -73.95300123199996,
                40.577476511999855
              ],
              [
                -73.95287451099996,
                40.576882158999915
              ],
              [
                -73.95242628499996,
                40.57455910699991
              ],
              [
                -73.9523584672133,
                40.57427468584519
              ],
              [
                -73.95224221101466,
                40.57433344096679
              ],
              [
                -73.95207791647857,
                40.5743986028653
              ],
              [
                -73.95176309165655,
                40.57442195456104
              ],
              [
                -73.95151329579528,
                40.57442445750245
              ],
              [
                -73.95147653230822,
                40.57442444197993
              ],
              [
                -73.95132509565437,
                40.57442437791534
              ],
              [
                -73.95104789991738,
                40.57446078911595
              ],
              [
                -73.95081177068961,
                40.57449199818994
              ],
              [
                -73.9505311591345,
                40.57452057902976
              ],
              [
                -73.95053081571785,
                40.57452059915292
              ],
              [
                -73.95030872902427,
                40.57453352906245
              ],
              [
                -73.95018552432869,
                40.57455956843377
              ],
              [
                -73.9500657581515,
                40.57456212504081
              ],
              [
                -73.94991862626001,
                40.574551624739044
              ],
              [
                -73.94954065902311,
                40.57464998733391
              ],
              [
                -73.94938813625923,
                40.574689679862985
              ],
              [
                -73.94896375973461,
                40.574778204211455
              ],
              [
                -73.94868549287553,
                40.57480822367913
              ],
              [
                -73.94776716764005,
                40.57490728869874
              ],
              [
                -73.94708500319491,
                40.574980872126766
              ],
              [
                -73.9469198029282,
                40.57500518403111
              ],
              [
                -73.94680134069225,
                40.57501544034428
              ],
              [
                -73.94677953289835,
                40.57501732852101
              ],
              [
                -73.94671197360066,
                40.57502011722814
              ],
              [
                -73.94665101734034,
                40.575019426357464
              ],
              [
                -73.94592390235844,
                40.5751154323094
              ],
              [
                -73.94590897955277,
                40.575139259547086
              ],
              [
                -73.94592148276949,
                40.57519893359939
              ],
              [
                -73.94595330078268,
                40.575212017721306
              ],
              [
                -73.94615898850593,
                40.57518971074444
              ],
              [
                -73.94620550151313,
                40.57519906694626
              ],
              [
                -73.94619846661749,
                40.57524956178109
              ],
              [
                -73.94618237597034,
                40.57529883620686
              ],
              [
                -73.94615753638362,
                40.575345950613354
              ],
              [
                -73.94612442150431,
                40.5753900065771
              ],
              [
                -73.94608366278499,
                40.57543016399477
              ],
              [
                -73.94603603744247,
                40.57546565710265
              ],
              [
                -73.94598245363807,
                40.57549580908012
              ],
              [
                -73.9458985372183,
                40.57552242909964
              ],
              [
                -73.94581203002983,
                40.5755436874989
              ],
              [
                -73.94572352013991,
                40.57555943976494
              ],
              [
                -73.94563360923209,
                40.5755695788146
              ],
              [
                -73.94554290851462,
                40.575574035723626
              ],
              [
                -73.94545203456569,
                40.575572780194
              ],
              [
                -73.94518784085108,
                40.575559313062094
              ],
              [
                -73.944995277061,
                40.575555595685046
              ],
              [
                -73.94486926137374,
                40.57557366248061
              ],
              [
                -73.94470763999647,
                40.57552645321338
              ],
              [
                -73.94467689102792,
                40.57533610054031
              ],
              [
                -73.94464810794125,
                40.575261112339255
              ],
              [
                -73.9446271597642,
                40.575245466207605
              ],
              [
                -73.94460730835148,
                40.575233834609236
              ],
              [
                -73.94456942217181,
                40.57524755241893
              ],
              [
                -73.94456623438639,
                40.5752690239822
              ],
              [
                -73.94455272237937,
                40.57529349621064
              ],
              [
                -73.94463319129585,
                40.57569119674712
              ],
              [
                -73.94460727243747,
                40.57571622551796
              ],
              [
                -73.94457698614625,
                40.575738219165125
              ],
              [
                -73.94454293700002,
                40.57575673864667
              ],
              [
                -73.94450580469348,
                40.57577141427217
              ],
              [
                -73.94439308875675,
                40.575788813263394
              ],
              [
                -73.94427911427726,
                40.57580051170005
              ],
              [
                -73.94416437658232,
                40.575806458741084
              ],
              [
                -73.94404937431712,
                40.57580662854107
              ],
              [
                -73.94393460727696,
                40.57580102036193
              ],
              [
                -73.94382057423418,
                40.575789658576525
              ],
              [
                -73.94364149533129,
                40.5757728599584
              ],
              [
                -73.94347256946371,
                40.57574893063853
              ],
              [
                -73.94329220627851,
                40.575720285331506
              ],
              [
                -73.94311590561615,
                40.57567677484502
              ],
              [
                -73.94288748744692,
                40.57563246688344
              ],
              [
                -73.94274060818059,
                40.57559878801045
              ],
              [
                -73.94259862202954,
                40.57557071257776
              ],
              [
                -73.94257753090295,
                40.575568812618904
              ],
              [
                -73.94255712264767,
                40.575564330853865
              ],
              [
                -73.94253793710583,
                40.57555738583487
              ],
              [
                -73.94252048177572,
                40.57554816127269
              ],
              [
                -73.94250521838768,
                40.57553690117669
              ],
              [
                -73.94249255068962,
                40.575523903400516
              ],
              [
                -73.94248281376751,
                40.57550951176291
              ],
              [
                -73.9424762651813,
                40.575494106952924
              ],
              [
                -73.94248954076535,
                40.57548950597306
              ],
              [
                -73.94250173588297,
                40.57548340928891
              ],
              [
                -73.94251256292429,
                40.575475960685154
              ],
              [
                -73.94252176654372,
                40.575467335830226
              ],
              [
                -73.94252912968336,
                40.57545773813323
              ],
              [
                -73.94253447869173,
                40.5754473939465
              ],
              [
                -73.94253768741825,
                40.57543654722796
              ],
              [
                -73.94253867414574,
                40.57542503814424
              ],
              [
                -73.94253725163497,
                40.57541355561045
              ],
              [
                -73.94253345598342,
                40.57540239099203
              ],
              [
                -73.9425273835051,
                40.575391827587254
              ],
              [
                -73.94251918828812,
                40.57538213343873
              ],
              [
                -73.94250907828433,
                40.575373554531794
              ],
              [
                -73.9389240781322,
                40.57548947143868
              ],
              [
                -73.93684965167063,
                40.575556494513954
              ],
              [
                -73.93684376985449,
                40.57555843707146
              ],
              [
                -73.93683755115332,
                40.575559621733554
              ],
              [
                -73.93683116227929,
                40.575560016741726
              ],
              [
                -73.93682477450699,
                40.57555961150643
              ],
              [
                -73.93681855908139,
                40.575558416891425
              ],
              [
                -73.93681268262718,
                40.575556464921895
              ],
              [
                -73.93680730268137,
                40.57555380792715
              ],
              [
                -73.9368025634706,
                40.57555051713613
              ],
              [
                -73.93679859204515,
                40.575546680769264
              ],
              [
                -73.93679549487118,
                40.575542401672834
              ],
              [
                -73.93679335497849,
                40.575537794561626
              ],
              [
                -73.9367567450178,
                40.57542766108187
              ],
              [
                -73.93675673209019,
                40.575427623715704
              ],
              [
                -73.93674537686583,
                40.57542296532855
              ],
              [
                -73.93673315658057,
                40.57541982795627
              ],
              [
                -73.93672041869382,
                40.57541830080356
              ],
              [
                -73.93670752538155,
                40.57541842729197
              ],
              [
                -73.93669484323917,
                40.57542020382507
              ],
              [
                -73.93668273285745,
                40.575423579890796
              ],
              [
                -73.9366715385712,
                40.57542845949741
              ],
              [
                -73.93665097426798,
                40.57562271008414
              ],
              [
                -73.93662109585456,
                40.575722715958214
              ],
              [
                -73.93660358189786,
                40.575772406452614
              ],
              [
                -73.93656163438752,
                40.57581920144328
              ],
              [
                -73.93647107586584,
                40.575860496356334
              ],
              [
                -73.93623167905102,
                40.575850406099825
              ],
              [
                -73.93574425819364,
                40.57575056398321
              ],
              [
                -73.93530038281477,
                40.57563414818021
              ],
              [
                -73.93504799627776,
                40.57555434930602
              ],
              [
                -73.93496611332279,
                40.57548259278711
              ],
              [
                -73.93494759970542,
                40.575272085419236
              ],
              [
                -73.93494503400603,
                40.575270782939796
              ],
              [
                -73.93494222492475,
                40.57526981552538
              ],
              [
                -73.93493924706893,
                40.57526920887027
              ],
              [
                -73.93493617952893,
                40.57526897908671
              ],
              [
                -73.9349331037764,
                40.57526913227764
              ],
              [
                -73.93493010150159,
                40.57526966437434
              ],
              [
                -73.93492725244316,
                40.57527056124474
              ],
              [
                -73.93492463227011,
                40.575271799068425
              ],
              [
                -73.93492231057287,
                40.57527334496963
              ],
              [
                -73.9349203490142,
                40.575275157890175
              ],
              [
                -73.93492001314723,
                40.575275538662154
              ],
              [
                -73.93492129931023,
                40.57553735812247
              ],
              [
                -73.9349183826133,
                40.575558372887045
              ],
              [
                -73.93490632576673,
                40.57557530530585
              ],
              [
                -73.93488263802315,
                40.57557812179097
              ],
              [
                -73.93331645009378,
                40.57558763164092
              ],
              [
                -73.93307310428823,
                40.57560872400213
              ],
              [
                -73.93150256744042,
                40.57566050512675
              ],
              [
                -73.93144225619588,
                40.57570097093256
              ],
              [
                -73.93106288335652,
                40.57616310072336
              ],
              [
                -73.93106028107997,
                40.57626592009687
              ],
              [
                -73.93164069394192,
                40.578170809176015
              ],
              [
                -73.93169026330237,
                40.578178861649455
              ],
              [
                -73.93182688427206,
                40.57868047109542
              ],
              [
                -73.93181497833322,
                40.57895869723001
              ],
              [
                -73.93199576875995,
                40.5795210103253
              ],
              [
                -73.93202124664518,
                40.57954186892261
              ],
              [
                -73.9324506061651,
                40.58097120459654
              ],
              [
                -73.93250341155822,
                40.58114698863907
              ],
              [
                -73.93252433664163,
                40.58119604284197
              ],
              [
                -73.93255268134347,
                40.58124009494572
              ],
              [
                -73.9325800656803,
                40.58126474120341
              ],
              [
                -73.93264074804816,
                40.58127671947117
              ],
              [
                -73.93269753266526,
                40.58126928847301
              ],
              [
                -73.93274944870562,
                40.58123722558508
              ],
              [
                -73.9327788560717,
                40.58119843073623
              ],
              [
                -73.93280636427473,
                40.58114741868649
              ],
              [
                -73.93286916364703,
                40.58114848252055
              ],
              [
                -73.93287295022381,
                40.58128600047321
              ],
              [
                -73.93291340814964,
                40.58128373933714
              ],
              [
                -73.93290958744812,
                40.58114576736104
              ],
              [
                -73.93296350375749,
                40.58114517026216
              ],
              [
                -73.93297161890672,
                40.58128363007478
              ],
              [
                -73.93300294726038,
                40.58128140965124
              ],
              [
                -73.93300348916418,
                40.581144675417164
              ],
              [
                -73.93375801356129,
                40.58113204160584
              ],
              [
                -73.93376313431382,
                40.58118346622567
              ],
              [
                -73.93366279643807,
                40.581184599637325
              ],
              [
                -73.93366243426807,
                40.58116231381857
              ],
              [
                -73.93339931868996,
                40.58116480152509
              ],
              [
                -73.93340042885238,
                40.58121538886923
              ],
              [
                -73.93366325722222,
                40.58121290317209
              ],
              [
                -73.93366295933798,
                40.58119469455552
              ],
              [
                -73.9337631473704,
                40.58119356440797
              ],
              [
                -73.93376391735075,
                40.58123317233996
              ],
              [
                -73.93381328089814,
                40.581232615607576
              ],
              [
                -73.93381304804846,
                40.58122057898259
              ],
              [
                -73.93383627277876,
                40.58122031677364
              ],
              [
                -73.93383645111399,
                40.58123016141136
              ],
              [
                -73.93391404481234,
                40.58122934798396
              ],
              [
                -73.93509532596819,
                40.581210618963134
              ],
              [
                -73.93510615823921,
                40.58122139622253
              ],
              [
                -73.93510879115179,
                40.58130381702099
              ],
              [
                -73.93513789283365,
                40.58130327675363
              ],
              [
                -73.9351340770489,
                40.58118378955771
              ],
              [
                -73.93429954734847,
                40.581199276901735
              ],
              [
                -73.93383598074706,
                40.58120413761315
              ],
              [
                -73.93383615339322,
                40.58121373631436
              ],
              [
                -73.93381364630925,
                40.58121398905245
              ],
              [
                -73.93381280663905,
                40.58113292574201
              ],
              [
                -73.9347519384382,
                40.58111865415686
              ],
              [
                -73.93616960757984,
                40.58109709572432
              ],
              [
                -73.93710561356725,
                40.58108285258503
              ],
              [
                -73.93805680414177,
                40.5810683717811
              ],
              [
                -73.93899065793352,
                40.58105414563041
              ],
              [
                -73.94035765693272,
                40.581033307886806
              ],
              [
                -73.94124120272384,
                40.58101932764535
              ],
              [
                -73.94216538347959,
                40.581004699665165
              ],
              [
                -73.94274062592493,
                40.58099558955926
              ],
              [
                -73.94306208267815,
                40.58105626783241
              ],
              [
                -73.94413672913842,
                40.58125911318595
              ],
              [
                -73.94502940359521,
                40.58142927640804
              ],
              [
                -73.94600616631155,
                40.58161546108211
              ],
              [
                -73.9469768187521,
                40.581800471469556
              ],
              [
                -73.94800448116914,
                40.58199633761599
              ],
              [
                -73.94937473522309,
                40.582257483993565
              ],
              [
                -73.9496967249941,
                40.582319401066655
              ],
              [
                -73.95008839464253,
                40.582284807847294
              ],
              [
                -73.95102369462639,
                40.58220219549055
              ],
              [
                -73.95193849433237,
                40.58212209597103
              ],
              [
                -73.95233099098503,
                40.58208772664359
              ],
              [
                -73.95286292151859,
                40.58204114558686
              ],
              [
                -73.95330135995673,
                40.58200274963344
              ],
              [
                -73.95341929823668,
                40.5826082108862
              ],
              [
                -73.95343216344405,
                40.582674343320946
              ],
              [
                -73.95349322048675,
                40.58298819826098
              ],
              [
                -73.95247447970824,
                40.583077176927254
              ],
              [
                -73.95118226986268,
                40.583199265180184
              ],
              [
                -73.95025076956786,
                40.5832872646997
              ],
              [
                -73.94958266755164,
                40.58336143778913
              ],
              [
                -73.94836747924887,
                40.58348859676087
              ],
              [
                -73.94746867861319,
                40.58352819536239
              ],
              [
                -73.94701390883573,
                40.58318810097944
              ],
              [
                -73.9468220294719,
                40.58319119187112
              ],
              [
                -73.94726832705227,
                40.5835331825745
              ],
              [
                -73.946917962761,
                40.58354603324974
              ],
              [
                -73.94645496477298,
                40.583205168080084
              ],
              [
                -73.94631603648743,
                40.58320694021067
              ],
              [
                -73.94678945068769,
                40.58355046761415
              ],
              [
                -73.94643429059336,
                40.58356315259246
              ],
              [
                -73.945924129353,
                40.58319410443476
              ],
              [
                -73.9457994125887,
                40.583194640230225
              ],
              [
                -73.94628634691863,
                40.58356868570794
              ],
              [
                -73.945899118881,
                40.58355266261021
              ],
              [
                -73.94537605237885,
                40.5831819316505
              ],
              [
                -73.94526670124631,
                40.583182518567234
              ],
              [
                -73.94573014930472,
                40.583544874558775
              ],
              [
                -73.94534992503475,
                40.583529052110386
              ],
              [
                -73.94484512940836,
                40.58316423534613
              ],
              [
                -73.94471270964229,
                40.58316504453028
              ],
              [
                -73.94517477781615,
                40.583521378244185
              ],
              [
                -73.94477544081295,
                40.583507804409855
              ],
              [
                -73.94432059818067,
                40.58315942487761
              ],
              [
                -73.94417458523772,
                40.58315829121823
              ],
              [
                -73.9446182866623,
                40.583496626087715
              ],
              [
                -73.9442340358713,
                40.58347995164651
              ],
              [
                -73.9437651164146,
                40.583144718831555
              ],
              [
                -73.94363589795726,
                40.58314557390952
              ],
              [
                -73.9440676416711,
                40.58347200911033
              ],
              [
                -73.94369153004567,
                40.58346775426596
              ],
              [
                -73.94351205889528,
                40.583337045980464
              ],
              [
                -73.9432281554656,
                40.583130820211096
              ],
              [
                -73.94309334781462,
                40.58313454521438
              ],
              [
                -73.94354467530928,
                40.58347101436107
              ],
              [
                -73.9431950615802,
                40.583489662633205
              ],
              [
                -73.94268774005667,
                40.583122816572036
              ],
              [
                -73.94256316190977,
                40.58312734751645
              ],
              [
                -73.94304709645994,
                40.58349212946424
              ],
              [
                -73.94270167762977,
                40.58350879761406
              ],
              [
                -73.9421493881021,
                40.58310667955099
              ],
              [
                -73.94202033352745,
                40.5831104237825
              ],
              [
                -73.94255133565284,
                40.583514476170805
              ],
              [
                -73.9415740808356,
                40.58360755593934
              ],
              [
                -73.94154438657303,
                40.5835180832994
              ],
              [
                -73.94148833784251,
                40.583528736446475
              ],
              [
                -73.94144050857183,
                40.58334014747614
              ],
              [
                -73.94131115116105,
                40.58333662684797
              ],
              [
                -73.94126351164643,
                40.58333675629567
              ],
              [
                -73.94124697402302,
                40.58330137935952
              ],
              [
                -73.9412092982398,
                40.58329850488304
              ],
              [
                -73.94119384397212,
                40.58326360343946
              ],
              [
                -73.94115833809383,
                40.58312930454903
              ],
              [
                -73.94115408610509,
                40.58311322222525
              ],
              [
                -73.94120561662946,
                40.58309900307509
              ],
              [
                -73.94120155728876,
                40.58309046520494
              ],
              [
                -73.94115074719811,
                40.583103532454906
              ],
              [
                -73.94114534994199,
                40.583100179939024
              ],
              [
                -73.94109844565489,
                40.58309477669981
              ],
              [
                -73.94110624879366,
                40.58312802407002
              ],
              [
                -73.94110905972568,
                40.58314114122361
              ],
              [
                -73.94113089391008,
                40.583163110832146
              ],
              [
                -73.94113701228311,
                40.58316528347314
              ],
              [
                -73.94116258456303,
                40.58327511614186
              ],
              [
                -73.94115699675478,
                40.58329922746803
              ],
              [
                -73.94093422085362,
                40.5833023064367
              ],
              [
                -73.94092816033081,
                40.58325534247295
              ],
              [
                -73.94091736917896,
                40.583123377834916
              ],
              [
                -73.94091450362929,
                40.58308833841558
              ],
              [
                -73.94102584392368,
                40.583085497695045
              ],
              [
                -73.94102517918674,
                40.58307036190614
              ],
              [
                -73.94100408977755,
                40.583070747956384
              ],
              [
                -73.94097532797558,
                40.58307127447183
              ],
              [
                -73.94091637255394,
                40.58307235421962
              ],
              [
                -73.94084677123455,
                40.58307362932439
              ],
              [
                -73.94083081733403,
                40.5830739208196
              ],
              [
                -73.9407935538431,
                40.58307460323667
              ],
              [
                -73.94073840604624,
                40.583075612817495
              ],
              [
                -73.94073868464446,
                40.58309124295593
              ],
              [
                -73.94089710477411,
                40.58308760707977
              ],
              [
                -73.94090001047674,
                40.58312295058611
              ],
              [
                -73.94091645490734,
                40.58330240653128
              ],
              [
                -73.94069690323742,
                40.583302436824326
              ],
              [
                -73.9407001951476,
                40.583327624732135
              ],
              [
                -73.94063649330319,
                40.58332834119564
              ],
              [
                -73.94062855574232,
                40.58320529576527
              ],
              [
                -73.94058872809934,
                40.58320694745643
              ],
              [
                -73.9405884696085,
                40.58319543000866
              ],
              [
                -73.94058754647727,
                40.58315420589595
              ],
              [
                -73.94058727912824,
                40.58314227246784
              ],
              [
                -73.9405866718767,
                40.583115240775406
              ],
              [
                -73.94058568405266,
                40.58308597689124
              ],
              [
                -73.94067712191324,
                40.5830857907708
              ],
              [
                -73.94067657802263,
                40.58306580556734
              ],
              [
                -73.94065816747744,
                40.5830661068271
              ],
              [
                -73.94057550702213,
                40.583067456273604
              ],
              [
                -73.94056166659252,
                40.583067681310226
              ],
              [
                -73.94048659523155,
                40.583068906793656
              ],
              [
                -73.9404728145691,
                40.583069132353124
              ],
              [
                -73.94037688389578,
                40.58307069759491
              ],
              [
                -73.94036352490993,
                40.58307091632379
              ],
              [
                -73.94027941504933,
                40.5830722889292
              ],
              [
                -73.94026708883287,
                40.58307249059311
              ],
              [
                -73.94022518035786,
                40.58307317309136
              ],
              [
                -73.94021422135123,
                40.58307335351443
              ],
              [
                -73.94012576769904,
                40.583074796790015
              ],
              [
                -73.94010903682874,
                40.58307506968685
              ],
              [
                -73.94010952396489,
                40.5830929753204
              ],
              [
                -73.9404735774075,
                40.5830872204
              ],
              [
                -73.94047360621605,
                40.583093850727835
              ],
              [
                -73.94054229555765,
                40.58309368157399
              ],
              [
                -73.94054199309038,
                40.583088130329386
              ],
              [
                -73.9405660894776,
                40.58308736830808
              ],
              [
                -73.94056754325045,
                40.58311477034369
              ],
              [
                -73.94056916880331,
                40.583149147053916
              ],
              [
                -73.94056962801625,
                40.583159062186596
              ],
              [
                -73.94057214999937,
                40.58321354507143
              ],
              [
                -73.94057258161916,
                40.58322286210951
              ],
              [
                -73.94057495376235,
                40.58327411762927
              ],
              [
                -73.94057532545163,
                40.58328213645074
              ],
              [
                -73.94057745828908,
                40.58332822557355
              ],
              [
                -73.94027355766525,
                40.58332993058913
              ],
              [
                -73.9398585157681,
                40.583331334095426
              ],
              [
                -73.93985700196716,
                40.58317497237716
              ],
              [
                -73.93982883984603,
                40.58317053569223
              ],
              [
                -73.93982538850575,
                40.58333144625054
              ],
              [
                -73.93930153084881,
                40.58333321505447
              ],
              [
                -73.93929640384748,
                40.583167268734954
              ],
              [
                -73.93869745798771,
                40.58316493936689
              ],
              [
                -73.93868719999838,
                40.583139421503844
              ],
              [
                -73.93867832712031,
                40.58313795155602
              ],
              [
                -73.93866322813588,
                40.58310503987518
              ],
              [
                -73.93866851730543,
                40.583084065331484
              ],
              [
                -73.93873115333592,
                40.583089453828734
              ],
              [
                -73.9387826663899,
                40.58309483804126
              ],
              [
                -73.93878660441516,
                40.58307093787541
              ],
              [
                -73.93862755765123,
                40.583067021797504
              ],
              [
                -73.93862218897615,
                40.583103004925135
              ],
              [
                -73.93864663032167,
                40.58310636722805
              ],
              [
                -73.93866334157603,
                40.58314134490725
              ],
              [
                -73.93865999147236,
                40.583142084269014
              ],
              [
                -73.93866646107386,
                40.58315914171803
              ],
              [
                -73.9386684665775,
                40.583164428175046
              ],
              [
                -73.93847712885861,
                40.58317789727109
              ],
              [
                -73.93855276236503,
                40.5833379849913
              ],
              [
                -73.93833089139768,
                40.58334119827814
              ],
              [
                -73.93825745769908,
                40.58327956714172
              ],
              [
                -73.93823991479728,
                40.58311688913321
              ],
              [
                -73.9380942062777,
                40.58311681071695
              ],
              [
                -73.93811917695099,
                40.58334047899514
              ],
              [
                -73.93788732683,
                40.58334396766736
              ],
              [
                -73.93783180297176,
                40.58329309395867
              ],
              [
                -73.93732470759876,
                40.58329281845608
              ],
              [
                -73.93732049535753,
                40.58334048130114
              ],
              [
                -73.93729744072706,
                40.583348841508204
              ],
              [
                -73.93712459798441,
                40.58334673016416
              ],
              [
                -73.9370773777828,
                40.58332975569451
              ],
              [
                -73.93705106890114,
                40.58323547162591
              ],
              [
                -73.93701481021185,
                40.58323327236398
              ],
              [
                -73.93701423047526,
                40.58306606358339
              ],
              [
                -73.93700387324068,
                40.583065857037205
              ],
              [
                -73.93700520516775,
                40.583027065988155
              ],
              [
                -73.93700558408256,
                40.583016019471415
              ],
              [
                -73.93703051033472,
                40.58301651679284
              ],
              [
                -73.93703012691904,
                40.583027680242786
              ],
              [
                -73.93702675588713,
                40.5831258725993
              ],
              [
                -73.9370527874272,
                40.583126392468074
              ],
              [
                -73.9370535633145,
                40.58310377574734
              ],
              [
                -73.9376320432887,
                40.583115319712995
              ],
              [
                -73.9376326974329,
                40.58309626828403
              ],
              [
                -73.93705290451025,
                40.583084699478135
              ],
              [
                -73.93705623806854,
                40.58302832312092
              ],
              [
                -73.93705921677467,
                40.58297798232018
              ],
              [
                -73.93663307422491,
                40.58297221190406
              ],
              [
                -73.93663263829826,
                40.582990942126486
              ],
              [
                -73.93664922102909,
                40.5829911658764
              ],
              [
                -73.93666300283665,
                40.5829913523961
              ],
              [
                -73.93673190264388,
                40.58299228630497
              ],
              [
                -73.93674491407205,
                40.58299246200375
              ],
              [
                -73.93682584926127,
                40.5829935577815
              ],
              [
                -73.9368916774398,
                40.582994450368595
              ],
              [
                -73.93690397408103,
                40.582994615772506
              ],
              [
                -73.93693148518238,
                40.58299498821779
              ],
              [
                -73.936931007221,
                40.583015504784726
              ],
              [
                -73.93698767830983,
                40.58301627317647
              ],
              [
                -73.93698743671953,
                40.58302662803936
              ],
              [
                -73.93698651739236,
                40.58306617302943
              ],
              [
                -73.93697724711018,
                40.58306604782619
              ],
              [
                -73.93697427463773,
                40.58323032641129
              ],
              [
                -73.93662876045104,
                40.583242629029996
              ],
              [
                -73.93657592440762,
                40.58324050558993
              ],
              [
                -73.9365485548836,
                40.5832214552796
              ],
              [
                -73.93653912847077,
                40.583163620917986
              ],
              [
                -73.93631716129542,
                40.58317905120111
              ],
              [
                -73.93630860178921,
                40.58301953831565
              ],
              [
                -73.93630359862767,
                40.583019096950025
              ],
              [
                -73.93630169169033,
                40.58300973334176
              ],
              [
                -73.9363003120676,
                40.58300295567552
              ],
              [
                -73.9363490619603,
                40.583004602702836
              ],
              [
                -73.93635050713729,
                40.58297972186167
              ],
              [
                -73.936564367648,
                40.58298694422441
              ],
              [
                -73.93656607029142,
                40.58295764878639
              ],
              [
                -73.93635149114577,
                40.582950401904526
              ],
              [
                -73.93624037244722,
                40.5829520068767
              ],
              [
                -73.93624023910758,
                40.58294667199487
              ],
              [
                -73.93609668691353,
                40.582948745832866
              ],
              [
                -73.93609807701681,
                40.583004715850954
              ],
              [
                -73.93609824969617,
                40.5830116389341
              ],
              [
                -73.93612881237642,
                40.583011198070324
              ],
              [
                -73.9361286594258,
                40.58300547015331
              ],
              [
                -73.93612802211166,
                40.58297936452198
              ],
              [
                -73.93624185128186,
                40.582976153214894
              ],
              [
                -73.93624507152867,
                40.58300005559124
              ],
              [
                -73.93628723189404,
                40.58300090355827
              ],
              [
                -73.93628752264293,
                40.583009384060425
              ],
              [
                -73.9362893368138,
                40.58306024734306
              ],
              [
                -73.93627045070224,
                40.58305920974585
              ],
              [
                -73.93627418270637,
                40.58318123473924
              ],
              [
                -73.93625432045448,
                40.58318392412821
              ],
              [
                -73.93625253184901,
                40.58324718862198
              ],
              [
                -73.9362018028412,
                40.58326923582002
              ],
              [
                -73.93612717900335,
                40.5833143331585
              ],
              [
                -73.9358603647903,
                40.58328708780447
              ],
              [
                -73.93576657211078,
                40.5832775098497
              ],
              [
                -73.93575981919531,
                40.58315597669307
              ],
              [
                -73.93582690671904,
                40.58315194690377
              ],
              [
                -73.93582439275755,
                40.58302111555204
              ],
              [
                -73.93567353145987,
                40.583019424412576
              ],
              [
                -73.93567676468071,
                40.58315353070111
              ],
              [
                -73.9357193387555,
                40.58315269979287
              ],
              [
                -73.93572423542369,
                40.583253981456245
              ],
              [
                -73.93568953636128,
                40.58325484190387
              ],
              [
                -73.93569071639773,
                40.583275093189116
              ],
              [
                -73.9354404068802,
                40.58325396438772
              ],
              [
                -73.93527611180885,
                40.58324291418614
              ],
              [
                -73.93527605262638,
                40.58321782276441
              ],
              [
                -73.93528194372138,
                40.58309697687027
              ],
              [
                -73.93456057101987,
                40.58302017383225
              ],
              [
                -73.93456279121907,
                40.58312490630239
              ],
              [
                -73.93455992902854,
                40.58316541413151
              ],
              [
                -73.93446928196293,
                40.58315251128187
              ],
              [
                -73.93437970143313,
                40.58313903367363
              ],
              [
                -73.93437027873017,
                40.58311020468171
              ],
              [
                -73.93420772865394,
                40.58309425873808
              ],
              [
                -73.93420971486945,
                40.582979798893426
              ],
              [
                -73.93371378016602,
                40.582967184177306
              ],
              [
                -73.93348952833713,
                40.58296458804381
              ],
              [
                -73.9334914563774,
                40.583058717260364
              ],
              [
                -73.93338721555227,
                40.58303809112294
              ],
              [
                -73.93329332900153,
                40.58302794132647
              ],
              [
                -73.93313721070093,
                40.58298882945064
              ],
              [
                -73.93310519300422,
                40.58296769389288
              ],
              [
                -73.93304245856554,
                40.58294880928074
              ],
              [
                -73.93297306018958,
                40.58293898308529
              ],
              [
                -73.93294538593976,
                40.5829411671018
              ],
              [
                -73.93289556463691,
                40.58294509991835
              ],
              [
                -73.93286703550757,
                40.58294979451565
              ],
              [
                -73.93283754945256,
                40.58295861814764
              ],
              [
                -73.93280322794682,
                40.58295779393815
              ],
              [
                -73.9327786668411,
                40.58294102796516
              ],
              [
                -73.93275209395242,
                40.58287986868579
              ],
              [
                -73.93266897618398,
                40.58289070037483
              ],
              [
                -73.9326135465296,
                40.582915629097435
              ],
              [
                -73.93254303390566,
                40.58291174777677
              ],
              [
                -73.93238094220585,
                40.58298589501709
              ],
              [
                -73.93234737144762,
                40.58297755523921
              ],
              [
                -73.93233559258715,
                40.5830044292421
              ],
              [
                -73.93228100685351,
                40.58302423759583
              ],
              [
                -73.93226590414366,
                40.583016548137714
              ],
              [
                -73.93225833516155,
                40.58303062452619
              ],
              [
                -73.93220291301583,
                40.58304659240697
              ],
              [
                -73.93209376133852,
                40.58306764922299
              ],
              [
                -73.93206437602129,
                40.58307147129168
              ],
              [
                -73.9320183941152,
                40.58311112461856
              ],
              [
                -73.93198873707402,
                40.58294351160219
              ],
              [
                -73.93201379961046,
                40.5829259827501
              ],
              [
                -73.93237196954628,
                40.58296138469958
              ],
              [
                -73.9323769574282,
                40.58291850997811
              ],
              [
                -73.9319281118675,
                40.58286976893519
              ],
              [
                -73.93197721282121,
                40.583110543041705
              ],
              [
                -73.9319425895196,
                40.58310975022709
              ],
              [
                -73.93186345464171,
                40.58308286112595
              ],
              [
                -73.932029438,
                40.58414863699995
              ],
              [
                -73.9320424499999,
                40.584233959999914
              ],
              [
                -73.93116473799996,
                40.58431299099991
              ],
              [
                -73.93106383699993,
                40.58431846799987
              ],
              [
                -73.93107641299986,
                40.58437967599986
              ],
              [
                -73.9300961629999,
                40.584502075999886
              ],
              [
                -73.9301907789999,
                40.58508559299986
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000384563286473,
        "objectid": 121,
        "shape_leng": 0.0969153373445,
        "location_id": 121,
        "zone": "Hillcrest/Pomonok",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.79911195699995,
                40.738206139999875
              ],
              [
                -73.80000292499997,
                40.738237623999936
              ],
              [
                -73.80089151300002,
                40.7382690159999
              ],
              [
                -73.80132155499992,
                40.738284201999946
              ],
              [
                -73.80180834299986,
                40.738302654999885
              ],
              [
                -73.80268632099998,
                40.7383310519999
              ],
              [
                -73.80363600499999,
                40.73836085199987
              ],
              [
                -73.80461553500005,
                40.73839157799993
              ],
              [
                -73.80466543299995,
                40.738392984999926
              ],
              [
                -73.80471291600003,
                40.738393948999914
              ],
              [
                -73.80564550999999,
                40.7384186529999
              ],
              [
                -73.8065788389999,
                40.73844721099993
              ],
              [
                -73.80752082000002,
                40.73848084799993
              ],
              [
                -73.80805258499988,
                40.738500293999905
              ],
              [
                -73.80844891400001,
                40.738514786999865
              ],
              [
                -73.80893016799997,
                40.73853237999986
              ],
              [
                -73.809411422,
                40.73854997199989
              ],
              [
                -73.81040435599981,
                40.7385896489999
              ],
              [
                -73.8113972909998,
                40.738629306999904
              ],
              [
                -73.81227737799998,
                40.7386644509999
              ],
              [
                -73.813224018,
                40.73870225399991
              ],
              [
                -73.81407382,
                40.73878090299995
              ],
              [
                -73.81489497099994,
                40.73891574299995
              ],
              [
                -73.81499755799993,
                40.73893258599991
              ],
              [
                -73.81504998799984,
                40.73894313399993
              ],
              [
                -73.815038629,
                40.7388936679999
              ],
              [
                -73.81497208299996,
                40.738560715999874
              ],
              [
                -73.81483959999986,
                40.73791441399993
              ],
              [
                -73.8146939199999,
                40.737140479999894
              ],
              [
                -73.81469163099986,
                40.73594999399995
              ],
              [
                -73.81472294099993,
                40.73533802999991
              ],
              [
                -73.81474261099987,
                40.73490936899986
              ],
              [
                -73.81475217299983,
                40.73470101899988
              ],
              [
                -73.81475908299986,
                40.73455042099994
              ],
              [
                -73.81477621499985,
                40.734246805999874
              ],
              [
                -73.81480147499998,
                40.7337991599999
              ],
              [
                -73.81481416399997,
                40.73359959799995
              ],
              [
                -73.81481996299976,
                40.733510727999935
              ],
              [
                -73.8148269949999,
                40.73340297599987
              ],
              [
                -73.81483204899995,
                40.733325524999884
              ],
              [
                -73.81483811099991,
                40.7332326189999
              ],
              [
                -73.81484566899994,
                40.733116797999905
              ],
              [
                -73.81485170599991,
                40.73302427699986
              ],
              [
                -73.81485676300004,
                40.73294679699997
              ],
              [
                -73.81486255999994,
                40.732857955999854
              ],
              [
                -73.81486810699997,
                40.7327729559999
              ],
              [
                -73.81487391699997,
                40.732683920999904
              ],
              [
                -73.81487971599991,
                40.732595050999876
              ],
              [
                -73.81488551400004,
                40.73250620899991
              ],
              [
                -73.81489282099993,
                40.732394229999976
              ],
              [
                -73.81489836700001,
                40.73230923099992
              ],
              [
                -73.81491341999994,
                40.732078588999904
              ],
              [
                -73.81491646899985,
                40.73203762099995
              ],
              [
                -73.81492083199987,
                40.731979832999855
              ],
              [
                -73.81492410799987,
                40.731930747999854
              ],
              [
                -73.81492830299987,
                40.73186790399991
              ],
              [
                -73.81493851899984,
                40.731714870999916
              ],
              [
                -73.81494260199989,
                40.73165369899991
              ],
              [
                -73.81496058399995,
                40.7313843389999
              ],
              [
                -73.81496454700003,
                40.73132495999995
              ],
              [
                -73.81497202699997,
                40.731212908999915
              ],
              [
                -73.81497572599984,
                40.731157492999934
              ],
              [
                -73.81502002699983,
                40.7304938719999
              ],
              [
                -73.81502127700006,
                40.73046566999987
              ],
              [
                -73.81504493700004,
                40.730398834999946
              ],
              [
                -73.81514620799994,
                40.729685964999945
              ],
              [
                -73.81523144899997,
                40.72879391199996
              ],
              [
                -73.81517739599994,
                40.72866695299993
              ],
              [
                -73.81517732599985,
                40.72866678199994
              ],
              [
                -73.81517723199983,
                40.72866662799988
              ],
              [
                -73.81510037099987,
                40.728543638999895
              ],
              [
                -73.81510024099985,
                40.72854342299992
              ],
              [
                -73.81510007599998,
                40.728543224999875
              ],
              [
                -73.81500114199996,
                40.72842749499996
              ],
              [
                -73.81500104699994,
                40.728427377999914
              ],
              [
                -73.81500091699995,
                40.728427258999936
              ],
              [
                -73.81488194899997,
                40.72832209599993
              ],
              [
                -73.81488183099987,
                40.72832198699994
              ],
              [
                -73.81488170099983,
                40.72832189699996
              ],
              [
                -73.81474600600002,
                40.72823021399993
              ],
              [
                -73.81474579299991,
                40.72823006999991
              ],
              [
                -73.8147455559999,
                40.72822995299987
              ],
              [
                -73.81459716399995,
                40.72815380899989
              ],
              [
                -73.81459693999996,
                40.72815369099989
              ],
              [
                -73.81459667899993,
                40.72815359199995
              ],
              [
                -73.81443976499985,
                40.72809402299991
              ],
              [
                -73.81394369400002,
                40.72785224499995
              ],
              [
                -73.81280168299986,
                40.72729564399991
              ],
              [
                -73.81246807499996,
                40.72713304499993
              ],
              [
                -73.81151613699981,
                40.72665799399994
              ],
              [
                -73.81074426599986,
                40.726265112999926
              ],
              [
                -73.810693702,
                40.72648898699988
              ],
              [
                -73.81068815299999,
                40.72662953899988
              ],
              [
                -73.81068815299979,
                40.72662962799992
              ],
              [
                -73.81068816499996,
                40.726629718999966
              ],
              [
                -73.81070499699992,
                40.72677121699991
              ],
              [
                -73.81070500999995,
                40.72677128899988
              ],
              [
                -73.81070503300002,
                40.726771351999915
              ],
              [
                -73.81074434999985,
                40.72691076199989
              ],
              [
                -73.81074438399986,
                40.726910887999985
              ],
              [
                -73.81074444399991,
                40.72691102299994
              ],
              [
                -73.8108053549999,
                40.72704517299991
              ],
              [
                -73.81080542499983,
                40.727045334999914
              ],
              [
                -73.81080551900001,
                40.72704547999993
              ],
              [
                -73.81088635099984,
                40.72717185599988
              ],
              [
                -73.81101008099996,
                40.72743995099991
              ],
              [
                -73.8111179729999,
                40.727816415999946
              ],
              [
                -73.80985637299993,
                40.72797360399989
              ],
              [
                -73.80895751599999,
                40.72807345899994
              ],
              [
                -73.80806424099987,
                40.728170002999896
              ],
              [
                -73.80717042299993,
                40.728269132999884
              ],
              [
                -73.80704045699993,
                40.72759650399991
              ],
              [
                -73.80691166299997,
                40.726912665999926
              ],
              [
                -73.80678542899982,
                40.72623219899988
              ],
              [
                -73.80665182000001,
                40.72555305099993
              ],
              [
                -73.80652142399995,
                40.7248730639999
              ],
              [
                -73.80497988299993,
                40.72503869699989
              ],
              [
                -73.80487958299982,
                40.725049592999895
              ],
              [
                -73.80480892999992,
                40.72435438799994
              ],
              [
                -73.80473910599993,
                40.72363153599985
              ],
              [
                -73.80466720799983,
                40.72290600699991
              ],
              [
                -73.8045963239999,
                40.72220220899997
              ],
              [
                -73.80453391999993,
                40.72151758799988
              ],
              [
                -73.80452680199981,
                40.721462545999884
              ],
              [
                -73.8045993129999,
                40.721441120999934
              ],
              [
                -73.80475913799987,
                40.72138373999997
              ],
              [
                -73.80550258899996,
                40.72111680899995
              ],
              [
                -73.80578893899983,
                40.7210187299999
              ],
              [
                -73.80636387699998,
                40.72082022699994
              ],
              [
                -73.80722012300005,
                40.72052512499991
              ],
              [
                -73.80786105999998,
                40.72030243799989
              ],
              [
                -73.80808460099985,
                40.72022476199991
              ],
              [
                -73.80904032699983,
                40.71991160499987
              ],
              [
                -73.80902609799992,
                40.7198627919999
              ],
              [
                -73.80861283999984,
                40.71844559999988
              ],
              [
                -73.80823529499995,
                40.71723813199988
              ],
              [
                -73.80809856399999,
                40.71680213799987
              ],
              [
                -73.80784387599995,
                40.715999733999915
              ],
              [
                -73.80782231800002,
                40.71591146599993
              ],
              [
                -73.80780927699995,
                40.715853895999906
              ],
              [
                -73.80670677799995,
                40.71588523999989
              ],
              [
                -73.80579237899985,
                40.71594264899989
              ],
              [
                -73.80494148499987,
                40.715996230999956
              ],
              [
                -73.80464476399993,
                40.716034396999866
              ],
              [
                -73.80394838899987,
                40.716102911999926
              ],
              [
                -73.80319837999987,
                40.7162025249999
              ],
              [
                -73.80313952600001,
                40.716213906999954
              ],
              [
                -73.80308484099997,
                40.7162201619999
              ],
              [
                -73.80188321,
                40.71640881099992
              ],
              [
                -73.8009273429999,
                40.7166039329999
              ],
              [
                -73.8002167269999,
                40.71673785899986
              ],
              [
                -73.80004693799998,
                40.716781906999906
              ],
              [
                -73.79959535799995,
                40.71689904299997
              ],
              [
                -73.79912568399995,
                40.71703434599991
              ],
              [
                -73.79802996199992,
                40.71735242599988
              ],
              [
                -73.79741025099996,
                40.717532907999946
              ],
              [
                -73.79653978099992,
                40.71778674899987
              ],
              [
                -73.79563775099989,
                40.71807616099995
              ],
              [
                -73.79480794899995,
                40.71837515899991
              ],
              [
                -73.7944373599998,
                40.71850085799991
              ],
              [
                -73.79399335099997,
                40.71864513999992
              ],
              [
                -73.79315493399994,
                40.718917578999864
              ],
              [
                -73.79309042899993,
                40.71893343699986
              ],
              [
                -73.79261566399995,
                40.71904520999994
              ],
              [
                -73.79249599699979,
                40.719067435999904
              ],
              [
                -73.79211141199988,
                40.71913886399995
              ],
              [
                -73.79176986799989,
                40.71917860699989
              ],
              [
                -73.79155692199994,
                40.71920099499993
              ],
              [
                -73.791417731,
                40.71920231099992
              ],
              [
                -73.79073248899982,
                40.719208756999876
              ],
              [
                -73.79070834499987,
                40.71926865199993
              ],
              [
                -73.79053210899994,
                40.71975195999992
              ],
              [
                -73.79050800399992,
                40.71980649699989
              ],
              [
                -73.79037661699995,
                40.72010372399992
              ],
              [
                -73.79030267399999,
                40.7208914649999
              ],
              [
                -73.79029346799996,
                40.721376148999894
              ],
              [
                -73.79035703899997,
                40.7230052999999
              ],
              [
                -73.79045149300003,
                40.72355453199985
              ],
              [
                -73.79088077399986,
                40.72418885899991
              ],
              [
                -73.79136702000001,
                40.72495161799992
              ],
              [
                -73.79147613699985,
                40.72513846499991
              ],
              [
                -73.79157252999995,
                40.7253296119999
              ],
              [
                -73.79165577199997,
                40.72552430799988
              ],
              [
                -73.7917255479998,
                40.725721754999896
              ],
              [
                -73.79174472699992,
                40.725788849999944
              ],
              [
                -73.79176169599994,
                40.7258396519999
              ],
              [
                -73.79191210299997,
                40.72620816199992
              ],
              [
                -73.79204513299997,
                40.72655107399986
              ],
              [
                -73.79229974199997,
                40.72714071899992
              ],
              [
                -73.79283910399985,
                40.728429007999914
              ],
              [
                -73.79322750899982,
                40.72934789399992
              ],
              [
                -73.79352516299998,
                40.73005381599991
              ],
              [
                -73.79378938699996,
                40.73070118199996
              ],
              [
                -73.79384983499995,
                40.73124678299997
              ],
              [
                -73.79381937199997,
                40.73165738799989
              ],
              [
                -73.793909425,
                40.731717775999904
              ],
              [
                -73.79392707899997,
                40.73199969499992
              ],
              [
                -73.79397708699996,
                40.73229685299988
              ],
              [
                -73.7942776749999,
                40.73269614499986
              ],
              [
                -73.79438449799987,
                40.73286592899996
              ],
              [
                -73.79447360099982,
                40.73304250799991
              ],
              [
                -73.79454395199986,
                40.733224229999905
              ],
              [
                -73.79459486499994,
                40.7334093579999
              ],
              [
                -73.794626052,
                40.73359608899993
              ],
              [
                -73.79459579399989,
                40.73454424599992
              ],
              [
                -73.79458633199991,
                40.735052251999875
              ],
              [
                -73.79516450899986,
                40.73641140399996
              ],
              [
                -73.79594852799994,
                40.73781371899989
              ],
              [
                -73.79584803299987,
                40.73807467999991
              ],
              [
                -73.79651029099988,
                40.7379086369999
              ],
              [
                -73.79650350099979,
                40.73800383599995
              ],
              [
                -73.79607605199982,
                40.738144297999966
              ],
              [
                -73.79636108899999,
                40.73812415299987
              ],
              [
                -73.79639000899994,
                40.73823357999986
              ],
              [
                -73.79640218700004,
                40.73828235799987
              ],
              [
                -73.79684795799999,
                40.738235540999916
              ],
              [
                -73.79729260599996,
                40.738185428999955
              ],
              [
                -73.79822058899992,
                40.7381746309999
              ],
              [
                -73.79911195699995,
                40.738206139999875
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000226597104976,
        "objectid": 122,
        "shape_leng": 0.0665318439446,
        "location_id": 122,
        "zone": "Hollis",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.75725671499985,
                40.718138601999904
              ],
              [
                -73.75832728699997,
                40.71958208899985
              ],
              [
                -73.75839264999983,
                40.71955392299988
              ],
              [
                -73.75878322199996,
                40.71938563599986
              ],
              [
                -73.75921639499985,
                40.71919899199988
              ],
              [
                -73.7600295979999,
                40.71884681499989
              ],
              [
                -73.76084486799981,
                40.71849351999988
              ],
              [
                -73.76166150099981,
                40.71814054599985
              ],
              [
                -73.76247897300004,
                40.71778561499992
              ],
              [
                -73.76335501799993,
                40.717411382999934
              ],
              [
                -73.76437291199994,
                40.71696662599988
              ],
              [
                -73.765714472,
                40.71639337899988
              ],
              [
                -73.76664095199988,
                40.71764661399986
              ],
              [
                -73.76733228200001,
                40.717289796999886
              ],
              [
                -73.76817892099992,
                40.71699299399994
              ],
              [
                -73.76906210699998,
                40.716683767999896
              ],
              [
                -73.769139141,
                40.716656798999914
              ],
              [
                -73.76922535799989,
                40.716626587999876
              ],
              [
                -73.77007984799988,
                40.716327201999896
              ],
              [
                -73.77110410799997,
                40.71597506299987
              ],
              [
                -73.77192122100001,
                40.71568504499994
              ],
              [
                -73.77113435099984,
                40.71450193699997
              ],
              [
                -73.77039451900004,
                40.71340019799992
              ],
              [
                -73.76946291199997,
                40.71201124799997
              ],
              [
                -73.76943987099992,
                40.711976892999864
              ],
              [
                -73.76962552399995,
                40.71178631999994
              ],
              [
                -73.7700680079999,
                40.711353867999904
              ],
              [
                -73.7706583899999,
                40.7108188429999
              ],
              [
                -73.77076570499997,
                40.71073529199989
              ],
              [
                -73.77076577600003,
                40.710735237999884
              ],
              [
                -73.770765846,
                40.71073519299989
              ],
              [
                -73.77089074799977,
                40.71065160299992
              ],
              [
                -73.77102469499991,
                40.710576640999925
              ],
              [
                -73.77102481399992,
                40.710576578999884
              ],
              [
                -73.77102494399993,
                40.710576515999925
              ],
              [
                -73.77118657799993,
                40.710499509999885
              ],
              [
                -73.77130086399985,
                40.710449730999855
              ],
              [
                -73.7713009589999,
                40.71044968699987
              ],
              [
                -73.77130105399985,
                40.71044965099993
              ],
              [
                -73.77144123499994,
                40.71039604199993
              ],
              [
                -73.7714414249999,
                40.71039596999994
              ],
              [
                -73.77144162599984,
                40.71039590799989
              ],
              [
                -73.77158658399989,
                40.71035031499988
              ],
              [
                -73.77158671399987,
                40.71035026999989
              ],
              [
                -73.77158686799989,
                40.71035023399988
              ],
              [
                -73.77172444699998,
                40.71031681899992
              ],
              [
                -73.77169204899995,
                40.7102639849999
              ],
              [
                -73.77165155299986,
                40.71022237199992
              ],
              [
                -73.77147819699991,
                40.71022223599994
              ],
              [
                -73.77115938700001,
                40.71020522199995
              ],
              [
                -73.77084362899987,
                40.7101716259999
              ],
              [
                -73.76881217299984,
                40.709808123999885
              ],
              [
                -73.76865794899992,
                40.70973774499994
              ],
              [
                -73.76844546499984,
                40.709637026999886
              ],
              [
                -73.76825969099993,
                40.70952636799997
              ],
              [
                -73.76789085799992,
                40.70840640299988
              ],
              [
                -73.767762698,
                40.70799573499992
              ],
              [
                -73.7675558879999,
                40.707321957999895
              ],
              [
                -73.76734431899995,
                40.70663634599993
              ],
              [
                -73.76725075899984,
                40.70631255799994
              ],
              [
                -73.767052985,
                40.705470301999895
              ],
              [
                -73.76701441799999,
                40.705268130999904
              ],
              [
                -73.7667915569999,
                40.704008526999885
              ],
              [
                -73.76665413499987,
                40.70333761699992
              ],
              [
                -73.766572825,
                40.70275162099996
              ],
              [
                -73.76636925199999,
                40.70256339699992
              ],
              [
                -73.76612489399999,
                40.702334485999906
              ],
              [
                -73.76544147799987,
                40.7025123149999
              ],
              [
                -73.76455359299997,
                40.702742996999866
              ],
              [
                -73.76366846199983,
                40.70297297499996
              ],
              [
                -73.76278023599987,
                40.70320597499995
              ],
              [
                -73.76262946699983,
                40.70324155099993
              ],
              [
                -73.76126359299997,
                40.70359893999992
              ],
              [
                -73.76095546600004,
                40.70290957799994
              ],
              [
                -73.76006675299989,
                40.70314071099992
              ],
              [
                -73.75918228200003,
                40.70337203399988
              ],
              [
                -73.75829554399995,
                40.70360338099991
              ],
              [
                -73.75740580399986,
                40.70383535499994
              ],
              [
                -73.75651887399991,
                40.70406425799995
              ],
              [
                -73.75561453899982,
                40.70430046399987
              ],
              [
                -73.75471127099998,
                40.704537890999895
              ],
              [
                -73.75382395799984,
                40.70476583499989
              ],
              [
                -73.752937772,
                40.70499820599988
              ],
              [
                -73.75205066499986,
                40.70522802799995
              ],
              [
                -73.75052149099987,
                40.70562662299989
              ],
              [
                -73.75047058199985,
                40.70563993099994
              ],
              [
                -73.7507743589998,
                40.706321836999926
              ],
              [
                -73.75121246999993,
                40.707306579999965
              ],
              [
                -73.75139828700003,
                40.70774871999987
              ],
              [
                -73.75184269899988,
                40.70882292099989
              ],
              [
                -73.75245965599989,
                40.71031656399992
              ],
              [
                -73.75318399399988,
                40.71205517599987
              ],
              [
                -73.75357512199989,
                40.712992296999886
              ],
              [
                -73.75360661900004,
                40.71307112199987
              ],
              [
                -73.75370834599993,
                40.713324636999886
              ],
              [
                -73.75401024499983,
                40.71383490099994
              ],
              [
                -73.7540478399999,
                40.713877538999945
              ],
              [
                -73.75407540999991,
                40.7139046949999
              ],
              [
                -73.75470078399988,
                40.714710052999905
              ],
              [
                -73.75588877999999,
                40.716298643999885
              ],
              [
                -73.75725671499985,
                40.718138601999904
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000296445173366,
        "objectid": 123,
        "shape_leng": 0.0834211389387,
        "location_id": 123,
        "zone": "Homecrest",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.96285973999984,
                40.61254948599989
              ],
              [
                -73.96385332599989,
                40.612432518999874
              ],
              [
                -73.96478112799986,
                40.61233092199989
              ],
              [
                -73.96429649899991,
                40.60977642599992
              ],
              [
                -73.96522378099996,
                40.609674400999936
              ],
              [
                -73.96629835499999,
                40.60955521299994
              ],
              [
                -73.96739979599994,
                40.60945960699996
              ],
              [
                -73.96768635599992,
                40.60941778699993
              ],
              [
                -73.96792168899995,
                40.60938918999989
              ],
              [
                -73.96904086599989,
                40.609253192999894
              ],
              [
                -73.96996969299995,
                40.60915048299991
              ],
              [
                -73.9708953839999,
                40.60904971899988
              ],
              [
                -73.97191331299986,
                40.60893592099993
              ],
              [
                -73.97299433999983,
                40.608814141999886
              ],
              [
                -73.97258982399998,
                40.6066715329999
              ],
              [
                -73.97239735499981,
                40.605651889999876
              ],
              [
                -73.97217742800002,
                40.60419471199986
              ],
              [
                -73.97235152099995,
                40.60334811899988
              ],
              [
                -73.972452617,
                40.6026170539999
              ],
              [
                -73.9725653369999,
                40.601794372999905
              ],
              [
                -73.97291116900001,
                40.59931580399996
              ],
              [
                -73.9719108049999,
                40.599423058999896
              ],
              [
                -73.97176105299991,
                40.59865193199991
              ],
              [
                -73.97163038199992,
                40.59794401699991
              ],
              [
                -73.97148350899987,
                40.59717158499997
              ],
              [
                -73.97132951099988,
                40.59635243099994
              ],
              [
                -73.97153324499989,
                40.59636870899992
              ],
              [
                -73.97169554999981,
                40.59548854199992
              ],
              [
                -73.97176509099998,
                40.59511141099989
              ],
              [
                -73.97189684799982,
                40.594352510999904
              ],
              [
                -73.97178220299999,
                40.59375608399992
              ],
              [
                -73.97155530499995,
                40.59256045799991
              ],
              [
                -73.97141847599984,
                40.591838509999924
              ],
              [
                -73.97128748899985,
                40.591159501999876
              ],
              [
                -73.97115101799994,
                40.590442443999905
              ],
              [
                -73.97022337699987,
                40.59054017499991
              ],
              [
                -73.96929959299999,
                40.590647588999964
              ],
              [
                -73.96837099999982,
                40.59074946599988
              ],
              [
                -73.96787702199993,
                40.590803755999964
              ],
              [
                -73.96744527499995,
                40.59085120999989
              ],
              [
                -73.9665195109999,
                40.590952872999885
              ],
              [
                -73.96542523899981,
                40.59107142799992
              ],
              [
                -73.9652882789999,
                40.59108660999993
              ],
              [
                -73.96514385199995,
                40.5911019159999
              ],
              [
                -73.96483485600002,
                40.59113696899988
              ],
              [
                -73.96377366299998,
                40.59125722899989
              ],
              [
                -73.96283785899992,
                40.59136011199993
              ],
              [
                -73.9606798409999,
                40.59159758199995
              ],
              [
                -73.9606241039998,
                40.59086268099987
              ],
              [
                -73.96057230499996,
                40.590181426999926
              ],
              [
                -73.96051667199988,
                40.5894529119999
              ],
              [
                -73.96045968399986,
                40.58872381999986
              ],
              [
                -73.96040710199983,
                40.58803435199996
              ],
              [
                -73.96034953599988,
                40.58730628599994
              ],
              [
                -73.95978030299993,
                40.587369474999875
              ],
              [
                -73.95934736799988,
                40.58741753699991
              ],
              [
                -73.95819543199988,
                40.58754433199988
              ],
              [
                -73.9571256639999,
                40.58766096099986
              ],
              [
                -73.95619313699986,
                40.58776226899994
              ],
              [
                -73.95622721599997,
                40.587995914999944
              ],
              [
                -73.95639200299993,
                40.58892867499993
              ],
              [
                -73.95658790599994,
                40.58988411499995
              ],
              [
                -73.95665683399993,
                40.590101652999905
              ],
              [
                -73.9567157929999,
                40.590498733999866
              ],
              [
                -73.95700330399997,
                40.59200225599987
              ],
              [
                -73.95607720399984,
                40.592101431999936
              ],
              [
                -73.95655540499982,
                40.59464772499994
              ],
              [
                -73.956927197,
                40.59661207599988
              ],
              [
                -73.95733246399982,
                40.59873091399985
              ],
              [
                -73.95775745999987,
                40.60098588899995
              ],
              [
                -73.95683001599991,
                40.601087348999954
              ],
              [
                -73.957283474,
                40.60346964199993
              ],
              [
                -73.95753057699993,
                40.60482330899992
              ],
              [
                -73.95772401299996,
                40.60579983299993
              ],
              [
                -73.95828056999996,
                40.60874735399996
              ],
              [
                -73.95859278499987,
                40.61040303099988
              ],
              [
                -73.95952069599996,
                40.610302052999884
              ],
              [
                -73.9604436189999,
                40.61020150699987
              ],
              [
                -73.96137268399995,
                40.61009887499992
              ],
              [
                -73.96237947799978,
                40.609986373999966
              ],
              [
                -73.96285973999984,
                40.61254948599989
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000754837821479,
        "objectid": 124,
        "shape_leng": 0.127724410062,
        "location_id": 124,
        "zone": "Howard Beach",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.850964537,
                40.67171951099988
              ],
              [
                -73.85163460999999,
                40.67193033299986
              ],
              [
                -73.85283803899996,
                40.6722791889999
              ],
              [
                -73.85648739299987,
                40.67337677399991
              ],
              [
                -73.8571134909999,
                40.67357505499996
              ],
              [
                -73.85825153699983,
                40.67413561099991
              ],
              [
                -73.85928317999998,
                40.67445271599995
              ],
              [
                -73.85959262199998,
                40.674556409999894
              ],
              [
                -73.86029565599982,
                40.67477189399992
              ],
              [
                -73.86131863599985,
                40.6750212689999
              ],
              [
                -73.86106294999989,
                40.67434293499984
              ],
              [
                -73.86107192599995,
                40.67401981399992
              ],
              [
                -73.86077512799977,
                40.67280562199989
              ],
              [
                -73.86038937900001,
                40.67126877499987
              ],
              [
                -73.85947416499985,
                40.67138053199996
              ],
              [
                -73.85855480399997,
                40.67153293399988
              ],
              [
                -73.85763323099987,
                40.67165619399988
              ],
              [
                -73.85730947799988,
                40.67041623299986
              ],
              [
                -73.8572848669999,
                40.67032198899989
              ],
              [
                -73.85726744599997,
                40.670255293999965
              ],
              [
                -73.85724675799997,
                40.67017405099994
              ],
              [
                -73.85722693699994,
                40.67009621199988
              ],
              [
                -73.85684676199999,
                40.668602934999925
              ],
              [
                -73.85646311899993,
                40.66704176299991
              ],
              [
                -73.85610973799986,
                40.66550601099988
              ],
              [
                -73.85568461199988,
                40.66386749199993
              ],
              [
                -73.8561171019999,
                40.663808401999844
              ],
              [
                -73.85659075099987,
                40.66374715999991
              ],
              [
                -73.85753563299991,
                40.66359786299995
              ],
              [
                -73.85842950899992,
                40.66345335999987
              ],
              [
                -73.85811779299999,
                40.66203657099988
              ],
              [
                -73.8580250429998,
                40.661721049999905
              ],
              [
                -73.85792219800001,
                40.66131885599989
              ],
              [
                -73.85761052799985,
                40.660108355999924
              ],
              [
                -73.85851436399994,
                40.65989894199987
              ],
              [
                -73.859386918,
                40.6597727149999
              ],
              [
                -73.8603597819999,
                40.65964582899991
              ],
              [
                -73.86122244999984,
                40.659220936999894
              ],
              [
                -73.86174758999981,
                40.65893835699988
              ],
              [
                -73.86317083299996,
                40.65827651199994
              ],
              [
                -73.86269778299766,
                40.657618309006146
              ],
              [
                -73.86269762139092,
                40.65761837633347
              ],
              [
                -73.86172714514397,
                40.65803365399325
              ],
              [
                -73.86171459803093,
                40.65803003966288
              ],
              [
                -73.86170301269323,
                40.658024889483436
              ],
              [
                -73.86169271052574,
                40.65801834632874
              ],
              [
                -73.8616839773257,
                40.65801059171595
              ],
              [
                -73.86167705536486,
                40.658001840769856
              ],
              [
                -73.86167213666835,
                40.65799233625483
              ],
              [
                -73.86166935768706,
                40.657982341840516
              ],
              [
                -73.86166879551332,
                40.65797213478675
              ],
              [
                -73.86164247711287,
                40.65783012799152
              ],
              [
                -73.86162505358661,
                40.65768732889236
              ],
              [
                -73.86161656428084,
                40.65754406042941
              ],
              [
                -73.861617028336,
                40.65740064660212
              ],
              [
                -73.86162644464528,
                40.657257411736595
              ],
              [
                -73.86163686179145,
                40.65720533028669
              ],
              [
                -73.86164080314583,
                40.65715273365752
              ],
              [
                -73.86163823380745,
                40.65710008780065
              ],
              [
                -73.86162917655315,
                40.65704785910245
              ],
              [
                -73.86161371163614,
                40.65699651025287
              ],
              [
                -73.86149375847893,
                40.65699993405751
              ],
              [
                -73.86127520118559,
                40.65700090635929
              ],
              [
                -73.86088798593681,
                40.65690873603679
              ],
              [
                -73.86088201128541,
                40.65690808159261
              ],
              [
                -73.86087597607579,
                40.656908162070074
              ],
              [
                -73.86087003517075,
                40.65690897540413
              ],
              [
                -73.86086434101308,
                40.65691050072462
              ],
              [
                -73.86085903971427,
                40.6569126988922
              ],
              [
                -73.86085426730544,
                40.65691551350192
              ],
              [
                -73.86085014624553,
                40.65691887233131
              ],
              [
                -73.86084678228117,
                40.656922689193244
              ],
              [
                -73.8608442617313,
                40.65692686614719
              ],
              [
                -73.86084264927302,
                40.656931296013134
              ],
              [
                -73.86075342042025,
                40.657080134282296
              ],
              [
                -73.86073759543031,
                40.65708712564858
              ],
              [
                -73.86072054730728,
                40.65709218126327
              ],
              [
                -73.86070268132822,
                40.65709518094143
              ],
              [
                -73.8606844222127,
                40.657096053373294
              ],
              [
                -73.86068040424408,
                40.65709595977959
              ],
              [
                -73.86067642566083,
                40.657096397299135
              ],
              [
                -73.86067260659318,
                40.65709735272123
              ],
              [
                -73.86066906235502,
                40.6570987971978
              ],
              [
                -73.86066589996193,
                40.6571006871139
              ],
              [
                -73.86066321489993,
                40.65710296540492
              ],
              [
                -73.86066108824288,
                40.657105563279615
              ],
              [
                -73.8606595842032,
                40.65710840229702
              ],
              [
                -73.86065874819455,
                40.6571113967354
              ],
              [
                -73.86065860545966,
                40.65711445617964
              ],
              [
                -73.86065916030824,
                40.65711748825223
              ],
              [
                -73.86066039598721,
                40.657120401401976
              ],
              [
                -73.86066227518654,
                40.65712310766844
              ],
              [
                -73.86066429380234,
                40.65714025618191
              ],
              [
                -73.86066287940999,
                40.657157439737254
              ],
              [
                -73.8606580646268,
                40.65717426203342
              ],
              [
                -73.86062372337487,
                40.65720545608297
              ],
              [
                -73.86061013643769,
                40.65720541164122
              ],
              [
                -73.86061974209726,
                40.657161699393114
              ],
              [
                -73.86060930392513,
                40.65715545670058
              ],
              [
                -73.86058792205708,
                40.657159821871936
              ],
              [
                -73.86053263950032,
                40.65718076942
              ],
              [
                -73.8604763417868,
                40.65720008536418
              ],
              [
                -73.86045714784515,
                40.657206943896405
              ],
              [
                -73.8604367815357,
                40.657211431719574
              ],
              [
                -73.86041574418222,
                40.65721343836423
              ],
              [
                -73.86039455362588,
                40.65721291443624
              ],
              [
                -73.86037373148025,
                40.657209872832226
              ],
              [
                -73.86035030672473,
                40.65722606716767
              ],
              [
                -73.86034164704868,
                40.657247905698014
              ],
              [
                -73.8603163571442,
                40.65738435445214
              ],
              [
                -73.86031029516808,
                40.65741807054195
              ],
              [
                -73.86032360895182,
                40.657462443016705
              ],
              [
                -73.86035941846357,
                40.657507227160686
              ],
              [
                -73.8603868461818,
                40.65755532113634
              ],
              [
                -73.86040537631257,
                40.657605820541995
              ],
              [
                -73.8604071420188,
                40.65776134156809
              ],
              [
                -73.86044444123876,
                40.65778360613783
              ],
              [
                -73.86048529822187,
                40.65780189396694
              ],
              [
                -73.86052898667043,
                40.65781587995884
              ],
              [
                -73.86057472995071,
                40.65782531548935
              ],
              [
                -73.86057588325953,
                40.657826688646104
              ],
              [
                -73.86057671272718,
                40.65782819129701
              ],
              [
                -73.86057719413046,
                40.657829779560366
              ],
              [
                -73.86057731341108,
                40.65783140705414
              ],
              [
                -73.86057706708579,
                40.657833026250664
              ],
              [
                -73.86057646234767,
                40.65783458986454
              ],
              [
                -73.86057551685668,
                40.65783605223369
              ],
              [
                -73.86057425822476,
                40.65783737065265
              ],
              [
                -73.86057272320707,
                40.657838506619505
              ],
              [
                -73.86057095663061,
                40.65783942696082
              ],
              [
                -73.8605690100849,
                40.65784010479977
              ],
              [
                -73.8605669404149,
                40.65784052034145
              ],
              [
                -73.86056480806081,
                40.65784066145086
              ],
              [
                -73.86056267529403,
                40.657840524007156
              ],
              [
                -73.8605252694779,
                40.6578453353356
              ],
              [
                -73.86048734107467,
                40.65784589781526
              ],
              [
                -73.86044971765398,
                40.657842199173004
              ],
              [
                -73.86041004790772,
                40.657853570437744
              ],
              [
                -73.86039799754259,
                40.65786675945566
              ],
              [
                -73.8603838782098,
                40.65787869881579
              ],
              [
                -73.86036791109113,
                40.65788920148472
              ],
              [
                -73.86033585506264,
                40.65788712348675
              ],
              [
                -73.86030373983284,
                40.65788857770955
              ],
              [
                -73.86027223300464,
                40.65789353392311
              ],
              [
                -73.86024198953398,
                40.6579018890989
              ],
              [
                -73.8602136381153,
                40.65791346955159
              ],
              [
                -73.86021393581149,
                40.65792045727575
              ],
              [
                -73.86021545296792,
                40.65792735254526
              ],
              [
                -73.86021816254973,
                40.657934032490644
              ],
              [
                -73.86022201627462,
                40.65794037807924
              ],
              [
                -73.86021965587378,
                40.65795397706385
              ],
              [
                -73.86021442008945,
                40.65796710203258
              ],
              [
                -73.86020644859816,
                40.65797940284286
              ],
              [
                -73.86019595405857,
                40.6579905513386
              ],
              [
                -73.860183216439,
                40.65800025010463
              ],
              [
                -73.86005062973462,
                40.65802992465072
              ],
              [
                -73.86004668743104,
                40.65803120778048
              ],
              [
                -73.86004308390716,
                40.65803297668691
              ],
              [
                -73.86003992143453,
                40.65803518116662
              ],
              [
                -73.8600372897666,
                40.658037758654906
              ],
              [
                -73.86003526359225,
                40.65804063600037
              ],
              [
                -73.86003390041654,
                40.658043731541326
              ],
              [
                -73.8600332389273,
                40.65804695742388
              ],
              [
                -73.86003329789838,
                40.65805022209471
              ],
              [
                -73.8600340756564,
                40.65805343289953
              ],
              [
                -73.86003555012763,
                40.65805649871297
              ],
              [
                -73.86003767946562,
                40.65805933252453
              ],
              [
                -73.86004040323812,
                40.65806185390814
              ],
              [
                -73.86004364414178,
                40.658063991304914
              ],
              [
                -73.86004731019705,
                40.658065684053284
              ],
              [
                -73.86004532220305,
                40.658077173872094
              ],
              [
                -73.86004078173501,
                40.65808823513674
              ],
              [
                -73.86003382273881,
                40.658098541531075
              ],
              [
                -73.86002465050994,
                40.658107789007914
              ],
              [
                -73.86001353563562,
                40.65811570475901
              ],
              [
                -73.8600008060138,
                40.658122055263135
              ],
              [
                -73.85998683717858,
                40.65812665317486
              ],
              [
                -73.85997204122235,
                40.65812936285188
              ],
              [
                -73.85995685463872,
                40.658130104356246
              ],
              [
                -73.85983888003487,
                40.658211445593295
              ],
              [
                -73.85982151770705,
                40.65823278714298
              ],
              [
                -73.85982047387745,
                40.65823555661405
              ],
              [
                -73.85981881936422,
                40.65823814780911
              ],
              [
                -73.85981660360636,
                40.658240483299906
              ],
              [
                -73.85981389281268,
                40.65824249329965
              ],
              [
                -73.85981076798527,
                40.658244117746854
              ],
              [
                -73.85980732249737,
                40.6582453081016
              ],
              [
                -73.85980365930405,
                40.658246028794416
              ],
              [
                -73.8597998878657,
                40.658246258290234
              ],
              [
                -73.85979612087758,
                40.65824598973154
              ],
              [
                -73.85979247090133,
                40.65824523114304
              ],
              [
                -73.85978904700261,
                40.65824400519239
              ],
              [
                -73.85978595149133,
                40.65824234851229
              ],
              [
                -73.85978327686513,
                40.658240310606246
              ],
              [
                -73.85978110304457,
                40.658237952369326
              ],
              [
                -73.85978013873164,
                40.65823829022027
              ],
              [
                -73.85977925809075,
                40.65823874152121
              ],
              [
                -73.85977848394018,
                40.658239294578664
              ],
              [
                -73.8597778363381,
                40.65823993506261
              ],
              [
                -73.85977733206455,
                40.65824064637783
              ],
              [
                -73.85977698418533,
                40.65824141009364
              ],
              [
                -73.85977680171472,
                40.65824220642171
              ],
              [
                -73.8597767893799,
                40.658243014728804
              ],
              [
                -73.85977694750098,
                40.658243814071206
              ],
              [
                -73.85977727198093,
                40.65824458373749
              ],
              [
                -73.85977775441188,
                40.658245303785094
              ],
              [
                -73.8597748765548,
                40.65826388696598
              ],
              [
                -73.85976839588899,
                40.65828193634574
              ],
              [
                -73.85977576914712,
                40.658287698752105
              ],
              [
                -73.8597843074698,
                40.65829243731947
              ],
              [
                -73.8597937687644,
                40.65829601769209
              ],
              [
                -73.85980388476865,
                40.6582983383532
              ],
              [
                -73.85981436865637,
                40.65829933350352
              ],
              [
                -73.85982492317086,
                40.65829897492686
              ],
              [
                -73.85983524905286,
                40.658297272790215
              ],
              [
                -73.85989401628483,
                40.658309592716414
              ],
              [
                -73.86004218360436,
                40.65832819115837
              ],
              [
                -73.86007727060799,
                40.65832958333267
              ],
              [
                -73.86011221667275,
                40.658326815201924
              ],
              [
                -73.86014617638143,
                40.65831995373307
              ],
              [
                -73.8601783281795,
                40.658309164919004
              ],
              [
                -73.86020789424994,
                40.65829470976335
              ],
              [
                -73.86038783682787,
                40.65823690689207
              ],
              [
                -73.86043405988346,
                40.65820651309113
              ],
              [
                -73.86047483240216,
                40.6581718896318
              ],
              [
                -73.86050949236048,
                40.658133598702875
              ],
              [
                -73.86053747698874,
                40.65809226204196
              ],
              [
                -73.86059952811797,
                40.65807824993888
              ],
              [
                -73.86066308604214,
                40.658068945629424
              ],
              [
                -73.86072753647886,
                40.65806443903922
              ],
              [
                -73.86081572030625,
                40.658061544025244
              ],
              [
                -73.86090375083607,
                40.658056640224764
              ],
              [
                -73.86093499368765,
                40.65805606752872
              ],
              [
                -73.86095892095699,
                40.6580609430229
              ],
              [
                -73.86098144498345,
                40.65806878993202
              ],
              [
                -73.86100193454507,
                40.65807938835124
              ],
              [
                -73.86101981543375,
                40.65809244126645
              ],
              [
                -73.86113319136574,
                40.658159241925624
              ],
              [
                -73.86124850937952,
                40.65822408602452
              ],
              [
                -73.8612728030787,
                40.65826516768471
              ],
              [
                -73.86128968762509,
                40.658308349239846
              ],
              [
                -73.8612988615183,
                40.658352859651416
              ],
              [
                -73.86130016093611,
                40.65839790415194
              ],
              [
                -73.86130281819504,
                40.658430417916556
              ],
              [
                -73.86130941531205,
                40.65846260485656
              ],
              [
                -73.86131463955402,
                40.65848898311093
              ],
              [
                -73.86132276468814,
                40.65851493216582
              ],
              [
                -73.86131634468514,
                40.65856851327989
              ],
              [
                -73.86130183562334,
                40.65862117020251
              ],
              [
                -73.86127943196229,
                40.65867219712344
              ],
              [
                -73.86124943398261,
                40.658720910078664
              ],
              [
                -73.86121224376127,
                40.658766656119575
              ],
              [
                -73.86116835978298,
                40.6588088220647
              ],
              [
                -73.86102487572501,
                40.65891352586272
              ],
              [
                -73.86094803804941,
                40.658950657250166
              ],
              [
                -73.86087297680847,
                40.658989839340656
              ],
              [
                -73.86085533927911,
                40.659003680203085
              ],
              [
                -73.86084059983904,
                40.65901936416935
              ],
              [
                -73.86082908610129,
                40.65903654263418
              ],
              [
                -73.86082105398334,
                40.659054833773936
              ],
              [
                -73.86081668201686,
                40.65907383103329
              ],
              [
                -73.86081606738043,
                40.65909311216203
              ],
              [
                -73.86081724695877,
                40.659130941731725
              ],
              [
                -73.86081402183073,
                40.65916870217401
              ],
              [
                -73.86080641734286,
                40.659206096640276
              ],
              [
                -73.86079238499961,
                40.65923294195022
              ],
              [
                -73.86077260311248,
                40.65925759798893
              ],
              [
                -73.86074763950079,
                40.65927935702162
              ],
              [
                -73.86071821072426,
                40.65929759446912
              ],
              [
                -73.86068516151484,
                40.65931178683697
              ],
              [
                -73.86064944052751,
                40.65932152674169
              ],
              [
                -73.86061207311211,
                40.65932653460514
              ],
              [
                -73.86057345610458,
                40.659276567127264
              ],
              [
                -73.86053093212242,
                40.659228474545266
              ],
              [
                -73.86048465762494,
                40.659182433798016
              ],
              [
                -73.86047396806991,
                40.65913555812848
              ],
              [
                -73.86047128866177,
                40.65908802463314
              ],
              [
                -73.86047666394151,
                40.65904062378932
              ],
              [
                -73.86049000450191,
                40.65899414386636
              ],
              [
                -73.86051108847565,
                40.65894935781753
              ],
              [
                -73.86053956522544,
                40.65890701042552
              ],
              [
                -73.86057496117598,
                40.658867805917616
              ],
              [
                -73.86061668769061,
                40.65883239625404
              ],
              [
                -73.86066405086045,
                40.658801370287186
              ],
              [
                -73.86071626304346,
                40.658775243969025
              ],
              [
                -73.86077245596431,
                40.65875445177121
              ],
              [
                -73.86083169515129,
                40.65873933946057
              ],
              [
                -73.86084580404025,
                40.65873048186487
              ],
              [
                -73.86085773074154,
                40.65871992692759
              ],
              [
                -73.86086712818317,
                40.65870798180266
              ],
              [
                -73.86087372289653,
                40.658694994099044
              ],
              [
                -73.86087732297413,
                40.65868134176507
              ],
              [
                -73.86087782365375,
                40.65866742208985
              ],
              [
                -73.86088284859116,
                40.658652523323696
              ],
              [
                -73.8608910415375,
                40.65863846316638
              ],
              [
                -73.86090217660048,
                40.658625629275
              ],
              [
                -73.86093499484525,
                40.65859435237231
              ],
              [
                -73.8609615079497,
                40.658559780695214
              ],
              [
                -73.86098115499654,
                40.65852264566346
              ],
              [
                -73.86099352033254,
                40.65848373292757
              ],
              [
                -73.86099834236325,
                40.658443865747
              ],
              [
                -73.86100058364913,
                40.658430802500895
              ],
              [
                -73.8609998854719,
                40.65841763895132
              ],
              [
                -73.86099626803218,
                40.658404755920564
              ],
              [
                -73.86098983598349,
                40.6583925261152
              ],
              [
                -73.86098077540692,
                40.658381303343276
              ],
              [
                -73.86096934842652,
                40.65837141227997
              ],
              [
                -73.86095588562512,
                40.65836313907351
              ],
              [
                -73.86094077648218,
                40.658356723067584
              ],
              [
                -73.86092445810512,
                40.65835234987694
              ],
              [
                -73.86082324998215,
                40.65838623488364
              ],
              [
                -73.86071997976644,
                40.65841629578989
              ],
              [
                -73.86065341840745,
                40.65848721618129
              ],
              [
                -73.86064802839297,
                40.65849162938626
              ],
              [
                -73.860641730764,
                40.65849527962917
              ],
              [
                -73.86063470926462,
                40.65849806040769
              ],
              [
                -73.86062716875976,
                40.658499890587755
              ],
              [
                -73.86061932925696,
                40.65850071677058
              ],
              [
                -73.86061141948765,
                40.6585005148507
              ],
              [
                -73.86060367023326,
                40.65849929071967
              ],
              [
                -73.86059630759262,
                40.65849708009351
              ],
              [
                -73.86058954638308,
                40.658493947471186
              ],
              [
                -73.86058646610161,
                40.658488220873856
              ],
              [
                -73.8605821667946,
                40.658482969523305
              ],
              [
                -73.86057677057047,
                40.65847834256679
              ],
              [
                -73.86057043069079,
                40.65847447141755
              ],
              [
                -73.86055420988649,
                40.65847754514345
              ],
              [
                -73.86053882769906,
                40.65848252693923
              ],
              [
                -73.86052466844856,
                40.65848929233576
              ],
              [
                -73.86051208590054,
                40.65849767230155
              ],
              [
                -73.86050139442817,
                40.65850745746509
              ],
              [
                -73.8604997919849,
                40.658511523488585
              ],
              [
                -73.86049908782563,
                40.65851573468887
              ],
              [
                -73.86049930105918,
                40.658519976787765
              ],
              [
                -73.8605004258989,
                40.658524134668866
              ],
              [
                -73.86050243182072,
                40.65852809550094
              ],
              [
                -73.86050526439084,
                40.65853175180016
              ],
              [
                -73.86050884674245,
                40.65853500434657
              ],
              [
                -73.86051308166277,
                40.65853776487685
              ],
              [
                -73.86051785422997,
                40.658539958479096
              ],
              [
                -73.86052303493194,
                40.65854152562638
              ],
              [
                -73.86051845625364,
                40.65854913056664
              ],
              [
                -73.86051237115804,
                40.65855609610052
              ],
              [
                -73.86050492960698,
                40.658562250568316
              ],
              [
                -73.86049631499077,
                40.65856744229854
              ],
              [
                -73.86048673960897,
                40.6585715433451
              ],
              [
                -73.86047643943894,
                40.658574452641474
              ],
              [
                -73.8604656683193,
                40.65857609849029
              ],
              [
                -73.86045469169552,
                40.658576440331096
              ],
              [
                -73.8604437800774,
                40.658575469739354
              ],
              [
                -73.86043385613202,
                40.658577827141784
              ],
              [
                -73.8604245403572,
                40.65858134046279
              ],
              [
                -73.8604160632406,
                40.65858592277726
              ],
              [
                -73.86040863451933,
                40.65859146071144
              ],
              [
                -73.86040243799279,
                40.65859781724776
              ],
              [
                -73.86039762697337,
                40.658604835115334
              ],
              [
                -73.86039432049358,
                40.65861234068067
              ],
              [
                -73.86039260036225,
                40.658620148243934
              ],
              [
                -73.86040490009731,
                40.65866687883854
              ],
              [
                -73.86042536587483,
                40.65871191852856
              ],
              [
                -73.86045362350445,
                40.65875444378722
              ],
              [
                -73.86045710824995,
                40.65876274177489
              ],
              [
                -73.86045868894304,
                40.65877137022513
              ],
              [
                -73.8604583195202,
                40.65878007769707
              ],
              [
                -73.86045601074608,
                40.65878861044688
              ],
              [
                -73.86045182989939,
                40.65879671982229
              ],
              [
                -73.86044589881381,
                40.65880416950844
              ],
              [
                -73.86043839032605,
                40.65881074241427
              ],
              [
                -73.86042952324041,
                40.658816246999066
              ],
              [
                -73.86041955595195,
                40.658820522853624
              ],
              [
                -73.8604087789173,
                40.65882344537561
              ],
              [
                -73.8603544076603,
                40.65885385483542
              ],
              [
                -73.86029526049886,
                40.65887855938868
              ],
              [
                -73.86023236327559,
                40.65889713056005
              ],
              [
                -73.86022679505247,
                40.658897433192514
              ],
              [
                -73.86022123911002,
                40.65889701989502
              ],
              [
                -73.86021585304373,
                40.65889590239075
              ],
              [
                -73.86021078963006,
                40.65889411237784
              ],
              [
                -73.86020619249393,
                40.658891700630384
              ],
              [
                -73.86020219203384,
                40.658888735558065
              ],
              [
                -73.86019890172325,
                40.65888530126567
              ],
              [
                -73.86019641489253,
                40.65888149516746
              ],
              [
                -73.86019480208077,
                40.65887742522409
              ],
              [
                -73.8601941090356,
                40.658873206880116
              ],
              [
                -73.86019435541495,
                40.6588689597897
              ],
              [
                -73.86019553423012,
                40.658864804422
              ],
              [
                -73.86019761204382,
                40.658860858644715
              ],
              [
                -73.86020052991832,
                40.65885723438042
              ],
              [
                -73.86023343179158,
                40.65882979359741
              ],
              [
                -73.8602702573463,
                40.65880541474649
              ],
              [
                -73.86031051842247,
                40.65878442099156
              ],
              [
                -73.86028735677134,
                40.65870650880663
              ],
              [
                -73.86025847890078,
                40.658629711739074
              ],
              [
                -73.86023037136583,
                40.65861758216259
              ],
              [
                -73.86019996283547,
                40.65860927322729
              ],
              [
                -73.86016814087985,
                40.658605027455565
              ],
              [
                -73.86013583432292,
                40.65860496877397
              ],
              [
                -73.86010398613425,
                40.65860909889511
              ],
              [
                -73.8600352653625,
                40.65859661684022
              ],
              [
                -73.85996520362455,
                40.65858969804046
              ],
              [
                -73.85989457525864,
                40.658588418964136
              ],
              [
                -73.8598358454955,
                40.6585712985537
              ],
              [
                -73.85977993090415,
                40.658549383168015
              ],
              [
                -73.85972750407433,
                40.65852293642601
              ],
              [
                -73.85967919564028,
                40.65849227645348
              ],
              [
                -73.8596355866937,
                40.65845777205655
              ],
              [
                -73.85960552483705,
                40.65839519004295
              ],
              [
                -73.85958200860286,
                40.658331003594526
              ],
              [
                -73.85956518319344,
                40.65826560908615
              ],
              [
                -73.85955515249182,
                40.658199410351564
              ],
              [
                -73.85955197842098,
                40.65813281619026
              ],
              [
                -73.85955850409009,
                40.65808366389068
              ],
              [
                -73.85957204817525,
                40.65803534975175
              ],
              [
                -73.85959244822888,
                40.65798845320094
              ],
              [
                -73.85961945958066,
                40.65794353666321
              ],
              [
                -73.85965275827455,
                40.657901138816925
              ],
              [
                -73.85966622177314,
                40.657886205519254
              ],
              [
                -73.85970571828645,
                40.65783698479454
              ],
              [
                -73.85973220186875,
                40.65782348532853
              ],
              [
                -73.85980115657043,
                40.657785608986785
              ],
              [
                -73.85987781547442,
                40.65775218919596
              ],
              [
                -73.85992218331278,
                40.6577351975651
              ],
              [
                -73.85993897279535,
                40.657720177150324
              ],
              [
                -73.8599371302826,
                40.65770538048095
              ],
              [
                -73.8599233637411,
                40.65767044897215
              ],
              [
                -73.85980671376048,
                40.657456599183625
              ],
              [
                -73.85979094247071,
                40.6574320146118
              ],
              [
                -73.85980008978002,
                40.65738015765183
              ],
              [
                -73.85981027609661,
                40.657322411493716
              ],
              [
                -73.85988237954867,
                40.65727360319277
              ],
              [
                -73.859915202642,
                40.65721444921535
              ],
              [
                -73.85993882823614,
                40.65722282206969
              ],
              [
                -73.85994397479166,
                40.65720924111884
              ],
              [
                -73.85996128243944,
                40.65717146595806
              ],
              [
                -73.85999101706163,
                40.65713697389694
              ],
              [
                -73.86002730651316,
                40.65710430078599
              ],
              [
                -73.86004275639077,
                40.6570872739136
              ],
              [
                -73.86005194367061,
                40.6570774861926
              ],
              [
                -73.86007068925896,
                40.65705804790261
              ],
              [
                -73.86007499798218,
                40.65704861107995
              ],
              [
                -73.86006924302438,
                40.65704267592144
              ],
              [
                -73.8600544693938,
                40.657041449733214
              ],
              [
                -73.86002338719183,
                40.65704871413689
              ],
              [
                -73.86000819218508,
                40.657049744706775
              ],
              [
                -73.86000010014942,
                40.65705011196406
              ],
              [
                -73.85996243295547,
                40.65705261874037
              ],
              [
                -73.8599341705406,
                40.657038037005314
              ],
              [
                -73.85988581709219,
                40.65701420936156
              ],
              [
                -73.85984591953108,
                40.65699662103968
              ],
              [
                -73.85983363521494,
                40.65697706322046
              ],
              [
                -73.85984572805579,
                40.65696766219373
              ],
              [
                -73.85988145175494,
                40.65696512678623
              ],
              [
                -73.85993091334905,
                40.65696583368836
              ],
              [
                -73.85996142495827,
                40.65696629518922
              ],
              [
                -73.86000758602331,
                40.6569700282924
              ],
              [
                -73.86004870310448,
                40.656972549706204
              ],
              [
                -73.86005569574436,
                40.65697225515543
              ],
              [
                -73.86006234511073,
                40.65696372700656
              ],
              [
                -73.86006870315983,
                40.65693949825014
              ],
              [
                -73.86003298298652,
                40.65682052391104
              ],
              [
                -73.86004168871449,
                40.656794266899
              ],
              [
                -73.86006777819523,
                40.65677986104951
              ],
              [
                -73.86007981013411,
                40.65678226363547
              ],
              [
                -73.86009989868663,
                40.656792992796326
              ],
              [
                -73.86011027831917,
                40.656809583354615
              ],
              [
                -73.86011860091382,
                40.65684538540262
              ],
              [
                -73.86014272235784,
                40.6569613555664
              ],
              [
                -73.86015025570852,
                40.65699537213987
              ],
              [
                -73.86015948294515,
                40.65701226368074
              ],
              [
                -73.86019280975664,
                40.657017710835376
              ],
              [
                -73.86020839501049,
                40.65701037444651
              ],
              [
                -73.86022643272057,
                40.65698470455706
              ],
              [
                -73.86024448470059,
                40.656953161473936
              ],
              [
                -73.86028077947773,
                40.656917523372606
              ],
              [
                -73.8603057625893,
                40.65689748985388
              ],
              [
                -73.86035088822705,
                40.65687934621476
              ],
              [
                -73.86044742487603,
                40.65683653491432
              ],
              [
                -73.86047711306266,
                40.65680648870485
              ],
              [
                -73.86051503888413,
                40.6567670097428
              ],
              [
                -73.86051434648235,
                40.65675339538304
              ],
              [
                -73.86050971089192,
                40.65674718601959
              ],
              [
                -73.86048143216688,
                40.65673998720494
              ],
              [
                -73.86044847473897,
                40.656731000372886
              ],
              [
                -73.86044003211457,
                40.656717375436614
              ],
              [
                -73.86044288674648,
                40.65669698517322
              ],
              [
                -73.86045768505309,
                40.65668638132803
              ],
              [
                -73.8604949922559,
                40.656684449245354
              ],
              [
                -73.86058123554953,
                40.65666879928138
              ],
              [
                -73.8606030661496,
                40.65665647421112
              ],
              [
                -73.86061557040897,
                40.65664054217458
              ],
              [
                -73.86062302068669,
                40.65662787031268
              ],
              [
                -73.86060742785907,
                40.65651814179852
              ],
              [
                -73.86061429246945,
                40.65647560596028
              ],
              [
                -73.86061669329668,
                40.65646465720696
              ],
              [
                -73.86062404578773,
                40.65646529853594
              ],
              [
                -73.86063722236801,
                40.65647184710185
              ],
              [
                -73.86064784053592,
                40.65651297627976
              ],
              [
                -73.86067414586063,
                40.656584591665585
              ],
              [
                -73.86072304455804,
                40.656588631862135
              ],
              [
                -73.8607879936244,
                40.656569935588145
              ],
              [
                -73.86083111892167,
                40.65656062746426
              ],
              [
                -73.86086488851105,
                40.656561052628334
              ],
              [
                -73.86090869860645,
                40.656568296936086
              ],
              [
                -73.86093087921417,
                40.65656129711496
              ],
              [
                -73.86095970776478,
                40.656545741612355
              ],
              [
                -73.86098888249673,
                40.65653696605767
              ],
              [
                -73.86101561574246,
                40.65654210479475
              ],
              [
                -73.86108773357645,
                40.65655950982143
              ],
              [
                -73.86117420316046,
                40.65657281662431
              ],
              [
                -73.86123700169337,
                40.65658368055687
              ],
              [
                -73.86130453077845,
                40.6565889764917
              ],
              [
                -73.86136038361256,
                40.65659420520712
              ],
              [
                -73.86139225922216,
                40.656587216479785
              ],
              [
                -73.86140125106603,
                40.65657808763925
              ],
              [
                -73.8614013099616,
                40.65656773931729
              ],
              [
                -73.86138863839427,
                40.656542856308725
              ],
              [
                -73.86136322406594,
                40.65651084904303
              ],
              [
                -73.86135294244752,
                40.656480946411385
              ],
              [
                -73.86135850209601,
                40.65645937797578
              ],
              [
                -73.86136875139077,
                40.65643518131159
              ],
              [
                -73.86136381950391,
                40.65641475416531
              ],
              [
                -73.86135193244354,
                40.65639462119119
              ],
              [
                -73.86125486605461,
                40.6563634693526
              ],
              [
                -73.86106223001468,
                40.65625196164962
              ],
              [
                -73.8608403069991,
                40.656171574779144
              ],
              [
                -73.86072952202075,
                40.65604681266761
              ],
              [
                -73.8599590652879,
                40.65549029704332
              ],
              [
                -73.85975838110188,
                40.6552759472571
              ],
              [
                -73.85971498705463,
                40.65523000429859
              ],
              [
                -73.85967017937693,
                40.65518789516081
              ],
              [
                -73.85961223713457,
                40.65514717084673
              ],
              [
                -73.85951590784107,
                40.655079466255074
              ],
              [
                -73.85939916950612,
                40.65500810629568
              ],
              [
                -73.85930573554418,
                40.654972383384326
              ],
              [
                -73.85929417768489,
                40.6549100555591
              ],
              [
                -73.8592944217652,
                40.654794329098245
              ],
              [
                -73.85927772471581,
                40.65470428193611
              ],
              [
                -73.85926552902157,
                40.65463850910968
              ],
              [
                -73.85915498446028,
                40.654402468871005
              ],
              [
                -73.85900327710367,
                40.65428655623112
              ],
              [
                -73.85892499802527,
                40.654255434322025
              ],
              [
                -73.85885729088807,
                40.65422851438058
              ],
              [
                -73.85875807991592,
                40.65416162733512
              ],
              [
                -73.85876338041551,
                40.654049771785886
              ],
              [
                -73.85876419753458,
                40.65403255472361
              ],
              [
                -73.85868853267029,
                40.65388557791477
              ],
              [
                -73.85857198690208,
                40.65372519794098
              ],
              [
                -73.85856069416674,
                40.653538241121254
              ],
              [
                -73.85852026658151,
                40.653311189403766
              ],
              [
                -73.85839791574583,
                40.653132998353556
              ],
              [
                -73.85828132383823,
                40.652994872151815
              ],
              [
                -73.8581470689611,
                40.652919040094034
              ],
              [
                -73.85789601477089,
                40.652803003396144
              ],
              [
                -73.85772331773968,
                40.652726229071625
              ],
              [
                -73.8576776484451,
                40.65275707251167
              ],
              [
                -73.85762719001431,
                40.65278323123448
              ],
              [
                -73.8575727654805,
                40.652804278559756
              ],
              [
                -73.85751526257103,
                40.65281987117967
              ],
              [
                -73.85745561922818,
                40.65282975475945
              ],
              [
                -73.85739480830867,
                40.652833768085365
              ],
              [
                -73.8573338217152,
                40.65283184569515
              ],
              [
                -73.85733367832401,
                40.65283183345523
              ],
              [
                -73.85732293065068,
                40.65283184028352
              ],
              [
                -73.85731234163377,
                40.652833241589015
              ],
              [
                -73.8573022208929,
                40.652835996397876
              ],
              [
                -73.857292864355,
                40.65284002416056
              ],
              [
                -73.85728454560274,
                40.65284520710654
              ],
              [
                -73.8572784227743,
                40.652850458618865
              ],
              [
                -73.85727337473517,
                40.65285633592442
              ],
              [
                -73.8572695101429,
                40.65286271251662
              ],
              [
                -73.85726691218123,
                40.65286945114181
              ],
              [
                -73.8572656367711,
                40.65287640675375
              ],
              [
                -73.857265711365,
                40.65288342963558
              ],
              [
                -73.8572317825592,
                40.652910349208035
              ],
              [
                -73.85719251829886,
                40.65293266830593
              ],
              [
                -73.85714898102678,
                40.65294978299878
              ],
              [
                -73.85710234881229,
                40.6529612301815
              ],
              [
                -73.8570538834724,
                40.6529667001055
              ],
              [
                -73.85700489642797,
                40.652966044760376
              ],
              [
                -73.85695671321744,
                40.65295928187907
              ],
              [
                -73.85691063762755,
                40.65294659445798
              ],
              [
                -73.85683865479179,
                40.65290324442496
              ],
              [
                -73.85677253105183,
                40.652854782173215
              ],
              [
                -73.85671288972269,
                40.65280166454158
              ],
              [
                -73.856660293008,
                40.65274439225287
              ],
              [
                -73.85661523669977,
                40.65268350519323
              ],
              [
                -73.85657814550649,
                40.65261957732214
              ],
              [
                -73.85654936904841,
                40.652553211262365
              ],
              [
                -73.85652917856396,
                40.65248503261863
              ],
              [
                -73.85651776435327,
                40.652415684079976
              ],
              [
                -73.85651523398519,
                40.652345819361805
              ],
              [
                -73.8564633419696,
                40.652310589875086
              ],
              [
                -73.85641792408416,
                40.65227050803005
              ],
              [
                -73.85637975575189,
                40.65222625815806
              ],
              [
                -73.85634948861879,
                40.652178595751586
              ],
              [
                -73.85632763942613,
                40.65212833456415
              ],
              [
                -73.85631458119158,
                40.65207633271785
              ],
              [
                -73.85631053684067,
                40.65202347805094
              ],
              [
                -73.85631557540161,
                40.65197067296015
              ],
              [
                -73.85631771824539,
                40.651959961690814
              ],
              [
                -73.85632504793077,
                40.651898221463334
              ],
              [
                -73.8563245119338,
                40.65183623071372
              ],
              [
                -73.85631611529033,
                40.65177456936082
              ],
              [
                -73.85629993657182,
                40.65171381424032
              ],
              [
                -73.85627612714923,
                40.651654533708474
              ],
              [
                -73.85624490977578,
                40.651597282325675
              ],
              [
                -73.85620657650314,
                40.65154259566837
              ],
              [
                -73.85616148594845,
                40.651490985319235
              ],
              [
                -73.85611005993886,
                40.651442934081814
              ],
              [
                -73.85605277956542,
                40.65139889146354
              ],
              [
                -73.85599018068156,
                40.651359269471584
              ],
              [
                -73.8559568502225,
                40.65133254363233
              ],
              [
                -73.85591932944749,
                40.65130926166608
              ],
              [
                -73.8558782271612,
                40.65128980133864
              ],
              [
                -73.85583421027903,
                40.65127447840717
              ],
              [
                -73.85578799300526,
                40.6512635414964
              ],
              [
                -73.85575421744053,
                40.651254879944034
              ],
              [
                -73.85571911058845,
                40.651250207964
              ],
              [
                -73.85568347985614,
                40.651249633005186
              ],
              [
                -73.8556481446975,
                40.65125316829065
              ],
              [
                -73.8556139177695,
                40.65126073251392
              ],
              [
                -73.85558158624221,
                40.65127215170912
              ],
              [
                -73.8555518936946,
                40.65128716325114
              ],
              [
                -73.85553675143038,
                40.65128848190486
              ],
              [
                -73.85552152951848,
                40.65128790228397
              ],
              [
                -73.85550663545077,
                40.65128543990492
              ],
              [
                -73.85549246794199,
                40.65128116068577
              ],
              [
                -73.85547940625678,
                40.65127517918145
              ],
              [
                -73.85546780005674,
                40.651267655516996
              ],
              [
                -73.85545796003967,
                40.65125879110123
              ],
              [
                -73.85545014962247,
                40.65124882323466
              ],
              [
                -73.85544457788902,
                40.65123801875736
              ],
              [
                -73.85544139399369,
                40.65122666690516
              ],
              [
                -73.85544068316777,
                40.65121507156706
              ],
              [
                -73.85544246443914,
                40.65120354314979
              ],
              [
                -73.8553479640749,
                40.65114454000978
              ],
              [
                -73.85524875978942,
                40.651090200298626
              ],
              [
                -73.8551452476776,
                40.65104074097392
              ],
              [
                -73.8550300218924,
                40.65099338414663
              ],
              [
                -73.85491085294494,
                40.65095207423868
              ],
              [
                -73.85478828709621,
                40.6509170006093
              ],
              [
                -73.8547019581179,
                40.65089650604583
              ],
              [
                -73.8545946948596,
                40.650872913005834
              ],
              [
                -73.85448967644179,
                40.65084405019861
              ],
              [
                -73.8543873486555,
                40.650810040144385
              ],
              [
                -73.85428814586837,
                40.650771027212656
              ],
              [
                -73.85419248918079,
                40.650727177009394
              ],
              [
                -73.85410078463791,
                40.65067867567396
              ],
              [
                -73.85403329487946,
                40.65066031157476
              ],
              [
                -73.85396367995506,
                40.650647341731684
              ],
              [
                -73.8538926809404,
                40.65063990421313
              ],
              [
                -73.85382105364425,
                40.65063807819404
              ],
              [
                -73.853749560563,
                40.650641883112876
              ],
              [
                -73.85368048940352,
                40.650656102279704
              ],
              [
                -73.85361016035154,
                40.65066612555269
              ],
              [
                -73.85358840515592,
                40.65066834860513
              ],
              [
                -73.85340232218081,
                40.650662526486464
              ],
              [
                -73.85321677107093,
                40.65065033827066
              ],
              [
                -73.85303212709412,
                40.650631808607606
              ],
              [
                -73.8528487636828,
                40.65060697497253
              ],
              [
                -73.85266705167781,
                40.650575887590264
              ],
              [
                -73.85261629191653,
                40.65056189114388
              ],
              [
                -73.85256339779652,
                40.65055367148329
              ],
              [
                -73.85250949612202,
                40.65055140371183
              ],
              [
                -73.8524557351611,
                40.65055513613993
              ],
              [
                -73.85240326018318,
                40.65056478925559
              ],
              [
                -73.85235318906456,
                40.650580157418446
              ],
              [
                -73.85230658847337,
                40.65060091324008
              ],
              [
                -73.85226445114697,
                40.65062661455847
              ],
              [
                -73.85222767474559,
                40.65065671385671
              ],
              [
                -73.85219704272762,
                40.65069056992688
              ],
              [
                -73.8521866537404,
                40.650710097264614
              ],
              [
                -73.85216433069581,
                40.65073938588089
              ],
              [
                -73.85213569281673,
                40.65076529232678
              ],
              [
                -73.8521016033448,
                40.650787035688936
              ],
              [
                -73.8520630898557,
                40.65080396054379
              ],
              [
                -73.85202131328327,
                40.65081555671429
              ],
              [
                -73.85197753292577,
                40.650821474649476
              ],
              [
                -73.85195622589887,
                40.65081066558662
              ],
              [
                -73.85193296507735,
                40.650802521662705
              ],
              [
                -73.8519083205149,
                40.65079724246117
              ],
              [
                -73.85188289617619,
                40.65079495735937
              ],
              [
                -73.8518573860499,
                40.65079571564545
              ],
              [
                -73.85183234172547,
                40.650799488605834
              ],
              [
                -73.8518083735549,
                40.65080618429004
              ],
              [
                -73.85178606566313,
                40.6508156395188
              ],
              [
                -73.85176596171377,
                40.650827623859854
              ],
              [
                -73.85149116369915,
                40.65119797317987
              ],
              [
                -73.8514032610962,
                40.651259962436924
              ],
              [
                -73.85131038218424,
                40.65131758898635
              ],
              [
                -73.85121289956777,
                40.651370621638634
              ],
              [
                -73.8511112043237,
                40.651418847633906
              ],
              [
                -73.85100570443232,
                40.65146207349548
              ],
              [
                -73.85089682314013,
                40.65150012580616
              ],
              [
                -73.8507849972619,
                40.65153285190433
              ],
              [
                -73.8507739507164,
                40.651523275644614
              ],
              [
                -73.8507608972872,
                40.65151529762897
              ],
              [
                -73.85074622986458,
                40.651509157983945
              ],
              [
                -73.85073038991608,
                40.65150504150388
              ],
              [
                -73.85071385420143,
                40.65150307208859
              ],
              [
                -73.85069712042095,
                40.6515033090148
              ],
              [
                -73.8506806922369,
                40.651505745151205
              ],
              [
                -73.85066506411285,
                40.65151030717369
              ],
              [
                -73.85065070643299,
                40.651516857772364
              ],
              [
                -73.85063805134276,
                40.65152519978338
              ],
              [
                -73.85059644320425,
                40.651550752293396
              ],
              [
                -73.8505504511477,
                40.65157149266227
              ],
              [
                -73.8505010272444,
                40.65158699154691
              ],
              [
                -73.85044919461022,
                40.6515969281069
              ],
              [
                -73.850396026226,
                40.6516010966466
              ],
              [
                -73.85034262272379,
                40.65159941087344
              ],
              [
                -73.85029008960439,
                40.65159190568471
              ],
              [
                -73.85024113552014,
                40.65157691801334
              ],
              [
                -73.85011846133992,
                40.65166816894875
              ],
              [
                -73.85007106583203,
                40.651656032281785
              ],
              [
                -73.8500216521738,
                40.65165020427447
              ],
              [
                -73.84997165682728,
                40.65165085434767
              ],
              [
                -73.84992253316281,
                40.65165796360372
              ],
              [
                -73.84963745407998,
                40.65152744224391
              ],
              [
                -73.84958985302212,
                40.65152541408135
              ],
              [
                -73.84954338481424,
                40.65151729434486
              ],
              [
                -73.8494993834858,
                40.651503316139454
              ],
              [
                -73.84946074188646,
                40.65148481650966
              ],
              [
                -73.84942653543145,
                40.65146180228387
              ],
              [
                -73.84939766406322,
                40.65143487895031
              ],
              [
                -73.84936074751847,
                40.65139383119352
              ],
              [
                -73.84935406865914,
                40.651371613866026
              ],
              [
                -73.84934739432025,
                40.65134768875858
              ],
              [
                -73.84934183808984,
                40.6513246196781
              ],
              [
                -73.84933405656909,
                40.65129471436318
              ],
              [
                -73.84931501987279,
                40.6512818761756
              ],
              [
                -73.8493149601344,
                40.651281836058025
              ],
              [
                -73.84929598318138,
                40.65126903915757
              ],
              [
                -73.84928261075792,
                40.65123143779345
              ],
              [
                -73.84925128119134,
                40.65119893909618
              ],
              [
                -73.84923897186141,
                40.651187818671346
              ],
              [
                -73.84921658357277,
                40.65116899798761
              ],
              [
                -73.84920545733438,
                40.65112969177341
              ],
              [
                -73.84921448394407,
                40.65110578824746
              ],
              [
                -73.84922351138029,
                40.65108102948894
              ],
              [
                -73.84925726840814,
                40.65103153255095
              ],
              [
                -73.84930217800292,
                40.6510093828231
              ],
              [
                -73.84937404260533,
                40.65096933110812
              ],
              [
                -73.84942572156427,
                40.65092925242015
              ],
              [
                -73.8494526399356,
                40.650927579873404
              ],
              [
                -73.84946832622983,
                40.650934433389565
              ],
              [
                -73.84948962372066,
                40.65093873256863
              ],
              [
                -73.84951990723373,
                40.65093706440982
              ],
              [
                -73.84954570943401,
                40.650933680920275
              ],
              [
                -73.84957152059873,
                40.65092602763811
              ],
              [
                -73.84961078670443,
                40.650920099730065
              ],
              [
                -73.84968367444215,
                40.650923612260776
              ],
              [
                -73.84968598790128,
                40.650892010198575
              ],
              [
                -73.8496893831687,
                40.650878348332455
              ],
              [
                -73.8497286297765,
                40.65088096213345
              ],
              [
                -73.84978812176738,
                40.65085712398512
              ],
              [
                -73.8498543445891,
                40.650832439863
              ],
              [
                -73.84991497447257,
                40.65080091441475
              ],
              [
                -73.84997000591888,
                40.650765966563554
              ],
              [
                -73.85002053333528,
                40.65073869935453
              ],
              [
                -73.85003739282374,
                40.65072249251934
              ],
              [
                -73.85004638150967,
                40.65071481551951
              ],
              [
                -73.85005311362572,
                40.650713116511334
              ],
              [
                -73.85006096796688,
                40.65071141779318
              ],
              [
                -73.85011037314206,
                40.65068500381192
              ],
              [
                -73.8501552962456,
                40.65065602145418
              ],
              [
                -73.85017217294566,
                40.650632126404396
              ],
              [
                -73.85017554722221,
                40.650627859493774
              ],
              [
                -73.85019692461213,
                40.65059713849153
              ],
              [
                -73.85021831217075,
                40.65056129146183
              ],
              [
                -73.85024190234327,
                40.65054338440372
              ],
              [
                -73.85026883423848,
                40.65053658715475
              ],
              [
                -73.85031482485618,
                40.6505315219885
              ],
              [
                -73.85033840378038,
                40.65051959365265
              ],
              [
                -73.8503698265407,
                40.65051024021073
              ],
              [
                -73.85039790929824,
                40.65048977594707
              ],
              [
                -73.85042261012721,
                40.65047699482539
              ],
              [
                -73.8504450863127,
                40.65045567070605
              ],
              [
                -73.8504361891814,
                40.650422346766355
              ],
              [
                -73.85043630981514,
                40.6503685342596
              ],
              [
                -73.85042180513697,
                40.65033520302619
              ],
              [
                -73.85039827326455,
                40.65031137131143
              ],
              [
                -73.85037704901447,
                40.6502898755957
              ],
              [
                -73.85037708349644,
                40.6502744998756
              ],
              [
                -73.85039615267061,
                40.65027281689653
              ],
              [
                -73.85042751079845,
                40.65029164901825
              ],
              [
                -73.85047002729478,
                40.650336120462036
              ],
              [
                -73.85049799039878,
                40.65036861445439
              ],
              [
                -73.8505203895992,
                40.65038231154806
              ],
              [
                -73.85052714912491,
                40.65036865235396
              ],
              [
                -73.8505383729429,
                40.65036439663914
              ],
              [
                -73.85055520793269,
                40.650358439256316
              ],
              [
                -73.85057653598565,
                40.65034992519284
              ],
              [
                -73.85058665633537,
                40.650337980994955
              ],
              [
                -73.85060247877833,
                40.65028333583014
              ],
              [
                -73.85060708653099,
                40.650228674420646
              ],
              [
                -73.85060609083524,
                40.65017315317966
              ],
              [
                -73.85059386659752,
                40.6501227413788
              ],
              [
                -73.85058726080116,
                40.65006806707686
              ],
              [
                -73.85057504998849,
                40.650011676031205
              ],
              [
                -73.85057629463016,
                40.64995701192712
              ],
              [
                -73.85058867347907,
                40.64993738194251
              ],
              [
                -73.85059992406472,
                40.64992202246745
              ],
              [
                -73.85058537349428,
                40.649909190315405
              ],
              [
                -73.85058538497059,
                40.649904065801174
              ],
              [
                -73.85058099294763,
                40.649862205947784
              ],
              [
                -73.85059680888422,
                40.64981012328831
              ],
              [
                -73.85061030166267,
                40.64979476622227
              ],
              [
                -73.85066077351658,
                40.64979226925467
              ],
              [
                -73.85068206689586,
                40.64979827666419
              ],
              [
                -73.85070336667691,
                40.64980171988692
              ],
              [
                -73.85070897081077,
                40.6498034361198
              ],
              [
                -73.85078185996018,
                40.64980609222276
              ],
              [
                -73.85078640830984,
                40.64977791128624
              ],
              [
                -73.85077185391279,
                40.64976678811094
              ],
              [
                -73.85076965564022,
                40.64974713969403
              ],
              [
                -73.85076965946267,
                40.64974543074062
              ],
              [
                -73.85077078166908,
                40.64974543219674
              ],
              [
                -73.85079096686978,
                40.649745458385965
              ],
              [
                -73.85079213977107,
                40.649722398269375
              ],
              [
                -73.85079562923032,
                40.64966688284576
              ],
              [
                -73.85080470275665,
                40.6496207701668
              ],
              [
                -73.85080586316285,
                40.649603688120564
              ],
              [
                -73.85080931543638,
                40.649564400966256
              ],
              [
                -73.85082731757902,
                40.64953794476206
              ],
              [
                -73.85083072973136,
                40.64951659533433
              ],
              [
                -73.8508621464434,
                40.64950980376304
              ],
              [
                -73.85087901364395,
                40.649489325338685
              ],
              [
                -73.85088238186796,
                40.64948762192024
              ],
              [
                -73.85088799001969,
                40.649487629191476
              ],
              [
                -73.85090369682953,
                40.64948423230821
              ],
              [
                -73.85091158726522,
                40.64946630643265
              ],
              [
                -73.850927408209,
                40.64941251427145
              ],
              [
                -73.85093423750284,
                40.64936725172731
              ],
              [
                -73.85093211912952,
                40.6493117285264
              ],
              [
                -73.85091541738015,
                40.64925789588271
              ],
              [
                -73.850898688717,
                40.64921602004624
              ],
              [
                -73.8508976017595,
                40.64920064337294
              ],
              [
                -73.85090552461405,
                40.64916819600036
              ],
              [
                -73.85094274444295,
                40.64913866656226
              ],
              [
                -73.85098543270321,
                40.64911379709003
              ],
              [
                -73.85103259422581,
                40.64909416734893
              ],
              [
                -73.85108312956335,
                40.649080234953836
              ],
              [
                -73.85113586061982,
                40.6490723247009
              ],
              [
                -73.85118955811286,
                40.649070620996035
              ],
              [
                -73.85124297023212,
                40.64907516355653
              ],
              [
                -73.85129485181847,
                40.649085846484944
              ],
              [
                -73.85130828776491,
                40.649095260363396
              ],
              [
                -73.85132394460764,
                40.649114926170576
              ],
              [
                -73.8513250249294,
                40.64913371890502
              ],
              [
                -73.85132719860135,
                40.649164471573044
              ],
              [
                -73.85133725114797,
                40.649182421175006
              ],
              [
                -73.85137202165077,
                40.64917990359877
              ],
              [
                -73.85138773436582,
                40.64917479937388
              ],
              [
                -73.85137657351407,
                40.64915001435362
              ],
              [
                -73.85138556695787,
                40.649140629468725
              ],
              [
                -73.85139792829229,
                40.649128688093924
              ],
              [
                -73.85143723187642,
                40.64910567656138
              ],
              [
                -73.85145630361569,
                40.64910228394917
              ],
              [
                -73.85149886566872,
                40.64912625478146
              ],
              [
                -73.85150335327252,
                40.6491254075207
              ],
              [
                -73.85150896387735,
                40.64912370580648
              ],
              [
                -73.8515213251907,
                40.64911176274326
              ],
              [
                -73.8515336867929,
                40.64910067608463
              ],
              [
                -73.85154042061026,
                40.649098122261165
              ],
              [
                -73.8515449128939,
                40.64909556604642
              ],
              [
                -73.85155498639377,
                40.64910411982
              ],
              [
                -73.8515717997111,
                40.64910841232451
              ],
              [
                -73.85159089132347,
                40.649096478442125
              ],
              [
                -73.85158643770166,
                40.649081951658765
              ],
              [
                -73.8515887121938,
                40.649067433557654
              ],
              [
                -73.85161119411089,
                40.649043546699254
              ],
              [
                -73.85163030849162,
                40.64902136277524
              ],
              [
                -73.85165839001101,
                40.649000900377935
              ],
              [
                -73.85167303933729,
                40.64896931468332
              ],
              [
                -73.85169775768199,
                40.64894799320827
              ],
              [
                -73.85172587398122,
                40.648912154569786
              ],
              [
                -73.85174616040437,
                40.648866909827035
              ],
              [
                -73.85174846522098,
                40.64883872545535
              ],
              [
                -73.85175409675192,
                40.64882848315255
              ],
              [
                -73.85180888140944,
                40.64877019307381
              ],
              [
                -73.85185762820167,
                40.64870887119299
              ],
              [
                -73.851900049338,
                40.6486448795617
              ],
              [
                -73.85193589437841,
                40.64857859599325
              ],
              [
                -73.85196495171064,
                40.64851041183155
              ],
              [
                -73.85198704979906,
                40.6484407296406
              ],
              [
                -73.85200205819733,
                40.648369960827395
              ],
              [
                -73.85203846598957,
                40.64824676738454
              ],
              [
                -73.85207890601953,
                40.648124305734925
              ],
              [
                -73.8521449496086,
                40.6480398157142
              ],
              [
                -73.85220452331654,
                40.647952580089594
              ],
              [
                -73.85225743110122,
                40.64786288596904
              ],
              [
                -73.85230349886226,
                40.647771028550196
              ],
              [
                -73.85234257501176,
                40.64767731014961
              ],
              [
                -73.85237453097487,
                40.647582039207286
              ],
              [
                -73.85239926161105,
                40.64748552927132
              ],
              [
                -73.85241668556101,
                40.64738809796638
              ],
              [
                -73.85241844896015,
                40.6472532118956
              ],
              [
                -73.85241077433211,
                40.64711844585524
              ],
              [
                -73.85239368353616,
                40.64698418269542
              ],
              [
                -73.85236722518137,
                40.6468508038351
              ],
              [
                -73.85233147448616,
                40.646718688178936
              ],
              [
                -73.85228653306682,
                40.64658821104078
              ],
              [
                -73.85223252864492,
                40.64645974307783
              ],
              [
                -73.85216961468622,
                40.64633364923708
              ],
              [
                -73.8520979699638,
                40.646210287719754
              ],
              [
                -73.85201779804854,
                40.646090008962815
              ],
              [
                -73.85192932673164,
                40.64597315464422
              ],
              [
                -73.85183280737658,
                40.64586005671262
              ],
              [
                -73.84950699084165,
                40.644133765678774
              ],
              [
                -73.845996083007,
                40.64456831949448
              ],
              [
                -73.84089258685123,
                40.64496903671177
              ],
              [
                -73.84058394688778,
                40.644863026022605
              ],
              [
                -73.84030326670076,
                40.64500944497184
              ],
              [
                -73.84009313481012,
                40.64505620356249
              ],
              [
                -73.84000646464365,
                40.64513606888882
              ],
              [
                -73.83970469104592,
                40.64515639707752
              ],
              [
                -73.83640735456112,
                40.645317413498425
              ],
              [
                -73.83633074787276,
                40.645321149280505
              ],
              [
                -73.83626109724382,
                40.6453516770483
              ],
              [
                -73.83592320073284,
                40.645499790674954
              ],
              [
                -73.83681334575157,
                40.64924941500194
              ],
              [
                -73.83723913520092,
                40.65089692276271
              ],
              [
                -73.83762971693615,
                40.6525077107773
              ],
              [
                -73.83814021702015,
                40.65422479256091
              ],
              [
                -73.83855449359646,
                40.65589206266263
              ],
              [
                -73.83895864143369,
                40.65755139365542
              ],
              [
                -73.83942312759972,
                40.659230644163436
              ],
              [
                -73.83978752612933,
                40.66048599804801
              ],
              [
                -73.83972540353096,
                40.66095065887477
              ],
              [
                -73.83997949975625,
                40.661904273862234
              ],
              [
                -73.83958258784455,
                40.66208228458937
              ],
              [
                -73.83934944817479,
                40.66218467836681
              ],
              [
                -73.8387360855594,
                40.662454060021076
              ],
              [
                -73.83805800948616,
                40.66200606333904
              ],
              [
                -73.83878517503125,
                40.66108844843479
              ],
              [
                -73.83826804054719,
                40.659377900272
              ],
              [
                -73.8379647452176,
                40.65776477174412
              ],
              [
                -73.83754994165396,
                40.656028284424565
              ],
              [
                -73.83704202978238,
                40.654409550282224
              ],
              [
                -73.836538981054,
                40.652698463356415
              ],
              [
                -73.83622362056934,
                40.65107375730866
              ],
              [
                -73.83572275412187,
                40.64937822992839
              ],
              [
                -73.83578775207206,
                40.649162198678994
              ],
              [
                -73.83575488271195,
                40.64911251861524
              ],
              [
                -73.83572947152958,
                40.6490603624343
              ],
              [
                -73.83571183379932,
                40.649006377269814
              ],
              [
                -73.83570218834342,
                40.648951232947006
              ],
              [
                -73.83570065481867,
                40.64889561367202
              ],
              [
                -73.83570725223217,
                40.648840209541966
              ],
              [
                -73.83572189870628,
                40.648785707983265
              ],
              [
                -73.83571437724277,
                40.64872688567388
              ],
              [
                -73.83569927830658,
                40.64866891475943
              ],
              [
                -73.83567674904123,
                40.648612360109
              ],
              [
                -73.8356470089908,
                40.64855777279051
              ],
              [
                -73.83561034795801,
                40.64850568470053
              ],
              [
                -73.8355671231816,
                40.648456603382485
              ],
              [
                -73.83551775585407,
                40.64841100708057
              ],
              [
                -73.8354627270177,
                40.64836934008047
              ],
              [
                -73.83540257287673,
                40.64833200838043
              ],
              [
                -73.83527877303453,
                40.64828384481462
              ],
              [
                -73.83515168862532,
                40.64824093026812
              ],
              [
                -73.835021702915,
                40.6482033941623
              ],
              [
                -73.83488920791645,
                40.64817134969802
              ],
              [
                -73.83475460320872,
                40.648144893514186
              ],
              [
                -73.83461829473161,
                40.64812410539682
              ],
              [
                -73.83448069356174,
                40.648109048038265
              ],
              [
                -73.83434221467296,
                40.648099766847906
              ],
              [
                -73.83420327568595,
                40.64809628981582
              ],
              [
                -73.83166902767807,
                40.647972211017645
              ],
              [
                -73.83158080311367,
                40.64800981150384
              ],
              [
                -73.8314972547742,
                40.64805316814803
              ],
              [
                -73.83141902828879,
                40.64810194591173
              ],
              [
                -73.83134672816617,
                40.64815576786409
              ],
              [
                -73.83128091312282,
                40.648214218094076
              ],
              [
                -73.83122209176555,
                40.64827684492427
              ],
              [
                -73.8311707186609,
                40.64834316440142
              ],
              [
                -73.8311271908222,
                40.64841266403529
              ],
              [
                -73.83109184464118,
                40.64848480675929
              ],
              [
                -73.83106495328707,
                40.648559035079835
              ],
              [
                -73.83104672459606,
                40.6486347753853
              ],
              [
                -73.83103729946343,
                40.64871144237756
              ],
              [
                -73.83103675075473,
                40.64878844359568
              ],
              [
                -73.83104647225137,
                40.64908920770505
              ],
              [
                -73.83111241541593,
                40.6494199220414
              ],
              [
                -73.83127055094019,
                40.65006783182467
              ],
              [
                -73.8314392955408,
                40.65069429983084
              ],
              [
                -73.83156681412632,
                40.651167714415806
              ],
              [
                -73.83170565136656,
                40.651683134577226
              ],
              [
                -73.8318330050207,
                40.65213831057452
              ],
              [
                -73.83197428225198,
                40.65264323892535
              ],
              [
                -73.83217106968996,
                40.653346552404486
              ],
              [
                -73.83238192807039,
                40.6542376666548
              ],
              [
                -73.8325688862547,
                40.655027751935165
              ],
              [
                -73.83280439716816,
                40.655822735255846
              ],
              [
                -73.83306069956934,
                40.65668787927796
              ],
              [
                -73.83338439472544,
                40.65805185920798
              ],
              [
                -73.83284428358573,
                40.65813812869346
              ],
              [
                -73.83194576717005,
                40.658274346859116
              ],
              [
                -73.83128446183628,
                40.65840668263044
              ],
              [
                -73.83096907996668,
                40.65823029417234
              ],
              [
                -73.83201491707895,
                40.65807753263677
              ],
              [
                -73.83260036370599,
                40.65782659162733
              ],
              [
                -73.83194394578256,
                40.65617142725338
              ],
              [
                -73.83208497100838,
                40.65613102851132
              ],
              [
                -73.83278194361473,
                40.657548460383495
              ],
              [
                -73.83290309524601,
                40.657483771623006
              ],
              [
                -73.83285224112385,
                40.657062138771735
              ],
              [
                -73.83275352381291,
                40.656742985804286
              ],
              [
                -73.83248465579328,
                40.655873727062385
              ],
              [
                -73.83224117996961,
                40.65508654248411
              ],
              [
                -73.8321818606802,
                40.65489475195478
              ],
              [
                -73.83212857266406,
                40.65487544577759
              ],
              [
                -73.83206411482192,
                40.654889396766556
              ],
              [
                -73.83200725288442,
                40.65490052071422
              ],
              [
                -73.83191670520524,
                40.65488260712334
              ],
              [
                -73.8318523847929,
                40.65488503826691
              ],
              [
                -73.83175312959285,
                40.65496210950518
              ],
              [
                -73.83168728328505,
                40.655091260206675
              ],
              [
                -73.83161418598198,
                40.655194435687584
              ],
              [
                -73.83155309177049,
                40.6552429728747
              ],
              [
                -73.83155930253487,
                40.65535534364433
              ],
              [
                -73.8315472294415,
                40.655415743091844
              ],
              [
                -73.83151638832737,
                40.65546449041525
              ],
              [
                -73.83146993367257,
                40.65555345217367
              ],
              [
                -73.83147596319569,
                40.65563337828009
              ],
              [
                -73.83146320794879,
                40.655700618851284
              ],
              [
                -73.83144150703528,
                40.65576651663348
              ],
              [
                -73.8314110871302,
                40.65583038321861
              ],
              [
                -73.83137226599474,
                40.65589155141575
              ],
              [
                -73.83132544915816,
                40.655949382221294
              ],
              [
                -73.83127112568108,
                40.656003271494676
              ],
              [
                -73.83120986304854,
                40.65605265626985
              ],
              [
                -73.83114230124039,
                40.65609702063661
              ],
              [
                -73.83110547238412,
                40.65611876166861
              ],
              [
                -73.83106670806474,
                40.65613845468092
              ],
              [
                -73.83050532057368,
                40.65634236731615
              ],
              [
                -73.83039087156523,
                40.656435709132374
              ],
              [
                -73.83032728545408,
                40.65650662730798
              ],
              [
                -73.83024118364905,
                40.65663148083697
              ],
              [
                -73.83018507808814,
                40.65669388796893
              ],
              [
                -73.83009172569676,
                40.65673635744868
              ],
              [
                -73.83000967244328,
                40.656736236211835
              ],
              [
                -73.82990152392094,
                40.65673039577453
              ],
              [
                -73.829891865403,
                40.656728820795735
              ],
              [
                -73.82977849236663,
                40.65671033048467
              ],
              [
                -73.82968157040276,
                40.6566903032254
              ],
              [
                -73.82958468477634,
                40.65665607388188
              ],
              [
                -73.82950265119837,
                40.65664743198323
              ],
              [
                -73.82946151593032,
                40.656689977169535
              ],
              [
                -73.82946131261421,
                40.65676951032551
              ],
              [
                -73.829494655654,
                40.65685761471543
              ],
              [
                -73.8294982617395,
                40.65690590795539
              ],
              [
                -73.82942711948475,
                40.6570137395408
              ],
              [
                -73.82934715237306,
                40.65710305815868
              ],
              [
                -73.82924393205002,
                40.65718105614681
              ],
              [
                -73.82917290744729,
                40.65724344063425
              ],
              [
                -73.82909434825203,
                40.65733421866332
              ],
              [
                -73.82906423436305,
                40.657442112650095
              ],
              [
                -73.82910499991122,
                40.657544429058845
              ],
              [
                -73.82922393902857,
                40.65770651238392
              ],
              [
                -73.82937290582937,
                40.657794788408104
              ],
              [
                -73.82945866947031,
                40.65780343760186
              ],
              [
                -73.82951463014484,
                40.65779783883258
              ],
              [
                -73.82963013197599,
                40.65784629785772
              ],
              [
                -73.82970079467542,
                40.65792593544326
              ],
              [
                -73.82977140702343,
                40.658025457192664
              ],
              [
                -73.82982712281719,
                40.658116434856694
              ],
              [
                -73.82989047899356,
                40.65813641184888
              ],
              [
                -73.82991415861345,
                40.658144597291
              ],
              [
                -73.83000600274035,
                40.65817634852307
              ],
              [
                -73.8301027678112,
                40.65825886521025
              ],
              [
                -73.83016976043841,
                40.658315773528294
              ],
              [
                -73.83024427039375,
                40.65834996934818
              ],
              [
                -73.8302440680071,
                40.65842950198767
              ],
              [
                -73.83019910654943,
                40.658508969064144
              ],
              [
                -73.8301580575766,
                40.65851742995981
              ],
              [
                -73.83013346980562,
                40.65853149250715
              ],
              [
                -73.83005721360321,
                40.65857124945065
              ],
              [
                -73.83000120757706,
                40.658593890244056
              ],
              [
                -73.82994527545966,
                40.658588127013736
              ],
              [
                -73.82987072192734,
                40.658570973769045
              ],
              [
                -73.82979240260045,
                40.658568017626614
              ],
              [
                -73.8297065289459,
                40.65860197635544
              ],
              [
                -73.82969520083391,
                40.65865592869148
              ],
              [
                -73.82971373370533,
                40.658701403737254
              ],
              [
                -73.82972481464316,
                40.65874402629943
              ],
              [
                -73.8297638699683,
                40.65882457547288
              ],
              [
                -73.82978551303393,
                40.65888007029171
              ],
              [
                -73.82982589468608,
                40.65896126923763
              ],
              [
                -73.82988152586422,
                40.65903089943323
              ],
              [
                -73.82989716548171,
                40.65904868626837
              ],
              [
                -73.82994729215727,
                40.659105694910686
              ],
              [
                -73.83001214184989,
                40.65916316387881
              ],
              [
                -73.8300216692606,
                40.659196561173374
              ],
              [
                -73.83002780027701,
                40.65922988643684
              ],
              [
                -73.8300341461414,
                40.65925979142263
              ],
              [
                -73.8300689875143,
                40.65942398436714
              ],
              [
                -73.83007338502351,
                40.65947650297144
              ],
              [
                -73.83009786351583,
                40.659564913033996
              ],
              [
                -73.83010937351253,
                40.65960903757746
              ],
              [
                -73.83011550258621,
                40.65964315775381
              ],
              [
                -73.83012437380432,
                40.65966760604268
              ],
              [
                -73.83013365723922,
                40.65969380058542
              ],
              [
                -73.83014068468839,
                40.659709202632996
              ],
              [
                -73.83015376710541,
                40.65972096853836
              ],
              [
                -73.83016641944104,
                40.65973654021963
              ],
              [
                -73.83017592521581,
                40.65975845105124
              ],
              [
                -73.8301773206398,
                40.65977606369466
              ],
              [
                -73.83017248495642,
                40.6597889021288
              ],
              [
                -73.83016243281726,
                40.6598041109262
              ],
              [
                -73.83014950104759,
                40.65980868495731
              ],
              [
                -73.83013138340057,
                40.65980690317595
              ],
              [
                -73.83011665938497,
                40.65978815376841
              ],
              [
                -73.83010115287577,
                40.659754808633444
              ],
              [
                -73.83008151753675,
                40.65971114418244
              ],
              [
                -73.830050130331,
                40.65963239448659
              ],
              [
                -73.83002344734399,
                40.65957887999993
              ],
              [
                -73.83001290827576,
                40.659554586714876
              ],
              [
                -73.83001426888887,
                40.65952333831572
              ],
              [
                -73.83000997071117,
                40.65950143734391
              ],
              [
                -73.82999639203986,
                40.65945334232529
              ],
              [
                -73.82997176218308,
                40.659349384543845
              ],
              [
                -73.829965808183,
                40.65932446628161
              ],
              [
                -73.82996192425614,
                40.659303519491544
              ],
              [
                -73.8299597282835,
                40.659296555440676
              ],
              [
                -73.82994255948424,
                40.65924208618035
              ],
              [
                -73.82992819287433,
                40.659180981407424
              ],
              [
                -73.82991536797653,
                40.659155414532115
              ],
              [
                -73.82987347914914,
                40.659101233040296
              ],
              [
                -73.82982393761212,
                40.65903097943118
              ],
              [
                -73.82981399218815,
                40.65901687524389
              ],
              [
                -73.82975486375837,
                40.65894854086477
              ],
              [
                -73.82972318498385,
                40.658893904007776
              ],
              [
                -73.8296925320881,
                40.65883615062188
              ],
              [
                -73.82967103985062,
                40.65879528408579
              ],
              [
                -73.82961343158013,
                40.65870966027128
              ],
              [
                -73.82959986903019,
                40.658656806915396
              ],
              [
                -73.8296065278313,
                40.658600186315454
              ],
              [
                -73.82962667738495,
                40.65855707860324
              ],
              [
                -73.82966825159824,
                40.65852258057251
              ],
              [
                -73.82970706838906,
                40.658502513864484
              ],
              [
                -73.8297320793274,
                40.65849859839243
              ],
              [
                -73.82977169416301,
                40.65848836843673
              ],
              [
                -73.82981318489234,
                40.658477666467334
              ],
              [
                -73.82985300549767,
                40.658467911061095
              ],
              [
                -73.82988279238083,
                40.65846860680395
              ],
              [
                -73.8299167073457,
                40.65847962236207
              ],
              [
                -73.82995500685273,
                40.65848794953018
              ],
              [
                -73.82998894883302,
                40.65849182605149
              ],
              [
                -73.83002290290227,
                40.65849173668062
              ],
              [
                -73.83005061350448,
                40.65849004839813
              ],
              [
                -73.83007584536904,
                40.65848264406377
              ],
              [
                -73.83011362777819,
                40.65846019477694
              ],
              [
                -73.83012345473455,
                40.65845006243003
              ],
              [
                -73.83013062197733,
                40.658425804682416
              ],
              [
                -73.83013028740389,
                40.658402643721146
              ],
              [
                -73.83011807881135,
                40.658379299370495
              ],
              [
                -73.83009440305685,
                40.65835910285972
              ],
              [
                -73.830053020508,
                40.65833887197489
              ],
              [
                -73.83000933019636,
                40.65832371226763
              ],
              [
                -73.82997749698586,
                40.658312700818946
              ],
              [
                -73.82995461939598,
                40.65830234272708
              ],
              [
                -73.82993571633205,
                40.65828755117176
              ],
              [
                -73.82993139548506,
                40.65827199496381
              ],
              [
                -73.82993039585924,
                40.6582600959532
              ],
              [
                -73.82991710261359,
                40.65824944025395
              ],
              [
                -73.8298640541808,
                40.658229503318054
              ],
              [
                -73.82982243247803,
                40.658218312164244
              ],
              [
                -73.82980019620072,
                40.65820303702627
              ],
              [
                -73.82979192787604,
                40.65818477793193
              ],
              [
                -73.82977638969626,
                40.65816095049101
              ],
              [
                -73.82975314668516,
                40.65813615539199
              ],
              [
                -73.82972820317205,
                40.65812103421233
              ],
              [
                -73.8296890823881,
                40.65810921378971
              ],
              [
                -73.82965499496454,
                40.658088204168216
              ],
              [
                -73.8295541464757,
                40.65797806131063
              ],
              [
                -73.82953964021831,
                40.65795661560979
              ],
              [
                -73.82953241448706,
                40.657938357706115
              ],
              [
                -73.82952765623907,
                40.65792898916625
              ],
              [
                -73.82951375096921,
                40.65791452442429
              ],
              [
                -73.82945589724292,
                40.65789870070533
              ],
              [
                -73.829417622329,
                40.65788323518796
              ],
              [
                -73.8293876196137,
                40.65788523554144
              ],
              [
                -73.82932389422109,
                40.657880663134165
              ],
              [
                -73.82925795356903,
                40.65785419395177
              ],
              [
                -73.82920828304648,
                40.65782204739615
              ],
              [
                -73.82917919734568,
                40.65779977854428
              ],
              [
                -73.82913225563826,
                40.657702754762695
              ],
              [
                -73.82907748488758,
                40.657581284803534
              ],
              [
                -73.8290132530184,
                40.65742521182773
              ],
              [
                -73.82898079995603,
                40.65729522216301
              ],
              [
                -73.82896508276984,
                40.65726298592368
              ],
              [
                -73.82896084087689,
                40.657226015639814
              ],
              [
                -73.82896778740296,
                40.65720524792007
              ],
              [
                -73.82898285487585,
                40.657185608348904
              ],
              [
                -73.8290043505531,
                40.65717438943226
              ],
              [
                -73.82903021278634,
                40.65716524162266
              ],
              [
                -73.82905921013833,
                40.65715292547796
              ],
              [
                -73.82908591602636,
                40.65714092489513
              ],
              [
                -73.82909990801163,
                40.65713080100088
              ],
              [
                -73.82911536880955,
                40.65711766545051
              ],
              [
                -73.82913482607461,
                40.65709375102419
              ],
              [
                -73.82915801556824,
                40.65707444514942
              ],
              [
                -73.82920543743198,
                40.65703583493438
              ],
              [
                -73.82923845041701,
                40.65700718833912
              ],
              [
                -73.8292662361284,
                40.65698408503011
              ],
              [
                -73.82928525714213,
                40.65696556283854
              ],
              [
                -73.82930974695338,
                40.65693245753517
              ],
              [
                -73.82930287477964,
                40.65687263733884
              ],
              [
                -73.82928204674597,
                40.65681342281772
              ],
              [
                -73.82926720321493,
                40.65676960885122
              ],
              [
                -73.82925959351661,
                40.65674167324479
              ],
              [
                -73.8292606922071,
                40.6567258116075
              ],
              [
                -73.829258470225,
                40.65670629509868
              ],
              [
                -73.82924646374745,
                40.65668501339268
              ],
              [
                -73.8292365379905,
                40.656664527519055
              ],
              [
                -73.82924163157504,
                40.65663788649891
              ],
              [
                -73.82925819722638,
                40.65660651123435
              ],
              [
                -73.82930775204434,
                40.6565536280378
              ],
              [
                -73.82937603410723,
                40.656506335215965
              ],
              [
                -73.82947893001378,
                40.65644927767985
              ],
              [
                -73.82953233393798,
                40.65642686044974
              ],
              [
                -73.82955413715317,
                40.65642377086847
              ],
              [
                -73.82955286969737,
                40.656437956806904
              ],
              [
                -73.82960562793441,
                40.65644448515605
              ],
              [
                -73.8296089716734,
                40.656431618228844
              ],
              [
                -73.82965828569365,
                40.65644694842202
              ],
              [
                -73.82971177079273,
                40.656460699950216
              ],
              [
                -73.8297995641651,
                40.65649197276191
              ],
              [
                -73.82984486541207,
                40.65652284123112
              ],
              [
                -73.82988890772087,
                40.65655640318574
              ],
              [
                -73.82992739066431,
                40.65657155373612
              ],
              [
                -73.82998526032287,
                40.656582616757234
              ],
              [
                -73.83003301456556,
                40.65656716833609
              ],
              [
                -73.83005514080462,
                40.65655420551352
              ],
              [
                -73.83007188433658,
                40.65653203038682
              ],
              [
                -73.83008780413877,
                40.6565063627481
              ],
              [
                -73.83008724952313,
                40.65648669174413
              ],
              [
                -73.83007856308653,
                40.65646906446877
              ],
              [
                -73.83021213161007,
                40.6563371925055
              ],
              [
                -73.83024550384125,
                40.656324569073654
              ],
              [
                -73.83028389469517,
                40.6563067226315
              ],
              [
                -73.83034959412645,
                40.65628306117195
              ],
              [
                -73.83038004883461,
                40.65626599456391
              ],
              [
                -73.83038132783888,
                40.65626527842324
              ],
              [
                -73.83040507534943,
                40.65625197214509
              ],
              [
                -73.83046931981686,
                40.65621703514759
              ],
              [
                -73.83047191680427,
                40.65621567093798
              ],
              [
                -73.83060324919234,
                40.65613989430177
              ],
              [
                -73.83075005477025,
                40.65607124700919
              ],
              [
                -73.83083321606246,
                40.65604345157029
              ],
              [
                -73.83088868963759,
                40.6560359514772
              ],
              [
                -73.83092856003488,
                40.65603056223288
              ],
              [
                -73.83110341009044,
                40.65594681934605
              ],
              [
                -73.83116100809627,
                40.655919233737166
              ],
              [
                -73.83130844752576,
                40.655599324638864
              ],
              [
                -73.83135764938115,
                40.655470975839805
              ],
              [
                -73.83138106348437,
                40.655351796412724
              ],
              [
                -73.83141211466815,
                40.65519373895365
              ],
              [
                -73.83149330103005,
                40.65497982053969
              ],
              [
                -73.83154966356706,
                40.654917034858364
              ],
              [
                -73.83160220213368,
                40.65484199906217
              ],
              [
                -73.83185835751884,
                40.654611839725554
              ],
              [
                -73.83190474303174,
                40.654630519939104
              ],
              [
                -73.83204647773076,
                40.654481076319556
              ],
              [
                -73.83216594711061,
                40.65453045482921
              ],
              [
                -73.83221698518575,
                40.65446038629294
              ],
              [
                -73.83219775627533,
                40.654454077095956
              ],
              [
                -73.83216050229879,
                40.65450950867271
              ],
              [
                -73.83205201431501,
                40.65446642666778
              ],
              [
                -73.83200052870741,
                40.65444081896506
              ],
              [
                -73.83212330981648,
                40.654273161676066
              ],
              [
                -73.83205445130537,
                40.65404641806764
              ],
              [
                -73.8319968847036,
                40.65400152699488
              ],
              [
                -73.83195297309997,
                40.65403133434726
              ],
              [
                -73.8317081441153,
                40.65393117429689
              ],
              [
                -73.83146913310613,
                40.6541158575324
              ],
              [
                -73.83144752173936,
                40.65410263768664
              ],
              [
                -73.83134353287537,
                40.65416837939515
              ],
              [
                -73.83135734463538,
                40.65418069958862
              ],
              [
                -73.83097845384093,
                40.65449499728797
              ],
              [
                -73.83060245974755,
                40.654806889777575
              ],
              [
                -73.83056886757998,
                40.65486131285203
              ],
              [
                -73.8305560975616,
                40.654892921740554
              ],
              [
                -73.8305583178127,
                40.65492718927473
              ],
              [
                -73.83056930687597,
                40.655142455925414
              ],
              [
                -73.83055777780149,
                40.6551398033839
              ],
              [
                -73.83055028060309,
                40.65491224243858
              ],
              [
                -73.83053759090538,
                40.65491222374297
              ],
              [
                -73.83053540141074,
                40.65531987725623
              ],
              [
                -73.83053069151869,
                40.655357648990694
              ],
              [
                -73.83055145115408,
                40.65535943678201
              ],
              [
                -73.83055620208107,
                40.65530585075516
              ],
              [
                -73.83055658589423,
                40.655154737125905
              ],
              [
                -73.83057157846139,
                40.65515651641771
              ],
              [
                -73.83058237017302,
                40.65532375453788
              ],
              [
                -73.83055410998001,
                40.655394769436384
              ],
              [
                -73.83053411626767,
                40.655420264784226
              ],
              [
                -73.83044328071922,
                40.65551947244448
              ],
              [
                -73.8303697749091,
                40.65557179443946
              ],
              [
                -73.830308055358,
                40.65561999347818
              ],
              [
                -73.83020017064872,
                40.65565570813665
              ],
              [
                -73.83015032686718,
                40.65566460284435
              ],
              [
                -73.8300860160229,
                40.65566312775938
              ],
              [
                -73.83006519242146,
                40.655658957870024
              ],
              [
                -73.83002356082697,
                40.655644408828344
              ],
              [
                -73.83002267408831,
                40.65563681981891
              ],
              [
                -73.82985054455617,
                40.65564484306467
              ],
              [
                -73.82966677652134,
                40.655599040016355
              ],
              [
                -73.82943341421314,
                40.655818761757544
              ],
              [
                -73.82945602175384,
                40.65583397285636
              ],
              [
                -73.8293897294643,
                40.65589872179446
              ],
              [
                -73.82934890224662,
                40.655924187204135
              ],
              [
                -73.82921364262403,
                40.6560371254486
              ],
              [
                -73.82918818855708,
                40.65607227030177
              ],
              [
                -73.82915279961651,
                40.656096363115545
              ],
              [
                -73.82910836312278,
                40.65611561380376
              ],
              [
                -73.82907029897899,
                40.65612314544521
              ],
              [
                -73.82903950091571,
                40.65612309967281
              ],
              [
                -73.82901417557953,
                40.65610788445241
              ],
              [
                -73.82902428985523,
                40.65604926151257
              ],
              [
                -73.8290406635642,
                40.65602238094799
              ],
              [
                -73.82917306859065,
                40.6558648655424
              ],
              [
                -73.82934489242977,
                40.65571002208627
              ],
              [
                -73.82990080019458,
                40.65529687902323
              ],
              [
                -73.83008155069425,
                40.655143841054624
              ],
              [
                -73.83011226681153,
                40.6550972821852
              ],
              [
                -73.8301301265926,
                40.6550396663723
              ],
              [
                -73.83018320665522,
                40.655064273266824
              ],
              [
                -73.83020905999666,
                40.65502997144835
              ],
              [
                -73.83016885409617,
                40.65500906266849
              ],
              [
                -73.83014785427359,
                40.65503478765407
              ],
              [
                -73.83013498681484,
                40.65502863509913
              ],
              [
                -73.83015719582365,
                40.65500135673469
              ],
              [
                -73.83042047893507,
                40.65482827198997
              ],
              [
                -73.830425167031,
                40.65483084527054
              ],
              [
                -73.8311318184884,
                40.654248097946095
              ],
              [
                -73.83120160215553,
                40.65418976108503
              ],
              [
                -73.83118201843119,
                40.65417083010364
              ],
              [
                -73.83158322059658,
                40.65381378633368
              ],
              [
                -73.83161314793684,
                40.653748953236196
              ],
              [
                -73.83159216342204,
                40.65364699650284
              ],
              [
                -73.83156068724121,
                40.65360365032635
              ],
              [
                -73.83156040928802,
                40.65360326778682
              ],
              [
                -73.83139918925784,
                40.65351404947987
              ],
              [
                -73.83129214295735,
                40.6534709244684
              ],
              [
                -73.83129886631285,
                40.653233772107235
              ],
              [
                -73.83133151403268,
                40.65274755744263
              ],
              [
                -73.83110356276802,
                40.652256695411175
              ],
              [
                -73.8309944186776,
                40.65176986504941
              ],
              [
                -73.83093651353481,
                40.65162612925583
              ],
              [
                -73.83081972914303,
                40.65151378578287
              ],
              [
                -73.8306991637799,
                40.65140431258623
              ],
              [
                -73.83053125515515,
                40.651329520124385
              ],
              [
                -73.83038140837137,
                40.65114791992982
              ],
              [
                -73.83001172288489,
                40.65091735424785
              ],
              [
                -73.82986948766401,
                40.6504296306443
              ],
              [
                -73.82968053496424,
                40.65022886619794
              ],
              [
                -73.82945096002805,
                40.65004416733567
              ],
              [
                -73.82938618067728,
                40.6500344090457
              ],
              [
                -73.82933909113267,
                40.64994732500824
              ],
              [
                -73.8292314845031,
                40.64988086987097
              ],
              [
                -73.82913955292905,
                40.64983384359273
              ],
              [
                -73.82912090236671,
                40.64982581589168
              ],
              [
                -73.82911349712793,
                40.64976828127217
              ],
              [
                -73.82907978220996,
                40.64966181132405
              ],
              [
                -73.82900455239407,
                40.64954665058593
              ],
              [
                -73.82891475597495,
                40.649379155820654
              ],
              [
                -73.82884595377621,
                40.649344096854094
              ],
              [
                -73.82878155878606,
                40.64930447775525
              ],
              [
                -73.82872209037984,
                40.64926061807522
              ],
              [
                -73.82866802819375,
                40.64921287156725
              ],
              [
                -73.82859874347989,
                40.64916979456999
              ],
              [
                -73.82852522721237,
                40.64913099927314
              ],
              [
                -73.82844793440582,
                40.6490967257891
              ],
              [
                -73.82836734344617,
                40.649067186242995
              ],
              [
                -73.82828395313024,
                40.649042563461066
              ],
              [
                -73.82819827957856,
                40.649023009838
              ],
              [
                -73.82811085304165,
                40.64900864639472
              ],
              [
                -73.82805365995087,
                40.64901998026128
              ],
              [
                -73.82799502108342,
                40.64902557539942
              ],
              [
                -73.82793592469449,
                40.64902533751281
              ],
              [
                -73.82787736674904,
                40.64901927061066
              ],
              [
                -73.82782033413825,
                40.649007476939914
              ],
              [
                -73.82776578804481,
                40.648990155262155
              ],
              [
                -73.82771464774393,
                40.64896759750319
              ],
              [
                -73.82766777510973,
                40.64894018383361
              ],
              [
                -73.82766103254055,
                40.64893562305563
              ],
              [
                -73.82765338230843,
                40.64893198684006
              ],
              [
                -73.82764504054491,
                40.648929377915906
              ],
              [
                -73.82763624291755,
                40.64892786998927
              ],
              [
                -73.82762723797369,
                40.648927505661526
              ],
              [
                -73.82761828011664,
                40.648928295225396
              ],
              [
                -73.82760962241967,
                40.648930216374445
              ],
              [
                -73.82760150947664,
                40.64893321483334
              ],
              [
                -73.82759417049095,
                40.648937205890874
              ],
              [
                -73.82758781280077,
                40.648942076793254
              ],
              [
                -73.82758261602079,
                40.648947689929926
              ],
              [
                -73.82757872696895,
                40.648953886720946
              ],
              [
                -73.8275505313945,
                40.64896136540876
              ],
              [
                -73.82752122418499,
                40.64896570685226
              ],
              [
                -73.82749140376714,
                40.64896682240293
              ],
              [
                -73.82746167904786,
                40.64896468928232
              ],
              [
                -73.82743265697913,
                40.6489593510468
              ],
              [
                -73.82741402724488,
                40.648954073918354
              ],
              [
                -73.82733874603292,
                40.64894539714439
              ],
              [
                -73.82726478462722,
                40.64893162931575
              ],
              [
                -73.82719273528123,
                40.64891288067976
              ],
              [
                -73.827123174935,
                40.648889301368094
              ],
              [
                -73.82705666059681,
                40.64886108019405
              ],
              [
                -73.82699372488173,
                40.64882844314125
              ],
              [
                -73.82693055388556,
                40.64881642896361
              ],
              [
                -73.82686957464296,
                40.64879904518614
              ],
              [
                -73.82681157114656,
                40.64877651530746
              ],
              [
                -73.82675728912727,
                40.64874912898789
              ],
              [
                -73.82672745601468,
                40.64869767959132
              ],
              [
                -73.82669139111327,
                40.64864860003753
              ],
              [
                -73.82664941531884,
                40.648602327005115
              ],
              [
                -73.82660190211631,
                40.64855927220128
              ],
              [
                -73.82658130944175,
                40.64852038005436
              ],
              [
                -73.8265661906541,
                40.64848005632383
              ],
              [
                -73.8265567128021,
                40.6484387465753
              ],
              [
                -73.82655298060223,
                40.64839690726854
              ],
              [
                -73.82652753836285,
                40.648375169941836
              ],
              [
                -73.82649802934516,
                40.64835665330203
              ],
              [
                -73.82646514835756,
                40.64834179333344
              ],
              [
                -73.82642966960194,
                40.64833093992153
              ],
              [
                -73.82639242872236,
                40.64832434880783
              ],
              [
                -73.82671943599986,
                40.64957881899987
              ],
              [
                -73.82689623399988,
                40.65034970899996
              ],
              [
                -73.82701324499997,
                40.65085989899995
              ],
              [
                -73.82712509999998,
                40.65132568599991
              ],
              [
                -73.82724192599983,
                40.65181216199989
              ],
              [
                -73.82736078999979,
                40.65230711899992
              ],
              [
                -73.827478071,
                40.65279547799986
              ],
              [
                -73.82759641799994,
                40.65328826599993
              ],
              [
                -73.82770683599992,
                40.65374803599989
              ],
              [
                -73.82782507599995,
                40.65424036199992
              ],
              [
                -73.82807181199998,
                40.65526770199994
              ],
              [
                -73.82827925599995,
                40.656033532999885
              ],
              [
                -73.82859942399985,
                40.6571969729999
              ],
              [
                -73.82931419799984,
                40.65889865199993
              ],
              [
                -73.82991335799981,
                40.659665667999946
              ],
              [
                -73.83003131999997,
                40.65989400799991
              ],
              [
                -73.83030563699988,
                40.660424996999936
              ],
              [
                -73.83107771099985,
                40.66205622399986
              ],
              [
                -73.83185737999993,
                40.66365393099993
              ],
              [
                -73.83247768299996,
                40.66496821299991
              ],
              [
                -73.832705339,
                40.665405772999854
              ],
              [
                -73.83291947199984,
                40.665765048999916
              ],
              [
                -73.83295614299986,
                40.665827065999956
              ],
              [
                -73.83300277599999,
                40.66592051799994
              ],
              [
                -73.83334819299995,
                40.66677318699994
              ],
              [
                -73.83464965599987,
                40.667115307999936
              ],
              [
                -73.83487654300001,
                40.6672116649999
              ],
              [
                -73.83528941499992,
                40.667235978999955
              ],
              [
                -73.83570108799995,
                40.66726022199987
              ],
              [
                -73.83667829499991,
                40.66758649199992
              ],
              [
                -73.83730072099985,
                40.667707358999955
              ],
              [
                -73.83744908699992,
                40.667798349999885
              ],
              [
                -73.83834358199996,
                40.66798902599986
              ],
              [
                -73.83927850999994,
                40.668201848999935
              ],
              [
                -73.84008991099994,
                40.66843655599992
              ],
              [
                -73.84013623800001,
                40.66826802499993
              ],
              [
                -73.84117399199987,
                40.66850744299989
              ],
              [
                -73.84215802199992,
                40.66870144799986
              ],
              [
                -73.84227237899998,
                40.66873604599993
              ],
              [
                -73.84229542199994,
                40.66881391799992
              ],
              [
                -73.84243585399989,
                40.66928855699992
              ],
              [
                -73.84258049099998,
                40.66928053299988
              ],
              [
                -73.84271367699986,
                40.66927829299993
              ],
              [
                -73.84288589399995,
                40.66928507599987
              ],
              [
                -73.84305613800001,
                40.66930593799987
              ],
              [
                -73.84322150199992,
                40.66934041099991
              ],
              [
                -73.84556670099984,
                40.67008464499985
              ],
              [
                -73.84593839099992,
                40.67017897699987
              ],
              [
                -73.8474137819998,
                40.67062999499992
              ],
              [
                -73.84787000899989,
                40.67077949999991
              ],
              [
                -73.84847765299996,
                40.67091673899989
              ],
              [
                -73.850964537,
                40.67171951099988
              ]
            ]
          ],
          [
            [
              [
                -73.83032725337058,
                40.655132805803305
              ],
              [
                -73.83004306625594,
                40.65534942254124
              ],
              [
                -73.82994556435834,
                40.655424494353
              ],
              [
                -73.82988919856028,
                40.655522822584075
              ],
              [
                -73.8299259406864,
                40.65554220350468
              ],
              [
                -73.83000321318903,
                40.65555212870698
              ],
              [
                -73.8301257067886,
                40.65551061084527
              ],
              [
                -73.83019020357143,
                40.655478819385834
              ],
              [
                -73.83026601851492,
                40.65542864678474
              ],
              [
                -73.83033058333683,
                40.65536987237488
              ],
              [
                -73.83035808100335,
                40.65532208288756
              ],
              [
                -73.83040011516177,
                40.655257144501505
              ],
              [
                -73.83041203905732,
                40.65521585576174
              ],
              [
                -73.83041325059033,
                40.655157822336285
              ],
              [
                -73.8303778971816,
                40.65512833511791
              ],
              [
                -73.83032725337058,
                40.655132805803305
              ]
            ]
          ],
          [
            [
              [
                -73.85975533686633,
                40.65410417609495
              ],
              [
                -73.85979242750741,
                40.65447615737696
              ],
              [
                -73.86015870367471,
                40.654793663305895
              ],
              [
                -73.86021221902251,
                40.65484005263406
              ],
              [
                -73.86024486376577,
                40.65487225456881
              ],
              [
                -73.86025130311069,
                40.654878606692364
              ],
              [
                -73.86025748919218,
                40.65488405050394
              ],
              [
                -73.86030402711815,
                40.65492500272206
              ],
              [
                -73.86045463170862,
                40.65506710736616
              ],
              [
                -73.86072015717329,
                40.65519718344291
              ],
              [
                -73.86076223553128,
                40.65521213047427
              ],
              [
                -73.86080677472891,
                40.65522211962027
              ],
              [
                -73.86085277407571,
                40.65522692644853
              ],
              [
                -73.86089920007385,
                40.655226442960796
              ],
              [
                -73.86094500963966,
                40.65522068001973
              ],
              [
                -73.86098917376945,
                40.65520976705256
              ],
              [
                -73.86059601899994,
                40.65477969699992
              ],
              [
                -73.86056716699993,
                40.654747451999874
              ],
              [
                -73.86052767799987,
                40.6547059619999
              ],
              [
                -73.86048308199986,
                40.65465892799988
              ],
              [
                -73.86042178299996,
                40.65461220199989
              ],
              [
                -73.85975533686633,
                40.65410417609495
              ]
            ]
          ],
          [
            [
              [
                -73.85898002989568,
                40.65267545791771
              ],
              [
                -73.85900715381493,
                40.653004058372126
              ],
              [
                -73.85904529229782,
                40.653107976083916
              ],
              [
                -73.8591261328236,
                40.653172994731754
              ],
              [
                -73.85924955873764,
                40.65325429542834
              ],
              [
                -73.85934240802169,
                40.65356106691007
              ],
              [
                -73.8594447402705,
                40.653637548104285
              ],
              [
                -73.85940989064696,
                40.65385786181041
              ],
              [
                -73.85941022942576,
                40.65386473792503
              ],
              [
                -73.85948691913471,
                40.65393234893364
              ],
              [
                -73.85975533686633,
                40.65410417609495
              ],
              [
                -73.85898002989568,
                40.65267545791771
              ]
            ]
          ],
          [
            [
              [
                -73.85898002989568,
                40.65267545791771
              ],
              [
                -73.85695870295892,
                40.65058737348715
              ],
              [
                -73.85702601785158,
                40.650693719304435
              ],
              [
                -73.85718757766341,
                40.65087569460869
              ],
              [
                -73.85730224535344,
                40.65106410457844
              ],
              [
                -73.85731941515513,
                40.65123709900785
              ],
              [
                -73.85752745156287,
                40.65137924311267
              ],
              [
                -73.85774883373948,
                40.65148988169545
              ],
              [
                -73.85783392607334,
                40.65155815255726
              ],
              [
                -73.85793978941108,
                40.65167574157643
              ],
              [
                -73.85805076446096,
                40.65180186819084
              ],
              [
                -73.85837385611718,
                40.65218529244998
              ],
              [
                -73.85854822276487,
                40.65236079018086
              ],
              [
                -73.85868859934205,
                40.65248755609561
              ],
              [
                -73.85898002989568,
                40.65267545791771
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0007032738273,
        "objectid": 126,
        "shape_leng": 0.12994872606,
        "location_id": 126,
        "zone": "Hunts Point",
        "borough": "Bronx"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.88615037899991,
                40.82730567799995
              ],
              [
                -73.88609624199987,
                40.827655776999904
              ],
              [
                -73.88639957199996,
                40.827503378999886
              ],
              [
                -73.88789510999992,
                40.82674318499989
              ],
              [
                -73.88885046199992,
                40.82676859499989
              ],
              [
                -73.88963895299987,
                40.8267975099999
              ],
              [
                -73.88969004999984,
                40.826799383999834
              ],
              [
                -73.88980564199993,
                40.82680362199994
              ],
              [
                -73.88983599199996,
                40.82680473399996
              ],
              [
                -73.88997942599995,
                40.82680999199989
              ],
              [
                -73.89090560099991,
                40.82678778499993
              ],
              [
                -73.89191271899998,
                40.82679444999992
              ],
              [
                -73.89293226099981,
                40.82678703699992
              ],
              [
                -73.89386719099983,
                40.82651496799989
              ],
              [
                -73.89483522899992,
                40.826246840999964
              ],
              [
                -73.89487088500002,
                40.82393727199993
              ],
              [
                -73.89389702299997,
                40.82398399799994
              ],
              [
                -73.89320004999996,
                40.824008182999926
              ],
              [
                -73.89390164399987,
                40.823643120999904
              ],
              [
                -73.89433874699982,
                40.82341635299984
              ],
              [
                -73.89488228899991,
                40.82313435499988
              ],
              [
                -73.89586019599982,
                40.822631045999856
              ],
              [
                -73.8958813589999,
                40.82099257499988
              ],
              [
                -73.89490478799985,
                40.820987519999946
              ],
              [
                -73.89387380299988,
                40.820979334999926
              ],
              [
                -73.89283653100001,
                40.82097405499998
              ],
              [
                -73.89290156199988,
                40.82081122299991
              ],
              [
                -73.89389462500002,
                40.8198602899999
              ],
              [
                -73.894930762,
                40.81884671999994
              ],
              [
                -73.89567610799998,
                40.81812936799991
              ],
              [
                -73.8970446669999,
                40.816801859999906
              ],
              [
                -73.8962135219999,
                40.81631897099989
              ],
              [
                -73.89580071599984,
                40.81606564899989
              ],
              [
                -73.89542858799986,
                40.81584467199988
              ],
              [
                -73.89685073199996,
                40.81446016799987
              ],
              [
                -73.89708341799985,
                40.814224345999904
              ],
              [
                -73.89733049099995,
                40.81399633599995
              ],
              [
                -73.89759123799989,
                40.81377687299992
              ],
              [
                -73.89775779499988,
                40.81364891599992
              ],
              [
                -73.89786486599994,
                40.81356666999991
              ],
              [
                -73.89815051799985,
                40.813366353999875
              ],
              [
                -73.89844723499995,
                40.81317650299993
              ],
              [
                -73.89912449199987,
                40.81269524099988
              ],
              [
                -73.90090498099991,
                40.811428712999884
              ],
              [
                -73.90259424199986,
                40.81022335899997
              ],
              [
                -73.90279087799998,
                40.810083046999864
              ],
              [
                -73.90307457199998,
                40.80988061199988
              ],
              [
                -73.90307568299997,
                40.809768985999945
              ],
              [
                -73.90302967699985,
                40.809702120999866
              ],
              [
                -73.90302018099997,
                40.80962342899996
              ],
              [
                -73.9030087789999,
                40.809550072999876
              ],
              [
                -73.90299766999989,
                40.80945558299994
              ],
              [
                -73.90298795399984,
                40.80936430099996
              ],
              [
                -73.90293929200004,
                40.80906270099993
              ],
              [
                -73.90288034499996,
                40.808744792999974
              ],
              [
                -73.90222284749719,
                40.80494811309475
              ],
              [
                -73.9022105498581,
                40.804950444085605
              ],
              [
                -73.90219315158322,
                40.80494809570519
              ],
              [
                -73.90218395580108,
                40.804937198455164
              ],
              [
                -73.90217680960625,
                40.80492397049571
              ],
              [
                -73.90216556590887,
                40.80491307267904
              ],
              [
                -73.90214921089174,
                40.80489828059668
              ],
              [
                -73.90213183502179,
                40.804881154694804
              ],
              [
                -73.90211549422733,
                40.80485625165957
              ],
              [
                -73.90211039482898,
                40.80484380274888
              ],
              [
                -73.90210325482057,
                40.804826685031244
              ],
              [
                -73.90210234487554,
                40.804819692275316
              ],
              [
                -73.90210122978193,
                40.804811128297494
              ],
              [
                -73.90209715811166,
                40.80479634613557
              ],
              [
                -73.90208285109311,
                40.80478077964038
              ],
              [
                -73.9020685374805,
                40.80476910021591
              ],
              [
                -73.90205261120369,
                40.80476668920271
              ],
              [
                -73.9019953447206,
                40.804751534352
              ],
              [
                -73.90191482832148,
                40.80475146587339
              ],
              [
                -73.90178827694882,
                40.80476883817056
              ],
              [
                -73.9016732158928,
                40.8047949604022
              ],
              [
                -73.90152939058154,
                40.80482542934504
              ],
              [
                -73.9013222978677,
                40.80486021234714
              ],
              [
                -73.90115552085723,
                40.80485569941628
              ],
              [
                -73.90097148274116,
                40.804855541470076
              ],
              [
                -73.90081615675385,
                40.804885999475765
              ],
              [
                -73.90063777921121,
                40.80494702693435
              ],
              [
                -73.90049967830153,
                40.80499497839508
              ],
              [
                -73.90022352372337,
                40.80506029150024
              ],
              [
                -73.89985531773284,
                40.80514737360609
              ],
              [
                -73.89946985583099,
                40.80523444066137
              ],
              [
                -73.89919723524343,
                40.80529093940124
              ],
              [
                -73.89904988651375,
                40.805321475042476
              ],
              [
                -73.89853926244915,
                40.80541173173145
              ],
              [
                -73.89831343224124,
                40.805517482476375
              ],
              [
                -73.8981151559596,
                40.80563396548573
              ],
              [
                -73.89808317604229,
                40.80565275226313
              ],
              [
                -73.8980744627235,
                40.80566924860035
              ],
              [
                -73.89804856839497,
                40.80571827344633
              ],
              [
                -73.8981059864958,
                40.805779505271964
              ],
              [
                -73.89819792651059,
                40.80583202779014
              ],
              [
                -73.89823813224358,
                40.80586702336687
              ],
              [
                -73.89824374203175,
                40.80595880041047
              ],
              [
                -73.89826085644887,
                40.80605058777352
              ],
              [
                -73.89822049087705,
                40.806120473974254
              ],
              [
                -73.89817641121758,
                40.80613353615754
              ],
              [
                -73.89816110752683,
                40.806138070099855
              ],
              [
                -73.89806803798805,
                40.8059137157084
              ],
              [
                -73.89793600296244,
                40.80552029002879
              ],
              [
                -73.89787127990651,
                40.805534981759635
              ],
              [
                -73.89799037051526,
                40.80593085364809
              ],
              [
                -73.89800306596389,
                40.80609064751374
              ],
              [
                -73.8980134089615,
                40.806166604361806
              ],
              [
                -73.89787360834964,
                40.80617953999495
              ],
              [
                -73.89786715835055,
                40.80611264967438
              ],
              [
                -73.89779945195382,
                40.805962640911574
              ],
              [
                -73.89772827251059,
                40.80596749413728
              ],
              [
                -73.89775065693242,
                40.80613712932528
              ],
              [
                -73.89776126648744,
                40.806189933501514
              ],
              [
                -73.89763818741136,
                40.80620132083884
              ],
              [
                -73.89754706596734,
                40.80599437375069
              ],
              [
                -73.8974859174091,
                40.8057878325167
              ],
              [
                -73.89741472698567,
                40.805800059478074
              ],
              [
                -73.89747588735356,
                40.80599922682154
              ],
              [
                -73.89749681953876,
                40.80621440020254
              ],
              [
                -73.89738639439207,
                40.806224617136664
              ],
              [
                -73.8973129614691,
                40.806081527294694
              ],
              [
                -73.89726014999152,
                40.8060409598472
              ],
              [
                -73.89717387408167,
                40.80604525364755
              ],
              [
                -73.89715079498718,
                40.806093303628536
              ],
              [
                -73.89711438014326,
                40.80611086592895
              ],
              [
                -73.89708748307676,
                40.806123837656685
              ],
              [
                -73.89678682323746,
                40.80615925851491
              ],
              [
                -73.89635118493798,
                40.806210580554584
              ],
              [
                -73.8962764250295,
                40.80620614259313
              ],
              [
                -73.89623040737013,
                40.80621047190213
              ],
              [
                -73.89619585301467,
                40.806241031322514
              ],
              [
                -73.89619572352184,
                40.80632406352671
              ],
              [
                -73.89620708243055,
                40.806415845339046
              ],
              [
                -73.89622424119065,
                40.8064770418422
              ],
              [
                -73.89610824517877,
                40.80649615095126
              ],
              [
                -73.89601320589148,
                40.80626831615592
              ],
              [
                -73.895948888355,
                40.806022440451976
              ],
              [
                -73.89587481599303,
                40.8058085115824
              ],
              [
                -73.89579066958076,
                40.80583055920915
              ],
              [
                -73.8958776634502,
                40.80605679059505
              ],
              [
                -73.89593553363191,
                40.80628791146805
              ],
              [
                -73.89594184174581,
                40.80639116118041
              ],
              [
                -73.89597794687755,
                40.806517615839546
              ],
              [
                -73.89583838073091,
                40.806540607571904
              ],
              [
                -73.89583174887831,
                40.806454974302504
              ],
              [
                -73.89574790898853,
                40.80628036801016
              ],
              [
                -73.89567025138753,
                40.80629013045689
              ],
              [
                -73.89569912530925,
                40.80644502168963
              ],
              [
                -73.89572273778744,
                40.80655965730823
              ],
              [
                -73.89559109091819,
                40.8065813433475
              ],
              [
                -73.8955116391718,
                40.806348983004874
              ],
              [
                -73.89546655698209,
                40.80621620001536
              ],
              [
                -73.8954054906102,
                40.805960493749275
              ],
              [
                -73.89534416707072,
                40.80586948553049
              ],
              [
                -73.89531197487513,
                40.80576867103502
              ],
              [
                -73.8952796287401,
                40.805766184067906
              ],
              [
                -73.89531181635847,
                40.80586945615722
              ],
              [
                -73.8953020614291,
                40.805901404161716
              ],
              [
                -73.89539221099777,
                40.80617680177724
              ],
              [
                -73.89541794877962,
                40.80626777768829
              ],
              [
                -73.89550633968572,
                40.806595305303645
              ],
              [
                -73.89545911867938,
                40.80660308388955
              ],
              [
                -73.89537269057816,
                40.80670351733483
              ],
              [
                -73.89528550564228,
                40.80684271107136
              ],
              [
                -73.89509836487397,
                40.80686363917353
              ],
              [
                -73.89496909528714,
                40.80623113049388
              ],
              [
                -73.89484295983623,
                40.80572399701221
              ],
              [
                -73.89472970526674,
                40.80574110146164
              ],
              [
                -73.89485360535866,
                40.80621490815875
              ],
              [
                -73.89480337371349,
                40.806208222822605
              ],
              [
                -73.89477679720954,
                40.806213634245196
              ],
              [
                -73.89472601909603,
                40.80602624658351
              ],
              [
                -73.8945739391341,
                40.80604577300728
              ],
              [
                -73.8946325235109,
                40.80623233192205
              ],
              [
                -73.89462863918334,
                40.806232644993585
              ],
              [
                -73.89452510142179,
                40.806242382508565
              ],
              [
                -73.8942209637422,
                40.80626668559509
              ],
              [
                -73.89410449503039,
                40.80626903743051
              ],
              [
                -73.89403658607417,
                40.80625176721945
              ],
              [
                -73.89395248091247,
                40.80624677353254
              ],
              [
                -73.89383602227304,
                40.80624420825316
              ],
              [
                -73.89371954313471,
                40.80625393413141
              ],
              [
                -73.89362245442146,
                40.80627596757254
              ],
              [
                -73.89354804655919,
                40.80627589888845
              ],
              [
                -73.89345420126807,
                40.806293020005555
              ],
              [
                -73.8933765511915,
                40.80629786456428
              ],
              [
                -73.89330516489227,
                40.80634399783534
              ],
              [
                -73.89315654170535,
                40.80631240992103
              ],
              [
                -73.89304654394503,
                40.80631476551205
              ],
              [
                -73.89289770901877,
                40.80632691879631
              ],
              [
                -73.8927481403741,
                40.80634026130199
              ],
              [
                -73.89256667738417,
                40.805620710004725
              ],
              [
                -73.89240027239099,
                40.80564532485975
              ],
              [
                -73.8923980350899,
                40.805638488931876
              ],
              [
                -73.8923350700471,
                40.80564867947971
              ],
              [
                -73.89233730507779,
                40.805656370118335
              ],
              [
                -73.89230020327585,
                40.80566060567012
              ],
              [
                -73.89219739671567,
                40.80528468791937
              ],
              [
                -73.89182888997564,
                40.80516647209418
              ],
              [
                -73.89186610809385,
                40.80509048742664
              ],
              [
                -73.89173240995615,
                40.80504936342029
              ],
              [
                -73.89169561834646,
                40.80511584342212
              ],
              [
                -73.89150645665745,
                40.80505769308249
              ],
              [
                -73.89146488860796,
                40.80504398744603
              ],
              [
                -73.89131118790088,
                40.80498982140617
              ],
              [
                -73.89131376917373,
                40.80498493831313
              ],
              [
                -73.89128402413917,
                40.80497628189916
              ],
              [
                -73.8912671062289,
                40.805010489424156
              ],
              [
                -73.89129856973848,
                40.805016497722505
              ],
              [
                -73.89130372999014,
                40.80500678832565
              ],
              [
                -73.89150751395867,
                40.80507031104263
              ],
              [
                -73.89149997938128,
                40.80508158808916
              ],
              [
                -73.89156004966382,
                40.8050999810407
              ],
              [
                -73.89156417608577,
                40.80509393995633
              ],
              [
                -73.891686993125,
                40.80513011010928
              ],
              [
                -73.89167604184013,
                40.80515095314249
              ],
              [
                -73.89180973582788,
                40.8051937849576
              ],
              [
                -73.8918131200952,
                40.80518695546603
              ],
              [
                -73.89216364547643,
                40.805302592565425
              ],
              [
                -73.89227315843527,
                40.80570243312481
              ],
              [
                -73.89253177139425,
                40.80565655156828
              ],
              [
                -73.89270681066346,
                40.80634394909811
              ],
              [
                -73.89270641330582,
                40.80634399044191
              ],
              [
                -73.89245494636062,
                40.80637010660528
              ],
              [
                -73.89197929534278,
                40.806384708976196
              ],
              [
                -73.89178618135598,
                40.80562289208621
              ],
              [
                -73.89144104277274,
                40.80566189856482
              ],
              [
                -73.89149903729991,
                40.8059312387431
              ],
              [
                -73.89149717186729,
                40.805927198969336
              ],
              [
                -73.89127112984032,
                40.80591242189429
              ],
              [
                -73.89101234323621,
                40.80589988606528
              ],
              [
                -73.89086354177184,
                40.805892371352776
              ],
              [
                -73.8907212261885,
                40.8058750287227
              ],
              [
                -73.89058213079707,
                40.805867522847066
              ],
              [
                -73.89051422588408,
                40.80584779297372
              ],
              [
                -73.89043667322538,
                40.805793638766474
              ],
              [
                -73.89035259769872,
                40.805771435838906
              ],
              [
                -73.89033596715151,
                40.80576663651646
              ],
              [
                -73.89023295689198,
                40.805736907528235
              ],
              [
                -73.89013594081199,
                40.80571469094741
              ],
              [
                -73.89005932692345,
                40.80570432518393
              ],
              [
                -73.88999139115282,
                40.80570426037567
              ],
              [
                -73.88996089069998,
                40.805714769917465
              ],
              [
                -73.88992823364853,
                40.80574844674449
              ],
              [
                -73.88989905762257,
                40.805785290927766
              ],
              [
                -73.88987229258508,
                40.805831356464886
              ],
              [
                -73.88985041274157,
                40.80585714644435
              ],
              [
                -73.88981398349773,
                40.80587739185005
              ],
              [
                -73.88981346825864,
                40.80587739135758
              ],
              [
                -73.88975332987161,
                40.805875491088834
              ],
              [
                -73.88965874963506,
                40.80584774526956
              ],
              [
                -73.88954478026055,
                40.80580707619277
              ],
              [
                -73.8893743925369,
                40.80592527311521
              ],
              [
                -73.890150985016,
                40.80495818226255
              ],
              [
                -73.88999598170764,
                40.804886286752776
              ],
              [
                -73.889995124756,
                40.804887350890446
              ],
              [
                -73.88979493978445,
                40.80513577207837
              ],
              [
                -73.88977893617727,
                40.805155633599696
              ],
              [
                -73.88947004066108,
                40.80553895898339
              ],
              [
                -73.88946981686438,
                40.805539236524986
              ],
              [
                -73.88922416091161,
                40.80584535119195
              ],
              [
                -73.88940685507217,
                40.80558202078196
              ],
              [
                -73.889441059023,
                40.80544009355649
              ],
              [
                -73.88938265718072,
                40.80531020813596
              ],
              [
                -73.88927892586686,
                40.80518182876005
              ],
              [
                -73.88907297129742,
                40.805013860005374
              ],
              [
                -73.88882576006077,
                40.80485322537337
              ],
              [
                -73.88859217706313,
                40.80478927560034
              ],
              [
                -73.88795897223945,
                40.8049979259206
              ],
              [
                -73.88763129537637,
                40.804671325375985
              ],
              [
                -73.88771124600427,
                40.80461478122007
              ],
              [
                -73.88752886022138,
                40.804401726740345
              ],
              [
                -73.88740234683179,
                40.80437434258938
              ],
              [
                -73.88733552633306,
                40.80429318940804
              ],
              [
                -73.8872473845825,
                40.804186141119395
              ],
              [
                -73.88738095624211,
                40.804110681283944
              ],
              [
                -73.88727097347115,
                40.8039470023367
              ],
              [
                -73.8870857353168,
                40.80380916368019
              ],
              [
                -73.8868487982525,
                40.8036352191167
              ],
              [
                -73.88672389248721,
                40.80353021498236
              ],
              [
                -73.88648691823535,
                40.80337921283246
              ],
              [
                -73.8864243555294,
                40.803345162755825
              ],
              [
                -73.88627576454361,
                40.80326428936847
              ],
              [
                -73.88616377056557,
                40.803175684976814
              ],
              [
                -73.88609972664483,
                40.80308537287127
              ],
              [
                -73.88607771246615,
                40.80305432969496
              ],
              [
                -73.88600887424613,
                40.80295265729102
              ],
              [
                -73.88591424672595,
                40.802798516993064
              ],
              [
                -73.88578073880988,
                40.80268039213666
              ],
              [
                -73.8855997811226,
                40.80256549733334
              ],
              [
                -73.8852555367023,
                40.802409781025375
              ],
              [
                -73.88515517126592,
                40.802272454046424
              ],
              [
                -73.88526166505221,
                40.80229683344886
              ],
              [
                -73.88525755169408,
                40.802308237466505
              ],
              [
                -73.88528342105936,
                40.80234248553894
              ],
              [
                -73.88533116885945,
                40.80235290267008
              ],
              [
                -73.88538033197243,
                40.802334285769945
              ],
              [
                -73.88539676408831,
                40.80230215307005
              ],
              [
                -73.88536544598874,
                40.80226063974466
              ],
              [
                -73.88531087058676,
                40.802253326772586
              ],
              [
                -73.88527262213964,
                40.802274028967865
              ],
              [
                -73.88526714505164,
                40.80228439531952
              ],
              [
                -73.8851457697332,
                40.802259589487285
              ],
              [
                -73.88505172336708,
                40.80213090679797
              ],
              [
                -73.8839894532262,
                40.8019038796612
              ],
              [
                -73.88396774707934,
                40.80195744485262
              ],
              [
                -73.88378457957894,
                40.801919683823925
              ],
              [
                -73.88379279040652,
                40.8019072480143
              ],
              [
                -73.88377442409538,
                40.80187378498757
              ],
              [
                -73.88372326665917,
                40.80186284394103
              ],
              [
                -73.88368024716245,
                40.80188069019258
              ],
              [
                -73.88366893088866,
                40.801913345967975
              ],
              [
                -73.88369036996541,
                40.801945255805194
              ],
              [
                -73.8837384573708,
                40.801956193769755
              ],
              [
                -73.88377737963155,
                40.80193989916994
              ],
              [
                -73.88378353970126,
                40.80192901592477
              ],
              [
                -73.88396462519694,
                40.801965148359656
              ],
              [
                -73.88393791667497,
                40.80203106129535
              ],
              [
                -73.88391536772113,
                40.80204892757249
              ],
              [
                -73.8838999931241,
                40.802061357851024
              ],
              [
                -73.8838774507143,
                40.80207533486863
              ],
              [
                -73.88385182690112,
                40.80209553165793
              ],
              [
                -73.88383849993177,
                40.80210718399522
              ],
              [
                -73.88380941028178,
                40.80212219573655
              ],
              [
                -73.88369542422481,
                40.80216212360289
              ],
              [
                -73.88354011849329,
                40.80217917463179
              ],
              [
                -73.88320360331396,
                40.80222799770891
              ],
              [
                -73.88307091642712,
                40.802259820082895
              ],
              [
                -73.88297059068802,
                40.80228429903962
              ],
              [
                -73.88288323972418,
                40.80228912718334
              ],
              [
                -73.88276028400804,
                40.80230620885112
              ],
              [
                -73.88259526944276,
                40.80232570632336
              ],
              [
                -73.88254113402193,
                40.80233568156292
              ],
              [
                -73.88251894912045,
                40.80225218128442
              ],
              [
                -73.88243021774045,
                40.80226557239003
              ],
              [
                -73.88244775081927,
                40.802351212093384
              ],
              [
                -73.88239465208328,
                40.802354999950545
              ],
              [
                -73.88237010115716,
                40.802353846955604
              ],
              [
                -73.8823208879866,
                40.80235153497294
              ],
              [
                -73.88219256706495,
                40.802372145836685
              ],
              [
                -73.88206539258177,
                40.80240288628746
              ],
              [
                -73.8820601312739,
                40.80240415828226
              ],
              [
                -73.88203490143373,
                40.802407793388866
              ],
              [
                -73.88193863933246,
                40.802421663907005
              ],
              [
                -73.88182125479855,
                40.80243191425689
              ],
              [
                -73.88170931957762,
                40.80244735497574
              ],
              [
                -73.8815332076576,
                40.802481396450624
              ],
              [
                -73.88143082957599,
                40.80249477276176
              ],
              [
                -73.88132025761237,
                40.802511251986864
              ],
              [
                -73.88117965667224,
                40.802529773446125
              ],
              [
                -73.88106910311686,
                40.802535882321486
              ],
              [
                -73.88092030545262,
                40.80255854342731
              ],
              [
                -73.88077149808389,
                40.80258742721189
              ],
              [
                -73.88056943638324,
                40.80263284747915
              ],
              [
                -73.88041836510055,
                40.80266794028656
              ],
              [
                -73.88027898796521,
                40.80272105380305
              ],
              [
                -73.88017115118777,
                40.80272421955093
              ],
              [
                -73.88005462984884,
                40.80276015140756
              ],
              [
                -73.87991660603436,
                40.8027600077111
              ],
              [
                -73.87975704576816,
                40.802743453764144
              ],
              [
                -73.8797430082038,
                40.80274343912096
              ],
              [
                -73.87962764836531,
                40.8027433187194
              ],
              [
                -73.87950995961023,
                40.80276949081519
              ],
              [
                -73.87934292184536,
                40.80277251917869
              ],
              [
                -73.87921348407693,
                40.80279532683503
              ],
              [
                -73.8789245383548,
                40.802772080508284
              ],
              [
                -73.87874344166545,
                40.802739114243025
              ],
              [
                -73.87843295513449,
                40.80270273469495
              ],
              [
                -73.87823464921779,
                40.80264680576819
              ],
              [
                -73.87816581140154,
                40.802551683744916
              ],
              [
                -73.87811438894289,
                40.802368084879326
              ],
              [
                -73.8779064649521,
                40.80227180102014
              ],
              [
                -73.87806294853425,
                40.802194319345
              ],
              [
                -73.8780330023647,
                40.80205990686082
              ],
              [
                -73.87796880375369,
                40.80171111512818
              ],
              [
                -73.87798629509405,
                40.801656715506915
              ],
              [
                -73.8780768548655,
                40.801666644034995
              ],
              [
                -73.87814218819929,
                40.801319290306495
              ],
              [
                -73.87592998071507,
                40.80108422359372
              ],
              [
                -73.87585788581308,
                40.80145598758634
              ],
              [
                -73.87526517037327,
                40.80139815254847
              ],
              [
                -73.87473060452689,
                40.80125663762865
              ],
              [
                -73.87467716313955,
                40.80097942336671
              ],
              [
                -73.87355096572502,
                40.80085330277184
              ],
              [
                -73.87317223327184,
                40.80099783970594
              ],
              [
                -73.87270503758596,
                40.80116717681056
              ],
              [
                -73.87266088531166,
                40.80114791108709
              ],
              [
                -73.87261058260187,
                40.80110410183527
              ],
              [
                -73.8725854704256,
                40.80106099335558
              ],
              [
                -73.87257566696651,
                40.801013804969756
              ],
              [
                -73.87258566151068,
                40.8009611639185
              ],
              [
                -73.8725988302891,
                40.80089185814027
              ],
              [
                -73.8726270581177,
                40.80079186150162
              ],
              [
                -73.87287336804405,
                40.80083936740926
              ],
              [
                -73.8728771106369,
                40.80084008904619
              ],
              [
                -73.87297862944645,
                40.80025068902643
              ],
              [
                -73.87297065553919,
                40.800249730540266
              ],
              [
                -73.87234684998835,
                40.80017476301046
              ],
              [
                -73.87234565306399,
                40.80018113333119
              ],
              [
                -73.87223731051014,
                40.800757694671354
              ],
              [
                -73.87223657260759,
                40.800761622649624
              ],
              [
                -73.87246949337055,
                40.80078919284294
              ],
              [
                -73.87238060453703,
                40.801290114632714
              ],
              [
                -73.87174509373908,
                40.80154736611613
              ],
              [
                -73.87160336192524,
                40.80175181144655
              ],
              [
                -73.87169881930807,
                40.801805271920394
              ],
              [
                -73.87161142592561,
                40.80191439620694
              ],
              [
                -73.8715185503029,
                40.80187414888809
              ],
              [
                -73.86978968017556,
                40.80412510586383
              ],
              [
                -73.86846346959149,
                40.805936548939
              ],
              [
                -73.86811219140058,
                40.806751832831786
              ],
              [
                -73.87055342191628,
                40.81124338072289
              ],
              [
                -73.87047093165313,
                40.81145829499173
              ],
              [
                -73.87056806927927,
                40.812087317622414
              ],
              [
                -73.87095753684095,
                40.81214618060943
              ],
              [
                -73.87165777683393,
                40.813254261976
              ],
              [
                -73.87180463640202,
                40.81331489053923
              ],
              [
                -73.87208038785018,
                40.81358405461942
              ],
              [
                -73.87212931147367,
                40.813621168692585
              ],
              [
                -73.87203687539917,
                40.813685513372704
              ],
              [
                -73.87209716960993,
                40.81373802152102
              ],
              [
                -73.87218961012046,
                40.81367367076715
              ],
              [
                -73.8724366723506,
                40.81385093400158
              ],
              [
                -73.87232977098004,
                40.813921823341296
              ],
              [
                -73.87239437900097,
                40.81397433560582
              ],
              [
                -73.87250110885978,
                40.81388812823407
              ],
              [
                -73.87446381927738,
                40.81464523186162
              ],
              [
                -73.87520539092391,
                40.81487874500283
              ],
              [
                -73.87642408558143,
                40.815284123784465
              ],
              [
                -73.87673185506821,
                40.81538649648658
              ],
              [
                -73.87663459981847,
                40.81571150804957
              ],
              [
                -73.8780628329999,
                40.81618263199994
              ],
              [
                -73.8809802929999,
                40.818237845999924
              ],
              [
                -73.88244779599998,
                40.81926832299992
              ],
              [
                -73.88378786100002,
                40.82193341399996
              ],
              [
                -73.88423986499997,
                40.822625629999955
              ],
              [
                -73.88429488199988,
                40.822718204999944
              ],
              [
                -73.88433212399998,
                40.82281014299991
              ],
              [
                -73.88436128599993,
                40.82287380099997
              ],
              [
                -73.8843985489999,
                40.82296557599989
              ],
              [
                -73.88456358400003,
                40.822937066999906
              ],
              [
                -73.88508162399985,
                40.82286844499986
              ],
              [
                -73.8865801009999,
                40.822668117999875
              ],
              [
                -73.88698003699992,
                40.82261725799987
              ],
              [
                -73.88709505500002,
                40.82260272399986
              ],
              [
                -73.88719405099995,
                40.82259352799987
              ],
              [
                -73.88753429599986,
                40.822509338999964
              ],
              [
                -73.88706248999988,
                40.823190274999945
              ],
              [
                -73.88710697199997,
                40.82330587099989
              ],
              [
                -73.88647552799976,
                40.825285423999915
              ],
              [
                -73.88635032500001,
                40.82601262299995
              ],
              [
                -73.88615037899991,
                40.82730567799995
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000163195647796,
        "objectid": 127,
        "shape_leng": 0.0600188855815,
        "location_id": 127,
        "zone": "Inwood",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.911224294065,
                40.87302771883775
              ],
              [
                -73.91127418900135,
                40.87306348765569
              ],
              [
                -73.91130490090681,
                40.87309194960777
              ],
              [
                -73.91135863952978,
                40.873127721845506
              ],
              [
                -73.91142580718564,
                40.87318173085132
              ],
              [
                -73.91154281116533,
                40.87325370616174
              ],
              [
                -73.91176119999992,
                40.873063789999925
              ],
              [
                -73.91206172199999,
                40.872806091999934
              ],
              [
                -73.91267976599984,
                40.87229176099988
              ],
              [
                -73.91334258899985,
                40.871756027999936
              ],
              [
                -73.91366488599988,
                40.87150058499987
              ],
              [
                -73.9140098549999,
                40.87116059399995
              ],
              [
                -73.91415003100002,
                40.871219850999864
              ],
              [
                -73.91505692599983,
                40.87160289599991
              ],
              [
                -73.9162778549999,
                40.87211564499995
              ],
              [
                -73.91717669599988,
                40.87249897299992
              ],
              [
                -73.91845930899993,
                40.873022052999936
              ],
              [
                -73.91945895199983,
                40.8722492809999
              ],
              [
                -73.91969887499998,
                40.87206477599995
              ],
              [
                -73.91925481799977,
                40.871007841999926
              ],
              [
                -73.92089662999997,
                40.86987701899989
              ],
              [
                -73.92112107599998,
                40.86970946599991
              ],
              [
                -73.92112131299997,
                40.869709285999875
              ],
              [
                -73.92112156199988,
                40.869709123999876
              ],
              [
                -73.92135975499993,
                40.869551853999894
              ],
              [
                -73.92161064999992,
                40.869405685999936
              ],
              [
                -73.921610721,
                40.8694056499999
              ],
              [
                -73.92187252499991,
                40.869271627999865
              ],
              [
                -73.92187269199987,
                40.86927154799988
              ],
              [
                -73.92187285799986,
                40.86927147499992
              ],
              [
                -73.92214424199993,
                40.86915018199993
              ],
              [
                -73.9223436069999,
                40.86945904099991
              ],
              [
                -73.92382143399999,
                40.869018381999915
              ],
              [
                -73.92623313399997,
                40.868506137999915
              ],
              [
                -73.92700250499986,
                40.86795125999991
              ],
              [
                -73.92872203199992,
                40.8667426629999
              ],
              [
                -73.92805770399998,
                40.866199363999954
              ],
              [
                -73.92726915099985,
                40.865534559999944
              ],
              [
                -73.92661948999994,
                40.86452429399989
              ],
              [
                -73.92601092899994,
                40.86357366499991
              ],
              [
                -73.92537318199992,
                40.8625808149999
              ],
              [
                -73.92474153399992,
                40.861593637999924
              ],
              [
                -73.924677287,
                40.86149664499993
              ],
              [
                -73.92461430599997,
                40.86140156099989
              ],
              [
                -73.92411747399994,
                40.86065512499988
              ],
              [
                -73.92295846599977,
                40.85891378099997
              ],
              [
                -73.92295449499989,
                40.85885076199988
              ],
              [
                -73.92282845999992,
                40.856851408999916
              ],
              [
                -73.92277921199988,
                40.8568261979999
              ],
              [
                -73.92263334899997,
                40.856831752999895
              ],
              [
                -73.92204492207946,
                40.856854159792
              ],
              [
                -73.92196978492623,
                40.856911118312865
              ],
              [
                -73.92194132896722,
                40.85693428361933
              ],
              [
                -73.92191814231168,
                40.85695425312496
              ],
              [
                -73.92190021806313,
                40.85697502778718
              ],
              [
                -73.9218865189657,
                40.8569846112325
              ],
              [
                -73.92186334597841,
                40.856991790499265
              ],
              [
                -73.92183702160173,
                40.856992571921474
              ],
              [
                -73.92180121504491,
                40.8569989433622
              ],
              [
                -73.92174434054141,
                40.857013294715706
              ],
              [
                -73.9217032680549,
                40.85702126106142
              ],
              [
                -73.92166323981648,
                40.85703562380639
              ],
              [
                -73.92164638409345,
                40.85704360657247
              ],
              [
                -73.92161794209801,
                40.85705477965234
              ],
              [
                -73.92159160704566,
                40.857065154233375
              ],
              [
                -73.92155857155751,
                40.857088830629124
              ],
              [
                -73.92155849622625,
                40.857152702522846
              ],
              [
                -73.92157985204594,
                40.85721144384182
              ],
              [
                -73.92158614447966,
                40.85723223373973
              ],
              [
                -73.92159242598983,
                40.85726341560259
              ],
              [
                -73.92158397059998,
                40.85728979192766
              ],
              [
                -73.92155550463495,
                40.857321750596235
              ],
              [
                -73.9215196726864,
                40.85734890638003
              ],
              [
                -73.92145963506641,
                40.8573672528818
              ],
              [
                -73.92140169782736,
                40.85738959755385
              ],
              [
                -73.92136378026812,
                40.857399965363875
              ],
              [
                -73.92130475209376,
                40.85742065143315
              ],
              [
                -73.92122323658643,
                40.85752233035376
              ],
              [
                -73.92118439137633,
                40.85752410721184
              ],
              [
                -73.92112119690319,
                40.85753677083312
              ],
              [
                -73.92107291328591,
                40.85751698028255
              ],
              [
                -73.92113244274998,
                40.85745494138236
              ],
              [
                -73.92116405945595,
                40.85743662069778
              ],
              [
                -73.92109350791351,
                40.85738295630128
              ],
              [
                -73.92106933540855,
                40.857394233182
              ],
              [
                -73.92104891513684,
                40.85738152111153
              ],
              [
                -73.9210135498486,
                40.85742946602829
              ],
              [
                -73.92103583613053,
                40.85743935946252
              ],
              [
                -73.92099676230845,
                40.85748447487976
              ],
              [
                -73.92094474783686,
                40.85747033747435
              ],
              [
                -73.92093729165354,
                40.857487262269146
              ],
              [
                -73.92090943072884,
                40.85747877738355
              ],
              [
                -73.92090011378151,
                40.85749428733865
              ],
              [
                -73.9210319869216,
                40.857555044393585
              ],
              [
                -73.92102453923798,
                40.85756407868502
              ],
              [
                -73.92101657172314,
                40.857561776989
              ],
              [
                -73.92101050029305,
                40.85757127391592
              ],
              [
                -73.92101505160242,
                40.85757358148218
              ],
              [
                -73.92101011202337,
                40.85757846860788
              ],
              [
                -73.92095148909628,
                40.857556663495814
              ],
              [
                -73.92094565691801,
                40.8575642327251
              ],
              [
                -73.92100215706549,
                40.8575850987588
              ],
              [
                -73.92098217660369,
                40.85761221698062
              ],
              [
                -73.92092983808878,
                40.85759199222074
              ],
              [
                -73.92091900484338,
                40.85760586142001
              ],
              [
                -73.92089408405235,
                40.85759764155769
              ],
              [
                -73.92090075662524,
                40.857582499179266
              ],
              [
                -73.92079356302033,
                40.8575534032327
              ],
              [
                -73.92078857040964,
                40.857562233308116
              ],
              [
                -73.92077195797644,
                40.85754896662084
              ],
              [
                -73.92075532130517,
                40.8575571591458
              ],
              [
                -73.92069706927528,
                40.85762148676177
              ],
              [
                -73.92071949545583,
                40.85763286578187
              ],
              [
                -73.9207835691488,
                40.85756980360237
              ],
              [
                -73.9207885591364,
                40.857571698701406
              ],
              [
                -73.92077605235171,
                40.85759756056828
              ],
              [
                -73.92079765383627,
                40.85760388767716
              ],
              [
                -73.92077266841045,
                40.857649939761984
              ],
              [
                -73.92078761914492,
                40.85765437063227
              ],
              [
                -73.92081427830648,
                40.85760706042052
              ],
              [
                -73.92088491030913,
                40.85762351560547
              ],
              [
                -73.92089157534419,
                40.85761341584083
              ],
              [
                -73.9209140083115,
                40.857619113275405
              ],
              [
                -73.92088985451419,
                40.8576639061803
              ],
              [
                -73.9209588081255,
                40.85768541149859
              ],
              [
                -73.92093550328049,
                40.857715057538556
              ],
              [
                -73.92092553330006,
                40.85771063010125
              ],
              [
                -73.92093219665317,
                40.85770306094023
              ],
              [
                -73.92092388660173,
                40.85769990379673
              ],
              [
                -73.92091722324945,
                40.85770747178462
              ],
              [
                -73.92090725459605,
                40.85770304384415
              ],
              [
                -73.92091142601693,
                40.85769547347845
              ],
              [
                -73.92090394377362,
                40.857694208574024
              ],
              [
                -73.9208972809938,
                40.8577024067838
              ],
              [
                -73.92086987022243,
                40.85769418520923
              ],
              [
                -73.9208640314502,
                40.85770616565296
              ],
              [
                -73.92087151368248,
                40.85770744044379
              ],
              [
                -73.92087484340281,
                40.857701129947436
              ],
              [
                -73.92089395343788,
                40.8577074558319
              ],
              [
                -73.9208922874603,
                40.85771312803338
              ],
              [
                -73.92090225899011,
                40.85771439581785
              ],
              [
                -73.92090475572954,
                40.857709985133646
              ],
              [
                -73.92091472483578,
                40.85771440436355
              ],
              [
                -73.92091056810698,
                40.857719452843746
              ],
              [
                -73.92091720674985,
                40.857721347894746
              ],
              [
                -73.92092137657248,
                40.85771567858191
              ],
              [
                -73.92093134355565,
                40.85772262741053
              ],
              [
                -73.92092883675433,
                40.8577264083683
              ],
              [
                -73.9209629044585,
                40.857741577492064
              ],
              [
                -73.92097620645463,
                40.857735905051605
              ],
              [
                -73.92098948520304,
                40.85774979964861
              ],
              [
                -73.9209853172339,
                40.857764312351854
              ],
              [
                -73.92097118010598,
                40.85777313684196
              ],
              [
                -73.92096286020836,
                40.85777881319741
              ],
              [
                -73.92083991421826,
                40.857721294888435
              ],
              [
                -73.92082407985954,
                40.85775220748162
              ],
              [
                -73.92095035684835,
                40.85781035713936
              ],
              [
                -73.92096035984791,
                40.857787014262115
              ],
              [
                -73.92098779381654,
                40.85777630838017
              ],
              [
                -73.92098278238336,
                40.85780154635139
              ],
              [
                -73.92100188046803,
                40.85781796615766
              ],
              [
                -73.92101432159707,
                40.85783880318059
              ],
              [
                -73.92100848746315,
                40.857847632689
              ],
              [
                -73.92095530904551,
                40.857834971712904
              ],
              [
                -73.92095947032028,
                40.85782740083621
              ],
              [
                -73.92091792340256,
                40.857817277915004
              ],
              [
                -73.92090874735563,
                40.857843772803285
              ],
              [
                -73.92085806645605,
                40.85782607154625
              ],
              [
                -73.92081902487526,
                40.85781341970117
              ],
              [
                -73.92082485064823,
                40.85780143807837
              ],
              [
                -73.92085476658923,
                40.85780840110435
              ],
              [
                -73.92085894403364,
                40.85779577941521
              ],
              [
                -73.92075009406679,
                40.85776289023625
              ],
              [
                -73.92074591736552,
                40.857774872820706
              ],
              [
                -73.9207209967533,
                40.857766652921526
              ],
              [
                -73.9207176690908,
                40.85777233268964
              ],
              [
                -73.92070770216483,
                40.85776664378533
              ],
              [
                -73.9207118650148,
                40.85775592096779
              ],
              [
                -73.92074018031833,
                40.857712392912646
              ],
              [
                -73.92074102474629,
                40.8576997783191
              ],
              [
                -73.92072604222552,
                40.85771238319908
              ],
              [
                -73.92071524126256,
                40.85770985505422
              ],
              [
                -73.92070610816093,
                40.857701014101934
              ],
              [
                -73.92068280608277,
                40.857727500938644
              ],
              [
                -73.9206744727128,
                40.85774390193743
              ],
              [
                -73.9206786250252,
                40.857752747676464
              ],
              [
                -73.92069440518277,
                40.857757169747714
              ],
              [
                -73.92069356299953,
                40.85776789384213
              ],
              [
                -73.92062871453668,
                40.85778458097081
              ],
              [
                -73.9206311915981,
                40.85779593807325
              ],
              [
                -73.92069520768324,
                40.85778083514134
              ],
              [
                -73.92067272424282,
                40.857817424694986
              ],
              [
                -73.92068020565321,
                40.85781995106413
              ],
              [
                -73.92070601599154,
                40.857778321847256
              ],
              [
                -73.92071848663913,
                40.85778338174717
              ],
              [
                -73.92071431024054,
                40.85779473427549
              ],
              [
                -73.92075835110651,
                40.857810540535226
              ],
              [
                -73.92075084244313,
                40.857831995110466
              ],
              [
                -73.92072417703403,
                40.85788498852889
              ],
              [
                -73.92077568813255,
                40.85790332113988
              ],
              [
                -73.92077315152532,
                40.85794118585793
              ],
              [
                -73.92061194313827,
                40.85790446953135
              ],
              [
                -73.92061195368183,
                40.85789563653846
              ],
              [
                -73.9205828871062,
                40.85787415680172
              ],
              [
                -73.92056458747452,
                40.85788297887913
              ],
              [
                -73.92056957361469,
                40.85788866436666
              ],
              [
                -73.92058121435349,
                40.85788362925947
              ],
              [
                -73.92059865529568,
                40.85789878704374
              ],
              [
                -73.92056783382262,
                40.857955559738315
              ],
              [
                -73.92053626149328,
                40.85794544354678
              ],
              [
                -73.92053125929229,
                40.857953013828954
              ],
              [
                -73.92056616182882,
                40.85796439326245
              ],
              [
                -73.92054366782155,
                40.85800066666607
              ],
              [
                -73.92058272320153,
                40.85801141923441
              ],
              [
                -73.92055940739405,
                40.85804926796663
              ],
              [
                -73.92053115561134,
                40.85803978528797
              ],
              [
                -73.9205244898293,
                40.858049874644045
              ],
              [
                -73.92055690666027,
                40.858058100919905
              ],
              [
                -73.92051858770813,
                40.85812623254581
              ],
              [
                -73.92047870523538,
                40.85811294928194
              ],
              [
                -73.92047369719569,
                40.858125570886756
              ],
              [
                -73.92050777201234,
                40.85813505809411
              ],
              [
                -73.92050776371386,
                40.85814200109049
              ],
              [
                -73.92049359805799,
                40.858165341058694
              ],
              [
                -73.92043129405509,
                40.8581372208709
              ],
              [
                -73.92042547044453,
                40.85814731130443
              ],
              [
                -73.9204827854302,
                40.858171961497824
              ],
              [
                -73.92047278308782,
                40.858195304332554
              ],
              [
                -73.92036477249754,
                40.85816493709914
              ],
              [
                -73.92035142875443,
                40.858195220619145
              ],
              [
                -73.9204843854752,
                40.85823254806245
              ],
              [
                -73.92060931660876,
                40.858018696153046
              ],
              [
                -73.92062427494882,
                40.85801681477228
              ],
              [
                -73.9206600093851,
                40.85802724891317
              ],
              [
                -73.92066414958995,
                40.85803608476008
              ],
              [
                -73.92081205174519,
                40.858082255605574
              ],
              [
                -73.92093869016941,
                40.858120674461524
              ],
              [
                -73.92091459857794,
                40.85821896663772
              ],
              [
                -73.92092950082889,
                40.85825501412292
              ],
              [
                -73.92094165735571,
                40.85828441491401
              ],
              [
                -73.92098748015538,
                40.858289758969825
              ],
              [
                -73.92099712882886,
                40.85830377722352
              ],
              [
                -73.9210639508839,
                40.85829381914131
              ],
              [
                -73.92108767646545,
                40.85829984124802
              ],
              [
                -73.92107887262824,
                40.85831184831973
              ],
              [
                -73.92105864446829,
                40.85831717344756
              ],
              [
                -73.92107885124753,
                40.85832986610281
              ],
              [
                -73.92107355499465,
                40.8583452163224
              ],
              [
                -73.92105857633999,
                40.858374571228296
              ],
              [
                -73.92106558355202,
                40.85839726855678
              ],
              [
                -73.92106819230459,
                40.858417288031646
              ],
              [
                -73.92108224017782,
                40.85843265148383
              ],
              [
                -73.92109189655196,
                40.85844132909271
              ],
              [
                -73.92112177944212,
                40.85844535685077
              ],
              [
                -73.92111383643037,
                40.85847271718302
              ],
              [
                -73.92110236769079,
                40.85851275393425
              ],
              [
                -73.92107155243102,
                40.858553444528184
              ],
              [
                -73.9210856169332,
                40.85855611977917
              ],
              [
                -73.92108911428319,
                40.858566801779055
              ],
              [
                -73.92110317429248,
                40.85857215153363
              ],
              [
                -73.92112073232282,
                40.85858818278336
              ],
              [
                -73.92110930043289,
                40.85859751288116
              ],
              [
                -73.92110400043417,
                40.858616194633974
              ],
              [
                -73.92113298285777,
                40.858638906647386
              ],
              [
                -73.92117253002358,
                40.858645615841816
              ],
              [
                -73.92118832732727,
                40.858666311233264
              ],
              [
                -73.92121027761029,
                40.85868901775321
              ],
              [
                -73.92123928232213,
                40.85869371179081
              ],
              [
                -73.9212568347567,
                40.858715082986926
              ],
              [
                -73.92126384154847,
                40.85873710468035
              ],
              [
                -73.92125502240955,
                40.85876313160802
              ],
              [
                -73.92124971927129,
                40.85878448837617
              ],
              [
                -73.92123916377011,
                40.85879381975475
              ],
              [
                -73.9212391392654,
                40.85881451204279
              ],
              [
                -73.9212558013679,
                40.85884655352703
              ],
              [
                -73.92127775129002,
                40.85886926170835
              ],
              [
                -73.92130940178662,
                40.858863943160955
              ],
              [
                -73.92133313153455,
                40.858866633349265
              ],
              [
                -73.92137003870111,
                40.8588786714329
              ],
              [
                -73.92138847158147,
                40.85889670296219
              ],
              [
                -73.92137001739772,
                40.85889669038685
              ],
              [
                -73.92134542016208,
                40.85888532810863
              ],
              [
                -73.92131378782533,
                40.85887529166448
              ],
              [
                -73.92129619437267,
                40.8588799538981
              ],
              [
                -73.92130672674394,
                40.8588993128658
              ],
              [
                -73.92132253891833,
                40.858907337627066
              ],
              [
                -73.92134098678977,
                40.85891268984172
              ],
              [
                -73.9213620792983,
                40.85891937700612
              ],
              [
                -73.92138491884843,
                40.85893207207082
              ],
              [
                -73.92139545451528,
                40.858940083845006
              ],
              [
                -73.92140688831236,
                40.85893942522687
              ],
              [
                -73.92141478438363,
                40.85895210943488
              ],
              [
                -73.92143674687564,
                40.85896481260309
              ],
              [
                -73.92145693841896,
                40.85898017148006
              ],
              [
                -73.92148241816876,
                40.85899687380335
              ],
              [
                -73.92149647545965,
                40.859005564926306
              ],
              [
                -73.92151667805072,
                40.85901158404418
              ],
              [
                -73.92153688128289,
                40.85902828444114
              ],
              [
                -73.92156323476758,
                40.859038306848646
              ],
              [
                -73.92157640358245,
                40.8590550019536
              ],
              [
                -73.92162299102328,
                40.85905570001837
              ],
              [
                -73.92164144199684,
                40.85906840025916
              ],
              [
                -73.92165637797628,
                40.859075749092845
              ],
              [
                -73.92167133304646,
                40.85906707886248
              ],
              [
                -73.92168362346396,
                40.859074434775295
              ],
              [
                -73.92170031211961,
                40.85908378468562
              ],
              [
                -73.92171348569521,
                40.859096472454404
              ],
              [
                -73.92175392059862,
                40.85909383425407
              ],
              [
                -73.92179523134078,
                40.859103875462374
              ],
              [
                -73.92181807326534,
                40.85911523813002
              ],
              [
                -73.92182860052971,
                40.85912859049983
              ],
              [
                -73.92181013137586,
                40.85914125631136
              ],
              [
                -73.92184878326474,
                40.85916264288542
              ],
              [
                -73.92186633783652,
                40.85918134014976
              ],
              [
                -73.92187159464771,
                40.859200029084526
              ],
              [
                -73.92186363617589,
                40.859240734187836
              ],
              [
                -73.92185568980089,
                40.859270760194725
              ],
              [
                -73.92185654400586,
                40.85929078717131
              ],
              [
                -73.92184158900768,
                40.85930012500566
              ],
              [
                -73.9218362834468,
                40.85932347934413
              ],
              [
                -73.9218063875841,
                40.85932879922491
              ],
              [
                -73.92179318314086,
                40.859352814615036
              ],
              [
                -73.92182743857511,
                40.85937152320405
              ],
              [
                -73.92184589125199,
                40.85938288171891
              ],
              [
                -73.92181422123235,
                40.85939487385554
              ],
              [
                -73.9218010581483,
                40.85938351774833
              ],
              [
                -73.92178084462995,
                40.859376165858485
              ],
              [
                -73.92174216515187,
                40.85937813885153
              ],
              [
                -73.92173862702768,
                40.859391481185064
              ],
              [
                -73.92172807855657,
                40.859394815447835
              ],
              [
                -73.92174475909616,
                40.859411511742024
              ],
              [
                -73.92175531523264,
                40.859410853164086
              ],
              [
                -73.92177813999356,
                40.859436892705396
              ],
              [
                -73.92178337916735,
                40.859460255864654
              ],
              [
                -73.92179128111253,
                40.8594669330016
              ],
              [
                -73.92178951542853,
                40.859475612188575
              ],
              [
                -73.92178423413667,
                40.85947961643428
              ],
              [
                -73.92178421372903,
                40.85949696897949
              ],
              [
                -73.92180092709991,
                40.85949497170502
              ],
              [
                -73.92182640001612,
                40.859508343081615
              ],
              [
                -73.9218395729169,
                40.85952169724189
              ],
              [
                -73.92183602852785,
                40.85954038021452
              ],
              [
                -73.92183337804909,
                40.85955506534812
              ],
              [
                -73.92181579426176,
                40.859560393069266
              ],
              [
                -73.92181669341468,
                40.859542365833605
              ],
              [
                -73.92180349832363,
                40.85954769753206
              ],
              [
                -73.9218034849839,
                40.85955904303372
              ],
              [
                -73.92176920814246,
                40.859559019797416
              ],
              [
                -73.9217586285431,
                40.85957903969074
              ],
              [
                -73.92174017250268,
                40.859579694083415
              ],
              [
                -73.92175425140597,
                40.85956902335617
              ],
              [
                -73.92171996443375,
                40.85956766728357
              ],
              [
                -73.92173052181315,
                40.85955566202522
              ],
              [
                -73.92169009920316,
                40.85954762061979
              ],
              [
                -73.92164878482414,
                40.85955093398912
              ],
              [
                -73.92164788416521,
                40.85957094989075
              ],
              [
                -73.92166897017356,
                40.85957230640458
              ],
              [
                -73.92167072779478,
                40.85958097977293
              ],
              [
                -73.92166455789794,
                40.859592321597304
              ],
              [
                -73.92168123846953,
                40.85960901907275
              ],
              [
                -73.92169705737561,
                40.8596116954394
              ],
              [
                -73.92168648450185,
                40.85963572198345
              ],
              [
                -73.92170406731475,
                40.85963106018624
              ],
              [
                -73.92172252011017,
                40.85963373783402
              ],
              [
                -73.92173832622478,
                40.85964710317339
              ],
              [
                -73.92173654090277,
                40.85967246028849
              ],
              [
                -73.92171017514295,
                40.85967244240075
              ],
              [
                -73.92170136023316,
                40.85969379563056
              ],
              [
                -73.92171803927064,
                40.85971182591403
              ],
              [
                -73.92170654327566,
                40.85976520860326
              ],
              [
                -73.92168799950892,
                40.85983994521568
              ],
              [
                -73.92166068449595,
                40.859890650861324
              ],
              [
                -73.92163335462683,
                40.859964040976585
              ],
              [
                -73.92159014848224,
                40.86007280002688
              ],
              [
                -73.92155671740983,
                40.860099476650014
              ],
              [
                -73.92154265042431,
                40.86009880067782
              ],
              [
                -73.921516243884,
                40.86012347346225
              ],
              [
                -73.92151623522281,
                40.860130812815925
              ],
              [
                -73.92145906374984,
                40.86016281389905
              ],
              [
                -73.92143091729879,
                40.86017480665244
              ],
              [
                -73.92139397626858,
                40.86019080173543
              ],
              [
                -73.92136406183813,
                40.86021146544214
              ],
              [
                -73.92134560571333,
                40.8602127856764
              ],
              [
                -73.92134032212975,
                40.86021678989969
              ],
              [
                -73.92132187073445,
                40.86021411051681
              ],
              [
                -73.92127091296308,
                40.86018804281668
              ],
              [
                -73.92120245201015,
                40.86009856883876
              ],
              [
                -73.92107166676891,
                40.85993630055118
              ],
              [
                -73.92096638988286,
                40.85975936332765
              ],
              [
                -73.9208248103987,
                40.85953762397836
              ],
              [
                -73.92081547121266,
                40.85952299932422
              ],
              [
                -73.92074793277061,
                40.859398152496816
              ],
              [
                -73.92069348890548,
                40.859351398048304
              ],
              [
                -73.92069792257463,
                40.85932403533704
              ],
              [
                -73.92067070680027,
                40.85928997791521
              ],
              [
                -73.92065316209771,
                40.85926326599645
              ],
              [
                -73.92061976769563,
                40.85925924508324
              ],
              [
                -73.92050818037727,
                40.859212450713834
              ],
              [
                -73.92050906689407,
                40.859205103755116
              ],
              [
                -73.92043350742907,
                40.85917235515496
              ],
              [
                -73.92044055434963,
                40.85916101282756
              ],
              [
                -73.92043089498743,
                40.85915500079302
              ],
              [
                -73.92047868273168,
                40.85910409209883
              ],
              [
                -73.9200731723362,
                40.858926343959574
              ],
              [
                -73.91996200375141,
                40.8588783602684
              ],
              [
                -73.91991539174748,
                40.858858598542184
              ],
              [
                -73.91984149127127,
                40.85882719172704
              ],
              [
                -73.91977424610867,
                40.858794772291134
              ],
              [
                -73.91977223409496,
                40.858801858298555
              ],
              [
                -73.91984679689212,
                40.858843882069536
              ],
              [
                -73.91990871589499,
                40.85886719354676
              ],
              [
                -73.91993534975472,
                40.858880863670024
              ],
              [
                -73.9199566565892,
                40.85888644357646
              ],
              [
                -73.9200691729343,
                40.858938614947505
              ],
              [
                -73.92009647993548,
                40.85894521618595
              ],
              [
                -73.92019834633355,
                40.858987258634485
              ],
              [
                -73.92020833439094,
                40.85899586467843
              ],
              [
                -73.92024228428149,
                40.85901106140345
              ],
              [
                -73.92026958923736,
                40.85901867159204
              ],
              [
                -73.92032551794716,
                40.85904349210987
              ],
              [
                -73.92046134307469,
                40.85910326112519
              ],
              [
                -73.92044466588774,
                40.859124492077136
              ],
              [
                -73.9204346715058,
                40.859120946591695
              ],
              [
                -73.92041198649032,
                40.85914773801527
              ],
              [
                -73.92036070029987,
                40.859148711450665
              ],
              [
                -73.92028080078518,
                40.85911527525441
              ],
              [
                -73.92029013424884,
                40.8591061774847
              ],
              [
                -73.92009437526832,
                40.85902815900783
              ],
              [
                -73.91981340987077,
                40.858912646598796
              ],
              [
                -73.9195470792652,
                40.85879866699753
              ],
              [
                -73.91954642281223,
                40.858791579133346
              ],
              [
                -73.91952379146707,
                40.858783477504694
              ],
              [
                -73.91950213660932,
                40.85879113379047
              ],
              [
                -73.91948096295053,
                40.85881393735841
              ],
              [
                -73.91943853541449,
                40.85885542134399
              ],
              [
                -73.91940131157715,
                40.858899249081375
              ],
              [
                -73.91933772085359,
                40.858963833604314
              ],
              [
                -73.9192321799137,
                40.85907661750323
              ],
              [
                -73.91913611131903,
                40.85917299234001
              ],
              [
                -73.91900350576367,
                40.85931346590101
              ],
              [
                -73.91890338201662,
                40.85941804164173
              ],
              [
                -73.9188857796496,
                40.85943916349721
              ],
              [
                -73.91877796600042,
                40.85954675131652
              ],
              [
                -73.9188014004669,
                40.85955809573262
              ],
              [
                -73.91872079932321,
                40.85964222493354
              ],
              [
                -73.91873839393087,
                40.85964830753148
              ],
              [
                -73.91868681416331,
                40.85970516392412
              ],
              [
                -73.91870302556356,
                40.85971645820534
              ],
              [
                -73.91869354554858,
                40.85972877034594
              ],
              [
                -73.918694889946,
                40.85973492157373
              ],
              [
                -73.91867866057468,
                40.859748246660274
              ],
              [
                -73.91866650284997,
                40.859739008230505
              ],
              [
                -73.91864215241455,
                40.85976771710835
              ],
              [
                -73.91862051432182,
                40.85978206428477
              ],
              [
                -73.91860966058975,
                40.859811808084224
              ],
              [
                -73.91860422359034,
                40.8598354076015
              ],
              [
                -73.91861094928478,
                40.8598631103016
              ],
              [
                -73.9185879382561,
                40.85989387361192
              ],
              [
                -73.91855953955259,
                40.85990719830251
              ],
              [
                -73.91852845077686,
                40.859918459210604
              ],
              [
                -73.91850002964881,
                40.85995024476262
              ],
              [
                -73.91849190290432,
                40.859970760616775
              ],
              [
                -73.91846755096878,
                40.85999228321194
              ],
              [
                -73.91845133777615,
                40.85999124584131
              ],
              [
                -73.91843508111556,
                40.860017915606264
              ],
              [
                -73.91843100240942,
                40.860045612185054
              ],
              [
                -73.91842828384382,
                40.860056892617
              ],
              [
                -73.91841071714774,
                40.86004867742146
              ],
              [
                -73.9183931121764,
                40.860080469421916
              ],
              [
                -73.91839903543182,
                40.860096197508376
              ],
              [
                -73.91838738720058,
                40.86013529739786
              ],
              [
                -73.9183764802733,
                40.86017013802983
              ],
              [
                -73.91836074527978,
                40.860195809044086
              ],
              [
                -73.9184343925178,
                40.86022153339532
              ],
              [
                -73.91826264968334,
                40.86040482431119
              ],
              [
                -73.91805221056595,
                40.86062109843894
              ],
              [
                -73.91795061771704,
                40.86073198472936
              ],
              [
                -73.91792828566327,
                40.86074989768184
              ],
              [
                -73.91788387387606,
                40.860802221270625
              ],
              [
                -73.91792234646313,
                40.86081399922542
              ],
              [
                -73.91792190583864,
                40.86082542617216
              ],
              [
                -73.91790211673528,
                40.86083961320201
              ],
              [
                -73.91790472454431,
                40.86084987200048
              ],
              [
                -73.91788211332283,
                40.86087367385555
              ],
              [
                -73.91787707054957,
                40.860883836076674
              ],
              [
                -73.91786536368912,
                40.86088446784961
              ],
              [
                -73.91786115746794,
                40.86090065580484
              ],
              [
                -73.91784103780006,
                40.86093144631542
              ],
              [
                -73.91782136420316,
                40.860948901791126
              ],
              [
                -73.91782468139061,
                40.86098042213539
              ],
              [
                -73.91783553764998,
                40.86099693592737
              ],
              [
                -73.91782221945564,
                40.86102443659887
              ],
              [
                -73.91782702380144,
                40.86104278280522
              ],
              [
                -73.9178082344227,
                40.86106355285196
              ],
              [
                -73.91778534143992,
                40.861093504262975
              ],
              [
                -73.91772917654819,
                40.86114499922022
              ],
              [
                -73.9176882562444,
                40.861192821694004
              ],
              [
                -73.9176563633175,
                40.861221191772415
              ],
              [
                -73.91761405307706,
                40.861261125565434
              ],
              [
                -73.91759256564323,
                40.861276364714385
              ],
              [
                -73.91757176600544,
                40.86129211697167
              ],
              [
                -73.91756413342188,
                40.861306835801585
              ],
              [
                -73.91754749618079,
                40.86132313129051
              ],
              [
                -73.9175329272891,
                40.86133573604431
              ],
              [
                -73.91751560442543,
                40.86134992426702
              ],
              [
                -73.91750401182631,
                40.86136332486962
              ],
              [
                -73.91748061393716,
                40.86139863458939
              ],
              [
                -73.91746019820957,
                40.86142946988304
              ],
              [
                -73.91740994469338,
                40.86148637270622
              ],
              [
                -73.91740810708951,
                40.86153273761919
              ],
              [
                -73.91737841920231,
                40.86157097798817
              ],
              [
                -73.91734277917362,
                40.86161732807046
              ],
              [
                -73.91729467741676,
                40.86167941823068
              ],
              [
                -73.91727034392622,
                40.86169469967212
              ],
              [
                -73.91723056919916,
                40.86173654373122
              ],
              [
                -73.91715278759457,
                40.86181076114889
              ],
              [
                -73.91709135961965,
                40.86186030823942
              ],
              [
                -73.91706909419574,
                40.86187826898196
              ],
              [
                -73.91705129801854,
                40.861892622976505
              ],
              [
                -73.91701546163455,
                40.86190998158957
              ],
              [
                -73.91700967075877,
                40.86191278695556
              ],
              [
                -73.9169843735389,
                40.86192504083443
              ],
              [
                -73.9169581550374,
                40.86193325556352
              ],
              [
                -73.91692039865875,
                40.861931727543194
              ],
              [
                -73.91687889367468,
                40.86195358002902
              ],
              [
                -73.91685100780661,
                40.861963608813674
              ],
              [
                -73.91679534822482,
                40.8619776012672
              ],
              [
                -73.91678584948336,
                40.86198617595878
              ],
              [
                -73.91678113004234,
                40.861990437862325
              ],
              [
                -73.91676153411372,
                40.862008128077925
              ],
              [
                -73.91675431445412,
                40.862014645383695
              ],
              [
                -73.91674689045284,
                40.86202134865994
              ],
              [
                -73.91673206785232,
                40.862034730435695
              ],
              [
                -73.91672483714147,
                40.86204125761551
              ],
              [
                -73.91666173979016,
                40.86209363474733
              ],
              [
                -73.91664696334841,
                40.8621059005061
              ],
              [
                -73.91663823024982,
                40.862112890466776
              ],
              [
                -73.91661720458566,
                40.86212972006312
              ],
              [
                -73.91660191778598,
                40.86214195529302
              ],
              [
                -73.91659639029284,
                40.86214637960492
              ],
              [
                -73.91656892746097,
                40.8621578639007
              ],
              [
                -73.91653438522638,
                40.86217571564996
              ],
              [
                -73.91652512043825,
                40.86218050396303
              ],
              [
                -73.91649196679447,
                40.86219763869997
              ],
              [
                -73.91642605482255,
                40.86224898054916
              ],
              [
                -73.91640019183043,
                40.86226912657568
              ],
              [
                -73.91639175003213,
                40.86227570227626
              ],
              [
                -73.91627635012742,
                40.86236559143216
              ],
              [
                -73.91622522141265,
                40.86240541779581
              ],
              [
                -73.9162048494689,
                40.862421286040465
              ],
              [
                -73.91616935439103,
                40.862441260343836
              ],
              [
                -73.9161051792775,
                40.86248814480872
              ],
              [
                -73.91595260030206,
                40.862630756958566
              ],
              [
                -73.91595185469565,
                40.86263145582462
              ],
              [
                -73.91593363168721,
                40.862648487694265
              ],
              [
                -73.91586962339923,
                40.862704495961346
              ],
              [
                -73.91586450572528,
                40.862708975484104
              ],
              [
                -73.91585768842066,
                40.86271494020015
              ],
              [
                -73.91583883849174,
                40.862731434183324
              ],
              [
                -73.91581868929809,
                40.862751251544594
              ],
              [
                -73.91578014796721,
                40.86278915736011
              ],
              [
                -73.91571907457929,
                40.86285286403297
              ],
              [
                -73.91569323361948,
                40.86287981889348
              ],
              [
                -73.91568204906034,
                40.86289390578287
              ],
              [
                -73.91567199779999,
                40.86290242787803
              ],
              [
                -73.91561827252399,
                40.862878908221205
              ],
              [
                -73.91560361016737,
                40.86287975337306
              ],
              [
                -73.91559401542857,
                40.86288915679958
              ],
              [
                -73.91559964505785,
                40.86289215924693
              ],
              [
                -73.91558892372463,
                40.86290113918815
              ],
              [
                -73.91557991520047,
                40.86289599834803
              ],
              [
                -73.91556299694936,
                40.862909251121
              ],
              [
                -73.91557595266735,
                40.86291610425661
              ],
              [
                -73.91556578044927,
                40.86293107187449
              ],
              [
                -73.91552015328232,
                40.86291007496551
              ],
              [
                -73.91549965939643,
                40.86293812779098
              ],
              [
                -73.91544970533424,
                40.86300651049479
              ],
              [
                -73.91538673051149,
                40.86308438420073
              ],
              [
                -73.9154109612744,
                40.8630949190698
              ],
              [
                -73.91534506689356,
                40.86316454277737
              ],
              [
                -73.91541083335659,
                40.86319548646494
              ],
              [
                -73.91542295700886,
                40.86319418063079
              ],
              [
                -73.9154549655414,
                40.86320845729238
              ],
              [
                -73.91545061536698,
                40.863232984517246
              ],
              [
                -73.91545987478224,
                40.863257073924885
              ],
              [
                -73.91547306854308,
                40.86328033060862
              ],
              [
                -73.9154851646404,
                40.86330071832317
              ],
              [
                -73.91547682444238,
                40.863346107296714
              ],
              [
                -73.91546863429258,
                40.8633624976231
              ],
              [
                -73.91542702548192,
                40.86342917098901
              ],
              [
                -73.91541657363635,
                40.86344790631757
              ],
              [
                -73.91537404288228,
                40.86352873067105
              ],
              [
                -73.91536190015128,
                40.863547232567136
              ],
              [
                -73.91534455813895,
                40.863564205684476
              ],
              [
                -73.91530810246218,
                40.86359047369598
              ],
              [
                -73.91529676393407,
                40.86359864401922
              ],
              [
                -73.91525443919686,
                40.86361804378021
              ],
              [
                -73.9152181857278,
                40.86364108139574
              ],
              [
                -73.915200710796,
                40.863652186226076
              ],
              [
                -73.91517556429707,
                40.863680433750886
              ],
              [
                -73.91517539779056,
                40.863708386201246
              ],
              [
                -73.91517656557124,
                40.8637355194391
              ],
              [
                -73.91517693846923,
                40.863744186521835
              ],
              [
                -73.91517880018044,
                40.863787451908316
              ],
              [
                -73.91518119486989,
                40.86384311506673
              ],
              [
                -73.91518192994755,
                40.86386020413749
              ],
              [
                -73.9151850595422,
                40.86387946104222
              ],
              [
                -73.91518803106224,
                40.86389774401879
              ],
              [
                -73.91519574800094,
                40.86394522473583
              ],
              [
                -73.91521584698249,
                40.86397595269107
              ],
              [
                -73.91524067177384,
                40.863996068174735
              ],
              [
                -73.91526742492745,
                40.86401967088995
              ],
              [
                -73.91527885056384,
                40.864023505504335
              ],
              [
                -73.91528923936099,
                40.864028772408474
              ],
              [
                -73.91529828280513,
                40.86403531519251
              ],
              [
                -73.91530571233524,
                40.864042939557436
              ],
              [
                -73.91531130731791,
                40.86405141908448
              ],
              [
                -73.91532122117133,
                40.86406002676066
              ],
              [
                -73.91533281441582,
                40.86406732572735
              ],
              [
                -73.91534579103364,
                40.86407312961483
              ],
              [
                -73.91535981968394,
                40.86407729022841
              ],
              [
                -73.9153745421635,
                40.864079701332074
              ],
              [
                -73.91538444080764,
                40.86408030195847
              ],
              [
                -73.91540554211757,
                40.86408158273297
              ],
              [
                -73.9154398263038,
                40.86407835707759
              ],
              [
                -73.91549340827902,
                40.864066193949895
              ],
              [
                -73.9155387298444,
                40.864066818623165
              ],
              [
                -73.91558377690811,
                40.8640706510481
              ],
              [
                -73.91561569079903,
                40.864075356830625
              ],
              [
                -73.9156672828416,
                40.864086648919944
              ],
              [
                -73.91572596025567,
                40.86409562529628
              ],
              [
                -73.91574189992429,
                40.86409647019708
              ],
              [
                -73.91579009737725,
                40.86410300812719
              ],
              [
                -73.91584686790445,
                40.8641103696352
              ],
              [
                -73.91585434906487,
                40.86412257697171
              ],
              [
                -73.91587148065646,
                40.8641290910356
              ],
              [
                -73.9158982423084,
                40.86414619304341
              ],
              [
                -73.91592605810227,
                40.8641730558767
              ],
              [
                -73.9159313804981,
                40.86420396508695
              ],
              [
                -73.91593561439066,
                40.86424218421198
              ],
              [
                -73.91592270819694,
                40.864280401402205
              ],
              [
                -73.91589911645785,
                40.86430803808506
              ],
              [
                -73.91586052063045,
                40.86432590208421
              ],
              [
                -73.91581658566602,
                40.86433481225365
              ],
              [
                -73.91575872905088,
                40.86434209128957
              ],
              [
                -73.9157115910561,
                40.86434286717415
              ],
              [
                -73.9156644439211,
                40.864350153818044
              ],
              [
                -73.91563874300478,
                40.8643485145793
              ],
              [
                -73.91563236347605,
                40.86430947317944
              ],
              [
                -73.91545342726465,
                40.86433373708637
              ],
              [
                -73.91545872397363,
                40.86438416877071
              ],
              [
                -73.91543408398833,
                40.8643874009978
              ],
              [
                -73.9154286973113,
                40.86440772916068
              ],
              [
                -73.91543189718274,
                40.86441830355374
              ],
              [
                -73.91525511928769,
                40.86443362651925
              ],
              [
                -73.91516833371625,
                40.864439254176354
              ],
              [
                -73.91508583854264,
                40.86444814391995
              ],
              [
                -73.9150740494132,
                40.86444650490859
              ],
              [
                -73.9150440759955,
                40.864436721607696
              ],
              [
                -73.91441113392617,
                40.864173627375536
              ],
              [
                -73.91447397606235,
                40.86431128599491
              ],
              [
                -73.91454037626015,
                40.864412351623365
              ],
              [
                -73.91492863719179,
                40.86456956663371
              ],
              [
                -73.91492387927858,
                40.864580378919136
              ],
              [
                -73.91438922696749,
                40.86462417974643
              ],
              [
                -73.91431200712502,
                40.86462502269582
              ],
              [
                -73.91425257819593,
                40.86464572510954
              ],
              [
                -73.91416821692901,
                40.86466640191283
              ],
              [
                -73.91403866833014,
                40.86471050057713
              ],
              [
                -73.91393526952008,
                40.86473928454506
              ],
              [
                -73.9139186313323,
                40.86474558440355
              ],
              [
                -73.9138448505671,
                40.864848338592154
              ],
              [
                -73.91351997708159,
                40.86526747116958
              ],
              [
                -73.91312015100736,
                40.86578124232253
              ],
              [
                -73.91282859693649,
                40.86615711135718
              ],
              [
                -73.91254775130287,
                40.866514943004354
              ],
              [
                -73.91255219352705,
                40.866528624292194
              ],
              [
                -73.91273173919514,
                40.866605212711306
              ],
              [
                -73.9126175467601,
                40.86673955984179
              ],
              [
                -73.91245215298915,
                40.86694055877057
              ],
              [
                -73.91222962953469,
                40.86721546219407
              ],
              [
                -73.9120975526718,
                40.86737319112113
              ],
              [
                -73.91199296785679,
                40.86750583452553
              ],
              [
                -73.91184774646815,
                40.867672520472475
              ],
              [
                -73.91191159865868,
                40.867700205672485
              ],
              [
                -73.91189225260952,
                40.86772591741011
              ],
              [
                -73.91188960227053,
                40.86774775335029
              ],
              [
                -73.91187101089474,
                40.867759489718864
              ],
              [
                -73.91184571092025,
                40.86777625586335
              ],
              [
                -73.91182376049142,
                40.867789088776355
              ],
              [
                -73.91180556034618,
                40.86781047911382
              ],
              [
                -73.91179676558265,
                40.867831400035136
              ],
              [
                -73.91179420262449,
                40.86786802111718
              ],
              [
                -73.91180859603989,
                40.86788230457578
              ],
              [
                -73.91179668040857,
                40.86789561438441
              ],
              [
                -73.91178038282324,
                40.8678956019256
              ],
              [
                -73.91176907763841,
                40.867913187887574
              ],
              [
                -73.9117527627056,
                40.86793505776837
              ],
              [
                -73.9117552250194,
                40.86796598305694
              ],
              [
                -73.91173516231117,
                40.86797738507468
              ],
              [
                -73.91173640142544,
                40.867991172662656
              ],
              [
                -73.91172510380609,
                40.86800353710152
              ],
              [
                -73.91172571085134,
                40.8680201880116
              ],
              [
                -73.91172569759559,
                40.86803017371232
              ],
              [
                -73.9117338348191,
                40.868030179937016
              ],
              [
                -73.91173006189777,
                40.86804016393434
              ],
              [
                -73.9117319206429,
                40.86806062376698
              ],
              [
                -73.91172833168766,
                40.8680744889103
              ],
              [
                -73.91171163037997,
                40.86810028506055
              ],
              [
                -73.91166781354046,
                40.86813542482227
              ],
              [
                -73.91165319817412,
                40.86815430536876
              ],
              [
                -73.91162485562174,
                40.868174480740095
              ],
              [
                -73.91160166210436,
                40.868186197122384
              ],
              [
                -73.91158019244979,
                40.86820376706928
              ],
              [
                -73.91154065941286,
                40.86824542871927
              ],
              [
                -73.91153547484777,
                40.86826431698233
              ],
              [
                -73.91152432337303,
                40.868264966127796
              ],
              [
                -73.91151485871004,
                40.8682812392571
              ],
              [
                -73.91150538755127,
                40.86830338506651
              ],
              [
                -73.91149244825743,
                40.868354846413126
              ],
              [
                -73.91149242138631,
                40.868375035257806
              ],
              [
                -73.91148208144206,
                40.868408255175645
              ],
              [
                -73.91144165907038,
                40.86847662530261
              ],
              [
                -73.91141932680448,
                40.8684915930923
              ],
              [
                -73.9114098549045,
                40.86851308940594
              ],
              [
                -73.91139004972909,
                40.868563240128715
              ],
              [
                -73.91136427055301,
                40.86858340919443
              ],
              [
                -73.91135909150219,
                40.86860621531272
              ],
              [
                -73.91134705123666,
                40.86862379245795
              ],
              [
                -73.91135046077642,
                40.868646595289825
              ],
              [
                -73.91133500160275,
                40.86865765936163
              ],
              [
                -73.91132553554417,
                40.868675895507614
              ],
              [
                -73.91133149891803,
                40.86870521125037
              ],
              [
                -73.91132547908168,
                40.86871823689665
              ],
              [
                -73.91133402644324,
                40.86874039636799
              ],
              [
                -73.9113297074864,
                40.86875863646886
              ],
              [
                -73.91133742099721,
                40.86877427396471
              ],
              [
                -73.91133483134575,
                40.86878534909652
              ],
              [
                -73.9113281775882,
                40.86880243953576
              ],
              [
                -73.91130393727755,
                40.868824955426426
              ],
              [
                -73.91128294662256,
                40.868847676345666
              ],
              [
                -73.91125149289986,
                40.868861286183495
              ],
              [
                -73.91120057610415,
                40.868864659790056
              ],
              [
                -73.91116612307725,
                40.86887713149074
              ],
              [
                -73.91114965452033,
                40.868880531589426
              ],
              [
                -73.91114364443597,
                40.86889530382795
              ],
              [
                -73.91113013545254,
                40.86892030091883
              ],
              [
                -73.91114059177055,
                40.86893395120445
              ],
              [
                -73.91112177734426,
                40.868952397578404
              ],
              [
                -73.91113515331632,
                40.86897277565782
              ],
              [
                -73.91112043564152,
                40.869006199729476
              ],
              [
                -73.91109389484103,
                40.869019290133835
              ],
              [
                -73.91109483782506,
                40.869031655730765
              ],
              [
                -73.91107757570234,
                40.86904327606211
              ],
              [
                -73.91108138261407,
                40.869061459425666
              ],
              [
                -73.91107752901732,
                40.869078187477406
              ],
              [
                -73.91105736488242,
                40.86911162543575
              ],
              [
                -73.91103335740765,
                40.869155253026946
              ],
              [
                -73.91106400513745,
                40.869166911456745
              ],
              [
                -73.91109943435848,
                40.86917857305972
              ],
              [
                -73.91111955311597,
                40.86917858855775
              ],
              [
                -73.91112525892672,
                40.869213505408005
              ],
              [
                -73.91135794914872,
                40.869311873114164
              ],
              [
                -73.91136078970678,
                40.86933442355124
              ],
              [
                -73.91139623561234,
                40.86934244714698
              ],
              [
                -73.91140292777331,
                40.86934972847732
              ],
              [
                -73.91141921168854,
                40.86935264984735
              ],
              [
                -73.91142686201691,
                40.86936211004542
              ],
              [
                -73.91146613195859,
                40.8693752336082
              ],
              [
                -73.91147470849903,
                40.869410882204654
              ],
              [
                -73.91147752414803,
                40.86945234244303
              ],
              [
                -73.91146694984837,
                40.86947415352528
              ],
              [
                -73.911442974879,
                40.869492316562216
              ],
              [
                -73.91138545202726,
                40.86952063722174
              ],
              [
                -73.91135000667573,
                40.86952205974031
              ],
              [
                -73.91130786590509,
                40.86950821324815
              ],
              [
                -73.91125998364576,
                40.86948562818122
              ],
              [
                -73.91119295273501,
                40.869457211291966
              ],
              [
                -73.91113645047578,
                40.869440437276346
              ],
              [
                -73.91106176168185,
                40.8694076558008
              ],
              [
                -73.91104353600879,
                40.869420725127576
              ],
              [
                -73.91101288698583,
                40.869418523362796
              ],
              [
                -73.91099086451533,
                40.86940469176467
              ],
              [
                -73.91096019309789,
                40.869410486341906
              ],
              [
                -73.91092950706621,
                40.869435919113364
              ],
              [
                -73.91095152625854,
                40.86945120817018
              ],
              [
                -73.9109457240888,
                40.86948829494994
              ],
              [
                -73.91092363633766,
                40.86952318935023
              ],
              [
                -73.91093033139221,
                40.86953701733965
              ],
              [
                -73.91092169441231,
                40.869547186351866
              ],
              [
                -73.91092070672006,
                40.86956755453764
              ],
              [
                -73.91092931612711,
                40.86957846642498
              ],
              [
                -73.9109149158664,
                40.869595185825965
              ],
              [
                -73.91090242290286,
                40.869622812609265
              ],
              [
                -73.91088227999221,
                40.86964170687792
              ],
              [
                -73.91085928331742,
                40.86964751556358
              ],
              [
                -73.91088991976903,
                40.86965917352723
              ],
              [
                -73.91088893770015,
                40.86968390016123
              ],
              [
                -73.91087931262922,
                40.86971371717353
              ],
              [
                -73.91086874328361,
                40.86973116088065
              ],
              [
                -73.91088311464894,
                40.8697355388011
              ],
              [
                -73.91086678151244,
                40.8697704381356
              ],
              [
                -73.91086099421386,
                40.86979588140576
              ],
              [
                -73.91084755376188,
                40.86981333110147
              ],
              [
                -73.91084850117832,
                40.86982351689345
              ],
              [
                -73.91085711578782,
                40.86983007017777
              ],
              [
                -73.91085708459481,
                40.86985333858793
              ],
              [
                -73.9108407706945,
                40.8698736949313
              ],
              [
                -73.9108407482736,
                40.86989041671913
              ],
              [
                -73.91083402520907,
                40.86990569346968
              ],
              [
                -73.91081674002804,
                40.86993331586905
              ],
              [
                -73.9108234376424,
                40.86993767965712
              ],
              [
                -73.91082053654102,
                40.86996022499773
              ],
              [
                -73.91081282552356,
                40.86999586172145
              ],
              [
                -73.91081758861237,
                40.87001768409285
              ],
              [
                -73.91080989861791,
                40.87002859159875
              ],
              [
                -73.91080986642974,
                40.87005258956975
              ],
              [
                -73.91081268992717,
                40.870096959196715
              ],
              [
                -73.91078961731874,
                40.87015003373559
              ],
              [
                -73.91078863932837,
                40.870171851669056
              ],
              [
                -73.9107818820916,
                40.87020385002602
              ],
              [
                -73.91077897510436,
                40.87023076285048
              ],
              [
                -73.91075785297797,
                40.87026202120585
              ],
              [
                -73.91077411735417,
                40.870279485149254
              ],
              [
                -73.9107778959443,
                40.870318766837286
              ],
              [
                -73.91077305792284,
                40.870352945480015
              ],
              [
                -73.91076915471747,
                40.87040603533581
              ],
              [
                -73.91078255041361,
                40.87042204781848
              ],
              [
                -73.91076623112325,
                40.870445313013256
              ],
              [
                -73.91075950216381,
                40.87045621254697
              ],
              [
                -73.91077478077005,
                40.87050132069525
              ],
              [
                -73.910771854801,
                40.870533322179696
              ],
              [
                -73.91076321449745,
                40.870554404624095
              ],
              [
                -73.91076797207829,
                40.870571139989956
              ],
              [
                -73.91076412223113,
                40.87058494994798
              ],
              [
                -73.9107612369148,
                40.87059586166744
              ],
              [
                -73.9107372219756,
                40.870643847959045
              ],
              [
                -73.91075731220944,
                40.87066495362825
              ],
              [
                -73.91074959513284,
                40.870705676166885
              ],
              [
                -73.9107389951068,
                40.870745668085036
              ],
              [
                -73.91073130565934,
                40.8707653015184
              ],
              [
                -73.91072840254334,
                40.87078929726526
              ],
              [
                -73.91070443211592,
                40.870804560661924
              ],
              [
                -73.91070344390971,
                40.870824919459565
              ],
              [
                -73.91069192441397,
                40.87084309096915
              ],
              [
                -73.91069092913636,
                40.87086854714837
              ],
              [
                -73.91071292770269,
                40.87089983835427
              ],
              [
                -73.91072726861505,
                40.870918039087265
              ],
              [
                -73.91073681875089,
                40.87094422427526
              ],
              [
                -73.91072912439586,
                40.87096749563152
              ],
              [
                -73.91071567489408,
                40.870991483050425
              ],
              [
                -73.91070605869153,
                40.871015482982855
              ],
              [
                -73.91069358025322,
                40.87103219563057
              ],
              [
                -73.910672492682,
                40.87103800475036
              ],
              [
                -73.91066290216686,
                40.871051081702696
              ],
              [
                -73.91066096630357,
                40.87106126526174
              ],
              [
                -73.91062831291488,
                40.87112014990978
              ],
              [
                -73.91061103840086,
                40.871139046347885
              ],
              [
                -73.9105985494958,
                40.871164493788534
              ],
              [
                -73.91058704261461,
                40.8711732190149
              ],
              [
                -73.91056588782178,
                40.87122847529583
              ],
              [
                -73.9105582087164,
                40.87124083253429
              ],
              [
                -73.91056680858036,
                40.871258300446634
              ],
              [
                -73.9105504764678,
                40.871283014168284
              ],
              [
                -73.91052747017778,
                40.87129536119792
              ],
              [
                -73.91051786233251,
                40.87131208375771
              ],
              [
                -73.910499658267,
                40.8713171583159
              ],
              [
                -73.91047856276914,
                40.87132877558034
              ],
              [
                -73.91046990523188,
                40.871353496918864
              ],
              [
                -73.91045264466976,
                40.8713629380159
              ],
              [
                -73.91043824727869,
                40.87137747855179
              ],
              [
                -73.91045068458797,
                40.87139058263127
              ],
              [
                -73.91045545985003,
                40.87140367908884
              ],
              [
                -73.91045350986802,
                40.87143349263081
              ],
              [
                -73.91044771865839,
                40.87146112338387
              ],
              [
                -73.91043239004443,
                40.87146329950375
              ],
              [
                -73.91042949267286,
                40.87148292792279
              ],
              [
                -73.91044577257622,
                40.87148803744208
              ],
              [
                -73.91045053766994,
                40.87149967593997
              ],
              [
                -73.91045522870999,
                40.87157532001962
              ],
              [
                -73.91046185402595,
                40.8716415112769
              ],
              [
                -73.91047327677899,
                40.871695343065966
              ],
              [
                -73.91047920100607,
                40.87172972553631
              ],
              [
                -73.91048468158361,
                40.871761537525806
              ],
              [
                -73.91049514192561,
                40.87182555244867
              ],
              [
                -73.91051802379026,
                40.87190629880136
              ],
              [
                -73.910544749547,
                40.87198632794732
              ],
              [
                -73.91055333016271,
                40.87201833783434
              ],
              [
                -73.91053797212396,
                40.872033597817236
              ],
              [
                -73.91054082062271,
                40.87205832757491
              ],
              [
                -73.91056469338127,
                40.87211653582045
              ],
              [
                -73.91060004692918,
                40.87218492862952
              ],
              [
                -73.91064402620876,
                40.87226205286499
              ],
              [
                -73.9106487727767,
                40.87229624946581
              ],
              [
                -73.91066886250452,
                40.872318082527606
              ],
              [
                -73.91069663181787,
                40.87236327354684
              ],
              [
                -73.91076301659224,
                40.872452058077684
              ],
              [
                -73.91083208878284,
                40.87251773000828
              ],
              [
                -73.91091462693844,
                40.87257393920995
              ],
              [
                -73.91094435651671,
                40.8726082351562
              ],
              [
                -73.91096164062033,
                40.87261700189777
              ],
              [
                -73.91096737028887,
                40.8726439838647
              ],
              [
                -73.91098078938774,
                40.87266003201782
              ],
              [
                -73.91099227963159,
                40.872689938350334
              ],
              [
                -73.91101240352126,
                40.87272203768021
              ],
              [
                -73.91103927429148,
                40.87274830755421
              ],
              [
                -73.91103635995363,
                40.87277163688558
              ],
              [
                -73.91105552733826,
                40.87280081839454
              ],
              [
                -73.91108430768067,
                40.872826360167416
              ],
              [
                -73.91110445375335,
                40.872851175101246
              ],
              [
                -73.91109962664044,
                40.872867938744996
              ],
              [
                -73.91110344780436,
                40.87288470905089
              ],
              [
                -73.91115332406393,
                40.87294308042572
              ],
              [
                -73.91116674737339,
                40.872956220865504
              ],
              [
                -73.91116864796129,
                40.87297226012854
              ],
              [
                -73.91118017602021,
                40.8729744565152
              ],
              [
                -73.91117439655734,
                40.872984663421995
              ],
              [
                -73.91118400043388,
                40.87298831795382
              ],
              [
                -73.91119073224566,
                40.87298467599477
              ],
              [
                -73.911224294065,
                40.87302771883775
              ]
            ]
          ],
          [
            [
              [
                -73.92151894059094,
                40.85660117062316
              ],
              [
                -73.92169841904696,
                40.85667230185494
              ],
              [
                -73.92177019744629,
                40.85656789490128
              ],
              [
                -73.92176508454392,
                40.856565868590216
              ],
              [
                -73.92183549330599,
                40.856462141312434
              ],
              [
                -73.9218175009566,
                40.8564542243656
              ],
              [
                -73.92188760113714,
                40.85635513372336
              ],
              [
                -73.92187322153111,
                40.85634966205607
              ],
              [
                -73.92195201468988,
                40.85623699044721
              ],
              [
                -73.92207704565449,
                40.85605913536592
              ],
              [
                -73.92206335132519,
                40.85605391178446
              ],
              [
                -73.92215014297035,
                40.8559318311289
              ],
              [
                -73.92215474438092,
                40.85593217732165
              ],
              [
                -73.92215935791673,
                40.85593196633969
              ],
              [
                -73.92216386729518,
                40.85593120413541
              ],
              [
                -73.92216815974786,
                40.85592991040065
              ],
              [
                -73.92217212888958,
                40.85592811806574
              ],
              [
                -73.92217567339655,
                40.85592587095338
              ],
              [
                -73.92217870362472,
                40.85592322545872
              ],
              [
                -73.92218114624346,
                40.855920249043876
              ],
              [
                -73.92218293740308,
                40.85591701537637
              ],
              [
                -73.92218403221776,
                40.85591360667985
              ],
              [
                -73.92218440300834,
                40.85591010854004
              ],
              [
                -73.92218404128785,
                40.85590660873356
              ],
              [
                -73.9221829564415,
                40.855903194713854
              ],
              [
                -73.92218117594763,
                40.85589995310889
              ],
              [
                -73.92217874229384,
                40.85589696519633
              ],
              [
                -73.92219572192109,
                40.85587499055045
              ],
              [
                -73.92232785330081,
                40.85570400173132
              ],
              [
                -73.92232888081013,
                40.85570267178517
              ],
              [
                -73.92232971276387,
                40.85570159265731
              ],
              [
                -73.9223363626243,
                40.85569298743926
              ],
              [
                -73.92234217898556,
                40.855685461012676
              ],
              [
                -73.92234459387056,
                40.85568233590739
              ],
              [
                -73.92229611007241,
                40.855663835114505
              ],
              [
                -73.92228837057658,
                40.85567563890435
              ],
              [
                -73.92228832283236,
                40.855675713420055
              ],
              [
                -73.9222811377406,
                40.85568693163889
              ],
              [
                -73.92227219913848,
                40.855700892373285
              ],
              [
                -73.92225931710543,
                40.85572561566736
              ],
              [
                -73.92223557106671,
                40.85577118594847
              ],
              [
                -73.9222189508469,
                40.85579546579659
              ],
              [
                -73.92218505751369,
                40.855847415549796
              ],
              [
                -73.9221580751638,
                40.85588877209428
              ],
              [
                -73.92215338838638,
                40.855888600403496
              ],
              [
                -73.9221487254288,
                40.8558890018259
              ],
              [
                -73.9221442056687,
                40.85588996488286
              ],
              [
                -73.92213994408347,
                40.855891467204145
              ],
              [
                -73.92213604839007,
                40.85589346932677
              ],
              [
                -73.92213261947896,
                40.85589592022434
              ],
              [
                -73.92212974214682,
                40.85589875797036
              ],
              [
                -73.92212749215773,
                40.855901909575984
              ],
              [
                -73.92212592520553,
                40.85590529500261
              ],
              [
                -73.92212508375181,
                40.855908829512146
              ],
              [
                -73.92212498555783,
                40.8559124189683
              ],
              [
                -73.92212563579955,
                40.855915977100395
              ],
              [
                -73.92212701759904,
                40.855919408911056
              ],
              [
                -73.92212909531288,
                40.85592262927441
              ],
              [
                -73.92204171700534,
                40.85604505428097
              ],
              [
                -73.9220295673179,
                40.856040055047735
              ],
              [
                -73.921823250446,
                40.85632912586351
              ],
              [
                -73.92175965629767,
                40.85630468180855
              ],
              [
                -73.92168970374024,
                40.8564006434736
              ],
              [
                -73.9216718169156,
                40.85639401600261
              ],
              [
                -73.9215969651289,
                40.85649911033576
              ],
              [
                -73.92159078705224,
                40.856496661134166
              ],
              [
                -73.92151894059094,
                40.85660117062316
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000207405982869,
        "objectid": 128,
        "shape_leng": 0.0702090290681,
        "location_id": 128,
        "zone": "Inwood Hill Park",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.92359742020388,
                40.878898712992644
              ],
              [
                -73.92361150251905,
                40.878900860405935
              ],
              [
                -73.92359172576555,
                40.8789521591663
              ],
              [
                -73.92362973975199,
                40.878963943121555
              ],
              [
                -73.92365233486954,
                40.878911576877584
              ],
              [
                -73.92366219321842,
                40.87891158339879
              ],
              [
                -73.92367773890643,
                40.8788656261365
              ],
              [
                -73.92366647926977,
                40.87886349651294
              ],
              [
                -73.92367919311681,
                40.878826074498626
              ],
              [
                -73.92363695289211,
                40.87881535593209
              ],
              [
                -73.92362423150792,
                40.87885490128636
              ],
              [
                -73.9236171919141,
                40.87885275853596
              ],
              [
                -73.92359742020388,
                40.878898712992644
              ]
            ]
          ],
          [
            [
              [
                -73.9264055692112,
                40.87762147653742
              ],
              [
                -73.9264159145478,
                40.87761004770086
              ],
              [
                -73.92648185718433,
                40.87761820301796
              ],
              [
                -73.92653096278354,
                40.87758369178244
              ],
              [
                -73.92660824434302,
                40.87750763121183
              ],
              [
                -73.92689940502429,
                40.877216633932015
              ],
              [
                -73.92753082127467,
                40.87671053481295
              ],
              [
                -73.92796594643464,
                40.876617685376516
              ],
              [
                -73.92803638576686,
                40.876582443008516
              ],
              [
                -73.92814623603574,
                40.876522528943994
              ],
              [
                -73.9283297696842,
                40.87635346958729
              ],
              [
                -73.92859002632834,
                40.87609601029504
              ],
              [
                -73.9290134580973,
                40.875738660592724
              ],
              [
                -73.92917968255965,
                40.87555554896632
              ],
              [
                -73.9293191881939,
                40.875294438561
              ],
              [
                -73.92986215062544,
                40.87441844152513
              ],
              [
                -73.92997686743148,
                40.87433896233042
              ],
              [
                -73.93068381818156,
                40.87315687690047
              ],
              [
                -73.93084511434465,
                40.87288717030783
              ],
              [
                -73.93106254199596,
                40.87266680652867
              ],
              [
                -73.93122107559076,
                40.87233532285592
              ],
              [
                -73.93137655898947,
                40.87210862736062
              ],
              [
                -73.9314682113937,
                40.871797716206544
              ],
              [
                -73.93160149587301,
                40.87158387547001
              ],
              [
                -73.9317844012482,
                40.87133442292377
              ],
              [
                -73.93191377136804,
                40.87112949372268
              ],
              [
                -73.9320315692666,
                40.8707572025347
              ],
              [
                -73.93204740481396,
                40.870582903966586
              ],
              [
                -73.93212328527157,
                40.870378943105216
              ],
              [
                -73.93220600531866,
                40.87009644510452
              ],
              [
                -73.9321985510756,
                40.8698448708717
              ],
              [
                -73.93156536999994,
                40.86958215799991
              ],
              [
                -73.93141437399996,
                40.868961927999905
              ],
              [
                -73.93127671599983,
                40.86884359399991
              ],
              [
                -73.93117877500005,
                40.86876886699991
              ],
              [
                -73.93091942499993,
                40.868571000999935
              ],
              [
                -73.92997974899997,
                40.86779614899997
              ],
              [
                -73.92934104099999,
                40.86725723999994
              ],
              [
                -73.92872203199992,
                40.8667426629999
              ],
              [
                -73.92700250499986,
                40.86795125999991
              ],
              [
                -73.92623313399997,
                40.868506137999915
              ],
              [
                -73.92382143399995,
                40.86901838099993
              ],
              [
                -73.9223436069999,
                40.86945904099991
              ],
              [
                -73.92214424199993,
                40.86915018199993
              ],
              [
                -73.92187285799986,
                40.86927147499992
              ],
              [
                -73.92187269199987,
                40.86927154799988
              ],
              [
                -73.92187252499991,
                40.869271627999865
              ],
              [
                -73.921610721,
                40.8694056499999
              ],
              [
                -73.92161064999992,
                40.869405685999936
              ],
              [
                -73.92135975499993,
                40.869551853999894
              ],
              [
                -73.92112156199988,
                40.869709123999876
              ],
              [
                -73.92112131299997,
                40.869709285999875
              ],
              [
                -73.92112107599998,
                40.86970946599991
              ],
              [
                -73.92089662999997,
                40.86987701899989
              ],
              [
                -73.91925481799977,
                40.871007841999926
              ],
              [
                -73.91969887499998,
                40.87206477599995
              ],
              [
                -73.91945895199983,
                40.8722492809999
              ],
              [
                -73.91845930899993,
                40.873022052999936
              ],
              [
                -73.91717669599988,
                40.87249897299992
              ],
              [
                -73.9162778549999,
                40.87211564499995
              ],
              [
                -73.91505692599983,
                40.87160289599991
              ],
              [
                -73.91415003100002,
                40.871219850999864
              ],
              [
                -73.9140098549999,
                40.87116059399995
              ],
              [
                -73.91366488699992,
                40.871500584999886
              ],
              [
                -73.91334258899985,
                40.871756027999936
              ],
              [
                -73.91267976699986,
                40.87229176099989
              ],
              [
                -73.91206172199999,
                40.872806091999934
              ],
              [
                -73.91176119999992,
                40.873063789999925
              ],
              [
                -73.91154281116533,
                40.87325370616174
              ],
              [
                -73.91208146441699,
                40.87352884099815
              ],
              [
                -73.91229017490778,
                40.87363544449129
              ],
              [
                -73.91250176411434,
                40.87372832966795
              ],
              [
                -73.91260884231676,
                40.8737674436236
              ],
              [
                -73.91272877461884,
                40.8738065683737
              ],
              [
                -73.9128872888601,
                40.873835961915
              ],
              [
                -73.91297760373487,
                40.87384462648886
              ],
              [
                -73.91303726119472,
                40.87384257993784
              ],
              [
                -73.9130929525688,
                40.873854006909944
              ],
              [
                -73.91314007075697,
                40.87386867985808
              ],
              [
                -73.91319197797986,
                40.87389272024491
              ],
              [
                -73.9132471240974,
                40.873927309609364
              ],
              [
                -73.91329209830897,
                40.873941980883494
              ],
              [
                -73.91334350989777,
                40.87395015089037
              ],
              [
                -73.91349623201457,
                40.87399286752708
              ],
              [
                -73.91358433902973,
                40.874029105900824
              ],
              [
                -73.91390244516936,
                40.874096565043786
              ],
              [
                -73.91419784904603,
                40.87418935531247
              ],
              [
                -73.91422167905834,
                40.87419960364557
              ],
              [
                -73.91463283278519,
                40.874326621096806
              ],
              [
                -73.91498198606966,
                40.87442464213988
              ],
              [
                -73.91506980141546,
                40.8744491021406
              ],
              [
                -73.9152240214605,
                40.874486621868144
              ],
              [
                -73.91540183736201,
                40.874503016307244
              ],
              [
                -73.91557751037355,
                40.874519408904725
              ],
              [
                -73.91568463084475,
                40.87452761810509
              ],
              [
                -73.91576604987803,
                40.87452767756275
              ],
              [
                -73.91589033125027,
                40.874519637324326
              ],
              [
                -73.91601675124143,
                40.87451485046096
              ],
              [
                -73.91609852720278,
                40.87450015889178
              ],
              [
                -73.91621216322862,
                40.874503583365296
              ],
              [
                -73.91634348667561,
                40.874523849889655
              ],
              [
                -73.91644828715275,
                40.87449544308543
              ],
              [
                -73.91658048205615,
                40.87443560224685
              ],
              [
                -73.9166556014652,
                40.87438699433423
              ],
              [
                -73.9167134990195,
                40.87435498785027
              ],
              [
                -73.91685267654526,
                40.87433728573168
              ],
              [
                -73.91698090690281,
                40.87432669780203
              ],
              [
                -73.9170372269262,
                40.874303002166734
              ],
              [
                -73.91711624585014,
                40.87425735872737
              ],
              [
                -73.91718819114,
                40.87424198521311
              ],
              [
                -73.91726640898547,
                40.874209993198725
              ],
              [
                -73.91739625419048,
                40.874160243657684
              ],
              [
                -73.91742914539073,
                40.87411754000315
              ],
              [
                -73.91749486066541,
                40.87408434930755
              ],
              [
                -73.91755115955162,
                40.87406777530256
              ],
              [
                -73.9175903009546,
                40.87403575514311
              ],
              [
                -73.9176434714112,
                40.87402630142592
              ],
              [
                -73.91770785270614,
                40.874016027949004
              ],
              [
                -73.91774021567048,
                40.87400673105857
              ],
              [
                -73.91776812456737,
                40.873991514582436
              ],
              [
                -73.91778097440154,
                40.87398008829645
              ],
              [
                -73.91777764915211,
                40.87396314669118
              ],
              [
                -73.91779327441755,
                40.87395300141786
              ],
              [
                -73.91780777752835,
                40.87395724404439
              ],
              [
                -73.91781448198516,
                40.87394623603753
              ],
              [
                -73.91784685200199,
                40.87393101283528
              ],
              [
                -73.91787144917501,
                40.87390499528865
              ],
              [
                -73.91788506630628,
                40.8739602501475
              ],
              [
                -73.91789032098379,
                40.87396247775168
              ],
              [
                -73.91770931109484,
                40.87423249407926
              ],
              [
                -73.91769066058586,
                40.874225222183256
              ],
              [
                -73.91749921000535,
                40.87452007854011
              ],
              [
                -73.9175772988473,
                40.87454867854727
              ],
              [
                -73.91776990325637,
                40.8742543038425
              ],
              [
                -73.91775468095318,
                40.87424853456615
              ],
              [
                -73.91792902917979,
                40.87397889320507
              ],
              [
                -73.91794108366345,
                40.8739831478103
              ],
              [
                -73.91803715672657,
                40.87389725829062
              ],
              [
                -73.9180516579742,
                40.87386254546655
              ],
              [
                -73.91805893427565,
                40.873849867984006
              ],
              [
                -73.91807901550598,
                40.87385157522655
              ],
              [
                -73.918106915921,
                40.873843129766485
              ],
              [
                -73.91813594089376,
                40.87382705878906
              ],
              [
                -73.91815939815253,
                40.87380505857412
              ],
              [
                -73.91817615884986,
                40.8737771196134
              ],
              [
                -73.91818288978932,
                40.87375510703017
              ],
              [
                -73.91821079469307,
                40.87374243042434
              ],
              [
                -73.91824427174542,
                40.873733133679266
              ],
              [
                -73.91826659956283,
                40.8737221454243
              ],
              [
                -73.91828781665393,
                40.873708608859026
              ],
              [
                -73.91830232945689,
                40.873695066371134
              ],
              [
                -73.91833357491785,
                40.87368747088964
              ],
              [
                -73.91835591726749,
                40.87366462284993
              ],
              [
                -73.91838048803518,
                40.873642613995955
              ],
              [
                -73.91839947532085,
                40.873628237548765
              ],
              [
                -73.91842852157109,
                40.8735952279453
              ],
              [
                -73.918414036594,
                40.87358505191864
              ],
              [
                -73.91843599403886,
                40.873567021044586
              ],
              [
                -73.91845086074116,
                40.873574920368135
              ],
              [
                -73.9184731967847,
                40.873557151592706
              ],
              [
                -73.91850556824869,
                40.87353938939763
              ],
              [
                -73.918551335354,
                40.87352079132854
              ],
              [
                -73.91860268946238,
                40.87349288492382
              ],
              [
                -73.9186272845728,
                40.873451407369295
              ],
              [
                -73.9186284370542,
                40.873420918526044
              ],
              [
                -73.91864021482168,
                40.873374776470676
              ],
              [
                -73.91862350877972,
                40.873347659666095
              ],
              [
                -73.91860122675237,
                40.87332139429759
              ],
              [
                -73.91857669301552,
                40.87331205769525
              ],
              [
                -73.9185622051726,
                40.87329596461076
              ],
              [
                -73.91854552710514,
                40.873256142715604
              ],
              [
                -73.91853998447118,
                40.87322904265611
              ],
              [
                -73.91853666560324,
                40.87319685767055
              ],
              [
                -73.91855365026461,
                40.873180588115396
              ],
              [
                -73.91862104668414,
                40.8731972942998
              ],
              [
                -73.91893896566567,
                40.873302966470234
              ],
              [
                -73.91921896474608,
                40.873617972027304
              ],
              [
                -73.91926375452918,
                40.87361259137962
              ],
              [
                -73.91928102175844,
                40.87363655754588
              ],
              [
                -73.91927030351344,
                40.87366088122923
              ],
              [
                -73.91926009034685,
                40.873689455653626
              ],
              [
                -73.91925904468509,
                40.87372035852512
              ],
              [
                -73.91926195569941,
                40.87383392226377
              ],
              [
                -73.91925978641387,
                40.87393975445136
              ],
              [
                -73.91924749339371,
                40.87401082192225
              ],
              [
                -73.91923368469071,
                40.87406837125046
              ],
              [
                -73.91922652147316,
                40.8740969478044
              ],
              [
                -73.9192111816839,
                40.87415564878188
              ],
              [
                -73.91916221498532,
                40.874249859633835
              ],
              [
                -73.91911737112589,
                40.87430931388154
              ],
              [
                -73.91908625963018,
                40.87436298815266
              ],
              [
                -73.91903833950522,
                40.87443479585484
              ],
              [
                -73.91901591932367,
                40.87446336168924
              ],
              [
                -73.91899146424903,
                40.874486523480684
              ],
              [
                -73.91894154174601,
                40.87452665921787
              ],
              [
                -73.91889469405842,
                40.87455675644839
              ],
              [
                -73.91887126163864,
                40.874568329706655
              ],
              [
                -73.91885699002506,
                40.87458762583616
              ],
              [
                -73.91884881993983,
                40.87461388732878
              ],
              [
                -73.9188589780202,
                40.87463089568875
              ],
              [
                -73.918857942435,
                40.87464324876078
              ],
              [
                -73.91890319914951,
                40.87467379886838
              ],
              [
                -73.91894286506647,
                40.87469700569053
              ],
              [
                -73.91896727626954,
                40.87470937663401
              ],
              [
                -73.91897439539596,
                40.87470861588605
              ],
              [
                -73.91924742219437,
                40.874831044944585
              ],
              [
                -73.91972463724571,
                40.8749961351941
              ],
              [
                -73.91981895201579,
                40.875034348711836
              ],
              [
                -73.92009707040329,
                40.875147034489174
              ],
              [
                -73.92018482308941,
                40.875189096689034
              ],
              [
                -73.92023398573666,
                40.875202823991025
              ],
              [
                -73.92025056805598,
                40.87520816837106
              ],
              [
                -73.9203024613251,
                40.8752159305375
              ],
              [
                -73.92034213453782,
                40.87523372489072
              ],
              [
                -73.92039300443471,
                40.87525384948979
              ],
              [
                -73.92047030646286,
                40.875292524993
              ],
              [
                -73.92053640420096,
                40.87533660421736
              ],
              [
                -73.92057606461886,
                40.875365222461205
              ],
              [
                -73.92067983077874,
                40.875397738296705
              ],
              [
                -73.9207327312122,
                40.87541631556056
              ],
              [
                -73.92076221925711,
                40.87543951476238
              ],
              [
                -73.92079579222155,
                40.875450352408016
              ],
              [
                -73.92084158803193,
                40.87545733501876
              ],
              [
                -73.92087559199673,
                40.87546395002319
              ],
              [
                -73.92089428451298,
                40.875457831169996
              ],
              [
                -73.92090325515422,
                40.87546616638258
              ],
              [
                -73.92091322183165,
                40.875464660315174
              ],
              [
                -73.92091823046628,
                40.875453308385055
              ],
              [
                -73.92092920057632,
                40.875446499233476
              ],
              [
                -73.92093020805649,
                40.87543742320585
              ],
              [
                -73.920955153647,
                40.875429110728255
              ],
              [
                -73.92096913044006,
                40.875418521973565
              ],
              [
                -73.92097812109449,
                40.87540036481026
              ],
              [
                -73.92099010857669,
                40.87538523549415
              ],
              [
                -73.92101105768876,
                40.875376920770776
              ],
              [
                -73.92102403531776,
                40.875367844223476
              ],
              [
                -73.92103602209794,
                40.875343628802725
              ],
              [
                -73.92105700109649,
                40.8753194202021
              ],
              [
                -73.92106199576551,
                40.87530883400249
              ],
              [
                -73.9210799642233,
                40.87529294352374
              ],
              [
                -73.92110491037751,
                40.87528539676059
              ],
              [
                -73.92110691408502,
                40.87527631152839
              ],
              [
                -73.9211129036416,
                40.87526723019338
              ],
              [
                -73.92110394084428,
                40.87525284290744
              ],
              [
                -73.9211109446201,
                40.87523846720639
              ],
              [
                -73.92112690618701,
                40.87523469595512
              ],
              [
                -73.9211319044239,
                40.87522183111014
              ],
              [
                -73.92113391038936,
                40.875209721255395
              ],
              [
                -73.92114588498156,
                40.8752067048147
              ],
              [
                -73.92114389543798,
                40.87520366727274
              ],
              [
                -73.92115986015386,
                40.87519611486263
              ],
              [
                -73.92115787665247,
                40.87518853980348
              ],
              [
                -73.9211698417931,
                40.87518325291774
              ],
              [
                -73.92116885342608,
                40.87517568021374
              ],
              [
                -73.92117284868004,
                40.87516811912251
              ],
              [
                -73.92116987769633,
                40.875152969684855
              ],
              [
                -73.9211698884575,
                40.87514389297377
              ],
              [
                -73.92118186508976,
                40.87513784085071
              ],
              [
                -73.92117491342296,
                40.875117405193684
              ],
              [
                -73.92117492688537,
                40.87510604933731
              ],
              [
                -73.92116695696885,
                40.875094688530055
              ],
              [
                -73.92115600330543,
                40.8750772747615
              ],
              [
                -73.92114009384979,
                40.87504849286096
              ],
              [
                -73.92111420160488,
                40.87501366259345
              ],
              [
                -73.92108133530783,
                40.874978061804434
              ],
              [
                -73.92105493692358,
                40.874960259196556
              ],
              [
                -73.92103202290917,
                40.8749345076661
              ],
              [
                -73.92101610565861,
                40.87490270061613
              ],
              [
                -73.92100015808852,
                40.87489512470691
              ],
              [
                -73.92098721924138,
                40.874881483515736
              ],
              [
                -73.92098723992623,
                40.87486407674106
              ],
              [
                -73.92097230770959,
                40.8748413557891
              ],
              [
                -73.9209473691218,
                40.87484285160678
              ],
              [
                -73.9209384181303,
                40.87482770845203
              ],
              [
                -73.92092746229659,
                40.87481332045575
              ],
              [
                -73.92092349157225,
                40.874799693610235
              ],
              [
                -73.9209155100288,
                40.87479817524274
              ],
              [
                -73.92091453517739,
                40.874780010750214
              ],
              [
                -73.92092254079128,
                40.87476184470561
              ],
              [
                -73.92091956358946,
                40.87475124433698
              ],
              [
                -73.9209056154877,
                40.87473912355144
              ],
              [
                -73.92089167392983,
                40.87472095067331
              ],
              [
                -73.92088671102694,
                40.87470429684725
              ],
              [
                -73.92088273221307,
                40.87468839252237
              ],
              [
                -73.92086879559815,
                40.87467627994869
              ],
              [
                -73.92085235584491,
                40.874663778679036
              ],
              [
                -73.92084337428213,
                40.87466452838157
              ],
              [
                -73.92083940266566,
                40.87465241443042
              ],
              [
                -73.92084240240087,
                40.87464333105765
              ],
              [
                -73.92082945415412,
                40.87462894101476
              ],
              [
                -73.92081649581893,
                40.87462135908939
              ],
              [
                -73.92081650482736,
                40.87461379460437
              ],
              [
                -73.92080954431921,
                40.874600922738345
              ],
              [
                -73.92079957959791,
                40.87459107343412
              ],
              [
                -73.92080359467634,
                40.8745766945262
              ],
              [
                -73.92078865109977,
                40.87456306014473
              ],
              [
                -73.92079265694505,
                40.87454716950531
              ],
              [
                -73.9207816924167,
                40.87453958877671
              ],
              [
                -73.92078070500605,
                40.8745312585311
              ],
              [
                -73.92076677770551,
                40.87451081688577
              ],
              [
                -73.92075483429562,
                40.874489612027126
              ],
              [
                -73.92075785117514,
                40.874466146501845
              ],
              [
                -73.92074290853479,
                40.87445175558191
              ],
              [
                -73.92072498444587,
                40.874430554820826
              ],
              [
                -73.92071604571976,
                40.87440632505257
              ],
              [
                -73.92070010598974,
                40.874391933609424
              ],
              [
                -73.92071107412367,
                40.874387393739674
              ],
              [
                -73.92069665918827,
                40.87434840144939
              ],
              [
                -73.9206857269419,
                40.87432341511571
              ],
              [
                -73.92066980301328,
                40.87429691178115
              ],
              [
                -73.92066084429216,
                40.874278743471514
              ],
              [
                -73.92080751306929,
                40.87421526009999
              ],
              [
                -73.92080255630347,
                40.87419405886663
              ],
              [
                -73.92078761461624,
                40.87417891108441
              ],
              [
                -73.92076966714161,
                40.87417738586543
              ],
              [
                -73.92062699165683,
                40.87423481112878
              ],
              [
                -73.92060608624132,
                40.874206791627785
              ],
              [
                -73.92057619910041,
                40.874180279175135
              ],
              [
                -73.9205433335032,
                40.874144678227346
              ],
              [
                -73.92049844862419,
                40.874159793050396
              ],
              [
                -73.9204720447048,
                40.87413668703333
              ],
              [
                -73.92043917329222,
                40.87410637993942
              ],
              [
                -73.92040330261301,
                40.87408365502706
              ],
              [
                -73.92034849897662,
                40.8740503081534
              ],
              [
                -73.9202877068482,
                40.874015444218784
              ],
              [
                -73.92021794909964,
                40.87397754775454
              ],
              [
                -73.9202369138315,
                40.87396166747345
              ],
              [
                -73.92020902200159,
                40.87394272900417
              ],
              [
                -73.92019105255761,
                40.873960122867445
              ],
              [
                -73.92015018861089,
                40.87393662750303
              ],
              [
                -73.92016516941842,
                40.87391847571556
              ],
              [
                -73.92013926594399,
                40.873904076629906
              ],
              [
                -73.92012428446928,
                40.87392222958632
              ],
              [
                -73.9199917496795,
                40.87384417366738
              ],
              [
                -73.91997828639676,
                40.87384302919795
              ],
              [
                -73.91996733096013,
                40.87382788507755
              ],
              [
                -73.91997433025301,
                40.87381804746398
              ],
              [
                -73.91996637212853,
                40.87379608824889
              ],
              [
                -73.91995942777578,
                40.873771103436646
              ],
              [
                -73.91995446542514,
                40.873753693704764
              ],
              [
                -73.91995149021679,
                40.873742336274965
              ],
              [
                -73.9199465131935,
                40.873727951659475
              ],
              [
                -73.91994054414333,
                40.87371961794944
              ],
              [
                -73.91994555163063,
                40.873698432970734
              ],
              [
                -73.91994956492047,
                40.87367648256047
              ],
              [
                -73.91993362050847,
                40.873666629031426
              ],
              [
                -73.91991668692258,
                40.873651479752745
              ],
              [
                -73.91989077111431,
                40.8736461667043
              ],
              [
                -73.9198628465619,
                40.87364614731621
              ],
              [
                -73.91982295820223,
                40.8736499012699
              ],
              [
                -73.91978308103732,
                40.873643823136156
              ],
              [
                -73.91978312944534,
                40.873603697435584
              ],
              [
                -73.91981902154345,
                40.873608269785905
              ],
              [
                -73.91986389068701,
                40.8736060310184
              ],
              [
                -73.91989579909402,
                40.87360907896863
              ],
              [
                -73.91990280475248,
                40.87359394680504
              ],
              [
                -73.91991776524846,
                40.87359320132476
              ],
              [
                -73.91991976382093,
                40.873588655304864
              ],
              [
                -73.9199357337655,
                40.873576554151356
              ],
              [
                -73.91994074372579,
                40.87355385728065
              ],
              [
                -73.91994374992086,
                40.87353946949144
              ],
              [
                -73.9199577235154,
                40.87353115832614
              ],
              [
                -73.91995376124532,
                40.87351147064185
              ],
              [
                -73.91995279557032,
                40.87348573411187
              ],
              [
                -73.91995979041688,
                40.873469845068826
              ],
              [
                -73.91994884190137,
                40.873448640142286
              ],
              [
                -73.91995189299274,
                40.87340776937151
              ],
              [
                -73.9199658574108,
                40.87339642369029
              ],
              [
                -73.9199758550652,
                40.87337902433251
              ],
              [
                -73.91998535385889,
                40.87335215975609
              ],
              [
                -73.92001431202269,
                40.87332190544188
              ],
              [
                -73.92004627217649,
                40.873281811066754
              ],
              [
                -73.92006027075733,
                40.8732522938619
              ],
              [
                -73.92008222781284,
                40.873233389377276
              ],
              [
                -73.92010118828746,
                40.87322053491069
              ],
              [
                -73.92012314974757,
                40.87320768235057
              ],
              [
                -73.92013213760733,
                40.87319179517843
              ],
              [
                -73.92014910357753,
                40.87318120741504
              ],
              [
                -73.92016706828103,
                40.87316759571542
              ],
              [
                -73.92019300382896,
                40.87316533500905
              ],
              [
                -73.92022292951731,
                40.873149462307765
              ],
              [
                -73.92025736613591,
                40.873121103218885
              ],
              [
                -73.92027634052175,
                40.873105978798655
              ],
              [
                -73.92029230572703,
                40.873098416622625
              ],
              [
                -73.92030226117289,
                40.87310599670292
              ],
              [
                -73.92032920968069,
                40.873089364882055
              ],
              [
                -73.92035813253032,
                40.87307878651478
              ],
              [
                -73.92038905696707,
                40.87307123464822
              ],
              [
                -73.92042797810988,
                40.8730523417989
              ],
              [
                -73.92046088686524,
                40.87304176665674
              ],
              [
                -73.9204938100222,
                40.87302892057548
              ],
              [
                -73.92052973842686,
                40.87301305243241
              ],
              [
                -73.9205606635804,
                40.87299414465016
              ],
              [
                -73.92057463023556,
                40.872990373776126
              ],
              [
                -73.92060953713961,
                40.8729858585973
              ],
              [
                -73.92064442871197,
                40.872993446419365
              ],
              [
                -73.92066636738936,
                40.87300027767256
              ],
              [
                -73.92069129315439,
                40.87299878190671
              ],
              [
                -73.92069726314591,
                40.8730063510054
              ],
              [
                -73.92071319024366,
                40.87301051299764
              ],
              [
                -73.92075652672875,
                40.873009344976154
              ],
              [
                -73.9207740068131,
                40.873014463241574
              ],
              [
                -73.92079015106097,
                40.873015491522644
              ],
              [
                -73.92081299828271,
                40.87302571905911
              ],
              [
                -73.92084932060317,
                40.87302778708621
              ],
              [
                -73.92089505217784,
                40.8730278184436
              ],
              [
                -73.92092598584571,
                40.873031918824836
              ],
              [
                -73.92097844127738,
                40.873033999037375
              ],
              [
                -73.92100265196892,
                40.87303606039706
              ],
              [
                -73.92104702078318,
                40.87304425733171
              ],
              [
                -73.92108198156424,
                40.87305347540085
              ],
              [
                -73.921169296781,
                40.87306939228805
              ],
              [
                -73.92125275808976,
                40.873096473550774
              ],
              [
                -73.92132916660043,
                40.873151899122355
              ],
              [
                -73.92141123301121,
                40.8732024497342
              ],
              [
                -73.92149841910096,
                40.873247793849444
              ],
              [
                -73.9215901530671,
                40.8732876340777
              ],
              [
                -73.9216183807434,
                40.873302970870434
              ],
              [
                -73.9216701428844,
                40.873330056596004
              ],
              [
                -73.92172791772019,
                40.87337809225714
              ],
              [
                -73.92178168307726,
                40.873416921132666
              ],
              [
                -73.92183005389812,
                40.87345269423069
              ],
              [
                -73.92186904474784,
                40.87347517824164
              ],
              [
                -73.92189592800635,
                40.87348439059407
              ],
              [
                -73.92191875466726,
                40.8735221817653
              ],
              [
                -73.92194296317746,
                40.87352627732295
              ],
              [
                -73.92196579224448,
                40.8735528389296
              ],
              [
                -73.9219859680907,
                40.87355592443662
              ],
              [
                -73.9219926791775,
                40.8735681752257
              ],
              [
                -73.92203570759096,
                40.87358760930835
              ],
              [
                -73.92206527090742,
                40.873608043774446
              ],
              [
                -73.92208944494341,
                40.87363971185712
              ],
              [
                -73.92211364251733,
                40.87365300102317
              ],
              [
                -73.92216067057092,
                40.87369081785797
              ],
              [
                -73.92218619484476,
                40.87372554881245
              ],
              [
                -73.92220362984004,
                40.87376945924828
              ],
              [
                -73.9222184093859,
                40.87378172534674
              ],
              [
                -73.92224324758364,
                40.87382513568445
              ],
              [
                -73.92226204657453,
                40.87384863303612
              ],
              [
                -73.92226069249845,
                40.87385986117744
              ],
              [
                -73.92227950313357,
                40.87387416555273
              ],
              [
                -73.92232781274556,
                40.87397324191267
              ],
              [
                -73.92234123070199,
                40.874002859089806
              ],
              [
                -73.92237683031455,
                40.87404525123125
              ],
              [
                -73.92239695345737,
                40.8740830488613
              ],
              [
                -73.92241305509722,
                40.874120835910325
              ],
              [
                -73.92244396077805,
                40.874149446459604
              ],
              [
                -73.92245467613134,
                40.87418723004988
              ],
              [
                -73.92248291316665,
                40.87419541609168
              ],
              [
                -73.92250844284314,
                40.87421483823994
              ],
              [
                -73.92253531894417,
                40.87424037754329
              ],
              [
                -73.92253663536354,
                40.87426181832856
              ],
              [
                -73.92253526184382,
                40.87428938947979
              ],
              [
                -73.9225674567449,
                40.8743629189126
              ],
              [
                -73.92258487775169,
                40.87441806653692
              ],
              [
                -73.9226076645474,
                40.87449261572223
              ],
              [
                -73.92262241329665,
                40.87453041004046
              ],
              [
                -73.92264185921482,
                40.87457994063309
              ],
              [
                -73.92263913448598,
                40.874606485471205
              ],
              [
                -73.92265258491385,
                40.87460955596584
              ],
              [
                -73.92263911310297,
                40.87462486404581
              ],
              [
                -73.92263505079865,
                40.87465039011858
              ],
              [
                -73.92265246019703,
                40.87471676794625
              ],
              [
                -73.92264706229452,
                40.87473616162277
              ],
              [
                -73.92265104634215,
                40.87477904576762
              ],
              [
                -73.92265370811916,
                40.874806619622134
              ],
              [
                -73.9226563300901,
                40.8748566527251
              ],
              [
                -73.92265628020954,
                40.87489953416529
              ],
              [
                -73.92266297766196,
                40.8749250582346
              ],
              [
                -73.92265621964499,
                40.8749516003686
              ],
              [
                -73.92265347533191,
                40.87500673563257
              ],
              [
                -73.92265343019594,
                40.875045536722624
              ],
              [
                -73.92265672015898,
                40.87510322472124
              ],
              [
                -73.92265534021038,
                40.875136919827476
              ],
              [
                -73.92264992358426,
                40.87516346285897
              ],
              [
                -73.92265124753175,
                40.87518796562526
              ],
              [
                -73.92264179478191,
                40.875216550231265
              ],
              [
                -73.9226094679212,
                40.87525634854943
              ],
              [
                -73.92259326010884,
                40.875309429326634
              ],
              [
                -73.9225838173596,
                40.875328819782375
              ],
              [
                -73.92257168317542,
                40.87535842849239
              ],
              [
                -73.9225541393091,
                40.87540742215168
              ],
              [
                -73.92254872142617,
                40.875434990583074
              ],
              [
                -73.92252715989451,
                40.875470708211054
              ],
              [
                -73.92249078070564,
                40.875526837409545
              ],
              [
                -73.92247527129064,
                40.875560010903335
              ],
              [
                -73.92247388452017,
                40.87558962682106
              ],
              [
                -73.92244696222403,
                40.875613085202474
              ],
              [
                -73.92243077867309,
                40.87564575148044
              ],
              [
                -73.92242265440576,
                40.875694751448385
              ],
              [
                -73.92242396062541,
                40.8757243604639
              ],
              [
                -73.92242259483537,
                40.87574580815282
              ],
              [
                -73.92242659071222,
                40.875778480956704
              ],
              [
                -73.92242252812991,
                40.87580298010221
              ],
              [
                -73.92241443957167,
                40.8758111424034
              ],
              [
                -73.92241845190593,
                40.87583973536945
              ],
              [
                -73.92241369514767,
                40.875879038367216
              ],
              [
                -73.92241496389698,
                40.875941326184744
              ],
              [
                -73.9224269873826,
                40.87601791226069
              ],
              [
                -73.92244171582213,
                40.87607407695334
              ],
              [
                -73.92243750433738,
                40.87622621072903
              ],
              [
                -73.92241108552581,
                40.87638802009181
              ],
              [
                -73.92240693330263,
                40.87647889402186
              ],
              [
                -73.9224229921152,
                40.87655445689456
              ],
              [
                -73.92241886633713,
                40.87663307471048
              ],
              [
                -73.92240533581122,
                40.876699431748015
              ],
              [
                -73.922400623303,
                40.87673234126452
              ],
              [
                -73.92239907976202,
                40.876743057157164
              ],
              [
                -73.92240887383943,
                40.87674831222904
              ],
              [
                -73.92241576943685,
                40.876765822988446
              ],
              [
                -73.92243362687847,
                40.876773263786816
              ],
              [
                -73.92243706188535,
                40.87678901507315
              ],
              [
                -73.9224837033913,
                40.87682404894122
              ],
              [
                -73.92249578378302,
                40.8768419865078
              ],
              [
                -73.92253610173903,
                40.87686826271068
              ],
              [
                -73.92256839578593,
                40.87685735168328
              ],
              [
                -73.92258912035129,
                40.87686829828595
              ],
              [
                -73.92257758831417,
                40.87687923028559
              ],
              [
                -73.92257007003337,
                40.876895848184894
              ],
              [
                -73.92257641535174,
                40.876897158951586
              ],
              [
                -73.92257120834556,
                40.87690634910445
              ],
              [
                -73.92257466338114,
                40.87691422163937
              ],
              [
                -73.92258387170894,
                40.87692253928839
              ],
              [
                -73.92259771145176,
                40.876917298409786
              ],
              [
                -73.9226075060566,
                40.876920808883476
              ],
              [
                -73.92260748875238,
                40.87693567517189
              ],
              [
                -73.922631137663,
                40.87692257098524
              ],
              [
                -73.92265706349428,
                40.87692871165308
              ],
              [
                -73.92269767779175,
                40.876944704783746
              ],
              [
                -73.92272934926771,
                40.87696966040843
              ],
              [
                -73.92274949451486,
                40.87698936702666
              ],
              [
                -73.92276677289159,
                40.87699550255101
              ],
              [
                -73.92278406427518,
                40.87700032286081
              ],
              [
                -73.9227771360302,
                40.87701081720804
              ],
              [
                -73.92276213709974,
                40.87702349534052
              ],
              [
                -73.92276904248315,
                40.87703268590172
              ],
              [
                -73.92277768846607,
                40.87702525300922
              ],
              [
                -73.92279786519552,
                40.87702832748321
              ],
              [
                -73.9228243580552,
                40.87703753047251
              ],
              [
                -73.92286583578245,
                40.87705549586329
              ],
              [
                -73.92288254092276,
                40.87706513321681
              ],
              [
                -73.92292227478717,
                40.877094030120965
              ],
              [
                -73.92294354888257,
                40.87713253970405
              ],
              [
                -73.92294412537231,
                40.87713691678707
              ],
              [
                -73.92300876211289,
                40.87718468513292
              ],
              [
                -73.92303506229437,
                40.87720731455437
              ],
              [
                -73.92305863152065,
                40.877230084878235
              ],
              [
                -73.92310580095484,
                40.87725093071908
              ],
              [
                -73.92315569321521,
                40.87726773224347
              ],
              [
                -73.92320770869343,
                40.87728028752906
              ],
              [
                -73.92326088083085,
                40.87728840831927
              ],
              [
                -73.9233149012421,
                40.87729209093475
              ],
              [
                -73.92336912887104,
                40.87729129167426
              ],
              [
                -73.9234293956286,
                40.8772870102061
              ],
              [
                -73.92348842591905,
                40.877276845938546
              ],
              [
                -73.92353366131377,
                40.877264724938115
              ],
              [
                -73.92357701900715,
                40.87724914428569
              ],
              [
                -73.92363399400763,
                40.87723398877403
              ],
              [
                -73.92369334793713,
                40.8772256070146
              ],
              [
                -73.92375369171315,
                40.87722419516896
              ],
              [
                -73.9238136130879,
                40.87722978627897
              ],
              [
                -73.9238717096996,
                40.877242249493825
              ],
              [
                -73.92391006772807,
                40.877260960104174
              ],
              [
                -73.92397527867065,
                40.87728422323366
              ],
              [
                -73.92397669329614,
                40.877284704453075
              ],
              [
                -73.92407672001595,
                40.87731619293739
              ],
              [
                -73.92417885993329,
                40.877343494966674
              ],
              [
                -73.92428280917072,
                40.87736652931362
              ],
              [
                -73.92438825846597,
                40.87738522744807
              ],
              [
                -73.92447065956256,
                40.87739181072039
              ],
              [
                -73.92455187350787,
                40.877404270395694
              ],
              [
                -73.92463117456373,
                40.87742249513269
              ],
              [
                -73.92466562648586,
                40.87742864594198
              ],
              [
                -73.92469340614272,
                40.87743289418772
              ],
              [
                -73.9247602128762,
                40.87744057495241
              ],
              [
                -73.92482755466249,
                40.87744477528718
              ],
              [
                -73.92489511714378,
                40.87744547558453
              ],
              [
                -73.92493514854823,
                40.87744265630253
              ],
              [
                -73.92497425597504,
                40.87743557990351
              ],
              [
                -73.92501165764392,
                40.87742438784907
              ],
              [
                -73.92504660587467,
                40.877409303874636
              ],
              [
                -73.92507840203368,
                40.87739062951777
              ],
              [
                -73.92510748792193,
                40.877350298819394
              ],
              [
                -73.92514139843446,
                40.87731220075884
              ],
              [
                -73.92519060669277,
                40.87726782779955
              ],
              [
                -73.92524634149107,
                40.87722814955089
              ],
              [
                -73.92530783107327,
                40.87719371542732
              ],
              [
                -73.92539963430987,
                40.877158935721575
              ],
              [
                -73.92549403426749,
                40.877128422489996
              ],
              [
                -73.92559068716548,
                40.877102286852825
              ],
              [
                -73.92564159048314,
                40.87709048786867
              ],
              [
                -73.92575170680726,
                40.8770693823326
              ],
              [
                -73.92586343379948,
                40.87705390392122
              ],
              [
                -73.92596054274807,
                40.877059516576416
              ],
              [
                -73.92605688918461,
                40.877070309564594
              ],
              [
                -73.92615199405294,
                40.87708622922075
              ],
              [
                -73.9262453844698,
                40.877107196388685
              ],
              [
                -73.92626926759239,
                40.87711654902951
              ],
              [
                -73.92630027044785,
                40.8771296702047
              ],
              [
                -73.92632864338461,
                40.8771458606087
              ],
              [
                -73.92635386246528,
                40.877164821268956
              ],
              [
                -73.92637952771739,
                40.87719099712255
              ],
              [
                -73.9263991893142,
                40.877220013565
              ],
              [
                -73.92639977123254,
                40.8772210887617
              ],
              [
                -73.92640746178061,
                40.877237432824344
              ],
              [
                -73.92641611724352,
                40.87726616044384
              ],
              [
                -73.92641753339738,
                40.877294193421214
              ],
              [
                -73.92642110544408,
                40.87733596882139
              ],
              [
                -73.92640802970631,
                40.87737059295211
              ],
              [
                -73.9263910032764,
                40.87738349591492
              ],
              [
                -73.92637867418443,
                40.87739558168086
              ],
              [
                -73.92636692938808,
                40.877400016219504
              ],
              [
                -73.9263534010335,
                40.87740645871844
              ],
              [
                -73.92634196592843,
                40.87741324614079
              ],
              [
                -73.92632463145766,
                40.87742671584046
              ],
              [
                -73.92631051670104,
                40.877442200322804
              ],
              [
                -73.92630002103081,
                40.87745926146291
              ],
              [
                -73.92629344141879,
                40.87747741652433
              ],
              [
                -73.9262909640355,
                40.87749615181876
              ],
              [
                -73.92629265898066,
                40.877514937239674
              ],
              [
                -73.92629847830014,
                40.87753324126217
              ],
              [
                -73.92630825734184,
                40.87755054598149
              ],
              [
                -73.9262952034438,
                40.87756537764221
              ],
              [
                -73.92635080268249,
                40.87759232046259
              ],
              [
                -73.9264055692112,
                40.87762147653742
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.00047398833179,
        "objectid": 129,
        "shape_leng": 0.0932727273403,
        "location_id": 129,
        "zone": "Jackson Heights",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.87586503899996,
                40.7660516539999
              ],
              [
                -73.87613975699989,
                40.76769550599995
              ],
              [
                -73.87623300399997,
                40.76808593799989
              ],
              [
                -73.87624365599994,
                40.76812831899985
              ],
              [
                -73.87625062099983,
                40.76816607499991
              ],
              [
                -73.87638792400001,
                40.76899955799996
              ],
              [
                -73.87601415599995,
                40.76981697599996
              ],
              [
                -73.87602197499997,
                40.77031200099994
              ],
              [
                -73.87600937699986,
                40.77048155299986
              ],
              [
                -73.87597756999998,
                40.77090960899993
              ],
              [
                -73.87597362699988,
                40.77104276199987
              ],
              [
                -73.87598378999998,
                40.77117614699994
              ],
              [
                -73.87600801500002,
                40.7713084929999
              ],
              [
                -73.87604778699992,
                40.77144359299995
              ],
              [
                -73.87608949699984,
                40.77153690299987
              ],
              [
                -73.87615314099999,
                40.77152345599989
              ],
              [
                -73.87665135299991,
                40.771418945999926
              ],
              [
                -73.87704537199986,
                40.77131652899987
              ],
              [
                -73.87743339799992,
                40.771200254999926
              ],
              [
                -73.87781416299985,
                40.77107045699989
              ],
              [
                -73.87818646999993,
                40.77092753499992
              ],
              [
                -73.87854915699987,
                40.77077199599996
              ],
              [
                -73.87897365099985,
                40.77055642099989
              ],
              [
                -73.87961158799999,
                40.77023244799993
              ],
              [
                -73.88020161599997,
                40.769904189999856
              ],
              [
                -73.88056341499993,
                40.76970115299986
              ],
              [
                -73.88068583599984,
                40.76963245199993
              ],
              [
                -73.88159926399999,
                40.7691258519999
              ],
              [
                -73.88277241799986,
                40.76852944099994
              ],
              [
                -73.8835659359999,
                40.7681804449999
              ],
              [
                -73.88426804199986,
                40.767894764999916
              ],
              [
                -73.88439729399991,
                40.767842169999874
              ],
              [
                -73.88506266099994,
                40.767598942999946
              ],
              [
                -73.88564823400002,
                40.767407690999946
              ],
              [
                -73.88602554199989,
                40.76728295799989
              ],
              [
                -73.88667620399991,
                40.76710325899991
              ],
              [
                -73.88671945999997,
                40.767091312999874
              ],
              [
                -73.88713429399999,
                40.76698296599988
              ],
              [
                -73.88762452899994,
                40.76686520099992
              ],
              [
                -73.88770661799985,
                40.766838597999936
              ],
              [
                -73.88777877399983,
                40.76682329599986
              ],
              [
                -73.88835471199987,
                40.766697713999925
              ],
              [
                -73.88877310599993,
                40.76651132699987
              ],
              [
                -73.88918520499999,
                40.76629769199996
              ],
              [
                -73.88994886800005,
                40.766104409999905
              ],
              [
                -73.89042403199987,
                40.7659620609999
              ],
              [
                -73.89072002699994,
                40.76588687999986
              ],
              [
                -73.89089043699997,
                40.765858197999925
              ],
              [
                -73.89144489699991,
                40.7660746539999
              ],
              [
                -73.89165463899995,
                40.76615653599992
              ],
              [
                -73.89183535799987,
                40.766227079999936
              ],
              [
                -73.89206947799994,
                40.76621963599993
              ],
              [
                -73.89232583099981,
                40.76621747199989
              ],
              [
                -73.89277464899999,
                40.76623007399993
              ],
              [
                -73.89299530699995,
                40.766248613999956
              ],
              [
                -73.89325108799977,
                40.76627144599996
              ],
              [
                -73.89325764999997,
                40.7662118549999
              ],
              [
                -73.89349605899986,
                40.765950929999924
              ],
              [
                -73.89377741500005,
                40.76586719899996
              ],
              [
                -73.89384202100001,
                40.76584233599995
              ],
              [
                -73.89390458899999,
                40.76581825699986
              ],
              [
                -73.89403763099993,
                40.76575891899991
              ],
              [
                -73.8941491849999,
                40.765694356999866
              ],
              [
                -73.89428031700004,
                40.76560677599989
              ],
              [
                -73.89437624799997,
                40.76552658499991
              ],
              [
                -73.89446044299982,
                40.76544639099992
              ],
              [
                -73.89453879999992,
                40.7653572669999
              ],
              [
                -73.89461716499984,
                40.76525625699986
              ],
              [
                -73.89470543599994,
                40.76506903299987
              ],
              [
                -73.89477219899987,
                40.764889217999944
              ],
              [
                -73.89483535199997,
                40.76465531699996
              ],
              [
                -73.89499458899998,
                40.764453153999945
              ],
              [
                -73.89505758700001,
                40.76434653799987
              ],
              [
                -73.89506657699998,
                40.764331327999884
              ],
              [
                -73.8951683709999,
                40.7641973619999
              ],
              [
                -73.89527479499989,
                40.76408559799993
              ],
              [
                -73.89542447799998,
                40.76394724499989
              ],
              [
                -73.89557704399981,
                40.76382796799991
              ],
              [
                -73.89585991899992,
                40.76362847399992
              ],
              [
                -73.89598406499984,
                40.76354310999992
              ],
              [
                -73.89608492499991,
                40.76347375399992
              ],
              [
                -73.89624744699991,
                40.763332747999875
              ],
              [
                -73.89641138399999,
                40.76315008299991
              ],
              [
                -73.89668188499988,
                40.762777588999874
              ],
              [
                -73.89700714599998,
                40.762351192999894
              ],
              [
                -73.89720062399992,
                40.76207328199991
              ],
              [
                -73.8972310879999,
                40.762029524999924
              ],
              [
                -73.89735543399992,
                40.76185091399987
              ],
              [
                -73.897895281,
                40.76104450599991
              ],
              [
                -73.89791145199983,
                40.76102034899989
              ],
              [
                -73.89825240299986,
                40.76051448699996
              ],
              [
                -73.89866137899992,
                40.75992101699996
              ],
              [
                -73.89906072899988,
                40.75932801599991
              ],
              [
                -73.89941035199995,
                40.75880883999989
              ],
              [
                -73.89948479499999,
                40.75868286799992
              ],
              [
                -73.89956743900002,
                40.758545262999846
              ],
              [
                -73.89960731900003,
                40.75845463599989
              ],
              [
                -73.89964864399998,
                40.75837966699988
              ],
              [
                -73.89967747499992,
                40.758296863999966
              ],
              [
                -73.8997166869999,
                40.758162578999915
              ],
              [
                -73.89976111400001,
                40.75798575899993
              ],
              [
                -73.89978921699989,
                40.75789847899993
              ],
              [
                -73.89980933199992,
                40.75778375299994
              ],
              [
                -73.89984592699983,
                40.75756215299987
              ],
              [
                -73.89986047499984,
                40.757337462999914
              ],
              [
                -73.89985245499989,
                40.757111608999885
              ],
              [
                -73.89982174199989,
                40.75688653699989
              ],
              [
                -73.89979518199993,
                40.75677539099996
              ],
              [
                -73.89976862099998,
                40.75666423699994
              ],
              [
                -73.89972650399997,
                40.75654186699989
              ],
              [
                -73.89969372699986,
                40.75644660899997
              ],
              [
                -73.89959808799998,
                40.75623543799993
              ],
              [
                -73.89923583699981,
                40.75553001699993
              ],
              [
                -73.89912259299984,
                40.755169293999906
              ],
              [
                -73.89905513000004,
                40.75494982499988
              ],
              [
                -73.89897117099996,
                40.75471530599987
              ],
              [
                -73.89855812999998,
                40.754137095999916
              ],
              [
                -73.89826519799995,
                40.75366541399989
              ],
              [
                -73.89790235299994,
                40.753087714999864
              ],
              [
                -73.89770744899982,
                40.752734858999894
              ],
              [
                -73.89762297800004,
                40.75252318399989
              ],
              [
                -73.89757880899995,
                40.75238393499995
              ],
              [
                -73.89748654099999,
                40.7520143479999
              ],
              [
                -73.89733942799997,
                40.751419567999896
              ],
              [
                -73.89728972099992,
                40.750872780999906
              ],
              [
                -73.89721143799989,
                40.75046637499993
              ],
              [
                -73.8970765,
                40.75011887599991
              ],
              [
                -73.89648281799981,
                40.74907123399993
              ],
              [
                -73.89624245599988,
                40.74871954499985
              ],
              [
                -73.89614389199978,
                40.74867878599984
              ],
              [
                -73.89583955299997,
                40.74854685699991
              ],
              [
                -73.89560209500003,
                40.74844971999991
              ],
              [
                -73.89521297399996,
                40.74829055299993
              ],
              [
                -73.89379708799999,
                40.74769149999987
              ],
              [
                -73.89240906699999,
                40.747135328999896
              ],
              [
                -73.89175048799984,
                40.7468117279999
              ],
              [
                -73.89143128599993,
                40.74684473699989
              ],
              [
                -73.89050129199978,
                40.74694193899997
              ],
              [
                -73.88957128399994,
                40.747039053999906
              ],
              [
                -73.88867766199998,
                40.74713427799992
              ],
              [
                -73.88778326899985,
                40.74722892699992
              ],
              [
                -73.88764663699996,
                40.74724483899995
              ],
              [
                -73.88671656299988,
                40.747341462999906
              ],
              [
                -73.88578740499986,
                40.74743975399987
              ],
              [
                -73.88485538499994,
                40.747534811999856
              ],
              [
                -73.88393579499991,
                40.74763024299991
              ],
              [
                -73.8829991829999,
                40.747734401999914
              ],
              [
                -73.88256876999999,
                40.747777844999874
              ],
              [
                -73.88207112100002,
                40.747829926999884
              ],
              [
                -73.88113747599988,
                40.74792729299991
              ],
              [
                -73.88020647999986,
                40.748024979999926
              ],
              [
                -73.87983894900005,
                40.748061724999936
              ],
              [
                -73.87927779999994,
                40.748124092999944
              ],
              [
                -73.87845779299981,
                40.74821481099991
              ],
              [
                -73.87834312599989,
                40.74822265999992
              ],
              [
                -73.87741612499993,
                40.74831937399995
              ],
              [
                -73.87692458299988,
                40.74836898899989
              ],
              [
                -73.87647952999995,
                40.74841640199986
              ],
              [
                -73.87625180199986,
                40.748438849999914
              ],
              [
                -73.8755564309999,
                40.74851360799994
              ],
              [
                -73.87543236199986,
                40.74852735999987
              ],
              [
                -73.87462946399991,
                40.74861392799995
              ],
              [
                -73.87398293699992,
                40.74868566899991
              ],
              [
                -73.8737009829999,
                40.748717318999866
              ],
              [
                -73.87330310099995,
                40.74875812499991
              ],
              [
                -73.87277356899986,
                40.74881295799993
              ],
              [
                -73.87237256399987,
                40.74885155099986
              ],
              [
                -73.87184095900001,
                40.7489055769999
              ],
              [
                -73.871356485,
                40.74895695099991
              ],
              [
                -73.87037881299995,
                40.74906407799991
              ],
              [
                -73.86942458599985,
                40.74915685199993
              ],
              [
                -73.86968551,
                40.749612361999894
              ],
              [
                -73.87084215599985,
                40.751544692999865
              ],
              [
                -73.87179244199989,
                40.753325668999935
              ],
              [
                -73.87207047299985,
                40.75386198799989
              ],
              [
                -73.87214232699986,
                40.753986047999874
              ],
              [
                -73.87266966999982,
                40.7549867989999
              ],
              [
                -73.87269240699999,
                40.7550299389999
              ],
              [
                -73.87271067399989,
                40.75506352099989
              ],
              [
                -73.87365721799983,
                40.756803485999946
              ],
              [
                -73.87420803099994,
                40.75673156299994
              ],
              [
                -73.87513874300004,
                40.756633815999905
              ],
              [
                -73.87551080399989,
                40.758679635999975
              ],
              [
                -73.87580654599999,
                40.76030783599993
              ],
              [
                -73.87601371899996,
                40.76144373199992
              ],
              [
                -73.87549091099996,
                40.76135159499989
              ],
              [
                -73.87499575099986,
                40.761264321999924
              ],
              [
                -73.87531552900002,
                40.762944974999876
              ],
              [
                -73.87532089499993,
                40.763008007999886
              ],
              [
                -73.87532637000001,
                40.76305660499987
              ],
              [
                -73.875535095,
                40.76423051299992
              ],
              [
                -73.87586503899996,
                40.7660516539999
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000202375182143,
        "objectid": 134,
        "shape_leng": 0.0695878223284,
        "location_id": 134,
        "zone": "Kew Gardens",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.82288529599995,
                40.71192805799993
              ],
              [
                -73.82320247400003,
                40.71220594799992
              ],
              [
                -73.8234526099998,
                40.71245950199993
              ],
              [
                -73.82363860699984,
                40.71266664399989
              ],
              [
                -73.82385072099994,
                40.71293320599995
              ],
              [
                -73.82398559799991,
                40.71321593899988
              ],
              [
                -73.82428940599978,
                40.71387006599987
              ],
              [
                -73.82467827499991,
                40.7144485109999
              ],
              [
                -73.82471749499993,
                40.71450684999994
              ],
              [
                -73.82497234899986,
                40.714802605999864
              ],
              [
                -73.8251664649999,
                40.71502786599996
              ],
              [
                -73.8253709409999,
                40.71526515499992
              ],
              [
                -73.82559716299987,
                40.715514336999874
              ],
              [
                -73.82567283299986,
                40.715615109999874
              ],
              [
                -73.82570967299992,
                40.71566664699994
              ],
              [
                -73.8257365329998,
                40.715704222999896
              ],
              [
                -73.8258906989998,
                40.715938525999924
              ],
              [
                -73.82591945199992,
                40.71598987599993
              ],
              [
                -73.82627825999985,
                40.71580687999991
              ],
              [
                -73.82630698999996,
                40.71579072399989
              ],
              [
                -73.82646966099985,
                40.715695567999916
              ],
              [
                -73.82681044699991,
                40.715550945999965
              ],
              [
                -73.82700619999986,
                40.715440297999855
              ],
              [
                -73.82715524199998,
                40.71538800399985
              ],
              [
                -73.82850807699985,
                40.71504432999991
              ],
              [
                -73.82926493499993,
                40.71479222999991
              ],
              [
                -73.82995752599986,
                40.71458088699995
              ],
              [
                -73.83031312099993,
                40.71449974499997
              ],
              [
                -73.83082284299995,
                40.71438342799992
              ],
              [
                -73.8309675249999,
                40.714354490999895
              ],
              [
                -73.83103141799982,
                40.71433672499988
              ],
              [
                -73.83115264699987,
                40.71430685599991
              ],
              [
                -73.83129817899982,
                40.714264132999865
              ],
              [
                -73.83135959999987,
                40.71423170499988
              ],
              [
                -73.83243372799994,
                40.71365996799988
              ],
              [
                -73.83317906799991,
                40.71326322499993
              ],
              [
                -73.83330196199995,
                40.71320007099996
              ],
              [
                -73.83355895899986,
                40.713068003999865
              ],
              [
                -73.833657742,
                40.71301572899994
              ],
              [
                -73.83374150300001,
                40.712969680999905
              ],
              [
                -73.83426226999985,
                40.71268337399986
              ],
              [
                -73.83505175599991,
                40.712253576999885
              ],
              [
                -73.83600510799987,
                40.711729670999894
              ],
              [
                -73.83602786500002,
                40.711749063999896
              ],
              [
                -73.83611476599997,
                40.71182514599994
              ],
              [
                -73.83640857599988,
                40.711641768999925
              ],
              [
                -73.83640880199984,
                40.71164162599989
              ],
              [
                -73.83640902599988,
                40.711641499999864
              ],
              [
                -73.83671639099991,
                40.711470315999854
              ],
              [
                -73.83671661599998,
                40.7114701909999
              ],
              [
                -73.83671685300001,
                40.71147007499993
              ],
              [
                -73.83703669299994,
                40.71131180399991
              ],
              [
                -73.83703688199994,
                40.711311704999915
              ],
              [
                -73.83703708399995,
                40.7113116139999
              ],
              [
                -73.837368179,
                40.711166928999944
              ],
              [
                -73.8377089419999,
                40.71103648399988
              ],
              [
                -73.83805756099987,
                40.71092087199988
              ],
              [
                -73.83952312699982,
                40.7104964509999
              ],
              [
                -73.83972439899992,
                40.71039876599992
              ],
              [
                -73.83978025199981,
                40.710385523999925
              ],
              [
                -73.84019199299999,
                40.71027682899993
              ],
              [
                -73.84024206299998,
                40.710261930999884
              ],
              [
                -73.84069154199982,
                40.71015321399994
              ],
              [
                -73.84133936599986,
                40.709946233999894
              ],
              [
                -73.84263067999991,
                40.709432976999885
              ],
              [
                -73.84267962199995,
                40.709414123999935
              ],
              [
                -73.8430466579998,
                40.709264149999974
              ],
              [
                -73.84314625299982,
                40.70922345599992
              ],
              [
                -73.84296437499994,
                40.70919495899991
              ],
              [
                -73.84290926199998,
                40.709186319999915
              ],
              [
                -73.84280177899991,
                40.709169477999914
              ],
              [
                -73.84220736299994,
                40.70907631699986
              ],
              [
                -73.84159160299998,
                40.70898160199991
              ],
              [
                -73.84151213299998,
                40.708969379999864
              ],
              [
                -73.84144586899984,
                40.70895751099994
              ],
              [
                -73.8409080659998,
                40.70887501099991
              ],
              [
                -73.84069937599986,
                40.70884116899991
              ],
              [
                -73.83975789399979,
                40.70868851299986
              ],
              [
                -73.83938086799998,
                40.70862603299986
              ],
              [
                -73.83911666099988,
                40.70857492899989
              ],
              [
                -73.83901101199996,
                40.70855449199996
              ],
              [
                -73.83868296400001,
                40.708493320999885
              ],
              [
                -73.8386827869999,
                40.70849328499984
              ],
              [
                -73.8386826099999,
                40.70849323899989
              ],
              [
                -73.8383589909999,
                40.70841610699994
              ],
              [
                -73.83829235499992,
                40.708396615999945
              ],
              [
                -73.83804164799996,
                40.70832329499996
              ],
              [
                -73.83773266099993,
                40.70821535599993
              ],
              [
                -73.83743395899987,
                40.70809301499986
              ],
              [
                -73.83743389999984,
                40.708092986999894
              ],
              [
                -73.83743384099992,
                40.708092960999934
              ],
              [
                -73.83714702999997,
                40.707957045999905
              ],
              [
                -73.83781050699982,
                40.70698817599987
              ],
              [
                -73.83790705599984,
                40.70687146099988
              ],
              [
                -73.83790716199995,
                40.706871325999906
              ],
              [
                -73.83790724599992,
                40.706871190999934
              ],
              [
                -73.83798758599987,
                40.706743142999855
              ],
              [
                -73.83798760999981,
                40.70674309799989
              ],
              [
                -73.83798763399989,
                40.70674305399993
              ],
              [
                -73.8380487209998,
                40.706605603999954
              ],
              [
                -73.83804876799985,
                40.70660549599996
              ],
              [
                -73.83804879199987,
                40.70660539699989
              ],
              [
                -73.83808807400005,
                40.706461289999936
              ],
              [
                -73.83808812099979,
                40.70646110899985
              ],
              [
                -73.83808814499992,
                40.70646092899996
              ],
              [
                -73.83810401799988,
                40.7063130239999
              ],
              [
                -73.83809585899986,
                40.70616468899991
              ],
              [
                -73.83809584699989,
                40.70616458099993
              ],
              [
                -73.8380958239999,
                40.7061644729999
              ],
              [
                -73.83806408500001,
                40.70601925699987
              ],
              [
                -73.83806406099988,
                40.70601912199989
              ],
              [
                -73.83806401399981,
                40.7060189869999
              ],
              [
                -73.83801015599994,
                40.705879826999876
              ],
              [
                -73.83801013199991,
                40.70587977299991
              ],
              [
                -73.83801010900004,
                40.70587971799994
              ],
              [
                -73.83793647999991,
                40.705749291999865
              ],
              [
                -73.83793643299985,
                40.705749219999866
              ],
              [
                -73.83793637399988,
                40.70574914799994
              ],
              [
                -73.83784595199988,
                40.70562953999993
              ],
              [
                -73.83733095699989,
                40.704854975999886
              ],
              [
                -73.83696237799982,
                40.704212451999936
              ],
              [
                -73.83661882199989,
                40.703420017999896
              ],
              [
                -73.83644057000006,
                40.70345494699993
              ],
              [
                -73.83633362899994,
                40.70342921199993
              ],
              [
                -73.83621686499995,
                40.70341872699988
              ],
              [
                -73.8360961639999,
                40.703425946999886
              ],
              [
                -73.83597857799987,
                40.70345153499989
              ],
              [
                -73.8358710909998,
                40.70349394799989
              ],
              [
                -73.83577932099995,
                40.70354977499989
              ],
              [
                -73.83577924899996,
                40.703549820999854
              ],
              [
                -73.83577918999991,
                40.70354987399992
              ],
              [
                -73.83570634099986,
                40.703614678999905
              ],
              [
                -73.83548255899987,
                40.70370111499993
              ],
              [
                -73.83475458000002,
                40.7038869019999
              ],
              [
                -73.83460244999996,
                40.70392746699989
              ],
              [
                -73.83391709699988,
                40.70234736699993
              ],
              [
                -73.83302084599993,
                40.70257032599992
              ],
              [
                -73.83213204199994,
                40.702794080999894
              ],
              [
                -73.83152109599999,
                40.701389628999955
              ],
              [
                -73.830629147,
                40.70161654599988
              ],
              [
                -73.82973796799996,
                40.70184039599991
              ],
              [
                -73.82946917899983,
                40.70191018399988
              ],
              [
                -73.82884805700002,
                40.702065798999875
              ],
              [
                -73.82795308,
                40.70228996199991
              ],
              [
                -73.82706578399991,
                40.7025150569999
              ],
              [
                -73.82607186999988,
                40.70277207899992
              ],
              [
                -73.82517586799999,
                40.702991787999984
              ],
              [
                -73.82426993499998,
                40.70321932799988
              ],
              [
                -73.82387070899986,
                40.70332224199993
              ],
              [
                -73.82338938599995,
                40.703443280999885
              ],
              [
                -73.822708114,
                40.70362481299992
              ],
              [
                -73.82245890399994,
                40.70365553099989
              ],
              [
                -73.821478249,
                40.703415603999936
              ],
              [
                -73.82053608499999,
                40.703227147999876
              ],
              [
                -73.8170868249999,
                40.70257033099989
              ],
              [
                -73.81686030399995,
                40.70244433199985
              ],
              [
                -73.81647531299984,
                40.702450425999885
              ],
              [
                -73.81637824499992,
                40.702451963999884
              ],
              [
                -73.81658976900003,
                40.70291543599996
              ],
              [
                -73.81669961199981,
                40.703156092999954
              ],
              [
                -73.8169748309999,
                40.70374317299995
              ],
              [
                -73.81709738799995,
                40.704029800999876
              ],
              [
                -73.81735529099984,
                40.704550139999846
              ],
              [
                -73.81738330599997,
                40.70460389799992
              ],
              [
                -73.81809905199992,
                40.70597371499988
              ],
              [
                -73.81822457299995,
                40.70617766199991
              ],
              [
                -73.81828566399993,
                40.70630284999987
              ],
              [
                -73.81861275299995,
                40.706853399999915
              ],
              [
                -73.81892102499984,
                40.70734990599987
              ],
              [
                -73.81899305699982,
                40.70747190299993
              ],
              [
                -73.8190501699998,
                40.70757022099986
              ],
              [
                -73.81910729599991,
                40.707668538999904
              ],
              [
                -73.81916136299994,
                40.707760331999914
              ],
              [
                -73.81921543099992,
                40.70785211599987
              ],
              [
                -73.81955363300001,
                40.7084252289999
              ],
              [
                -73.81994398999993,
                40.70901972299995
              ],
              [
                -73.82023145099988,
                40.70945750299994
              ],
              [
                -73.82045236499991,
                40.70977454899991
              ],
              [
                -73.82065280899984,
                40.7099897069999
              ],
              [
                -73.82081877299998,
                40.71017277799994
              ],
              [
                -73.82098519099996,
                40.710323844999934
              ],
              [
                -73.82176010399989,
                40.71099293099995
              ],
              [
                -73.82252678099998,
                40.71165488499987
              ],
              [
                -73.82288529599995,
                40.71192805799993
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000128848901918,
        "objectid": 151,
        "shape_leng": 0.0548899410999,
        "location_id": 151,
        "zone": "Manhattan Valley",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.96371096499993,
                40.79301293399991
              ],
              [
                -73.96280590599986,
                40.79423581299993
              ],
              [
                -73.96235980199994,
                40.79485206099987
              ],
              [
                -73.96189985499994,
                40.795479269999916
              ],
              [
                -73.96144060699996,
                40.796110826999964
              ],
              [
                -73.96097971799988,
                40.796738643999916
              ],
              [
                -73.96052271699993,
                40.79736846899988
              ],
              [
                -73.96004456499999,
                40.79804123499991
              ],
              [
                -73.96147779999993,
                40.79865415599994
              ],
              [
                -73.96286980099991,
                40.799239676999946
              ],
              [
                -73.96571144299992,
                40.80043806999988
              ],
              [
                -73.96775900399992,
                40.80130351599994
              ],
              [
                -73.96787379699998,
                40.80135169799993
              ],
              [
                -73.96798415999996,
                40.80139826599985
              ],
              [
                -73.96858360799983,
                40.80163546999991
              ],
              [
                -73.97004742199995,
                40.80226500999991
              ],
              [
                -73.97021594799989,
                40.80233585099994
              ],
              [
                -73.97027400499987,
                40.802359035999885
              ],
              [
                -73.97032589799987,
                40.80238456099995
              ],
              [
                -73.97150381000002,
                40.80283773599996
              ],
              [
                -73.97250022199995,
                40.80321661299997
              ],
              [
                -73.97257779799996,
                40.803247183999915
              ],
              [
                -73.9726574479999,
                40.803276513999926
              ],
              [
                -73.97279907800002,
                40.803329158999894
              ],
              [
                -73.97287179090726,
                40.803356187573904
              ],
              [
                -73.97286807262148,
                40.803320734176026
              ],
              [
                -73.97292317001974,
                40.80324982284389
              ],
              [
                -73.97303522902078,
                40.80307397374196
              ],
              [
                -73.97307070537937,
                40.80305554844743
              ],
              [
                -73.97312859120979,
                40.80297471550859
              ],
              [
                -73.97320518045738,
                40.80283005827629
              ],
              [
                -73.97336671067806,
                40.802630113346446
              ],
              [
                -73.9735422772158,
                40.802356411250344
              ],
              [
                -73.97361322463878,
                40.80229686038974
              ],
              [
                -73.97372060455768,
                40.802167815280235
              ],
              [
                -73.9737747724601,
                40.80204584594857
              ],
              [
                -73.97389989052456,
                40.801889863531166
              ],
              [
                -73.97394098366695,
                40.801809025864046
              ],
              [
                -73.97414361823463,
                40.801516895341194
              ],
              [
                -73.9744872252097,
                40.80105742889691
              ],
              [
                -73.97466556722719,
                40.80081351473425
              ],
              [
                -73.97483924435981,
                40.80055824326267
              ],
              [
                -73.97496436808174,
                40.8003696341939
              ],
              [
                -73.97508668067901,
                40.80018953363309
              ],
              [
                -73.97526783234454,
                40.79993284953168
              ],
              [
                -73.97554385822002,
                40.799528250637344
              ],
              [
                -73.97576219486481,
                40.79926017354923
              ],
              [
                -73.97579140130185,
                40.799209627886306
              ],
              [
                -73.97578169321176,
                40.79915625678026
              ],
              [
                -73.97583055785226,
                40.799092280913214
              ],
              [
                -73.97628527884258,
                40.79843523541014
              ],
              [
                -73.97639951915279,
                40.79827321034626
              ],
              [
                -73.97635598099994,
                40.798254020999956
              ],
              [
                -73.97582955799997,
                40.798021996999935
              ],
              [
                -73.97563350899993,
                40.797946205999914
              ],
              [
                -73.97549419399992,
                40.79788855199996
              ],
              [
                -73.97539813999988,
                40.7978477439999
              ],
              [
                -73.97521014699981,
                40.79777354799995
              ],
              [
                -73.97507069999986,
                40.797713349999874
              ],
              [
                -73.97413148099994,
                40.79734378899995
              ],
              [
                -73.97365145399993,
                40.797148318999845
              ],
              [
                -73.97225661799988,
                40.79656086499989
              ],
              [
                -73.97093073199993,
                40.79600104799988
              ],
              [
                -73.97082734199991,
                40.79595859999988
              ],
              [
                -73.97073269599991,
                40.79591916999989
              ],
              [
                -73.96941543899999,
                40.79536077899998
              ],
              [
                -73.96987063299996,
                40.79473520499991
              ],
              [
                -73.96701396999998,
                40.793559990999924
              ],
              [
                -73.96525997299999,
                40.79280137099989
              ],
              [
                -73.96417598599989,
                40.792362044999884
              ],
              [
                -73.96371096499993,
                40.79301293399991
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000468322514327,
        "objectid": 130,
        "shape_leng": 0.142028320665,
        "location_id": 130,
        "zone": "Jamaica",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.76962552399995,
                40.71178631999994
              ],
              [
                -73.76943987099992,
                40.711976892999864
              ],
              [
                -73.76946291199997,
                40.71201124799997
              ],
              [
                -73.77039451899986,
                40.71340019899987
              ],
              [
                -73.77113434999984,
                40.71450193699991
              ],
              [
                -73.77192121999991,
                40.71568504399992
              ],
              [
                -73.77275859199993,
                40.715397220999954
              ],
              [
                -73.77357135199996,
                40.71512009499986
              ],
              [
                -73.7744423819999,
                40.71490504299987
              ],
              [
                -73.77529927899995,
                40.7146963139999
              ],
              [
                -73.775714584,
                40.71459845499994
              ],
              [
                -73.77618628399998,
                40.71448557399985
              ],
              [
                -73.77712346999995,
                40.71426199399986
              ],
              [
                -73.77730633899989,
                40.71420931699992
              ],
              [
                -73.77800119699988,
                40.714051123999894
              ],
              [
                -73.77886957499987,
                40.71384929699985
              ],
              [
                -73.77972553599984,
                40.7136357699999
              ],
              [
                -73.7799800519999,
                40.71357457099993
              ],
              [
                -73.78042034199984,
                40.71346870999985
              ],
              [
                -73.78058892899989,
                40.71342817599989
              ],
              [
                -73.78178273999978,
                40.71313999399994
              ],
              [
                -73.78227126599995,
                40.7130251309999
              ],
              [
                -73.7823665,
                40.7130027439999
              ],
              [
                -73.782470433,
                40.71297658099992
              ],
              [
                -73.78262547499989,
                40.71293754799992
              ],
              [
                -73.78356075700003,
                40.71271078799989
              ],
              [
                -73.78446144599992,
                40.71249390499993
              ],
              [
                -73.78535272199989,
                40.71228361699985
              ],
              [
                -73.78575198499988,
                40.7121639529999
              ],
              [
                -73.78616005899981,
                40.7120600709999
              ],
              [
                -73.78657528699985,
                40.71197249299993
              ],
              [
                -73.78699598699988,
                40.711901608999895
              ],
              [
                -73.7874204069999,
                40.71184767799992
              ],
              [
                -73.78784678499987,
                40.711810832999916
              ],
              [
                -73.78843481199984,
                40.71198468799988
              ],
              [
                -73.78844455400004,
                40.7119876949999
              ],
              [
                -73.78845433099993,
                40.71199065799995
              ],
              [
                -73.78846414499992,
                40.71199357499987
              ],
              [
                -73.78847398199989,
                40.71199643899991
              ],
              [
                -73.788483855,
                40.71199925699995
              ],
              [
                -73.7884937509998,
                40.712002022999854
              ],
              [
                -73.78850368299993,
                40.71200473399997
              ],
              [
                -73.78851365099997,
                40.71200739899989
              ],
              [
                -73.78852363099992,
                40.712010019999866
              ],
              [
                -73.78853364699978,
                40.712012578999925
              ],
              [
                -73.78854369699997,
                40.712015090999905
              ],
              [
                -73.78855376099986,
                40.71201755899993
              ],
              [
                -73.78856385999993,
                40.71201996399993
              ],
              [
                -73.78857398199985,
                40.71202232299989
              ],
              [
                -73.78858412899999,
                40.71202462999992
              ],
              [
                -73.78859429999986,
                40.71202688199987
              ],
              [
                -73.78860448199985,
                40.71202907999985
              ],
              [
                -73.78861469999994,
                40.71203123299989
              ],
              [
                -73.78862492999987,
                40.71203333199993
              ],
              [
                -73.78863519600003,
                40.71203536699989
              ],
              [
                -73.78864546199993,
                40.71203735799987
              ],
              [
                -73.78865576299995,
                40.71203929499991
              ],
              [
                -73.78866607699986,
                40.71204117799995
              ],
              [
                -73.78867640200001,
                40.71204300799993
              ],
              [
                -73.78868675199999,
                40.712044781999914
              ],
              [
                -73.78869712499983,
                40.712046502999904
              ],
              [
                -73.78870749899997,
                40.71204817899995
              ],
              [
                -73.7887178959999,
                40.712049792999885
              ],
              [
                -73.78872830499998,
                40.712051350999886
              ],
              [
                -73.7887387379998,
                40.71205285599998
              ],
              [
                -73.78874917199984,
                40.71205430699993
              ],
              [
                -73.78875961699991,
                40.712055695999936
              ],
              [
                -73.78877008599987,
                40.71205703799991
              ],
              [
                -73.7887805549999,
                40.71205832699994
              ],
              [
                -73.78879103599988,
                40.712059553999936
              ],
              [
                -73.78880153,
                40.71206073299989
              ],
              [
                -73.78881202299988,
                40.71206185199992
              ],
              [
                -73.78882252899999,
                40.71206292599988
              ],
              [
                -73.78883304599998,
                40.712063934999954
              ],
              [
                -73.7888435639998,
                40.71206488999992
              ],
              [
                -73.78885408199986,
                40.712065791999905
              ],
              [
                -73.78886461199977,
                40.712066639999925
              ],
              [
                -73.78887515399994,
                40.712067424999915
              ],
              [
                -73.78888568399992,
                40.71206816399995
              ],
              [
                -73.78889622599996,
                40.71206884199989
              ],
              [
                -73.78890676899992,
                40.71206947299992
              ],
              [
                -73.78891729899986,
                40.71207004099992
              ],
              [
                -73.78892784199994,
                40.71207055599989
              ],
              [
                -73.7889383849999,
                40.71207101599991
              ],
              [
                -73.78894892899983,
                40.7120714229999
              ],
              [
                -73.78895945899991,
                40.71207177599991
              ],
              [
                -73.78897000299989,
                40.71207207499989
              ],
              [
                -73.78898053499987,
                40.71207230999987
              ],
              [
                -73.78899105499988,
                40.71207249999986
              ],
              [
                -73.78900157599992,
                40.71207263699995
              ],
              [
                -73.78901209599994,
                40.7120727089999
              ],
              [
                -73.78914879699997,
                40.71205688599986
              ],
              [
                -73.78928335099997,
                40.712031728999946
              ],
              [
                -73.78941412099978,
                40.71199753199989
              ],
              [
                -73.79021544199992,
                40.7117170449999
              ],
              [
                -73.79101410700002,
                40.71143258599992
              ],
              [
                -73.79202854699994,
                40.71106937099995
              ],
              [
                -73.79303800099989,
                40.71071107499989
              ],
              [
                -73.79389609999994,
                40.71035232899985
              ],
              [
                -73.79473052399993,
                40.71001246199997
              ],
              [
                -73.79561668999996,
                40.709753078999896
              ],
              [
                -73.79639418099987,
                40.70953157899987
              ],
              [
                -73.79731400799989,
                40.709270198999874
              ],
              [
                -73.79776810199988,
                40.709143187999906
              ],
              [
                -73.79807226500003,
                40.70963987799994
              ],
              [
                -73.79870150699992,
                40.71066337699988
              ],
              [
                -73.79985783199994,
                40.71025256299989
              ],
              [
                -73.80106920299994,
                40.70981821099986
              ],
              [
                -73.80118994599997,
                40.7097862969999
              ],
              [
                -73.80119015899984,
                40.709786243999915
              ],
              [
                -73.80119037199992,
                40.70978616299992
              ],
              [
                -73.80130606099976,
                40.70974222899992
              ],
              [
                -73.80130615599984,
                40.709742192999926
              ],
              [
                -73.80130625,
                40.709742148999965
              ],
              [
                -73.80141399799987,
                40.70968695199988
              ],
              [
                -73.8014141629999,
                40.7096868729999
              ],
              [
                -73.80141430599986,
                40.7096867729999
              ],
              [
                -73.80151137199988,
                40.709621715999916
              ],
              [
                -73.80151145499995,
                40.70962166199995
              ],
              [
                -73.80151152599983,
                40.70962160799997
              ],
              [
                -73.80159590599997,
                40.70954842499992
              ],
              [
                -73.80193297099994,
                40.70891159599995
              ],
              [
                -73.80212493399992,
                40.7087376039999
              ],
              [
                -73.80233119999987,
                40.708572470999904
              ],
              [
                -73.80255069099992,
                40.70841720299988
              ],
              [
                -73.80278213499996,
                40.70827267099986
              ],
              [
                -73.80302414799988,
                40.70813965699997
              ],
              [
                -73.80278721399985,
                40.707715802999864
              ],
              [
                -73.80222783399978,
                40.706749312999925
              ],
              [
                -73.80165725599993,
                40.70576147499985
              ],
              [
                -73.80265548599988,
                40.70548042999997
              ],
              [
                -73.80357251599989,
                40.705233230999966
              ],
              [
                -73.8056477309999,
                40.70467172799995
              ],
              [
                -73.80613695999992,
                40.70569149199989
              ],
              [
                -73.80658872800001,
                40.706633909999866
              ],
              [
                -73.80694856299992,
                40.70736709999989
              ],
              [
                -73.80934800399993,
                40.70668543899991
              ],
              [
                -73.81201248599982,
                40.70594494499994
              ],
              [
                -73.81190436299984,
                40.70512394099996
              ],
              [
                -73.81180202699996,
                40.70436635199991
              ],
              [
                -73.81177296799999,
                40.704180950999906
              ],
              [
                -73.81171289,
                40.70366220199985
              ],
              [
                -73.81163157099994,
                40.70305215999991
              ],
              [
                -73.81152856899989,
                40.70227287499991
              ],
              [
                -73.81193002299992,
                40.70234083599995
              ],
              [
                -73.81301882900003,
                40.70249515699991
              ],
              [
                -73.81314101299998,
                40.702499551999914
              ],
              [
                -73.81356470599994,
                40.70252849799991
              ],
              [
                -73.81398666699994,
                40.70254206699993
              ],
              [
                -73.81429413399988,
                40.70253110899996
              ],
              [
                -73.81496204599993,
                40.70249848699989
              ],
              [
                -73.81531593299998,
                40.7024873309999
              ],
              [
                -73.8159402059999,
                40.70245890199989
              ],
              [
                -73.81628916400003,
                40.702454064999884
              ],
              [
                -73.81637824499992,
                40.702451963999884
              ],
              [
                -73.81613853900002,
                40.701937569999885
              ],
              [
                -73.81593703000003,
                40.70150511699991
              ],
              [
                -73.81551777599985,
                40.70060539199991
              ],
              [
                -73.81546252299988,
                40.70049286499989
              ],
              [
                -73.81540728100003,
                40.70038033799993
              ],
              [
                -73.81537190699999,
                40.700310310999974
              ],
              [
                -73.81513940199982,
                40.69985000899991
              ],
              [
                -73.81498727099982,
                40.699548830999966
              ],
              [
                -73.81492343299988,
                40.69942244099986
              ],
              [
                -73.81466945899993,
                40.69897071199994
              ],
              [
                -73.81441548799988,
                40.69851897299989
              ],
              [
                -73.81430922799986,
                40.698335331999886
              ],
              [
                -73.81415191799988,
                40.69803996999989
              ],
              [
                -73.8140705039998,
                40.69788712999989
              ],
              [
                -73.81369419799995,
                40.697266795999894
              ],
              [
                -73.81365634399988,
                40.69720105099984
              ],
              [
                -73.81361850799995,
                40.69713673899986
              ],
              [
                -73.81338900499996,
                40.6967577539999
              ],
              [
                -73.81315381599988,
                40.69636935899991
              ],
              [
                -73.81299801799996,
                40.69611206299992
              ],
              [
                -73.81245207999991,
                40.695198124999926
              ],
              [
                -73.81224188600005,
                40.69483223599984
              ],
              [
                -73.8120913559999,
                40.694559620999875
              ],
              [
                -73.81202952199988,
                40.69444762999994
              ],
              [
                -73.81176990199997,
                40.693993904999935
              ],
              [
                -73.81172663199997,
                40.6939192989999
              ],
              [
                -73.81139791299987,
                40.69332231399992
              ],
              [
                -73.81102158699986,
                40.69262288699987
              ],
              [
                -73.81075433199986,
                40.69215870099987
              ],
              [
                -73.81063298299996,
                40.691947382999885
              ],
              [
                -73.81054216699988,
                40.69197245899991
              ],
              [
                -73.81026957999993,
                40.69204524899994
              ],
              [
                -73.80971017199981,
                40.692199383999885
              ],
              [
                -73.80890216900004,
                40.69256434899993
              ],
              [
                -73.80828132399985,
                40.692147385999895
              ],
              [
                -73.80803640399986,
                40.69198289499991
              ],
              [
                -73.80723957200003,
                40.69143198799989
              ],
              [
                -73.80642479399984,
                40.69087385799991
              ],
              [
                -73.80555849799985,
                40.691608704999986
              ],
              [
                -73.80468792999994,
                40.69234557199992
              ],
              [
                -73.80381770599995,
                40.6930796319999
              ],
              [
                -73.80295940099997,
                40.69381747099988
              ],
              [
                -73.80211252599995,
                40.69454449899996
              ],
              [
                -73.80119617899996,
                40.695336488999956
              ],
              [
                -73.80053204200006,
                40.69585669199992
              ],
              [
                -73.79985927599985,
                40.69634707999995
              ],
              [
                -73.79918226899999,
                40.69687147599991
              ],
              [
                -73.79876814999977,
                40.69725955099994
              ],
              [
                -73.79854640199991,
                40.69746653299996
              ],
              [
                -73.79786193399997,
                40.69799340399993
              ],
              [
                -73.79753672799987,
                40.69819892499988
              ],
              [
                -73.79722374699978,
                40.69835945699992
              ],
              [
                -73.7964760649998,
                40.698693084999924
              ],
              [
                -73.79572173799994,
                40.69901473499991
              ],
              [
                -73.79449920899998,
                40.69938577699986
              ],
              [
                -73.79357708699986,
                40.699756471999876
              ],
              [
                -73.79284356499997,
                40.70024368299989
              ],
              [
                -73.79260506799987,
                40.70043209999988
              ],
              [
                -73.79246111600004,
                40.700553788999855
              ],
              [
                -73.79246106899983,
                40.70055382499993
              ],
              [
                -73.79232916999992,
                40.70068422399993
              ],
              [
                -73.79232905199986,
                40.70068434099989
              ],
              [
                -73.79232894499991,
                40.70068447699989
              ],
              [
                -73.79221043999986,
                40.700822508999885
              ],
              [
                -73.7922102969999,
                40.7008226709999
              ],
              [
                -73.79221016699994,
                40.70082285099994
              ],
              [
                -73.7921061119999,
                40.7009673569999
              ],
              [
                -73.7921059579999,
                40.70096757299996
              ],
              [
                -73.79210582699996,
                40.700967788999954
              ],
              [
                -73.7920169119999,
                40.70111772699987
              ],
              [
                -73.79194372099994,
                40.701271725999945
              ],
              [
                -73.79186916599991,
                40.701366136999894
              ],
              [
                -73.79235282099985,
                40.70209793999988
              ],
              [
                -73.79239170299988,
                40.7021665299999
              ],
              [
                -73.79219799199994,
                40.702200687999905
              ],
              [
                -73.79200046599996,
                40.70222080899988
              ],
              [
                -73.79180176799987,
                40.70222663599988
              ],
              [
                -73.791637999,
                40.7022205499999
              ],
              [
                -73.7914732489998,
                40.70222990499985
              ],
              [
                -73.79131126499983,
                40.70225465399993
              ],
              [
                -73.79115569699985,
                40.702294075999916
              ],
              [
                -73.79055566899994,
                40.702567795999904
              ],
              [
                -73.78999405799986,
                40.702871415999944
              ],
              [
                -73.78977597299989,
                40.7029788639999
              ],
              [
                -73.78966391499986,
                40.70303948899994
              ],
              [
                -73.78919309,
                40.70320438499993
              ],
              [
                -73.78835413999998,
                40.70346023099992
              ],
              [
                -73.78804161499981,
                40.703555595999894
              ],
              [
                -73.78751689999983,
                40.703715081999896
              ],
              [
                -73.78597976500005,
                40.70418661599993
              ],
              [
                -73.7848487629999,
                40.704585569999935
              ],
              [
                -73.78442027299995,
                40.70476246699992
              ],
              [
                -73.78256582599995,
                40.705424863999895
              ],
              [
                -73.78147887399984,
                40.705455250999854
              ],
              [
                -73.78034491200003,
                40.70541241699988
              ],
              [
                -73.7798182799998,
                40.70492789799994
              ],
              [
                -73.77916009800003,
                40.70438896699998
              ],
              [
                -73.77849131099994,
                40.70383322499986
              ],
              [
                -73.77791103899986,
                40.703282388999895
              ],
              [
                -73.77691037799988,
                40.702524884999924
              ],
              [
                -73.77638291300002,
                40.7020949279999
              ],
              [
                -73.77586892899987,
                40.70165517099993
              ],
              [
                -73.77536886199987,
                40.701205982999966
              ],
              [
                -73.77488318599994,
                40.70074775199991
              ],
              [
                -73.77441230099998,
                40.70028087699991
              ],
              [
                -73.77395659499993,
                40.6998057809999
              ],
              [
                -73.77359394899996,
                40.69999438299989
              ],
              [
                -73.7734383289999,
                40.7000753219999
              ],
              [
                -73.77318719099986,
                40.70018646399989
              ],
              [
                -73.77293506699988,
                40.700294919999905
              ],
              [
                -73.77274622199978,
                40.70037864699993
              ],
              [
                -73.77230768699997,
                40.70057150699989
              ],
              [
                -73.77272432599987,
                40.70126221599989
              ],
              [
                -73.77312771699984,
                40.70194727699995
              ],
              [
                -73.7733174479998,
                40.702261695999916
              ],
              [
                -73.7735339129999,
                40.70262042799986
              ],
              [
                -73.77394353599978,
                40.7033115039999
              ],
              [
                -73.7743477909998,
                40.7039863969999
              ],
              [
                -73.77507462299982,
                40.70523998399989
              ],
              [
                -73.7755921879999,
                40.705329307999946
              ],
              [
                -73.77561355999983,
                40.70551157799985
              ],
              [
                -73.77561486299999,
                40.705697221999834
              ],
              [
                -73.77559530099992,
                40.705884318999956
              ],
              [
                -73.77555451099995,
                40.70607083499987
              ],
              [
                -73.77549258399985,
                40.70625467199991
              ],
              [
                -73.77541010599997,
                40.70643377599989
              ],
              [
                -73.77530808899986,
                40.70660617899991
              ],
              [
                -73.77518799499988,
                40.706770116999955
              ],
              [
                -73.775051581,
                40.70692403599989
              ],
              [
                -73.7749009329999,
                40.707066661999875
              ],
              [
                -73.77461091499988,
                40.70723572399992
              ],
              [
                -73.77430843599988,
                40.707393667999945
              ],
              [
                -73.77399462499987,
                40.707539717999914
              ],
              [
                -73.77373764399991,
                40.707645606999904
              ],
              [
                -73.77367069099986,
                40.70767319499986
              ],
              [
                -73.77333799499985,
                40.70779351599987
              ],
              [
                -73.772997901,
                40.70790021499987
              ],
              [
                -73.77278262899993,
                40.707957893999925
              ],
              [
                -73.77265189,
                40.707992923999875
              ],
              [
                -73.77230141699995,
                40.70807142299989
              ],
              [
                -73.77093585099995,
                40.70862589699987
              ],
              [
                -73.76918606499989,
                40.70933634199989
              ],
              [
                -73.76844546499984,
                40.709637026999886
              ],
              [
                -73.76865794899992,
                40.70973774499994
              ],
              [
                -73.76881217299984,
                40.709808123999885
              ],
              [
                -73.77084362899981,
                40.710171624999894
              ],
              [
                -73.77115938700001,
                40.71020522199995
              ],
              [
                -73.77147819699984,
                40.710222234999925
              ],
              [
                -73.77165155299986,
                40.71022237199992
              ],
              [
                -73.77169204899995,
                40.7102639849999
              ],
              [
                -73.77172444699998,
                40.71031681899992
              ],
              [
                -73.77158686799989,
                40.71035023399988
              ],
              [
                -73.77158671399987,
                40.71035026999989
              ],
              [
                -73.77158658399989,
                40.71035031499988
              ],
              [
                -73.77144162599984,
                40.71039590799989
              ],
              [
                -73.7714414249999,
                40.71039596999994
              ],
              [
                -73.77144123499994,
                40.71039604199993
              ],
              [
                -73.77130105399985,
                40.71044965099993
              ],
              [
                -73.7713009589999,
                40.71044968699987
              ],
              [
                -73.77130086399985,
                40.710449730999855
              ],
              [
                -73.77118657799993,
                40.710499509999885
              ],
              [
                -73.77102494399993,
                40.710576515999925
              ],
              [
                -73.77102481399992,
                40.710576578999884
              ],
              [
                -73.77102469499991,
                40.710576640999925
              ],
              [
                -73.77089074799977,
                40.71065160299992
              ],
              [
                -73.770765846,
                40.71073519299989
              ],
              [
                -73.77076577600003,
                40.710735237999884
              ],
              [
                -73.77076570499997,
                40.71073529199989
              ],
              [
                -73.7706583899999,
                40.7108188429999
              ],
              [
                -73.7700680079999,
                40.711353867999904
              ],
              [
                -73.76962552399995,
                40.71178631999994
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000446669880154,
        "objectid": 139,
        "shape_leng": 0.0932997884483,
        "location_id": 139,
        "zone": "Laurelton",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.74125253099989,
                40.68745783899989
              ],
              [
                -73.74746788899986,
                40.68932218099994
              ],
              [
                -73.74755175399999,
                40.689347336999944
              ],
              [
                -73.74856993199998,
                40.687803737999914
              ],
              [
                -73.74884601399998,
                40.68744748399996
              ],
              [
                -73.74944546799985,
                40.68667202199987
              ],
              [
                -73.75008991099996,
                40.68592344599994
              ],
              [
                -73.75025615899997,
                40.68561310999991
              ],
              [
                -73.75103020599988,
                40.684222866999924
              ],
              [
                -73.75125793499984,
                40.683734125999926
              ],
              [
                -73.75154215499991,
                40.683046042999926
              ],
              [
                -73.75180409199986,
                40.682403322999946
              ],
              [
                -73.75225828699998,
                40.6814914109999
              ],
              [
                -73.75247059899989,
                40.681180513999934
              ],
              [
                -73.75268290999999,
                40.68086961699991
              ],
              [
                -73.75321057900004,
                40.680154287999926
              ],
              [
                -73.75323817199994,
                40.680116885999915
              ],
              [
                -73.75328689999988,
                40.68005114499995
              ],
              [
                -73.75345604299982,
                40.67982295599991
              ],
              [
                -73.75372596099987,
                40.679457427999935
              ],
              [
                -73.7539939519999,
                40.67909450699991
              ],
              [
                -73.75417856899982,
                40.67884448599991
              ],
              [
                -73.75484909699989,
                40.67792883799995
              ],
              [
                -73.75523457799997,
                40.67728840899989
              ],
              [
                -73.75549392499991,
                40.67662967299991
              ],
              [
                -73.75562236499995,
                40.676258472999926
              ],
              [
                -73.75563646999986,
                40.676215178999925
              ],
              [
                -73.75576169199995,
                40.67588590899988
              ],
              [
                -73.75648885799987,
                40.67602276199989
              ],
              [
                -73.75662976499991,
                40.676116912999916
              ],
              [
                -73.756727424,
                40.67620876499993
              ],
              [
                -73.75680933199992,
                40.67630971499993
              ],
              [
                -73.75687349699994,
                40.67641731799989
              ],
              [
                -73.756918817,
                40.67652887099994
              ],
              [
                -73.75789961600002,
                40.676123409999896
              ],
              [
                -73.75801266799994,
                40.676077675999856
              ],
              [
                -73.75950918099984,
                40.67544720999995
              ],
              [
                -73.76031463999993,
                40.675109970999856
              ],
              [
                -73.75880484999992,
                40.67300565399995
              ],
              [
                -73.75859630299982,
                40.67274790399996
              ],
              [
                -73.75857198899986,
                40.67263733099987
              ],
              [
                -73.75814760699996,
                40.672706914999935
              ],
              [
                -73.75643906099987,
                40.672441159999906
              ],
              [
                -73.75636239300002,
                40.672429236999974
              ],
              [
                -73.75646388399996,
                40.67168400299994
              ],
              [
                -73.7564837459999,
                40.671489865999874
              ],
              [
                -73.75646127199984,
                40.67109403099993
              ],
              [
                -73.7564247869999,
                40.67063356499994
              ],
              [
                -73.75640621299988,
                40.670372914999916
              ],
              [
                -73.75643734399979,
                40.669666378999906
              ],
              [
                -73.75668454799994,
                40.66875852199992
              ],
              [
                -73.75671602499992,
                40.66856252699993
              ],
              [
                -73.75699533399998,
                40.667741267999915
              ],
              [
                -73.75731692799982,
                40.66704031599992
              ],
              [
                -73.75763129599991,
                40.66645115699992
              ],
              [
                -73.75756437999986,
                40.666448683999924
              ],
              [
                -73.75602173499989,
                40.66639171599998
              ],
              [
                -73.75551141799991,
                40.6663987639999
              ],
              [
                -73.7552623119999,
                40.666394765999954
              ],
              [
                -73.75336722899982,
                40.6664006249999
              ],
              [
                -73.75318579199988,
                40.666400028999924
              ],
              [
                -73.75175886599992,
                40.6663953199999
              ],
              [
                -73.75073016999993,
                40.66640190199997
              ],
              [
                -73.74865865799985,
                40.666383527999905
              ],
              [
                -73.74608613500001,
                40.66640039999989
              ],
              [
                -73.74536651700001,
                40.66640403199993
              ],
              [
                -73.74440564500003,
                40.66640390899993
              ],
              [
                -73.74117817299991,
                40.66640295399989
              ],
              [
                -73.74080404599995,
                40.66684396799993
              ],
              [
                -73.73993331699991,
                40.66776382799988
              ],
              [
                -73.73921986399984,
                40.66851888399988
              ],
              [
                -73.73900041699996,
                40.668749285999866
              ],
              [
                -73.73869946900007,
                40.6690682359999
              ],
              [
                -73.73850086499984,
                40.66928477299995
              ],
              [
                -73.73823608799982,
                40.66953170599987
              ],
              [
                -73.73798806,
                40.66978963599991
              ],
              [
                -73.73775794399985,
                40.670057546999935
              ],
              [
                -73.7375467379998,
                40.67033434399988
              ],
              [
                -73.73735527499991,
                40.67061884099995
              ],
              [
                -73.73718424499995,
                40.670909812999895
              ],
              [
                -73.73593438599974,
                40.67243297099988
              ],
              [
                -73.736249418,
                40.6726306019999
              ],
              [
                -73.73597469399992,
                40.67256742099995
              ],
              [
                -73.73561853899999,
                40.67313052699989
              ],
              [
                -73.73509933199999,
                40.67392425699991
              ],
              [
                -73.73505915299991,
                40.67398974999989
              ],
              [
                -73.73502751499981,
                40.67402828999993
              ],
              [
                -73.73403489799982,
                40.6752376569999
              ],
              [
                -73.73384204199998,
                40.67546337699988
              ],
              [
                -73.73332244199996,
                40.67613256599988
              ],
              [
                -73.7330322339999,
                40.676495025999905
              ],
              [
                -73.73187383,
                40.67756892099987
              ],
              [
                -73.73106142499994,
                40.678306620999905
              ],
              [
                -73.73047301299988,
                40.6793479369999
              ],
              [
                -73.72950555799994,
                40.680055725999885
              ],
              [
                -73.72924739999999,
                40.68043098599987
              ],
              [
                -73.7289899879998,
                40.68068281299986
              ],
              [
                -73.72874616799989,
                40.680942857999916
              ],
              [
                -73.7285165689999,
                40.681210472999915
              ],
              [
                -73.72830180799986,
                40.681484974999925
              ],
              [
                -73.72810238799995,
                40.681765636999934
              ],
              [
                -73.72720084199985,
                40.68356123499993
              ],
              [
                -73.72718272499995,
                40.68359732999989
              ],
              [
                -73.72785251200004,
                40.68380044199992
              ],
              [
                -73.72872058399992,
                40.68406239099988
              ],
              [
                -73.72959149799995,
                40.68432533799995
              ],
              [
                -73.73045844999993,
                40.68458679399987
              ],
              [
                -73.73132732799995,
                40.684850543999865
              ],
              [
                -73.73222005799998,
                40.685121095999925
              ],
              [
                -73.73308454599989,
                40.6853825349999
              ],
              [
                -73.73350033599996,
                40.68550058399994
              ],
              [
                -73.73355835299998,
                40.68551705399991
              ],
              [
                -73.73361575099986,
                40.68553440399993
              ],
              [
                -73.73396025399994,
                40.68563856299993
              ],
              [
                -73.73451246099985,
                40.685808342999955
              ],
              [
                -73.73483039799987,
                40.68590609699992
              ],
              [
                -73.73569987899988,
                40.68617269699994
              ],
              [
                -73.73657845699985,
                40.68643801399987
              ],
              [
                -73.737321908,
                40.686664320999924
              ],
              [
                -73.73744163699995,
                40.68670076699989
              ],
              [
                -73.7375999409999,
                40.68636201199989
              ],
              [
                -73.74125253099989,
                40.68745783899989
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000423696152789,
        "objectid": 131,
        "shape_leng": 0.116547120922,
        "location_id": 131,
        "zone": "Jamaica Estates",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.77732562899998,
                40.72239270099994
              ],
              [
                -73.77738048899985,
                40.72244906299987
              ],
              [
                -73.77757868099984,
                40.722642217999855
              ],
              [
                -73.77774895399996,
                40.72284210999989
              ],
              [
                -73.77787601899989,
                40.72303670299994
              ],
              [
                -73.7780376599998,
                40.723284268999855
              ],
              [
                -73.77781693000003,
                40.7233391429999
              ],
              [
                -73.77759997899993,
                40.72341057599988
              ],
              [
                -73.77738999299997,
                40.72349836699989
              ],
              [
                -73.77719009799996,
                40.723601881999905
              ],
              [
                -73.77700327899989,
                40.723720008999905
              ],
              [
                -73.77683220599997,
                40.72385121499991
              ],
              [
                -73.77667910999996,
                40.72399361299994
              ],
              [
                -73.77654571399995,
                40.72414504599986
              ],
              [
                -73.7764331279998,
                40.72430318199989
              ],
              [
                -73.77634188999991,
                40.72446564699988
              ],
              [
                -73.7752338719998,
                40.725237532999884
              ],
              [
                -73.77556110500002,
                40.725969863999865
              ],
              [
                -73.77596177499996,
                40.726812848999955
              ],
              [
                -73.77672825799982,
                40.72843008099995
              ],
              [
                -73.7775479359998,
                40.730136776999906
              ],
              [
                -73.77757506899987,
                40.73019327299987
              ],
              [
                -73.77848636099995,
                40.729943894999906
              ],
              [
                -73.77936529399982,
                40.72970186799997
              ],
              [
                -73.78024774699992,
                40.729459822999914
              ],
              [
                -73.78116054999998,
                40.72921275899992
              ],
              [
                -73.78205851399987,
                40.72891889699988
              ],
              [
                -73.78292253099984,
                40.728635947999905
              ],
              [
                -73.78360140099988,
                40.72841281999992
              ],
              [
                -73.78397695399983,
                40.72829139699994
              ],
              [
                -73.78482734399992,
                40.72801171399991
              ],
              [
                -73.78568492799992,
                40.72773019299991
              ],
              [
                -73.7861901749999,
                40.72756357399987
              ],
              [
                -73.78655584699987,
                40.727441503999906
              ],
              [
                -73.78738339599995,
                40.727171980999834
              ],
              [
                -73.78825003899982,
                40.726886972999935
              ],
              [
                -73.78911280699997,
                40.7266001679999
              ],
              [
                -73.78964444799998,
                40.72642499899994
              ],
              [
                -73.78998042299993,
                40.726317244999954
              ],
              [
                -73.79085659799982,
                40.72602748899991
              ],
              [
                -73.7916922309998,
                40.725792384999906
              ],
              [
                -73.79174472699992,
                40.725788849999944
              ],
              [
                -73.7917255479998,
                40.725721754999896
              ],
              [
                -73.79165577199997,
                40.72552430799988
              ],
              [
                -73.79157252999995,
                40.7253296119999
              ],
              [
                -73.79147613699985,
                40.72513846499991
              ],
              [
                -73.79136702000001,
                40.72495161799992
              ],
              [
                -73.79088077299993,
                40.72418885899989
              ],
              [
                -73.79045149300003,
                40.72355453199985
              ],
              [
                -73.79035703899997,
                40.7230052999999
              ],
              [
                -73.79029346799996,
                40.721376148999894
              ],
              [
                -73.79030267399999,
                40.7208914649999
              ],
              [
                -73.79037661699995,
                40.72010372399992
              ],
              [
                -73.79050800399992,
                40.71980649699989
              ],
              [
                -73.79053210899994,
                40.71975195999992
              ],
              [
                -73.7907083439998,
                40.71926865199988
              ],
              [
                -73.79073248899982,
                40.719208756999876
              ],
              [
                -73.79076914099981,
                40.71913418899989
              ],
              [
                -73.79089607099996,
                40.7188759379999
              ],
              [
                -73.79123627699988,
                40.71868931099989
              ],
              [
                -73.79137357500004,
                40.718607991999875
              ],
              [
                -73.791502837,
                40.71851882299991
              ],
              [
                -73.79162293699987,
                40.71842251499991
              ],
              [
                -73.79173290199986,
                40.718319928999904
              ],
              [
                -73.7919010649999,
                40.718136366999936
              ],
              [
                -73.79201376599997,
                40.717967686999955
              ],
              [
                -73.79210870199984,
                40.71779218799989
              ],
              [
                -73.79210873799991,
                40.717792114999945
              ],
              [
                -73.7921849289999,
                40.71761139399989
              ],
              [
                -73.79224175599992,
                40.71742717899984
              ],
              [
                -73.79226766099997,
                40.71728392699988
              ],
              [
                -73.79234087599984,
                40.71646287399992
              ],
              [
                -73.79240413399991,
                40.71619304099993
              ],
              [
                -73.7926872159999,
                40.71568003299994
              ],
              [
                -73.79314903799985,
                40.71493342999985
              ],
              [
                -73.79346012399989,
                40.714419191999895
              ],
              [
                -73.7935315959999,
                40.714277345999925
              ],
              [
                -73.7935316439998,
                40.71427725599991
              ],
              [
                -73.7935316789999,
                40.71427716599994
              ],
              [
                -73.79358570299998,
                40.71412867799988
              ],
              [
                -73.79362091299996,
                40.713975346999895
              ],
              [
                -73.79363634199993,
                40.71381958599996
              ],
              [
                -73.79363636699993,
                40.7138193969999
              ],
              [
                -73.79363635499982,
                40.71381920899989
              ],
              [
                -73.79363166700001,
                40.713663041999894
              ],
              [
                -73.79360706699993,
                40.713508577999924
              ],
              [
                -73.79360705599984,
                40.71350852299992
              ],
              [
                -73.79360704399981,
                40.713508469999915
              ],
              [
                -73.79356328499998,
                40.71335799599988
              ],
              [
                -73.79350163599987,
                40.71321339799988
              ],
              [
                -73.79321635699988,
                40.71261327999989
              ],
              [
                -73.79313224999994,
                40.71240723599991
              ],
              [
                -73.79306872799985,
                40.71219599599993
              ],
              [
                -73.79302654199988,
                40.71198136399988
              ],
              [
                -73.79300604199986,
                40.71176522199993
              ],
              [
                -73.79300721999992,
                40.711549469999916
              ],
              [
                -73.79303800099989,
                40.71071107499989
              ],
              [
                -73.79202854699994,
                40.71106937099995
              ],
              [
                -73.79101410700002,
                40.71143258599992
              ],
              [
                -73.79021544199992,
                40.7117170449999
              ],
              [
                -73.78941412099978,
                40.71199753199989
              ],
              [
                -73.78928335099997,
                40.712031728999946
              ],
              [
                -73.78914879699997,
                40.71205688599986
              ],
              [
                -73.78901209599994,
                40.7120727089999
              ],
              [
                -73.78900157599998,
                40.712072635999895
              ],
              [
                -73.78899105499988,
                40.71207249999986
              ],
              [
                -73.78898053499987,
                40.71207230999987
              ],
              [
                -73.78897000299993,
                40.71207207399988
              ],
              [
                -73.78895945899997,
                40.71207177499989
              ],
              [
                -73.78894892899983,
                40.7120714229999
              ],
              [
                -73.7889383849999,
                40.71207101599991
              ],
              [
                -73.78892784199994,
                40.71207055599989
              ],
              [
                -73.78891729899986,
                40.71207004099992
              ],
              [
                -73.78890676899992,
                40.71206947299992
              ],
              [
                -73.78889622599979,
                40.712068840999954
              ],
              [
                -73.78888568399992,
                40.71206816399995
              ],
              [
                -73.78887515399994,
                40.712067424999915
              ],
              [
                -73.78886461199977,
                40.712066639999925
              ],
              [
                -73.78885408199986,
                40.712065791999905
              ],
              [
                -73.7888435639998,
                40.71206488999992
              ],
              [
                -73.78883304599987,
                40.712063933999936
              ],
              [
                -73.78882252899987,
                40.7120629249999
              ],
              [
                -73.78881202299988,
                40.71206185199992
              ],
              [
                -73.78880153,
                40.71206073299989
              ],
              [
                -73.78879103599994,
                40.712059552999925
              ],
              [
                -73.7887805549999,
                40.71205832699994
              ],
              [
                -73.78877008599987,
                40.71205703799991
              ],
              [
                -73.78875961699991,
                40.712055695999936
              ],
              [
                -73.78874917199984,
                40.71205430699993
              ],
              [
                -73.7887387379998,
                40.71205285599998
              ],
              [
                -73.78872830499998,
                40.712051350999886
              ],
              [
                -73.7887178959999,
                40.712049792999885
              ],
              [
                -73.78870749899997,
                40.71204817899995
              ],
              [
                -73.78869712499983,
                40.712046502999904
              ],
              [
                -73.78868675199999,
                40.712044781999914
              ],
              [
                -73.78867640200001,
                40.71204300799993
              ],
              [
                -73.78866607699986,
                40.71204117799995
              ],
              [
                -73.78865576299995,
                40.71203929499991
              ],
              [
                -73.78864546199993,
                40.71203735799987
              ],
              [
                -73.78863519600003,
                40.71203536699989
              ],
              [
                -73.78862492999987,
                40.71203333199993
              ],
              [
                -73.78861469999994,
                40.71203123299989
              ],
              [
                -73.78860448199985,
                40.71202907999985
              ],
              [
                -73.78859429999986,
                40.71202688199987
              ],
              [
                -73.78858412899999,
                40.71202462999992
              ],
              [
                -73.78857398199985,
                40.71202232299989
              ],
              [
                -73.78856385999993,
                40.71201996399993
              ],
              [
                -73.78855376099986,
                40.71201755899993
              ],
              [
                -73.78854369699997,
                40.712015090999905
              ],
              [
                -73.78853364699988,
                40.712012577999914
              ],
              [
                -73.78852363099992,
                40.712010019999866
              ],
              [
                -73.78851365099997,
                40.71200739899989
              ],
              [
                -73.78850368299993,
                40.71200473399997
              ],
              [
                -73.7884937509998,
                40.712002022999854
              ],
              [
                -73.788483855,
                40.71199925699995
              ],
              [
                -73.78847398199989,
                40.71199643899991
              ],
              [
                -73.78846414499992,
                40.71199357499987
              ],
              [
                -73.78845433099993,
                40.71199065799995
              ],
              [
                -73.78844455400004,
                40.7119876949999
              ],
              [
                -73.78843481199984,
                40.71198468799988
              ],
              [
                -73.78784678499987,
                40.711810832999916
              ],
              [
                -73.7874204069999,
                40.71184767799992
              ],
              [
                -73.78699598699988,
                40.711901608999895
              ],
              [
                -73.78657528699985,
                40.71197249299993
              ],
              [
                -73.78616005899981,
                40.7120600709999
              ],
              [
                -73.78575198499988,
                40.7121639529999
              ],
              [
                -73.78535272199989,
                40.71228361699985
              ],
              [
                -73.78446144599992,
                40.71249390499993
              ],
              [
                -73.78356075700003,
                40.71271078799989
              ],
              [
                -73.78262547499989,
                40.71293754799992
              ],
              [
                -73.782470433,
                40.71297658099992
              ],
              [
                -73.78236649999998,
                40.71300274299994
              ],
              [
                -73.78227126599994,
                40.7130251299999
              ],
              [
                -73.78178274,
                40.71313999299989
              ],
              [
                -73.7805889279999,
                40.713428175999894
              ],
              [
                -73.78042034099985,
                40.71346870999986
              ],
              [
                -73.7799800519999,
                40.71357457099993
              ],
              [
                -73.77972553599984,
                40.7136357699999
              ],
              [
                -73.77886957500004,
                40.71384929799993
              ],
              [
                -73.77800119599992,
                40.714051123999894
              ],
              [
                -73.77730633899989,
                40.71420931699992
              ],
              [
                -73.77712346999995,
                40.71426199399986
              ],
              [
                -73.77618628399998,
                40.71448557399985
              ],
              [
                -73.775714584,
                40.71459845499994
              ],
              [
                -73.77529927899995,
                40.7146963139999
              ],
              [
                -73.7744423819999,
                40.71490504299987
              ],
              [
                -73.77357135199996,
                40.71512009499986
              ],
              [
                -73.77275859299989,
                40.71539722099992
              ],
              [
                -73.77192122099994,
                40.715685043999926
              ],
              [
                -73.77110410799997,
                40.71597506299987
              ],
              [
                -73.77007984799988,
                40.716327201999896
              ],
              [
                -73.76922535799989,
                40.716626587999876
              ],
              [
                -73.769139141,
                40.716656798999914
              ],
              [
                -73.76906210699998,
                40.716683767999896
              ],
              [
                -73.76870434999991,
                40.716809028999904
              ],
              [
                -73.76817892099992,
                40.71699299399994
              ],
              [
                -73.76733228200001,
                40.717289796999886
              ],
              [
                -73.76664095199988,
                40.71764661399986
              ],
              [
                -73.765714472,
                40.71639337899988
              ],
              [
                -73.76437291199994,
                40.71696662599988
              ],
              [
                -73.763355018,
                40.71741138199993
              ],
              [
                -73.76247897300004,
                40.71778561499992
              ],
              [
                -73.76166150099981,
                40.71814054599985
              ],
              [
                -73.76084486799981,
                40.71849351999988
              ],
              [
                -73.7600295979999,
                40.71884681499989
              ],
              [
                -73.75921639499985,
                40.71919899199988
              ],
              [
                -73.75878322199986,
                40.71938563499985
              ],
              [
                -73.75839264999983,
                40.71955392299988
              ],
              [
                -73.75832728699997,
                40.71958208899985
              ],
              [
                -73.75725671499985,
                40.718138601999904
              ],
              [
                -73.75721025899988,
                40.7181586389999
              ],
              [
                -73.75638211900001,
                40.718515726999954
              ],
              [
                -73.75556553099985,
                40.718866869999914
              ],
              [
                -73.75474912799989,
                40.71921669499994
              ],
              [
                -73.75393063599992,
                40.71957218199986
              ],
              [
                -73.75313314299989,
                40.71991531799997
              ],
              [
                -73.7523343839998,
                40.72026246099994
              ],
              [
                -73.75247511899987,
                40.72048373099989
              ],
              [
                -73.75263582400002,
                40.7206979659999
              ],
              [
                -73.75281552300001,
                40.7209036249999
              ],
              [
                -73.75301295399987,
                40.72109927899993
              ],
              [
                -73.7532266449999,
                40.72128364999988
              ],
              [
                -73.75433064399982,
                40.72230878599986
              ],
              [
                -73.7546271559998,
                40.72258094099988
              ],
              [
                -73.75491106099987,
                40.7228415239999
              ],
              [
                -73.7554616309999,
                40.723352762999916
              ],
              [
                -73.75577120999998,
                40.72364021599992
              ],
              [
                -73.755035606,
                40.7241153209999
              ],
              [
                -73.75496919499986,
                40.724159646999944
              ],
              [
                -73.75508625099991,
                40.724490059999866
              ],
              [
                -73.75524449499997,
                40.724783126999924
              ],
              [
                -73.75548073699997,
                40.72511980699992
              ],
              [
                -73.75566637499988,
                40.72531916999991
              ],
              [
                -73.75581422999997,
                40.725430010999965
              ],
              [
                -73.75603126999992,
                40.72559271799985
              ],
              [
                -73.75670987299993,
                40.72617907299987
              ],
              [
                -73.75669771299987,
                40.7262287109999
              ],
              [
                -73.75677678599992,
                40.726208335999964
              ],
              [
                -73.75683174999993,
                40.72619401699994
              ],
              [
                -73.75698318299987,
                40.726160324999896
              ],
              [
                -73.75721902199986,
                40.72610785399996
              ],
              [
                -73.75755793699983,
                40.72603243899989
              ],
              [
                -73.75796018499997,
                40.72595204999992
              ],
              [
                -73.75835433199993,
                40.72588850899994
              ],
              [
                -73.7587980189999,
                40.72584059599992
              ],
              [
                -73.75922708600002,
                40.725805977999926
              ],
              [
                -73.75955973699996,
                40.72580223299992
              ],
              [
                -73.75994854100003,
                40.72580527799992
              ],
              [
                -73.76017281699987,
                40.72581402099991
              ],
              [
                -73.76083706099992,
                40.725877042999855
              ],
              [
                -73.76102756099999,
                40.72589417899992
              ],
              [
                -73.76181677799993,
                40.72600393899992
              ],
              [
                -73.7620440879999,
                40.72603512499993
              ],
              [
                -73.76241922899995,
                40.72608659299995
              ],
              [
                -73.76368265699995,
                40.72625991699992
              ],
              [
                -73.76376765199994,
                40.726271572999934
              ],
              [
                -73.76386689499986,
                40.7262841589999
              ],
              [
                -73.76475781199981,
                40.72639712399995
              ],
              [
                -73.76545422899993,
                40.72638236199993
              ],
              [
                -73.7654693229998,
                40.726382041999926
              ],
              [
                -73.76568253199987,
                40.72637752399991
              ],
              [
                -73.76610679099996,
                40.72633693499997
              ],
              [
                -73.76618933199987,
                40.72632947499992
              ],
              [
                -73.76629387899985,
                40.72631836799996
              ],
              [
                -73.76660888099983,
                40.726267875999966
              ],
              [
                -73.76717559599999,
                40.7261826729999
              ],
              [
                -73.76745788000002,
                40.72613928099987
              ],
              [
                -73.76856605399993,
                40.72585397099989
              ],
              [
                -73.76856674299998,
                40.72586018599985
              ],
              [
                -73.76856792799998,
                40.72586650099988
              ],
              [
                -73.7685696449999,
                40.72587288899995
              ],
              [
                -73.768571885,
                40.72587934999991
              ],
              [
                -73.76857466699991,
                40.72588583999988
              ],
              [
                -73.76857799399986,
                40.725892356999914
              ],
              [
                -73.76858186599988,
                40.72589887499989
              ],
              [
                -73.76858628199993,
                40.72590537699995
              ],
              [
                -73.76859122999998,
                40.725911825999916
              ],
              [
                -73.76859671300001,
                40.72591821299991
              ],
              [
                -73.76860271599992,
                40.7259245189999
              ],
              [
                -73.76860920399996,
                40.72593070099992
              ],
              [
                -73.7686161789998,
                40.725936765999954
              ],
              [
                -73.76862361599991,
                40.725942669999874
              ],
              [
                -73.76863146699993,
                40.725948403999965
              ],
              [
                -73.76863972199986,
                40.72595394099988
              ],
              [
                -73.76864834499995,
                40.72595927999993
              ],
              [
                -73.7686572999999,
                40.72596439499989
              ],
              [
                -73.76866656199985,
                40.7259692859999
              ],
              [
                -73.76867607499993,
                40.725973915999894
              ],
              [
                -73.76868582499995,
                40.72597829299986
              ],
              [
                -73.76869575399984,
                40.72598241999985
              ],
              [
                -73.7687058369998,
                40.72598626699988
              ],
              [
                -73.76871602799994,
                40.72598984399986
              ],
              [
                -73.76872630299988,
                40.72599314299994
              ],
              [
                -73.76924074899993,
                40.72590270099988
              ],
              [
                -73.7697527519999,
                40.72578523199992
              ],
              [
                -73.76991329299986,
                40.72574839799991
              ],
              [
                -73.771395781,
                40.72535050099993
              ],
              [
                -73.77163268999986,
                40.72527440499992
              ],
              [
                -73.77165390499994,
                40.72526758999993
              ],
              [
                -73.77224702999986,
                40.72507707299991
              ],
              [
                -73.77286784099985,
                40.72486798499995
              ],
              [
                -73.77348555899981,
                40.72462495199988
              ],
              [
                -73.77372529499999,
                40.724530629999926
              ],
              [
                -73.77423835499987,
                40.72431652299992
              ],
              [
                -73.774371087,
                40.72426113199988
              ],
              [
                -73.77496975299987,
                40.723988060999936
              ],
              [
                -73.77524876600002,
                40.723843161999895
              ],
              [
                -73.77548473600002,
                40.72372061399989
              ],
              [
                -73.77587312899985,
                40.723472820999916
              ],
              [
                -73.7760563109999,
                40.72335593999985
              ],
              [
                -73.77603280499999,
                40.72336247699992
              ],
              [
                -73.77583631399985,
                40.72343173999993
              ],
              [
                -73.77600685799993,
                40.7233035689999
              ],
              [
                -73.77727808000003,
                40.72234633599988
              ],
              [
                -73.77732562899998,
                40.72239270099994
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.00203830095472,
        "objectid": 132,
        "shape_leng": 0.24547851707,
        "location_id": 132,
        "zone": "JFK Airport",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.8250346749999,
                40.663580133999844
              ],
              [
                -73.82571227999983,
                40.66359313999993
              ],
              [
                -73.82629450900006,
                40.663638333999856
              ],
              [
                -73.82687353900002,
                40.663704106999916
              ],
              [
                -73.82713313599999,
                40.66375536799995
              ],
              [
                -73.82789758799997,
                40.66390630699991
              ],
              [
                -73.82910033499995,
                40.66425073799991
              ],
              [
                -73.82938864199997,
                40.6643646499999
              ],
              [
                -73.830031569,
                40.66458188699991
              ],
              [
                -73.83205765,
                40.66525531699989
              ],
              [
                -73.83248638399985,
                40.665354910999945
              ],
              [
                -73.832705339,
                40.665405772999854
              ],
              [
                -73.83247768199996,
                40.66496821299992
              ],
              [
                -73.83185737999993,
                40.6636539299999
              ],
              [
                -73.83107771099985,
                40.66205622399986
              ],
              [
                -73.83030563699988,
                40.660424996999936
              ],
              [
                -73.82991335799981,
                40.659665667999946
              ],
              [
                -73.82931419799992,
                40.658898651499925
              ],
              [
                -73.82859942399985,
                40.6571969729999
              ],
              [
                -73.82827925599995,
                40.656033532999885
              ],
              [
                -73.82807181099994,
                40.65526770199991
              ],
              [
                -73.82782507499988,
                40.6542403619999
              ],
              [
                -73.82770683599992,
                40.65374803599989
              ],
              [
                -73.8275964169999,
                40.6532882659999
              ],
              [
                -73.827478071,
                40.65279547799986
              ],
              [
                -73.82736078899997,
                40.6523071189999
              ],
              [
                -73.82724192599993,
                40.65181216299988
              ],
              [
                -73.82712509999998,
                40.65132568599991
              ],
              [
                -73.82701324399996,
                40.65085989899992
              ],
              [
                -73.82689623299987,
                40.65034970899992
              ],
              [
                -73.82671943599986,
                40.64957881899987
              ],
              [
                -73.8263924282224,
                40.648324348807826
              ],
              [
                -73.82636174739348,
                40.648333560177385
              ],
              [
                -73.82633362674252,
                40.64834668251362
              ],
              [
                -73.82630891763688,
                40.64836331844286
              ],
              [
                -73.8262883679647,
                40.64838296443594
              ],
              [
                -73.82627259971964,
                40.64840502585524
              ],
              [
                -73.82629771679228,
                40.64852151477403
              ],
              [
                -73.82630930852616,
                40.648549236759
              ],
              [
                -73.82631551521101,
                40.648577944098385
              ],
              [
                -73.82631620636471,
                40.64860703331525
              ],
              [
                -73.82631136745069,
                40.64863589290398
              ],
              [
                -73.82630110018461,
                40.64866391618588
              ],
              [
                -73.82631265539997,
                40.64873072495662
              ],
              [
                -73.8263311331647,
                40.6487966248865
              ],
              [
                -73.82635641701073,
                40.648861200527726
              ],
              [
                -73.82638834756247,
                40.64892404477983
              ],
              [
                -73.82641484543666,
                40.64898472001563
              ],
              [
                -73.82643333268707,
                40.649047094187246
              ],
              [
                -73.82644362792341,
                40.64911055537567
              ],
              [
                -73.82644563012045,
                40.64917448099633
              ],
              [
                -73.82643931961192,
                40.649238243906275
              ],
              [
                -73.82642475828362,
                40.64930121855725
              ],
              [
                -73.82640208896747,
                40.649362787132425
              ],
              [
                -73.82637153404077,
                40.64942234560773
              ],
              [
                -73.82635181315084,
                40.649433621893095
              ],
              [
                -73.82633000036155,
                40.64944238270316
              ],
              [
                -73.82630664389826,
                40.649448407850315
              ],
              [
                -73.82628233078407,
                40.64945154590321
              ],
              [
                -73.82625767208633,
                40.649451717992406
              ],
              [
                -73.826236951579,
                40.64945888263075
              ],
              [
                -73.82621814846696,
                40.649468644525705
              ],
              [
                -73.82620181848995,
                40.64948071515783
              ],
              [
                -73.8261884442933,
                40.64949473777115
              ],
              [
                -73.82617842116275,
                40.64951029791739
              ],
              [
                -73.8261720453419,
                40.649526935704934
              ],
              [
                -73.82616950527574,
                40.64954415939124
              ],
              [
                -73.82617718949719,
                40.64956061055322
              ],
              [
                -73.82618120113091,
                40.64957780238093
              ],
              [
                -73.82618143000539,
                40.649595262748306
              ],
              [
                -73.82617786983297,
                40.649612512153844
              ],
              [
                -73.82617061838054,
                40.64962907688925
              ],
              [
                -73.82615987478752,
                40.64964450204853
              ],
              [
                -73.82614593409555,
                40.64965836402087
              ],
              [
                -73.82613482726364,
                40.649672350170725
              ],
              [
                -73.82612682064773,
                40.64968751550487
              ],
              [
                -73.82612212300786,
                40.649703464615264
              ],
              [
                -73.8261208568291,
                40.649719781657176
              ],
              [
                -73.82612305512723,
                40.649736041192924
              ],
              [
                -73.82612866058787,
                40.64975181928379
              ],
              [
                -73.82613752706081,
                40.64976670454362
              ],
              [
                -73.82614942337017,
                40.6497803088649
              ],
              [
                -73.8261640393419,
                40.64979227753825
              ],
              [
                -73.82618099389052,
                40.64980229850086
              ],
              [
                -73.82619984495574,
                40.649810110472956
              ],
              [
                -73.82620931351565,
                40.649836487055964
              ],
              [
                -73.82621444154577,
                40.6498635513873
              ],
              [
                -73.82621515084269,
                40.64989089075632
              ],
              [
                -73.8262491669272,
                40.64993271153165
              ],
              [
                -73.82627759205985,
                40.64997688727781
              ],
              [
                -73.82630014811815,
                40.65002298577976
              ],
              [
                -73.8262853839852,
                40.650039557326934
              ],
              [
                -73.82626713317553,
                40.6500539739491
              ],
              [
                -73.82624592792003,
                40.65006581522621
              ],
              [
                -73.82622238660916,
                40.65007473584107
              ],
              [
                -73.82619719575777,
                40.65008047564895
              ],
              [
                -73.82617108998622,
                40.65008286726443
              ],
              [
                -73.82614483059669,
                40.65008184094254
              ],
              [
                -73.82613102713692,
                40.65007181383231
              ],
              [
                -73.82611970664932,
                40.65006012062906
              ],
              [
                -73.82611120982533,
                40.65004711324215
              ],
              [
                -73.82610579237598,
                40.650033183130894
              ],
              [
                -73.82610361733849,
                40.6500187495244
              ],
              [
                -73.82607582221202,
                40.649768559507606
              ],
              [
                -73.82607198260253,
                40.649737520202514
              ],
              [
                -73.82606144501594,
                40.64970739421815
              ],
              [
                -73.8260445006524,
                40.649679014042576
              ],
              [
                -73.82602161775158,
                40.64965316391996
              ],
              [
                -73.82599342865467,
                40.64963055817927
              ],
              [
                -73.82599248690012,
                40.649605793635175
              ],
              [
                -73.82598644267202,
                40.64958145017446
              ],
              [
                -73.82597544644872,
                40.64955813383485
              ],
              [
                -73.82595977198939,
                40.64953642508324
              ],
              [
                -73.8259398095177,
                40.6495168643645
              ],
              [
                -73.82591605600746,
                40.64949993864713
              ],
              [
                -73.82588910281056,
                40.64948606930045
              ],
              [
                -73.825859620933,
                40.6494756016045
              ],
              [
                -73.82582834433248,
                40.6494687961544
              ],
              [
                -73.82579252953232,
                40.64946450651432
              ],
              [
                -73.8257562754046,
                40.64946468940278
              ],
              [
                -73.82572053834781,
                40.649469339995136
              ],
              [
                -73.82568626112003,
                40.64947833560678
              ],
              [
                -73.82565434796986,
                40.64949143892988
              ],
              [
                -73.82562564078114,
                40.64950830429299
              ],
              [
                -73.82560089686515,
                40.64952848678038
              ],
              [
                -73.82558076898194,
                40.6495514539687
              ],
              [
                -73.82556578811976,
                40.64957659997198
              ],
              [
                -73.8255563494875,
                40.64960326142566
              ],
              [
                -73.8255527020886,
                40.64963073498586
              ],
              [
                -73.82555494215005,
                40.64965829588412
              ],
              [
                -73.82548770814857,
                40.649892156941476
              ],
              [
                -73.82536184791358,
                40.6499135656924
              ],
              [
                -73.82525509340354,
                40.64963507489118
              ],
              [
                -73.82524054970115,
                40.64958828676116
              ],
              [
                -73.82521766228541,
                40.64954347659193
              ],
              [
                -73.82518686973117,
                40.64950150301649
              ],
              [
                -73.82514876208542,
                40.6494631703127
              ],
              [
                -73.82510406955905,
                40.649429212992416
              ],
              [
                -73.82505364853591,
                40.64940028172764
              ],
              [
                -73.82499846516194,
                40.64937693088289
              ],
              [
                -73.8249551639988,
                40.649372294484195
              ],
              [
                -73.82491144628239,
                40.64937297863697
              ],
              [
                -73.82486843170206,
                40.64937896581871
              ],
              [
                -73.82482722193953,
                40.64939010268695
              ],
              [
                -73.8247888724527,
                40.64940610400621
              ],
              [
                -73.82477589644336,
                40.649411395324854
              ],
              [
                -73.82476393924252,
                40.64941793246944
              ],
              [
                -73.82475320580947,
                40.64942560338616
              ],
              [
                -73.82474388012731,
                40.649434276587215
              ],
              [
                -73.82473399556079,
                40.64944700152872
              ],
              [
                -73.8247271324188,
                40.64946083189746
              ],
              [
                -73.8247234953338,
                40.6494753553304
              ],
              [
                -73.82472319274974,
                40.64949013880005
              ],
              [
                -73.82472624652519,
                40.64950478115005
              ],
              [
                -73.82473257069404,
                40.649518802796834
              ],
              [
                -73.82474197565908,
                40.64953178337036
              ],
              [
                -73.82476319575667,
                40.64956818783311
              ],
              [
                -73.82478828507705,
                40.6496031355048
              ],
              [
                -73.82481633061325,
                40.64963560130075
              ],
              [
                -73.82484771498439,
                40.64966624308177
              ],
              [
                -73.82484283346373,
                40.64968086331909
              ],
              [
                -73.82483480544411,
                40.649694654161486
              ],
              [
                -73.8248238577957,
                40.64970722588017
              ],
              [
                -73.82481029989704,
                40.649718223199045
              ],
              [
                -73.8247945148927,
                40.649727335333914
              ],
              [
                -73.82477694886632,
                40.64973430477595
              ],
              [
                -73.82475809823322,
                40.64973893456874
              ],
              [
                -73.82473849571241,
                40.649741093874155
              ],
              [
                -73.82471869527126,
                40.64974072167033
              ],
              [
                -73.82469925647074,
                40.649737828475644
              ],
              [
                -73.82468072865154,
                40.649732496051804
              ],
              [
                -73.82466363540958,
                40.64972487509331
              ],
              [
                -73.8246484597997,
                40.64971518096831
              ],
              [
                -73.82460791959153,
                40.64965905749829
              ],
              [
                -73.82456192030523,
                40.6496054311751
              ],
              [
                -73.8245107256674,
                40.64955460943831
              ],
              [
                -73.82445462918801,
                40.64950688364841
              ],
              [
                -73.8243939524766,
                40.64946252741616
              ],
              [
                -73.82432487379928,
                40.64941938633569
              ],
              [
                -73.82427182414952,
                40.64938625642101
              ],
              [
                -73.82419085739201,
                40.649348629986974
              ],
              [
                -73.82411480773072,
                40.64930546018296
              ],
              [
                -73.8240443273407,
                40.64925711722376
              ],
              [
                -73.82398002063259,
                40.64920401568732
              ],
              [
                -73.8239597224891,
                40.649193533493026
              ],
              [
                -73.82394204550852,
                40.649180586646885
              ],
              [
                -73.82392749939734,
                40.64916554846581
              ],
              [
                -73.82391650358429,
                40.64914885256923
              ],
              [
                -73.82390937512567,
                40.64913098037584
              ],
              [
                -73.82390631956432,
                40.649112447221775
              ],
              [
                -73.82390742500239,
                40.64909378750115
              ],
              [
                -73.82392801590352,
                40.649057574649156
              ],
              [
                -73.82394033932346,
                40.64901924408195
              ],
              [
                -73.82394404601638,
                40.64897988213347
              ],
              [
                -73.82393903094528,
                40.64894060436635
              ],
              [
                -73.82392543625805,
                40.64890252395543
              ],
              [
                -73.8239036472566,
                40.64886672013942
              ],
              [
                -73.82386954563091,
                40.64881873080939
              ],
              [
                -73.82383332708838,
                40.64877164985671
              ],
              [
                -73.82377434375773,
                40.64870391901736
              ],
              [
                -73.82370866290353,
                40.648639863655674
              ],
              [
                -73.82363667760976,
                40.648579867109135
              ],
              [
                -73.82358519817106,
                40.64853843415195
              ],
              [
                -73.82353725987745,
                40.64849460579652
              ],
              [
                -73.82349305441758,
                40.64844855730413
              ],
              [
                -73.82343814617748,
                40.6483812184878
              ],
              [
                -73.82341209148474,
                40.64834386783241
              ],
              [
                -73.82338836405704,
                40.64830562802026
              ],
              [
                -73.82319444111556,
                40.64801116168028
              ],
              [
                -73.82315416510792,
                40.64800436149545
              ],
              [
                -73.82311296945245,
                40.6480026955924
              ],
              [
                -73.82307197451618,
                40.64800620927739
              ],
              [
                -73.8230322952084,
                40.64801480699146
              ],
              [
                -73.82299501065862,
                40.64802825490867
              ],
              [
                -73.8229611348693,
                40.64804618729603
              ],
              [
                -73.82293158913964,
                40.64806811645885
              ],
              [
                -73.8229071770089,
                40.648093446004744
              ],
              [
                -73.82318105938614,
                40.649157489032675
              ],
              [
                -73.82330678473657,
                40.6491390676526
              ],
              [
                -73.82335427337142,
                40.64924553584911
              ],
              [
                -73.82339285229136,
                40.64935407310847
              ],
              [
                -73.8234223678752,
                40.649464247358345
              ],
              [
                -73.82344270258007,
                40.64957562000802
              ],
              [
                -73.82345377540877,
                40.64968774769351
              ],
              [
                -73.82345090324336,
                40.64970970436158
              ],
              [
                -73.82345208765207,
                40.649731751289046
              ],
              [
                -73.8234573053961,
                40.64975345583666
              ],
              [
                -73.82346645408897,
                40.649774392084105
              ],
              [
                -73.82347935420376,
                40.649794149186995
              ],
              [
                -73.82350320773554,
                40.64983093773841
              ],
              [
                -73.8235214834271,
                40.64986953459699
              ],
              [
                -73.82353394983606,
                40.64990945099478
              ],
              [
                -73.82354044908438,
                40.6499501814528
              ],
              [
                -73.823540898858,
                40.64999121018287
              ],
              [
                -73.82352664158358,
                40.65006056173423
              ],
              [
                -73.82351808491744,
                40.65013045509767
              ],
              [
                -73.82351537645953,
                40.650214022406225
              ],
              [
                -73.82352081414116,
                40.6502975125119
              ],
              [
                -73.82353436803197,
                40.650380465398506
              ],
              [
                -73.82355596348255,
                40.65046242400777
              ],
              [
                -73.82358548153385,
                40.650542936758335
              ],
              [
                -73.82362275957273,
                40.6506215600335
              ],
              [
                -73.8236692815312,
                40.65070046277919
              ],
              [
                -73.82369758019378,
                40.650732223295826
              ],
              [
                -73.8237192589662,
                40.65076687371507
              ],
              [
                -73.82373382088048,
                40.65080361972109
              ],
              [
                -73.82374093211106,
                40.65084161895829
              ],
              [
                -73.82374042962867,
                40.6508800003406
              ],
              [
                -73.82373232493954,
                40.65091788402049
              ],
              [
                -73.82371869974587,
                40.65094302509748
              ],
              [
                -73.82370821868588,
                40.65096902587929
              ],
              [
                -73.82370174145375,
                40.6509921204863
              ],
              [
                -73.82369651809381,
                40.65103035317578
              ],
              [
                -73.82369796272235,
                40.65106877653759
              ],
              [
                -73.82370605009852,
                40.65110671892028
              ],
              [
                -73.82372063886339,
                40.65114351707926
              ],
              [
                -73.82374147401084,
                40.6511785277698
              ],
              [
                -73.82379298040433,
                40.65128031613115
              ],
              [
                -73.8238375018489,
                40.65138399816808
              ],
              [
                -73.82387491900565,
                40.65148929603944
              ],
              [
                -73.8239051315718,
                40.65159592757305
              ],
              [
                -73.82392805855035,
                40.65170360702154
              ],
              [
                -73.82394363846666,
                40.651812045827754
              ],
              [
                -73.82395182953364,
                40.65192095339843
              ],
              [
                -73.8239514926208,
                40.651984916638774
              ],
              [
                -73.82395867771312,
                40.652048645874544
              ],
              [
                -73.82397332709648,
                40.652111629018954
              ],
              [
                -73.82399532307905,
                40.65217335997963
              ],
              [
                -73.82402448893473,
                40.65223334272411
              ],
              [
                -73.82406059032242,
                40.65229109526625
              ],
              [
                -73.82410333716987,
                40.652346153539526
              ],
              [
                -73.82414985256521,
                40.65246330893492
              ],
              [
                -73.82418861602066,
                40.65258208963841
              ],
              [
                -73.82421953046139,
                40.652702198315076
              ],
              [
                -73.82424251845833,
                40.652823334304095
              ],
              [
                -73.82425752242335,
                40.65294519437141
              ],
              [
                -73.82424701574062,
                40.653001038381994
              ],
              [
                -73.82424434870848,
                40.653057416212896
              ],
              [
                -73.82424955146031,
                40.65311369129192
              ],
              [
                -73.824262565271,
                40.65316922820571
              ],
              [
                -73.82428324321732,
                40.653223399873795
              ],
              [
                -73.82431135183771,
                40.653275594629434
              ],
              [
                -73.8243465737663,
                40.65332522312678
              ],
              [
                -73.82437767743885,
                40.65336473513566
              ],
              [
                -73.82440243660656,
                40.65340676822528
              ],
              [
                -73.82442050473036,
                40.653450734103124
              ],
              [
                -73.82443162891542,
                40.65349601742499
              ],
              [
                -73.82443565345434,
                40.653541984406125
              ],
              [
                -73.82442469120275,
                40.65358551740426
              ],
              [
                -73.82442140510676,
                40.65362977305578
              ],
              [
                -73.82442585321732,
                40.653673969771624
              ],
              [
                -73.82443795699234,
                40.653717327002234
              ],
              [
                -73.82445750268344,
                40.65375907902263
              ],
              [
                -73.82448414511005,
                40.65379848845566
              ],
              [
                -73.82450787277001,
                40.65382131583166
              ],
              [
                -73.82452910764981,
                40.653845525354484
              ],
              [
                -73.82455852448103,
                40.6538883948722
              ],
              [
                -73.8245801511025,
                40.65393387499496
              ],
              [
                -73.82459044523067,
                40.65396684623752
              ],
              [
                -73.82459663375491,
                40.65400040744441
              ],
              [
                -73.82490473333498,
                40.65514880880938
              ],
              [
                -73.82498070378553,
                40.65543197118367
              ],
              [
                -73.82496862375538,
                40.65565882975496
              ],
              [
                -73.82489319190266,
                40.65589263277677
              ],
              [
                -73.82444909603728,
                40.657196409512636
              ],
              [
                -73.82440384045701,
                40.65725597667439
              ],
              [
                -73.82436660576136,
                40.65731868224192
              ],
              [
                -73.82433776276098,
                40.657383901786034
              ],
              [
                -73.82431759870339,
                40.65745098584098
              ],
              [
                -73.82431091078547,
                40.657465176643356
              ],
              [
                -73.82430741328439,
                40.65748001664265
              ],
              [
                -73.82430720351563,
                40.65749509293536
              ],
              [
                -73.82430724725508,
                40.65755864616926
              ],
              [
                -73.82430065317342,
                40.65762200069774
              ],
              [
                -73.8242874630355,
                40.657684755070306
              ],
              [
                -73.82426776040361,
                40.65774651163844
              ],
              [
                -73.82410557911007,
                40.65772182318792
              ],
              [
                -73.82411097425337,
                40.657704180138715
              ],
              [
                -73.82405929133697,
                40.65769595402021
              ],
              [
                -73.82404130456753,
                40.657755670666944
              ],
              [
                -73.82410368854457,
                40.657762554502504
              ],
              [
                -73.82410728599014,
                40.657750339641616
              ],
              [
                -73.82426410867653,
                40.657779092686525
              ],
              [
                -73.82425358457401,
                40.6578143741298
              ],
              [
                -73.8242370916942,
                40.65784830428727
              ],
              [
                -73.82421491336572,
                40.65788030025945
              ],
              [
                -73.82418743059175,
                40.657909812374
              ],
              [
                -73.82415310845582,
                40.65797126807331
              ],
              [
                -73.82412449130834,
                40.65803439533399
              ],
              [
                -73.82410171821057,
                40.65809888743305
              ],
              [
                -73.82408489983058,
                40.658164431014825
              ],
              [
                -73.82408625283456,
                40.658289354848314
              ],
              [
                -73.82393475032389,
                40.65827147191632
              ],
              [
                -73.82393298572904,
                40.65826467912035
              ],
              [
                -73.82387951519932,
                40.65825780833886
              ],
              [
                -73.82387766032782,
                40.65828496185774
              ],
              [
                -73.82409509645046,
                40.65831652422908
              ],
              [
                -73.82409668723942,
                40.658374059699526
              ],
              [
                -73.82409166371446,
                40.65843148062118
              ],
              [
                -73.8240800643652,
                40.65848834684704
              ],
              [
                -73.82406197808747,
                40.658544222480565
              ],
              [
                -73.82403754350221,
                40.65859867921765
              ],
              [
                -73.82352151962043,
                40.660075390257994
              ],
              [
                -73.82345520656958,
                40.66014230644704
              ],
              [
                -73.82338324351306,
                40.660205747025216
              ],
              [
                -73.823305943689,
                40.66026543583709
              ],
              [
                -73.82322364356938,
                40.66032111305753
              ],
              [
                -73.82313670139534,
                40.66037253632343
              ],
              [
                -73.82304549561698,
                40.66041948178828
              ],
              [
                -73.82295042324705,
                40.6604617450977
              ],
              [
                -73.82285189813183,
                40.66049914227764
              ],
              [
                -73.82280568997277,
                40.6605156358195
              ],
              [
                -73.82275836985622,
                40.660530186628634
              ],
              [
                -73.82269131252144,
                40.66054703976019
              ],
              [
                -73.8226227774157,
                40.66055999460139
              ],
              [
                -73.82255315472135,
                40.66056897739789
              ],
              [
                -73.8224964558123,
                40.66057329373723
              ],
              [
                -73.82243921391427,
                40.66058339067398
              ],
              [
                -73.82238417239627,
                40.660599050413666
              ],
              [
                -73.822332259163,
                40.66062000896068
              ],
              [
                -73.82228434938322,
                40.66064591299077
              ],
              [
                -73.82224125073748,
                40.66067632580719
              ],
              [
                -73.8221733868732,
                40.66072225645163
              ],
              [
                -73.82210002252529,
                40.66076298892437
              ],
              [
                -73.82202184163974,
                40.66079814348775
              ],
              [
                -73.82193957306959,
                40.660827392405345
              ],
              [
                -73.82185398377906,
                40.660850462996635
              ],
              [
                -73.82171812593968,
                40.6608964848459
              ],
              [
                -73.82158370972842,
                40.66094489951681
              ],
              [
                -73.8215134434048,
                40.660971380279626
              ],
              [
                -73.82143730984893,
                40.66100468496761
              ],
              [
                -73.8213641950798,
                40.66104170502375
              ],
              [
                -73.82133823599376,
                40.66105616093905
              ],
              [
                -73.82120184105277,
                40.661134408077274
              ],
              [
                -73.82106144900045,
                40.66120844223857
              ],
              [
                -73.8209172840944,
                40.661278145156544
              ],
              [
                -73.82076957662187,
                40.66134340548313
              ],
              [
                -73.82061856253115,
                40.66140411896701
              ],
              [
                -73.82040649221668,
                40.661489476820655
              ],
              [
                -73.82037971904346,
                40.661501189106325
              ],
              [
                -73.82035096412434,
                40.66150975436517
              ],
              [
                -73.82032086102717,
                40.66151498387531
              ],
              [
                -73.82029007302556,
                40.66151676241272
              ],
              [
                -73.82025927848434,
                40.66151505079034
              ],
              [
                -73.82022915591229,
                40.66150988672106
              ],
              [
                -73.82022279607854,
                40.66150702053648
              ],
              [
                -73.82021589525574,
                40.66150500686851
              ],
              [
                -73.82020864878618,
                40.66150390271829
              ],
              [
                -73.82020126179641,
                40.66150373934118
              ],
              [
                -73.82019394339054,
                40.66150452136184
              ],
              [
                -73.8201869007313,
                40.66150622664353
              ],
              [
                -73.82018033317576,
                40.6615088069148
              ],
              [
                -73.82016247953617,
                40.661525550772986
              ],
              [
                -73.8201319856056,
                40.661550036956825
              ],
              [
                -73.82009861237917,
                40.661572239070445
              ],
              [
                -73.8200642288144,
                40.66159117480092
              ],
              [
                -73.81992114486225,
                40.661664337560495
              ],
              [
                -73.81977303707818,
                40.66173145297591
              ],
              [
                -73.81962034574538,
                40.66179232152532
              ],
              [
                -73.81946352477661,
                40.66184676225733
              ],
              [
                -73.81932891080704,
                40.66189595044533
              ],
              [
                -73.81919250867675,
                40.661942193966475
              ],
              [
                -73.81905442944014,
                40.66198545516971
              ],
              [
                -73.81899985670667,
                40.6620015959443
              ],
              [
                -73.81897271127576,
                40.662011151766535
              ],
              [
                -73.8189242639554,
                40.66203097687983
              ],
              [
                -73.81886428195173,
                40.66204979225861
              ],
              [
                -73.81880193629708,
                40.66206344322803
              ],
              [
                -73.81873799114157,
                40.662071762472166
              ],
              [
                -73.81874439468628,
                40.662050340154344
              ],
              [
                -73.81873303698315,
                40.66204831258691
              ],
              [
                -73.81867189288894,
                40.66213298725428
              ],
              [
                -73.81850715940104,
                40.66210554531113
              ],
              [
                -73.81849667296106,
                40.66205174193634
              ],
              [
                -73.81848323953413,
                40.66204925589437
              ],
              [
                -73.81845317149529,
                40.66202756310682
              ],
              [
                -73.81837398685735,
                40.66199293999266
              ],
              [
                -73.81830156202945,
                40.66197126421072
              ],
              [
                -73.8181679581757,
                40.661958116388405
              ],
              [
                -73.81807847301988,
                40.66196314935153
              ],
              [
                -73.81801507606332,
                40.66195356260958
              ],
              [
                -73.8179165742848,
                40.66194219422515
              ],
              [
                -73.81777053754436,
                40.66191954030058
              ],
              [
                -73.81775357123733,
                40.661911751161355
              ],
              [
                -73.81775019419428,
                40.661903983550744
              ],
              [
                -73.81771168625721,
                40.66190478497017
              ],
              [
                -73.81769128097959,
                40.6619116524564
              ],
              [
                -73.81759279884251,
                40.66189338517429
              ],
              [
                -73.81746603892431,
                40.66186213505691
              ],
              [
                -73.81735511777369,
                40.661836948510086
              ],
              [
                -73.81726686277027,
                40.661806623415565
              ],
              [
                -73.81724313336177,
                40.6617867491833
              ],
              [
                -73.8172080410992,
                40.66178065536633
              ],
              [
                -73.81718991097739,
                40.66178407680209
              ],
              [
                -73.8171604345205,
                40.661795241288374
              ],
              [
                -73.8170721153652,
                40.66178820155076
              ],
              [
                -73.81699404510307,
                40.66176048025337
              ],
              [
                -73.81689780305658,
                40.66175170189912
              ],
              [
                -73.81686613144068,
                40.6617369898985
              ],
              [
                -73.81656839875535,
                40.661690806056995
              ],
              [
                -73.81621480696883,
                40.66163787278962
              ],
              [
                -73.81575896428923,
                40.66156963231751
              ],
              [
                -73.81528124195414,
                40.661492970107226
              ],
              [
                -73.81508540456983,
                40.66145988271495
              ],
              [
                -73.81493715265891,
                40.66141997173022
              ],
              [
                -73.81413798832756,
                40.66127293017234
              ],
              [
                -73.8141267035501,
                40.66125824985139
              ],
              [
                -73.8141142532729,
                40.66125564192136
              ],
              [
                -73.81407345526836,
                40.66126506362191
              ],
              [
                -73.81287252009298,
                40.66101990685543
              ],
              [
                -73.8128476324838,
                40.66100951629253
              ],
              [
                -73.81281590117128,
                40.66101722692333
              ],
              [
                -73.81220466325642,
                40.66089979956434
              ],
              [
                -73.81199403997107,
                40.66088996904534
              ],
              [
                -73.8117054641712,
                40.66081187608111
              ],
              [
                -73.81149831122212,
                40.660776177410646
              ],
              [
                -73.81143921922407,
                40.66074562606795
              ],
              [
                -73.81143245672342,
                40.660724812509216
              ],
              [
                -73.81142149668757,
                40.660705062481824
              ],
              [
                -73.81140660968168,
                40.66068686353827
              ],
              [
                -73.81138816321133,
                40.660670664940305
              ],
              [
                -73.8113666126508,
                40.660656866568424
              ],
              [
                -73.81134249000172,
                40.660645809051026
              ],
              [
                -73.81131639075859,
                40.66063776535484
              ],
              [
                -73.81130060352014,
                40.660634562813165
              ],
              [
                -73.8112844989112,
                40.660632468392436
              ],
              [
                -73.81122870505938,
                40.6605463973961
              ],
              [
                -73.81128488574544,
                40.6603734089116
              ],
              [
                -73.81141753006644,
                40.660306732679274
              ],
              [
                -73.81143455044243,
                40.660252885947564
              ],
              [
                -73.81149705738905,
                40.66022526713164
              ],
              [
                -73.81153691191429,
                40.660231303485325
              ],
              [
                -73.81157245411484,
                40.660217980880766
              ],
              [
                -73.81193258523973,
                40.6601921483329
              ],
              [
                -73.81199051239547,
                40.66020636936098
              ],
              [
                -73.81200162977873,
                40.66020606034837
              ],
              [
                -73.81230741828408,
                40.6602031101358
              ],
              [
                -73.81260077144056,
                40.66016005549476
              ],
              [
                -73.81262125517684,
                40.66015704989839
              ],
              [
                -73.81309228037946,
                40.660194038556426
              ],
              [
                -73.81325179850951,
                40.66023191665838
              ],
              [
                -73.81358233645567,
                40.660310401950674
              ],
              [
                -73.81374960325887,
                40.66034738827007
              ],
              [
                -73.81376113561484,
                40.66035099875185
              ],
              [
                -73.81388342232948,
                40.660369535680175
              ],
              [
                -73.81408940126198,
                40.66041989142047
              ],
              [
                -73.81462533969825,
                40.66050667365316
              ],
              [
                -73.81461414120095,
                40.660570670059734
              ],
              [
                -73.8145885643114,
                40.66057062883587
              ],
              [
                -73.81457011758246,
                40.66063459763916
              ],
              [
                -73.8146760376376,
                40.66065007155565
              ],
              [
                -73.8146779499051,
                40.6606194675287
              ],
              [
                -73.8146980430019,
                40.66062089038479
              ],
              [
                -73.81470180006028,
                40.66058333187362
              ],
              [
                -73.81467075459373,
                40.660579108725585
              ],
              [
                -73.81468148791375,
                40.66051521476173
              ],
              [
                -73.81515235583697,
                40.660594956353094
              ],
              [
                -73.81525403832491,
                40.66062117545136
              ],
              [
                -73.81535709770534,
                40.660644065666865
              ],
              [
                -73.8154613461441,
                40.6606635852798
              ],
              [
                -73.81605203034718,
                40.66071157420517
              ],
              [
                -73.8160422583988,
                40.66077608901589
              ],
              [
                -73.81599658826127,
                40.66077462431111
              ],
              [
                -73.81598180388713,
                40.66083581654858
              ],
              [
                -73.81616082356737,
                40.66084445023504
              ],
              [
                -73.81616087340889,
                40.66082636495243
              ],
              [
                -73.8161754844337,
                40.660827778788665
              ],
              [
                -73.81617376586361,
                40.66078881982252
              ],
              [
                -73.81613358334364,
                40.66078458246755
              ],
              [
                -73.8161395470037,
                40.66071525433433
              ],
              [
                -73.8164600471155,
                40.660732455016365
              ],
              [
                -73.81656759304917,
                40.66074251742007
              ],
              [
                -73.81667422498099,
                40.660757172739714
              ],
              [
                -73.81677960495773,
                40.66077637452772
              ],
              [
                -73.8168833989935,
                40.66080006192711
              ],
              [
                -73.81698527812745,
                40.66082815986422
              ],
              [
                -73.81717968323511,
                40.66087661147586
              ],
              [
                -73.81756727119134,
                40.66093673426202
              ],
              [
                -73.81756353412058,
                40.66099137710599
              ],
              [
                -73.81751421355285,
                40.66098851672879
              ],
              [
                -73.81750491431545,
                40.6610483262015
              ],
              [
                -73.81767296498694,
                40.66105972277706
              ],
              [
                -73.81767487969634,
                40.661027726546756
              ],
              [
                -73.81769314953247,
                40.661027755503554
              ],
              [
                -73.81769505282463,
                40.66099993305731
              ],
              [
                -73.81768043594998,
                40.66099990989073
              ],
              [
                -73.81768045496287,
                40.66099295347147
              ],
              [
                -73.81763843488952,
                40.66099288686266
              ],
              [
                -73.81764402333808,
                40.66094903243002
              ],
              [
                -73.81833879327824,
                40.66103689387314
              ],
              [
                -73.81840561146835,
                40.661061655905236
              ],
              [
                -73.81847543090659,
                40.66108102980283
              ],
              [
                -73.81854750471477,
                40.661094808317145
              ],
              [
                -73.8186210618963,
                40.66110284405486
              ],
              [
                -73.8186953155868,
                40.661105051055245
              ],
              [
                -73.81877746971344,
                40.66108638182687
              ],
              [
                -73.81885784899167,
                40.66106367002582
              ],
              [
                -73.81893611080119,
                40.66103701246259
              ],
              [
                -73.81901192154808,
                40.66100652276707
              ],
              [
                -73.81965725287023,
                40.660741919045414
              ],
              [
                -73.8196979434636,
                40.66080413687485
              ],
              [
                -73.81967417049665,
                40.66081244754961
              ],
              [
                -73.8197141990923,
                40.6608737261759
              ],
              [
                -73.81978369873667,
                40.66084601012296
              ],
              [
                -73.81977097679966,
                40.660820946705336
              ],
              [
                -73.81979109615273,
                40.660812630789344
              ],
              [
                -73.81976926718504,
                40.66077781467788
              ],
              [
                -73.819741832896,
                40.66078890177405
              ],
              [
                -73.81970074120684,
                40.660724086808706
              ],
              [
                -73.82130067723702,
                40.66006804473085
              ],
              [
                -73.82139373444608,
                40.66003693505065
              ],
              [
                -73.82148333467894,
                40.66000041109402
              ],
              [
                -73.82156893575569,
                40.65995869387322
              ],
              [
                -73.82159281364113,
                40.6600071077536
              ],
              [
                -73.82157269913499,
                40.660014033506634
              ],
              [
                -73.82160726243185,
                40.66006973820904
              ],
              [
                -73.82169139439411,
                40.66003508674308
              ],
              [
                -73.82167498880229,
                40.66002114790921
              ],
              [
                -73.82169328351475,
                40.66001143784335
              ],
              [
                -73.82166962703728,
                40.65997661925753
              ],
              [
                -73.82164220805245,
                40.65998214203958
              ],
              [
                -73.82161125510115,
                40.65993528559825
              ],
              [
                -73.8216632417594,
                40.65990368229463
              ],
              [
                -73.821713048647,
                40.659870104825664
              ],
              [
                -73.8221481173748,
                40.659537874423144
              ],
              [
                -73.8222169130284,
                40.65948338823207
              ],
              [
                -73.82227996040434,
                40.65942500793119
              ],
              [
                -73.82233688072874,
                40.659363084269756
              ],
              [
                -73.82238733204298,
                40.659297989284234
              ],
              [
                -73.8224310112556,
                40.65923011406356
              ],
              [
                -73.82246765596551,
                40.65915986639888
              ],
              [
                -73.82285416151373,
                40.65769698749797
              ],
              [
                -73.82340406982628,
                40.656080242093545
              ],
              [
                -73.82348381110747,
                40.655369912718626
              ],
              [
                -73.82321692539362,
                40.654237698696726
              ],
              [
                -73.82320066970243,
                40.654186123846564
              ],
              [
                -73.82314918373586,
                40.65402277106661
              ],
              [
                -73.82308822527926,
                40.6538574920286
              ],
              [
                -73.82301892265204,
                40.653694131402226
              ],
              [
                -73.82294137953392,
                40.65353293343766
              ],
              [
                -73.82285571192202,
                40.65337413914955
              ],
              [
                -73.82276204795913,
                40.65321798595695
              ],
              [
                -73.82270920020741,
                40.653108972418735
              ],
              [
                -73.82266421873223,
                40.6529979304159
              ],
              [
                -73.82262723675585,
                40.65288518892125
              ],
              [
                -73.82259836379991,
                40.65277108194029
              ],
              [
                -73.82257768536014,
                40.652655947523044
              ],
              [
                -73.82256526265546,
                40.652540126760734
              ],
              [
                -73.8225684993983,
                40.6524372355868
              ],
              [
                -73.82256361725032,
                40.652334382075075
              ],
              [
                -73.82255106707531,
                40.652234546280745
              ],
              [
                -73.82253086731384,
                40.65213544095626
              ],
              [
                -73.82250308723727,
                40.652037405796534
              ],
              [
                -73.82247190960453,
                40.651950939727485
              ],
              [
                -73.82243483427496,
                40.65186583461365
              ],
              [
                -73.82239196285094,
                40.65178232362784
              ],
              [
                -73.82225307066219,
                40.65146190149238
              ],
              [
                -73.82224652345761,
                40.651369268579025
              ],
              [
                -73.8222469921401,
                40.651276502232356
              ],
              [
                -73.8222544751245,
                40.651183910435485
              ],
              [
                -73.8222453562692,
                40.65113750048067
              ],
              [
                -73.82223097577003,
                40.65109186980007
              ],
              [
                -73.82221144247737,
                40.651047363752205
              ],
              [
                -73.8221902479438,
                40.651009623193396
              ],
              [
                -73.82216534681433,
                40.6509732193911
              ],
              [
                -73.82211698163465,
                40.650893526988895
              ],
              [
                -73.82207580081815,
                40.65081152658687
              ],
              [
                -73.82204199276579,
                40.65072759338713
              ],
              [
                -73.82201571214154,
                40.65064211143376
              ],
              [
                -73.82199874537184,
                40.650565012698614
              ],
              [
                -73.82198790363263,
                40.65048727582654
              ],
              [
                -73.82198322623395,
                40.65040918282821
              ],
              [
                -73.82198473012225,
                40.650331017004994
              ],
              [
                -73.82195737344777,
                40.65022071980836
              ],
              [
                -73.82192156855426,
                40.650111834886324
              ],
              [
                -73.82187743880839,
                40.65000473728069
              ],
              [
                -73.8218251362488,
                40.64989979587491
              ],
              [
                -73.82176484106242,
                40.649797372124084
              ],
              [
                -73.82169676096179,
                40.64969781880991
              ],
              [
                -73.82162113047113,
                40.64960147882572
              ],
              [
                -73.82153821011678,
                40.64950868399608
              ],
              [
                -73.821449210412,
                40.64938186688043
              ],
              [
                -73.82135239156109,
                40.649258417753316
              ],
              [
                -73.82124797279177,
                40.649138616087505
              ],
              [
                -73.82113619053361,
                40.64902273309736
              ],
              [
                -73.8210172978826,
                40.648911031124314
              ],
              [
                -73.82089156402674,
                40.648803763043496
              ],
              [
                -73.82075927363805,
                40.64870117169109
              ],
              [
                -73.82062072622644,
                40.64860348931487
              ],
              [
                -73.8204762354628,
                40.64851093704876
              ],
              [
                -73.82032612846861,
                40.648423724412154
              ],
              [
                -73.82015435122005,
                40.64826502638614
              ],
              [
                -73.81998826089563,
                40.64810285464577
              ],
              [
                -73.8198279784907,
                40.64793732737236
              ],
              [
                -73.81967362076583,
                40.64776856519206
              ],
              [
                -73.81952530016113,
                40.647596691087436
              ],
              [
                -73.81938312471513,
                40.64742183030759
              ],
              [
                -73.8192471979852,
                40.64724411027735
              ],
              [
                -73.81911761897327,
                40.64706366050396
              ],
              [
                -73.81903963830126,
                40.646962216113344
              ],
              [
                -73.81895364819273,
                40.64686459939906
              ],
              [
                -73.81885997144619,
                40.64677117676502
              ],
              [
                -73.81875895970646,
                40.64668229887117
              ],
              [
                -73.81865099214541,
                40.646598299317404
              ],
              [
                -73.81853647403962,
                40.64651949339183
              ],
              [
                -73.81841583524769,
                40.64644617688754
              ],
              [
                -73.81830607819735,
                40.64638698303705
              ],
              [
                -73.81819233025854,
                40.646332335083514
              ],
              [
                -73.81807491562687,
                40.646282388775674
              ],
              [
                -73.81795416894465,
                40.64623728646191
              ],
              [
                -73.81783043434912,
                40.64619715668482
              ],
              [
                -73.81772234126824,
                40.646166823977445
              ],
              [
                -73.81761254067318,
                40.64614028701629
              ],
              [
                -73.81750126216694,
                40.6461176012922
              ],
              [
                -73.81738678602395,
                40.646098524750855
              ],
              [
                -73.81727126904765,
                40.64608352388748
              ],
              [
                -73.81715496119858,
                40.64607263116145
              ],
              [
                -73.81704979799966,
                40.646080469980966
              ],
              [
                -73.8169441540211,
                40.64608209671973
              ],
              [
                -73.81683866081133,
                40.646077501652904
              ],
              [
                -73.81673394901813,
                40.64606671225031
              ],
              [
                -73.81663064461708,
                40.64604979301205
              ],
              [
                -73.81652936516892,
                40.646026845082275
              ],
              [
                -73.81643071612778,
                40.645998005645694
              ],
              [
                -73.81633528722062,
                40.64596344710644
              ],
              [
                -73.81625100793559,
                40.64592686421838
              ],
              [
                -73.81617034788053,
                40.64588581983268
              ],
              [
                -73.81609371342545,
                40.6458405207369
              ],
              [
                -73.81602149065631,
                40.64579119515376
              ],
              [
                -73.81595404342984,
                40.64573809159135
              ],
              [
                -73.81589171154134,
                40.645681477591566
              ],
              [
                -73.81495103596714,
                40.645269131959644
              ],
              [
                -73.81413907180028,
                40.64493357127087
              ],
              [
                -73.8139162641244,
                40.644861977076886
              ],
              [
                -73.81350685093165,
                40.644636653826915
              ],
              [
                -73.81329120817746,
                40.644576029430496
              ],
              [
                -73.81278138102107,
                40.64450580716118
              ],
              [
                -73.81203306986673,
                40.64430548288074
              ],
              [
                -73.81147284678906,
                40.64399223391306
              ],
              [
                -73.81114276103064,
                40.643701277467756
              ],
              [
                -73.81075488263984,
                40.64349789690538
              ],
              [
                -73.81011433589615,
                40.64334366400593
              ],
              [
                -73.8100216378948,
                40.64332134400355
              ],
              [
                -73.80918082188222,
                40.643029536203535
              ],
              [
                -73.80864189012154,
                40.642825898775506
              ],
              [
                -73.80746397407664,
                40.642193786972776
              ],
              [
                -73.80669543516171,
                40.64179249305545
              ],
              [
                -73.80641503675442,
                40.64174270506611
              ],
              [
                -73.80621256020946,
                40.641670094926205
              ],
              [
                -73.80607729812384,
                40.6416215870187
              ],
              [
                -73.80579685288461,
                40.64158823603389
              ],
              [
                -73.80565288923972,
                40.641620870776514
              ],
              [
                -73.80465071082949,
                40.640926963131896
              ],
              [
                -73.80296815476305,
                40.64028898605275
              ],
              [
                -73.80176556112649,
                40.639776735115646
              ],
              [
                -73.80044220412391,
                40.639150285134306
              ],
              [
                -73.80042438422666,
                40.63913531815223
              ],
              [
                -73.80015081910297,
                40.63890554276854
              ],
              [
                -73.79973096309048,
                40.63873655897618
              ],
              [
                -73.7992331459311,
                40.63843175211149
              ],
              [
                -73.79908367407819,
                40.63838264387314
              ],
              [
                -73.79889825186385,
                40.638442021780094
              ],
              [
                -73.79876716487976,
                40.63843271275026
              ],
              [
                -73.79874153985106,
                40.6384308929263
              ],
              [
                -73.79863471817262,
                40.638414423101985
              ],
              [
                -73.79854807076897,
                40.63833982104324
              ],
              [
                -73.79852101882554,
                40.63831652986206
              ],
              [
                -73.79849301895358,
                40.63831648083057
              ],
              [
                -73.79837852383065,
                40.63831627998196
              ],
              [
                -73.79822890601163,
                40.63831601776468
              ],
              [
                -73.7980154439074,
                40.63822337519174
              ],
              [
                -73.7978590131349,
                40.638119978036706
              ],
              [
                -73.79764548701372,
                40.63804904518251
              ],
              [
                -73.7974958850722,
                40.63804335394931
              ],
              [
                -73.7972537292323,
                40.6380157900091
              ],
              [
                -73.79706159379626,
                40.63793946591556
              ],
              [
                -73.79694783097632,
                40.63786328070896
              ],
              [
                -73.79690544683324,
                40.63774380016065
              ],
              [
                -73.7968128757759,
                40.637727354823575
              ],
              [
                -73.79668466495421,
                40.637716273245374
              ],
              [
                -73.79661356807392,
                40.63766730017196
              ],
              [
                -73.79634329520728,
                40.637514851228474
              ],
              [
                -73.79607999980418,
                40.63741126294715
              ],
              [
                -73.79590925887318,
                40.63732954788928
              ],
              [
                -73.7957956970553,
                40.637188231169866
              ],
              [
                -73.79558220892677,
                40.63710644015768
              ],
              [
                -73.79543259302967,
                40.63710617412033
              ],
              [
                -73.79515520090551,
                40.63695371052475
              ],
              [
                -73.79495701378538,
                40.636817189054696
              ],
              [
                -73.79487081891858,
                40.63675781386125
              ],
              [
                -73.79474296180882,
                40.636632753184195
              ],
              [
                -73.79459351375628,
                40.63657821123562
              ],
              [
                -73.79444917094999,
                40.636601845171256
              ],
              [
                -73.79442956693237,
                40.63660505591771
              ],
              [
                -73.79425869505887,
                40.63656675767252
              ],
              [
                -73.79410219077344,
                40.63649049296781
              ],
              [
                -73.79394525178239,
                40.63638816277205
              ],
              [
                -73.7939102137638,
                40.63636531615686
              ],
              [
                -73.79373951221346,
                40.63627274349698
              ],
              [
                -73.79359019918721,
                40.63617478001646
              ],
              [
                -73.79343373004328,
                40.636087659116605
              ],
              [
                -73.79330547380057,
                40.63609285693106
              ],
              [
                -73.79322527289374,
                40.636082854212745
              ],
              [
                -73.79308469922691,
                40.63606532197151
              ],
              [
                -73.79286950387751,
                40.63600972090085
              ],
              [
                -73.79222318018806,
                40.635890088139995
              ],
              [
                -73.7919746030624,
                40.635639975037286
              ],
              [
                -73.79188217210884,
                40.63558010508693
              ],
              [
                -73.79174707915567,
                40.635493020877604
              ],
              [
                -73.79122898504875,
                40.634857064034925
              ],
              [
                -73.79125088480927,
                40.6346888518818
              ],
              [
                -73.79130837076706,
                40.63453155904331
              ],
              [
                -73.7913087779411,
                40.63440130082736
              ],
              [
                -73.79120937878695,
                40.63429257005595
              ],
              [
                -73.79108132786324,
                40.63423263418287
              ],
              [
                -73.7908111127525,
                40.63431035575526
              ],
              [
                -73.79079284299529,
                40.634282043102445
              ],
              [
                -73.79075801012758,
                40.63426627205102
              ],
              [
                -73.79072700193291,
                40.6342464009223
              ],
              [
                -73.79070064104286,
                40.63422295700536
              ],
              [
                -73.79067962789144,
                40.634196563014534
              ],
              [
                -73.79066451992368,
                40.63416791982652
              ],
              [
                -73.79065571819211,
                40.63413778717811
              ],
              [
                -73.79068489718905,
                40.634130436849915
              ],
              [
                -73.7906881908633,
                40.634113166967225
              ],
              [
                -73.79070763314095,
                40.634111556760274
              ],
              [
                -73.79072501421285,
                40.63407868196885
              ],
              [
                -73.79076283988275,
                40.63406887889643
              ],
              [
                -73.79075209788766,
                40.63405076003948
              ],
              [
                -73.79069485088321,
                40.63405559206403
              ],
              [
                -73.79067758390491,
                40.63405227012881
              ],
              [
                -73.79068301089686,
                40.63404323077711
              ],
              [
                -73.79073165223517,
                40.63402768906849
              ],
              [
                -73.7907587809813,
                40.63398578260416
              ],
              [
                -73.79075676815526,
                40.63396126718425
              ],
              [
                -73.79076604011865,
                40.63395567894366
              ],
              [
                -73.79079607598177,
                40.63393756976984
              ],
              [
                -73.79080029422181,
                40.6339277789571
              ],
              [
                -73.79080231893697,
                40.63391759019434
              ],
              [
                -73.79080209298559,
                40.63390728711586
              ],
              [
                -73.79079962300845,
                40.6338971559493
              ],
              [
                -73.79079497784153,
                40.633887478033984
              ],
              [
                -73.790788286062,
                40.63387852290435
              ],
              [
                -73.79077973419163,
                40.63387053905073
              ],
              [
                -73.79076956011139,
                40.633863748957
              ],
              [
                -73.79075804675547,
                40.63385834101589
              ],
              [
                -73.790747509173,
                40.63385581122462
              ],
              [
                -73.79073659000414,
                40.63385453100076
              ],
              [
                -73.79072554203712,
                40.63385452921195
              ],
              [
                -73.79071462205351,
                40.63385580687652
              ],
              [
                -73.79070408384901,
                40.633858333163005
              ],
              [
                -73.79069417178903,
                40.63386205023439
              ],
              [
                -73.79068557898376,
                40.63386658513973
              ],
              [
                -73.79067793184666,
                40.63387201467103
              ],
              [
                -73.79067138886722,
                40.63387822489607
              ],
              [
                -73.79066608605616,
                40.633885087760575
              ],
              [
                -73.79063822503424,
                40.633908298003504
              ],
              [
                -73.79060632203662,
                40.63392826107031
              ],
              [
                -73.79057102300065,
                40.63394457288379
              ],
              [
                -73.79053304224058,
                40.63395690282931
              ],
              [
                -73.79049314921532,
                40.63396500109682
              ],
              [
                -73.79038792704422,
                40.63393720424558
              ],
              [
                -73.79038485091743,
                40.63395856627045
              ],
              [
                -73.79028957023738,
                40.63395634688558
              ],
              [
                -73.7902721961424,
                40.63396911009475
              ],
              [
                -73.79025234611903,
                40.6339795701863
              ],
              [
                -73.79023054082906,
                40.633987453065025
              ],
              [
                -73.79020735106536,
                40.63399255191643
              ],
              [
                -73.79018338575843,
                40.63399473291488
              ],
              [
                -73.7901592720115,
                40.63399393897522
              ],
              [
                -73.79006601986006,
                40.633994937908
              ],
              [
                -73.78997313912213,
                40.634001353957565
              ],
              [
                -73.78988117200988,
                40.634013149923305
              ],
              [
                -73.78979065407431,
                40.63403025692456
              ],
              [
                -73.78970211314221,
                40.63405257510062
              ],
              [
                -73.7896527891609,
                40.63407485715359
              ],
              [
                -73.78960869986732,
                40.6341027908895
              ],
              [
                -73.78957094985863,
                40.63413567520593
              ],
              [
                -73.78954048577843,
                40.63417268694484
              ],
              [
                -73.78951807095375,
                40.63421289722418
              ],
              [
                -73.78952088608465,
                40.6342225301659
              ],
              [
                -73.78952162816965,
                40.63423238293962
              ],
              [
                -73.78952027802049,
                40.63424219793791
              ],
              [
                -73.78951687076218,
                40.63425171904371
              ],
              [
                -73.78951149487399,
                40.63426069797435
              ],
              [
                -73.7895042910262,
                40.634268900026925
              ],
              [
                -73.78949544725019,
                40.634276111137304
              ],
              [
                -73.78948519498256,
                40.63428214317577
              ],
              [
                -73.7894261901355,
                40.63425419441593
              ],
              [
                -73.7894638409377,
                40.634150008642116
              ],
              [
                -73.78946908512059,
                40.63413253664237
              ],
              [
                -73.78947031687677,
                40.63411463793803
              ],
              [
                -73.78946749894477,
                40.63409684396085
              ],
              [
                -73.78946071511484,
                40.63407968209371
              ],
              [
                -73.78945016698914,
                40.63406366184335
              ],
              [
                -73.78943616699877,
                40.6340492577919
              ],
              [
                -73.7892270198415,
                40.63394725414594
              ],
              [
                -73.78832339420084,
                40.63342507953271
              ],
              [
                -73.78779972389135,
                40.63302419014573
              ],
              [
                -73.78743060093569,
                40.632576536786054
              ],
              [
                -73.78690666692243,
                40.632261900864485
              ],
              [
                -73.78649505508018,
                40.632229771423916
              ],
              [
                -73.78593974683072,
                40.632071906585665
              ],
              [
                -73.78570771785074,
                40.63209452405763
              ],
              [
                -73.78570293720223,
                40.632094989240336
              ],
              [
                -73.78501190495508,
                40.631728189090815
              ],
              [
                -73.78434764221069,
                40.63137332021674
              ],
              [
                -73.78377728687502,
                40.63063411582448
              ],
              [
                -73.78264543417806,
                40.630247968544396
              ],
              [
                -73.78284335187118,
                40.62940570608821
              ],
              [
                -73.78346558387602,
                40.628118140878534
              ],
              [
                -73.78487888720439,
                40.62663147085399
              ],
              [
                -73.78822856205788,
                40.623765128776704
              ],
              [
                -73.78843028253671,
                40.623639390078246
              ],
              [
                -73.78880577116429,
                40.623376736173
              ],
              [
                -73.78896262786533,
                40.62328635703604
              ],
              [
                -73.78911343183823,
                40.62312915883663
              ],
              [
                -73.78918594317895,
                40.62300669798104
              ],
              [
                -73.78919528322274,
                40.62299092280559
              ],
              [
                -73.78925824323107,
                40.62288605391918
              ],
              [
                -73.78949653224412,
                40.622746646107935
              ],
              [
                -73.78419483298926,
                40.62089266995511
              ],
              [
                -73.78136195895802,
                40.62484274605734
              ],
              [
                -73.78126157179449,
                40.6250414971426
              ],
              [
                -73.78115275085243,
                40.6252376549018
              ],
              [
                -73.78103561120142,
                40.62543101099439
              ],
              [
                -73.7809102778173,
                40.62562136106153
              ],
              [
                -73.78077688201479,
                40.625808503834996
              ],
              [
                -73.78063556587867,
                40.625992240243676
              ],
              [
                -73.78048647818801,
                40.62617237701872
              ],
              [
                -73.78032977783832,
                40.626348722796536
              ],
              [
                -73.78016562975726,
                40.626521090219555
              ],
              [
                -73.77999420781757,
                40.62668929803533
              ],
              [
                -73.77981569324412,
                40.6268531671932
              ],
              [
                -73.77963027601938,
                40.627012524938706
              ],
              [
                -73.77943815178199,
                40.6271672019052
              ],
              [
                -73.77923952322412,
                40.62731703420362
              ],
              [
                -73.77909939508251,
                40.62738208288988
              ],
              [
                -73.77895497594699,
                40.62744143382485
              ],
              [
                -73.77880666416571,
                40.62749492308148
              ],
              [
                -73.7786548699618,
                40.62754240382372
              ],
              [
                -73.77850001186737,
                40.62758374400991
              ],
              [
                -73.77834251714502,
                40.6276188300741
              ],
              [
                -73.77818282119772,
                40.627647565083585
              ],
              [
                -73.77802136496797,
                40.62766986987261
              ],
              [
                -73.77785859382915,
                40.62768568215203
              ],
              [
                -73.77769495697099,
                40.627694959094136
              ],
              [
                -73.77753090677732,
                40.627697674893184
              ],
              [
                -73.77736689520371,
                40.6276938218004
              ],
              [
                -73.77720337615169,
                40.62768341013488
              ],
              [
                -73.77704080084246,
                40.62766646976843
              ],
              [
                -73.77687961719566,
                40.62764304608586
              ],
              [
                -73.77672027120762,
                40.627613204921
              ],
              [
                -73.77656320283751,
                40.627577027966574
              ],
              [
                -73.77640884490044,
                40.62753461516095
              ],
              [
                -73.77619349302057,
                40.62743160023667
              ],
              [
                -73.77598317496873,
                40.627322716064306
              ],
              [
                -73.7757781659672,
                40.627208104951144
              ],
              [
                -73.77557873475992,
                40.627087916954245
              ],
              [
                -73.77538514193587,
                40.62696230978304
              ],
              [
                -73.77519764175813,
                40.62683144719504
              ],
              [
                -73.77501647899835,
                40.62669550188856
              ],
              [
                -73.77484189177467,
                40.62655465089077
              ],
              [
                -73.77467410789808,
                40.62640907894024
              ],
              [
                -73.7745133472214,
                40.62625897686671
              ],
              [
                -73.77435982099605,
                40.62610454096607
              ],
              [
                -73.7742137292342,
                40.62594597387124
              ],
              [
                -73.77407526407741,
                40.62578348292003
              ],
              [
                -73.77368363678957,
                40.62533150208693
              ],
              [
                -73.77365915317526,
                40.62525912903939
              ],
              [
                -73.77362449683453,
                40.625236842888626
              ],
              [
                -73.77364341420143,
                40.62520859101682
              ],
              [
                -73.7735789731014,
                40.62516133097599
              ],
              [
                -73.77349461607284,
                40.62514256918544
              ],
              [
                -73.77348560409436,
                40.62511314810922
              ],
              [
                -73.77348616522502,
                40.625085118163526
              ],
              [
                -73.77348668402476,
                40.62505922810143
              ],
              [
                -73.77343972988045,
                40.625027550859066
              ],
              [
                -73.77336936296258,
                40.62506619419173
              ],
              [
                -73.7732353722024,
                40.624994162238465
              ],
              [
                -73.77300150699932,
                40.624839462974855
              ],
              [
                -73.77266148887917,
                40.62457090378069
              ],
              [
                -73.77255536221206,
                40.624448926834454
              ],
              [
                -73.77186033009272,
                40.62376460624241
              ],
              [
                -73.77173897590608,
                40.6236057260367
              ],
              [
                -73.77172760292548,
                40.62358502801516
              ],
              [
                -73.7716552250971,
                40.62348170082506
              ],
              [
                -73.77160292680045,
                40.623406511065454
              ],
              [
                -73.77155510482564,
                40.623362218098244
              ],
              [
                -73.77145620797471,
                40.62329297296164
              ],
              [
                -73.77143883682004,
                40.62328461685248
              ],
              [
                -73.7714187722017,
                40.62328126421179
              ],
              [
                -73.77135751084268,
                40.62329765723661
              ],
              [
                -73.77129441699431,
                40.62328434386562
              ],
              [
                -73.77125194997689,
                40.62328477803947
              ],
              [
                -73.77117239021177,
                40.623244064377566
              ],
              [
                -73.77114623114845,
                40.62322466820464
              ],
              [
                -73.77109995894352,
                40.623230534066145
              ],
              [
                -73.77108337112617,
                40.62325564010893
              ],
              [
                -73.77108646010738,
                40.62328159298683
              ],
              [
                -73.77110277385513,
                40.62330302315218
              ],
              [
                -73.77109204879525,
                40.62332852410139
              ],
              [
                -73.77106841006369,
                40.62333608481959
              ],
              [
                -73.77101792504749,
                40.62332031219918
              ],
              [
                -73.77098537788825,
                40.62332377402939
              ],
              [
                -73.77096875214411,
                40.623340605806035
              ],
              [
                -73.77092465895865,
                40.62336690114088
              ],
              [
                -73.77086001275374,
                40.623330538186465
              ],
              [
                -73.77082069703974,
                40.62328299797239
              ],
              [
                -73.77083838283006,
                40.623203191955646
              ],
              [
                -73.77088576897395,
                40.62315869629415
              ],
              [
                -73.77091145703879,
                40.623078079366785
              ],
              [
                -73.77089044820052,
                40.623004198992774
              ],
              [
                -73.77083934993311,
                40.62298885323997
              ],
              [
                -73.77081128924291,
                40.622949747147885
              ],
              [
                -73.7708327022184,
                40.62291155179134
              ],
              [
                -73.77086739903149,
                40.622826343061796
              ],
              [
                -73.77089021348017,
                40.622755565035
              ],
              [
                -73.770928432952,
                40.62270219684788
              ],
              [
                -73.77095099724231,
                40.62265230601101
              ],
              [
                -73.77090735485449,
                40.622571409475164
              ],
              [
                -73.7709249541415,
                40.622507033172646
              ],
              [
                -73.77090364093927,
                40.62244748380959
              ],
              [
                -73.7709149992296,
                40.62240388857772
              ],
              [
                -73.77097308310022,
                40.62233796390867
              ],
              [
                -73.77102862072397,
                40.62226790907031
              ],
              [
                -73.77104111915502,
                40.62221150832388
              ],
              [
                -73.77107118086863,
                40.62213676315836
              ],
              [
                -73.77114355388717,
                40.62204241575441
              ],
              [
                -73.7712382622036,
                40.62189031307072
              ],
              [
                -73.77130563307692,
                40.621776107786324
              ],
              [
                -73.77141068794693,
                40.621682326928536
              ],
              [
                -73.7714760930544,
                40.621598272863764
              ],
              [
                -73.77156382711908,
                40.62136052739377
              ],
              [
                -73.77171699423648,
                40.62096167889895
              ],
              [
                -73.77171003007592,
                40.620669263211866
              ],
              [
                -73.77165454611215,
                40.62041642715901
              ],
              [
                -73.77160377191542,
                40.62034324789983
              ],
              [
                -73.77148534799002,
                40.620232974991936
              ],
              [
                -73.77138995416946,
                40.62015301702538
              ],
              [
                -73.77128160285079,
                40.620084812874886
              ],
              [
                -73.77109809419906,
                40.62000538117142
              ],
              [
                -73.7709437441232,
                40.62000134175697
              ],
              [
                -73.77077016777163,
                40.620031182154996
              ],
              [
                -73.7706709380609,
                40.620066785965015
              ],
              [
                -73.77058952001528,
                40.62011463920456
              ],
              [
                -73.77053739283372,
                40.62014942710828
              ],
              [
                -73.77049319005005,
                40.620177032209995
              ],
              [
                -73.77046155012042,
                40.62019777912229
              ],
              [
                -73.77044322286108,
                40.62022301322432
              ],
              [
                -73.77041082116838,
                40.620259519199514
              ],
              [
                -73.77036668317228,
                40.62029867382739
              ],
              [
                -73.77031005582694,
                40.620370583789295
              ],
              [
                -73.77023670585152,
                40.62048289488686
              ],
              [
                -73.77014985520455,
                40.620663607486684
              ],
              [
                -73.77014121422373,
                40.62070154406916
              ],
              [
                -73.77014141903945,
                40.62073182298346
              ],
              [
                -73.77014161704857,
                40.62074716718853
              ],
              [
                -73.77013119683447,
                40.62076056097611
              ],
              [
                -73.77011033520134,
                40.620770481101474
              ],
              [
                -73.77009366424308,
                40.62079245192024
              ],
              [
                -73.77008243996472,
                40.620823231002696
              ],
              [
                -73.77007920110442,
                40.620841729494316
              ],
              [
                -73.77009088724724,
                40.62085971075153
              ],
              [
                -73.77009538383358,
                40.62086914843996
              ],
              [
                -73.7700953110428,
                40.62088849793653
              ],
              [
                -73.77008467078387,
                40.62091292716778
              ],
              [
                -73.77006954778791,
                40.62092847181169
              ],
              [
                -73.77002791372077,
                40.62095229079749
              ],
              [
                -73.77000686475955,
                40.6209647748278
              ],
              [
                -73.76998637178036,
                40.620977785098496
              ],
              [
                -73.76996645723942,
                40.62099130876381
              ],
              [
                -73.76994714476979,
                40.62100532705097
              ],
              [
                -73.76991315213583,
                40.62102052400654
              ],
              [
                -73.76988324717225,
                40.621036890620935
              ],
              [
                -73.76986000090845,
                40.62104568820954
              ],
              [
                -73.7698350719695,
                40.62106206504972
              ],
              [
                -73.76980738698879,
                40.62106872111419
              ],
              [
                -73.76977817279169,
                40.621087255234855
              ],
              [
                -73.76975585615149,
                40.62110662019634
              ],
              [
                -73.76974528683073,
                40.62112588175851
              ],
              [
                -73.76981558098718,
                40.62137735099985
              ],
              [
                -73.76982199110604,
                40.62140497283596
              ],
              [
                -73.76981912694133,
                40.62144047489153
              ],
              [
                -73.76980535702252,
                40.62145924592373
              ],
              [
                -73.7698025430636,
                40.62148012780239
              ],
              [
                -73.76980523479268,
                40.621494753975306
              ],
              [
                -73.76980516277662,
                40.6215156405966
              ],
              [
                -73.76981332097934,
                40.621534454247495
              ],
              [
                -73.76980016504409,
                40.62155529095859
              ],
              [
                -73.76980240607485,
                40.62156032598795
              ],
              [
                -73.76980392714529,
                40.62156551453018
              ],
              [
                -73.76980471388426,
                40.62157079783143
              ],
              [
                -73.76980475721858,
                40.62157611314453
              ],
              [
                -73.76980405604107,
                40.6215814028983
              ],
              [
                -73.76980261813513,
                40.62158660477592
              ],
              [
                -73.76980046012629,
                40.62159166055971
              ],
              [
                -73.76979760714596,
                40.62159651204383
              ],
              [
                -73.7697940909038,
                40.62160110277698
              ],
              [
                -73.7697899512098,
                40.62160538342687
              ],
              [
                -73.76974531116765,
                40.62163369969716
              ],
              [
                -73.76972974597128,
                40.62166282317238
              ],
              [
                -73.76972588182295,
                40.62169697876198
              ],
              [
                -73.76972852877415,
                40.62170495276344
              ],
              [
                -73.76973034323274,
                40.621713061032196
              ],
              [
                -73.76973131423883,
                40.62172125401087
              ],
              [
                -73.76973143591486,
                40.62172947889159
              ],
              [
                -73.76973070596648,
                40.621737685461994
              ],
              [
                -73.76972913095265,
                40.621745822842534
              ],
              [
                -73.76972671714512,
                40.621753840747886
              ],
              [
                -73.76972348458568,
                40.62176168823273
              ],
              [
                -73.76971945081115,
                40.621769318775804
              ],
              [
                -73.7697146400569,
                40.62177668277605
              ],
              [
                -73.7697090820228,
                40.621783734979
              ],
              [
                -73.7696945200498,
                40.62179608777269
              ],
              [
                -73.76967925648756,
                40.62180793882556
              ],
              [
                -73.76966332105941,
                40.621819264103735
              ],
              [
                -73.76964674485194,
                40.62183004291749
              ],
              [
                -73.76962955995242,
                40.62184025299343
              ],
              [
                -73.7696118017596,
                40.621849876738246
              ],
              [
                -73.76960480513839,
                40.621857766909976
              ],
              [
                -73.76959851486356,
                40.62186599407178
              ],
              [
                -73.76959295597149,
                40.62187452257052
              ],
              [
                -73.76958815698806,
                40.62188331317089
              ],
              [
                -73.769584135165,
                40.621892329040996
              ],
              [
                -73.76958091284605,
                40.62190152884764
              ],
              [
                -73.76957849882942,
                40.621910870994576
              ],
              [
                -73.76957690782679,
                40.62192031731396
              ],
              [
                -73.76957614588838,
                40.62192982119036
              ],
              [
                -73.76957621684247,
                40.621939342023985
              ],
              [
                -73.76956651706179,
                40.621958144931554
              ],
              [
                -73.76955766697007,
                40.62197718805461
              ],
              [
                -73.76954967604951,
                40.6219964517677
              ],
              [
                -73.76954255124781,
                40.62201591160216
              ],
              [
                -73.76953630587634,
                40.622035544087204
              ],
              [
                -73.76953094211446,
                40.62205533001469
              ],
              [
                -73.76952647084715,
                40.62207524215706
              ],
              [
                -73.76952289183043,
                40.62209526004421
              ],
              [
                -73.7695246119857,
                40.62209905377691
              ],
              [
                -73.76952555122534,
                40.62210300121164
              ],
              [
                -73.769525688104,
                40.622107012174965
              ],
              [
                -73.76952502101452,
                40.622110992589775
              ],
              [
                -73.76952356310456,
                40.6221148471451
              ],
              [
                -73.76952135011707,
                40.622118488229816
              ],
              [
                -73.76951843276731,
                40.62212182875304
              ],
              [
                -73.76951488202643,
                40.6221247900876
              ],
              [
                -73.76951077775935,
                40.62212730421767
              ],
              [
                -73.76950543380192,
                40.62212116115477
              ],
              [
                -73.76950077677073,
                40.62211470104368
              ],
              [
                -73.76949683802994,
                40.6221079679464
              ],
              [
                -73.76949364722697,
                40.62210101001647
              ],
              [
                -73.76949122522518,
                40.622093873809995
              ],
              [
                -73.76948958785172,
                40.62208660989106
              ],
              [
                -73.76948200710078,
                40.62201875403866
              ],
              [
                -73.76947907599535,
                40.62201620313077
              ],
              [
                -73.76947558477929,
                40.62201410015047
              ],
              [
                -73.76947165086743,
                40.62201251405936
              ],
              [
                -73.76946740590363,
                40.62201149996388
              ],
              [
                -73.76946298798225,
                40.62201108808022
              ],
              [
                -73.76945854705099,
                40.62201129393001
              ],
              [
                -73.7694542288964,
                40.622012110141064
              ],
              [
                -73.76945017624116,
                40.622013510119785
              ],
              [
                -73.769446523782,
                40.62201544821602
              ],
              [
                -73.76944339413559,
                40.62201785863213
              ],
              [
                -73.76944088895685,
                40.62202066101585
              ],
              [
                -73.76943909504818,
                40.62202376430043
              ],
              [
                -73.76943806796594,
                40.62202706293446
              ],
              [
                -73.76942980406623,
                40.62204087191083
              ],
              [
                -73.76942082423848,
                40.62205441908239
              ],
              [
                -73.76941114283879,
                40.62206768115219
              ],
              [
                -73.7694007730429,
                40.622080640839044
              ],
              [
                -73.76938973517645,
                40.622093273100624
              ],
              [
                -73.76937804391703,
                40.62210556006948
              ],
              [
                -73.76936571819242,
                40.62211748196484
              ],
              [
                -73.76935277893075,
                40.62212901883874
              ],
              [
                -73.76933924483245,
                40.622140155087976
              ],
              [
                -73.76932513919682,
                40.6221508700141
              ],
              [
                -73.7692914139998,
                40.62217706404205
              ],
              [
                -73.76925702092481,
                40.62220274975544
              ],
              [
                -73.76922197518535,
                40.62222791597615
              ],
              [
                -73.7691862890147,
                40.62225255377323
              ],
              [
                -73.7691499749121,
                40.622276654806186
              ],
              [
                -73.76911304704414,
                40.62230020764196
              ],
              [
                -73.7688844388137,
                40.62247043994169
              ],
              [
                -73.76887053516842,
                40.622476881206246
              ],
              [
                -73.76885705482441,
                40.62248382393223
              ],
              [
                -73.76884402603831,
                40.62249125277827
              ],
              [
                -73.7688314838828,
                40.62249914814575
              ],
              [
                -73.76881945081844,
                40.62250749510021
              ],
              [
                -73.76880795788237,
                40.622516271950985
              ],
              [
                -73.76879703317857,
                40.622525459928845
              ],
              [
                -73.768786699849,
                40.62253503624783
              ],
              [
                -73.76877698185318,
                40.622544980795055
              ],
              [
                -73.76876790122411,
                40.62255526903334
              ],
              [
                -73.76875948088907,
                40.622565876836134
              ],
              [
                -73.7687517382295,
                40.62257678208441
              ],
              [
                -73.76874469313805,
                40.622587957224546
              ],
              [
                -73.76873319111314,
                40.62260292984728
              ],
              [
                -73.76872240107345,
                40.622618204878385
              ],
              [
                -73.76871233509625,
                40.622633765034614
              ],
              [
                -73.76870300681249,
                40.622649590194165
              ],
              [
                -73.7686944291862,
                40.622665658897695
              ],
              [
                -73.76868661087502,
                40.62268195310872
              ],
              [
                -73.76867956296898,
                40.62269844877488
              ],
              [
                -73.76867329605707,
                40.622715126192546
              ],
              [
                -73.76866781708627,
                40.62273196481157
              ],
              [
                -73.76866313206479,
                40.62274893999482
              ],
              [
                -73.7686592480413,
                40.622766035037955
              ],
              [
                -73.76865226893555,
                40.62280618584328
              ],
              [
                -73.76864456009086,
                40.62284625905277
              ],
              [
                -73.76863612400902,
                40.62288624781495
              ],
              [
                -73.76862696197361,
                40.62292614294331
              ],
              [
                -73.76861707399566,
                40.62296593800334
              ],
              [
                -73.768606446225,
                40.62298548443991
              ],
              [
                -73.76859655119661,
                40.623005252928714
              ],
              [
                -73.76858739678129,
                40.6230252287764
              ],
              [
                -73.76857899217852,
                40.62304539377934
              ],
              [
                -73.76857134278468,
                40.62306573198389
              ],
              [
                -73.76856445685357,
                40.623086228944665
              ],
              [
                -73.76855833596699,
                40.623106865864834
              ],
              [
                -73.76855567951644,
                40.623122850776326
              ],
              [
                -73.76855382388167,
                40.62313889999025
              ],
              [
                -73.76855277292091,
                40.62315499186854
              ],
              [
                -73.7685525281643,
                40.623171102853874
              ],
              [
                -73.76855308910385,
                40.62318720888311
              ],
              [
                -73.76855326086714,
                40.62318922909009
              ],
              [
                -73.76856504520188,
                40.62325181221814
              ],
              [
                -73.76858406027465,
                40.62331335403188
              ],
              [
                -73.76861015682157,
                40.623373370029945
              ],
              [
                -73.76864312823385,
                40.62343138820988
              ],
              [
                -73.76863983616859,
                40.623479034920074
              ],
              [
                -73.76866759912812,
                40.623588691870225
              ],
              [
                -73.76877022903915,
                40.62375091782677
              ],
              [
                -73.76876163024419,
                40.62376114122832
              ],
              [
                -73.76875545621763,
                40.6237723360525
              ],
              [
                -73.7687518891432,
                40.62378417096829
              ],
              [
                -73.7687510342038,
                40.62379629619006
              ],
              [
                -73.76875291702562,
                40.623808354139896
              ],
              [
                -73.76875748130531,
                40.62381998823742
              ],
              [
                -73.76883530214275,
                40.62392259876787
              ],
              [
                -73.76883850684732,
                40.62392635993896
              ],
              [
                -73.76884085518273,
                40.62393047181581
              ],
              [
                -73.76884228415045,
                40.62393482188034
              ],
              [
                -73.76884275478271,
                40.62393929186669
              ],
              [
                -73.76884225317274,
                40.62394375987632
              ],
              [
                -73.76884079414897,
                40.62394810403742
              ],
              [
                -73.76883841708924,
                40.623952206163196
              ],
              [
                -73.76883518687913,
                40.62395595486489
              ],
              [
                -73.76882288900899,
                40.62396807916797
              ],
              [
                -73.76881348095439,
                40.62398162280006
              ],
              [
                -73.7688072371525,
                40.62399618891719
              ],
              [
                -73.76880434081289,
                40.62401135223113
              ],
              [
                -73.7688048757524,
                40.62402667022669
              ],
              [
                -73.76880882615856,
                40.624041695133656
              ],
              [
                -73.76881607781758,
                40.6240559879643
              ],
              [
                -73.76882345004064,
                40.62406991882953
              ],
              [
                -73.76882796113601,
                40.62408454087465
              ],
              [
                -73.7688295063075,
                40.624099515096596
              ],
              [
                -73.76882805014026,
                40.62411449490773
              ],
              [
                -73.76882362601532,
                40.62412913216011
              ],
              [
                -73.76881633621885,
                40.62414308817167
              ],
              [
                -73.76880635075322,
                40.62415603916029
              ],
              [
                -73.76879744811067,
                40.62416232205967
              ],
              [
                -73.76878992611906,
                40.624169577061416
              ],
              [
                -73.76878396502812,
                40.62417763011906
              ],
              [
                -73.76877970736207,
                40.62418628810991
              ],
              [
                -73.7687772562009,
                40.62419534315399
              ],
              [
                -73.76877666945663,
                40.62420457810708
              ],
              [
                -73.76877796216628,
                40.62421377116687
              ],
              [
                -73.76878110232373,
                40.62422270203292
              ],
              [
                -73.76878399909147,
                40.62428942195741
              ],
              [
                -73.76878577709435,
                40.62430056597931
              ],
              [
                -73.7687852271618,
                40.624311784112315
              ],
              [
                -73.76878236316831,
                40.62432279608776
              ],
              [
                -73.76877725642362,
                40.62433332621679
              ],
              [
                -73.76877003527649,
                40.62434311183308
              ],
              [
                -73.76876088001904,
                40.62435190708572
              ],
              [
                -73.76875001912778,
                40.62435949299941
              ],
              [
                -73.76873772440149,
                40.62436567922353
              ],
              [
                -73.76872430356536,
                40.62437031140525
              ],
              [
                -73.76868770421753,
                40.624408087171446
              ],
              [
                -73.76865793092445,
                40.62444919712085
              ],
              [
                -73.76863549892782,
                40.62449292988524
              ],
              [
                -73.76862079691871,
                40.6245385278925
              ],
              [
                -73.7686203694395,
                40.62455025181579
              ],
              [
                -73.76862069515812,
                40.624561977247375
              ],
              [
                -73.7686217709152,
                40.62457367644524
              ],
              [
                -73.76862359415976,
                40.6245853219183
              ],
              [
                -73.7686171489849,
                40.62466282801152
              ],
              [
                -73.76860408710382,
                40.62473271820611
              ],
              [
                -73.76856340725851,
                40.6248012361289
              ],
              [
                -73.76851422621908,
                40.6248796788312
              ],
              [
                -73.76850023949467,
                40.624896628043146
              ],
              [
                -73.76845860808363,
                40.624940961061434
              ],
              [
                -73.76844172050497,
                40.624961427751785
              ],
              [
                -73.76841808484274,
                40.62498871281703
              ],
              [
                -73.7683663209338,
                40.62504498390169
              ],
              [
                -73.76834605616219,
                40.625069713843544
              ],
              [
                -73.7683257825466,
                40.62509700578745
              ],
              [
                -73.76831112016495,
                40.62512260015108
              ],
              [
                -73.76830546187118,
                40.62513796411724
              ],
              [
                -73.768300915086,
                40.625155891881526
              ],
              [
                -73.76829397897339,
                40.625216523777766
              ],
              [
                -73.76829278930508,
                40.62523616724309
              ],
              [
                -73.76829038175273,
                40.62528399579814
              ],
              [
                -73.76828692619806,
                40.62531046815623
              ],
              [
                -73.76828239779898,
                40.62532327141245
              ],
              [
                -73.7682767508157,
                40.62533521875825
              ],
              [
                -73.76826773786703,
                40.62534801224389
              ],
              [
                -73.76825648311167,
                40.62536080196984
              ],
              [
                -73.76823286329876,
                40.62538296275592
              ],
              [
                -73.76816878309884,
                40.62543493771688
              ],
              [
                -73.76815415926434,
                40.62544942902406
              ],
              [
                -73.7681417801081,
                40.6254630701424
              ],
              [
                -73.76793346888844,
                40.625726586842795
              ],
              [
                -73.7678510332581,
                40.62582695479091
              ],
              [
                -73.76778148220518,
                40.625911632928016
              ],
              [
                -73.76777697413748,
                40.62591845813745
              ],
              [
                -73.767773587144,
                40.625925284225175
              ],
              [
                -73.76776904921671,
                40.625940650225814
              ],
              [
                -73.76776898701145,
                40.62595858700787
              ],
              [
                -73.76777222017238,
                40.625996177784245
              ],
              [
                -73.7677721639386,
                40.62601240617546
              ],
              [
                -73.76775752719107,
                40.62611336296656
              ],
              [
                -73.76772095788968,
                40.62619063724528
              ],
              [
                -73.76751516687348,
                40.6264544302241
              ],
              [
                -73.76749830998838,
                40.62647904614118
              ],
              [
                -73.76748217581073,
                40.62650394175314
              ],
              [
                -73.76746677717722,
                40.62652910437782
              ],
              [
                -73.76745211836821,
                40.62655452390438
              ],
              [
                -73.76743820794681,
                40.626580186452124
              ],
              [
                -73.7674250514114,
                40.62660607845947
              ],
              [
                -73.76741265731175,
                40.62663218711531
              ],
              [
                -73.76740103118654,
                40.626658500536415
              ],
              [
                -73.76735257584863,
                40.62673069338544
              ],
              [
                -73.76730493067119,
                40.62680319912287
              ],
              [
                -73.76725810051867,
                40.626876010555655
              ],
              [
                -73.76721208650336,
                40.626949124504065
              ],
              [
                -73.76716689546122,
                40.62702253595713
              ],
              [
                -73.7671446716013,
                40.627081365255556
              ],
              [
                -73.767123149695,
                40.62714034540716
              ],
              [
                -73.76710233327698,
                40.62719947223159
              ],
              [
                -73.76709732545605,
                40.627213595023214
              ],
              [
                -73.76709144868353,
                40.62722752289317
              ],
              [
                -73.76708471604509,
                40.627241223366795
              ],
              [
                -73.7670771412838,
                40.62725466464172
              ],
              [
                -73.76706874383655,
                40.627267821627285
              ],
              [
                -73.76705953921358,
                40.627280660179075
              ],
              [
                -73.76705201585456,
                40.62728579746587
              ],
              [
                -73.76704506722791,
                40.62729138606778
              ],
              [
                -73.76703874403829,
                40.62729738822611
              ],
              [
                -73.76703308314185,
                40.62730376498135
              ],
              [
                -73.76702812493608,
                40.627310470847334
              ],
              [
                -73.76702390210798,
                40.62731746434337
              ],
              [
                -73.76702044297221,
                40.62732469694326
              ],
              [
                -73.7670177727647,
                40.627332120114744
              ],
              [
                -73.76701590397035,
                40.627339684797235
              ],
              [
                -73.76701485545138,
                40.62734734177523
              ],
              [
                -73.76701462915281,
                40.627355037778365
              ],
              [
                -73.76701522987504,
                40.62736272054742
              ],
              [
                -73.76701384602596,
                40.62738448951084
              ],
              [
                -73.76701346102084,
                40.627406280769236
              ],
              [
                -73.76701407736509,
                40.62742806970117
              ],
              [
                -73.76701569515575,
                40.62744982883179
              ],
              [
                -73.7670183092109,
                40.62747153117857
              ],
              [
                -73.76701742532335,
                40.627481346434436
              ],
              [
                -73.76701563050011,
                40.62749108847694
              ],
              [
                -73.76701293392289,
                40.62750070957858
              ],
              [
                -73.76700935027542,
                40.627510160348194
              ],
              [
                -73.76700489774849,
                40.62751939475185
              ],
              [
                -73.7669995971799,
                40.6275283644157
              ],
              [
                -73.76699347444435,
                40.627537026672194
              ],
              [
                -73.76697393385558,
                40.62754209403049
              ],
              [
                -73.76695411655648,
                40.62754649606844
              ],
              [
                -73.76693406193907,
                40.62755022348406
              ],
              [
                -73.7669138107104,
                40.62755326815109
              ],
              [
                -73.76689340268125,
                40.62755562679931
              ],
              [
                -73.76688740602556,
                40.62755890843355
              ],
              [
                -73.76688185950474,
                40.62756262019008
              ],
              [
                -73.76687681315445,
                40.627566727156896
              ],
              [
                -73.76687231812464,
                40.627571189901
              ],
              [
                -73.76686841568339,
                40.627575965618284
              ],
              [
                -73.76686514424892,
                40.62758100881904
              ],
              [
                -73.76686253499463,
                40.62758627081542
              ],
              [
                -73.76686061161894,
                40.62759170273707
              ],
              [
                -73.76685939410102,
                40.62759725034499
              ],
              [
                -73.76686039447092,
                40.62760602688592
              ],
              [
                -73.76686058490878,
                40.62761483361505
              ],
              [
                -73.7668599611599,
                40.62762362947912
              ],
              [
                -73.76685852623642,
                40.62763237042384
              ],
              [
                -73.7668562884253,
                40.627641013076015
              ],
              [
                -73.76685325865539,
                40.627649513062615
              ],
              [
                -73.76684945267611,
                40.6276578308784
              ],
              [
                -73.76684488734944,
                40.62766592316767
              ],
              [
                -73.76677690671515,
                40.62782045583435
              ],
              [
                -73.76676940794098,
                40.627839549847
              ],
              [
                -73.7667611841475,
                40.627858472346134
              ],
              [
                -73.76675224266138,
                40.627877202907804
              ],
              [
                -73.76674259495724,
                40.62789572982826
              ],
              [
                -73.76673224725123,
                40.62791403586424
              ],
              [
                -73.76672120706944,
                40.62793210662342
              ],
              [
                -73.76670948392939,
                40.62794992403178
              ],
              [
                -73.76669708885986,
                40.627967478227156
              ],
              [
                -73.76668403182535,
                40.62798474895866
              ],
              [
                -73.76667032231768,
                40.628001725690744
              ],
              [
                -73.76652939526993,
                40.628206282305335
              ],
              [
                -73.76649501288001,
                40.62825139294142
              ],
              [
                -73.76646100724825,
                40.62829156154942
              ],
              [
                -73.76639800533182,
                40.628351215867944
              ],
              [
                -73.76619212915513,
                40.62849174392619
              ],
              [
                -73.7661195748294,
                40.62853249275897
              ],
              [
                -73.76607218802029,
                40.62855663347987
              ],
              [
                -73.76597213038873,
                40.6285912613634
              ],
              [
                -73.76593928289284,
                40.62859912750986
              ],
              [
                -73.76589420336144,
                40.62861251211603
              ],
              [
                -73.76584951207545,
                40.62862823117769
              ],
              [
                -73.76581093689565,
                40.62864626771484
              ],
              [
                -73.76577466863773,
                40.62866370616869
              ],
              [
                -73.76572766467146,
                40.62869166233603
              ],
              [
                -73.76568068639081,
                40.62872249987193
              ],
              [
                -73.76564671200506,
                40.62874373079655
              ],
              [
                -73.76559055507889,
                40.628782729703964
              ],
              [
                -73.76553631026275,
                40.62882027665417
              ],
              [
                -73.76546947233054,
                40.62885373782001
              ],
              [
                -73.76536916817881,
                40.628913175422134
              ],
              [
                -73.76533137805642,
                40.628931423147215
              ],
              [
                -73.76529320125475,
                40.62894919847576
              ],
              [
                -73.76525464835133,
                40.62896649489524
              ],
              [
                -73.7652336529205,
                40.6289769887779
              ],
              [
                -73.76521224540274,
                40.62898698609621
              ],
              [
                -73.76519044277647,
                40.628996473649764
              ],
              [
                -73.76516826528541,
                40.62900544729139
              ],
              [
                -73.76514573716072,
                40.629013894840845
              ],
              [
                -73.76512287799476,
                40.629021810139434
              ],
              [
                -73.76509971002196,
                40.62902918586086
              ],
              [
                -73.7650762539453,
                40.629036012665864
              ],
              [
                -73.7650466693259,
                40.629045980512736
              ],
              [
                -73.76501747731787,
                40.62905660084301
              ],
              [
                -73.76498870588472,
                40.629067862657486
              ],
              [
                -73.76496037396085,
                40.62907975895889
              ],
              [
                -73.76493251039375,
                40.629092277576895
              ],
              [
                -73.76490513522714,
                40.629105408668444
              ],
              [
                -73.76483544663137,
                40.62913016149801
              ],
              [
                -73.76481677891066,
                40.62913657134701
              ],
              [
                -73.76479765522052,
                40.6291421471372
              ],
              [
                -73.7647781418146,
                40.62914686990568
              ],
              [
                -73.76477097322164,
                40.62914956435476
              ],
              [
                -73.76476415802365,
                40.62915274753915
              ],
              [
                -73.76475775699419,
                40.62915639545896
              ],
              [
                -73.76475182126312,
                40.62916047521576
              ],
              [
                -73.76474640438602,
                40.6291649519055
              ],
              [
                -73.76474154826336,
                40.62916979093532
              ],
              [
                -73.764737297029,
                40.62917494800066
              ],
              [
                -73.76473613849733,
                40.62917836289144
              ],
              [
                -73.76473574724314,
                40.62918187702178
              ],
              [
                -73.76473613460911,
                40.62918539174025
              ],
              [
                -73.76473728929172,
                40.62918880751127
              ],
              [
                -73.76473917644952,
                40.62919202776673
              ],
              [
                -73.7647417478057,
                40.62919496227705
              ],
              [
                -73.76474492713982,
                40.629197525781215
              ],
              [
                -73.76475336672318,
                40.62920318091118
              ],
              [
                -73.7647624739453,
                40.629208195601926
              ],
              [
                -73.76477217068526,
                40.6292125247958
              ],
              [
                -73.76478236428616,
                40.62921612977116
              ],
              [
                -73.76479299967764,
                40.62921903856026
              ],
              [
                -73.76480324664354,
                40.62922266341147
              ],
              [
                -73.76481302413637,
                40.62922697852707
              ],
              [
                -73.76482225027125,
                40.62923194621306
              ],
              [
                -73.76483085393808,
                40.629237528630135
              ],
              [
                -73.76484141524752,
                40.629243303855986
              ],
              [
                -73.76485148051162,
                40.629249572780246
              ],
              [
                -73.7648610124392,
                40.62925630986229
              ],
              [
                -73.76490570001684,
                40.62928277822219
              ],
              [
                -73.7649156135491,
                40.629288990708346
              ],
              [
                -73.76492609175202,
                40.62929464228733
              ],
              [
                -73.76493707425986,
                40.62929970368583
              ],
              [
                -73.76494850993906,
                40.62930414615167
              ],
              [
                -73.7649603348704,
                40.62930795045545
              ],
              [
                -73.76496973075218,
                40.62931074093154
              ],
              [
                -73.76497881357875,
                40.62931407506937
              ],
              [
                -73.76498753350214,
                40.62931793400401
              ],
              [
                -73.76499583652024,
                40.629322292160474
              ],
              [
                -73.76500367346122,
                40.62932712581705
              ],
              [
                -73.76501099847133,
                40.629332405897266
              ],
              [
                -73.76501776724778,
                40.62933809981014
              ],
              [
                -73.76502394010535,
                40.62934417514138
              ],
              [
                -73.76502789002711,
                40.62934827679081
              ],
              [
                -73.7650311478655,
                40.62935271794774
              ],
              [
                -73.76503366680842,
                40.629357429326824
              ],
              [
                -73.76503540620031,
                40.6293623416557
              ],
              [
                -73.76503634101641,
                40.62936738033253
              ],
              [
                -73.76503645570155,
                40.62937246675449
              ],
              [
                -73.7650357489798,
                40.629377526033366
              ],
              [
                -73.76503423343195,
                40.629382482304045
              ],
              [
                -73.76503192978599,
                40.62938725636768
              ],
              [
                -73.76502848828177,
                40.629393261456
              ],
              [
                -73.76502431003084,
                40.62939898844781
              ],
              [
                -73.76501942886131,
                40.629404384640424
              ],
              [
                -73.76501389332667,
                40.62940939953896
              ],
              [
                -73.7650077554797,
                40.62941398784964
              ],
              [
                -73.76500106538414,
                40.62941810728942
              ],
              [
                -73.76499389199198,
                40.629421721813124
              ],
              [
                -73.76498629546414,
                40.62942479418456
              ],
              [
                -73.76497598902309,
                40.629426432661795
              ],
              [
                -73.76496552940306,
                40.629427360501
              ],
              [
                -73.76495500237463,
                40.629427573856795
              ],
              [
                -73.76494449524782,
                40.629427068719515
              ],
              [
                -73.76493408716709,
                40.62942584960647
              ],
              [
                -73.76492386517279,
                40.62942392658015
              ],
              [
                -73.76490678237407,
                40.62941852719778
              ],
              [
                -73.76489002235603,
                40.629412571270755
              ],
              [
                -73.76487361499011,
                40.6294060682419
              ],
              [
                -73.76485759145058,
                40.629399032414945
              ],
              [
                -73.76482281837222,
                40.62937886113492
              ],
              [
                -73.76478875600937,
                40.62935799906747
              ],
              [
                -73.76475542541905,
                40.62933646099893
              ],
              [
                -73.76474143813012,
                40.629327786865275
              ],
              [
                -73.764726844702,
                40.62931971141194
              ],
              [
                -73.76471169033191,
                40.629312262875686
              ],
              [
                -73.76469601826966,
                40.62930546044355
              ],
              [
                -73.76462918685293,
                40.62927565237709
              ],
              [
                -73.76461783858255,
                40.629271995603176
              ],
              [
                -73.76460690541752,
                40.62926766554031
              ],
              [
                -73.7645964587305,
                40.629262688134105
              ],
              [
                -73.76458656107263,
                40.62925709651642
              ],
              [
                -73.76457727785248,
                40.629250924159344
              ],
              [
                -73.76456866544092,
                40.62924421088294
              ],
              [
                -73.76456463956274,
                40.62923933557967
              ],
              [
                -73.76455984721007,
                40.629234881382835
              ],
              [
                -73.76455436224664,
                40.629230915958196
              ],
              [
                -73.76454827217987,
                40.62922750381578
              ],
              [
                -73.76454167049532,
                40.629224697751305
              ],
              [
                -73.76453466017286,
                40.62922253969029
              ],
              [
                -73.76452857462218,
                40.62922289612643
              ],
              [
                -73.76452260238051,
                40.62922385673714
              ],
              [
                -73.76451684574849,
                40.62922540665455
              ],
              [
                -73.76451140773813,
                40.62922751610168
              ],
              [
                -73.76450637708541,
                40.629230150749464
              ],
              [
                -73.76450184476275,
                40.62923326555135
              ],
              [
                -73.7644978859462,
                40.629236805376806
              ],
              [
                -73.76449457143279,
                40.629240709892805
              ],
              [
                -73.76448519635906,
                40.62924262414412
              ],
              [
                -73.76447562277858,
                40.6292438380508
              ],
              [
                -73.76446594001443,
                40.62924433805847
              ],
              [
                -73.76445623866347,
                40.62924412334762
              ],
              [
                -73.764446611526,
                40.62924319176268
              ],
              [
                -73.76444060991837,
                40.629242877578996
              ],
              [
                -73.76443460887138,
                40.629243214414835
              ],
              [
                -73.76442873242357,
                40.629244195990616
              ],
              [
                -73.76442309937791,
                40.629245804456716
              ],
              [
                -73.76441782243766,
                40.62924800536533
              ],
              [
                -73.76441300752685,
                40.6292507540359
              ],
              [
                -73.76437636152288,
                40.62927315885194
              ],
              [
                -73.76436432867723,
                40.62927948891092
              ],
              [
                -73.76435183378379,
                40.629285275729266
              ],
              [
                -73.76433891715781,
                40.629290497946045
              ],
              [
                -73.76432562415123,
                40.629295140073786
              ],
              [
                -73.76431199924436,
                40.62929918427782
              ],
              [
                -73.76427505806674,
                40.62931051427112
              ],
              [
                -73.7642383939009,
                40.629322352099145
              ],
              [
                -73.76420201687426,
                40.62933469392969
              ],
              [
                -73.76416593843608,
                40.629347535263314
              ],
              [
                -73.76413017113231,
                40.62936087244032
              ],
              [
                -73.7641086018728,
                40.629369516590415
              ],
              [
                -73.76408655232912,
                40.62937742630796
              ],
              [
                -73.76406406519877,
                40.62938459079085
              ],
              [
                -73.7640411873824,
                40.62939099187458
              ],
              [
                -73.76398294861052,
                40.62940698379245
              ],
              [
                -73.7639237281608,
                40.62942517064357
              ],
              [
                -73.76386912947355,
                40.629440758693676
              ],
              [
                -73.76383414592328,
                40.62945106226117
              ],
              [
                -73.76381987889455,
                40.629456717892005
              ],
              [
                -73.76380605564347,
                40.629462982228844
              ],
              [
                -73.76379272175977,
                40.629469834424334
              ],
              [
                -73.76373256767408,
                40.62948730441171
              ],
              [
                -73.76367871368474,
                40.62949586672036
              ],
              [
                -73.76363498092073,
                40.629505054256896
              ],
              [
                -73.76363107151938,
                40.62950561330642
              ],
              [
                -73.76362737759139,
                40.62950673603196
              ],
              [
                -73.76362403802558,
                40.62950838318239
              ],
              [
                -73.76362117926836,
                40.62951048968119
              ],
              [
                -73.76361890955843,
                40.62951297835253
              ],
              [
                -73.76361731235069,
                40.62951575404378
              ],
              [
                -73.76361645089807,
                40.62951871385463
              ],
              [
                -73.76361749961579,
                40.62952167440964
              ],
              [
                -73.76363306624754,
                40.62955742163634
              ],
              [
                -73.7636336855933,
                40.62956030055935
              ],
              [
                -73.76363351189113,
                40.62956321571355
              ],
              [
                -73.7636325543882,
                40.62956603979584
              ],
              [
                -73.7636308524258,
                40.629568653773795
              ],
              [
                -73.76362847896975,
                40.62957094404483
              ],
              [
                -73.76362339454302,
                40.62957409235571
              ],
              [
                -73.76361783278999,
                40.629576729055785
              ],
              [
                -73.76361187960619,
                40.62957881495237
              ],
              [
                -73.76360036889724,
                40.62958252970073
              ],
              [
                -73.76358857343548,
                40.62958568448227
              ],
              [
                -73.76357653988008,
                40.62958826733083
              ],
              [
                -73.76357056393655,
                40.6295884597631
              ],
              [
                -73.76353662363248,
                40.629587965612906
              ],
              [
                -73.76351895188444,
                40.6295868922608
              ],
              [
                -73.76350122621838,
                40.629586700501484
              ],
              [
                -73.76348352315705,
                40.62958738982226
              ],
              [
                -73.76347276641425,
                40.62958596178847
              ],
              [
                -73.76346189115502,
                40.629585215353835
              ],
              [
                -73.7634509730086,
                40.629585153857064
              ],
              [
                -73.76344008585049,
                40.62958577929286
              ],
              [
                -73.7634293033663,
                40.62958708511168
              ],
              [
                -73.76341748134072,
                40.62958842648459
              ],
              [
                -73.76340591299954,
                40.62959071709631
              ],
              [
                -73.76339472970923,
                40.62959393074778
              ],
              [
                -73.76338129973229,
                40.62959859342583
              ],
              [
                -73.76336834851364,
                40.629603982824065
              ],
              [
                -73.76335594386991,
                40.62961007328316
              ],
              [
                -73.76331144955105,
                40.629630595665496
              ],
              [
                -73.76326391914132,
                40.62965429466728
              ],
              [
                -73.76325534239558,
                40.62965924508681
              ],
              [
                -73.76324628813578,
                40.62966367518182
              ],
              [
                -73.7632368103359,
                40.62966755658323
              ],
              [
                -73.76322696470938,
                40.62967086628677
              ],
              [
                -73.76321681421054,
                40.62967358582622
              ],
              [
                -73.76320641608257,
                40.62967569488082
              ],
              [
                -73.76317051492023,
                40.629680919550616
              ],
              [
                -73.7631687699776,
                40.62968082984631
              ],
              [
                -73.76316395731318,
                40.629680319690515
              ],
              [
                -73.76315924098999,
                40.62967942944157
              ],
              [
                -73.76315467374808,
                40.62967816842226
              ],
              [
                -73.76312545290956,
                40.62964861101601
              ],
              [
                -73.7631225343584,
                40.62964934397452
              ],
              [
                -73.76310569745239,
                40.62965528957632
              ],
              [
                -73.76308661984865,
                40.629660374819046
              ],
              [
                -73.76302107334124,
                40.62971201096331
              ],
              [
                -73.76295388724613,
                40.62976191076733
              ],
              [
                -73.76289946189033,
                40.6297993748504
              ],
              [
                -73.7628576513959,
                40.62983527289682
              ],
              [
                -73.7628074786225,
                40.62986239725879
              ],
              [
                -73.76275193784853,
                40.62989942037932
              ],
              [
                -73.76269869800606,
                40.62993735338237
              ],
              [
                -73.7626484031195,
                40.62996878677754
              ],
              [
                -73.76259133473147,
                40.62999971262329
              ],
              [
                -73.76252560314214,
                40.6300348761254
              ],
              [
                -73.76243265991498,
                40.63006358626346
              ],
              [
                -73.76238520005512,
                40.630097249184296
              ],
              [
                -73.76233939251507,
                40.630132672212525
              ],
              [
                -73.76229285501617,
                40.630150279492135
              ],
              [
                -73.76227486437197,
                40.63016393881594
              ],
              [
                -73.76225562918488,
                40.630183634154946
              ],
              [
                -73.76222090635459,
                40.63023222758299
              ],
              [
                -73.76220794059698,
                40.630251140450525
              ],
              [
                -73.76219292080067,
                40.63026049604866
              ],
              [
                -73.7621476220663,
                40.63027420926925
              ],
              [
                -73.76211449776119,
                40.630278559825754
              ],
              [
                -73.76209586454806,
                40.63027006741182
              ],
              [
                -73.76208184709206,
                40.63026120006665
              ],
              [
                -73.76206325149165,
                40.6302527070551
              ],
              [
                -73.76204329334408,
                40.63025244612014
              ],
              [
                -73.76202356486533,
                40.630268683098585
              ],
              [
                -73.76202016617357,
                40.630292500899024
              ],
              [
                -73.76198301778457,
                40.63032368730294
              ],
              [
                -73.76196692560318,
                40.63033043305897
              ],
              [
                -73.76191993967196,
                40.630342385482734
              ],
              [
                -73.76188743880134,
                40.6303436905783
              ],
              [
                -73.76184133173781,
                40.630341782918606
              ],
              [
                -73.76180536131753,
                40.630346073713945
              ],
              [
                -73.7617851400165,
                40.63035884948161
              ],
              [
                -73.76176520181093,
                40.63038373149479
              ],
              [
                -73.76173894571001,
                40.63041161861906
              ],
              [
                -73.76170878035607,
                40.63043463928736
              ],
              [
                -73.76167726317362,
                40.63044291876144
              ],
              [
                -73.76162817878301,
                40.63044745572565
              ],
              [
                -73.76158384549085,
                40.630442971522584
              ],
              [
                -73.76154609974837,
                40.63044986514293
              ],
              [
                -73.76151721749568,
                40.63046682285877
              ],
              [
                -73.76149481082304,
                40.63047652055118
              ],
              [
                -73.76143644071634,
                40.630488339765265
              ],
              [
                -73.76138921621218,
                40.63048596303699
              ],
              [
                -73.76134619270202,
                40.63049841799601
              ],
              [
                -73.76131227686686,
                40.63051229121834
              ],
              [
                -73.76128694367084,
                40.63052412247828
              ],
              [
                -73.76125670903987,
                40.630526312131806
              ],
              [
                -73.76123122711068,
                40.63051947897371
              ],
              [
                -73.7612079670496,
                40.630515697781185
              ],
              [
                -73.76116741261265,
                40.630512127096495
              ],
              [
                -73.76112982898906,
                40.63051418937378
              ],
              [
                -73.76109585282921,
                40.630525181406725
              ],
              [
                -73.76105643152373,
                40.6305369570399
              ],
              [
                -73.76102586516208,
                40.630551523338426
              ],
              [
                -73.760998356,
                40.63056730303196
              ],
              [
                -73.7609679127443,
                40.630577450987424
              ],
              [
                -73.76094128812467,
                40.63058766115393
              ],
              [
                -73.7609120400946,
                40.630596054365576
              ],
              [
                -73.76088465534657,
                40.63060741520441
              ],
              [
                -73.7608532539369,
                40.630623160466435
              ],
              [
                -73.76082264621186,
                40.630639182454
              ],
              [
                -73.76079871707456,
                40.630651208683226
              ],
              [
                -73.7607882515007,
                40.630666365058374
              ],
              [
                -73.76077763490368,
                40.63067328789428
              ],
              [
                -73.7607663903239,
                40.63067400614984
              ],
              [
                -73.76075328608108,
                40.630671727625064
              ],
              [
                -73.7607192411912,
                40.63067149092445
              ],
              [
                -73.76069898019995,
                40.630675319519895
              ],
              [
                -73.76067935948718,
                40.63067118946043
              ],
              [
                -73.76061342699172,
                40.630661911474704
              ],
              [
                -73.76056937547405,
                40.630659157876835
              ],
              [
                -73.76053091824247,
                40.630663881184184
              ],
              [
                -73.76050547757657,
                40.63067527395926
              ],
              [
                -73.76045987972935,
                40.630701693152545
              ],
              [
                -73.76044221810595,
                40.63072443932606
              ],
              [
                -73.76041692849454,
                40.630744093765486
              ],
              [
                -73.76037384025314,
                40.630764068446034
              ],
              [
                -73.76034398715748,
                40.630780419566065
              ],
              [
                -73.76031691743549,
                40.63079414258618
              ],
              [
                -73.76027525726276,
                40.63081768841192
              ],
              [
                -73.7602537179264,
                40.63082617928269
              ],
              [
                -73.7602137700301,
                40.630844458689346
              ],
              [
                -73.76019214398727,
                40.63085737012922
              ],
              [
                -73.76016216673949,
                40.630878139526764
              ],
              [
                -73.76012253442501,
                40.630898779544374
              ],
              [
                -73.76010910058118,
                40.63091062062763
              ],
              [
                -73.76009000765369,
                40.63092745081629
              ],
              [
                -73.76006058703396,
                40.63094322696733
              ],
              [
                -73.76001141454829,
                40.63097194473036
              ],
              [
                -73.75997858596594,
                40.63098411739689
              ],
              [
                -73.75994663679194,
                40.63099217586444
              ],
              [
                -73.7599302662337,
                40.63099606622412
              ],
              [
                -73.75990440716355,
                40.63099387237703
              ],
              [
                -73.75988793308466,
                40.63098656368786
              ],
              [
                -73.75986776277361,
                40.630974745263515
              ],
              [
                -73.75983248858583,
                40.63097626411999
              ],
              [
                -73.75980279920832,
                40.630986715229696
              ],
              [
                -73.75968491183559,
                40.63103856462029
              ],
              [
                -73.75961840113031,
                40.631079680497585
              ],
              [
                -73.75958992784098,
                40.6311025411422
              ],
              [
                -73.75955940732992,
                40.63111416851669
              ],
              [
                -73.75953158196724,
                40.63112758838682
              ],
              [
                -73.75948286372973,
                40.63114011199059
              ],
              [
                -73.75945154587143,
                40.63115289160992
              ],
              [
                -73.759428283806,
                40.63116964109626
              ],
              [
                -73.75940636732578,
                40.6311928992238
              ],
              [
                -73.75937603869771,
                40.631241061223434
              ],
              [
                -73.75934852999643,
                40.63128665017219
              ],
              [
                -73.759332330336,
                40.63131293895996
              ],
              [
                -73.7593225377309,
                40.631331363430185
              ],
              [
                -73.75931051807694,
                40.63134739594609
              ],
              [
                -73.75928928927192,
                40.631359756876485
              ],
              [
                -73.75925753087604,
                40.63137459462253
              ],
              [
                -73.75922323824433,
                40.631383196975726
              ],
              [
                -73.75918672601648,
                40.631387951381946
              ],
              [
                -73.75915871325151,
                40.6313931072225
              ],
              [
                -73.7591293759085,
                40.631405919382615
              ],
              [
                -73.75910055263272,
                40.6314264170106
              ],
              [
                -73.75907180593221,
                40.63144543366577
              ],
              [
                -73.75904134603263,
                40.63146974316017
              ],
              [
                -73.75900906659346,
                40.631489575299604
              ],
              [
                -73.75897247001858,
                40.631497266918686
              ],
              [
                -73.75884997041852,
                40.63151896844934
              ],
              [
                -73.75878043724416,
                40.631529088113346
              ],
              [
                -73.758741704338,
                40.631529966956684
              ],
              [
                -73.75868393990362,
                40.631532919896436
              ],
              [
                -73.75862267398684,
                40.63153814536174
              ],
              [
                -73.75856752919167,
                40.63154437005996
              ],
              [
                -73.75854646556999,
                40.63155082931303
              ],
              [
                -73.75851596563963,
                40.631576594413254
              ],
              [
                -73.75848512889073,
                40.6316156430282
              ],
              [
                -73.75845963000812,
                40.63164268061588
              ],
              [
                -73.75843471865126,
                40.63167737685817
              ],
              [
                -73.75840800529826,
                40.63169200651097
              ],
              [
                -73.75837390268825,
                40.631707387578146
              ],
              [
                -73.7583163932744,
                40.63172980089711
              ],
              [
                -73.75826666624022,
                40.63175231464911
              ],
              [
                -73.7582201663185,
                40.631768711972406
              ],
              [
                -73.75818551546642,
                40.631776434907515
              ],
              [
                -73.75814094243319,
                40.6317787016428
              ],
              [
                -73.75808862169123,
                40.63178081485376
              ],
              [
                -73.75804316756229,
                40.63178719639315
              ],
              [
                -73.75799870111251,
                40.631799180495484
              ],
              [
                -73.75795975654852,
                40.631808897472354
              ],
              [
                -73.75790341524565,
                40.6318168760451
              ],
              [
                -73.75786233850928,
                40.631818299153224
              ],
              [
                -73.75781407545027,
                40.631814601824225
              ],
              [
                -73.75776671718441,
                40.63181947014024
              ],
              [
                -73.75773524825536,
                40.63182401508024
              ],
              [
                -73.75770658938339,
                40.63183861249354
              ],
              [
                -73.75768102421526,
                40.63185442296527
              ],
              [
                -73.75766174651459,
                40.631865334056464
              ],
              [
                -73.75763936245247,
                40.63187796733063
              ],
              [
                -73.75757274652175,
                40.63190788247455
              ],
              [
                -73.75754868671227,
                40.63192581014227
              ],
              [
                -73.75752947030263,
                40.63194937549311
              ],
              [
                -73.75749827965645,
                40.63198606194012
              ],
              [
                -73.75748358593373,
                40.6320144402201
              ],
              [
                -73.75747297457133,
                40.63204966045866
              ],
              [
                -73.75745388137142,
                40.6320688337754
              ],
              [
                -73.75742398540433,
                40.632086639373746
              ],
              [
                -73.75738780818773,
                40.63210791698879
              ],
              [
                -73.75734568131885,
                40.63212081210867
              ],
              [
                -73.7573012565386,
                40.63213134020055
              ],
              [
                -73.7572517803673,
                40.63214353189772
              ],
              [
                -73.75721744562729,
                40.63215361569562
              ],
              [
                -73.75719252775905,
                40.632160014460865
              ],
              [
                -73.75717969544705,
                40.632161579391656
              ],
              [
                -73.75717249301164,
                40.63217062097838
              ],
              [
                -73.75716688504453,
                40.6321773074557
              ],
              [
                -73.75715865681255,
                40.63218072107617
              ],
              [
                -73.75714799946306,
                40.63218912511934
              ],
              [
                -73.75714083769348,
                40.63219665818928
              ],
              [
                -73.75713166934715,
                40.63219150489043
              ],
              [
                -73.75711856482872,
                40.632189253928836
              ],
              [
                -73.75706992673652,
                40.632198813423784
              ],
              [
                -73.75703757806313,
                40.63220744553516
              ],
              [
                -73.75701324998789,
                40.63222004710484
              ],
              [
                -73.75700052169095,
                40.632232839541636
              ],
              [
                -73.75697491342238,
                40.63225013256656
              ],
              [
                -73.75694995943626,
                40.6322565300303
              ],
              [
                -73.75686057294176,
                40.632262514161894
              ],
              [
                -73.75679202591738,
                40.63226445794382
              ],
              [
                -73.75673983597723,
                40.632291500172485
              ],
              [
                -73.75670392809457,
                40.632332663693894
              ],
              [
                -73.75666563107961,
                40.632341473092126
              ],
              [
                -73.75664435054578,
                40.63235085308025
              ],
              [
                -73.75660686882948,
                40.63236414207319
              ],
              [
                -73.75655350205051,
                40.63237629775954
              ],
              [
                -73.75648885226455,
                40.632390626001595
              ],
              [
                -73.75644827666468,
                40.63240267202476
              ],
              [
                -73.75642748681958,
                40.63241297593068
              ],
              [
                -73.75639978351029,
                40.63242197504331
              ],
              [
                -73.75639362639575,
                40.63242100071574
              ],
              [
                -73.75638676192048,
                40.63241675924196
              ],
              [
                -73.75637032315481,
                40.632409423822445
              ],
              [
                -73.75635669373776,
                40.632412167718535
              ],
              [
                -73.75634600028775,
                40.632420544136295
              ],
              [
                -73.7563395049462,
                40.63243282651429
              ],
              [
                -73.75633067386859,
                40.63244419811155
              ],
              [
                -73.75631528510678,
                40.63245517283825
              ],
              [
                -73.75628789837182,
                40.63246653308169
              ],
              [
                -73.7562613910807,
                40.632473777482126
              ],
              [
                -73.75622523370636,
                40.63247941055959
              ],
              [
                -73.75620434134115,
                40.632478515381656
              ],
              [
                -73.7561745005988,
                40.632480702860434
              ],
              [
                -73.75615773263061,
                40.63248516931407
              ],
              [
                -73.75613095727086,
                40.63248711567426
              ],
              [
                -73.75610493061326,
                40.63249082083038
              ],
              [
                -73.75607599598158,
                40.63250157396462
              ],
              [
                -73.75605957824654,
                40.63250840113115
              ],
              [
                -73.75603621696222,
                40.63251246876386
              ],
              [
                -73.75600263133545,
                40.632524336802284
              ],
              [
                -73.75597648108734,
                40.63253248822788
              ],
              [
                -73.7559253565285,
                40.63253287493039
              ],
              [
                -73.75589579130344,
                40.632538905037045
              ],
              [
                -73.75587483581369,
                40.63255508173109
              ],
              [
                -73.75585893932731,
                40.63255688644428
              ],
              [
                -73.75584751064262,
                40.63254934212478
              ],
              [
                -73.75581905849747,
                40.63255658329881
              ],
              [
                -73.75579550006998,
                40.63255532504481
              ],
              [
                -73.75578297837113,
                40.63256073427027
              ],
              [
                -73.75577188570304,
                40.63256971305935
              ],
              [
                -73.75575077877197,
                40.63257762871056
              ],
              [
                -73.75569749684556,
                40.63258681924811
              ],
              [
                -73.75568013691804,
                40.6325850805996
              ],
              [
                -73.75566085845692,
                40.63259599202397
              ],
              [
                -73.75564077717657,
                40.63260953546596
              ],
              [
                -73.75561676373151,
                40.63262449906871
              ],
              [
                -73.75561450693363,
                40.63265043315702
              ],
              [
                -73.7556138803065,
                40.6326740090507
              ],
              [
                -73.7555974037463,
                40.63269645393822
              ],
              [
                -73.755554464175,
                40.63272468900141
              ],
              [
                -73.75552439566636,
                40.63274987725503
              ],
              [
                -73.75550007253388,
                40.6327609973894
              ],
              [
                -73.75546104350131,
                40.63277364912334
              ],
              [
                -73.75541908551729,
                40.632779186683756
              ],
              [
                -73.7553850394664,
                40.63277897705101
              ],
              [
                -73.75535700715443,
                40.63276996933914
              ],
              [
                -73.75533891369845,
                40.6327523097814
              ],
              [
                -73.75532756649883,
                40.63274179998137
              ],
              [
                -73.75531029075492,
                40.63273712450136
              ],
              [
                -73.7552940433308,
                40.63273656840547
              ],
              [
                -73.7552825272304,
                40.63273344225976
              ],
              [
                -73.75526060382325,
                40.632728373238336
              ],
              [
                -73.75523192286363,
                40.632728833704796
              ],
              [
                -73.75521515399497,
                40.63273327153914
              ],
              [
                -73.7552025080384,
                40.632743098852735
              ],
              [
                -73.75517970307233,
                40.63277195310988
              ],
              [
                -73.75516622495807,
                40.63278293158373
              ],
              [
                -73.75514208750556,
                40.63280233970867
              ],
              [
                -73.75512626252157,
                40.632814301267295
              ],
              [
                -73.75510630943813,
                40.63282246519053
              ],
              [
                -73.75507496607389,
                40.632831923470455
              ],
              [
                -73.75504634040247,
                40.63283737838965
              ],
              [
                -73.75499944126739,
                40.632844334294674
              ],
              [
                -73.75498836788584,
                40.63284700004566
              ],
              [
                -73.75497423365873,
                40.63285040092461
              ],
              [
                -73.75492844934085,
                40.6328585102698
              ],
              [
                -73.75490324828398,
                40.63286314904655
              ],
              [
                -73.75483158903131,
                40.63287268599523
              ],
              [
                -73.75479180499259,
                40.632884651368045
              ],
              [
                -73.75476459598178,
                40.63289647886701
              ],
              [
                -73.7547257494144,
                40.63291829894527
              ],
              [
                -73.7546721959348,
                40.63295183595383
              ],
              [
                -73.75464388538201,
                40.632979277813355
              ],
              [
                -73.75462602651683,
                40.63301525262287
              ],
              [
                -73.75460998461537,
                40.63303736776137
              ],
              [
                -73.75460136335478,
                40.63305000401672
              ],
              [
                -73.75458063586557,
                40.633062832387445
              ],
              [
                -73.75455160892948,
                40.63306955000011
              ],
              [
                -73.75450787357656,
                40.63307845965084
              ],
              [
                -73.75445135571275,
                40.633085310695805
              ],
              [
                -73.75441789324101,
                40.63308309720109
              ],
              [
                -73.75438552708675,
                40.63308635033907
              ],
              [
                -73.75435569788611,
                40.63309564697632
              ],
              [
                -73.75434172916353,
                40.63310245165503
              ],
              [
                -73.7543050687578,
                40.63311771564991
              ],
              [
                -73.75427674329964,
                40.63312901912948
              ],
              [
                -73.7542227987003,
                40.633141886175004
              ],
              [
                -73.7541805178403,
                40.63315713979145
              ],
              [
                -73.75414992118378,
                40.633168987377346
              ],
              [
                -73.75411400945771,
                40.63318598265151
              ],
              [
                -73.75410574377156,
                40.63319029409778
              ],
              [
                -73.753957421808,
                40.63325660016266
              ],
              [
                -73.75387867256454,
                40.63329280020785
              ],
              [
                -73.75379664693634,
                40.633328937731505
              ],
              [
                -73.75376740764654,
                40.633344165343836
              ],
              [
                -73.75376232723436,
                40.63335400719265
              ],
              [
                -73.75377660737931,
                40.63337028693529
              ],
              [
                -73.75377694706003,
                40.63338538405602
              ],
              [
                -73.75375784378504,
                40.63339777766772
              ],
              [
                -73.753690614823,
                40.63342667419209
              ],
              [
                -73.75361241275372,
                40.63345120983239
              ],
              [
                -73.75356385552718,
                40.633458790671874
              ],
              [
                -73.75353932880935,
                40.63346592946122
              ],
              [
                -73.75353303999954,
                40.63347154228246
              ],
              [
                -73.7535198771118,
                40.63349440676724
              ],
              [
                -73.75351332531253,
                40.63351239859553
              ],
              [
                -73.75349765803799,
                40.633530535060075
              ],
              [
                -73.75341601415487,
                40.63355058915294
              ],
              [
                -73.75320617450369,
                40.63359158484089
              ],
              [
                -73.75306899945488,
                40.63362458525472
              ],
              [
                -73.75296000940244,
                40.63364504585675
              ],
              [
                -73.75275631212412,
                40.63364230349722
              ],
              [
                -73.75265631341018,
                40.633637641867075
              ],
              [
                -73.75260211764352,
                40.63364984888782
              ],
              [
                -73.75251235529672,
                40.63368292316824
              ],
              [
                -73.75245502470679,
                40.633704510034825
              ],
              [
                -73.75240933538956,
                40.63374503582233
              ],
              [
                -73.75235508487731,
                40.63379092234885
              ],
              [
                -73.75228483426119,
                40.633808638517785
              ],
              [
                -73.75221291779746,
                40.63382879513764
              ],
              [
                -73.75218878170351,
                40.633847325965625
              ],
              [
                -73.75214715284747,
                40.63388074962218
              ],
              [
                -73.75210021112169,
                40.633918307654845
              ],
              [
                -73.7520461881115,
                40.633951814954195
              ],
              [
                -73.75193817967717,
                40.63398885589988
              ],
              [
                -73.75186213641547,
                40.63400428326161
              ],
              [
                -73.75166205100028,
                40.63407661425945
              ],
              [
                -73.75159882339656,
                40.63408523527375
              ],
              [
                -73.75157477362498,
                40.634100059575424
              ],
              [
                -73.75155838145801,
                40.634138478815466
              ],
              [
                -73.75154329427396,
                40.63417492453081
              ],
              [
                -73.75152087297442,
                40.634206605946986
              ],
              [
                -73.75143514452971,
                40.63424937732433
              ],
              [
                -73.75137625183443,
                40.6342840535226
              ],
              [
                -73.75133389361284,
                40.6343196985878
              ],
              [
                -73.7513205615511,
                40.63432002726201
              ],
              [
                -73.75125688143288,
                40.6343341068871
              ],
              [
                -73.75118128290848,
                40.634375253679394
              ],
              [
                -73.75112682317871,
                40.63439986648097
              ],
              [
                -73.75099646021218,
                40.63447920970961
              ],
              [
                -73.75086407034361,
                40.63456154197829
              ],
              [
                -73.75082743535216,
                40.634588829330006
              ],
              [
                -73.75081926856257,
                40.63460494988895
              ],
              [
                -73.75080781850349,
                40.63462232717233
              ],
              [
                -73.75078821189561,
                40.63464342016499
              ],
              [
                -73.75073275582511,
                40.63467439740075
              ],
              [
                -73.75062836415057,
                40.63471646949397
              ],
              [
                -73.75052267929836,
                40.634758070442544
              ],
              [
                -73.7504457445215,
                40.63477088583466
              ],
              [
                -73.75030816891771,
                40.63479408263555
              ],
              [
                -73.75017390727064,
                40.634788713694405
              ],
              [
                -73.75010474833118,
                40.634803275492075
              ],
              [
                -73.75000235066122,
                40.63483247773605
              ],
              [
                -73.74997722438475,
                40.63485578019461
              ],
              [
                -73.74995536558897,
                40.634871653633915
              ],
              [
                -73.74991001947673,
                40.63488722670301
              ],
              [
                -73.74980600508577,
                40.634915190063154
              ],
              [
                -73.74969316885283,
                40.63494162334695
              ],
              [
                -73.74955459566453,
                40.63498120310818
              ],
              [
                -73.749477009468,
                40.634985069368504
              ],
              [
                -73.74939809033248,
                40.63500833858589
              ],
              [
                -73.74936511437703,
                40.63502989573867
              ],
              [
                -73.74933539830447,
                40.63505642857014
              ],
              [
                -73.7492926711699,
                40.63508419401817
              ],
              [
                -73.74922842905895,
                40.63510373408857
              ],
              [
                -73.74915016047596,
                40.635125988992634
              ],
              [
                -73.74908166657815,
                40.63514576731354
              ],
              [
                -73.74900204451106,
                40.63519236434149
              ],
              [
                -73.74884578400584,
                40.63525995690307
              ],
              [
                -73.74880661033536,
                40.63529097044979
              ],
              [
                -73.74877979914012,
                40.63533040956813
              ],
              [
                -73.748762754701,
                40.635388479165535
              ],
              [
                -73.7487349724278,
                40.6354182544066
              ],
              [
                -73.7486942175569,
                40.63542939097165
              ],
              [
                -73.74861889305822,
                40.635444679550616
              ],
              [
                -73.74860324379861,
                40.63545732629315
              ],
              [
                -73.74857577209558,
                40.63550025037003
              ],
              [
                -73.74854241848169,
                40.63555460734049
              ],
              [
                -73.74849297657126,
                40.63567172844183
              ],
              [
                -73.74846487933117,
                40.63586166252608
              ],
              [
                -73.74846407433594,
                40.63595078378953
              ],
              [
                -73.74848582725184,
                40.635982095170284
              ],
              [
                -73.74845687452745,
                40.636121358336005
              ],
              [
                -73.74842848625192,
                40.63630184797764
              ],
              [
                -73.74840924794756,
                40.636483319414666
              ],
              [
                -73.74839815536177,
                40.63665363731992
              ],
              [
                -73.7483634697079,
                40.63671743343142
              ],
              [
                -73.74826747018606,
                40.63680744481218
              ],
              [
                -73.74821198773496,
                40.63684462571982
              ],
              [
                -73.748181958921,
                40.6368580083674
              ],
              [
                -73.74816342022596,
                40.63686285291794
              ],
              [
                -73.74814391801104,
                40.636865445773175
              ],
              [
                -73.74812714762471,
                40.63686761204869
              ],
              [
                -73.748124885848,
                40.63687273164474
              ],
              [
                -73.74811692671953,
                40.6369017552278
              ],
              [
                -73.74811006443797,
                40.636937614728446
              ],
              [
                -73.74809966015668,
                40.63702044751698
              ],
              [
                -73.74809279075087,
                40.63705801378809
              ],
              [
                -73.74808031511408,
                40.637095569786695
              ],
              [
                -73.74804523977623,
                40.63717920157469
              ],
              [
                -73.74803843870359,
                40.63719883231717
              ],
              [
                -73.74803388651996,
                40.63721676069723
              ],
              [
                -73.7480315660138,
                40.63723725431576
              ],
              [
                -73.74803137572476,
                40.63728765002759
              ],
              [
                -73.74802908555866,
                40.63730045713858
              ],
              [
                -73.74802679707516,
                40.6373124106919
              ],
              [
                -73.74799513204617,
                40.63738409086545
              ],
              [
                -73.7479702100006,
                40.637451515490376
              ],
              [
                -73.74795090962952,
                40.637514680940555
              ],
              [
                -73.74794747163065,
                40.63753431904882
              ],
              [
                -73.74794514917606,
                40.637555667897225
              ],
              [
                -73.74794595925108,
                40.63763766883915
              ],
              [
                -73.74794246705504,
                40.63767182792205
              ],
              [
                -73.74792988195635,
                40.637738425347344
              ],
              [
                -73.74791053393895,
                40.637814403985956
              ],
              [
                -73.7478957798794,
                40.6378613493329
              ],
              [
                -73.74786067163458,
                40.63795352229415
              ],
              [
                -73.7478470717812,
                40.637991931203175
              ],
              [
                -73.74783570405536,
                40.63803290418113
              ],
              [
                -73.74782995639367,
                40.63807047557322
              ],
              [
                -73.74781949759755,
                40.63816782762569
              ],
              [
                -73.74781594823676,
                40.63821650649802
              ],
              [
                -73.74781593855258,
                40.63821906900298
              ],
              [
                -73.74781593209414,
                40.638220777953784
              ],
              [
                -73.74781703473738,
                40.638225904921676
              ],
              [
                -73.74782705455601,
                40.63824471830712
              ],
              [
                -73.7478281572005,
                40.638249845274956
              ],
              [
                -73.7478292624093,
                40.63825411918974
              ],
              [
                -73.74782811200754,
                40.63826180424532
              ],
              [
                -73.74782695771786,
                40.63827034352352
              ],
              [
                -73.74782128750516,
                40.63828741420521
              ],
              [
                -73.74781225191403,
                40.63830447800827
              ],
              [
                -73.74778518436703,
                40.63834627295628
              ],
              [
                -73.74777952874075,
                40.6383590726791
              ],
              [
                -73.74777500158162,
                40.63837016658211
              ],
              [
                -73.74772609744176,
                40.638552850204555
              ],
              [
                -73.74772606071858,
                40.63864470150411
              ],
              [
                -73.74774229930892,
                40.63870113112879
              ],
              [
                -73.7477147538997,
                40.63873448974994
              ],
              [
                -73.74768040032282,
                40.63877654041845
              ],
              [
                -73.74767582401284,
                40.6388004463367
              ],
              [
                -73.74766449288337,
                40.63883202618261
              ],
              [
                -73.74763057301057,
                40.638906263824076
              ],
              [
                -73.74761811035013,
                40.638940403205126
              ],
              [
                -73.74763143903947,
                40.63901419286407
              ],
              [
                -73.74762024328325,
                40.63907474046785
              ],
              [
                -73.7475803804156,
                40.63921982368135
              ],
              [
                -73.74756328824401,
                40.63926298158842
              ],
              [
                -73.74755242024823,
                40.639307294035724
              ],
              [
                -73.74754790379832,
                40.63935224174217
              ],
              [
                -73.74754979183437,
                40.63939729798184
              ],
              [
                -73.7475580622437,
                40.63944193475566
              ],
              [
                -73.74757191274122,
                40.63948389251258
              ],
              [
                -73.74754928202161,
                40.639529324649
              ],
              [
                -73.74748804315348,
                40.63965226798819
              ],
              [
                -73.74747323926697,
                40.639664510125726
              ],
              [
                -73.74746128085006,
                40.63967844065176
              ],
              [
                -73.74745249375627,
                40.63969367997683
              ],
              [
                -73.7474471174258,
                40.639709812848274
              ],
              [
                -73.74744529835942,
                40.63972639966522
              ],
              [
                -73.74744708612687,
                40.6397429884568
              ],
              [
                -73.7474524320164,
                40.63975912719789
              ],
              [
                -73.74746119036125,
                40.63977437612689
              ],
              [
                -73.74747648506289,
                40.63981071897193
              ],
              [
                -73.74748529534024,
                40.639848288971436
              ],
              [
                -73.7474874644855,
                40.639886417919534
              ],
              [
                -73.74748295390837,
                40.639924427668255
              ],
              [
                -73.74748511952103,
                40.6400041332127
              ],
              [
                -73.7474802076455,
                40.640083767972826
              ],
              [
                -73.74746824072419,
                40.6401629676888
              ],
              [
                -73.74744927347035,
                40.64024137008907
              ],
              [
                -73.7474428957037,
                40.6403171311831
              ],
              [
                -73.74746782126103,
                40.64034003086601
              ],
              [
                -73.74748761968836,
                40.640365030618334
              ],
              [
                -73.74750206113116,
                40.640392075452496
              ],
              [
                -73.74751079261682,
                40.640420504361444
              ],
              [
                -73.74752684254419,
                40.64106951944089
              ],
              [
                -73.74753701760997,
                40.64107486342859
              ],
              [
                -73.74754589598956,
                40.64108141280712
              ],
              [
                -73.7475532344098,
                40.64108898811972
              ],
              [
                -73.74755883179367,
                40.64109738179864
              ],
              [
                -73.74756253476885,
                40.6411063638522
              ],
              [
                -73.74756424187052,
                40.641115688167105
              ],
              [
                -73.74756390632288,
                40.64112509925164
              ],
              [
                -73.74756153731882,
                40.641134339236196
              ],
              [
                -73.74755719976996,
                40.64114315493975
              ],
              [
                -73.74755101252694,
                40.64115130480638
              ],
              [
                -73.7475431451236,
                40.64115856552465
              ],
              [
                -73.74753381313113,
                40.64116473814626
              ],
              [
                -73.74752327225228,
                40.6411696535371
              ],
              [
                -73.74751181131327,
                40.64117317701244
              ],
              [
                -73.747499744352,
                40.64117521202666
              ],
              [
                -73.74747188470438,
                40.641198329597636
              ],
              [
                -73.74744905153455,
                40.64122445001427
              ],
              [
                -73.7474317938311,
                40.64125294525846
              ],
              [
                -73.74742052653178,
                40.64128313021307
              ],
              [
                -73.7474155205471,
                40.64131427913381
              ],
              [
                -73.74741689624642,
                40.64134564309854
              ],
              [
                -73.74742462056209,
                40.6413764680137
              ],
              [
                -73.74743850778465,
                40.64140601274523
              ],
              [
                -73.74748172934405,
                40.64145590137578
              ],
              [
                -73.74751750052548,
                40.64150910261508
              ],
              [
                -73.74754538897116,
                40.641564973459914
              ],
              [
                -73.7475650575933,
                40.641622838639336
              ],
              [
                -73.74757626864935,
                40.641681998776626
              ],
              [
                -73.74757643258337,
                40.64168342916494
              ],
              [
                -73.74758092454391,
                40.64169141709598
              ],
              [
                -73.74765147633876,
                40.64182240679819
              ],
              [
                -73.74771698986176,
                40.641954906203395
              ],
              [
                -73.74777740949662,
                40.64208880289097
              ],
              [
                -73.7480264435227,
                40.642337609186114
              ],
              [
                -73.74814232521368,
                40.6424733382133
              ],
              [
                -73.7482522840352,
                40.642611897001615
              ],
              [
                -73.74835620097792,
                40.642753135633406
              ],
              [
                -73.74845396356635,
                40.64289690129079
              ],
              [
                -73.7485454659819,
                40.64304303842062
              ],
              [
                -73.74863060917852,
                40.64319138890303
              ],
              [
                -73.74870930098776,
                40.64334179222194
              ],
              [
                -73.74874038845483,
                40.64336644855764
              ],
              [
                -73.74883395552304,
                40.64342568208787
              ],
              [
                -73.74892145185547,
                40.64349007456678
              ],
              [
                -73.74900238843753,
                40.643559266120086
              ],
              [
                -73.7490763129122,
                40.64363287005163
              ],
              [
                -73.75014428585494,
                40.64435016863483
              ],
              [
                -73.75032661179766,
                40.64446083625524
              ],
              [
                -73.75048503032512,
                40.64461845248785
              ],
              [
                -73.75057032811947,
                40.64472855667386
              ],
              [
                -73.75376146361373,
                40.64668632265801
              ],
              [
                -73.75361134104452,
                40.646856182256535
              ],
              [
                -73.75343611609502,
                40.64678154246175
              ],
              [
                -73.75340189507165,
                40.64680746120928
              ],
              [
                -73.7537377082634,
                40.646960423577866
              ],
              [
                -73.75376706809163,
                40.646930780708104
              ],
              [
                -73.7536600374918,
                40.646871139190836
              ],
              [
                -73.75379289369529,
                40.64670560418291
              ],
              [
                -73.75460006764922,
                40.6472007730313
              ],
              [
                -73.75522386899995,
                40.64744446899988
              ],
              [
                -73.75502113300001,
                40.6478138189999
              ],
              [
                -73.75483677999976,
                40.648070902999976
              ],
              [
                -73.7545491879999,
                40.64834992799991
              ],
              [
                -73.75416443199985,
                40.648654094999856
              ],
              [
                -73.75411086699988,
                40.648697664999915
              ],
              [
                -73.75465758099988,
                40.64901209699989
              ],
              [
                -73.7579393749999,
                40.65085330299991
              ],
              [
                -73.76406290099993,
                40.65435626599989
              ],
              [
                -73.76417770199993,
                40.65442150099993
              ],
              [
                -73.76475455399994,
                40.654746896999875
              ],
              [
                -73.76566897099984,
                40.655253468999874
              ],
              [
                -73.76628008299998,
                40.65557821499993
              ],
              [
                -73.76669593900002,
                40.6558028479999
              ],
              [
                -73.76683051599994,
                40.65587605299992
              ],
              [
                -73.76729260999991,
                40.65612444299994
              ],
              [
                -73.76735274299986,
                40.656160125999875
              ],
              [
                -73.76740737399983,
                40.65619021499987
              ],
              [
                -73.7677137589999,
                40.656358979999915
              ],
              [
                -73.76786196699993,
                40.65644061399995
              ],
              [
                -73.768070047,
                40.6565553269999
              ],
              [
                -73.76873168699989,
                40.65692006399991
              ],
              [
                -73.76877799799986,
                40.65694558799989
              ],
              [
                -73.76976145199995,
                40.65748223599992
              ],
              [
                -73.7704997779999,
                40.65787603999991
              ],
              [
                -73.77291920299982,
                40.65912940399993
              ],
              [
                -73.77305501099993,
                40.6591997509999
              ],
              [
                -73.77331158599985,
                40.65934671699995
              ],
              [
                -73.7737837049999,
                40.65962415999996
              ],
              [
                -73.77382964799997,
                40.65965115799987
              ],
              [
                -73.77388075599985,
                40.6596788949999
              ],
              [
                -73.77418923599993,
                40.659860523999924
              ],
              [
                -73.77434521599992,
                40.659932140999906
              ],
              [
                -73.7745200739998,
                40.660012414999926
              ],
              [
                -73.7748492269999,
                40.660148686999946
              ],
              [
                -73.77552237699994,
                40.66036546999993
              ],
              [
                -73.77560556199991,
                40.66038771299989
              ],
              [
                -73.77644444499985,
                40.66062997399993
              ],
              [
                -73.77670850699995,
                40.66070622999995
              ],
              [
                -73.776792695,
                40.66074400799987
              ],
              [
                -73.77849144899989,
                40.661506302999896
              ],
              [
                -73.77864409099983,
                40.66156701299995
              ],
              [
                -73.77871548099994,
                40.66159539999996
              ],
              [
                -73.77876727900002,
                40.661615994999885
              ],
              [
                -73.77932933099991,
                40.661839519999916
              ],
              [
                -73.780755286,
                40.66240660199992
              ],
              [
                -73.78128320799988,
                40.66258308299995
              ],
              [
                -73.78140184699991,
                40.662622741999876
              ],
              [
                -73.78211253799984,
                40.66283540499988
              ],
              [
                -73.78321833499997,
                40.6631662839999
              ],
              [
                -73.784349205,
                40.66340259899992
              ],
              [
                -73.78481455499993,
                40.66349858199991
              ],
              [
                -73.78502513799997,
                40.66342107199989
              ],
              [
                -73.78618470399978,
                40.663609552999944
              ],
              [
                -73.78751177999993,
                40.663825242999906
              ],
              [
                -73.78811679799986,
                40.66390588099991
              ],
              [
                -73.78984347199987,
                40.6641359919999
              ],
              [
                -73.78990759999994,
                40.66414453799994
              ],
              [
                -73.7899673679998,
                40.664153288999955
              ],
              [
                -73.79005979999995,
                40.66416690799991
              ],
              [
                -73.79057832499984,
                40.66424329999992
              ],
              [
                -73.79118919099993,
                40.6643332339999
              ],
              [
                -73.79199464699984,
                40.664439276999865
              ],
              [
                -73.79256433099995,
                40.664514275999856
              ],
              [
                -73.79394317799995,
                40.66470238799994
              ],
              [
                -73.7952263039999,
                40.66487742499989
              ],
              [
                -73.79582296499997,
                40.66495325499988
              ],
              [
                -73.79589506299997,
                40.66496081399988
              ],
              [
                -73.79642246799989,
                40.66501610199989
              ],
              [
                -73.79702409499983,
                40.66506588399988
              ],
              [
                -73.79762708599995,
                40.66510255299991
              ],
              [
                -73.79785485699998,
                40.6651148049999
              ],
              [
                -73.79983020599992,
                40.66522105199994
              ],
              [
                -73.80072889200001,
                40.66526937699989
              ],
              [
                -73.80180049599981,
                40.66525044099991
              ],
              [
                -73.80188667499992,
                40.6652489169999
              ],
              [
                -73.80203207699995,
                40.665248024999926
              ],
              [
                -73.80214866500003,
                40.66524995299992
              ],
              [
                -73.80299527199993,
                40.66521661099991
              ],
              [
                -73.80377524499997,
                40.66516018399992
              ],
              [
                -73.8043205059999,
                40.66512073299992
              ],
              [
                -73.8057239779999,
                40.664960640999894
              ],
              [
                -73.80614396,
                40.66491272999988
              ],
              [
                -73.80764810699986,
                40.66461830599991
              ],
              [
                -73.80779777599999,
                40.664589007999915
              ],
              [
                -73.8084501609999,
                40.66446130099996
              ],
              [
                -73.80884594199996,
                40.66429766899992
              ],
              [
                -73.80945926899989,
                40.66404408899996
              ],
              [
                -73.81006836999995,
                40.66379225199996
              ],
              [
                -73.81061044899995,
                40.6634808539999
              ],
              [
                -73.81116608799994,
                40.663177473999895
              ],
              [
                -73.81176694899993,
                40.6629345349999
              ],
              [
                -73.81228672799995,
                40.66282283799993
              ],
              [
                -73.812816163,
                40.662724435999955
              ],
              [
                -73.81335011199994,
                40.662640078999836
              ],
              [
                -73.81388758999984,
                40.662569925999925
              ],
              [
                -73.81442762999983,
                40.66251410199988
              ],
              [
                -73.81496922499986,
                40.66247266799991
              ],
              [
                -73.81791759599996,
                40.662956015999896
              ],
              [
                -73.82306169099988,
                40.66353701599996
              ],
              [
                -73.82427005699986,
                40.663576563999904
              ],
              [
                -73.82486060999993,
                40.66357678999988
              ],
              [
                -73.8250346749999,
                40.663580133999844
              ]
            ]
          ],
          [
            [
              [
                -73.74661040472857,
                40.638724973885545
              ],
              [
                -73.74666935864037,
                40.63870604063595
              ],
              [
                -73.74664302817305,
                40.63866224980709
              ],
              [
                -73.74659585423827,
                40.6386800868929
              ],
              [
                -73.7466105111792,
                40.63869694045981
              ],
              [
                -73.74654132522075,
                40.63869678799164
              ],
              [
                -73.7465397979704,
                40.63871136266967
              ],
              [
                -73.74660309901053,
                40.638710380738985
              ],
              [
                -73.74661040472857,
                40.638724973885545
              ]
            ]
          ],
          [
            [
              [
                -73.74693721618011,
                40.637556101860085
              ],
              [
                -73.74704316342152,
                40.637566426459216
              ],
              [
                -73.74704913170524,
                40.63754513439954
              ],
              [
                -73.74694320619099,
                40.637529202088494
              ],
              [
                -73.74693721618011,
                40.637556101860085
              ]
            ]
          ],
          [
            [
              [
                -73.74712154262465,
                40.63716227570799
              ],
              [
                -73.7471040262248,
                40.637211259241234
              ],
              [
                -73.74713019919187,
                40.63721686134726
              ],
              [
                -73.74715435857692,
                40.637126363606505
              ],
              [
                -73.7471492449571,
                40.6371258313463
              ],
              [
                -73.74715781614569,
                40.637060524028385
              ],
              [
                -73.74713631374684,
                40.63705806030438
              ],
              [
                -73.74712053523142,
                40.63715124102521
              ],
              [
                -73.74706683249946,
                40.63714316627905
              ],
              [
                -73.74706834853848,
                40.6371373188243
              ],
              [
                -73.74703789109672,
                40.637132742106544
              ],
              [
                -73.74701102390156,
                40.63712870635767
              ],
              [
                -73.74699985119133,
                40.6371270278801
              ],
              [
                -73.74699391228548,
                40.63715064484292
              ],
              [
                -73.74700628446361,
                40.63715245006159
              ],
              [
                -73.74702836326502,
                40.6371556726449
              ],
              [
                -73.74706485671244,
                40.63716099926838
              ],
              [
                -73.74706655474154,
                40.63715424973437
              ],
              [
                -73.74712154262465,
                40.63716227570799
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000157188987507,
        "objectid": 133,
        "shape_leng": 0.065134863162,
        "location_id": 133,
        "zone": "Kensington",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.97819074399992,
                40.64752599199992
              ],
              [
                -73.97911821899996,
                40.64742806099992
              ],
              [
                -73.98029115499993,
                40.64729716099987
              ],
              [
                -73.98132887699992,
                40.64718167299986
              ],
              [
                -73.98180533899996,
                40.64712779899989
              ],
              [
                -73.982309398,
                40.646927403999875
              ],
              [
                -73.98307340099996,
                40.646625133999905
              ],
              [
                -73.98528412799996,
                40.64576793799989
              ],
              [
                -73.98834986699991,
                40.64456224399992
              ],
              [
                -73.98749940600001,
                40.64404550999989
              ],
              [
                -73.98673080599988,
                40.64357399299989
              ],
              [
                -73.9858964449999,
                40.643067594999906
              ],
              [
                -73.985095196,
                40.642573055999925
              ],
              [
                -73.98458719499989,
                40.64226200399989
              ],
              [
                -73.98430293099987,
                40.64208456299986
              ],
              [
                -73.98331628499983,
                40.641478681999914
              ],
              [
                -73.98299419199992,
                40.64132499699987
              ],
              [
                -73.98244454199998,
                40.6415386259999
              ],
              [
                -73.98204321799996,
                40.64170758199987
              ],
              [
                -73.98123240499983,
                40.64204491299995
              ],
              [
                -73.98042057899988,
                40.64238522699991
              ],
              [
                -73.98007428599995,
                40.640539699999906
              ],
              [
                -73.98000693099998,
                40.640208836999896
              ],
              [
                -73.97990983199979,
                40.639686357999906
              ],
              [
                -73.97987360300003,
                40.63951820599989
              ],
              [
                -73.9796770499999,
                40.6384647999999
              ],
              [
                -73.97955441199994,
                40.63781925899985
              ],
              [
                -73.9794759209999,
                40.63743136399991
              ],
              [
                -73.97938644699988,
                40.63693216299988
              ],
              [
                -73.97928591699991,
                40.63641419399992
              ],
              [
                -73.97911188699993,
                40.63544038599991
              ],
              [
                -73.97802159000001,
                40.63555270299985
              ],
              [
                -73.9768886119999,
                40.63567486199991
              ],
              [
                -73.97663655999995,
                40.63435382099991
              ],
              [
                -73.97655064099989,
                40.633903508999886
              ],
              [
                -73.97643342199997,
                40.63328912299988
              ],
              [
                -73.97604935699994,
                40.63127590599987
              ],
              [
                -73.97517146299998,
                40.63168858099992
              ],
              [
                -73.97431883499996,
                40.63209057399993
              ],
              [
                -73.97320194999996,
                40.632618080999876
              ],
              [
                -73.97281084499981,
                40.63280454799989
              ],
              [
                -73.97241686999978,
                40.632992044999874
              ],
              [
                -73.97231136199997,
                40.633041554999885
              ],
              [
                -73.97216145999994,
                40.63311162099996
              ],
              [
                -73.97190427099986,
                40.63323009399994
              ],
              [
                -73.97087567699991,
                40.63371951299991
              ],
              [
                -73.96986869299991,
                40.634194674999904
              ],
              [
                -73.97029036199991,
                40.63640634099988
              ],
              [
                -73.96935965799996,
                40.63650554999993
              ],
              [
                -73.96803868499985,
                40.63665098499994
              ],
              [
                -73.96822343400002,
                40.637296905999946
              ],
              [
                -73.96872218399989,
                40.639018537999895
              ],
              [
                -73.968790992,
                40.639255932999895
              ],
              [
                -73.96891461099989,
                40.63975715999988
              ],
              [
                -73.9693990309999,
                40.64138244599991
              ],
              [
                -73.96948468100001,
                40.641689896999935
              ],
              [
                -73.9696861179999,
                40.642356009999915
              ],
              [
                -73.96988464699999,
                40.64303029699992
              ],
              [
                -73.9700889779999,
                40.643745839999944
              ],
              [
                -73.97031677099991,
                40.64453794099992
              ],
              [
                -73.97054554399976,
                40.645316667999914
              ],
              [
                -73.97076976099986,
                40.64611090699987
              ],
              [
                -73.97084113799984,
                40.64637857099989
              ],
              [
                -73.97174398199986,
                40.64600110999986
              ],
              [
                -73.972085748,
                40.645862324999925
              ],
              [
                -73.97310135399987,
                40.6454509759999
              ],
              [
                -73.9741399689999,
                40.64502975399993
              ],
              [
                -73.97442369399981,
                40.64491306499989
              ],
              [
                -73.974642245,
                40.64481321899993
              ],
              [
                -73.97467086799998,
                40.64493508299989
              ],
              [
                -73.97474803899986,
                40.64526359099989
              ],
              [
                -73.97482741800002,
                40.64566342099994
              ],
              [
                -73.97591918699987,
                40.645544272999935
              ],
              [
                -73.97684760999987,
                40.645442020999944
              ],
              [
                -73.97777380600002,
                40.64534187699998
              ],
              [
                -73.97819074399992,
                40.64752599199992
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000114203907779,
        "objectid": 140,
        "shape_leng": 0.0475842911325,
        "location_id": 140,
        "zone": "Lenox Hill East",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.95854329689507,
                40.75849562296791
              ],
              [
                -73.95849119520824,
                40.75854590391523
              ],
              [
                -73.95845501771939,
                40.75858090209874
              ],
              [
                -73.95842620189605,
                40.75860387300684
              ],
              [
                -73.95838528673994,
                40.75863979740061
              ],
              [
                -73.9583612533255,
                40.75866024195484
              ],
              [
                -73.95825660841928,
                40.758749261193095
              ],
              [
                -73.9580049661682,
                40.758971999629246
              ],
              [
                -73.95763772742096,
                40.75930673989833
              ],
              [
                -73.95759869445274,
                40.75934231825778
              ],
              [
                -73.9573173758794,
                40.759592212256514
              ],
              [
                -73.95697841727626,
                40.75989675444593
              ],
              [
                -73.95633318967775,
                40.76048658556384
              ],
              [
                -73.95587222339078,
                40.760904712115575
              ],
              [
                -73.95557264605307,
                40.761168326230354
              ],
              [
                -73.954418260786,
                40.76218410495111
              ],
              [
                -73.95336559963572,
                40.763368170911676
              ],
              [
                -73.95230963181133,
                40.76455428860604
              ],
              [
                -73.95176480900415,
                40.76516371184316
              ],
              [
                -73.95122497666563,
                40.76577119897351
              ],
              [
                -73.95066345154245,
                40.76638071134941
              ],
              [
                -73.95006979303056,
                40.767025088383264
              ],
              [
                -73.9495791207141,
                40.767628930179555
              ],
              [
                -73.94937332131488,
                40.76788219105435
              ],
              [
                -73.94908536768632,
                40.76827827079564
              ],
              [
                -73.94866416477879,
                40.76885762439947
              ],
              [
                -73.94814040181448,
                40.769416756358105
              ],
              [
                -73.9474897547817,
                40.77011515400953
              ],
              [
                -73.94752257899997,
                40.770129694999895
              ],
              [
                -73.94761452999984,
                40.77017042899995
              ],
              [
                -73.94766538399982,
                40.77019325299993
              ],
              [
                -73.94771625099995,
                40.77021606799992
              ],
              [
                -73.94779385500001,
                40.770249151999934
              ],
              [
                -73.94807387899996,
                40.770394394999876
              ],
              [
                -73.95043560599999,
                40.7713911029999
              ],
              [
                -73.95268514999994,
                40.772339404999926
              ],
              [
                -73.95318747599984,
                40.771656893999925
              ],
              [
                -73.95364859999982,
                40.77102410799993
              ],
              [
                -73.9541124529999,
                40.7703890789999
              ],
              [
                -73.95457540899991,
                40.769754856999946
              ],
              [
                -73.95503700499988,
                40.769120065999864
              ],
              [
                -73.9555017079999,
                40.76848474299992
              ],
              [
                -73.95599908699987,
                40.76780389299994
              ],
              [
                -73.95649564199994,
                40.76711891499989
              ],
              [
                -73.95695607099981,
                40.766493435999884
              ],
              [
                -73.95741202499985,
                40.765868753999875
              ],
              [
                -73.95786874099996,
                40.765242324999896
              ],
              [
                -73.95832133999988,
                40.764616629999864
              ],
              [
                -73.95878081199999,
                40.76399001699986
              ],
              [
                -73.95923908799993,
                40.76336615799994
              ],
              [
                -73.95969458599983,
                40.762740538999935
              ],
              [
                -73.9601506909999,
                40.762114874999945
              ],
              [
                -73.96060649299993,
                40.76148739799985
              ],
              [
                -73.96106754499992,
                40.76085809299985
              ],
              [
                -73.96152208599995,
                40.760230359999916
              ],
              [
                -73.96162070799981,
                40.760094433999896
              ],
              [
                -73.96164363299995,
                40.76005959199991
              ],
              [
                -73.96167816899991,
                40.76001396599987
              ],
              [
                -73.96169733399984,
                40.75998713799995
              ],
              [
                -73.96172701399999,
                40.75994980699992
              ],
              [
                -73.96176766499995,
                40.75989304099994
              ],
              [
                -73.96177668399997,
                40.759879715999936
              ],
              [
                -73.96196573699997,
                40.75961946099988
              ],
              [
                -73.95972180399991,
                40.75866306399995
              ],
              [
                -73.95903681099995,
                40.75838378299988
              ],
              [
                -73.95893448599985,
                40.75833515499994
              ],
              [
                -73.95877790811335,
                40.75827092092435
              ],
              [
                -73.95854329689507,
                40.75849562296791
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000467912240542,
        "objectid": 135,
        "shape_leng": 0.121793045659,
        "location_id": 135,
        "zone": "Kew Gardens Hills",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.83126834299985,
                40.740262275999925
              ],
              [
                -73.83122032399993,
                40.74095321999991
              ],
              [
                -73.83117294899978,
                40.74163764599994
              ],
              [
                -73.83113122799989,
                40.742337999999954
              ],
              [
                -73.83207064899982,
                40.74245093799993
              ],
              [
                -73.83301515399995,
                40.742600957999905
              ],
              [
                -73.83382572699993,
                40.74276242099988
              ],
              [
                -73.83586131899989,
                40.74322798499995
              ],
              [
                -73.8360998919999,
                40.7424290689999
              ],
              [
                -73.83687304099996,
                40.742137546999864
              ],
              [
                -73.83690407799999,
                40.742125838999904
              ],
              [
                -73.83699037399995,
                40.74209330099992
              ],
              [
                -73.83710720699999,
                40.74139622899986
              ],
              [
                -73.83696272799982,
                40.7406566619999
              ],
              [
                -73.836792774,
                40.73990191099992
              ],
              [
                -73.83675088199985,
                40.739720533999936
              ],
              [
                -73.83669458799986,
                40.73954125399997
              ],
              [
                -73.83662419799994,
                40.739365059999905
              ],
              [
                -73.83627527100005,
                40.73956837599993
              ],
              [
                -73.83564585599999,
                40.73849101099988
              ],
              [
                -73.83563372699986,
                40.73711162999992
              ],
              [
                -73.83574733199998,
                40.73699114199996
              ],
              [
                -73.8361887679999,
                40.73562665299992
              ],
              [
                -73.83628669399984,
                40.735131366999966
              ],
              [
                -73.83635625099998,
                40.73500859199989
              ],
              [
                -73.83612778599989,
                40.73424131199993
              ],
              [
                -73.83606223299994,
                40.734098694999865
              ],
              [
                -73.83600708999997,
                40.73397874199989
              ],
              [
                -73.83584824699987,
                40.73383374799992
              ],
              [
                -73.83489939499987,
                40.73296764199992
              ],
              [
                -73.83448336599982,
                40.73216391599991
              ],
              [
                -73.83400368799985,
                40.731567709999915
              ],
              [
                -73.83386267199987,
                40.73055318899989
              ],
              [
                -73.83307087999992,
                40.72991903099995
              ],
              [
                -73.832895707,
                40.729586330999915
              ],
              [
                -73.83269855399999,
                40.72925986199991
              ],
              [
                -73.83248002199991,
                40.728940853999916
              ],
              [
                -73.83224087999997,
                40.72863052099991
              ],
              [
                -73.83198207999989,
                40.728329997999886
              ],
              [
                -73.83170465899991,
                40.72804033199986
              ],
              [
                -73.83140452799991,
                40.727597621999976
              ],
              [
                -73.83086471400001,
                40.72670963399989
              ],
              [
                -73.83073214599987,
                40.72654932799992
              ],
              [
                -73.83057976800002,
                40.72639650399987
              ],
              [
                -73.83057961499995,
                40.72639635099988
              ],
              [
                -73.83057943699991,
                40.72639619699988
              ],
              [
                -73.83040824999995,
                40.726252862999914
              ],
              [
                -73.83040820199987,
                40.726252827999915
              ],
              [
                -73.83040815499996,
                40.726252799999955
              ],
              [
                -73.83021964599999,
                40.72612077799986
              ],
              [
                -73.83021953999996,
                40.72612069599989
              ],
              [
                -73.83021940999994,
                40.72612062499991
              ],
              [
                -73.8300158949998,
                40.72600180799987
              ],
              [
                -73.83001569299999,
                40.72600168999989
              ],
              [
                -73.83001549299993,
                40.72600159099995
              ],
              [
                -73.8297991689999,
                40.72589728099996
              ],
              [
                -73.82979910999985,
                40.72589725399996
              ],
              [
                -73.8297990389998,
                40.72589722599989
              ],
              [
                -73.82957260399982,
                40.72580846999996
              ],
              [
                -73.82957252099986,
                40.72580843399988
              ],
              [
                -73.829572438,
                40.72580840699991
              ],
              [
                -73.82933887099998,
                40.725735938999925
              ],
              [
                -73.82933877699988,
                40.72573592099995
              ],
              [
                -73.82910097699987,
                40.72567999799992
              ],
              [
                -73.82887984299998,
                40.725638253999925
              ],
              [
                -73.828879714,
                40.72563822699992
              ],
              [
                -73.82887959600001,
                40.725638191999906
              ],
              [
                -73.82866154199978,
                40.72558142299994
              ],
              [
                -73.82844909199999,
                40.72550982199992
              ],
              [
                -73.82824491699995,
                40.72542399599991
              ],
              [
                -73.82805160699986,
                40.72532493099989
              ],
              [
                -73.82805145299996,
                40.72532484999992
              ],
              [
                -73.8280513119999,
                40.72532475999987
              ],
              [
                -73.82787086399985,
                40.72521360999989
              ],
              [
                -73.82770512199998,
                40.72509185599993
              ],
              [
                -73.8277049809999,
                40.725091747999905
              ],
              [
                -73.827704851,
                40.7250916299999
              ],
              [
                -73.82755527699996,
                40.72496094999984
              ],
              [
                -73.82742447699991,
                40.724807702999925
              ],
              [
                -73.82742443099994,
                40.72480765799986
              ],
              [
                -73.82742439499987,
                40.724807603999935
              ],
              [
                -73.8273090039999,
                40.724646076999896
              ],
              [
                -73.82721028599993,
                40.7244775779999
              ],
              [
                -73.8271293019999,
                40.72430378399993
              ],
              [
                -73.82712926599994,
                40.72430371199989
              ],
              [
                -73.82712924299985,
                40.7243036399999
              ],
              [
                -73.82706662699995,
                40.7241260829999
              ],
              [
                -73.82702267100002,
                40.72394639099993
              ],
              [
                -73.82672635699993,
                40.723081599999894
              ],
              [
                -73.82585945699992,
                40.72046263199995
              ],
              [
                -73.82574130299989,
                40.72022165599989
              ],
              [
                -73.82564380900001,
                40.71997413799995
              ],
              [
                -73.825643726,
                40.71997393899991
              ],
              [
                -73.82564366799976,
                40.71997373299991
              ],
              [
                -73.82556770399987,
                40.71972116799993
              ],
              [
                -73.82556765699991,
                40.71972101499993
              ],
              [
                -73.82556762200001,
                40.71972086999989
              ],
              [
                -73.82551375400003,
                40.71946457499995
              ],
              [
                -73.82551374199984,
                40.719464530999865
              ],
              [
                -73.82551374199993,
                40.719464485999936
              ],
              [
                -73.8254823199998,
                40.71920620799989
              ],
              [
                -73.82548231999995,
                40.7192061349999
              ],
              [
                -73.82547346900002,
                40.71894785599994
              ],
              [
                -73.82547345799988,
                40.71894765699991
              ],
              [
                -73.82547346999992,
                40.71894746799989
              ],
              [
                -73.82548692499986,
                40.71869066199986
              ],
              [
                -73.82545983199994,
                40.71799986599993
              ],
              [
                -73.8254192649999,
                40.717392543999935
              ],
              [
                -73.82531644999993,
                40.71727569999995
              ],
              [
                -73.82518614699985,
                40.717288189999884
              ],
              [
                -73.82487503799997,
                40.71732243199994
              ],
              [
                -73.8237298389999,
                40.717437003999855
              ],
              [
                -73.82332432099994,
                40.71745250999989
              ],
              [
                -73.82291752899988,
                40.71745256099993
              ],
              [
                -73.82251099399988,
                40.717437075999925
              ],
              [
                -73.82210623799986,
                40.71740613099991
              ],
              [
                -73.82170479099999,
                40.717359889999905
              ],
              [
                -73.82085213599987,
                40.71734887699994
              ],
              [
                -73.81962622499991,
                40.7173404429999
              ],
              [
                -73.81952343899992,
                40.71733973299991
              ],
              [
                -73.8191283259999,
                40.71734991899995
              ],
              [
                -73.81732944799997,
                40.717660456999916
              ],
              [
                -73.81725907799989,
                40.71763787699993
              ],
              [
                -73.81729568999994,
                40.71757964499995
              ],
              [
                -73.81887154599994,
                40.71725932899994
              ],
              [
                -73.81907861099985,
                40.71720434499988
              ],
              [
                -73.81930469499987,
                40.71713608099988
              ],
              [
                -73.82005442499995,
                40.716810332999955
              ],
              [
                -73.82018883899984,
                40.716735223999855
              ],
              [
                -73.81948747099985,
                40.71647859799987
              ],
              [
                -73.81931944099983,
                40.71640861399996
              ],
              [
                -73.81875295799986,
                40.71617287499986
              ],
              [
                -73.81852868899988,
                40.71608211599988
              ],
              [
                -73.81828452799981,
                40.71600371399997
              ],
              [
                -73.81789512299984,
                40.71591837099992
              ],
              [
                -73.817780212,
                40.71590104799987
              ],
              [
                -73.81767650099987,
                40.71588471999992
              ],
              [
                -73.81743591099992,
                40.71585731399992
              ],
              [
                -73.81720417899986,
                40.715840727999925
              ],
              [
                -73.81680638099989,
                40.71582792999988
              ],
              [
                -73.81643940199992,
                40.7158318299999
              ],
              [
                -73.8163608039998,
                40.71583237099991
              ],
              [
                -73.8162894489999,
                40.71583316699989
              ],
              [
                -73.81595932699985,
                40.71584062699988
              ],
              [
                -73.81524262499985,
                40.715856831999936
              ],
              [
                -73.81364203999982,
                40.71589299199993
              ],
              [
                -73.81291344199987,
                40.715896708999885
              ],
              [
                -73.81151841199983,
                40.7159049969999
              ],
              [
                -73.81114665699984,
                40.71590720599988
              ],
              [
                -73.81022434999987,
                40.71591266799988
              ],
              [
                -73.80780927699995,
                40.715853895999906
              ],
              [
                -73.80782231800002,
                40.71591146599993
              ],
              [
                -73.80784387599995,
                40.715999733999915
              ],
              [
                -73.80809856399999,
                40.71680213799987
              ],
              [
                -73.80823529499995,
                40.71723813199988
              ],
              [
                -73.80861283999984,
                40.71844559999988
              ],
              [
                -73.80902609799992,
                40.7198627919999
              ],
              [
                -73.80904032699983,
                40.71991160499987
              ],
              [
                -73.80808460099985,
                40.72022476199991
              ],
              [
                -73.80786105999998,
                40.72030243799989
              ],
              [
                -73.80722012300005,
                40.72052512499991
              ],
              [
                -73.80636387699998,
                40.72082022699994
              ],
              [
                -73.80578893899983,
                40.7210187299999
              ],
              [
                -73.80550258899996,
                40.72111680899995
              ],
              [
                -73.80475913799987,
                40.72138373999997
              ],
              [
                -73.8045993129999,
                40.721441120999934
              ],
              [
                -73.80452680199981,
                40.721462545999884
              ],
              [
                -73.80453391999993,
                40.72151758799988
              ],
              [
                -73.8045963239999,
                40.72220220899997
              ],
              [
                -73.80466720799983,
                40.72290600699991
              ],
              [
                -73.80473910599993,
                40.72363153599985
              ],
              [
                -73.80480892999992,
                40.72435438799994
              ],
              [
                -73.80487958299982,
                40.725049592999895
              ],
              [
                -73.80497988299993,
                40.72503869699989
              ],
              [
                -73.80652142399995,
                40.7248730639999
              ],
              [
                -73.80665182000001,
                40.72555305099993
              ],
              [
                -73.80678542899982,
                40.72623219899988
              ],
              [
                -73.80691166299997,
                40.726912665999926
              ],
              [
                -73.80704045699993,
                40.72759650399991
              ],
              [
                -73.80717042299993,
                40.728269132999884
              ],
              [
                -73.80806424099987,
                40.728170002999896
              ],
              [
                -73.80895751599999,
                40.72807345899994
              ],
              [
                -73.80985637299993,
                40.72797360399989
              ],
              [
                -73.8111179729999,
                40.727816415999946
              ],
              [
                -73.81101008099996,
                40.72743995099991
              ],
              [
                -73.81088635099984,
                40.72717185599988
              ],
              [
                -73.81080551900001,
                40.72704547999993
              ],
              [
                -73.81080542499983,
                40.727045334999914
              ],
              [
                -73.8108053549999,
                40.72704517299991
              ],
              [
                -73.81074444399991,
                40.72691102299994
              ],
              [
                -73.81074438399986,
                40.726910887999985
              ],
              [
                -73.81074434999985,
                40.72691076199989
              ],
              [
                -73.81070503300002,
                40.726771351999915
              ],
              [
                -73.81070500999995,
                40.72677128899988
              ],
              [
                -73.81070499699992,
                40.72677121699991
              ],
              [
                -73.81068816499996,
                40.726629718999966
              ],
              [
                -73.81068815299979,
                40.72662962799992
              ],
              [
                -73.81068815299999,
                40.72662953899988
              ],
              [
                -73.810693702,
                40.72648898699988
              ],
              [
                -73.81074426599986,
                40.726265112999926
              ],
              [
                -73.81151613699981,
                40.72665799399994
              ],
              [
                -73.81246807499996,
                40.72713304499993
              ],
              [
                -73.81280168299986,
                40.72729564399991
              ],
              [
                -73.81394369400002,
                40.72785224499995
              ],
              [
                -73.81443976499985,
                40.72809402299991
              ],
              [
                -73.81459667899993,
                40.72815359199995
              ],
              [
                -73.81459693999996,
                40.72815369099989
              ],
              [
                -73.81459716399995,
                40.72815380899989
              ],
              [
                -73.8147455559999,
                40.72822995299987
              ],
              [
                -73.81474579299991,
                40.72823006999991
              ],
              [
                -73.81474600600002,
                40.72823021399993
              ],
              [
                -73.81488170099983,
                40.72832189699996
              ],
              [
                -73.81488183099987,
                40.72832198699994
              ],
              [
                -73.81488194899997,
                40.72832209599993
              ],
              [
                -73.81500091699995,
                40.728427258999936
              ],
              [
                -73.81500104699994,
                40.728427377999914
              ],
              [
                -73.81500114199996,
                40.72842749499996
              ],
              [
                -73.81510007599998,
                40.728543224999875
              ],
              [
                -73.81510024099985,
                40.72854342299992
              ],
              [
                -73.81510037099987,
                40.728543638999895
              ],
              [
                -73.81517723199983,
                40.72866662799988
              ],
              [
                -73.81517732599985,
                40.72866678199994
              ],
              [
                -73.81517739599994,
                40.72866695299993
              ],
              [
                -73.81523144899997,
                40.72879391199996
              ],
              [
                -73.81514620799994,
                40.729685964999945
              ],
              [
                -73.81504493700004,
                40.730398834999946
              ],
              [
                -73.81502127700006,
                40.73046566999987
              ],
              [
                -73.81502002699983,
                40.7304938719999
              ],
              [
                -73.81497572599984,
                40.731157492999934
              ],
              [
                -73.81497202699997,
                40.731212908999915
              ],
              [
                -73.81496454700003,
                40.73132495999995
              ],
              [
                -73.81496058399995,
                40.7313843389999
              ],
              [
                -73.81494260199989,
                40.73165369899991
              ],
              [
                -73.81493851899984,
                40.731714870999916
              ],
              [
                -73.81492830299987,
                40.73186790399991
              ],
              [
                -73.81492410799987,
                40.731930747999854
              ],
              [
                -73.81492083199987,
                40.731979832999855
              ],
              [
                -73.81491646899985,
                40.73203762099995
              ],
              [
                -73.81491341999994,
                40.732078588999904
              ],
              [
                -73.81489836700001,
                40.73230923099992
              ],
              [
                -73.81489282099993,
                40.732394229999976
              ],
              [
                -73.81488551400004,
                40.73250620899991
              ],
              [
                -73.81487971599991,
                40.732595050999876
              ],
              [
                -73.81487391699997,
                40.732683920999904
              ],
              [
                -73.814868106,
                40.732772954999895
              ],
              [
                -73.81486255999994,
                40.732857955999854
              ],
              [
                -73.81485676300004,
                40.73294679699997
              ],
              [
                -73.81485170599991,
                40.73302427699986
              ],
              [
                -73.81484566899994,
                40.733116797999905
              ],
              [
                -73.81483811099991,
                40.7332326189999
              ],
              [
                -73.81483204799986,
                40.73332552499991
              ],
              [
                -73.81482699400004,
                40.733402975999944
              ],
              [
                -73.81481996299976,
                40.733510727999935
              ],
              [
                -73.81481416399997,
                40.73359959799995
              ],
              [
                -73.81480147399989,
                40.733799159999926
              ],
              [
                -73.81477621499994,
                40.73424680499987
              ],
              [
                -73.81475908299986,
                40.73455042099994
              ],
              [
                -73.81475217299992,
                40.734701017999946
              ],
              [
                -73.81474261099987,
                40.73490936899986
              ],
              [
                -73.81472294099993,
                40.73533802999991
              ],
              [
                -73.8146916309999,
                40.73594999299991
              ],
              [
                -73.81469391899994,
                40.737140479999915
              ],
              [
                -73.81483959999986,
                40.73791441399993
              ],
              [
                -73.81497208299996,
                40.738560715999874
              ],
              [
                -73.81501663899991,
                40.738558544999876
              ],
              [
                -73.81518583099992,
                40.73857117099989
              ],
              [
                -73.8160767679998,
                40.73885793399994
              ],
              [
                -73.81633109399993,
                40.738446400999905
              ],
              [
                -73.81647443699993,
                40.73840887099994
              ],
              [
                -73.81662531599994,
                40.73838631099992
              ],
              [
                -73.81677959599985,
                40.73837979399984
              ],
              [
                -73.81693283800001,
                40.738389509999934
              ],
              [
                -73.81708075599988,
                40.738414734999886
              ],
              [
                -73.81837989599987,
                40.73859373999994
              ],
              [
                -73.82006431399985,
                40.73882813999993
              ],
              [
                -73.82089716499988,
                40.739002110999934
              ],
              [
                -73.82177826400005,
                40.739193646999894
              ],
              [
                -73.82266847299987,
                40.739384992999916
              ],
              [
                -73.82355883499997,
                40.73957874499993
              ],
              [
                -73.8243501109999,
                40.73975360599993
              ],
              [
                -73.82481178499988,
                40.739630959999886
              ],
              [
                -73.82494800899998,
                40.73956710599992
              ],
              [
                -73.8249744,
                40.73944042699991
              ],
              [
                -73.8254265879999,
                40.7394851379999
              ],
              [
                -73.82559030399982,
                40.73944759199988
              ],
              [
                -73.82585368399998,
                40.739123779999886
              ],
              [
                -73.82634965799993,
                40.73884326499992
              ],
              [
                -73.82686746499992,
                40.73862891199984
              ],
              [
                -73.82718901699991,
                40.738549870999904
              ],
              [
                -73.82759017299992,
                40.73849421699993
              ],
              [
                -73.82788031199985,
                40.73848979699993
              ],
              [
                -73.8279436029999,
                40.738489981999884
              ],
              [
                -73.82794969099994,
                40.73781959899991
              ],
              [
                -73.82809152599998,
                40.73708317499995
              ],
              [
                -73.8294675199999,
                40.737024482999914
              ],
              [
                -73.83053551299994,
                40.737520194999895
              ],
              [
                -73.83143025500004,
                40.739135732999955
              ],
              [
                -73.8313011729999,
                40.73956106299991
              ],
              [
                -73.83126834299985,
                40.740262275999925
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000148524994129,
        "objectid": 136,
        "shape_leng": 0.0906287154122,
        "location_id": 136,
        "zone": "Kingsbridge Heights",
        "borough": "Bronx"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.89996501599988,
                40.86801852399987
              ],
              [
                -73.90106211299995,
                40.86828264799995
              ],
              [
                -73.90235804799984,
                40.8686961209999
              ],
              [
                -73.90318667499999,
                40.86914899099989
              ],
              [
                -73.90374975899987,
                40.869595626999924
              ],
              [
                -73.90403592599995,
                40.86994916299995
              ],
              [
                -73.90442003099997,
                40.87036127599988
              ],
              [
                -73.90448860599989,
                40.870578548999895
              ],
              [
                -73.9044342879998,
                40.87224530899994
              ],
              [
                -73.90444007299996,
                40.872330327999855
              ],
              [
                -73.90444008499996,
                40.872330418999866
              ],
              [
                -73.90444010799983,
                40.87233049899992
              ],
              [
                -73.90446896699991,
                40.872415394999905
              ],
              [
                -73.90446901399983,
                40.87241552099991
              ],
              [
                -73.90446908599996,
                40.872415627999935
              ],
              [
                -73.90452057499998,
                40.87249502299987
              ],
              [
                -73.90452070499992,
                40.87249521099987
              ],
              [
                -73.90452088299993,
                40.872495391999976
              ],
              [
                -73.90459188899993,
                40.872564445999956
              ],
              [
                -73.90459194899984,
                40.87256449999991
              ],
              [
                -73.90459203199988,
                40.872564553999894
              ],
              [
                -73.904677283,
                40.872619878999934
              ],
              [
                -73.905714254,
                40.87313832199988
              ],
              [
                -73.90586616799999,
                40.873182659999884
              ],
              [
                -73.90591126399978,
                40.87319581699988
              ],
              [
                -73.90601809399988,
                40.8732269889999
              ],
              [
                -73.90608130599988,
                40.873239628999904
              ],
              [
                -73.90614668899998,
                40.87326020499995
              ],
              [
                -73.90636316599998,
                40.873319047999885
              ],
              [
                -73.90662887099994,
                40.87338037899989
              ],
              [
                -73.90669984700006,
                40.87339676199991
              ],
              [
                -73.90746489699994,
                40.873547353999854
              ],
              [
                -73.9077427079998,
                40.87284595199988
              ],
              [
                -73.90846367899988,
                40.87262079199995
              ],
              [
                -73.90872046399987,
                40.87222866599997
              ],
              [
                -73.90893235220783,
                40.87215734798525
              ],
              [
                -73.90864943177567,
                40.87138578380337
              ],
              [
                -73.90893714929904,
                40.87101984030879
              ],
              [
                -73.9092746410738,
                40.869554132885696
              ],
              [
                -73.90949664312228,
                40.86883115714293
              ],
              [
                -73.9096669484779,
                40.86841703959004
              ],
              [
                -73.90986176427579,
                40.867943315616394
              ],
              [
                -73.9101746817974,
                40.86719121511589
              ],
              [
                -73.91035983824382,
                40.86687689140605
              ],
              [
                -73.91034888650508,
                40.86683249964201
              ],
              [
                -73.9103450122364,
                40.86678743190789
              ],
              [
                -73.91034827112132,
                40.866742336116
              ],
              [
                -73.91035861629439,
                40.866697860580764
              ],
              [
                -73.91037589901607,
                40.866654644698166
              ],
              [
                -73.91039987081115,
                40.866613309754015
              ],
              [
                -73.91041774440954,
                40.86656954469076
              ],
              [
                -73.91044282747865,
                40.86652786855185
              ],
              [
                -73.91047470999959,
                40.86648896257217
              ],
              [
                -73.91051287081301,
                40.86645346270479
              ],
              [
                -73.9105566861374,
                40.86642194922577
              ],
              [
                -73.91091292411947,
                40.86593794649016
              ],
              [
                -73.9126763402038,
                40.86402111160246
              ],
              [
                -73.9125260451679,
                40.86379522383775
              ],
              [
                -73.9127305402088,
                40.8639097902769
              ],
              [
                -73.91271607314991,
                40.86392622943414
              ],
              [
                -73.91275216216489,
                40.8639450568429
              ],
              [
                -73.91279762128934,
                40.86389887543283
              ],
              [
                -73.91276050612291,
                40.86387691390986
              ],
              [
                -73.91274397646826,
                40.863892568173036
              ],
              [
                -73.91227278976973,
                40.86363214899671
              ],
              [
                -73.91276271995461,
                40.86302917415757
              ],
              [
                -73.91341462193644,
                40.86336749370071
              ],
              [
                -73.91422823999919,
                40.86249079035963
              ],
              [
                -73.91343151700002,
                40.86213906599987
              ],
              [
                -73.91335994599989,
                40.86211267299989
              ],
              [
                -73.91329746899991,
                40.86208962899986
              ],
              [
                -73.91295585399997,
                40.86191313599993
              ],
              [
                -73.91278962899986,
                40.86187247099988
              ],
              [
                -73.91273784299996,
                40.86186388599992
              ],
              [
                -73.91266095199984,
                40.861851634999894
              ],
              [
                -73.91245929699984,
                40.8618204329999
              ],
              [
                -73.91208148799987,
                40.86176800699992
              ],
              [
                -73.91194992699994,
                40.861750140999966
              ],
              [
                -73.91181229199991,
                40.861732385999886
              ],
              [
                -73.91075595799987,
                40.86165148399988
              ],
              [
                -73.909866682,
                40.862310067999914
              ],
              [
                -73.90972193999986,
                40.86240666799993
              ],
              [
                -73.90956472199993,
                40.862493010999856
              ],
              [
                -73.909397036,
                40.86256777199988
              ],
              [
                -73.90922113399998,
                40.86262994799992
              ],
              [
                -73.9090395089999,
                40.86267886399991
              ],
              [
                -73.90905267599997,
                40.86253910899997
              ],
              [
                -73.90905268799997,
                40.86253892899989
              ],
              [
                -73.90905273599994,
                40.862538738999966
              ],
              [
                -73.90908816499991,
                40.862399911999965
              ],
              [
                -73.90908822499988,
                40.86239969599988
              ],
              [
                -73.90908830799995,
                40.8623994979999
              ],
              [
                -73.90914581699987,
                40.86226429799993
              ],
              [
                -73.90914584099987,
                40.862264244999956
              ],
              [
                -73.90914587699984,
                40.86226418999996
              ],
              [
                -73.90922418100003,
                40.86213565299996
              ],
              [
                -73.909224264,
                40.86213551799989
              ],
              [
                -73.90922437099981,
                40.8621353919999
              ],
              [
                -73.9093213549998,
                40.862016161999854
              ],
              [
                -73.90947846399986,
                40.861808334999886
              ],
              [
                -73.90947854799991,
                40.86180822799987
              ],
              [
                -73.90947862999988,
                40.86180812899987
              ],
              [
                -73.90965329599997,
                40.86160687999992
              ],
              [
                -73.9098448269999,
                40.861413369999916
              ],
              [
                -73.91005219099996,
                40.86122888599988
              ],
              [
                -73.91027422499997,
                40.861054534999894
              ],
              [
                -73.91050960899994,
                40.86089126999988
              ],
              [
                -73.91075688399998,
                40.8607399549999
              ],
              [
                -73.9110144729998,
                40.8606012619999
              ],
              [
                -73.91114937399995,
                40.86052367099993
              ],
              [
                -73.9112955739999,
                40.860457380999875
              ],
              [
                -73.91145048700004,
                40.86040372399992
              ],
              [
                -73.91161125399995,
                40.860363549999896
              ],
              [
                -73.91187557299992,
                40.86029780899986
              ],
              [
                -73.91213471699986,
                40.860217493999876
              ],
              [
                -73.91238663199994,
                40.86012299899992
              ],
              [
                -73.91262932399985,
                40.860014943999914
              ],
              [
                -73.9128609299999,
                40.859894146999856
              ],
              [
                -73.91307976699996,
                40.85976164299994
              ],
              [
                -73.91328437100006,
                40.859618600999916
              ],
              [
                -73.91088488399993,
                40.858776182999925
              ],
              [
                -73.91072104299988,
                40.8587062319999
              ],
              [
                -73.91056799299989,
                40.858620592999955
              ],
              [
                -73.91042952699988,
                40.85852085699992
              ],
              [
                -73.91030880899984,
                40.85840932699995
              ],
              [
                -73.91020818099982,
                40.858288788999886
              ],
              [
                -73.90980269899988,
                40.85760754899993
              ],
              [
                -73.90976165899988,
                40.85718261199991
              ],
              [
                -73.90956720099997,
                40.8575916239999
              ],
              [
                -73.90777320599983,
                40.85960753799986
              ],
              [
                -73.90725786199998,
                40.85935343799985
              ],
              [
                -73.90707003899985,
                40.85936184899989
              ],
              [
                -73.90679734899983,
                40.85937358899988
              ],
              [
                -73.90572262499991,
                40.85899298899991
              ],
              [
                -73.90486847199993,
                40.8586932249999
              ],
              [
                -73.90447464099996,
                40.859341594999876
              ],
              [
                -73.90408132700006,
                40.85998784799989
              ],
              [
                -73.90351390500003,
                40.860917509999865
              ],
              [
                -73.90254479399998,
                40.86057825499989
              ],
              [
                -73.90233887199986,
                40.86095497099996
              ],
              [
                -73.902117203,
                40.86132694799996
              ],
              [
                -73.90188007299994,
                40.86169362499993
              ],
              [
                -73.90162785099997,
                40.86205443399998
              ],
              [
                -73.901360904,
                40.862408864999956
              ],
              [
                -73.90107966999994,
                40.86275638299992
              ],
              [
                -73.90027587299991,
                40.86378763499995
              ],
              [
                -73.89887282999976,
                40.86558402699991
              ],
              [
                -73.89797842699994,
                40.866727707999885
              ],
              [
                -73.8974032329998,
                40.86747418999991
              ],
              [
                -73.89656060599998,
                40.868565199999935
              ],
              [
                -73.89891854299988,
                40.86965857399992
              ],
              [
                -73.8990732729999,
                40.86952535499991
              ],
              [
                -73.89921387699998,
                40.86938138699989
              ],
              [
                -73.89933851199986,
                40.86922802799989
              ],
              [
                -73.89944561599987,
                40.86906684199993
              ],
              [
                -73.8995340439999,
                40.86889961299995
              ],
              [
                -73.89960300399986,
                40.868728207999894
              ],
              [
                -73.89965220299995,
                40.86855457799991
              ],
              [
                -73.89942436199988,
                40.86788083799993
              ],
              [
                -73.89996501599988,
                40.86801852399987
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000116455098947,
        "objectid": 137,
        "shape_leng": 0.0461076242707,
        "location_id": 137,
        "zone": "Kips Bay",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.97219641647804,
                40.74040257875024
              ],
              [
                -73.97223566211,
                40.74040541522519
              ],
              [
                -73.97223504317171,
                40.74041340340201
              ],
              [
                -73.9722251561433,
                40.740416696931206
              ],
              [
                -73.97221525425884,
                40.740426563519435
              ],
              [
                -73.97216549760822,
                40.74067216504988
              ],
              [
                -73.97216477368652,
                40.74067572212913
              ],
              [
                -73.97216848387873,
                40.740693120300485
              ],
              [
                -73.9721826857009,
                40.74070627990965
              ],
              [
                -73.97219567275307,
                40.74070957946137
              ],
              [
                -73.9722128259937,
                40.740711663608245
              ],
              [
                -73.97228992099222,
                40.74072103903176
              ],
              [
                -73.97228618248482,
                40.74073827407896
              ],
              [
                -73.97219804813778,
                40.74114466869176
              ],
              [
                -73.97215263572176,
                40.74138858760125
              ],
              [
                -73.97210163980672,
                40.741623202060914
              ],
              [
                -73.97206949682803,
                40.741788905934726
              ],
              [
                -73.97204926316721,
                40.7418877537763
              ],
              [
                -73.97195955856255,
                40.74233792154363
              ],
              [
                -73.9717750124801,
                40.74261591264713
              ],
              [
                -73.97174059715836,
                40.74266357950899
              ],
              [
                -73.97176261327057,
                40.74267267153466
              ],
              [
                -73.97188888913038,
                40.7427248203555
              ],
              [
                -73.97167921200406,
                40.74305446402989
              ],
              [
                -73.97191659199993,
                40.74313739399983
              ],
              [
                -73.97198880299989,
                40.74316289599995
              ],
              [
                -73.97206165299998,
                40.74318802999993
              ],
              [
                -73.97210897599994,
                40.74320549099992
              ],
              [
                -73.97223028499984,
                40.743249026999884
              ],
              [
                -73.97350835000003,
                40.743782033999885
              ],
              [
                -73.97522419999986,
                40.744504681999906
              ],
              [
                -73.97588117699995,
                40.74477922399991
              ],
              [
                -73.97735988999995,
                40.74540421599994
              ],
              [
                -73.97812378199983,
                40.74572717299987
              ],
              [
                -73.97860713299984,
                40.7450578309999
              ],
              [
                -73.9790584949999,
                40.7444435519999
              ],
              [
                -73.97950790899995,
                40.743825200999915
              ],
              [
                -73.97995899799996,
                40.743205200999924
              ],
              [
                -73.98040998099987,
                40.74258715999988
              ],
              [
                -73.98085965399993,
                40.741969767999905
              ],
              [
                -73.98131180499988,
                40.74135092399991
              ],
              [
                -73.98176150799992,
                40.740733112999905
              ],
              [
                -73.98221134499993,
                40.7401145349999
              ],
              [
                -73.98266497499989,
                40.73949733299986
              ],
              [
                -73.98041832999985,
                40.73855053099987
              ],
              [
                -73.97949062699986,
                40.73815979199986
              ],
              [
                -73.97891574499987,
                40.737917658999876
              ],
              [
                -73.97805226499996,
                40.7375539539999
              ],
              [
                -73.97849845599995,
                40.73679095699989
              ],
              [
                -73.97833820599989,
                40.7367214709999
              ],
              [
                -73.97792798399998,
                40.73654414599988
              ],
              [
                -73.97712441199997,
                40.736196797999895
              ],
              [
                -73.9763637329999,
                40.73586797799992
              ],
              [
                -73.97589093699993,
                40.735673198999955
              ],
              [
                -73.97522275599997,
                40.73539790799989
              ],
              [
                -73.97511055299996,
                40.73539025499991
              ],
              [
                -73.97495992999993,
                40.73536871799993
              ],
              [
                -73.9748930509999,
                40.73537519599992
              ],
              [
                -73.9748261859999,
                40.73538167399991
              ],
              [
                -73.97474900599997,
                40.73542518899997
              ],
              [
                -73.97467193099992,
                40.73539830299991
              ],
              [
                -73.97443700979296,
                40.73531635606464
              ],
              [
                -73.9735447289197,
                40.73494432655072
              ],
              [
                -73.97352270834288,
                40.734975360846946
              ],
              [
                -73.97345945915038,
                40.73496837685119
              ],
              [
                -73.97341821248345,
                40.73497115816109
              ],
              [
                -73.97336870942664,
                40.73498927476267
              ],
              [
                -73.97332929043593,
                40.735010877391716
              ],
              [
                -73.97329627362959,
                40.7350408566426
              ],
              [
                -73.97327700851481,
                40.73507919579837
              ],
              [
                -73.97327241144049,
                40.7351189338514
              ],
              [
                -73.97327881634085,
                40.73514752741424
              ],
              [
                -73.97329897672428,
                40.73517681588363
              ],
              [
                -73.97331638345146,
                40.735196342781975
              ],
              [
                -73.97332187458159,
                40.73520191866486
              ],
              [
                -73.97329895046123,
                40.735242355077084
              ],
              [
                -73.97303684713691,
                40.7351279481501
              ],
              [
                -73.97300291199267,
                40.73517256003519
              ],
              [
                -73.9729708363559,
                40.735157216971274
              ],
              [
                -73.97299560236563,
                40.73512793848568
              ],
              [
                -73.97294519031807,
                40.7351077005131
              ],
              [
                -73.97296719785788,
                40.73507843076685
              ],
              [
                -73.97294520758678,
                40.73506517007111
              ],
              [
                -73.97283745943452,
                40.73519971670444
              ],
              [
                -73.97286403116168,
                40.735212267015186
              ],
              [
                -73.97288512856927,
                40.73518020390186
              ],
              [
                -73.97293185609784,
                40.73520531236901
              ],
              [
                -73.9729639526808,
                40.735169066679866
              ],
              [
                -73.97299695202474,
                40.73518650689114
              ],
              [
                -73.97276308380349,
                40.73550508747356
              ],
              [
                -73.9729303192644,
                40.73560761439672
              ],
              [
                -73.97293673973691,
                40.73559924929959
              ],
              [
                -73.97280661710455,
                40.73552391852113
              ],
              [
                -73.97305698712053,
                40.73517745341554
              ],
              [
                -73.97343868734961,
                40.735341388996005
              ],
              [
                -73.97341851539187,
                40.735367886022004
              ],
              [
                -73.9740893642723,
                40.73564553073938
              ],
              [
                -73.97409217163273,
                40.73564160471855
              ],
              [
                -73.97414539712055,
                40.73567255831331
              ],
              [
                -73.97415673129369,
                40.73565879219353
              ],
              [
                -73.97418881323293,
                40.73566089849516
              ],
              [
                -73.97419155221249,
                40.73568111586587
              ],
              [
                -73.97426396904014,
                40.73567694357618
              ],
              [
                -73.97426298913933,
                40.73567253975694
              ],
              [
                -73.97439108143224,
                40.73567315398562
              ],
              [
                -73.97439147294988,
                40.73566874159347
              ],
              [
                -73.97446461207309,
                40.73566817266455
              ],
              [
                -73.97447066995052,
                40.73572851519182
              ],
              [
                -73.97447676009864,
                40.73578915860297
              ],
              [
                -73.97446862602692,
                40.7357891567999
              ],
              [
                -73.974469391487,
                40.73579916097891
              ],
              [
                -73.97425771965976,
                40.73576732638401
              ],
              [
                -73.97424533118316,
                40.73578115493269
              ],
              [
                -73.97424881734733,
                40.73579823084531
              ],
              [
                -73.97446125831894,
                40.73583242506949
              ],
              [
                -73.97444962990862,
                40.73587039671474
              ],
              [
                -73.97448737985142,
                40.735876987533125
              ],
              [
                -73.97450793938151,
                40.73579694577236
              ],
              [
                -73.97451459772603,
                40.735894598411235
              ],
              [
                -73.97450488285963,
                40.73604228053671
              ],
              [
                -73.97447828183064,
                40.73604418545101
              ],
              [
                -73.97444828612812,
                40.73603779285906
              ],
              [
                -73.97444722336759,
                40.73603347295401
              ],
              [
                -73.97415127634777,
                40.735933792668014
              ],
              [
                -73.97414062018366,
                40.73595565716735
              ],
              [
                -73.97443372879933,
                40.73605371686953
              ],
              [
                -73.97444082924032,
                40.73605047965705
              ],
              [
                -73.97447471755295,
                40.73606238071294
              ],
              [
                -73.97450376013232,
                40.736064588444
              ],
              [
                -73.97446647047282,
                40.73627845682991
              ],
              [
                -73.97442775474407,
                40.73626619954391
              ],
              [
                -73.97442932366866,
                40.736260633491526
              ],
              [
                -73.97404679254456,
                40.73609992993463
              ],
              [
                -73.97402692104305,
                40.73612576724549
              ],
              [
                -73.97439952343301,
                40.73628408408965
              ],
              [
                -73.97441154346602,
                40.73628527904678
              ],
              [
                -73.974416773548,
                40.73627772610679
              ],
              [
                -73.97445975070453,
                40.73629401971837
              ],
              [
                -73.97441385816511,
                40.736414393859
              ],
              [
                -73.97286939178467,
                40.73577086128555
              ],
              [
                -73.9725101678601,
                40.735787129859595
              ],
              [
                -73.97248994174741,
                40.73580328010761
              ],
              [
                -73.9725633368432,
                40.73644588163205
              ],
              [
                -73.97255622610943,
                40.73646148552752
              ],
              [
                -73.97257525871015,
                40.73664650893144
              ],
              [
                -73.972579797985,
                40.736690689579284
              ],
              [
                -73.9725829245608,
                40.73674468509609
              ],
              [
                -73.97259038224664,
                40.73676965534477
              ],
              [
                -73.97258445692434,
                40.73687548339179
              ],
              [
                -73.97258360606305,
                40.73701588054919
              ],
              [
                -73.97259776670337,
                40.73709748050962
              ],
              [
                -73.97259775633782,
                40.737122685024104
              ],
              [
                -73.97259459485838,
                40.73715508388077
              ],
              [
                -73.97260563605106,
                40.73716588375281
              ],
              [
                -73.97259458103935,
                40.73718868180281
              ],
              [
                -73.97260003717393,
                40.737221768395315
              ],
              [
                -73.97259521395056,
                40.73723114989956
              ],
              [
                -73.97259412459493,
                40.73726174867489
              ],
              [
                -73.97256998441054,
                40.73726378775497
              ],
              [
                -73.97257959604842,
                40.73735478687316
              ],
              [
                -73.97257739454064,
                40.73749433836131
              ],
              [
                -73.97260583208758,
                40.73749474970565
              ],
              [
                -73.97260621196618,
                40.73766627757036
              ],
              [
                -73.97261404225611,
                40.73779827683594
              ],
              [
                -73.97260457307685,
                40.73782227307573
              ],
              [
                -73.9726124265646,
                40.73789787206911
              ],
              [
                -73.97261872947428,
                40.73791467680059
              ],
              [
                -73.9726060999748,
                40.737938672288784
              ],
              [
                -73.97261474017301,
                40.73802986934807
              ],
              [
                -73.97261946865977,
                40.73804427874751
              ],
              [
                -73.97262254718066,
                40.738217068211725
              ],
              [
                -73.97263327107359,
                40.738223833298434
              ],
              [
                -73.9726327464413,
                40.73823242426211
              ],
              [
                -73.97262452608486,
                40.73824336395957
              ],
              [
                -73.97262807328735,
                40.73836796724543
              ],
              [
                -73.97263833265164,
                40.73837968597632
              ],
              [
                -73.97263884800155,
                40.73839530977717
              ],
              [
                -73.97262753952535,
                40.73840038548476
              ],
              [
                -73.9726192965622,
                40.738463068045895
              ],
              [
                -73.97262084766338,
                40.73852066599611
              ],
              [
                -73.97262792189784,
                40.738593869671085
              ],
              [
                -73.97262630543392,
                40.73866586408449
              ],
              [
                -73.97262787457508,
                40.73870906197222
              ],
              [
                -73.97263100998644,
                40.738740265008055
              ],
              [
                -73.97263256455133,
                40.738789470052794
              ],
              [
                -73.97264674830716,
                40.73884587151235
              ],
              [
                -73.97264512721954,
                40.738958668310985
              ],
              [
                -73.97264431062423,
                40.73901746233535
              ],
              [
                -73.9726506037967,
                40.73905826571552
              ],
              [
                -73.97265528968896,
                40.73917826848007
              ],
              [
                -73.97265743803044,
                40.739222754276966
              ],
              [
                -73.97253745182257,
                40.73966141394607
              ],
              [
                -73.97243888981417,
                40.74002174997318
              ],
              [
                -73.97233141721733,
                40.7400109081997
              ],
              [
                -73.97231030439093,
                40.74001851267486
              ],
              [
                -73.97229979590853,
                40.740034962742975
              ],
              [
                -73.97224837803633,
                40.74029117185863
              ],
              [
                -73.97225332319164,
                40.740300105554866
              ],
              [
                -73.9722700133414,
                40.74030857434567
              ],
              [
                -73.97226753642127,
                40.740313743277184
              ],
              [
                -73.97221685422322,
                40.74030762506154
              ],
              [
                -73.97219641647804,
                40.74040257875024
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0000766545579019,
        "objectid": 141,
        "shape_leng": 0.0415144638712,
        "location_id": 141,
        "zone": "Lenox Hill West",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.96177668399997,
                40.759879715999936
              ],
              [
                -73.96176766499995,
                40.75989304099994
              ],
              [
                -73.96172701399999,
                40.75994980699992
              ],
              [
                -73.96169733399982,
                40.7599871369999
              ],
              [
                -73.96167816899991,
                40.76001396699989
              ],
              [
                -73.96164363299995,
                40.76005959199991
              ],
              [
                -73.96162070799981,
                40.760094433999896
              ],
              [
                -73.96152208599995,
                40.760230359999916
              ],
              [
                -73.96106754499992,
                40.76085809299985
              ],
              [
                -73.96060649299993,
                40.76148739799985
              ],
              [
                -73.9601506909999,
                40.762114874999945
              ],
              [
                -73.95969458599983,
                40.762740538999935
              ],
              [
                -73.95923908799993,
                40.76336615799994
              ],
              [
                -73.95878081199999,
                40.76399001699986
              ],
              [
                -73.95832133999988,
                40.764616629999864
              ],
              [
                -73.95786874099996,
                40.765242324999896
              ],
              [
                -73.95741202499985,
                40.765868753999875
              ],
              [
                -73.95695607099981,
                40.766493435999884
              ],
              [
                -73.95649564199994,
                40.76711891499989
              ],
              [
                -73.95599908699987,
                40.76780389299994
              ],
              [
                -73.9555017079999,
                40.76848474299992
              ],
              [
                -73.95503700499988,
                40.769120065999864
              ],
              [
                -73.95457540899991,
                40.769754856999946
              ],
              [
                -73.9541124529999,
                40.7703890789999
              ],
              [
                -73.95364859999982,
                40.77102410799993
              ],
              [
                -73.95318747599984,
                40.771656893999925
              ],
              [
                -73.95268514999994,
                40.772339404999926
              ],
              [
                -73.95505481599999,
                40.773336850999954
              ],
              [
                -73.95729409999986,
                40.7742835549999
              ],
              [
                -73.95779380499984,
                40.77359989699994
              ],
              [
                -73.95825534899998,
                40.772965336999896
              ],
              [
                -73.9587170739999,
                40.77233167499991
              ],
              [
                -73.9591817999999,
                40.77169682399995
              ],
              [
                -73.959644408,
                40.771064265999925
              ],
              [
                -73.96010955199993,
                40.77043221099989
              ],
              [
                -73.96060180599979,
                40.769749186999924
              ],
              [
                -73.9611017069999,
                40.7690593189999
              ],
              [
                -73.96155824299983,
                40.76843761199996
              ],
              [
                -73.96201413099995,
                40.76781230799988
              ],
              [
                -73.9624699899999,
                40.76718493299991
              ],
              [
                -73.96292865800001,
                40.76655862799987
              ],
              [
                -73.96340154099988,
                40.765911190999894
              ],
              [
                -73.96384291099993,
                40.76530968599997
              ],
              [
                -73.9642979039999,
                40.76468198699991
              ],
              [
                -73.96475544899988,
                40.76405706999992
              ],
              [
                -73.96521328099986,
                40.76343135899992
              ],
              [
                -73.96566959999997,
                40.76280455599994
              ],
              [
                -73.96612747299989,
                40.76217929999994
              ],
              [
                -73.9665833839999,
                40.76155093499988
              ],
              [
                -73.96431353599988,
                40.760599230999944
              ],
              [
                -73.96355618899993,
                40.76028081899991
              ],
              [
                -73.96308073599987,
                40.760083162999884
              ],
              [
                -73.96196573699996,
                40.75961945999995
              ],
              [
                -73.96177668399997,
                40.759879715999936
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0000756537920738,
        "objectid": 142,
        "shape_leng": 0.0381758942321,
        "location_id": 142,
        "zone": "Lincoln Square East",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.98147898799989,
                40.76772505899991
              ],
              [
                -73.98141469499981,
                40.76777787299987
              ],
              [
                -73.98136213699995,
                40.76783673899992
              ],
              [
                -73.98131853299991,
                40.76789900199987
              ],
              [
                -73.9812909039998,
                40.76796936299995
              ],
              [
                -73.98129086899992,
                40.76796945299993
              ],
              [
                -73.98129085699999,
                40.76796955199996
              ],
              [
                -73.98128253299977,
                40.76804427399991
              ],
              [
                -73.98129467599998,
                40.7681188019999
              ],
              [
                -73.98132588899983,
                40.768188379999884
              ],
              [
                -73.98137263399991,
                40.768249315999924
              ],
              [
                -73.98145320300002,
                40.76832036999991
              ],
              [
                -73.98145334399989,
                40.768320494999905
              ],
              [
                -73.98145349899993,
                40.768320602999935
              ],
              [
                -73.98154591499996,
                40.76838333899988
              ],
              [
                -73.98154597299994,
                40.76838337499988
              ],
              [
                -73.98164804599995,
                40.76843632199994
              ],
              [
                -73.9810229009999,
                40.76927283399994
              ],
              [
                -73.980563707,
                40.769905324999904
              ],
              [
                -73.98011181599992,
                40.770528368999884
              ],
              [
                -73.97965233399994,
                40.77115532699992
              ],
              [
                -73.97919214499984,
                40.77178126499997
              ],
              [
                -73.978722468,
                40.77241889799995
              ],
              [
                -73.97828220099997,
                40.77303187899987
              ],
              [
                -73.97782639399986,
                40.77365619199991
              ],
              [
                -73.97737084999984,
                40.77428100699992
              ],
              [
                -73.97691168299995,
                40.774909123999905
              ],
              [
                -73.97645763799984,
                40.77553235199985
              ],
              [
                -73.97595517399988,
                40.7762197549999
              ],
              [
                -73.9754606719999,
                40.776898502999884
              ],
              [
                -73.97499744000005,
                40.77753254599988
              ],
              [
                -73.97783054399989,
                40.77872973099989
              ],
              [
                -73.980673654,
                40.779925181999914
              ],
              [
                -73.98113503699982,
                40.7792913869999
              ],
              [
                -73.98162126599993,
                40.778608082999874
              ],
              [
                -73.98211778999992,
                40.77801318099994
              ],
              [
                -73.98215547099988,
                40.77797229499992
              ],
              [
                -73.98258286200002,
                40.7773028379999
              ],
              [
                -73.983046668,
                40.77668267699989
              ],
              [
                -73.98349614799992,
                40.77604586099993
              ],
              [
                -73.98397150999992,
                40.77542927299991
              ],
              [
                -73.98441202599994,
                40.77480807799988
              ],
              [
                -73.98487946600002,
                40.774155670999924
              ],
              [
                -73.9853325459999,
                40.77353064599993
              ],
              [
                -73.98546970299988,
                40.77334411699991
              ],
              [
                -73.98551605299986,
                40.77328108299987
              ],
              [
                -73.98556790099997,
                40.773210570999936
              ],
              [
                -73.98562257199983,
                40.773136219999884
              ],
              [
                -73.9857570959999,
                40.77295327099989
              ],
              [
                -73.98577822599991,
                40.77292453299991
              ],
              [
                -73.98582184799984,
                40.772865207999914
              ],
              [
                -73.98596463199983,
                40.77267102299991
              ],
              [
                -73.98602063499989,
                40.77259485899986
              ],
              [
                -73.98613338199989,
                40.77244152299989
              ],
              [
                -73.98618447899992,
                40.772372030999854
              ],
              [
                -73.98623798899992,
                40.772299256999894
              ],
              [
                -73.98645451899998,
                40.77200064599989
              ],
              [
                -73.98649075499988,
                40.77195067399991
              ],
              [
                -73.98665479099989,
                40.77172445399991
              ],
              [
                -73.98669559399988,
                40.771668182999896
              ],
              [
                -73.98714552199996,
                40.771054037999875
              ],
              [
                -73.98761512099995,
                40.77041544299988
              ],
              [
                -73.98806289599996,
                40.769790799999925
              ],
              [
                -73.98521720299992,
                40.76859462999991
              ],
              [
                -73.98236545099999,
                40.7673921529999
              ],
              [
                -73.98215157199994,
                40.76768179799989
              ],
              [
                -73.98205899699985,
                40.76764257499985
              ],
              [
                -73.9820588189999,
                40.76764250299994
              ],
              [
                -73.98205860599991,
                40.76764244899991
              ],
              [
                -73.98195445399982,
                40.76761766099985
              ],
              [
                -73.981954182,
                40.76761759699986
              ],
              [
                -73.98195388499981,
                40.76761757899992
              ],
              [
                -73.98184408100003,
                40.76760970899987
              ],
              [
                -73.98184380799981,
                40.76760969099991
              ],
              [
                -73.98184353599999,
                40.767609717999925
              ],
              [
                -73.98173502899986,
                40.76761929099996
              ],
              [
                -73.98173489899985,
                40.76761929999994
              ],
              [
                -73.98173478100003,
                40.7676193279999
              ],
              [
                -73.98163412099991,
                40.76764472399989
              ],
              [
                -73.98155301499993,
                40.76768021699989
              ],
              [
                -73.98147898799989,
                40.76772505899991
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000146637644064,
        "objectid": 152,
        "shape_leng": 0.0579094768782,
        "location_id": 152,
        "zone": "Manhattanville",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.95357262899981,
                40.82197589199988
              ],
              [
                -73.95367103499984,
                40.82201753399995
              ],
              [
                -73.953769274,
                40.822058690999896
              ],
              [
                -73.9556966079999,
                40.82287647699994
              ],
              [
                -73.95573074999992,
                40.82289125699989
              ],
              [
                -73.95576937299988,
                40.822904320999896
              ],
              [
                -73.95634010399982,
                40.82309232799989
              ],
              [
                -73.95651468499985,
                40.82312012799986
              ],
              [
                -73.95708264299999,
                40.823201547999865
              ],
              [
                -73.95715187499992,
                40.82321376599985
              ],
              [
                -73.95722203099996,
                40.82322595899993
              ],
              [
                -73.9595089796959,
                40.82359578895143
              ],
              [
                -73.95950361164493,
                40.823591185797746
              ],
              [
                -73.95949747410948,
                40.82358681628588
              ],
              [
                -73.9594826976694,
                40.82357865266566
              ],
              [
                -73.95946454189165,
                40.82357113567166
              ],
              [
                -73.95943846744429,
                40.823562392292935
              ],
              [
                -73.95918196037638,
                40.82345912294556
              ],
              [
                -73.95916458632307,
                40.823451795483315
              ],
              [
                -73.95814312769197,
                40.82302072227144
              ],
              [
                -73.95820639539099,
                40.822922131912215
              ],
              [
                -73.95822838183025,
                40.82288786710083
              ],
              [
                -73.95793904909117,
                40.82277435354731
              ],
              [
                -73.95807467943848,
                40.82261135846494
              ],
              [
                -73.95813063305536,
                40.82253109146093
              ],
              [
                -73.95816805363577,
                40.82245810099125
              ],
              [
                -73.9582001935402,
                40.82228313691813
              ],
              [
                -73.95852731524242,
                40.821843756652164
              ],
              [
                -73.95864585147663,
                40.82190436963179
              ],
              [
                -73.958651260119,
                40.82191843657338
              ],
              [
                -73.95883849863245,
                40.82240504207783
              ],
              [
                -73.95886745433303,
                40.822450239228004
              ],
              [
                -73.95891721760434,
                40.82248605137144
              ],
              [
                -73.95904953331714,
                40.822541335368236
              ],
              [
                -73.95892614718308,
                40.822711755441375
              ],
              [
                -73.9596033246027,
                40.82299468678336
              ],
              [
                -73.95990999444398,
                40.822571119821994
              ],
              [
                -73.95922976213465,
                40.82228691013227
              ],
              [
                -73.95911304024106,
                40.822454964645544
              ],
              [
                -73.95900018289882,
                40.822409728374346
              ],
              [
                -73.95896360094618,
                40.822373794142074
              ],
              [
                -73.95874523660488,
                40.82180551197357
              ],
              [
                -73.95894172941705,
                40.821569364009555
              ],
              [
                -73.95881892233997,
                40.82151852484672
              ],
              [
                -73.9590896024009,
                40.821206247055926
              ],
              [
                -73.95917712794255,
                40.82124942998514
              ],
              [
                -73.95923467194001,
                40.82127782527041
              ],
              [
                -73.95903294155774,
                40.821513746228455
              ],
              [
                -73.95914685715196,
                40.821571328551
              ],
              [
                -73.95942624349648,
                40.821252381738034
              ],
              [
                -73.95930263598672,
                40.821189897523006
              ],
              [
                -73.95926255403748,
                40.82123565535862
              ],
              [
                -73.95920767709258,
                40.82120790934945
              ],
              [
                -73.95913061580634,
                40.82116895377474
              ],
              [
                -73.95928300647289,
                40.820994974413715
              ],
              [
                -73.95932566265213,
                40.820946276253366
              ],
              [
                -73.95939068797922,
                40.82085517835582
              ],
              [
                -73.95981462674504,
                40.820297875203906
              ],
              [
                -73.95999892229341,
                40.82038294480238
              ],
              [
                -73.96044728492026,
                40.81987715679099
              ],
              [
                -73.96094889843968,
                40.81931128887266
              ],
              [
                -73.9613183318058,
                40.818894523557375
              ],
              [
                -73.96203105797969,
                40.818086330740854
              ],
              [
                -73.9618998499999,
                40.8180472869999
              ],
              [
                -73.96181860199984,
                40.81802315399989
              ],
              [
                -73.96168805999997,
                40.81796669399991
              ],
              [
                -73.9616361359999,
                40.81793985099996
              ],
              [
                -73.96157544099992,
                40.81790997099991
              ],
              [
                -73.96138875999992,
                40.81781807599991
              ],
              [
                -73.9610201569998,
                40.81764868599986
              ],
              [
                -73.96068717899983,
                40.817510516999945
              ],
              [
                -73.95949328899988,
                40.8170074739999
              ],
              [
                -73.95839496699998,
                40.81579688699991
              ],
              [
                -73.95829447399994,
                40.81569078999992
              ],
              [
                -73.95819953200001,
                40.81558041799985
              ],
              [
                -73.95782821899992,
                40.81515604899989
              ],
              [
                -73.95716929999979,
                40.814403123999966
              ],
              [
                -73.95710690099989,
                40.8143318209999
              ],
              [
                -73.95707738499995,
                40.81429809399993
              ],
              [
                -73.95626957999987,
                40.81337502299987
              ],
              [
                -73.95570690100003,
                40.81414395999996
              ],
              [
                -73.95520337899985,
                40.8135541289999
              ],
              [
                -73.95408058399987,
                40.81228011299991
              ],
              [
                -73.95354382999984,
                40.81205082099992
              ],
              [
                -73.9521012519999,
                40.811442853999935
              ],
              [
                -73.95150537299989,
                40.812007684999905
              ],
              [
                -73.95098203399994,
                40.81252162999989
              ],
              [
                -73.95092724899993,
                40.812575428999914
              ],
              [
                -73.95035019299995,
                40.813159388999914
              ],
              [
                -73.94986218999995,
                40.813799979999935
              ],
              [
                -73.94962668099991,
                40.81410004999989
              ],
              [
                -73.94962653799998,
                40.81410023899991
              ],
              [
                -73.94962639600001,
                40.81410043599993
              ],
              [
                -73.949405505,
                40.814407408999976
              ],
              [
                -73.94919960099985,
                40.81472085399986
              ],
              [
                -73.94900927799986,
                40.81503994299992
              ],
              [
                -73.94883491299987,
                40.815363980999955
              ],
              [
                -73.94867681799998,
                40.815692285999916
              ],
              [
                -73.94835601499989,
                40.81637430599994
              ],
              [
                -73.94800732899995,
                40.81711650899995
              ],
              [
                -73.94766641299987,
                40.817855508999905
              ],
              [
                -73.94734953999983,
                40.81853499799996
              ],
              [
                -73.94703623199983,
                40.81920837199988
              ],
              [
                -73.94671831999985,
                40.81989426799995
              ],
              [
                -73.94640289799987,
                40.820572041999945
              ],
              [
                -73.94607828700005,
                40.82126321599991
              ],
              [
                -73.94723844699998,
                40.82175398599991
              ],
              [
                -73.94808289799983,
                40.82210802199994
              ],
              [
                -73.94854029799983,
                40.82148762299991
              ],
              [
                -73.94992194399984,
                40.8220723139999
              ],
              [
                -73.95037710599996,
                40.82144561799988
              ],
              [
                -73.95082880000002,
                40.82082793699989
              ],
              [
                -73.9529102909999,
                40.8216969469999
              ],
              [
                -73.95357262899981,
                40.82197589199988
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000536797294367,
        "objectid": 138,
        "shape_leng": 0.107466933508,
        "location_id": 138,
        "zone": "LaGuardia Airport",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.8712485624251,
                40.78603815125499
              ],
              [
                -73.87484527331479,
                40.78168492407536
              ],
              [
                -73.8751575489337,
                40.781509449653285
              ],
              [
                -73.87833923298054,
                40.78302592074941
              ],
              [
                -73.87883146781734,
                40.78243071449443
              ],
              [
                -73.87885705699973,
                40.782444885571216
              ],
              [
                -73.87886747171292,
                40.782436991160054
              ],
              [
                -73.87912427592009,
                40.782560356165554
              ],
              [
                -73.8790602451593,
                40.78264047102351
              ],
              [
                -73.87908251177446,
                40.782650658660685
              ],
              [
                -73.87914654749781,
                40.78256828605519
              ],
              [
                -73.87943006869374,
                40.78270635948339
              ],
              [
                -73.87940622567464,
                40.782745861494845
              ],
              [
                -73.87942106533049,
                40.78275604079166
              ],
              [
                -73.87945679555362,
                40.78271542259297
              ],
              [
                -73.87970469079072,
                40.78283764835939
              ],
              [
                -73.87967788425708,
                40.782872629506564
              ],
              [
                -73.8797016367226,
                40.782882818072366
              ],
              [
                -73.87974777853563,
                40.782836564207
              ],
              [
                -73.87916440606021,
                40.78255136427313
              ],
              [
                -73.87918078802261,
                40.782529923616465
              ],
              [
                -73.87916445729643,
                40.78252313142975
              ],
              [
                -73.87914510421407,
                40.782544567795654
              ],
              [
                -73.87888384757818,
                40.7824189397462
              ],
              [
                -73.8788883123546,
                40.78241442661198
              ],
              [
                -73.878858374856,
                40.78239817868118
              ],
              [
                -73.87930868295156,
                40.781853658332345
              ],
              [
                -73.87905115692989,
                40.781730533363366
              ],
              [
                -73.87870562518874,
                40.78058590995002
              ],
              [
                -73.87892488684261,
                40.78067355215143
              ],
              [
                -73.87913370828485,
                40.78067822938222
              ],
              [
                -73.87920483851617,
                40.78076738933479
              ],
              [
                -73.87930409174346,
                40.7807594078809
              ],
              [
                -73.8793580603621,
                40.78065668012734
              ],
              [
                -73.87944805023491,
                40.7807004765119
              ],
              [
                -73.87947341303463,
                40.780702688914175
              ],
              [
                -73.8794746864389,
                40.78085695107505
              ],
              [
                -73.87960692432854,
                40.78085031306525
              ],
              [
                -73.87960408126767,
                40.780779162686564
              ],
              [
                -73.87959071037689,
                40.78077914871647
              ],
              [
                -73.87958646834618,
                40.780698110860044
              ],
              [
                -73.87961343937161,
                40.780694319457815
              ],
              [
                -73.87973466843,
                40.78065481804985
              ],
              [
                -73.87990587782392,
                40.780639145170944
              ],
              [
                -73.8800224049746,
                40.78064471983977
              ],
              [
                -73.88018929439086,
                40.78063496792158
              ],
              [
                -73.88038817410674,
                40.780619416402864
              ],
              [
                -73.88075025848272,
                40.78057432624618
              ],
              [
                -73.88087003883854,
                40.78057306017537
              ],
              [
                -73.88096087912395,
                40.78058557723644
              ],
              [
                -73.8812191473699,
                40.78061061723774
              ],
              [
                -73.88139075985553,
                40.78060160840806
              ],
              [
                -73.88155872181727,
                40.78054662573665
              ],
              [
                -73.88168407683699,
                40.780500062638886
              ],
              [
                -73.88187200517194,
                40.780498454869836
              ],
              [
                -73.88202629453421,
                40.78048504352782
              ],
              [
                -73.88208293364791,
                40.780450836167304
              ],
              [
                -73.88211863000274,
                40.78039586317721
              ],
              [
                -73.88215899724347,
                40.780367867563115
              ],
              [
                -73.88231777091364,
                40.780360549298436
              ],
              [
                -73.8826403051081,
                40.7802858579589
              ],
              [
                -73.88274449717143,
                40.780223424072155
              ],
              [
                -73.88281116030053,
                40.78021893669761
              ],
              [
                -73.88287851751367,
                40.78023632010934
              ],
              [
                -73.88290491186557,
                40.780348697239766
              ],
              [
                -73.88324038254821,
                40.78032572327379
              ],
              [
                -73.88325271441282,
                40.78026542290212
              ],
              [
                -73.88385203219555,
                40.780264319754494
              ],
              [
                -73.88445032609359,
                40.78015460132617
              ],
              [
                -73.8847822502109,
                40.780038480497915
              ],
              [
                -73.88488113731053,
                40.779987196741224
              ],
              [
                -73.88493466381183,
                40.77993691280691
              ],
              [
                -73.88497842475354,
                40.77974438290775
              ],
              [
                -73.88508410716267,
                40.77935257161612
              ],
              [
                -73.88512416435393,
                40.779061209283
              ],
              [
                -73.88505291740942,
                40.77877543567209
              ],
              [
                -73.88508345876635,
                40.77867131316193
              ],
              [
                -73.88521875942243,
                40.77866136280502
              ],
              [
                -73.88525026986592,
                40.778612901742385
              ],
              [
                -73.88523914391557,
                40.778559221175875
              ],
              [
                -73.8852000839678,
                40.778513571263524
              ],
              [
                -73.88511399222635,
                40.77847552903457
              ],
              [
                -73.88505522658441,
                40.77840531274414
              ],
              [
                -73.88503248656444,
                40.77829983172282
              ],
              [
                -73.88499070411882,
                40.77814521789072
              ],
              [
                -73.88493197897337,
                40.777905022104456
              ],
              [
                -73.88482322075201,
                40.77751239820959
              ],
              [
                -73.88475744404441,
                40.77725413868524
              ],
              [
                -73.88440957187971,
                40.775549360877285
              ],
              [
                -73.88436257156576,
                40.77549337373265
              ],
              [
                -73.88433217018762,
                40.77548297087071
              ],
              [
                -73.8842883055274,
                40.7754830970367
              ],
              [
                -73.88428737712128,
                40.77547205616595
              ],
              [
                -73.88441988513166,
                40.77545646620444
              ],
              [
                -73.88445519420895,
                40.775464803850525
              ],
              [
                -73.88454882637298,
                40.77542804960337
              ],
              [
                -73.88460175355154,
                40.77542051069447
              ],
              [
                -73.8846752373344,
                40.775396766475154
              ],
              [
                -73.8847736190733,
                40.77533228058653
              ],
              [
                -73.88483519529827,
                40.77522084211525
              ],
              [
                -73.88485536080363,
                40.77510221207482
              ],
              [
                -73.88483742391051,
                40.77495298074568
              ],
              [
                -73.88479222517094,
                40.774807063622696
              ],
              [
                -73.8846598266042,
                40.77448010121009
              ],
              [
                -73.88455150532151,
                40.77436062233214
              ],
              [
                -73.88410836235168,
                40.77416708339821
              ],
              [
                -73.8839316828436,
                40.77418464435257
              ],
              [
                -73.88391627963797,
                40.77413260659154
              ],
              [
                -73.88408262463024,
                40.7741044801433
              ],
              [
                -73.88431556689014,
                40.77418436398409
              ],
              [
                -73.88578473697669,
                40.77402471216453
              ],
              [
                -73.88578718974873,
                40.77403826926387
              ],
              [
                -73.88579477727255,
                40.774080084653825
              ],
              [
                -73.88578683431469,
                40.77408091775973
              ],
              [
                -73.88578804109311,
                40.774087565703645
              ],
              [
                -73.88567708364381,
                40.77409920206598
              ],
              [
                -73.8856758683633,
                40.77409250687015
              ],
              [
                -73.8856323103537,
                40.77409707478859
              ],
              [
                -73.8856385336236,
                40.77413137765436
              ],
              [
                -73.88564276584745,
                40.77413093288607
              ],
              [
                -73.88566791315597,
                40.77426951277721
              ],
              [
                -73.88566130980054,
                40.77427020514186
              ],
              [
                -73.88566773133905,
                40.77430558840732
              ],
              [
                -73.88564767572932,
                40.7743076912231
              ],
              [
                -73.88564627381488,
                40.774299959528406
              ],
              [
                -73.88560236103582,
                40.774304563939076
              ],
              [
                -73.88562650739728,
                40.77443764029466
              ],
              [
                -73.88567007665036,
                40.774433071216826
              ],
              [
                -73.88565054964837,
                40.774325457489574
              ],
              [
                -73.88567349258264,
                40.77432305063104
              ],
              [
                -73.88567398900969,
                40.774325779948875
              ],
              [
                -73.88571221642924,
                40.77432177112161
              ],
              [
                -73.88570207086045,
                40.77426586763382
              ],
              [
                -73.88569498085096,
                40.774266610445544
              ],
              [
                -73.88566947657924,
                40.774126056083816
              ],
              [
                -73.88568292636904,
                40.77412464546015
              ],
              [
                -73.88568182351487,
                40.77411856804315
              ],
              [
                -73.88579034397598,
                40.774107187250394
              ],
              [
                -73.88579151867035,
                40.774113655072405
              ],
              [
                -73.88583361561382,
                40.77410924043991
              ],
              [
                -73.88582793733663,
                40.77407794805797
              ],
              [
                -73.88581792367333,
                40.774078998740464
              ],
              [
                -73.88581012437984,
                40.77403601766625
              ],
              [
                -73.88580762286652,
                40.774022224475374
              ],
              [
                -73.88767940155543,
                40.77381879172325
              ],
              [
                -73.88779843805143,
                40.773711297424654
              ],
              [
                -73.88945486901865,
                40.77353295106357
              ],
              [
                -73.88942374399998,
                40.77304592899988
              ],
              [
                -73.889040003,
                40.77091623799992
              ],
              [
                -73.88873647199979,
                40.76898593099992
              ],
              [
                -73.8887255129998,
                40.76891623899995
              ],
              [
                -73.88862144999993,
                40.7688940489999
              ],
              [
                -73.8885192579999,
                40.76885784099994
              ],
              [
                -73.88842375699991,
                40.76880764399987
              ],
              [
                -73.88833975599985,
                40.7687448059999
              ],
              [
                -73.8882713109998,
                40.76867187099987
              ],
              [
                -73.88822109399986,
                40.768592289999944
              ],
              [
                -73.88819008199995,
                40.768509872999935
              ],
              [
                -73.88817759199992,
                40.7684282569999
              ],
              [
                -73.88781814599999,
                40.767202077999876
              ],
              [
                -73.88772988199987,
                40.76691443399996
              ],
              [
                -73.88770661799985,
                40.766838597999936
              ],
              [
                -73.88762452899991,
                40.76686519999991
              ],
              [
                -73.88713429399999,
                40.76698296599988
              ],
              [
                -73.88671945999997,
                40.767091312999874
              ],
              [
                -73.88667620399991,
                40.76710325899991
              ],
              [
                -73.88602554199989,
                40.76728295799989
              ],
              [
                -73.88564823400002,
                40.767407690999946
              ],
              [
                -73.88506266099994,
                40.767598942999946
              ],
              [
                -73.88439729399991,
                40.767842169999874
              ],
              [
                -73.88426804199986,
                40.767894764999916
              ],
              [
                -73.8835659359999,
                40.7681804449999
              ],
              [
                -73.88277241799986,
                40.76852944099994
              ],
              [
                -73.88159926399999,
                40.7691258519999
              ],
              [
                -73.88068583599984,
                40.76963245199993
              ],
              [
                -73.88056341499993,
                40.76970115299986
              ],
              [
                -73.88020161599997,
                40.769904189999856
              ],
              [
                -73.87961158799999,
                40.77023244799993
              ],
              [
                -73.87897365099998,
                40.77055642199985
              ],
              [
                -73.87854915699987,
                40.77077199599996
              ],
              [
                -73.87818646999993,
                40.77092753499992
              ],
              [
                -73.87781416299993,
                40.77107045599997
              ],
              [
                -73.87743339799992,
                40.771200254999926
              ],
              [
                -73.87704537199986,
                40.77131652899987
              ],
              [
                -73.87665135299991,
                40.771418945999926
              ],
              [
                -73.87615314099988,
                40.77152345499992
              ],
              [
                -73.87608949699984,
                40.77153690299987
              ],
              [
                -73.87601656500001,
                40.77155104399987
              ],
              [
                -73.87517573299998,
                40.77167671099994
              ],
              [
                -73.87475943399997,
                40.771719986999926
              ],
              [
                -73.87426330499994,
                40.7717533869999
              ],
              [
                -73.87380524599989,
                40.77176797799989
              ],
              [
                -73.8732794149999,
                40.77176740099993
              ],
              [
                -73.87272561699997,
                40.7717366429999
              ],
              [
                -73.87223577699984,
                40.77169565899994
              ],
              [
                -73.87173533999989,
                40.771622584999896
              ],
              [
                -73.87143357299982,
                40.77157646699987
              ],
              [
                -73.87071447499991,
                40.771390689999926
              ],
              [
                -73.87016128299994,
                40.77120898499992
              ],
              [
                -73.86962970499988,
                40.77100636599993
              ],
              [
                -73.869437198,
                40.77092448199991
              ],
              [
                -73.8688938269999,
                40.77064902199992
              ],
              [
                -73.868098735,
                40.77017200899994
              ],
              [
                -73.86728193799998,
                40.769650002999896
              ],
              [
                -73.86606875999985,
                40.768796181999946
              ],
              [
                -73.8655058869999,
                40.76834576799996
              ],
              [
                -73.86509294099989,
                40.76801531499993
              ],
              [
                -73.86458486999996,
                40.76756668999991
              ],
              [
                -73.86404975699985,
                40.76706394699995
              ],
              [
                -73.86353063400003,
                40.76657622399993
              ],
              [
                -73.86340214900001,
                40.76645551199989
              ],
              [
                -73.86321805300003,
                40.76625081599989
              ],
              [
                -73.86286118599996,
                40.76584623599989
              ],
              [
                -73.86180888799989,
                40.764544706999835
              ],
              [
                -73.8610972439999,
                40.763664476999935
              ],
              [
                -73.86093691499987,
                40.76372861499989
              ],
              [
                -73.86057039699999,
                40.76383121299988
              ],
              [
                -73.86043337809801,
                40.76386888243775
              ],
              [
                -73.86123668863942,
                40.764960616103124
              ],
              [
                -73.86153844239246,
                40.76534253221643
              ],
              [
                -73.86176874486004,
                40.765594004521994
              ],
              [
                -73.86201493096908,
                40.76589092640018
              ],
              [
                -73.86216751063289,
                40.76604799421443
              ],
              [
                -73.86215785661702,
                40.76619175792949
              ],
              [
                -73.86224047985023,
                40.766283086505766
              ],
              [
                -73.8627202147973,
                40.7666706422922
              ],
              [
                -73.86275490196954,
                40.76676452492579
              ],
              [
                -73.8626886417663,
                40.76687591050486
              ],
              [
                -73.8626022140311,
                40.7668939798399
              ],
              [
                -73.86243485599871,
                40.76688891752954
              ],
              [
                -73.86226565137707,
                40.76680316777269
              ],
              [
                -73.86220059780494,
                40.7668398937497
              ],
              [
                -73.86222539270146,
                40.766894601510174
              ],
              [
                -73.86212976522565,
                40.76691133536475
              ],
              [
                -73.86205271633084,
                40.76691261216039
              ],
              [
                -73.86202142385251,
                40.766913133555526
              ],
              [
                -73.86181545316371,
                40.76693736156741
              ],
              [
                -73.86169177207913,
                40.76691704203793
              ],
              [
                -73.86143250938784,
                40.76691478503675
              ],
              [
                -73.86131386166113,
                40.766940631743715
              ],
              [
                -73.86121194395173,
                40.7670310899356
              ],
              [
                -73.86118998431375,
                40.76720716686883
              ],
              [
                -73.8611027715228,
                40.767271942529824
              ],
              [
                -73.86103345678848,
                40.76725679418058
              ],
              [
                -73.86100038221741,
                40.76717391624676
              ],
              [
                -73.86100762175549,
                40.76705938132751
              ],
              [
                -73.86098883676625,
                40.76701427074403
              ],
              [
                -73.86092884231329,
                40.766941950620456
              ],
              [
                -73.8608553594459,
                40.76693211808921
              ],
              [
                -73.86077468876901,
                40.76699451566885
              ],
              [
                -73.86069743030745,
                40.767011334357925
              ],
              [
                -73.86055456613312,
                40.76700031925954
              ],
              [
                -73.86033645988697,
                40.76695943339514
              ],
              [
                -73.86016778943647,
                40.766942227274065
              ],
              [
                -73.85998650669511,
                40.766957936481205
              ],
              [
                -73.85983028214702,
                40.76703180469933
              ],
              [
                -73.85963779907536,
                40.76702796771879
              ],
              [
                -73.85937510429778,
                40.767075328942674
              ],
              [
                -73.85892075977725,
                40.76727356056681
              ],
              [
                -73.85877376553057,
                40.76734999560101
              ],
              [
                -73.85859539017551,
                40.76748501377069
              ],
              [
                -73.85843507175626,
                40.767705440958686
              ],
              [
                -73.85832813918591,
                40.76782418555582
              ],
              [
                -73.85819704236047,
                40.76794331410307
              ],
              [
                -73.85805261946524,
                40.76809065637136
              ],
              [
                -73.85797604163109,
                40.76811483047016
              ],
              [
                -73.8576780026624,
                40.76827626539154
              ],
              [
                -73.85731254450552,
                40.76876360817364
              ],
              [
                -73.85724041942667,
                40.76888157464139
              ],
              [
                -73.85716158430333,
                40.768991401708725
              ],
              [
                -73.85710288000817,
                40.76909250012824
              ],
              [
                -73.85704790262234,
                40.769139555948875
              ],
              [
                -73.85681833769972,
                40.76903884617975
              ],
              [
                -73.8566356595733,
                40.76894032975357
              ],
              [
                -73.85647771882165,
                40.76890515740585
              ],
              [
                -73.85631296048622,
                40.76887844058347
              ],
              [
                -73.85624565746173,
                40.76888571419896
              ],
              [
                -73.85614973578456,
                40.76898684694477
              ],
              [
                -73.85614888582383,
                40.76907879647392
              ],
              [
                -73.85621267610162,
                40.76919579775784
              ],
              [
                -73.85669054762312,
                40.769385508210135
              ],
              [
                -73.85692407357327,
                40.76951252654719
              ],
              [
                -73.85745009449602,
                40.76980870552686
              ],
              [
                -73.8577362357601,
                40.77000314507246
              ],
              [
                -73.85798709647926,
                40.770173613711364
              ],
              [
                -73.85818924879476,
                40.770290460502615
              ],
              [
                -73.85820236123325,
                40.77030737932604
              ],
              [
                -73.8582051105021,
                40.77032345647718
              ],
              [
                -73.85819573008204,
                40.77033990568516
              ],
              [
                -73.8581872858477,
                40.77034491101544
              ],
              [
                -73.85818022082987,
                40.77034752359621
              ],
              [
                -73.85817215042374,
                40.77034947605985
              ],
              [
                -73.85816356056772,
                40.770350636994046
              ],
              [
                -73.85815492427808,
                40.77035094147852
              ],
              [
                -73.85814676560885,
                40.77035036435933
              ],
              [
                -73.85813724838887,
                40.77034723565637
              ],
              [
                -73.85812062818592,
                40.77033733686403
              ],
              [
                -73.85810814036756,
                40.770333287296765
              ],
              [
                -73.85790399937207,
                40.770437863495005
              ],
              [
                -73.85790102304883,
                40.77043921025004
              ],
              [
                -73.85789051085527,
                40.77044210670596
              ],
              [
                -73.85787912685437,
                40.770442876015444
              ],
              [
                -73.8578600035371,
                40.770440060440414
              ],
              [
                -73.85784854459581,
                40.770436606663424
              ],
              [
                -73.85783557421978,
                40.7704316193319
              ],
              [
                -73.8577762649952,
                40.770405098333605
              ],
              [
                -73.85776107882876,
                40.77039997440542
              ],
              [
                -73.85765412555506,
                40.77038128230353
              ],
              [
                -73.85762452189127,
                40.770378022362266
              ],
              [
                -73.8575984180025,
                40.770376766494465
              ],
              [
                -73.8575730336926,
                40.77037761613721
              ],
              [
                -73.85755023687742,
                40.77038078501798
              ],
              [
                -73.85752974542834,
                40.770386307969765
              ],
              [
                -73.85750688715996,
                40.7703968777218
              ],
              [
                -73.85750267630988,
                40.77039981644549
              ],
              [
                -73.85749528182234,
                40.77040655222482
              ],
              [
                -73.85748920023862,
                40.770414505027546
              ],
              [
                -73.85748432439799,
                40.7704238102495
              ],
              [
                -73.85747816799682,
                40.77044489329805
              ],
              [
                -73.85747194415998,
                40.77049130633454
              ],
              [
                -73.85746766311485,
                40.77051046314486
              ],
              [
                -73.8574640529471,
                40.770520193771915
              ],
              [
                -73.85745955790304,
                40.77052876117753
              ],
              [
                -73.85744762320293,
                40.770542920269385
              ],
              [
                -73.85743064536503,
                40.77055579456295
              ],
              [
                -73.85740515395037,
                40.770567937621934
              ],
              [
                -73.8573967280712,
                40.77056998960427
              ],
              [
                -73.85738091328331,
                40.77057080715644
              ],
              [
                -73.85735910137694,
                40.770568213827595
              ],
              [
                -73.85733677055224,
                40.77056407124349
              ],
              [
                -73.85731372099039,
                40.77055836056099
              ],
              [
                -73.85728951464255,
                40.770550973015304
              ],
              [
                -73.8572320319643,
                40.77052901036683
              ],
              [
                -73.85676549671736,
                40.77030660168678
              ],
              [
                -73.85674255927898,
                40.77029851350239
              ],
              [
                -73.85671858798878,
                40.77029173877042
              ],
              [
                -73.85669390392543,
                40.77028636785116
              ],
              [
                -73.8566688368006,
                40.77028247319014
              ],
              [
                -73.85661890723391,
                40.77027930513913
              ],
              [
                -73.85657099964907,
                40.7702823695339
              ],
              [
                -73.85654757393833,
                40.77028645581004
              ],
              [
                -73.85652419256841,
                40.77029246081006
              ],
              [
                -73.85650122019709,
                40.770300293686326
              ],
              [
                -73.85645807349368,
                40.77032086119883
              ],
              [
                -73.85643866852624,
                40.77033323611143
              ],
              [
                -73.85642119168853,
                40.77034671356568
              ],
              [
                -73.8556587255464,
                40.77115074024036
              ],
              [
                -73.85518065336801,
                40.7717835813866
              ],
              [
                -73.85511549005498,
                40.77181323378766
              ],
              [
                -73.85508635910341,
                40.77183138827739
              ],
              [
                -73.85506798595154,
                40.77187023014758
              ],
              [
                -73.85505451774685,
                40.771924657916436
              ],
              [
                -73.85504803073704,
                40.77203707882778
              ],
              [
                -73.85504825390281,
                40.772114350714084
              ],
              [
                -73.85505106271819,
                40.7721953899891
              ],
              [
                -73.8555107354459,
                40.77243896817119
              ],
              [
                -73.8559380688681,
                40.772636741565236
              ],
              [
                -73.85656384625518,
                40.7728996687605
              ],
              [
                -73.85674398259154,
                40.772979072572504
              ],
              [
                -73.85690044014613,
                40.77305877286938
              ],
              [
                -73.85723387590262,
                40.77318362981195
              ],
              [
                -73.85773563893147,
                40.77338481475659
              ],
              [
                -73.8579615794531,
                40.773489205888644
              ],
              [
                -73.85840105714999,
                40.77372133605423
              ],
              [
                -73.85863356071194,
                40.7738088345996
              ],
              [
                -73.85893795943063,
                40.77395612487197
              ],
              [
                -73.85890298388608,
                40.77400941135215
              ],
              [
                -73.85892842217254,
                40.77409513659952
              ],
              [
                -73.85901031879031,
                40.77413418740526
              ],
              [
                -73.85911792602863,
                40.77413042538886
              ],
              [
                -73.85921146961682,
                40.77408846731156
              ],
              [
                -73.86215889693487,
                40.775514553767344
              ],
              [
                -73.86471066497087,
                40.77673318625358
              ],
              [
                -73.86513221186647,
                40.776966470014244
              ],
              [
                -73.86576064949364,
                40.777358120694394
              ],
              [
                -73.86615198692074,
                40.777590680987565
              ],
              [
                -73.86632902853108,
                40.77764199906134
              ],
              [
                -73.86792643466,
                40.778394247798424
              ],
              [
                -73.86825520270827,
                40.77851264407573
              ],
              [
                -73.87015751178103,
                40.779497945638965
              ],
              [
                -73.87019485020025,
                40.779546803981
              ],
              [
                -73.87118588705187,
                40.7800015988523
              ],
              [
                -73.87218014326744,
                40.78052591567086
              ],
              [
                -73.87238637036785,
                40.780740527476986
              ],
              [
                -73.87255732673727,
                40.78082112154257
              ],
              [
                -73.87146292358177,
                40.78212640074081
              ],
              [
                -73.87048029898958,
                40.78230611231524
              ],
              [
                -73.86942344795426,
                40.78359131491769
              ],
              [
                -73.86871328577288,
                40.783696941857244
              ],
              [
                -73.86870913523617,
                40.78368323056261
              ],
              [
                -73.86870223255126,
                40.78367017590812
              ],
              [
                -73.8686927453537,
                40.783658094929415
              ],
              [
                -73.86868090404359,
                40.783647281015995
              ],
              [
                -73.86866699619074,
                40.783637996786204
              ],
              [
                -73.86865135955154,
                40.78363046770972
              ],
              [
                -73.86863437386539,
                40.783624876631684
              ],
              [
                -73.86861499376909,
                40.783621170754984
              ],
              [
                -73.86859507059657,
                40.783619986976205
              ],
              [
                -73.86857516830997,
                40.78362135880435
              ],
              [
                -73.86855585028033,
                40.783625247407215
              ],
              [
                -73.86853766334073,
                40.78363154271083
              ],
              [
                -73.86852112230667,
                40.78364006651474
              ],
              [
                -73.8685066954039,
                40.78365057753674
              ],
              [
                -73.86849479101389,
                40.78366277824263
              ],
              [
                -73.8684857461148,
                40.78367632326808
              ],
              [
                -73.86847981674143,
                40.78369082919536
              ],
              [
                -73.86847717073857,
                40.78370588540606
              ],
              [
                -73.86847788300818,
                40.783721065704746
              ],
              [
                -73.86848193339068,
                40.78373594038316
              ],
              [
                -73.86848920723403,
                40.783750088384004
              ],
              [
                -73.86849949863941,
                40.783763109219564
              ],
              [
                -73.86851294940965,
                40.78377495698295
              ],
              [
                -73.86852888971897,
                40.783784861256905
              ],
              [
                -73.86854684064295,
                40.783792524468076
              ],
              [
                -73.86856626284758,
                40.78379771637564
              ],
              [
                -73.86858657279326,
                40.78380028098891
              ],
              [
                -73.8686071602691,
                40.78380014125412
              ],
              [
                -73.86862740672426,
                40.783797301369724
              ],
              [
                -73.86864670385509,
                40.78379184665982
              ],
              [
                -73.86866447188046,
                40.783783941011144
              ],
              [
                -73.868680176961,
                40.78377382194827
              ],
              [
                -73.86869334724,
                40.78376179349807
              ],
              [
                -73.86870358701877,
                40.78374821705438
              ],
              [
                -73.86871058864548,
                40.783733500520235
              ],
              [
                -73.86871414175994,
                40.783718086052126
              ],
              [
                -73.86943751657091,
                40.78363839981371
              ],
              [
                -73.86987007434347,
                40.78508590706814
              ],
              [
                -73.86969325713956,
                40.78529616325478
              ],
              [
                -73.86918113468879,
                40.785943489784934
              ],
              [
                -73.86923491782913,
                40.78596991495815
              ],
              [
                -73.86978081192638,
                40.78533793332583
              ],
              [
                -73.8712485624251,
                40.78603815125499
              ]
            ]
          ],
          [
            [
              [
                -73.8728719590193,
                40.78597502790236
              ],
              [
                -73.8728849699182,
                40.785962633107935
              ],
              [
                -73.8728404791595,
                40.78594312287908
              ],
              [
                -73.8728535000943,
                40.78592565061054
              ],
              [
                -73.87280920016116,
                40.78590310909999
              ],
              [
                -73.8727740594054,
                40.78594192145882
              ],
              [
                -73.8728226286446,
                40.78596397466698
              ],
              [
                -73.87282839783694,
                40.78595446173833
              ],
              [
                -73.8728719590193,
                40.78597502790236
              ]
            ]
          ],
          [
            [
              [
                -73.89008921683674,
                40.773626435762374
              ],
              [
                -73.89019171520681,
                40.773646876224944
              ],
              [
                -73.8900676015257,
                40.77396248425602
              ],
              [
                -73.88988671629585,
                40.77401734907423
              ],
              [
                -73.88984246558316,
                40.77410037855676
              ],
              [
                -73.88978900756075,
                40.774236861169776
              ],
              [
                -73.88961448773941,
                40.77484568714223
              ],
              [
                -73.88958935733879,
                40.77495871069513
              ],
              [
                -73.88958184665768,
                40.77503504961379
              ],
              [
                -73.88956149685701,
                40.77509223860254
              ],
              [
                -73.88955512480328,
                40.77527870961293
              ],
              [
                -73.8894710539975,
                40.77553555096718
              ],
              [
                -73.88947151143196,
                40.775738056523394
              ],
              [
                -73.88951644380981,
                40.775936714445564
              ],
              [
                -73.89002369095195,
                40.77724712640633
              ],
              [
                -73.89008729416794,
                40.77778419360695
              ],
              [
                -73.89017369508576,
                40.77785081277785
              ],
              [
                -73.89035193082609,
                40.77780032027483
              ],
              [
                -73.89046537395133,
                40.777703219287076
              ],
              [
                -73.89045915776707,
                40.77755049725344
              ],
              [
                -73.89049834642208,
                40.77746263585198
              ],
              [
                -73.89056250595364,
                40.77740392096429
              ],
              [
                -73.89073132114231,
                40.77733204986777
              ],
              [
                -73.89094860635713,
                40.77728384517263
              ],
              [
                -73.89106681416045,
                40.77721292530485
              ],
              [
                -73.89112044339463,
                40.77712469973607
              ],
              [
                -73.89110422665516,
                40.77702320697468
              ],
              [
                -73.89126414584786,
                40.77685585537462
              ],
              [
                -73.89145185712488,
                40.776512769270866
              ],
              [
                -73.8913676627334,
                40.77634299857849
              ],
              [
                -73.89135801404697,
                40.77604940793021
              ],
              [
                -73.89144361875265,
                40.77562269470968
              ],
              [
                -73.89152759083345,
                40.775478135367386
              ],
              [
                -73.89168935121086,
                40.775254737843575
              ],
              [
                -73.89171751335807,
                40.77516363289303
              ],
              [
                -73.8917254778065,
                40.77505203213404
              ],
              [
                -73.89183244902246,
                40.77488019994431
              ],
              [
                -73.89034195999996,
                40.77323760499998
              ],
              [
                -73.89008921683674,
                40.773626435762374
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.00015109426901,
        "objectid": 143,
        "shape_leng": 0.0541798538849,
        "location_id": 143,
        "zone": "Lincoln Square West",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.98162787399998,
                40.780328934999936
              ],
              [
                -73.98232616399993,
                40.78062377299992
              ],
              [
                -73.98351422299999,
                40.781125389999914
              ],
              [
                -73.98507184299989,
                40.781779680999946
              ],
              [
                -73.98536952700006,
                40.781078372999914
              ],
              [
                -73.98567936099997,
                40.780312632999944
              ],
              [
                -73.98654378999994,
                40.780657980999926
              ],
              [
                -73.98707137499986,
                40.780906381999955
              ],
              [
                -73.98730772799986,
                40.781041302999924
              ],
              [
                -73.98736363999998,
                40.781062804999856
              ],
              [
                -73.98741432699994,
                40.7810875109999
              ],
              [
                -73.98746089399997,
                40.7811080339999
              ],
              [
                -73.98746219899976,
                40.78110860999992
              ],
              [
                -73.98799363199988,
                40.78134281699988
              ],
              [
                -73.9881274618433,
                40.78140179672363
              ],
              [
                -73.98813788174485,
                40.781379281274056
              ],
              [
                -73.98814418135414,
                40.781365671327244
              ],
              [
                -73.98821042083165,
                40.78122548254207
              ],
              [
                -73.98823241384693,
                40.78123314421561
              ],
              [
                -73.98838105020263,
                40.78098107404577
              ],
              [
                -73.98891926146848,
                40.780226094343675
              ],
              [
                -73.98891166067348,
                40.780122366770236
              ],
              [
                -73.98891168026452,
                40.780096037146635
              ],
              [
                -73.98892610353073,
                40.78005929201363
              ],
              [
                -73.98893965670626,
                40.77995617844041
              ],
              [
                -73.98888614411702,
                40.77987889853276
              ],
              [
                -73.99141982585954,
                40.779755280287084
              ],
              [
                -73.99155105922739,
                40.779574821437386
              ],
              [
                -73.99135945556924,
                40.779585883337354
              ],
              [
                -73.99132196764978,
                40.77963179604143
              ],
              [
                -73.99129488711995,
                40.77963020920888
              ],
              [
                -73.99125531828973,
                40.77965078251653
              ],
              [
                -73.99124281740438,
                40.77967136708451
              ],
              [
                -73.99115742949688,
                40.77966977560645
              ],
              [
                -73.99105747282958,
                40.77965235262575
              ],
              [
                -73.9909616722243,
                40.77963968375177
              ],
              [
                -73.99087211428213,
                40.77964600764394
              ],
              [
                -73.99074506950537,
                40.77967132818406
              ],
              [
                -73.99062219939665,
                40.77967606491435
              ],
              [
                -73.99050764615511,
                40.779668139123864
              ],
              [
                -73.99043059869707,
                40.779664973055496
              ],
              [
                -73.99033584033211,
                40.77966179439508
              ],
              [
                -73.99022336845653,
                40.779682361211215
              ],
              [
                -73.99013797752488,
                40.779699769704784
              ],
              [
                -73.99004010212052,
                40.77970767769822
              ],
              [
                -73.9899443022714,
                40.779696593271176
              ],
              [
                -73.98984224780634,
                40.779680752670714
              ],
              [
                -73.98975270293778,
                40.77967124421157
              ],
              [
                -73.98967981290244,
                40.77967756865803
              ],
              [
                -73.98959649338828,
                40.77969814668337
              ],
              [
                -73.98949861492709,
                40.77972504438973
              ],
              [
                -73.98940905418009,
                40.77973770647197
              ],
              [
                -73.98927785904421,
                40.779723439271145
              ],
              [
                -73.98921902288056,
                40.77969789520934
              ],
              [
                -73.98887187449955,
                40.77971373825301
              ],
              [
                -73.98886861739992,
                40.779692922911416
              ],
              [
                -73.9890851947858,
                40.779407597662015
              ],
              [
                -73.98917577843797,
                40.779287524015736
              ],
              [
                -73.98920068089916,
                40.77925278678187
              ],
              [
                -73.9892009588643,
                40.77925239865153
              ],
              [
                -73.9896488729282,
                40.77862756000903
              ],
              [
                -73.99010126597655,
                40.77799645751658
              ],
              [
                -73.99039616003645,
                40.777585065679084
              ],
              [
                -73.99073041339479,
                40.77756055024258
              ],
              [
                -73.99092717414973,
                40.77756687876296
              ],
              [
                -73.99142953190211,
                40.77687956054528
              ],
              [
                -73.99201877991067,
                40.776073340735394
              ],
              [
                -73.99208768471263,
                40.7759839708214
              ],
              [
                -73.99238604296023,
                40.77555718042467
              ],
              [
                -73.99236175680107,
                40.77550189976658
              ],
              [
                -73.9923293466088,
                40.77546890095856
              ],
              [
                -73.99222686779687,
                40.77548221102612
              ],
              [
                -73.99212537239482,
                40.77550907505336
              ],
              [
                -73.99205908493721,
                40.7754975981922
              ],
              [
                -73.99226708903534,
                40.77511603385852
              ],
              [
                -73.99240383719177,
                40.775018140390756
              ],
              [
                -73.9924697458154,
                40.77502415955178
              ],
              [
                -73.9925277439515,
                40.775002110439836
              ],
              [
                -73.99257784276604,
                40.77495601635934
              ],
              [
                -73.99262266386546,
                40.774974056036925
              ],
              [
                -73.99335832646867,
                40.773926888327935
              ],
              [
                -73.99341107446543,
                40.77380263098026
              ],
              [
                -73.99344271598801,
                40.773770566055774
              ],
              [
                -73.99352708391201,
                40.773714451955094
              ],
              [
                -73.99362199595426,
                40.773632286315
              ],
              [
                -73.99369846374411,
                40.77356214105259
              ],
              [
                -73.99376701931838,
                40.7734839812249
              ],
              [
                -73.99382239352724,
                40.773381758622975
              ],
              [
                -73.99386194292893,
                40.773269531698894
              ],
              [
                -73.99393587681126,
                40.773179512586104
              ],
              [
                -73.99557226516114,
                40.773870731394304
              ],
              [
                -73.9960512527533,
                40.77406889580328
              ],
              [
                -73.99609524511304,
                40.7740861862188
              ],
              [
                -73.99617945998676,
                40.77398695417577
              ],
              [
                -73.99611835151377,
                40.77396032398065
              ],
              [
                -73.99609880718478,
                40.77395180514953
              ],
              [
                -73.99612636120095,
                40.7739167138271
              ],
              [
                -73.99612850491377,
                40.77391398495152
              ],
              [
                -73.99612697786858,
                40.77391335481041
              ],
              [
                -73.99606316854084,
                40.773886886805
              ],
              [
                -73.99610778171481,
                40.77381737780049
              ],
              [
                -73.99610846890408,
                40.77381631600754
              ],
              [
                -73.99619583723546,
                40.77385235609209
              ],
              [
                -73.99624065194922,
                40.77378979119886
              ],
              [
                -73.99505028484955,
                40.77329715309494
              ],
              [
                -73.99501396385818,
                40.77335803471349
              ],
              [
                -73.9950893919464,
                40.7733884750195
              ],
              [
                -73.9950710884848,
                40.773414138832905
              ],
              [
                -73.9950232764303,
                40.773481196788154
              ],
              [
                -73.99429402991179,
                40.77315624399585
              ],
              [
                -73.99431863484354,
                40.773121440135085
              ],
              [
                -73.99437655287542,
                40.77303955105065
              ],
              [
                -73.99428153506716,
                40.77299380153468
              ],
              [
                -73.99430134219553,
                40.77297002883192
              ],
              [
                -73.99413665279414,
                40.77290187008702
              ],
              [
                -73.99412205804117,
                40.77292405851296
              ],
              [
                -73.99401262490255,
                40.772882846315966
              ],
              [
                -73.99396258575715,
                40.77294465345443
              ],
              [
                -73.99389125221832,
                40.772955194438346
              ],
              [
                -73.9938875450122,
                40.7729557440819
              ],
              [
                -73.99383108201548,
                40.77293178742534
              ],
              [
                -73.9937873699999,
                40.77290384599992
              ],
              [
                -73.99372534699988,
                40.772864197999915
              ],
              [
                -73.9935908639999,
                40.77279007999994
              ],
              [
                -73.9935026219999,
                40.772751632999885
              ],
              [
                -73.99387445099994,
                40.77223816799995
              ],
              [
                -73.99378189799992,
                40.77219909099988
              ],
              [
                -73.99365606199987,
                40.772145961999854
              ],
              [
                -73.990913076,
                40.77098775899989
              ],
              [
                -73.99059644699987,
                40.770854809999946
              ],
              [
                -73.98806289599996,
                40.769790799999925
              ],
              [
                -73.98761512099995,
                40.77041544299988
              ],
              [
                -73.98714552199996,
                40.771054037999875
              ],
              [
                -73.98669559399988,
                40.771668182999896
              ],
              [
                -73.98665479099989,
                40.77172445399991
              ],
              [
                -73.98649075499988,
                40.77195067399991
              ],
              [
                -73.98645451899998,
                40.77200064599989
              ],
              [
                -73.98623798899992,
                40.772299256999894
              ],
              [
                -73.98618447899992,
                40.772372030999854
              ],
              [
                -73.98613338199989,
                40.77244152299989
              ],
              [
                -73.98602063499989,
                40.77259485899986
              ],
              [
                -73.98596463199983,
                40.77267102299991
              ],
              [
                -73.98582184799984,
                40.772865207999914
              ],
              [
                -73.98577822599991,
                40.77292453299991
              ],
              [
                -73.9857570959999,
                40.77295327099989
              ],
              [
                -73.98562257199983,
                40.773136219999884
              ],
              [
                -73.98556790099997,
                40.773210570999936
              ],
              [
                -73.98551605299986,
                40.77328108299987
              ],
              [
                -73.98546970299988,
                40.77334411699991
              ],
              [
                -73.9853325459999,
                40.77353064599993
              ],
              [
                -73.98487946600002,
                40.774155670999924
              ],
              [
                -73.98441202599994,
                40.77480807799988
              ],
              [
                -73.98397150999982,
                40.77542927399991
              ],
              [
                -73.98349614799992,
                40.77604586099993
              ],
              [
                -73.983046668,
                40.77668267699989
              ],
              [
                -73.98258286099983,
                40.7773028379999
              ],
              [
                -73.98215547099988,
                40.77797229499992
              ],
              [
                -73.98211778999992,
                40.77801318099994
              ],
              [
                -73.98162126499993,
                40.778608082999924
              ],
              [
                -73.98113503699982,
                40.7792913869999
              ],
              [
                -73.980673654,
                40.779925181999914
              ],
              [
                -73.98140948699984,
                40.780235418999894
              ],
              [
                -73.98151911299976,
                40.78028175799991
              ],
              [
                -73.98162787399998,
                40.780328934999936
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0000474789670369,
        "objectid": 144,
        "shape_leng": 0.0276201668505,
        "location_id": 144,
        "zone": "Little Italy/NoLiTa",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.99580912300002,
                40.71664465899988
              ],
              [
                -73.99542875899999,
                40.717279242999936
              ],
              [
                -73.99480778999998,
                40.71845742599988
              ],
              [
                -73.99442117799985,
                40.719391298999874
              ],
              [
                -73.99437745899998,
                40.71951908899993
              ],
              [
                -73.99403499399986,
                40.720323010999884
              ],
              [
                -73.99379527399988,
                40.72093644299991
              ],
              [
                -73.99352298099996,
                40.721631692999956
              ],
              [
                -73.99325915400001,
                40.72235330999995
              ],
              [
                -73.99308859399994,
                40.72281909799991
              ],
              [
                -73.99263254999987,
                40.72405686599991
              ],
              [
                -73.99260322299988,
                40.724136449999946
              ],
              [
                -73.99267182399991,
                40.72416025399984
              ],
              [
                -73.99339891300001,
                40.7244125049999
              ],
              [
                -73.99345769099995,
                40.72443289599994
              ],
              [
                -73.99417930499997,
                40.724692406999914
              ],
              [
                -73.99490827399991,
                40.724951228999934
              ],
              [
                -73.99532907899994,
                40.72508373999996
              ],
              [
                -73.99584577599984,
                40.72520835299988
              ],
              [
                -73.9967717579999,
                40.72543194399992
              ],
              [
                -73.99684295899995,
                40.725347837999905
              ],
              [
                -73.9977850889999,
                40.724234893999906
              ],
              [
                -73.9988117219999,
                40.72302140899991
              ],
              [
                -73.99952163199998,
                40.72218334699987
              ],
              [
                -73.99985330399981,
                40.721791787999926
              ],
              [
                -74.000660725,
                40.72083979399993
              ],
              [
                -74.00142450999995,
                40.71993719799993
              ],
              [
                -74.001523638,
                40.71982079799995
              ],
              [
                -74.00188406299992,
                40.71939757099991
              ],
              [
                -74.00140055699994,
                40.719052619999886
              ],
              [
                -74.00127273399993,
                40.71896142599997
              ],
              [
                -74.00053195499999,
                40.71843023899987
              ],
              [
                -73.999956209,
                40.71801709499989
              ],
              [
                -73.99931241699989,
                40.71755024199989
              ],
              [
                -73.99858925599978,
                40.717099511999905
              ],
              [
                -73.99776285999995,
                40.71681392799996
              ],
              [
                -73.99698375299992,
                40.716540542999894
              ],
              [
                -73.99605872699998,
                40.7162316399999
              ],
              [
                -73.99580912300002,
                40.71664465899988
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000346641733797,
        "objectid": 145,
        "shape_leng": 0.114313844981,
        "location_id": 145,
        "zone": "Long Island City/Hunters Point",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.94345411699997,
                40.75205360799992
              ],
              [
                -73.944052652,
                40.75231325999994
              ],
              [
                -73.94458401999987,
                40.752534888999946
              ],
              [
                -73.94481659399985,
                40.7526316979999
              ],
              [
                -73.94504698699998,
                40.75273571599994
              ],
              [
                -73.94552905199996,
                40.75295740699989
              ],
              [
                -73.9457177839999,
                40.75304419599996
              ],
              [
                -73.94597511599991,
                40.75315619699993
              ],
              [
                -73.94635771599988,
                40.75332271699992
              ],
              [
                -73.94655794699989,
                40.75340986099998
              ],
              [
                -73.94745112999996,
                40.75379680499989
              ],
              [
                -73.9482744449999,
                40.75415377799993
              ],
              [
                -73.9491129519999,
                40.75452361999986
              ],
              [
                -73.95080763809156,
                40.75526368005709
              ],
              [
                -73.95083917142055,
                40.75521808361643
              ],
              [
                -73.9508770195158,
                40.75516691420224
              ],
              [
                -73.9510179273249,
                40.755086919725095
              ],
              [
                -73.95136748557822,
                40.75452388323725
              ],
              [
                -73.95162538718246,
                40.7542491588566
              ],
              [
                -73.95177949644007,
                40.75408671350756
              ],
              [
                -73.9520814316973,
                40.753757041221704
              ],
              [
                -73.95217262360619,
                40.75370276114808
              ],
              [
                -73.95265700069434,
                40.75310724080279
              ],
              [
                -73.95284882052746,
                40.752940028800296
              ],
              [
                -73.95300921156827,
                40.75277519542741
              ],
              [
                -73.95297152022262,
                40.75274650142647
              ],
              [
                -73.95364139195567,
                40.75203698846651
              ],
              [
                -73.953679096483,
                40.75204656300799
              ],
              [
                -73.95380171765365,
                40.75195818796781
              ],
              [
                -73.95399353526572,
                40.75178619551906
              ],
              [
                -73.95394013937512,
                40.75174554742943
              ],
              [
                -73.95431654115386,
                40.75125819070782
              ],
              [
                -73.95465000413118,
                40.750831040959476
              ],
              [
                -73.95451584573382,
                40.75058669897675
              ],
              [
                -73.95506652560155,
                40.74963273037535
              ],
              [
                -73.95520275040869,
                40.74967653740949
              ],
              [
                -73.95513239152461,
                40.74992642433234
              ],
              [
                -73.95522809429409,
                40.7499434460404
              ],
              [
                -73.95521529965907,
                40.74999196768931
              ],
              [
                -73.95534928514437,
                40.750013856904026
              ],
              [
                -73.95546436936412,
                40.74967421308258
              ],
              [
                -73.95559517265838,
                40.74968396904215
              ],
              [
                -73.95606185080375,
                40.74976141809475
              ],
              [
                -73.956086873372,
                40.74976613327862
              ],
              [
                -73.95611932084378,
                40.74975783785615
              ],
              [
                -73.95620052208228,
                40.74968568845317
              ],
              [
                -73.95619904110013,
                40.74967096066778
              ],
              [
                -73.9561741642638,
                40.74966509499414
              ],
              [
                -73.95576039140944,
                40.74959665953019
              ],
              [
                -73.95613499240578,
                40.74926462627479
              ],
              [
                -73.95625582868091,
                40.749334827486386
              ],
              [
                -73.9564856879466,
                40.74911169015581
              ],
              [
                -73.95638045449142,
                40.74903400751158
              ],
              [
                -73.9563216250964,
                40.74908155443487
              ],
              [
                -73.95478387869785,
                40.74842768777324
              ],
              [
                -73.95305375571401,
                40.748087487229284
              ],
              [
                -73.95318382492853,
                40.74773481196097
              ],
              [
                -73.95470561241689,
                40.748013620452426
              ],
              [
                -73.95464547239432,
                40.74812830865803
              ],
              [
                -73.95439031474363,
                40.748019022887604
              ],
              [
                -73.95432006769612,
                40.748103917081885
              ],
              [
                -73.95465178220594,
                40.748232644095495
              ],
              [
                -73.95470926432743,
                40.748150170963356
              ],
              [
                -73.95467737259249,
                40.7481331739454
              ],
              [
                -73.95473347953263,
                40.748018725711106
              ],
              [
                -73.95485394268042,
                40.74804079464106
              ],
              [
                -73.9567945329049,
                40.748839516955606
              ],
              [
                -73.95831740194431,
                40.747072210120145
              ],
              [
                -73.95833641386992,
                40.74702409778029
              ],
              [
                -73.95834783270944,
                40.746976943789186
              ],
              [
                -73.95838708370566,
                40.74693942457044
              ],
              [
                -73.95840482607544,
                40.7468980489933
              ],
              [
                -73.95844912833226,
                40.74687785428838
              ],
              [
                -73.95854662645935,
                40.74678068736895
              ],
              [
                -73.958670723744,
                40.746643109693856
              ],
              [
                -73.95864684421029,
                40.74654483695282
              ],
              [
                -73.95899427160536,
                40.74606464643515
              ],
              [
                -73.95837927151231,
                40.74595309009328
              ],
              [
                -73.95838334268795,
                40.74590390888174
              ],
              [
                -73.95837114990087,
                40.74586718558515
              ],
              [
                -73.95836656305252,
                40.74582947057589
              ],
              [
                -73.9583696922635,
                40.74579166941702
              ],
              [
                -73.95838046238632,
                40.74575468973874
              ],
              [
                -73.95839861481154,
                40.7457194194454
              ],
              [
                -73.9584760944999,
                40.74568888408759
              ],
              [
                -73.9585686575644,
                40.7456889174215
              ],
              [
                -73.95878289860286,
                40.74573332038774
              ],
              [
                -73.95894743371302,
                40.74577118529589
              ],
              [
                -73.95905935607935,
                40.74575336834632
              ],
              [
                -73.95911259993665,
                40.7457341839628
              ],
              [
                -73.95916167785694,
                40.7457093927616
              ],
              [
                -73.95920557605818,
                40.74567950684699
              ],
              [
                -73.95924338775806,
                40.74564514356168
              ],
              [
                -73.95927433190408,
                40.745607012735555
              ],
              [
                -73.95930281987525,
                40.74557677679971
              ],
              [
                -73.95932420250084,
                40.74554332269665
              ],
              [
                -73.95933786845197,
                40.745507606896524
              ],
              [
                -73.95934342702446,
                40.74547065053107
              ],
              [
                -73.9593407193104,
                40.74543351019863
              ],
              [
                -73.95932982273781,
                40.74539724775561
              ],
              [
                -73.95931104885652,
                40.74536289995734
              ],
              [
                -73.95929419130337,
                40.74534486486369
              ],
              [
                -73.95927397022142,
                40.74532895496046
              ],
              [
                -73.95925084238363,
                40.745315529633864
              ],
              [
                -73.95922533022177,
                40.74530489214626
              ],
              [
                -73.95919801002645,
                40.74529728278581
              ],
              [
                -73.95916949892894,
                40.74529287343875
              ],
              [
                -73.95914044096014,
                40.745291763707
              ],
              [
                -73.95873752408312,
                40.74519508943747
              ],
              [
                -73.95862450071114,
                40.745167971959944
              ],
              [
                -73.95862451222585,
                40.745149431165274
              ],
              [
                -73.95845252269613,
                40.745119012324686
              ],
              [
                -73.95847142298703,
                40.745060241387236
              ],
              [
                -73.95847854708337,
                40.745038091625844
              ],
              [
                -73.95849389303115,
                40.74498786491623
              ],
              [
                -73.95862255916191,
                40.745007816177534
              ],
              [
                -73.95907628880576,
                40.74509246880723
              ],
              [
                -73.95905544841918,
                40.74510830469202
              ],
              [
                -73.95965229755977,
                40.74521658817342
              ],
              [
                -73.95964910654475,
                40.74514416077284
              ],
              [
                -73.95875870330303,
                40.74495372973999
              ],
              [
                -73.95874827896084,
                40.744969569298654
              ],
              [
                -73.95864096676264,
                40.744948314954975
              ],
              [
                -73.95876372515237,
                40.74459261916389
              ],
              [
                -73.95888268557037,
                40.74461262144167
              ],
              [
                -73.95887919943338,
                40.74462433145469
              ],
              [
                -73.95937339680776,
                40.74470980818754
              ],
              [
                -73.95938138680651,
                40.74471017989835
              ],
              [
                -73.95938938708176,
                40.744709954381186
              ],
              [
                -73.95939731899777,
                40.74470913043566
              ],
              [
                -73.95940510501366,
                40.74470771909118
              ],
              [
                -73.95941267265367,
                40.744705732384
              ],
              [
                -73.95941994745534,
                40.744703188883506
              ],
              [
                -73.95942685803531,
                40.74470011553626
              ],
              [
                -73.95943333829659,
                40.74469653979322
              ],
              [
                -73.95943932500018,
                40.74469249781804
              ],
              [
                -73.95944476107415,
                40.7446880262789
              ],
              [
                -73.95944959362535,
                40.74468317256721
              ],
              [
                -73.95945377637196,
                40.74467797871584
              ],
              [
                -73.95945726676932,
                40.74467249882063
              ],
              [
                -73.95946003174586,
                40.74466678513858
              ],
              [
                -73.95946204747965,
                40.74466089177205
              ],
              [
                -73.95946329058697,
                40.74465487734709
              ],
              [
                -73.9594637511212,
                40.74464879848417
              ],
              [
                -73.95946417955432,
                40.7446433481684
              ],
              [
                -73.95946387758195,
                40.744637893575
              ],
              [
                -73.95946284296743,
                40.744632490489366
              ],
              [
                -73.9594610886709,
                40.74462719738259
              ],
              [
                -73.95945863272348,
                40.74462206602672
              ],
              [
                -73.95945549887861,
                40.744617154059156
              ],
              [
                -73.9594517260948,
                40.74461250974093
              ],
              [
                -73.95944734539928,
                40.744608183508426
              ],
              [
                -73.95944240940852,
                40.744604220612175
              ],
              [
                -73.9594369665561,
                40.744600662113186
              ],
              [
                -73.95943107519165,
                40.74459754354726
              ],
              [
                -73.95942479652714,
                40.74459490145693
              ],
              [
                -73.95941819442535,
                40.74459275982058
              ],
              [
                -73.95941134001788,
                40.74459114362481
              ],
              [
                -73.95922188572065,
                40.744553805630886
              ],
              [
                -73.95918723985115,
                40.744621011759705
              ],
              [
                -73.9587753700448,
                40.74455559986341
              ],
              [
                -73.95881841584692,
                40.744508907268305
              ],
              [
                -73.95885419935777,
                40.74447009115742
              ],
              [
                -73.95891561234897,
                40.744463173779614
              ],
              [
                -73.95897577051586,
                40.74445150791883
              ],
              [
                -73.95903404086978,
                40.74443521632426
              ],
              [
                -73.9591002268356,
                40.744404384503106
              ],
              [
                -73.95916115518868,
                40.74436781692285
              ],
              [
                -73.95921597110394,
                40.74432602663238
              ],
              [
                -73.95923368945668,
                40.74431004302653
              ],
              [
                -73.95929971633628,
                40.74432422217919
              ],
              [
                -73.95936852954134,
                40.74432480534333
              ],
              [
                -73.95952410819169,
                40.744354895906056
              ],
              [
                -73.95954322849366,
                40.74430877828985
              ],
              [
                -73.95979139383248,
                40.7443558442473
              ],
              [
                -73.95980151741631,
                40.74432936858096
              ],
              [
                -73.96002610056895,
                40.74437386231268
              ],
              [
                -73.96001147139246,
                40.74442254304437
              ],
              [
                -73.96012600962386,
                40.74444393699146
              ],
              [
                -73.96015525517686,
                40.74436878260556
              ],
              [
                -73.95972855228639,
                40.74427211561938
              ],
              [
                -73.95971280471103,
                40.74431481791598
              ],
              [
                -73.95946913213879,
                40.74426775269982
              ],
              [
                -73.95946013173084,
                40.74429251979717
              ],
              [
                -73.9593910660955,
                40.744283324743876
              ],
              [
                -73.9594036821734,
                40.7442260747471
              ],
              [
                -73.95936198209034,
                40.7442139760372
              ],
              [
                -73.95955401304717,
                40.74401549720348
              ],
              [
                -73.95963225023952,
                40.74393463191414
              ],
              [
                -73.9599331149753,
                40.74399458841103
              ],
              [
                -73.95992298789854,
                40.74402704209605
              ],
              [
                -73.96010602637564,
                40.744058709806936
              ],
              [
                -73.96011951918781,
                40.744029672294424
              ],
              [
                -73.96051732602352,
                40.744105341081195
              ],
              [
                -73.96064863700711,
                40.74413031761407
              ],
              [
                -73.960672045794,
                40.744051215855905
              ],
              [
                -73.96056856858453,
                40.744029666585355
              ],
              [
                -73.96052090981668,
                40.744019740075224
              ],
              [
                -73.96051032999415,
                40.74404689195289
              ],
              [
                -73.95975123850988,
                40.74390227665779
              ],
              [
                -73.95974393197373,
                40.74387256884915
              ],
              [
                -73.9598049477139,
                40.74370675289006
              ],
              [
                -73.95971981529016,
                40.74369304546522
              ],
              [
                -73.95978468552276,
                40.74349771658391
              ],
              [
                -73.9601638816042,
                40.74355079186102
              ],
              [
                -73.96022543287424,
                40.74355754686459
              ],
              [
                -73.96028762060436,
                40.74355781751105
              ],
              [
                -73.96034926948344,
                40.743551598685286
              ],
              [
                -73.96040921438393,
                40.74353900791942
              ],
              [
                -73.96046632238422,
                40.74352028317143
              ],
              [
                -73.9605195141806,
                40.74349577832753
              ],
              [
                -73.96056778448636,
                40.743465956513845
              ],
              [
                -73.96061022103159,
                40.74343138134335
              ],
              [
                -73.96064602180344,
                40.743392706263144
              ],
              [
                -73.96093449806607,
                40.742829760022445
              ],
              [
                -73.96097168385474,
                40.74275405426373
              ],
              [
                -73.96109548654543,
                40.742781947731416
              ],
              [
                -73.9611136948121,
                40.74274854913792
              ],
              [
                -73.96119029789011,
                40.7426448552597
              ],
              [
                -73.96155253168952,
                40.74199326140724
              ],
              [
                -73.96117277851152,
                40.74188633414116
              ],
              [
                -73.96110814501404,
                40.74182802590792
              ],
              [
                -73.9613394216989,
                40.74187793481265
              ],
              [
                -73.96134895292636,
                40.74186850238443
              ],
              [
                -73.9613564494236,
                40.74185806142123
              ],
              [
                -73.96136173044927,
                40.74184686365817
              ],
              [
                -73.96136466867709,
                40.74183517907704
              ],
              [
                -73.96136519326672,
                40.74182328939675
              ],
              [
                -73.96136329157102,
                40.74181148128098
              ],
              [
                -73.96129180598602,
                40.74179635293707
              ],
              [
                -73.96127192619346,
                40.74183259794322
              ],
              [
                -73.96111505608512,
                40.741797804148966
              ],
              [
                -73.96112672584677,
                40.7417351350947
              ],
              [
                -73.96113102375936,
                40.741671925079324
              ],
              [
                -73.96112791580276,
                40.74160867474957
              ],
              [
                -73.96111742661412,
                40.74154588507022
              ],
              [
                -73.9611165295705,
                40.741541998156656
              ],
              [
                -73.96111520859633,
                40.741536490115315
              ],
              [
                -73.96110246937006,
                40.74149926037208
              ],
              [
                -73.96109646058719,
                40.74146106285253
              ],
              [
                -73.96109729243683,
                40.741422598098566
              ],
              [
                -73.96110494965113,
                40.741384571551684
              ],
              [
                -73.96111929178639,
                40.7413476806157
              ],
              [
                -73.96113163485765,
                40.74134022044097
              ],
              [
                -73.9611452699347,
                40.741334200697885
              ],
              [
                -73.96115990869546,
                40.74132974867761
              ],
              [
                -73.96117524159504,
                40.74132695852066
              ],
              [
                -73.96119094440999,
                40.741325889226246
              ],
              [
                -73.96120668509533,
                40.741326563405515
              ],
              [
                -73.96121299217897,
                40.74132803071502
              ],
              [
                -73.96123173063957,
                40.74133082059913
              ],
              [
                -73.9612508100668,
                40.74133138968267
              ],
              [
                -73.96126977822759,
                40.74132972447691
              ],
              [
                -73.96128818552575,
                40.74132586445139
              ],
              [
                -73.96130559565955,
                40.741319901099274
              ],
              [
                -73.96132159596328,
                40.74131197576757
              ],
              [
                -73.9613358071877,
                40.741302276307785
              ],
              [
                -73.96134490840703,
                40.74130045577837
              ],
              [
                -73.96135349223277,
                40.74129752188664
              ],
              [
                -73.96136132503524,
                40.74129355448597
              ],
              [
                -73.96136819362471,
                40.74128866155895
              ],
              [
                -73.96137391105589,
                40.74128297627892
              ],
              [
                -73.96137832171493,
                40.741276653384745
              ],
              [
                -73.96138130555555,
                40.74126986496984
              ],
              [
                -73.961382781365,
                40.741262795797596
              ],
              [
                -73.96138270897617,
                40.74125563827293
              ],
              [
                -73.96138109036006,
                40.74124858720552
              ],
              [
                -73.96137796957133,
                40.7412418345074
              ],
              [
                -73.96137343155077,
                40.74123556396972
              ],
              [
                -73.96136759981154,
                40.74122994626041
              ],
              [
                -73.9613667652031,
                40.74122776395002
              ],
              [
                -73.9613631423506,
                40.74121330931282
              ],
              [
                -73.96136268661652,
                40.7411985984556
              ],
              [
                -73.96136541035025,
                40.74118403007582
              ],
              [
                -73.96137123973048,
                40.74116999900953
              ],
              [
                -73.96138001676614,
                40.74115688552988
              ],
              [
                -73.96139150357843,
                40.74114504504104
              ],
              [
                -73.96140538884808,
                40.74113479844658
              ],
              [
                -73.96148615243109,
                40.74113702304026
              ],
              [
                -73.96156689485383,
                40.74113439133732
              ],
              [
                -73.96164711213778,
                40.74112691976451
              ],
              [
                -73.96172630358241,
                40.74111465495805
              ],
              [
                -73.96180397489094,
                40.74109767347214
              ],
              [
                -73.96188806252042,
                40.740982169809506
              ],
              [
                -73.9619093021767,
                40.74086783324394
              ],
              [
                -73.96185009745176,
                40.740724481630345
              ],
              [
                -73.96172732138895,
                40.74066968474288
              ],
              [
                -73.96151773884108,
                40.74060680575997
              ],
              [
                -73.96126581236175,
                40.74053747110824
              ],
              [
                -73.96129339923257,
                40.74043279936792
              ],
              [
                -73.96162982563845,
                40.74045892945751
              ],
              [
                -73.96164163979053,
                40.74035611292451
              ],
              [
                -73.96145013844338,
                40.74033783468036
              ],
              [
                -73.96146496178848,
                40.74030080140807
              ],
              [
                -73.96152351376229,
                40.74016007237984
              ],
              [
                -73.96159204782984,
                40.740003968231605
              ],
              [
                -73.96220916495864,
                40.740006175249036
              ],
              [
                -73.96243311685276,
                40.739661471070654
              ],
              [
                -73.96262085588614,
                40.73900493653315
              ],
              [
                -73.96260615442901,
                40.73876172434986
              ],
              [
                -73.962589594156,
                40.73873601859644
              ],
              [
                -73.96256114655104,
                40.7386899573533
              ],
              [
                -73.96254775525007,
                40.738671915600484
              ],
              [
                -73.96253213723082,
                40.738654296293426
              ],
              [
                -73.96251438912807,
                40.73863719897305
              ],
              [
                -73.96249462716804,
                40.73862073993982
              ],
              [
                -73.9624729587812,
                40.73860501036202
              ],
              [
                -73.96244960919122,
                40.738590171807274
              ],
              [
                -73.96242479019499,
                40.73857636858405
              ],
              [
                -73.96239876314083,
                40.738563751885486
              ],
              [
                -73.96235370202787,
                40.73854691567182
              ],
              [
                -73.96226893180528,
                40.73850949938995
              ],
              [
                -73.96218880375358,
                40.73846989550221
              ],
              [
                -73.96215861110738,
                40.73845597235087
              ],
              [
                -73.96215095325073,
                40.73845183729329
              ],
              [
                -73.96214483395143,
                40.73844786423642
              ],
              [
                -73.96213942723989,
                40.73844335063821
              ],
              [
                -73.96213559336611,
                40.7384387486004
              ],
              [
                -73.96213424591258,
                40.73843638821311
              ],
              [
                -73.9621332643156,
                40.738433982881446
              ],
              [
                -73.96213266090855,
                40.73843153327965
              ],
              [
                -73.96213242556007,
                40.738429038901685
              ],
              [
                -73.96213345576498,
                40.738409471822564
              ],
              [
                -73.96213733911205,
                40.738387860351764
              ],
              [
                -73.96215854719352,
                40.738316826581624
              ],
              [
                -73.96216465998417,
                40.73829035322094
              ],
              [
                -73.96216739979442,
                40.73828076340236
              ],
              [
                -73.96216781615563,
                40.73827865722969
              ],
              [
                -73.96216791163884,
                40.738276900579265
              ],
              [
                -73.96216782846125,
                40.738275991553664
              ],
              [
                -73.96216763907424,
                40.73827517128228
              ],
              [
                -73.9621673443472,
                40.73827446037089
              ],
              [
                -73.96216693042085,
                40.7382738300006
              ],
              [
                -73.96216669315326,
                40.738273550488735
              ],
              [
                -73.96216640895788,
                40.73827329843555
              ],
              [
                -73.9621661137397,
                40.738273064471755
              ],
              [
                -73.96216578260618,
                40.73827285746796
              ],
              [
                -73.96216502515645,
                40.738272506418724
              ],
              [
                -73.9621641612824,
                40.738272234909694
              ],
              [
                -73.96213404613793,
                40.73826588531027
              ],
              [
                -73.96209998908977,
                40.73826019177947
              ],
              [
                -73.9619544165527,
                40.73824088226492
              ],
              [
                -73.96170910164201,
                40.73821891866324
              ],
              [
                -73.96072580373679,
                40.73808017017914
              ],
              [
                -73.9602854405676,
                40.73824399232705
              ],
              [
                -73.96027048974302,
                40.73825993585105
              ],
              [
                -73.96022715341631,
                40.738310700489436
              ],
              [
                -73.96022403678212,
                40.73831388660278
              ],
              [
                -73.96022036568877,
                40.73831686613192
              ],
              [
                -73.96021115211703,
                40.738322365186036
              ],
              [
                -73.96020045878585,
                40.738326990245405
              ],
              [
                -73.96016917242167,
                40.7383385067528
              ],
              [
                -73.96016047979936,
                40.73834278002493
              ],
              [
                -73.96015351682223,
                40.73834734438553
              ],
              [
                -73.96014908547855,
                40.73835134907848
              ],
              [
                -73.96014547202849,
                40.738355895332084
              ],
              [
                -73.96014265220762,
                40.73836104512277
              ],
              [
                -73.96014056496966,
                40.738366862257124
              ],
              [
                -73.96013848536926,
                40.73837979256485
              ],
              [
                -73.96013686315297,
                40.738419342807674
              ],
              [
                -73.96013545062728,
                40.738425365897776
              ],
              [
                -73.9601334583406,
                40.738430769107055
              ],
              [
                -73.96013085166251,
                40.73843563903461
              ],
              [
                -73.96012759268096,
                40.73844002408246
              ],
              [
                -73.96011713293024,
                40.738451033478185
              ],
              [
                -73.96010554777817,
                40.73846119597236
              ],
              [
                -73.9600932301298,
                40.73847017095194
              ],
              [
                -73.9600804629096,
                40.73847774810163
              ],
              [
                -73.96006757901837,
                40.73848374962379
              ],
              [
                -73.96005462382847,
                40.73848817519884
              ],
              [
                -73.96004172884936,
                40.738490990697215
              ],
              [
                -73.96002897845497,
                40.73849216482073
              ],
              [
                -73.96001767208647,
                40.73849209891121
              ],
              [
                -73.96000509976616,
                40.73849124753223
              ],
              [
                -73.95995298823041,
                40.738485159606604
              ],
              [
                -73.95993158356721,
                40.738484639028044
              ],
              [
                -73.95987349884916,
                40.73848746390514
              ],
              [
                -73.95985404654236,
                40.738490096504115
              ],
              [
                -73.95983699528468,
                40.73849397164277
              ],
              [
                -73.95978270346585,
                40.73851171066127
              ],
              [
                -73.9597242033727,
                40.73853559924901
              ],
              [
                -73.9596359543351,
                40.73857627022979
              ],
              [
                -73.95953181719854,
                40.73861833273099
              ],
              [
                -73.95939408153595,
                40.73867735790769
              ],
              [
                -73.95927346121162,
                40.73872399754858
              ],
              [
                -73.95926236387591,
                40.73872924373326
              ],
              [
                -73.95925086376484,
                40.73873583149299
              ],
              [
                -73.95920597529579,
                40.73876691855057
              ],
              [
                -73.95918469317864,
                40.73877905935348
              ],
              [
                -73.95913624628112,
                40.7388012208244
              ],
              [
                -73.95902844677197,
                40.738848054061364
              ],
              [
                -73.95898468852309,
                40.73886827282493
              ],
              [
                -73.958924869193,
                40.738900390939776
              ],
              [
                -73.95890248656039,
                40.7389111977878
              ],
              [
                -73.958875049531,
                40.73892219229909
              ],
              [
                -73.95881278544756,
                40.73894415168756
              ],
              [
                -73.9587857625725,
                40.73895460555049
              ],
              [
                -73.95868260497008,
                40.7389982611434
              ],
              [
                -73.95858431411132,
                40.73904116201237
              ],
              [
                -73.95846682395378,
                40.73909505247406
              ],
              [
                -73.95836705562306,
                40.73913333207909
              ],
              [
                -73.95825512163869,
                40.73918362971911
              ],
              [
                -73.95813766520311,
                40.73923911236207
              ],
              [
                -73.9581231238344,
                40.739245104352136
              ],
              [
                -73.9581063434869,
                40.739250609196986
              ],
              [
                -73.95803720372618,
                40.73926803625334
              ],
              [
                -73.95800848806388,
                40.73927730138706
              ],
              [
                -73.95798514776511,
                40.739287351325665
              ],
              [
                -73.95796044362709,
                40.739299912652086
              ],
              [
                -73.95787174668567,
                40.739352470288175
              ],
              [
                -73.95783305440638,
                40.73937267287572
              ],
              [
                -73.9578129824211,
                40.73938121104518
              ],
              [
                -73.95778977239853,
                40.73938959544456
              ],
              [
                -73.95769167671632,
                40.739420221633935
              ],
              [
                -73.9576550372954,
                40.73943488599123
              ],
              [
                -73.95764957785607,
                40.73943779341431
              ],
              [
                -73.95764437858944,
                40.7394413036926
              ],
              [
                -73.95763940313131,
                40.739445452998694
              ],
              [
                -73.95763455804378,
                40.73945032204577
              ],
              [
                -73.95760956733187,
                40.73948419137328
              ],
              [
                -73.95760021934018,
                40.73949474092096
              ],
              [
                -73.95759520937837,
                40.73949910648883
              ],
              [
                -73.95758998468358,
                40.73950276116321
              ],
              [
                -73.9575845020466,
                40.73950576741509
              ],
              [
                -73.95757867555936,
                40.73950814112807
              ],
              [
                -73.95752016571687,
                40.739527436022044
              ],
              [
                -73.95746607442862,
                40.73954341851145
              ],
              [
                -73.95741470766005,
                40.73955658201162
              ],
              [
                -73.957365367112,
                40.739567100327285
              ],
              [
                -73.95728369206074,
                40.73958014549551
              ],
              [
                -73.95725341436346,
                40.739585969037165
              ],
              [
                -73.95722993362249,
                40.7395921200965
              ],
              [
                -73.95717457418112,
                40.73960988496113
              ],
              [
                -73.95714889104126,
                40.73961686243014
              ],
              [
                -73.95710461786645,
                40.73962579772006
              ],
              [
                -73.95706179227868,
                40.73963183281364
              ],
              [
                -73.95702052948604,
                40.73963495083508
              ],
              [
                -73.95698094007172,
                40.73963513423747
              ],
              [
                -73.95697731731507,
                40.73963489030603
              ],
              [
                -73.95697362315164,
                40.73963437562511
              ],
              [
                -73.9569658816245,
                40.73963253597347
              ],
              [
                -73.95695727726076,
                40.73962950689662
              ],
              [
                -73.95693155675913,
                40.73961893440816
              ],
              [
                -73.95692303345511,
                40.73961658116053
              ],
              [
                -73.95691900947335,
                40.7396158857611
              ],
              [
                -73.95691509079995,
                40.73961548792784
              ],
              [
                -73.95688022501504,
                40.73961380062032
              ],
              [
                -73.95685053222071,
                40.73961355462707
              ],
              [
                -73.95682353901007,
                40.739614724063564
              ],
              [
                -73.9567987103568,
                40.73961736183668
              ],
              [
                -73.95673650026704,
                40.73962737934407
              ],
              [
                -73.95665583006708,
                40.73964273061248
              ],
              [
                -73.9566348235059,
                40.73964760341775
              ],
              [
                -73.95657678916139,
                40.73966328647773
              ],
              [
                -73.95655856629828,
                40.73966688125483
              ],
              [
                -73.95654189613127,
                40.73966890169956
              ],
              [
                -73.95636593483088,
                40.73968033400858
              ],
              [
                -73.95624261164724,
                40.7396890133316
              ],
              [
                -73.95621492962265,
                40.73969192882607
              ],
              [
                -73.95614908424905,
                40.73970175566426
              ],
              [
                -73.95611897472277,
                40.73970515704294
              ],
              [
                -73.95598072513853,
                40.739711308622894
              ],
              [
                -73.95587541922043,
                40.739721786132485
              ],
              [
                -73.95569800427987,
                40.73974671664131
              ],
              [
                -73.95568919593795,
                40.73974743294592
              ],
              [
                -73.95567927370665,
                40.739747601512505
              ],
              [
                -73.95563528007237,
                40.73974612491633
              ],
              [
                -73.95562429351857,
                40.73974672394605
              ],
              [
                -73.95561450106388,
                40.73974814247126
              ],
              [
                -73.9556084627258,
                40.73974967116739
              ],
              [
                -73.95560214990203,
                40.739751938716154
              ],
              [
                -73.9555748520794,
                40.739765218893865
              ],
              [
                -73.95556803152874,
                40.73976788244475
              ],
              [
                -73.95556162429453,
                40.73976969863884
              ],
              [
                -73.95555130014233,
                40.739771785048795
              ],
              [
                -73.95553724485578,
                40.73977463360252
              ],
              [
                -73.95551509086064,
                40.73977788527795
              ],
              [
                -73.9555140840356,
                40.73977796613924
              ],
              [
                -73.95549441822823,
                40.73977956093091
              ],
              [
                -73.95547496705139,
                40.73977967855461
              ],
              [
                -73.95546765067904,
                40.739779090556205
              ],
              [
                -73.95545971983971,
                40.73977779083517
              ],
              [
                -73.95542672904534,
                40.73976946641811
              ],
              [
                -73.95541891596714,
                40.73976795046337
              ],
              [
                -73.95541178942047,
                40.73976708310083
              ],
              [
                -73.95536636374354,
                40.7397648873269
              ],
              [
                -73.95531915980796,
                40.73976557112597
              ],
              [
                -73.95529063771633,
                40.73976809020765
              ],
              [
                -73.95522880785674,
                40.73977679230127
              ],
              [
                -73.95520363590497,
                40.739778808074156
              ],
              [
                -73.95505997596827,
                40.739781796059255
              ],
              [
                -73.95502314341954,
                40.73978318588669
              ],
              [
                -73.95498631086818,
                40.739784577377606
              ],
              [
                -73.95493966279436,
                40.73978726870691
              ],
              [
                -73.95491398069144,
                40.73978998749271
              ],
              [
                -73.95487585469716,
                40.73979578103484
              ],
              [
                -73.9548569689627,
                40.73979970849289
              ],
              [
                -73.95481021948795,
                40.739812243579415
              ],
              [
                -73.95479667341917,
                40.739814715140724
              ],
              [
                -73.95478421779814,
                40.73981591627342
              ],
              [
                -73.95474210608039,
                40.73981589973218
              ],
              [
                -73.95467405648948,
                40.739811533534606
              ],
              [
                -73.95457431562856,
                40.73980474928995
              ],
              [
                -73.95445339268437,
                40.739800279169174
              ],
              [
                -73.9543425424343,
                40.73979884866056
              ],
              [
                -73.95421335151975,
                40.73980073347309
              ],
              [
                -73.95416096154287,
                40.73980326975502
              ],
              [
                -73.95404538116453,
                40.73981205786133
              ],
              [
                -73.95399631710347,
                40.73981409130872
              ],
              [
                -73.95387406540449,
                40.73981406988426
              ],
              [
                -73.95385881619836,
                40.73981511667895
              ],
              [
                -73.9538200274479,
                40.739819829228566
              ],
              [
                -73.95380883920839,
                40.739820292977846
              ],
              [
                -73.95379878808706,
                40.73981986527195
              ],
              [
                -73.95377724201458,
                40.739817218253506
              ],
              [
                -73.95375351918099,
                40.739813003817964
              ],
              [
                -73.95367148545417,
                40.7397943388994
              ],
              [
                -73.95350014162969,
                40.7397539274847
              ],
              [
                -73.95341454477163,
                40.73973626995544
              ],
              [
                -73.95331835122244,
                40.73971859868651
              ],
              [
                -73.95301574053705,
                40.739669173287226
              ],
              [
                -73.95291652689204,
                40.73965211309403
              ],
              [
                -73.9528313441822,
                40.73963599520285
              ],
              [
                -73.9527541290097,
                40.739619700259
              ],
              [
                -73.95269882536086,
                40.73960617945536
              ],
              [
                -73.95263400587451,
                40.73959128886935
              ],
              [
                -73.95256648437781,
                40.739575777453766
              ],
              [
                -73.95249805377391,
                40.73955863944509
              ],
              [
                -73.95243355272876,
                40.73954393499918
              ],
              [
                -73.952297207924,
                40.73951862852658
              ],
              [
                -73.95221537377104,
                40.73950345675014
              ],
              [
                -73.95219672758512,
                40.73953625486484
              ],
              [
                -73.9519220819223,
                40.73948730592797
              ],
              [
                -73.95138071451841,
                40.73933647729512
              ],
              [
                -73.95136030838314,
                40.7393149740383
              ],
              [
                -73.95072468223914,
                40.73913050454873
              ],
              [
                -73.9503931919434,
                40.739027560339586
              ],
              [
                -73.9503362620346,
                40.739006985843396
              ],
              [
                -73.94715087181176,
                40.73790012334473
              ],
              [
                -73.94601135253046,
                40.73812081475249
              ],
              [
                -73.94548236590111,
                40.738207031293705
              ],
              [
                -73.9453584794753,
                40.73826948539713
              ],
              [
                -73.9452942041489,
                40.73831485856154
              ],
              [
                -73.9452386207261,
                40.73835682364524
              ],
              [
                -73.94480159543221,
                40.73850057832633
              ],
              [
                -73.9445925287478,
                40.73857388800324
              ],
              [
                -73.94433882538458,
                40.73864623976486
              ],
              [
                -73.94396995657188,
                40.738782396059904
              ],
              [
                -73.9439578703344,
                40.73880237987792
              ],
              [
                -73.9439417005796,
                40.73882061714279
              ],
              [
                -73.94392187163798,
                40.73883662926438
              ],
              [
                -73.94389313413845,
                40.73884748169998
              ],
              [
                -73.94382272278396,
                40.738871537881835
              ],
              [
                -73.94375068797267,
                40.738892630763054
              ],
              [
                -73.94367724507745,
                40.73891069727868
              ],
              [
                -73.94364201133406,
                40.738919398390706
              ],
              [
                -73.94360554747992,
                40.73892436668734
              ],
              [
                -73.94356853368713,
                40.738925509493264
              ],
              [
                -73.9435316603867,
                40.7389228054914
              ],
              [
                -73.94329026866802,
                40.73900862239311
              ],
              [
                -73.94290141828863,
                40.73900380150434
              ],
              [
                -73.9427910693754,
                40.73906903345401
              ],
              [
                -73.942706089921,
                40.73911506297872
              ],
              [
                -73.94269588366701,
                40.739133880831695
              ],
              [
                -73.94271139005915,
                40.73914369336378
              ],
              [
                -73.94279045640067,
                40.739193721204316
              ],
              [
                -73.94272766121334,
                40.73923159719581
              ],
              [
                -73.94271034058505,
                40.73925125852501
              ],
              [
                -73.94270012786521,
                40.739277828156176
              ],
              [
                -73.94267972562717,
                40.73930217768122
              ],
              [
                -73.94264913635597,
                40.739323200416734
              ],
              [
                -73.94262581394155,
                40.73935862247503
              ],
              [
                -73.94261561041534,
                40.73937522646038
              ],
              [
                -73.94259666844879,
                40.73939514780283
              ],
              [
                -73.94257045548689,
                40.73940620705603
              ],
              [
                -73.94253405960497,
                40.73940840276209
              ],
              [
                -73.94251076458535,
                40.73941171452453
              ],
              [
                -73.9424816492286,
                40.739411699986874
              ],
              [
                -73.94244816527319,
                40.739413896617506
              ],
              [
                -73.9424132194964,
                40.73942273710663
              ],
              [
                -73.94237973008305,
                40.73943047196953
              ],
              [
                -73.94215937774742,
                40.739551634032345
              ],
              [
                -73.94208664916357,
                40.73959258700146
              ],
              [
                -73.94203367165663,
                40.73965693856116
              ],
              [
                -73.94183504992756,
                40.73969262021148
              ],
              [
                -73.94165001879603,
                40.739864154209705
              ],
              [
                -73.94159470030033,
                40.73986412616895
              ],
              [
                -73.9411881669617,
                40.740290218294476
              ],
              [
                -73.94079972883496,
                40.740897668398425
              ],
              [
                -73.94014181484296,
                40.74196918618731
              ],
              [
                -73.94008406290678,
                40.7420632446415
              ],
              [
                -73.94004815102228,
                40.742071226340684
              ],
              [
                -73.93965301521378,
                40.74273108709569
              ],
              [
                -73.9401485852122,
                40.74283244145998
              ],
              [
                -73.9400192297566,
                40.74320827221074
              ],
              [
                -73.9397560191853,
                40.74314401748943
              ],
              [
                -73.93974435898761,
                40.7431595125988
              ],
              [
                -73.93930746279257,
                40.743067754024935
              ],
              [
                -73.93858786976764,
                40.74293088268746
              ],
              [
                -73.93866991753185,
                40.742534082024875
              ],
              [
                -73.93910874138116,
                40.74261703517674
              ],
              [
                -73.93965611215268,
                40.741769215868466
              ],
              [
                -73.93992390124144,
                40.741354430638765
              ],
              [
                -73.94028230242282,
                40.74079928241357
              ],
              [
                -73.9405614496907,
                40.74041832293418
              ],
              [
                -73.94036159285052,
                40.740335345658174
              ],
              [
                -73.94050888805725,
                40.7401139417531
              ],
              [
                -73.94077801743279,
                40.73992178145365
              ],
              [
                -73.94101774041413,
                40.73967977289773
              ],
              [
                -73.94134983794343,
                40.73936163080154
              ],
              [
                -73.94140886345744,
                40.73929957085318
              ],
              [
                -73.9406430239998,
                40.73898181299989
              ],
              [
                -73.93993706299993,
                40.738688177999904
              ],
              [
                -73.939963245,
                40.73864135599992
              ],
              [
                -73.93982348499995,
                40.73844550399986
              ],
              [
                -73.93869664499978,
                40.73814081699989
              ],
              [
                -73.93867968899997,
                40.73820830199989
              ],
              [
                -73.93866464499992,
                40.73826815899986
              ],
              [
                -73.93864769800001,
                40.73832587299991
              ],
              [
                -73.93862452799989,
                40.73840478099993
              ],
              [
                -73.93819856599988,
                40.74054323199988
              ],
              [
                -73.93801851799994,
                40.74145502399992
              ],
              [
                -73.93765115799985,
                40.74330513499994
              ],
              [
                -73.93726110699993,
                40.745176490999896
              ],
              [
                -73.9376968159998,
                40.74523857399986
              ],
              [
                -73.9382576189999,
                40.74506843699991
              ],
              [
                -73.9392596719999,
                40.74474786799993
              ],
              [
                -73.94031559800003,
                40.7444044979999
              ],
              [
                -73.94174116799987,
                40.74385915199991
              ],
              [
                -73.94211068899993,
                40.74371373399991
              ],
              [
                -73.94297613999994,
                40.74336892999986
              ],
              [
                -73.94419561599983,
                40.742882145999936
              ],
              [
                -73.94401030399996,
                40.7436394539999
              ],
              [
                -73.94399533799991,
                40.74365885099996
              ],
              [
                -73.94383757199998,
                40.743871813999895
              ],
              [
                -73.94342742899987,
                40.74423117199994
              ],
              [
                -73.94272343099986,
                40.74457686299997
              ],
              [
                -73.94184736499994,
                40.74490368999989
              ],
              [
                -73.94032696100007,
                40.74541314299995
              ],
              [
                -73.93996463499987,
                40.74574558399994
              ],
              [
                -73.93952057299987,
                40.745983778999864
              ],
              [
                -73.93880511399983,
                40.74653465899992
              ],
              [
                -73.93812286699995,
                40.74705995499991
              ],
              [
                -73.93673442199996,
                40.7481289489999
              ],
              [
                -73.93663681899982,
                40.748204093999895
              ],
              [
                -73.93683480500002,
                40.7483945519999
              ],
              [
                -73.93739653799992,
                40.74891643999991
              ],
              [
                -73.93745906699988,
                40.74898291299991
              ],
              [
                -73.93752799699986,
                40.749049403999926
              ],
              [
                -73.93759818899993,
                40.74911710999986
              ],
              [
                -73.93773732499993,
                40.7492513079999
              ],
              [
                -73.9378270479999,
                40.749414816999895
              ],
              [
                -73.93796657799999,
                40.749669085999855
              ],
              [
                -73.9381182169999,
                40.749792644999935
              ],
              [
                -73.9388134609999,
                40.75006471699994
              ],
              [
                -73.93963348799986,
                40.75038201099994
              ],
              [
                -73.94003764200001,
                40.75055990099989
              ],
              [
                -73.94044179899981,
                40.75073778999991
              ],
              [
                -73.94055712800004,
                40.75079503999992
              ],
              [
                -73.94069186599994,
                40.75085230099989
              ],
              [
                -73.94126747099995,
                40.751116911999915
              ],
              [
                -73.94143064399995,
                40.75118721999993
              ],
              [
                -73.94206838500003,
                40.75146200499996
              ],
              [
                -73.94256342299988,
                40.75166697299995
              ],
              [
                -73.94270657799986,
                40.75172979099992
              ],
              [
                -73.94291412899997,
                40.751818828999916
              ],
              [
                -73.94345411699997,
                40.75205360799992
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000104638923425,
        "objectid": 146,
        "shape_leng": 0.0471405215717,
        "location_id": 146,
        "zone": "Long Island City/Queens Plaza",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.93675697199996,
                40.74914697199991
              ],
              [
                -73.93645660599992,
                40.74941256799988
              ],
              [
                -73.93616086999987,
                40.74967406999991
              ],
              [
                -73.93609498999984,
                40.7497315299999
              ],
              [
                -73.93474259199988,
                40.7509790439999
              ],
              [
                -73.93383269399993,
                40.75160470199992
              ],
              [
                -73.932276233,
                40.75195388599997
              ],
              [
                -73.93195863799987,
                40.75197425899992
              ],
              [
                -73.93126506799997,
                40.75199252199999
              ],
              [
                -73.93015432399984,
                40.75201212499995
              ],
              [
                -73.9291422419998,
                40.75203153499992
              ],
              [
                -73.92874215599983,
                40.752041624999954
              ],
              [
                -73.92790807399994,
                40.75205079499992
              ],
              [
                -73.92646866899993,
                40.75206856799986
              ],
              [
                -73.92596072499995,
                40.75209646699996
              ],
              [
                -73.92562743799989,
                40.752137243999904
              ],
              [
                -73.92415699999985,
                40.753961141999866
              ],
              [
                -73.92495400099993,
                40.75433675199992
              ],
              [
                -73.92575429299997,
                40.75471089899986
              ],
              [
                -73.92655412999981,
                40.75508563499988
              ],
              [
                -73.92735228500001,
                40.75546007599992
              ],
              [
                -73.92815372599988,
                40.755835855999905
              ],
              [
                -73.92895587499993,
                40.75621258399986
              ],
              [
                -73.92975052199988,
                40.75658057799993
              ],
              [
                -73.93055267499999,
                40.7569589429999
              ],
              [
                -73.93135128699987,
                40.75733408499986
              ],
              [
                -73.93215200299993,
                40.757710384999925
              ],
              [
                -73.93335306599997,
                40.75827433399996
              ],
              [
                -73.93425020699995,
                40.758695133999936
              ],
              [
                -73.93496053299995,
                40.75902508599996
              ],
              [
                -73.9350341019999,
                40.75905925799991
              ],
              [
                -73.93505987399978,
                40.75907122899988
              ],
              [
                -73.93579879199996,
                40.759418044999904
              ],
              [
                -73.93588175099988,
                40.75945697999988
              ],
              [
                -73.93679091699993,
                40.759882880999925
              ],
              [
                -73.93791705599988,
                40.7587066049999
              ],
              [
                -73.93910030499991,
                40.7574941399999
              ],
              [
                -73.94004034700002,
                40.75648675099994
              ],
              [
                -73.94123861599985,
                40.755234414999954
              ],
              [
                -73.94243020699994,
                40.75399761299994
              ],
              [
                -73.9438353679999,
                40.75253012299991
              ],
              [
                -73.94391620499988,
                40.75245068799991
              ],
              [
                -73.94392521099985,
                40.752441104999924
              ],
              [
                -73.94396284399984,
                40.75240407599993
              ],
              [
                -73.94401823399977,
                40.75234992099994
              ],
              [
                -73.944052652,
                40.75231325999994
              ],
              [
                -73.94345411699997,
                40.75205360799992
              ],
              [
                -73.94291412899997,
                40.751818828999916
              ],
              [
                -73.94270657799986,
                40.75172979099992
              ],
              [
                -73.94256342299988,
                40.75166697299995
              ],
              [
                -73.94206838500003,
                40.75146200499996
              ],
              [
                -73.94143064399995,
                40.75118721999993
              ],
              [
                -73.94126747099995,
                40.751116911999915
              ],
              [
                -73.94069186599994,
                40.75085230099989
              ],
              [
                -73.94055712800004,
                40.75079503999992
              ],
              [
                -73.94044179899981,
                40.75073778999991
              ],
              [
                -73.94003764200001,
                40.75055990099989
              ],
              [
                -73.93963348799986,
                40.75038201099994
              ],
              [
                -73.9388134609999,
                40.75006471699994
              ],
              [
                -73.9381182169999,
                40.749792644999935
              ],
              [
                -73.93796657799999,
                40.749669085999855
              ],
              [
                -73.9378270479999,
                40.749414816999895
              ],
              [
                -73.93773732499993,
                40.7492513079999
              ],
              [
                -73.93759818899993,
                40.74911710999986
              ],
              [
                -73.93752799699986,
                40.749049403999926
              ],
              [
                -73.93745906699988,
                40.74898291299991
              ],
              [
                -73.93739653799992,
                40.74891643999991
              ],
              [
                -73.93675697199996,
                40.74914697199991
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000106418172757,
        "objectid": 147,
        "shape_leng": 0.0587654949268,
        "location_id": 147,
        "zone": "Longwood",
        "borough": "Bronx"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.89515277899991,
                40.82783428999991
              ],
              [
                -73.8951102089999,
                40.82803689899988
              ],
              [
                -73.89529156500001,
                40.82814234999994
              ],
              [
                -73.89548522799996,
                40.82824854099993
              ],
              [
                -73.89576668599982,
                40.8282118029999
              ],
              [
                -73.89700278099993,
                40.8280504559999
              ],
              [
                -73.89820469299998,
                40.82788910899996
              ],
              [
                -73.89932566999991,
                40.82774501799988
              ],
              [
                -73.89940994800001,
                40.82773418399995
              ],
              [
                -73.89979756799993,
                40.82768435399992
              ],
              [
                -73.90093510699997,
                40.82792810199991
              ],
              [
                -73.90164895599993,
                40.82625697799991
              ],
              [
                -73.90050540499992,
                40.82600762199993
              ],
              [
                -73.901176691,
                40.824388908999914
              ],
              [
                -73.90181687399985,
                40.82285063499994
              ],
              [
                -73.901846838,
                40.822778632999885
              ],
              [
                -73.90198758599993,
                40.822440422999904
              ],
              [
                -73.90061141099989,
                40.822108807999875
              ],
              [
                -73.90084561199987,
                40.82154141799985
              ],
              [
                -73.9012927759998,
                40.820475442999914
              ],
              [
                -73.9016012119998,
                40.81969485899989
              ],
              [
                -73.90166319399991,
                40.81955042599994
              ],
              [
                -73.90210743299993,
                40.81850519199991
              ],
              [
                -73.90257859099984,
                40.81736979399992
              ],
              [
                -73.90300983199995,
                40.81631779199992
              ],
              [
                -73.90306443799986,
                40.8161067619999
              ],
              [
                -73.9034699719999,
                40.81507578799996
              ],
              [
                -73.90380412299992,
                40.81413184199994
              ],
              [
                -73.9041833779999,
                40.813086454999905
              ],
              [
                -73.9044655149999,
                40.81228195999996
              ],
              [
                -73.90418036399996,
                40.81212482099991
              ],
              [
                -73.90341720399988,
                40.811506882999886
              ],
              [
                -73.90324273499989,
                40.8107128849999
              ],
              [
                -73.90307457199998,
                40.80988061199988
              ],
              [
                -73.90279087799998,
                40.810083046999864
              ],
              [
                -73.90259424199986,
                40.81022335899997
              ],
              [
                -73.90090498099991,
                40.811428712999884
              ],
              [
                -73.89912449199987,
                40.81269524099988
              ],
              [
                -73.89844723499995,
                40.81317650299993
              ],
              [
                -73.8981505179999,
                40.81336635499991
              ],
              [
                -73.89786486599994,
                40.81356666999991
              ],
              [
                -73.89775779499988,
                40.81364891599992
              ],
              [
                -73.89759123799989,
                40.81377687299992
              ],
              [
                -73.89733049099995,
                40.81399633599995
              ],
              [
                -73.89708341799987,
                40.81422434699991
              ],
              [
                -73.89685073199996,
                40.81446016799987
              ],
              [
                -73.89542858799986,
                40.81584467199988
              ],
              [
                -73.89580071599984,
                40.81606564899989
              ],
              [
                -73.8962135219999,
                40.81631897099989
              ],
              [
                -73.8970446669999,
                40.816801859999906
              ],
              [
                -73.89567610799998,
                40.81812936799991
              ],
              [
                -73.894930762,
                40.81884671999994
              ],
              [
                -73.89389462500002,
                40.8198602899999
              ],
              [
                -73.89290156199988,
                40.82081122299991
              ],
              [
                -73.89283653100001,
                40.82097405499998
              ],
              [
                -73.89387380299988,
                40.820979334999926
              ],
              [
                -73.89490478799985,
                40.820987519999946
              ],
              [
                -73.8958813589999,
                40.82099257499988
              ],
              [
                -73.89586019599982,
                40.822631045999856
              ],
              [
                -73.89488228899991,
                40.82313435499988
              ],
              [
                -73.89433874699982,
                40.82341635299984
              ],
              [
                -73.89390164399987,
                40.823643120999904
              ],
              [
                -73.89320004999996,
                40.824008182999926
              ],
              [
                -73.89389702299997,
                40.82398399799994
              ],
              [
                -73.89487088500002,
                40.82393727199993
              ],
              [
                -73.89483522899992,
                40.826246840999964
              ],
              [
                -73.89515277899991,
                40.82783428999991
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0000697489921327,
        "objectid": 148,
        "shape_leng": 0.0391305015633,
        "location_id": 148,
        "zone": "Lower East Side",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.98447731699999,
                40.720234238999886
              ],
              [
                -73.98386422799992,
                40.7213963779999
              ],
              [
                -73.9838238729998,
                40.72147287199987
              ],
              [
                -73.98467780299995,
                40.72173060499992
              ],
              [
                -73.98551686199984,
                40.72198522499987
              ],
              [
                -73.98628574000003,
                40.722241919999874
              ],
              [
                -73.98713584799998,
                40.72246981099991
              ],
              [
                -73.98786491500006,
                40.72269708899991
              ],
              [
                -73.98854361399987,
                40.722903617999904
              ],
              [
                -73.98863862799996,
                40.72293371999991
              ],
              [
                -73.98875683199988,
                40.72297036599986
              ],
              [
                -73.98939218099996,
                40.723174057999884
              ],
              [
                -73.99027361600001,
                40.72342601799987
              ],
              [
                -73.99102410399988,
                40.72364943199991
              ],
              [
                -73.99127307099994,
                40.7237298129999
              ],
              [
                -73.99135472799998,
                40.723754618999855
              ],
              [
                -73.99250385799984,
                40.72410592499995
              ],
              [
                -73.99260322199994,
                40.72413644999988
              ],
              [
                -73.99263254999998,
                40.7240568649999
              ],
              [
                -73.99308859399994,
                40.72281909799991
              ],
              [
                -73.99325915400001,
                40.72235330999995
              ],
              [
                -73.99352297999984,
                40.7216316929999
              ],
              [
                -73.99379527399988,
                40.72093644299991
              ],
              [
                -73.99403499399986,
                40.720323010999884
              ],
              [
                -73.99437745899998,
                40.71951908899993
              ],
              [
                -73.99442117799985,
                40.719391298999874
              ],
              [
                -73.99480778999998,
                40.71845742599988
              ],
              [
                -73.99542875899999,
                40.717279242999936
              ],
              [
                -73.99580912300002,
                40.71664465899988
              ],
              [
                -73.99605872699998,
                40.7162316399999
              ],
              [
                -73.99614929199988,
                40.716084840999955
              ],
              [
                -73.99622493199992,
                40.71599579099993
              ],
              [
                -73.99653128199989,
                40.715491390999894
              ],
              [
                -73.99680607099992,
                40.71503695599991
              ],
              [
                -73.99709223399998,
                40.71461839799991
              ],
              [
                -73.99750445299988,
                40.71406913199994
              ],
              [
                -73.99744448599988,
                40.71406687299986
              ],
              [
                -73.99647830999992,
                40.7141072639999
              ],
              [
                -73.99484048899991,
                40.71420935199993
              ],
              [
                -73.99472479499984,
                40.71421571299988
              ],
              [
                -73.99465684999993,
                40.714219446999884
              ],
              [
                -73.99450033499993,
                40.71423116399996
              ],
              [
                -73.99434786700003,
                40.71424791599986
              ],
              [
                -73.99431855699987,
                40.71425088499997
              ],
              [
                -73.99414039099992,
                40.71426893199987
              ],
              [
                -73.99395033499991,
                40.71429083199993
              ],
              [
                -73.99301588399996,
                40.71435562499989
              ],
              [
                -73.99282173499992,
                40.71436522999988
              ],
              [
                -73.99256242199989,
                40.714388076999974
              ],
              [
                -73.99213486499995,
                40.7144198019999
              ],
              [
                -73.99122551100001,
                40.714486643999926
              ],
              [
                -73.99075515399984,
                40.71455407299985
              ],
              [
                -73.99022012699989,
                40.71440493999988
              ],
              [
                -73.99022750999988,
                40.714491668999926
              ],
              [
                -73.99022155699988,
                40.71457875599987
              ],
              [
                -73.9902023599999,
                40.71466442999987
              ],
              [
                -73.98975418199988,
                40.71555234799996
              ],
              [
                -73.98915463799979,
                40.716708646999905
              ],
              [
                -73.98836626299988,
                40.71644570199992
              ],
              [
                -73.98795181500003,
                40.717265627999915
              ],
              [
                -73.98791645999995,
                40.71733557399992
              ],
              [
                -73.98787935799997,
                40.7174089729999
              ],
              [
                -73.98751208099989,
                40.71807218599989
              ],
              [
                -73.98743405799996,
                40.718213079999934
              ],
              [
                -73.98739393199992,
                40.7183035229999
              ],
              [
                -73.98736015299995,
                40.71836979699993
              ],
              [
                -73.98675136699994,
                40.719592845999955
              ],
              [
                -73.9859109239999,
                40.71933897299989
              ],
              [
                -73.985073423,
                40.71908329399989
              ],
              [
                -73.98447731699999,
                40.720234238999886
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0000324684964817,
        "objectid": 153,
        "shape_leng": 0.024737239022,
        "location_id": 153,
        "zone": "Marble Hill",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.90666530999995,
                40.875715608999904
              ],
              [
                -73.9068187309999,
                40.87661958799991
              ],
              [
                -73.90703748899998,
                40.87689516099993
              ],
              [
                -73.90765281699993,
                40.87742802299989
              ],
              [
                -73.90835569799991,
                40.87791831399997
              ],
              [
                -73.90906235999998,
                40.878486782999886
              ],
              [
                -73.90949832099987,
                40.87877673199989
              ],
              [
                -73.91033193599998,
                40.879038046999895
              ],
              [
                -73.91148913199984,
                40.87901885899991
              ],
              [
                -73.91205829999997,
                40.878119319999904
              ],
              [
                -73.91487449199987,
                40.876642145999924
              ],
              [
                -73.91571027699979,
                40.875795454999924
              ],
              [
                -73.91574356,
                40.875760999999954
              ],
              [
                -73.91578609320084,
                40.87571718248032
              ],
              [
                -73.9157431844291,
                40.875713322870155
              ],
              [
                -73.91572098193815,
                40.87570967097484
              ],
              [
                -73.91570881079426,
                40.875693136137784
              ],
              [
                -73.91567267909491,
                40.875685508351
              ],
              [
                -73.9156178241692,
                40.875677866462915
              ],
              [
                -73.9155856207657,
                40.875662969433066
              ],
              [
                -73.91557648264494,
                40.87565833610146
              ],
              [
                -73.91555253991932,
                40.875654021779646
              ],
              [
                -73.91552990793934,
                40.875645742470525
              ],
              [
                -73.91551075196118,
                40.875643414795675
              ],
              [
                -73.91548768177182,
                40.875636787460465
              ],
              [
                -73.91546939921095,
                40.87563214618999
              ],
              [
                -73.91545588514181,
                40.875621131955185
              ],
              [
                -73.91542631477185,
                40.875607945317405
              ],
              [
                -73.91540287121052,
                40.87559941029492
              ],
              [
                -73.91538349409625,
                40.87559320888148
              ],
              [
                -73.91535186479148,
                40.875594734825164
              ],
              [
                -73.91406883175179,
                40.87520834074647
              ],
              [
                -73.91407508569827,
                40.875182799046996
              ],
              [
                -73.91388267200851,
                40.87512957211256
              ],
              [
                -73.91387403109368,
                40.87512410000559
              ],
              [
                -73.91386393524301,
                40.87512409223816
              ],
              [
                -73.91377097505355,
                40.87509610769932
              ],
              [
                -73.91365295999721,
                40.875058144214975
              ],
              [
                -73.91364510961307,
                40.87506479319905
              ],
              [
                -73.9136317314757,
                40.875061983121626
              ],
              [
                -73.91362249791955,
                40.87506547903329
              ],
              [
                -73.9136017368192,
                40.87506476096734
              ],
              [
                -73.91358789193708,
                40.87506580397602
              ],
              [
                -73.91358236087551,
                40.87505879442265
              ],
              [
                -73.91356898724574,
                40.87505212924253
              ],
              [
                -73.91354822577777,
                40.87505316849612
              ],
              [
                -73.91353440086448,
                40.87504719658454
              ],
              [
                -73.91350901692014,
                40.87504437616451
              ],
              [
                -73.91350919503029,
                40.87503478681045
              ],
              [
                -73.91349978845808,
                40.875034131379806
              ],
              [
                -73.91348952397077,
                40.87503607785827
              ],
              [
                -73.91350066356716,
                40.87501790507695
              ],
              [
                -73.91294378259612,
                40.87484934731967
              ],
              [
                -73.91293626418764,
                40.87485619390874
              ],
              [
                -73.9129212312108,
                40.87485789377647
              ],
              [
                -73.91290469377688,
                40.8748578810364
              ],
              [
                -73.9128949292361,
                40.87484988736233
              ],
              [
                -73.91288441177969,
                40.8748458800894
              ],
              [
                -73.91287914404397,
                40.874855007183406
              ],
              [
                -73.91286937917496,
                40.87484701292165
              ],
              [
                -73.91285660205791,
                40.87484757013021
              ],
              [
                -73.91284834382581,
                40.8748395772712
              ],
              [
                -73.91282129694946,
                40.87482985793964
              ],
              [
                -73.91280626515456,
                40.874829846761436
              ],
              [
                -73.91280025722797,
                40.87482528618445
              ],
              [
                -73.91278749082916,
                40.87481671205942
              ],
              [
                -73.9127687078019,
                40.87481099814735
              ],
              [
                -73.91275367183313,
                40.87481497632551
              ],
              [
                -73.91274316499636,
                40.87480241502598
              ],
              [
                -73.91272859671744,
                40.87480240476915
              ],
              [
                -73.91272287889382,
                40.87480240010714
              ],
              [
                -73.91270634490408,
                40.87480010903188
              ],
              [
                -73.9126962150604,
                40.87478954831901
              ],
              [
                -73.91267517023708,
                40.874788955883474
              ],
              [
                -73.91264284973255,
                40.87478379887098
              ],
              [
                -73.91261880851071,
                40.874779790908924
              ],
              [
                -73.91259176808397,
                40.87476436313473
              ],
              [
                -73.91255570224403,
                40.87475692486938
              ],
              [
                -73.912551200576,
                40.874751780277656
              ],
              [
                -73.912529410846,
                40.8747494851984
              ],
              [
                -73.9125256581892,
                40.87474377377167
              ],
              [
                -73.91251965496578,
                40.87473521388558
              ],
              [
                -73.91250687871081,
                40.87473406062238
              ],
              [
                -73.91250236313707,
                40.87474033369901
              ],
              [
                -73.91249033807661,
                40.87473690281091
              ],
              [
                -73.91248809220902,
                40.874730624171306
              ],
              [
                -73.91247757264037,
                40.87472833869738
              ],
              [
                -73.91245729299712,
                40.87472375717076
              ],
              [
                -73.91245203282763,
                40.87471804391961
              ],
              [
                -73.91244000191651,
                40.87471861183008
              ],
              [
                -73.91243099100983,
                40.87471518316869
              ],
              [
                -73.91242271779869,
                40.87471859877235
              ],
              [
                -73.91240469493584,
                40.87471174086121
              ],
              [
                -73.91239492490993,
                40.874707168154984
              ],
              [
                -73.91238741516419,
                40.874707162804576
              ],
              [
                -73.91237764718186,
                40.8747020128729
              ],
              [
                -73.91236862605753,
                40.874706004164956
              ],
              [
                -73.9123648748739,
                40.87469858203209
              ],
              [
                -73.9123588548898,
                40.87470257521208
              ],
              [
                -73.9123498419076,
                40.87470028136261
              ],
              [
                -73.9123558652191,
                40.874694017966114
              ],
              [
                -73.91234910104856,
                40.874686593188855
              ],
              [
                -73.91234308865762,
                40.8746940081139
              ],
              [
                -73.91233707714717,
                40.874691716904124
              ],
              [
                -73.91234234304949,
                40.87468373307686
              ],
              [
                -73.91233333490989,
                40.87467688342118
              ],
              [
                -73.91232355802433,
                40.87467858616002
              ],
              [
                -73.91231155258637,
                40.874669446851534
              ],
              [
                -73.91230627871727,
                40.874674574878256
              ],
              [
                -73.91229500780878,
                40.874675710853595
              ],
              [
                -73.91228449380333,
                40.87466829175391
              ],
              [
                -73.91227397278395,
                40.87466770678097
              ],
              [
                -73.91227699162117,
                40.8746540217871
              ],
              [
                -73.91225294388349,
                40.87465513804568
              ],
              [
                -73.91223791359347,
                40.87465455896359
              ],
              [
                -73.91222813320367,
                40.87465854110614
              ],
              [
                -73.91222814396944,
                40.87465056297354
              ],
              [
                -73.91221566296912,
                40.87464190888751
              ],
              [
                -73.91221454479957,
                40.87463509998273
              ],
              [
                -73.9121764462137,
                40.874621456116515
              ],
              [
                -73.91215290813037,
                40.87461377507739
              ],
              [
                -73.91208452800096,
                40.874595847197156
              ],
              [
                -73.91203072161403,
                40.8745830378179
              ],
              [
                -73.91196907175222,
                40.87456256846288
              ],
              [
                -73.91196570064146,
                40.87457277735309
              ],
              [
                -73.91195784463281,
                40.87457447245355
              ],
              [
                -73.91195447088613,
                40.87457872896526
              ],
              [
                -73.91194663053723,
                40.87457702112579
              ],
              [
                -73.9119444037699,
                40.87456595249829
              ],
              [
                -73.91193318113005,
                40.8745659442165
              ],
              [
                -73.91192983324362,
                40.874558278608944
              ],
              [
                -73.91192086201634,
                40.874560828884455
              ],
              [
                -73.9119152574419,
                40.87455571920048
              ],
              [
                -73.91189619479312,
                40.87455399388582
              ],
              [
                -73.91188274672243,
                40.87455057896902
              ],
              [
                -73.91185921079003,
                40.874541205994404
              ],
              [
                -73.91183904256401,
                40.874532671021676
              ],
              [
                -73.91180933995194,
                40.87452201416823
              ],
              [
                -73.91179139183596,
                40.87452114412123
              ],
              [
                -73.91177009202042,
                40.87451602293514
              ],
              [
                -73.91173983012341,
                40.87450578818882
              ],
              [
                -73.91173423700735,
                40.874501524916205
              ],
              [
                -73.91170284178415,
                40.874496395362755
              ],
              [
                -73.91169275467155,
                40.87448957927271
              ],
              [
                -73.91168826975449,
                40.87449043115864
              ],
              [
                -73.91167146278335,
                40.87448786152828
              ],
              [
                -73.91166810950028,
                40.87447594488272
              ],
              [
                -73.91165017603142,
                40.874473374319955
              ],
              [
                -73.91163897503573,
                40.87445719203627
              ],
              [
                -73.91162776520517,
                40.874456337333214
              ],
              [
                -73.91162329185646,
                40.874447815101746
              ],
              [
                -73.91159974693575,
                40.87444524899033
              ],
              [
                -73.91159751286466,
                40.874439285901424
              ],
              [
                -73.91157173258563,
                40.874431612885914
              ],
              [
                -73.91155043066773,
                40.87442818308494
              ],
              [
                -73.91151006661373,
                40.87442304598177
              ],
              [
                -73.91148428297787,
                40.87441792104998
              ],
              [
                -73.91131058924775,
                40.87434481582342
              ],
              [
                -73.91127864317853,
                40.87433136924933
              ],
              [
                -73.91127192479053,
                40.87431604794521
              ],
              [
                -73.9112629469905,
                40.87432370407539
              ],
              [
                -73.91125288020052,
                40.87431007188996
              ],
              [
                -73.91119459821621,
                40.87428194078672
              ],
              [
                -73.91115761996681,
                40.87426573964851
              ],
              [
                -73.91111279481103,
                40.874242725000144
              ],
              [
                -73.9110690967962,
                40.87422141212586
              ],
              [
                -73.91098953820747,
                40.87417963995649
              ],
              [
                -73.91092118004799,
                40.87414639601998
              ],
              [
                -73.91085171090067,
                40.87410973724408
              ],
              [
                -73.91080913168175,
                40.874085022055525
              ],
              [
                -73.91065439307907,
                40.8739759430305
              ],
              [
                -73.9105060659268,
                40.87388026812663
              ],
              [
                -73.91044539476847,
                40.87383862805885
              ],
              [
                -73.9103551397179,
                40.87377785515433
              ],
              [
                -73.9102737818055,
                40.87370809418795
              ],
              [
                -73.9101894638254,
                40.87364283404717
              ],
              [
                -73.91018355408711,
                40.87362708835755
              ],
              [
                -73.91017913401178,
                40.87361472095865
              ],
              [
                -73.91016876214525,
                40.873616964007915
              ],
              [
                -73.91010367477757,
                40.87356070595167
              ],
              [
                -73.91010961470225,
                40.87355396586297
              ],
              [
                -73.91009482143302,
                40.87353708775016
              ],
              [
                -73.91007556909462,
                40.8735359559803
              ],
              [
                -73.91001640976121,
                40.87348194410383
              ],
              [
                -73.90996168304555,
                40.87343132034095
              ],
              [
                -73.90990769944696,
                40.873383498929115
              ],
              [
                -73.90984559578646,
                40.87331376126337
              ],
              [
                -73.90978644251612,
                40.87324738489864
              ],
              [
                -73.90974797469846,
                40.873218124915354
              ],
              [
                -73.90971690053208,
                40.87320461120855
              ],
              [
                -73.9097095289807,
                40.87318212947383
              ],
              [
                -73.90967403485497,
                40.87314725286422
              ],
              [
                -73.90965332823252,
                40.873124751217716
              ],
              [
                -73.90963706316084,
                40.87310788118937
              ],
              [
                -73.90960006660967,
                40.87308761824522
              ],
              [
                -73.90958973101802,
                40.87306400028056
              ],
              [
                -73.90957936387078,
                40.87306286600733
              ],
              [
                -73.90954388318947,
                40.87301787612438
              ],
              [
                -73.90951136313969,
                40.87297738355044
              ],
              [
                -73.90943966199148,
                40.87288233476204
              ],
              [
                -73.90935540980549,
                40.87277097703952
              ],
              [
                -73.90927114016716,
                40.87266299598998
              ],
              [
                -73.90926526019165,
                40.87263376123266
              ],
              [
                -73.90924604694906,
                40.872604525423235
              ],
              [
                -73.90921055884137,
                40.87256514682172
              ],
              [
                -73.90919137619544,
                40.87251342547653
              ],
              [
                -73.90917513278698,
                40.87248081419602
              ],
              [
                -73.90913961647068,
                40.87246279498993
              ],
              [
                -73.90909969322314,
                40.87241442532674
              ],
              [
                -73.90909972105362,
                40.87239419188019
              ],
              [
                -73.90908937875491,
                40.87237507530972
              ],
              [
                -73.90905980364177,
                40.87234132839487
              ],
              [
                -73.90901544805163,
                40.87228958702743
              ],
              [
                -73.90897851360198,
                40.8722243539186
              ],
              [
                -73.90893235220783,
                40.87215734798525
              ],
              [
                -73.90872046399987,
                40.87222866599997
              ],
              [
                -73.90846367899988,
                40.87262079199995
              ],
              [
                -73.9077427079998,
                40.87284595199988
              ],
              [
                -73.90746489800001,
                40.87354735399992
              ],
              [
                -73.90716476399983,
                40.87437421899993
              ],
              [
                -73.90692502800006,
                40.87503467099995
              ],
              [
                -73.90666530999995,
                40.875715608999904
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.00178968692433,
        "objectid": 154,
        "shape_leng": 0.22733157076,
        "location_id": 154,
        "zone": "Marine Park/Floyd Bennett Field",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.93531145199985,
                40.608860039999875
              ],
              [
                -73.93572378299984,
                40.60922748599994
              ],
              [
                -73.936046277,
                40.609514868999966
              ],
              [
                -73.93676116199995,
                40.609055286999926
              ],
              [
                -73.93709375999993,
                40.608841982999934
              ],
              [
                -73.93753749399987,
                40.60855738999986
              ],
              [
                -73.93829679599983,
                40.60806269399995
              ],
              [
                -73.93901246899993,
                40.607599476999965
              ],
              [
                -73.93933968999978,
                40.60738555999993
              ],
              [
                -73.93939269099997,
                40.60735090899986
              ],
              [
                -73.93972613899992,
                40.60713291499996
              ],
              [
                -73.9384349359999,
                40.605973862999896
              ],
              [
                -73.93791557899978,
                40.60549790099989
              ],
              [
                -73.9360940729999,
                40.6038731619999
              ],
              [
                -73.93426266699994,
                40.60223071099988
              ],
              [
                -73.9335511719998,
                40.602687280999916
              ],
              [
                -73.93173390199983,
                40.601062068999894
              ],
              [
                -73.92992224899984,
                40.599437382999895
              ],
              [
                -73.92813139699999,
                40.59777792799987
              ],
              [
                -73.92879010199995,
                40.59732329499992
              ],
              [
                -73.929124371,
                40.5971073039999
              ],
              [
                -73.92956858299992,
                40.59682026499993
              ],
              [
                -73.92866850999998,
                40.59602045099991
              ],
              [
                -73.92759936700004,
                40.5950607789999
              ],
              [
                -73.92653305100005,
                40.59410431499987
              ],
              [
                -73.9254714779999,
                40.59314878799997
              ],
              [
                -73.92440413399991,
                40.59218425199988
              ],
              [
                -73.92405498699993,
                40.591871702999875
              ],
              [
                -73.92332177899988,
                40.591211915999956
              ],
              [
                -73.922307925,
                40.5903082079999
              ],
              [
                -73.92095992099986,
                40.58909565099987
              ],
              [
                -73.91972510799992,
                40.5879872779999
              ],
              [
                -73.91819087799986,
                40.58660685499996
              ],
              [
                -73.91809293895267,
                40.586570621122036
              ],
              [
                -73.91804018286068,
                40.58667554276565
              ],
              [
                -73.91797994101194,
                40.586778094671196
              ],
              [
                -73.91791239551053,
                40.58687796681937
              ],
              [
                -73.91783775059895,
                40.586974857171796
              ],
              [
                -73.9177562319928,
                40.58706847270638
              ],
              [
                -73.91766808619886,
                40.58715853030392
              ],
              [
                -73.917573579769,
                40.587244757604196
              ],
              [
                -73.91747299849543,
                40.587326893829655
              ],
              [
                -73.91736664654537,
                40.58740469057485
              ],
              [
                -73.9172548455425,
                40.58747791255727
              ],
              [
                -73.91713793359288,
                40.5875463383291
              ],
              [
                -73.91708037510332,
                40.58757879416149
              ],
              [
                -73.91701314900615,
                40.5876122064447
              ],
              [
                -73.91698977173137,
                40.587623206627434
              ],
              [
                -73.91693796566115,
                40.58764758526024
              ],
              [
                -73.91688299692045,
                40.5876685992666
              ],
              [
                -73.91683061848151,
                40.587691618270895
              ],
              [
                -73.91678987631491,
                40.587709099449924
              ],
              [
                -73.91674383919741,
                40.58772855479769
              ],
              [
                -73.91671105957896,
                40.58774203491711
              ],
              [
                -73.91666397617632,
                40.58776308083632
              ],
              [
                -73.9165930400693,
                40.5877980810418
              ],
              [
                -73.91656706158336,
                40.58781321303844
              ],
              [
                -73.9165470007693,
                40.587819539458216
              ],
              [
                -73.91652639955635,
                40.58782465776249
              ],
              [
                -73.91650162698664,
                40.58782612139333
              ],
              [
                -73.9164911196411,
                40.58782205150422
              ],
              [
                -73.91648378328203,
                40.5878151841674
              ],
              [
                -73.9164774856515,
                40.58781273667597
              ],
              [
                -73.91646960332541,
                40.58781069981946
              ],
              [
                -73.91645584254458,
                40.5878154377661
              ],
              [
                -73.9164547143442,
                40.58782509920445
              ],
              [
                -73.91645091822839,
                40.587838737526255
              ],
              [
                -73.91643920245625,
                40.58785031266023
              ],
              [
                -73.91642231217018,
                40.587853840607266
              ],
              [
                -73.9164143062014,
                40.587863853879874
              ],
              [
                -73.91639678848273,
                40.58788022701923
              ],
              [
                -73.91638561041509,
                40.58789303823581
              ],
              [
                -73.91636700973291,
                40.58791100311697
              ],
              [
                -73.9163574633772,
                40.58791638860475
              ],
              [
                -73.91634791504127,
                40.587921774760396
              ],
              [
                -73.91629459488155,
                40.5879456095403
              ],
              [
                -73.9161180019858,
                40.588028905209704
              ],
              [
                -73.91605017778171,
                40.58806135353307
              ],
              [
                -73.91589662195774,
                40.58813277847436
              ],
              [
                -73.91574214296385,
                40.58819592473777
              ],
              [
                -73.91560044596098,
                40.58825666809438
              ],
              [
                -73.91548337245831,
                40.588307980061984
              ],
              [
                -73.91531479153443,
                40.58838399235685
              ],
              [
                -73.91519736488564,
                40.58844309137683
              ],
              [
                -73.91503100554014,
                40.58852178603493
              ],
              [
                -73.91493950490353,
                40.58856585747424
              ],
              [
                -73.91481632371293,
                40.58862640331881
              ],
              [
                -73.914714923843,
                40.58867093766245
              ],
              [
                -73.91457898189549,
                40.58872901662642
              ],
              [
                -73.91454699795425,
                40.58874232600697
              ],
              [
                -73.91453034938282,
                40.58875299362349
              ],
              [
                -73.91451559869842,
                40.58876804773156
              ],
              [
                -73.9144822973293,
                40.588790601726366
              ],
              [
                -73.91444263086916,
                40.5888082711758
              ],
              [
                -73.91440356140954,
                40.58883592346303
              ],
              [
                -73.91434427671781,
                40.588886889305044
              ],
              [
                -73.91431860453066,
                40.58891725229078
              ],
              [
                -73.91431473855607,
                40.58892551731867
              ],
              [
                -73.9143124426024,
                40.58893987254068
              ],
              [
                -73.91430342280617,
                40.5889583493321
              ],
              [
                -73.91427808727641,
                40.5889845729084
              ],
              [
                -73.91426299448393,
                40.5890049813031
              ],
              [
                -73.91425076198561,
                40.58902831722852
              ],
              [
                -73.91422882674009,
                40.58908083807476
              ],
              [
                -73.91421550107218,
                40.589136057374354
              ],
              [
                -73.9142056620668,
                40.58919688363613
              ],
              [
                -73.91420586362732,
                40.5892243877569
              ],
              [
                -73.91421185535758,
                40.58924192820198
              ],
              [
                -73.91421687783043,
                40.58926214303232
              ],
              [
                -73.91422064513259,
                40.58927700017323
              ],
              [
                -73.91422754452432,
                40.589306468549864
              ],
              [
                -73.91423671673226,
                40.58932645042782
              ],
              [
                -73.91424721366302,
                40.58933597007819
              ],
              [
                -73.914259657519,
                40.58933770474941
              ],
              [
                -73.91426381938237,
                40.5893350360962
              ],
              [
                -73.91427056247792,
                40.589326047479624
              ],
              [
                -73.91427801098399,
                40.589301482673804
              ],
              [
                -73.91429566541713,
                40.58927962035804
              ],
              [
                -73.91433571458899,
                40.58924686137038
              ],
              [
                -73.91435841159172,
                40.58924034514564
              ],
              [
                -73.91437694166262,
                40.589237713498434
              ],
              [
                -73.91440312160414,
                40.58923802115436
              ],
              [
                -73.91443404829512,
                40.58924807869657
              ],
              [
                -73.91445507154582,
                40.58925981353339
              ],
              [
                -73.91447191089588,
                40.589279085247334
              ],
              [
                -73.91448014568856,
                40.589294439749025
              ],
              [
                -73.91449040241481,
                40.58935993947872
              ],
              [
                -73.91450820178449,
                40.589378968648916
              ],
              [
                -73.91453148417652,
                40.58938486809347
              ],
              [
                -73.91454905473766,
                40.58938247778483
              ],
              [
                -73.91456983819307,
                40.58937522665172
              ],
              [
                -73.91458106544532,
                40.58936308543215
              ],
              [
                -73.91459071646592,
                40.58934607044761
              ],
              [
                -73.91460418691328,
                40.589331743796976
              ],
              [
                -73.91461314912445,
                40.58932665387823
              ],
              [
                -73.91461921395867,
                40.58932715577966
              ],
              [
                -73.91462236929549,
                40.58933568352719
              ],
              [
                -73.91461687594222,
                40.58935124627611
              ],
              [
                -73.91461455401743,
                40.58937168732432
              ],
              [
                -73.91461767341197,
                40.589388733747064
              ],
              [
                -73.91462335306716,
                40.589404324566445
              ],
              [
                -73.91462521460305,
                40.5894172292305
              ],
              [
                -73.91462008284304,
                40.589422572285436
              ],
              [
                -73.91461210133193,
                40.5894225521145
              ],
              [
                -73.9145990233472,
                40.589419355148785
              ],
              [
                -73.9145891302395,
                40.58941860061437
              ],
              [
                -73.91458143799926,
                40.589425396589455
              ],
              [
                -73.91457116498613,
                40.58943851454467
              ],
              [
                -73.91457013332167,
                40.58945579393127
              ],
              [
                -73.91458120974193,
                40.58947894467519
              ],
              [
                -73.91458236504873,
                40.58950742619977
              ],
              [
                -73.91457938937448,
                40.58953127079049
              ],
              [
                -73.91457292166801,
                40.589550727703354
              ],
              [
                -73.91456705345101,
                40.589578947015895
              ],
              [
                -73.91456756604065,
                40.58960840023556
              ],
              [
                -73.91457929644203,
                40.58962814576077
              ],
              [
                -73.9145856549886,
                40.58963424671419
              ],
              [
                -73.9145849811801,
                40.589642520657094
              ],
              [
                -73.91457952837368,
                40.58964834880415
              ],
              [
                -73.9145651300669,
                40.58965561507336
              ],
              [
                -73.91454975235602,
                40.58966799042617
              ],
              [
                -73.91453781571583,
                40.58969692543289
              ],
              [
                -73.91453482435507,
                40.58972442231839
              ],
              [
                -73.91454321966893,
                40.58977701838073
              ],
              [
                -73.91457227110722,
                40.58985278772232
              ],
              [
                -73.91458333215583,
                40.58987958958919
              ],
              [
                -73.91458331076498,
                40.58988445831207
              ],
              [
                -73.91457974374596,
                40.589897105301254
              ],
              [
                -73.91457011491376,
                40.58990925156168
              ],
              [
                -73.91456114732735,
                40.589915559076424
              ],
              [
                -73.91454447795088,
                40.58993109541749
              ],
              [
                -73.91454059121104,
                40.58994422917545
              ],
              [
                -73.91454758323465,
                40.58995179078809
              ],
              [
                -73.91456415888068,
                40.58995816118874
              ],
              [
                -73.91458713986239,
                40.58995967916908
              ],
              [
                -73.91461045994836,
                40.5899570592537
              ],
              [
                -73.91464529511366,
                40.58994911257965
              ],
              [
                -73.91466350346147,
                40.589946968157875
              ],
              [
                -73.91468712274471,
                40.58994873085014
              ],
              [
                -73.91470241341574,
                40.589957044120176
              ],
              [
                -73.91476036036907,
                40.58999515800442
              ],
              [
                -73.91481733329107,
                40.59003716401749
              ],
              [
                -73.91485837901661,
                40.59007061286331
              ],
              [
                -73.91490766991242,
                40.59011697995943
              ],
              [
                -73.91493149706874,
                40.590145030794716
              ],
              [
                -73.91493875864109,
                40.59016427725421
              ],
              [
                -73.91493900169378,
                40.590182047274766
              ],
              [
                -73.91493443939666,
                40.5901919680025
              ],
              [
                -73.9149335231024,
                40.59019396058866
              ],
              [
                -73.91491911877063,
                40.59020244399837
              ],
              [
                -73.91490185955985,
                40.59020678229064
              ],
              [
                -73.91488937785802,
                40.59021356585977
              ],
              [
                -73.91488456355718,
                40.590219639254286
              ],
              [
                -73.91488771456264,
                40.59022938342131
              ],
              [
                -73.9149007396338,
                40.59024474914581
              ],
              [
                -73.91491473902336,
                40.59025622455302
              ],
              [
                -73.91493577149043,
                40.59026552527298
              ],
              [
                -73.91495457131008,
                40.590274577901006
              ],
              [
                -73.91496060892453,
                40.59028116543617
              ],
              [
                -73.91496407656577,
                40.59029163992844
              ],
              [
                -73.91496944173487,
                40.59030650040353
              ],
              [
                -73.91497578584391,
                40.59031625245941
              ],
              [
                -73.91499456432393,
                40.59033017263103
              ],
              [
                -73.91501972942814,
                40.59034410841229
              ],
              [
                -73.91502991287624,
                40.590351679559056
              ],
              [
                -73.9150441987662,
                40.59037118752542
              ],
              [
                -73.91507343618841,
                40.590403144927166
              ],
              [
                -73.91509954328245,
                40.59042049211745
              ],
              [
                -73.91511643660108,
                40.590427592263175
              ],
              [
                -73.91512345801782,
                40.59042785326721
              ],
              [
                -73.91513242627919,
                40.59042154570932
              ],
              [
                -73.91514265685389,
                40.590418163978185
              ],
              [
                -73.9151567040808,
                40.59041868598575
              ],
              [
                -73.9151783846261,
                40.59042579786577
              ],
              [
                -73.91520164257466,
                40.590437781822295
              ],
              [
                -73.91525101186438,
                40.590465896502415
              ],
              [
                -73.91527428392196,
                40.59047422931951
              ],
              [
                -73.91529822663234,
                40.59047550511644
              ],
              [
                -73.91531739818042,
                40.59047190161762
              ],
              [
                -73.91533015912064,
                40.59047436767196
              ],
              [
                -73.91534736250536,
                40.5904834162111
              ],
              [
                -73.91536421759947,
                40.59049903499291
              ],
              [
                -73.91538043592861,
                40.59051440904557
              ],
              [
                -73.91539254361412,
                40.59052028081895
              ],
              [
                -73.91540945612026,
                40.59052251387243
              ],
              [
                -73.91542160053244,
                40.590519865796246
              ],
              [
                -73.91543403496343,
                40.59052403503736
              ],
              [
                -73.91544164940774,
                40.590535494121205
              ],
              [
                -73.91545150377355,
                40.590545983852465
              ],
              [
                -73.91546805766974,
                40.59055722116936
              ],
              [
                -73.91548049738573,
                40.59056017431362
              ],
              [
                -73.91548911690852,
                40.59056043697325
              ],
              [
                -73.91549995436303,
                40.59056460168198
              ],
              [
                -73.91550277477633,
                40.590577266630824
              ],
              [
                -73.91549954144826,
                40.59058699402363
              ],
              [
                -73.9155019977137,
                40.59058872289026
              ],
              [
                -73.9155071807511,
                40.590592368454786
              ],
              [
                -73.91552281863378,
                40.59059411086591
              ],
              [
                -73.91553016329338,
                40.59059388457218
              ],
              [
                -73.91554898746243,
                40.59059685295645
              ],
              [
                -73.91559140326441,
                40.59060888391547
              ],
              [
                -73.91563604009976,
                40.59062359963988
              ],
              [
                -73.91565291595063,
                40.59063434965596
              ],
              [
                -73.9156589448785,
                40.590643371349415
              ],
              [
                -73.9156604731002,
                40.59065919679233
              ],
              [
                -73.91567001088868,
                40.59066895550933
              ],
              [
                -73.91568148602673,
                40.59067336492675
              ],
              [
                -73.91568656639039,
                40.59068019279566
              ],
              [
                -73.91568430582039,
                40.59068602936783
              ],
              [
                -73.91568618670998,
                40.59069406528883
              ],
              [
                -73.91569924434596,
                40.5907021308537
              ],
              [
                -73.9157129766521,
                40.59070740821817
              ],
              [
                -73.91572984627537,
                40.590713890072635
              ],
              [
                -73.9157639779409,
                40.59072151875406
              ],
              [
                -73.91578440969224,
                40.59072205575175
              ],
              [
                -73.91581825525529,
                40.59072165351978
              ],
              [
                -73.91584504731276,
                40.590728290386984
              ],
              [
                -73.91587977537915,
                40.590745901707976
              ],
              [
                -73.91589154083256,
                40.590757127751594
              ],
              [
                -73.91590103695928,
                40.59077662272271
              ],
              [
                -73.91590163861409,
                40.590785386791595
              ],
              [
                -73.9159143732943,
                40.59079393742816
              ],
              [
                -73.91593573367871,
                40.59080153526841
              ],
              [
                -73.91595519207301,
                40.590805964235926
              ],
              [
                -73.91599381110436,
                40.590809223614706
              ],
              [
                -73.9160273196699,
                40.59081295804486
              ],
              [
                -73.91605602036711,
                40.59082154830961
              ],
              [
                -73.91607925140498,
                40.59083961723719
              ],
              [
                -73.91609259372333,
                40.59085571482531
              ],
              [
                -73.91609926411591,
                40.590863763618216
              ],
              [
                -73.9161122698496,
                40.59088399675563
              ],
              [
                -73.91613262466446,
                40.59090278947715
              ],
              [
                -73.91615011770604,
                40.5909186533689
              ],
              [
                -73.91617051377338,
                40.59092770964246
              ],
              [
                -73.91619091467014,
                40.59093554881504
              ],
              [
                -73.91620682762219,
                40.59094775890304
              ],
              [
                -73.91621251436143,
                40.590962133718165
              ],
              [
                -73.91622395296595,
                40.59097506242519
              ],
              [
                -73.91624466481959,
                40.5909848495112
              ],
              [
                -73.91625612032115,
                40.59099412591964
              ],
              [
                -73.91625681924144,
                40.59100282421826
              ],
              [
                -73.91625733271273,
                40.591009221030916
              ],
              [
                -73.91626238685089,
                40.59102213352131
              ],
              [
                -73.91627585918232,
                40.591031527830346
              ],
              [
                -73.916297637387,
                40.59103689659431
              ],
              [
                -73.91631783688462,
                40.59103660992124
              ],
              [
                -73.91633765358978,
                40.59103612260056
              ],
              [
                -73.91634498527033,
                40.59103594348856
              ],
              [
                -73.91637259668555,
                40.59104054685506
              ],
              [
                -73.91639753524665,
                40.59105121484327
              ],
              [
                -73.91641498243874,
                40.591063057621426
              ],
              [
                -73.9164292230805,
                40.591077724117596
              ],
              [
                -73.91643813849961,
                40.591088326665556
              ],
              [
                -73.91644709062327,
                40.59109892806687
              ],
              [
                -73.91646245643696,
                40.591105882492904
              ],
              [
                -73.91647682208814,
                40.59110636067772
              ],
              [
                -73.91649447616238,
                40.591097946949525
              ],
              [
                -73.91650784540843,
                40.591087883291635
              ],
              [
                -73.916515885389,
                40.59107979093159
              ],
              [
                -73.91653349833246,
                40.59107543992184
              ],
              [
                -73.91655148738137,
                40.591086487643445
              ],
              [
                -73.91655940040121,
                40.59109258743761
              ],
              [
                -73.9165646477849,
                40.59110074272387
              ],
              [
                -73.91656556230647,
                40.59111737687511
              ],
              [
                -73.91655843588346,
                40.59114410603505
              ],
              [
                -73.91655181759432,
                40.591167981030864
              ],
              [
                -73.91656176922584,
                40.591185087811155
              ],
              [
                -73.91657705029935,
                40.59120220013525
              ],
              [
                -73.9165870007566,
                40.591221311736284
              ],
              [
                -73.9165895000649,
                40.591237533072494
              ],
              [
                -73.91658296231468,
                40.59125534323073
              ],
              [
                -73.91658262287172,
                40.591295910049716
              ],
              [
                -73.9165856229251,
                40.59131537394508
              ],
              [
                -73.91660452811986,
                40.59134305580997
              ],
              [
                -73.91661805304851,
                40.59135899314296
              ],
              [
                -73.91663666876241,
                40.591380929373315
              ],
              [
                -73.91667518648008,
                40.591417242492774
              ],
              [
                -73.916695798276,
                40.5914323538422
              ],
              [
                -73.91670116708866,
                40.59143322453111
              ],
              [
                -73.91671383362063,
                40.59143527807313
              ],
              [
                -73.91672819934682,
                40.59143575455125
              ],
              [
                -73.91674198048536,
                40.59144312058357
              ],
              [
                -73.91675251814392,
                40.591453311992446
              ],
              [
                -73.91676254866707,
                40.59146432593706
              ],
              [
                -73.91677733382835,
                40.59147616673124
              ],
              [
                -73.91679898924541,
                40.59149168993495
              ],
              [
                -73.91682064118416,
                40.59151124857899
              ],
              [
                -73.9168285112746,
                40.591523468673046
              ],
              [
                -73.91683475739015,
                40.5915381025267
              ],
              [
                -73.91683837899292,
                40.59155070419254
              ],
              [
                -73.91685466142087,
                40.591572261589704
              ],
              [
                -73.9168714828245,
                40.59159708787204
              ],
              [
                -73.91688609829379,
                40.59162718202434
              ],
              [
                -73.91689171821308,
                40.59165479710351
              ],
              [
                -73.91689804588674,
                40.59166130801701
              ],
              [
                -73.91694002403251,
                40.591666389880295
              ],
              [
                -73.91696929521748,
                40.59166654656599
              ],
              [
                -73.91697883177392,
                40.591672262689016
              ],
              [
                -73.91697824907432,
                40.59167712112166
              ],
              [
                -73.91697500174618,
                40.59168197762907
              ],
              [
                -73.91695572071207,
                40.591696895419545
              ],
              [
                -73.91693431558186,
                40.59171098876717
              ],
              [
                -73.91691557738284,
                40.591723051237935
              ],
              [
                -73.91691067278236,
                40.5917316394341
              ],
              [
                -73.91690749444442,
                40.59173720865933
              ],
              [
                -73.9169084937787,
                40.591743687574194
              ],
              [
                -73.9169153646122,
                40.59174940060219
              ],
              [
                -73.91693014832455,
                40.59176124204537
              ],
              [
                -73.91694809719017,
                40.59177635142396
              ],
              [
                -73.9169696638963,
                40.59180606510393
              ],
              [
                -73.91699331669324,
                40.591836604363515
              ],
              [
                -73.91700972126809,
                40.591848035682645
              ],
              [
                -73.91703487550618,
                40.59188831952459
              ],
              [
                -73.91704490749379,
                40.591899305970315
              ],
              [
                -73.91707030734244,
                40.591915271589784
              ],
              [
                -73.91707540693278,
                40.59191724826304
              ],
              [
                -73.91710528993583,
                40.59192882817625
              ],
              [
                -73.91716471428259,
                40.59194575262577
              ],
              [
                -73.91723104894635,
                40.59196638643098
              ],
              [
                -73.91725387046415,
                40.59197216637892
              ],
              [
                -73.91726390596145,
                40.59197911803125
              ],
              [
                -73.91726698858045,
                40.59199048309584
              ],
              [
                -73.91726735796777,
                40.59201197570097
              ],
              [
                -73.91727190030072,
                40.59203714824435
              ],
              [
                -73.91727322348392,
                40.59204142119511
              ],
              [
                -73.91727656176853,
                40.59205219372456
              ],
              [
                -73.91728393191366,
                40.59206114593454
              ],
              [
                -73.91729184574098,
                40.59206727365566
              ],
              [
                -73.91731149774752,
                40.59207384791265
              ],
              [
                -73.91734615416519,
                40.59208630452813
              ],
              [
                -73.9173470150399,
                40.59208661423905
              ],
              [
                -73.91734813434157,
                40.59208746977875
              ],
              [
                -73.91735484702652,
                40.59209601642529
              ],
              [
                -73.91735678099728,
                40.592099712340655
              ],
              [
                -73.9173593183346,
                40.59210456095687
              ],
              [
                -73.91736754058746,
                40.59212216482173
              ],
              [
                -73.91737049255926,
                40.59212848548097
              ],
              [
                -73.9173783961284,
                40.59214931104008
              ],
              [
                -73.91739059704649,
                40.59218145813762
              ],
              [
                -73.91743081571296,
                40.59227715433683
              ],
              [
                -73.91743416901262,
                40.59228398909526
              ],
              [
                -73.91743720400157,
                40.592287698365034
              ],
              [
                -73.91743976237933,
                40.59229082596563
              ],
              [
                -73.91745319419307,
                40.59230364892901
              ],
              [
                -73.91746886335424,
                40.59231476670886
              ],
              [
                -73.9174712855077,
                40.59231627152164
              ],
              [
                -73.91751141325034,
                40.592341273094874
              ],
              [
                -73.91752260805403,
                40.592348968213955
              ],
              [
                -73.91752270311702,
                40.592349045680784
              ],
              [
                -73.91753156213116,
                40.592356662394074
              ],
              [
                -73.91757632591728,
                40.59240281969285
              ],
              [
                -73.91762199009659,
                40.59245471767685
              ],
              [
                -73.91762220143637,
                40.59245495655774
              ],
              [
                -73.91762241276545,
                40.59245520431751
              ],
              [
                -73.91767143080133,
                40.592513929328334
              ],
              [
                -73.91773967608385,
                40.59259939470761
              ],
              [
                -73.9177487889554,
                40.59261185385346
              ],
              [
                -73.91776092863202,
                40.59262845163157
              ],
              [
                -73.91777407793404,
                40.59264753844387
              ],
              [
                -73.9177832999983,
                40.5926609259451
              ],
              [
                -73.91785486631765,
                40.59277885210294
              ],
              [
                -73.91786089090307,
                40.592788229842974
              ],
              [
                -73.91788506186869,
                40.592825851420706
              ],
              [
                -73.91790088378806,
                40.59284586958066
              ],
              [
                -73.91792086252269,
                40.59287114811293
              ],
              [
                -73.91793876571165,
                40.59289166129557
              ],
              [
                -73.91794892365819,
                40.592902328775125
              ],
              [
                -73.91795667035201,
                40.59291046550964
              ],
              [
                -73.91796562587395,
                40.59291815898639
              ],
              [
                -73.91797682082668,
                40.59292585406084
              ],
              [
                -73.91800056987351,
                40.59293955932895
              ],
              [
                -73.91802720748579,
                40.59295493222924
              ],
              [
                -73.9180372829567,
                40.59296262649914
              ],
              [
                -73.91804476691296,
                40.592969976322536
              ],
              [
                -73.91804511790262,
                40.592970317495045
              ],
              [
                -73.91808986807042,
                40.59302587230569
              ],
              [
                -73.91809745143634,
                40.59303370920417
              ],
              [
                -73.91810889365574,
                40.593045531526876
              ],
              [
                -73.91813127757429,
                40.59306604787208
              ],
              [
                -73.9181559042382,
                40.59308656530774
              ],
              [
                -73.91817732323075,
                40.59310363504601
              ],
              [
                -73.9181816512938,
                40.59310708336827
              ],
              [
                -73.91820047103653,
                40.59312025971294
              ],
              [
                -73.91820851885727,
                40.59312589392409
              ],
              [
                -73.91821261029659,
                40.59312857244701
              ],
              [
                -73.91823203190397,
                40.59314128599627
              ],
              [
                -73.91825802733138,
                40.59315535014913
              ],
              [
                -73.91825864842129,
                40.59315568883272
              ],
              [
                -73.91825890692178,
                40.59315582505032
              ],
              [
                -73.91828802138625,
                40.59317036736588
              ],
              [
                -73.9183104343813,
                40.593180309078356
              ],
              [
                -73.91832273846754,
                40.593185767385805
              ],
              [
                -73.91833601898574,
                40.59319068727657
              ],
              [
                -73.91835969863051,
                40.59319946002229
              ],
              [
                -73.91838825446025,
                40.593207368927125
              ],
              [
                -73.9184369876614,
                40.59322086886431
              ],
              [
                -73.9184541925095,
                40.59322676319679
              ],
              [
                -73.91846946904094,
                40.59323199575792
              ],
              [
                -73.9185400270365,
                40.5932593790435
              ],
              [
                -73.91856448495857,
                40.59327011322934
              ],
              [
                -73.91859266312099,
                40.59328247926826
              ],
              [
                -73.91863745400322,
                40.593306426973605
              ],
              [
                -73.91864938775757,
                40.59331446945863
              ],
              [
                -73.91865648749493,
                40.59331925258817
              ],
              [
                -73.91867439973274,
                40.59333207857866
              ],
              [
                -73.91869566731967,
                40.593350031494765
              ],
              [
                -73.9187111966399,
                40.59336518713156
              ],
              [
                -73.91871581289689,
                40.59336969190977
              ],
              [
                -73.91873259542515,
                40.5933902024993
              ],
              [
                -73.9187471353387,
                40.593412421472536
              ],
              [
                -73.91875127627858,
                40.59342050785744
              ],
              [
                -73.91877731813608,
                40.59347138029361
              ],
              [
                -73.91878459182179,
                40.59348295969993
              ],
              [
                -73.91879073786008,
                40.59349274323215
              ],
              [
                -73.91881163274061,
                40.59352412115971
              ],
              [
                -73.91882317352201,
                40.59354145335175
              ],
              [
                -73.91883493800617,
                40.59355663931504
              ],
              [
                -73.91885561687559,
                40.59358333011417
              ],
              [
                -73.9188688709467,
                40.59359871916609
              ],
              [
                -73.9188835901415,
                40.5936158083331
              ],
              [
                -73.91893059566054,
                40.593662821414355
              ],
              [
                -73.91896976736547,
                40.59370128613031
              ],
              [
                -73.91900670477759,
                40.593734623698595
              ],
              [
                -73.91904140279736,
                40.59376539915937
              ],
              [
                -73.91905683743724,
                40.59377794608303
              ],
              [
                -73.9190761054956,
                40.59379360990664
              ],
              [
                -73.91908101874826,
                40.59379736334264
              ],
              [
                -73.9190861794543,
                40.59380130525551
              ],
              [
                -73.91909107643377,
                40.593803798189064
              ],
              [
                -73.91909961781475,
                40.59380814756754
              ],
              [
                -73.91913418741461,
                40.59382435137779
              ],
              [
                -73.91914889160033,
                40.5938312451687
              ],
              [
                -73.91916792844246,
                40.59384150766923
              ],
              [
                -73.91926645872195,
                40.5939039305056
              ],
              [
                -73.9193123626819,
                40.593935566982964
              ],
              [
                -73.91935714497409,
                40.59396720365993
              ],
              [
                -73.91936038119364,
                40.59396967464281
              ],
              [
                -73.91936050349337,
                40.593969768042456
              ],
              [
                -73.91936148012404,
                40.59397088581317
              ],
              [
                -73.91936274228013,
                40.59397233214171
              ],
              [
                -73.91936497849723,
                40.59397575047111
              ],
              [
                -73.91936557868348,
                40.5939766671101
              ],
              [
                -73.91936721603241,
                40.59397916930396
              ],
              [
                -73.91937138796564,
                40.59399407647595
              ],
              [
                -73.91937391060262,
                40.594003089982266
              ],
              [
                -73.91937726205724,
                40.59401077941585
              ],
              [
                -73.91937767575332,
                40.59401125347896
              ],
              [
                -73.91937950018671,
                40.5940133435143
              ],
              [
                -73.9193817387566,
                40.5940159071105
              ],
              [
                -73.91938397688439,
                40.59401847288416
              ],
              [
                -73.91938524979608,
                40.594019931282176
              ],
              [
                -73.91938621479679,
                40.594021035307115
              ],
              [
                -73.91942232132243,
                40.5940451644121
              ],
              [
                -73.91949369648137,
                40.594092861447805
              ],
              [
                -73.91958214734348,
                40.59415185968701
              ],
              [
                -73.91968739555371,
                40.59421941309036
              ],
              [
                -73.91969653244487,
                40.594224994683294
              ],
              [
                -73.91970419135835,
                40.59422967393518
              ],
              [
                -73.91974562641109,
                40.59425020377596
              ],
              [
                -73.91976147753289,
                40.594259284211354
              ],
              [
                -73.91976354218846,
                40.59426046589697
              ],
              [
                -73.9197637997452,
                40.594260662086015
              ],
              [
                -73.9197720703081,
                40.594266974836
              ],
              [
                -73.91977249912514,
                40.59426730567077
              ],
              [
                -73.91977651555335,
                40.59427124943402
              ],
              [
                -73.91978030061485,
                40.59427496687101
              ],
              [
                -73.91978033156241,
                40.59427500039857
              ],
              [
                -73.91980271208584,
                40.59430149285308
              ],
              [
                -73.91981166463727,
                40.59431089414367
              ],
              [
                -73.91986434478065,
                40.59436023560278
              ],
              [
                -73.919870986523,
                40.59436645724651
              ],
              [
                -73.91988592795829,
                40.594379828795795
              ],
              [
                -73.91988613097047,
                40.59437987668323
              ],
              [
                -73.91989341612258,
                40.59438159123086
              ],
              [
                -73.91990113765013,
                40.594383932976825
              ],
              [
                -73.91990844633234,
                40.59438691891992
              ],
              [
                -73.91992254950124,
                40.5943931990351
              ],
              [
                -73.91993627761585,
                40.59439995031461
              ],
              [
                -73.91994960036463,
                40.59440715866515
              ],
              [
                -73.91996249051269,
                40.5944148098278
              ],
              [
                -73.91997492719942,
                40.59442288971577
              ],
              [
                -73.9199868814336,
                40.59443138222618
              ],
              [
                -73.91999833213882,
                40.59444026908375
              ],
              [
                -73.92000925647662,
                40.5944495360329
              ],
              [
                -73.92001963315487,
                40.594459161112475
              ],
              [
                -73.92002944043563,
                40.59446912772211
              ],
              [
                -73.92003601258826,
                40.594476957589066
              ],
              [
                -73.92004320576882,
                40.594484463048396
              ],
              [
                -73.9200509956168,
                40.594491615435544
              ],
              [
                -73.92005935007998,
                40.59449838608078
              ],
              [
                -73.92006823842108,
                40.59450474916266
              ],
              [
                -73.9200776279219,
                40.59451068120464
              ],
              [
                -73.92008747949008,
                40.59451615956271
              ],
              [
                -73.92009775711063,
                40.59452116109281
              ],
              [
                -73.92011212547445,
                40.59452496712885
              ],
              [
                -73.92012618982102,
                40.59452938560641
              ],
              [
                -73.92013990335815,
                40.5945344005778
              ],
              [
                -73.9201532223689,
                40.59453999710266
              ],
              [
                -73.9201661064344,
                40.59454615940524
              ],
              [
                -73.92018008139189,
                40.59455715434407
              ],
              [
                -73.92019345696568,
                40.59456857371928
              ],
              [
                -73.92020621097927,
                40.594580400092504
              ],
              [
                -73.92021832170109,
                40.594592611837655
              ],
              [
                -73.92022976717702,
                40.59460518933874
              ],
              [
                -73.92024126006544,
                40.59461726729928
              ],
              [
                -73.92025195380327,
                40.59462976352719
              ],
              [
                -73.92026182183206,
                40.594642649021814
              ],
              [
                -73.92027084089638,
                40.59465588959138
              ],
              [
                -73.92027898708089,
                40.594669451378344
              ],
              [
                -73.92028283542274,
                40.59467727280757
              ],
              [
                -73.92028739654017,
                40.594684868566645
              ],
              [
                -73.92029264564238,
                40.594692201949314
              ],
              [
                -73.92029855552282,
                40.59469923490743
              ],
              [
                -73.92030509875002,
                40.59470593458601
              ],
              [
                -73.92031224305792,
                40.594712266284226
              ],
              [
                -73.92032141052387,
                40.59471884699308
              ],
              [
                -73.92033115284846,
                40.59472493405577
              ],
              [
                -73.92034142062134,
                40.59473049510492
              ],
              [
                -73.92035216727967,
                40.59473550564875
              ],
              [
                -73.92036333944645,
                40.59473994186103
              ],
              [
                -73.9203717496947,
                40.594742741910224
              ],
              [
                -73.92038042340398,
                40.59474502950155
              ],
              [
                -73.9203893065268,
                40.59474679002274
              ],
              [
                -73.9203983436945,
                40.59474801170814
              ],
              [
                -73.92040747865146,
                40.59474868882287
              ],
              [
                -73.92041665557934,
                40.59474881764266
              ],
              [
                -73.92043515582486,
                40.59474924274287
              ],
              [
                -73.92045361560604,
                40.59475024361038
              ],
              [
                -73.92047200877086,
                40.594751819221884
              ],
              [
                -73.92049030170058,
                40.594753964695656
              ],
              [
                -73.92050846362989,
                40.594756678000174
              ],
              [
                -73.92052646577419,
                40.59475995459244
              ],
              [
                -73.92055936050805,
                40.59476815695497
              ],
              [
                -73.92059194829402,
                40.59477704177774
              ],
              [
                -73.92062420430545,
                40.594786602845495
              ],
              [
                -73.92065610723425,
                40.59479683277226
              ],
              [
                -73.92068762918156,
                40.5948077218226
              ],
              [
                -73.92071874971512,
                40.59481926478916
              ],
              [
                -73.92074398898464,
                40.59483332349922
              ],
              [
                -73.92075810533983,
                40.594840675540496
              ],
              [
                -73.92076979996794,
                40.594846766592624
              ],
              [
                -73.92079615542374,
                40.59485958433362
              ],
              [
                -73.92080585767157,
                40.594863567160516
              ],
              [
                -73.92081593070311,
                40.594866973773875
              ],
              [
                -73.92082631563939,
                40.59486978670982
              ],
              [
                -73.9208369518422,
                40.594871989341264
              ],
              [
                -73.9208477744972,
                40.59487356570814
              ],
              [
                -73.92085872405313,
                40.59487450923572
              ],
              [
                -73.92086973568264,
                40.59487481435083
              ],
              [
                -73.92088531154974,
                40.59487514237571
              ],
              [
                -73.92090089039222,
                40.594874813853366
              ],
              [
                -73.92091642451642,
                40.5948738309287
              ],
              [
                -73.92093186359128,
                40.594872195745
              ],
              [
                -73.92094715815956,
                40.59486991446692
              ],
              [
                -73.92096226118213,
                40.5948669932607
              ],
              [
                -73.92097712429542,
                40.59486344298249
              ],
              [
                -73.92098829656439,
                40.594860602666905
              ],
              [
                -73.9209996883861,
                40.59485831970343
              ],
              [
                -73.92101124898306,
                40.59485660276881
              ],
              [
                -73.92102293043708,
                40.59485545853127
              ],
              [
                -73.92103468548993,
                40.59485489298929
              ],
              [
                -73.92104646227406,
                40.594854907112335
              ],
              [
                -73.92105821375999,
                40.594855499360364
              ],
              [
                -73.92106988895912,
                40.5948566710386
              ],
              [
                -73.92111013964636,
                40.594861146413116
              ],
              [
                -73.92115025195898,
                40.594866300674816
              ],
              [
                -73.92119020436793,
                40.59487212727535
              ],
              [
                -73.9212299773131,
                40.59487862737438
              ],
              [
                -73.92126955278182,
                40.594885794760934
              ],
              [
                -73.92130891011638,
                40.594893629086094
              ],
              [
                -73.92134803130321,
                40.59490212464171
              ],
              [
                -73.92137237470664,
                40.59490760727872
              ],
              [
                -73.92139648558721,
                40.59491365516282
              ],
              [
                -73.92142034439162,
                40.59492026291998
              ],
              [
                -73.92144392629557,
                40.59492742265944
              ],
              [
                -73.92146720976831,
                40.594935128335415
              ],
              [
                -73.9214901717425,
                40.594943373063884
              ],
              [
                -73.9215127928866,
                40.59495214979558
              ],
              [
                -73.92153504859822,
                40.594961448461916
              ],
              [
                -73.92155692042512,
                40.59497126184707
              ],
              [
                -73.92157838684278,
                40.594981578879306
              ],
              [
                -73.92158605585645,
                40.594982896537104
              ],
              [
                -73.92159352026091,
                40.59498477527775
              ],
              [
                -73.92160070953007,
                40.59498719729538
              ],
              [
                -73.92160755841799,
                40.59499013942618
              ],
              [
                -73.92161400124391,
                40.59499357482093
              ],
              [
                -73.92161997607357,
                40.59499746775368
              ],
              [
                -73.9216254262431,
                40.59500178568509
              ],
              [
                -73.92162741235373,
                40.595009828780334
              ],
              [
                -73.92163061144817,
                40.59501764318769
              ],
              [
                -73.92163497617989,
                40.59502512299625
              ],
              [
                -73.92164141577734,
                40.595028879038274
              ],
              [
                -73.9216482785463,
                40.595032170809766
              ],
              [
                -73.92165551001412,
                40.5950349716363
              ],
              [
                -73.92166304515766,
                40.59503725600919
              ],
              [
                -73.92167082443592,
                40.595039008139906
              ],
              [
                -73.92167671823155,
                40.59503948107164
              ],
              [
                -73.92168264322856,
                40.595039397826966
              ],
              [
                -73.92168851107718,
                40.59503875834593
              ],
              [
                -73.92169423033926,
                40.595037572617876
              ],
              [
                -73.92169971505945,
                40.59503586085532
              ],
              [
                -73.92170488103618,
                40.595033646622426
              ],
              [
                -73.92170964954919,
                40.59503096487946
              ],
              [
                -73.92171575006248,
                40.59502851523408
              ],
              [
                -73.92172216029513,
                40.59502657843905
              ],
              [
                -73.92172880461726,
                40.595025178734375
              ],
              [
                -73.921735611145,
                40.595024330813644
              ],
              [
                -73.92174249613382,
                40.59502404400164
              ],
              [
                -73.92174938265764,
                40.59502432260152
              ],
              [
                -73.92175619006346,
                40.595025163375475
              ],
              [
                -73.92176284122208,
                40.59502655621906
              ],
              [
                -73.92176925703451,
                40.59502848466075
              ],
              [
                -73.92177536324344,
                40.59503092620117
              ],
              [
                -73.92178113524761,
                40.59503574559186
              ],
              [
                -73.92178615002189,
                40.595041032208634
              ],
              [
                -73.92179034544655,
                40.59504672067289
              ],
              [
                -73.92179366710775,
                40.595052734051755
              ],
              [
                -73.92181306389156,
                40.59509315012058
              ],
              [
                -73.92181507323924,
                40.59509920046746
              ],
              [
                -73.92181785926209,
                40.59510507074624
              ],
              [
                -73.92182139587858,
                40.59511069995835
              ],
              [
                -73.92182564491118,
                40.595116033798305
              ],
              [
                -73.92183056795825,
                40.59512102231607
              ],
              [
                -73.9218351352871,
                40.59512330030293
              ],
              [
                -73.92184007726809,
                40.595125069758076
              ],
              [
                -73.92184529548462,
                40.595126294093
              ],
              [
                -73.92185068798891,
                40.595126949951975
              ],
              [
                -73.92185614886566,
                40.59512702335771
              ],
              [
                -73.92186156866788,
                40.59512651323074
              ],
              [
                -73.92186672506277,
                40.59512647887521
              ],
              [
                -73.921869209959,
                40.59512618571319
              ],
              [
                -73.92187211183328,
                40.59512583687988
              ],
              [
                -73.92187439945658,
                40.595125471210935
              ],
              [
                -73.92187863799909,
                40.59512456005261
              ],
              [
                -73.92188195708184,
                40.595124521766934
              ],
              [
                -73.92188482570957,
                40.59512553927943
              ],
              [
                -73.9218873709001,
                40.5951272477979
              ],
              [
                -73.92188936906379,
                40.59512933841378
              ],
              [
                -73.92189104834448,
                40.59513142060401
              ],
              [
                -73.92189199328271,
                40.59513375492912
              ],
              [
                -73.92189248507893,
                40.595135684865674
              ],
              [
                -73.92189304385532,
                40.59513822197387
              ],
              [
                -73.92189371865047,
                40.595140026890405
              ],
              [
                -73.92189474842517,
                40.59514254622556
              ],
              [
                -73.92189748415402,
                40.59514764298553
              ],
              [
                -73.92189984052709,
                40.59515182159453
              ],
              [
                -73.92190432655661,
                40.59515671917879
              ],
              [
                -73.92190952281642,
                40.59515999623224
              ],
              [
                -73.92191664456314,
                40.595163615850865
              ],
              [
                -73.92193661822674,
                40.59517538595588
              ],
              [
                -73.92194049917714,
                40.59518032048668
              ],
              [
                -73.92196095458273,
                40.59521034322984
              ],
              [
                -73.92197863347785,
                40.595242717032924
              ],
              [
                -73.92199273933893,
                40.59525980638696
              ],
              [
                -73.92200756890878,
                40.59527653503715
              ],
              [
                -73.92202310814187,
                40.5952928858858
              ],
              [
                -73.92203933617749,
                40.59530884417599
              ],
              [
                -73.92205623831359,
                40.59532439012892
              ],
              [
                -73.92207379764584,
                40.59533950832016
              ],
              [
                -73.92209223262552,
                40.595358347425034
              ],
              [
                -73.92211008461364,
                40.59537750996585
              ],
              [
                -73.92212734219116,
                40.59539698722358
              ],
              [
                -73.92214399438339,
                40.595416767129116
              ],
              [
                -73.92214854800336,
                40.59542234709617
              ],
              [
                -73.9221538077597,
                40.595427553113566
              ],
              [
                -73.92215972118291,
                40.59543233689709
              ],
              [
                -73.92216622766996,
                40.595436650659764
              ],
              [
                -73.92217326441789,
                40.59544044929366
              ],
              [
                -73.9221807631201,
                40.595443695393314
              ],
              [
                -73.92218864667122,
                40.595446357746
              ],
              [
                -73.92219462792107,
                40.59544914227926
              ],
              [
                -73.9222001549719,
                40.59545242657963
              ],
              [
                -73.9222051577627,
                40.59545617005755
              ],
              [
                -73.92220956821986,
                40.59546032374859
              ],
              [
                -73.92221332882592,
                40.59546483266422
              ],
              [
                -73.92221639107898,
                40.59546963813652
              ],
              [
                -73.92221871637015,
                40.59547467865593
              ],
              [
                -73.9222208713644,
                40.59548497696644
              ],
              [
                -73.92222189417565,
                40.5954953765359
              ],
              [
                -73.92222177609516,
                40.59550580615867
              ],
              [
                -73.92222335755983,
                40.595512635722265
              ],
              [
                -73.92222576159047,
                40.59551932478299
              ],
              [
                -73.92222896451868,
                40.595525815694536
              ],
              [
                -73.92223294047409,
                40.5955320539925
              ],
              [
                -73.922237653476,
                40.595537986545466
              ],
              [
                -73.92225291508969,
                40.59555541807354
              ],
              [
                -73.9222691205748,
                40.59557234798532
              ],
              [
                -73.9222862427194,
                40.59558874175086
              ],
              [
                -73.92229707948252,
                40.59560023153538
              ],
              [
                -73.9223071320517,
                40.59561212989518
              ],
              [
                -73.92231637101301,
                40.595624405482546
              ],
              [
                -73.92232477376976,
                40.59563702343639
              ],
              [
                -73.92233231530048,
                40.59564995492493
              ],
              [
                -73.92233898026504,
                40.59566316190871
              ],
              [
                -73.92235294893801,
                40.595740737736236
              ],
              [
                -73.92235359972803,
                40.59574165606875
              ],
              [
                -73.92244647095809,
                40.59585788525397
              ],
              [
                -73.92245408604424,
                40.595870351220036
              ],
              [
                -73.92246212866127,
                40.595883521264376
              ],
              [
                -73.9224715207821,
                40.59590174322612
              ],
              [
                -73.92247666275819,
                40.59591171853983
              ],
              [
                -73.92248775867337,
                40.595941805737205
              ],
              [
                -73.9224889511689,
                40.59594503885128
              ],
              [
                -73.92251016113933,
                40.59601423972755
              ],
              [
                -73.92252021192988,
                40.59604328854055
              ],
              [
                -73.92253362477693,
                40.59607319284546
              ],
              [
                -73.92254139384103,
                40.59608633690025
              ],
              [
                -73.92254928091384,
                40.59609968307414
              ],
              [
                -73.92255213594396,
                40.59610375277645
              ],
              [
                -73.9225660626608,
                40.59612361086124
              ],
              [
                -73.92257973624291,
                40.596138694524875
              ],
              [
                -73.92258620658549,
                40.59614583211233
              ],
              [
                -73.92259679370878,
                40.596156471991236
              ],
              [
                -73.92260747216093,
                40.59616720038616
              ],
              [
                -73.92263098168353,
                40.59618686354616
              ],
              [
                -73.92264423377209,
                40.596195295671365
              ],
              [
                -73.92265785437878,
                40.59620396358915
              ],
              [
                -73.9226853149523,
                40.59621958189009
              ],
              [
                -73.92271048717738,
                40.5962338959377
              ],
              [
                -73.9227317627059,
                40.59624672187783
              ],
              [
                -73.9227507936471,
                40.596261255773726
              ],
              [
                -73.92276271417477,
                40.5962723071478
              ],
              [
                -73.92276646638513,
                40.596275786236866
              ],
              [
                -73.92278324901831,
                40.59629715263754
              ],
              [
                -73.92282911735096,
                40.596362099027324
              ],
              [
                -73.92287275532036,
                40.59641764902809
              ],
              [
                -73.92288730417671,
                40.59643388768615
              ],
              [
                -73.92290185533977,
                40.59644756381632
              ],
              [
                -73.92292364204584,
                40.59646621723373
              ],
              [
                -73.92293879754466,
                40.5964791933248
              ],
              [
                -73.92297792333675,
                40.59650640184731
              ],
              [
                -73.92300149949261,
                40.596522797484724
              ],
              [
                -73.92307478221241,
                40.59657397448222
              ],
              [
                -73.92313249869694,
                40.59661428093752
              ],
              [
                -73.92326350666963,
                40.59669978562923
              ],
              [
                -73.92329597364638,
                40.596725431071505
              ],
              [
                -73.92335306472157,
                40.596777573107154
              ],
              [
                -73.92336749391434,
                40.596789092827436
              ],
              [
                -73.92337769380057,
                40.59679723518436
              ],
              [
                -73.9233877705673,
                40.5968040759234
              ],
              [
                -73.92340008997358,
                40.59681091698384
              ],
              [
                -73.92341050780455,
                40.59681549245017
              ],
              [
                -73.92344488896383,
                40.59683059248918
              ],
              [
                -73.92346159030858,
                40.596838246800964
              ],
              [
                -73.92346168981793,
                40.59683829143005
              ],
              [
                -73.92347512574173,
                40.59684684152183
              ],
              [
                -73.92347892351393,
                40.59685032229311
              ],
              [
                -73.9234807233837,
                40.59685197147678
              ],
              [
                -73.92348519926999,
                40.596857099009256
              ],
              [
                -73.92349415008601,
                40.59686991710316
              ],
              [
                -73.92350197762798,
                40.59688444341451
              ],
              [
                -73.92351196722836,
                40.59691003379076
              ],
              [
                -73.92352432308421,
                40.596941686781676
              ],
              [
                -73.92353550130474,
                40.59696475713423
              ],
              [
                -73.92356905544636,
                40.59701603033871
              ],
              [
                -73.9236003920106,
                40.59705619553987
              ],
              [
                -73.92361261437688,
                40.59706972997629
              ],
              [
                -73.92362277341711,
                40.59708098112428
              ],
              [
                -73.92363815280564,
                40.597094256980604
              ],
              [
                -73.92364852239879,
                40.597103206414346
              ],
              [
                -73.92367539301226,
                40.59712287041878
              ],
              [
                -73.92369666779267,
                40.59713599153241
              ],
              [
                -73.92370450736209,
                40.59714082710826
              ],
              [
                -73.92371794598832,
                40.59714766937994
              ],
              [
                -73.9237336273626,
                40.5971536580692
              ],
              [
                -73.92380644037645,
                40.59717591555349
              ],
              [
                -73.92382324111027,
                40.59718190597782
              ],
              [
                -73.92383668205115,
                40.59718789300036
              ],
              [
                -73.9238803590299,
                40.59720927605455
              ],
              [
                -73.92390611558727,
                40.59722381521734
              ],
              [
                -73.92392851138528,
                40.597239204203476
              ],
              [
                -73.92394638258862,
                40.597254556685016
              ],
              [
                -73.92394641925141,
                40.597254592393
              ],
              [
                -73.92402924289875,
                40.59734518668049
              ],
              [
                -73.92408559602251,
                40.59740499617654
              ],
              [
                -73.92409527585878,
                40.597415271442955
              ],
              [
                -73.92410219522203,
                40.597422268683566
              ],
              [
                -73.92413669063639,
                40.597457153123095
              ],
              [
                -73.92415907942457,
                40.597477668286466
              ],
              [
                -73.92418594655079,
                40.597499894192936
              ],
              [
                -73.92430462445286,
                40.5975922224009
              ],
              [
                -73.92441433342832,
                40.59768796067522
              ],
              [
                -73.9244487071711,
                40.59771545706825
              ],
              [
                -73.92446135436103,
                40.59772557459886
              ],
              [
                -73.92447607835382,
                40.59773660451701
              ],
              [
                -73.9245206957021,
                40.597770030950876
              ],
              [
                -73.92460691614966,
                40.59782816909613
              ],
              [
                -73.92468978154238,
                40.59787947380582
              ],
              [
                -73.92470915116704,
                40.59789207246629
              ],
              [
                -73.924720014367,
                40.59789913926472
              ],
              [
                -73.92473193763267,
                40.59790889493054
              ],
              [
                -73.9247356885243,
                40.59791196166436
              ],
              [
                -73.92474009937212,
                40.597916052098775
              ],
              [
                -73.9247513614209,
                40.59792649302673
              ],
              [
                -73.9247622735302,
                40.59793931615762
              ],
              [
                -73.9247659085614,
                40.59794358617714
              ],
              [
                -73.92477918550237,
                40.59796133118798
              ],
              [
                -73.92477933494938,
                40.597961531650796
              ],
              [
                -73.92478465432619,
                40.59796997156951
              ],
              [
                -73.92479387712824,
                40.59798460408009
              ],
              [
                -73.92482295530831,
                40.59803587230784
              ],
              [
                -73.92483261008998,
                40.59805062370846
              ],
              [
                -73.92483637884429,
                40.598056381472716
              ],
              [
                -73.92483837071387,
                40.59805914684017
              ],
              [
                -73.92486099248995,
                40.59809056481995
              ],
              [
                -73.92490127818121,
                40.59814013205909
              ],
              [
                -73.92491081971119,
                40.59815125469593
              ],
              [
                -73.92492254260961,
                40.59816491665724
              ],
              [
                -73.92494604709893,
                40.598189702714464
              ],
              [
                -73.92500649201054,
                40.59824868012971
              ],
              [
                -73.92504791458926,
                40.598284581694294
              ],
              [
                -73.92509717876669,
                40.59832219614788
              ],
              [
                -73.92511070317777,
                40.598331863859535
              ],
              [
                -73.92514980439398,
                40.59835981327223
              ],
              [
                -73.92523363547856,
                40.598415064701264
              ],
              [
                -73.92525618334719,
                40.598429924300426
              ],
              [
                -73.92527969577682,
                40.5984470232153
              ],
              [
                -73.92527992898941,
                40.598447201785646
              ],
              [
                -73.92528528476437,
                40.598451292310614
              ],
              [
                -73.92531665072475,
                40.598469255413406
              ],
              [
                -73.92536255938539,
                40.598490455906635
              ],
              [
                -73.92541297158328,
                40.59851373482397
              ],
              [
                -73.92548129626527,
                40.59854281966876
              ],
              [
                -73.92552721669209,
                40.59856420405059
              ],
              [
                -73.92555233718647,
                40.59857815507232
              ],
              [
                -73.9255641743498,
                40.59858472888034
              ],
              [
                -73.92560112639357,
                40.598609523511556
              ],
              [
                -73.92562669063508,
                40.59862942443688
              ],
              [
                -73.92565822746133,
                40.59865397681586
              ],
              [
                -73.92573659804447,
                40.59871894304823
              ],
              [
                -73.92575251558674,
                40.5987296690046
              ],
              [
                -73.9257556331239,
                40.59873176747935
              ],
              [
                -73.92580155223895,
                40.598755714779784
              ],
              [
                -73.92580598355872,
                40.59875830328665
              ],
              [
                -73.92582058901954,
                40.59876683090566
              ],
              [
                -73.92583753317132,
                40.598779768730935
              ],
              [
                -73.92585977761183,
                40.59879675195068
              ],
              [
                -73.92589261456003,
                40.59882652682169
              ],
              [
                -73.92589560021958,
                40.598829232333486
              ],
              [
                -73.92589568516014,
                40.59882933776401
              ],
              [
                -73.92590119587815,
                40.59883607046336
              ],
              [
                -73.92590679030292,
                40.59884461588251
              ],
              [
                -73.92592691844457,
                40.598882211189355
              ],
              [
                -73.92593156242029,
                40.59888727725046
              ],
              [
                -73.92593375346489,
                40.598889666624025
              ],
              [
                -73.92593475338458,
                40.598890758051176
              ],
              [
                -73.92593811050776,
                40.598895031707116
              ],
              [
                -73.92594258905342,
                40.59889845135167
              ],
              [
                -73.92595057866393,
                40.598903884613996
              ],
              [
                -73.92601425313464,
                40.59894718418077
              ],
              [
                -73.92602433232163,
                40.5989531704571
              ],
              [
                -73.92602488490398,
                40.59895353368099
              ],
              [
                -73.92604902566563,
                40.598965654878825
              ],
              [
                -73.92609843902208,
                40.59899058200881
              ],
              [
                -73.92612301467624,
                40.59900854822989
              ],
              [
                -73.92616352553107,
                40.59904148437343
              ],
              [
                -73.92619933031126,
                40.599064893612514
              ],
              [
                -73.92621944449444,
                40.59907813547178
              ],
              [
                -73.92622216715536,
                40.59909092841007
              ],
              [
                -73.92622687393289,
                40.599100456472485
              ],
              [
                -73.92623619163915,
                40.59910918988767
              ],
              [
                -73.92625127323285,
                40.59911518421302
              ],
              [
                -73.92626891935092,
                40.59911338286962
              ],
              [
                -73.92628368473243,
                40.599112953750236
              ],
              [
                -73.92629448276806,
                40.59911746168336
              ],
              [
                -73.92631564601325,
                40.59912713683159
              ],
              [
                -73.92633410188601,
                40.59914142215219
              ],
              [
                -73.92634446148519,
                40.599153671156806
              ],
              [
                -73.92634891129076,
                40.599167040661705
              ],
              [
                -73.92634920303678,
                40.59916955177651
              ],
              [
                -73.92635044516359,
                40.59918021664433
              ],
              [
                -73.92635478144055,
                40.59919907617597
              ],
              [
                -73.92636463944082,
                40.59921037848474
              ],
              [
                -73.9263695630676,
                40.599216020505054
              ],
              [
                -73.92638616901631,
                40.599243863943386
              ],
              [
                -73.92641473713508,
                40.599265566214655
              ],
              [
                -73.92643732732675,
                40.599286797413555
              ],
              [
                -73.92645110447421,
                40.59930022883921
              ],
              [
                -73.92646120556235,
                40.59931634725729
              ],
              [
                -73.926467243388,
                40.59932711018144
              ],
              [
                -73.9264754552105,
                40.59933881098442
              ],
              [
                -73.9264840697178,
                40.59935108415668
              ],
              [
                -73.9264951855822,
                40.5993620150088
              ],
              [
                -73.92653118070082,
                40.59937230187015
              ],
              [
                -73.92653503480115,
                40.59937340449818
              ],
              [
                -73.92659961215756,
                40.599397268917265
              ],
              [
                -73.92663891620732,
                40.59941477953946
              ],
              [
                -73.92665925151499,
                40.59942417918013
              ],
              [
                -73.92669369939772,
                40.599437651743486
              ],
              [
                -73.92672334147932,
                40.59946445990761
              ],
              [
                -73.92674017770368,
                40.59947973171431
              ],
              [
                -73.9267652631132,
                40.59949154862738
              ],
              [
                -73.9268059068983,
                40.599501621079256
              ],
              [
                -73.92682702785184,
                40.59951837790045
              ],
              [
                -73.9268471064393,
                40.59953164793892
              ],
              [
                -73.92687471342725,
                40.59954275510411
              ],
              [
                -73.92689425681216,
                40.59955344534665
              ],
              [
                -73.92690317700756,
                40.59956283818453
              ],
              [
                -73.92692599660758,
                40.59957149770611
              ],
              [
                -73.92693981836622,
                40.5995778469715
              ],
              [
                -73.92695110992662,
                40.599592017905714
              ],
              [
                -73.92696222590703,
                40.599602949215075
              ],
              [
                -73.92698531092736,
                40.59963381488915
              ],
              [
                -73.92700202272425,
                40.59966327730648
              ],
              [
                -73.92701978356502,
                40.59968914504341
              ],
              [
                -73.92705304954457,
                40.59972904749481
              ],
              [
                -73.92708901013796,
                40.599758127919365
              ],
              [
                -73.92710413921029,
                40.59977036228994
              ],
              [
                -73.92715594268309,
                40.59981744152432
              ],
              [
                -73.92716032319036,
                40.599829246397604
              ],
              [
                -73.9271722759155,
                40.59983177914042
              ],
              [
                -73.92720540235807,
                40.59983531405303
              ],
              [
                -73.92722768616635,
                40.59984142103517
              ],
              [
                -73.92723463181115,
                40.599845405424254
              ],
              [
                -73.92723610197662,
                40.59984985291512
              ],
              [
                -73.92722777404134,
                40.599860414545994
              ],
              [
                -73.92720914776773,
                40.59986740195218
              ],
              [
                -73.9271928227896,
                40.599879139930984
              ],
              [
                -73.92719261227055,
                40.59988011096436
              ],
              [
                -73.92719043353631,
                40.59989014508548
              ],
              [
                -73.92719082214731,
                40.59989818757071
              ],
              [
                -73.9272024040044,
                40.59991008043419
              ],
              [
                -73.92721130654229,
                40.599912568520686
              ],
              [
                -73.92722641495031,
                40.59991679295599
              ],
              [
                -73.92724506651244,
                40.59991847854939
              ],
              [
                -73.92725756679881,
                40.59991491876052
              ],
              [
                -73.92726483978696,
                40.59991662345015
              ],
              [
                -73.92726648709431,
                40.599924311067284
              ],
              [
                -73.92726658379864,
                40.59993460412226
              ],
              [
                -73.92728762410275,
                40.59995844226587
              ],
              [
                -73.92731870493382,
                40.59998810521245
              ],
              [
                -73.92736149014415,
                40.600015498994644
              ],
              [
                -73.9274210074881,
                40.60005657136589
              ],
              [
                -73.92746901219999,
                40.60008736974934
              ],
              [
                -73.9275020849737,
                40.60010665865258
              ],
              [
                -73.92753411732149,
                40.60012246245048
              ],
              [
                -73.92754632704413,
                40.60012115152656
              ],
              [
                -73.92755662150886,
                40.60012469991845
              ],
              [
                -73.92756399058695,
                40.600136726793124
              ],
              [
                -73.92757953964676,
                40.60014368034258
              ],
              [
                -73.92760041413544,
                40.600155605993095
              ],
              [
                -73.92762853105334,
                40.600160564808725
              ],
              [
                -73.9276600797816,
                40.600158032029405
              ],
              [
                -73.92767834403921,
                40.60015170304727
              ],
              [
                -73.92769451952992,
                40.60014545450232
              ],
              [
                -73.92771292360688,
                40.60014233763988
              ],
              [
                -73.9277274696526,
                40.600145750311476
              ],
              [
                -73.92775178900308,
                40.60016758627985
              ],
              [
                -73.92776246340723,
                40.60018625808715
              ],
              [
                -73.9277785060595,
                40.60020284618846
              ],
              [
                -73.927802190762,
                40.60021180964185
              ],
              [
                -73.92781734374648,
                40.600219422305095
              ],
              [
                -73.9278263345313,
                40.60023040714475
              ],
              [
                -73.92782833586782,
                40.600244516034614
              ],
              [
                -73.9278263353957,
                40.60026356368477
              ],
              [
                -73.92782473100979,
                40.600281980333726
              ],
              [
                -73.92782946477035,
                40.600300181834655
              ],
              [
                -73.92784799166246,
                40.60031605898453
              ],
              [
                -73.92786714717562,
                40.600318704903096
              ],
              [
                -73.92792338512083,
                40.600328648868754
              ],
              [
                -73.92799773750467,
                40.60033772647209
              ],
              [
                -73.92808461583712,
                40.600351916648
              ],
              [
                -73.92811939022465,
                40.60036313655675
              ],
              [
                -73.928146997769,
                40.60037424274529
              ],
              [
                -73.92816870920258,
                40.60037777024236
              ],
              [
                -73.9281960446756,
                40.6003753718888
              ],
              [
                -73.92823188564527,
                40.60036562280154
              ],
              [
                -73.92825300571437,
                40.600349222651644
              ],
              [
                -73.92827775792114,
                40.60033724234181
              ],
              [
                -73.92830671347868,
                40.60033380243893
              ],
              [
                -73.92833177428656,
                40.600336947682656
              ],
              [
                -73.9283768102623,
                40.60035009530454
              ],
              [
                -73.9284064979437,
                40.600369820931
              ],
              [
                -73.92842495526673,
                40.600384105914415
              ],
              [
                -73.92843329354068,
                40.60039802725411
              ],
              [
                -73.9284354807066,
                40.600408266157125
              ],
              [
                -73.92843056920009,
                40.600419983943
              ],
              [
                -73.9284193577783,
                40.60043191626296
              ],
              [
                -73.92840663787993,
                40.600439375283045
              ],
              [
                -73.92838857780212,
                40.60045758785564
              ],
              [
                -73.92838329688392,
                40.60047866526484
              ],
              [
                -73.92838295485663,
                40.60049672570302
              ],
              [
                -73.92838858614411,
                40.60051682115461
              ],
              [
                -73.92840113840293,
                40.6005306358932
              ],
              [
                -73.92843482019475,
                40.6005540973881
              ],
              [
                -73.9284747682273,
                40.600575778754894
              ],
              [
                -73.92850907632634,
                40.60058603814539
              ],
              [
                -73.9285546210608,
                40.600593065167494
              ],
              [
                -73.92856862903965,
                40.6005939522305
              ],
              [
                -73.92857518798455,
                40.6005987799747
              ],
              [
                -73.9285787056341,
                40.60060136998412
              ],
              [
                -73.9285923060007,
                40.60061161597628
              ],
              [
                -73.92860303341341,
                40.60061450581846
              ],
              [
                -73.92862261946878,
                40.60061811198817
              ],
              [
                -73.92862453839155,
                40.60060832528744
              ],
              [
                -73.92862464646834,
                40.60060776597455
              ],
              [
                -73.9286223818028,
                40.60060460798318
              ],
              [
                -73.92861018260626,
                40.60059724486953
              ],
              [
                -73.92861094896604,
                40.60058722576032
              ],
              [
                -73.92862225814345,
                40.600585614452854
              ],
              [
                -73.92864116613579,
                40.60058345786463
              ],
              [
                -73.92864686122837,
                40.60057909709787
              ],
              [
                -73.92865155628814,
                40.60056545794398
              ],
              [
                -73.92864212258888,
                40.60049839601815
              ],
              [
                -73.9286416693992,
                40.60044942963665
              ],
              [
                -73.92863995628161,
                40.600435704582125
              ],
              [
                -73.9286316082062,
                40.60042905753612
              ],
              [
                -73.9286258273593,
                40.600413929578814
              ],
              [
                -73.92862706586206,
                40.60039935547633
              ],
              [
                -73.92865250413834,
                40.60038559182869
              ],
              [
                -73.9286978030231,
                40.60038809133293
              ],
              [
                -73.92874943482549,
                40.60039652075547
              ],
              [
                -73.92880778459828,
                40.60041854344657
              ],
              [
                -73.92886892721346,
                40.60045654174437
              ],
              [
                -73.928887533228,
                40.60046670954803
              ],
              [
                -73.92891648851871,
                40.60046354356911
              ],
              [
                -73.92894839890866,
                40.600459555836416
              ],
              [
                -73.92898762768309,
                40.60048060583296
              ],
              [
                -73.92901401862485,
                40.600485947052206
              ],
              [
                -73.92904353846012,
                40.60049425395592
              ],
              [
                -73.92907270660845,
                40.60049493018326
              ],
              [
                -73.92910537836164,
                40.60048556368234
              ],
              [
                -73.9291482359441,
                40.600479715701496
              ],
              [
                -73.92919242166283,
                40.60047993559243
              ],
              [
                -73.92922698259743,
                40.60048810851607
              ],
              [
                -73.92925599005152,
                40.60050561122919
              ],
              [
                -73.9292974468055,
                40.600531190805064
              ],
              [
                -73.929330732723,
                40.60055393761681
              ],
              [
                -73.92935568467544,
                40.600590706347006
              ],
              [
                -73.92936224319976,
                40.600621122788205
              ],
              [
                -73.92936653782064,
                40.600647008853805
              ],
              [
                -73.92935953042145,
                40.600667124466206
              ],
              [
                -73.92934730529184,
                40.600683557762316
              ],
              [
                -73.92932802274736,
                40.600699437724295
              ],
              [
                -73.92931151719048,
                40.60071062689882
              ],
              [
                -73.92930300852304,
                40.60072083340618
              ],
              [
                -73.92929361057489,
                40.60075324267777
              ],
              [
                -73.92927955903113,
                40.60079344522294
              ],
              [
                -73.92927494280951,
                40.60080123701229
              ],
              [
                -73.92932650927015,
                40.60076835930303
              ],
              [
                -73.9293783012559,
                40.60075993761868
              ],
              [
                -73.92941042444443,
                40.60075976352278
              ],
              [
                -73.92939430707476,
                40.600778611543895
              ],
              [
                -73.9293528014468,
                40.600831861990756
              ],
              [
                -73.92933595940595,
                40.600856088752415
              ],
              [
                -73.92932027997658,
                40.60090323472064
              ],
              [
                -73.92933017967741,
                40.600940432285654
              ],
              [
                -73.92934268039237,
                40.600969151121994
              ],
              [
                -73.9293596248877,
                40.60098626107385
              ],
              [
                -73.9293789210336,
                40.60099104885453
              ],
              [
                -73.92944300324639,
                40.601007529025466
              ],
              [
                -73.9294981881416,
                40.601026585494935
              ],
              [
                -73.92953297631976,
                40.60105924184885
              ],
              [
                -73.9295396584037,
                40.60109078649572
              ],
              [
                -73.92956636704699,
                40.60111943159284
              ],
              [
                -73.92958667176651,
                40.60114121143544
              ],
              [
                -73.92960072089012,
                40.601156354292605
              ],
              [
                -73.92962473188327,
                40.601182234329734
              ],
              [
                -73.92963530032544,
                40.601192609013744
              ],
              [
                -73.9296404041741,
                40.60119761807765
              ],
              [
                -73.92964936173404,
                40.60120445757878
              ],
              [
                -73.9296536647654,
                40.60120664780872
              ],
              [
                -73.92965944160925,
                40.60120958829845
              ],
              [
                -73.92966621007596,
                40.60121303332258
              ],
              [
                -73.92966952368374,
                40.60121471952115
              ],
              [
                -73.92967475251648,
                40.60121605172981
              ],
              [
                -73.92967960538135,
                40.601217288216624
              ],
              [
                -73.92969849898986,
                40.601221414794615
              ],
              [
                -73.92970313339589,
                40.60122242716018
              ],
              [
                -73.9297311454627,
                40.60122671628487
              ],
              [
                -73.92976745752982,
                40.60123027870774
              ],
              [
                -73.92982751092616,
                40.601236171206125
              ],
              [
                -73.93001555567409,
                40.60125803015145
              ],
              [
                -73.93002696120159,
                40.601259354545704
              ],
              [
                -73.93007178366099,
                40.60126280243787
              ],
              [
                -73.93013341782661,
                40.60126454386272
              ],
              [
                -73.93013683387696,
                40.60126454593938
              ],
              [
                -73.93014462384336,
                40.60126455067462
              ],
              [
                -73.93015219266586,
                40.60126311368638
              ],
              [
                -73.9301580737881,
                40.6012619963229
              ],
              [
                -73.9301855361498,
                40.601255607196066
              ],
              [
                -73.93022055592758,
                40.601213260337396
              ],
              [
                -73.93023945031183,
                40.601189748869615
              ],
              [
                -73.93025369099225,
                40.60117400290233
              ],
              [
                -73.93026651635373,
                40.60116904210492
              ],
              [
                -73.93028452329249,
                40.60116773491007
              ],
              [
                -73.930306809869,
                40.601171674640895
              ],
              [
                -73.93032538113468,
                40.60118184218983
              ],
              [
                -73.93033638091511,
                40.6012006501541
              ],
              [
                -73.9303275332228,
                40.601223892645976
              ],
              [
                -73.93032404489968,
                40.60125460482645
              ],
              [
                -73.9303188583356,
                40.601288573581805
              ],
              [
                -73.93033845548122,
                40.60130310538271
              ],
              [
                -73.93040899205376,
                40.60135376604912
              ],
              [
                -73.93050866654544,
                40.6014253536094
              ],
              [
                -73.9305258229981,
                40.601437087002914
              ],
              [
                -73.93056241907169,
                40.60146211586327
              ],
              [
                -73.93057105199372,
                40.601467388866006
              ],
              [
                -73.93057921705011,
                40.601472375613234
              ],
              [
                -73.93062178134748,
                40.60149204695867
              ],
              [
                -73.93063046460767,
                40.60149668873738
              ],
              [
                -73.93063298119036,
                40.601498033002564
              ],
              [
                -73.93063996976649,
                40.60150336816609
              ],
              [
                -73.93064082322839,
                40.60150400763745
              ],
              [
                -73.93065201488477,
                40.60151512810838
              ],
              [
                -73.93066320829776,
                40.60152794698997
              ],
              [
                -73.93068222893048,
                40.60155742717873
              ],
              [
                -73.93068245865955,
                40.60155759802956
              ],
              [
                -73.93073018821799,
                40.60159276186143
              ],
              [
                -73.93078208265746,
                40.60162567642217
              ],
              [
                -73.93082481679065,
                40.60163665386861
              ],
              [
                -73.93085356035948,
                40.60162969994252
              ],
              [
                -73.93087733573208,
                40.601622880227566
              ],
              [
                -73.93091035991495,
                40.60162114287192
              ],
              [
                -73.93096575788032,
                40.6016439854247
              ],
              [
                -73.93102539821933,
                40.60167209995919
              ],
              [
                -73.93108919528449,
                40.60172225826412
              ],
              [
                -73.93114405923458,
                40.60177499180074
              ],
              [
                -73.93119934599105,
                40.601835355009364
              ],
              [
                -73.93124308428159,
                40.60188610616583
              ],
              [
                -73.93128455811797,
                40.60193232479023
              ],
              [
                -73.93134168560594,
                40.60198958739512
              ],
              [
                -73.93137235644889,
                40.602000174290794
              ],
              [
                -73.93139614519956,
                40.60201399540885
              ],
              [
                -73.93144561470642,
                40.60205923116278
              ],
              [
                -73.93148823885093,
                40.60210772913052
              ],
              [
                -73.93150390891184,
                40.60213941404541
              ],
              [
                -73.93152590725897,
                40.60217703141789
              ],
              [
                -73.93157427847974,
                40.602240656638706
              ],
              [
                -73.93163945294982,
                40.6023175777339
              ],
              [
                -73.93168375514567,
                40.60237980061377
              ],
              [
                -73.93170559532443,
                40.602434270451404
              ],
              [
                -73.93172110351152,
                40.60248278019161
              ],
              [
                -73.93174384565035,
                40.60253568600244
              ],
              [
                -73.9317550722581,
                40.60257897750979
              ],
              [
                -73.93176200342052,
                40.602596384217804
              ],
              [
                -73.93178896067144,
                40.602613198367706
              ],
              [
                -73.93183431132815,
                40.60263633592031
              ],
              [
                -73.93187888608789,
                40.602644213602936
              ],
              [
                -73.93192029481557,
                40.602649123289446
              ],
              [
                -73.93197337876065,
                40.602646794236705
              ],
              [
                -73.93198823448277,
                40.60264601363159
              ],
              [
                -73.93200644100637,
                40.602645057091074
              ],
              [
                -73.93205807277273,
                40.60265348619675
              ],
              [
                -73.93209824153307,
                40.60267297162927
              ],
              [
                -73.93211608839732,
                40.60268851664704
              ],
              [
                -73.93212319646145,
                40.60270973924875
              ],
              [
                -73.93211937076838,
                40.60275348789475
              ],
              [
                -73.93209319849413,
                40.602851434260984
              ],
              [
                -73.93207363026063,
                40.60290099373188
              ],
              [
                -73.93203695430982,
                40.602950296648615
              ],
              [
                -73.93200606453784,
                40.603014725664416
              ],
              [
                -73.93198888391238,
                40.60305196241939
              ],
              [
                -73.93197205593805,
                40.603096857304706
              ],
              [
                -73.93196227064435,
                40.60312163761543
              ],
              [
                -73.93193669261566,
                40.60313155862344
              ],
              [
                -73.93191463221174,
                40.6031521040622
              ],
              [
                -73.93186823221386,
                40.60316802383073
              ],
              [
                -73.93182093031916,
                40.60318547962285
              ],
              [
                -73.93177214165783,
                40.603213695746746
              ],
              [
                -73.93172784434582,
                40.603250970640424
              ],
              [
                -73.93169449676314,
                40.603286386755855
              ],
              [
                -73.93166059968237,
                40.60333099586334
              ],
              [
                -73.93160741952671,
                40.603391514450706
              ],
              [
                -73.93156257164631,
                40.60343798462657
              ],
              [
                -73.93154406622969,
                40.603469127657824
              ],
              [
                -73.93151404507825,
                40.603490683838736
              ],
              [
                -73.9314815991787,
                40.603524534869194
              ],
              [
                -73.93144846344933,
                40.603563793151686
              ],
              [
                -73.93142546481485,
                40.6035858755184
              ],
              [
                -73.93135649064573,
                40.603631535786974
              ],
              [
                -73.93130497314321,
                40.603685082831184
              ],
              [
                -73.93127496724915,
                40.603727307361844
              ],
              [
                -73.93124811330779,
                40.60375180231972
              ],
              [
                -73.9312344359677,
                40.603778994501006
              ],
              [
                -73.93123128465788,
                40.60379669826616
              ],
              [
                -73.93120849798017,
                40.603822621823745
              ],
              [
                -73.93117476161733,
                40.60385040643471
              ],
              [
                -73.9311228428378,
                40.603875655476635
              ],
              [
                -73.93109906602827,
                40.603882475240546
              ],
              [
                -73.93108217243231,
                40.60388603331434
              ],
              [
                -73.93107488876831,
                40.6038886211246
              ],
              [
                -73.93106343895494,
                40.6038926914137
              ],
              [
                -73.93104826056619,
                40.60391000197197
              ],
              [
                -73.9310033614306,
                40.60393580466656
              ],
              [
                -73.93098650258456,
                40.60393570943958
              ],
              [
                -73.93098128579337,
                40.60393568050736
              ],
              [
                -73.93095561984985,
                40.603924960483035
              ],
              [
                -73.930943894245,
                40.6039115318425
              ],
              [
                -73.93092861464275,
                40.603887478210694
              ],
              [
                -73.93091985385577,
                40.603880117411514
              ],
              [
                -73.93090288636984,
                40.60386586120507
              ],
              [
                -73.93087590379082,
                40.60383990611028
              ],
              [
                -73.93085406295367,
                40.6038225733086
              ],
              [
                -73.93082574338456,
                40.603801038156014
              ],
              [
                -73.93078896040733,
                40.603778562417375
              ],
              [
                -73.93074706415364,
                40.603758226283595
              ],
              [
                -73.93071390997461,
                40.6037448391521
              ],
              [
                -73.93066836360131,
                40.603734985264325
              ],
              [
                -73.93061949814351,
                40.60373281542373
              ],
              [
                -73.93056523214433,
                40.60372682581861
              ],
              [
                -73.93052669571796,
                40.60373116683176
              ],
              [
                -73.93048920100134,
                40.603735864628945
              ],
              [
                -73.9304436695569,
                40.603747585397215
              ],
              [
                -73.93039711769738,
                40.603769707245455
              ],
              [
                -73.93039048333972,
                40.60377332855783
              ],
              [
                -73.93037621988707,
                40.603781112870344
              ],
              [
                -73.93034486413778,
                40.60380360174426
              ],
              [
                -73.93031739463554,
                40.60383037434031
              ],
              [
                -73.93028462271235,
                40.60386606480681
              ],
              [
                -73.93026947522583,
                40.60388727234369
              ],
              [
                -73.93025730770022,
                40.603916386716115
              ],
              [
                -73.93023442879164,
                40.603961388576344
              ],
              [
                -73.93021404882279,
                40.60399354414058
              ],
              [
                -73.93019328021879,
                40.60401771318433
              ],
              [
                -73.93016762708739,
                40.60402845833087
              ],
              [
                -73.9301425251583,
                40.60402959534135
              ],
              [
                -73.93012524946275,
                40.60401970413939
              ],
              [
                -73.93010981569321,
                40.604004598370565
              ],
              [
                -73.93009881658116,
                40.60398414190381
              ],
              [
                -73.93008748758332,
                40.60393615584436
              ],
              [
                -73.93008511429105,
                40.60389907216384
              ],
              [
                -73.93008075602481,
                40.60386528106569
              ],
              [
                -73.93007711500096,
                40.60383461951268
              ],
              [
                -73.93006118620376,
                40.60380952233384
              ],
              [
                -73.93004747973626,
                40.60379795900568
              ],
              [
                -73.92997300169458,
                40.60376538720001
              ],
              [
                -73.9299235720069,
                40.60375127624858
              ],
              [
                -73.92989750871342,
                40.6037432460956
              ],
              [
                -73.92987492996903,
                40.60374232598875
              ],
              [
                -73.92984643610663,
                40.60374914429056
              ],
              [
                -73.92981112592351,
                40.603765371381975
              ],
              [
                -73.92977444433969,
                40.60378601670778
              ],
              [
                -73.92973379494495,
                40.60381116110372
              ],
              [
                -73.92969815565213,
                40.60383219302955
              ],
              [
                -73.92966717688859,
                40.60384106668949
              ],
              [
                -73.92964239204595,
                40.603848187925294
              ],
              [
                -73.92961228066397,
                40.603853468099025
              ],
              [
                -73.92957181899766,
                40.60387183231382
              ],
              [
                -73.92953282447986,
                40.60389854388255
              ],
              [
                -73.92950334816175,
                40.60392664406198
              ],
              [
                -73.9294913330262,
                40.60393809786158
              ],
              [
                -73.9294646491881,
                40.60397003046514
              ],
              [
                -73.92944103951852,
                40.603990300567034
              ],
              [
                -73.92942158380257,
                40.603999236016456
              ],
              [
                -73.92940872349553,
                40.604003619894215
              ],
              [
                -73.92935726345164,
                40.6040015296777
              ],
              [
                -73.92930104928405,
                40.603998805096374
              ],
              [
                -73.92927458471804,
                40.60399357480485
              ],
              [
                -73.92925238099349,
                40.60397906752425
              ],
              [
                -73.92924015235188,
                40.60396503462515
              ],
              [
                -73.92921374589248,
                40.603940259737406
              ],
              [
                -73.92919568365807,
                40.60392520767894
              ],
              [
                -73.92918035528922,
                40.60391207752094
              ],
              [
                -73.92916956895058,
                40.60389562956537
              ],
              [
                -73.92916767703367,
                40.60387932597248
              ],
              [
                -73.92917220501894,
                40.60385443175684
              ],
              [
                -73.92916872325594,
                40.603846216663115
              ],
              [
                -73.92916685323532,
                40.603841802961
              ],
              [
                -73.92916490474228,
                40.60383863478947
              ],
              [
                -73.92915768588944,
                40.603826893081695
              ],
              [
                -73.92914897671425,
                40.60381997026786
              ],
              [
                -73.92912996631458,
                40.60381732465399
              ],
              [
                -73.9291125387482,
                40.603814238573996
              ],
              [
                -73.92909011185044,
                40.60380651186569
              ],
              [
                -73.92905279131001,
                40.603782856671955
              ],
              [
                -73.92904052647938,
                40.60376882322641
              ],
              [
                -73.92903956400407,
                40.60375968358305
              ],
              [
                -73.9290372022207,
                40.603744172445516
              ],
              [
                -73.92902457501721,
                40.60373296582849
              ],
              [
                -73.92901612396753,
                40.60372124027128
              ],
              [
                -73.9290135071661,
                40.60371053404616
              ],
              [
                -73.92900966703503,
                40.60369746691502
              ],
              [
                -73.9290116971971,
                40.60368541846881
              ],
              [
                -73.92901905320171,
                40.60367521127385
              ],
              [
                -73.92903906112069,
                40.60365666994539
              ],
              [
                -73.92907960400987,
                40.6036295214103
              ],
              [
                -73.92918120003131,
                40.603555831367814
              ],
              [
                -73.92924206782794,
                40.60351612474901
              ],
              [
                -73.92928201173613,
                40.60347700799478
              ],
              [
                -73.92930010623508,
                40.60346170260863
              ],
              [
                -73.92931073811064,
                40.603454601222325
              ],
              [
                -73.92932709555383,
                40.603446513593866
              ],
              [
                -73.92934132980231,
                40.60343771098358
              ],
              [
                -73.92936630105366,
                40.60342381186586
              ],
              [
                -73.9293791374652,
                40.60340866791665
              ],
              [
                -73.92939237228437,
                40.60339069648648
              ],
              [
                -73.9293999160438,
                40.60337371081844
              ],
              [
                -73.92940808221257,
                40.60334909508272
              ],
              [
                -73.92941520249596,
                40.603324122872095
              ],
              [
                -73.92942835807844,
                40.60331496275152
              ],
              [
                -73.92944931704055,
                40.60331434436946
              ],
              [
                -73.92947942819046,
                40.603309065913386
              ],
              [
                -73.92951172074437,
                40.60328495834488
              ],
              [
                -73.92956844608068,
                40.6032457425525
              ],
              [
                -73.92959504624501,
                40.60322259275404
              ],
              [
                -73.92959887783277,
                40.603216537917675
              ],
              [
                -73.92961589749179,
                40.60318963997944
              ],
              [
                -73.92962541070168,
                40.60314981876794
              ],
              [
                -73.92962544552631,
                40.603137841628545
              ],
              [
                -73.92962552383216,
                40.60311067917201
              ],
              [
                -73.92962907767883,
                40.60308743232413
              ],
              [
                -73.92964063354694,
                40.60305713739852
              ],
              [
                -73.9296478101645,
                40.603012592107646
              ],
              [
                -73.92964821803915,
                40.6029686497986
              ],
              [
                -73.92963545750591,
                40.602944678785796
              ],
              [
                -73.92963408761969,
                40.602907641274406
              ],
              [
                -73.92963395191335,
                40.60290397247063
              ],
              [
                -73.92962398500417,
                40.602862328339604
              ],
              [
                -73.9295988094564,
                40.60283113206136
              ],
              [
                -73.92958404325599,
                40.60279760847476
              ],
              [
                -73.92956616907622,
                40.602775775845686
              ],
              [
                -73.92956585098817,
                40.60277539468948
              ],
              [
                -73.92954217933408,
                40.602746940767126
              ],
              [
                -73.9295311997649,
                40.60273352734808
              ],
              [
                -73.92952653371673,
                40.60272782849605
              ],
              [
                -73.92950671202776,
                40.60270923350431
              ],
              [
                -73.92946177257461,
                40.60268289815908
              ],
              [
                -73.92943377206495,
                40.60266836038135
              ],
              [
                -73.92940240951565,
                40.60265382120168
              ],
              [
                -73.92930719474792,
                40.602612761925556
              ],
              [
                -73.92921086019335,
                40.60257255728442
              ],
              [
                -73.92920595124994,
                40.60257105671767
              ],
              [
                -73.92920525844762,
                40.60257084486918
              ],
              [
                -73.92919741628921,
                40.602569132245996
              ],
              [
                -73.92919101377164,
                40.602568083087085
              ],
              [
                -73.92918172865058,
                40.60256656005458
              ],
              [
                -73.92913017763841,
                40.60256652827745
              ],
              [
                -73.92912414379957,
                40.60256623741147
              ],
              [
                -73.92911224821778,
                40.602565662486256
              ],
              [
                -73.92907527403578,
                40.60255966206693
              ],
              [
                -73.92905667218389,
                40.60255513701545
              ],
              [
                -73.92902597637303,
                40.60254767256533
              ],
              [
                -73.92900581177689,
                40.60254168082792
              ],
              [
                -73.92898846106694,
                40.60253505572274
              ],
              [
                -73.92898564680316,
                40.602533981294734
              ],
              [
                -73.92896660726448,
                40.60252457298607
              ],
              [
                -73.92896227304934,
                40.6025216784189
              ],
              [
                -73.92894868747497,
                40.60251260401329
              ],
              [
                -73.92893711921303,
                40.60250436179799
              ],
              [
                -73.92893189064395,
                40.6025006360672
              ],
              [
                -73.9289184567712,
                40.60248781563248
              ],
              [
                -73.92891552365388,
                40.602484006215754
              ],
              [
                -73.92890726601246,
                40.60247328661357
              ],
              [
                -73.92889831522541,
                40.60245876048726
              ],
              [
                -73.92888764503715,
                40.60243838033042
              ],
              [
                -73.92888713241264,
                40.6024373989624
              ],
              [
                -73.92887707111007,
                40.602413476777336
              ],
              [
                -73.92887540522597,
                40.6024087591306
              ],
              [
                -73.92884690357079,
                40.60232804169031
              ],
              [
                -73.92883420122563,
                40.602297292728345
              ],
              [
                -73.9288335008079,
                40.60229556841967
              ],
              [
                -73.92881896369747,
                40.60225627483531
              ],
              [
                -73.9288149185336,
                40.602245759205296
              ],
              [
                -73.92881337630806,
                40.60224175028252
              ],
              [
                -73.928807628277,
                40.602232971042326
              ],
              [
                -73.92880442554089,
                40.602228078379746
              ],
              [
                -73.92879882926825,
                40.602222095127104
              ],
              [
                -73.92879430416593,
                40.60221726095029
              ],
              [
                -73.928793231457,
                40.60221611287849
              ],
              [
                -73.92879235977134,
                40.602215669391235
              ],
              [
                -73.92878987124975,
                40.6022144030043
              ],
              [
                -73.92878753889542,
                40.60221262072413
              ],
              [
                -73.92878651195417,
                40.60221183839689
              ],
              [
                -73.92878478744824,
                40.602211398736934
              ],
              [
                -73.9287831508356,
                40.60221098325557
              ],
              [
                -73.9287811637933,
                40.60221022278225
              ],
              [
                -73.92877866988114,
                40.602209271011326
              ],
              [
                -73.92877575426226,
                40.60220926920445
              ],
              [
                -73.9287741871033,
                40.60220926823322
              ],
              [
                -73.92876970498486,
                40.60220926545536
              ],
              [
                -73.92876747029989,
                40.60220926407032
              ],
              [
                -73.92876522242689,
                40.60220926267705
              ],
              [
                -73.92875961732828,
                40.60221011393652
              ],
              [
                -73.92874531826534,
                40.60221494632949
              ],
              [
                -73.92873943918403,
                40.60221693376839
              ],
              [
                -73.92872798506362,
                40.60222187933014
              ],
              [
                -73.92869795603175,
                40.60223484538602
              ],
              [
                -73.92868351783146,
                40.60224070246098
              ],
              [
                -73.92868113858397,
                40.60224166829777
              ],
              [
                -73.92868077406598,
                40.60224175485167
              ],
              [
                -73.92868072657457,
                40.60224176906222
              ],
              [
                -73.92866320311964,
                40.602245928657375
              ],
              [
                -73.92865535739779,
                40.60224677684564
              ],
              [
                -73.92865203667742,
                40.60224719746012
              ],
              [
                -73.92864863242363,
                40.602247627404495
              ],
              [
                -73.92852648878832,
                40.60223986443279
              ],
              [
                -73.92839873568074,
                40.60223807594712
              ],
              [
                -73.92838528891583,
                40.60223721451037
              ],
              [
                -73.92837440833208,
                40.60223529354172
              ],
              [
                -73.9283707227971,
                40.60223464190169
              ],
              [
                -73.92834495508235,
                40.602227792827264
              ],
              [
                -73.92833967200662,
                40.60222595776937
              ],
              [
                -73.92833263229963,
                40.60222351365262
              ],
              [
                -73.92832255237369,
                40.602218382314504
              ],
              [
                -73.92831931739906,
                40.60221591326261
              ],
              [
                -73.92831359349337,
                40.60221154438527
              ],
              [
                -73.92830575510733,
                40.60220556021254
              ],
              [
                -73.92829344377773,
                40.6021910326123
              ],
              [
                -73.92828225525899,
                40.60217479557377
              ],
              [
                -73.9282561205666,
                40.60212207860975
              ],
              [
                -73.9282542979957,
                40.60211840439525
              ],
              [
                -73.92824423625846,
                40.60210045976148
              ],
              [
                -73.92823304157285,
                40.60208507761535
              ],
              [
                -73.92822072840617,
                40.60207311186189
              ],
              [
                -73.92821735995149,
                40.60206968914763
              ],
              [
                -73.9282117688975,
                40.60206627275142
              ],
              [
                -73.92819048928519,
                40.60205515512197
              ],
              [
                -73.9281826490905,
                40.602050879904674
              ],
              [
                -73.92817982602546,
                40.6020480060206
              ],
              [
                -73.92817929074548,
                40.60204746104889
              ],
              [
                -73.92817885678181,
                40.60204712974004
              ],
              [
                -73.92817593148192,
                40.60204489642349
              ],
              [
                -73.92817534501715,
                40.60204309930675
              ],
              [
                -73.92817481375147,
                40.60204147896779
              ],
              [
                -73.9281728601055,
                40.602038494220075
              ],
              [
                -73.928172576813,
                40.60203806098013
              ],
              [
                -73.9281725773517,
                40.60203756023577
              ],
              [
                -73.92817257865096,
                40.60203635251887
              ],
              [
                -73.92817258048821,
                40.602034644727745
              ],
              [
                -73.92817258416396,
                40.60203122797283
              ],
              [
                -73.92817491178074,
                40.60202063890426
              ],
              [
                -73.92817596272405,
                40.60201585425813
              ],
              [
                -73.92817627850464,
                40.60201489602116
              ],
              [
                -73.92817708692468,
                40.602012439376104
              ],
              [
                -73.92817653573007,
                40.602010751510406
              ],
              [
                -73.92817597007449,
                40.602009021418375
              ],
              [
                -73.92817485418183,
                40.60200389617147
              ],
              [
                -73.92817437712355,
                40.60200133116942
              ],
              [
                -73.92817374140687,
                40.60199791619285
              ],
              [
                -73.92816966445174,
                40.60199213472644
              ],
              [
                -73.92816590857194,
                40.60198680746447
              ],
              [
                -73.9281594595408,
                40.60197818975407
              ],
              [
                -73.92815695657418,
                40.60197484279973
              ],
              [
                -73.9281502908348,
                40.60196825825463
              ],
              [
                -73.9281379252257,
                40.60195603949036
              ],
              [
                -73.92813120697132,
                40.60195005600606
              ],
              [
                -73.92812448977602,
                40.60194492725578
              ],
              [
                -73.92812176618881,
                40.601943368703864
              ],
              [
                -73.92811552999339,
                40.60193979659988
              ],
              [
                -73.92810544832876,
                40.60193551880252
              ],
              [
                -73.92808304299008,
                40.60192867244247
              ],
              [
                -73.92801806164896,
                40.60191325710751
              ],
              [
                -73.92800013798627,
                40.601906412365466
              ],
              [
                -73.92799229777866,
                40.60190299186898
              ],
              [
                -73.92798557836042,
                40.60189871566504
              ],
              [
                -73.927969618924,
                40.601887691291275
              ],
              [
                -73.92793854543464,
                40.60186622724136
              ],
              [
                -73.92785120812964,
                40.60179698541436
              ],
              [
                -73.92781313709692,
                40.60176877472591
              ],
              [
                -73.92770115744216,
                40.60169012126254
              ],
              [
                -73.92761976678575,
                40.60163463999163
              ],
              [
                -73.92760709237855,
                40.60162599956571
              ],
              [
                -73.92759648550174,
                40.601620081114646
              ],
              [
                -73.92757797229089,
                40.601609752333566
              ],
              [
                -73.92751524676548,
                40.601581524834224
              ],
              [
                -73.92748948522386,
                40.60156869695777
              ],
              [
                -73.92746597086003,
                40.601552453233886
              ],
              [
                -73.92745701242593,
                40.60154476050356
              ],
              [
                -73.92744917922722,
                40.60153535901533
              ],
              [
                -73.92743015841607,
                40.60150715955346
              ],
              [
                -73.9274154778035,
                40.60147967868183
              ],
              [
                -73.9274077913679,
                40.60146529111266
              ],
              [
                -73.9273876687054,
                40.60141915426929
              ],
              [
                -73.92737648337224,
                40.6013995015584
              ],
              [
                -73.92736305373745,
                40.60138326368279
              ],
              [
                -73.92734631436632,
                40.60136728824181
              ],
              [
                -73.92734514011707,
                40.60136617091746
              ],
              [
                -73.92734386492111,
                40.60136510948414
              ],
              [
                -73.9272802035588,
                40.60131231528863
              ],
              [
                -73.92722198514105,
                40.60126188298016
              ],
              [
                -73.92721137751528,
                40.6012533086529
              ],
              [
                -73.92720295053422,
                40.60124649610228
              ],
              [
                -73.92718391352784,
                40.60123452664748
              ],
              [
                -73.92717864611902,
                40.601231729090834
              ],
              [
                -73.92715815311817,
                40.601220842287695
              ],
              [
                -73.9271304337976,
                40.601206454062854
              ],
              [
                -73.92713015240506,
                40.601206303443234
              ],
              [
                -73.92710895443535,
                40.6011967188911
              ],
              [
                -73.92704502576358,
                40.60116781237987
              ],
              [
                -73.92702874570601,
                40.60116065575206
              ],
              [
                -73.92693413537623,
                40.6011190552739
              ],
              [
                -73.92689898565789,
                40.60110116208167
              ],
              [
                -73.92688709503545,
                40.60109510939491
              ],
              [
                -73.92685087595353,
                40.60107248297588
              ],
              [
                -73.92683782009782,
                40.60106432755367
              ],
              [
                -73.92683610747942,
                40.60106310718605
              ],
              [
                -73.92680422726917,
                40.601040389698035
              ],
              [
                -73.92677813208405,
                40.601018653922324
              ],
              [
                -73.9267549643135,
                40.60099935805849
              ],
              [
                -73.9267362962585,
                40.600982994306264
              ],
              [
                -73.92672473632462,
                40.600972860802656
              ],
              [
                -73.92666092795997,
                40.60090961067161
              ],
              [
                -73.92662958157993,
                40.600882258110396
              ],
              [
                -73.92659428951238,
                40.6008572852854
              ],
              [
                -73.92658366943729,
                40.60084977034631
              ],
              [
                -73.92647728272885,
                40.6007839320536
              ],
              [
                -73.92643249022989,
                40.60075315340746
              ],
              [
                -73.92638994148001,
                40.60072152312235
              ],
              [
                -73.92637114016009,
                40.600707168402415
              ],
              [
                -73.92635187240185,
                40.60069245654335
              ],
              [
                -73.92634118598816,
                40.600681966220854
              ],
              [
                -73.92633619879054,
                40.60067707133951
              ],
              [
                -73.92630150229996,
                40.600638612538845
              ],
              [
                -73.92629142748436,
                40.600629210035734
              ],
              [
                -73.92628023072332,
                40.600621514101974
              ],
              [
                -73.92627826204523,
                40.600620513192474
              ],
              [
                -73.92627350927923,
                40.600618094205224
              ],
              [
                -73.92626678761923,
                40.60061467145995
              ],
              [
                -73.92626038449839,
                40.6006123897923
              ],
              [
                -73.9262499858011,
                40.60060868256752
              ],
              [
                -73.92618388603849,
                40.600594120711094
              ],
              [
                -73.92617274590835,
                40.60059128030165
              ],
              [
                -73.92617044246994,
                40.6005906936425
              ],
              [
                -73.92615812012767,
                40.60058641540671
              ],
              [
                -73.92612390674402,
                40.60057033742145
              ],
              [
                -73.92608531584786,
                40.60055220186512
              ],
              [
                -73.92605619362678,
                40.60054022573549
              ],
              [
                -73.9260337234564,
                40.600531802315736
              ],
              [
                -73.92593521516956,
                40.600494876755434
              ],
              [
                -73.92592836870797,
                40.60049243496076
              ],
              [
                -73.92591841275298,
                40.60048888664137
              ],
              [
                -73.92586498616973,
                40.60047612181405
              ],
              [
                -73.92586463613146,
                40.60047603983401
              ],
              [
                -73.92586100945621,
                40.60047454816006
              ],
              [
                -73.9258500736281,
                40.60047005115491
              ],
              [
                -73.92584335486009,
                40.600466630061845
              ],
              [
                -73.92584251867842,
                40.60046611906081
              ],
              [
                -73.92583775483408,
                40.60046321036056
              ],
              [
                -73.92583327596893,
                40.60045979071229
              ],
              [
                -73.92583164324368,
                40.60045771179413
              ],
              [
                -73.92582991943321,
                40.60045551705444
              ],
              [
                -73.92582320504269,
                40.600446970910184
              ],
              [
                -73.92580642995411,
                40.600416210098814
              ],
              [
                -73.92579971739785,
                40.60040681089572
              ],
              [
                -73.92579962957868,
                40.60040672305363
              ],
              [
                -73.92573201532659,
                40.6003383974963
              ],
              [
                -73.92568778087471,
                40.600317481027375
              ],
              [
                -73.92562198775776,
                40.60028727366164
              ],
              [
                -73.92555357867363,
                40.60024490462461
              ],
              [
                -73.92547665986197,
                40.600212712620724
              ],
              [
                -73.9254249818069,
                40.600183612782885
              ],
              [
                -73.9253808749357,
                40.6001458694782
              ],
              [
                -73.92532417702287,
                40.600096261599504
              ],
              [
                -73.92530612196603,
                40.60007689957386
              ],
              [
                -73.92527811813908,
                40.60006534229357
              ],
              [
                -73.92524212099853,
                40.60005336249182
              ],
              [
                -73.92521529692843,
                40.60004994151647
              ],
              [
                -73.9252079878428,
                40.60004831675259
              ],
              [
                -73.92519060360202,
                40.60004094939815
              ],
              [
                -73.9251724338564,
                40.60002787347486
              ],
              [
                -73.92514689619352,
                40.60000057186655
              ],
              [
                -73.92513064453816,
                40.59997871379739
              ],
              [
                -73.92510107941985,
                40.59994773262404
              ],
              [
                -73.92507625606675,
                40.59992460579028
              ],
              [
                -73.9250452756294,
                40.599903340875706
              ],
              [
                -73.92501594498844,
                40.599888116121775
              ],
              [
                -73.92495896714752,
                40.599863705108035
              ],
              [
                -73.92491642219117,
                40.599846438900116
              ],
              [
                -73.92489500836547,
                40.59983539076441
              ],
              [
                -73.92487924455052,
                40.59982700885955
              ],
              [
                -73.92486491934976,
                40.59982148242323
              ],
              [
                -73.92485419122106,
                40.599818704308404
              ],
              [
                -73.92482542770848,
                40.599811492965735
              ],
              [
                -73.92479626607707,
                40.599804942425614
              ],
              [
                -73.92478060994458,
                40.599798151937556
              ],
              [
                -73.92476743780465,
                40.59979065006929
              ],
              [
                -73.92474940221007,
                40.599786218505685
              ],
              [
                -73.92473567944916,
                40.599788707882375
              ],
              [
                -73.92471629808001,
                40.5997955846586
              ],
              [
                -73.92470272386295,
                40.59979420336635
              ],
              [
                -73.92468717251317,
                40.599789032941636
              ],
              [
                -73.92467381667127,
                40.59978540103439
              ],
              [
                -73.9246638050116,
                40.59978676687102
              ],
              [
                -73.92465076177538,
                40.599793401858
              ],
              [
                -73.92463430497592,
                40.59979341686655
              ],
              [
                -73.92461752940672,
                40.59978863044405
              ],
              [
                -73.92459877781145,
                40.59978005438489
              ],
              [
                -73.92459068384734,
                40.59977263808477
              ],
              [
                -73.9245857956167,
                40.599763192261264
              ],
              [
                -73.92458307728964,
                40.599748177515366
              ],
              [
                -73.92458240393556,
                40.59973857027893
              ],
              [
                -73.92457841785492,
                40.5997293994598
              ],
              [
                -73.92457042932716,
                40.59972360273239
              ],
              [
                -73.92455239376244,
                40.59971917231049
              ],
              [
                -73.92452211365516,
                40.599714594963075
              ],
              [
                -73.92448153095812,
                40.59971366247214
              ],
              [
                -73.92446511640095,
                40.5997082170372
              ],
              [
                -73.92444650700794,
                40.599701233579175
              ],
              [
                -73.92442659380441,
                40.59970009452212
              ],
              [
                -73.92441456365636,
                40.59970316077135
              ],
              [
                -73.92440486987621,
                40.599709330191985
              ],
              [
                -73.92439249970637,
                40.59972559868912
              ],
              [
                -73.92438186307997,
                40.599736956871936
              ],
              [
                -73.92437141172344,
                40.59974444273834
              ],
              [
                -73.92435708039476,
                40.599744377854
              ],
              [
                -73.92434437540875,
                40.59973780887978
              ],
              [
                -73.92432195634314,
                40.599724866057706
              ],
              [
                -73.9243065894021,
                40.59971585358805
              ],
              [
                -73.92429647697782,
                40.59971013887201
              ],
              [
                -73.92429123088938,
                40.59970135035228
              ],
              [
                -73.9242912722805,
                40.599695860782575
              ],
              [
                -73.92429552785268,
                40.59969023678251
              ],
              [
                -73.92430039289492,
                40.599687138351584
              ],
              [
                -73.92431019279303,
                40.59968256154236
              ],
              [
                -73.924315205492,
                40.59967562109321
              ],
              [
                -73.92431291117896,
                40.59966702667426
              ],
              [
                -73.92430715377998,
                40.59966276898079
              ],
              [
                -73.92429073928069,
                40.59965729604619
              ],
              [
                -73.92427578653687,
                40.599655177672034
              ],
              [
                -73.92424541012143,
                40.59965087192059
              ],
              [
                -73.92421174312555,
                40.59964802199761
              ],
              [
                -73.92418462982292,
                40.59964632982713
              ],
              [
                -73.92418280970283,
                40.59964656082189
              ],
              [
                -73.9241638512213,
                40.59964896207375
              ],
              [
                -73.9241561770424,
                40.599649933704676
              ],
              [
                -73.92412523564633,
                40.599657928547906
              ],
              [
                -73.92410988673973,
                40.59966615280867
              ],
              [
                -73.92409477905095,
                40.59968123872038
              ],
              [
                -73.92408709514405,
                40.59969462877764
              ],
              [
                -73.92408013809667,
                40.59970143122846
              ],
              [
                -73.92406547246631,
                40.59970910643898
              ],
              [
                -73.92404544701309,
                40.59971235952234
              ],
              [
                -73.92402682516602,
                40.59971558605552
              ],
              [
                -73.92401237890165,
                40.59972227381948
              ],
              [
                -73.92400307854606,
                40.599730145227994
              ],
              [
                -73.9239973367064,
                40.59974312494409
              ],
              [
                -73.92398834060724,
                40.59976966064605
              ],
              [
                -73.92398177222427,
                40.59978236523442
              ],
              [
                -73.923971574733,
                40.59978941264072
              ],
              [
                -73.92396069286787,
                40.599794674904764
              ],
              [
                -73.92394552203531,
                40.59980320114894
              ],
              [
                -73.92393478234803,
                40.59981060503038
              ],
              [
                -73.92392760768657,
                40.59981894558113
              ],
              [
                -73.9239277423936,
                40.599827069161414
              ],
              [
                -73.92393411506927,
                40.599858447075874
              ],
              [
                -73.92392988662432,
                40.59987244150937
              ],
              [
                -73.92392747048704,
                40.59987609104144
              ],
              [
                -73.92391525222916,
                40.599884290041075
              ],
              [
                -73.9239046937622,
                40.59989254475296
              ],
              [
                -73.92390086636624,
                40.59990042094949
              ],
              [
                -73.92389948837919,
                40.599908819113615
              ],
              [
                -73.92389971842576,
                40.599928746529244
              ],
              [
                -73.9238934428192,
                40.59993735990809
              ],
              [
                -73.92388449965362,
                40.59994825092208
              ],
              [
                -73.92387703838902,
                40.59995464191007
              ],
              [
                -73.92386986398152,
                40.599960619625364
              ],
              [
                -73.92386215202568,
                40.59996692919447
              ],
              [
                -73.92385501257137,
                40.599975789606994
              ],
              [
                -73.92385067646083,
                40.59998819327638
              ],
              [
                -73.92382682188565,
                40.600034069914116
              ],
              [
                -73.92380187302743,
                40.60009026796515
              ],
              [
                -73.92378100763126,
                40.60013452656747
              ],
              [
                -73.92376899292714,
                40.600156120148256
              ],
              [
                -73.92375575253068,
                40.60017628623612
              ],
              [
                -73.92374789127815,
                40.600186464785224
              ],
              [
                -73.92373840639789,
                40.600198205638904
              ],
              [
                -73.92373389127943,
                40.600210278649705
              ],
              [
                -73.92373103394813,
                40.6002218603923
              ],
              [
                -73.92372968924906,
                40.60023264821259
              ],
              [
                -73.92372755454412,
                40.60027293874014
              ],
              [
                -73.9237213054254,
                40.600290448094725
              ],
              [
                -73.92371520547469,
                40.60030224496983
              ],
              [
                -73.92370521869141,
                40.600312531334076
              ],
              [
                -73.9236940481655,
                40.60031949631534
              ],
              [
                -73.92367599533473,
                40.6003289264404
              ],
              [
                -73.92364332452888,
                40.600336864050256
              ],
              [
                -73.92363165286676,
                40.60034056237583
              ],
              [
                -73.92362170569025,
                40.600347719648724
              ],
              [
                -73.92361600655249,
                40.60035649924918
              ],
              [
                -73.9236096193698,
                40.60036824282097
              ],
              [
                -73.92360027447675,
                40.600383415256594
              ],
              [
                -73.92359010595837,
                40.60039518311811
              ],
              [
                -73.92358480111764,
                40.60040569271802
              ],
              [
                -73.92358353124993,
                40.600413870816496
              ],
              [
                -73.92358553540072,
                40.60042474211328
              ],
              [
                -73.92359340322476,
                40.60044094222395
              ],
              [
                -73.92360604612871,
                40.600469166565496
              ],
              [
                -73.92362495167734,
                40.60050077178616
              ],
              [
                -73.92362727560467,
                40.60051589719593
              ],
              [
                -73.9236227264069,
                40.600525638173046
              ],
              [
                -73.92361544051083,
                40.60053600807853
              ],
              [
                -73.92359095650694,
                40.600564428542576
              ],
              [
                -73.92358039242698,
                40.60057630587172
              ],
              [
                -73.92357051667331,
                40.60058401218073
              ],
              [
                -73.92355873559974,
                40.60058897359619
              ],
              [
                -73.92355232434709,
                40.60058946169989
              ],
              [
                -73.92354527007507,
                40.60058737210636
              ],
              [
                -73.92353778463892,
                40.600582535752075
              ],
              [
                -73.9235278484509,
                40.600579482957485
              ],
              [
                -73.92351009864076,
                40.60057700025719
              ],
              [
                -73.92348532507853,
                40.60057476098872
              ],
              [
                -73.92346760593718,
                40.60057697196763
              ],
              [
                -73.923456435849,
                40.60058215274005
              ],
              [
                -73.9234481416064,
                40.600592440209006
              ],
              [
                -73.9234454346592,
                40.60059779029338
              ],
              [
                -73.92344513492918,
                40.60060789143381
              ],
              [
                -73.92345061633692,
                40.60063243358808
              ],
              [
                -73.92345016908627,
                40.6006464037169
              ],
              [
                -73.92344796102553,
                40.60065504893015
              ],
              [
                -73.92343688775689,
                40.60067200438674
              ],
              [
                -73.92342816371738,
                40.60068006828801
              ],
              [
                -73.92342059661388,
                40.600684345124556
              ],
              [
                -73.92340842111713,
                40.60068708180908
              ],
              [
                -73.92339757658699,
                40.60069105457211
              ],
              [
                -73.92339040365536,
                40.60069755494927
              ],
              [
                -73.92338805690385,
                40.60070408601805
              ],
              [
                -73.92338760309362,
                40.60072222879164
              ],
              [
                -73.92338507405101,
                40.60076081845941
              ],
              [
                -73.92338073597212,
                40.6007755830977
              ],
              [
                -73.92336764802194,
                40.60078949051849
              ],
              [
                -73.92335644130469,
                40.60079648174456
              ],
              [
                -73.92334123743036,
                40.60080212588178
              ],
              [
                -73.92332671714138,
                40.6008077436682
              ],
              [
                -73.9233176713275,
                40.600815203403556
              ],
              [
                -73.92331229486442,
                40.60082332380894
              ],
              [
                -73.92330748891582,
                40.60083896598524
              ],
              [
                -73.92330984628042,
                40.600855162437234
              ],
              [
                -73.92331228746465,
                40.60086172367157
              ],
              [
                -73.9233166766265,
                40.600866035283374
              ],
              [
                -73.9233248849953,
                40.600867221673404
              ],
              [
                -73.92333785168488,
                40.600865529567336
              ],
              [
                -73.92334872870467,
                40.600863916815356
              ],
              [
                -73.92335996492247,
                40.60086397875585
              ],
              [
                -73.92336759441473,
                40.60086626257552
              ],
              [
                -73.92337086771961,
                40.600870738122445
              ],
              [
                -73.92337276154241,
                40.60088234999545
              ],
              [
                -73.92337084434381,
                40.60089110563525
              ],
              [
                -73.92334959532695,
                40.600923480049914
              ],
              [
                -73.92332825119229,
                40.600946411782346
              ],
              [
                -73.92331675430847,
                40.60095513254967
              ],
              [
                -73.92330154702422,
                40.600963136662806
              ],
              [
                -73.92328317206356,
                40.60097190778595
              ],
              [
                -73.92327200343347,
                40.600977088541356
              ],
              [
                -73.92326511697703,
                40.600983670353365
              ],
              [
                -73.92326053419595,
                40.60099234195466
              ],
              [
                -73.92326142664844,
                40.60099914909372
              ],
              [
                -73.92326199310561,
                40.60100771322882
              ],
              [
                -73.92326212649822,
                40.601017676948665
              ],
              [
                -73.92325991357451,
                40.60103052110628
              ],
              [
                -73.92325253786898,
                40.60105601596551
              ],
              [
                -73.92324120433761,
                40.60107939346007
              ],
              [
                -73.9232335960521,
                40.60108732256027
              ],
              [
                -73.92322859023683,
                40.6010882776509
              ],
              [
                -73.92322315123742,
                40.60108882351327
              ],
              [
                -73.9232125308212,
                40.60108763617463
              ],
              [
                -73.92319949464685,
                40.601086447723304
              ],
              [
                -73.92318988107249,
                40.601085865500586
              ],
              [
                -73.92317990463356,
                40.60108775274995
              ],
              [
                -73.92317345039767,
                40.60109422561627
              ],
              [
                -73.92317293760227,
                40.601101115246145
              ],
              [
                -73.92317706933007,
                40.60111006490415
              ],
              [
                -73.92317781181822,
                40.601120742705355
              ],
              [
                -73.92317419374658,
                40.6011354815147
              ],
              [
                -73.92317061869976,
                40.601144727405725
              ],
              [
                -73.92315994773388,
                40.601155562257624
              ],
              [
                -73.92314957058,
                40.601159948101596
              ],
              [
                -73.92313916397367,
                40.601160132125614
              ],
              [
                -73.92313272021084,
                40.601158262043334
              ],
              [
                -73.92312353670413,
                40.601157541556205
              ],
              [
                -73.9231134887283,
                40.6011589353784
              ],
              [
                -73.92310638907755,
                40.6011641456443
              ],
              [
                -73.92309867078826,
                40.601174625628886
              ],
              [
                -73.92308987388277,
                40.60118345908437
              ],
              [
                -73.92308518768189,
                40.60118685779584
              ],
              [
                -73.92307895564846,
                40.60118817174445
              ],
              [
                -73.92307369907834,
                40.601187755936685
              ],
              [
                -73.92306336458508,
                40.60118849067049
              ],
              [
                -73.92305075467893,
                40.6011936967414
              ],
              [
                -73.92303904049196,
                40.60120285709952
              ],
              [
                -73.92301384197246,
                40.60122529214809
              ],
              [
                -73.92300226467997,
                40.60124076559624
              ],
              [
                -73.92299641354248,
                40.60125580261341
              ],
              [
                -73.92298643636853,
                40.60128878895722
              ],
              [
                -73.92297908904168,
                40.60132081527757
              ],
              [
                -73.92297287919708,
                40.60133469978733
              ],
              [
                -73.92296591366737,
                40.60134803596965
              ],
              [
                -73.92294803045895,
                40.60136613907889
              ],
              [
                -73.92291904736955,
                40.60139279849785
              ],
              [
                -73.92287038507452,
                40.601433553005954
              ],
              [
                -73.92283614734508,
                40.601456916258684
              ],
              [
                -73.92278407843641,
                40.60148504294149
              ],
              [
                -73.92274484167972,
                40.601501512835604
              ],
              [
                -73.92272837333097,
                40.60151171233243
              ],
              [
                -73.92272227920651,
                40.60151936683123
              ],
              [
                -73.9227048796416,
                40.601554570265705
              ],
              [
                -73.92269254017482,
                40.60157555973793
              ],
              [
                -73.92267444374241,
                40.601592236485025
              ],
              [
                -73.92266222777391,
                40.60159862419392
              ],
              [
                -73.92265062818646,
                40.60160210116198
              ],
              [
                -73.92264400244554,
                40.60160171239054
              ],
              [
                -73.92263492944167,
                40.60160080112304
              ],
              [
                -73.92262311519376,
                40.601602879745315
              ],
              [
                -73.92260931861337,
                40.60160665711753
              ],
              [
                -73.92259804503614,
                40.601608378761654
              ],
              [
                -73.92258882756775,
                40.60160713853344
              ],
              [
                -73.92256431597028,
                40.60159666434681
              ],
              [
                -73.92255848474096,
                40.60159366750894
              ],
              [
                -73.92255532265294,
                40.601588450194875
              ],
              [
                -73.92255331166082,
                40.60158359116379
              ],
              [
                -73.92254931939385,
                40.601579140741265
              ],
              [
                -73.9225372978724,
                40.60157383687443
              ],
              [
                -73.92251886505412,
                40.60156954139786
              ],
              [
                -73.9224939509947,
                40.6015651609661
              ],
              [
                -73.92247457913338,
                40.60156188108117
              ],
              [
                -73.92245607084489,
                40.601560688854136
              ],
              [
                -73.92244162733233,
                40.60156320377256
              ],
              [
                -73.92241237195186,
                40.60157539891304
              ],
              [
                -73.92240228150452,
                40.60158225409547
              ],
              [
                -73.92239460312709,
                40.60158908292862
              ],
              [
                -73.92239106545904,
                40.601597069000825
              ],
              [
                -73.92239159974326,
                40.60160088517372
              ],
              [
                -73.92239609427259,
                40.6016068158936
              ],
              [
                -73.92240501735294,
                40.6016139858011
              ],
              [
                -73.92242394500137,
                40.60162522537915
              ],
              [
                -73.92244053684487,
                40.60163517273299
              ],
              [
                -73.92244898816395,
                40.60164374219245
              ],
              [
                -73.92245081383555,
                40.60165373335662
              ],
              [
                -73.92244561623141,
                40.60166402284182
              ],
              [
                -73.92243703508662,
                40.601672416126014
              ],
              [
                -73.92241545175197,
                40.601683765061445
              ],
              [
                -73.92237055773482,
                40.60170358075429
              ],
              [
                -73.9223477078364,
                40.60171896309158
              ],
              [
                -73.92233524073562,
                40.601725789187405
              ],
              [
                -73.92232385875587,
                40.601728279857774
              ],
              [
                -73.92231078303837,
                40.60173019123886
              ],
              [
                -73.92230106397129,
                40.601728016840866
              ],
              [
                -73.92229192411692,
                40.60172183486584
              ],
              [
                -73.92228552206859,
                40.60171450247579
              ],
              [
                -73.92227371755679,
                40.60170875728871
              ],
              [
                -73.92226763245095,
                40.601708038996286
              ],
              [
                -73.92225538189477,
                40.601713877142856
              ],
              [
                -73.92223055154116,
                40.60172912141327
              ],
              [
                -73.92219667576958,
                40.60175182549274
              ],
              [
                -73.92218456872656,
                40.60175615462621
              ],
              [
                -73.92217127987671,
                40.60175724344635
              ],
              [
                -73.92215543963513,
                40.6017538017207
              ],
              [
                -73.92214223054566,
                40.60174756152526
              ],
              [
                -73.92210098576165,
                40.601726810384946
              ],
              [
                -73.92209187580309,
                40.60172535055902
              ],
              [
                -73.92208135790413,
                40.60172759301031
              ],
              [
                -73.92206921126198,
                40.60173741119819
              ],
              [
                -73.92206036685496,
                40.60175587713474
              ],
              [
                -73.92205567929052,
                40.601761117618885
              ],
              [
                -73.92205027568491,
                40.601762732285806
              ],
              [
                -73.92204581235028,
                40.60176097422282
              ],
              [
                -73.92204131784406,
                40.60175504131139
              ],
              [
                -73.92203559501866,
                40.601751826565156
              ],
              [
                -73.92202446625261,
                40.6017533560952
              ],
              [
                -73.92201383653271,
                40.60175817975391
              ],
              [
                -73.92200425704225,
                40.60175995626607
              ],
              [
                -73.92199853119115,
                40.6017585799811
              ],
              [
                -73.92199421934278,
                40.601751139764374
              ],
              [
                -73.92199106492465,
                40.60173807235806
              ],
              [
                -73.92198887708614,
                40.60173109864389
              ],
              [
                -73.92198506448837,
                40.601726402893526
              ],
              [
                -73.92197455638636,
                40.60172082323691
              ],
              [
                -73.92197269359112,
                40.60172046463249
              ],
              [
                -73.9219649789354,
                40.60171897776648
              ],
              [
                -73.92194967363287,
                40.601719352187736
              ],
              [
                -73.92193443677061,
                40.60172266260216
              ],
              [
                -73.92192301744056,
                40.601726443684605
              ],
              [
                -73.92191657304511,
                40.601730547121704
              ],
              [
                -73.92190795213722,
                40.60173604024183
              ],
              [
                -73.92188701180113,
                40.601752850463434
              ],
              [
                -73.92184685489362,
                40.601786529864995
              ],
              [
                -73.92182079481722,
                40.6018063301717
              ],
              [
                -73.92180753372094,
                40.601813923623894
              ],
              [
                -73.9217946389653,
                40.60181668712288
              ],
              [
                -73.9217794065531,
                40.601815798401006
              ],
              [
                -73.92176479249822,
                40.60181016165114
              ],
              [
                -73.92175468270877,
                40.6018021130332
              ],
              [
                -73.92173932549886,
                40.60178524897408
              ],
              [
                -73.92172730617746,
                40.601778653876686
              ],
              [
                -73.9217192807535,
                40.601774145896606
              ],
              [
                -73.92170315115611,
                40.60177062148704
              ],
              [
                -73.92168255247921,
                40.601770058458705
              ],
              [
                -73.92166349282462,
                40.60177885683339
              ],
              [
                -73.92165094648091,
                40.60179114441956
              ],
              [
                -73.92165032453889,
                40.60180006458902
              ],
              [
                -73.9216535155744,
                40.60181184205504
              ],
              [
                -73.9216511602419,
                40.601824383526086
              ],
              [
                -73.92164091966228,
                40.601835082328684
              ],
              [
                -73.92162693823371,
                40.601842702911206
              ],
              [
                -73.92160810002534,
                40.60184689001953
              ],
              [
                -73.92158706910077,
                40.60184772605043
              ],
              [
                -73.9215697796087,
                40.6018498283034
              ],
              [
                -73.92155965231296,
                40.6018561343936
              ],
              [
                -73.92154854419883,
                40.601870180163765
              ],
              [
                -73.92153811694328,
                40.601888398544055
              ],
              [
                -73.92153598005682,
                40.60189814108448
              ],
              [
                -73.92153694106672,
                40.60190837928074
              ],
              [
                -73.92153322059893,
                40.60191732480883
              ],
              [
                -73.92152612096316,
                40.601922535646985
              ],
              [
                -73.92151689981944,
                40.6019236541411
              ],
              [
                -73.92148780695602,
                40.60191998313929
              ],
              [
                -73.92147830140904,
                40.601918659698356
              ],
              [
                -73.92147002357932,
                40.6019145922976
              ],
              [
                -73.92145617208807,
                40.60190346643383
              ],
              [
                -73.92141645365716,
                40.60187041877977
              ],
              [
                -73.92136829024516,
                40.60182410908578
              ],
              [
                -73.92135890295562,
                40.60181290333742
              ],
              [
                -73.92135790599158,
                40.60180447727797
              ],
              [
                -73.92136346453296,
                40.60179300667326
              ],
              [
                -73.92137497382095,
                40.60177465184242
              ],
              [
                -73.92138907143205,
                40.60176077281503
              ],
              [
                -73.92141105247315,
                40.60174876628953
              ],
              [
                -73.9214224428487,
                40.60173842663845
              ],
              [
                -73.92142504461891,
                40.60173148299273
              ],
              [
                -73.92142034348977,
                40.6017181406029
              ],
              [
                -73.92141210729977,
                40.6017091589255
              ],
              [
                -73.92139987012983,
                40.6017030022362
              ],
              [
                -73.92138561263522,
                40.601700357417975
              ],
              [
                -73.92136278007644,
                40.60170138550414
              ],
              [
                -73.92132078400631,
                40.60170830101293
              ],
              [
                -73.92131055774983,
                40.60170700336676
              ],
              [
                -73.92129954787023,
                40.60169810270905
              ],
              [
                -73.92129495169254,
                40.60168692888357
              ],
              [
                -73.92128996036958,
                40.60167534233052
              ],
              [
                -73.92128115139077,
                40.60166432912758
              ],
              [
                -73.92126610981683,
                40.6016541092608
              ],
              [
                -73.92124868755369,
                40.601648058232094
              ],
              [
                -73.92122870387188,
                40.60164584923453
              ],
              [
                -73.92120515319725,
                40.60164506396388
              ],
              [
                -73.92118462754671,
                40.60164504988942
              ],
              [
                -73.92117651951577,
                40.60164781660151
              ],
              [
                -73.92116559830849,
                40.60165672970425
              ],
              [
                -73.92115698009401,
                40.60166641233959
              ],
              [
                -73.92114912406117,
                40.601671128592166
              ],
              [
                -73.92114084135589,
                40.601671232138145
              ],
              [
                -73.9211245708161,
                40.60166556669542
              ],
              [
                -73.92109423755731,
                40.60164608635416
              ],
              [
                -73.92107492332471,
                40.601624772929625
              ],
              [
                -73.9210588114722,
                40.60160741471996
              ],
              [
                -73.92104070841883,
                40.60159774171375
              ],
              [
                -73.92102209672717,
                40.60159311600929
              ],
              [
                -73.92099710909585,
                40.60158950315624
              ],
              [
                -73.92097770063809,
                40.60158748666638
              ],
              [
                -73.92096222064357,
                40.60158338680168
              ],
              [
                -73.92095521008692,
                40.60157349960146
              ],
              [
                -73.92095576692455,
                40.601559309927886
              ],
              [
                -73.92095761605037,
                40.60154948427314
              ],
              [
                -73.92095589791772,
                40.60154056300027
              ],
              [
                -73.92094881399764,
                40.60153193891907
              ],
              [
                -73.92092552893062,
                40.6015205325913
              ],
              [
                -73.92089593937085,
                40.60150939532722
              ],
              [
                -73.92087747193757,
                40.601504552245764
              ],
              [
                -73.92085298676878,
                40.601502449312605
              ],
              [
                -73.92083397239482,
                40.60150213432865
              ],
              [
                -73.92081935415786,
                40.6015001490891
              ],
              [
                -73.92080851865346,
                40.60149627035892
              ],
              [
                -73.92077599006171,
                40.601475277642294
              ],
              [
                -73.92076807095478,
                40.60147239017859
              ],
              [
                -73.92075503628809,
                40.601471200948374
              ],
              [
                -73.9207393708553,
                40.60147226115963
              ],
              [
                -73.92072395869437,
                40.6014710428115
              ],
              [
                -73.92069756657507,
                40.60146803219695
              ],
              [
                -73.92068392280544,
                40.60146374090319
              ],
              [
                -73.92067554164554,
                40.60145626867809
              ],
              [
                -73.92066993292822,
                40.60144866233248
              ],
              [
                -73.92066785275189,
                40.60144091954056
              ],
              [
                -73.92066840768469,
                40.601428568332146
              ],
              [
                -73.92066643721799,
                40.60142005967257
              ],
              [
                -73.92065543050411,
                40.6014093467918
              ],
              [
                -73.92062779408764,
                40.601392337401286
              ],
              [
                -73.92059529862183,
                40.60137370581253
              ],
              [
                -73.920577982519,
                40.60136924611485
              ],
              [
                -73.92055062419101,
                40.60136016990732
              ],
              [
                -73.9205300788218,
                40.60134577298693
              ],
              [
                -73.92051076120704,
                40.601328632113805
              ],
              [
                -73.92048430764656,
                40.60131617929523
              ],
              [
                -73.92046928978824,
                40.6013166641199
              ],
              [
                -73.92045575359825,
                40.60131454120359
              ],
              [
                -73.92044747436779,
                40.60131047322529
              ],
              [
                -73.92044273110251,
                40.60130316818244
              ],
              [
                -73.9204353244499,
                40.6012933915802
              ],
              [
                -73.92042017776066,
                40.60128155101958
              ],
              [
                -73.92039721693821,
                40.60126896339313
              ],
              [
                -73.9203795450762,
                40.601261513860855
              ],
              [
                -73.92035956000656,
                40.60126114217106
              ],
              [
                -73.92033578660204,
                40.60126551780687
              ],
              [
                -73.92031373899856,
                40.60127230906516
              ],
              [
                -73.92030281623185,
                40.60128119461035
              ],
              [
                -73.92029336077799,
                40.601298947486654
              ],
              [
                -73.9202864689772,
                40.60131102038816
              ],
              [
                -73.9202745033028,
                40.60131932823683
              ],
              [
                -73.9202524656747,
                40.60131774788927
              ],
              [
                -73.92022921531151,
                40.601306890939895
              ],
              [
                -73.92018070185937,
                40.6012826750884
              ],
              [
                -73.92014597657983,
                40.60126014408959
              ],
              [
                -73.92012777059982,
                40.60124703819444
              ],
              [
                -73.92010831237522,
                40.60122830512586
              ],
              [
                -73.92009810034679,
                40.601214464806496
              ],
              [
                -73.92009786339072,
                40.6012029084784
              ],
              [
                -73.92010883532043,
                40.6011825222757
              ],
              [
                -73.92012940107826,
                40.601150175962374
              ],
              [
                -73.92015118519022,
                40.601121644628705
              ],
              [
                -73.92016993010388,
                40.60110568290524
              ],
              [
                -73.92017443595286,
                40.601101953149644
              ],
              [
                -73.92017439212422,
                40.601099454253884
              ],
              [
                -73.92017433407824,
                40.601096162097456
              ],
              [
                -73.92017300752794,
                40.601091274852166
              ],
              [
                -73.92016573798129,
                40.60108783880484
              ],
              [
                -73.92016017744024,
                40.60108652184815
              ],
              [
                -73.9201439928501,
                40.60108269043541
              ],
              [
                -73.92012260482421,
                40.60108053454888
              ],
              [
                -73.92010700786292,
                40.601084505373585
              ],
              [
                -73.92009753006873,
                40.60109023431383
              ],
              [
                -73.92007226783052,
                40.60110506720478
              ],
              [
                -73.92006098575499,
                40.60111463700143
              ],
              [
                -73.92004992437437,
                40.60111959738493
              ],
              [
                -73.92003732206923,
                40.60111827116776
              ],
              [
                -73.92001158049183,
                40.60111232506652
              ],
              [
                -73.91997796402242,
                40.601098028680205
              ],
              [
                -73.91995763043886,
                40.6010868156966
              ],
              [
                -73.91994313488395,
                40.60107206554428
              ],
              [
                -73.91993779023873,
                40.601055674786
              ],
              [
                -73.91993193709642,
                40.6010424962128
              ],
              [
                -73.91992204048402,
                40.60103529795941
              ],
              [
                -73.91989763488907,
                40.60102822679992
              ],
              [
                -73.91987865386812,
                40.60103027216724
              ],
              [
                -73.91985292611214,
                40.601042880903044
              ],
              [
                -73.91982352098546,
                40.60106133283122
              ],
              [
                -73.91980392083788,
                40.601068647217595
              ],
              [
                -73.91978570053212,
                40.60106756466037
              ],
              [
                -73.91976935752436,
                40.601063160972004
              ],
              [
                -73.9197558270934,
                40.60105447853934
              ],
              [
                -73.91975014484001,
                40.60104764005091
              ],
              [
                -73.91975375422574,
                40.60104075310076
              ],
              [
                -73.91975984495103,
                40.60103727140606
              ],
              [
                -73.91977266662413,
                40.60103527670832
              ],
              [
                -73.9197807746835,
                40.601030643652265
              ],
              [
                -73.91978251184943,
                40.60102339855724
              ],
              [
                -73.91977670330691,
                40.60100239739925
              ],
              [
                -73.91976249301169,
                40.60099192980434
              ],
              [
                -73.91974280611898,
                40.600982006203495
              ],
              [
                -73.9197286939526,
                40.60097914131517
              ],
              [
                -73.9197120904502,
                40.60098118831558
              ],
              [
                -73.91969353230722,
                40.60099146834274
              ],
              [
                -73.91967426193835,
                40.60099524371564
              ],
              [
                -73.91962467121434,
                40.60099809104887
              ],
              [
                -73.91957317528879,
                40.60099821970376
              ],
              [
                -73.91954271573707,
                40.600992790038674
              ],
              [
                -73.91951117923655,
                40.6009862359931
              ],
              [
                -73.9194835657342,
                40.600979409436704
              ],
              [
                -73.91946214741152,
                40.60097253243344
              ],
              [
                -73.91945196503714,
                40.60096577370335
              ],
              [
                -73.919449917555,
                40.60096039240345
              ],
              [
                -73.91944888545888,
                40.60095196512916
              ],
              [
                -73.9194438593342,
                40.60093801865419
              ],
              [
                -73.91942851763349,
                40.600909132567935
              ],
              [
                -73.9194177594273,
                40.60090217959984
              ],
              [
                -73.91940336080107,
                40.60089739408335
              ],
              [
                -73.91932071242007,
                40.60089994368452
              ],
              [
                -73.91930356890065,
                40.60090234708414
              ],
              [
                -73.91929798058312,
                40.60090676224505
              ],
              [
                -73.9192980448084,
                40.6009138164419
              ],
              [
                -73.91930265004916,
                40.60091771673973
              ],
              [
                -73.91931363050709,
                40.60091953611011
              ],
              [
                -73.91932309982607,
                40.60092031121431
              ],
              [
                -73.91935533192367,
                40.600918495371204
              ],
              [
                -73.91935979294124,
                40.600922066705714
              ],
              [
                -73.91935921049398,
                40.60092788475354
              ],
              [
                -73.91935182054645,
                40.60093301232718
              ],
              [
                -73.91933788168978,
                40.60093517104268
              ],
              [
                -73.91931886749276,
                40.600936667803865
              ],
              [
                -73.91931068795509,
                40.60094023026551
              ],
              [
                -73.91930761917936,
                40.6009467887216
              ],
              [
                -73.91930740174624,
                40.60097829829693
              ],
              [
                -73.91930014678054,
                40.60099157759767
              ],
              [
                -73.91928471935167,
                40.60100419327109
              ],
              [
                -73.9192689379233,
                40.60101200429177
              ],
              [
                -73.91925362988023,
                40.60101476581045
              ],
              [
                -73.91924192974514,
                40.60101190356205
              ],
              [
                -73.91923257897216,
                40.60100069816702
              ],
              [
                -73.91922071571277,
                40.60098369901857
              ],
              [
                -73.91920714797703,
                40.60097685546285
              ],
              [
                -73.91918590709885,
                40.60097266787889
              ],
              [
                -73.91913369661202,
                40.600968102839225
              ],
              [
                -73.9191160236535,
                40.60096065193879
              ],
              [
                -73.91911145577778,
                40.60095548798699
              ],
              [
                -73.91911171349467,
                40.600951398948304
              ],
              [
                -73.91912104778446,
                40.60094588794382
              ],
              [
                -73.91914135970333,
                40.60094452983742
              ],
              [
                -73.91916343676967,
                40.60094245929478
              ],
              [
                -73.91917705749938,
                40.60093601916811
              ],
              [
                -73.91917814168286,
                40.6009317105772
              ],
              [
                -73.91917533854992,
                40.600927618883105
              ],
              [
                -73.91916353375625,
                40.600921353030095
              ],
              [
                -73.9191079478483,
                40.60090887939911
              ],
              [
                -73.91910082286103,
                40.60090519577777
              ],
              [
                -73.91909805291066,
                40.60090220259423
              ],
              [
                -73.9190859350907,
                40.60088745501235
              ],
              [
                -73.91907478072156,
                40.60088061263738
              ],
              [
                -73.91906947725295,
                40.60087921723785
              ],
              [
                -73.91905184816414,
                40.60087458436685
              ],
              [
                -73.9190048336398,
                40.60086154075759
              ],
              [
                -73.91899367352788,
                40.60085889849522
              ],
              [
                -73.91898232576955,
                40.60086055707653
              ],
              [
                -73.91898059854392,
                40.60086081016813
              ],
              [
                -73.91896161093118,
                40.60086938901805
              ],
              [
                -73.91893729843603,
                40.600873570823495
              ],
              [
                -73.91891144376474,
                40.600873853968906
              ],
              [
                -73.91889402037988,
                40.600867802581654
              ],
              [
                -73.91887901850234,
                40.600856292957054
              ],
              [
                -73.91884779871687,
                40.60082399206091
              ],
              [
                -73.91883736467854,
                40.60081712269675
              ],
              [
                -73.91882519627006,
                40.600814396779036
              ],
              [
                -73.91880409704811,
                40.60081235024749
              ],
              [
                -73.91878422409624,
                40.60080755961301
              ],
              [
                -73.91875723059746,
                40.60079546352168
              ],
              [
                -73.91869312203318,
                40.60077334989827
              ],
              [
                -73.91867828675998,
                40.600772351817
              ],
              [
                -73.91866855912362,
                40.600776160243235
              ],
              [
                -73.91863602304811,
                40.60079172764754
              ],
              [
                -73.9186209992879,
                40.600798222168876
              ],
              [
                -73.91860918373875,
                40.600800299875196
              ],
              [
                -73.91859190395141,
                40.60079639057197
              ],
              [
                -73.91857336923096,
                40.600786824423906
              ],
              [
                -73.91855603487761,
                40.60076801084185
              ],
              [
                -73.91854477885144,
                40.60075537618888
              ],
              [
                -73.91852934477951,
                40.600744002915896
              ],
              [
                -73.91850912125022,
                40.60073204759907
              ],
              [
                -73.91843788919837,
                40.60070446732975
              ],
              [
                -73.91840751312273,
                40.60069044669305
              ],
              [
                -73.9183770355267,
                40.60067066364188
              ],
              [
                -73.91832875071364,
                40.60063708731235
              ],
              [
                -73.91830543680375,
                40.60061969561864
              ],
              [
                -73.91826447883913,
                40.600572126567606
              ],
              [
                -73.91824881183966,
                40.600544448479255
              ],
              [
                -73.91824003666655,
                40.60053579605467
              ],
              [
                -73.91822251015459,
                40.60052631349386
              ],
              [
                -73.91820814640923,
                40.60052207684534
              ],
              [
                -73.91818895561347,
                40.60051907077912
              ],
              [
                -73.91814855814971,
                40.60051426609187
              ],
              [
                -73.91814175737942,
                40.600509923916974
              ],
              [
                -73.91813356095673,
                40.60049910524136
              ],
              [
                -73.91813278460573,
                40.60048551790259
              ],
              [
                -73.91813309247705,
                40.600469431967795
              ],
              [
                -73.91813383910213,
                40.60044774969768
              ],
              [
                -73.91813182934679,
                40.60044288941647
              ],
              [
                -73.91812603535004,
                40.60043863138738
              ],
              [
                -73.91811422430293,
                40.60043886957542
              ],
              [
                -73.9180915283442,
                40.6004462104469
              ],
              [
                -73.91807514078245,
                40.60044913471525
              ],
              [
                -73.91806182036798,
                40.60044547509258
              ],
              [
                -73.91803500633199,
                40.60043422916976
              ],
              [
                -73.9180077656362,
                40.60041837236504
              ],
              [
                -73.91799042415569,
                40.60040556913691
              ],
              [
                -73.91797470448769,
                40.600391724696905
              ],
              [
                -73.91795951878667,
                40.60038353491406
              ],
              [
                -73.91794587383234,
                40.60038160378018
              ],
              [
                -73.91793394476751,
                40.600388099918824
              ],
              [
                -73.91792643852354,
                40.600401791075996
              ],
              [
                -73.91791951170606,
                40.60041150215275
              ],
              [
                -73.91790488417722,
                40.60041733813869
              ],
              [
                -73.91789299943636,
                40.60041834521039
              ],
              [
                -73.91787279962206,
                40.60041712372899
              ],
              [
                -73.9178452953706,
                40.600410076723165
              ],
              [
                -73.91783658859187,
                40.60040430699156
              ],
              [
                -73.91782637491526,
                40.60039282645142
              ],
              [
                -73.91779521853181,
                40.60034068097581
              ],
              [
                -73.91778123630412,
                40.600319040272716
              ],
              [
                -73.9177454576067,
                40.60027737662656
              ],
              [
                -73.91771262127872,
                40.600243758488254
              ],
              [
                -73.9176961462692,
                40.60022939027532
              ],
              [
                -73.91768406403119,
                40.60021519106045
              ],
              [
                -73.91767925353922,
                40.600202643865465
              ],
              [
                -73.91768435883627,
                40.600180552430146
              ],
              [
                -73.91770801389576,
                40.600124190096444
              ],
              [
                -73.91770929031333,
                40.60011181194219
              ],
              [
                -73.91770566532304,
                40.600101983590754
              ],
              [
                -73.91769264353164,
                40.600090610759196
              ],
              [
                -73.91766806318164,
                40.600079036987424
              ],
              [
                -73.91762670792177,
                40.600062676940574
              ],
              [
                -73.91760853457674,
                40.600051931154375
              ],
              [
                -73.91758695033738,
                40.600034568530916
              ],
              [
                -73.91756644521524,
                40.600016547952634
              ],
              [
                -73.91754276861181,
                40.60000343889449
              ],
              [
                -73.91750461425308,
                40.599988670784796
              ],
              [
                -73.91743211525555,
                40.599967457435376
              ],
              [
                -73.9173483850213,
                40.59994250140196
              ],
              [
                -73.9173338821583,
                40.5999360950405
              ],
              [
                -73.91732395216269,
                40.599928347545124
              ],
              [
                -73.91732032431774,
                40.59992087849325
              ],
              [
                -73.917318748244,
                40.59991409828968
              ],
              [
                -73.91731295816078,
                40.59990800125399
              ],
              [
                -73.91730057566032,
                40.59990387514625
              ],
              [
                -73.91728693511226,
                40.59989777195707
              ],
              [
                -73.91727721874068,
                40.59989323648137
              ],
              [
                -73.91726346642493,
                40.5998897121537
              ],
              [
                -73.91724668706972,
                40.599889095627184
              ],
              [
                -73.91723155883308,
                40.5998921600665
              ],
              [
                -73.9172176133197,
                40.5998998074473
              ],
              [
                -73.91719133448245,
                40.59992056716323
              ],
              [
                -73.9171737867899,
                40.59992911777356
              ],
              [
                -73.91716709068046,
                40.59992820828964
              ],
              [
                -73.91716075835816,
                40.59992372851747
              ],
              [
                -73.91715709354423,
                40.599917550078835
              ],
              [
                -73.91716038381828,
                40.599906381267665
              ],
              [
                -73.91716475112281,
                40.59989817732702
              ],
              [
                -73.91716562558177,
                40.599890629761006
              ],
              [
                -73.91715858719805,
                40.59987418337073
              ],
              [
                -73.91713887012357,
                40.59986135149609
              ],
              [
                -73.91711367578142,
                40.59985090312656
              ],
              [
                -73.91709128513465,
                40.599844491045154
              ],
              [
                -73.91707040741538,
                40.59983728512503
              ],
              [
                -73.91702558933866,
                40.59982577953689
              ],
              [
                -73.91700881093413,
                40.59982335198557
              ],
              [
                -73.916988318111,
                40.599825669694226
              ],
              [
                -73.91696976278125,
                40.59983413829388
              ],
              [
                -73.91695138598205,
                40.59984290723196
              ],
              [
                -73.91693467357098,
                40.59984520303617
              ],
              [
                -73.91691904905846,
                40.599843133142805
              ],
              [
                -73.91690389584288,
                40.599837301552164
              ],
              [
                -73.9168861909504,
                40.59982751727261
              ],
              [
                -73.91687133689634,
                40.59981213622615
              ],
              [
                -73.91686357759053,
                40.59979752826428
              ],
              [
                -73.91685865887294,
                40.59978520058676
              ],
              [
                -73.91685914082419,
                40.5997735898205
              ],
              [
                -73.9168646609248,
                40.599766293225706
              ],
              [
                -73.91687230406696,
                40.59975839372091
              ],
              [
                -73.9168770324865,
                40.599749504020124
              ],
              [
                -73.91687661605516,
                40.59973761869382
              ],
              [
                -73.91686262219469,
                40.599726190282404
              ],
              [
                -73.9168344055606,
                40.59971261055431
              ],
              [
                -73.91678498028277,
                40.59969959126211
              ],
              [
                -73.91677260011099,
                40.59969365410818
              ],
              [
                -73.91676213565182,
                40.59968258558028
              ],
              [
                -73.91674941026518,
                40.59966531096908
              ],
              [
                -73.91673777424802,
                40.59963897884077
              ],
              [
                -73.91672219926375,
                40.59962543739328
              ],
              [
                -73.91670898542586,
                40.59962339552032
              ],
              [
                -73.91669770973537,
                40.59962695672514
              ],
              [
                -73.91668199820637,
                40.59963583745148
              ],
              [
                -73.91666986159849,
                40.59963728381072
              ],
              [
                -73.91665164333688,
                40.59963438859402
              ],
              [
                -73.91663530587657,
                40.59962581198032
              ],
              [
                -73.91662775280886,
                40.5996180671538
              ],
              [
                -73.91661611022751,
                40.59959826814529
              ],
              [
                -73.91658101917103,
                40.59955421667036
              ],
              [
                -73.91657083730628,
                40.59954745651137
              ],
              [
                -73.91656158487505,
                40.59954566678059
              ],
              [
                -73.91654703433908,
                40.599547109866506
              ],
              [
                -73.91652506718975,
                40.59954841152992
              ],
              [
                -73.91650681425914,
                40.599544967272
              ],
              [
                -73.91648935966295,
                40.59953655651727
              ],
              [
                -73.91647730002268,
                40.599533061302694
              ],
              [
                -73.91645444113797,
                40.59952755508971
              ],
              [
                -73.91643680104468,
                40.59952301277696
              ],
              [
                -73.91642042780603,
                40.59951391559324
              ],
              [
                -73.91639933954947,
                40.59950349803017
              ],
              [
                -73.91638281598057,
                40.599498791840034
              ],
              [
                -73.91637158318203,
                40.599496889748785
              ],
              [
                -73.91635614296347,
                40.59949095032297
              ],
              [
                -73.9163513221006,
                40.599486746689
              ],
              [
                -73.91633776063104,
                40.59947573014741
              ],
              [
                -73.91632181903475,
                40.599466496057374
              ],
              [
                -73.91629727311577,
                40.599457283675974
              ],
              [
                -73.91628276233509,
                40.59945507780331
              ],
              [
                -73.91627624656071,
                40.59945395429647
              ],
              [
                -73.91624224253353,
                40.59946169014241
              ],
              [
                -73.91620786703439,
                40.599450878044635
              ],
              [
                -73.91616551967118,
                40.599421614484044
              ],
              [
                -73.91611372324502,
                40.59940332488126
              ],
              [
                -73.91606364579803,
                40.59939379075025
              ],
              [
                -73.91603690126853,
                40.59938432909901
              ],
              [
                -73.91594923488863,
                40.59931248795741
              ],
              [
                -73.91591433020129,
                40.599321795309486
              ],
              [
                -73.91588501406622,
                40.59932430019671
              ],
              [
                -73.91585358956556,
                40.59931384531451
              ],
              [
                -73.91581805281605,
                40.59928230955465
              ],
              [
                -73.91580410672464,
                40.599257794335635
              ],
              [
                -73.91580033757546,
                40.59925117048404
              ],
              [
                -73.91578387793759,
                40.59922618082099
              ],
              [
                -73.91573129686093,
                40.599202701085055
              ],
              [
                -73.91567311869402,
                40.599190883785916
              ],
              [
                -73.91563449423268,
                40.599180095873166
              ],
              [
                -73.91560394477511,
                40.59916184793783
              ],
              [
                -73.91556585249901,
                40.59912773010479
              ],
              [
                -73.9155344739184,
                40.59911079798628
              ],
              [
                -73.91544471569526,
                40.599042194935045
              ],
              [
                -73.91542612796813,
                40.59901882299184
              ],
              [
                -73.91539958417555,
                40.59896560873541
              ],
              [
                -73.91538171429588,
                40.598943115669016
              ],
              [
                -73.91537759243302,
                40.59893979857065
              ],
              [
                -73.91537293989077,
                40.59893691749504
              ],
              [
                -73.91536783815263,
                40.59893451807008
              ],
              [
                -73.9153623700253,
                40.598932642071404
              ],
              [
                -73.91535663173352,
                40.59893132257308
              ],
              [
                -73.91534470118378,
                40.59892998092272
              ],
              [
                -73.91533297981634,
                40.59892781652164
              ],
              [
                -73.91532156629518,
                40.598924845860466
              ],
              [
                -73.91531418704089,
                40.59892251226915
              ],
              [
                -73.91530715795862,
                40.59891961905267
              ],
              [
                -73.91530054890139,
                40.59891619742324
              ],
              [
                -73.91529442927782,
                40.598912282110305
              ],
              [
                -73.91528935512915,
                40.59890286540518
              ],
              [
                -73.91528527806723,
                40.598893172174044
              ],
              [
                -73.91528222417162,
                40.598883261741335
              ],
              [
                -73.91528104485967,
                40.59887957238083
              ],
              [
                -73.91527917322075,
                40.59887605439502
              ],
              [
                -73.91527664872287,
                40.59887278186069
              ],
              [
                -73.9152735282043,
                40.59886982283655
              ],
              [
                -73.91526987290355,
                40.59886724220175
              ],
              [
                -73.915265766048,
                40.59886509664248
              ],
              [
                -73.91526129154352,
                40.59886342776752
              ],
              [
                -73.91523041274706,
                40.59884891354834
              ],
              [
                -73.91522241638397,
                40.59884100814373
              ],
              [
                -73.91521502464106,
                40.59883276879627
              ],
              [
                -73.91520826276222,
                40.59882422031901
              ],
              [
                -73.91520285851922,
                40.59881843255223
              ],
              [
                -73.915196724909,
                40.59881308166579
              ],
              [
                -73.915189920761,
                40.59880822550064
              ],
              [
                -73.91518251480994,
                40.59880391017785
              ],
              [
                -73.91517458370498,
                40.5988001801492
              ],
              [
                -73.91516620168738,
                40.598797071823114
              ],
              [
                -73.91515832155062,
                40.59879457149558
              ],
              [
                -73.91515075811886,
                40.59879155876172
              ],
              [
                -73.91514356433069,
                40.598788057617604
              ],
              [
                -73.91513679883606,
                40.59878409407357
              ],
              [
                -73.91513297459494,
                40.5987811159303
              ],
              [
                -73.91512981376925,
                40.598777714427904
              ],
              [
                -73.91512739866897,
                40.598773975904685
              ],
              [
                -73.91512578543986,
                40.59876999472124
              ],
              [
                -73.9151250150574,
                40.59876586874486
              ],
              [
                -73.9151251089278,
                40.59876170219458
              ],
              [
                -73.91512606406265,
                40.598757598266225
              ],
              [
                -73.91512862910307,
                40.59872274134727
              ],
              [
                -73.91512669959891,
                40.5987137484606
              ],
              [
                -73.9151239136417,
                40.59870488561415
              ],
              [
                -73.91512029051009,
                40.59869620224337
              ],
              [
                -73.91511584905167,
                40.59868774108177
              ],
              [
                -73.91511061052513,
                40.59867955005853
              ],
              [
                -73.91510460476944,
                40.59867167024018
              ],
              [
                -73.91509155225391,
                40.59865114982516
              ],
              [
                -73.91507760103967,
                40.598630975697596
              ],
              [
                -73.91506276560158,
                40.59861117249451
              ],
              [
                -73.91505904404208,
                40.598602470955754
              ],
              [
                -73.91505463343391,
                40.598593957712914
              ],
              [
                -73.9150495502191,
                40.59858566628414
              ],
              [
                -73.91504381238073,
                40.59857762750775
              ],
              [
                -73.91503744295963,
                40.59856987038346
              ],
              [
                -73.91503046214223,
                40.59856242189821
              ],
              [
                -73.9150229010989,
                40.59855531273264
              ],
              [
                -73.91501380787247,
                40.59854583038715
              ],
              [
                -73.91500554115406,
                40.59853591609163
              ],
              [
                -73.91499813627117,
                40.598525615440444
              ],
              [
                -73.91499162482151,
                40.598514968496566
              ],
              [
                -73.91496788713279,
                40.59846348595248
              ],
              [
                -73.9149588192007,
                40.59844209782064
              ],
              [
                -73.91494867331002,
                40.59842098883165
              ],
              [
                -73.91493746765627,
                40.598400196357694
              ],
              [
                -73.9149310281033,
                40.598387475451446
              ],
              [
                -73.91492344431688,
                40.59837512544513
              ],
              [
                -73.91491475600024,
                40.59836320768375
              ],
              [
                -73.91490442500042,
                40.59835457479133
              ],
              [
                -73.91489473543446,
                40.59834552070196
              ],
              [
                -73.91488571429964,
                40.59833607358045
              ],
              [
                -73.91487739057096,
                40.5983262619288
              ],
              [
                -73.91487318314006,
                40.598320636865864
              ],
              [
                -73.91486965518266,
                40.59831474777747
              ],
              [
                -73.91486683411426,
                40.59830864025188
              ],
              [
                -73.91486473898978,
                40.598302365734845
              ],
              [
                -73.91486339040608,
                40.59829597366267
              ],
              [
                -73.9148627986268,
                40.5982895158096
              ],
              [
                -73.91486296908423,
                40.59828304093065
              ],
              [
                -73.91486389752937,
                40.598276606149966
              ],
              [
                -73.91486557862623,
                40.59827025920976
              ],
              [
                -73.91486766802393,
                40.59825895990486
              ],
              [
                -73.91486876431405,
                40.59824758079052
              ],
              [
                -73.91486886237944,
                40.59823617128399
              ],
              [
                -73.9148670538468,
                40.59823335210232
              ],
              [
                -73.91486454641684,
                40.598230862938124
              ],
              [
                -73.9148614415051,
                40.59822880203848
              ],
              [
                -73.91485786186766,
                40.598227250746106
              ],
              [
                -73.91485395050435,
                40.598226271822824
              ],
              [
                -73.91484986099398,
                40.59822590491934
              ],
              [
                -73.91484575771953,
                40.59822616305701
              ],
              [
                -73.91483829359821,
                40.59822392653901
              ],
              [
                -73.91483120919096,
                40.598221059554305
              ],
              [
                -73.91482459719681,
                40.59821760120608
              ],
              [
                -73.91481854174168,
                40.59821359260133
              ],
              [
                -73.91481312012188,
                40.598209086569184
              ],
              [
                -73.91480840413203,
                40.598204142468305
              ],
              [
                -73.91480445347518,
                40.59819882115612
              ],
              [
                -73.91479694380537,
                40.598194046198614
              ],
              [
                -73.914788972442,
                40.59818973043085
              ],
              [
                -73.91478058572818,
                40.59818589851413
              ],
              [
                -73.9147718352837,
                40.59818257360564
              ],
              [
                -73.91476277163233,
                40.59817977651639
              ],
              [
                -73.91475345299705,
                40.59817752270197
              ],
              [
                -73.91474393078971,
                40.59817582593816
              ],
              [
                -73.91473426565655,
                40.59817469732672
              ],
              [
                -73.91472785736643,
                40.598175405576384
              ],
              [
                -73.91472138415484,
                40.59817549190888
              ],
              [
                -73.91471495064236,
                40.598174954056546
              ],
              [
                -73.91470865484172,
                40.59817380097123
              ],
              [
                -73.91470260090514,
                40.598172053000994
              ],
              [
                -73.91469688616588,
                40.598169734845285
              ],
              [
                -73.91469159870707,
                40.598166886273795
              ],
              [
                -73.91468682814674,
                40.59816354940298
              ],
              [
                -73.9146826471602,
                40.5981597820844
              ],
              [
                -73.91466665632866,
                40.59814650407323
              ],
              [
                -73.91465127197414,
                40.59813281589602
              ],
              [
                -73.91463651165726,
                40.59811873448663
              ],
              [
                -73.91462239206152,
                40.59810427594023
              ],
              [
                -73.91460892986605,
                40.598089458865516
              ],
              [
                -73.91459614131674,
                40.59807429751458
              ],
              [
                -73.91458404111373,
                40.59805881166699
              ],
              [
                -73.9145726426407,
                40.598043018923704
              ],
              [
                -73.91456917723674,
                40.59803671322722
              ],
              [
                -73.91456641363808,
                40.598030209865136
              ],
              [
                -73.91456436959103,
                40.598023552241045
              ],
              [
                -73.91456305954142,
                40.598016787441125
              ],
              [
                -73.91456249507513,
                40.598009964057766
              ],
              [
                -73.91456285733523,
                40.598003736929805
              ],
              [
                -73.91456210042733,
                40.59799753091627
              ],
              [
                -73.9145602378312,
                40.59799146078436
              ],
              [
                -73.91455730478492,
                40.59798564182066
              ],
              [
                -73.91454978714879,
                40.59798420452612
              ],
              [
                -73.91454207391614,
                40.59798361746406
              ],
              [
                -73.91453433232283,
                40.597983895501564
              ],
              [
                -73.91452697332055,
                40.59798060947065
              ],
              [
                -73.91452008601821,
                40.597976775299365
              ],
              [
                -73.91451374091785,
                40.59797243106944
              ],
              [
                -73.91450800213941,
                40.597967621223866
              ],
              [
                -73.91450293005995,
                40.597962395228734
              ],
              [
                -73.91449857362512,
                40.59795680522226
              ],
              [
                -73.91449185269425,
                40.597949545702434
              ],
              [
                -73.91448573502264,
                40.597941981392914
              ],
              [
                -73.91448024628512,
                40.597934143640764
              ],
              [
                -73.91447540490731,
                40.59792606043718
              ],
              [
                -73.9144712319452,
                40.59791776530377
              ],
              [
                -73.91446774120679,
                40.597909287903505
              ],
              [
                -73.91446495001335,
                40.59790066108521
              ],
              [
                -73.91446286403465,
                40.597891919029024
              ],
              [
                -73.91446404312785,
                40.59788114710573
              ],
              [
                -73.91446411807439,
                40.59787033817419
              ],
              [
                -73.91446308483542,
                40.59785955740039
              ],
              [
                -73.91445870780481,
                40.59785426825938
              ],
              [
                -73.91445363534353,
                40.59784935018052
              ],
              [
                -73.91444792848162,
                40.597844857655964
              ],
              [
                -73.91444164298508,
                40.597840836797886
              ],
              [
                -73.91443484583249,
                40.597837330543335
              ],
              [
                -73.91442482452722,
                40.597830509331665
              ],
              [
                -73.91441564958077,
                40.59782302566856
              ],
              [
                -73.91440740025686,
                40.597814940426545
              ],
              [
                -73.91440557464115,
                40.597811412576156
              ],
              [
                -73.91440462445449,
                40.597807689368366
              ],
              [
                -73.9144045796461,
                40.59780389597014
              ],
              [
                -73.91440544444663,
                40.597800161549664
              ],
              [
                -73.91440718683135,
                40.59779660720647
              ],
              [
                -73.91440975037757,
                40.59779335653535
              ],
              [
                -73.91441304901245,
                40.597790516523325
              ],
              [
                -73.91441262819859,
                40.59778343072762
              ],
              [
                -73.91441142005344,
                40.59777639711732
              ],
              [
                -73.91440943330196,
                40.59776946780052
              ],
              [
                -73.91440668436618,
                40.597762691037566
              ],
              [
                -73.91440319054587,
                40.597756116262644
              ],
              [
                -73.9143982969349,
                40.59774713438988
              ],
              [
                -73.91439248443643,
                40.59773847566643
              ],
              [
                -73.91438579166037,
                40.59773019624311
              ],
              [
                -73.91437825854496,
                40.59772234490082
              ],
              [
                -73.91436993469344,
                40.59771497495033
              ],
              [
                -73.91436531702114,
                40.59770814033665
              ],
              [
                -73.91436156897954,
                40.59770100381299
              ],
              [
                -73.91435872213594,
                40.597693629064025
              ],
              [
                -73.91435680387828,
                40.59768608161435
              ],
              [
                -73.91435582906274,
                40.597678431167004
              ],
              [
                -73.91435674220051,
                40.5976723103285
              ],
              [
                -73.91435849718793,
                40.59766629783877
              ],
              [
                -73.91436107635855,
                40.59766045935589
              ],
              [
                -73.91436445039524,
                40.59765486136717
              ],
              [
                -73.91436858185395,
                40.597649567003245
              ],
              [
                -73.91437342274712,
                40.597644634360826
              ],
              [
                -73.91437892135427,
                40.597640119523504
              ],
              [
                -73.91438501497187,
                40.59763607320601
              ],
              [
                -73.91438836334468,
                40.59763300421926
              ],
              [
                -73.91439105128462,
                40.597629580750926
              ],
              [
                -73.91439301780787,
                40.59762588333749
              ],
              [
                -73.9143942168692,
                40.59762199872459
              ],
              [
                -73.91439461648184,
                40.59761801902976
              ],
              [
                -73.91439420905029,
                40.59761404040906
              ],
              [
                -73.9143930041245,
                40.59761015668588
              ],
              [
                -73.91439103037744,
                40.59760646069284
              ],
              [
                -73.9143878000206,
                40.5975978802859
              ],
              [
                -73.91438367903727,
                40.59758952370447
              ],
              [
                -73.9143786948325,
                40.59758144424333
              ],
              [
                -73.91437287920782,
                40.597573694027545
              ],
              [
                -73.9143662694607,
                40.59756632434871
              ],
              [
                -73.9143589072927,
                40.597559379968246
              ],
              [
                -73.9143508416565,
                40.597552907160384
              ],
              [
                -73.91434395226241,
                40.597549032434756
              ],
              [
                -73.9143365737823,
                40.59754572660846
              ],
              [
                -73.91432877979503,
                40.59754302709555
              ],
              [
                -73.91432066037476,
                40.59754096244276
              ],
              [
                -73.91431230076894,
                40.597539554157485
              ],
              [
                -73.91430379568328,
                40.59753881872816
              ],
              [
                -73.91428433609784,
                40.59753729846243
              ],
              [
                -73.9142649680164,
                40.59753520732196
              ],
              [
                -73.91424572089046,
                40.59753254532877
              ],
              [
                -73.91424177939865,
                40.59753196156355
              ],
              [
                -73.91423806326388,
                40.59753080216825
              ],
              [
                -73.91423471572932,
                40.59752911047262
              ],
              [
                -73.91423186594204,
                40.597526952579294
              ],
              [
                -73.91422962654339,
                40.59752441200196
              ],
              [
                -73.91422808180374,
                40.59752158697586
              ],
              [
                -73.91422729136438,
                40.59751858576938
              ],
              [
                -73.91422728518754,
                40.597515524167655
              ],
              [
                -73.91422806465675,
                40.59751252195489
              ],
              [
                -73.91423453499429,
                40.59750452843461
              ],
              [
                -73.9142403740324,
                40.59749625667946
              ],
              [
                -73.91424555953537,
                40.5974877363253
              ],
              [
                -73.91425007476137,
                40.59747899751535
              ],
              [
                -73.91425040281213,
                40.597472560113324
              ],
              [
                -73.9142499220557,
                40.597466128641315
              ],
              [
                -73.91424863681233,
                40.59745976207313
              ],
              [
                -73.91424655909593,
                40.59745351821498
              ],
              [
                -73.9142437083946,
                40.59744745387397
              ],
              [
                -73.91424010990987,
                40.59744162619629
              ],
              [
                -73.91423579808338,
                40.597436085633944
              ],
              [
                -73.91422997039156,
                40.597422182219574
              ],
              [
                -73.91422322106098,
                40.5974085233799
              ],
              [
                -73.91421556762899,
                40.59739514447635
              ],
              [
                -73.91420703005407,
                40.59738207835948
              ],
              [
                -73.91419763070647,
                40.597369362404834
              ],
              [
                -73.91418739482286,
                40.59735702628376
              ],
              [
                -73.91416961235151,
                40.59734002369386
              ],
              [
                -73.91415119772826,
                40.597323415998154
              ],
              [
                -73.91413216544208,
                40.597307215771714
              ],
              [
                -73.91411253415568,
                40.59729143827324
              ],
              [
                -73.91409231747883,
                40.597276096077415
              ],
              [
                -73.91407153341738,
                40.59726120209712
              ],
              [
                -73.91405368888863,
                40.59724601110429
              ],
              [
                -73.91403514020566,
                40.59723131814898
              ],
              [
                -73.9140159093215,
                40.59721714268064
              ],
              [
                -73.91399602346323,
                40.597203504822886
              ],
              [
                -73.91398287746675,
                40.597199395375256
              ],
              [
                -73.91396946373938,
                40.597195827515314
              ],
              [
                -73.91395581589491,
                40.5971928111524
              ],
              [
                -73.91394197787766,
                40.59719035519822
              ],
              [
                -73.91392798726092,
                40.59718846671731
              ],
              [
                -73.9139228280989,
                40.59718830420283
              ],
              [
                -73.91391778211195,
                40.59718746931054
              ],
              [
                -73.91391299827909,
                40.59718598795135
              ],
              [
                -73.91390862028274,
                40.59718390245039
              ],
              [
                -73.91390477485574,
                40.59718127555867
              ],
              [
                -73.9139015792635,
                40.5971781835899
              ],
              [
                -73.9138991250328,
                40.59717472109913
              ],
              [
                -73.91389566060403,
                40.597169958996986
              ],
              [
                -73.91389155335746,
                40.59716550500207
              ],
              [
                -73.91388684938416,
                40.59716140789974
              ],
              [
                -73.91388160467154,
                40.597157711624554
              ],
              [
                -73.913875874767,
                40.59715446078091
              ],
              [
                -73.91386972380056,
                40.59715169110045
              ],
              [
                -73.91386322491958,
                40.59714943329596
              ],
              [
                -73.91385644798187,
                40.59714771271643
              ],
              [
                -73.91384946966531,
                40.59714654918794
              ],
              [
                -73.91382245374955,
                40.597139015244394
              ],
              [
                -73.91379516585833,
                40.59713207900235
              ],
              [
                -73.91376762576394,
                40.59712574583744
              ],
              [
                -73.91373985785499,
                40.59712002079374
              ],
              [
                -73.91371188344324,
                40.59711490874536
              ],
              [
                -73.91366677328988,
                40.597110692329316
              ],
              [
                -73.91366644565318,
                40.597110648190466
              ],
              [
                -73.91366216133986,
                40.597110058619364
              ],
              [
                -73.91365806430431,
                40.59710894063396
              ],
              [
                -73.91365426308154,
                40.59710732279575
              ],
              [
                -73.91365086288786,
                40.59710525058437
              ],
              [
                -73.91364795661879,
                40.5971027798569
              ],
              [
                -73.91364562396872,
                40.597099977182324
              ],
              [
                -73.91364393033042,
                40.59709692151604
              ],
              [
                -73.91364291845582,
                40.59709369397452
              ],
              [
                -73.91364447990745,
                40.597084590099804
              ],
              [
                -73.91364696318834,
                40.597075603853234
              ],
              [
                -73.91365035020185,
                40.597066792851045
              ],
              [
                -73.91365462264105,
                40.59705820700347
              ],
              [
                -73.91365975450121,
                40.59704990073744
              ],
              [
                -73.91365990349821,
                40.59704328310831
              ],
              [
                -73.91365920125445,
                40.5970366879587
              ],
              [
                -73.91365765692197,
                40.597030176108476
              ],
              [
                -73.91365528558478,
                40.597023810225004
              ],
              [
                -73.9136521076035,
                40.5970176518067
              ],
              [
                -73.91364815411255,
                40.59701175850705
              ],
              [
                -73.91364346371596,
                40.59700619016292
              ],
              [
                -73.91363513536471,
                40.59698968662716
              ],
              [
                -73.91362754399032,
                40.596972976578456
              ],
              [
                -73.91362069747747,
                40.596956080461545
              ],
              [
                -73.91361707127864,
                40.596947274072846
              ],
              [
                -73.91361265469588,
                40.59693867901431
              ],
              [
                -73.91360746723453,
                40.59693033802055
              ],
              [
                -73.9136015376347,
                40.59692229081758
              ],
              [
                -73.91359489178382,
                40.5969145734435
              ],
              [
                -73.91358756193775,
                40.59690722612938
              ],
              [
                -73.91358092215793,
                40.59690483920523
              ],
              [
                -73.91357468332745,
                40.59690188985154
              ],
              [
                -73.91356892495125,
                40.59689842051282
              ],
              [
                -73.91356372390055,
                40.5968944717893
              ],
              [
                -73.91355914647922,
                40.59689009750761
              ],
              [
                -73.91355525415447,
                40.59688535266385
              ],
              [
                -73.91353599720709,
                40.59686277650728
              ],
              [
                -73.9135160129812,
                40.59684056920232
              ],
              [
                -73.91349531310574,
                40.596818746169596
              ],
              [
                -73.91348757199944,
                40.59681315139831
              ],
              [
                -73.91348040397632,
                40.59680712885302
              ],
              [
                -73.91347385295029,
                40.59680071106774
              ],
              [
                -73.91346795271964,
                40.59679393509194
              ],
              [
                -73.91346273839629,
                40.596786841326825
              ],
              [
                -73.91345823828297,
                40.5967794676552
              ],
              [
                -73.91344987598562,
                40.596766828645336
              ],
              [
                -73.9134423569443,
                40.59675388402633
              ],
              [
                -73.91343570243254,
                40.596740668325644
              ],
              [
                -73.91342993174986,
                40.59672721288587
              ],
              [
                -73.91342790179394,
                40.59671709443138
              ],
              [
                -73.91342688228153,
                40.596706887445116
              ],
              [
                -73.91342688038962,
                40.59669665039993
              ],
              [
                -73.91342789757822,
                40.59668644327255
              ],
              [
                -73.9134299249803,
                40.59667632418902
              ],
              [
                -73.9134318974596,
                40.59666974747053
              ],
              [
                -73.9134331081,
                40.59666306429953
              ],
              [
                -73.91343354606566,
                40.596656326099506
              ],
              [
                -73.91343321041076,
                40.596649584636296
              ],
              [
                -73.91343210282828,
                40.59664289033733
              ],
              [
                -73.91343023115952,
                40.59663629815819
              ],
              [
                -73.91342761073061,
                40.596629853008274
              ],
              [
                -73.9134184062952,
                40.596612103718705
              ],
              [
                -73.91340840923465,
                40.596594604622254
              ],
              [
                -73.9133976309511,
                40.596577376836244
              ],
              [
                -73.91338608350591,
                40.596560440808034
              ],
              [
                -73.91337377983909,
                40.596543817823395
              ],
              [
                -73.91335065170345,
                40.59652491732448
              ],
              [
                -73.91334225834795,
                40.59652083936401
              ],
              [
                -73.91333427965073,
                40.596516304360705
              ],
              [
                -73.91332675579793,
                40.59651133831195
              ],
              [
                -73.91331972741393,
                40.59650596805322
              ],
              [
                -73.91331323182914,
                40.5965002182397
              ],
              [
                -73.91330730701902,
                40.596494124751075
              ],
              [
                -73.91330197888098,
                40.59648771541725
              ],
              [
                -73.91329838432034,
                40.596481169179256
              ],
              [
                -73.91329574855533,
                40.596474362821574
              ],
              [
                -73.91329410422776,
                40.59646737711783
              ],
              [
                -73.91329346814813,
                40.59646029835845
              ],
              [
                -73.91329384966288,
                40.596453206629405
              ],
              [
                -73.91329178469968,
                40.59643554229221
              ],
              [
                -73.91329069144658,
                40.59641782708906
              ],
              [
                -73.91329057205931,
                40.59640009285237
              ],
              [
                -73.91329096739976,
                40.59639233972814
              ],
              [
                -73.9132904963672,
                40.59638459013847
              ],
              [
                -73.91328916240832,
                40.59637689819784
              ],
              [
                -73.91328697424066,
                40.59636932037021
              ],
              [
                -73.91327615678135,
                40.59632545086424
              ],
              [
                -73.91327310716622,
                40.59631797943744
              ],
              [
                -73.91326928805552,
                40.596310716506274
              ],
              [
                -73.91326472422526,
                40.59630370782495
              ],
              [
                -73.91325944330885,
                40.59629699881459
              ],
              [
                -73.91325348063961,
                40.596290628536266
              ],
              [
                -73.91324402023648,
                40.5962847344157
              ],
              [
                -73.91323516183053,
                40.596278315879594
              ],
              [
                -73.91322695723878,
                40.59627141200169
              ],
              [
                -73.9132194503669,
                40.596264061179326
              ],
              [
                -73.91321326987232,
                40.59625517880027
              ],
              [
                -73.91320776245195,
                40.59624604211734
              ],
              [
                -73.91320294366942,
                40.59623668196745
              ],
              [
                -73.91319883018978,
                40.596227127513636
              ],
              [
                -73.9131982336504,
                40.596216861538814
              ],
              [
                -73.91319873952403,
                40.59620659153841
              ],
              [
                -73.91320034508173,
                40.59619638803997
              ],
              [
                -73.9132009442157,
                40.59619197811667
              ],
              [
                -73.91320074910033,
                40.59618754564709
              ],
              [
                -73.91319976094162,
                40.59618317741244
              ],
              [
                -73.91319800271661,
                40.59617895015827
              ],
              [
                -73.91319550464623,
                40.596174947504515
              ],
              [
                -73.91319231257388,
                40.596171239010545
              ],
              [
                -73.91318604095544,
                40.596168217740626
              ],
              [
                -73.91318026188453,
                40.596164670968015
              ],
              [
                -73.91317505024338,
                40.59616064649524
              ],
              [
                -73.91317047431652,
                40.59615619530302
              ],
              [
                -73.91316659227054,
                40.59615137473006
              ],
              [
                -73.91316073754624,
                40.59614077039334
              ],
              [
                -73.91315586956061,
                40.59612987932162
              ],
              [
                -73.91315201615448,
                40.59611875598327
              ],
              [
                -73.91314515659491,
                40.596108163618496
              ],
              [
                -73.91313721910822,
                40.5960980155638
              ],
              [
                -73.91312825261531,
                40.596088380542874
              ],
              [
                -73.91312134243871,
                40.596081899809555
              ],
              [
                -73.91311386562552,
                40.596075795755745
              ],
              [
                -73.9131058559836,
                40.5960700975571
              ],
              [
                -73.9130973506202,
                40.5960648320462
              ],
              [
                -73.9130883928005,
                40.59606002354743
              ],
              [
                -73.91308088732873,
                40.5960539908373
              ],
              [
                -73.91307395466517,
                40.5960475698921
              ],
              [
                -73.91306763102718,
                40.59604079592028
              ],
              [
                -73.9130619464842,
                40.59603369960277
              ],
              [
                -73.91305692802226,
                40.596026316643616
              ],
              [
                -73.91305260328602,
                40.59601868341789
              ],
              [
                -73.91304898958688,
                40.5960108388054
              ],
              [
                -73.9130461086363,
                40.5960028185064
              ],
              [
                -73.91304397224778,
                40.59599466424475
              ],
              [
                -73.91304385180312,
                40.5959771178064
              ],
              [
                -73.91304461800443,
                40.595968973447455
              ],
              [
                -73.91304500854287,
                40.59596492791073
              ],
              [
                -73.91304459975052,
                40.59596088227176
              ],
              [
                -73.91304339787871,
                40.595956930519236
              ],
              [
                -73.91304143226363,
                40.59595316046054
              ],
              [
                -73.91303874499017,
                40.59594965823763
              ],
              [
                -73.9130354003512,
                40.59594650247041
              ],
              [
                -73.91303147033749,
                40.59594376675855
              ],
              [
                -73.91300999866758,
                40.59592324190677
              ],
              [
                -73.91298785020784,
                40.59590313770714
              ],
              [
                -73.91296504296281,
                40.595883467575156
              ],
              [
                -73.91295938648818,
                40.595877618529144
              ],
              [
                -73.91295297974408,
                40.595872237661645
              ],
              [
                -73.91294589167049,
                40.595867378968876
              ],
              [
                -73.91293819362873,
                40.59586309293119
              ],
              [
                -73.91292996621935,
                40.595859424004985
              ],
              [
                -73.91292471215944,
                40.59585812720045
              ],
              [
                -73.91291975751173,
                40.595856266886514
              ],
              [
                -73.91291520859951,
                40.59585388050289
              ],
              [
                -73.91291115854254,
                40.59585101888135
              ],
              [
                -73.91290769231603,
                40.595847741726985
              ],
              [
                -73.91290432766792,
                40.59584173744018
              ],
              [
                -73.91290021343968,
                40.595836009678685
              ],
              [
                -73.91289538757611,
                40.59583061693884
              ],
              [
                -73.91288989639123,
                40.5958256051585
              ],
              [
                -73.91287570551383,
                40.59581405414549
              ],
              [
                -73.91286098688525,
                40.59580289474907
              ],
              [
                -73.9128457585103,
                40.59579214005007
              ],
              [
                -73.91283861291132,
                40.59578573534673
              ],
              [
                -73.91283219292015,
                40.59577889980546
              ],
              [
                -73.91282654397065,
                40.59577167953136
              ],
              [
                -73.91282170555611,
                40.595764124813265
              ],
              [
                -73.91280846012137,
                40.59575110661751
              ],
              [
                -73.91279450379669,
                40.59573852982303
              ],
              [
                -73.91277985941181,
                40.59572641522045
              ],
              [
                -73.91276455309242,
                40.595714784273206
              ],
              [
                -73.91274502546243,
                40.59570017647381
              ],
              [
                -73.91272477246828,
                40.595686157153246
              ],
              [
                -73.91270381891817,
                40.59567274660089
              ],
              [
                -73.91268220126985,
                40.595659964109984
              ],
              [
                -73.9126185828551,
                40.595599282546296
              ],
              [
                -73.91260740442422,
                40.595588328352015
              ],
              [
                -73.91259695465172,
                40.59557696459905
              ],
              [
                -73.91258726075218,
                40.59556521995584
              ],
              [
                -73.91257834444754,
                40.59555312174647
              ],
              [
                -73.91257022789713,
                40.59554069863545
              ],
              [
                -73.91256284970204,
                40.595528180593945
              ],
              [
                -73.91255619048279,
                40.59551542922817
              ],
              [
                -73.91255026383445,
                40.59550246817019
              ],
              [
                -73.91254508158896,
                40.59548932440134
              ],
              [
                -73.91254065272486,
                40.595476022387885
              ],
              [
                -73.91253698820049,
                40.59546258492219
              ],
              [
                -73.91253125147179,
                40.595448765922704
              ],
              [
                -73.91252463292044,
                40.595435179117295
              ],
              [
                -73.91251715052985,
                40.59542185450746
              ],
              [
                -73.91250882029763,
                40.59540882695103
              ],
              [
                -73.91249966064814,
                40.595396125277055
              ],
              [
                -73.91248969835243,
                40.59538378133634
              ],
              [
                -73.91247895249303,
                40.59537182395806
              ],
              [
                -73.91246724474364,
                40.595356633033454
              ],
              [
                -73.91245633809108,
                40.59534109911518
              ],
              [
                -73.91244624568722,
                40.595325248348175
              ],
              [
                -73.91243521413207,
                40.595311619140816
              ],
              [
                -73.91242496499889,
                40.59529764139762
              ],
              [
                -73.91241551452005,
                40.59528333875266
              ],
              [
                -73.91240990085376,
                40.59527676397495
              ],
              [
                -73.91240511732963,
                40.5952698159044
              ],
              [
                -73.91240121133356,
                40.595262559578536
              ],
              [
                -73.912398213778,
                40.59525505298609
              ],
              [
                -73.91239615270095,
                40.59524736651102
              ],
              [
                -73.9123957728195,
                40.59524158729829
              ],
              [
                -73.91239616280816,
                40.59523580984513
              ],
              [
                -73.91239732039394,
                40.595230091109734
              ],
              [
                -73.9123992347286,
                40.59522449156218
              ],
              [
                -73.91240188485837,
                40.595219068145916
              ],
              [
                -73.91240524323416,
                40.595213879810636
              ],
              [
                -73.91240927462516,
                40.59520897729063
              ],
              [
                -73.91241223475028,
                40.59520497492656
              ],
              [
                -73.91241453674903,
                40.5952007288082
              ],
              [
                -73.91241614779253,
                40.59519630156653
              ],
              [
                -73.91241704471922,
                40.59519175852045
              ],
              [
                -73.91241721205836,
                40.59518716549724
              ],
              [
                -73.91241665059879,
                40.59518259168705
              ],
              [
                -73.91241536508893,
                40.59517810293239
              ],
              [
                -73.91241193979788,
                40.595161926373045
              ],
              [
                -73.91240732222434,
                40.595145921795016
              ],
              [
                -73.91240152834456,
                40.595130140809395
              ],
              [
                -73.91239553887561,
                40.59511854221082
              ],
              [
                -73.91238873303661,
                40.595107209193536
              ],
              [
                -73.91238112616925,
                40.59509617477248
              ],
              [
                -73.91237274394311,
                40.59508547247284
              ],
              [
                -73.91236361225184,
                40.59507513297205
              ],
              [
                -73.91235220897208,
                40.59506161337201
              ],
              [
                -73.91233993149896,
                40.59504854643836
              ],
              [
                -73.9123268081393,
                40.59503596620071
              ],
              [
                -73.91231287313701,
                40.595023903845416
              ],
              [
                -73.91230408481061,
                40.59501418814592
              ],
              [
                -73.91229590763923,
                40.595004168343955
              ],
              [
                -73.91228835808013,
                40.59499386405297
              ],
              [
                -73.9122814541188,
                40.59498330225926
              ],
              [
                -73.91227521023195,
                40.59497250441752
              ],
              [
                -73.91226963979554,
                40.59496149348983
              ],
              [
                -73.91226475530168,
                40.594950295955286
              ],
              [
                -73.9122605681491,
                40.59493893376909
              ],
              [
                -73.91225708819063,
                40.59492743525124
              ],
              [
                -73.91225432088841,
                40.594915824362694
              ],
              [
                -73.91225105686691,
                40.59489186132671
              ],
              [
                -73.9122469415631,
                40.59486797453656
              ],
              [
                -73.91224198154814,
                40.59484418158755
              ],
              [
                -73.91223617877866,
                40.594820499401614
              ],
              [
                -73.91222953960647,
                40.5947969450716
              ],
              [
                -73.91222206642739,
                40.59477353551941
              ],
              [
                -73.91221791556377,
                40.594757987974425
              ],
              [
                -73.91221294959934,
                40.594742579358574
              ],
              [
                -73.91220717597442,
                40.5947273346393
              ],
              [
                -73.91220060718297,
                40.59471227878772
              ],
              [
                -73.91219324868815,
                40.59469743643484
              ],
              [
                -73.91218511716173,
                40.59468283121475
              ],
              [
                -73.91217622268415,
                40.594668485583995
              ],
              [
                -73.91214527987934,
                40.594623089801686
              ],
              [
                -73.91214392062031,
                40.594618384204466
              ],
              [
                -73.91214169190117,
                40.59461387445433
              ],
              [
                -73.9121386426113,
                40.59460965222715
              ],
              [
                -73.91213482999255,
                40.5946058076975
              ],
              [
                -73.91213033657822,
                40.59460241782418
              ],
              [
                -73.91212525458131,
                40.59459955270503
              ],
              [
                -73.91211248448944,
                40.594593845769886
              ],
              [
                -73.91210029925591,
                40.5945874405159
              ],
              [
                -73.91208876235244,
                40.59458036932501
              ],
              [
                -73.91207793921957,
                40.594572671784384
              ],
              [
                -73.91207017020878,
                40.5945623530707
              ],
              [
                -73.912063112988,
                40.59455174340022
              ],
              [
                -73.91205678620321,
                40.59454086925701
              ],
              [
                -73.91205120454022,
                40.594529759969845
              ],
              [
                -73.912046385103,
                40.59451844436709
              ],
              [
                -73.91204234015626,
                40.594506954288846
              ],
              [
                -73.9120390802086,
                40.5944953205686
              ],
              [
                -73.91203648719127,
                40.59447490597496
              ],
              [
                -73.9120331732451,
                40.59445454963199
              ],
              [
                -73.912029141862,
                40.59443427013824
              ],
              [
                -73.91202439676188,
                40.594414080731404
              ],
              [
                -73.91201894144422,
                40.594393994481024
              ],
              [
                -73.91199890341385,
                40.594353356127385
              ],
              [
                -73.911995037946,
                40.59434177002283
              ],
              [
                -73.91199201065983,
                40.59433004031736
              ],
              [
                -73.91198982634339,
                40.59431820253105
              ],
              [
                -73.91198849462178,
                40.59430629017683
              ],
              [
                -73.91198802138084,
                40.594294339445824
              ],
              [
                -73.91198840283423,
                40.594282387861696
              ],
              [
                -73.91198964223281,
                40.59427046977031
              ],
              [
                -73.91199173403426,
                40.594258621521305
              ],
              [
                -73.91199467115827,
                40.59424687879309
              ],
              [
                -73.9119984476273,
                40.594235274416604
              ],
              [
                -73.91200013311465,
                40.594230228049106
              ],
              [
                -73.91200260886559,
                40.59422537544805
              ],
              [
                -73.9120058383051,
                40.5942207869476
              ],
              [
                -73.91200977299259,
                40.59421653119779
              ],
              [
                -73.9120143561458,
                40.59421266997332
              ],
              [
                -73.91201710402474,
                40.5942089452201
              ],
              [
                -73.9120191775284,
                40.594204974520096
              ],
              [
                -73.91202054052064,
                40.594200828710434
              ],
              [
                -73.9120211654341,
                40.59419658081293
              ],
              [
                -73.9120210442546,
                40.59419230872269
              ],
              [
                -73.91201820450559,
                40.59416134461793
              ],
              [
                -73.91201603617307,
                40.5941525547049
              ],
              [
                -73.9120146479311,
                40.59414367308049
              ],
              [
                -73.91201404829762,
                40.594134739958214
              ],
              [
                -73.91201423721662,
                40.59412579755544
              ],
              [
                -73.91201457177262,
                40.59412274676776
              ],
              [
                -73.9120152168351,
                40.59411688373554
              ],
              [
                -73.9120169791869,
                40.59410804020712
              ],
              [
                -73.91202433770972,
                40.594074574656005
              ],
              [
                -73.91203056523912,
                40.59404097538782
              ],
              [
                -73.91203565427318,
                40.59400726601857
              ],
              [
                -73.91204103569373,
                40.59399650771939
              ],
              [
                -73.9120456848716,
                40.593985555195474
              ],
              [
                -73.91204959056492,
                40.593974434070525
              ],
              [
                -73.91205274438181,
                40.59396317566611
              ],
              [
                -73.91205513595628,
                40.59395180795161
              ],
              [
                -73.91206934645322,
                40.5939069956867
              ],
              [
                -73.91207197762718,
                40.593890746853425
              ],
              [
                -73.91207380540214,
                40.59387443424667
              ],
              [
                -73.91207482931073,
                40.59385807981273
              ],
              [
                -73.91207504734324,
                40.59384170784156
              ],
              [
                -73.91207445836996,
                40.59382534228926
              ],
              [
                -73.91207382123703,
                40.59380549477044
              ],
              [
                -73.91207199728977,
                40.59378568906358
              ],
              [
                -73.91206899130812,
                40.593765967557175
              ],
              [
                -73.91203963699338,
                40.59371728033616
              ],
              [
                -73.91203299391199,
                40.59370617140748
              ],
              [
                -73.91202706693066,
                40.59369482982578
              ],
              [
                -73.91202187051887,
                40.593683281736865
              ],
              [
                -73.91201741606962,
                40.5936715536191
              ],
              [
                -73.91201371717094,
                40.59365967413067
              ],
              [
                -73.91201205074663,
                40.59364059143211
              ],
              [
                -73.91201131619253,
                40.5936214744335
              ],
              [
                -73.91201151347225,
                40.59360234993944
              ],
              [
                -73.9120126440893,
                40.59358324425311
              ],
              [
                -73.9120147064696,
                40.593564183172916
              ],
              [
                -73.91201295164508,
                40.593547143761754
              ],
              [
                -73.91201202628056,
                40.59353006712406
              ],
              [
                -73.91201193232597,
                40.59351297403495
              ],
              [
                -73.91201267282268,
                40.593495890966494
              ],
              [
                -73.91201432846694,
                40.593487626002094
              ],
              [
                -73.91201671339157,
                40.59347946261628
              ],
              [
                -73.91201981347797,
                40.59347144083774
              ],
              [
                -73.91202361395224,
                40.59346359801385
              ],
              [
                -73.91202809960842,
                40.59345596596393
              ],
              [
                -73.9120297533253,
                40.59344966243115
              ],
              [
                -73.91203068499284,
                40.59344327324081
              ],
              [
                -73.91203088861565,
                40.59343684563139
              ],
              [
                -73.91203036412719,
                40.593430430029336
              ],
              [
                -73.91202911454454,
                40.59342407217239
              ],
              [
                -73.91203260974942,
                40.59340739244514
              ],
              [
                -73.91203694234129,
                40.593390827613746
              ],
              [
                -73.91204210635345,
                40.59337440179784
              ],
              [
                -73.91204230481588,
                40.593364382858205
              ],
              [
                -73.9120415573729,
                40.59335437910986
              ],
              [
                -73.91203986813295,
                40.59334444165229
              ],
              [
                -73.91203724472012,
                40.59333462259307
              ],
              [
                -73.91203370135146,
                40.593324973541854
              ],
              [
                -73.91202925752236,
                40.59331554343209
              ],
              [
                -73.91199823503203,
                40.5932759665064
              ],
              [
                -73.91199399066632,
                40.593268776247676
              ],
              [
                -73.91199048106598,
                40.593261359884295
              ],
              [
                -73.91198772727473,
                40.59325375847721
              ],
              [
                -73.91198574768993,
                40.593246019953924
              ],
              [
                -73.91198455038396,
                40.593238188715944
              ],
              [
                -73.91198414452755,
                40.593230309668144
              ],
              [
                -73.91198576718821,
                40.59322238995673
              ],
              [
                -73.91198819074486,
                40.593214591144715
              ],
              [
                -73.91199140041407,
                40.59320695811859
              ],
              [
                -73.91199538031132,
                40.59319953710441
              ],
              [
                -73.9120001029016,
                40.593192376161646
              ],
              [
                -73.91200554044276,
                40.593185514471
              ],
              [
                -73.91201166013033,
                40.59317899640249
              ],
              [
                -73.91201842675004,
                40.59317286096334
              ],
              [
                -73.91202166563247,
                40.59315772968093
              ],
              [
                -73.91202384383816,
                40.59314248785471
              ],
              [
                -73.91202495141847,
                40.59312718037485
              ],
              [
                -73.91202498832092,
                40.593111847951036
              ],
              [
                -73.91202508652567,
                40.59310403194515
              ],
              [
                -73.91202436365306,
                40.5930962339064
              ],
              [
                -73.91202282754696,
                40.593088505607476
              ],
              [
                -73.91202048561775,
                40.59308089412948
              ],
              [
                -73.91201735581953,
                40.5930734505828
              ],
              [
                -73.91201345501351,
                40.59306622105077
              ],
              [
                -73.91200884617297,
                40.593057447361026
              ],
              [
                -73.91200328706624,
                40.593049000128296
              ],
              [
                -73.91199681783436,
                40.593040938018405
              ],
              [
                -73.91198948257448,
                40.59303331886241
              ],
              [
                -73.91198437819286,
                40.59302952443413
              ],
              [
                -73.9119798958833,
                40.59302529892666
              ],
              [
                -73.91197609314611,
                40.593020702359425
              ],
              [
                -73.9119730246225,
                40.59301579609012
              ],
              [
                -73.9119707313167,
                40.59301065000998
              ],
              [
                -73.91196924544082,
                40.59300533500876
              ],
              [
                -73.91196675991245,
                40.59299796600214
              ],
              [
                -73.91196344868533,
                40.59299078801648
              ],
              [
                -73.91195933586212,
                40.59298385534954
              ],
              [
                -73.91195445566146,
                40.59297721795141
              ],
              [
                -73.91194884120097,
                40.59297092694358
              ],
              [
                -73.91191233423666,
                40.592939470460855
              ],
              [
                -73.91190623375056,
                40.59292994358427
              ],
              [
                -73.91190081178132,
                40.59292018000606
              ],
              [
                -73.9118960849921,
                40.59291020888914
              ],
              [
                -73.91189206631022,
                40.59290005939372
              ],
              [
                -73.91188876800659,
                40.592889759003825
              ],
              [
                -73.91188620080706,
                40.59287933989358
              ],
              [
                -73.91188660880523,
                40.59278848787402
              ],
              [
                -73.9118877426437,
                40.59277345265022
              ],
              [
                -73.91188966274626,
                40.592758464267426
              ],
              [
                -73.91189236578171,
                40.592743548354925
              ],
              [
                -73.91189584754873,
                40.59272872417544
              ],
              [
                -73.91190010295739,
                40.592714018026946
              ],
              [
                -73.91190512494188,
                40.592699454698426
              ],
              [
                -73.91191090622381,
                40.59268505378503
              ],
              [
                -73.91191657786295,
                40.59267380066374
              ],
              [
                -73.91192148125323,
                40.59266233770897
              ],
              [
                -73.91192560206754,
                40.592650696908194
              ],
              [
                -73.91192893169222,
                40.59263891109059
              ],
              [
                -73.9119314584418,
                40.59262700939735
              ],
              [
                -73.91193317875779,
                40.592615024158995
              ],
              [
                -73.91193145554234,
                40.59260188016147
              ],
              [
                -73.91192892700222,
                40.59258881294534
              ],
              [
                -73.91192559639886,
                40.5925758494851
              ],
              [
                -73.91190838846245,
                40.59251871750039
              ],
              [
                -73.91190562686765,
                40.59250989864904
              ],
              [
                -73.9119019619985,
                40.59250127377429
              ],
              [
                -73.91189741400537,
                40.592492894825476
              ],
              [
                -73.9118920142423,
                40.59248481711113
              ],
              [
                -73.91188579473291,
                40.592477087899134
              ],
              [
                -73.91187942178374,
                40.59246872631347
              ],
              [
                -73.91187382854676,
                40.59246004618197
              ],
              [
                -73.91186904133892,
                40.592451088904774
              ],
              [
                -73.91186508779114,
                40.592441899568435
              ],
              [
                -73.91186198256916,
                40.592432522077075
              ],
              [
                -73.9118610947081,
                40.59242386934313
              ],
              [
                -73.91186108023207,
                40.59241518963674
              ],
              [
                -73.91186193995095,
                40.59240653539508
              ],
              [
                -73.91186366808383,
                40.59239795704029
              ],
              [
                -73.91186625423705,
                40.59238950331526
              ],
              [
                -73.91186968361524,
                40.59238122765037
              ],
              [
                -73.91187270133815,
                40.59237714023845
              ],
              [
                -73.9118750381175,
                40.59237280251793
              ],
              [
                -73.9118766600256,
                40.59236827812406
              ],
              [
                -73.91187754125806,
                40.592363636226565
              ],
              [
                -73.91187766985718,
                40.59235894600619
              ],
              [
                -73.91187584440306,
                40.592356176183856
              ],
              [
                -73.91187332558673,
                40.59235374072081
              ],
              [
                -73.91187021744544,
                40.59235173954454
              ],
              [
                -73.91186664535698,
                40.59235025467265
              ],
              [
                -73.9118627564853,
                40.59234934585878
              ],
              [
                -73.91185870813305,
                40.592349050749995
              ],
              [
                -73.91185048565515,
                40.592349177293464
              ],
              [
                -73.91184229063613,
                40.592348647309905
              ],
              [
                -73.91183421361002,
                40.592347467402426
              ],
              [
                -73.91182634159016,
                40.59234564869474
              ],
              [
                -73.91181966657005,
                40.59234354543511
              ],
              [
                -73.91181335161605,
                40.592340874861954
              ],
              [
                -73.91180747755767,
                40.592337671045954
              ],
              [
                -73.91180212323454,
                40.59233397626503
              ],
              [
                -73.91179735715085,
                40.59232983781491
              ],
              [
                -73.91179324384265,
                40.592325312873065
              ],
              [
                -73.91178691570899,
                40.592316318222956
              ],
              [
                -73.91178126787422,
                40.59230706392187
              ],
              [
                -73.91177631875895,
                40.59229758013916
              ],
              [
                -73.9117720834846,
                40.59228789871696
              ],
              [
                -73.91176857871429,
                40.592278048818486
              ],
              [
                -73.9117658107778,
                40.59226806328435
              ],
              [
                -73.91174298986893,
                40.5922087294806
              ],
              [
                -73.91173517224131,
                40.592165109723055
              ],
              [
                -73.91172811225438,
                40.59212141365091
              ],
              [
                -73.91172181055562,
                40.592077648970985
              ],
              [
                -73.911721688759,
                40.59206638035073
              ],
              [
                -73.91172195119445,
                40.59205903824359
              ],
              [
                -73.91171148620498,
                40.592046085713186
              ],
              [
                -73.911701055142,
                40.592041217169175
              ],
              [
                -73.9116899148627,
                40.59202923143223
              ],
              [
                -73.91168840929198,
                40.59202255707856
              ],
              [
                -73.9116843620899,
                40.59200462497694
              ],
              [
                -73.91167975585125,
                40.59196240202968
              ],
              [
                -73.911677119813,
                40.59193712032687
              ],
              [
                -73.91168012436576,
                40.59192171613446
              ],
              [
                -73.91168026066258,
                40.591921018982006
              ],
              [
                -73.91169903912827,
                40.59189887413861
              ],
              [
                -73.91171505699691,
                40.59189112196329
              ],
              [
                -73.91172153092963,
                40.59188072302918
              ],
              [
                -73.9117240825571,
                40.591863141778646
              ],
              [
                -73.91172068205198,
                40.59184127189481
              ],
              [
                -73.91171221410116,
                40.591817341191984
              ],
              [
                -73.91170750309422,
                40.59179617894988
              ],
              [
                -73.91170706136631,
                40.59177241306372
              ],
              [
                -73.9117097952771,
                40.59174062814445
              ],
              [
                -73.9117037400627,
                40.5917120924049
              ],
              [
                -73.9116970064868,
                40.59168452128027
              ],
              [
                -73.91168364528845,
                40.591653630828205
              ],
              [
                -73.911669007738,
                40.59163153049636
              ],
              [
                -73.9116543903509,
                40.59160848212957
              ],
              [
                -73.91164618788592,
                40.59159554506943
              ],
              [
                -73.91159882997333,
                40.591508600613395
              ],
              [
                -73.91159103013511,
                40.591493009021214
              ],
              [
                -73.91159070977163,
                40.59147487774219
              ],
              [
                -73.91159418044754,
                40.59146760176437
              ],
              [
                -73.9116006977331,
                40.59145597923551
              ],
              [
                -73.91160429919019,
                40.5914450324546
              ],
              [
                -73.91160144478978,
                40.59142586825051
              ],
              [
                -73.91159055475737,
                40.59141584681003
              ],
              [
                -73.91156823019618,
                40.59139309941538
              ],
              [
                -73.91155194294743,
                40.59137218859724
              ],
              [
                -73.91151015669493,
                40.591291236895636
              ],
              [
                -73.91150304364807,
                40.5912653725903
              ],
              [
                -73.91149998055923,
                40.59124302097649
              ],
              [
                -73.91150127545596,
                40.59121561567756
              ],
              [
                -73.91150421772527,
                40.59118701984338
              ],
              [
                -73.91149942867085,
                40.5911590005544
              ],
              [
                -73.91149028340267,
                40.59113603327594
              ],
              [
                -73.91148579005616,
                40.59110875301851
              ],
              [
                -73.91149952765117,
                40.59105662393773
              ],
              [
                -73.91152817634813,
                40.59091932083587
              ],
              [
                -73.91153153170995,
                40.590887432696064
              ],
              [
                -73.91153412800526,
                40.590807943013964
              ],
              [
                -73.91153745141267,
                40.59078341474937
              ],
              [
                -73.91154559397185,
                40.5907613533788
              ],
              [
                -73.91155792085337,
                40.590739057001514
              ],
              [
                -73.91156021933259,
                40.590728266695926
              ],
              [
                -73.91155706528342,
                40.59071353676129
              ],
              [
                -73.91155361662597,
                40.59069193689023
              ],
              [
                -73.91155657497599,
                40.590677713410656
              ],
              [
                -73.91156242154459,
                40.590665214858404
              ],
              [
                -73.91157085091264,
                40.59065125140629
              ],
              [
                -73.91157219974197,
                40.590637022834535
              ],
              [
                -73.91157093448166,
                40.59063162339421
              ],
              [
                -73.91156200782149,
                40.590612216790255
              ],
              [
                -73.911563334329,
                40.59060289647684
              ],
              [
                -73.91157038299714,
                40.59058590118818
              ],
              [
                -73.9115714665765,
                40.590583288396324
              ],
              [
                -73.91157925684544,
                40.59056785052824
              ],
              [
                -73.91159189283387,
                40.590548744311604
              ],
              [
                -73.91159162634092,
                40.59053573980642
              ],
              [
                -73.91158480689317,
                40.59051074202458
              ],
              [
                -73.91158031302237,
                40.59047240851566
              ],
              [
                -73.9115798597866,
                40.59042799780452
              ],
              [
                -73.91159440572522,
                40.590338969310366
              ],
              [
                -73.91160104928282,
                40.590291140763306
              ],
              [
                -73.91160082544711,
                40.59026832308974
              ],
              [
                -73.91159414412351,
                40.59025039381368
              ],
              [
                -73.91158392479421,
                40.59023172133506
              ],
              [
                -73.91157817747673,
                40.59022091059742
              ],
              [
                -73.9115701795048,
                40.590209850016485
              ],
              [
                -73.91157259435383,
                40.59017207081653
              ],
              [
                -73.91158038765167,
                40.59015663244755
              ],
              [
                -73.91159136405783,
                40.59014856396673
              ],
              [
                -73.91159973505589,
                40.59014809445611
              ],
              [
                -73.91161168877787,
                40.59013732866452
              ],
              [
                -73.91161981427781,
                40.59011894638712
              ],
              [
                -73.91164502688518,
                40.59009422855554
              ],
              [
                -73.91166124503799,
                40.59006482604859
              ],
              [
                -73.91167938675305,
                40.59003739097523
              ],
              [
                -73.91169362007794,
                40.59002074363032
              ],
              [
                -73.91169946208502,
                40.590009471381954
              ],
              [
                -73.9116988350344,
                40.59000554417884
              ],
              [
                -73.91168470756043,
                40.58999765692762
              ],
              [
                -73.91167767127622,
                40.589987579652856
              ],
              [
                -73.91168189842355,
                40.58997753030133
              ],
              [
                -73.91170351762428,
                40.58996433454363
              ],
              [
                -73.91171803975364,
                40.589955783610165
              ],
              [
                -73.9117219311665,
                40.58994867866315
              ],
              [
                -73.91171620703926,
                40.58993296134929
              ],
              [
                -73.91170756485637,
                40.58992165384687
              ],
              [
                -73.9116960606178,
                40.58990248684887
              ],
              [
                -73.911688730822,
                40.58988553863398
              ],
              [
                -73.91168717578466,
                40.58987326708404
              ],
              [
                -73.9116955715619,
                40.589866662491836
              ],
              [
                -73.9117123115081,
                40.589865723453165
              ],
              [
                -73.91171584839755,
                40.58986646882848
              ],
              [
                -73.91172134034741,
                40.58986182058934
              ],
              [
                -73.91171911495387,
                40.589855435674934
              ],
              [
                -73.91170952314022,
                40.589840689739994
              ],
              [
                -73.9116973704343,
                40.58982250262557
              ],
              [
                -73.9116871419636,
                40.5898062834486
              ],
              [
                -73.9116801316113,
                40.58979007277551
              ],
              [
                -73.91167636550992,
                40.58976773486286
              ],
              [
                -73.91167493064509,
                40.58972724793808
              ],
              [
                -73.91169302433782,
                40.58963651100751
              ],
              [
                -73.91169762851035,
                40.58961370474932
              ],
              [
                -73.91170314503307,
                40.58960292244128
              ],
              [
                -73.9117192808355,
                40.58959314793992
              ],
              [
                -73.91172411356864,
                40.58959193354666
              ],
              [
                -73.91173250930531,
                40.5895853299567
              ],
              [
                -73.91173189429051,
                40.589578948959876
              ],
              [
                -73.91172902556457,
                40.589572317450646
              ],
              [
                -73.91171909424732,
                40.58956174122944
              ],
              [
                -73.91170915177597,
                40.58955361997432
              ],
              [
                -73.91170276783195,
                40.58954133650486
              ],
              [
                -73.91170091348322,
                40.58952342082706
              ],
              [
                -73.91169740685693,
                40.589440970887765
              ],
              [
                -73.91169295508318,
                40.589353857878244
              ],
              [
                -73.9116991629254,
                40.58933228134486
              ],
              [
                -73.91171142607773,
                40.58932470637737
              ],
              [
                -73.9117191842418,
                40.589316628877455
              ],
              [
                -73.91172131485254,
                40.58930792401164
              ],
              [
                -73.91172344502144,
                40.58929922082089
              ],
              [
                -73.91171803818274,
                40.58928423937157
              ],
              [
                -73.91170456851212,
                40.58927291877753
              ],
              [
                -73.91169299451158,
                40.589269699517395
              ],
              [
                -73.91169217786889,
                40.58926689813332
              ],
              [
                -73.91168855492587,
                40.58925447622736
              ],
              [
                -73.91169119529174,
                40.589239516876425
              ],
              [
                -73.91170191749868,
                40.58921599008429
              ],
              [
                -73.91172949088498,
                40.58916575998968
              ],
              [
                -73.91175419016415,
                40.589110125360335
              ],
              [
                -73.91179582600458,
                40.58900816057293
              ],
              [
                -73.9118134253641,
                40.58895717009086
              ],
              [
                -73.9118407333424,
                40.5888939361326
              ],
              [
                -73.91184394026183,
                40.588896398427345
              ],
              [
                -73.91184811016666,
                40.58889984301534
              ],
              [
                -73.91185520460894,
                40.588896425664586
              ],
              [
                -73.91185779782703,
                40.588892506963326
              ],
              [
                -73.91185621380923,
                40.588886368978756
              ],
              [
                -73.91185049421847,
                40.58886942418557
              ],
              [
                -73.91185216172038,
                40.588855933652034
              ],
              [
                -73.91185931058577,
                40.58884024933746
              ],
              [
                -73.91186737695496,
                40.58883536266104
              ],
              [
                -73.9118728889092,
                40.5888258078285
              ],
              [
                -73.91187843365023,
                40.58880889144386
              ],
              [
                -73.91187556560362,
                40.58880225893319
              ],
              [
                -73.91186785886741,
                40.588798069980626
              ],
              [
                -73.91186370033843,
                40.58879217092882
              ],
              [
                -73.91186181002288,
                40.58878284093906
              ],
              [
                -73.91186659394712,
                40.58871832434472
              ],
              [
                -73.91188405404365,
                40.58862488698604
              ],
              [
                -73.91190444550037,
                40.58852336036855
              ],
              [
                -73.91191080135235,
                40.58846743387369
              ],
              [
                -73.91191138386733,
                40.58840683122709
              ],
              [
                -73.91190189541219,
                40.5882932073502
              ],
              [
                -73.91189192575368,
                40.58821687665698
              ],
              [
                -73.91188215862513,
                40.58816827022503
              ],
              [
                -73.91187205689816,
                40.58812260870256
              ],
              [
                -73.911845001611,
                40.58805261359306
              ],
              [
                -73.91182775574835,
                40.587992519956764
              ],
              [
                -73.9118037815627,
                40.58790897675168
              ],
              [
                -73.91177111790168,
                40.58779627473994
              ],
              [
                -73.91174031291423,
                40.587700263761086
              ],
              [
                -73.91173099495536,
                40.58767108319774
              ],
              [
                -73.91170855730851,
                40.587600813836396
              ],
              [
                -73.91167832660508,
                40.58752099700941
              ],
              [
                -73.91166871545042,
                40.58749837298169
              ],
              [
                -73.91164837700495,
                40.58745050340242
              ],
              [
                -73.91161178372174,
                40.587353495322986
              ],
              [
                -73.9116019490053,
                40.587320837779906
              ],
              [
                -73.91159461349682,
                40.58730511703834
              ],
              [
                -73.91157988212737,
                40.587288395480456
              ],
              [
                -73.9115767744986,
                40.58726262488539
              ],
              [
                -73.91158104928806,
                40.58724153538241
              ],
              [
                -73.91158315677313,
                40.5872363191326
              ],
              [
                -73.9115924344312,
                40.58721334843776
              ],
              [
                -73.91161866478295,
                40.58717611945393
              ],
              [
                -73.91162872325218,
                40.58715725114704
              ],
              [
                -73.91163069835724,
                40.58714695094648
              ],
              [
                -73.91163434544393,
                40.58712193373372
              ],
              [
                -73.9116606646332,
                40.587063849907224
              ],
              [
                -73.9116762087755,
                40.5870415613444
              ],
              [
                -73.91171623638176,
                40.58701393601307
              ],
              [
                -73.91175461101325,
                40.58699612042423
              ],
              [
                -73.91178779388788,
                40.586988597698166
              ],
              [
                -73.91180968955037,
                40.586985954306115
              ],
              [
                -73.91182673638393,
                40.58698820491201
              ],
              [
                -73.9118366883188,
                40.586993872865335
              ],
              [
                -73.91184339080381,
                40.58700689387197
              ],
              [
                -73.91184694361347,
                40.58701510905807
              ],
              [
                -73.9118532688722,
                40.58702973654936
              ],
              [
                -73.91186318039331,
                40.58704521934865
              ],
              [
                -73.9118731269851,
                40.58705211428022
              ],
              [
                -73.91187505429691,
                40.5870528557359
              ],
              [
                -73.91188248894109,
                40.58704526766378
              ],
              [
                -73.91188736476136,
                40.58703423891996
              ],
              [
                -73.91188936124115,
                40.58701903095658
              ],
              [
                -73.91189636497208,
                40.586962125574345
              ],
              [
                -73.91191493562087,
                40.58690966523873
              ],
              [
                -73.91193704810674,
                40.586856723454495
              ],
              [
                -73.91195662718775,
                40.58679420692518
              ],
              [
                -73.91196717937,
                40.58673559260823
              ],
              [
                -73.91197278431736,
                40.58669735747132
              ],
              [
                -73.91191811783656,
                40.58642796543401
              ],
              [
                -73.91183849093946,
                40.586349496231
              ],
              [
                -73.911783401795,
                40.58631186553526
              ],
              [
                -73.9117278225857,
                40.58626100614427
              ],
              [
                -73.91168200873301,
                40.586216290501035
              ],
              [
                -73.9116207890572,
                40.58616588255846
              ],
              [
                -73.91155384340597,
                40.58611989980266
              ],
              [
                -73.91148171990072,
                40.586078718712294
              ],
              [
                -73.91140500905156,
                40.58604267645197
              ],
              [
                -73.91132433892447,
                40.586012068112495
              ],
              [
                -73.9112403699983,
                40.58598714429474
              ],
              [
                -73.91115378975734,
                40.58596810905845
              ],
              [
                -73.91106530706425,
                40.58595511825114
              ],
              [
                -73.91097564635562,
                40.585948278232635
              ],
              [
                -73.91088554171192,
                40.58594764500442
              ],
              [
                -73.91078707408074,
                40.585975354188705
              ],
              [
                -73.91069745365972,
                40.586002458682756
              ],
              [
                -73.91058545398192,
                40.586039195892155
              ],
              [
                -73.910444885584,
                40.58607772993131
              ],
              [
                -73.9103740498895,
                40.586094774764355
              ],
              [
                -73.91021202252327,
                40.58613376555101
              ],
              [
                -73.91002610774325,
                40.58618044593877
              ],
              [
                -73.9098759422859,
                40.5862238168853
              ],
              [
                -73.90964850230635,
                40.58628011260677
              ],
              [
                -73.90951334397168,
                40.586321335257146
              ],
              [
                -73.90931521062699,
                40.58638768338272
              ],
              [
                -73.90910459453215,
                40.5864620246764
              ],
              [
                -73.90900182081849,
                40.586499228178525
              ],
              [
                -73.90889649531321,
                40.58653738414824
              ],
              [
                -73.90864547838636,
                40.586638956636016
              ],
              [
                -73.90846615145995,
                40.586697394105244
              ],
              [
                -73.90830027751733,
                40.58674515750672
              ],
              [
                -73.90810885035972,
                40.58679650530153
              ],
              [
                -73.90795644961685,
                40.586828728060624
              ],
              [
                -73.90780535419943,
                40.58685414075541
              ],
              [
                -73.90761945884292,
                40.5868814098163
              ],
              [
                -73.90746679470584,
                40.586900732784976
              ],
              [
                -73.90734765801528,
                40.58691770836381
              ],
              [
                -73.90708063256595,
                40.586956451872
              ],
              [
                -73.90683620925472,
                40.586993586042155
              ],
              [
                -73.90665451225826,
                40.58700499090303
              ],
              [
                -73.90647377807485,
                40.587020989415
              ],
              [
                -73.90643924223771,
                40.587033856374696
              ],
              [
                -73.90641708967891,
                40.58705202482791
              ],
              [
                -73.9064092070858,
                40.58706896317275
              ],
              [
                -73.90641557145698,
                40.587077415190976
              ],
              [
                -73.90641463144355,
                40.58708128569556
              ],
              [
                -73.90640863377207,
                40.58709169243014
              ],
              [
                -73.90638772801907,
                40.58710187945029
              ],
              [
                -73.90633517612734,
                40.58713192625079
              ],
              [
                -73.90634513023984,
                40.587182889448776
              ],
              [
                -73.90643776678166,
                40.5872795292213
              ],
              [
                -73.90645189748874,
                40.58730333661102
              ],
              [
                -73.90647062557228,
                40.5873252225155
              ],
              [
                -73.90649351286079,
                40.58734467487575
              ],
              [
                -73.90652002386877,
                40.58736123856922
              ],
              [
                -73.90654953832612,
                40.587374526058746
              ],
              [
                -73.90658136569024,
                40.58738422645951
              ],
              [
                -73.90661476130273,
                40.587390112812926
              ],
              [
                -73.90664894381285,
                40.587392047396925
              ],
              [
                -73.90668320402682,
                40.58739695785736
              ],
              [
                -73.9067180522822,
                40.587397660293924
              ],
              [
                -73.90675260499656,
                40.587394136896236
              ],
              [
                -73.90678349384332,
                40.587387204980324
              ],
              [
                -73.90681271489419,
                40.587376893944146
              ],
              [
                -73.90686281021054,
                40.58737594292347
              ],
              [
                -73.90691239343307,
                40.58738147210735
              ],
              [
                -73.90696003272562,
                40.587393321826745
              ],
              [
                -73.90700435238543,
                40.58741114989219
              ],
              [
                -73.90704407257007,
                40.58743444147446
              ],
              [
                -73.90707804625505,
                40.58746252397109
              ],
              [
                -73.90710529235673,
                40.5874945864288
              ],
              [
                -73.90712502406382,
                40.58752970296232
              ],
              [
                -73.90709859479351,
                40.58759526633552
              ],
              [
                -73.90706463708528,
                40.58765878273197
              ],
              [
                -73.90702341893196,
                40.58771975084335
              ],
              [
                -73.90697526563262,
                40.5877776894727
              ],
              [
                -73.90692055722614,
                40.58783214133197
              ],
              [
                -73.90685972549075,
                40.58788267665216
              ],
              [
                -73.90679325053729,
                40.587928896575136
              ],
              [
                -73.90672165702053,
                40.58797043630181
              ],
              [
                -73.90665708479354,
                40.587994953652625
              ],
              [
                -73.90658912076653,
                40.58801338827532
              ],
              [
                -73.90651874663754,
                40.58802547389256
              ],
              [
                -73.90644697891925,
                40.588031035934584
              ],
              [
                -73.90637485425499,
                40.58802999406099
              ],
              [
                -73.90630341444425,
                40.5880223633211
              ],
              [
                -73.90623369139416,
                40.58800825393622
              ],
              [
                -73.9061160973374,
                40.58796789581832
              ],
              [
                -73.90600054222729,
                40.58792424889283
              ],
              [
                -73.90588718425934,
                40.58787737291433
              ],
              [
                -73.90585651561567,
                40.58780004538562
              ],
              [
                -73.90583401259678,
                40.587721103071445
              ],
              [
                -73.9058198177865,
                40.58764104627039
              ],
              [
                -73.9058237065873,
                40.587623233786864
              ],
              [
                -73.90583120205693,
                40.58760610425149
              ],
              [
                -73.90584211968375,
                40.58759007932756
              ],
              [
                -73.90585619071548,
                40.58757555348632
              ],
              [
                -73.90587306877613,
                40.58756288429728
              ],
              [
                -73.9058923383927,
                40.58755238362625
              ],
              [
                -73.90591352522198,
                40.58754430995855
              ],
              [
                -73.9059110839291,
                40.58753348865561
              ],
              [
                -73.90590625678568,
                40.587523143522574
              ],
              [
                -73.90589918533036,
                40.58751357789054
              ],
              [
                -73.90589007690757,
                40.58750507223485
              ],
              [
                -73.90587919858659,
                40.58749787595081
              ],
              [
                -73.90586686933293,
                40.5874922000416
              ],
              [
                -73.9058534506541,
                40.58748821093105
              ],
              [
                -73.9052355542867,
                40.58724567170061
              ],
              [
                -73.9051553687622,
                40.58723286693648
              ],
              [
                -73.90508188414515,
                40.58720732955104
              ],
              [
                -73.90486485460703,
                40.58707466945402
              ],
              [
                -73.90436592432967,
                40.58696016202153
              ],
              [
                -73.90429819371803,
                40.58692345370346
              ],
              [
                -73.90429188191315,
                40.58691925496826
              ],
              [
                -73.90428472919143,
                40.58691593102567
              ],
              [
                -73.90427694184562,
                40.586913577742024
              ],
              [
                -73.90426874447125,
                40.58691226298858
              ],
              [
                -73.90426037348962,
                40.58691202468422
              ],
              [
                -73.90425207032871,
                40.58691286970183
              ],
              [
                -73.90424407446119,
                40.58691477367026
              ],
              [
                -73.9042366164965,
                40.58691768167701
              ],
              [
                -73.90422991153025,
                40.58692150985194
              ],
              [
                -73.90422415294172,
                40.586926147786194
              ],
              [
                -73.9042195068151,
                40.5869314617167
              ],
              [
                -73.90421511213714,
                40.586946397843775
              ],
              [
                -73.90420762950359,
                40.58696060245887
              ],
              [
                -73.90419725298479,
                40.58697370714616
              ],
              [
                -73.90418425170782,
                40.586985372018056
              ],
              [
                -73.90416896287779,
                40.5869952945298
              ],
              [
                -73.90413063108551,
                40.587011553451966
              ],
              [
                -73.90409070029817,
                40.58702297796951
              ],
              [
                -73.90401746259435,
                40.587032037284395
              ],
              [
                -73.90389474443407,
                40.58703923748239
              ],
              [
                -73.90379102822469,
                40.587036736418824
              ],
              [
                -73.90372220483816,
                40.58703660019963
              ],
              [
                -73.9036905070502,
                40.58704269248063
              ],
              [
                -73.90358685482148,
                40.587064370643716
              ],
              [
                -73.90356373306014,
                40.5870755286372
              ],
              [
                -73.90355615784536,
                40.58708956359199
              ],
              [
                -73.90355589541075,
                40.58711060004415
              ],
              [
                -73.90355753648133,
                40.5871311495783
              ],
              [
                -73.90355282546452,
                40.58714929040312
              ],
              [
                -73.90354523920348,
                40.587158490113076
              ],
              [
                -73.90353637445695,
                40.58716478965208
              ],
              [
                -73.90350374038445,
                40.587177170988454
              ],
              [
                -73.90346445668251,
                40.58719367264418
              ],
              [
                -73.90345147414308,
                40.58720143007638
              ],
              [
                -73.903429952794,
                40.58721863016428
              ],
              [
                -73.90339384113169,
                40.587235126252715
              ],
              [
                -73.90332316116782,
                40.5872524019143
              ],
              [
                -73.90330193885434,
                40.58726307176454
              ],
              [
                -73.90329940237604,
                40.587263317740984
              ],
              [
                -73.9032851377987,
                40.587265757360896
              ],
              [
                -73.90325153084287,
                40.58726991931488
              ],
              [
                -73.90316147474502,
                40.58727561852619
              ],
              [
                -73.90307999027851,
                40.58728517254634
              ],
              [
                -73.90304607979559,
                40.58729465357895
              ],
              [
                -73.90301852886405,
                40.58731017086194
              ],
              [
                -73.90298179375928,
                40.587331261627426
              ],
              [
                -73.90294629774272,
                40.58734074682029
              ],
              [
                -73.90289526590237,
                40.58735218684215
              ],
              [
                -73.90285597557542,
                40.58736627066676
              ],
              [
                -73.90281793711038,
                40.587373581986355
              ],
              [
                -73.90276847512405,
                40.58737897647188
              ],
              [
                -73.90270377665888,
                40.58737980056433
              ],
              [
                -73.90263812040308,
                40.58737724122182
              ],
              [
                -73.90260767161384,
                40.58737656322494
              ],
              [
                -73.90257722282765,
                40.587375883544674
              ],
              [
                -73.90246145296426,
                40.58737243289574
              ],
              [
                -73.90239958293348,
                40.58736285530437
              ],
              [
                -73.9023364362419,
                40.58735037867021
              ],
              [
                -73.90230280005393,
                40.587343659451946
              ],
              [
                -73.90227615524799,
                40.58734200740235
              ],
              [
                -73.90224476490202,
                40.58734519927618
              ],
              [
                -73.90221211386367,
                40.587351534428784
              ],
              [
                -73.90217786310491,
                40.58735182810352
              ],
              [
                -73.9021448686587,
                40.587347767910146
              ],
              [
                -73.90209953736353,
                40.587356541176426
              ],
              [
                -73.90208465746939,
                40.587365994245914
              ],
              [
                -73.90207865778105,
                40.5873763995802
              ],
              [
                -73.90207582339582,
                40.58738438245557
              ],
              [
                -73.90206190935329,
                40.58739963654965
              ],
              [
                -73.90203783294848,
                40.58740982778253
              ],
              [
                -73.90201280116307,
                40.58741905423124
              ],
              [
                -73.9019906415914,
                40.587434803705435
              ],
              [
                -73.90198305508366,
                40.5874440028094
              ],
              [
                -73.9019732551163,
                40.587456590820544
              ],
              [
                -73.90194760410006,
                40.58747162028164
              ],
              [
                -73.90191970554051,
                40.587475531402355
              ],
              [
                -73.90187308305745,
                40.58747536042042
              ],
              [
                -73.90185214591399,
                40.58747345799931
              ],
              [
                -73.90184072984661,
                40.587473958206935
              ],
              [
                -73.90182711495812,
                40.587482684408194
              ],
              [
                -73.90181383140683,
                40.58749696901538
              ],
              [
                -73.90180023790927,
                40.58751415647383
              ],
              [
                -73.90177365047886,
                40.58753426509109
              ],
              [
                -73.90172868375056,
                40.587560688252054
              ],
              [
                -73.9016792907157,
                40.58759267946963
              ],
              [
                -73.90164797681828,
                40.587624884329685
              ],
              [
                -73.90161062709599,
                40.58765419815086
              ],
              [
                -73.90155711564758,
                40.58768764446794
              ],
              [
                -73.90149093994187,
                40.587729573813405
              ],
              [
                -73.90140231439533,
                40.58779861793193
              ],
              [
                -73.90135392693685,
                40.58785091594769
              ],
              [
                -73.9013450777913,
                40.587863261636045
              ],
              [
                -73.90133052222224,
                40.58787585656555
              ],
              [
                -73.90130739238987,
                40.58788459530769
              ],
              [
                -73.9012601653681,
                40.58789627291517
              ],
              [
                -73.90121296750546,
                40.58791882972239
              ],
              [
                -73.90116957776658,
                40.587941623656896
              ],
              [
                -73.90113980175646,
                40.58795569262863
              ],
              [
                -73.90107897284466,
                40.587980930352835
              ],
              [
                -73.90101909683602,
                40.588007133332454
              ],
              [
                -73.9009927926347,
                40.58801466876069
              ],
              [
                -73.9009424181541,
                40.58803481230604
              ],
              [
                -73.90088030290644,
                40.588053524382154
              ],
              [
                -73.9008115206208,
                40.58806910272747
              ],
              [
                -73.90071546045651,
                40.588083995388345
              ],
              [
                -73.90065268067511,
                40.58809037660059
              ],
              [
                -73.90062128251243,
                40.58809114873183
              ],
              [
                -73.90057465441193,
                40.58808855960117
              ],
              [
                -73.90051186093923,
                40.58809010599277
              ],
              [
                -73.90044462743533,
                40.58809117422715
              ],
              [
                -73.9003520358427,
                40.58809832487129
              ],
              [
                -73.90031112653362,
                40.58810032001037
              ],
              [
                -73.90027496146784,
                40.58809626356584
              ],
              [
                -73.90023561971621,
                40.5880910040035
              ],
              [
                -73.90016550698388,
                40.58808313020387
              ],
              [
                -73.90012745158445,
                40.58808439598863
              ],
              [
                -73.90007855320306,
                40.58806069212094
              ],
              [
                -73.8999523407064,
                40.588041598696904
              ],
              [
                -73.89995410717111,
                40.58803668977956
              ],
              [
                -73.89995774139376,
                40.58802658606032
              ],
              [
                -73.89996122599122,
                40.588016904887446
              ],
              [
                -73.89996767315942,
                40.58801713013152
              ],
              [
                -73.89996820251265,
                40.58801714868563
              ],
              [
                -73.900031156696,
                40.58786974774759
              ],
              [
                -73.90029373007684,
                40.58776167531456
              ],
              [
                -73.90034256835513,
                40.58783483590821
              ],
              [
                -73.90091898871088,
                40.58761120045025
              ],
              [
                -73.90095285629884,
                40.58759806116519
              ],
              [
                -73.90120752373674,
                40.58749925509589
              ],
              [
                -73.90116046868427,
                40.587428765983866
              ],
              [
                -73.90123018530906,
                40.587401715480986
              ],
              [
                -73.90121791883185,
                40.58738334002311
              ],
              [
                -73.90117826603625,
                40.58739872567171
              ],
              [
                -73.90110684815338,
                40.58742024503451
              ],
              [
                -73.90115709211169,
                40.58749551116587
              ],
              [
                -73.90055860047781,
                40.587727723817935
              ],
              [
                -73.90035530331363,
                40.58780658540625
              ],
              [
                -73.90030360847027,
                40.58773256542618
              ],
              [
                -73.90006476759744,
                40.587829511284006
              ],
              [
                -73.90000932514889,
                40.58781834931441
              ],
              [
                -73.9000038525524,
                40.58783414860933
              ],
              [
                -73.89999572400494,
                40.58783251164108
              ],
              [
                -73.8999446503535,
                40.587979950883664
              ],
              [
                -73.89994345059304,
                40.58798341652566
              ],
              [
                -73.89987827846566,
                40.587971138818126
              ],
              [
                -73.8998797733794,
                40.587966924074884
              ],
              [
                -73.89993476711301,
                40.58781183519476
              ],
              [
                -73.89994383459322,
                40.587814097702335
              ],
              [
                -73.89994811282996,
                40.587805210472645
              ],
              [
                -73.89990604460492,
                40.587794089248185
              ],
              [
                -73.89984402458235,
                40.58797915724739
              ],
              [
                -73.89981060525021,
                40.58801445705558
              ],
              [
                -73.89975146067313,
                40.58801773092041
              ],
              [
                -73.8996923308126,
                40.58801430603838
              ],
              [
                -73.89963451393774,
                40.588004257606855
              ],
              [
                -73.89957927948808,
                40.587987806251505
              ],
              [
                -73.89952784020072,
                40.58796531318173
              ],
              [
                -73.89948132548207,
                40.58793727225973
              ],
              [
                -73.8994407566106,
                40.58790429915679
              ],
              [
                -73.89939658415568,
                40.58785984103415
              ],
              [
                -73.89934576169931,
                40.58781972403837
              ],
              [
                -73.89928901709786,
                40.587784522697845
              ],
              [
                -73.89922716301633,
                40.58775474114151
              ],
              [
                -73.89916108529071,
                40.58773080587935
              ],
              [
                -73.89909173024091,
                40.58771305969453
              ],
              [
                -73.89906116730546,
                40.58769626832069
              ],
              [
                -73.89903425420563,
                40.58767617174579
              ],
              [
                -73.89901159999678,
                40.58765322476688
              ],
              [
                -73.89899371735159,
                40.587627946686865
              ],
              [
                -73.89915650729063,
                40.58752699536934
              ],
              [
                -73.89914367094656,
                40.587515275804726
              ],
              [
                -73.89922478113836,
                40.58746364369793
              ],
              [
                -73.89921710048566,
                40.58745663171735
              ],
              [
                -73.89923001526552,
                40.58744841081481
              ],
              [
                -73.89923739812788,
                40.58745515147077
              ],
              [
                -73.89931117446991,
                40.58740818878553
              ],
              [
                -73.89932907018108,
                40.5874245283683
              ],
              [
                -73.89944274924315,
                40.58735182480616
              ],
              [
                -73.89961007389714,
                40.58724531081463
              ],
              [
                -73.8996324668466,
                40.58723076754464
              ],
              [
                -73.89973341305188,
                40.587165212109895
              ],
              [
                -73.89965842539361,
                40.587097199550456
              ],
              [
                -73.89938087234081,
                40.58727505722636
              ],
              [
                -73.8993501676844,
                40.587299416256286
              ],
              [
                -73.89925698447745,
                40.587355611253955
              ],
              [
                -73.89927787511728,
                40.587375743489446
              ],
              [
                -73.89918723259748,
                40.5874304091032
              ],
              [
                -73.89918075194707,
                40.58742416277747
              ],
              [
                -73.89909217892532,
                40.58748389102921
              ],
              [
                -73.89905144380201,
                40.58745417790854
              ],
              [
                -73.89891599908806,
                40.58753743606104
              ],
              [
                -73.89886748676379,
                40.58750502165708
              ],
              [
                -73.89880458585415,
                40.58746425427504
              ],
              [
                -73.89876737942862,
                40.587425381473125
              ],
              [
                -73.8986961284725,
                40.5873440055765
              ],
              [
                -73.89864072346847,
                40.5872577698476
              ],
              [
                -73.89859712057444,
                40.58719835474862
              ],
              [
                -73.8985455781012,
                40.58713411598312
              ],
              [
                -73.89846957082578,
                40.58705274680054
              ],
              [
                -73.898384918464,
                40.58693971556551
              ],
              [
                -73.89830506227048,
                40.5868411850034
              ],
              [
                -73.89826305550277,
                40.586785395352344
              ],
              [
                -73.89824518891376,
                40.58674359215489
              ],
              [
                -73.89822124402095,
                40.58668197153612
              ],
              [
                -73.89822306945673,
                40.58665126098159
              ],
              [
                -73.8982385367799,
                40.58662270839467
              ],
              [
                -73.89825559212163,
                40.5865953605759
              ],
              [
                -73.89827579278028,
                40.58655712913951
              ],
              [
                -73.89827886597834,
                40.58651843774707
              ],
              [
                -73.89825781710847,
                40.58647301292908
              ],
              [
                -73.89820561988033,
                40.58640007077295
              ],
              [
                -73.89814988350828,
                40.586308274201855
              ],
              [
                -73.89808148539953,
                40.58621873957948
              ],
              [
                -73.89802850979339,
                40.586149390157516
              ],
              [
                -73.89794445722232,
                40.586011643328135
              ],
              [
                -73.89792276360309,
                40.5859618833834
              ],
              [
                -73.89790736589434,
                40.58591299951198
              ],
              [
                -73.89789705159707,
                40.58582125572848
              ],
              [
                -73.8979120612451,
                40.58571759730074
              ],
              [
                -73.89796085650754,
                40.58556684501419
              ],
              [
                -73.89800527113262,
                40.585494117516134
              ],
              [
                -73.89807651852621,
                40.58540248565512
              ],
              [
                -73.89812462627432,
                40.58534046668772
              ],
              [
                -73.89814195208847,
                40.585306903778694
              ],
              [
                -73.89814920164099,
                40.58527475426074
              ],
              [
                -73.89814492456449,
                40.58523658071856
              ],
              [
                -73.89814405045641,
                40.585219562130895
              ],
              [
                -73.8981433096778,
                40.58520992718847
              ],
              [
                -73.89813542477575,
                40.58518565505979
              ],
              [
                -73.89812203191424,
                40.585162477710426
              ],
              [
                -73.89807119409498,
                40.5850937026443
              ],
              [
                -73.8979560997442,
                40.5849481533903
              ],
              [
                -73.89782641488564,
                40.58483319667289
              ],
              [
                -73.8976690506408,
                40.584667900690036
              ],
              [
                -73.89754679748128,
                40.58456716783018
              ],
              [
                -73.89743775799184,
                40.584466062322235
              ],
              [
                -73.89731639815692,
                40.58432286681173
              ],
              [
                -73.89720897798324,
                40.58417595175524
              ],
              [
                -73.89703138264092,
                40.58398757986602
              ],
              [
                -73.89699309708226,
                40.583928121658865
              ],
              [
                -73.89698071894091,
                40.58390011302921
              ],
              [
                -73.89697560657291,
                40.58387686054261
              ],
              [
                -73.89697815102885,
                40.58386524951089
              ],
              [
                -73.89698256415994,
                40.58382415199713
              ],
              [
                -73.89697992068518,
                40.58378296626747
              ],
              [
                -73.89697026562979,
                40.58374239360962
              ],
              [
                -73.89695376340694,
                40.583703124871526
              ],
              [
                -73.8969306950178,
                40.58366582869709
              ],
              [
                -73.89690145326705,
                40.583631140141904
              ],
              [
                -73.89660012312444,
                40.58312489552907
              ],
              [
                -73.89655591241934,
                40.58300501897465
              ],
              [
                -73.89651954859457,
                40.58288361920591
              ],
              [
                -73.89649112098013,
                40.58276099459256
              ],
              [
                -73.8964706993996,
                40.58263744651309
              ],
              [
                -73.89645833399909,
                40.58251327861424
              ],
              [
                -73.89645405512407,
                40.58238879606406
              ],
              [
                -73.89645787324548,
                40.582264304802216
              ],
              [
                -73.89646977893447,
                40.58214011078842
              ],
              [
                -73.89648974288556,
                40.58201651924971
              ],
              [
                -73.89651771599007,
                40.58189383393113
              ],
              [
                -73.89663622640884,
                40.58157138211056
              ],
              [
                -73.896759876751,
                40.58125005430533
              ],
              [
                -73.89683046299629,
                40.581145624801245
              ],
              [
                -73.8968936154914,
                40.58103846397899
              ],
              [
                -73.8969491531528,
                40.58092887917256
              ],
              [
                -73.89699691673887,
                40.580817184666074
              ],
              [
                -73.89703676930591,
                40.58070370079286
              ],
              [
                -73.89706859660005,
                40.58058875301628
              ],
              [
                -73.89709230738518,
                40.58047267099644
              ],
              [
                -73.89710783370369,
                40.58035578764456
              ],
              [
                -73.8971151310708,
                40.58023843816832
              ],
              [
                -73.89711417860228,
                40.58012095911049
              ],
              [
                -73.89710497907294,
                40.580003687383694
              ],
              [
                -73.89708755890891,
                40.57988695930395
              ],
              [
                -73.89706196811126,
                40.57977110962682
              ],
              [
                -73.8970282801111,
                40.57965647058665
              ],
              [
                -73.89698659156097,
                40.57954337094459
              ],
              [
                -73.89694123170634,
                40.579456684046434
              ],
              [
                -73.89688790512886,
                40.579372674760734
              ],
              [
                -73.89682688163259,
                40.57929176808161
              ],
              [
                -73.89675846995432,
                40.57921437330632
              ],
              [
                -73.89668301620476,
                40.579140881964236
              ],
              [
                -73.89660090211501,
                40.57907166583643
              ],
              [
                -73.89651254310586,
                40.57900707507548
              ],
              [
                -73.89638605766544,
                40.57881440819826
              ],
              [
                -73.89626664920769,
                40.578619133679574
              ],
              [
                -73.89615441005304,
                40.57842140259736
              ],
              [
                -73.89604942697282,
                40.578221367928826
              ],
              [
                -73.89595178112191,
                40.57801918443167
              ],
              [
                -73.8958615479775,
                40.57781500852496
              ],
              [
                -73.89577879727906,
                40.57760899816774
              ],
              [
                -73.89570359297613,
                40.57740131273658
              ],
              [
                -73.89563599317808,
                40.577192112902715
              ],
              [
                -73.89557605010988,
                40.576981560507335
              ],
              [
                -73.89552381007165,
                40.576769818436354
              ],
              [
                -73.8954009886479,
                40.5767576108554
              ],
              [
                -73.89527739709217,
                40.57675139054873
              ],
              [
                -73.89515353689528,
                40.5767511827561
              ],
              [
                -73.89502991063868,
                40.57675698832076
              ],
              [
                -73.89490701995419,
                40.57676878368562
              ],
              [
                -73.89478536348979,
                40.57678652098937
              ],
              [
                -73.89466543488584,
                40.576810128260085
              ],
              [
                -73.89454772077411,
                40.57683950970763
              ],
              [
                -73.89443269880123,
                40.576874546111796
              ],
              [
                -73.89432083569274,
                40.576915095306695
              ],
              [
                -73.89418492279262,
                40.57696594323416
              ],
              [
                -73.89404726978998,
                40.577013992892795
              ],
              [
                -73.89387153148483,
                40.57707358858637
              ],
              [
                -73.89369280047919,
                40.577127763626294
              ],
              [
                -73.89351136423606,
                40.5771764308782
              ],
              [
                -73.89332751457036,
                40.577219512066144
              ],
              [
                -73.89314154718112,
                40.57725693789829
              ],
              [
                -73.89295376117498,
                40.57728864817913
              ],
              [
                -73.89277418426028,
                40.57731365606831
              ],
              [
                -73.8925962500554,
                40.57734475022032
              ],
              [
                -73.8924203131694,
                40.577381868667594
              ],
              [
                -73.89224672423306,
                40.57742493743623
              ],
              [
                -73.89207582919903,
                40.57747387069376
              ],
              [
                -73.89190796865317,
                40.57752857091994
              ],
              [
                -73.89174347713703,
                40.577588929101594
              ],
              [
                -73.89158268247964,
                40.57765482494874
              ],
              [
                -73.8915434908459,
                40.57770110473469
              ],
              [
                -73.89149700625241,
                40.57774327770597
              ],
              [
                -73.89144396065697,
                40.577780679785086
              ],
              [
                -73.89138518933413,
                40.57781272201844
              ],
              [
                -73.89132161772321,
                40.57783889985071
              ],
              [
                -73.89125424685513,
                40.57785880107041
              ],
              [
                -73.89118413759032,
                40.577872112300845
              ],
              [
                -73.89111239391109,
                40.577878623935014
              ],
              [
                -73.89104014553801,
                40.57787823343677
              ],
              [
                -73.89032484405561,
                40.57783736110558
              ],
              [
                -73.89021304955556,
                40.57781650682822
              ],
              [
                -73.89009973775032,
                40.57780113389258
              ],
              [
                -73.88998537041505,
                40.57779130494721
              ],
              [
                -73.88987041362529,
                40.57778706004756
              ],
              [
                -73.88975533585844,
                40.577788416492574
              ],
              [
                -73.88964060608498,
                40.57779536875448
              ],
              [
                -73.88952669185717,
                40.57780788850105
              ],
              [
                -73.88945229395125,
                40.57782428630401
              ],
              [
                -73.88938036037099,
                40.57784616178201
              ],
              [
                -73.8893115829617,
                40.577873304541065
              ],
              [
                -73.88924662321557,
                40.57790545352724
              ],
              [
                -73.88918610590936,
                40.57794229953755
              ],
              [
                -73.8891306130966,
                40.57798348819287
              ],
              [
                -73.88908067850879,
                40.57802862334688
              ],
              [
                -73.88903678242181,
                40.578077270895506
              ],
              [
                -73.88893118528091,
                40.57810262995654
              ],
              [
                -73.8888234939638,
                40.57812221086377
              ],
              [
                -73.88871425158845,
                40.57813591486514
              ],
              [
                -73.88860400909583,
                40.57814367284707
              ],
              [
                -73.88849332247199,
                40.57814544568404
              ],
              [
                -73.88838274994282,
                40.57814122443477
              ],
              [
                -73.88827284915907,
                40.57813103038835
              ],
              [
                -73.88816417438308,
                40.578114914956664
              ],
              [
                -73.88805727369287,
                40.57809295941429
              ],
              [
                -73.88795268621801,
                40.578065274489646
              ],
              [
                -73.88780498557446,
                40.578059277360616
              ],
              [
                -73.88774071588242,
                40.578056666285036
              ],
              [
                -73.88769395836898,
                40.578063238844635
              ],
              [
                -73.88765149200802,
                40.57807474195776
              ],
              [
                -73.88755053045395,
                40.57806788716012
              ],
              [
                -73.88744916964511,
                40.578068048493954
              ],
              [
                -73.88734824650477,
                40.578075224627106
              ],
              [
                -73.88724859434285,
                40.57808935630708
              ],
              [
                -73.88715103597593,
                40.57811032685046
              ],
              [
                -73.88705637693425,
                40.57813796310554
              ],
              [
                -73.8870507197036,
                40.578139857497376
              ],
              [
                -73.8869815213548,
                40.578164575507394
              ],
              [
                -73.88690936203807,
                40.57818374968069
              ],
              [
                -73.88683500337883,
                40.57819717763759
              ],
              [
                -73.88675923021812,
                40.578204717648504
              ],
              [
                -73.88668284232692,
                40.57820629012985
              ],
              [
                -73.88666279855236,
                40.57820571047401
              ],
              [
                -73.88654107830757,
                40.578206860979016
              ],
              [
                -73.88641974810582,
                40.57821437350695
              ],
              [
                -73.8862993792403,
                40.578228212684515
              ],
              [
                -73.8861805384788,
                40.578248313348745
              ],
              [
                -73.88606378539488,
                40.578274580853815
              ],
              [
                -73.88594966973379,
                40.57830689151693
              ],
              [
                -73.88583872882465,
                40.57834509319991
              ],
              [
                -73.88573148504992,
                40.5783890060262
              ],
              [
                -73.8857255282976,
                40.578391661979474
              ],
              [
                -73.88571671041561,
                40.5783929932206
              ],
              [
                -73.88570775257024,
                40.57839356401887
              ],
              [
                -73.88569450921206,
                40.5783930039042
              ],
              [
                -73.88568156784665,
                40.57839078785232
              ],
              [
                -73.88566928215334,
                40.578386976426685
              ],
              [
                -73.88565798789254,
                40.57838167379117
              ],
              [
                -73.88564799372837,
                40.57837502486346
              ],
              [
                -73.88563228736759,
                40.578372588856126
              ],
              [
                -73.88561627300489,
                40.57837208466164
              ],
              [
                -73.88560035671478,
                40.57837352506468
              ],
              [
                -73.88558494208567,
                40.57837687354125
              ],
              [
                -73.88557041998473,
                40.57838204518411
              ],
              [
                -73.88553401637762,
                40.578409682285894
              ],
              [
                -73.88549432825204,
                40.57843455222713
              ],
              [
                -73.88544524245411,
                40.578459374450716
              ],
              [
                -73.88539289841202,
                40.578479966064634
              ],
              [
                -73.88533792081007,
                40.57849608132347
              ],
              [
                -73.88528096576366,
                40.57850752790355
              ],
              [
                -73.88522271298856,
                40.578514169198414
              ],
              [
                -73.88510319335664,
                40.57852048655189
              ],
              [
                -73.8849844237208,
                40.57853247859521
              ],
              [
                -73.88488017951566,
                40.57853380579348
              ],
              [
                -73.88477619541953,
                40.578539572039766
              ],
              [
                -73.88467279632157,
                40.578549759318044
              ],
              [
                -73.88456280290566,
                40.57856055925847
              ],
              [
                -73.88445392836726,
                40.578576652980416
              ],
              [
                -73.88434661049752,
                40.578597975770364
              ],
              [
                -73.88424128082934,
                40.57862444188816
              ],
              [
                -73.884138362902,
                40.57865594491179
              ],
              [
                -73.88403827055893,
                40.57869235816557
              ],
              [
                -73.88394140628381,
                40.57873353522914
              ],
              [
                -73.88378628902927,
                40.57879036591525
              ],
              [
                -73.8836344631034,
                40.578852148301955
              ],
              [
                -73.88348620025185,
                40.57891877181205
              ],
              [
                -73.88334176584539,
                40.5789901172032
              ],
              [
                -73.88322915221077,
                40.579032942836015
              ],
              [
                -73.88311325581375,
                40.5790703319885
              ],
              [
                -73.88299452757539,
                40.579102139188336
              ],
              [
                -73.88287342943622,
                40.57912824068065
              ],
              [
                -73.88275043256014,
                40.57914853491064
              ],
              [
                -73.88262601549968,
                40.579162942917755
              ],
              [
                -73.88250066233414,
                40.57917140864374
              ],
              [
                -73.88232347768535,
                40.57917709878924
              ],
              [
                -73.88214694143117,
                40.57918997182285
              ],
              [
                -73.88206958203641,
                40.579073978696606
              ],
              [
                -73.88174676983894,
                40.579158807212416
              ],
              [
                -73.8818672452673,
                40.57945462578764
              ],
              [
                -73.881742900221,
                40.57959170076466
              ],
              [
                -73.87970231664,
                40.580135100698136
              ],
              [
                -73.87671672482064,
                40.584914430200875
              ],
              [
                -73.87788478766805,
                40.58642220211564
              ],
              [
                -73.87789962824263,
                40.58647177930294
              ],
              [
                -73.87792179139247,
                40.58651974188609
              ],
              [
                -73.87795098768318,
                40.586565463474315
              ],
              [
                -73.87798683582403,
                40.58660834694272
              ],
              [
                -73.8780288676496,
                40.58664783223147
              ],
              [
                -73.87807653423106,
                40.58668340365991
              ],
              [
                -73.87820200233887,
                40.58686794328779
              ],
              [
                -73.87831969403057,
                40.58705543712823
              ],
              [
                -73.87842948971884,
                40.58724569477255
              ],
              [
                -73.87853127783266,
                40.58743852300377
              ],
              [
                -73.87862495492989,
                40.58763372599224
              ],
              [
                -73.87871042580291,
                40.58783110549548
              ],
              [
                -73.8787876035763,
                40.5880304610584
              ],
              [
                -73.87885640979461,
                40.58823159021744
              ],
              [
                -73.87891677450195,
                40.58843428870589
              ],
              [
                -73.8789686363146,
                40.58863835066148
              ],
              [
                -73.87901194248273,
                40.58884356883528
              ],
              [
                -73.87903458169106,
                40.5889701772114
              ],
              [
                -73.87905394992099,
                40.589097100566306
              ],
              [
                -73.87913901161362,
                40.589373200231236
              ],
              [
                -73.87907498390977,
                40.58960053485112
              ],
              [
                -73.87896840364141,
                40.58973041813033
              ],
              [
                -73.87896831259086,
                40.58990093504834
              ],
              [
                -73.87898885895304,
                40.589984666635424
              ],
              [
                -73.87900019672759,
                40.59003086209086
              ],
              [
                -73.87901743697853,
                40.59004820262238
              ],
              [
                -73.87923439766338,
                40.590266409958495
              ],
              [
                -73.87924495401444,
                40.59044504782278
              ],
              [
                -73.87929326377282,
                40.59066143187066
              ],
              [
                -73.87892411931159,
                40.59071278028153
              ],
              [
                -73.8789689043874,
                40.59089677311756
              ],
              [
                -73.87897881256478,
                40.59089541936104
              ],
              [
                -73.87935729205896,
                40.590843735297476
              ],
              [
                -73.87971492228009,
                40.591398891829044
              ],
              [
                -73.8798990285191,
                40.59202063885204
              ],
              [
                -73.8794080701356,
                40.59210340689228
              ],
              [
                -73.87941617176985,
                40.592132005798014
              ],
              [
                -73.87990713258998,
                40.59204799432242
              ],
              [
                -73.88068791594142,
                40.594664413198295
              ],
              [
                -73.88071906028287,
                40.59482632973357
              ],
              [
                -73.88074304729447,
                40.59498895277267
              ],
              [
                -73.8807598498148,
                40.595152098525624
              ],
              [
                -73.88076934532417,
                40.595312787053885
              ],
              [
                -73.8807718709499,
                40.59547362699728
              ],
              [
                -73.88076742388394,
                40.59563444273595
              ],
              [
                -73.88075600893184,
                40.595795058675215
              ],
              [
                -73.8807249603179,
                40.59587827542993
              ],
              [
                -73.88070214849132,
                40.59596302686683
              ],
              [
                -73.88068770080325,
                40.59604883996433
              ],
              [
                -73.88068169792272,
                40.59613523577336
              ],
              [
                -73.88068417338692,
                40.596221732090946
              ],
              [
                -73.88069511341199,
                40.59630784615116
              ],
              [
                -73.88071445697005,
                40.59639309731992
              ],
              [
                -73.88074209612957,
                40.596477009777004
              ],
              [
                -73.88077787665533,
                40.59655911517274
              ],
              [
                -73.88082159887102,
                40.59663895524159
              ],
              [
                -73.88087301877087,
                40.596716084360054
              ],
              [
                -73.88097793345088,
                40.59685029963641
              ],
              [
                -73.88107463235342,
                40.59698805376489
              ],
              [
                -73.88116291044133,
                40.59712905472902
              ],
              [
                -73.88124258052439,
                40.59727300362764
              ],
              [
                -73.8813134736566,
                40.597419595308416
              ],
              [
                -73.88137543949483,
                40.597568519014914
              ],
              [
                -73.8814283466183,
                40.59771945904484
              ],
              [
                -73.88147208280688,
                40.59787209541924
              ],
              [
                -73.88150655528008,
                40.598026104561185
              ],
              [
                -73.88153169089443,
                40.59818115998111
              ],
              [
                -73.88154743629828,
                40.59833693296921
              ],
              [
                -73.88155375804611,
                40.598493093292134
              ],
              [
                -73.8815506426699,
                40.59864930989325
              ],
              [
                -73.88153809670827,
                40.59880525159409
              ],
              [
                -73.88151232775914,
                40.59884588525993
              ],
              [
                -73.88149531120334,
                40.5988891133295
              ],
              [
                -73.8814874751096,
                40.59893384840017
              ],
              [
                -73.88148901661386,
                40.598978965158636
              ],
              [
                -73.88149989695842,
                40.599023328687935
              ],
              [
                -73.88151984246439,
                40.59906582301653
              ],
              [
                -73.88154835141519,
                40.599105379190554
              ],
              [
                -73.88158242343145,
                40.599136055296974
              ],
              [
                -73.88160936216501,
                40.59917060783245
              ],
              [
                -73.88162842405985,
                40.59920808310127
              ],
              [
                -73.88163908296839,
                40.59924744673533
              ],
              [
                -73.88164104467455,
                40.59928761224311
              ],
              [
                -73.88163425501722,
                40.59932747099834
              ],
              [
                -73.88162336218662,
                40.599356556530054
              ],
              [
                -73.88161860364681,
                40.59938658567115
              ],
              [
                -73.88162009774692,
                40.599416811622945
              ],
              [
                -73.8816278073381,
                40.599446482690986
              ],
              [
                -73.88164154069716,
                40.59947486097972
              ],
              [
                -73.88166095629371,
                40.599501240742846
              ],
              [
                -73.88168557128205,
                40.59952496593494
              ],
              [
                -73.88171477351025,
                40.59954544652754
              ],
              [
                -73.8817537484157,
                40.59957536752163
              ],
              [
                -73.88178739432897,
                40.59960883551801
              ],
              [
                -73.88181516088457,
                40.59964530306987
              ],
              [
                -73.88183659388517,
                40.59968417366451
              ],
              [
                -73.88185134273189,
                40.599724811480996
              ],
              [
                -73.88185916615947,
                40.59976655178991
              ],
              [
                -73.88185993618363,
                40.59980871182692
              ],
              [
                -73.88186249006357,
                40.599847258683255
              ],
              [
                -73.88187148466092,
                40.59988524086713
              ],
              [
                -73.88188677253875,
                40.599922035738956
              ],
              [
                -73.88190810309368,
                40.59995704012172
              ],
              [
                -73.88193512666273,
                40.59998968018904
              ],
              [
                -73.88199070930334,
                40.60003184850537
              ],
              [
                -73.88204016508438,
                40.600078238832246
              ],
              [
                -73.88208294540055,
                40.600128336581164
              ],
              [
                -73.88211857569163,
                40.6001815860379
              ],
              [
                -73.88214666070611,
                40.60023739652581
              ],
              [
                -73.8821668888879,
                40.60029514895839
              ],
              [
                -73.88217903583141,
                40.600354202706164
              ],
              [
                -73.88218296677205,
                40.60041390270307
              ],
              [
                -73.88217863808364,
                40.600473586712326
              ],
              [
                -73.88216609776086,
                40.60053259267284
              ],
              [
                -73.88218487977502,
                40.60061683594177
              ],
              [
                -73.88221179539481,
                40.60069978767551
              ],
              [
                -73.8822466968278,
                40.6007809922971
              ],
              [
                -73.88228939242067,
                40.60086000382322
              ],
              [
                -73.88233964771186,
                40.60093638831373
              ],
              [
                -73.88239718671782,
                40.601009726255015
              ],
              [
                -73.88244602764492,
                40.60108667250561
              ],
              [
                -73.88248755960187,
                40.601166073215715
              ],
              [
                -73.88252157190462,
                40.601247525650834
              ],
              [
                -73.88254789200822,
                40.601330616668534
              ],
              [
                -73.88256638638296,
                40.601414924813234
              ],
              [
                -73.88257696119135,
                40.60150002245466
              ],
              [
                -73.88257956276458,
                40.601585477956135
              ],
              [
                -73.88256990027072,
                40.601628258045785
              ],
              [
                -73.882569287959,
                40.601671664937186
              ],
              [
                -73.8825777414367,
                40.60171459348781
              ],
              [
                -73.88259504549387,
                40.601755950731935
              ],
              [
                -73.88262075958285,
                40.6017946837073
              ],
              [
                -73.88265422903095,
                40.60182980626406
              ],
              [
                -73.8826946017093,
                40.601860424173175
              ],
              [
                -73.88274084972663,
                40.60188575789383
              ],
              [
                -73.88283747082252,
                40.60200481692117
              ],
              [
                -73.88292661358965,
                40.602127218400184
              ],
              [
                -73.88300807939133,
                40.60225268964139
              ],
              [
                -73.88308168669093,
                40.60238095111473
              ],
              [
                -73.88314727145664,
                40.602511717072225
              ],
              [
                -73.88320468752659,
                40.60264469618467
              ],
              [
                -73.88325380693556,
                40.6027795921904
              ],
              [
                -73.88329452020042,
                40.602916104555774
              ],
              [
                -73.88332673656372,
                40.6030539291434
              ],
              [
                -73.88289983055381,
                40.60350161304467
              ],
              [
                -73.88296945455222,
                40.60378535567239
              ],
              [
                -73.88269358924951,
                40.6042249701492
              ],
              [
                -73.88297332801775,
                40.60465281752274
              ],
              [
                -73.8839804295303,
                40.60556651972074
              ],
              [
                -73.88450938061123,
                40.60537793913672
              ],
              [
                -73.884522322727,
                40.60537260229965
              ],
              [
                -73.88453385483898,
                40.60536563613089
              ],
              [
                -73.88454362766481,
                40.605357251620745
              ],
              [
                -73.88455134520697,
                40.60534770271806
              ],
              [
                -73.88455677371944,
                40.605337278638245
              ],
              [
                -73.88455974878485,
                40.60532629510396
              ],
              [
                -73.88456018029669,
                40.605315084782355
              ],
              [
                -73.88455805518677,
                40.60530398720932
              ],
              [
                -73.88457115377963,
                40.6052706169266
              ],
              [
                -73.88459153564902,
                40.605239441177694
              ],
              [
                -73.88461860221912,
                40.60521137551737
              ],
              [
                -73.88465155860543,
                40.60518724416357
              ],
              [
                -73.88468943695659,
                40.60516775579296
              ],
              [
                -73.88473112487893,
                40.60515348272891
              ],
              [
                -73.88477539810481,
                40.60514484413419
              ],
              [
                -73.88482095644369,
                40.60514209370178
              ],
              [
                -73.88486646196644,
                40.60514531220497
              ],
              [
                -73.88491057829488,
                40.60515440512451
              ],
              [
                -73.88495200984666,
                40.60516910542513
              ],
              [
                -73.88504138218454,
                40.60516694191991
              ],
              [
                -73.88512439308187,
                40.605161825651166
              ],
              [
                -73.88524221829852,
                40.60515782180433
              ],
              [
                -73.88549498016289,
                40.60514758054541
              ],
              [
                -73.88558856481752,
                40.605147745111296
              ],
              [
                -73.88566338591882,
                40.605149507593005
              ],
              [
                -73.88575595461784,
                40.60515455733594
              ],
              [
                -73.88586517865721,
                40.60516626641288
              ],
              [
                -73.88598136294254,
                40.6051729616086
              ],
              [
                -73.88605682072867,
                40.60518021355977
              ],
              [
                -73.8861267655826,
                40.60519001096643
              ],
              [
                -73.88619211092349,
                40.60519442290595
              ],
              [
                -73.88626714040382,
                40.60520074048715
              ],
              [
                -73.88635267579495,
                40.605210830443724
              ],
              [
                -73.8864281664883,
                40.60521959011375
              ],
              [
                -73.88650617257574,
                40.60523074135186
              ],
              [
                -73.88661834051047,
                40.6052472571894
              ],
              [
                -73.88670784167516,
                40.60525573246175
              ],
              [
                -73.88677527065971,
                40.605263137357106
              ],
              [
                -73.88682674518223,
                40.605270882335745
              ],
              [
                -73.88686309321677,
                40.60528080653204
              ],
              [
                -73.8869180480688,
                40.60529577088031
              ],
              [
                -73.88699136586392,
                40.605311308283596
              ],
              [
                -73.88704036373788,
                40.605316661597534
              ],
              [
                -73.8870953366259,
                40.60532191153128
              ],
              [
                -73.8871577037901,
                40.605321490799476
              ],
              [
                -73.88725806021515,
                40.60532028993035
              ],
              [
                -73.8873463911858,
                40.60531932245005
              ],
              [
                -73.8874557241072,
                40.605313054574665
              ],
              [
                -73.88756220282258,
                40.60529371954206
              ],
              [
                -73.88764437104099,
                40.60527704731357
              ],
              [
                -73.88770414238029,
                40.605259937003005
              ],
              [
                -73.88776291623786,
                40.60523796633565
              ],
              [
                -73.88781168218313,
                40.60521469572743
              ],
              [
                -73.88794618738609,
                40.60515271767377
              ],
              [
                -73.88798465970856,
                40.60514458444246
              ],
              [
                -73.88802478686374,
                40.60513702927851
              ],
              [
                -73.88805490559625,
                40.60512968113553
              ],
              [
                -73.88808576758608,
                40.60510981961291
              ],
              [
                -73.88810556920826,
                40.60509354081569
              ],
              [
                -73.88813757394236,
                40.60506056252109
              ],
              [
                -73.88816018183915,
                40.60504453405386
              ],
              [
                -73.88818728742056,
                40.605030870444125
              ],
              [
                -73.8882184920877,
                40.605020149444336
              ],
              [
                -73.8882394945908,
                40.60501482133218
              ],
              [
                -73.88826766445584,
                40.60500903529082
              ],
              [
                -73.88829471413327,
                40.605005115099054
              ],
              [
                -73.88832014479517,
                40.60500061635722
              ],
              [
                -73.88834371671093,
                40.60498944596438
              ],
              [
                -73.88834454377714,
                40.60498974982812
              ],
              [
                -73.88834931982103,
                40.60497866654848
              ],
              [
                -73.88837063642056,
                40.60495991996444
              ],
              [
                -73.88840497731677,
                40.60494730804253
              ],
              [
                -73.88844750219316,
                40.60492935333917
              ],
              [
                -73.88853217218396,
                40.604903764487716
              ],
              [
                -73.8886955641107,
                40.60485581532362
              ],
              [
                -73.88870705524045,
                40.60485313760791
              ],
              [
                -73.8887311613169,
                40.604844384764014
              ],
              [
                -73.88876767301007,
                40.60482655965154
              ],
              [
                -73.88880141294047,
                40.60480845910824
              ],
              [
                -73.88883148283712,
                40.60478832103327
              ],
              [
                -73.88885369531336,
                40.60477292229178
              ],
              [
                -73.88887864752957,
                40.60475470115008
              ],
              [
                -73.88890320391096,
                40.60473711053534
              ],
              [
                -73.88892758849589,
                40.60471496429544
              ],
              [
                -73.88894909569777,
                40.60469102957642
              ],
              [
                -73.88896224867293,
                40.6046679096859
              ],
              [
                -73.88897061353639,
                40.6046445645324
              ],
              [
                -73.88897128950605,
                40.60463051451192
              ],
              [
                -73.88896802301711,
                40.60460682641376
              ],
              [
                -73.8889624822399,
                40.604586813719294
              ],
              [
                -73.88895962644617,
                40.60457377484337
              ],
              [
                -73.8889619907474,
                40.60456183882948
              ],
              [
                -73.88896957425946,
                40.60455100701697
              ],
              [
                -73.88897627762157,
                40.60454813316309
              ],
              [
                -73.88898582146595,
                40.6045470185962
              ],
              [
                -73.88900775526638,
                40.60454501351479
              ],
              [
                -73.88902417118193,
                40.604545580035236
              ],
              [
                -73.88903616246353,
                40.604545318708205
              ],
              [
                -73.88905370221889,
                40.60454405002204
              ],
              [
                -73.88906807134859,
                40.60454313393746
              ],
              [
                -73.88908312889893,
                40.60453944573976
              ],
              [
                -73.88910208281249,
                40.60453266133952
              ],
              [
                -73.88911991617734,
                40.60452771482308
              ],
              [
                -73.88916194261427,
                40.60450731646343
              ],
              [
                -73.88925094029364,
                40.604458486747234
              ],
              [
                -73.889272206251,
                40.604447889236795
              ],
              [
                -73.88930393936333,
                40.604442711824746
              ],
              [
                -73.88934064576748,
                40.60443542564425
              ],
              [
                -73.88937241465213,
                40.60443027521993
              ],
              [
                -73.88941698840019,
                40.604413915164706
              ],
              [
                -73.8894431084006,
                40.604406562307766
              ],
              [
                -73.88949061795029,
                40.60438057144903
              ],
              [
                -73.88953659378701,
                40.604364734716555
              ],
              [
                -73.88957343459485,
                40.60434405563658
              ],
              [
                -73.88962213646897,
                40.60433469766528
              ],
              [
                -73.88966942825904,
                40.604327480348005
              ],
              [
                -73.88970753820436,
                40.60432074489342
              ],
              [
                -73.88975202422739,
                40.60431242418274
              ],
              [
                -73.889787415091,
                40.604296547710575
              ],
              [
                -73.88981931636272,
                40.60427797786594
              ],
              [
                -73.8898498133187,
                40.604261518539545
              ],
              [
                -73.88988162700245,
                40.60425098815237
              ],
              [
                -73.88992616445805,
                40.604234627864834
              ],
              [
                -73.88997427282537,
                40.604215608604726
              ],
              [
                -73.89001812356094,
                40.604200318135675
              ],
              [
                -73.89005980212846,
                40.60419092410768
              ],
              [
                -73.8901105870756,
                40.6041837362525
              ],
              [
                -73.890142308947,
                40.604183938463436
              ],
              [
                -73.89017331472813,
                40.60418251712603
              ],
              [
                -73.89022137677411,
                40.60416887808593
              ],
              [
                -73.89027849507592,
                40.604162796369955
              ],
              [
                -73.89030103578878,
                40.60416348046474
              ],
              [
                -73.89034344230693,
                40.604150326668474
              ],
              [
                -73.89041056052695,
                40.60413196213995
              ],
              [
                -73.89045726371825,
                40.604112391171796
              ],
              [
                -73.8905125583027,
                40.60408267710517
              ],
              [
                -73.89055147645125,
                40.60406683123262
              ],
              [
                -73.89060446917647,
                40.6040537455533
              ],
              [
                -73.89064619902992,
                40.60403628382532
              ],
              [
                -73.89068367020164,
                40.60402523875585
              ],
              [
                -73.89071833666605,
                40.6040104306468
              ],
              [
                -73.89076073860078,
                40.60399996821626
              ],
              [
                -73.8908017889319,
                40.603980914426046
              ],
              [
                -73.89083019806574,
                40.60396228335258
              ],
              [
                -73.89085789102629,
                40.603942061237156
              ],
              [
                -73.89088766987723,
                40.60392400959349
              ],
              [
                -73.89092024791846,
                40.603909721461804
              ],
              [
                -73.89094875605967,
                40.60387767052611
              ],
              [
                -73.8909692995164,
                40.60386814420292
              ],
              [
                -73.8910106050327,
                40.6038275994314
              ],
              [
                -73.89104110517215,
                40.60380847892531
              ],
              [
                -73.89108508500111,
                40.60378245708838
              ],
              [
                -73.89111205598965,
                40.603763304739005
              ],
              [
                -73.89113273209944,
                40.603740328932645
              ],
              [
                -73.89114851886244,
                40.60371413762715
              ],
              [
                -73.89115796922074,
                40.60368681334989
              ],
              [
                -73.89115550834605,
                40.603656183497584
              ],
              [
                -73.89116301115692,
                40.603613269620354
              ],
              [
                -73.89117096536285,
                40.60359615399234
              ],
              [
                -73.89118930023031,
                40.60359249659942
              ],
              [
                -73.89121034637888,
                40.603600699319685
              ],
              [
                -73.89122153254314,
                40.6036077360825
              ],
              [
                -73.89123976857209,
                40.60361752749747
              ],
              [
                -73.89126797330928,
                40.60361234570178
              ],
              [
                -73.89128919675657,
                40.6036044096491
              ],
              [
                -73.89132743458912,
                40.60358694504052
              ],
              [
                -73.89135516744993,
                40.603564031821016
              ],
              [
                -73.89135661721187,
                40.60355920349191
              ],
              [
                -73.89134760000071,
                40.6035489574253
              ],
              [
                -73.8913632105614,
                40.603538847722064
              ],
              [
                -73.89138371189244,
                40.60353201113373
              ],
              [
                -73.8914387062907,
                40.60352644567227
              ],
              [
                -73.89158964434058,
                40.60350970974737
              ],
              [
                -73.89164186812114,
                40.603503044091354
              ],
              [
                -73.8916841035943,
                40.60350331174378
              ],
              [
                -73.89169922125943,
                40.60350582653148
              ],
              [
                -73.89171433958727,
                40.60350834014506
              ],
              [
                -73.89174104754184,
                40.603513365215036
              ],
              [
                -73.89177200539345,
                40.60351729746736
              ],
              [
                -73.89180164974196,
                40.60351266471673
              ],
              [
                -73.89182992930482,
                40.60350479350722
              ],
              [
                -73.89186106878775,
                40.6034899530861
              ],
              [
                -73.89191759402632,
                40.60347420942617
              ],
              [
                -73.89199316756297,
                40.60345911824154
              ],
              [
                -73.89202702408144,
                40.60345344685695
              ],
              [
                -73.89205313344806,
                40.60345144461459
              ],
              [
                -73.89208920339584,
                40.603437186905964
              ],
              [
                -73.89211472969849,
                40.60342017281502
              ],
              [
                -73.89215789933395,
                40.60340326088269
              ],
              [
                -73.8921960955575,
                40.603388483616975
              ],
              [
                -73.89223628261263,
                40.60338658014452
              ],
              [
                -73.89229339638527,
                40.60338318509448
              ],
              [
                -73.89235957546367,
                40.603384686506374
              ],
              [
                -73.89239904115597,
                40.6033838528129
              ],
              [
                -73.8924519450272,
                40.60337883490781
              ],
              [
                -73.89249498473285,
                40.60337265381055
              ],
              [
                -73.89252807514794,
                40.603373404464
              ],
              [
                -73.89256260761059,
                40.60337201543285
              ],
              [
                -73.89260628922699,
                40.60337014257243
              ],
              [
                -73.89263941349019,
                40.60337089389566
              ],
              [
                -73.89268436914168,
                40.60338033008217
              ],
              [
                -73.89274764309623,
                40.60339414999597
              ],
              [
                -73.89282355444473,
                40.603407517101985
              ],
              [
                -73.89293110384769,
                40.6034291525551
              ],
              [
                -73.89306395211347,
                40.60345792473965
              ],
              [
                -73.89312081407311,
                40.60347330186404
              ],
              [
                -73.89316163463332,
                40.60347839685457
              ],
              [
                -73.8932130138628,
                40.603480868752726
              ],
              [
                -73.8932644737467,
                40.60347798898237
              ],
              [
                -73.89330402166809,
                40.60347180383682
              ],
              [
                -73.89334081897587,
                40.60345378655277
              ],
              [
                -73.89335643009105,
                40.60344367708221
              ],
              [
                -73.89338190366958,
                40.60343470272277
              ],
              [
                -73.89342630576279,
                40.603431760880326
              ],
              [
                -73.89345382364209,
                40.60342764775285
              ],
              [
                -73.8934743652867,
                40.60341811930485
              ],
              [
                -73.89351762125344,
                40.60339316637787
              ],
              [
                -73.89355599214248,
                40.60336225207441
              ],
              [
                -73.8935930455252,
                40.60332277395287
              ],
              [
                -73.89363933020215,
                40.60327743263172
              ],
              [
                -73.89366433856775,
                40.603248040324665
              ],
              [
                -73.89368284421465,
                40.60323098973712
              ],
              [
                -73.8937054753631,
                40.6032209438402
              ],
              [
                -73.89372095675178,
                40.60322156310543
              ],
              [
                -73.8937406432839,
                40.60322652526302
              ],
              [
                -73.89375752898599,
                40.603227696992754
              ],
              [
                -73.89376531210335,
                40.60322400028398
              ],
              [
                -73.89377037361662,
                40.60321382454772
              ],
              [
                -73.89378250052948,
                40.603198332603206
              ],
              [
                -73.8937973886478,
                40.60318929291597
              ],
              [
                -73.89381784436425,
                40.60318783286892
              ],
              [
                -73.89386368619678,
                40.60318544118078
              ],
              [
                -73.89393058604774,
                40.60318584436743
              ],
              [
                -73.89400870714773,
                40.60319333999398
              ],
              [
                -73.8940881482603,
                40.603206738167636
              ],
              [
                -73.89414509368001,
                40.60321676247209
              ],
              [
                -73.89420904879614,
                40.60323220271871
              ],
              [
                -73.89431791280668,
                40.60326242864694
              ],
              [
                -73.89437553712958,
                40.60327407346867
              ],
              [
                -73.89443392639161,
                40.6032819591061
              ],
              [
                -73.89450571278714,
                40.603288322109464
              ],
              [
                -73.89453455145652,
                40.60329011131931
              ],
              [
                -73.89461326656757,
                40.60330726714865
              ],
              [
                -73.89465268435441,
                40.60331181133016
              ],
              [
                -73.89467867139669,
                40.60331787759096
              ],
              [
                -73.89469984783439,
                40.60331532073108
              ],
              [
                -73.8947295822229,
                40.60329995672739
              ],
              [
                -73.89475157385648,
                40.60328557388049
              ],
              [
                -73.89478274837147,
                40.60327076067778
              ],
              [
                -73.89481732811157,
                40.603263992989824
              ],
              [
                -73.89486245266193,
                40.60325998074217
              ],
              [
                -73.89488150427202,
                40.60325796972546
              ],
              [
                -73.89490978913655,
                40.60324740791423
              ],
              [
                -73.89494020094996,
                40.603236326354455
              ],
              [
                -73.89496911593581,
                40.60323542500599
              ],
              [
                -73.89501492149341,
                40.60323303232299
              ],
              [
                -73.89505294263799,
                40.60323436472936
              ],
              [
                -73.89506870434732,
                40.603231960455474
              ],
              [
                -73.89508387334074,
                40.60322790697739
              ],
              [
                -73.89509815412535,
                40.60322228325696
              ],
              [
                -73.8951080345795,
                40.60321714527522
              ],
              [
                -73.89512150442954,
                40.603207974018034
              ],
              [
                -73.89513292735533,
                40.6031973104271
              ],
              [
                -73.89514202241102,
                40.6031854167734
              ],
              [
                -73.89514890576154,
                40.60317170791783
              ],
              [
                -73.89515269577515,
                40.60315731650591
              ],
              [
                -73.8951532864784,
                40.60314264494938
              ],
              [
                -73.89518646376794,
                40.60313532687786
              ],
              [
                -73.89521755076294,
                40.60312855521406
              ],
              [
                -73.89526973559381,
                40.60312191601927
              ],
              [
                -73.89530920285445,
                40.603121080155425
              ],
              [
                -73.89535002955812,
                40.60312348603335
              ],
              [
                -73.89540348623548,
                40.60313081668848
              ],
              [
                -73.89546191575252,
                40.60313601199154
              ],
              [
                -73.89551469074578,
                40.60314172297262
              ],
              [
                -73.89554633671868,
                40.60314461079189
              ],
              [
                -73.89561514064073,
                40.60316598335386
              ],
              [
                -73.89566221716372,
                40.603177562497564
              ],
              [
                -73.89571626303791,
                40.6031972434598
              ],
              [
                -73.89578792525599,
                40.603211645611054
              ],
              [
                -73.89585609066721,
                40.60322873557059
              ],
              [
                -73.89590669508553,
                40.60324031781086
              ],
              [
                -73.89593693744821,
                40.6032426552581
              ],
              [
                -73.89596997504184,
                40.60325147341411
              ],
              [
                -73.89600302238496,
                40.603254884899656
              ],
              [
                -73.89604384457012,
                40.603259978867996
              ],
              [
                -73.89607199797904,
                40.603262863885924
              ],
              [
                -73.8961028735264,
                40.60327217358966
              ],
              [
                -73.89614004022837,
                40.6032879936268
              ],
              [
                -73.89617091227475,
                40.60329999215271
              ],
              [
                -73.89619757325677,
                40.603310367362226
              ],
              [
                -73.8962467332312,
                40.603324088668614
              ],
              [
                -73.89628190774995,
                40.60332700895574
              ],
              [
                -73.89629815197993,
                40.6033238966817
              ],
              [
                -73.89632014324167,
                40.60330954050689
              ],
              [
                -73.89634068580082,
                40.60329998507814
              ],
              [
                -73.8963675258509,
                40.60329158876306
              ],
              [
                -73.89640069803062,
                40.60328695867762
              ],
              [
                -73.89644297536412,
                40.60328453460272
              ],
              [
                -73.89647618572916,
                40.60327724416621
              ],
              [
                -73.8964853737435,
                40.60327406955487
              ],
              [
                -73.89648830856083,
                40.60326441212934
              ],
              [
                -73.89649469553736,
                40.60325747630931
              ],
              [
                -73.89651302448962,
                40.6032565353855
              ],
              [
                -73.89654755812676,
                40.60325514582821
              ],
              [
                -73.89658493981706,
                40.6032521398211
              ],
              [
                -73.89666088711941,
                40.60326553089423
              ],
              [
                -73.89673399613444,
                40.603277797256126
              ],
              [
                -73.8968036118789,
                40.60329005708448
              ],
              [
                -73.89688024236807,
                40.60330504166095
              ],
              [
                -73.89698218409178,
                40.60332178246928
              ],
              [
                -73.8970419760686,
                40.60333021753445
              ],
              [
                -73.89706804280142,
                40.603330931605875
              ],
              [
                -73.89708289097219,
                40.60332458013397
              ],
              [
                -73.89710904509039,
                40.60331722556555
              ],
              [
                -73.89712805517956,
                40.60331787718356
              ],
              [
                -73.89715888700431,
                40.60333256693186
              ],
              [
                -73.89723313102549,
                40.60337224796697
              ],
              [
                -73.8972926689453,
                40.60340214316442
              ],
              [
                -73.89730601994378,
                40.60340599938736
              ],
              [
                -73.89732290509437,
                40.60340717059261
              ],
              [
                -73.8973370660497,
                40.60340188915286
              ],
              [
                -73.89735739381571,
                40.60341115994675
              ],
              [
                -73.8973833796039,
                40.6034172255945
              ],
              [
                -73.89739961901024,
                40.60341680367943
              ],
              [
                -73.89743288129814,
                40.60340144329912
              ],
              [
                -73.89746746061941,
                40.60339467364118
              ],
              [
                -73.89748089896779,
                40.603390461883016
              ],
              [
                -73.89749017011742,
                40.603381936550555
              ],
              [
                -73.8974846020403,
                40.60337438456127
              ],
              [
                -73.8974642755875,
                40.60336511312106
              ],
              [
                -73.89746504398501,
                40.603358692077414
              ],
              [
                -73.897480565615,
                40.60335662320115
              ],
              [
                -73.89752215614736,
                40.60335529560034
              ],
              [
                -73.89755308037532,
                40.603359253237464
              ],
              [
                -73.89758896908246,
                40.60336376461837
              ],
              [
                -73.89762630991217,
                40.60336347622985
              ],
              [
                -73.89769932584397,
                40.603386469626514
              ],
              [
                -73.89776039488824,
                40.603406185530325
              ],
              [
                -73.89780602200346,
                40.60342259052972
              ],
              [
                -73.89783200629752,
                40.60342865657719
              ],
              [
                -73.89785879654409,
                40.60342830060091
              ],
              [
                -73.89787509197035,
                40.603417120613685
              ],
              [
                -73.89788865968686,
                40.603402178510535
              ],
              [
                -73.89793178098228,
                40.60332534051492
              ],
              [
                -73.89800185480577,
                40.603345968944865
              ],
              [
                -73.89807627731452,
                40.60340231401518
              ],
              [
                -73.89815265901343,
                40.603457114897516
              ],
              [
                -73.89821489308682,
                40.60349967591782
              ],
              [
                -73.89828301111473,
                40.6035366338127
              ],
              [
                -73.8983561492238,
                40.60356751987694
              ],
              [
                -73.8984333798716,
                40.603591942408954
              ],
              [
                -73.8985137236098,
                40.60360959167794
              ],
              [
                -73.89857368491455,
                40.60363213883991
              ],
              [
                -73.89863711790828,
                40.603648248415226
              ],
              [
                -73.89870283930944,
                40.60365761989448
              ],
              [
                -73.89876962314602,
                40.603660078461445
              ],
              [
                -73.89880755323303,
                40.603657090412455
              ],
              [
                -73.8988450766934,
                40.60365191820797
              ],
              [
                -73.89892158010787,
                40.60363628250317
              ],
              [
                -73.89900742307576,
                40.6036161748893
              ],
              [
                -73.89919990850507,
                40.60357468338286
              ],
              [
                -73.8995314914598,
                40.60343798627703
              ],
              [
                -73.8998349343069,
                40.60333979138547
              ],
              [
                -73.90002589744076,
                40.603339957658044
              ],
              [
                -73.90014380076332,
                40.60337002677496
              ],
              [
                -73.9002785777926,
                40.60338298557928
              ],
              [
                -73.90041343986005,
                40.603340294117864
              ],
              [
                -73.90050901645664,
                40.60327616396207
              ],
              [
                -73.90062134878224,
                40.603276261150086
              ],
              [
                -73.90077857954725,
                40.60329780153353
              ],
              [
                -73.90087406740976,
                40.60329360372775
              ],
              [
                -73.90098085235894,
                40.60324660650749
              ],
              [
                -73.90103135038235,
                40.60328089632068
              ],
              [
                -73.90113803879981,
                40.60329811130186
              ],
              [
                -73.90131218457975,
                40.603276856435485
              ],
              [
                -73.90143013761237,
                40.603272677456
              ],
              [
                -73.9016493483106,
                40.60316156254692
              ],
              [
                -73.90171674023382,
                40.6031659015738
              ],
              [
                -73.9017504831144,
                40.60313596548357
              ],
              [
                -73.90186285142322,
                40.60311037507594
              ],
              [
                -73.90195271046693,
                40.60311473314686
              ],
              [
                -73.90201449163602,
                40.60311478583652
              ],
              [
                -73.90208761376871,
                40.60304207364253
              ],
              [
                -73.90225623536327,
                40.60295660037571
              ],
              [
                -73.90234610004825,
                40.60295667676808
              ],
              [
                -73.90242477475519,
                40.6029267774762
              ],
              [
                -73.9024866508553,
                40.60286261655582
              ],
              [
                -73.90255408560772,
                40.60283698901413
              ],
              [
                -73.902576614479,
                40.60279419971393
              ],
              [
                -73.90264403730299,
                40.60277713368875
              ],
              [
                -73.90268897007445,
                40.60277717174257
              ],
              [
                -73.9027507945804,
                40.60274725745622
              ],
              [
                -73.90283654031418,
                40.60275824936524
              ],
              [
                -73.90289817410734,
                40.60263905970299
              ],
              [
                -73.90302393445054,
                40.602607227452005
              ],
              [
                -73.90306024236759,
                40.602613645300934
              ],
              [
                -73.9031328460829,
                40.602634999478695
              ],
              [
                -73.90319707837811,
                40.602649959509044
              ],
              [
                -73.90323626727096,
                40.602596759271336
              ],
              [
                -73.90327267391594,
                40.60253504033163
              ],
              [
                -73.90330627451772,
                40.60248183586857
              ],
              [
                -73.90332866108642,
                40.60245630398559
              ],
              [
                -73.90332579345151,
                40.602507404177345
              ],
              [
                -73.90335926212688,
                40.60254576015766
              ],
              [
                -73.90341789367874,
                40.60256923136666
              ],
              [
                -73.90358548780357,
                40.60258640628678
              ],
              [
                -73.9036692796328,
                40.602599252494066
              ],
              [
                -73.90368597722761,
                40.6026439820409
              ],
              [
                -73.90376136276835,
                40.60267385533677
              ],
              [
                -73.90385350349119,
                40.60270800085838
              ],
              [
                -73.9039344950867,
                40.60272510278642
              ],
              [
                -73.90399039590088,
                40.6027059870072
              ],
              [
                -73.90413019682194,
                40.60262305982415
              ],
              [
                -73.90434259119111,
                40.602570004735014
              ],
              [
                -73.90457452701459,
                40.602525481994604
              ],
              [
                -73.90484544064694,
                40.60257468042595
              ],
              [
                -73.90489568749854,
                40.602602402865344
              ],
              [
                -73.90497116186197,
                40.60257052619085
              ],
              [
                -73.90516387803422,
                40.602602624483175
              ],
              [
                -73.90527559951936,
                40.602619751824705
              ],
              [
                -73.90531188284139,
                40.602643203645044
              ],
              [
                -73.9053566064639,
                40.602626206283105
              ],
              [
                -73.90538730361908,
                40.60264965347927
              ],
              [
                -73.90545995334338,
                40.60263906728645
              ],
              [
                -73.90551306816398,
                40.6026135590793
              ],
              [
                -73.90556336027852,
                40.60260934215845
              ],
              [
                -73.90562200191036,
                40.602626424530015
              ],
              [
                -73.90567510895289,
                40.602607304464634
              ],
              [
                -73.90567795028957,
                40.60257323839539
              ],
              [
                -73.90571429217962,
                40.602556232842254
              ],
              [
                -73.9057757380013,
                40.602566930385514
              ],
              [
                -73.9058121025746,
                40.60253289061944
              ],
              [
                -73.90585959129852,
                40.60253505949457
              ],
              [
                -73.90594063485345,
                40.60251596168406
              ],
              [
                -73.90602170167863,
                40.60247983013725
              ],
              [
                -73.90610553378727,
                40.602462864456804
              ],
              [
                -73.906197694686,
                40.6024842321717
              ],
              [
                -73.90628991431532,
                40.60246301494524
              ],
              [
                -73.906438010992,
                40.602439713683964
              ],
              [
                -73.90651073121998,
                40.602378023109374
              ],
              [
                -73.90657223869778,
                40.60234400472531
              ],
              [
                -73.90665322757248,
                40.602363233026466
              ],
              [
                -73.90669509431963,
                40.60239094887763
              ],
              [
                -73.90676215947408,
                40.60237822736159
              ],
              [
                -73.90683199105865,
                40.6023846717135
              ],
              [
                -73.90694091369421,
                40.60240605241943
              ],
              [
                -73.90700516441797,
                40.60240823389004
              ],
              [
                -73.90708065697879,
                40.60236145110831
              ],
              [
                -73.90714497577616,
                40.60231465757442
              ],
              [
                -73.90722328631641,
                40.602250842271935
              ],
              [
                -73.90730710229315,
                40.602246651645885
              ],
              [
                -73.90734337430463,
                40.60227862100558
              ],
              [
                -73.90741315696371,
                40.60232126232739
              ],
              [
                -73.90746904037098,
                40.60231279065254
              ],
              [
                -73.90757801658788,
                40.60229584363696
              ],
              [
                -73.90767859330253,
                40.602291666720006
              ],
              [
                -73.90770657902236,
                40.602255489657146
              ],
              [
                -73.90766193140539,
                40.602219255928695
              ],
              [
                -73.90773743343888,
                40.60216608449969
              ],
              [
                -73.90780451831077,
                40.60213845765395
              ],
              [
                -73.90783802758442,
                40.60214913101066
              ],
              [
                -73.90788270462727,
                40.60216407223665
              ],
              [
                -73.90793857675921,
                40.60216411700606
              ],
              [
                -73.90798331091122,
                40.60213860094357
              ],
              [
                -73.90796940375016,
                40.60209387425564
              ],
              [
                -73.90805043964112,
                40.6020790338784
              ],
              [
                -73.90812586795658,
                40.60207909420143
              ],
              [
                -73.90822367322265,
                40.602057879720974
              ],
              [
                -73.90827682294537,
                40.60200681954893
              ],
              [
                -73.908324335646,
                40.60199195154881
              ],
              [
                -73.9084165741453,
                40.60195582722336
              ],
              [
                -73.90848647031369,
                40.60191542677815
              ],
              [
                -73.90845303178325,
                40.60185365039274
              ],
              [
                -73.90848381882816,
                40.601811088823
              ],
              [
                -73.90859284082919,
                40.60176007298139
              ],
              [
                -73.90868507886448,
                40.60172394726692
              ],
              [
                -73.90877728320666,
                40.60171337401026
              ],
              [
                -73.90884156530147,
                40.60169213306004
              ],
              [
                -73.90886397208234,
                40.601649564740526
              ],
              [
                -73.90888637011734,
                40.601613384130545
              ],
              [
                -73.90894225138031,
                40.60160704069869
              ],
              [
                -73.90899811500016,
                40.60161347266757
              ],
              [
                -73.90904839322077,
                40.60161777072552
              ],
              [
                -73.90911535948183,
                40.6016774439939
              ],
              [
                -73.90915998079213,
                40.60173284130614
              ],
              [
                -73.90919062963887,
                40.601792485818294
              ],
              [
                -73.909277170665,
                40.60183726971906
              ],
              [
                -73.90932461695023,
                40.601871375565395
              ],
              [
                -73.90941393690319,
                40.601926807532934
              ],
              [
                -73.90951771292929,
                40.601956558870725
              ],
              [
                -73.90960169091878,
                40.60197161379309
              ],
              [
                -73.90964543475901,
                40.6019797321461
              ],
              [
                -73.90969049558633,
                40.60199341197879
              ],
              [
                -73.90971898460656,
                40.602007077101696
              ],
              [
                -73.90975806473323,
                40.6020313608281
              ],
              [
                -73.9097964992642,
                40.60204301300349
              ],
              [
                -73.9098276518062,
                40.60204758503006
              ],
              [
                -73.90987671309695,
                40.60204509667054
              ],
              [
                -73.90992843512186,
                40.602035536455276
              ],
              [
                -73.9100086835059,
                40.60201084068342
              ],
              [
                -73.91008627743624,
                40.60198917304695
              ],
              [
                -73.91018507191166,
                40.60197661876325
              ],
              [
                -73.91026730340195,
                40.60195647093538
              ],
              [
                -73.91029382758018,
                40.60195194506717
              ],
              [
                -73.91032498284824,
                40.60195399011824
              ],
              [
                -73.91046418020109,
                40.601968751419854
              ],
              [
                -73.91059343421288,
                40.601983505990546
              ],
              [
                -73.9106762807201,
                40.60199923388728
              ],
              [
                -73.91073123013267,
                40.60202137602758
              ],
              [
                -73.91072006391992,
                40.602107882275334
              ],
              [
                -73.91061414321331,
                40.602114581859915
              ],
              [
                -73.9102805229684,
                40.6021356791902
              ],
              [
                -73.91025181119574,
                40.60213749406501
              ],
              [
                -73.90940934304653,
                40.602190765755324
              ],
              [
                -73.90941924579833,
                40.602271305847204
              ],
              [
                -73.90943138265835,
                40.602270629212775
              ],
              [
                -73.90942469395955,
                40.60221004622427
              ],
              [
                -73.90942704334952,
                40.60220472265799
              ],
              [
                -73.90951755361037,
                40.60220046315532
              ],
              [
                -73.90951989175076,
                40.602200353589325
              ],
              [
                -73.90963206821161,
                40.60219507471625
              ],
              [
                -73.90963361898599,
                40.6021950012175
              ],
              [
                -73.90964573480426,
                40.60219443159515
              ],
              [
                -73.90965206539155,
                40.60219413300905
              ],
              [
                -73.90969237078123,
                40.60219223676134
              ],
              [
                -73.90970812690573,
                40.602191495427235
              ],
              [
                -73.90973216268853,
                40.60219036422046
              ],
              [
                -73.90975360754564,
                40.60218935561577
              ],
              [
                -73.90978008939538,
                40.60218810888166
              ],
              [
                -73.90980032704539,
                40.60218715577803
              ],
              [
                -73.90981964785841,
                40.60218624651409
              ],
              [
                -73.90982047676913,
                40.60218620762759
              ],
              [
                -73.909862033319,
                40.60218425249421
              ],
              [
                -73.90988771570824,
                40.60218304347249
              ],
              [
                -73.90990426776705,
                40.60218226487402
              ],
              [
                -73.90993251206359,
                40.60218093488415
              ],
              [
                -73.90995100154383,
                40.6021800649861
              ],
              [
                -73.90997268049512,
                40.60217904446189
              ],
              [
                -73.90999627049041,
                40.60217793429433
              ],
              [
                -73.91001923951535,
                40.60217685379102
              ],
              [
                -73.91004020253801,
                40.60217586670196
              ],
              [
                -73.91005595359917,
                40.6021751253163
              ],
              [
                -73.91008138165765,
                40.602173929287105
              ],
              [
                -73.91010418626243,
                40.60217285533925
              ],
              [
                -73.91012895752176,
                40.6021716899462
              ],
              [
                -73.91015378417322,
                40.60217052140797
              ],
              [
                -73.91016998540684,
                40.60216975891488
              ],
              [
                -73.9101967479393,
                40.60216849973944
              ],
              [
                -73.91021056882964,
                40.602167848965415
              ],
              [
                -73.91024293172588,
                40.6021663267779
              ],
              [
                -73.91025051699417,
                40.60216596882822
              ],
              [
                -73.91028579107838,
                40.60216430817758
              ],
              [
                -73.91029576547825,
                40.60216383951041
              ],
              [
                -73.91032666200859,
                40.6021623846742
              ],
              [
                -73.91033802012439,
                40.60216185007125
              ],
              [
                -73.91037483200661,
                40.60216011771769
              ],
              [
                -73.91038608351266,
                40.60215958855539
              ],
              [
                -73.91041650590408,
                40.602158156444325
              ],
              [
                -73.91042744923307,
                40.60215764178028
              ],
              [
                -73.91045997763976,
                40.602156110279026
              ],
              [
                -73.9104711442972,
                40.60215558489542
              ],
              [
                -73.91049990864467,
                40.60215423104709
              ],
              [
                -73.91051037212462,
                40.602153737947845
              ],
              [
                -73.91054088529246,
                40.602152302021395
              ],
              [
                -73.91055007891823,
                40.60215186958073
              ],
              [
                -73.91058133746694,
                40.60215039786989
              ],
              [
                -73.91058959271591,
                40.60215000959379
              ],
              [
                -73.91062070288947,
                40.60214854512802
              ],
              [
                -73.91062829606578,
                40.6021481874947
              ],
              [
                -73.91065876834338,
                40.60214675368314
              ],
              [
                -73.91070633053948,
                40.60214451526533
              ],
              [
                -73.91071565416222,
                40.60214811818798
              ],
              [
                -73.9107226252365,
                40.60215882888604
              ],
              [
                -73.91071154457025,
                40.60241949317829
              ],
              [
                -73.91043547430496,
                40.60244582989798
              ],
              [
                -73.91042934860617,
                40.6024464141566
              ],
              [
                -73.9103029227871,
                40.60245847448841
              ],
              [
                -73.91027633909889,
                40.60246101073568
              ],
              [
                -73.91025100644434,
                40.60246342783535
              ],
              [
                -73.91022711673475,
                40.602465706002164
              ],
              [
                -73.91020916982755,
                40.60246741904027
              ],
              [
                -73.91018019243549,
                40.602470182910174
              ],
              [
                -73.91015996416098,
                40.602472112615686
              ],
              [
                -73.91013283507168,
                40.60247470000243
              ],
              [
                -73.91011106096981,
                40.60247677725652
              ],
              [
                -73.91009512657575,
                40.6024782975171
              ],
              [
                -73.9100671150931,
                40.602480970475604
              ],
              [
                -73.91004411786929,
                40.60248316369524
              ],
              [
                -73.91002236530495,
                40.60248523877155
              ],
              [
                -73.90999151650696,
                40.60248818172515
              ],
              [
                -73.90997533502276,
                40.602489724727064
              ],
              [
                -73.90994939430503,
                40.602492198913986
              ],
              [
                -73.90992660678593,
                40.60249437418132
              ],
              [
                -73.90990680276435,
                40.60249626296263
              ],
              [
                -73.90988173079907,
                40.60249865438574
              ],
              [
                -73.90985487053688,
                40.60250121611813
              ],
              [
                -73.90983331888152,
                40.60250327322308
              ],
              [
                -73.90980438696396,
                40.60250603150542
              ],
              [
                -73.9097846079935,
                40.60250791928021
              ],
              [
                -73.90975811637514,
                40.602510445595655
              ],
              [
                -73.90973128995621,
                40.602513004645324
              ],
              [
                -73.90972417524168,
                40.60251368307858
              ],
              [
                -73.90968807012342,
                40.602517127424825
              ],
              [
                -73.90965952112998,
                40.6025198514605
              ],
              [
                -73.90964070192067,
                40.602521645981106
              ],
              [
                -73.9096261497805,
                40.602523034412805
              ],
              [
                -73.90959719234452,
                40.60252579597342
              ],
              [
                -73.9095731102396,
                40.602528093621366
              ],
              [
                -73.90906874090433,
                40.602576203912996
              ],
              [
                -73.90904999552458,
                40.602577991861644
              ],
              [
                -73.90903931176973,
                40.60256941964955
              ],
              [
                -73.90903202754454,
                40.602523822826754
              ],
              [
                -73.90901848567071,
                40.60252482766916
              ],
              [
                -73.90903689530526,
                40.60267734322018
              ],
              [
                -73.90905328065217,
                40.60267738366236
              ],
              [
                -73.90904427233981,
                40.60260255330935
              ],
              [
                -73.90907342707447,
                40.60259973123097
              ],
              [
                -73.90909403356345,
                40.602597736009244
              ],
              [
                -73.90913303330453,
                40.602593960922725
              ],
              [
                -73.90915072464523,
                40.60259224902323
              ],
              [
                -73.90918404797516,
                40.60258902278094
              ],
              [
                -73.90920512642842,
                40.60258698200923
              ],
              [
                -73.90923282104954,
                40.60258430180697
              ],
              [
                -73.9092614174048,
                40.6025815335193
              ],
              [
                -73.90928958025673,
                40.60257880709977
              ],
              [
                -73.90930709023293,
                40.60257711145099
              ],
              [
                -73.90933598554832,
                40.60257431423067
              ],
              [
                -73.90936888678964,
                40.602571129316644
              ],
              [
                -73.90939318505413,
                40.602568776932316
              ],
              [
                -73.90942194736621,
                40.60256599282036
              ],
              [
                -73.90943690776788,
                40.60256454492836
              ],
              [
                -73.90946895123075,
                40.60256144273933
              ],
              [
                -73.9094999608392,
                40.602558440747195
              ],
              [
                -73.90952963345006,
                40.60255556836719
              ],
              [
                -73.90955752545295,
                40.60255286746831
              ],
              [
                -73.90958653110046,
                40.60255005988509
              ],
              [
                -73.90960749565794,
                40.60254803034201
              ],
              [
                -73.90963956416478,
                40.60254492594713
              ],
              [
                -73.90966007411464,
                40.60254294059984
              ],
              [
                -73.90968750292872,
                40.602540284873236
              ],
              [
                -73.90971635138919,
                40.60253749187628
              ],
              [
                -73.9097384814668,
                40.60253534913751
              ],
              [
                -73.90975051902264,
                40.60253418437883
              ],
              [
                -73.90979379588099,
                40.602529994608894
              ],
              [
                -73.90981528119745,
                40.60252791501045
              ],
              [
                -73.90986311771721,
                40.60252328366731
              ],
              [
                -73.90987755469114,
                40.6025218852311
              ],
              [
                -73.90991192081292,
                40.60251855857538
              ],
              [
                -73.9099316760778,
                40.60251664613335
              ],
              [
                -73.90996588677584,
                40.60251333475238
              ],
              [
                -73.9099910825552,
                40.602510895159526
              ],
              [
                -73.91004612016683,
                40.602505566594765
              ],
              [
                -73.91005288052807,
                40.602504912658624
              ],
              [
                -73.91008979311793,
                40.6025013381567
              ],
              [
                -73.91009326067807,
                40.60250100380062
              ],
              [
                -73.91013268083921,
                40.60249718581631
              ],
              [
                -73.91015123956895,
                40.60249538933629
              ],
              [
                -73.91018938472047,
                40.602491696652756
              ],
              [
                -73.91021480448809,
                40.602489235239496
              ],
              [
                -73.91024244561032,
                40.60248655977797
              ],
              [
                -73.91027032458491,
                40.60248386037127
              ],
              [
                -73.91029630204753,
                40.60248134610121
              ],
              [
                -73.91032083788154,
                40.602478969581966
              ],
              [
                -73.91035235455522,
                40.60247591833451
              ],
              [
                -73.91038614226254,
                40.60247264821339
              ],
              [
                -73.91041217225653,
                40.602470127927056
              ],
              [
                -73.91044228268643,
                40.60246721226258
              ],
              [
                -73.91046885872737,
                40.60246463911457
              ],
              [
                -73.91050174667028,
                40.602461455541146
              ],
              [
                -73.91052511652381,
                40.602459192827915
              ],
              [
                -73.91054326019861,
                40.602457436503435
              ],
              [
                -73.9105785304056,
                40.60245402089172
              ],
              [
                -73.9105976573654,
                40.602452169329524
              ],
              [
                -73.91062491178121,
                40.602449530834186
              ],
              [
                -73.91066114943791,
                40.60244602313811
              ],
              [
                -73.91068388222124,
                40.6024438218831
              ],
              [
                -73.91070227869017,
                40.602442040098055
              ],
              [
                -73.91075123972264,
                40.60243729986253
              ],
              [
                -73.91074939384244,
                40.60241889132751
              ],
              [
                -73.91073679022985,
                40.6024184967246
              ],
              [
                -73.91074229839457,
                40.60215033418665
              ],
              [
                -73.91075830846516,
                40.602027500983425
              ],
              [
                -73.91091553413821,
                40.602050454175654
              ],
              [
                -73.91148979015881,
                40.60212474557265
              ],
              [
                -73.91148569138097,
                40.60214745066214
              ],
              [
                -73.911555291408,
                40.602155464729776
              ],
              [
                -73.91155222594735,
                40.60221315858475
              ],
              [
                -73.91132243660033,
                40.602185450098375
              ],
              [
                -73.91112022777182,
                40.602160317305405
              ],
              [
                -73.91095863542682,
                40.60213922142222
              ],
              [
                -73.91096008663212,
                40.602131729803695
              ],
              [
                -73.91093999639928,
                40.60212847422249
              ],
              [
                -73.91091626763057,
                40.60220676550788
              ],
              [
                -73.91092886882552,
                40.60220894310244
              ],
              [
                -73.91094453904712,
                40.60215164384616
              ],
              [
                -73.91105083185793,
                40.60216460647587
              ],
              [
                -73.91105289498671,
                40.60216485735607
              ],
              [
                -73.91106495246135,
                40.6021663273742
              ],
              [
                -73.91108453833075,
                40.60216871624981
              ],
              [
                -73.91151135057045,
                40.60222076259748
              ],
              [
                -73.91154234369162,
                40.60222454078565
              ],
              [
                -73.91151957194396,
                40.6023968685693
              ],
              [
                -73.91150953757199,
                40.60244206876105
              ],
              [
                -73.9110514931486,
                40.60240834888496
              ],
              [
                -73.91103082003085,
                40.60240682595815
              ],
              [
                -73.91098165500402,
                40.602403206448635
              ],
              [
                -73.91098752750092,
                40.60234836998357
              ],
              [
                -73.91087683378183,
                40.60234235483657
              ],
              [
                -73.91086536585769,
                40.602490483288435
              ],
              [
                -73.91088639353035,
                40.602492338060195
              ],
              [
                -73.91089355171218,
                40.60236306418763
              ],
              [
                -73.91094634346705,
                40.602364642186586
              ],
              [
                -73.91095655549329,
                40.602375711045866
              ],
              [
                -73.91094007982434,
                40.60249781432193
              ],
              [
                -73.9109522497182,
                40.60249892173824
              ],
              [
                -73.91095957598458,
                40.60243305225949
              ],
              [
                -73.91097269662909,
                40.602423840445034
              ],
              [
                -73.9110172449766,
                40.602426830495006
              ],
              [
                -73.91104924152093,
                40.60242897869628
              ],
              [
                -73.91111012276473,
                40.60243306442648
              ],
              [
                -73.91111283637792,
                40.60243324661913
              ],
              [
                -73.91113463385531,
                40.602434710592746
              ],
              [
                -73.91117505476424,
                40.602437423479735
              ],
              [
                -73.91118973777338,
                40.602438408674146
              ],
              [
                -73.91122379607837,
                40.60244069445367
              ],
              [
                -73.9112492528857,
                40.60244240331183
              ],
              [
                -73.91128075538136,
                40.60244451806375
              ],
              [
                -73.91130574252344,
                40.602446195218796
              ],
              [
                -73.91133770304369,
                40.60244833996134
              ],
              [
                -73.9113646086419,
                40.60245014590577
              ],
              [
                -73.91140473154945,
                40.60245283821128
              ],
              [
                -73.91143632064774,
                40.60245495901835
              ],
              [
                -73.91146180823614,
                40.60245666902581
              ],
              [
                -73.91149232921046,
                40.60245871812983
              ],
              [
                -73.91151505923006,
                40.60246024305988
              ],
              [
                -73.91154361361349,
                40.60246415724636
              ],
              [
                -73.91156976933146,
                40.602467742228065
              ],
              [
                -73.91160007717856,
                40.60247189698133
              ],
              [
                -73.911637820139,
                40.602477070324646
              ],
              [
                -73.91166041994477,
                40.60248016788978
              ],
              [
                -73.91170103252993,
                40.602485734263695
              ],
              [
                -73.9117202557417,
                40.60248836918769
              ],
              [
                -73.91175977525427,
                40.6024937859353
              ],
              [
                -73.91178116749288,
                40.60249671904108
              ],
              [
                -73.91181118946098,
                40.6025008334797
              ],
              [
                -73.91182630076685,
                40.602502904175324
              ],
              [
                -73.91186977947989,
                40.602508863534
              ],
              [
                -73.91188459718971,
                40.602510895130145
              ],
              [
                -73.91192036060177,
                40.60251579730855
              ],
              [
                -73.91194080371508,
                40.60251859948695
              ],
              [
                -73.91197311405725,
                40.60252302740842
              ],
              [
                -73.91199845033861,
                40.60252649975535
              ],
              [
                -73.91203349801042,
                40.60253130418354
              ],
              [
                -73.91206013131695,
                40.60253495542559
              ],
              [
                -73.9120873814935,
                40.602538689390265
              ],
              [
                -73.91211791202088,
                40.60254287500265
              ],
              [
                -73.91212846663146,
                40.602544321310965
              ],
              [
                -73.91225241960679,
                40.60256131083096
              ],
              [
                -73.91225841311291,
                40.60256213110643
              ],
              [
                -73.91229292482903,
                40.602566861500954
              ],
              [
                -73.91229536833681,
                40.60256719658126
              ],
              [
                -73.9124011270441,
                40.602581692958154
              ],
              [
                -73.91240792926915,
                40.60258262474648
              ],
              [
                -73.91245933636156,
                40.60258967031997
              ],
              [
                -73.91247938988259,
                40.60259292443454
              ],
              [
                -73.9124900672872,
                40.60260684871142
              ],
              [
                -73.91247528777578,
                40.602673398809095
              ],
              [
                -73.91248882820365,
                40.602674150771854
              ],
              [
                -73.91251889258051,
                40.60254030930362
              ],
              [
                -73.91259129985662,
                40.60254942169341
              ],
              [
                -73.91255889942957,
                40.6026796937017
              ],
              [
                -73.91257290407538,
                40.60268330003465
              ],
              [
                -73.91261098676527,
                40.602532006593805
              ],
              [
                -73.91250311483624,
                40.60251641751612
              ],
              [
                -73.91245058778547,
                40.60256043098706
              ],
              [
                -73.91243560024542,
                40.60256536017185
              ],
              [
                -73.91240284062849,
                40.60256072949534
              ],
              [
                -73.91238406029152,
                40.602558074082104
              ],
              [
                -73.91236146275128,
                40.60255487949404
              ],
              [
                -73.91234251100596,
                40.602552199819186
              ],
              [
                -73.91231860788426,
                40.602548821452615
              ],
              [
                -73.91229559896385,
                40.6025455677351
              ],
              [
                -73.91227574643298,
                40.602542761714844
              ],
              [
                -73.91225690633148,
                40.60254009802631
              ],
              [
                -73.91223208880625,
                40.602536588773404
              ],
              [
                -73.91220966467009,
                40.60253341841225
              ],
              [
                -73.91219875740704,
                40.60253187685288
              ],
              [
                -73.91208793773212,
                40.60251620941023
              ],
              [
                -73.9120862627353,
                40.60251597258292
              ],
              [
                -73.91162568786596,
                40.602450856416695
              ],
              [
                -73.91162277849257,
                40.6024504457434
              ],
              [
                -73.91154411182994,
                40.60243932310391
              ],
              [
                -73.91155964713951,
                40.60232040358004
              ],
              [
                -73.91157412225583,
                40.60232151203432
              ],
              [
                -73.9115770834805,
                40.602288741416
              ],
              [
                -73.91164855920921,
                40.60229392864238
              ],
              [
                -73.91165189230664,
                40.60227825784424
              ],
              [
                -73.91182941479134,
                40.60228942881859
              ],
              [
                -73.91202422222574,
                40.60230280855631
              ],
              [
                -73.91225218426689,
                40.60232444717415
              ],
              [
                -73.91225514662504,
                40.60228989185114
              ],
              [
                -73.91230602075088,
                40.602297891165506
              ],
              [
                -73.91251410692395,
                40.60237254361556
              ],
              [
                -73.9125051581593,
                40.60238604116294
              ],
              [
                -73.91252801774947,
                40.60239217890725
              ],
              [
                -73.91254170182432,
                40.602364439955366
              ],
              [
                -73.9123215242302,
                40.60228332812272
              ],
              [
                -73.91230939977284,
                40.602275084477924
              ],
              [
                -73.91216040266461,
                40.60225973592647
              ],
              [
                -73.91181195390864,
                40.60223276224484
              ],
              [
                -73.91157836556718,
                40.60221787119119
              ],
              [
                -73.91158427689317,
                40.60213548840343
              ],
              [
                -73.91176263325637,
                40.60215570368334
              ],
              [
                -73.91195753841404,
                40.60215484195358
              ],
              [
                -73.91212130476976,
                40.602139808917805
              ],
              [
                -73.9122678192514,
                40.60213688891289
              ],
              [
                -73.91243685475212,
                40.60214762929161
              ],
              [
                -73.91256809408638,
                40.60216541442624
              ],
              [
                -73.912674147097,
                40.60217913779714
              ],
              [
                -73.91277093173233,
                40.60218274884301
              ],
              [
                -73.91281401274672,
                40.60219086548635
              ],
              [
                -73.91292868939256,
                40.60220004758419
              ],
              [
                -73.9129903429,
                40.60220009427759
              ],
              [
                -73.91305994409429,
                40.602206210166656
              ],
              [
                -73.91309691306819,
                40.60221445392554
              ],
              [
                -73.91292175545611,
                40.60243208532434
              ],
              [
                -73.91292596573744,
                40.602434586374365
              ],
              [
                -73.91288693485376,
                40.60248470364052
              ],
              [
                -73.91274890958807,
                40.60242270427682
              ],
              [
                -73.91272398622357,
                40.60245326294611
              ],
              [
                -73.91285319795077,
                40.6025074055746
              ],
              [
                -73.91270696033584,
                40.6026947094838
              ],
              [
                -73.91273866862338,
                40.60270867701649
              ],
              [
                -73.91290604871081,
                40.602491169005475
              ],
              [
                -73.91290000359776,
                40.602487953559965
              ],
              [
                -73.9131165873372,
                40.6022202559288
              ],
              [
                -73.91317591595909,
                40.60223964770512
              ],
              [
                -73.91324485254826,
                40.60224677368357
              ],
              [
                -73.91329854635799,
                40.60225035124641
              ],
              [
                -73.91331444593793,
                40.60225844819831
              ],
              [
                -73.91336876508088,
                40.60229082781673
              ],
              [
                -73.91340321353769,
                40.602310559901
              ],
              [
                -73.91347511544238,
                40.6023492958734
              ],
              [
                -73.9133567063621,
                40.60243100982437
              ],
              [
                -73.91336279315738,
                40.60243938115535
              ],
              [
                -73.91332373554694,
                40.602462591353046
              ],
              [
                -73.91331885909023,
                40.60246072776628
              ],
              [
                -73.91328222596215,
                40.602496024160615
              ],
              [
                -73.91329806292846,
                40.602509050780895
              ],
              [
                -73.91300017523358,
                40.602740291683446
              ],
              [
                -73.91301723241872,
                40.602754248717616
              ],
              [
                -73.91324920347246,
                40.602566647465736
              ],
              [
                -73.91326383824178,
                40.60256758846607
              ],
              [
                -73.9133564397603,
                40.60263644847511
              ],
              [
                -73.91335399077845,
                40.60264388224369
              ],
              [
                -73.91318430081161,
                40.60276924744311
              ],
              [
                -73.91319891628773,
                40.60278506201215
              ],
              [
                -73.91338693272549,
                40.60263461155052
              ],
              [
                -73.91327117511105,
                40.602552721097645
              ],
              [
                -73.91335175598681,
                40.60248678208413
              ],
              [
                -73.9133383546093,
                40.60247561654665
              ],
              [
                -73.91337620538674,
                40.602442179955865
              ],
              [
                -73.91338351755155,
                40.602446833564045
              ],
              [
                -73.91349826413605,
                40.60236697606776
              ],
              [
                -73.91357808200848,
                40.60242539323002
              ],
              [
                -73.91365030202735,
                40.60245677496811
              ],
              [
                -73.91366089338922,
                40.602468910027454
              ],
              [
                -73.91363102852749,
                40.602494152327786
              ],
              [
                -73.91363834620357,
                40.60250187020004
              ],
              [
                -73.91329651030489,
                40.6027656146779
              ],
              [
                -73.91347072649002,
                40.60291169068994
              ],
              [
                -73.91348903819966,
                40.6028986903068
              ],
              [
                -73.91332700399015,
                40.60276377776985
              ],
              [
                -73.91364075667397,
                40.60252418170431
              ],
              [
                -73.91373455897015,
                40.60260791471353
              ],
              [
                -73.91375408736835,
                40.60259677510992
              ],
              [
                -73.9138375968917,
                40.60266383283232
              ],
              [
                -73.91383692737334,
                40.602664180288436
              ],
              [
                -73.91382869588111,
                40.60266843672914
              ],
              [
                -73.91385524392098,
                40.60269025150675
              ],
              [
                -73.91382428947968,
                40.60274325664474
              ],
              [
                -73.91381966764283,
                40.60275117194805
              ],
              [
                -73.91383531832668,
                40.60275572740903
              ],
              [
                -73.91378662750738,
                40.60286814468284
              ],
              [
                -73.91369332211234,
                40.60283939097751
              ],
              [
                -73.91368718510047,
                40.60285042101266
              ],
              [
                -73.91380709007022,
                40.60288445810618
              ],
              [
                -73.91382020162834,
                40.60288818005007
              ],
              [
                -73.91382118712602,
                40.60287785981948
              ],
              [
                -73.9138010981717,
                40.60287282172692
              ],
              [
                -73.91384885636978,
                40.60275647820453
              ],
              [
                -73.91386005212335,
                40.60275901226624
              ],
              [
                -73.91389434799432,
                40.60270357776866
              ],
              [
                -73.91395185624506,
                40.60276832278435
              ],
              [
                -73.91396961253687,
                40.602775505488346
              ],
              [
                -73.91399608925019,
                40.60278621349814
              ],
              [
                -73.91442813462284,
                40.602863919563234
              ],
              [
                -73.91489358102517,
                40.60328888376004
              ],
              [
                -73.91476853163945,
                40.603360221672666
              ],
              [
                -73.91541600735383,
                40.60395000593016
              ],
              [
                -73.91549935833606,
                40.60391435136835
              ],
              [
                -73.91560086104275,
                40.60393625315706
              ],
              [
                -73.91712470643998,
                40.6053104238462
              ],
              [
                -73.91701787907759,
                40.605381778503705
              ],
              [
                -73.91794980550603,
                40.60620925217101
              ],
              [
                -73.91847519000007,
                40.606145567999924
              ],
              [
                -73.9185276079998,
                40.60611336699989
              ],
              [
                -73.91970928699986,
                40.607302162999886
              ],
              [
                -73.91984929899995,
                40.60722139799986
              ],
              [
                -73.92065861699989,
                40.60675454299997
              ],
              [
                -73.92158429099997,
                40.60763908499989
              ],
              [
                -73.92230145599989,
                40.60717863099994
              ],
              [
                -73.9230107439999,
                40.60671540299994
              ],
              [
                -73.92373054999989,
                40.60625280699985
              ],
              [
                -73.92444144399987,
                40.60579356899994
              ],
              [
                -73.92624885099994,
                40.6074135219999
              ],
              [
                -73.92696222699988,
                40.606961374999855
              ],
              [
                -73.92769136199986,
                40.606480507999905
              ],
              [
                -73.92844217799984,
                40.60600119699996
              ],
              [
                -73.92915810699985,
                40.60552985699994
              ],
              [
                -73.9295009169999,
                40.60530869199986
              ],
              [
                -73.92986880199989,
                40.60507134099994
              ],
              [
                -73.93169716599986,
                40.6067140589999
              ],
              [
                -73.93201257899985,
                40.60699707199991
              ],
              [
                -73.93351310700004,
                40.60834345599989
              ],
              [
                -73.93423157599979,
                40.607897714999915
              ],
              [
                -73.93531145199985,
                40.608860039999875
              ]
            ]
          ],
          [
            [
              [
                -73.91990064335972,
                40.599600522592766
              ],
              [
                -73.91989599129685,
                40.59960620110358
              ],
              [
                -73.91989630775923,
                40.59961300837114
              ],
              [
                -73.91990148636712,
                40.59961874818084
              ],
              [
                -73.91991448104537,
                40.599623093898245
              ],
              [
                -73.91992622063209,
                40.59962348689317
              ],
              [
                -73.91994336348232,
                40.59962061582613
              ],
              [
                -73.91996786796024,
                40.59960729371514
              ],
              [
                -73.91999918067486,
                40.59959007860376
              ],
              [
                -73.92001964207236,
                40.599582655051165
              ],
              [
                -73.9200925395554,
                40.59957334540119
              ],
              [
                -73.9201178545925,
                40.59957396577038
              ],
              [
                -73.92013207395463,
                40.59957683135624
              ],
              [
                -73.9201479477897,
                40.59958290727961
              ],
              [
                -73.92016241311848,
                40.599591455288426
              ],
              [
                -73.92017364161147,
                40.59959706191467
              ],
              [
                -73.92017911629651,
                40.59959673635486
              ],
              [
                -73.92018340395252,
                40.599594159215464
              ],
              [
                -73.9201920865931,
                40.59959004804745
              ],
              [
                -73.92020343507266,
                40.59958684456042
              ],
              [
                -73.92021492291106,
                40.59958583697428
              ],
              [
                -73.92022539730596,
                40.59958905561313
              ],
              [
                -73.92023641368432,
                40.59959136946685
              ],
              [
                -73.92024296948648,
                40.59959032997473
              ],
              [
                -73.92025331946004,
                40.59957902960549
              ],
              [
                -73.92025584763479,
                40.59957153694035
              ],
              [
                -73.92025813429498,
                40.59955647011201
              ],
              [
                -73.92027043637921,
                40.599538033178455
              ],
              [
                -73.92028841969866,
                40.59952629833728
              ],
              [
                -73.92030873371877,
                40.59952150902786
              ],
              [
                -73.92036966536112,
                40.59952083708676
              ],
              [
                -73.9204158269711,
                40.59952383399503
              ],
              [
                -73.92046271021108,
                40.599524937466384
              ],
              [
                -73.92051636737814,
                40.59952343750909
              ],
              [
                -73.92055176948652,
                40.599519372265526
              ],
              [
                -73.92063321092299,
                40.599501915704884
              ],
              [
                -73.92066385993064,
                40.59949760054105
              ],
              [
                -73.92069036019608,
                40.59950050083734
              ],
              [
                -73.92071257127054,
                40.599506554753866
              ],
              [
                -73.92072869332861,
                40.59951592506269
              ],
              [
                -73.920738838775,
                40.599522794056895
              ],
              [
                -73.9207545719535,
                40.5995266761719
              ],
              [
                -73.92077725291253,
                40.599530725577786
              ],
              [
                -73.92079917621065,
                40.59953667005008
              ],
              [
                -73.92083168628147,
                40.59954264861638
              ],
              [
                -73.92084893682663,
                40.599541397328444
              ],
              [
                -73.92087055640594,
                40.599530077357564
              ],
              [
                -73.92088050854765,
                40.599519432702316
              ],
              [
                -73.92088123526705,
                40.599513230601026
              ],
              [
                -73.92087757811551,
                40.599499943686666
              ],
              [
                -73.9208663980296,
                40.599484427307
              ],
              [
                -73.92086133639023,
                40.59946987642223
              ],
              [
                -73.92086156604135,
                40.59945840334221
              ],
              [
                -73.92086878083218,
                40.599447649249015
              ],
              [
                -73.9208916323198,
                40.59943111393279
              ],
              [
                -73.920948314347,
                40.599398434313336
              ],
              [
                -73.9210736991064,
                40.599339232239195
              ],
              [
                -73.92116996805429,
                40.59929771521762
              ],
              [
                -73.92129088298472,
                40.59924265618809
              ],
              [
                -73.92140953753236,
                40.59918040144592
              ],
              [
                -73.9215182161155,
                40.599119652557384
              ],
              [
                -73.92163283677688,
                40.59905769772212
              ],
              [
                -73.92174186243156,
                40.59898908068103
              ],
              [
                -73.92178152944105,
                40.59895906919444
              ],
              [
                -73.92185415447607,
                40.59890307036068
              ],
              [
                -73.92187416539961,
                40.59888751212504
              ],
              [
                -73.92189352936738,
                40.5988714868774
              ],
              [
                -73.92191222987921,
                40.59885500884677
              ],
              [
                -73.921930246693,
                40.59883809611275
              ],
              [
                -73.9219572644096,
                40.59881386745661
              ],
              [
                -73.92198501540479,
                40.59879012680193
              ],
              [
                -73.92201349109149,
                40.59876688670699
              ],
              [
                -73.92204267189015,
                40.59874416307338
              ],
              [
                -73.92205887142968,
                40.59873159462677
              ],
              [
                -73.92207447255038,
                40.5987185927092
              ],
              [
                -73.92208945303466,
                40.598705174226176
              ],
              [
                -73.92210379615743,
                40.598691357259696
              ],
              [
                -73.92211748277803,
                40.5986771583825
              ],
              [
                -73.92213049353393,
                40.59866259617731
              ],
              [
                -73.92214281543683,
                40.598647688728896
              ],
              [
                -73.92215443198057,
                40.59863245529205
              ],
              [
                -73.92216532643818,
                40.59861691612658
              ],
              [
                -73.92219508751423,
                40.59857142210698
              ],
              [
                -73.92222389025856,
                40.59852557210152
              ],
              [
                -73.92225173004306,
                40.59847937783468
              ],
              [
                -73.92225745971561,
                40.5984694954761
              ],
              [
                -73.92226234307493,
                40.5984593528744
              ],
              [
                -73.9222663622689,
                40.598448992905304
              ],
              [
                -73.92226949878852,
                40.59843845609803
              ],
              [
                -73.92227173873475,
                40.59842778801162
              ],
              [
                -73.9222733571766,
                40.598418965174275
              ],
              [
                -73.9222736000364,
                40.59841764621339
              ],
              [
                -73.922274560961,
                40.59840743176861
              ],
              [
                -73.92227461683909,
                40.59839719107969
              ],
              [
                -73.92227376607877,
                40.598386970383636
              ],
              [
                -73.92227201543713,
                40.59837681843611
              ],
              [
                -73.92226641356453,
                40.598362266073835
              ],
              [
                -73.92225970057747,
                40.59834799022031
              ],
              [
                -73.92225189927653,
                40.59833404031221
              ],
              [
                -73.92224651074953,
                40.59831860755954
              ],
              [
                -73.92224024769264,
                40.598303369721556
              ],
              [
                -73.92223312018483,
                40.598288354111936
              ],
              [
                -73.92223027928547,
                40.598272654700615
              ],
              [
                -73.92222847126045,
                40.59825686702997
              ],
              [
                -73.92222770111806,
                40.59824103013812
              ],
              [
                -73.9222276601082,
                40.59823170710867
              ],
              [
                -73.9222268074062,
                40.59822240664894
              ],
              [
                -73.92222514648115,
                40.598213169805774
              ],
              [
                -73.92222268629754,
                40.598204037295034
              ],
              [
                -73.92221943647932,
                40.598195049162605
              ],
              [
                -73.92221665717726,
                40.59818704189707
              ],
              [
                -73.9222131820685,
                40.59817919649644
              ],
              [
                -73.92220902320445,
                40.598171544966654
              ],
              [
                -73.92220420296172,
                40.59816412250407
              ],
              [
                -73.92219873932149,
                40.59815696480449
              ],
              [
                -73.92219673029048,
                40.598154117625235
              ],
              [
                -73.92219403907933,
                40.598151620790425
              ],
              [
                -73.92219076954105,
                40.5981495643333
              ],
              [
                -73.92218704005073,
                40.59814802472743
              ],
              [
                -73.92218299295898,
                40.59814706187695
              ],
              [
                -73.92217877811844,
                40.5981467103945
              ],
              [
                -73.92217455243843,
                40.59814698479378
              ],
              [
                -73.92216944943885,
                40.59815060063941
              ],
              [
                -73.92216378352414,
                40.59815369073182
              ],
              [
                -73.92215764684087,
                40.598156205712336
              ],
              [
                -73.92215114207612,
                40.59815810510834
              ],
              [
                -73.92214437212587,
                40.59815935766138
              ],
              [
                -73.92213744624773,
                40.598159942001935
              ],
              [
                -73.92213110335145,
                40.598160052964346
              ],
              [
                -73.92212478773406,
                40.598159576587314
              ],
              [
                -73.92211859455087,
                40.59815852281977
              ],
              [
                -73.92211261324087,
                40.59815690311413
              ],
              [
                -73.92210693564516,
                40.59815474299735
              ],
              [
                -73.92210164172575,
                40.5981520758617
              ],
              [
                -73.92209680990214,
                40.598148939622206
              ],
              [
                -73.92209251176975,
                40.59814538056535
              ],
              [
                -73.92208881386274,
                40.598141450838106
              ],
              [
                -73.92208576446558,
                40.59813720860585
              ],
              [
                -73.92204814326828,
                40.598067930696
              ],
              [
                -73.92204451209867,
                40.5980593666513
              ],
              [
                -73.92203994346059,
                40.59805106716933
              ],
              [
                -73.92203446651459,
                40.59804309308286
              ],
              [
                -73.92202812405263,
                40.59803550104562
              ],
              [
                -73.92201817026452,
                40.59802381348326
              ],
              [
                -73.92200887509807,
                40.598011814092544
              ],
              [
                -73.92200025874621,
                40.59799952583874
              ],
              [
                -73.92198589025554,
                40.59798343378146
              ],
              [
                -73.92197229278264,
                40.59796695843641
              ],
              [
                -73.92195948103144,
                40.59795011807443
              ],
              [
                -73.92195009521461,
                40.597935025189955
              ],
              [
                -73.92193988286631,
                40.597920246865684
              ],
              [
                -73.92192886505762,
                40.597905807742755
              ],
              [
                -73.92191705889918,
                40.597891735977036
              ],
              [
                -73.92190045183087,
                40.59786428708856
              ],
              [
                -73.9218845536419,
                40.59783659609711
              ],
              [
                -73.92186937091306,
                40.59780867339433
              ],
              [
                -73.9218642366908,
                40.597798222612774
              ],
              [
                -73.92185985963846,
                40.59778757198074
              ],
              [
                -73.92185625114323,
                40.59777675668741
              ],
              [
                -73.92185342501575,
                40.59776580689733
              ],
              [
                -73.92185138891014,
                40.597754754949015
              ],
              [
                -73.9218501460818,
                40.59774363602583
              ],
              [
                -73.92184676836521,
                40.59772211560305
              ],
              [
                -73.92184230236822,
                40.597700711375445
              ],
              [
                -73.9218367544302,
                40.59767945350236
              ],
              [
                -73.92182557190223,
                40.597656894617266
              ],
              [
                -73.92181530858868,
                40.59763408439231
              ],
              [
                -73.92180597171647,
                40.597611044276626
              ],
              [
                -73.92179757049145,
                40.59758779505022
              ],
              [
                -73.9217901116981,
                40.597564360339476
              ],
              [
                -73.92178280956708,
                40.59754303057234
              ],
              [
                -73.92177639687284,
                40.59752153706383
              ],
              [
                -73.92177087842761,
                40.597499898915856
              ],
              [
                -73.92176626013793,
                40.59747813874907
              ],
              [
                -73.92176254791133,
                40.597456278848824
              ],
              [
                -73.92176084869334,
                40.597450532779106
              ],
              [
                -73.92175992630537,
                40.597444685212885
              ],
              [
                -73.9217597899089,
                40.597438795294394
              ],
              [
                -73.92176044206576,
                40.59743292735643
              ],
              [
                -73.9217618734752,
                40.59742714069809
              ],
              [
                -73.92176407307933,
                40.59742149394702
              ],
              [
                -73.92176701421401,
                40.59741604706057
              ],
              [
                -73.92177066802131,
                40.597410856811436
              ],
              [
                -73.92177440962753,
                40.59740557565353
              ],
              [
                -73.92177888270717,
                40.597400636417944
              ],
              [
                -73.92178403246993,
                40.59739609451963
              ],
              [
                -73.92178980016665,
                40.59739200704583
              ],
              [
                -73.92179611673029,
                40.597388421695356
              ],
              [
                -73.92180474666027,
                40.597381696745295
              ],
              [
                -73.92181284405521,
                40.597374597674474
              ],
              [
                -73.92182038141432,
                40.597367148421114
              ],
              [
                -73.92182733386989,
                40.59735937610802
              ],
              [
                -73.92182885437087,
                40.597355579924404
              ],
              [
                -73.92182965039831,
                40.597351657601
              ],
              [
                -73.92182971196767,
                40.59734768921009
              ],
              [
                -73.92182903239139,
                40.59734375549621
              ],
              [
                -73.92182763092089,
                40.59733993320074
              ],
              [
                -73.92182553098029,
                40.597336300910875
              ],
              [
                -73.92182277731894,
                40.59733293287255
              ],
              [
                -73.92181942480326,
                40.59732989646963
              ],
              [
                -73.92181554237384,
                40.5973272522272
              ],
              [
                -73.92181120601096,
                40.59732505514629
              ],
              [
                -73.92180488497279,
                40.59731951785693
              ],
              [
                -73.92179796210176,
                40.59731441657138
              ],
              [
                -73.9217904898824,
                40.59730978818167
              ],
              [
                -73.9217825208026,
                40.59730566673181
              ],
              [
                -73.92177411768235,
                40.59730208443022
              ],
              [
                -73.92176534005367,
                40.5972990657764
              ],
              [
                -73.92175625404046,
                40.597296636447474
              ],
              [
                -73.9217395650497,
                40.5972944495486
              ],
              [
                -73.9217231009897,
                40.59729143336318
              ],
              [
                -73.9217069323978,
                40.59728759949889
              ],
              [
                -73.92169886504772,
                40.59728313370371
              ],
              [
                -73.92169128308619,
                40.597278199826896
              ],
              [
                -73.9216842315336,
                40.59727282788704
              ],
              [
                -73.92167775452769,
                40.597267050582495
              ],
              [
                -73.92167189378407,
                40.597260904295716
              ],
              [
                -73.92166668222728,
                40.59725442573801
              ],
              [
                -73.92166215299599,
                40.5972476556416
              ],
              [
                -73.92165833417285,
                40.59724063624328
              ],
              [
                -73.9216552494449,
                40.597233409274
              ],
              [
                -73.9216483381444,
                40.597225207523415
              ],
              [
                -73.92164229873923,
                40.597216614348525
              ],
              [
                -73.92163717072572,
                40.59720768422334
              ],
              [
                -73.92163298634603,
                40.597198472622125
              ],
              [
                -73.92162977256656,
                40.597189036523446
              ],
              [
                -73.92162431386555,
                40.59714464994658
              ],
              [
                -73.92162244475769,
                40.59714060501833
              ],
              [
                -73.92161978522482,
                40.59713682525231
              ],
              [
                -73.92161639559961,
                40.59713340182553
              ],
              [
                -73.92161235974629,
                40.59713041386902
              ],
              [
                -73.92160777230781,
                40.59712793164191
              ],
              [
                -73.92160273958794,
                40.59712601502461
              ],
              [
                -73.92159738021682,
                40.597124708158326
              ],
              [
                -73.9215918233893,
                40.59712404178888
              ],
              [
                -73.92158416850413,
                40.59712414579482
              ],
              [
                -73.92157653514104,
                40.597123702496916
              ],
              [
                -73.9215689887934,
                40.59712271311245
              ],
              [
                -73.92156160087892,
                40.59712118740702
              ],
              [
                -73.92155443094379,
                40.59711913815345
              ],
              [
                -73.92154754687742,
                40.59711658466404
              ],
              [
                -73.92154100821479,
                40.59711354942833
              ],
              [
                -73.92153487426533,
                40.5971100587891
              ],
              [
                -73.92152149882783,
                40.59710425314364
              ],
              [
                -73.9215086173052,
                40.5970978319964
              ],
              [
                -73.9214962795578,
                40.59709082101375
              ],
              [
                -73.92148452797672,
                40.59708324334359
              ],
              [
                -73.92147341219564,
                40.597075129845614
              ],
              [
                -73.92144843480666,
                40.597055633479016
              ],
              [
                -73.92142420334383,
                40.59703559850785
              ],
              [
                -73.92140073316898,
                40.59701504370635
              ],
              [
                -73.92137804712212,
                40.596993983833165
              ],
              [
                -73.92137001659407,
                40.59698666669204
              ],
              [
                -73.92136257461662,
                40.59697899646632
              ],
              [
                -73.9213557508287,
                40.596971000651266
              ],
              [
                -73.92134956431755,
                40.59696270706994
              ],
              [
                -73.92134404273862,
                40.59695414723674
              ],
              [
                -73.92133920342198,
                40.59694534847099
              ],
              [
                -73.92133506368913,
                40.59693634596581
              ],
              [
                -73.92133163778813,
                40.59692717105889
              ],
              [
                -73.9213289977091,
                40.59691902598954
              ],
              [
                -73.92132562535527,
                40.59691103906907
              ],
              [
                -73.92132153738551,
                40.59690324817034
              ],
              [
                -73.92131675353417,
                40.59689569234113
              ],
              [
                -73.92131129376092,
                40.596888406106025
              ],
              [
                -73.92130518989238,
                40.59688142466787
              ],
              [
                -73.9213008598254,
                40.596878586269995
              ],
              [
                -73.9212960626006,
                40.59687622685317
              ],
              [
                -73.92129088541984,
                40.59687438919708
              ],
              [
                -73.92128542867985,
                40.59687310972428
              ],
              [
                -73.92127979345427,
                40.596872409780126
              ],
              [
                -73.92127408543934,
                40.59687230484983
              ],
              [
                -73.9212684105677,
                40.59687279617859
              ],
              [
                -73.92126288049948,
                40.59687387379104
              ],
              [
                -73.92125759174067,
                40.59687551815258
              ],
              [
                -73.92124976005617,
                40.59687591351603
              ],
              [
                -73.92124191611856,
                40.596875703419734
              ],
              [
                -73.92123413992638,
                40.59687488942642
              ],
              [
                -73.9212265105916,
                40.59687347946416
              ],
              [
                -73.92121911029548,
                40.596871487661744
              ],
              [
                -73.92121201088028,
                40.59686893618235
              ],
              [
                -73.92120528506241,
                40.59686585137812
              ],
              [
                -73.92119900511614,
                40.5968622617784
              ],
              [
                -73.92119323341322,
                40.59685820646053
              ],
              [
                -73.92118802880371,
                40.59685372835234
              ],
              [
                -73.92118344574286,
                40.59684887021132
              ],
              [
                -73.92114826240214,
                40.5968144526543
              ],
              [
                -73.92111376902572,
                40.596779630976705
              ],
              [
                -73.92107997175623,
                40.59674441389509
              ],
              [
                -73.92104405708473,
                40.59669643816311
              ],
              [
                -73.92103836464742,
                40.59669060758424
              ],
              [
                -73.92103198797186,
                40.596685203064474
              ],
              [
                -73.9210249801875,
                40.596680273558626
              ],
              [
                -73.92101740410085,
                40.59667586132719
              ],
              [
                -73.92100932472403,
                40.59667200260107
              ],
              [
                -73.92100080948812,
                40.5966687321049
              ],
              [
                -73.92099193374358,
                40.596666078370724
              ],
              [
                -73.92098277197168,
                40.596664061720254
              ],
              [
                -73.92097252446897,
                40.59666113313091
              ],
              [
                -73.92096256639095,
                40.59665766948375
              ],
              [
                -73.92095294738532,
                40.59665369007874
              ],
              [
                -73.92094371358772,
                40.59664921019301
              ],
              [
                -73.92093491024322,
                40.59664425532217
              ],
              [
                -73.92092657556758,
                40.59663884727151
              ],
              [
                -73.92091875480446,
                40.59663301187162
              ],
              [
                -73.92091148132826,
                40.59662677678802
              ],
              [
                -73.92090479224294,
                40.596620174211864
              ],
              [
                -73.92089682095298,
                40.59661265403259
              ],
              [
                -73.920889501097,
                40.59660475819812
              ],
              [
                -73.92088286582367,
                40.59659651872935
              ],
              [
                -73.92087694168622,
                40.596587969485654
              ],
              [
                -73.92087175325507,
                40.59657914801057
              ],
              [
                -73.92086732334573,
                40.59657008933353
              ],
              [
                -73.92086367015503,
                40.59656083082614
              ],
              [
                -73.92085595328383,
                40.59647774037959
              ],
              [
                -73.92084999213027,
                40.59646093811481
              ],
              [
                -73.92084489116979,
                40.59644397176089
              ],
              [
                -73.92084065586504,
                40.59642686762392
              ],
              [
                -73.92083729585357,
                40.59640965268268
              ],
              [
                -73.92083481154756,
                40.59639234938658
              ],
              [
                -73.92083321038463,
                40.59637498689091
              ],
              [
                -73.92083284385042,
                40.59634880181437
              ],
              [
                -73.92083406860237,
                40.59634589920839
              ],
              [
                -73.92083433001174,
                40.5963428553818
              ],
              [
                -73.92083361357264,
                40.59633985594688
              ],
              [
                -73.92083196259102,
                40.59633707868207
              ],
              [
                -73.9208294770879,
                40.5963346906829
              ],
              [
                -73.92081828920803,
                40.59633602572169
              ],
              [
                -73.92080765250182,
                40.59633646033432
              ],
              [
                -73.92080389184022,
                40.59633518686577
              ],
              [
                -73.9208005263122,
                40.59633338176389
              ],
              [
                -73.92079768374862,
                40.596331112631205
              ],
              [
                -73.92079547525519,
                40.596328465654445
              ],
              [
                -73.92079398510812,
                40.59632554140927
              ],
              [
                -73.92079326702034,
                40.59632245133962
              ],
              [
                -73.92078891821032,
                40.5963198995518
              ],
              [
                -73.92078410260292,
                40.59631789308497
              ],
              [
                -73.92077893925904,
                40.59631648194468
              ],
              [
                -73.92077354813901,
                40.59631569871439
              ],
              [
                -73.92076806416243,
                40.596315563758104
              ],
              [
                -73.9207626185346,
                40.596316079344064
              ],
              [
                -73.92075734401655,
                40.59631723283174
              ],
              [
                -73.92075236920974,
                40.596318998175185
              ],
              [
                -73.92074781350654,
                40.596321330894085
              ],
              [
                -73.92074378994201,
                40.596324172765975
              ],
              [
                -73.92073187491273,
                40.596330793848416
              ],
              [
                -73.92071951388583,
                40.596336913876925
              ],
              [
                -73.92070673786347,
                40.596342521480665
              ],
              [
                -73.92069358511306,
                40.59634759524241
              ],
              [
                -73.9206800923512,
                40.59635212362765
              ],
              [
                -73.92066630069343,
                40.596356092927344
              ],
              [
                -73.92065224971363,
                40.59635949177685
              ],
              [
                -73.9206427133015,
                40.59636170294411
              ],
              [
                -73.92063295831434,
                40.59636327064729
              ],
              [
                -73.92062306411049,
                40.596364180031074
              ],
              [
                -73.92061310036657,
                40.59636442511242
              ],
              [
                -73.92060314884166,
                40.59636400544524
              ],
              [
                -73.92059328162205,
                40.596362922084374
              ],
              [
                -73.92058357869517,
                40.59636118563964
              ],
              [
                -73.92057411125808,
                40.59635880537442
              ],
              [
                -73.9205602394341,
                40.596353117381554
              ],
              [
                -73.92054674359254,
                40.59634692722666
              ],
              [
                -73.92053365382506,
                40.59634024950575
              ],
              [
                -73.92052100044532,
                40.596333098144974
              ],
              [
                -73.92050881398063,
                40.59632549159359
              ],
              [
                -73.92049712078564,
                40.59631744561786
              ],
              [
                -73.92048594962638,
                40.59630898117897
              ],
              [
                -73.92046462962641,
                40.59629453478411
              ],
              [
                -73.92044395180271,
                40.59627955642175
              ],
              [
                -73.92042393591537,
                40.59626406352913
              ],
              [
                -73.92040460677839,
                40.59624807321162
              ],
              [
                -73.92038203104995,
                40.59621662908212
              ],
              [
                -73.92037982410287,
                40.59621270250999
              ],
              [
                -73.92037692695145,
                40.59620904451141
              ],
              [
                -73.9203733926946,
                40.596205729003806
              ],
              [
                -73.92036929048967,
                40.59620281668046
              ],
              [
                -73.92036469872751,
                40.59620036623104
              ],
              [
                -73.92035970724424,
                40.596198422280416
              ],
              [
                -73.92035441005862,
                40.59619702359293
              ],
              [
                -73.92034891109198,
                40.596196198553145
              ],
              [
                -73.92034331494288,
                40.59619596030082
              ],
              [
                -73.92033772885611,
                40.596196314271104
              ],
              [
                -73.92032581833102,
                40.59619969864914
              ],
              [
                -73.92031436616476,
                40.5962039012216
              ],
              [
                -73.92030346909885,
                40.596208889220044
              ],
              [
                -73.92029984712111,
                40.59621149312845
              ],
              [
                -73.92029570289029,
                40.5962135949244
              ],
              [
                -73.92029115471638,
                40.59621513605457
              ],
              [
                -73.92028632990525,
                40.59621607137432
              ],
              [
                -73.92028136826723,
                40.59621637484613
              ],
              [
                -73.92027640871201,
                40.596216038189866
              ],
              [
                -73.92027159562296,
                40.596215070887304
              ],
              [
                -73.92026499570562,
                40.59621412931699
              ],
              [
                -73.92025857071978,
                40.59621264591043
              ],
              [
                -73.92025239471131,
                40.59621063680195
              ],
              [
                -73.92024654479414,
                40.596208126169365
              ],
              [
                -73.92024108818286,
                40.59620514538713
              ],
              [
                -73.92023608923323,
                40.59620172733599
              ],
              [
                -73.92023160987182,
                40.59619791444363
              ],
              [
                -73.920199545372,
                40.59618334528056
              ],
              [
                -73.92019230374304,
                40.59617814382869
              ],
              [
                -73.92018571015468,
                40.59617246302273
              ],
              [
                -73.9201798214724,
                40.596166350648
              ],
              [
                -73.92017468291108,
                40.59615985615717
              ],
              [
                -73.92017033748222,
                40.5961530340273
              ],
              [
                -73.92016682094476,
                40.59614593839515
              ],
              [
                -73.92016551508158,
                40.59614184558559
              ],
              [
                -73.92016334764742,
                40.596137971138084
              ],
              [
                -73.92016038049594,
                40.596134418461304
              ],
              [
                -73.9201566897743,
                40.596131284607594
              ],
              [
                -73.9201523762624,
                40.596128651568435
              ],
              [
                -73.92014755503682,
                40.59612659229925
              ],
              [
                -73.92014235789823,
                40.59612516200854
              ],
              [
                -73.92013692435978,
                40.59612439898986
              ],
              [
                -73.92013178166758,
                40.59612485545117
              ],
              [
                -73.92012660538668,
                40.5961247293896
              ],
              [
                -73.92012151177822,
                40.59612402205878
              ],
              [
                -73.92011661027354,
                40.596122748947195
              ],
              [
                -73.92011200852703,
                40.596120940787934
              ],
              [
                -73.92010780825348,
                40.59611863333531
              ],
              [
                -73.920104099939,
                40.59611587825123
              ],
              [
                -73.92010096592858,
                40.596112736071106
              ],
              [
                -73.92006696565075,
                40.5960895742889
              ],
              [
                -73.9200638780322,
                40.596086786129426
              ],
              [
                -73.92006140223293,
                40.5960836663525
              ],
              [
                -73.92005959706684,
                40.59608028737167
              ],
              [
                -73.92005850682558,
                40.596076735327735
              ],
              [
                -73.92005815755803,
                40.5960730976892
              ],
              [
                -73.9200585623432,
                40.596069464428446
              ],
              [
                -73.92005970503378,
                40.59606592164422
              ],
              [
                -73.92006156134629,
                40.59606255945037
              ],
              [
                -73.92006408502839,
                40.59605946074169
              ],
              [
                -73.92006688182877,
                40.596055498609516
              ],
              [
                -73.92006886665922,
                40.596051261499575
              ],
              [
                -73.92006999325366,
                40.596046843698616
              ],
              [
                -73.92007023995146,
                40.59604234788717
              ],
              [
                -73.92006959498332,
                40.59603787507715
              ],
              [
                -73.9200680762517,
                40.59603352512852
              ],
              [
                -73.92006464528366,
                40.59603060287425
              ],
              [
                -73.92006056555643,
                40.59602821090137
              ],
              [
                -73.92005597806747,
                40.59602643290499
              ],
              [
                -73.9200510403239,
                40.596025329975305
              ],
              [
                -73.92004591909364,
                40.59602493824694
              ],
              [
                -73.92004079325876,
                40.59602527174944
              ],
              [
                -73.9200359617981,
                40.59602442739062
              ],
              [
                -73.92003139283277,
                40.59602296503134
              ],
              [
                -73.92002721751561,
                40.59602093049816
              ],
              [
                -73.92002356215279,
                40.59601837866143
              ],
              [
                -73.92002053170106,
                40.59601539067809
              ],
              [
                -73.92001821814745,
                40.59601204970692
              ],
              [
                -73.92001668708448,
                40.596008456813756
              ],
              [
                -73.92001543462604,
                40.5960049255976
              ],
              [
                -73.92001350063795,
                40.59600157718424
              ],
              [
                -73.92001092612406,
                40.59599849084323
              ],
              [
                -73.92000777078299,
                40.59599573396309
              ],
              [
                -73.92000410728487,
                40.595993370422896
              ],
              [
                -73.92000001753891,
                40.59599145656942
              ],
              [
                -73.91999559600244,
                40.595990031670325
              ],
              [
                -73.91999094482841,
                40.59598913248555
              ],
              [
                -73.91998616838778,
                40.595988777851126
              ],
              [
                -73.91998137524033,
                40.595988976553876
              ],
              [
                -73.91997667682186,
                40.59598972146796
              ],
              [
                -73.91997217930066,
                40.595990999097296
              ],
              [
                -73.91996798579088,
                40.59599277751557
              ],
              [
                -73.9199628254628,
                40.59599548638811
              ],
              [
                -73.91995728615548,
                40.595997714019774
              ],
              [
                -73.9199514406448,
                40.59599943533208
              ],
              [
                -73.91994537248216,
                40.59600062123291
              ],
              [
                -73.91993916387872,
                40.596001259214944
              ],
              [
                -73.91993290056156,
                40.59600133828074
              ],
              [
                -73.91992666802464,
                40.59600085798744
              ],
              [
                -73.9199205513129,
                40.59599982610055
              ],
              [
                -73.91991463502488,
                40.59599825574649
              ],
              [
                -73.9199090000144,
                40.59599616742015
              ],
              [
                -73.91989839371136,
                40.59599163388923
              ],
              [
                -73.91988819857727,
                40.59598658130335
              ],
              [
                -73.9198784572203,
                40.59598103331365
              ],
              [
                -73.91986921247154,
                40.595975011394216
              ],
              [
                -73.91986050188099,
                40.59596854237573
              ],
              [
                -73.91985236431643,
                40.59596165325779
              ],
              [
                -73.91984215554052,
                40.59595424017082
              ],
              [
                -73.91983143262976,
                40.595947258112055
              ],
              [
                -73.91982023116061,
                40.59594072972267
              ],
              [
                -73.91980857945877,
                40.59593467596356
              ],
              [
                -73.91979651486983,
                40.59592911093313
              ],
              [
                -73.91978407275373,
                40.59592405459171
              ],
              [
                -73.91977128891902,
                40.59591951902651
              ],
              [
                -73.9197603773393,
                40.59591317963915
              ],
              [
                -73.91974999206417,
                40.59590634489923
              ],
              [
                -73.91974017130073,
                40.59589904348096
              ],
              [
                -73.91973094952398,
                40.59589130003549
              ],
              [
                -73.9197223605453,
                40.59588314356909
              ],
              [
                -73.91969471112844,
                40.59586168251381
              ],
              [
                -73.9196662471378,
                40.59584085096006
              ],
              [
                -73.91963698811024,
                40.5958206678516
              ],
              [
                -73.91961723004908,
                40.59581254142758
              ],
              [
                -73.91959794087923,
                40.595803782402626
              ],
              [
                -73.91957915728098,
                40.595794410068486
              ],
              [
                -73.919560910227,
                40.59578443751435
              ],
              [
                -73.91954342840789,
                40.59577230419794
              ],
              [
                -73.91952660766293,
                40.59575964111163
              ],
              [
                -73.91951047302153,
                40.59574646904678
              ],
              [
                -73.91947256021903,
                40.59571292909151
              ],
              [
                -73.91946538126052,
                40.595708335266195
              ],
              [
                -73.91945767332409,
                40.59570426861929
              ],
              [
                -73.91944950735552,
                40.59570076387921
              ],
              [
                -73.91944094793479,
                40.59569784957087
              ],
              [
                -73.91943207194952,
                40.595695553558066
              ],
              [
                -73.9194203294221,
                40.595692162071025
              ],
              [
                -73.91940891375984,
                40.59568817792587
              ],
              [
                -73.91939787065067,
                40.59568362209594
              ],
              [
                -73.91938725435921,
                40.59567851204243
              ],
              [
                -73.91937710903207,
                40.5956728709155
              ],
              [
                -73.9193674821083,
                40.59566672622334
              ],
              [
                -73.91935841663299,
                40.595660103795794
              ],
              [
                -73.91934995256923,
                40.59565303331372
              ],
              [
                -73.91927234651445,
                40.59560096600699
              ],
              [
                -73.91924989028144,
                40.59558746010179
              ],
              [
                -73.9192279709202,
                40.59557345114396
              ],
              [
                -73.91920661061332,
                40.59555895121168
              ],
              [
                -73.9191516399281,
                40.59551724651069
              ],
              [
                -73.91914145013469,
                40.59550970487937
              ],
              [
                -73.91913074203036,
                40.59550259326509
              ],
              [
                -73.91911954724104,
                40.595495929783226
              ],
              [
                -73.91910789892468,
                40.5954897380785
              ],
              [
                -73.91909582870996,
                40.595484033083174
              ],
              [
                -73.91908337195937,
                40.59547883274771
              ],
              [
                -73.91907056667698,
                40.59547415100351
              ],
              [
                -73.91905259799357,
                40.59546692168887
              ],
              [
                -73.91903518537742,
                40.59545894239933
              ],
              [
                -73.91901838396703,
                40.595450233947965
              ],
              [
                -73.9190122172361,
                40.59544664430802
              ],
              [
                -73.91900663155917,
                40.59544253908685
              ],
              [
                -73.9190017033528,
                40.59543797211569
              ],
              [
                -73.91899749210195,
                40.59543300441698
              ],
              [
                -73.9189940590452,
                40.5954277012031
              ],
              [
                -73.91899144585678,
                40.59542213068759
              ],
              [
                -73.91898968914384,
                40.595416371467714
              ],
              [
                -73.9189910839741,
                40.59540267475423
              ],
              [
                -73.9189936002051,
                40.595389071642394
              ],
              [
                -73.9189972289798,
                40.59537561590289
              ],
              [
                -73.91899822960946,
                40.59537236184937
              ],
              [
                -73.91899850156396,
                40.595369024857696
              ],
              [
                -73.91899803461551,
                40.595365702255336
              ],
              [
                -73.91899684447867,
                40.59536248451919
              ],
              [
                -73.91899496422664,
                40.59535946448354
              ],
              [
                -73.9189924469416,
                40.595356727793465
              ],
              [
                -73.91898936395688,
                40.5953543513952
              ],
              [
                -73.9189858022233,
                40.595352401691365
              ],
              [
                -73.91898186320948,
                40.595350934373336
              ],
              [
                -73.91897765653323,
                40.59534999089809
              ],
              [
                -73.91897330083931,
                40.595349598656504
              ],
              [
                -73.91896892270665,
                40.59534976795696
              ],
              [
                -73.91896083661622,
                40.595353481404985
              ],
              [
                -73.91895328033219,
                40.59535779699133
              ],
              [
                -73.91894633061598,
                40.5953626651815
              ],
              [
                -73.91894005455104,
                40.59536804330293
              ],
              [
                -73.91893452077733,
                40.595373873774165
              ],
              [
                -73.91892978123445,
                40.59538009682401
              ],
              [
                -73.91892501148821,
                40.595381328484834
              ],
              [
                -73.91892005888634,
                40.59538201889688
              ],
              [
                -73.91891502696288,
                40.59538215205037
              ],
              [
                -73.91891002230876,
                40.5953817280204
              ],
              [
                -73.91890515304664,
                40.59538075291447
              ],
              [
                -73.91890052068642,
                40.59537924824794
              ],
              [
                -73.91889622518737,
                40.59537724592191
              ],
              [
                -73.91887141082583,
                40.59536272757387
              ],
              [
                -73.91884599683691,
                40.595348826477355
              ],
              [
                -73.91882001089783,
                40.595335554043615
              ],
              [
                -73.9188140216552,
                40.595333502269604
              ],
              [
                -73.9188083736725,
                40.595330950493924
              ],
              [
                -73.91880313812132,
                40.59532792858708
              ],
              [
                -73.91879838286097,
                40.59532447831194
              ],
              [
                -73.91879416586319,
                40.59532064025169
              ],
              [
                -73.91879054354642,
                40.595316466715296
              ],
              [
                -73.91878755958474,
                40.59531200816002
              ],
              [
                -73.9187919274468,
                40.59530600615955
              ],
              [
                -73.91879701861106,
                40.595300347267404
              ],
              [
                -73.91880278708891,
                40.595295077018974
              ],
              [
                -73.91880918204893,
                40.59529024731228
              ],
              [
                -73.91881614564056,
                40.595285898983654
              ],
              [
                -73.91882014322945,
                40.595284011742265
              ],
              [
                -73.91882365132508,
                40.59528162625802
              ],
              [
                -73.9188265634708,
                40.59527881265043
              ],
              [
                -73.9188287940674,
                40.595275658812
              ],
              [
                -73.91883027377388,
                40.59527225683477
              ],
              [
                -73.91883095718875,
                40.5952687115602
              ],
              [
                -73.91883082616152,
                40.5952651295239
              ],
              [
                -73.9187815888106,
                40.595217802390344
              ],
              [
                -73.91877443878163,
                40.59521082828593
              ],
              [
                -73.91876666762612,
                40.59520425179165
              ],
              [
                -73.91875831332682,
                40.59519810409444
              ],
              [
                -73.91874941782382,
                40.595192415547004
              ],
              [
                -73.91874002174238,
                40.59518721348491
              ],
              [
                -73.91873017339927,
                40.595182525584526
              ],
              [
                -73.91871991914397,
                40.59517837047457
              ],
              [
                -73.91871131514995,
                40.59517496186866
              ],
              [
                -73.91870237798345,
                40.59517209046051
              ],
              [
                -73.91869316783504,
                40.59516977890888
              ],
              [
                -73.91868374161186,
                40.59516803864602
              ],
              [
                -73.91867416347108,
                40.59516688395734
              ],
              [
                -73.9186644953832,
                40.59516631940978
              ],
              [
                -73.91865479689959,
                40.59516635090902
              ],
              [
                -73.9186451352711,
                40.595166978670484
              ],
              [
                -73.91863557006518,
                40.595168195532175
              ],
              [
                -73.91862616546092,
                40.595169997184115
              ],
              [
                -73.91861698059509,
                40.59517236926053
              ],
              [
                -73.91860806009105,
                40.5951740422563
              ],
              [
                -73.91859897399996,
                40.5951750728267
              ],
              [
                -73.91858980233157,
                40.59517545164696
              ],
              [
                -73.91858062355114,
                40.5951751745842
              ],
              [
                -73.91857151831273,
                40.59517424420853
              ],
              [
                -73.9185625679228,
                40.59517266945682
              ],
              [
                -73.91855384950556,
                40.595170464120976
              ],
              [
                -73.91854543930093,
                40.59516764651594
              ],
              [
                -73.91853741375868,
                40.595164242662904
              ],
              [
                -73.91852862030223,
                40.59515977612917
              ],
              [
                -73.9185202753283,
                40.59515483396208
              ],
              [
                -73.91851242429686,
                40.59514944366872
              ],
              [
                -73.91850510739273,
                40.595143633255056
              ],
              [
                -73.9184983634789,
                40.59513743290424
              ],
              [
                -73.9184922270216,
                40.595130874471074
              ],
              [
                -73.91848673204085,
                40.595123994333775
              ],
              [
                -73.91848190838296,
                40.59511682802975
              ],
              [
                -73.91847777885674,
                40.595109414274674
              ],
              [
                -73.91844064659385,
                40.59505652405939
              ],
              [
                -73.9184117231708,
                40.595004901323236
              ],
              [
                -73.91840519752516,
                40.59499761419172
              ],
              [
                -73.91839795822357,
                40.59499072778558
              ],
              [
                -73.91839005092659,
                40.59498428519232
              ],
              [
                -73.91838151998473,
                40.59497832229457
              ],
              [
                -73.91837241458613,
                40.59497287296793
              ],
              [
                -73.91836279205062,
                40.59496797092646
              ],
              [
                -73.91835270288986,
                40.594963645691
              ],
              [
                -73.91834221191117,
                40.5949599184163
              ],
              [
                -73.9182262852408,
                40.59491591918241
              ],
              [
                -73.918207930059,
                40.594910612528665
              ],
              [
                -73.91818987864859,
                40.59490473464592
              ],
              [
                -73.91817216066987,
                40.594898293094225
              ],
              [
                -73.91815480335815,
                40.59489130179788
              ],
              [
                -73.91813784076567,
                40.59488377116831
              ],
              [
                -73.91812129858931,
                40.5948757154639
              ],
              [
                -73.91810542448017,
                40.594866624902195
              ],
              [
                -73.91809003643904,
                40.59485705873409
              ],
              [
                -73.91807516103935,
                40.59484703289378
              ],
              [
                -73.9180608211166,
                40.59483656448606
              ],
              [
                -73.91804703994809,
                40.594825668772536
              ],
              [
                -73.91803383971062,
                40.594814362354946
              ],
              [
                -73.91797050181093,
                40.59478412436037
              ],
              [
                -73.9179616525297,
                40.59478169875255
              ],
              [
                -73.9179531651492,
                40.594778612499425
              ],
              [
                -73.91794512270772,
                40.59477489464292
              ],
              [
                -73.91793760669219,
                40.59477058544821
              ],
              [
                -73.91793069331456,
                40.594765725512104
              ],
              [
                -73.91792445130464,
                40.594760362629444
              ],
              [
                -73.91791893971238,
                40.594754553467645
              ],
              [
                -73.91790371163492,
                40.594744340725505
              ],
              [
                -73.91788919657135,
                40.594733539457906
              ],
              [
                -73.91787543558024,
                40.59472218152495
              ],
              [
                -73.917862462914,
                40.5947102932533
              ],
              [
                -73.91782689591649,
                40.59466812583093
              ],
              [
                -73.91779072246149,
                40.59462625767345
              ],
              [
                -73.91775394649545,
                40.594584696489335
              ],
              [
                -73.91771131849671,
                40.594536689098746
              ],
              [
                -73.91766779440684,
                40.59448915147224
              ],
              [
                -73.91762337882922,
                40.59444209349658
              ],
              [
                -73.91760122161998,
                40.594415529912666
              ],
              [
                -73.91757838205497,
                40.59438930675705
              ],
              [
                -73.91755486430114,
                40.59436343106873
              ],
              [
                -73.91753067933053,
                40.59433791675988
              ],
              [
                -73.91749936364604,
                40.59430938514992
              ],
              [
                -73.91746875136242,
                40.59428041376948
              ],
              [
                -73.91743885037874,
                40.594251013011615
              ],
              [
                -73.91740967232886,
                40.5942211934396
              ],
              [
                -73.9173812292882,
                40.5941909647794
              ],
              [
                -73.91735352563852,
                40.59416033758895
              ],
              [
                -73.91732421979414,
                40.594132442479726
              ],
              [
                -73.91729430957736,
                40.59410492303116
              ],
              [
                -73.91726380465204,
                40.5940777841082
              ],
              [
                -73.91723271379725,
                40.59405103560095
              ],
              [
                -73.91720664817602,
                40.59403055328651
              ],
              [
                -73.91718123499682,
                40.59400959933874
              ],
              [
                -73.91715648940665,
                40.59398818733887
              ],
              [
                -73.91713242391874,
                40.59396632852089
              ],
              [
                -73.91710905280128,
                40.59394403596257
              ],
              [
                -73.91709537713228,
                40.59393411405783
              ],
              [
                -73.91708232664953,
                40.593923713971336
              ],
              [
                -73.91706993363321,
                40.5939128570028
              ],
              [
                -73.91705822442664,
                40.593901566290455
              ],
              [
                -73.91704722361061,
                40.59388986882459
              ],
              [
                -73.917036957087,
                40.59387778975349
              ],
              [
                -73.91702744789585,
                40.593865357238826
              ],
              [
                -73.9170187162244,
                40.59385259692734
              ],
              [
                -73.91699743042804,
                40.593827426525614
              ],
              [
                -73.91697687977835,
                40.59380190366676
              ],
              [
                -73.91695706909132,
                40.593776043264626
              ],
              [
                -73.9169380100018,
                40.593749855045004
              ],
              [
                -73.91691971237843,
                40.5937233556006
              ],
              [
                -73.9169048159175,
                40.593703930862624
              ],
              [
                -73.91688924636479,
                40.593684815230084
              ],
              [
                -73.91687301557144,
                40.59366602194602
              ],
              [
                -73.91685613451001,
                40.59364756408544
              ],
              [
                -73.91683861744997,
                40.59362945439061
              ],
              [
                -73.91682047250606,
                40.59361170660444
              ],
              [
                -73.91680276978079,
                40.59359163969211
              ],
              [
                -73.91678576241264,
                40.593571223646926
              ],
              [
                -73.91676946488826,
                40.593550473222244
              ],
              [
                -73.91675388927177,
                40.59352940601761
              ],
              [
                -73.91673904632025,
                40.59350803142278
              ],
              [
                -73.91673345332673,
                40.59349752244428
              ],
              [
                -73.91672699122175,
                40.593487306012044
              ],
              [
                -73.91671968698407,
                40.59347742503283
              ],
              [
                -73.9167115691328,
                40.59346792040453
              ],
              [
                -73.91670267014563,
                40.593458830849606
              ],
              [
                -73.9166930304151,
                40.5934501925835
              ],
              [
                -73.91668374507812,
                40.5934412794905
              ],
              [
                -73.91667515910902,
                40.59343196617363
              ],
              [
                -73.91666730147057,
                40.59342229001331
              ],
              [
                -73.91666019740298,
                40.5934122773298
              ],
              [
                -73.91665387103079,
                40.5934019681806
              ],
              [
                -73.91664834560989,
                40.593391394077926
              ],
              [
                -73.91662510339982,
                40.593358205071205
              ],
              [
                -73.91660115824598,
                40.59332530889332
              ],
              [
                -73.91657651607466,
                40.593292712416904
              ],
              [
                -73.91655118236952,
                40.593260424524416
              ],
              [
                -73.91653822727342,
                40.59324298285205
              ],
              [
                -73.91652592370005,
                40.59322526774106
              ],
              [
                -73.91651428195775,
                40.59320729511446
              ],
              [
                -73.91650331125844,
                40.59318907988913
              ],
              [
                -73.91649792297807,
                40.5931806438879
              ],
              [
                -73.91649178886854,
                40.59317250872964
              ],
              [
                -73.91648493921161,
                40.593164711795275
              ],
              [
                -73.9164774023079,
                40.59315729264223
              ],
              [
                -73.91646921811346,
                40.59315028480526
              ],
              [
                -73.9164604226294,
                40.59314372097866
              ],
              [
                -73.91645105691396,
                40.593137631682296
              ],
              [
                -73.91644576551357,
                40.59313239572667
              ],
              [
                -73.91644112902213,
                40.593126809440484
              ],
              [
                -73.91643718935549,
                40.593120920600335
              ],
              [
                -73.91643398161206,
                40.59311478032825
              ],
              [
                -73.91643152880289,
                40.59310844024004
              ],
              [
                -73.9164298561325,
                40.593101955303645
              ],
              [
                -73.91642897496094,
                40.59309537930434
              ],
              [
                -73.91642889466654,
                40.59308876971152
              ],
              [
                -73.91642961583784,
                40.59308218264789
              ],
              [
                -73.91643113115211,
                40.593075674230406
              ],
              [
                -73.91643342713131,
                40.59306930191187
              ],
              [
                -73.91643201092783,
                40.592993103518715
              ],
              [
                -73.91641519169556,
                40.59293882719668
              ],
              [
                -73.9164112103134,
                40.59292887875456
              ],
              [
                -73.9164064832413,
                40.592919121925846
              ],
              [
                -73.9164010264833,
                40.59290958805044
              ],
              [
                -73.91639485647836,
                40.5929003121534
              ],
              [
                -73.91638799341142,
                40.592891321221764
              ],
              [
                -73.9163838190933,
                40.592883468597634
              ],
              [
                -73.91638032365744,
                40.592875424311316
              ],
              [
                -73.91637752617831,
                40.59286722473057
              ],
              [
                -73.91637543518614,
                40.592858902027096
              ],
              [
                -73.91637406096247,
                40.5928504937351
              ],
              [
                -73.91637341049615,
                40.5928420348735
              ],
              [
                -73.91637348374238,
                40.59283356062327
              ],
              [
                -73.91637278979103,
                40.59281841044097
              ],
              [
                -73.9163729318465,
                40.59280325048028
              ],
              [
                -73.91637390921291,
                40.59278810922053
              ],
              [
                -73.91637571922077,
                40.592773011956865
              ],
              [
                -73.91637508232326,
                40.592766353710275
              ],
              [
                -73.91637362076389,
                40.592759772262134
              ],
              [
                -73.91637134677796,
                40.592753325251216
              ],
              [
                -73.91636827963086,
                40.59274707199717
              ],
              [
                -73.91636444870188,
                40.59274106797325
              ],
              [
                -73.91635988820347,
                40.59273537033198
              ],
              [
                -73.91634218846329,
                40.592721110250565
              ],
              [
                -73.9163239447397,
                40.59270725552634
              ],
              [
                -73.91630517416276,
                40.59269381538572
              ],
              [
                -73.91628589099996,
                40.5926808035761
              ],
              [
                -73.91627974421226,
                40.59267775458736
              ],
              [
                -73.91627402851688,
                40.59267425190788
              ],
              [
                -73.91626880100847,
                40.59267033126319
              ],
              [
                -73.91626411548197,
                40.5926660305541
              ],
              [
                -73.91626390260747,
                40.592665792172184
              ],
              [
                -73.9162518590549,
                40.592656075573146
              ],
              [
                -73.91624945251799,
                40.59265124294339
              ],
              [
                -73.9162484985648,
                40.59263683204502
              ],
              [
                -73.91625229497743,
                40.5926247845946
              ],
              [
                -73.91625655485981,
                40.59261485320969
              ],
              [
                -73.9162635552358,
                40.59260456530891
              ],
              [
                -73.91626723431409,
                40.592598281284076
              ],
              [
                -73.91626562385585,
                40.5925904025463
              ],
              [
                -73.91625850431825,
                40.59258320599394
              ],
              [
                -73.9162453771656,
                40.592571695705516
              ],
              [
                -73.91623119728854,
                40.59256460373689
              ],
              [
                -73.91621295176718,
                40.59255783883499
              ],
              [
                -73.91619967037674,
                40.59255401383706
              ],
              [
                -73.91618772457261,
                40.59254733527939
              ],
              [
                -73.91618089572286,
                40.59253687261481
              ],
              [
                -73.91617734507624,
                40.59252627563727
              ],
              [
                -73.91616756691072,
                40.592512708177104
              ],
              [
                -73.91615216892161,
                40.59250366644124
              ],
              [
                -73.91608387128535,
                40.59246977431968
              ],
              [
                -73.91606440192857,
                40.59246108593366
              ],
              [
                -73.91604396293694,
                40.592451025105646
              ],
              [
                -73.91602654750764,
                40.592442174035114
              ],
              [
                -73.91601536006375,
                40.59243434324431
              ],
              [
                -73.91601046924518,
                40.59242852070569
              ],
              [
                -73.91600796522113,
                40.592415426122685
              ],
              [
                -73.91601348867175,
                40.592404532208946
              ],
              [
                -73.9160209498559,
                40.5923983080836
              ],
              [
                -73.916038492975,
                40.59239123927052
              ],
              [
                -73.91605729853244,
                40.59238321076389
              ],
              [
                -73.9160693358746,
                40.59237490172501
              ],
              [
                -73.91608090794963,
                40.592362586551076
              ],
              [
                -73.91609389321474,
                40.592345468504135
              ],
              [
                -73.9161023777214,
                40.59232656415504
              ],
              [
                -73.91609987302385,
                40.592313469573476
              ],
              [
                -73.91609232720587,
                40.592302236750065
              ],
              [
                -73.91607271953715,
                40.59228910337583
              ],
              [
                -73.916060851914,
                40.59227762146071
              ],
              [
                -73.91605485567763,
                40.59226411153528
              ],
              [
                -73.91605692678102,
                40.59224992297813
              ],
              [
                -73.91605928472141,
                40.5922353498157
              ],
              [
                -73.91605951520725,
                40.59222384926242
              ],
              [
                -73.9160567568717,
                40.592213060200834
              ],
              [
                -73.91604992895557,
                40.59220259752877
              ],
              [
                -73.91603722801985,
                40.592195121801495
              ],
              [
                -73.91601764425313,
                40.59219219867833
              ],
              [
                -73.91599809766262,
                40.59218732654275
              ],
              [
                -73.9159793868295,
                40.592178446707926
              ],
              [
                -73.91596453027877,
                40.59216674229603
              ],
              [
                -73.91595820693891,
                40.592156472813755
              ],
              [
                -73.91595375997414,
                40.5921416475703
              ],
              [
                -73.9159592077002,
                40.59213363611337
              ],
              [
                -73.9159654848041,
                40.59212353985746
              ],
              [
                -73.91596920508806,
                40.592114375540156
              ],
              [
                -73.91596584109006,
                40.592107785491294
              ],
              [
                -73.91596485962249,
                40.592105862876934
              ],
              [
                -73.91595741179528,
                40.59210097227443
              ],
              [
                -73.91594282730497,
                40.592092782656636
              ],
              [
                -73.91591233782945,
                40.59207408685877
              ],
              [
                -73.9158848745586,
                40.5920539726193
              ],
              [
                -73.9158414740981,
                40.59202713681674
              ],
              [
                -73.91581693482594,
                40.59201295624624
              ],
              [
                -73.91578588626044,
                40.59199502142596
              ],
              [
                -73.91576028801653,
                40.59197801451728
              ],
              [
                -73.91573749889469,
                40.5919626846212
              ],
              [
                -73.91572345856227,
                40.591951911401885
              ],
              [
                -73.91571036195032,
                40.59193970716734
              ],
              [
                -73.91569351970946,
                40.59192487014005
              ],
              [
                -73.91566887157265,
                40.59190523989588
              ],
              [
                -73.91563923277573,
                40.59188130215355
              ],
              [
                -73.91559773374159,
                40.59184922063521
              ],
              [
                -73.91555622818305,
                40.59181833307854
              ],
              [
                -73.91554218725159,
                40.59180755933398
              ],
              [
                -73.9155300466648,
                40.59179153996537
              ],
              [
                -73.91552850159098,
                40.591789561480596
              ],
              [
                -73.91552070534733,
                40.59177958440249
              ],
              [
                -73.9155050826615,
                40.5917723860744
              ],
              [
                -73.91549880725888,
                40.591771557383005
              ],
              [
                -73.91548850030455,
                40.59177019717104
              ],
              [
                -73.91547285817636,
                40.591767772405554
              ],
              [
                -73.91545911639325,
                40.59176010268695
              ],
              [
                -73.91545320606065,
                40.59175125731217
              ],
              [
                -73.9154447907877,
                40.591742645640196
              ],
              [
                -73.91542229154537,
                40.59173280599863
              ],
              [
                -73.91540228983338,
                40.59172511843073
              ],
              [
                -73.91539010272311,
                40.59171983831438
              ],
              [
                -73.91537950656559,
                40.591708596663636
              ],
              [
                -73.91536859466886,
                40.591697830060234
              ],
              [
                -73.91532923434016,
                40.59167792487982
              ],
              [
                -73.91529735058633,
                40.59166686890398
              ],
              [
                -73.9152573297239,
                40.591655075150484
              ],
              [
                -73.91524795391862,
                40.59165147255335
              ],
              [
                -73.91524390832531,
                40.5916457357098
              ],
              [
                -73.91524864533646,
                40.59163620159028
              ],
              [
                -73.91525180122733,
                40.59163024272094
              ],
              [
                -73.91524478304683,
                40.591587508178506
              ],
              [
                -73.91522916065571,
                40.59158030964545
              ],
              [
                -73.91522318452532,
                40.59158697675797
              ],
              [
                -73.91521524065092,
                40.59161440131527
              ],
              [
                -73.91521052507932,
                40.59161916337565
              ],
              [
                -73.91520629438757,
                40.59162022574052
              ],
              [
                -73.91520206369185,
                40.59162129095316
              ],
              [
                -73.91519173782501,
                40.59162030998716
              ],
              [
                -73.9151707793104,
                40.59161643916386
              ],
              [
                -73.91505290195363,
                40.5915824977516
              ],
              [
                -73.91501037646702,
                40.59157093810925
              ],
              [
                -73.91496940541205,
                40.591561768737435
              ],
              [
                -73.91492622875452,
                40.591555933730234
              ],
              [
                -73.91490745180603,
                40.591554694014796
              ],
              [
                -73.91489431446409,
                40.59155203715427
              ],
              [
                -73.91487868248505,
                40.59154722435184
              ],
              [
                -73.91486620116964,
                40.59153764783905
              ],
              [
                -73.91485433907953,
                40.59152950516489
              ],
              [
                -73.91484340298724,
                40.591524704046115
              ],
              [
                -73.91482496106987,
                40.59151821536827
              ],
              [
                -73.91479274617356,
                40.591511214222244
              ],
              [
                -73.91476051002702,
                40.591508986800335
              ],
              [
                -73.91466971094331,
                40.59151234102924
              ],
              [
                -73.91462401406288,
                40.591510319350284
              ],
              [
                -73.91459077606592,
                40.59150874600867
              ],
              [
                -73.9145654842131,
                40.591507549140566
              ],
              [
                -73.91453231311066,
                40.59150436470198
              ],
              [
                -73.91448160066902,
                40.591503999786774
              ],
              [
                -73.9144415190658,
                40.59150652644782
              ],
              [
                -73.91441866087001,
                40.591507901594845
              ],
              [
                -73.91439422677782,
                40.59151165799816
              ],
              [
                -73.91437041211175,
                40.591516849914335
              ],
              [
                -73.91434753262654,
                40.59152299710507
              ],
              [
                -73.91428863303828,
                40.591533828689535
              ],
              [
                -73.91422122921253,
                40.591556333539884
              ],
              [
                -73.91411744004438,
                40.59159592931138
              ],
              [
                -73.91408669435623,
                40.591611603325816
              ],
              [
                -73.914047785409,
                40.591632507691166
              ],
              [
                -73.91400292654167,
                40.59165411305677
              ],
              [
                -73.9139295519438,
                40.59168209130958
              ],
              [
                -73.91382323701731,
                40.5917266927106
              ],
              [
                -73.91376301345376,
                40.59175374791542
              ],
              [
                -73.91374261964577,
                40.591764436226356
              ],
              [
                -73.91372473055898,
                40.591774892040554
              ],
              [
                -73.91371184740139,
                40.59178607734835
              ],
              [
                -73.91369584515164,
                40.59179486682028
              ],
              [
                -73.91367483520123,
                40.591802928968804
              ],
              [
                -73.91363440815343,
                40.591813090574156
              ],
              [
                -73.91351840022506,
                40.59185456400565
              ],
              [
                -73.9134901741942,
                40.59186642662111
              ],
              [
                -73.91348546354506,
                40.59186999463282
              ],
              [
                -73.91347854059687,
                40.5918780909722
              ],
              [
                -73.91347506019093,
                40.59188691228988
              ],
              [
                -73.91347065385504,
                40.59189239036473
              ],
              [
                -73.91346218665768,
                40.591895709613595
              ],
              [
                -73.9134236499028,
                40.591903012507245
              ],
              [
                -73.91335188024114,
                40.59192144845819
              ],
              [
                -73.91329641035666,
                40.5919353902923
              ],
              [
                -73.91324094882303,
                40.59194694632045
              ],
              [
                -73.9131688710089,
                40.591964663893584
              ],
              [
                -73.91309803285056,
                40.59198525012645
              ],
              [
                -73.91304661071742,
                40.5920037364541
              ],
              [
                -73.91300897860152,
                40.59201915467987
              ],
              [
                -73.91298199276169,
                40.5920338842014
              ],
              [
                -73.91295122631982,
                40.59205432996962
              ],
              [
                -73.91292953548812,
                40.59207527665066
              ],
              [
                -73.91292793536515,
                40.59207748012446
              ],
              [
                -73.91292700612708,
                40.592079895360975
              ],
              [
                -73.91292678880089,
                40.59208240729889
              ],
              [
                -73.91292729629032,
                40.59208489499163
              ],
              [
                -73.91292850303763,
                40.59208723997671
              ],
              [
                -73.91293035161614,
                40.592089325777565
              ],
              [
                -73.91293275402934,
                40.592091053485206
              ],
              [
                -73.91293559105239,
                40.59209234075243
              ],
              [
                -73.91293872980943,
                40.59209312482254
              ],
              [
                -73.91294510479689,
                40.592094434035815
              ],
              [
                -73.91297933679013,
                40.59209974317011
              ],
              [
                -73.91301375793527,
                40.59209937067967
              ],
              [
                -73.91304815192677,
                40.59209824829683
              ],
              [
                -73.91308249063543,
                40.592096374325415
              ],
              [
                -73.91311674306567,
                40.592093754772776
              ],
              [
                -73.91312596704603,
                40.5920934065844
              ],
              [
                -73.91313519733325,
                40.59209361610527
              ],
              [
                -73.91314437568768,
                40.59209438496666
              ],
              [
                -73.91315344761742,
                40.59209570609116
              ],
              [
                -73.91316235094138,
                40.59209756988267
              ],
              [
                -73.9131710340265,
                40.59209996775797
              ],
              [
                -73.91317943909614,
                40.592102883088366
              ],
              [
                -73.91318751519033,
                40.592106296569355
              ],
              [
                -73.91319610149006,
                40.59211163836298
              ],
              [
                -73.91320404118419,
                40.592117532514415
              ],
              [
                -73.91321127587692,
                40.59212393173632
              ],
              [
                -73.91321774738982,
                40.592130790417
              ],
              [
                -73.91322340744581,
                40.59213805357044
              ],
              [
                -73.91323411215068,
                40.592157876354754
              ],
              [
                -73.91324371783442,
                40.59217802381779
              ],
              [
                -73.91325220674099,
                40.59219846093276
              ],
              [
                -73.91325634037796,
                40.59220838480609
              ],
              [
                -73.91325954327222,
                40.59221850733644
              ],
              [
                -73.91326180340411,
                40.59222877624605
              ],
              [
                -73.91326310633481,
                40.592239141097345
              ],
              [
                -73.91326344707652,
                40.59224954961761
              ],
              [
                -73.91326371992723,
                40.592262822000755
              ],
              [
                -73.91326326931149,
                40.592276093335386
              ],
              [
                -73.91326209482493,
                40.59228933664858
              ],
              [
                -73.9132601989084,
                40.59230253334669
              ],
              [
                -73.91325758511212,
                40.5923156569626
              ],
              [
                -73.91325425697755,
                40.59232868789812
              ],
              [
                -73.91325022046983,
                40.59234160136325
              ],
              [
                -73.91324027512778,
                40.59238681773064
              ],
              [
                -73.91323154928529,
                40.592432178255464
              ],
              [
                -73.91322404779922,
                40.592477668198995
              ],
              [
                -73.91321888794455,
                40.59249878171076
              ],
              [
                -73.91321285557164,
                40.592519759534696
              ],
              [
                -73.91320595268233,
                40.59254058240631
              ],
              [
                -73.91319819007434,
                40.592561227381836
              ],
              [
                -73.91318957392643,
                40.592581674530095
              ],
              [
                -73.9131801117352,
                40.592601904255474
              ],
              [
                -73.91316981451872,
                40.59262189344698
              ],
              [
                -73.91316533689628,
                40.592631096135634
              ],
              [
                -73.91316172772541,
                40.592640517436074
              ],
              [
                -73.91315900750725,
                40.59265010961811
              ],
              [
                -73.91315719168685,
                40.59265982528243
              ],
              [
                -73.91315628604208,
                40.592669615850184
              ],
              [
                -73.91316297118759,
                40.592734123717854
              ],
              [
                -73.91316653199776,
                40.592751586651225
              ],
              [
                -73.9131708761973,
                40.59276894362763
              ],
              [
                -73.91317599853208,
                40.592786179398125
              ],
              [
                -73.91318189134228,
                40.59280326966477
              ],
              [
                -73.91318854871699,
                40.59282019750264
              ],
              [
                -73.91319596453002,
                40.59283694196596
              ],
              [
                -73.91320166030528,
                40.59285278214622
              ],
              [
                -73.91320657966746,
                40.5928687743592
              ],
              [
                -73.91321071517602,
                40.59288489380467
              ],
              [
                -73.9132140622409,
                40.59290112087838
              ],
              [
                -73.91321661540135,
                40.592917429609386
              ],
              [
                -73.91321837160567,
                40.59293379989187
              ],
              [
                -73.91321932890631,
                40.592950208437685
              ],
              [
                -73.913222236431,
                40.59296323034306
              ],
              [
                -73.91322432146595,
                40.59297634494108
              ],
              [
                -73.91322557899919,
                40.59298951872209
              ],
              [
                -73.91322600686817,
                40.593002724377286
              ],
              [
                -73.91322560159801,
                40.593015929738435
              ],
              [
                -73.91322436872045,
                40.593029105492
              ],
              [
                -73.91322230519827,
                40.59304222081026
              ],
              [
                -73.9132194198598,
                40.593055246549746
              ],
              [
                -73.91321571824042,
                40.593068150381505
              ],
              [
                -73.91321409220434,
                40.59307912064933
              ],
              [
                -73.91321161534506,
                40.59308999913862
              ],
              [
                -73.9132082951851,
                40.59310074698815
              ],
              [
                -73.91320414430363,
                40.59311132333019
              ],
              [
                -73.91319917702849,
                40.59312169500469
              ],
              [
                -73.91319341143057,
                40.593131823325926
              ],
              [
                -73.91318686755433,
                40.593141672792555
              ],
              [
                -73.91317956676434,
                40.59315120756959
              ],
              [
                -73.91317511613073,
                40.59315686736825
              ],
              [
                -73.91317141121499,
                40.59316283062339
              ],
              [
                -73.91316848659537,
                40.59316904040104
              ],
              [
                -73.91316637333283,
                40.59317543976474
              ],
              [
                -73.91316509019187,
                40.593181964230084
              ],
              [
                -73.91316464867865,
                40.593188554165245
              ],
              [
                -73.91317118195181,
                40.593196160577854
              ],
              [
                -73.91317870050439,
                40.593203216562976
              ],
              [
                -73.91318712553303,
                40.593209650693474
              ],
              [
                -73.91319084913009,
                40.59321383051525
              ],
              [
                -73.91319387213517,
                40.59321832560056
              ],
              [
                -73.9131961495811,
                40.59322307074632
              ],
              [
                -73.91319764464198,
                40.59322799221201
              ],
              [
                -73.91319833653526,
                40.59323301626912
              ],
              [
                -73.91319821480876,
                40.59323806886176
              ],
              [
                -73.91319377112114,
                40.593277095985265
              ],
              [
                -73.91319249858384,
                40.59328171950357
              ],
              [
                -73.91319211005799,
                40.59328643365293
              ],
              [
                -73.91319261072314,
                40.59329114244282
              ],
              [
                -73.91319399257523,
                40.593295748197924
              ],
              [
                -73.91319622563225,
                40.59330015372814
              ],
              [
                -73.91319896559207,
                40.59329973630441
              ],
              [
                -73.91320174007198,
                40.593299982657896
              ],
              [
                -73.9132042803228,
                40.59330087114179
              ],
              [
                -73.91320633592201,
                40.59330231428433
              ],
              [
                -73.91320770530692,
                40.59330416970075
              ],
              [
                -73.91320725394404,
                40.59331292963591
              ],
              [
                -73.91320591324575,
                40.59332163629507
              ],
              [
                -73.91320368767602,
                40.59333023674252
              ],
              [
                -73.91320059444199,
                40.59333868073212
              ],
              [
                -73.91319665185189,
                40.593346916008585
              ],
              [
                -73.91319188128803,
                40.59335489266442
              ],
              [
                -73.91318631401867,
                40.593362563479566
              ],
              [
                -73.91317730072866,
                40.593377534461766
              ],
              [
                -73.91316762383072,
                40.5933922606841
              ],
              [
                -73.91315729476962,
                40.59340672892018
              ],
              [
                -73.91314632499565,
                40.59342092175523
              ],
              [
                -73.91313472793975,
                40.593434819766266
              ],
              [
                -73.91312251746469,
                40.59344840939321
              ],
              [
                -73.91310970633701,
                40.59346167523328
              ],
              [
                -73.9131054101615,
                40.59346481198675
              ],
              [
                -73.91310170265045,
                40.59346836097237
              ],
              [
                -73.91309865070238,
                40.593472254056266
              ],
              [
                -73.91309630912717,
                40.59347642443576
              ],
              [
                -73.91309471978188,
                40.5934807945765
              ],
              [
                -73.91309391243468,
                40.59348528827508
              ],
              [
                -73.91309389861794,
                40.59348982546134
              ],
              [
                -73.91310256497233,
                40.593525651124985
              ],
              [
                -73.91310288411862,
                40.5935300106469
              ],
              [
                -73.91310267588342,
                40.593552220857056
              ],
              [
                -73.91310672252689,
                40.5935614892939
              ],
              [
                -73.91311438459752,
                40.59357498354342
              ],
              [
                -73.91311688681535,
                40.593580111665084
              ],
              [
                -73.91311847430313,
                40.5935854464964
              ],
              [
                -73.91311912036744,
                40.593590896546225
              ],
              [
                -73.9131188115122,
                40.59359636229193
              ],
              [
                -73.91311755291649,
                40.59360174774314
              ],
              [
                -73.91310785650141,
                40.593665721702585
              ],
              [
                -73.91310224584822,
                40.59368457975379
              ],
              [
                -73.91309740334691,
                40.59370356135215
              ],
              [
                -73.91309333561674,
                40.593722647236795
              ],
              [
                -73.91309004685282,
                40.59374182266848
              ],
              [
                -73.91308753818599,
                40.59376106402652
              ],
              [
                -73.91308581425143,
                40.59378035590188
              ],
              [
                -73.91308805667423,
                40.59378973002564
              ],
              [
                -73.91308955462387,
                40.59379918969645
              ],
              [
                -73.91309030418766,
                40.59380870157294
              ],
              [
                -73.9130903025535,
                40.59381823097446
              ],
              [
                -73.91308954844835,
                40.59382774271889
              ],
              [
                -73.91308804543189,
                40.59383720296805
              ],
              [
                -73.91308768841496,
                40.59384370350563
              ],
              [
                -73.91308814719179,
                40.593850199634495
              ],
              [
                -73.91308941502501,
                40.593856633886666
              ],
              [
                -73.91309148232251,
                40.5938629462797
              ],
              [
                -73.9130943311372,
                40.59386908034235
              ],
              [
                -73.91309793495263,
                40.593874977754325
              ],
              [
                -73.91310263291687,
                40.59387908644554
              ],
              [
                -73.91310660421107,
                40.59388361676074
              ],
              [
                -73.9131097854148,
                40.59388849661484
              ],
              [
                -73.9131121247657,
                40.5938936465596
              ],
              [
                -73.91311358259483,
                40.59389898196291
              ],
              [
                -73.91311413594113,
                40.59390441485457
              ],
              [
                -73.91311187786731,
                40.59395165172404
              ],
              [
                -73.91310967476895,
                40.59396406917397
              ],
              [
                -73.91310671753541,
                40.593976394917675
              ],
              [
                -73.91310301037561,
                40.59398860265618
              ],
              [
                -73.91309856211481,
                40.59400066592642
              ],
              [
                -73.91309338179741,
                40.594012558600554
              ],
              [
                -73.91308748220384,
                40.59402425405113
              ],
              [
                -73.91308087435495,
                40.5940357278269
              ],
              [
                -73.91307549850795,
                40.59404452575076
              ],
              [
                -73.91307078498586,
                40.594053541628526
              ],
              [
                -73.91306674591392,
                40.59406274682179
              ],
              [
                -73.9130634000125,
                40.59407211068704
              ],
              [
                -73.91306842449032,
                40.59411139257373
              ],
              [
                -73.91306801812564,
                40.594118319603524
              ],
              [
                -73.91306857370918,
                40.5941252396546
              ],
              [
                -73.91307008145094,
                40.59413207699639
              ],
              [
                -73.91307252694513,
                40.59413875539214
              ],
              [
                -73.91307588369632,
                40.59414520043867
              ],
              [
                -73.91308011158317,
                40.59415133822508
              ],
              [
                -73.91308381127278,
                40.59415720505207
              ],
              [
                -73.91308822934405,
                40.594162774387534
              ],
              [
                -73.91309332564262,
                40.59416799711505
              ],
              [
                -73.9130990532032,
                40.59417282294016
              ],
              [
                -73.9131053617516,
                40.594177210612635
              ],
              [
                -73.91311219156654,
                40.59418111669704
              ],
              [
                -73.91315993990132,
                40.594206734349015
              ],
              [
                -73.91317005235025,
                40.59421494456072
              ],
              [
                -73.91317941032952,
                40.59422365863231
              ],
              [
                -73.9131879710342,
                40.594232837162195
              ],
              [
                -73.91319569584229,
                40.59424243606125
              ],
              [
                -73.91320254876632,
                40.59425241258229
              ],
              [
                -73.91320854177944,
                40.59426572882043
              ],
              [
                -73.91321568749602,
                40.59427870885975
              ],
              [
                -73.91322395917989,
                40.594291295049885
              ],
              [
                -73.91323337694475,
                40.594302215523214
              ],
              [
                -73.9132420076644,
                40.59431350865692
              ],
              [
                -73.91324982633047,
                40.59432513874831
              ],
              [
                -73.91325680990705,
                40.59433707512254
              ],
              [
                -73.91327614121455,
                40.59439720140775
              ],
              [
                -73.9132827505952,
                40.59440959585814
              ],
              [
                -73.91328850694116,
                40.59442223677017
              ],
              [
                -73.91329339161908,
                40.594435087776006
              ],
              [
                -73.91329739741934,
                40.59444811486208
              ],
              [
                -73.91330050724518,
                40.594461283001635
              ],
              [
                -73.91332902450166,
                40.594571672821644
              ],
              [
                -73.91335923316663,
                40.59465670265218
              ],
              [
                -73.91336239693057,
                40.59466998491807
              ],
              [
                -73.91336637028736,
                40.59468313879649
              ],
              [
                -73.91337114602034,
                40.59469613680738
              ],
              [
                -73.91337671207945,
                40.59470895096393
              ],
              [
                -73.91338305751242,
                40.59472155277809
              ],
              [
                -73.91338838307945,
                40.59472933333483
              ],
              [
                -73.91339441539591,
                40.59473680818051
              ],
              [
                -73.91340112506057,
                40.59474394043621
              ],
              [
                -73.91340847783525,
                40.59475069422487
              ],
              [
                -73.91341800788979,
                40.594756105901084
              ],
              [
                -73.91342688750655,
                40.59476212588781
              ],
              [
                -73.9134350503717,
                40.59476870974023
              ],
              [
                -73.91344243589238,
                40.59477580732157
              ],
              [
                -73.91345712503106,
                40.59479011985895
              ],
              [
                -73.91347100128986,
                40.594804897346314
              ],
              [
                -73.91348404008961,
                40.59482011229072
              ],
              [
                -73.91348741306078,
                40.59482630872339
              ],
              [
                -73.91349160331386,
                40.59483220991466
              ],
              [
                -73.91349656939875,
                40.59483774932377
              ],
              [
                -73.9135022555663,
                40.59484286994874
              ],
              [
                -73.91350860144662,
                40.594847519475174
              ],
              [
                -73.91351554205342,
                40.594851646254675
              ],
              [
                -73.91352057636503,
                40.59485354999327
              ],
              [
                -73.91352596208924,
                40.5948547793555
              ],
              [
                -73.91353154060256,
                40.59485529351244
              ],
              [
                -73.91353715522014,
                40.59485508145677
              ],
              [
                -73.9135426389073,
                40.594854147921026
              ],
              [
                -73.91354783910492,
                40.59485252093563
              ],
              [
                -73.91355430761881,
                40.594850213562296
              ],
              [
                -73.91356105347381,
                40.59484842573824
              ],
              [
                -73.91356800390113,
                40.59484717550153
              ],
              [
                -73.91357508679708,
                40.59484647703807
              ],
              [
                -73.91358222589302,
                40.594846335818836
              ],
              [
                -73.91358934624067,
                40.59484675631081
              ],
              [
                -73.91359637356418,
                40.5948477325947
              ],
              [
                -73.91360323117576,
                40.59484925456106
              ],
              [
                -73.91360984591306,
                40.59485130489927
              ],
              [
                -73.91362567389052,
                40.59485888476592
              ],
              [
                -73.91364109137724,
                40.594866940942794
              ],
              [
                -73.9136560746552,
                40.59487545984242
              ],
              [
                -73.91367059780575,
                40.59488443038827
              ],
              [
                -73.91368464019413,
                40.59489383413723
              ],
              [
                -73.91369817809823,
                40.59490366085192
              ],
              [
                -73.91371136923529,
                40.59491635339595
              ],
              [
                -73.91372371955862,
                40.59492952779163
              ],
              [
                -73.91373519921859,
                40.594943152856295
              ],
              [
                -73.91374578320782,
                40.59495719221758
              ],
              [
                -73.91376127018658,
                40.594977835911806
              ],
              [
                -73.91377770759524,
                40.594998049431794
              ],
              [
                -73.9137950717308,
                40.59501780746226
              ],
              [
                -73.91380330081489,
                40.5950255836439
              ],
              [
                -73.91381083754081,
                40.59503375500997
              ],
              [
                -73.9138176516205,
                40.595042289874726
              ],
              [
                -73.91382370947856,
                40.5950511491789
              ],
              [
                -73.91382898589073,
                40.595060294371784
              ],
              [
                -73.91383346024931,
                40.59506968556604
              ],
              [
                -73.9138537235489,
                40.59513316052115
              ],
              [
                -73.91385625476711,
                40.59514060021671
              ],
              [
                -73.91385956751664,
                40.59514786057181
              ],
              [
                -73.91386363944557,
                40.595154891478046
              ],
              [
                -73.91386844511617,
                40.59516164935875
              ],
              [
                -73.91387395030226,
                40.595168088955226
              ],
              [
                -73.91388267633272,
                40.59518216427003
              ],
              [
                -73.91389057169397,
                40.595196521081114
              ],
              [
                -73.9138976159783,
                40.595211134746435
              ],
              [
                -73.91390379823966,
                40.5952259717521
              ],
              [
                -73.91393854415261,
                40.59528213986123
              ],
              [
                -73.91397259619512,
                40.59533855454572
              ],
              [
                -73.91400595151501,
                40.595395210778236
              ],
              [
                -73.91405688721092,
                40.59546739604291
              ],
              [
                -73.91410718137445,
                40.595539842485735
              ],
              [
                -73.91415683400916,
                40.59561254591908
              ],
              [
                -73.91418267734579,
                40.59564875540079
              ],
              [
                -73.91420936445135,
                40.59568460816652
              ],
              [
                -73.91423688589165,
                40.595720092648975
              ],
              [
                -73.91428110220451,
                40.59577771098373
              ],
              [
                -73.91430326644782,
                40.59581832421601
              ],
              [
                -73.9143125910306,
                40.59583297255867
              ],
              [
                -73.91432278175411,
                40.595847282131885
              ],
              [
                -73.91433382129797,
                40.59586122075711
              ],
              [
                -73.91434568376329,
                40.595874761442396
              ],
              [
                -73.91434954174001,
                40.59587921037758
              ],
              [
                -73.91435260279731,
                40.59588400784973
              ],
              [
                -73.91435481363932,
                40.5958890682118
              ],
              [
                -73.91435613855114,
                40.595894306164574
              ],
              [
                -73.91435655303478,
                40.59589963088913
              ],
              [
                -73.9143560521526,
                40.59590495040828
              ],
              [
                -73.91435464041491,
                40.59591017492951
              ],
              [
                -73.91435482800439,
                40.59591779245828
              ],
              [
                -73.91435590267103,
                40.595925366755495
              ],
              [
                -73.91435785789893,
                40.59593283733825
              ],
              [
                -73.9143606757417,
                40.59594014606041
              ],
              [
                -73.91436433605422,
                40.59594723477416
              ],
              [
                -73.9143688074807,
                40.59595404750139
              ],
              [
                -73.91437576239598,
                40.59596445759244
              ],
              [
                -73.91438202425076,
                40.595975120303336
              ],
              [
                -73.91438757505767,
                40.595986008648765
              ],
              [
                -73.91439239968507,
                40.59599709631534
              ],
              [
                -73.91439649157746,
                40.59600835414823
              ],
              [
                -73.91439983516479,
                40.59601975466104
              ],
              [
                -73.91440265387341,
                40.59602639427079
              ],
              [
                -73.9144061486421,
                40.59603284440576
              ],
              [
                -73.91441030018171,
                40.5960390650121
              ],
              [
                -73.91441508107104,
                40.59604501653243
              ],
              [
                -73.91442046212607,
                40.59605066275853
              ],
              [
                -73.91442641042542,
                40.59605596848466
              ],
              [
                -73.91443288710968,
                40.596060901348714
              ],
              [
                -73.91443985397817,
                40.5960654299941
              ],
              [
                -73.9145031109535,
                40.5961005555275
              ],
              [
                -73.91451264755382,
                40.59610502241924
              ],
              [
                -73.91452169796004,
                40.596110040622364
              ],
              [
                -73.91453020968119,
                40.596115582120746
              ],
              [
                -73.91453812803861,
                40.59612161118993
              ],
              [
                -73.91454540714601,
                40.59612809043696
              ],
              [
                -73.91455200243439,
                40.59613498431266
              ],
              [
                -73.91455782032091,
                40.59614311816441
              ],
              [
                -73.91456444944365,
                40.596150882044014
              ],
              [
                -73.91457185074239,
                40.59615822884688
              ],
              [
                -73.91457997899913,
                40.59616511498144
              ],
              [
                -73.91459173933087,
                40.59617301719956
              ],
              [
                -73.91460416462144,
                40.5961803045756
              ],
              [
                -73.91461719909148,
                40.59618694238927
              ],
              [
                -73.91463078827157,
                40.59619290262249
              ],
              [
                -73.91464147134002,
                40.59619610115331
              ],
              [
                -73.91465244289965,
                40.59619867350541
              ],
              [
                -73.9146636390126,
                40.596200605391275
              ],
              [
                -73.91467499376066,
                40.59620188520234
              ],
              [
                -73.91468402067954,
                40.596202772438275
              ],
              [
                -73.91469289246983,
                40.59620432046137
              ],
              [
                -73.91470152607288,
                40.596206516142686
              ],
              [
                -73.91470984041722,
                40.59620933848096
              ],
              [
                -73.9147177586143,
                40.59621276111716
              ],
              [
                -73.91472520510443,
                40.596216749819206
              ],
              [
                -73.91473210938248,
                40.596221270191414
              ],
              [
                -73.91473840952901,
                40.596226276955136
              ],
              [
                -73.91474667965433,
                40.59623498908469
              ],
              [
                -73.91475405756363,
                40.59624415338349
              ],
              [
                -73.91476050464186,
                40.59625371772133
              ],
              [
                -73.91476597964072,
                40.596263627285836
              ],
              [
                -73.91478848941416,
                40.596317140097824
              ],
              [
                -73.91479213624416,
                40.59632106784538
              ],
              [
                -73.91479649616662,
                40.596324549320805
              ],
              [
                -73.9148014723568,
                40.59632750805907
              ],
              [
                -73.9148069635742,
                40.59632988317191
              ],
              [
                -73.91481284944607,
                40.596331622803476
              ],
              [
                -73.91486335090366,
                40.59634554890828
              ],
              [
                -73.91487543339264,
                40.596349394949385
              ],
              [
                -73.91488718947119,
                40.59635378990855
              ],
              [
                -73.91489857718574,
                40.59635871482398
              ],
              [
                -73.91490955501757,
                40.596364154922256
              ],
              [
                -73.9149200834365,
                40.59637008655266
              ],
              [
                -73.91493012378385,
                40.596376492096105
              ],
              [
                -73.91493964092737,
                40.59638334639759
              ],
              [
                -73.91496812229593,
                40.596412682920636
              ],
              [
                -73.91497149030398,
                40.59641742515102
              ],
              [
                -73.91497565747576,
                40.59642178533562
              ],
              [
                -73.91498054457234,
                40.596425684342
              ],
              [
                -73.91498606904307,
                40.59642905409223
              ],
              [
                -73.91499918969747,
                40.596435390038266
              ],
              [
                -73.91501168661961,
                40.59644241825519
              ],
              [
                -73.91502350117507,
                40.59645010201071
              ],
              [
                -73.91503457187356,
                40.596458403900684
              ],
              [
                -73.91507420366649,
                40.596494077729304
              ],
              [
                -73.91511318266967,
                40.596530166031975
              ],
              [
                -73.91515149921888,
                40.59656666394374
              ],
              [
                -73.91516952473685,
                40.596583378244326
              ],
              [
                -73.91518685632093,
                40.596600516381514
              ],
              [
                -73.91520347223593,
                40.59661806024657
              ],
              [
                -73.9152193595365,
                40.596635992239456
              ],
              [
                -73.91522598582947,
                40.596646776463636
              ],
              [
                -73.91523347892155,
                40.59665722593314
              ],
              [
                -73.91524180941101,
                40.59666730226208
              ],
              [
                -73.91525094460707,
                40.596676961365844
              ],
              [
                -73.9152608491663,
                40.596686170884965
              ],
              [
                -73.91527605403033,
                40.596698254254974
              ],
              [
                -73.91529057697453,
                40.59671081642155
              ],
              [
                -73.91530438923654,
                40.59672383474726
              ],
              [
                -73.91531746930816,
                40.5967372850923
              ],
              [
                -73.91532979194228,
                40.59675114549204
              ],
              [
                -73.91534133629257,
                40.59676539029938
              ],
              [
                -73.91536307918673,
                40.596794310642146
              ],
              [
                -73.91538570765951,
                40.596822833080616
              ],
              [
                -73.91540921250193,
                40.596850940686984
              ],
              [
                -73.91542043769202,
                40.596864028461596
              ],
              [
                -73.91543090572634,
                40.596877476368526
              ],
              [
                -73.915440597078,
                40.59689125742118
              ],
              [
                -73.91544151292464,
                40.59689314983561
              ],
              [
                -73.91545344898562,
                40.59692283207128
              ],
              [
                -73.9154643275876,
                40.596943048961904
              ],
              [
                -73.91547435092544,
                40.59696352154214
              ],
              [
                -73.91548351001532,
                40.596984227356764
              ],
              [
                -73.9155213755222,
                40.597053095318245
              ],
              [
                -73.91552539527324,
                40.59706060090371
              ],
              [
                -73.91553013578316,
                40.59706785723263
              ],
              [
                -73.9155355744642,
                40.59707482491902
              ],
              [
                -73.91554167664198,
                40.59708146406529
              ],
              [
                -73.91554841247242,
                40.59708773812804
              ],
              [
                -73.91555574309857,
                40.597093611897435
              ],
              [
                -73.91556362790365,
                40.597099051837766
              ],
              [
                -73.91557202032934,
                40.59710402993731
              ],
              [
                -73.91558087865401,
                40.59710851701496
              ],
              [
                -73.91559014950262,
                40.59711248790232
              ],
              [
                -73.91560139499292,
                40.597115316181856
              ],
              [
                -73.91561234036882,
                40.597118756391914
              ],
              [
                -73.91562292982492,
                40.59712279341376
              ],
              [
                -73.91563310734465,
                40.5971274055952
              ],
              [
                -73.91564282109223,
                40.59713256743394
              ],
              [
                -73.91565201637599,
                40.597138252085216
              ],
              [
                -73.91565862762218,
                40.59714225167274
              ],
              [
                -73.91566468287105,
                40.597146734676514
              ],
              [
                -73.91567012130248,
                40.59715165397592
              ],
              [
                -73.91567489088993,
                40.597156960949654
              ],
              [
                -73.9156789426856,
                40.59716260513551
              ],
              [
                -73.91568223654275,
                40.59716852853892
              ],
              [
                -73.91568473912828,
                40.59717467266789
              ],
              [
                -73.91568811152045,
                40.59717897243525
              ],
              [
                -73.91569220857743,
                40.597182888924515
              ],
              [
                -73.91569695610016,
                40.59718635255674
              ],
              [
                -73.915702269334,
                40.597189299943395
              ],
              [
                -73.91570805163852,
                40.59719168008486
              ],
              [
                -73.91571639548738,
                40.597195809434886
              ],
              [
                -73.91572424173867,
                40.59720047149739
              ],
              [
                -73.91573153351578,
                40.59720562937446
              ],
              [
                -73.91573821833664,
                40.597211246841454
              ],
              [
                -73.91574424372803,
                40.5972172804699
              ],
              [
                -73.9157495649093,
                40.597223687004295
              ],
              [
                -73.91582116481455,
                40.597298919401595
              ],
              [
                -73.91582834821361,
                40.597305506720275
              ],
              [
                -73.9158348937766,
                40.5973124668265
              ],
              [
                -73.9158407698957,
                40.59731976736431
              ],
              [
                -73.91584594694979,
                40.59732736877481
              ],
              [
                -73.91585039795515,
                40.59733523200433
              ],
              [
                -73.91585409966335,
                40.597343318169045
              ],
              [
                -73.91585703476251,
                40.597351586546715
              ],
              [
                -73.91585918616788,
                40.59735999105442
              ],
              [
                -73.91585967137381,
                40.597363013972526
              ],
              [
                -73.91585936271079,
                40.59736604954423
              ],
              [
                -73.91585826736222,
                40.597368978493904
              ],
              [
                -73.91585643470812,
                40.59737168241448
              ],
              [
                -73.91585019918199,
                40.597374018734975
              ],
              [
                -73.91584364635065,
                40.59737578371558
              ],
              [
                -73.91583687009222,
                40.597376952965526
              ],
              [
                -73.91582996053887,
                40.597377509463094
              ],
              [
                -73.9158276154909,
                40.59737713884606
              ],
              [
                -73.91582524438459,
                40.59737738974341
              ],
              [
                -73.91582312088312,
                40.5973782345459
              ],
              [
                -73.9158214952229,
                40.59737957509693
              ],
              [
                -73.915820555297,
                40.5973812530515
              ],
              [
                -73.91582041124065,
                40.59738307499418
              ],
              [
                -73.91582183952798,
                40.597385227953666
              ],
              [
                -73.91582389767622,
                40.59738706022103
              ],
              [
                -73.91582646691447,
                40.59738846700332
              ],
              [
                -73.91582940272734,
                40.59738936878566
              ],
              [
                -73.91583253506784,
                40.59738971367723
              ],
              [
                -73.91583568395936,
                40.59738948211257
              ],
              [
                -73.91583867158363,
                40.59738868552115
              ],
              [
                -73.9158413264497,
                40.5973873721932
              ],
              [
                -73.91584967642903,
                40.597387186151764
              ],
              [
                -73.91585800601077,
                40.59738766434826
              ],
              [
                -73.91586622618824,
                40.59738880286444
              ],
              [
                -73.91587424401098,
                40.59739058789482
              ],
              [
                -73.91588197356779,
                40.59739300128345
              ],
              [
                -73.91589255287641,
                40.597397639032906
              ],
              [
                -73.91590273640362,
                40.5974027645043
              ],
              [
                -73.91591248351031,
                40.59740836158555
              ],
              [
                -73.91592176104105,
                40.5974144052903
              ],
              [
                -73.91593052924073,
                40.597420875151236
              ],
              [
                -73.91593875715337,
                40.59742774484375
              ],
              [
                -73.91594641294233,
                40.59743498904764
              ],
              [
                -73.91600014145929,
                40.59748995229666
              ],
              [
                -73.9160164174995,
                40.59750843831349
              ],
              [
                -73.91603208942446,
                40.59752722744943
              ],
              [
                -73.91604714626143,
                40.59754630662963
              ],
              [
                -73.91606157945606,
                40.597565661943186
              ],
              [
                -73.91607538176632,
                40.59758528417079
              ],
              [
                -73.9160885433178,
                40.597605160740876
              ],
              [
                -73.9161020401838,
                40.59762702679646
              ],
              [
                -73.91611487786153,
                40.59764912188436
              ],
              [
                -73.91612704757105,
                40.59767143611411
              ],
              [
                -73.91613854295507,
                40.597693956246424
              ],
              [
                -73.9161493611691,
                40.59771667189275
              ],
              [
                -73.91615949277804,
                40.59773956981154
              ],
              [
                -73.91616941972902,
                40.597760754258296
              ],
              [
                -73.91617865392307,
                40.597782118292876
              ],
              [
                -73.91618718702189,
                40.597803650852356
              ],
              [
                -73.91619501706217,
                40.59782533937077
              ],
              [
                -73.91620213680828,
                40.597847170105595
              ],
              [
                -73.91620806619363,
                40.59786778789788
              ],
              [
                -73.91621307912544,
                40.59788854775658
              ],
              [
                -73.91621716882148,
                40.59790942437992
              ],
              [
                -73.91622033377298,
                40.59793039364274
              ],
              [
                -73.91622256719475,
                40.597951431918446
              ],
              [
                -73.91622571836957,
                40.59797077194902
              ],
              [
                -73.91622814769968,
                40.59799017176383
              ],
              [
                -73.91622985454572,
                40.5980096154471
              ],
              [
                -73.91623083342898,
                40.598029089425175
              ],
              [
                -73.91623108832657,
                40.598048576612705
              ],
              [
                -73.91623204956238,
                40.59805689078467
              ],
              [
                -73.9162337438657,
                40.5980651363014
              ],
              [
                -73.91623616293184,
                40.598073275797674
              ],
              [
                -73.91623929361789,
                40.598081274250426
              ],
              [
                -73.9162416452982,
                40.59808724201596
              ],
              [
                -73.91624301761748,
                40.59809338648106
              ],
              [
                -73.91624338937461,
                40.59809961314378
              ],
              [
                -73.9162427571722,
                40.598105826845305
              ],
              [
                -73.91626393108346,
                40.598161137312744
              ],
              [
                -73.91628872592389,
                40.59817424862464
              ],
              [
                -73.91630524090209,
                40.59818554173763
              ],
              [
                -73.91631995192462,
                40.59819856226888
              ],
              [
                -73.91632987687207,
                40.59820828619148
              ],
              [
                -73.91633635086238,
                40.598215647951484
              ],
              [
                -73.91634329033828,
                40.59822320120079
              ],
              [
                -73.91635253692556,
                40.59823020663312
              ],
              [
                -73.91636304525383,
                40.59823531906491
              ],
              [
                -73.91637413205817,
                40.598238676377704
              ],
              [
                -73.91638820908456,
                40.598241294715805
              ],
              [
                -73.91643318584649,
                40.59823957021609
              ],
              [
                -73.91645838918683,
                40.598241728893974
              ],
              [
                -73.91648413507367,
                40.59824309253193
              ],
              [
                -73.91651917038025,
                40.5982456981055
              ],
              [
                -73.91654685169436,
                40.59825269061002
              ],
              [
                -73.91657399383551,
                40.59826042369829
              ],
              [
                -73.91659706414211,
                40.59826947026975
              ],
              [
                -73.91661660534268,
                40.598279063269366
              ],
              [
                -73.91663492212591,
                40.59828865588008
              ],
              [
                -73.91665388086413,
                40.59830286086588
              ],
              [
                -73.91666733468917,
                40.59831492083318
              ],
              [
                -73.91668204701033,
                40.59832698003602
              ],
              [
                -73.9166990656994,
                40.598337505503245
              ],
              [
                -73.9167168437384,
                40.59834786583189
              ],
              [
                -73.91676075195072,
                40.598365547184315
              ],
              [
                -73.91683159151566,
                40.598387529390436
              ],
              [
                -73.9168949456315,
                40.59840654120745
              ],
              [
                -73.91692359695183,
                40.59841584103257
              ],
              [
                -73.91694338962024,
                40.59842600945371
              ],
              [
                -73.9169657041723,
                40.598435248063446
              ],
              [
                -73.91698503186986,
                40.598442344157654
              ],
              [
                -73.91700990567614,
                40.5984506514697
              ],
              [
                -73.91703301312745,
                40.5984587366974
              ],
              [
                -73.91705557819927,
                40.5984685536139
              ],
              [
                -73.91706910902833,
                40.59847674232392
              ],
              [
                -73.91708785377304,
                40.59848940882983
              ],
              [
                -73.91711134867722,
                40.59850444092122
              ],
              [
                -73.91713135620083,
                40.59851614704808
              ],
              [
                -73.9171636707798,
                40.598534121940524
              ],
              [
                -73.91718598486706,
                40.59854432061714
              ],
              [
                -73.91722082113657,
                40.59856136350259
              ],
              [
                -73.91725216844844,
                40.59857511007312
              ],
              [
                -73.91730300654208,
                40.59859247455116
              ],
              [
                -73.91732851226746,
                40.59860118683176
              ],
              [
                -73.91735212322997,
                40.59861042579631
              ],
              [
                -73.91737141259789,
                40.59861944201798
              ],
              [
                -73.9173912030149,
                40.598631533930664
              ],
              [
                -73.91741142408016,
                40.5986448053876
              ],
              [
                -73.91743193445951,
                40.59865766676978
              ],
              [
                -73.91745827841918,
                40.59866946031888
              ],
              [
                -73.91751290831591,
                40.59869667312019
              ],
              [
                -73.91752841691161,
                40.598706620409466
              ],
              [
                -73.91754687082093,
                40.59872159233635
              ],
              [
                -73.91756133029189,
                40.59873499834696
              ],
              [
                -73.91757625561311,
                40.59874955595092
              ],
              [
                -73.91758866362653,
                40.59876312416288
              ],
              [
                -73.91760114715821,
                40.598772849642174
              ],
              [
                -73.9176158979434,
                40.598782988532264
              ],
              [
                -73.91763291686203,
                40.59879354133554
              ],
              [
                -73.91764666435176,
                40.59880134582037
              ],
              [
                -73.91766195936232,
                40.598808795245105
              ],
              [
                -73.91767804865373,
                40.59881413168813
              ],
              [
                -73.91770871940791,
                40.598821317539446
              ],
              [
                -73.9177162426041,
                40.59882425988118
              ],
              [
                -73.91773056760843,
                40.59883036300761
              ],
              [
                -73.91773905845905,
                40.59883657286049
              ],
              [
                -73.91775902524327,
                40.59885118950123
              ],
              [
                -73.91777298385558,
                40.59886248174546
              ],
              [
                -73.91779176637456,
                40.598873226267685
              ],
              [
                -73.91783715009365,
                40.59889439421103
              ],
              [
                -73.91786446493568,
                40.598907505308006
              ],
              [
                -73.91788652720797,
                40.59891712787
              ],
              [
                -73.91792064845438,
                40.598931479512274
              ],
              [
                -73.91796123355884,
                40.59894806021192
              ],
              [
                -73.91799442804891,
                40.59896397740137
              ],
              [
                -73.91804585326514,
                40.59899099433261
              ],
              [
                -73.91807651254089,
                40.59900817118528
              ],
              [
                -73.9181046173184,
                40.59902367242493
              ],
              [
                -73.91813441167821,
                40.599041095248786
              ],
              [
                -73.9181494507974,
                40.59905172932432
              ],
              [
                -73.91815912501023,
                40.599061918305395
              ],
              [
                -73.91816725209881,
                40.59907106482226
              ],
              [
                -73.91817444689813,
                40.59907694385496
              ],
              [
                -73.91818826639198,
                40.59908420050544
              ],
              [
                -73.91820399820861,
                40.59908863028693
              ],
              [
                -73.91822768169669,
                40.5990964972257
              ],
              [
                -73.91824247639758,
                40.59910142155158
              ],
              [
                -73.91826766956041,
                40.59911274635402
              ],
              [
                -73.9183307496015,
                40.599149544597836
              ],
              [
                -73.91834996643696,
                40.59916023606275
              ],
              [
                -73.91835846285828,
                40.599161971335704
              ],
              [
                -73.91836188575677,
                40.59916095820504
              ],
              [
                -73.91836476827352,
                40.599158983407925
              ],
              [
                -73.91836574328038,
                40.599156624116326
              ],
              [
                -73.9183734172165,
                40.599152458089534
              ],
              [
                -73.91838252841951,
                40.59915254631499
              ],
              [
                -73.91839995371869,
                40.59915590793324
              ],
              [
                -73.91842418174826,
                40.59916155093263
              ],
              [
                -73.91843548283042,
                40.59916639083171
              ],
              [
                -73.91844534064039,
                40.599173149422995
              ],
              [
                -73.91849250402807,
                40.59921136195501
              ],
              [
                -73.91849962223338,
                40.5992204259785
              ],
              [
                -73.91850202337783,
                40.59923036383942
              ],
              [
                -73.91850154385789,
                40.59923985989337
              ],
              [
                -73.9185056337515,
                40.599251144546486
              ],
              [
                -73.9185095875017,
                40.59925836568167
              ],
              [
                -73.91851393301229,
                40.599266768343014
              ],
              [
                -73.91851518774826,
                40.599272560717885
              ],
              [
                -73.91851396151061,
                40.59927349248356
              ],
              [
                -73.91850866798785,
                40.5992741469524
              ],
              [
                -73.91847353794809,
                40.59926113969438
              ],
              [
                -73.91846550873942,
                40.59926033856943
              ],
              [
                -73.9184602497121,
                40.59926154255631
              ],
              [
                -73.9184567146926,
                40.5992665356019
              ],
              [
                -73.91845879275182,
                40.599273946893824
              ],
              [
                -73.91850101350444,
                40.59932017235565
              ],
              [
                -73.9185162581545,
                40.59934024763768
              ],
              [
                -73.91852481921545,
                40.59934739078725
              ],
              [
                -73.91854795461344,
                40.5993623388988
              ],
              [
                -73.91856022204298,
                40.59937231172802
              ],
              [
                -73.9185676996966,
                40.59938258204469
              ],
              [
                -73.9185740177184,
                40.599398643024266
              ],
              [
                -73.9185809834588,
                40.599415967632794
              ],
              [
                -73.91858809185996,
                40.59943310018573
              ],
              [
                -73.91859431220341,
                40.59944131268619
              ],
              [
                -73.91860193800093,
                40.59944708159542
              ],
              [
                -73.91862464667486,
                40.599458378257275
              ],
              [
                -73.91865887598058,
                40.59947242837071
              ],
              [
                -73.91869397302801,
                40.59948304926218
              ],
              [
                -73.91872292022468,
                40.59948784515845
              ],
              [
                -73.91875262975405,
                40.59948695983317
              ],
              [
                -73.91877290619072,
                40.59948351569318
              ],
              [
                -73.91878975891339,
                40.59948481707567
              ],
              [
                -73.91880275327955,
                40.59948861576836
              ],
              [
                -73.91881748280642,
                40.59948868062134
              ],
              [
                -73.91883099294382,
                40.599483501951916
              ],
              [
                -73.91883409341436,
                40.59947996324422
              ],
              [
                -73.91883561589877,
                40.5994710991759
              ],
              [
                -73.91884394589192,
                40.59946152456533
              ],
              [
                -73.91885687930014,
                40.59945670399819
              ],
              [
                -73.91887261711781,
                40.5994563034897
              ],
              [
                -73.9188916996778,
                40.599458622496165
              ],
              [
                -73.91890901034738,
                40.59946651227104
              ],
              [
                -73.91892365348792,
                40.599477803182296
              ],
              [
                -73.9189367403538,
                40.59949557235395
              ],
              [
                -73.91894334746688,
                40.59950984832563
              ],
              [
                -73.91894466693066,
                40.59952047328901
              ],
              [
                -73.91893936296279,
                40.599529718500904
              ],
              [
                -73.91893179296305,
                40.599534983630335
              ],
              [
                -73.91892389949841,
                40.59954200473985
              ],
              [
                -73.91892306220868,
                40.599548975374844
              ],
              [
                -73.91892780618915,
                40.59955671907388
              ],
              [
                -73.91893450034259,
                40.59955977165504
              ],
              [
                -73.91894166672142,
                40.59955892582917
              ],
              [
                -73.91894948394368,
                40.59955695449778
              ],
              [
                -73.91896144073314,
                40.59955638595923
              ],
              [
                -73.91897393464515,
                40.59955730110181
              ],
              [
                -73.91899643132949,
                40.59956533122337
              ],
              [
                -73.91901705219169,
                40.59957728623355
              ],
              [
                -73.91903590917147,
                40.59958676961136
              ],
              [
                -73.91905614206664,
                40.59959079066001
              ],
              [
                -73.91907123177553,
                40.5995896485187
              ],
              [
                -73.91908229418473,
                40.599582602155714
              ],
              [
                -73.91909908366019,
                40.59957663033503
              ],
              [
                -73.91911572215265,
                40.59957463722452
              ],
              [
                -73.91912742272756,
                40.5995769241914
              ],
              [
                -73.91913595045415,
                40.599582996760006
              ],
              [
                -73.91914116057043,
                40.59959170139074
              ],
              [
                -73.91914244716914,
                40.59959990871455
              ],
              [
                -73.91914776993256,
                40.59960597902738
              ],
              [
                -73.91915856902736,
                40.59960928091884
              ],
              [
                -73.91917567513987,
                40.59960827688601
              ],
              [
                -73.91920264897199,
                40.59960541435092
              ],
              [
                -73.91923769619382,
                40.59959830190617
              ],
              [
                -73.91926585786376,
                40.5995977174154
              ],
              [
                -73.91928832602004,
                40.59959902367481
              ],
              [
                -73.91933109688695,
                40.59960734355339
              ],
              [
                -73.91935694674213,
                40.59961136798919
              ],
              [
                -73.91939817858746,
                40.59961191843322
              ],
              [
                -73.9194367504255,
                40.5996078282813
              ],
              [
                -73.9194634744964,
                40.59960356513312
              ],
              [
                -73.9194856681038,
                40.59959433072601
              ],
              [
                -73.91951280094558,
                40.599581339915694
              ],
              [
                -73.91955859161355,
                40.59956221383822
              ],
              [
                -73.9196387882035,
                40.59953092271564
              ],
              [
                -73.91966742919745,
                40.599520842403244
              ],
              [
                -73.919690043808,
                40.59951951328441
              ],
              [
                -73.91977249931006,
                40.599527339205835
              ],
              [
                -73.91981967414765,
                40.59952610895856
              ],
              [
                -73.91986505165498,
                40.59952240822561
              ],
              [
                -73.91991463613738,
                40.59952439215991
              ],
              [
                -73.91997188266981,
                40.59953134781201
              ],
              [
                -73.91998332969219,
                40.59953596652177
              ],
              [
                -73.91998771390962,
                40.59954305170422
              ],
              [
                -73.91998356109615,
                40.59955213408974
              ],
              [
                -73.91996319600395,
                40.599568698599455
              ],
              [
                -73.91990064335972,
                40.599600522592766
              ]
            ]
          ],
          [
            [
              [
                -73.91513331918173,
                40.58626938540072
              ],
              [
                -73.91515204156394,
                40.58627781540403
              ],
              [
                -73.91516591088359,
                40.58628623294222
              ],
              [
                -73.91519436770284,
                40.586287289281145
              ],
              [
                -73.91521120965783,
                40.58628141355369
              ],
              [
                -73.91522159402,
                40.58627305563373
              ],
              [
                -73.91523328597421,
                40.58626149601752
              ],
              [
                -73.91524915677222,
                40.58625586550119
              ],
              [
                -73.91527796350833,
                40.5862502659654
              ],
              [
                -73.91535428832987,
                40.58624996152778
              ],
              [
                -73.91538726288206,
                40.58625275474167
              ],
              [
                -73.91541313034281,
                40.586254052372254
              ],
              [
                -73.91543485999519,
                40.58623955881032
              ],
              [
                -73.91544945077013,
                40.58623096456614
              ],
              [
                -73.91546951520041,
                40.58622756256615
              ],
              [
                -73.91549894226415,
                40.586228375285664
              ],
              [
                -73.91560372717565,
                40.58622789509719
              ],
              [
                -73.9156609884719,
                40.5862233516944
              ],
              [
                -73.91569369009719,
                40.58621431017306
              ],
              [
                -73.91570763002198,
                40.58620670132931
              ],
              [
                -73.91572385197135,
                40.58619441321773
              ],
              [
                -73.91575157363133,
                40.58615320555848
              ],
              [
                -73.91575583739197,
                40.58613940894999
              ],
              [
                -73.91576429525115,
                40.58612784137988
              ],
              [
                -73.91578373549699,
                40.58611926053255
              ],
              [
                -73.9157995907863,
                40.58611732663337
              ],
              [
                -73.91586132497896,
                40.58612561564516
              ],
              [
                -73.91588942769874,
                40.58613332766476
              ],
              [
                -73.9159168763074,
                40.586143258126185
              ],
              [
                -73.91594435422995,
                40.58614579251291
              ],
              [
                -73.91597154288236,
                40.5861401882691
              ],
              [
                -73.91600427151542,
                40.58612498243353
              ],
              [
                -73.91603052799135,
                40.58611099264596
              ],
              [
                -73.9160548296259,
                40.586099957648706
              ],
              [
                -73.91607976201512,
                40.58609286959775
              ],
              [
                -73.9161118133772,
                40.586084811718
              ],
              [
                -73.91613643951098,
                40.586073284571306
              ],
              [
                -73.91618892396225,
                40.5860514703273
              ],
              [
                -73.9162060865486,
                40.58604633416272
              ],
              [
                -73.91621741107191,
                40.5860451303454
              ],
              [
                -73.91623259946527,
                40.58604787901804
              ],
              [
                -73.91625445075606,
                40.58605447315751
              ],
              [
                -73.91628477437516,
                40.586046645663444
              ],
              [
                -73.91632781802157,
                40.58602943924279
              ],
              [
                -73.91636452334217,
                40.58601590663649
              ],
              [
                -73.91640867322334,
                40.586007238290954
              ],
              [
                -73.91647093984702,
                40.58599320205486
              ],
              [
                -73.91653688591344,
                40.585973487534574
              ],
              [
                -73.91658474943861,
                40.58596111623532
              ],
              [
                -73.91664168755146,
                40.58594713216046
              ],
              [
                -73.91669002681094,
                40.58592787017412
              ],
              [
                -73.91672834696432,
                40.58591798975894
              ],
              [
                -73.91674588603664,
                40.58591344667326
              ],
              [
                -73.91676079252959,
                40.58591298973111
              ],
              [
                -73.91677519324223,
                40.58591335700053
              ],
              [
                -73.91679336548401,
                40.585920205362974
              ],
              [
                -73.916826413246,
                40.58592253483053
              ],
              [
                -73.91685137450696,
                40.585914729607154
              ],
              [
                -73.91687849705549,
                40.58590366029057
              ],
              [
                -73.91689071090967,
                40.585897109678164
              ],
              [
                -73.91690133788347,
                40.585890995838696
              ],
              [
                -73.91692798089821,
                40.58589090637801
              ],
              [
                -73.91695779321046,
                40.585889993616846
              ],
              [
                -73.91697482395934,
                40.585888304160385
              ],
              [
                -73.91699186041457,
                40.58588255345677
              ],
              [
                -73.91701358970802,
                40.585867444762776
              ],
              [
                -73.91702577438325,
                40.585854826868726
              ],
              [
                -73.91702254497318,
                40.58584549281175
              ],
              [
                -73.91702143445264,
                40.58584101796852
              ],
              [
                -73.91702778060761,
                40.585833281686234
              ],
              [
                -73.91703777686546,
                40.585813746427874
              ],
              [
                -73.91703876934454,
                40.585797910754025
              ],
              [
                -73.9170274534233,
                40.58577844085546
              ],
              [
                -73.91702634637925,
                40.5857699305656
              ],
              [
                -73.91703265900931,
                40.58576016530978
              ],
              [
                -73.91703907035587,
                40.58575849447719
              ],
              [
                -73.91706848518903,
                40.585736469753876
              ],
              [
                -73.91707247680205,
                40.58573362310762
              ],
              [
                -73.91707140079066,
                40.585729148198695
              ],
              [
                -73.91706334718288,
                40.58572104494091
              ],
              [
                -73.9170643642343,
                40.585713332863925
              ],
              [
                -73.91706646222588,
                40.58570600583345
              ],
              [
                -73.91707762657224,
                40.585703132108954
              ],
              [
                -73.91709732310541,
                40.58570144483368
              ],
              [
                -73.91711540304983,
                40.585696105156536
              ],
              [
                -73.91713348553323,
                40.58568873491521
              ],
              [
                -73.9171515959741,
                40.585687456870694
              ],
              [
                -73.91719685570743,
                40.58568323522975
              ],
              [
                -73.91722134212345,
                40.58568028891875
              ],
              [
                -73.9172325088335,
                40.58567538378926
              ],
              [
                -73.91723939050807,
                40.58567088809598
              ],
              [
                -73.917253758239,
                40.58566922303926
              ],
              [
                -73.91726764686682,
                40.585676479950266
              ],
              [
                -73.91727624210911,
                40.585683759690035
              ],
              [
                -73.91728692782108,
                40.58568983298939
              ],
              [
                -73.91730608489028,
                40.58568690990466
              ],
              [
                -73.91731725148759,
                40.585682005105916
              ],
              [
                -73.91733679717491,
                40.58565594382682
              ],
              [
                -73.91735050897289,
                40.58563070089981
              ],
              [
                -73.91735949096542,
                40.58561687400963
              ],
              [
                -73.91735676211395,
                40.58561077908913
              ],
              [
                -73.91735086460804,
                40.58560553188344
              ],
              [
                -73.917332790029,
                40.58560680920559
              ],
              [
                -73.91731785281505,
                40.585603202989304
              ],
              [
                -73.91732099320654,
                40.58559628814148
              ],
              [
                -73.91733000611228,
                40.58558649515049
              ],
              [
                -73.91734059880369,
                40.58558038228434
              ],
              [
                -73.91736083787714,
                40.58557584007939
              ],
              [
                -73.9174183969112,
                40.58558334749755
              ],
              [
                -73.91745201487552,
                40.58559094791694
              ],
              [
                -73.91748137764621,
                40.58560304522633
              ],
              [
                -73.91750062805785,
                40.585612309123725
              ],
              [
                -73.9175140048618,
                40.585624450164104
              ],
              [
                -73.91752738910463,
                40.58563252983091
              ],
              [
                -73.91756842886592,
                40.585634837923806
              ],
              [
                -73.91761790796343,
                40.58562611720425
              ],
              [
                -73.91767005590565,
                40.5856133637893
              ],
              [
                -73.917684391637,
                40.58560760983217
              ],
              [
                -73.91769711376763,
                40.58559620098828
              ],
              [
                -73.9177055554971,
                40.58558316860574
              ],
              [
                -73.91771672592182,
                40.58557623213947
              ],
              [
                -73.91773324586171,
                40.58557942908907
              ],
              [
                -73.91774656917057,
                40.585577352068825
              ],
              [
                -73.91776246144475,
                40.58556509491476
              ],
              [
                -73.91776931508163,
                40.585554531821415
              ],
              [
                -73.91778308723293,
                40.58553944504273
              ],
              [
                -73.9177926701432,
                40.58553494996175
              ],
              [
                -73.91781340858657,
                40.58553364790511
              ],
              [
                -73.91783158505767,
                40.585538464107415
              ],
              [
                -73.91785340457652,
                40.58553554292887
              ],
              [
                -73.91787462576838,
                40.58552328917213
              ],
              [
                -73.9178910616563,
                40.58550820433879
              ],
              [
                -73.91792602311763,
                40.58547680106673
              ],
              [
                -73.9179361149062,
                40.58546742024709
              ],
              [
                -73.91798290071343,
                40.58545263278522
              ],
              [
                -73.91802016905545,
                40.58544845985361
              ],
              [
                -73.9180447140579,
                40.5854556679581
              ],
              [
                -73.9180990127636,
                40.58544980595572
              ],
              [
                -73.91812882886319,
                40.58544483180026
              ],
              [
                -73.91815112990798,
                40.58543296197253
              ],
              [
                -73.918161186817,
                40.58542358221696
              ],
              [
                -73.91814993016737,
                40.58541226592164
              ],
              [
                -73.91814559186984,
                40.58539845590684
              ],
              [
                -73.91815930380116,
                40.58537324092018
              ],
              [
                -73.9181624127841,
                40.58536226392255
              ],
              [
                -73.9181937597851,
                40.58534266121693
              ],
              [
                -73.9182245319517,
                40.585321822118935
              ],
              [
                -73.91823994698542,
                40.585316508142256
              ],
              [
                -73.91825592986801,
                40.58531846876331
              ],
              [
                -73.91826712194714,
                40.58532168793035
              ],
              [
                -73.91827146785576,
                40.58533140808124
              ],
              [
                -73.91826511878484,
                40.585341203056636
              ],
              [
                -73.91824820621798,
                40.585363175901406
              ],
              [
                -73.91823982570482,
                40.58538636456421
              ],
              [
                -73.91823404896664,
                40.58539936983953
              ],
              [
                -73.91823036218165,
                40.58541117092258
              ],
              [
                -73.91823895680054,
                40.58541844979083
              ],
              [
                -73.91824643996013,
                40.58542331388145
              ],
              [
                -73.9182735591321,
                40.58541630618432
              ],
              [
                -73.91836770486005,
                40.585387937842434
              ],
              [
                -73.91839850282939,
                40.585375249219055
              ],
              [
                -73.9184219442395,
                40.5853719180042
              ],
              [
                -73.91845484179895,
                40.58534987385609
              ],
              [
                -73.91849417580627,
                40.58533431108199
              ],
              [
                -73.91853027186833,
                40.58531547890593
              ],
              [
                -73.91856856184334,
                40.58530156299117
              ],
              [
                -73.91860367512984,
                40.585292503038126
              ],
              [
                -73.91864411675294,
                40.58528341897724
              ],
              [
                -73.91867386954468,
                40.585270320225796
              ],
              [
                -73.9187126627855,
                40.58525555293776
              ],
              [
                -73.91873721872057,
                40.58524552776275
              ],
              [
                -73.91875783174495,
                40.58523711184288
              ],
              [
                -73.91877854743201,
                40.58522568102734
              ],
              [
                -73.91878746251156,
                40.585207791930536
              ],
              [
                -73.91880491087741,
                40.585189084310024
              ],
              [
                -73.91883139681731,
                40.58516870923595
              ],
              [
                -73.9188477987526,
                40.58515162016086
              ],
              [
                -73.91885357481186,
                40.58513861378098
              ],
              [
                -73.91885072980678,
                40.58511023119707
              ],
              [
                -73.91885704690988,
                40.5850964284581
              ],
              [
                -73.91887078202592,
                40.58508136983835
              ],
              [
                -73.91888191484048,
                40.5850744328019
              ],
              [
                -73.91891800789304,
                40.585059691014564
              ],
              [
                -73.91895996358046,
                40.58504819221252
              ],
              [
                -73.9189907896268,
                40.585041599882885
              ],
              [
                -73.91903223468157,
                40.58503698987687
              ],
              [
                -73.91904558254163,
                40.58502727326961
              ],
              [
                -73.91905651889365,
                40.58501594077381
              ],
              [
                -73.91906471319794,
                40.58500333392851
              ],
              [
                -73.9190947752347,
                40.5849742059551
              ],
              [
                -73.91912638043381,
                40.584948536089435
              ],
              [
                -73.91916952508687,
                40.58493728597217
              ],
              [
                -73.91920586215204,
                40.584927786223666
              ],
              [
                -73.91927705899953,
                40.58491114777055
              ],
              [
                -73.91929297386154,
                40.58490989686085
              ],
              [
                -73.91932871683659,
                40.584916015102
              ],
              [
                -73.91937580400347,
                40.58491920492259
              ],
              [
                -73.91942049622301,
                40.58490850321154
              ],
              [
                -73.91947054421904,
                40.58490414853632
              ],
              [
                -73.91951750146096,
                40.58489575386747
              ],
              [
                -73.91955459723633,
                40.584885103282424
              ],
              [
                -73.91958942401223,
                40.584875053196185
              ],
              [
                -73.91962490654521,
                40.584858033802526
              ],
              [
                -73.91964457281003,
                40.584850965110405
              ],
              [
                -73.91964529779912,
                40.58484690373697
              ],
              [
                -73.91964214014378,
                40.58483710290166
              ],
              [
                -73.91964661390365,
                40.58483013376188
              ],
              [
                -73.9196610169716,
                40.584828305063716
              ],
              [
                -73.91967772176935,
                40.58482875678562
              ],
              [
                -73.91972168778938,
                40.58482211613802
              ],
              [
                -73.91977009476832,
                40.58480562699643
              ],
              [
                -73.91980409869021,
                40.58479093906657
              ],
              [
                -73.91981686088313,
                40.58477697595833
              ],
              [
                -73.91982199311455,
                40.58476015392272
              ],
              [
                -73.91983330502325,
                40.584754287910506
              ],
              [
                -73.91984853078816,
                40.58475709809768
              ],
              [
                -73.9198682575901,
                40.584758704244905
              ],
              [
                -73.91988338562933,
                40.58475281382352
              ],
              [
                -73.91988779254024,
                40.584740053995624
              ],
              [
                -73.91991558489718,
                40.584711526425195
              ],
              [
                -73.9199457111396,
                40.584688189774276
              ],
              [
                -73.91998043707673,
                40.584672348684705
              ],
              [
                -73.92000618720687,
                40.58466465286823
              ],
              [
                -73.92005107000836,
                40.58467420901524
              ],
              [
                -73.92009138359737,
                40.58468205984741
              ],
              [
                -73.92012015689424,
                40.5846755198759
              ],
              [
                -73.92014899576304,
                40.58467477183762
              ],
              [
                -73.92017316611688,
                40.58466361680092
              ],
              [
                -73.92018513914945,
                40.58464792511611
              ],
              [
                -73.92019563076693,
                40.58463453793491
              ],
              [
                -73.92021920679475,
                40.58463902814203
              ],
              [
                -73.92024896609914,
                40.584651565118655
              ],
              [
                -73.92028625217345,
                40.584661170223
              ],
              [
                -73.92032272007835,
                40.58466325417151
              ],
              [
                -73.92035159113443,
                40.58466538770473
              ],
              [
                -73.92038424385058,
                40.58466749706092
              ],
              [
                -73.9204266297896,
                40.58465739624063
              ],
              [
                -73.92044708686242,
                40.58465205946206
              ],
              [
                -73.92047746876065,
                40.58465477015312
              ],
              [
                -73.92051853961806,
                40.584661468036145
              ],
              [
                -73.92055119089721,
                40.584663576865765
              ],
              [
                -73.92059058710986,
                40.58465520518997
              ],
              [
                -73.92062166083434,
                40.58465097222066
              ],
              [
                -73.9206673680099,
                40.584665166807305
              ],
              [
                -73.92071070781651,
                40.58467126255376
              ],
              [
                -73.92073119220957,
                40.58467168976598
              ],
              [
                -73.9207736146413,
                40.584661590231725
              ],
              [
                -73.92081748274697,
                40.58464627634087
              ],
              [
                -73.92086664424144,
                40.58463148802433
              ],
              [
                -73.92092557569407,
                40.584604492442864
              ],
              [
                -73.92097161604326,
                40.58457990304708
              ],
              [
                -73.92101255523748,
                40.584575018806134
              ],
              [
                -73.92104987508381,
                40.58458753278874
              ],
              [
                -73.92107282496436,
                40.5846047309178
              ],
              [
                -73.92108374176723,
                40.58462897524493
              ],
              [
                -73.92110968386203,
                40.584641537287716
              ],
              [
                -73.92114999573468,
                40.58464938718229
              ],
              [
                -73.92118797609473,
                40.58465204824699
              ],
              [
                -73.92120843103847,
                40.58464671006229
              ],
              [
                -73.92123411391307,
                40.58463613316943
              ],
              [
                -73.92126153570996,
                40.584646389845624
              ],
              [
                -73.9213147401029,
                40.58465183215768
              ],
              [
                -73.92135650311464,
                40.5846515877502
              ],
              [
                -73.92139293822753,
                40.58465076177563
              ],
              [
                -73.92143088467141,
                40.58465053919734
              ],
              [
                -73.92148629379541,
                40.58464961783133
              ],
              [
                -73.92153193395391,
                40.584657993825424
              ],
              [
                -73.92160322497085,
                40.584652909523356
              ],
              [
                -73.92165711794213,
                40.58465431860371
              ],
              [
                -73.92170654401191,
                40.58465978702819
              ],
              [
                -73.92177263622186,
                40.58466575988431
              ],
              [
                -73.92182955621489,
                40.58466538744681
              ],
              [
                -73.92187674408291,
                40.58467436694931
              ],
              [
                -73.92192310809092,
                40.584681590977866
              ],
              [
                -73.9219678630824,
                40.584676679788
              ],
              [
                -73.92205510173692,
                40.584673226016584
              ],
              [
                -73.92211281118044,
                40.58467461004741
              ],
              [
                -73.92216374696794,
                40.58468065586813
              ],
              [
                -73.92221382879028,
                40.58467917977125
              ],
              [
                -73.92225174191464,
                40.58467604905821
              ],
              [
                -73.92227673549401,
                40.58466953294011
              ],
              [
                -73.92229117085057,
                40.58467058609264
              ],
              [
                -73.92232306860292,
                40.58467096404476
              ],
              [
                -73.92234875056442,
                40.584660387027085
              ],
              [
                -73.92239659516568,
                40.58466242301596
              ],
              [
                -73.92245200364532,
                40.58466147167664
              ],
              [
                -73.92253407944777,
                40.58466907546317
              ],
              [
                -73.92258724882197,
                40.58467451822009
              ],
              [
                -73.92261003898298,
                40.58467436905576
              ],
              [
                -73.92266396482276,
                40.58467866100205
              ],
              [
                -73.92269579781666,
                40.584673274036504
              ],
              [
                -73.92271703950689,
                40.58467254710608
              ],
              [
                -73.92274216376683,
                40.5846776150609
              ],
              [
                -73.92276198979245,
                40.58468789324158
              ],
              [
                -73.92278033793175,
                40.58469762217821
              ],
              [
                -73.92282834680518,
                40.58471409522457
              ],
              [
                -73.92287786521419,
                40.58473114700482
              ],
              [
                -73.9229448471968,
                40.58474752403041
              ],
              [
                -73.92303385793461,
                40.584767784961926
              ],
              [
                -73.92306503049772,
                40.584772225529996
              ],
              [
                -73.9230877558018,
                40.58476628396649
              ],
              [
                -73.92311274452238,
                40.58476267883551
              ],
              [
                -73.92316062204868,
                40.584767568191914
              ],
              [
                -73.9232025479314,
                40.58478178624696
              ],
              [
                -73.92323447490276,
                40.58478795582878
              ],
              [
                -73.92325805473139,
                40.58478953623461
              ],
              [
                -73.92327255614914,
                40.58479638092647
              ],
              [
                -73.92327791316033,
                40.5848027249586
              ],
              [
                -73.92330757405333,
                40.58480658717107
              ],
              [
                -73.92336761870938,
                40.584813160775454
              ],
              [
                -73.92344966307486,
                40.58481785398266
              ],
              [
                -73.92348001315959,
                40.584817654024285
              ],
              [
                -73.92350796271555,
                40.584806474244125
              ],
              [
                -73.92354117972411,
                40.58478717278925
              ],
              [
                -73.9235745230812,
                40.58478233678802
              ],
              [
                -73.92360336279948,
                40.58478155982771
              ],
              [
                -73.9236321999026,
                40.58478081093552
              ],
              [
                -73.92366251887553,
                40.58477772918947
              ],
              [
                -73.92368152483748,
                40.58478051414096
              ],
              [
                -73.92369747506775,
                40.584779234114414
              ],
              [
                -73.92372776000664,
                40.584773271095216
              ],
              [
                -73.92372841881188,
                40.58476341685393
              ],
              [
                -73.92372605222299,
                40.58475534520569
              ],
              [
                -73.92373128195398,
                40.5847471979526
              ],
              [
                -73.92374259286065,
                40.584741330996394
              ],
              [
                -73.92375627067007,
                40.58474356317658
              ],
              [
                -73.92380030285037,
                40.58474271518986
              ],
              [
                -73.92383976587256,
                40.58474015987421
              ],
              [
                -73.92387090509487,
                40.58474169108285
              ],
              [
                -73.92390415048484,
                40.58472818091493
              ],
              [
                -73.92393894021765,
                40.58471810400935
              ],
              [
                -73.92397692219475,
                40.5847178812738
              ],
              [
                -73.92400490615093,
                40.5847096101003
              ],
              [
                -73.92402980071381,
                40.58469439377297
              ],
              [
                -73.9240456500917,
                40.584687349179895
              ],
              [
                -73.92407731884595,
                40.58466747023642
              ],
              [
                -73.92409849487532,
                40.58466097989905
              ],
              [
                -73.92411977008088,
                40.58466316292721
              ],
              [
                -73.92414943223648,
                40.584667024780224
              ],
              [
                -73.92419040422422,
                40.58466502023714
              ],
              [
                -73.92420858699313,
                40.58466316601206
              ],
              [
                -73.92423972762631,
                40.584664723322064
              ],
              [
                -73.9242837915042,
                40.58466675592001
              ],
              [
                -73.92432019498605,
                40.58466304721778
              ],
              [
                -73.92435810855726,
                40.5846599151546
              ],
              [
                -73.9243921770264,
                40.5846510170628
              ],
              [
                -73.92443091389757,
                40.58465252495511
              ],
              [
                -73.92447425376244,
                40.58465861915651
              ],
              [
                -73.92449855479425,
                40.584659020049834
              ],
              [
                -73.92453331122891,
                40.58464608802477
              ],
              [
                -73.92453696487618,
                40.58463159715461
              ],
              [
                -73.92454209517814,
                40.584614774761945
              ],
              [
                -73.92455343880235,
                40.58461181885607
              ],
              [
                -73.92457168715535,
                40.58461575537051
              ],
              [
                -73.92461433600955,
                40.584628794034025
              ],
              [
                -73.92465843183506,
                40.584633707823755
              ],
              [
                -73.92476270182271,
                40.58465968695741
              ],
              [
                -73.92482669484389,
                40.58467779007523
              ],
              [
                -73.92488747621792,
                40.584689483876296
              ],
              [
                -73.92491416077436,
                40.58469461802293
              ],
              [
                -73.92496894248232,
                40.58470642798466
              ],
              [
                -73.92502668581022,
                40.58471069338556
              ],
              [
                -73.9250699258873,
                40.58470808620264
              ],
              [
                -73.92511237793357,
                40.584703776872544
              ],
              [
                -73.9251449348402,
                40.58469430202819
              ],
              [
                -73.92517439669376,
                40.584683698937525
              ],
              [
                -73.9251978787683,
                40.584676604775304
              ],
              [
                -73.92525943415264,
                40.58468375411181
              ],
              [
                -73.92528057806628,
                40.58467435291048
              ],
              [
                -73.92528656418784,
                40.58466505214801
              ],
              [
                -73.92528564357285,
                40.58465176613855
              ],
              [
                -73.92529083990867,
                40.58464073583231
              ],
              [
                -73.92530527578549,
                40.58464178904685
              ],
              [
                -73.92532658357095,
                40.584646879877994
              ],
              [
                -73.92534111881649,
                40.58465660587106
              ],
              [
                -73.92536636945633,
                40.584676138412185
              ],
              [
                -73.92537889694987,
                40.58470963220566
              ],
              [
                -73.92542431690643,
                40.584734620218576
              ],
              [
                -73.9254698561495,
                40.58478284309026
              ],
              [
                -73.92552355379907,
                40.584825074490034
              ],
              [
                -73.92562656290309,
                40.58486435424147
              ],
              [
                -73.92571918722876,
                40.58490337694544
              ],
              [
                -73.92583335908428,
                40.5849530771106
              ],
              [
                -73.92678292699998,
                40.584861437999905
              ],
              [
                -73.92712081899994,
                40.58487935699989
              ],
              [
                -73.92762689299991,
                40.585035055999874
              ],
              [
                -73.92840477199982,
                40.58526206699991
              ],
              [
                -73.92858912,
                40.58520725999994
              ],
              [
                -73.92942869199987,
                40.585488201999915
              ],
              [
                -73.93131301499997,
                40.58605420599989
              ],
              [
                -73.93139947599984,
                40.586079894999905
              ],
              [
                -73.93134323699987,
                40.585805633999875
              ],
              [
                -73.9313279159999,
                40.585737850999884
              ],
              [
                -73.93131673999989,
                40.58567129699988
              ],
              [
                -73.93126944999997,
                40.58543639299992
              ],
              [
                -73.93119181099983,
                40.58541372599994
              ],
              [
                -73.9301907789999,
                40.58508559299986
              ],
              [
                -73.9300961629999,
                40.584502075999886
              ],
              [
                -73.93107641299986,
                40.58437967599986
              ],
              [
                -73.93106383699993,
                40.58431846799987
              ],
              [
                -73.93116473799996,
                40.58431299099991
              ],
              [
                -73.9320424499999,
                40.584233959999914
              ],
              [
                -73.932029438,
                40.58414863699995
              ],
              [
                -73.93186345464171,
                40.58308286112595
              ],
              [
                -73.93177777573123,
                40.58304145392001
              ],
              [
                -73.93178761860817,
                40.58300976000745
              ],
              [
                -73.93180776058966,
                40.58300977196392
              ],
              [
                -73.93182358895909,
                40.58300978135734
              ],
              [
                -73.93183622347397,
                40.582989131466256
              ],
              [
                -73.93184655018902,
                40.582941055297354
              ],
              [
                -73.93182555909537,
                40.58291076876258
              ],
              [
                -73.93174431011353,
                40.58287545908992
              ],
              [
                -73.93171529784688,
                40.5828884275619
              ],
              [
                -73.93170213621669,
                40.58281040746728
              ],
              [
                -73.93094864986666,
                40.582727356689524
              ],
              [
                -73.93090885941557,
                40.58278974134694
              ],
              [
                -73.93082583214515,
                40.58275756895427
              ],
              [
                -73.93080777939004,
                40.582752968781584
              ],
              [
                -73.93074445590659,
                40.58275391486452
              ],
              [
                -73.9307501649442,
                40.58279278313103
              ],
              [
                -73.93071285975189,
                40.58284281760607
              ],
              [
                -73.93072893058803,
                40.58285815808529
              ],
              [
                -73.93076371083933,
                40.582858754345594
              ],
              [
                -73.93083552079771,
                40.582861824016305
              ],
              [
                -73.93084790700136,
                40.58286625944569
              ],
              [
                -73.93085911168123,
                40.58287224903774
              ],
              [
                -73.93086880267725,
                40.582879615232706
              ],
              [
                -73.93087669270234,
                40.582888139661826
              ],
              [
                -73.93088254785809,
                40.58289756962061
              ],
              [
                -73.93088619456917,
                40.58290762556057
              ],
              [
                -73.93088752472832,
                40.58291800937569
              ],
              [
                -73.93088649890265,
                40.58292841324016
              ],
              [
                -73.93088314750095,
                40.582938528733884
              ],
              [
                -73.9308656330301,
                40.58298498948705
              ],
              [
                -73.93084016643408,
                40.58302926053867
              ],
              [
                -73.93080720052055,
                40.58307055470392
              ],
              [
                -73.93074230029701,
                40.58311759770329
              ],
              [
                -73.93067191898056,
                40.58315981186996
              ],
              [
                -73.9305966722979,
                40.58319682789029
              ],
              [
                -73.93051721854356,
                40.58322832192653
              ],
              [
                -73.93043425282119,
                40.583254018449985
              ],
              [
                -73.93034850096225,
                40.58327369265175
              ],
              [
                -73.93017152683852,
                40.583311415229346
              ],
              [
                -73.92999308017578,
                40.58334486355774
              ],
              [
                -73.92981333853065,
                40.58337400435511
              ],
              [
                -73.92963248074891,
                40.58339880862554
              ],
              [
                -73.92946601177597,
                40.58341562819852
              ],
              [
                -73.92947294984545,
                40.583374315505374
              ],
              [
                -73.92944661932395,
                40.583372643646044
              ],
              [
                -73.92943745840644,
                40.583418929257036
              ],
              [
                -73.92823025252056,
                40.583525761222035
              ],
              [
                -73.92712531836796,
                40.583579273883316
              ],
              [
                -73.92630382039857,
                40.58355293393954
              ],
              [
                -73.92626785352488,
                40.583550691936296
              ],
              [
                -73.9259909277576,
                40.583523172411894
              ],
              [
                -73.92564570811074,
                40.58348876245475
              ],
              [
                -73.92563805833993,
                40.5834773939806
              ],
              [
                -73.92563446397422,
                40.583461138616
              ],
              [
                -73.92562049996914,
                40.58345382127171
              ],
              [
                -73.92560365807826,
                40.583455383134215
              ],
              [
                -73.92559744025867,
                40.58346657292388
              ],
              [
                -73.92559188192438,
                40.58349861603501
              ],
              [
                -73.92557372740383,
                40.583513901056314
              ],
              [
                -73.92521875540417,
                40.58350642424622
              ],
              [
                -73.92509444897401,
                40.58351540393565
              ],
              [
                -73.92496324822743,
                40.58352618713359
              ],
              [
                -73.92485051379164,
                40.58353802647261
              ],
              [
                -73.92473925816661,
                40.583547505006955
              ],
              [
                -73.92462570164494,
                40.583554649411376
              ],
              [
                -73.92452046724112,
                40.58355474551058
              ],
              [
                -73.92443604094976,
                40.58355820344425
              ],
              [
                -73.92437765038743,
                40.58355388196904
              ],
              [
                -73.92428618071578,
                40.58354449050496
              ],
              [
                -73.92414240638934,
                40.58353017761237
              ],
              [
                -73.92408408214602,
                40.58353172994967
              ],
              [
                -73.92400495251304,
                40.58352986714735
              ],
              [
                -73.92391812130147,
                40.58352514298099
              ],
              [
                -73.92381898015073,
                40.583518764778304
              ],
              [
                -73.92374136944463,
                40.58351163115182
              ],
              [
                -73.9236306089874,
                40.58349651662117
              ],
              [
                -73.92352834449206,
                40.58348310839798
              ],
              [
                -73.92343223286434,
                40.58347201022718
              ],
              [
                -73.92336456215884,
                40.58346131526237
              ],
              [
                -73.92330531313662,
                40.58345230033658
              ],
              [
                -73.92321384460402,
                40.58344293381905
              ],
              [
                -73.9230930457788,
                40.58342262484577
              ],
              [
                -73.92294226666579,
                40.58339546060754
              ],
              [
                -73.92281136591295,
                40.5833640814189
              ],
              [
                -73.92264745221506,
                40.58333056697821
              ],
              [
                -73.9225235966035,
                40.58330910090226
              ],
              [
                -73.9223789353746,
                40.583284245254845
              ],
              [
                -73.92221271877774,
                40.58325133291647
              ],
              [
                -73.92207260289807,
                40.583219425126714
              ],
              [
                -73.92195641792519,
                40.583194999877364
              ],
              [
                -73.92181939703667,
                40.58316421984647
              ],
              [
                -73.9216538372547,
                40.58312428162971
              ],
              [
                -73.92154835139512,
                40.583095086159446
              ],
              [
                -73.92144124962209,
                40.58306240420429
              ],
              [
                -73.9213218375613,
                40.58302801245795
              ],
              [
                -73.92110154983231,
                40.58296324929816
              ],
              [
                -73.92097048597475,
                40.58291721174891
              ],
              [
                -73.92081803189944,
                40.58287777320654
              ],
              [
                -73.92069085443077,
                40.582834674975466
              ],
              [
                -73.92058072742617,
                40.58280374734509
              ],
              [
                -73.9204837313241,
                40.58278210497244
              ],
              [
                -73.92038597491594,
                40.58276164340871
              ],
              [
                -73.92029134800215,
                40.582745246187834
              ],
              [
                -73.92016136884439,
                40.582727342195156
              ],
              [
                -73.92001983914027,
                40.58270363785088
              ],
              [
                -73.91988900617487,
                40.582678102992794
              ],
              [
                -73.91979661930036,
                40.5826552572084
              ],
              [
                -73.91967029776173,
                40.58261976093146
              ],
              [
                -73.91955473892287,
                40.58258534206253
              ],
              [
                -73.91940452561472,
                40.58253945349346
              ],
              [
                -73.91927583490043,
                40.58249868514514
              ],
              [
                -73.91914800330908,
                40.58246261143317
              ],
              [
                -73.91903244654954,
                40.58242819203943
              ],
              [
                -73.9188552089828,
                40.58237075616669
              ],
              [
                -73.91876881548053,
                40.58233558897786
              ],
              [
                -73.91866398779692,
                40.58229936463159
              ],
              [
                -73.91864198877482,
                40.58229310137835
              ],
              [
                -73.9185654146302,
                40.582271298953046
              ],
              [
                -73.91846993349417,
                40.582247295964045
              ],
              [
                -73.9182225636432,
                40.58216510659864
              ],
              [
                -73.9181401186957,
                40.582138697172795
              ],
              [
                -73.9180700430864,
                40.58212275487273
              ],
              [
                -73.91801544298364,
                40.58211256011891
              ],
              [
                -73.91795316745137,
                40.58210826125213
              ],
              [
                -73.9178718350551,
                40.582112868168814
              ],
              [
                -73.91774511391259,
                40.582110718912936
              ],
              [
                -73.91765594991877,
                40.58210365633107
              ],
              [
                -73.91754282640312,
                40.58208038107559
              ],
              [
                -73.91747275685563,
                40.582061475656175
              ],
              [
                -73.91740031696504,
                40.58203735111879
              ],
              [
                -73.91730082303066,
                40.58199874307117
              ],
              [
                -73.91717068751475,
                40.58196326877144
              ],
              [
                -73.91705904915005,
                40.58193466918452
              ],
              [
                -73.91694046369045,
                40.58190496765121
              ],
              [
                -73.91684419368603,
                40.581879232997046
              ],
              [
                -73.91675250020047,
                40.5818493585736
              ],
              [
                -73.91664609625747,
                40.58180961848239
              ],
              [
                -73.91656440839974,
                40.58178202990354
              ],
              [
                -73.91648646817089,
                40.581761427290445
              ],
              [
                -73.91642536736876,
                40.58175900863264
              ],
              [
                -73.91631145428028,
                40.581736253610735
              ],
              [
                -73.91620858371672,
                40.58172094550985
              ],
              [
                -73.91614001524881,
                40.581709560603244
              ],
              [
                -73.91602025693952,
                40.58169739441901
              ],
              [
                -73.91579767708303,
                40.58165874981451
              ],
              [
                -73.91565428472096,
                40.581632842913194
              ],
              [
                -73.91553381168441,
                40.58161507786608
              ],
              [
                -73.91543478914738,
                40.581603093173285
              ],
              [
                -73.9152602172066,
                40.581578673607275
              ],
              [
                -73.91505520170745,
                40.581543607722544
              ],
              [
                -73.91501420402969,
                40.581539295516976
              ],
              [
                -73.91492725370495,
                40.58154746122127
              ],
              [
                -73.91463184406278,
                40.58166550687895
              ],
              [
                -73.91449886342394,
                40.5817015427681
              ],
              [
                -73.91444081609372,
                40.581712697227594
              ],
              [
                -73.9143867033746,
                40.58171603364235
              ],
              [
                -73.91427450114413,
                40.5817063436689
              ],
              [
                -73.91415185936313,
                40.58169713882522
              ],
              [
                -73.91407954472982,
                40.58168893211236
              ],
              [
                -73.91395583736505,
                40.58166809050036
              ],
              [
                -73.91381856564966,
                40.58164114285245
              ],
              [
                -73.91375946773572,
                40.58162948804214
              ],
              [
                -73.91375780594963,
                40.58162946149775
              ],
              [
                -73.9137112998617,
                40.581628737195174
              ],
              [
                -73.91362394588555,
                40.58164094124915
              ],
              [
                -73.91352729918786,
                40.581657612453704
              ],
              [
                -73.91334451057884,
                40.58169463881008
              ],
              [
                -73.91317650008669,
                40.581719407858145
              ],
              [
                -73.91298249383611,
                40.581745995443704
              ],
              [
                -73.91284574967929,
                40.58175695343282
              ],
              [
                -73.91280910196406,
                40.58175840776044
              ],
              [
                -73.91262397247574,
                40.58176575987498
              ],
              [
                -73.91248781450348,
                40.58176930688096
              ],
              [
                -73.91231554202781,
                40.58177584538587
              ],
              [
                -73.91213518740672,
                40.58176966899819
              ],
              [
                -73.9119719708825,
                40.58176103573953
              ],
              [
                -73.911838086706,
                40.58176021890369
              ],
              [
                -73.91175016920778,
                40.58176215505988
              ],
              [
                -73.91160531805478,
                40.581778101410514
              ],
              [
                -73.91157100347282,
                40.581782987640224
              ],
              [
                -73.91154161171623,
                40.581794027155716
              ],
              [
                -73.91151740121359,
                40.58180655278768
              ],
              [
                -73.91149960130659,
                40.581818781464655
              ],
              [
                -73.91148327601971,
                40.58183216186749
              ],
              [
                -73.91147523338645,
                40.58184362959968
              ],
              [
                -73.91145206083493,
                40.581887830989714
              ],
              [
                -73.91139720194924,
                40.5819927774305
              ],
              [
                -73.91134231551266,
                40.58208888446339
              ],
              [
                -73.91129057746076,
                40.58217390763525
              ],
              [
                -73.91127823803255,
                40.58222132721825
              ],
              [
                -73.91127375383395,
                40.58226309908053
              ],
              [
                -73.91128207390518,
                40.58229642025531
              ],
              [
                -73.91128635983831,
                40.58231358629239
              ],
              [
                -73.91132007841033,
                40.58237915824153
              ],
              [
                -73.91138691369105,
                40.582501187871976
              ],
              [
                -73.91144146900237,
                40.58259998891141
              ],
              [
                -73.91148034599208,
                40.58268472295832
              ],
              [
                -73.91150233164481,
                40.58271957145567
              ],
              [
                -73.91152073412317,
                40.58274297140646
              ],
              [
                -73.91155605037828,
                40.582770886898416
              ],
              [
                -73.91163746024111,
                40.58281695196503
              ],
              [
                -73.91168534279711,
                40.58284350350639
              ],
              [
                -73.91175246333535,
                40.58288670280388
              ],
              [
                -73.9117865670517,
                40.582906464135164
              ],
              [
                -73.91180279364123,
                40.58291306468987
              ],
              [
                -73.91182877719504,
                40.582921264092924
              ],
              [
                -73.91184835219073,
                40.58292863466334
              ],
              [
                -73.91186572974851,
                40.58293691038208
              ],
              [
                -73.91188540427932,
                40.5829507861927
              ],
              [
                -73.91191058860355,
                40.58296337725262
              ],
              [
                -73.91193607084406,
                40.582967843354815
              ],
              [
                -73.91195554655069,
                40.582968709163175
              ],
              [
                -73.91196332932957,
                40.582964213106855
              ],
              [
                -73.91197064789375,
                40.58295598430477
              ],
              [
                -73.91197405738335,
                40.582936746992246
              ],
              [
                -73.91197619502306,
                40.58292703091419
              ],
              [
                -73.9119816384673,
                40.58292039321011
              ],
              [
                -73.911996442554,
                40.582915793300984
              ],
              [
                -73.91204284826748,
                40.58291464859112
              ],
              [
                -73.91213692649706,
                40.58291049296738
              ],
              [
                -73.91223403674643,
                40.58289871079228
              ],
              [
                -73.91226320479217,
                40.58289348989912
              ],
              [
                -73.91228847591118,
                40.582894909070625
              ],
              [
                -73.91230632607945,
                40.58289923256003
              ],
              [
                -73.91232914954863,
                40.582900842010794
              ],
              [
                -73.91235104822732,
                40.5828938583312
              ],
              [
                -73.91237553234416,
                40.58289085829274
              ],
              [
                -73.9124020267364,
                40.58289277241675
              ],
              [
                -73.9124432502069,
                40.58289129372832
              ],
              [
                -73.91248404877419,
                40.582884902401524
              ],
              [
                -73.91249009464558,
                40.5828861696764
              ],
              [
                -73.91249951422785,
                40.58289589339358
              ],
              [
                -73.91249831647532,
                40.582904263595154
              ],
              [
                -73.91248403760483,
                40.582920255471954
              ],
              [
                -73.91245483289673,
                40.58292662866106
              ],
              [
                -73.91241554492984,
                40.5829342286816
              ],
              [
                -73.91236962524634,
                40.58294794566098
              ],
              [
                -73.91232749133063,
                40.58295732817243
              ],
              [
                -73.91224058548934,
                40.58295673980216
              ],
              [
                -73.91222499686906,
                40.58295689257551
              ],
              [
                -73.91222052272708,
                40.5829633395454
              ],
              [
                -73.91223166714833,
                40.58297611129374
              ],
              [
                -73.91225008134586,
                40.582990672782415
              ],
              [
                -73.91227025577561,
                40.58300712903057
              ],
              [
                -73.91234714435846,
                40.583041910547266
              ],
              [
                -73.9123793498798,
                40.58305442343077
              ],
              [
                -73.91240270924914,
                40.58305861339707
              ],
              [
                -73.91244999095223,
                40.583049564646466
              ],
              [
                -73.91247445044137,
                40.58303890545499
              ],
              [
                -73.91249709814748,
                40.583036342583966
              ],
              [
                -73.91250735235212,
                40.583040495575254
              ],
              [
                -73.91250705583342,
                40.5830486200271
              ],
              [
                -73.91250405700487,
                40.5830562477066
              ],
              [
                -73.9124923404176,
                40.58306856292292
              ],
              [
                -73.91250111083939,
                40.58307919315001
              ],
              [
                -73.91251212757238,
                40.5830789539308
              ],
              [
                -73.91253079033142,
                40.58306771501689
              ],
              [
                -73.91258267952516,
                40.5830322357435
              ],
              [
                -73.91264228805895,
                40.582984576891036
              ],
              [
                -73.91266221096731,
                40.58297265154034
              ],
              [
                -73.91269329275185,
                40.58296468735702
              ],
              [
                -73.91272345895841,
                40.58296575431994
              ],
              [
                -73.91275312226192,
                40.58296660025648
              ],
              [
                -73.91276986017411,
                40.58296924753426
              ],
              [
                -73.91279155667922,
                40.58297925496105
              ],
              [
                -73.91280395957409,
                40.58299257727315
              ],
              [
                -73.91281481249887,
                40.58300705117809
              ],
              [
                -73.91282996485275,
                40.583010849344284
              ],
              [
                -73.91285379588716,
                40.583012267298805
              ],
              [
                -73.91287356643893,
                40.58300728673101
              ],
              [
                -73.9128904277056,
                40.58299758347568
              ],
              [
                -73.9128947357864,
                40.58297925047148
              ],
              [
                -73.91288830211467,
                40.5829440856767
              ],
              [
                -73.91288587847073,
                40.58292536426114
              ],
              [
                -73.91288948472226,
                40.582892538586236
              ],
              [
                -73.91288847348098,
                40.58286846600925
              ],
              [
                -73.91289270581302,
                40.58285243999625
              ],
              [
                -73.91290960457906,
                40.582841582823235
              ],
              [
                -73.91293207294984,
                40.58283830622232
              ],
              [
                -73.91294622469934,
                40.58283579227632
              ],
              [
                -73.91295444392058,
                40.582828497233265
              ],
              [
                -73.91295787683184,
                40.58281804214048
              ],
              [
                -73.91295300420718,
                40.582799511027126
              ],
              [
                -73.91294388704215,
                40.582779247458035
              ],
              [
                -73.91293793874237,
                40.582756735028134
              ],
              [
                -73.91294625687493,
                40.58272934875089
              ],
              [
                -73.91296334252092,
                40.5827126469424
              ],
              [
                -73.91298188944978,
                40.58270719718404
              ],
              [
                -73.91299384246965,
                40.582706740169286
              ],
              [
                -73.91301932318423,
                40.582713539213536
              ],
              [
                -73.91304170419419,
                40.58272146053163
              ],
              [
                -73.91305439577195,
                40.58273549604103
              ],
              [
                -73.913048114127,
                40.58274891341534
              ],
              [
                -73.91300682242084,
                40.582774683878206
              ],
              [
                -73.91300493732533,
                40.58278514014456
              ],
              [
                -73.91299537214289,
                40.582804319049316
              ],
              [
                -73.91298036028756,
                40.58283134342265
              ],
              [
                -73.9129720797649,
                40.58285870342857
              ],
              [
                -73.91295907838796,
                40.582889490861454
              ],
              [
                -73.91294501682766,
                40.5829062499101
              ],
              [
                -73.91293062903803,
                40.582924630227694
              ],
              [
                -73.912928919258,
                40.58293813281428
              ],
              [
                -73.91294541913625,
                40.5829566727388
              ],
              [
                -73.91296905167876,
                40.582972802624006
              ],
              [
                -73.91297786818073,
                40.58297459282094
              ],
              [
                -73.91300639684992,
                40.58296374611722
              ],
              [
                -73.91303517141986,
                40.58295591612842
              ],
              [
                -73.9130704563263,
                40.58295311677563
              ],
              [
                -73.91311276013543,
                40.58295139155833
              ],
              [
                -73.91314502174606,
                40.58294856191883
              ],
              [
                -73.9131830787931,
                40.58294510422573
              ],
              [
                -73.91321912488108,
                40.58293906655485
              ],
              [
                -73.91324486679412,
                40.58293771359351
              ],
              [
                -73.91326014818266,
                40.58293860348841
              ],
              [
                -73.91327543023212,
                40.58293949220914
              ],
              [
                -73.91329310293676,
                40.582941948965214
              ],
              [
                -73.91331008978153,
                40.58294649077557
              ],
              [
                -73.9133350692023,
                40.58295073655695
              ],
              [
                -73.91335911446285,
                40.58295402101426
              ],
              [
                -73.91343525105174,
                40.582930582894676
              ],
              [
                -73.913527587149,
                40.5828806142154
              ],
              [
                -73.91361432670014,
                40.5828153530152
              ],
              [
                -73.91364667393216,
                40.582801297650484
              ],
              [
                -73.91367567620946,
                40.58278531669264
              ],
              [
                -73.91369614547608,
                40.58276938477012
              ],
              [
                -73.91370286431712,
                40.58275201466304
              ],
              [
                -73.91370947032047,
                40.582738131429586
              ],
              [
                -73.91372600624901,
                40.58272889387424
              ],
              [
                -73.91376111494455,
                40.58272307378791
              ],
              [
                -73.91379074062979,
                40.5827250716963
              ],
              [
                -73.91381669671352,
                40.5827267649564
              ],
              [
                -73.91385050619411,
                40.58272275603462
              ],
              [
                -73.91388053298886,
                40.582721955640615
              ],
              [
                -73.91393182914146,
                40.58272627546225
              ],
              [
                -73.91402181932011,
                40.58273625220924
              ],
              [
                -73.91405490474298,
                40.58273550833787
              ],
              [
                -73.9140810716333,
                40.58274022091247
              ],
              [
                -73.9141058657216,
                40.58274913275424
              ],
              [
                -73.9141239526859,
                40.582765368490456
              ],
              [
                -73.9141325725104,
                40.582781871226885
              ],
              [
                -73.91413967884692,
                40.5827983453569
              ],
              [
                -73.91415391948266,
                40.5828112571092
              ],
              [
                -73.91417322815995,
                40.582801361662455
              ],
              [
                -73.91418220880657,
                40.58278964750494
              ],
              [
                -73.91418770187882,
                40.582772936352654
              ],
              [
                -73.91418200427732,
                40.58275113835298
              ],
              [
                -73.91416685228447,
                40.58272071574065
              ],
              [
                -73.91416846209651,
                40.58270067941076
              ],
              [
                -73.9141761412379,
                40.58266417862779
              ],
              [
                -73.91421410726333,
                40.58261987951508
              ],
              [
                -73.9142369244519,
                40.58259725192275
              ],
              [
                -73.91425741969694,
                40.582589004832556
              ],
              [
                -73.9142833750802,
                40.582590697986724
              ],
              [
                -73.91429776347408,
                40.58260009640314
              ],
              [
                -73.9143108166107,
                40.582611332458086
              ],
              [
                -73.91432932462531,
                40.58260818966933
              ],
              [
                -73.91434396357897,
                40.58259167631233
              ],
              [
                -73.91435321731488,
                40.582589542854066
              ],
              [
                -73.91436533351332,
                40.58260215110844
              ],
              [
                -73.91436649738984,
                40.58262155658219
              ],
              [
                -73.91437177016182,
                40.58263728885107
              ],
              [
                -73.91438026345521,
                40.58263957274419
              ],
              [
                -73.91439308844032,
                40.582632364627614
              ],
              [
                -73.91440306841196,
                40.58262696421106
              ],
              [
                -73.91444177524455,
                40.582622600250765
              ],
              [
                -73.91445859867211,
                40.58261405068004
              ],
              [
                -73.91446429302799,
                40.58260927781603
              ],
              [
                -73.91445578619143,
                40.58258926242984
              ],
              [
                -73.91444439356765,
                40.58257338957389
              ],
              [
                -73.91443205047094,
                40.582541155245934
              ],
              [
                -73.9144126777686,
                40.58251673964264
              ],
              [
                -73.91439432847737,
                40.582507503306
              ],
              [
                -73.91436373972239,
                40.582498010403285
              ],
              [
                -73.9143418953653,
                40.582491516926424
              ],
              [
                -73.91433402510343,
                40.58248061477258
              ],
              [
                -73.91432797974475,
                40.58245154222201
              ],
              [
                -73.9143386047637,
                40.582418612504824
              ],
              [
                -73.91435652574022,
                40.582396338075576
              ],
              [
                -73.91438667636007,
                40.58238315804942
              ],
              [
                -73.91442069856869,
                40.58238222378729
              ],
              [
                -73.91456038746365,
                40.58240650895869
              ],
              [
                -73.9146294466244,
                40.58242750365832
              ],
              [
                -73.9146614460973,
                40.582431644635726
              ],
              [
                -73.91469476968891,
                40.58244163202668
              ],
              [
                -73.91477047211993,
                40.58247536683081
              ],
              [
                -73.91481200341404,
                40.582486926341616
              ],
              [
                -73.91485429620485,
                40.58249409310831
              ],
              [
                -73.91489145347465,
                40.582490855991736
              ],
              [
                -73.91492361437683,
                40.582481465155716
              ],
              [
                -73.91495621705263,
                40.58246266266324
              ],
              [
                -73.91499231015695,
                40.582430576817316
              ],
              [
                -73.91503244299057,
                40.58239799219668
              ],
              [
                -73.91507188576279,
                40.58238152573492
              ],
              [
                -73.91510347182249,
                40.582370707574924
              ],
              [
                -73.9151453494571,
                40.58236406916207
              ],
              [
                -73.91518549237695,
                40.58236324792052
              ],
              [
                -73.91526029387258,
                40.58236942346995
              ],
              [
                -73.91529755299925,
                40.58237156499927
              ],
              [
                -73.91533636981164,
                40.582364868305405
              ],
              [
                -73.91537680116978,
                40.58236358114242
              ],
              [
                -73.91541290900807,
                40.58236407379874
              ],
              [
                -73.91543278029661,
                40.58236565348724
              ],
              [
                -73.91545552568117,
                40.582371927285905
              ],
              [
                -73.9154627460607,
                40.58238376269418
              ],
              [
                -73.91546395768319,
                40.58239312446376
              ],
              [
                -73.91547157698861,
                40.58240331284745
              ],
              [
                -73.91548489271247,
                40.58240755008249
              ],
              [
                -73.91549292208342,
                40.58240607268248
              ],
              [
                -73.91550413660777,
                40.58239112289203
              ],
              [
                -73.91551693880298,
                40.5823750466268
              ],
              [
                -73.91552366963936,
                40.58237540841372
              ],
              [
                -73.91553029051371,
                40.582378104308006
              ],
              [
                -73.91554158161824,
                40.58238859660441
              ],
              [
                -73.91555618378293,
                40.582399861634094
              ],
              [
                -73.91557828001777,
                40.58240706924327
              ],
              [
                -73.9156141628191,
                40.58241456205834
              ],
              [
                -73.91566729548903,
                40.58241844319197
              ],
              [
                -73.91570859960468,
                40.582410403773984
              ],
              [
                -73.915718940778,
                40.58240335669329
              ],
              [
                -73.91573004424852,
                40.582391919557736
              ],
              [
                -73.91574397996054,
                40.582387536987355
              ],
              [
                -73.9157652891067,
                40.5823914518614
              ],
              [
                -73.9157805779702,
                40.58240062961325
              ],
              [
                -73.91580422300518,
                40.582406713521394
              ],
              [
                -73.91582308234135,
                40.582410816567666
              ],
              [
                -73.9158442416109,
                40.58241824357294
              ],
              [
                -73.91586237611384,
                40.58242561290706
              ],
              [
                -73.915875254895,
                40.582433801295096
              ],
              [
                -73.9158787393108,
                40.58243877310615
              ],
              [
                -73.91588532189255,
                40.58244380029227
              ],
              [
                -73.91589601535239,
                40.58244402775174
              ],
              [
                -73.91589979876528,
                40.58244084712578
              ],
              [
                -73.91590736628613,
                40.5824344567238
              ],
              [
                -73.91592914771844,
                40.58243326544658
              ],
              [
                -73.91594473563659,
                40.582434264437964
              ],
              [
                -73.91596151080888,
                40.582435758340175
              ],
              [
                -73.91597323763864,
                40.58244229956228
              ],
              [
                -73.91598506756877,
                40.582454221235714
              ],
              [
                -73.91598930325056,
                40.5824647912558
              ],
              [
                -73.91599507564251,
                40.58248313074189
              ],
              [
                -73.91599673914028,
                40.582506271801925
              ],
              [
                -73.91600076262644,
                40.58251382295231
              ],
              [
                -73.91601234094581,
                40.58252384934536
              ],
              [
                -73.9160421323442,
                40.58253891208453
              ],
              [
                -73.91605533428852,
                40.5825466620962
              ],
              [
                -73.91605982993855,
                40.582550233591405
              ],
              [
                -73.91605924441413,
                40.58255769961802
              ],
              [
                -73.91604510737572,
                40.58257679368181
              ],
              [
                -73.91603150828996,
                40.58259846708606
              ],
              [
                -73.91602327833968,
                40.58261580824715
              ],
              [
                -73.91602653797953,
                40.582627777428684
              ],
              [
                -73.91603398469314,
                40.58263376652197
              ],
              [
                -73.91604312676826,
                40.582635119302275
              ],
              [
                -73.91604831118616,
                40.58263545077821
              ],
              [
                -73.91606105132732,
                40.58263826034202
              ],
              [
                -73.91606608507493,
                40.58264325958138
              ],
              [
                -73.91608276193867,
                40.58266602729392
              ],
              [
                -73.91609776307327,
                40.58267449279288
              ],
              [
                -73.91611252487061,
                40.582673377103596
              ],
              [
                -73.91612707682087,
                40.58266806280264
              ],
              [
                -73.91614359890356,
                40.58266887196805
              ],
              [
                -73.9161613368347,
                40.58267785930009
              ],
              [
                -73.9162099122431,
                40.5826982068487
              ],
              [
                -73.91624727128278,
                40.58270687911213
              ],
              [
                -73.9162610483292,
                40.58271602989832
              ],
              [
                -73.9162755368557,
                40.582730807511425
              ],
              [
                -73.91628476885714,
                40.582747556968165
              ],
              [
                -73.91629316588654,
                40.58276990632649
              ],
              [
                -73.91629061804558,
                40.582791313398594
              ],
              [
                -73.91629330489032,
                40.5828018839539
              ],
              [
                -73.91631125527731,
                40.582813918108805
              ],
              [
                -73.916326405681,
                40.58281886992297
              ],
              [
                -73.91633310071417,
                40.582819260116366
              ],
              [
                -73.9163509359136,
                40.58280818348835
              ],
              [
                -73.9163755408857,
                40.582802731561685
              ],
              [
                -73.91639106239693,
                40.58280858159557
              ],
              [
                -73.9164107855549,
                40.582810660077364
              ],
              [
                -73.9164297950498,
                40.58281113226658
              ],
              [
                -73.91648384235516,
                40.58278464746009
              ],
              [
                -73.9165602576571,
                40.582731786446736
              ],
              [
                -73.91659049396182,
                40.58272577750722
              ],
              [
                -73.91663555189481,
                40.582726696272644
              ],
              [
                -73.91671303114217,
                40.582734479091386
              ],
              [
                -73.91676942718226,
                40.58274770268724
              ],
              [
                -73.91681104385314,
                40.58276204855713
              ],
              [
                -73.91683298733993,
                40.58278236834692
              ],
              [
                -73.91683095894794,
                40.58279632912118
              ],
              [
                -73.91680996859863,
                40.582817869441286
              ],
              [
                -73.91680237542735,
                40.5828431210236
              ],
              [
                -73.91681802428016,
                40.582870439325305
              ],
              [
                -73.91684561145445,
                40.58289288546195
              ],
              [
                -73.91687676635951,
                40.58292337089746
              ],
              [
                -73.91708382946541,
                40.58305897765284
              ],
              [
                -73.91729861410717,
                40.58319080174952
              ],
              [
                -73.91731074592352,
                40.58321813118889
              ],
              [
                -73.91730410990102,
                40.58322701133876
              ],
              [
                -73.91729747607816,
                40.5832358881392
              ],
              [
                -73.9172715063787,
                40.58324885900864
              ],
              [
                -73.91722154944054,
                40.58325278928729
              ],
              [
                -73.91704549903487,
                40.58324266348338
              ],
              [
                -73.91700811433819,
                40.58322991161618
              ],
              [
                -73.91697421629549,
                40.58321178020428
              ],
              [
                -73.91694732728378,
                40.58318825923233
              ],
              [
                -73.91692804679539,
                40.58314216882286
              ],
              [
                -73.91691664570561,
                40.58311912946354
              ],
              [
                -73.91690459603636,
                40.58310521664011
              ],
              [
                -73.91688414354172,
                40.583098847118
              ],
              [
                -73.91686865501184,
                40.58309836381438
              ],
              [
                -73.91684198716314,
                40.583112409956115
              ],
              [
                -73.91680904215018,
                40.58313613910652
              ],
              [
                -73.91674435706146,
                40.58314924314891
              ],
              [
                -73.91660432992357,
                40.58315938686152
              ],
              [
                -73.91656345737127,
                40.58315201369969
              ],
              [
                -73.91652537447123,
                40.58314033821525
              ],
              [
                -73.91647381467018,
                40.58311153408881
              ],
              [
                -73.91640110933903,
                40.58307572857577
              ],
              [
                -73.9163582813012,
                40.58306600817028
              ],
              [
                -73.91632545582766,
                40.58305857276467
              ],
              [
                -73.91628745922038,
                40.58304193956394
              ],
              [
                -73.91625458407773,
                40.5830191062994
              ],
              [
                -73.91619291399608,
                40.58296092642285
              ],
              [
                -73.91616533434859,
                40.582934911924504
              ],
              [
                -73.91616117295023,
                40.582922009955205
              ],
              [
                -73.91616340652098,
                40.582893327856574
              ],
              [
                -73.91616526389136,
                40.58287515862867
              ],
              [
                -73.91615620879065,
                40.58286260825072
              ],
              [
                -73.91614199473273,
                40.582857437633876
              ],
              [
                -73.91612226132845,
                40.5828612385458
              ],
              [
                -73.91607986037056,
                40.58288302829907
              ],
              [
                -73.91604528931647,
                40.58289142893822
              ],
              [
                -73.91601352716064,
                40.582898049776674
              ],
              [
                -73.9159559503259,
                40.582907147915336
              ],
              [
                -73.91590083079711,
                40.582908369582256
              ],
              [
                -73.91579646420027,
                40.58290675605841
              ],
              [
                -73.91577896476002,
                40.58290849994628
              ],
              [
                -73.9157616045728,
                40.58291447087012
              ],
              [
                -73.91575888276965,
                40.58293167834109
              ],
              [
                -73.9157501285275,
                40.582936393405475
              ],
              [
                -73.91573006889206,
                40.58294184032313
              ],
              [
                -73.91569870775079,
                40.58294563313594
              ],
              [
                -73.91565304415474,
                40.58295545303636
              ],
              [
                -73.91560713091408,
                40.58296458737211
              ],
              [
                -73.91556719011433,
                40.58297732193168
              ],
              [
                -73.91554499756505,
                40.58299017825141
              ],
              [
                -73.9155269382117,
                40.58300825263117
              ],
              [
                -73.9155053193231,
                40.58302253721877
              ],
              [
                -73.91547650540166,
                40.58303267233263
              ],
              [
                -73.91545449816903,
                40.58303970913199
              ],
              [
                -73.91542990602406,
                40.58304271142218
              ],
              [
                -73.91539584617905,
                40.58304482787611
              ],
              [
                -73.91535364351932,
                40.58305308548065
              ],
              [
                -73.91528333907931,
                40.583075953372386
              ],
              [
                -73.91525733442549,
                40.58308729219161
              ],
              [
                -73.9152135907857,
                40.5830951512281
              ],
              [
                -73.91516678218403,
                40.583103942296944
              ],
              [
                -73.91514044992658,
                40.58310451791601
              ],
              [
                -73.9151053823759,
                40.583108503838766
              ],
              [
                -73.91508577200474,
                40.583117917738996
              ],
              [
                -73.91507218234854,
                40.58312458692769
              ],
              [
                -73.91506390193432,
                40.58312520820454
              ],
              [
                -73.91504738947053,
                40.58312522296865
              ],
              [
                -73.91502932438901,
                40.58313056686786
              ],
              [
                -73.91500434950726,
                40.58315145121622
              ],
              [
                -73.91499355016039,
                40.583153361271734
              ],
              [
                -73.91498685680594,
                40.5831528403285
              ],
              [
                -73.91498253486131,
                40.58314919202402
              ],
              [
                -73.91496680213771,
                40.58313340379472
              ],
              [
                -73.91495087200076,
                40.58312114358798
              ],
              [
                -73.91492120779233,
                40.583112480957304
              ],
              [
                -73.91489759501914,
                40.58310526003698
              ],
              [
                -73.91487113272144,
                40.583094422049186
              ],
              [
                -73.91486183617289,
                40.58307425013897
              ],
              [
                -73.91487128569776,
                40.5830478456147
              ],
              [
                -73.91487974591004,
                40.583027004010866
              ],
              [
                -73.91487850168284,
                40.58300552279337
              ],
              [
                -73.91485751324602,
                40.582982099519164
              ],
              [
                -73.91485126450586,
                40.58296204629953
              ],
              [
                -73.91485961850306,
                40.58293816395804
              ],
              [
                -73.91488463973496,
                40.58291191856394
              ],
              [
                -73.91490200810954,
                40.582894927353856
              ],
              [
                -73.91490695367519,
                40.582885095863055
              ],
              [
                -73.91489741970577,
                40.58287261036763
              ],
              [
                -73.91488143663862,
                40.582869871315225
              ],
              [
                -73.9148566460506,
                40.58287050731671
              ],
              [
                -73.91482516810426,
                40.58286646059711
              ],
              [
                -73.91481396714835,
                40.5828604269273
              ],
              [
                -73.91481966904604,
                40.58284853136759
              ],
              [
                -73.91482435715882,
                40.582833823240975
              ],
              [
                -73.91482092346082,
                40.58281738588487
              ],
              [
                -73.91481206768587,
                40.582803984666235
              ],
              [
                -73.91478662998793,
                40.58278758426655
              ],
              [
                -73.91475316846913,
                40.58277671090445
              ],
              [
                -73.91472075055118,
                40.582772835846555
              ],
              [
                -73.91463166275301,
                40.582757634905384
              ],
              [
                -73.91460961616629,
                40.582758846513336
              ],
              [
                -73.91459109911116,
                40.58276577165373
              ],
              [
                -73.91457136618544,
                40.58277756781226
              ],
              [
                -73.91454056069132,
                40.58279893188524
              ],
              [
                -73.91450871967749,
                40.58280905411824
              ],
              [
                -73.91448137951106,
                40.58280678954632
              ],
              [
                -73.91444562078021,
                40.58279792088553
              ],
              [
                -73.9144260182388,
                40.582798993218404
              ],
              [
                -73.91441354718485,
                40.582811541939826
              ],
              [
                -73.91441656474707,
                40.58282958359571
              ],
              [
                -73.91443075792112,
                40.58284528564937
              ],
              [
                -73.91442531787224,
                40.58285787143888
              ],
              [
                -73.91440852626427,
                40.582866745532776
              ],
              [
                -73.91438024403833,
                40.58286468177313
              ],
              [
                -73.91436272478424,
                40.582857695301314
              ],
              [
                -73.91434813864615,
                40.58285200266575
              ],
              [
                -73.91433869075239,
                40.582856266618926
              ],
              [
                -73.9143203815262,
                40.58287345673762
              ],
              [
                -73.91431347825309,
                40.58288483538588
              ],
              [
                -73.91430480864925,
                40.58289543755721
              ],
              [
                -73.9142875703119,
                40.58290173067609
              ],
              [
                -73.91426461163289,
                40.58290733047502
              ],
              [
                -73.91424209153641,
                40.58292388518252
              ],
              [
                -73.91421440940195,
                40.58294421934995
              ],
              [
                -73.91418696590387,
                40.58295684146895
              ],
              [
                -73.91415019662927,
                40.58296724421899
              ],
              [
                -73.91412574717617,
                40.582967421957065
              ],
              [
                -73.91408049248979,
                40.58296818137606
              ],
              [
                -73.91404880615279,
                40.58297597979547
              ],
              [
                -73.91401448220044,
                40.58298626974122
              ],
              [
                -73.91398276542384,
                40.58298988055261
              ],
              [
                -73.913933638425,
                40.58298954623003
              ],
              [
                -73.91388783020813,
                40.58298468270128
              ],
              [
                -73.91384198140268,
                40.58298096820855
              ],
              [
                -73.91379817883397,
                40.582991336421415
              ],
              [
                -73.91370574416129,
                40.583020700121565
              ],
              [
                -73.91367040800638,
                40.58302812126698
              ],
              [
                -73.91362890961973,
                40.583032296241164
              ],
              [
                -73.91359114656434,
                40.58303030925953
              ],
              [
                -73.91356029877663,
                40.583030709235075
              ],
              [
                -73.91351194436805,
                40.583040871371054
              ],
              [
                -73.9134426509044,
                40.583066501794434
              ],
              [
                -73.91334530004634,
                40.583087744026905
              ],
              [
                -73.91320747763811,
                40.58310644953779
              ],
              [
                -73.91315375727191,
                40.583110124915834
              ],
              [
                -73.91311537518962,
                40.58311743000316
              ],
              [
                -73.91309259176921,
                40.583133266757365
              ],
              [
                -73.91308233206294,
                40.58314917329266
              ],
              [
                -73.91307702388137,
                40.58317317373953
              ],
              [
                -73.91305439371871,
                40.58318671645332
              ],
              [
                -73.91302765232814,
                40.583184710654315
              ],
              [
                -73.91301010544076,
                40.583173509583744
              ],
              [
                -73.91300400920699,
                40.583151161727585
              ],
              [
                -73.91301314865379,
                40.58312940288127
              ],
              [
                -73.91303116125995,
                40.583111495720246
              ],
              [
                -73.91309366069213,
                40.583078117863344
              ],
              [
                -73.91310803072021,
                40.58305564660949
              ],
              [
                -73.91310764522147,
                40.5830393284431
              ],
              [
                -73.9131006349199,
                40.58302555523264
              ],
              [
                -73.9130834248184,
                40.58301392236904
              ],
              [
                -73.91306274695064,
                40.58300796478858
              ],
              [
                -73.91304660950463,
                40.583007548495594
              ],
              [
                -73.91296715647701,
                40.5830341705914
              ],
              [
                -73.91286173083516,
                40.58307045982732
              ],
              [
                -73.91281681585251,
                40.58309287336547
              ],
              [
                -73.91275025844384,
                40.58314540498739
              ],
              [
                -73.91270404052639,
                40.58320432501546
              ],
              [
                -73.91267748287284,
                40.58322632474245
              ],
              [
                -73.91264957500252,
                40.583245914027785
              ],
              [
                -73.91262124306265,
                40.583271353280885
              ],
              [
                -73.9126052251269,
                40.583290724427194
              ],
              [
                -73.91257072971156,
                40.583312858480944
              ],
              [
                -73.9124951143902,
                40.58333753547241
              ],
              [
                -73.91246022035992,
                40.58335175442641
              ],
              [
                -73.91243878847268,
                40.58336997401473
              ],
              [
                -73.91242103069457,
                40.58339278402727
              ],
              [
                -73.9124066060869,
                40.58342480550646
              ],
              [
                -73.9124007270715,
                40.583452761192795
              ],
              [
                -73.91240864017935,
                40.583488475465195
              ],
              [
                -73.9124242085302,
                40.583514934039755
              ],
              [
                -73.9124518134371,
                40.583540028377854
              ],
              [
                -73.91248355319789,
                40.58356693082129
              ],
              [
                -73.91252584404202,
                40.58362708449973
              ],
              [
                -73.91254323349644,
                40.58364476824473
              ],
              [
                -73.91256416724003,
                40.58365563053855
              ],
              [
                -73.91258263821426,
                40.58365404217411
              ],
              [
                -73.91259559746719,
                40.58364292740566
              ],
              [
                -73.91261090987317,
                40.58362022959436
              ],
              [
                -73.91262686025286,
                40.58361073325424
              ],
              [
                -73.91263067653858,
                40.583608461273506
              ],
              [
                -73.91265111155091,
                40.5836041503621
              ],
              [
                -73.91266228121614,
                40.58360599681931
              ],
              [
                -73.91267910298274,
                40.58362345009425
              ],
              [
                -73.9126928262588,
                40.58365030745778
              ],
              [
                -73.91270485854531,
                40.583675242138966
              ],
              [
                -73.91271079739748,
                40.58369994166923
              ],
              [
                -73.91270280580808,
                40.58375387975506
              ],
              [
                -73.91270605956096,
                40.58383062848673
              ],
              [
                -73.91269902546921,
                40.58385270453265
              ],
              [
                -73.91269014904348,
                40.583875153606506
              ],
              [
                -73.9126727844487,
                40.58388795641479
              ],
              [
                -73.91265005310576,
                40.583894272084706
              ],
              [
                -73.9126066432168,
                40.583894630422925
              ],
              [
                -73.91256813712123,
                40.58388633321259
              ],
              [
                -73.91253467541472,
                40.58387545803292
              ],
              [
                -73.91251327125534,
                40.58387575300659
              ],
              [
                -73.9125046003567,
                40.583886356213235
              ],
              [
                -73.91250423830841,
                40.58390055205037
              ],
              [
                -73.9125210309118,
                40.58391378927906
              ],
              [
                -73.91253889560856,
                40.58391613103659
              ],
              [
                -73.91258990362563,
                40.583916094632926
              ],
              [
                -73.91261666905709,
                40.58392647501517
              ],
              [
                -73.91263581397888,
                40.583950298528386
              ],
              [
                -73.91263830734084,
                40.58396693224811
              ],
              [
                -73.91261266604457,
                40.58402458326315
              ],
              [
                -73.91259318315109,
                40.58406758280877
              ],
              [
                -73.9125855857062,
                40.58408940042328
              ],
              [
                -73.91258466852842,
                40.58412011407671
              ],
              [
                -73.91259425542349,
                40.584145189643074
              ],
              [
                -73.9126020924363,
                40.58415993903613
              ],
              [
                -73.91261674746673,
                40.58416867210214
              ],
              [
                -73.9126341968073,
                40.58417261862884
              ],
              [
                -73.91263982584111,
                40.58417985163811
              ],
              [
                -73.91263170246216,
                40.584200234970815
              ],
              [
                -73.91261808094076,
                40.58422485647048
              ],
              [
                -73.91261758823649,
                40.58424974980263
              ],
              [
                -73.91261720032453,
                40.58427768337179
              ],
              [
                -73.9126084771919,
                40.58431994827597
              ],
              [
                -73.91257873642861,
                40.58437882582973
              ],
              [
                -73.91252051530321,
                40.58446125242105
              ],
              [
                -73.91250885205581,
                40.584484327928415
              ],
              [
                -73.91248473796232,
                40.58450618455644
              ],
              [
                -73.9124193285938,
                40.58450239244319
              ],
              [
                -73.91239232328932,
                40.58449966926964
              ],
              [
                -73.91237486072286,
                40.58450524607659
              ],
              [
                -73.91236790288794,
                40.58452617424718
              ],
              [
                -73.91236972324565,
                40.58456165217832
              ],
              [
                -73.91237790672497,
                40.58459386763484
              ],
              [
                -73.91238199348389,
                40.584623153394475
              ],
              [
                -73.91239412142289,
                40.58463736260747
              ],
              [
                -73.9124137811953,
                40.584644694110274
              ],
              [
                -73.91244299981304,
                40.584650746770585
              ],
              [
                -73.91247773546301,
                40.58466517741909
              ],
              [
                -73.91249831364493,
                40.584686019518024
              ],
              [
                -73.91250598875574,
                40.584707306965946
              ],
              [
                -73.91250278714065,
                40.5847316239427
              ],
              [
                -73.9125006491395,
                40.58474179788662
              ],
              [
                -73.9124734047356,
                40.58489829622764
              ],
              [
                -73.9124594364618,
                40.58494263288399
              ],
              [
                -73.91245221064955,
                40.58498789654785
              ],
              [
                -73.91245182213171,
                40.58503349325462
              ],
              [
                -73.91245827602079,
                40.58507882466821
              ],
              [
                -73.91249053068118,
                40.58511412044127
              ],
              [
                -73.9125158367049,
                40.585152568724624
              ],
              [
                -73.91253367438031,
                40.585193379922245
              ],
              [
                -73.91254367736796,
                40.58523571591028
              ],
              [
                -73.91254564022543,
                40.585278707249124
              ],
              [
                -73.9125395226262,
                40.5853214710392
              ],
              [
                -73.91256608953849,
                40.58560928558327
              ],
              [
                -73.91266190734372,
                40.58568909761718
              ],
              [
                -73.91268773524789,
                40.58571702455057
              ],
              [
                -73.91271619323912,
                40.58574342393866
              ],
              [
                -73.91275274022804,
                40.585792655597565
              ],
              [
                -73.91278142692737,
                40.58584818440604
              ],
              [
                -73.91282142952436,
                40.585896830337056
              ],
              [
                -73.91286589107678,
                40.58594317173063
              ],
              [
                -73.91291458410106,
                40.58598697147525
              ],
              [
                -73.91297578641507,
                40.58601722826302
              ],
              [
                -73.91302501082505,
                40.586029908561194
              ],
              [
                -73.91307177986289,
                40.58604716501876
              ],
              [
                -73.91311536491438,
                40.5860687287984
              ],
              [
                -73.9131550869676,
                40.58609426395945
              ],
              [
                -73.91326113648611,
                40.58610888327647
              ],
              [
                -73.91331747182582,
                40.5861284356148
              ],
              [
                -73.913376322177,
                40.58614306155436
              ],
              [
                -73.91343694901617,
                40.58615257755136
              ],
              [
                -73.91349859152439,
                40.58615686418726
              ],
              [
                -73.91356047613637,
                40.586155867668325
              ],
              [
                -73.91356785981522,
                40.58615615501117
              ],
              [
                -73.91357521234436,
                40.58615556394073
              ],
              [
                -73.9135823550201,
                40.58615410882269
              ],
              [
                -73.91358911423973,
                40.586151825024054
              ],
              [
                -73.91359532572005,
                40.58614876805248
              ],
              [
                -73.9136008384908,
                40.58614501220783
              ],
              [
                -73.91360551856437,
                40.58614064877597
              ],
              [
                -73.91360925219124,
                40.58613578381029
              ],
              [
                -73.91361194862614,
                40.58613053555383
              ],
              [
                -73.91360599652994,
                40.586126227483696
              ],
              [
                -73.91360105528585,
                40.586121231520224
              ],
              [
                -73.91359725957363,
                40.58611568383497
              ],
              [
                -73.91359471284989,
                40.58610973563731
              ],
              [
                -73.91359348452896,
                40.58610354905293
              ],
              [
                -73.91359360808936,
                40.58609729270503
              ],
              [
                -73.91359508016339,
                40.586091137118316
              ],
              [
                -73.91359786062695,
                40.58608525007121
              ],
              [
                -73.91360187369521,
                40.586079792022346
              ],
              [
                -73.91364541255663,
                40.586060011234764
              ],
              [
                -73.91369275676598,
                40.586046217506315
              ],
              [
                -73.9137425028251,
                40.58603881974524
              ],
              [
                -73.91379317603624,
                40.58603803725476
              ],
              [
                -73.91384327421697,
                40.586043893231235
              ],
              [
                -73.91389131223131,
                40.58605621407723
              ],
              [
                -73.9139358660144,
                40.58607463454718
              ],
              [
                -73.91397561478601,
                40.58609860857474
              ],
              [
                -73.91399595873644,
                40.58610581031467
              ],
              [
                -73.91401569268363,
                40.58610413320441
              ],
              [
                -73.91403188386349,
                40.58609924139808
              ],
              [
                -73.91405550969763,
                40.58609535597158
              ],
              [
                -73.91408557111487,
                40.586098882499265
              ],
              [
                -73.91410914905579,
                40.586106091495616
              ],
              [
                -73.91416592220669,
                40.58614025732272
              ],
              [
                -73.91419138861141,
                40.58615930551065
              ],
              [
                -73.91421300745357,
                40.58617069991829
              ],
              [
                -73.91422561352081,
                40.58617245733183
              ],
              [
                -73.91424534967837,
                40.58617078118889
              ],
              [
                -73.9142566771619,
                40.586168342875034
              ],
              [
                -73.91427510080042,
                40.58617110092185
              ],
              [
                -73.91430221625379,
                40.58618275690621
              ],
              [
                -73.9144106863387,
                40.586228145088704
              ],
              [
                -73.91444848600902,
                40.586237115793516
              ],
              [
                -73.91447306751336,
                40.58623668343948
              ],
              [
                -73.91448795703413,
                40.58623376141626
              ],
              [
                -73.91450223072464,
                40.586223195234766
              ],
              [
                -73.91451002779564,
                40.58621507803657
              ],
              [
                -73.91451879447324,
                40.58620671730121
              ],
              [
                -73.91453176360426,
                40.58619935163311
              ],
              [
                -73.91455182297848,
                40.586197182806316
              ],
              [
                -73.91457444535509,
                40.58620093754456
              ],
              [
                -73.91465937411907,
                40.58623048801493
              ],
              [
                -73.91468133993774,
                40.58623645999916
              ],
              [
                -73.91469360490532,
                40.58624240835949
              ],
              [
                -73.91470909811237,
                40.586249595817065
              ],
              [
                -73.91472719418067,
                40.58625284708998
              ],
              [
                -73.91473689701499,
                40.58625287070833
              ],
              [
                -73.91475822751109,
                40.586256129234776
              ],
              [
                -73.9147763034473,
                40.5862643112114
              ],
              [
                -73.91479664555939,
                40.5862715106301
              ],
              [
                -73.91482249731176,
                40.58627650659439
              ],
              [
                -73.91483964457827,
                40.58627506833222
              ],
              [
                -73.9148490627048,
                40.58626572331664
              ],
              [
                -73.91486137370094,
                40.58626057605091
              ],
              [
                -73.91488111429749,
                40.5862576662828
              ],
              [
                -73.9149089072476,
                40.586262172972006
              ],
              [
                -73.91498196714757,
                40.586269257594864
              ],
              [
                -73.91505538740107,
                40.586267220962505
              ],
              [
                -73.91509711064722,
                40.58626658369322
              ],
              [
                -73.91513331918173,
                40.58626938540072
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000868261018498,
        "objectid": 155,
        "shape_leng": 0.169808473032,
        "location_id": 155,
        "zone": "Marine Park/Mill Basin",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.90464209220775,
                40.62513319188153
              ],
              [
                -73.90826357306823,
                40.62678363831664
              ],
              [
                -73.9091803329726,
                40.62720140975427
              ],
              [
                -73.9147993677344,
                40.62974079537697
              ],
              [
                -73.91535460301414,
                40.62999169507066
              ],
              [
                -73.91731998946489,
                40.63100715271805
              ],
              [
                -73.91751823209556,
                40.631340259516676
              ],
              [
                -73.91751676263425,
                40.63139132831636
              ],
              [
                -73.9175065733389,
                40.63144181607985
              ],
              [
                -73.91748783785057,
                40.63149086232209
              ],
              [
                -73.91746087546879,
                40.63153763112486
              ],
              [
                -73.91742614571034,
                40.6315813253835
              ],
              [
                -73.917384240479,
                40.63162120039297
              ],
              [
                -73.91733587397775,
                40.63165657654043
              ],
              [
                -73.91728187053637,
                40.63168685088907
              ],
              [
                -73.91722315056269,
                40.631711507453836
              ],
              [
                -73.91716071492723,
                40.631730125998445
              ],
              [
                -73.91860649599991,
                40.63209041499988
              ],
              [
                -73.91835491799992,
                40.62979529799986
              ],
              [
                -73.91824254499994,
                40.62877007499988
              ],
              [
                -73.91816260199982,
                40.62800449499985
              ],
              [
                -73.91799464599991,
                40.62645772299992
              ],
              [
                -73.91775240199999,
                40.624164716999864
              ],
              [
                -73.91770927100005,
                40.6237176859999
              ],
              [
                -73.91751556199989,
                40.621940315999936
              ],
              [
                -73.91746991599989,
                40.62151242899988
              ],
              [
                -73.91741279799999,
                40.62095853099987
              ],
              [
                -73.91726440899994,
                40.619548037999955
              ],
              [
                -73.91717137699983,
                40.618711251999905
              ],
              [
                -73.91716779499997,
                40.61838796199991
              ],
              [
                -73.91711554499987,
                40.61792519099986
              ],
              [
                -73.9170081,
                40.6172019239999
              ],
              [
                -73.91697651699994,
                40.6169322129999
              ],
              [
                -73.91690302699992,
                40.61627422899993
              ],
              [
                -73.91719587499983,
                40.616091715999886
              ],
              [
                -73.91739073199989,
                40.6159663059999
              ],
              [
                -73.91788753399999,
                40.61564657999988
              ],
              [
                -73.91862838199995,
                40.615168606999916
              ],
              [
                -73.91893348499988,
                40.61496777999992
              ],
              [
                -73.91937088699989,
                40.61469022299991
              ],
              [
                -73.91960797099988,
                40.61453446499993
              ],
              [
                -73.920107,
                40.614212128999895
              ],
              [
                -73.9205269409999,
                40.61394269499988
              ],
              [
                -73.92062400299996,
                40.61483868199991
              ],
              [
                -73.92159370099995,
                40.614778183999874
              ],
              [
                -73.92252930300005,
                40.61471903299993
              ],
              [
                -73.92349786399998,
                40.61465815799991
              ],
              [
                -73.92446651399993,
                40.614599417999955
              ],
              [
                -73.9253954579999,
                40.6145405629999
              ],
              [
                -73.92640044599995,
                40.61447853499988
              ],
              [
                -73.9263159899999,
                40.61343967599986
              ],
              [
                -73.92629769899993,
                40.61319215499987
              ],
              [
                -73.92629604599998,
                40.613116798999926
              ],
              [
                -73.92739580799999,
                40.6140526909999
              ],
              [
                -73.92769203599991,
                40.61431264099985
              ],
              [
                -73.92776130099995,
                40.61437342499989
              ],
              [
                -73.92808935399992,
                40.61466788499994
              ],
              [
                -73.9284298539999,
                40.61497277999991
              ],
              [
                -73.92946094800006,
                40.61589681999993
              ],
              [
                -73.92989884499987,
                40.61629427199993
              ],
              [
                -73.93060244599984,
                40.616922262999914
              ],
              [
                -73.93173179199982,
                40.61792692099988
              ],
              [
                -73.93248727399993,
                40.61743170199987
              ],
              [
                -73.93320457599995,
                40.6169704419999
              ],
              [
                -73.93501858699992,
                40.61860011099992
              ],
              [
                -73.93686699599992,
                40.62025328099991
              ],
              [
                -73.93696272700001,
                40.620199140999944
              ],
              [
                -73.93757616399986,
                40.61979552899988
              ],
              [
                -73.93781351899986,
                40.6196520959999
              ],
              [
                -73.93828802399997,
                40.61932901899992
              ],
              [
                -73.93864732399993,
                40.61909724599993
              ],
              [
                -73.93900236399998,
                40.61886727099993
              ],
              [
                -73.93949341999998,
                40.61855079099992
              ],
              [
                -73.9397167159999,
                40.61840609599988
              ],
              [
                -73.94037010899983,
                40.617992385999884
              ],
              [
                -73.94045274699987,
                40.617929049999894
              ],
              [
                -73.94078914099985,
                40.61769678999986
              ],
              [
                -73.941282159,
                40.61762926999994
              ],
              [
                -73.94138899899984,
                40.6176174189999
              ],
              [
                -73.94221251499997,
                40.61752605499987
              ],
              [
                -73.94314510099991,
                40.61742561699991
              ],
              [
                -73.94325340699987,
                40.61741206399994
              ],
              [
                -73.943469619,
                40.61738500099992
              ],
              [
                -73.94395779499983,
                40.6168163089999
              ],
              [
                -73.94467599499988,
                40.61616511299997
              ],
              [
                -73.9448277099998,
                40.61604620899986
              ],
              [
                -73.94479547299984,
                40.61588853099991
              ],
              [
                -73.94457366500001,
                40.61480377199996
              ],
              [
                -73.94430888199999,
                40.613411737999925
              ],
              [
                -73.94417450600004,
                40.612722537999936
              ],
              [
                -73.94405560899996,
                40.61199284199993
              ],
              [
                -73.94380031999991,
                40.610719118999924
              ],
              [
                -73.94369953899981,
                40.61021403899987
              ],
              [
                -73.94364091199994,
                40.6098588169999
              ],
              [
                -73.94354698,
                40.60934655499992
              ],
              [
                -73.94231175899985,
                40.608268196999866
              ],
              [
                -73.94187229999983,
                40.60855546699988
              ],
              [
                -73.9415475769999,
                40.608767735999905
              ],
              [
                -73.94082897899993,
                40.609229317999926
              ],
              [
                -73.94011528799989,
                40.60969107799988
              ],
              [
                -73.93934703399997,
                40.610187140999884
              ],
              [
                -73.93753749399987,
                40.60855738999986
              ],
              [
                -73.93709375999993,
                40.608841982999934
              ],
              [
                -73.93676116199995,
                40.609055286999926
              ],
              [
                -73.936046277,
                40.609514868999966
              ],
              [
                -73.93572378299984,
                40.60922748599994
              ],
              [
                -73.93531145199985,
                40.608860039999875
              ],
              [
                -73.93423157599979,
                40.607897714999915
              ],
              [
                -73.93351310700004,
                40.60834345599989
              ],
              [
                -73.93201257899985,
                40.60699707199991
              ],
              [
                -73.93169716599986,
                40.6067140589999
              ],
              [
                -73.92986880199989,
                40.60507134099994
              ],
              [
                -73.9295009169999,
                40.60530869199986
              ],
              [
                -73.92915810699985,
                40.60552985699994
              ],
              [
                -73.92844217799984,
                40.60600119699996
              ],
              [
                -73.92769136199998,
                40.606480506999915
              ],
              [
                -73.92696222699988,
                40.606961374999855
              ],
              [
                -73.92624885099994,
                40.6074135219999
              ],
              [
                -73.92444144399987,
                40.60579356899994
              ],
              [
                -73.92373054999989,
                40.60625280699985
              ],
              [
                -73.9230107439999,
                40.60671540299994
              ],
              [
                -73.92230145599989,
                40.60717863099994
              ],
              [
                -73.92158429099997,
                40.60763908499989
              ],
              [
                -73.92065861699989,
                40.60675454299997
              ],
              [
                -73.91984929899995,
                40.60722139799986
              ],
              [
                -73.91970928699986,
                40.607302162999886
              ],
              [
                -73.91852760899995,
                40.606113366999864
              ],
              [
                -73.91847519099994,
                40.60614556799992
              ],
              [
                -73.91794980550603,
                40.60620925217101
              ],
              [
                -73.91858342114791,
                40.60677183459648
              ],
              [
                -73.91858079216594,
                40.60679365825723
              ],
              [
                -73.91868471829673,
                40.606966356992835
              ],
              [
                -73.9188771952235,
                40.607113323711154
              ],
              [
                -73.91905150176778,
                40.60721662408656
              ],
              [
                -73.91924135011546,
                40.60738541386062
              ],
              [
                -73.91952209795994,
                40.60773879743479
              ],
              [
                -73.91950459282141,
                40.607922077734756
              ],
              [
                -73.91950358918922,
                40.60797490397523
              ],
              [
                -73.91947494229278,
                40.60798083621683
              ],
              [
                -73.9194228518384,
                40.60799865797485
              ],
              [
                -73.91937100429925,
                40.60803264124188
              ],
              [
                -73.91840159359653,
                40.60715485289804
              ],
              [
                -73.91851674502753,
                40.60708466836772
              ],
              [
                -73.918503004139,
                40.607071236027004
              ],
              [
                -73.91825269092949,
                40.607229350640516
              ],
              [
                -73.91827215643065,
                40.60724314341611
              ],
              [
                -73.91837826940144,
                40.607174021935705
              ],
              [
                -73.91935398484114,
                40.60804292228766
              ],
              [
                -73.91902310765325,
                40.60825686819944
              ],
              [
                -73.91830794532584,
                40.6076105077084
              ],
              [
                -73.91841039790253,
                40.607544731667545
              ],
              [
                -73.91836246737634,
                40.607501369418664
              ],
              [
                -73.9181381488997,
                40.60764538690772
              ],
              [
                -73.91814759534256,
                40.607653933765455
              ],
              [
                -73.91824893434108,
                40.607588872927295
              ],
              [
                -73.91900737147333,
                40.60826725497114
              ],
              [
                -73.91894160444555,
                40.60828346764001
              ],
              [
                -73.91893141978508,
                40.60828295005247
              ],
              [
                -73.91892124499323,
                40.608283570215306
              ],
              [
                -73.91890956709285,
                40.60828574105839
              ],
              [
                -73.91889855118033,
                40.6082894069123
              ],
              [
                -73.91888852404472,
                40.60829445902878
              ],
              [
                -73.91887978314314,
                40.608300747535836
              ],
              [
                -73.91857357570947,
                40.60848794628732
              ],
              [
                -73.91812303859335,
                40.60808069425832
              ],
              [
                -73.91818728590098,
                40.60802458091069
              ],
              [
                -73.91800500501185,
                40.60814977851463
              ],
              [
                -73.91809925398383,
                40.60809335825846
              ],
              [
                -73.9185566612059,
                40.60849830904344
              ],
              [
                -73.91806301173895,
                40.60882486519667
              ],
              [
                -73.91805049767602,
                40.60883084266264
              ],
              [
                -73.91803929189071,
                40.60883817440284
              ],
              [
                -73.91802964454176,
                40.608846696743484
              ],
              [
                -73.91802177099734,
                40.608856219431914
              ],
              [
                -73.9180158470274,
                40.60886652988326
              ],
              [
                -73.91787400308746,
                40.60931132766885
              ],
              [
                -73.9178197277143,
                40.6093043000659
              ],
              [
                -73.91798455297189,
                40.60879177334503
              ],
              [
                -73.91796270083073,
                40.60878593712031
              ],
              [
                -73.91767870757266,
                40.60966286494891
              ],
              [
                -73.91770422310164,
                40.609667630156096
              ],
              [
                -73.91781719473106,
                40.60931749853929
              ],
              [
                -73.9178663862696,
                40.60932723380082
              ],
              [
                -73.91772902651729,
                40.60975703538167
              ],
              [
                -73.91772932254797,
                40.60976985881372
              ],
              [
                -73.91773185485035,
                40.60978253819983
              ],
              [
                -73.91773657839126,
                40.609794848048324
              ],
              [
                -73.91774340916776,
                40.60980656943985
              ],
              [
                -73.91810261499006,
                40.61012428359595
              ],
              [
                -73.91811036679019,
                40.61020839405809
              ],
              [
                -73.91805286362053,
                40.61027534308027
              ],
              [
                -73.9168292081243,
                40.611118077722935
              ],
              [
                -73.91678083391083,
                40.61107389428803
              ],
              [
                -73.91678827905612,
                40.611065400092905
              ],
              [
                -73.91669171257122,
                40.61099592085719
              ],
              [
                -73.9166731127402,
                40.61100582325055
              ],
              [
                -73.91671582048875,
                40.611039850701495
              ],
              [
                -73.91665069880038,
                40.61109363057433
              ],
              [
                -73.91662098248936,
                40.61107519473402
              ],
              [
                -73.9165893648604,
                40.611090754004245
              ],
              [
                -73.91667108215461,
                40.61114322406103
              ],
              [
                -73.9166915481743,
                40.611127656686364
              ],
              [
                -73.916746523893,
                40.611178455468114
              ],
              [
                -73.91573181927129,
                40.61183084451381
              ],
              [
                -73.91662804371141,
                40.61344358926541
              ],
              [
                -73.9164991210398,
                40.613520542098016
              ],
              [
                -73.91646978847766,
                40.61349045050586
              ],
              [
                -73.91650568236642,
                40.61346912199881
              ],
              [
                -73.91645866206082,
                40.61342467104202
              ],
              [
                -73.91641827930147,
                40.61345026708692
              ],
              [
                -73.9160031884947,
                40.61285973859123
              ],
              [
                -73.91587086536354,
                40.61291003739054
              ],
              [
                -73.91588093410674,
                40.61292456583794
              ],
              [
                -73.91599307131547,
                40.612881939344255
              ],
              [
                -73.91639586745879,
                40.613446834034704
              ],
              [
                -73.91633753617162,
                40.61348352081669
              ],
              [
                -73.91638679683788,
                40.61352968074032
              ],
              [
                -73.91626452766656,
                40.613605612704106
              ],
              [
                -73.91588941550151,
                40.6133097996056
              ],
              [
                -73.91598251878865,
                40.61325178355086
              ],
              [
                -73.9159657250026,
                40.613236396468864
              ],
              [
                -73.91574585230263,
                40.61338486070303
              ],
              [
                -73.91576488361795,
                40.61340281130991
              ],
              [
                -73.91588490852118,
                40.613328587196975
              ],
              [
                -73.91624768959588,
                40.61362610079118
              ],
              [
                -73.91597173175394,
                40.613807835366686
              ],
              [
                -73.91575004260955,
                40.61361719582282
              ],
              [
                -73.91576807356562,
                40.61354033333579
              ],
              [
                -73.91572538303375,
                40.613618030803636
              ],
              [
                -73.9159560237015,
                40.613819782939224
              ],
              [
                -73.9159145211068,
                40.61384537631227
              ],
              [
                -73.91592907529404,
                40.6138590549774
              ],
              [
                -73.91614556120368,
                40.61373023354987
              ],
              [
                -73.91640428251645,
                40.61355234521992
              ],
              [
                -73.91642099003494,
                40.61356935469273
              ],
              [
                -73.91596393246677,
                40.61389020969622
              ],
              [
                -73.91580224199757,
                40.6140037143864
              ],
              [
                -73.91526818192825,
                40.614350947429806
              ],
              [
                -73.91508764077801,
                40.61446832874461
              ],
              [
                -73.91477780531795,
                40.6146772210089
              ],
              [
                -73.91475621277912,
                40.61467683057097
              ],
              [
                -73.91466875774388,
                40.61467525055097
              ],
              [
                -73.91461912939545,
                40.61472243073407
              ],
              [
                -73.91460363331858,
                40.61477281863788
              ],
              [
                -73.9146017069913,
                40.61477907860198
              ],
              [
                -73.91457541233427,
                40.61480636505006
              ],
              [
                -73.91454638326994,
                40.61483199121626
              ],
              [
                -73.91450091243084,
                40.61486515769846
              ],
              [
                -73.914450878778,
                40.6148942602153
              ],
              [
                -73.91439690440212,
                40.61491893691919
              ],
              [
                -73.91433966039311,
                40.6149388809906
              ],
              [
                -73.91428072663338,
                40.61493881616294
              ],
              [
                -73.91422238665953,
                40.614932456704416
              ],
              [
                -73.9141657924689,
                40.61491992819078
              ],
              [
                -73.9141120615841,
                40.61490147801327
              ],
              [
                -73.91422619595673,
                40.614803352896544
              ],
              [
                -73.91418409377795,
                40.61477876758933
              ],
              [
                -73.91416424991556,
                40.6147919744944
              ],
              [
                -73.91418158175665,
                40.61480520748114
              ],
              [
                -73.91407985868283,
                40.61488823339903
              ],
              [
                -73.91386053060673,
                40.61464226907087
              ],
              [
                -73.91415762351875,
                40.61445296931085
              ],
              [
                -73.91487851298005,
                40.613993630319015
              ],
              [
                -73.91493418790425,
                40.61395815505491
              ],
              [
                -73.91486977791232,
                40.61349308259039
              ],
              [
                -73.91485570360723,
                40.613500333207455
              ],
              [
                -73.91479289781104,
                40.613532687277505
              ],
              [
                -73.91476336459147,
                40.613370238318836
              ],
              [
                -73.91482568233826,
                40.6133252111722
              ],
              [
                -73.91486259053775,
                40.613298542099834
              ],
              [
                -73.91474395639995,
                40.61270828753798
              ],
              [
                -73.91458057649909,
                40.61189538467203
              ],
              [
                -73.91449542205973,
                40.61165322366488
              ],
              [
                -73.91455938763573,
                40.611570851641716
              ],
              [
                -73.91464933054556,
                40.61145502607295
              ],
              [
                -73.91481827328002,
                40.611342077848214
              ],
              [
                -73.91489001105649,
                40.61139315949259
              ],
              [
                -73.91499950855342,
                40.61133572880869
              ],
              [
                -73.91492035932123,
                40.611266657338064
              ],
              [
                -73.91554577672987,
                40.61092847019064
              ],
              [
                -73.9156289810894,
                40.61088347754488
              ],
              [
                -73.9161647503772,
                40.61051557507611
              ],
              [
                -73.91606077132579,
                40.61043050936295
              ],
              [
                -73.91607693139578,
                40.610411746853806
              ],
              [
                -73.91613568922111,
                40.6103381533554
              ],
              [
                -73.9161880144276,
                40.61026390742237
              ],
              [
                -73.9162350895359,
                40.61018764764728
              ],
              [
                -73.91627678158125,
                40.61010958945703
              ],
              [
                -73.91638585145203,
                40.61008889290487
              ],
              [
                -73.91656604065834,
                40.60943782267781
              ],
              [
                -73.9166637973001,
                40.60945728558763
              ],
              [
                -73.91664099809648,
                40.60954543188989
              ],
              [
                -73.91668075282955,
                40.609551335120976
              ],
              [
                -73.91670379289528,
                40.60947271234203
              ],
              [
                -73.91679953090683,
                40.609494192231004
              ],
              [
                -73.91681036958981,
                40.609466915624324
              ],
              [
                -73.91665676533457,
                40.60943674997084
              ],
              [
                -73.91681964529575,
                40.60893138688516
              ],
              [
                -73.91702822741907,
                40.608974877413445
              ],
              [
                -73.91708838503304,
                40.60882085489853
              ],
              [
                -73.91705165775807,
                40.60881413108985
              ],
              [
                -73.91699867098345,
                40.608936730311996
              ],
              [
                -73.91682803243748,
                40.60890605736671
              ],
              [
                -73.9168739501378,
                40.60876294889291
              ],
              [
                -73.9168553679984,
                40.608760932307376
              ],
              [
                -73.91688964587311,
                40.60871001368692
              ],
              [
                -73.91650924535926,
                40.60862975409001
              ],
              [
                -73.91648700927323,
                40.608699208722385
              ],
              [
                -73.91683981118769,
                40.608760014720524
              ],
              [
                -73.91662568373555,
                40.60943683665816
              ],
              [
                -73.91657073983414,
                40.60942353954593
              ],
              [
                -73.9167839443274,
                40.60877388439221
              ],
              [
                -73.91668775150282,
                40.608753417541635
              ],
              [
                -73.91632230017511,
                40.608675662668986
              ],
              [
                -73.91664171593675,
                40.60783414643551
              ],
              [
                -73.91672115759047,
                40.60762484678013
              ],
              [
                -73.91666955358512,
                40.607319499676706
              ],
              [
                -73.91661465264755,
                40.60720525980096
              ],
              [
                -73.91670644426729,
                40.60716337556577
              ],
              [
                -73.91660442147958,
                40.607089393444696
              ],
              [
                -73.91671583604739,
                40.60698858703718
              ],
              [
                -73.91669121427903,
                40.606938548523104
              ],
              [
                -73.91675554034506,
                40.60690644261104
              ],
              [
                -73.91669736890354,
                40.606835969556194
              ],
              [
                -73.91673556486195,
                40.60681456155016
              ],
              [
                -73.9163482853367,
                40.60646059724837
              ],
              [
                -73.91626594416164,
                40.60644063365889
              ],
              [
                -73.9159512486574,
                40.6060997417252
              ],
              [
                -73.91591887691341,
                40.60606219940043
              ],
              [
                -73.91567006907964,
                40.60583883549569
              ],
              [
                -73.91509616292997,
                40.60532360703459
              ],
              [
                -73.91504389099256,
                40.60535572210597
              ],
              [
                -73.91500401101551,
                40.60532198810563
              ],
              [
                -73.91491345354056,
                40.60524538625552
              ],
              [
                -73.91497779074928,
                40.60520409454706
              ],
              [
                -73.91384142172905,
                40.604157684687685
              ],
              [
                -73.91377776447158,
                40.60420645695332
              ],
              [
                -73.91362533833528,
                40.60401648707572
              ],
              [
                -73.91365119759595,
                40.60398251383695
              ],
              [
                -73.91352500916965,
                40.60392913916154
              ],
              [
                -73.91329003999543,
                40.60387843583746
              ],
              [
                -73.91306509093998,
                40.60384611337231
              ],
              [
                -73.91247254015315,
                40.603751902419724
              ],
              [
                -73.91092270021187,
                40.60350547610063
              ],
              [
                -73.90913452972943,
                40.60394410684935
              ],
              [
                -73.90912526593853,
                40.60392710704199
              ],
              [
                -73.90893375673267,
                40.603976528676505
              ],
              [
                -73.90889944956521,
                40.60391367252357
              ],
              [
                -73.9083253683517,
                40.604073373918595
              ],
              [
                -73.9083096617489,
                40.604128950077566
              ],
              [
                -73.90845796643069,
                40.60446109885738
              ],
              [
                -73.90854436867018,
                40.60444585598162
              ],
              [
                -73.90857840990381,
                40.6045254997872
              ],
              [
                -73.9086135794197,
                40.60451654785957
              ],
              [
                -73.90874285904927,
                40.6047292670212
              ],
              [
                -73.90872476424393,
                40.604739969994476
              ],
              [
                -73.90885691315668,
                40.60506007379715
              ],
              [
                -73.908945318632,
                40.60504942652808
              ],
              [
                -73.90898535789192,
                40.605150509642996
              ],
              [
                -73.90915233587795,
                40.60529384716793
              ],
              [
                -73.90959070936644,
                40.605665197167404
              ],
              [
                -73.90960943418337,
                40.605657796050735
              ],
              [
                -73.90977661382514,
                40.60582267723143
              ],
              [
                -73.9098515484547,
                40.60599972217769
              ],
              [
                -73.90985478785177,
                40.60602719798734
              ],
              [
                -73.90980883736027,
                40.6060808600487
              ],
              [
                -73.90984528326653,
                40.60616830550711
              ],
              [
                -73.90933804983145,
                40.60647760302468
              ],
              [
                -73.90927254334011,
                40.606452575925076
              ],
              [
                -73.9092344962553,
                40.606483646661
              ],
              [
                -73.90918728471067,
                40.606493718409816
              ],
              [
                -73.90913322764857,
                40.60648368458372
              ],
              [
                -73.90908737385216,
                40.606466165654
              ],
              [
                -73.90906279646923,
                40.60646614621083
              ],
              [
                -73.90888574422165,
                40.60653651882834
              ],
              [
                -73.90885497326904,
                40.606494719034416
              ],
              [
                -73.90887338346825,
                40.6064900126619
              ],
              [
                -73.90887097975077,
                40.606482818228415
              ],
              [
                -73.90892527516411,
                40.60646650338679
              ],
              [
                -73.90892100027692,
                40.606458594970306
              ],
              [
                -73.90886670981311,
                40.60647128783418
              ],
              [
                -73.9088430576895,
                40.6064641320021
              ],
              [
                -73.90882788621846,
                40.606445812721105
              ],
              [
                -73.90882146047558,
                40.606434852056864
              ],
              [
                -73.90881635624245,
                40.60642614587491
              ],
              [
                -73.90877708012741,
                40.6063591466173
              ],
              [
                -73.90876710091456,
                40.60633468212509
              ],
              [
                -73.90879343156898,
                40.60632722552804
              ],
              [
                -73.90878902084852,
                40.606317557607746
              ],
              [
                -73.90877458860328,
                40.60628592168103
              ],
              [
                -73.90875297445298,
                40.60623854035454
              ],
              [
                -73.9087358277961,
                40.60620095263826
              ],
              [
                -73.90873265005877,
                40.60619398492398
              ],
              [
                -73.9087169598315,
                40.60615837135179
              ],
              [
                -73.90873438126897,
                40.60615315961841
              ],
              [
                -73.90872998669624,
                40.606145361501085
              ],
              [
                -73.90871078443426,
                40.60611128875226
              ],
              [
                -73.90870624207278,
                40.606103228668594
              ],
              [
                -73.90868421931644,
                40.60610910367546
              ],
              [
                -73.90866141097756,
                40.606064510574505
              ],
              [
                -73.90867801158534,
                40.60605996797876
              ],
              [
                -73.9086732940981,
                40.60605186704511
              ],
              [
                -73.90867297919127,
                40.6060513250056
              ],
              [
                -73.90862096434266,
                40.605961988291895
              ],
              [
                -73.90860117540839,
                40.60591900217281
              ],
              [
                -73.90859589280906,
                40.605907529095056
              ],
              [
                -73.90849314617617,
                40.60568434142088
              ],
              [
                -73.90851327699045,
                40.605679690252465
              ],
              [
                -73.908490614308,
                40.60562652359302
              ],
              [
                -73.90846767150063,
                40.60563292872581
              ],
              [
                -73.90844916641329,
                40.605588204983995
              ],
              [
                -73.90844522073026,
                40.60557866641764
              ],
              [
                -73.90843296851578,
                40.605549054593745
              ],
              [
                -73.90842905394325,
                40.60553959412006
              ],
              [
                -73.90840357563164,
                40.60554762376038
              ],
              [
                -73.90838831344149,
                40.60551588275037
              ],
              [
                -73.90841392944104,
                40.60550778185575
              ],
              [
                -73.90840933442644,
                40.60549810171106
              ],
              [
                -73.90839498062358,
                40.605467869020416
              ],
              [
                -73.90838993959262,
                40.605457249686026
              ],
              [
                -73.90837834785442,
                40.60543283312589
              ],
              [
                -73.90837359285274,
                40.60542281578357
              ],
              [
                -73.9083539845863,
                40.605381514101424
              ],
              [
                -73.9083302893999,
                40.60533160184377
              ],
              [
                -73.90831206762074,
                40.605337601102406
              ],
              [
                -73.90829586897208,
                40.60530550148999
              ],
              [
                -73.90841800626492,
                40.60526772238175
              ],
              [
                -73.90840361574526,
                40.605243297059886
              ],
              [
                -73.90803306097814,
                40.605358602117214
              ],
              [
                -73.90804594062307,
                40.60538064820042
              ],
              [
                -73.908241104175,
                40.605322778618266
              ],
              [
                -73.90825589550573,
                40.60535701746725
              ],
              [
                -73.90825600624365,
                40.60536712777165
              ],
              [
                -73.90825771968117,
                40.60536662571439
              ],
              [
                -73.90827578546283,
                40.60540122518409
              ],
              [
                -73.90826141716495,
                40.6054055954409
              ],
              [
                -73.90826584843533,
                40.60541396571817
              ],
              [
                -73.90828019855472,
                40.60544106881038
              ],
              [
                -73.90830727294615,
                40.60549220187811
              ],
              [
                -73.90833426588041,
                40.60548438371615
              ],
              [
                -73.90835197687807,
                40.605489421369015
              ],
              [
                -73.90836357914405,
                40.605510812028825
              ],
              [
                -73.90836540085215,
                40.605522232434794
              ],
              [
                -73.90838206875904,
                40.605553618736536
              ],
              [
                -73.90835983309901,
                40.60556113982057
              ],
              [
                -73.90836406109749,
                40.605569008684576
              ],
              [
                -73.9083830686712,
                40.60560439493423
              ],
              [
                -73.90838757402335,
                40.605612781683384
              ],
              [
                -73.90839062290351,
                40.60561845715274
              ],
              [
                -73.90840420569957,
                40.605613883543526
              ],
              [
                -73.90841899874358,
                40.60564634053193
              ],
              [
                -73.9083936515068,
                40.60565380921938
              ],
              [
                -73.90839936710282,
                40.60566205855359
              ],
              [
                -73.90841705422126,
                40.605687586163086
              ],
              [
                -73.90842412709902,
                40.605697793652894
              ],
              [
                -73.90844212792871,
                40.60569268997907
              ],
              [
                -73.90845649277887,
                40.605722290919644
              ],
              [
                -73.90843408138487,
                40.60572873265535
              ],
              [
                -73.90846664297462,
                40.60577949344965
              ],
              [
                -73.9084923668496,
                40.605772138128174
              ],
              [
                -73.90853378311802,
                40.605876801749574
              ],
              [
                -73.90851058586273,
                40.60588372150549
              ],
              [
                -73.9085146951497,
                40.60589194928403
              ],
              [
                -73.90853062658859,
                40.605923850463896
              ],
              [
                -73.90855248523931,
                40.605967620435536
              ],
              [
                -73.90856864929573,
                40.60599998735828
              ],
              [
                -73.90857234429065,
                40.60600738587102
              ],
              [
                -73.90859415171684,
                40.606001271315165
              ],
              [
                -73.90861508773968,
                40.60604515043975
              ],
              [
                -73.90865739211544,
                40.606132193503576
              ],
              [
                -73.90868276737514,
                40.60618759326796
              ],
              [
                -73.90870825751658,
                40.606243242903496
              ],
              [
                -73.90874058936066,
                40.60631382849622
              ],
              [
                -73.90877243515098,
                40.606384339645565
              ],
              [
                -73.90880856612377,
                40.606457353049976
              ],
              [
                -73.90878731055211,
                40.60646279780028
              ],
              [
                -73.90881136337046,
                40.60652146330248
              ],
              [
                -73.90881822284258,
                40.606538193846674
              ],
              [
                -73.90882919865997,
                40.606564966000455
              ],
              [
                -73.90874599099338,
                40.606608616355295
              ],
              [
                -73.90870529107143,
                40.60664693764753
              ],
              [
                -73.90863959535422,
                40.606658722047854
              ],
              [
                -73.90861472394567,
                40.60666083776884
              ],
              [
                -73.90856971576302,
                40.606653284607326
              ],
              [
                -73.90848093177033,
                40.60663539956951
              ],
              [
                -73.90844815360893,
                40.606616746739654
              ],
              [
                -73.90841450766717,
                40.60652757551175
              ],
              [
                -73.90837376514945,
                40.606396627742306
              ],
              [
                -73.90828607999653,
                40.606402870303384
              ],
              [
                -73.90821683308761,
                40.60637297909434
              ],
              [
                -73.90818770904363,
                40.60635433731642
              ],
              [
                -73.90811001167562,
                40.60627326534302
              ],
              [
                -73.90797041860135,
                40.606306949975966
              ],
              [
                -73.90782843032022,
                40.60633420615307
              ],
              [
                -73.90768455599682,
                40.606354936134224
              ],
              [
                -73.9075393115617,
                40.60636906558195
              ],
              [
                -73.90739321785887,
                40.60637654382807
              ],
              [
                -73.90724679877958,
                40.60637734405589
              ],
              [
                -73.90710057938108,
                40.606371463395725
              ],
              [
                -73.90695508400545,
                40.60635892293556
              ],
              [
                -73.90623422405241,
                40.60635398000769
              ],
              [
                -73.90597461177855,
                40.606384028704326
              ],
              [
                -73.90588481979447,
                40.60629761103489
              ],
              [
                -73.90575205936467,
                40.606116504275796
              ],
              [
                -73.90570001056875,
                40.60600553334022
              ],
              [
                -73.90560690377077,
                40.60589785864346
              ],
              [
                -73.90542440003202,
                40.6055402975893
              ],
              [
                -73.90533534473163,
                40.60532427015961
              ],
              [
                -73.90539181422342,
                40.60522680882993
              ],
              [
                -73.90528793023383,
                40.60493479702361
              ],
              [
                -73.90524300992108,
                40.60491009032442
              ],
              [
                -73.90520530979795,
                40.60490818290941
              ],
              [
                -73.90470757613124,
                40.605031778563855
              ],
              [
                -73.90381289962677,
                40.60525393333968
              ],
              [
                -73.90291909122419,
                40.60547586392896
              ],
              [
                -73.90189710069492,
                40.60572961179577
              ],
              [
                -73.90187582791519,
                40.605705858274185
              ],
              [
                -73.90187376201806,
                40.60570355147678
              ],
              [
                -73.90185037412324,
                40.60571038411894
              ],
              [
                -73.90181267270403,
                40.60566786468221
              ],
              [
                -73.90177669809985,
                40.60567468655584
              ],
              [
                -73.90164211790079,
                40.60576336073891
              ],
              [
                -73.90164808286106,
                40.605797243755
              ],
              [
                -73.90165858100026,
                40.60585692192891
              ],
              [
                -73.90170081037174,
                40.60587222129953
              ],
              [
                -73.90171150437247,
                40.606096766404924
              ],
              [
                -73.90168632809845,
                40.606095133744354
              ],
              [
                -73.90167739606466,
                40.60609454913335
              ],
              [
                -73.90169872618107,
                40.60622136984966
              ],
              [
                -73.90170495677201,
                40.60625836846018
              ],
              [
                -73.90171996150777,
                40.606347533429954
              ],
              [
                -73.90186893018571,
                40.607233090025055
              ],
              [
                -73.90189745276427,
                40.60723317753214
              ],
              [
                -73.90190752124978,
                40.60734516610802
              ],
              [
                -73.90187641024093,
                40.607345418990526
              ],
              [
                -73.9018877939877,
                40.607510421771124
              ],
              [
                -73.90191177585922,
                40.60778705575984
              ],
              [
                -73.90191354153009,
                40.60780748124328
              ],
              [
                -73.90187803412165,
                40.60780778415157
              ],
              [
                -73.90191623151601,
                40.60805967499218
              ],
              [
                -73.90194746000888,
                40.608059729112
              ],
              [
                -73.90197086408783,
                40.60827985434309
              ],
              [
                -73.90197307401525,
                40.60828757345611
              ],
              [
                -73.90197885878756,
                40.60829054114181
              ],
              [
                -73.9019968182827,
                40.60829973286323
              ],
              [
                -73.90200130936587,
                40.608307085021536
              ],
              [
                -73.90200434649164,
                40.6083141928554
              ],
              [
                -73.90201440809034,
                40.60835068139818
              ],
              [
                -73.90199063921322,
                40.608378928150536
              ],
              [
                -73.90196196584279,
                40.60839931125431
              ],
              [
                -73.90196164219753,
                40.608619700032655
              ],
              [
                -73.90210336163577,
                40.609037916486464
              ],
              [
                -73.90214599092712,
                40.60904276556271
              ],
              [
                -73.90207490818688,
                40.60954900355572
              ],
              [
                -73.90220516294403,
                40.60955308455875
              ],
              [
                -73.90227449074713,
                40.610246077790606
              ],
              [
                -73.90222497639522,
                40.61025596362205
              ],
              [
                -73.9022144276591,
                40.61034331603207
              ],
              [
                -73.90225859966276,
                40.61042277259992
              ],
              [
                -73.9023236133439,
                40.61050423258507
              ],
              [
                -73.90239138175353,
                40.610482449724834
              ],
              [
                -73.90268277928772,
                40.61075272266692
              ],
              [
                -73.90262906864334,
                40.610810188398226
              ],
              [
                -73.90274504537491,
                40.61093146873143
              ],
              [
                -73.90286496065242,
                40.61088193328191
              ],
              [
                -73.90313578707003,
                40.61096753815709
              ],
              [
                -73.9033385604731,
                40.6112714876157
              ],
              [
                -73.90342187266506,
                40.61131126705753
              ],
              [
                -73.90345549862127,
                40.61134747337173
              ],
              [
                -73.90349407447779,
                40.6113890087427
              ],
              [
                -73.90337648208998,
                40.61146970301354
              ],
              [
                -73.90337362137012,
                40.61146642441068
              ],
              [
                -73.90328328895731,
                40.611519846158565
              ],
              [
                -73.90331047012505,
                40.611547163863655
              ],
              [
                -73.90340510537716,
                40.61149047001517
              ],
              [
                -73.90339509282545,
                40.611479543900096
              ],
              [
                -73.90350980782237,
                40.611406490372545
              ],
              [
                -73.90384925159573,
                40.61168886793209
              ],
              [
                -73.90414089145172,
                40.61189613938366
              ],
              [
                -73.90409929902891,
                40.611927767007664
              ],
              [
                -73.90416083877471,
                40.61197258159145
              ],
              [
                -73.90411637142161,
                40.612010756371944
              ],
              [
                -73.90406770985663,
                40.61197796216749
              ],
              [
                -73.90404332529339,
                40.61199868643175
              ],
              [
                -73.90410629882949,
                40.61204241028689
              ],
              [
                -73.90420814113486,
                40.611955152099085
              ],
              [
                -73.9042910372516,
                40.61201485612886
              ],
              [
                -73.90428600143811,
                40.612035358829765
              ],
              [
                -73.90427007250842,
                40.612100214482325
              ],
              [
                -73.90428823036942,
                40.612155823778274
              ],
              [
                -73.90431684436872,
                40.61218761479117
              ],
              [
                -73.90437410766295,
                40.61222538629694
              ],
              [
                -73.90424103859327,
                40.61236227321875
              ],
              [
                -73.90490995852686,
                40.61282147677114
              ],
              [
                -73.90496210660214,
                40.61279372164136
              ],
              [
                -73.9050844775059,
                40.612857870940516
              ],
              [
                -73.90509488171134,
                40.61286332355426
              ],
              [
                -73.90509214724543,
                40.612865770235686
              ],
              [
                -73.90505053373573,
                40.6129029970034
              ],
              [
                -73.90521241748836,
                40.61298965918143
              ],
              [
                -73.90535513021162,
                40.613066057932066
              ],
              [
                -73.90544378702945,
                40.61301450900802
              ],
              [
                -73.90674467587257,
                40.61430216486309
              ],
              [
                -73.9068723978097,
                40.61426255834329
              ],
              [
                -73.90730153384293,
                40.61480891300767
              ],
              [
                -73.90740582820469,
                40.61475340341135
              ],
              [
                -73.90785596197065,
                40.61520595414655
              ],
              [
                -73.90834295344649,
                40.61537312326995
              ],
              [
                -73.90840539767107,
                40.615436709578084
              ],
              [
                -73.9085747448177,
                40.61544019631195
              ],
              [
                -73.90865024676272,
                40.615484555687225
              ],
              [
                -73.90900782730678,
                40.6157727869073
              ],
              [
                -73.9090202084435,
                40.61578615010567
              ],
              [
                -73.90902960565275,
                40.615796291369776
              ],
              [
                -73.90959740910328,
                40.616392669799325
              ],
              [
                -73.90963625834844,
                40.616565437433664
              ],
              [
                -73.90964406040352,
                40.61657537149951
              ],
              [
                -73.90945370835946,
                40.61668640833571
              ],
              [
                -73.9094302789907,
                40.61667050578894
              ],
              [
                -73.90911449272927,
                40.616866461592316
              ],
              [
                -73.90895706128724,
                40.616991484819316
              ],
              [
                -73.908814562086,
                40.61689044188241
              ],
              [
                -73.90916894153112,
                40.61664616184902
              ],
              [
                -73.90917675105386,
                40.61652194698017
              ],
              [
                -73.90862861698722,
                40.61606482263631
              ],
              [
                -73.90850653891894,
                40.61596935984316
              ],
              [
                -73.90812345822494,
                40.6156515622869
              ],
              [
                -73.90817445255608,
                40.615612783891855
              ],
              [
                -73.90815663851605,
                40.61560112405151
              ],
              [
                -73.90797306390525,
                40.615736844061175
              ],
              [
                -73.90799087259747,
                40.61575238674425
              ],
              [
                -73.90810560476338,
                40.61566901642847
              ],
              [
                -73.90847891987521,
                40.61598019021542
              ],
              [
                -73.90859831133376,
                40.616085198376894
              ],
              [
                -73.90913853186629,
                40.616531621729045
              ],
              [
                -73.90913839448129,
                40.616632552115426
              ],
              [
                -73.9087942081916,
                40.616872957324695
              ],
              [
                -73.9083744262503,
                40.61651742845357
              ],
              [
                -73.90835403214685,
                40.61653099896153
              ],
              [
                -73.90907145153933,
                40.61716043833179
              ],
              [
                -73.9088369111078,
                40.617311646627634
              ],
              [
                -73.90866392881254,
                40.617150410808485
              ],
              [
                -73.90863333912958,
                40.617167854909376
              ],
              [
                -73.90881396020816,
                40.61733103799171
              ],
              [
                -73.90886910990886,
                40.61739079305857
              ],
              [
                -73.90879088017641,
                40.617436396721416
              ],
              [
                -73.90860351593427,
                40.61726549705418
              ],
              [
                -73.90852252572826,
                40.61732090816455
              ],
              [
                -73.90839229831543,
                40.617388428518915
              ],
              [
                -73.90815289956946,
                40.61716189299594
              ],
              [
                -73.90781388603338,
                40.617374067585835
              ],
              [
                -73.90775403862018,
                40.617316440383
              ],
              [
                -73.9078114318726,
                40.617264864560234
              ],
              [
                -73.90776459701048,
                40.617217174556814
              ],
              [
                -73.90797062107767,
                40.61708232710207
              ],
              [
                -73.90796856793587,
                40.61705361769889
              ],
              [
                -73.9079680653862,
                40.61704658677693
              ],
              [
                -73.90778588935453,
                40.61689355788851
              ],
              [
                -73.90771656608526,
                40.61693202218735
              ],
              [
                -73.90768940958893,
                40.61694708904509
              ],
              [
                -73.90765229512199,
                40.61691575661923
              ],
              [
                -73.90755135602237,
                40.616780739630535
              ],
              [
                -73.90767882862006,
                40.616695440008044
              ],
              [
                -73.90758977173546,
                40.61662937645131
              ],
              [
                -73.90747503705722,
                40.61671274456844
              ],
              [
                -73.9073429922425,
                40.61658613341431
              ],
              [
                -73.9072949558038,
                40.61660844991536
              ],
              [
                -73.90724169382693,
                40.616551501887535
              ],
              [
                -73.90737516888878,
                40.616462185743174
              ],
              [
                -73.90754332867888,
                40.616362736714585
              ],
              [
                -73.90752735132158,
                40.61634443254885
              ],
              [
                -73.90735919243342,
                40.616443881554964
              ],
              [
                -73.9071059539902,
                40.616609860214204
              ],
              [
                -73.90702160678437,
                40.616533600356696
              ],
              [
                -73.90725494717977,
                40.6163780969955
              ],
              [
                -73.90744255412062,
                40.616258535700595
              ],
              [
                -73.90741202194343,
                40.616235218724015
              ],
              [
                -73.90722592437267,
                40.616351525932345
              ],
              [
                -73.90708346968198,
                40.61622136802845
              ],
              [
                -73.90727466091477,
                40.61610506465551
              ],
              [
                -73.90725685565036,
                40.61608758137684
              ],
              [
                -73.9070631177642,
                40.616203883163976
              ],
              [
                -73.90681023172979,
                40.61635959428093
              ],
              [
                -73.90673848666401,
                40.616295919990094
              ],
              [
                -73.90698679725256,
                40.61613782775689
              ],
              [
                -73.90717291149826,
                40.61600987533847
              ],
              [
                -73.9071474543953,
                40.61600015032795
              ],
              [
                -73.90696283645082,
                40.61611401757884
              ],
              [
                -73.9069651511667,
                40.61606387150079
              ],
              [
                -73.90596246364093,
                40.61515342406156
              ],
              [
                -73.90595891316937,
                40.61515017846609
              ],
              [
                -73.90587937620926,
                40.61507746141139
              ],
              [
                -73.90591926792638,
                40.61505054133524
              ],
              [
                -73.90592374609002,
                40.6150539622565
              ],
              [
                -73.90596638222885,
                40.61502410025575
              ],
              [
                -73.90592831880679,
                40.614990756644175
              ],
              [
                -73.90595950755576,
                40.61497014075064
              ],
              [
                -73.90598587691342,
                40.614989980831474
              ],
              [
                -73.90603328924851,
                40.61495338921948
              ],
              [
                -73.90600865413121,
                40.61493485327302
              ],
              [
                -73.90599790513686,
                40.614925896927815
              ],
              [
                -73.90588571114608,
                40.61500182609081
              ],
              [
                -73.90584343332286,
                40.61496233286455
              ],
              [
                -73.9058167572531,
                40.614937413523464
              ],
              [
                -73.90580868591383,
                40.614929874136834
              ],
              [
                -73.90578708389997,
                40.61490969482326
              ],
              [
                -73.9057790314495,
                40.61490217237043
              ],
              [
                -73.90575026311356,
                40.61487529925099
              ],
              [
                -73.9057345590125,
                40.61488297394373
              ],
              [
                -73.90587336970381,
                40.61500950305286
              ],
              [
                -73.90590695505037,
                40.615038572703504
              ],
              [
                -73.90587105277362,
                40.615063313977224
              ],
              [
                -73.90418982390513,
                40.613564560181956
              ],
              [
                -73.90418042590366,
                40.613555088969534
              ],
              [
                -73.90417612379217,
                40.613550753418046
              ],
              [
                -73.90271124133432,
                40.61224706649885
              ],
              [
                -73.90255975123588,
                40.61216397444919
              ],
              [
                -73.90242274717579,
                40.61203356000482
              ],
              [
                -73.90252788313913,
                40.61195837924747
              ],
              [
                -73.90258114049809,
                40.61193640119693
              ],
              [
                -73.90259744740868,
                40.611948363690104
              ],
              [
                -73.90262918245355,
                40.61192580917083
              ],
              [
                -73.90270664450739,
                40.611994227282295
              ],
              [
                -73.90280546663433,
                40.612078745857225
              ],
              [
                -73.90290953065013,
                40.61216840906741
              ],
              [
                -73.90290113117334,
                40.6121750305936
              ],
              [
                -73.90291750370345,
                40.61218654344852
              ],
              [
                -73.90304392812484,
                40.612095214987185
              ],
              [
                -73.9030278119923,
                40.61208425621744
              ],
              [
                -73.90292666983426,
                40.612159441976395
              ],
              [
                -73.90284371811897,
                40.61208669411006
              ],
              [
                -73.90281973905196,
                40.61206566519541
              ],
              [
                -73.90280506848971,
                40.61205279778554
              ],
              [
                -73.90277356656733,
                40.612025170567065
              ],
              [
                -73.90275819543582,
                40.612011691077484
              ],
              [
                -73.90272726591957,
                40.6119845649073
              ],
              [
                -73.90271107896186,
                40.61197036886942
              ],
              [
                -73.90268153110614,
                40.61194445609632
              ],
              [
                -73.90266710890624,
                40.61193180733682
              ],
              [
                -73.90263827747394,
                40.61190652255531
              ],
              [
                -73.90263899474436,
                40.611894789763895
              ],
              [
                -73.90272806388137,
                40.61183688186435
              ],
              [
                -73.90271854260655,
                40.61182602529592
              ],
              [
                -73.90257168768603,
                40.61192096694345
              ],
              [
                -73.90256308414796,
                40.61192431374954
              ],
              [
                -73.90251499233746,
                40.61194329773798
              ],
              [
                -73.90250700965525,
                40.61194329096753
              ],
              [
                -73.90240693535868,
                40.61201601667727
              ],
              [
                -73.90236032096202,
                40.61198041218262
              ],
              [
                -73.9024724301828,
                40.61190700784576
              ],
              [
                -73.90246715531369,
                40.611897561265714
              ],
              [
                -73.90249848536895,
                40.61187265516861
              ],
              [
                -73.90250227199049,
                40.611878189184345
              ],
              [
                -73.90265077946786,
                40.61178062116415
              ],
              [
                -73.9026400798301,
                40.611771639916476
              ],
              [
                -73.90255730736897,
                40.611827233143984
              ],
              [
                -73.90253555121053,
                40.61182621119941
              ],
              [
                -73.90130898852283,
                40.61063895269084
              ],
              [
                -73.90124836865569,
                40.610580274499085
              ],
              [
                -73.90125388461304,
                40.61056475632969
              ],
              [
                -73.90131057398872,
                40.6105333206096
              ],
              [
                -73.90130184418443,
                40.61052505778402
              ],
              [
                -73.90134497358643,
                40.61049882105902
              ],
              [
                -73.90132962659395,
                40.61048104310328
              ],
              [
                -73.9013139570879,
                40.61049050408986
              ],
              [
                -73.90129324731704,
                40.610503008602365
              ],
              [
                -73.90127679496271,
                40.610512942181124
              ],
              [
                -73.90125039672985,
                40.61052888100421
              ],
              [
                -73.90123565151283,
                40.610537784567235
              ],
              [
                -73.90120767284094,
                40.61055467777576
              ],
              [
                -73.90119253465454,
                40.61056381754662
              ],
              [
                -73.90120761505968,
                40.610581542184626
              ],
              [
                -73.90121944263846,
                40.61057524923629
              ],
              [
                -73.90249392985292,
                40.611814999032276
              ],
              [
                -73.90245961268204,
                40.61183936733822
              ],
              [
                -73.90248711043368,
                40.61186185849171
              ],
              [
                -73.90244757158227,
                40.61188564757185
              ],
              [
                -73.9023323476477,
                40.611961420779174
              ],
              [
                -73.9023162562616,
                40.61194597097947
              ],
              [
                -73.90230593068858,
                40.61195208254238
              ],
              [
                -73.90228989425326,
                40.61196157467682
              ],
              [
                -73.90221791366726,
                40.611898571464984
              ],
              [
                -73.90217513917952,
                40.61186764623178
              ],
              [
                -73.90202288769994,
                40.61175766156236
              ],
              [
                -73.90198714602955,
                40.61174540197325
              ],
              [
                -73.90195417433628,
                40.61174518940405
              ],
              [
                -73.90188714552353,
                40.61177117644259
              ],
              [
                -73.90170562488021,
                40.61183470065617
              ],
              [
                -73.90170411628955,
                40.61183522909304
              ],
              [
                -73.90169576011469,
                40.611837919499436
              ],
              [
                -73.9016146158425,
                40.61186404809773
              ],
              [
                -73.9015869204988,
                40.61185709103186
              ],
              [
                -73.90156274501301,
                40.61183959202547
              ],
              [
                -73.90155357611931,
                40.61182126537447
              ],
              [
                -73.90152882294917,
                40.61179645975702
              ],
              [
                -73.9014638578319,
                40.61176485702418
              ],
              [
                -73.90139558106458,
                40.611753332952986
              ],
              [
                -73.90133303458649,
                40.61171788290601
              ],
              [
                -73.90131357332729,
                40.61166820355293
              ],
              [
                -73.90131215941024,
                40.61156677221145
              ],
              [
                -73.90130949868475,
                40.61142783171896
              ],
              [
                -73.90133145872336,
                40.611383025828395
              ],
              [
                -73.90135576888217,
                40.6113690372444
              ],
              [
                -73.90137555983165,
                40.6113770508916
              ],
              [
                -73.90139687678771,
                40.61139744875438
              ],
              [
                -73.90140134363516,
                40.6113968970597
              ],
              [
                -73.90142267880147,
                40.61139425399252
              ],
              [
                -73.9014312140961,
                40.611383967824764
              ],
              [
                -73.90143162425723,
                40.61138131401363
              ],
              [
                -73.90144034047158,
                40.61132501631332
              ],
              [
                -73.90142494070084,
                40.61123266980432
              ],
              [
                -73.90139131154872,
                40.61114628207606
              ],
              [
                -73.90134997400646,
                40.611088615101956
              ],
              [
                -73.90129606828727,
                40.61104764834428
              ],
              [
                -73.90124817814846,
                40.611033317818915
              ],
              [
                -73.90122318207342,
                40.61103522862133
              ],
              [
                -73.90120565297312,
                40.61103513615773
              ],
              [
                -73.90115641441547,
                40.611016370443465
              ],
              [
                -73.90111116924585,
                40.61098960276765
              ],
              [
                -73.90108769605926,
                40.610966052611424
              ],
              [
                -73.90108427487063,
                40.6109435170087
              ],
              [
                -73.9010863069046,
                40.61092218342888
              ],
              [
                -73.90109605210759,
                40.610909974757824
              ],
              [
                -73.90109862677392,
                40.61089435740115
              ],
              [
                -73.90107410161538,
                40.610860962329035
              ],
              [
                -73.9010298537826,
                40.610822733140516
              ],
              [
                -73.90097600589961,
                40.61080307245986
              ],
              [
                -73.9009318497063,
                40.61078773816928
              ],
              [
                -73.9009186838028,
                40.61077741356415
              ],
              [
                -73.90090494093566,
                40.610759781177414
              ],
              [
                -73.90084903639666,
                40.610703892346436
              ],
              [
                -73.90076147221444,
                40.610612791033375
              ],
              [
                -73.9007083743688,
                40.61057054175734
              ],
              [
                -73.90066942279951,
                40.6105452829159
              ],
              [
                -73.90053422078527,
                40.61048882412866
              ],
              [
                -73.90044811559746,
                40.610444771360704
              ],
              [
                -73.90041773606399,
                40.61041081605207
              ],
              [
                -73.9004090206505,
                40.61037530892323
              ],
              [
                -73.90043054227256,
                40.61032955477281
              ],
              [
                -73.90045278253653,
                40.61029746708983
              ],
              [
                -73.90045104607532,
                40.61027554624946
              ],
              [
                -73.90043779117451,
                40.610242324307904
              ],
              [
                -73.90041033828338,
                40.61020864879213
              ],
              [
                -73.90034074595498,
                40.61015643348929
              ],
              [
                -73.90027897234978,
                40.61011811091024
              ],
              [
                -73.90025430873604,
                40.61011620162476
              ],
              [
                -73.90023385289848,
                40.610115829212276
              ],
              [
                -73.90020146175748,
                40.61012292442926
              ],
              [
                -73.9001830218845,
                40.6101193450738
              ],
              [
                -73.9001638970745,
                40.61010369029466
              ],
              [
                -73.90015619936528,
                40.610076438186915
              ],
              [
                -73.90016673111657,
                40.61004322816152
              ],
              [
                -73.9001978588538,
                40.61001675092082
              ],
              [
                -73.9002164372098,
                40.609988843136406
              ],
              [
                -73.90022454304199,
                40.60988379201695
              ],
              [
                -73.90023451276159,
                40.6096177958153
              ],
              [
                -73.9002510678944,
                40.60951740547907
              ],
              [
                -73.90028079199116,
                40.60946518631982
              ],
              [
                -73.90029844130726,
                40.60945192009201
              ],
              [
                -73.9003356691309,
                40.60943712990715
              ],
              [
                -73.90036972500582,
                40.60941093292077
              ],
              [
                -73.90039892292792,
                40.60937271167002
              ],
              [
                -73.90041127533874,
                40.60930863093726
              ],
              [
                -73.90042781786602,
                40.609264214041495
              ],
              [
                -73.90044173391954,
                40.60925194973194
              ],
              [
                -73.90046890043159,
                40.60923506203564
              ],
              [
                -73.90047685714813,
                40.60921746982694
              ],
              [
                -73.90047946417721,
                40.60916559820864
              ],
              [
                -73.90048848205193,
                40.60906403212219
              ],
              [
                -73.9004850051341,
                40.60898234561103
              ],
              [
                -73.90046543832625,
                40.608927897627424
              ],
              [
                -73.9004478707653,
                40.60888837050812
              ],
              [
                -73.9004517588019,
                40.608837753786
              ],
              [
                -73.9004757485306,
                40.60877161396943
              ],
              [
                -73.90048641763464,
                40.60870691911335
              ],
              [
                -73.90047105749785,
                40.60865400632223
              ],
              [
                -73.90044931995531,
                40.608614533220866
              ],
              [
                -73.90044035823007,
                40.60856789909398
              ],
              [
                -73.90045970284832,
                40.608499275154514
              ],
              [
                -73.90048274556077,
                40.60842805989012
              ],
              [
                -73.9005104144828,
                40.60833961239302
              ],
              [
                -73.9005111987812,
                40.608280766797975
              ],
              [
                -73.90049794019995,
                40.6082097013678
              ],
              [
                -73.90049322225323,
                40.60816619282188
              ],
              [
                -73.90050222623539,
                40.608120598522966
              ],
              [
                -73.90052116645809,
                40.60801477170054
              ],
              [
                -73.90052614806866,
                40.60788177414696
              ],
              [
                -73.90053369436052,
                40.60778913221905
              ],
              [
                -73.90056573940005,
                40.60763447914039
              ],
              [
                -73.90056214377256,
                40.607603995614454
              ],
              [
                -73.9005613486067,
                40.60754930538066
              ],
              [
                -73.90056595304027,
                40.60747450979
              ],
              [
                -73.9006068015383,
                40.607378259937256
              ],
              [
                -73.90071969724089,
                40.60720030571528
              ],
              [
                -73.90080499748406,
                40.60711397755872
              ],
              [
                -73.90084067879593,
                40.60706708712521
              ],
              [
                -73.90086969928235,
                40.607020918849805
              ],
              [
                -73.9008767251003,
                40.60698012134728
              ],
              [
                -73.90087037418692,
                40.60691946077631
              ],
              [
                -73.90083447110563,
                40.60684327927139
              ],
              [
                -73.90075756347069,
                40.606705610157405
              ],
              [
                -73.90070783005758,
                40.60662674374107
              ],
              [
                -73.90064279017145,
                40.60647842793532
              ],
              [
                -73.90059787827084,
                40.60640999415863
              ],
              [
                -73.90051858955577,
                40.606277762992335
              ],
              [
                -73.9004762407651,
                40.606249685469535
              ],
              [
                -73.90037522509616,
                40.60619151476267
              ],
              [
                -73.90033080688517,
                40.60614533621356
              ],
              [
                -73.90025988076917,
                40.60603271386394
              ],
              [
                -73.90019598545987,
                40.605954983341476
              ],
              [
                -73.9001507242467,
                40.60587065291169
              ],
              [
                -73.900112441877,
                40.60583775294134
              ],
              [
                -73.90007152836573,
                40.60579916171551
              ],
              [
                -73.90003750449233,
                40.60575126017141
              ],
              [
                -73.90000916147456,
                40.6057081701382
              ],
              [
                -73.89999705604231,
                40.605694573996985
              ],
              [
                -73.89998399429273,
                40.605679904835725
              ],
              [
                -73.89996859770162,
                40.60566745692013
              ],
              [
                -73.89993286393346,
                40.60565029830364
              ],
              [
                -73.8999065606021,
                40.60563523446464
              ],
              [
                -73.8998968912271,
                40.60562216077978
              ],
              [
                -73.89989449398105,
                40.605611755969115
              ],
              [
                -73.89990091168008,
                40.60560726005029
              ],
              [
                -73.89990948636967,
                40.60560496131166
              ],
              [
                -73.89991439513969,
                40.60559719725947
              ],
              [
                -73.8999067538063,
                40.605578060451045
              ],
              [
                -73.8998911542277,
                40.60555735115054
              ],
              [
                -73.89987585891427,
                40.60555039290598
              ],
              [
                -73.89986625885673,
                40.605540064076465
              ],
              [
                -73.89986880363854,
                40.60552464054402
              ],
              [
                -73.89986221809437,
                40.605520874447095
              ],
              [
                -73.89984767580246,
                40.605515564839045
              ],
              [
                -73.89983742177343,
                40.60550907822097
              ],
              [
                -73.89983224542372,
                40.605503117043085
              ],
              [
                -73.89981289975292,
                40.6055082877214
              ],
              [
                -73.89978363261964,
                40.60552352327391
              ],
              [
                -73.89975502217835,
                40.6055348623285
              ],
              [
                -73.89971831657213,
                40.60553919460993
              ],
              [
                -73.8996612397111,
                40.60553607063497
              ],
              [
                -73.89959332756771,
                40.60553032957513
              ],
              [
                -73.89955484411396,
                40.60552035979495
              ],
              [
                -73.89954599733558,
                40.60551167790594
              ],
              [
                -73.89953705115353,
                40.60549748003192
              ],
              [
                -73.89951668033191,
                40.60549005123669
              ],
              [
                -73.89944780783917,
                40.605474428046406
              ],
              [
                -73.89938188499723,
                40.60546259405864
              ],
              [
                -73.89933650290519,
                40.60546648053334
              ],
              [
                -73.89930511062286,
                40.60548226359843
              ],
              [
                -73.89927388742446,
                40.605506307498246
              ],
              [
                -73.89922946325662,
                40.60552007496963
              ],
              [
                -73.89919567313135,
                40.60552545250936
              ],
              [
                -73.89916181724695,
                40.60552808535133
              ],
              [
                -73.89912474245436,
                40.60551589388616
              ],
              [
                -73.89907731163696,
                40.605491752340114
              ],
              [
                -73.89905646541636,
                40.60548607154766
              ],
              [
                -73.89903664073196,
                40.605473215034834
              ],
              [
                -73.89902693503325,
                40.60546379405012
              ],
              [
                -73.89901959721236,
                40.60545837925954
              ],
              [
                -73.8989845177309,
                40.60546141388184
              ],
              [
                -73.89891955307318,
                40.60544424567243
              ],
              [
                -73.89871019993686,
                40.60535284240423
              ],
              [
                -73.89867496480568,
                40.60532029209347
              ],
              [
                -73.89864718918473,
                40.605297980361165
              ],
              [
                -73.89855419496172,
                40.60529446731979
              ],
              [
                -73.8985021104329,
                40.60529669018294
              ],
              [
                -73.8983387811778,
                40.605303659788
              ],
              [
                -73.89831730436643,
                40.60531217656739
              ],
              [
                -73.89828601297629,
                40.605333475509006
              ],
              [
                -73.89826235401648,
                40.60535402614453
              ],
              [
                -73.898258390021,
                40.605357468040296
              ],
              [
                -73.89824445577,
                40.60537963354985
              ],
              [
                -73.89823457150491,
                40.60538967185719
              ],
              [
                -73.89819758590764,
                40.60541313406261
              ],
              [
                -73.89814371654832,
                40.60543271103574
              ],
              [
                -73.89810829528953,
                40.605445937561875
              ],
              [
                -73.89805944668541,
                40.605455336356655
              ],
              [
                -73.89802202818251,
                40.605455632524325
              ],
              [
                -73.8979910694053,
                40.60544629964381
              ],
              [
                -73.8979473239477,
                40.60544011416417
              ],
              [
                -73.89790846177605,
                40.60544254886297
              ],
              [
                -73.89787099896003,
                40.60544825161065
              ],
              [
                -73.89782852308652,
                40.60545875445863
              ],
              [
                -73.89779935721683,
                40.605478491104854
              ],
              [
                -73.8977702764438,
                40.605490157604734
              ],
              [
                -73.89774123587092,
                40.60549910714501
              ],
              [
                -73.89767890823889,
                40.60551541072862
              ],
              [
                -73.89760739642848,
                40.605532200642855
              ],
              [
                -73.89753375190506,
                40.60555219947498
              ],
              [
                -73.89746649122829,
                40.6055679220776
              ],
              [
                -73.89740632780372,
                40.60558101540749
              ],
              [
                -73.89737384283042,
                40.6055818927383
              ],
              [
                -73.89736261836337,
                40.60557482806235
              ],
              [
                -73.89733808216623,
                40.6055585300206
              ],
              [
                -73.89730704372322,
                40.605554577251134
              ],
              [
                -73.89726958519435,
                40.60555756248376
              ],
              [
                -73.89722931501039,
                40.60556216569965
              ],
              [
                -73.8971819549379,
                40.605564017258665
              ],
              [
                -73.89714512964417,
                40.60557672021103
              ],
              [
                -73.8970990839511,
                40.60558988146552
              ],
              [
                -73.89705299760507,
                40.605605732010865
              ],
              [
                -73.89702463446002,
                40.60561901997688
              ],
              [
                -73.8970027013133,
                40.605619960433316
              ],
              [
                -73.8969462734801,
                40.605616917445865
              ],
              [
                -73.89688900043505,
                40.60562301557119
              ],
              [
                -73.89682399044987,
                40.60562745823588
              ],
              [
                -73.89676038286802,
                40.605632451451555
              ],
              [
                -73.89672573151199,
                40.605636537517185
              ],
              [
                -73.89669873597367,
                40.60565037394948
              ],
              [
                -73.8966696507696,
                40.60566473051763
              ],
              [
                -73.89661922910476,
                40.60568971838731
              ],
              [
                -73.89655332325336,
                40.60571405970929
              ],
              [
                -73.89651361336718,
                40.60572835289767
              ],
              [
                -73.89646612349583,
                40.60574365244823
              ],
              [
                -73.89641374038978,
                40.605755710905825
              ],
              [
                -73.8963741968421,
                40.605756552442166
              ],
              [
                -73.89634239220534,
                40.605759048426656
              ],
              [
                -73.89630914096797,
                40.60576637564334
              ],
              [
                -73.89627865673783,
                40.60577749200916
              ],
              [
                -73.89625089867457,
                40.60579250789101
              ],
              [
                -73.89622585106429,
                40.6058055510447
              ],
              [
                -73.89619251418554,
                40.60582198972618
              ],
              [
                -73.89616247045461,
                40.605827424204975
              ],
              [
                -73.89613229172254,
                40.605827369461075
              ],
              [
                -73.89611282804842,
                40.605825774750244
              ],
              [
                -73.8960933645966,
                40.60582417886363
              ],
              [
                -73.89604504219373,
                40.60581897443151
              ],
              [
                -73.89600848153306,
                40.60582341589371
              ],
              [
                -73.89596629793112,
                40.60583122729713
              ],
              [
                -73.89591344424802,
                40.605844711276376
              ],
              [
                -73.89587853199558,
                40.60585516480123
              ],
              [
                -73.8958174463283,
                40.605881926173296
              ],
              [
                -73.89575210848106,
                40.60590989070359
              ],
              [
                -73.89569445337851,
                40.605931110576776
              ],
              [
                -73.89562963265263,
                40.60595246229328
              ],
              [
                -73.89558134136269,
                40.60597300441302
              ],
              [
                -73.89549686545347,
                40.606011682489644
              ],
              [
                -73.8954530647932,
                40.6060392553139
              ],
              [
                -73.89541355308576,
                40.60606567975878
              ],
              [
                -73.89534756041574,
                40.60612048934005
              ],
              [
                -73.89531041351154,
                40.60615451787293
              ],
              [
                -73.89527724698775,
                40.60617644603923
              ],
              [
                -73.89524390832825,
                40.60619291191853
              ],
              [
                -73.89519469177483,
                40.606206315955255
              ],
              [
                -73.89516502304126,
                40.606225447028784
              ],
              [
                -73.89512849348962,
                40.60625566310224
              ],
              [
                -73.89509196395277,
                40.60628584884134
              ],
              [
                -73.89506033940964,
                40.6063110446682
              ],
              [
                -73.89502195866986,
                40.60632703981315
              ],
              [
                -73.89499201826885,
                40.606335191905785
              ],
              [
                -73.89496005370184,
                40.60634943567864
              ],
              [
                -73.89492901063724,
                40.60637076123968
              ],
              [
                -73.89489159174688,
                40.60639386514719
              ],
              [
                -73.89486168368406,
                40.60640476320584
              ],
              [
                -73.8948279702857,
                40.60640753093549
              ],
              [
                -73.89479799307118,
                40.60641571159138
              ],
              [
                -73.89476314750107,
                40.60642888216109
              ],
              [
                -73.89471331217747,
                40.6064461561861
              ],
              [
                -73.89466683917881,
                40.60645514392868
              ],
              [
                -73.89461789972681,
                40.606453781617866
              ],
              [
                -73.89457965967956,
                40.606449464672146
              ],
              [
                -73.89454769743276,
                40.60643793464262
              ],
              [
                -73.89453092576933,
                40.606430563215945
              ],
              [
                -73.89447793734078,
                40.60643855681627
              ],
              [
                -73.89443956109264,
                40.60642877707576
              ],
              [
                -73.89438918052521,
                40.60642689165829
              ],
              [
                -73.89436554952162,
                40.60643057602175
              ],
              [
                -73.89433115077942,
                40.60643441449226
              ],
              [
                -73.8942667062879,
                40.606443714576336
              ],
              [
                -73.89422747013796,
                40.6064553168923
              ],
              [
                -73.89417838753693,
                40.60647423936249
              ],
              [
                -73.89411984237347,
                40.60648834752128
              ],
              [
                -73.89406640667247,
                40.60650567222853
              ],
              [
                -73.89403704781694,
                40.606510009969746
              ],
              [
                -73.89399630332582,
                40.606518371472546
              ],
              [
                -73.89395425232833,
                40.60653167185507
              ],
              [
                -73.89392804932636,
                40.60654526243471
              ],
              [
                -73.89390098811438,
                40.60655445943423
              ],
              [
                -73.8938776645142,
                40.6065690945242
              ],
              [
                -73.89384899207721,
                40.60657233603772
              ],
              [
                -73.89382398895224,
                40.60657821315191
              ],
              [
                -73.89380526239005,
                40.606576878904285
              ],
              [
                -73.89379102987849,
                40.60658260178375
              ],
              [
                -73.89376811931783,
                40.606585189876874
              ],
              [
                -73.89375096918045,
                40.60658983853832
              ],
              [
                -73.8937209246845,
                40.60659527288074
              ],
              [
                -73.89370261066202,
                40.606607635008515
              ],
              [
                -73.89366875603982,
                40.606630661065246
              ],
              [
                -73.8936431345325,
                40.6066404088316
              ],
              [
                -73.89361034669517,
                40.60665028666278
              ],
              [
                -73.89356829553775,
                40.6066635869038
              ],
              [
                -73.89356637422146,
                40.60667236901308
              ],
              [
                -73.89355865577507,
                40.60667908665289
              ],
              [
                -73.89353228048533,
                40.60668718621482
              ],
              [
                -73.89350216842729,
                40.606689875807554
              ],
              [
                -73.89346029068597,
                40.60670866677011
              ],
              [
                -73.89343192495704,
                40.6067228310295
              ],
              [
                -73.89339858556959,
                40.60673929637264
              ],
              [
                -73.89337437125587,
                40.606746822104746
              ],
              [
                -73.89335633266543,
                40.60674438993362
              ],
              [
                -73.89334621420008,
                40.606743446234645
              ],
              [
                -73.89333077816711,
                40.606756854512696
              ],
              [
                -73.89330488145153,
                40.60678139694993
              ],
              [
                -73.89325682785129,
                40.60681014472861
              ],
              [
                -73.8932400878229,
                40.606828519860194
              ],
              [
                -73.89321442938612,
                40.6068612689135
              ],
              [
                -73.89319508034635,
                40.60688954979086
              ],
              [
                -73.89316193846592,
                40.606962996099575
              ],
              [
                -73.89314509341307,
                40.60700162773868
              ],
              [
                -73.89313133653025,
                40.60702376715937
              ],
              [
                -73.89311202433865,
                40.607052047051546
              ],
              [
                -73.89307868475188,
                40.60706848482656
              ],
              [
                -73.89305100460894,
                40.6070815516433
              ],
              [
                -73.89301735849152,
                40.60708706537741
              ],
              [
                -73.89295881188569,
                40.60710117294339
              ],
              [
                -73.89291755142094,
                40.607116120494126
              ],
              [
                -73.89285972311285,
                40.607131877333
              ],
              [
                -73.89282052082658,
                40.60714347802739
              ],
              [
                -73.89277298224872,
                40.60716563967522
              ],
              [
                -73.89274578072478,
                40.60719514834253
              ],
              [
                -73.89272944999256,
                40.607227192316486
              ],
              [
                -73.89271349483414,
                40.60727296062592
              ],
              [
                -73.89269805366088,
                40.60731211452456
              ],
              [
                -73.89267753876939,
                40.6073480796248
              ],
              [
                -73.89266865111792,
                40.607365226309525
              ],
              [
                -73.89266288822178,
                40.607388881068196
              ],
              [
                -73.89265629812327,
                40.60741091621995
              ],
              [
                -73.89264562768527,
                40.60743958942035
              ],
              [
                -73.89262744796882,
                40.60748011247971
              ],
              [
                -73.89259877163929,
                40.60752894321463
              ],
              [
                -73.8925925566787,
                40.60756440064487
              ],
              [
                -73.89258925681183,
                40.60760087535537
              ],
              [
                -73.89257923318526,
                40.60765375878902
              ],
              [
                -73.89257644693231,
                40.60768372882252
              ],
              [
                -73.89257263734851,
                40.60770118330554
              ],
              [
                -73.89255332887906,
                40.60772647170545
              ],
              [
                -73.89253453424524,
                40.60774525542631
              ],
              [
                -73.89253161366011,
                40.60776976371558
              ],
              [
                -73.89251606825799,
                40.60782873601951
              ],
              [
                -73.89251265000011,
                40.60791631872829
              ],
              [
                -73.89251225672591,
                40.60798213871314
              ],
              [
                -73.8925039094105,
                40.608043695516365
              ],
              [
                -73.89249634968839,
                40.608109619031715
              ],
              [
                -73.89248324041495,
                40.60815330506726
              ],
              [
                -73.89246694577112,
                40.60818510142687
              ],
              [
                -73.89245861387248,
                40.60819283297451
              ],
              [
                -73.89242809957985,
                40.60819928310624
              ],
              [
                -73.89236254719486,
                40.608202460074224
              ],
              [
                -73.89234067063052,
                40.608212046375286
              ],
              [
                -73.89232674661802,
                40.60822586654712
              ],
              [
                -73.89228652890434,
                40.60824224336817
              ],
              [
                -73.89227551823753,
                40.60825708232819
              ],
              [
                -73.89226491754677,
                40.608288254421005
              ],
              [
                -73.89227063393139,
                40.608315898734105
              ],
              [
                -73.89226476757497,
                40.608336645068235
              ],
              [
                -73.89224765128853,
                40.608364103297085
              ],
              [
                -73.89224455411593,
                40.6084087308223
              ],
              [
                -73.89221975673654,
                40.60844282605055
              ],
              [
                -73.89220613849824,
                40.60846748830896
              ],
              [
                -73.89220095663808,
                40.60848713679302
              ],
              [
                -73.89218566106375,
                40.608503150962925
              ],
              [
                -73.89217035937482,
                40.60854472135724
              ],
              [
                -73.8921728042202,
                40.60855097030929
              ],
              [
                -73.89217649206044,
                40.60856040046501
              ],
              [
                -73.89220227177582,
                40.6085643491832
              ],
              [
                -73.89222497128918,
                40.608559101009256
              ],
              [
                -73.89225154669337,
                40.6085391152419
              ],
              [
                -73.8922660530439,
                40.60852150779185
              ],
              [
                -73.89228759092023,
                40.60849833390585
              ],
              [
                -73.89230655271741,
                40.608487701847714
              ],
              [
                -73.89232081489433,
                40.608487495076766
              ],
              [
                -73.892350466836,
                40.60850226407465
              ],
              [
                -73.89237230295815,
                40.60851823078346
              ],
              [
                -73.89240003217414,
                40.60854172523009
              ],
              [
                -73.89241874979801,
                40.608548521816985
              ],
              [
                -73.89242327207693,
                40.608558242738695
              ],
              [
                -73.89242165582378,
                40.60857783895004
              ],
              [
                -73.89240577649015,
                40.60859764211121
              ],
              [
                -73.89237419500388,
                40.60861718296011
              ],
              [
                -73.89233887804373,
                40.60863131246133
              ],
              [
                -73.89230884579732,
                40.608628512054835
              ],
              [
                -73.89227092888656,
                40.60862419464573
              ],
              [
                -73.89224086147311,
                40.60862139536124
              ],
              [
                -73.89222320409746,
                40.60862708685754
              ],
              [
                -73.89222484752896,
                40.6086357625538
              ],
              [
                -73.89222992108611,
                40.608638951201954
              ],
              [
                -73.89226903237174,
                40.608661193349555
              ],
              [
                -73.89232233910616,
                40.60868048485801
              ],
              [
                -73.89235117112669,
                40.60869094155797
              ],
              [
                -73.89236659371454,
                40.60870863250116
              ],
              [
                -73.8923721087751,
                40.60872812515512
              ],
              [
                -73.89236651131546,
                40.60875974053201
              ],
              [
                -73.89235474828891,
                40.60877298626251
              ],
              [
                -73.89233475269599,
                40.608799373641006
              ],
              [
                -73.89232388063871,
                40.60881967384889
              ],
              [
                -73.89231046617176,
                40.608852489616744
              ],
              [
                -73.89228712956258,
                40.608940959978945
              ],
              [
                -73.89226636666562,
                40.60901955154592
              ],
              [
                -73.89223415091612,
                40.60909673199515
              ],
              [
                -73.89223736489393,
                40.609136918708735
              ],
              [
                -73.89223996351477,
                40.609155366770366
              ],
              [
                -73.89225854392797,
                40.609156701637595
              ],
              [
                -73.89226735458966,
                40.609165273146616
              ],
              [
                -73.89225205821351,
                40.609181288998805
              ],
              [
                -73.89218982624992,
                40.60920181662087
              ],
              [
                -73.89214806385068,
                40.609214952433085
              ],
              [
                -73.89210797938067,
                40.6092367622846
              ],
              [
                -73.89209579987548,
                40.6092619772171
              ],
              [
                -73.89208619107376,
                40.609302892039366
              ],
              [
                -73.89207449626187,
                40.60931885629962
              ],
              [
                -73.89203673967039,
                40.609348244480756
              ],
              [
                -73.8920126272566,
                40.609381242319145
              ],
              [
                -73.89200226922794,
                40.60939498404646
              ],
              [
                -73.89198865066955,
                40.60941967442162
              ],
              [
                -73.89195381174603,
                40.60945010835341
              ],
              [
                -73.8919186964821,
                40.609472391053565
              ],
              [
                -73.89185947818015,
                40.609499398508056
              ],
              [
                -73.89182975218749,
                40.609507468223676
              ],
              [
                -73.89178661669833,
                40.609522797410925
              ],
              [
                -73.89176940682302,
                40.60951926895924
              ],
              [
                -73.89176138601042,
                40.609512317659906
              ],
              [
                -73.8917687630466,
                40.609494811784046
              ],
              [
                -73.89178570212476,
                40.609487472702774
              ],
              [
                -73.89179602504251,
                40.609473729956825
              ],
              [
                -73.89179300947643,
                40.609467249270025
              ],
              [
                -73.89178632544525,
                40.60945810342683
              ],
              [
                -73.8917721304934,
                40.6094610538192
              ],
              [
                -73.89176235782821,
                40.609468263445976
              ],
              [
                -73.89175255064889,
                40.60947547303947
              ],
              [
                -73.89174617296976,
                40.609477224245765
              ],
              [
                -73.89173846460528,
                40.60945558797274
              ],
              [
                -73.89176826872492,
                40.60942196430188
              ],
              [
                -73.89179320143182,
                40.609393304615686
              ],
              [
                -73.8918129158035,
                40.609384293354566
              ],
              [
                -73.89184147817522,
                40.609383853666735
              ],
              [
                -73.8919042874189,
                40.60938506552099
              ],
              [
                -73.89191491605392,
                40.6093821935129
              ],
              [
                -73.89195434226023,
                40.609389751297826
              ],
              [
                -73.89197710758086,
                40.609387220054444
              ],
              [
                -73.89198204467463,
                40.609384973940706
              ],
              [
                -73.89198053798371,
                40.60938173368455
              ],
              [
                -73.89197251628632,
                40.609374782399094
              ],
              [
                -73.89194567584633,
                40.60935834216501
              ],
              [
                -73.89191115290005,
                40.6093485387373
              ],
              [
                -73.89188030062537,
                40.609341400119455
              ],
              [
                -73.89186552721634,
                40.60932258442983
              ],
              [
                -73.89186495088494,
                40.609300845645386
              ],
              [
                -73.89187706053777,
                40.60927294300222
              ],
              [
                -73.89189568455569,
                40.609248723413735
              ],
              [
                -73.89191293485872,
                40.60922670000972
              ],
              [
                -73.8919469214283,
                40.60918920946492
              ],
              [
                -73.89194730240311,
                40.60917724254854
              ],
              [
                -73.89195059673267,
                40.60916632073706
              ],
              [
                -73.89196496903469,
                40.6091432510807
              ],
              [
                -73.89198153250831,
                40.609122350806004
              ],
              [
                -73.89199251089269,
                40.60910476805301
              ],
              [
                -73.89199014557242,
                40.60909718915862
              ],
              [
                -73.89198356658119,
                40.60909078805483
              ],
              [
                -73.89197293667654,
                40.609093660068
              ],
              [
                -73.89194461446813,
                40.609102252148375
              ],
              [
                -73.89190421803633,
                40.609138746902666
              ],
              [
                -73.89181714695249,
                40.609218922819394
              ],
              [
                -73.89169657038079,
                40.60932733847845
              ],
              [
                -73.89156049550925,
                40.60944361761325
              ],
              [
                -73.89147510697336,
                40.60950691495885
              ],
              [
                -73.89136506644252,
                40.609581470018504
              ],
              [
                -73.8912635280208,
                40.60965369715776
              ],
              [
                -73.89116264069276,
                40.609724858835946
              ],
              [
                -73.89107790656504,
                40.609784339019306
              ],
              [
                -73.8910330863643,
                40.609816548402335
              ],
              [
                -73.89099306795256,
                40.609841076421816
              ],
              [
                -73.89093412025686,
                40.609878954116766
              ],
              [
                -73.89086026035156,
                40.60991816154118
              ],
              [
                -73.89078567758789,
                40.60995843874219
              ],
              [
                -73.89071377146458,
                40.60999163782227
              ],
              [
                -73.89066106589074,
                40.610022356259655
              ],
              [
                -73.89058822830458,
                40.61007402697117
              ],
              [
                -73.89051720497692,
                40.610139806989906
              ],
              [
                -73.89042145603655,
                40.61021687144163
              ],
              [
                -73.89030211389475,
                40.610317656862506
              ],
              [
                -73.8902171644626,
                40.61039725778824
              ],
              [
                -73.89015491427742,
                40.61047160921031
              ],
              [
                -73.89012431460466,
                40.61052916574382
              ],
              [
                -73.89010750322278,
                40.610567495071145
              ],
              [
                -73.8900687474416,
                40.61061266422905
              ],
              [
                -73.89003276807986,
                40.61065618921911
              ],
              [
                -73.88998862619619,
                40.610712855576274
              ],
              [
                -73.88993676636512,
                40.610776182686614
              ],
              [
                -73.88988195927155,
                40.61083574776446
              ],
              [
                -73.88985088630069,
                40.61087428184732
              ],
              [
                -73.8898030753878,
                40.61092830904876
              ],
              [
                -73.88978451776185,
                40.6109552440053
              ],
              [
                -73.88976640213248,
                40.61099845717886
              ],
              [
                -73.88975400969885,
                40.61104378938594
              ],
              [
                -73.88974700785134,
                40.61107488176401
              ],
              [
                -73.88974339996096,
                40.61110048780423
              ],
              [
                -73.88975069109793,
                40.611134090577856
              ],
              [
                -73.88975684996826,
                40.61117804120631
              ],
              [
                -73.88974857431269,
                40.61121678927907
              ],
              [
                -73.88973659161245,
                40.61127568050741
              ],
              [
                -73.88972080425104,
                40.61132649904574
              ],
              [
                -73.88970771942019,
                40.61139848244819
              ],
              [
                -73.88969631846447,
                40.611453559592256
              ],
              [
                -73.88969643663843,
                40.6115128204364
              ],
              [
                -73.88970208064967,
                40.61156330202819
              ],
              [
                -73.88971513183803,
                40.611598996904085
              ],
              [
                -73.8897357979812,
                40.61162533990426
              ],
              [
                -73.8897667138741,
                40.61166075002261
              ],
              [
                -73.88979163130142,
                40.61168591567457
              ],
              [
                -73.88981830085874,
                40.61169692042769
              ],
              [
                -73.88987373662216,
                40.6117156656097
              ],
              [
                -73.88992780018391,
                40.61173657844983
              ],
              [
                -73.88999149500485,
                40.611770399383474
              ],
              [
                -73.89006245439326,
                40.61180955364933
              ],
              [
                -73.89013698090788,
                40.61184865700127
              ],
              [
                -73.8901893241623,
                40.61188647426966
              ],
              [
                -73.89025541408637,
                40.61193059250822
              ],
              [
                -73.89030196651541,
                40.61196357450043
              ],
              [
                -73.89032551194437,
                40.61199096150182
              ],
              [
                -73.89033390698104,
                40.61201147423185
              ],
              [
                -73.89036856635637,
                40.61202671290264
              ],
              [
                -73.89044497991411,
                40.61205708774632
              ],
              [
                -73.8904906752201,
                40.61208576016452
              ],
              [
                -73.89054936434638,
                40.61211910978468
              ],
              [
                -73.89058827569782,
                40.61213320017273
              ],
              [
                -73.8906436077181,
                40.61214920125985
              ],
              [
                -73.89069540915486,
                40.61216527904198
              ],
              [
                -73.890751328315,
                40.61217474733709
              ],
              [
                -73.89082997815156,
                40.61218171152773
              ],
              [
                -73.89088095934763,
                40.612193425320065
              ],
              [
                -73.89097777129396,
                40.612239244912296
              ],
              [
                -73.89102257650492,
                40.61226086095502
              ],
              [
                -73.89107794632615,
                40.61227686186852
              ],
              [
                -73.89109920436923,
                40.61227111683316
              ],
              [
                -73.89112317309483,
                40.612260956285105
              ],
              [
                -73.8911383350983,
                40.61223950749254
              ],
              [
                -73.891152639307,
                40.612213746860185
              ],
              [
                -73.89116138821068,
                40.61219402005372
              ],
              [
                -73.89115950685309,
                40.612177193160996
              ],
              [
                -73.8911505614258,
                40.61216321259893
              ],
              [
                -73.8911380178029,
                40.61214654095967
              ],
              [
                -73.89107089230457,
                40.61211820650221
              ],
              [
                -73.8910319492454,
                40.61210139775338
              ],
              [
                -73.89099866192876,
                40.61208396478062
              ],
              [
                -73.89098820988829,
                40.61206671696543
              ],
              [
                -73.89099709323197,
                40.61205245241628
              ],
              [
                -73.89101629376061,
                40.6120499734344
              ],
              [
                -73.89108638304268,
                40.612028246179136
              ],
              [
                -73.891114883189,
                40.61199953457442
              ],
              [
                -73.89115352994774,
                40.611977201691424
              ],
              [
                -73.89119093957224,
                40.611962497568136
              ],
              [
                -73.89122728330689,
                40.61196082903338
              ],
              [
                -73.89125615692345,
                40.61194570569445
              ],
              [
                -73.89127724585015,
                40.61193452850365
              ],
              [
                -73.89132284639948,
                40.61193218199241
              ],
              [
                -73.89134842585895,
                40.61192797975101
              ],
              [
                -73.89137304754392,
                40.6119140331084
              ],
              [
                -73.89142291623855,
                40.611856712912115
              ],
              [
                -73.8914422940607,
                40.611834114623306
              ],
              [
                -73.89145981786032,
                40.61182298730612
              ],
              [
                -73.89149026555634,
                40.611813819880346
              ],
              [
                -73.89153473422412,
                40.61182182232983
              ],
              [
                -73.8915780373995,
                40.61181464435661
              ],
              [
                -73.89162453754801,
                40.611791082358025
              ],
              [
                -73.89168982834119,
                40.6117514810573
              ],
              [
                -73.89173382823894,
                40.61171493550106
              ],
              [
                -73.89176733298619,
                40.61168666821169
              ],
              [
                -73.89176751025295,
                40.61166654897499
              ],
              [
                -73.89175568450037,
                40.611651523381326
              ],
              [
                -73.89175102759246,
                40.61163636816919
              ],
              [
                -73.89175552504005,
                40.61161781755489
              ],
              [
                -73.89176776994219,
                40.61159532121979
              ],
              [
                -73.89179465309756,
                40.611586207484216
              ],
              [
                -73.89181625045815,
                40.611594049913414
              ],
              [
                -73.89184576980698,
                40.61160338259251
              ],
              [
                -73.89186089427974,
                40.611581932495476
              ],
              [
                -73.89186052081706,
                40.61156834536893
              ],
              [
                -73.89187232044357,
                40.61155512602865
              ],
              [
                -73.89189389086438,
                40.61153467124988
              ],
              [
                -73.89191302416836,
                40.61152947356082
              ],
              [
                -73.89194175364253,
                40.61153718546652
              ],
              [
                -73.89197535223015,
                40.61153993436919
              ],
              [
                -73.8919986952916,
                40.611559169878795
              ],
              [
                -73.89202221714038,
                40.611558258170575
              ],
              [
                -73.89204347623411,
                40.61155248716198
              ],
              [
                -73.89210656779056,
                40.611536297253906
              ],
              [
                -73.89217291854987,
                40.61150918713816
              ],
              [
                -73.89226399024324,
                40.61147353473118
              ],
              [
                -73.8923714192018,
                40.61143438481997
              ],
              [
                -73.89243289959188,
                40.61141223689545
              ],
              [
                -73.89248131228022,
                40.61140821994621
              ],
              [
                -73.89257532615905,
                40.61140188444927
              ],
              [
                -73.89262435670531,
                40.61139408018489
              ],
              [
                -73.89266036251935,
                40.61137882578152
              ],
              [
                -73.89269269747017,
                40.61136093254014
              ],
              [
                -73.89274228034354,
                40.61134659661493
              ],
              [
                -73.89277578448072,
                40.61131832785792
              ],
              [
                -73.8928193004153,
                40.6112910308815
              ],
              [
                -73.89286007197272,
                40.611268122937865
              ],
              [
                -73.89288082130972,
                40.61124332820534
              ],
              [
                -73.89289532812593,
                40.61122569320594
              ],
              [
                -73.89290770999314,
                40.61120866016716
              ],
              [
                -73.89293616670399,
                40.61120550211656
              ],
              [
                -73.8929933904562,
                40.611210056909705
              ],
              [
                -73.89304180314119,
                40.61120606652911
              ],
              [
                -73.8930885375207,
                40.611193375284905
              ],
              [
                -73.8931159029391,
                40.61117501006328
              ],
              [
                -73.89313335787313,
                40.61116116560753
              ],
              [
                -73.8931596216847,
                40.61115586511134
              ],
              [
                -73.89319644725846,
                40.61114497392651
              ],
              [
                -73.89325185819874,
                40.611135418613095
              ],
              [
                -73.89331326937933,
                40.61111055400427
              ],
              [
                -73.89336405167376,
                40.61108855982999
              ],
              [
                -73.89338856819977,
                40.61107186618587
              ],
              [
                -73.89344216311285,
                40.61104822945014
              ],
              [
                -73.89345807815877,
                40.61102839820613
              ],
              [
                -73.89347916625445,
                40.61101719095751
              ],
              [
                -73.89351887151976,
                40.61100734872457
              ],
              [
                -73.89354938853043,
                40.61100089763648
              ],
              [
                -73.89357760194986,
                40.61101514178717
              ],
              [
                -73.89359851326351,
                40.61102408173476
              ],
              [
                -73.89360608756932,
                40.611040256139525
              ],
              [
                -73.8936083475598,
                40.6110451170771
              ],
              [
                -73.89359497528554,
                40.61105240477843
              ],
              [
                -73.89358009404614,
                40.61105642577179
              ],
              [
                -73.89357265103773,
                40.61107124084786
              ],
              [
                -73.89357185579416,
                40.61109517582817
              ],
              [
                -73.89358175848243,
                40.61111895435713
              ],
              [
                -73.89357472169938,
                40.61115004643159
              ],
              [
                -73.89358085539476,
                40.611165724958006
              ],
              [
                -73.89359381403101,
                40.611170402472155
              ],
              [
                -73.89364561695476,
                40.61118648060301
              ],
              [
                -73.8937168229822,
                40.61120822975432
              ],
              [
                -73.89379152722755,
                40.61122718301811
              ],
              [
                -73.89383232489568,
                40.611232545619906
              ],
              [
                -73.89384782444569,
                40.61122470985778
              ],
              [
                -73.89386562665591,
                40.611196154046304
              ],
              [
                -73.893880751707,
                40.611174703181234
              ],
              [
                -73.89389343874454,
                40.61116853910195
              ],
              [
                -73.89391997408065,
                40.61117410746641
              ],
              [
                -73.89394013317667,
                40.611181426651264
              ],
              [
                -73.89396385060894,
                40.611214222580756
              ],
              [
                -73.89396648650072,
                40.611232669460605
              ],
              [
                -73.89395838926941,
                40.611251272292755
              ],
              [
                -73.89396616744754,
                40.611275625798044
              ],
              [
                -73.893968869809,
                40.61129679123335
              ],
              [
                -73.89398851362914,
                40.61131064256277
              ],
              [
                -73.8940308819601,
                40.61134748877293
              ],
              [
                -73.89404098799922,
                40.61137942058624
              ],
              [
                -73.89404032982232,
                40.61140878883044
              ],
              [
                -73.89404227901488,
                40.61142833356322
              ],
              [
                -73.89403393868307,
                40.6114643655653
              ],
              [
                -73.89400217954798,
                40.61150399862141
              ],
              [
                -73.89396490703528,
                40.611524137926374
              ],
              [
                -73.893932400722,
                40.61153659672418
              ],
              [
                -73.89388460631375,
                40.61153680021843
              ],
              [
                -73.89383650564831,
                40.61152610597935
              ],
              [
                -73.89376203873022,
                40.61151527762216
              ],
              [
                -73.89369638020443,
                40.611515737262955
              ],
              [
                -73.89364470697575,
                40.611530677282715
              ],
              [
                -73.89359262137847,
                40.61155758232603
              ],
              [
                -73.8935342917296,
                40.61159164678059
              ],
              [
                -73.89348377908387,
                40.61162450939241
              ],
              [
                -73.8934180488139,
                40.611647806705236
              ],
              [
                -73.89335882918584,
                40.61167481660163
              ],
              [
                -73.89330629990764,
                40.61168538969999
              ],
              [
                -73.89326463906255,
                40.611701270806336
              ],
              [
                -73.89320774838846,
                40.61173585727904
              ],
              [
                -73.8931504529306,
                40.61175411127376
              ],
              [
                -73.89311499848357,
                40.61176283471969
              ],
              [
                -73.89307714661389,
                40.611761234459024
              ],
              [
                -73.89303367142404,
                40.61176297769133
              ],
              [
                -73.8930119700201,
                40.61175244588281
              ],
              [
                -73.89297737859249,
                40.61173992438857
              ],
              [
                -73.89293363286878,
                40.611730798883066
              ],
              [
                -73.89286362222313,
                40.61172697329185
              ],
              [
                -73.89279796128262,
                40.6117274335901
              ],
              [
                -73.89274879586955,
                40.61172983113704
              ],
              [
                -73.89270638042095,
                40.61174406335356
              ],
              [
                -73.89266643069745,
                40.61177133731431
              ],
              [
                -73.89263748842207,
                40.61178374429193
              ],
              [
                -73.89256424515882,
                40.61181911110156
              ],
              [
                -73.89247429597349,
                40.611872687222466
              ],
              [
                -73.89240262899736,
                40.61191401173188
              ],
              [
                -73.8923231069556,
                40.611956565035406
              ],
              [
                -73.89226337354145,
                40.61199010366619
              ],
              [
                -73.8922285622075,
                40.612023256194334
              ],
              [
                -73.8922013661422,
                40.61204705667544
              ],
              [
                -73.89217749989469,
                40.612062651684674
              ],
              [
                -73.89215284463695,
                40.61207390912371
              ],
              [
                -73.89212013413923,
                40.612078215085624
              ],
              [
                -73.89209637445802,
                40.612070975129214
              ],
              [
                -73.8920861604436,
                40.6120618797693
              ],
              [
                -73.89209765347529,
                40.612037763693344
              ],
              [
                -73.892109144477,
                40.61201367458645
              ],
              [
                -73.89211576721836,
                40.611994550156304
              ],
              [
                -73.89212863177866,
                40.6119682390583
              ],
              [
                -73.89211560559829,
                40.61196084332454
              ],
              [
                -73.89209633768667,
                40.61196057880319
              ],
              [
                -73.89207363854527,
                40.61196582695137
              ],
              [
                -73.89200214578041,
                40.61198703197176
              ],
              [
                -73.8918596094373,
                40.61202022032937
              ],
              [
                -73.89182922786112,
                40.61203210473392
              ],
              [
                -73.89181155973804,
                40.61206609499765
              ],
              [
                -73.89179636682488,
                40.612084800226675
              ],
              [
                -73.89177898056371,
                40.612101390512024
              ],
              [
                -73.89175874328289,
                40.61211962374799
              ],
              [
                -73.89174190772161,
                40.612129681247986
              ],
              [
                -73.89171982690954,
                40.61213111577445
              ],
              [
                -73.89168448105909,
                40.6121169741802
              ],
              [
                -73.89167276013539,
                40.61210463902659
              ],
              [
                -73.8916763660208,
                40.612079032927184
              ],
              [
                -73.89168017762498,
                40.612061580158354
              ],
              [
                -73.8916642358476,
                40.61205311061254
              ],
              [
                -73.89164657746798,
                40.612058831168355
              ],
              [
                -73.89161708480994,
                40.612077769328295
              ],
              [
                -73.8915846815305,
                40.61209294605331
              ],
              [
                -73.89154010439341,
                40.61210775226804
              ],
              [
                -73.89149618167237,
                40.61211877175369
              ],
              [
                -73.89145771186591,
                40.612120986688616
              ],
              [
                -73.8914143047419,
                40.612125447699476
              ],
              [
                -73.89138307694229,
                40.61214771530805
              ],
              [
                -73.89137297960706,
                40.61215491521179
              ],
              [
                -73.89134386172833,
                40.612187440430176
              ],
              [
                -73.89130942516735,
                40.61223417980853
              ],
              [
                -73.89127258158636,
                40.612298894107944
              ],
              [
                -73.89125604245064,
                40.61234809263648
              ],
              [
                -73.89123374315199,
                40.61239519913752
              ],
              [
                -73.89120818997512,
                40.61242767391718
              ],
              [
                -73.89116522429462,
                40.61244843934729
              ],
              [
                -73.89112774540011,
                40.61246042607494
              ],
              [
                -73.89109229075407,
                40.61246914839409
              ],
              [
                -73.89106691390678,
                40.612481475929734
              ],
              [
                -73.89104410644784,
                40.61250956099793
              ],
              [
                -73.89103710302403,
                40.61254068092289
              ],
              [
                -73.8910457011399,
                40.61256934475072
              ],
              [
                -73.89104723360161,
                40.612600855905775
              ],
              [
                -73.89104355929425,
                40.61262377090028
              ],
              [
                -73.89102790276476,
                40.612649666939646
              ],
              [
                -73.89098259882945,
                40.61267913104102
              ],
              [
                -73.8909586166174,
                40.61269744300786
              ],
              [
                -73.89094910253571,
                40.612723784626944
              ],
              [
                -73.8909435536638,
                40.61276917772556
              ],
              [
                -73.89095414564672,
                40.61283404731705
              ],
              [
                -73.89098063257914,
                40.612868164435845
              ],
              [
                -73.89103674500853,
                40.6129144943587
              ],
              [
                -73.89108702975122,
                40.61295595988698
              ],
              [
                -73.89114036104236,
                40.613006707880025
              ],
              [
                -73.8911734991628,
                40.6130500235712
              ],
              [
                -73.89118725926157,
                40.61309302069823
              ],
              [
                -73.89120383206885,
                40.61313431755282
              ],
              [
                -73.891209806409,
                40.61314732312623
              ],
              [
                -73.89121431130582,
                40.613157136717994
              ],
              [
                -73.89123107591735,
                40.61317005365965
              ],
              [
                -73.89127831370406,
                40.61320229475102
              ],
              [
                -73.89131556906169,
                40.61323899991268
              ],
              [
                -73.89134028451792,
                40.6132562074032
              ],
              [
                -73.891375801745,
                40.61327600186071
              ],
              [
                -73.89142496896234,
                40.61329674401335
              ],
              [
                -73.89149171553888,
                40.61333748560506
              ],
              [
                -73.89157666468313,
                40.613391665582824
              ],
              [
                -73.89165791434924,
                40.61344043434066
              ],
              [
                -73.891733954042,
                40.6134805256579
              ],
              [
                -73.89179484323651,
                40.613513685222244
              ],
              [
                -73.8918680042854,
                40.61355273020212
              ],
              [
                -73.89189118166033,
                40.61356392216908
              ],
              [
                -73.89196849544119,
                40.61359635711157
              ],
              [
                -73.89207536848573,
                40.613635542866696
              ],
              [
                -73.89218519034605,
                40.61367852012477
              ],
              [
                -73.89230358303553,
                40.613721942893896
              ],
              [
                -73.8924350055877,
                40.6137701290254
              ],
              [
                -73.89249926249595,
                40.61379231218374
              ],
              [
                -73.89255539456104,
                40.61380477002301
              ],
              [
                -73.89261149496224,
                40.61381451215948
              ],
              [
                -73.89267109788662,
                40.61381873930028
              ],
              [
                -73.89270552819843,
                40.613820528152864
              ],
              [
                -73.89277688473038,
                40.61383991921007
              ],
              [
                -73.89283216522163,
                40.613845268197935
              ],
              [
                -73.89291775644425,
                40.613859043965135
              ],
              [
                -73.8929960778081,
                40.61387015153529
              ],
              [
                -73.89306942949449,
                40.61388075921319
              ],
              [
                -73.89315059976579,
                40.61389022201504
              ],
              [
                -73.89320355276945,
                40.613885220727596
              ],
              [
                -73.89323713137844,
                40.613879927010046
              ],
              [
                -73.89326360875233,
                40.613877426936696
              ],
              [
                -73.89330589222904,
                40.613878041486494
              ],
              [
                -73.89337269463596,
                40.6138849116851
              ],
              [
                -73.89343222994763,
                40.61388639307022
              ],
              [
                -73.89348292372811,
                40.61387653138529
              ],
              [
                -73.89353934479138,
                40.61386604473845
              ],
              [
                -73.8935701409316,
                40.61386513944984
              ],
              [
                -73.89359452455123,
                40.61386595745186
              ],
              [
                -73.89364109666258,
                40.61386542525889
              ],
              [
                -73.8936774541801,
                40.61385571440071
              ],
              [
                -73.89371319266695,
                40.61384984490182
              ],
              [
                -73.89376473888517,
                40.61384703970086
              ],
              [
                -73.8938207402506,
                40.613851316693626
              ],
              [
                -73.89386882050955,
                40.61385405161237
              ],
              [
                -73.89391529273703,
                40.61384802845224
              ],
              [
                -73.89395164950118,
                40.61383834548399
              ],
              [
                -73.89401835332855,
                40.6138397519273
              ],
              [
                -73.8940930419325,
                40.613848165051664
              ],
              [
                -73.89415922473533,
                40.61385887683561
              ],
              [
                -73.89420959882699,
                40.61386921619111
              ],
              [
                -73.89427581408144,
                40.613882643248125
              ],
              [
                -73.89432769529049,
                40.61389625125662
              ],
              [
                -73.894399016324,
                40.613915639598204
              ],
              [
                -73.89445096385644,
                40.61393196392751
              ],
              [
                -73.8945274945732,
                40.61396003112318
              ],
              [
                -73.8946011447671,
                40.61398702511605
              ],
              [
                -73.89465257429408,
                40.6140126537761
              ],
              [
                -73.89473568611905,
                40.61404720454117
              ],
              [
                -73.89478995879112,
                40.61407387760602
              ],
              [
                -73.89487398981485,
                40.614118256029336
              ],
              [
                -73.89495144332771,
                40.614156122725774
              ],
              [
                -73.89508186256663,
                40.614225631353406
              ],
              [
                -73.8951798123067,
                40.614284596107396
              ],
              [
                -73.8952740916741,
                40.6143408404477
              ],
              [
                -73.89535161149779,
                40.61438145172896
              ],
              [
                -73.89541819432449,
                40.614414010107076
              ],
              [
                -73.89545646109381,
                40.6144266715782
              ],
              [
                -73.89547940515911,
                40.61442696685561
              ],
              [
                -73.89550221294368,
                40.61442177308392
              ],
              [
                -73.89551772066596,
                40.61440850179481
              ],
              [
                -73.89552681480382,
                40.61439692760705
              ],
              [
                -73.89552942926865,
                40.614384330796305
              ],
              [
                -73.895521927757,
                40.61436801988701
              ],
              [
                -73.89553252926262,
                40.61435971220933
              ],
              [
                -73.89553639672285,
                40.61435854486383
              ],
              [
                -73.89555890646692,
                40.614351749400186
              ],
              [
                -73.89557193332423,
                40.61435925397077
              ],
              [
                -73.89557717490436,
                40.61437065020294
              ],
              [
                -73.89557757652038,
                40.61438978120849
              ],
              [
                -73.89557732518234,
                40.61441272860194
              ],
              [
                -73.8955825661107,
                40.61442412315789
              ],
              [
                -73.89558999916949,
                40.61443768970803
              ],
              [
                -73.89561883888243,
                40.61444556547204
              ],
              [
                -73.89568501893534,
                40.61445899386163
              ],
              [
                -73.89571673713985,
                40.61446791474621
              ],
              [
                -73.89574845601021,
                40.61447683713038
              ],
              [
                -73.89581611284076,
                40.61449078712889
              ],
              [
                -73.89584505008592,
                40.61450409756555
              ],
              [
                -73.89586112894176,
                40.6145181374948
              ],
              [
                -73.89586224922375,
                40.6145388628535
              ],
              [
                -73.895866416726,
                40.614568867221756
              ],
              [
                -73.89589071515321,
                40.61460081121421
              ],
              [
                -73.8959092860416,
                40.61463338076165
              ],
              [
                -73.89591934865109,
                40.61467093865275
              ],
              [
                -73.89593641173241,
                40.61470024101983
              ],
              [
                -73.89597285228832,
                40.614729863479745
              ],
              [
                -73.89600719861568,
                40.614762776648455
              ],
              [
                -73.89602128163696,
                40.61478557232797
              ],
              [
                -73.89602881667831,
                40.614804600052004
              ],
              [
                -73.8960171760576,
                40.614831488482274
              ],
              [
                -73.89600183471937,
                40.61485296689965
              ],
              [
                -73.89598917340122,
                40.614864592846224
              ],
              [
                -73.89597637791361,
                40.61487075705691
              ],
              [
                -73.89594911474681,
                40.61486889391844
              ],
              [
                -73.89591310967744,
                40.61486109300155
              ],
              [
                -73.89589284502115,
                40.61485097332106
              ],
              [
                -73.89586898182053,
                40.614840850209816
              ],
              [
                -73.89585156740996,
                40.61482903234952
              ],
              [
                -73.89584423213397,
                40.61482092802116
              ],
              [
                -73.89582832172617,
                40.614815122628436
              ],
              [
                -73.89580527744951,
                40.61480936621944
              ],
              [
                -73.89578754392873,
                40.61481777668518
              ],
              [
                -73.89578215002605,
                40.61483476208429
              ],
              [
                -73.89577659016369,
                40.614843566723586
              ],
              [
                -73.89575106482042,
                40.614858612476155
              ],
              [
                -73.89573706509346,
                40.614875151477094
              ],
              [
                -73.8957344515297,
                40.614887747120115
              ],
              [
                -73.89575285496711,
                40.61491211130449
              ],
              [
                -73.89578507514733,
                40.61494562588269
              ],
              [
                -73.8957918237687,
                40.614960289783674
              ],
              [
                -73.8957928770522,
                40.61497829658824
              ],
              [
                -73.89579481816479,
                40.615003412620936
              ],
              [
                -73.89580772810929,
                40.61503929881661
              ],
              [
                -73.8958410010153,
                40.61509082135503
              ],
              [
                -73.8958885243663,
                40.61517329001603
              ],
              [
                -73.89590336269953,
                40.615197705225874
              ],
              [
                -73.8959266731785,
                40.61521710575265
              ],
              [
                -73.89595293093242,
                40.61524026762303
              ],
              [
                -73.89598371160828,
                40.61527323302143
              ],
              [
                -73.89599134458564,
                40.615297723453466
              ],
              [
                -73.89599489372915,
                40.61533157003519
              ],
              [
                -73.89598948311652,
                40.61538242610541
              ],
              [
                -73.8959886094387,
                40.6154119318619
              ],
              [
                -73.895987253043,
                40.61545071409956
              ],
              [
                -73.8960050700419,
                40.61548166461638
              ],
              [
                -73.89603259976462,
                40.61549717070589
              ],
              [
                -73.89606075325278,
                40.615506143051796
              ],
              [
                -73.89609054371857,
                40.61552653748974
              ],
              [
                -73.89610524671173,
                40.61554548875961
              ],
              [
                -73.89608178433468,
                40.61555449903938
              ],
              [
                -73.89606843901986,
                40.61556722169051
              ],
              [
                -73.89607175222872,
                40.61559011685286
              ],
              [
                -73.89607657418811,
                40.615616278519056
              ],
              [
                -73.89609539639181,
                40.61562590231658
              ],
              [
                -73.89611041756297,
                40.615624626422786
              ],
              [
                -73.89614423131907,
                40.61563025646679
              ],
              [
                -73.89616531514628,
                40.61564476794418
              ],
              [
                -73.89617336977584,
                40.61565449123096
              ],
              [
                -73.89618196016558,
                40.61568880892708
              ],
              [
                -73.89616825783165,
                40.61572173409067
              ],
              [
                -73.89615177996474,
                40.615756358504655
              ],
              [
                -73.89615450745923,
                40.61578581273259
              ],
              [
                -73.8961674495314,
                40.615824444713695
              ],
              [
                -73.8961761931683,
                40.61583035331735
              ],
              [
                -73.8962143578366,
                40.61584029594833
              ],
              [
                -73.89623756851961,
                40.61585423353519
              ],
              [
                -73.89624565770121,
                40.61586395634527
              ],
              [
                -73.89624255855993,
                40.615888574948116
              ],
              [
                -73.8962271845636,
                40.615907334871046
              ],
              [
                -73.89620658955951,
                40.615925350102344
              ],
              [
                -73.89617244709056,
                40.61594664688142
              ],
              [
                -73.89616498434603,
                40.61597416934122
              ],
              [
                -73.89617052459134,
                40.6160011837037
              ],
              [
                -73.8961955415309,
                40.61603436396913
              ],
              [
                -73.89622640450688,
                40.61606151042197
              ],
              [
                -73.8962363544032,
                40.61607919588967
              ],
              [
                -73.89625578586792,
                40.61609085133173
              ],
              [
                -73.89627315092359,
                40.616089274471946
              ],
              [
                -73.89627832083232,
                40.616076076680265
              ],
              [
                -73.8962900198324,
                40.61605852165433
              ],
              [
                -73.8963002241372,
                40.61605142076263
              ],
              [
                -73.89633131361525,
                40.61604826592165
              ],
              [
                -73.8963594695086,
                40.61605501491883
              ],
              [
                -73.89638390254667,
                40.61606999611067
              ],
              [
                -73.89641048978349,
                40.61608997528785
              ],
              [
                -73.8964493155107,
                40.61611605784324
              ],
              [
                -73.89646002707948,
                40.61612987389046
              ],
              [
                -73.89646281830267,
                40.6161420088535
              ],
              [
                -73.8964538748985,
                40.61617172647015
              ],
              [
                -73.89644124174151,
                40.6162124202765
              ],
              [
                -73.89644304793725,
                40.61625595380669
              ],
              [
                -73.89645129330266,
                40.6163033643969
              ],
              [
                -73.89647860282666,
                40.61632221941885
              ],
              [
                -73.89650091074138,
                40.616337773966066
              ],
              [
                -73.89651162235695,
                40.61635158984058
              ],
              [
                -73.89651297380907,
                40.616363174680906
              ],
              [
                -73.89650208809937,
                40.61636862649259
              ],
              [
                -73.89648031491386,
                40.6163795311165
              ],
              [
                -73.89647146180651,
                40.61639821518922
              ],
              [
                -73.89646687893172,
                40.61642684042371
              ],
              [
                -73.89647385439008,
                40.61645717615207
              ],
              [
                -73.89647499164603,
                40.61649077343724
              ],
              [
                -73.89647976597487,
                40.61652440147233
              ],
              [
                -73.89647877731848,
                40.61655857330994
              ],
              [
                -73.89647055156632,
                40.616589911410685
              ],
              [
                -73.89646731947157,
                40.61663011930661
              ],
              [
                -73.89645900601768,
                40.616672492460786
              ],
              [
                -73.89645289839982,
                40.616708827511644
              ],
              [
                -73.89645340049675,
                40.61673251555537
              ],
              [
                -73.89645381130968,
                40.61676998176963
              ],
              [
                -73.89647077897715,
                40.61681523238751
              ],
              [
                -73.89648639538667,
                40.61684892563744
              ],
              [
                -73.8965215956256,
                40.61695926895687
              ],
              [
                -73.89654702264502,
                40.61702991652715
              ],
              [
                -73.89657411018266,
                40.61707632732681
              ],
              [
                -73.8966012956822,
                40.61710621516143
              ],
              [
                -73.89662424028306,
                40.61712890782723
              ],
              [
                -73.89663050089752,
                40.61715756911899
              ],
              [
                -73.89663729815841,
                40.6172099447852
              ],
              [
                -73.89664253106987,
                40.617272778379835
              ],
              [
                -73.89664335225238,
                40.61734771314745
              ],
              [
                -73.89662829741033,
                40.617416484153516
              ],
              [
                -73.89661900303167,
                40.61748750941414
              ],
              [
                -73.89660510673086,
                40.61750561366508
              ],
              [
                -73.8965794728729,
                40.61754404520301
              ],
              [
                -73.8965560811242,
                40.617576440171455
              ],
              [
                -73.89654641678631,
                40.61760722748477
              ],
              [
                -73.8965316687724,
                40.61764020719645
              ],
              [
                -73.89652920407661,
                40.61767380073216
              ],
              [
                -73.89654715229243,
                40.61769042285876
              ],
              [
                -73.89653978363197,
                40.617704167673814
              ],
              [
                -73.89649856930203,
                40.617708906488446
              ],
              [
                -73.89647607844687,
                40.61771816415723
              ],
              [
                -73.89645130037977,
                40.61773897490345
              ],
              [
                -73.89643947672181,
                40.61776756446039
              ],
              [
                -73.89644505819918,
                40.61779183454971
              ],
              [
                -73.89646516413855,
                40.61781065342933
              ],
              [
                -73.89648375473702,
                40.61783169522281
              ],
              [
                -73.89647498570086,
                40.61784211868376
              ],
              [
                -73.89645032769116,
                40.61785466717717
              ],
              [
                -73.89643071664314,
                40.61786502474569
              ],
              [
                -73.89640301407015,
                40.617890224450385
              ],
              [
                -73.89639712615588,
                40.61790177411838
              ],
              [
                -73.89640262071784,
                40.61793430548626
              ],
              [
                -73.89642471559748,
                40.617971874162514
              ],
              [
                -73.89641441392176,
                40.617995497016445
              ],
              [
                -73.89640785140439,
                40.617999883376804
              ],
              [
                -73.89642638670509,
                40.61803193178124
              ],
              [
                -73.89645145560387,
                40.6180568496699
              ],
              [
                -73.89645347974444,
                40.61807557101044
              ],
              [
                -73.89643882054831,
                40.61809754413676
              ],
              [
                -73.89642073010339,
                40.618102961974365
              ],
              [
                -73.89640197406328,
                40.618095698273144
              ],
              [
                -73.89637957358076,
                40.618093949352385
              ],
              [
                -73.89635415386694,
                40.61811034092256
              ],
              [
                -73.89634089502,
                40.618135607912954
              ],
              [
                -73.89633851449184,
                40.61816093966126
              ],
              [
                -73.89632614336392,
                40.61817135818712
              ],
              [
                -73.89631961692115,
                40.6181757176027
              ],
              [
                -73.89630432968093,
                40.61818500820401
              ],
              [
                -73.8962576889639,
                40.618227758543576
              ],
              [
                -73.89619952947552,
                40.61826602225771
              ],
              [
                -73.89616025744242,
                40.61829502726245
              ],
              [
                -73.89611869448018,
                40.618338329449024
              ],
              [
                -73.89608357745955,
                40.61838279138693
              ],
              [
                -73.89607322875578,
                40.61841193123013
              ],
              [
                -73.89606127673136,
                40.61845430055603
              ],
              [
                -73.89606105380388,
                40.618481831223356
              ],
              [
                -73.89606087614197,
                40.61850387066773
              ],
              [
                -73.8960490075756,
                40.61853795023287
              ],
              [
                -73.89603565085702,
                40.61857976879041
              ],
              [
                -73.89601991843259,
                40.618644147492475
              ],
              [
                -73.89601372545526,
                40.61868871732069
              ],
              [
                -73.89601480773337,
                40.61873334867912
              ],
              [
                -73.89601458972035,
                40.61875813505843
              ],
              [
                -73.8960086507563,
                40.61877794651656
              ],
              [
                -73.89599673768316,
                40.618817542595544
              ],
              [
                -73.89598707714167,
                40.61884561254246
              ],
              [
                -73.89597444319298,
                40.618886306956284
              ],
              [
                -73.89594745359078,
                40.61891592744519
              ],
              [
                -73.89592275902997,
                40.61892847562392
              ],
              [
                -73.89590229663737,
                40.61895370923521
              ],
              [
                -73.89589203704357,
                40.618971842992835
              ],
              [
                -73.89589974097906,
                40.61899830982437
              ],
              [
                -73.89590091378314,
                40.619031934110915
              ],
              [
                -73.8958751941522,
                40.61907862827095
              ],
              [
                -73.89586628685244,
                40.61910831772148
              ],
              [
                -73.89585293851253,
                40.61914461987047
              ],
              [
                -73.89582666510604,
                40.619175886113155
              ],
              [
                -73.89580103532275,
                40.61921157268145
              ],
              [
                -73.8957637468164,
                40.61926207163293
              ],
              [
                -73.89574031871707,
                40.61929443842012
              ],
              [
                -73.89572065812946,
                40.61931308515289
              ],
              [
                -73.89570104654027,
                40.619323442260274
              ],
              [
                -73.8956786407143,
                40.61932443799254
              ],
              [
                -73.89563743758063,
                40.61932091465214
              ],
              [
                -73.89562799133904,
                40.61932694465632
              ],
              [
                -73.89561044677644,
                40.619350533384385
              ],
              [
                -73.89561243615934,
                40.61936928268317
              ],
              [
                -73.89562440512043,
                40.61940846131175
              ],
              [
                -73.89564087338148,
                40.61942727803455
              ],
              [
                -73.89566886189472,
                40.6194505529362
              ],
              [
                -73.89569612286115,
                40.61947766877681
              ],
              [
                -73.89571326932347,
                40.61950090467971
              ],
              [
                -73.8957167412086,
                40.619516004195766
              ],
              [
                -73.89571934602988,
                40.619529950158764
              ],
              [
                -73.89571166246276,
                40.61956010794418
              ],
              [
                -73.89569398800683,
                40.61959898559129
              ],
              [
                -73.89564527067563,
                40.61970151431145
              ],
              [
                -73.89561842775316,
                40.619752599162155
              ],
              [
                -73.89561388501926,
                40.6198012871506
              ],
              [
                -73.89564146603549,
                40.619854534287015
              ],
              [
                -73.89565708473634,
                40.619863742954344
              ],
              [
                -73.89567436959614,
                40.61986727138822
              ],
              [
                -73.89569532006122,
                40.61987618467693
              ],
              [
                -73.89570509771258,
                40.61988942230158
              ],
              [
                -73.8957091110666,
                40.61990202461987
              ],
              [
                -73.89570116683534,
                40.61991549427644
              ],
              [
                -73.89568213618082,
                40.61992231269128
              ],
              [
                -73.89566550350563,
                40.619939533809564
              ],
              [
                -73.89567985869817,
                40.61995058146446
              ],
              [
                -73.89569880676578,
                40.61995004916278
              ],
              [
                -73.89570835936625,
                40.61994508975929
              ],
              [
                -73.89572773952914,
                40.61994549215357
              ],
              [
                -73.89574043818135,
                40.61995593385402
              ],
              [
                -73.89576287108352,
                40.61998433476828
              ],
              [
                -73.89577740344706,
                40.62001992053215
              ],
              [
                -73.89577962782305,
                40.62004915399918
              ],
              [
                -73.89576993937993,
                40.62007140662517
              ],
              [
                -73.89574632647593,
                40.620083544127105
              ],
              [
                -73.89573667625618,
                40.620104258534475
              ],
              [
                -73.8957467992111,
                40.62012630899182
              ],
              [
                -73.89574243647453,
                40.62015298384748
              ],
              [
                -73.89572611095683,
                40.62018058165857
              ],
              [
                -73.89571728892822,
                40.62020157189784
              ],
              [
                -73.8957180308928,
                40.620211316554965
              ],
              [
                -73.89572932995614,
                40.620240915258115
              ],
              [
                -73.8958158246942,
                40.620351664785574
              ],
              [
                -73.89585305268275,
                40.62038737966316
              ],
              [
                -73.8958764463905,
                40.62040038434476
              ],
              [
                -73.89590647248616,
                40.62040963350072
              ],
              [
                -73.89594222035427,
                40.6204242686892
              ],
              [
                -73.89596971501871,
                40.62044043297345
              ],
              [
                -73.89599175101233,
                40.62046946540125
              ],
              [
                -73.89600274852945,
                40.62048555980104
              ],
              [
                -73.89602082120945,
                40.62049254685933
              ],
              [
                -73.89603159920458,
                40.620487259678434
              ],
              [
                -73.89605303119886,
                40.620487691348096
              ],
              [
                -73.89607184210558,
                40.62050601557943
              ],
              [
                -73.89608070054213,
                40.62053149635783
              ],
              [
                -73.89608043035841,
                40.620566079943536
              ],
              [
                -73.89607523203932,
                40.62059558177544
              ],
              [
                -73.89607842200743,
                40.6206062889185
              ],
              [
                -73.89608370284704,
                40.620615433734294
              ],
              [
                -73.89610054469308,
                40.620625880311806
              ],
              [
                -73.89612551516502,
                40.62064423757571
              ],
              [
                -73.89620462845743,
                40.6208002132736
              ],
              [
                -73.89621383518804,
                40.620832939477786
              ],
              [
                -73.8962224331226,
                40.62084022100947
              ],
              [
                -73.89623478359779,
                40.62084341604472
              ],
              [
                -73.89625097822167,
                40.62082998177605
              ],
              [
                -73.89626695075945,
                40.62079675560789
              ],
              [
                -73.89627175922178,
                40.62076313622585
              ],
              [
                -73.89627652049418,
                40.62073582929
              ],
              [
                -73.8962790496671,
                40.62073050834114
              ],
              [
                -73.89628847802915,
                40.620736499974626
              ],
              [
                -73.89630104744656,
                40.620761077751766
              ],
              [
                -73.8963058886522,
                40.62077557353618
              ],
              [
                -73.89631373620297,
                40.62082432867684
              ],
              [
                -73.89631753490582,
                40.62090700527002
              ],
              [
                -73.89631329635806,
                40.62096919789126
              ],
              [
                -73.89630687672837,
                40.620997464612906
              ],
              [
                -73.89630054609012,
                40.62101472309059
              ],
              [
                -73.89630006047612,
                40.62107129230283
              ],
              [
                -73.89636674052934,
                40.621231895372894
              ],
              [
                -73.89644123346895,
                40.62139475619014
              ],
              [
                -73.89645921169515,
                40.6214159073261
              ],
              [
                -73.89647475412445,
                40.621428246088904
              ],
              [
                -73.89651464215949,
                40.62144447618244
              ],
              [
                -73.89657304085416,
                40.62146236989354
              ],
              [
                -73.89661882635251,
                40.62146194501101
              ],
              [
                -73.89666488195714,
                40.6214773501577
              ],
              [
                -73.89675081965714,
                40.621514322259834
              ],
              [
                -73.89680032629262,
                40.62157109835966
              ],
              [
                -73.89684302276225,
                40.621620064831596
              ],
              [
                -73.89692451062226,
                40.621655116447066
              ],
              [
                -73.89699450437195,
                40.62167351002614
              ],
              [
                -73.89705223967074,
                40.62168396562138
              ],
              [
                -73.89739897897785,
                40.621682061709464
              ],
              [
                -73.89744594264127,
                40.621687647982554
              ],
              [
                -73.89748094470643,
                40.6216956671465
              ],
              [
                -73.89750149948756,
                40.62170520982685
              ],
              [
                -73.89757314558577,
                40.62174460494207
              ],
              [
                -73.89757415829762,
                40.62174516137009
              ],
              [
                -73.89758135050047,
                40.62174712652558
              ],
              [
                -73.89758984528348,
                40.621749446829334
              ],
              [
                -73.8976223342305,
                40.62176228737035
              ],
              [
                -73.89763690014603,
                40.62176657182498
              ],
              [
                -73.89772094578028,
                40.6217854380091
              ],
              [
                -73.8978722356107,
                40.621813760416316
              ],
              [
                -73.89796188892215,
                40.62183006888401
              ],
              [
                -73.89800838382129,
                40.62183749146526
              ],
              [
                -73.89804258216508,
                40.621842952081394
              ],
              [
                -73.89810966198586,
                40.621850642661855
              ],
              [
                -73.89811767568024,
                40.621851562126416
              ],
              [
                -73.89818301655687,
                40.62184257697343
              ],
              [
                -73.8982228118341,
                40.621839759865054
              ],
              [
                -73.89826260368,
                40.6218367395273
              ],
              [
                -73.89830237647831,
                40.62183351996709
              ],
              [
                -73.89834211373747,
                40.62183010167243
              ],
              [
                -73.89838180270591,
                40.62182648446468
              ],
              [
                -73.89842324306126,
                40.621817400145325
              ],
              [
                -73.8984643680204,
                40.62180751574971
              ],
              [
                -73.89850515009056,
                40.62179683661469
              ],
              [
                -73.89854556221432,
                40.621785371595784
              ],
              [
                -73.89855118164952,
                40.621783512293526
              ],
              [
                -73.89855704742023,
                40.62178217254793
              ],
              [
                -73.89856308232926,
                40.62178136921108
              ],
              [
                -73.8985691977505,
                40.621781116612425
              ],
              [
                -73.8985753103544,
                40.62178141568349
              ],
              [
                -73.89858133351967,
                40.621782263667605
              ],
              [
                -73.89858718283976,
                40.621783647255306
              ],
              [
                -73.89859277633204,
                40.62178554895142
              ],
              [
                -73.89859803554845,
                40.62178794087705
              ],
              [
                -73.89860288776403,
                40.62179079046724
              ],
              [
                -73.89860726224767,
                40.621794055107635
              ],
              [
                -73.89861109970154,
                40.62179769202599
              ],
              [
                -73.89861434721522,
                40.62180165074945
              ],
              [
                -73.89861695826836,
                40.62180587176444
              ],
              [
                -73.898630952885,
                40.62181012258581
              ],
              [
                -73.89864520775548,
                40.62181383737513
              ],
              [
                -73.8986596896992,
                40.62181700337063
              ],
              [
                -73.89867436046924,
                40.62181961450763
              ],
              [
                -73.89868918489748,
                40.621821664221294
              ],
              [
                -73.89870412473198,
                40.62182314912738
              ],
              [
                -73.89871914172656,
                40.62182406265846
              ],
              [
                -73.89873420070202,
                40.6218244052862
              ],
              [
                -73.8987492634061,
                40.62182417429647
              ],
              [
                -73.89876429026145,
                40.621823370659314
              ],
              [
                -73.89877924586729,
                40.62182199601911
              ],
              [
                -73.89879271426739,
                40.62181833698411
              ],
              [
                -73.89880643066871,
                40.621815259658646
              ],
              [
                -73.89882035196477,
                40.621812773051154
              ],
              [
                -73.89883443439226,
                40.62181088432671
              ],
              [
                -73.89884863352947,
                40.62180960014755
              ],
              [
                -73.8988629051774,
                40.62180892483031
              ],
              [
                -73.89887720250371,
                40.62180886000913
              ],
              [
                -73.89889148527308,
                40.62180940615119
              ],
              [
                -73.8989057042379,
                40.62181056220801
              ],
              [
                -73.89891981455663,
                40.6218123219417
              ],
              [
                -73.8989337746791,
                40.621814682635105
              ],
              [
                -73.89894753866744,
                40.621817636039175
              ],
              [
                -73.89896106322364,
                40.621821172231535
              ],
              [
                -73.89897430878847,
                40.62182528079094
              ],
              [
                -73.89907849548186,
                40.62180337877469
              ],
              [
                -73.8991827974815,
                40.621781802943936
              ],
              [
                -73.89928720972922,
                40.621760555304206
              ],
              [
                -73.89930329567727,
                40.62175827039643
              ],
              [
                -73.89931950127078,
                40.621756541117115
              ],
              [
                -73.89933579374747,
                40.621755369615045
              ],
              [
                -73.89935213792377,
                40.62175475971284
              ],
              [
                -73.89936850279855,
                40.621754712053374
              ],
              [
                -73.89938485407163,
                40.621755228114274
              ],
              [
                -73.89940115832995,
                40.62175630401324
              ],
              [
                -73.89941738171501,
                40.621757939888234
              ],
              [
                -73.89943349257463,
                40.62176013068558
              ],
              [
                -73.89944945771525,
                40.62176287285836
              ],
              [
                -73.89946524438575,
                40.621766161184446
              ],
              [
                -73.89948081895608,
                40.62176999010625
              ],
              [
                -73.89949615483923,
                40.62177434854341
              ],
              [
                -73.89951121357092,
                40.621779229258955
              ],
              [
                -73.89952597097867,
                40.62178462419038
              ],
              [
                -73.89954039322573,
                40.62179051890058
              ],
              [
                -73.89955445196465,
                40.62179690348092
              ],
              [
                -73.89956811841279,
                40.621803765341745
              ],
              [
                -73.89959737567133,
                40.621802942196254
              ],
              [
                -73.89962659403483,
                40.62180152009362
              ],
              [
                -73.89965575349534,
                40.62179949851381
              ],
              [
                -73.89968483030535,
                40.62179687894403
              ],
              [
                -73.89971380554923,
                40.621793665388324
              ],
              [
                -73.89974265811907,
                40.62178985833123
              ],
              [
                -73.89977136294782,
                40.621785459258405
              ],
              [
                -73.89979990441465,
                40.621780474355056
              ],
              [
                -73.89982825789053,
                40.621774906280386
              ],
              [
                -73.89985640556002,
                40.621768758872115
              ],
              [
                -73.89988432499028,
                40.621762036466635
              ],
              [
                -73.89991199748695,
                40.621754742398316
              ],
              [
                -73.899939401271,
                40.62174688485744
              ],
              [
                -73.89996651632606,
                40.621738469355165
              ],
              [
                -73.89999332307809,
                40.62172949956019
              ],
              [
                -73.90001980348657,
                40.62171998333111
              ],
              [
                -73.900045936871,
                40.62170992885898
              ],
              [
                -73.90006989359242,
                40.62170297076788
              ],
              [
                -73.90009355346207,
                40.621695447003816
              ],
              [
                -73.90011689338732,
                40.62168736190277
              ],
              [
                -73.90016300923918,
                40.62167709474681
              ],
              [
                -73.9002747811528,
                40.62163566135085
              ],
              [
                -73.9002836790366,
                40.621614277809286
              ],
              [
                -73.90032359224014,
                40.62160080255894
              ],
              [
                -73.90035318038561,
                40.62157605923211
              ],
              [
                -73.90041231877402,
                40.62155134200269
              ],
              [
                -73.90042112502233,
                40.62159075443377
              ],
              [
                -73.90046101789774,
                40.62159191427346
              ],
              [
                -73.90048169037065,
                40.62160093952846
              ],
              [
                -73.9005024221681,
                40.62156943356443
              ],
              [
                -73.90051418685034,
                40.621607722586994
              ],
              [
                -73.90050676271674,
                40.62163135867499
              ],
              [
                -73.90052594431344,
                40.62164938948678
              ],
              [
                -73.90057471065897,
                40.62164492849703
              ],
              [
                -73.9006205611866,
                40.62161344473578
              ],
              [
                -73.90071952540437,
                40.62163492049855
              ],
              [
                -73.90075776586589,
                40.62175316892799
              ],
              [
                -73.90074590109352,
                40.62178355612915
              ],
              [
                -73.90082851794222,
                40.62186806717416
              ],
              [
                -73.9008240556225,
                40.621888328119624
              ],
              [
                -73.90089196289041,
                40.62192891794758
              ],
              [
                -73.90092435763049,
                40.622004377755005
              ],
              [
                -73.9008858937174,
                40.62203586850725
              ],
              [
                -73.90088142638618,
                40.622059508340136
              ],
              [
                -73.90086511381486,
                40.62209889907488
              ],
              [
                -73.90085618850341,
                40.62213942146962
              ],
              [
                -73.9008162734123,
                40.622154022864166
              ],
              [
                -73.90084869383492,
                40.62221146967446
              ],
              [
                -73.90084422502528,
                40.62223623596836
              ],
              [
                -73.90089885737537,
                40.62226105154894
              ],
              [
                -73.90092994243166,
                40.62228593292351
              ],
              [
                -73.90094270330684,
                40.62228625853888
              ],
              [
                -73.90094433639536,
                40.62229504164157
              ],
              [
                -73.90093689110948,
                40.62231950013653
              ],
              [
                -73.90092000809851,
                40.62232324979319
              ],
              [
                -73.90091875871514,
                40.622332657807604
              ],
              [
                -73.90095657102668,
                40.62237189650028
              ],
              [
                -73.90095693119575,
                40.6224063988774
              ],
              [
                -73.9009385133859,
                40.622422082640874
              ],
              [
                -73.90094131085958,
                40.622527914876436
              ],
              [
                -73.90100332746997,
                40.62255611565839
              ],
              [
                -73.9013517044865,
                40.6227793340492
              ],
              [
                -73.90139898058246,
                40.62278387711192
              ],
              [
                -73.90142702629066,
                40.6228030411669
              ],
              [
                -73.90145658297767,
                40.622799688789115
              ],
              [
                -73.90146543654427,
                40.622807577772804
              ],
              [
                -73.90149057503622,
                40.62279408828156
              ],
              [
                -73.90151570542957,
                40.6227862295903
              ],
              [
                -73.90156294269181,
                40.62281666748739
              ],
              [
                -73.90158064619611,
                40.62283582264878
              ],
              [
                -73.90161018895355,
                40.622841476905876
              ],
              [
                -73.9016116367975,
                40.622861744110466
              ],
              [
                -73.90163675271114,
                40.622864016855246
              ],
              [
                -73.90164559635443,
                40.622878660244865
              ],
              [
                -73.90167662514698,
                40.6228786867867
              ],
              [
                -73.90169728160188,
                40.62289896974676
              ],
              [
                -73.90174161518455,
                40.622894505141474
              ],
              [
                -73.90176672305017,
                40.622902406820494
              ],
              [
                -73.90178890563845,
                40.6228889154084
              ],
              [
                -73.90181844313123,
                40.622899073285616
              ],
              [
                -73.90184060318614,
                40.62290134463416
              ],
              [
                -73.90189823888298,
                40.622894638900085
              ],
              [
                -73.90193219544054,
                40.622913806702435
              ],
              [
                -73.90196913303357,
                40.622914964167045
              ],
              [
                -73.90197796683574,
                40.62293636295585
              ],
              [
                -73.90199713928754,
                40.622961147771974
              ],
              [
                -73.90202817351549,
                40.622957797009555
              ],
              [
                -73.90205918813636,
                40.62296795608351
              ],
              [
                -73.9020901880153,
                40.622987121990164
              ],
              [
                -73.90206356590346,
                40.62300511285368
              ],
              [
                -73.90207683368587,
                40.62302538945052
              ],
              [
                -73.9021033754338,
                40.62306369206218
              ],
              [
                -73.9021638700949,
                40.62312228718644
              ],
              [
                -73.90219931622605,
                40.62313245016115
              ],
              [
                -73.90221554084374,
                40.623152729257036
              ],
              [
                -73.90219926673512,
                40.62316622577746
              ],
              [
                -73.90222431373307,
                40.62321578452483
              ],
              [
                -73.90227302978802,
                40.62324622405617
              ],
              [
                -73.90229070515271,
                40.6232845179135
              ],
              [
                -73.90232022224446,
                40.62330818667751
              ],
              [
                -73.90233347621846,
                40.62333859537672
              ],
              [
                -73.90234523676943,
                40.62337913612289
              ],
              [
                -73.90234516923223,
                40.62342529629276
              ],
              [
                -73.90236135937828,
                40.62346921851605
              ],
              [
                -73.90245738131404,
                40.62348393635161
              ],
              [
                -73.90251801478948,
                40.623447960200764
              ],
              [
                -73.90254016047618,
                40.62346036287619
              ],
              [
                -73.90258004442525,
                40.623468278074846
              ],
              [
                -73.90258451617723,
                40.62344126163986
              ],
              [
                -73.90263183552673,
                40.62341653325734
              ],
              [
                -73.9026746709802,
                40.62342670167294
              ],
              [
                -73.90271602565338,
                40.6234391205746
              ],
              [
                -73.90273527475455,
                40.62341099067618
              ],
              [
                -73.90276039965381,
                40.623406508922486
              ],
              [
                -73.90278104436183,
                40.6234357983618
              ],
              [
                -73.90280760743192,
                40.62345833803818
              ],
              [
                -73.90284160059183,
                40.623452737299786
              ],
              [
                -73.90294328774249,
                40.6236363383035
              ],
              [
                -73.90293596678084,
                40.62369363569648
              ],
              [
                -73.90294467424503,
                40.62370694265951
              ],
              [
                -73.90295209682361,
                40.62374684608528
              ],
              [
                -73.9029246363893,
                40.62376914628601
              ],
              [
                -73.90292835977928,
                40.62378054859443
              ],
              [
                -73.90295453165464,
                40.62378722024456
              ],
              [
                -73.9029594628466,
                40.623824747347705
              ],
              [
                -73.90293442885333,
                40.623894071759224
              ],
              [
                -73.90291133815845,
                40.62391115176173
              ],
              [
                -73.9028968143141,
                40.62392696594419
              ],
              [
                -73.90288823724383,
                40.62393630530045
              ],
              [
                -73.90291619574111,
                40.62395453125644
              ],
              [
                -73.90288181620222,
                40.62393885251318
              ],
              [
                -73.90275168375602,
                40.62388298807522
              ],
              [
                -73.90273926215669,
                40.62389978674032
              ],
              [
                -73.90286800877112,
                40.62395505763929
              ],
              [
                -73.9028681966426,
                40.62395513804433
              ],
              [
                -73.90287130277893,
                40.62395647235533
              ],
              [
                -73.9028743526672,
                40.623957780316665
              ],
              [
                -73.90287492374995,
                40.62395802673141
              ],
              [
                -73.90284816261747,
                40.623994217822315
              ],
              [
                -73.90284448473464,
                40.62399263877051
              ],
              [
                -73.90283958140391,
                40.623990533647586
              ],
              [
                -73.90283456578874,
                40.62398838135388
              ],
              [
                -73.90283449085966,
                40.62398834946012
              ],
              [
                -73.90271236759573,
                40.623935921062156
              ],
              [
                -73.90270209085729,
                40.62394981859844
              ],
              [
                -73.90264355554693,
                40.62392468930722
              ],
              [
                -73.90263558802576,
                40.623935463542715
              ],
              [
                -73.90274531019497,
                40.62398256701517
              ],
              [
                -73.90271937115764,
                40.62401764604501
              ],
              [
                -73.90262364456693,
                40.623976551265365
              ],
              [
                -73.90261908735015,
                40.62398271330393
              ],
              [
                -73.90271369573058,
                40.624023326835896
              ],
              [
                -73.9024038097296,
                40.62444239705086
              ],
              [
                -73.90229694049818,
                40.624396518807785
              ],
              [
                -73.9022870793228,
                40.624409853930224
              ],
              [
                -73.90241543050294,
                40.62446290725005
              ],
              [
                -73.90271898828888,
                40.62405239371349
              ],
              [
                -73.90275548257644,
                40.624068060054036
              ],
              [
                -73.9027862895168,
                40.624039182861765
              ],
              [
                -73.9028326222324,
                40.62405099444945
              ],
              [
                -73.90269333271974,
                40.62421333487328
              ],
              [
                -73.90288536052951,
                40.624310565122265
              ],
              [
                -73.90302283562494,
                40.62436795022767
              ],
              [
                -73.9032704594402,
                40.62448418394103
              ],
              [
                -73.90320334369764,
                40.62457058890887
              ],
              [
                -73.90317372279071,
                40.624555741015534
              ],
              [
                -73.90313231786656,
                40.62460225619683
              ],
              [
                -73.90263059517203,
                40.624349364751005
              ],
              [
                -73.90254656112614,
                40.62444423916955
              ],
              [
                -73.90255865671568,
                40.624448888127624
              ],
              [
                -73.90262747173354,
                40.62437012914627
              ],
              [
                -73.9026992455198,
                40.624406056662714
              ],
              [
                -73.90271162732654,
                40.62441225448336
              ],
              [
                -73.9027845026159,
                40.62444873287562
              ],
              [
                -73.90279838836062,
                40.62445568399558
              ],
              [
                -73.90286791182726,
                40.624490484715125
              ],
              [
                -73.90288263908505,
                40.62449785653051
              ],
              [
                -73.9029303690017,
                40.62452174741624
              ],
              [
                -73.90296568765461,
                40.624539426225304
              ],
              [
                -73.9030375683653,
                40.624575406893996
              ],
              [
                -73.90312436585704,
                40.62461885378027
              ],
              [
                -73.90305433084016,
                40.62469945601756
              ],
              [
                -73.90307005655593,
                40.6247054984719
              ],
              [
                -73.90315226124025,
                40.62461338719581
              ],
              [
                -73.90320848278458,
                40.62464076938308
              ],
              [
                -73.90312443792637,
                40.62473795326122
              ],
              [
                -73.9031407632169,
                40.624745381674416
              ],
              [
                -73.90320897829281,
                40.62466523688162
              ],
              [
                -73.90327787444245,
                40.6247000472108
              ],
              [
                -73.90329059682475,
                40.624706475774936
              ],
              [
                -73.90335985536355,
                40.62474146930103
              ],
              [
                -73.90342223545693,
                40.6247729884905
              ],
              [
                -73.90344089448031,
                40.6247824166026
              ],
              [
                -73.90345432810342,
                40.624789203084916
              ],
              [
                -73.90352248737133,
                40.62482364324692
              ],
              [
                -73.9035373680356,
                40.62483116052178
              ],
              [
                -73.90360313362555,
                40.624864389236954
              ],
              [
                -73.90361911089087,
                40.62487246194099
              ],
              [
                -73.9036874294035,
                40.624906980541795
              ],
              [
                -73.90369982053774,
                40.624913241924276
              ],
              [
                -73.90377017455431,
                40.624948788797305
              ],
              [
                -73.90378061751059,
                40.62495406481113
              ],
              [
                -73.90385806241612,
                40.62499319514218
              ],
              [
                -73.90386956154683,
                40.62499900561048
              ],
              [
                -73.90395484264204,
                40.62504209326003
              ],
              [
                -73.90396618557332,
                40.625047824514525
              ],
              [
                -73.90404776615199,
                40.62508904306778
              ],
              [
                -73.90406470521154,
                40.62509760234598
              ],
              [
                -73.90414446601987,
                40.6251379009188
              ],
              [
                -73.90416026610586,
                40.62514588444185
              ],
              [
                -73.9042399340885,
                40.625186137303
              ],
              [
                -73.90425570849342,
                40.6251941060489
              ],
              [
                -73.90433438062468,
                40.62523385509352
              ],
              [
                -73.90435248995925,
                40.625243005339264
              ],
              [
                -73.90443316820429,
                40.6252837683614
              ],
              [
                -73.90444812311598,
                40.625291324111274
              ],
              [
                -73.90452861581954,
                40.625331992425714
              ],
              [
                -73.90454359250501,
                40.62533955907061
              ],
              [
                -73.90462553017346,
                40.62538095877455
              ],
              [
                -73.90463581485052,
                40.62538615433295
              ],
              [
                -73.90471864600123,
                40.625428003688626
              ],
              [
                -73.90473668710962,
                40.62543711930621
              ],
              [
                -73.9048175268759,
                40.62547796344272
              ],
              [
                -73.90483360089087,
                40.62548608430569
              ],
              [
                -73.90491413580976,
                40.62552677382754
              ],
              [
                -73.90492895768172,
                40.62553426239311
              ],
              [
                -73.90500969727343,
                40.62557505521469
              ],
              [
                -73.9050236675441,
                40.62558211318544
              ],
              [
                -73.90510413545952,
                40.62562276968268
              ],
              [
                -73.90512160694796,
                40.62563159594965
              ],
              [
                -73.90520180604905,
                40.6256721159555
              ],
              [
                -73.90521440773834,
                40.625678482723266
              ],
              [
                -73.90529638710873,
                40.62571990107963
              ],
              [
                -73.90531020209858,
                40.625726880986626
              ],
              [
                -73.90539726701536,
                40.62576726762869
              ],
              [
                -73.90531870381365,
                40.6258584656061
              ],
              [
                -73.90532958044659,
                40.62586495608443
              ],
              [
                -73.9054129826676,
                40.625775618132344
              ],
              [
                -73.90544562242668,
                40.62579278066394
              ],
              [
                -73.90546206137252,
                40.62577481968345
              ],
              [
                -73.90500868576218,
                40.62554514495986
              ],
              [
                -73.9042953851381,
                40.6251832322892
              ],
              [
                -73.9038667886285,
                40.62496884844075
              ],
              [
                -73.90387959925984,
                40.6249494946642
              ],
              [
                -73.90385540661482,
                40.62494019869969
              ],
              [
                -73.90396106711259,
                40.624833615554124
              ],
              [
                -73.90464209220775,
                40.62513319188153
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0010521217443,
        "objectid": 156,
        "shape_leng": 0.144476890476,
        "location_id": 156,
        "zone": "Mariners Harbor",
        "borough": "Staten Island"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -74.17989568340026,
                40.64526879647802
              ],
              [
                -74.17996507274022,
                40.645266982302964
              ],
              [
                -74.17993656012413,
                40.64465489915892
              ],
              [
                -74.1799240477021,
                40.644655012049334
              ],
              [
                -74.17990671455671,
                40.64437872166939
              ],
              [
                -74.1800477217942,
                40.64437168072984
              ],
              [
                -74.18004077949224,
                40.644288140673666
              ],
              [
                -74.18048173953655,
                40.64427892477651
              ],
              [
                -74.18048997769914,
                40.64435989958376
              ],
              [
                -74.18049917835953,
                40.644427145743954
              ],
              [
                -74.18050323923352,
                40.64445745168035
              ],
              [
                -74.18052719090944,
                40.644484695631874
              ],
              [
                -74.18058154148198,
                40.64450664166579
              ],
              [
                -74.18060507292968,
                40.64451203666371
              ],
              [
                -74.18062914205834,
                40.64451529314595
              ],
              [
                -74.18067579790016,
                40.644527395171814
              ],
              [
                -74.18071298324575,
                40.64453771481313
              ],
              [
                -74.18075192718292,
                40.644555000705274
              ],
              [
                -74.18076994300719,
                40.64456451716834
              ],
              [
                -74.18078836640565,
                40.64457793875232
              ],
              [
                -74.1807928938142,
                40.64458669958499
              ],
              [
                -74.18079999363216,
                40.644610237599075
              ],
              [
                -74.18080328559822,
                40.64462693285812
              ],
              [
                -74.18080491630866,
                40.64464254933104
              ],
              [
                -74.18080510372455,
                40.64467397072121
              ],
              [
                -74.18080699545725,
                40.64469703728795
              ],
              [
                -74.18080780137124,
                40.64472449263601
              ],
              [
                -74.18080739951004,
                40.644745339367674
              ],
              [
                -74.18080512854887,
                40.64477298132178
              ],
              [
                -74.18080183217685,
                40.644800684193044
              ],
              [
                -74.1808068653847,
                40.64481923762298
              ],
              [
                -74.1808286272017,
                40.64483992218444
              ],
              [
                -74.1808501901788,
                40.64484883028891
              ],
              [
                -74.18087212513211,
                40.644851429678305
              ],
              [
                -74.18089420751467,
                40.64485048476193
              ],
              [
                -74.18091579948613,
                40.64484485483489
              ],
              [
                -74.18094445062565,
                40.64482779470519
              ],
              [
                -74.180963096418,
                40.644813698818204
              ],
              [
                -74.18097651497148,
                40.64479402725504
              ],
              [
                -74.18099528793684,
                40.64477127964499
              ],
              [
                -74.18100704872606,
                40.644750528619284
              ],
              [
                -74.1810329091121,
                40.64470181345178
              ],
              [
                -74.18104239123718,
                40.64468395135612
              ],
              [
                -74.18106508249205,
                40.64465428616894
              ],
              [
                -74.18108652313572,
                40.64462744898819
              ],
              [
                -74.18111257031259,
                40.6446101536854
              ],
              [
                -74.18113469914123,
                40.64459977477268
              ],
              [
                -74.18116006545075,
                40.644590773035006
              ],
              [
                -74.18118434243927,
                40.64458615924493
              ],
              [
                -74.18121659349188,
                40.6445838111074
              ],
              [
                -74.18124435360966,
                40.64458291363371
              ],
              [
                -74.18127078096558,
                40.64458406327712
              ],
              [
                -74.18129509360917,
                40.6445896616227
              ],
              [
                -74.18133327279313,
                40.64459991030747
              ],
              [
                -74.18138106656384,
                40.64461274101333
              ],
              [
                -74.18141652844002,
                40.644626306836486
              ],
              [
                -74.18144993953202,
                40.64463999952074
              ],
              [
                -74.18149439873258,
                40.64465577121395
              ],
              [
                -74.18154423129559,
                40.64467357440473
              ],
              [
                -74.18159074200629,
                40.64468922136378
              ],
              [
                -74.18162706434607,
                40.64470116425299
              ],
              [
                -74.18166763350055,
                40.644709314273186
              ],
              [
                -74.18171591587041,
                40.64471228249049
              ],
              [
                -74.18174935519656,
                40.64471143304819
              ],
              [
                -74.18179397192914,
                40.64470401421466
              ],
              [
                -74.18184219957065,
                40.64469166162957
              ],
              [
                -74.18186789280165,
                40.644685782289194
              ],
              [
                -74.18189998192533,
                40.64468187234611
              ],
              [
                -74.18192823190024,
                40.64468566155351
              ],
              [
                -74.18195246178735,
                40.64469047820206
              ],
              [
                -74.18198616489583,
                40.644697080453376
              ],
              [
                -74.1820090620485,
                40.64470394355196
              ],
              [
                -74.18222090790326,
                40.64474689514836
              ],
              [
                -74.18227968543268,
                40.64475158276636
              ],
              [
                -74.18233563311567,
                40.64475880016901
              ],
              [
                -74.18242832172822,
                40.64476221868636
              ],
              [
                -74.18251092355709,
                40.64476781978643
              ],
              [
                -74.1825850137458,
                40.644775902039214
              ],
              [
                -74.18265318039725,
                40.64478159346869
              ],
              [
                -74.18272829668106,
                40.644789613019086
              ],
              [
                -74.18279757007667,
                40.644796021805305
              ],
              [
                -74.18288931621824,
                40.644800282946065
              ],
              [
                -74.18298000103783,
                40.64479439161926
              ],
              [
                -74.18310606948668,
                40.64475688935175
              ],
              [
                -74.1831516115432,
                40.644743519908275
              ],
              [
                -74.18318312917108,
                40.64473414455775
              ],
              [
                -74.18320935712921,
                40.644723516636915
              ],
              [
                -74.18322611315764,
                40.64471110772638
              ],
              [
                -74.18324492195774,
                40.64469857240891
              ],
              [
                -74.18326782928085,
                40.64468578914795
              ],
              [
                -74.18328823272974,
                40.64467865860643
              ],
              [
                -74.1833256914268,
                40.6446767807096
              ],
              [
                -74.18335950399491,
                40.64466962442314
              ],
              [
                -74.18339190186244,
                40.644663730198744
              ],
              [
                -74.18341751142201,
                40.64465706945902
              ],
              [
                -74.18344730522703,
                40.644650941648905
              ],
              [
                -74.18348441994567,
                40.64464083490326
              ],
              [
                -74.18350412369487,
                40.644636888820415
              ],
              [
                -74.1835395966405,
                40.64463080955415
              ],
              [
                -74.18356389126626,
                40.644631301676164
              ],
              [
                -74.1835946460288,
                40.64462943657016
              ],
              [
                -74.18363390887212,
                40.64462509201518
              ],
              [
                -74.18365974655892,
                40.644615667470724
              ],
              [
                -74.1836674587201,
                40.64461048505006
              ],
              [
                -74.1836716056141,
                40.644600803140726
              ],
              [
                -74.18367062573017,
                40.64459143331648
              ],
              [
                -74.18366981003881,
                40.64458362459774
              ],
              [
                -74.18368396408773,
                40.644570978701886
              ],
              [
                -74.18370426855401,
                40.64455796178772
              ],
              [
                -74.18372750215218,
                40.644548301989296
              ],
              [
                -74.18374425935657,
                40.64453589182821
              ],
              [
                -74.18376216878362,
                40.64451476782726
              ],
              [
                -74.18377555128015,
                40.64448488143152
              ],
              [
                -74.18379336287131,
                40.64445787062245
              ],
              [
                -74.18380044942428,
                40.64443190122303
              ],
              [
                -74.18379367344528,
                40.64441148862553
              ],
              [
                -74.18375686313779,
                40.64435046212031
              ],
              [
                -74.18373445919728,
                40.64430388589636
              ],
              [
                -74.18372970545593,
                40.64429788792152
              ],
              [
                -74.18372435273662,
                40.6443009629189
              ],
              [
                -74.18371629728709,
                40.64429791537324
              ],
              [
                -74.18371510901716,
                40.64429641587898
              ],
              [
                -74.1837084599112,
                40.6442673503823
              ],
              [
                -74.18369981985037,
                40.644209332734825
              ],
              [
                -74.18368494209423,
                40.644195303687
              ],
              [
                -74.18366485015768,
                40.64415605230033
              ],
              [
                -74.18364108756896,
                40.644081663097005
              ],
              [
                -74.18361739421937,
                40.64402769988577
              ],
              [
                -74.18356308699293,
                40.64394730326751
              ],
              [
                -74.18354289587366,
                40.64390216505725
              ],
              [
                -74.1835291889148,
                40.643884529739495
              ],
              [
                -74.18353584047904,
                40.64386919543812
              ],
              [
                -74.18354306065436,
                40.64385932736155
              ],
              [
                -74.18354232785633,
                40.643852301370764
              ],
              [
                -74.18353342056768,
                40.643831230085
              ],
              [
                -74.18353011105356,
                40.643809428653675
              ],
              [
                -74.1835188912455,
                40.64378103402031
              ],
              [
                -74.18350690847798,
                40.64376015158669
              ],
              [
                -74.18349881775332,
                40.64374688901716
              ],
              [
                -74.18349031843778,
                40.64372972401452
              ],
              [
                -74.18348360638707,
                40.64370498553589
              ],
              [
                -74.18345736459925,
                40.64366610679056
              ],
              [
                -74.18343316208438,
                40.64364675076355
              ],
              [
                -74.18341590888673,
                40.64362972268411
              ],
              [
                -74.18339832798834,
                40.643609571551366
              ],
              [
                -74.18338476709731,
                40.643588390739886
              ],
              [
                -74.18337670464464,
                40.64356059064253
              ],
              [
                -74.18337764050344,
                40.64353499561693
              ],
              [
                -74.18338303939252,
                40.64352248835844
              ],
              [
                -74.18339963287723,
                40.64350851613387
              ],
              [
                -74.18341365855974,
                40.64350452316628
              ],
              [
                -74.18343248256524,
                40.643497095407746
              ],
              [
                -74.18345208754049,
                40.64348726303311
              ],
              [
                -74.1834630019431,
                40.643473242228794
              ],
              [
                -74.18346695058911,
                40.643451785381494
              ],
              [
                -74.18346987385277,
                40.64343039165297
              ],
              [
                -74.18347453640791,
                40.64341085723141
              ],
              [
                -74.18347382733964,
                40.64340405837093
              ],
              [
                -74.18346988667655,
                40.643366347403976
              ],
              [
                -74.18346315710941,
                40.64333650200497
              ],
              [
                -74.18345513064986,
                40.64331891407216
              ],
              [
                -74.18344583352973,
                40.64329904726186
              ],
              [
                -74.18343777108669,
                40.64327124716791
              ],
              [
                -74.18342822891086,
                40.643249037857494
              ],
              [
                -74.183416700406,
                40.64322262607267
              ],
              [
                -74.18339926624556,
                40.643198930634746
              ],
              [
                -74.18338103400569,
                40.64317253121238
              ],
              [
                -74.18335949769718,
                40.643149083886144
              ],
              [
                -74.18334351509431,
                40.64313433802367
              ],
              [
                -74.18332858412336,
                40.643104988676825
              ],
              [
                -74.1833180172884,
                40.64308284079816
              ],
              [
                -74.18330191653295,
                40.6430571000389
              ],
              [
                -74.1832835392777,
                40.6430342465781
              ],
              [
                -74.18326729257106,
                40.64301205061986
              ],
              [
                -74.18325446680502,
                40.64299789745645
              ],
              [
                -74.18324399975582,
                40.6429816375449
              ],
              [
                -74.18324212279416,
                40.64296367810297
              ],
              [
                -74.1832500261246,
                40.6429455174547
              ],
              [
                -74.18326590229488,
                40.64292962399897
              ],
              [
                -74.1832835379203,
                40.64292069726795
              ],
              [
                -74.18330953917729,
                40.642912834241855
              ],
              [
                -74.18332782755704,
                40.642910153108126
              ],
              [
                -74.18334541783328,
                40.64291066031904
              ],
              [
                -74.18337360336598,
                40.64291877285581
              ],
              [
                -74.18338450746943,
                40.64292439934392
              ],
              [
                -74.18340441023176,
                40.642932228220396
              ],
              [
                -74.18343384779197,
                40.64293751705894
              ],
              [
                -74.18345121209893,
                40.64294078532028
              ],
              [
                -74.18347427166451,
                40.64294920957174
              ],
              [
                -74.18349671423685,
                40.64296160054373
              ],
              [
                -74.18351569164176,
                40.64297538149871
              ],
              [
                -74.1835297885602,
                40.64299181350821
              ],
              [
                -74.18353931163176,
                40.64300891757011
              ],
              [
                -74.1835450268846,
                40.643019179971134
              ],
              [
                -74.18356172737563,
                40.64303584822491
              ],
              [
                -74.18360544172096,
                40.643064235877986
              ],
              [
                -74.18362054583271,
                40.64307550169185
              ],
              [
                -74.1836367564231,
                40.64308748309497
              ],
              [
                -74.1836556701408,
                40.64310558938578
              ],
              [
                -74.18366454111677,
                40.6431164458238
              ],
              [
                -74.1836746827703,
                40.64312958314574
              ],
              [
                -74.18369172686103,
                40.64315448135174
              ],
              [
                -74.18371078634262,
                40.64316904165455
              ],
              [
                -74.18372312230241,
                40.643178509602784
              ],
              [
                -74.18373047952299,
                40.64318474281771
              ],
              [
                -74.18373830844334,
                40.643190555283866
              ],
              [
                -74.18374322410038,
                40.643198115373536
              ],
              [
                -74.18376016910378,
                40.643217125594596
              ],
              [
                -74.18378349279038,
                40.64323299986798
              ],
              [
                -74.18380081037569,
                40.6432457003549
              ],
              [
                -74.18382325266997,
                40.643258091766036
              ],
              [
                -74.18384575936905,
                40.643266156807385
              ],
              [
                -74.18387557032597,
                40.643265135803276
              ],
              [
                -74.18389568292724,
                40.64326509442864
              ],
              [
                -74.18390346813281,
                40.64325950611962
              ],
              [
                -74.18391107965493,
                40.643248435709495
              ],
              [
                -74.18391586166544,
                40.64323989565509
              ],
              [
                -74.18392013590596,
                40.64322156234679
              ],
              [
                -74.18393449863362,
                40.64320104625344
              ],
              [
                -74.18394767177904,
                40.6431790311709
              ],
              [
                -74.18396037388047,
                40.64315743733751
              ],
              [
                -74.18397514388748,
                40.64314082585006
              ],
              [
                -74.18399969266282,
                40.64312401309292
              ],
              [
                -74.18401605931439,
                40.64311280519824
              ],
              [
                -74.18402611192003,
                40.64310040856969
              ],
              [
                -74.18402970542947,
                40.643090368852036
              ],
              [
                -74.18401938279032,
                40.64307056303376
              ],
              [
                -74.18398047012975,
                40.64299434208246
              ],
              [
                -74.18396522943465,
                40.642966976183246
              ],
              [
                -74.18395277585942,
                40.642946515044564
              ],
              [
                -74.18394489393962,
                40.642925382843096
              ],
              [
                -74.18394807926667,
                40.64291143751274
              ],
              [
                -74.18394945937301,
                40.64289996042306
              ],
              [
                -74.18394450544073,
                40.642882186509965
              ],
              [
                -74.18392865089538,
                40.64285878833344
              ],
              [
                -74.1839143096972,
                40.64284001169558
              ],
              [
                -74.18388937232362,
                40.64281362860444
              ],
              [
                -74.18386697631102,
                40.642791804257556
              ],
              [
                -74.18383019147466,
                40.64276063838624
              ],
              [
                -74.18380131774566,
                40.64273606384266
              ],
              [
                -74.18379068641373,
                40.64271824287462
              ],
              [
                -74.18378812880815,
                40.642708574856904
              ],
              [
                -74.18379225864186,
                40.642693786524106
              ],
              [
                -74.18379706677689,
                40.64267070661141
              ],
              [
                -74.18380378190207,
                40.64265104760308
              ],
              [
                -74.18380877202564,
                40.64263463504348
              ],
              [
                -74.18380665138265,
                40.64261433310972
              ],
              [
                -74.18379548529253,
                40.64260125824885
              ],
              [
                -74.1837372296301,
                40.64254231856232
              ],
              [
                -74.18372202672101,
                40.642525167635604
              ],
              [
                -74.18370501881277,
                40.64251048159309
              ],
              [
                -74.18368608635798,
                40.64248727006792
              ],
              [
                -74.18367268913023,
                40.64246765022708
              ],
              [
                -74.18365642485556,
                40.64244034690106
              ],
              [
                -74.18363631699478,
                40.642395990256574
              ],
              [
                -74.18362698370106,
                40.64236590861791
              ],
              [
                -74.18361672504857,
                40.64234177790494
              ],
              [
                -74.18361271660737,
                40.642323160970086
              ],
              [
                -74.18361500455805,
                40.642300627378575
              ],
              [
                -74.18362163771548,
                40.64228018732446
              ],
              [
                -74.18363300578271,
                40.6422606383333
              ],
              [
                -74.18365044228705,
                40.64223993676865
              ],
              [
                -74.18365067064063,
                40.642239641050665
              ],
              [
                -74.18372687316322,
                40.6422733378588
              ],
              [
                -74.18372612396574,
                40.642274254768054
              ],
              [
                -74.1837193912754,
                40.642288806853855
              ],
              [
                -74.18372084348564,
                40.642297756433344
              ],
              [
                -74.18372750164232,
                40.6423071746499
              ],
              [
                -74.18382380222785,
                40.642374809424595
              ],
              [
                -74.1839035841428,
                40.64243244377228
              ],
              [
                -74.18393263758804,
                40.64246368631842
              ],
              [
                -74.18394325132424,
                40.64247640186284
              ],
              [
                -74.18395490670636,
                40.642494160701126
              ],
              [
                -74.1839595333223,
                40.64250881156425
              ],
              [
                -74.18396961120783,
                40.64252627321551
              ],
              [
                -74.18398053223476,
                40.642537005394374
              ],
              [
                -74.1839913917381,
                40.642552064100904
              ],
              [
                -74.1840048060151,
                40.64257678965135
              ],
              [
                -74.18401176301163,
                40.64260387076817
              ],
              [
                -74.18402454295668,
                40.642627455455234
              ],
              [
                -74.18403744099062,
                40.64266203570206
              ],
              [
                -74.18406005446543,
                40.64270074003324
              ],
              [
                -74.1840764542197,
                40.642744144608876
              ],
              [
                -74.184091145958,
                40.6427959032249
              ],
              [
                -74.18413505499888,
                40.64292486390932
              ],
              [
                -74.18414539613768,
                40.64294977495936
              ],
              [
                -74.18415887514915,
                40.64297017462733
              ],
              [
                -74.18417961224515,
                40.64299092000832
              ],
              [
                -74.18419608628761,
                40.643010351584735
              ],
              [
                -74.18420079256292,
                40.643025782826314
              ],
              [
                -74.18419643752019,
                40.643043335088414
              ],
              [
                -74.18418955959052,
                40.64306143317914
              ],
              [
                -74.18419133566236,
                40.64307350463432
              ],
              [
                -74.184198957169,
                40.643087188404834
              ],
              [
                -74.18420706556407,
                40.64310555566649
              ],
              [
                -74.18421444000022,
                40.64311689576945
              ],
              [
                -74.18422288569082,
                40.64311874097171
              ],
              [
                -74.18424189131768,
                40.64311798043345
              ],
              [
                -74.18425765815476,
                40.64311584665501
              ],
              [
                -74.18427562166455,
                40.643110042820076
              ],
              [
                -74.18429610585869,
                40.64310369365739
              ],
              [
                -74.18431210081681,
                40.643098793271136
              ],
              [
                -74.18433873506196,
                40.64309207087386
              ],
              [
                -74.18435901049814,
                40.64309359136269
              ],
              [
                -74.18437046773803,
                40.64309957688994
              ],
              [
                -74.18436802684603,
                40.643100903275275
              ],
              [
                -74.18435976254926,
                40.64310572661005
              ],
              [
                -74.18434858566671,
                40.64311229791751
              ],
              [
                -74.18433937666497,
                40.64311796476475
              ],
              [
                -74.18433277341413,
                40.64312386448769
              ],
              [
                -74.18433358916838,
                40.64313167437568
              ],
              [
                -74.18434174390215,
                40.64314060817702
              ],
              [
                -74.18435234019536,
                40.64314821676444
              ],
              [
                -74.18435922446363,
                40.64315487286987
              ],
              [
                -74.18436264465592,
                40.643162915635614
              ],
              [
                -74.18436157408281,
                40.643172410536145
              ],
              [
                -74.18434756615507,
                40.64318151053776
              ],
              [
                -74.18433282380548,
                40.643183582878514
              ],
              [
                -74.18432296259024,
                40.64318300362268
              ],
              [
                -74.18431076052859,
                40.64318963537222
              ],
              [
                -74.18430518133162,
                40.64319547481718
              ],
              [
                -74.18430519961645,
                40.64320058173425
              ],
              [
                -74.18431524134853,
                40.64320783052454
              ],
              [
                -74.18432725184827,
                40.643214174853476
              ],
              [
                -74.18433208756102,
                40.643220954362135
              ],
              [
                -74.18432855692002,
                40.64322666923377
              ],
              [
                -74.18432170877976,
                40.64323022696153
              ],
              [
                -74.18431436846345,
                40.6432291002011
              ],
              [
                -74.18429794940448,
                40.64322498905803
              ],
              [
                -74.18429202526653,
                40.643222597362445
              ],
              [
                -74.18428327075355,
                40.64322273553051
              ],
              [
                -74.18427310093753,
                40.64322413678721
              ],
              [
                -74.18426010141027,
                40.64322807008333
              ],
              [
                -74.18424970411742,
                40.6432322357593
              ],
              [
                -74.18423961563511,
                40.64323441924199
              ],
              [
                -74.18423281336433,
                40.64322854468718
              ],
              [
                -74.18422669045312,
                40.64321437988864
              ],
              [
                -74.18422232688232,
                40.64320717794934
              ],
              [
                -74.18422196499976,
                40.64319384156521
              ],
              [
                -74.18421664086912,
                40.64318237688753
              ],
              [
                -74.18420698929333,
                40.64317392578003
              ],
              [
                -74.18419743534481,
                40.64317136097311
              ],
              [
                -74.18419239221018,
                40.643172453799494
              ],
              [
                -74.18418691194765,
                40.64318417953793
              ],
              [
                -74.18417552561037,
                40.64319862166465
              ],
              [
                -74.18416475732954,
                40.64320909705991
              ],
              [
                -74.18415359802326,
                40.64322077493146
              ],
              [
                -74.18414084291625,
                40.643227048534335
              ],
              [
                -74.18412999241754,
                40.64323674404398
              ],
              [
                -74.18412096541132,
                40.64324907923165
              ],
              [
                -74.18411737168672,
                40.643259118784826
              ],
              [
                -74.18411386017405,
                40.643269940062865
              ],
              [
                -74.18410655661627,
                40.64327902712314
              ],
              [
                -74.18408854781073,
                40.643294263207466
              ],
              [
                -74.18407613737229,
                40.6433087672738
              ],
              [
                -74.18406483232063,
                40.643323990445815
              ],
              [
                -74.1840555962863,
                40.643344194787886
              ],
              [
                -74.18406031079249,
                40.64338437923155
              ],
              [
                -74.18406377506524,
                40.64339453765575
              ],
              [
                -74.18406923513074,
                40.643410555721495
              ],
              [
                -74.1840953581969,
                40.643482837070216
              ],
              [
                -74.18411319064435,
                40.6435300836258
              ],
              [
                -74.18413275658622,
                40.6435544364428
              ],
              [
                -74.18415521885615,
                40.643571933698
              ],
              [
                -74.1841781328029,
                40.64358390378721
              ],
              [
                -74.18420047676955,
                40.64359040664669
              ],
              [
                -74.18424412017556,
                40.64359836906536
              ],
              [
                -74.18429967762147,
                40.64360678782117
              ],
              [
                -74.18440200669028,
                40.64363830195877
              ],
              [
                -74.18442444966344,
                40.64365069157601
              ],
              [
                -74.18443576130949,
                40.64366022207072
              ],
              [
                -74.18444233837069,
                40.643668860361124
              ],
              [
                -74.18444458664025,
                40.643680510557104
              ],
              [
                -74.18444476870414,
                40.64368717857925
              ],
              [
                -74.18444338933051,
                40.64369865718048
              ],
              [
                -74.18444479428362,
                40.64371703902874
              ],
              [
                -74.18444714306519,
                40.64373457669359
              ],
              [
                -74.18445533338135,
                40.64375372432168
              ],
              [
                -74.18447219805573,
                40.643771954552136
              ],
              [
                -74.18449226409504,
                40.64378134685902
              ],
              [
                -74.18451247582924,
                40.64378719335035
              ],
              [
                -74.18453535594595,
                40.643788948860255
              ],
              [
                -74.18455325306144,
                40.6437874710164
              ],
              [
                -74.1845779388453,
                40.64378676044492
              ],
              [
                -74.18458607648822,
                40.64379058848246
              ],
              [
                -74.18459493028341,
                40.64379633943743
              ],
              [
                -74.1846048914571,
                40.643802806642306
              ],
              [
                -74.18461771832088,
                40.64381695847985
              ],
              [
                -74.18462589083089,
                40.643831000853496
              ],
              [
                -74.18462815701118,
                40.64384775913575
              ],
              [
                -74.18464037452098,
                40.64389062972575
              ],
              [
                -74.18465961650686,
                40.643911858226716
              ],
              [
                -74.18468425564608,
                40.64392058058057
              ],
              [
                -74.18470012186833,
                40.64392433303965
              ],
              [
                -74.18472119483637,
                40.64392855576274
              ],
              [
                -74.18474860069834,
                40.64393907425763
              ],
              [
                -74.1847539079795,
                40.643945431488284
              ],
              [
                -74.18475773562074,
                40.64395738052475
              ],
              [
                -74.1847666430869,
                40.643978449537975
              ],
              [
                -74.18477183957653,
                40.64399856541102
              ],
              [
                -74.1847708510447,
                40.644008840196506
              ],
              [
                -74.18477004263795,
                40.64402578568698
              ],
              [
                -74.18477310833003,
                40.6440452445851
              ],
              [
                -74.18478881821554,
                40.644072187448586
              ],
              [
                -74.18482549519861,
                40.64411711231907
              ],
              [
                -74.18483594580836,
                40.644128266338726
              ],
              [
                -74.18484658678399,
                40.64412644260538
              ],
              [
                -74.18486580195882,
                40.6440849187032
              ],
              [
                -74.18489155397833,
                40.64407178191685
              ],
              [
                -74.1849268329308,
                40.64407172519156
              ],
              [
                -74.1849818130333,
                40.64407163676705
              ],
              [
                -74.18513627010243,
                40.64397317080407
              ],
              [
                -74.18516634593075,
                40.64396984836739
              ],
              [
                -74.1851963785221,
                40.64395015675915
              ],
              [
                -74.18524793597288,
                40.643943526193354
              ],
              [
                -74.1851743550746,
                40.643902944930645
              ],
              [
                -74.18510588327057,
                40.64386518143945
              ],
              [
                -74.18508860902325,
                40.64383574445557
              ],
              [
                -74.18511431614884,
                40.643806238290004
              ],
              [
                -74.18513580682765,
                40.64380620369449
              ],
              [
                -74.18520452940678,
                40.64378972313489
              ],
              [
                -74.18530332013262,
                40.64376664672959
              ],
              [
                -74.18534542963745,
                40.64378629130626
              ],
              [
                -74.18535926743485,
                40.6437927478421
              ],
              [
                -74.18550116554881,
                40.64381543579884
              ],
              [
                -74.1855741946739,
                40.643802222588306
              ],
              [
                -74.18563857496096,
                40.64376937987739
              ],
              [
                -74.18590896667844,
                40.64363143950175
              ],
              [
                -74.18598170439189,
                40.643513462230736
              ],
              [
                -74.18606318400101,
                40.64344785231857
              ],
              [
                -74.18610078573344,
                40.64327121851158
              ],
              [
                -74.18623209809668,
                40.64318739685526
              ],
              [
                -74.18652496897599,
                40.64338866217144
              ],
              [
                -74.18702347003286,
                40.64298164152386
              ],
              [
                -74.19323428828835,
                40.63796129947735
              ],
              [
                -74.19371964621938,
                40.637566814230105
              ],
              [
                -74.19358317694844,
                40.63746911688469
              ],
              [
                -74.1936265512071,
                40.63744465583689
              ],
              [
                -74.19372224854173,
                40.637390939351576
              ],
              [
                -74.19377428207832,
                40.63736732324974
              ],
              [
                -74.19382692423089,
                40.637346408674595
              ],
              [
                -74.19389836339376,
                40.63732469610308
              ],
              [
                -74.1939730862749,
                40.637309114196356
              ],
              [
                -74.19408281695587,
                40.637304398388096
              ],
              [
                -74.194132757168,
                40.637306412217576
              ],
              [
                -74.19417412801486,
                40.637310550690366
              ],
              [
                -74.19420411105152,
                40.63732307611527
              ],
              [
                -74.19423516733062,
                40.637337129760915
              ],
              [
                -74.1942575100169,
                40.63733586721419
              ],
              [
                -74.19428109920926,
                40.63732721369496
              ],
              [
                -74.19431119833347,
                40.63731570385954
              ],
              [
                -74.194337169516,
                40.63731630227534
              ],
              [
                -74.19435651895958,
                40.63733022238393
              ],
              [
                -74.19435806101039,
                40.637357710600014
              ],
              [
                -74.19435943570639,
                40.63736919137743
              ],
              [
                -74.19435954275858,
                40.63737008161258
              ],
              [
                -74.19436689245398,
                40.63738969117939
              ],
              [
                -74.19438004804958,
                40.63739734997404
              ],
              [
                -74.1943903812557,
                40.637405382437144
              ],
              [
                -74.19442405923806,
                40.63741899183634
              ],
              [
                -74.1945003136097,
                40.63742228013342
              ],
              [
                -74.19454065381191,
                40.637413670095796
              ],
              [
                -74.19462310145185,
                40.63739118356368
              ],
              [
                -74.19473713646656,
                40.63726735349718
              ],
              [
                -74.1947553988096,
                40.637230515964795
              ],
              [
                -74.19477077585893,
                40.63719910153652
              ],
              [
                -74.19477150701454,
                40.63719629402145
              ],
              [
                -74.19477657384645,
                40.63717686213754
              ],
              [
                -74.19478689682471,
                40.637131511664265
              ],
              [
                -74.19477123463915,
                40.63708007013124
              ],
              [
                -74.19473403883907,
                40.637040775395356
              ],
              [
                -74.19470211317162,
                40.637034750838815
              ],
              [
                -74.19469106179373,
                40.63703246234289
              ],
              [
                -74.19467950093835,
                40.637034642034195
              ],
              [
                -74.19466484842447,
                40.63704884313686
              ],
              [
                -74.19465612062449,
                40.63705906092511
              ],
              [
                -74.19464418330966,
                40.63707821728935
              ],
              [
                -74.19462135058987,
                40.637090283004646
              ],
              [
                -74.19459412279457,
                40.637093190311745
              ],
              [
                -74.19456129013619,
                40.637081565084394
              ],
              [
                -74.19454212579782,
                40.637070027627864
              ],
              [
                -74.19451851091635,
                40.637034291947785
              ],
              [
                -74.19450295976435,
                40.63697061655945
              ],
              [
                -74.194496454051,
                40.63691869661756
              ],
              [
                -74.19447252206251,
                40.636874485003666
              ],
              [
                -74.19444962836066,
                40.63680402638214
              ],
              [
                -74.19441386949622,
                40.63663358412861
              ],
              [
                -74.19442256854931,
                40.63658210621316
              ],
              [
                -74.19443606542332,
                40.63656407202271
              ],
              [
                -74.19445852262734,
                40.63655548454359
              ],
              [
                -74.19449323469706,
                40.636549715612375
              ],
              [
                -74.19452606843777,
                40.63653434575063
              ],
              [
                -74.19454462292255,
                40.63651620314385
              ],
              [
                -74.19454716543115,
                40.63648953862543
              ],
              [
                -74.19453800288491,
                40.63647469933822
              ],
              [
                -74.19451352160544,
                40.63645633636188
              ],
              [
                -74.19449480774462,
                40.6364495219479
              ],
              [
                -74.19515479613113,
                40.635745190822554
              ],
              [
                -74.19517661084649,
                40.63573314917542
              ],
              [
                -74.19520096900095,
                40.635707944646235
              ],
              [
                -74.19520450148279,
                40.63568925481752
              ],
              [
                -74.195205241607,
                40.63567824858528
              ],
              [
                -74.19520941676511,
                40.63566342892389
              ],
              [
                -74.19521834364427,
                40.635647986363836
              ],
              [
                -74.19522420248127,
                40.63562740034538
              ],
              [
                -74.19521804797338,
                40.63560329500409
              ],
              [
                -74.1951989606932,
                40.63559115592845
              ],
              [
                -74.19518280919583,
                40.63557563297519
              ],
              [
                -74.19517890816915,
                40.635553052173016
              ],
              [
                -74.19518208481396,
                40.63553909111779
              ],
              [
                -74.19519126481346,
                40.63552688696694
              ],
              [
                -74.19519958010655,
                40.63552407210242
              ],
              [
                -74.19521533742004,
                40.63552091792131
              ],
              [
                -74.19521832967706,
                40.63551143455646
              ],
              [
                -74.19520661370925,
                40.63549123956763
              ],
              [
                -74.19518601247457,
                40.63546657139275
              ],
              [
                -74.1951782949683,
                40.635442944149275
              ],
              [
                -74.19518577309628,
                40.6354157810454
              ],
              [
                -74.1952126270202,
                40.635377173311646
              ],
              [
                -74.1952551116096,
                40.63533379058962
              ],
              [
                -74.1952893503173,
                40.63530094300926
              ],
              [
                -74.19530747310547,
                40.63528711471202
              ],
              [
                -74.19530406581976,
                40.63525719564209
              ],
              [
                -74.19530704356409,
                40.63523389551714
              ],
              [
                -74.195329349119,
                40.63521215576102
              ],
              [
                -74.19536142041393,
                40.63519932032491
              ],
              [
                -74.19539218181946,
                40.63519020256259
              ],
              [
                -74.19542894127432,
                40.63517593464233
              ],
              [
                -74.195460045448,
                40.63514705490001
              ],
              [
                -74.19547347096992,
                40.635109961439696
              ],
              [
                -74.19548692692484,
                40.63509729984499
              ],
              [
                -74.19550852184707,
                40.63507698291241
              ],
              [
                -74.19552753757247,
                40.63505459920113
              ],
              [
                -74.1955597527974,
                40.635023986316575
              ],
              [
                -74.19248359599999,
                40.63340507199996
              ],
              [
                -74.1921598649999,
                40.633230970999946
              ],
              [
                -74.18649341199989,
                40.63021905099988
              ],
              [
                -74.18368103499995,
                40.62874067099991
              ],
              [
                -74.1819023569999,
                40.62775580699989
              ],
              [
                -74.18033424799995,
                40.626834629999905
              ],
              [
                -74.17993706099998,
                40.62660129699989
              ],
              [
                -74.1795447069999,
                40.626362353999895
              ],
              [
                -74.17924623499991,
                40.626180580999886
              ],
              [
                -74.17918377399995,
                40.62613788499989
              ],
              [
                -74.17888390599994,
                40.625932870999925
              ],
              [
                -74.17828177399998,
                40.62545136099991
              ],
              [
                -74.1780882019999,
                40.62526042699994
              ],
              [
                -74.1778150209999,
                40.624990962999966
              ],
              [
                -74.17775298599997,
                40.62493844999993
              ],
              [
                -74.17610052899997,
                40.623406241999895
              ],
              [
                -74.17519625699995,
                40.62270624399988
              ],
              [
                -74.17490328599996,
                40.622529106999885
              ],
              [
                -74.17459706299995,
                40.6223640109999
              ],
              [
                -74.17427887999996,
                40.62221179199987
              ],
              [
                -74.17395016799996,
                40.62207316899987
              ],
              [
                -74.17361243999996,
                40.621948740999855
              ],
              [
                -74.17326730799988,
                40.62183898399992
              ],
              [
                -74.17270752199991,
                40.62171153799989
              ],
              [
                -74.17236260999994,
                40.6216357989999
              ],
              [
                -74.17207112899989,
                40.62157862099992
              ],
              [
                -74.17181177999997,
                40.62154338399994
              ],
              [
                -74.17095546499992,
                40.62144218099994
              ],
              [
                -74.16985740699987,
                40.621312386999925
              ],
              [
                -74.16953656499994,
                40.621270499999916
              ],
              [
                -74.16923624499995,
                40.6212164329999
              ],
              [
                -74.16888894299989,
                40.62115114299992
              ],
              [
                -74.16878471999995,
                40.62112402899988
              ],
              [
                -74.16847311299995,
                40.621045814999896
              ],
              [
                -74.16808086299993,
                40.62093625599991
              ],
              [
                -74.1676971039999,
                40.62079027399992
              ],
              [
                -74.16742666699997,
                40.620677283999896
              ],
              [
                -74.16603193299996,
                40.62009453499998
              ],
              [
                -74.16570682499993,
                40.61995403599988
              ],
              [
                -74.16484190399989,
                40.61957240499989
              ],
              [
                -74.1639409499999,
                40.61914326199996
              ],
              [
                -74.16337391799988,
                40.61885458999993
              ],
              [
                -74.1623545779999,
                40.61833563799997
              ],
              [
                -74.16163010299995,
                40.61793493499987
              ],
              [
                -74.16052177399989,
                40.61722978899996
              ],
              [
                -74.15986704899998,
                40.61681322499988
              ],
              [
                -74.15912798199999,
                40.61629637499988
              ],
              [
                -74.1586174169999,
                40.61593931499986
              ],
              [
                -74.15792673899992,
                40.6154574249999
              ],
              [
                -74.157373837,
                40.6150319109999
              ],
              [
                -74.15651187899992,
                40.61435448199991
              ],
              [
                -74.1547926529999,
                40.61303223099987
              ],
              [
                -74.15474080199988,
                40.61299231699992
              ],
              [
                -74.15386832299995,
                40.612320627999964
              ],
              [
                -74.15379116699992,
                40.612255611999906
              ],
              [
                -74.15375060999999,
                40.612399390999876
              ],
              [
                -74.15371281699991,
                40.61254675799992
              ],
              [
                -74.15368213999994,
                40.612659589999915
              ],
              [
                -74.15321287899991,
                40.614385562999914
              ],
              [
                -74.15295619099999,
                40.61532973299991
              ],
              [
                -74.15290527299994,
                40.615516992999865
              ],
              [
                -74.15281391799995,
                40.615825620999885
              ],
              [
                -74.1525926639999,
                40.616573080999935
              ],
              [
                -74.15251285699996,
                40.616773003999924
              ],
              [
                -74.15241908699996,
                40.61696983799988
              ],
              [
                -74.15231170399994,
                40.61716275599988
              ],
              [
                -74.15219115799991,
                40.61735094499989
              ],
              [
                -74.15205801199987,
                40.617533619999904
              ],
              [
                -74.15183858099991,
                40.61782347299985
              ],
              [
                -74.15165757899995,
                40.6180405579999
              ],
              [
                -74.15135466199992,
                40.618349997999864
              ],
              [
                -74.15093894399993,
                40.61877463899993
              ],
              [
                -74.15071865699997,
                40.61899965099991
              ],
              [
                -74.15037556699993,
                40.61935276499988
              ],
              [
                -74.15019521699998,
                40.61953838199985
              ],
              [
                -74.149823327,
                40.6199211269999
              ],
              [
                -74.14926443299994,
                40.620484252999915
              ],
              [
                -74.14922838099989,
                40.62052057799984
              ],
              [
                -74.14911237299995,
                40.62063746199988
              ],
              [
                -74.14859494699986,
                40.621167623999945
              ],
              [
                -74.14831982299995,
                40.62144951599987
              ],
              [
                -74.14825244699995,
                40.62152904599993
              ],
              [
                -74.14818936799996,
                40.62160107899996
              ],
              [
                -74.14772830399991,
                40.62213661899996
              ],
              [
                -74.14753387799992,
                40.62241637399988
              ],
              [
                -74.14745462999993,
                40.622530399999896
              ],
              [
                -74.14728835499992,
                40.62276964599989
              ],
              [
                -74.14710957699992,
                40.62303225999994
              ],
              [
                -74.14678442599998,
                40.623607550999935
              ],
              [
                -74.14650354399993,
                40.624200100999914
              ],
              [
                -74.14622812499992,
                40.6249253619999
              ],
              [
                -74.145917453,
                40.62584358599986
              ],
              [
                -74.1457599769999,
                40.626301354999946
              ],
              [
                -74.1457144359999,
                40.62643373899993
              ],
              [
                -74.14563382799992,
                40.6267251499999
              ],
              [
                -74.14560791099998,
                40.626894508999904
              ],
              [
                -74.14559821999993,
                40.62698877599997
              ],
              [
                -74.14554348099993,
                40.6275211899999
              ],
              [
                -74.14554071999993,
                40.62783252199995
              ],
              [
                -74.14565757199988,
                40.6278295379999
              ],
              [
                -74.14593544599994,
                40.62782411499996
              ],
              [
                -74.14607595499996,
                40.62875294999986
              ],
              [
                -74.14607246899999,
                40.62883419899987
              ],
              [
                -74.14608756399993,
                40.62891590299992
              ],
              [
                -74.14612099499995,
                40.6289943939999
              ],
              [
                -74.14617085499992,
                40.62906625599994
              ],
              [
                -74.14622202799995,
                40.62910695699993
              ],
              [
                -74.14628562599995,
                40.62913738599987
              ],
              [
                -74.14635596699998,
                40.629154846999924
              ],
              [
                -74.14752618999992,
                40.62938928799989
              ],
              [
                -74.1473730989999,
                40.6298270839999
              ],
              [
                -74.14691085899993,
                40.631156230999856
              ],
              [
                -74.146830166,
                40.63138265399992
              ],
              [
                -74.14666005299989,
                40.631952710999876
              ],
              [
                -74.14659947199988,
                40.632129298999935
              ],
              [
                -74.1464212309999,
                40.63271983599984
              ],
              [
                -74.14583905999997,
                40.634574971999925
              ],
              [
                -74.14566932799993,
                40.63505193199996
              ],
              [
                -74.14556478899993,
                40.635459017999935
              ],
              [
                -74.14478228999994,
                40.63795377999992
              ],
              [
                -74.14365267799991,
                40.63848492799989
              ],
              [
                -74.14359998299996,
                40.638514125999905
              ],
              [
                -74.14312802471521,
                40.63968037306379
              ],
              [
                -74.14317676696162,
                40.639692146338845
              ],
              [
                -74.14328000083096,
                40.639718663023466
              ],
              [
                -74.14336103010835,
                40.639709845181166
              ],
              [
                -74.14345479580568,
                40.63947745811819
              ],
              [
                -74.14342476830102,
                40.63946633799697
              ],
              [
                -74.14348833765422,
                40.6393255525301
              ],
              [
                -74.14357308593789,
                40.6392267852571
              ],
              [
                -74.14361490860774,
                40.639231387648564
              ],
              [
                -74.1436457188332,
                40.639233154789
              ],
              [
                -74.1436836356135,
                40.63923310741769
              ],
              [
                -74.14374287110046,
                40.639229422808754
              ],
              [
                -74.14381734530275,
                40.639207478924
              ],
              [
                -74.14384192519628,
                40.63908835505591
              ],
              [
                -74.14374959918894,
                40.638961577750614
              ],
              [
                -74.14376126311204,
                40.63894427643435
              ],
              [
                -74.1437762697454,
                40.63892854110129
              ],
              [
                -74.14379426457702,
                40.638914743475986
              ],
              [
                -74.14381482250369,
                40.63890320950684
              ],
              [
                -74.14383745787333,
                40.63889421166692
              ],
              [
                -74.14386163595883,
                40.63888796251683
              ],
              [
                -74.14388678558862,
                40.638884609683366
              ],
              [
                -74.1439530270115,
                40.638880756041466
              ],
              [
                -74.14402416136578,
                40.6389005233065
              ],
              [
                -74.14405027073543,
                40.638920348614874
              ],
              [
                -74.14409302447481,
                40.63896542493142
              ],
              [
                -74.14416181663793,
                40.63899783257256
              ],
              [
                -74.14422822731413,
                40.63902482662209
              ],
              [
                -74.1442614355038,
                40.639039226440964
              ],
              [
                -74.14433923109625,
                40.639096025578574
              ],
              [
                -74.14459085274906,
                40.639201285704395
              ],
              [
                -74.14467133234159,
                40.639304267726864
              ],
              [
                -74.14484204603131,
                40.63935381470645
              ],
              [
                -74.1451927948153,
                40.63937849765469
              ],
              [
                -74.14605733618387,
                40.63922925768988
              ],
              [
                -74.14602173101719,
                40.63909454970071
              ],
              [
                -74.14749939740649,
                40.63890959781476
              ],
              [
                -74.14761237249,
                40.63903703784254
              ],
              [
                -74.14877792005885,
                40.6388762511277
              ],
              [
                -74.14851602490765,
                40.63754707359789
              ],
              [
                -74.14852816684943,
                40.63744521351662
              ],
              [
                -74.14855815092194,
                40.637437837527244
              ],
              [
                -74.1488817554376,
                40.63870520797328
              ],
              [
                -74.14919418155682,
                40.63866833137192
              ],
              [
                -74.14924406471184,
                40.638617670290245
              ],
              [
                -74.14928187482029,
                40.638570686367906
              ],
              [
                -74.14929130075687,
                40.63854720620638
              ],
              [
                -74.14929120773513,
                40.638505627711666
              ],
              [
                -74.14929120382706,
                40.63850388089491
              ],
              [
                -74.14930052539914,
                40.63843346602416
              ],
              [
                -74.14931701723211,
                40.63839012084783
              ],
              [
                -74.1494188170834,
                40.63829164054705
              ],
              [
                -74.14945194107334,
                40.637885156579
              ],
              [
                -74.1493123559734,
                40.63752595814034
              ],
              [
                -74.14936001445896,
                40.6374186270979
              ],
              [
                -74.14937832221757,
                40.63741188131931
              ],
              [
                -74.14939786389472,
                40.63740759150982
              ],
              [
                -74.1494180764536,
                40.637405881267924
              ],
              [
                -74.1494383775278,
                40.637406799869275
              ],
              [
                -74.14945818220086,
                40.63741032084707
              ],
              [
                -74.14947691985856,
                40.63741634275454
              ],
              [
                -74.14949405062916,
                40.637424692087784
              ],
              [
                -74.14950908093832,
                40.637435128284935
              ],
              [
                -74.1496788983088,
                40.63751767426054
              ],
              [
                -74.14979386126164,
                40.63789278086418
              ],
              [
                -74.14996782090535,
                40.638150075249314
              ],
              [
                -74.15011380443705,
                40.63826728554248
              ],
              [
                -74.15030855893832,
                40.638281016934656
              ],
              [
                -74.15039275370141,
                40.63833817252051
              ],
              [
                -74.15041179728392,
                40.63837605701539
              ],
              [
                -74.15043886424382,
                40.638427351903644
              ],
              [
                -74.15044035571407,
                40.63843017599428
              ],
              [
                -74.1505563884209,
                40.63855933066728
              ],
              [
                -74.15112965090489,
                40.63850068007395
              ],
              [
                -74.15124827966567,
                40.63940934288592
              ],
              [
                -74.15139517613655,
                40.639396513057434
              ],
              [
                -74.15126280621466,
                40.63848705662542
              ],
              [
                -74.15119545007182,
                40.637730788536146
              ],
              [
                -74.15204639233272,
                40.63766721531547
              ],
              [
                -74.15205180954048,
                40.63770506596077
              ],
              [
                -74.15213675899915,
                40.6382907479556
              ],
              [
                -74.15229786175496,
                40.63827428826358
              ],
              [
                -74.15221806884477,
                40.63769258171684
              ],
              [
                -74.15242876126817,
                40.63766334125418
              ],
              [
                -74.15269700449673,
                40.63764184172464
              ],
              [
                -74.1527251690349,
                40.6375696904026
              ],
              [
                -74.15272506988086,
                40.637526366764654
              ],
              [
                -74.1527247451835,
                40.6373844954816
              ],
              [
                -74.15272473935788,
                40.63738195005758
              ],
              [
                -74.15271277799731,
                40.63733322496385
              ],
              [
                -74.15269603885832,
                40.637266454488504
              ],
              [
                -74.15270060752232,
                40.63719243556223
              ],
              [
                -74.15279703511403,
                40.63713275485255
              ],
              [
                -74.15282279548838,
                40.637134940709984
              ],
              [
                -74.15288058323338,
                40.63713984502593
              ],
              [
                -74.15292086749692,
                40.63713979145949
              ],
              [
                -74.15296592921918,
                40.63715597795898
              ],
              [
                -74.1530299679276,
                40.63718116680883
              ],
              [
                -74.15313666098517,
                40.63720629715013
              ],
              [
                -74.15327592280529,
                40.63726700478363
              ],
              [
                -74.15341406597796,
                40.63727271907927
              ],
              [
                -74.15351122315009,
                40.637272589413755
              ],
              [
                -74.15362965258818,
                40.63724896382038
              ],
              [
                -74.15376464086046,
                40.63721268010741
              ],
              [
                -74.15388072157435,
                40.637198082839554
              ],
              [
                -74.15402997235756,
                40.63718163556967
              ],
              [
                -74.15415791876252,
                40.637174244123685
              ],
              [
                -74.15425270073524,
                40.63717231088824
              ],
              [
                -74.1543830825974,
                40.637193798384054
              ],
              [
                -74.15445425716959,
                40.63722980725909
              ],
              [
                -74.15454919896747,
                40.637296472567364
              ],
              [
                -74.15459670163321,
                40.63734334406899
              ],
              [
                -74.15464669833945,
                40.637444368544955
              ],
              [
                -74.15477829011976,
                40.63772154555092
              ],
              [
                -74.15469263864514,
                40.63919396811424
              ],
              [
                -74.15475933665317,
                40.63919632730393
              ],
              [
                -74.15482932461856,
                40.63772385541876
              ],
              [
                -74.15558643758918,
                40.63773077586534
              ],
              [
                -74.15558911934629,
                40.637785622488245
              ],
              [
                -74.15555271196432,
                40.63778433017423
              ],
              [
                -74.15549268934303,
                40.63846677952642
              ],
              [
                -74.1556182932615,
                40.63847022002475
              ],
              [
                -74.15568515769907,
                40.63778748240714
              ],
              [
                -74.15569862053141,
                40.6375179389041
              ],
              [
                -74.15611488264744,
                40.63753074716438
              ],
              [
                -74.15610276490888,
                40.63793959088611
              ],
              [
                -74.15636552863708,
                40.637950742260294
              ],
              [
                -74.15636848598677,
                40.63790584773889
              ],
              [
                -74.15707077305574,
                40.63794713564092
              ],
              [
                -74.15697723294244,
                40.63927540119182
              ],
              [
                -74.15710126690709,
                40.6392777186002
              ],
              [
                -74.15718782782525,
                40.63795080522895
              ],
              [
                -74.15777365937033,
                40.63795716024865
              ],
              [
                -74.15773352572349,
                40.638879035165374
              ],
              [
                -74.15784122272909,
                40.63887391496617
              ],
              [
                -74.15789262953875,
                40.63796505123115
              ],
              [
                -74.15814883915031,
                40.6379675114477
              ],
              [
                -74.15815277195976,
                40.63810996561789
              ],
              [
                -74.15863472442109,
                40.63812847909774
              ],
              [
                -74.15864580008073,
                40.63824900845806
              ],
              [
                -74.15893299160653,
                40.638264017819026
              ],
              [
                -74.15889532350845,
                40.6388152771312
              ],
              [
                -74.15900957887435,
                40.63882257790974
              ],
              [
                -74.15902532863447,
                40.63858386637523
              ],
              [
                -74.15898941372843,
                40.63857894244465
              ],
              [
                -74.15901521285012,
                40.6382686180716
              ],
              [
                -74.15905622378882,
                40.638039310319826
              ],
              [
                -74.15907664464935,
                40.63801139557032
              ],
              [
                -74.15908695137247,
                40.63799641345516
              ],
              [
                -74.15909621101908,
                40.637976705628795
              ],
              [
                -74.15911062904141,
                40.63795147645531
              ],
              [
                -74.15912711237603,
                40.6379254560316
              ],
              [
                -74.15914460807416,
                40.63788998122662
              ],
              [
                -74.15916107601004,
                40.63785687116967
              ],
              [
                -74.15916415812242,
                40.63784820147878
              ],
              [
                -74.1592271162294,
                40.637796119756985
              ],
              [
                -74.15931954037283,
                40.63781040574547
              ],
              [
                -74.15941973096449,
                40.63790693181594
              ],
              [
                -74.15944770353745,
                40.63792816298503
              ],
              [
                -74.15945704784016,
                40.63793297921156
              ],
              [
                -74.15950258014311,
                40.63795644765375
              ],
              [
                -74.15957712397919,
                40.63799258321185
              ],
              [
                -74.15963118099671,
                40.63821444591709
              ],
              [
                -74.1596314936956,
                40.63834516180006
              ],
              [
                -74.15964146528947,
                40.638414239827924
              ],
              [
                -74.1596709437014,
                40.638440341927584
              ],
              [
                -74.15972000040932,
                40.63845334506176
              ],
              [
                -74.15973732907446,
                40.638524280974515
              ],
              [
                -74.15973010466857,
                40.63857844417414
              ],
              [
                -74.15978175306086,
                40.638649332401585
              ],
              [
                -74.15985697767896,
                40.63872920221944
              ],
              [
                -74.1598895680379,
                40.63873833806138
              ],
              [
                -74.15992364589968,
                40.638743428799664
              ],
              [
                -74.15995835194632,
                40.63874434606485
              ],
              [
                -74.15999281101924,
                40.63874106672675
              ],
              [
                -74.16002615418796,
                40.63873367347838
              ],
              [
                -74.16005754066094,
                40.638722352749866
              ],
              [
                -74.1600857788726,
                40.638675053080085
              ],
              [
                -74.16009307313972,
                40.63865076683729
              ],
              [
                -74.16010280780186,
                40.638620875705065
              ],
              [
                -74.16012967800789,
                40.63858162399913
              ],
              [
                -74.16015283988946,
                40.63838621753958
              ],
              [
                -74.1602347053031,
                40.6384103169249
              ],
              [
                -74.16020691922398,
                40.63860957529813
              ],
              [
                -74.15986410465037,
                40.639927823692716
              ],
              [
                -74.15994899634474,
                40.639940136777724
              ],
              [
                -74.16034819993155,
                40.63844372645346
              ],
              [
                -74.1604218697275,
                40.638465412322525
              ],
              [
                -74.16075060309191,
                40.638518400438876
              ],
              [
                -74.16076221947098,
                40.63862369025732
              ],
              [
                -74.16081126840741,
                40.63863295888877
              ],
              [
                -74.16089198961085,
                40.63854118915293
              ],
              [
                -74.1610816175891,
                40.63856776831252
              ],
              [
                -74.16106428065294,
                40.63868123971491
              ],
              [
                -74.16073654872477,
                40.6393510144754
              ],
              [
                -74.16090490770398,
                40.63938614081605
              ],
              [
                -74.16115789550317,
                40.63869406237822
              ],
              [
                -74.161240158151,
                40.63859501161622
              ],
              [
                -74.16166010706984,
                40.638664993313284
              ],
              [
                -74.16130221801548,
                40.63939373445705
              ],
              [
                -74.16144917826759,
                40.63942833666835
              ],
              [
                -74.16181856612248,
                40.63864627877762
              ],
              [
                -74.16202564070505,
                40.63869931460563
              ],
              [
                -74.1622846525209,
                40.63876565277234
              ],
              [
                -74.16167534694229,
                40.640610784766764
              ],
              [
                -74.16206502578967,
                40.64072523582194
              ],
              [
                -74.16206517092041,
                40.64078499076366
              ],
              [
                -74.16242722719876,
                40.640923909391844
              ],
              [
                -74.16250131292811,
                40.640923804723464
              ],
              [
                -74.16263650082281,
                40.640960130845265
              ],
              [
                -74.16274123160056,
                40.641016418743845
              ],
              [
                -74.1627935602801,
                40.64102962400666
              ],
              [
                -74.16284583292604,
                40.641019590245065
              ],
              [
                -74.16285868894607,
                40.64092993814722
              ],
              [
                -74.16322855844136,
                40.64094851032994
              ],
              [
                -74.16345445681993,
                40.63985656039824
              ],
              [
                -74.16355431885063,
                40.63985654304622
              ],
              [
                -74.16365388535758,
                40.63986239114689
              ],
              [
                -74.16375256354029,
                40.639874069881785
              ],
              [
                -74.16384976588608,
                40.639891509717735
              ],
              [
                -74.16394491366823,
                40.639914606821165
              ],
              [
                -74.16399656455822,
                40.63992979827479
              ],
              [
                -74.16404731516637,
                40.63994665879948
              ],
              [
                -74.16386249420347,
                40.64105279734375
              ],
              [
                -74.16400179001414,
                40.641065860006854
              ],
              [
                -74.16416886813627,
                40.63999411023031
              ],
              [
                -74.16431603722535,
                40.640104784619226
              ],
              [
                -74.16459656597705,
                40.64031574604752
              ],
              [
                -74.16441459649108,
                40.64139678472601
              ],
              [
                -74.16457132366716,
                40.64141866159588
              ],
              [
                -74.16473426536795,
                40.640391467024884
              ],
              [
                -74.16481109266343,
                40.6404337150713
              ],
              [
                -74.16519041758224,
                40.64064230577938
              ],
              [
                -74.16494139041178,
                40.64220899988934
              ],
              [
                -74.16544310082392,
                40.642280546708584
              ],
              [
                -74.16563409763798,
                40.641214371375646
              ],
              [
                -74.1658011075589,
                40.64122451144942
              ],
              [
                -74.16591014147886,
                40.641257551347
              ],
              [
                -74.16604534908741,
                40.64130051310063
              ],
              [
                -74.16611608669034,
                40.641370843361386
              ],
              [
                -74.16631887292456,
                40.6414366750049
              ],
              [
                -74.1660998989604,
                40.64240658700117
              ],
              [
                -74.1662159991704,
                40.64242410022791
              ],
              [
                -74.16647971882989,
                40.641434871677774
              ],
              [
                -74.16653375792842,
                40.641422637883714
              ],
              [
                -74.16671693757165,
                40.64147880919975
              ],
              [
                -74.1667738499355,
                40.64158163822525
              ],
              [
                -74.16757607092507,
                40.641844793608
              ],
              [
                -74.16774094051357,
                40.64172777449844
              ],
              [
                -74.16801680461005,
                40.64183766681538
              ],
              [
                -74.16812547492654,
                40.64182533149999
              ],
              [
                -74.16820402897147,
                40.64186837353088
              ],
              [
                -74.1682342144124,
                40.64192427424841
              ],
              [
                -74.16824343577274,
                40.64194135036167
              ],
              [
                -74.16840232530689,
                40.641991241544225
              ],
              [
                -74.16906431628871,
                40.6421300167986
              ],
              [
                -74.16915295569137,
                40.641872859874326
              ],
              [
                -74.17007878651035,
                40.64208616057553
              ],
              [
                -74.17028635441103,
                40.64177530825585
              ],
              [
                -74.17119260951202,
                40.64173962651394
              ],
              [
                -74.17121130105224,
                40.64206757586896
              ],
              [
                -74.17127524445598,
                40.6421116827678
              ],
              [
                -74.17099208752254,
                40.64257180445465
              ],
              [
                -74.17105017239898,
                40.642593819129914
              ],
              [
                -74.17117734635514,
                40.642403561368766
              ],
              [
                -74.17115412449108,
                40.642399175687814
              ],
              [
                -74.17132740135541,
                40.64208508443947
              ],
              [
                -74.17137382350516,
                40.642085015268094
              ],
              [
                -74.1713905404507,
                40.64176250593573
              ],
              [
                -74.17140176158097,
                40.641499460895865
              ],
              [
                -74.17138614681488,
                40.64147972331705
              ],
              [
                -74.17138651859484,
                40.64142273652408
              ],
              [
                -74.17139744134563,
                40.64141393688061
              ],
              [
                -74.17140864510931,
                40.6413969836961
              ],
              [
                -74.17143101720238,
                40.64137368881441
              ],
              [
                -74.17143965724362,
                40.641359126900866
              ],
              [
                -74.17143859751879,
                40.641343845504196
              ],
              [
                -74.17144203866056,
                40.641253602025294
              ],
              [
                -74.1714558850582,
                40.64121303758398
              ],
              [
                -74.17147992035214,
                40.64119144364455
              ],
              [
                -74.17149326948423,
                40.64117981215319
              ],
              [
                -74.17149730887861,
                40.64117767281821
              ],
              [
                -74.17151170840289,
                40.6411740096028
              ],
              [
                -74.17152934464757,
                40.64117302201761
              ],
              [
                -74.17154669424497,
                40.64116986385235
              ],
              [
                -74.1715588562989,
                40.64116015383602
              ],
              [
                -74.17156545223199,
                40.641148307922435
              ],
              [
                -74.17156634010111,
                40.64112958542867
              ],
              [
                -74.17154914531588,
                40.641090302975165
              ],
              [
                -74.17154741606059,
                40.64106995349324
              ],
              [
                -74.1715539568967,
                40.641046773840586
              ],
              [
                -74.17155907481232,
                40.64102736051345
              ],
              [
                -74.17156994685418,
                40.641011532092755
              ],
              [
                -74.171584960751,
                40.64100160153897
              ],
              [
                -74.17160107265416,
                40.64099634322897
              ],
              [
                -74.17162317131236,
                40.640994648532406
              ],
              [
                -74.17165773761847,
                40.64099638765365
              ],
              [
                -74.17169348843088,
                40.64099803436283
              ],
              [
                -74.17173545900431,
                40.64099225762033
              ],
              [
                -74.17177628856427,
                40.6409851024651
              ],
              [
                -74.17181716981398,
                40.640981967740466
              ],
              [
                -74.1718640859913,
                40.64097727266598
              ],
              [
                -74.17189978466081,
                40.64097490104446
              ],
              [
                -74.17193676659375,
                40.64097133351591
              ],
              [
                -74.17196571583546,
                40.64096874797935
              ],
              [
                -74.1719924762055,
                40.640964135763646
              ],
              [
                -74.17202446166853,
                40.6409554653829
              ],
              [
                -74.17206200514698,
                40.64094161338942
              ],
              [
                -74.1721097577176,
                40.64091417577345
              ],
              [
                -74.17221543257125,
                40.64085632820192
              ],
              [
                -74.17230798868354,
                40.64080094946075
              ],
              [
                -74.17237264271998,
                40.64074880691059
              ],
              [
                -74.17243738848725,
                40.64069007394007
              ],
              [
                -74.17246114186268,
                40.640673620668416
              ],
              [
                -74.17250125093443,
                40.640657377733376
              ],
              [
                -74.17255704830454,
                40.64064358932918
              ],
              [
                -74.17262782121746,
                40.64063047986058
              ],
              [
                -74.17271366501747,
                40.640618776734094
              ],
              [
                -74.17275611439214,
                40.64061661819037
              ],
              [
                -74.17279941322334,
                40.640606349125285
              ],
              [
                -74.17282511932686,
                40.64059376895656
              ],
              [
                -74.17285348835655,
                40.64057952423441
              ],
              [
                -74.17288949955986,
                40.64055408346094
              ],
              [
                -74.17291914361653,
                40.64053132684716
              ],
              [
                -74.17294123198201,
                40.64051317422253
              ],
              [
                -74.17295918809823,
                40.64049643471537
              ],
              [
                -74.17296754566539,
                40.64048701573994
              ],
              [
                -74.17297990582061,
                40.64048606719566
              ],
              [
                -74.17298798800311,
                40.64048910506626
              ],
              [
                -74.17299051114732,
                40.640491122002416
              ],
              [
                -74.17299926424006,
                40.6404981157247
              ],
              [
                -74.17300388381133,
                40.64050763814558
              ],
              [
                -74.17299923819058,
                40.64052335631653
              ],
              [
                -74.1729879887585,
                40.64054360451508
              ],
              [
                -74.17296661535327,
                40.640563532045654
              ],
              [
                -74.17293896175397,
                40.64057955269739
              ],
              [
                -74.17290384335851,
                40.64059358477596
              ],
              [
                -74.17284329736425,
                40.64061505330325
              ],
              [
                -74.1727962478836,
                40.640629634012406
              ],
              [
                -74.17275233141466,
                40.640638855609325
              ],
              [
                -74.17270403672576,
                40.64064402136012
              ],
              [
                -74.17265255879144,
                40.64065053025726
              ],
              [
                -74.17261757416877,
                40.64065467584056
              ],
              [
                -74.17254523063315,
                40.64066680711364
              ],
              [
                -74.17251006213091,
                40.6406768196294
              ],
              [
                -74.1724794616066,
                40.64069233295828
              ],
              [
                -74.17246079147431,
                40.6407072986612
              ],
              [
                -74.17244758967797,
                40.64072367362159
              ],
              [
                -74.17244071098945,
                40.64074066046267
              ],
              [
                -74.17243121713608,
                40.640756019159774
              ],
              [
                -74.1724160191034,
                40.64077181511472
              ],
              [
                -74.17239216946237,
                40.64078754395923
              ],
              [
                -74.17236527396382,
                40.64080204284477
              ],
              [
                -74.17233215308794,
                40.64081665291115
              ],
              [
                -74.17229575775352,
                40.64083919499945
              ],
              [
                -74.17225779312604,
                40.6408607617291
              ],
              [
                -74.17208977321141,
                40.640948261930674
              ],
              [
                -74.17205432959555,
                40.64097073115047
              ],
              [
                -74.1720231585248,
                40.640989215662884
              ],
              [
                -74.17200429275435,
                40.6409954174848
              ],
              [
                -74.17197078141105,
                40.640999816986145
              ],
              [
                -74.17191307620529,
                40.64100643588378
              ],
              [
                -74.17183706459231,
                40.641008971456586
              ],
              [
                -74.17178249117183,
                40.64101023055249
              ],
              [
                -74.17175073432414,
                40.6410097388801
              ],
              [
                -74.1717056688524,
                40.64101026839081
              ],
              [
                -74.17165119164028,
                40.64101225184257
              ],
              [
                -74.17162486188907,
                40.64101388919833
              ],
              [
                -74.17160070795778,
                40.64101539095348
              ],
              [
                -74.1715971917376,
                40.6410178555225
              ],
              [
                -74.1715872192374,
                40.641029593394464
              ],
              [
                -74.17158024474797,
                40.641045856267034
              ],
              [
                -74.17157427204839,
                40.64106606462145
              ],
              [
                -74.17157328411733,
                40.64107674772807
              ],
              [
                -74.17158113805186,
                40.64108894818463
              ],
              [
                -74.17159008305946,
                40.64109850324171
              ],
              [
                -74.17159955189405,
                40.64110838453248
              ],
              [
                -74.17160617556183,
                40.641125799144945
              ],
              [
                -74.17160210287882,
                40.64114586360611
              ],
              [
                -74.17159279987557,
                40.641162670066514
              ],
              [
                -74.17158967454701,
                40.64117534693892
              ],
              [
                -74.17157689873703,
                40.641191323114086
              ],
              [
                -74.17156055313183,
                40.64119842793582
              ],
              [
                -74.17154059420163,
                40.64120727340808
              ],
              [
                -74.17152229426043,
                40.641210505529756
              ],
              [
                -74.17151573437815,
                40.64121174061924
              ],
              [
                -74.17150704445345,
                40.64122228259367
              ],
              [
                -74.17149328066832,
                40.641248941865214
              ],
              [
                -74.17148493532513,
                40.641272990998544
              ],
              [
                -74.17148462205134,
                40.64129605866502
              ],
              [
                -74.17148663794484,
                40.64131858107016
              ],
              [
                -74.17149054869456,
                40.64133364361031
              ],
              [
                -74.17149521934651,
                40.64134718603417
              ],
              [
                -74.1714963733101,
                40.64136318967349
              ],
              [
                -74.17149378298565,
                40.641390821537875
              ],
              [
                -74.17149114120241,
                40.641414433961884
              ],
              [
                -74.17148316419237,
                40.64142674967047
              ],
              [
                -74.17147622922754,
                40.64143240221208
              ],
              [
                -74.1714630212239,
                40.64144146158959
              ],
              [
                -74.17145228873805,
                40.6414547212406
              ],
              [
                -74.17145387097246,
                40.641470328036235
              ],
              [
                -74.17146506842424,
                40.6415005604963
              ],
              [
                -74.17146800890498,
                40.641548249029
              ],
              [
                -74.17147872144523,
                40.641622045376614
              ],
              [
                -74.17148506880689,
                40.6416519168383
              ],
              [
                -74.17148750672796,
                40.64166672743327
              ],
              [
                -74.17148353018582,
                40.64168751580476
              ],
              [
                -74.17147878989451,
                40.64170250999356
              ],
              [
                -74.17148332476262,
                40.64172593597716
              ],
              [
                -74.17148857094215,
                40.64174382256753
              ],
              [
                -74.17149496395665,
                40.64177040017785
              ],
              [
                -74.17150857950507,
                40.64179349598847
              ],
              [
                -74.17152666189581,
                40.64181405545097
              ],
              [
                -74.17153942223504,
                40.64183795013772
              ],
              [
                -74.17155066453364,
                40.64186488555612
              ],
              [
                -74.17156189474113,
                40.64187719238308
              ],
              [
                -74.17157986192119,
                40.64187508403213
              ],
              [
                -74.17159136106804,
                40.64186761904967
              ],
              [
                -74.17160447942199,
                40.64186515003683
              ],
              [
                -74.17162797232027,
                40.641875784546365
              ],
              [
                -74.17166487828337,
                40.641893437748415
              ],
              [
                -74.17169759758742,
                40.64190848524939
              ],
              [
                -74.17171130316294,
                40.64192499166151
              ],
              [
                -74.171721869505,
                40.64193954418145
              ],
              [
                -74.1717122849496,
                40.64196149319705
              ],
              [
                -74.1716942410576,
                40.641984822881895
              ],
              [
                -74.17167999810484,
                40.642007861420325
              ],
              [
                -74.17167128154989,
                40.64204364399355
              ],
              [
                -74.17168216664471,
                40.6420969430892
              ],
              [
                -74.1716960887233,
                40.642144155854176
              ],
              [
                -74.17171872782124,
                40.64215558855388
              ],
              [
                -74.17174621554558,
                40.642167379220545
              ],
              [
                -74.1717645795822,
                40.642182797270586
              ],
              [
                -74.17177029647218,
                40.642196990308676
              ],
              [
                -74.17175833773514,
                40.642222778443774
              ],
              [
                -74.17172813024924,
                40.64225582034927
              ],
              [
                -74.17172311588905,
                40.64228327188951
              ],
              [
                -74.17173434531898,
                40.64229557870037
              ],
              [
                -74.17175555524433,
                40.64230346074553
              ],
              [
                -74.17178294030919,
                40.642307213696114
              ],
              [
                -74.17180272014537,
                40.64231155061576
              ],
              [
                -74.17182251389802,
                40.642330514946245
              ],
              [
                -74.17184012461776,
                40.64235476907155
              ],
              [
                -74.17184927245123,
                40.64238040270817
              ],
              [
                -74.17184624853066,
                40.64240111779714
              ],
              [
                -74.17182336830281,
                40.64243871661524
              ],
              [
                -74.17179591691541,
                40.64247081458347
              ],
              [
                -74.17175449245057,
                40.64250617945189
              ],
              [
                -74.17175012787148,
                40.6425167566973
              ],
              [
                -74.17176078401346,
                40.642524717158906
              ],
              [
                -74.17178988074738,
                40.64252687552602
              ],
              [
                -74.1718325674352,
                40.64252287080461
              ],
              [
                -74.17186042582567,
                40.64252292851886
              ],
              [
                -74.17185834485531,
                40.64253625652775
              ],
              [
                -74.17184238051536,
                40.642546258729965
              ],
              [
                -74.17181957513452,
                40.64256263808036
              ],
              [
                -74.17176948707788,
                40.642583304866314
              ],
              [
                -74.17164594223647,
                40.64265130043638
              ],
              [
                -74.17150330552997,
                40.64274197439647
              ],
              [
                -74.17144058293839,
                40.64277604458667
              ],
              [
                -74.17139545714402,
                40.642819740348514
              ],
              [
                -74.17136827527347,
                40.642832065402914
              ],
              [
                -74.17134793296881,
                40.64283801517441
              ],
              [
                -74.17133587281808,
                40.642855765052
              ],
              [
                -74.17134786953622,
                40.64287386440186
              ],
              [
                -74.17140640381228,
                40.64294618860575
              ],
              [
                -74.17143219470348,
                40.64297420351297
              ],
              [
                -74.1714537863652,
                40.64298498530131
              ],
              [
                -74.1714726101952,
                40.64298207959421
              ],
              [
                -74.17149115230349,
                40.64298431358229
              ],
              [
                -74.17148298554169,
                40.64299518145323
              ],
              [
                -74.17146730099273,
                40.64300004172946
              ],
              [
                -74.17144886157108,
                40.643005846449306
              ],
              [
                -74.1714332783832,
                40.64301874442955
              ],
              [
                -74.17142369357941,
                40.64304069392105
              ],
              [
                -74.17142961518836,
                40.64307096506938
              ],
              [
                -74.17144598761874,
                40.64309311861381
              ],
              [
                -74.17147481628083,
                40.64311504986822
              ],
              [
                -74.17150811099722,
                40.64313444209083
              ],
              [
                -74.17151077414584,
                40.643134604474994
              ],
              [
                -74.17157200738855,
                40.64313832290443
              ],
              [
                -74.17165832740464,
                40.643122924347885
              ],
              [
                -74.17175033852061,
                40.64309246075225
              ],
              [
                -74.17179385518948,
                40.64305839881659
              ],
              [
                -74.17184049894703,
                40.64301166216294
              ],
              [
                -74.17185540509317,
                40.64298637907182
              ],
              [
                -74.1718725950498,
                40.64296384439198
              ],
              [
                -74.17188085297778,
                40.6429463872854
              ],
              [
                -74.17192969117691,
                40.642908991228566
              ],
              [
                -74.17195513744909,
                40.64286899993016
              ],
              [
                -74.17196330565986,
                40.64285813269326
              ],
              [
                -74.17198536861879,
                40.64286521966928
              ],
              [
                -74.17200715298468,
                40.6428774475086
              ],
              [
                -74.1720323441418,
                40.64287185876253
              ],
              [
                -74.17205258429262,
                40.64285787015778
              ],
              [
                -74.17209783689812,
                40.6428514745503
              ],
              [
                -74.17214841028964,
                40.64284174434231
              ],
              [
                -74.17222686800724,
                40.64286133226363
              ],
              [
                -74.17228105389907,
                40.64284986132473
              ],
              [
                -74.17230254351885,
                40.642852601896365
              ],
              [
                -74.17231215386455,
                40.642859912857034
              ],
              [
                -74.17232689255582,
                40.64286244005618
              ],
              [
                -74.17234875391364,
                40.64285344836138
              ],
              [
                -74.17236984813191,
                40.642838663496875
              ],
              [
                -74.17239397993379,
                40.642817792112425
              ],
              [
                -74.17244027862134,
                40.642812048163904
              ],
              [
                -74.17246652969651,
                40.64282173903219
              ],
              [
                -74.17247983025173,
                40.64283226518402
              ],
              [
                -74.17249012522633,
                40.64284041274658
              ],
              [
                -74.1725059252311,
                40.64285822080247
              ],
              [
                -74.17253903933235,
                40.64289079516259
              ],
              [
                -74.17255246456675,
                40.64291244402013
              ],
              [
                -74.17255990601879,
                40.642939672184774
              ],
              [
                -74.17256259721643,
                40.642974579119304
              ],
              [
                -74.17257192685548,
                40.64298703143301
              ],
              [
                -74.17259008697096,
                40.642986371256086
              ],
              [
                -74.17264446337221,
                40.64297634848098
              ],
              [
                -74.17269113227853,
                40.642958871002655
              ],
              [
                -74.17272145305189,
                40.642948498996596
              ],
              [
                -74.17272896940098,
                40.64295450533838
              ],
              [
                -74.17273173837609,
                40.642968192798314
              ],
              [
                -74.17273403548249,
                40.642985574304745
              ],
              [
                -74.17271969180938,
                40.64300057358569
              ],
              [
                -74.17268776272147,
                40.64302057839417
              ],
              [
                -74.1726368189117,
                40.64304204176554
              ],
              [
                -74.17261030040635,
                40.64305212140045
              ],
              [
                -74.17257085513344,
                40.643066120329216
              ],
              [
                -74.17255348547413,
                40.643101834698925
              ],
              [
                -74.17255588536146,
                40.6431272544168
              ],
              [
                -74.17254923656448,
                40.64313507994243
              ],
              [
                -74.17252033132723,
                40.643134369598
              ],
              [
                -74.17249030372325,
                40.64315422926671
              ],
              [
                -74.1725008695514,
                40.64316878221698
              ],
              [
                -74.17252141705573,
                40.64317891050156
              ],
              [
                -74.1725422440035,
                40.64318389774486
              ],
              [
                -74.17254529348257,
                40.643192442661814
              ],
              [
                -74.17252685277765,
                40.6431982458812
              ],
              [
                -74.17250802827286,
                40.64320115176178
              ],
              [
                -74.17247912367155,
                40.643200443081305
              ],
              [
                -74.17245953399646,
                40.643197555283656
              ],
              [
                -74.17242340763579,
                40.64320032509589
              ],
              [
                -74.17239812675159,
                40.64321250598127
              ],
              [
                -74.17237019114741,
                40.64323366736318
              ],
              [
                -74.17236165386568,
                40.64325626755186
              ],
              [
                -74.17237260369193,
                40.64327371734715
              ],
              [
                -74.17238840157012,
                40.643291526091694
              ],
              [
                -74.17240344811884,
                40.64331817025155
              ],
              [
                -74.17240430879276,
                40.64332015618675
              ],
              [
                -74.1724089734546,
                40.64333091425274
              ],
              [
                -74.17241479273052,
                40.643353147141276
              ],
              [
                -74.17242498838897,
                40.643379431347974
              ],
              [
                -74.17243394765843,
                40.6434036159276
              ],
              [
                -74.17244784485074,
                40.643421570750824
              ],
              [
                -74.17250700754171,
                40.643447759564296
              ],
              [
                -74.17251090723762,
                40.64344862653893
              ],
              [
                -74.17263662669752,
                40.64347659174544
              ],
              [
                -74.1726771463489,
                40.64349250393413
              ],
              [
                -74.17271527709515,
                40.64349762665969
              ],
              [
                -74.17275397064707,
                40.64349246595072
              ],
              [
                -74.17278382065997,
                40.64348578696892
              ],
              [
                -74.17282297214406,
                40.64346230075672
              ],
              [
                -74.17284673524703,
                40.64345316387827
              ],
              [
                -74.1728717473641,
                40.64346075570623
              ],
              [
                -74.17289334095112,
                40.64347153554611
              ],
              [
                -74.17289614187574,
                40.64347368106336
              ],
              [
                -74.17292112211442,
                40.6434928141482
              ],
              [
                -74.17294804849875,
                40.64351489062812
              ],
              [
                -74.17297317769635,
                40.64354515039891
              ],
              [
                -74.17300154585647,
                40.6435854050796
              ],
              [
                -74.17301975911775,
                40.64364326202731
              ],
              [
                -74.17302818117628,
                40.64370699218261
              ],
              [
                -74.17301624948162,
                40.643762040966145
              ],
              [
                -74.17300185492398,
                40.643827520941635
              ],
              [
                -74.17298278854645,
                40.643879459059164
              ],
              [
                -74.17295430469129,
                40.64392553644085
              ],
              [
                -74.17291705460552,
                40.64394887542741
              ],
              [
                -74.1728888372495,
                40.643975179985354
              ],
              [
                -74.17287698237259,
                40.64400900811272
              ],
              [
                -74.17288641310363,
                40.64402950028471
              ],
              [
                -74.17291021359736,
                40.64406425250436
              ],
              [
                -74.17291679919698,
                40.64409227735587
              ],
              [
                -74.17292292555672,
                40.644138626012946
              ],
              [
                -74.17292333373825,
                40.64417078169329
              ],
              [
                -74.1729412389165,
                40.644204522855894
              ],
              [
                -74.17296865141027,
                40.64423753436872
              ],
              [
                -74.17297421830348,
                40.64424146476255
              ],
              [
                -74.17300042799175,
                40.644259970260144
              ],
              [
                -74.17304465948314,
                40.64428218150937
              ],
              [
                -74.17309117778854,
                40.64430714397382
              ],
              [
                -74.17315481958882,
                40.644345425303094
              ],
              [
                -74.17323931641718,
                40.64441795205415
              ],
              [
                -74.17332562596047,
                40.644496923017186
              ],
              [
                -74.17338319488704,
                40.64454737444702
              ],
              [
                -74.1734312431562,
                40.644583922928554
              ],
              [
                -74.17348147469656,
                40.644615185582026
              ],
              [
                -74.17354483786617,
                40.644658606895206
              ],
              [
                -74.173598692665,
                40.64470275904733
              ],
              [
                -74.17364112552036,
                40.64473315555264
              ],
              [
                -74.17378734092976,
                40.64481484800369
              ],
              [
                -74.17388619569034,
                40.6448870034496
              ],
              [
                -74.17395013353845,
                40.6449347715418
              ],
              [
                -74.17399636979121,
                40.6449648766939
              ],
              [
                -74.17401245134988,
                40.644977542658836
              ],
              [
                -74.1740333926389,
                40.64500519709439
              ],
              [
                -74.1740548408656,
                40.64502187545243
              ],
              [
                -74.17405908286237,
                40.64502517320027
              ],
              [
                -74.1741110252966,
                40.64505484096361
              ],
              [
                -74.17415373990465,
                40.64508009422507
              ],
              [
                -74.17436572943608,
                40.64513625864544
              ],
              [
                -74.17439463489723,
                40.64513696735422
              ],
              [
                -74.17443209299536,
                40.64512970547127
              ],
              [
                -74.1744475844544,
                40.64512339646471
              ],
              [
                -74.17445955562798,
                40.64511223664268
              ],
              [
                -74.17446515842171,
                40.645103759903485
              ],
              [
                -74.17448253856479,
                40.64508267551567
              ],
              [
                -74.17450182079058,
                40.64506144449937
              ],
              [
                -74.17452262296977,
                40.64503717253804
              ],
              [
                -74.17454923838939,
                40.64502291968124
              ],
              [
                -74.17455132269359,
                40.64502180312211
              ],
              [
                -74.17458621477577,
                40.64501693253468
              ],
              [
                -74.17460875182422,
                40.645020323777494
              ],
              [
                -74.17464451191779,
                40.64502928553517
              ],
              [
                -74.17468018172738,
                40.64504483883493
              ],
              [
                -74.17472003854463,
                40.64506299652924
              ],
              [
                -74.17475209647739,
                40.64508028888076
              ],
              [
                -74.17478835445861,
                40.64511481705891
              ],
              [
                -74.1748554056802,
                40.64513527793488
              ],
              [
                -74.17489620525124,
                40.6451460479629
              ],
              [
                -74.17495335456692,
                40.6451497114997
              ],
              [
                -74.17504185709666,
                40.64512902440762
              ],
              [
                -74.17511371862008,
                40.64510595536824
              ],
              [
                -74.17516638744445,
                40.64509752552763
              ],
              [
                -74.17520888416513,
                40.645092072230405
              ],
              [
                -74.17525879608037,
                40.64508458521078
              ],
              [
                -74.17529691545047,
                40.64507507681888
              ],
              [
                -74.17532749146805,
                40.64503030222789
              ],
              [
                -74.17534660403777,
                40.644927883075006
              ],
              [
                -74.17534628381469,
                40.64488913702666
              ],
              [
                -74.17535053232227,
                40.644855891684976
              ],
              [
                -74.1753750953006,
                40.64478743702187
              ],
              [
                -74.17540528962303,
                40.64473976558173
              ],
              [
                -74.17544240953579,
                40.644679127066915
              ],
              [
                -74.17546691157192,
                40.64464652110229
              ],
              [
                -74.17549931299419,
                40.64462282196993
              ],
              [
                -74.17552706974192,
                40.644614840590755
              ],
              [
                -74.17555892305174,
                40.644616055134655
              ],
              [
                -74.17557528468835,
                40.64462357832871
              ],
              [
                -74.17559031828651,
                40.64463559348451
              ],
              [
                -74.17560174068048,
                40.64464934673441
              ],
              [
                -74.17562477603211,
                40.644678305245755
              ],
              [
                -74.17565170598887,
                40.6447003810864
              ],
              [
                -74.17566940804059,
                40.64471804256369
              ],
              [
                -74.17568470742572,
                40.64471028489508
              ],
              [
                -74.17569001562464,
                40.644692320893235
              ],
              [
                -74.17569009214982,
                40.64467109974981
              ],
              [
                -74.17569977789418,
                40.64465719029011
              ],
              [
                -74.17570879961221,
                40.64464552488581
              ],
              [
                -74.17570768504721,
                40.64457172467191
              ],
              [
                -74.17562352359606,
                40.64434920675861
              ],
              [
                -74.17556911636981,
                40.64422097206648
              ],
              [
                -74.17554692124178,
                40.64417658755787
              ],
              [
                -74.17551972507742,
                40.644174285007885
              ],
              [
                -74.17549423978275,
                40.6441703863076
              ],
              [
                -74.17547711304717,
                40.64415706995424
              ],
              [
                -74.17547291355909,
                40.64413983464345
              ],
              [
                -74.17547166255278,
                40.64412310546115
              ],
              [
                -74.1754775450055,
                40.644109486118765
              ],
              [
                -74.17549255121834,
                40.6440922412695
              ],
              [
                -74.17549159458244,
                40.644084999290904
              ],
              [
                -74.17548032439193,
                40.64402880424375
              ],
              [
                -74.17547659531657,
                40.64400787487742
              ],
              [
                -74.17547770557813,
                40.64397267624064
              ],
              [
                -74.1754769120151,
                40.64393762257318
              ],
              [
                -74.1754840199676,
                40.64391147255845
              ],
              [
                -74.1755021389856,
                40.64386692331762
              ],
              [
                -74.17551922607672,
                40.64383634988809
              ],
              [
                -74.17553233126134,
                40.643819251841606
              ],
              [
                -74.17555532735325,
                40.64380431908099
              ],
              [
                -74.17556511464507,
                40.64379844733607
              ],
              [
                -74.17558649028975,
                40.64377851932653
              ],
              [
                -74.17558456226031,
                40.643749405111045
              ],
              [
                -74.17557190131993,
                40.643733551264056
              ],
              [
                -74.1755395485597,
                40.643706770930734
              ],
              [
                -74.17551985806496,
                40.64369584645085
              ],
              [
                -74.17550644511681,
                40.64368882703041
              ],
              [
                -74.17550405793433,
                40.643678037636654
              ],
              [
                -74.17550345643659,
                40.64364443296587
              ],
              [
                -74.17550408022687,
                40.64359829762305
              ],
              [
                -74.1754908667434,
                40.64349835910246
              ],
              [
                -74.17548579327112,
                40.64347100404518
              ],
              [
                -74.17548417914058,
                40.64346229451269
              ],
              [
                -74.17551664116927,
                40.643402743967066
              ],
              [
                -74.17552797662421,
                40.64338269844048
              ],
              [
                -74.17553221024086,
                40.643375214669454
              ],
              [
                -74.17558265458499,
                40.64332818304796
              ],
              [
                -74.17560250954419,
                40.643311298672444
              ],
              [
                -74.17562684579914,
                40.64330650587083
              ],
              [
                -74.17564120364021,
                40.64330613531523
              ],
              [
                -74.17566478766791,
                40.64331017911892
              ],
              [
                -74.17566973981538,
                40.64331857781009
              ],
              [
                -74.17567564816922,
                40.64333421847934
              ],
              [
                -74.17568450673052,
                40.6433503645987
              ],
              [
                -74.17568964875981,
                40.64336021111712
              ],
              [
                -74.17570735046618,
                40.64337787259119
              ],
              [
                -74.1757312282689,
                40.643391403585994
              ],
              [
                -74.17577964702862,
                40.643416220500136
              ],
              [
                -74.17585002747882,
                40.6434400825729
              ],
              [
                -74.17589757805769,
                40.64345106540614
              ],
              [
                -74.17598183191912,
                40.64346362228083
              ],
              [
                -74.17610392257231,
                40.643471814162666
              ],
              [
                -74.1762882750846,
                40.64346425526383
              ],
              [
                -74.17633666713805,
                40.6434598102465
              ],
              [
                -74.17637126443755,
                40.64344545241627
              ],
              [
                -74.17643380731708,
                40.64342456030131
              ],
              [
                -74.1764732522114,
                40.643410560047975
              ],
              [
                -74.17649654093123,
                40.643405115977934
              ],
              [
                -74.17651461386725,
                40.643411045559844
              ],
              [
                -74.17653877163026,
                40.64341943332855
              ],
              [
                -74.17658489192628,
                40.64342686897757
              ],
              [
                -74.17661645169186,
                40.643418594349434
              ],
              [
                -74.17665028095568,
                40.64339844229373
              ],
              [
                -74.17666795538246,
                40.64338684477162
              ],
              [
                -74.17667625101973,
                40.64337403249797
              ],
              [
                -74.17668390509117,
                40.64336221113667
              ],
              [
                -74.17670779048979,
                40.64328137265503
              ],
              [
                -74.17671109337599,
                40.64325551605539
              ],
              [
                -74.17670848832692,
                40.643214017163274
              ],
              [
                -74.17670588284568,
                40.643172519946916
              ],
              [
                -74.17671685309467,
                40.64311022814015
              ],
              [
                -74.17672442495068,
                40.642971386120585
              ],
              [
                -74.17671674393047,
                40.64278982023797
              ],
              [
                -74.17668723791225,
                40.642552138621205
              ],
              [
                -74.17667571342811,
                40.64253034458887
              ],
              [
                -74.17665211551694,
                40.64251167173469
              ],
              [
                -74.17659218717394,
                40.64247969257936
              ],
              [
                -74.17638356878425,
                40.64236182591777
              ],
              [
                -74.1763588355939,
                40.6423490917955
              ],
              [
                -74.17634950665703,
                40.64233663978609
              ],
              [
                -74.1763578661754,
                40.64232722056289
              ],
              [
                -74.17636880109187,
                40.64233003804384
              ],
              [
                -74.17639077729885,
                40.64234371455394
              ],
              [
                -74.1764825728471,
                40.64239153953694
              ],
              [
                -74.17668345519618,
                40.64247269041299
              ],
              [
                -74.1766862119555,
                40.64247174801642
              ],
              [
                -74.17669428881098,
                40.64246747015741
              ],
              [
                -74.17669931832964,
                40.6424546486591
              ],
              [
                -74.17670101435597,
                40.64243842324041
              ],
              [
                -74.17669557683358,
                40.64241909073796
              ],
              [
                -74.1767048915117,
                40.64241691128393
              ],
              [
                -74.17671593066746,
                40.642427769783616
              ],
              [
                -74.17672611574272,
                40.64243942384839
              ],
              [
                -74.17673612174508,
                40.64246426035349
              ],
              [
                -74.17674814546466,
                40.64251162084296
              ],
              [
                -74.17676112814671,
                40.642566221118834
              ],
              [
                -74.1767696150388,
                40.64259410060224
              ],
              [
                -74.17677181116849,
                40.64260344164405
              ],
              [
                -74.17677419851748,
                40.64261423101283
              ],
              [
                -74.17677183831798,
                40.642632700495454
              ],
              [
                -74.17676738531827,
                40.64264986831357
              ],
              [
                -74.17677282288471,
                40.64266920248744
              ],
              [
                -74.17678179644722,
                40.64270801816725
              ],
              [
                -74.17679314456521,
                40.642742992950076
              ],
              [
                -74.17680514054855,
                40.64276109340948
              ],
              [
                -74.17681380742438,
                40.642775789944245
              ],
              [
                -74.17681514794688,
                40.642785929402294
              ],
              [
                -74.17681193300832,
                40.64280519562605
              ],
              [
                -74.17681290478336,
                40.64282706735282
              ],
              [
                -74.17682386895896,
                40.64285914598334
              ],
              [
                -74.17684737674092,
                40.64288440901792
              ],
              [
                -74.17686764286216,
                40.64289968009175
              ],
              [
                -74.17688733532462,
                40.64291060534411
              ],
              [
                -74.17692854506882,
                40.64295353082521
              ],
              [
                -74.17698088246381,
                40.64300072270873
              ],
              [
                -74.17703597759045,
                40.64304697216207
              ],
              [
                -74.1770577629456,
                40.643059200719584
              ],
              [
                -74.17707592215211,
                40.64305853816041
              ],
              [
                -74.17708741934894,
                40.643051072799175
              ],
              [
                -74.17709330160173,
                40.64303745504743
              ],
              [
                -74.17709899306082,
                40.64302238729297
              ],
              [
                -74.1771187583656,
                40.643012090692075
              ],
              [
                -74.17713187740289,
                40.6430096222208
              ],
              [
                -74.17714537910913,
                40.643010048222365
              ],
              [
                -74.17715118704483,
                40.64301765060814
              ],
              [
                -74.17715528395614,
                40.64302684648237
              ],
              [
                -74.17715510495633,
                40.643040027248084
              ],
              [
                -74.17715454548046,
                40.643050311693315
              ],
              [
                -74.17714838240748,
                40.64306907418532
              ],
              [
                -74.1771434442994,
                40.64307530531673
              ],
              [
                -74.17713784267173,
                40.643083780512065
              ],
              [
                -74.17713480593916,
                40.64308986715253
              ],
              [
                -74.17714042132805,
                40.643096019371725
              ],
              [
                -74.17717352557737,
                40.64311396330433
              ],
              [
                -74.1772111857094,
                40.64312277860308
              ],
              [
                -74.1772411227328,
                40.643109508083995
              ],
              [
                -74.17725898931039,
                40.64309935879524
              ],
              [
                -74.17726935092438,
                40.64309783322198
              ],
              [
                -74.17732826853079,
                40.6430640495109
              ],
              [
                -74.17735372544297,
                40.64303868795123
              ],
              [
                -74.17738574360622,
                40.64301209096009
              ],
              [
                -74.17739591374877,
                40.64300911637971
              ],
              [
                -74.17742254808788,
                40.64302170243326
              ],
              [
                -74.17744138462227,
                40.64303342601288
              ],
              [
                -74.17745864523722,
                40.64303526866406
              ],
              [
                -74.17748837295586,
                40.64302619231759
              ],
              [
                -74.17753128407476,
                40.64301404792928
              ],
              [
                -74.17764021549506,
                40.64297629966751
              ],
              [
                -74.17768636299832,
                40.64294859651954
              ],
              [
                -74.17772066748054,
                40.642916000216225
              ],
              [
                -74.17772806566205,
                40.642907282314525
              ],
              [
                -74.17774310515362,
                40.64288955731427
              ],
              [
                -74.17775957363405,
                40.6428488354446
              ],
              [
                -74.1777693527224,
                40.642802944427494
              ],
              [
                -74.17777330613944,
                40.6427273808469
              ],
              [
                -74.17778543224081,
                40.6426505918904
              ],
              [
                -74.17778978404121,
                40.64263565684429
              ],
              [
                -74.17779804018457,
                40.64260731346272
              ],
              [
                -74.17781237608769,
                40.64259984414208
              ],
              [
                -74.17782359552945,
                40.642603587143725
              ],
              [
                -74.17784088061585,
                40.64261134848987
              ],
              [
                -74.17786677190573,
                40.642622597203086
              ],
              [
                -74.1778907931597,
                40.64263039369055
              ],
              [
                -74.1779307085354,
                40.64262511408804
              ],
              [
                -74.17794003358942,
                40.642619489792125
              ],
              [
                -74.17795599825318,
                40.642590235344315
              ],
              [
                -74.177959521865,
                40.642577817352326
              ],
              [
                -74.17798349357308,
                40.64255681721493
              ],
              [
                -74.17800763457008,
                40.64254330299948
              ],
              [
                -74.17806133296799,
                40.64253019082057
              ],
              [
                -74.17810992650661,
                40.64252918461098
              ],
              [
                -74.17813298867733,
                40.64253782067966
              ],
              [
                -74.17815221653208,
                40.64254981912208
              ],
              [
                -74.17817324087041,
                40.64256448217051
              ],
              [
                -74.17820217655361,
                40.642580696333994
              ],
              [
                -74.17824602581983,
                40.6425925534066
              ],
              [
                -74.17828874493674,
                40.642604104799396
              ],
              [
                -74.17831712917216,
                40.64260298977853
              ],
              [
                -74.17834127011962,
                40.6425894759958
              ],
              [
                -74.17836130963984,
                40.64256513264287
              ],
              [
                -74.17836830862726,
                40.642545428951436
              ],
              [
                -74.17836001223176,
                40.64251707015714
              ],
              [
                -74.17834761707202,
                40.64247788162496
              ],
              [
                -74.17828674141411,
                40.64233356708218
              ],
              [
                -74.17823909996137,
                40.64218105189992
              ],
              [
                -74.17823600662598,
                40.64214728758993
              ],
              [
                -74.17824494768865,
                40.64213182054494
              ],
              [
                -74.17825662146018,
                40.64212922786821
              ],
              [
                -74.178272612486,
                40.64213981770081
              ],
              [
                -74.17829126398283,
                40.64216249697142
              ],
              [
                -74.17830667986333,
                40.64218377049654
              ],
              [
                -74.17832032191038,
                40.64220829599011
              ],
              [
                -74.17833396352167,
                40.64223281930481
              ],
              [
                -74.17834923580007,
                40.64225252296742
              ],
              [
                -74.17836990148625,
                40.6422802242935
              ],
              [
                -74.17839512367179,
                40.64232938166007
              ],
              [
                -74.17842482969903,
                40.64239921197955
              ],
              [
                -74.1784702390646,
                40.64249345634389
              ],
              [
                -74.1784831382736,
                40.642521175733
              ],
              [
                -74.17851356154607,
                40.64254796376642
              ],
              [
                -74.17859909822172,
                40.642571425701284
              ],
              [
                -74.17864062006429,
                40.6425723740862
              ],
              [
                -74.17867612428056,
                40.642564174015035
              ],
              [
                -74.17873543165993,
                40.642527485532895
              ],
              [
                -74.17877071758866,
                40.64249996468183
              ],
              [
                -74.17878114482909,
                40.6424950715423
              ],
              [
                -74.1787907843686,
                40.64249850345951
              ],
              [
                -74.17880190796936,
                40.64251250982811
              ],
              [
                -74.1788118087668,
                40.64253013085435
              ],
              [
                -74.17882094373518,
                40.6425450312311
              ],
              [
                -74.17883755862744,
                40.64257373791308
              ],
              [
                -74.17888889246925,
                40.642687392326735
              ],
              [
                -74.1788900868082,
                40.6426880074771
              ],
              [
                -74.17890900554816,
                40.64269776490691
              ],
              [
                -74.17892204821959,
                40.64271008897004
              ],
              [
                -74.17893187791923,
                40.64272692673195
              ],
              [
                -74.17893820887467,
                40.64274513194574
              ],
              [
                -74.1789462643082,
                40.6427652188389
              ],
              [
                -74.17898445948865,
                40.6428598464723
              ],
              [
                -74.17901881653239,
                40.64292390473273
              ],
              [
                -74.17903926810845,
                40.642949250649174
              ],
              [
                -74.17905058329731,
                40.64295969520147
              ],
              [
                -74.17906331341108,
                40.64296296296409
              ],
              [
                -74.17908174926843,
                40.64296632352995
              ],
              [
                -74.17912286465878,
                40.642985441200274
              ],
              [
                -74.17913986136752,
                40.64299006113411
              ],
              [
                -74.17915856161366,
                40.64299064348187
              ],
              [
                -74.17918728067563,
                40.64298753999648
              ],
              [
                -74.17922112974624,
                40.64297824334814
              ],
              [
                -74.17924404807638,
                40.642968345044125
              ],
              [
                -74.1792748897107,
                40.642938971062776
              ],
              [
                -74.17929353576812,
                40.64291243442381
              ],
              [
                -74.17930397810764,
                40.64288524146344
              ],
              [
                -74.17931410709717,
                40.64281281422096
              ],
              [
                -74.17931361676912,
                40.64277087517095
              ],
              [
                -74.17931618712414,
                40.64274665438114
              ],
              [
                -74.17932201695177,
                40.64272663850571
              ],
              [
                -74.17932835858704,
                40.64270696107765
              ],
              [
                -74.17934017622518,
                40.6426895473017
              ],
              [
                -74.17936728876705,
                40.6426038098765
              ],
              [
                -74.17937786209191,
                40.64256237609079
              ],
              [
                -74.1793935826967,
                40.64253526652582
              ],
              [
                -74.17940491070927,
                40.642507296515255
              ],
              [
                -74.17946208703786,
                40.64237325114848
              ],
              [
                -74.17947213042252,
                40.64234169936052
              ],
              [
                -74.179483390033,
                40.64228161971553
              ],
              [
                -74.17948560678192,
                40.642263985362575
              ],
              [
                -74.17950717898177,
                40.64222233302617
              ],
              [
                -74.17952274275385,
                40.64218202175161
              ],
              [
                -74.17953398205535,
                40.64214785246401
              ],
              [
                -74.17954304267344,
                40.64204811398006
              ],
              [
                -74.1795490462878,
                40.64196204005944
              ],
              [
                -74.17955383456189,
                40.64193587581603
              ],
              [
                -74.1795575356884,
                40.641924001629974
              ],
              [
                -74.17956888859025,
                40.6419171942972
              ],
              [
                -74.1795871372141,
                40.64191768346404
              ],
              [
                -74.17959758214889,
                40.64192187162108
              ],
              [
                -74.17960181816804,
                40.64193149924905
              ],
              [
                -74.17960596648476,
                40.64196631043894
              ],
              [
                -74.1796096733729,
                40.642032892135944
              ],
              [
                -74.17961213567315,
                40.642044073849945
              ],
              [
                -74.17960229355927,
                40.64207780354592
              ],
              [
                -74.17960437994127,
                40.64212148307463
              ],
              [
                -74.1796007467559,
                40.64214977547622
              ],
              [
                -74.17959150128301,
                40.64217435030055
              ],
              [
                -74.17958207750978,
                40.64218652995008
              ],
              [
                -74.17957035016218,
                40.64221014250998
              ],
              [
                -74.17955611609806,
                40.64224301159537
              ],
              [
                -74.17955292697471,
                40.64228660604772
              ],
              [
                -74.17954696292499,
                40.64230516849862
              ],
              [
                -74.17954188859915,
                40.64233864698984
              ],
              [
                -74.17954253318479,
                40.642356126938104
              ],
              [
                -74.17953248940147,
                40.64237198832542
              ],
              [
                -74.17952914022524,
                40.642377275065385
              ],
              [
                -74.17952382009699,
                40.64239762789443
              ],
              [
                -74.17949462433023,
                40.64248676106769
              ],
              [
                -74.17948402624658,
                40.642522720064
              ],
              [
                -74.17947260813814,
                40.64254449266458
              ],
              [
                -74.17946977164036,
                40.6425501183509
              ],
              [
                -74.17945445077008,
                40.642565895155556
              ],
              [
                -74.1794392413294,
                40.642593341501566
              ],
              [
                -74.17942844383572,
                40.64261143168502
              ],
              [
                -74.17941578547331,
                40.64265626018011
              ],
              [
                -74.17940844705623,
                40.64268073317074
              ],
              [
                -74.17940401630874,
                40.64273169277699
              ],
              [
                -74.17940157858908,
                40.64274167442417
              ],
              [
                -74.17939512553814,
                40.642749680637856
              ],
              [
                -74.17938528361431,
                40.64279910390166
              ],
              [
                -74.17938838983962,
                40.64281207532977
              ],
              [
                -74.17939451000966,
                40.642816127516774
              ],
              [
                -74.17940530835232,
                40.64282942066175
              ],
              [
                -74.17940728473074,
                40.642861428467214
              ],
              [
                -74.1794109227674,
                40.64288021030177
              ],
              [
                -74.1794168668049,
                40.64290324795664
              ],
              [
                -74.17940773684909,
                40.642957271454904
              ],
              [
                -74.17942116737596,
                40.64297383785648
              ],
              [
                -74.17943368167663,
                40.6429917154257
              ],
              [
                -74.17943548051005,
                40.643011344976344
              ],
              [
                -74.17942351984416,
                40.643027762691275
              ],
              [
                -74.17941014309534,
                40.64303439139716
              ],
              [
                -74.17939283485812,
                40.64303767917798
              ],
              [
                -74.1793807514003,
                40.64304147814553
              ],
              [
                -74.17937253045648,
                40.64304783310402
              ],
              [
                -74.17937080384323,
                40.64306291673371
              ],
              [
                -74.17938080286824,
                40.64307027642212
              ],
              [
                -74.17939583435012,
                40.64308170641112
              ],
              [
                -74.17940578317517,
                40.643094196743085
              ],
              [
                -74.17940832646521,
                40.6431106318674
              ],
              [
                -74.17940482802032,
                40.64312896647636
              ],
              [
                -74.17939533407673,
                40.64314406775223
              ],
              [
                -74.17938596172657,
                40.643154823812225
              ],
              [
                -74.17937498303432,
                40.64317631728317
              ],
              [
                -74.17935247481581,
                40.643218940035844
              ],
              [
                -74.17933890723586,
                40.64322913069166
              ],
              [
                -74.1793295090677,
                40.64323397067766
              ],
              [
                -74.17930807826693,
                40.64323747679809
              ],
              [
                -74.17928587918527,
                40.643238262943115
              ],
              [
                -74.17926967251843,
                40.64324228075334
              ],
              [
                -74.17925404421624,
                40.64325258048979
              ],
              [
                -74.1792363054284,
                40.643268121131555
              ],
              [
                -74.17920770481655,
                40.64328384455109
              ],
              [
                -74.17917908126803,
                40.643293652392295
              ],
              [
                -74.17916203645535,
                40.643294163609156
              ],
              [
                -74.17913343601877,
                40.64329292305074
              ],
              [
                -74.17911394582485,
                40.643300667436826
              ],
              [
                -74.17910124230973,
                40.64332028004216
              ],
              [
                -74.17907959489555,
                40.643338395464895
              ],
              [
                -74.1790659065113,
                40.643352931636656
              ],
              [
                -74.17904538824455,
                40.64337769585
              ],
              [
                -74.17903555928557,
                40.64339478702068
              ],
              [
                -74.17903563701373,
                40.64348039474873
              ],
              [
                -74.1790187639507,
                40.643556251888086
              ],
              [
                -74.17900814920617,
                40.643649529808684
              ],
              [
                -74.17900148715438,
                40.64368420739953
              ],
              [
                -74.17900196853455,
                40.64371771675018
              ],
              [
                -74.17900604586508,
                40.643756557968395
              ],
              [
                -74.17900895017787,
                40.64379388370456
              ],
              [
                -74.17901676603547,
                40.64382266218595
              ],
              [
                -74.1790261907259,
                40.64385766939297
              ],
              [
                -74.17903966591282,
                40.64390863393699
              ],
              [
                -74.17904410570114,
                40.643985330885194
              ],
              [
                -74.1790596462759,
                40.644070116257964
              ],
              [
                -74.1790494161959,
                40.644173236895035
              ],
              [
                -74.17904565802472,
                40.64426220585288
              ],
              [
                -74.17905352462097,
                40.64431978260426
              ],
              [
                -74.17905513344847,
                40.64435993740098
              ],
              [
                -74.17905647832927,
                40.64440286989942
              ],
              [
                -74.17906767743065,
                40.64445159008092
              ],
              [
                -74.17908144140392,
                40.6445056970058
              ],
              [
                -74.17907466583888,
                40.644646507056514
              ],
              [
                -74.1791061325883,
                40.6448768145412
              ],
              [
                -74.17912788280461,
                40.64495022357106
              ],
              [
                -74.17914433076402,
                40.644988406555434
              ],
              [
                -74.17915399385407,
                40.645014721149856
              ],
              [
                -74.17917151967094,
                40.64503075364965
              ],
              [
                -74.17919288123369,
                40.6450434254078
              ],
              [
                -74.17920271291945,
                40.645046927855404
              ],
              [
                -74.17931302542273,
                40.644984837802625
              ],
              [
                -74.17931557745557,
                40.64498333022863
              ],
              [
                -74.17931777023503,
                40.64498152419132
              ],
              [
                -74.17931954434904,
                40.64497946862413
              ],
              [
                -74.1793208517291,
                40.64497721922148
              ],
              [
                -74.17932165695264,
                40.644974836929514
              ],
              [
                -74.17932193820279,
                40.644972386295024
              ],
              [
                -74.17932168785909,
                40.644969933716446
              ],
              [
                -74.17932091270441,
                40.644967545644946
              ],
              [
                -74.17931963374143,
                40.64496528678373
              ],
              [
                -74.17931836177972,
                40.64496371215445
              ],
              [
                -74.17930796114265,
                40.64495342619408
              ],
              [
                -74.17929323036354,
                40.644941152681376
              ],
              [
                -74.17928120941691,
                40.644927271078465
              ],
              [
                -74.1792721959103,
                40.64491212506001
              ],
              [
                -74.17926641299384,
                40.64489608960431
              ],
              [
                -74.17926163405197,
                40.64488783447277
              ],
              [
                -74.17925868751354,
                40.644879096020624
              ],
              [
                -74.17925765235418,
                40.6448701084659
              ],
              [
                -74.17925855631869,
                40.6448611127034
              ],
              [
                -74.17926137517728,
                40.644852349847696
              ],
              [
                -74.17926603337476,
                40.64484405477082
              ],
              [
                -74.17927240605663,
                40.64483644980667
              ],
              [
                -74.17928032241431,
                40.6448297387919
              ],
              [
                -74.17928957026453,
                40.64482410160241
              ],
              [
                -74.1793222293982,
                40.64473350225572
              ],
              [
                -74.17931470191704,
                40.64469713345993
              ],
              [
                -74.17930285901164,
                40.64468082142819
              ],
              [
                -74.17929490273036,
                40.644663193898964
              ],
              [
                -74.17929107338853,
                40.6446447833094
              ],
              [
                -74.17929148664743,
                40.64462614574843
              ],
              [
                -74.1792961300211,
                40.644607844160234
              ],
              [
                -74.1793048632539,
                40.64459043134046
              ],
              [
                -74.17929700922963,
                40.64458163085817
              ],
              [
                -74.17929105076134,
                40.64457200591713
              ],
              [
                -74.17928713331075,
                40.64456179148904
              ],
              [
                -74.17928535251289,
                40.64455123693612
              ],
              [
                -74.17928575184081,
                40.644540599924156
              ],
              [
                -74.17928832154499,
                40.64453014013178
              ],
              [
                -74.17929299889084,
                40.64452011291114
              ],
              [
                -74.17930259638361,
                40.644514896281905
              ],
              [
                -74.1793109900664,
                40.64450858920713
              ],
              [
                -74.17931796869652,
                40.64450135041597
              ],
              [
                -74.17932335664443,
                40.64449336208634
              ],
              [
                -74.17932701831296,
                40.644484825259305
              ],
              [
                -74.17932886154996,
                40.64447595477976
              ],
              [
                -74.17932883996812,
                40.64446697388958
              ],
              [
                -74.17932695411096,
                40.64445810860943
              ],
              [
                -74.17932325144032,
                40.64444958205001
              ],
              [
                -74.17931782514128,
                40.64444160879774
              ],
              [
                -74.17931081177709,
                40.64443438951364
              ],
              [
                -74.17933117249432,
                40.64440051177255
              ],
              [
                -74.17972987752029,
                40.64438070211185
              ],
              [
                -74.17977452733285,
                40.64516667984792
              ],
              [
                -74.17984839409118,
                40.64516485991633
              ],
              [
                -74.17984376950355,
                40.64511030338695
              ],
              [
                -74.17988406361408,
                40.64511024036652
              ],
              [
                -74.17989568340026,
                40.64526879647802
              ]
            ]
          ],
          [
            [
              [
                -74.15945602438185,
                40.641448333324036
              ],
              [
                -74.15931925561976,
                40.641556386571736
              ],
              [
                -74.15920024534566,
                40.6416504039774
              ],
              [
                -74.158810937453,
                40.641758634740285
              ],
              [
                -74.15874378147612,
                40.64200383984838
              ],
              [
                -74.15869278737433,
                40.64219001147033
              ],
              [
                -74.15866125960514,
                40.64230510545717
              ],
              [
                -74.15857610598304,
                40.6424293511026
              ],
              [
                -74.15855043770534,
                40.642497501306394
              ],
              [
                -74.15848495839121,
                40.642538466885725
              ],
              [
                -74.15840347672427,
                40.642541603881966
              ],
              [
                -74.15827543717339,
                40.642563383838365
              ],
              [
                -74.1581989309435,
                40.642600924229356
              ],
              [
                -74.15813451603343,
                40.642632530662986
              ],
              [
                -74.1581057339996,
                40.64268885333524
              ],
              [
                -74.1580701364351,
                40.64275853874279
              ],
              [
                -74.15805052271504,
                40.64286755565935
              ],
              [
                -74.15799119129825,
                40.64298873896387
              ],
              [
                -74.15791589406754,
                40.64308269445287
              ],
              [
                -74.15782859631487,
                40.64314639242652
              ],
              [
                -74.15770553736039,
                40.64321014591431
              ],
              [
                -74.15755898947857,
                40.643234778165706
              ],
              [
                -74.15743349200979,
                40.643302857779
              ],
              [
                -74.1575055522123,
                40.64337992447659
              ],
              [
                -74.15762669194054,
                40.64348424661619
              ],
              [
                -74.15773024039142,
                40.643573417844465
              ],
              [
                -74.15777609272376,
                40.64363087087956
              ],
              [
                -74.15782088030684,
                40.643662607867775
              ],
              [
                -74.1578517562741,
                40.64369662171831
              ],
              [
                -74.15791348653059,
                40.64374875882984
              ],
              [
                -74.1579712715804,
                40.64381302168961
              ],
              [
                -74.15798759520317,
                40.64386178898304
              ],
              [
                -74.16146036002637,
                40.644294969882374
              ],
              [
                -74.16147514233892,
                40.64410152646554
              ],
              [
                -74.16143754262458,
                40.6439036986013
              ],
              [
                -74.16139280510836,
                40.64366821292528
              ],
              [
                -74.16134686994936,
                40.643426460821644
              ],
              [
                -74.16125491873757,
                40.64294256044936
              ],
              [
                -74.16123004765561,
                40.6428116688183
              ],
              [
                -74.16120187191912,
                40.6426633650289
              ],
              [
                -74.16120631312614,
                40.64235864106052
              ],
              [
                -74.16119293221192,
                40.6422561721368
              ],
              [
                -74.161176743771,
                40.64213205724615
              ],
              [
                -74.16117897104701,
                40.642006612330825
              ],
              [
                -74.16114204605087,
                40.64191164984924
              ],
              [
                -74.16111242522162,
                40.64183545373729
              ],
              [
                -74.16102490269706,
                40.64179924136286
              ],
              [
                -74.16094538402464,
                40.6417872393533
              ],
              [
                -74.16085388585509,
                40.64175406652501
              ],
              [
                -74.16077833143676,
                40.64173903462685
              ],
              [
                -74.16069638162347,
                40.64168952114458
              ],
              [
                -74.16058341643887,
                40.64166664358547
              ],
              [
                -74.16046972940245,
                40.64164362251296
              ],
              [
                -74.16032485634236,
                40.641576178173665
              ],
              [
                -74.1601497797168,
                40.641494681697715
              ],
              [
                -74.15997875699604,
                40.64144648083638
              ],
              [
                -74.15974815874293,
                40.64141652579022
              ],
              [
                -74.15945602438185,
                40.641448333324036
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.00017124910387,
        "objectid": 159,
        "shape_leng": 0.064363233206,
        "location_id": 159,
        "zone": "Melrose South",
        "borough": "Bronx"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.91189154999992,
                40.82214148199989
              ],
              [
                -73.91287633399996,
                40.822441347999884
              ],
              [
                -73.91450553699998,
                40.822941856999925
              ],
              [
                -73.91621588599988,
                40.82346792999992
              ],
              [
                -73.91587735799988,
                40.824103731999855
              ],
              [
                -73.91677400599986,
                40.82438708699987
              ],
              [
                -73.9168919579999,
                40.824311925999915
              ],
              [
                -73.917126938,
                40.82416263099988
              ],
              [
                -73.91719007399992,
                40.82412251699993
              ],
              [
                -73.91725010299993,
                40.824084376999956
              ],
              [
                -73.9173178239999,
                40.82404134999992
              ],
              [
                -73.9174045909999,
                40.82398622199988
              ],
              [
                -73.91746772799985,
                40.8239461069999
              ],
              [
                -73.91756599399989,
                40.823883671999944
              ],
              [
                -73.91782151699984,
                40.823718370999885
              ],
              [
                -73.91788272099983,
                40.8236787769999
              ],
              [
                -73.91833693999992,
                40.823384931999904
              ],
              [
                -73.91875570599991,
                40.8231152179999
              ],
              [
                -73.91888836899983,
                40.823029773999934
              ],
              [
                -73.91894597199995,
                40.82299267299992
              ],
              [
                -73.91929142000006,
                40.82277017699989
              ],
              [
                -73.91938041799983,
                40.82271235199995
              ],
              [
                -73.91944303599995,
                40.822671667999884
              ],
              [
                -73.91980424599993,
                40.822436974999896
              ],
              [
                -73.91993471699983,
                40.82235220199986
              ],
              [
                -73.92038676299994,
                40.82205848399988
              ],
              [
                -73.92048603299985,
                40.82197953699993
              ],
              [
                -73.92054346999996,
                40.82193385699987
              ],
              [
                -73.92066539199996,
                40.82183689399994
              ],
              [
                -73.92114408999996,
                40.82156300199989
              ],
              [
                -73.92194764099997,
                40.82103154499987
              ],
              [
                -73.92265261199995,
                40.82049431499993
              ],
              [
                -73.92402557199983,
                40.8192853299999
              ],
              [
                -73.92454726099986,
                40.81871262399987
              ],
              [
                -73.92518486499998,
                40.81801266899989
              ],
              [
                -73.92621971999989,
                40.816758655999905
              ],
              [
                -73.92662718199988,
                40.81611895899993
              ],
              [
                -73.92568897199988,
                40.81587494499995
              ],
              [
                -73.92550133099999,
                40.81583301299993
              ],
              [
                -73.92505519900006,
                40.81572157599992
              ],
              [
                -73.92480953899991,
                40.81565901999995
              ],
              [
                -73.92399951299991,
                40.81543891199993
              ],
              [
                -73.92395483099982,
                40.81550096099991
              ],
              [
                -73.92390804799992,
                40.815565926999945
              ],
              [
                -73.92376838899996,
                40.81575986499996
              ],
              [
                -73.92372701399987,
                40.815817319999915
              ],
              [
                -73.92309299899993,
                40.81669772799995
              ],
              [
                -73.92124519699988,
                40.816219396999955
              ],
              [
                -73.92158392200005,
                40.815550631999955
              ],
              [
                -73.9213038339999,
                40.81547724799986
              ],
              [
                -73.92048117399989,
                40.8145636869999
              ],
              [
                -73.92034892799985,
                40.8146288639999
              ],
              [
                -73.91946744899994,
                40.81507370999988
              ],
              [
                -73.91861739800004,
                40.815523135999854
              ],
              [
                -73.91768626999985,
                40.8160812429999
              ],
              [
                -73.91677117699994,
                40.81569882899992
              ],
              [
                -73.9149549369999,
                40.81493646599995
              ],
              [
                -73.91295450799997,
                40.81426933499994
              ],
              [
                -73.91181210799998,
                40.813883314999885
              ],
              [
                -73.91094778999998,
                40.813620920999874
              ],
              [
                -73.91043307699985,
                40.813516376999885
              ],
              [
                -73.91038742199996,
                40.8135071039999
              ],
              [
                -73.91033664499996,
                40.813496788999885
              ],
              [
                -73.91029615499986,
                40.81348856499991
              ],
              [
                -73.91013342199993,
                40.81345551199998
              ],
              [
                -73.91001840399991,
                40.81343214799989
              ],
              [
                -73.9095236779999,
                40.813327805999975
              ],
              [
                -73.90949684799988,
                40.813322147999926
              ],
              [
                -73.90907451799984,
                40.81323307299989
              ],
              [
                -73.90815917699986,
                40.8130435759999
              ],
              [
                -73.90724503699998,
                40.81285516299992
              ],
              [
                -73.90632991800001,
                40.81266707399989
              ],
              [
                -73.9054135989999,
                40.81247619599993
              ],
              [
                -73.9044655149999,
                40.81228195999996
              ],
              [
                -73.9041833779999,
                40.813086454999905
              ],
              [
                -73.90380412299992,
                40.81413184199994
              ],
              [
                -73.9034699719999,
                40.81507578799996
              ],
              [
                -73.90306443799986,
                40.8161067619999
              ],
              [
                -73.90300983199995,
                40.81631779199992
              ],
              [
                -73.90257859099984,
                40.81736979399992
              ],
              [
                -73.90210743299993,
                40.81850519199991
              ],
              [
                -73.90166319399991,
                40.81955042599994
              ],
              [
                -73.9016012119998,
                40.81969485899989
              ],
              [
                -73.9012927759998,
                40.820475442999914
              ],
              [
                -73.90266819199994,
                40.82080315899992
              ],
              [
                -73.90379310199981,
                40.821091099999876
              ],
              [
                -73.90478103799988,
                40.82134144499991
              ],
              [
                -73.90489653200002,
                40.82137070999988
              ],
              [
                -73.90525022600004,
                40.821456921999896
              ],
              [
                -73.90532770299998,
                40.82147580599995
              ],
              [
                -73.90545592300005,
                40.82150705899991
              ],
              [
                -73.90552466299982,
                40.82152381299992
              ],
              [
                -73.90565890299979,
                40.821556532999864
              ],
              [
                -73.90645722199996,
                40.821715363999886
              ],
              [
                -73.90742681699992,
                40.82194506199991
              ],
              [
                -73.90841854199991,
                40.82218127099991
              ],
              [
                -73.90945760899984,
                40.82243240199992
              ],
              [
                -73.90990894100004,
                40.822551107999914
              ],
              [
                -73.91069998799996,
                40.8223241139999
              ],
              [
                -73.91107298599978,
                40.82224294299992
              ],
              [
                -73.91135642300002,
                40.82216364799994
              ],
              [
                -73.91136683199993,
                40.822163151999874
              ],
              [
                -73.91137726399995,
                40.822162555999945
              ],
              [
                -73.91138768499987,
                40.82216185299992
              ],
              [
                -73.9113981169998,
                40.822161058999875
              ],
              [
                -73.91140856199999,
                40.82216015799987
              ],
              [
                -73.911418994,
                40.82215915699986
              ],
              [
                -73.91142942699986,
                40.82215805799994
              ],
              [
                -73.9114398599999,
                40.822156858999925
              ],
              [
                -73.91145026999986,
                40.822155560999896
              ],
              [
                -73.91146067899994,
                40.82215415499997
              ],
              [
                -73.91147107699982,
                40.82215264999993
              ],
              [
                -73.91148146299997,
                40.822151046999885
              ],
              [
                -73.91149182600002,
                40.822149333999896
              ],
              [
                -73.91150216499999,
                40.822147531999875
              ],
              [
                -73.91151249299988,
                40.822145621999915
              ],
              [
                -73.9115227839998,
                40.82214361299988
              ],
              [
                -73.91153305199994,
                40.82214150499988
              ],
              [
                -73.9115432849999,
                40.82213929699986
              ],
              [
                -73.91155348299996,
                40.82213699099988
              ],
              [
                -73.91156365699992,
                40.822134584999894
              ],
              [
                -73.91157378299995,
                40.822132080999914
              ],
              [
                -73.9115838739999,
                40.82212947799998
              ],
              [
                -73.91159391799995,
                40.82212677499996
              ],
              [
                -73.9116039149999,
                40.8221239719999
              ],
              [
                -73.91161386399986,
                40.8221210799999
              ],
              [
                -73.91162376599982,
                40.8221180889999
              ],
              [
                -73.91163361999985,
                40.8221150089999
              ],
              [
                -73.91164340399992,
                40.8221118279999
              ],
              [
                -73.91165313999997,
                40.82210855799985
              ],
              [
                -73.91166281800001,
                40.82210519699988
              ],
              [
                -73.91167243599985,
                40.8221017459999
              ],
              [
                -73.91168198299992,
                40.82209820599994
              ],
              [
                -73.91169147099988,
                40.822094565999876
              ],
              [
                -73.9117008879999,
                40.82209084599988
              ],
              [
                -73.91171023399987,
                40.82208704299993
              ],
              [
                -73.91171949699986,
                40.8220831519999
              ],
              [
                -73.9117286999999,
                40.82207916899994
              ],
              [
                -73.91173782099985,
                40.82207510599989
              ],
              [
                -73.91174685999992,
                40.82207096199994
              ],
              [
                -73.91175582700001,
                40.82206673699992
              ],
              [
                -73.91176469899987,
                40.8220624379999
              ],
              [
                -73.91177350099997,
                40.82205805099993
              ],
              [
                -73.91178220799999,
                40.822053599999876
              ],
              [
                -73.91179083099985,
                40.82204905899987
              ],
              [
                -73.91179935999997,
                40.8220444549999
              ],
              [
                -73.91180780599983,
                40.82203976999995
              ],
              [
                -73.91181615799985,
                40.82203502199992
              ],
              [
                -73.91189154999992,
                40.82214148199989
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000354370128323,
        "objectid": 157,
        "shape_leng": 0.131300217777,
        "location_id": 157,
        "zone": "Maspeth",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.89783236899989,
                40.73516035399992
              ],
              [
                -73.89806882999987,
                40.73518359899987
              ],
              [
                -73.89806895999997,
                40.73518360799989
              ],
              [
                -73.89806910099988,
                40.73518361699996
              ],
              [
                -73.89830786699996,
                40.735194173999965
              ],
              [
                -73.89830799699995,
                40.73519418399997
              ],
              [
                -73.89830812699986,
                40.73519418399992
              ],
              [
                -73.8985475869999,
                40.73519190999994
              ],
              [
                -73.89854772799987,
                40.735191909999855
              ],
              [
                -73.89854787099992,
                40.7351919009999
              ],
              [
                -73.89878621299994,
                40.73517681999992
              ],
              [
                -73.89878634299994,
                40.73517681099992
              ],
              [
                -73.89878646099986,
                40.73517679299993
              ],
              [
                -73.89902209999995,
                40.73514908399993
              ],
              [
                -73.89922944799997,
                40.73511159899988
              ],
              [
                -73.89943348500005,
                40.735062589999934
              ],
              [
                -73.89963257999993,
                40.73500235799992
              ],
              [
                -73.89982513099983,
                40.734931394999926
              ],
              [
                -73.90000969399993,
                40.73485031999995
              ],
              [
                -73.90015343399983,
                40.734796404999926
              ],
              [
                -73.90028989999986,
                40.73472960699994
              ],
              [
                -73.90029003099994,
                40.73472954299993
              ],
              [
                -73.90029014899983,
                40.734729471999906
              ],
              [
                -73.90041629699991,
                40.734650803999884
              ],
              [
                -73.90041646300001,
                40.73465070499993
              ],
              [
                -73.90041661699985,
                40.73465058799992
              ],
              [
                -73.90053009099984,
                40.73456135699992
              ],
              [
                -73.90053014999994,
                40.73456130199992
              ],
              [
                -73.90062887299989,
                40.73446333999989
              ],
              [
                -73.90062893299998,
                40.734463285999894
              ],
              [
                -73.90062897999994,
                40.734463231999946
              ],
              [
                -73.9007113889999,
                40.73435872699986
              ],
              [
                -73.90091094899985,
                40.7341260369999
              ],
              [
                -73.90107970900003,
                40.73391463399987
              ],
              [
                -73.90259650799989,
                40.73210442799993
              ],
              [
                -73.90416853299989,
                40.73018659099991
              ],
              [
                -73.904218336,
                40.730125830999945
              ],
              [
                -73.90518057899999,
                40.72895657699995
              ],
              [
                -73.90557740099993,
                40.72863668399986
              ],
              [
                -73.90573360899982,
                40.728510757999906
              ],
              [
                -73.90673028099992,
                40.72773268299987
              ],
              [
                -73.90693049899986,
                40.72757602999992
              ],
              [
                -73.90706867299988,
                40.7274665229999
              ],
              [
                -73.9071225309999,
                40.72742472799994
              ],
              [
                -73.90716705199989,
                40.72738865399989
              ],
              [
                -73.90730013799994,
                40.727294368999935
              ],
              [
                -73.90740997399989,
                40.727208738999934
              ],
              [
                -73.90804680899986,
                40.72673388699996
              ],
              [
                -73.90889173800001,
                40.726068424999916
              ],
              [
                -73.91134506899984,
                40.72418216999996
              ],
              [
                -73.91257187699983,
                40.723239634999864
              ],
              [
                -73.91270773499983,
                40.7232845029999
              ],
              [
                -73.91316969799992,
                40.72308152599989
              ],
              [
                -73.91384828299984,
                40.72289841199989
              ],
              [
                -73.91376963799998,
                40.722746977999854
              ],
              [
                -73.91367234399998,
                40.7226011579999
              ],
              [
                -73.91355755899988,
                40.72246288799989
              ],
              [
                -73.9134268889998,
                40.722333936999874
              ],
              [
                -73.91336473699985,
                40.72217933499995
              ],
              [
                -73.91336471299988,
                40.72217927199989
              ],
              [
                -73.91331372599998,
                40.7220773499999
              ],
              [
                -73.91328886299983,
                40.72202764999987
              ],
              [
                -73.913199729,
                40.721880043999874
              ],
              [
                -73.91309801799984,
                40.7217375959999
              ],
              [
                -73.91309792299981,
                40.72173746899997
              ],
              [
                -73.91309781699987,
                40.721737344999916
              ],
              [
                -73.9129842039999,
                40.72160084799986
              ],
              [
                -73.91245967199984,
                40.72093053399987
              ],
              [
                -73.9118888929999,
                40.720262830999886
              ],
              [
                -73.91132095099978,
                40.71962422999994
              ],
              [
                -73.91152490699984,
                40.71956777999985
              ],
              [
                -73.9123211429999,
                40.71934838599991
              ],
              [
                -73.91339850999998,
                40.71905108699989
              ],
              [
                -73.914265253,
                40.71881117399987
              ],
              [
                -73.91516686599985,
                40.71855456599994
              ],
              [
                -73.91610398900004,
                40.7182981029999
              ],
              [
                -73.9180503519999,
                40.71776542099987
              ],
              [
                -73.91930947299991,
                40.71741710199994
              ],
              [
                -73.92153810999987,
                40.71679515599987
              ],
              [
                -73.92232773978448,
                40.71659264406108
              ],
              [
                -73.92228207681381,
                40.71648327299211
              ],
              [
                -73.9222376112434,
                40.71638244049413
              ],
              [
                -73.92216958810182,
                40.716368481916874
              ],
              [
                -73.92171847814689,
                40.716277486351885
              ],
              [
                -73.92170005987498,
                40.71627377114592
              ],
              [
                -73.92140714622649,
                40.71620977997169
              ],
              [
                -73.92127646277748,
                40.716180378827495
              ],
              [
                -73.92109482826223,
                40.71614176633603
              ],
              [
                -73.92060425100038,
                40.7160360071899
              ],
              [
                -73.92057861388705,
                40.71602742469067
              ],
              [
                -73.92057300316696,
                40.71601877592619
              ],
              [
                -73.92056905536089,
                40.716003708340594
              ],
              [
                -73.92056808910688,
                40.715991072958154
              ],
              [
                -73.92056821733091,
                40.71597197396795
              ],
              [
                -73.92056920092939,
                40.71597116381607
              ],
              [
                -73.92064258940593,
                40.715773192028834
              ],
              [
                -73.92232970167696,
                40.7160765451724
              ],
              [
                -73.92279196672435,
                40.71580026374764
              ],
              [
                -73.92352547251075,
                40.71561660836784
              ],
              [
                -73.92352747398412,
                40.71560519089198
              ],
              [
                -73.92352752100395,
                40.715605083370995
              ],
              [
                -73.92353323079922,
                40.71559062456079
              ],
              [
                -73.92354470735603,
                40.71558267230683
              ],
              [
                -73.92356011347093,
                40.71557516241921
              ],
              [
                -73.9235983444235,
                40.7155612575952
              ],
              [
                -73.92366895763148,
                40.715535568440714
              ],
              [
                -73.92376389043952,
                40.715479717209874
              ],
              [
                -73.92381513128566,
                40.71544130034116
              ],
              [
                -73.92389709645242,
                40.71537152830813
              ],
              [
                -73.92397375250823,
                40.71529766471718
              ],
              [
                -73.92406244021412,
                40.715218064846695
              ],
              [
                -73.92409931290926,
                40.71518070819926
              ],
              [
                -73.92412241575492,
                40.71513804844516
              ],
              [
                -73.9240522324595,
                40.71487024514004
              ],
              [
                -73.92374911534878,
                40.71421127605487
              ],
              [
                -73.92368352152519,
                40.71408284140815
              ],
              [
                -73.92405909718518,
                40.71411155982241
              ],
              [
                -73.92404011299993,
                40.71400831299994
              ],
              [
                -73.92083937599983,
                40.713815139999966
              ],
              [
                -73.91947977199997,
                40.71373199799997
              ],
              [
                -73.91633296499982,
                40.71353115599988
              ],
              [
                -73.91569881899994,
                40.71348528899991
              ],
              [
                -73.91521146700003,
                40.71346123899988
              ],
              [
                -73.91466958199993,
                40.71343707399992
              ],
              [
                -73.91303759100005,
                40.71333207799989
              ],
              [
                -73.91077807399988,
                40.71318759999987
              ],
              [
                -73.91069895799997,
                40.71326852099986
              ],
              [
                -73.91015817899998,
                40.71382159999995
              ],
              [
                -73.91159537799996,
                40.714641132999894
              ],
              [
                -73.91173862299988,
                40.714723810999885
              ],
              [
                -73.91274485099986,
                40.71530376799992
              ],
              [
                -73.91279689999989,
                40.71534014299996
              ],
              [
                -73.91283037699982,
                40.71536090699995
              ],
              [
                -73.91245827599991,
                40.71553228999992
              ],
              [
                -73.91232805399994,
                40.71561530699993
              ],
              [
                -73.91221070499992,
                40.7157093569999
              ],
              [
                -73.912108516,
                40.715812621999866
              ],
              [
                -73.9117893419999,
                40.7161224779999
              ],
              [
                -73.91156249200004,
                40.71636931299988
              ],
              [
                -73.9115182479999,
                40.71641746599994
              ],
              [
                -73.91134270799989,
                40.716597027999974
              ],
              [
                -73.91105946199994,
                40.71684218099989
              ],
              [
                -73.91048364399984,
                40.71732796499988
              ],
              [
                -73.91002374299995,
                40.71758812399992
              ],
              [
                -73.90980535299991,
                40.71771369999993
              ],
              [
                -73.90965980099995,
                40.71779315499991
              ],
              [
                -73.90962006699982,
                40.71775153799996
              ],
              [
                -73.90955515599987,
                40.71769519699992
              ],
              [
                -73.90943398699983,
                40.717581772999885
              ],
              [
                -73.90862256599989,
                40.71748082299995
              ],
              [
                -73.90850501099996,
                40.71746899599985
              ],
              [
                -73.90768387299997,
                40.71737969499987
              ],
              [
                -73.9069637079999,
                40.717304416999866
              ],
              [
                -73.90688152399996,
                40.71731291499992
              ],
              [
                -73.90639010099999,
                40.71736369199989
              ],
              [
                -73.90617600199982,
                40.71642140399996
              ],
              [
                -73.9060207829998,
                40.715742678999916
              ],
              [
                -73.90586816399987,
                40.7150686929999
              ],
              [
                -73.90574342600004,
                40.714516011999926
              ],
              [
                -73.90571002399982,
                40.7143679749999
              ],
              [
                -73.90556258999989,
                40.713714632999896
              ],
              [
                -73.90537582199983,
                40.71287658099986
              ],
              [
                -73.90489449600001,
                40.71285681499993
              ],
              [
                -73.90477244599997,
                40.71286579999986
              ],
              [
                -73.90380184499992,
                40.71334837899985
              ],
              [
                -73.90285443299987,
                40.71379311999988
              ],
              [
                -73.90243306099984,
                40.71400341199992
              ],
              [
                -73.902045084,
                40.71419703399989
              ],
              [
                -73.90115752599986,
                40.71462231499989
              ],
              [
                -73.90071775,
                40.714835708999914
              ],
              [
                -73.89989954399981,
                40.71523272099996
              ],
              [
                -73.89926420299997,
                40.7155443909999
              ],
              [
                -73.89860294399998,
                40.71586712099985
              ],
              [
                -73.89782401399992,
                40.71623638099991
              ],
              [
                -73.89750226399987,
                40.716383867999916
              ],
              [
                -73.89758646599992,
                40.716494624999875
              ],
              [
                -73.89758653699987,
                40.71649471499991
              ],
              [
                -73.89758660799986,
                40.71649479699993
              ],
              [
                -73.89768581399989,
                40.71659970299993
              ],
              [
                -73.89779888000004,
                40.71669705899989
              ],
              [
                -73.89779893899993,
                40.716697103999884
              ],
              [
                -73.89779899799984,
                40.7166971499999
              ],
              [
                -73.89792419799988,
                40.71678533099995
              ],
              [
                -73.89792430299995,
                40.716785401999914
              ],
              [
                -73.89792442199999,
                40.71678547399989
              ],
              [
                -73.89805976800001,
                40.71686323599995
              ],
              [
                -73.89805995699993,
                40.71686334499989
              ],
              [
                -73.89806016999988,
                40.71686344399993
              ],
              [
                -73.89820366399991,
                40.71693000199986
              ],
              [
                -73.89901821099993,
                40.71738212699993
              ],
              [
                -73.89882095599994,
                40.71737222899991
              ],
              [
                -73.897267579,
                40.718147156999855
              ],
              [
                -73.89834760099983,
                40.719197677999894
              ],
              [
                -73.89985149399986,
                40.7183753979999
              ],
              [
                -73.90005937999983,
                40.71804637899991
              ],
              [
                -73.90033904299986,
                40.718019947999885
              ],
              [
                -73.90033454499991,
                40.718669139999854
              ],
              [
                -73.90062644399997,
                40.719764053999874
              ],
              [
                -73.90009286399996,
                40.72017685299995
              ],
              [
                -73.89783709999988,
                40.720470195999916
              ],
              [
                -73.89774824399997,
                40.72035734699994
              ],
              [
                -73.89736598399989,
                40.72046438299989
              ],
              [
                -73.89739704899986,
                40.7215512099999
              ],
              [
                -73.89981610199999,
                40.721236899999894
              ],
              [
                -73.90010558699989,
                40.72329624099992
              ],
              [
                -73.89903191799989,
                40.72385695999988
              ],
              [
                -73.8983528509999,
                40.72311228999995
              ],
              [
                -73.8960873509999,
                40.72381130499992
              ],
              [
                -73.8958238069999,
                40.72389882199993
              ],
              [
                -73.89569743299992,
                40.72341258599993
              ],
              [
                -73.89451032599995,
                40.72313194899989
              ],
              [
                -73.89426494199992,
                40.72314318899986
              ],
              [
                -73.89411255499982,
                40.72321425999992
              ],
              [
                -73.89265591299981,
                40.723887824999935
              ],
              [
                -73.89181746299987,
                40.724256213999865
              ],
              [
                -73.89100768499999,
                40.724616330999964
              ],
              [
                -73.89030329399986,
                40.724923886999896
              ],
              [
                -73.89017449699986,
                40.724946888999966
              ],
              [
                -73.88897522300003,
                40.724819902999876
              ],
              [
                -73.8870771209998,
                40.72463770199994
              ],
              [
                -73.88709910799992,
                40.725318679999894
              ],
              [
                -73.8871325359999,
                40.726142346999914
              ],
              [
                -73.88720395699988,
                40.72619323199995
              ],
              [
                -73.88724113899983,
                40.72740160899993
              ],
              [
                -73.88722136099985,
                40.72752653399989
              ],
              [
                -73.88721164499992,
                40.72761629699996
              ],
              [
                -73.88720496699986,
                40.727694363999916
              ],
              [
                -73.88720524999994,
                40.727792996999874
              ],
              [
                -73.88720482599992,
                40.72786882899991
              ],
              [
                -73.88720430099983,
                40.728010829999924
              ],
              [
                -73.88720051699987,
                40.72809239399991
              ],
              [
                -73.88719765199994,
                40.7281837119999
              ],
              [
                -73.88720114099984,
                40.728612069999926
              ],
              [
                -73.88723573099986,
                40.72893296499995
              ],
              [
                -73.88728902599995,
                40.72925284999991
              ],
              [
                -73.887360922,
                40.72957073799995
              ],
              [
                -73.88736093299984,
                40.72957078299989
              ],
              [
                -73.88736094499983,
                40.72957082699987
              ],
              [
                -73.88745118199992,
                40.72988564399986
              ],
              [
                -73.88745122999997,
                40.72988582299991
              ],
              [
                -73.8874512889999,
                40.729885994999904
              ],
              [
                -73.8875596219999,
                40.730197055999916
              ],
              [
                -73.88805975199999,
                40.73008722999991
              ],
              [
                -73.88811223499987,
                40.73059071099994
              ],
              [
                -73.88817834099999,
                40.7311947819999
              ],
              [
                -73.88825349299994,
                40.73189716199991
              ],
              [
                -73.88828525999989,
                40.73226053999988
              ],
              [
                -73.88830747899983,
                40.73251463099991
              ],
              [
                -73.88833156099989,
                40.73276054799993
              ],
              [
                -73.8883492629999,
                40.73294143199996
              ],
              [
                -73.88837510099995,
                40.733205358999925
              ],
              [
                -73.88840065599999,
                40.73344799199985
              ],
              [
                -73.88844349199991,
                40.733854755999864
              ],
              [
                -73.88847908999993,
                40.73415815399989
              ],
              [
                -73.8885228599999,
                40.734531159999925
              ],
              [
                -73.88910318999984,
                40.73459239599988
              ],
              [
                -73.88993450699996,
                40.73455995599993
              ],
              [
                -73.89113852799989,
                40.73451296999988
              ],
              [
                -73.89138497899995,
                40.7345049889999
              ],
              [
                -73.89175016399979,
                40.73448480999986
              ],
              [
                -73.89232644100002,
                40.73447014099993
              ],
              [
                -73.89255554699984,
                40.73447812599994
              ],
              [
                -73.89278405699996,
                40.73449596299988
              ],
              [
                -73.89301088099991,
                40.73452357599995
              ],
              [
                -73.89323494499989,
                40.73456081199991
              ],
              [
                -73.89347408299996,
                40.734602177999875
              ],
              [
                -73.89370905699988,
                40.734657451999915
              ],
              [
                -73.89393780599978,
                40.73472620999993
              ],
              [
                -73.89415835499986,
                40.734807791999884
              ],
              [
                -73.89424644900001,
                40.73484181299994
              ],
              [
                -73.89440759699976,
                40.73489932399988
              ],
              [
                -73.89457531899991,
                40.73494649199996
              ],
              [
                -73.89457544899996,
                40.734946528999885
              ],
              [
                -73.89457557899992,
                40.73494655499992
              ],
              [
                -73.8947480899998,
                40.73498275099996
              ],
              [
                -73.89474827899986,
                40.734982786999915
              ],
              [
                -73.89474848099997,
                40.73498281499995
              ],
              [
                -73.89492421799993,
                40.73500772199992
              ],
              [
                -73.89510133499994,
                40.73502118299989
              ],
              [
                -73.89602931599993,
                40.73501789199986
              ],
              [
                -73.89651105199997,
                40.73509674199993
              ],
              [
                -73.89691187999993,
                40.73508660199993
              ],
              [
                -73.89783236899989,
                40.73516035399992
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000185568253002,
        "objectid": 158,
        "shape_leng": 0.0548099905515,
        "location_id": 158,
        "zone": "Meatpacking/West Village West",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -74.00779116099989,
                40.74197084499985
              ],
              [
                -74.00820401899988,
                40.74214751799986
              ],
              [
                -74.00869902299993,
                40.74236172299993
              ],
              [
                -74.00879080599995,
                40.742375143999915
              ],
              [
                -74.008893399,
                40.742384166999884
              ],
              [
                -74.00902439199994,
                40.742399742999964
              ],
              [
                -74.00916608258481,
                40.742416591231674
              ],
              [
                -74.00927792756069,
                40.74187861085614
              ],
              [
                -74.00944784072523,
                40.741891591220124
              ],
              [
                -74.00951947145005,
                40.741490302198606
              ],
              [
                -74.01211745872139,
                40.74175851621158
              ],
              [
                -74.01216232909212,
                40.74149188968266
              ],
              [
                -74.00957692791619,
                40.741210656654424
              ],
              [
                -74.00958131822176,
                40.74117496028479
              ],
              [
                -74.00941140266131,
                40.741154093312815
              ],
              [
                -74.00946293860208,
                40.740902337240264
              ],
              [
                -74.00950137905853,
                40.74082922374572
              ],
              [
                -74.00951844195927,
                40.74080572291691
              ],
              [
                -74.00951627358208,
                40.7407735836735
              ],
              [
                -74.00949154621287,
                40.74074270943978
              ],
              [
                -74.00949685520365,
                40.74072701178543
              ],
              [
                -74.00963145571744,
                40.740645367374285
              ],
              [
                -74.01071840609639,
                40.74068559392549
              ],
              [
                -74.01137438820483,
                40.740723947641335
              ],
              [
                -74.01147486918853,
                40.74068706027103
              ],
              [
                -74.01257372874738,
                40.74073525812518
              ],
              [
                -74.0125737299691,
                40.740741731844885
              ],
              [
                -74.01265928208485,
                40.74074763849748
              ],
              [
                -74.01265742489983,
                40.740696652231826
              ],
              [
                -74.01215372624051,
                40.740674256620736
              ],
              [
                -74.01215741913083,
                40.74060599813125
              ],
              [
                -74.01146073955334,
                40.7405842147525
              ],
              [
                -74.01151491736529,
                40.739972863698824
              ],
              [
                -74.01158637887598,
                40.73997384795255
              ],
              [
                -74.01157686924238,
                40.74016009950159
              ],
              [
                -74.01231001212764,
                40.74019475620399
              ],
              [
                -74.01232975860133,
                40.74018348844809
              ],
              [
                -74.01232852356425,
                40.74016283157746
              ],
              [
                -74.01230877290088,
                40.74014781277593
              ],
              [
                -74.01226310747168,
                40.74014218976771
              ],
              [
                -74.01227049910142,
                40.74003422696505
              ],
              [
                -74.01232356118135,
                40.74003140300372
              ],
              [
                -74.01234947552231,
                40.74001637981593
              ],
              [
                -74.01235687134118,
                40.73993658471746
              ],
              [
                -74.01234328917008,
                40.7399206297923
              ],
              [
                -74.01231366348769,
                40.73990279375338
              ],
              [
                -74.01228897847172,
                40.739899042124534
              ],
              [
                -74.01228649627012,
                40.73979296102123
              ],
              [
                -74.01234943428379,
                40.73979389076181
              ],
              [
                -74.01237411577733,
                40.73977886786768
              ],
              [
                -74.0123691740004,
                40.73975164637212
              ],
              [
                -74.01234819241687,
                40.739737565353636
              ],
              [
                -74.01161258017336,
                40.73970760054936
              ],
              [
                -74.01159286133141,
                40.73987376488411
              ],
              [
                -74.0115274502041,
                40.739873771449915
              ],
              [
                -74.01155518156293,
                40.7395567262273
              ],
              [
                -74.01162792311773,
                40.73956247410615
              ],
              [
                -74.01163351895192,
                40.739506486066205
              ],
              [
                -74.01158715816628,
                40.73947887033475
              ],
              [
                -74.01158818724917,
                40.73927159590028
              ],
              [
                -74.01158045502316,
                40.739264536112046
              ],
              [
                -74.01148456734634,
                40.73926046259409
              ],
              [
                -74.01138798406548,
                40.73921605608857
              ],
              [
                -74.01074676135308,
                40.739175936679615
              ],
              [
                -74.01055473996806,
                40.739186239049005
              ],
              [
                -74.01049858988287,
                40.739173432770414
              ],
              [
                -74.0103930313456,
                40.739174296059204
              ],
              [
                -74.01042867884628,
                40.738703364658704
              ],
              [
                -74.01045432864609,
                40.738364506228145
              ],
              [
                -74.01105126196637,
                40.73839642575062
              ],
              [
                -74.01105426715442,
                40.73838794795728
              ],
              [
                -74.01107516272492,
                40.73839172974585
              ],
              [
                -74.01109653938134,
                40.73839332225607
              ],
              [
                -74.01111800254128,
                40.738392696092404
              ],
              [
                -74.01113915602558,
                40.73838986281303
              ],
              [
                -74.01113215659413,
                40.738369789784436
              ],
              [
                -74.01112123961192,
                40.738350755119946
              ],
              [
                -74.01110665713955,
                40.73833319830264
              ],
              [
                -74.01108874586753,
                40.73831752469385
              ],
              [
                -74.01106791934316,
                40.73830409617404
              ],
              [
                -74.01108706635937,
                40.73809275008135
              ],
              [
                -74.0104765981442,
                40.73806394059505
              ],
              [
                -74.01051581576753,
                40.73759606495365
              ],
              [
                -74.01056810573431,
                40.736972220142476
              ],
              [
                -74.01061910077436,
                40.73634539072178
              ],
              [
                -74.01065421033896,
                40.73634774524588
              ],
              [
                -74.01065512584192,
                40.7363006896678
              ],
              [
                -74.01067576035113,
                40.736289501811896
              ],
              [
                -74.01069377906512,
                40.73627594935932
              ],
              [
                -74.0107087188013,
                40.73626038068563
              ],
              [
                -74.01072046305407,
                40.736242698688
              ],
              [
                -74.0107282303185,
                40.736223789689696
              ],
              [
                -74.01073180968515,
                40.73620416714772
              ],
              [
                -74.01073110396315,
                40.73618436389367
              ],
              [
                -74.01072613231958,
                40.73616491766635
              ],
              [
                -74.01071702975825,
                40.73614635650884
              ],
              [
                -74.01070404345337,
                40.73612918443091
              ],
              [
                -74.01068752603774,
                40.73611386772276
              ],
              [
                -74.01066986751215,
                40.73610193192913
              ],
              [
                -74.01067724659347,
                40.736056279956806
              ],
              [
                -74.01064292017875,
                40.736048697704945
              ],
              [
                -74.01070922910374,
                40.73536287214015
              ],
              [
                -74.01078798331716,
                40.73454829384434
              ],
              [
                -74.01081359479103,
                40.734283443647534
              ],
              [
                -74.01200124222407,
                40.734338401668715
              ],
              [
                -74.01201792010478,
                40.734063744456314
              ],
              [
                -74.01083284563215,
                40.734007094513636
              ],
              [
                -74.0108645138697,
                40.73359301463594
              ],
              [
                -74.01083770052972,
                40.73358846350718
              ],
              [
                -74.01081228611086,
                40.73358052690649
              ],
              [
                -74.0107890034796,
                40.73356943369936
              ],
              [
                -74.01076852402858,
                40.733555503776586
              ],
              [
                -74.01075143831419,
                40.73353913883062
              ],
              [
                -74.01073823902861,
                40.733520810771275
              ],
              [
                -74.01072930679061,
                40.73350104811758
              ],
              [
                -74.01072489917198,
                40.73348042075682
              ],
              [
                -74.01072514326883,
                40.73345952351112
              ],
              [
                -74.01073003203787,
                40.7334389589845
              ],
              [
                -74.01073942450006,
                40.73341932018602
              ],
              [
                -74.01075304980591,
                40.733401173429414
              ],
              [
                -74.01077051504689,
                40.7333850420029
              ],
              [
                -74.01079131658479,
                40.73337139107892
              ],
              [
                -74.01081485457661,
                40.73336061430152
              ],
              [
                -74.01084045026995,
                40.73335302243395
              ],
              [
                -74.01086736557718,
                40.7333488343982
              ],
              [
                -74.01089482435796,
                40.73334817096212
              ],
              [
                -74.01090389883275,
                40.7332976171396
              ],
              [
                -74.01399957240955,
                40.73333227751771
              ],
              [
                -74.01400220214602,
                40.73306800179544
              ],
              [
                -74.01094127374121,
                40.73302031334128
              ],
              [
                -74.01099350879153,
                40.73232041794062
              ],
              [
                -74.01108642940412,
                40.73128902958223
              ],
              [
                -74.01116195696464,
                40.730450676349065
              ],
              [
                -74.01401552295421,
                40.73068283965607
              ],
              [
                -74.01403796924805,
                40.73069421884393
              ],
              [
                -74.01408693559922,
                40.730663173040305
              ],
              [
                -74.0140774139113,
                40.730640409187835
              ],
              [
                -74.01421928543024,
                40.729523427755595
              ],
              [
                -74.01434461474159,
                40.72862218378146
              ],
              [
                -74.014390699162,
                40.728463047760016
              ],
              [
                -74.01138323602366,
                40.728229272183555
              ],
              [
                -74.01100472999994,
                40.7281932389999
              ],
              [
                -74.01087083299997,
                40.72818049399996
              ],
              [
                -74.01076791499999,
                40.728173957999914
              ],
              [
                -74.01067264999996,
                40.729112666999896
              ],
              [
                -74.01054441299988,
                40.7291000979999
              ],
              [
                -74.00934961999998,
                40.72898755599988
              ],
              [
                -74.00859272099996,
                40.72891527699988
              ],
              [
                -74.0070902079999,
                40.72877225299994
              ],
              [
                -74.00697298799992,
                40.72949498999988
              ],
              [
                -74.00685682099994,
                40.7302282359999
              ],
              [
                -74.00673713399992,
                40.7309470659999
              ],
              [
                -74.00662407199995,
                40.73166937899993
              ],
              [
                -74.00650135299995,
                40.73242587499988
              ],
              [
                -74.00640707599995,
                40.73305393999988
              ],
              [
                -74.0062942419999,
                40.733742041999925
              ],
              [
                -74.00618179699998,
                40.734439131999885
              ],
              [
                -74.00606869899997,
                40.735133213999916
              ],
              [
                -74.00596018000002,
                40.7358450069999
              ],
              [
                -74.00585387099987,
                40.73653322599989
              ],
              [
                -74.00579620299999,
                40.73692169799991
              ],
              [
                -74.0056870449999,
                40.73755187899992
              ],
              [
                -74.00559149599995,
                40.73813833999992
              ],
              [
                -74.00549494099988,
                40.73875392299989
              ],
              [
                -74.00539035999991,
                40.73941197299994
              ],
              [
                -74.00529178799992,
                40.74002322299992
              ],
              [
                -74.00515808199998,
                40.74085808099991
              ],
              [
                -74.00536591799992,
                40.74094529499997
              ],
              [
                -74.00697077799991,
                40.741627126999916
              ],
              [
                -74.00779116099989,
                40.74197084499985
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000571234977994,
        "objectid": 160,
        "shape_leng": 0.14151914297,
        "location_id": 160,
        "zone": "Middle Village",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.86970765399998,
                40.72514643399994
              ],
              [
                -73.87021135899994,
                40.72618732599993
              ],
              [
                -73.87063185099991,
                40.727058853999914
              ],
              [
                -73.87074560999994,
                40.72726953899995
              ],
              [
                -73.87084539399993,
                40.72748459299991
              ],
              [
                -73.87093081299994,
                40.72770320699995
              ],
              [
                -73.87100156100003,
                40.727924523999896
              ],
              [
                -73.87113283499988,
                40.72837171999991
              ],
              [
                -73.87140980799985,
                40.72917810199995
              ],
              [
                -73.87146074199987,
                40.729326373999854
              ],
              [
                -73.87154749999993,
                40.72936238299992
              ],
              [
                -73.87235229600002,
                40.72965548399991
              ],
              [
                -73.87316942599995,
                40.729950618999965
              ],
              [
                -73.87408913199982,
                40.730282791999855
              ],
              [
                -73.87475475600003,
                40.730541890999916
              ],
              [
                -73.8749534949998,
                40.73061869599996
              ],
              [
                -73.87509154199992,
                40.73067136299993
              ],
              [
                -73.8753864729999,
                40.73059067299993
              ],
              [
                -73.87628302399997,
                40.730313093999946
              ],
              [
                -73.87699724199997,
                40.73004835299989
              ],
              [
                -73.87789402699987,
                40.729715930999944
              ],
              [
                -73.87836629199992,
                40.72951588699991
              ],
              [
                -73.87847478499992,
                40.72946892199991
              ],
              [
                -73.87969354099992,
                40.729010158999884
              ],
              [
                -73.88008877499988,
                40.728906274999936
              ],
              [
                -73.88049068199993,
                40.72881654199995
              ],
              [
                -73.88088484699995,
                40.728743729999906
              ],
              [
                -73.88089793699997,
                40.7287413119999
              ],
              [
                -73.88130917699985,
                40.72868082699992
              ],
              [
                -73.88172301799989,
                40.728635253999876
              ],
              [
                -73.88208519900004,
                40.728610374999924
              ],
              [
                -73.88297543399985,
                40.728540950999914
              ],
              [
                -73.8835332119999,
                40.72849744799988
              ],
              [
                -73.88387642499987,
                40.7284675829999
              ],
              [
                -73.88391980299993,
                40.728463912999885
              ],
              [
                -73.88444917100003,
                40.728419126999945
              ],
              [
                -73.88500729499985,
                40.72837189499992
              ],
              [
                -73.88559330999979,
                40.728274440999954
              ],
              [
                -73.88590069999995,
                40.72821132299992
              ],
              [
                -73.88623765899992,
                40.72812701699997
              ],
              [
                -73.8865683549999,
                40.728028890999944
              ],
              [
                -73.88661323299988,
                40.728013392999884
              ],
              [
                -73.88672984899989,
                40.7279731279999
              ],
              [
                -73.88689134399985,
                40.727917365999915
              ],
              [
                -73.88720524999994,
                40.727792996999874
              ],
              [
                -73.88720496699986,
                40.727694363999916
              ],
              [
                -73.88721164499992,
                40.72761629699996
              ],
              [
                -73.88722136099985,
                40.72752653399989
              ],
              [
                -73.88724113899983,
                40.72740160899993
              ],
              [
                -73.88720395699988,
                40.72619323199995
              ],
              [
                -73.8871325359999,
                40.726142346999914
              ],
              [
                -73.88709910799992,
                40.725318679999894
              ],
              [
                -73.8870771209998,
                40.72463770199994
              ],
              [
                -73.88897522300003,
                40.724819902999876
              ],
              [
                -73.89017449699986,
                40.724946888999966
              ],
              [
                -73.89030329399986,
                40.724923886999896
              ],
              [
                -73.89100768499999,
                40.724616330999964
              ],
              [
                -73.89181746299987,
                40.724256213999865
              ],
              [
                -73.89265591299981,
                40.723887824999935
              ],
              [
                -73.89411255499982,
                40.72321425999992
              ],
              [
                -73.89426494199992,
                40.72314318899986
              ],
              [
                -73.89451032599995,
                40.72313194899989
              ],
              [
                -73.89569743299992,
                40.72341258599993
              ],
              [
                -73.8958238069999,
                40.72389882199993
              ],
              [
                -73.8960873509999,
                40.72381130499992
              ],
              [
                -73.8983528509999,
                40.72311228999995
              ],
              [
                -73.89903191799989,
                40.72385695999988
              ],
              [
                -73.90010558699989,
                40.72329624099992
              ],
              [
                -73.89981610199999,
                40.721236899999894
              ],
              [
                -73.89739704899986,
                40.7215512099999
              ],
              [
                -73.89736598399989,
                40.72046438299989
              ],
              [
                -73.89774824399997,
                40.72035734699994
              ],
              [
                -73.89783709999988,
                40.720470195999916
              ],
              [
                -73.90009286399996,
                40.72017685299995
              ],
              [
                -73.90062644399997,
                40.719764053999874
              ],
              [
                -73.90033454499991,
                40.718669139999854
              ],
              [
                -73.90033904299986,
                40.718019947999885
              ],
              [
                -73.90005937999983,
                40.71804637899991
              ],
              [
                -73.89985149399986,
                40.7183753979999
              ],
              [
                -73.89834760099983,
                40.719197677999894
              ],
              [
                -73.897267579,
                40.718147156999855
              ],
              [
                -73.89882095599994,
                40.71737222899991
              ],
              [
                -73.89901821099993,
                40.71738212699993
              ],
              [
                -73.89820366399991,
                40.71693000199986
              ],
              [
                -73.89806016999988,
                40.71686344399993
              ],
              [
                -73.89805995699993,
                40.71686334499989
              ],
              [
                -73.89805976800001,
                40.71686323599995
              ],
              [
                -73.89792442199999,
                40.71678547399989
              ],
              [
                -73.89792430299995,
                40.716785401999914
              ],
              [
                -73.89792419799988,
                40.71678533099995
              ],
              [
                -73.89779899799984,
                40.7166971499999
              ],
              [
                -73.89779893899993,
                40.716697103999884
              ],
              [
                -73.89779888000004,
                40.71669705899989
              ],
              [
                -73.89768581399989,
                40.71659970299993
              ],
              [
                -73.89758660799986,
                40.71649479699993
              ],
              [
                -73.89758653699987,
                40.71649471499991
              ],
              [
                -73.89758646599992,
                40.716494624999875
              ],
              [
                -73.89750226399987,
                40.716383867999916
              ],
              [
                -73.89782401399992,
                40.71623638099991
              ],
              [
                -73.89860294399998,
                40.71586712099985
              ],
              [
                -73.89926420299997,
                40.7155443909999
              ],
              [
                -73.89989954399981,
                40.71523272099996
              ],
              [
                -73.90115752599986,
                40.71462231499989
              ],
              [
                -73.902045084,
                40.71419703399989
              ],
              [
                -73.90243306099984,
                40.71400341199992
              ],
              [
                -73.90285443299987,
                40.71379311999988
              ],
              [
                -73.90380184499992,
                40.71334837899985
              ],
              [
                -73.90477244599997,
                40.71286579999986
              ],
              [
                -73.90376943999979,
                40.7128170319999
              ],
              [
                -73.90293472899992,
                40.7127860729999
              ],
              [
                -73.90224267799981,
                40.712759832999886
              ],
              [
                -73.900595879,
                40.71269738499992
              ],
              [
                -73.90018380399995,
                40.712680657999954
              ],
              [
                -73.89935504399993,
                40.71264701399986
              ],
              [
                -73.89768322699987,
                40.71258029799992
              ],
              [
                -73.89710338699993,
                40.71255773699992
              ],
              [
                -73.89621914099989,
                40.712522056999944
              ],
              [
                -73.8959038609999,
                40.71250695899986
              ],
              [
                -73.89502461000001,
                40.712478252999894
              ],
              [
                -73.89401868699997,
                40.7124405549999
              ],
              [
                -73.89367393299992,
                40.71243002499995
              ],
              [
                -73.88877304399992,
                40.71228017799995
              ],
              [
                -73.88842000699994,
                40.71226995799986
              ],
              [
                -73.88687942999985,
                40.712223184999885
              ],
              [
                -73.88637649699994,
                40.71220771499993
              ],
              [
                -73.88622785599989,
                40.7122023819999
              ],
              [
                -73.88519763099994,
                40.71216627399993
              ],
              [
                -73.88507555699985,
                40.71216105599992
              ],
              [
                -73.88435098699988,
                40.712230172999966
              ],
              [
                -73.88205387399988,
                40.71245414999988
              ],
              [
                -73.88006267699978,
                40.71265257399994
              ],
              [
                -73.87907685299999,
                40.71035938999994
              ],
              [
                -73.87943495499985,
                40.71028441899989
              ],
              [
                -73.87926181200001,
                40.70990534699992
              ],
              [
                -73.87872783199988,
                40.71002753499987
              ],
              [
                -73.87761134199985,
                40.707167704999954
              ],
              [
                -73.88086188499989,
                40.7065847289999
              ],
              [
                -73.88088332699985,
                40.70645397699992
              ],
              [
                -73.88000104599992,
                40.70649293399988
              ],
              [
                -73.87900684300003,
                40.7065368389999
              ],
              [
                -73.87814452399996,
                40.7066096259999
              ],
              [
                -73.87720614800001,
                40.70668883999991
              ],
              [
                -73.87625034900005,
                40.70674464299992
              ],
              [
                -73.87532966799982,
                40.70679838799992
              ],
              [
                -73.87482359599984,
                40.706819477999964
              ],
              [
                -73.87425518099995,
                40.706857030999906
              ],
              [
                -73.87326053099987,
                40.706922736999914
              ],
              [
                -73.87153400399993,
                40.70703676799997
              ],
              [
                -73.86911007199988,
                40.70708933699992
              ],
              [
                -73.86949738599989,
                40.70787206999991
              ],
              [
                -73.86989582499986,
                40.7085070939999
              ],
              [
                -73.87016462499984,
                40.70920774299988
              ],
              [
                -73.86661823199995,
                40.71040164899997
              ],
              [
                -73.86557714699987,
                40.71065921899993
              ],
              [
                -73.86500981999998,
                40.710776908999875
              ],
              [
                -73.86207872699998,
                40.71220105999993
              ],
              [
                -73.85978566499996,
                40.71331129599985
              ],
              [
                -73.85965416799995,
                40.71337996099988
              ],
              [
                -73.8596317549999,
                40.713598263999856
              ],
              [
                -73.85962920999985,
                40.713975294999926
              ],
              [
                -73.85967906599994,
                40.71417332399993
              ],
              [
                -73.8597452479999,
                40.71436917699986
              ],
              [
                -73.85982746199984,
                40.71456168899992
              ],
              [
                -73.85992523600002,
                40.714749735999945
              ],
              [
                -73.86003789899996,
                40.71493223599987
              ],
              [
                -73.86062663399984,
                40.71548716899991
              ],
              [
                -73.86122406399997,
                40.7160373289999
              ],
              [
                -73.861528867,
                40.716314936999915
              ],
              [
                -73.86183368299996,
                40.7165925429999
              ],
              [
                -73.86213638199993,
                40.7168767739999
              ],
              [
                -73.86242540499987,
                40.71714814699987
              ],
              [
                -73.86272195199989,
                40.71742801899989
              ],
              [
                -73.86301850099983,
                40.717707881999964
              ],
              [
                -73.8635711119999,
                40.718276679999875
              ],
              [
                -73.86412297599996,
                40.718844806999925
              ],
              [
                -73.86468729499988,
                40.719427075999874
              ],
              [
                -73.86523870699996,
                40.71997670999991
              ],
              [
                -73.86547610900004,
                40.72028093699992
              ],
              [
                -73.86604176200004,
                40.72090092299987
              ],
              [
                -73.86657241099984,
                40.721486636999884
              ],
              [
                -73.86711404599988,
                40.72207640399993
              ],
              [
                -73.86730908299992,
                40.722289024999924
              ],
              [
                -73.86765927399983,
                40.72266125599993
              ],
              [
                -73.86821068499994,
                40.723254748999885
              ],
              [
                -73.86845305199996,
                40.72348549399988
              ],
              [
                -73.8687681729999,
                40.723820906999904
              ],
              [
                -73.86927215699988,
                40.72441183599994
              ],
              [
                -73.86929270499994,
                40.7244359289999
              ],
              [
                -73.86931025899987,
                40.72446599099992
              ],
              [
                -73.86970765399998,
                40.72514643399994
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0000719130682992,
        "objectid": 161,
        "shape_leng": 0.0358039100611,
        "location_id": 161,
        "zone": "Midtown Center",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.97534377999993,
                40.7552996949999
              ],
              [
                -73.97488727199989,
                40.75592379099991
              ],
              [
                -73.97443866399978,
                40.75655630599991
              ],
              [
                -73.97398183199975,
                40.757179757999936
              ],
              [
                -73.97352419599999,
                40.75780843999996
              ],
              [
                -73.97306851999987,
                40.7584314939999
              ],
              [
                -73.9726127139999,
                40.75905930799997
              ],
              [
                -73.97215595499983,
                40.759681239999985
              ],
              [
                -73.97169609199985,
                40.760312660999936
              ],
              [
                -73.97124277300003,
                40.76093641799986
              ],
              [
                -73.97133777299992,
                40.760977297999894
              ],
              [
                -73.97285250499992,
                40.76161435699987
              ],
              [
                -73.97445730599989,
                40.762293083999914
              ],
              [
                -73.977686006,
                40.76364440299992
              ],
              [
                -73.97813931699997,
                40.76302401399992
              ],
              [
                -73.97860169399989,
                40.76239607199995
              ],
              [
                -73.97905731199987,
                40.761770719999944
              ],
              [
                -73.97951319199996,
                40.761148561999924
              ],
              [
                -73.97997188199987,
                40.76052470099987
              ],
              [
                -73.98042606399994,
                40.75989908099994
              ],
              [
                -73.98088574599987,
                40.759270669999914
              ],
              [
                -73.98134107099996,
                40.75864529799986
              ],
              [
                -73.98179404299992,
                40.75802130299997
              ],
              [
                -73.98225528700006,
                40.75738736499995
              ],
              [
                -73.98271205299979,
                40.756771227999934
              ],
              [
                -73.98316796099988,
                40.75614022199994
              ],
              [
                -73.98362433299988,
                40.75551634599986
              ],
              [
                -73.98411754799999,
                40.754842052999955
              ],
              [
                -73.98088709899987,
                40.75348098899989
              ],
              [
                -73.97928686199992,
                40.75280049699995
              ],
              [
                -73.9783696929999,
                40.75241622699992
              ],
              [
                -73.9778749419999,
                40.75309839199991
              ],
              [
                -73.97743259200001,
                40.75371708799987
              ],
              [
                -73.97697379099985,
                40.75434867599992
              ],
              [
                -73.9765156939999,
                40.754974262999944
              ],
              [
                -73.97589877700003,
                40.7547142229999
              ],
              [
                -73.97580739399986,
                40.754677274999935
              ],
              [
                -73.97534377999993,
                40.7552996949999
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0000478936962112,
        "objectid": 162,
        "shape_leng": 0.0352698146219,
        "location_id": 162,
        "zone": "Midtown East",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.9712170449999,
                40.75519332399994
              ],
              [
                -73.97075919399998,
                40.7558248509999
              ],
              [
                -73.97031393599993,
                40.75645301899989
              ],
              [
                -73.96984859899989,
                40.75707595799987
              ],
              [
                -73.96939411299984,
                40.75769952799991
              ],
              [
                -73.96893510299992,
                40.75833009199994
              ],
              [
                -73.96847979599983,
                40.75895012799988
              ],
              [
                -73.96802436899996,
                40.759578139999896
              ],
              [
                -73.96792193499995,
                40.75959308699991
              ],
              [
                -73.96746215199991,
                40.76022290599994
              ],
              [
                -73.96699471899981,
                40.76090538299993
              ],
              [
                -73.96657146999985,
                40.761481879999955
              ],
              [
                -73.9665833839999,
                40.76155093499988
              ],
              [
                -73.96818687799986,
                40.76222682099993
              ],
              [
                -73.96968801199992,
                40.76286596199986
              ],
              [
                -73.96980028899992,
                40.76291351199995
              ],
              [
                -73.97025814699983,
                40.76228875299991
              ],
              [
                -73.97075063899997,
                40.76160870199995
              ],
              [
                -73.97124277300003,
                40.76093641799986
              ],
              [
                -73.97169609199985,
                40.760312660999936
              ],
              [
                -73.97215595499983,
                40.759681239999985
              ],
              [
                -73.9726127139999,
                40.75905930799997
              ],
              [
                -73.97306851999987,
                40.7584314939999
              ],
              [
                -73.97352419599999,
                40.75780843999996
              ],
              [
                -73.97398183199975,
                40.757179757999936
              ],
              [
                -73.97443866299996,
                40.75655630599988
              ],
              [
                -73.97488727199989,
                40.75592379099991
              ],
              [
                -73.97534377999993,
                40.7552996949999
              ],
              [
                -73.97580739399984,
                40.75467727399997
              ],
              [
                -73.97589877700003,
                40.7547142229999
              ],
              [
                -73.9765156939999,
                40.754974262999944
              ],
              [
                -73.97697379000006,
                40.754348675999886
              ],
              [
                -73.97743259200001,
                40.75371708799987
              ],
              [
                -73.9778749419999,
                40.75309839199991
              ],
              [
                -73.9783696929999,
                40.75241622699992
              ],
              [
                -73.97779152099986,
                40.7521675949999
              ],
              [
                -73.97767660499981,
                40.7521189369999
              ],
              [
                -73.97754284999984,
                40.7520635559999
              ],
              [
                -73.97710781099983,
                40.75188340599991
              ],
              [
                -73.97685152399993,
                40.75177649399989
              ],
              [
                -73.97604824899986,
                40.75144139299993
              ],
              [
                -73.9744494109998,
                40.75076644499992
              ],
              [
                -73.97395914699996,
                40.75144102099995
              ],
              [
                -73.97350255899984,
                40.7520649319999
              ],
              [
                -73.97304502599985,
                40.7526916219999
              ],
              [
                -73.97258874999983,
                40.753317887999856
              ],
              [
                -73.97213617399994,
                40.7539432349999
              ],
              [
                -73.97167699199997,
                40.75456963099995
              ],
              [
                -73.9712170449999,
                40.75519332399994
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0000408567804196,
        "objectid": 163,
        "shape_leng": 0.0341768669752,
        "location_id": 163,
        "zone": "Midtown North",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.98136213699995,
                40.76783673899992
              ],
              [
                -73.98141469499981,
                40.76777787299987
              ],
              [
                -73.98147898799989,
                40.76772505899991
              ],
              [
                -73.98155301499993,
                40.76768021699989
              ],
              [
                -73.98163412099991,
                40.76764472399989
              ],
              [
                -73.98173478100003,
                40.7676193279999
              ],
              [
                -73.98173489899985,
                40.76761929999994
              ],
              [
                -73.98173502899986,
                40.76761929099996
              ],
              [
                -73.98184353599999,
                40.767609717999925
              ],
              [
                -73.98184380799981,
                40.76760969099991
              ],
              [
                -73.98184408100003,
                40.76760970899987
              ],
              [
                -73.98195388499981,
                40.76761757899992
              ],
              [
                -73.981954182,
                40.76761759699986
              ],
              [
                -73.98195445399982,
                40.76761766099985
              ],
              [
                -73.98205860599991,
                40.76764244899991
              ],
              [
                -73.9820588189999,
                40.76764250299994
              ],
              [
                -73.98205899699985,
                40.76764257499985
              ],
              [
                -73.98215157200003,
                40.76768179699994
              ],
              [
                -73.98236545099999,
                40.7673921529999
              ],
              [
                -73.98287713499988,
                40.766715594999866
              ],
              [
                -73.98336657899995,
                40.766045241999926
              ],
              [
                -73.98382185700001,
                40.76541847499991
              ],
              [
                -73.98428230099987,
                40.76479181499995
              ],
              [
                -73.9824697079998,
                40.76403346399993
              ],
              [
                -73.98143351999995,
                40.763594359999914
              ],
              [
                -73.97860169399989,
                40.76239607199995
              ],
              [
                -73.97813931699997,
                40.76302401399992
              ],
              [
                -73.977686006,
                40.76364440299992
              ],
              [
                -73.97445730599989,
                40.762293083999914
              ],
              [
                -73.97285250499992,
                40.76161435699987
              ],
              [
                -73.97133777299992,
                40.760977297999894
              ],
              [
                -73.97124277300003,
                40.76093641799986
              ],
              [
                -73.97075063899997,
                40.76160870199995
              ],
              [
                -73.97025814699983,
                40.76228875299991
              ],
              [
                -73.96980028899992,
                40.76291351199995
              ],
              [
                -73.9699048929999,
                40.762958042999834
              ],
              [
                -73.97141141699996,
                40.763587473999955
              ],
              [
                -73.97301487199995,
                40.76427887899991
              ],
              [
                -73.9736358469999,
                40.76456991299992
              ],
              [
                -73.9741922899999,
                40.764806527999916
              ],
              [
                -73.97623388799995,
                40.765674630999854
              ],
              [
                -73.97836113000002,
                40.766573710999886
              ],
              [
                -73.97907658799991,
                40.76687608599993
              ],
              [
                -73.98136213699995,
                40.76783673899992
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0000556318237441,
        "objectid": 164,
        "shape_leng": 0.0357716119908,
        "location_id": 164,
        "zone": "Midtown South",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.98362519999995,
                40.7439431149999
              ],
              [
                -73.98317411899995,
                40.7445603109999
              ],
              [
                -73.98272686499999,
                40.74518041399988
              ],
              [
                -73.98233039299987,
                40.74572641199994
              ],
              [
                -73.98227872699987,
                40.74579756299987
              ],
              [
                -73.98183365099987,
                40.74641659099996
              ],
              [
                -73.98177455300002,
                40.74649679899989
              ],
              [
                -73.98134068699997,
                40.747078663999936
              ],
              [
                -73.98085054499998,
                40.74775036999995
              ],
              [
                -73.98094249999997,
                40.747790070999905
              ],
              [
                -73.98247211999985,
                40.74843150099986
              ],
              [
                -73.98407636100002,
                40.74910212499994
              ],
              [
                -73.983621411,
                40.749723770999935
              ],
              [
                -73.9831741629998,
                40.75034220999989
              ],
              [
                -73.9827238749999,
                40.750958943999926
              ],
              [
                -73.98227490399981,
                40.75157729799986
              ],
              [
                -73.98182244299983,
                40.75219712699989
              ],
              [
                -73.98137143199989,
                40.752813855999946
              ],
              [
                -73.98088709899987,
                40.75348098899989
              ],
              [
                -73.98411754799999,
                40.754842052999955
              ],
              [
                -73.984595843,
                40.75417694199992
              ],
              [
                -73.98504363799995,
                40.753554148999854
              ],
              [
                -73.98548819099985,
                40.752935179999945
              ],
              [
                -73.98594653099994,
                40.7523168049999
              ],
              [
                -73.98639930899988,
                40.751699985999934
              ],
              [
                -73.98684991099998,
                40.75108167899989
              ],
              [
                -73.98729377099981,
                40.750451608999896
              ],
              [
                -73.98776905899994,
                40.749787028999926
              ],
              [
                -73.98797357699985,
                40.74949077899986
              ],
              [
                -73.98823761499983,
                40.74913016899993
              ],
              [
                -73.9887140509998,
                40.748513273999954
              ],
              [
                -73.98916651699989,
                40.747895434999904
              ],
              [
                -73.98961683100002,
                40.74727642299989
              ],
              [
                -73.99006749199991,
                40.746659282999936
              ],
              [
                -73.99051761299985,
                40.746038637999895
              ],
              [
                -73.98872905499988,
                40.74528637399991
              ],
              [
                -73.98729805500001,
                40.74468149799989
              ],
              [
                -73.98569571299991,
                40.744007640999946
              ],
              [
                -73.98413729099997,
                40.74334959099991
              ],
              [
                -73.98407485299994,
                40.7433247179999
              ],
              [
                -73.98362519999995,
                40.7439431149999
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0000743150675972,
        "objectid": 170,
        "shape_leng": 0.0457690091051,
        "location_id": 170,
        "zone": "Murray Hill",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.97203331099983,
                40.745804967999945
              ],
              [
                -73.97194428900002,
                40.745931871999886
              ],
              [
                -73.97174810699977,
                40.74618613899989
              ],
              [
                -73.97165740599984,
                40.746303693999955
              ],
              [
                -73.97121971999988,
                40.74692154099996
              ],
              [
                -73.9727379199999,
                40.74755798999995
              ],
              [
                -73.97359187599997,
                40.74791866299991
              ],
              [
                -73.97497000599988,
                40.748501848999865
              ],
              [
                -73.9758346669999,
                40.74886314599998
              ],
              [
                -73.97538295899986,
                40.74948305799993
              ],
              [
                -73.9749347119999,
                40.75009962799993
              ],
              [
                -73.9744494109998,
                40.75076644499992
              ],
              [
                -73.97604824899986,
                40.75144139299993
              ],
              [
                -73.97685152399993,
                40.75177649399989
              ],
              [
                -73.97710781099983,
                40.75188340599991
              ],
              [
                -73.97754284999984,
                40.7520635559999
              ],
              [
                -73.97767660499981,
                40.7521189369999
              ],
              [
                -73.97779152099986,
                40.7521675949999
              ],
              [
                -73.9783696929999,
                40.75241622699992
              ],
              [
                -73.97928686199992,
                40.75280049699995
              ],
              [
                -73.98088709899987,
                40.75348098899989
              ],
              [
                -73.98137143199989,
                40.752813855999946
              ],
              [
                -73.98182244299983,
                40.75219712699989
              ],
              [
                -73.98227490399981,
                40.75157729799986
              ],
              [
                -73.98272387399996,
                40.750958943999905
              ],
              [
                -73.98317416299989,
                40.75034220899994
              ],
              [
                -73.983621411,
                40.749723770999935
              ],
              [
                -73.98407636100002,
                40.74910212499994
              ],
              [
                -73.98247211999985,
                40.74843150099986
              ],
              [
                -73.98094249999997,
                40.747790070999905
              ],
              [
                -73.98085054399985,
                40.74775036999989
              ],
              [
                -73.98134068699997,
                40.747078663999936
              ],
              [
                -73.98177455300002,
                40.74649679899989
              ],
              [
                -73.98183365099987,
                40.74641659099996
              ],
              [
                -73.98227872699987,
                40.74579756299987
              ],
              [
                -73.98233039299987,
                40.74572641199994
              ],
              [
                -73.98272686499999,
                40.74518041399988
              ],
              [
                -73.98317411899995,
                40.7445603109999
              ],
              [
                -73.98362519999995,
                40.7439431149999
              ],
              [
                -73.98407485299994,
                40.7433247179999
              ],
              [
                -73.98400852,
                40.74329901799996
              ],
              [
                -73.98245520799999,
                40.74264695999995
              ],
              [
                -73.98085965399993,
                40.741969767999905
              ],
              [
                -73.98040998099987,
                40.74258715999988
              ],
              [
                -73.97995899799996,
                40.743205200999924
              ],
              [
                -73.97950791000001,
                40.74382520099996
              ],
              [
                -73.9790584949999,
                40.7444435519999
              ],
              [
                -73.97860713299984,
                40.7450578309999
              ],
              [
                -73.97812378199983,
                40.74572717299987
              ],
              [
                -73.97735988999995,
                40.74540421599994
              ],
              [
                -73.97588117699995,
                40.74477922399991
              ],
              [
                -73.97522419999986,
                40.744504681999906
              ],
              [
                -73.97350835000003,
                40.743782033999885
              ],
              [
                -73.97302670299986,
                40.74445004099993
              ],
              [
                -73.97257598299977,
                40.74506785499992
              ],
              [
                -73.97212958899986,
                40.74568090199994
              ],
              [
                -73.97203331099983,
                40.745804967999945
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000353959317019,
        "objectid": 165,
        "shape_leng": 0.088608036213,
        "location_id": 165,
        "zone": "Midwood",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.96014773499996,
                40.62891518499989
              ],
              [
                -73.96022560099996,
                40.629254500999906
              ],
              [
                -73.96024902900002,
                40.629370621999904
              ],
              [
                -73.96025855099992,
                40.62941826299991
              ],
              [
                -73.96040734299989,
                40.63016253099992
              ],
              [
                -73.96046280599994,
                40.63015566099992
              ],
              [
                -73.96136921599995,
                40.6300583879999
              ],
              [
                -73.96163639899993,
                40.63002222299983
              ],
              [
                -73.96182099799995,
                40.63000487699987
              ],
              [
                -73.96197454299985,
                40.62999504099987
              ],
              [
                -73.96232599399991,
                40.6299687169999
              ],
              [
                -73.96322149299982,
                40.62984106099988
              ],
              [
                -73.96414862899985,
                40.629738964999966
              ],
              [
                -73.9650807279999,
                40.62965467499987
              ],
              [
                -73.96608191399986,
                40.62954270699991
              ],
              [
                -73.96707656900001,
                40.629434681999946
              ],
              [
                -73.96800084399993,
                40.62933305799992
              ],
              [
                -73.96892915899984,
                40.62923162599992
              ],
              [
                -73.97000109799986,
                40.62911347399989
              ],
              [
                -73.97109232299984,
                40.628966140999886
              ],
              [
                -73.97136622199992,
                40.62892916199998
              ],
              [
                -73.97120750499982,
                40.62808971199992
              ],
              [
                -73.97111421999993,
                40.62758588399992
              ],
              [
                -73.97092126300004,
                40.62656998499993
              ],
              [
                -73.97086150900002,
                40.626237182999894
              ],
              [
                -73.97080408199996,
                40.62593266699988
              ],
              [
                -73.97047171899992,
                40.62417002999995
              ],
              [
                -73.97019276999991,
                40.624203249999894
              ],
              [
                -73.96909724499984,
                40.624332824999975
              ],
              [
                -73.96864510599995,
                40.6219416949999
              ],
              [
                -73.96757137699979,
                40.62206027499997
              ],
              [
                -73.9666451569999,
                40.62216223699988
              ],
              [
                -73.96571874599992,
                40.622264028999915
              ],
              [
                -73.96471983399981,
                40.62237296099993
              ],
              [
                -73.96426798299994,
                40.61998380299988
              ],
              [
                -73.96388935599992,
                40.618000549999934
              ],
              [
                -73.96381145199987,
                40.61758940199998
              ],
              [
                -73.96375418699988,
                40.61730243299991
              ],
              [
                -73.9636224559999,
                40.616589736999856
              ],
              [
                -73.96356470599983,
                40.616238721999935
              ],
              [
                -73.9633609399999,
                40.615204427999906
              ],
              [
                -73.96285973999984,
                40.61254948599989
              ],
              [
                -73.96237947799978,
                40.609986373999966
              ],
              [
                -73.96137268399995,
                40.61009887499992
              ],
              [
                -73.9604436189999,
                40.61020150699987
              ],
              [
                -73.95952069599996,
                40.610302052999884
              ],
              [
                -73.95859278499987,
                40.61040303099988
              ],
              [
                -73.95804731899992,
                40.61046276199994
              ],
              [
                -73.95766801399986,
                40.61050371499993
              ],
              [
                -73.95670471399993,
                40.61061187799986
              ],
              [
                -73.95574066999986,
                40.6107148269999
              ],
              [
                -73.954795527,
                40.61082076599994
              ],
              [
                -73.9535988889999,
                40.61094986499993
              ],
              [
                -73.95258786900004,
                40.611112239999905
              ],
              [
                -73.95256553099985,
                40.61098718399993
              ],
              [
                -73.95163234999984,
                40.61112353899988
              ],
              [
                -73.95070512899999,
                40.61125045199992
              ],
              [
                -73.94978380499995,
                40.61137174999987
              ],
              [
                -73.94993049299987,
                40.61214465099991
              ],
              [
                -73.94996296699989,
                40.61231572699991
              ],
              [
                -73.94999598099999,
                40.612491460999884
              ],
              [
                -73.95026514899993,
                40.61392435199983
              ],
              [
                -73.94930527399987,
                40.614030734999886
              ],
              [
                -73.94834517399987,
                40.61413649599991
              ],
              [
                -73.94772682600002,
                40.61416971599985
              ],
              [
                -73.94742608799993,
                40.61422460099989
              ],
              [
                -73.94657366799993,
                40.61478913099988
              ],
              [
                -73.94573691999982,
                40.6153634069999
              ],
              [
                -73.94576359499983,
                40.61550406399987
              ],
              [
                -73.94606699600003,
                40.617103738999894
              ],
              [
                -73.94651373199997,
                40.61949401899995
              ],
              [
                -73.94551663599992,
                40.61960561199992
              ],
              [
                -73.94451833400001,
                40.61971364699989
              ],
              [
                -73.9449703129999,
                40.6221045119999
              ],
              [
                -73.94542442899984,
                40.62449560999993
              ],
              [
                -73.946422661,
                40.62438556699992
              ],
              [
                -73.94687439899997,
                40.62677366799994
              ],
              [
                -73.94732672199994,
                40.62916656699985
              ],
              [
                -73.94832386299989,
                40.62905666099993
              ],
              [
                -73.94925229799985,
                40.62895476499993
              ],
              [
                -73.95017656999988,
                40.6288527719999
              ],
              [
                -73.95110641899993,
                40.628750466999904
              ],
              [
                -73.9520690179999,
                40.62864578099989
              ],
              [
                -73.95303133199995,
                40.62854011499994
              ],
              [
                -73.95395983399986,
                40.62843938999987
              ],
              [
                -73.95488499199999,
                40.62833554799986
              ],
              [
                -73.95581207199996,
                40.62823349199989
              ],
              [
                -73.95684697299987,
                40.628119878999925
              ],
              [
                -73.95805293100001,
                40.62797111899991
              ],
              [
                -73.95899416299996,
                40.62788559399996
              ],
              [
                -73.95995565099992,
                40.62777867199989
              ],
              [
                -73.96014773499996,
                40.62891518499989
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000255276270405,
        "objectid": 166,
        "shape_leng": 0.0688237542871,
        "location_id": 166,
        "zone": "Morningside Heights",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.95707738499995,
                40.81429809399993
              ],
              [
                -73.95710689999994,
                40.8143318209999
              ],
              [
                -73.95716929999979,
                40.814403123999966
              ],
              [
                -73.95782821899992,
                40.81515604899989
              ],
              [
                -73.95819953200001,
                40.81558041799985
              ],
              [
                -73.95829447399994,
                40.81569078999992
              ],
              [
                -73.9583949659998,
                40.815796886999934
              ],
              [
                -73.95949328899988,
                40.8170074739999
              ],
              [
                -73.96068717899983,
                40.817510516999945
              ],
              [
                -73.9610201569998,
                40.81764868599986
              ],
              [
                -73.96138875999992,
                40.81781807599991
              ],
              [
                -73.96157544099992,
                40.81790997099991
              ],
              [
                -73.9616361359999,
                40.81793985099996
              ],
              [
                -73.96168806099998,
                40.817966693999956
              ],
              [
                -73.961818603,
                40.81802315399993
              ],
              [
                -73.9618998499999,
                40.8180472869999
              ],
              [
                -73.96203105797969,
                40.818086330740854
              ],
              [
                -73.96271913392074,
                40.81736849075736
              ],
              [
                -73.96308529694089,
                40.81700611343311
              ],
              [
                -73.96312702403796,
                40.816955095336404
              ],
              [
                -73.9634039510228,
                40.81668419794027
              ],
              [
                -73.96414082888293,
                40.81577165745391
              ],
              [
                -73.96432483391364,
                40.81551273701066
              ],
              [
                -73.96509511906311,
                40.814319572659144
              ],
              [
                -73.96510784267409,
                40.814275476532295
              ],
              [
                -73.96534706551687,
                40.813939651896696
              ],
              [
                -73.96548932424895,
                40.81374769375629
              ],
              [
                -73.96548975012963,
                40.81374707236785
              ],
              [
                -73.96565840161266,
                40.81349873149242
              ],
              [
                -73.96579194974646,
                40.81332838170002
              ],
              [
                -73.96588240945108,
                40.81320062634857
              ],
              [
                -73.96610639539493,
                40.812863206936264
              ],
              [
                -73.96615809223952,
                40.81281570279123
              ],
              [
                -73.96627762716759,
                40.81263881250281
              ],
              [
                -73.96639177720876,
                40.81247337316197
              ],
              [
                -73.96652962321858,
                40.812285015524836
              ],
              [
                -73.96663300760063,
                40.812153970788046
              ],
              [
                -73.96674930787943,
                40.81198198551441
              ],
              [
                -73.96688175014941,
                40.811773976428185
              ],
              [
                -73.96692265508437,
                40.81169044653371
              ],
              [
                -73.96700232144049,
                40.81154794802964
              ],
              [
                -73.9670411118029,
                40.81152009962688
              ],
              [
                -73.96713048758585,
                40.81138742007399
              ],
              [
                -73.96740185312369,
                40.811022155397055
              ],
              [
                -73.96754397270519,
                40.81077319972411
              ],
              [
                -73.9676204142259,
                40.81063070153998
              ],
              [
                -73.96762038824623,
                40.8105848503943
              ],
              [
                -73.96766778940892,
                40.810550445942845
              ],
              [
                -73.96770439052305,
                40.81048819988439
              ],
              [
                -73.96772160095219,
                40.810434161110926
              ],
              [
                -73.96779484834431,
                40.81036699737401
              ],
              [
                -73.96788099174,
                40.81024414732448
              ],
              [
                -73.96788312497532,
                40.81020156518219
              ],
              [
                -73.9679606531174,
                40.810100010069306
              ],
              [
                -73.96813186592715,
                40.809844489225036
              ],
              [
                -73.96811675018661,
                40.80980683624046
              ],
              [
                -73.96815337512054,
                40.809765881408794
              ],
              [
                -73.96823952732154,
                40.80966268389443
              ],
              [
                -73.9684247350703,
                40.809394060333055
              ],
              [
                -73.96846348611653,
                40.8093187140199
              ],
              [
                -73.96854420633765,
                40.80914511039273
              ],
              [
                -73.96855747771205,
                40.80907289432357
              ],
              [
                -73.96866264764923,
                40.80896329267825
              ],
              [
                -73.96885218645302,
                40.80875198661713
              ],
              [
                -73.9689167848404,
                40.808630780559795
              ],
              [
                -73.96909103244369,
                40.80843016507843
              ],
              [
                -73.96911173565825,
                40.808406370068965
              ],
              [
                -73.96955934797347,
                40.80779550099896
              ],
              [
                -73.96977288943326,
                40.80752528264376
              ],
              [
                -73.9698829061469,
                40.80736478848125
              ],
              [
                -73.97021400799224,
                40.80693570615333
              ],
              [
                -73.97045990226607,
                40.80663109398826
              ],
              [
                -73.97110765876137,
                40.805790139589654
              ],
              [
                -73.97084148399995,
                40.80568534399987
              ],
              [
                -73.97076362399993,
                40.80565737299987
              ],
              [
                -73.97069326499995,
                40.80563026799993
              ],
              [
                -73.96978722000001,
                40.80530049699997
              ],
              [
                -73.96860707399988,
                40.804870949999895
              ],
              [
                -73.96855913399985,
                40.80485358399993
              ],
              [
                -73.96849506699984,
                40.80483226999993
              ],
              [
                -73.96804849299988,
                40.804683699999906
              ],
              [
                -73.96680327299997,
                40.804207560999906
              ],
              [
                -73.96670106899995,
                40.80416847599993
              ],
              [
                -73.96659731599986,
                40.804122764999946
              ],
              [
                -73.96386126299988,
                40.80297203799993
              ],
              [
                -73.96107793999975,
                40.801800357999866
              ],
              [
                -73.95964685399987,
                40.80115642299993
              ],
              [
                -73.95914741099993,
                40.80184375999991
              ],
              [
                -73.95868839399998,
                40.80246979399986
              ],
              [
                -73.95824878299987,
                40.80310648599989
              ],
              [
                -73.958203008,
                40.803894270999905
              ],
              [
                -73.95825482599987,
                40.804713877999916
              ],
              [
                -73.95818167299996,
                40.80559679099991
              ],
              [
                -73.95772176,
                40.8062909889999
              ],
              [
                -73.95726581599985,
                40.80691617099996
              ],
              [
                -73.95680543799989,
                40.80754570899993
              ],
              [
                -73.95634493700001,
                40.80817335399992
              ],
              [
                -73.95588417599986,
                40.808802248999875
              ],
              [
                -73.95542974899983,
                40.80942915299993
              ],
              [
                -73.95496657099991,
                40.81006455499995
              ],
              [
                -73.9535757779999,
                40.8094766979999
              ],
              [
                -73.9532765489999,
                40.80987960399989
              ],
              [
                -73.95307869999975,
                40.81009408499988
              ],
              [
                -73.9525912579999,
                40.81076533299994
              ],
              [
                -73.9521012519999,
                40.811442853999935
              ],
              [
                -73.95354382999984,
                40.81205082099992
              ],
              [
                -73.95408058399987,
                40.81228011299991
              ],
              [
                -73.95520337899985,
                40.8135541289999
              ],
              [
                -73.95570690100003,
                40.81414395999996
              ],
              [
                -73.95626957999987,
                40.81337502299987
              ],
              [
                -73.95707738499995,
                40.81429809399993
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000167529391193,
        "objectid": 167,
        "shape_leng": 0.0908159737292,
        "location_id": 167,
        "zone": "Morrisania/Melrose",
        "borough": "Bronx"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.89690017899987,
                40.8310333359999
              ],
              [
                -73.89669216099999,
                40.83196614399989
              ],
              [
                -73.89647296199996,
                40.83304756799988
              ],
              [
                -73.8963019999999,
                40.8335432179999
              ],
              [
                -73.89678082699997,
                40.834126788999924
              ],
              [
                -73.89697220299995,
                40.8343452539999
              ],
              [
                -73.89809341399993,
                40.834628802999916
              ],
              [
                -73.89845304899997,
                40.83472024199993
              ],
              [
                -73.89963414999981,
                40.835016222999926
              ],
              [
                -73.9012157839999,
                40.83541014099991
              ],
              [
                -73.90122534999992,
                40.83549991099993
              ],
              [
                -73.90134101199988,
                40.835441323999866
              ],
              [
                -73.90185605899981,
                40.83462212499989
              ],
              [
                -73.90343501499996,
                40.83221638099998
              ],
              [
                -73.90367608499997,
                40.83185824799992
              ],
              [
                -73.90397692599986,
                40.83141130499994
              ],
              [
                -73.9045062189998,
                40.8306249629999
              ],
              [
                -73.90553116799988,
                40.8291041329999
              ],
              [
                -73.90657083599997,
                40.829452219999936
              ],
              [
                -73.90747341000002,
                40.829743308999916
              ],
              [
                -73.907527588,
                40.82976078199988
              ],
              [
                -73.90772165099985,
                40.829823367999914
              ],
              [
                -73.908959903,
                40.83022623299989
              ],
              [
                -73.90948178399991,
                40.829592118999855
              ],
              [
                -73.91030770399989,
                40.828772484999895
              ],
              [
                -73.91169430299983,
                40.82775871599993
              ],
              [
                -73.91179361999995,
                40.8264281559999
              ],
              [
                -73.91130974499993,
                40.824848557999914
              ],
              [
                -73.91192435199989,
                40.82504975899994
              ],
              [
                -73.91316674499997,
                40.82545645499987
              ],
              [
                -73.91351191699981,
                40.824804943999915
              ],
              [
                -73.91475121099991,
                40.82520047299994
              ],
              [
                -73.91527590199993,
                40.82565539499991
              ],
              [
                -73.91541108199998,
                40.82577390899994
              ],
              [
                -73.91545061499994,
                40.825747778999855
              ],
              [
                -73.91592284999992,
                40.8254400559999
              ],
              [
                -73.91613011899993,
                40.825305720999964
              ],
              [
                -73.91618778499992,
                40.825268345999895
              ],
              [
                -73.91624748099997,
                40.82522965499989
              ],
              [
                -73.91630388,
                40.825193101999936
              ],
              [
                -73.9163666169999,
                40.82515243999989
              ],
              [
                -73.91642451899982,
                40.825114912999936
              ],
              [
                -73.91661368499985,
                40.82499230799993
              ],
              [
                -73.91630917499997,
                40.82490286699987
              ],
              [
                -73.91608782899984,
                40.824837851999895
              ],
              [
                -73.91677400599986,
                40.82438708699987
              ],
              [
                -73.91587735799988,
                40.824103731999855
              ],
              [
                -73.91621588599988,
                40.82346792999992
              ],
              [
                -73.91450553699998,
                40.822941856999925
              ],
              [
                -73.91287633399996,
                40.822441347999884
              ],
              [
                -73.91189154999992,
                40.82214148199989
              ],
              [
                -73.91181615799985,
                40.82203502199992
              ],
              [
                -73.91180780599983,
                40.82203976999995
              ],
              [
                -73.91179935999997,
                40.8220444549999
              ],
              [
                -73.91179083099985,
                40.82204905899987
              ],
              [
                -73.91178220799999,
                40.822053599999876
              ],
              [
                -73.91177350099997,
                40.82205805099993
              ],
              [
                -73.91176469899987,
                40.8220624379999
              ],
              [
                -73.91175582700001,
                40.82206673699992
              ],
              [
                -73.91174685999992,
                40.82207096199994
              ],
              [
                -73.91173782099985,
                40.82207510599989
              ],
              [
                -73.9117286999999,
                40.82207916899994
              ],
              [
                -73.91171949699985,
                40.822083150999944
              ],
              [
                -73.91171023399987,
                40.82208704299993
              ],
              [
                -73.9117008879999,
                40.82209084599988
              ],
              [
                -73.91169147099988,
                40.822094565999876
              ],
              [
                -73.91168198299992,
                40.82209820599994
              ],
              [
                -73.91167243599985,
                40.8221017459999
              ],
              [
                -73.91166281800001,
                40.82210519699988
              ],
              [
                -73.91165313999997,
                40.82210855799985
              ],
              [
                -73.91164340399992,
                40.8221118279999
              ],
              [
                -73.91163361999985,
                40.8221150089999
              ],
              [
                -73.91162376599982,
                40.8221180889999
              ],
              [
                -73.91161386399986,
                40.8221210799999
              ],
              [
                -73.9116039149999,
                40.8221239719999
              ],
              [
                -73.91159391799995,
                40.82212677499996
              ],
              [
                -73.9115838739999,
                40.82212947799998
              ],
              [
                -73.91157378299995,
                40.822132080999914
              ],
              [
                -73.91156365699992,
                40.822134584999894
              ],
              [
                -73.91155348299996,
                40.82213699099988
              ],
              [
                -73.9115432849999,
                40.82213929699986
              ],
              [
                -73.91153305199994,
                40.82214150499988
              ],
              [
                -73.9115227839998,
                40.82214361299988
              ],
              [
                -73.91151249299988,
                40.822145621999915
              ],
              [
                -73.91150216499999,
                40.822147531999875
              ],
              [
                -73.91149182600002,
                40.822149333999896
              ],
              [
                -73.91148146299997,
                40.822151046999885
              ],
              [
                -73.91147107699982,
                40.82215264999993
              ],
              [
                -73.91146067899994,
                40.82215415499997
              ],
              [
                -73.91145026999986,
                40.822155560999896
              ],
              [
                -73.9114398599999,
                40.822156858999925
              ],
              [
                -73.91142942699986,
                40.82215805799994
              ],
              [
                -73.911418994,
                40.82215915699986
              ],
              [
                -73.91140856199999,
                40.82216015799987
              ],
              [
                -73.9113981169998,
                40.822161058999875
              ],
              [
                -73.91138768499987,
                40.82216185299992
              ],
              [
                -73.91137726399995,
                40.822162555999945
              ],
              [
                -73.91136683199993,
                40.822163151999874
              ],
              [
                -73.91135642300002,
                40.82216364799994
              ],
              [
                -73.91107298599978,
                40.82224294299992
              ],
              [
                -73.91069998799996,
                40.8223241139999
              ],
              [
                -73.90990894100004,
                40.822551107999914
              ],
              [
                -73.90945760899984,
                40.82243240199992
              ],
              [
                -73.90841854199991,
                40.82218127099991
              ],
              [
                -73.90742681699992,
                40.82194506199991
              ],
              [
                -73.90645722199996,
                40.821715363999886
              ],
              [
                -73.90565890299979,
                40.821556532999864
              ],
              [
                -73.90552466299982,
                40.82152381299992
              ],
              [
                -73.90545592300005,
                40.82150705899991
              ],
              [
                -73.90532770299998,
                40.82147580599995
              ],
              [
                -73.90525022600004,
                40.821456921999896
              ],
              [
                -73.90489653200002,
                40.82137070999988
              ],
              [
                -73.90478103799988,
                40.82134144499991
              ],
              [
                -73.90379310199981,
                40.821091099999876
              ],
              [
                -73.90266819199994,
                40.82080315899992
              ],
              [
                -73.9012927759998,
                40.820475442999914
              ],
              [
                -73.90084561199987,
                40.82154141799985
              ],
              [
                -73.90061141099989,
                40.822108807999875
              ],
              [
                -73.90198758599993,
                40.822440422999904
              ],
              [
                -73.901846838,
                40.822778632999885
              ],
              [
                -73.90181687399985,
                40.82285063499994
              ],
              [
                -73.901176691,
                40.824388908999914
              ],
              [
                -73.90050540499992,
                40.82600762199993
              ],
              [
                -73.90164895599993,
                40.82625697799991
              ],
              [
                -73.90093510699997,
                40.82792810199991
              ],
              [
                -73.89979756799993,
                40.82768435399992
              ],
              [
                -73.89940994800001,
                40.82773418399995
              ],
              [
                -73.89932566999991,
                40.82774501799988
              ],
              [
                -73.89820469300003,
                40.82788910999992
              ],
              [
                -73.89700278099991,
                40.82805045499991
              ],
              [
                -73.89576668599982,
                40.8282118029999
              ],
              [
                -73.89548522799996,
                40.82824854099993
              ],
              [
                -73.89529156500001,
                40.82814234999994
              ],
              [
                -73.8951102089999,
                40.82803689899988
              ],
              [
                -73.89515277899991,
                40.82783428999991
              ],
              [
                -73.89483522899992,
                40.826246840999964
              ],
              [
                -73.89386719099983,
                40.82651496799989
              ],
              [
                -73.89293226099981,
                40.82678703699992
              ],
              [
                -73.89191271899998,
                40.82679444999992
              ],
              [
                -73.89090560099991,
                40.82678778499993
              ],
              [
                -73.88997942599995,
                40.82680999199989
              ],
              [
                -73.88983599199996,
                40.82680473399996
              ],
              [
                -73.88980564199993,
                40.82680362199994
              ],
              [
                -73.88969004999984,
                40.826799383999834
              ],
              [
                -73.88963895299987,
                40.8267975099999
              ],
              [
                -73.88885046199992,
                40.82676859499989
              ],
              [
                -73.88789510999992,
                40.82674318499989
              ],
              [
                -73.88639957199996,
                40.827503378999886
              ],
              [
                -73.88609624199987,
                40.827655776999904
              ],
              [
                -73.88601262699991,
                40.82823853099994
              ],
              [
                -73.88755325099989,
                40.8292963429999
              ],
              [
                -73.88761307899999,
                40.829337419999945
              ],
              [
                -73.88805361099989,
                40.82950305199992
              ],
              [
                -73.88898205299992,
                40.829814800999934
              ],
              [
                -73.8899110649999,
                40.83012419199988
              ],
              [
                -73.89087299799995,
                40.8301360029999
              ],
              [
                -73.89187594399993,
                40.83013750599992
              ],
              [
                -73.8928855849999,
                40.83014757099991
              ],
              [
                -73.893360418,
                40.83015253099996
              ],
              [
                -73.894692052,
                40.8306190939999
              ],
              [
                -73.89485843399979,
                40.830601857999895
              ],
              [
                -73.89560633499985,
                40.83052438299989
              ],
              [
                -73.89669632499997,
                40.830414504999965
              ],
              [
                -73.89717220999982,
                40.83036883299998
              ],
              [
                -73.89690017899987,
                40.8310333359999
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000175805409143,
        "objectid": 178,
        "shape_leng": 0.0682528770739,
        "location_id": 178,
        "zone": "Ocean Parkway South",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.97080408199996,
                40.62593266699988
              ],
              [
                -73.97086150900002,
                40.626237182999894
              ],
              [
                -73.97092126300004,
                40.62656998499993
              ],
              [
                -73.97108160299985,
                40.62655370899989
              ],
              [
                -73.97119209799985,
                40.626542695999916
              ],
              [
                -73.97229894799983,
                40.626431167999904
              ],
              [
                -73.97322001099991,
                40.62631854299984
              ],
              [
                -73.97414911500005,
                40.62621489799991
              ],
              [
                -73.97507611299997,
                40.626113766999936
              ],
              [
                -73.975635809,
                40.62603520899996
              ],
              [
                -73.97623453700001,
                40.62597634999991
              ],
              [
                -73.9772614999999,
                40.62585277299993
              ],
              [
                -73.97719665599993,
                40.62510197899993
              ],
              [
                -73.97710959299997,
                40.6249482599999
              ],
              [
                -73.976943824,
                40.624052749999855
              ],
              [
                -73.97683465199992,
                40.623481041999895
              ],
              [
                -73.97675209599991,
                40.6230133119999
              ],
              [
                -73.97656583899989,
                40.62199490299991
              ],
              [
                -73.97695070799992,
                40.621630006999986
              ],
              [
                -73.97705352899999,
                40.62153252199987
              ],
              [
                -73.97539380499998,
                40.62076998699996
              ],
              [
                -73.97682433599995,
                40.6186757999999
              ],
              [
                -73.97752301899989,
                40.617676540999895
              ],
              [
                -73.97785009299986,
                40.617287605999906
              ],
              [
                -73.97562954199996,
                40.61594539099984
              ],
              [
                -73.97537335500002,
                40.615754721999885
              ],
              [
                -73.975442767,
                40.61611408999994
              ],
              [
                -73.97548096999985,
                40.616311924999906
              ],
              [
                -73.97444328399989,
                40.61642164299991
              ],
              [
                -73.97335879399992,
                40.6165417149999
              ],
              [
                -73.97290326899996,
                40.61415296799988
              ],
              [
                -73.97399319500002,
                40.61402989399994
              ],
              [
                -73.97517176999992,
                40.61472418599991
              ],
              [
                -73.97497264699993,
                40.613668109999885
              ],
              [
                -73.97489295799981,
                40.61318636799989
              ],
              [
                -73.97477658,
                40.6126384749999
              ],
              [
                -73.97458407499984,
                40.61161357599985
              ],
              [
                -73.97451543899997,
                40.61125680099988
              ],
              [
                -73.974390729,
                40.6105792129999
              ],
              [
                -73.97418385499991,
                40.60946350199992
              ],
              [
                -73.97403097799986,
                40.608702309999906
              ],
              [
                -73.97316643599986,
                40.60879557699993
              ],
              [
                -73.97299433999983,
                40.608814141999886
              ],
              [
                -73.97191331299986,
                40.60893592099993
              ],
              [
                -73.9708953839999,
                40.60904971899988
              ],
              [
                -73.96996969299995,
                40.60915048299991
              ],
              [
                -73.96904086599989,
                40.609253192999894
              ],
              [
                -73.96792168899995,
                40.60938918999989
              ],
              [
                -73.96768635599992,
                40.60941778699993
              ],
              [
                -73.96739979599994,
                40.60945960699996
              ],
              [
                -73.96629835499999,
                40.60955521299994
              ],
              [
                -73.96522378099996,
                40.609674400999936
              ],
              [
                -73.96429649899991,
                40.60977642599992
              ],
              [
                -73.96478112799986,
                40.61233092199989
              ],
              [
                -73.96385332599989,
                40.612432518999874
              ],
              [
                -73.96285973999984,
                40.61254948599989
              ],
              [
                -73.9633609399999,
                40.615204427999906
              ],
              [
                -73.96356470599983,
                40.616238721999935
              ],
              [
                -73.9636224559999,
                40.616589736999856
              ],
              [
                -73.96375418699988,
                40.61730243299991
              ],
              [
                -73.96381145199987,
                40.61758940199998
              ],
              [
                -73.96388935599992,
                40.618000549999934
              ],
              [
                -73.96426798299994,
                40.61998380299988
              ],
              [
                -73.96471983399981,
                40.62237296099993
              ],
              [
                -73.96571874599992,
                40.622264028999915
              ],
              [
                -73.9666451569999,
                40.62216223699988
              ],
              [
                -73.96757137699979,
                40.62206027499997
              ],
              [
                -73.96864510599995,
                40.6219416949999
              ],
              [
                -73.96909724499984,
                40.624332824999975
              ],
              [
                -73.97019276999991,
                40.624203249999894
              ],
              [
                -73.97047171899992,
                40.62417002999995
              ],
              [
                -73.97080408199996,
                40.62593266699988
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000547058100444,
        "objectid": 168,
        "shape_leng": 0.114517434155,
        "location_id": 168,
        "zone": "Mott Haven/Port Morris",
        "borough": "Bronx"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.92480953899991,
                40.81565901999995
              ],
              [
                -73.92505519900006,
                40.81572157599992
              ],
              [
                -73.92550133099999,
                40.81583301299993
              ],
              [
                -73.92568897199988,
                40.81587494499995
              ],
              [
                -73.92662718199988,
                40.81611895899993
              ],
              [
                -73.92621972000003,
                40.816758654999944
              ],
              [
                -73.92518486499998,
                40.81801266899989
              ],
              [
                -73.92538538499986,
                40.818065414999865
              ],
              [
                -73.92582070699982,
                40.81818033699992
              ],
              [
                -73.92635859099991,
                40.81832069199992
              ],
              [
                -73.92724662700006,
                40.818542984999965
              ],
              [
                -73.92731653099999,
                40.81855963399993
              ],
              [
                -73.92775576099996,
                40.817572380999884
              ],
              [
                -73.92821699000002,
                40.81653564699993
              ],
              [
                -73.92885642099992,
                40.81487986599993
              ],
              [
                -73.92896567499989,
                40.81482825499992
              ],
              [
                -73.92919204499995,
                40.814496010999946
              ],
              [
                -73.92921297999986,
                40.81438195799991
              ],
              [
                -73.93027812699984,
                40.813248024999965
              ],
              [
                -73.93039605099992,
                40.81330374699994
              ],
              [
                -73.93056964999984,
                40.81337329799991
              ],
              [
                -73.93104478900003,
                40.81358844199988
              ],
              [
                -73.93115852299998,
                40.813635488999914
              ],
              [
                -73.9312360049999,
                40.813666728999884
              ],
              [
                -73.93130722899988,
                40.81369873899998
              ],
              [
                -73.93139319399977,
                40.81374459799993
              ],
              [
                -73.93222960499986,
                40.81409433299993
              ],
              [
                -73.93236752499996,
                40.81411173099992
              ],
              [
                -73.93242632000944,
                40.81411751897961
              ],
              [
                -73.93242839923347,
                40.81406068615789
              ],
              [
                -73.93243119390786,
                40.81401683319364
              ],
              [
                -73.93247701798535,
                40.813423607917436
              ],
              [
                -73.93263229277598,
                40.811904622131635
              ],
              [
                -73.93262208681354,
                40.811531179116784
              ],
              [
                -73.93261225770267,
                40.81139350530766
              ],
              [
                -73.93256503302071,
                40.81088597518973
              ],
              [
                -73.93267921899451,
                40.810799135187075
              ],
              [
                -73.93269089700772,
                40.81058293308123
              ],
              [
                -73.93278688085368,
                40.81049446019911
              ],
              [
                -73.93287412001943,
                40.810414043429
              ],
              [
                -73.93286397083267,
                40.81012443738852
              ],
              [
                -73.93283247594536,
                40.81011611568632
              ],
              [
                -73.93259434400991,
                40.81005315882681
              ],
              [
                -73.93262095288786,
                40.809770741773775
              ],
              [
                -73.93264257115321,
                40.80938286452633
              ],
              [
                -73.93252708785967,
                40.808823281775354
              ],
              [
                -73.9322696077928,
                40.808339527481216
              ],
              [
                -73.93191523558487,
                40.80793524371051
              ],
              [
                -73.93187509626175,
                40.80788945175409
              ],
              [
                -73.93184564493039,
                40.80785584548141
              ],
              [
                -73.93181050747292,
                40.807818417950465
              ],
              [
                -73.93176476876161,
                40.80776969347356
              ],
              [
                -73.93116268129293,
                40.807128295776856
              ],
              [
                -73.93051758839161,
                40.806590563566765
              ],
              [
                -73.93053645059723,
                40.806470276942896
              ],
              [
                -73.93031950058611,
                40.806190146201594
              ],
              [
                -73.93005458890505,
                40.80590234732321
              ],
              [
                -73.92872607489814,
                40.80410362522731
              ],
              [
                -73.92848299256855,
                40.80419302907306
              ],
              [
                -73.92841615833625,
                40.80410061878124
              ],
              [
                -73.92836704046069,
                40.80403270404446
              ],
              [
                -73.92822904734729,
                40.80397013297662
              ],
              [
                -73.92768097596573,
                40.80402420015814
              ],
              [
                -73.92764500352583,
                40.80398863284607
              ],
              [
                -73.92728271943585,
                40.80350826441703
              ],
              [
                -73.92743689902498,
                40.803277441693766
              ],
              [
                -73.92731248358167,
                40.80321177098279
              ],
              [
                -73.92750834884211,
                40.80293323371109
              ],
              [
                -73.92762788658843,
                40.80269566548149
              ],
              [
                -73.92700359398965,
                40.80251195043942
              ],
              [
                -73.92665794788495,
                40.802409803914706
              ],
              [
                -73.92422706820558,
                40.80254501117898
              ],
              [
                -73.92358699072928,
                40.8025157251619
              ],
              [
                -73.9229271008289,
                40.8023732948214
              ],
              [
                -73.92259611000095,
                40.80218820141712
              ],
              [
                -73.9220376598457,
                40.801877197786325
              ],
              [
                -73.92168826986943,
                40.801585723402034
              ],
              [
                -73.92132730789253,
                40.80128459181566
              ],
              [
                -73.9209564528218,
                40.800598814112334
              ],
              [
                -73.92078133912115,
                40.80027500972725
              ],
              [
                -73.92044066990172,
                40.7998041562453
              ],
              [
                -73.92024068146364,
                40.79961368793487
              ],
              [
                -73.91912380285072,
                40.79903245502606
              ],
              [
                -73.91904978748826,
                40.79899393584325
              ],
              [
                -73.91895581703086,
                40.79894691713742
              ],
              [
                -73.9188909264878,
                40.79891589479821
              ],
              [
                -73.91880394085273,
                40.79887972986115
              ],
              [
                -73.91616947166384,
                40.79783071846342
              ],
              [
                -73.91512767422381,
                40.797362948754
              ],
              [
                -73.91372255986785,
                40.79678943887929
              ],
              [
                -73.91344774544463,
                40.79678242853534
              ],
              [
                -73.91317604910027,
                40.79677239148837
              ],
              [
                -73.91300786031593,
                40.79676243196518
              ],
              [
                -73.9126682602596,
                40.79673513462004
              ],
              [
                -73.91234481835205,
                40.79671768244817
              ],
              [
                -73.91223808592994,
                40.79671022611217
              ],
              [
                -73.91215402389724,
                40.7966806644417
              ],
              [
                -73.91214700601829,
                40.796678118073665
              ],
              [
                -73.91208612977921,
                40.79665603089616
              ],
              [
                -73.91203438162788,
                40.79665107505946
              ],
              [
                -73.91195998908077,
                40.79664855949788
              ],
              [
                -73.91188561236626,
                40.796633753595536
              ],
              [
                -73.91182091990503,
                40.7966337041236
              ],
              [
                -73.91178857213136,
                40.79663367937284
              ],
              [
                -73.9117465332402,
                40.796626273244925
              ],
              [
                -73.91168831241184,
                40.796623769888726
              ],
              [
                -73.9116228580015,
                40.79664107849611
              ],
              [
                -73.91149413374444,
                40.79669982456393
              ],
              [
                -73.91141644034329,
                40.796746470854565
              ],
              [
                -73.91126755502734,
                40.796815184825704
              ],
              [
                -73.91109827415714,
                40.79688807133547
              ],
              [
                -73.91105069334637,
                40.796920719929275
              ],
              [
                -73.9109859346481,
                40.79696983299864
              ],
              [
                -73.91095679669981,
                40.79698947591845
              ],
              [
                -73.91090501284451,
                40.7970115600936
              ],
              [
                -73.91089222265335,
                40.7970177950363
              ],
              [
                -73.91079168499027,
                40.797097509262294
              ],
              [
                -73.91071392139699,
                40.79719577567378
              ],
              [
                -73.91060709552251,
                40.79725714741217
              ],
              [
                -73.9104679052537,
                40.79733078589607
              ],
              [
                -73.9103254478973,
                40.79742900177439
              ],
              [
                -73.91013438705258,
                40.797588634628404
              ],
              [
                -73.91004369318306,
                40.7976795165524
              ],
              [
                -73.90996589145395,
                40.797804822964714
              ],
              [
                -73.90994644280848,
                40.79783430590632
              ],
              [
                -73.90991082881695,
                40.797858861028395
              ],
              [
                -73.90984607703047,
                40.79790059833692
              ],
              [
                -73.90979388517711,
                40.797868495233274
              ],
              [
                -73.90974077921025,
                40.79791214367448
              ],
              [
                -73.9096745552532,
                40.79796191908997
              ],
              [
                -73.90967334768548,
                40.79796124955154
              ],
              [
                -73.90963900093544,
                40.79794222580131
              ],
              [
                -73.9091565966024,
                40.79830942792147
              ],
              [
                -73.90830303542083,
                40.798959135920065
              ],
              [
                -73.90824133200054,
                40.79902510304329
              ],
              [
                -73.90824310840051,
                40.799056907059764
              ],
              [
                -73.90825513733583,
                40.799091000088985
              ],
              [
                -73.90825579566476,
                40.799092868181084
              ],
              [
                -73.90829031194237,
                40.79913299559319
              ],
              [
                -73.90837756004214,
                40.79919805242705
              ],
              [
                -73.90848846163395,
                40.79926451139593
              ],
              [
                -73.90855026742408,
                40.79930742538956
              ],
              [
                -73.90847556062435,
                40.799383415386544
              ],
              [
                -73.90842190768012,
                40.79944209244497
              ],
              [
                -73.90832086851556,
                40.79940541663984
              ],
              [
                -73.90825177132321,
                40.79937217681462
              ],
              [
                -73.90816814020228,
                40.7993223306837
              ],
              [
                -73.90802633276698,
                40.79923787216215
              ],
              [
                -73.90796076542273,
                40.7992834492685
              ],
              [
                -73.90813152403726,
                40.79943130077593
              ],
              [
                -73.90816794754268,
                40.799461986193734
              ],
              [
                -73.90810210034232,
                40.799539646862826
              ],
              [
                -73.90805140706364,
                40.79952688191277
              ],
              [
                -73.90799501744833,
                40.799514392328135
              ],
              [
                -73.90793317717971,
                40.79949636769412
              ],
              [
                -73.90784951426157,
                40.79947002824018
              ],
              [
                -73.90778950640636,
                40.79944232607244
              ],
              [
                -73.9076822280089,
                40.79938831392528
              ],
              [
                -73.90765852928469,
                40.799420098716624
              ],
              [
                -73.90773488799034,
                40.7994657893005
              ],
              [
                -73.90779307559532,
                40.79949348953792
              ],
              [
                -73.90787127636884,
                40.799522589666495
              ],
              [
                -73.90795130658027,
                40.79954477778335
              ],
              [
                -73.90802770464775,
                40.799561430602715
              ],
              [
                -73.90807397255666,
                40.79957281916722
              ],
              [
                -73.90802032355599,
                40.799636092924835
              ],
              [
                -73.90799121626038,
                40.799631921275214
              ],
              [
                -73.90795847677178,
                40.79962221554705
              ],
              [
                -73.90793663556686,
                40.79962772927608
              ],
              [
                -73.90790019737781,
                40.79966088618895
              ],
              [
                -73.90775808108162,
                40.799799044286004
              ],
              [
                -73.90764331288219,
                40.799895744109016
              ],
              [
                -73.90764146516834,
                40.79991648288546
              ],
              [
                -73.90763052690512,
                40.79993168488236
              ],
              [
                -73.9076086725145,
                40.799945494205694
              ],
              [
                -73.90757045920829,
                40.79994684581937
              ],
              [
                -73.9075395155793,
                40.79995511834565
              ],
              [
                -73.90752658104599,
                40.79996321969477
              ],
              [
                -73.90749762847857,
                40.799981356167365
              ],
              [
                -73.9074703050283,
                40.800003458417955
              ],
              [
                -73.90745262874306,
                40.80002824445095
              ],
              [
                -73.90743384140606,
                40.80005458974867
              ],
              [
                -73.90739374242607,
                40.80010433539613
              ],
              [
                -73.90734272035664,
                40.800156838421394
              ],
              [
                -73.9072629877428,
                40.800237448444896
              ],
              [
                -73.90717872796303,
                40.800322633558224
              ],
              [
                -73.90676872957964,
                40.80074541735579
              ],
              [
                -73.90676850092169,
                40.80074671046322
              ],
              [
                -73.90670130044884,
                40.80082002982634
              ],
              [
                -73.9067500914722,
                40.80085059452257
              ],
              [
                -73.90681401688877,
                40.80089063960622
              ],
              [
                -73.90672893676945,
                40.80096996755171
              ],
              [
                -73.90658587767751,
                40.80110747844539
              ],
              [
                -73.90645171125526,
                40.80103691520162
              ],
              [
                -73.90638976821404,
                40.80109079168585
              ],
              [
                -73.90652042213162,
                40.801175510519826
              ],
              [
                -73.90620386757755,
                40.80149299299668
              ],
              [
                -73.90606336502219,
                40.80158339144959
              ],
              [
                -73.90601427821801,
                40.80155293109594
              ],
              [
                -73.9057605151772,
                40.801799881262475
              ],
              [
                -73.90552153240687,
                40.80201995489853
              ],
              [
                -73.90550588373289,
                40.80206135891333
              ],
              [
                -73.90549128546061,
                40.80209038453587
              ],
              [
                -73.90546395588993,
                40.802115251297714
              ],
              [
                -73.90543844595938,
                40.80214011954579
              ],
              [
                -73.90539107059975,
                40.80218571009474
              ],
              [
                -73.90531233908494,
                40.80227472998736
              ],
              [
                -73.90524527987044,
                40.802340456710375
              ],
              [
                -73.90518695888107,
                40.802405396178024
              ],
              [
                -73.90515780873844,
                40.80243026086632
              ],
              [
                -73.90511408874724,
                40.80246479364503
              ],
              [
                -73.90508491686398,
                40.80250486787974
              ],
              [
                -73.90506666976971,
                40.80254080436112
              ],
              [
                -73.90505752846576,
                40.80257121666834
              ],
              [
                -73.90504498642524,
                40.8026003333841
              ],
              [
                -73.90503014241452,
                40.80263480045381
              ],
              [
                -73.90500098071378,
                40.802667961258535
              ],
              [
                -73.90495358976553,
                40.80272461344582
              ],
              [
                -73.90485211233522,
                40.80283522898895
              ],
              [
                -73.90479864625082,
                40.802886243911
              ],
              [
                -73.90476931117227,
                40.80291284189395
              ],
              [
                -73.90467203828253,
                40.803014796698285
              ],
              [
                -73.90462487688006,
                40.803069202764306
              ],
              [
                -73.90457566775626,
                40.80312360745076
              ],
              [
                -73.90455004089955,
                40.80315003003259
              ],
              [
                -73.9045295409574,
                40.803170235651834
              ],
              [
                -73.90449983884291,
                40.80318343345092
              ],
              [
                -73.90448088690111,
                40.80319169531934
              ],
              [
                -73.90445884046326,
                40.803211385845806
              ],
              [
                -73.90437865610475,
                40.80327459751856
              ],
              [
                -73.904148988348,
                40.8034556525867
              ],
              [
                -73.9040977498009,
                40.80341659812556
              ],
              [
                -73.90401680971797,
                40.80347097616888
              ],
              [
                -73.90407055303483,
                40.80352097714822
              ],
              [
                -73.90400232330266,
                40.80357907559452
              ],
              [
                -73.9039674817376,
                40.80360782449495
              ],
              [
                -73.90394842506747,
                40.80362130994786
              ],
              [
                -73.90387236017021,
                40.80355874470061
              ],
              [
                -73.90385084302861,
                40.803574282777895
              ],
              [
                -73.90392732704238,
                40.80363439130163
              ],
              [
                -73.90389269713366,
                40.80365209611302
              ],
              [
                -73.90385991710878,
                40.80366995724983
              ],
              [
                -73.90382200372163,
                40.803698703544086
              ],
              [
                -73.90379945054784,
                40.803722796073195
              ],
              [
                -73.90377177524543,
                40.803749995258485
              ],
              [
                -73.90376599453884,
                40.80375345467898
              ],
              [
                -73.90368997946493,
                40.803687704539584
              ],
              [
                -73.90366539253351,
                40.80370246203189
              ],
              [
                -73.90374201335595,
                40.80376780879086
              ],
              [
                -73.90373284581685,
                40.80377329611779
              ],
              [
                -73.90368265865864,
                40.80379503309547
              ],
              [
                -73.90363555376418,
                40.80380977075968
              ],
              [
                -73.90356583811838,
                40.803831023631005
              ],
              [
                -73.90355158434579,
                40.80383536869234
              ],
              [
                -73.90339901556943,
                40.80387490713975
              ],
              [
                -73.90335633787747,
                40.80386112361064
              ],
              [
                -73.90333877121293,
                40.803773744176645
              ],
              [
                -73.90331727421179,
                40.803775282455184
              ],
              [
                -73.90333865261518,
                40.80385541293835
              ],
              [
                -73.90325563815311,
                40.80392767616932
              ],
              [
                -73.9032126886261,
                40.80389963951358
              ],
              [
                -73.9030860978034,
                40.80399708789624
              ],
              [
                -73.90300283171007,
                40.803917353272695
              ],
              [
                -73.90297311945014,
                40.80393677187099
              ],
              [
                -73.90305966667553,
                40.804017434849776
              ],
              [
                -73.90289540505104,
                40.804143881212795
              ],
              [
                -73.9027097310254,
                40.8041636634672
              ],
              [
                -73.90271278211219,
                40.80417688793935
              ],
              [
                -73.90287760500664,
                40.80415758214557
              ],
              [
                -73.90292770331085,
                40.80419729170906
              ],
              [
                -73.9027559359522,
                40.804328534821494
              ],
              [
                -73.90252298450754,
                40.80446378809854
              ],
              [
                -73.90244539136891,
                40.80441695455712
              ],
              [
                -73.90229231992772,
                40.804564776141675
              ],
              [
                -73.90229048802287,
                40.8045730707531
              ],
              [
                -73.90230320400495,
                40.80458829228138
              ],
              [
                -73.90231697601617,
                40.80459644908813
              ],
              [
                -73.90231956717278,
                40.804597984779555
              ],
              [
                -73.90225572775613,
                40.80470025213065
              ],
              [
                -73.9022265326984,
                40.804754153035844
              ],
              [
                -73.90219372776716,
                40.804788693243474
              ],
              [
                -73.90219185700263,
                40.80482326082269
              ],
              [
                -73.90220455168733,
                40.804852308496756
              ],
              [
                -73.90221178642066,
                40.8048827347292
              ],
              [
                -73.90222993494517,
                40.80491593583728
              ],
              [
                -73.90223822507791,
                40.804924022797294
              ],
              [
                -73.90223923407719,
                40.80493413504008
              ],
              [
                -73.90223410896625,
                40.80493879715243
              ],
              [
                -73.90222284749719,
                40.80494811309475
              ],
              [
                -73.90288034499996,
                40.808744792999974
              ],
              [
                -73.90293929200004,
                40.80906270099993
              ],
              [
                -73.90298795399984,
                40.80936430099996
              ],
              [
                -73.90299766999989,
                40.80945558299994
              ],
              [
                -73.9030087789999,
                40.809550072999876
              ],
              [
                -73.90302018099997,
                40.80962342899996
              ],
              [
                -73.90302967699985,
                40.809702120999866
              ],
              [
                -73.90307568299997,
                40.809768985999945
              ],
              [
                -73.90307457199998,
                40.80988061199988
              ],
              [
                -73.90324273499989,
                40.8107128849999
              ],
              [
                -73.90341720399988,
                40.811506882999886
              ],
              [
                -73.90418036399996,
                40.81212482099991
              ],
              [
                -73.9044655149999,
                40.81228195999996
              ],
              [
                -73.9054135989999,
                40.81247619599993
              ],
              [
                -73.90632991800001,
                40.81266707399989
              ],
              [
                -73.90724503699998,
                40.81285516299992
              ],
              [
                -73.90815917699986,
                40.8130435759999
              ],
              [
                -73.90907451799984,
                40.81323307299989
              ],
              [
                -73.90949684799988,
                40.813322147999926
              ],
              [
                -73.9095236779999,
                40.813327805999975
              ],
              [
                -73.91001840399991,
                40.81343214799989
              ],
              [
                -73.91013342199993,
                40.81345551199998
              ],
              [
                -73.91029615499986,
                40.81348856499991
              ],
              [
                -73.91033664499996,
                40.813496788999885
              ],
              [
                -73.91038742199996,
                40.8135071039999
              ],
              [
                -73.91043307699985,
                40.813516376999885
              ],
              [
                -73.91094778999998,
                40.813620920999874
              ],
              [
                -73.91181210799998,
                40.813883314999885
              ],
              [
                -73.91295450799997,
                40.81426933499994
              ],
              [
                -73.9149549369999,
                40.81493646599995
              ],
              [
                -73.91677117699994,
                40.81569882899992
              ],
              [
                -73.91768626999985,
                40.8160812429999
              ],
              [
                -73.91861739800004,
                40.815523135999854
              ],
              [
                -73.91946744899994,
                40.81507370999988
              ],
              [
                -73.92034892799985,
                40.8146288639999
              ],
              [
                -73.92048117399989,
                40.8145636869999
              ],
              [
                -73.9213038339999,
                40.81547724799986
              ],
              [
                -73.92158392200005,
                40.815550631999955
              ],
              [
                -73.92124519699988,
                40.816219396999955
              ],
              [
                -73.92309299899993,
                40.81669772799995
              ],
              [
                -73.92372701399987,
                40.815817319999915
              ],
              [
                -73.92376838899996,
                40.81575986499996
              ],
              [
                -73.92390804799992,
                40.815565926999945
              ],
              [
                -73.92395483099982,
                40.81550096099991
              ],
              [
                -73.92399951299991,
                40.81543891199993
              ],
              [
                -73.92480953899991,
                40.81565901999995
              ]
            ]
          ],
          [
            [
              [
                -73.89833036270552,
                40.80241282093998
              ],
              [
                -73.89938748571029,
                40.80193566507032
              ],
              [
                -73.89948919423654,
                40.80090111592956
              ],
              [
                -73.90003735815736,
                40.800908742050225
              ],
              [
                -73.89971563817438,
                40.800798808502805
              ],
              [
                -73.89978738800835,
                40.79950955437454
              ],
              [
                -73.90021004993142,
                40.79926415589601
              ],
              [
                -73.89902455567211,
                40.799172199839965
              ],
              [
                -73.89864038714974,
                40.79910116462135
              ],
              [
                -73.89798486686917,
                40.79960428081981
              ],
              [
                -73.89646668834577,
                40.80079047089134
              ],
              [
                -73.89833036270552,
                40.80241282093998
              ]
            ]
          ],
          [
            [
              [
                -73.89680883223778,
                40.79580844515979
              ],
              [
                -73.89678526341211,
                40.796329166487105
              ],
              [
                -73.89713149795635,
                40.79679807772831
              ],
              [
                -73.8978825324018,
                40.79711653214705
              ],
              [
                -73.89852052071454,
                40.796936194189726
              ],
              [
                -73.89919434249981,
                40.796502456018175
              ],
              [
                -73.89895261832532,
                40.796227852579634
              ],
              [
                -73.89857332665562,
                40.7960691402596
              ],
              [
                -73.89796839783742,
                40.795644839161994
              ],
              [
                -73.89723603843935,
                40.79572003753713
              ],
              [
                -73.89693872998787,
                40.79563587285353
              ],
              [
                -73.89680883223778,
                40.79580844515979
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000146027537733,
        "objectid": 169,
        "shape_leng": 0.0601046580332,
        "location_id": 169,
        "zone": "Mount Hope",
        "borough": "Bronx"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.89816577499982,
                40.85514637599989
              ],
              [
                -73.89877598599993,
                40.85536324699995
              ],
              [
                -73.89967155800004,
                40.85566890799991
              ],
              [
                -73.90060385999978,
                40.85598723799997
              ],
              [
                -73.90077922699986,
                40.85604710999993
              ],
              [
                -73.90085477099996,
                40.85593743999986
              ],
              [
                -73.90116590999993,
                40.85549567499992
              ],
              [
                -73.90130126599995,
                40.85530348999987
              ],
              [
                -73.90153703400001,
                40.85499464299988
              ],
              [
                -73.90185996099993,
                40.85458614899994
              ],
              [
                -73.90208001099982,
                40.85462259899992
              ],
              [
                -73.90298864599995,
                40.85477310899994
              ],
              [
                -73.90395154299999,
                40.85497392299991
              ],
              [
                -73.90472622199998,
                40.85537039499997
              ],
              [
                -73.90557007299988,
                40.855787321999884
              ],
              [
                -73.90733289699989,
                40.853816414999876
              ],
              [
                -73.9084173709999,
                40.85264382199996
              ],
              [
                -73.90921545599986,
                40.85180094499991
              ],
              [
                -73.910581699,
                40.850181594999896
              ],
              [
                -73.9106946369999,
                40.85001909799993
              ],
              [
                -73.91112232299982,
                40.84941809799992
              ],
              [
                -73.9116799109998,
                40.84863951899994
              ],
              [
                -73.91197263799982,
                40.84822959499992
              ],
              [
                -73.91236345699993,
                40.84768333899996
              ],
              [
                -73.91305693099993,
                40.84670883799991
              ],
              [
                -73.91315074799996,
                40.84657700399988
              ],
              [
                -73.91346470799996,
                40.84614733699992
              ],
              [
                -73.91360819800002,
                40.84594143899991
              ],
              [
                -73.9137516889998,
                40.84573553999996
              ],
              [
                -73.9139753489999,
                40.845429430999936
              ],
              [
                -73.91412710499995,
                40.845228913999925
              ],
              [
                -73.91416184199993,
                40.845179187999925
              ],
              [
                -73.9147938479999,
                40.84516499699993
              ],
              [
                -73.9156148529999,
                40.845146555999875
              ],
              [
                -73.91689747599987,
                40.84511773399992
              ],
              [
                -73.91684309399992,
                40.84507077899991
              ],
              [
                -73.91611927099989,
                40.84485579299996
              ],
              [
                -73.915866478,
                40.84479392499991
              ],
              [
                -73.91474419399994,
                40.8443425039999
              ],
              [
                -73.91385799499986,
                40.843978793999945
              ],
              [
                -73.91302876899995,
                40.8436416209999
              ],
              [
                -73.91293803199989,
                40.84359755499991
              ],
              [
                -73.91211222199998,
                40.843260341999915
              ],
              [
                -73.91193751499992,
                40.84320238799992
              ],
              [
                -73.91175379899997,
                40.84318035699991
              ],
              [
                -73.91096872799983,
                40.84313621399989
              ],
              [
                -73.91043026399994,
                40.8431077299999
              ],
              [
                -73.91046008499987,
                40.84279164299989
              ],
              [
                -73.90958727299986,
                40.84275637499993
              ],
              [
                -73.90865715699992,
                40.842719369999905
              ],
              [
                -73.90774413799994,
                40.84270215599994
              ],
              [
                -73.90684546599987,
                40.842667510999966
              ],
              [
                -73.90596061799985,
                40.84262870399992
              ],
              [
                -73.9058672189999,
                40.84257998699996
              ],
              [
                -73.90514893999988,
                40.84254719499995
              ],
              [
                -73.9050151499998,
                40.84240469799989
              ],
              [
                -73.90493735799986,
                40.84337489399997
              ],
              [
                -73.90484645099987,
                40.84458935999987
              ],
              [
                -73.90484219199982,
                40.844665447999944
              ],
              [
                -73.90483494099993,
                40.84475261899998
              ],
              [
                -73.90483121999989,
                40.84480034199988
              ],
              [
                -73.9041010739999,
                40.84473082199992
              ],
              [
                -73.90338651199983,
                40.84465529599993
              ],
              [
                -73.90268810099988,
                40.84458147199991
              ],
              [
                -73.901344046,
                40.84442875099995
              ],
              [
                -73.9013172989999,
                40.844473940999926
              ],
              [
                -73.90126635699991,
                40.84456221799993
              ],
              [
                -73.90107505699997,
                40.844904978999885
              ],
              [
                -73.90088731499989,
                40.845241359999925
              ],
              [
                -73.90020067899994,
                40.84647356099991
              ],
              [
                -73.89967790699988,
                40.84742465799994
              ],
              [
                -73.89887482600001,
                40.84881118299994
              ],
              [
                -73.89808598699996,
                40.85021312399993
              ],
              [
                -73.89708706499987,
                40.85196827699987
              ],
              [
                -73.89662446799987,
                40.8527875899999
              ],
              [
                -73.8956088329998,
                40.854570369999884
              ],
              [
                -73.89644052599982,
                40.854907758999914
              ],
              [
                -73.89709926799996,
                40.855172290999874
              ],
              [
                -73.89831118599984,
                40.854061150999904
              ],
              [
                -73.89863543699985,
                40.85390697499993
              ],
              [
                -73.89816577499982,
                40.85514637599989
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000520099538161,
        "objectid": 171,
        "shape_leng": 0.107353876244,
        "location_id": 171,
        "zone": "Murray Hill-Queens",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.79317944599987,
                40.77040317699989
              ],
              [
                -73.79323711899993,
                40.77046772099994
              ],
              [
                -73.79394905799994,
                40.7712673749999
              ],
              [
                -73.79415923499985,
                40.77151603799992
              ],
              [
                -73.79480546099995,
                40.7722568749999
              ],
              [
                -73.794936368,
                40.77240452999996
              ],
              [
                -73.79507190299978,
                40.77254212499992
              ],
              [
                -73.79521550699988,
                40.77267490699989
              ],
              [
                -73.79565702099983,
                40.77303608599992
              ],
              [
                -73.79571707399992,
                40.77306964299989
              ],
              [
                -73.79652490499979,
                40.77350327799991
              ],
              [
                -73.79672685099996,
                40.7736092289999
              ],
              [
                -73.79724339799986,
                40.773893896999894
              ],
              [
                -73.79730005599994,
                40.773932285999884
              ],
              [
                -73.79741514499982,
                40.774010264999916
              ],
              [
                -73.79741522800005,
                40.774010318999885
              ],
              [
                -73.79741530999986,
                40.77401038199986
              ],
              [
                -73.79757522899993,
                40.77413684199995
              ],
              [
                -73.79772190099995,
                40.77427237399995
              ],
              [
                -73.79785400499989,
                40.77441566899992
              ],
              [
                -73.79823295499997,
                40.77484830499985
              ],
              [
                -73.79853009399987,
                40.775190728999924
              ],
              [
                -73.7986519199999,
                40.7752005589999
              ],
              [
                -73.80098974099998,
                40.77538911599991
              ],
              [
                -73.80379022899989,
                40.775610111999924
              ],
              [
                -73.80659228499994,
                40.77583186199997
              ],
              [
                -73.8094525209999,
                40.77605807899988
              ],
              [
                -73.812686263,
                40.776312793999914
              ],
              [
                -73.81485850699984,
                40.77648592299988
              ],
              [
                -73.81478821799982,
                40.776995738999865
              ],
              [
                -73.81475882999987,
                40.77720350099992
              ],
              [
                -73.8146630589999,
                40.77790468699987
              ],
              [
                -73.81452997799975,
                40.77888817499994
              ],
              [
                -73.81728146999997,
                40.77910551299991
              ],
              [
                -73.81930985099999,
                40.77926005999989
              ],
              [
                -73.8198104219999,
                40.779296988999874
              ],
              [
                -73.82032141599986,
                40.779334680999916
              ],
              [
                -73.82132278499986,
                40.77940925899987
              ],
              [
                -73.82223999299985,
                40.77949023699989
              ],
              [
                -73.82331286899986,
                40.77957865999988
              ],
              [
                -73.82354681599996,
                40.7790355379999
              ],
              [
                -73.82376244899996,
                40.778504551999895
              ],
              [
                -73.82382108999985,
                40.77836013799991
              ],
              [
                -73.82405384599981,
                40.7777178299999
              ],
              [
                -73.82414480499983,
                40.77747809299996
              ],
              [
                -73.82422147299988,
                40.77723522699992
              ],
              [
                -73.8242835999999,
                40.77698996099984
              ],
              [
                -73.82433101899987,
                40.776743069999924
              ],
              [
                -73.82486800999989,
                40.77654235499993
              ],
              [
                -73.82583089999997,
                40.77591527099988
              ],
              [
                -73.82625348499994,
                40.77452065899989
              ],
              [
                -73.82647720799984,
                40.77311644299987
              ],
              [
                -73.82657481899986,
                40.772345397999885
              ],
              [
                -73.82657511799988,
                40.771630352999935
              ],
              [
                -73.82656578899986,
                40.770641475999895
              ],
              [
                -73.82655841999986,
                40.77013708399991
              ],
              [
                -73.82604927499996,
                40.77021081399988
              ],
              [
                -73.8255648519999,
                40.770282959999925
              ],
              [
                -73.82466908999987,
                40.77040760799985
              ],
              [
                -73.82444330299992,
                40.769285513999904
              ],
              [
                -73.82421202499988,
                40.76816922799988
              ],
              [
                -73.82395032599992,
                40.76688720199996
              ],
              [
                -73.82372658199988,
                40.7658255159999
              ],
              [
                -73.82348245199985,
                40.76465179199993
              ],
              [
                -73.82302513300002,
                40.7636663409999
              ],
              [
                -73.82260068399988,
                40.76275987799987
              ],
              [
                -73.8220716649999,
                40.761625090999885
              ],
              [
                -73.8218107709998,
                40.76107223699989
              ],
              [
                -73.82176735399993,
                40.760979813999924
              ],
              [
                -73.82155809699998,
                40.76053432399992
              ],
              [
                -73.82116404999985,
                40.75968607499994
              ],
              [
                -73.82080684899987,
                40.7589417829999
              ],
              [
                -73.82063593299985,
                40.758872267999905
              ],
              [
                -73.8172882139999,
                40.75977554699992
              ],
              [
                -73.8150873259998,
                40.76037161099987
              ],
              [
                -73.81399882899984,
                40.760665033999906
              ],
              [
                -73.8131322989999,
                40.76089834899995
              ],
              [
                -73.81226778099976,
                40.76113002199986
              ],
              [
                -73.81104963199984,
                40.761417020999964
              ],
              [
                -73.81044348399985,
                40.761377438999894
              ],
              [
                -73.80951372599986,
                40.76129991099989
              ],
              [
                -73.80859484099997,
                40.76113246199993
              ],
              [
                -73.80768023699983,
                40.76095995899988
              ],
              [
                -73.80676048599993,
                40.7607877729999
              ],
              [
                -73.80584005399999,
                40.76061539099988
              ],
              [
                -73.804916685,
                40.760440411999895
              ],
              [
                -73.80399971299993,
                40.76027048799995
              ],
              [
                -73.80307328899983,
                40.760126247999885
              ],
              [
                -73.80215291900004,
                40.75998721799987
              ],
              [
                -73.80125017499996,
                40.75982823699995
              ],
              [
                -73.80030038999979,
                40.759702298999926
              ],
              [
                -73.79940088899988,
                40.759397218999936
              ],
              [
                -73.798501602,
                40.75907103599995
              ],
              [
                -73.79760259799991,
                40.758744178999905
              ],
              [
                -73.79670467899993,
                40.75841771199986
              ],
              [
                -73.79621926899993,
                40.75824167999989
              ],
              [
                -73.79580437699985,
                40.75809121699992
              ],
              [
                -73.79493246199993,
                40.757798029999954
              ],
              [
                -73.79402473999977,
                40.757735968999945
              ],
              [
                -73.79303015799997,
                40.75775619399999
              ],
              [
                -73.79212059099993,
                40.75777758199993
              ],
              [
                -73.79201171599978,
                40.757780149999924
              ],
              [
                -73.79122151599987,
                40.757798765999944
              ],
              [
                -73.79031517,
                40.75782345099988
              ],
              [
                -73.79053450999997,
                40.759373037999886
              ],
              [
                -73.79076004599987,
                40.76098576899991
              ],
              [
                -73.791654662,
                40.76091206299987
              ],
              [
                -73.79255337999997,
                40.760840293999934
              ],
              [
                -73.79343991199988,
                40.76076412799995
              ],
              [
                -73.79344033499987,
                40.76082252699996
              ],
              [
                -73.79344299999985,
                40.76118533699985
              ],
              [
                -73.793438002,
                40.7615135269999
              ],
              [
                -73.79343673799991,
                40.76159683099995
              ],
              [
                -73.79334546300005,
                40.76229534299991
              ],
              [
                -73.79322483899982,
                40.763142007999946
              ],
              [
                -73.79302551399985,
                40.76462119199988
              ],
              [
                -73.7939591469999,
                40.7646953199999
              ],
              [
                -73.79361279599983,
                40.76722335799997
              ],
              [
                -73.793422277,
                40.768599907999906
              ],
              [
                -73.79321182299991,
                40.770336488999874
              ],
              [
                -73.79317944599987,
                40.77040317699989
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000658402501406,
        "objectid": 172,
        "shape_leng": 0.118476116148,
        "location_id": 172,
        "zone": "New Dorp/Midland Beach",
        "borough": "Staten Island"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -74.08654858694766,
                40.56963231913558
              ],
              [
                -74.08595297385914,
                40.57029822710432
              ],
              [
                -74.08495715819193,
                40.57125984002107
              ],
              [
                -74.08597647499992,
                40.572236970999874
              ],
              [
                -74.08765791399992,
                40.57301266499997
              ],
              [
                -74.08769737599994,
                40.57306188399987
              ],
              [
                -74.08775041799993,
                40.57311057999993
              ],
              [
                -74.08886977599995,
                40.573723237999936
              ],
              [
                -74.08960544799997,
                40.57413046999991
              ],
              [
                -74.09032842599996,
                40.57451533599988
              ],
              [
                -74.0910942809999,
                40.57489922899992
              ],
              [
                -74.09182234099991,
                40.57527493299995
              ],
              [
                -74.09257214599995,
                40.57567191299993
              ],
              [
                -74.09335651099995,
                40.576047193999926
              ],
              [
                -74.09408671799999,
                40.57641955099989
              ],
              [
                -74.09483640599994,
                40.57680653799986
              ],
              [
                -74.09517725099997,
                40.57702113899996
              ],
              [
                -74.09613172499989,
                40.57752610599994
              ],
              [
                -74.09741191099992,
                40.57817443199992
              ],
              [
                -74.09851032499989,
                40.5787306869999
              ],
              [
                -74.09940090399988,
                40.57918675599992
              ],
              [
                -74.10039083499996,
                40.579688665999925
              ],
              [
                -74.09974044199997,
                40.58020781799998
              ],
              [
                -74.09908893799992,
                40.58072522899993
              ],
              [
                -74.09844000899997,
                40.581241705999915
              ],
              [
                -74.1006131089999,
                40.58271119099991
              ],
              [
                -74.10249695999993,
                40.58401503299994
              ],
              [
                -74.10265901099994,
                40.584088199999954
              ],
              [
                -74.10278862899995,
                40.58422448699991
              ],
              [
                -74.10525775499997,
                40.585921435999865
              ],
              [
                -74.10588393199993,
                40.58540720699988
              ],
              [
                -74.10649465099986,
                40.58489534299985
              ],
              [
                -74.10729031099994,
                40.584222297999865
              ],
              [
                -74.10790851599995,
                40.583685637999935
              ],
              [
                -74.10924835499999,
                40.58267841299991
              ],
              [
                -74.11028714299995,
                40.58219874799996
              ],
              [
                -74.11127706499995,
                40.58179072899988
              ],
              [
                -74.11220998099996,
                40.581266237999934
              ],
              [
                -74.11306954499995,
                40.58076852199988
              ],
              [
                -74.11367299899999,
                40.580312936999924
              ],
              [
                -74.11394959799999,
                40.58004141599991
              ],
              [
                -74.11422746699996,
                40.579733907999916
              ],
              [
                -74.11481941499994,
                40.579176874999895
              ],
              [
                -74.1154228409999,
                40.57863086699996
              ],
              [
                -74.11551544900001,
                40.57854987899991
              ],
              [
                -74.11562578199988,
                40.57847975299993
              ],
              [
                -74.11575021599991,
                40.5784236329999
              ],
              [
                -74.11588397199995,
                40.578383694999914
              ],
              [
                -74.11602165699998,
                40.578360825999916
              ],
              [
                -74.11622655999997,
                40.57835373999991
              ],
              [
                -74.1164310249999,
                40.5783335949999
              ],
              [
                -74.1166329409999,
                40.57830047599991
              ],
              [
                -74.11683019199994,
                40.57825471799991
              ],
              [
                -74.11702078599996,
                40.578196925999926
              ],
              [
                -74.1175761019999,
                40.57770348399993
              ],
              [
                -74.118033584,
                40.57717040899997
              ],
              [
                -74.11844855699992,
                40.57665868099991
              ],
              [
                -74.11910954899994,
                40.576157251999895
              ],
              [
                -74.11978567499995,
                40.5758155559999
              ],
              [
                -74.12112080299991,
                40.57530684499991
              ],
              [
                -74.1212571389999,
                40.575254893999904
              ],
              [
                -74.1215372899999,
                40.57515325299986
              ],
              [
                -74.12172623899997,
                40.57490698499986
              ],
              [
                -74.12185333599992,
                40.57465318099989
              ],
              [
                -74.1218307979999,
                40.574217088999916
              ],
              [
                -74.1217007549999,
                40.57383202799988
              ],
              [
                -74.12160439199994,
                40.573555919999876
              ],
              [
                -74.12139299299997,
                40.572975547999924
              ],
              [
                -74.12136044699997,
                40.57282674399992
              ],
              [
                -74.12134352099997,
                40.57267573399989
              ],
              [
                -74.12134352099994,
                40.57267566199993
              ],
              [
                -74.12134254799993,
                40.572523870999945
              ],
              [
                -74.12135750799999,
                40.57237280099989
              ],
              [
                -74.12135751999995,
                40.572372710999936
              ],
              [
                -74.1213575439999,
                40.57237261199986
              ],
              [
                -74.12138815399996,
                40.572223667999886
              ],
              [
                -74.12138731299996,
                40.57209221099987
              ],
              [
                -74.12150213699994,
                40.57172155899991
              ],
              [
                -74.12162563999989,
                40.5715013939999
              ],
              [
                -74.12216856599991,
                40.570939492999834
              ],
              [
                -74.12259644899996,
                40.570596432999906
              ],
              [
                -74.1227896719999,
                40.57042984499988
              ],
              [
                -74.12345096199988,
                40.56992276099989
              ],
              [
                -74.12418560399995,
                40.56942453799998
              ],
              [
                -74.12489904399992,
                40.56894914199992
              ],
              [
                -74.12395063699994,
                40.56843071899991
              ],
              [
                -74.12359663199994,
                40.56823998099992
              ],
              [
                -74.12329206199995,
                40.56807586399987
              ],
              [
                -74.1230928109999,
                40.567966365999894
              ],
              [
                -74.12166636299999,
                40.56708845999986
              ],
              [
                -74.12024008999991,
                40.56622746499988
              ],
              [
                -74.11948009699996,
                40.5657795529999
              ],
              [
                -74.11876038899995,
                40.56535522399992
              ],
              [
                -74.11804055399998,
                40.5649216419999
              ],
              [
                -74.11721290299988,
                40.56441776399986
              ],
              [
                -74.11641052699991,
                40.56394351599993
              ],
              [
                -74.11636336399992,
                40.56391563799987
              ],
              [
                -74.11546552899996,
                40.56480595799997
              ],
              [
                -74.11489902599997,
                40.56536698599995
              ],
              [
                -74.11430477299992,
                40.56594740099993
              ],
              [
                -74.11376161199998,
                40.566497188999946
              ],
              [
                -74.11369932899999,
                40.56645974399986
              ],
              [
                -74.11211239799994,
                40.56550555799989
              ],
              [
                -74.11013947199989,
                40.56431066699985
              ],
              [
                -74.10936214499999,
                40.5639089379999
              ],
              [
                -74.108615941,
                40.563528362999925
              ],
              [
                -74.1078679589999,
                40.56314558799996
              ],
              [
                -74.10710400899995,
                40.56276988299987
              ],
              [
                -74.10690692399996,
                40.56266704599994
              ],
              [
                -74.10617236999992,
                40.56229633299994
              ],
              [
                -74.10557645499996,
                40.56198997299986
              ],
              [
                -74.10484627099999,
                40.56159523899992
              ],
              [
                -74.10413152099991,
                40.56117322799984
              ],
              [
                -74.10391630199994,
                40.56102206099994
              ],
              [
                -74.10369512999992,
                40.5608759759999
              ],
              [
                -74.10346821399996,
                40.560735110999865
              ],
              [
                -74.10323576299993,
                40.56059959599989
              ],
              [
                -74.10299799599996,
                40.560469557999944
              ],
              [
                -74.10275513299999,
                40.56034511799994
              ],
              [
                -74.10250740099998,
                40.56022639199989
              ],
              [
                -74.10225503199992,
                40.56011348999988
              ],
              [
                -74.10199826099996,
                40.560006518999955
              ],
              [
                -74.10173732699991,
                40.559905577999885
              ],
              [
                -74.0999291420065,
                40.55907068582615
              ],
              [
                -74.09971784547332,
                40.55914130003113
              ],
              [
                -74.09938031432728,
                40.5589641933612
              ],
              [
                -74.09929593747901,
                40.55900497241414
              ],
              [
                -74.09932770361462,
                40.559097413730214
              ],
              [
                -74.09909547833853,
                40.55925879063089
              ],
              [
                -74.09892581152889,
                40.559350056892846
              ],
              [
                -74.09883262879084,
                40.559387308977776
              ],
              [
                -74.09876415480899,
                40.559423551650866
              ],
              [
                -74.09870617083808,
                40.55942360149753
              ],
              [
                -74.09865870468045,
                40.5594075604186
              ],
              [
                -74.09862204726852,
                40.559387981860255
              ],
              [
                -74.0979317271503,
                40.559041462891436
              ],
              [
                -74.09784815988272,
                40.55903366909171
              ],
              [
                -74.09782127023999,
                40.55905346621769
              ],
              [
                -74.09779665317956,
                40.559087242775234
              ],
              [
                -74.09783230303547,
                40.5591204548634
              ],
              [
                -74.09799902257588,
                40.55918570617138
              ],
              [
                -74.09808686320238,
                40.55920274335817
              ],
              [
                -74.09822154829854,
                40.55928358203834
              ],
              [
                -74.09829030823695,
                40.55933261415681
              ],
              [
                -74.09827265698341,
                40.5595488087949
              ],
              [
                -74.09819767261925,
                40.559836476804655
              ],
              [
                -74.09812034213034,
                40.559892952543535
              ],
              [
                -74.09804431644582,
                40.559913165445806
              ],
              [
                -74.09799348696792,
                40.559967105357885
              ],
              [
                -74.09797864152097,
                40.559993013615724
              ],
              [
                -74.09796392976403,
                40.56003655008663
              ],
              [
                -74.09788807970713,
                40.56016942673052
              ],
              [
                -74.09759824840336,
                40.560612925033304
              ],
              [
                -74.09741969570297,
                40.560883647706234
              ],
              [
                -74.09730732893384,
                40.561015811089995
              ],
              [
                -74.09723420757017,
                40.561119586719194
              ],
              [
                -74.09715495845641,
                40.56118830750991
              ],
              [
                -74.09708844300383,
                40.56125456802853
              ],
              [
                -74.09702483295521,
                40.56133643899699
              ],
              [
                -74.0969819943174,
                40.56147621667032
              ],
              [
                -74.09687036780505,
                40.56171797265268
              ],
              [
                -74.09661362272206,
                40.56208714553732
              ],
              [
                -74.09629202201867,
                40.562420830773256
              ],
              [
                -74.09611847135454,
                40.56258757449008
              ],
              [
                -74.09588687685212,
                40.56272491684932
              ],
              [
                -74.09582321827422,
                40.56272227341536
              ],
              [
                -74.09576141073522,
                40.56273211959321
              ],
              [
                -74.09571191999136,
                40.562750869401256
              ],
              [
                -74.09567198061367,
                40.5627755982454
              ],
              [
                -74.09566331996835,
                40.56281931767249
              ],
              [
                -74.09568304214078,
                40.56291939729115
              ],
              [
                -74.09542371796327,
                40.5631546355686
              ],
              [
                -74.09524145226949,
                40.563148386538714
              ],
              [
                -74.09511063361298,
                40.56317327343459
              ],
              [
                -74.09407071109435,
                40.56273161946121
              ],
              [
                -74.09399643661986,
                40.56280605965053
              ],
              [
                -74.0940496840973,
                40.562981647611124
              ],
              [
                -74.09123807762191,
                40.56650661322813
              ],
              [
                -74.09099905467035,
                40.566737129097476
              ],
              [
                -74.09054960106333,
                40.567083597211294
              ],
              [
                -74.08998259932834,
                40.56738001153308
              ],
              [
                -74.08924906075131,
                40.567015570534714
              ],
              [
                -74.08914045954154,
                40.56713347467469
              ],
              [
                -74.089202951032,
                40.567174036207575
              ],
              [
                -74.08852300985629,
                40.568070329933924
              ],
              [
                -74.08835699781724,
                40.56826670759142
              ],
              [
                -74.0879298186953,
                40.5685932869361
              ],
              [
                -74.08756362440468,
                40.56885044817723
              ],
              [
                -74.08734672272566,
                40.569021891998524
              ],
              [
                -74.08725078316105,
                40.56908167819288
              ],
              [
                -74.08621632224266,
                40.56853269814713
              ],
              [
                -74.0861924436643,
                40.56856462059217
              ],
              [
                -74.08691263653212,
                40.56898584268364
              ],
              [
                -74.08654858694766,
                40.56963231913558
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000178329851357,
        "objectid": 173,
        "shape_leng": 0.0600639661414,
        "location_id": 173,
        "zone": "North Corona",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.85540366099997,
                40.75834030899992
              ],
              [
                -73.85560819899995,
                40.75830541099986
              ],
              [
                -73.85695186799988,
                40.758133126999894
              ],
              [
                -73.85775351199999,
                40.75802727499996
              ],
              [
                -73.85856138999993,
                40.75791243699991
              ],
              [
                -73.85888932400003,
                40.75789947599986
              ],
              [
                -73.85951332399986,
                40.75787858999996
              ],
              [
                -73.85992746199989,
                40.757864101999886
              ],
              [
                -73.86046162299996,
                40.75784373199986
              ],
              [
                -73.86099882399996,
                40.75782499299989
              ],
              [
                -73.8619061719999,
                40.75779486399995
              ],
              [
                -73.86280704199999,
                40.75775898399999
              ],
              [
                -73.86371499900002,
                40.757725670999875
              ],
              [
                -73.86461956599983,
                40.75769371599994
              ],
              [
                -73.86567436999994,
                40.7576295829999
              ],
              [
                -73.86656997600001,
                40.75753552099993
              ],
              [
                -73.86746336500002,
                40.75744153999991
              ],
              [
                -73.86848374899982,
                40.75733493699992
              ],
              [
                -73.86950314799998,
                40.7572280189999
              ],
              [
                -73.87041371399981,
                40.757132411999876
              ],
              [
                -73.87132395899997,
                40.75703633999996
              ],
              [
                -73.87221856399994,
                40.75694322399988
              ],
              [
                -73.87311054799987,
                40.75685088199991
              ],
              [
                -73.87365721799983,
                40.756803485999946
              ],
              [
                -73.87271067399989,
                40.75506352099989
              ],
              [
                -73.87269240699999,
                40.7550299389999
              ],
              [
                -73.87266966999982,
                40.7549867989999
              ],
              [
                -73.87214232699986,
                40.753986047999874
              ],
              [
                -73.87207047299985,
                40.75386198799989
              ],
              [
                -73.87179244199989,
                40.753325668999935
              ],
              [
                -73.87084215599985,
                40.751544692999865
              ],
              [
                -73.86968551,
                40.749612361999894
              ],
              [
                -73.86942458599985,
                40.74915685199993
              ],
              [
                -73.86919188099995,
                40.74876289399987
              ],
              [
                -73.8689139579999,
                40.748368876999955
              ],
              [
                -73.86852925199995,
                40.747588301999954
              ],
              [
                -73.86843750999991,
                40.74703119699994
              ],
              [
                -73.86826502599992,
                40.74631422299994
              ],
              [
                -73.86808574299982,
                40.74560058299989
              ],
              [
                -73.86790963899986,
                40.7449337159999
              ],
              [
                -73.8660885059999,
                40.745473952999944
              ],
              [
                -73.86407236699985,
                40.7460736519999
              ],
              [
                -73.86253100999993,
                40.746533801999895
              ],
              [
                -73.86032027699984,
                40.747195698999896
              ],
              [
                -73.86027336099988,
                40.74710172799993
              ],
              [
                -73.85734765199993,
                40.747971434999904
              ],
              [
                -73.85442575299999,
                40.748851725999884
              ],
              [
                -73.8544701179998,
                40.748939347999936
              ],
              [
                -73.85479286699997,
                40.74956323099989
              ],
              [
                -73.85513928899988,
                40.75022639499992
              ],
              [
                -73.85547890700003,
                40.750891233999866
              ],
              [
                -73.855834956,
                40.7515772719999
              ],
              [
                -73.85455552299993,
                40.75196191899991
              ],
              [
                -73.85497762399996,
                40.75278221699993
              ],
              [
                -73.85538540499991,
                40.753571059999935
              ],
              [
                -73.85579909099987,
                40.75440952799994
              ],
              [
                -73.85478630699987,
                40.75470744999992
              ],
              [
                -73.85383081399995,
                40.75495833799989
              ],
              [
                -73.85323754899999,
                40.75509560099989
              ],
              [
                -73.85312635499994,
                40.7551093429999
              ],
              [
                -73.8531874079998,
                40.75540002899993
              ],
              [
                -73.8533527399998,
                40.756017825999905
              ],
              [
                -73.85339582599983,
                40.75614235899993
              ],
              [
                -73.85354137499989,
                40.75656303299997
              ],
              [
                -73.85377320199977,
                40.75711640099996
              ],
              [
                -73.85379813799992,
                40.757177611999914
              ],
              [
                -73.85405570299993,
                40.75760316299988
              ],
              [
                -73.85436796999998,
                40.758119082999876
              ],
              [
                -73.85449593299987,
                40.75827863899985
              ],
              [
                -73.85462918699993,
                40.75830695499997
              ],
              [
                -73.85476880299996,
                40.7583281299999
              ],
              [
                -73.85490872699997,
                40.758336448999884
              ],
              [
                -73.85505441,
                40.758330370999936
              ],
              [
                -73.85530200199993,
                40.7582928419999
              ],
              [
                -73.85540366099997,
                40.75834030899992
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000155928664422,
        "objectid": 174,
        "shape_leng": 0.0678177559602,
        "location_id": 174,
        "zone": "Norwood",
        "borough": "Bronx"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.87772817699982,
                40.88345419499996
              ],
              [
                -73.87760676600001,
                40.88452304399993
              ],
              [
                -73.8778312589999,
                40.88677275999987
              ],
              [
                -73.87799008999986,
                40.8870503589999
              ],
              [
                -73.87803562399996,
                40.88711832199991
              ],
              [
                -73.878131292,
                40.88691279699989
              ],
              [
                -73.8781494459999,
                40.88687380699995
              ],
              [
                -73.87876041699998,
                40.886040198999865
              ],
              [
                -73.87936283299985,
                40.885218239999915
              ],
              [
                -73.8799579879998,
                40.884390628999896
              ],
              [
                -73.88124422499992,
                40.882709632999905
              ],
              [
                -73.882086237,
                40.8831322209999
              ],
              [
                -73.88274833399998,
                40.88339738699995
              ],
              [
                -73.88319088799989,
                40.88328483399986
              ],
              [
                -73.88334213499988,
                40.88322651799995
              ],
              [
                -73.88334234899999,
                40.88322643699995
              ],
              [
                -73.88334258699989,
                40.88322637399989
              ],
              [
                -73.88350764199998,
                40.883183245999874
              ],
              [
                -73.8835078799999,
                40.883183183999925
              ],
              [
                -73.88350810499995,
                40.88318314699995
              ],
              [
                -73.88368327399996,
                40.88315763299994
              ],
              [
                -73.883683369,
                40.883157614999966
              ],
              [
                -73.88368347599999,
                40.88315761499991
              ],
              [
                -73.88386358099999,
                40.883151393999874
              ],
              [
                -73.8838637479999,
                40.883151384999884
              ],
              [
                -73.88386391399983,
                40.88315139399986
              ],
              [
                -73.88404328600001,
                40.883164973999946
              ],
              [
                -73.88404341599993,
                40.88316498399992
              ],
              [
                -73.88404353399987,
                40.88316500199992
              ],
              [
                -73.88421671500001,
                40.88319766499993
              ],
              [
                -73.88421683399994,
                40.88319769199991
              ],
              [
                -73.88421696399995,
                40.883197727999914
              ],
              [
                -73.88437887899987,
                40.88324759799994
              ],
              [
                -73.88437905799985,
                40.883247650999905
              ],
              [
                -73.88437922299993,
                40.883247722999954
              ],
              [
                -73.88452615199998,
                40.88331214699986
              ],
              [
                -73.88516495799995,
                40.883631611999924
              ],
              [
                -73.88662412999999,
                40.88426730299991
              ],
              [
                -73.88683486699986,
                40.884342025999935
              ],
              [
                -73.88692889699986,
                40.884355714999906
              ],
              [
                -73.88705142599984,
                40.88434957599993
              ],
              [
                -73.88704304099994,
                40.88404023199996
              ],
              [
                -73.88708705799996,
                40.88379702699996
              ],
              [
                -73.88710004499984,
                40.88372994399993
              ],
              [
                -73.88710703399992,
                40.88367398599991
              ],
              [
                -73.88712303599982,
                40.88320093999989
              ],
              [
                -73.88713206599988,
                40.883150944999905
              ],
              [
                -73.88714697699992,
                40.88311793799991
              ],
              [
                -73.88718101599989,
                40.88308394199995
              ],
              [
                -73.8872319409999,
                40.883054977999905
              ],
              [
                -73.88697007500001,
                40.8827956429999
              ],
              [
                -73.88694246899995,
                40.88268276699995
              ],
              [
                -73.88716505299983,
                40.88257651099992
              ],
              [
                -73.88706962799996,
                40.88247689599992
              ],
              [
                -73.88595071699983,
                40.88050741199993
              ],
              [
                -73.88582680399998,
                40.879907896999896
              ],
              [
                -73.88578568400006,
                40.879616873999964
              ],
              [
                -73.88575540599997,
                40.87931311799986
              ],
              [
                -73.88567517999986,
                40.87887251099988
              ],
              [
                -73.88541068099991,
                40.87910168399994
              ],
              [
                -73.88527683199987,
                40.87921766899991
              ],
              [
                -73.88505419400002,
                40.87889287599995
              ],
              [
                -73.88489171499985,
                40.878606016999946
              ],
              [
                -73.88477074799978,
                40.87839502799989
              ],
              [
                -73.8846776199998,
                40.87823547699989
              ],
              [
                -73.88382977799998,
                40.87678284199991
              ],
              [
                -73.88355294399997,
                40.87625222899988
              ],
              [
                -73.88349725699989,
                40.8761567399999
              ],
              [
                -73.88345586399996,
                40.876056256999945
              ],
              [
                -73.88345579299984,
                40.87605609499994
              ],
              [
                -73.88345575699994,
                40.876055933999936
              ],
              [
                -73.88342964999991,
                40.875952169999906
              ],
              [
                -73.88341932399999,
                40.87584701799994
              ],
              [
                -73.88342472699996,
                40.875742665999866
              ],
              [
                -73.88343632799992,
                40.87538431899993
              ],
              [
                -73.88344008999987,
                40.87479468399987
              ],
              [
                -73.883400353,
                40.87394662599996
              ],
              [
                -73.8833499429999,
                40.87375884999992
              ],
              [
                -73.88327982500005,
                40.87357380999988
              ],
              [
                -73.88319052799997,
                40.87339337899994
              ],
              [
                -73.88319048200002,
                40.87339327999999
              ],
              [
                -73.8831904229999,
                40.87339318999993
              ],
              [
                -73.88308277499985,
                40.87321902499988
              ],
              [
                -73.88308273999985,
                40.873218971999904
              ],
              [
                -73.88308270399986,
                40.87321891799995
              ],
              [
                -73.882957783,
                40.87305245199985
              ],
              [
                -73.8828305549999,
                40.872916664999885
              ],
              [
                -73.88283042399992,
                40.87291651999991
              ],
              [
                -73.88283030599987,
                40.8729163669999
              ],
              [
                -73.88271951399993,
                40.87277078099995
              ],
              [
                -73.88262696899983,
                40.87261718899993
              ],
              [
                -73.88262687499989,
                40.872617027999944
              ],
              [
                -73.88262679199994,
                40.872616855999865
              ],
              [
                -73.88255399799999,
                40.87245745799991
              ],
              [
                -73.88255391499996,
                40.872457287999914
              ],
              [
                -73.88255385599989,
                40.87245711599995
              ],
              [
                -73.88250150999994,
                40.87229386699992
              ],
              [
                -73.8825014739999,
                40.872293749999876
              ],
              [
                -73.8825014509999,
                40.872293623999944
              ],
              [
                -73.88246984299988,
                40.87212868499994
              ],
              [
                -73.88222049399987,
                40.87122724799985
              ],
              [
                -73.88197649899989,
                40.87038728399996
              ],
              [
                -73.88174189599988,
                40.8695527139999
              ],
              [
                -73.88146889699983,
                40.86857364599993
              ],
              [
                -73.88188379899985,
                40.86832083599991
              ],
              [
                -73.88284356199999,
                40.86773622599992
              ],
              [
                -73.88362518100003,
                40.86725758799991
              ],
              [
                -73.8830900319999,
                40.86659150899994
              ],
              [
                -73.88135802599994,
                40.86757898699991
              ],
              [
                -73.87991019699996,
                40.86836304099992
              ],
              [
                -73.87772752999979,
                40.86955533799994
              ],
              [
                -73.87694351999987,
                40.86995468199993
              ],
              [
                -73.87619224999979,
                40.87047671099987
              ],
              [
                -73.87518802799977,
                40.87157682799995
              ],
              [
                -73.87436750799998,
                40.872701008999876
              ],
              [
                -73.87255250899982,
                40.87517518299991
              ],
              [
                -73.87160577400002,
                40.876921927999916
              ],
              [
                -73.87094367500002,
                40.87851076699998
              ],
              [
                -73.87024100099985,
                40.880229352999955
              ],
              [
                -73.87084077099992,
                40.880438893999965
              ],
              [
                -73.87187100700007,
                40.880328119999966
              ],
              [
                -73.87191803300003,
                40.88023746599988
              ],
              [
                -73.87307334600001,
                40.88013438499995
              ],
              [
                -73.87406751299994,
                40.880038905999896
              ],
              [
                -73.87494589799991,
                40.87994766899988
              ],
              [
                -73.87479240699987,
                40.880075128999884
              ],
              [
                -73.87467601499999,
                40.88156285199994
              ],
              [
                -73.87533699399987,
                40.88167183199992
              ],
              [
                -73.87625890099994,
                40.88183203999991
              ],
              [
                -73.87717005999981,
                40.88199391299993
              ],
              [
                -73.87812594399992,
                40.8821538259999
              ],
              [
                -73.87772817699982,
                40.88345419499996
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000504920726134,
        "objectid": 175,
        "shape_leng": 0.134897665102,
        "location_id": 175,
        "zone": "Oakland Gardens",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.75625606399988,
                40.758430468999904
              ],
              [
                -73.75682062199996,
                40.75973480599985
              ],
              [
                -73.75800131799996,
                40.76037986699988
              ],
              [
                -73.7598774599999,
                40.75999492299992
              ],
              [
                -73.76031788499999,
                40.7599045509999
              ],
              [
                -73.76125540599999,
                40.75971470799991
              ],
              [
                -73.76239510599996,
                40.759496997999854
              ],
              [
                -73.7619974599999,
                40.75810219099993
              ],
              [
                -73.76176692899985,
                40.75727880199986
              ],
              [
                -73.76185572299985,
                40.756516974999926
              ],
              [
                -73.76192713800003,
                40.75577285299988
              ],
              [
                -73.76189611099981,
                40.755654002999904
              ],
              [
                -73.76184343999986,
                40.75553726899991
              ],
              [
                -73.76176970599987,
                40.755426020999884
              ],
              [
                -73.76167669899993,
                40.75532347699988
              ],
              [
                -73.7615672369999,
                40.75523235199987
              ],
              [
                -73.76144497499985,
                40.755154656999885
              ],
              [
                -73.76128938199999,
                40.75505221499991
              ],
              [
                -73.76114556499998,
                40.7549389489999
              ],
              [
                -73.76101539999992,
                40.75481609199988
              ],
              [
                -73.76090047,
                40.75468513599987
              ],
              [
                -73.76080194199993,
                40.754547729999956
              ],
              [
                -73.76048623699985,
                40.75383961699987
              ],
              [
                -73.76018801899988,
                40.75316049899986
              ],
              [
                -73.76013367999985,
                40.75302838899988
              ],
              [
                -73.76013364499983,
                40.75302828899989
              ],
              [
                -73.76013358599995,
                40.75302819999992
              ],
              [
                -73.76005811999997,
                40.75289976499995
              ],
              [
                -73.76005800199988,
                40.752899574999894
              ],
              [
                -73.76005787299981,
                40.752899403999926
              ],
              [
                -73.75996192099981,
                40.752777446999865
              ],
              [
                -73.7599618029999,
                40.75277730199993
              ],
              [
                -73.75996168499987,
                40.75277718499993
              ],
              [
                -73.75984680199994,
                40.75266435499989
              ],
              [
                -73.75984667299988,
                40.75266422799993
              ],
              [
                -73.7598465069999,
                40.75266410199988
              ],
              [
                -73.75971521799991,
                40.75256288999989
              ],
              [
                -73.75971508799988,
                40.7525627919999
              ],
              [
                -73.75971494599995,
                40.75256269999991
              ],
              [
                -73.759570322,
                40.75247497699996
              ],
              [
                -73.75957016899991,
                40.75247487799994
              ],
              [
                -73.75957000299998,
                40.75247479599991
              ],
              [
                -73.75948272499986,
                40.7524338129999
              ],
              [
                -73.75910049199996,
                40.752253820999954
              ],
              [
                -73.75782746199997,
                40.750758269999906
              ],
              [
                -73.75737714099989,
                40.75022810199991
              ],
              [
                -73.75698991500002,
                40.74957500999989
              ],
              [
                -73.75686552299992,
                40.74936148999998
              ],
              [
                -73.75684127399992,
                40.74932207799992
              ],
              [
                -73.75714080500002,
                40.749237258999884
              ],
              [
                -73.75744314899997,
                40.74915673299992
              ],
              [
                -73.75773936999988,
                40.7490634429999
              ],
              [
                -73.75802805599993,
                40.74895781599986
              ],
              [
                -73.75805782299985,
                40.74894194899989
              ],
              [
                -73.75845857499995,
                40.748763092999916
              ],
              [
                -73.75885932599981,
                40.748584227999906
              ],
              [
                -73.75972553599988,
                40.748188660999894
              ],
              [
                -73.76073612099987,
                40.747737384999944
              ],
              [
                -73.76138355799993,
                40.747529170999904
              ],
              [
                -73.76145571899991,
                40.747507951999864
              ],
              [
                -73.76151846699999,
                40.74749054799987
              ],
              [
                -73.76240045799992,
                40.7472432539999
              ],
              [
                -73.76297777799992,
                40.74708162199994
              ],
              [
                -73.76414324400004,
                40.74676404599995
              ],
              [
                -73.76501623199981,
                40.74652288199991
              ],
              [
                -73.7658920239999,
                40.74628062699991
              ],
              [
                -73.76635520899988,
                40.74615249699984
              ],
              [
                -73.76681551899995,
                40.7460300569999
              ],
              [
                -73.76729011899992,
                40.74590381399993
              ],
              [
                -73.76772991399994,
                40.74577622599993
              ],
              [
                -73.76770614599985,
                40.74572274199989
              ],
              [
                -73.76761507999991,
                40.745526354999924
              ],
              [
                -73.76697863,
                40.74422296799989
              ],
              [
                -73.76687853599992,
                40.744093578999895
              ],
              [
                -73.7667631489999,
                40.74397036199991
              ],
              [
                -73.76663352600005,
                40.74385485299991
              ],
              [
                -73.76649109699986,
                40.74374842999987
              ],
              [
                -73.76633756199995,
                40.74365225899988
              ],
              [
                -73.76617484799982,
                40.743567271999886
              ],
              [
                -73.76556849799985,
                40.74325815499988
              ],
              [
                -73.76515128899993,
                40.74243734599987
              ],
              [
                -73.76431495599994,
                40.740672626999896
              ],
              [
                -73.76360860199983,
                40.73917476499987
              ],
              [
                -73.763168165,
                40.73826170799991
              ],
              [
                -73.76268297199981,
                40.73725583599987
              ],
              [
                -73.7625969479999,
                40.73708732599991
              ],
              [
                -73.76253086199996,
                40.73691167999987
              ],
              [
                -73.76248609299982,
                40.73673098199989
              ],
              [
                -73.76246348399997,
                40.73654750099993
              ],
              [
                -73.76246334599998,
                40.73636362499995
              ],
              [
                -73.76248538699991,
                40.73618169499989
              ],
              [
                -73.76252875800002,
                40.73600396099992
              ],
              [
                -73.76273303499985,
                40.735262162999945
              ],
              [
                -73.76311240499989,
                40.73516391399995
              ],
              [
                -73.76318167899983,
                40.73514494799989
              ],
              [
                -73.76388970599994,
                40.73492637999993
              ],
              [
                -73.7642906499999,
                40.734802606999885
              ],
              [
                -73.76503749400004,
                40.73457204399989
              ],
              [
                -73.76510920699994,
                40.734556584999936
              ],
              [
                -73.7650327579999,
                40.734466408999886
              ],
              [
                -73.76470929199999,
                40.73408485499987
              ],
              [
                -73.76374010499987,
                40.73291287299987
              ],
              [
                -73.76369979199988,
                40.73286411699993
              ],
              [
                -73.76434014999984,
                40.73275295499994
              ],
              [
                -73.76491667699993,
                40.732654672999935
              ],
              [
                -73.76555086099985,
                40.732544282999875
              ],
              [
                -73.76564374500005,
                40.73253312699996
              ],
              [
                -73.76558856199988,
                40.732467520999876
              ],
              [
                -73.76528830399985,
                40.732114663999916
              ],
              [
                -73.76486853099996,
                40.731668639999945
              ],
              [
                -73.76435071499999,
                40.731151191999885
              ],
              [
                -73.76381866799998,
                40.73068357399991
              ],
              [
                -73.76327537799995,
                40.730240540999915
              ],
              [
                -73.76234209799992,
                40.729538290999876
              ],
              [
                -73.76160814399992,
                40.72904753399989
              ],
              [
                -73.76114083699993,
                40.728794413999886
              ],
              [
                -73.76024211099985,
                40.72830761199987
              ],
              [
                -73.75993818299986,
                40.72815841199991
              ],
              [
                -73.75831675599993,
                40.72736240699991
              ],
              [
                -73.75787141499994,
                40.7271068509999
              ],
              [
                -73.75684854799977,
                40.726355811999944
              ],
              [
                -73.75678272799996,
                40.726305783999855
              ],
              [
                -73.75674739599988,
                40.72627289499993
              ],
              [
                -73.75669771299987,
                40.7262287109999
              ],
              [
                -73.75663658099985,
                40.72624641099991
              ],
              [
                -73.75655253399987,
                40.72627075399994
              ],
              [
                -73.75612598799987,
                40.72643025999991
              ],
              [
                -73.75527584399988,
                40.72671544099993
              ],
              [
                -73.75442222499994,
                40.72704214799993
              ],
              [
                -73.75406469299989,
                40.72720104599991
              ],
              [
                -73.75380709300003,
                40.72731553199991
              ],
              [
                -73.75363109999996,
                40.72739374799988
              ],
              [
                -73.75297028799989,
                40.727705619999874
              ],
              [
                -73.75259471000005,
                40.7279089859999
              ],
              [
                -73.75221914099994,
                40.728112343999925
              ],
              [
                -73.75184238299993,
                40.728326501999895
              ],
              [
                -73.75106753999994,
                40.72881769799993
              ],
              [
                -73.75081066899993,
                40.72899503899988
              ],
              [
                -73.75048295699995,
                40.72922129399997
              ],
              [
                -73.74980987499995,
                40.72974674999989
              ],
              [
                -73.74917188599981,
                40.7302990689999
              ],
              [
                -73.7479342389999,
                40.73140119599985
              ],
              [
                -73.74749692899994,
                40.73179105099995
              ],
              [
                -73.74733126699995,
                40.73193874099995
              ],
              [
                -73.74704531499992,
                40.73219365299987
              ],
              [
                -73.74672140400004,
                40.73247715099991
              ],
              [
                -73.7461625949999,
                40.73292721999995
              ],
              [
                -73.74555368099982,
                40.733404317999906
              ],
              [
                -73.74472417799997,
                40.734011029999905
              ],
              [
                -73.742947859,
                40.73545655199991
              ],
              [
                -73.74228431199992,
                40.73608640799994
              ],
              [
                -73.74158944499997,
                40.736782008999946
              ],
              [
                -73.74151636899985,
                40.73686036099988
              ],
              [
                -73.741446287,
                40.73693874699991
              ],
              [
                -73.74016951799993,
                40.738481897999876
              ],
              [
                -73.74029081599987,
                40.738516669999996
              ],
              [
                -73.74055585499997,
                40.7385871829999
              ],
              [
                -73.740773448,
                40.7386352729999
              ],
              [
                -73.7408821769999,
                40.738696239999925
              ],
              [
                -73.74100612499988,
                40.73874389499987
              ],
              [
                -73.74100625499993,
                40.73874393099989
              ],
              [
                -73.74100638499996,
                40.73874396799991
              ],
              [
                -73.74114115799996,
                40.73877533799986
              ],
              [
                -73.74128113899988,
                40.73878869199998
              ],
              [
                -73.74142000699983,
                40.738783807999916
              ],
              [
                -73.74155202599987,
                40.73876204099989
              ],
              [
                -73.74222678199988,
                40.73861567199989
              ],
              [
                -73.74306820799991,
                40.73844081799991
              ],
              [
                -73.74346956799998,
                40.738870103999915
              ],
              [
                -73.74360280299992,
                40.73901260899993
              ],
              [
                -73.74371941299982,
                40.73938314999984
              ],
              [
                -73.74385264199996,
                40.74009394899984
              ],
              [
                -73.74396193599989,
                40.74078912699985
              ],
              [
                -73.74657796099996,
                40.74034659199985
              ],
              [
                -73.74996804299997,
                40.73974575199988
              ],
              [
                -73.75009150899994,
                40.73981690799993
              ],
              [
                -73.75009170899982,
                40.739817026999944
              ],
              [
                -73.75009192199988,
                40.73981711699987
              ],
              [
                -73.7502278629999,
                40.73987649399994
              ],
              [
                -73.75022795699984,
                40.7398765299999
              ],
              [
                -73.75037344499992,
                40.73992242099994
              ],
              [
                -73.75037358699991,
                40.73992246699987
              ],
              [
                -73.75037372900003,
                40.7399224929999
              ],
              [
                -73.75052495299994,
                40.739953708999934
              ],
              [
                -73.75052516599997,
                40.73995375399993
              ],
              [
                -73.75052540299986,
                40.739953781999894
              ],
              [
                -73.75067885099985,
                40.73996996399992
              ],
              [
                -73.75083158999992,
                40.739988898999904
              ],
              [
                -73.75083176700001,
                40.739988916999906
              ],
              [
                -73.75083193299997,
                40.739988952999965
              ],
              [
                -73.75098218299992,
                40.74002143499993
              ],
              [
                -73.75098230099987,
                40.74002146199997
              ],
              [
                -73.75098241899991,
                40.740021497999884
              ],
              [
                -73.75112706799989,
                40.74006711699994
              ],
              [
                -73.7511273519999,
                40.74006720699986
              ],
              [
                -73.75112759899997,
                40.740067314999884
              ],
              [
                -73.75126376099992,
                40.740125142999936
              ],
              [
                -73.75126399699991,
                40.74012524299995
              ],
              [
                -73.75126422199995,
                40.740125368999884
              ],
              [
                -73.75138967499991,
                40.74019415999989
              ],
              [
                -73.75347299999997,
                40.74240484399992
              ],
              [
                -73.75253331799986,
                40.74261133299993
              ],
              [
                -73.75163327499985,
                40.74280675999993
              ],
              [
                -73.75072790300001,
                40.74299784699989
              ],
              [
                -73.74979834499989,
                40.74314244299993
              ],
              [
                -73.74887697299984,
                40.74328837399992
              ],
              [
                -73.74784861300004,
                40.743296077999936
              ],
              [
                -73.74671975299987,
                40.743123647999944
              ],
              [
                -73.74567621100002,
                40.74302729899991
              ],
              [
                -73.74477587099986,
                40.74328109699994
              ],
              [
                -73.74593060399998,
                40.74571565899988
              ],
              [
                -73.74625984399985,
                40.74671147499993
              ],
              [
                -73.74384322099985,
                40.74769009599988
              ],
              [
                -73.74409668799984,
                40.74820664299995
              ],
              [
                -73.74507911699986,
                40.75027389699994
              ],
              [
                -73.74577948799998,
                40.75156080499994
              ],
              [
                -73.74621665699989,
                40.75168584999995
              ],
              [
                -73.74637489399997,
                40.75176036499988
              ],
              [
                -73.74652681399978,
                40.75184264599989
              ],
              [
                -73.74667146099986,
                40.75193218699994
              ],
              [
                -73.74680794599988,
                40.75202841699993
              ],
              [
                -73.74684219699999,
                40.752058075999884
              ],
              [
                -73.74687468099984,
                40.75208864699992
              ],
              [
                -73.7470988539999,
                40.752346828999904
              ],
              [
                -73.74737515000001,
                40.752959619999935
              ],
              [
                -73.74760569999998,
                40.753507876999905
              ],
              [
                -73.74774116499992,
                40.75384994399991
              ],
              [
                -73.74711113899991,
                40.754173285999926
              ],
              [
                -73.74809587899985,
                40.75589954999988
              ],
              [
                -73.75004764499985,
                40.758404657999975
              ],
              [
                -73.75057710699984,
                40.75801489499991
              ],
              [
                -73.75115582999986,
                40.75906976799995
              ],
              [
                -73.75230259399993,
                40.75945519899993
              ],
              [
                -73.752771716,
                40.75887302799991
              ],
              [
                -73.75320997199995,
                40.75824487299992
              ],
              [
                -73.75335958499983,
                40.75832324899994
              ],
              [
                -73.75352058499998,
                40.75839033099988
              ],
              [
                -73.75369069100002,
                40.75844478799994
              ],
              [
                -73.7538672559999,
                40.75848565299992
              ],
              [
                -73.75404744899984,
                40.75851238599994
              ],
              [
                -73.75422838200002,
                40.75852489199992
              ],
              [
                -73.75497607999992,
                40.75850155999996
              ],
              [
                -73.75511648399994,
                40.75847949799994
              ],
              [
                -73.75525632799999,
                40.75844214599992
              ],
              [
                -73.75539137399987,
                40.758389247999915
              ],
              [
                -73.75551718999988,
                40.7583214729999
              ],
              [
                -73.7555734369999,
                40.75828095199988
              ],
              [
                -73.75583713299987,
                40.757742061999934
              ],
              [
                -73.75625606399988,
                40.758430468999904
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000183893478476,
        "objectid": 179,
        "shape_leng": 0.0713808644377,
        "location_id": 179,
        "zone": "Old Astoria",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.93290347544752,
                40.777944498577085
              ],
              [
                -73.93390840247848,
                40.778117129818426
              ],
              [
                -73.93441179004596,
                40.778092545767635
              ],
              [
                -73.93474353011273,
                40.77807633537696
              ],
              [
                -73.93508232492144,
                40.77794423372777
              ],
              [
                -73.93550938863625,
                40.77753910328637
              ],
              [
                -73.93585452641493,
                40.77721168462422
              ],
              [
                -73.93608919699105,
                40.77718432207706
              ],
              [
                -73.9363802281754,
                40.77692145656271
              ],
              [
                -73.93703012839919,
                40.775893204998034
              ],
              [
                -73.93704702153673,
                40.775866487041355
              ],
              [
                -73.93765483090819,
                40.775085685383594
              ],
              [
                -73.93785811822775,
                40.77408756897306
              ],
              [
                -73.93781335969511,
                40.77356289464787
              ],
              [
                -73.93746638537067,
                40.772531495125165
              ],
              [
                -73.93605335028684,
                40.772105801626445
              ],
              [
                -73.9351221671339,
                40.771825258333344
              ],
              [
                -73.93480681344163,
                40.771734138889876
              ],
              [
                -73.93480858897101,
                40.77169958689862
              ],
              [
                -73.93482622761688,
                40.77157695807326
              ],
              [
                -73.93443525743162,
                40.7714623623334
              ],
              [
                -73.93448015089042,
                40.771323068200275
              ],
              [
                -73.93484282697513,
                40.77139579319229
              ],
              [
                -73.93483715299811,
                40.77142013530973
              ],
              [
                -73.9349294331986,
                40.7714402360901
              ],
              [
                -73.93495398714983,
                40.771372943016836
              ],
              [
                -73.93487018163421,
                40.77135427855316
              ],
              [
                -73.93485790974897,
                40.77138291296916
              ],
              [
                -73.9344854830391,
                40.77130652321843
              ],
              [
                -73.93452730104705,
                40.77117677505168
              ],
              [
                -73.93424609600005,
                40.77102927599988
              ],
              [
                -73.93447449099996,
                40.770411596999956
              ],
              [
                -73.93458521699995,
                40.770217420999906
              ],
              [
                -73.93471718499994,
                40.77002985799988
              ],
              [
                -73.93486912400003,
                40.76985103099991
              ],
              [
                -73.93486925499987,
                40.769850877999886
              ],
              [
                -73.934869397,
                40.76985073499986
              ],
              [
                -73.9350398979999,
                40.76968230199987
              ],
              [
                -73.93522718199998,
                40.76952572999988
              ],
              [
                -73.93577134099989,
                40.769050009999866
              ],
              [
                -73.935820709,
                40.76863091499995
              ],
              [
                -73.93593177099983,
                40.7677837029999
              ],
              [
                -73.93618379699991,
                40.767691800999934
              ],
              [
                -73.93594942700004,
                40.767578225999976
              ],
              [
                -73.93493511099992,
                40.76701302399987
              ],
              [
                -73.93422865800001,
                40.76785468299989
              ],
              [
                -73.93344468499993,
                40.768817789999915
              ],
              [
                -73.93202371699994,
                40.7682564959999
              ],
              [
                -73.9298564789999,
                40.767326551999865
              ],
              [
                -73.92855636599988,
                40.76671423899995
              ],
              [
                -73.92643792699984,
                40.765732814999936
              ],
              [
                -73.92506062099999,
                40.76509731499993
              ],
              [
                -73.92425288799978,
                40.76472456399996
              ],
              [
                -73.92353865299998,
                40.765603497999905
              ],
              [
                -73.92218946199995,
                40.76726735699988
              ],
              [
                -73.92139066099993,
                40.76689410499988
              ],
              [
                -73.92058727399994,
                40.76652069899995
              ],
              [
                -73.91977764799998,
                40.766152183999914
              ],
              [
                -73.918906013,
                40.76722677599987
              ],
              [
                -73.91849336999996,
                40.76773465699996
              ],
              [
                -73.91911161599978,
                40.76802545099991
              ],
              [
                -73.91995596899991,
                40.768440064999865
              ],
              [
                -73.91828990899992,
                40.76987856799984
              ],
              [
                -73.91811686499992,
                40.77002848599989
              ],
              [
                -73.91770217299991,
                40.7703931119999
              ],
              [
                -73.91815346699994,
                40.77070124299994
              ],
              [
                -73.91870527799998,
                40.77107888199988
              ],
              [
                -73.91880312499995,
                40.77114938899994
              ],
              [
                -73.919421785,
                40.77159517499992
              ],
              [
                -73.920076088,
                40.77206690899993
              ],
              [
                -73.92076339599988,
                40.77255254799996
              ],
              [
                -73.92146878499983,
                40.773051306999925
              ],
              [
                -73.92216697499995,
                40.77354687799987
              ],
              [
                -73.92279264499986,
                40.773962294999905
              ],
              [
                -73.92398142900001,
                40.77461511299994
              ],
              [
                -73.92523246199983,
                40.775298853999885
              ],
              [
                -73.92531479799992,
                40.77534385199994
              ],
              [
                -73.92602208199996,
                40.77573039899992
              ],
              [
                -73.92658081899984,
                40.776039258999916
              ],
              [
                -73.92737641899997,
                40.776466611999886
              ],
              [
                -73.92782147400004,
                40.77669752699993
              ],
              [
                -73.92827730816353,
                40.77689897516218
              ],
              [
                -73.92839561141334,
                40.77679052956769
              ],
              [
                -73.92860515023345,
                40.776592675100964
              ],
              [
                -73.92903572143476,
                40.77646741705894
              ],
              [
                -73.92988058500097,
                40.77622163190188
              ],
              [
                -73.9306285655417,
                40.7765909027496
              ],
              [
                -73.93088304263134,
                40.776973706679804
              ],
              [
                -73.93107377146733,
                40.777260594398136
              ],
              [
                -73.9310496429894,
                40.77744189731199
              ],
              [
                -73.93178834305208,
                40.77787060660231
              ],
              [
                -73.93184646077484,
                40.77788082503059
              ],
              [
                -73.93264931173371,
                40.77802197423286
              ],
              [
                -73.9327221045395,
                40.778034767117944
              ],
              [
                -73.9327412296532,
                40.777993950317615
              ],
              [
                -73.93277477867667,
                40.777922388777874
              ],
              [
                -73.93290347544752,
                40.777944498577085
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000657782126267,
        "objectid": 176,
        "shape_leng": 0.151995190703,
        "location_id": 176,
        "zone": "Oakwood",
        "borough": "Staten Island"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -74.12187198599999,
                40.57510757499989
              ],
              [
                -74.12198554099993,
                40.57511463099989
              ],
              [
                -74.12198569499998,
                40.57511464099986
              ],
              [
                -74.12198585999995,
                40.57511466699986
              ],
              [
                -74.12209602099993,
                40.57513258699987
              ],
              [
                -74.12264375399991,
                40.57548301999992
              ],
              [
                -74.12276057999999,
                40.57556662599996
              ],
              [
                -74.122760698,
                40.57556670699991
              ],
              [
                -74.12276083999994,
                40.5755667879999
              ],
              [
                -74.12289120599996,
                40.57564049999989
              ],
              [
                -74.12289127699994,
                40.57564054599994
              ],
              [
                -74.12289135999994,
                40.57564058099991
              ],
              [
                -74.12303304199995,
                40.57570264699994
              ],
              [
                -74.12303319599995,
                40.57570271899991
              ],
              [
                -74.12303336099994,
                40.575702772999875
              ],
              [
                -74.12318352299998,
                40.57575177099994
              ],
              [
                -74.12318366499987,
                40.57575181599987
              ],
              [
                -74.1231838069999,
                40.57575185199992
              ],
              [
                -74.12333936499992,
                40.57578694099992
              ],
              [
                -74.12333955299995,
                40.57578698599992
              ],
              [
                -74.12333972999994,
                40.575787011999914
              ],
              [
                -74.12349756499998,
                40.57580793399989
              ],
              [
                -74.12408417799992,
                40.575834019999924
              ],
              [
                -74.12513333899997,
                40.57587998799992
              ],
              [
                -74.12563664099993,
                40.575973443999935
              ],
              [
                -74.12665688899989,
                40.576284932999926
              ],
              [
                -74.12708814799998,
                40.57636910599995
              ],
              [
                -74.12714220399988,
                40.57617076799989
              ],
              [
                -74.1271422509999,
                40.57617061599995
              ],
              [
                -74.12714229799994,
                40.576170480999906
              ],
              [
                -74.12721289299992,
                40.57597456599993
              ],
              [
                -74.12721297499998,
                40.57597434899988
              ],
              [
                -74.12721306899995,
                40.57597415099987
              ],
              [
                -74.12730002599994,
                40.575781539999916
              ],
              [
                -74.12730004999996,
                40.57578149499989
              ],
              [
                -74.12730007399989,
                40.575781449999965
              ],
              [
                -74.12740288899992,
                40.575593395999896
              ],
              [
                -74.12740297099995,
                40.5755932429999
              ],
              [
                -74.1274030649999,
                40.57559308999994
              ],
              [
                -74.12752099599994,
                40.57541088199988
              ],
              [
                -74.12752110199995,
                40.575410709999936
              ],
              [
                -74.1275212319999,
                40.575410538999954
              ],
              [
                -74.12765358299998,
                40.575234942999884
              ],
              [
                -74.12784711499992,
                40.575167575999906
              ],
              [
                -74.12814797599995,
                40.575056538999924
              ],
              [
                -74.12914464399998,
                40.57473005399987
              ],
              [
                -74.12930379600002,
                40.57467541099988
              ],
              [
                -74.13094048799995,
                40.57410601099989
              ],
              [
                -74.13194427599997,
                40.57373938299985
              ],
              [
                -74.13227350299991,
                40.5736285019999
              ],
              [
                -74.13261022999991,
                40.573530162999894
              ],
              [
                -74.13295322899991,
                40.57344477499989
              ],
              [
                -74.1333012009999,
                40.57337268099994
              ],
              [
                -74.13378794099991,
                40.573296974999934
              ],
              [
                -74.13461855699988,
                40.573197712999914
              ],
              [
                -74.1357919339999,
                40.573051949999844
              ],
              [
                -74.13635343199988,
                40.57299130999997
              ],
              [
                -74.13704258199989,
                40.57290845199989
              ],
              [
                -74.13813873699995,
                40.572779642999926
              ],
              [
                -74.13846597099997,
                40.57273988699994
              ],
              [
                -74.1402400869999,
                40.57252737199989
              ],
              [
                -74.14110631299992,
                40.57242255299991
              ],
              [
                -74.14184764599999,
                40.57233261399994
              ],
              [
                -74.14418588199999,
                40.572037409999886
              ],
              [
                -74.1440387979999,
                40.5712994959999
              ],
              [
                -74.14387453599991,
                40.570606593999905
              ],
              [
                -74.14402153199993,
                40.570330119999916
              ],
              [
                -74.14438744999988,
                40.56970609999989
              ],
              [
                -74.1428731119999,
                40.56908188399994
              ],
              [
                -74.1415196949999,
                40.56852030799994
              ],
              [
                -74.1413123359999,
                40.568433347999914
              ],
              [
                -74.14026824299997,
                40.56795018899986
              ],
              [
                -74.13976413499992,
                40.56771224499989
              ],
              [
                -74.13889993799994,
                40.567293035999946
              ],
              [
                -74.13768350999999,
                40.566717866999966
              ],
              [
                -74.13651149199997,
                40.566166621999876
              ],
              [
                -74.13622008499992,
                40.56598659899989
              ],
              [
                -74.1359159259999,
                40.565817959999926
              ],
              [
                -74.13560020799989,
                40.565661454999926
              ],
              [
                -74.13527417299991,
                40.565517760999896
              ],
              [
                -74.13493919999993,
                40.56538743599988
              ],
              [
                -74.13459671299997,
                40.5652709339999
              ],
              [
                -74.13329816299988,
                40.56497397399989
              ],
              [
                -74.13240280499994,
                40.56476099999992
              ],
              [
                -74.13170009,
                40.56453574899992
              ],
              [
                -74.13264383999997,
                40.563503334999915
              ],
              [
                -74.1331097929999,
                40.56286769099993
              ],
              [
                -74.13340740099986,
                40.5621298059999
              ],
              [
                -74.13367116299993,
                40.5618409339999
              ],
              [
                -74.13396833899998,
                40.561586992999885
              ],
              [
                -74.13480973399993,
                40.56082861899988
              ],
              [
                -74.13519379599992,
                40.55974841799983
              ],
              [
                -74.13523859399992,
                40.55958551299993
              ],
              [
                -74.13530363499989,
                40.5594250889999
              ],
              [
                -74.13538846199995,
                40.559269341999915
              ],
              [
                -74.13549208699995,
                40.559120344999876
              ],
              [
                -74.13561300299993,
                40.55898002699994
              ],
              [
                -74.13574932399997,
                40.55885004499987
              ],
              [
                -74.13660029399993,
                40.55838893899992
              ],
              [
                -74.13739437899987,
                40.558020936999874
              ],
              [
                -74.13818000999993,
                40.55759386599989
              ],
              [
                -74.13885092699991,
                40.5569511789999
              ],
              [
                -74.13769985100001,
                40.556027345999944
              ],
              [
                -74.13754790999995,
                40.555906577999956
              ],
              [
                -74.137431255,
                40.55581758299994
              ],
              [
                -74.13609776299991,
                40.55472110499991
              ],
              [
                -74.13498726199998,
                40.55381088399986
              ],
              [
                -74.13376761399991,
                40.55281510299994
              ],
              [
                -74.13321002499998,
                40.552383875999936
              ],
              [
                -74.13316825899992,
                40.55235617899992
              ],
              [
                -74.13177828099997,
                40.553489970999884
              ],
              [
                -74.13108665199998,
                40.5539737589999
              ],
              [
                -74.13042459599988,
                40.55443704199992
              ],
              [
                -74.12975602299994,
                40.555032624999974
              ],
              [
                -74.12896141599992,
                40.555769871999864
              ],
              [
                -74.12811697199996,
                40.556534435999886
              ],
              [
                -74.12730057799996,
                40.557295171999954
              ],
              [
                -74.1272522789999,
                40.55726549099991
              ],
              [
                -74.12572675099993,
                40.55632805699986
              ],
              [
                -74.12402047099992,
                40.555230827999914
              ],
              [
                -74.12380031599992,
                40.55508465799989
              ],
              [
                -74.12269895799986,
                40.554358501999936
              ],
              [
                -74.12076288199995,
                40.55308194999988
              ],
              [
                -74.1194585929999,
                40.5522219059999
              ],
              [
                -74.11852157499992,
                40.55143427699991
              ],
              [
                -74.11876291999992,
                40.55121611399988
              ],
              [
                -74.11813477599993,
                40.550592692999906
              ],
              [
                -74.11656290099995,
                40.54912774199988
              ],
              [
                -74.11495831999993,
                40.54958143899991
              ],
              [
                -74.11444772299996,
                40.549169449999944
              ],
              [
                -74.11395149899992,
                40.548725322999935
              ],
              [
                -74.11338340608125,
                40.54807337507532
              ],
              [
                -74.11301046102231,
                40.547780065215015
              ],
              [
                -74.11291619901675,
                40.54781765186015
              ],
              [
                -74.11278846979026,
                40.54788426261344
              ],
              [
                -74.1116674256761,
                40.54951517842227
              ],
              [
                -74.11120326201794,
                40.55019042824327
              ],
              [
                -74.11097678212174,
                40.55051237768512
              ],
              [
                -74.11028457387536,
                40.55149635741752
              ],
              [
                -74.10984807430184,
                40.551945284580626
              ],
              [
                -74.1094704941342,
                40.5523569644331
              ],
              [
                -74.10924543154377,
                40.55257472917352
              ],
              [
                -74.10924476841656,
                40.55257537043909
              ],
              [
                -74.10909619942147,
                40.55271912189415
              ],
              [
                -74.10853922462532,
                40.55325802567331
              ],
              [
                -74.10768623464052,
                40.553976671477535
              ],
              [
                -74.10734831283652,
                40.55414815203118
              ],
              [
                -74.10704738449654,
                40.55419545154716
              ],
              [
                -74.10667681964419,
                40.55418786113204
              ],
              [
                -74.1066323714532,
                40.5541863945881
              ],
              [
                -74.10658884341498,
                40.55417937697288
              ],
              [
                -74.10654741892462,
                40.554166999074
              ],
              [
                -74.1065092241863,
                40.55414959740878
              ],
              [
                -74.10647529759494,
                40.554127645075255
              ],
              [
                -74.1064465615053,
                40.55410173888954
              ],
              [
                -74.10642952644133,
                40.554073022618624
              ],
              [
                -74.10641868352172,
                40.55404260790871
              ],
              [
                -74.10641430769086,
                40.55401126601117
              ],
              [
                -74.10641650990111,
                40.55397979168804
              ],
              [
                -74.10642523429996,
                40.553948983057964
              ],
              [
                -74.10643031530263,
                40.55390124353809
              ],
              [
                -74.10642822065164,
                40.553853373621216
              ],
              [
                -74.1060364393664,
                40.55364056738116
              ],
              [
                -74.10509470498073,
                40.55312902839251
              ],
              [
                -74.10491463759158,
                40.5530162671125
              ],
              [
                -74.10484447636338,
                40.55302581455626
              ],
              [
                -74.10486249561575,
                40.55308001800673
              ],
              [
                -74.10508907947455,
                40.55323546801066
              ],
              [
                -74.10465943491393,
                40.55412046731117
              ],
              [
                -74.10385795454353,
                40.55511816010134
              ],
              [
                -74.10309839111532,
                40.555904641451136
              ],
              [
                -74.10265076360245,
                40.55613440478522
              ],
              [
                -74.1014744063506,
                40.55553361099763
              ],
              [
                -74.10140516910823,
                40.555538526164405
              ],
              [
                -74.10135423270516,
                40.555583299943464
              ],
              [
                -74.10134593796643,
                40.55562567725728
              ],
              [
                -74.10137477595708,
                40.555692372106726
              ],
              [
                -74.10184032964895,
                40.55594463924128
              ],
              [
                -74.10184637674693,
                40.55621698878
              ],
              [
                -74.10160202986725,
                40.55680345871943
              ],
              [
                -74.10124354139867,
                40.55766083131552
              ],
              [
                -74.10079963928099,
                40.55832182111589
              ],
              [
                -74.1002122584932,
                40.558871151128976
              ],
              [
                -74.0999291420065,
                40.55907068582615
              ],
              [
                -74.10173732699991,
                40.559905577999885
              ],
              [
                -74.10199826099996,
                40.560006518999955
              ],
              [
                -74.10225503199992,
                40.56011348999988
              ],
              [
                -74.10250740099998,
                40.56022639199989
              ],
              [
                -74.10275513299999,
                40.56034511799994
              ],
              [
                -74.10299799599996,
                40.560469557999944
              ],
              [
                -74.10323576299993,
                40.56059959599989
              ],
              [
                -74.10346821399996,
                40.560735110999865
              ],
              [
                -74.10369512999992,
                40.5608759759999
              ],
              [
                -74.10391630199994,
                40.56102206099994
              ],
              [
                -74.10413152099991,
                40.56117322799984
              ],
              [
                -74.10484627099999,
                40.56159523899992
              ],
              [
                -74.10557645499996,
                40.56198997299986
              ],
              [
                -74.10617236999992,
                40.56229633299994
              ],
              [
                -74.10690692399996,
                40.56266704599994
              ],
              [
                -74.10710400899995,
                40.56276988299987
              ],
              [
                -74.1078679589999,
                40.56314558799996
              ],
              [
                -74.108615941,
                40.563528362999925
              ],
              [
                -74.10936214499999,
                40.5639089379999
              ],
              [
                -74.11013947199989,
                40.56431066699985
              ],
              [
                -74.11211239799994,
                40.56550555799989
              ],
              [
                -74.11369932899999,
                40.56645974399986
              ],
              [
                -74.11376161199998,
                40.566497188999946
              ],
              [
                -74.11430477299992,
                40.56594740099993
              ],
              [
                -74.11489902599997,
                40.56536698599995
              ],
              [
                -74.11546552899996,
                40.56480595799997
              ],
              [
                -74.11636336299996,
                40.56391563799986
              ],
              [
                -74.11641052699991,
                40.56394351599993
              ],
              [
                -74.11721290299988,
                40.56441776399986
              ],
              [
                -74.11804055399998,
                40.5649216419999
              ],
              [
                -74.11876038799987,
                40.565355223999894
              ],
              [
                -74.11948009699996,
                40.5657795529999
              ],
              [
                -74.12024009,
                40.56622746599986
              ],
              [
                -74.12166636299999,
                40.56708845999986
              ],
              [
                -74.1230928109999,
                40.567966365999894
              ],
              [
                -74.12329206199995,
                40.56807586399987
              ],
              [
                -74.12359663199994,
                40.56823998099992
              ],
              [
                -74.12395063699994,
                40.56843071899991
              ],
              [
                -74.12489904399992,
                40.56894914199992
              ],
              [
                -74.12418560399995,
                40.56942453799998
              ],
              [
                -74.12345096199988,
                40.56992276099989
              ],
              [
                -74.12278967299989,
                40.57042984499992
              ],
              [
                -74.12259644899996,
                40.570596432999906
              ],
              [
                -74.12216856599991,
                40.570939492999834
              ],
              [
                -74.12162563999989,
                40.5715013939999
              ],
              [
                -74.12150213699994,
                40.57172155899991
              ],
              [
                -74.12138731299996,
                40.57209221099987
              ],
              [
                -74.12138815399996,
                40.572223667999886
              ],
              [
                -74.1213575439999,
                40.57237261199986
              ],
              [
                -74.12135751999995,
                40.572372710999936
              ],
              [
                -74.12135750799999,
                40.57237280099989
              ],
              [
                -74.12134254799993,
                40.572523870999945
              ],
              [
                -74.12134352099994,
                40.57267566199993
              ],
              [
                -74.12134352099997,
                40.57267573399989
              ],
              [
                -74.12136044699997,
                40.57282674399992
              ],
              [
                -74.12139299299997,
                40.572975547999924
              ],
              [
                -74.12160439199994,
                40.573555919999876
              ],
              [
                -74.1217007549999,
                40.57383202799988
              ],
              [
                -74.1218307979999,
                40.574217088999916
              ],
              [
                -74.12185333599992,
                40.57465318099989
              ],
              [
                -74.12172623899997,
                40.57490698499986
              ],
              [
                -74.1215372899999,
                40.57515325299986
              ],
              [
                -74.12164470299997,
                40.57512727699992
              ],
              [
                -74.12164482099995,
                40.57512724899992
              ],
              [
                -74.121644926,
                40.57512723099996
              ],
              [
                -74.12175709399996,
                40.57511182099994
              ],
              [
                -74.12175724699989,
                40.57511180299991
              ],
              [
                -74.12175740099993,
                40.57511179399995
              ],
              [
                -74.12187169099991,
                40.57510757499995
              ],
              [
                -74.12187183299987,
                40.57510756699992
              ],
              [
                -74.12187198599999,
                40.57510757499989
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000198894762248,
        "objectid": 177,
        "shape_leng": 0.0715805407056,
        "location_id": 177,
        "zone": "Ocean Hill",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.90287079599995,
                40.6786314149999
              ],
              [
                -73.9031631289999,
                40.6788149779999
              ],
              [
                -73.90324287499999,
                40.67886505099997
              ],
              [
                -73.90333666299999,
                40.67892394299992
              ],
              [
                -73.90333723699995,
                40.678924302999874
              ],
              [
                -73.903630796,
                40.679046955999915
              ],
              [
                -73.90400588299991,
                40.6792036709999
              ],
              [
                -73.90402950599994,
                40.67921353999993
              ],
              [
                -73.90404639799989,
                40.679220597999866
              ],
              [
                -73.90456891299982,
                40.67951553699992
              ],
              [
                -73.90479113399992,
                40.67964096499992
              ],
              [
                -73.90541416499981,
                40.67999264299987
              ],
              [
                -73.90549303499996,
                40.680037157999934
              ],
              [
                -73.905548368,
                40.68007160199991
              ],
              [
                -73.90565934199981,
                40.68014069099989
              ],
              [
                -73.90628287099989,
                40.68050675599993
              ],
              [
                -73.90671941199996,
                40.68076742199987
              ],
              [
                -73.90701820299994,
                40.680948279999896
              ],
              [
                -73.90777158799989,
                40.68137784699997
              ],
              [
                -73.90851642300001,
                40.681803942999906
              ],
              [
                -73.90886025599995,
                40.681994035999914
              ],
              [
                -73.90905526999994,
                40.682105574999895
              ],
              [
                -73.90927462899988,
                40.68223432399993
              ],
              [
                -73.91002460999994,
                40.68265737099987
              ],
              [
                -73.91076954399978,
                40.68309270099994
              ],
              [
                -73.911511929,
                40.683504989999896
              ],
              [
                -73.91227301199991,
                40.68393496499991
              ],
              [
                -73.91303721399987,
                40.684367809999884
              ],
              [
                -73.91330728099987,
                40.684520857999914
              ],
              [
                -73.91379700199988,
                40.684799871999864
              ],
              [
                -73.91453972399987,
                40.68522691799994
              ],
              [
                -73.91470083399992,
                40.685316944999876
              ],
              [
                -73.91528963499992,
                40.68564553599988
              ],
              [
                -73.91550033999997,
                40.68576152399992
              ],
              [
                -73.91604599299995,
                40.68607206899986
              ],
              [
                -73.9166103199999,
                40.68639135299993
              ],
              [
                -73.916798329,
                40.68649719899989
              ],
              [
                -73.91755226099991,
                40.68693022299989
              ],
              [
                -73.91768113899987,
                40.68700409499997
              ],
              [
                -73.91804607,
                40.68721324799989
              ],
              [
                -73.91799580199995,
                40.686965380999894
              ],
              [
                -73.917915484,
                40.68656593599996
              ],
              [
                -73.91789200399997,
                40.68644915899997
              ],
              [
                -73.91784937499982,
                40.68623316699994
              ],
              [
                -73.91783531199984,
                40.686163668999875
              ],
              [
                -73.91778426799988,
                40.6859114119999
              ],
              [
                -73.91776547599996,
                40.68581854099989
              ],
              [
                -73.91770109499986,
                40.68550035899995
              ],
              [
                -73.91765638699994,
                40.68527188299991
              ],
              [
                -73.91764776599992,
                40.68522782299993
              ],
              [
                -73.91763742499995,
                40.685174975999885
              ],
              [
                -73.91762630599987,
                40.685118148999884
              ],
              [
                -73.91755763299994,
                40.68476719199989
              ],
              [
                -73.91741294299997,
                40.68403405199995
              ],
              [
                -73.9172663269999,
                40.68330322399991
              ],
              [
                -73.91712138499985,
                40.682568471999964
              ],
              [
                -73.9169763279999,
                40.68183415899995
              ],
              [
                -73.91683011499998,
                40.68110098099987
              ],
              [
                -73.91668638399985,
                40.680371378999965
              ],
              [
                -73.916539987,
                40.67963749699988
              ],
              [
                -73.91639300100002,
                40.678905848999904
              ],
              [
                -73.9163011989999,
                40.678577111999914
              ],
              [
                -73.91720270699984,
                40.678626814999916
              ],
              [
                -73.91796830599993,
                40.67866902199989
              ],
              [
                -73.91905083899984,
                40.678728473999875
              ],
              [
                -73.92164666399992,
                40.67886992299992
              ],
              [
                -73.92171159899995,
                40.67811571399989
              ],
              [
                -73.92181538999996,
                40.67701838999991
              ],
              [
                -73.92182360999988,
                40.676938357999894
              ],
              [
                -73.92183232600001,
                40.676849041999894
              ],
              [
                -73.92190090500002,
                40.676131620999904
              ],
              [
                -73.92197495299997,
                40.675348546999935
              ],
              [
                -73.92204843299987,
                40.67457439699991
              ],
              [
                -73.92213153399979,
                40.673683888999946
              ],
              [
                -73.92221444299993,
                40.67279163299992
              ],
              [
                -73.922299504,
                40.67190026199993
              ],
              [
                -73.92238401799992,
                40.67100876299986
              ],
              [
                -73.92245355399993,
                40.67026114899993
              ],
              [
                -73.92246696799994,
                40.6701169299999
              ],
              [
                -73.92254432199985,
                40.66927581099986
              ],
              [
                -73.92261354299985,
                40.66856410699991
              ],
              [
                -73.92263210799995,
                40.66837321099994
              ],
              [
                -73.92265529999987,
                40.66812711499991
              ],
              [
                -73.9227271419999,
                40.66736505599987
              ],
              [
                -73.92275257199998,
                40.66709733299991
              ],
              [
                -73.92221564099988,
                40.6673334459999
              ],
              [
                -73.92187171199996,
                40.667480689999906
              ],
              [
                -73.9210198879999,
                40.66785269799992
              ],
              [
                -73.92014864299996,
                40.668229483999916
              ],
              [
                -73.92011464299999,
                40.668376001999924
              ],
              [
                -73.91999873699986,
                40.668409483999916
              ],
              [
                -73.91923062999996,
                40.668631364999925
              ],
              [
                -73.91838191199984,
                40.669002246999874
              ],
              [
                -73.917482661,
                40.6693922309999
              ],
              [
                -73.9171358899999,
                40.66954376399992
              ],
              [
                -73.91658459999987,
                40.66978754899991
              ],
              [
                -73.91652296199993,
                40.66981426399985
              ],
              [
                -73.9164716349998,
                40.669836508999886
              ],
              [
                -73.91586213199994,
                40.670100668999964
              ],
              [
                -73.91580361899986,
                40.670126027999906
              ],
              [
                -73.9157287449999,
                40.670158477999955
              ],
              [
                -73.91558467299986,
                40.67022136299989
              ],
              [
                -73.91553422600005,
                40.67024338399993
              ],
              [
                -73.91487246299994,
                40.6705322279999
              ],
              [
                -73.91461857800005,
                40.670643237999904
              ],
              [
                -73.91454485599989,
                40.67067547299996
              ],
              [
                -73.91403857199998,
                40.67089683999989
              ],
              [
                -73.91308574699985,
                40.67131022599989
              ],
              [
                -73.91221776899997,
                40.671687162999895
              ],
              [
                -73.91133533699998,
                40.672076452999875
              ],
              [
                -73.9111599549999,
                40.67215535099995
              ],
              [
                -73.91109867399982,
                40.67218291999989
              ],
              [
                -73.91104631099985,
                40.672204641999954
              ],
              [
                -73.910463053,
                40.67244660199987
              ],
              [
                -73.91037473899996,
                40.6724832379999
              ],
              [
                -73.91019287099996,
                40.672558684999906
              ],
              [
                -73.91014265099993,
                40.6725795189999
              ],
              [
                -73.90995832899995,
                40.67265598099992
              ],
              [
                -73.90989833499988,
                40.67268086899987
              ],
              [
                -73.90954171999984,
                40.672828802999966
              ],
              [
                -73.90925438099987,
                40.67294799799986
              ],
              [
                -73.90841931599985,
                40.6733133329999
              ],
              [
                -73.90832954199989,
                40.673355302999894
              ],
              [
                -73.90827800999982,
                40.67337939399994
              ],
              [
                -73.90787711599997,
                40.6735668089999
              ],
              [
                -73.90746842999984,
                40.673761613999915
              ],
              [
                -73.9069565549999,
                40.67398525899991
              ],
              [
                -73.90629662099994,
                40.67426533299991
              ],
              [
                -73.90609438799999,
                40.67435293199991
              ],
              [
                -73.90582626799984,
                40.6744696539999
              ],
              [
                -73.90524012899992,
                40.67472494599988
              ],
              [
                -73.90450912299991,
                40.67505211099989
              ],
              [
                -73.90438792800002,
                40.67520674699994
              ],
              [
                -73.90428786799986,
                40.67522935599987
              ],
              [
                -73.90374374599986,
                40.67540683399991
              ],
              [
                -73.90347422300006,
                40.67550668299987
              ],
              [
                -73.90313803899994,
                40.67562221499988
              ],
              [
                -73.90302076399996,
                40.67565648099992
              ],
              [
                -73.90291887299995,
                40.675684580999864
              ],
              [
                -73.90279745999987,
                40.675757492999864
              ],
              [
                -73.90267831799984,
                40.6757897289999
              ],
              [
                -73.90257716399984,
                40.67583170599995
              ],
              [
                -73.90245583099987,
                40.675882049999935
              ],
              [
                -73.90096234799994,
                40.67650221199989
              ],
              [
                -73.90079183699987,
                40.67657245799992
              ],
              [
                -73.90004626199993,
                40.67689308999995
              ],
              [
                -73.90014864499996,
                40.67694931599987
              ],
              [
                -73.9005649959999,
                40.6771779849999
              ],
              [
                -73.90099766899989,
                40.67745246799996
              ],
              [
                -73.90136992499991,
                40.6776886199999
              ],
              [
                -73.90140356899998,
                40.67770996299991
              ],
              [
                -73.90142169599987,
                40.67772146199992
              ],
              [
                -73.90144574099996,
                40.67773656199995
              ],
              [
                -73.90187352500004,
                40.67800519199989
              ],
              [
                -73.90198538099985,
                40.678075431999936
              ],
              [
                -73.90199214699999,
                40.678079679999925
              ],
              [
                -73.90236198299992,
                40.67831191499987
              ],
              [
                -73.90239451999992,
                40.67833234699991
              ],
              [
                -73.9023946,
                40.67833239799992
              ],
              [
                -73.90287079599995,
                40.6786314149999
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000247682193353,
        "objectid": 180,
        "shape_leng": 0.0805277671523,
        "location_id": 180,
        "zone": "Ozone Park",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.8411960569998,
                40.683351365999904
              ],
              [
                -73.84134091999981,
                40.68331050299987
              ],
              [
                -73.84151391999985,
                40.6832616809999
              ],
              [
                -73.84237029099975,
                40.68301842199991
              ],
              [
                -73.84323090399981,
                40.682775196999884
              ],
              [
                -73.84408914999996,
                40.68252929899994
              ],
              [
                -73.84451063800005,
                40.68241508799991
              ],
              [
                -73.84496190700004,
                40.682307454999936
              ],
              [
                -73.84505837899984,
                40.682284450999866
              ],
              [
                -73.84513349899997,
                40.68226653299996
              ],
              [
                -73.84520200299993,
                40.682250667999895
              ],
              [
                -73.84529042399988,
                40.68223044499991
              ],
              [
                -73.84583237399981,
                40.68210644999987
              ],
              [
                -73.84671464299996,
                40.68185849299988
              ],
              [
                -73.84757845099998,
                40.68157986799986
              ],
              [
                -73.84786817599985,
                40.681486831999926
              ],
              [
                -73.84863383999999,
                40.68168402799994
              ],
              [
                -73.84921620599982,
                40.68184421099987
              ],
              [
                -73.8497640749999,
                40.681986613999925
              ],
              [
                -73.85060478199983,
                40.68219378299992
              ],
              [
                -73.85087162799985,
                40.68225954199994
              ],
              [
                -73.85197864599989,
                40.68256421099987
              ],
              [
                -73.851639288,
                40.681886271999936
              ],
              [
                -73.85131773799995,
                40.681240015999926
              ],
              [
                -73.85099089299987,
                40.68056836699988
              ],
              [
                -73.85168205499998,
                40.68036797699996
              ],
              [
                -73.85223417099995,
                40.68020789099992
              ],
              [
                -73.85267726099994,
                40.68007941499991
              ],
              [
                -73.85392389199987,
                40.67968615599988
              ],
              [
                -73.85439330799991,
                40.67966011399989
              ],
              [
                -73.85653410299985,
                40.67952772499996
              ],
              [
                -73.85683137000001,
                40.6795093379999
              ],
              [
                -73.85721078599984,
                40.67948586399992
              ],
              [
                -73.85779268399989,
                40.67945571699988
              ],
              [
                -73.85819836099995,
                40.67943084899987
              ],
              [
                -73.85878576899987,
                40.68063046299989
              ],
              [
                -73.85929512400006,
                40.68048636599984
              ],
              [
                -73.85966271799995,
                40.68038237199996
              ],
              [
                -73.85977462599983,
                40.6803507119999
              ],
              [
                -73.86053300099985,
                40.68013444799992
              ],
              [
                -73.86066198699996,
                40.68009753799986
              ],
              [
                -73.861423295,
                40.67987881299996
              ],
              [
                -73.86156047099999,
                40.679839967999875
              ],
              [
                -73.86229626199984,
                40.67963157999993
              ],
              [
                -73.86334943599995,
                40.6793516399999
              ],
              [
                -73.86330964200002,
                40.679167969999874
              ],
              [
                -73.86328640800004,
                40.679077196999856
              ],
              [
                -73.86296470699996,
                40.67911281699992
              ],
              [
                -73.86234580499983,
                40.67916478599995
              ],
              [
                -73.86225943099993,
                40.67881678599989
              ],
              [
                -73.86202843099984,
                40.677886113999875
              ],
              [
                -73.86171238699991,
                40.676610595999854
              ],
              [
                -73.86139775899983,
                40.67533658999988
              ],
              [
                -73.86131863599985,
                40.6750212689999
              ],
              [
                -73.86029565599982,
                40.67477189399992
              ],
              [
                -73.85959262199998,
                40.674556409999894
              ],
              [
                -73.85928317999998,
                40.67445271599995
              ],
              [
                -73.85825153699983,
                40.67413561099991
              ],
              [
                -73.85711349199988,
                40.67357505499993
              ],
              [
                -73.85648739299987,
                40.67337677399991
              ],
              [
                -73.85283803899996,
                40.6722791889999
              ],
              [
                -73.85163460999999,
                40.67193033299986
              ],
              [
                -73.850964537,
                40.67171951099988
              ],
              [
                -73.84847765299996,
                40.67091673899989
              ],
              [
                -73.84787000899989,
                40.67077949999991
              ],
              [
                -73.8474137819998,
                40.67062999499992
              ],
              [
                -73.84593839099992,
                40.67017897699987
              ],
              [
                -73.84556670099984,
                40.67008464499985
              ],
              [
                -73.84322150199992,
                40.66934041099991
              ],
              [
                -73.84305613800001,
                40.66930593799987
              ],
              [
                -73.84288589399995,
                40.66928507599987
              ],
              [
                -73.84271367699986,
                40.66927829299993
              ],
              [
                -73.84258049099998,
                40.66928053299988
              ],
              [
                -73.84243585399989,
                40.66928855699992
              ],
              [
                -73.84229542199994,
                40.66881391799992
              ],
              [
                -73.84227237899998,
                40.66873604599993
              ],
              [
                -73.84215802199992,
                40.66870144799986
              ],
              [
                -73.84117399199987,
                40.66850744299989
              ],
              [
                -73.84013623800001,
                40.66826802499993
              ],
              [
                -73.84008991099994,
                40.66843655599992
              ],
              [
                -73.83927850999994,
                40.668201848999935
              ],
              [
                -73.83834358199996,
                40.66798902599986
              ],
              [
                -73.83744908699992,
                40.667798349999885
              ],
              [
                -73.83730072099985,
                40.667707358999955
              ],
              [
                -73.83667829499991,
                40.66758649199992
              ],
              [
                -73.83570108799995,
                40.66726022199987
              ],
              [
                -73.83528941499992,
                40.667235978999955
              ],
              [
                -73.83487654300001,
                40.6672116649999
              ],
              [
                -73.83464965599987,
                40.667115307999936
              ],
              [
                -73.83334819299995,
                40.66677318699994
              ],
              [
                -73.83527457099994,
                40.670800402999916
              ],
              [
                -73.83600752799984,
                40.67226955899987
              ],
              [
                -73.83641423899984,
                40.673115588999956
              ],
              [
                -73.83680894799993,
                40.673961116999884
              ],
              [
                -73.83713760399998,
                40.67466513399991
              ],
              [
                -73.83749571700004,
                40.67540682399994
              ],
              [
                -73.83784372299996,
                40.67607812399992
              ],
              [
                -73.83811946699984,
                40.67662988899985
              ],
              [
                -73.83794250999986,
                40.676794208999866
              ],
              [
                -73.8395206529999,
                40.679961868999946
              ],
              [
                -73.84012856099982,
                40.6811466509999
              ],
              [
                -73.8411960569998,
                40.683351365999904
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000306890292807,
        "objectid": 181,
        "shape_leng": 0.0895372399547,
        "location_id": 181,
        "zone": "Park Slope",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.97583132299987,
                40.665815079999895
              ],
              [
                -73.97533714599997,
                40.666419095999906
              ],
              [
                -73.97483327599997,
                40.66702209799993
              ],
              [
                -73.97433093599994,
                40.667621234999956
              ],
              [
                -73.97382528499993,
                40.668223087999884
              ],
              [
                -73.97332284299985,
                40.66882492299992
              ],
              [
                -73.97281990699993,
                40.669427177999864
              ],
              [
                -73.97231720899988,
                40.67002909799988
              ],
              [
                -73.97181594699985,
                40.670629474999906
              ],
              [
                -73.9713252639998,
                40.67124822399993
              ],
              [
                -73.97085440699995,
                40.67186436499992
              ],
              [
                -73.9704128949999,
                40.672593722999906
              ],
              [
                -73.97000244600004,
                40.67263114999985
              ],
              [
                -73.96966394100005,
                40.67264236699988
              ],
              [
                -73.96927327499998,
                40.67279742699989
              ],
              [
                -73.96892332199981,
                40.6729375529999
              ],
              [
                -73.96889649099982,
                40.67298628299993
              ],
              [
                -73.96884578399981,
                40.67309921099997
              ],
              [
                -73.96880301499984,
                40.673214027999904
              ],
              [
                -73.96876830399998,
                40.67333040999996
              ],
              [
                -73.968741747,
                40.673448029999896
              ],
              [
                -73.96872342099994,
                40.67356655699992
              ],
              [
                -73.96871337599998,
                40.673685655999876
              ],
              [
                -73.96871164100001,
                40.67380499399991
              ],
              [
                -73.96871822199984,
                40.67392423399988
              ],
              [
                -73.96874205,
                40.67408712199992
              ],
              [
                -73.96878240699996,
                40.67424884999986
              ],
              [
                -73.96883910899994,
                40.67440796699988
              ],
              [
                -73.96891162400006,
                40.67456305499991
              ],
              [
                -73.96899918499997,
                40.67471277599989
              ],
              [
                -73.9690773179999,
                40.674839670999916
              ],
              [
                -73.96907735399994,
                40.67483971599988
              ],
              [
                -73.96907739,
                40.67483976099989
              ],
              [
                -73.96917229799983,
                40.67496136499995
              ],
              [
                -73.96917233299985,
                40.67496140999985
              ],
              [
                -73.96917236899992,
                40.67496145599997
              ],
              [
                -73.96928310699995,
                40.67507596699993
              ],
              [
                -73.96940813699987,
                40.675181621999876
              ],
              [
                -73.96940821999982,
                40.675181675999944
              ],
              [
                -73.96954556799999,
                40.67527696899988
              ],
              [
                -73.96954563799987,
                40.675277004999906
              ],
              [
                -73.96969317499985,
                40.67536085399989
              ],
              [
                -73.9694973889999,
                40.675906379999965
              ],
              [
                -73.97188596799982,
                40.676394652999925
              ],
              [
                -73.973686958,
                40.67707800199988
              ],
              [
                -73.97618051399984,
                40.67806432099995
              ],
              [
                -73.97574929499986,
                40.678699705999925
              ],
              [
                -73.97532192699995,
                40.67933277599991
              ],
              [
                -73.97495737999982,
                40.679877112999925
              ],
              [
                -73.97490473799986,
                40.67995413099987
              ],
              [
                -73.97469118399995,
                40.68026661599992
              ],
              [
                -73.97450395899989,
                40.68073320299993
              ],
              [
                -73.97439144199983,
                40.68104616999989
              ],
              [
                -73.97422671399997,
                40.68150463899984
              ],
              [
                -73.97395135399996,
                40.68228072999996
              ],
              [
                -73.973845731,
                40.682586386999915
              ],
              [
                -73.97374011799997,
                40.68289203199989
              ],
              [
                -73.9737559849999,
                40.68303401999989
              ],
              [
                -73.97483191299989,
                40.68323523699998
              ],
              [
                -73.97595299799993,
                40.68347469799989
              ],
              [
                -73.97624898299983,
                40.68355587899987
              ],
              [
                -73.97653928699988,
                40.68364905099987
              ],
              [
                -73.97676303199997,
                40.683731781999946
              ],
              [
                -73.97682267999997,
                40.683753837999866
              ],
              [
                -73.97709796699996,
                40.683869779999895
              ],
              [
                -73.97745722099987,
                40.68405228699987
              ],
              [
                -73.97804289599988,
                40.68485661499989
              ],
              [
                -73.97838862599984,
                40.68442334199988
              ],
              [
                -73.97842970699993,
                40.684355612999866
              ],
              [
                -73.97876022499986,
                40.68376544399991
              ],
              [
                -73.97918390599997,
                40.68312883799993
              ],
              [
                -73.97961592499983,
                40.682493556999916
              ],
              [
                -73.98003510099997,
                40.68185262699988
              ],
              [
                -73.98046155400003,
                40.681222546999926
              ],
              [
                -73.98088932299994,
                40.6805933489999
              ],
              [
                -73.98132413699994,
                40.67995210099992
              ],
              [
                -73.98175642299985,
                40.67931462399988
              ],
              [
                -73.98218939700001,
                40.67867580399994
              ],
              [
                -73.98261725399998,
                40.67804242999993
              ],
              [
                -73.98304811899982,
                40.67740668599986
              ],
              [
                -73.98347361499994,
                40.67677916099998
              ],
              [
                -73.98390048699991,
                40.67614865299993
              ],
              [
                -73.98420294599991,
                40.67577119999986
              ],
              [
                -73.9846987299998,
                40.67517562999993
              ],
              [
                -73.98520256000002,
                40.67457380999993
              ],
              [
                -73.98570017199982,
                40.673974832999875
              ],
              [
                -73.98622500899995,
                40.67334636499988
              ],
              [
                -73.98670905399989,
                40.672766732999904
              ],
              [
                -73.98721100499988,
                40.67216061599988
              ],
              [
                -73.98744496399998,
                40.67188008599991
              ],
              [
                -73.98771575999984,
                40.67155538099996
              ],
              [
                -73.98821210799997,
                40.67096081399996
              ],
              [
                -73.988713226,
                40.67035690699995
              ],
              [
                -73.9890700769999,
                40.669929483999915
              ],
              [
                -73.98907079799996,
                40.66992861999994
              ],
              [
                -73.98921685399992,
                40.66975347499993
              ],
              [
                -73.98972203499986,
                40.66914780699988
              ],
              [
                -73.99022252199988,
                40.66854809799991
              ],
              [
                -73.99072558999995,
                40.6679461729999
              ],
              [
                -73.99123399599989,
                40.6673356529999
              ],
              [
                -73.99173365199998,
                40.666736594999875
              ],
              [
                -73.9922346229999,
                40.66613762499993
              ],
              [
                -73.99277080699981,
                40.665507280999876
              ],
              [
                -73.99284786,
                40.66555439199989
              ],
              [
                -73.99490350199991,
                40.66678543499988
              ],
              [
                -73.99494918099985,
                40.66670164299991
              ],
              [
                -73.99496779899978,
                40.66666374099989
              ],
              [
                -73.99498642699994,
                40.66662582999986
              ],
              [
                -73.99502872600004,
                40.666515284999925
              ],
              [
                -73.9951247889999,
                40.6663813549999
              ],
              [
                -73.99530800399985,
                40.66615799899995
              ],
              [
                -73.995759574,
                40.66578388799989
              ],
              [
                -73.9958739449999,
                40.66573228499993
              ],
              [
                -73.99595866299985,
                40.66570454899987
              ],
              [
                -73.995810253,
                40.66553098899993
              ],
              [
                -73.99583369399984,
                40.665507261999885
              ],
              [
                -73.99593232999982,
                40.66540742499992
              ],
              [
                -73.99605311599986,
                40.665285165999876
              ],
              [
                -73.9963724839999,
                40.664961908999885
              ],
              [
                -73.99692689899985,
                40.66437491499994
              ],
              [
                -73.99521061899985,
                40.66332127999993
              ],
              [
                -73.99510907599998,
                40.66326211199989
              ],
              [
                -73.99502082499981,
                40.66320906699988
              ],
              [
                -73.99283830599987,
                40.66190848099995
              ],
              [
                -73.99156751399993,
                40.66114158699991
              ],
              [
                -73.9906354539999,
                40.66057908799997
              ],
              [
                -73.99005216500004,
                40.661138647999934
              ],
              [
                -73.98947000099999,
                40.661699040999956
              ],
              [
                -73.98888159799985,
                40.66224717499995
              ],
              [
                -73.9887680069999,
                40.66235596399985
              ],
              [
                -73.988716511,
                40.66240493799996
              ],
              [
                -73.98804399099996,
                40.6614754359999
              ],
              [
                -73.98729619199992,
                40.660441852999895
              ],
              [
                -73.98725068999991,
                40.660378955999875
              ],
              [
                -73.98713178899986,
                40.66037436799995
              ],
              [
                -73.98688464499989,
                40.6603663889999
              ],
              [
                -73.9868065409999,
                40.66024190199991
              ],
              [
                -73.98671251899984,
                40.6601232029999
              ],
              [
                -73.98660379800002,
                40.66001202199991
              ],
              [
                -73.98648200899994,
                40.65990987999993
              ],
              [
                -73.98508679399998,
                40.65901328799989
              ],
              [
                -73.98448024099987,
                40.659598336999935
              ],
              [
                -73.98391792699988,
                40.660137487999876
              ],
              [
                -73.98323712099993,
                40.66093622099991
              ],
              [
                -73.98273252399984,
                40.66153948399988
              ],
              [
                -73.98223034199995,
                40.66214149299991
              ],
              [
                -73.98017161099993,
                40.66115465799992
              ],
              [
                -73.98013355499987,
                40.66118761199988
              ],
              [
                -73.98008984499991,
                40.66121620899992
              ],
              [
                -73.98004133199991,
                40.661239892999916
              ],
              [
                -73.97998896000001,
                40.6612582029999
              ],
              [
                -73.97993375199998,
                40.661270780999864
              ],
              [
                -73.97987678099987,
                40.66127738199991
              ],
              [
                -73.97981915699984,
                40.661277877999964
              ],
              [
                -73.97976200499983,
                40.66127225999991
              ],
              [
                -73.97970643699993,
                40.66126063599991
              ],
              [
                -73.97965353499985,
                40.66124323299993
              ],
              [
                -73.97935059899989,
                40.6615948209999
              ],
              [
                -73.97885475899994,
                40.662201832999905
              ],
              [
                -73.97834945199986,
                40.66279901499991
              ],
              [
                -73.97784275099997,
                40.66340176899992
              ],
              [
                -73.97734252999986,
                40.66401189699998
              ],
              [
                -73.97684516799998,
                40.66460638199994
              ],
              [
                -73.97633386199989,
                40.6652136039999
              ],
              [
                -73.97583132299987,
                40.665815079999895
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0000910644156732,
        "objectid": 182,
        "shape_leng": 0.0393606369479,
        "location_id": 182,
        "zone": "Parkchester",
        "borough": "Bronx"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.85465135700002,
                40.83388802699988
              ],
              [
                -73.85347099099992,
                40.83401491699993
              ],
              [
                -73.85281379799993,
                40.83407976199991
              ],
              [
                -73.85188599699991,
                40.83417942099992
              ],
              [
                -73.85168459699997,
                40.8342010519999
              ],
              [
                -73.85185868799985,
                40.83477774699989
              ],
              [
                -73.85207005899987,
                40.83562530299987
              ],
              [
                -73.85220720999986,
                40.83634749499992
              ],
              [
                -73.85233148699994,
                40.83701052699998
              ],
              [
                -73.85247938999986,
                40.83778428699986
              ],
              [
                -73.85266480499992,
                40.83878008399992
              ],
              [
                -73.85286997899986,
                40.839851303999936
              ],
              [
                -73.85303510299988,
                40.84073545799989
              ],
              [
                -73.85309460199986,
                40.84104695999991
              ],
              [
                -73.85333524999986,
                40.84247015499989
              ],
              [
                -73.85349746599992,
                40.84245166799994
              ],
              [
                -73.85420745799989,
                40.842370738999946
              ],
              [
                -73.85520012799999,
                40.84225761399988
              ],
              [
                -73.85553579799995,
                40.84221948999987
              ],
              [
                -73.85618402199997,
                40.84214591699994
              ],
              [
                -73.85719247999995,
                40.842027647999906
              ],
              [
                -73.85835669199989,
                40.841899285999894
              ],
              [
                -73.85919593699994,
                40.841804032999924
              ],
              [
                -73.8597245239999,
                40.84174401899985
              ],
              [
                -73.86018491899982,
                40.841689089999875
              ],
              [
                -73.86045627399983,
                40.841661664999954
              ],
              [
                -73.86162833399996,
                40.841527859999864
              ],
              [
                -73.86176166600002,
                40.84151271999993
              ],
              [
                -73.86347232399994,
                40.84130593299991
              ],
              [
                -73.86358013999997,
                40.84127795699989
              ],
              [
                -73.863639382,
                40.84126527499991
              ],
              [
                -73.86436922099999,
                40.84110901799995
              ],
              [
                -73.86448011799995,
                40.84108779699995
              ],
              [
                -73.8639374809998,
                40.84004456599995
              ],
              [
                -73.86355551599988,
                40.83786561999994
              ],
              [
                -73.863156384,
                40.83553082199993
              ],
              [
                -73.86295952099994,
                40.83442032299993
              ],
              [
                -73.862942603,
                40.83429960099994
              ],
              [
                -73.86289761399996,
                40.83400338399993
              ],
              [
                -73.86289361599997,
                40.8339642609999
              ],
              [
                -73.86288113299992,
                40.833889072999916
              ],
              [
                -73.86284851199984,
                40.8336902309999
              ],
              [
                -73.86272700700006,
                40.833072220999945
              ],
              [
                -73.86271114399993,
                40.83299150899998
              ],
              [
                -73.86155834899989,
                40.83333022099989
              ],
              [
                -73.86142308999996,
                40.833447410999895
              ],
              [
                -73.86137654799992,
                40.83349072299995
              ],
              [
                -73.86129183699992,
                40.83353642099994
              ],
              [
                -73.86085019699982,
                40.83361353799989
              ],
              [
                -73.86075203199984,
                40.833595374999916
              ],
              [
                -73.86065651499993,
                40.83356427199994
              ],
              [
                -73.8605680069999,
                40.83352073499988
              ],
              [
                -73.86049053499993,
                40.833466403999864
              ],
              [
                -73.8604271769999,
                40.83340383299987
              ],
              [
                -73.86037967100003,
                40.83333610299992
              ],
              [
                -73.86033845899995,
                40.83325316599994
              ],
              [
                -73.85983937599991,
                40.83334089599988
              ],
              [
                -73.8590567219999,
                40.8334132249999
              ],
              [
                -73.85773226199993,
                40.83355730699993
              ],
              [
                -73.85465135700002,
                40.83388802699988
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0000951929451066,
        "objectid": 183,
        "shape_leng": 0.0398262960348,
        "location_id": 183,
        "zone": "Pelham Bay",
        "borough": "Bronx"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.83676643699985,
                40.84338596999993
              ],
              [
                -73.83582911399996,
                40.84347129499989
              ],
              [
                -73.83474813499993,
                40.8435028079999
              ],
              [
                -73.83383204399993,
                40.84351641299987
              ],
              [
                -73.83294076399983,
                40.84351915999992
              ],
              [
                -73.83200795799995,
                40.843630393999916
              ],
              [
                -73.83108377099984,
                40.84374456899991
              ],
              [
                -73.83043186399985,
                40.84385798399996
              ],
              [
                -73.8296554989998,
                40.84408912299994
              ],
              [
                -73.8293154839999,
                40.84419272599997
              ],
              [
                -73.82870970999984,
                40.84437387199991
              ],
              [
                -73.8284527429999,
                40.84445071699992
              ],
              [
                -73.8275753599998,
                40.84473187499989
              ],
              [
                -73.82674715099992,
                40.845078917999885
              ],
              [
                -73.82611300699996,
                40.84533869399992
              ],
              [
                -73.82611304199997,
                40.84533885599993
              ],
              [
                -73.8261701409999,
                40.84557398799995
              ],
              [
                -73.82624552999988,
                40.84580733899987
              ],
              [
                -73.82633899899987,
                40.84603754699994
              ],
              [
                -73.82634836399981,
                40.846056569999845
              ],
              [
                -73.82645014900007,
                40.846263395999934
              ],
              [
                -73.826578403,
                40.84648372299992
              ],
              [
                -73.82672302600004,
                40.84669743899988
              ],
              [
                -73.82688311099984,
                40.84690351499991
              ],
              [
                -73.827677297,
                40.8481306809999
              ],
              [
                -73.82799676999998,
                40.84862431399989
              ],
              [
                -73.82799320299986,
                40.84890315399986
              ],
              [
                -73.82796831299994,
                40.8491823059999
              ],
              [
                -73.82792209199984,
                40.849460363999874
              ],
              [
                -73.82785470899994,
                40.84973592299991
              ],
              [
                -73.82776655799988,
                40.85000758699992
              ],
              [
                -73.82765823699988,
                40.850274033999916
              ],
              [
                -73.82742445699994,
                40.850993646999946
              ],
              [
                -73.82718423799986,
                40.85171120499995
              ],
              [
                -73.82713133299993,
                40.85187108099991
              ],
              [
                -73.82737835799986,
                40.8528179589999
              ],
              [
                -73.82721751200002,
                40.853250512999935
              ],
              [
                -73.82723026100001,
                40.853350756999895
              ],
              [
                -73.8280141599998,
                40.85472248699993
              ],
              [
                -73.82860197199996,
                40.855717847999884
              ],
              [
                -73.82908318700004,
                40.8556760139999
              ],
              [
                -73.82956640599991,
                40.85562081699991
              ],
              [
                -73.83056649299975,
                40.85551244799995
              ],
              [
                -73.83129157799985,
                40.85543410499991
              ],
              [
                -73.83157449000001,
                40.855379596999946
              ],
              [
                -73.83193102199998,
                40.85528511599992
              ],
              [
                -73.83228148899984,
                40.85517744999994
              ],
              [
                -73.832624647,
                40.855056966999925
              ],
              [
                -73.83295928499993,
                40.85492410599992
              ],
              [
                -73.83350017899996,
                40.85467968399995
              ],
              [
                -73.83390574099988,
                40.85446777999993
              ],
              [
                -73.83408232099995,
                40.854362639999906
              ],
              [
                -73.83424656499982,
                40.85424355199993
              ],
              [
                -73.83439574299983,
                40.85411187099993
              ],
              [
                -73.83452741999977,
                40.85396932399991
              ],
              [
                -73.83463970299987,
                40.85381799399991
              ],
              [
                -73.83473123599998,
                40.85366018699991
              ],
              [
                -73.83480132399997,
                40.85349832599997
              ],
              [
                -73.83504028899982,
                40.8531267369999
              ],
              [
                -73.83519691899983,
                40.85284353099991
              ],
              [
                -73.83533589099999,
                40.852554697999935
              ],
              [
                -73.83545671599992,
                40.85226119199991
              ],
              [
                -73.83555900199993,
                40.85196401299993
              ],
              [
                -73.83580031399995,
                40.84967681599991
              ],
              [
                -73.83593362199987,
                40.84840635599993
              ],
              [
                -73.83606934399998,
                40.84716484899992
              ],
              [
                -73.83615362999997,
                40.84613117599993
              ],
              [
                -73.83619907199994,
                40.84582739599996
              ],
              [
                -73.8365331269999,
                40.844620694999946
              ],
              [
                -73.83676876799983,
                40.843542820999886
              ],
              [
                -73.83688925199996,
                40.843374787999906
              ],
              [
                -73.83676643699985,
                40.84338596999993
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.00198883363103,
        "objectid": 184,
        "shape_leng": 0.260815683043,
        "location_id": 184,
        "zone": "Pelham Bay Park",
        "borough": "Bronx"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.80728647099995,
                40.88593181399989
              ],
              [
                -73.80764139499996,
                40.885953252999926
              ],
              [
                -73.80799398199989,
                40.88599083199992
              ],
              [
                -73.80834232399997,
                40.88604431399995
              ],
              [
                -73.80868459399994,
                40.886113291999905
              ],
              [
                -73.80915068099985,
                40.88621447699993
              ],
              [
                -73.8097107929999,
                40.88633607499994
              ],
              [
                -73.81027053199992,
                40.88645124299992
              ],
              [
                -73.81083611999995,
                40.88655115199987
              ],
              [
                -73.81140645199994,
                40.886635586999915
              ],
              [
                -73.811980437,
                40.886704338999955
              ],
              [
                -73.81209659199986,
                40.88671500999993
              ],
              [
                -73.813567347,
                40.886778708999906
              ],
              [
                -73.81399748399998,
                40.8867821319999
              ],
              [
                -73.81442762200005,
                40.886771964999895
              ],
              [
                -73.81485665899983,
                40.88674823199989
              ],
              [
                -73.81518316800005,
                40.88671965999993
              ],
              [
                -73.81528345499991,
                40.886711023999936
              ],
              [
                -73.81536464199993,
                40.886701220999896
              ],
              [
                -73.8154787309999,
                40.88668744599995
              ],
              [
                -73.81670778700003,
                40.886480950999974
              ],
              [
                -73.81742638899989,
                40.88629430599987
              ],
              [
                -73.81841703399992,
                40.886036994999884
              ],
              [
                -73.81983275399992,
                40.88566925399989
              ],
              [
                -73.82027432999985,
                40.885550699999925
              ],
              [
                -73.82023958399981,
                40.88549105099992
              ],
              [
                -73.81992586499995,
                40.88211933299989
              ],
              [
                -73.82110268599995,
                40.87896924499993
              ],
              [
                -73.82329599399986,
                40.87309741799988
              ],
              [
                -73.821266938,
                40.86907160599991
              ],
              [
                -73.82111615299983,
                40.86900813699992
              ],
              [
                -73.82103881099985,
                40.8689760799999
              ],
              [
                -73.82097601199978,
                40.868949786999956
              ],
              [
                -73.8209161719998,
                40.868916934999966
              ],
              [
                -73.81940759199995,
                40.867999270999874
              ],
              [
                -73.81938761399984,
                40.86798690299986
              ],
              [
                -73.81578764099999,
                40.86575806099991
              ],
              [
                -73.81641455899984,
                40.86515149799992
              ],
              [
                -73.81699760299986,
                40.86445903099996
              ],
              [
                -73.8168449589999,
                40.86430679299993
              ],
              [
                -73.81761651299989,
                40.86362634299998
              ],
              [
                -73.81884300999991,
                40.8625682099999
              ],
              [
                -73.82037552999996,
                40.86103300499991
              ],
              [
                -73.82450575099989,
                40.85976402099996
              ],
              [
                -73.82444012099991,
                40.85950138399989
              ],
              [
                -73.82632571099997,
                40.85929799699995
              ],
              [
                -73.82699653199998,
                40.859087785999876
              ],
              [
                -73.82714556299992,
                40.85923239499993
              ],
              [
                -73.82720794099991,
                40.85941373899988
              ],
              [
                -73.82732348799999,
                40.85968688699988
              ],
              [
                -73.82741458099993,
                40.85995987299993
              ],
              [
                -73.82759107999996,
                40.86007855099994
              ],
              [
                -73.82787032399982,
                40.860678212999915
              ],
              [
                -73.82799444999982,
                40.860840185999905
              ],
              [
                -73.82826796599981,
                40.860837332999935
              ],
              [
                -73.82834347399992,
                40.860892025999895
              ],
              [
                -73.82847346699994,
                40.86073135299991
              ],
              [
                -73.82872856899989,
                40.860413327999915
              ],
              [
                -73.82959409199992,
                40.85935976399989
              ],
              [
                -73.83055471299986,
                40.85819039599994
              ],
              [
                -73.83072266700005,
                40.857994586999915
              ],
              [
                -73.83111449199998,
                40.85752432999991
              ],
              [
                -73.83128338399985,
                40.857349961999894
              ],
              [
                -73.83193393799985,
                40.85669351699989
              ],
              [
                -73.8321371229998,
                40.85654369099994
              ],
              [
                -73.83238468599995,
                40.856352210999916
              ],
              [
                -73.83268903299997,
                40.85613238299992
              ],
              [
                -73.83276219999989,
                40.856079539999925
              ],
              [
                -73.83283502299986,
                40.85603584499989
              ],
              [
                -73.83281265300002,
                40.85597971299992
              ],
              [
                -73.83242972799992,
                40.85582103999991
              ],
              [
                -73.83229711899999,
                40.85574641199985
              ],
              [
                -73.83216420699983,
                40.85567161299986
              ],
              [
                -73.83200598799996,
                40.855594876999916
              ],
              [
                -73.83183634999988,
                40.855531711999966
              ],
              [
                -73.83165843199998,
                40.85548344799993
              ],
              [
                -73.83147565799987,
                40.85545085299986
              ],
              [
                -73.83129157799985,
                40.85543410499991
              ],
              [
                -73.83056649299975,
                40.85551244799995
              ],
              [
                -73.82956640499994,
                40.85562081699991
              ],
              [
                -73.82908318599985,
                40.85567601399988
              ],
              [
                -73.82860197199996,
                40.855717847999884
              ],
              [
                -73.8280141599998,
                40.85472248699993
              ],
              [
                -73.82723026100001,
                40.853350756999895
              ],
              [
                -73.82721751200002,
                40.853250512999935
              ],
              [
                -73.82737835799986,
                40.8528179589999
              ],
              [
                -73.82713133299993,
                40.85187108099991
              ],
              [
                -73.82718423799986,
                40.85171120499995
              ],
              [
                -73.82742445699994,
                40.850993646999946
              ],
              [
                -73.82765823699988,
                40.850274033999916
              ],
              [
                -73.82776655799988,
                40.85000758699992
              ],
              [
                -73.82785470799985,
                40.84973592299989
              ],
              [
                -73.82792209199984,
                40.849460363999874
              ],
              [
                -73.82796831299994,
                40.8491823059999
              ],
              [
                -73.82799320199993,
                40.84890315399989
              ],
              [
                -73.82799676999987,
                40.8486243129999
              ],
              [
                -73.827677297,
                40.8481306809999
              ],
              [
                -73.82688311099984,
                40.84690351499991
              ],
              [
                -73.82672302600004,
                40.84669743899988
              ],
              [
                -73.826578403,
                40.84648372299992
              ],
              [
                -73.82645014900007,
                40.846263395999934
              ],
              [
                -73.82634836399981,
                40.846056569999845
              ],
              [
                -73.82633899899987,
                40.84603754699994
              ],
              [
                -73.82624552999988,
                40.84580733899987
              ],
              [
                -73.8261701409999,
                40.84557398799995
              ],
              [
                -73.82611304199985,
                40.84533885499997
              ],
              [
                -73.82611300699996,
                40.84533869399992
              ],
              [
                -73.82624132899997,
                40.84299359399996
              ],
              [
                -73.82580705599993,
                40.84288069299988
              ],
              [
                -73.82567803699993,
                40.84284715199994
              ],
              [
                -73.82558155599993,
                40.842818658999946
              ],
              [
                -73.82517998999998,
                40.842700058999895
              ],
              [
                -73.82513836199983,
                40.84334359799992
              ],
              [
                -73.82507950000002,
                40.844304001999916
              ],
              [
                -73.8250848919999,
                40.84469769799994
              ],
              [
                -73.82495697699986,
                40.846105444999864
              ],
              [
                -73.82443760499989,
                40.84637787399989
              ],
              [
                -73.82395955500006,
                40.8466412419999
              ],
              [
                -73.82301297099994,
                40.847161737999905
              ],
              [
                -73.82203826799994,
                40.847690368999906
              ],
              [
                -73.82110355699993,
                40.84819897999993
              ],
              [
                -73.82048405599983,
                40.848501025999965
              ],
              [
                -73.82014043700003,
                40.848020598999895
              ],
              [
                -73.81915009199982,
                40.84817017999989
              ],
              [
                -73.81820226899997,
                40.848311031999906
              ],
              [
                -73.8172632259998,
                40.84842656699994
              ],
              [
                -73.81630119199998,
                40.84857217199994
              ],
              [
                -73.81507165904237,
                40.84913361979451
              ],
              [
                -73.81506551195956,
                40.849224131912884
              ],
              [
                -73.81514445571717,
                40.84927831218819
              ],
              [
                -73.8151455705632,
                40.849279077548296
              ],
              [
                -73.81521774467456,
                40.84928528484982
              ],
              [
                -73.81529809165959,
                40.8492366855288
              ],
              [
                -73.81537028110344,
                40.84923680109017
              ],
              [
                -73.81545040822886,
                40.84926738413394
              ],
              [
                -73.81558644724866,
                40.84938332849485
              ],
              [
                -73.81561022437111,
                40.84948691181121
              ],
              [
                -73.8156741733411,
                40.84956619565584
              ],
              [
                -73.81575414982423,
                40.84965159606209
              ],
              [
                -73.81589012272623,
                40.84979190260365
              ],
              [
                -73.8162100447246,
                40.85012741179522
              ],
              [
                -73.81624996708972,
                40.850194475367005
              ],
              [
                -73.81624162574909,
                40.85031018953592
              ],
              [
                -73.8161692493101,
                40.85037707369391
              ],
              [
                -73.81603285347633,
                40.850389038927
              ],
              [
                -73.81594460284292,
                40.85039498764829
              ],
              [
                -73.81593644656883,
                40.850443702450676
              ],
              [
                -73.81599252836979,
                40.85046815507269
              ],
              [
                -73.81602444523365,
                40.85052911495124
              ],
              [
                -73.81602424307003,
                40.850602206267055
              ],
              [
                -73.81587962275361,
                40.850687246489585
              ],
              [
                -73.81585752887506,
                40.85069559143508
              ],
              [
                -73.81578326522938,
                40.85072363847702
              ],
              [
                -73.81570289992754,
                40.85077832859145
              ],
              [
                -73.8157028324223,
                40.85080269213893
              ],
              [
                -73.81571882498523,
                40.85082099034472
              ],
              [
                -73.81579903851735,
                40.85082111845267
              ],
              [
                -73.81584723466285,
                40.8507968317441
              ],
              [
                -73.81590219996258,
                40.85078437395156
              ],
              [
                -73.81596136832383,
                40.85076751093163
              ],
              [
                -73.81604706927764,
                40.85077792604365
              ],
              [
                -73.816110132014,
                40.85081571307258
              ],
              [
                -73.81615793715294,
                40.850871629300734
              ],
              [
                -73.8161775944023,
                40.85089462177078
              ],
              [
                -73.81622718968529,
                40.85090840624422
              ],
              [
                -73.81623984150588,
                40.85089957410617
              ],
              [
                -73.81628595906908,
                40.850867385157265
              ],
              [
                -73.81632663289388,
                40.85084346721822
              ],
              [
                -73.81641233245735,
                40.85085388255884
              ],
              [
                -73.81650694371834,
                40.85090542502866
              ],
              [
                -73.81660611270236,
                40.85093984385119
              ],
              [
                -73.81670082809461,
                40.850953698165675
              ],
              [
                -73.8167820523949,
                40.850950401837046
              ],
              [
                -73.81685873870755,
                40.85095737599871
              ],
              [
                -73.81691731869432,
                40.850984877716044
              ],
              [
                -73.81696683811616,
                40.85102606981369
              ],
              [
                -73.81701179857696,
                40.85108438496986
              ],
              [
                -73.81705764722436,
                40.85117859683576
              ],
              [
                -73.81708814456702,
                40.851214699159414
              ],
              [
                -73.81712409042197,
                40.85126957344483
              ],
              [
                -73.81712386434577,
                40.851351800789594
              ],
              [
                -73.81712368537173,
                40.85141689622223
              ],
              [
                -73.81710555246347,
                40.8514477037433
              ],
              [
                -73.81708786881536,
                40.851487704505494
              ],
              [
                -73.81708739083649,
                40.8514887879615
              ],
              [
                -73.81708724951115,
                40.85154017967314
              ],
              [
                -73.81706907886695,
                40.85158468973445
              ],
              [
                -73.81701488702278,
                40.851601735774366
              ],
              [
                -73.8169742219704,
                40.8516222269334
              ],
              [
                -73.81693804031279,
                40.85165300579098
              ],
              [
                -73.81691542317321,
                40.85167352556717
              ],
              [
                -73.81692891227493,
                40.851690677786415
              ],
              [
                -73.81696953091003,
                40.85168731570691
              ],
              [
                -73.81702373247587,
                40.85166684551811
              ],
              [
                -73.81708234186158,
                40.85168406927604
              ],
              [
                -73.81710848616731,
                40.85169856987072
              ],
              [
                -73.81713189892409,
                40.85171155607017
              ],
              [
                -73.81719039626154,
                40.85176989361129
              ],
              [
                -73.81720828353117,
                40.851828165777334
              ],
              [
                -73.8171945965907,
                40.851882961380404
              ],
              [
                -73.81717191352776,
                40.85192746497483
              ],
              [
                -73.81715825633269,
                40.851971983372756
              ],
              [
                -73.81713976212657,
                40.85201844005767
              ],
              [
                -73.81713099465547,
                40.852040462904455
              ],
              [
                -73.81712633131487,
                40.8520952739737
              ],
              [
                -73.81713069323155,
                40.852150098176615
              ],
              [
                -73.81715861778896,
                40.85221128743284
              ],
              [
                -73.81715689336222,
                40.852257041070125
              ],
              [
                -73.81714840664624,
                40.852302357551125
              ],
              [
                -73.8171332651999,
                40.852346662471184
              ],
              [
                -73.81711166093481,
                40.8523893942462
              ],
              [
                -73.81708386768247,
                40.85243001123146
              ],
              [
                -73.81704302179158,
                40.852497134847326
              ],
              [
                -73.81701023768579,
                40.85256676112483
              ],
              [
                -73.81698577763542,
                40.85263833311411
              ],
              [
                -73.81696983732702,
                40.85271127829922
              ],
              [
                -73.8169625442986,
                40.8527850131781
              ],
              [
                -73.81696177954453,
                40.85284084997985
              ],
              [
                -73.81696688000618,
                40.85289655533696
              ],
              [
                -73.81697781322627,
                40.85295177460578
              ],
              [
                -73.81698689510887,
                40.85298390587563
              ],
              [
                -73.81699272677278,
                40.85301646912296
              ],
              [
                -73.81699500473643,
                40.853074288355856
              ],
              [
                -73.81699066884251,
                40.8531687356329
              ],
              [
                -73.81697773259017,
                40.85326272842153
              ],
              [
                -73.81695625801093,
                40.8533558157469
              ],
              [
                -73.81692634810447,
                40.85344755097646
              ],
              [
                -73.81688814634457,
                40.85353749396302
              ],
              [
                -73.81684183599074,
                40.85362521315728
              ],
              [
                -73.8167876392117,
                40.85371028767776
              ],
              [
                -73.81672581601713,
                40.85379230933093
              ],
              [
                -73.81665666301353,
                40.85387088456941
              ],
              [
                -73.81658051197839,
                40.85394563638093
              ],
              [
                -73.81649772827157,
                40.85401620609714
              ],
              [
                -73.81640870908014,
                40.854082255114704
              ],
              [
                -73.81230602708416,
                40.854162026473176
              ],
              [
                -73.81273146495035,
                40.85887754194771
              ],
              [
                -73.8138180258687,
                40.86054827867073
              ],
              [
                -73.81485206024097,
                40.86090099030983
              ],
              [
                -73.81479629757334,
                40.86112895674678
              ],
              [
                -73.81526321415275,
                40.86111774712503
              ],
              [
                -73.81549293707927,
                40.860955286737195
              ],
              [
                -73.8156820424257,
                40.86110976454376
              ],
              [
                -73.81587638257493,
                40.86100386461141
              ],
              [
                -73.81632908278753,
                40.86115273052222
              ],
              [
                -73.81641392102722,
                40.86118062770394
              ],
              [
                -73.81550189599471,
                40.86248004810606
              ],
              [
                -73.81510315384149,
                40.863057465367774
              ],
              [
                -73.81501897203428,
                40.863122525364865
              ],
              [
                -73.8149932178353,
                40.8630942076252
              ],
              [
                -73.8149399052731,
                40.863026906535225
              ],
              [
                -73.81494003249354,
                40.86298118875594
              ],
              [
                -73.81482452410822,
                40.86294077217953
              ],
              [
                -73.8149383406364,
                40.862723339156375
              ],
              [
                -73.81494351469581,
                40.86259533943938
              ],
              [
                -73.81501601995143,
                40.86250767727128
              ],
              [
                -73.81507393686067,
                40.86246936737076
              ],
              [
                -73.81515356048828,
                40.86242012043739
              ],
              [
                -73.81515628564216,
                40.86230674492953
              ],
              [
                -73.81503361392043,
                40.86224437200939
              ],
              [
                -73.8149054682641,
                40.8624178936931
              ],
              [
                -73.8148450715455,
                40.86248180163667
              ],
              [
                -73.81471457316346,
                40.86263520277645
              ],
              [
                -73.81458906516404,
                40.86272643663105
              ],
              [
                -73.81450667389406,
                40.86290368723947
              ],
              [
                -73.8146872979941,
                40.86291495026299
              ],
              [
                -73.81468686995449,
                40.86306856019122
              ],
              [
                -73.81447233903512,
                40.86312307718502
              ],
              [
                -73.81436118470883,
                40.8632490778893
              ],
              [
                -73.81411414337536,
                40.86373145821166
              ],
              [
                -73.81384566155016,
                40.86390175802964
              ],
              [
                -73.81368612350815,
                40.86373500838618
              ],
              [
                -73.8136222293541,
                40.863631361315115
              ],
              [
                -73.81356635930297,
                40.863527725999255
              ],
              [
                -73.81340632950182,
                40.863375195554674
              ],
              [
                -73.81329794398005,
                40.86322312991144
              ],
              [
                -73.81323099767701,
                40.863196704303526
              ],
              [
                -73.81303909715699,
                40.863226219723316
              ],
              [
                -73.81275751667131,
                40.863095928599826
              ],
              [
                -73.81256827895241,
                40.863000876957884
              ],
              [
                -73.81230290205067,
                40.8628618385043
              ],
              [
                -73.81228010874105,
                40.86274951229557
              ],
              [
                -73.81198840271249,
                40.862623715215996
              ],
              [
                -73.81166764690963,
                40.86256837491089
              ],
              [
                -73.81139501173807,
                40.86251920286503
              ],
              [
                -73.81110457149269,
                40.862464468730856
              ],
              [
                -73.81102719668907,
                40.862431738257484
              ],
              [
                -73.81095471851108,
                40.86239308319121
              ],
              [
                -73.81088792104822,
                40.8623489217181
              ],
              [
                -73.81082752692866,
                40.86229973159373
              ],
              [
                -73.81077418950343,
                40.862246044974576
              ],
              [
                -73.81072848577665,
                40.86218844266047
              ],
              [
                -73.81069091016548,
                40.862127547810765
              ],
              [
                -73.81066186915,
                40.86206401920328
              ],
              [
                -73.81060422139164,
                40.862047158180964
              ],
              [
                -73.81055048877973,
                40.86202401430756
              ],
              [
                -73.81050185133067,
                40.861995095845195
              ],
              [
                -73.81045937716219,
                40.86196103787082
              ],
              [
                -73.81042399903666,
                40.86192258832921
              ],
              [
                -73.81039649387583,
                40.86188059160657
              ],
              [
                -73.81037746570142,
                40.86183596998654
              ],
              [
                -73.81024209019576,
                40.8615858244837
              ],
              [
                -73.810136840863,
                40.861431267304674
              ],
              [
                -73.81002481831423,
                40.8612794791436
              ],
              [
                -73.80990614882143,
                40.86113063103378
              ],
              [
                -73.8097809661435,
                40.860984890694944
              ],
              [
                -73.8096494113749,
                40.86084242234358
              ],
              [
                -73.80951163278827,
                40.860703386508746
              ],
              [
                -73.80936778566492,
                40.8605679398505
              ],
              [
                -73.8089217106452,
                40.86028795999539
              ],
              [
                -73.80851624551879,
                40.85995638438492
              ],
              [
                -73.80824887200353,
                40.85956779276715
              ],
              [
                -73.80768048107562,
                40.85853806328953
              ],
              [
                -73.8076037333222,
                40.85853559904765
              ],
              [
                -73.80751534772594,
                40.8585841783749
              ],
              [
                -73.80772902934285,
                40.85909168620478
              ],
              [
                -73.80805973431342,
                40.85959661665058
              ],
              [
                -73.80812573026353,
                40.85974803990317
              ],
              [
                -73.80811833175508,
                40.859806070038665
              ],
              [
                -73.80810249259939,
                40.85986311770023
              ],
              [
                -73.80807840864172,
                40.85991847743786
              ],
              [
                -73.80804637768432,
                40.85997146467256
              ],
              [
                -73.80800679580457,
                40.8600214241621
              ],
              [
                -73.80796015245868,
                40.860067738104355
              ],
              [
                -73.80790702442809,
                40.86010983377677
              ],
              [
                -73.8078480686893,
                40.860147190619365
              ],
              [
                -73.80778401428749,
                40.86017934667235
              ],
              [
                -73.80783343870087,
                40.86031813330539
              ],
              [
                -73.80742214437697,
                40.860399409944236
              ],
              [
                -73.80733593194458,
                40.86038305269601
              ],
              [
                -73.80725232975388,
                40.86036018222822
              ],
              [
                -73.80717218909406,
                40.86033103142287
              ],
              [
                -73.80709632600464,
                40.860295897112344
              ],
              [
                -73.80702551296353,
                40.860255137056164
              ],
              [
                -73.80696047102249,
                40.860209166298226
              ],
              [
                -73.80690186246515,
                40.8601584529397
              ],
              [
                -73.80685028406145,
                40.860103513373076
              ],
              [
                -73.80680626099317,
                40.86004490702291
              ],
              [
                -73.80677024150565,
                40.859983230649476
              ],
              [
                -73.80674259234422,
                40.859919112272024
              ],
              [
                -73.8065822930691,
                40.85967223238393
              ],
              [
                -73.80612390783546,
                40.859812206619026
              ],
              [
                -73.80582041215638,
                40.860034612469434
              ],
              [
                -73.80624571930763,
                40.8614274844424
              ],
              [
                -73.80592716427573,
                40.861941196990294
              ],
              [
                -73.80582568415838,
                40.86194868967932
              ],
              [
                -73.80572372998525,
                40.86194943434327
              ],
              [
                -73.80562207877394,
                40.86194342530668
              ],
              [
                -73.80552150523441,
                40.86193070836608
              ],
              [
                -73.80542277586062,
                40.861911380440375
              ],
              [
                -73.8053266430914,
                40.86188558883212
              ],
              [
                -73.80523383957318,
                40.86185353010516
              ],
              [
                -73.80514507257695,
                40.86181544858605
              ],
              [
                -73.80506101860708,
                40.861771634502
              ],
              [
                -73.8049823182458,
                40.86172242176844
              ],
              [
                -73.80490957126987,
                40.86166818544455
              ],
              [
                -73.80484333208106,
                40.8616093388739
              ],
              [
                -73.80478410547944,
                40.86154633053462
              ],
              [
                -73.80473234281749,
                40.8614796406207
              ],
              [
                -73.80464756427624,
                40.86134183147036
              ],
              [
                -73.80460527769026,
                40.86121718099268
              ],
              [
                -73.80462819630267,
                40.86102974103276
              ],
              [
                -73.8046348742635,
                40.86100342015325
              ],
              [
                -73.80468371760045,
                40.86081090572282
              ],
              [
                -73.8046898389874,
                40.8605860934545
              ],
              [
                -73.804679688048,
                40.86038010036601
              ],
              [
                -73.80464293882284,
                40.85996820157236
              ],
              [
                -73.80456624045203,
                40.85968139696558
              ],
              [
                -73.80449817480466,
                40.859438252845386
              ],
              [
                -73.80439744909204,
                40.85922025991276
              ],
              [
                -73.80430576415975,
                40.8590896320368
              ],
              [
                -73.80422034282891,
                40.85897798290789
              ],
              [
                -73.80420585726513,
                40.85895904893254
              ],
              [
                -73.80417167874226,
                40.858821868166345
              ],
              [
                -73.80417485011644,
                40.85875569150591
              ],
              [
                -73.80417856111791,
                40.85867822182148
              ],
              [
                -73.8042014225376,
                40.85848453815938
              ],
              [
                -73.80417354171651,
                40.858141274646506
              ],
              [
                -73.80413784756402,
                40.857841760375635
              ],
              [
                -73.80405246302159,
                40.85750505118683
              ],
              [
                -73.80395891628706,
                40.85717462927909
              ],
              [
                -73.80389056334138,
                40.85690026632974
              ],
              [
                -73.80386367685729,
                40.856663144315135
              ],
              [
                -73.80383737490631,
                40.85648845650412
              ],
              [
                -73.80377806309502,
                40.85630145969353
              ],
              [
                -73.80368603416521,
                40.85613337035755
              ],
              [
                -73.80353916065029,
                40.85564938529117
              ],
              [
                -73.80352718257473,
                40.85560344631303
              ],
              [
                -73.8035235980902,
                40.85555669488954
              ],
              [
                -73.80352847464194,
                40.85551001091841
              ],
              [
                -73.8035417204318,
                40.85546427302577
              ],
              [
                -73.80356308614826,
                40.855420342030335
              ],
              [
                -73.80359216965921,
                40.85537904474201
              ],
              [
                -73.80362842358109,
                40.85534115840109
              ],
              [
                -73.80367116558243,
                40.855307396050726
              ],
              [
                -73.80371959122618,
                40.855278393116954
              ],
              [
                -73.80377278910957,
                40.85525469545025
              ],
              [
                -73.80382975801699,
                40.855236749052494
              ],
              [
                -73.80388942576332,
                40.85522489168396
              ],
              [
                -73.80395066937308,
                40.85521934650569
              ],
              [
                -73.80401233621217,
                40.85522021788068
              ],
              [
                -73.80408499331024,
                40.85520704833207
              ],
              [
                -73.80415514766925,
                40.85518756780286
              ],
              [
                -73.80422185194051,
                40.85516203935425
              ],
              [
                -73.8042842053675,
                40.855130807717266
              ],
              [
                -73.80434136595021,
                40.855094294636736
              ],
              [
                -73.80439256181494,
                40.855052993176685
              ],
              [
                -73.80443710163813,
                40.85500746106075
              ],
              [
                -73.80447438398078,
                40.85495831314143
              ],
              [
                -73.80450390541125,
                40.854906213096434
              ],
              [
                -73.8045252673004,
                40.85485186446629
              ],
              [
                -73.80453818120623,
                40.85479600115426
              ],
              [
                -73.80454247276606,
                40.85473937751501
              ],
              [
                -73.80417557932397,
                40.853340120189074
              ],
              [
                -73.80378369924797,
                40.85273925768593
              ],
              [
                -73.80353684022062,
                40.85246299713217
              ],
              [
                -73.80329723345861,
                40.852183079832955
              ],
              [
                -73.80306497280239,
                40.85189961552297
              ],
              [
                -73.80284014920842,
                40.8516127153258
              ],
              [
                -73.80262285071488,
                40.85132249171067
              ],
              [
                -73.80241316240595,
                40.851029058447864
              ],
              [
                -73.80221116637931,
                40.85073253056464
              ],
              [
                -73.8020169417146,
                40.8504330242999
              ],
              [
                -73.80183056444126,
                40.850130657058436
              ],
              [
                -73.80179286047874,
                40.850040649665964
              ],
              [
                -73.80176208903741,
                40.84994913439596
              ],
              [
                -73.80173835351495,
                40.849856418842926
              ],
              [
                -73.80172173365993,
                40.849762814634865
              ],
              [
                -73.80171228530318,
                40.84966863638507
              ],
              [
                -73.80172140324993,
                40.849565349292966
              ],
              [
                -73.80172128953284,
                40.84946183043671
              ],
              [
                -73.80171194471762,
                40.849358555034705
              ],
              [
                -73.80169341174509,
                40.849255997185686
              ],
              [
                -73.80166577573556,
                40.84915462769178
              ],
              [
                -73.80162916359535,
                40.84905491189835
              ],
              [
                -73.80158374343453,
                40.84895730755684
              ],
              [
                -73.8015297237949,
                40.848862262724154
              ],
              [
                -73.80146735269052,
                40.84877021370569
              ],
              [
                -73.80139691647074,
                40.84868158305278
              ],
              [
                -73.80131873850269,
                40.848596777623065
              ],
              [
                -73.80123317768903,
                40.848516186713255
              ],
              [
                -73.80114062681764,
                40.84844018027172
              ],
              [
                -73.80104151076102,
                40.84836910720121
              ],
              [
                -73.80093628452296,
                40.848303293756715
              ],
              [
                -73.80082543115157,
                40.84824304204874
              ],
              [
                -73.80070945952177,
                40.84818862865633
              ],
              [
                -73.80058890199892,
                40.8481403033575
              ],
              [
                -73.80046431199588,
                40.848098287983504
              ],
              [
                -73.80040387554423,
                40.84810822100143
              ],
              [
                -73.80034555421923,
                40.848123825764596
              ],
              [
                -73.80029026716008,
                40.848144856343545
              ],
              [
                -73.80023888568967,
                40.84817098129821
              ],
              [
                -73.80019221958115,
                40.84820178890101
              ],
              [
                -73.8001566945148,
                40.84823140574045
              ],
              [
                -73.80012554455553,
                40.84826373787394
              ],
              [
                -73.80009912704746,
                40.84829841440254
              ],
              [
                -73.80005311397095,
                40.84853352350942
              ],
              [
                -73.8000106592219,
                40.84858977848549
              ],
              [
                -73.79997376027083,
                40.84864824934954
              ],
              [
                -73.799942616454,
                40.848708620252836
              ],
              [
                -73.79991618964858,
                40.84877399556367
              ],
              [
                -73.79989651109994,
                40.848840728886024
              ],
              [
                -73.79988337043154,
                40.84891083980215
              ],
              [
                -73.7998776779705,
                40.848981525193935
              ],
              [
                -73.79985472391844,
                40.84908373299662
              ],
              [
                -73.79982410723423,
                40.84918477625115
              ],
              [
                -73.79978592816823,
                40.84928432398887
              ],
              [
                -73.79974031174332,
                40.8493820501384
              ],
              [
                -73.79968740734603,
                40.84947763459342
              ],
              [
                -73.79962738823652,
                40.84957076426158
              ],
              [
                -73.79956045098254,
                40.84966113408999
              ],
              [
                -73.79948681481507,
                40.84974844806467
              ],
              [
                -73.79940672091065,
                40.84983242018016
              ],
              [
                -73.79932043160208,
                40.8499127753765
              ],
              [
                -73.7981936599684,
                40.850592561406664
              ],
              [
                -73.79780522990592,
                40.85194959859714
              ],
              [
                -73.79795583310242,
                40.85298833071624
              ],
              [
                -73.79806903145645,
                40.8530561820021
              ],
              [
                -73.79818632879257,
                40.853119893012085
              ],
              [
                -73.7983074626901,
                40.85317932120463
              ],
              [
                -73.79843216214293,
                40.85323433361904
              ],
              [
                -73.7985601481648,
                40.85328480717451
              ],
              [
                -73.79864274330347,
                40.85335745992206
              ],
              [
                -73.79871889218126,
                40.85343406544942
              ],
              [
                -73.79878826756028,
                40.85351429457996
              ],
              [
                -73.79885057130656,
                40.85359780256494
              ],
              [
                -73.7989055356695,
                40.85368423056507
              ],
              [
                -73.79895292443402,
                40.85377320719199
              ],
              [
                -73.79899253393481,
                40.85386435010421
              ],
              [
                -73.7990241939332,
                40.85395726765013
              ],
              [
                -73.79904776834825,
                40.854051560550324
              ],
              [
                -73.7987225560008,
                40.85491440117259
              ],
              [
                -73.79853274127018,
                40.85500533260181
              ],
              [
                -73.79824950227362,
                40.854795985109554
              ],
              [
                -73.79798533480839,
                40.854848932595736
              ],
              [
                -73.79790796604266,
                40.855004944756715
              ],
              [
                -73.79782221312759,
                40.855158409255914
              ],
              [
                -73.79772822107861,
                40.855309066468664
              ],
              [
                -73.79762614885384,
                40.855456661517955
              ],
              [
                -73.79751616908338,
                40.85560094470548
              ],
              [
                -73.79739846777977,
                40.85574167193426
              ],
              [
                -73.79727324402177,
                40.855878605121866
              ],
              [
                -73.79714070961904,
                40.856011512603175
              ],
              [
                -73.79700108875328,
                40.856140169522234
              ],
              [
                -73.79685461759917,
                40.85626435821341
              ],
              [
                -73.79558880172944,
                40.85651323676668
              ],
              [
                -73.79489023542949,
                40.85656202421893
              ],
              [
                -73.79480897609665,
                40.85647889953375
              ],
              [
                -73.79476507532874,
                40.856459490399686
              ],
              [
                -73.79471761683845,
                40.8564457303821
              ],
              [
                -73.79466785137188,
                40.85643798211963
              ],
              [
                -73.79461709047264,
                40.85643644981377
              ],
              [
                -73.79456667191796,
                40.85644117384735
              ],
              [
                -73.7945179244621,
                40.856452029721105
              ],
              [
                -73.7944721328208,
                40.85646873133395
              ],
              [
                -73.79435005934272,
                40.85652702380392
              ],
              [
                -73.79430448203574,
                40.85657593402549
              ],
              [
                -73.79429396351048,
                40.85660753428865
              ],
              [
                -73.79428796644193,
                40.856639808509314
              ],
              [
                -73.79427176966915,
                40.85667882827607
              ],
              [
                -73.79426303171185,
                40.856719198544056
              ],
              [
                -73.79426192953805,
                40.85676010176765
              ],
              [
                -73.79426848548164,
                40.856800709606965
              ],
              [
                -73.79428256679108,
                40.85684019970247
              ],
              [
                -73.79430388831402,
                40.85687777232902
              ],
              [
                -73.79432902891644,
                40.8569094438096
              ],
              [
                -73.79435931592499,
                40.85693840310721
              ],
              [
                -73.79439424825077,
                40.85696417109349
              ],
              [
                -73.79443324794623,
                40.85698632143941
              ],
              [
                -73.79447566976818,
                40.857004487669094
              ],
              [
                -73.7945578714582,
                40.85717924058659
              ],
              [
                -73.79463354556832,
                40.85735568514508
              ],
              [
                -73.79470263177444,
                40.85753368081173
              ],
              [
                -73.7947650749976,
                40.85771308581657
              ],
              [
                -73.79482082544892,
                40.85789375726638
              ],
              [
                -73.794869838669,
                40.858075551258054
              ],
              [
                -73.79470951415546,
                40.85848231519075
              ],
              [
                -73.79454562318031,
                40.85888825916122
              ],
              [
                -73.79439746946176,
                40.859027443657986
              ],
              [
                -73.79425610311195,
                40.85917063977193
              ],
              [
                -73.7941217124408,
                40.859317656800634
              ],
              [
                -73.79409935372796,
                40.859450285063986
              ],
              [
                -73.79401054069923,
                40.859632852092886
              ],
              [
                -73.79386568469461,
                40.85977877482918
              ],
              [
                -73.79379355287108,
                40.859796236249466
              ],
              [
                -73.79364088707253,
                40.859833191400895
              ],
              [
                -73.79339985516695,
                40.85994848620095
              ],
              [
                -73.79335942097596,
                40.86005195896525
              ],
              [
                -73.79355684101755,
                40.86014838482354
              ],
              [
                -73.79355967957899,
                40.86014977213993
              ],
              [
                -73.79363962082937,
                40.860241278169745
              ],
              [
                -73.79362302791493,
                40.86041788353875
              ],
              [
                -73.79358268830747,
                40.86049090242635
              ],
              [
                -73.79351029757724,
                40.86055168129084
              ],
              [
                -73.79332560925523,
                40.86060616885451
              ],
              [
                -73.7932052338849,
                40.86061813531404
              ],
              [
                -73.79301479076307,
                40.86062800565798
              ],
              [
                -73.7928251816082,
                40.86064474264513
              ],
              [
                -73.79263683568297,
                40.86066830838418
              ],
              [
                -73.79245017939147,
                40.86069864952373
              ],
              [
                -73.79226563531348,
                40.86073569737327
              ],
              [
                -73.79208362124982,
                40.860779368058694
              ],
              [
                -73.791904549275,
                40.86082956271206
              ],
              [
                -73.79188014597733,
                40.86125563220965
              ],
              [
                -73.79147317119875,
                40.862207106249
              ],
              [
                -73.79265095767063,
                40.86413665318479
              ],
              [
                -73.79228914014455,
                40.865814377219436
              ],
              [
                -73.79139032586131,
                40.86814130746614
              ],
              [
                -73.79069663688344,
                40.86875677621221
              ],
              [
                -73.79038207692929,
                40.86899442562481
              ],
              [
                -73.78968197448623,
                40.86936131077263
              ],
              [
                -73.78901059796526,
                40.869663275452666
              ],
              [
                -73.78853953524599,
                40.86977069609063
              ],
              [
                -73.78795446145405,
                40.8698454190636
              ],
              [
                -73.78726970836335,
                40.86986581499132
              ],
              [
                -73.78657103927205,
                40.86977789988556
              ],
              [
                -73.78598679893318,
                40.86959273828636
              ],
              [
                -73.78568791368025,
                40.869386447791484
              ],
              [
                -73.78551744254952,
                40.86916956571913
              ],
              [
                -73.78551838553852,
                40.86887720666757
              ],
              [
                -73.78557645524876,
                40.868563295841604
              ],
              [
                -73.78560245305442,
                40.868341652223684
              ],
              [
                -73.78534746253581,
                40.86880109054053
              ],
              [
                -73.7852180023888,
                40.86913652413008
              ],
              [
                -73.78495998839328,
                40.86952585943926
              ],
              [
                -73.78490216077769,
                40.86976397324066
              ],
              [
                -73.78465917845698,
                40.86991511496513
              ],
              [
                -73.7842880274107,
                40.87000104674132
              ],
              [
                -73.78414454020398,
                40.870260656235736
              ],
              [
                -73.78391578371762,
                40.87042265137192
              ],
              [
                -73.78389734052207,
                40.87042695836958
              ],
              [
                -73.78350179966235,
                40.87051932877748
              ],
              [
                -73.78320175426292,
                40.870670360853765
              ],
              [
                -73.78318664230072,
                40.8709302097453
              ],
              [
                -73.78324331138717,
                40.87104942747412
              ],
              [
                -73.78324264074435,
                40.87125516094619
              ],
              [
                -73.78314218899501,
                40.8714390527097
              ],
              [
                -73.7830557962405,
                40.871680678238974
              ],
              [
                -73.78306853426027,
                40.872153575922695
              ],
              [
                -73.78342458843244,
                40.872327496290914
              ],
              [
                -73.78380906886679,
                40.872533952915106
              ],
              [
                -73.78403716971833,
                40.8728266012636
              ],
              [
                -73.78343142541377,
                40.87317870194881
              ],
              [
                -73.78333967922703,
                40.873512621378566
              ],
              [
                -73.78358738486025,
                40.87378095468029
              ],
              [
                -73.78380448210427,
                40.873736688343506
              ],
              [
                -73.78402157839528,
                40.87369242209927
              ],
              [
                -73.78439311743477,
                40.87278409492877
              ],
              [
                -73.78526152075709,
                40.87174532233235
              ],
              [
                -73.78555929452179,
                40.872152766694725
              ],
              [
                -73.78568099853844,
                40.87238726505711
              ],
              [
                -73.78517279045143,
                40.87311937546341
              ],
              [
                -73.78508139658601,
                40.8735899632257
              ],
              [
                -73.7852750909762,
                40.873543713800444
              ],
              [
                -73.78544759969013,
                40.873132563370994
              ],
              [
                -73.78573128788767,
                40.87280383386965
              ],
              [
                -73.7859914785657,
                40.87257050546376
              ],
              [
                -73.78624874452605,
                40.87238284677215
              ],
              [
                -73.78679051717079,
                40.872507015313424
              ],
              [
                -73.78719087560506,
                40.872730553729134
              ],
              [
                -73.78715932296441,
                40.87315738936014
              ],
              [
                -73.7870867847903,
                40.87353624221611
              ],
              [
                -73.78668493218761,
                40.87429347554254
              ],
              [
                -73.78596895445062,
                40.875136750127425
              ],
              [
                -73.78542459134646,
                40.875485302024416
              ],
              [
                -73.78470964026441,
                40.87635799484767
              ],
              [
                -73.7841773836883,
                40.87772135392849
              ],
              [
                -73.7843816810755,
                40.878420531670514
              ],
              [
                -73.78588496976647,
                40.87906723175265
              ],
              [
                -73.78673946461805,
                40.879031707195864
              ],
              [
                -73.78694087806699,
                40.879004217266434
              ],
              [
                -73.78712640734612,
                40.87898290341337
              ],
              [
                -73.78741169652315,
                40.87899425712939
              ],
              [
                -73.78775415489733,
                40.87897323005541
              ],
              [
                -73.78827313470907,
                40.87887357315418
              ],
              [
                -73.789523093688,
                40.87899812706049
              ],
              [
                -73.79006332860774,
                40.879594661514844
              ],
              [
                -73.78912020636906,
                40.88008021099346
              ],
              [
                -73.78931835780448,
                40.88057867009183
              ],
              [
                -73.78926161019805,
                40.88120541462362
              ],
              [
                -73.7895587294431,
                40.88126128491542
              ],
              [
                -73.78978716776687,
                40.881207560463345
              ],
              [
                -73.78986765156738,
                40.881169184109304
              ],
              [
                -73.79005864434492,
                40.8810781162332
              ],
              [
                -73.7902301522649,
                40.88098097375842
              ],
              [
                -73.79043046503072,
                40.880797258029226
              ],
              [
                -73.79047377660959,
                40.880634913732
              ],
              [
                -73.79048858865667,
                40.88046168983179
              ],
              [
                -73.790574766816,
                40.88027776709316
              ],
              [
                -73.79068927478805,
                40.88015886427821
              ],
              [
                -73.79090368014784,
                40.880029315198314
              ],
              [
                -73.79127474265381,
                40.87998667328982
              ],
              [
                -73.7914602395418,
                40.87997618076568
              ],
              [
                -73.79174583941239,
                40.879890071652405
              ],
              [
                -73.79188890967126,
                40.87976039082595
              ],
              [
                -73.79218928228387,
                40.879511883286014
              ],
              [
                -73.79258921077623,
                40.87936100860247
              ],
              [
                -73.79288910674136,
                40.879264092822304
              ],
              [
                -73.79299880220003,
                40.87914686267464
              ],
              [
                -73.7938470278817,
                40.87859445979086
              ],
              [
                -73.79440294586227,
                40.87764184299902
              ],
              [
                -73.79422169990922,
                40.87738237046632
              ],
              [
                -73.79316703778704,
                40.877055639066455
              ],
              [
                -73.79359605330511,
                40.87672073272589
              ],
              [
                -73.79357210883583,
                40.87612105830348
              ],
              [
                -73.79397363837722,
                40.8745665945684
              ],
              [
                -73.79479044681598,
                40.87320048403398
              ],
              [
                -73.79693375040256,
                40.87262338150215
              ],
              [
                -73.79780377219292,
                40.87225613227397
              ],
              [
                -73.79857728261854,
                40.87119632057149
              ],
              [
                -73.80069778002715,
                40.86808147781558
              ],
              [
                -73.80225816157765,
                40.8656082064109
              ],
              [
                -73.80306041484539,
                40.86581513370676
              ],
              [
                -73.80300388108948,
                40.86704183656887
              ],
              [
                -73.80385280781442,
                40.86716648480004
              ],
              [
                -73.80464604502912,
                40.86664711643685
              ],
              [
                -73.80487266169933,
                40.86823330283224
              ],
              [
                -73.80593179559793,
                40.86837193206786
              ],
              [
                -73.80673074905101,
                40.868770013078354
              ],
              [
                -73.80695275224407,
                40.86965794817595
              ],
              [
                -73.80780673194735,
                40.86978752042846
              ],
              [
                -73.80754063636171,
                40.870377552173395
              ],
              [
                -73.80838731046325,
                40.87057162161172
              ],
              [
                -73.80841596818787,
                40.87118969336753
              ],
              [
                -73.8057448608033,
                40.869692576552794
              ],
              [
                -73.80588410012888,
                40.869068502184916
              ],
              [
                -73.80554495995769,
                40.86895600501215
              ],
              [
                -73.80483122145964,
                40.86931318178597
              ],
              [
                -73.80422027051749,
                40.86900214816814
              ],
              [
                -73.80302203685277,
                40.869155119796496
              ],
              [
                -73.8028295989319,
                40.870076294030426
              ],
              [
                -73.80358314939251,
                40.86994806102859
              ],
              [
                -73.80373339399281,
                40.87096260282379
              ],
              [
                -73.80431849468226,
                40.871159741067984
              ],
              [
                -73.8033911752309,
                40.87250503763109
              ],
              [
                -73.80269939786251,
                40.87221469129425
              ],
              [
                -73.80317726501715,
                40.871683155070606
              ],
              [
                -73.80252284756597,
                40.87050664469678
              ],
              [
                -73.8013723540985,
                40.87146104310755
              ],
              [
                -73.80087270458245,
                40.87159011989393
              ],
              [
                -73.80027274375148,
                40.872820951824174
              ],
              [
                -73.79987713793562,
                40.87348466729266
              ],
              [
                -73.79981389580328,
                40.87415984971623
              ],
              [
                -73.79891245463618,
                40.87464589314291
              ],
              [
                -73.7985533078771,
                40.87440142827449
              ],
              [
                -73.79743815457286,
                40.87513533295314
              ],
              [
                -73.7972039970304,
                40.87578579298386
              ],
              [
                -73.79712701174677,
                40.87619040090393
              ],
              [
                -73.7962509753578,
                40.8770119997785
              ],
              [
                -73.79502596232832,
                40.87974779721172
              ],
              [
                -73.79554579908852,
                40.88069101537498
              ],
              [
                -73.7949170558667,
                40.88054379098488
              ],
              [
                -73.7937923749044,
                40.881970326764964
              ],
              [
                -73.79358741929856,
                40.8825175238832
              ],
              [
                -73.79322870891143,
                40.88282259495222
              ],
              [
                -73.79416555799986,
                40.88299321599987
              ],
              [
                -73.79449254399985,
                40.88306825099997
              ],
              [
                -73.79744825599991,
                40.88384538399988
              ],
              [
                -73.79937520399986,
                40.884351969999976
              ],
              [
                -73.803286821,
                40.885415552999916
              ],
              [
                -73.80520113699998,
                40.88599995599985
              ],
              [
                -73.80550576700001,
                40.886092948999895
              ],
              [
                -73.80571277199984,
                40.88603936799991
              ],
              [
                -73.80592498899998,
                40.885996717999944
              ],
              [
                -73.80614087499991,
                40.88596540999987
              ],
              [
                -73.80635884999995,
                40.88594565899991
              ],
              [
                -73.80657730199997,
                40.885937524999896
              ],
              [
                -73.80693113299986,
                40.88592660899994
              ],
              [
                -73.80728647099995,
                40.88593181399989
              ]
            ]
          ],
          [
            [
              [
                -73.78650554049733,
                40.88094013447924
              ],
              [
                -73.78691765981317,
                40.880944795188604
              ],
              [
                -73.787127834652,
                40.880923463171264
              ],
              [
                -73.78742531437602,
                40.880447373965616
              ],
              [
                -73.7873445170899,
                40.88007294498983
              ],
              [
                -73.78742039384403,
                40.87977089028999
              ],
              [
                -73.78726793709994,
                40.87968270383201
              ],
              [
                -73.7869998480519,
                40.87982153242358
              ],
              [
                -73.78679741033042,
                40.87994707381406
              ],
              [
                -73.78677238818247,
                40.88013589624925
              ],
              [
                -73.78692034479134,
                40.88027335060641
              ],
              [
                -73.78684884910366,
                40.88044811129195
              ],
              [
                -73.78664008476228,
                40.88049983743608
              ],
              [
                -73.78640732130829,
                40.880410113772506
              ],
              [
                -73.78609930496378,
                40.88038657172328
              ],
              [
                -73.785981809052,
                40.88031748451099
              ],
              [
                -73.78578002611279,
                40.88036396266135
              ],
              [
                -73.78572615707054,
                40.88057541460278
              ],
              [
                -73.78582190502614,
                40.88075509610013
              ],
              [
                -73.78598849185093,
                40.88076643617943
              ],
              [
                -73.78613451219806,
                40.88074604087828
              ],
              [
                -73.78650554049733,
                40.88094013447924
              ]
            ]
          ],
          [
            [
              [
                -73.78103351104939,
                40.87648400204775
              ],
              [
                -73.78112767478156,
                40.87665268050811
              ],
              [
                -73.78132704118002,
                40.87636132654636
              ],
              [
                -73.78120649649391,
                40.87628502546014
              ],
              [
                -73.78103351104939,
                40.87648400204775
              ]
            ]
          ],
          [
            [
              [
                -73.78241811865315,
                40.87492327042175
              ],
              [
                -73.78247604125525,
                40.87516813337885
              ],
              [
                -73.78266372854155,
                40.875136564444894
              ],
              [
                -73.78280207510406,
                40.87494886620542
              ],
              [
                -73.78265785360905,
                40.87479265669399
              ],
              [
                -73.78241811865315,
                40.87492327042175
              ]
            ]
          ],
          [
            [
              [
                -73.77435244645233,
                40.87416995587479
              ],
              [
                -73.77425306035093,
                40.87428435764292
              ],
              [
                -73.7740318153443,
                40.874394774956215
              ],
              [
                -73.77397610935063,
                40.87459940192133
              ],
              [
                -73.77415170184918,
                40.87469267688606
              ],
              [
                -73.77407613213158,
                40.87481473400711
              ],
              [
                -73.77409538292126,
                40.87489618448014
              ],
              [
                -73.77420264266921,
                40.87488112230395
              ],
              [
                -73.77453640510954,
                40.87471001543833
              ],
              [
                -73.77455324011393,
                40.87454877066337
              ],
              [
                -73.77451315255762,
                40.87446495533306
              ],
              [
                -73.77457138994558,
                40.87433206752441
              ],
              [
                -73.77460919866006,
                40.874202172627676
              ],
              [
                -73.77458142278213,
                40.87410913390799
              ],
              [
                -73.7744796961532,
                40.87407767028597
              ],
              [
                -73.77435244645233,
                40.87416995587479
              ]
            ]
          ],
          [
            [
              [
                -73.78605394964902,
                40.873782646192794
              ],
              [
                -73.7861946371647,
                40.873885319414654
              ],
              [
                -73.78632872330336,
                40.87380492844096
              ],
              [
                -73.78633325996694,
                40.873677724887465
              ],
              [
                -73.78618924358646,
                40.87359972769974
              ],
              [
                -73.78605394964902,
                40.873782646192794
              ]
            ]
          ],
          [
            [
              [
                -73.78648510546586,
                40.873209254950424
              ],
              [
                -73.78632869488987,
                40.8732711445541
              ],
              [
                -73.78626202153426,
                40.87339368663393
              ],
              [
                -73.786499866474,
                40.87348161860737
              ],
              [
                -73.78665449887535,
                40.873393637760984
              ],
              [
                -73.78672689095349,
                40.87307453926568
              ],
              [
                -73.78662489062734,
                40.873034648034434
              ],
              [
                -73.78648510546586,
                40.873209254950424
              ]
            ]
          ],
          [
            [
              [
                -73.77080975398194,
                40.871549946847985
              ],
              [
                -73.77217786492288,
                40.87198111068936
              ],
              [
                -73.77288042665229,
                40.871245263430254
              ],
              [
                -73.77109611675694,
                40.87069300521903
              ],
              [
                -73.76990710668542,
                40.87047694447026
              ],
              [
                -73.77080975398194,
                40.871549946847985
              ]
            ]
          ],
          [
            [
              [
                -73.76668965841924,
                40.867097781624494
              ],
              [
                -73.76760072096499,
                40.86749638654143
              ],
              [
                -73.76779449382023,
                40.86678117376547
              ],
              [
                -73.76709907439783,
                40.866704572748205
              ],
              [
                -73.76668965841924,
                40.867097781624494
              ]
            ]
          ],
          [
            [
              [
                -73.76964988627088,
                40.86548557242442
              ],
              [
                -73.770068972998,
                40.86552587618341
              ],
              [
                -73.77022931630273,
                40.86513893939602
              ],
              [
                -73.76979148260493,
                40.865128233564434
              ],
              [
                -73.76964988627088,
                40.86548557242442
              ]
            ]
          ],
          [
            [
              [
                -73.78401249138903,
                40.863131994074394
              ],
              [
                -73.78404441686625,
                40.8631401403623
              ],
              [
                -73.78408589281415,
                40.863159219008956
              ],
              [
                -73.78410980767785,
                40.86317422294244
              ],
              [
                -73.78414276900331,
                40.863191264967305
              ],
              [
                -73.78416434113748,
                40.86319940006106
              ],
              [
                -73.78418478751462,
                40.863207111388306
              ],
              [
                -73.78421458891884,
                40.86321323131151
              ],
              [
                -73.78423747960105,
                40.863216104429355
              ],
              [
                -73.78425664943187,
                40.86321614027492
              ],
              [
                -73.78426981898308,
                40.86321401741422
              ],
              [
                -73.7842865050008,
                40.86320508878048
              ],
              [
                -73.78428784760068,
                40.863185330546045
              ],
              [
                -73.78427596567165,
                40.863169681063454
              ],
              [
                -73.78425470292191,
                40.86315548573396
              ],
              [
                -73.78420682075179,
                40.86313618660031
              ],
              [
                -73.7841682767837,
                40.863111848550936
              ],
              [
                -73.78416702363967,
                40.86308757924547
              ],
              [
                -73.78419106476986,
                40.863066391104326
              ],
              [
                -73.78422440861301,
                40.86305128839422
              ],
              [
                -73.78425378453589,
                40.86302808686685
              ],
              [
                -73.78427247111449,
                40.86301497907962
              ],
              [
                -73.7842937482678,
                40.86302512924161
              ],
              [
                -73.78430301799293,
                40.863041323316814
              ],
              [
                -73.78432954841307,
                40.86307372807705
              ],
              [
                -73.78435811804623,
                40.863073517960906
              ],
              [
                -73.78439355912623,
                40.86304755890363
              ],
              [
                -73.78441622565995,
                40.86303951254887
              ],
              [
                -73.7844482486985,
                40.86302137278443
              ],
              [
                -73.78447228643577,
                40.863001196420726
              ],
              [
                -73.78449501214256,
                40.86297495006532
              ],
              [
                -73.78451638211648,
                40.8629557792117
              ],
              [
                -73.78452979565623,
                40.86292648311367
              ],
              [
                -73.78455255057246,
                40.86289113759077
              ],
              [
                -73.7845673217424,
                40.86285375481233
              ],
              [
                -73.78456744948141,
                40.862814322346935
              ],
              [
                -73.78456087516444,
                40.86278802243997
              ],
              [
                -73.78454650079381,
                40.862773764806754
              ],
              [
                -73.78453969720576,
                40.86274753851558
              ],
              [
                -73.7845398085792,
                40.86271316238302
              ],
              [
                -73.78454392939913,
                40.86267474804279
              ],
              [
                -73.78454667485435,
                40.86264947603923
              ],
              [
                -73.7845773613989,
                40.86263234491482
              ],
              [
                -73.784602707706,
                40.862619247832065
              ],
              [
                -73.7846306682256,
                40.8626213228734
              ],
              [
                -73.78464133697304,
                40.862622861723345
              ],
              [
                -73.78465196734292,
                40.86262439547496
              ],
              [
                -73.78467197341119,
                40.86261533360189
              ],
              [
                -73.78468005226556,
                40.862588048700914
              ],
              [
                -73.78469476742806,
                40.862567854389425
              ],
              [
                -73.78469165850701,
                40.86254278990822
              ],
              [
                -73.78466024197864,
                40.86253644596146
              ],
              [
                -73.78463228171132,
                40.862534371932846
              ],
              [
                -73.78458968070599,
                40.86252923721539
              ],
              [
                -73.7845523718973,
                40.862535233919864
              ],
              [
                -73.78452699591001,
                40.86255743014714
              ],
              [
                -73.78449766115587,
                40.86256849741921
              ],
              [
                -73.78447101878139,
                40.86257046999977
              ],
              [
                -73.78444173884387,
                40.86256434843543
              ],
              [
                -73.78440446211489,
                40.862560235271445
              ],
              [
                -73.78435921788362,
                40.862549029165784
              ],
              [
                -73.78432861001711,
                40.862541893918575
              ],
              [
                -73.78430733389965,
                40.86253174259113
              ],
              [
                -73.78425940296712,
                40.86252760845761
              ],
              [
                -73.78423274486083,
                40.862534636647986
              ],
              [
                -73.78420876955407,
                40.862535602480506
              ],
              [
                -73.78418483033813,
                40.86252544665239
              ],
              [
                -73.78416090425225,
                40.862511247494524
              ],
              [
                -73.78413030583008,
                40.86250107869518
              ],
              [
                -73.78410369695696,
                40.862492940367574
              ],
              [
                -73.7840757656106,
                40.86248176587436
              ],
              [
                -73.78403314760357,
                40.86248168611115
              ],
              [
                -73.78401316513425,
                40.862483671051514
              ],
              [
                -73.78398912415656,
                40.86250485966031
              ],
              [
                -73.78397177545816,
                40.862515948735506
              ],
              [
                -73.78393983203142,
                40.86250982255919
              ],
              [
                -73.78391453469644,
                40.862507752337905
              ],
              [
                -73.78389450817735,
                40.862522882081066
              ],
              [
                -73.78389574150354,
                40.86255321621144
              ],
              [
                -73.78388769190262,
                40.862571401231904
              ],
              [
                -73.7838597152167,
                40.8625743816744
              ],
              [
                -73.78382641096285,
                40.86257735330138
              ],
              [
                -73.78379307646343,
                40.86258942357059
              ],
              [
                -73.78376770348979,
                40.86261060846435
              ],
              [
                -73.78375829499413,
                40.86263687913513
              ],
              [
                -73.78375691707258,
                40.86265103263053
              ],
              [
                -73.78373688873616,
                40.86266717183979
              ],
              [
                -73.78372750718738,
                40.86268535384969
              ],
              [
                -73.78372741840673,
                40.86271265366126
              ],
              [
                -73.78372199876624,
                40.86274095347989
              ],
              [
                -73.78371130197019,
                40.862754077831326
              ],
              [
                -73.78369528417225,
                40.86276516935951
              ],
              [
                -73.78368722462388,
                40.862786387863814
              ],
              [
                -73.78368048381539,
                40.86281165168633
              ],
              [
                -73.7836724235964,
                40.86283286968554
              ],
              [
                -73.78367633617697,
                40.86285815465367
              ],
              [
                -73.78369627734214,
                40.862869313593926
              ],
              [
                -73.7837202166094,
                40.862879468852725
              ],
              [
                -73.7837507750274,
                40.862901770915116
              ],
              [
                -73.78376405410458,
                40.862913929031954
              ],
              [
                -73.783777332747,
                40.86292608664389
              ],
              [
                -73.78379858786646,
                40.862942303722576
              ],
              [
                -73.78380117343677,
                40.862966574524414
              ],
              [
                -73.78377317859609,
                40.862974611276094
              ],
              [
                -73.78374256752757,
                40.862968487542936
              ],
              [
                -73.7837185262937,
                40.86298967441828
              ],
              [
                -73.78370779897656,
                40.8630118990948
              ],
              [
                -73.78368644190898,
                40.86302702463133
              ],
              [
                -73.78364647814364,
                40.86302998372141
              ],
              [
                -73.78361720203176,
                40.86302285078349
              ],
              [
                -73.78358659292192,
                40.86301571584049
              ],
              [
                -73.78356394999163,
                40.86301668454098
              ],
              [
                -73.78354391074059,
                40.863035857714856
              ],
              [
                -73.78354252311605,
                40.86305304353275
              ],
              [
                -73.78353980995178,
                40.8630682046868
              ],
              [
                -73.78353843789931,
                40.863080334672304
              ],
              [
                -73.7835410529967,
                40.86309550632483
              ],
              [
                -73.78355166102793,
                40.86310968179935
              ],
              [
                -73.78356481886246,
                40.863116312438635
              ],
              [
                -73.78359127698181,
                40.863126757908645
              ],
              [
                -73.78360828124866,
                40.86313811003793
              ],
              [
                -73.78363488084095,
                40.863146245278166
              ],
              [
                -73.78366256726379,
                40.863147915470165
              ],
              [
                -73.78368763328497,
                40.86313623765822
              ],
              [
                -73.78369885872442,
                40.8631348890298
              ],
              [
                -73.78371746749217,
                40.86313265463886
              ],
              [
                -73.78375633278242,
                40.86313555729302
              ],
              [
                -73.7837787415074,
                40.86314595995069
              ],
              [
                -73.78379568035903,
                40.86315382459153
              ],
              [
                -73.78381322544635,
                40.863162751962605
              ],
              [
                -73.78383612396978,
                40.86316279486427
              ],
              [
                -73.78385122027875,
                40.86315600527144
              ],
              [
                -73.78386118864937,
                40.8631515220842
              ],
              [
                -73.78387081440718,
                40.86313941124134
              ],
              [
                -73.78389534027481,
                40.86313015811382
              ],
              [
                -73.78395232143296,
                40.86313026482231
              ],
              [
                -73.78401249138903,
                40.863131994074394
              ]
            ]
          ],
          [
            [
              [
                -73.78312589594455,
                40.862856167300684
              ],
              [
                -73.78316714445815,
                40.86286121709221
              ],
              [
                -73.78318905689552,
                40.8628438875819
              ],
              [
                -73.78320868897492,
                40.86282115717827
              ],
              [
                -73.78322307739761,
                40.86280732615862
              ],
              [
                -73.78324136063814,
                40.862798450598234
              ],
              [
                -73.78325309247046,
                40.86279946353931
              ],
              [
                -73.78326872514111,
                40.86280345182183
              ],
              [
                -73.78328053505923,
                40.86278070671259
              ],
              [
                -73.7832805769957,
                40.86276783796488
              ],
              [
                -73.78327150474333,
                40.862750992510286
              ],
              [
                -73.78326241313623,
                40.86274008723734
              ],
              [
                -73.78324935830236,
                40.862745012533196
              ],
              [
                -73.78323629034399,
                40.86275389621756
              ],
              [
                -73.78321153015568,
                40.862749890111346
              ],
              [
                -73.78323114283971,
                40.862733099215696
              ],
              [
                -73.7832585692753,
                40.86271929218303
              ],
              [
                -73.78325863057353,
                40.862700483922836
              ],
              [
                -73.78324441706522,
                40.86266086151539
              ],
              [
                -73.783230148944,
                40.8626380669149
              ],
              [
                -73.78321713842091,
                40.86262913356664
              ],
              [
                -73.78318585915916,
                40.86262511587645
              ],
              [
                -73.78316056266704,
                40.86262521709972
              ],
              [
                -73.783142831741,
                40.86262503501525
              ],
              [
                -73.78312068770266,
                40.86261806411566
              ],
              [
                -73.78313379182322,
                40.862598290641344
              ],
              [
                -73.783159423968,
                40.862584371536556
              ],
              [
                -73.78315909842297,
                40.86257268615447
              ],
              [
                -73.78317178126751,
                40.86254391687244
              ],
              [
                -73.78317316493471,
                40.862519172720496
              ],
              [
                -73.7831745376775,
                40.86249838796806
              ],
              [
                -73.78319152877746,
                40.86248555106949
              ],
              [
                -73.78320980870355,
                40.86247766640956
              ],
              [
                -73.78322682047566,
                40.86245888999568
              ],
              [
                -73.7832255845896,
                40.862438100341336
              ],
              [
                -73.78322562653649,
                40.86242523159286
              ],
              [
                -73.78322046681964,
                40.86240839298142
              ],
              [
                -73.78321923261986,
                40.86238661309795
              ],
              [
                -73.78321931974057,
                40.8623598863801
              ],
              [
                -73.7832284861612,
                40.86234802450366
              ],
              [
                -73.78323505687578,
                40.862332198832505
              ],
              [
                -73.78323379517936,
                40.86231932763464
              ],
              [
                -73.78321820970366,
                40.862300490479086
              ],
              [
                -73.78320916011666,
                40.86227671645224
              ],
              [
                -73.78320536214062,
                40.86224206225332
              ],
              [
                -73.78319889402896,
                40.86222621208387
              ],
              [
                -73.78318983477908,
                40.86220540722528
              ],
              [
                -73.7831885989095,
                40.862184617569675
              ],
              [
                -73.78319519264798,
                40.862161862664934
              ],
              [
                -73.7831965836362,
                40.86213513839592
              ],
              [
                -73.78317971460484,
                40.86211035877071
              ],
              [
                -73.78315761269366,
                40.86209051912838
              ],
              [
                -73.7831450428813,
                40.862068678905565
              ],
              [
                -73.78310610268183,
                40.862106670424794
              ],
              [
                -73.78308839527007,
                40.86212394684795
              ],
              [
                -73.78308699864493,
                40.862153739132445
              ],
              [
                -73.78308042401895,
                40.86217055569048
              ],
              [
                -73.7830432358342,
                40.86216800507987
              ],
              [
                -73.78303721101206,
                40.862173883880736
              ],
              [
                -73.78301584621748,
                40.86219472719624
              ],
              [
                -73.78304897317251,
                40.86221900100554
              ],
              [
                -73.78305278875975,
                40.86224870475323
              ],
              [
                -73.78305399875154,
                40.86227741454213
              ],
              [
                -73.78302916358793,
                40.86229617621972
              ],
              [
                -73.78299917499666,
                40.86229611982303
              ],
              [
                -73.78297566695083,
                40.86230795353379
              ],
              [
                -73.7829691084348,
                40.86231982029563
              ],
              [
                -73.78294302128242,
                40.86232274158374
              ],
              [
                -73.78290913725111,
                40.86231772801563
              ],
              [
                -73.78289032081526,
                40.86231719372547
              ],
              [
                -73.78289575072031,
                40.8623500601781
              ],
              [
                -73.78289839828749,
                40.86237138655792
              ],
              [
                -73.7828974421713,
                40.86238720417944
              ],
              [
                -73.7828819783119,
                40.862406433091266
              ],
              [
                -73.78288192666942,
                40.86242225124191
              ],
              [
                -73.7828818862492,
                40.86243463199707
              ],
              [
                -73.78289634979612,
                40.86244428763149
              ],
              [
                -73.78290536622264,
                40.862457373279604
              ],
              [
                -73.78290619366129,
                40.86248144697154
              ],
              [
                -73.78289979786094,
                40.862497941037
              ],
              [
                -73.78289883055704,
                40.86251650937445
              ],
              [
                -73.7828996766172,
                40.8625350811239
              ],
              [
                -73.78290505414365,
                40.86255297354776
              ],
              [
                -73.78290043669504,
                40.86257978890438
              ],
              [
                -73.78290218565189,
                40.86259904953427
              ],
              [
                -73.78289306367382,
                40.86261829037946
              ],
              [
                -73.78289118224538,
                40.8626396082364
              ],
              [
                -73.78290014705881,
                40.86266851203449
              ],
              [
                -73.78291186723298,
                40.862685728539645
              ],
              [
                -73.78292445681086,
                40.86271464032812
              ],
              [
                -73.78291897441507,
                40.862729072054314
              ],
              [
                -73.7829161867475,
                40.862750388373655
              ],
              [
                -73.7829278359814,
                40.86278961449438
              ],
              [
                -73.78293775624341,
                40.86280338851873
              ],
              [
                -73.7829558947177,
                40.86279723281158
              ],
              [
                -73.78296318511775,
                40.86278417784315
              ],
              [
                -73.78297408257099,
                40.86277594495668
              ],
              [
                -73.78299672466267,
                40.86277805109834
              ],
              [
                -73.78300940115757,
                40.862780138326855
              ],
              [
                -73.7830102575906,
                40.862795271002454
              ],
              [
                -73.78301654119176,
                40.86281316512423
              ],
              [
                -73.7830355109771,
                40.86282970756513
              ],
              [
                -73.78305814187065,
                40.86283525259774
              ],
              [
                -73.78307171542822,
                40.86284009291337
              ],
              [
                -73.78308802390593,
                40.86283943572183
              ],
              [
                -73.78310886092432,
                40.862839474892525
              ],
              [
                -73.78312589594455,
                40.862856167300684
              ]
            ]
          ],
          [
            [
              [
                -73.77460156350928,
                40.862069047459734
              ],
              [
                -73.77462582015613,
                40.862062930810644
              ],
              [
                -73.77464467344878,
                40.86206296763552
              ],
              [
                -73.77466262361408,
                40.86206436516329
              ],
              [
                -73.7746796809878,
                40.86206439847501
              ],
              [
                -73.77470842425795,
                40.86206036501546
              ],
              [
                -73.77472549317646,
                40.86205698990684
              ],
              [
                -73.77474885077193,
                40.862052264100676
              ],
              [
                -73.77477043454223,
                40.862041400948804
              ],
              [
                -73.77478574578609,
                40.86202711778363
              ],
              [
                -73.77480913710158,
                40.86201216856554
              ],
              [
                -73.77483701776669,
                40.86199791042756
              ],
              [
                -73.77485681624742,
                40.86198363433427
              ],
              [
                -73.77486136712257,
                40.861965241579526
              ],
              [
                -73.7748685983656,
                40.861950940961435
              ],
              [
                -73.77487587189495,
                40.861923691349936
              ],
              [
                -73.77487680292438,
                40.86191415152209
              ],
              [
                -73.77487686060509,
                40.8618971119668
              ],
              [
                -73.77486614039097,
                40.86188141435422
              ],
              [
                -73.77486709592125,
                40.86186437655069
              ],
              [
                -73.77487254969277,
                40.8618446209093
              ],
              [
                -73.77488877354436,
                40.8618255674319
              ],
              [
                -73.77491036622386,
                40.86181197832623
              ],
              [
                -73.77494010924667,
                40.86177727569181
              ],
              [
                -73.77495447620205,
                40.86177662139126
              ],
              [
                -73.7749688747791,
                40.861766426008586
              ],
              [
                -73.7749743266488,
                40.86174735150945
              ],
              [
                -73.77499681899609,
                40.861733082320825
              ],
              [
                -73.77501840657533,
                40.86172085498463
              ],
              [
                -73.77503283049055,
                40.861703162792296
              ],
              [
                -73.77503496253725,
                40.861685922567034
              ],
              [
                -73.7750620542956,
                40.861661972262645
              ],
              [
                -73.7750931670466,
                40.86164807115778
              ],
              [
                -73.7751048844543,
                40.861634461592736
              ],
              [
                -73.77513862733318,
                40.86159428114062
              ],
              [
                -73.77515266437278,
                40.86157593818726
              ],
              [
                -73.77515720845145,
                40.86155958852497
              ],
              [
                -73.77516262896701,
                40.86154937562171
              ],
              [
                -73.77517525592451,
                40.861532360558094
              ],
              [
                -73.77518699402977,
                40.86151261714614
              ],
              [
                -73.77519783801257,
                40.861491508515684
              ],
              [
                -73.77522481965403,
                40.86147724786151
              ],
              [
                -73.7752311396364,
                40.861466354885955
              ],
              [
                -73.77525273004983,
                40.86145344635983
              ],
              [
                -73.77527340232487,
                40.86144667092544
              ],
              [
                -73.7752770457749,
                40.861431002497994
              ],
              [
                -73.77527711947938,
                40.86140919039076
              ],
              [
                -73.77529156793233,
                40.86138399968669
              ],
              [
                -73.77529520995593,
                40.861369012406726
              ],
              [
                -73.77530333426999,
                40.86135607814727
              ],
              [
                -73.77530517118274,
                40.861343812291516
              ],
              [
                -73.77530522414426,
                40.86132813687054
              ],
              [
                -73.77530528862722,
                40.86130905136013
              ],
              [
                -73.77530443892574,
                40.86129473665108
              ],
              [
                -73.7753054060716,
                40.861274290428604
              ],
              [
                -73.77530545442977,
                40.86125997746839
              ],
              [
                -73.77530191604177,
                40.86124429388045
              ],
              [
                -73.77529297955125,
                40.86123200821588
              ],
              [
                -73.77527954829246,
                40.86122175741748
              ],
              [
                -73.77526521436943,
                40.861212869004625
              ],
              [
                -73.77525088505807,
                40.86120261712371
              ],
              [
                -73.77523206568029,
                40.861192356494044
              ],
              [
                -73.77521233827294,
                40.86118550286396
              ],
              [
                -73.77519080850668,
                40.8611806895072
              ],
              [
                -73.77516742171098,
                40.86117626888893
              ],
              [
                -73.7751378266812,
                40.86118467534819
              ],
              [
                -73.77512255323983,
                40.86118805451983
              ],
              [
                -73.77511532368617,
                40.86120235298102
              ],
              [
                -73.77510807711035,
                40.86122142332018
              ],
              [
                -73.77510711704002,
                40.861239824761384
              ],
              [
                -73.77510436129421,
                40.861258222032106
              ],
              [
                -73.77510007843742,
                40.86128378399368
              ],
              [
                -73.77508178153292,
                40.86129839240863
              ],
              [
                -73.77507096157976,
                40.861312003219034
              ],
              [
                -73.77506102234307,
                40.86133106830559
              ],
              [
                -73.77505473165346,
                40.86133310083516
              ],
              [
                -73.77503944137759,
                40.8613412496968
              ],
              [
                -73.77502326610856,
                40.86134598956684
              ],
              [
                -73.7750133716438,
                40.86135142350354
              ],
              [
                -73.77500438446661,
                40.86135413142098
              ],
              [
                -73.7749854888804,
                40.86136704532386
              ],
              [
                -73.77497201524237,
                40.861369064342945
              ],
              [
                -73.77495940759927,
                40.86138062619217
              ],
              [
                -73.7749432115575,
                40.8613914998895
              ],
              [
                -73.77493509683372,
                40.86140170870001
              ],
              [
                -73.7749287867994,
                40.861409193242494
              ],
              [
                -73.77492784743957,
                40.86142145967052
              ],
              [
                -73.77491794834289,
                40.86142825656257
              ],
              [
                -73.7749179068177,
                40.86144052591441
              ],
              [
                -73.77492058397516,
                40.86144530154081
              ],
              [
                -73.7749268524907,
                40.861450086183176
              ],
              [
                -73.77492862302375,
                40.861457585985775
              ],
              [
                -73.77492858842464,
                40.861467809384756
              ],
              [
                -73.77492942815577,
                40.861484851361986
              ],
              [
                -73.77492845924615,
                40.86150597923115
              ],
              [
                -73.77492928975407,
                40.86152574663276
              ],
              [
                -73.7749318896738,
                40.86155230286411
              ],
              [
                -73.77490045511236,
                40.86155704310424
              ],
              [
                -73.7748743968104,
                40.86156380846539
              ],
              [
                -73.77484472768987,
                40.861576700653266
              ],
              [
                -73.77484645161898,
                40.861597833943854
              ],
              [
                -73.77484549586673,
                40.861614871747584
              ],
              [
                -73.77483644633928,
                40.86163598284136
              ],
              [
                -73.77481933138,
                40.8616529891088
              ],
              [
                -73.77482736581815,
                40.861666637193984
              ],
              [
                -73.77481833935344,
                40.861680933301336
              ],
              [
                -73.77481468588563,
                40.86169932881202
              ],
              [
                -73.7748029483004,
                40.86171907168417
              ],
              [
                -73.77477862120502,
                40.86174492486485
              ],
              [
                -73.77475796560724,
                40.86174692933692
              ],
              [
                -73.77476056449404,
                40.86177488053859
              ],
              [
                -73.77473987426,
                40.86178710790324
              ],
              [
                -73.77471472524364,
                40.86179046656734
              ],
              [
                -73.7746895827087,
                40.861791781115016
              ],
              [
                -73.77466976742645,
                40.86181082688054
              ],
              [
                -73.77465542355712,
                40.86180466498846
              ],
              [
                -73.77463383677213,
                40.86181689058305
              ],
              [
                -73.77462479638463,
                40.86183527673784
              ],
              [
                -73.77462471322954,
                40.86185981426719
              ],
              [
                -73.77461024990187,
                40.86188909343952
              ],
              [
                -73.7745975950102,
                40.86191428772824
              ],
              [
                -73.77458855460326,
                40.8619326727072
              ],
              [
                -73.77457410741187,
                40.86195718217302
              ],
              [
                -73.77457403579132,
                40.86197831128814
              ],
              [
                -73.77457307831463,
                40.861996030906475
              ],
              [
                -73.77457032723042,
                40.86201306519905
              ],
              [
                -73.77457833789663,
                40.862033528953766
              ],
              [
                -73.77458187384889,
                40.86204989320576
              ],
              [
                -73.77460156350928,
                40.862069047459734
              ]
            ]
          ],
          [
            [
              [
                -73.77290231992438,
                40.86120858327854
              ],
              [
                -73.77310632921936,
                40.861614575032775
              ],
              [
                -73.77351264165688,
                40.8612411840871
              ],
              [
                -73.77323150244695,
                40.86074716979339
              ],
              [
                -73.77290231992438,
                40.86120858327854
              ]
            ]
          ],
          [
            [
              [
                -73.8022229535527,
                40.841634813144104
              ],
              [
                -73.80367560026521,
                40.842258492915995
              ],
              [
                -73.80393073307404,
                40.84259407254998
              ],
              [
                -73.80680801372397,
                40.84248913998751
              ],
              [
                -73.80694608641589,
                40.84146244718637
              ],
              [
                -73.80529495910672,
                40.8411349248502
              ],
              [
                -73.80263811156135,
                40.841081153267076
              ],
              [
                -73.8022229535527,
                40.841634813144104
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000228537100218,
        "objectid": 185,
        "shape_leng": 0.0861619681636,
        "location_id": 185,
        "zone": "Pelham Parkway",
        "borough": "Bronx"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.84004614299985,
                40.85780626899989
              ],
              [
                -73.8409714479999,
                40.85794907899987
              ],
              [
                -73.84191078799992,
                40.858091531999925
              ],
              [
                -73.8432955319999,
                40.858290375999914
              ],
              [
                -73.84458712899988,
                40.858445809999914
              ],
              [
                -73.84552460599988,
                40.85853848699988
              ],
              [
                -73.84645854099996,
                40.85863637399989
              ],
              [
                -73.84739746999989,
                40.85868254599995
              ],
              [
                -73.84833709099988,
                40.85870184799994
              ],
              [
                -73.8493089339999,
                40.85871750299987
              ],
              [
                -73.85028234799984,
                40.85865726599997
              ],
              [
                -73.8512286699999,
                40.8585758439999
              ],
              [
                -73.85217337899977,
                40.858499155999965
              ],
              [
                -73.85310947900003,
                40.8584097979999
              ],
              [
                -73.85405423899992,
                40.85832373099985
              ],
              [
                -73.85585355899978,
                40.858171764999966
              ],
              [
                -73.85601437899996,
                40.858158179999904
              ],
              [
                -73.85663774599989,
                40.85811917199993
              ],
              [
                -73.85882515999995,
                40.858061356999876
              ],
              [
                -73.8617906949999,
                40.85798290399987
              ],
              [
                -73.86287784399995,
                40.85795359099992
              ],
              [
                -73.8629585349999,
                40.857951413999956
              ],
              [
                -73.86318907800002,
                40.85794519699993
              ],
              [
                -73.86326398600002,
                40.8579431759999
              ],
              [
                -73.863870478,
                40.85792681599987
              ],
              [
                -73.863957134,
                40.85792447899998
              ],
              [
                -73.86455073499997,
                40.85790846299995
              ],
              [
                -73.86467028199996,
                40.857905236999976
              ],
              [
                -73.8656415789999,
                40.85788154599987
              ],
              [
                -73.8665833139999,
                40.85785535399993
              ],
              [
                -73.86758752499986,
                40.857830089999894
              ],
              [
                -73.86802029299992,
                40.85783059499988
              ],
              [
                -73.86807772399996,
                40.85784684199992
              ],
              [
                -73.86813140299985,
                40.8578765929999
              ],
              [
                -73.86817334099987,
                40.85791854899991
              ],
              [
                -73.86819723700003,
                40.857967986999896
              ],
              [
                -73.86820126399982,
                40.85801835599993
              ],
              [
                -73.8681883059999,
                40.858063833999964
              ],
              [
                -73.86841539699994,
                40.85778035799996
              ],
              [
                -73.86876752999989,
                40.85734077999995
              ],
              [
                -73.86887858999994,
                40.85720213799993
              ],
              [
                -73.86907624599999,
                40.85694556099987
              ],
              [
                -73.86927391299994,
                40.8566889829999
              ],
              [
                -73.86988842299996,
                40.85597106699988
              ],
              [
                -73.86990379599993,
                40.85587302199991
              ],
              [
                -73.86990421600002,
                40.85577329299987
              ],
              [
                -73.86988939599995,
                40.855673861999925
              ],
              [
                -73.86985960399987,
                40.85557672799994
              ],
              [
                -73.86981576299978,
                40.85548377399993
              ],
              [
                -73.86937675499983,
                40.85426572299986
              ],
              [
                -73.86916668699996,
                40.85368604599991
              ],
              [
                -73.86872263199984,
                40.85242385199994
              ],
              [
                -73.86860515599989,
                40.852068645999914
              ],
              [
                -73.86780004799985,
                40.85158929399995
              ],
              [
                -73.86680474499987,
                40.85094982699991
              ],
              [
                -73.86612130799993,
                40.85052722199986
              ],
              [
                -73.86553546999978,
                40.85028863499992
              ],
              [
                -73.86518787199995,
                40.85016438599992
              ],
              [
                -73.86473360999999,
                40.8502745589999
              ],
              [
                -73.86303938099996,
                40.85061130699993
              ],
              [
                -73.86278872899996,
                40.84923798299993
              ],
              [
                -73.86250923799993,
                40.848362280999886
              ],
              [
                -73.86160276499992,
                40.84854631199996
              ],
              [
                -73.86119240899995,
                40.848603467999936
              ],
              [
                -73.86094001199987,
                40.848670934999895
              ],
              [
                -73.86069456699983,
                40.848753393999885
              ],
              [
                -73.86045834099978,
                40.84885014599993
              ],
              [
                -73.86023344799996,
                40.84896024699987
              ],
              [
                -73.85942539999989,
                40.84932393799991
              ],
              [
                -73.85861723499988,
                40.849687847999895
              ],
              [
                -73.85780815,
                40.85005115699994
              ],
              [
                -73.85700079699997,
                40.85041559699992
              ],
              [
                -73.85619083999988,
                40.85077955999988
              ],
              [
                -73.855383516,
                40.8511437189999
              ],
              [
                -73.85457388299992,
                40.85150834599997
              ],
              [
                -73.85304526899994,
                40.849547331999865
              ],
              [
                -73.85224152599983,
                40.8499109959999
              ],
              [
                -73.85136789699986,
                40.85030370399994
              ],
              [
                -73.85049849099983,
                40.85069600599994
              ],
              [
                -73.84968869799998,
                40.85105890699989
              ],
              [
                -73.84861308099981,
                40.85107995799998
              ],
              [
                -73.8482145439999,
                40.85109088899993
              ],
              [
                -73.84729781299993,
                40.85108686899989
              ],
              [
                -73.84721027699993,
                40.851036838999946
              ],
              [
                -73.84668500499978,
                40.8511180779999
              ],
              [
                -73.84453077199993,
                40.8511328989999
              ],
              [
                -73.8443084699999,
                40.85119046399989
              ],
              [
                -73.84441301999993,
                40.85104299699992
              ],
              [
                -73.84475779899996,
                40.850468460999906
              ],
              [
                -73.8449695409999,
                40.850097030999926
              ],
              [
                -73.84502303799998,
                40.84943496099992
              ],
              [
                -73.84504985900004,
                40.8491915849999
              ],
              [
                -73.8450550669999,
                40.84894655899993
              ],
              [
                -73.84503845499987,
                40.84870153999991
              ],
              [
                -73.84500013599994,
                40.84845821899989
              ],
              [
                -73.84494053799982,
                40.84821822099991
              ],
              [
                -73.84428070699988,
                40.84912269899995
              ],
              [
                -73.84361735799992,
                40.85008033999989
              ],
              [
                -73.84194933699985,
                40.85251267999992
              ],
              [
                -73.84149163399988,
                40.85319428599991
              ],
              [
                -73.84134190199983,
                40.85336811399991
              ],
              [
                -73.84118561999999,
                40.85353858499998
              ],
              [
                -73.84102291699999,
                40.85370555799992
              ],
              [
                -73.84085393099993,
                40.85386889199996
              ],
              [
                -73.8406788009999,
                40.854028451999966
              ],
              [
                -73.8404976739999,
                40.854184104999916
              ],
              [
                -73.84031070099996,
                40.854335719999945
              ],
              [
                -73.84011803799997,
                40.854483171999924
              ],
              [
                -73.83991984599993,
                40.85462633599991
              ],
              [
                -73.83971628999994,
                40.85476509399998
              ],
              [
                -73.83935709000001,
                40.854986753999924
              ],
              [
                -73.83898125999988,
                40.85519446599994
              ],
              [
                -73.83859024199985,
                40.85538719199998
              ],
              [
                -73.83818561199983,
                40.8555640099999
              ],
              [
                -73.83776909799984,
                40.855724111999905
              ],
              [
                -73.83734249299997,
                40.85586686399993
              ],
              [
                -73.83690771399993,
                40.85599174899993
              ],
              [
                -73.83646667999994,
                40.856098438999894
              ],
              [
                -73.83562457299985,
                40.85636963099996
              ],
              [
                -73.835623356,
                40.85637002299991
              ],
              [
                -73.8353782199999,
                40.85644896499986
              ],
              [
                -73.83499552599993,
                40.8565712009999
              ],
              [
                -73.83667557299988,
                40.85665266299989
              ],
              [
                -73.83691212799982,
                40.857008106999956
              ],
              [
                -73.83690582299991,
                40.857180695999965
              ],
              [
                -73.83681918499988,
                40.85727680899989
              ],
              [
                -73.83724089099992,
                40.85735732299986
              ],
              [
                -73.838176221,
                40.85750772399996
              ],
              [
                -73.83911116499993,
                40.85765790099986
              ],
              [
                -73.84004614299985,
                40.85780626899989
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0000370729416953,
        "objectid": 186,
        "shape_leng": 0.0246963902234,
        "location_id": 186,
        "zone": "Penn Station/Madison Sq West",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.99096832799995,
                40.74542088999988
              ],
              [
                -73.99051761299985,
                40.746038637999895
              ],
              [
                -73.99006749199991,
                40.746659282999936
              ],
              [
                -73.98961683100002,
                40.74727642299989
              ],
              [
                -73.98916651699989,
                40.747895434999904
              ],
              [
                -73.9887140509998,
                40.748513273999954
              ],
              [
                -73.98823761499983,
                40.74913016899993
              ],
              [
                -73.98797357699985,
                40.74949077899986
              ],
              [
                -73.98776905899994,
                40.749787028999926
              ],
              [
                -73.98793687499989,
                40.749854966999926
              ],
              [
                -73.99062219999986,
                40.75099175399994
              ],
              [
                -73.99211708899992,
                40.75162207799989
              ],
              [
                -73.99346417699985,
                40.752190054999886
              ],
              [
                -73.99394825699984,
                40.75152222199995
              ],
              [
                -73.99437946899982,
                40.75093038999993
              ],
              [
                -73.99484363799989,
                40.75029330899991
              ],
              [
                -73.99529821499979,
                40.74967168299992
              ],
              [
                -73.99574855599987,
                40.74905219599989
              ],
              [
                -73.99619655499993,
                40.74843932799997
              ],
              [
                -73.99664704399989,
                40.74782272899996
              ],
              [
                -73.99709902899994,
                40.74720510199991
              ],
              [
                -73.99426258599996,
                40.74600398099987
              ],
              [
                -73.99141858599994,
                40.74480268199988
              ],
              [
                -73.99096832799995,
                40.74542088999988
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000101427594033,
        "objectid": 189,
        "shape_leng": 0.0486743306502,
        "location_id": 189,
        "zone": "Prospect Heights",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.96070974499996,
                40.67312139399996
              ],
              [
                -73.96039301899985,
                40.67399860199987
              ],
              [
                -73.96007428300001,
                40.67487807099994
              ],
              [
                -73.96318238899983,
                40.675529503999904
              ],
              [
                -73.9628644419999,
                40.67640534099995
              ],
              [
                -73.9625463539998,
                40.67728661699993
              ],
              [
                -73.96222841199993,
                40.67816620799993
              ],
              [
                -73.96195644399998,
                40.6789338429999
              ],
              [
                -73.96167998599987,
                40.679702205999924
              ],
              [
                -73.96153961999995,
                40.680086254999935
              ],
              [
                -73.9614104629999,
                40.680413152999925
              ],
              [
                -73.96134655899994,
                40.6804635239999
              ],
              [
                -73.96329205499984,
                40.680881585999884
              ],
              [
                -73.96442184899986,
                40.68111928799995
              ],
              [
                -73.96447930699999,
                40.681131147999956
              ],
              [
                -73.965510434,
                40.68134401999992
              ],
              [
                -73.96657247099982,
                40.681564813999955
              ],
              [
                -73.96753196799997,
                40.681768783999935
              ],
              [
                -73.96868961400001,
                40.68200525799989
              ],
              [
                -73.96969385699983,
                40.68221680499991
              ],
              [
                -73.97067424999982,
                40.6824209629999
              ],
              [
                -73.97186424199982,
                40.68267028699993
              ],
              [
                -73.97264904900005,
                40.68282745499994
              ],
              [
                -73.97278445899987,
                40.68285242199994
              ],
              [
                -73.9737559849999,
                40.68303401999989
              ],
              [
                -73.97374011799997,
                40.68289203199989
              ],
              [
                -73.973845731,
                40.682586386999915
              ],
              [
                -73.97395135399996,
                40.68228072999996
              ],
              [
                -73.97422671399997,
                40.68150463899984
              ],
              [
                -73.97439144199983,
                40.68104616999989
              ],
              [
                -73.97450395899989,
                40.68073320299993
              ],
              [
                -73.97469118399995,
                40.68026661599992
              ],
              [
                -73.97490473799986,
                40.67995413099987
              ],
              [
                -73.97495737999982,
                40.679877112999925
              ],
              [
                -73.97532192699995,
                40.67933277599991
              ],
              [
                -73.97574929499986,
                40.678699705999925
              ],
              [
                -73.97618051399984,
                40.67806432099995
              ],
              [
                -73.973686958,
                40.67707800199988
              ],
              [
                -73.97188596799982,
                40.676394652999925
              ],
              [
                -73.9694973889999,
                40.675906379999965
              ],
              [
                -73.96969317499985,
                40.67536085399989
              ],
              [
                -73.96954563799987,
                40.675277004999906
              ],
              [
                -73.96954556799999,
                40.67527696899988
              ],
              [
                -73.96940821999982,
                40.675181675999944
              ],
              [
                -73.96940813699987,
                40.675181621999876
              ],
              [
                -73.96928310699995,
                40.67507596699993
              ],
              [
                -73.96917236899992,
                40.67496145599997
              ],
              [
                -73.96917233299985,
                40.67496140999985
              ],
              [
                -73.96917229799983,
                40.67496136499995
              ],
              [
                -73.96907739,
                40.67483976099989
              ],
              [
                -73.96907735499994,
                40.67483971599996
              ],
              [
                -73.9690773179999,
                40.674839670999916
              ],
              [
                -73.96899918499987,
                40.67471277699991
              ],
              [
                -73.96891162400006,
                40.67456305499991
              ],
              [
                -73.96883910899994,
                40.67440796699988
              ],
              [
                -73.96878240699996,
                40.67424884999986
              ],
              [
                -73.96874205,
                40.67408712199992
              ],
              [
                -73.96871822199984,
                40.67392423399988
              ],
              [
                -73.96871164100001,
                40.67380499399991
              ],
              [
                -73.96871337599998,
                40.673685655999876
              ],
              [
                -73.96872342099994,
                40.67356655699992
              ],
              [
                -73.968741747,
                40.673448029999896
              ],
              [
                -73.96876830399998,
                40.67333040999996
              ],
              [
                -73.96880301499984,
                40.673214027999904
              ],
              [
                -73.96884578399981,
                40.67309921099997
              ],
              [
                -73.96889649099982,
                40.67298628299993
              ],
              [
                -73.96892332199981,
                40.6729375529999
              ],
              [
                -73.96865541400003,
                40.673023838999924
              ],
              [
                -73.96841552899997,
                40.6730286279999
              ],
              [
                -73.96839545899999,
                40.67302801799984
              ],
              [
                -73.96817496399977,
                40.673021321999876
              ],
              [
                -73.96793529099995,
                40.67300188399988
              ],
              [
                -73.96769809699995,
                40.67297045299993
              ],
              [
                -73.96746491799992,
                40.672927297999856
              ],
              [
                -73.96468350799994,
                40.67226296899986
              ],
              [
                -73.9631432779999,
                40.67186010899993
              ],
              [
                -73.96258784899996,
                40.671711596999906
              ],
              [
                -73.96131877999991,
                40.671406675999876
              ],
              [
                -73.96126442099991,
                40.6715635909999
              ],
              [
                -73.96102898299978,
                40.67224318399995
              ],
              [
                -73.96070974499996,
                40.67312139399996
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000270873666522,
        "objectid": 190,
        "shape_leng": 0.0743294660829,
        "location_id": 190,
        "zone": "Prospect Park",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.96307724199983,
                40.66213019499987
              ],
              [
                -73.96306232099985,
                40.66225337199995
              ],
              [
                -73.96306230799985,
                40.66225346199992
              ],
              [
                -73.96306228499995,
                40.662253550999864
              ],
              [
                -73.96302689399995,
                40.66237571299994
              ],
              [
                -73.96302687099993,
                40.66237578499987
              ],
              [
                -73.96302683499988,
                40.6623758479999
              ],
              [
                -73.96297127299991,
                40.66249420299992
              ],
              [
                -73.96289695799999,
                40.66260575299985
              ],
              [
                -73.96289685099988,
                40.66260591499988
              ],
              [
                -73.9628967209998,
                40.662606058999856
              ],
              [
                -73.96280577299981,
                40.6627084549999
              ],
              [
                -73.96242894199995,
                40.663125207999876
              ],
              [
                -73.96193896099986,
                40.66322359899989
              ],
              [
                -73.96095595499996,
                40.66328505499992
              ],
              [
                -73.96110886899996,
                40.664062323999914
              ],
              [
                -73.96125791699995,
                40.664801822999955
              ],
              [
                -73.96161627599987,
                40.66669521599989
              ],
              [
                -73.96180658699991,
                40.66768122699988
              ],
              [
                -73.96199657599993,
                40.668662869999906
              ],
              [
                -73.96211021099995,
                40.66919569699998
              ],
              [
                -73.96219447199988,
                40.669641029999866
              ],
              [
                -73.96252326599993,
                40.67137973899987
              ],
              [
                -73.96254108199987,
                40.67146011899995
              ],
              [
                -73.96258784899996,
                40.671711596999906
              ],
              [
                -73.96314327899981,
                40.67186010899995
              ],
              [
                -73.96468350799994,
                40.67226296899986
              ],
              [
                -73.96746491799992,
                40.672927297999856
              ],
              [
                -73.96769809699995,
                40.67297045299993
              ],
              [
                -73.96793529099995,
                40.67300188399988
              ],
              [
                -73.96817496399977,
                40.673021321999876
              ],
              [
                -73.96839545899999,
                40.67302801799984
              ],
              [
                -73.96841552899997,
                40.6730286279999
              ],
              [
                -73.96865541400003,
                40.673023838999924
              ],
              [
                -73.96883836699993,
                40.67296491399987
              ],
              [
                -73.96892332199981,
                40.6729375529999
              ],
              [
                -73.96927327499998,
                40.67279742699989
              ],
              [
                -73.96966394100005,
                40.67264236699988
              ],
              [
                -73.97000244600004,
                40.67263114999985
              ],
              [
                -73.97041289500005,
                40.67259372399992
              ],
              [
                -73.97085440699995,
                40.67186436499992
              ],
              [
                -73.97132526399996,
                40.671248224999914
              ],
              [
                -73.97181594699985,
                40.670629474999906
              ],
              [
                -73.97231720899988,
                40.67002909799988
              ],
              [
                -73.97281990699993,
                40.669427177999864
              ],
              [
                -73.97332284299985,
                40.66882492299992
              ],
              [
                -73.97382528499993,
                40.668223087999884
              ],
              [
                -73.97433093599994,
                40.667621234999956
              ],
              [
                -73.97483327599997,
                40.66702209799993
              ],
              [
                -73.97533714599997,
                40.666419095999906
              ],
              [
                -73.97583132299987,
                40.665815079999895
              ],
              [
                -73.97633386199989,
                40.6652136039999
              ],
              [
                -73.97684516799998,
                40.66460638199994
              ],
              [
                -73.97734252999986,
                40.66401189699998
              ],
              [
                -73.97784275099997,
                40.66340176899992
              ],
              [
                -73.97834945199986,
                40.66279901499991
              ],
              [
                -73.97885475899994,
                40.662201832999905
              ],
              [
                -73.97935059899989,
                40.6615948209999
              ],
              [
                -73.97965353499985,
                40.66124323299993
              ],
              [
                -73.97970643699993,
                40.66126063599991
              ],
              [
                -73.97976200499983,
                40.66127225999991
              ],
              [
                -73.97981915699984,
                40.661277877999964
              ],
              [
                -73.97987678099987,
                40.66127738199991
              ],
              [
                -73.97993375199998,
                40.661270780999864
              ],
              [
                -73.97998896000001,
                40.6612582029999
              ],
              [
                -73.98004133199991,
                40.661239892999916
              ],
              [
                -73.98008984499991,
                40.66121620899992
              ],
              [
                -73.98013355499987,
                40.66118761199988
              ],
              [
                -73.98017161099993,
                40.66115465799992
              ],
              [
                -73.98020628599996,
                40.66110595499992
              ],
              [
                -73.98020645099992,
                40.66110571199989
              ],
              [
                -73.98020654600003,
                40.66110546099991
              ],
              [
                -73.98022710399982,
                40.661048127999905
              ],
              [
                -73.98022716299988,
                40.66104797499988
              ],
              [
                -73.98022716299992,
                40.661047847999924
              ],
              [
                -73.980229014,
                40.660985577999966
              ],
              [
                -73.98022902599985,
                40.660985325999896
              ],
              [
                -73.980228943,
                40.660985055999895
              ],
              [
                -73.98021012299994,
                40.66092355599994
              ],
              [
                -73.98021005299988,
                40.66092332199989
              ],
              [
                -73.98020992299999,
                40.660923132999876
              ],
              [
                -73.98017209799995,
                40.66086787999992
              ],
              [
                -73.98017201599991,
                40.66086775399986
              ],
              [
                -73.98017186199998,
                40.66086761899989
              ],
              [
                -73.98012012799995,
                40.66082312499993
              ],
              [
                -73.98011991500002,
                40.660822935999896
              ],
              [
                -73.98011964299995,
                40.6608227909999
              ],
              [
                -73.98006025399978,
                40.66079075799991
              ],
              [
                -73.98000138699987,
                40.66075769899989
              ],
              [
                -73.98000113899982,
                40.66075756399992
              ],
              [
                -73.98000088999997,
                40.660757491999924
              ],
              [
                -73.97992682399993,
                40.660734731999916
              ],
              [
                -73.9799267049999,
                40.66073469699994
              ],
              [
                -73.9799265629999,
                40.66073468699993
              ],
              [
                -73.97984261899994,
                40.660727440999956
              ],
              [
                -73.9798422759999,
                40.66072741499992
              ],
              [
                -73.9798419799999,
                40.66072744999987
              ],
              [
                -73.97975708399997,
                40.66073846699988
              ],
              [
                -73.97975683499992,
                40.660738501999894
              ],
              [
                -73.97975657599989,
                40.660738601999924
              ],
              [
                -73.97968049599983,
                40.660766593999895
              ],
              [
                -73.97968033000001,
                40.6607666569999
              ],
              [
                -73.97968019899987,
                40.660766737999964
              ],
              [
                -73.97962025699994,
                40.66080686399989
              ],
              [
                -73.9796200439999,
                40.66080700799995
              ],
              [
                -73.97961986699994,
                40.66080721499988
              ],
              [
                -73.97957870099991,
                40.6608532419999
              ],
              [
                -73.97751171399987,
                40.65986275599987
              ],
              [
                -73.97515738099997,
                40.65872279399991
              ],
              [
                -73.97448233999991,
                40.65833944999988
              ],
              [
                -73.97436701299992,
                40.65818790399992
              ],
              [
                -73.97426735599991,
                40.65802878699991
              ],
              [
                -73.97418457599994,
                40.65786357699993
              ],
              [
                -73.97418455199994,
                40.65786350499992
              ],
              [
                -73.97411953499982,
                40.6576937519999
              ],
              [
                -73.97407283499992,
                40.657521192999916
              ],
              [
                -73.97407281199997,
                40.657521085999896
              ],
              [
                -73.97407278699997,
                40.65752097799993
              ],
              [
                -73.97404458299991,
                40.65734725299994
              ],
              [
                -73.97379189999987,
                40.656392323999945
              ],
              [
                -73.97355493999991,
                40.6556439389999
              ],
              [
                -73.97326628099982,
                40.654649260999854
              ],
              [
                -73.97305103699988,
                40.653912506999895
              ],
              [
                -73.97268288599984,
                40.65263927799989
              ],
              [
                -73.97232846299997,
                40.65139792899988
              ],
              [
                -73.97251721299988,
                40.65131415399991
              ],
              [
                -73.97265581899993,
                40.651173570999944
              ],
              [
                -73.97267189899989,
                40.65112201399995
              ],
              [
                -73.97268018299987,
                40.65106939899991
              ],
              [
                -73.97268056399999,
                40.651016407999855
              ],
              [
                -73.97267303699994,
                40.650963727999915
              ],
              [
                -73.97265769799989,
                40.650912039999874
              ],
              [
                -73.9726347479999,
                40.65086201399992
              ],
              [
                -73.97252930399988,
                40.650753079999944
              ],
              [
                -73.97234114899992,
                40.65065904799994
              ],
              [
                -73.97210978599996,
                40.65066414399991
              ],
              [
                -73.9719089039999,
                40.650051409999946
              ],
              [
                -73.97155930399998,
                40.648822828999876
              ],
              [
                -73.97139631700004,
                40.64825778599986
              ],
              [
                -73.97069344099991,
                40.648496775999945
              ],
              [
                -73.96998500799992,
                40.64877493499993
              ],
              [
                -73.96967173699989,
                40.6488979359999
              ],
              [
                -73.96879367199982,
                40.64924826299994
              ],
              [
                -73.96790948399995,
                40.6495993739999
              ],
              [
                -73.9670280919999,
                40.649947273999885
              ],
              [
                -73.96614503299999,
                40.650297679999866
              ],
              [
                -73.96526281999996,
                40.6506424499999
              ],
              [
                -73.96465438699984,
                40.650887727999915
              ],
              [
                -73.9652410359999,
                40.65174204699989
              ],
              [
                -73.96575121499994,
                40.65249922899996
              ],
              [
                -73.96621321999996,
                40.65318940499991
              ],
              [
                -73.96414422599983,
                40.65399426499992
              ],
              [
                -73.96190028499997,
                40.65487096199992
              ],
              [
                -73.96244465949985,
                40.658315463999855
              ],
              [
                -73.96281137499982,
                40.66048727899992
              ],
              [
                -73.96307724199983,
                40.66213019499987
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000421195755741,
        "objectid": 187,
        "shape_leng": 0.126868431324,
        "location_id": 187,
        "zone": "Port Richmond",
        "borough": "Staten Island"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -74.13435269573621,
                40.64188679740515
              ],
              [
                -74.13499655243614,
                40.641760622409926
              ],
              [
                -74.1349989231714,
                40.64178536058637
              ],
              [
                -74.13570958979983,
                40.64164552310737
              ],
              [
                -74.13570742110944,
                40.64162131057876
              ],
              [
                -74.13737001749985,
                40.64129546348895
              ],
              [
                -74.13734401529285,
                40.64114832283013
              ],
              [
                -74.13736902811014,
                40.641113629433725
              ],
              [
                -74.13740054305566,
                40.64108215951298
              ],
              [
                -74.13743785761464,
                40.64105461457024
              ],
              [
                -74.13748013999505,
                40.64103160861471
              ],
              [
                -74.1375264476695,
                40.64101365447568
              ],
              [
                -74.13757574838482,
                40.64100115237116
              ],
              [
                -74.1387924907907,
                40.64089044986865
              ],
              [
                -74.13918328035892,
                40.640854892019256
              ],
              [
                -74.13920382377303,
                40.64074596817814
              ],
              [
                -74.13925744852794,
                40.640735727226804
              ],
              [
                -74.13929745763882,
                40.64080583775189
              ],
              [
                -74.13993785315502,
                40.640733739100604
              ],
              [
                -74.14028892343684,
                40.64066702411253
              ],
              [
                -74.14076253645274,
                40.640589396911246
              ],
              [
                -74.14102430552119,
                40.640506668843905
              ],
              [
                -74.14119221839894,
                40.64038867508108
              ],
              [
                -74.14128624022685,
                40.6401452751609
              ],
              [
                -74.1414804740345,
                40.64009219429549
              ],
              [
                -74.14155622972346,
                40.640366768816975
              ],
              [
                -74.14205431483457,
                40.640296759707326
              ],
              [
                -74.14209864155765,
                40.64032805206495
              ],
              [
                -74.14215420029252,
                40.64034324777035
              ],
              [
                -74.14229766980965,
                40.640305807093604
              ],
              [
                -74.1423806145685,
                40.64025245611129
              ],
              [
                -74.14240463835897,
                40.64019825128139
              ],
              [
                -74.14238558314106,
                40.640055010567046
              ],
              [
                -74.14231969707282,
                40.63979226696526
              ],
              [
                -74.14232244041622,
                40.63974825529747
              ],
              [
                -74.14235881393219,
                40.63967020775366
              ],
              [
                -74.1423879514932,
                40.6396412108299
              ],
              [
                -74.14247549160602,
                40.63956988881194
              ],
              [
                -74.14253970701276,
                40.63953752561879
              ],
              [
                -74.14257769336038,
                40.63953413750177
              ],
              [
                -74.14268320996482,
                40.63954429908314
              ],
              [
                -74.14278730139041,
                40.6395620093365
              ],
              [
                -74.14285974698427,
                40.63956930452341
              ],
              [
                -74.14292244780515,
                40.63959699783796
              ],
              [
                -74.14293728788314,
                40.639632316382276
              ],
              [
                -74.14307730726739,
                40.63966758679392
              ],
              [
                -74.14312802471521,
                40.63968037306379
              ],
              [
                -74.14359998299996,
                40.638514125999905
              ],
              [
                -74.14365267699999,
                40.638484927999876
              ],
              [
                -74.143943612,
                40.638348130999944
              ],
              [
                -74.14478228999994,
                40.63795377999992
              ],
              [
                -74.14556478899993,
                40.635459017999935
              ],
              [
                -74.14566932899992,
                40.63505193199996
              ],
              [
                -74.14583905999997,
                40.634574971999925
              ],
              [
                -74.1464212309999,
                40.63271983599984
              ],
              [
                -74.14659947199988,
                40.632129298999935
              ],
              [
                -74.14666005299989,
                40.631952710999876
              ],
              [
                -74.146830166,
                40.63138265399992
              ],
              [
                -74.14691085899993,
                40.631156230999856
              ],
              [
                -74.1473730989999,
                40.6298270839999
              ],
              [
                -74.14752618999992,
                40.62938928799989
              ],
              [
                -74.14635596699998,
                40.629154846999924
              ],
              [
                -74.14628562599995,
                40.62913738599987
              ],
              [
                -74.14622202799995,
                40.62910695699993
              ],
              [
                -74.14617085499992,
                40.62906625599994
              ],
              [
                -74.14612099599996,
                40.628994393999896
              ],
              [
                -74.14608756399993,
                40.62891590299992
              ],
              [
                -74.14607246899999,
                40.62883419899987
              ],
              [
                -74.14607595499996,
                40.62875294999986
              ],
              [
                -74.14593544599994,
                40.62782411499996
              ],
              [
                -74.14565757199988,
                40.6278295379999
              ],
              [
                -74.14554071999993,
                40.62783252199995
              ],
              [
                -74.14554348099992,
                40.62752119099989
              ],
              [
                -74.14559821999993,
                40.62698877599997
              ],
              [
                -74.1456079119999,
                40.626894508999904
              ],
              [
                -74.14563382799992,
                40.6267251499999
              ],
              [
                -74.14571443699994,
                40.62643373899992
              ],
              [
                -74.1457599779999,
                40.626301354999946
              ],
              [
                -74.145917453,
                40.62584358599986
              ],
              [
                -74.14622812499992,
                40.6249253619999
              ],
              [
                -74.14650354399993,
                40.624200100999914
              ],
              [
                -74.14678442599998,
                40.623607550999935
              ],
              [
                -74.14710957699992,
                40.62303225999994
              ],
              [
                -74.14728835499992,
                40.62276964599989
              ],
              [
                -74.14745462999993,
                40.622530399999896
              ],
              [
                -74.14753387799992,
                40.62241637399988
              ],
              [
                -74.14772830399991,
                40.62213661899996
              ],
              [
                -74.14818936799996,
                40.62160107899996
              ],
              [
                -74.14825244599989,
                40.621529045999864
              ],
              [
                -74.14831982299995,
                40.62144951599987
              ],
              [
                -74.14859494699988,
                40.62116762499993
              ],
              [
                -74.14911237299995,
                40.62063746199988
              ],
              [
                -74.14922838099989,
                40.62052057799984
              ],
              [
                -74.14926443299994,
                40.620484252999915
              ],
              [
                -74.149823327,
                40.6199211269999
              ],
              [
                -74.15019521699998,
                40.61953838199985
              ],
              [
                -74.15037556699993,
                40.61935276499988
              ],
              [
                -74.15071865699997,
                40.61899965099991
              ],
              [
                -74.15093894399993,
                40.61877463899993
              ],
              [
                -74.1513546609999,
                40.618349997999886
              ],
              [
                -74.15165757899995,
                40.6180405579999
              ],
              [
                -74.15183858099991,
                40.61782347299985
              ],
              [
                -74.15205801099998,
                40.61753361999995
              ],
              [
                -74.15219115799991,
                40.61735094499989
              ],
              [
                -74.15231170399994,
                40.61716275599988
              ],
              [
                -74.15241908699993,
                40.61696983899991
              ],
              [
                -74.15251285699996,
                40.616773003999924
              ],
              [
                -74.1525926639999,
                40.616573080999935
              ],
              [
                -74.15281391799995,
                40.615825620999885
              ],
              [
                -74.15290527299994,
                40.615516992999865
              ],
              [
                -74.15295619099997,
                40.61532973399988
              ],
              [
                -74.15311484199995,
                40.61474617099987
              ],
              [
                -74.15321287899991,
                40.614385562999914
              ],
              [
                -74.15368213999994,
                40.612659589999915
              ],
              [
                -74.15371281699991,
                40.61254675899991
              ],
              [
                -74.15375060999993,
                40.61239939199994
              ],
              [
                -74.15379116699992,
                40.612255611999906
              ],
              [
                -74.15371834199986,
                40.612206280999864
              ],
              [
                -74.15317275299992,
                40.6117615219999
              ],
              [
                -74.15120348199993,
                40.61026499499994
              ],
              [
                -74.15031900699995,
                40.60959281499989
              ],
              [
                -74.15018666499996,
                40.60949781099991
              ],
              [
                -74.14998477099994,
                40.6095384179999
              ],
              [
                -74.14954343599997,
                40.60962717199986
              ],
              [
                -74.14893620699995,
                40.60975083699987
              ],
              [
                -74.14774036799996,
                40.60999924999987
              ],
              [
                -74.14696864199988,
                40.61015135599992
              ],
              [
                -74.14689344899988,
                40.61063170899994
              ],
              [
                -74.14680105499993,
                40.6113349189999
              ],
              [
                -74.14670113499992,
                40.612071520999926
              ],
              [
                -74.14654340699992,
                40.61326141199991
              ],
              [
                -74.14645944899996,
                40.61389142499991
              ],
              [
                -74.14640703899988,
                40.61427315199995
              ],
              [
                -74.14603562099998,
                40.617012776999964
              ],
              [
                -74.14512419099998,
                40.6169659789999
              ],
              [
                -74.14419491799998,
                40.61687026199998
              ],
              [
                -74.14326214899995,
                40.616740238999924
              ],
              [
                -74.14233611399992,
                40.61660864399994
              ],
              [
                -74.14173426799988,
                40.61653721899988
              ],
              [
                -74.14161689099993,
                40.61652246999996
              ],
              [
                -74.14072587899987,
                40.61641455499989
              ],
              [
                -74.13982184799993,
                40.61629982499988
              ],
              [
                -74.1396460239999,
                40.61701454899986
              ],
              [
                -74.13944209899994,
                40.617765813999895
              ],
              [
                -74.13923849299987,
                40.61851915699989
              ],
              [
                -74.13900214099995,
                40.61939464299991
              ],
              [
                -74.13873783599995,
                40.62037370299991
              ],
              [
                -74.13853418099995,
                40.62112823399985
              ],
              [
                -74.13836666899986,
                40.6217371169999
              ],
              [
                -74.13861274099999,
                40.62177287699995
              ],
              [
                -74.1385045579999,
                40.62263379799989
              ],
              [
                -74.13826358199992,
                40.62424376999996
              ],
              [
                -74.13805654699992,
                40.62422387299996
              ],
              [
                -74.13784655299993,
                40.62421900999986
              ],
              [
                -74.13763642599997,
                40.62422942999996
              ],
              [
                -74.13742903499994,
                40.624255017999886
              ],
              [
                -74.137227136,
                40.62429519699986
              ],
              [
                -74.13708396099996,
                40.624347681999915
              ],
              [
                -74.13694386099996,
                40.62440478199993
              ],
              [
                -74.13680709299996,
                40.624466392999864
              ],
              [
                -74.13667390599991,
                40.62453240299991
              ],
              [
                -74.13654454499994,
                40.6246026899999
              ],
              [
                -74.13641924499991,
                40.62467712699986
              ],
              [
                -74.13629823599996,
                40.62475557699985
              ],
              [
                -74.13618173899991,
                40.62483789599987
              ],
              [
                -74.13581008299997,
                40.62506433999991
              ],
              [
                -74.13793305099995,
                40.62695669699984
              ],
              [
                -74.13805849499992,
                40.627068517999874
              ],
              [
                -74.13789126699994,
                40.62745287999996
              ],
              [
                -74.13806393799992,
                40.62756847099992
              ],
              [
                -74.13785800099993,
                40.62769048799991
              ],
              [
                -74.13730784599991,
                40.62819029599989
              ],
              [
                -74.13674237799992,
                40.62866253699986
              ],
              [
                -74.13604609,
                40.628188012999864
              ],
              [
                -74.13586108399996,
                40.62806152799991
              ],
              [
                -74.13408698299992,
                40.62954168099987
              ],
              [
                -74.13343731199994,
                40.62907434599988
              ],
              [
                -74.13228531899989,
                40.630018989999925
              ],
              [
                -74.13161834499988,
                40.630583688999884
              ],
              [
                -74.13107460299986,
                40.631147729999896
              ],
              [
                -74.13059499799988,
                40.630880282999904
              ],
              [
                -74.13047428999985,
                40.63088550899992
              ],
              [
                -74.13060220399994,
                40.63135782699985
              ],
              [
                -74.13046976799993,
                40.6316763209999
              ],
              [
                -74.13016617699994,
                40.6323710279999
              ],
              [
                -74.1300590599999,
                40.6326420269999
              ],
              [
                -74.129932476,
                40.632908786999934
              ],
              [
                -74.12978688499999,
                40.63317008399988
              ],
              [
                -74.1296229589999,
                40.63342476399985
              ],
              [
                -74.12944148699994,
                40.6336717209999
              ],
              [
                -74.12900054099997,
                40.634333593999955
              ],
              [
                -74.12893129399997,
                40.63457748699988
              ],
              [
                -74.128881913,
                40.634824897999934
              ],
              [
                -74.12885281899997,
                40.6350744189999
              ],
              [
                -74.128844165,
                40.63532458499988
              ],
              [
                -74.12885588799993,
                40.63557396099989
              ],
              [
                -74.12884672499999,
                40.636436003999975
              ],
              [
                -74.12886142599997,
                40.63656824599984
              ],
              [
                -74.12885649499997,
                40.636702185999916
              ],
              [
                -74.128831656,
                40.63683527499989
              ],
              [
                -74.12878738899998,
                40.636964953999936
              ],
              [
                -74.12872489299997,
                40.637088836999936
              ],
              [
                -74.12867047299994,
                40.63734826499993
              ],
              [
                -74.12784669199989,
                40.63727742999988
              ],
              [
                -74.1268887269999,
                40.63721008799995
              ],
              [
                -74.12707923599991,
                40.638122436999915
              ],
              [
                -74.12705476399996,
                40.638197599999934
              ],
              [
                -74.12700759399989,
                40.63833452499993
              ],
              [
                -74.1269897449999,
                40.63837057999993
              ],
              [
                -74.1269820749999,
                40.638396369999896
              ],
              [
                -74.12697697999995,
                40.638431721999886
              ],
              [
                -74.12698007099992,
                40.638609711999905
              ],
              [
                -74.12699049099994,
                40.63910816399989
              ],
              [
                -74.12702091299994,
                40.63927376299989
              ],
              [
                -74.12702714583776,
                40.639315784971714
              ],
              [
                -74.12714774563887,
                40.6393110568502
              ],
              [
                -74.12724481838394,
                40.63976689085693
              ],
              [
                -74.12734770317398,
                40.63980148638105
              ],
              [
                -74.12739119380265,
                40.63982979896201
              ],
              [
                -74.12739568551268,
                40.63997472215616
              ],
              [
                -74.1275267724088,
                40.64015530471651
              ],
              [
                -74.12783485946173,
                40.640153450858804
              ],
              [
                -74.12810900485857,
                40.640139674293174
              ],
              [
                -74.12813214919126,
                40.64024999943814
              ],
              [
                -74.12881201847124,
                40.6402269775039
              ],
              [
                -74.12882766993523,
                40.640401922667834
              ],
              [
                -74.12890729033458,
                40.641194827586176
              ],
              [
                -74.12899496309494,
                40.64118931324102
              ],
              [
                -74.12891779304782,
                40.64049800620997
              ],
              [
                -74.12893301209895,
                40.640413929487785
              ],
              [
                -74.12922977261063,
                40.64048437235243
              ],
              [
                -74.1293071318684,
                40.64033741121096
              ],
              [
                -74.12992088579598,
                40.64062898297609
              ],
              [
                -74.12987750736707,
                40.640686818458725
              ],
              [
                -74.1299774026272,
                40.640729872855594
              ],
              [
                -74.12949057637357,
                40.6413548351959
              ],
              [
                -74.12967785166222,
                40.64138531255287
              ],
              [
                -74.13012782872325,
                40.64079470651257
              ],
              [
                -74.13018370013205,
                40.64072382608305
              ],
              [
                -74.13073845051632,
                40.64093831183052
              ],
              [
                -74.13108083937513,
                40.64102656060496
              ],
              [
                -74.13146199764822,
                40.64131010407508
              ],
              [
                -74.13125581180262,
                40.64159683076382
              ],
              [
                -74.13134468029385,
                40.641605437431764
              ],
              [
                -74.13172794959776,
                40.64164128020024
              ],
              [
                -74.1324028357712,
                40.64170438898459
              ],
              [
                -74.1324204314397,
                40.64162342108233
              ],
              [
                -74.13251504013276,
                40.64158922672978
              ],
              [
                -74.13256222253686,
                40.641531855788735
              ],
              [
                -74.13266166572117,
                40.64148661175371
              ],
              [
                -74.13274216389047,
                40.64144860847784
              ],
              [
                -74.13282505053002,
                40.64141963033559
              ],
              [
                -74.13288903113595,
                40.64141775096135
              ],
              [
                -74.13299172393485,
                40.641416936664676
              ],
              [
                -74.13314502680257,
                40.641444532663186
              ],
              [
                -74.13322799616068,
                40.64145707358684
              ],
              [
                -74.13331577284222,
                40.64150390667397
              ],
              [
                -74.13341539233275,
                40.641547116550996
              ],
              [
                -74.13346740034588,
                40.641595789936815
              ],
              [
                -74.1334971976666,
                40.64171690148104
              ],
              [
                -74.13430281185364,
                40.641753794909306
              ],
              [
                -74.13435269573621,
                40.64188679740515
              ]
            ]
          ],
          [
            [
              [
                -74.12117246275159,
                40.641669145572486
              ],
              [
                -74.12119488957956,
                40.64167501006542
              ],
              [
                -74.12123492143331,
                40.64158487954768
              ],
              [
                -74.12124033529696,
                40.641586639758586
              ],
              [
                -74.12131194814832,
                40.64145253116248
              ],
              [
                -74.12128074797168,
                40.64143249599354
              ],
              [
                -74.12117246275159,
                40.641669145572486
              ]
            ]
          ],
          [
            [
              [
                -74.1215534360538,
                40.64161162832474
              ],
              [
                -74.12155886560592,
                40.64162163225002
              ],
              [
                -74.12175977879318,
                40.64158785752315
              ],
              [
                -74.12176442929801,
                40.64159491878666
              ],
              [
                -74.12177911197513,
                40.64159254711704
              ],
              [
                -74.12177132201067,
                40.64155958222512
              ],
              [
                -74.12178754859733,
                40.64155662156419
              ],
              [
                -74.12175764698422,
                40.64142342422716
              ],
              [
                -74.12172375309429,
                40.64142866422191
              ],
              [
                -74.12176052602824,
                40.641574315062414
              ],
              [
                -74.1215534360538,
                40.64161162832474
              ]
            ]
          ],
          [
            [
              [
                -74.12107727405213,
                40.641607420588684
              ],
              [
                -74.12110124737936,
                40.64161328229902
              ],
              [
                -74.12121470277087,
                40.641396839993696
              ],
              [
                -74.12118039503834,
                40.64138344518971
              ],
              [
                -74.12107727405213,
                40.641607420588684
              ]
            ]
          ],
          [
            [
              [
                -74.12130529083001,
                40.641600703130024
              ],
              [
                -74.12132617111645,
                40.64160656922885
              ],
              [
                -74.12138690694671,
                40.64148533252626
              ],
              [
                -74.12137332919552,
                40.64147647512165
              ],
              [
                -74.12130529083001,
                40.641600703130024
              ]
            ]
          ],
          [
            [
              [
                -74.12185329597821,
                40.641580692279604
              ],
              [
                -74.12187416191144,
                40.64157831572086
              ],
              [
                -74.12187182772014,
                40.64157007444868
              ],
              [
                -74.12199083807539,
                40.64155463949943
              ],
              [
                -74.12198146380658,
                40.64150106676945
              ],
              [
                -74.12194282613203,
                40.64150640851948
              ],
              [
                -74.12194830995264,
                40.64154644035803
              ],
              [
                -74.12186561799325,
                40.64155594982686
              ],
              [
                -74.12184390745297,
                40.64146529598151
              ],
              [
                -74.1218414704778,
                40.641405894387034
              ],
              [
                -74.12180661091023,
                40.6414121590377
              ],
              [
                -74.12183391805557,
                40.64155186120819
              ],
              [
                -74.12184550812958,
                40.64154890545135
              ],
              [
                -74.12185329597821,
                40.641580692279604
              ]
            ]
          ],
          [
            [
              [
                -74.12270319595868,
                40.64137664893253
              ],
              [
                -74.12269546793483,
                40.64137724570611
              ],
              [
                -74.12271103075855,
                40.641433755252706
              ],
              [
                -74.12276203509289,
                40.641426635297144
              ],
              [
                -74.12274570372185,
                40.64137307106
              ],
              [
                -74.1227256087455,
                40.6413748590964
              ],
              [
                -74.12272114424832,
                40.641347623773065
              ],
              [
                -74.12269555893829,
                40.641353026836214
              ],
              [
                -74.12270319595868,
                40.64137664893253
              ]
            ]
          ],
          [
            [
              [
                -74.12613276551227,
                40.641264388028205
              ],
              [
                -74.12563265806331,
                40.64134171819285
              ],
              [
                -74.12564026870596,
                40.64141849208226
              ],
              [
                -74.12636808688976,
                40.64133238048696
              ],
              [
                -74.1263418044479,
                40.64125278419954
              ],
              [
                -74.12619996291646,
                40.64126431430287
              ],
              [
                -74.12606982881191,
                40.64025447334624
              ],
              [
                -74.1259954246122,
                40.640231242814394
              ],
              [
                -74.12613276551227,
                40.641264388028205
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000313003032771,
        "objectid": 188,
        "shape_leng": 0.0977983968367,
        "location_id": 188,
        "zone": "Prospect-Lefferts Gardens",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.95729172699996,
                40.6643026319999
              ],
              [
                -73.95787061899992,
                40.66427335799993
              ],
              [
                -73.95934422499984,
                40.664175646999915
              ],
              [
                -73.96033682899981,
                40.664108697999964
              ],
              [
                -73.96061544799986,
                40.663302749999936
              ],
              [
                -73.96095595499996,
                40.66328505499992
              ],
              [
                -73.96193896099986,
                40.66322359899989
              ],
              [
                -73.96242894199995,
                40.663125207999876
              ],
              [
                -73.96280577299981,
                40.6627084549999
              ],
              [
                -73.9628967209998,
                40.662606058999856
              ],
              [
                -73.96289685099988,
                40.66260591499988
              ],
              [
                -73.96289695799999,
                40.66260575299985
              ],
              [
                -73.96297127299991,
                40.66249420299992
              ],
              [
                -73.96302683499988,
                40.6623758479999
              ],
              [
                -73.96302687099993,
                40.66237578499987
              ],
              [
                -73.96302689399995,
                40.66237571299994
              ],
              [
                -73.96306228499995,
                40.662253550999864
              ],
              [
                -73.96306230799985,
                40.66225346199992
              ],
              [
                -73.96306232099982,
                40.66225337099995
              ],
              [
                -73.96307724199983,
                40.66213019499987
              ],
              [
                -73.96281137499982,
                40.66048727899992
              ],
              [
                -73.9624446589999,
                40.6583154639999
              ],
              [
                -73.96190028499997,
                40.65487096199992
              ],
              [
                -73.96156461500001,
                40.655004116999905
              ],
              [
                -73.96069809399995,
                40.6553409239999
              ],
              [
                -73.95986103199981,
                40.65563478899989
              ],
              [
                -73.95969665899997,
                40.65483921499991
              ],
              [
                -73.959451838,
                40.65357538799988
              ],
              [
                -73.95932926499988,
                40.652928178999936
              ],
              [
                -73.95915000999992,
                40.65214455699985
              ],
              [
                -73.95892527299989,
                40.65121154999988
              ],
              [
                -73.9587078989999,
                40.65038727299989
              ],
              [
                -73.9558251839998,
                40.6505298079999
              ],
              [
                -73.95480071999985,
                40.65056930299989
              ],
              [
                -73.95295084000004,
                40.65065555999994
              ],
              [
                -73.95246901399993,
                40.650669454999914
              ],
              [
                -73.95137845899984,
                40.65071574499989
              ],
              [
                -73.94957113100006,
                40.65080789999991
              ],
              [
                -73.94966851199989,
                40.6516975199999
              ],
              [
                -73.94976875000005,
                40.65264232299987
              ],
              [
                -73.94993008899999,
                40.65415032699993
              ],
              [
                -73.95006664899988,
                40.65542299699989
              ],
              [
                -73.94715896299984,
                40.65559378699993
              ],
              [
                -73.94507909299995,
                40.65572204699989
              ],
              [
                -73.94487284399997,
                40.655734761999966
              ],
              [
                -73.94446083699984,
                40.655760165999936
              ],
              [
                -73.94335488899993,
                40.655828334999896
              ],
              [
                -73.94240147899986,
                40.655884810999964
              ],
              [
                -73.94146260999986,
                40.65594424899995
              ],
              [
                -73.94053214699989,
                40.65600153899994
              ],
              [
                -73.93955925399985,
                40.65606275299992
              ],
              [
                -73.93859348999989,
                40.65612237899993
              ],
              [
                -73.93765923499997,
                40.65618387599989
              ],
              [
                -73.93712063899989,
                40.656215902999904
              ],
              [
                -73.936684695,
                40.65624182299995
              ],
              [
                -73.936179278,
                40.656273971999916
              ],
              [
                -73.93571813299981,
                40.65630329699991
              ],
              [
                -73.93478437399983,
                40.65636039499992
              ],
              [
                -73.93381012099996,
                40.65642299299988
              ],
              [
                -73.93284657399983,
                40.6564784819999
              ],
              [
                -73.93190743899989,
                40.656537652999944
              ],
              [
                -73.93090354899984,
                40.65661035599994
              ],
              [
                -73.92989802399997,
                40.65668443599994
              ],
              [
                -73.92896419499984,
                40.65674261299992
              ],
              [
                -73.92799465199988,
                40.65680210099989
              ],
              [
                -73.92817215400001,
                40.658477344999945
              ],
              [
                -73.92834017599986,
                40.6600600359999
              ],
              [
                -73.92819257899993,
                40.66017717399992
              ],
              [
                -73.92725505399994,
                40.65933756399987
              ],
              [
                -73.92646424299983,
                40.65981436199997
              ],
              [
                -73.92828249199994,
                40.6614470669999
              ],
              [
                -73.92854068399994,
                40.66168068599989
              ],
              [
                -73.93068465499985,
                40.663620476999924
              ],
              [
                -73.93160743400001,
                40.66355672299988
              ],
              [
                -73.93437459299999,
                40.663372810999896
              ],
              [
                -73.93455236699992,
                40.6633622859999
              ],
              [
                -73.93726377600001,
                40.66319183299991
              ],
              [
                -73.94002976999997,
                40.66302074299991
              ],
              [
                -73.94282892199992,
                40.6628474859999
              ],
              [
                -73.94269628999989,
                40.664055128999955
              ],
              [
                -73.94408198699985,
                40.664128572999914
              ],
              [
                -73.94489227700002,
                40.66417414399994
              ],
              [
                -73.94546293699983,
                40.6642261039999
              ],
              [
                -73.94816604199978,
                40.664081821999964
              ],
              [
                -73.9494063899999,
                40.66400530799991
              ],
              [
                -73.9509741009999,
                40.66390486799992
              ],
              [
                -73.95384851099975,
                40.66372780899991
              ],
              [
                -73.95720935499996,
                40.66351739699992
              ],
              [
                -73.95729172699996,
                40.6643026319999
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000688460603185,
        "objectid": 191,
        "shape_leng": 0.130932637664,
        "location_id": 191,
        "zone": "Queens Village",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.73016587199996,
                40.723958595999896
              ],
              [
                -73.73043003899987,
                40.724640254999876
              ],
              [
                -73.73069257599995,
                40.72532826699992
              ],
              [
                -73.73095653299988,
                40.726014939999864
              ],
              [
                -73.73121918199986,
                40.72669819599987
              ],
              [
                -73.73144806399984,
                40.72734384999995
              ],
              [
                -73.7314721509999,
                40.727411794999924
              ],
              [
                -73.73161735999996,
                40.72744912699996
              ],
              [
                -73.73272577999994,
                40.727734069999904
              ],
              [
                -73.73361727799991,
                40.72793357399994
              ],
              [
                -73.73424214199997,
                40.728075379999915
              ],
              [
                -73.73450816499992,
                40.72813640999992
              ],
              [
                -73.73498719899997,
                40.72824220599991
              ],
              [
                -73.73545493899995,
                40.728345624999925
              ],
              [
                -73.73632483599988,
                40.72854566199994
              ],
              [
                -73.73715501999992,
                40.728732010999884
              ],
              [
                -73.73816144099987,
                40.728958091999914
              ],
              [
                -73.73840175399988,
                40.72901152399988
              ],
              [
                -73.73904904899976,
                40.72916463999991
              ],
              [
                -73.73968059999994,
                40.729306682999926
              ],
              [
                -73.74040469999989,
                40.72946953699989
              ],
              [
                -73.74080920999988,
                40.72956050799996
              ],
              [
                -73.74200329399991,
                40.72982592399989
              ],
              [
                -73.74230785999983,
                40.72989362199996
              ],
              [
                -73.74242143799988,
                40.72991886499995
              ],
              [
                -73.74376398999986,
                40.73022719799998
              ],
              [
                -73.7438387839998,
                40.73024459999991
              ],
              [
                -73.74385189999987,
                40.73024764599997
              ],
              [
                -73.74398330999986,
                40.73027821199989
              ],
              [
                -73.74411105400003,
                40.73030792499985
              ],
              [
                -73.74417482499997,
                40.730322763999936
              ],
              [
                -73.74488800699997,
                40.73048310599994
              ],
              [
                -73.74543152500001,
                40.73061791799989
              ],
              [
                -73.74555667199989,
                40.73063689799994
              ],
              [
                -73.74629555799991,
                40.731180177999875
              ],
              [
                -73.74640108899996,
                40.73129283899991
              ],
              [
                -73.74649162199995,
                40.73141458899992
              ],
              [
                -73.74656523699987,
                40.73154360499996
              ],
              [
                -73.74659290699987,
                40.73161070999988
              ],
              [
                -73.74662058899987,
                40.73167780499995
              ],
              [
                -73.74665689499989,
                40.73181492399991
              ],
              [
                -73.74667404599982,
                40.73195264999993
              ],
              [
                -73.74669850599986,
                40.73221018799995
              ],
              [
                -73.74671314399986,
                40.73236436899988
              ],
              [
                -73.74672140400004,
                40.73247715099991
              ],
              [
                -73.74704531499992,
                40.73219365299987
              ],
              [
                -73.74733126699995,
                40.73193874099995
              ],
              [
                -73.74749692899994,
                40.73179105099995
              ],
              [
                -73.7479342389999,
                40.73140119599985
              ],
              [
                -73.74917188599981,
                40.7302990689999
              ],
              [
                -73.74980987499995,
                40.72974674999989
              ],
              [
                -73.75048295699995,
                40.72922129399997
              ],
              [
                -73.75081066899993,
                40.72899503899988
              ],
              [
                -73.75106753999994,
                40.72881769799993
              ],
              [
                -73.75184238299993,
                40.728326501999895
              ],
              [
                -73.75221914099994,
                40.728112343999925
              ],
              [
                -73.75259471000005,
                40.7279089859999
              ],
              [
                -73.75297028799989,
                40.727705619999874
              ],
              [
                -73.75363109999996,
                40.72739374799988
              ],
              [
                -73.75380709300003,
                40.72731553199991
              ],
              [
                -73.75406469299989,
                40.72720104599991
              ],
              [
                -73.75442222499994,
                40.72704214799993
              ],
              [
                -73.75527584399988,
                40.72671544099993
              ],
              [
                -73.75612598799987,
                40.72643025999991
              ],
              [
                -73.75655253399987,
                40.72627075399994
              ],
              [
                -73.75663658099985,
                40.72624641099991
              ],
              [
                -73.75669771299987,
                40.7262287109999
              ],
              [
                -73.75670987299993,
                40.72617907299987
              ],
              [
                -73.75603126999992,
                40.72559271799985
              ],
              [
                -73.75581422999997,
                40.725430010999965
              ],
              [
                -73.75566637499988,
                40.72531916999991
              ],
              [
                -73.75548073699997,
                40.72511980699992
              ],
              [
                -73.75524449499997,
                40.724783126999924
              ],
              [
                -73.75508625099991,
                40.724490059999866
              ],
              [
                -73.75496919499986,
                40.724159646999944
              ],
              [
                -73.755035606,
                40.7241153209999
              ],
              [
                -73.75577120999998,
                40.72364021599992
              ],
              [
                -73.7554616309999,
                40.723352762999916
              ],
              [
                -73.75491106099987,
                40.7228415239999
              ],
              [
                -73.7546271559998,
                40.72258094099988
              ],
              [
                -73.75433064399984,
                40.72230878699987
              ],
              [
                -73.7532266449999,
                40.72128364999988
              ],
              [
                -73.75301295399987,
                40.72109927899993
              ],
              [
                -73.75281552300001,
                40.7209036249999
              ],
              [
                -73.75263582400002,
                40.7206979659999
              ],
              [
                -73.75247511899987,
                40.72048373099989
              ],
              [
                -73.7523343839998,
                40.72026246099994
              ],
              [
                -73.75313314299989,
                40.71991531799997
              ],
              [
                -73.75393063599992,
                40.71957218199986
              ],
              [
                -73.75474912799989,
                40.71921669499994
              ],
              [
                -73.75556553099985,
                40.718866869999914
              ],
              [
                -73.75638211900001,
                40.718515726999954
              ],
              [
                -73.75721025899988,
                40.7181586389999
              ],
              [
                -73.75725671499985,
                40.718138601999904
              ],
              [
                -73.75588877999999,
                40.716298643999885
              ],
              [
                -73.75470078399988,
                40.714710052999905
              ],
              [
                -73.75407540999991,
                40.7139046949999
              ],
              [
                -73.7540478399999,
                40.713877538999945
              ],
              [
                -73.75401024499983,
                40.71383490099994
              ],
              [
                -73.75370834599995,
                40.71332463599994
              ],
              [
                -73.75360661900004,
                40.71307112199987
              ],
              [
                -73.75357512199989,
                40.712992296999886
              ],
              [
                -73.75318399399988,
                40.71205517599987
              ],
              [
                -73.75245965599989,
                40.71031656399992
              ],
              [
                -73.75184269899988,
                40.70882292099989
              ],
              [
                -73.75139828700003,
                40.70774871999987
              ],
              [
                -73.75121246999993,
                40.707306579999965
              ],
              [
                -73.7507743589998,
                40.706321836999926
              ],
              [
                -73.75047058199985,
                40.70563993099994
              ],
              [
                -73.75052149099987,
                40.70562662299989
              ],
              [
                -73.75205066499986,
                40.70522802799995
              ],
              [
                -73.75174489199993,
                40.704546154999925
              ],
              [
                -73.75143698199983,
                40.7038643239999
              ],
              [
                -73.75113533300001,
                40.70320334299989
              ],
              [
                -73.75082756899988,
                40.70251465499992
              ],
              [
                -73.75053038699998,
                40.701854896999876
              ],
              [
                -73.75022047099984,
                40.701165996999904
              ],
              [
                -73.74991098899996,
                40.70047962799991
              ],
              [
                -73.7496211959998,
                40.699829779999945
              ],
              [
                -73.7493054709999,
                40.69914231299992
              ],
              [
                -73.7501929579999,
                40.69891208199989
              ],
              [
                -73.74928080799988,
                40.69689407699991
              ],
              [
                -73.74863325999986,
                40.69544476099992
              ],
              [
                -73.74829993899986,
                40.69553724399987
              ],
              [
                -73.74774501699983,
                40.695688540999946
              ],
              [
                -73.74744768999992,
                40.695770420999914
              ],
              [
                -73.74624875999989,
                40.69610314499993
              ],
              [
                -73.74618157999997,
                40.69612192599991
              ],
              [
                -73.74649367299992,
                40.69679987399994
              ],
              [
                -73.74681909299983,
                40.69752545199986
              ],
              [
                -73.74712436899999,
                40.698198034999926
              ],
              [
                -73.74741867999983,
                40.69885902799989
              ],
              [
                -73.74773012199991,
                40.69955313699991
              ],
              [
                -73.74767063100002,
                40.699568575999855
              ],
              [
                -73.74677542799994,
                40.69980088799986
              ],
              [
                -73.74588751799996,
                40.70003041699989
              ],
              [
                -73.74500119100004,
                40.70026221299988
              ],
              [
                -73.74590871000004,
                40.70228727399989
              ],
              [
                -73.74502395299994,
                40.70251829799987
              ],
              [
                -73.74413435499979,
                40.70274211199992
              ],
              [
                -73.74386175999997,
                40.702811095999955
              ],
              [
                -73.74330723899996,
                40.70295979699989
              ],
              [
                -73.74287389299987,
                40.70307494299988
              ],
              [
                -73.74167563399995,
                40.70338409999992
              ],
              [
                -73.74148805299996,
                40.70343161199993
              ],
              [
                -73.74129277999985,
                40.70346424899991
              ],
              [
                -73.74109293999989,
                40.70348106299991
              ],
              [
                -73.74089188600004,
                40.70348164599992
              ],
              [
                -73.74069300199987,
                40.70346611399988
              ],
              [
                -73.74055102499982,
                40.70344338099995
              ],
              [
                -73.74050336499984,
                40.70343355599992
              ],
              [
                -73.74051703299997,
                40.703387615999894
              ],
              [
                -73.74077675799997,
                40.702679836999955
              ],
              [
                -73.74072128199992,
                40.7026780189999
              ],
              [
                -73.73819579999989,
                40.70259533599991
              ],
              [
                -73.73624941199995,
                40.7025369779999
              ],
              [
                -73.73430403699984,
                40.702478130999936
              ],
              [
                -73.73426739299985,
                40.70316624699992
              ],
              [
                -73.73426455299999,
                40.70321954199996
              ],
              [
                -73.7333187329999,
                40.70319051999997
              ],
              [
                -73.73245633499985,
                40.703164050999874
              ],
              [
                -73.732314832,
                40.70315970599992
              ],
              [
                -73.73036969799985,
                40.70309853299989
              ],
              [
                -73.7277679189999,
                40.70302063799988
              ],
              [
                -73.7273634669998,
                40.70303714099988
              ],
              [
                -73.72725657499997,
                40.703041498999916
              ],
              [
                -73.72713924599991,
                40.703046271999895
              ],
              [
                -73.72677759516957,
                40.703059291199416
              ],
              [
                -73.72683525330599,
                40.70440569982361
              ],
              [
                -73.72689497712992,
                40.70580017280212
              ],
              [
                -73.72696135646343,
                40.707549087048115
              ],
              [
                -73.7270469657635,
                40.70949875315656
              ],
              [
                -73.72689487490652,
                40.7099785738097
              ],
              [
                -73.72690004194364,
                40.70999683099981
              ],
              [
                -73.72696515545228,
                40.71066071005936
              ],
              [
                -73.7269714679152,
                40.71071450401676
              ],
              [
                -73.72698520205971,
                40.71077631182682
              ],
              [
                -73.72718876297576,
                40.711692617843994
              ],
              [
                -73.72735961821309,
                40.71238894105729
              ],
              [
                -73.72736801308001,
                40.7124111318114
              ],
              [
                -73.72850152477136,
                40.71670554586145
              ],
              [
                -73.72852236515118,
                40.71678448081894
              ],
              [
                -73.72855295497688,
                40.71689594605375
              ],
              [
                -73.72856951516104,
                40.71695629288504
              ],
              [
                -73.72883757476156,
                40.71793301900656
              ],
              [
                -73.72917629507717,
                40.71916721478088
              ],
              [
                -73.72947667309306,
                40.71998333630182
              ],
              [
                -73.72963556812131,
                40.72041503730895
              ],
              [
                -73.72964196075878,
                40.720432413923874
              ],
              [
                -73.72964242877289,
                40.720433685154234
              ],
              [
                -73.72964541390358,
                40.72044178737716
              ],
              [
                -73.72964935890131,
                40.72045251304386
              ],
              [
                -73.72965728480857,
                40.7204740351862
              ],
              [
                -73.72965817488387,
                40.72047644199296
              ],
              [
                -73.7296587481738,
                40.72047801913239
              ],
              [
                -73.72965916916328,
                40.72047916440093
              ],
              [
                -73.72965947411386,
                40.720479993970926
              ],
              [
                -73.72966017623872,
                40.720481877213984
              ],
              [
                -73.72966006089678,
                40.72048124695883
              ],
              [
                -73.73030132022357,
                40.722094516994844
              ],
              [
                -73.73032628984046,
                40.722157296892334
              ],
              [
                -73.73004830901387,
                40.72232591492649
              ],
              [
                -73.72975788476148,
                40.722483579204564
              ],
              [
                -73.72965186981291,
                40.7225348580731
              ],
              [
                -73.7294561428778,
                40.722629528205644
              ],
              [
                -73.72914435403575,
                40.72276307076252
              ],
              [
                -73.72882383511435,
                40.72288364309897
              ],
              [
                -73.72849600617099,
                40.72299077906031
              ],
              [
                -73.72816233910696,
                40.7230841398913
              ],
              [
                -73.72818574899999,
                40.72314563699992
              ],
              [
                -73.72836415399992,
                40.72361435199985
              ],
              [
                -73.72990512499977,
                40.72327249499997
              ],
              [
                -73.73016587199996,
                40.723958595999896
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000373808019026,
        "objectid": 192,
        "shape_leng": 0.11196461971,
        "location_id": 192,
        "zone": "Queensboro Hill",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.82239598999983,
                40.74986422299987
              ],
              [
                -73.82321319899982,
                40.7507079319999
              ],
              [
                -73.82426493799993,
                40.75183288799993
              ],
              [
                -73.8246103029999,
                40.75162674799987
              ],
              [
                -73.82492269399982,
                40.751440294999924
              ],
              [
                -73.82518837299989,
                40.75128858499995
              ],
              [
                -73.82576557199985,
                40.75096902399988
              ],
              [
                -73.82566698599992,
                40.7509087479999
              ],
              [
                -73.82565209100001,
                40.75078150099996
              ],
              [
                -73.8256520129999,
                40.75065299899992
              ],
              [
                -73.8256669259999,
                40.750524743999904
              ],
              [
                -73.82569664700004,
                40.75039824999986
              ],
              [
                -73.82574065099986,
                40.750274963999864
              ],
              [
                -73.82584440999999,
                40.74934839099993
              ],
              [
                -73.82673694299986,
                40.74967723499986
              ],
              [
                -73.82723921499992,
                40.74985960299993
              ],
              [
                -73.82737756299996,
                40.74987437099992
              ],
              [
                -73.82751792599984,
                40.749874789999886
              ],
              [
                -73.82765641899984,
                40.749860830999886
              ],
              [
                -73.82786693899982,
                40.74981128499993
              ],
              [
                -73.82807314199995,
                40.749750465999945
              ],
              [
                -73.82827349999988,
                40.749678714999966
              ],
              [
                -73.82846651899989,
                40.74959657899998
              ],
              [
                -73.82865083499996,
                40.749504731999856
              ],
              [
                -73.82882914999982,
                40.74940718399992
              ],
              [
                -73.82902159099983,
                40.74932374899986
              ],
              [
                -73.82922511999989,
                40.749256080999906
              ],
              [
                -73.82943632699994,
                40.74920530899993
              ],
              [
                -73.82965157399984,
                40.749171992999926
              ],
              [
                -73.83060941299986,
                40.74902588399991
              ],
              [
                -73.83071466599985,
                40.74901721399987
              ],
              [
                -73.83081942899994,
                40.74899579199993
              ],
              [
                -73.83091972499989,
                40.748961710999886
              ],
              [
                -73.8310116789999,
                40.7489160189999
              ],
              [
                -73.8310920649999,
                40.74886061199991
              ],
              [
                -73.83115861499981,
                40.748797997999915
              ],
              [
                -73.83121778599984,
                40.74869012199992
              ],
              [
                -73.83126084299984,
                40.748576271999895
              ],
              [
                -73.83128640899984,
                40.748458584999895
              ],
              [
                -73.83129377999995,
                40.74833944999994
              ],
              [
                -73.83128299699986,
                40.7482212609999
              ],
              [
                -73.83125479799999,
                40.74810635899991
              ],
              [
                -73.83300301999995,
                40.7478336309999
              ],
              [
                -73.83364897199982,
                40.74764609899995
              ],
              [
                -73.83370364199992,
                40.74762634799989
              ],
              [
                -73.83392204099982,
                40.74753213699994
              ],
              [
                -73.83392218299996,
                40.7475320749999
              ],
              [
                -73.83392232599984,
                40.747532001999936
              ],
              [
                -73.83413036799998,
                40.7474264389999
              ],
              [
                -73.83426490799998,
                40.74733779799994
              ],
              [
                -73.8342650619998,
                40.74733769799993
              ],
              [
                -73.83426519299998,
                40.74733759999988
              ],
              [
                -73.83438980999983,
                40.74723985799992
              ],
              [
                -73.83450304299996,
                40.7471339679999
              ],
              [
                -73.83450311499995,
                40.747133903999945
              ],
              [
                -73.83450317399992,
                40.74713383299995
              ],
              [
                -73.83460358899993,
                40.747021133999986
              ],
              [
                -73.834603649,
                40.747021071999875
              ],
              [
                -73.83460369599997,
                40.747020998999886
              ],
              [
                -73.83469042599992,
                40.74690269799993
              ],
              [
                -73.835065747,
                40.74620410899994
              ],
              [
                -73.83543777199996,
                40.74552397399991
              ],
              [
                -73.835569587,
                40.745150630999916
              ],
              [
                -73.83567553899992,
                40.74485051799992
              ],
              [
                -73.83584967799999,
                40.7441757629999
              ],
              [
                -73.83595071499995,
                40.743730568999936
              ],
              [
                -73.83592530399984,
                40.743500570999906
              ],
              [
                -73.83591564899993,
                40.74343089299988
              ],
              [
                -73.83590196999995,
                40.74337980599994
              ],
              [
                -73.83586131899989,
                40.74322798499995
              ],
              [
                -73.83382572699993,
                40.74276242099988
              ],
              [
                -73.83301515399995,
                40.742600957999905
              ],
              [
                -73.83207064899982,
                40.74245093799993
              ],
              [
                -73.83113122799989,
                40.742337999999954
              ],
              [
                -73.8311729489998,
                40.74163764499991
              ],
              [
                -73.83122032399993,
                40.74095321999991
              ],
              [
                -73.83126834299985,
                40.740262275999925
              ],
              [
                -73.8313011729999,
                40.73956106299991
              ],
              [
                -73.83143025500004,
                40.739135732999955
              ],
              [
                -73.83053551299994,
                40.737520194999895
              ],
              [
                -73.82946751999992,
                40.737024481999896
              ],
              [
                -73.82809152599998,
                40.73708317499995
              ],
              [
                -73.82794969099994,
                40.73781959899991
              ],
              [
                -73.8279436029999,
                40.738489981999884
              ],
              [
                -73.82788031099986,
                40.73848979699993
              ],
              [
                -73.82759017299992,
                40.73849421699993
              ],
              [
                -73.82718901699991,
                40.738549870999904
              ],
              [
                -73.82686746499992,
                40.73862891199984
              ],
              [
                -73.826349658,
                40.73884326399992
              ],
              [
                -73.82585368399998,
                40.739123779999886
              ],
              [
                -73.82559030299986,
                40.73944759199992
              ],
              [
                -73.8254265879999,
                40.7394851379999
              ],
              [
                -73.82497439999995,
                40.73944042599991
              ],
              [
                -73.82494800899991,
                40.739567104999914
              ],
              [
                -73.82481178499988,
                40.739630959999886
              ],
              [
                -73.8243501109999,
                40.73975360599993
              ],
              [
                -73.82355883499997,
                40.73957874499993
              ],
              [
                -73.82266847299987,
                40.739384992999916
              ],
              [
                -73.82177826400005,
                40.739193646999894
              ],
              [
                -73.82089716499988,
                40.739002110999934
              ],
              [
                -73.82006431399985,
                40.73882813999993
              ],
              [
                -73.81837989599987,
                40.73859373999994
              ],
              [
                -73.81708075599988,
                40.738414734999886
              ],
              [
                -73.81693283800001,
                40.738389509999934
              ],
              [
                -73.81677959599985,
                40.73837979399984
              ],
              [
                -73.81662531599994,
                40.73838631099992
              ],
              [
                -73.81647443699993,
                40.73840887099994
              ],
              [
                -73.81633109399993,
                40.738446400999905
              ],
              [
                -73.8160767679998,
                40.73885793399994
              ],
              [
                -73.81518583099992,
                40.73857117099989
              ],
              [
                -73.81501663899991,
                40.738558544999876
              ],
              [
                -73.81497208299996,
                40.738560715999874
              ],
              [
                -73.81503862799978,
                40.73889366799993
              ],
              [
                -73.81504998799993,
                40.73894313299993
              ],
              [
                -73.81499755699998,
                40.738932585999926
              ],
              [
                -73.81489497099994,
                40.73891574299995
              ],
              [
                -73.81407381999989,
                40.738780901999874
              ],
              [
                -73.813224018,
                40.73870225399991
              ],
              [
                -73.81227737799998,
                40.7386644509999
              ],
              [
                -73.8113972909998,
                40.738629306999904
              ],
              [
                -73.8104043559999,
                40.73858964799992
              ],
              [
                -73.809411422,
                40.73854997199989
              ],
              [
                -73.80893016799997,
                40.73853237999986
              ],
              [
                -73.80844891299986,
                40.73851478699992
              ],
              [
                -73.80805258499988,
                40.738500293999905
              ],
              [
                -73.80752082000002,
                40.73848084799993
              ],
              [
                -73.8065788389999,
                40.73844721099993
              ],
              [
                -73.80564551,
                40.73841865199988
              ],
              [
                -73.80471291600003,
                40.738393948999914
              ],
              [
                -73.80466543299995,
                40.738392984999926
              ],
              [
                -73.80461553500005,
                40.73839157799993
              ],
              [
                -73.80363600499999,
                40.73836085199987
              ],
              [
                -73.80268632099998,
                40.7383310519999
              ],
              [
                -73.80180834299986,
                40.738302654999885
              ],
              [
                -73.80132155499992,
                40.738284201999946
              ],
              [
                -73.80089151300002,
                40.7382690159999
              ],
              [
                -73.80000292499997,
                40.738237623999936
              ],
              [
                -73.79911195699995,
                40.738206139999875
              ],
              [
                -73.79822058899992,
                40.7381746309999
              ],
              [
                -73.79729260599996,
                40.738185428999955
              ],
              [
                -73.79684795799999,
                40.738235540999916
              ],
              [
                -73.79640218700004,
                40.73828235799987
              ],
              [
                -73.79641178399993,
                40.738325325999895
              ],
              [
                -73.7964394009999,
                40.73843894799995
              ],
              [
                -73.79672497900003,
                40.738416642999894
              ],
              [
                -73.79629297299984,
                40.73855230599992
              ],
              [
                -73.79640833699986,
                40.738593419999894
              ],
              [
                -73.7971768669999,
                40.739986684999934
              ],
              [
                -73.79722362499994,
                40.7400978009999
              ],
              [
                -73.79722373099987,
                40.74009803499992
              ],
              [
                -73.79722380199985,
                40.740098277999905
              ],
              [
                -73.79725664999995,
                40.74021283499994
              ],
              [
                -73.79725670899992,
                40.74021304299991
              ],
              [
                -73.79725674299982,
                40.74021324199991
              ],
              [
                -73.79727515299997,
                40.740330033999896
              ],
              [
                -73.79727517699985,
                40.740330185999895
              ],
              [
                -73.79727517699983,
                40.74033034899987
              ],
              [
                -73.79727886799998,
                40.740447600999886
              ],
              [
                -73.79727887899985,
                40.740447861999925
              ],
              [
                -73.79727885499992,
                40.74044812399994
              ],
              [
                -73.79726795099995,
                40.74056448599986
              ],
              [
                -73.79723084999992,
                40.74169550699992
              ],
              [
                -73.79695065300002,
                40.741933034999896
              ],
              [
                -73.79666622899994,
                40.74213522099994
              ],
              [
                -73.79666601799985,
                40.74213537199991
              ],
              [
                -73.79666581899986,
                40.742135522999895
              ],
              [
                -73.79639432299984,
                40.74234834999987
              ],
              [
                -73.79639423599997,
                40.742348424999925
              ],
              [
                -73.79639414999991,
                40.74234849999988
              ],
              [
                -73.79613638599983,
                40.74257141099988
              ],
              [
                -73.79613631199986,
                40.74257146699995
              ],
              [
                -73.79613624999985,
                40.74257152399986
              ],
              [
                -73.79589335199982,
                40.742803526999886
              ],
              [
                -73.79589319199994,
                40.74280368799989
              ],
              [
                -73.79589302899998,
                40.74280385799994
              ],
              [
                -73.79566566899987,
                40.74304423599989
              ],
              [
                -73.79543059299992,
                40.74326428999993
              ],
              [
                -73.7952125779999,
                40.74349556899989
              ],
              [
                -73.79501312,
                40.74373670699995
              ],
              [
                -73.79501298499999,
                40.743736880999926
              ],
              [
                -73.79501285899983,
                40.743737044999875
              ],
              [
                -73.79483295499978,
                40.74398694199992
              ],
              [
                -73.79467356599999,
                40.74424409499991
              ],
              [
                -73.79467340799984,
                40.74424434599993
              ],
              [
                -73.79467327199987,
                40.74424460699987
              ],
              [
                -73.79453507400001,
                40.74450763799989
              ],
              [
                -73.7944912069999,
                40.744737567999955
              ],
              [
                -73.79449117099993,
                40.74473774799992
              ],
              [
                -73.7944911469999,
                40.744737937999965
              ],
              [
                -73.7944636839999,
                40.74497002199992
              ],
              [
                -73.79445282999983,
                40.7452030899999
              ],
              [
                -73.79445281699995,
                40.74520343199987
              ],
              [
                -73.79445282799998,
                40.74520375699995
              ],
              [
                -73.79445869199989,
                40.74543695399997
              ],
              [
                -73.79448120499993,
                40.745669369999895
              ],
              [
                -73.79448121599987,
                40.745669531999944
              ],
              [
                -73.79448123999998,
                40.745669693999915
              ],
              [
                -73.79452019399987,
                40.74590015899989
              ],
              [
                -73.7946756879998,
                40.74721192799996
              ],
              [
                -73.79826235399989,
                40.746965655999894
              ],
              [
                -73.79921673599998,
                40.746856280999914
              ],
              [
                -73.80017472200002,
                40.74675557799992
              ],
              [
                -73.80327809299986,
                40.74643950999997
              ],
              [
                -73.8033521419999,
                40.74643196499987
              ],
              [
                -73.80302898599999,
                40.747194800999935
              ],
              [
                -73.80294034199999,
                40.747408915999905
              ],
              [
                -73.80286300399987,
                40.7476429709999
              ],
              [
                -73.80280481399994,
                40.74788116299989
              ],
              [
                -73.80276629299996,
                40.74812214499989
              ],
              [
                -73.80274770699985,
                40.74836447599992
              ],
              [
                -73.80274910899995,
                40.74860675199987
              ],
              [
                -73.80277033699984,
                40.748847537999936
              ],
              [
                -73.80280784099993,
                40.74906666099989
              ],
              [
                -73.80287666499983,
                40.74976514999991
              ],
              [
                -73.80295670699991,
                40.74977052699988
              ],
              [
                -73.80353070499987,
                40.74982541299988
              ],
              [
                -73.80446953099995,
                40.74989927599989
              ],
              [
                -73.8053995369999,
                40.7499727289999
              ],
              [
                -73.80633253899988,
                40.75004766599994
              ],
              [
                -73.8072707609999,
                40.75011897499993
              ],
              [
                -73.80750599299991,
                40.750136586999936
              ],
              [
                -73.80976035399995,
                40.74877922299991
              ],
              [
                -73.81161754599998,
                40.74766484299989
              ],
              [
                -73.81235411899996,
                40.7472232829999
              ],
              [
                -73.8131629729999,
                40.74673791899988
              ],
              [
                -73.81341871899986,
                40.74658444499988
              ],
              [
                -73.81416171999987,
                40.74613857999992
              ],
              [
                -73.81477152699999,
                40.74668237099996
              ],
              [
                -73.8153731739999,
                40.747218337999925
              ],
              [
                -73.81575069499983,
                40.746979479999915
              ],
              [
                -73.81657996199982,
                40.74713778799989
              ],
              [
                -73.81691898599989,
                40.74720466799994
              ],
              [
                -73.81782025899999,
                40.74738541599988
              ],
              [
                -73.81873142799999,
                40.747711900999875
              ],
              [
                -73.81961962699988,
                40.748042042999934
              ],
              [
                -73.82052281799989,
                40.7483568499999
              ],
              [
                -73.82108427599998,
                40.748558445999954
              ],
              [
                -73.82131898199997,
                40.748758182999914
              ],
              [
                -73.82239598999983,
                40.74986422299987
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000145591657944,
        "objectid": 193,
        "shape_leng": 0.0655299584801,
        "location_id": 193,
        "zone": "Queensbridge/Ravenswood",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.93522718099996,
                40.76952572899989
              ],
              [
                -73.93503989699988,
                40.76968230199992
              ],
              [
                -73.934869397,
                40.76985073499986
              ],
              [
                -73.93486925499987,
                40.769850877999886
              ],
              [
                -73.93486912299994,
                40.769851030999874
              ],
              [
                -73.93471718499994,
                40.77002985799988
              ],
              [
                -73.93458521599987,
                40.770217420999884
              ],
              [
                -73.93447449099996,
                40.770411596999956
              ],
              [
                -73.93424609599985,
                40.77102927499995
              ],
              [
                -73.93452730104705,
                40.77117677505168
              ],
              [
                -73.93490481291309,
                40.77127064109336
              ],
              [
                -73.93493892978363,
                40.771164052837406
              ],
              [
                -73.93494027200222,
                40.77116402096635
              ],
              [
                -73.9350081388611,
                40.771162394433276
              ],
              [
                -73.93514715402594,
                40.77054195605843
              ],
              [
                -73.93469499066988,
                40.770425338525115
              ],
              [
                -73.93489533894557,
                40.7700418628895
              ],
              [
                -73.93524016831192,
                40.76967932546299
              ],
              [
                -73.93574021432624,
                40.76936722986654
              ],
              [
                -73.93616913919553,
                40.76951611465446
              ],
              [
                -73.93640381736212,
                40.76947488401429
              ],
              [
                -73.93670737989197,
                40.769252609074115
              ],
              [
                -73.93668539119408,
                40.7691330546909
              ],
              [
                -73.93681460654729,
                40.76890394747839
              ],
              [
                -73.93757632517331,
                40.76892399418494
              ],
              [
                -73.9382038348605,
                40.768727571864005
              ],
              [
                -73.93895502453339,
                40.76795818621701
              ],
              [
                -73.93936587223878,
                40.76815970137701
              ],
              [
                -73.93953321663712,
                40.76805874435428
              ],
              [
                -73.93983559283014,
                40.76798800593895
              ],
              [
                -73.94035103784384,
                40.76765337598741
              ],
              [
                -73.94046256885547,
                40.76753046980284
              ],
              [
                -73.9413117494144,
                40.76691800476962
              ],
              [
                -73.94158523271751,
                40.76661608765766
              ],
              [
                -73.94350282032728,
                40.76449904074385
              ],
              [
                -73.94471460801532,
                40.76287884021323
              ],
              [
                -73.94473048986049,
                40.76285654700463
              ],
              [
                -73.94570525620351,
                40.76148815702824
              ],
              [
                -73.94580554802424,
                40.76144956706048
              ],
              [
                -73.94596424209928,
                40.761292606805114
              ],
              [
                -73.94783189558463,
                40.75898919290095
              ],
              [
                -73.94844487681874,
                40.75821799715228
              ],
              [
                -73.94915021804697,
                40.75732894544873
              ],
              [
                -73.95066341780087,
                40.75543632291635
              ],
              [
                -73.9507348149783,
                40.7553522591792
              ],
              [
                -73.95077122679736,
                40.75530796907786
              ],
              [
                -73.95080763809156,
                40.75526368005709
              ],
              [
                -73.94911295099988,
                40.75452361999986
              ],
              [
                -73.9482744449999,
                40.75415377799993
              ],
              [
                -73.94745112999996,
                40.75379680499989
              ],
              [
                -73.94655794699989,
                40.75340986099998
              ],
              [
                -73.94635771599988,
                40.75332271699992
              ],
              [
                -73.94597511599991,
                40.75315619699993
              ],
              [
                -73.9457177839999,
                40.75304419599996
              ],
              [
                -73.94552905199996,
                40.75295740699989
              ],
              [
                -73.94504698699998,
                40.75273571599994
              ],
              [
                -73.94481659399985,
                40.7526316979999
              ],
              [
                -73.94458401999987,
                40.752534888999946
              ],
              [
                -73.944052652,
                40.75231325999994
              ],
              [
                -73.94401823399977,
                40.75234992099994
              ],
              [
                -73.94396284399984,
                40.75240407599993
              ],
              [
                -73.94392521099985,
                40.752441104999924
              ],
              [
                -73.94391620499988,
                40.75245068799991
              ],
              [
                -73.9438353679999,
                40.75253012299991
              ],
              [
                -73.94374837099986,
                40.752620980999886
              ],
              [
                -73.94355728100003,
                40.75282055199991
              ],
              [
                -73.94243020699994,
                40.75399761299994
              ],
              [
                -73.94123861599985,
                40.755234414999954
              ],
              [
                -73.94004034700002,
                40.75648675099994
              ],
              [
                -73.93910030499991,
                40.7574941399999
              ],
              [
                -73.93791705599988,
                40.7587066049999
              ],
              [
                -73.93679091699993,
                40.759882880999925
              ],
              [
                -73.93605584499984,
                40.76065330999995
              ],
              [
                -73.9360170199998,
                40.7606940019999
              ],
              [
                -73.93593276099996,
                40.76078231199991
              ],
              [
                -73.93589256999995,
                40.7608244349999
              ],
              [
                -73.93584407599987,
                40.76087525999995
              ],
              [
                -73.93581185199994,
                40.7609090339999
              ],
              [
                -73.93577489099982,
                40.76094777199988
              ],
              [
                -73.93569960399994,
                40.76102667699991
              ],
              [
                -73.93566314299997,
                40.76106489099993
              ],
              [
                -73.93560854899978,
                40.761122109999846
              ],
              [
                -73.93556528699992,
                40.7611674509999
              ],
              [
                -73.93550408999991,
                40.76123158999995
              ],
              [
                -73.93546712899983,
                40.761270327999945
              ],
              [
                -73.93543323399999,
                40.76130585199992
              ],
              [
                -73.93538955299994,
                40.76135538399994
              ],
              [
                -73.93480791700001,
                40.7619604159999
              ],
              [
                -73.93472503099987,
                40.762047169999896
              ],
              [
                -73.93463877499988,
                40.76213744999994
              ],
              [
                -73.93457323599993,
                40.762206044999935
              ],
              [
                -73.9343717929999,
                40.76241688499991
              ],
              [
                -73.93429712599992,
                40.76249503399991
              ],
              [
                -73.9341081699999,
                40.76269280199993
              ],
              [
                -73.93404264799992,
                40.762761377999915
              ],
              [
                -73.93398092199995,
                40.762825981999875
              ],
              [
                -73.93541372899996,
                40.763496787999884
              ],
              [
                -73.93614654199985,
                40.76384282899991
              ],
              [
                -73.93616861799997,
                40.76385325299988
              ],
              [
                -73.93619184999997,
                40.76386422399987
              ],
              [
                -73.9362163929999,
                40.76387581299984
              ],
              [
                -73.9362557499999,
                40.763894396999916
              ],
              [
                -73.93684249199987,
                40.764165082999924
              ],
              [
                -73.93687031099991,
                40.7641779169999
              ],
              [
                -73.93700478599986,
                40.76423995399997
              ],
              [
                -73.93600532399995,
                40.76588489499989
              ],
              [
                -73.93682006599995,
                40.766182735999905
              ],
              [
                -73.93763535299998,
                40.76647487199989
              ],
              [
                -73.93846096299997,
                40.766768302999935
              ],
              [
                -73.93858976899998,
                40.766817358999916
              ],
              [
                -73.93836125899996,
                40.766910755999966
              ],
              [
                -73.93709253699996,
                40.76736039299991
              ],
              [
                -73.93618379699997,
                40.76769180199995
              ],
              [
                -73.93593177099983,
                40.767783701999896
              ],
              [
                -73.935820709,
                40.76863091499995
              ],
              [
                -73.93577134099989,
                40.769050009999866
              ],
              [
                -73.93522718099996,
                40.76952572899989
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000273481702719,
        "objectid": 194,
        "shape_leng": 0.0817938353532,
        "location_id": 194,
        "zone": "Randalls Island",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.91639852914044,
                40.79768484203896
              ],
              [
                -73.91662985247798,
                40.79785813943717
              ],
              [
                -73.9179027981093,
                40.798362653231486
              ],
              [
                -73.9179799205819,
                40.79838020192752
              ],
              [
                -73.91891076100565,
                40.798740138368245
              ],
              [
                -73.9189872445785,
                40.798769712219844
              ],
              [
                -73.91899496843432,
                40.79877184320446
              ],
              [
                -73.91906560282143,
                40.79880279731935
              ],
              [
                -73.91914573748252,
                40.79883898734595
              ],
              [
                -73.91923785764347,
                40.79888106768282
              ],
              [
                -73.9203146552122,
                40.799375459290026
              ],
              [
                -73.92035479893731,
                40.79942285236057
              ],
              [
                -73.92057878116655,
                40.79966814846023
              ],
              [
                -73.92083898713122,
                40.80001259800666
              ],
              [
                -73.92103317645132,
                40.800265281858074
              ],
              [
                -73.92113594709092,
                40.80047546009652
              ],
              [
                -73.92133752419274,
                40.8008521075022
              ],
              [
                -73.92191092229565,
                40.8015026662535
              ],
              [
                -73.92215538898822,
                40.80162646997485
              ],
              [
                -73.92263378594882,
                40.80186873814114
              ],
              [
                -73.92436044107556,
                40.80198555341902
              ],
              [
                -73.9252752102012,
                40.80199852274338
              ],
              [
                -73.92529030602597,
                40.801995586077716
              ],
              [
                -73.92549293546509,
                40.80195616882528
              ],
              [
                -73.92569826189312,
                40.801810871426575
              ],
              [
                -73.92577551805864,
                40.80173060099814
              ],
              [
                -73.92582494619354,
                40.80164248988908
              ],
              [
                -73.92588046318872,
                40.801551741962996
              ],
              [
                -73.9265095488364,
                40.80065703126224
              ],
              [
                -73.9265524180424,
                40.80060482504616
              ],
              [
                -73.9266778180094,
                40.8004496894687
              ],
              [
                -73.9265025028775,
                40.80037491715338
              ],
              [
                -73.92653846445016,
                40.80033389815394
              ],
              [
                -73.9265535167681,
                40.80031517296066
              ],
              [
                -73.92666565490181,
                40.80018271401784
              ],
              [
                -73.92669572614771,
                40.800146977076245
              ],
              [
                -73.92680607671764,
                40.80003590076877
              ],
              [
                -73.92685410186321,
                40.79998755902302
              ],
              [
                -73.92688261777174,
                40.79994076875161
              ],
              [
                -73.92690105422145,
                40.799888236676075
              ],
              [
                -73.92691995657893,
                40.79983759048993
              ],
              [
                -73.92726293295631,
                40.79891864506106
              ],
              [
                -73.92747588601745,
                40.79812289983246
              ],
              [
                -73.92743922192645,
                40.79771053810799
              ],
              [
                -73.9272772028445,
                40.79740745307425
              ],
              [
                -73.92721689278596,
                40.7974154332488
              ],
              [
                -73.9272029981222,
                40.797386747692755
              ],
              [
                -73.92718514800379,
                40.7973498967187
              ],
              [
                -73.9271115141623,
                40.79719788006736
              ],
              [
                -73.92703845685821,
                40.796988795207476
              ],
              [
                -73.92695192377981,
                40.79670843214218
              ],
              [
                -73.9268896786395,
                40.796252011431335
              ],
              [
                -73.92689066980924,
                40.79623452436238
              ],
              [
                -73.92690492714154,
                40.795983851767936
              ],
              [
                -73.92692321960746,
                40.795662348903164
              ],
              [
                -73.92692590462262,
                40.79557958400357
              ],
              [
                -73.9269361484504,
                40.795497160940506
              ],
              [
                -73.92695390083247,
                40.79541547781582
              ],
              [
                -73.92697907687112,
                40.79533492894325
              ],
              [
                -73.9270115547382,
                40.795255903895765
              ],
              [
                -73.92705117746826,
                40.7951787840646
              ],
              [
                -73.92709775283825,
                40.79510394223802
              ],
              [
                -73.92724362202914,
                40.79477971085496
              ],
              [
                -73.92735224578799,
                40.794460846782584
              ],
              [
                -73.92826221599341,
                40.79320287515347
              ],
              [
                -73.9282921999328,
                40.79315082224056
              ],
              [
                -73.9283194359912,
                40.793091266082726
              ],
              [
                -73.92826720298079,
                40.79232852397404
              ],
              [
                -73.92800053586241,
                40.79213364095347
              ],
              [
                -73.92780141486193,
                40.791997479131155
              ],
              [
                -73.92713563764022,
                40.791929306801514
              ],
              [
                -73.92723286076209,
                40.79179136818892
              ],
              [
                -73.92727664462073,
                40.791767327255954
              ],
              [
                -73.92732065704298,
                40.7917431602298
              ],
              [
                -73.92757830264749,
                40.79146047486957
              ],
              [
                -73.92765982803098,
                40.79134833426529
              ],
              [
                -73.9278937449928,
                40.791091111210484
              ],
              [
                -73.9279105358314,
                40.79103914679122
              ],
              [
                -73.92790202759703,
                40.791009818668336
              ],
              [
                -73.9278932871547,
                40.79097884634051
              ],
              [
                -73.9278688922052,
                40.79095483831723
              ],
              [
                -73.9277840996424,
                40.79094160382707
              ],
              [
                -73.92765319089136,
                40.79095840286456
              ],
              [
                -73.92756120582968,
                40.79096352366368
              ],
              [
                -73.92751010802489,
                40.79096090213123
              ],
              [
                -73.92743856860018,
                40.79096085712281
              ],
              [
                -73.92738061595081,
                40.790997068665945
              ],
              [
                -73.9273158787935,
                40.79100738396167
              ],
              [
                -73.92724094286734,
                40.790996980621685
              ],
              [
                -73.92716599683943,
                40.790996933453535
              ],
              [
                -73.92711487713235,
                40.791015024230255
              ],
              [
                -73.92701950689816,
                40.79099942975277
              ],
              [
                -73.92694456108919,
                40.79099938201269
              ],
              [
                -73.92682874582606,
                40.790988952076695
              ],
              [
                -73.92673885594114,
                40.790974120837205
              ],
              [
                -73.92670272281141,
                40.79096815888377
              ],
              [
                -73.92656584807987,
                40.79097950185658
              ],
              [
                -73.92648804311864,
                40.791022393999704
              ],
              [
                -73.9264266261548,
                40.79111038629359
              ],
              [
                -73.92634471493115,
                40.79124755705966
              ],
              [
                -73.92633080392882,
                40.791503873114216
              ],
              [
                -73.9263681028016,
                40.79166183419214
              ],
              [
                -73.92650765413156,
                40.79177325647902
              ],
              [
                -73.92657917174049,
                40.7917940151715
              ],
              [
                -73.92664049578528,
                40.79179146422634
              ],
              [
                -73.92670177076633,
                40.79183293010977
              ],
              [
                -73.92673913886138,
                40.79192875185231
              ],
              [
                -73.92666775380432,
                40.79194057213958
              ],
              [
                -73.92663010365783,
                40.79194680694441
              ],
              [
                -73.92642909660879,
                40.79195703508008
              ],
              [
                -73.9261702508689,
                40.79189990812939
              ],
              [
                -73.92560814781015,
                40.79189695828148
              ],
              [
                -73.92558094001407,
                40.79185551410814
              ],
              [
                -73.9255877914775,
                40.79182185999814
              ],
              [
                -73.92563608586745,
                40.79182315574161
              ],
              [
                -73.92576489923744,
                40.79185822180665
              ],
              [
                -73.92592839987515,
                40.79187645092191
              ],
              [
                -73.92614646074732,
                40.79184811016752
              ],
              [
                -73.92627928808834,
                40.79187926587529
              ],
              [
                -73.92651433019059,
                40.791897539443355
              ],
              [
                -73.9265688801793,
                40.79185873681861
              ],
              [
                -73.9265484745292,
                40.79182765421351
              ],
              [
                -73.92644290176644,
                40.791796517697264
              ],
              [
                -73.92627260981645,
                40.791757571034466
              ],
              [
                -73.92616023377902,
                40.791716073510884
              ],
              [
                -73.92616033990507,
                40.791620275177166
              ],
              [
                -73.92615360747125,
                40.79154777494013
              ],
              [
                -73.9261128771905,
                40.791413114098766
              ],
              [
                -73.92608708144499,
                40.79135413308455
              ],
              [
                -73.92604831400956,
                40.79126549193269
              ],
              [
                -73.92604500498163,
                40.79117745932123
              ],
              [
                -73.92608938664702,
                40.791092046136995
              ],
              [
                -73.9260860778176,
                40.791004012938735
              ],
              [
                -73.92606571253833,
                40.79093668275996
              ],
              [
                -73.92606242622999,
                40.79082793616362
              ],
              [
                -73.9261041024332,
                40.79073160730996
              ],
              [
                -73.92625431474322,
                40.79069453300849
              ],
              [
                -73.92641834021488,
                40.790693376193516
              ],
              [
                -73.92666714373019,
                40.790734513893575
              ],
              [
                -73.92680823697293,
                40.79076444018542
              ],
              [
                -73.9269225827856,
                40.790788693002014
              ],
              [
                -73.92737326899098,
                40.79081082516725
              ],
              [
                -73.92807273587826,
                40.790823391141565
              ],
              [
                -73.92859459823978,
                40.79096692415191
              ],
              [
                -73.9287194896338,
                40.7909435327562
              ],
              [
                -73.92966610521292,
                40.79135578622649
              ],
              [
                -73.92972400790947,
                40.791366178171536
              ],
              [
                -73.92978443004151,
                40.79134147536453
              ],
              [
                -73.93072940501884,
                40.790955114215386
              ],
              [
                -73.93084178719639,
                40.79099143000079
              ],
              [
                -73.93147595314876,
                40.79047915912888
              ],
              [
                -73.93224324588684,
                40.789692515205765
              ],
              [
                -73.93240673563922,
                40.789718503046515
              ],
              [
                -73.93270707318968,
                40.78916719218212
              ],
              [
                -73.93314749383153,
                40.788691282071184
              ],
              [
                -73.93571265998845,
                40.785680489246246
              ],
              [
                -73.93576384806458,
                40.785446039146336
              ],
              [
                -73.93579345324424,
                40.78525478115202
              ],
              [
                -73.93591823870035,
                40.78480687998174
              ],
              [
                -73.93600271220353,
                40.78467537090369
              ],
              [
                -73.93605332720759,
                40.784512368076776
              ],
              [
                -73.93607064658492,
                40.78421721581008
              ],
              [
                -73.93598226003293,
                40.7839423680532
              ],
              [
                -73.93581299799298,
                40.783710081268794
              ],
              [
                -73.93540566292114,
                40.78339449913405
              ],
              [
                -73.93502345117236,
                40.78300638201435
              ],
              [
                -73.9326708060521,
                40.782693639812365
              ],
              [
                -73.93105352803921,
                40.78239659378324
              ],
              [
                -73.93063819695584,
                40.78226141302441
              ],
              [
                -73.9303098816472,
                40.782077335895316
              ],
              [
                -73.92917352795325,
                40.78141057877102
              ],
              [
                -73.92849269580674,
                40.78100835324047
              ],
              [
                -73.92810118993663,
                40.78092032002107
              ],
              [
                -73.92778746547549,
                40.780989858917586
              ],
              [
                -73.92769650608254,
                40.78101478526652
              ],
              [
                -73.9276039547454,
                40.78103974834903
              ],
              [
                -73.92754410768512,
                40.781050635401634
              ],
              [
                -73.92732422574852,
                40.781090629324254
              ],
              [
                -73.92713754068623,
                40.78119426862356
              ],
              [
                -73.92571808678538,
                40.78217655794456
              ],
              [
                -73.9253893908798,
                40.78227414439881
              ],
              [
                -73.92524195308084,
                40.78227404884854
              ],
              [
                -73.92515351840345,
                40.78224837480777
              ],
              [
                -73.92514021519146,
                40.78224376759571
              ],
              [
                -73.92506087908808,
                40.78221629397282
              ],
              [
                -73.92488394771702,
                40.78222258277354
              ],
              [
                -73.92467329671872,
                40.782244860001015
              ],
              [
                -73.92448052280419,
                40.782320490645915
              ],
              [
                -73.92431077958685,
                40.78245595655429
              ],
              [
                -73.92424754901657,
                40.782494339126565
              ],
              [
                -73.9242362187582,
                40.78249691558929
              ],
              [
                -73.92412113996787,
                40.78252307401083
              ],
              [
                -73.92406208829156,
                40.78259027845207
              ],
              [
                -73.9240136473876,
                40.78265764398257
              ],
              [
                -73.92401145782644,
                40.78266068982501
              ],
              [
                -73.92306473139391,
                40.78334288477424
              ],
              [
                -73.92285203280137,
                40.783555693417696
              ],
              [
                -73.92218831157653,
                40.785509278139266
              ],
              [
                -73.92199195097098,
                40.78575394991961
              ],
              [
                -73.92188898370831,
                40.785701197162865
              ],
              [
                -73.91850876959093,
                40.789425089769395
              ],
              [
                -73.91859001366782,
                40.78947410390863
              ],
              [
                -73.9185629977498,
                40.78969533382591
              ],
              [
                -73.91843338465661,
                40.78980396433666
              ],
              [
                -73.91812330871126,
                40.79004023993423
              ],
              [
                -73.91784071922031,
                40.79017131993739
              ],
              [
                -73.91710622312881,
                40.790468617239384
              ],
              [
                -73.91707800430397,
                40.79045626926614
              ],
              [
                -73.9168544196006,
                40.79035844788467
              ],
              [
                -73.91616965652562,
                40.79101537285409
              ],
              [
                -73.916305770761,
                40.79111736995261
              ],
              [
                -73.91552117846275,
                40.79178325024936
              ],
              [
                -73.91535698910359,
                40.79184304950765
              ],
              [
                -73.91514134426104,
                40.79201248299488
              ],
              [
                -73.9145920846776,
                40.7927766019591
              ],
              [
                -73.91378385370594,
                40.793836285482065
              ],
              [
                -73.91380428276793,
                40.79451184645597
              ],
              [
                -73.91443827233297,
                40.79566551786385
              ],
              [
                -73.91545470966419,
                40.79706346603093
              ],
              [
                -73.91639852914044,
                40.79768484203896
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0001974713336,
        "objectid": 196,
        "shape_leng": 0.0832421106332,
        "location_id": 196,
        "zone": "Rego Park",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.85762923599995,
                40.728606921999884
              ],
              [
                -73.857827674,
                40.728974719999904
              ],
              [
                -73.85816500199988,
                40.72962493499991
              ],
              [
                -73.85837971299996,
                40.73003567799985
              ],
              [
                -73.85851097899999,
                40.73028680199992
              ],
              [
                -73.85625665099991,
                40.73095912299993
              ],
              [
                -73.85659673999993,
                40.731623059999954
              ],
              [
                -73.85704937999998,
                40.73251243399991
              ],
              [
                -73.85730927099998,
                40.73300411099994
              ],
              [
                -73.85765182999988,
                40.73366788599986
              ],
              [
                -73.85799293699985,
                40.73433365699993
              ],
              [
                -73.85834345899994,
                40.735001239999896
              ],
              [
                -73.8585833349998,
                40.73547194199988
              ],
              [
                -73.8586707819999,
                40.73561895999997
              ],
              [
                -73.85872465799989,
                40.73570864399988
              ],
              [
                -73.85877141199995,
                40.73578528999992
              ],
              [
                -73.85886555799988,
                40.735939138999875
              ],
              [
                -73.85942834899983,
                40.73578873199989
              ],
              [
                -73.86106394499986,
                40.73531449099991
              ],
              [
                -73.86284000199998,
                40.734799493999965
              ],
              [
                -73.86462193199988,
                40.73428275699996
              ],
              [
                -73.864572486,
                40.734188397999965
              ],
              [
                -73.86450230199999,
                40.73407539899988
              ],
              [
                -73.86447086199989,
                40.73400727399998
              ],
              [
                -73.86491748899991,
                40.73386650999988
              ],
              [
                -73.86524772599986,
                40.733762248999945
              ],
              [
                -73.8661043939999,
                40.733491762999904
              ],
              [
                -73.86682801299989,
                40.733245779999926
              ],
              [
                -73.86792586499988,
                40.73287256899993
              ],
              [
                -73.86810582299985,
                40.73282625499992
              ],
              [
                -73.86827761499993,
                40.732767071999874
              ],
              [
                -73.8683180679999,
                40.73275361999993
              ],
              [
                -73.86835853299982,
                40.73274015899988
              ],
              [
                -73.86845203499989,
                40.7327133759999
              ],
              [
                -73.8686094929999,
                40.73265317699983
              ],
              [
                -73.8690483869999,
                40.73251391799993
              ],
              [
                -73.86955585299982,
                40.732352842999894
              ],
              [
                -73.87054725300004,
                40.732037017999936
              ],
              [
                -73.87112231399985,
                40.73187152899993
              ],
              [
                -73.87128940500001,
                40.7318162619999
              ],
              [
                -73.87134309799984,
                40.7317993569999
              ],
              [
                -73.87149800699996,
                40.73175058699995
              ],
              [
                -73.87192058399992,
                40.731613928999884
              ],
              [
                -73.87315527199992,
                40.731243969999866
              ],
              [
                -73.87415476600002,
                40.73095008599993
              ],
              [
                -73.87481299,
                40.73075339399985
              ],
              [
                -73.87509154199992,
                40.73067136299993
              ],
              [
                -73.8749534949998,
                40.73061869599996
              ],
              [
                -73.87475475600003,
                40.730541890999916
              ],
              [
                -73.87408913199982,
                40.730282791999855
              ],
              [
                -73.87316942599995,
                40.729950618999965
              ],
              [
                -73.87235229600002,
                40.72965548399991
              ],
              [
                -73.87154749999993,
                40.72936238299992
              ],
              [
                -73.87146074199987,
                40.729326373999854
              ],
              [
                -73.87140980799985,
                40.72917810199995
              ],
              [
                -73.87113283499988,
                40.72837171999991
              ],
              [
                -73.87100156100003,
                40.727924523999896
              ],
              [
                -73.87093081299994,
                40.72770320699995
              ],
              [
                -73.87084539399993,
                40.72748459299991
              ],
              [
                -73.87074560999994,
                40.72726953899995
              ],
              [
                -73.87063185099991,
                40.727058853999914
              ],
              [
                -73.87021135899994,
                40.72618732599993
              ],
              [
                -73.86970765399998,
                40.72514643399994
              ],
              [
                -73.86931025899987,
                40.72446599099992
              ],
              [
                -73.86929270499994,
                40.7244359289999
              ],
              [
                -73.86927215699988,
                40.72441183599994
              ],
              [
                -73.8687681729999,
                40.723820906999904
              ],
              [
                -73.86845305199996,
                40.72348549399988
              ],
              [
                -73.86821068499994,
                40.723254748999885
              ],
              [
                -73.86765927399983,
                40.72266125599993
              ],
              [
                -73.86730908299992,
                40.722289024999924
              ],
              [
                -73.86711404599988,
                40.72207640399993
              ],
              [
                -73.86657241099984,
                40.721486636999884
              ],
              [
                -73.86604176200004,
                40.72090092299987
              ],
              [
                -73.86547610900004,
                40.72028093699992
              ],
              [
                -73.86523870699996,
                40.71997670999991
              ],
              [
                -73.86468729499988,
                40.719427075999874
              ],
              [
                -73.86412297599996,
                40.718844806999925
              ],
              [
                -73.8635711119999,
                40.718276679999875
              ],
              [
                -73.86301850099983,
                40.717707881999964
              ],
              [
                -73.86272195199989,
                40.71742801899989
              ],
              [
                -73.86242540499987,
                40.71714814699987
              ],
              [
                -73.86213638199993,
                40.7168767739999
              ],
              [
                -73.86183368299996,
                40.7165925429999
              ],
              [
                -73.861528867,
                40.716314936999915
              ],
              [
                -73.86122406399997,
                40.7160373289999
              ],
              [
                -73.86062663399984,
                40.71548716899991
              ],
              [
                -73.86003789899996,
                40.71493223599987
              ],
              [
                -73.85992523600002,
                40.714749735999945
              ],
              [
                -73.85982746199984,
                40.71456168899992
              ],
              [
                -73.8597452479999,
                40.71436917699986
              ],
              [
                -73.85967906599994,
                40.71417332399993
              ],
              [
                -73.85962920999985,
                40.713975294999926
              ],
              [
                -73.8596317549999,
                40.713598263999856
              ],
              [
                -73.85965416799995,
                40.71337996099988
              ],
              [
                -73.85966066799993,
                40.713294645999945
              ],
              [
                -73.85971313599988,
                40.71260553899993
              ],
              [
                -73.85975932900001,
                40.711845058999856
              ],
              [
                -73.85964591099994,
                40.711827343999886
              ],
              [
                -73.8581874529999,
                40.71159954599992
              ],
              [
                -73.85729287299985,
                40.711467676999874
              ],
              [
                -73.85744857899986,
                40.7136927999999
              ],
              [
                -73.85777168499992,
                40.71645629299992
              ],
              [
                -73.85823757099998,
                40.71961241099995
              ],
              [
                -73.85852214099987,
                40.72153379399993
              ],
              [
                -73.8586809759999,
                40.72261243999988
              ],
              [
                -73.85990796499992,
                40.72434623399987
              ],
              [
                -73.86203833799982,
                40.725956784999944
              ],
              [
                -73.86015205599992,
                40.72522704099989
              ],
              [
                -73.85960517199996,
                40.725022399999915
              ],
              [
                -73.85805416899993,
                40.72444540599987
              ],
              [
                -73.85776316799985,
                40.72490571199995
              ],
              [
                -73.857271081,
                40.72568129799989
              ],
              [
                -73.8594198799999,
                40.72646483799989
              ],
              [
                -73.85901374300002,
                40.7271089069999
              ],
              [
                -73.85860783699987,
                40.727752283999926
              ],
              [
                -73.85820066499991,
                40.72839135199994
              ],
              [
                -73.85812618599992,
                40.72850824599991
              ],
              [
                -73.8580876569999,
                40.72856872199988
              ],
              [
                -73.85775002599989,
                40.72844560099985
              ],
              [
                -73.85741240699991,
                40.72832247999986
              ],
              [
                -73.85771592500002,
                40.728505460999926
              ],
              [
                -73.85762923599995,
                40.728606921999884
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000801651953985,
        "objectid": 195,
        "shape_leng": 0.131308244067,
        "location_id": 195,
        "zone": "Red Hook",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -74.0071710969999,
                40.6863570099999
              ],
              [
                -74.00753083199993,
                40.68607448699991
              ],
              [
                -74.0077170754789,
                40.686264429009206
              ],
              [
                -74.00776820497654,
                40.68624901912176
              ],
              [
                -74.00782671596491,
                40.68624036700246
              ],
              [
                -74.00788683480657,
                40.68624536814394
              ],
              [
                -74.00793423715932,
                40.686281348969096
              ],
              [
                -74.00798157801087,
                40.68627511006106
              ],
              [
                -74.00816181924652,
                40.68617442307402
              ],
              [
                -74.00816320571415,
                40.686173644858464
              ],
              [
                -74.00842516199995,
                40.68601553199992
              ],
              [
                -74.00851294299997,
                40.68596254899994
              ],
              [
                -74.0086007239999,
                40.68590956499989
              ],
              [
                -74.010928413,
                40.684491472999845
              ],
              [
                -74.01193259955522,
                40.68388774907754
              ],
              [
                -74.01178272201199,
                40.683759995499265
              ],
              [
                -74.01077312339376,
                40.68435861554086
              ],
              [
                -74.0100860394785,
                40.68365543104351
              ],
              [
                -74.00967108558879,
                40.683268727715024
              ],
              [
                -74.01060858082327,
                40.68246876026793
              ],
              [
                -74.01186281377171,
                40.6813984795506
              ],
              [
                -74.013018354221,
                40.68041237401426
              ],
              [
                -74.013742066444,
                40.68091770134299
              ],
              [
                -74.01387398777976,
                40.68100981281634
              ],
              [
                -74.01360863270277,
                40.68122194672818
              ],
              [
                -74.01410549647974,
                40.68171251937541
              ],
              [
                -74.01213481688791,
                40.68290592802506
              ],
              [
                -74.01212285893645,
                40.68296217785077
              ],
              [
                -74.01262428295385,
                40.68346869619578
              ],
              [
                -74.01282000316418,
                40.68362241624865
              ],
              [
                -74.01486782800362,
                40.6823832140236
              ],
              [
                -74.0150829519353,
                40.68225409916025
              ],
              [
                -74.01532059799361,
                40.68211072889028
              ],
              [
                -74.01520082289557,
                40.68197941921398
              ],
              [
                -74.01678833975089,
                40.68102196539755
              ],
              [
                -74.01684837001166,
                40.680985758993785
              ],
              [
                -74.01746928868555,
                40.68061473185043
              ],
              [
                -74.01757049414064,
                40.68065064806215
              ],
              [
                -74.01764735092732,
                40.6806018909208
              ],
              [
                -74.01772532586205,
                40.680661902836746
              ],
              [
                -74.01899105402694,
                40.67990499335191
              ],
              [
                -74.01892813994154,
                40.6798443050555
              ],
              [
                -74.01906833414864,
                40.67976074748824
              ],
              [
                -74.01928128101007,
                40.67964814029766
              ],
              [
                -74.01955261263586,
                40.6790292520846
              ],
              [
                -74.01955551398514,
                40.67901674720214
              ],
              [
                -74.0195556826687,
                40.679004049385874
              ],
              [
                -74.01955311412145,
                40.678991502387134
              ],
              [
                -74.01954787787973,
                40.67897944587447
              ],
              [
                -74.01954011569893,
                40.67896820623785
              ],
              [
                -74.01953003771537,
                40.67895808775279
              ],
              [
                -74.01951791675745,
                40.67894936434322
              ],
              [
                -74.01950408096023,
                40.67894227216632
              ],
              [
                -74.01948890488147,
                40.67893700321874
              ],
              [
                -74.01947279936257,
                40.67893370013941
              ],
              [
                -74.01945620040586,
                40.67893245234804
              ],
              [
                -74.01943955737198,
                40.678933293624226
              ],
              [
                -74.0192135121457,
                40.67896311902051
              ],
              [
                -74.01879335612368,
                40.67897633313838
              ],
              [
                -74.01857882788774,
                40.678983079472324
              ],
              [
                -74.01838313797529,
                40.67874482169809
              ],
              [
                -74.01832227122632,
                40.67872166500742
              ],
              [
                -74.01828314900892,
                40.678728289412085
              ],
              [
                -74.01825267004557,
                40.67855288672587
              ],
              [
                -74.01841786210231,
                40.67854955189746
              ],
              [
                -74.01840544610997,
                40.67844222006227
              ],
              [
                -74.01851059175075,
                40.67844051183506
              ],
              [
                -74.01856476167906,
                40.67843963246608
              ],
              [
                -74.01859505982546,
                40.67846098249659
              ],
              [
                -74.01859998188733,
                40.67846098170102
              ],
              [
                -74.01860179179258,
                40.67846098140842
              ],
              [
                -74.01863191295386,
                40.67843476230332
              ],
              [
                -74.01863319772804,
                40.67843364217266
              ],
              [
                -74.01871751760083,
                40.67843166874766
              ],
              [
                -74.01877503939653,
                40.67843032099711
              ],
              [
                -74.01878017201017,
                40.678430202722595
              ],
              [
                -74.01881159159161,
                40.678451550837714
              ],
              [
                -74.01881607949576,
                40.67845155010387
              ],
              [
                -74.01882433869434,
                40.67844485367609
              ],
              [
                -74.01884757078854,
                40.67842601741433
              ],
              [
                -74.01885085228994,
                40.678423357206974
              ],
              [
                -74.01898714122163,
                40.6784200921312
              ],
              [
                -74.01899029164359,
                40.67842001639134
              ],
              [
                -74.01899445934592,
                40.67841991686206
              ],
              [
                -74.01902587989507,
                40.67844211914231
              ],
              [
                -74.01903013778114,
                40.6784426585475
              ],
              [
                -74.0190326112239,
                40.6784429727554
              ],
              [
                -74.01906401917516,
                40.6784156345743
              ],
              [
                -74.01912079360895,
                40.6784125619095
              ],
              [
                -74.0192065036037,
                40.6784079243722
              ],
              [
                -74.01924241195326,
                40.6784318341242
              ],
              [
                -74.01924689963592,
                40.67843183337361
              ],
              [
                -74.01927485273436,
                40.67840833946815
              ],
              [
                -74.01927671763752,
                40.67840677243678
              ],
              [
                -74.01927942798392,
                40.678404494946236
              ],
              [
                -74.0193650147007,
                40.67840202997831
              ],
              [
                -74.01942574949798,
                40.67840028231049
              ],
              [
                -74.01942864377901,
                40.67840019839305
              ],
              [
                -74.019518417501,
                40.67845912061587
              ],
              [
                -74.01955208101631,
                40.67848217760074
              ],
              [
                -74.01966312651498,
                40.67838820127059
              ],
              [
                -74.01966200306937,
                40.67838563895895
              ],
              [
                -74.01953856537543,
                40.67830195259355
              ],
              [
                -74.0194723889745,
                40.678358337050625
              ],
              [
                -74.01902137413651,
                40.678377204914746
              ],
              [
                -74.01898995247258,
                40.678353293180585
              ],
              [
                -74.01898322115336,
                40.67835244023516
              ],
              [
                -74.01895405840415,
                40.678381486304616
              ],
              [
                -74.01866235777157,
                40.67839434600305
              ],
              [
                -74.01864991332955,
                40.67838487583739
              ],
              [
                -74.01862981462028,
                40.67836958079897
              ],
              [
                -74.01862787070775,
                40.6783695811137
              ],
              [
                -74.0186253260616,
                40.678369581525644
              ],
              [
                -74.0185950422009,
                40.67839777362915
              ],
              [
                -74.01841103183156,
                40.67840712127843
              ],
              [
                -74.01842965849707,
                40.67792758482305
              ],
              [
                -74.01843040661609,
                40.677908321864855
              ],
              [
                -74.01903387384746,
                40.677876998462985
              ],
              [
                -74.01904108711257,
                40.677876624017756
              ],
              [
                -74.01906541502082,
                40.67785015211889
              ],
              [
                -74.01908257644466,
                40.677831478813324
              ],
              [
                -74.01908763812094,
                40.67783123639877
              ],
              [
                -74.01917743946889,
                40.67782694900701
              ],
              [
                -74.01921892737656,
                40.67777728854646
              ],
              [
                -74.01919098767956,
                40.677743271179814
              ],
              [
                -74.01918927313335,
                40.67774118273031
              ],
              [
                -74.01914183574368,
                40.67772313432773
              ],
              [
                -74.01908257386326,
                40.67713956404753
              ],
              [
                -74.01995197864672,
                40.67710301044995
              ],
              [
                -74.01976925613225,
                40.67661322491722
              ],
              [
                -74.01912261786643,
                40.67661698915941
              ],
              [
                -74.01910985389938,
                40.67654584509902
              ],
              [
                -74.01797996235578,
                40.67657189835662
              ],
              [
                -74.01797569982641,
                40.67651692060813
              ],
              [
                -74.01791198226178,
                40.67651046280194
              ],
              [
                -74.01789207055593,
                40.67644077862896
              ],
              [
                -74.01788008221803,
                40.67637005104178
              ],
              [
                -74.017876105569,
                40.676298801257545
              ],
              [
                -74.01788016988853,
                40.67622755434058
              ],
              [
                -74.01789224519982,
                40.67615683533195
              ],
              [
                -74.01791224249098,
                40.67608716538086
              ],
              [
                -74.01794001437204,
                40.6760190579041
              ],
              [
                -74.01797535616167,
                40.67595301480272
              ],
              [
                -74.018018007396,
                40.67588952276335
              ],
              [
                -74.01839604996148,
                40.675879760737644
              ],
              [
                -74.01842037213925,
                40.675878100894636
              ],
              [
                -74.0184439492843,
                40.6758732593221
              ],
              [
                -74.01846606893977,
                40.675865382323295
              ],
              [
                -74.01848606269128,
                40.6758547079266
              ],
              [
                -74.0185033263665,
                40.675841558691964
              ],
              [
                -74.01851733829146,
                40.675826331964494
              ],
              [
                -74.01852767505498,
                40.67580948786671
              ],
              [
                -74.01853402430305,
                40.675791535394815
              ],
              [
                -74.01853619417705,
                40.67577301703707
              ],
              [
                -74.01848092689923,
                40.675604859056556
              ],
              [
                -74.01846054380597,
                40.67559227783935
              ],
              [
                -74.01844278335878,
                40.67557757157944
              ],
              [
                -74.01842802392619,
                40.67556105358067
              ],
              [
                -74.01841657994261,
                40.67554307574418
              ],
              [
                -74.0184086952087,
                40.675524021071425
              ],
              [
                -74.0188524118484,
                40.675127025698735
              ],
              [
                -74.01885603431671,
                40.67511905276392
              ],
              [
                -74.01885783747345,
                40.675110728731944
              ],
              [
                -74.0188577695892,
                40.6751022924089
              ],
              [
                -74.01885583261196,
                40.6750939858223
              ],
              [
                -74.01885208211192,
                40.67508604727746
              ],
              [
                -74.0188466256867,
                40.67507870452095
              ],
              [
                -74.01883961987457,
                40.67507216820711
              ],
              [
                -74.01883126566364,
                40.675066625854186
              ],
              [
                -74.01882180272558,
                40.67506223646492
              ],
              [
                -74.01881150253966,
                40.6750591259652
              ],
              [
                -74.01880066060488,
                40.67505738359123
              ],
              [
                -74.01878958796178,
                40.6750570593293
              ],
              [
                -74.01877860226983,
                40.675058162481996
              ],
              [
                -74.01876801869413,
                40.675060661401396
              ],
              [
                -74.01875814086333,
                40.67506448439689
              ],
              [
                -74.01851887130543,
                40.675273889640124
              ],
              [
                -74.01842523089353,
                40.675214741574976
              ],
              [
                -74.01847650481606,
                40.67516266940931
              ],
              [
                -74.0183929089602,
                40.675107724147864
              ],
              [
                -74.01818210632884,
                40.67529143140757
              ],
              [
                -74.01826950310674,
                40.67534926808163
              ],
              [
                -74.01832457843103,
                40.67530442504134
              ],
              [
                -74.0184123582175,
                40.67536544993416
              ],
              [
                -74.01829802655324,
                40.6754591791814
              ],
              [
                -74.01822964241143,
                40.67546208190086
              ],
              [
                -74.01819544347482,
                40.67544039313992
              ],
              [
                -74.01814986189117,
                40.675467879414875
              ],
              [
                -74.01783447572687,
                40.67525822301435
              ],
              [
                -74.01798640472803,
                40.67512514362107
              ],
              [
                -74.01792370591399,
                40.675075981326394
              ],
              [
                -74.01801676669672,
                40.675009439505125
              ],
              [
                -74.01800916427273,
                40.67499353142102
              ],
              [
                -74.01803574975752,
                40.6749588168435
              ],
              [
                -74.01803004073714,
                40.67492410782759
              ],
              [
                -74.01802399440471,
                40.67485275836311
              ],
              [
                -74.01804519537194,
                40.67476791076818
              ],
              [
                -74.01817618274653,
                40.674464177036725
              ],
              [
                -74.01817794541266,
                40.67444161501924
              ],
              [
                -74.01817468570435,
                40.674419149796904
              ],
              [
                -74.01816649698902,
                40.674397424802805
              ],
              [
                -74.01815361380635,
                40.67437706226808
              ],
              [
                -74.01813640515077,
                40.67435864540075
              ],
              [
                -74.01811536390225,
                40.67434270168224
              ],
              [
                -74.01780187203198,
                40.67409544141244
              ],
              [
                -74.01785694945181,
                40.67405783059562
              ],
              [
                -74.01783604921178,
                40.674040479131776
              ],
              [
                -74.01792341013338,
                40.67397538419331
              ],
              [
                -74.01795760572409,
                40.67398694866511
              ],
              [
                -74.01801077320536,
                40.67391318105236
              ],
              [
                -74.01794428080251,
                40.67388282049685
              ],
              [
                -74.01787971614986,
                40.67395658985093
              ],
              [
                -74.01789301471658,
                40.67396526455016
              ],
              [
                -74.01781515223432,
                40.674030357995235
              ],
              [
                -74.01779805190311,
                40.67401589897323
              ],
              [
                -74.01775247096357,
                40.67404772289249
              ],
              [
                -74.0172928251957,
                40.673628215024586
              ],
              [
                -74.01727138470127,
                40.67360864580548
              ],
              [
                -74.0188009477692,
                40.6722507648487
              ],
              [
                -74.01816839280792,
                40.671939240601084
              ],
              [
                -74.01670765236591,
                40.673193774421705
              ],
              [
                -74.01543956638726,
                40.67427288215864
              ],
              [
                -74.01496392184575,
                40.674677630294255
              ],
              [
                -74.01457946720949,
                40.674414017071584
              ],
              [
                -74.01632099922307,
                40.6729082695637
              ],
              [
                -74.01511918663962,
                40.67215699596906
              ],
              [
                -74.0139510936524,
                40.6731115881933
              ],
              [
                -74.01336378218133,
                40.67359153693441
              ],
              [
                -74.01297587792189,
                40.67332791872094
              ],
              [
                -74.01422923190452,
                40.6722072054929
              ],
              [
                -74.01428722178991,
                40.67224118299987
              ],
              [
                -74.01568625497515,
                40.671038834928865
              ],
              [
                -74.01559644489554,
                40.67097047751736
              ],
              [
                -74.01423289452782,
                40.672130000512986
              ],
              [
                -74.01387273763235,
                40.67188076176044
              ],
              [
                -74.01412302856022,
                40.67166933413736
              ],
              [
                -74.01436920648234,
                40.67145479665876
              ],
              [
                -74.0151998057007,
                40.6707540276644
              ],
              [
                -74.01508006471072,
                40.67068567329552
              ],
              [
                -74.01427407257738,
                40.67137929918189
              ],
              [
                -74.01399134213433,
                40.67119359160873
              ],
              [
                -74.01393626689504,
                40.671179076877884
              ],
              [
                -74.01387900570414,
                40.67117081152283
              ],
              [
                -74.01382077477412,
                40.67116897109753
              ],
              [
                -74.0137628109139,
                40.67117359469209
              ],
              [
                -74.01370634525973,
                40.671184584102704
              ],
              [
                -74.01365024646175,
                40.671202612725985
              ],
              [
                -74.0135983928744,
                40.67122691731589
              ],
              [
                -74.01355198671448,
                40.67125693437712
              ],
              [
                -74.01261963416778,
                40.67084190894051
              ],
              [
                -74.01257090731009,
                40.67087467728396
              ],
              [
                -74.01251715357101,
                40.670902518051534
              ],
              [
                -74.01245922701378,
                40.67092498889416
              ],
              [
                -74.0123980480043,
                40.67094173278214
              ],
              [
                -74.01233458858763,
                40.67095248367923
              ],
              [
                -74.01226985704386,
                40.67095707076886
              ],
              [
                -74.01220488186557,
                40.670955421168514
              ],
              [
                -74.01214069541709,
                40.67094756108805
              ],
              [
                -74.0120783175301,
                40.670933615413155
              ],
              [
                -74.01201873929931,
                40.67091380572068
              ],
              [
                -74.01196290733463,
                40.670888446758354
              ],
              [
                -74.01191170872066,
                40.67085794144327
              ],
              [
                -74.01186776616825,
                40.67082434412613
              ],
              [
                -74.01182946197498,
                40.670786959294624
              ],
              [
                -74.01179735454902,
                40.67074633196386
              ],
              [
                -74.01177191195623,
                40.670703054418496
              ],
              [
                -74.01175350509699,
                40.67065775757834
              ],
              [
                -74.01424180477498,
                40.669808370538796
              ],
              [
                -74.01424926931875,
                40.66972860473707
              ],
              [
                -74.01406219828175,
                40.66970583843647
              ],
              [
                -74.01162527521356,
                40.670581028275876
              ],
              [
                -74.01151443463287,
                40.67040702338139
              ],
              [
                -74.01162173116997,
                40.67011434594475
              ],
              [
                -74.01154288031563,
                40.66978434275026
              ],
              [
                -74.01087343978188,
                40.66971011555842
              ],
              [
                -74.01091024340404,
                40.66919049236948
              ],
              [
                -74.0105598064476,
                40.66919052506966
              ],
              [
                -74.01033845947926,
                40.669078195448016
              ],
              [
                -74.0103568366731,
                40.66864283538849
              ],
              [
                -74.01087664028981,
                40.66860161576353
              ],
              [
                -74.01227967872792,
                40.66573241415882
              ],
              [
                -74.0159369853455,
                40.66547079172142
              ],
              [
                -74.01602551776006,
                40.664863048788035
              ],
              [
                -74.01648665554487,
                40.664930508953816
              ],
              [
                -74.01657536097834,
                40.665038537839834
              ],
              [
                -74.01668768368882,
                40.66543706726947
              ],
              [
                -74.01751037086694,
                40.67103453098416
              ],
              [
                -74.01888283363327,
                40.67160166620541
              ],
              [
                -74.01933946871777,
                40.671624948422604
              ],
              [
                -74.01876336878675,
                40.67107587449439
              ],
              [
                -74.01857605010865,
                40.67039241386994
              ],
              [
                -74.01788719149395,
                40.66585046628535
              ],
              [
                -74.01744446051002,
                40.66526799635173
              ],
              [
                -74.01690267361069,
                40.66484660233887
              ],
              [
                -74.01574872882654,
                40.66456833629161
              ],
              [
                -74.01154017543327,
                40.66508591975661
              ],
              [
                -74.00985411855632,
                40.66852362833534
              ],
              [
                -74.00708724221813,
                40.668591358535764
              ],
              [
                -74.00779653349619,
                40.66715977077725
              ],
              [
                -74.00754821618384,
                40.667051745733595
              ],
              [
                -74.00680345938905,
                40.668591375668285
              ],
              [
                -74.00662609870568,
                40.668631901133274
              ],
              [
                -74.0060285141717,
                40.669784746279085
              ],
              [
                -74.00543926529352,
                40.6709214682384
              ],
              [
                -74.00475388692331,
                40.67072135894501
              ],
              [
                -74.00531907552737,
                40.66955428536639
              ],
              [
                -74.00604596458896,
                40.66805324348048
              ],
              [
                -74.00625662073614,
                40.6675957547138
              ],
              [
                -74.00630635035533,
                40.66749553361808
              ],
              [
                -74.00675023792375,
                40.666538299107046
              ],
              [
                -74.00689626622095,
                40.6662233826561
              ],
              [
                -74.00684452428645,
                40.666212448349675
              ],
              [
                -74.00686975908653,
                40.666159079647606
              ],
              [
                -74.00688141944153,
                40.66613246713734
              ],
              [
                -74.0068962959208,
                40.66613399560906
              ],
              [
                -74.00689654893131,
                40.666134001122344
              ],
              [
                -74.00690934024162,
                40.66613347377333
              ],
              [
                -74.00692182955493,
                40.666131304706845
              ],
              [
                -74.00693365614498,
                40.666127556571325
              ],
              [
                -74.00694447842689,
                40.6661223376235
              ],
              [
                -74.00695398382344,
                40.66611579860116
              ],
              [
                -74.00696189779265,
                40.66610812836938
              ],
              [
                -74.00696799175797,
                40.666099548465645
              ],
              [
                -74.00697208970928,
                40.66609030670144
              ],
              [
                -74.00697407328752,
                40.66608067000398
              ],
              [
                -74.00697388520256,
                40.66607091670768
              ],
              [
                -74.0069715308875,
                40.666061328514246
              ],
              [
                -74.00696707834247,
                40.66605218235668
              ],
              [
                -74.00696065616967,
                40.66604374240062
              ],
              [
                -74.00695244985948,
                40.66603625241477
              ],
              [
                -74.00694269643263,
                40.66602992872995
              ],
              [
                -74.00693167759442,
                40.66602495399109
              ],
              [
                -74.00691971159856,
                40.66602147188171
              ],
              [
                -74.0069071440549,
                40.66601958297461
              ],
              [
                -74.00689433794756,
                40.666019341826235
              ],
              [
                -74.00688166315058,
                40.66602075540171
              ],
              [
                -74.00686948574585,
                40.6660237828732
              ],
              [
                -74.00685815744939,
                40.666028336799286
              ],
              [
                -74.0068480054524,
                40.666034285650504
              ],
              [
                -74.00683932297235,
                40.66604145760807
              ],
              [
                -74.00683236078251,
                40.66604964552678
              ],
              [
                -74.00682731997071,
                40.66605861291754
              ],
              [
                -74.00682434612966,
                40.666068100778055
              ],
              [
                -74.00682352515308,
                40.666077835072954
              ],
              [
                -74.0068248807538,
                40.666087534649485
              ],
              [
                -74.00682837377936,
                40.66609691935749
              ],
              [
                -74.00683390334235,
                40.66610571814059
              ],
              [
                -74.00684130973484,
                40.666113676866004
              ],
              [
                -74.00685037903985,
                40.666120565663505
              ],
              [
                -74.00686084931154,
                40.6661261855656
              ],
              [
                -74.0068671303844,
                40.66612878941264
              ],
              [
                -74.00683085521844,
                40.66620895670721
              ],
              [
                -74.00677062553615,
                40.66618886244351
              ],
              [
                -74.0065952902589,
                40.66656108418717
              ],
              [
                -74.00656004211396,
                40.66663591439603
              ],
              [
                -74.00631442722586,
                40.66713963653245
              ],
              [
                -74.0062287607646,
                40.66732597312532
              ],
              [
                -74.00625704452314,
                40.66733351369734
              ],
              [
                -74.00612925948894,
                40.66761146714417
              ],
              [
                -74.00600812868151,
                40.66788538149734
              ],
              [
                -74.00597850124245,
                40.667882004170515
              ],
              [
                -74.0058636618068,
                40.66799376258568
              ],
              [
                -74.00552727453992,
                40.6678992047115
              ],
              [
                -74.00547017208598,
                40.66795127142809
              ],
              [
                -74.00510227346261,
                40.667975647728206
              ],
              [
                -74.00510111198017,
                40.66797532110035
              ],
              [
                -74.0044025343906,
                40.6677790863226
              ],
              [
                -74.00398842006275,
                40.66766564164089
              ],
              [
                -74.00363026351536,
                40.667567523356496
              ],
              [
                -74.00369462000533,
                40.66742761120842
              ],
              [
                -74.00319075274831,
                40.667263199814926
              ],
              [
                -74.00289483876944,
                40.667348465949345
              ],
              [
                -74.00221506816538,
                40.668578637441165
              ],
              [
                -74.00141527103162,
                40.66835941434697
              ],
              [
                -74.0010566411318,
                40.668945681717275
              ],
              [
                -74.00030337854434,
                40.66871689224848
              ],
              [
                -73.99991082003736,
                40.66949866821084
              ],
              [
                -73.99962813682909,
                40.67011307640566
              ],
              [
                -73.99962139936635,
                40.670127167946426
              ],
              [
                -73.99931101149451,
                40.67077263902337
              ],
              [
                -73.99892591269524,
                40.671414905753885
              ],
              [
                -73.99876941079444,
                40.67160204705256
              ],
              [
                -73.99868108199985,
                40.67173915799987
              ],
              [
                -73.9986188799999,
                40.67183017199988
              ],
              [
                -73.99898242799982,
                40.67241358399986
              ],
              [
                -73.99903998999996,
                40.67250767899989
              ],
              [
                -73.99934534299987,
                40.673006548999915
              ],
              [
                -73.99983349199978,
                40.67375604999996
              ],
              [
                -73.99991051299993,
                40.67387272999985
              ],
              [
                -73.99994578200001,
                40.673926156999876
              ],
              [
                -74.00045011499992,
                40.674769425999926
              ],
              [
                -74.00077979399993,
                40.67529390099993
              ],
              [
                -74.00105973699986,
                40.67555567799996
              ],
              [
                -74.00112481699996,
                40.675697553999925
              ],
              [
                -74.00090355299992,
                40.67563696899988
              ],
              [
                -74.00083254999997,
                40.67561225999985
              ],
              [
                -74.0006740919999,
                40.67555578899988
              ],
              [
                -74.00102822999993,
                40.67641403999991
              ],
              [
                -74.00153194399996,
                40.67727039699989
              ],
              [
                -74.0021569849999,
                40.67818268099997
              ],
              [
                -74.00221991299993,
                40.67826032199985
              ],
              [
                -74.00255365299998,
                40.678658683999856
              ],
              [
                -74.00310560799991,
                40.679331885999936
              ],
              [
                -74.00329231299999,
                40.67964328799994
              ],
              [
                -74.0033891689999,
                40.679721305999905
              ],
              [
                -74.00345372099991,
                40.6797898869999
              ],
              [
                -74.00371762999994,
                40.68002103199994
              ],
              [
                -74.00378241799994,
                40.68008600199992
              ],
              [
                -74.00447389899988,
                40.680993011999846
              ],
              [
                -74.00456896599997,
                40.681149975999865
              ],
              [
                -74.00503641499995,
                40.681910287999884
              ],
              [
                -74.00560068499993,
                40.68281599899994
              ],
              [
                -74.00567492299993,
                40.682929927999965
              ],
              [
                -74.00596041099988,
                40.683362870999936
              ],
              [
                -74.00581925399995,
                40.683656554999914
              ],
              [
                -74.0054980969999,
                40.68432595799989
              ],
              [
                -74.0051790759999,
                40.684997224999904
              ],
              [
                -74.00485241599992,
                40.68566813099989
              ],
              [
                -74.00549714599993,
                40.6858477269999
              ],
              [
                -74.0071710969999,
                40.6863570099999
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000615129861659,
        "objectid": 203,
        "shape_leng": 0.189937815868,
        "location_id": 203,
        "zone": "Rosedale",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.72753500301229,
                40.673930284146124
              ],
              [
                -73.72755616378211,
                40.674161588095764
              ],
              [
                -73.72753358410341,
                40.674213863808255
              ],
              [
                -73.72744080479352,
                40.67442874117539
              ],
              [
                -73.72714134234852,
                40.67509587592297
              ],
              [
                -73.72654317309771,
                40.67648672981019
              ],
              [
                -73.72619806197862,
                40.67738088986485
              ],
              [
                -73.72601116044585,
                40.678078338165896
              ],
              [
                -73.72563005109944,
                40.679587950890664
              ],
              [
                -73.72568736813386,
                40.680250454931496
              ],
              [
                -73.72575419113436,
                40.680945914047264
              ],
              [
                -73.72582367682486,
                40.6816357508064
              ],
              [
                -73.7259066008032,
                40.682656604812024
              ],
              [
                -73.72586264191175,
                40.6832410809739
              ],
              [
                -73.72658306499989,
                40.68341482999995
              ],
              [
                -73.72666460699985,
                40.683436888999964
              ],
              [
                -73.72675245899993,
                40.68346409399994
              ],
              [
                -73.72718272499995,
                40.68359732999989
              ],
              [
                -73.72720084199985,
                40.68356123499993
              ],
              [
                -73.72810238799995,
                40.681765636999934
              ],
              [
                -73.72830180799986,
                40.681484974999925
              ],
              [
                -73.72851656799985,
                40.68121047299993
              ],
              [
                -73.72874616700001,
                40.68094285799989
              ],
              [
                -73.72898998699992,
                40.68068281299987
              ],
              [
                -73.72924739999999,
                40.68043098599987
              ],
              [
                -73.72950555799994,
                40.680055725999885
              ],
              [
                -73.73047301200002,
                40.67934793699989
              ],
              [
                -73.73106142499994,
                40.678306620999905
              ],
              [
                -73.73187383,
                40.67756892099987
              ],
              [
                -73.7330322339999,
                40.676495025999905
              ],
              [
                -73.733322441,
                40.67613256599993
              ],
              [
                -73.73384204199998,
                40.67546337699988
              ],
              [
                -73.73403489799982,
                40.6752376569999
              ],
              [
                -73.73502751399988,
                40.674028289999896
              ],
              [
                -73.73505915299991,
                40.67398974999989
              ],
              [
                -73.73509933199999,
                40.67392425699991
              ],
              [
                -73.73561853899999,
                40.67313052699989
              ],
              [
                -73.73597469399992,
                40.67256742099995
              ],
              [
                -73.736249418,
                40.6726306019999
              ],
              [
                -73.73593438599974,
                40.67243297099988
              ],
              [
                -73.73718424499995,
                40.670909812999895
              ],
              [
                -73.73735527499991,
                40.67061884099995
              ],
              [
                -73.7375467379998,
                40.67033434399988
              ],
              [
                -73.73775794399985,
                40.670057546999935
              ],
              [
                -73.73798806,
                40.66978963599991
              ],
              [
                -73.73823608799982,
                40.66953170599987
              ],
              [
                -73.73850086499984,
                40.66928477299995
              ],
              [
                -73.73869946900007,
                40.6690682359999
              ],
              [
                -73.73900041699996,
                40.668749285999866
              ],
              [
                -73.73921986399984,
                40.66851888399988
              ],
              [
                -73.73993331699991,
                40.66776382799988
              ],
              [
                -73.74080404599995,
                40.66684396799993
              ],
              [
                -73.74117817299991,
                40.66640295399989
              ],
              [
                -73.74042437599985,
                40.66640252299988
              ],
              [
                -73.74021946399998,
                40.6664022769999
              ],
              [
                -73.74010286899983,
                40.666402139999896
              ],
              [
                -73.73993110699995,
                40.66640155399988
              ],
              [
                -73.73856469500002,
                40.666396897999924
              ],
              [
                -73.73891022900001,
                40.66595449199986
              ],
              [
                -73.73920855099999,
                40.66562918899996
              ],
              [
                -73.73937134199998,
                40.66545222799987
              ],
              [
                -73.73954958300003,
                40.66525602199989
              ],
              [
                -73.74281884699987,
                40.66530554899988
              ],
              [
                -73.74340973899989,
                40.6644041699999
              ],
              [
                -73.74374970899983,
                40.66409039299991
              ],
              [
                -73.74405313899989,
                40.663658070999944
              ],
              [
                -73.74450922599979,
                40.66382127799988
              ],
              [
                -73.7461369799999,
                40.66171671999991
              ],
              [
                -73.74684750599981,
                40.66079554099986
              ],
              [
                -73.74679260099995,
                40.65890762199992
              ],
              [
                -73.74733230899986,
                40.65912452099992
              ],
              [
                -73.74779788899987,
                40.65852210299988
              ],
              [
                -73.74853177199994,
                40.65757569699993
              ],
              [
                -73.74807112499987,
                40.6575484919999
              ],
              [
                -73.74705195899993,
                40.65748828899993
              ],
              [
                -73.74679970800001,
                40.65747128099992
              ],
              [
                -73.74618048999982,
                40.657439074999914
              ],
              [
                -73.74506037799982,
                40.65740714999988
              ],
              [
                -73.74492278899996,
                40.656478974999914
              ],
              [
                -73.74487420299995,
                40.656144557999944
              ],
              [
                -73.74377018899996,
                40.656139023999934
              ],
              [
                -73.74328714099991,
                40.656134731999934
              ],
              [
                -73.74329846199996,
                40.655423526999925
              ],
              [
                -73.74330720799989,
                40.654708558999886
              ],
              [
                -73.74331194199989,
                40.653994419999975
              ],
              [
                -73.74331805599994,
                40.65328120399988
              ],
              [
                -73.74332546799988,
                40.6525378039999
              ],
              [
                -73.74654917999989,
                40.65255348199986
              ],
              [
                -73.74867194699979,
                40.65267808799992
              ],
              [
                -73.74920814100003,
                40.652933863999934
              ],
              [
                -73.7493714279999,
                40.653034834999914
              ],
              [
                -73.75109427999985,
                40.65421244099991
              ],
              [
                -73.75149741999986,
                40.65433688199992
              ],
              [
                -73.752088853,
                40.65451770399988
              ],
              [
                -73.75294892899993,
                40.65488504099995
              ],
              [
                -73.75371894599986,
                40.65536538099991
              ],
              [
                -73.75606608399983,
                40.656486460999886
              ],
              [
                -73.75680959499985,
                40.65709328399992
              ],
              [
                -73.75763169899993,
                40.65735762899988
              ],
              [
                -73.75819056099982,
                40.65755494699994
              ],
              [
                -73.75845384399994,
                40.657647902999955
              ],
              [
                -73.75922652199985,
                40.657902235999906
              ],
              [
                -73.76003600699998,
                40.658073440999885
              ],
              [
                -73.76165732899996,
                40.65823808599986
              ],
              [
                -73.76166370199995,
                40.65789692799995
              ],
              [
                -73.76166371699979,
                40.65789649699987
              ],
              [
                -73.76166374199983,
                40.6578960729999
              ],
              [
                -73.76168563999983,
                40.657555109999876
              ],
              [
                -73.76168565299982,
                40.65755497499989
              ],
              [
                -73.76168566599995,
                40.65755484799992
              ],
              [
                -73.76172307699989,
                40.657214420999935
              ],
              [
                -73.76172311299987,
                40.65721414199993
              ],
              [
                -73.76172316099992,
                40.65721386399987
              ],
              [
                -73.76177602300001,
                40.65687469299998
              ],
              [
                -73.76177603499984,
                40.65687459399989
              ],
              [
                -73.76177605899989,
                40.656874485999886
              ],
              [
                -73.76184429799997,
                40.656536949999925
              ],
              [
                -73.76196576499984,
                40.65641589199987
              ],
              [
                -73.76210493899991,
                40.65630306599995
              ],
              [
                -73.76226027499987,
                40.65620066499993
              ],
              [
                -73.76242965099998,
                40.6561106019999
              ],
              [
                -73.76261039799991,
                40.65603444099988
              ],
              [
                -73.76279952199998,
                40.65597330699988
              ],
              [
                -73.76299380499994,
                40.65592780799995
              ],
              [
                -73.76319000499986,
                40.655898053999884
              ],
              [
                -73.76404059399991,
                40.654393294999856
              ],
              [
                -73.76406290099993,
                40.65435626599989
              ],
              [
                -73.7579393749999,
                40.65085330299991
              ],
              [
                -73.75465758099988,
                40.64901209699989
              ],
              [
                -73.75411086699988,
                40.648697664999915
              ],
              [
                -73.75416443199985,
                40.648654094999856
              ],
              [
                -73.7545491879999,
                40.64834992799991
              ],
              [
                -73.75483677999976,
                40.648070902999976
              ],
              [
                -73.75502113300001,
                40.6478138189999
              ],
              [
                -73.75522386899995,
                40.64744446899988
              ],
              [
                -73.75460006764922,
                40.6472007730313
              ],
              [
                -73.7549204502295,
                40.64756676385092
              ],
              [
                -73.75487502521172,
                40.647680932847216
              ],
              [
                -73.75475454803566,
                40.647806369149464
              ],
              [
                -73.75450655308,
                40.64793153307261
              ],
              [
                -73.75439420696924,
                40.64788558617587
              ],
              [
                -73.75428169216147,
                40.647885346006184
              ],
              [
                -73.75406462691677,
                40.64775918987507
              ],
              [
                -73.75388954423967,
                40.64754839624724
              ],
              [
                -73.75383530017639,
                40.647529730160265
              ],
              [
                -73.75377829206127,
                40.647516691037595
              ],
              [
                -73.75371952093603,
                40.64750950786568
              ],
              [
                -73.7536600188211,
                40.64750830621547
              ],
              [
                -73.75363797324661,
                40.64750784198052
              ],
              [
                -73.75361627009028,
                40.6475048600306
              ],
              [
                -73.75359540119142,
                40.64749942821127
              ],
              [
                -73.75357584091819,
                40.64749167017348
              ],
              [
                -73.75355803278856,
                40.64748176197368
              ],
              [
                -73.7535423809316,
                40.64746992807569
              ],
              [
                -73.75350317781748,
                40.64743395713441
              ],
              [
                -73.75345752108981,
                40.647402713017634
              ],
              [
                -73.75340638296309,
                40.64737686011339
              ],
              [
                -73.75335085096773,
                40.64735694895327
              ],
              [
                -73.7532921068744,
                40.64734340286091
              ],
              [
                -73.75342130018777,
                40.64716801923589
              ],
              [
                -73.75352565808248,
                40.64721422007991
              ],
              [
                -73.7535419181792,
                40.64719631215963
              ],
              [
                -73.75323328284715,
                40.64705211484303
              ],
              [
                -73.7532111242384,
                40.647072251933615
              ],
              [
                -73.75340365378038,
                40.64716237480367
              ],
              [
                -73.75327090546294,
                40.647333762759565
              ],
              [
                -73.75308099198507,
                40.64722784317051
              ],
              [
                -73.75303393403244,
                40.64721316513301
              ],
              [
                -73.75277838595592,
                40.64704553084228
              ],
              [
                -73.75252557187562,
                40.646935092227764
              ],
              [
                -73.75220824210493,
                40.64675386910737
              ],
              [
                -73.75196590098793,
                40.64659859696408
              ],
              [
                -73.75186438589381,
                40.646580435877084
              ],
              [
                -73.75171156796117,
                40.646501609859
              ],
              [
                -73.75167487578338,
                40.64647125390893
              ],
              [
                -73.75166474521832,
                40.646424134759044
              ],
              [
                -73.751607403107,
                40.64640382522379
              ],
              [
                -73.75154567506182,
                40.64637565823736
              ],
              [
                -73.75147474305422,
                40.6463275245372
              ],
              [
                -73.75145614255564,
                40.64630257591344
              ],
              [
                -73.75141643490626,
                40.646291275725986
              ],
              [
                -73.75139443879556,
                40.64626767984412
              ],
              [
                -73.75135766788735,
                40.64625862875684
              ],
              [
                -73.75133567996826,
                40.64623279044436
              ],
              [
                -73.75125051822864,
                40.64617205100801
              ],
              [
                -73.75114929577599,
                40.64607539489828
              ],
              [
                -73.75107287523264,
                40.64603934501582
              ],
              [
                -73.75067194915144,
                40.6457749541129
              ],
              [
                -73.75062636605905,
                40.64576027709227
              ],
              [
                -73.7505382608302,
                40.64569953203489
              ],
              [
                -73.75052512012377,
                40.645670347910375
              ],
              [
                -73.75048097018974,
                40.64566576731067
              ],
              [
                -73.75039425820727,
                40.64562633104435
              ],
              [
                -73.75023266906744,
                40.64553178494396
              ],
              [
                -73.75011226494563,
                40.64544742003596
              ],
              [
                -73.75006516810481,
                40.645443953622895
              ],
              [
                -73.74991395709944,
                40.64533036651036
              ],
              [
                -73.74979905407045,
                40.64521030217188
              ],
              [
                -73.74979113708127,
                40.64519861292796
              ],
              [
                -73.74978096511316,
                40.645187983864666
              ],
              [
                -73.74976877794585,
                40.64517866514493
              ],
              [
                -73.74975879009766,
                40.645172827850715
              ],
              [
                -73.74974800895761,
                40.645167876230175
              ],
              [
                -73.74973294810907,
                40.64516284297651
              ],
              [
                -73.7497170850548,
                40.64515953522575
              ],
              [
                -73.74970075823686,
                40.645158024806804
              ],
              [
                -73.74965005599087,
                40.64514066700189
              ],
              [
                -73.74960378617715,
                40.64511720012024
              ],
              [
                -73.74956320702307,
                40.645088262037596
              ],
              [
                -73.74952942093164,
                40.64505463897659
              ],
              [
                -73.74950334599635,
                40.645017244820245
              ],
              [
                -73.74948569102449,
                40.64497709519505
              ],
              [
                -73.74948381716177,
                40.64496821706417
              ],
              [
                -73.74948005522563,
                40.64495969382198
              ],
              [
                -73.74947450775917,
                40.644951756119674
              ],
              [
                -73.74946732403991,
                40.64494462015597
              ],
              [
                -73.74945870003683,
                40.6449384797547
              ],
              [
                -73.74944886976486,
                40.64493350023758
              ],
              [
                -73.7494380991099,
                40.64492981816267
              ],
              [
                -73.74942668120917,
                40.64492753299104
              ],
              [
                -73.74941492598496,
                40.644926706231594
              ],
              [
                -73.74938698075869,
                40.64492527687788
              ],
              [
                -73.74935910296436,
                40.64492733619019
              ],
              [
                -73.74933203905464,
                40.644932830114456
              ],
              [
                -73.74930651309568,
                40.64494161115258
              ],
              [
                -73.74928320807959,
                40.644953444829426
              ],
              [
                -73.74921285809943,
                40.64502719075019
              ],
              [
                -73.74916792702578,
                40.64504933986304
              ],
              [
                -73.74912448024499,
                40.645073145226604
              ],
              [
                -73.74911679883317,
                40.64509118825734
              ],
              [
                -73.74910565481635,
                40.645108151145216
              ],
              [
                -73.74909129997339,
                40.64512365093547
              ],
              [
                -73.749074058821,
                40.64513733718795
              ],
              [
                -73.74905431995698,
                40.64514890192631
              ],
              [
                -73.74903252966776,
                40.64515808312488
              ],
              [
                -73.749009178898,
                40.64516467415515
              ],
              [
                -73.74899757763455,
                40.645168368956945
              ],
              [
                -73.74898534781404,
                40.64517059711793
              ],
              [
                -73.74897280699656,
                40.64517130174732
              ],
              [
                -73.74896028077396,
                40.645170463214406
              ],
              [
                -73.74895379812875,
                40.6451694079589
              ],
              [
                -73.74892326194116,
                40.64516602190768
              ],
              [
                -73.74889242315085,
                40.645165182763606
              ],
              [
                -73.74884575010155,
                40.64516880380207
              ],
              [
                -73.74886499005787,
                40.6451419319572
              ],
              [
                -73.74880038307,
                40.64509693646108
              ],
              [
                -73.7487767478731,
                40.645118190982494
              ],
              [
                -73.74874307407524,
                40.64506760191111
              ],
              [
                -73.74871722418025,
                40.645014409097655
              ],
              [
                -73.74869953808962,
                40.6449593121776
              ],
              [
                -73.748690248044,
                40.64490303580215
              ],
              [
                -73.74868947609633,
                40.64484631887667
              ],
              [
                -73.74869723280953,
                40.644789907198756
              ],
              [
                -73.74871341519051,
                40.64473454305685
              ],
              [
                -73.74874242087304,
                40.64466768080756
              ],
              [
                -73.74876839311067,
                40.644600098772194
              ],
              [
                -73.74876528278705,
                40.644558251825416
              ],
              [
                -73.74876828394348,
                40.64451640006256
              ],
              [
                -73.74875121280796,
                40.64447110317513
              ],
              [
                -73.74855450017644,
                40.644322651223206
              ],
              [
                -73.7485294907717,
                40.644318111919986
              ],
              [
                -73.74846633091204,
                40.644279846138794
              ],
              [
                -73.74845082016503,
                40.644252269201175
              ],
              [
                -73.74842757009225,
                40.644231981840704
              ],
              [
                -73.74840059785282,
                40.644214571104705
              ],
              [
                -73.74834849194528,
                40.64419277370574
              ],
              [
                -73.74832252972762,
                40.64418406064911
              ],
              [
                -73.74829894571495,
                40.644172048091995
              ],
              [
                -73.7482768618246,
                40.64416280985292
              ],
              [
                -73.74827006681191,
                40.64415124193084
              ],
              [
                -73.74826128919973,
                40.64414047595863
              ],
              [
                -73.74825068985452,
                40.6441307078977
              ],
              [
                -73.74823846022485,
                40.64412211513
              ],
              [
                -73.7482245059317,
                40.644114708946006
              ],
              [
                -73.74820934613322,
                40.64410883515944
              ],
              [
                -73.74818212515288,
                40.64409897824132
              ],
              [
                -73.74815733184687,
                40.64408592412917
              ],
              [
                -73.74813560706815,
                40.64407001075741
              ],
              [
                -73.7481175139531,
                40.64405164906598
              ],
              [
                -73.74810352014437,
                40.64403131516901
              ],
              [
                -73.74809398823074,
                40.644009535205576
              ],
              [
                -73.74808916506113,
                40.643986873028865
              ],
              [
                -73.74808917505155,
                40.643963914911176
              ],
              [
                -73.7480828762219,
                40.643951206788415
              ],
              [
                -73.74807387893388,
                40.64393947877814
              ],
              [
                -73.74806243515813,
                40.64392906022953
              ],
              [
                -73.74804829105341,
                40.64391993210214
              ],
              [
                -73.7480322809754,
                40.64391281315847
              ],
              [
                -73.74801489111466,
                40.64390792003266
              ],
              [
                -73.74799665011672,
                40.643905401051896
              ],
              [
                -73.74796912931046,
                40.643897565087016
              ],
              [
                -73.74794372492357,
                40.643886323260716
              ],
              [
                -73.74792115600381,
                40.64387199439489
              ],
              [
                -73.74790206240706,
                40.64385498506315
              ],
              [
                -73.7478869862286,
                40.643835776334306
              ],
              [
                -73.74787635413617,
                40.643814913439236
              ],
              [
                -73.74787046781157,
                40.643792987554754
              ],
              [
                -73.74786191116262,
                40.64376339748545
              ],
              [
                -73.74784784286973,
                40.64373505696274
              ],
              [
                -73.74782855597535,
                40.64370855719548
              ],
              [
                -73.74781185615768,
                40.64369111489988
              ],
              [
                -73.74779288977031,
                40.64367507318879
              ],
              [
                -73.74778146223453,
                40.64366003175658
              ],
              [
                -73.74777524297676,
                40.64365820395109
              ],
              [
                -73.74776870697815,
                40.64365720497771
              ],
              [
                -73.74776204313743,
                40.64365706376416
              ],
              [
                -73.74771593886167,
                40.64363132585477
              ],
              [
                -73.74767474790812,
                40.64360113788255
              ],
              [
                -73.74763920510864,
                40.6435670380599
              ],
              [
                -73.74760994492256,
                40.64352963498252
              ],
              [
                -73.747587488784,
                40.643489595206034
              ],
              [
                -73.74758883378736,
                40.643484397068534
              ],
              [
                -73.74758906281693,
                40.64347910186851
              ],
              [
                -73.74758816988634,
                40.64347384714799
              ],
              [
                -73.74758617863681,
                40.64346877041464
              ],
              [
                -73.74758314053385,
                40.643464004356176
              ],
              [
                -73.74757913469017,
                40.64345967311333
              ],
              [
                -73.74757426583201,
                40.643455889160656
              ],
              [
                -73.74756866093848,
                40.643452751332916
              ],
              [
                -73.74756246658518,
                40.64345034153919
              ],
              [
                -73.747555843039,
                40.64344872269588
              ],
              [
                -73.7475489641527,
                40.64344793740737
              ],
              [
                -73.74754200911231,
                40.6434480054163
              ],
              [
                -73.74753405503529,
                40.643442013412674
              ],
              [
                -73.74752743197416,
                40.64343514618109
              ],
              [
                -73.74752230376089,
                40.64342757307285
              ],
              [
                -73.74751879675959,
                40.643419481158865
              ],
              [
                -73.74751699780106,
                40.643411070918255
              ],
              [
                -73.74751695111142,
                40.64340254926682
              ],
              [
                -73.74751865876323,
                40.643394127989865
              ],
              [
                -73.74751667803395,
                40.643380081064656
              ],
              [
                -73.74751177693027,
                40.643366455860075
              ],
              [
                -73.74750408090786,
                40.6433536018904
              ],
              [
                -73.74749378782086,
                40.64334184976104
              ],
              [
                -73.74748116188306,
                40.643331500926024
              ],
              [
                -73.74746652776997,
                40.64332282181108
              ],
              [
                -73.74745026095411,
                40.643316034898085
              ],
              [
                -73.74743277987598,
                40.64331131486161
              ],
              [
                -73.74741453307503,
                40.64330878282976
              ],
              [
                -73.74741029980449,
                40.64330658195646
              ],
              [
                -73.7474029040544,
                40.643301901860134
              ],
              [
                -73.74739632105728,
                40.64329656386504
              ],
              [
                -73.74739065087017,
                40.6432906490615
              ],
              [
                -73.74733741407343,
                40.6432407038501
              ],
              [
                -73.7472915388935,
                40.643186686711125
              ],
              [
                -73.74725355694628,
                40.643129224001946
              ],
              [
                -73.74722390905603,
                40.643068983064566
              ],
              [
                -73.74720293919907,
                40.6430066628579
              ],
              [
                -73.7471908910085,
                40.642942985903574
              ],
              [
                -73.74718790386437,
                40.64287869159084
              ],
              [
                -73.74702879315804,
                40.642644211838004
              ],
              [
                -73.74702218202302,
                40.642631421098244
              ],
              [
                -73.74700930015858,
                40.64262332784111
              ],
              [
                -73.74699477003442,
                40.64261705996882
              ],
              [
                -73.74698636565677,
                40.642614497118885
              ],
              [
                -73.74697768082068,
                40.64261254999068
              ],
              [
                -73.7469556986995,
                40.64260388485852
              ],
              [
                -73.74693590494275,
                40.642592564890016
              ],
              [
                -73.74691884764626,
                40.64257890305551
              ],
              [
                -73.74690499708854,
                40.64256327719393
              ],
              [
                -73.74689473721212,
                40.64254611979221
              ],
              [
                -73.74688465111727,
                40.64251527593588
              ],
              [
                -73.74688032576586,
                40.642483660957225
              ],
              [
                -73.74688184514358,
                40.642451895899086
              ],
              [
                -73.74688593494254,
                40.64244485988225
              ],
              [
                -73.74688846780323,
                40.6424374108656
              ],
              [
                -73.74688937497864,
                40.642429803031426
              ],
              [
                -73.74688865781499,
                40.64242218381513
              ],
              [
                -73.74688633422684,
                40.642414752216574
              ],
              [
                -73.74688246620484,
                40.64240770476529
              ],
              [
                -73.74687715501426,
                40.64240122496013
              ],
              [
                -73.74687053986388,
                40.64239548384163
              ],
              [
                -73.74686279407507,
                40.64239063175947
              ],
              [
                -73.74685412180307,
                40.642386795895014
              ],
              [
                -73.74682259497264,
                40.642372568034354
              ],
              [
                -73.74679476917676,
                40.64235440513799
              ],
              [
                -73.74677148184261,
                40.64233285382947
              ],
              [
                -73.74675343215667,
                40.64230856119204
              ],
              [
                -73.74674116403365,
                40.642282259021144
              ],
              [
                -73.746720374971,
                40.64221253186799
              ],
              [
                -73.74670489405828,
                40.64214200794834
              ],
              [
                -73.74667588614882,
                40.64206492995332
              ],
              [
                -73.74664070001944,
                40.64198935977372
              ],
              [
                -73.74657654809101,
                40.64182549759709
              ],
              [
                -73.74654320079884,
                40.64169085889285
              ],
              [
                -73.74650806618484,
                40.64163919898094
              ],
              [
                -73.7464317261564,
                40.64158408305853
              ],
              [
                -73.74639834592102,
                40.64155533205001
              ],
              [
                -73.74637170395165,
                40.64152275416237
              ],
              [
                -73.74635254190866,
                40.641487256145524
              ],
              [
                -73.74634139288489,
                40.641449825878105
              ],
              [
                -73.74634883268592,
                40.641436466113134
              ],
              [
                -73.74635320161447,
                40.64142234107921
              ],
              [
                -73.74635437383768,
                40.64140785692506
              ],
              [
                -73.74633897087129,
                40.641404342228675
              ],
              [
                -73.7463263960144,
                40.64139993015429
              ],
              [
                -73.74631467883957,
                40.641394313090785
              ],
              [
                -73.74631546715995,
                40.64137482484783
              ],
              [
                -73.74631998880159,
                40.6413556337711
              ],
              [
                -73.74632814516256,
                40.64133715215073
              ],
              [
                -73.74633002486517,
                40.6413362498496
              ],
              [
                -73.74633169581273,
                40.641335134122336
              ],
              [
                -73.74633311693245,
                40.6413338332032
              ],
              [
                -73.746334253225,
                40.64133237910914
              ],
              [
                -73.74633507719585,
                40.64133080724513
              ],
              [
                -73.7463355682,
                40.641329156963344
              ],
              [
                -73.74633571419191,
                40.641327468086715
              ],
              [
                -73.74633551187432,
                40.64132578290818
              ],
              [
                -73.74633496524163,
                40.641324142179286
              ],
              [
                -73.74633408902065,
                40.641322587098266
              ],
              [
                -73.74633541115192,
                40.6413168318543
              ],
              [
                -73.74633789203453,
                40.64131130288634
              ],
              [
                -73.74634147100916,
                40.641306134911474
              ],
              [
                -73.74634606106507,
                40.64130145375827
              ],
              [
                -73.74635155089871,
                40.64129737283889
              ],
              [
                -73.74635780677009,
                40.64129399176527
              ],
              [
                -73.74636466683809,
                40.64124389223951
              ],
              [
                -73.74636506704749,
                40.64119352277496
              ],
              [
                -73.74635900398914,
                40.64114336394257
              ],
              [
                -73.74634653518861,
                40.64109389680149
              ],
              [
                -73.74632778082714,
                40.641045594101676
              ],
              [
                -73.74630732097097,
                40.641022911095824
              ],
              [
                -73.74629175705896,
                40.640998074780846
              ],
              [
                -73.7462814701426,
                40.640971692242736
              ],
              [
                -73.74627671216511,
                40.64094441098869
              ],
              [
                -73.74627759987665,
                40.640916898037254
              ],
              [
                -73.74628411090862,
                40.64088982724009
              ],
              [
                -73.74629608604022,
                40.640863862032816
              ],
              [
                -73.74632233618877,
                40.64083404412968
              ],
              [
                -73.74634189979751,
                40.640801381135645
              ],
              [
                -73.74635425902625,
                40.640766735886004
              ],
              [
                -73.74635908816686,
                40.640731022895444
              ],
              [
                -73.7463575239505,
                40.64070293086822
              ],
              [
                -73.74633516808112,
                40.64064590919771
              ],
              [
                -73.74630539588146,
                40.640590890195135
              ],
              [
                -73.74626850888167,
                40.64053843194672
              ],
              [
                -73.74622488120059,
                40.64048906604953
              ],
              [
                -73.74617495614544,
                40.640443293912355
              ],
              [
                -73.74616735915122,
                40.640430848147076
              ],
              [
                -73.74616200777528,
                40.64041774225677
              ],
              [
                -73.74633811184864,
                40.63987714617596
              ],
              [
                -73.74633694803521,
                40.6397914128776
              ],
              [
                -73.7463337720348,
                40.63967796479997
              ],
              [
                -73.74633989205708,
                40.63956458622932
              ],
              [
                -73.74633390615892,
                40.63955478390221
              ],
              [
                -73.74632982877726,
                40.639544428197794
              ],
              [
                -73.74632774309308,
                40.63953373423414
              ],
              [
                -73.74632769317725,
                40.63952292313115
              ],
              [
                -73.74632968004435,
                40.63951221822162
              ],
              [
                -73.746339761774,
                40.63947941688862
              ],
              [
                -73.7463528328097,
                40.639447233095254
              ],
              [
                -73.7463543469099,
                40.63944628919412
              ],
              [
                -73.74635563897762,
                40.63944517014253
              ],
              [
                -73.74635667475202,
                40.639443905890126
              ],
              [
                -73.74635742589957,
                40.63944252982915
              ],
              [
                -73.74635787288386,
                40.63944107884278
              ],
              [
                -73.74635800323406,
                40.639439591813925
              ],
              [
                -73.74635781420385,
                40.639438108107896
              ],
              [
                -73.74635731081932,
                40.63943666804103
              ],
              [
                -73.74635650581013,
                40.639435309851685
              ],
              [
                -73.74635542092985,
                40.63943406918583
              ],
              [
                -73.7463540851698,
                40.63943298011283
              ],
              [
                -73.7463525348708,
                40.63943207118219
              ],
              [
                -73.74635081024664,
                40.63943136703504
              ],
              [
                -73.74633716998578,
                40.639413900998285
              ],
              [
                -73.74632736315525,
                40.639395000261615
              ],
              [
                -73.74632164143556,
                40.63937515005291
              ],
              [
                -73.74632015215033,
                40.63935485827473
              ],
              [
                -73.74632293238811,
                40.639334645482435
              ],
              [
                -73.74632991201233,
                40.63931503021965
              ],
              [
                -73.74634091107603,
                40.63929651437757
              ],
              [
                -73.74635198583576,
                40.63928971620863
              ],
              [
                -73.7463614350201,
                40.63928161835958
              ],
              [
                -73.74636899999037,
                40.63927244342558
              ],
              [
                -73.74637447296097,
                40.63926244327974
              ],
              [
                -73.74637770384747,
                40.63925189211935
              ],
              [
                -73.74637860382623,
                40.63924107920139
              ],
              [
                -73.74637129507286,
                40.639222605796995
              ],
              [
                -73.74636789607887,
                40.63920348581024
              ],
              [
                -73.74636849123883,
                40.63918419622716
              ],
              [
                -73.74637306612547,
                40.63916521914372
              ],
              [
                -73.74638150617538,
                40.63914702776413
              ],
              [
                -73.7463936011126,
                40.63913007499626
              ],
              [
                -73.74639701512068,
                40.639118954034174
              ],
              [
                -73.74639811707112,
                40.639107563992184
              ],
              [
                -73.74639688005601,
                40.63909618197723
              ],
              [
                -73.74639333412867,
                40.639085084998094
              ],
              [
                -73.74638756594,
                40.639074543094445
              ],
              [
                -73.7463962818071,
                40.63903857623228
              ],
              [
                -73.74641195210026,
                40.63900400500943
              ],
              [
                -73.7464342260009,
                40.638971604792566
              ],
              [
                -73.74646260310949,
                40.638942102090844
              ],
              [
                -73.74649644705079,
                40.63891615989873
              ],
              [
                -73.74650471498471,
                40.63889816004763
              ],
              [
                -73.7465215919483,
                40.63885253100168
              ],
              [
                -73.74653311778164,
                40.63880594522591
              ],
              [
                -73.74653920203187,
                40.63875876643843
              ],
              [
                -73.7465397979704,
                40.63871136266967
              ],
              [
                -73.74654132522075,
                40.63869678799164
              ],
              [
                -73.74658064617921,
                40.63842101621456
              ],
              [
                -73.74658248411995,
                40.638389689146535
              ],
              [
                -73.74659068995052,
                40.63835896011138
              ],
              [
                -73.74660506399749,
                40.638329575857945
              ],
              [
                -73.74662525716256,
                40.63830224980313
              ],
              [
                -73.74665622285652,
                40.6382936537797
              ],
              [
                -73.7466849856923,
                40.6382823580292
              ],
              [
                -73.74671166797484,
                40.638268411070015
              ],
              [
                -73.74673585173714,
                40.638252031390294
              ],
              [
                -73.74676951657275,
                40.63819882441706
              ],
              [
                -73.74679919514226,
                40.63814425825788
              ],
              [
                -73.74682479488087,
                40.63808850595298
              ],
              [
                -73.74684051287743,
                40.638066103836
              ],
              [
                -73.74685099495117,
                40.6380419900177
              ],
              [
                -73.74685593509076,
                40.638016869000054
              ],
              [
                -73.74685518918301,
                40.637991473990446
              ],
              [
                -73.7468487791179,
                40.63796654619524
              ],
              [
                -73.74683689197154,
                40.63794281399734
              ],
              [
                -73.7468198747719,
                40.637920969832074
              ],
              [
                -73.74679822393382,
                40.63790165107325
              ],
              [
                -73.74677257200611,
                40.63788542222379
              ],
              [
                -73.74676044982169,
                40.63785300708383
              ],
              [
                -73.7467805007807,
                40.63784627104143
              ],
              [
                -73.74678203982828,
                40.637831866230115
              ],
              [
                -73.74681465385632,
                40.637801251226406
              ],
              [
                -73.74683698181362,
                40.63776663181091
              ],
              [
                -73.74686422903173,
                40.63773983099183
              ],
              [
                -73.74686888875607,
                40.63774554680735
              ],
              [
                -73.74688172727954,
                40.63773345181411
              ],
              [
                -73.7468842769982,
                40.63771943376995
              ],
              [
                -73.74689571510714,
                40.637631141760515
              ],
              [
                -73.74689808620667,
                40.637614606938726
              ],
              [
                -73.74690356808544,
                40.63759850680125
              ],
              [
                -73.74691204524537,
                40.63758317822122
              ],
              [
                -73.74692334076764,
                40.63756894397321
              ],
              [
                -73.74693721618011,
                40.637556101860085
              ],
              [
                -73.74694320619099,
                40.637529202088494
              ],
              [
                -73.74699980678282,
                40.63735439195552
              ],
              [
                -73.74699391228548,
                40.63715064484292
              ],
              [
                -73.74699985119133,
                40.6371270278801
              ],
              [
                -73.74702484920296,
                40.63709147605737
              ],
              [
                -73.74704201885555,
                40.63705332507569
              ],
              [
                -73.74705091277869,
                40.6370135677895
              ],
              [
                -73.74705129976839,
                40.63697323795453
              ],
              [
                -73.74705109695317,
                40.63695931005968
              ],
              [
                -73.74704805917632,
                40.636945575016966
              ],
              [
                -73.74704226024103,
                40.63693236524009
              ],
              [
                -73.74703384090704,
                40.63692000177474
              ],
              [
                -73.74702300418711,
                40.6369087829215
              ],
              [
                -73.7470100138752,
                40.63689898110525
              ],
              [
                -73.74699518386495,
                40.63689083408166
              ],
              [
                -73.74697726324622,
                40.63689153366158
              ],
              [
                -73.7469597418971,
                40.6368944830285
              ],
              [
                -73.74694310299202,
                40.63689960090885
              ],
              [
                -73.74692780549884,
                40.63690674613092
              ],
              [
                -73.7469142713852,
                40.636915721600595
              ],
              [
                -73.74690287397662,
                40.636926279738155
              ],
              [
                -73.74689392766133,
                40.63693812930759
              ],
              [
                -73.74683530688736,
                40.63699321074432
              ],
              [
                -73.74678162543171,
                40.637051140671836
              ],
              [
                -73.74673378674966,
                40.63710887499921
              ],
              [
                -73.74667907572703,
                40.637162951453284
              ],
              [
                -73.74665239719059,
                40.63718591822407
              ],
              [
                -73.74660886413301,
                40.63722680220137
              ],
              [
                -73.74657199571627,
                40.637271327120175
              ],
              [
                -73.74654231123998,
                40.637318865854155
              ],
              [
                -73.74650999202005,
                40.637342642155396
              ],
              [
                -73.74648289889547,
                40.63736994602534
              ],
              [
                -73.74646168622706,
                40.637400118019556
              ],
              [
                -73.74645832276612,
                40.63740838099433
              ],
              [
                -73.74645339578426,
                40.63741617550551
              ],
              [
                -73.74644709594338,
                40.63742325126385
              ],
              [
                -73.74643950901621,
                40.63742955157549
              ],
              [
                -73.74640893399729,
                40.637442740326634
              ],
              [
                -73.74638148401334,
                40.63745944882354
              ],
              [
                -73.74635785086164,
                40.63747925597855
              ],
              [
                -73.74635102409644,
                40.63748635705709
              ],
              [
                -73.74628878934672,
                40.63752640089844
              ],
              [
                -73.74622200979226,
                40.63756194301498
              ],
              [
                -73.74621102933737,
                40.63756893578738
              ],
              [
                -73.74619875362757,
                40.63757454096377
              ],
              [
                -73.74618548204819,
                40.63757862184229
              ],
              [
                -73.7461715382726,
                40.637581078896716
              ],
              [
                -73.74615726236783,
                40.637581852203155
              ],
              [
                -73.74614300250167,
                40.63758092290192
              ],
              [
                -73.74602015126122,
                40.63764382151665
              ],
              [
                -73.74571895695183,
                40.63782402657131
              ],
              [
                -73.7456650449037,
                40.6378363254409
              ],
              [
                -73.74551058251747,
                40.63794388103343
              ],
              [
                -73.74567316004243,
                40.63812465742794
              ],
              [
                -73.74537980558401,
                40.638292462003385
              ],
              [
                -73.74532770333599,
                40.63837221196975
              ],
              [
                -73.74529639409818,
                40.63833013954071
              ],
              [
                -73.745285924386,
                40.63832482560704
              ],
              [
                -73.74527661766675,
                40.63831837696979
              ],
              [
                -73.74526868393507,
                40.63831093913482
              ],
              [
                -73.74526230220606,
                40.638302679927996
              ],
              [
                -73.7452576164747,
                40.638293785708285
              ],
              [
                -73.74525473246845,
                40.63828445716308
              ],
              [
                -73.74525371526053,
                40.638274904779685
              ],
              [
                -73.74525353272955,
                40.63827254271655
              ],
              [
                -73.74524732536197,
                40.63823722177521
              ],
              [
                -73.74523479225815,
                40.63820288848959
              ],
              [
                -73.74521617222685,
                40.6381701970288
              ],
              [
                -73.74519182005321,
                40.638139770278514
              ],
              [
                -73.74516219973704,
                40.63811218797305
              ],
              [
                -73.74515886775069,
                40.63807741225209
              ],
              [
                -73.74516813350769,
                40.63804309215179
              ],
              [
                -73.74519377749161,
                40.63800913536165
              ],
              [
                -73.74524489519087,
                40.63798603142309
              ],
              [
                -73.74524280313427,
                40.63797738781068
              ],
              [
                -73.74523790141208,
                40.63796171950571
              ],
              [
                -73.74527129986721,
                40.637938577469136
              ],
              [
                -73.74527138642911,
                40.63791590124532
              ],
              [
                -73.74545481335466,
                40.63779861767815
              ],
              [
                -73.7454922640949,
                40.63779598978516
              ],
              [
                -73.74553630736396,
                40.63776963121705
              ],
              [
                -73.7457196111919,
                40.637652335898274
              ],
              [
                -73.74596746181402,
                40.63752114496121
              ],
              [
                -73.74598653863283,
                40.6375368445497
              ],
              [
                -73.7460362068704,
                40.63752183642386
              ],
              [
                -73.74614917206594,
                40.63744973737821
              ],
              [
                -73.74613888619244,
                40.63743378549239
              ],
              [
                -73.74619472121901,
                40.63739962624329
              ],
              [
                -73.74620675133012,
                40.63740451164502
              ],
              [
                -73.74635020373697,
                40.63732924134758
              ],
              [
                -73.74645394301926,
                40.637259820881205
              ],
              [
                -73.74645973515285,
                40.637227978646926
              ],
              [
                -73.74648181054782,
                40.63720049205395
              ],
              [
                -73.74658986937197,
                40.637113263923226
              ],
              [
                -73.7466521856608,
                40.63703299024159
              ],
              [
                -73.74676973632697,
                40.63689418553681
              ],
              [
                -73.74678417402505,
                40.63687273321897
              ],
              [
                -73.74679368917418,
                40.63684974129233
              ],
              [
                -73.74679801273618,
                40.63682585985995
              ],
              [
                -73.74679702246733,
                40.63680176417458
              ],
              [
                -73.74679074637297,
                40.636778135546706
              ],
              [
                -73.74677936191642,
                40.63675564207972
              ],
              [
                -73.74676319099969,
                40.6367349197796
              ],
              [
                -73.74674269086134,
                40.63671655457172
              ],
              [
                -73.74668073499127,
                40.636647518865125
              ],
              [
                -73.74661229878836,
                40.63658213120218
              ],
              [
                -73.74655613686292,
                40.63654409453335
              ],
              [
                -73.7464954765674,
                40.63651030383522
              ],
              [
                -73.74643086896201,
                40.63648106607109
              ],
              [
                -73.74636290096177,
                40.63645664684356
              ],
              [
                -73.74629219000661,
                40.6364372679824
              ],
              [
                -73.7462638571202,
                40.63644221911156
              ],
              [
                -73.74623652817685,
                40.636462655330966
              ],
              [
                -73.7462117333877,
                40.6364845035449
              ],
              [
                -73.74618833562963,
                40.63650769540583
              ],
              [
                -73.7461655987983,
                40.636531263651285
              ],
              [
                -73.74614353062222,
                40.63655520008996
              ],
              [
                -73.74612214127605,
                40.6365794893318
              ],
              [
                -73.7461014404632,
                40.63660412436257
              ],
              [
                -73.74607393134647,
                40.63662905495397
              ],
              [
                -73.74607359389105,
                40.63662948844448
              ],
              [
                -73.74605477568909,
                40.63665378335163
              ],
              [
                -73.74603225626699,
                40.636678503814004
              ],
              [
                -73.74600637720442,
                40.63670236312224
              ],
              [
                -73.74597825393182,
                40.6367262181368
              ],
              [
                -73.74592203058502,
                40.63676880130041
              ],
              [
                -73.74586695037763,
                40.63680540935348
              ],
              [
                -73.74576131977507,
                40.63686667582133
              ],
              [
                -73.7457354656978,
                40.63688370227759
              ],
              [
                -73.74567138084416,
                40.636929684692674
              ],
              [
                -73.74563429196256,
                40.63695266556124
              ],
              [
                -73.74553205659532,
                40.63700539751762
              ],
              [
                -73.74541408813883,
                40.63706748942081
              ],
              [
                -73.74540060046327,
                40.63707600136937
              ],
              [
                -73.7453854460563,
                40.6370808826185
              ],
              [
                -73.74534457149674,
                40.63710182003373
              ],
              [
                -73.74533233070176,
                40.637106647920525
              ],
              [
                -73.74531990806949,
                40.63711175534442
              ],
              [
                -73.74530950051825,
                40.63711657137386
              ],
              [
                -73.74528780756869,
                40.63712970385059
              ],
              [
                -73.7452257881082,
                40.637163630500375
              ],
              [
                -73.74519496966812,
                40.63719171231205
              ],
              [
                -73.7451725195585,
                40.63719849658055
              ],
              [
                -73.74516353911312,
                40.63720103870245
              ],
              [
                -73.74515344517405,
                40.637201871065756
              ],
              [
                -73.74514667060379,
                40.6372025921744
              ],
              [
                -73.74514559368646,
                40.63720270672301
              ],
              [
                -73.74513662540275,
                40.637201833786996
              ],
              [
                -73.7451276632474,
                40.63720010495726
              ],
              [
                -73.74511757909548,
                40.63719837481242
              ],
              [
                -73.7451007854299,
                40.6371915035677
              ],
              [
                -73.74508334793234,
                40.63718397987455
              ],
              [
                -73.74508287457525,
                40.63718377628237
              ],
              [
                -73.74505601113682,
                40.6371717598314
              ],
              [
                -73.74504257301133,
                40.637167458041695
              ],
              [
                -73.7450302490282,
                40.63716486818898
              ],
              [
                -73.74500110986895,
                40.63716138681514
              ],
              [
                -73.7449685938785,
                40.637161314702475
              ],
              [
                -73.74493270409721,
                40.63716379762361
              ],
              [
                -73.74489231636274,
                40.6371696873126
              ],
              [
                -73.74486538068541,
                40.63717646039699
              ],
              [
                -73.74484067698694,
                40.63718580162469
              ],
              [
                -73.74482045163967,
                40.63719686108063
              ],
              [
                -73.74481145943241,
                40.6372028204096
              ],
              [
                -73.74480358440826,
                40.63720963695133
              ],
              [
                -73.74477993647058,
                40.637236062456545
              ],
              [
                -73.74475964729425,
                40.63726420538296
              ],
              [
                -73.74474271381361,
                40.6372940630451
              ],
              [
                -73.74473447464202,
                40.637313227015916
              ],
              [
                -73.74471275511705,
                40.637307046894215
              ],
              [
                -73.74483896213275,
                40.63708698507472
              ],
              [
                -73.7447955814851,
                40.63707502907608
              ],
              [
                -73.7447138760486,
                40.63722840062137
              ],
              [
                -73.74470895990751,
                40.63722838970687
              ],
              [
                -73.74467432936497,
                40.63728511526768
              ],
              [
                -73.74470379258364,
                40.637293919511244
              ],
              [
                -73.74470130596332,
                40.6373014045561
              ],
              [
                -73.74468657946085,
                40.637295753264695
              ],
              [
                -73.74467585741199,
                40.63731383199286
              ],
              [
                -73.74472799357945,
                40.63733161319794
              ],
              [
                -73.7447279674387,
                40.637338446655924
              ],
              [
                -73.74473236368084,
                40.637361518149994
              ],
              [
                -73.74473345626622,
                40.63736920882553
              ],
              [
                -73.74473230486352,
                40.637376893849456
              ],
              [
                -73.74473117199703,
                40.637380308092446
              ],
              [
                -73.74472891886717,
                40.63738286461203
              ],
              [
                -73.7446939616317,
                40.63743489143132
              ],
              [
                -73.74465002034717,
                40.63749031504758
              ],
              [
                -73.74461624163654,
                40.63752696879767
              ],
              [
                -73.74460273481121,
                40.63754060566321
              ],
              [
                -73.74458923625069,
                40.6375516795163
              ],
              [
                -73.74458136924316,
                40.63755678709512
              ],
              [
                -73.74457350396703,
                40.63756103994329
              ],
              [
                -73.74455328893065,
                40.63756953684862
              ],
              [
                -73.74453083731126,
                40.637576320486055
              ],
              [
                -73.74450503104404,
                40.63758053346876
              ],
              [
                -73.74445896739225,
                40.6376551193112
              ],
              [
                -73.74426333512342,
                40.637954413046685
              ],
              [
                -73.74422124418085,
                40.638019704396356
              ],
              [
                -73.74415773134824,
                40.638180589981324
              ],
              [
                -73.7441562976429,
                40.63818422033204
              ],
              [
                -73.74416787097152,
                40.63819157414298
              ],
              [
                -73.74418327814672,
                40.63819542255729
              ],
              [
                -73.74412476786969,
                40.638347042992116
              ],
              [
                -73.74411229309374,
                40.63837937031152
              ],
              [
                -73.74409080311706,
                40.63843505894466
              ],
              [
                -73.74408094395514,
                40.63843494636935
              ],
              [
                -73.74407869780748,
                40.63843492109936
              ],
              [
                -73.74406202167812,
                40.63843307349592
              ],
              [
                -73.74404519787957,
                40.63843509464785
              ],
              [
                -73.74404183532315,
                40.63843549777715
              ],
              [
                -73.74403572252056,
                40.63844127682693
              ],
              [
                -73.74398391923127,
                40.63855855757751
              ],
              [
                -73.74395663263608,
                40.63862033552935
              ],
              [
                -73.7439593745559,
                40.63862876818487
              ],
              [
                -73.74396627971574,
                40.63863193947468
              ],
              [
                -73.74401611368735,
                40.63864841433777
              ],
              [
                -73.74402564937427,
                40.638651566853
              ],
              [
                -73.74402997541277,
                40.63865299662771
              ],
              [
                -73.74402804795417,
                40.638656173046186
              ],
              [
                -73.74402450885484,
                40.63866200607283
              ],
              [
                -73.74384645847971,
                40.63895539411121
              ],
              [
                -73.74383148928237,
                40.63895978102217
              ],
              [
                -73.74378684907363,
                40.63900571121325
              ],
              [
                -73.74376618359526,
                40.639020322461185
              ],
              [
                -73.7437342150463,
                40.6390221176639
              ],
              [
                -73.74366611241899,
                40.638995999520056
              ],
              [
                -73.74364746537915,
                40.63898884834804
              ],
              [
                -73.74356355009007,
                40.638941682375915
              ],
              [
                -73.74352226238186,
                40.63888948637474
              ],
              [
                -73.74352389261917,
                40.63888663263329
              ],
              [
                -73.74366064685469,
                40.638647155902675
              ],
              [
                -73.7437853783843,
                40.638428730572215
              ],
              [
                -73.74403815450607,
                40.637986076622575
              ],
              [
                -73.74397128496219,
                40.63788001261484
              ],
              [
                -73.74373884101573,
                40.63796918108366
              ],
              [
                -73.74350517659866,
                40.63808397296629
              ],
              [
                -73.74334449521898,
                40.638172447128454
              ],
              [
                -73.74326148890701,
                40.63818080414269
              ],
              [
                -73.74318072177344,
                40.6381900181516
              ],
              [
                -73.74310107004379,
                40.638200944566094
              ],
              [
                -73.74302365615813,
                40.63821273015811
              ],
              [
                -73.74289350196115,
                40.63823550095225
              ],
              [
                -73.74276445497053,
                40.63826169200664
              ],
              [
                -73.7427296630336,
                40.63827015598163
              ],
              [
                -73.74271621824596,
                40.63826756337385
              ],
              [
                -73.74260419719744,
                40.63823997995447
              ],
              [
                -73.74249440927368,
                40.63821496345038
              ],
              [
                -73.74238797185457,
                40.638193369932814
              ],
              [
                -73.74228377060031,
                40.63817349079602
              ],
              [
                -73.74219748524882,
                40.63815963051506
              ],
              [
                -73.74211119816731,
                40.63814662540195
              ],
              [
                -73.74202490121498,
                40.638136182225935
              ],
              [
                -73.74193747964232,
                40.638126589519885
              ],
              [
                -73.74189040305612,
                40.63812221354027
              ],
              [
                -73.74187696472138,
                40.638117911883164
              ],
              [
                -73.74172246881945,
                40.63805606667884
              ],
              [
                -73.74160828610616,
                40.638007976547584
              ],
              [
                -73.74158142517841,
                40.637995102881575
              ],
              [
                -73.74150869561394,
                40.63795564761388
              ],
              [
                -73.74148518992934,
                40.637945345167125
              ],
              [
                -73.74146279147763,
                40.637938462465826
              ],
              [
                -73.74145382711917,
                40.63793673334486
              ],
              [
                -73.74144486341844,
                40.63793500489493
              ],
              [
                -73.74142468384161,
                40.63793410478492
              ],
              [
                -73.74142244203142,
                40.63793409974377
              ],
              [
                -73.74142020000122,
                40.63793409470204
              ],
              [
                -73.74141683155504,
                40.637934941861324
              ],
              [
                -73.74141346178007,
                40.63793664257871
              ],
              [
                -73.74140223045113,
                40.637941741872275
              ],
              [
                -73.7413999840108,
                40.6379425910516
              ],
              [
                -73.74139661688328,
                40.637943438213206
              ],
              [
                -73.74138764391616,
                40.6379442710919
              ],
              [
                -73.7413829865627,
                40.637944260617324
              ],
              [
                -73.74137979362102,
                40.63794425343617
              ],
              [
                -73.74137195611459,
                40.63794167378464
              ],
              [
                -73.74136748331289,
                40.63793824813897
              ],
              [
                -73.74135964846242,
                40.637934813255875
              ],
              [
                -73.74135404212909,
                40.63793180606096
              ],
              [
                -73.74134845884922,
                40.63792880880198
              ],
              [
                -73.74133615185963,
                40.63792194877484
              ],
              [
                -73.74131825515447,
                40.63791080300416
              ],
              [
                -73.7413056658439,
                40.637900414173444
              ],
              [
                -73.74130372491103,
                40.63789881291847
              ],
              [
                -73.74129078685051,
                40.63788603316224
              ],
              [
                -73.74125345959186,
                40.63784915842032
              ],
              [
                -73.74123335092197,
                40.63783032058114
              ],
              [
                -73.74121658796302,
                40.63781576293301
              ],
              [
                -73.74120095386145,
                40.637799498857504
              ],
              [
                -73.74118644354527,
                40.637782382575246
              ],
              [
                -73.74117306495566,
                40.637762706814186
              ],
              [
                -73.74115278454505,
                40.63774285753533
              ],
              [
                -73.74112989506905,
                40.637714333579964
              ],
              [
                -73.74109421928736,
                40.637646355176074
              ],
              [
                -73.74096487686366,
                40.63730477503008
              ],
              [
                -73.74113327509292,
                40.63715533874026
              ],
              [
                -73.74117860782604,
                40.63706419767031
              ],
              [
                -73.7412429544081,
                40.63693385749198
              ],
              [
                -73.7414418260182,
                40.636701590327284
              ],
              [
                -73.741457402659,
                40.6366865443704
              ],
              [
                -73.74149147298868,
                40.636653631785
              ],
              [
                -73.74173263035995,
                40.636428580336805
              ],
              [
                -73.74176405727077,
                40.636438638677255
              ],
              [
                -73.74190682047218,
                40.63629123963369
              ],
              [
                -73.74194304350247,
                40.63626225729084
              ],
              [
                -73.7420809643295,
                40.63609706192348
              ],
              [
                -73.74217084528762,
                40.63596535231388
              ],
              [
                -73.74224399981287,
                40.63582509465421
              ],
              [
                -73.74231340408035,
                40.63568798445788
              ],
              [
                -73.74236603847486,
                40.63569612654984
              ],
              [
                -73.74238343507966,
                40.63564126234088
              ],
              [
                -73.74242234728739,
                40.63565374835393
              ],
              [
                -73.74242717666695,
                40.63565529792807
              ],
              [
                -73.74240752450443,
                40.63568531819995
              ],
              [
                -73.74239929447097,
                40.635697890757854
              ],
              [
                -73.7424012521535,
                40.635698675159794
              ],
              [
                -73.74243638020491,
                40.63571275510745
              ],
              [
                -73.74258539843598,
                40.63545335666109
              ],
              [
                -73.74255329130374,
                40.63544146022973
              ],
              [
                -73.74243665568173,
                40.63564138156584
              ],
              [
                -73.74242902740329,
                40.6356395206516
              ],
              [
                -73.7423912395262,
                40.635630300126756
              ],
              [
                -73.7424158673349,
                40.635569117161985
              ],
              [
                -73.74236547732188,
                40.63555422299665
              ],
              [
                -73.74238800510525,
                40.63549865225259
              ],
              [
                -73.74243972762315,
                40.635371073474545
              ],
              [
                -73.74244751595937,
                40.63536127628998
              ],
              [
                -73.74245732011887,
                40.635352592786546
              ],
              [
                -73.74246887116452,
                40.63534526115992
              ],
              [
                -73.74248185224103,
                40.63533948252295
              ],
              [
                -73.74249590726653,
                40.63533541538815
              ],
              [
                -73.74250797465538,
                40.63533343708692
              ],
              [
                -73.74251778150813,
                40.63533032499192
              ],
              [
                -73.74252676201193,
                40.63532600119686
              ],
              [
                -73.74253466053293,
                40.63532058878079
              ],
              [
                -73.74254125223675,
                40.63531424181039
              ],
              [
                -73.7425463494877,
                40.63530714095494
              ],
              [
                -73.74254980719093,
                40.635299488343584
              ],
              [
                -73.74255152692179,
                40.63529150181095
              ],
              [
                -73.7425514597287,
                40.63528340869712
              ],
              [
                -73.74254960752418,
                40.6352754393758
              ],
              [
                -73.74252931736748,
                40.63522118848194
              ],
              [
                -73.74250148250209,
                40.63516891578415
              ],
              [
                -73.74246642615259,
                40.63511922825211
              ],
              [
                -73.74231218319328,
                40.63504370812134
              ],
              [
                -73.74221252506776,
                40.63501581057906
              ],
              [
                -73.74200012051077,
                40.63498325953498
              ],
              [
                -73.74192006040933,
                40.634999163347956
              ],
              [
                -73.74178960649937,
                40.6350214041624
              ],
              [
                -73.74178775322268,
                40.63502171947901
              ],
              [
                -73.74176899243555,
                40.63498866620964
              ],
              [
                -73.7417193582388,
                40.63499716188116
              ],
              [
                -73.74170635665378,
                40.634955637353514
              ],
              [
                -73.7417731743439,
                40.6349420243985
              ],
              [
                -73.7417693079242,
                40.63492645827807
              ],
              [
                -73.74170957088873,
                40.634937095231436
              ],
              [
                -73.74170882729821,
                40.634926322434794
              ],
              [
                -73.74144240592258,
                40.63496402067877
              ],
              [
                -73.74144863296593,
                40.63497839545674
              ],
              [
                -73.74163098437188,
                40.634947090883486
              ],
              [
                -73.7416332857125,
                40.634961456830304
              ],
              [
                -73.74167886270997,
                40.63495617450638
              ],
              [
                -73.74169596702518,
                40.63500169042439
              ],
              [
                -73.74163556420449,
                40.635013759726604
              ],
              [
                -73.74164684544989,
                40.63504715472515
              ],
              [
                -73.74164858697307,
                40.63505389381684
              ],
              [
                -73.74133506687683,
                40.63511399889662
              ],
              [
                -73.74102145003503,
                40.635162790993924
              ],
              [
                -73.74078781200114,
                40.63516103486981
              ],
              [
                -73.7407742254746,
                40.6351225353337
              ],
              [
                -73.74079465283724,
                40.635118301681715
              ],
              [
                -73.74080494840042,
                40.63514713489752
              ],
              [
                -73.74091019852334,
                40.635125323073204
              ],
              [
                -73.74089879802692,
                40.6350933946194
              ],
              [
                -73.74091479001564,
                40.63509007973384
              ],
              [
                -73.74092361851515,
                40.63511480617143
              ],
              [
                -73.74098927253279,
                40.63510119942609
              ],
              [
                -73.74098314718391,
                40.635084045876724
              ],
              [
                -73.74099288434387,
                40.635081128174676
              ],
              [
                -73.7409838783064,
                40.63505590512063
              ],
              [
                -73.74097754781047,
                40.63505721601665
              ],
              [
                -73.74097436060475,
                40.63504828823865
              ],
              [
                -73.74091396115182,
                40.63506080464041
              ],
              [
                -73.74091563209127,
                40.635065483979695
              ],
              [
                -73.74074057242717,
                40.63509750865651
              ],
              [
                -73.7404395370067,
                40.63515257587345
              ],
              [
                -73.74043676987378,
                40.635141673858776
              ],
              [
                -73.74042047354324,
                40.63514429844044
              ],
              [
                -73.74041969158272,
                40.63517860666841
              ],
              [
                -73.74044226722103,
                40.635182638126686
              ],
              [
                -73.74052856928526,
                40.635170700437456
              ],
              [
                -73.74053168112293,
                40.6352039754206
              ],
              [
                -73.74065635495072,
                40.635182736379726
              ],
              [
                -73.74065547157522,
                40.635168818234085
              ],
              [
                -73.74073204713044,
                40.63515833840738
              ],
              [
                -73.74075056900965,
                40.63515580273157
              ],
              [
                -73.74074429132365,
                40.635129988065906
              ],
              [
                -73.74076026693612,
                40.63512664736523
              ],
              [
                -73.740773490474,
                40.63516320006483
              ],
              [
                -73.74066377917438,
                40.635182529138014
              ],
              [
                -73.7406680700561,
                40.63520396413288
              ],
              [
                -73.74065639913499,
                40.63520548812593
              ],
              [
                -73.74020461124273,
                40.635264471195924
              ],
              [
                -73.7400137673264,
                40.6353455867249
              ],
              [
                -73.7399595753528,
                40.6351438091866
              ],
              [
                -73.73986921396012,
                40.63524791286182
              ],
              [
                -73.73947121613399,
                40.63570643191467
              ],
              [
                -73.73955759615876,
                40.63586253413049
              ],
              [
                -73.74033103314677,
                40.63726030097883
              ],
              [
                -73.74125900083672,
                40.63889749946875
              ],
              [
                -73.74227387212949,
                40.64012338122569
              ],
              [
                -73.74142279307473,
                40.64050339087667
              ],
              [
                -73.74205084926373,
                40.641474204762794
              ],
              [
                -73.74166141308763,
                40.64219806209121
              ],
              [
                -73.74162258204508,
                40.64271602721338
              ],
              [
                -73.74148523636285,
                40.64524783312301
              ],
              [
                -73.74146811233383,
                40.64556352703526
              ],
              [
                -73.74144760054372,
                40.64594171784394
              ],
              [
                -73.74143979613382,
                40.64608557613657
              ],
              [
                -73.74143890933054,
                40.64634484395938
              ],
              [
                -73.74143706021165,
                40.646889178160706
              ],
              [
                -73.7390448211571,
                40.64820118017217
              ],
              [
                -73.73673508618299,
                40.64909981624865
              ],
              [
                -73.73513501486147,
                40.64973204023334
              ],
              [
                -73.73398259890436,
                40.64985220914876
              ],
              [
                -73.73207263098428,
                40.65008747436137
              ],
              [
                -73.73051457300515,
                40.65031031200321
              ],
              [
                -73.73028652986073,
                40.65036852283715
              ],
              [
                -73.7264979013976,
                40.65166351420005
              ],
              [
                -73.72522608103496,
                40.65200100007374
              ],
              [
                -73.72509934591031,
                40.65234574815586
              ],
              [
                -73.72505569109768,
                40.65350789382089
              ],
              [
                -73.72533218511886,
                40.654308694194164
              ],
              [
                -73.72555284202292,
                40.65503995829804
              ],
              [
                -73.72570240303517,
                40.65645873584839
              ],
              [
                -73.72575924916656,
                40.65699795914444
              ],
              [
                -73.72580343602628,
                40.65719125281852
              ],
              [
                -73.72592687720594,
                40.65773131917636
              ],
              [
                -73.72602875988939,
                40.657998998936456
              ],
              [
                -73.7262113990149,
                40.65847881694851
              ],
              [
                -73.72644438687217,
                40.65916161496058
              ],
              [
                -73.72680978675194,
                40.65979485606477
              ],
              [
                -73.72720802015712,
                40.660422320847296
              ],
              [
                -73.7275917590304,
                40.661094488119176
              ],
              [
                -73.7279312440573,
                40.66174883505237
              ],
              [
                -73.72815281678892,
                40.662454142752736
              ],
              [
                -73.72833071887973,
                40.66304394386551
              ],
              [
                -73.72808466920584,
                40.663071188790134
              ],
              [
                -73.72781392396702,
                40.66322396923896
              ],
              [
                -73.72783352392932,
                40.66397643722228
              ],
              [
                -73.72787486779976,
                40.664695132359554
              ],
              [
                -73.72811351398208,
                40.66534531496973
              ],
              [
                -73.72814252019296,
                40.665514374138105
              ],
              [
                -73.72821911979167,
                40.665914584726565
              ],
              [
                -73.7282727227748,
                40.66642959204175
              ],
              [
                -73.72826620792841,
                40.666606286172005
              ],
              [
                -73.72822217020318,
                40.667313639278575
              ],
              [
                -73.72826385201664,
                40.66807287581589
              ],
              [
                -73.7281284031003,
                40.66870817078305
              ],
              [
                -73.72799445690038,
                40.669426693791536
              ],
              [
                -73.72792572875012,
                40.6700971482613
              ],
              [
                -73.72788358613498,
                40.67079643681179
              ],
              [
                -73.72779414890223,
                40.67145467623334
              ],
              [
                -73.72753500301229,
                40.673930284146124
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000504689160432,
        "objectid": 197,
        "shape_leng": 0.108568532229,
        "location_id": 197,
        "zone": "Richmond Hill",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.83696237799982,
                40.704212451999936
              ],
              [
                -73.83856418299983,
                40.70329696899991
              ],
              [
                -73.83947815200001,
                40.70269019399994
              ],
              [
                -73.84156378099983,
                40.70110059799986
              ],
              [
                -73.84215844799995,
                40.700902563999875
              ],
              [
                -73.842365123,
                40.700832543999915
              ],
              [
                -73.84236528999996,
                40.700832489999904
              ],
              [
                -73.84236544299989,
                40.7008324279999
              ],
              [
                -73.84256506999995,
                40.70075073599988
              ],
              [
                -73.84256525999987,
                40.70075065499987
              ],
              [
                -73.8425654379999,
                40.70075056599993
              ],
              [
                -73.8427564879998,
                40.70065776799991
              ],
              [
                -73.84275664199988,
                40.70065769499993
              ],
              [
                -73.84275678399989,
                40.70065761499992
              ],
              [
                -73.84293789599988,
                40.70055442899991
              ],
              [
                -73.84359398099988,
                40.700172351999896
              ],
              [
                -73.844273522,
                40.69978050599992
              ],
              [
                -73.84502472899999,
                40.69935937699991
              ],
              [
                -73.84700405300002,
                40.698825210999914
              ],
              [
                -73.84789669799994,
                40.69860032899992
              ],
              [
                -73.84820912899981,
                40.69851840899985
              ],
              [
                -73.84913837299976,
                40.69828274099998
              ],
              [
                -73.84947763499991,
                40.69819580699987
              ],
              [
                -73.84894128999987,
                40.69755684799992
              ],
              [
                -73.84773325500002,
                40.69477527999989
              ],
              [
                -73.84661793899996,
                40.6924951829999
              ],
              [
                -73.84596733099984,
                40.69116596499991
              ],
              [
                -73.84504194999991,
                40.689318946999904
              ],
              [
                -73.84500533199989,
                40.68924391099989
              ],
              [
                -73.84430714400003,
                40.689441667999894
              ],
              [
                -73.84419150899994,
                40.68947440799992
              ],
              [
                -73.84415010299988,
                40.68939328599993
              ],
              [
                -73.84386364499983,
                40.68883211199997
              ],
              [
                -73.84356447799996,
                40.68821396099985
              ],
              [
                -73.84300297499996,
                40.6870379159999
              ],
              [
                -73.84217464599998,
                40.68535355899992
              ],
              [
                -73.8411960569998,
                40.683351365999904
              ],
              [
                -73.84012856099982,
                40.6811466509999
              ],
              [
                -73.83952877099978,
                40.68126341499991
              ],
              [
                -73.83865065799998,
                40.681438236999895
              ],
              [
                -73.8377973829999,
                40.68167058599989
              ],
              [
                -73.83778880399989,
                40.68167389699992
              ],
              [
                -73.83772037399996,
                40.681700306999915
              ],
              [
                -73.83713815499993,
                40.6819249999999
              ],
              [
                -73.83711511399999,
                40.681933891999954
              ],
              [
                -73.83697486299991,
                40.68198801799991
              ],
              [
                -73.83807769099997,
                40.68423890999989
              ],
              [
                -73.83717651699999,
                40.68449539199987
              ],
              [
                -73.83595082799985,
                40.684843545999904
              ],
              [
                -73.8351108629999,
                40.68508768199992
              ],
              [
                -73.83426545999994,
                40.685327337999894
              ],
              [
                -73.833421907,
                40.68556778199997
              ],
              [
                -73.83257747700002,
                40.68580634599992
              ],
              [
                -73.83172298299988,
                40.6860507049999
              ],
              [
                -73.83088556299994,
                40.686286547999885
              ],
              [
                -73.83003934099995,
                40.68652935899992
              ],
              [
                -73.82918242299996,
                40.68677080599986
              ],
              [
                -73.82835620899998,
                40.687002808999914
              ],
              [
                -73.82753021399995,
                40.687239482999914
              ],
              [
                -73.82851136799992,
                40.689239055999934
              ],
              [
                -73.82768417699987,
                40.68947479999992
              ],
              [
                -73.82685467499992,
                40.68970953599996
              ],
              [
                -73.82599347399987,
                40.689953661999894
              ],
              [
                -73.82501128899997,
                40.68795231799991
              ],
              [
                -73.82398034299996,
                40.688245267999946
              ],
              [
                -73.82293216299993,
                40.688543000999864
              ],
              [
                -73.82189346699994,
                40.688838225999866
              ],
              [
                -73.82085264199988,
                40.68913438299991
              ],
              [
                -73.81993922399998,
                40.68939369199994
              ],
              [
                -73.81906075299987,
                40.689642441999894
              ],
              [
                -73.81818710999987,
                40.68989207499987
              ],
              [
                -73.8169264139999,
                40.69025014699988
              ],
              [
                -73.81607487599989,
                40.690492269999886
              ],
              [
                -73.81522826699991,
                40.69073347699986
              ],
              [
                -73.81438438,
                40.69097195299985
              ],
              [
                -73.81354014199995,
                40.691211367999905
              ],
              [
                -73.81309863099992,
                40.6913181019999
              ],
              [
                -73.81271762199997,
                40.69140780299994
              ],
              [
                -73.81186315099997,
                40.69163007999991
              ],
              [
                -73.8109881059999,
                40.691855044999926
              ],
              [
                -73.81072587499985,
                40.69192242199996
              ],
              [
                -73.81063298299996,
                40.691947382999885
              ],
              [
                -73.81075433199986,
                40.69215870099987
              ],
              [
                -73.81102158699986,
                40.69262288699987
              ],
              [
                -73.81139791299987,
                40.69332231399992
              ],
              [
                -73.81172663199997,
                40.6939192989999
              ],
              [
                -73.81176990199997,
                40.693993904999935
              ],
              [
                -73.81202952199988,
                40.69444762999994
              ],
              [
                -73.8120913559999,
                40.694559620999875
              ],
              [
                -73.81224188600005,
                40.69483223599984
              ],
              [
                -73.81245207999991,
                40.695198124999926
              ],
              [
                -73.81299801799996,
                40.69611206299992
              ],
              [
                -73.81315381599988,
                40.69636935899991
              ],
              [
                -73.81338900499996,
                40.6967577539999
              ],
              [
                -73.81361850799995,
                40.69713673899986
              ],
              [
                -73.81365634399988,
                40.69720105099984
              ],
              [
                -73.81369419799995,
                40.697266795999894
              ],
              [
                -73.8140705039998,
                40.69788712999989
              ],
              [
                -73.81415191799988,
                40.69803996999989
              ],
              [
                -73.81430922799986,
                40.698335331999886
              ],
              [
                -73.81441548799988,
                40.69851897299989
              ],
              [
                -73.81466945899993,
                40.69897071199994
              ],
              [
                -73.8149234329999,
                40.69942243999986
              ],
              [
                -73.81498727099982,
                40.699548830999966
              ],
              [
                -73.81513940199982,
                40.69985000899991
              ],
              [
                -73.81537190699999,
                40.700310310999974
              ],
              [
                -73.81540727999986,
                40.700380337999874
              ],
              [
                -73.81546252299998,
                40.70049286399996
              ],
              [
                -73.81551777599985,
                40.70060539199991
              ],
              [
                -73.81593703000003,
                40.70150511699991
              ],
              [
                -73.81613853799999,
                40.70193756999992
              ],
              [
                -73.81637824499992,
                40.702451963999884
              ],
              [
                -73.81647531200002,
                40.70245042599993
              ],
              [
                -73.81686030399995,
                40.70244433099988
              ],
              [
                -73.8170868249999,
                40.70257033099989
              ],
              [
                -73.82053608399994,
                40.70322714699991
              ],
              [
                -73.821478249,
                40.703415603999936
              ],
              [
                -73.82245890399994,
                40.70365553099989
              ],
              [
                -73.822708114,
                40.70362481299992
              ],
              [
                -73.82338938599995,
                40.703443280999885
              ],
              [
                -73.82387070899986,
                40.70332224199993
              ],
              [
                -73.82426993499998,
                40.70321932799988
              ],
              [
                -73.82517586799999,
                40.702991787999984
              ],
              [
                -73.82607186999988,
                40.70277207899992
              ],
              [
                -73.82706578399991,
                40.7025150569999
              ],
              [
                -73.82795308,
                40.70228996199991
              ],
              [
                -73.82884805700002,
                40.702065798999875
              ],
              [
                -73.82946917899983,
                40.70191018399988
              ],
              [
                -73.82973796799996,
                40.70184039599991
              ],
              [
                -73.830629147,
                40.70161654599988
              ],
              [
                -73.83152109599999,
                40.701389628999955
              ],
              [
                -73.83213204199994,
                40.702794080999894
              ],
              [
                -73.83302084599993,
                40.70257032599992
              ],
              [
                -73.83391709699988,
                40.70234736699993
              ],
              [
                -73.83460244999996,
                40.70392746699989
              ],
              [
                -73.83475458000002,
                40.7038869019999
              ],
              [
                -73.83548255899987,
                40.70370111499993
              ],
              [
                -73.83570634099986,
                40.703614678999905
              ],
              [
                -73.83577918999991,
                40.70354987399992
              ],
              [
                -73.83577924899996,
                40.703549820999854
              ],
              [
                -73.83577932099995,
                40.70354977499989
              ],
              [
                -73.8358710909998,
                40.70349394799989
              ],
              [
                -73.83597857799987,
                40.70345153499989
              ],
              [
                -73.8360961639999,
                40.703425946999886
              ],
              [
                -73.83621686499995,
                40.70341872699988
              ],
              [
                -73.83633362899994,
                40.70342921199993
              ],
              [
                -73.83644057000006,
                40.70345494699993
              ],
              [
                -73.83661882199989,
                40.703420017999896
              ],
              [
                -73.83696237799982,
                40.704212451999936
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000499809882564,
        "objectid": 198,
        "shape_leng": 0.134094242763,
        "location_id": 198,
        "zone": "Ridgewood",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.90768387299997,
                40.71737969499987
              ],
              [
                -73.90850501099996,
                40.71746899599985
              ],
              [
                -73.90862256599989,
                40.71748082299995
              ],
              [
                -73.90943398699983,
                40.717581772999885
              ],
              [
                -73.90955515599987,
                40.71769519699992
              ],
              [
                -73.90962006699982,
                40.71775153799996
              ],
              [
                -73.90965980099995,
                40.71779315499991
              ],
              [
                -73.90980535299991,
                40.71771369999993
              ],
              [
                -73.91002374299995,
                40.71758812399992
              ],
              [
                -73.91048364399984,
                40.71732796499988
              ],
              [
                -73.91105946199994,
                40.71684218099989
              ],
              [
                -73.9113427079999,
                40.716597028999935
              ],
              [
                -73.9115182479999,
                40.71641746599994
              ],
              [
                -73.91156249200004,
                40.71636931299988
              ],
              [
                -73.91178934199993,
                40.716122478999914
              ],
              [
                -73.912108516,
                40.715812621999866
              ],
              [
                -73.91221070499992,
                40.7157093569999
              ],
              [
                -73.91232805399994,
                40.71561530699993
              ],
              [
                -73.91245827599991,
                40.71553228999992
              ],
              [
                -73.91283037699992,
                40.715360907999866
              ],
              [
                -73.91279689999989,
                40.71534014299996
              ],
              [
                -73.91274485099986,
                40.71530376799992
              ],
              [
                -73.91173862299988,
                40.714723810999885
              ],
              [
                -73.91159537799996,
                40.714641132999894
              ],
              [
                -73.91015817899998,
                40.71382159999995
              ],
              [
                -73.91069895799997,
                40.71326852099986
              ],
              [
                -73.91077807399988,
                40.71318759999987
              ],
              [
                -73.91303759100005,
                40.71333207799989
              ],
              [
                -73.91466958199993,
                40.71343707399992
              ],
              [
                -73.91521146700003,
                40.71346123899988
              ],
              [
                -73.91569881899994,
                40.71348528899991
              ],
              [
                -73.91633296499982,
                40.71353115599988
              ],
              [
                -73.91947977199997,
                40.71373199799997
              ],
              [
                -73.92083937599983,
                40.713815139999966
              ],
              [
                -73.92404011299993,
                40.71400831299994
              ],
              [
                -73.92313936299986,
                40.713394727999905
              ],
              [
                -73.92223294699991,
                40.712854424999904
              ],
              [
                -73.92168663599989,
                40.71189431699987
              ],
              [
                -73.92154601699998,
                40.71104328399984
              ],
              [
                -73.92122073500002,
                40.71079055899986
              ],
              [
                -73.92074519699985,
                40.71052968599989
              ],
              [
                -73.92189184699987,
                40.70939609599985
              ],
              [
                -73.92143475999987,
                40.709128765999914
              ],
              [
                -73.92070065899999,
                40.708694629999876
              ],
              [
                -73.91995952899983,
                40.708257287999885
              ],
              [
                -73.919564734,
                40.708023977999915
              ],
              [
                -73.91922162699984,
                40.70781513399986
              ],
              [
                -73.91884342599998,
                40.70759799799987
              ],
              [
                -73.91847670899989,
                40.70738156299989
              ],
              [
                -73.918102996,
                40.707159968999896
              ],
              [
                -73.91808167700003,
                40.70714732999993
              ],
              [
                -73.91773662899993,
                40.70694273899988
              ],
              [
                -73.91736520300005,
                40.70672246899992
              ],
              [
                -73.9169950679999,
                40.70650296499988
              ],
              [
                -73.91664948199995,
                40.70629938799995
              ],
              [
                -73.91660892400002,
                40.70627549499992
              ],
              [
                -73.91625251999977,
                40.706065542999916
              ],
              [
                -73.91589738599997,
                40.70585481699986
              ],
              [
                -73.91551250499982,
                40.705626444999965
              ],
              [
                -73.91477063799996,
                40.70518943699992
              ],
              [
                -73.91403097499997,
                40.7047491129999
              ],
              [
                -73.91329162399998,
                40.70431263899994
              ],
              [
                -73.91254913899994,
                40.70387341999993
              ],
              [
                -73.91180710099992,
                40.703434952999864
              ],
              [
                -73.91290404099983,
                40.70236189199989
              ],
              [
                -73.9121616979999,
                40.70192316499989
              ],
              [
                -73.91142242699996,
                40.70148658899994
              ],
              [
                -73.91067882699998,
                40.701045968999914
              ],
              [
                -73.91180820099987,
                40.699938002999865
              ],
              [
                -73.91103489599992,
                40.6995321139999
              ],
              [
                -73.90953273199997,
                40.69867510499995
              ],
              [
                -73.90877366099991,
                40.69825522699988
              ],
              [
                -73.9080224919999,
                40.69782540899994
              ],
              [
                -73.9075698519999,
                40.69757174999995
              ],
              [
                -73.90727452399994,
                40.69739918999991
              ],
              [
                -73.90684583499988,
                40.697163548999875
              ],
              [
                -73.90652350699987,
                40.69697614399997
              ],
              [
                -73.906116261,
                40.6967505569999
              ],
              [
                -73.90576535999999,
                40.69654855599991
              ],
              [
                -73.90540127699988,
                40.69635030599994
              ],
              [
                -73.90500484799985,
                40.69611874699991
              ],
              [
                -73.90466384799993,
                40.69593224699988
              ],
              [
                -73.9042601839999,
                40.6957003709999
              ],
              [
                -73.90480435399992,
                40.695148168999914
              ],
              [
                -73.90491424299982,
                40.69507357099999
              ],
              [
                -73.90579597099993,
                40.69412715499988
              ],
              [
                -73.90504295399994,
                40.6937040059999
              ],
              [
                -73.90428792799995,
                40.69327806799992
              ],
              [
                -73.90386600499991,
                40.693035711999904
              ],
              [
                -73.90354057099982,
                40.692848781999906
              ],
              [
                -73.9027906169999,
                40.69242104699992
              ],
              [
                -73.90207277399993,
                40.69199923099992
              ],
              [
                -73.90170525399989,
                40.69166024399991
              ],
              [
                -73.90148893599996,
                40.6915568839999
              ],
              [
                -73.90123290699994,
                40.69144227899996
              ],
              [
                -73.90103661799981,
                40.691845252999904
              ],
              [
                -73.90038667799995,
                40.69313995999987
              ],
              [
                -73.900030392,
                40.69343745099991
              ],
              [
                -73.89954093099989,
                40.69428573099987
              ],
              [
                -73.89888585799994,
                40.695389474999935
              ],
              [
                -73.89838185199996,
                40.69613599799993
              ],
              [
                -73.89756262999987,
                40.69648070799991
              ],
              [
                -73.89677249999983,
                40.696799116999884
              ],
              [
                -73.89558457599986,
                40.6971276419999
              ],
              [
                -73.89578005099996,
                40.69851096199989
              ],
              [
                -73.89484994999988,
                40.69858962299989
              ],
              [
                -73.89500322699999,
                40.69971237699987
              ],
              [
                -73.89420165299988,
                40.70050730899994
              ],
              [
                -73.89384018399996,
                40.700859725999884
              ],
              [
                -73.89283458099982,
                40.70191027699992
              ],
              [
                -73.89194157299991,
                40.702886227999905
              ],
              [
                -73.8912683769999,
                40.70359657599989
              ],
              [
                -73.89105316999985,
                40.70381641299988
              ],
              [
                -73.88984330199986,
                40.704972401999896
              ],
              [
                -73.88911917799989,
                40.70559887699992
              ],
              [
                -73.88826271699993,
                40.70580991299988
              ],
              [
                -73.8873957249999,
                40.706018129999954
              ],
              [
                -73.88652826799989,
                40.706225205999885
              ],
              [
                -73.88554946899997,
                40.70615461899994
              ],
              [
                -73.88553214199999,
                40.70644568299985
              ],
              [
                -73.88456244600002,
                40.70636218699993
              ],
              [
                -73.88377296899992,
                40.706294201999874
              ],
              [
                -73.88266573799993,
                40.70635551699991
              ],
              [
                -73.8817801769999,
                40.706404547999966
              ],
              [
                -73.88088332699985,
                40.70645397699992
              ],
              [
                -73.88086188499989,
                40.7065847289999
              ],
              [
                -73.87761134199985,
                40.707167704999954
              ],
              [
                -73.87872783199988,
                40.71002753499987
              ],
              [
                -73.87926181200001,
                40.70990534699992
              ],
              [
                -73.87943495499985,
                40.71028441899989
              ],
              [
                -73.87907685299999,
                40.71035938999994
              ],
              [
                -73.88006267699978,
                40.71265257399994
              ],
              [
                -73.88205387399988,
                40.71245414999988
              ],
              [
                -73.88435098699988,
                40.712230172999966
              ],
              [
                -73.88507555699987,
                40.71216105499992
              ],
              [
                -73.88519762999981,
                40.71216627399991
              ],
              [
                -73.88622785599988,
                40.712202380999926
              ],
              [
                -73.88637649699994,
                40.71220771499993
              ],
              [
                -73.88687942999985,
                40.712223184999885
              ],
              [
                -73.88842000699994,
                40.71226995799986
              ],
              [
                -73.88877304399992,
                40.71228017799995
              ],
              [
                -73.89367393299992,
                40.71243002499995
              ],
              [
                -73.89401868699997,
                40.7124405549999
              ],
              [
                -73.89502461000001,
                40.712478252999894
              ],
              [
                -73.8959038609999,
                40.71250695899986
              ],
              [
                -73.89621914099989,
                40.712522056999944
              ],
              [
                -73.89710338699993,
                40.71255773699992
              ],
              [
                -73.89768322699987,
                40.71258029799992
              ],
              [
                -73.89935504399993,
                40.71264701399986
              ],
              [
                -73.90018380399995,
                40.712680657999954
              ],
              [
                -73.900595879,
                40.71269738499992
              ],
              [
                -73.90224267799981,
                40.712759832999886
              ],
              [
                -73.90293472899992,
                40.7127860729999
              ],
              [
                -73.90376943999979,
                40.7128170319999
              ],
              [
                -73.90477244599997,
                40.71286579999986
              ],
              [
                -73.90489449600001,
                40.71285681499993
              ],
              [
                -73.90537582199983,
                40.71287658099986
              ],
              [
                -73.90556258999989,
                40.713714632999896
              ],
              [
                -73.90571002399982,
                40.7143679749999
              ],
              [
                -73.90574342600004,
                40.714516011999926
              ],
              [
                -73.90586816399987,
                40.7150686929999
              ],
              [
                -73.9060207829998,
                40.715742678999916
              ],
              [
                -73.90617600199982,
                40.71642140399996
              ],
              [
                -73.90639010099999,
                40.71736369199989
              ],
              [
                -73.90688152399996,
                40.71731291499992
              ],
              [
                -73.9069637079999,
                40.717304416999866
              ],
              [
                -73.90768387299997,
                40.71737969499987
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000288747549303,
        "objectid": 199,
        "shape_leng": 0.0778085005169,
        "location_id": 199,
        "zone": "Rikers Island",
        "borough": "Bronx"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.88885148496313,
                40.798706328958765
              ],
              [
                -73.88883691629131,
                40.79874672177403
              ],
              [
                -73.88897708980068,
                40.79875053085948
              ],
              [
                -73.88899654222763,
                40.798680754990436
              ],
              [
                -73.88859543371056,
                40.7986215940571
              ],
              [
                -73.88860998976942,
                40.7985885467283
              ],
              [
                -73.88850364697176,
                40.79858844391537
              ],
              [
                -73.88839282545187,
                40.798375280867816
              ],
              [
                -73.88850873250031,
                40.79843783999455
              ],
              [
                -73.88856204587574,
                40.79835340382447
              ],
              [
                -73.88846064025032,
                40.7982908587836
              ],
              [
                -73.88851389271126,
                40.79824315578625
              ],
              [
                -73.88831097224373,
                40.798184185370175
              ],
              [
                -73.8883524809819,
                40.79813935970092
              ],
              [
                -73.88846004385208,
                40.79808501552154
              ],
              [
                -73.88856224600559,
                40.798022499381545
              ],
              [
                -73.88871105363494,
                40.797938248730176
              ],
              [
                -73.88886162193924,
                40.79787305739847
              ],
              [
                -73.88906339518934,
                40.797833878023496
              ],
              [
                -73.88914925293622,
                40.797810130857705
              ],
              [
                -73.88921283898088,
                40.79780120410412
              ],
              [
                -73.88936510050216,
                40.79780809138587
              ],
              [
                -73.88961192353301,
                40.7978476501687
              ],
              [
                -73.8897656404151,
                40.79786914297931
              ],
              [
                -73.89000362263465,
                40.79789184095756
              ],
              [
                -73.89004647710622,
                40.79790311657497
              ],
              [
                -73.89008344066364,
                40.79790090498262
              ],
              [
                -73.89011894402582,
                40.797887457255776
              ],
              [
                -73.89016916368543,
                40.79796465893521
              ],
              [
                -73.89060785824246,
                40.79776750713778
              ],
              [
                -73.89065939632935,
                40.79784194150464
              ],
              [
                -73.89065123202073,
                40.79784606676986
              ],
              [
                -73.89079505126988,
                40.79802183785564
              ],
              [
                -73.89083042169466,
                40.79800740607707
              ],
              [
                -73.89068932626901,
                40.79782957303974
              ],
              [
                -73.89067572679147,
                40.797831626396025
              ],
              [
                -73.89062148266537,
                40.797748923448545
              ],
              [
                -73.89067322907707,
                40.79769731502422
              ],
              [
                -73.89068952645522,
                40.79770766174903
              ],
              [
                -73.89071401711358,
                40.797695287449045
              ],
              [
                -73.89078725984355,
                40.79779867072494
              ],
              [
                -73.89075732720465,
                40.79781310663913
              ],
              [
                -73.89080346891845,
                40.79786274095018
              ],
              [
                -73.89087421678985,
                40.79782974822639
              ],
              [
                -73.89082535336988,
                40.7977821769492
              ],
              [
                -73.89080903156564,
                40.7977862944989
              ],
              [
                -73.89072763263877,
                40.797682903002475
              ],
              [
                -73.89074667860793,
                40.797674655538785
              ],
              [
                -73.89073139028322,
                40.797660899806495
              ],
              [
                -73.89126861053201,
                40.797297593364334
              ],
              [
                -73.89131158868766,
                40.797232471180436
              ],
              [
                -73.89138566506293,
                40.79713592160786
              ],
              [
                -73.89146412571839,
                40.797069709707095
              ],
              [
                -73.89179289686568,
                40.79677524575867
              ],
              [
                -73.89261805166494,
                40.79384099530879
              ],
              [
                -73.89282283610926,
                40.79281708195147
              ],
              [
                -73.89218214128833,
                40.79039450806073
              ],
              [
                -73.89212803173004,
                40.79031548212441
              ],
              [
                -73.89197448913517,
                40.790236274120744
              ],
              [
                -73.89177016601688,
                40.79022204338593
              ],
              [
                -73.89160587107676,
                40.79016070047654
              ],
              [
                -73.89140452851855,
                40.79019391914968
              ],
              [
                -73.89115649549046,
                40.790173855852174
              ],
              [
                -73.89091952981933,
                40.79022555413888
              ],
              [
                -73.89079999904915,
                40.7902075760992
              ],
              [
                -73.8905697158751,
                40.79010763610642
              ],
              [
                -73.89030135549454,
                40.78987672716732
              ],
              [
                -73.89012684985322,
                40.78963627949646
              ],
              [
                -73.88988482198518,
                40.78900996596757
              ],
              [
                -73.88987049722098,
                40.78889549799246
              ],
              [
                -73.88979724043043,
                40.78865239234611
              ],
              [
                -73.88961097071468,
                40.78819999100402
              ],
              [
                -73.88952254814943,
                40.78805882484886
              ],
              [
                -73.88940460886543,
                40.78779128028888
              ],
              [
                -73.88905212421602,
                40.78737256012899
              ],
              [
                -73.88856541983498,
                40.78716095016911
              ],
              [
                -73.88830725683066,
                40.787006344392786
              ],
              [
                -73.88770126353202,
                40.78687227428626
              ],
              [
                -73.88727783409318,
                40.78680667337566
              ],
              [
                -73.8871531644244,
                40.786826182015
              ],
              [
                -73.88692199760122,
                40.78673311412678
              ],
              [
                -73.88659282785127,
                40.78673817520634
              ],
              [
                -73.88631726186046,
                40.78673287031077
              ],
              [
                -73.88612955519179,
                40.78670799337734
              ],
              [
                -73.88596200190945,
                40.786752519242384
              ],
              [
                -73.885770293862,
                40.78667786706754
              ],
              [
                -73.88537442205505,
                40.78666912689923
              ],
              [
                -73.88522186791745,
                40.78663048709767
              ],
              [
                -73.8783068007881,
                40.78535662075425
              ],
              [
                -73.87812782109263,
                40.785435832963415
              ],
              [
                -73.87794438183145,
                40.785508913798175
              ],
              [
                -73.87775684519504,
                40.785575717871666
              ],
              [
                -73.87756558393352,
                40.78563611301422
              ],
              [
                -73.87737097598894,
                40.78568997990353
              ],
              [
                -73.87717340711899,
                40.78573721118406
              ],
              [
                -73.87697326901583,
                40.785777714072246
              ],
              [
                -73.87677095791717,
                40.78581140794937
              ],
              [
                -73.87653089978205,
                40.7858374969154
              ],
              [
                -73.87628987276307,
                40.78585777993133
              ],
              [
                -73.87604811986586,
                40.785872236272226
              ],
              [
                -73.87580588496253,
                40.785880852150214
              ],
              [
                -73.87556341216793,
                40.78588361822196
              ],
              [
                -73.8753209462172,
                40.78588053209308
              ],
              [
                -73.87507873184192,
                40.785871596319225
              ],
              [
                -73.87483701214678,
                40.78585682040508
              ],
              [
                -73.87459603198725,
                40.785836219299306
              ],
              [
                -73.87435603384517,
                40.78580981338755
              ],
              [
                -73.87421072081537,
                40.78579958883035
              ],
              [
                -73.87403767496974,
                40.78578000485868
              ],
              [
                -73.87386380700414,
                40.78576521513465
              ],
              [
                -73.87368934684048,
                40.785755239933856
              ],
              [
                -73.87351452479308,
                40.78575009134952
              ],
              [
                -73.87342763293343,
                40.78576182947974
              ],
              [
                -73.87335461041052,
                40.78578868916403
              ],
              [
                -73.87328576323272,
                40.785812384081346
              ],
              [
                -73.87320234357092,
                40.78582496944759
              ],
              [
                -73.87308761694455,
                40.78585495239161
              ],
              [
                -73.87298121191189,
                40.78589445223215
              ],
              [
                -73.8728849699182,
                40.785962633107935
              ],
              [
                -73.8728719590193,
                40.78597502790236
              ],
              [
                -73.87278847084744,
                40.786077976176486
              ],
              [
                -73.87268454313948,
                40.78615392699542
              ],
              [
                -73.87247298656574,
                40.78630300714109
              ],
              [
                -73.87226518948736,
                40.786423918388614
              ],
              [
                -73.8722480610516,
                40.786428740218575
              ],
              [
                -73.87210572314568,
                40.78646881706841
              ],
              [
                -73.871875621225,
                40.78662350897697
              ],
              [
                -73.8716306538668,
                40.786797905421466
              ],
              [
                -73.87148752123443,
                40.78705256623683
              ],
              [
                -73.87137398779542,
                40.78727091441369
              ],
              [
                -73.87122538745565,
                40.78744541709071
              ],
              [
                -73.87107316722266,
                40.787574840125096
              ],
              [
                -73.87080888583708,
                40.78789669184151
              ],
              [
                -73.87079504870778,
                40.78792901624749
              ],
              [
                -73.87078728267711,
                40.78796249289199
              ],
              [
                -73.8707857397605,
                40.7879964651227
              ],
              [
                -73.87079045056352,
                40.78803026893092
              ],
              [
                -73.87080132248498,
                40.78806324195944
              ],
              [
                -73.87085254976718,
                40.788206120771726
              ],
              [
                -73.87089201274493,
                40.78833862702215
              ],
              [
                -73.87092419700147,
                40.78847226522805
              ],
              [
                -73.87094904721623,
                40.78860680496223
              ],
              [
                -73.87096652040277,
                40.78874201301874
              ],
              [
                -73.87097658644602,
                40.78887765611326
              ],
              [
                -73.87096821802318,
                40.78891988308356
              ],
              [
                -73.8709644492219,
                40.78896249015654
              ],
              [
                -73.870965306828,
                40.78900518832903
              ],
              [
                -73.870972637136,
                40.789057134951996
              ],
              [
                -73.87098333940936,
                40.78910382881657
              ],
              [
                -73.87098474146693,
                40.78910815287393
              ],
              [
                -73.87100344385925,
                40.789165859806765
              ],
              [
                -73.87101127918723,
                40.789194414888016
              ],
              [
                -73.87101364700264,
                40.789214039106746
              ],
              [
                -73.87101378121669,
                40.789230545525015
              ],
              [
                -73.87101411499657,
                40.789272148964656
              ],
              [
                -73.87101491849324,
                40.78935891423255
              ],
              [
                -73.8710158705443,
                40.789375259066304
              ],
              [
                -73.87101839580241,
                40.78939257797725
              ],
              [
                -73.87102842677966,
                40.78943168893714
              ],
              [
                -73.87105158005147,
                40.78950368287371
              ],
              [
                -73.87107215941816,
                40.78956494041246
              ],
              [
                -73.87110291637639,
                40.789663642521134
              ],
              [
                -73.8711340950591,
                40.789746073192674
              ],
              [
                -73.87114607484399,
                40.78978815788255
              ],
              [
                -73.87115500629291,
                40.789819523743084
              ],
              [
                -73.87118336516131,
                40.78994531968979
              ],
              [
                -73.87119724046612,
                40.78999989667398
              ],
              [
                -73.87120394439336,
                40.790019085475556
              ],
              [
                -73.87121154143482,
                40.79003612173712
              ],
              [
                -73.87123092167869,
                40.79006873225195
              ],
              [
                -73.87126782455506,
                40.79011992145684
              ],
              [
                -73.8713252263043,
                40.790201283563874
              ],
              [
                -73.87134366346872,
                40.790224888522246
              ],
              [
                -73.87136151517977,
                40.790245430797164
              ],
              [
                -73.87143773747367,
                40.79032368749403
              ],
              [
                -73.87153422429404,
                40.79042463390263
              ],
              [
                -73.87160773568894,
                40.790507993800524
              ],
              [
                -73.87168210639884,
                40.79059470477724
              ],
              [
                -73.87171094798327,
                40.79062407591867
              ],
              [
                -73.87175020506083,
                40.79065850952961
              ],
              [
                -73.8718453860334,
                40.790736977019165
              ],
              [
                -73.87197367245845,
                40.79084501775287
              ],
              [
                -73.87208390642414,
                40.79093283057373
              ],
              [
                -73.87216399871963,
                40.7909957211357
              ],
              [
                -73.87221878971064,
                40.791036861766415
              ],
              [
                -73.87222386671776,
                40.79104017317085
              ],
              [
                -73.8722629891168,
                40.79106568272003
              ],
              [
                -73.87235602503631,
                40.791119887235126
              ],
              [
                -73.87246121603856,
                40.79118066172868
              ],
              [
                -73.87256990394003,
                40.791247807291285
              ],
              [
                -73.87264706604644,
                40.79129414076946
              ],
              [
                -73.87273896798422,
                40.79135358558459
              ],
              [
                -73.87284269361069,
                40.79142558682334
              ],
              [
                -73.87288457445655,
                40.79145210820714
              ],
              [
                -73.87292724191762,
                40.791476261133425
              ],
              [
                -73.87297284856061,
                40.791499590043614
              ],
              [
                -73.8730353749119,
                40.7915296270255
              ],
              [
                -73.87309734778617,
                40.791558187298946
              ],
              [
                -73.87314608947096,
                40.791578358725786
              ],
              [
                -73.87320017614613,
                40.791597418954005
              ],
              [
                -73.87327840110588,
                40.79162162895528
              ],
              [
                -73.87329524220436,
                40.79162598804913
              ],
              [
                -73.87334448778849,
                40.79163647882993
              ],
              [
                -73.87335904106946,
                40.791640979639574
              ],
              [
                -73.87337134112268,
                40.791646207041694
              ],
              [
                -73.87343783594675,
                40.79168303863182
              ],
              [
                -73.87351081387887,
                40.79172536073438
              ],
              [
                -73.87361978246375,
                40.79177281871232
              ],
              [
                -73.87386851936817,
                40.79184991406616
              ],
              [
                -73.87388510082928,
                40.791853381220164
              ],
              [
                -73.87392742618482,
                40.79185865900266
              ],
              [
                -73.87401755922818,
                40.79186378314718
              ],
              [
                -73.87407064434674,
                40.79186803733165
              ],
              [
                -73.87411193956802,
                40.79187302627992
              ],
              [
                -73.87413213350334,
                40.7918771181924
              ],
              [
                -73.87415314054883,
                40.79188356220574
              ],
              [
                -73.87417526889703,
                40.791892455652956
              ],
              [
                -73.8742000330937,
                40.791904378316076
              ],
              [
                -73.87429326006679,
                40.79195306184479
              ],
              [
                -73.87440998103716,
                40.7920153600434
              ],
              [
                -73.87449318523954,
                40.79205745058919
              ],
              [
                -73.87454446880673,
                40.7920870781407
              ],
              [
                -73.87463269006614,
                40.79214496832345
              ],
              [
                -73.87466759149237,
                40.79216665405675
              ],
              [
                -73.87471064054327,
                40.79218973574527
              ],
              [
                -73.87481081338947,
                40.792237400478065
              ],
              [
                -73.87485267905681,
                40.7922607239068
              ],
              [
                -73.87491771753012,
                40.79230347842359
              ],
              [
                -73.87498356453385,
                40.79235155521852
              ],
              [
                -73.87507395872458,
                40.79242077547183
              ],
              [
                -73.8751279512564,
                40.792465183933835
              ],
              [
                -73.87522354103142,
                40.792550726651896
              ],
              [
                -73.87530659534343,
                40.792628989825324
              ],
              [
                -73.87538576406375,
                40.792706834279535
              ],
              [
                -73.87543203665531,
                40.79275543862973
              ],
              [
                -73.87544969810897,
                40.79277715038665
              ],
              [
                -73.87547123144924,
                40.79280636776735
              ],
              [
                -73.87551232737925,
                40.7928672589875
              ],
              [
                -73.87553042327113,
                40.79289078231575
              ],
              [
                -73.87555275917276,
                40.79291615567658
              ],
              [
                -73.87557896865843,
                40.79294118191423
              ],
              [
                -73.87561279450044,
                40.79296780115392
              ],
              [
                -73.87569604980123,
                40.793021110207654
              ],
              [
                -73.8757250017987,
                40.79304350168519
              ],
              [
                -73.87574836430458,
                40.79306453503411
              ],
              [
                -73.87575830870135,
                40.79307552250692
              ],
              [
                -73.87578703333045,
                40.793111699371224
              ],
              [
                -73.87580272711051,
                40.793127448573365
              ],
              [
                -73.87581681561576,
                40.793139835991155
              ],
              [
                -73.87583075485645,
                40.793150117287205
              ],
              [
                -73.87584483848269,
                40.79315850721028
              ],
              [
                -73.87586407715737,
                40.793167315968404
              ],
              [
                -73.87591748808131,
                40.79318803151328
              ],
              [
                -73.87596602598026,
                40.79321042474757
              ],
              [
                -73.87599744174818,
                40.793227208556225
              ],
              [
                -73.87601532254274,
                40.79323949180808
              ],
              [
                -73.87604786716726,
                40.79326668649695
              ],
              [
                -73.87606235014438,
                40.79327750811569
              ],
              [
                -73.87609618491513,
                40.79329952547196
              ],
              [
                -73.87612202605544,
                40.793312782209235
              ],
              [
                -73.87614385924937,
                40.79332124240848
              ],
              [
                -73.87620619071437,
                40.79334278693766
              ],
              [
                -73.87624509705624,
                40.79335847975797
              ],
              [
                -73.87627147160717,
                40.7933711870491
              ],
              [
                -73.87627936699432,
                40.793375904489544
              ],
              [
                -73.87628751906915,
                40.79338163204678
              ],
              [
                -73.87631755762334,
                40.79340643656756
              ],
              [
                -73.87633147326574,
                40.79341632158013
              ],
              [
                -73.87636541846646,
                40.79343652853074
              ],
              [
                -73.87640255055362,
                40.79345691060194
              ],
              [
                -73.8764934794008,
                40.79350070098349
              ],
              [
                -73.87653382908424,
                40.79352398548307
              ],
              [
                -73.87657771056746,
                40.79355319140963
              ],
              [
                -73.87667149377093,
                40.79361710079402
              ],
              [
                -73.87676325835338,
                40.793677253703784
              ],
              [
                -73.87678543251936,
                40.793693224801345
              ],
              [
                -73.87683239271796,
                40.793730195529655
              ],
              [
                -73.8768533497296,
                40.79374495929963
              ],
              [
                -73.87690341417037,
                40.793775458467515
              ],
              [
                -73.87695902423698,
                40.79380537015929
              ],
              [
                -73.87705474512775,
                40.79385314443542
              ],
              [
                -73.87715743688905,
                40.79390651051257
              ],
              [
                -73.87726571206598,
                40.7939634917726
              ],
              [
                -73.87733499061781,
                40.7939972977185
              ],
              [
                -73.87744715599825,
                40.79405969551738
              ],
              [
                -73.87753980202646,
                40.79411136643943
              ],
              [
                -73.8776219539544,
                40.79416047624432
              ],
              [
                -73.87778072525025,
                40.79425333319389
              ],
              [
                -73.87789422394007,
                40.794319244803035
              ],
              [
                -73.87793243517783,
                40.794339716979216
              ],
              [
                -73.87797523216784,
                40.794359790094425
              ],
              [
                -73.87801071682776,
                40.79437429898059
              ],
              [
                -73.878041848809,
                40.794384372712784
              ],
              [
                -73.87811831475943,
                40.79440155322258
              ],
              [
                -73.87816302352833,
                40.794413145988514
              ],
              [
                -73.87817745874578,
                40.79441798697567
              ],
              [
                -73.87818989044003,
                40.794423195906724
              ],
              [
                -73.87820787186249,
                40.79443256180638
              ],
              [
                -73.87824960606054,
                40.79445685734028
              ],
              [
                -73.87834370276049,
                40.79450663694727
              ],
              [
                -73.87837437812739,
                40.79452080676732
              ],
              [
                -73.87841281195189,
                40.79453577816251
              ],
              [
                -73.87847233873828,
                40.79455671457334
              ],
              [
                -73.87852685920608,
                40.79457407923452
              ],
              [
                -73.87864663726008,
                40.79460883955226
              ],
              [
                -73.87874662234786,
                40.79463944423724
              ],
              [
                -73.87887252713152,
                40.79467360633401
              ],
              [
                -73.87899352766185,
                40.79470652965673
              ],
              [
                -73.87907659806768,
                40.79472827448676
              ],
              [
                -73.87920446532179,
                40.794758277792184
              ],
              [
                -73.87934893739114,
                40.794792621323815
              ],
              [
                -73.87946001096141,
                40.794818572592426
              ],
              [
                -73.8795627221185,
                40.79484239063016
              ],
              [
                -73.87967478298773,
                40.794866496596136
              ],
              [
                -73.87975745821161,
                40.794883683670676
              ],
              [
                -73.87980493376996,
                40.79489225117035
              ],
              [
                -73.8798539645331,
                40.79489884900334
              ],
              [
                -73.87991449155209,
                40.79490426216129
              ],
              [
                -73.87991508464467,
                40.794904315549616
              ],
              [
                -73.87998700129121,
                40.79490802055457
              ],
              [
                -73.88009881438347,
                40.79491159363487
              ],
              [
                -73.88023542873849,
                40.794914456273816
              ],
              [
                -73.88031188472486,
                40.794917723358644
              ],
              [
                -73.88038031546304,
                40.7949231428035
              ],
              [
                -73.8805007720725,
                40.79493616249445
              ],
              [
                -73.88061282089043,
                40.794947229557025
              ],
              [
                -73.8806622449003,
                40.79495316103831
              ],
              [
                -73.8807225522413,
                40.79496202975529
              ],
              [
                -73.88075892952136,
                40.79496851476577
              ],
              [
                -73.88084019560638,
                40.7949851335379
              ],
              [
                -73.88087685804106,
                40.794991311397396
              ],
              [
                -73.88097705479038,
                40.79500379702309
              ],
              [
                -73.88106414287464,
                40.795011532095685
              ],
              [
                -73.88110369782537,
                40.79501712870741
              ],
              [
                -73.8811967614242,
                40.79503589266158
              ],
              [
                -73.88127276943143,
                40.795051531382505
              ],
              [
                -73.88128967259559,
                40.79505425130767
              ],
              [
                -73.88130870043936,
                40.795056034607555
              ],
              [
                -73.88136502712474,
                40.795057605515076
              ],
              [
                -73.88138137953057,
                40.79505729851871
              ],
              [
                -73.88148620045226,
                40.79505534394159
              ],
              [
                -73.88153903087833,
                40.79505787510881
              ],
              [
                -73.88159381157865,
                40.79506283837468
              ],
              [
                -73.88166054417218,
                40.795070831515986
              ],
              [
                -73.88170835287255,
                40.79507870635921
              ],
              [
                -73.88179719700753,
                40.79509817697693
              ],
              [
                -73.88189640244067,
                40.79512278910244
              ],
              [
                -73.88200405621475,
                40.79514623125208
              ],
              [
                -73.88202447821953,
                40.79514958423101
              ],
              [
                -73.8820730731555,
                40.79515530736489
              ],
              [
                -73.88209633687474,
                40.79515986104309
              ],
              [
                -73.88218546415864,
                40.795187615265064
              ],
              [
                -73.88228246152997,
                40.795213981971614
              ],
              [
                -73.88237750527836,
                40.79523946391297
              ],
              [
                -73.88241189497742,
                40.795250943982204
              ],
              [
                -73.88245647333433,
                40.795270099375806
              ],
              [
                -73.88251548178499,
                40.79529729944238
              ],
              [
                -73.88256589218054,
                40.795321754196166
              ],
              [
                -73.88264736334979,
                40.795363837058446
              ],
              [
                -73.8827338115198,
                40.79541236241356
              ],
              [
                -73.88280269382362,
                40.795451298967265
              ],
              [
                -73.88281685343809,
                40.79545799459989
              ],
              [
                -73.8828337519772,
                40.795463937644186
              ],
              [
                -73.88289156223459,
                40.79547848530561
              ],
              [
                -73.88294565229103,
                40.795492362242605
              ],
              [
                -73.8829831164623,
                40.79550055976043
              ],
              [
                -73.88309890770998,
                40.79552013680325
              ],
              [
                -73.8831435822138,
                40.795525044687366
              ],
              [
                -73.88316079453162,
                40.795527944070635
              ],
              [
                -73.88316406288516,
                40.79552885587222
              ],
              [
                -73.88317473357542,
                40.795531856843624
              ],
              [
                -73.88318903725748,
                40.79553750855992
              ],
              [
                -73.88320394303746,
                40.795544997462315
              ],
              [
                -73.88322052550512,
                40.79555484865767
              ],
              [
                -73.88324624360256,
                40.795571948032055
              ],
              [
                -73.88326600487356,
                40.79558727649096
              ],
              [
                -73.88332622624746,
                40.79563943140553
              ],
              [
                -73.8833475098465,
                40.79565830923785
              ],
              [
                -73.88338637422893,
                40.79569276525555
              ],
              [
                -73.88341030322668,
                40.795716860808724
              ],
              [
                -73.88343778229446,
                40.79574969303035
              ],
              [
                -73.88349059449249,
                40.795824460775926
              ],
              [
                -73.88352132338426,
                40.79586230375447
              ],
              [
                -73.88354870255068,
                40.795892453771195
              ],
              [
                -73.88357745705342,
                40.79592096493203
              ],
              [
                -73.88360044806825,
                40.79593998935041
              ],
              [
                -73.88361818816536,
                40.79595213675057
              ],
              [
                -73.88362568067883,
                40.795956394259576
              ],
              [
                -73.88363294995125,
                40.79595985998667
              ],
              [
                -73.88364828586806,
                40.79596550380876
              ],
              [
                -73.88365516756649,
                40.795967230065116
              ],
              [
                -73.88366175261613,
                40.795968291282186
              ],
              [
                -73.88366478678273,
                40.79596850944679
              ],
              [
                -73.88366792669434,
                40.795968512615815
              ],
              [
                -73.88367448015705,
                40.795967844606196
              ],
              [
                -73.88368124897234,
                40.79596630233258
              ],
              [
                -73.8836879120701,
                40.79596394963296
              ],
              [
                -73.88369799188168,
                40.795959241124685
              ],
              [
                -73.883706840044,
                40.79595406314022
              ],
              [
                -73.88371433895306,
                40.79594847905321
              ],
              [
                -73.88372041619786,
                40.7959425506077
              ],
              [
                -73.88372319549671,
                40.79593887975372
              ],
              [
                -73.88372552582665,
                40.79593466784329
              ],
              [
                -73.88372908649971,
                40.795924225575405
              ],
              [
                -73.88373179288648,
                40.79590815460777
              ],
              [
                -73.8837322847909,
                40.795891180121124
              ],
              [
                -73.88373136425903,
                40.79588233621306
              ],
              [
                -73.88372923494416,
                40.79587318468205
              ],
              [
                -73.88372136446775,
                40.79585411288936
              ],
              [
                -73.88369177813868,
                40.79579940586303
              ],
              [
                -73.88368570715002,
                40.79578851244839
              ],
              [
                -73.88366120810024,
                40.795744542372425
              ],
              [
                -73.88364118305184,
                40.79571067304271
              ],
              [
                -73.88363082548061,
                40.79569131997075
              ],
              [
                -73.88362352038043,
                40.795674067394074
              ],
              [
                -73.8836234384092,
                40.795673797260854
              ],
              [
                -73.88362339060498,
                40.795673527664746
              ],
              [
                -73.88362339107707,
                40.79567325761467
              ],
              [
                -73.8836234160024,
                40.79567299630075
              ],
              [
                -73.88362347511048,
                40.79567272630989
              ],
              [
                -73.88362358249438,
                40.79567245636789
              ],
              [
                -73.88362389181384,
                40.79567192529059
              ],
              [
                -73.88362434350388,
                40.795671412449714
              ],
              [
                -73.88362493671484,
                40.79567089924916
              ],
              [
                -73.88362566059602,
                40.79567041415722
              ],
              [
                -73.88362651450376,
                40.7956699467867
              ],
              [
                -73.883628660453,
                40.795669066263606
              ],
              [
                -73.88363113716696,
                40.79566837537766
              ],
              [
                -73.88363371018107,
                40.79566795430428
              ],
              [
                -73.88363491877148,
                40.79566786539587
              ],
              [
                -73.88363602032433,
                40.795667848415356
              ],
              [
                -73.8836472632472,
                40.79566874245469
              ],
              [
                -73.88365916952804,
                40.79567073562462
              ],
              [
                -73.88368606502722,
                40.79567830910384
              ],
              [
                -73.88371602364624,
                40.795689839259396
              ],
              [
                -73.88375741590156,
                40.79570856541983
              ],
              [
                -73.88384160297444,
                40.795750019440945
              ],
              [
                -73.88388038316793,
                40.795771625529
              ],
              [
                -73.88391718206042,
                40.795795021959435
              ],
              [
                -73.88395093197967,
                40.79581988366578
              ],
              [
                -73.88398001309021,
                40.795845486149574
              ],
              [
                -73.88400985604741,
                40.79587539478737
              ],
              [
                -73.88403220776783,
                40.79590031636477
              ],
              [
                -73.88408036837752,
                40.795958392820786
              ],
              [
                -73.8841566572552,
                40.79605151864878
              ],
              [
                -73.88421619472517,
                40.79612287999498
              ],
              [
                -73.88422937027987,
                40.79613685074921
              ],
              [
                -73.88424498708406,
                40.79615101275439
              ],
              [
                -73.88428576654385,
                40.796181769926925
              ],
              [
                -73.88436938249504,
                40.79623858587312
              ],
              [
                -73.88446288938577,
                40.79630395695949
              ],
              [
                -73.88450450963163,
                40.79633557027956
              ],
              [
                -73.8845759214609,
                40.796392444540665
              ],
              [
                -73.88465812670354,
                40.79645712952542
              ],
              [
                -73.88470366866711,
                40.796495032070275
              ],
              [
                -73.88479288320566,
                40.79657631045865
              ],
              [
                -73.8848710637576,
                40.79664615907997
              ],
              [
                -73.88500486751617,
                40.79677535262023
              ],
              [
                -73.88511038267097,
                40.79687410782221
              ],
              [
                -73.8852237547494,
                40.79697882190968
              ],
              [
                -73.88526897378183,
                40.79701915569958
              ],
              [
                -73.88531358206146,
                40.79705652568051
              ],
              [
                -73.88536002886438,
                40.79709249327738
              ],
              [
                -73.8854196281804,
                40.79713548837187
              ],
              [
                -73.88557218565714,
                40.7972410974448
              ],
              [
                -73.8857087958639,
                40.79733929778954
              ],
              [
                -73.88586675456301,
                40.797453971075726
              ],
              [
                -73.88598921889239,
                40.797543566057
              ],
              [
                -73.88609112726316,
                40.797618569936645
              ],
              [
                -73.88618605645821,
                40.79769315237726
              ],
              [
                -73.88628680056246,
                40.797770028532234
              ],
              [
                -73.88631837125503,
                40.79779607530592
              ],
              [
                -73.88639344440251,
                40.7978612554141
              ],
              [
                -73.8864476279971,
                40.79790611773003
              ],
              [
                -73.88646193517582,
                40.79791690200779
              ],
              [
                -73.88648571061235,
                40.79793482714909
              ],
              [
                -73.88653645218785,
                40.79796863725461
              ],
              [
                -73.88660440944788,
                40.798010334600754
              ],
              [
                -73.88665108524579,
                40.798038196699856
              ],
              [
                -73.88668224426033,
                40.798055399991554
              ],
              [
                -73.88670122347439,
                40.79806432478119
              ],
              [
                -73.88672123646727,
                40.79807189078294
              ],
              [
                -73.88673071139101,
                40.79807487986166
              ],
              [
                -73.88677992738062,
                40.79810681476005
              ],
              [
                -73.8868262842498,
                40.79813420874448
              ],
              [
                -73.88693756858686,
                40.79819382317698
              ],
              [
                -73.88699057468439,
                40.79822080915985
              ],
              [
                -73.8870335290872,
                40.79824148180351
              ],
              [
                -73.88707655961188,
                40.79825992072696
              ],
              [
                -73.88713264426238,
                40.79828110190251
              ],
              [
                -73.88718674168426,
                40.798299650460244
              ],
              [
                -73.88723161846639,
                40.79831226578501
              ],
              [
                -73.88728568013728,
                40.798324358684575
              ],
              [
                -73.88734380764393,
                40.79833532909415
              ],
              [
                -73.88744413439005,
                40.7983519333435
              ],
              [
                -73.88745451160933,
                40.79835365457648
              ],
              [
                -73.88755967580657,
                40.79836873243706
              ],
              [
                -73.88766940705628,
                40.798381050154454
              ],
              [
                -73.88794420603693,
                40.798343336683786
              ],
              [
                -73.88823353313644,
                40.79824288428591
              ],
              [
                -73.88839250519672,
                40.79856629726993
              ],
              [
                -73.88835861919844,
                40.798595651190354
              ],
              [
                -73.8882523074438,
                40.7985771803983
              ],
              [
                -73.88821348851273,
                40.79866530463852
              ],
              [
                -73.88858562507336,
                40.798706072297094
              ],
              [
                -73.88860021869878,
                40.798650985918705
              ],
              [
                -73.88885148496313,
                40.798706328958765
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000161276191858,
        "objectid": 212,
        "shape_leng": 0.0671290653429,
        "location_id": 212,
        "zone": "Soundview/Bruckner",
        "borough": "Bronx"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.88421976599987,
                40.82299645799986
              ],
              [
                -73.88276030399989,
                40.82324854799993
              ],
              [
                -73.88178621799985,
                40.823401699999856
              ],
              [
                -73.879930023,
                40.82364106199992
              ],
              [
                -73.87898855699989,
                40.82371109099993
              ],
              [
                -73.87806435799995,
                40.823834394999906
              ],
              [
                -73.87793513899997,
                40.82385161099991
              ],
              [
                -73.8771361349999,
                40.82395802999987
              ],
              [
                -73.87621305999994,
                40.82408474999996
              ],
              [
                -73.87528548299996,
                40.82420788399993
              ],
              [
                -73.87437004400003,
                40.824330510999886
              ],
              [
                -73.87423302099994,
                40.82434858699995
              ],
              [
                -73.87356808499986,
                40.8244198459999
              ],
              [
                -73.87288306599994,
                40.824515959999886
              ],
              [
                -73.872630712,
                40.824553357999925
              ],
              [
                -73.87258431899996,
                40.824562563999976
              ],
              [
                -73.87253656999984,
                40.82456891299994
              ],
              [
                -73.87216881599993,
                40.82461073999989
              ],
              [
                -73.87145992799994,
                40.82472020899991
              ],
              [
                -73.87051877799986,
                40.824840902999895
              ],
              [
                -73.86984170799977,
                40.82492309399988
              ],
              [
                -73.86922974599999,
                40.825002488999914
              ],
              [
                -73.8691174439999,
                40.82501705799992
              ],
              [
                -73.86849386399992,
                40.825097955999894
              ],
              [
                -73.86840249599986,
                40.82510980799989
              ],
              [
                -73.86787899199994,
                40.82517771999988
              ],
              [
                -73.86779104499986,
                40.82518945099987
              ],
              [
                -73.8677031089998,
                40.82520117399986
              ],
              [
                -73.86761742399982,
                40.825213376999926
              ],
              [
                -73.86753174,
                40.82522556999992
              ],
              [
                -73.86718504299985,
                40.825274542999935
              ],
              [
                -73.86707924599993,
                40.825289487999896
              ],
              [
                -73.86662745199993,
                40.825353304999965
              ],
              [
                -73.86653521299998,
                40.82536633399993
              ],
              [
                -73.86577274899996,
                40.825474029999945
              ],
              [
                -73.86567536799994,
                40.8254877849999
              ],
              [
                -73.86538050299997,
                40.82552943099994
              ],
              [
                -73.86529634299995,
                40.82554131799993
              ],
              [
                -73.86490949499988,
                40.8255959549999
              ],
              [
                -73.86474490899984,
                40.82561811299995
              ],
              [
                -73.86455514499985,
                40.82564365999986
              ],
              [
                -73.86446141999993,
                40.82565627799988
              ],
              [
                -73.86406913799999,
                40.82570908999987
              ],
              [
                -73.86395604499984,
                40.825724314999924
              ],
              [
                -73.86361696799989,
                40.825769961999924
              ],
              [
                -73.86354738899982,
                40.82577932899992
              ],
              [
                -73.86216687599976,
                40.82596516299993
              ],
              [
                -73.86120690899979,
                40.82608779499991
              ],
              [
                -73.85970333299981,
                40.82627734899989
              ],
              [
                -73.85867194099998,
                40.826424076999956
              ],
              [
                -73.85727871200001,
                40.8265996249999
              ],
              [
                -73.85700204899983,
                40.82665967699992
              ],
              [
                -73.85672105899991,
                40.826708251999946
              ],
              [
                -73.856436988,
                40.8267450809999
              ],
              [
                -73.85635677499992,
                40.82675208499994
              ],
              [
                -73.85615112900003,
                40.82677004599987
              ],
              [
                -73.85632851899997,
                40.82750768399989
              ],
              [
                -73.85638435699991,
                40.82775374199989
              ],
              [
                -73.85641767299995,
                40.82790051999994
              ],
              [
                -73.85649133899996,
                40.82822507799992
              ],
              [
                -73.85665560399987,
                40.828944912999894
              ],
              [
                -73.85682187599986,
                40.829664768999876
              ],
              [
                -73.85698995199976,
                40.83038523999988
              ],
              [
                -73.85715675899989,
                40.83110226799995
              ],
              [
                -73.857323208,
                40.83182148399989
              ],
              [
                -73.85737529499985,
                40.83204654599996
              ],
              [
                -73.85738092499992,
                40.832135234999924
              ],
              [
                -73.85738951499987,
                40.83225420099995
              ],
              [
                -73.85739121299999,
                40.83229737199986
              ],
              [
                -73.85766180999998,
                40.83237925499987
              ],
              [
                -73.85847699499992,
                40.832625921999934
              ],
              [
                -73.859119143,
                40.83282022499991
              ],
              [
                -73.85934693599994,
                40.83288902199995
              ],
              [
                -73.86008270300002,
                40.833111456999866
              ],
              [
                -73.86032508999983,
                40.83319863999986
              ],
              [
                -73.86033845899995,
                40.83325316599994
              ],
              [
                -73.86037967100003,
                40.83333610299992
              ],
              [
                -73.8604271769999,
                40.83340383299987
              ],
              [
                -73.86049053499993,
                40.833466403999864
              ],
              [
                -73.8605680069999,
                40.83352073499988
              ],
              [
                -73.86065651499993,
                40.83356427199994
              ],
              [
                -73.86075203199984,
                40.833595374999916
              ],
              [
                -73.86085019699982,
                40.83361353799989
              ],
              [
                -73.86129183699992,
                40.83353642099994
              ],
              [
                -73.86137654799992,
                40.83349072299995
              ],
              [
                -73.86142308999996,
                40.833447410999895
              ],
              [
                -73.86155834899989,
                40.83333022099989
              ],
              [
                -73.86271114399993,
                40.83299150899998
              ],
              [
                -73.86366561299998,
                40.832695324999904
              ],
              [
                -73.86456547299998,
                40.83238923499991
              ],
              [
                -73.86546044899993,
                40.83213164199987
              ],
              [
                -73.86635554699981,
                40.83187448399987
              ],
              [
                -73.86725770399997,
                40.83162391799994
              ],
              [
                -73.86814994399988,
                40.8313603849999
              ],
              [
                -73.86907935400002,
                40.83108592799988
              ],
              [
                -73.87000670000005,
                40.83082034099988
              ],
              [
                -73.87090345699994,
                40.830563653999874
              ],
              [
                -73.87179422399991,
                40.830305438999936
              ],
              [
                -73.87275546499988,
                40.830027881999854
              ],
              [
                -73.87322660099987,
                40.8299061669999
              ],
              [
                -73.8732728849999,
                40.8298937199999
              ],
              [
                -73.87332596899981,
                40.82987665899986
              ],
              [
                -73.87372870299995,
                40.829752885999945
              ],
              [
                -73.87460867899986,
                40.8295029049999
              ],
              [
                -73.87550702499989,
                40.829234134999886
              ],
              [
                -73.876401345,
                40.8289772939999
              ],
              [
                -73.87729930499995,
                40.82874277399991
              ],
              [
                -73.8782350109998,
                40.82866001899995
              ],
              [
                -73.87870283599995,
                40.82862110599992
              ],
              [
                -73.8791706709999,
                40.82858218299989
              ],
              [
                -73.88011211299997,
                40.82851166599995
              ],
              [
                -73.88104510399991,
                40.828431812999966
              ],
              [
                -73.88198096599997,
                40.828355979999856
              ],
              [
                -73.88295104599977,
                40.82830386599987
              ],
              [
                -73.88365759099987,
                40.82827878599989
              ],
              [
                -73.88378414099992,
                40.828274291999946
              ],
              [
                -73.88438705399992,
                40.826817283999965
              ],
              [
                -73.88460938899989,
                40.82530353599988
              ],
              [
                -73.88461335999997,
                40.825276502999905
              ],
              [
                -73.8846216589999,
                40.824956205999904
              ],
              [
                -73.88465102199984,
                40.82382289799994
              ],
              [
                -73.8843985489999,
                40.82296557599989
              ],
              [
                -73.88421976599987,
                40.82299645799986
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000904077906339,
        "objectid": 213,
        "shape_leng": 0.159958783822,
        "location_id": 213,
        "zone": "Soundview/Castle Hill",
        "borough": "Bronx"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.8366785639999,
                40.817588762999954
              ],
              [
                -73.83666068699988,
                40.81797116099992
              ],
              [
                -73.8365014589999,
                40.81871065899989
              ],
              [
                -73.83639638699991,
                40.81889136599989
              ],
              [
                -73.83614239499985,
                40.81919890299987
              ],
              [
                -73.83613216399988,
                40.82033578499994
              ],
              [
                -73.83613095099999,
                40.8209565959999
              ],
              [
                -73.83612954299997,
                40.821677827999984
              ],
              [
                -73.83612811599986,
                40.82240889399994
              ],
              [
                -73.83612598999984,
                40.823497431999925
              ],
              [
                -73.83619033399988,
                40.82420544999992
              ],
              [
                -73.83626003599986,
                40.82497188099989
              ],
              [
                -73.8365046079999,
                40.826403116999906
              ],
              [
                -73.83661819699992,
                40.827114148999925
              ],
              [
                -73.83664057899998,
                40.82721504499989
              ],
              [
                -73.83678907899998,
                40.827884395999895
              ],
              [
                -73.83685097699993,
                40.828163394999876
              ],
              [
                -73.83686087299981,
                40.82819847399989
              ],
              [
                -73.83691608899996,
                40.82845182599989
              ],
              [
                -73.83703204399984,
                40.828809450999906
              ],
              [
                -73.83707424299983,
                40.82892307199996
              ],
              [
                -73.8370906139999,
                40.82896714699996
              ],
              [
                -73.83715124799986,
                40.82895951499991
              ],
              [
                -73.83747911799995,
                40.82892810099991
              ],
              [
                -73.8403229479999,
                40.828823950999926
              ],
              [
                -73.84058337699986,
                40.8288056799999
              ],
              [
                -73.84102340199989,
                40.82877480599996
              ],
              [
                -73.84122680199985,
                40.82876053399988
              ],
              [
                -73.84143734599988,
                40.82874131099985
              ],
              [
                -73.8415995109999,
                40.828726494999906
              ],
              [
                -73.84223265299995,
                40.82865263099997
              ],
              [
                -73.84228428387955,
                40.82864660449133
              ],
              [
                -73.84227572757854,
                40.82860533187138
              ],
              [
                -73.84223389473829,
                40.82840361740345
              ],
              [
                -73.84222728410815,
                40.82837171423911
              ],
              [
                -73.8422204799932,
                40.82834188794376
              ],
              [
                -73.84207575103679,
                40.82812327445313
              ],
              [
                -73.84200819971358,
                40.827978219592914
              ],
              [
                -73.84194160834433,
                40.82788512569254
              ],
              [
                -73.84190270994051,
                40.82781133116808
              ],
              [
                -73.84167732111614,
                40.827375673219834
              ],
              [
                -73.84146448487586,
                40.826964271825005
              ],
              [
                -73.84132088542798,
                40.826764735218475
              ],
              [
                -73.84114422392041,
                40.826349167204796
              ],
              [
                -73.8411078091212,
                40.826153326748745
              ],
              [
                -73.84088074239585,
                40.82581942096713
              ],
              [
                -73.84067027156776,
                40.82547609463137
              ],
              [
                -73.84027247787519,
                40.82476197802365
              ],
              [
                -73.84013888869323,
                40.82440183982608
              ],
              [
                -73.84014119006315,
                40.824373413143014
              ],
              [
                -73.84013859078757,
                40.824345001281685
              ],
              [
                -73.84013113528368,
                40.82431708968003
              ],
              [
                -73.84010835009009,
                40.82428311962924
              ],
              [
                -73.84007814578007,
                40.82425267122908
              ],
              [
                -73.84004144009445,
                40.824226669627215
              ],
              [
                -73.83999934830801,
                40.82420590485803
              ],
              [
                -73.83995782576656,
                40.82419219616364
              ],
              [
                -73.83991398769338,
                40.82418360136331
              ],
              [
                -73.83986891799215,
                40.82418033296486
              ],
              [
                -73.83982204750704,
                40.824161452037096
              ],
              [
                -73.83977886908967,
                40.824138035389005
              ],
              [
                -73.83974013877227,
                40.82411049303672
              ],
              [
                -73.83968199656572,
                40.82396422735439
              ],
              [
                -73.83961186638571,
                40.823863426519054
              ],
              [
                -73.83961874262435,
                40.82380510087934
              ],
              [
                -73.83963278927277,
                40.823685970476205
              ],
              [
                -73.83958012564537,
                40.82342745369349
              ],
              [
                -73.83944934873239,
                40.82326535508344
              ],
              [
                -73.83949864965886,
                40.82321871738544
              ],
              [
                -73.83946189414036,
                40.82316261788687
              ],
              [
                -73.83952358080822,
                40.82307863242265
              ],
              [
                -73.83952777714363,
                40.82303815864931
              ],
              [
                -73.8394991378315,
                40.82301632234595
              ],
              [
                -73.83945427382974,
                40.82292284655805
              ],
              [
                -73.83925243425638,
                40.822883564489686
              ],
              [
                -73.83921255059413,
                40.82287580342701
              ],
              [
                -73.83912263063309,
                40.82276980922682
              ],
              [
                -73.83904904481194,
                40.8226887481458
              ],
              [
                -73.83905338450177,
                40.82258911313997
              ],
              [
                -73.83905358783915,
                40.82250504258935
              ],
              [
                -73.83906086613176,
                40.82240162060206
              ],
              [
                -73.83901290013844,
                40.82238043416579
              ],
              [
                -73.83903519242583,
                40.82233703003282
              ],
              [
                -73.8390622301788,
                40.82232134057082
              ],
              [
                -73.83907059472882,
                40.82225284946008
              ],
              [
                -73.8389946271518,
                40.822234976265854
              ],
              [
                -73.83891082837913,
                40.82221526137368
              ],
              [
                -73.83888231646733,
                40.822140489856025
              ],
              [
                -73.83891701760722,
                40.82203972258253
              ],
              [
                -73.83901168067719,
                40.82195017052962
              ],
              [
                -73.8389604985146,
                40.82152409506769
              ],
              [
                -73.83893197127122,
                40.82132893403106
              ],
              [
                -73.83895314598207,
                40.82113240303728
              ],
              [
                -73.83899977773552,
                40.82069960682301
              ],
              [
                -73.83899820982785,
                40.8204051183965
              ],
              [
                -73.83899692969895,
                40.82016459270339
              ],
              [
                -73.83905466437488,
                40.81998400139827
              ],
              [
                -73.83906398923855,
                40.819802432341646
              ],
              [
                -73.83880789875982,
                40.819741936146926
              ],
              [
                -73.83868347700722,
                40.819591381519366
              ],
              [
                -73.83879133810841,
                40.81952858868995
              ],
              [
                -73.83892733295289,
                40.8190012101071
              ],
              [
                -73.83921022101013,
                40.818888356624264
              ],
              [
                -73.83982211939366,
                40.81817921466233
              ],
              [
                -73.83988175585432,
                40.8181353561267
              ],
              [
                -73.83993520565333,
                40.81808711336742
              ],
              [
                -73.83998191347625,
                40.81803498761287
              ],
              [
                -73.84002139406009,
                40.81797952043319
              ],
              [
                -73.84005323723338,
                40.817921288113666
              ],
              [
                -73.84007711217745,
                40.817860895666705
              ],
              [
                -73.84009277086297,
                40.817798970546214
              ],
              [
                -73.8401000506261,
                40.817736156128184
              ],
              [
                -73.84009887585671,
                40.81767310502613
              ],
              [
                -73.84008925878433,
                40.817610472310655
              ],
              [
                -73.84007129934879,
                40.81754890870419
              ],
              [
                -73.84019020523155,
                40.81718827305633
              ],
              [
                -73.84014513168316,
                40.817064467355145
              ],
              [
                -73.84009272315726,
                40.81694233976237
              ],
              [
                -73.84003308726955,
                40.81682214096213
              ],
              [
                -73.83996634646947,
                40.81670411767843
              ],
              [
                -73.83991546362404,
                40.816680517942245
              ],
              [
                -73.839869589337,
                40.81665159290646
              ],
              [
                -73.83982968160092,
                40.8166179466171
              ],
              [
                -73.83979657380424,
                40.816580281713584
              ],
              [
                -73.83977095732818,
                40.81653938475521
              ],
              [
                -73.83975336710861,
                40.81649610979542
              ],
              [
                -73.83974417046625,
                40.8164513605455
              ],
              [
                -73.83974355921865,
                40.816406071751366
              ],
              [
                -73.83895834000002,
                40.81636899399992
              ],
              [
                -73.83696070299992,
                40.81634054099991
              ],
              [
                -73.8366785639999,
                40.817588762999954
              ]
            ]
          ],
          [
            [
              [
                -73.84231712480313,
                40.827184684680276
              ],
              [
                -73.84246322388854,
                40.82737496051707
              ],
              [
                -73.84248198442683,
                40.82747757169662
              ],
              [
                -73.84253325730938,
                40.82752730414838
              ],
              [
                -73.84253934488183,
                40.82759983777526
              ],
              [
                -73.84256508338187,
                40.8277392607169
              ],
              [
                -73.84260103680262,
                40.82780573035187
              ],
              [
                -73.84265027487082,
                40.8279089858428
              ],
              [
                -73.84266998835984,
                40.82804005329698
              ],
              [
                -73.84272187785812,
                40.82827562091454
              ],
              [
                -73.84276131433994,
                40.828327725005714
              ],
              [
                -73.842874508125,
                40.82852757180864
              ],
              [
                -73.84289527503903,
                40.82856917606086
              ],
              [
                -73.84310437699999,
                40.82853286499993
              ],
              [
                -73.84370821,
                40.82843879899991
              ],
              [
                -73.84390624999999,
                40.82840782899995
              ],
              [
                -73.84389248199977,
                40.828349044999904
              ],
              [
                -73.84385743399987,
                40.82819934399991
              ],
              [
                -73.843851037,
                40.82817200499993
              ],
              [
                -73.84383805299989,
                40.82810246899989
              ],
              [
                -73.84381208799992,
                40.827962324999916
              ],
              [
                -73.84379428399991,
                40.82789034299988
              ],
              [
                -73.84375757299995,
                40.827782782999876
              ],
              [
                -73.84374214799988,
                40.8277331529999
              ],
              [
                -73.843728169,
                40.82766817299994
              ],
              [
                -73.84358503199984,
                40.82700294399992
              ],
              [
                -73.84341053499996,
                40.82628047299993
              ],
              [
                -73.84324615099987,
                40.82555394499989
              ],
              [
                -73.84306938499985,
                40.82481661099991
              ],
              [
                -73.84289858800003,
                40.82408305899986
              ],
              [
                -73.84595550900002,
                40.823670982999886
              ],
              [
                -73.849017675,
                40.823259957999916
              ],
              [
                -73.85207829099994,
                40.822847237999895
              ],
              [
                -73.85513639699997,
                40.82243618899988
              ],
              [
                -73.855312208,
                40.823192430999896
              ],
              [
                -73.85548601399985,
                40.823912145999934
              ],
              [
                -73.85564695999977,
                40.8246328619999
              ],
              [
                -73.85588881599983,
                40.825673717999955
              ],
              [
                -73.855971624,
                40.82606387799991
              ],
              [
                -73.85619656499995,
                40.826040994999865
              ],
              [
                -73.85621834499996,
                40.826160628999894
              ],
              [
                -73.856260876,
                40.826329366999865
              ],
              [
                -73.85626925499986,
                40.826393776999936
              ],
              [
                -73.85629023899993,
                40.82648152999993
              ],
              [
                -73.85632684699985,
                40.82662383499993
              ],
              [
                -73.85635677499992,
                40.82675208499994
              ],
              [
                -73.856436988,
                40.8267450809999
              ],
              [
                -73.85672105899991,
                40.826708251999946
              ],
              [
                -73.85700204899983,
                40.82665967699992
              ],
              [
                -73.857278712,
                40.82659962399987
              ],
              [
                -73.85867193999985,
                40.826424076999906
              ],
              [
                -73.85970333199991,
                40.82627734899994
              ],
              [
                -73.86120690799989,
                40.82608779499991
              ],
              [
                -73.86216687599976,
                40.82596516299993
              ],
              [
                -73.86354738899982,
                40.82577932899992
              ],
              [
                -73.86361696799989,
                40.825769961999924
              ],
              [
                -73.86395604399995,
                40.82572431499988
              ],
              [
                -73.86406913799999,
                40.82570908999987
              ],
              [
                -73.86446141899997,
                40.82565627799988
              ],
              [
                -73.86455514499985,
                40.82564365999986
              ],
              [
                -73.86474490899984,
                40.82561811299995
              ],
              [
                -73.86490949499988,
                40.8255959549999
              ],
              [
                -73.86529634199991,
                40.825541317999864
              ],
              [
                -73.86538050299997,
                40.82552943099994
              ],
              [
                -73.86567536799994,
                40.8254877849999
              ],
              [
                -73.86577274899996,
                40.825474029999945
              ],
              [
                -73.86653521299998,
                40.82536633399993
              ],
              [
                -73.86662745199993,
                40.825353304999965
              ],
              [
                -73.86707924599993,
                40.825289487999896
              ],
              [
                -73.86718504299985,
                40.825274542999935
              ],
              [
                -73.86753174,
                40.82522556999992
              ],
              [
                -73.86761742299993,
                40.82521337699991
              ],
              [
                -73.86770310799987,
                40.82520117399989
              ],
              [
                -73.86779104499986,
                40.82518945099987
              ],
              [
                -73.86787899199994,
                40.82517771999988
              ],
              [
                -73.86840249499991,
                40.82510980799992
              ],
              [
                -73.86849386399992,
                40.825097955999894
              ],
              [
                -73.8691174439999,
                40.82501705799992
              ],
              [
                -73.86922974599999,
                40.825002488999914
              ],
              [
                -73.86984170799977,
                40.82492309399988
              ],
              [
                -73.87051877799986,
                40.824840902999895
              ],
              [
                -73.87145992799994,
                40.82472020899991
              ],
              [
                -73.87216881599993,
                40.82461073999989
              ],
              [
                -73.87253656999984,
                40.82456891299994
              ],
              [
                -73.87258431899996,
                40.824562563999976
              ],
              [
                -73.872630712,
                40.824553357999925
              ],
              [
                -73.87288306599994,
                40.824515959999886
              ],
              [
                -73.87356808499986,
                40.8244198459999
              ],
              [
                -73.87423302099994,
                40.82434858699995
              ],
              [
                -73.87437004400003,
                40.824330510999886
              ],
              [
                -73.87528548299996,
                40.82420788399993
              ],
              [
                -73.87621305999994,
                40.82408474999996
              ],
              [
                -73.8771361349999,
                40.82395802999987
              ],
              [
                -73.87793513899997,
                40.82385161099991
              ],
              [
                -73.87806435799995,
                40.823834394999906
              ],
              [
                -73.87898855699989,
                40.82371109099993
              ],
              [
                -73.879930023,
                40.82364106199992
              ],
              [
                -73.88178621799985,
                40.823401699999856
              ],
              [
                -73.88276030399989,
                40.82324854799993
              ],
              [
                -73.88421976599987,
                40.82299645799986
              ],
              [
                -73.8843985489999,
                40.82296557599989
              ],
              [
                -73.88436128599993,
                40.82287380099997
              ],
              [
                -73.884332124,
                40.82281014199988
              ],
              [
                -73.88429488199988,
                40.822718204999944
              ],
              [
                -73.88423986499997,
                40.822625629999955
              ],
              [
                -73.88378786099997,
                40.821933412999975
              ],
              [
                -73.88244779599998,
                40.81926832299992
              ],
              [
                -73.8809802929999,
                40.81823784499989
              ],
              [
                -73.8780628329999,
                40.81618263199994
              ],
              [
                -73.87663459981847,
                40.81571150804957
              ],
              [
                -73.8765199603362,
                40.81613106342415
              ],
              [
                -73.87171606450099,
                40.81492233549018
              ],
              [
                -73.87129421599971,
                40.81472953603785
              ],
              [
                -73.87077804885931,
                40.81448689548779
              ],
              [
                -73.86976955850041,
                40.813423699757195
              ],
              [
                -73.86868890960871,
                40.81185427245648
              ],
              [
                -73.86765646284456,
                40.81058376193712
              ],
              [
                -73.86440333379416,
                40.81027598694018
              ],
              [
                -73.86264680664812,
                40.810012540015265
              ],
              [
                -73.86228604582757,
                40.80994591122738
              ],
              [
                -73.86089171845343,
                40.80968838050701
              ],
              [
                -73.86077073203082,
                40.80966584661852
              ],
              [
                -73.86065222514291,
                40.80963668169183
              ],
              [
                -73.86053682643018,
                40.809601040438
              ],
              [
                -73.86042514804214,
                40.80955911192294
              ],
              [
                -73.86031778239017,
                40.80951111856422
              ],
              [
                -73.86021529900421,
                40.80945731495083
              ],
              [
                -73.86011824151163,
                40.809397986492776
              ],
              [
                -73.86002712475386,
                40.809333447906674
              ],
              [
                -73.85994243205343,
                40.80926404154604
              ],
              [
                -73.85986461265254,
                40.80919013558493
              ],
              [
                -73.85979407932838,
                40.80911212206512
              ],
              [
                -73.85973120620523,
                40.80903041481562
              ],
              [
                -73.85967632676821,
                40.80894544725784
              ],
              [
                -73.85962973209718,
                40.8088576701059
              ],
              [
                -73.85958742774581,
                40.808788150245576
              ],
              [
                -73.8595538465831,
                40.80871593407298
              ],
              [
                -73.85952928167883,
                40.808641651893566
              ],
              [
                -73.85951394740479,
                40.80856595204331
              ],
              [
                -73.85950797756688,
                40.80848949522924
              ],
              [
                -73.8594573598221,
                40.80822546650248
              ],
              [
                -73.85945637248398,
                40.808215918002425
              ],
              [
                -73.85945338797475,
                40.808206612402444
              ],
              [
                -73.85944848313243,
                40.80819778927944
              ],
              [
                -73.85944178423567,
                40.80818967578855
              ],
              [
                -73.85943346375063,
                40.80818248081511
              ],
              [
                -73.85942373589205,
                40.80817638959654
              ],
              [
                -73.85941285110802,
                40.808171558953795
              ],
              [
                -73.85940108963192,
                40.80816811325362
              ],
              [
                -73.85938875426787,
                40.80816614120708
              ],
              [
                -73.85937646678792,
                40.80816813843904
              ],
              [
                -73.85936475125517,
                40.808171590225726
              ],
              [
                -73.85935390385922,
                40.80817640929949
              ],
              [
                -73.85934419884254,
                40.808182473825724
              ],
              [
                -73.85933588156571,
                40.80818963048179
              ],
              [
                -73.85932916230536,
                40.80819769833464
              ],
              [
                -73.85932421093707,
                40.808206473413854
              ],
              [
                -73.85932115264181,
                40.80821573386948
              ],
              [
                -73.8594494636649,
                40.80886953437551
              ],
              [
                -73.85940334758394,
                40.80890502265235
              ],
              [
                -73.85936390257604,
                40.80894488421529
              ],
              [
                -73.85935890566358,
                40.808951384097334
              ],
              [
                -73.85935531642495,
                40.808958400238666
              ],
              [
                -73.85935322420667,
                40.80896575798885
              ],
              [
                -73.8593526810898,
                40.808973274193605
              ],
              [
                -73.8593537005947,
                40.80898076175472
              ],
              [
                -73.85935625734362,
                40.80898803428646
              ],
              [
                -73.85936028769267,
                40.80899491075598
              ],
              [
                -73.85942148359356,
                40.80899804445721
              ],
              [
                -73.85948183810812,
                40.80900634284724
              ],
              [
                -73.8595405986246,
                40.8090197024464
              ],
              [
                -73.85962537388161,
                40.80914597801559
              ],
              [
                -73.85967606941364,
                40.809172552709995
              ],
              [
                -73.85967072961763,
                40.80919825389722
              ],
              [
                -73.85972355427504,
                40.80921840239761
              ],
              [
                -73.85971928075661,
                40.809239285698126
              ],
              [
                -73.85974669043343,
                40.809277881916294
              ],
              [
                -73.85977839204479,
                40.80928675791445
              ],
              [
                -73.85987123219722,
                40.809384883359144
              ],
              [
                -73.85992341159555,
                40.80940215340927
              ],
              [
                -73.85992715349192,
                40.80944118855354
              ],
              [
                -73.85995984693308,
                40.809481397869405
              ],
              [
                -73.8600088165894,
                40.80948747650811
              ],
              [
                -73.86003172846314,
                40.809490322094156
              ],
              [
                -73.86003529913395,
                40.809513934861144
              ],
              [
                -73.86008049117329,
                40.80955908337169
              ],
              [
                -73.86011826350311,
                40.80956996152849
              ],
              [
                -73.8601269523559,
                40.80960549989126
              ],
              [
                -73.85983152493267,
                40.80973347730822
              ],
              [
                -73.8596661655864,
                40.809806564723154
              ],
              [
                -73.85953938146619,
                40.80989044997531
              ],
              [
                -73.85931976564537,
                40.80994146254331
              ],
              [
                -73.85915209523831,
                40.80995076988108
              ],
              [
                -73.85907662566406,
                40.80997555810217
              ],
              [
                -73.85903336121947,
                40.81004609127586
              ],
              [
                -73.8590049367417,
                40.81010470912204
              ],
              [
                -73.85900066509616,
                40.810197636595774
              ],
              [
                -73.8589514122177,
                40.810271417586556
              ],
              [
                -73.85877452654408,
                40.80923397563811
              ],
              [
                -73.85861930569854,
                40.808846836576414
              ],
              [
                -73.85838996228809,
                40.80825880914881
              ],
              [
                -73.85841832709596,
                40.808112040631535
              ],
              [
                -73.85857242494637,
                40.807314677973
              ],
              [
                -73.85866549456955,
                40.806833088596065
              ],
              [
                -73.85827985213197,
                40.806640377185325
              ],
              [
                -73.85827706725419,
                40.80642348767274
              ],
              [
                -73.85826360022342,
                40.80622496518059
              ],
              [
                -73.85825004218657,
                40.80614727157391
              ],
              [
                -73.85822115694067,
                40.80607157611544
              ],
              [
                -73.8581779208772,
                40.806000420677286
              ],
              [
                -73.85814131055962,
                40.80591837521059
              ],
              [
                -73.8580861892141,
                40.80584179204566
              ],
              [
                -73.858015037578,
                40.805774103446005
              ],
              [
                -73.85780984666786,
                40.80562019904475
              ],
              [
                -73.85775568476672,
                40.805533277693534
              ],
              [
                -73.8576933897171,
                40.80544956123754
              ],
              [
                -73.85762328796818,
                40.8053694883417
              ],
              [
                -73.8575457468712,
                40.80529347857728
              ],
              [
                -73.85746117275458,
                40.805221930224434
              ],
              [
                -73.85737000879435,
                40.80515521818442
              ],
              [
                -73.85727273269194,
                40.805093692016285
              ],
              [
                -73.85706828071217,
                40.80499579164018
              ],
              [
                -73.85686073107355,
                40.804901730348796
              ],
              [
                -73.856614854502,
                40.80479692395034
              ],
              [
                -73.85636513336867,
                40.804697504962434
              ],
              [
                -73.85611177205843,
                40.80460355474823
              ],
              [
                -73.8558549779329,
                40.80451515019537
              ],
              [
                -73.85561003777734,
                40.804470590943886
              ],
              [
                -73.85538916176097,
                40.80446471807749
              ],
              [
                -73.85517631512307,
                40.80449574470607
              ],
              [
                -73.8551608367921,
                40.8044980005837
              ],
              [
                -73.85503561758694,
                40.804520223181086
              ],
              [
                -73.85479994155997,
                40.804547900395356
              ],
              [
                -73.85460114248228,
                40.804547649827434
              ],
              [
                -73.85435087482364,
                40.804513765251485
              ],
              [
                -73.8542184149609,
                40.804480028668046
              ],
              [
                -73.85412473375457,
                40.8044656688655
              ],
              [
                -73.85407120427065,
                40.80445746241942
              ],
              [
                -73.85399349144964,
                40.804461913159045
              ],
              [
                -73.85257375622835,
                40.80455088870428
              ],
              [
                -73.85234565390326,
                40.80458032926472
              ],
              [
                -73.85204922599476,
                40.80459911624105
              ],
              [
                -73.8517780752273,
                40.804593976711885
              ],
              [
                -73.85147537230502,
                40.804598378325544
              ],
              [
                -73.8513241118025,
                40.804559849949
              ],
              [
                -73.85126338998977,
                40.80453037668599
              ],
              [
                -73.85118552925947,
                40.804492586101524
              ],
              [
                -73.8509207057748,
                40.80447786811724
              ],
              [
                -73.8506495461609,
                40.804477517135346
              ],
              [
                -73.85036247088496,
                40.80451256399025
              ],
              [
                -73.85033560101454,
                40.804515843590345
              ],
              [
                -73.85019839036401,
                40.804510024973965
              ],
              [
                -73.85014867564297,
                40.80453421881292
              ],
              [
                -73.85013436531689,
                40.80458810722977
              ],
              [
                -73.85012150751717,
                40.804633328618905
              ],
              [
                -73.85011291202908,
                40.80466355117527
              ],
              [
                -73.85008420001887,
                40.80477933154937
              ],
              [
                -73.85006279732194,
                40.80486563853722
              ],
              [
                -73.84997722709161,
                40.80505959498189
              ],
              [
                -73.84982107183842,
                40.80509443084343
              ],
              [
                -73.84970756101627,
                40.80509428291423
              ],
              [
                -73.84961180552062,
                40.80508607261304
              ],
              [
                -73.84952672182332,
                40.80506439844526
              ],
              [
                -73.84942852727698,
                40.80505982814053
              ],
              [
                -73.84941036337706,
                40.804667976275645
              ],
              [
                -73.8493178894178,
                40.80466146570309
              ],
              [
                -73.84933382307932,
                40.80505121826177
              ],
              [
                -73.84921466578409,
                40.80502086441958
              ],
              [
                -73.84916510390543,
                40.80497767329904
              ],
              [
                -73.84911910597006,
                40.804926401396955
              ],
              [
                -73.84910521575513,
                40.80489775186137
              ],
              [
                -73.84908379915261,
                40.80485357982767
              ],
              [
                -73.8490307194202,
                40.80479690682731
              ],
              [
                -73.84898109667138,
                40.804780670479516
              ],
              [
                -73.84894604005996,
                40.8047621689947
              ],
              [
                -73.84890663580971,
                40.80476709519247
              ],
              [
                -73.84887115183398,
                40.804772439297636
              ],
              [
                -73.84883562500292,
                40.80479665116928
              ],
              [
                -73.84883560644094,
                40.804804824520055
              ],
              [
                -73.84883551481951,
                40.80484516792806
              ],
              [
                -73.84879992124709,
                40.80489902944047
              ],
              [
                -73.8487431721285,
                40.80489625968615
              ],
              [
                -73.84870423878404,
                40.80485847292912
              ],
              [
                -73.84870289538134,
                40.804832908365114
              ],
              [
                -73.8487009796497,
                40.80473178627718
              ],
              [
                -73.8486478460621,
                40.804699371480396
              ],
              [
                -73.84860518188748,
                40.80474244174423
              ],
              [
                -73.84855540464777,
                40.804793588072606
              ],
              [
                -73.8484027288335,
                40.804858076828026
              ],
              [
                -73.84825364831518,
                40.80490100697116
              ],
              [
                -73.84807624599382,
                40.80491964114916
              ],
              [
                -73.8479029865273,
                40.805002179397256
              ],
              [
                -73.84766805165862,
                40.805166449227315
              ],
              [
                -73.84752776906166,
                40.8053296047884
              ],
              [
                -73.847488226419,
                40.805448909149185
              ],
              [
                -73.84751706251517,
                40.80571750200649
              ],
              [
                -73.84753621975607,
                40.805926402795116
              ],
              [
                -73.84763420691263,
                40.80600859041147
              ],
              [
                -73.84785003790937,
                40.80607601426631
              ],
              [
                -73.84798630581214,
                40.806103050623804
              ],
              [
                -73.84811499148918,
                40.806128582675655
              ],
              [
                -73.84828163452254,
                40.806240699710536
              ],
              [
                -73.8483795372141,
                40.80636018633455
              ],
              [
                -73.84839879797016,
                40.80652432778419
              ],
              [
                -73.84834937076192,
                40.806673459624534
              ],
              [
                -73.84828024018286,
                40.80685240504775
              ],
              [
                -73.84832891885054,
                40.80703150497563
              ],
              [
                -73.84836776547775,
                40.807218051494516
              ],
              [
                -73.8484164096164,
                40.80736877579531
              ],
              [
                -73.84852183536408,
                40.8074637299373
              ],
              [
                -73.84864314771036,
                40.80754361587297
              ],
              [
                -73.84876995488845,
                40.807636097377575
              ],
              [
                -73.84894530707565,
                40.807709909055895
              ],
              [
                -73.84908995466621,
                40.80777079426387
              ],
              [
                -73.8492610092135,
                40.807833960161354
              ],
              [
                -73.84937135407831,
                40.80788026251642
              ],
              [
                -73.8494264181715,
                40.80795166988995
              ],
              [
                -73.84943455377403,
                40.80802546098172
              ],
              [
                -73.84952218256736,
                40.80805527772021
              ],
              [
                -73.84913269939788,
                40.8081467587697
              ],
              [
                -73.84912748807834,
                40.80813347459896
              ],
              [
                -73.84870962479349,
                40.80824046991645
              ],
              [
                -73.84876007745984,
                40.80833745779309
              ],
              [
                -73.84917093986465,
                40.80823576434291
              ],
              [
                -73.8491657211891,
                40.80822513593612
              ],
              [
                -73.8496116642038,
                40.80812418505127
              ],
              [
                -73.84964993901428,
                40.80813814087777
              ],
              [
                -73.84968135249555,
                40.80815544176079
              ],
              [
                -73.84970227028309,
                40.808178039490635
              ],
              [
                -73.84973191508689,
                40.80820463166332
              ],
              [
                -73.84974410135966,
                40.80822456273514
              ],
              [
                -73.84975452880082,
                40.80824980372666
              ],
              [
                -73.84975944944871,
                40.80826933666984
              ],
              [
                -73.84978989800263,
                40.8083073408305
              ],
              [
                -73.84986663269066,
                40.80833700976058
              ],
              [
                -73.84990019134227,
                40.80840965538154
              ],
              [
                -73.84988093264293,
                40.80847625242864
              ],
              [
                -73.84990773115473,
                40.8085009360906
              ],
              [
                -73.84994260631682,
                40.808532846727324
              ],
              [
                -73.84996696802818,
                40.80857801884359
              ],
              [
                -73.84997211370978,
                40.80862051218814
              ],
              [
                -73.84997030101277,
                40.808649718624785
              ],
              [
                -73.84995975669729,
                40.80867625909903
              ],
              [
                -73.84995355606374,
                40.808693406076365
              ],
              [
                -73.84955076934936,
                40.808720867510885
              ],
              [
                -73.84955595679385,
                40.80874477203535
              ],
              [
                -73.84995116937989,
                40.80871472017221
              ],
              [
                -73.84995749465193,
                40.80883006842509
              ],
              [
                -73.84972865358634,
                40.80887511163889
              ],
              [
                -73.84974778878306,
                40.80891364015451
              ],
              [
                -73.84996732905364,
                40.808875512454414
              ],
              [
                -73.84996962763664,
                40.808948448392
              ],
              [
                -73.84997477933597,
                40.80898828546775
              ],
              [
                -73.84997993834935,
                40.80902546728387
              ],
              [
                -73.84999554853006,
                40.80907726851891
              ],
              [
                -73.85000535107207,
                40.80909363437767
              ],
              [
                -73.85002340188676,
                40.809123772963886
              ],
              [
                -73.85004255373175,
                40.80915433463935
              ],
              [
                -73.85004422603993,
                40.809187529348215
              ],
              [
                -73.85004241612901,
                40.80921540848763
              ],
              [
                -73.85003001109642,
                40.809235103323665
              ],
              [
                -73.84984662518073,
                40.80925232875297
              ],
              [
                -73.84984833932599,
                40.80926693630329
              ],
              [
                -73.85002012324549,
                40.80925403953007
              ],
              [
                -73.8500171018922,
                40.809330656286136
              ],
              [
                -73.85000539859047,
                40.809406789881606
              ],
              [
                -73.84998510045496,
                40.80948187341392
              ],
              [
                -73.84995635860089,
                40.809555347797485
              ],
              [
                -73.84991938701842,
                40.8096266659269
              ],
              [
                -73.84988421698621,
                40.809629442261034
              ],
              [
                -73.84988767869739,
                40.80964405091255
              ],
              [
                -73.84979155453436,
                40.80965189202137
              ],
              [
                -73.84978633908524,
                40.80963993635043
              ],
              [
                -73.84976011634066,
                40.80964521322045
              ],
              [
                -73.84976705103755,
                40.8096691200086
              ],
              [
                -73.84990892306314,
                40.809660122089426
              ],
              [
                -73.84987171574225,
                40.80974891825657
              ],
              [
                -73.84983485578132,
                40.809821893651566
              ],
              [
                -73.84981189671988,
                40.80992940596733
              ],
              [
                -73.8498099933728,
                40.809998443815324
              ],
              [
                -73.84979804522004,
                40.81003287788504
              ],
              [
                -73.84976960468839,
                40.810086018230216
              ],
              [
                -73.84971355923824,
                40.810143036636525
              ],
              [
                -73.84964356064835,
                40.810188086771475
              ],
              [
                -73.84957706330714,
                40.810230486163675
              ],
              [
                -73.84952977419123,
                40.810278221124975
              ],
              [
                -73.84949990037568,
                40.810352532991686
              ],
              [
                -73.84947877479328,
                40.81042154456285
              ],
              [
                -73.84945411997754,
                40.81050648413491
              ],
              [
                -73.84941898714325,
                40.810587427448674
              ],
              [
                -73.84935768132704,
                40.810652405114325
              ],
              [
                -73.84930338063882,
                40.81070942442401
              ],
              [
                -73.8492490511082,
                40.81077972051286
              ],
              [
                -73.84919469827567,
                40.81086063912465
              ],
              [
                -73.84917358424858,
                40.810924340784
              ],
              [
                -73.84919088767829,
                40.81100004157146
              ],
              [
                -73.84921346822934,
                40.811059816644764
              ],
              [
                -73.8492482752338,
                40.811122263471
              ],
              [
                -73.84929711666227,
                40.81116083088632
              ],
              [
                -73.84937559536057,
                40.81122997375722
              ],
              [
                -73.84938420255818,
                40.81128707476754
              ],
              [
                -73.84937358980308,
                40.8113441523431
              ],
              [
                -73.84934201516832,
                40.81139721878769
              ],
              [
                -73.84927896005827,
                40.81146219294545
              ],
              [
                -73.84924043533324,
                40.81149799036517
              ],
              [
                -73.84920883946317,
                40.81156035034881
              ],
              [
                -73.84917019428873,
                40.811649255285694
              ],
              [
                -73.84914037531507,
                40.811698340230684
              ],
              [
                -73.84911051614033,
                40.81176601299328
              ],
              [
                -73.84908240942389,
                40.81183103394668
              ],
              [
                -73.84906301850037,
                40.81190535823212
              ],
              [
                -73.84904536052214,
                40.81198632429126
              ],
              [
                -73.84903822877388,
                40.812048716252406
              ],
              [
                -73.84903294180673,
                40.81211891007853
              ],
              [
                -73.84908514945569,
                40.81221023875827
              ],
              [
                -73.84914506617369,
                40.81229879360988
              ],
              [
                -73.84921243816422,
                40.81238419948506
              ],
              [
                -73.84928698004323,
                40.812466094574425
              ],
              [
                -73.84936837604964,
                40.812544131940214
              ],
              [
                -73.84945628138209,
                40.81261798098622
              ],
              [
                -73.84955032365964,
                40.8126873288585
              ],
              [
                -73.8496501044987,
                40.81275188177086
              ],
              [
                -73.84975520120099,
                40.81281136624995
              ],
              [
                -73.84986516854471,
                40.8128655302934
              ],
              [
                -73.84997954066965,
                40.81291414443821
              ],
              [
                -73.85009783305254,
                40.81295700273269
              ],
              [
                -73.85014521897382,
                40.8129722092388
              ],
              [
                -73.85021605769937,
                40.81299882803898
              ],
              [
                -73.85028418150665,
                40.81302926391442
              ],
              [
                -73.85034923468585,
                40.813063357945545
              ],
              [
                -73.85041087755909,
                40.813100932111354
              ],
              [
                -73.85054835887382,
                40.8132024199404
              ],
              [
                -73.85069123473296,
                40.81329951159003
              ],
              [
                -73.85083926181652,
                40.8133920416961
              ],
              [
                -73.85099218802844,
                40.81347985266294
              ],
              [
                -73.85109767952622,
                40.81351388512053
              ],
              [
                -73.85119997725174,
                40.813553144376186
              ],
              [
                -73.85136357625557,
                40.813550061697136
              ],
              [
                -73.85148124228404,
                40.813590682107
              ],
              [
                -73.85171353554064,
                40.813646744048626
              ],
              [
                -73.8519141729744,
                40.81367205792279
              ],
              [
                -73.85206357413182,
                40.813726060160775
              ],
              [
                -73.85247575393879,
                40.81373380190724
              ],
              [
                -73.85251652128306,
                40.8137361935279
              ],
              [
                -73.85255739434923,
                40.813735334655206
              ],
              [
                -73.85261014676433,
                40.81372934610967
              ],
              [
                -73.85266133386995,
                40.813717954136905
              ],
              [
                -73.85271000151494,
                40.8137013710889
              ],
              [
                -73.8527482834161,
                40.81368364638446
              ],
              [
                -73.85278364056278,
                40.813662706287985
              ],
              [
                -73.852847930473,
                40.813632657235814
              ],
              [
                -73.85291406875528,
                40.81360502060807
              ],
              [
                -73.85301912932853,
                40.813567034544974
              ],
              [
                -73.85312764700011,
                40.81353514843818
              ],
              [
                -73.85323900924007,
                40.8135095422681
              ],
              [
                -73.85335258746518,
                40.813490360568174
              ],
              [
                -73.85346774058523,
                40.813477711608684
              ],
              [
                -73.85385011057137,
                40.81357141220996
              ],
              [
                -73.85411268734923,
                40.81363514632315
              ],
              [
                -73.85443075596594,
                40.81371791906976
              ],
              [
                -73.85471008210905,
                40.81384440141541
              ],
              [
                -73.85504860133678,
                40.814073189622945
              ],
              [
                -73.8553196589575,
                40.81416156715021
              ],
              [
                -73.85538030243693,
                40.81428888208279
              ],
              [
                -73.85531511789715,
                40.81441214218983
              ],
              [
                -73.85514695315437,
                40.814368994296316
              ],
              [
                -73.8549550400126,
                40.81419341717086
              ],
              [
                -73.85484235409912,
                40.81409151801244
              ],
              [
                -73.85434655584949,
                40.81386385791406
              ],
              [
                -73.85374358027236,
                40.81364583315395
              ],
              [
                -73.85336857868805,
                40.813593903136336
              ],
              [
                -73.85297995235538,
                40.813673822603405
              ],
              [
                -73.85286936942734,
                40.81369282492303
              ],
              [
                -73.85294266398027,
                40.813750246390946
              ],
              [
                -73.85294165170167,
                40.81381958893503
              ],
              [
                -73.85288616298551,
                40.81388171252951
              ],
              [
                -73.85261262519265,
                40.81396258284018
              ],
              [
                -73.85265013335618,
                40.814363146473674
              ],
              [
                -73.8528188301477,
                40.814335174744016
              ],
              [
                -73.85291177501854,
                40.81450099671154
              ],
              [
                -73.85279369903853,
                40.81443515054267
              ],
              [
                -73.85262058756422,
                40.814505749279334
              ],
              [
                -73.85242403627308,
                40.81442008423487
              ],
              [
                -73.85246819393365,
                40.814279207419204
              ],
              [
                -73.8512981940995,
                40.81438294371766
              ],
              [
                -73.8511447226069,
                40.814396548876
              ],
              [
                -73.85091029734019,
                40.81419494294586
              ],
              [
                -73.85090731401337,
                40.81419237663027
              ],
              [
                -73.85060246203852,
                40.813930200042115
              ],
              [
                -73.85052470545138,
                40.81383031534663
              ],
              [
                -73.85041066820106,
                40.81358402388847
              ],
              [
                -73.85020486696233,
                40.81353429551917
              ],
              [
                -73.8500504981515,
                40.81348902434005
              ],
              [
                -73.84938556147972,
                40.81323686534507
              ],
              [
                -73.84876286855327,
                40.812904686119495
              ],
              [
                -73.84821486515582,
                40.81246290949828
              ],
              [
                -73.84770256622504,
                40.81145576486604
              ],
              [
                -73.84763099298789,
                40.81135174127133
              ],
              [
                -73.84758283399765,
                40.81128175025538
              ],
              [
                -73.84746772168266,
                40.81090394285429
              ],
              [
                -73.8471968450964,
                40.81058048761422
              ],
              [
                -73.84688871208358,
                40.81052132517833
              ],
              [
                -73.84617302872043,
                40.81067666443038
              ],
              [
                -73.84602600366073,
                40.81102371442156
              ],
              [
                -73.84591166916594,
                40.81133011244603
              ],
              [
                -73.84585412185099,
                40.811429471534375
              ],
              [
                -73.84576561702221,
                40.811582278841925
              ],
              [
                -73.845465386758,
                40.81176496816686
              ],
              [
                -73.84536842801629,
                40.8117514383606
              ],
              [
                -73.84528019236548,
                40.81177811674559
              ],
              [
                -73.84520736885104,
                40.811887022009515
              ],
              [
                -73.84513193773182,
                40.81210532460856
              ],
              [
                -73.84504990735974,
                40.81232224900448
              ],
              [
                -73.84496132121113,
                40.81253768005686
              ],
              [
                -73.84486622624331,
                40.81275150341687
              ],
              [
                -73.8447574149284,
                40.81297820360321
              ],
              [
                -73.84464128728749,
                40.81320279885394
              ],
              [
                -73.84451791370576,
                40.813425152899185
              ],
              [
                -73.84438736896749,
                40.81364513082717
              ],
              [
                -73.84417920712755,
                40.814186671061535
              ],
              [
                -73.84417911440977,
                40.814357177091956
              ],
              [
                -73.84408472247657,
                40.814571052924975
              ],
              [
                -73.84395505101229,
                40.8147960124459
              ],
              [
                -73.84377226781889,
                40.81513907127948
              ],
              [
                -73.84374053000356,
                40.81532169954207
              ],
              [
                -73.843580014338,
                40.815468346597264
              ],
              [
                -73.84344776972225,
                40.81569806525776
              ],
              [
                -73.84316992763719,
                40.81605105501337
              ],
              [
                -73.8431528346121,
                40.81627342796554
              ],
              [
                -73.84304301763714,
                40.816377320053974
              ],
              [
                -73.84306379033725,
                40.816558645026724
              ],
              [
                -73.84289216450946,
                40.81673045392878
              ],
              [
                -73.84279875811097,
                40.81693700864937
              ],
              [
                -73.84237534420508,
                40.81748984708057
              ],
              [
                -73.84209366311855,
                40.81805954295158
              ],
              [
                -73.84213733844804,
                40.818259033778745
              ],
              [
                -73.84212585817498,
                40.818458222415856
              ],
              [
                -73.8419868483405,
                40.8187743739081
              ],
              [
                -73.84194908907305,
                40.81880494783188
              ],
              [
                -73.84192413895823,
                40.81883237292191
              ],
              [
                -73.84188441800475,
                40.818850018104236
              ],
              [
                -73.84170330169793,
                40.818904212247226
              ],
              [
                -73.84156703725515,
                40.81894043221164
              ],
              [
                -73.84142475199273,
                40.819041880828784
              ],
              [
                -73.84134926355486,
                40.819122974148726
              ],
              [
                -73.8413111172789,
                40.81913614403746
              ],
              [
                -73.841289746031,
                40.819156509570085
              ],
              [
                -73.84124966376413,
                40.81916101508201
              ],
              [
                -73.84117574214497,
                40.8191830818138
              ],
              [
                -73.84104454654545,
                40.81923016959915
              ],
              [
                -73.84091856215316,
                40.81934212492082
              ],
              [
                -73.84084352269801,
                40.819355043389606
              ],
              [
                -73.84081344956941,
                40.819444691190384
              ],
              [
                -73.84070339531256,
                40.81947762296207
              ],
              [
                -73.84061881496048,
                40.81954950077844
              ],
              [
                -73.84052340849311,
                40.81953879722471
              ],
              [
                -73.84036528525624,
                40.81957775002431
              ],
              [
                -73.84028506253651,
                40.819626266126434
              ],
              [
                -73.84028066974183,
                40.81967849755754
              ],
              [
                -73.84023155497631,
                40.81973553077104
              ],
              [
                -73.8402891194478,
                40.81980770505643
              ],
              [
                -73.84024605965783,
                40.8198260235228
              ],
              [
                -73.84014625733194,
                40.81981924855246
              ],
              [
                -73.83999860407422,
                40.81993494681306
              ],
              [
                -73.83996730528736,
                40.820179082004
              ],
              [
                -73.8400605997814,
                40.820230071739864
              ],
              [
                -73.84010069135866,
                40.82032276172763
              ],
              [
                -73.8400535103904,
                40.820512079962526
              ],
              [
                -73.84005150923332,
                40.82061444541428
              ],
              [
                -73.83999403392806,
                40.82068256106775
              ],
              [
                -73.83998460083185,
                40.82079477636269
              ],
              [
                -73.83988142932418,
                40.82089686751444
              ],
              [
                -73.83985813718604,
                40.82100998191924
              ],
              [
                -73.83990886675211,
                40.82115479859535
              ],
              [
                -73.83983703184639,
                40.82143133110787
              ],
              [
                -73.83982926241688,
                40.82157150149604
              ],
              [
                -73.83992345125985,
                40.821739538441555
              ],
              [
                -73.83998726019148,
                40.821921932554226
              ],
              [
                -73.8399595945973,
                40.821999860742416
              ],
              [
                -73.84002656012701,
                40.82206773291293
              ],
              [
                -73.840017529957,
                40.82219024312548
              ],
              [
                -73.84011253729896,
                40.82229940621903
              ],
              [
                -73.84012785556982,
                40.82242142701467
              ],
              [
                -73.84041765401979,
                40.8229980294567
              ],
              [
                -73.84046603526154,
                40.82308002358374
              ],
              [
                -73.84043234895627,
                40.82311304429766
              ],
              [
                -73.8404204444649,
                40.823268525056605
              ],
              [
                -73.84044970316315,
                40.82334556649661
              ],
              [
                -73.840581564588,
                40.82359483615622
              ],
              [
                -73.84086821945728,
                40.82415350720821
              ],
              [
                -73.84100637880968,
                40.8242410271096
              ],
              [
                -73.8410237668874,
                40.8243411658736
              ],
              [
                -73.8410740038712,
                40.824526113427574
              ],
              [
                -73.84120250732005,
                40.82481685808503
              ],
              [
                -73.84127392633422,
                40.82490899785533
              ],
              [
                -73.84136106744249,
                40.82508503349905
              ],
              [
                -73.84138206178059,
                40.82514743339896
              ],
              [
                -73.84175457615963,
                40.825755420396746
              ],
              [
                -73.8417046629585,
                40.825767381987035
              ],
              [
                -73.84202727066095,
                40.826547447744986
              ],
              [
                -73.84207620647236,
                40.82653773536751
              ],
              [
                -73.84231712480313,
                40.827184684680276
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000744643168558,
        "objectid": 200,
        "shape_leng": 0.112661735435,
        "location_id": 200,
        "zone": "Riverdale/North Riverdale/Fieldston",
        "borough": "Bronx"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.896411335,
                40.904504520999915
              ],
              [
                -73.89643503499988,
                40.905092998999855
              ],
              [
                -73.89649229399996,
                40.90633965199994
              ],
              [
                -73.89654653299982,
                40.90750665599994
              ],
              [
                -73.89656464500001,
                40.9082858239999
              ],
              [
                -73.89659583799997,
                40.90962765899994
              ],
              [
                -73.89660613699981,
                40.91020224899991
              ],
              [
                -73.89663068599988,
                40.91129887799991
              ],
              [
                -73.8966306859999,
                40.91129892699993
              ],
              [
                -73.89663333881637,
                40.91141737492306
              ],
              [
                -73.89725326377472,
                40.91165551996923
              ],
              [
                -73.89810489644351,
                40.911790600005084
              ],
              [
                -73.89991648381654,
                40.91237068998033
              ],
              [
                -73.90066409305153,
                40.91246387783499
              ],
              [
                -73.90245663952454,
                40.91295893321298
              ],
              [
                -73.90851155008805,
                40.91500226401318
              ],
              [
                -73.91011276612554,
                40.915398734072234
              ],
              [
                -73.9103325682445,
                40.91553277700258
              ],
              [
                -73.91066855401304,
                40.91479942865664
              ],
              [
                -73.91070074571455,
                40.91475076932193
              ],
              [
                -73.9107393818088,
                40.91470488834581
              ],
              [
                -73.91078405014537,
                40.91466227514885
              ],
              [
                -73.9108342742287,
                40.91462338429266
              ],
              [
                -73.91088951830272,
                40.91458863063124
              ],
              [
                -73.91095893887852,
                40.91452884723514
              ],
              [
                -73.91102302821666,
                40.914465731055465
              ],
              [
                -73.91108150903531,
                40.914399555179116
              ],
              [
                -73.91113412832105,
                40.91433060593092
              ],
              [
                -73.91118065842387,
                40.914259181634065
              ],
              [
                -73.91122089804242,
                40.91418559131997
              ],
              [
                -73.91122114380083,
                40.9141404292674
              ],
              [
                -73.91121589433219,
                40.914095442742074
              ],
              [
                -73.91120519445253,
                40.914051015710875
              ],
              [
                -73.91123699234173,
                40.91400187185856
              ],
              [
                -73.91127466250195,
                40.91395517753351
              ],
              [
                -73.91131788006946,
                40.91391133540744
              ],
              [
                -73.91136627234505,
                40.91387072355504
              ],
              [
                -73.91141942200743,
                40.913833692194345
              ],
              [
                -73.91148558068319,
                40.91373219915614
              ],
              [
                -73.91154690203327,
                40.9136289726679
              ],
              [
                -73.91160330729478,
                40.91352414535435
              ],
              [
                -73.91165472402199,
                40.913417851896696
              ],
              [
                -73.91155029028242,
                40.91306981269463
              ],
              [
                -73.91150045783571,
                40.912968269325965
              ],
              [
                -73.91147332099729,
                40.91291090353671
              ],
              [
                -73.91143213027841,
                40.91282382621967
              ],
              [
                -73.91144882473377,
                40.912754682588634
              ],
              [
                -73.9115361890538,
                40.91230206277718
              ],
              [
                -73.91149190703744,
                40.912233769312
              ],
              [
                -73.91150475394844,
                40.91216987987947
              ],
              [
                -73.91157895749342,
                40.912135959319954
              ],
              [
                -73.91177495821104,
                40.91125218736267
              ],
              [
                -73.91181952393785,
                40.91114833486351
              ],
              [
                -73.91227584011669,
                40.909263516555455
              ],
              [
                -73.91226868851436,
                40.90923014850242
              ],
              [
                -73.91228107016744,
                40.90919336109481
              ],
              [
                -73.9123221765585,
                40.90918884493952
              ],
              [
                -73.91235918448416,
                40.909136733576894
              ],
              [
                -73.91234051167459,
                40.90905819476918
              ],
              [
                -73.91264932172878,
                40.90807081195536
              ],
              [
                -73.91272669647383,
                40.90799484239435
              ],
              [
                -73.91281956481328,
                40.90797531481988
              ],
              [
                -73.91290961086187,
                40.90794926755081
              ],
              [
                -73.91299604135064,
                40.907916930054235
              ],
              [
                -73.91307809486497,
                40.90787858721111
              ],
              [
                -73.91309062177854,
                40.90786849887935
              ],
              [
                -73.91310088929278,
                40.90785703510997
              ],
              [
                -73.91310863924069,
                40.907844484150004
              ],
              [
                -73.9131136767581,
                40.90783116158216
              ],
              [
                -73.91311587518277,
                40.90781740239073
              ],
              [
                -73.91311517923928,
                40.90780355253809
              ],
              [
                -73.91311160642807,
                40.9077899602662
              ],
              [
                -73.91310524658566,
                40.90777696733996
              ],
              [
                -73.91307611900355,
                40.907739530743044
              ],
              [
                -73.91304024119393,
                40.90770563487262
              ],
              [
                -73.91299834908081,
                40.90767597498969
              ],
              [
                -73.91295130194734,
                40.907651159467385
              ],
              [
                -73.91292539713486,
                40.90763234502044
              ],
              [
                -73.9129034249316,
                40.9076108425564
              ],
              [
                -73.91288586402978,
                40.9075871205375
              ],
              [
                -73.91287309701401,
                40.90756169578182
              ],
              [
                -73.91286540202547,
                40.907535122202916
              ],
              [
                -73.91386708918738,
                40.90495583544221
              ],
              [
                -73.91418416752522,
                40.904139333800934
              ],
              [
                -73.91429080087573,
                40.90409320746263
              ],
              [
                -73.9145903924332,
                40.90411900219645
              ],
              [
                -73.91501449586045,
                40.90308129910642
              ],
              [
                -73.91543172958416,
                40.90181268368833
              ],
              [
                -73.91523397419424,
                40.90169679724567
              ],
              [
                -73.9151845587166,
                40.90158428286216
              ],
              [
                -73.91667343212758,
                40.897527053119
              ],
              [
                -73.91672314187362,
                40.897486942489934
              ],
              [
                -73.9167281398398,
                40.89746088267584
              ],
              [
                -73.91672732153596,
                40.89743455567752
              ],
              [
                -73.916720710036,
                40.89740870362791
              ],
              [
                -73.91670849171784,
                40.89738405527042
              ],
              [
                -73.91685138033517,
                40.89693078285707
              ],
              [
                -73.91694161078398,
                40.896644551702416
              ],
              [
                -73.91763488207482,
                40.89494190535218
              ],
              [
                -73.9179087010731,
                40.894676766400025
              ],
              [
                -73.91786770394255,
                40.89460801163131
              ],
              [
                -73.91781851992614,
                40.89454242132222
              ],
              [
                -73.91782218257843,
                40.894422288084016
              ],
              [
                -73.91800263585222,
                40.89400861634836
              ],
              [
                -73.91816405458411,
                40.89356661453003
              ],
              [
                -73.91853688876205,
                40.89254568291542
              ],
              [
                -73.91888014391608,
                40.89160572195277
              ],
              [
                -73.91915943838116,
                40.89089435397416
              ],
              [
                -73.91978510749304,
                40.88928644430761
              ],
              [
                -73.9203928019909,
                40.88764850022696
              ],
              [
                -73.92058641888602,
                40.887182678309856
              ],
              [
                -73.920398527,
                40.887129304999846
              ],
              [
                -73.91869590499994,
                40.88688520399988
              ],
              [
                -73.91866590399985,
                40.8866665349999
              ],
              [
                -73.91865219599984,
                40.886446221999954
              ],
              [
                -73.91865495699979,
                40.88622532599991
              ],
              [
                -73.91867419999998,
                40.8860048999999
              ],
              [
                -73.91870982599978,
                40.885786007999926
              ],
              [
                -73.91876151499997,
                40.88556968399996
              ],
              [
                -73.91630438600004,
                40.88490951099997
              ],
              [
                -73.91586527699988,
                40.8848581069999
              ],
              [
                -73.9148927699999,
                40.8846247709999
              ],
              [
                -73.91440838499985,
                40.8849988979999
              ],
              [
                -73.91393522099992,
                40.8853813979999
              ],
              [
                -73.91386874899985,
                40.88543763799994
              ],
              [
                -73.91347365499988,
                40.88577197099993
              ],
              [
                -73.91302404599989,
                40.88617032399993
              ],
              [
                -73.91281277499982,
                40.886395186999884
              ],
              [
                -73.91261653600003,
                40.88662819299988
              ],
              [
                -73.91243612599989,
                40.886868448999934
              ],
              [
                -73.91227221199996,
                40.88711502299987
              ],
              [
                -73.91210920699979,
                40.88738250499992
              ],
              [
                -73.9119642749998,
                40.88765633999993
              ],
              [
                -73.9118380099998,
                40.887935438999904
              ],
              [
                -73.91173089099983,
                40.88821871299996
              ],
              [
                -73.91130760799977,
                40.888236029999874
              ],
              [
                -73.91115061799995,
                40.88827710599987
              ],
              [
                -73.91075165299989,
                40.888492454999906
              ],
              [
                -73.90981767099994,
                40.888996568999886
              ],
              [
                -73.90975930299997,
                40.889250027999886
              ],
              [
                -73.90929422499987,
                40.889282296999944
              ],
              [
                -73.90900536,
                40.889500140999864
              ],
              [
                -73.90887023199998,
                40.88942175399989
              ],
              [
                -73.90881651000004,
                40.889390590999916
              ],
              [
                -73.90877717899996,
                40.889370172999854
              ],
              [
                -73.90855714199998,
                40.88928966699996
              ],
              [
                -73.90853308099986,
                40.88916738899993
              ],
              [
                -73.90848550899992,
                40.88914254199988
              ],
              [
                -73.90843131799984,
                40.889124993999914
              ],
              [
                -73.90837360299984,
                40.889116015999896
              ],
              [
                -73.90831585200002,
                40.889115896999954
              ],
              [
                -73.9079634,
                40.88885975299993
              ],
              [
                -73.90759731899983,
                40.888200979999965
              ],
              [
                -73.90738830199982,
                40.88781969199989
              ],
              [
                -73.90626252500002,
                40.88757033799994
              ],
              [
                -73.90533517099996,
                40.887354598999885
              ],
              [
                -73.90413642399992,
                40.887080708999896
              ],
              [
                -73.90381882699994,
                40.88747900899994
              ],
              [
                -73.90358612699993,
                40.8878014589999
              ],
              [
                -73.903373145,
                40.88813234499987
              ],
              [
                -73.9031806419999,
                40.88847059499993
              ],
              [
                -73.90300926099984,
                40.888815111999875
              ],
              [
                -73.90285949099989,
                40.8891647439999
              ],
              [
                -73.90273228799997,
                40.88913231799993
              ],
              [
                -73.90259906499982,
                40.88911217899994
              ],
              [
                -73.90246297399985,
                40.88910514799996
              ],
              [
                -73.90232738499991,
                40.88911138199995
              ],
              [
                -73.90219556299989,
                40.88913041499988
              ],
              [
                -73.90207033599997,
                40.88915127199995
              ],
              [
                -73.90193848899985,
                40.88915603999994
              ],
              [
                -73.90180553799988,
                40.8891434199999
              ],
              [
                -73.90167746699991,
                40.88911356799987
              ],
              [
                -73.90155978899989,
                40.8890681819999
              ],
              [
                -73.90145684499987,
                40.88901019299989
              ],
              [
                -73.90136756999983,
                40.88895949999994
              ],
              [
                -73.90126187899992,
                40.88892096799989
              ],
              [
                -73.90114449999989,
                40.888898679999926
              ],
              [
                -73.90102228799991,
                40.888895126999884
              ],
              [
                -73.90090301399997,
                40.888910548999895
              ],
              [
                -73.90079375199993,
                40.88894284499989
              ],
              [
                -73.9006996359998,
                40.88898822099989
              ],
              [
                -73.90056048999982,
                40.88911965299994
              ],
              [
                -73.90040701199986,
                40.889242697999876
              ],
              [
                -73.90024058900005,
                40.88935606899991
              ],
              [
                -73.90006286299982,
                40.88945861599991
              ],
              [
                -73.89987570700002,
                40.88954945799986
              ],
              [
                -73.89959425899988,
                40.889816998999905
              ],
              [
                -73.89853911899992,
                40.89038907399993
              ],
              [
                -73.89842801199981,
                40.890430973999884
              ],
              [
                -73.89834896199993,
                40.89044499699991
              ],
              [
                -73.89828904299992,
                40.890442980999914
              ],
              [
                -73.89825201999993,
                40.89043496999996
              ],
              [
                -73.89820200999998,
                40.89041593499998
              ],
              [
                -73.89801518,
                40.89031454599989
              ],
              [
                -73.89787887499983,
                40.89027610099993
              ],
              [
                -73.89776669099993,
                40.89050938999991
              ],
              [
                -73.89769601099988,
                40.890681823999955
              ],
              [
                -73.89731936199985,
                40.8916158609999
              ],
              [
                -73.89728112499986,
                40.89171068399993
              ],
              [
                -73.89705763499997,
                40.89214045799991
              ],
              [
                -73.89675790099987,
                40.892645443999925
              ],
              [
                -73.89643170799985,
                40.89365527899989
              ],
              [
                -73.89670747099987,
                40.89580502899987
              ],
              [
                -73.896994929,
                40.897878723999916
              ],
              [
                -73.89709149099991,
                40.89859331799995
              ],
              [
                -73.89715717199994,
                40.89937182699986
              ],
              [
                -73.89713947299998,
                40.8996194349999
              ],
              [
                -73.89707329199993,
                40.90008389099995
              ],
              [
                -73.89704651399992,
                40.90022470299987
              ],
              [
                -73.8970336369998,
                40.900278620999885
              ],
              [
                -73.89702895099995,
                40.90031520399991
              ],
              [
                -73.89697054199992,
                40.90061116799995
              ],
              [
                -73.89687844599996,
                40.90104057999989
              ],
              [
                -73.89661002199993,
                40.90220351299991
              ],
              [
                -73.89640895699986,
                40.9029611079999
              ],
              [
                -73.89635785999997,
                40.9032001319999
              ],
              [
                -73.896411335,
                40.904504520999915
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000641384265972,
        "objectid": 204,
        "shape_leng": 0.101912418311,
        "location_id": 204,
        "zone": "Rossville/Woodrow",
        "borough": "Staten Island"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -74.2032648999999,
                40.55562847699989
              ],
              [
                -74.20325826899995,
                40.55570233899991
              ],
              [
                -74.20324645499988,
                40.55577582299988
              ],
              [
                -74.20322948999994,
                40.5558487179999
              ],
              [
                -74.20320742499993,
                40.55592081499991
              ],
              [
                -74.20318032199998,
                40.555991904999914
              ],
              [
                -74.20314825999996,
                40.556061784999876
              ],
              [
                -74.2032263689999,
                40.556087191999886
              ],
              [
                -74.20330246299997,
                40.55611593799991
              ],
              [
                -74.20337629799997,
                40.55614792799985
              ],
              [
                -74.20344936699998,
                40.556183969999886
              ],
              [
                -74.20351956699993,
                40.55622318999985
              ],
              [
                -74.20358106099994,
                40.55626173299989
              ],
              [
                -74.20363978099991,
                40.55630271299988
              ],
              [
                -74.20369556299995,
                40.55634601699993
              ],
              [
                -74.20384426499996,
                40.55648572799987
              ],
              [
                -74.20398449999993,
                40.556617484999954
              ],
              [
                -74.2049282399999,
                40.557205488999905
              ],
              [
                -74.20506288299997,
                40.5572778759999
              ],
              [
                -74.20520041099995,
                40.5573322209999
              ],
              [
                -74.20530544099992,
                40.557368621999956
              ],
              [
                -74.20557588399996,
                40.5574435929999
              ],
              [
                -74.205854485,
                40.55750245699991
              ],
              [
                -74.2061387249999,
                40.557544505999914
              ],
              [
                -74.20642597299995,
                40.55756934899989
              ],
              [
                -74.20671355499994,
                40.557576935999876
              ],
              [
                -74.20699885299997,
                40.55756748899989
              ],
              [
                -74.20880330699998,
                40.55730868899991
              ],
              [
                -74.21050789400002,
                40.557064181999934
              ],
              [
                -74.21220033999998,
                40.55644342999993
              ],
              [
                -74.21350175499995,
                40.55566761799988
              ],
              [
                -74.21437138999993,
                40.55558806599988
              ],
              [
                -74.21455413899992,
                40.55559031799988
              ],
              [
                -74.21455422299992,
                40.55559031799992
              ],
              [
                -74.21455430499994,
                40.5555903089999
              ],
              [
                -74.21473791199993,
                40.555576142999875
              ],
              [
                -74.21473802999996,
                40.55557613299987
              ],
              [
                -74.21473815899992,
                40.555576114999965
              ],
              [
                -74.21491900999992,
                40.555545456999894
              ],
              [
                -74.21509339699995,
                40.55549893299997
              ],
              [
                -74.21525777299996,
                40.555437837999904
              ],
              [
                -74.21721338199995,
                40.55471485899994
              ],
              [
                -74.21784397799998,
                40.55443271799988
              ],
              [
                -74.21787855399997,
                40.55423566199992
              ],
              [
                -74.21789469299988,
                40.554036199999906
              ],
              [
                -74.21789204399995,
                40.55383585499987
              ],
              [
                -74.21787055399989,
                40.55363617699987
              ],
              [
                -74.21783047799995,
                40.553438685999886
              ],
              [
                -74.21777232599997,
                40.55324488499987
              ],
              [
                -74.21778377699997,
                40.55304918799994
              ],
              [
                -74.21877630399993,
                40.552473628999955
              ],
              [
                -74.21911576499998,
                40.5522323139999
              ],
              [
                -74.21931406699997,
                40.5520551349999
              ],
              [
                -74.22106803199995,
                40.55055473299996
              ],
              [
                -74.2211486979999,
                40.55048965799989
              ],
              [
                -74.22121804999989,
                40.55041641999993
              ],
              [
                -74.2212741239999,
                40.550336848999926
              ],
              [
                -74.22131562699998,
                40.550253073999926
              ],
              [
                -74.22109340399999,
                40.54977355599989
              ],
              [
                -74.22087751999999,
                40.54920399899989
              ],
              [
                -74.22083496899992,
                40.549091739999895
              ],
              [
                -74.22064536099997,
                40.54859150899989
              ],
              [
                -74.22060624599999,
                40.54848651999988
              ],
              [
                -74.22056994699994,
                40.54839063799991
              ],
              [
                -74.22036701999991,
                40.54783386999986
              ],
              [
                -74.22008210999998,
                40.54713827099988
              ],
              [
                -74.21997344699999,
                40.546834415999854
              ],
              [
                -74.2198714179999,
                40.54657677099993
              ],
              [
                -74.21981449799993,
                40.546438396999854
              ],
              [
                -74.21954309399989,
                40.5456993839999
              ],
              [
                -74.21929684599996,
                40.54505270699986
              ],
              [
                -74.21899304799996,
                40.54430991699988
              ],
              [
                -74.21888584299994,
                40.544070039999895
              ],
              [
                -74.21873688699995,
                40.54366800399986
              ],
              [
                -74.21844432499992,
                40.54296409499988
              ],
              [
                -74.21817195699992,
                40.54225028499995
              ],
              [
                -74.21803946299991,
                40.54191335999993
              ],
              [
                -74.21800676099997,
                40.54182852099994
              ],
              [
                -74.21776941599992,
                40.54112662999992
              ],
              [
                -74.21775655499991,
                40.54098679399993
              ],
              [
                -74.21767785799992,
                40.5401315469999
              ],
              [
                -74.21770976099997,
                40.53831199299994
              ],
              [
                -74.21773895399996,
                40.538030206999935
              ],
              [
                -74.21779984299988,
                40.53774949499996
              ],
              [
                -74.21781643899992,
                40.537655948999934
              ],
              [
                -74.21786779799996,
                40.53736657899988
              ],
              [
                -74.21791053499996,
                40.53702886099995
              ],
              [
                -74.21806225399989,
                40.53649651299988
              ],
              [
                -74.21819886099995,
                40.53585901999993
              ],
              [
                -74.21828207299988,
                40.53548704299991
              ],
              [
                -74.2183151519999,
                40.535312078999915
              ],
              [
                -74.21829305299998,
                40.534765002999976
              ],
              [
                -74.2182685219999,
                40.534525247999966
              ],
              [
                -74.21800676499996,
                40.53229858999993
              ],
              [
                -74.21775551799986,
                40.53144884899995
              ],
              [
                -74.21752355299995,
                40.530643798999925
              ],
              [
                -74.21728324499995,
                40.52987328299985
              ],
              [
                -74.21701386699995,
                40.52912208399994
              ],
              [
                -74.216845171,
                40.52864208699986
              ],
              [
                -74.21672062699997,
                40.52840575299991
              ],
              [
                -74.21666369900001,
                40.52828331599992
              ],
              [
                -74.21661081199987,
                40.52817658599992
              ],
              [
                -74.21648646999992,
                40.52792562699994
              ],
              [
                -74.2161939549999,
                40.52735009899994
              ],
              [
                -74.21586638599997,
                40.52664794199994
              ],
              [
                -74.21574341099989,
                40.52646400599987
              ],
              [
                -74.21564470599999,
                40.525981847999944
              ],
              [
                -74.21560783599998,
                40.52549473899997
              ],
              [
                -74.21559935899987,
                40.52437390099989
              ],
              [
                -74.21559699299993,
                40.52406106999991
              ],
              [
                -74.2156723039999,
                40.523957627999906
              ],
              [
                -74.21409806799993,
                40.524097194999925
              ],
              [
                -74.2135190009999,
                40.524148838999885
              ],
              [
                -74.21229294499989,
                40.52427685299989
              ],
              [
                -74.21201514099992,
                40.524316230999915
              ],
              [
                -74.21142518399989,
                40.524414101999874
              ],
              [
                -74.21028201899998,
                40.52472106699988
              ],
              [
                -74.2101209019999,
                40.52479737599986
              ],
              [
                -74.20997053199989,
                40.52488777599995
              ],
              [
                -74.20983404399988,
                40.52499076799997
              ],
              [
                -74.20983398499993,
                40.524990811999864
              ],
              [
                -74.20983392599999,
                40.52499086699992
              ],
              [
                -74.20971385199991,
                40.5251045449999
              ],
              [
                -74.20961205799995,
                40.52522663499996
              ],
              [
                -74.20953726399988,
                40.52526850299993
              ],
              [
                -74.20945757499996,
                40.52530473499994
              ],
              [
                -74.20937372099999,
                40.525334998999874
              ],
              [
                -74.20928646399996,
                40.52535902099987
              ],
              [
                -74.20919660199999,
                40.525376579999865
              ],
              [
                -74.209104955,
                40.52538751599994
              ],
              [
                -74.20901235799994,
                40.52539172999996
              ],
              [
                -74.2089196559999,
                40.52538918299991
              ],
              [
                -74.20882769699993,
                40.52537989899984
              ],
              [
                -74.208737317,
                40.525363960999854
              ],
              [
                -74.20864934299995,
                40.525341516999916
              ],
              [
                -74.20856457599996,
                40.52531276899992
              ],
              [
                -74.20844138199995,
                40.525286719999926
              ],
              [
                -74.20831655199999,
                40.52526569499987
              ],
              [
                -74.20819043499995,
                40.52524975299989
              ],
              [
                -74.20806338799993,
                40.52523893799989
              ],
              [
                -74.20793576699992,
                40.52523328199993
              ],
              [
                -74.20780793299994,
                40.52523279999989
              ],
              [
                -74.20768024599988,
                40.52523749299995
              ],
              [
                -74.2075530649999,
                40.52524734899991
              ],
              [
                -74.207426748,
                40.525262338999916
              ],
              [
                -74.20730165099995,
                40.5252824219999
              ],
              [
                -74.20674433099995,
                40.52534553599986
              ],
              [
                -74.20666645799994,
                40.525354357999966
              ],
              [
                -74.20546090199988,
                40.525544336999936
              ],
              [
                -74.20525952799987,
                40.52557617999988
              ],
              [
                -74.203687632,
                40.525782940999896
              ],
              [
                -74.20276973299997,
                40.52590721799992
              ],
              [
                -74.20217525999989,
                40.52606646299992
              ],
              [
                -74.20152661599995,
                40.526265205999934
              ],
              [
                -74.201425651,
                40.52638203299995
              ],
              [
                -74.20133258999991,
                40.52650262099994
              ],
              [
                -74.20124767199997,
                40.5266266589999
              ],
              [
                -74.20117111699992,
                40.52675382699987
              ],
              [
                -74.20103324199994,
                40.5269194149999
              ],
              [
                -74.200879328,
                40.527077231999904
              ],
              [
                -74.20071057499999,
                40.52722595199986
              ],
              [
                -74.2005284199999,
                40.52736440399985
              ],
              [
                -74.19984360499993,
                40.52785744099988
              ],
              [
                -74.19927554899992,
                40.52819690099989
              ],
              [
                -74.19909783799994,
                40.52829964499994
              ],
              [
                -74.19779445399999,
                40.52903208399988
              ],
              [
                -74.19608390199986,
                40.52991174699987
              ],
              [
                -74.19478532600002,
                40.53051789799984
              ],
              [
                -74.19453974799997,
                40.5306197419999
              ],
              [
                -74.1939845689999,
                40.53087104699991
              ],
              [
                -74.19313382999997,
                40.531194914999894
              ],
              [
                -74.19250928499991,
                40.53149740799989
              ],
              [
                -74.19174368799999,
                40.53191669699987
              ],
              [
                -74.19237403399998,
                40.53304061099991
              ],
              [
                -74.19267129299996,
                40.533633488999925
              ],
              [
                -74.19300900999994,
                40.53432605099988
              ],
              [
                -74.19329057000002,
                40.53487778199998
              ],
              [
                -74.19335436399993,
                40.53499987599987
              ],
              [
                -74.19341548699992,
                40.535120498999945
              ],
              [
                -74.19356025199987,
                40.53540617199995
              ],
              [
                -74.19377169299999,
                40.53583384699993
              ],
              [
                -74.19409661699994,
                40.5365072449999
              ],
              [
                -74.19444077699994,
                40.537156592999914
              ],
              [
                -74.19468316799997,
                40.537661891999946
              ],
              [
                -74.19476262699999,
                40.53783140799991
              ],
              [
                -74.19507682399993,
                40.53848646999991
              ],
              [
                -74.19542518899992,
                40.53917847399987
              ],
              [
                -74.19576408099995,
                40.53983810199987
              ],
              [
                -74.19609298399995,
                40.540497735999914
              ],
              [
                -74.19640185999997,
                40.54117319999994
              ],
              [
                -74.19671334599992,
                40.541846973999895
              ],
              [
                -74.19702175199988,
                40.542526848999934
              ],
              [
                -74.19730759099996,
                40.543262044999935
              ],
              [
                -74.19840857099992,
                40.54530644699991
              ],
              [
                -74.19860457899992,
                40.545679908999915
              ],
              [
                -74.19912044699996,
                40.546708706999894
              ],
              [
                -74.19985031599995,
                40.5481324189999
              ],
              [
                -74.20012791899997,
                40.548759873999856
              ],
              [
                -74.20018262899993,
                40.54890664599988
              ],
              [
                -74.20097039299995,
                40.5507901389999
              ],
              [
                -74.20185089099988,
                40.552689430999926
              ],
              [
                -74.20216129599993,
                40.55335994099991
              ],
              [
                -74.20234825099999,
                40.55375861799991
              ],
              [
                -74.20262828599995,
                40.55434351199996
              ],
              [
                -74.2029103849999,
                40.554944665999905
              ],
              [
                -74.2032648999999,
                40.55562847699989
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000619350747819,
        "objectid": 201,
        "shape_leng": 0.130404117686,
        "location_id": 201,
        "zone": "Rockaway Park",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.81849901800001,
                40.5873623529999
              ],
              [
                -73.81880351800001,
                40.587495921999896
              ],
              [
                -73.81890104000003,
                40.58757409799996
              ],
              [
                -73.81893219199988,
                40.58759907299991
              ],
              [
                -73.81897507799992,
                40.587644058999906
              ],
              [
                -73.81904410199994,
                40.587733741999934
              ],
              [
                -73.81909407399996,
                40.587831960999885
              ],
              [
                -73.81912265299988,
                40.58793502599986
              ],
              [
                -73.81912652299997,
                40.587996764999964
              ],
              [
                -73.81912916381579,
                40.588038892972584
              ],
              [
                -73.8191882946688,
                40.58797371677861
              ],
              [
                -73.81923430777522,
                40.5879332933974
              ],
              [
                -73.81929743026505,
                40.58789496589782
              ],
              [
                -73.81934686541226,
                40.58787282886022
              ],
              [
                -73.81968658634672,
                40.58769368688696
              ],
              [
                -73.82003299074137,
                40.58751102017109
              ],
              [
                -73.82039765036815,
                40.58732160621366
              ],
              [
                -73.8211617779835,
                40.586924690234156
              ],
              [
                -73.82145971991768,
                40.58676992492123
              ],
              [
                -73.82199421976468,
                40.5870533304065
              ],
              [
                -73.82246212330784,
                40.58730228474667
              ],
              [
                -73.82256733467796,
                40.587360098081334
              ],
              [
                -73.82264303518465,
                40.58740168458186
              ],
              [
                -73.82271074897726,
                40.58742580613709
              ],
              [
                -73.82278668904684,
                40.58745286624584
              ],
              [
                -73.82286311305266,
                40.58747562388299
              ],
              [
                -73.8228937850444,
                40.58748201986876
              ],
              [
                -73.82293688631506,
                40.58749164972748
              ],
              [
                -73.82298001388828,
                40.58749111290447
              ],
              [
                -73.82302002675071,
                40.587493966089156
              ],
              [
                -73.82306926486032,
                40.58749178998469
              ],
              [
                -73.82310704583925,
                40.587490120589194
              ],
              [
                -73.8231555877068,
                40.587487970348164
              ],
              [
                -73.82320743426241,
                40.587478314815854
              ],
              [
                -73.823261572582,
                40.587468241280696
              ],
              [
                -73.82474352289884,
                40.58704514197361
              ],
              [
                -73.8247544742627,
                40.58706910000852
              ],
              [
                -73.82475708090976,
                40.58706910398409
              ],
              [
                -73.82480116623435,
                40.58715461536995
              ],
              [
                -73.82481333907114,
                40.587151322035965
              ],
              [
                -73.82477099301649,
                40.58706515072738
              ],
              [
                -73.82477620978837,
                40.58706383302067
              ],
              [
                -73.82476427875102,
                40.58703921577226
              ],
              [
                -73.82495652892945,
                40.586984326713804
              ],
              [
                -73.82524882706767,
                40.58690086942337
              ],
              [
                -73.82668838981161,
                40.586489799000006
              ],
              [
                -73.82784717485339,
                40.586137100330134
              ],
              [
                -73.82791491176476,
                40.5861150571124
              ],
              [
                -73.82797849511182,
                40.58608672111368
              ],
              [
                -73.82803692404548,
                40.586052538366324
              ],
              [
                -73.82808927885533,
                40.58601304693411
              ],
              [
                -73.8281347354472,
                40.58596886844342
              ],
              [
                -73.82817257831394,
                40.58592069829708
              ],
              [
                -73.82817935896809,
                40.585910367667324
              ],
              [
                -73.82819566531695,
                40.58588552243649
              ],
              [
                -73.82822250440167,
                40.58583865492305
              ],
              [
                -73.82824469493104,
                40.58579445389311
              ],
              [
                -73.82826267442242,
                40.5857520669423
              ],
              [
                -73.82827661199255,
                40.58571110398111
              ],
              [
                -73.82836036871306,
                40.58542692421065
              ],
              [
                -73.82844883541067,
                40.585125263817595
              ],
              [
                -73.82846704297498,
                40.585063172162656
              ],
              [
                -73.82850475950465,
                40.584929329682886
              ],
              [
                -73.82852471261212,
                40.58485422038247
              ],
              [
                -73.82921665703279,
                40.58466566280552
              ],
              [
                -73.82922322222436,
                40.58467614618565
              ],
              [
                -73.82923330280437,
                40.5846922254238
              ],
              [
                -73.82964511265375,
                40.584579236360725
              ],
              [
                -73.82995875885679,
                40.584492313959174
              ],
              [
                -73.83035863109153,
                40.58438148239096
              ],
              [
                -73.83115765903744,
                40.584170216785935
              ],
              [
                -73.83122819886285,
                40.58415157234287
              ],
              [
                -73.83122690074686,
                40.58411388211674
              ],
              [
                -73.83584132334414,
                40.58287524942917
              ],
              [
                -73.83649535606483,
                40.58282757145429
              ],
              [
                -73.8364974655717,
                40.582819740264725
              ],
              [
                -73.83649754841137,
                40.582819740382604
              ],
              [
                -73.83749042864683,
                40.58277110741209
              ],
              [
                -73.83819230033566,
                40.58273150172096
              ],
              [
                -73.83819123533861,
                40.58271787503072
              ],
              [
                -73.83829873026785,
                40.58271302862349
              ],
              [
                -73.83830919856948,
                40.582760609225566
              ],
              [
                -73.83846883217524,
                40.5827402026538
              ],
              [
                -73.83848216240924,
                40.582800781704194
              ],
              [
                -73.83855306451112,
                40.58279171429926
              ],
              [
                -73.83853891608467,
                40.5827273424076
              ],
              [
                -73.83860223382548,
                40.58271924637283
              ],
              [
                -73.83866495939662,
                40.5827112290482
              ],
              [
                -73.83868793405183,
                40.58270107700946
              ],
              [
                -73.8386630081304,
                40.582668307377666
              ],
              [
                -73.83875192458102,
                40.582628998727245
              ],
              [
                -73.83879155856049,
                40.58261287528828
              ],
              [
                -73.83882938757823,
                40.58259440263508
              ],
              [
                -73.83888249024051,
                40.5825623690427
              ],
              [
                -73.83893018678124,
                40.58252572328265
              ],
              [
                -73.83897179252091,
                40.58248499140799
              ],
              [
                -73.83900671021873,
                40.58244075812746
              ],
              [
                -73.83902799504563,
                40.58240605117919
              ],
              [
                -73.83904515203258,
                40.58237003990317
              ],
              [
                -73.83910865862316,
                40.582272878813484
              ],
              [
                -73.83916563218244,
                40.582173391093406
              ],
              [
                -73.83921592662652,
                40.58207183188689
              ],
              [
                -73.83925941300275,
                40.581968461649055
              ],
              [
                -73.83929597982015,
                40.581863545478186
              ],
              [
                -73.83935385899039,
                40.581857262805556
              ],
              [
                -73.84033325297445,
                40.58180755277049
              ],
              [
                -73.84129969123791,
                40.58175849046556
              ],
              [
                -73.84225010404165,
                40.581710235344076
              ],
              [
                -73.84233979552249,
                40.581705680693865
              ],
              [
                -73.8432082793877,
                40.58166760860317
              ],
              [
                -73.8441571217811,
                40.58162600643133
              ],
              [
                -73.8450567062853,
                40.58158655664318
              ],
              [
                -73.84521016865186,
                40.5815798262637
              ],
              [
                -73.84624074421872,
                40.5817693841386
              ],
              [
                -73.84725841773745,
                40.58194922099893
              ],
              [
                -73.84819630556807,
                40.582114949858294
              ],
              [
                -73.84861053538168,
                40.58218814264622
              ],
              [
                -73.84930667192806,
                40.582166161193626
              ],
              [
                -73.8504438514919,
                40.5821302435814
              ],
              [
                -73.85134716820436,
                40.58182772564319
              ],
              [
                -73.85218976840359,
                40.58154553231258
              ],
              [
                -73.85305549585115,
                40.58125526785501
              ],
              [
                -73.85381681956373,
                40.580853655037664
              ],
              [
                -73.85456020425893,
                40.58046149731443
              ],
              [
                -73.85532244619522,
                40.58005842202098
              ],
              [
                -73.85607299805861,
                40.579661516574575
              ],
              [
                -73.8568386972949,
                40.57925659271926
              ],
              [
                -73.85758465057629,
                40.578864533874594
              ],
              [
                -73.85833844346155,
                40.57846834605504
              ],
              [
                -73.85908862067566,
                40.578074048806
              ],
              [
                -73.85984470698162,
                40.577672694329856
              ],
              [
                -73.8606003068899,
                40.57727158725375
              ],
              [
                -73.86288301945423,
                40.576059761627334
              ],
              [
                -73.86280361999994,
                40.57593303499989
              ],
              [
                -73.86278001199996,
                40.57589856099986
              ],
              [
                -73.8627506159999,
                40.575857984999864
              ],
              [
                -73.86333068899987,
                40.5753832519999
              ],
              [
                -73.86341227899987,
                40.5753164769999
              ],
              [
                -73.86396642099993,
                40.57502678699988
              ],
              [
                -73.86394267899996,
                40.574988107999914
              ],
              [
                -73.86340629400003,
                40.57411433099986
              ],
              [
                -73.86361132999987,
                40.57382305799997
              ],
              [
                -73.86449302999984,
                40.57354277599991
              ],
              [
                -73.86662812000002,
                40.5730576489999
              ],
              [
                -73.86633836399983,
                40.57238167499988
              ],
              [
                -73.86522555399998,
                40.57045847199989
              ],
              [
                -73.86454176099993,
                40.56924292199992
              ],
              [
                -73.86410310099986,
                40.569354608999916
              ],
              [
                -73.86406802399988,
                40.569297978999934
              ],
              [
                -73.86316930299984,
                40.56773927199992
              ],
              [
                -73.86308767499982,
                40.567597728999914
              ],
              [
                -73.86286892288632,
                40.56683057177313
              ],
              [
                -73.86283290775314,
                40.566818668268446
              ],
              [
                -73.86265927675198,
                40.56680029670612
              ],
              [
                -73.86255286029154,
                40.56681001230082
              ],
              [
                -73.86239006797445,
                40.56688989281008
              ],
              [
                -73.86226388529106,
                40.566974048964596
              ],
              [
                -73.86214592196148,
                40.567045948811234
              ],
              [
                -73.86202965574294,
                40.56710844193087
              ],
              [
                -73.86116232688367,
                40.567504232051306
              ],
              [
                -73.86022276711229,
                40.56792109732444
              ],
              [
                -73.86004153002388,
                40.56799843894274
              ],
              [
                -73.85985829196693,
                40.568071843395465
              ],
              [
                -73.85977216149385,
                40.56810427358402
              ],
              [
                -73.85968856304649,
                40.56813429470619
              ],
              [
                -73.85957813852878,
                40.5681677128154
              ],
              [
                -73.8595035986373,
                40.56818605581619
              ],
              [
                -73.85942657606537,
                40.568205007884465
              ],
              [
                -73.8592271825485,
                40.56826204617407
              ],
              [
                -73.85909318999227,
                40.568310798256775
              ],
              [
                -73.85852684289735,
                40.56852014971852
              ],
              [
                -73.858271174134,
                40.568618144918446
              ],
              [
                -73.85791711481181,
                40.56879210324577
              ],
              [
                -73.85777929081,
                40.568859238137684
              ],
              [
                -73.85775087478515,
                40.568870265115116
              ],
              [
                -73.8576707951522,
                40.56890133870102
              ],
              [
                -73.85734798265783,
                40.56899135998707
              ],
              [
                -73.85727709576854,
                40.569022962381474
              ],
              [
                -73.85713422195636,
                40.569126875590044
              ],
              [
                -73.8568830828207,
                40.5692194160495
              ],
              [
                -73.85681285637894,
                40.56924595756865
              ],
              [
                -73.85664094497899,
                40.56929855877574
              ],
              [
                -73.85645598828029,
                40.56934443452474
              ],
              [
                -73.85623100571082,
                40.569436358403735
              ],
              [
                -73.85612202437311,
                40.56950549051674
              ],
              [
                -73.85596762967015,
                40.569523253046185
              ],
              [
                -73.85585072247942,
                40.56955516524605
              ],
              [
                -73.85574599043328,
                40.5695973586782
              ],
              [
                -73.85551038432557,
                40.56965884706633
              ],
              [
                -73.85524079248215,
                40.56975142438561
              ],
              [
                -73.8550557024824,
                40.569814983670355
              ],
              [
                -73.85499076009883,
                40.56986537710774
              ],
              [
                -73.85493371329643,
                40.56989687691534
              ],
              [
                -73.85487827913525,
                40.569913844513444
              ],
              [
                -73.85471701291569,
                40.569936064702475
              ],
              [
                -73.8544210818527,
                40.57006354647071
              ],
              [
                -73.85423306127085,
                40.57013716925942
              ],
              [
                -73.8540958517921,
                40.57025512554546
              ],
              [
                -73.85396556577076,
                40.5703125488727
              ],
              [
                -73.85384605365918,
                40.57033074642008
              ],
              [
                -73.85381160480503,
                40.57033599183698
              ],
              [
                -73.85357525479438,
                40.57034136437587
              ],
              [
                -73.85332207852214,
                40.57043243577317
              ],
              [
                -73.85312102850051,
                40.57050635573066
              ],
              [
                -73.8527416269569,
                40.570605305649075
              ],
              [
                -73.8522020239966,
                40.57074510300149
              ],
              [
                -73.8518407838795,
                40.570891505698
              ],
              [
                -73.8517692209879,
                40.57092755592739
              ],
              [
                -73.85168071856945,
                40.5709721381476
              ],
              [
                -73.85153838483795,
                40.57106626704578
              ],
              [
                -73.85128141360782,
                40.57117218726261
              ],
              [
                -73.85119585627858,
                40.57118706150655
              ],
              [
                -73.85110217430288,
                40.57121318098269
              ],
              [
                -73.85085482979719,
                40.571301292407654
              ],
              [
                -73.85071291963756,
                40.571342585945395
              ],
              [
                -73.85053462925642,
                40.571407642657924
              ],
              [
                -73.8503876633553,
                40.571451035065664
              ],
              [
                -73.85024821434561,
                40.571492207765424
              ],
              [
                -73.84995330636089,
                40.571588385290596
              ],
              [
                -73.84978693455582,
                40.57165195232749
              ],
              [
                -73.84969529478947,
                40.57168265828363
              ],
              [
                -73.84957881218321,
                40.571732376262254
              ],
              [
                -73.8494932823788,
                40.57176272551272
              ],
              [
                -73.8493710236191,
                40.571806108948984
              ],
              [
                -73.84930014476147,
                40.571836895085205
              ],
              [
                -73.84915963634386,
                40.57187790910384
              ],
              [
                -73.84900064117859,
                40.57195839648904
              ],
              [
                -73.84890933304747,
                40.57200424812317
              ],
              [
                -73.84883931672952,
                40.57201892477135
              ],
              [
                -73.84877296803536,
                40.572056309009476
              ],
              [
                -73.84862868351767,
                40.57209635461399
              ],
              [
                -73.84850444718795,
                40.57214844758093
              ],
              [
                -73.84776924944421,
                40.572405332877956
              ],
              [
                -73.84699487325773,
                40.572719128136086
              ],
              [
                -73.84622515923755,
                40.57300882173354
              ],
              [
                -73.84615755209353,
                40.573034265606616
              ],
              [
                -73.84526900267889,
                40.57332475203252
              ],
              [
                -73.84451460324345,
                40.57361053577377
              ],
              [
                -73.84378359075176,
                40.57388745204175
              ],
              [
                -73.84288906671075,
                40.574197168991205
              ],
              [
                -73.84206938902219,
                40.57448096166989
              ],
              [
                -73.84138297830171,
                40.574718608414166
              ],
              [
                -73.84043821431337,
                40.57503351795107
              ],
              [
                -73.83961762770561,
                40.5753070268271
              ],
              [
                -73.83888275267505,
                40.57555196026983
              ],
              [
                -73.8386147788839,
                40.575636260900176
              ],
              [
                -73.83843049074804,
                40.575700534010586
              ],
              [
                -73.83825877960838,
                40.575794576486345
              ],
              [
                -73.83800935914823,
                40.57584870792821
              ],
              [
                -73.8377849130498,
                40.5759236834754
              ],
              [
                -73.8376569816259,
                40.57601237581605
              ],
              [
                -73.83710829476571,
                40.57625273333054
              ],
              [
                -73.83703852680397,
                40.57628429349712
              ],
              [
                -73.83641045643289,
                40.57656841076556
              ],
              [
                -73.83632284878783,
                40.576611088002586
              ],
              [
                -73.8362312572482,
                40.57665570551992
              ],
              [
                -73.83600181324205,
                40.576743908161994
              ],
              [
                -73.83592239041295,
                40.57676697468214
              ],
              [
                -73.83575900817624,
                40.57681443276687
              ],
              [
                -73.83542129219823,
                40.576962041781314
              ],
              [
                -73.83506278580667,
                40.57711626452329
              ],
              [
                -73.83465388054621,
                40.57728495788833
              ],
              [
                -73.8345508754213,
                40.57732545851957
              ],
              [
                -73.83450274193689,
                40.57734903736139
              ],
              [
                -73.8340874013796,
                40.57755249778741
              ],
              [
                -73.83386923172188,
                40.5776498976089
              ],
              [
                -73.8336543297874,
                40.577715514812624
              ],
              [
                -73.83338855905824,
                40.577785947378075
              ],
              [
                -73.83304274011873,
                40.577903720195806
              ],
              [
                -73.83292788645088,
                40.57794283360204
              ],
              [
                -73.83269598986004,
                40.57802571437782
              ],
              [
                -73.8322688248142,
                40.57812917409044
              ],
              [
                -73.83219724462421,
                40.578153612508984
              ],
              [
                -73.83203538969488,
                40.5782088710694
              ],
              [
                -73.83180355871933,
                40.578278935116955
              ],
              [
                -73.83162601375413,
                40.578366566537156
              ],
              [
                -73.83146093331435,
                40.57841943762274
              ],
              [
                -73.83124796291203,
                40.57846166566752
              ],
              [
                -73.83106502235128,
                40.578473121547155
              ],
              [
                -73.83090077277144,
                40.57849590721292
              ],
              [
                -73.83066083402844,
                40.5785679107544
              ],
              [
                -73.83048178791245,
                40.57865015244701
              ],
              [
                -73.83032282201474,
                40.57870371594956
              ],
              [
                -73.8301726281433,
                40.57874867245687
              ],
              [
                -73.83002699980847,
                40.578768359130734
              ],
              [
                -73.82990284920685,
                40.57877585645819
              ],
              [
                -73.82973373970096,
                40.578768249890736
              ],
              [
                -73.82967941933,
                40.578769546132996
              ],
              [
                -73.82961974131514,
                40.57877358256276
              ],
              [
                -73.82956637549206,
                40.578780356045904
              ],
              [
                -73.82946175273133,
                40.57880456046041
              ],
              [
                -73.8292744991744,
                40.57886524292074
              ],
              [
                -73.8291106634538,
                40.5789260059822
              ],
              [
                -73.82878289896757,
                40.579038479966385
              ],
              [
                -73.82868597099545,
                40.57905585942421
              ],
              [
                -73.82837544428158,
                40.579139550197006
              ],
              [
                -73.82798819062813,
                40.57924391773673
              ],
              [
                -73.82755616404526,
                40.57933987898739
              ],
              [
                -73.82717378884985,
                40.57944451295523
              ],
              [
                -73.82687139012124,
                40.57959730905056
              ],
              [
                -73.82670299479278,
                40.579659794905
              ],
              [
                -73.82622485462862,
                40.579787027917014
              ],
              [
                -73.82562469406477,
                40.57996870979248
              ],
              [
                -73.82516976808387,
                40.58006973278708
              ],
              [
                -73.82495905834286,
                40.58013813920259
              ],
              [
                -73.82473701900733,
                40.58025723770769
              ],
              [
                -73.8245996868574,
                40.58030150058319
              ],
              [
                -73.82436118230778,
                40.58037836962267
              ],
              [
                -73.82419170047795,
                40.58042525361688
              ],
              [
                -73.82397556699816,
                40.58048076353874
              ],
              [
                -73.82372487494457,
                40.580571314175785
              ],
              [
                -73.82321130220612,
                40.58074496142335
              ],
              [
                -73.82320233589388,
                40.58074798778894
              ],
              [
                -73.82264897973464,
                40.58093751426907
              ],
              [
                -73.82242185945246,
                40.581041805400375
              ],
              [
                -73.82228991494195,
                40.581096073193926
              ],
              [
                -73.82222751243998,
                40.581115032161954
              ],
              [
                -73.82214136703563,
                40.5811355755287
              ],
              [
                -73.82194916338798,
                40.5811711179728
              ],
              [
                -73.82134165094914,
                40.581262127228094
              ],
              [
                -73.82050198699154,
                40.58145867602859
              ],
              [
                -73.82048260084306,
                40.58146454207297
              ],
              [
                -73.8203660881055,
                40.581498083891034
              ],
              [
                -73.81964430603263,
                40.58168608220069
              ],
              [
                -73.81940325144654,
                40.58173798797365
              ],
              [
                -73.81928812015272,
                40.58176697417712
              ],
              [
                -73.81917967481245,
                40.581804148232266
              ],
              [
                -73.81895632282897,
                40.58190067592644
              ],
              [
                -73.81886971738469,
                40.581932715311716
              ],
              [
                -73.81838189820859,
                40.58207656004311
              ],
              [
                -73.81820861021012,
                40.58211739081631
              ],
              [
                -73.8176010354653,
                40.58226054391583
              ],
              [
                -73.81730795916116,
                40.58233475897001
              ],
              [
                -73.81751059199993,
                40.58278527099993
              ],
              [
                -73.81670273099988,
                40.5830153519999
              ],
              [
                -73.81567618499987,
                40.58330770499988
              ],
              [
                -73.81544517899997,
                40.58337349199993
              ],
              [
                -73.81550882099985,
                40.583475793999924
              ],
              [
                -73.81563939099986,
                40.583722207999855
              ],
              [
                -73.81567316399989,
                40.58377646399993
              ],
              [
                -73.81570076199996,
                40.58382964799994
              ],
              [
                -73.81576138799994,
                40.58395921499993
              ],
              [
                -73.81637844599986,
                40.584911004999896
              ],
              [
                -73.81693289499991,
                40.58582331099997
              ],
              [
                -73.81733678199987,
                40.58648319299993
              ],
              [
                -73.81739092999987,
                40.58656943199989
              ],
              [
                -73.81771610099989,
                40.587150957999924
              ],
              [
                -73.81788322299984,
                40.587448468999916
              ],
              [
                -73.81800188699995,
                40.587401720999935
              ],
              [
                -73.81812861099992,
                40.58737005199989
              ],
              [
                -73.81829590099994,
                40.58735573699989
              ],
              [
                -73.81849901800001,
                40.5873623529999
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000106175396322,
        "objectid": 202,
        "shape_leng": 0.0699215712618,
        "location_id": 202,
        "zone": "Roosevelt Island",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.94180032729437,
                40.76904692662474
              ],
              [
                -73.94125975382602,
                40.769308879392725
              ],
              [
                -73.94121934173008,
                40.76933434312226
              ],
              [
                -73.9411177871802,
                40.76941440857463
              ],
              [
                -73.94082605300244,
                40.76991215726811
              ],
              [
                -73.94035533477506,
                40.77049657437108
              ],
              [
                -73.94027985829325,
                40.77067071100978
              ],
              [
                -73.94022870389077,
                40.77085226166444
              ],
              [
                -73.94020021779653,
                40.77112830566273
              ],
              [
                -73.9402776672235,
                40.77171314173954
              ],
              [
                -73.94028207924202,
                40.77230140699121
              ],
              [
                -73.94018451224508,
                40.772564906804156
              ],
              [
                -73.9400115727917,
                40.77284236077629
              ],
              [
                -73.94007665725826,
                40.77292618617896
              ],
              [
                -73.94017959802048,
                40.772959315797124
              ],
              [
                -73.94027460960578,
                40.77294743744903
              ],
              [
                -73.94056957410288,
                40.77261475898203
              ],
              [
                -73.94088707205813,
                40.772372515888044
              ],
              [
                -73.94136990721195,
                40.77230915091386
              ],
              [
                -73.94212674623404,
                40.77203470075466
              ],
              [
                -73.94257830300492,
                40.7717018568592
              ],
              [
                -73.94281710980138,
                40.77141086085113
              ],
              [
                -73.94320414157106,
                40.77099239987432
              ],
              [
                -73.94400423978152,
                40.77029398207532
              ],
              [
                -73.9442700703175,
                40.77014053537861
              ],
              [
                -73.94456161508772,
                40.769886395963674
              ],
              [
                -73.94467293291667,
                40.76981996802662
              ],
              [
                -73.94472478498723,
                40.76978627176734
              ],
              [
                -73.94472353210327,
                40.76973405894194
              ],
              [
                -73.9450906579704,
                40.76921372115898
              ],
              [
                -73.94515704210755,
                40.76911831787578
              ],
              [
                -73.94524510320667,
                40.76877326815515
              ],
              [
                -73.94538208189473,
                40.76876320302393
              ],
              [
                -73.94540491875006,
                40.768732173019295
              ],
              [
                -73.94556302024347,
                40.76880177601348
              ],
              [
                -73.94562721096723,
                40.7686929530894
              ],
              [
                -73.94548114294636,
                40.76862477921884
              ],
              [
                -73.94559658517925,
                40.768470865002506
              ],
              [
                -73.94570357284314,
                40.76832793415568
              ],
              [
                -73.94575361316765,
                40.76830167121359
              ],
              [
                -73.94584451411889,
                40.76832122812566
              ],
              [
                -73.94585112316524,
                40.76824775011775
              ],
              [
                -73.94591458852791,
                40.76821393900933
              ],
              [
                -73.94655273946525,
                40.767368205283255
              ],
              [
                -73.94770109006382,
                40.76602239716259
              ],
              [
                -73.94881315308189,
                40.7646107542343
              ],
              [
                -73.94908628795766,
                40.76426402713744
              ],
              [
                -73.94920376011383,
                40.76426964391638
              ],
              [
                -73.94997191725122,
                40.76364603015847
              ],
              [
                -73.95037548087188,
                40.76317384529021
              ],
              [
                -73.95073068791342,
                40.76275822521683
              ],
              [
                -73.95093734914026,
                40.762570495118865
              ],
              [
                -73.95097191647194,
                40.76249450775369
              ],
              [
                -73.95162504986979,
                40.76167886380286
              ],
              [
                -73.95187667349164,
                40.761245582899015
              ],
              [
                -73.9520863228326,
                40.76086215420241
              ],
              [
                -73.95231257072545,
                40.76063375519731
              ],
              [
                -73.95297788208018,
                40.75996210793836
              ],
              [
                -73.95312451552458,
                40.76005275821471
              ],
              [
                -73.9533914071698,
                40.7597967621788
              ],
              [
                -73.95332321608947,
                40.75974596434368
              ],
              [
                -73.95365747474159,
                40.75949610137514
              ],
              [
                -73.9549940600116,
                40.75792901932858
              ],
              [
                -73.95544589007469,
                40.757399251221365
              ],
              [
                -73.95546455390387,
                40.75737736810592
              ],
              [
                -73.9555042551224,
                40.75733345603629
              ],
              [
                -73.95553464388513,
                40.75729603423413
              ],
              [
                -73.95556724783259,
                40.75725766684307
              ],
              [
                -73.95559286286709,
                40.75722280008675
              ],
              [
                -73.95565513182049,
                40.75709510586294
              ],
              [
                -73.95598563455,
                40.756724225403765
              ],
              [
                -73.95646348786491,
                40.756187982772815
              ],
              [
                -73.9568947698001,
                40.755703995161895
              ],
              [
                -73.95768072880023,
                40.75482196108277
              ],
              [
                -73.95845836852823,
                40.7539492367524
              ],
              [
                -73.95892671110458,
                40.75356249218072
              ],
              [
                -73.95901616663733,
                40.75359505947865
              ],
              [
                -73.95949352891616,
                40.752972403252826
              ],
              [
                -73.96001132397946,
                40.75183151716754
              ],
              [
                -73.96026766186368,
                40.75158071381146
              ],
              [
                -73.9607094085506,
                40.75114849812205
              ],
              [
                -73.96111060170932,
                40.750600469450376
              ],
              [
                -73.96132003404371,
                40.750074671967425
              ],
              [
                -73.96155080052249,
                40.74977388985281
              ],
              [
                -73.9616079947122,
                40.74956431675508
              ],
              [
                -73.96158304969282,
                40.74941430105318
              ],
              [
                -73.9614247793652,
                40.749401291404006
              ],
              [
                -73.96117372960865,
                40.74949844307386
              ],
              [
                -73.96104019266978,
                40.749670844523514
              ],
              [
                -73.96082903392376,
                40.749910550705266
              ],
              [
                -73.9603917057254,
                40.750256186117326
              ],
              [
                -73.95990187081871,
                40.750558515353546
              ],
              [
                -73.95955875930795,
                40.75081459776195
              ],
              [
                -73.95941235107571,
                40.750947663845146
              ],
              [
                -73.95902757442488,
                40.751297369850946
              ],
              [
                -73.95903975987336,
                40.7513924851116
              ],
              [
                -73.95860755756419,
                40.75161332398696
              ],
              [
                -73.95791452493181,
                40.752109854525315
              ],
              [
                -73.95721003531298,
                40.75271535683171
              ],
              [
                -73.95692404304639,
                40.75296115989125
              ],
              [
                -73.95579415237704,
                40.75416154740866
              ],
              [
                -73.95519503183546,
                40.75477452347619
              ],
              [
                -73.95507326928144,
                40.75479868255479
              ],
              [
                -73.95409527736291,
                40.75565492894537
              ],
              [
                -73.95340583051967,
                40.75625853187726
              ],
              [
                -73.95334556462886,
                40.75631137566744
              ],
              [
                -73.95330696035853,
                40.7563443271275
              ],
              [
                -73.95326888611,
                40.756381044103335
              ],
              [
                -73.95322009963138,
                40.756426842320344
              ],
              [
                -73.95312156546179,
                40.7565238048271
              ],
              [
                -73.95254079336881,
                40.75709531453726
              ],
              [
                -73.9522114634206,
                40.75758363983295
              ],
              [
                -73.95217663423693,
                40.757831336440056
              ],
              [
                -73.9520923302263,
                40.75799314034483
              ],
              [
                -73.95177822026001,
                40.758402549876976
              ],
              [
                -73.9517119041103,
                40.75846249420143
              ],
              [
                -73.95148626283033,
                40.75866645585446
              ],
              [
                -73.95126005908766,
                40.758810116371734
              ],
              [
                -73.95033358814476,
                40.7598567555536
              ],
              [
                -73.94971226700027,
                40.760328917384776
              ],
              [
                -73.94943175497114,
                40.76054861736116
              ],
              [
                -73.94896770106449,
                40.76109023662192
              ],
              [
                -73.94833197228184,
                40.76183755882174
              ],
              [
                -73.94832459525026,
                40.76184623000118
              ],
              [
                -73.9479297966422,
                40.76231031625978
              ],
              [
                -73.94788268389203,
                40.76236569759203
              ],
              [
                -73.94708876929518,
                40.763298921086715
              ],
              [
                -73.94682460228385,
                40.76361763323449
              ],
              [
                -73.94662141408685,
                40.763862772080905
              ],
              [
                -73.94623787959823,
                40.76428501454969
              ],
              [
                -73.9448402665221,
                40.76582362839085
              ],
              [
                -73.94422343089231,
                40.76666497788985
              ],
              [
                -73.94381427322762,
                40.76722091204987
              ],
              [
                -73.94321923520103,
                40.76789264385325
              ],
              [
                -73.94285528620689,
                40.76830349440931
              ],
              [
                -73.9425680614214,
                40.76850590573147
              ],
              [
                -73.94180032729437,
                40.76904692662474
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000766216513505,
        "objectid": 205,
        "shape_leng": 0.141953388327,
        "location_id": 205,
        "zone": "Saint Albans",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.76636925199999,
                40.70256339699992
              ],
              [
                -73.766572825,
                40.70275162099996
              ],
              [
                -73.76665413499987,
                40.70333761699992
              ],
              [
                -73.7667915569999,
                40.704008526999885
              ],
              [
                -73.76701441799999,
                40.705268130999904
              ],
              [
                -73.767052985,
                40.705470301999895
              ],
              [
                -73.76725075899984,
                40.70631255799994
              ],
              [
                -73.76734431899995,
                40.70663634599993
              ],
              [
                -73.7675558879999,
                40.707321957999895
              ],
              [
                -73.767762698,
                40.70799573499992
              ],
              [
                -73.76789085799992,
                40.70840640299988
              ],
              [
                -73.76825969099991,
                40.70952636699996
              ],
              [
                -73.76844546499984,
                40.709637026999886
              ],
              [
                -73.76918606499989,
                40.70933634199989
              ],
              [
                -73.77230141699995,
                40.70807142299989
              ],
              [
                -73.77265189,
                40.707992923999875
              ],
              [
                -73.77278262899993,
                40.707957893999925
              ],
              [
                -73.772997901,
                40.70790021499987
              ],
              [
                -73.77333799499985,
                40.70779351599987
              ],
              [
                -73.77367069099986,
                40.70767319499986
              ],
              [
                -73.77373764399991,
                40.707645606999904
              ],
              [
                -73.77399462499987,
                40.707539717999914
              ],
              [
                -73.77430843599988,
                40.707393667999945
              ],
              [
                -73.77461091499995,
                40.70723572499986
              ],
              [
                -73.7749009329999,
                40.707066661999875
              ],
              [
                -73.775051581,
                40.70692403599989
              ],
              [
                -73.77518799499988,
                40.706770116999955
              ],
              [
                -73.77530808899986,
                40.70660617899991
              ],
              [
                -73.77541010599997,
                40.70643377599989
              ],
              [
                -73.77549258399985,
                40.70625467199991
              ],
              [
                -73.77555451099992,
                40.706070835999924
              ],
              [
                -73.77559530099992,
                40.705884318999956
              ],
              [
                -73.77561486299999,
                40.705697221999834
              ],
              [
                -73.77561355999983,
                40.70551157799985
              ],
              [
                -73.77559218799982,
                40.70532930899987
              ],
              [
                -73.77507462299982,
                40.70523998399989
              ],
              [
                -73.7743477909998,
                40.7039863969999
              ],
              [
                -73.77394353599978,
                40.7033115039999
              ],
              [
                -73.7735339129999,
                40.70262042799986
              ],
              [
                -73.7733174479998,
                40.702261695999916
              ],
              [
                -73.77312771699984,
                40.70194727699995
              ],
              [
                -73.77272432599987,
                40.70126221599989
              ],
              [
                -73.77230768699997,
                40.70057150699989
              ],
              [
                -73.77274622199978,
                40.70037864699993
              ],
              [
                -73.77293506599993,
                40.70029491999985
              ],
              [
                -73.77318719099986,
                40.70018646399989
              ],
              [
                -73.7734383289999,
                40.7000753219999
              ],
              [
                -73.77359394899996,
                40.69999438299989
              ],
              [
                -73.77395659499993,
                40.6998057809999
              ],
              [
                -73.77446186199991,
                40.69899514599994
              ],
              [
                -73.7745937749998,
                40.69879949799987
              ],
              [
                -73.774892245,
                40.698387447999906
              ],
              [
                -73.77513633799991,
                40.69805046599988
              ],
              [
                -73.77532842099994,
                40.69778566699989
              ],
              [
                -73.77567626099993,
                40.69730502899993
              ],
              [
                -73.7757628779999,
                40.697182862999924
              ],
              [
                -73.77608749199995,
                40.6967252309999
              ],
              [
                -73.77618932299991,
                40.69657434099989
              ],
              [
                -73.77659775499988,
                40.69596911399988
              ],
              [
                -73.77703882099993,
                40.69536433699991
              ],
              [
                -73.77725734299997,
                40.695064041999935
              ],
              [
                -73.77783593399984,
                40.69432133499986
              ],
              [
                -73.77863399799988,
                40.69401378799992
              ],
              [
                -73.77944081299977,
                40.69364510899988
              ],
              [
                -73.78018646099991,
                40.69301522899988
              ],
              [
                -73.7802372379999,
                40.69297233499993
              ],
              [
                -73.779198385,
                40.691650567999915
              ],
              [
                -73.77789337399993,
                40.68991768999992
              ],
              [
                -73.77796055199985,
                40.689894809999956
              ],
              [
                -73.77891249599985,
                40.689570646999925
              ],
              [
                -73.77977295099997,
                40.68928116999993
              ],
              [
                -73.78078155499992,
                40.69102994299994
              ],
              [
                -73.78160296599982,
                40.690686903999904
              ],
              [
                -73.7824271229998,
                40.69034436899994
              ],
              [
                -73.78326624999988,
                40.68999429299992
              ],
              [
                -73.78234452799995,
                40.68840743799994
              ],
              [
                -73.78148675699995,
                40.6886971829999
              ],
              [
                -73.78051569899993,
                40.687036520999875
              ],
              [
                -73.77976509199982,
                40.685743602999935
              ],
              [
                -73.77966850599998,
                40.68537712299996
              ],
              [
                -73.77957347199998,
                40.68541503299992
              ],
              [
                -73.77870794599994,
                40.685700419999876
              ],
              [
                -73.77784971099992,
                40.68598927299988
              ],
              [
                -73.77699767599997,
                40.6862826889999
              ],
              [
                -73.77697494099996,
                40.686246713999935
              ],
              [
                -73.7767991239999,
                40.68596846499985
              ],
              [
                -73.77632402199986,
                40.6852165559999
              ],
              [
                -73.77567289899983,
                40.68474158899993
              ],
              [
                -73.775012315,
                40.6842755789999
              ],
              [
                -73.77462061100005,
                40.683990969999854
              ],
              [
                -73.7745579039998,
                40.683885621999906
              ],
              [
                -73.77441975399975,
                40.683653521999915
              ],
              [
                -73.77418414899984,
                40.68323792099991
              ],
              [
                -73.773499153,
                40.68348222699996
              ],
              [
                -73.7732870399999,
                40.683454731999866
              ],
              [
                -73.77297749199987,
                40.68293379299992
              ],
              [
                -73.77256209599992,
                40.682209496999846
              ],
              [
                -73.77220275299987,
                40.68161033899991
              ],
              [
                -73.77232211299984,
                40.681490967999935
              ],
              [
                -73.77299539399996,
                40.6812088249999
              ],
              [
                -73.77356539499996,
                40.68096392599992
              ],
              [
                -73.77408130199987,
                40.68076015399987
              ],
              [
                -73.77469730199986,
                40.680486045999906
              ],
              [
                -73.77346815399997,
                40.67971949999987
              ],
              [
                -73.77177843899987,
                40.678668299999906
              ],
              [
                -73.77109570599985,
                40.67824358299989
              ],
              [
                -73.77030295199994,
                40.67775050099991
              ],
              [
                -73.76932752799988,
                40.67714378899992
              ],
              [
                -73.76837330699989,
                40.67655024099987
              ],
              [
                -73.76561573099998,
                40.67477901999992
              ],
              [
                -73.76380484799996,
                40.67376993299995
              ],
              [
                -73.76371825199992,
                40.67431589199987
              ],
              [
                -73.76367677599995,
                40.67474552499992
              ],
              [
                -73.76360070799988,
                40.67474980899989
              ],
              [
                -73.76352325899985,
                40.67473995099995
              ],
              [
                -73.76345120599991,
                40.674716182999866
              ],
              [
                -73.76339040599994,
                40.674681117999924
              ],
              [
                -73.76248600399997,
                40.67419498199989
              ],
              [
                -73.76114911299982,
                40.67476361999996
              ],
              [
                -73.76031463999993,
                40.675109970999856
              ],
              [
                -73.75950918099984,
                40.67544720999995
              ],
              [
                -73.75801266799994,
                40.676077675999856
              ],
              [
                -73.75789961600002,
                40.676123409999896
              ],
              [
                -73.756918817,
                40.67652887099994
              ],
              [
                -73.75687349699994,
                40.67641731799989
              ],
              [
                -73.75680933199992,
                40.67630971499993
              ],
              [
                -73.756727424,
                40.67620876499993
              ],
              [
                -73.75662976499991,
                40.676116912999916
              ],
              [
                -73.75648885799987,
                40.67602276199989
              ],
              [
                -73.75576169199995,
                40.67588590899988
              ],
              [
                -73.75563646999986,
                40.676215178999925
              ],
              [
                -73.75562236499995,
                40.676258472999926
              ],
              [
                -73.75549392499991,
                40.67662967299991
              ],
              [
                -73.75523457799996,
                40.67728840999994
              ],
              [
                -73.75484909699989,
                40.67792883799995
              ],
              [
                -73.75417856999988,
                40.67884448599993
              ],
              [
                -73.7539939519999,
                40.67909450699991
              ],
              [
                -73.75372596199996,
                40.67945742799991
              ],
              [
                -73.75345604299982,
                40.67982295599991
              ],
              [
                -73.75328689999988,
                40.68005114499995
              ],
              [
                -73.75323817199994,
                40.680116885999915
              ],
              [
                -73.75321057900004,
                40.680154287999926
              ],
              [
                -73.75268290999999,
                40.68086961699991
              ],
              [
                -73.75247059899984,
                40.681180514999895
              ],
              [
                -73.75225828699998,
                40.6814914109999
              ],
              [
                -73.75180409200001,
                40.682403323999885
              ],
              [
                -73.751542155,
                40.683046041999944
              ],
              [
                -73.75125793499984,
                40.683734125999926
              ],
              [
                -73.75103020599988,
                40.684222866999924
              ],
              [
                -73.75025615899997,
                40.68561310999991
              ],
              [
                -73.75008991199991,
                40.685923445999904
              ],
              [
                -73.74944546799985,
                40.68667202199987
              ],
              [
                -73.74884601399998,
                40.68744748399996
              ],
              [
                -73.74856993199998,
                40.687803737999914
              ],
              [
                -73.7475517539999,
                40.689347335999855
              ],
              [
                -73.74728617699985,
                40.68973717299985
              ],
              [
                -73.74624345599987,
                40.69127944399992
              ],
              [
                -73.74510485099985,
                40.692782934999876
              ],
              [
                -73.74487951500002,
                40.69309307799993
              ],
              [
                -73.74484610600003,
                40.69313905699989
              ],
              [
                -73.74479652999989,
                40.69322037199988
              ],
              [
                -73.74467955599985,
                40.69341226299991
              ],
              [
                -73.7445271819999,
                40.693690598999865
              ],
              [
                -73.74438939,
                40.6939734339999
              ],
              [
                -73.74426648799988,
                40.694260100999934
              ],
              [
                -73.74415874099992,
                40.69454989999985
              ],
              [
                -73.74386913499988,
                40.695400117999945
              ],
              [
                -73.7435928609999,
                40.69621532499986
              ],
              [
                -73.74365178399992,
                40.696224156999904
              ],
              [
                -73.74458386999987,
                40.696363781999864
              ],
              [
                -73.74474545399995,
                40.696385464999885
              ],
              [
                -73.7449099489999,
                40.69639459999991
              ],
              [
                -73.74507479999993,
                40.69639094899986
              ],
              [
                -73.74523747599996,
                40.69637465899995
              ],
              [
                -73.74612956299988,
                40.6961357329999
              ],
              [
                -73.74618157999997,
                40.69612192599991
              ],
              [
                -73.74624875999989,
                40.69610314499993
              ],
              [
                -73.74744768999992,
                40.695770420999914
              ],
              [
                -73.74774501699983,
                40.695688540999946
              ],
              [
                -73.74829993899986,
                40.69553724399987
              ],
              [
                -73.74863325999986,
                40.69544476099992
              ],
              [
                -73.74928080799988,
                40.69689407699991
              ],
              [
                -73.7501929579999,
                40.69891208199989
              ],
              [
                -73.7493054709999,
                40.69914231299992
              ],
              [
                -73.7496211959998,
                40.699829779999945
              ],
              [
                -73.74991098899996,
                40.70047962799991
              ],
              [
                -73.75022047099984,
                40.701165996999904
              ],
              [
                -73.75053038699998,
                40.701854896999876
              ],
              [
                -73.75082756899988,
                40.70251465499992
              ],
              [
                -73.75113533300001,
                40.70320334299989
              ],
              [
                -73.75143698199983,
                40.7038643239999
              ],
              [
                -73.75174489199993,
                40.704546154999925
              ],
              [
                -73.75205066499986,
                40.70522802799995
              ],
              [
                -73.752937772,
                40.70499820599988
              ],
              [
                -73.75382395799984,
                40.70476583499989
              ],
              [
                -73.75471127099998,
                40.704537890999895
              ],
              [
                -73.75561453899982,
                40.70430046399987
              ],
              [
                -73.75651887399991,
                40.70406425799995
              ],
              [
                -73.75740580399986,
                40.70383535499994
              ],
              [
                -73.75829554399995,
                40.70360338099991
              ],
              [
                -73.75918228200003,
                40.70337203399988
              ],
              [
                -73.76006675299989,
                40.70314071099992
              ],
              [
                -73.76095546600004,
                40.70290957799994
              ],
              [
                -73.76126359299997,
                40.70359893999992
              ],
              [
                -73.76262946699983,
                40.70324155099993
              ],
              [
                -73.76278023599987,
                40.70320597499995
              ],
              [
                -73.76366846199983,
                40.70297297499996
              ],
              [
                -73.76455359299997,
                40.702742996999866
              ],
              [
                -73.76544147799987,
                40.7025123149999
              ],
              [
                -73.76612489399999,
                40.702334485999906
              ],
              [
                -73.76636925199999,
                40.70256339699992
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000944392507762,
        "objectid": 206,
        "shape_leng": 0.212756793386,
        "location_id": 206,
        "zone": "Saint George/New Brighton",
        "borough": "Staten Island"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -74.08221272914942,
                40.648280162290085
              ],
              [
                -74.08256585807113,
                40.64831581935348
              ],
              [
                -74.0839032166416,
                40.6485816691905
              ],
              [
                -74.08402179337246,
                40.64860435489161
              ],
              [
                -74.08413809812035,
                40.64863306432455
              ],
              [
                -74.08425160405804,
                40.6486676674447
              ],
              [
                -74.08436179703365,
                40.64870800751036
              ],
              [
                -74.08446817789951,
                40.6487539017932
              ],
              [
                -74.08457026477313,
                40.6488051424052
              ],
              [
                -74.08463018353473,
                40.64883376738129
              ],
              [
                -74.08469394761234,
                40.64885708011113
              ],
              [
                -74.08476074851815,
                40.6488747850029
              ],
              [
                -74.08482973925649,
                40.64888665756863
              ],
              [
                -74.08484403215712,
                40.64889114733939
              ],
              [
                -74.08522698411666,
                40.64892555177345
              ],
              [
                -74.08570754378212,
                40.648880553370454
              ],
              [
                -74.08634467630301,
                40.64869960938666
              ],
              [
                -74.0876744268149,
                40.64823529361475
              ],
              [
                -74.08793804157592,
                40.64808009375914
              ],
              [
                -74.08824112474028,
                40.647901656486084
              ],
              [
                -74.08827444370826,
                40.64792065007823
              ],
              [
                -74.08836043489221,
                40.64790790532765
              ],
              [
                -74.08845521748377,
                40.647843471079106
              ],
              [
                -74.08940351561799,
                40.647305776658875
              ],
              [
                -74.08992334047646,
                40.64717613260691
              ],
              [
                -74.09096024080317,
                40.64681894305661
              ],
              [
                -74.0913569794201,
                40.64670072410049
              ],
              [
                -74.09300507296297,
                40.646076314816774
              ],
              [
                -74.09298828187653,
                40.6459727807525
              ],
              [
                -74.09303960268036,
                40.64596199782462
              ],
              [
                -74.09362656930101,
                40.64583865879389
              ],
              [
                -74.09468500921257,
                40.645555717734815
              ],
              [
                -74.09604555281575,
                40.64537274744267
              ],
              [
                -74.09849981625507,
                40.64537036195378
              ],
              [
                -74.09880066885808,
                40.64504703431087
              ],
              [
                -74.09971112877103,
                40.64505571085139
              ],
              [
                -74.10010007356692,
                40.64505941609462
              ],
              [
                -74.10060542005353,
                40.64513233331709
              ],
              [
                -74.10164266441156,
                40.645396548926215
              ],
              [
                -74.10163782873113,
                40.64540559839609
              ],
              [
                -74.10164420314467,
                40.64540783027231
              ],
              [
                -74.1015783397477,
                40.645514421316165
              ],
              [
                -74.10161828954732,
                40.64552653887154
              ],
              [
                -74.10162518955083,
                40.645509512888964
              ],
              [
                -74.10169906586987,
                40.64553648533393
              ],
              [
                -74.10170826781193,
                40.64551863173062
              ],
              [
                -74.10162941761631,
                40.645488958940284
              ],
              [
                -74.1016648034949,
                40.64541538334382
              ],
              [
                -74.10167855232588,
                40.64541576035738
              ],
              [
                -74.10168264441663,
                40.64540673298673
              ],
              [
                -74.10231679337399,
                40.64554260018013
              ],
              [
                -74.10307296677558,
                40.6456381494226
              ],
              [
                -74.10365048802035,
                40.645654813250864
              ],
              [
                -74.10369926142748,
                40.645636634996855
              ],
              [
                -74.10372839854041,
                40.64561609971926
              ],
              [
                -74.10376428818483,
                40.64560923117643
              ],
              [
                -74.10383609558916,
                40.645612585284475
              ],
              [
                -74.10391463250333,
                40.645614222131584
              ],
              [
                -74.10398644791051,
                40.64562270284431
              ],
              [
                -74.10402237217032,
                40.64563805214804
              ],
              [
                -74.1040550911333,
                40.64566616481553
              ],
              [
                -74.1041235630174,
                40.64574043220019
              ],
              [
                -74.10434058744977,
                40.64572116640805
              ],
              [
                -74.10439613555202,
                40.64566994107999
              ],
              [
                -74.10464577782204,
                40.64563047031578
              ],
              [
                -74.10472221599757,
                40.6455636573994
              ],
              [
                -74.1068034270611,
                40.645560195940895
              ],
              [
                -74.1077967414638,
                40.645561984289195
              ],
              [
                -74.1082362049677,
                40.645562773203665
              ],
              [
                -74.10869553743693,
                40.645574723348716
              ],
              [
                -74.10886489895957,
                40.64557912880391
              ],
              [
                -74.1095380858384,
                40.64549583253676
              ],
              [
                -74.1097393256449,
                40.645463728184794
              ],
              [
                -74.11101110999861,
                40.645053095282016
              ],
              [
                -74.11149571146441,
                40.64477816472749
              ],
              [
                -74.11167635063406,
                40.6446881827913
              ],
              [
                -74.11172529995703,
                40.644659361726134
              ],
              [
                -74.11176646650023,
                40.64470616034501
              ],
              [
                -74.111116336666,
                40.64504690232306
              ],
              [
                -74.11116129469252,
                40.64509642318122
              ],
              [
                -74.11190333685228,
                40.64470602724296
              ],
              [
                -74.11180799136828,
                40.6446106744768
              ],
              [
                -74.1123037090662,
                40.64431880043215
              ],
              [
                -74.11333023192623,
                40.64372349758636
              ],
              [
                -74.11354450863914,
                40.643954120067306
              ],
              [
                -74.11363867686998,
                40.64391300836717
              ],
              [
                -74.11369030102539,
                40.64392321192987
              ],
              [
                -74.11376653215497,
                40.643890663485635
              ],
              [
                -74.11381590334797,
                40.643895741340636
              ],
              [
                -74.11389010824342,
                40.64398966912674
              ],
              [
                -74.11385205211577,
                40.64404098058403
              ],
              [
                -74.11380493647306,
                40.64404273550791
              ],
              [
                -74.11379152893623,
                40.64407522183085
              ],
              [
                -74.1128296089354,
                40.64446242868865
              ],
              [
                -74.11288806510967,
                40.644532444637576
              ],
              [
                -74.11395094068477,
                40.64413659167517
              ],
              [
                -74.1140405314602,
                40.64404250211974
              ],
              [
                -74.11379666086005,
                40.64360951580788
              ],
              [
                -74.11429538722346,
                40.64327914734322
              ],
              [
                -74.11478076428318,
                40.64295761592899
              ],
              [
                -74.11480735067566,
                40.64297696808481
              ],
              [
                -74.11511714752054,
                40.64278499267786
              ],
              [
                -74.11535082182925,
                40.642470540158136
              ],
              [
                -74.11564823433872,
                40.64237468333449
              ],
              [
                -74.11614843005724,
                40.64333111259184
              ],
              [
                -74.11622691905411,
                40.64330710586202
              ],
              [
                -74.1157191704229,
                40.64235181921402
              ],
              [
                -74.11599693311375,
                40.64226229373324
              ],
              [
                -74.11614208635716,
                40.64221645415032
              ],
              [
                -74.1161572274712,
                40.642244901789745
              ],
              [
                -74.11621943792053,
                40.642354723252524
              ],
              [
                -74.11624629709844,
                40.64234725163884
              ],
              [
                -74.11667041382688,
                40.64318982489282
              ],
              [
                -74.1167534287466,
                40.643161826702304
              ],
              [
                -74.11630481365927,
                40.6422801988119
              ],
              [
                -74.11633166977425,
                40.6422708667768
              ],
              [
                -74.11630000082738,
                40.64220266510229
              ],
              [
                -74.11659975924091,
                40.64210998296402
              ],
              [
                -74.11660991949574,
                40.6421254323637
              ],
              [
                -74.11666607646228,
                40.64210676633395
              ],
              [
                -74.11673967258692,
                40.642281620264555
              ],
              [
                -74.11684258188978,
                40.64245458178295
              ],
              [
                -74.11695775345594,
                40.642653584774536
              ],
              [
                -74.11705580706132,
                40.642843300202514
              ],
              [
                -74.1171490052741,
                40.64304976865223
              ],
              [
                -74.1172247008255,
                40.64302735996259
              ],
              [
                -74.1170334812623,
                40.64264978574939
              ],
              [
                -74.11688396076235,
                40.64236707592776
              ],
              [
                -74.11673703407642,
                40.64205948561656
              ],
              [
                -74.11672187663888,
                40.64203175609662
              ],
              [
                -74.11713706403347,
                40.641898064631064
              ],
              [
                -74.11741912492029,
                40.64244841215652
              ],
              [
                -74.11750702523283,
                40.64242040843011
              ],
              [
                -74.11722122301936,
                40.64187096523021
              ],
              [
                -74.117134061225,
                40.64169616394918
              ],
              [
                -74.11711235699552,
                40.64165596713886
              ],
              [
                -74.1171709328468,
                40.64162427104175
              ],
              [
                -74.11728814480372,
                40.641594377204584
              ],
              [
                -74.1173028282626,
                40.64160924948545
              ],
              [
                -74.1173492642659,
                40.64162036721683
              ],
              [
                -74.11740303374847,
                40.64163333969023
              ],
              [
                -74.11745689604214,
                40.64164480177371
              ],
              [
                -74.11762841614808,
                40.64162158290135
              ],
              [
                -74.11773478385697,
                40.6417966985356
              ],
              [
                -74.11780150303653,
                40.641809469261645
              ],
              [
                -74.11785534854104,
                40.6418375794936
              ],
              [
                -74.11791728683136,
                40.64182587695216
              ],
              [
                -74.1180489115635,
                40.64215746101189
              ],
              [
                -74.11815635743852,
                40.64213129829872
              ],
              [
                -74.1180076709366,
                40.64180549753333
              ],
              [
                -74.11820831549385,
                40.64175023537593
              ],
              [
                -74.11815536520912,
                40.641593684261736
              ],
              [
                -74.11840185083544,
                40.64152980054284
              ],
              [
                -74.11842117592663,
                40.64157604952014
              ],
              [
                -74.11858364618543,
                40.64199687165027
              ],
              [
                -74.11864479718022,
                40.642039609106014
              ],
              [
                -74.11883624616948,
                40.64254186406039
              ],
              [
                -74.11896077402346,
                40.64250451599316
              ],
              [
                -74.1188994831258,
                40.64238361874216
              ],
              [
                -74.1188750592626,
                40.64238736597404
              ],
              [
                -74.1186517159865,
                40.64180884662435
              ],
              [
                -74.11868834100237,
                40.64179764355327
              ],
              [
                -74.11860486153472,
                40.64156325117877
              ],
              [
                -74.11861635236262,
                40.641530365028956
              ],
              [
                -74.11900348200304,
                40.64146461451004
              ],
              [
                -74.11940029532461,
                40.64237937853275
              ],
              [
                -74.11948087155078,
                40.642355101709775
              ],
              [
                -74.11909720493722,
                40.64147346652344
              ],
              [
                -74.11932302547099,
                40.64143919866271
              ],
              [
                -74.11942631292476,
                40.641423524687255
              ],
              [
                -74.11944988177683,
                40.64143464839656
              ],
              [
                -74.11946561296826,
                40.64147887417195
              ],
              [
                -74.1195298610575,
                40.64146991432193
              ],
              [
                -74.11985443942083,
                40.642235613904816
              ],
              [
                -74.1199569987576,
                40.64220945342907
              ],
              [
                -74.11962565609885,
                40.641442628676074
              ],
              [
                -74.11960716747862,
                40.64138030935416
              ],
              [
                -74.11997111213574,
                40.641314814562264
              ],
              [
                -74.11993480088911,
                40.64119760438392
              ],
              [
                -74.12016783688034,
                40.641138995988314
              ],
              [
                -74.12052811811581,
                40.64106278260177
              ],
              [
                -74.1205742902843,
                40.6410563284083
              ],
              [
                -74.12064634499572,
                40.64104409562102
              ],
              [
                -74.12071317594332,
                40.641045782095446
              ],
              [
                -74.12118039503834,
                40.64138344518971
              ],
              [
                -74.12121470277087,
                40.641396839993696
              ],
              [
                -74.12128074797168,
                40.64143249599354
              ],
              [
                -74.12131194814832,
                40.64145253116248
              ],
              [
                -74.12137332919552,
                40.64147647512165
              ],
              [
                -74.12138690694671,
                40.64148533252626
              ],
              [
                -74.12144702079314,
                40.64150237930765
              ],
              [
                -74.12172375309429,
                40.64142866422191
              ],
              [
                -74.12175764698422,
                40.64142342422716
              ],
              [
                -74.12180661091023,
                40.6414121590377
              ],
              [
                -74.1218414704778,
                40.641405894387034
              ],
              [
                -74.12190205880334,
                40.641401202125294
              ],
              [
                -74.12194129710181,
                40.64144190806065
              ],
              [
                -74.1219585008117,
                40.64144698304061
              ],
              [
                -74.12269555893829,
                40.641353026836214
              ],
              [
                -74.12272114424832,
                40.641347623773065
              ],
              [
                -74.12277438475277,
                40.64133737539299
              ],
              [
                -74.12279544107928,
                40.64132432597279
              ],
              [
                -74.12282049121923,
                40.64129907821787
              ],
              [
                -74.1228256540515,
                40.641293875757476
              ],
              [
                -74.12282672976266,
                40.641291594234964
              ],
              [
                -74.12285868322375,
                40.6412302431834
              ],
              [
                -74.12286625478826,
                40.641188283220224
              ],
              [
                -74.12285698406907,
                40.641138183935254
              ],
              [
                -74.12290581795914,
                40.64107287288533
              ],
              [
                -74.12290724899286,
                40.641019266308525
              ],
              [
                -74.12287959621975,
                40.640956367786856
              ],
              [
                -74.12279688379691,
                40.64090051990417
              ],
              [
                -74.12274476463523,
                40.64084347425283
              ],
              [
                -74.12257280489571,
                40.64062234004702
              ],
              [
                -74.12260363650799,
                40.64059707395136
              ],
              [
                -74.12265174080149,
                40.64062676581546
              ],
              [
                -74.12277976988646,
                40.64077054990097
              ],
              [
                -74.12285630577614,
                40.64082050817312
              ],
              [
                -74.12318343872492,
                40.640673189868664
              ],
              [
                -74.12340726999689,
                40.640588957305845
              ],
              [
                -74.12351439698959,
                40.64052216109505
              ],
              [
                -74.12354793753005,
                40.640490843191316
              ],
              [
                -74.12354039801825,
                40.64045103899064
              ],
              [
                -74.12354404655959,
                40.64040553481481
              ],
              [
                -74.12357759285729,
                40.640377060469554
              ],
              [
                -74.12363726012842,
                40.64034287119686
              ],
              [
                -74.12374918070343,
                40.640302937917255
              ],
              [
                -74.12384245180269,
                40.640271556074076
              ],
              [
                -74.1239506352287,
                40.6402287828927
              ],
              [
                -74.12415578422493,
                40.64013471682004
              ],
              [
                -74.12425872953952,
                40.640147087334014
              ],
              [
                -74.12438357096798,
                40.64016859594369
              ],
              [
                -74.12448065891867,
                40.64018271074546
              ],
              [
                -74.12452923299043,
                40.64020540789038
              ],
              [
                -74.1246151276807,
                40.64022237751125
              ],
              [
                -74.12469728890524,
                40.64023935103756
              ],
              [
                -74.12487769898753,
                40.64024024996559
              ],
              [
                -74.12520491374713,
                40.64019329877803
              ],
              [
                -74.12533094012922,
                40.64018202506979
              ],
              [
                -74.1254624989608,
                40.64015483952424
              ],
              [
                -74.12557833584698,
                40.64014732822179
              ],
              [
                -74.12569811616551,
                40.640155933093205
              ],
              [
                -74.12580812521344,
                40.64017984793915
              ],
              [
                -74.12593026603197,
                40.64021089814806
              ],
              [
                -74.1259954246122,
                40.640231242814394
              ],
              [
                -74.12606982881191,
                40.64025447334624
              ],
              [
                -74.12651529296663,
                40.640371752940354
              ],
              [
                -74.12705362202625,
                40.64024173781951
              ],
              [
                -74.12708156085438,
                40.6401610290877
              ],
              [
                -74.12708754066087,
                40.64004284927895
              ],
              [
                -74.12709776289094,
                40.639979811657014
              ],
              [
                -74.12712443004504,
                40.63986318388078
              ],
              [
                -74.12710456677688,
                40.63976646139726
              ],
              [
                -74.12710164386345,
                40.63971966695508
              ],
              [
                -74.12710264794042,
                40.63968075296103
              ],
              [
                -74.12709862022669,
                40.63962602221113
              ],
              [
                -74.1270762008027,
                40.63957569431476
              ],
              [
                -74.12706108014973,
                40.63951364215877
              ],
              [
                -74.12702714583776,
                40.639315784971714
              ],
              [
                -74.12702091299994,
                40.63927376299989
              ],
              [
                -74.12699049099994,
                40.63910816399989
              ],
              [
                -74.12698007099992,
                40.638609711999905
              ],
              [
                -74.12697697999995,
                40.638431721999886
              ],
              [
                -74.1269820749999,
                40.638396369999896
              ],
              [
                -74.1269897449999,
                40.63837057999993
              ],
              [
                -74.12700759399989,
                40.63833452499993
              ],
              [
                -74.12705476399996,
                40.638197599999934
              ],
              [
                -74.12707923599991,
                40.638122436999915
              ],
              [
                -74.1268887269999,
                40.63721008799995
              ],
              [
                -74.12784669199989,
                40.63727742999988
              ],
              [
                -74.12867047299994,
                40.63734826499993
              ],
              [
                -74.12872489299997,
                40.637088836999936
              ],
              [
                -74.12878738899998,
                40.636964953999936
              ],
              [
                -74.128831656,
                40.63683527499989
              ],
              [
                -74.12885649499997,
                40.636702185999916
              ],
              [
                -74.12886142599997,
                40.63656824599984
              ],
              [
                -74.12884672499999,
                40.636436003999975
              ],
              [
                -74.12885588799993,
                40.63557396099989
              ],
              [
                -74.128844165,
                40.63532458499988
              ],
              [
                -74.12885281899997,
                40.6350744189999
              ],
              [
                -74.128881913,
                40.634824897999934
              ],
              [
                -74.12893129399997,
                40.63457748699988
              ],
              [
                -74.12900054099997,
                40.634333593999955
              ],
              [
                -74.12944148699994,
                40.6336717209999
              ],
              [
                -74.1296229589999,
                40.63342476399985
              ],
              [
                -74.12978688499999,
                40.63317008399988
              ],
              [
                -74.129932476,
                40.632908786999934
              ],
              [
                -74.1300590599999,
                40.6326420269999
              ],
              [
                -74.13016617699994,
                40.6323710279999
              ],
              [
                -74.13046976899992,
                40.63167632099987
              ],
              [
                -74.13060220399994,
                40.63135782699985
              ],
              [
                -74.13047428999988,
                40.630885509999935
              ],
              [
                -74.13014141499997,
                40.629965208999906
              ],
              [
                -74.13024788999991,
                40.629609130999924
              ],
              [
                -74.13033008299996,
                40.629384339999916
              ],
              [
                -74.13056374599995,
                40.628707729999896
              ],
              [
                -74.13069219499997,
                40.628336901999866
              ],
              [
                -74.13099290699996,
                40.627374110999874
              ],
              [
                -74.1313098529999,
                40.62632834299997
              ],
              [
                -74.13069767299996,
                40.62634095699987
              ],
              [
                -74.13007946899988,
                40.62641859599995
              ],
              [
                -74.1292025009999,
                40.626540605999864
              ],
              [
                -74.12826227099993,
                40.62667350399991
              ],
              [
                -74.12666303799995,
                40.626889617999915
              ],
              [
                -74.12513287199992,
                40.627102600999876
              ],
              [
                -74.12424800199987,
                40.627211641999914
              ],
              [
                -74.12365042899994,
                40.627286876999904
              ],
              [
                -74.12270654899999,
                40.62740739599991
              ],
              [
                -74.12216225499999,
                40.627476891999876
              ],
              [
                -74.12125677899994,
                40.62782274999989
              ],
              [
                -74.12056862499992,
                40.626988699999906
              ],
              [
                -74.12012887999997,
                40.6265361529999
              ],
              [
                -74.11967435899994,
                40.62619142199989
              ],
              [
                -74.11916467799988,
                40.62582382799994
              ],
              [
                -74.11845939699997,
                40.62526898899994
              ],
              [
                -74.11828960499999,
                40.62512868299991
              ],
              [
                -74.11810485699996,
                40.62499772999993
              ],
              [
                -74.11790654899988,
                40.624877460999876
              ],
              [
                -74.11769631299995,
                40.62476906499989
              ],
              [
                -74.11747606599991,
                40.62467350199995
              ],
              [
                -74.11724781899987,
                40.62459150899988
              ],
              [
                -74.11701375799994,
                40.624523576999884
              ],
              [
                -74.11627412799992,
                40.62423942099988
              ],
              [
                -74.11541921799997,
                40.623910962999965
              ],
              [
                -74.11326249599989,
                40.62305442499996
              ],
              [
                -74.1135847819999,
                40.62369716899987
              ],
              [
                -74.11393395699994,
                40.6243302039999
              ],
              [
                -74.11407428699995,
                40.62458176999998
              ],
              [
                -74.1147144859999,
                40.625811685999935
              ],
              [
                -74.11473772799998,
                40.6258563459999
              ],
              [
                -74.11475499299996,
                40.62588928799992
              ],
              [
                -74.11503453399992,
                40.62642257599991
              ],
              [
                -74.11524316599996,
                40.62682656499992
              ],
              [
                -74.11526026599996,
                40.62685967799991
              ],
              [
                -74.115275528,
                40.626889055999875
              ],
              [
                -74.11544777599991,
                40.6272206169999
              ],
              [
                -74.11560933499997,
                40.62754898299993
              ],
              [
                -74.11588524999996,
                40.62808893699992
              ],
              [
                -74.11595162399986,
                40.628230133999864
              ],
              [
                -74.11614321199995,
                40.62870226399988
              ],
              [
                -74.11618948699994,
                40.62894119699992
              ],
              [
                -74.11634635499996,
                40.629835002999876
              ],
              [
                -74.11650496399994,
                40.63075571399985
              ],
              [
                -74.11666642599987,
                40.631710101999914
              ],
              [
                -74.11683619599992,
                40.63267481799988
              ],
              [
                -74.11695838499996,
                40.63335140799992
              ],
              [
                -74.11704470999996,
                40.63381660999992
              ],
              [
                -74.11707785799996,
                40.63400710799992
              ],
              [
                -74.1171994709999,
                40.634568097999924
              ],
              [
                -74.11738361299989,
                40.63539539899992
              ],
              [
                -74.11745986599989,
                40.63576940499993
              ],
              [
                -74.11746820599997,
                40.63588019599997
              ],
              [
                -74.11747417299995,
                40.635943517999884
              ],
              [
                -74.11747983199996,
                40.63602690599986
              ],
              [
                -74.117485764,
                40.63611340599992
              ],
              [
                -74.11749409699998,
                40.636224090999875
              ],
              [
                -74.1175003539999,
                40.63630719399989
              ],
              [
                -74.11751136899993,
                40.636441801999915
              ],
              [
                -74.11751731899994,
                40.6365325329999
              ],
              [
                -74.11752704599989,
                40.63666172399995
              ],
              [
                -74.11753352399988,
                40.63674776299993
              ],
              [
                -74.11754902299995,
                40.63695363199987
              ],
              [
                -74.11765722499996,
                40.6380624669999
              ],
              [
                -74.11709261399987,
                40.638126946999925
              ],
              [
                -74.11641377299995,
                40.63820248399986
              ],
              [
                -74.11588274199994,
                40.63826439099995
              ],
              [
                -74.11519147399999,
                40.63834768099987
              ],
              [
                -74.11499559699989,
                40.63729222599993
              ],
              [
                -74.1137364029999,
                40.63742912299987
              ],
              [
                -74.11279390199998,
                40.6375308099999
              ],
              [
                -74.11197916199993,
                40.63761817599988
              ],
              [
                -74.11174806399994,
                40.63764295499989
              ],
              [
                -74.11080857499996,
                40.637746199999874
              ],
              [
                -74.10974833299994,
                40.63786583299993
              ],
              [
                -74.10954135499992,
                40.637987429999896
              ],
              [
                -74.10938968899991,
                40.63806694599991
              ],
              [
                -74.10938961799994,
                40.63806698199992
              ],
              [
                -74.10938954799997,
                40.63806700899991
              ],
              [
                -74.109225354,
                40.63813328099991
              ],
              [
                -74.10922521199993,
                40.638133334999935
              ],
              [
                -74.10922508199995,
                40.638133370999896
              ],
              [
                -74.10905134699988,
                40.63818482099989
              ],
              [
                -74.10905120499997,
                40.63818486599991
              ],
              [
                -74.10905105199996,
                40.638184892999895
              ],
              [
                -74.10887129699995,
                40.63822050799991
              ],
              [
                -74.1088711309999,
                40.638220543999914
              ],
              [
                -74.10887095399988,
                40.6382205619999
              ],
              [
                -74.10868871399997,
                40.63823996099991
              ],
              [
                -74.10822759399989,
                40.638314039999926
              ],
              [
                -74.10746323199996,
                40.63843848799997
              ],
              [
                -74.1073365579999,
                40.63854326299991
              ],
              [
                -74.10729545999992,
                40.63858007999988
              ],
              [
                -74.10724963099997,
                40.638613498999874
              ],
              [
                -74.10719955199995,
                40.63864316999993
              ],
              [
                -74.10714574999999,
                40.63866877999992
              ],
              [
                -74.10708878899997,
                40.63869005999989
              ],
              [
                -74.10702926799993,
                40.63870678799988
              ],
              [
                -74.10696781199987,
                40.63871878699993
              ],
              [
                -74.10690506699991,
                40.63872593199991
              ],
              [
                -74.10542286599998,
                40.638898966999875
              ],
              [
                -74.10480990899997,
                40.638976700999876
              ],
              [
                -74.10393828599992,
                40.63919020399988
              ],
              [
                -74.10305261099988,
                40.639401245999856
              ],
              [
                -74.10216582799997,
                40.63961813399992
              ],
              [
                -74.10129653399997,
                40.63982715799995
              ],
              [
                -74.10042458199996,
                40.6400410299999
              ],
              [
                -74.09990409599992,
                40.640162954999944
              ],
              [
                -74.09928833799988,
                40.64020472299996
              ],
              [
                -74.09787969199995,
                40.64035805499989
              ],
              [
                -74.09767290799995,
                40.63896067899987
              ],
              [
                -74.09686242099997,
                40.639007470999914
              ],
              [
                -74.09637906199991,
                40.63903536999992
              ],
              [
                -74.09570924799992,
                40.6390986409999
              ],
              [
                -74.09409312599998,
                40.639266913999975
              ],
              [
                -74.09386276399991,
                40.63802491699991
              ],
              [
                -74.09362634099998,
                40.637283694999894
              ],
              [
                -74.09338942299995,
                40.63659955499988
              ],
              [
                -74.09242513499993,
                40.63558181299992
              ],
              [
                -74.09182025899999,
                40.63555529799987
              ],
              [
                -74.09090698799996,
                40.6354857289999
              ],
              [
                -74.09027738599993,
                40.63549902999994
              ],
              [
                -74.08970378299989,
                40.63568250099992
              ],
              [
                -74.08961180999991,
                40.63574147799994
              ],
              [
                -74.08952415699994,
                40.63580415699986
              ],
              [
                -74.08944108199995,
                40.635870355999884
              ],
              [
                -74.08936282699987,
                40.63593987999993
              ],
              [
                -74.089289621,
                40.636012524999956
              ],
              [
                -74.08922167799987,
                40.6360880799999
              ],
              [
                -74.08915919799995,
                40.63616632399988
              ],
              [
                -74.08910236299987,
                40.63624702699983
              ],
              [
                -74.08891735099989,
                40.636528383999874
              ],
              [
                -74.08870582299987,
                40.636845205999876
              ],
              [
                -74.08759775199998,
                40.636262023999926
              ],
              [
                -74.0869059279999,
                40.635857242999904
              ],
              [
                -74.08587882899995,
                40.63669158999993
              ],
              [
                -74.08568983299989,
                40.6366072799999
              ],
              [
                -74.08513463099995,
                40.63635959899995
              ],
              [
                -74.08373397299994,
                40.63573714399991
              ],
              [
                -74.08302091199991,
                40.63602507099989
              ],
              [
                -74.08177940499988,
                40.63662301399993
              ],
              [
                -74.08055168499997,
                40.63731999999989
              ],
              [
                -74.07878831099995,
                40.638268558999926
              ],
              [
                -74.078722026,
                40.63828966999992
              ],
              [
                -74.07865269699992,
                40.63830402499988
              ],
              [
                -74.07858150399997,
                40.638311379999905
              ],
              [
                -74.07850965999987,
                40.63831160899986
              ],
              [
                -74.07843838799988,
                40.63830470799997
              ],
              [
                -74.0783689039999,
                40.63829079499987
              ],
              [
                -74.07830238999996,
                40.63827010599988
              ],
              [
                -74.07823997999992,
                40.63824299499991
              ],
              [
                -74.07818273599995,
                40.63820992299987
              ],
              [
                -74.078131634,
                40.6381714529999
              ],
              [
                -74.0778990219999,
                40.63809465199992
              ],
              [
                -74.07698362199989,
                40.63779569399989
              ],
              [
                -74.0768399779999,
                40.6377502599999
              ],
              [
                -74.07601776799993,
                40.637748104999936
              ],
              [
                -74.07530550099996,
                40.637729959999895
              ],
              [
                -74.07513243599989,
                40.63772719999991
              ],
              [
                -74.07506052899998,
                40.637748399999936
              ],
              [
                -74.07496304100002,
                40.637773349999854
              ],
              [
                -74.07493115799993,
                40.63778151099995
              ],
              [
                -74.07486917499988,
                40.637792809999915
              ],
              [
                -74.07469451399994,
                40.637805179999916
              ],
              [
                -74.07373948799989,
                40.637861233999885
              ],
              [
                -74.07329742299997,
                40.6379035079999
              ],
              [
                -74.07258133886755,
                40.63794187429598
              ],
              [
                -74.0726063279754,
                40.63813630834696
              ],
              [
                -74.07237941129377,
                40.63816272607411
              ],
              [
                -74.0723885295029,
                40.63824526599672
              ],
              [
                -74.07260933867629,
                40.63822689532101
              ],
              [
                -74.07258173009109,
                40.638258614007
              ],
              [
                -74.07258809054142,
                40.63830189417055
              ],
              [
                -74.07261338131848,
                40.63833554523842
              ],
              [
                -74.07267024642057,
                40.6383763896267
              ],
              [
                -74.07272076458273,
                40.638385976507635
              ],
              [
                -74.07279336887404,
                40.63838593056913
              ],
              [
                -74.07287265506746,
                40.63837998786964
              ],
              [
                -74.07292278393903,
                40.63837622975343
              ],
              [
                -74.07293199871835,
                40.63841448843019
              ],
              [
                -74.07301444562252,
                40.6384093846214
              ],
              [
                -74.07301618029148,
                40.63842663393234
              ],
              [
                -74.07292601136344,
                40.63844115456002
              ],
              [
                -74.07292933384748,
                40.63859264983827
              ],
              [
                -74.07303265958848,
                40.638590610271926
              ],
              [
                -74.07309035578784,
                40.63861899968587
              ],
              [
                -74.07307725837738,
                40.64051331179637
              ],
              [
                -74.07277690011115,
                40.64052896314734
              ],
              [
                -74.07285041194942,
                40.64080368844509
              ],
              [
                -74.07183643460411,
                40.64090187382061
              ],
              [
                -74.0718364242488,
                40.640892255011934
              ],
              [
                -74.07182934715873,
                40.64089073365056
              ],
              [
                -74.07182203764496,
                40.64089012188678
              ],
              [
                -74.0718146957496,
                40.6408904364629
              ],
              [
                -74.07180752240184,
                40.6408916687699
              ],
              [
                -74.07180071391737,
                40.64089378508269
              ],
              [
                -74.071794456627,
                40.640896727483266
              ],
              [
                -74.07178892177699,
                40.64090041544563
              ],
              [
                -74.07178426084165,
                40.640904748039766
              ],
              [
                -74.0717806013794,
                40.640909606693626
              ],
              [
                -74.07177804354045,
                40.64091485843836
              ],
              [
                -74.07177665732668,
                40.640920359547096
              ],
              [
                -74.07177648067534,
                40.64092595946847
              ],
              [
                -74.06998354653952,
                40.64106894311676
              ],
              [
                -74.07001849148746,
                40.64127813223749
              ],
              [
                -74.07181141822922,
                40.641123124201876
              ],
              [
                -74.07180500367038,
                40.64102934375624
              ],
              [
                -74.07198176865172,
                40.641012401156196
              ],
              [
                -74.07196280961668,
                40.6409955797607
              ],
              [
                -74.07289005609624,
                40.640922672876044
              ],
              [
                -74.07308689819374,
                40.64163681496966
              ],
              [
                -74.07019399559213,
                40.642007502805306
              ],
              [
                -74.07024658698661,
                40.64220697429687
              ],
              [
                -74.07314400586021,
                40.64185297448013
              ],
              [
                -74.07323829183592,
                40.64218473608239
              ],
              [
                -74.07032923715386,
                40.64252612813239
              ],
              [
                -74.07037430990586,
                40.64269140305315
              ],
              [
                -74.07210952444638,
                40.642463216792784
              ],
              [
                -74.0721843496904,
                40.642714093289044
              ],
              [
                -74.07170351967257,
                40.64279633528001
              ],
              [
                -74.07170286121513,
                40.642679898060315
              ],
              [
                -74.07164892198989,
                40.64267993167963
              ],
              [
                -74.07164540901456,
                40.642800359680585
              ],
              [
                -74.07147777322605,
                40.64280829377494
              ],
              [
                -74.07150263147396,
                40.64312930419293
              ],
              [
                -74.07113105720924,
                40.643205263678276
              ],
              [
                -74.0711430928901,
                40.643250909742015
              ],
              [
                -74.07130787990505,
                40.64322113269777
              ],
              [
                -74.07130487290098,
                40.64321200375996
              ],
              [
                -74.07141659716521,
                40.64319310959575
              ],
              [
                -74.07144331706321,
                40.6432705613306
              ],
              [
                -74.07127283144267,
                40.643296361122516
              ],
              [
                -74.07125765981509,
                40.64325850172703
              ],
              [
                -74.07095942168601,
                40.643309727028274
              ],
              [
                -74.07096809463135,
                40.643334419890685
              ],
              [
                -74.07093784471476,
                40.643345963840645
              ],
              [
                -74.07095083519633,
                40.64336571396243
              ],
              [
                -74.07097244806427,
                40.643364053151245
              ],
              [
                -74.0709832823077,
                40.6433887440084
              ],
              [
                -74.07125775124943,
                40.643344119757025
              ],
              [
                -74.07125341439202,
                40.64333094968349
              ],
              [
                -74.0714820243262,
                40.64329262506356
              ],
              [
                -74.07150206405164,
                40.64335486600386
              ],
              [
                -74.07147284705017,
                40.64336027271819
              ],
              [
                -74.07149090063898,
                40.64342874058589
              ],
              [
                -74.0713410941792,
                40.643456225521575
              ],
              [
                -74.07135612176627,
                40.64349730455598
              ],
              [
                -74.07159679201153,
                40.643460157488626
              ],
              [
                -74.07162787633769,
                40.64357834116639
              ],
              [
                -74.07158891657957,
                40.643591315373655
              ],
              [
                -74.07164969408515,
                40.64378688572463
              ],
              [
                -74.07177075212515,
                40.6437720548353
              ],
              [
                -74.07179259243398,
                40.64381630857892
              ],
              [
                -74.0719062401355,
                40.64379710917852
              ],
              [
                -74.07200080155476,
                40.643781132969515
              ],
              [
                -74.0720581750603,
                40.64390042769858
              ],
              [
                -74.07194769578626,
                40.64393610218965
              ],
              [
                -74.07193800055079,
                40.64392688581522
              ],
              [
                -74.07186296798388,
                40.64395828969226
              ],
              [
                -74.0718702509905,
                40.64397488502974
              ],
              [
                -74.07175648087771,
                40.64401184549864
              ],
              [
                -74.0718608087088,
                40.64420176177014
              ],
              [
                -74.07196247757301,
                40.644170341733236
              ],
              [
                -74.07197701849,
                40.64418139905795
              ],
              [
                -74.07218488601673,
                40.644110465395165
              ],
              [
                -74.07229190281811,
                40.64426604782037
              ],
              [
                -74.07223218396409,
                40.6442882697936
              ],
              [
                -74.07209826186593,
                40.64433810424379
              ],
              [
                -74.07211281812974,
                40.64436391744447
              ],
              [
                -74.07201843213228,
                40.644411932940834
              ],
              [
                -74.07216148380604,
                40.64458153480653
              ],
              [
                -74.07225588436278,
                40.64454643110188
              ],
              [
                -74.07230919046846,
                40.644577754057316
              ],
              [
                -74.07168733413398,
                40.64501343947631
              ],
              [
                -74.0716824805112,
                40.64500421939304
              ],
              [
                -74.07165829759784,
                40.64503374643502
              ],
              [
                -74.07168737376726,
                40.64505032820053
              ],
              [
                -74.07171641184448,
                40.645031865721236
              ],
              [
                -74.07170671648227,
                40.64502264983108
              ],
              [
                -74.07233100894283,
                40.644599873185015
              ],
              [
                -74.07269828799015,
                40.64444127346731
              ],
              [
                -74.07278260612932,
                40.64449184001752
              ],
              [
                -74.07274586000851,
                40.64452403106857
              ],
              [
                -74.07279042215042,
                40.64455201831284
              ],
              [
                -74.07272467145069,
                40.644620883766066
              ],
              [
                -74.07258408702005,
                40.64454688046277
              ],
              [
                -74.07259920774122,
                40.644536992437715
              ],
              [
                -74.07258623043283,
                40.64453041424987
              ],
              [
                -74.07253222025724,
                40.64455679217023
              ],
              [
                -74.07254303492037,
                40.644563371225715
              ],
              [
                -74.07255383884463,
                40.64456007230925
              ],
              [
                -74.07266630771204,
                40.644620920659506
              ],
              [
                -74.07262311218182,
                40.6446538788378
              ],
              [
                -74.07261662439397,
                40.64465058915858
              ],
              [
                -74.07262742408484,
                40.64464399763452
              ],
              [
                -74.07257551719216,
                40.64461768538858
              ],
              [
                -74.07252587521035,
                40.64467004001712
              ],
              [
                -74.07257263940097,
                40.64469380340304
              ],
              [
                -74.072599349268,
                40.64466706492531
              ],
              [
                -74.07261232795736,
                40.64467529058511
              ],
              [
                -74.07256468232941,
                40.64471974885817
              ],
              [
                -74.07259617125487,
                40.64473547246686
              ],
              [
                -74.07269226553024,
                40.644637370287064
              ],
              [
                -74.07270308267607,
                40.64464559562754
              ],
              [
                -74.0725877555387,
                40.64479086317364
              ],
              [
                -74.0727985034149,
                40.64491012376874
              ],
              [
                -74.07287964133008,
                40.64484417943485
              ],
              [
                -74.07292585037442,
                40.64486407396003
              ],
              [
                -74.07312184183515,
                40.64471479946889
              ],
              [
                -74.07334517133606,
                40.64485025792902
              ],
              [
                -74.0732727290158,
                40.64491627997814
              ],
              [
                -74.07342590747642,
                40.64500056851528
              ],
              [
                -74.07341383827793,
                40.64501591847193
              ],
              [
                -74.07305559881726,
                40.644807917840964
              ],
              [
                -74.07301240029484,
                40.64483867970177
              ],
              [
                -74.07320225954692,
                40.64494394124359
              ],
              [
                -74.07302520507207,
                40.64513123832012
              ],
              [
                -74.07305744965362,
                40.64514502737443
              ],
              [
                -74.0732385381076,
                40.6449638639098
              ],
              [
                -74.07334938819302,
                40.64502209632997
              ],
              [
                -74.07333328763886,
                40.645034380734074
              ],
              [
                -74.07346429816413,
                40.645107943609474
              ],
              [
                -74.07369011806543,
                40.64530572505996
              ],
              [
                -74.07341838754051,
                40.64548847917834
              ],
              [
                -74.07348694129136,
                40.64554980774531
              ],
              [
                -74.07324539464845,
                40.645704925269534
              ],
              [
                -74.0733381385699,
                40.64578311616232
              ],
              [
                -74.07382143191991,
                40.64544348337722
              ],
              [
                -74.0738565368472,
                40.645424816099606
              ],
              [
                -74.07383419382343,
                40.64540738068856
              ],
              [
                -74.07377880342949,
                40.64535629846428
              ],
              [
                -74.07382911232168,
                40.645311772142726
              ],
              [
                -74.07380088322195,
                40.64528570709492
              ],
              [
                -74.07375861295428,
                40.64531335227553
              ],
              [
                -74.07349851367614,
                40.64508337347514
              ],
              [
                -74.07359107919265,
                40.64499892804304
              ],
              [
                -74.0738725805336,
                40.6451478163471
              ],
              [
                -74.07395839976465,
                40.64519320544552
              ],
              [
                -74.07407812091206,
                40.64508432891062
              ],
              [
                -74.07445282563698,
                40.64506748872323
              ],
              [
                -74.07530767022044,
                40.64591302206679
              ],
              [
                -74.07599222761549,
                40.646552023758986
              ],
              [
                -74.07620922502595,
                40.64672388272723
              ],
              [
                -74.07650657152854,
                40.64696881818336
              ],
              [
                -74.07682895680941,
                40.64708052243883
              ],
              [
                -74.07699341088886,
                40.647205851839075
              ],
              [
                -74.0770865800269,
                40.647199861267865
              ],
              [
                -74.07899546333263,
                40.64814255442242
              ],
              [
                -74.07923252724933,
                40.648186325488645
              ],
              [
                -74.07980996428698,
                40.64838331298795
              ],
              [
                -74.08051823788348,
                40.64824551473271
              ],
              [
                -74.08072838762372,
                40.64827487384626
              ],
              [
                -74.08095598247468,
                40.648348676459456
              ],
              [
                -74.08135603833722,
                40.64849094353099
              ],
              [
                -74.08142228203803,
                40.64850472594945
              ],
              [
                -74.08165246388559,
                40.64842426021783
              ],
              [
                -74.08186603545586,
                40.64837339135716
              ],
              [
                -74.08204629493247,
                40.6483077531151
              ],
              [
                -74.08212394387884,
                40.64827811218603
              ],
              [
                -74.08221272914942,
                40.648280162290085
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0000447230848441,
        "objectid": 207,
        "shape_leng": 0.0283756831492,
        "location_id": 207,
        "zone": "Saint Michaels Cemetery/Woodside",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.90137640299986,
                40.759642037999896
              ],
              [
                -73.90127448199993,
                40.7596514789999
              ],
              [
                -73.89901804799997,
                40.7598912129999
              ],
              [
                -73.89886718099987,
                40.75990420699985
              ],
              [
                -73.89877250799998,
                40.75991557699993
              ],
              [
                -73.89866137899992,
                40.75992101699996
              ],
              [
                -73.89825240299986,
                40.76051448699996
              ],
              [
                -73.89791145199983,
                40.76102034899989
              ],
              [
                -73.897895281,
                40.76104450599991
              ],
              [
                -73.89735543399995,
                40.7618509129999
              ],
              [
                -73.89723108699998,
                40.76202952499994
              ],
              [
                -73.89720062399992,
                40.76207328199991
              ],
              [
                -73.89700714599998,
                40.762351192999894
              ],
              [
                -73.8966818839999,
                40.762777588999874
              ],
              [
                -73.89641138399999,
                40.76315008299991
              ],
              [
                -73.89624744699991,
                40.76333274699987
              ],
              [
                -73.89608492499991,
                40.76347375499992
              ],
              [
                -73.89598406499984,
                40.76354310999992
              ],
              [
                -73.89585991899992,
                40.76362847399992
              ],
              [
                -73.89557704399981,
                40.76382796799991
              ],
              [
                -73.89542447799998,
                40.76394724499989
              ],
              [
                -73.89527479399989,
                40.76408559899992
              ],
              [
                -73.8951683709999,
                40.7641973619999
              ],
              [
                -73.89506657699998,
                40.764331327999884
              ],
              [
                -73.89505758700001,
                40.76434653799987
              ],
              [
                -73.89499458899998,
                40.764453153999945
              ],
              [
                -73.89483535100004,
                40.76465531599998
              ],
              [
                -73.89477219899987,
                40.764889217999944
              ],
              [
                -73.89470543599994,
                40.76506903299987
              ],
              [
                -73.8946171639999,
                40.765256256999905
              ],
              [
                -73.89453879999992,
                40.7653572669999
              ],
              [
                -73.89446044299982,
                40.76544639099992
              ],
              [
                -73.89437624799997,
                40.76552658499991
              ],
              [
                -73.89428031700004,
                40.76560677599989
              ],
              [
                -73.8941491849999,
                40.765694356999866
              ],
              [
                -73.89403763099985,
                40.76575891999987
              ],
              [
                -73.89390458899999,
                40.76581825699986
              ],
              [
                -73.89384201999984,
                40.765842335999885
              ],
              [
                -73.89377741500005,
                40.76586719899996
              ],
              [
                -73.89349605899986,
                40.765950929999924
              ],
              [
                -73.89432371999989,
                40.76614647099994
              ],
              [
                -73.89595958499986,
                40.766431033999865
              ],
              [
                -73.90065875099992,
                40.76721143399991
              ],
              [
                -73.90202516599992,
                40.7674167839999
              ],
              [
                -73.90217951699996,
                40.76731584199997
              ],
              [
                -73.90255919500005,
                40.766940786999896
              ],
              [
                -73.90341888199997,
                40.765957306999944
              ],
              [
                -73.90351227799994,
                40.76584945999989
              ],
              [
                -73.90356915599997,
                40.76578178899994
              ],
              [
                -73.90331045299985,
                40.765168148999905
              ],
              [
                -73.90299205599985,
                40.7644118389999
              ],
              [
                -73.902767937,
                40.763879456999916
              ],
              [
                -73.90225241299993,
                40.76264382599989
              ],
              [
                -73.90175373499979,
                40.76077547499991
              ],
              [
                -73.90147053099994,
                40.759633049999884
              ],
              [
                -73.90137640299986,
                40.759642037999896
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.00202032598969,
        "objectid": 208,
        "shape_leng": 0.214721009581,
        "location_id": 208,
        "zone": "Schuylerville/Edgewater Park",
        "borough": "Bronx"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.825986869,
                40.84067575199989
              ],
              [
                -73.82602634299997,
                40.84102446399987
              ],
              [
                -73.82608382099987,
                40.841371456999894
              ],
              [
                -73.82624132999996,
                40.84299359399991
              ],
              [
                -73.82619627799995,
                40.84381693599995
              ],
              [
                -73.82611300699996,
                40.84533869399992
              ],
              [
                -73.82674715099992,
                40.845078917999885
              ],
              [
                -73.8275753599998,
                40.84473187499989
              ],
              [
                -73.8284527429999,
                40.84445071699992
              ],
              [
                -73.82870971100007,
                40.84437387199993
              ],
              [
                -73.8293154839999,
                40.84419272599997
              ],
              [
                -73.82965549999982,
                40.84408912299994
              ],
              [
                -73.83043186399985,
                40.84385798399996
              ],
              [
                -73.83108377199984,
                40.84374456899991
              ],
              [
                -73.83200795799995,
                40.843630393999916
              ],
              [
                -73.832940765,
                40.8435191599999
              ],
              [
                -73.83383204499994,
                40.84351641299992
              ],
              [
                -73.83474813499987,
                40.84350280699989
              ],
              [
                -73.83582911399996,
                40.84347129499989
              ],
              [
                -73.83676643799986,
                40.84338596999988
              ],
              [
                -73.83688925199996,
                40.843374787999906
              ],
              [
                -73.83714579999986,
                40.84272763299991
              ],
              [
                -73.83736984299993,
                40.84204138399985
              ],
              [
                -73.83767001599998,
                40.84112190799987
              ],
              [
                -73.83768901199981,
                40.8408111819999
              ],
              [
                -73.83771374899992,
                40.8404063099999
              ],
              [
                -73.83812376299989,
                40.84057018199996
              ],
              [
                -73.83821037099989,
                40.840604797999895
              ],
              [
                -73.83830524999978,
                40.84064271899997
              ],
              [
                -73.83839056299992,
                40.840676813999934
              ],
              [
                -73.83849424699997,
                40.84069921999994
              ],
              [
                -73.83880813300001,
                40.84076703399992
              ],
              [
                -73.83936452799995,
                40.84087706699995
              ],
              [
                -73.83956700299987,
                40.84054722799988
              ],
              [
                -73.83972014457761,
                40.839565405993035
              ],
              [
                -73.83958953782852,
                40.83879848591283
              ],
              [
                -73.83936180401575,
                40.838801784857914
              ],
              [
                -73.83919255217855,
                40.838713628028806
              ],
              [
                -73.83915984296951,
                40.83845984582745
              ],
              [
                -73.83920223885042,
                40.83827756978396
              ],
              [
                -73.83921295808717,
                40.83824771181022
              ],
              [
                -73.83928671894768,
                40.83804224481622
              ],
              [
                -73.8392872278922,
                40.83783158616525
              ],
              [
                -73.8392458501793,
                40.837592545924615
              ],
              [
                -73.83922069428176,
                40.83739247280874
              ],
              [
                -73.83926310309631,
                40.83720488523883
              ],
              [
                -73.83918642082617,
                40.83710741571121
              ],
              [
                -73.83923392302755,
                40.83674104063165
              ],
              [
                -73.83920869951596,
                40.83656934682848
              ],
              [
                -73.83911300797067,
                40.83591795498704
              ],
              [
                -73.83909187186077,
                40.83572403322114
              ],
              [
                -73.839046635205,
                40.83558234988274
              ],
              [
                -73.8390594007588,
                40.835443894584444
              ],
              [
                -73.83904319206685,
                40.83529281108478
              ],
              [
                -73.83899797777033,
                40.83514168759397
              ],
              [
                -73.83896515672897,
                40.835006315757816
              ],
              [
                -73.83895304620648,
                40.834874120441896
              ],
              [
                -73.83893684463153,
                40.834719889813044
              ],
              [
                -73.8389413081543,
                40.83458771713139
              ],
              [
                -73.83892926676718,
                40.834427198085116
              ],
              [
                -73.83887989543763,
                40.83428236293754
              ],
              [
                -73.83883473556496,
                40.83410920866094
              ],
              [
                -73.83881438332645,
                40.833958119303475
              ],
              [
                -73.83882717280252,
                40.83381022387017
              ],
              [
                -73.83885232384739,
                40.83369066784825
              ],
              [
                -73.83888164699847,
                40.83360115423359
              ],
              [
                -73.83899809143146,
                40.83338245535807
              ],
              [
                -73.83918095199299,
                40.833156118752484
              ],
              [
                -73.83943411928455,
                40.83298338028858
              ],
              [
                -73.84024396311139,
                40.83220402332118
              ],
              [
                -73.84075047236948,
                40.83177986361001
              ],
              [
                -73.8408458452518,
                40.83174852432908
              ],
              [
                -73.84188643774428,
                40.830713176534395
              ],
              [
                -73.84191892734383,
                40.830629758638985
              ],
              [
                -73.84223013648109,
                40.829830691248866
              ],
              [
                -73.84233077477266,
                40.82907396245371
              ],
              [
                -73.84231435750092,
                40.828922980996836
              ],
              [
                -73.84230753757464,
                40.828799468721485
              ],
              [
                -73.842292373229,
                40.828699807532125
              ],
              [
                -73.84228428387955,
                40.828646604991334
              ],
              [
                -73.84223265299995,
                40.82865263099997
              ],
              [
                -73.8415995109999,
                40.828726494999906
              ],
              [
                -73.84143734599988,
                40.82874131099985
              ],
              [
                -73.84122680199985,
                40.82876053399988
              ],
              [
                -73.84102340199989,
                40.82877480599996
              ],
              [
                -73.84058337699986,
                40.8288056799999
              ],
              [
                -73.8403229479999,
                40.828823950999926
              ],
              [
                -73.83747911899988,
                40.82892810099993
              ],
              [
                -73.83715124799993,
                40.82895951599989
              ],
              [
                -73.8370906139999,
                40.82896714699996
              ],
              [
                -73.83707424299983,
                40.82892307199996
              ],
              [
                -73.83703204399984,
                40.828809450999906
              ],
              [
                -73.83691608899996,
                40.82845182599989
              ],
              [
                -73.83686087299981,
                40.82819847399989
              ],
              [
                -73.83685097699993,
                40.828163394999876
              ],
              [
                -73.83678907899998,
                40.827884395999895
              ],
              [
                -73.83664057999991,
                40.82721504499991
              ],
              [
                -73.83661819699992,
                40.827114148999925
              ],
              [
                -73.8365046079999,
                40.826403116999906
              ],
              [
                -73.83626003599986,
                40.82497188099989
              ],
              [
                -73.83619033399988,
                40.82420544999992
              ],
              [
                -73.83612598999984,
                40.823497431999925
              ],
              [
                -73.83612811599986,
                40.82240889399994
              ],
              [
                -73.83612954299997,
                40.821677827999984
              ],
              [
                -73.83613095099999,
                40.8209565959999
              ],
              [
                -73.83613216399988,
                40.82033578499994
              ],
              [
                -73.83614239499985,
                40.81919890299987
              ],
              [
                -73.83639638699991,
                40.81889136599989
              ],
              [
                -73.8365014589999,
                40.81871065899989
              ],
              [
                -73.83666068699988,
                40.81797116099992
              ],
              [
                -73.8366785639999,
                40.817588762999954
              ],
              [
                -73.83696070299992,
                40.81634054099991
              ],
              [
                -73.83895834000002,
                40.81636899399992
              ],
              [
                -73.83974355921865,
                40.816406071751366
              ],
              [
                -73.83974683007641,
                40.816381621942874
              ],
              [
                -73.8397526121542,
                40.81635744206133
              ],
              [
                -73.83974606475891,
                40.816292018214845
              ],
              [
                -73.83973129719243,
                40.81622737208514
              ],
              [
                -73.83970844688206,
                40.81616409798452
              ],
              [
                -73.83967772293015,
                40.81610277791438
              ],
              [
                -73.83963940814726,
                40.816043975888206
              ],
              [
                -73.83959385575336,
                40.81598823283838
              ],
              [
                -73.83953766684768,
                40.8158191821111
              ],
              [
                -73.8396044608103,
                40.815605271036816
              ],
              [
                -73.83961742575758,
                40.81538184391267
              ],
              [
                -73.8395309152364,
                40.815183454975
              ],
              [
                -73.83956806564427,
                40.81449124880409
              ],
              [
                -73.83959097056857,
                40.81432752377344
              ],
              [
                -73.83964922798799,
                40.81421745603259
              ],
              [
                -73.83965775474368,
                40.814116760046424
              ],
              [
                -73.83964836001681,
                40.814081763169504
              ],
              [
                -73.839629016849,
                40.814009717774574
              ],
              [
                -73.83966236691798,
                40.81392164504031
              ],
              [
                -73.83974535687518,
                40.813861965759735
              ],
              [
                -73.83985463009746,
                40.81378992319995
              ],
              [
                -73.83987397095441,
                40.81377717115362
              ],
              [
                -73.83988754547781,
                40.81375800402595
              ],
              [
                -73.83993631613669,
                40.81368913875994
              ],
              [
                -73.84002346996,
                40.8136200230888
              ],
              [
                -73.84014400211656,
                40.81345024593872
              ],
              [
                -73.8401712669304,
                40.81341311815479
              ],
              [
                -73.84026867690187,
                40.8132804750118
              ],
              [
                -73.84032701577196,
                40.81313578806891
              ],
              [
                -73.8403095227641,
                40.813049704798075
              ],
              [
                -73.84030654525426,
                40.81303505222116
              ],
              [
                -73.84028603821864,
                40.81295005105956
              ],
              [
                -73.84029454885903,
                40.81285564990051
              ],
              [
                -73.84034035406837,
                40.81275500628137
              ],
              [
                -73.84039027980772,
                40.81266380801226
              ],
              [
                -73.84040031307201,
                40.81263430143549
              ],
              [
                -73.84043199520461,
                40.81254112811847
              ],
              [
                -73.84041550491237,
                40.81247929982902
              ],
              [
                -73.84039504843176,
                40.81240260456677
              ],
              [
                -73.84032899386817,
                40.81231124600108
              ],
              [
                -73.84028789614791,
                40.81217586311288
              ],
              [
                -73.84026339022252,
                40.81203106194505
              ],
              [
                -73.84028871196402,
                40.811835975239994
              ],
              [
                -73.84030147044926,
                40.81169752058669
              ],
              [
                -73.84029763042702,
                40.81157163000532
              ],
              [
                -73.84025952898116,
                40.8114430368914
              ],
              [
                -73.84017805294744,
                40.811344872467366
              ],
              [
                -73.84008711700521,
                40.811266068070566
              ],
              [
                -73.8400005035066,
                40.8111117391841
              ],
              [
                -73.83996760888152,
                40.81101098591627
              ],
              [
                -73.83986029409101,
                40.810853481044866
              ],
              [
                -73.83975711385058,
                40.81069912905494
              ],
              [
                -73.83966635991298,
                40.810544794157764
              ],
              [
                -73.83957150390646,
                40.81037471781321
              ],
              [
                -73.83944757694832,
                40.81023607121156
              ],
              [
                -73.83938574944685,
                40.8101101008016
              ],
              [
                -73.83929500564086,
                40.80995261819516
              ],
              [
                -73.83918782313233,
                40.80974161221812
              ],
              [
                -73.8390845859294,
                40.80961243627587
              ],
              [
                -73.8389729881599,
                40.809514719474485
              ],
              [
                -73.8389152291753,
                40.80942022504677
              ],
              [
                -73.83889477021641,
                40.809316341987696
              ],
              [
                -73.83881633282607,
                40.80920923009084
              ],
              [
                -73.83873795606092,
                40.809076942389616
              ],
              [
                -73.83868023517216,
                40.80896671211983
              ],
              [
                -73.83861835811027,
                40.808862770047945
              ],
              [
                -73.83854418408738,
                40.80870531022513
              ],
              [
                -73.83853400696889,
                40.808650453119284
              ],
              [
                -73.83852374282493,
                40.808595132848616
              ],
              [
                -73.83848257584289,
                40.8084912202423
              ],
              [
                -73.8383846401907,
                40.80840870719506
              ],
              [
                -73.83831015916998,
                40.80829425002933
              ],
              [
                -73.8382442091364,
                40.80817681009698
              ],
              [
                -73.83818699604151,
                40.80805675426877
              ],
              [
                -73.83813869819386,
                40.80793445799861
              ],
              [
                -73.8380994669796,
                40.80781030223836
              ],
              [
                -73.83806942412893,
                40.80768467534121
              ],
              [
                -73.83804866402264,
                40.807557969956754
              ],
              [
                -73.8380372510487,
                40.8074305809179
              ],
              [
                -73.83803522099961,
                40.80730290612348
              ],
              [
                -73.83804258001825,
                40.80717534491687
              ],
              [
                -73.8380568771934,
                40.80714967620097
              ],
              [
                -73.83806598715289,
                40.80712267695711
              ],
              [
                -73.83806970692224,
                40.80709494793898
              ],
              [
                -73.83806795307203,
                40.807067107027194
              ],
              [
                -73.83806076514087,
                40.80703977484668
              ],
              [
                -73.8380469598718,
                40.80701132094273
              ],
              [
                -73.83802727502474,
                40.80698494009136
              ],
              [
                -73.83800223017066,
                40.80696132815477
              ],
              [
                -73.83743612112924,
                40.806202642591565
              ],
              [
                -73.83735487277579,
                40.80615004018267
              ],
              [
                -73.83727080965522,
                40.806100056110616
              ],
              [
                -73.83715549184235,
                40.80603808520558
              ],
              [
                -73.83703585287998,
                40.80598104011442
              ],
              [
                -73.83691225676597,
                40.80592909361343
              ],
              [
                -73.83667080310849,
                40.80587431601089
              ],
              [
                -73.83643061278549,
                40.80581642195254
              ],
              [
                -73.83589256622264,
                40.80566144824106
              ],
              [
                -73.83531327587227,
                40.80543717686122
              ],
              [
                -73.83506866846948,
                40.805358727163096
              ],
              [
                -73.83482062883235,
                40.80528676793845
              ],
              [
                -73.83456945601597,
                40.805221384928785
              ],
              [
                -73.83431545195694,
                40.8051626569234
              ],
              [
                -73.8340589217921,
                40.8051106542127
              ],
              [
                -73.83380017317468,
                40.80506544004523
              ],
              [
                -73.83353951809008,
                40.805027068090645
              ],
              [
                -73.8332772691688,
                40.804995584906834
              ],
              [
                -73.83301374199807,
                40.804971027912465
              ],
              [
                -73.83274925293276,
                40.8049534268637
              ],
              [
                -73.83248411940501,
                40.8049428028372
              ],
              [
                -73.83221866123425,
                40.80493916821706
              ],
              [
                -73.83195319643455,
                40.80494252818707
              ],
              [
                -73.8316880445239,
                40.804952878228214
              ],
              [
                -73.831615744777,
                40.80493825599656
              ],
              [
                -73.83160651058895,
                40.80503241752972
              ],
              [
                -73.83161133284692,
                40.805125763910695
              ],
              [
                -73.83162387312709,
                40.80521869493028
              ],
              [
                -73.83164408179083,
                40.80531084250266
              ],
              [
                -73.83167187882668,
                40.80540184164405
              ],
              [
                -73.83174365227164,
                40.80549986848207
              ],
              [
                -73.83180860655449,
                40.805600603776334
              ],
              [
                -73.83186656518768,
                40.805703773867236
              ],
              [
                -73.8319173706858,
                40.80580909847973
              ],
              [
                -73.8319608849947,
                40.80591629148392
              ],
              [
                -73.83199698986499,
                40.80602506167317
              ],
              [
                -73.83202558717514,
                40.80613511355461
              ],
              [
                -73.83204659919782,
                40.80624614815206
              ],
              [
                -73.83205996881212,
                40.80635786381795
              ],
              [
                -73.8320432579997,
                40.80639177017507
              ],
              [
                -73.83201944514244,
                40.80642313058547
              ],
              [
                -73.83198917699312,
                40.80645109329341
              ],
              [
                -73.83195327563462,
                40.80647489882504
              ],
              [
                -73.83191271615236,
                40.80649390061511
              ],
              [
                -73.83186860015144,
                40.806507582569296
              ],
              [
                -73.83182212583493,
                40.80651557308137
              ],
              [
                -73.83177455546043,
                40.80651765512602
              ],
              [
                -73.8317271810562,
                40.806513772154354
              ],
              [
                -73.83170424242344,
                40.80652081687644
              ],
              [
                -73.83168311292104,
                40.806530597385375
              ],
              [
                -73.83166435298635,
                40.80654285426413
              ],
              [
                -73.83164846020705,
                40.80655726241233
              ],
              [
                -73.83163585612412,
                40.806573439669805
              ],
              [
                -73.8316268750495,
                40.80659095695197
              ],
              [
                -73.83162175520002,
                40.80660934963127
              ],
              [
                -73.8316206323777,
                40.80662812986097
              ],
              [
                -73.83162353636713,
                40.80664679951426
              ],
              [
                -73.83163039014659,
                40.80666486339696
              ],
              [
                -73.83164101192945,
                40.806681842382105
              ],
              [
                -73.83165511998523,
                40.80669728611808
              ],
              [
                -73.83167234011336,
                40.80671078497406
              ],
              [
                -73.83169221556712,
                40.806721980905195
              ],
              [
                -73.83171421916902,
                40.806730576949285
              ],
              [
                -73.83173526176998,
                40.806735881467716
              ],
              [
                -73.83175709637996,
                40.80673880231731
              ],
              [
                -73.83177925796888,
                40.806739277290355
              ],
              [
                -73.83180127454277,
                40.80673729627106
              ],
              [
                -73.83188006069922,
                40.806852913048715
              ],
              [
                -73.83195121671773,
                40.80697135202643
              ],
              [
                -73.83201456802169,
                40.80709232269032
              ],
              [
                -73.8320699591752,
                40.807215528314956
              ],
              [
                -73.83211725426618,
                40.80734066669107
              ],
              [
                -73.83215633723853,
                40.80746743086719
              ],
              [
                -73.83218711217783,
                40.80759550990189
              ],
              [
                -73.83220950354776,
                40.807724589627036
              ],
              [
                -73.8322234563745,
                40.80785435341753
              ],
              [
                -73.83222893638282,
                40.80798448296906
              ],
              [
                -73.83222593008111,
                40.80811465907792
              ],
              [
                -73.83221444479388,
                40.80824456242455
              ],
              [
                -73.8321945086448,
                40.80837387435645
              ],
              [
                -73.8321661704888,
                40.808502277670144
              ],
              [
                -73.83212949979134,
                40.80862945738909
              ],
              [
                -73.83208458645953,
                40.80875510153658
              ],
              [
                -73.83203154062217,
                40.80887890190087
              ],
              [
                -73.83195945014288,
                40.80903520056658
              ],
              [
                -73.83187885064571,
                40.80918908107865
              ],
              [
                -73.83178988207746,
                40.80934027613904
              ],
              [
                -73.83169269892545,
                40.80948852311305
              ],
              [
                -73.83158746995016,
                40.80963356448455
              ],
              [
                -73.83147437789091,
                40.80977514830487
              ],
              [
                -73.83135361915025,
                40.809913028629325
              ],
              [
                -73.83122540345188,
                40.81004696594539
              ],
              [
                -73.83108995347739,
                40.81017672758829
              ],
              [
                -73.83094750447907,
                40.810302088145704
              ],
              [
                -73.8307983038718,
                40.81042282984924
              ],
              [
                -73.83064261080314,
                40.810538742953185
              ],
              [
                -73.83048069570309,
                40.81064962609816
              ],
              [
                -73.8303327518616,
                40.81074474921704
              ],
              [
                -73.83017915330238,
                40.81083454948945
              ],
              [
                -73.83002023146078,
                40.81091883313155
              ],
              [
                -73.82985632926382,
                40.810997418262865
              ],
              [
                -73.82968780038979,
                40.811070135299786
              ],
              [
                -73.82951500850446,
                40.811136827321235
              ],
              [
                -73.82933832647683,
                40.81119735040733
              ],
              [
                -73.82915813557412,
                40.811251573950464
              ],
              [
                -73.82897482463858,
                40.81129938093681
              ],
              [
                -73.82878878924798,
                40.81134066819928
              ],
              [
                -73.8286004308618,
                40.811375346640155
              ],
              [
                -73.8284101559549,
                40.81140334142335
              ],
              [
                -73.82821837513981,
                40.811424592135864
              ],
              [
                -73.82802550227991,
                40.8114390529188
              ],
              [
                -73.82783195359622,
                40.81144669256547
              ],
              [
                -73.82763814676892,
                40.811447494589565
              ],
              [
                -73.82744450003561,
                40.81144145726028
              ],
              [
                -73.8272514312881,
                40.81142859360614
              ],
              [
                -73.82705935717021,
                40.81140893138716
              ],
              [
                -73.82686869217912,
                40.8113825130345
              ],
              [
                -73.82667984776913,
                40.8113493955589
              ],
              [
                -73.82653684940894,
                40.81131957851295
              ],
              [
                -73.82639533594696,
                40.81128591951342
              ],
              [
                -73.82623648599804,
                40.81128265605054
              ],
              [
                -73.82622408754939,
                40.81128177077569
              ],
              [
                -73.82621166726312,
                40.81128245694886
              ],
              [
                -73.82619956778117,
                40.811284695640154
              ],
              [
                -73.82618812289604,
                40.81128842509033
              ],
              [
                -73.82617764834127,
                40.81129354241382
              ],
              [
                -73.82611489720574,
                40.811406376298365
              ],
              [
                -73.8260807602305,
                40.81142997662357
              ],
              [
                -73.82604238618816,
                40.81144946038735
              ],
              [
                -73.82600062730944,
                40.81146439488271
              ],
              [
                -73.82595641099927,
                40.81147444843503
              ],
              [
                -73.82579755905094,
                40.811205335639734
              ],
              [
                -73.82576724152807,
                40.81121591023323
              ],
              [
                -73.82592714984648,
                40.81148650139662
              ],
              [
                -73.82581255718891,
                40.81153370452028
              ],
              [
                -73.82569235530275,
                40.811341485249415
              ],
              [
                -73.82571569203296,
                40.81132735881507
              ],
              [
                -73.82565308444987,
                40.811212197522536
              ],
              [
                -73.8256390924955,
                40.81121748740355
              ],
              [
                -73.8256066217491,
                40.8111607904274
              ],
              [
                -73.8256229491065,
                40.81115373367981
              ],
              [
                -73.82553710461548,
                40.81101552428063
              ],
              [
                -73.82549979349373,
                40.811027859126376
              ],
              [
                -73.82557397476148,
                40.811171362619184
              ],
              [
                -73.82559029284799,
                40.811167845997794
              ],
              [
                -73.8256250897204,
                40.81122631740209
              ],
              [
                -73.82559709277551,
                40.8112404367688
              ],
              [
                -73.8257664503311,
                40.81155182855334
              ],
              [
                -73.82572515052027,
                40.811566265408715
              ],
              [
                -73.82567229084775,
                40.811584743730364
              ],
              [
                -73.82535766859549,
                40.811027643923815
              ],
              [
                -73.82532502163363,
                40.81103821604529
              ],
              [
                -73.82539691090074,
                40.811167553292286
              ],
              [
                -73.82540857362882,
                40.81116226158856
              ],
              [
                -73.82565070298091,
                40.81159229091601
              ],
              [
                -73.82559378915639,
                40.811612185356886
              ],
              [
                -73.82551303817333,
                40.81164913340942
              ],
              [
                -73.82523137433175,
                40.81120978979894
              ],
              [
                -73.82520803886291,
                40.811223916137436
              ],
              [
                -73.82549075318562,
                40.81165933042339
              ],
              [
                -73.8254048364168,
                40.8116986420627
              ],
              [
                -73.82526664318074,
                40.81175234789845
              ],
              [
                -73.82494681756404,
                40.81132619395647
              ],
              [
                -73.82496314503149,
                40.81131913746995
              ],
              [
                -73.82491670053449,
                40.81126064918352
              ],
              [
                -73.82487938938073,
                40.811272983828225
              ],
              [
                -73.82492115121352,
                40.81134031669775
              ],
              [
                -73.82493047923725,
                40.81133679072726
              ],
              [
                -73.82524350467826,
                40.811761340240054
              ],
              [
                -73.82515527774036,
                40.811795628141596
              ],
              [
                -73.82507659171799,
                40.811823313387535
              ],
              [
                -73.82491588091875,
                40.81157221235922
              ],
              [
                -73.82492762380458,
                40.811536824817146
              ],
              [
                -73.82487876522168,
                40.81151019708602
              ],
              [
                -73.82483004135572,
                40.81143223154372
              ],
              [
                -73.82480905359992,
                40.81143928159993
              ],
              [
                -73.82505782162761,
                40.8118299183893
              ],
              [
                -73.82502001572507,
                40.81184321961664
              ],
              [
                -73.82494076044935,
                40.81185726151931
              ],
              [
                -73.82487321949907,
                40.81184653686489
              ],
              [
                -73.82483138373236,
                40.81180752780155
              ],
              [
                -73.82481754381085,
                40.81175439904835
              ],
              [
                -73.82479433788363,
                40.81171895958462
              ],
              [
                -73.82476875524654,
                40.81170121718032
              ],
              [
                -73.8247105112495,
                40.8116993594112
              ],
              [
                -73.82463817629335,
                40.811739964149915
              ],
              [
                -73.82459615811634,
                40.81176999506733
              ],
              [
                -73.82458438257436,
                40.81181777383605
              ],
              [
                -73.82459116681233,
                40.811895675676965
              ],
              [
                -73.82456543017311,
                40.81193635175814
              ],
              [
                -73.82453550423843,
                40.81198695467631
              ],
              [
                -73.82435772900803,
                40.812063494629065
              ],
              [
                -73.82421778221027,
                40.81211992869825
              ],
              [
                -73.82406387432496,
                40.81216926152623
              ],
              [
                -73.82387267753717,
                40.81222207732535
              ],
              [
                -73.82381669226842,
                40.81223637848479
              ],
              [
                -73.8237211278877,
                40.812260790715214
              ],
              [
                -73.82362089732939,
                40.81227656987352
              ],
              [
                -73.823566809061,
                40.81227648711492
              ],
              [
                -73.82355798874956,
                40.81227647361686
              ],
              [
                -73.82352317906111,
                40.81222331261734
              ],
              [
                -73.82349762473447,
                40.812194949570085
              ],
              [
                -73.82347433550338,
                40.81219137377911
              ],
              [
                -73.82346265768916,
                40.81220197747665
              ],
              [
                -73.82346492657634,
                40.812224993839294
              ],
              [
                -73.82346484675018,
                40.81225508855102
              ],
              [
                -73.82346008270234,
                40.81229402676288
              ],
              [
                -73.8234551334144,
                40.81230986502463
              ],
              [
                -73.82345068402797,
                40.81232410603196
              ],
              [
                -73.82339468020483,
                40.8123558854955
              ],
              [
                -73.82332937582741,
                40.8123805692376
              ],
              [
                -73.82324776141463,
                40.81240522676671
              ],
              [
                -73.82318481038556,
                40.81242106285956
              ],
              [
                -73.82307756008963,
                40.81244745252517
              ],
              [
                -73.82298666429756,
                40.81245793391372
              ],
              [
                -73.82290738835799,
                40.81247905532738
              ],
              [
                -73.82285185101995,
                40.81249017339935
              ],
              [
                -73.82272558091357,
                40.81250532945067
              ],
              [
                -73.82263918380445,
                40.81257600731609
              ],
              [
                -73.82230863560132,
                40.81265821507864
              ],
              [
                -73.82225248068919,
                40.81254885759145
              ],
              [
                -73.82227114362937,
                40.81254003518731
              ],
              [
                -73.8221714521873,
                40.81235400527647
              ],
              [
                -73.82213413969171,
                40.81236633903022
              ],
              [
                -73.82228290825742,
                40.81266149978754
              ],
              [
                -73.82217757126159,
                40.812679740811724
              ],
              [
                -73.82212646342491,
                40.812623014621785
              ],
              [
                -73.82209149942082,
                40.81262827121212
              ],
              [
                -73.8221099447492,
                40.81270088014639
              ],
              [
                -73.82197234447621,
                40.812750234169755
              ],
              [
                -73.82175599067492,
                40.812768536024244
              ],
              [
                -73.82169530549909,
                40.8126648342724
              ],
              [
                -73.82166033195143,
                40.81267363135489
              ],
              [
                -73.82170205886845,
                40.81275335780746
              ],
              [
                -73.8216892526155,
                40.81276832745508
              ],
              [
                -73.82162030083451,
                40.81283112168251
              ],
              [
                -73.82151718570897,
                40.812847321235445
              ],
              [
                -73.82142259915253,
                40.812701587306364
              ],
              [
                -73.8214039410239,
                40.812708639848246
              ],
              [
                -73.82145723512515,
                40.81281847796686
              ],
              [
                -73.82139426361685,
                40.81284139332036
              ],
              [
                -73.82141253439893,
                40.81287295247801
              ],
              [
                -73.82136621348968,
                40.81287498483093
              ],
              [
                -73.82133130764494,
                40.812858998126025
              ],
              [
                -73.82130103684717,
                40.81285187094222
              ],
              [
                -73.82126609620738,
                40.812848276143605
              ],
              [
                -73.82124512277771,
                40.81285001336021
              ],
              [
                -73.82122415255714,
                40.81284998084558
              ],
              [
                -73.82121713848622,
                40.812858821144275
              ],
              [
                -73.82121710519647,
                40.81287121357524
              ],
              [
                -73.82122873118149,
                40.812880082275
              ],
              [
                -73.82124502768364,
                40.812885418582646
              ],
              [
                -73.82125259793112,
                40.81290133764064
              ],
              [
                -73.82125429431079,
                40.812904906204366
              ],
              [
                -73.82123791376058,
                40.81293143382986
              ],
              [
                -73.82117960695315,
                40.81295258706845
              ],
              [
                -73.8211306443835,
                40.81296490242737
              ],
              [
                -73.82105838069432,
                40.81297718277626
              ],
              [
                -73.82096282827042,
                40.812985885107636
              ],
              [
                -73.82084163522836,
                40.81299808882693
              ],
              [
                -73.82058709143999,
                40.813019446608095
              ],
              [
                -73.82052755199602,
                40.812825885700526
              ],
              [
                -73.82056254831423,
                40.81280823782181
              ],
              [
                -73.82053476598452,
                40.812742694375274
              ],
              [
                -73.82048115677324,
                40.81274969286066
              ],
              [
                -73.82046264635122,
                40.81270186735578
              ],
              [
                -73.82044179624151,
                40.81265757783616
              ],
              [
                -73.82113664072327,
                40.81246747116151
              ],
              [
                -73.82112969804922,
                40.81244975686678
              ],
              [
                -73.82042087950583,
                40.81263807250523
              ],
              [
                -73.82032355515894,
                40.81243965219495
              ],
              [
                -73.8210813694438,
                40.81222663057413
              ],
              [
                -73.8210651207081,
                40.81220359246741
              ],
              [
                -73.82024436122036,
                40.81243067757398
              ],
              [
                -73.82025827890693,
                40.81245371266437
              ],
              [
                -73.8202909114851,
                40.812448452493214
              ],
              [
                -73.82042068366609,
                40.812710652675996
              ],
              [
                -73.82043933256568,
                40.812707141586564
              ],
              [
                -73.8204624983142,
                40.812756744077056
              ],
              [
                -73.82040420162947,
                40.81277435630248
              ],
              [
                -73.82044595032454,
                40.812845231399315
              ],
              [
                -73.82049024326342,
                40.81283644919561
              ],
              [
                -73.82055521517324,
                40.81301765507684
              ],
              [
                -73.82047810145939,
                40.813018766338544
              ],
              [
                -73.8204130295436,
                40.81297161162655
              ],
              [
                -73.82039207280113,
                40.81284401450982
              ],
              [
                -73.82010759340298,
                40.81286406966539
              ],
              [
                -73.82009596025118,
                40.812973084723126
              ],
              [
                -73.82006955436661,
                40.81304473962545
              ],
              [
                -73.82001227716462,
                40.813092173502625
              ],
              [
                -73.81990270879282,
                40.81313758189024
              ],
              [
                -73.81981638454968,
                40.8131799331559
              ],
              [
                -73.819681173456,
                40.813206276464676
              ],
              [
                -73.81956231040617,
                40.81321848131106
              ],
              [
                -73.81946672338206,
                40.813239575333824
              ],
              [
                -73.81938042142714,
                40.81327307513322
              ],
              [
                -73.81916590911875,
                40.813329386734786
              ],
              [
                -73.81893043021248,
                40.81338389527925
              ],
              [
                -73.8187439158347,
                40.8134260883509
              ],
              [
                -73.81854113857591,
                40.813450553177184
              ],
              [
                -73.81845432163811,
                40.813459900590125
              ],
              [
                -73.81820085716599,
                40.8134871917177
              ],
              [
                -73.81814963601113,
                40.81347294922809
              ],
              [
                -73.81811703024695,
                40.81346758675591
              ],
              [
                -73.81808440601249,
                40.81346930619032
              ],
              [
                -73.81807968272179,
                40.813492311624415
              ],
              [
                -73.81807961497645,
                40.81351709530926
              ],
              [
                -73.81804229161202,
                40.813532967856275
              ],
              [
                -73.81795807110602,
                40.813541089528506
              ],
              [
                -73.81796401253763,
                40.81340156948314
              ],
              [
                -73.81795616014033,
                40.81339757451686
              ],
              [
                -73.81795889327482,
                40.81335675269386
              ],
              [
                -73.81797068908527,
                40.81335677131783
              ],
              [
                -73.81797608887013,
                40.81329902414638
              ],
              [
                -73.81793546303673,
                40.81329796423283
              ],
              [
                -73.81793138112988,
                40.813352725510505
              ],
              [
                -73.81794185814788,
                40.81335572885523
              ],
              [
                -73.81793912728281,
                40.81339555608656
              ],
              [
                -73.81793125037362,
                40.813400521983574
              ],
              [
                -73.8179208412743,
                40.81354468060326
              ],
              [
                -73.81789779310118,
                40.81354690310726
              ],
              [
                -73.81777195284765,
                40.813553784551054
              ],
              [
                -73.81760101809583,
                40.813548387661534
              ],
              [
                -73.81759488728466,
                40.8135481935175
              ],
              [
                -73.8174480681646,
                40.81355858322607
              ],
              [
                -73.81738942091515,
                40.81357073625067
              ],
              [
                -73.81737692242342,
                40.81337574752845
              ],
              [
                -73.81735988456492,
                40.81337572053987
              ],
              [
                -73.81737201626977,
                40.81357434380445
              ],
              [
                -73.8173548154798,
                40.81357790828955
              ],
              [
                -73.81724755216894,
                40.81360783264775
              ],
              [
                -73.81686078332412,
                40.81368842878229
              ],
              [
                -73.81674850706827,
                40.813708498273854
              ],
              [
                -73.81667835319837,
                40.8138004404734
              ],
              [
                -73.81648015546546,
                40.81385323288393
              ],
              [
                -73.81620056896291,
                40.81384747647933
              ],
              [
                -73.81587919093577,
                40.81376705536386
              ],
              [
                -73.81576169237755,
                40.8135718498211
              ],
              [
                -73.81566326325769,
                40.81351203974346
              ],
              [
                -73.81551416367422,
                40.81350472099332
              ],
              [
                -73.81533237506966,
                40.813522132317814
              ],
              [
                -73.81501777001836,
                40.813541100741375
              ],
              [
                -73.814890079811,
                40.81354089580195
              ],
              [
                -73.81485700087099,
                40.81354084268807
              ],
              [
                -73.81470560966756,
                40.81351935582401
              ],
              [
                -73.81469475694274,
                40.81351931861388
              ],
              [
                -73.81447727191015,
                40.81351854184116
              ],
              [
                -73.81449745463826,
                40.81296493146696
              ],
              [
                -73.81461860845134,
                40.81296689723878
              ],
              [
                -73.8146186626928,
                40.81294742406824
              ],
              [
                -73.81452546792079,
                40.81294550378021
              ],
              [
                -73.81450223252202,
                40.81292245350818
              ],
              [
                -73.81449994116008,
                40.812908287604444
              ],
              [
                -73.81446965693051,
                40.81290646864015
              ],
              [
                -73.81446963225495,
                40.812915320279316
              ],
              [
                -73.81443694808743,
                40.81293828107302
              ],
              [
                -73.8143693843687,
                40.812936401405985
              ],
              [
                -73.81437165013429,
                40.81295941861481
              ],
              [
                -73.81446017919359,
                40.81296310225658
              ],
              [
                -73.81443067312435,
                40.81351837571565
              ],
              [
                -73.81433879436773,
                40.81351370322762
              ],
              [
                -73.81424674267123,
                40.813515566181394
              ],
              [
                -73.81415532160705,
                40.813523948314405
              ],
              [
                -73.81406532924294,
                40.81353877645404
              ],
              [
                -73.81397755117555,
                40.81355992115674
              ],
              [
                -73.81389275367364,
                40.813587197838025
              ],
              [
                -73.81382695686109,
                40.81357388378527
              ],
              [
                -73.8137635598112,
                40.81355500981668
              ],
              [
                -73.81370337355365,
                40.81353081738516
              ],
              [
                -73.81364716804077,
                40.81350161598164
              ],
              [
                -73.8135956622967,
                40.81346777917578
              ],
              [
                -73.81354951521904,
                40.81342973983681
              ],
              [
                -73.81358329077027,
                40.813395979392524
              ],
              [
                -73.81359278876356,
                40.8133864853681
              ],
              [
                -73.81357590139099,
                40.81336304009896
              ],
              [
                -73.81359600879816,
                40.81333731331733
              ],
              [
                -73.81358218143802,
                40.81332206814458
              ],
              [
                -73.81348806610542,
                40.813359385883324
              ],
              [
                -73.8134754982378,
                40.813380409354224
              ],
              [
                -73.81346638785192,
                40.813395649261096
              ],
              [
                -73.81313381796316,
                40.81333105424298
              ],
              [
                -73.81304259684661,
                40.81330070011885
              ],
              [
                -73.81297045202061,
                40.81327048877551
              ],
              [
                -73.81290297626032,
                40.81323674432575
              ],
              [
                -73.8128331909565,
                40.81319591631859
              ],
              [
                -73.81276572662637,
                40.813158630665875
              ],
              [
                -73.81268886647128,
                40.813147884745675
              ],
              [
                -73.81260732758811,
                40.81314421093821
              ],
              [
                -73.81255145273762,
                40.81312818866174
              ],
              [
                -73.81248159793859,
                40.813112142449086
              ],
              [
                -73.81241175315147,
                40.81309255607458
              ],
              [
                -73.8123442043051,
                40.813085365376885
              ],
              [
                -73.81225801881348,
                40.813076374393425
              ],
              [
                -73.81216251439967,
                40.81306736648839
              ],
              [
                -73.81207166602559,
                40.81306013817158
              ],
              [
                -73.81199714539541,
                40.81304585446229
              ],
              [
                -73.8119412707777,
                40.813029830717355
              ],
              [
                -73.81191569106036,
                40.813012086637585
              ],
              [
                -73.81189483219015,
                40.81297310709518
              ],
              [
                -73.81187624715334,
                40.81295360401523
              ],
              [
                -73.81185065747438,
                40.812939400040214
              ],
              [
                -73.81183200304997,
                40.8129446806377
              ],
              [
                -73.81181560739665,
                40.8129747487165
              ],
              [
                -73.81179920804846,
                40.81300658601698
              ],
              [
                -73.81177347317572,
                40.813043718612995
              ],
              [
                -73.81173149756725,
                40.81305604205966
              ],
              [
                -73.81169687915175,
                40.813056924669226
              ],
              [
                -73.81166625396294,
                40.813057705737904
              ],
              [
                -73.81156607359787,
                40.813054001914104
              ],
              [
                -73.8115477260317,
                40.81305095597552
              ],
              [
                -73.81147990483964,
                40.81303969933488
              ],
              [
                -73.81142401094972,
                40.813030756248956
              ],
              [
                -73.81133318625712,
                40.813014675039135
              ],
              [
                -73.81124703768323,
                40.81299329087468
              ],
              [
                -73.81121212830357,
                40.81297907149982
              ],
              [
                -73.81119160436302,
                40.812960526894834
              ],
              [
                -73.81116987432775,
                40.81293575275008
              ],
              [
                -73.81114311206214,
                40.8129140065998
              ],
              [
                -73.81111203579378,
                40.81289587204872
              ],
              [
                -73.8110774795241,
                40.81288183577624
              ],
              [
                -73.81104806187903,
                40.81288056229013
              ],
              [
                -73.81101873877009,
                40.81288275940664
              ],
              [
                -73.81099021524315,
                40.81288837429818
              ],
              [
                -73.81096317711896,
                40.81289727196018
              ],
              [
                -73.81093363196798,
                40.81289512854652
              ],
              [
                -73.8109049419503,
                40.812889354418935
              ],
              [
                -73.81087787465059,
                40.812880104060845
              ],
              [
                -73.81085315423755,
                40.81286762496026
              ],
              [
                -73.81083144208924,
                40.81285225098786
              ],
              [
                -73.81081331909884,
                40.8128343934649
              ],
              [
                -73.81079927013214,
                40.81281453015781
              ],
              [
                -73.81078967105614,
                40.81279319249638
              ],
              [
                -73.81078477868346,
                40.81277095135499
              ],
              [
                -73.81066931287849,
                40.812764366537614
              ],
              [
                -73.81059832209316,
                40.81293882510617
              ],
              [
                -73.81050159246415,
                40.81296494143388
              ],
              [
                -73.81042488566062,
                40.81298055690011
              ],
              [
                -73.81034693698221,
                40.81299208843081
              ],
              [
                -73.81026812262404,
                40.812999480372234
              ],
              [
                -73.810164771254,
                40.812987973839114
              ],
              [
                -73.81006463809193,
                40.812968335663946
              ],
              [
                -73.80998314944907,
                40.812946959403526
              ],
              [
                -73.8099202908178,
                40.812929152261766
              ],
              [
                -73.80972609888273,
                40.81284119116311
              ],
              [
                -73.80953579719565,
                40.812748452092066
              ],
              [
                -73.80933224205826,
                40.81264160525433
              ],
              [
                -73.80913385048646,
                40.81252929207718
              ],
              [
                -73.80894087664379,
                40.8124116564625
              ],
              [
                -73.80875356774892,
                40.81228884913054
              ],
              [
                -73.80857216375779,
                40.81216102742722
              ],
              [
                -73.80839689705721,
                40.81202835512165
              ],
              [
                -73.80822799216608,
                40.81189100219711
              ],
              [
                -73.808108664426,
                40.81178772984366
              ],
              [
                -73.80799293413388,
                40.81168212066458
              ],
              [
                -73.80788088051817,
                40.81157424697541
              ],
              [
                -73.80773421272856,
                40.81142370404314
              ],
              [
                -73.80759470369257,
                40.811269283289995
              ],
              [
                -73.80746907410685,
                40.811119307908385
              ],
              [
                -73.80735019739234,
                40.810966187898856
              ],
              [
                -73.80724175664754,
                40.81081524051
              ],
              [
                -73.80713987884187,
                40.81066167645373
              ],
              [
                -73.80661359765998,
                40.81031221261748
              ],
              [
                -73.80621482271201,
                40.81004741226069
              ],
              [
                -73.80603047812363,
                40.80996919585505
              ],
              [
                -73.80584889757822,
                40.80988733217924
              ],
              [
                -73.80567020604411,
                40.809801877578565
              ],
              [
                -73.80551448228043,
                40.809723254745585
              ],
              [
                -73.80536120432704,
                40.80964190475309
              ],
              [
                -73.80521045489398,
                40.80955787150303
              ],
              [
                -73.80503639918926,
                40.80945560231074
              ],
              [
                -73.80506378406417,
                40.80942970050321
              ],
              [
                -73.80507948389705,
                40.80943868913328
              ],
              [
                -73.80511892265707,
                40.809396934363
              ],
              [
                -73.80506135232707,
                40.80936497193479
              ],
              [
                -73.80502190996822,
                40.80940772345323
              ],
              [
                -73.80503236536408,
                40.80941769848615
              ],
              [
                -73.80501511652447,
                40.809442695706984
              ],
              [
                -73.80484464130905,
                40.80933600431578
              ],
              [
                -73.8046780836507,
                40.80922579881701
              ],
              [
                -73.80451556897218,
                40.80911216221173
              ],
              [
                -73.8043246055215,
                40.80897027231311
              ],
              [
                -73.80413994427606,
                40.808823645840725
              ],
              [
                -73.8040536382148,
                40.80875165808883
              ],
              [
                -73.80403448452356,
                40.80869203448019
              ],
              [
                -73.80400670107925,
                40.80863440527091
              ],
              [
                -73.80397063436935,
                40.808579489109675
              ],
              [
                -73.80392673417204,
                40.80852797081171
              ],
              [
                -73.8039002554765,
                40.80851805701732
              ],
              [
                -73.80387198076747,
                40.8085116141824
              ],
              [
                -73.8038426918526,
                40.80850882045381
              ],
              [
                -73.80381319858235,
                40.80850975307942
              ],
              [
                -73.8037843164576,
                40.80851438627146
              ],
              [
                -73.80375684408128,
                40.80852259192064
              ],
              [
                -73.80373154107626,
                40.80853414313723
              ],
              [
                -73.80370910708318,
                40.80854872052492
              ],
              [
                -73.80369016241391,
                40.80856592101269
              ],
              [
                -73.80367523090003,
                40.80858526899891
              ],
              [
                -73.80366472540902,
                40.80860622950239
              ],
              [
                -73.80365216061135,
                40.8086534158187
              ],
              [
                -73.80361883887213,
                40.80871630005787
              ],
              [
                -73.80359386615068,
                40.80875717047788
              ],
              [
                -73.80352332574783,
                40.80880110869287
              ],
              [
                -73.80343625359559,
                40.80883242967637
              ],
              [
                -73.80333253213347,
                40.80889204732416
              ],
              [
                -73.80319979764344,
                40.80895790675767
              ],
              [
                -73.8030628852357,
                40.80903634957932
              ],
              [
                -73.80295921748117,
                40.80907708372951
              ],
              [
                -73.80281002721175,
                40.80910515042796
              ],
              [
                -73.80266088412849,
                40.80911748306436
              ],
              [
                -73.80250350466216,
                40.80911406594121
              ],
              [
                -73.80235859690632,
                40.809094933856684
              ],
              [
                -73.80226131336802,
                40.809071390765304
              ],
              [
                -73.80215605651892,
                40.80905477250791
              ],
              [
                -73.80205286352215,
                40.80903186988071
              ],
              [
                -73.80195238321679,
                40.80900282688758
              ],
              [
                -73.80185524738366,
                40.80896782614058
              ],
              [
                -73.80176206677199,
                40.80892708771134
              ],
              [
                -73.80167342725866,
                40.80888086774809
              ],
              [
                -73.80149578047421,
                40.80876493036835
              ],
              [
                -73.80135746259248,
                40.80867466115674
              ],
              [
                -73.80057684375755,
                40.808311824761496
              ],
              [
                -73.79885945699559,
                40.807136473955254
              ],
              [
                -73.79791967769252,
                40.806694227553194
              ],
              [
                -73.79715349910241,
                40.80639398668263
              ],
              [
                -73.79702945544223,
                40.806345377719005
              ],
              [
                -73.79651535630283,
                40.80629227429098
              ],
              [
                -73.79636945999776,
                40.8062772027666
              ],
              [
                -73.79650268526339,
                40.80610443598995
              ],
              [
                -73.79620903473185,
                40.805968590461845
              ],
              [
                -73.79618823923639,
                40.805996877675135
              ],
              [
                -73.79601037569107,
                40.80592103186696
              ],
              [
                -73.79584187394705,
                40.806168721202106
              ],
              [
                -73.79577769394001,
                40.80616294902918
              ],
              [
                -73.7956205800675,
                40.80606148535965
              ],
              [
                -73.79546898916594,
                40.805955281637246
              ],
              [
                -73.79532316872111,
                40.80584451126842
              ],
              [
                -73.79518335679417,
                40.80572935511478
              ],
              [
                -73.795049024771,
                40.805609295066425
              ],
              [
                -73.79492122399245,
                40.80548518672159
              ],
              [
                -73.79480016551285,
                40.80535723507231
              ],
              [
                -73.79451648626257,
                40.80500321868419
              ],
              [
                -73.79401383625986,
                40.80482686129688
              ],
              [
                -73.79400631451523,
                40.80463795266795
              ],
              [
                -73.79373474810106,
                40.80444351547707
              ],
              [
                -73.79365540502808,
                40.80438665196931
              ],
              [
                -73.79358593394123,
                40.80437214572878
              ],
              [
                -73.79351389643507,
                40.80426120031478
              ],
              [
                -73.7933898228672,
                40.804204330036214
              ],
              [
                -73.7932537794263,
                40.80420442110713
              ],
              [
                -73.79311797997443,
                40.80419823204248
              ],
              [
                -73.7929829261505,
                40.80418578570447
              ],
              [
                -73.79284911683806,
                40.80416712806937
              ],
              [
                -73.79271704632252,
                40.80414232805787
              ],
              [
                -73.7925951894607,
                40.804113566784565
              ],
              [
                -73.79247568741523,
                40.804079575992134
              ],
              [
                -73.79235892851956,
                40.804040466138126
              ],
              [
                -73.79224529219171,
                40.803996364314884
              ],
              [
                -73.79215607116778,
                40.80405105809916
              ],
              [
                -73.79206204901791,
                40.804100892141136
              ],
              [
                -73.79198995045105,
                40.804114294951276
              ],
              [
                -73.79191664479967,
                40.804123143292045
              ],
              [
                -73.7918426294635,
                40.804127377124786
              ],
              [
                -73.7917684066589,
                40.80412696772178
              ],
              [
                -73.7916944800095,
                40.80412191786077
              ],
              [
                -73.7916001293646,
                40.80414342358668
              ],
              [
                -73.79103717515576,
                40.804263031702
              ],
              [
                -73.79065958257803,
                40.8044857925821
              ],
              [
                -73.79056823807788,
                40.80455801129177
              ],
              [
                -73.79028410874172,
                40.80481710368888
              ],
              [
                -73.79015192600129,
                40.8050799885924
              ],
              [
                -73.79006256328053,
                40.80540894183437
              ],
              [
                -73.7900820425006,
                40.80552272773616
              ],
              [
                -73.79012463294092,
                40.80577155988572
              ],
              [
                -73.79025263728276,
                40.80601919752716
              ],
              [
                -73.79042839442938,
                40.806326649910694
              ],
              [
                -73.79073479983785,
                40.80658882885658
              ],
              [
                -73.79072432977641,
                40.806684277782416
              ],
              [
                -73.79072154309202,
                40.80678003427088
              ],
              [
                -73.79072645019966,
                40.80687574156305
              ],
              [
                -73.79073903285,
                40.80697104308165
              ],
              [
                -73.79075924419678,
                40.80706558376
              ],
              [
                -73.79078700896973,
                40.80715901136414
              ],
              [
                -73.79082222375605,
                40.80725097780619
              ],
              [
                -73.79086475738406,
                40.80734114044052
              ],
              [
                -73.79101327380822,
                40.807371263930484
              ],
              [
                -73.79115926599255,
                40.80740782405771
              ],
              [
                -73.7913022512035,
                40.80745069993452
              ],
              [
                -73.79144175664779,
                40.807499749789905
              ],
              [
                -73.79146280704165,
                40.807500987094166
              ],
              [
                -73.79148383959571,
                40.80749958553697
              ],
              [
                -73.79150428520737,
                40.80749558304205
              ],
              [
                -73.79152359065651,
                40.80748908790948
              ],
              [
                -73.7915412335731,
                40.807480275885624
              ],
              [
                -73.79155673657297,
                40.8074693854076
              ],
              [
                -73.79156968017568,
                40.80745671115149
              ],
              [
                -73.79157971415242,
                40.80744259605889
              ],
              [
                -73.79158656700483,
                40.8074274220576
              ],
              [
                -73.79159005331,
                40.80741159972699
              ],
              [
                -73.79156011736815,
                40.807377591389475
              ],
              [
                -73.79152496573849,
                40.80734659831364
              ],
              [
                -73.7914851191538,
                40.80731907962212
              ],
              [
                -73.79144116789494,
                40.807295442968716
              ],
              [
                -73.7914086337071,
                40.80728532035198
              ],
              [
                -73.79137890234121,
                40.8072710646301
              ],
              [
                -73.79135579322265,
                40.80725545526747
              ],
              [
                -73.79133609046531,
                40.80723734868177
              ],
              [
                -73.7913202557913,
                40.8072171691925
              ],
              [
                -73.7913086602745,
                40.80719538969614
              ],
              [
                -73.79130157564589,
                40.80717252058411
              ],
              [
                -73.7912991761033,
                40.80714749033482
              ],
              [
                -73.79130217735735,
                40.80712249759293
              ],
              [
                -73.79131049914889,
                40.80709821066667
              ],
              [
                -73.79132391894659,
                40.80707527899014
              ],
              [
                -73.79134207789949,
                40.80705431575743
              ],
              [
                -73.79136449043281,
                40.80703588152564
              ],
              [
                -73.79140059874486,
                40.806997877913076
              ],
              [
                -73.79144335489711,
                40.806964090642744
              ],
              [
                -73.79149191636198,
                40.80693518549899
              ],
              [
                -73.79154532622086,
                40.80691173206286
              ],
              [
                -73.79160253202036,
                40.80689419248751
              ],
              [
                -73.79166240651075,
                40.8068829123926
              ],
              [
                -73.79172376985717,
                40.80687811405359
              ],
              [
                -73.79228907337423,
                40.80684900557946
              ],
              [
                -73.79361464719491,
                40.80739206270612
              ],
              [
                -73.79367568886633,
                40.80742378797602
              ],
              [
                -73.79376346976115,
                40.807451167872856
              ],
              [
                -73.79575752264316,
                40.80834332114413
              ],
              [
                -73.7985376596495,
                40.80958707552972
              ],
              [
                -73.80010949916637,
                40.80971095407831
              ],
              [
                -73.80146670007328,
                40.8101417179631
              ],
              [
                -73.80124032530553,
                40.8104793397404
              ],
              [
                -73.80099517994405,
                40.81070707633148
              ],
              [
                -73.80101172909842,
                40.81071339964729
              ],
              [
                -73.80115707624924,
                40.81058776561507
              ],
              [
                -73.80135552746913,
                40.81071084489494
              ],
              [
                -73.8014783291995,
                40.810606279088695
              ],
              [
                -73.802049646329,
                40.81016071487724
              ],
              [
                -73.80224245334347,
                40.810197393660346
              ],
              [
                -73.80252595880887,
                40.8104780428706
              ],
              [
                -73.8027259539976,
                40.81063016439715
              ],
              [
                -73.80295467022943,
                40.81096515449443
              ],
              [
                -73.803166607394,
                40.811267696376106
              ],
              [
                -73.80333236911666,
                40.81159984216772
              ],
              [
                -73.80334845213235,
                40.811632068063865
              ],
              [
                -73.80361382016022,
                40.811938282058
              ],
              [
                -73.80390281789289,
                40.81214399837654
              ],
              [
                -73.80421491507515,
                40.812162265824796
              ],
              [
                -73.80438719791255,
                40.8122297932157
              ],
              [
                -73.80454814914059,
                40.81243148190015
              ],
              [
                -73.80479686676797,
                40.812727893160904
              ],
              [
                -73.80505213646254,
                40.812894962434136
              ],
              [
                -73.80519224369245,
                40.81314382111636
              ],
              [
                -73.80518396072651,
                40.81323589220617
              ],
              [
                -73.80517536383367,
                40.813331475425606
              ],
              [
                -73.80516580583347,
                40.813685080692835
              ],
              [
                -73.80519435133107,
                40.81391936791102
              ],
              [
                -73.80522949485474,
                40.814207803093495
              ],
              [
                -73.80531902580653,
                40.81478131299817
              ],
              [
                -73.80567498487898,
                40.815212292188626
              ],
              [
                -73.80635184999934,
                40.815533471107855
              ],
              [
                -73.80659328471772,
                40.815616706154735
              ],
              [
                -73.80661773802603,
                40.81566855824344
              ],
              [
                -73.80656368335475,
                40.81568760275698
              ],
              [
                -73.80641230578708,
                40.81566444938959
              ],
              [
                -73.80640177811462,
                40.81570203064956
              ],
              [
                -73.80657844298469,
                40.81573031518815
              ],
              [
                -73.80658671974201,
                40.81569923127239
              ],
              [
                -73.80662873422997,
                40.81568384071021
              ],
              [
                -73.80676593813136,
                40.815975614513306
              ],
              [
                -73.80679144235306,
                40.816008574204375
              ],
              [
                -73.80679777288513,
                40.81602191978747
              ],
              [
                -73.80694686997859,
                40.81621407857681
              ],
              [
                -73.80704600153508,
                40.81653369387525
              ],
              [
                -73.80695662026791,
                40.81656026698045
              ],
              [
                -73.80694308670084,
                40.816564290547376
              ],
              [
                -73.80694199535742,
                40.81656461422068
              ],
              [
                -73.8068992956903,
                40.81648403113643
              ],
              [
                -73.80671792148993,
                40.816160117391824
              ],
              [
                -73.80655435560061,
                40.815889090149504
              ],
              [
                -73.80653572493937,
                40.815891986386276
              ],
              [
                -73.80656839498864,
                40.815944435256185
              ],
              [
                -73.80659257259929,
                40.8159855709925
              ],
              [
                -73.80659999819879,
                40.815998204767645
              ],
              [
                -73.80666804914216,
                40.816113983888016
              ],
              [
                -73.80669511006323,
                40.81616002537432
              ],
              [
                -73.80669928739789,
                40.816167131911655
              ],
              [
                -73.80672672445573,
                40.816213811285124
              ],
              [
                -73.80673208315861,
                40.816222928423784
              ],
              [
                -73.80675616662523,
                40.8162639019693
              ],
              [
                -73.80676068779914,
                40.81627159591997
              ],
              [
                -73.80678668905028,
                40.8163158311977
              ],
              [
                -73.80679174430693,
                40.816324432853754
              ],
              [
                -73.80679665584827,
                40.816332788342386
              ],
              [
                -73.80685196012053,
                40.81642687966935
              ],
              [
                -73.8068860922784,
                40.81648781099847
              ],
              [
                -73.80692933177552,
                40.81656837907671
              ],
              [
                -73.80660603189658,
                40.81666449404111
              ],
              [
                -73.80657923631605,
                40.81661807521464
              ],
              [
                -73.80658464685322,
                40.8166167970302
              ],
              [
                -73.8065868955687,
                40.81661680080236
              ],
              [
                -73.80671967987072,
                40.81657517074692
              ],
              [
                -73.80670737798758,
                40.81655294253462
              ],
              [
                -73.80661216122775,
                40.81657794543157
              ],
              [
                -73.80658167058803,
                40.81652183764143
              ],
              [
                -73.80657281840718,
                40.8165070281737
              ],
              [
                -73.80655014924943,
                40.81646910357052
              ],
              [
                -73.80654164576909,
                40.81645487817415
              ],
              [
                -73.80651889611549,
                40.81641681790179
              ],
              [
                -73.80651302341059,
                40.81640884391773
              ],
              [
                -73.80649071162662,
                40.816378548646405
              ],
              [
                -73.80648254634458,
                40.81636746054751
              ],
              [
                -73.8064628307186,
                40.81634068882139
              ],
              [
                -73.8064518701059,
                40.81632580611449
              ],
              [
                -73.80642825943642,
                40.81629374762941
              ],
              [
                -73.80641916861939,
                40.81628140304365
              ],
              [
                -73.80639654597478,
                40.816245282896446
              ],
              [
                -73.80632932566019,
                40.81613795932021
              ],
              [
                -73.80621827961798,
                40.816026841830286
              ],
              [
                -73.80610141797764,
                40.815919717456396
              ],
              [
                -73.8060592785209,
                40.81589012535946
              ],
              [
                -73.80591819777831,
                40.81579105535801
              ],
              [
                -73.80589470306954,
                40.81577488333441
              ],
              [
                -73.80588246586188,
                40.815766460022346
              ],
              [
                -73.80574845644438,
                40.81567421796571
              ],
              [
                -73.80571555266238,
                40.815651568452495
              ],
              [
                -73.8056960781262,
                40.81563816447364
              ],
              [
                -73.80564435234865,
                40.815602558846166
              ],
              [
                -73.80562333543433,
                40.81558809333046
              ],
              [
                -73.80556384511613,
                40.81554714376871
              ],
              [
                -73.80554514789834,
                40.815534273468856
              ],
              [
                -73.80543623459857,
                40.81545953364263
              ],
              [
                -73.80539844430555,
                40.815470647458284
              ],
              [
                -73.80538058746234,
                40.81545725436467
              ],
              [
                -73.80535574933889,
                40.81543773750667
              ],
              [
                -73.805295359951,
                40.81539028515077
              ],
              [
                -73.80527878825075,
                40.815377264776224
              ],
              [
                -73.8052425045649,
                40.81534064421272
              ],
              [
                -73.80521866258665,
                40.81531726722484
              ],
              [
                -73.80517384860573,
                40.815268524202764
              ],
              [
                -73.80516130844828,
                40.81525488475045
              ],
              [
                -73.80511150262885,
                40.815200713001595
              ],
              [
                -73.80509851805549,
                40.81518659082201
              ],
              [
                -73.80505018523058,
                40.81513402005794
              ],
              [
                -73.80503703935383,
                40.81511972085984
              ],
              [
                -73.80498880311676,
                40.81506725560581
              ],
              [
                -73.80481419768991,
                40.81487734178963
              ],
              [
                -73.80479626573847,
                40.814856948283
              ],
              [
                -73.80475413212416,
                40.81480903083777
              ],
              [
                -73.80473920794104,
                40.814792059084844
              ],
              [
                -73.80470143568597,
                40.81474910104061
              ],
              [
                -73.80468790441603,
                40.81473371206964
              ],
              [
                -73.80465309753234,
                40.81469412845984
              ],
              [
                -73.80464142045771,
                40.81468084723961
              ],
              [
                -73.80458924572581,
                40.814621511143976
              ],
              [
                -73.80468358440314,
                40.81457194040205
              ],
              [
                -73.80465942815042,
                40.81454498912198
              ],
              [
                -73.80454209268697,
                40.81460836963895
              ],
              [
                -73.80455902583564,
                40.814626139728986
              ],
              [
                -73.80455440518865,
                40.8146298422371
              ],
              [
                -73.80464185020507,
                40.81472374153498
              ],
              [
                -73.80482922720184,
                40.814925178154446
              ],
              [
                -73.80483702030837,
                40.814933620526354
              ],
              [
                -73.8048746512254,
                40.814974386725275
              ],
              [
                -73.80488927230539,
                40.81499022665194
              ],
              [
                -73.80493788840948,
                40.815042891275674
              ],
              [
                -73.80495091220341,
                40.81505700198084
              ],
              [
                -73.80498138535133,
                40.815090012868914
              ],
              [
                -73.80504496718342,
                40.81515889219241
              ],
              [
                -73.80506739580052,
                40.81518318854919
              ],
              [
                -73.80508103466697,
                40.81519796401256
              ],
              [
                -73.8052821809176,
                40.815411173344636
              ],
              [
                -73.8053787878482,
                40.815482620282616
              ],
              [
                -73.80541005396141,
                40.815505275337735
              ],
              [
                -73.80543412145816,
                40.81549900093787
              ],
              [
                -73.80545310964646,
                40.81549861684705
              ],
              [
                -73.80551227589093,
                40.81553890218644
              ],
              [
                -73.80554519963387,
                40.81556131842981
              ],
              [
                -73.8055639454624,
                40.81557408176333
              ],
              [
                -73.80558653716153,
                40.81558946396059
              ],
              [
                -73.8056135268847,
                40.81560783986605
              ],
              [
                -73.80566605855704,
                40.815643607689424
              ],
              [
                -73.80567946981297,
                40.815652738953
              ],
              [
                -73.80571940783229,
                40.815679931875984
              ],
              [
                -73.80573575544753,
                40.81569106179364
              ],
              [
                -73.80578506371808,
                40.81572463491661
              ],
              [
                -73.8057992148723,
                40.815734269149196
              ],
              [
                -73.80581997726753,
                40.81574840552006
              ],
              [
                -73.80583410312231,
                40.815758023120715
              ],
              [
                -73.80587372833129,
                40.815785003543915
              ],
              [
                -73.8058889626789,
                40.81579537569322
              ],
              [
                -73.80592904941429,
                40.81582266981062
              ],
              [
                -73.80594103581132,
                40.81583082968136
              ],
              [
                -73.80597884247236,
                40.815856571344206
              ],
              [
                -73.8059940724492,
                40.81586694129429
              ],
              [
                -73.8060233194087,
                40.8158868540877
              ],
              [
                -73.80608837514092,
                40.81593114701562
              ],
              [
                -73.80611761373622,
                40.81595220932608
              ],
              [
                -73.8061506996536,
                40.81598585820601
              ],
              [
                -73.80623465856394,
                40.816069679988125
              ],
              [
                -73.80630968841191,
                40.81614680715885
              ],
              [
                -73.80654330780881,
                40.81652789731751
              ],
              [
                -73.80654668247519,
                40.81652704994332
              ],
              [
                -73.80658358725697,
                40.81659458816553
              ],
              [
                -73.80656664218886,
                40.81660043734656
              ],
              [
                -73.8065724726017,
                40.816606355392366
              ],
              [
                -73.80654546539031,
                40.81661485334422
              ],
              [
                -73.80641593595035,
                40.81651369035763
              ],
              [
                -73.80628451748318,
                40.81626848689108
              ],
              [
                -73.80619919500408,
                40.816287070772816
              ],
              [
                -73.80596026416015,
                40.8160424492395
              ],
              [
                -73.8058461181963,
                40.81601415814745
              ],
              [
                -73.80528885455031,
                40.81573468025809
              ],
              [
                -73.80523830973368,
                40.81571790550185
              ],
              [
                -73.80519172182187,
                40.81569547258355
              ],
              [
                -73.80515017994553,
                40.81566790594097
              ],
              [
                -73.80511465526511,
                40.815635850028094
              ],
              [
                -73.8050859782673,
                40.81560005424783
              ],
              [
                -73.80506481935052,
                40.815561355432756
              ],
              [
                -73.80505747143667,
                40.81554349284566
              ],
              [
                -73.80504660879434,
                40.815526696180065
              ],
              [
                -73.80503248781558,
                40.8155113618846
              ],
              [
                -73.80501544179707,
                40.815497851891934
              ],
              [
                -73.80499587307426,
                40.81548648507595
              ],
              [
                -73.80495319709797,
                40.81545564196675
              ],
              [
                -73.80491612834518,
                40.81542086565561
              ],
              [
                -73.80488528910851,
                40.815382739958984
              ],
              [
                -73.8048611970971,
                40.81534190492101
              ],
              [
                -73.80480730478178,
                40.815309571254865
              ],
              [
                -73.80475934689049,
                40.81527223036974
              ],
              [
                -73.80471812332812,
                40.815230505093915
              ],
              [
                -73.8046869621925,
                40.815189184241724
              ],
              [
                -73.80464897472457,
                40.81512510503409
              ],
              [
                -73.80461752374775,
                40.81505901320188
              ],
              [
                -73.80460283523365,
                40.81504422819805
              ],
              [
                -73.80447282080358,
                40.81488983215369
              ],
              [
                -73.80440501682179,
                40.81477022485211
              ],
              [
                -73.80436154415412,
                40.81469712818208
              ],
              [
                -73.80435128904165,
                40.81461877682578
              ],
              [
                -73.80432977892738,
                40.81460314258582
              ],
              [
                -73.80436535843762,
                40.81458826393252
              ],
              [
                -73.80438628236728,
                40.814604232053576
              ],
              [
                -73.80443003333578,
                40.8145830626131
              ],
              [
                -73.8043917200654,
                40.81453785620168
              ],
              [
                -73.80435321971795,
                40.81455637926851
              ],
              [
                -73.80436017115758,
                40.814569668414656
              ],
              [
                -73.80431466904517,
                40.814592160480984
              ],
              [
                -73.80418033130341,
                40.814589962811475
              ],
              [
                -73.80388842505995,
                40.81430900826341
              ],
              [
                -73.80356595538284,
                40.813999297010604
              ],
              [
                -73.80351667337368,
                40.813951963648606
              ],
              [
                -73.80319870538818,
                40.81350524057211
              ],
              [
                -73.80322176299285,
                40.81337410994223
              ],
              [
                -73.80329255692035,
                40.81338806964399
              ],
              [
                -73.80335745131454,
                40.81340112448984
              ],
              [
                -73.80335485916852,
                40.81340642122662
              ],
              [
                -73.8034304211,
                40.81342211984285
              ],
              [
                -73.80346026692781,
                40.813452907857055
              ],
              [
                -73.80346343494779,
                40.813458904450336
              ],
              [
                -73.80349891935015,
                40.813496633575944
              ],
              [
                -73.80350374365683,
                40.813501763536735
              ],
              [
                -73.80354113734674,
                40.81354152194913
              ],
              [
                -73.8035482997436,
                40.81354913760569
              ],
              [
                -73.8035847810641,
                40.8135879273277
              ],
              [
                -73.80359077080303,
                40.81359429543636
              ],
              [
                -73.8036267551889,
                40.81363255676223
              ],
              [
                -73.80363275064951,
                40.81363893141183
              ],
              [
                -73.8036701640525,
                40.81367871192804
              ],
              [
                -73.80367439908748,
                40.813683213329725
              ],
              [
                -73.80370996088197,
                40.81372102427258
              ],
              [
                -73.80371743434281,
                40.813728971477154
              ],
              [
                -73.80375575455592,
                40.813769715771556
              ],
              [
                -73.80375932423901,
                40.813773511260365
              ],
              [
                -73.80379652765603,
                40.813813068401444
              ],
              [
                -73.8038036632947,
                40.81382065551698
              ],
              [
                -73.80383895795188,
                40.81385818117211
              ],
              [
                -73.80384261688646,
                40.813862071629224
              ],
              [
                -73.80387071965497,
                40.81389195243698
              ],
              [
                -73.80392654060662,
                40.81395130327641
              ],
              [
                -73.80395955345396,
                40.813988085558265
              ],
              [
                -73.80403602000342,
                40.81396304862276
              ],
              [
                -73.80409350211168,
                40.814012682577726
              ],
              [
                -73.80412441310237,
                40.814040240001034
              ],
              [
                -73.80417015187051,
                40.814081016525826
              ],
              [
                -73.80420271980937,
                40.81411005230107
              ],
              [
                -73.80423729676549,
                40.814140877795836
              ],
              [
                -73.80425937361548,
                40.8141605587425
              ],
              [
                -73.8044336759091,
                40.814318886601356
              ],
              [
                -73.80453081609834,
                40.81425461992442
              ],
              [
                -73.80452231465456,
                40.81424730125912
              ],
              [
                -73.80445407178813,
                40.81429219092678
              ],
              [
                -73.80443465897424,
                40.814293196487256
              ],
              [
                -73.8043747213263,
                40.81424259074795
              ],
              [
                -73.80424913836396,
                40.81412710072005
              ],
              [
                -73.80421121425614,
                40.814092224321634
              ],
              [
                -73.80419191642532,
                40.81407447713419
              ],
              [
                -73.80417701117335,
                40.814060768219996
              ],
              [
                -73.80419080253513,
                40.814052795680546
              ],
              [
                -73.80417379955037,
                40.814038159973684
              ],
              [
                -73.80418428440666,
                40.8140304573973
              ],
              [
                -73.80409073408268,
                40.813951524759666
              ],
              [
                -73.80407143404403,
                40.81396600950239
              ],
              [
                -73.80405436278971,
                40.81395450687946
              ],
              [
                -73.80404393782305,
                40.8139408945177
              ],
              [
                -73.80396704457125,
                40.81396655325541
              ],
              [
                -73.80370197676172,
                40.8136782368844
              ],
              [
                -73.80369692748782,
                40.81367289362502
              ],
              [
                -73.8036399068593,
                40.813612554743706
              ],
              [
                -73.80363362401805,
                40.813605906707025
              ],
              [
                -73.80358389635965,
                40.81355328519059
              ],
              [
                -73.80355921961917,
                40.81352717208713
              ],
              [
                -73.80352565577122,
                40.813491655739064
              ],
              [
                -73.80351937559367,
                40.813485009375675
              ],
              [
                -73.80345039819939,
                40.81341422712505
              ],
              [
                -73.8034551929976,
                40.81340269099047
              ],
              [
                -73.80335865160461,
                40.81338421473085
              ],
              [
                -73.80335641541957,
                40.81339202176539
              ],
              [
                -73.80329365293572,
                40.813375858613625
              ],
              [
                -73.80322449366612,
                40.81335858052122
              ],
              [
                -73.80323201378704,
                40.813315809673725
              ],
              [
                -73.80336399216976,
                40.81334102720879
              ],
              [
                -73.80336795116905,
                40.81333207238363
              ],
              [
                -73.80345177160015,
                40.81335213167165
              ],
              [
                -73.80346101380117,
                40.813329243757465
              ],
              [
                -73.8033496817059,
                40.81330515615793
              ],
              [
                -73.8033443955358,
                40.813320082824134
              ],
              [
                -73.8032354331691,
                40.81329636637064
              ],
              [
                -73.80325294454988,
                40.81319677354991
              ],
              [
                -73.8033003046772,
                40.81316068489423
              ],
              [
                -73.80338820741223,
                40.8131299665213
              ],
              [
                -73.80345921365522,
                40.81305142207793
              ],
              [
                -73.80351370643069,
                40.81293524328938
              ],
              [
                -73.80361455805945,
                40.81297219119157
              ],
              [
                -73.80355921193633,
                40.81304271217233
              ],
              [
                -73.80360189093184,
                40.81305645256865
              ],
              [
                -73.80370612798548,
                40.81316339722425
              ],
              [
                -73.80372561929765,
                40.81318209619964
              ],
              [
                -73.80377802413402,
                40.81323236949504
              ],
              [
                -73.80379871138616,
                40.813252213348065
              ],
              [
                -73.80384850093282,
                40.81329997732783
              ],
              [
                -73.80387189663088,
                40.813322421238645
              ],
              [
                -73.80389636709297,
                40.81334589524031
              ],
              [
                -73.80394338723917,
                40.813391002381074
              ],
              [
                -73.80395040123676,
                40.81339773172135
              ],
              [
                -73.80415866820893,
                40.813597522048475
              ],
              [
                -73.80422899591787,
                40.81366330521166
              ],
              [
                -73.8041423746032,
                40.81371830410692
              ],
              [
                -73.80415365301042,
                40.813730672865105
              ],
              [
                -73.80419004986149,
                40.81370794409653
              ],
              [
                -73.80432127867917,
                40.81363188929205
              ],
              [
                -73.804358103467,
                40.81360853867401
              ],
              [
                -73.80434433653338,
                40.81359676461409
              ],
              [
                -73.80425238349035,
                40.81365044031158
              ],
              [
                -73.80423653201755,
                40.813646527993306
              ],
              [
                -73.80417389009331,
                40.81358773932113
              ],
              [
                -73.80399360549882,
                40.81341111218359
              ],
              [
                -73.803981205966,
                40.81339896380831
              ],
              [
                -73.80394693022657,
                40.81336538363002
              ],
              [
                -73.8038800682624,
                40.813299878414014
              ],
              [
                -73.80385705015105,
                40.81327732809869
              ],
              [
                -73.80379173882275,
                40.81321334056338
              ],
              [
                -73.80377171351131,
                40.81319372114633
              ],
              [
                -73.80370415669779,
                40.81312753398959
              ],
              [
                -73.80368492820668,
                40.813108696077066
              ],
              [
                -73.80361429261028,
                40.813044514928094
              ],
              [
                -73.80377912019877,
                40.81280990604305
              ],
              [
                -73.80373757162269,
                40.81279360802259
              ],
              [
                -73.80362063328042,
                40.81296474081772
              ],
              [
                -73.80351997906509,
                40.81292187125158
              ],
              [
                -73.80355021877938,
                40.81285740190122
              ],
              [
                -73.80364013123157,
                40.81263514480158
              ],
              [
                -73.80365034781317,
                40.81260614051942
              ],
              [
                -73.80365392095106,
                40.81257959356962
              ],
              [
                -73.80364525098142,
                40.812557007836645
              ],
              [
                -73.80362783442257,
                40.81253706297841
              ],
              [
                -73.80361390158114,
                40.812521106621354
              ],
              [
                -73.80357865673949,
                40.81251868448007
              ],
              [
                -73.8035555484133,
                40.81252779548783
              ],
              [
                -73.80354921841081,
                40.81253029003732
              ],
              [
                -73.80352685926309,
                40.81254800706583
              ],
              [
                -73.80351243525232,
                40.81255943733641
              ],
              [
                -73.80347049567364,
                40.81255936585386
              ],
              [
                -73.80346320797932,
                40.81255539280687
              ],
              [
                -73.80343385755597,
                40.81253938769703
              ],
              [
                -73.80340685531824,
                40.81252445472245
              ],
              [
                -73.80332121165736,
                40.81249550149052
              ],
              [
                -73.80323223374798,
                40.812473091920474
              ],
              [
                -73.80314078528485,
                40.81245744353789
              ],
              [
                -73.80304775394252,
                40.81244870823887
              ],
              [
                -73.80295404275847,
                40.81244697081491
              ],
              [
                -73.80286056136796,
                40.81245224813114
              ],
              [
                -73.8027682171769,
                40.812464488961574
              ],
              [
                -73.80267790655299,
                40.8124835744869
              ],
              [
                -73.8025905061269,
                40.81250931944726
              ],
              [
                -73.80250686428272,
                40.81254147394104
              ],
              [
                -73.80242779292391,
                40.81257972584967
              ],
              [
                -73.80235405959267,
                40.8126237038675
              ],
              [
                -73.8022863800205,
                40.8126729811057
              ],
              [
                -73.80225169457027,
                40.81270458850319
              ],
              [
                -73.80221217944803,
                40.81273273386118
              ],
              [
                -73.80216842849373,
                40.81275699420263
              ],
              [
                -73.80212109920605,
                40.81277700493558
              ],
              [
                -73.80206824965333,
                40.81279313454525
              ],
              [
                -73.80201310827442,
                40.81280396492848
              ],
              [
                -73.80195659132497,
                40.81280931612244
              ],
              [
                -73.8017593218082,
                40.81286139698734
              ],
              [
                -73.80155119869595,
                40.81256980959418
              ],
              [
                -73.80140380968845,
                40.8123633086586
              ],
              [
                -73.80138806882769,
                40.81236726411358
              ],
              [
                -73.80139154446515,
                40.81237390961669
              ],
              [
                -73.80133906141197,
                40.81239373439239
              ],
              [
                -73.8013738591895,
                40.81244424632371
              ],
              [
                -73.80142634447695,
                40.812424421536164
              ],
              [
                -73.80151992552115,
                40.81255404711591
              ],
              [
                -73.80174561215799,
                40.81286501791826
              ],
              [
                -73.801458005173,
                40.81294094720315
              ],
              [
                -73.80131991856462,
                40.81326090053892
              ],
              [
                -73.80110647514584,
                40.81353949952995
              ],
              [
                -73.80091593237641,
                40.813761402506564
              ],
              [
                -73.8004882479156,
                40.81386700886416
              ],
              [
                -73.8001114686428,
                40.814024525560605
              ],
              [
                -73.7999467818626,
                40.81444956568942
              ],
              [
                -73.79902816314,
                40.81554113493865
              ],
              [
                -73.798642206466,
                40.81546401593515
              ],
              [
                -73.79811945293483,
                40.8156987850032
              ],
              [
                -73.79771465236787,
                40.81551329274344
              ],
              [
                -73.79744080996127,
                40.81571872968728
              ],
              [
                -73.79738112573526,
                40.815763504895614
              ],
              [
                -73.79740587876202,
                40.815851536133934
              ],
              [
                -73.79759032777322,
                40.816507532070055
              ],
              [
                -73.79765347182345,
                40.816533684805684
              ],
              [
                -73.79775500596446,
                40.81656430857696
              ],
              [
                -73.79786829159976,
                40.816592990977135
              ],
              [
                -73.7979480421167,
                40.816622801752466
              ],
              [
                -73.79798482995983,
                40.81665547358205
              ],
              [
                -73.7980657792349,
                40.81677490177376
              ],
              [
                -73.79809463222344,
                40.816796385879016
              ],
              [
                -73.79812765294622,
                40.816814069979365
              ],
              [
                -73.79816398385059,
                40.81682749481479
              ],
              [
                -73.79820268141646,
                40.816836311739145
              ],
              [
                -73.79824274065875,
                40.816840291774895
              ],
              [
                -73.79832995437337,
                40.81685631503778
              ],
              [
                -73.79841487478996,
                40.81687832849409
              ],
              [
                -73.79849679070325,
                40.81690614778245
              ],
              [
                -73.79934721608103,
                40.817218494274584
              ],
              [
                -73.79945250870377,
                40.817260947321245
              ],
              [
                -73.79956940847994,
                40.81729861114653
              ],
              [
                -73.79968878220974,
                40.81733351415372
              ],
              [
                -73.79980333641196,
                40.81737090411841
              ],
              [
                -73.79989909088276,
                40.817404667434865
              ],
              [
                -73.80001179104401,
                40.817443530705255
              ],
              [
                -73.80012056556171,
                40.817483656730346
              ],
              [
                -73.80022175941981,
                40.81752168902745
              ],
              [
                -73.8003233797814,
                40.81756283391447
              ],
              [
                -73.80041511587254,
                40.81761465286758
              ],
              [
                -73.80051419814835,
                40.817667880441114
              ],
              [
                -73.80057291210369,
                40.81770009533799
              ],
              [
                -73.8005663755435,
                40.81776719362643
              ],
              [
                -73.80056705886216,
                40.81785168024065
              ],
              [
                -73.80058276188892,
                40.8179114283121
              ],
              [
                -73.80058906737113,
                40.81793542809662
              ],
              [
                -73.80061491137691,
                40.817965639480576
              ],
              [
                -73.80070762635494,
                40.81804509879783
              ],
              [
                -73.80081280395602,
                40.81809119590075
              ],
              [
                -73.80092598956914,
                40.81810722246578
              ],
              [
                -73.80102437218615,
                40.81810285470766
              ],
              [
                -73.8010504292954,
                40.81810170186842
              ],
              [
                -73.80111526287806,
                40.818106540093595
              ],
              [
                -73.80118517550201,
                40.818129687279416
              ],
              [
                -73.8012874403864,
                40.818182947143264
              ],
              [
                -73.80139138775395,
                40.81822023599132
              ],
              [
                -73.80149789289162,
                40.81824276150477
              ],
              [
                -73.80161087523832,
                40.81826749431404
              ],
              [
                -73.80171011541323,
                40.81830567455004
              ],
              [
                -73.80181251656832,
                40.818336954427394
              ],
              [
                -73.80188453083599,
                40.818358950889284
              ],
              [
                -73.80193233179318,
                40.81838812871296
              ],
              [
                -73.80197305662682,
                40.81841298026637
              ],
              [
                -73.80203991576222,
                40.81846644869598
              ],
              [
                -73.802045607213,
                40.81854554171547
              ],
              [
                -73.80207892871874,
                40.818556736968574
              ],
              [
                -73.80220279006075,
                40.81853869768866
              ],
              [
                -73.80224832249247,
                40.818509526299124
              ],
              [
                -73.80226765659415,
                40.818497142353344
              ],
              [
                -73.80230024253227,
                40.818484716294485
              ],
              [
                -73.80233422077421,
                40.818494265831504
              ],
              [
                -73.80241341714243,
                40.81851652790629
              ],
              [
                -73.8024681188673,
                40.818540907528245
              ],
              [
                -73.80252163401762,
                40.81852598830584
              ],
              [
                -73.80256135485679,
                40.81848137328552
              ],
              [
                -73.80257434029811,
                40.8184516064342
              ],
              [
                -73.8025867881536,
                40.81842309141102
              ],
              [
                -73.80258966124985,
                40.81838925599239
              ],
              [
                -73.80259400940082,
                40.81833793435091
              ],
              [
                -73.80262323567511,
                40.81826744875063
              ],
              [
                -73.80267407634148,
                40.818238575091385
              ],
              [
                -73.80276008083706,
                40.81823144647568
              ],
              [
                -73.80288339224204,
                40.81823100031589
              ],
              [
                -73.80301362934604,
                40.81823305962993
              ],
              [
                -73.80313418501758,
                40.818238128099956
              ],
              [
                -73.8032484670736,
                40.81824411399361
              ],
              [
                -73.8033684017511,
                40.818251071675974
              ],
              [
                -73.80348320649303,
                40.81827698546656
              ],
              [
                -73.80350917589287,
                40.81828553127121
              ],
              [
                -73.80365193483067,
                40.81834914398076
              ],
              [
                -73.80379053893057,
                40.81841785572151
              ],
              [
                -73.80392467287915,
                40.81849151018598
              ],
              [
                -73.80405403152841,
                40.818569939822176
              ],
              [
                -73.8041783205906,
                40.81865296621511
              ],
              [
                -73.80429725730733,
                40.818740400491954
              ],
              [
                -73.80441057109248,
                40.818832043751954
              ],
              [
                -73.80451800414906,
                40.81892768751854
              ],
              [
                -73.80461931205352,
                40.8190271142137
              ],
              [
                -73.80507202199193,
                40.819487976380756
              ],
              [
                -73.80523559070691,
                40.81965728288172
              ],
              [
                -73.80547386434988,
                40.81990665057202
              ],
              [
                -73.80571008397013,
                40.82015714401186
              ],
              [
                -73.80572802989728,
                40.820177338695004
              ],
              [
                -73.80599159848933,
                40.82082355849007
              ],
              [
                -73.80599142834016,
                40.8208266279261
              ],
              [
                -73.80595822489663,
                40.82142804377335
              ],
              [
                -73.80590748413401,
                40.82148477084862
              ],
              [
                -73.80585139220263,
                40.821546971806285
              ],
              [
                -73.80581748219579,
                40.82167150380356
              ],
              [
                -73.80582465580834,
                40.82176778979303
              ],
              [
                -73.8058623716858,
                40.8218981288477
              ],
              [
                -73.80604677047366,
                40.82227392069624
              ],
              [
                -73.80621136474034,
                40.822609352914775
              ],
              [
                -73.80622264763869,
                40.82266354012839
              ],
              [
                -73.80622242082086,
                40.822741429989435
              ],
              [
                -73.80620054772561,
                40.82278387948665
              ],
              [
                -73.80614763085906,
                40.82281683527486
              ],
              [
                -73.80608232613048,
                40.82283560918261
              ],
              [
                -73.80601079450372,
                40.82285909124417
              ],
              [
                -73.80595169124446,
                40.82288259534941
              ],
              [
                -73.80596714429264,
                40.8229109452442
              ],
              [
                -73.80604792752236,
                40.82291108114822
              ],
              [
                -73.80612875487964,
                40.822897054873074
              ],
              [
                -73.80622518370484,
                40.822859451712446
              ],
              [
                -73.80629982379307,
                40.822835974739654
              ],
              [
                -73.80638355020798,
                40.82289276212969
              ],
              [
                -73.80644539068587,
                40.822996720660086
              ],
              [
                -73.80649171471407,
                40.82309357302469
              ],
              [
                -73.80650507976743,
                40.823136288598555
              ],
              [
                -73.80651018671007,
                40.82315261172039
              ],
              [
                -73.80646027418055,
                40.82322097751142
              ],
              [
                -73.80637938534883,
                40.823256247544435
              ],
              [
                -73.8063016326953,
                40.82328208043145
              ],
              [
                -73.80623630693701,
                40.823307934650714
              ],
              [
                -73.806264174748,
                40.82334102570462
              ],
              [
                -73.80634189402245,
                40.823326994058355
              ],
              [
                -73.80642582751348,
                40.823312973294115
              ],
              [
                -73.80650977491813,
                40.82329423114612
              ],
              [
                -73.80656262901503,
                40.82331927529272
              ],
              [
                -73.80659975771903,
                40.823336868686084
              ],
              [
                -73.80666166000621,
                40.823419584237065
              ],
              [
                -73.80668314901997,
                40.8235093129397
              ],
              [
                -73.80670458478495,
                40.82361792408328
              ],
              [
                -73.80669197728993,
                40.82367927106417
              ],
              [
                -73.8066638763997,
                40.82372643154826
              ],
              [
                -73.80660475663629,
                40.823736307329185
              ],
              [
                -73.80657994221666,
                40.8237404524844
              ],
              [
                -73.80650842971878,
                40.82375685462484
              ],
              [
                -73.80646486788677,
                40.823778025148485
              ],
              [
                -73.80646480610469,
                40.82379926749916
              ],
              [
                -73.80648339363748,
                40.82381818189916
              ],
              [
                -73.80653930859727,
                40.82382299606159
              ],
              [
                -73.8066002188434,
                40.823816490059045
              ],
              [
                -73.80664809366584,
                40.82381137674557
              ],
              [
                -73.80670714973103,
                40.82380439448801
              ],
              [
                -73.80675058060002,
                40.82382807065859
              ],
              [
                -73.80676901809413,
                40.82389891219253
              ],
              [
                -73.80679049436186,
                40.823993361689105
              ],
              [
                -73.80676853145432,
                40.82406649477149
              ],
              [
                -73.80673534271885,
                40.8241270448584
              ],
              [
                -73.80673104693946,
                40.824134881495056
              ],
              [
                -73.80669991403106,
                40.82415607293682
              ],
              [
                -73.80658800807504,
                40.82417240690529
              ],
              [
                -73.80652975478517,
                40.82417364586319
              ],
              [
                -73.80651819407517,
                40.82419799191265
              ],
              [
                -73.80652125397441,
                40.824214519159305
              ],
              [
                -73.80653971012154,
                40.82422625523477
              ],
              [
                -73.80653986288887,
                40.82422635215272
              ],
              [
                -73.80659267091683,
                40.824231161075886
              ],
              [
                -73.80665477291527,
                40.824245426908966
              ],
              [
                -73.80670755354015,
                40.824259677586625
              ],
              [
                -73.80675402432223,
                40.824306962560634
              ],
              [
                -73.80677866215677,
                40.82438253431517
              ],
              [
                -73.80677536296291,
                40.82444861773686
              ],
              [
                -73.80675345538235,
                40.824502870046146
              ],
              [
                -73.80671607190382,
                40.824535851615266
              ],
              [
                -73.80663835217979,
                40.82454988300982
              ],
              [
                -73.80663305925727,
                40.824551977740555
              ],
              [
                -73.8065730253077,
                40.82457573741435
              ],
              [
                -73.80656361554976,
                40.82460640543176
              ],
              [
                -73.80659773251966,
                40.82462770628072
              ],
              [
                -73.80665055458624,
                40.824627794860085
              ],
              [
                -73.80666416253398,
                40.82463077264189
              ],
              [
                -73.80669402711494,
                40.82463730859012
              ],
              [
                -73.80673428399315,
                40.82468458298443
              ],
              [
                -73.80675587036083,
                40.82474126692514
              ],
              [
                -73.80678982983369,
                40.82481685479485
              ],
              [
                -73.8068082607937,
                40.82489005554782
              ],
              [
                -73.8068081786768,
                40.824918339253124
              ],
              [
                -73.80680811003201,
                40.82494198271789
              ],
              [
                -73.80681727303701,
                40.82499628591178
              ],
              [
                -73.80686672775298,
                40.82508606190427
              ],
              [
                -73.80691927004251,
                40.82518292330172
              ],
              [
                -73.80697191452626,
                40.825244380707225
              ],
              [
                -73.80699654610872,
                40.82532231281292
              ],
              [
                -73.80697779368697,
                40.82536004598342
              ],
              [
                -73.80697771152202,
                40.82538837089738
              ],
              [
                -73.80702113005182,
                40.82541676744421
              ],
              [
                -73.80710187725667,
                40.825431064232426
              ],
              [
                -73.80716084578457,
                40.82545476690021
              ],
              [
                -73.80722875093613,
                40.82545562424454
              ],
              [
                -73.80727567651664,
                40.82550216477541
              ],
              [
                -73.80730969098856,
                40.82555886937343
              ],
              [
                -73.80734677251718,
                40.825629742447454
              ],
              [
                -73.80741500869622,
                40.825672341990455
              ],
              [
                -73.80746459514764,
                40.82571727077683
              ],
              [
                -73.80752039504299,
                40.82576220991271
              ],
              [
                -73.80757311562436,
                40.82579770320492
              ],
              [
                -73.80763826655806,
                40.82583321720865
              ],
              [
                -73.80764748561123,
                40.82586863722248
              ],
              [
                -73.80766604765233,
                40.825896992059015
              ],
              [
                -73.80770957571971,
                40.8258876239723
              ],
              [
                -73.80772524164837,
                40.82584280293803
              ],
              [
                -73.80775957168109,
                40.825790932759794
              ],
              [
                -73.80783421319295,
                40.825767454803035
              ],
              [
                -73.80789323724042,
                40.82577227298297
              ],
              [
                -73.80796309299755,
                40.82578174273226
              ],
              [
                -73.80795249362444,
                40.82582557697377
              ],
              [
                -73.80798431158676,
                40.82582757491857
              ],
              [
                -73.80799778085272,
                40.82579036736254
              ],
              [
                -73.80807959655905,
                40.825798547247096
              ],
              [
                -73.80816347858548,
                40.825803407159285
              ],
              [
                -73.8082753447703,
                40.82580123328293
              ],
              [
                -73.80838405054162,
                40.82581793724487
              ],
              [
                -73.80843370571013,
                40.82583926168704
              ],
              [
                -73.80846922650872,
                40.82586033865238
              ],
              [
                -73.80853309675436,
                40.82585358894327
              ],
              [
                -73.80859213406444,
                40.82585368695095
              ],
              [
                -73.80870719042498,
                40.82582319357269
              ],
              [
                -73.8087849392145,
                40.82579971898294
              ],
              [
                -73.80888744462783,
                40.82581169067729
              ],
              [
                -73.80898675442158,
                40.82585434128301
              ],
              [
                -73.80909234633528,
                40.82587339816415
              ],
              [
                -73.80916700777829,
                40.82584283794912
              ],
              [
                -73.80919816842238,
                40.82581220521554
              ],
              [
                -73.80922018271397,
                40.825720188582096
              ],
              [
                -73.80924839089201,
                40.825635263618715
              ],
              [
                -73.80930446315837,
                40.82558578886219
              ],
              [
                -73.80935119369302,
                40.8255433805666
              ],
              [
                -73.80944144497771,
                40.825493962244934
              ],
              [
                -73.8095722864966,
                40.82537616136589
              ],
              [
                -73.8096548114174,
                40.825275535621635
              ],
              [
                -73.80968028036179,
                40.82526297277709
              ],
              [
                -73.80980576678344,
                40.82522312553289
              ],
              [
                -73.80990841221151,
                40.82518552905512
              ],
              [
                -73.81006078637894,
                40.825143294569564
              ],
              [
                -73.8101601978205,
                40.82515053852707
              ],
              [
                -73.81020678524449,
                40.82515769664278
              ],
              [
                -73.81025025884574,
                40.82516721003686
              ],
              [
                -73.81029687386898,
                40.8251649268132
              ],
              [
                -73.81031870564868,
                40.825136637675875
              ],
              [
                -73.8103312351883,
                40.82510125364717
              ],
              [
                -73.81036867618812,
                40.8250470278884
              ],
              [
                -73.81040914396259,
                40.82502113046902
              ],
              [
                -73.81046825426856,
                40.824995264180224
              ],
              [
                -73.81052734503115,
                40.82497647758409
              ],
              [
                -73.81064242541402,
                40.824936540965346
              ],
              [
                -73.81068601764441,
                40.82492378484928
              ],
              [
                -73.81076192559172,
                40.82490157071416
              ],
              [
                -73.81078485286451,
                40.82511380062512
              ],
              [
                -73.81087496294379,
                40.82511394844661
              ],
              [
                -73.81084728678657,
                40.82487249096482
              ],
              [
                -73.81091717931496,
                40.82484035124952
              ],
              [
                -73.8109752916904,
                40.82479782810828
              ],
              [
                -73.81102206082721,
                40.82474125700512
              ],
              [
                -73.81103147525586,
                40.824708227042
              ],
              [
                -73.81105945414954,
                40.824703552548606
              ],
              [
                -73.8111029139569,
                40.82471778543648
              ],
              [
                -73.81112145616824,
                40.82475322061574
              ],
              [
                -73.81114003255594,
                40.82477685441311
              ],
              [
                -73.81116561831503,
                40.824789871760714
              ],
              [
                -73.81116795788567,
                40.824791062503195
              ],
              [
                -73.81119903005481,
                40.824791113380655
              ],
              [
                -73.81120379684033,
                40.82478211456257
              ],
              [
                -73.81121152557873,
                40.82476753080167
              ],
              [
                -73.81121163272373,
                40.824729765749524
              ],
              [
                -73.81121173987003,
                40.82469200019445
              ],
              [
                -73.81119009570455,
                40.82465419902699
              ],
              [
                -73.81118706943631,
                40.8246258701925
              ],
              [
                -73.81119333024951,
                40.82460935833414
              ],
              [
                -73.81122136266126,
                40.824585800688645
              ],
              [
                -73.81126489672882,
                40.82457407017874
              ],
              [
                -73.8113333076674,
                40.82455530009843
              ],
              [
                -73.8113923576503,
                40.82455067635565
              ],
              [
                -73.81145450110277,
                40.82455077798018
              ],
              [
                -73.81155844768891,
                40.82451209023507
              ],
              [
                -73.81161100459448,
                40.82461149170246
              ],
              [
                -73.81164355619616,
                40.824601662268584
              ],
              [
                -73.81158752719122,
                40.82449259986495
              ],
              [
                -73.81165700660038,
                40.82444603382072
              ],
              [
                -73.81167128657385,
                40.82441999628019
              ],
              [
                -73.81169048180882,
                40.824395850757654
              ],
              [
                -73.81171416958549,
                40.82437412898191
              ],
              [
                -73.8117418282521,
                40.82435530930591
              ],
              [
                -73.81177284871205,
                40.8243398061728
              ],
              [
                -73.81183479277101,
                40.82434236051894
              ],
              [
                -73.811896795995,
                40.824340846242265
              ],
              [
                -73.81195839613518,
                40.82433527463219
              ],
              [
                -73.81201913394872,
                40.824325687226406
              ],
              [
                -73.81209843436649,
                40.82433785512184
              ],
              [
                -73.81217559375642,
                40.82435633110879
              ],
              [
                -73.81224974515277,
                40.824380907590964
              ],
              [
                -73.81232005538445,
                40.824411308426264
              ],
              [
                -73.8123857344377,
                40.82444719202964
              ],
              [
                -73.81244604433085,
                40.824488155210986
              ],
              [
                -73.81250030740723,
                40.82453373770477
              ],
              [
                -73.81254791394832,
                40.82458342734158
              ],
              [
                -73.81258832902596,
                40.82463666580285
              ],
              [
                -73.81260988545361,
                40.82467716960712
              ],
              [
                -73.81263921487141,
                40.82471475042109
              ],
              [
                -73.812675650269,
                40.824748553565264
              ],
              [
                -73.81271836302544,
                40.82477781027235
              ],
              [
                -73.8127333911017,
                40.82477847086892
              ],
              [
                -73.81274834915234,
                40.82477718710231
              ],
              [
                -73.8127628043757,
                40.82477399611764
              ],
              [
                -73.81277633851847,
                40.82476899024393
              ],
              [
                -73.81278855997932,
                40.8247623143231
              ],
              [
                -73.81279911513802,
                40.82475416151878
              ],
              [
                -73.81280769858893,
                40.8247447677268
              ],
              [
                -73.81281406197633,
                40.82473440475074
              ],
              [
                -73.81282255867912,
                40.82467968021855
              ],
              [
                -73.81282365660886,
                40.82462458268784
              ],
              [
                -73.81281734431211,
                40.82456968786888
              ],
              [
                -73.81280368776335,
                40.82451556935282
              ],
              [
                -73.81279050434462,
                40.82448082091667
              ],
              [
                -73.81278451728717,
                40.82444494527273
              ],
              [
                -73.81278586920962,
                40.824408797086605
              ],
              [
                -73.81279452789245,
                40.82437323751554
              ],
              [
                -73.81281028704977,
                40.824339113692574
              ],
              [
                -73.81283277124157,
                40.82430723854595
              ],
              [
                -73.8128614448218,
                40.82427837143275
              ],
              [
                -73.81291207987397,
                40.82426338869871
              ],
              [
                -73.81295971743359,
                40.82424352757412
              ],
              [
                -73.81300354722481,
                40.82421912588165
              ],
              [
                -73.81304282374128,
                40.824190598675166
              ],
              [
                -73.81307687892688,
                40.824158431179974
              ],
              [
                -73.81319339244563,
                40.82413798079497
              ],
              [
                -73.81327417399014,
                40.82396666581956
              ],
              [
                -73.81378080191402,
                40.823999920518375
              ],
              [
                -73.8138381424988,
                40.82404144642347
              ],
              [
                -73.81388934712506,
                40.82408736742192
              ],
              [
                -73.8139338355063,
                40.82413716311618
              ],
              [
                -73.81397110346312,
                40.8241902691979
              ],
              [
                -73.81400072863937,
                40.82424608384259
              ],
              [
                -73.81402237528658,
                40.82430397452919
              ],
              [
                -73.81403579807271,
                40.82436328520822
              ],
              [
                -73.81404084486043,
                40.82442334373652
              ],
              [
                -73.81403745843352,
                40.82448346949386
              ],
              [
                -73.8138271853649,
                40.82455801014524
              ],
              [
                -73.81361813317882,
                40.82463450709193
              ],
              [
                -73.8134870982995,
                40.82468924163074
              ],
              [
                -73.813359465185,
                40.824748433206416
              ],
              [
                -73.81323549637867,
                40.82481196006493
              ],
              [
                -73.81319013315122,
                40.82484005592482
              ],
              [
                -73.81314913024612,
                40.82487178418835
              ],
              [
                -73.81311298890206,
                40.824906756999546
              ],
              [
                -73.81308215093006,
                40.82494454683971
              ],
              [
                -73.81308316485993,
                40.82497702021325
              ],
              [
                -73.81309055928445,
                40.82500901337185
              ],
              [
                -73.81310416796303,
                40.82503980700894
              ],
              [
                -73.81312368493748,
                40.82506870878607
              ],
              [
                -73.81314867141117,
                40.82509506889879
              ],
              [
                -73.81317856561382,
                40.825118294686746
              ],
              [
                -73.8132088587443,
                40.82513595603481
              ],
              [
                -73.81324198658949,
                40.82515038461205
              ],
              [
                -73.81327735515306,
                40.82516132170754
              ],
              [
                -73.81301238918822,
                40.825235449993684
              ],
              [
                -73.81300000697752,
                40.825218907807944
              ],
              [
                -73.81293781512854,
                40.825235329045846
              ],
              [
                -73.81294709154035,
                40.82525186737314
              ],
              [
                -73.81289110191149,
                40.825273018985
              ],
              [
                -73.81288179895613,
                40.825265923633005
              ],
              [
                -73.81211350556435,
                40.82555263465939
              ],
              [
                -73.81212588761348,
                40.82556917811197
              ],
              [
                -73.81295635248642,
                40.82527312484057
              ],
              [
                -73.81296590521575,
                40.825294457496376
              ],
              [
                -73.81303402684449,
                40.82527561072115
              ],
              [
                -73.81303096572003,
                40.82525908365102
              ],
              [
                -73.81333522606086,
                40.82517094670755
              ],
              [
                -73.81342574760329,
                40.82520071539207
              ],
              [
                -73.81349093261149,
                40.825224424375584
              ],
              [
                -73.81356857982078,
                40.825236350529536
              ],
              [
                -73.8136442164446,
                40.8252472508123
              ],
              [
                -73.81366797129029,
                40.825250673361865
              ],
              [
                -73.81373629018793,
                40.8252649465927
              ],
              [
                -73.81379523517661,
                40.825298086001474
              ],
              [
                -73.81382927557102,
                40.82534770845478
              ],
              [
                -73.8138415853742,
                40.825390214389905
              ],
              [
                -73.81382905764472,
                40.82542559946842
              ],
              [
                -73.81380340159866,
                40.82546784927636
              ],
              [
                -73.813792607721,
                40.82549425305641
              ],
              [
                -73.81378774081614,
                40.82552165232791
              ],
              [
                -73.81378893626466,
                40.825549284978734
              ],
              [
                -73.8137961608223,
                40.82557638240445
              ],
              [
                -73.81380921354477,
                40.8256021908869
              ],
              [
                -73.81382773137503,
                40.825625992559374
              ],
              [
                -73.81385119924143,
                40.825647125374076
              ],
              [
                -73.8138789643853,
                40.825665001517535
              ],
              [
                -73.81391025451528,
                40.82567912376048
              ],
              [
                -73.8139416195339,
                40.82571703172848
              ],
              [
                -73.81342102197195,
                40.82592501676828
              ],
              [
                -73.81342885926271,
                40.82593498727306
              ],
              [
                -73.8139505407614,
                40.82572915144359
              ],
              [
                -73.81398050035672,
                40.82577600571639
              ],
              [
                -73.81400472461513,
                40.825824736005075
              ],
              [
                -73.8140230126393,
                40.82587493820311
              ],
              [
                -73.81403521275747,
                40.82592619599652
              ],
              [
                -73.81403905208353,
                40.82595630546266
              ],
              [
                -73.81403640754664,
                40.825986489068086
              ],
              [
                -73.81402734925915,
                40.82601594648597
              ],
              [
                -73.81401211739598,
                40.82604389664315
              ],
              [
                -73.81399111582792,
                40.82606959843132
              ],
              [
                -73.81396490141368,
                40.826092370357664
              ],
              [
                -73.8139341692345,
                40.82611160861488
              ],
              [
                -73.813899734164,
                40.8261268030922
              ],
              [
                -73.81386250926184,
                40.82613755090088
              ],
              [
                -73.81382086755549,
                40.82616297313953
              ],
              [
                -73.8137841180907,
                40.8261924278859
              ],
              [
                -73.81375293191677,
                40.82622537729863
              ],
              [
                -73.8137278785009,
                40.82626121972323
              ],
              [
                -73.8132783556247,
                40.826314130059636
              ],
              [
                -73.81327444814539,
                40.82630516167435
              ],
              [
                -73.81291776131222,
                40.82635038912066
              ],
              [
                -73.81292293225121,
                40.82637628741591
              ],
              [
                -73.81328485368158,
                40.82633405572625
              ],
              [
                -73.8132835686149,
                40.82632509158734
              ],
              [
                -73.81372207686618,
                40.82627159251505
              ],
              [
                -73.81370737098622,
                40.82630478711727
              ],
              [
                -73.8136978528011,
                40.826339056541826
              ],
              [
                -73.8136856562489,
                40.82636857599056
              ],
              [
                -73.81367994257492,
                40.82639920922203
              ],
              [
                -73.81368086394365,
                40.82643014047231
              ],
              [
                -73.81368839582821,
                40.82646054604041
              ],
              [
                -73.81370233766253,
                40.826489616222744
              ],
              [
                -73.81372231818152,
                40.82651657687666
              ],
              [
                -73.81374816945775,
                40.82654100133767
              ],
              [
                -73.81377896438124,
                40.82656185725204
              ],
              [
                -73.81381386152117,
                40.82657857475636
              ],
              [
                -73.81383954002625,
                40.82659498296335
              ],
              [
                -73.81386127446576,
                40.82661440280253
              ],
              [
                -73.81387846115314,
                40.826636294880494
              ],
              [
                -73.81389062271647,
                40.82666005113571
              ],
              [
                -73.81389742135792,
                40.82668501172801
              ],
              [
                -73.81389866823578,
                40.82671048336514
              ],
              [
                -73.81389432871104,
                40.826735758559764
              ],
              [
                -73.81388452331007,
                40.82676013527993
              ],
              [
                -73.81386952437761,
                40.82678293644884
              ],
              [
                -73.81384974851248,
                40.8268035287504
              ],
              [
                -73.81382574499777,
                40.82682134022102
              ],
              [
                -73.81379818054299,
                40.82683587613544
              ],
              [
                -73.81376782076715,
                40.826846732749374
              ],
              [
                -73.81370651602658,
                40.82686104455957
              ],
              [
                -73.8136486066307,
                40.826881982209485
              ],
              [
                -73.81359532607138,
                40.82690909972042
              ],
              [
                -73.81354780924852,
                40.8269418194804
              ],
              [
                -73.81350706829701,
                40.826979444546694
              ],
              [
                -73.81347397102728,
                40.827021173490174
              ],
              [
                -73.81345470467141,
                40.827054409336945
              ],
              [
                -73.81341746821232,
                40.827104396661156
              ],
              [
                -73.81337245546887,
                40.82715054520458
              ],
              [
                -73.81332733265431,
                40.827187146920544
              ],
              [
                -73.81327731601434,
                40.82721987364286
              ],
              [
                -73.81322297909766,
                40.827248350084865
              ],
              [
                -73.81307958697005,
                40.82733519027174
              ],
              [
                -73.81294049778498,
                40.827413445256624
              ],
              [
                -73.81279793343212,
                40.827488004691915
              ],
              [
                -73.81265206400504,
                40.8275587796147
              ],
              [
                -73.81267998431142,
                40.82759084117899
              ],
              [
                -73.81270087766386,
                40.827625853450854
              ],
              [
                -73.81271421812419,
                40.82766293510186
              ],
              [
                -73.81271966987296,
                40.8277011527119
              ],
              [
                -73.81271796789207,
                40.82773400099278
              ],
              [
                -73.81270426003739,
                40.82775931905108
              ],
              [
                -73.81268540614411,
                40.82778264975009
              ],
              [
                -73.81266188997422,
                40.82780339445333
              ],
              [
                -73.81263431492006,
                40.827821020877195
              ],
              [
                -73.81260338852059,
                40.82783507674917
              ],
              [
                -73.81256990430887,
                40.82784520141243
              ],
              [
                -73.81253472144937,
                40.8278511350799
              ],
              [
                -73.81249874269284,
                40.827852725500776
              ],
              [
                -73.81246289121182,
                40.82784993186659
              ],
              [
                -73.81239850067199,
                40.8278503341554
              ],
              [
                -73.81233452441523,
                40.82785588810633
              ],
              [
                -73.81227167478252,
                40.827866531878975
              ],
              [
                -73.81221065157155,
                40.82788214696051
              ],
              [
                -73.81215213424491,
                40.827902559485516
              ],
              [
                -73.81193103041976,
                40.8279578538898
              ],
              [
                -73.81170732705306,
                40.828006745094235
              ],
              [
                -73.8115494562914,
                40.827993165022086
              ],
              [
                -73.8113909490106,
                40.82798490548766
              ],
              [
                -73.81123211581864,
                40.82798198267601
              ],
              [
                -73.81107326796136,
                40.827984402314726
              ],
              [
                -73.81106294234102,
                40.827843650439824
              ],
              [
                -73.8118223300786,
                40.82784541192274
              ],
              [
                -73.81182240952798,
                40.82782550729801
              ],
              [
                -73.81180813106737,
                40.82782543575082
              ],
              [
                -73.81172246317686,
                40.827825004424675
              ],
              [
                -73.81170712744435,
                40.82782492778828
              ],
              [
                -73.81161722848229,
                40.82782447573839
              ],
              [
                -73.81159977820816,
                40.827824387927386
              ],
              [
                -73.81150987969025,
                40.82782393529195
              ],
              [
                -73.8114913701604,
                40.82782384188009
              ],
              [
                -73.81140305953447,
                40.82782339778795
              ],
              [
                -73.81139036654251,
                40.82782333364005
              ],
              [
                -73.81129835436784,
                40.82782286967677
              ],
              [
                -73.81128460343885,
                40.827822801608285
              ],
              [
                -73.81119523466404,
                40.82782235126974
              ],
              [
                -73.81117672513534,
                40.827822257807334
              ],
              [
                -73.81109158610842,
                40.82782182905629
              ],
              [
                -73.81107307944718,
                40.82782173558195
              ],
              [
                -73.81098317961307,
                40.827821282533804
              ],
              [
                -73.81096784388194,
                40.8278212057991
              ],
              [
                -73.81087159975854,
                40.82782072082119
              ],
              [
                -73.81027562551783,
                40.82781771683239
              ],
              [
                -73.81027578336358,
                40.82777852407167
              ],
              [
                -73.81025463156841,
                40.82777848927095
              ],
              [
                -73.81016578820224,
                40.82777834473126
              ],
              [
                -73.81014675074867,
                40.82777831339124
              ],
              [
                -73.81005896686955,
                40.82777817051337
              ],
              [
                -73.81003940033308,
                40.827778138786755
              ],
              [
                -73.81003932398117,
                40.827797080653205
              ],
              [
                -73.81025357303955,
                40.82779717689454
              ],
              [
                -73.81025342354486,
                40.82783413271453
              ],
              [
                -73.81104291722447,
                40.8278399863693
              ],
              [
                -73.81105578108608,
                40.82798499568344
              ],
              [
                -73.8109219160017,
                40.82799169144544
              ],
              [
                -73.81078847380294,
                40.82800218232476
              ],
              [
                -73.81069015948461,
                40.828009512996964
              ],
              [
                -73.8105914289227,
                40.82801204598526
              ],
              [
                -73.81049268747769,
                40.82800977088995
              ],
              [
                -73.81039434055502,
                40.82800269705201
              ],
              [
                -73.81029679194,
                40.827990853514585
              ],
              [
                -73.81026961372608,
                40.827948904092985
              ],
              [
                -73.8102352370028,
                40.82791012151226
              ],
              [
                -73.81019428969105,
                40.82787521415669
              ],
              [
                -73.81014751972339,
                40.827844819626264
              ],
              [
                -73.81009578138243,
                40.827819493091425
              ],
              [
                -73.81006571750203,
                40.82783115818529
              ],
              [
                -73.81003838242802,
                40.82784620639562
              ],
              [
                -73.81001443054461,
                40.827864277478895
              ],
              [
                -73.80999443524618,
                40.827884938826074
              ],
              [
                -73.80997887521065,
                40.82790769581818
              ],
              [
                -73.80996812293998,
                40.82793200366774
              ],
              [
                -73.80996243584207,
                40.827957280459984
              ],
              [
                -73.80996195006828,
                40.827982921083546
              ],
              [
                -73.80994476641965,
                40.8280433437766
              ],
              [
                -73.80993254336377,
                40.828104458990914
              ],
              [
                -73.80992532786387,
                40.82816603197872
              ],
              [
                -73.80992515914596,
                40.82817971199262
              ],
              [
                -73.80992799237673,
                40.828193222272205
              ],
              [
                -73.80993374840969,
                40.828206185400404
              ],
              [
                -73.80994226644886,
                40.82821823924444
              ],
              [
                -73.80995330853902,
                40.828229047073116
              ],
              [
                -73.80996656621346,
                40.82823830696328
              ],
              [
                -73.80998166911195,
                40.82824576023401
              ],
              [
                -73.809998195326,
                40.8282511986734
              ],
              [
                -73.81001568318555,
                40.8282544703552
              ],
              [
                -73.81003364415581,
                40.828255483883105
              ],
              [
                -73.81005157648552,
                40.82825421094328
              ],
              [
                -73.81038899186555,
                40.82827282102427
              ],
              [
                -73.81072685676286,
                40.82828590628371
              ],
              [
                -73.81106501438015,
                40.82829346064893
              ],
              [
                -73.81140330778518,
                40.82829548061388
              ],
              [
                -73.81174157998137,
                40.828291965241405
              ],
              [
                -73.81207967398304,
                40.82828291616279
              ],
              [
                -73.81241743288683,
                40.82826833757757
              ],
              [
                -73.81275469994516,
                40.828248236251326
              ],
              [
                -73.81279771740698,
                40.828300709412915
              ],
              [
                -73.81283989701991,
                40.828337837634
              ],
              [
                -73.81287233170012,
                40.828370009061004
              ],
              [
                -73.81287548028874,
                40.82840707392778
              ],
              [
                -73.81285913372197,
                40.828436694705566
              ],
              [
                -73.81283950089782,
                40.8284786642376
              ],
              [
                -73.81282141350364,
                40.82850210002995
              ],
              [
                -73.81281664954315,
                40.82850827461945
              ],
              [
                -73.81281003898482,
                40.828545324148955
              ],
              [
                -73.81280015000063,
                40.82859225045511
              ],
              [
                -73.81283902833232,
                40.828646666695676
              ],
              [
                -73.81286608233691,
                40.82867206961132
              ],
              [
                -73.81288120079817,
                40.82868626570543
              ],
              [
                -73.81293970515073,
                40.8287011848559
              ],
              [
                -73.81297875814674,
                40.8286938369149
              ],
              [
                -73.8130373736062,
                40.82866922461706
              ],
              [
                -73.81308614880481,
                40.82867424515056
              ],
              [
                -73.81313163715156,
                40.82869161392436
              ],
              [
                -73.81314783102388,
                40.82871634649998
              ],
              [
                -73.81311848034197,
                40.82874347609438
              ],
              [
                -73.81306314544747,
                40.82875820947432
              ],
              [
                -73.81305005842924,
                40.828785365413324
              ],
              [
                -73.81305326894844,
                40.828800194854914
              ],
              [
                -73.81307603789213,
                40.82880023176302
              ],
              [
                -73.81309886147173,
                40.82878050405512
              ],
              [
                -73.81312813540961,
                40.828780551495306
              ],
              [
                -73.81315741540365,
                40.82877812812
              ],
              [
                -73.81317040437462,
                40.82878556044469
              ],
              [
                -73.81318011408904,
                40.82880287122954
              ],
              [
                -73.8131605294834,
                40.828827545836795
              ],
              [
                -73.81312472298374,
                40.82883737042055
              ],
              [
                -73.8130824246846,
                40.82884224350567
              ],
              [
                -73.81304009189215,
                40.82885946876583
              ],
              [
                -73.81299450062069,
                40.82887915954427
              ],
              [
                -73.8129651710785,
                40.828898877852495
              ],
              [
                -73.81293061658954,
                40.82891763412877
              ],
              [
                -73.81290321918615,
                40.828953132168536
              ],
              [
                -73.81292591739589,
                40.82897787532666
              ],
              [
                -73.81295192380884,
                40.828982857973706
              ],
              [
                -73.81298393018706,
                40.82898290988225
              ],
              [
                -73.81299420898003,
                40.828982926550694
              ],
              [
                -73.81304629884026,
                40.828965717125634
              ],
              [
                -73.81309840343981,
                40.828943564892654
              ],
              [
                -73.813143988668,
                40.82892634488135
              ],
              [
                -73.81319927513273,
                40.828928905266224
              ],
              [
                -73.81324478514992,
                40.82893886157594
              ],
              [
                -73.81329027328144,
                40.828956229113096
              ],
              [
                -73.81334223828975,
                40.8289834903866
              ],
              [
                -73.8133876638913,
                40.82902309447355
              ],
              [
                -73.81345250898103,
                40.82909731889222
              ],
              [
                -73.81348161595967,
                40.829156661241505
              ],
              [
                -73.81349778929821,
                40.82918880618543
              ],
              [
                -73.81350420409575,
                40.82922093467622
              ],
              [
                -73.81349434254014,
                40.829257978479895
              ],
              [
                -73.81345847285763,
                40.829290038748105
              ],
              [
                -73.81343239701273,
                40.829309761766766
              ],
              [
                -73.8133900852401,
                40.82931957590819
              ],
              [
                -73.81337049362966,
                40.82934672135539
              ],
              [
                -73.81336719226249,
                40.829364009893176
              ],
              [
                -73.81337687307196,
                40.82939120271049
              ],
              [
                -73.8134126382601,
                40.82939620272017
              ],
              [
                -73.81346143480168,
                40.829393810340115
              ],
              [
                -73.81350052277399,
                40.82937410835893
              ],
              [
                -73.8135135811422,
                40.829356835428236
              ],
              [
                -73.81354614192863,
                40.82934453465893
              ],
              [
                -73.81357536743714,
                40.82936187696007
              ],
              [
                -73.8136111049418,
                40.829376758961125
              ],
              [
                -73.81363379708303,
                40.82940397161257
              ],
              [
                -73.81365646178284,
                40.82944106798782
              ],
              [
                -73.81366931947859,
                40.829495443566074
              ],
              [
                -73.81368549299344,
                40.829527587309194
              ],
              [
                -73.81368881870836,
                40.829534579120164
              ],
              [
                -73.81356669955272,
                40.829589547888396
              ],
              [
                -73.81358494476265,
                40.82960764421972
              ],
              [
                -73.81369726279111,
                40.82955233460177
              ],
              [
                -73.81371136287345,
                40.82958198390346
              ],
              [
                -73.81376330779892,
                40.829616656230705
              ],
              [
                -73.81382829192314,
                40.829641467990086
              ],
              [
                -73.81385742042924,
                40.82969339899326
              ],
              [
                -73.81388971246565,
                40.82977745220226
              ],
              [
                -73.81388709503958,
                40.8298210324666
              ],
              [
                -73.81373601310588,
                40.829890004436386
              ],
              [
                -73.81372876508036,
                40.82986497781873
              ],
              [
                -73.81366831284711,
                40.82989128485377
              ],
              [
                -73.81370296001816,
                40.8299330331285
              ],
              [
                -73.8138677501533,
                40.82985736878261
              ],
              [
                -73.81383081071448,
                40.829903360608874
              ],
              [
                -73.81382094990104,
                40.82994040443735
              ],
              [
                -73.81382087385553,
                40.82996758146256
              ],
              [
                -73.81385179398815,
                40.82998846873033
              ],
              [
                -73.81386793788137,
                40.83000312971872
              ],
              [
                -73.81388380732328,
                40.83003477606283
              ],
              [
                -73.8139038412147,
                40.830068161662034
              ],
              [
                -73.81393851750965,
                40.83009879215506
              ],
              [
                -73.81396593536506,
                40.83010856419353
              ],
              [
                -73.81399844134208,
                40.83012165186595
              ],
              [
                -73.81404635686468,
                40.830137878488095
              ],
              [
                -73.81410480065875,
                40.83017549633094
              ],
              [
                -73.81415593309119,
                40.83021032195969
              ],
              [
                -73.81422894014088,
                40.83027436739528
              ],
              [
                -73.81427271193803,
                40.83032446922214
              ],
              [
                -73.81429959295038,
                40.83035010370264
              ],
              [
                -73.81385671977256,
                40.83056144292272
              ],
              [
                -73.81386585293004,
                40.83056701679292
              ],
              [
                -73.81430920681511,
                40.83035927120747
              ],
              [
                -73.81431644801685,
                40.83038707750724
              ],
              [
                -73.8143209286821,
                40.83041452806312
              ],
              [
                -73.81430350529655,
                40.83043569753944
              ],
              [
                -73.8142869880651,
                40.83045373660404
              ],
              [
                -73.81428691048255,
                40.83048153229187
              ],
              [
                -73.81429416266316,
                40.83050516943084
              ],
              [
                -73.81431243591469,
                40.83051353671036
              ],
              [
                -73.81433805012041,
                40.83051357794952
              ],
              [
                -73.81435818431459,
                40.83051083113236
              ],
              [
                -73.81438383728965,
                40.830496974768764
              ],
              [
                -73.81440990918259,
                40.83049533863696
              ],
              [
                -73.8144453576965,
                40.830527533052255
              ],
              [
                -73.81447146717453,
                40.830566602710356
              ],
              [
                -73.81450063252917,
                40.83060556269103
              ],
              [
                -73.81451016521453,
                40.830614047051064
              ],
              [
                -73.81409935105553,
                40.83081615673174
              ],
              [
                -73.81409205231331,
                40.83080919639035
              ],
              [
                -73.81389783360487,
                40.83090755415828
              ],
              [
                -73.81391425388867,
                40.83092425703546
              ],
              [
                -73.81411027925253,
                40.83083424000771
              ],
              [
                -73.8141029873901,
                40.83082450044882
              ],
              [
                -73.8145200857769,
                40.83062287579422
              ],
              [
                -73.8145444348584,
                40.83064454553257
              ],
              [
                -73.81457912170136,
                40.83067239594655
              ],
              [
                -73.81461196249836,
                40.83070580318127
              ],
              [
                -73.81465211672611,
                40.83074061093769
              ],
              [
                -73.81468315863783,
                40.830762896332885
              ],
              [
                -73.81471418896022,
                40.83078935088011
              ],
              [
                -73.81476387691956,
                40.830816799993485
              ],
              [
                -73.81484313432419,
                40.83098612051357
              ],
              [
                -73.8144703368803,
                40.83114145825201
              ],
              [
                -73.81449221012457,
                40.831171140224306
              ],
              [
                -73.81485346914792,
                40.8310081997294
              ],
              [
                -73.8148793738859,
                40.8310300864914
              ],
              [
                -73.81491210654471,
                40.83110240535299
              ],
              [
                -73.81494661465638,
                40.831194183164634
              ],
              [
                -73.8149519676384,
                40.83124283312106
              ],
              [
                -73.81495728330384,
                40.83130537949727
              ],
              [
                -73.81496845670792,
                40.83137887193076
              ],
              [
                -73.81497297607608,
                40.83140267752356
              ],
              [
                -73.81497525883807,
                40.83142075639332
              ],
              [
                -73.8149845118381,
                40.83142693244858
              ],
              [
                -73.81498986889515,
                40.83143050864127
              ],
              [
                -73.8150063166291,
                40.831437483601015
              ],
              [
                -73.81502276436505,
                40.83144445906092
              ],
              [
                -73.8150483259507,
                40.831463955665264
              ],
              [
                -73.8150701972006,
                40.83149456527241
              ],
              [
                -73.81508473776748,
                40.83152933179736
              ],
              [
                -73.81510110443273,
                40.83156549119746
              ],
              [
                -73.81511017129878,
                40.83159469031741
              ],
              [
                -73.81511743613852,
                40.831614158744074
              ],
              [
                -73.81511007088926,
                40.831630823817505
              ],
              [
                -73.81508809871504,
                40.83163634721538
              ],
              [
                -73.81508468995636,
                40.83163755931672
              ],
              [
                -73.81505696355028,
                40.831647415043136
              ],
              [
                -73.815047781245,
                40.8316599085229
              ],
              [
                -73.81503311704115,
                40.831669612305745
              ],
              [
                -73.8150312525411,
                40.83168211701787
              ],
              [
                -73.81504219117492,
                40.83169603271601
              ],
              [
                -73.81507143847716,
                40.831705807421635
              ],
              [
                -73.81509887669334,
                40.83170863014218
              ],
              [
                -73.81511886641741,
                40.83170941705418
              ],
              [
                -73.81513389129239,
                40.83173271194591
              ],
              [
                -73.81515181352549,
                40.83175318718669
              ],
              [
                -73.81519564015055,
                40.83178383195662
              ],
              [
                -73.81523578077928,
                40.8318241986078
              ],
              [
                -73.81528686987531,
                40.83187570053323
              ],
              [
                -73.81531774665558,
                40.8319577440986
              ],
              [
                -73.81533585027684,
                40.83202726000354
              ],
              [
                -73.81534851485645,
                40.83207870036741
              ],
              [
                -73.81534473691308,
                40.8321217765506
              ],
              [
                -73.81535012883155,
                40.832156528389326
              ],
              [
                -73.81536178096941,
                40.83218217994231
              ],
              [
                -73.81536778603407,
                40.83220016860216
              ],
              [
                -73.81538475036352,
                40.832208003900966
              ],
              [
                -73.81541947560144,
                40.832221957636506
              ],
              [
                -73.81545415504173,
                40.832252587162245
              ],
              [
                -73.81548695785708,
                40.83229989105653
              ],
              [
                -73.81550148340233,
                40.832340216626605
              ],
              [
                -73.81551415742112,
                40.83238887725339
              ],
              [
                -73.81552445059262,
                40.83242168308638
              ],
              [
                -73.81552626574158,
                40.83244031603443
              ],
              [
                -73.81558896401555,
                40.8325912046098
              ],
              [
                -73.81561069891927,
                40.83267091744394
              ],
              [
                -73.81565687208857,
                40.83273584511138
              ],
              [
                -73.81564455576138,
                40.83277844427898
              ],
              [
                -73.8156396258708,
                40.83279696542463
              ],
              [
                -73.81566150552952,
                40.832824796199446
              ],
              [
                -73.81570045065659,
                40.83285635786962
              ],
              [
                -73.81573931591329,
                40.83291756902325
              ],
              [
                -73.81575868443079,
                40.83297133606776
              ],
              [
                -73.81576099019283,
                40.83301951722804
              ],
              [
                -73.81578517553721,
                40.833095529137616
              ],
              [
                -73.81580692689249,
                40.83316968282723
              ],
              [
                -73.8158140512651,
                40.833240107180515
              ],
              [
                -73.81581632050575,
                40.83330125985278
              ],
              [
                -73.81580595261715,
                40.83335557175826
              ],
              [
                -73.81580394394058,
                40.833366094436535
              ],
              [
                -73.81577942432305,
                40.8334105262718
              ],
              [
                -73.81574031307859,
                40.83343825858438
              ],
              [
                -73.8157110123089,
                40.833447476698666
              ],
              [
                -73.8156987722173,
                40.83346228086112
              ],
              [
                -73.81570846020288,
                40.83348823831641
              ],
              [
                -73.8157328297484,
                40.83349754235347
              ],
              [
                -73.81575969200951,
                40.833488320170616
              ],
              [
                -73.81578412892186,
                40.83347353498157
              ],
              [
                -73.81581094367175,
                40.83348099075928
              ],
              [
                -73.81583040473905,
                40.833501403513104
              ],
              [
                -73.81584739072106,
                40.83353478505744
              ],
              [
                -73.81587121741998,
                40.8335691424606
              ],
              [
                -73.81589473438342,
                40.83360640172238
              ],
              [
                -73.81593920982324,
                40.83367686641551
              ],
              [
                -73.81598684286708,
                40.833901590474646
              ],
              [
                -73.81588801171014,
                40.83392519154063
              ],
              [
                -73.81589483304369,
                40.83393979513451
              ],
              [
                -73.81599215149127,
                40.83391958016274
              ],
              [
                -73.8160119668146,
                40.8339808052108
              ],
              [
                -73.81601183016511,
                40.834030219292856
              ],
              [
                -73.81602034962135,
                40.83408623390266
              ],
              [
                -73.81603747121723,
                40.83416861480527
              ],
              [
                -73.81603732548106,
                40.83422132189588
              ],
              [
                -73.81602840454023,
                40.83431025082214
              ],
              [
                -73.81602829523239,
                40.83434978118101
              ],
              [
                -73.81600703303407,
                40.83437790641613
              ],
              [
                -73.81599347092263,
                40.83439584455293
              ],
              [
                -73.81588930522145,
                40.83442203088624
              ],
              [
                -73.81577214678626,
                40.83444160965314
              ],
              [
                -73.81570270666533,
                40.834457969193664
              ],
              [
                -73.81566361790196,
                40.8344776720744
              ],
              [
                -73.81566358139244,
                40.834490849307016
              ],
              [
                -73.81568088451039,
                40.834507347444124
              ],
              [
                -73.81571124482586,
                40.83450739596075
              ],
              [
                -73.81577201908263,
                40.83448772837588
              ],
              [
                -73.81583274903504,
                40.834484531171
              ],
              [
                -73.81591952032647,
                40.83447478760987
              ],
              [
                -73.81597156677697,
                40.834474870668984
              ],
              [
                -73.81601039495825,
                40.83448148817769
              ],
              [
                -73.81601058212841,
                40.83448152080838
              ],
              [
                -73.8160408429049,
                40.83451780424187
              ],
              [
                -73.81608401401107,
                40.83459034626523
              ],
              [
                -73.81609245230891,
                40.83467600867484
              ],
              [
                -73.81607054775074,
                40.83475503363249
              ],
              [
                -73.8160617003037,
                40.834817608928276
              ],
              [
                -73.81602309893778,
                40.83487270907347
              ],
              [
                -73.8160225096872,
                40.83487354860254
              ],
              [
                -73.81600068763353,
                40.83492292691015
              ],
              [
                -73.81600054186795,
                40.83497563349135
              ],
              [
                -73.81602744008109,
                40.83500641676502
              ],
              [
                -73.81604377742967,
                40.83502511507667
              ],
              [
                -73.81609138692201,
                40.83506142783821
              ],
              [
                -73.81610422427252,
                40.83512403720764
              ],
              [
                -73.81610833453743,
                40.83520639784041
              ],
              [
                -73.81609076772055,
                40.83528543088402
              ],
              [
                -73.81608188313594,
                40.83536118240024
              ],
              [
                -73.81603998826779,
                40.835392869803144
              ],
              [
                -73.81602538032784,
                40.835403917006275
              ],
              [
                -73.81596024072202,
                40.835433461016734
              ],
              [
                -73.81585173701401,
                40.83545964089439
              ],
              [
                -73.81575628058062,
                40.83547266590019
              ],
              [
                -73.81569554972249,
                40.835475863064254
              ],
              [
                -73.81560880514262,
                40.835475724413065
              ],
              [
                -73.81557836264211,
                40.835505324177674
              ],
              [
                -73.81557397060101,
                40.8355250818311
              ],
              [
                -73.81559997608781,
                40.83553171130298
              ],
              [
                -73.81564334731141,
                40.83553178063829
              ],
              [
                -73.81567804623124,
                40.8355318360979
              ],
              [
                -73.81572147374337,
                40.83551214081741
              ],
              [
                -73.81579086935717,
                40.83551225167565
              ],
              [
                -73.8158776139851,
                40.83551239018993
              ],
              [
                -73.8159253513381,
                40.835502583129696
              ],
              [
                -73.81598173584557,
                40.83550267310647
              ],
              [
                -73.81600773070701,
                40.835512597838644
              ],
              [
                -73.81603801016679,
                40.835542293405226
              ],
              [
                -73.8160451953109,
                40.835553266143464
              ],
              [
                -73.81607256205915,
                40.83559505531963
              ],
              [
                -73.81607673539797,
                40.83565435717539
              ],
              [
                -73.81607658058105,
                40.83571035910466
              ],
              [
                -73.81606339574223,
                40.835772927473656
              ],
              [
                -73.81605249592852,
                40.83577807787728
              ],
              [
                -73.81599390874709,
                40.83580575876498
              ],
              [
                -73.81590709059596,
                40.835831973289345
              ],
              [
                -73.81579426718272,
                40.83585155782468
              ],
              [
                -73.81572047919107,
                40.835871205799
              ],
              [
                -73.81567705144427,
                40.83589090107792
              ],
              [
                -73.81561200118198,
                40.83588750290262
              ],
              [
                -73.8155425777741,
                40.83589727565455
              ],
              [
                -73.81550346830673,
                40.83592356566443
              ],
              [
                -73.81550338608844,
                40.835953213969525
              ],
              [
                -73.81552500922504,
                40.83597630692601
              ],
              [
                -73.81556838162061,
                40.835976376290915
              ],
              [
                -73.81562480304432,
                40.835963290218146
              ],
              [
                -73.81565956542137,
                40.8359402857404
              ],
              [
                -73.81571163104587,
                40.83593378105565
              ],
              [
                -73.81578540086508,
                40.83592072212072
              ],
              [
                -73.81585050609583,
                40.83590435561086
              ],
              [
                -73.81588948558863,
                40.83592418251606
              ],
              [
                -73.81592410268253,
                40.835953886207
              ],
              [
                -73.81598036912192,
                40.835996800713865
              ],
              [
                -73.81606068217324,
                40.836030196228414
              ],
              [
                -73.8172043459999,
                40.83574058099987
              ],
              [
                -73.81804583499988,
                40.8355423629999
              ],
              [
                -73.81890004099992,
                40.83534343599992
              ],
              [
                -73.81977578099998,
                40.83513283999992
              ],
              [
                -73.82062699699992,
                40.83492251499994
              ],
              [
                -73.82150930799995,
                40.83473357299988
              ],
              [
                -73.82223130099996,
                40.8345560129999
              ],
              [
                -73.822580515,
                40.8344787019999
              ],
              [
                -73.82265928699987,
                40.8344698719999
              ],
              [
                -73.82309210499987,
                40.834421378999934
              ],
              [
                -73.82319485499993,
                40.83440178799989
              ],
              [
                -73.8232749899999,
                40.8343881879999
              ],
              [
                -73.82368336699984,
                40.83431888899992
              ],
              [
                -73.82379383199985,
                40.83430014699991
              ],
              [
                -73.82463346500003,
                40.833855807999925
              ],
              [
                -73.82489104199995,
                40.8342277519999
              ],
              [
                -73.82515883900005,
                40.83461447199995
              ],
              [
                -73.82601252600003,
                40.83402077799985
              ],
              [
                -73.82612415599982,
                40.83421416499987
              ],
              [
                -73.82622087800003,
                40.834354707999864
              ],
              [
                -73.82604118599988,
                40.83450929499993
              ],
              [
                -73.8258777559998,
                40.83467404699993
              ],
              [
                -73.82573143499985,
                40.83484818899991
              ],
              [
                -73.82560350399983,
                40.83503014699991
              ],
              [
                -73.8254949649998,
                40.83521822399992
              ],
              [
                -73.82544607399976,
                40.83536112899986
              ],
              [
                -73.8254156899999,
                40.83550885499988
              ],
              [
                -73.82540482599977,
                40.835659274999955
              ],
              [
                -73.82541397499978,
                40.83581014899989
              ],
              [
                -73.82544296399993,
                40.83595917099997
              ],
              [
                -73.82549108799996,
                40.83610415299994
              ],
              [
                -73.82555705999988,
                40.836243043999914
              ],
              [
                -73.82565389199993,
                40.836442047999874
              ],
              [
                -73.82573525099993,
                40.83664539499995
              ],
              [
                -73.82580066799997,
                40.83685202199997
              ],
              [
                -73.8258498599999,
                40.83706086699989
              ],
              [
                -73.82588663799989,
                40.83812148899992
              ],
              [
                -73.82590798500001,
                40.838744817999896
              ],
              [
                -73.82596226799994,
                40.83997641699988
              ],
              [
                -73.82596550399984,
                40.840326137999924
              ],
              [
                -73.825986869,
                40.84067575199989
              ]
            ]
          ],
          [
            [
              [
                -73.83960098545434,
                40.83567071869859
              ],
              [
                -73.83960850400152,
                40.83570805084221
              ],
              [
                -73.83962599488439,
                40.83569971218478
              ],
              [
                -73.83961672561374,
                40.835667752627316
              ],
              [
                -73.83960098545434,
                40.83567071869859
              ]
            ]
          ],
          [
            [
              [
                -73.83979488581072,
                40.83561920724633
              ],
              [
                -73.83974862799982,
                40.83555882699994
              ],
              [
                -73.83972396100002,
                40.83547854199989
              ],
              [
                -73.83970551900002,
                40.83539354299991
              ],
              [
                -73.83967775499995,
                40.83530853299991
              ],
              [
                -73.83964701399998,
                40.835169230999966
              ],
              [
                -73.83963165699996,
                40.83509367899995
              ],
              [
                -73.83963502599988,
                40.83498510899992
              ],
              [
                -73.83961961899993,
                40.83493079999994
              ],
              [
                -73.83962906799987,
                40.83487888599994
              ],
              [
                -73.83964694899996,
                40.83484286999988
              ],
              [
                -73.83964780499996,
                40.834841145999924
              ],
              [
                -73.83959266810578,
                40.83456047582328
              ],
              [
                -73.83948374192396,
                40.83457420079437
              ],
              [
                -73.83948681004493,
                40.83459072830833
              ],
              [
                -73.8394122031086,
                40.834600065107395
              ],
              [
                -73.83941519123677,
                40.83464963615139
              ],
              [
                -73.83944316529578,
                40.83464731518525
              ],
              [
                -73.83946474201342,
                40.8347205149183
              ],
              [
                -73.83959530972285,
                40.83470181460231
              ],
              [
                -73.83960456474958,
                40.83473015128698
              ],
              [
                -73.83946467367059,
                40.8347488386364
              ],
              [
                -73.83947085592368,
                40.834763010572
              ],
              [
                -73.83960141648534,
                40.83474666847674
              ],
              [
                -73.8396137738246,
                40.834777369890524
              ],
              [
                -73.83947386622093,
                40.83480314031714
              ],
              [
                -73.83947693983029,
                40.834817305077515
              ],
              [
                -73.839619931812,
                40.83480098193838
              ],
              [
                -73.83961986353515,
                40.834829305656086
              ],
              [
                -73.83946132669129,
                40.83484796824394
              ],
              [
                -73.83947681454364,
                40.83486923220015
              ],
              [
                -73.83954830742064,
                40.83486225144243
              ],
              [
                -73.83955130770877,
                40.83490710149925
              ],
              [
                -73.83948291051087,
                40.83491880806814
              ],
              [
                -73.83948288203746,
                40.83493060977055
              ],
              [
                -73.83955747137699,
                40.83492835364492
              ],
              [
                -73.83956666839917,
                40.834980293734695
              ],
              [
                -73.83950138527082,
                40.83498964305827
              ],
              [
                -73.83950134541065,
                40.83500616624555
              ],
              [
                -73.83945783797591,
                40.83500610569194
              ],
              [
                -73.8394577753272,
                40.83503206833109
              ],
              [
                -73.83949818571932,
                40.8350274042466
              ],
              [
                -73.83949815155427,
                40.835041566356196
              ],
              [
                -73.83957586742348,
                40.835032232653354
              ],
              [
                -73.8395943764547,
                40.83508890719298
              ],
              [
                -73.83950733344857,
                40.835100587842255
              ],
              [
                -73.8395104064141,
                40.83511475377276
              ],
              [
                -73.83958501303327,
                40.8351054162397
              ],
              [
                -73.83960353479867,
                40.835157369294045
              ],
              [
                -73.83951337120288,
                40.835173765936
              ],
              [
                -73.83951955898183,
                40.835185576287614
              ],
              [
                -73.83960659574576,
                40.835176255533234
              ],
              [
                -73.8396189076475,
                40.8352258400356
              ],
              [
                -73.83953185811168,
                40.83523988110952
              ],
              [
                -73.83953492671941,
                40.83525640744827
              ],
              [
                -73.8396033299459,
                40.835242340441845
              ],
              [
                -73.83961561208373,
                40.835303726643794
              ],
              [
                -73.8395441244311,
                40.835308347537904
              ],
              [
                -73.83954719172159,
                40.835324873874214
              ],
              [
                -73.83963111649646,
                40.83531791035986
              ],
              [
                -73.83963722333964,
                40.83536276422769
              ],
              [
                -73.8395563898934,
                40.835376813962874
              ],
              [
                -73.83956877446222,
                40.83539571434489
              ],
              [
                -73.83965271639111,
                40.835381669425395
              ],
              [
                -73.83966499992795,
                40.835443053947344
              ],
              [
                -73.83957796718978,
                40.83545001483886
              ],
              [
                -73.83957792735119,
                40.83546653685204
              ],
              [
                -73.83966494873249,
                40.83546429744529
              ],
              [
                -73.83966798828091,
                40.835492625480214
              ],
              [
                -73.83959649407355,
                40.83549960798568
              ],
              [
                -73.83959955569631,
                40.835518494224885
              ],
              [
                -73.83968659420826,
                40.83550917341249
              ],
              [
                -73.83969886642244,
                40.83557527991654
              ],
              [
                -73.83961491790703,
                40.8355916852755
              ],
              [
                -73.8396148723857,
                40.83561056836577
              ],
              [
                -73.83967394092852,
                40.83560120866407
              ],
              [
                -73.83979488581072,
                40.83561920724633
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0000388703351209,
        "objectid": 209,
        "shape_leng": 0.0306953113473,
        "location_id": 209,
        "zone": "Seaport",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -74.00499805099996,
                40.71201189699988
              ],
              [
                -74.00510989899993,
                40.712055593999914
              ],
              [
                -74.00518548699988,
                40.71207123299992
              ],
              [
                -74.00522812799994,
                40.71207345199987
              ],
              [
                -74.00538198399992,
                40.7120767429999
              ],
              [
                -74.00543986499996,
                40.7120785329999
              ],
              [
                -74.00573499299995,
                40.71197868799989
              ],
              [
                -74.00589174999999,
                40.71200715999993
              ],
              [
                -74.00607752399999,
                40.7119623529999
              ],
              [
                -74.00698584099997,
                40.71173749899987
              ],
              [
                -74.00738921499999,
                40.71164226899988
              ],
              [
                -74.00746749399993,
                40.71156690299989
              ],
              [
                -74.00796787799999,
                40.71144733199986
              ],
              [
                -74.00834760999993,
                40.71135854599988
              ],
              [
                -74.00860085399992,
                40.711392239999945
              ],
              [
                -74.00906264699988,
                40.71088670399995
              ],
              [
                -74.00774466499993,
                40.71022077299991
              ],
              [
                -74.00705335799995,
                40.7098719509999
              ],
              [
                -74.00643549199992,
                40.70955732199991
              ],
              [
                -74.00551252899992,
                40.70909340799993
              ],
              [
                -74.00526217199993,
                40.70892071099994
              ],
              [
                -74.00505672799989,
                40.70878876899996
              ],
              [
                -74.00478782699994,
                40.708401892999895
              ],
              [
                -74.00432857299997,
                40.70782349599988
              ],
              [
                -74.00405660199985,
                40.707436780999934
              ],
              [
                -74.00392127499991,
                40.70723974499993
              ],
              [
                -74.00372234799997,
                40.70693234399992
              ],
              [
                -74.00360537999994,
                40.70678059299992
              ],
              [
                -74.00316110399999,
                40.706232014999884
              ],
              [
                -74.00306957,
                40.70614280299989
              ],
              [
                -74.00302615299988,
                40.70610867499988
              ],
              [
                -74.00299474599991,
                40.70608024599991
              ],
              [
                -74.0029653009999,
                40.706041688999925
              ],
              [
                -74.00296019099989,
                40.70603499799994
              ],
              [
                -74.00295417200002,
                40.706027114999934
              ],
              [
                -74.00256965079724,
                40.705523579761746
              ],
              [
                -74.00215818324273,
                40.705357119059194
              ],
              [
                -74.00212278743204,
                40.70537900283618
              ],
              [
                -74.00206282142268,
                40.70541607717397
              ],
              [
                -74.002061425319,
                40.70541700532031
              ],
              [
                -74.00205517713985,
                40.7054113499346
              ],
              [
                -74.00183509991501,
                40.7052122589112
              ],
              [
                -74.0014608578281,
                40.70487371790605
              ],
              [
                -74.00145437409415,
                40.70487330321598
              ],
              [
                -74.00143661179398,
                40.704872177705234
              ],
              [
                -74.00082417549918,
                40.70526711251571
              ],
              [
                -74.0005924020934,
                40.7054100794135
              ],
              [
                -74.0005829704798,
                40.7054339336489
              ],
              [
                -74.00140581495832,
                40.706178612883114
              ],
              [
                -74.00140666691954,
                40.706179388020644
              ],
              [
                -74.00140533024056,
                40.706180135207944
              ],
              [
                -74.00098276691728,
                40.70641671299288
              ],
              [
                -74.00117602079669,
                40.70662757435901
              ],
              [
                -74.00117643567683,
                40.70663505879536
              ],
              [
                -74.00116913341533,
                40.70663949766775
              ],
              [
                -74.00117521610974,
                40.70664874526917
              ],
              [
                -74.0011603902257,
                40.70666372083431
              ],
              [
                -74.00122701299958,
                40.706734869906434
              ],
              [
                -74.00123213691963,
                40.706740336930935
              ],
              [
                -74.00122367538816,
                40.70674526164779
              ],
              [
                -74.0011313057719,
                40.70679905068455
              ],
              [
                -74.00118685262835,
                40.70685982577176
              ],
              [
                -74.00088259097619,
                40.707025253300515
              ],
              [
                -74.00084973367683,
                40.70704311913909
              ],
              [
                -74.00077175219094,
                40.707080148200696
              ],
              [
                -74.0005524793205,
                40.707184275922934
              ],
              [
                -74.0004002783203,
                40.70726027957068
              ],
              [
                -74.00023380525755,
                40.70734714248689
              ],
              [
                -74.00013868859138,
                40.707405054407545
              ],
              [
                -73.99999645016015,
                40.70749164789845
              ],
              [
                -73.99997222648798,
                40.70750639882204
              ],
              [
                -73.99995671262306,
                40.70753119885585
              ],
              [
                -73.99994855952315,
                40.70755869155379
              ],
              [
                -73.99994505655117,
                40.70758262698328
              ],
              [
                -73.99994855949784,
                40.707591496371016
              ],
              [
                -73.99988297085214,
                40.7076240860353
              ],
              [
                -73.99960948039234,
                40.70775997317624
              ],
              [
                -73.99957149513773,
                40.70771719818587
              ],
              [
                -73.99955121325492,
                40.7076943527765
              ],
              [
                -73.99953839659445,
                40.70769347002565
              ],
              [
                -73.99949062474623,
                40.707721845224945
              ],
              [
                -73.99951211364748,
                40.70774870660909
              ],
              [
                -73.99952324941056,
                40.70776262865822
              ],
              [
                -73.99946033075176,
                40.70779632645101
              ],
              [
                -73.99942129138608,
                40.70781893705527
              ],
              [
                -73.99938225177354,
                40.707841549321465
              ],
              [
                -73.9993263267542,
                40.70787967649388
              ],
              [
                -73.99925945470767,
                40.7079140213104
              ],
              [
                -73.99919451154408,
                40.70794737617572
              ],
              [
                -73.99928731099999,
                40.708016602999926
              ],
              [
                -73.99928734399988,
                40.708016626999886
              ],
              [
                -73.99936195599989,
                40.70807228699991
              ],
              [
                -73.99939954999992,
                40.70810033799996
              ],
              [
                -73.99944281399992,
                40.70813655699987
              ],
              [
                -73.99956273399984,
                40.70823648299993
              ],
              [
                -73.99981193899987,
                40.70844261399988
              ],
              [
                -73.9999293639999,
                40.70853681699991
              ],
              [
                -74.00000765499989,
                40.70859961899986
              ],
              [
                -74.000123591,
                40.70868451899993
              ],
              [
                -74.000191031,
                40.708733911999886
              ],
              [
                -74.00026566699997,
                40.708792949999896
              ],
              [
                -74.00055004899994,
                40.709017916999905
              ],
              [
                -74.00134439799994,
                40.709646292999906
              ],
              [
                -74.00147140299997,
                40.709746553999885
              ],
              [
                -74.00155908199999,
                40.70981710799992
              ],
              [
                -74.00173688899991,
                40.70996016999993
              ],
              [
                -74.00223869799997,
                40.710363931999886
              ],
              [
                -74.0025796699999,
                40.71063789699985
              ],
              [
                -74.0033244169999,
                40.71123748299989
              ],
              [
                -74.00336407499992,
                40.71126941399996
              ],
              [
                -74.00366118299998,
                40.711487282999954
              ],
              [
                -74.00382358799995,
                40.71155186199991
              ],
              [
                -74.00418143899992,
                40.71168714199992
              ],
              [
                -74.00442860399994,
                40.71178622599991
              ],
              [
                -74.00490674599992,
                40.711972278999966
              ],
              [
                -74.00499805099996,
                40.71201189699988
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000453155131253,
        "objectid": 210,
        "shape_leng": 0.114851983372,
        "location_id": 210,
        "zone": "Sheepshead Bay",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.92759936700004,
                40.5950607789999
              ],
              [
                -73.92866850999998,
                40.59602045099991
              ],
              [
                -73.92956858299992,
                40.59682026499993
              ],
              [
                -73.929124371,
                40.5971073039999
              ],
              [
                -73.92879010099999,
                40.59732329499991
              ],
              [
                -73.92813139699999,
                40.59777792799987
              ],
              [
                -73.92992224899993,
                40.59943738199992
              ],
              [
                -73.93173390199983,
                40.601062068999894
              ],
              [
                -73.9335511719998,
                40.602687280999916
              ],
              [
                -73.93426266699994,
                40.60223071099988
              ],
              [
                -73.93503274599996,
                40.60173378999984
              ],
              [
                -73.93447158899997,
                40.601231511999906
              ],
              [
                -73.93525981999983,
                40.6011423479999
              ],
              [
                -73.93619686399984,
                40.60105506999985
              ],
              [
                -73.93715062999982,
                40.600948865999925
              ],
              [
                -73.93811308699988,
                40.60084375699994
              ],
              [
                -73.93904134699991,
                40.60074143199989
              ],
              [
                -73.93952543799986,
                40.600688096999896
              ],
              [
                -73.93996699299997,
                40.600639447999875
              ],
              [
                -73.94089263599993,
                40.60053783599993
              ],
              [
                -73.9411660979999,
                40.60050801599989
              ],
              [
                -73.94189057599992,
                40.600427418999885
              ],
              [
                -73.94288954099984,
                40.600318649999934
              ],
              [
                -73.94283742399996,
                40.60004569399995
              ],
              [
                -73.94278945999999,
                40.599794549999956
              ],
              [
                -73.94248473299992,
                40.598200364999855
              ],
              [
                -73.94341159099987,
                40.598097814999896
              ],
              [
                -73.94387596499999,
                40.59804616299987
              ],
              [
                -73.94433922599985,
                40.597994625999945
              ],
              [
                -73.94479667499994,
                40.59794473299995
              ],
              [
                -73.94526519199995,
                40.5978936269999
              ],
              [
                -73.94622941199994,
                40.59778899199995
              ],
              [
                -73.94719207000004,
                40.597682086999946
              ],
              [
                -73.948120131,
                40.597579399999866
              ],
              [
                -73.94855032699991,
                40.59753296399993
              ],
              [
                -73.94904681599999,
                40.59747936199991
              ],
              [
                -73.94997334599992,
                40.59737744199987
              ],
              [
                -73.9510058549999,
                40.597262618999906
              ],
              [
                -73.95220370799984,
                40.59713035099988
              ],
              [
                -73.95314947399989,
                40.597027918999906
              ],
              [
                -73.95411036199994,
                40.596920738999856
              ],
              [
                -73.95507631399984,
                40.59681539099995
              ],
              [
                -73.95545313399988,
                40.59677338399992
              ],
              [
                -73.95600127899989,
                40.59671338599991
              ],
              [
                -73.956927197,
                40.59661207599988
              ],
              [
                -73.95655540499982,
                40.59464772499994
              ],
              [
                -73.95607720399984,
                40.592101431999936
              ],
              [
                -73.95700330399997,
                40.59200225599987
              ],
              [
                -73.9567157929999,
                40.590498733999866
              ],
              [
                -73.95665683399993,
                40.590101652999905
              ],
              [
                -73.95658790599994,
                40.58988411499995
              ],
              [
                -73.95639200299993,
                40.58892867499993
              ],
              [
                -73.95622721599997,
                40.587995914999944
              ],
              [
                -73.95619313699986,
                40.58776226899994
              ],
              [
                -73.9571256639999,
                40.58766096099986
              ],
              [
                -73.95819543199988,
                40.58754433199988
              ],
              [
                -73.9593473669999,
                40.58741753699992
              ],
              [
                -73.95978030299993,
                40.587369474999875
              ],
              [
                -73.96034953599988,
                40.58730628599994
              ],
              [
                -73.96031712099983,
                40.586514723999876
              ],
              [
                -73.96031766399983,
                40.58643646799992
              ],
              [
                -73.9603380369999,
                40.58628108999995
              ],
              [
                -73.96034614999985,
                40.586124770999945
              ],
              [
                -73.96034190799985,
                40.585968365999854
              ],
              [
                -73.9603253929998,
                40.585812722999954
              ],
              [
                -73.96025825899996,
                40.585029307999946
              ],
              [
                -73.96021742199999,
                40.585036254999906
              ],
              [
                -73.96007463199999,
                40.585050315999915
              ],
              [
                -73.95917287700001,
                40.585139105999865
              ],
              [
                -73.95880175499998,
                40.58518715199991
              ],
              [
                -73.95777933699982,
                40.585183528999906
              ],
              [
                -73.95765292099998,
                40.58519361399986
              ],
              [
                -73.95761659699984,
                40.58519750799996
              ],
              [
                -73.95757916399988,
                40.5852008269999
              ],
              [
                -73.95690846199993,
                40.58527652799989
              ],
              [
                -73.95673945299994,
                40.58530330799991
              ],
              [
                -73.95657432099985,
                40.58534434599988
              ],
              [
                -73.95641613599993,
                40.58539901099992
              ],
              [
                -73.95626771099987,
                40.58546620499987
              ],
              [
                -73.95576287300001,
                40.585500097999905
              ],
              [
                -73.95485543100001,
                40.58562477799989
              ],
              [
                -73.95439984899998,
                40.585676247999885
              ],
              [
                -73.95398040799992,
                40.5857118479999
              ],
              [
                -73.95387860700004,
                40.58572124599991
              ],
              [
                -73.95388521699984,
                40.58564255099988
              ],
              [
                -73.95386831099988,
                40.58555947099995
              ],
              [
                -73.95382536099993,
                40.585478261999874
              ],
              [
                -73.95375778800003,
                40.58540595999989
              ],
              [
                -73.953671153,
                40.585348497999895
              ],
              [
                -73.95357366099982,
                40.585309187999876
              ],
              [
                -73.95347394799992,
                40.585288245999884
              ],
              [
                -73.9533602969999,
                40.585268180999904
              ],
              [
                -73.95324286999993,
                40.58525898299989
              ],
              [
                -73.95312439499999,
                40.585261132999904
              ],
              [
                -73.95300767399999,
                40.58527458399993
              ],
              [
                -73.95289540899992,
                40.58529875399992
              ],
              [
                -73.95287969899992,
                40.58519841999987
              ],
              [
                -73.95123865600004,
                40.5849246329999
              ],
              [
                -73.94986974599985,
                40.58485639599989
              ],
              [
                -73.94866230599997,
                40.58489385599993
              ],
              [
                -73.94763574499989,
                40.584998861999956
              ],
              [
                -73.9466911319999,
                40.585073071999915
              ],
              [
                -73.94555221999997,
                40.585139491999946
              ],
              [
                -73.94493086699988,
                40.58517250599994
              ],
              [
                -73.94386629099992,
                40.5852983619999
              ],
              [
                -73.94291014799995,
                40.58540563799992
              ],
              [
                -73.94191610399989,
                40.58552017299993
              ],
              [
                -73.94105998299987,
                40.58566108399993
              ],
              [
                -73.93920635200004,
                40.58597168899993
              ],
              [
                -73.93914504099992,
                40.58597982499988
              ],
              [
                -73.93908601799993,
                40.58599196799988
              ],
              [
                -73.93816896699991,
                40.58614987199995
              ],
              [
                -73.9372471729998,
                40.58631062899991
              ],
              [
                -73.936775802,
                40.586398593999945
              ],
              [
                -73.93633646899995,
                40.58648058799985
              ],
              [
                -73.93588537499998,
                40.58653208999993
              ],
              [
                -73.93541271099994,
                40.586586054999934
              ],
              [
                -73.93477406299994,
                40.58663996999996
              ],
              [
                -73.9344471859999,
                40.58665207499994
              ],
              [
                -73.93344399399984,
                40.58653743799994
              ],
              [
                -73.93244497799995,
                40.58636028999989
              ],
              [
                -73.93210850499995,
                40.58628620299985
              ],
              [
                -73.93145727300002,
                40.58610127199996
              ],
              [
                -73.93139947599984,
                40.586079894999905
              ],
              [
                -73.93131301499997,
                40.58605420599989
              ],
              [
                -73.92942869199987,
                40.585488201999915
              ],
              [
                -73.92858911999988,
                40.585207258999965
              ],
              [
                -73.92840477199982,
                40.58526206699991
              ],
              [
                -73.92762689299991,
                40.585035055999874
              ],
              [
                -73.92712081899994,
                40.58487935699989
              ],
              [
                -73.92678292699998,
                40.584861437999905
              ],
              [
                -73.92583335908428,
                40.5849530771106
              ],
              [
                -73.92604075506927,
                40.58506563472015
              ],
              [
                -73.92620547282668,
                40.58515502845803
              ],
              [
                -73.92618443073194,
                40.58518171360485
              ],
              [
                -73.92623109243671,
                40.58521022257151
              ],
              [
                -73.92628242042889,
                40.585242294513314
              ],
              [
                -73.92634542074707,
                40.585276154753124
              ],
              [
                -73.92627529542231,
                40.58535264744197
              ],
              [
                -73.92565445909003,
                40.5850414583568
              ],
              [
                -73.92552860874584,
                40.584978376572664
              ],
              [
                -73.92549354358314,
                40.585019292003224
              ],
              [
                -73.92559227190102,
                40.58507134304678
              ],
              [
                -73.92610161910714,
                40.58533987466663
              ],
              [
                -73.92654820963332,
                40.58557531538759
              ],
              [
                -73.92656690671208,
                40.58555752783965
              ],
              [
                -73.92660195912501,
                40.58552907123917
              ],
              [
                -73.92633697458787,
                40.58537928196404
              ],
              [
                -73.92630563576202,
                40.58536156724199
              ],
              [
                -73.92638041881064,
                40.58529575634781
              ],
              [
                -73.92657139551324,
                40.5854185317093
              ],
              [
                -73.92666272889834,
                40.585466812382734
              ],
              [
                -73.92666268980561,
                40.58550241135616
              ],
              [
                -73.92669065751838,
                40.58554692736528
              ],
              [
                -73.92676297506527,
                40.58560037198058
              ],
              [
                -73.92686097045829,
                40.5856573934011
              ],
              [
                -73.92697296751643,
                40.585719762297735
              ],
              [
                -73.92711203473591,
                40.585803552824565
              ],
              [
                -73.9271409664433,
                40.58581242644445
              ],
              [
                -73.9272133428372,
                40.585812472316505
              ],
              [
                -73.92730433823876,
                40.58586592841988
              ],
              [
                -73.92731976953024,
                40.58589260668801
              ],
              [
                -73.92735853538503,
                40.58592100147286
              ],
              [
                -73.92737247661461,
                40.585959205318275
              ],
              [
                -73.92733921875872,
                40.58599410697143
              ],
              [
                -73.92735088516017,
                40.586001234348885
              ],
              [
                -73.92732050626583,
                40.586026134635105
              ],
              [
                -73.9273065040188,
                40.58602078544186
              ],
              [
                -73.9272807972705,
                40.586043909008566
              ],
              [
                -73.92734144038619,
                40.586099125029804
              ],
              [
                -73.92751168880422,
                40.586270107541274
              ],
              [
                -73.92751621995603,
                40.586398266715506
              ],
              [
                -73.9275955985745,
                40.58640009709843
              ],
              [
                -73.92758641829884,
                40.58625413533235
              ],
              [
                -73.92753271376783,
                40.5862594418086
              ],
              [
                -73.92733683471778,
                40.586040384460546
              ],
              [
                -73.92736955785888,
                40.58600658531643
              ],
              [
                -73.92738822580927,
                40.586015497450724
              ],
              [
                -73.92740528162322,
                40.58599235415452
              ],
              [
                -73.92744400140172,
                40.58601571445453
              ],
              [
                -73.92751386933554,
                40.58602514187574
              ],
              [
                -73.92764071689658,
                40.586045316480686
              ],
              [
                -73.92774311153144,
                40.5860500652093
              ],
              [
                -73.92785999074735,
                40.585917424191905
              ],
              [
                -73.92801568895455,
                40.58587068011404
              ],
              [
                -73.92815700695613,
                40.58586452306607
              ],
              [
                -73.92827672497616,
                40.58610694357004
              ],
              [
                -73.92819501248111,
                40.58613440857039
              ],
              [
                -73.92817273929886,
                40.58617285270146
              ],
              [
                -73.92816239374059,
                40.58615176718003
              ],
              [
                -73.9281647386095,
                40.5861140034952
              ],
              [
                -73.92792514440899,
                40.586093653926554
              ],
              [
                -73.92791933785853,
                40.586135806046116
              ],
              [
                -73.92813819706498,
                40.58615614316272
              ],
              [
                -73.92814969379499,
                40.58617810776291
              ],
              [
                -73.92811039892527,
                40.58629489206263
              ],
              [
                -73.9280965704473,
                40.58629927452315
              ],
              [
                -73.92804358383414,
                40.58629397188225
              ],
              [
                -73.92803894403345,
                40.58632383025664
              ],
              [
                -73.92808962925501,
                40.58632649638931
              ],
              [
                -73.92811957884383,
                40.58632915019955
              ],
              [
                -73.92810548598204,
                40.58636172267004
              ],
              [
                -73.92809974275899,
                40.58640371685694
              ],
              [
                -73.92808640183115,
                40.58644425900183
              ],
              [
                -73.92807588962786,
                40.58648036137611
              ],
              [
                -73.92806347188096,
                40.58656879170751
              ],
              [
                -73.92808232971585,
                40.58669769393763
              ],
              [
                -73.92811462171161,
                40.58669916226926
              ],
              [
                -73.9281430268027,
                40.586781728308814
              ],
              [
                -73.9281857599151,
                40.58682528921666
              ],
              [
                -73.92824930479918,
                40.586875928826544
              ],
              [
                -73.92829674888085,
                40.58691795605454
              ],
              [
                -73.92836889002496,
                40.586959999834725
              ],
              [
                -73.92843153421316,
                40.58700058835756
              ],
              [
                -73.92849798568434,
                40.587032490303066
              ],
              [
                -73.92857393936457,
                40.58706150237137
              ],
              [
                -73.92869359490844,
                40.587081851936325
              ],
              [
                -73.92879236548926,
                40.58709060159948
              ],
              [
                -73.92888983084922,
                40.587083818719385
              ],
              [
                -73.92908894003958,
                40.58709506974446
              ],
              [
                -73.92919437666475,
                40.587087532114374
              ],
              [
                -73.92928895400573,
                40.587055262041325
              ],
              [
                -73.92956343312808,
                40.58703562312643
              ],
              [
                -73.92961575482573,
                40.58697984176409
              ],
              [
                -73.92966894624747,
                40.586955311676846
              ],
              [
                -73.93052821173194,
                40.5868572158949
              ],
              [
                -73.93101218068466,
                40.58683048768306
              ],
              [
                -73.93101679851631,
                40.586885528219156
              ],
              [
                -73.93095410532507,
                40.58689092186598
              ],
              [
                -73.93095696421327,
                40.58688223467156
              ],
              [
                -73.93091279265593,
                40.58688763841874
              ],
              [
                -73.93091135186273,
                40.58690284319078
              ],
              [
                -73.93082871260312,
                40.58690822494172
              ],
              [
                -73.93082019774091,
                40.58687563400685
              ],
              [
                -73.93055096924029,
                40.586907278390484
              ],
              [
                -73.92964181549499,
                40.58701394761244
              ],
              [
                -73.92964322159177,
                40.587031326968074
              ],
              [
                -73.93055771935786,
                40.58692511765015
              ],
              [
                -73.93080593035211,
                40.58689626302755
              ],
              [
                -73.9308144495186,
                40.58692342249529
              ],
              [
                -73.93105809300631,
                40.586907276373466
              ],
              [
                -73.9310452875478,
                40.586814160037164
              ],
              [
                -73.93118073044302,
                40.58679873371335
              ],
              [
                -73.93125191267131,
                40.58684982521422
              ],
              [
                -73.93127751247582,
                40.58689328743528
              ],
              [
                -73.93130299966685,
                40.58704753744362
              ],
              [
                -73.9313172217923,
                40.58707144166878
              ],
              [
                -73.93135135966136,
                40.587124684315924
              ],
              [
                -73.93136556192967,
                40.587169225129045
              ],
              [
                -73.93134917824065,
                40.587328167229494
              ],
              [
                -73.93073284303382,
                40.58740698378471
              ],
              [
                -73.93021881179953,
                40.58747683272641
              ],
              [
                -73.9299037110106,
                40.58752912420285
              ],
              [
                -73.92972232646723,
                40.587560082903835
              ],
              [
                -73.9297025502044,
                40.587607355474205
              ],
              [
                -73.93026338157462,
                40.588229816921945
              ],
              [
                -73.93029098162938,
                40.5882415287279
              ],
              [
                -73.93033698653332,
                40.5882579299451
              ],
              [
                -73.93036150933793,
                40.58827899571413
              ],
              [
                -73.93036760201352,
                40.588321101381986
              ],
              [
                -73.93037062807417,
                40.58836086664855
              ],
              [
                -73.9303596123009,
                40.588404298162175
              ],
              [
                -73.93038545256262,
                40.58844161607885
              ],
              [
                -73.9303680674251,
                40.58844862954883
              ],
              [
                -73.93036500375167,
                40.58844282514473
              ],
              [
                -73.92972846380192,
                40.58861391582969
              ],
              [
                -73.92974410780818,
                40.58864107972962
              ],
              [
                -73.93038328514656,
                40.58847744712129
              ],
              [
                -73.93037983195714,
                40.58847090635756
              ],
              [
                -73.93039967597488,
                40.58846552041535
              ],
              [
                -73.93043726960487,
                40.58851438636985
              ],
              [
                -73.93050707979359,
                40.588593413015495
              ],
              [
                -73.93054733435007,
                40.5886285422966
              ],
              [
                -73.93063532324204,
                40.58862358012013
              ],
              [
                -73.9306502192753,
                40.58866986977078
              ],
              [
                -73.9305344412823,
                40.58867959008413
              ],
              [
                -73.93058602090872,
                40.58875129283312
              ],
              [
                -73.9305446818435,
                40.5887708202054
              ],
              [
                -73.93053614268541,
                40.58876212495992
              ],
              [
                -73.9300700476359,
                40.58895409308378
              ],
              [
                -73.93009138279199,
                40.58898886237902
              ],
              [
                -73.93010137658842,
                40.58896931778159
              ],
              [
                -73.93056889562718,
                40.58877843681945
              ],
              [
                -73.93062438721954,
                40.58884907156901
              ],
              [
                -73.93068568059813,
                40.58882195422684
              ],
              [
                -73.93070488530002,
                40.588942066776646
              ],
              [
                -73.93079601726942,
                40.58907815135634
              ],
              [
                -73.9308888007358,
                40.589207340173736
              ],
              [
                -73.93092739827031,
                40.58925625816704
              ],
              [
                -73.93101236544717,
                40.58931170698335
              ],
              [
                -73.93105313024402,
                40.589330303923184
              ],
              [
                -73.9310917816459,
                40.589326565572115
              ],
              [
                -73.93121294858955,
                40.58953130811921
              ],
              [
                -73.93113301959801,
                40.58954611892402
              ],
              [
                -73.93131907299008,
                40.59052752841813
              ],
              [
                -73.93134125982561,
                40.59061546375556
              ],
              [
                -73.93141600360825,
                40.59060910592292
              ],
              [
                -73.93140697512008,
                40.59069991048216
              ],
              [
                -73.9313961055893,
                40.59080940786934
              ],
              [
                -73.93142883104393,
                40.59113638100172
              ],
              [
                -73.93149418291641,
                40.59116653304395
              ],
              [
                -73.93155272211995,
                40.591199797036346
              ],
              [
                -73.93155939441614,
                40.59129296407077
              ],
              [
                -73.93154664980304,
                40.59133793542349
              ],
              [
                -73.9314764750352,
                40.591342072281535
              ],
              [
                -73.93147538548219,
                40.5913345492299
              ],
              [
                -73.93145684761484,
                40.591236748623786
              ],
              [
                -73.93143272420807,
                40.591239241307925
              ],
              [
                -73.93146654894619,
                40.59139890185897
              ],
              [
                -73.9314862870528,
                40.59139640655311
              ],
              [
                -73.93148084879925,
                40.5913529410094
              ],
              [
                -73.93154334667179,
                40.5913504711831
              ],
              [
                -73.93151343730557,
                40.591412981001675
              ],
              [
                -73.93148064834168,
                40.5914317907456
              ],
              [
                -73.93149291902427,
                40.5914810392866
              ],
              [
                -73.93142232187742,
                40.591506634691186
              ],
              [
                -73.93144647757205,
                40.59156975807161
              ],
              [
                -73.93142252650097,
                40.59158857039048
              ],
              [
                -73.93124592494814,
                40.59163697699579
              ],
              [
                -73.9313054163602,
                40.591360358668325
              ],
              [
                -73.93128458380468,
                40.59136118117854
              ],
              [
                -73.93122728942248,
                40.591634458767764
              ],
              [
                -73.93123057043029,
                40.591641983132995
              ],
              [
                -73.93107702484296,
                40.591688697156016
              ],
              [
                -73.93109794805815,
                40.59160011299372
              ],
              [
                -73.9310760222574,
                40.59159759276817
              ],
              [
                -73.93104847875684,
                40.59172629086602
              ],
              [
                -73.9310704045976,
                40.59172881226959
              ],
              [
                -73.93107262221186,
                40.59170541142871
              ],
              [
                -73.93119326187228,
                40.591671213764336
              ],
              [
                -73.93119983469255,
                40.59167790513536
              ],
              [
                -73.93124260951417,
                40.59166288593491
              ],
              [
                -73.9312371374292,
                40.59165368814867
              ],
              [
                -73.93142565439746,
                40.59160418414737
              ],
              [
                -73.93140775751667,
                40.59164400925834
              ],
              [
                -73.93139310403653,
                40.59165416618969
              ],
              [
                -73.93135740555535,
                40.59166968924601
              ],
              [
                -73.93130130855009,
                40.591692970610374
              ],
              [
                -73.93135300954727,
                40.591791193961825
              ],
              [
                -73.93116347041968,
                40.59185424013379
              ],
              [
                -73.93115360172413,
                40.591855069690226
              ],
              [
                -73.93115143543241,
                40.59182999411142
              ],
              [
                -73.9310604356206,
                40.59182743247609
              ],
              [
                -73.93105716582592,
                40.59180820585462
              ],
              [
                -73.93099465973228,
                40.59181819898607
              ],
              [
                -73.9310001292229,
                40.59183073882467
              ],
              [
                -73.93100451460829,
                40.59183074145789
              ],
              [
                -73.9310098934316,
                40.59193104248098
              ],
              [
                -73.93139997986377,
                40.591804890562145
              ],
              [
                -73.93136406792838,
                40.591928373072186
              ],
              [
                -73.93131727481894,
                40.591946787410464
              ],
              [
                -73.93125838564315,
                40.59196519566692
              ],
              [
                -73.93120075354454,
                40.5919882405171
              ],
              [
                -73.93113956057566,
                40.59200892874752
              ],
              [
                -73.93115993293195,
                40.59202707583502
              ],
              [
                -73.9312210463182,
                40.59208280989741
              ],
              [
                -73.93127197116024,
                40.59213076681869
              ],
              [
                -73.93130932293144,
                40.592160581226096
              ],
              [
                -73.9314367561379,
                40.5921671345181
              ],
              [
                -73.931431598401,
                40.592225419412266
              ],
              [
                -73.93145367368668,
                40.592238386451946
              ],
              [
                -73.93146571541051,
                40.592263099517325
              ],
              [
                -73.93146597430784,
                40.59226351413933
              ],
              [
                -73.93148861724065,
                40.59230071094017
              ],
              [
                -73.93153229503947,
                40.59247981491753
              ],
              [
                -73.93156462560293,
                40.5925517407717
              ],
              [
                -73.93161598469497,
                40.592602227321635
              ],
              [
                -73.93166657890721,
                40.592635484344505
              ],
              [
                -73.93167672233834,
                40.592661153688
              ],
              [
                -73.93168985287654,
                40.5926867929756
              ],
              [
                -73.93174087511966,
                40.59279439369442
              ],
              [
                -73.931729250829,
                40.592797767865214
              ],
              [
                -73.93166486127859,
                40.59281645518014
              ],
              [
                -73.93162526000405,
                40.59282794876039
              ],
              [
                -73.93155057104202,
                40.59269009334777
              ],
              [
                -73.93153495293322,
                40.5926928305189
              ],
              [
                -73.93168855675178,
                40.59300489316119
              ],
              [
                -73.93168977728052,
                40.59300774573801
              ],
              [
                -73.9317109079429,
                40.59305030052047
              ],
              [
                -73.93173338203975,
                40.593044521213486
              ],
              [
                -73.93176073534399,
                40.59303709413345
              ],
              [
                -73.9317501541548,
                40.59301110338481
              ],
              [
                -73.9316741082931,
                40.59285395021889
              ],
              [
                -73.93174465539049,
                40.59283411261459
              ],
              [
                -73.9317589074308,
                40.592830104899186
              ],
              [
                -73.93182549996209,
                40.59299110993082
              ],
              [
                -73.93190580795942,
                40.59308588397505
              ],
              [
                -73.93200062731796,
                40.59328764992159
              ],
              [
                -73.93216554505263,
                40.59356300857742
              ],
              [
                -73.93235089999555,
                40.59386846912427
              ],
              [
                -73.93244863039499,
                40.593959961419955
              ],
              [
                -73.93252661189226,
                40.59404001871799
              ],
              [
                -73.93259834108942,
                40.59411222874199
              ],
              [
                -73.93267653975757,
                40.594236417967586
              ],
              [
                -73.93271241665666,
                40.594333871161496
              ],
              [
                -73.93278001234762,
                40.59455966061023
              ],
              [
                -73.93280121604168,
                40.59464525651379
              ],
              [
                -73.93280736080116,
                40.594669158995245
              ],
              [
                -73.93281932831556,
                40.594715702441036
              ],
              [
                -73.93282654852884,
                40.59480374496915
              ],
              [
                -73.93279727104483,
                40.594842732518494
              ],
              [
                -73.93267147327069,
                40.59491175331411
              ],
              [
                -73.93260420556844,
                40.59492954452495
              ],
              [
                -73.93255597836587,
                40.59491168558764
              ],
              [
                -73.93247702888684,
                40.594916096675945
              ],
              [
                -73.9324376290322,
                40.59484363603982
              ],
              [
                -73.93257803499358,
                40.59478576979389
              ],
              [
                -73.93256343839313,
                40.59476347229703
              ],
              [
                -73.9324054865637,
                40.59482355818788
              ],
              [
                -73.93239089433519,
                40.59479568931842
              ],
              [
                -73.93237188265157,
                40.594802363889585
              ],
              [
                -73.93239815454939,
                40.594845842136536
              ],
              [
                -73.93241277639827,
                40.594842507020665
              ],
              [
                -73.93245217688444,
                40.59491496766572
              ],
              [
                -73.93239677902231,
                40.594912014048695
              ],
              [
                -73.93234749083399,
                40.59491691693656
              ],
              [
                -73.93230826395404,
                40.59491634048717
              ],
              [
                -73.93225233912358,
                40.59490467831861
              ],
              [
                -73.93221820952614,
                40.59489192177981
              ],
              [
                -73.93216666204167,
                40.59486309676737
              ],
              [
                -73.9321296485849,
                40.594828742469076
              ],
              [
                -73.93208972932095,
                40.59479438694401
              ],
              [
                -73.93199915298683,
                40.59472828630575
              ],
              [
                -73.9319336498399,
                40.59469240430239
              ],
              [
                -73.93190503589656,
                40.59468541176618
              ],
              [
                -73.9318772173972,
                40.59468868237025
              ],
              [
                -73.93169652225768,
                40.594709929731266
              ],
              [
                -73.93156252515298,
                40.59469897618456
              ],
              [
                -73.93143018596695,
                40.59468661706268
              ],
              [
                -73.9313017105705,
                40.59465448108946
              ],
              [
                -73.93113820375036,
                40.59464019048005
              ],
              [
                -73.93101903473391,
                40.59461691000355
              ],
              [
                -73.93088473194919,
                40.594548891660544
              ],
              [
                -73.93085036660067,
                40.59454371510518
              ],
              [
                -73.93081918377538,
                40.594529428370684
              ],
              [
                -73.93080547079994,
                40.59451610120178
              ],
              [
                -73.93078131379613,
                40.59449895527679
              ],
              [
                -73.93084626727261,
                40.594451525297195
              ],
              [
                -73.93098771679708,
                40.59449342128471
              ],
              [
                -73.93102713500376,
                40.594504689850986
              ],
              [
                -73.93117195590494,
                40.59452009321482
              ],
              [
                -73.93136806343367,
                40.59454645078468
              ],
              [
                -73.93146047172367,
                40.59453319422923
              ],
              [
                -73.93153064222903,
                40.594519553776685
              ],
              [
                -73.93161313737198,
                40.594503518414385
              ],
              [
                -73.93162407058445,
                40.594429080940465
              ],
              [
                -73.93163177589155,
                40.59437662255864
              ],
              [
                -73.9317580816717,
                40.594384328761244
              ],
              [
                -73.93175908241429,
                40.59436700800084
              ],
              [
                -73.9317638740002,
                40.59428409876284
              ],
              [
                -73.93174280308597,
                40.594283512461125
              ],
              [
                -73.93174223368128,
                40.59430090316993
              ],
              [
                -73.93173994773721,
                40.5943708679028
              ],
              [
                -73.9316156203621,
                40.59436596310946
              ],
              [
                -73.93158758229917,
                40.59449506291083
              ],
              [
                -73.93151134999133,
                40.59450684322595
              ],
              [
                -73.931430230235,
                40.59451937864413
              ],
              [
                -73.93141979289189,
                40.59452099074847
              ],
              [
                -73.93136684826814,
                40.59452917209447
              ],
              [
                -73.93133571171825,
                40.59452513731441
              ],
              [
                -73.93132562373592,
                40.5945238302538
              ],
              [
                -73.93125615625141,
                40.59451482858217
              ],
              [
                -73.9312471362245,
                40.59451366053259
              ],
              [
                -73.93118315992675,
                40.59450537024826
              ],
              [
                -73.93117266234964,
                40.59450400965464
              ],
              [
                -73.93116797698177,
                40.59450340357536
              ],
              [
                -73.93117079151007,
                40.59449753084694
              ],
              [
                -73.93111095510909,
                40.5944890129843
              ],
              [
                -73.93100842980581,
                40.59448226571794
              ],
              [
                -73.9309546342639,
                40.594465945206274
              ],
              [
                -73.93097341657615,
                40.59445329362182
              ],
              [
                -73.93099809750406,
                40.5944366689339
              ],
              [
                -73.93103520310456,
                40.59441167456396
              ],
              [
                -73.93104573382053,
                40.59440476393444
              ],
              [
                -73.93112087099405,
                40.5943554614039
              ],
              [
                -73.93112950421332,
                40.594349796382254
              ],
              [
                -73.93119990483329,
                40.59430360013704
              ],
              [
                -73.93124036875787,
                40.594278043975
              ],
              [
                -73.9312765055576,
                40.594256764914206
              ],
              [
                -73.93131702522788,
                40.59428143789023
              ],
              [
                -73.9313251214607,
                40.594276256190035
              ],
              [
                -73.93130987534303,
                40.59426545835942
              ],
              [
                -73.93127371485065,
                40.59423310576509
              ],
              [
                -73.93122677419932,
                40.59419110690569
              ],
              [
                -73.93122055938903,
                40.5941833172628
              ],
              [
                -73.93121284213825,
                40.594188409219285
              ],
              [
                -73.93126301572376,
                40.59424418611863
              ],
              [
                -73.93119016326015,
                40.59428756527012
              ],
              [
                -73.93110437164648,
                40.59434369933412
              ],
              [
                -73.93103616514564,
                40.59438796003148
              ],
              [
                -73.93102812893794,
                40.59439371871824
              ],
              [
                -73.93099362203101,
                40.59441691739247
              ],
              [
                -73.93095924897628,
                40.594441570290854
              ],
              [
                -73.93093516114614,
                40.59445994986099
              ],
              [
                -73.93083206587438,
                40.594430655669264
              ],
              [
                -73.93081953536732,
                40.59444165044416
              ],
              [
                -73.93082526679706,
                40.59444675750644
              ],
              [
                -73.93076901026961,
                40.59449301481567
              ],
              [
                -73.93070567804722,
                40.59447704025298
              ],
              [
                -73.93066303510112,
                40.59446395093278
              ],
              [
                -73.93076839841687,
                40.594392122116396
              ],
              [
                -73.93080693832646,
                40.594429892356175
              ],
              [
                -73.93083446436513,
                40.594413571304166
              ],
              [
                -73.9307950624998,
                40.5943749557045
              ],
              [
                -73.93078832527874,
                40.59436827024408
              ],
              [
                -73.93074913473289,
                40.5943293798401
              ],
              [
                -73.9307406167828,
                40.59432092721014
              ],
              [
                -73.93069541073764,
                40.594276066941504
              ],
              [
                -73.93068733320767,
                40.59426805333233
              ],
              [
                -73.9306495693869,
                40.59423057770163
              ],
              [
                -73.93064177530424,
                40.594222842526435
              ],
              [
                -73.9306230568455,
                40.59420449765984
              ],
              [
                -73.93059803646442,
                40.59421933301731
              ],
              [
                -73.93061151254695,
                40.59423254145781
              ],
              [
                -73.93066439573106,
                40.59428529819391
              ],
              [
                -73.93070268256008,
                40.59432349434325
              ],
              [
                -73.93070936725901,
                40.594330163861734
              ],
              [
                -73.93074807610716,
                40.59436877907354
              ],
              [
                -73.93076122839551,
                40.594381900520744
              ],
              [
                -73.93075181378946,
                40.59438762434923
              ],
              [
                -73.9306134962184,
                40.59447681056651
              ],
              [
                -73.93058089609401,
                40.59446745145528
              ],
              [
                -73.93052974770505,
                40.594451249279025
              ],
              [
                -73.93043246129608,
                40.5944026749499
              ],
              [
                -73.93027533487947,
                40.59429698953186
              ],
              [
                -73.93020176823151,
                40.59423891758602
              ],
              [
                -73.93009827326867,
                40.594160850194044
              ],
              [
                -73.93004011947687,
                40.59410488733049
              ],
              [
                -73.93029934404385,
                40.59393805451023
              ],
              [
                -73.93032694746383,
                40.5939654478809
              ],
              [
                -73.93034434256145,
                40.5939827122677
              ],
              [
                -73.93033684931883,
                40.59398770328114
              ],
              [
                -73.93034386205119,
                40.59399459750925
              ],
              [
                -73.93034985747646,
                40.59400063806112
              ],
              [
                -73.9303662465998,
                40.59401715247634
              ],
              [
                -73.93042039868939,
                40.59407172008142
              ],
              [
                -73.9304339641136,
                40.594085389134996
              ],
              [
                -73.93044180984457,
                40.59409329506806
              ],
              [
                -73.93048105499315,
                40.594132840650566
              ],
              [
                -73.9304873099403,
                40.594139143200486
              ],
              [
                -73.93052029290217,
                40.59417284072188
              ],
              [
                -73.93052537125824,
                40.59417781957961
              ],
              [
                -73.93054679070711,
                40.59419930408557
              ],
              [
                -73.93057032638413,
                40.594185670534884
              ],
              [
                -73.9305433316513,
                40.594158593216584
              ],
              [
                -73.93044330312577,
                40.59405981155855
              ],
              [
                -73.93035968941524,
                40.59397594132398
              ],
              [
                -73.9303813688456,
                40.59396338222485
              ],
              [
                -73.93052488661866,
                40.59388024070077
              ],
              [
                -73.93045934564594,
                40.593814498247355
              ],
              [
                -73.93030764773724,
                40.59390237882368
              ],
              [
                -73.93029155870587,
                40.593911698603414
              ],
              [
                -73.9302876039036,
                40.5939060419233
              ],
              [
                -73.93022756280047,
                40.59393062089687
              ],
              [
                -73.93018748124085,
                40.59383790527812
              ],
              [
                -73.9301826176162,
                40.59382665457415
              ],
              [
                -73.93016493129313,
                40.59378428069545
              ],
              [
                -73.93013899067863,
                40.593722129151345
              ],
              [
                -73.93013632556473,
                40.593710960028424
              ],
              [
                -73.93008159453139,
                40.59359546287129
              ],
              [
                -73.93005017634239,
                40.593602306420586
              ],
              [
                -73.9300743711095,
                40.59366684857136
              ],
              [
                -73.93007832030693,
                40.59367446886645
              ],
              [
                -73.93009029511921,
                40.59369757090282
              ],
              [
                -73.93010626301084,
                40.59372837618566
              ],
              [
                -73.93011026067549,
                40.593739040516304
              ],
              [
                -73.93012135534387,
                40.593768640140134
              ],
              [
                -73.9301458561132,
                40.59383112654151
              ],
              [
                -73.93016374518345,
                40.59387674944167
              ],
              [
                -73.93016703888816,
                40.59388515270216
              ],
              [
                -73.93018541414243,
                40.59393201544327
              ],
              [
                -73.93018896715142,
                40.593941078758476
              ],
              [
                -73.93019837897519,
                40.593938843604086
              ],
              [
                -73.93021519470311,
                40.59397294418455
              ],
              [
                -73.93001650952081,
                40.5940945360621
              ],
              [
                -73.92996116581368,
                40.59400571081676
              ],
              [
                -73.92994377175573,
                40.5939324518857
              ],
              [
                -73.92991887262683,
                40.59387631171106
              ],
              [
                -73.92986032030679,
                40.59378210123926
              ],
              [
                -73.92985416775136,
                40.59369933684082
              ],
              [
                -73.92984368238119,
                40.59364613235039
              ],
              [
                -73.92985678414759,
                40.59358423525881
              ],
              [
                -73.92985305834146,
                40.593525079228115
              ],
              [
                -73.92978081742058,
                40.5934367414716
              ],
              [
                -73.92980068404492,
                40.593391441960726
              ],
              [
                -73.92990442337926,
                40.593375935617374
              ],
              [
                -73.9300354613713,
                40.59336745389019
              ],
              [
                -73.93003922396665,
                40.59334843088376
              ],
              [
                -73.92990069737226,
                40.59335881134764
              ],
              [
                -73.92989320208056,
                40.59336641696097
              ],
              [
                -73.92982317336855,
                40.59337269559881
              ],
              [
                -73.92981465540538,
                40.59329882823663
              ],
              [
                -73.9297785203565,
                40.59324743782412
              ],
              [
                -73.92976735400573,
                40.593186549679366
              ],
              [
                -73.92974147322658,
                40.59314392244556
              ],
              [
                -73.92979816541931,
                40.59314505122782
              ],
              [
                -73.92989436237971,
                40.59315842166691
              ],
              [
                -73.92997593629296,
                40.59317381541353
              ],
              [
                -73.92993575938482,
                40.5933053216952
              ],
              [
                -73.92995591909562,
                40.5933085723266
              ],
              [
                -73.92997510439875,
                40.593245975137066
              ],
              [
                -73.92997911251686,
                40.593236563438076
              ],
              [
                -73.93002033232533,
                40.59310546889237
              ],
              [
                -73.93002689378028,
                40.59309073114711
              ],
              [
                -73.93004238419583,
                40.59306087931805
              ],
              [
                -73.93001524679518,
                40.593054397167805
              ],
              [
                -73.9299812081446,
                40.59315888690685
              ],
              [
                -73.92988961346813,
                40.59314161893123
              ],
              [
                -73.92988841971216,
                40.59314682836411
              ],
              [
                -73.92980260586404,
                40.59313540760325
              ],
              [
                -73.92980336402717,
                40.593132097855005
              ],
              [
                -73.92975537733638,
                40.59312571263683
              ],
              [
                -73.92974639689021,
                40.59312451752123
              ],
              [
                -73.92973850612175,
                40.59312346748153
              ],
              [
                -73.92971282036608,
                40.593120048248096
              ],
              [
                -73.92983201351177,
                40.59290364860498
              ],
              [
                -73.92989066964346,
                40.592870791368064
              ],
              [
                -73.92989511559179,
                40.59287178484885
              ],
              [
                -73.92990039140618,
                40.59287296395568
              ],
              [
                -73.92990668955943,
                40.59287437035284
              ],
              [
                -73.9299605293827,
                40.592886397441916
              ],
              [
                -73.92995835875485,
                40.59289204101885
              ],
              [
                -73.92998549410797,
                40.59289810218026
              ],
              [
                -73.92998800211831,
                40.592891580452246
              ],
              [
                -73.93001166376469,
                40.592896866008296
              ],
              [
                -73.93000857751039,
                40.59290489163024
              ],
              [
                -73.93006353529177,
                40.59291716846798
              ],
              [
                -73.93004645355526,
                40.59295858421449
              ],
              [
                -73.9300326034588,
                40.59300023144112
              ],
              [
                -73.930052445394,
                40.59300024352078
              ],
              [
                -73.93006258490884,
                40.592964484363826
              ],
              [
                -73.93006659641668,
                40.592950339123064
              ],
              [
                -73.93007945243008,
                40.59291922737281
              ],
              [
                -73.93009377470048,
                40.59288456489173
              ],
              [
                -73.93009701128348,
                40.59287673101247
              ],
              [
                -73.9300989932412,
                40.59287244045236
              ],
              [
                -73.93008710848919,
                40.59286925033288
              ],
              [
                -73.93007514030563,
                40.59289515681875
              ],
              [
                -73.93007018426357,
                40.5929027765538
              ],
              [
                -73.93005338592835,
                40.592898976314295
              ],
              [
                -73.92998741450495,
                40.592881267325275
              ],
              [
                -73.92999065309387,
                40.5928742568567
              ],
              [
                -73.92992534072587,
                40.59285682164133
              ],
              [
                -73.92992176281749,
                40.59285586755785
              ],
              [
                -73.92991885786864,
                40.59285509230344
              ],
              [
                -73.9299125015645,
                40.592853394872975
              ],
              [
                -73.92996263703681,
                40.59281717042392
              ],
              [
                -73.92999008815492,
                40.59272828588208
              ],
              [
                -73.92999750442162,
                40.59273204473198
              ],
              [
                -73.92999827166076,
                40.59273243219207
              ],
              [
                -73.92999915553243,
                40.592732880703835
              ],
              [
                -73.92999950016439,
                40.592733054474145
              ],
              [
                -73.93007558219115,
                40.592771606402145
              ],
              [
                -73.93008682543812,
                40.592777303208564
              ],
              [
                -73.93007616098994,
                40.59278950994129
              ],
              [
                -73.930106582116,
                40.592804955384885
              ],
              [
                -73.93011754836337,
                40.59279184953564
              ],
              [
                -73.93012845255014,
                40.59277882171618
              ],
              [
                -73.9301469470833,
                40.59275672193974
              ],
              [
                -73.93016410331174,
                40.59273621991157
              ],
              [
                -73.9301788650343,
                40.59271858033942
              ],
              [
                -73.9302125148936,
                40.592678370292425
              ],
              [
                -73.93018660630729,
                40.5926656203169
              ],
              [
                -73.9301019165656,
                40.592760766189706
              ],
              [
                -73.93008905262103,
                40.59275478125344
              ],
              [
                -73.93009108240771,
                40.592752243581636
              ],
              [
                -73.93009404144675,
                40.59275361995975
              ],
              [
                -73.93011593181335,
                40.59272627808418
              ],
              [
                -73.9300297092209,
                40.592686162488484
              ],
              [
                -73.93002958401452,
                40.59268610478217
              ],
              [
                -73.93002811427151,
                40.59268542154039
              ],
              [
                -73.93002724398042,
                40.59268501642716
              ],
              [
                -73.9300230102621,
                40.59268304638487
              ],
              [
                -73.93010583866844,
                40.592574604143785
              ],
              [
                -73.93012526400537,
                40.59254669753296
              ],
              [
                -73.9301897065827,
                40.59245411828879
              ],
              [
                -73.93021322788651,
                40.59247159348604
              ],
              [
                -73.93021718600727,
                40.59246851938164
              ],
              [
                -73.93023781228277,
                40.592483951135904
              ],
              [
                -73.93023285891259,
                40.592487798622386
              ],
              [
                -73.93026579193081,
                40.592512439390404
              ],
              [
                -73.93027813759224,
                40.59251762420758
              ],
              [
                -73.93032634060388,
                40.592553689837
              ],
              [
                -73.93025320174314,
                40.592610495198684
              ],
              [
                -73.93026510927314,
                40.59261940493299
              ],
              [
                -73.93027668921863,
                40.59261097501627
              ],
              [
                -73.93030388752189,
                40.59259117346872
              ],
              [
                -73.93031400512083,
                40.592583807644964
              ],
              [
                -73.93034305347926,
                40.592562660609445
              ],
              [
                -73.93035364458565,
                40.59255495029408
              ],
              [
                -73.93026943351028,
                40.592491972534006
              ],
              [
                -73.93026531169377,
                40.59249522914789
              ],
              [
                -73.9302060581915,
                40.59245166621078
              ],
              [
                -73.93020149452236,
                40.59244831201491
              ],
              [
                -73.93021785135721,
                40.59244085717815
              ],
              [
                -73.93017616634198,
                40.59240622097041
              ],
              [
                -73.93020441819301,
                40.59237817647126
              ],
              [
                -73.93028656348375,
                40.592445575465305
              ],
              [
                -73.93030751617428,
                40.59235672359121
              ],
              [
                -73.93030109951036,
                40.59230803685339
              ],
              [
                -73.93036221568056,
                40.592311770609044
              ],
              [
                -73.93034189370505,
                40.59246497306262
              ],
              [
                -73.93036443603306,
                40.59248009351359
              ],
              [
                -73.93036570007463,
                40.59246813134643
              ],
              [
                -73.93036902388113,
                40.59243666532477
              ],
              [
                -73.93037011701409,
                40.59242632138718
              ],
              [
                -73.93037402412372,
                40.59238932825651
              ],
              [
                -73.93037497475126,
                40.59238034071729
              ],
              [
                -73.93037650336757,
                40.59236586778102
              ],
              [
                -73.93038116977189,
                40.59232169631125
              ],
              [
                -73.930383388218,
                40.59230069936355
              ],
              [
                -73.93038686981008,
                40.5922677474514
              ],
              [
                -73.93038744801052,
                40.59226226808533
              ],
              [
                -73.93038850696497,
                40.592252239751964
              ],
              [
                -73.93036922747558,
                40.5922435254257
              ],
              [
                -73.93036426041913,
                40.59229908703112
              ],
              [
                -73.93034462939919,
                40.59229806777916
              ],
              [
                -73.93034433277133,
                40.59230138551744
              ],
              [
                -73.93030161927013,
                40.59229917972631
              ],
              [
                -73.93031136732715,
                40.592193963156056
              ],
              [
                -73.93033106509768,
                40.59213223736405
              ],
              [
                -73.93034584310841,
                40.59209544130456
              ],
              [
                -73.93035545570713,
                40.59202813803919
              ],
              [
                -73.93038380855803,
                40.591829629289585
              ],
              [
                -73.93039905602124,
                40.591755596429
              ],
              [
                -73.93043758093522,
                40.591747242118345
              ],
              [
                -73.93043411755326,
                40.59193279181264
              ],
              [
                -73.93042696672612,
                40.59212508383387
              ],
              [
                -73.93042493568414,
                40.59224634183091
              ],
              [
                -73.93045091922694,
                40.59224503726175
              ],
              [
                -73.9304546522737,
                40.59213519976275
              ],
              [
                -73.93045964272,
                40.591985132532834
              ],
              [
                -73.930465294205,
                40.591747258891736
              ],
              [
                -73.9305051636497,
                40.59171691396533
              ],
              [
                -73.93060580656592,
                40.591541362508444
              ],
              [
                -73.93057117820942,
                40.59152813743205
              ],
              [
                -73.93046185647538,
                40.59172084849407
              ],
              [
                -73.93040541662201,
                40.59173094214291
              ],
              [
                -73.93047423397412,
                40.591464202397304
              ],
              [
                -73.93047480590833,
                40.5914619843199
              ],
              [
                -73.93049945183151,
                40.59129603763979
              ],
              [
                -73.93046536765816,
                40.591273407596056
              ],
              [
                -73.93052297842125,
                40.591241583055805
              ],
              [
                -73.93052645879092,
                40.591225740403424
              ],
              [
                -73.93057495301012,
                40.591229731613964
              ],
              [
                -73.93056428429375,
                40.591495123288
              ],
              [
                -73.93060066906222,
                40.59148458204168
              ],
              [
                -73.93060444306958,
                40.59118617622098
              ],
              [
                -73.93057152827818,
                40.59119143786804
              ],
              [
                -73.93056977645742,
                40.59120992249865
              ],
              [
                -73.93052474129993,
                40.591211215577204
              ],
              [
                -73.93053169280374,
                40.59119009299108
              ],
              [
                -73.93042667103755,
                40.591247715831585
              ],
              [
                -73.93037197719016,
                40.591211400875885
              ],
              [
                -73.93044497861847,
                40.591117027231086
              ],
              [
                -73.93050596616035,
                40.59111112823183
              ],
              [
                -73.93050592282728,
                40.591093850738034
              ],
              [
                -73.93072578729826,
                40.591089860265136
              ],
              [
                -73.93072580784407,
                40.59107005495978
              ],
              [
                -73.93044960172921,
                40.591079691929146
              ],
              [
                -73.93044970323461,
                40.59107887126438
              ],
              [
                -73.9304563207586,
                40.5910254169941
              ],
              [
                -73.93069641658052,
                40.59101854140027
              ],
              [
                -73.93069297189139,
                40.59100005480597
              ],
              [
                -73.93046013661869,
                40.59100121902088
              ],
              [
                -73.93046958507561,
                40.590956429572074
              ],
              [
                -73.93049556105831,
                40.590957682658804
              ],
              [
                -73.93049384083261,
                40.59094579825995
              ],
              [
                -73.93052502016778,
                40.590943176015756
              ],
              [
                -73.93052154756077,
                40.590952416342006
              ],
              [
                -73.93064106700564,
                40.59094456641932
              ],
              [
                -73.93064749948525,
                40.59061968417434
              ],
              [
                -73.93062639159919,
                40.59061900114782
              ],
              [
                -73.93062344246573,
                40.590918694276205
              ],
              [
                -73.9305250476421,
                40.590916768383124
              ],
              [
                -73.93052330262896,
                40.590929971990285
              ],
              [
                -73.93049905444629,
                40.590929957326566
              ],
              [
                -73.93049906406719,
                40.59092071339867
              ],
              [
                -73.93047712293603,
                40.59092070012568
              ],
              [
                -73.93047481956741,
                40.59090787720931
              ],
              [
                -73.93047135080229,
                40.590859601197096
              ],
              [
                -73.93047314150454,
                40.590740679401925
              ],
              [
                -73.93048078930119,
                40.59062507739256
              ],
              [
                -73.93052507228651,
                40.59033311342975
              ],
              [
                -73.93063131413255,
                40.59032925910514
              ],
              [
                -73.93062757826256,
                40.59059069309132
              ],
              [
                -73.93067434421376,
                40.5905907213156
              ],
              [
                -73.93065213846425,
                40.59029097949228
              ],
              [
                -73.93063135116711,
                40.59029360754254
              ],
              [
                -73.93062960712314,
                40.59030549085388
              ],
              [
                -73.93052888333264,
                40.590307988677466
              ],
              [
                -73.93053560681557,
                40.5902636614617
              ],
              [
                -73.93046768917159,
                40.59006137258804
              ],
              [
                -73.93056960951894,
                40.590042691535935
              ],
              [
                -73.93058909225768,
                40.590252966543545
              ],
              [
                -73.93062849364412,
                40.59025299033771
              ],
              [
                -73.93058940447355,
                40.58995259113252
              ],
              [
                -73.93055281235699,
                40.58995686078971
              ],
              [
                -73.93056682228968,
                40.590016943606095
              ],
              [
                -73.93045501847267,
                40.59003763271232
              ],
              [
                -73.93044041745254,
                40.59002041123876
              ],
              [
                -73.93032208619142,
                40.59004903480979
              ],
              [
                -73.93029788091665,
                40.59003516763002
              ],
              [
                -73.93037543247318,
                40.5899404597104
              ],
              [
                -73.9303106518833,
                40.589818527255304
              ],
              [
                -73.9301834273728,
                40.58967665569107
              ],
              [
                -73.93010325988745,
                40.58960610507143
              ],
              [
                -73.93016235501894,
                40.589563697105724
              ],
              [
                -73.9302712478111,
                40.58965316360369
              ],
              [
                -73.9302284661763,
                40.589688136024655
              ],
              [
                -73.93034074246636,
                40.58980768423797
              ],
              [
                -73.93038990330076,
                40.58977348527009
              ],
              [
                -73.93054835816316,
                40.58990734463013
              ],
              [
                -73.93057705718242,
                40.589890641203176
              ],
              [
                -73.93043882862878,
                40.58977608680935
              ],
              [
                -73.93042363784221,
                40.5897812224387
              ],
              [
                -73.93039500720478,
                40.58973232891242
              ],
              [
                -73.93032759859494,
                40.58965768991761
              ],
              [
                -73.93015902101656,
                40.58952510921487
              ],
              [
                -73.93003427450557,
                40.589425996500765
              ],
              [
                -73.92976615773311,
                40.58929206944766
              ],
              [
                -73.92957384158932,
                40.58927265737984
              ],
              [
                -73.9293731224163,
                40.58922365916531
              ],
              [
                -73.92936128354346,
                40.58925066298251
              ],
              [
                -73.92961092161859,
                40.589308693552695
              ],
              [
                -73.92975094353098,
                40.58932035518648
              ],
              [
                -73.92975766786877,
                40.58934351118515
              ],
              [
                -73.92986055070119,
                40.58937444315988
              ],
              [
                -73.92998364350571,
                40.58944139963776
              ],
              [
                -73.93001399131899,
                40.58946199760006
              ],
              [
                -73.92998187866192,
                40.589515997878614
              ],
              [
                -73.92990258163894,
                40.58946466728641
              ],
              [
                -73.92976491819753,
                40.589400619507934
              ],
              [
                -73.929645245279,
                40.58936184684617
              ],
              [
                -73.92934866543209,
                40.58930381597758
              ],
              [
                -73.92921570403128,
                40.58928563109156
              ],
              [
                -73.9290841723937,
                40.589261066913096
              ],
              [
                -73.92906310125774,
                40.58929592380375
              ],
              [
                -73.92891656225518,
                40.58926317842035
              ],
              [
                -73.9288859965192,
                40.589252405294935
              ],
              [
                -73.92884679091428,
                40.5892321238758
              ],
              [
                -73.9288784483006,
                40.58920995672331
              ],
              [
                -73.92875703499415,
                40.589152966925
              ],
              [
                -73.92878615917873,
                40.5891327278303
              ],
              [
                -73.9288240915015,
                40.58915976125539
              ],
              [
                -73.92884055219514,
                40.58915012510957
              ],
              [
                -73.92881653421958,
                40.58912792348119
              ],
              [
                -73.92885578380277,
                40.589104795901086
              ],
              [
                -73.92884820698325,
                40.58909128683479
              ],
              [
                -73.92888297663987,
                40.58906895960712
              ],
              [
                -73.92913312005682,
                40.58889660396919
              ],
              [
                -73.92924314818033,
                40.588950691621385
              ],
              [
                -73.92925835283317,
                40.58893237343768
              ],
              [
                -73.9291344038264,
                40.588879241681944
              ],
              [
                -73.92887113950044,
                40.5890576574502
              ],
              [
                -73.92873426467298,
                40.58914716516767
              ],
              [
                -73.92869126291087,
                40.589126881843384
              ],
              [
                -73.92866593842896,
                40.589143264066486
              ],
              [
                -73.928631587027,
                40.589126361638975
              ],
              [
                -73.92879080445435,
                40.58901929255331
              ],
              [
                -73.92881443150283,
                40.58900432654943
              ],
              [
                -73.92895102993238,
                40.58890964240958
              ],
              [
                -73.92888139684439,
                40.58885371957537
              ],
              [
                -73.92886464540858,
                40.58886735631615
              ],
              [
                -73.92891343177544,
                40.58890568422545
              ],
              [
                -73.92891351845398,
                40.58891963277444
              ],
              [
                -73.92885099369292,
                40.58896217032521
              ],
              [
                -73.92882969525398,
                40.58897666015272
              ],
              [
                -73.92880353610383,
                40.588994608624006
              ],
              [
                -73.9287803739474,
                40.5890105015188
              ],
              [
                -73.92876308761618,
                40.58902100394114
              ],
              [
                -73.92865104293678,
                40.58909642248606
              ],
              [
                -73.92865380058103,
                40.589098740794455
              ],
              [
                -73.92862026499205,
                40.58912043629614
              ],
              [
                -73.92844090493661,
                40.58903042814201
              ],
              [
                -73.92837745971521,
                40.58898132361609
              ],
              [
                -73.92838268998239,
                40.58897773722424
              ],
              [
                -73.92843184870172,
                40.58894403263148
              ],
              [
                -73.92844312664116,
                40.588952920722306
              ],
              [
                -73.92846910760312,
                40.588932555108265
              ],
              [
                -73.92839582804494,
                40.58887850436324
              ],
              [
                -73.92839036856563,
                40.588863457828595
              ],
              [
                -73.92845089882498,
                40.588818250208014
              ],
              [
                -73.92849655735782,
                40.58878414921092
              ],
              [
                -73.92851423024513,
                40.58877095118618
              ],
              [
                -73.92853560735229,
                40.58875564626781
              ],
              [
                -73.92856305715722,
                40.588735995723496
              ],
              [
                -73.92859836726451,
                40.588710018954586
              ],
              [
                -73.92862784709543,
                40.58868833082378
              ],
              [
                -73.92864656679113,
                40.58867455913807
              ],
              [
                -73.92869567082195,
                40.588638434100844
              ],
              [
                -73.92874984838602,
                40.588680648086715
              ],
              [
                -73.9287576155367,
                40.58867429700575
              ],
              [
                -73.92874926804639,
                40.58866558532836
              ],
              [
                -73.92873407743991,
                40.588655223094925
              ],
              [
                -73.92863061415636,
                40.588572636420736
              ],
              [
                -73.92862139018179,
                40.58858029583019
              ],
              [
                -73.92867656173934,
                40.58862566755948
              ],
              [
                -73.928673449338,
                40.58863516487811
              ],
              [
                -73.92861252736496,
                40.5886781018475
              ],
              [
                -73.92855975046837,
                40.58871530010423
              ],
              [
                -73.92854348148691,
                40.58872676541511
              ],
              [
                -73.92852165883288,
                40.588743206409596
              ],
              [
                -73.92849709626647,
                40.58876171082953
              ],
              [
                -73.92847329475423,
                40.58877954325496
              ],
              [
                -73.92842900670269,
                40.58881272670058
              ],
              [
                -73.92835866230406,
                40.588865431329005
              ],
              [
                -73.9283361999493,
                40.58886253531396
              ],
              [
                -73.92822539531493,
                40.58877625470065
              ],
              [
                -73.92821616994843,
                40.588783912903885
              ],
              [
                -73.92835661259966,
                40.58888924619399
              ],
              [
                -73.92836438201132,
                40.58888289346586
              ],
              [
                -73.92837977293736,
                40.58888637359861
              ],
              [
                -73.92839594752826,
                40.58889803316907
              ],
              [
                -73.92836947692139,
                40.58891821568689
              ],
              [
                -73.92839694020006,
                40.58894062377454
              ],
              [
                -73.92841297161772,
                40.58892903419734
              ],
              [
                -73.92841885809028,
                40.58893403509809
              ],
              [
                -73.92836783373292,
                40.588965824834474
              ],
              [
                -73.92836271266806,
                40.58896901609184
              ],
              [
                -73.92815905123096,
                40.58882524958995
              ],
              [
                -73.92776465896623,
                40.58907718211488
              ],
              [
                -73.92777636022275,
                40.58910220009743
              ],
              [
                -73.92725962405339,
                40.5894521425307
              ],
              [
                -73.9269617394228,
                40.589645266707684
              ],
              [
                -73.92665232672293,
                40.589845861094936
              ],
              [
                -73.92635608508674,
                40.59003791473551
              ],
              [
                -73.92615947414338,
                40.59016537791108
              ],
              [
                -73.92602962522365,
                40.59024379563498
              ],
              [
                -73.92587504247338,
                40.590337151599265
              ],
              [
                -73.92588801019414,
                40.59035203456
              ],
              [
                -73.92536357858714,
                40.59067334024729
              ],
              [
                -73.92509358650521,
                40.59083567992229
              ],
              [
                -73.92508693133408,
                40.59082975057346
              ],
              [
                -73.92495914742956,
                40.59091515674974
              ],
              [
                -73.92497245564526,
                40.59092870884723
              ],
              [
                -73.92492023633986,
                40.59095914581434
              ],
              [
                -73.92491025194087,
                40.5909515213958
              ],
              [
                -73.92475692077481,
                40.59104452971624
              ],
              [
                -73.92477134199518,
                40.591056389011506
              ],
              [
                -73.92463689474579,
                40.59114179046576
              ],
              [
                -73.9246568634645,
                40.591157885874786
              ],
              [
                -73.9246012993856,
                40.591199325003174
              ],
              [
                -73.92452686320941,
                40.591238211798434
              ],
              [
                -73.92451798743379,
                40.5912314344323
              ],
              [
                -73.92444799020575,
                40.591272016976234
              ],
              [
                -73.92443135419055,
                40.59125592422531
              ],
              [
                -73.9243635723439,
                40.591300741808645
              ],
              [
                -73.9243502582044,
                40.591292268624144
              ],
              [
                -73.9242824753091,
                40.59133793033905
              ],
              [
                -73.92425997013223,
                40.591321446703176
              ],
              [
                -73.9241996339265,
                40.59136428018873
              ],
              [
                -73.92407257175674,
                40.5912486814702
              ],
              [
                -73.9239529485009,
                40.59113985052502
              ],
              [
                -73.92395467165778,
                40.5911386873365
              ],
              [
                -73.92424735748756,
                40.59094101895615
              ],
              [
                -73.92454610016368,
                40.59073925905267
              ],
              [
                -73.92483070193937,
                40.59054704767478
              ],
              [
                -73.92513959885656,
                40.59035444080562
              ],
              [
                -73.92544308131156,
                40.59016520731763
              ],
              [
                -73.92575347866315,
                40.58997165992739
              ],
              [
                -73.9260602421983,
                40.58978037770559
              ],
              [
                -73.9263611882989,
                40.58959271895041
              ],
              [
                -73.9266528016335,
                40.58941087961657
              ],
              [
                -73.92662861634162,
                40.58938412866683
              ],
              [
                -73.92665299278437,
                40.58936849665159
              ],
              [
                -73.92695102774618,
                40.58918207521067
              ],
              [
                -73.92711186317564,
                40.589081472259394
              ],
              [
                -73.92776143639102,
                40.58866060975881
              ],
              [
                -73.92763372065457,
                40.58845892052031
              ],
              [
                -73.92755035972286,
                40.588513531820674
              ],
              [
                -73.92753942773564,
                40.58849382883325
              ],
              [
                -73.92743489681918,
                40.588369014793585
              ],
              [
                -73.92748612581521,
                40.588320574518725
              ],
              [
                -73.92745467790976,
                40.58829956407405
              ],
              [
                -73.92747241644948,
                40.588289117585504
              ],
              [
                -73.9275032776782,
                40.58826938434164
              ],
              [
                -73.92752878258517,
                40.58824788949044
              ],
              [
                -73.92751674803628,
                40.588236391561736
              ],
              [
                -73.92752258162788,
                40.588232089736294
              ],
              [
                -73.92751741750983,
                40.58822596562528
              ],
              [
                -73.92752569167634,
                40.58822165444986
              ],
              [
                -73.92754016723876,
                40.588233143865324
              ],
              [
                -73.92754721531419,
                40.58822790681791
              ],
              [
                -73.92755751586084,
                40.588235502728686
              ],
              [
                -73.9275672425981,
                40.588228952598264
              ],
              [
                -73.92755543879122,
                40.58821522114957
              ],
              [
                -73.92764121499813,
                40.588164131325144
              ],
              [
                -73.92768070085903,
                40.58814061294461
              ],
              [
                -73.92769483165648,
                40.58813219695235
              ],
              [
                -73.92776892978343,
                40.58808806153411
              ],
              [
                -73.92778443159247,
                40.588078829016816
              ],
              [
                -73.92779092085868,
                40.58807496400388
              ],
              [
                -73.92784847367838,
                40.58804068410847
              ],
              [
                -73.92785876092728,
                40.588034556479315
              ],
              [
                -73.9279231804831,
                40.58799618727679
              ],
              [
                -73.92800676518989,
                40.58807699939624
              ],
              [
                -73.92802185276429,
                40.588068756350886
              ],
              [
                -73.9279017574099,
                40.58795340927988
              ],
              [
                -73.92789056855494,
                40.58796033562995
              ],
              [
                -73.92790482500476,
                40.58797592078823
              ],
              [
                -73.92777244932779,
                40.58805697180036
              ],
              [
                -73.92763603930852,
                40.588140492014944
              ],
              [
                -73.92750396203982,
                40.58822136013032
              ],
              [
                -73.92744551755536,
                40.588206304231726
              ],
              [
                -73.92742552260684,
                40.58821939139703
              ],
              [
                -73.92740450151646,
                40.58820650448981
              ],
              [
                -73.9273901314655,
                40.58817864281224
              ],
              [
                -73.92743823840641,
                40.588147934742985
              ],
              [
                -73.92736935753528,
                40.58804459880299
              ],
              [
                -73.92747502650555,
                40.58797967687832
              ],
              [
                -73.92746925071934,
                40.58797306051263
              ],
              [
                -73.92759326264297,
                40.58789506450304
              ],
              [
                -73.92760667965281,
                40.587886644721095
              ],
              [
                -73.92767538027489,
                40.587843314123404
              ],
              [
                -73.92767581765723,
                40.58784303881275
              ],
              [
                -73.92782113152668,
                40.58775138740755
              ],
              [
                -73.92785030679849,
                40.587732985371986
              ],
              [
                -73.92795555226466,
                40.58766660355718
              ],
              [
                -73.92803041429266,
                40.587731484346115
              ],
              [
                -73.92807906817849,
                40.58770246052319
              ],
              [
                -73.92791625741049,
                40.587548736392016
              ],
              [
                -73.92787393831544,
                40.58757550348134
              ],
              [
                -73.92787426020541,
                40.58758815885383
              ],
              [
                -73.92794598351136,
                40.58765882000514
              ],
              [
                -73.92779832443665,
                40.58774941985335
              ],
              [
                -73.92773463890906,
                40.58778849541703
              ],
              [
                -73.92772506988965,
                40.587794365994355
              ],
              [
                -73.92765584309352,
                40.587836842728464
              ],
              [
                -73.92764640357963,
                40.58784263380434
              ],
              [
                -73.92758002886069,
                40.58788325840832
              ],
              [
                -73.92756846941398,
                40.58789045187032
              ],
              [
                -73.9275058017439,
                40.5879289015562
              ],
              [
                -73.92749456893686,
                40.58793579550671
              ],
              [
                -73.92746402494043,
                40.58795453583472
              ],
              [
                -73.92739188174689,
                40.587998800117184
              ],
              [
                -73.9273568092889,
                40.58802217627617
              ],
              [
                -73.92727932188905,
                40.58790916939882
              ],
              [
                -73.92726483869893,
                40.58788299850715
              ],
              [
                -73.92731915293669,
                40.58784992990631
              ],
              [
                -73.92724312575965,
                40.58776905070255
              ],
              [
                -73.92724750521369,
                40.58775327471978
              ],
              [
                -73.92727720149654,
                40.587738464989634
              ],
              [
                -73.92729944851459,
                40.58770320726212
              ],
              [
                -73.92732601574134,
                40.5876865790183
              ],
              [
                -73.92734349185156,
                40.58767564118687
              ],
              [
                -73.92735333435394,
                40.58766988322635
              ],
              [
                -73.9273705958315,
                40.58765867720809
              ],
              [
                -73.9273874493916,
                40.587648128649874
              ],
              [
                -73.9276119876809,
                40.58750759321528
              ],
              [
                -73.92766599128431,
                40.58747501343975
              ],
              [
                -73.92768287747319,
                40.58736160013402
              ],
              [
                -73.92766845967759,
                40.58735941719748
              ],
              [
                -73.92765006933621,
                40.58746669328727
              ],
              [
                -73.92762064976755,
                40.587486713955094
              ],
              [
                -73.92757304438481,
                40.58751583005148
              ],
              [
                -73.92756029198215,
                40.58752362921735
              ],
              [
                -73.92753045676024,
                40.58754187764159
              ],
              [
                -73.92750198619638,
                40.587559290779936
              ],
              [
                -73.9274700309266,
                40.58757883503081
              ],
              [
                -73.92743892751554,
                40.58759785829185
              ],
              [
                -73.92740972113143,
                40.587615721094
              ],
              [
                -73.92738589449549,
                40.58763029299064
              ],
              [
                -73.92734003426652,
                40.58765689078618
              ],
              [
                -73.92729373170548,
                40.58768666129003
              ],
              [
                -73.92725820254594,
                40.58770577334006
              ],
              [
                -73.92720774188899,
                40.5877227591867
              ],
              [
                -73.92715928733517,
                40.587661147188086
              ],
              [
                -73.92711083019412,
                40.587617691500114
              ],
              [
                -73.9270993562571,
                40.58759103716301
              ],
              [
                -73.92711984469311,
                40.587579713111076
              ],
              [
                -73.92710524303517,
                40.58757160329974
              ],
              [
                -73.92714112938127,
                40.58755137326473
              ],
              [
                -73.92717032872173,
                40.58757164457216
              ],
              [
                -73.92718761078223,
                40.58755950327781
              ],
              [
                -73.92696733834302,
                40.587353795865745
              ],
              [
                -73.92694208311515,
                40.58736896953345
              ],
              [
                -73.92712785833075,
                40.58754022531675
              ],
              [
                -73.9270946291992,
                40.58756045703374
              ],
              [
                -73.92708799146784,
                40.58755640263126
              ],
              [
                -73.92706884928583,
                40.5875666615426
              ],
              [
                -73.92700073232174,
                40.58750153462453
              ],
              [
                -73.92695782460427,
                40.587451968578875
              ],
              [
                -73.92687946572465,
                40.58740958238608
              ],
              [
                -73.92683150549894,
                40.5873736229982
              ],
              [
                -73.92677874563444,
                40.58733038506487
              ],
              [
                -73.9268423273604,
                40.58728968995381
              ],
              [
                -73.92673977671522,
                40.58718850671609
              ],
              [
                -73.92670333122614,
                40.58720409151719
              ],
              [
                -73.92658537503537,
                40.58713308560274
              ],
              [
                -73.92653260420076,
                40.587120911017614
              ],
              [
                -73.92649781833158,
                40.58709608235675
              ],
              [
                -73.92657322890143,
                40.587049125842086
              ],
              [
                -73.92647284761263,
                40.58696201521452
              ],
              [
                -73.92637018571116,
                40.586871420938216
              ],
              [
                -73.92630373489585,
                40.58690211797236
              ],
              [
                -73.92628231953488,
                40.58688625846585
              ],
              [
                -73.92628800917508,
                40.586843513469674
              ],
              [
                -73.92621271663319,
                40.5867842729822
              ],
              [
                -73.9261305753202,
                40.58672328774674
              ],
              [
                -73.92607581839893,
                40.58667972989756
              ],
              [
                -73.92598398427617,
                40.58668024004204
              ],
              [
                -73.92600279804184,
                40.58663093686223
              ],
              [
                -73.9259343533844,
                40.58657518269955
              ],
              [
                -73.92579061919318,
                40.58645844726843
              ],
              [
                -73.92570600948345,
                40.586467491616304
              ],
              [
                -73.92570847285627,
                40.58640268462251
              ],
              [
                -73.92564689237776,
                40.58633474880302
              ],
              [
                -73.92559446148006,
                40.5862528906471
              ],
              [
                -73.92554277632239,
                40.58619329007063
              ],
              [
                -73.9254183568754,
                40.58619651406238
              ],
              [
                -73.92546644505285,
                40.58609236910719
              ],
              [
                -73.92528378572595,
                40.58589815289526
              ],
              [
                -73.9252225445941,
                40.58587904435367
              ],
              [
                -73.92522694757393,
                40.585839824560125
              ],
              [
                -73.92520361514241,
                40.585806271155384
              ],
              [
                -73.92507664964324,
                40.58575987157345
              ],
              [
                -73.9249933142654,
                40.585712845971294
              ],
              [
                -73.92493840743964,
                40.58570024105191
              ],
              [
                -73.92486051151299,
                40.58571339815731
              ],
              [
                -73.92483669450533,
                40.585683013012336
              ],
              [
                -73.92480731263153,
                40.58565349922682
              ],
              [
                -73.92475709799683,
                40.585632575099325
              ],
              [
                -73.9246794528378,
                40.58563774645343
              ],
              [
                -73.92456754948067,
                40.5856463773686
              ],
              [
                -73.9243231453673,
                40.585705408319676
              ],
              [
                -73.92429576856087,
                40.5856827589019
              ],
              [
                -73.92402182009499,
                40.58577353533212
              ],
              [
                -73.92401064509494,
                40.58578022576852
              ],
              [
                -73.92400933327511,
                40.5858047063112
              ],
              [
                -73.92404909560508,
                40.585854675972136
              ],
              [
                -73.92406705274973,
                40.58587721116697
              ],
              [
                -73.92406317839455,
                40.58589483571767
              ],
              [
                -73.92404262147038,
                40.5858997188474
              ],
              [
                -73.92401693919693,
                40.58589284589225
              ],
              [
                -73.92397204036718,
                40.585841894617424
              ],
              [
                -73.92392584582923,
                40.585800734226915
              ],
              [
                -73.92389501884094,
                40.58580071381236
              ],
              [
                -73.92385770220567,
                40.585858466135356
              ],
              [
                -73.92369833045592,
                40.585941599249466
              ],
              [
                -73.92353515890866,
                40.585977723872205
              ],
              [
                -73.92334118079789,
                40.585995220555425
              ],
              [
                -73.92313312983316,
                40.58596276647312
              ],
              [
                -73.92302013749716,
                40.58592645722694
              ],
              [
                -73.92296409826356,
                40.585894457063766
              ],
              [
                -73.92284926622335,
                40.58595572141795
              ],
              [
                -73.92282358156666,
                40.58595080795373
              ],
              [
                -73.92271691647164,
                40.585996761618006
              ],
              [
                -73.92262827740261,
                40.58600453563984
              ],
              [
                -73.92256267742543,
                40.5860087299414
              ],
              [
                -73.92255248710735,
                40.58600938129622
              ],
              [
                -73.92246128010449,
                40.58601617525402
              ],
              [
                -73.92235337377305,
                40.58602393656231
              ],
              [
                -73.92230200146534,
                40.58601802573992
              ],
              [
                -73.92230460012115,
                40.58599256620722
              ],
              [
                -73.92216035285345,
                40.58585611721177
              ],
              [
                -73.92211851289852,
                40.58581051415299
              ],
              [
                -73.92188902702776,
                40.585821125656516
              ],
              [
                -73.9218992855612,
                40.58584461338752
              ],
              [
                -73.9218669875511,
                40.58583203777864
              ],
              [
                -73.92184819107767,
                40.5858384214364
              ],
              [
                -73.9219481743886,
                40.58571175204423
              ],
              [
                -73.92189955122011,
                40.58569317567348
              ],
              [
                -73.92183560839585,
                40.58577657692656
              ],
              [
                -73.92180978578286,
                40.585759174325084
              ],
              [
                -73.92181131953878,
                40.58574758568276
              ],
              [
                -73.92178148758434,
                40.585733486204724
              ],
              [
                -73.92175809215335,
                40.58576609277944
              ],
              [
                -73.92178240271222,
                40.58577654071906
              ],
              [
                -73.9217626135607,
                40.585799705445304
              ],
              [
                -73.92153288620224,
                40.58576690098837
              ],
              [
                -73.9215142802177,
                40.585781337526406
              ],
              [
                -73.92147080849863,
                40.58574619516197
              ],
              [
                -73.92128230738994,
                40.58574954274458
              ],
              [
                -73.92117707765658,
                40.58574646780549
              ],
              [
                -73.92110266358986,
                40.585741450321855
              ],
              [
                -73.92108167330595,
                40.585728545186846
              ],
              [
                -73.92103757896896,
                40.585736626332256
              ],
              [
                -73.92101224379708,
                40.58575355586075
              ],
              [
                -73.92101218266103,
                40.58575359585823
              ],
              [
                -73.92094410567492,
                40.58564899408574
              ],
              [
                -73.92090603836381,
                40.585590581533616
              ],
              [
                -73.9208784302737,
                40.58559946336436
              ],
              [
                -73.92091104290725,
                40.58565005223212
              ],
              [
                -73.92098905633135,
                40.585769048620286
              ],
              [
                -73.92097520370773,
                40.585778304978994
              ],
              [
                -73.92090217579442,
                40.58583040647379
              ],
              [
                -73.92092948644265,
                40.58587330627243
              ],
              [
                -73.92089145806791,
                40.58590306230564
              ],
              [
                -73.9207819787998,
                40.58580060411214
              ],
              [
                -73.92077774028736,
                40.58580382847681
              ],
              [
                -73.92073896679761,
                40.58577205976685
              ],
              [
                -73.92076368725981,
                40.585753785451
              ],
              [
                -73.92073901660137,
                40.58573009669359
              ],
              [
                -73.9206698086596,
                40.585773626519675
              ],
              [
                -73.9206973025787,
                40.58579731724049
              ],
              [
                -73.92072837493505,
                40.58577797077197
              ],
              [
                -73.92076503207028,
                40.58580973752351
              ],
              [
                -73.9207586767622,
                40.58581296160033
              ],
              [
                -73.92086842926197,
                40.585921080321086
              ],
              [
                -73.92082145662427,
                40.5859578344086
              ],
              [
                -73.92082156941888,
                40.5859957742063
              ],
              [
                -73.92071501761441,
                40.58598209801123
              ],
              [
                -73.92062531128488,
                40.58599594403365
              ],
              [
                -73.92055080816567,
                40.58600979932683
              ],
              [
                -73.92047021116144,
                40.5860340817413
              ],
              [
                -73.92040498231091,
                40.586089779645754
              ],
              [
                -73.9203607206783,
                40.5860676143172
              ],
              [
                -73.92029535641578,
                40.58606525189371
              ],
              [
                -73.92027733491835,
                40.586063777026595
              ],
              [
                -73.92027770472494,
                40.58602606138264
              ],
              [
                -73.92021874083052,
                40.58602697788635
              ],
              [
                -73.92021749158332,
                40.586022193886166
              ],
              [
                -73.92020745883667,
                40.586019317475255
              ],
              [
                -73.92020248303365,
                40.58598392644289
              ],
              [
                -73.92025517678752,
                40.58598109358259
              ],
              [
                -73.92025268900592,
                40.585962919603354
              ],
              [
                -73.92021129118353,
                40.58596193460527
              ],
              [
                -73.92020630052704,
                40.585938976926904
              ],
              [
                -73.92017117347483,
                40.58593990878502
              ],
              [
                -73.92017489750616,
                40.585972430677764
              ],
              [
                -73.92004691580856,
                40.58598860025636
              ],
              [
                -73.92004940419412,
                40.586006774240296
              ],
              [
                -73.92018491195508,
                40.58599060988719
              ],
              [
                -73.92018863964579,
                40.58602026183536
              ],
              [
                -73.92018236611428,
                40.58602121323273
              ],
              [
                -73.92018236154185,
                40.58602503893172
              ],
              [
                -73.92013970735708,
                40.586025009293046
              ],
              [
                -73.92013922923803,
                40.5860569452636
              ],
              [
                -73.92005215131567,
                40.586050016670605
              ],
              [
                -73.91997613293829,
                40.58605923516502
              ],
              [
                -73.9198930012924,
                40.58609162299616
              ],
              [
                -73.91983470833878,
                40.58610085810948
              ],
              [
                -73.91973129637576,
                40.58613555430021
              ],
              [
                -73.91961116419868,
                40.586169079899925
              ],
              [
                -73.91951394035418,
                40.58611454064769
              ],
              [
                -73.9195054592814,
                40.586133692869545
              ],
              [
                -73.91943489358086,
                40.58611332695053
              ],
              [
                -73.91940300812695,
                40.58608201277151
              ],
              [
                -73.9193270820224,
                40.586014741486196
              ],
              [
                -73.91927750147164,
                40.586014726935204
              ],
              [
                -73.91923435968188,
                40.58600888150374
              ],
              [
                -73.9191903317235,
                40.5859624941477
              ],
              [
                -73.91904637464812,
                40.58605874083082
              ],
              [
                -73.91900017409613,
                40.586077094625544
              ],
              [
                -73.91896974793146,
                40.5860956154972
              ],
              [
                -73.91894085082454,
                40.586107184130526
              ],
              [
                -73.91888988807571,
                40.58613479655335
              ],
              [
                -73.9188480729358,
                40.586147681871296
              ],
              [
                -73.91881005548656,
                40.5861592446999
              ],
              [
                -73.91877052073922,
                40.586167328699766
              ],
              [
                -73.91871030551792,
                40.5861916737085
              ],
              [
                -73.91862753793326,
                40.58624024037158
              ],
              [
                -73.91858038529266,
                40.58626338684265
              ],
              [
                -73.91855338716753,
                40.5862999345243
              ],
              [
                -73.91848607837932,
                40.58630967637503
              ],
              [
                -73.91843893816062,
                40.58632239140945
              ],
              [
                -73.91839025484447,
                40.586353648155765
              ],
              [
                -73.91831572119233,
                40.586390681349776
              ],
              [
                -73.91826250403695,
                40.58640428626349
              ],
              [
                -73.91823846308047,
                40.5864079422415
              ],
              [
                -73.91820323428233,
                40.5863871235826
              ],
              [
                -73.9181758618163,
                40.58639521720816
              ],
              [
                -73.91817127183086,
                40.58641955110694
              ],
              [
                -73.91813980819195,
                40.586445867169104
              ],
              [
                -73.91811498304048,
                40.58645427989567
              ],
              [
                -73.91808352411303,
                40.58643247412283
              ],
              [
                -73.91805571865743,
                40.58643685311621
              ],
              [
                -73.91812555866335,
                40.586507597473656
              ],
              [
                -73.91809293895267,
                40.586570621122036
              ],
              [
                -73.91819087799986,
                40.58660685499996
              ],
              [
                -73.91972510799992,
                40.5879872779999
              ],
              [
                -73.92095992099986,
                40.58909565099987
              ],
              [
                -73.922307925,
                40.5903082079999
              ],
              [
                -73.92332177899988,
                40.591211915999956
              ],
              [
                -73.92363677299986,
                40.5914953699999
              ],
              [
                -73.92405498699993,
                40.591871702999875
              ],
              [
                -73.92440413399991,
                40.59218425199988
              ],
              [
                -73.9254714779999,
                40.59314878799997
              ],
              [
                -73.92653305100005,
                40.59410431499987
              ],
              [
                -73.92759936700004,
                40.5950607789999
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0000397291966087,
        "objectid": 211,
        "shape_leng": 0.0252345082132,
        "location_id": 211,
        "zone": "SoHo",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -74.001523638,
                40.71982079799995
              ],
              [
                -74.00142450999995,
                40.71993719799993
              ],
              [
                -74.000660725,
                40.72083979399993
              ],
              [
                -73.99985330399981,
                40.721791787999926
              ],
              [
                -73.99952163199998,
                40.72218334699987
              ],
              [
                -73.9988117219999,
                40.72302140899991
              ],
              [
                -73.9977850889999,
                40.724234893999906
              ],
              [
                -73.99684295899995,
                40.725347837999905
              ],
              [
                -73.9967717579999,
                40.72543194399992
              ],
              [
                -73.99761019599993,
                40.72579713799992
              ],
              [
                -73.99836119299998,
                40.72616746799987
              ],
              [
                -73.9991182469999,
                40.72654231299988
              ],
              [
                -73.99991579199988,
                40.726934712999864
              ],
              [
                -74.00063239199994,
                40.727286611999915
              ],
              [
                -74.00139033499991,
                40.727660001999894
              ],
              [
                -74.00214891499996,
                40.728035366999904
              ],
              [
                -74.00221100999997,
                40.727961316999874
              ],
              [
                -74.00251324599992,
                40.72760092699993
              ],
              [
                -74.00312206399995,
                40.72687712799991
              ],
              [
                -74.003730175,
                40.726250058999945
              ],
              [
                -74.00403067899997,
                40.72551249599992
              ],
              [
                -74.00427457099987,
                40.72491210899993
              ],
              [
                -74.00457474399995,
                40.72417313099995
              ],
              [
                -74.00478574899996,
                40.7236248379999
              ],
              [
                -74.00505887099996,
                40.7229778639999
              ],
              [
                -74.00536435999994,
                40.722183951999966
              ],
              [
                -74.00538616799996,
                40.722112360999894
              ],
              [
                -74.00539186699989,
                40.7220373659999
              ],
              [
                -74.00538048599999,
                40.721962173999934
              ],
              [
                -74.00535249999994,
                40.72189013499991
              ],
              [
                -74.0053098609999,
                40.72182419199987
              ],
              [
                -74.00522684899992,
                40.72174422199989
              ],
              [
                -74.00463516199997,
                40.721353632999865
              ],
              [
                -74.00392928399991,
                40.72085443499993
              ],
              [
                -74.00351186399998,
                40.7205563519999
              ],
              [
                -74.0032595509999,
                40.72037709599996
              ],
              [
                -74.00260001899987,
                40.719903425999874
              ],
              [
                -74.00188406299992,
                40.71939757099991
              ],
              [
                -74.001523638,
                40.71982079799995
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000811976523012,
        "objectid": 214,
        "shape_leng": 0.135300314246,
        "location_id": 214,
        "zone": "South Beach/Dongan Hills",
        "borough": "Staten Island"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -74.08491019899996,
                40.59570472799985
              ],
              [
                -74.08578780799998,
                40.59588407999997
              ],
              [
                -74.08587511899997,
                40.59589012099985
              ],
              [
                -74.08585350599992,
                40.59594098099991
              ],
              [
                -74.08547413999999,
                40.59683381699989
              ],
              [
                -74.08532650099991,
                40.59700832199998
              ],
              [
                -74.08515829199987,
                40.59717273799993
              ],
              [
                -74.08515824499993,
                40.597172791999874
              ],
              [
                -74.0851581859999,
                40.59717283699994
              ],
              [
                -74.08497132899997,
                40.597325109999865
              ],
              [
                -74.08476818599993,
                40.59746346299988
              ],
              [
                -74.08476811499993,
                40.59746351699994
              ],
              [
                -74.08476803199989,
                40.59746356299997
              ],
              [
                -74.08469129699996,
                40.59750713199992
              ],
              [
                -74.08360775099996,
                40.597912413999936
              ],
              [
                -74.08248303399992,
                40.598329807999924
              ],
              [
                -74.0823092379999,
                40.59845665499995
              ],
              [
                -74.08199990199994,
                40.598682437999884
              ],
              [
                -74.08160410699992,
                40.59902445899997
              ],
              [
                -74.0814942209999,
                40.599081990999935
              ],
              [
                -74.08144216599995,
                40.599159219999905
              ],
              [
                -74.08130343799998,
                40.59938765599993
              ],
              [
                -74.08116420899995,
                40.600206553999946
              ],
              [
                -74.08119595999997,
                40.60041517299986
              ],
              [
                -74.081216903,
                40.600547931999905
              ],
              [
                -74.08125785899989,
                40.600678847999866
              ],
              [
                -74.081318208,
                40.600805328999904
              ],
              [
                -74.0813965889999,
                40.60092495199991
              ],
              [
                -74.08184498199992,
                40.601535196999926
              ],
              [
                -74.08218729299988,
                40.60194100799988
              ],
              [
                -74.082815161,
                40.60197418199989
              ],
              [
                -74.08383802499996,
                40.60192576699993
              ],
              [
                -74.08432821299992,
                40.60187268299991
              ],
              [
                -74.08457153399995,
                40.601822479999974
              ],
              [
                -74.08520894199994,
                40.60160095799992
              ],
              [
                -74.085837971,
                40.60142777299994
              ],
              [
                -74.08625420399993,
                40.60132418899987
              ],
              [
                -74.08647535899998,
                40.60126896199992
              ],
              [
                -74.08666235099996,
                40.6012335389999
              ],
              [
                -74.0883067039999,
                40.60110777999993
              ],
              [
                -74.08920169499996,
                40.600963789999916
              ],
              [
                -74.08947630599992,
                40.60090432199992
              ],
              [
                -74.08983655899988,
                40.60077409499996
              ],
              [
                -74.09010463499996,
                40.600592348999896
              ],
              [
                -74.09036073899993,
                40.60040036399992
              ],
              [
                -74.09060390399996,
                40.60019882399991
              ],
              [
                -74.09083323299996,
                40.59998852399984
              ],
              [
                -74.09147742399989,
                40.59940848999992
              ],
              [
                -74.0916133049999,
                40.59926156199986
              ],
              [
                -74.09172051499995,
                40.59922085699985
              ],
              [
                -74.09171640399988,
                40.59913660299989
              ],
              [
                -74.09171809699998,
                40.5990523009999
              ],
              [
                -74.09172558899995,
                40.59896818299989
              ],
              [
                -74.09173885999999,
                40.59888447899993
              ],
              [
                -74.09175787199996,
                40.598801421999916
              ],
              [
                -74.09178257499993,
                40.598719238999884
              ],
              [
                -74.09192827899987,
                40.598504050999935
              ],
              [
                -74.09228640999991,
                40.59808239999995
              ],
              [
                -74.09272305199994,
                40.59746426399992
              ],
              [
                -74.09336426799996,
                40.59703306899991
              ],
              [
                -74.09375453799998,
                40.5967448899999
              ],
              [
                -74.09427668499993,
                40.59644927099988
              ],
              [
                -74.09490220899986,
                40.59629562299991
              ],
              [
                -74.09586192699999,
                40.5959508889999
              ],
              [
                -74.09722072899997,
                40.59536515499987
              ],
              [
                -74.09798651199996,
                40.59492968699989
              ],
              [
                -74.09806839099988,
                40.59488151999992
              ],
              [
                -74.0987378739999,
                40.59448771499993
              ],
              [
                -74.09908179799994,
                40.59428707099988
              ],
              [
                -74.09949192299995,
                40.594047780999915
              ],
              [
                -74.09979588999995,
                40.593871365999895
              ],
              [
                -74.10006671900001,
                40.59364027099992
              ],
              [
                -74.10041498999992,
                40.59334309299989
              ],
              [
                -74.10053851599993,
                40.59318320499992
              ],
              [
                -74.10064720699991,
                40.59301685499988
              ],
              [
                -74.10064730199993,
                40.59301670199992
              ],
              [
                -74.1006473839999,
                40.59301653999994
              ],
              [
                -74.10074036799995,
                40.592844988999914
              ],
              [
                -74.10074047299992,
                40.592844782999926
              ],
              [
                -74.10074056699987,
                40.592844566999894
              ],
              [
                -74.100817394,
                40.592668734999904
              ],
              [
                -74.10083052299994,
                40.592632305999885
              ],
              [
                -74.10097579699995,
                40.591832233999845
              ],
              [
                -74.10090957399993,
                40.59102161399988
              ],
              [
                -74.10089056999995,
                40.59042994899993
              ],
              [
                -74.10088251699995,
                40.590179215999925
              ],
              [
                -74.10149482299994,
                40.58934426099992
              ],
              [
                -74.10207990099998,
                40.588741750999866
              ],
              [
                -74.10246256599999,
                40.58839633599994
              ],
              [
                -74.10256758199995,
                40.588301540999964
              ],
              [
                -74.10282423999995,
                40.58806986599994
              ],
              [
                -74.10292541199989,
                40.5879785399999
              ],
              [
                -74.10317352499997,
                40.58775457099988
              ],
              [
                -74.10328237999991,
                40.58765630999993
              ],
              [
                -74.10344974399993,
                40.587505230999945
              ],
              [
                -74.10357323099993,
                40.587393759999884
              ],
              [
                -74.10392537999996,
                40.58707587399994
              ],
              [
                -74.10454864599998,
                40.58650064899995
              ],
              [
                -74.10525775499997,
                40.585921435999865
              ],
              [
                -74.10278862899995,
                40.58422448699991
              ],
              [
                -74.10265901099994,
                40.584088199999954
              ],
              [
                -74.10249695999993,
                40.58401503299994
              ],
              [
                -74.1006131089999,
                40.58271119099991
              ],
              [
                -74.09844000899997,
                40.581241705999915
              ],
              [
                -74.09908893799992,
                40.58072522899993
              ],
              [
                -74.09974044199997,
                40.58020781799998
              ],
              [
                -74.10039083499996,
                40.579688665999925
              ],
              [
                -74.09940090399988,
                40.57918675599992
              ],
              [
                -74.09851032499989,
                40.5787306869999
              ],
              [
                -74.09741191099992,
                40.57817443199992
              ],
              [
                -74.09613172499989,
                40.57752610599994
              ],
              [
                -74.09517725099997,
                40.57702113899996
              ],
              [
                -74.09483640599994,
                40.57680653799986
              ],
              [
                -74.09408671799999,
                40.57641955099989
              ],
              [
                -74.09335651099995,
                40.576047193999926
              ],
              [
                -74.09257214599995,
                40.57567191299993
              ],
              [
                -74.09182234199987,
                40.575274932999896
              ],
              [
                -74.0910942809999,
                40.57489922899992
              ],
              [
                -74.09032842599996,
                40.57451533599988
              ],
              [
                -74.08960544799997,
                40.57413046999991
              ],
              [
                -74.08886977699989,
                40.5737232379999
              ],
              [
                -74.08775041799993,
                40.57311057999993
              ],
              [
                -74.08769737599994,
                40.57306188399987
              ],
              [
                -74.08765791399992,
                40.57301266499997
              ],
              [
                -74.08597647499992,
                40.572236970999874
              ],
              [
                -74.08495715819193,
                40.57125984002107
              ],
              [
                -74.08459134860526,
                40.57155929050495
              ],
              [
                -74.08359916061018,
                40.572371475370566
              ],
              [
                -74.08300620740228,
                40.57278557004957
              ],
              [
                -74.08259185204045,
                40.57314951590207
              ],
              [
                -74.08230682682282,
                40.57342250310401
              ],
              [
                -74.08173725351443,
                40.57393857852344
              ],
              [
                -74.08133770718658,
                40.57431998930681
              ],
              [
                -74.08090683283069,
                40.574689804751486
              ],
              [
                -74.08050289147513,
                40.57498045871066
              ],
              [
                -74.08017867442044,
                40.575180418259556
              ],
              [
                -74.07933640538252,
                40.57562720401685
              ],
              [
                -74.078751853163,
                40.57504212563718
              ],
              [
                -74.07867933938911,
                40.575081825223414
              ],
              [
                -74.07926321256913,
                40.57567129220577
              ],
              [
                -74.07877412894697,
                40.57601082224561
              ],
              [
                -74.07830169156932,
                40.57646471865796
              ],
              [
                -74.0773747252254,
                40.577282159408114
              ],
              [
                -74.07693881528753,
                40.57753434559136
              ],
              [
                -74.07689787379739,
                40.57751630829064
              ],
              [
                -74.07674837365107,
                40.57736695916289
              ],
              [
                -74.07673747616731,
                40.577372509325414
              ],
              [
                -74.076915528964,
                40.57757950001264
              ],
              [
                -74.07688226472128,
                40.57763678991154
              ],
              [
                -74.07672273866132,
                40.57773054541072
              ],
              [
                -74.07662183569524,
                40.577818535946605
              ],
              [
                -74.07658356267683,
                40.577849247854246
              ],
              [
                -74.07655452620622,
                40.57788252048221
              ],
              [
                -74.07653911250937,
                40.57791162845724
              ],
              [
                -74.07651642995224,
                40.57794004736422
              ],
              [
                -74.07649283077285,
                40.577960846125386
              ],
              [
                -74.07646467115256,
                40.57796709998583
              ],
              [
                -74.07642748022657,
                40.57795012014871
              ],
              [
                -74.07631467297796,
                40.57789861362748
              ],
              [
                -74.07629741018434,
                40.57789862509331
              ],
              [
                -74.07629015242425,
                40.57790763630177
              ],
              [
                -74.07630470430556,
                40.577920096699685
              ],
              [
                -74.07639844892991,
                40.57796977408893
              ],
              [
                -74.07640834714078,
                40.577975450946134
              ],
              [
                -74.07639928398518,
                40.57799485505246
              ],
              [
                -74.076366604199,
                40.578021202205825
              ],
              [
                -74.07633845483512,
                40.57803646224949
              ],
              [
                -74.07628942409059,
                40.57806559252332
              ],
              [
                -74.07626037024005,
                40.578085008718816
              ],
              [
                -74.0762467632469,
                40.57810441582899
              ],
              [
                -74.07620499324025,
                40.57812661339822
              ],
              [
                -74.07611008044724,
                40.578173861682544
              ],
              [
                -74.07601596977891,
                40.57824491229019
              ],
              [
                -74.07534622582394,
                40.577887969571734
              ],
              [
                -74.07523453617956,
                40.57799880325315
              ],
              [
                -74.07579819540712,
                40.578309250650626
              ],
              [
                -74.07538416887525,
                40.57880385281149
              ],
              [
                -74.07508288192615,
                40.57916015875552
              ],
              [
                -74.07481070714091,
                40.57902120169393
              ],
              [
                -74.07470110047463,
                40.57896342623949
              ],
              [
                -74.07462334758766,
                40.57892820057826
              ],
              [
                -74.07454539912841,
                40.578884811947134
              ],
              [
                -74.07446028819841,
                40.57883936042356
              ],
              [
                -74.07438401619959,
                40.57880248179908
              ],
              [
                -74.07430790841188,
                40.57876125411845
              ],
              [
                -74.07430257821694,
                40.57875771130072
              ],
              [
                -74.07418094820548,
                40.5786910297157
              ],
              [
                -74.07406033780588,
                40.57862739788969
              ],
              [
                -74.07394041277513,
                40.578563018314014
              ],
              [
                -74.0738809055482,
                40.57853027927583
              ],
              [
                -74.07382094607229,
                40.57849802465769
              ],
              [
                -74.07376053874323,
                40.578466256634016
              ],
              [
                -74.0736996934535,
                40.57843498022362
              ],
              [
                -74.07363841745605,
                40.57840419810166
              ],
              [
                -74.07360222645721,
                40.57838597330579
              ],
              [
                -74.07356653230441,
                40.578367186621364
              ],
              [
                -74.07353135170506,
                40.57834784557702
              ],
              [
                -74.07349669675456,
                40.578327959714436
              ],
              [
                -74.07350986543725,
                40.578315797858785
              ],
              [
                -74.0735199987435,
                40.578305678956475
              ],
              [
                -74.0735224180513,
                40.57830326364266
              ],
              [
                -74.07353433462039,
                40.57829037299427
              ],
              [
                -74.07354559978785,
                40.57827714736749
              ],
              [
                -74.07355619533521,
                40.57826360319215
              ],
              [
                -74.07356610854268,
                40.57824976192025
              ],
              [
                -74.07357532163503,
                40.57823564333189
              ],
              [
                -74.07357563510095,
                40.57823078980032
              ],
              [
                -74.0735751468232,
                40.578225946666414
              ],
              [
                -74.07357386347509,
                40.57822118797406
              ],
              [
                -74.07357180930835,
                40.57821658842577
              ],
              [
                -74.07356901187003,
                40.57821222272206
              ],
              [
                -74.07356551979396,
                40.57820815935126
              ],
              [
                -74.07356138742034,
                40.57820446193997
              ],
              [
                -74.07355667809294,
                40.57820118874751
              ],
              [
                -74.07355146657468,
                40.57819839300049
              ],
              [
                -74.07354583684445,
                40.57819611669501
              ],
              [
                -74.07353987705248,
                40.578194398976535
              ],
              [
                -74.07353368126489,
                40.57819326390911
              ],
              [
                -74.07352734903544,
                40.578192730527164
              ],
              [
                -74.0735209774885,
                40.57819280714509
              ],
              [
                -74.073514668353,
                40.578193492860095
              ],
              [
                -74.07350852334758,
                40.57819477755539
              ],
              [
                -74.0735026389054,
                40.57819664006071
              ],
              [
                -74.07349710639637,
                40.578199050329474
              ],
              [
                -74.07347805846352,
                40.578215779951464
              ],
              [
                -74.07345960990148,
                40.57823289685039
              ],
              [
                -74.0734417751959,
                40.57825038660976
              ],
              [
                -74.07342624827145,
                40.57825375834972
              ],
              [
                -74.07341048150207,
                40.57825641220918
              ],
              [
                -74.07339453332253,
                40.57825833826659
              ],
              [
                -74.07338704462865,
                40.57826073436712
              ],
              [
                -74.07337989556261,
                40.5782636780719
              ],
              [
                -74.07337316189488,
                40.5782671396797
              ],
              [
                -74.07336690379651,
                40.57827109016974
              ],
              [
                -74.07336118472098,
                40.57827548828916
              ],
              [
                -74.0733560566966,
                40.578280292792655
              ],
              [
                -74.07335157262345,
                40.57828545640317
              ],
              [
                -74.07334777133924,
                40.57829093084747
              ],
              [
                -74.07334469475148,
                40.57829666232185
              ],
              [
                -74.07334236696877,
                40.57830259586141
              ],
              [
                -74.07334152248276,
                40.57830590226093
              ],
              [
                -74.0733408134175,
                40.578308675494995
              ],
              [
                -74.07334004853416,
                40.57831484190797
              ],
              [
                -74.07334007906654,
                40.57832103696331
              ],
              [
                -74.07334090736205,
                40.578327197500876
              ],
              [
                -74.07334252105652,
                40.57833326841133
              ],
              [
                -74.07334515012121,
                40.578342982776036
              ],
              [
                -74.07334703225152,
                40.578352798637894
              ],
              [
                -74.07334815906086,
                40.578362681993774
              ],
              [
                -74.07333596236282,
                40.57837622985228
              ],
              [
                -74.07332463446693,
                40.57839021038644
              ],
              [
                -74.07331420346311,
                40.578404592083004
              ],
              [
                -74.07330469502064,
                40.57841934007957
              ],
              [
                -74.0733034140223,
                40.57842391529095
              ],
              [
                -74.07330291158698,
                40.57842857795851
              ],
              [
                -74.07330319905394,
                40.57843324950382
              ],
              [
                -74.07330426821353,
                40.57843785672159
              ],
              [
                -74.07330610403751,
                40.57844232021234
              ],
              [
                -74.0733086761243,
                40.57844656645007
              ],
              [
                -74.07331194199432,
                40.57845052844998
              ],
              [
                -74.07331584532228,
                40.578454135885565
              ],
              [
                -74.07332032496554,
                40.57845733351098
              ],
              [
                -74.0733253059379,
                40.57846006525176
              ],
              [
                -74.07333070316146,
                40.57846228894464
              ],
              [
                -74.07333643243899,
                40.578463963933444
              ],
              [
                -74.07334239355201,
                40.57846506682823
              ],
              [
                -74.07334849309805,
                40.57846557791998
              ],
              [
                -74.07335462647943,
                40.57846548722361
              ],
              [
                -74.07336069570323,
                40.57846479714736
              ],
              [
                -74.07336659685528,
                40.57846351981953
              ],
              [
                -74.0733722356986,
                40.5784616760739
              ],
              [
                -74.073377518446,
                40.57845929629342
              ],
              [
                -74.07338235571389,
                40.578456419402215
              ],
              [
                -74.07339495255152,
                40.57844069457178
              ],
              [
                -74.07340826824962,
                40.578425317909726
              ],
              [
                -74.07342228481102,
                40.578410308023
              ],
              [
                -74.07343698511879,
                40.57839568418803
              ],
              [
                -74.07345235029628,
                40.57838146400724
              ],
              [
                -74.07346627066252,
                40.57838568589744
              ],
              [
                -74.0734798634038,
                40.578390484464535
              ],
              [
                -74.07349309093149,
                40.57839584432014
              ],
              [
                -74.07350591302189,
                40.578401751752644
              ],
              [
                -74.073518289883,
                40.57840818584631
              ],
              [
                -74.07353359424235,
                40.578416370926654
              ],
              [
                -74.07354846340166,
                40.578425010288534
              ],
              [
                -74.07356287208148,
                40.578434093561526
              ],
              [
                -74.07357679631478,
                40.57844360467841
              ],
              [
                -74.07359021389335,
                40.5784535284082
              ],
              [
                -74.07360310700233,
                40.57846384884735
              ],
              [
                -74.07361545145487,
                40.578474550096225
              ],
              [
                -74.07362722877563,
                40.578485615078876
              ],
              [
                -74.07363842048949,
                40.578497026886566
              ],
              [
                -74.07364900987565,
                40.578508765594584
              ],
              [
                -74.07365746469745,
                40.57851812490771
              ],
              [
                -74.07366659189495,
                40.578527112711264
              ],
              [
                -74.07367635847415,
                40.57853569652539
              ],
              [
                -74.07368673738576,
                40.57854385458836
              ],
              [
                -74.0736976967387,
                40.57855155827312
              ],
              [
                -74.07370920047458,
                40.57855878565612
              ],
              [
                -74.07372121429081,
                40.578565513137605
              ],
              [
                -74.07373369993405,
                40.57857172047078
              ],
              [
                -74.07374662046949,
                40.57857738841345
              ],
              [
                -74.07375993347098,
                40.57858249889945
              ],
              [
                -74.07378101047931,
                40.57858539267311
              ],
              [
                -74.07380191528604,
                40.5785889348929
              ],
              [
                -74.07382261580653,
                40.57859312005089
              ],
              [
                -74.07384307577854,
                40.57859793996175
              ],
              [
                -74.07386326355447,
                40.57860338693935
              ],
              [
                -74.07388314529085,
                40.578609454974696
              ],
              [
                -74.07390268867572,
                40.578616131524086
              ],
              [
                -74.07392185897929,
                40.57862340387762
              ],
              [
                -74.07393903412809,
                40.578630588051375
              ],
              [
                -74.07395578869183,
                40.57863832802113
              ],
              [
                -74.07397209167445,
                40.578646609567144
              ],
              [
                -74.07398791515524,
                40.578655417964974
              ],
              [
                -74.07400322703648,
                40.57866473597995
              ],
              [
                -74.07401800049168,
                40.578674545201416
              ],
              [
                -74.0740322084776,
                40.57868482956384
              ],
              [
                -74.07404582592638,
                40.57869557115832
              ],
              [
                -74.07405882380822,
                40.57870674621438
              ],
              [
                -74.07406669862316,
                40.57871746838897
              ],
              [
                -74.07407519230351,
                40.5787279107247
              ],
              [
                -74.07408428615156,
                40.5787380539676
              ],
              [
                -74.07409396278919,
                40.57874787970052
              ],
              [
                -74.07410420131792,
                40.578757365152825
              ],
              [
                -74.07411498458016,
                40.57876649291226
              ],
              [
                -74.07412628574865,
                40.578775243730085
              ],
              [
                -74.07413808612743,
                40.578783600027656
              ],
              [
                -74.07415036416721,
                40.57879154657362
              ],
              [
                -74.07416279646655,
                40.57879831712884
              ],
              [
                -74.07417569662549,
                40.578804558154914
              ],
              [
                -74.07418902551538,
                40.57881025325894
              ],
              [
                -74.07420274268361,
                40.57881538102322
              ],
              [
                -74.07421680900784,
                40.57881993108587
              ],
              [
                -74.07423117920446,
                40.57882388471295
              ],
              [
                -74.074245812177,
                40.578827234392065
              ],
              [
                -74.07426066418708,
                40.57882996792144
              ],
              [
                -74.07427568952454,
                40.578832078294624
              ],
              [
                -74.07429351108699,
                40.5788358167525
              ],
              [
                -74.07431110216156,
                40.57884014539564
              ],
              [
                -74.074328426925,
                40.57884505620597
              ],
              [
                -74.074345454168,
                40.57885054082738
              ],
              [
                -74.07436215136022,
                40.57885658839164
              ],
              [
                -74.07437848377208,
                40.578863186189
              ],
              [
                -74.07439442348492,
                40.57887032167314
              ],
              [
                -74.07440993884515,
                40.57887798229987
              ],
              [
                -74.07442499929434,
                40.578886152006476
              ],
              [
                -74.07443957713203,
                40.57889481724106
              ],
              [
                -74.07445660063782,
                40.57890678457117
              ],
              [
                -74.07447307994613,
                40.57891918749326
              ],
              [
                -74.07448899504465,
                40.57893201027271
              ],
              [
                -74.0745043274632,
                40.57894524069212
              ],
              [
                -74.07451906179989,
                40.578958859663295
              ],
              [
                -74.07453317848523,
                40.57897285446702
              ],
              [
                -74.07454666036014,
                40.57898720651877
              ],
              [
                -74.07455949444534,
                40.57900190208977
              ],
              [
                -74.07456887026298,
                40.579009644078056
              ],
              [
                -74.07457881710285,
                40.57901695966718
              ],
              [
                -74.07458930110093,
                40.57902382475472
              ],
              [
                -74.07460028663816,
                40.57903021792018
              ],
              [
                -74.07461173941235,
                40.579036116233915
              ],
              [
                -74.07462361809372,
                40.57904149995423
              ],
              [
                -74.07463588772693,
                40.57904635184811
              ],
              [
                -74.0746485023713,
                40.579050655192376
              ],
              [
                -74.07466142311903,
                40.57905439510209
              ],
              [
                -74.07467460447475,
                40.57905756004705
              ],
              [
                -74.07468800489849,
                40.579060139164945
              ],
              [
                -74.0747015773599,
                40.579062124109605
              ],
              [
                -74.07471974443408,
                40.57906568720837
              ],
              [
                -74.07473769880751,
                40.57906982892193
              ],
              [
                -74.07475540795494,
                40.57907454290567
              ],
              [
                -74.07477284308196,
                40.57907981912662
              ],
              [
                -74.07478997100377,
                40.5790856510728
              ],
              [
                -74.07480676226291,
                40.57909202569628
              ],
              [
                -74.07482318784565,
                40.57909893296429
              ],
              [
                -74.07492909104904,
                40.57917126032183
              ],
              [
                -74.07499413073505,
                40.57923655741631
              ],
              [
                -74.07489264154759,
                40.579317436177746
              ],
              [
                -74.07383321073327,
                40.580277228732356
              ],
              [
                -74.07335389590793,
                40.58067893913892
              ],
              [
                -74.07177638431192,
                40.58187653516401
              ],
              [
                -74.07146670932998,
                40.58202724389815
              ],
              [
                -74.07065781537806,
                40.5816256250378
              ],
              [
                -74.07059533733295,
                40.58169054342513
              ],
              [
                -74.07149180521219,
                40.58216878265253
              ],
              [
                -74.07144837595611,
                40.5824340679645
              ],
              [
                -74.07096310182041,
                40.58318199040263
              ],
              [
                -74.07021154289465,
                40.58398135771152
              ],
              [
                -74.0698079096077,
                40.58430404221735
              ],
              [
                -74.06951435164832,
                40.58458551737247
              ],
              [
                -74.06933503355576,
                40.584776520152325
              ],
              [
                -74.06722276816986,
                40.58673445026267
              ],
              [
                -74.06593797099987,
                40.58760609779562
              ],
              [
                -74.06477833032984,
                40.58848831072569
              ],
              [
                -74.06470615524998,
                40.58847254683953
              ],
              [
                -74.06458752315092,
                40.588403157415684
              ],
              [
                -74.06440881666158,
                40.58823892431576
              ],
              [
                -74.06436003721248,
                40.588267891174794
              ],
              [
                -74.06454186133764,
                40.588433992394265
              ],
              [
                -74.06503309568002,
                40.588909314195966
              ],
              [
                -74.06503332378131,
                40.58899099843518
              ],
              [
                -74.06563611899992,
                40.589628104999896
              ],
              [
                -74.06572336299989,
                40.58969105399996
              ],
              [
                -74.06580036099992,
                40.589746608999874
              ],
              [
                -74.06575530299992,
                40.58978965399993
              ],
              [
                -74.06572536499986,
                40.58984529599992
              ],
              [
                -74.06571834999994,
                40.58990810299986
              ],
              [
                -74.06573690899995,
                40.589969516999936
              ],
              [
                -74.06577682899997,
                40.5900213909999
              ],
              [
                -74.0658293969999,
                40.590059164999914
              ],
              [
                -74.06651182899995,
                40.59034492199989
              ],
              [
                -74.06662559499993,
                40.59034201999989
              ],
              [
                -74.06666723999993,
                40.59038276199989
              ],
              [
                -74.06728410699992,
                40.59098620299988
              ],
              [
                -74.06751477499988,
                40.591334642999925
              ],
              [
                -74.06795227299993,
                40.59208368299988
              ],
              [
                -74.06814585499988,
                40.592455763999965
              ],
              [
                -74.06831810799993,
                40.59278576599994
              ],
              [
                -74.06964532799996,
                40.59526401099989
              ],
              [
                -74.06970478499989,
                40.59537635999994
              ],
              [
                -74.07043976099992,
                40.59673474699988
              ],
              [
                -74.07118634999996,
                40.59648999299984
              ],
              [
                -74.07281699299993,
                40.595952214999926
              ],
              [
                -74.073679591,
                40.59566839599995
              ],
              [
                -74.07453730499996,
                40.59538677999991
              ],
              [
                -74.07539899099994,
                40.59510414799992
              ],
              [
                -74.07626202999994,
                40.5948225699999
              ],
              [
                -74.07714922399995,
                40.59453408099989
              ],
              [
                -74.07802261099992,
                40.594254823999975
              ],
              [
                -74.07888191699992,
                40.59394279199989
              ],
              [
                -74.0784669379999,
                40.59330001199991
              ],
              [
                -74.07936282299987,
                40.59299407499987
              ],
              [
                -74.08029682099996,
                40.59266864299984
              ],
              [
                -74.08173342999991,
                40.59215628599987
              ],
              [
                -74.08177066499995,
                40.59221445099991
              ],
              [
                -74.08239591899998,
                40.59319116199994
              ],
              [
                -74.08304163599998,
                40.594229720999884
              ],
              [
                -74.0832075799999,
                40.594533734999864
              ],
              [
                -74.08330235099992,
                40.594716327999876
              ],
              [
                -74.08376862299998,
                40.595520956999934
              ],
              [
                -74.08406968699997,
                40.59549626999994
              ],
              [
                -74.08491019899996,
                40.59570472799985
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000395647022725,
        "objectid": 215,
        "shape_leng": 0.103556453625,
        "location_id": 215,
        "zone": "South Jamaica",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.7769103769999,
                40.70252488499994
              ],
              [
                -73.7779110379998,
                40.703282388999895
              ],
              [
                -73.77849131099994,
                40.70383322599987
              ],
              [
                -73.77916009800003,
                40.70438896699998
              ],
              [
                -73.7798182799998,
                40.70492789799994
              ],
              [
                -73.78034491200003,
                40.70541241699988
              ],
              [
                -73.78147887399984,
                40.705455250999854
              ],
              [
                -73.78256582599995,
                40.705424863999895
              ],
              [
                -73.78442027299995,
                40.70476246699992
              ],
              [
                -73.7848487629999,
                40.704585569999935
              ],
              [
                -73.78597976500005,
                40.70418661599993
              ],
              [
                -73.78751689999983,
                40.703715081999896
              ],
              [
                -73.78804161499981,
                40.703555595999894
              ],
              [
                -73.78835413999998,
                40.70346023099992
              ],
              [
                -73.78919309,
                40.70320438499993
              ],
              [
                -73.78966391499986,
                40.70303948899994
              ],
              [
                -73.78977597299989,
                40.7029788639999
              ],
              [
                -73.78999405799982,
                40.7028714169999
              ],
              [
                -73.79055566899994,
                40.702567795999904
              ],
              [
                -73.79115569699985,
                40.702294075999916
              ],
              [
                -73.79131126499983,
                40.70225465399993
              ],
              [
                -73.7914732489998,
                40.70222990499985
              ],
              [
                -73.791637999,
                40.7022205499999
              ],
              [
                -73.7918017669999,
                40.7022266369999
              ],
              [
                -73.79200046599996,
                40.70222080899988
              ],
              [
                -73.79219799199994,
                40.702200687999905
              ],
              [
                -73.79239170299988,
                40.7021665299999
              ],
              [
                -73.79235282099985,
                40.70209793999988
              ],
              [
                -73.79186916599991,
                40.701366136999894
              ],
              [
                -73.79194372099994,
                40.701271725999945
              ],
              [
                -73.7920169119999,
                40.70111772699987
              ],
              [
                -73.79210582699996,
                40.700967788999954
              ],
              [
                -73.7921059579999,
                40.70096757299996
              ],
              [
                -73.7921061119999,
                40.7009673569999
              ],
              [
                -73.79221016699994,
                40.70082285099994
              ],
              [
                -73.7922102969999,
                40.7008226709999
              ],
              [
                -73.79221043999986,
                40.700822508999885
              ],
              [
                -73.79232894499991,
                40.70068447699989
              ],
              [
                -73.79232905199986,
                40.70068434099989
              ],
              [
                -73.79232916999992,
                40.70068422399993
              ],
              [
                -73.79246106899983,
                40.70055382499993
              ],
              [
                -73.79246111600004,
                40.700553788999855
              ],
              [
                -73.79260506799987,
                40.70043209999988
              ],
              [
                -73.79284356499997,
                40.70024368299989
              ],
              [
                -73.79357708699986,
                40.699756471999876
              ],
              [
                -73.79449920899998,
                40.69938577699986
              ],
              [
                -73.79572173799994,
                40.69901473499991
              ],
              [
                -73.7964760649998,
                40.698693084999924
              ],
              [
                -73.79722374699978,
                40.69835945699992
              ],
              [
                -73.79753672799987,
                40.69819892499988
              ],
              [
                -73.79786193399997,
                40.69799340399993
              ],
              [
                -73.79854640199991,
                40.69746653299996
              ],
              [
                -73.79876814999977,
                40.69725955099994
              ],
              [
                -73.79918226899999,
                40.69687147599991
              ],
              [
                -73.79985927599985,
                40.69634707999995
              ],
              [
                -73.80053204200006,
                40.69585669199992
              ],
              [
                -73.80119617899996,
                40.695336488999956
              ],
              [
                -73.80211252599995,
                40.69454449899996
              ],
              [
                -73.80295940099997,
                40.69381747099988
              ],
              [
                -73.80381770599995,
                40.6930796319999
              ],
              [
                -73.80468792999994,
                40.69234557199992
              ],
              [
                -73.80555849799985,
                40.691608704999986
              ],
              [
                -73.80642479399984,
                40.69087385799991
              ],
              [
                -73.80723957200003,
                40.69143198799989
              ],
              [
                -73.80803640399986,
                40.69198289499991
              ],
              [
                -73.80828132399985,
                40.692147385999895
              ],
              [
                -73.80890216900004,
                40.69256434899993
              ],
              [
                -73.80971017199981,
                40.692199383999885
              ],
              [
                -73.81026957999993,
                40.69204524899994
              ],
              [
                -73.81054216699988,
                40.69197245899991
              ],
              [
                -73.81063298299996,
                40.691947382999885
              ],
              [
                -73.8102442619999,
                40.69126358699991
              ],
              [
                -73.80995601299992,
                40.69075611299995
              ],
              [
                -73.809723267,
                40.69034634399993
              ],
              [
                -73.80954787499984,
                40.690037545999914
              ],
              [
                -73.809131198,
                40.68930752699989
              ],
              [
                -73.80892417199985,
                40.68894480899991
              ],
              [
                -73.80874358599979,
                40.68862588399991
              ],
              [
                -73.80857655199986,
                40.688330889999925
              ],
              [
                -73.80807342399996,
                40.687452823999884
              ],
              [
                -73.80787469700006,
                40.68709833799989
              ],
              [
                -73.80733621899984,
                40.68612544699994
              ],
              [
                -73.80709063399985,
                40.68563031099991
              ],
              [
                -73.80660608799995,
                40.684681145999946
              ],
              [
                -73.80606202699997,
                40.68355435999991
              ],
              [
                -73.80576840199991,
                40.68293239099994
              ],
              [
                -73.80568769599995,
                40.68295563299994
              ],
              [
                -73.80519209299995,
                40.68309605799989
              ],
              [
                -73.80432351099998,
                40.68334179499986
              ],
              [
                -73.80344528099988,
                40.68359185699995
              ],
              [
                -73.80256430899988,
                40.683842438999875
              ],
              [
                -73.80168572400002,
                40.68409195599986
              ],
              [
                -73.80077394899995,
                40.68434992799991
              ],
              [
                -73.79986119199985,
                40.68461014299996
              ],
              [
                -73.79943031499988,
                40.684731988999935
              ],
              [
                -73.79898209099994,
                40.68485873799988
              ],
              [
                -73.79995042799996,
                40.68682771899991
              ],
              [
                -73.79913531299994,
                40.687181958999915
              ],
              [
                -73.79828588299998,
                40.68752401899994
              ],
              [
                -73.79746057199985,
                40.6878723019999
              ],
              [
                -73.7966293329999,
                40.68822145199995
              ],
              [
                -73.79561486799987,
                40.68865689099992
              ],
              [
                -73.79521094899988,
                40.688094665999905
              ],
              [
                -73.79477837399985,
                40.687470683999955
              ],
              [
                -73.79437087299995,
                40.68691258199987
              ],
              [
                -73.793908417,
                40.68630469099986
              ],
              [
                -73.79317220799987,
                40.68659668599987
              ],
              [
                -73.79248043499994,
                40.68688962999991
              ],
              [
                -73.79210699399982,
                40.68704753599997
              ],
              [
                -73.79038468299986,
                40.68775361399988
              ],
              [
                -73.78962631299986,
                40.68793905899987
              ],
              [
                -73.78920529899993,
                40.68805321299991
              ],
              [
                -73.78867911999995,
                40.68818018399991
              ],
              [
                -73.78764209899992,
                40.68843484999997
              ],
              [
                -73.78698344499996,
                40.68860847699995
              ],
              [
                -73.78643950599985,
                40.68878171099992
              ],
              [
                -73.78580499899994,
                40.688996896999896
              ],
              [
                -73.78479240699981,
                40.68936159999986
              ],
              [
                -73.78409903999982,
                40.68964775499989
              ],
              [
                -73.78326624999988,
                40.68999429299992
              ],
              [
                -73.7824271229998,
                40.69034436899994
              ],
              [
                -73.78160296599982,
                40.690686903999904
              ],
              [
                -73.78078155499992,
                40.69102994299994
              ],
              [
                -73.77977295099997,
                40.68928116999993
              ],
              [
                -73.77891249599985,
                40.689570646999925
              ],
              [
                -73.77796055199985,
                40.689894809999956
              ],
              [
                -73.77789337399993,
                40.68991768999992
              ],
              [
                -73.77919838599988,
                40.691650567999915
              ],
              [
                -73.7802372379999,
                40.69297233499993
              ],
              [
                -73.78018646099989,
                40.69301522999991
              ],
              [
                -73.77944081299995,
                40.693645109999856
              ],
              [
                -73.77863399799988,
                40.69401378799992
              ],
              [
                -73.77783593399984,
                40.69432133499986
              ],
              [
                -73.77725734399985,
                40.695064041999885
              ],
              [
                -73.77703882099993,
                40.69536433699991
              ],
              [
                -73.77659775499988,
                40.69596911399988
              ],
              [
                -73.77618932399996,
                40.69657434199996
              ],
              [
                -73.77608749199995,
                40.6967252309999
              ],
              [
                -73.7757628779999,
                40.697182862999924
              ],
              [
                -73.7756762609999,
                40.6973050299999
              ],
              [
                -73.77532842099994,
                40.69778566699989
              ],
              [
                -73.77513633799991,
                40.69805046599988
              ],
              [
                -73.774892245,
                40.698387447999906
              ],
              [
                -73.7745937749998,
                40.69879949799987
              ],
              [
                -73.77446186199991,
                40.69899514599994
              ],
              [
                -73.77395659499986,
                40.69980578199992
              ],
              [
                -73.77441230099998,
                40.70028087699991
              ],
              [
                -73.77488318599994,
                40.70074775199991
              ],
              [
                -73.77536886199987,
                40.701205982999966
              ],
              [
                -73.77586892899987,
                40.70165517099993
              ],
              [
                -73.77638291300002,
                40.7020949279999
              ],
              [
                -73.7769103769999,
                40.70252488499994
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000816076647781,
        "objectid": 216,
        "shape_leng": 0.116797554681,
        "location_id": 216,
        "zone": "South Ozone Park",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.80857655199986,
                40.688330889999925
              ],
              [
                -73.80874358599979,
                40.68862588399991
              ],
              [
                -73.80892417199985,
                40.68894480899991
              ],
              [
                -73.809131198,
                40.68930752699989
              ],
              [
                -73.80954787499984,
                40.690037545999914
              ],
              [
                -73.809723267,
                40.69034634399993
              ],
              [
                -73.80995601299992,
                40.69075611299995
              ],
              [
                -73.8102442619999,
                40.69126358699991
              ],
              [
                -73.81063298299996,
                40.691947382999885
              ],
              [
                -73.81072587499985,
                40.69192242199996
              ],
              [
                -73.8109881059999,
                40.691855044999926
              ],
              [
                -73.81186315099997,
                40.69163007999991
              ],
              [
                -73.81271762199997,
                40.69140780299994
              ],
              [
                -73.81309863099992,
                40.6913181019999
              ],
              [
                -73.81354014199995,
                40.691211367999905
              ],
              [
                -73.81438438,
                40.69097195299985
              ],
              [
                -73.81522826699991,
                40.69073347699986
              ],
              [
                -73.81607487599989,
                40.690492269999886
              ],
              [
                -73.8169264139999,
                40.69025014699988
              ],
              [
                -73.81818710999987,
                40.68989207499987
              ],
              [
                -73.81906075299987,
                40.689642441999894
              ],
              [
                -73.81993922399998,
                40.68939369199994
              ],
              [
                -73.82085264199988,
                40.68913438299991
              ],
              [
                -73.82189346699994,
                40.688838225999866
              ],
              [
                -73.82293216299993,
                40.688543000999864
              ],
              [
                -73.82398034299996,
                40.688245267999946
              ],
              [
                -73.82501128899997,
                40.68795231799991
              ],
              [
                -73.82599347399987,
                40.689953661999894
              ],
              [
                -73.82685467499992,
                40.68970953599996
              ],
              [
                -73.82768417699987,
                40.68947479999992
              ],
              [
                -73.82851136799992,
                40.689239055999934
              ],
              [
                -73.82753021399995,
                40.687239482999914
              ],
              [
                -73.82835620899998,
                40.687002808999914
              ],
              [
                -73.82918242299996,
                40.68677080599986
              ],
              [
                -73.83003934099995,
                40.68652935899992
              ],
              [
                -73.83088556299994,
                40.686286547999885
              ],
              [
                -73.83172298299988,
                40.6860507049999
              ],
              [
                -73.83257747700002,
                40.68580634599992
              ],
              [
                -73.833421907,
                40.68556778199997
              ],
              [
                -73.83426545999994,
                40.685327337999894
              ],
              [
                -73.8351108629999,
                40.68508768199992
              ],
              [
                -73.83595082799985,
                40.684843545999904
              ],
              [
                -73.83717651699999,
                40.68449539199987
              ],
              [
                -73.83807769099997,
                40.68423890999989
              ],
              [
                -73.83697486299991,
                40.68198801799991
              ],
              [
                -73.83711511399999,
                40.681933891999954
              ],
              [
                -73.83713815499993,
                40.6819249999999
              ],
              [
                -73.83772037399996,
                40.681700306999915
              ],
              [
                -73.83778880399989,
                40.68167389699992
              ],
              [
                -73.8377973829999,
                40.68167058599989
              ],
              [
                -73.83865065799998,
                40.681438236999895
              ],
              [
                -73.83952877099978,
                40.68126341499991
              ],
              [
                -73.84012856099982,
                40.6811466509999
              ],
              [
                -73.8395206529999,
                40.679961868999946
              ],
              [
                -73.83794250999986,
                40.676794208999866
              ],
              [
                -73.83811946699984,
                40.67662988899985
              ],
              [
                -73.83784372299996,
                40.67607812399992
              ],
              [
                -73.83749571700004,
                40.67540682399994
              ],
              [
                -73.83713760399998,
                40.67466513399991
              ],
              [
                -73.83680894799993,
                40.673961116999884
              ],
              [
                -73.83641423899984,
                40.673115588999956
              ],
              [
                -73.83600752699992,
                40.672269558999844
              ],
              [
                -73.83527457099994,
                40.670800402999916
              ],
              [
                -73.83334819299995,
                40.66677318699994
              ],
              [
                -73.83300277599999,
                40.66592051799994
              ],
              [
                -73.83295614199983,
                40.665827065999956
              ],
              [
                -73.83291947199984,
                40.665765048999916
              ],
              [
                -73.832705339,
                40.665405772999854
              ],
              [
                -73.83248638399985,
                40.665354910999945
              ],
              [
                -73.83205764999998,
                40.66525531599994
              ],
              [
                -73.83003156899997,
                40.66458188799993
              ],
              [
                -73.829388642,
                40.6643646489999
              ],
              [
                -73.82910033499995,
                40.66425073799991
              ],
              [
                -73.82789758799997,
                40.66390630699991
              ],
              [
                -73.82713313599999,
                40.66375536799995
              ],
              [
                -73.82687353900002,
                40.663704106999916
              ],
              [
                -73.82629450900006,
                40.663638333999856
              ],
              [
                -73.82571227999983,
                40.66359313999993
              ],
              [
                -73.82503467599989,
                40.66358013399989
              ],
              [
                -73.82486060999993,
                40.66357678999988
              ],
              [
                -73.82427005699986,
                40.663576563999904
              ],
              [
                -73.82306169099988,
                40.66353701599996
              ],
              [
                -73.81791759699993,
                40.66295601599991
              ],
              [
                -73.81496922499986,
                40.66247266799991
              ],
              [
                -73.81442762999983,
                40.66251410199988
              ],
              [
                -73.81388758999984,
                40.662569925999925
              ],
              [
                -73.81335011199994,
                40.662640078999836
              ],
              [
                -73.812816163,
                40.662724435999955
              ],
              [
                -73.81228672799995,
                40.66282283799993
              ],
              [
                -73.81176694899986,
                40.66293453599997
              ],
              [
                -73.81116608799994,
                40.663177473999895
              ],
              [
                -73.81061044899995,
                40.6634808539999
              ],
              [
                -73.81006836999995,
                40.66379225199996
              ],
              [
                -73.80945926899989,
                40.66404408899996
              ],
              [
                -73.80884594199996,
                40.66429766899992
              ],
              [
                -73.8084501619999,
                40.66446130099991
              ],
              [
                -73.80779777599999,
                40.664589007999915
              ],
              [
                -73.80764810699986,
                40.66461830599991
              ],
              [
                -73.80614396,
                40.66491272999988
              ],
              [
                -73.8057239779999,
                40.664960640999894
              ],
              [
                -73.8043205059999,
                40.66512073299992
              ],
              [
                -73.80377524499997,
                40.66516018399992
              ],
              [
                -73.80299527199998,
                40.66521660999994
              ],
              [
                -73.80214866500003,
                40.66524995299992
              ],
              [
                -73.80203207699995,
                40.665248024999926
              ],
              [
                -73.80183646299999,
                40.66588109399993
              ],
              [
                -73.80170297899987,
                40.66626406999995
              ],
              [
                -73.80168266599995,
                40.66632235299993
              ],
              [
                -73.8016677679998,
                40.66637296299996
              ],
              [
                -73.80155565300004,
                40.666753363999916
              ],
              [
                -73.80153052599994,
                40.666837158999954
              ],
              [
                -73.80141266299994,
                40.66735840999995
              ],
              [
                -73.80134286799985,
                40.667773796999896
              ],
              [
                -73.80125079599999,
                40.668385944999976
              ],
              [
                -73.80120616599989,
                40.66894442399994
              ],
              [
                -73.80117909099994,
                40.66910026499992
              ],
              [
                -73.80116000899989,
                40.66980042099991
              ],
              [
                -73.80113236199993,
                40.67054866799994
              ],
              [
                -73.8011343009999,
                40.671294461999906
              ],
              [
                -73.80113697199992,
                40.67135852899988
              ],
              [
                -73.80114238199995,
                40.67148794299991
              ],
              [
                -73.80115538600003,
                40.67179912099987
              ],
              [
                -73.80115934999986,
                40.67189388999986
              ],
              [
                -73.80117234299993,
                40.6722050319999
              ],
              [
                -73.80119461499989,
                40.67242246499994
              ],
              [
                -73.80130166399981,
                40.67312152199986
              ],
              [
                -73.80140210699984,
                40.67356131999987
              ],
              [
                -73.80142836799988,
                40.6736668339999
              ],
              [
                -73.80168194799997,
                40.674383848999916
              ],
              [
                -73.80202521799998,
                40.67511427299995
              ],
              [
                -73.80238906400001,
                40.67588847899992
              ],
              [
                -73.80267769599996,
                40.67649796699987
              ],
              [
                -73.8030752049999,
                40.67734560099989
              ],
              [
                -73.80323027699988,
                40.677681056999894
              ],
              [
                -73.80410226599996,
                40.67942454999991
              ],
              [
                -73.80428288199991,
                40.679817355999916
              ],
              [
                -73.80464517099982,
                40.68057988999991
              ],
              [
                -73.80492976299995,
                40.68117697399989
              ],
              [
                -73.80522682899998,
                40.681798149999935
              ],
              [
                -73.80551811199996,
                40.68240820999994
              ],
              [
                -73.80576840300003,
                40.682932390999895
              ],
              [
                -73.80606202699995,
                40.68355436099992
              ],
              [
                -73.80660608799995,
                40.684681145999946
              ],
              [
                -73.80709063399985,
                40.68563031099991
              ],
              [
                -73.80733621899984,
                40.68612544699994
              ],
              [
                -73.80787469700006,
                40.68709833799989
              ],
              [
                -73.80807342399996,
                40.687452823999884
              ],
              [
                -73.80857655199986,
                40.688330889999925
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000114733270758,
        "objectid": 217,
        "shape_leng": 0.0553905251285,
        "location_id": 217,
        "zone": "South Williamsburg",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.95554165799997,
                40.70759344799989
              ],
              [
                -73.95626945199986,
                40.70784956199987
              ],
              [
                -73.95672449099987,
                40.70800995199991
              ],
              [
                -73.95720321600001,
                40.70817924699989
              ],
              [
                -73.95728255399992,
                40.708207309999906
              ],
              [
                -73.95735981099993,
                40.70823462399987
              ],
              [
                -73.95790430799988,
                40.70842717299994
              ],
              [
                -73.95815250199995,
                40.70804091799986
              ],
              [
                -73.95838432400002,
                40.70767669099991
              ],
              [
                -73.958455768,
                40.707251846999895
              ],
              [
                -73.95920084799998,
                40.70733158499992
              ],
              [
                -73.95939542499995,
                40.707352407999984
              ],
              [
                -73.95993835900006,
                40.70740680099994
              ],
              [
                -73.960403832,
                40.70745188599992
              ],
              [
                -73.96050333599985,
                40.70746152299994
              ],
              [
                -73.96114422899987,
                40.707523595999874
              ],
              [
                -73.96186019899987,
                40.70759505699994
              ],
              [
                -73.96213128899981,
                40.707622692999955
              ],
              [
                -73.96274333099983,
                40.707685081999955
              ],
              [
                -73.963278738,
                40.70772929899995
              ],
              [
                -73.96442087399987,
                40.707533722999884
              ],
              [
                -73.96484837799986,
                40.70745361099989
              ],
              [
                -73.9659321969999,
                40.70724387399991
              ],
              [
                -73.96728890199985,
                40.70700200299995
              ],
              [
                -73.96838933699995,
                40.70682918699989
              ],
              [
                -73.96795139100001,
                40.7057312169999
              ],
              [
                -73.96761547799979,
                40.7049616349999
              ],
              [
                -73.96720961999985,
                40.70425570899993
              ],
              [
                -73.96653747199994,
                40.70369661299985
              ],
              [
                -73.96607413599992,
                40.70332015299993
              ],
              [
                -73.96587418600001,
                40.703157694999895
              ],
              [
                -73.96520438999994,
                40.70262274499987
              ],
              [
                -73.96451844799995,
                40.70207497799988
              ],
              [
                -73.96386889399994,
                40.701541888999934
              ],
              [
                -73.96318252099992,
                40.701000094999905
              ],
              [
                -73.9625725539999,
                40.700512198999895
              ],
              [
                -73.96217978199998,
                40.700220709999904
              ],
              [
                -73.96236579999989,
                40.69960558599991
              ],
              [
                -73.96283963699993,
                40.698038667999946
              ],
              [
                -73.96248295899996,
                40.698095383999956
              ],
              [
                -73.9623976909999,
                40.69810894499991
              ],
              [
                -73.96235903300003,
                40.698115091999966
              ],
              [
                -73.96233072899996,
                40.698119593999884
              ],
              [
                -73.96214289299981,
                40.69814945699991
              ],
              [
                -73.96189837799996,
                40.698188332999955
              ],
              [
                -73.96105100699991,
                40.69832607899989
              ],
              [
                -73.96019688899986,
                40.69846272699989
              ],
              [
                -73.95885874699991,
                40.698677325999874
              ],
              [
                -73.9579593819998,
                40.69882000299991
              ],
              [
                -73.95701993199991,
                40.69897391399997
              ],
              [
                -73.95716730100004,
                40.6997078679999
              ],
              [
                -73.95722517499998,
                40.69999934999988
              ],
              [
                -73.95745736399992,
                40.70082260299994
              ],
              [
                -73.95572360999994,
                40.701945769999924
              ],
              [
                -73.95381196900003,
                40.703180979999914
              ],
              [
                -73.95318085199992,
                40.702616904999935
              ],
              [
                -73.95255052799988,
                40.70205166699994
              ],
              [
                -73.95192018899999,
                40.70148754899992
              ],
              [
                -73.95128819399989,
                40.700922364999876
              ],
              [
                -73.95027424099992,
                40.70157924199989
              ],
              [
                -73.94937873499998,
                40.70215888999992
              ],
              [
                -73.94753858099985,
                40.703350649999926
              ],
              [
                -73.94705205299991,
                40.70366394899987
              ],
              [
                -73.94785367299988,
                40.70411138299988
              ],
              [
                -73.948140661,
                40.70427125399989
              ],
              [
                -73.94854962699999,
                40.704500430999936
              ],
              [
                -73.94866498899982,
                40.704565073999945
              ],
              [
                -73.949253357,
                40.70489593099995
              ],
              [
                -73.94947068299989,
                40.7050190189999
              ],
              [
                -73.94983788699977,
                40.70522902199989
              ],
              [
                -73.95023693799996,
                40.70547324699994
              ],
              [
                -73.95095606199983,
                40.70584293599995
              ],
              [
                -73.95108529899998,
                40.705912951999906
              ],
              [
                -73.95191055699979,
                40.70636007899991
              ],
              [
                -73.95229955499995,
                40.706490292999945
              ],
              [
                -73.95251503799986,
                40.70655858699993
              ],
              [
                -73.95291388700002,
                40.70670031099993
              ],
              [
                -73.95375309099992,
                40.70698188099989
              ],
              [
                -73.95401701399999,
                40.7070802409999
              ],
              [
                -73.95554165799997,
                40.70759344799989
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000281293736407,
        "objectid": 218,
        "shape_leng": 0.0837008281049,
        "location_id": 218,
        "zone": "Springfield Gardens North",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.76561573099998,
                40.67477901999992
              ],
              [
                -73.76837330699989,
                40.67655024099987
              ],
              [
                -73.76932752799988,
                40.67714378899992
              ],
              [
                -73.77030295199994,
                40.67775050099991
              ],
              [
                -73.77109570599985,
                40.67824358299989
              ],
              [
                -73.77177843899987,
                40.678668299999906
              ],
              [
                -73.77346815399997,
                40.67971949999987
              ],
              [
                -73.77469730199986,
                40.680486045999906
              ],
              [
                -73.77475342099996,
                40.680467280999935
              ],
              [
                -73.77549588399995,
                40.680219045999934
              ],
              [
                -73.77636248100002,
                40.679935908999916
              ],
              [
                -73.777229236,
                40.679652720999904
              ],
              [
                -73.77749031599994,
                40.67955740299992
              ],
              [
                -73.7777421129999,
                40.67944657599985
              ],
              [
                -73.77798227199999,
                40.679321092999906
              ],
              [
                -73.77820864799986,
                40.67918207599993
              ],
              [
                -73.77841935399988,
                40.6790308799999
              ],
              [
                -73.7793120879999,
                40.6784368039999
              ],
              [
                -73.77947817299986,
                40.67836454999986
              ],
              [
                -73.78025859499995,
                40.67784092399991
              ],
              [
                -73.7809512629998,
                40.677353507999946
              ],
              [
                -73.78128277899985,
                40.67710972999991
              ],
              [
                -73.78157185499998,
                40.676901330999975
              ],
              [
                -73.782218084,
                40.676437536999906
              ],
              [
                -73.78304214600001,
                40.67584371699991
              ],
              [
                -73.7832954899999,
                40.67567944499991
              ],
              [
                -73.783536133,
                40.675503090999904
              ],
              [
                -73.78376243999988,
                40.67531568599992
              ],
              [
                -73.78376261799986,
                40.67531553299987
              ],
              [
                -73.78376278399993,
                40.67531538999989
              ],
              [
                -73.78397345,
                40.675117994999944
              ],
              [
                -73.78397363999976,
                40.67511780699991
              ],
              [
                -73.78397384099986,
                40.67511760899994
              ],
              [
                -73.78416807199993,
                40.674911033999926
              ],
              [
                -73.78434496299991,
                40.674696439999906
              ],
              [
                -73.78484333899993,
                40.67390332299989
              ],
              [
                -73.78511037999995,
                40.673478363999905
              ],
              [
                -73.78532371499995,
                40.673125020999926
              ],
              [
                -73.78573730899993,
                40.67238503499988
              ],
              [
                -73.78686902099984,
                40.67282392399988
              ],
              [
                -73.78808647399985,
                40.6730096269999
              ],
              [
                -73.7884762149999,
                40.67132650099996
              ],
              [
                -73.78883107999984,
                40.66982348699987
              ],
              [
                -73.78915781999983,
                40.66833809599989
              ],
              [
                -73.78940999399987,
                40.66684115899995
              ],
              [
                -73.78697007199987,
                40.66708518199989
              ],
              [
                -73.78566409999999,
                40.667187540999905
              ],
              [
                -73.78502452299982,
                40.66722991499991
              ],
              [
                -73.78330043299998,
                40.66731460599988
              ],
              [
                -73.78265442899993,
                40.66736859699991
              ],
              [
                -73.78179791399985,
                40.667440441999936
              ],
              [
                -73.78062532299994,
                40.667523309999936
              ],
              [
                -73.77970512300006,
                40.667590197999864
              ],
              [
                -73.77791285799994,
                40.66771506699993
              ],
              [
                -73.77701324899986,
                40.66777636199986
              ],
              [
                -73.77611752599982,
                40.6678457269999
              ],
              [
                -73.77522378799983,
                40.66791507199986
              ],
              [
                -73.77431099300001,
                40.667981732999884
              ],
              [
                -73.77342462799992,
                40.66802761899987
              ],
              [
                -73.7715446069999,
                40.66796326899993
              ],
              [
                -73.76675402199996,
                40.66743299499994
              ],
              [
                -73.76493070899986,
                40.667227152999914
              ],
              [
                -73.76375687699996,
                40.66709461399991
              ],
              [
                -73.7627058149998,
                40.6669894849999
              ],
              [
                -73.76182231299998,
                40.66687775999986
              ],
              [
                -73.760795594,
                40.66677326799993
              ],
              [
                -73.75772789299985,
                40.66646098699994
              ],
              [
                -73.75763129599991,
                40.66645115699992
              ],
              [
                -73.75731692799982,
                40.66704031599992
              ],
              [
                -73.75699533399998,
                40.667741267999915
              ],
              [
                -73.75671602499992,
                40.66856252699993
              ],
              [
                -73.75668454799994,
                40.66875852199992
              ],
              [
                -73.75643734399979,
                40.669666378999906
              ],
              [
                -73.75640621299988,
                40.670372914999916
              ],
              [
                -73.7564247869999,
                40.67063356499994
              ],
              [
                -73.75646127199984,
                40.67109403099993
              ],
              [
                -73.7564837459999,
                40.671489865999874
              ],
              [
                -73.75646388399996,
                40.67168400299994
              ],
              [
                -73.75636239300002,
                40.672429236999974
              ],
              [
                -73.75643906099987,
                40.672441159999906
              ],
              [
                -73.75814760699994,
                40.6727069139999
              ],
              [
                -73.75857198899986,
                40.67263733099987
              ],
              [
                -73.75859630299982,
                40.67274790399996
              ],
              [
                -73.75880484999992,
                40.67300565399995
              ],
              [
                -73.76031463999993,
                40.675109970999856
              ],
              [
                -73.76114911299982,
                40.67476361999996
              ],
              [
                -73.76248600399997,
                40.67419498199989
              ],
              [
                -73.76339040599994,
                40.674681117999924
              ],
              [
                -73.76345120599991,
                40.674716182999866
              ],
              [
                -73.76352325899985,
                40.67473995099995
              ],
              [
                -73.76360070799988,
                40.67474980899989
              ],
              [
                -73.76367677599995,
                40.67474552499992
              ],
              [
                -73.76371825199992,
                40.67431589199987
              ],
              [
                -73.76380484799996,
                40.67376993299995
              ],
              [
                -73.76561573099998,
                40.67477901999992
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000428176336771,
        "objectid": 219,
        "shape_leng": 0.155126669675,
        "location_id": 219,
        "zone": "Springfield Gardens South",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.76182231299998,
                40.66687775999986
              ],
              [
                -73.7627058149998,
                40.6669894849999
              ],
              [
                -73.7637568769999,
                40.66709461499989
              ],
              [
                -73.76493070899986,
                40.667227152999914
              ],
              [
                -73.76675402199996,
                40.66743299499994
              ],
              [
                -73.7715446069999,
                40.66796326899993
              ],
              [
                -73.77342462799992,
                40.66802761899987
              ],
              [
                -73.77431099300001,
                40.667981732999884
              ],
              [
                -73.77522378799983,
                40.66791507199986
              ],
              [
                -73.77611752599982,
                40.6678457269999
              ],
              [
                -73.77701324899986,
                40.66777636199986
              ],
              [
                -73.77791285699988,
                40.667715066999904
              ],
              [
                -73.77970512300006,
                40.667590197999864
              ],
              [
                -73.78062532299994,
                40.667523309999936
              ],
              [
                -73.78179791400001,
                40.66744044099992
              ],
              [
                -73.78265442899993,
                40.66736859699991
              ],
              [
                -73.78330043299998,
                40.66731460599988
              ],
              [
                -73.78502452299999,
                40.66722991399993
              ],
              [
                -73.78566409999993,
                40.66718753999986
              ],
              [
                -73.78697007199987,
                40.66708518199989
              ],
              [
                -73.78940999399991,
                40.66684115799992
              ],
              [
                -73.79039544099997,
                40.666887703999905
              ],
              [
                -73.79129821899986,
                40.66697078499991
              ],
              [
                -73.79218446699987,
                40.667041888999904
              ],
              [
                -73.79306694299999,
                40.667067293999956
              ],
              [
                -73.79319502099987,
                40.66705844699985
              ],
              [
                -73.79392731599994,
                40.667006752999946
              ],
              [
                -73.79445715,
                40.66698105599994
              ],
              [
                -73.7949874579999,
                40.666961826999966
              ],
              [
                -73.79551810099989,
                40.666949071999916
              ],
              [
                -73.79604894599989,
                40.666942792999855
              ],
              [
                -73.7965798539999,
                40.666942991999896
              ],
              [
                -73.79711068999998,
                40.66694966899995
              ],
              [
                -73.79739484699991,
                40.6669398859999
              ],
              [
                -73.798072072,
                40.66671288499994
              ],
              [
                -73.79828522699991,
                40.66664725099994
              ],
              [
                -73.79850596999992,
                40.66659438099988
              ],
              [
                -73.79873226699989,
                40.66655498299989
              ],
              [
                -73.79896198599992,
                40.666529485999874
              ],
              [
                -73.79919289299993,
                40.66651807499988
              ],
              [
                -73.79942279899993,
                40.66652066399994
              ],
              [
                -73.79968140399984,
                40.66656547099993
              ],
              [
                -73.80027044399993,
                40.66666752699987
              ],
              [
                -73.80102008199994,
                40.66672907999992
              ],
              [
                -73.80139045299981,
                40.666746658999955
              ],
              [
                -73.80148383499996,
                40.666750151999935
              ],
              [
                -73.80155565300004,
                40.666753363999916
              ],
              [
                -73.8016677679998,
                40.66637296299996
              ],
              [
                -73.80168266599995,
                40.66632235299993
              ],
              [
                -73.80170297899987,
                40.66626406999995
              ],
              [
                -73.80183646299999,
                40.66588109399993
              ],
              [
                -73.80203207699995,
                40.665248024999926
              ],
              [
                -73.80188667499992,
                40.6652489169999
              ],
              [
                -73.80180049599981,
                40.66525044099991
              ],
              [
                -73.80072889200001,
                40.66526937699989
              ],
              [
                -73.79983020599992,
                40.66522105199994
              ],
              [
                -73.79785485699998,
                40.6651148049999
              ],
              [
                -73.79762708599995,
                40.66510255299991
              ],
              [
                -73.79702409499983,
                40.66506588399988
              ],
              [
                -73.79642246799989,
                40.66501610199989
              ],
              [
                -73.79589506299997,
                40.66496081399988
              ],
              [
                -73.79582296400001,
                40.66495325599993
              ],
              [
                -73.7952263039999,
                40.66487742499989
              ],
              [
                -73.79394317799995,
                40.66470238799994
              ],
              [
                -73.79256433099995,
                40.664514275999856
              ],
              [
                -73.791994646,
                40.664439276999865
              ],
              [
                -73.79118919099994,
                40.66433323299989
              ],
              [
                -73.79057832499984,
                40.66424329999992
              ],
              [
                -73.79005979999982,
                40.66416690699989
              ],
              [
                -73.7899673679998,
                40.664153288999955
              ],
              [
                -73.78990759999994,
                40.66414453799994
              ],
              [
                -73.78984347199987,
                40.6641359919999
              ],
              [
                -73.78811679799986,
                40.66390588099991
              ],
              [
                -73.78751177999993,
                40.663825242999906
              ],
              [
                -73.78618470399978,
                40.663609552999944
              ],
              [
                -73.78502513799997,
                40.66342107199989
              ],
              [
                -73.78481455499993,
                40.66349858199991
              ],
              [
                -73.784349205,
                40.66340259899992
              ],
              [
                -73.78321833499997,
                40.6631662839999
              ],
              [
                -73.78211253899983,
                40.66283540499992
              ],
              [
                -73.78140184699991,
                40.662622741999876
              ],
              [
                -73.78128320799988,
                40.66258308299995
              ],
              [
                -73.78075528499981,
                40.66240660299996
              ],
              [
                -73.77932933099991,
                40.661839519999916
              ],
              [
                -73.77876727900002,
                40.661615994999885
              ],
              [
                -73.77871548099994,
                40.66159539999996
              ],
              [
                -73.77864408999987,
                40.66156701299995
              ],
              [
                -73.77849144899989,
                40.661506302999896
              ],
              [
                -73.776792695,
                40.66074400799987
              ],
              [
                -73.77670850699995,
                40.66070622999995
              ],
              [
                -73.77644444599981,
                40.66062997399996
              ],
              [
                -73.77560556199978,
                40.660387711999896
              ],
              [
                -73.77552237699994,
                40.66036546999993
              ],
              [
                -73.7748492269999,
                40.660148686999946
              ],
              [
                -73.7745200739998,
                40.660012414999926
              ],
              [
                -73.77434521599992,
                40.659932140999906
              ],
              [
                -73.77418923599993,
                40.659860523999924
              ],
              [
                -73.77388075599985,
                40.6596788949999
              ],
              [
                -73.77382964699984,
                40.65965115799987
              ],
              [
                -73.7737837049999,
                40.65962415999996
              ],
              [
                -73.77331158599985,
                40.65934671699995
              ],
              [
                -73.77305501099993,
                40.6591997509999
              ],
              [
                -73.77291920299982,
                40.65912940399993
              ],
              [
                -73.7704997779999,
                40.65787603999991
              ],
              [
                -73.76976145199995,
                40.65748223599992
              ],
              [
                -73.76877799799986,
                40.65694558799989
              ],
              [
                -73.76873168699989,
                40.65692006399991
              ],
              [
                -73.768070047,
                40.6565553269999
              ],
              [
                -73.76786196699993,
                40.65644061399995
              ],
              [
                -73.7677137589999,
                40.656358979999915
              ],
              [
                -73.76740737399983,
                40.65619021499987
              ],
              [
                -73.76735274299986,
                40.656160125999875
              ],
              [
                -73.76729260999991,
                40.65612444299994
              ],
              [
                -73.76683051599994,
                40.65587605299992
              ],
              [
                -73.76669593900002,
                40.6558028479999
              ],
              [
                -73.76628008299998,
                40.65557821499993
              ],
              [
                -73.76566897099984,
                40.655253468999874
              ],
              [
                -73.76475455399994,
                40.654746896999875
              ],
              [
                -73.76417770199993,
                40.65442150099993
              ],
              [
                -73.76406290099993,
                40.65435626599989
              ],
              [
                -73.76404059399991,
                40.654393294999856
              ],
              [
                -73.76319000499986,
                40.655898053999884
              ],
              [
                -73.76299380499994,
                40.65592780799995
              ],
              [
                -73.76279952199998,
                40.65597330699988
              ],
              [
                -73.76261039799991,
                40.65603444099988
              ],
              [
                -73.76242965099998,
                40.6561106019999
              ],
              [
                -73.76226027499987,
                40.65620066499993
              ],
              [
                -73.76210493899991,
                40.65630306599995
              ],
              [
                -73.76196576499984,
                40.65641589199987
              ],
              [
                -73.76184429799997,
                40.656536949999925
              ],
              [
                -73.76177605899989,
                40.656874485999886
              ],
              [
                -73.76177603499984,
                40.65687459399989
              ],
              [
                -73.76177602300001,
                40.65687469299998
              ],
              [
                -73.76172316099992,
                40.65721386399987
              ],
              [
                -73.761723113,
                40.65721414299991
              ],
              [
                -73.76172307699989,
                40.657214420999935
              ],
              [
                -73.76168566599995,
                40.65755484799992
              ],
              [
                -73.76168565299982,
                40.65755497499989
              ],
              [
                -73.76168563999983,
                40.657555109999876
              ],
              [
                -73.76166374199983,
                40.6578960729999
              ],
              [
                -73.76166371699979,
                40.65789649699987
              ],
              [
                -73.76166370199995,
                40.65789692799995
              ],
              [
                -73.76165732899996,
                40.65823808599986
              ],
              [
                -73.76003600699983,
                40.65807344199986
              ],
              [
                -73.75922652199993,
                40.65790223699989
              ],
              [
                -73.75845384399994,
                40.657647902999955
              ],
              [
                -73.75819056099982,
                40.65755494699994
              ],
              [
                -73.75763169899994,
                40.65735762999987
              ],
              [
                -73.75680959499985,
                40.65709328399992
              ],
              [
                -73.75606608399983,
                40.656486460999886
              ],
              [
                -73.75371894599986,
                40.65536538099991
              ],
              [
                -73.75294892899993,
                40.6548850399999
              ],
              [
                -73.752088853,
                40.65451770399988
              ],
              [
                -73.75149741999984,
                40.65433688299988
              ],
              [
                -73.75109427999985,
                40.65421244099991
              ],
              [
                -73.74937142800002,
                40.65303483399986
              ],
              [
                -73.74920814099985,
                40.6529338629999
              ],
              [
                -73.74867194699979,
                40.65267808799992
              ],
              [
                -73.74654918000003,
                40.65255348099988
              ],
              [
                -73.74332546899993,
                40.65253780399992
              ],
              [
                -73.74331805699994,
                40.653281203999946
              ],
              [
                -73.74331194299997,
                40.6539944199999
              ],
              [
                -73.74330720899991,
                40.65470855899991
              ],
              [
                -73.74329846300004,
                40.65542352699995
              ],
              [
                -73.74328714099991,
                40.656134731999934
              ],
              [
                -73.74377018899996,
                40.656139023999934
              ],
              [
                -73.74487420299995,
                40.656144557999944
              ],
              [
                -73.74492278899996,
                40.656478974999914
              ],
              [
                -73.74506037899988,
                40.657407149999884
              ],
              [
                -73.74618048999982,
                40.657439074999914
              ],
              [
                -73.74679970800001,
                40.65747128099992
              ],
              [
                -73.74705195899993,
                40.65748828899993
              ],
              [
                -73.74807112499987,
                40.6575484919999
              ],
              [
                -73.74853177199994,
                40.65757569699993
              ],
              [
                -73.74779788899987,
                40.65852210299988
              ],
              [
                -73.74733230899986,
                40.65912452099992
              ],
              [
                -73.74679260099995,
                40.65890762199992
              ],
              [
                -73.74684750499985,
                40.660795540999864
              ],
              [
                -73.7461369799999,
                40.66171671999991
              ],
              [
                -73.74450922599979,
                40.66382127799988
              ],
              [
                -73.74405313899989,
                40.663658070999944
              ],
              [
                -73.74374970899983,
                40.66409039299991
              ],
              [
                -73.74340973899989,
                40.6644041699999
              ],
              [
                -73.74281884600006,
                40.66530554899985
              ],
              [
                -73.73954958300003,
                40.66525602199989
              ],
              [
                -73.73937134199998,
                40.66545222799987
              ],
              [
                -73.73920854999997,
                40.66562918899998
              ],
              [
                -73.73891022900001,
                40.66595449199986
              ],
              [
                -73.73856469500002,
                40.666396897999924
              ],
              [
                -73.73993110699995,
                40.66640155399988
              ],
              [
                -73.74010286899983,
                40.666402139999896
              ],
              [
                -73.74021946299993,
                40.66640227699988
              ],
              [
                -73.74042437599985,
                40.66640252299988
              ],
              [
                -73.74117817299991,
                40.66640295399989
              ],
              [
                -73.74440564499986,
                40.66640390999991
              ],
              [
                -73.74536651700001,
                40.66640403199993
              ],
              [
                -73.74608613399998,
                40.66640039999989
              ],
              [
                -73.74865865699996,
                40.66638352799993
              ],
              [
                -73.75073016999993,
                40.66640190199997
              ],
              [
                -73.75175886599992,
                40.6663953199999
              ],
              [
                -73.75318579199988,
                40.666400028999924
              ],
              [
                -73.75336722899982,
                40.6664006249999
              ],
              [
                -73.7552623119999,
                40.666394765999954
              ],
              [
                -73.75551141799991,
                40.6663987639999
              ],
              [
                -73.75602173399979,
                40.66639171599989
              ],
              [
                -73.75756437999986,
                40.666448683999924
              ],
              [
                -73.75763129599991,
                40.66645115699992
              ],
              [
                -73.75772789299985,
                40.66646098699994
              ],
              [
                -73.76079559299997,
                40.66677326799993
              ],
              [
                -73.76182231299998,
                40.66687775999986
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000310835395162,
        "objectid": 225,
        "shape_leng": 0.0887992073281,
        "location_id": 225,
        "zone": "Stuyvesant Heights",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.91741294299997,
                40.68403405199995
              ],
              [
                -73.91755763299994,
                40.68476719199989
              ],
              [
                -73.91762630599987,
                40.685118148999884
              ],
              [
                -73.91763742499995,
                40.685174975999885
              ],
              [
                -73.91764776599992,
                40.68522782299993
              ],
              [
                -73.91765638699994,
                40.68527188299991
              ],
              [
                -73.91770109499986,
                40.68550035899995
              ],
              [
                -73.91776547599996,
                40.68581854099989
              ],
              [
                -73.91778426799988,
                40.6859114119999
              ],
              [
                -73.91783531199984,
                40.686163668999875
              ],
              [
                -73.91784937499982,
                40.68623316699994
              ],
              [
                -73.91789200399997,
                40.68644915899997
              ],
              [
                -73.917915484,
                40.68656593599996
              ],
              [
                -73.91799580199995,
                40.686965380999894
              ],
              [
                -73.91804607,
                40.68721324799989
              ],
              [
                -73.91829345799982,
                40.687356667999936
              ],
              [
                -73.91878323299994,
                40.687626087999924
              ],
              [
                -73.91906109199991,
                40.68778436899987
              ],
              [
                -73.9198096189999,
                40.688211240999856
              ],
              [
                -73.92055013100003,
                40.68864092199994
              ],
              [
                -73.92096103899999,
                40.688872051999915
              ],
              [
                -73.92121369999991,
                40.68901947699987
              ],
              [
                -73.92130587799981,
                40.68907164399991
              ],
              [
                -73.92207133799984,
                40.68948984099993
              ],
              [
                -73.922807024,
                40.68992392499995
              ],
              [
                -73.9231696669999,
                40.690123405999884
              ],
              [
                -73.9236437979999,
                40.69039883799992
              ],
              [
                -73.92429213299994,
                40.69077335699992
              ],
              [
                -73.92439183300002,
                40.690831748999926
              ],
              [
                -73.92536488899991,
                40.69136634399996
              ],
              [
                -73.92556064099996,
                40.69149083999987
              ],
              [
                -73.92631612499989,
                40.691928452999925
              ],
              [
                -73.92644992099981,
                40.69200176899993
              ],
              [
                -73.92707069499986,
                40.692346223999955
              ],
              [
                -73.92750685299994,
                40.69260381099993
              ],
              [
                -73.92781120699985,
                40.692774208999936
              ],
              [
                -73.92804330699992,
                40.69290417199992
              ],
              [
                -73.92850659699988,
                40.693165725999904
              ],
              [
                -73.92864268999999,
                40.6932410129999
              ],
              [
                -73.9288283419999,
                40.693350098999936
              ],
              [
                -73.92882897899982,
                40.69335047399987
              ],
              [
                -73.9297019239999,
                40.69386339699993
              ],
              [
                -73.93051146699992,
                40.694316522999905
              ],
              [
                -73.93114516499988,
                40.69467397499987
              ],
              [
                -73.93145784300005,
                40.6948554709999
              ],
              [
                -73.93177404599997,
                40.69502821499992
              ],
              [
                -73.93192890099984,
                40.69511465699991
              ],
              [
                -73.93236605699987,
                40.69535837699995
              ],
              [
                -73.93311862899988,
                40.69579115399997
              ],
              [
                -73.93382817199998,
                40.69618427699988
              ],
              [
                -73.93413397299992,
                40.696358430999936
              ],
              [
                -73.93448784799989,
                40.696560267999914
              ],
              [
                -73.93457999399985,
                40.69661282399995
              ],
              [
                -73.93460675199995,
                40.696628084999865
              ],
              [
                -73.93468892700004,
                40.696674953999946
              ],
              [
                -73.93479699699994,
                40.69673659199992
              ],
              [
                -73.93522264399988,
                40.69698654499995
              ],
              [
                -73.93600679799988,
                40.69740574899992
              ],
              [
                -73.93638762599981,
                40.69761530199985
              ],
              [
                -73.93675978999995,
                40.69783118899989
              ],
              [
                -73.93739064799986,
                40.69818579499985
              ],
              [
                -73.93777354599992,
                40.69840469299989
              ],
              [
                -73.93817718599986,
                40.69864022299987
              ],
              [
                -73.93856854799981,
                40.69884813599989
              ],
              [
                -73.93896470899988,
                40.69880103199995
              ],
              [
                -73.93906425899979,
                40.698789194999925
              ],
              [
                -73.93952025999982,
                40.69873497499989
              ],
              [
                -73.93960973999992,
                40.69872433499995
              ],
              [
                -73.94002089599995,
                40.69867544399993
              ],
              [
                -73.94011148799991,
                40.69866467199989
              ],
              [
                -73.94051277999984,
                40.69861695199995
              ],
              [
                -73.9405942079999,
                40.698607267999904
              ],
              [
                -73.94072013299994,
                40.69859229399992
              ],
              [
                -73.94081408799997,
                40.69858111999987
              ],
              [
                -73.94115035299995,
                40.69854113199994
              ],
              [
                -73.94176673799987,
                40.69847070399986
              ],
              [
                -73.94185058700002,
                40.69846112399996
              ],
              [
                -73.94247471499993,
                40.698389807999945
              ],
              [
                -73.94253735900001,
                40.69838264999991
              ],
              [
                -73.9429206409998,
                40.698338850999974
              ],
              [
                -73.94299724499987,
                40.69833009799987
              ],
              [
                -73.94394947299996,
                40.698221278999924
              ],
              [
                -73.94391750199989,
                40.698056201999854
              ],
              [
                -73.94380383199999,
                40.69746926499997
              ],
              [
                -73.94378455600005,
                40.697369728999924
              ],
              [
                -73.9437430669999,
                40.69715549899994
              ],
              [
                -73.9437245359998,
                40.69705981199991
              ],
              [
                -73.94368427099982,
                40.69685190199992
              ],
              [
                -73.9436380689999,
                40.69661331799992
              ],
              [
                -73.94362121399985,
                40.696526278999876
              ],
              [
                -73.94355634200002,
                40.696191282999926
              ],
              [
                -73.94354024099975,
                40.696108141999964
              ],
              [
                -73.94352527499989,
                40.6960308549999
              ],
              [
                -73.943388021,
                40.69528898999993
              ],
              [
                -73.94324249099988,
                40.694557484999955
              ],
              [
                -73.94312826799984,
                40.6939670379999
              ],
              [
                -73.9431142779999,
                40.6938947199999
              ],
              [
                -73.94310040999994,
                40.69382302899988
              ],
              [
                -73.942951361,
                40.693090783999885
              ],
              [
                -73.94280765199987,
                40.69235779399988
              ],
              [
                -73.94266181699986,
                40.69162434399988
              ],
              [
                -73.94251587899991,
                40.690892000999916
              ],
              [
                -73.94236932699991,
                40.6901599449999
              ],
              [
                -73.94222203500003,
                40.68942797899993
              ],
              [
                -73.9420768489998,
                40.688697201999894
              ],
              [
                -73.94193245099987,
                40.68796295899991
              ],
              [
                -73.94178527599988,
                40.68722837199987
              ],
              [
                -73.94163933199995,
                40.686497269999954
              ],
              [
                -73.94149491799988,
                40.685764528999904
              ],
              [
                -73.94134827200003,
                40.68503120299989
              ],
              [
                -73.94120399299996,
                40.68429983899993
              ],
              [
                -73.94105783799992,
                40.68356687199986
              ],
              [
                -73.940913343,
                40.68283361699987
              ],
              [
                -73.94076893299986,
                40.6821008389999
              ],
              [
                -73.94062005399995,
                40.68137012999991
              ],
              [
                -73.94047634999994,
                40.68063569499987
              ],
              [
                -73.94032794,
                40.67988997499989
              ],
              [
                -73.93843613299987,
                40.67978585199994
              ],
              [
                -73.93739763699983,
                40.679729899999934
              ],
              [
                -73.93487210899985,
                40.679593087999855
              ],
              [
                -73.93435066499993,
                40.67956340799992
              ],
              [
                -73.93439530999997,
                40.67983537999992
              ],
              [
                -73.93450838999993,
                40.68022169299993
              ],
              [
                -73.93457098299989,
                40.68056476199989
              ],
              [
                -73.93471441099992,
                40.68129738399991
              ],
              [
                -73.93486257200001,
                40.68203056899991
              ],
              [
                -73.93500821699992,
                40.68276376799985
              ],
              [
                -73.93208669799994,
                40.6831003499999
              ],
              [
                -73.92916130699992,
                40.68343632399992
              ],
              [
                -73.92900883999985,
                40.68270290499991
              ],
              [
                -73.92609461699992,
                40.68303870999989
              ],
              [
                -73.92281374299995,
                40.68341442999991
              ],
              [
                -73.92267012199994,
                40.68268151199991
              ],
              [
                -73.9225447959999,
                40.68193561299989
              ],
              [
                -73.92250799299985,
                40.68171006999997
              ],
              [
                -73.92249266199997,
                40.681616108999926
              ],
              [
                -73.92242652099985,
                40.68121074499994
              ],
              [
                -73.92240197099996,
                40.68108955299994
              ],
              [
                -73.92238256000003,
                40.68099373699995
              ],
              [
                -73.92236962399983,
                40.68092987999992
              ],
              [
                -73.92235458599998,
                40.68085564499992
              ],
              [
                -73.92233849599998,
                40.680776217999885
              ],
              [
                -73.92231261899987,
                40.68064847299997
              ],
              [
                -73.92227774199988,
                40.6804762929999
              ],
              [
                -73.92225617599988,
                40.68036776399987
              ],
              [
                -73.92223681799994,
                40.680270342999904
              ],
              [
                -73.92221626999996,
                40.68016693499992
              ],
              [
                -73.92217715699984,
                40.6799700849999
              ],
              [
                -73.92215222999988,
                40.679844634999895
              ],
              [
                -73.92213242899994,
                40.67974497799986
              ],
              [
                -73.92211149999984,
                40.67965755699987
              ],
              [
                -73.92208787199982,
                40.679558857999915
              ],
              [
                -73.92205453499997,
                40.67941960399992
              ],
              [
                -73.92203214100005,
                40.67932605899988
              ],
              [
                -73.92201534599985,
                40.67925589999992
              ],
              [
                -73.9219999079998,
                40.679191413999895
              ],
              [
                -73.92197287199998,
                40.67907846999987
              ],
              [
                -73.92183500099996,
                40.67894555899992
              ],
              [
                -73.92164666399992,
                40.67886992299992
              ],
              [
                -73.91905083899984,
                40.678728473999875
              ],
              [
                -73.91796830599993,
                40.67866902199989
              ],
              [
                -73.91720270699984,
                40.678626814999916
              ],
              [
                -73.9163011989999,
                40.678577111999914
              ],
              [
                -73.91639300100002,
                40.678905848999904
              ],
              [
                -73.916539987,
                40.67963749699988
              ],
              [
                -73.91668638399985,
                40.680371378999965
              ],
              [
                -73.91683011499998,
                40.68110098099987
              ],
              [
                -73.9169763279999,
                40.68183415899995
              ],
              [
                -73.91712138499985,
                40.682568471999964
              ],
              [
                -73.9172663269999,
                40.68330322399991
              ],
              [
                -73.91741294299997,
                40.68403405199995
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000313035744467,
        "objectid": 220,
        "shape_leng": 0.106015943267,
        "location_id": 220,
        "zone": "Spuyten Duyvil/Kingsbridge",
        "borough": "Bronx"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.89827216899992,
                40.8894579429999
              ],
              [
                -73.89787887499983,
                40.89027610099993
              ],
              [
                -73.89801518,
                40.89031454599989
              ],
              [
                -73.89820200999998,
                40.89041593499998
              ],
              [
                -73.89825201999993,
                40.89043496999996
              ],
              [
                -73.89828904299992,
                40.890442980999914
              ],
              [
                -73.898348963,
                40.89044499699987
              ],
              [
                -73.89842801299993,
                40.89043097399991
              ],
              [
                -73.89853911899992,
                40.89038907399993
              ],
              [
                -73.89959425899988,
                40.889816998999905
              ],
              [
                -73.89987570700002,
                40.88954945799986
              ],
              [
                -73.90006286299982,
                40.88945861599991
              ],
              [
                -73.90024058900005,
                40.88935606899991
              ],
              [
                -73.90040701199986,
                40.889242697999876
              ],
              [
                -73.90056049099992,
                40.88911965299999
              ],
              [
                -73.9006996359998,
                40.88898822099989
              ],
              [
                -73.90079375300003,
                40.88894284499989
              ],
              [
                -73.90090301399997,
                40.888910548999895
              ],
              [
                -73.90102228799991,
                40.888895126999884
              ],
              [
                -73.90114449999989,
                40.888898679999926
              ],
              [
                -73.90126187999986,
                40.88892096799992
              ],
              [
                -73.90136756999983,
                40.88895949999994
              ],
              [
                -73.90145684499987,
                40.88901019299989
              ],
              [
                -73.90155978899989,
                40.8890681819999
              ],
              [
                -73.90167746699991,
                40.88911356799987
              ],
              [
                -73.90180553899997,
                40.88914341999991
              ],
              [
                -73.90193848899995,
                40.88915604099992
              ],
              [
                -73.90207033599997,
                40.88915127199995
              ],
              [
                -73.90219556399998,
                40.889130414999926
              ],
              [
                -73.902327386,
                40.889111381999925
              ],
              [
                -73.90246297399985,
                40.88910514799996
              ],
              [
                -73.9025990649999,
                40.88911217999988
              ],
              [
                -73.90273228799997,
                40.88913231799993
              ],
              [
                -73.90285949099989,
                40.8891647439999
              ],
              [
                -73.90300926099984,
                40.888815111999875
              ],
              [
                -73.9031806419999,
                40.88847059499993
              ],
              [
                -73.903373145,
                40.88813234499987
              ],
              [
                -73.90358612699993,
                40.8878014589999
              ],
              [
                -73.90381882699994,
                40.88747900899994
              ],
              [
                -73.90413642399992,
                40.887080708999896
              ],
              [
                -73.90533517099996,
                40.887354598999885
              ],
              [
                -73.90626252500002,
                40.88757033799994
              ],
              [
                -73.90738830199982,
                40.88781969199989
              ],
              [
                -73.90759731899983,
                40.888200979999965
              ],
              [
                -73.9079634,
                40.88885975299993
              ],
              [
                -73.90831585200002,
                40.889115896999954
              ],
              [
                -73.90837360299984,
                40.889116015999896
              ],
              [
                -73.90843131799984,
                40.889124993999914
              ],
              [
                -73.90848550899992,
                40.88914254199988
              ],
              [
                -73.90853308099986,
                40.88916738899993
              ],
              [
                -73.90855714199998,
                40.88928966699996
              ],
              [
                -73.90877717899996,
                40.889370172999854
              ],
              [
                -73.90881651000004,
                40.889390590999916
              ],
              [
                -73.90887023199998,
                40.88942175399989
              ],
              [
                -73.90900536,
                40.889500140999864
              ],
              [
                -73.90929422499987,
                40.889282296999944
              ],
              [
                -73.90975930299997,
                40.889250027999886
              ],
              [
                -73.90981767099994,
                40.888996568999886
              ],
              [
                -73.91075165299989,
                40.888492454999906
              ],
              [
                -73.91115061799995,
                40.88827710599987
              ],
              [
                -73.91130760799977,
                40.888236029999874
              ],
              [
                -73.91173089099983,
                40.88821871299996
              ],
              [
                -73.9118380099998,
                40.887935438999904
              ],
              [
                -73.9119642749998,
                40.88765633999993
              ],
              [
                -73.91210920699979,
                40.88738250499992
              ],
              [
                -73.91227221199996,
                40.88711502299987
              ],
              [
                -73.91243612599989,
                40.886868448999934
              ],
              [
                -73.91261653600003,
                40.88662819299988
              ],
              [
                -73.91281277499982,
                40.886395186999884
              ],
              [
                -73.91302404599978,
                40.88617032299987
              ],
              [
                -73.91347365499988,
                40.88577197099993
              ],
              [
                -73.91386874899996,
                40.88543763699992
              ],
              [
                -73.91393522100002,
                40.88538139699996
              ],
              [
                -73.91440838499985,
                40.8849988979999
              ],
              [
                -73.9148927699999,
                40.8846247709999
              ],
              [
                -73.91586527699988,
                40.8848581069999
              ],
              [
                -73.91630438600004,
                40.88490951099997
              ],
              [
                -73.91876151499994,
                40.8855696849999
              ],
              [
                -73.91870982599988,
                40.88578600699989
              ],
              [
                -73.91867419999998,
                40.8860048999999
              ],
              [
                -73.91865495699987,
                40.88622532499995
              ],
              [
                -73.91865219599984,
                40.886446221999954
              ],
              [
                -73.91866590399985,
                40.8866665349999
              ],
              [
                -73.91869590499982,
                40.8868852029999
              ],
              [
                -73.920398527,
                40.887129304999846
              ],
              [
                -73.92058641888602,
                40.887182678309856
              ],
              [
                -73.92106413087296,
                40.88615556120489
              ],
              [
                -73.92138842752367,
                40.8854590523609
              ],
              [
                -73.92182955043396,
                40.884511600836916
              ],
              [
                -73.92380525605498,
                40.880324901636136
              ],
              [
                -73.92490327486523,
                40.87888836792653
              ],
              [
                -73.92477853447213,
                40.8789048930686
              ],
              [
                -73.92472342192558,
                40.878929563137696
              ],
              [
                -73.92468081924542,
                40.878961843113316
              ],
              [
                -73.92465572010455,
                40.87901503911896
              ],
              [
                -73.92461565442643,
                40.879018813162645
              ],
              [
                -73.9245806172334,
                40.8790035870425
              ],
              [
                -73.92453803212285,
                40.87902066382244
              ],
              [
                -73.92453549398189,
                40.879051069428506
              ],
              [
                -73.92451795875512,
                40.87905865928088
              ],
              [
                -73.92449042789555,
                40.879047238978366
              ],
              [
                -73.92431268568636,
                40.87901861501658
              ],
              [
                -73.92417999610814,
                40.87900522556883
              ],
              [
                -73.92406481616945,
                40.8790070501429
              ],
              [
                -73.92405728847959,
                40.879020348152864
              ],
              [
                -73.92403725610205,
                40.87902223524943
              ],
              [
                -73.92398718777541,
                40.879014600929025
              ],
              [
                -73.92396967806097,
                40.8789993854873
              ],
              [
                -73.92394215243536,
                40.87898416315756
              ],
              [
                -73.9239020992123,
                40.878976535870166
              ],
              [
                -73.92382697888756,
                40.87898028680117
              ],
              [
                -73.92379441334087,
                40.878993569041214
              ],
              [
                -73.92374935500642,
                40.87898403604847
              ],
              [
                -73.92362973975199,
                40.878963943121555
              ],
              [
                -73.92359172576555,
                40.8789521591663
              ],
              [
                -73.92330375398006,
                40.878907722870565
              ],
              [
                -73.92326869180737,
                40.87891530105498
              ],
              [
                -73.92325917211696,
                40.878921071148184
              ],
              [
                -73.92324363504969,
                40.87893048898557
              ],
              [
                -73.92318603903094,
                40.87893615197473
              ],
              [
                -73.9231635068568,
                40.87893423653789
              ],
              [
                -73.92312345761908,
                40.878922807153145
              ],
              [
                -73.92309319824349,
                40.87890555521609
              ],
              [
                -73.92304082211855,
                40.87893035395367
              ],
              [
                -73.92295065383705,
                40.878954999157195
              ],
              [
                -73.92287303885502,
                40.87895114602392
              ],
              [
                -73.92279794475571,
                40.87893209178376
              ],
              [
                -73.92273788293744,
                40.87890544521391
              ],
              [
                -73.92267879914924,
                40.87886681391701
              ],
              [
                -73.92258529790826,
                40.878776112092886
              ],
              [
                -73.92253523103413,
                40.87876657593437
              ],
              [
                -73.92252221885806,
                40.87876471500181
              ],
              [
                -73.92245512078604,
                40.87875512007153
              ],
              [
                -73.92243012179054,
                40.87872089477376
              ],
              [
                -73.92237506622932,
                40.87869615122854
              ],
              [
                -73.92229996812422,
                40.87868089778524
              ],
              [
                -73.92221986267197,
                40.878665639878726
              ],
              [
                -73.92215230058137,
                40.87862948601803
              ],
              [
                -73.9220672367594,
                40.87857241490144
              ],
              [
                -73.92182211821769,
                40.8783517962847
              ],
              [
                -73.92169203707775,
                40.87825098410151
              ],
              [
                -73.92163286288483,
                40.87818479384493
              ],
              [
                -73.9216063027348,
                40.878157102290686
              ],
              [
                -73.9215771041196,
                40.87813654256217
              ],
              [
                -73.92025638949379,
                40.87695199013829
              ],
              [
                -73.92015400779998,
                40.87672386589551
              ],
              [
                -73.92011149588643,
                40.87668012575257
              ],
              [
                -73.920080284654,
                40.87668010394761
              ],
              [
                -73.9200714360083,
                40.876680097883686
              ],
              [
                -73.92005393019218,
                40.87666298111853
              ],
              [
                -73.92005645922515,
                40.87665692075475
              ],
              [
                -73.92006899571231,
                40.87662688332882
              ],
              [
                -73.92002897648118,
                40.87659264706156
              ],
              [
                -73.9199689385686,
                40.87654889515391
              ],
              [
                -73.91994332109749,
                40.87654683020628
              ],
              [
                -73.91992137189635,
                40.876545061945706
              ],
              [
                -73.91987703590954,
                40.876508887210406
              ],
              [
                -73.91978371651518,
                40.8765012552125
              ],
              [
                -73.91978381266422,
                40.876421436052915
              ],
              [
                -73.91908326345383,
                40.87606488191286
              ],
              [
                -73.91832983816866,
                40.87583697616597
              ],
              [
                -73.91829727517937,
                40.87584835619441
              ],
              [
                -73.91827974001829,
                40.87585594591024
              ],
              [
                -73.9182597238811,
                40.875844529771996
              ],
              [
                -73.91824974216237,
                40.87581791600413
              ],
              [
                -73.91808452282606,
                40.875794993071196
              ],
              [
                -73.91799187213586,
                40.87580442894817
              ],
              [
                -73.9179192956966,
                40.875777771168146
              ],
              [
                -73.91783922520155,
                40.87573780495587
              ],
              [
                -73.91778922586424,
                40.87567695509723
              ],
              [
                -73.91775669364831,
                40.8756636279301
              ],
              [
                -73.91768907119067,
                40.87568068402884
              ],
              [
                -73.91747364879211,
                40.875762250076576
              ],
              [
                -73.91740100893917,
                40.875786904136554
              ],
              [
                -73.91733338905482,
                40.875802059107805
              ],
              [
                -73.91726028318647,
                40.87580900379031
              ],
              [
                -73.91578609320084,
                40.87571718248032
              ],
              [
                -73.91574356,
                40.875760999999954
              ],
              [
                -73.91571027699979,
                40.875795454999924
              ],
              [
                -73.91487449199987,
                40.876642145999924
              ],
              [
                -73.91205829999997,
                40.878119319999904
              ],
              [
                -73.91148913199984,
                40.87901885899991
              ],
              [
                -73.91033193599998,
                40.879038046999895
              ],
              [
                -73.90949832099987,
                40.87877673199989
              ],
              [
                -73.90906235999998,
                40.878486782999886
              ],
              [
                -73.90835569799991,
                40.87791831399997
              ],
              [
                -73.90765281699993,
                40.87742802299989
              ],
              [
                -73.90703748899998,
                40.87689516099993
              ],
              [
                -73.9068187309999,
                40.87661958799991
              ],
              [
                -73.90666530999995,
                40.875715608999904
              ],
              [
                -73.90692502800006,
                40.87503467099995
              ],
              [
                -73.90746489699994,
                40.873547353999854
              ],
              [
                -73.90669984700006,
                40.87339676199991
              ],
              [
                -73.90662887099994,
                40.87338037899989
              ],
              [
                -73.90636316599984,
                40.87331904699993
              ],
              [
                -73.90614668899998,
                40.87326020499995
              ],
              [
                -73.90608130599988,
                40.873239628999904
              ],
              [
                -73.90605459699991,
                40.87329392499994
              ],
              [
                -73.90556943300004,
                40.87423099199992
              ],
              [
                -73.90526442899994,
                40.8747911099999
              ],
              [
                -73.90524600299995,
                40.87482494899992
              ],
              [
                -73.90503517999984,
                40.87523228299991
              ],
              [
                -73.90483848999986,
                40.875643742999905
              ],
              [
                -73.90465612299992,
                40.876058915999856
              ],
              [
                -73.90448826999989,
                40.87647735999989
              ],
              [
                -73.90417988499996,
                40.87733663399998
              ],
              [
                -73.903898338,
                40.87812110799993
              ],
              [
                -73.90369454799992,
                40.87850161799988
              ],
              [
                -73.90333605399985,
                40.87897514599996
              ],
              [
                -73.90226256899994,
                40.88010809899995
              ],
              [
                -73.9015065839999,
                40.88095490599994
              ],
              [
                -73.89988688799993,
                40.88282880899994
              ],
              [
                -73.89914458899997,
                40.88368756599993
              ],
              [
                -73.89855059899999,
                40.884374726999965
              ],
              [
                -73.89843428799998,
                40.88447464199989
              ],
              [
                -73.89835487800003,
                40.8845428649999
              ],
              [
                -73.89808000699986,
                40.884778999999874
              ],
              [
                -73.89781492099993,
                40.88499918299995
              ],
              [
                -73.89760048500003,
                40.885177292999906
              ],
              [
                -73.89711223299999,
                40.88556943499988
              ],
              [
                -73.89661546599986,
                40.885955255999946
              ],
              [
                -73.89668243100002,
                40.885964589999965
              ],
              [
                -73.89688178399989,
                40.88599934699987
              ],
              [
                -73.89715645399986,
                40.88604482399989
              ],
              [
                -73.89738336099998,
                40.88608677199995
              ],
              [
                -73.89892571799993,
                40.88638350199994
              ],
              [
                -73.89972588599989,
                40.88651488699986
              ],
              [
                -73.89965185599996,
                40.88666493399991
              ],
              [
                -73.89936426899996,
                40.88724908999995
              ],
              [
                -73.89922499099997,
                40.88753080299989
              ],
              [
                -73.89827216899992,
                40.8894579429999
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0000984786989135,
        "objectid": 224,
        "shape_leng": 0.0440204462769,
        "location_id": 224,
        "zone": "Stuy Town/Peter Cooper Village",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.97511055399991,
                40.73539025499987
              ],
              [
                -73.97522275599997,
                40.73539790699988
              ],
              [
                -73.97589093699993,
                40.735673198999955
              ],
              [
                -73.9763637329999,
                40.73586797799992
              ],
              [
                -73.97712441199997,
                40.736196797999895
              ],
              [
                -73.97792798399998,
                40.73654414599988
              ],
              [
                -73.97833820599989,
                40.7367214709999
              ],
              [
                -73.97849845599995,
                40.73679095699989
              ],
              [
                -73.97857449799984,
                40.736677280999864
              ],
              [
                -73.97890917399987,
                40.73616412599992
              ],
              [
                -73.9793481959999,
                40.73555311199989
              ],
              [
                -73.97944606000004,
                40.73541451499994
              ],
              [
                -73.97978904000004,
                40.734961816999906
              ],
              [
                -73.98009050499998,
                40.734544370999906
              ],
              [
                -73.98020496200002,
                40.73438532199987
              ],
              [
                -73.98041991299985,
                40.73408982099988
              ],
              [
                -73.980644989,
                40.733779319999904
              ],
              [
                -73.98088975699991,
                40.73343707099984
              ],
              [
                -73.98105323600001,
                40.73320848199997
              ],
              [
                -73.98125724799999,
                40.732938574999885
              ],
              [
                -73.98149910399985,
                40.732618595999924
              ],
              [
                -73.98180339299985,
                40.73220479799991
              ],
              [
                -73.98196782199994,
                40.7319825609999
              ],
              [
                -73.98232282599996,
                40.73149788999992
              ],
              [
                -73.98238628699993,
                40.7314112439999
              ],
              [
                -73.98255629299985,
                40.7313501339999
              ],
              [
                -73.98246876199994,
                40.731314883999914
              ],
              [
                -73.98150053399984,
                40.73091807699994
              ],
              [
                -73.98082000799998,
                40.73063915899997
              ],
              [
                -73.98034007599992,
                40.73044246099994
              ],
              [
                -73.97926849199995,
                40.72998142799986
              ],
              [
                -73.97802697999985,
                40.729433059999984
              ],
              [
                -73.97739386799988,
                40.72916504799993
              ],
              [
                -73.97690436400002,
                40.72895782399994
              ],
              [
                -73.976269971,
                40.7286892589999
              ],
              [
                -73.97615204099999,
                40.728645209999854
              ],
              [
                -73.9761329,
                40.72863827199994
              ],
              [
                -73.97580243799992,
                40.72851847999988
              ],
              [
                -73.97569651100005,
                40.728475052999926
              ],
              [
                -73.97329796799995,
                40.72742800799989
              ],
              [
                -73.97205015299988,
                40.72688094599988
              ],
              [
                -73.97197685099988,
                40.72683035599993
              ],
              [
                -73.97191244199995,
                40.72679100899989
              ],
              [
                -73.97185671300005,
                40.7267586849999
              ],
              [
                -73.97179602099986,
                40.72671367999989
              ],
              [
                -73.97162697285862,
                40.72662845299843
              ],
              [
                -73.97149039092838,
                40.72735009810991
              ],
              [
                -73.97149260058237,
                40.727373123351384
              ],
              [
                -73.97151282181225,
                40.72737430858104
              ],
              [
                -73.97152076789605,
                40.72738616623977
              ],
              [
                -73.97153686592743,
                40.72739296510087
              ],
              [
                -73.9715611436591,
                40.72739468824764
              ],
              [
                -73.97158604106335,
                40.72739693305272
              ],
              [
                -73.97168514866578,
                40.727405886636525
              ],
              [
                -73.97165160075565,
                40.72764321253462
              ],
              [
                -73.97163586152143,
                40.72764797412135
              ],
              [
                -73.97161872706992,
                40.72765055818574
              ],
              [
                -73.97158965149985,
                40.72764003369323
              ],
              [
                -73.97157278760149,
                40.72764591976768
              ],
              [
                -73.97156931647098,
                40.72769376719842
              ],
              [
                -73.97155553514948,
                40.72770310859809
              ],
              [
                -73.97151048447923,
                40.7280027639066
              ],
              [
                -73.97147343819977,
                40.728209255928085
              ],
              [
                -73.97145662182912,
                40.72831923851683
              ],
              [
                -73.97145269559667,
                40.728341433313076
              ],
              [
                -73.97142718443534,
                40.72848582638753
              ],
              [
                -73.97141946379264,
                40.72856882754402
              ],
              [
                -73.9714061790851,
                40.728718764251106
              ],
              [
                -73.97140659672053,
                40.72879447130567
              ],
              [
                -73.97141235109783,
                40.728879856021706
              ],
              [
                -73.97142139208222,
                40.728995843972214
              ],
              [
                -73.9714322181421,
                40.72906361904417
              ],
              [
                -73.97143981566721,
                40.72909584986445
              ],
              [
                -73.97145716122313,
                40.729169407557954
              ],
              [
                -73.97147994907196,
                40.72924957769849
              ],
              [
                -73.97150816031564,
                40.72932396649239
              ],
              [
                -73.97151882628022,
                40.72935136174009
              ],
              [
                -73.97157719441799,
                40.729469136637825
              ],
              [
                -73.97162729998553,
                40.72955788414599
              ],
              [
                -73.97170029038125,
                40.72966570245395
              ],
              [
                -73.97174494790077,
                40.729734548588496
              ],
              [
                -73.9717482235702,
                40.72974201454455
              ],
              [
                -73.97182992841701,
                40.72984569147017
              ],
              [
                -73.97185608949026,
                40.729875550267224
              ],
              [
                -73.97190238512813,
                40.729930708644424
              ],
              [
                -73.97196340301248,
                40.73000037721747
              ],
              [
                -73.97201026033457,
                40.73004517148755
              ],
              [
                -73.97205385214981,
                40.73008665043243
              ],
              [
                -73.9720891859024,
                40.73011835704164
              ],
              [
                -73.97223370120912,
                40.73022567784319
              ],
              [
                -73.97224018578797,
                40.7302304971359
              ],
              [
                -73.97233277439494,
                40.73029657297067
              ],
              [
                -73.97245936471883,
                40.73038632937771
              ],
              [
                -73.97247970053027,
                40.73040080736293
              ],
              [
                -73.97255990007288,
                40.730458093618154
              ],
              [
                -73.97264270492411,
                40.73052353780736
              ],
              [
                -73.9727461164315,
                40.73060583194699
              ],
              [
                -73.97283215562831,
                40.73068148130965
              ],
              [
                -73.97291929100172,
                40.73075024150268
              ],
              [
                -73.97300605975387,
                40.730823131910434
              ],
              [
                -73.97305304853799,
                40.73086730375558
              ],
              [
                -73.97309858148473,
                40.730913679890705
              ],
              [
                -73.9731459279161,
                40.73096088099614
              ],
              [
                -73.97320012915407,
                40.731016632390016
              ],
              [
                -73.97323625417937,
                40.731056926741
              ],
              [
                -73.97328321111144,
                40.731112675908484
              ],
              [
                -73.97331715426387,
                40.73115792717309
              ],
              [
                -73.97334064688678,
                40.73118304166194
              ],
              [
                -73.9733659100069,
                40.731220019948424
              ],
              [
                -73.97338181410112,
                40.73123602442203
              ],
              [
                -73.97341321730852,
                40.73128241642864
              ],
              [
                -73.97352137914261,
                40.73169016478128
              ],
              [
                -73.9735396928971,
                40.73176433282338
              ],
              [
                -73.97360833346643,
                40.73204268972966
              ],
              [
                -73.9736273640125,
                40.732107445607845
              ],
              [
                -73.97364735766598,
                40.732175493766555
              ],
              [
                -73.97367702799221,
                40.73231023125138
              ],
              [
                -73.9737703082618,
                40.73268844617683
              ],
              [
                -73.97386810776207,
                40.733065687195605
              ],
              [
                -73.97387206174007,
                40.73309495480662
              ],
              [
                -73.97384036797052,
                40.73310355629585
              ],
              [
                -73.97381887171846,
                40.733099247654614
              ],
              [
                -73.97380529755296,
                40.7331207574843
              ],
              [
                -73.97377813922648,
                40.73312419146021
              ],
              [
                -73.9737577603272,
                40.733139684862834
              ],
              [
                -73.97373739762315,
                40.73314311937236
              ],
              [
                -73.97372607356364,
                40.73315947055946
              ],
              [
                -73.97371588914245,
                40.73316979674709
              ],
              [
                -73.97369212859832,
                40.73317409574745
              ],
              [
                -73.97369438098605,
                40.733195609010984
              ],
              [
                -73.97367627576054,
                40.733205933888065
              ],
              [
                -73.97368984982582,
                40.73321885110607
              ],
              [
                -73.97370795169591,
                40.73321368520495
              ],
              [
                -73.97372718707769,
                40.73321971435091
              ],
              [
                -73.97374076899366,
                40.73320938893705
              ],
              [
                -73.9737645264947,
                40.73321284338783
              ],
              [
                -73.97378376867147,
                40.733199934826935
              ],
              [
                -73.97380298847429,
                40.733211987539875
              ],
              [
                -73.97378375783396,
                40.73322748070547
              ],
              [
                -73.97380976846544,
                40.733248999372925
              ],
              [
                -73.97383126846695,
                40.73324556510916
              ],
              [
                -73.9738493738127,
                40.73323265510628
              ],
              [
                -73.97384145962661,
                40.733219740365655
              ],
              [
                -73.97385730036494,
                40.73321630363855
              ],
              [
                -73.97386520802397,
                40.73324642803961
              ],
              [
                -73.97384483854864,
                40.733265361113745
              ],
              [
                -73.97385275193625,
                40.73328257140103
              ],
              [
                -73.97386292958613,
                40.733291182396094
              ],
              [
                -73.97386631865577,
                40.73331097608678
              ],
              [
                -73.97389120382235,
                40.733309261904566
              ],
              [
                -73.97390137711359,
                40.73332733730498
              ],
              [
                -73.97390702695192,
                40.73334971660826
              ],
              [
                -73.9739353135698,
                40.73336607678393
              ],
              [
                -73.97394321924938,
                40.73340308886323
              ],
              [
                -73.97395905029705,
                40.73342116553848
              ],
              [
                -73.97399237089745,
                40.73355457425366
              ],
              [
                -73.97402174588252,
                40.73366389393438
              ],
              [
                -73.97406016216316,
                40.73381107244231
              ],
              [
                -73.97410591714231,
                40.73399354354752
              ],
              [
                -73.97415110840357,
                40.73416396616608
              ],
              [
                -73.97416917978923,
                40.73423971179249
              ],
              [
                -73.97418317281978,
                40.73430095691658
              ],
              [
                -73.97421154391887,
                40.73440582932186
              ],
              [
                -73.97425447779786,
                40.73456678630614
              ],
              [
                -73.97427029379338,
                40.734630482401556
              ],
              [
                -73.97431379174476,
                40.734803480784734
              ],
              [
                -73.97434824707203,
                40.73494205848576
              ],
              [
                -73.97437155854928,
                40.735030348615766
              ],
              [
                -73.97438778900487,
                40.735091821036896
              ],
              [
                -73.97440811789221,
                40.735177896632045
              ],
              [
                -73.97442281130166,
                40.73523384837344
              ],
              [
                -73.97443700979296,
                40.73531635606464
              ],
              [
                -73.97467193099992,
                40.73539830299991
              ],
              [
                -73.97474900599997,
                40.73542518799996
              ],
              [
                -73.9748261859999,
                40.73538167399991
              ],
              [
                -73.9748930509999,
                40.73537519599992
              ],
              [
                -73.97495992999993,
                40.73536871799993
              ],
              [
                -73.97511055399991,
                40.73539025499987
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.00076194414504,
        "objectid": 226,
        "shape_leng": 0.168957762041,
        "location_id": 226,
        "zone": "Sunnyside",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.90989961499994,
                40.749087274999944
              ],
              [
                -73.90971148199992,
                40.74975470899995
              ],
              [
                -73.90951622499988,
                40.75044165999993
              ],
              [
                -73.90948267299984,
                40.75056086099988
              ],
              [
                -73.90945183999992,
                40.75065545299985
              ],
              [
                -73.90945182699987,
                40.75065550699988
              ],
              [
                -73.90944332099993,
                40.7507550329999
              ],
              [
                -73.90944330899997,
                40.75075513199992
              ],
              [
                -73.9094433199999,
                40.750755230999886
              ],
              [
                -73.9094586379999,
                40.75085507399991
              ],
              [
                -73.9094586489999,
                40.75085514499993
              ],
              [
                -73.90945867199996,
                40.75085521699987
              ],
              [
                -73.90949707399992,
                40.750950583999895
              ],
              [
                -73.90949709799997,
                40.75095063899988
              ],
              [
                -73.90949713299983,
                40.750950683999875
              ],
              [
                -73.90955575999992,
                40.75103731299989
              ],
              [
                -73.90984318599997,
                40.75163867999989
              ],
              [
                -73.91035492999984,
                40.75274990899987
              ],
              [
                -73.91067474899997,
                40.75299507599989
              ],
              [
                -73.91256954199997,
                40.75330421499995
              ],
              [
                -73.9135418349999,
                40.7534687139999
              ],
              [
                -73.91446450799998,
                40.75367641199993
              ],
              [
                -73.91468429699998,
                40.753680068999856
              ],
              [
                -73.91547326299991,
                40.7537918179999
              ],
              [
                -73.91650534599987,
                40.75389158999995
              ],
              [
                -73.91763192799988,
                40.753857847999896
              ],
              [
                -73.91892348099987,
                40.75363612399991
              ],
              [
                -73.920276847,
                40.75333811799994
              ],
              [
                -73.92047925899985,
                40.753285236999965
              ],
              [
                -73.92161909499991,
                40.75302765399987
              ],
              [
                -73.9220402039999,
                40.75293762799989
              ],
              [
                -73.9229664339999,
                40.752728802999854
              ],
              [
                -73.9244174849999,
                40.75240430199992
              ],
              [
                -73.92562743799989,
                40.752137243999904
              ],
              [
                -73.92596072499995,
                40.75209646699996
              ],
              [
                -73.92646866899993,
                40.75206856799986
              ],
              [
                -73.92790807399994,
                40.75205079499992
              ],
              [
                -73.92874215599983,
                40.752041624999954
              ],
              [
                -73.92914224099992,
                40.75203153399992
              ],
              [
                -73.93015432399984,
                40.75201212499995
              ],
              [
                -73.93126506799997,
                40.75199252199999
              ],
              [
                -73.93195863799993,
                40.75197425799993
              ],
              [
                -73.9322762319998,
                40.75195388599995
              ],
              [
                -73.93383269399993,
                40.75160470199992
              ],
              [
                -73.93474259199988,
                40.7509790439999
              ],
              [
                -73.93609498999984,
                40.7497315299999
              ],
              [
                -73.93616086999987,
                40.74967406999991
              ],
              [
                -73.93645660599992,
                40.74941256799988
              ],
              [
                -73.93675697099987,
                40.74914697199995
              ],
              [
                -73.93739653799992,
                40.74891643999991
              ],
              [
                -73.93683480500002,
                40.7483945519999
              ],
              [
                -73.93663681899982,
                40.748204093999895
              ],
              [
                -73.93673442199999,
                40.74812894999997
              ],
              [
                -73.93952057199998,
                40.745983778999914
              ],
              [
                -73.93996463499987,
                40.74574558399994
              ],
              [
                -73.94032696100007,
                40.74541314299995
              ],
              [
                -73.94184736499984,
                40.74490368899993
              ],
              [
                -73.94272343099998,
                40.744576861999874
              ],
              [
                -73.94342742899987,
                40.74423117199994
              ],
              [
                -73.94383757199994,
                40.74387181499994
              ],
              [
                -73.94399533799991,
                40.74365885099996
              ],
              [
                -73.94401030399996,
                40.7436394539999
              ],
              [
                -73.94419561599983,
                40.742882145999936
              ],
              [
                -73.94297613999994,
                40.74336892999986
              ],
              [
                -73.94211068899993,
                40.74371373399991
              ],
              [
                -73.94174116799995,
                40.74385915099986
              ],
              [
                -73.94031559800003,
                40.7444044979999
              ],
              [
                -73.93925967299994,
                40.74474786799989
              ],
              [
                -73.9382576189999,
                40.74506843699991
              ],
              [
                -73.93769681599989,
                40.745238574999824
              ],
              [
                -73.93726110699993,
                40.745176490999896
              ],
              [
                -73.93765115799985,
                40.74330513499994
              ],
              [
                -73.93801851799994,
                40.74145502399992
              ],
              [
                -73.93819856599988,
                40.74054323199988
              ],
              [
                -73.93862452799989,
                40.73840478099993
              ],
              [
                -73.93864769800001,
                40.73832587299991
              ],
              [
                -73.93866464499992,
                40.73826815899986
              ],
              [
                -73.93867968899997,
                40.73820830199989
              ],
              [
                -73.93869664599998,
                40.73814081699989
              ],
              [
                -73.93982348499995,
                40.73844550399986
              ],
              [
                -73.939963245,
                40.73864135599992
              ],
              [
                -73.93993706299993,
                40.738688177999904
              ],
              [
                -73.9406430239998,
                40.73898181299989
              ],
              [
                -73.94140886345744,
                40.73929957085318
              ],
              [
                -73.94146173481568,
                40.73924858381353
              ],
              [
                -73.94215338158746,
                40.739080051880244
              ],
              [
                -73.94255612802647,
                40.73902086587337
              ],
              [
                -73.94280080814401,
                40.73888072353937
              ],
              [
                -73.9428038426758,
                40.738861547461326
              ],
              [
                -73.94257885812185,
                40.73881602026858
              ],
              [
                -73.9440212875199,
                40.73828766393555
              ],
              [
                -73.94446254857964,
                40.73811890624521
              ],
              [
                -73.94447895031381,
                40.73811257545662
              ],
              [
                -73.94449557756668,
                40.73810731459806
              ],
              [
                -73.94452525921655,
                40.738104744972134
              ],
              [
                -73.94453169955865,
                40.73810486500775
              ],
              [
                -73.94453842296237,
                40.73810572464098
              ],
              [
                -73.94454546685765,
                40.73810733863194
              ],
              [
                -73.94455295830856,
                40.738109728485355
              ],
              [
                -73.94456692344379,
                40.738115579367374
              ],
              [
                -73.94459950294983,
                40.73813146247688
              ],
              [
                -73.94460803640322,
                40.73813489752271
              ],
              [
                -73.94461565846566,
                40.738137287434846
              ],
              [
                -73.94462383708854,
                40.7381388855492
              ],
              [
                -73.94462770874195,
                40.7381392206208
              ],
              [
                -73.94463143837856,
                40.738139240004294
              ],
              [
                -73.94463507246135,
                40.73813895410864
              ],
              [
                -73.94463860064843,
                40.73813835203953
              ],
              [
                -73.94465794094907,
                40.7381295730668
              ],
              [
                -73.9446824542441,
                40.73810797209327
              ],
              [
                -73.94475495293814,
                40.738011283505344
              ],
              [
                -73.94476502360287,
                40.73800261599569
              ],
              [
                -73.94477519762279,
                40.737996264587444
              ],
              [
                -73.94478762138586,
                40.73799052522571
              ],
              [
                -73.944842096523,
                40.73797275600296
              ],
              [
                -73.94485391467622,
                40.737967989169995
              ],
              [
                -73.9448638162748,
                40.73796292422635
              ],
              [
                -73.94487440629372,
                40.73795607679523
              ],
              [
                -73.94489834665639,
                40.73793866299153
              ],
              [
                -73.9449113999564,
                40.73793094325276
              ],
              [
                -73.94496461225259,
                40.7379058179528
              ],
              [
                -73.94501457717676,
                40.737885534434064
              ],
              [
                -73.94505824046409,
                40.73787025608997
              ],
              [
                -73.9451023158267,
                40.73785656205554
              ],
              [
                -73.94524265235826,
                40.737816529742766
              ],
              [
                -73.94539673388434,
                40.73777422441198
              ],
              [
                -73.94542554603402,
                40.73776467482329
              ],
              [
                -73.94545576803195,
                40.737752351992526
              ],
              [
                -73.9454881134532,
                40.737737004966846
              ],
              [
                -73.94552863165563,
                40.73771579954569
              ],
              [
                -73.94558536491945,
                40.737684353343674
              ],
              [
                -73.9456245960258,
                40.737659274404535
              ],
              [
                -73.94563862186966,
                40.73764821473276
              ],
              [
                -73.94566737658243,
                40.73762229253089
              ],
              [
                -73.94568038333992,
                40.73761231275128
              ],
              [
                -73.94572336904317,
                40.73758486739551
              ],
              [
                -73.94577543679387,
                40.73755542754234
              ],
              [
                -73.94579426649551,
                40.73754851136677
              ],
              [
                -73.94580315890092,
                40.7375460304649
              ],
              [
                -73.94580767006968,
                40.73754533869334
              ],
              [
                -73.945811813659,
                40.737545178142476
              ],
              [
                -73.94581379024902,
                40.73754530589004
              ],
              [
                -73.94581568524721,
                40.73754555823889
              ],
              [
                -73.94581751868692,
                40.73754594608758
              ],
              [
                -73.94581929496616,
                40.73754647831712
              ],
              [
                -73.94582536619039,
                40.73754895805148
              ],
              [
                -73.9458390231052,
                40.73755573572596
              ],
              [
                -73.94585686433791,
                40.73756888271069
              ],
              [
                -73.9458872862647,
                40.73757485860486
              ],
              [
                -73.94588965372489,
                40.73757533733508
              ],
              [
                -73.94589209140617,
                40.737575590440294
              ],
              [
                -73.9458946374179,
                40.737575609226745
              ],
              [
                -73.94589726599044,
                40.737575394687546
              ],
              [
                -73.94590000091138,
                40.73757494532625
              ],
              [
                -73.94590575564354,
                40.73757336373207
              ],
              [
                -73.94591175927842,
                40.73757093524086
              ],
              [
                -73.9459182746333,
                40.737567578725745
              ],
              [
                -73.94593726125986,
                40.7375564400967
              ],
              [
                -73.9459447990511,
                40.73754540314072
              ],
              [
                -73.94594749665019,
                40.737533130415414
              ],
              [
                -73.94594669817852,
                40.73752454833167
              ],
              [
                -73.94594514077696,
                40.73751837023106
              ],
              [
                -73.94594410333026,
                40.737512515700466
              ],
              [
                -73.94594215413971,
                40.737507121439634
              ],
              [
                -73.94594095987212,
                40.737505076553994
              ],
              [
                -73.94593932901459,
                40.73750311271366
              ],
              [
                -73.94593727014934,
                40.73750123930424
              ],
              [
                -73.94593478459852,
                40.737499455153596
              ],
              [
                -73.94593191000172,
                40.737497787753625
              ],
              [
                -73.94592866792132,
                40.73749626341612
              ],
              [
                -73.94592145868832,
                40.73749379303687
              ],
              [
                -73.94589125600203,
                40.73748258573916
              ],
              [
                -73.94579981312464,
                40.73745213200043
              ],
              [
                -73.94560258882304,
                40.73738209722606
              ],
              [
                -73.94542629742466,
                40.737323246383205
              ],
              [
                -73.94484628751296,
                40.73712779455903
              ],
              [
                -73.94445052831142,
                40.73700182984252
              ],
              [
                -73.94395913765192,
                40.73683610126801
              ],
              [
                -73.94377941508291,
                40.73677548586041
              ],
              [
                -73.94359480342632,
                40.73671778977357
              ],
              [
                -73.94357675397869,
                40.73669825857153
              ],
              [
                -73.9434641066377,
                40.73665973288504
              ],
              [
                -73.9434315732658,
                40.73664748848283
              ],
              [
                -73.94338584284627,
                40.73663054567099
              ],
              [
                -73.94328861692705,
                40.7365960172027
              ],
              [
                -73.94324208181114,
                40.73657908214609
              ],
              [
                -73.94316721664656,
                40.73654898645435
              ],
              [
                -73.94303934385847,
                40.7364975946782
              ],
              [
                -73.94291468021945,
                40.73644409636495
              ],
              [
                -73.94286937946474,
                40.73642441601049
              ],
              [
                -73.94275630720325,
                40.73637084319732
              ],
              [
                -73.94273679211524,
                40.73636265686359
              ],
              [
                -73.94272008107485,
                40.73635710141665
              ],
              [
                -73.94271158202731,
                40.7363552686376
              ],
              [
                -73.94270176880802,
                40.73635420096762
              ],
              [
                -73.9426591860718,
                40.736353055000016
              ],
              [
                -73.94264985873986,
                40.73635169004054
              ],
              [
                -73.9426417150164,
                40.73634951601436
              ],
              [
                -73.94261730140052,
                40.73634000426395
              ],
              [
                -73.94256066309957,
                40.73631464468655
              ],
              [
                -73.94240797511542,
                40.736252675273384
              ],
              [
                -73.94229951025787,
                40.73621056815021
              ],
              [
                -73.94227949922428,
                40.73620169747976
              ],
              [
                -73.94224043442604,
                40.73618436068222
              ],
              [
                -73.94216645287644,
                40.73614665455617
              ],
              [
                -73.9421480767946,
                40.7361356684895
              ],
              [
                -73.94210483382442,
                40.73610473259584
              ],
              [
                -73.94208360671527,
                40.73609177329889
              ],
              [
                -73.94205818845143,
                40.73607907105276
              ],
              [
                -73.94203049613846,
                40.736066720467086
              ],
              [
                -73.94196641273331,
                40.736042284609944
              ],
              [
                -73.94190459194984,
                40.7360134911547
              ],
              [
                -73.94180143973271,
                40.73596257820131
              ],
              [
                -73.94162472636452,
                40.73584144233815
              ],
              [
                -73.94161655224315,
                40.735832848274335
              ],
              [
                -73.94162290648393,
                40.73582389971836
              ],
              [
                -73.94163596800144,
                40.73581907939849
              ],
              [
                -73.94162192243846,
                40.73579878365014
              ],
              [
                -73.94122480467027,
                40.73544289009852
              ],
              [
                -73.94086498592704,
                40.73507040909106
              ],
              [
                -73.94066499856187,
                40.73477888416133
              ],
              [
                -73.94045033575216,
                40.73443253627468
              ],
              [
                -73.94034365972064,
                40.73419527943397
              ],
              [
                -73.94006709327033,
                40.73376215298005
              ],
              [
                -73.9401043685312,
                40.73375295054129
              ],
              [
                -73.94007982777362,
                40.73368815610394
              ],
              [
                -73.94001768032926,
                40.73354043068552
              ],
              [
                -73.94009696170568,
                40.73351114258189
              ],
              [
                -73.94016494736086,
                40.73348602666649
              ],
              [
                -73.94010107332389,
                40.733363028983874
              ],
              [
                -73.94005620356053,
                40.733286376485076
              ],
              [
                -73.94003200991092,
                40.733245044305804
              ],
              [
                -73.93991049829648,
                40.733276328864335
              ],
              [
                -73.93986983153752,
                40.733161519792404
              ],
              [
                -73.93978436990243,
                40.732981995071476
              ],
              [
                -73.93974902700619,
                40.73290970931863
              ],
              [
                -73.93966762886603,
                40.73275272716036
              ],
              [
                -73.93954196713415,
                40.73250702929891
              ],
              [
                -73.93948488081135,
                40.73238090140343
              ],
              [
                -73.939471865783,
                40.73233511256981
              ],
              [
                -73.93946414214875,
                40.73230170873942
              ],
              [
                -73.93941220925986,
                40.73223586225097
              ],
              [
                -73.93949645683922,
                40.73219948191652
              ],
              [
                -73.9394877863569,
                40.7321661851416
              ],
              [
                -73.93945253455881,
                40.73208705671458
              ],
              [
                -73.93941918776585,
                40.73198156260662
              ],
              [
                -73.93940973698923,
                40.73193481336588
              ],
              [
                -73.93936863200211,
                40.731861038995305
              ],
              [
                -73.93921026840314,
                40.731559510027324
              ],
              [
                -73.93917411650186,
                40.73150623557754
              ],
              [
                -73.93911054574804,
                40.7313798065255
              ],
              [
                -73.93901362096581,
                40.7312984572627
              ],
              [
                -73.9387573597808,
                40.73107820170799
              ],
              [
                -73.93804478507161,
                40.73050996784606
              ],
              [
                -73.9377791569555,
                40.730400476320746
              ],
              [
                -73.93725405595639,
                40.730134604415746
              ],
              [
                -73.9364813861402,
                40.729686519841614
              ],
              [
                -73.93644563967132,
                40.729672145598656
              ],
              [
                -73.93636315943891,
                40.72964338330151
              ],
              [
                -73.93613310488465,
                40.72956336265126
              ],
              [
                -73.9359282069927,
                40.729481770738914
              ],
              [
                -73.93588751333363,
                40.72946511487208
              ],
              [
                -73.93453176375674,
                40.729104806534956
              ],
              [
                -73.93413016327798,
                40.729051906120176
              ],
              [
                -73.9320430867126,
                40.728809781103045
              ],
              [
                -73.93131509360056,
                40.72872944648483
              ],
              [
                -73.93130248857327,
                40.728704999171754
              ],
              [
                -73.93126345392132,
                40.728677203810555
              ],
              [
                -73.931192677967,
                40.72864298727414
              ],
              [
                -73.93099163669307,
                40.72861759858373
              ],
              [
                -73.9307087143373,
                40.72860666760421
              ],
              [
                -73.93043971043073,
                40.72856313728456
              ],
              [
                -73.92994415137062,
                40.72843310035095
              ],
              [
                -73.9293925737079,
                40.728308555994396
              ],
              [
                -73.92923485876162,
                40.72826016576104
              ],
              [
                -73.92910283484903,
                40.72819767868575
              ],
              [
                -73.92889885311759,
                40.72805659697399
              ],
              [
                -73.92881964284582,
                40.72800861362809
              ],
              [
                -73.92877556756127,
                40.727980688658825
              ],
              [
                -73.92865537507299,
                40.72789068006659
              ],
              [
                -73.92861070615697,
                40.727919775386134
              ],
              [
                -73.92853805222317,
                40.72788244993674
              ],
              [
                -73.92855676085436,
                40.72784482818559
              ],
              [
                -73.92828343345148,
                40.72751209083436
              ],
              [
                -73.92810972310453,
                40.72726138067442
              ],
              [
                -73.9278527894413,
                40.72699899076887
              ],
              [
                -73.92776196800949,
                40.72692986474525
              ],
              [
                -73.92721721119939,
                40.72646064526293
              ],
              [
                -73.9272343323438,
                40.726412055746835
              ],
              [
                -73.92713796957001,
                40.726302393303726
              ],
              [
                -73.92708774691289,
                40.72624843030606
              ],
              [
                -73.92692613110464,
                40.726071764740794
              ],
              [
                -73.92661994717979,
                40.7257393192869
              ],
              [
                -73.92657286034844,
                40.72568642855424
              ],
              [
                -73.92655663912387,
                40.725669065287285
              ],
              [
                -73.92622803892246,
                40.72542404253874
              ],
              [
                -73.92619991273062,
                40.72540408766012
              ],
              [
                -73.92568785128063,
                40.72501803418585
              ],
              [
                -73.92553807417822,
                40.7248982412345
              ],
              [
                -73.92552386998959,
                40.72488741719076
              ],
              [
                -73.92539503255472,
                40.724799840758564
              ],
              [
                -73.9253014448662,
                40.72473297979239
              ],
              [
                -73.92528222312195,
                40.72472087389908
              ],
              [
                -73.9251696390123,
                40.72465346910107
              ],
              [
                -73.92513844593599,
                40.72463498871582
              ],
              [
                -73.92513666041378,
                40.72463392476516
              ],
              [
                -73.92506506894894,
                40.724594858699724
              ],
              [
                -73.9249873243424,
                40.72455493335685
              ],
              [
                -73.92490316165177,
                40.72450657567148
              ],
              [
                -73.9246880312782,
                40.72440369551731
              ],
              [
                -73.92465327395544,
                40.72438764398436
              ],
              [
                -73.9245573704283,
                40.72435928708784
              ],
              [
                -73.92448063404514,
                40.7243393712912
              ],
              [
                -73.92435683726048,
                40.724307250533094
              ],
              [
                -73.92428434537076,
                40.72429101209339
              ],
              [
                -73.92419217991092,
                40.72427574219597
              ],
              [
                -73.92417644379576,
                40.724280702018405
              ],
              [
                -73.9241468540506,
                40.72428985059852
              ],
              [
                -73.92412708328479,
                40.72429260092605
              ],
              [
                -73.92406939675712,
                40.724289222582044
              ],
              [
                -73.92375148655562,
                40.72428431135611
              ],
              [
                -73.92373820362327,
                40.724285455813295
              ],
              [
                -73.92368662861382,
                40.72426877082093
              ],
              [
                -73.92364637525688,
                40.724266294725375
              ],
              [
                -73.92350121459954,
                40.724253843105394
              ],
              [
                -73.92346284721405,
                40.72424892867402
              ],
              [
                -73.9234449378547,
                40.72424874488516
              ],
              [
                -73.92325723229807,
                40.72425124021487
              ],
              [
                -73.92321428988637,
                40.724251815351316
              ],
              [
                -73.92320876027148,
                40.724263419258385
              ],
              [
                -73.92314779007273,
                40.72426376556558
              ],
              [
                -73.92296314285906,
                40.72426147219674
              ],
              [
                -73.92294077605564,
                40.72425893528164
              ],
              [
                -73.9229312116518,
                40.72425911885538
              ],
              [
                -73.92291606069413,
                40.72425954897349
              ],
              [
                -73.92289812823829,
                40.72425877170427
              ],
              [
                -73.92287922843035,
                40.72425648202282
              ],
              [
                -73.92286129939659,
                40.724254461366606
              ],
              [
                -73.92284336872018,
                40.724252719638415
              ],
              [
                -73.922802609117,
                40.724248002070716
              ],
              [
                -73.92278302197036,
                40.724246177972006
              ],
              [
                -73.92276253419685,
                40.72424493073072
              ],
              [
                -73.92274576207295,
                40.724245215833314
              ],
              [
                -73.92272663337583,
                40.72424605939395
              ],
              [
                -73.92270938968392,
                40.724243598411014
              ],
              [
                -73.9226909998882,
                40.72424015511668
              ],
              [
                -73.92267610260356,
                40.72423584135026
              ],
              [
                -73.92265886271561,
                40.72423124489859
              ],
              [
                -73.92264165971227,
                40.72422541664469
              ],
              [
                -73.92262535665819,
                40.72421884081855
              ],
              [
                -73.92261186961284,
                40.72421257312171
              ],
              [
                -73.92259792197365,
                40.72420625133749
              ],
              [
                -73.92259126129625,
                40.72420326605584
              ],
              [
                -73.92258302832471,
                40.724199577611934
              ],
              [
                -73.92256326829788,
                40.72419305322854
              ],
              [
                -73.92254930440336,
                40.72419087335948
              ],
              [
                -73.92253299705405,
                40.72418800773261
              ],
              [
                -73.92251575589731,
                40.72418338545954
              ],
              [
                -73.92249992279847,
                40.72418038579073
              ],
              [
                -73.92248221887137,
                40.72417777134443
              ],
              [
                -73.92246219468647,
                40.72417440766097
              ],
              [
                -73.92244077340763,
                40.72417230383939
              ],
              [
                -73.92241840412618,
                40.72417080330912
              ],
              [
                -73.92239321762763,
                40.724169660388156
              ],
              [
                -73.92237410231867,
                40.72416904423851
              ],
              [
                -73.9223563819875,
                40.72416936550097
              ],
              [
                -73.9223354175614,
                40.72417223282227
              ],
              [
                -73.92232001360414,
                40.724176067840446
              ],
              [
                -73.92230392251479,
                40.724180937708354
              ],
              [
                -73.92228620280896,
                40.724181286434835
              ],
              [
                -73.92226802574567,
                40.72417748253126
              ],
              [
                -73.92225172329604,
                40.7241709966143
              ],
              [
                -73.9222340595316,
                40.72416360127799
              ],
              [
                -73.92221868964282,
                40.72415824193368
              ],
              [
                -73.92219982687453,
                40.72415501401578
              ],
              [
                -73.92218258271899,
                40.72415355258579
              ],
              [
                -73.92216396575357,
                40.72415197329159
              ],
              [
                -73.92214626057454,
                40.72415044838691
              ],
              [
                -73.92212761016063,
                40.724146590364946
              ],
              [
                -73.92210621542866,
                40.72414232590632
              ],
              [
                -73.92208803816622,
                40.72413852197417
              ],
              [
                -73.92206986223367,
                40.724135465712024
              ],
              [
                -73.92205078462618,
                40.724132184350694
              ],
              [
                -73.922032171955,
                40.72412750679789
              ],
              [
                -73.92201165064529,
                40.72412466521541
              ],
              [
                -73.92199372029582,
                40.72412325706851
              ],
              [
                -73.92197438287592,
                40.72412071397658
              ],
              [
                -73.9219573905525,
                40.72411556910474
              ],
              [
                -73.92194647162722,
                40.72411037574445
              ],
              [
                -73.92193443525865,
                40.72410004676227
              ],
              [
                -73.92192440661712,
                40.724091837013695
              ],
              [
                -73.92191255372065,
                40.72408617310424
              ],
              [
                -73.92189462544043,
                40.72408300993273
              ],
              [
                -73.92187665823177,
                40.7240822500712
              ],
              [
                -73.92186107058602,
                40.72408160875534
              ],
              [
                -73.92184451115037,
                40.72408157121448
              ],
              [
                -73.92183379891677,
                40.72408227475871
              ],
              [
                -73.92181489298494,
                40.72408396483194
              ],
              [
                -73.92179483043961,
                40.724084445243165
              ],
              [
                -73.92177549283973,
                40.724081883857316
              ],
              [
                -73.9217547948118,
                40.72407860134587
              ],
              [
                -73.92173545902399,
                40.724074499713296
              ],
              [
                -73.92171774477397,
                40.72406985002709
              ],
              [
                -73.92170194744206,
                40.72406588632377
              ],
              [
                -73.92168565511973,
                40.72406134582182
              ],
              [
                -73.9216656348037,
                40.724055316316296
              ],
              [
                -73.9216493313895,
                40.72404916402663
              ],
              [
                -73.92163443618163,
                40.724042615822114
              ],
              [
                -73.9216214240031,
                40.724035610041696
              ],
              [
                -73.92160762415,
                40.724025254025655
              ],
              [
                -73.92159370420008,
                40.72401478920121
              ],
              [
                -73.92157791535459,
                40.724003630881576
              ],
              [
                -73.92156328531532,
                40.72399368875586
              ],
              [
                -73.92154582684326,
                40.72398428430482
              ],
              [
                -73.92153328597036,
                40.72397853863698
              ],
              [
                -73.92151817990622,
                40.72397155889213
              ],
              [
                -73.92151374300536,
                40.72396977288027
              ],
              [
                -73.92149988652693,
                40.72396657506434
              ],
              [
                -73.92148477864409,
                40.72396039676201
              ],
              [
                -73.92147176748499,
                40.72395330033325
              ],
              [
                -73.92146083643901,
                40.723947422404024
              ],
              [
                -73.92143386500696,
                40.72393496748495
              ],
              [
                -73.92141709680399,
                40.72393086805336
              ],
              [
                -73.92140450640761,
                40.72392792305024
              ],
              [
                -73.92139557202769,
                40.723916913289614
              ],
              [
                -73.92138101252571,
                40.72390732131977
              ],
              [
                -73.92136493282352,
                40.72390179066688
              ],
              [
                -73.92134862576677,
                40.72389934000362
              ],
              [
                -73.92133185603937,
                40.72389710362383
              ],
              [
                -73.92131183242266,
                40.72389393842725
              ],
              [
                -73.9212941527061,
                40.72388994590688
              ],
              [
                -73.92127832111741,
                40.72388633225274
              ],
              [
                -73.92126155304385,
                40.72388270103589
              ],
              [
                -73.92124200387714,
                40.7238784914546
              ],
              [
                -73.9212229316624,
                40.7238720485617
              ],
              [
                -73.92120104505189,
                40.72386283971518
              ],
              [
                -73.92117731354068,
                40.72385220679656
              ],
              [
                -73.9211554651799,
                40.723841016293214
              ],
              [
                -73.92113732965376,
                40.723832575265554
              ],
              [
                -73.9211210638419,
                40.723824693212535
              ],
              [
                -73.92110036025859,
                40.723815782182214
              ],
              [
                -73.92108547646293,
                40.72381121508596
              ],
              [
                -73.92106917060302,
                40.72380722347265
              ],
              [
                -73.92105009514302,
                40.72380225009375
              ],
              [
                -73.92103050094364,
                40.72379723732216
              ],
              [
                -73.92100630217577,
                40.72379128658882
              ],
              [
                -73.92098351167088,
                40.723786192041075
              ],
              [
                -73.92096392679434,
                40.7237824243237
              ],
              [
                -73.9209462455048,
                40.72377980027506
              ],
              [
                -73.92092478837887,
                40.72377836206553
              ],
              [
                -73.9209070691402,
                40.72377914313431
              ],
              [
                -73.92088913627248,
                40.72377921206466
              ],
              [
                -73.92087514616121,
                40.723779202451325
              ],
              [
                -73.9208588364714,
                40.72377845295127
              ],
              [
                -73.92083881199962,
                40.723776241736815
              ],
              [
                -73.92081641914338,
                40.723774524099206
              ],
              [
                -73.92078170579352,
                40.72377255523485
              ],
              [
                -73.9207593478999,
                40.723771251401395
              ],
              [
                -73.92073650541636,
                40.72376933575726
              ],
              [
                -73.92071601898118,
                40.7237676736912
              ],
              [
                -73.9206917819404,
                40.723764866170406
              ],
              [
                -73.92066663445847,
                40.72376069719709
              ],
              [
                -73.92064009136858,
                40.72375541052289
              ],
              [
                -73.92062681530118,
                40.72375194361864
              ],
              [
                -73.92061214329087,
                40.72374726955754
              ],
              [
                -73.92058657492441,
                40.72373893086992
              ],
              [
                -73.92056373915375,
                40.723731396682716
              ],
              [
                -73.92054419370669,
                40.72372488030908
              ],
              [
                -73.92052580777833,
                40.72371896146285
              ],
              [
                -73.9205083546329,
                40.72371321229228
              ],
              [
                -73.92048926848676,
                40.72370820966326
              ],
              [
                -73.92047253822959,
                40.72370377741185
              ],
              [
                -73.92045948636574,
                40.723699922986135
              ],
              [
                -73.92044061317388,
                40.7236954612727
              ],
              [
                -73.9204245680091,
                40.72369191118123
              ],
              [
                -73.9204096691617,
                40.723688577989414
              ],
              [
                -73.92039358633491,
                40.72368577486949
              ],
              [
                -73.92037847580916,
                40.72368268477619
              ],
              [
                -73.92037064235663,
                40.723681113821044
              ],
              [
                -73.9203969262659,
                40.72360543367629
              ],
              [
                -73.9204264602165,
                40.723554485573416
              ],
              [
                -73.92043873457798,
                40.72355424209531
              ],
              [
                -73.92045519421163,
                40.72354794055583
              ],
              [
                -73.92046642086521,
                40.72354345624806
              ],
              [
                -73.92048789087026,
                40.723534212541196
              ],
              [
                -73.9205074882824,
                40.723527202679875
              ],
              [
                -73.92051240133446,
                40.7235266565839
              ],
              [
                -73.92052520159467,
                40.72353207754052
              ],
              [
                -73.92054338097533,
                40.72353315287746
              ],
              [
                -73.92055874473738,
                40.72353270395299
              ],
              [
                -73.92056693765387,
                40.7235304846822
              ],
              [
                -73.92058373536926,
                40.72351948322597
              ],
              [
                -73.92059962790063,
                40.7235021511431
              ],
              [
                -73.92061613696757,
                40.72348445829548
              ],
              [
                -73.92062422272116,
                40.723474035997334
              ],
              [
                -73.92062640317927,
                40.72347102922187
              ],
              [
                -73.92063256961379,
                40.72346251540544
              ],
              [
                -73.9206432094334,
                40.723443035520205
              ],
              [
                -73.92064757931314,
                40.72343134030356
              ],
              [
                -73.92065131037478,
                40.723419294515516
              ],
              [
                -73.92065537238075,
                40.723406888270155
              ],
              [
                -73.92065949702021,
                40.72339273476338
              ],
              [
                -73.92066419891653,
                40.72338032946123
              ],
              [
                -73.92067277335117,
                40.723366692824634
              ],
              [
                -73.920682492851,
                40.723354316777794
              ],
              [
                -73.92069126659261,
                40.7233417606574
              ],
              [
                -73.92070138952494,
                40.723329908072834
              ],
              [
                -73.92071391583305,
                40.72331682364269
              ],
              [
                -73.92072305946152,
                40.72331188555291
              ],
              [
                -73.92073356094122,
                40.72330981277042
              ],
              [
                -73.92075174076672,
                40.72331009533812
              ],
              [
                -73.92077504504552,
                40.72331202955763
              ],
              [
                -73.92079877381357,
                40.72331437886081
              ],
              [
                -73.92081926712207,
                40.72331977760221
              ],
              [
                -73.92083556108093,
                40.72332385049365
              ],
              [
                -73.92085510748184,
                40.723330230117085
              ],
              [
                -73.92088071312541,
                40.72333729841547
              ],
              [
                -73.92089539860493,
                40.72333984033839
              ],
              [
                -73.92091050915185,
                40.72334286720644
              ],
              [
                -73.92092660336701,
                40.723346227958984
              ],
              [
                -73.92094175031816,
                40.72334901109636
              ],
              [
                -73.92098062255107,
                40.72335784516723
              ],
              [
                -73.92100388693066,
                40.723362993817034
              ],
              [
                -73.92102531663207,
                40.723367952401205
              ],
              [
                -73.92104814300374,
                40.723372578728274
              ],
              [
                -73.92106094777881,
                40.72337475748257
              ],
              [
                -73.92107559652341,
                40.72337783778839
              ],
              [
                -73.92110354670835,
                40.72338411557766
              ],
              [
                -73.92111823163248,
                40.72338714262933
              ],
              [
                -73.92114660949105,
                40.723392268451825
              ],
              [
                -73.92116223129145,
                40.72339442282233
              ],
              [
                -73.92117737878509,
                40.723396765166186
              ],
              [
                -73.92118449107831,
                40.723397961318014
              ],
              [
                -73.92119296392273,
                40.72339938657214
              ],
              [
                -73.92120999375886,
                40.72340183892499
              ],
              [
                -73.92122604087237,
                40.723404461815285
              ],
              [
                -73.92125212089489,
                40.72341059388083
              ],
              [
                -73.92127212628812,
                40.72341862170823
              ],
              [
                -73.921291206101,
                40.72342915275493
              ],
              [
                -73.92131254152825,
                40.72344368370191
              ],
              [
                -73.92133302612832,
                40.72345708828365
              ],
              [
                -73.92135392698684,
                40.72346893581693
              ],
              [
                -73.92137624855995,
                40.72348004535592
              ],
              [
                -73.92138901309303,
                40.72348631153233
              ],
              [
                -73.92141367858304,
                40.72349788219014
              ],
              [
                -73.92143787220604,
                40.72350739060113
              ],
              [
                -73.92145391613036,
                40.723513308876925
              ],
              [
                -73.92147090534914,
                40.72351905641525
              ],
              [
                -73.92148694902394,
                40.7235244437938
              ],
              [
                -73.92151256626012,
                40.72353225912889
              ],
              [
                -73.92152506816119,
                40.72353923827266
              ],
              [
                -73.9215336952299,
                40.72354136035078
              ],
              [
                -73.92155162134374,
                40.72354622581703
              ],
              [
                -73.92156812636091,
                40.72355216248377
              ],
              [
                -73.92159279369614,
                40.7235614631166
              ],
              [
                -73.92161793603518,
                40.723569990093516
              ],
              [
                -73.92164260329814,
                40.72357936375715
              ],
              [
                -73.92166491956547,
                40.72358588015437
              ],
              [
                -73.92168728296132,
                40.72359220777658
              ],
              [
                -73.92170730455835,
                40.723597030249195
              ],
              [
                -73.92172496836294,
                40.72360346389584
              ],
              [
                -73.92174780344956,
                40.723611654892395
              ],
              [
                -73.9217701178658,
                40.72361864067797
              ],
              [
                -73.92178292005258,
                40.72362252225985
              ],
              [
                -73.92179667032231,
                40.72362629575934
              ],
              [
                -73.92181088183105,
                40.723629572853554
              ],
              [
                -73.92182786155625,
                40.723634079126704
              ],
              [
                -73.92184672311069,
                40.72363876458811
              ],
              [
                -73.92186508809633,
                40.7236436577778
              ],
              [
                -73.9218844207231,
                40.72364960502941
              ],
              [
                -73.9219066311652,
                40.723655896822095
              ],
              [
                -73.92193051002853,
                40.723661460162425
              ],
              [
                -73.92194937192383,
                40.723665867345225
              ],
              [
                -73.92197822194707,
                40.72367222728488
              ],
              [
                -73.92199290694263,
                40.72367528119664
              ],
              [
                -73.92200805343336,
                40.72367855990511
              ],
              [
                -73.92202457287847,
                40.723682579004624
              ],
              [
                -73.922041564213,
                40.723686596745914
              ],
              [
                -73.9220580844321,
                40.72368995762841
              ],
              [
                -73.92207557393212,
                40.72369372541684
              ],
              [
                -73.92209209376516,
                40.72369741950495
              ],
              [
                -73.92211958242353,
                40.723703696734844
              ],
              [
                -73.92214330961275,
                40.72370755701878
              ],
              [
                -73.92216243635019,
                40.723708830924714
              ],
              [
                -73.92218107895404,
                40.72370758255221
              ],
              [
                -73.92219483821049,
                40.72370369266489
              ],
              [
                -73.92221447025179,
                40.72369689898132
              ],
              [
                -73.92223359397735,
                40.723690185028914
              ],
              [
                -73.9222504231605,
                40.7236822085499
              ],
              [
                -73.9222656940553,
                40.72366983729115
              ],
              [
                -73.922277656188,
                40.72365354584812
              ],
              [
                -73.92228718990164,
                40.72363938655336
              ],
              [
                -73.92229897219161,
                40.723624357133566
              ],
              [
                -73.92231088437028,
                40.723620492287566
              ],
              [
                -73.92233231268693,
                40.72361696824349
              ],
              [
                -73.9223579754138,
                40.7236155263934
              ],
              [
                -73.92237991082241,
                40.723613343734236
              ],
              [
                -73.92240274462834,
                40.723611548663364
              ],
              [
                -73.92242698747023,
                40.723610142696714
              ],
              [
                -73.9224451801977,
                40.72360982174166
              ],
              [
                -73.9224638234505,
                40.723609122811595
              ],
              [
                -73.92248576885873,
                40.72360837182307
              ],
              [
                -73.92250766649262,
                40.72360761309183
              ],
              [
                -73.92252815613811,
                40.723607077392465
              ],
              [
                -73.92254728383853,
                40.723607504219714
              ],
              [
                -73.9225654605078,
                40.72360982144664
              ],
              [
                -73.92259107006863,
                40.72361376532749
              ],
              [
                -73.92261902301563,
                40.72361787210574
              ],
              [
                -73.92264135287964,
                40.723622632809544
              ],
              [
                -73.92266139852461,
                40.723627292631356
              ],
              [
                -73.92268282968774,
                40.723631170527455
              ],
              [
                -73.92270331567315,
                40.72363379568971
              ],
              [
                -73.92272521021323,
                40.72363589978034
              ],
              [
                -73.92274946035276,
                40.72363788834606
              ],
              [
                -73.92277276322162,
                40.72364004765116
              ],
              [
                -73.92279696737484,
                40.723642008198986
              ],
              [
                -73.92282120441699,
                40.72364485213253
              ],
              [
                -73.92284168885726,
                40.72364881999643
              ],
              [
                -73.92286333147072,
                40.72365399298254
              ],
              [
                -73.92287826463517,
                40.723657073919505
              ],
              [
                -73.92289409915618,
                40.72366008392411
              ],
              [
                -73.92291177917356,
                40.72366308477563
              ],
              [
                -73.92293413428841,
                40.72366622495289
              ],
              [
                -73.92296069104158,
                40.72366915216279
              ],
              [
                -73.9229973006625,
                40.72366929241361
              ],
              [
                -73.92301921560954,
                40.72366480461795
              ],
              [
                -73.92304208793995,
                40.72366092122469
              ],
              [
                -73.92306398950277,
                40.723657531382166
              ],
              [
                -73.92307871521786,
                40.723656443250455
              ],
              [
                -73.9230952382452,
                40.723656264312574
              ],
              [
                -73.92311225761829,
                40.723657978086294
              ],
              [
                -73.92313196363915,
                40.723659306999245
              ],
              [
                -73.92316168646866,
                40.723657984614285
              ],
              [
                -73.92318045240826,
                40.723653629712985
              ],
              [
                -73.92319910696337,
                40.7236532539981
              ],
              [
                -73.92321566742926,
                40.723651158729034
              ],
              [
                -73.9232395559496,
                40.72364900468312
              ],
              [
                -73.92326263887983,
                40.72364770431428
              ],
              [
                -73.92328573216754,
                40.723646396074024
              ],
              [
                -73.9233145206555,
                40.72364433473647
              ],
              [
                -73.92333678844763,
                40.72364223386953
              ],
              [
                -73.92335988204199,
                40.7236406562316
              ],
              [
                -73.92338459834129,
                40.72363902371492
              ],
              [
                -73.9234055974963,
                40.72363774974021
              ],
              [
                -73.92342353042456,
                40.72363682569634
              ],
              [
                -73.92344009144804,
                40.72363615219518
              ],
              [
                -73.92345570360429,
                40.72363550603773
              ],
              [
                -73.92347086659312,
                40.72363578566775
              ],
              [
                -73.92348695034306,
                40.72363654402902
              ],
              [
                -73.92350394758385,
                40.72363682537626
              ],
              [
                -73.92352186735339,
                40.723636621003294
              ],
              [
                -73.92354496100383,
                40.72363614062866
              ],
              [
                -73.92356888400943,
                40.72363481545981
              ],
              [
                -73.92358727926026,
                40.72363339481287
              ],
              [
                -73.92360524914349,
                40.72363173247472
              ],
              [
                -73.92362087514346,
                40.72363014865662
              ],
              [
                -73.92363787416826,
                40.72362829569396
              ],
              [
                -73.92365677799924,
                40.72362636391405
              ],
              [
                -73.92367520963622,
                40.72362470069839
              ],
              [
                -73.92369585379056,
                40.72362345340739
              ],
              [
                -73.92371645160814,
                40.723621711542854
              ],
              [
                -73.92373442057428,
                40.723620075485655
              ],
              [
                -73.92374957358223,
                40.723618491336815
              ],
              [
                -73.92377758195005,
                40.723615213959974
              ],
              [
                -73.92380088889931,
                40.723614211661776
              ],
              [
                -73.92382000424585,
                40.723614089284176
              ],
              [
                -73.92383818294192,
                40.72361544353513
              ],
              [
                -73.92385820811734,
                40.72361727595292
              ],
              [
                -73.92387451846261,
                40.72361802452596
              ],
              [
                -73.92389038913083,
                40.72361910718938
              ],
              [
                -73.92390994245602,
                40.72361956087592
              ],
              [
                -73.92393046732963,
                40.723619214422996
              ],
              [
                -73.92394908612663,
                40.72361851573897
              ],
              [
                -73.92396961279084,
                40.72361717835933
              ],
              [
                -73.92399970283363,
                40.72361454125024
              ],
              [
                -73.92401788626974,
                40.72361270710617
              ],
              [
                -73.92402933335605,
                40.72361167934416
              ],
              [
                -73.92404589530673,
                40.723609601547004
              ],
              [
                -73.92406814812615,
                40.723608049514034
              ],
              [
                -73.92408962131452,
                40.723606802699145
              ],
              [
                -73.92411350850553,
                40.72360519695369
              ],
              [
                -73.92413821366316,
                40.72360384571278
              ],
              [
                -73.92415838368639,
                40.7236026238256
              ],
              [
                -73.92417725243759,
                40.72360169977814
              ],
              [
                -73.92419565801896,
                40.7236012441627
              ],
              [
                -73.92421456043476,
                40.7236013103821
              ],
              [
                -73.92423205370977,
                40.72360184407611
              ],
              [
                -73.92426185708126,
                40.7236030432406
              ],
              [
                -73.92428938620976,
                40.72360446672827
              ],
              [
                -73.92431405339124,
                40.72360475299891
              ],
              [
                -73.92433693392289,
                40.72360384027381
              ],
              [
                -73.9244229875485,
                40.723589975986165
              ],
              [
                -73.92448364585582,
                40.723572058423045
              ],
              [
                -73.92450004833422,
                40.72356417146463
              ],
              [
                -73.92451734212514,
                40.72355224249956
              ],
              [
                -73.92452702650351,
                40.72354091197556
              ],
              [
                -73.92453179550152,
                40.72353045975492
              ],
              [
                -73.92453545522265,
                40.72351896211001
              ],
              [
                -73.92454380115511,
                40.72350691922106
              ],
              [
                -73.92455402970798,
                40.7234943543789
              ],
              [
                -73.92456370306807,
                40.72348126766127
              ],
              [
                -73.92457248802818,
                40.72346906305941
              ],
              [
                -73.9245785228289,
                40.72345967445595
              ],
              [
                -73.92458724029376,
                40.723444452818875
              ],
              [
                -73.92459901536252,
                40.72342461262705
              ],
              [
                -73.92460918114135,
                40.72340620356694
              ],
              [
                -73.9246161933593,
                40.72339061191696
              ],
              [
                -73.92462353707093,
                40.72337610019366
              ],
              [
                -73.92462830986031,
                40.72336227197145
              ],
              [
                -73.92463355937976,
                40.723346687375184
              ],
              [
                -73.92463700588846,
                40.72333483007488
              ],
              [
                -73.92464135468852,
                40.72332011937843
              ],
              [
                -73.92464641233643,
                40.7233057681557
              ],
              [
                -73.92465148338148,
                40.72329105625626
              ],
              [
                -73.9246554295014,
                40.72327706549346
              ],
              [
                -73.92466299526907,
                40.72325368403935
              ],
              [
                -73.92466748617827,
                40.723238891011604
              ],
              [
                -73.92467172269511,
                40.72322843960922
              ],
              [
                -73.92467755167888,
                40.72321408939213
              ],
              [
                -73.92468409072853,
                40.72319901893891
              ],
              [
                -73.9246893707238,
                40.72318645084718
              ],
              [
                -73.92469465093318,
                40.72317563994379
              ],
              [
                -73.9246993502326,
                40.72316485445947
              ],
              [
                -73.92470409832374,
                40.72315297991511
              ],
              [
                -73.92470886951321,
                40.723141123478435
              ],
              [
                -73.92471429210676,
                40.72312960067843
              ],
              [
                -73.92472134948497,
                40.72311667306206
              ],
              [
                -73.92472716263893,
                40.72310481730585
              ],
              [
                -73.92473226484064,
                40.72309329429545
              ],
              [
                -73.9247372961058,
                40.723081798712705
              ],
              [
                -73.92474915338778,
                40.72306374154622
              ],
              [
                -73.92475919487978,
                40.723049232749545
              ],
              [
                -73.92476371392571,
                40.72304088834496
              ],
              [
                -73.92477010438043,
                40.723030824160205
              ],
              [
                -73.92478289780632,
                40.72301169660296
              ],
              [
                -73.92479598408008,
                40.72299472111718
              ],
              [
                -73.9248029190019,
                40.722985693774504
              ],
              [
                -73.92481162925223,
                40.722975595419214
              ],
              [
                -73.92482015200315,
                40.72296481359908
              ],
              [
                -73.9248329804374,
                40.72294605495297
              ],
              [
                -73.92484191126566,
                40.72292975204938
              ],
              [
                -73.92485077217577,
                40.722912766595364
              ],
              [
                -73.92485991737739,
                40.72289576105525
              ],
              [
                -73.92486728971544,
                40.722876270437794
              ],
              [
                -73.92487163418612,
                40.72286513341072
              ],
              [
                -73.92488162462871,
                40.722843509539025
              ],
              [
                -73.9248940865167,
                40.72282405792342
              ],
              [
                -73.924899470711,
                40.72281431807685
              ],
              [
                -73.9249102531087,
                40.72279412640305
              ],
              [
                -73.9249192447023,
                40.722776428034564
              ],
              [
                -73.92492550837535,
                40.722762952074994
              ],
              [
                -73.92493190430896,
                40.72274915119919
              ],
              [
                -73.92493801596369,
                40.7227349626482
              ],
              [
                -73.92494503875348,
                40.722720801997696
              ],
              [
                -73.9249541832126,
                40.72270382442627
              ],
              [
                -73.9249637677976,
                40.72268610013894
              ],
              [
                -73.92497349319298,
                40.72266769075783
              ],
              [
                -73.92497861842384,
                40.72265794152464
              ],
              [
                -73.92498530741834,
                40.72264609503688
              ],
              [
                -73.92499817485323,
                40.7226227175155
              ],
              [
                -73.92500707089407,
                40.72260653251461
              ],
              [
                -73.92501275326833,
                40.72259536067002
              ],
              [
                -73.92502435259331,
                40.72257412481997
              ],
              [
                -73.92503563051362,
                40.72255535642939
              ],
              [
                -73.92504484590496,
                40.722538703395976
              ],
              [
                -73.92505356249276,
                40.72252347233964
              ],
              [
                -73.92506115205212,
                40.72250967223333
              ],
              [
                -73.92506902906533,
                40.722494801314625
              ],
              [
                -73.92507796093828,
                40.72247746357939
              ],
              [
                -73.92508329808372,
                40.72246800195521
              ],
              [
                -73.925095962869,
                40.72244638918539
              ],
              [
                -73.92510477532092,
                40.722430086686074
              ],
              [
                -73.92511262787342,
                40.722415215748285
              ],
              [
                -73.92511995706556,
                40.72240246016566
              ],
              [
                -73.9251294655167,
                40.722388138618825
              ],
              [
                -73.9251349215352,
                40.72237751610865
              ],
              [
                -73.92514064055572,
                40.72236583215185
              ],
              [
                -73.92514099577905,
                40.722365003460126
              ],
              [
                -73.92514671628442,
                40.72235199537208
              ],
              [
                -73.92515207271921,
                40.722335339820816
              ],
              [
                -73.92515446906472,
                40.72232046365769
              ],
              [
                -73.92515200046466,
                40.72230536973
              ],
              [
                -73.92514667659884,
                40.72229224573666
              ],
              [
                -73.92514204061482,
                40.72227873520337
              ],
              [
                -73.92514109473282,
                40.72227690720596
              ],
              [
                -73.92513559141594,
                40.722266133161334
              ],
              [
                -73.92512758968573,
                40.722255835110886
              ],
              [
                -73.92512111551618,
                40.72224606006672
              ],
              [
                -73.92511001692077,
                40.72223131132635
              ],
              [
                -73.92510108236903,
                40.72222011237809
              ],
              [
                -73.92508955921214,
                40.722205004349675
              ],
              [
                -73.92508141547155,
                40.72219470620336
              ],
              [
                -73.9250703868396,
                40.7221811997159
              ],
              [
                -73.92505749462427,
                40.72216307262995
              ],
              [
                -73.92505017933362,
                40.722153298206976
              ],
              [
                -73.92504243690607,
                40.72214388301798
              ],
              [
                -73.9250293176932,
                40.722127511294495
              ],
              [
                -73.9250192017173,
                40.722113673358116
              ],
              [
                -73.92501055154403,
                40.7221012312038
              ],
              [
                -73.92500108646036,
                40.72208720421688
              ],
              [
                -73.92499190408849,
                40.72207456470288
              ],
              [
                -73.92498497838295,
                40.72206533834207
              ],
              [
                -73.92497619924846,
                40.72205147430099
              ],
              [
                -73.92496617943623,
                40.72203653694394
              ],
              [
                -73.92495786103832,
                40.72202377100489
              ],
              [
                -73.9249504772168,
                40.72201133084395
              ],
              [
                -73.92494360336619,
                40.72199888984234
              ],
              [
                -73.92493596992092,
                40.72198716971545
              ],
              [
                -73.92492641966595,
                40.72197508715041
              ],
              [
                -73.92491241131268,
                40.721960139314014
              ],
              [
                -73.92489722918822,
                40.721945909902935
              ],
              [
                -73.92488223617752,
                40.72193347298252
              ],
              [
                -73.92487063133576,
                40.72192684609091
              ],
              [
                -73.92485411517968,
                40.721921315638724
              ],
              [
                -73.92483760061812,
                40.72191398343429
              ],
              [
                -73.9248259823025,
                40.72190974345154
              ],
              [
                -73.92481109912397,
                40.7219041954639
              ],
              [
                -73.92479620262061,
                40.72189894532828
              ],
              [
                -73.92477989915965,
                40.72189363128162
              ],
              [
                -73.92475987801606,
                40.72188807926038
              ],
              [
                -73.9247352201419,
                40.72188128249596
              ],
              [
                -73.92471238383689,
                40.721874955154824
              ],
              [
                -73.92468728877478,
                40.72186631194857
              ],
              [
                -73.92466496577617,
                40.72185586946992
              ],
              [
                -73.92464655219551,
                40.72184260186034
              ],
              [
                -73.92463703551884,
                40.721832824294545
              ],
              [
                -73.92462496479494,
                40.721820353570045
              ],
              [
                -73.92461498333894,
                40.72181307067513
              ],
              [
                -73.92460131260096,
                40.72180283253549
              ],
              [
                -73.92458999565892,
                40.72179381955194
              ],
              [
                -73.92458022893142,
                40.721784366819605
              ],
              [
                -73.9245701837071,
                40.72177176289197
              ],
              [
                -73.92456384149197,
                40.72175952039967
              ],
              [
                -73.92456361197056,
                40.72174247305472
              ],
              [
                -73.92456327106687,
                40.72173006378445
              ],
              [
                -73.92456115360524,
                40.72171905823001
              ],
              [
                -73.92455738027354,
                40.721705935726746
              ],
              [
                -73.92454776466519,
                40.721689242916334
              ],
              [
                -73.92454009433322,
                40.72167946705471
              ],
              [
                -73.92452362614105,
                40.721662382763775
              ],
              [
                -73.92450798360728,
                40.72164710947417
              ],
              [
                -73.92449195162573,
                40.7216321681327
              ],
              [
                -73.92447486521583,
                40.721617559643306
              ],
              [
                -73.92445622878934,
                40.72160225690817
              ],
              [
                -73.92443950621615,
                40.721590116322275
              ],
              [
                -73.92442508959054,
                40.72158041727589
              ],
              [
                -73.92440882740668,
                40.7215692848291
              ],
              [
                -73.92439256537686,
                40.721558215537755
              ],
              [
                -73.92437677505144,
                40.72154846180701
              ],
              [
                -73.92436235781304,
                40.721538736450505
              ],
              [
                -73.92434971245676,
                40.72152280689516
              ],
              [
                -73.92434163922734,
                40.721513220730884
              ],
              [
                -73.92432831367466,
                40.72150042465057
              ],
              [
                -73.92431346186582,
                40.72148831107488
              ],
              [
                -73.92430045146966,
                40.72147992768763
              ],
              [
                -73.92428649522112,
                40.721470886636695
              ],
              [
                -73.92427034324635,
                40.72145756667725
              ],
              [
                -73.92425441686576,
                40.72144298588943
              ],
              [
                -73.92424364921001,
                40.72142949875855
              ],
              [
                -73.9242379261758,
                40.72141404198266
              ],
              [
                -73.92423466510306,
                40.72139877680219
              ],
              [
                -73.92423337602997,
                40.721387608615146
              ],
              [
                -73.92423468847616,
                40.721378216919426
              ],
              [
                -73.92423546427787,
                40.72136208040768
              ],
              [
                -73.92423442657227,
                40.721347699388986
              ],
              [
                -73.92422946953641,
                40.721336358078275
              ],
              [
                -73.92422205069707,
                40.72132391734362
              ],
              [
                -73.92421693970446,
                40.72131164917252
              ],
              [
                -73.92420992474553,
                40.7212978132045
              ],
              [
                -73.92420373531107,
                40.72128608359164
              ],
              [
                -73.92419728792142,
                40.721273292526234
              ],
              [
                -73.92418893863268,
                40.721257312004845
              ],
              [
                -73.9241803303898,
                40.721239214443216
              ],
              [
                -73.92417342397546,
                40.72122288406962
              ],
              [
                -73.92417073109073,
                40.72120832957476
              ],
              [
                -73.92416898638247,
                40.72119200260039
              ],
              [
                -73.92416781897656,
                40.7211774502828
              ],
              [
                -73.92416653051197,
                40.721165760584185
              ],
              [
                -73.92416658089165,
                40.721152640082835
              ],
              [
                -73.9241688362886,
                40.72113562134416
              ],
              [
                -73.92417303906538,
                40.72111471508735
              ],
              [
                -73.92417554976448,
                40.721102829706446
              ],
              [
                -73.92417833286737,
                40.72109130519019
              ],
              [
                -73.92418312641927,
                40.72107038943791
              ],
              [
                -73.92418927711957,
                40.72105285175258
              ],
              [
                -73.92419805836222,
                40.721043113670596
              ],
              [
                -73.92421220566214,
                40.721030056225594
              ],
              [
                -73.92421781243856,
                40.72102243275404
              ],
              [
                -73.92421469112381,
                40.72100859934849
              ],
              [
                -73.92420803239791,
                40.72099385344084
              ],
              [
                -73.9242057726273,
                40.72098355910399
              ],
              [
                -73.92421177483018,
                40.72097170349509
              ],
              [
                -73.92421698496739,
                40.72095949656316
              ],
              [
                -73.92422096542752,
                40.7209456666591
              ],
              [
                -73.92422252889321,
                40.72093432963891
              ],
              [
                -73.92422615071281,
                40.72092316635863
              ],
              [
                -73.92422897974355,
                40.7209130641739
              ],
              [
                -73.92422996407744,
                40.72090136725978
              ],
              [
                -73.92422716247574,
                40.720887344591866
              ],
              [
                -73.92422166415659,
                40.720872599448455
              ],
              [
                -73.9242158655834,
                40.72086088047961
              ],
              [
                -73.92420812627586,
                40.720849150851315
              ],
              [
                -73.92419991274558,
                40.720838141108175
              ],
              [
                -73.9241890266866,
                40.72082585941625
              ],
              [
                -73.92417901108873,
                40.72081663147324
              ],
              [
                -73.92416866729903,
                40.72080595420593
              ],
              [
                -73.92416013192478,
                40.72079760005099
              ],
              [
                -73.92415022503648,
                40.720787274373606
              ],
              [
                -73.92413761146997,
                40.720773956725324
              ],
              [
                -73.9241250333994,
                40.72076060341568
              ],
              [
                -73.92411410914978,
                40.72074959137723
              ],
              [
                -73.92410265533735,
                40.720736076305144
              ],
              [
                -73.924091167956,
                40.720721686719614
              ],
              [
                -73.92408331265625,
                40.72070782271415
              ],
              [
                -73.92407539879285,
                40.7206941491476
              ],
              [
                -73.92406956443814,
                40.72068295048575
              ],
              [
                -73.92406499736772,
                40.72067070092598
              ],
              [
                -73.92406161198602,
                40.72066005504235
              ],
              [
                -73.92405651848719,
                40.72064390594744
              ],
              [
                -73.92405097182021,
                40.72062970907884
              ],
              [
                -73.92404601516307,
                40.72061675715621
              ],
              [
                -73.92404162708031,
                40.72060395822531
              ],
              [
                -73.92403673088528,
                40.720590122470405
              ],
              [
                -73.924031442296,
                40.72057804314288
              ],
              [
                -73.92402572591716,
                40.72056651302094
              ],
              [
                -73.92402007071856,
                40.72055443345097
              ],
              [
                -73.92401604747515,
                40.72054377793087
              ],
              [
                -73.92401194196292,
                40.7205317003883
              ],
              [
                -73.92400734237232,
                40.72051786482784
              ],
              [
                -73.92400154887697,
                40.72050117398883
              ],
              [
                -73.92399594529478,
                40.72048554656797
              ],
              [
                -73.92399097924742,
                40.7204710004509
              ],
              [
                -73.92398590472051,
                40.7204571645758
              ],
              [
                -73.92398068897397,
                40.72044436509593
              ],
              [
                -73.92397522267471,
                40.720431934846985
              ],
              [
                -73.92397313388344,
                40.72042680077999
              ],
              [
                -73.92396330913726,
                40.720407081406805
              ],
              [
                -73.92395672000909,
                40.72039357891363
              ],
              [
                -73.92395053233457,
                40.720380805594544
              ],
              [
                -73.92394358934119,
                40.720366942181634
              ],
              [
                -73.9239382293463,
                40.720354178287494
              ],
              [
                -73.92393250672855,
                40.72033927098248
              ],
              [
                -73.9239266377356,
                40.72032683058095
              ],
              [
                -73.923919623192,
                40.72031296711905
              ],
              [
                -73.9239113432088,
                40.7202989326132
              ],
              [
                -73.9239055793793,
                40.720287923964584
              ],
              [
                -73.9238983156301,
                40.720274798627464
              ],
              [
                -73.9238918671838,
                40.72026306999774
              ],
              [
                -73.92388254458827,
                40.72024868282657
              ],
              [
                -73.92387461400548,
                40.72023835725846
              ],
              [
                -73.92386683865381,
                40.720228419952164
              ],
              [
                -73.92386045794106,
                40.720218122882436
              ],
              [
                -73.92385451880013,
                40.72020639391724
              ],
              [
                -73.9238492669484,
                40.720193243605706
              ],
              [
                -73.92384398143544,
                40.72017833658507
              ],
              [
                -73.92383927592586,
                40.72016379013063
              ],
              [
                -73.92383575405634,
                40.720148164078125
              ],
              [
                -73.92383686216742,
                40.72013186528521
              ],
              [
                -73.92383860518696,
                40.72011874590189
              ],
              [
                -73.92384064121788,
                40.7201074098684
              ],
              [
                -73.92384479990304,
                40.720094292585465
              ],
              [
                -73.92384777212683,
                40.72008260569965
              ],
              [
                -73.92385294982424,
                40.720067183585684
              ],
              [
                -73.92385634626123,
                40.720056919283266
              ],
              [
                -73.92386102477121,
                40.72004344232816
              ],
              [
                -73.92386637776903,
                40.72003068551444
              ],
              [
                -73.92387060543061,
                40.720018279762655
              ],
              [
                -73.92387549941546,
                40.720001982969805
              ],
              [
                -73.923878791784,
                40.719990486270156
              ],
              [
                -73.92388031880364,
                40.719979474231074
              ],
              [
                -73.92387763646855,
                40.719966000617326
              ],
              [
                -73.92387208726169,
                40.71995410991191
              ],
              [
                -73.92386387508843,
                40.7199414791543
              ],
              [
                -73.92385580808386,
                40.71992744428184
              ],
              [
                -73.92384565312442,
                40.719916433228114
              ],
              [
                -73.92383826447644,
                40.71990860249048
              ],
              [
                -73.92384119889218,
                40.719899743768046
              ],
              [
                -73.92385419630129,
                40.719887567800924
              ],
              [
                -73.9238708020666,
                40.71987716899796
              ],
              [
                -73.92388365280839,
                40.71986963174935
              ],
              [
                -73.92389817173341,
                40.71986185218554
              ],
              [
                -73.92391614720357,
                40.719854398398525
              ],
              [
                -73.92393107839393,
                40.719848473960454
              ],
              [
                -73.92394815558461,
                40.71983978657722
              ],
              [
                -73.92395208918511,
                40.719835367471376
              ],
              [
                -73.923955063197,
                40.71982208690042
              ],
              [
                -73.92395747204432,
                40.71980702246508
              ],
              [
                -73.92395877931119,
                40.71979194909337
              ],
              [
                -73.92395955005584,
                40.71977990123739
              ],
              [
                -73.92396065776029,
                40.719763926941695
              ],
              [
                -73.92396287796257,
                40.7197472681959
              ],
              [
                -73.9239648383132,
                40.71973025093829
              ],
              [
                -73.92396652595392,
                40.719714303327194
              ],
              [
                -73.92396737063301,
                40.719699400359495
              ],
              [
                -73.92396839140731,
                40.719686280664114
              ],
              [
                -73.92396920047416,
                40.71967209837338
              ],
              [
                -73.92397048345813,
                40.71965861917091
              ],
              [
                -73.92397146819152,
                40.719646209598054
              ],
              [
                -73.92397252474507,
                40.719632027638184
              ],
              [
                -73.92397261212277,
                40.71961854764605
              ],
              [
                -73.92397241310016,
                40.719605786490064
              ],
              [
                -73.92397250096981,
                40.71959090999415
              ],
              [
                -73.92397259069342,
                40.7195742153303
              ],
              [
                -73.92397167174758,
                40.71956003139574
              ],
              [
                -73.92397133400594,
                40.71954495643444
              ],
              [
                -73.92397159621802,
                40.7195339792415
              ],
              [
                -73.92397236754869,
                40.71952121989887
              ],
              [
                -73.92397393346643,
                40.71950774088291
              ],
              [
                -73.92397539769324,
                40.719490370820544
              ],
              [
                -73.92397661433331,
                40.719471928422536
              ],
              [
                -73.92397695379665,
                40.71945383741931
              ],
              [
                -73.92397560486823,
                40.719433250825865
              ],
              [
                -73.92397511878302,
                40.7194123315912
              ],
              [
                -73.92397464452561,
                40.719392818249844
              ],
              [
                -73.92397473793514,
                40.71937366379086
              ],
              [
                -73.92397537276364,
                40.719356644986924
              ],
              [
                -73.9239759929686,
                40.71934105735537
              ],
              [
                -73.92397555119685,
                40.71932472185262
              ],
              [
                -73.923975051392,
                40.71931640984391
              ],
              [
                -73.92397434741072,
                40.71930043435084
              ],
              [
                -73.92397336653804,
                40.719278001853894
              ],
              [
                -73.92397287854023,
                40.71925991901506
              ],
              [
                -73.92397268584806,
                40.719242188345305
              ],
              [
                -73.92397140459813,
                40.71922442998474
              ],
              [
                -73.92397016852695,
                40.719207408437214
              ],
              [
                -73.92396786815651,
                40.719191098511196
              ],
              [
                -73.92396381455407,
                40.71917548149091
              ],
              [
                -73.92395903593649,
                40.719162331495156
              ],
              [
                -73.9239551919984,
                40.71914956910362
              ],
              [
                -73.92395484199838,
                40.71913501514193
              ],
              [
                -73.92395341018386,
                40.71912455984858
              ],
              [
                -73.92395050174613,
                40.71911088790108
              ],
              [
                -73.92394943901726,
                40.71909883883306
              ],
              [
                -73.92394786656745,
                40.7190864012685
              ],
              [
                -73.92394603448032,
                40.71907399988581
              ],
              [
                -73.9239431281534,
                40.719059446245545
              ],
              [
                -73.92393867329348,
                40.719042756786976
              ],
              [
                -73.92393252266959,
                40.71902927216989
              ],
              [
                -73.92392427540345,
                40.7190171903774
              ],
              [
                -73.92391649802045,
                40.71900741490247
              ],
              [
                -73.9239090414079,
                40.718997287832515
              ],
              [
                -73.92390285173965,
                40.71898715339055
              ],
              [
                -73.92389434500978,
                40.71897400092754
              ],
              [
                -73.92388855796287,
                40.71896318106351
              ],
              [
                -73.92387958888955,
                40.718950190794786
              ],
              [
                -73.92387454980316,
                40.71893821917454
              ],
              [
                -73.92386706032462,
                40.71892526456116
              ],
              [
                -73.92386007813933,
                40.71891424672613
              ],
              [
                -73.92385334923793,
                40.718899312447036
              ],
              [
                -73.92384376878007,
                40.718882619091644
              ],
              [
                -73.9238351219118,
                40.718868421833704
              ],
              [
                -73.92382198001256,
                40.71885133913325
              ],
              [
                -73.92380674671496,
                40.71883146354666
              ],
              [
                -73.92379402849934,
                40.71881723611651
              ],
              [
                -73.9237820267752,
                40.71880761933185
              ],
              [
                -73.92376638207142,
                40.71879536242285
              ],
              [
                -73.9237549176178,
                40.71878149600148
              ],
              [
                -73.92374465735347,
                40.7187701253544
              ],
              [
                -73.92373690528115,
                40.71875963739183
              ],
              [
                -73.9237316480074,
                40.71875057522751
              ],
              [
                -73.92372611395872,
                40.718736902036895
              ],
              [
                -73.92371971500296,
                40.71872306526936
              ],
              [
                -73.92371016687846,
                40.718709722796845
              ],
              [
                -73.92370267524055,
                40.7186987046143
              ],
              [
                -73.92369561174809,
                40.71868682227627
              ],
              [
                -73.92368935133436,
                40.718675453772434
              ],
              [
                -73.92368412977082,
                40.71866675214741
              ],
              [
                -73.92367688864357,
                40.718655022307445
              ],
              [
                -73.92366925753883,
                40.71864294090446
              ],
              [
                -73.9236621693762,
                40.718630500683325
              ],
              [
                -73.92365661849291,
                40.71862020415014
              ],
              [
                -73.92365007474687,
                40.718609194968955
              ],
              [
                -73.92364276737888,
                40.718593025787484
              ],
              [
                -73.92363730133178,
                40.718580947325925
              ],
              [
                -73.92363171765355,
                40.718568533397296
              ],
              [
                -73.92362567165331,
                40.71855645354539
              ],
              [
                -73.92361923640782,
                40.71854223093331
              ],
              [
                -73.92361279629088,
                40.718523423944006
              ],
              [
                -73.92360642980813,
                40.71850204127718
              ],
              [
                -73.92360061655474,
                40.71848323470302
              ],
              [
                -73.92359359652137,
                40.71846440102604
              ],
              [
                -73.92358943705864,
                40.71844809002436
              ],
              [
                -73.92358577561399,
                40.71843104039191
              ],
              [
                -73.92358200882758,
                40.71841330734709
              ],
              [
                -73.92357920969752,
                40.71839733917027
              ],
              [
                -73.92357623361883,
                40.71838207298124
              ],
              [
                -73.92357214222355,
                40.718368238909086
              ],
              [
                -73.92356761226664,
                40.71835475584967
              ],
              [
                -73.92356368450358,
                40.71834338771438
              ],
              [
                -73.9235596968506,
                40.71833202121441
              ],
              [
                -73.92355479997053,
                40.718319591319954
              ],
              [
                -73.92354915568099,
                40.71830822321707
              ],
              [
                -73.92354379439121,
                40.718297566118395
              ],
              [
                -73.92353810279464,
                40.71828655749625
              ],
              [
                -73.92353040240155,
                40.7182721720399
              ],
              [
                -73.92352244013037,
                40.71825939701273
              ],
              [
                -73.92351380173469,
                40.718246964630865
              ],
              [
                -73.92350469094387,
                40.71823539603836
              ],
              [
                -73.92349359141218,
                40.718223123348146
              ],
              [
                -73.92348314172996,
                40.71821211273376
              ],
              [
                -73.92347694825794,
                40.71820464395342
              ],
              [
                -73.92346794587377,
                40.71819052550288
              ],
              [
                -73.92345716590792,
                40.718178767496546
              ],
              [
                -73.92344762772258,
                40.71816704482553
              ],
              [
                -73.92344225406629,
                40.71815701845247
              ],
              [
                -73.92344083317266,
                40.71814787807723
              ],
              [
                -73.92346464691408,
                40.71813625883179
              ],
              [
                -73.92346602654162,
                40.718130334322254
              ],
              [
                -73.9234261670815,
                40.7180973680073
              ],
              [
                -73.92323928226051,
                40.71792648806659
              ],
              [
                -73.92305769386333,
                40.717761573640864
              ],
              [
                -73.9227941474539,
                40.717534576143976
              ],
              [
                -73.92267615785575,
                40.71731326927821
              ],
              [
                -73.92259771919795,
                40.71716571262627
              ],
              [
                -73.9225192815478,
                40.71701815625348
              ],
              [
                -73.92248379312586,
                40.71695219661068
              ],
              [
                -73.92241488477603,
                40.716802540429306
              ],
              [
                -73.92235122384676,
                40.71667093290754
              ],
              [
                -73.92235844722471,
                40.71666784003853
              ],
              [
                -73.92234972562501,
                40.71664649139136
              ],
              [
                -73.92232773978448,
                40.71659264406108
              ],
              [
                -73.92153810999987,
                40.71679515599987
              ],
              [
                -73.91930947299991,
                40.71741710199994
              ],
              [
                -73.9180503519999,
                40.71776542099987
              ],
              [
                -73.91610398900004,
                40.7182981029999
              ],
              [
                -73.91516686599985,
                40.71855456599994
              ],
              [
                -73.914265253,
                40.71881117399987
              ],
              [
                -73.91339850999998,
                40.71905108699989
              ],
              [
                -73.9123211429999,
                40.71934838599991
              ],
              [
                -73.91152490699984,
                40.71956777999985
              ],
              [
                -73.91132095099978,
                40.71962422999994
              ],
              [
                -73.9118888929999,
                40.720262830999886
              ],
              [
                -73.91245967199984,
                40.72093053399987
              ],
              [
                -73.91298420399994,
                40.721600848999906
              ],
              [
                -73.91309781699982,
                40.72173734399994
              ],
              [
                -73.91309792299981,
                40.72173746899997
              ],
              [
                -73.91309801799984,
                40.7217375959999
              ],
              [
                -73.913199729,
                40.721880043999874
              ],
              [
                -73.91328886299983,
                40.72202764999987
              ],
              [
                -73.91331372599998,
                40.7220773499999
              ],
              [
                -73.91336471299988,
                40.72217927199989
              ],
              [
                -73.91336473699985,
                40.72217933499995
              ],
              [
                -73.9134268889998,
                40.722333936999874
              ],
              [
                -73.91355755899988,
                40.72246288799989
              ],
              [
                -73.91367234399998,
                40.7226011579999
              ],
              [
                -73.91376963799998,
                40.722746977999854
              ],
              [
                -73.91384828299984,
                40.72289841199989
              ],
              [
                -73.91316969799992,
                40.72308152599989
              ],
              [
                -73.91270773499983,
                40.7232845029999
              ],
              [
                -73.91257187699983,
                40.723239634999864
              ],
              [
                -73.91134506899984,
                40.72418216999996
              ],
              [
                -73.90889173799998,
                40.72606842599993
              ],
              [
                -73.90804680899987,
                40.72673388799993
              ],
              [
                -73.90740997399989,
                40.727208738999934
              ],
              [
                -73.9073001379999,
                40.72729436999991
              ],
              [
                -73.90716705199989,
                40.72738865399989
              ],
              [
                -73.9071225309999,
                40.72742472799994
              ],
              [
                -73.90934522600006,
                40.728803161999956
              ],
              [
                -73.90981313899988,
                40.72907325099989
              ],
              [
                -73.91017627699985,
                40.72927572499985
              ],
              [
                -73.9105504049999,
                40.72946621999993
              ],
              [
                -73.91096208699999,
                40.72964075299994
              ],
              [
                -73.91148836199982,
                40.72983170699989
              ],
              [
                -73.91174734199988,
                40.72991203299991
              ],
              [
                -73.91177014899978,
                40.7298527969999
              ],
              [
                -73.91189388199996,
                40.72956051399989
              ],
              [
                -73.91200974700003,
                40.72922886399991
              ],
              [
                -73.91205021299994,
                40.72850636299993
              ],
              [
                -73.91505218099998,
                40.72860118099991
              ],
              [
                -73.91507233799989,
                40.7279708199999
              ],
              [
                -73.91796696699996,
                40.72850077099989
              ],
              [
                -73.9177303909999,
                40.72987934399989
              ],
              [
                -73.91760690999989,
                40.73057198099986
              ],
              [
                -73.91752107599989,
                40.73100270499989
              ],
              [
                -73.91736640299979,
                40.731327577999906
              ],
              [
                -73.917342506,
                40.73138282599992
              ],
              [
                -73.917563071,
                40.731446009999885
              ],
              [
                -73.91881334600005,
                40.73191752199989
              ],
              [
                -73.91903825199986,
                40.73200596599987
              ],
              [
                -73.91902180399987,
                40.73208783799994
              ],
              [
                -73.91879292099999,
                40.731969448999905
              ],
              [
                -73.91835109099986,
                40.73315255899992
              ],
              [
                -73.91759250800001,
                40.73523944099985
              ],
              [
                -73.91755768599998,
                40.735291762999964
              ],
              [
                -73.91751347899988,
                40.73535098499991
              ],
              [
                -73.91689741899991,
                40.735444707999896
              ],
              [
                -73.91687214199993,
                40.735541359999914
              ],
              [
                -73.91651522799987,
                40.73675734599987
              ],
              [
                -73.91717737999994,
                40.736897870999876
              ],
              [
                -73.91709074600003,
                40.73714468199994
              ],
              [
                -73.91651190599994,
                40.738944757999896
              ],
              [
                -73.916301085,
                40.73906911799992
              ],
              [
                -73.91567469599988,
                40.74078492999992
              ],
              [
                -73.91538439399999,
                40.74072944299994
              ],
              [
                -73.91443483199988,
                40.74053105299989
              ],
              [
                -73.91382781999981,
                40.742413687999935
              ],
              [
                -73.9137938869998,
                40.742514491999955
              ],
              [
                -73.91289084199995,
                40.74248224999995
              ],
              [
                -73.9126012949999,
                40.74244224599993
              ],
              [
                -73.9119412979999,
                40.74238294899994
              ],
              [
                -73.91193129999984,
                40.74243121799988
              ],
              [
                -73.91190626499984,
                40.74255201199992
              ],
              [
                -73.91150870799994,
                40.74447070499985
              ],
              [
                -73.9112472229998,
                40.745783992999876
              ],
              [
                -73.91213076499986,
                40.74588664299989
              ],
              [
                -73.9124485379999,
                40.74592040199985
              ],
              [
                -73.91232274099995,
                40.74644708599989
              ],
              [
                -73.91212028799991,
                40.74729474899992
              ],
              [
                -73.91193027699991,
                40.747988209999924
              ],
              [
                -73.91190987499982,
                40.74810505399993
              ],
              [
                -73.91190983999999,
                40.74810522499994
              ],
              [
                -73.91190979099993,
                40.748105394999875
              ],
              [
                -73.91187504099992,
                40.74822079599992
              ],
              [
                -73.91182635699991,
                40.748333131999914
              ],
              [
                -73.9118262499998,
                40.74833339299994
              ],
              [
                -73.91182610699991,
                40.74833364499993
              ],
              [
                -73.91182081399998,
                40.74834287299994
              ],
              [
                -73.91176417899987,
                40.748441596999946
              ],
              [
                -73.91164202799983,
                40.74868457599992
              ],
              [
                -73.91015860699994,
                40.748501967999914
              ],
              [
                -73.90989961499994,
                40.749087274999944
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000890111567051,
        "objectid": 221,
        "shape_leng": 0.166218220846,
        "location_id": 221,
        "zone": "Stapleton",
        "borough": "Staten Island"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -74.07530550099992,
                40.63772995899997
              ],
              [
                -74.07601776799993,
                40.637748104999936
              ],
              [
                -74.0768399779999,
                40.6377502599999
              ],
              [
                -74.07698362099995,
                40.637795693999855
              ],
              [
                -74.0778990219999,
                40.63809465199992
              ],
              [
                -74.07813163399993,
                40.63817145399989
              ],
              [
                -74.07818273599995,
                40.63820992299987
              ],
              [
                -74.07823997999992,
                40.63824299499991
              ],
              [
                -74.07830238999996,
                40.63827010599988
              ],
              [
                -74.0783689039999,
                40.63829079499987
              ],
              [
                -74.07843838799988,
                40.63830470799997
              ],
              [
                -74.07850965999987,
                40.63831160899986
              ],
              [
                -74.07858150399997,
                40.638311379999905
              ],
              [
                -74.07865269699992,
                40.63830402499988
              ],
              [
                -74.078722026,
                40.63828966999992
              ],
              [
                -74.07878831099995,
                40.638268558999926
              ],
              [
                -74.08055168499997,
                40.63731999999989
              ],
              [
                -74.08177940499988,
                40.63662301399993
              ],
              [
                -74.08302091199991,
                40.63602507099989
              ],
              [
                -74.08373397299994,
                40.63573714399991
              ],
              [
                -74.08394671399999,
                40.63565410499991
              ],
              [
                -74.08443647599991,
                40.63540524799989
              ],
              [
                -74.08574928999991,
                40.63395555699988
              ],
              [
                -74.08520082199986,
                40.63376239799988
              ],
              [
                -74.08491321699994,
                40.63363992399992
              ],
              [
                -74.08344172599995,
                40.632275245999914
              ],
              [
                -74.08260312199998,
                40.631464807999926
              ],
              [
                -74.08201443099996,
                40.63089152599993
              ],
              [
                -74.08140615399998,
                40.63030123599993
              ],
              [
                -74.08173135299991,
                40.63001222599993
              ],
              [
                -74.08075821999995,
                40.629494042999944
              ],
              [
                -74.07972492199994,
                40.62895068799985
              ],
              [
                -74.08037988899997,
                40.62826190099988
              ],
              [
                -74.08045421199994,
                40.628184620999946
              ],
              [
                -74.0805160199999,
                40.62809988399987
              ],
              [
                -74.08056350699997,
                40.6280095199999
              ],
              [
                -74.08059556699997,
                40.62791570899993
              ],
              [
                -74.08061179799988,
                40.62782070099992
              ],
              [
                -74.08061902199996,
                40.62772455599993
              ],
              [
                -74.08064690599996,
                40.62762867599987
              ],
              [
                -74.08069514799999,
                40.62753689699988
              ],
              [
                -74.08076183699995,
                40.62745285899992
              ],
              [
                -74.08084371499997,
                40.62737949099987
              ],
              [
                -74.08099625499995,
                40.62733981399989
              ],
              [
                -74.0816522029999,
                40.62735102899992
              ],
              [
                -74.08183352399993,
                40.62740084399997
              ],
              [
                -74.08202428299997,
                40.62743512499987
              ],
              [
                -74.082220943,
                40.627452544999855
              ],
              [
                -74.08241960099991,
                40.62745240299989
              ],
              [
                -74.08261621699997,
                40.62743470099987
              ],
              [
                -74.08280689099995,
                40.62740014699993
              ],
              [
                -74.08298808899993,
                40.62735007299989
              ],
              [
                -74.08306246799997,
                40.62731250399988
              ],
              [
                -74.08326107499997,
                40.62719474299995
              ],
              [
                -74.08344837699995,
                40.62706443699992
              ],
              [
                -74.08362236699995,
                40.62692257799988
              ],
              [
                -74.08378128799997,
                40.62677039999993
              ],
              [
                -74.08392362399994,
                40.626609354999864
              ],
              [
                -74.08404820999988,
                40.62644102099992
              ],
              [
                -74.08415419899998,
                40.626267114999905
              ],
              [
                -74.08496211799991,
                40.62462523299994
              ],
              [
                -74.0852499159999,
                40.6228008709999
              ],
              [
                -74.08537203099992,
                40.62220532899995
              ],
              [
                -74.08548866899993,
                40.621653178999956
              ],
              [
                -74.0855941459999,
                40.62119087199993
              ],
              [
                -74.08560513399996,
                40.62114273099995
              ],
              [
                -74.0858126919999,
                40.6203755489999
              ],
              [
                -74.08589883599991,
                40.61990942899995
              ],
              [
                -74.08600629799997,
                40.61909415099995
              ],
              [
                -74.08604124499995,
                40.61882017799996
              ],
              [
                -74.08608006299993,
                40.61851530399988
              ],
              [
                -74.08626756599996,
                40.61783201099995
              ],
              [
                -74.0865715779999,
                40.61716938599994
              ],
              [
                -74.08698538899989,
                40.61653298199999
              ],
              [
                -74.087413392,
                40.61592932499989
              ],
              [
                -74.08769173799998,
                40.61553515299992
              ],
              [
                -74.08783375499992,
                40.61531764999988
              ],
              [
                -74.08785144099994,
                40.61524323599987
              ],
              [
                -74.08785147699993,
                40.61524307299993
              ],
              [
                -74.08785147599997,
                40.61524292099995
              ],
              [
                -74.08784843099988,
                40.615164874999884
              ],
              [
                -74.08784843099991,
                40.61516472999994
              ],
              [
                -74.08784838399993,
                40.61516456799993
              ],
              [
                -74.0878234189999,
                40.6150879349999
              ],
              [
                -74.08782337199992,
                40.61508779999986
              ],
              [
                -74.08782330099994,
                40.61508769299994
              ],
              [
                -74.08777810299993,
                40.61501762099993
              ],
              [
                -74.087778008,
                40.61501746799991
              ],
              [
                -74.08777786599995,
                40.61501732399993
              ],
              [
                -74.08771657499993,
                40.61495785499991
              ],
              [
                -74.087617514,
                40.614832668999945
              ],
              [
                -74.08753556499991,
                40.614699614999886
              ],
              [
                -74.08747218499994,
                40.61456080299993
              ],
              [
                -74.08742825199987,
                40.61441850099989
              ],
              [
                -74.08720982799996,
                40.61330697299989
              ],
              [
                -74.08692973699989,
                40.61349961799993
              ],
              [
                -74.08628169099997,
                40.61397561799987
              ],
              [
                -74.08617531899993,
                40.6140616069999
              ],
              [
                -74.08546427599995,
                40.61458243099993
              ],
              [
                -74.08476230899998,
                40.6151046959999
              ],
              [
                -74.08464853199992,
                40.61520502499992
              ],
              [
                -74.08404976899992,
                40.61562731399989
              ],
              [
                -74.08379332799987,
                40.615809838999944
              ],
              [
                -74.0833131969999,
                40.61616342599991
              ],
              [
                -74.08323479999994,
                40.616219476999966
              ],
              [
                -74.08274330499991,
                40.616581570999905
              ],
              [
                -74.08173992099994,
                40.61731079299991
              ],
              [
                -74.08110332499999,
                40.61778874499987
              ],
              [
                -74.08000094999994,
                40.61855295199995
              ],
              [
                -74.07938896299991,
                40.61888579499989
              ],
              [
                -74.07805406299991,
                40.61958534199987
              ],
              [
                -74.07769140399995,
                40.61977633599982
              ],
              [
                -74.07695604999999,
                40.620160559999896
              ],
              [
                -74.07626873999996,
                40.62051818499987
              ],
              [
                -74.0759248129999,
                40.62069712999992
              ],
              [
                -74.07558011999997,
                40.62087646899994
              ],
              [
                -74.07461777599994,
                40.621380100999914
              ],
              [
                -74.0744973829999,
                40.62144277399997
              ],
              [
                -74.07381368799996,
                40.621797074999975
              ],
              [
                -74.07253551699988,
                40.6224640219999
              ],
              [
                -74.0720638059999,
                40.6218592669999
              ],
              [
                -74.0717450489999,
                40.62145893399993
              ],
              [
                -74.07127025999996,
                40.620835842999924
              ],
              [
                -74.07116568799995,
                40.62069878599992
              ],
              [
                -74.07114283699993,
                40.620549106999874
              ],
              [
                -74.071134623,
                40.62035994799992
              ],
              [
                -74.07114727799993,
                40.62016924599988
              ],
              [
                -74.07115583099988,
                40.620121156999886
              ],
              [
                -74.07118111099999,
                40.61997902699991
              ],
              [
                -74.07123598299994,
                40.61979131499989
              ],
              [
                -74.07125950599996,
                40.61973410799993
              ],
              [
                -74.0713113039999,
                40.61960813799994
              ],
              [
                -74.0714060829999,
                40.61943137899991
              ],
              [
                -74.0715188819999,
                40.61926275799993
              ],
              [
                -74.07164799799989,
                40.61910377199992
              ],
              [
                -74.0718329299999,
                40.61890075099993
              ],
              [
                -74.07197737499989,
                40.61876338999991
              ],
              [
                -74.07203694399992,
                40.618706741999915
              ],
              [
                -74.072258849,
                40.61852327399987
              ],
              [
                -74.07249719099993,
                40.61835175599992
              ],
              [
                -74.07255048299993,
                40.61831840599997
              ],
              [
                -74.0727502249999,
                40.6181934099999
              ],
              [
                -74.07301605899991,
                40.61804928399993
              ],
              [
                -74.07329261599993,
                40.6179201889999
              ],
              [
                -74.0735311079999,
                40.61778201399987
              ],
              [
                -74.07375937799999,
                40.61763293099989
              ],
              [
                -74.07397615999992,
                40.6174736419999
              ],
              [
                -74.07418030999995,
                40.61730493399995
              ],
              [
                -74.07437079999995,
                40.617127704999916
              ],
              [
                -74.07454676799989,
                40.61694292999991
              ],
              [
                -74.075025699,
                40.616378739999924
              ],
              [
                -74.07527690499992,
                40.61597807099985
              ],
              [
                -74.0753823519999,
                40.61580988199988
              ],
              [
                -74.07620479299995,
                40.61441868999987
              ],
              [
                -74.07661374799993,
                40.613728729999885
              ],
              [
                -74.0768105549999,
                40.61339668199992
              ],
              [
                -74.07721874699989,
                40.612707982999936
              ],
              [
                -74.07755680899999,
                40.61213759399992
              ],
              [
                -74.07784324799991,
                40.611655287999895
              ],
              [
                -74.07841920399994,
                40.61070060799995
              ],
              [
                -74.07915097299991,
                40.60948761699993
              ],
              [
                -74.07939423999989,
                40.60908436499988
              ],
              [
                -74.07962112599992,
                40.6087629369999
              ],
              [
                -74.07985980899997,
                40.6084464359999
              ],
              [
                -74.08011002799992,
                40.608135238999886
              ],
              [
                -74.08069497999998,
                40.60738826099992
              ],
              [
                -74.08129735699994,
                40.606619015999904
              ],
              [
                -74.08136745599988,
                40.6065294999999
              ],
              [
                -74.08148289099995,
                40.60638202899987
              ],
              [
                -74.0815547009999,
                40.60629029699988
              ],
              [
                -74.07778089599998,
                40.60617100899986
              ],
              [
                -74.07656734699997,
                40.606121663999936
              ],
              [
                -74.07606892799994,
                40.606062951999874
              ],
              [
                -74.07551713899987,
                40.60599795099994
              ],
              [
                -74.07543239699991,
                40.6059879669999
              ],
              [
                -74.074789422,
                40.60587021299993
              ],
              [
                -74.07399965299999,
                40.60572556699987
              ],
              [
                -74.07358326699998,
                40.60562106799991
              ],
              [
                -74.07335207399997,
                40.60556304399994
              ],
              [
                -74.07298106899998,
                40.60546992999985
              ],
              [
                -74.07268510599995,
                40.60538461299992
              ],
              [
                -74.07242904799995,
                40.60530270299992
              ],
              [
                -74.07206682799995,
                40.605186347999954
              ],
              [
                -74.07172956199993,
                40.60505809099989
              ],
              [
                -74.06914030999994,
                40.604039717999896
              ],
              [
                -74.0689811829999,
                40.60398191899989
              ],
              [
                -74.06571018099996,
                40.602793822999935
              ],
              [
                -74.06550663199994,
                40.60271988099993
              ],
              [
                -74.06472638299991,
                40.60249786699991
              ],
              [
                -74.0642173399999,
                40.60235301499994
              ],
              [
                -74.06367318899999,
                40.60219816599994
              ],
              [
                -74.06350565699995,
                40.60215005499991
              ],
              [
                -74.06383152699986,
                40.602588967999964
              ],
              [
                -74.0651709129999,
                40.6042135079999
              ],
              [
                -74.06046429699992,
                40.606405824999854
              ],
              [
                -74.06042867199996,
                40.6063527299999
              ],
              [
                -74.05801067099993,
                40.60736879199994
              ],
              [
                -74.05778710399987,
                40.60744959099992
              ],
              [
                -74.0578901689999,
                40.607580753999905
              ],
              [
                -74.05685812390232,
                40.608056357772554
              ],
              [
                -74.0569659895738,
                40.60826954732188
              ],
              [
                -74.05699887669253,
                40.6083747730916
              ],
              [
                -74.05703263589402,
                40.60847428919628
              ],
              [
                -74.05713403543749,
                40.608635671820515
              ],
              [
                -74.05716383878973,
                40.608742783103665
              ],
              [
                -74.05737570322836,
                40.60896194823915
              ],
              [
                -74.05776219713343,
                40.609326052209965
              ],
              [
                -74.05832711414438,
                40.60976822898005
              ],
              [
                -74.05847736437649,
                40.60992729480929
              ],
              [
                -74.05848042971112,
                40.61001074045375
              ],
              [
                -74.05857881231054,
                40.61004864489009
              ],
              [
                -74.059085843087,
                40.610633677254455
              ],
              [
                -74.05912214071775,
                40.610738429046556
              ],
              [
                -74.05912333939817,
                40.610822289089086
              ],
              [
                -74.05904563473145,
                40.61088963849604
              ],
              [
                -74.05892744754186,
                40.6109383775444
              ],
              [
                -74.05890133421524,
                40.611002286486254
              ],
              [
                -74.05892163455023,
                40.61105605540064
              ],
              [
                -74.0590226904752,
                40.61115235902414
              ],
              [
                -74.05911472042038,
                40.611255451789184
              ],
              [
                -74.05915829525203,
                40.61133778916809
              ],
              [
                -74.05921042182443,
                40.61141718769858
              ],
              [
                -74.05927076216308,
                40.611493132537035
              ],
              [
                -74.0593389250291,
                40.61156513123265
              ],
              [
                -74.05941446845583,
                40.61163271692137
              ],
              [
                -74.05943076241836,
                40.61165437479536
              ],
              [
                -74.05945132766442,
                40.61167380745654
              ],
              [
                -74.05947565119531,
                40.61169053015486
              ],
              [
                -74.05950312625981,
                40.61170412574047
              ],
              [
                -74.05953306749007,
                40.611714255069394
              ],
              [
                -74.05990110428574,
                40.61174917307821
              ],
              [
                -74.06007754896766,
                40.6118301797601
              ],
              [
                -74.06027224809941,
                40.61181963993919
              ],
              [
                -74.06002048435357,
                40.61192857421075
              ],
              [
                -74.06003432329638,
                40.61194273457413
              ],
              [
                -74.05976930738177,
                40.61205664713937
              ],
              [
                -74.05999919711631,
                40.612783899618606
              ],
              [
                -74.060195940971,
                40.61280177462218
              ],
              [
                -74.06019772795607,
                40.61278637593195
              ],
              [
                -74.06001625116235,
                40.61276368789535
              ],
              [
                -74.06000447610441,
                40.61272658503737
              ],
              [
                -74.06004736821689,
                40.612721594064716
              ],
              [
                -74.0600417658949,
                40.612703632190346
              ],
              [
                -74.06001816918659,
                40.612627966152125
              ],
              [
                -74.06000161183916,
                40.61257487347526
              ],
              [
                -74.05999192119081,
                40.61254379805701
              ],
              [
                -74.0599613278625,
                40.61244569699513
              ],
              [
                -74.05995219194659,
                40.612416401614105
              ],
              [
                -74.05992120586593,
                40.61231704193241
              ],
              [
                -74.05991223842199,
                40.612288287746125
              ],
              [
                -74.05988716753706,
                40.61220789427623
              ],
              [
                -74.0598670814194,
                40.612143485996505
              ],
              [
                -74.05985444328618,
                40.61210295860812
              ],
              [
                -74.06000850450059,
                40.61203838063161
              ],
              [
                -74.06000457322973,
                40.61203319548432
              ],
              [
                -74.06007967319145,
                40.61199824137404
              ],
              [
                -74.06009027689035,
                40.612014567895784
              ],
              [
                -74.06023648814465,
                40.61194773576082
              ],
              [
                -74.0603368079201,
                40.61190188021971
              ],
              [
                -74.06050359224615,
                40.61210055412033
              ],
              [
                -74.06061238930377,
                40.612305676737726
              ],
              [
                -74.0606742614506,
                40.61257799811711
              ],
              [
                -74.06064940589565,
                40.61280785092204
              ],
              [
                -74.06073779377422,
                40.61304667709509
              ],
              [
                -74.06080853165754,
                40.613031733806146
              ],
              [
                -74.06103942014853,
                40.61359054933772
              ],
              [
                -74.06118151412508,
                40.61386088813253
              ],
              [
                -74.06130709550277,
                40.6141922507656
              ],
              [
                -74.06183263746597,
                40.61478731064752
              ],
              [
                -74.06288005786693,
                40.61574448068286
              ],
              [
                -74.06288724049963,
                40.61575188002389
              ],
              [
                -74.06355954496281,
                40.61644436693835
              ],
              [
                -74.06368609895254,
                40.616541671226486
              ],
              [
                -74.06376668678112,
                40.61660363084028
              ],
              [
                -74.06374596336498,
                40.61664357318375
              ],
              [
                -74.06373372626567,
                40.616685499520024
              ],
              [
                -74.06373027901742,
                40.61672836994454
              ],
              [
                -74.06373570714015,
                40.61677112113414
              ],
              [
                -74.0637612998448,
                40.61679874999733
              ],
              [
                -74.06379249208877,
                40.61682280646529
              ],
              [
                -74.06382843924409,
                40.61684263912981
              ],
              [
                -74.06386816792602,
                40.61685771095525
              ],
              [
                -74.06391060234927,
                40.61686761382144
              ],
              [
                -74.06419812869672,
                40.616900934590326
              ],
              [
                -74.06432745858045,
                40.61698850302211
              ],
              [
                -74.06390892240353,
                40.61732882514911
              ],
              [
                -74.06401211702085,
                40.61740222663609
              ],
              [
                -74.06443662005032,
                40.617062414602515
              ],
              [
                -74.06509908407213,
                40.61751095612544
              ],
              [
                -74.06425721751587,
                40.61817168758555
              ],
              [
                -74.06435705158657,
                40.618245944865606
              ],
              [
                -74.0649618928053,
                40.61777239957042
              ],
              [
                -74.06513041282273,
                40.61789638699403
              ],
              [
                -74.0651374231156,
                40.61789069960471
              ],
              [
                -74.06546549952745,
                40.61810707028078
              ],
              [
                -74.06569864744148,
                40.617956415586626
              ],
              [
                -74.06604991535914,
                40.61819541035272
              ],
              [
                -74.06641558715182,
                40.617872498165575
              ],
              [
                -74.06668207982919,
                40.61806749593851
              ],
              [
                -74.0660793790844,
                40.618620919774415
              ],
              [
                -74.06544586069478,
                40.61920262828925
              ],
              [
                -74.065572808412,
                40.619274543599936
              ],
              [
                -74.06619907954864,
                40.618722481288756
              ],
              [
                -74.06640988953299,
                40.61886446756711
              ],
              [
                -74.06637761277008,
                40.61889481228406
              ],
              [
                -74.06591003230022,
                40.61931580040389
              ],
              [
                -74.06596065271776,
                40.61935901559374
              ],
              [
                -74.06641466743935,
                40.61895799851777
              ],
              [
                -74.06642027508448,
                40.618962266761294
              ],
              [
                -74.06642251522541,
                40.61896055650501
              ],
              [
                -74.06649335296378,
                40.61889760159517
              ],
              [
                -74.06665653311698,
                40.619016488437495
              ],
              [
                -74.06669157924436,
                40.619085764247465
              ],
              [
                -74.06720519104827,
                40.619455405542915
              ],
              [
                -74.06837042857423,
                40.62029399424883
              ],
              [
                -74.0683926888309,
                40.62029389372511
              ],
              [
                -74.06875750296253,
                40.620556259976595
              ],
              [
                -74.06922460488548,
                40.62089218540343
              ],
              [
                -74.06921332498109,
                40.62090256474303
              ],
              [
                -74.06946265791278,
                40.62108749300955
              ],
              [
                -74.06975543088323,
                40.62120813987242
              ],
              [
                -74.06978373653142,
                40.62124717450165
              ],
              [
                -74.06977065208979,
                40.621268541866506
              ],
              [
                -74.06983970578148,
                40.621323207983316
              ],
              [
                -74.06985814982642,
                40.62131912900937
              ],
              [
                -74.06987800332105,
                40.621351098699265
              ],
              [
                -74.06989782466233,
                40.62135108664928
              ],
              [
                -74.06990602432006,
                40.62138839552679
              ],
              [
                -74.0698909248967,
                40.62139278876934
              ],
              [
                -74.06988154825585,
                40.62139551832082
              ],
              [
                -74.0698803921237,
                40.62140529098005
              ],
              [
                -74.06990374594828,
                40.621439036688244
              ],
              [
                -74.06992059737611,
                40.621433097762676
              ],
              [
                -74.0699445353289,
                40.62142124427365
              ],
              [
                -74.06995735676463,
                40.621417682515556
              ],
              [
                -74.06998300752403,
                40.62141766690337
              ],
              [
                -74.07002735061577,
                40.621454063850685
              ],
              [
                -74.07006471960437,
                40.62151001179222
              ],
              [
                -74.07007993001957,
                40.62156064112396
              ],
              [
                -74.07007995707166,
                40.621586406538135
              ],
              [
                -74.0700869743588,
                40.621607722648356
              ],
              [
                -74.07016754440262,
                40.621721391394786
              ],
              [
                -74.07022006282969,
                40.62177022212412
              ],
              [
                -74.07024340413841,
                40.62179241716038
              ],
              [
                -74.07028312709502,
                40.62186879624333
              ],
              [
                -74.07028197822274,
                40.62188478924676
              ],
              [
                -74.07033687509666,
                40.621978038063936
              ],
              [
                -74.07036956800744,
                40.62202155092269
              ],
              [
                -74.07039291413982,
                40.62204818912426
              ],
              [
                -74.07044775729958,
                40.62208902217423
              ],
              [
                -74.07049566264669,
                40.62218494141913
              ],
              [
                -74.07050617650607,
                40.622204480055515
              ],
              [
                -74.07055403064878,
                40.62225242475038
              ],
              [
                -74.07056805240434,
                40.62228084561617
              ],
              [
                -74.07056577813432,
                40.622309548718434
              ],
              [
                -74.07056693762618,
                40.62232882085634
              ],
              [
                -74.07058681402935,
                40.6223812237282
              ],
              [
                -74.07071994036913,
                40.62257748182329
              ],
              [
                -74.0707596091144,
                40.62260322116679
              ],
              [
                -74.07078879374497,
                40.6226124987033
              ],
              [
                -74.07082375839343,
                40.62262361498614
              ],
              [
                -74.07091706977349,
                40.62265642963041
              ],
              [
                -74.07098941522604,
                40.62270880005274
              ],
              [
                -74.07106179396493,
                40.62279315450549
              ],
              [
                -74.07114704488251,
                40.62292014490077
              ],
              [
                -74.07121598307037,
                40.62305780569777
              ],
              [
                -74.07123455549109,
                40.62309329756458
              ],
              [
                -74.07225500955778,
                40.624608750193936
              ],
              [
                -74.0725678017724,
                40.62622489763663
              ],
              [
                -74.07270543377275,
                40.626874148562784
              ],
              [
                -74.07278065950129,
                40.6269279631301
              ],
              [
                -74.07278382204916,
                40.62704855517877
              ],
              [
                -74.07271151257997,
                40.62710344565109
              ],
              [
                -74.0722922308001,
                40.62715170770657
              ],
              [
                -74.07239900616756,
                40.62763110052599
              ],
              [
                -74.07245527865845,
                40.627644995424916
              ],
              [
                -74.0724492976149,
                40.627679394180596
              ],
              [
                -74.07245835854137,
                40.62770919635306
              ],
              [
                -74.07250354307324,
                40.6277481482825
              ],
              [
                -74.07251259406816,
                40.627768778427004
              ],
              [
                -74.0729282255191,
                40.62995669634175
              ],
              [
                -74.07291177936548,
                40.62999637940654
              ],
              [
                -74.06813019247986,
                40.62853904609151
              ],
              [
                -74.06802173543946,
                40.62878758518542
              ],
              [
                -74.07278206817809,
                40.63024610461542
              ],
              [
                -74.0729847461233,
                40.6303089789814
              ],
              [
                -74.07305751299738,
                40.63069065404744
              ],
              [
                -74.0733282131641,
                40.63205844299033
              ],
              [
                -74.07283184410423,
                40.63366603282566
              ],
              [
                -74.07303948646671,
                40.6347982312994
              ],
              [
                -74.07335822045584,
                40.63653609416519
              ],
              [
                -74.07331604904051,
                40.63655876338426
              ],
              [
                -74.07323116008128,
                40.636566425452884
              ],
              [
                -74.07322343404338,
                40.63654567542717
              ],
              [
                -74.07290140645824,
                40.63657339543612
              ],
              [
                -74.0729014239926,
                40.63658944583374
              ],
              [
                -74.07282919715684,
                40.63659866245108
              ],
              [
                -74.07282315707188,
                40.636580323406946
              ],
              [
                -74.07250414244812,
                40.63661033285557
              ],
              [
                -74.07250717003198,
                40.63662638135514
              ],
              [
                -74.07241085491567,
                40.636628736184086
              ],
              [
                -74.07241084247497,
                40.63661727120748
              ],
              [
                -74.07209483807503,
                40.63664727762461
              ],
              [
                -74.07210395164891,
                40.63672523098917
              ],
              [
                -74.07239588123399,
                40.636697532168384
              ],
              [
                -74.07239586630878,
                40.63668377473271
              ],
              [
                -74.07250421469608,
                40.636676828580555
              ],
              [
                -74.07251025221059,
                40.63669287467942
              ],
              [
                -74.07323857883864,
                40.63663279738647
              ],
              [
                -74.07324020722542,
                40.636618919791154
              ],
              [
                -74.07337342888026,
                40.636610725639436
              ],
              [
                -74.07341562632823,
                40.63689395096554
              ],
              [
                -74.07052719831867,
                40.63713205870565
              ],
              [
                -74.07049412213834,
                40.637161886839586
              ],
              [
                -74.07053959705199,
                40.63747140462424
              ],
              [
                -74.07348737303558,
                40.63723352923621
              ],
              [
                -74.07353447593354,
                40.637641722632296
              ],
              [
                -74.07345636159803,
                40.63765394568574
              ],
              [
                -74.07348353801716,
                40.63778735793568
              ],
              [
                -74.07257275212604,
                40.637871909875905
              ],
              [
                -74.07258133886755,
                40.63794187429598
              ],
              [
                -74.07329742299997,
                40.6379035079999
              ],
              [
                -74.07373948699988,
                40.63786123399991
              ],
              [
                -74.07469451399999,
                40.6378051809999
              ],
              [
                -74.07486917499988,
                40.637792809999915
              ],
              [
                -74.07493115799997,
                40.63778150999984
              ],
              [
                -74.07496303999991,
                40.637773349999854
              ],
              [
                -74.07506052899998,
                40.637748399999936
              ],
              [
                -74.07513243599989,
                40.63772719999991
              ],
              [
                -74.07530550099992,
                40.63772995899997
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000130268343918,
        "objectid": 222,
        "shape_leng": 0.0483893527976,
        "location_id": 222,
        "zone": "Starrett City",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.87908517399995,
                40.65020211699988
              ],
              [
                -73.87992598699992,
                40.651334759999905
              ],
              [
                -73.88141998000002,
                40.65334717599994
              ],
              [
                -73.88172188099986,
                40.65375382699986
              ],
              [
                -73.88220052999989,
                40.65444157599989
              ],
              [
                -73.88221364899994,
                40.65451863799996
              ],
              [
                -73.88278243099995,
                40.654269767999935
              ],
              [
                -73.88465215899991,
                40.653451653999916
              ],
              [
                -73.88551222099994,
                40.65309253199993
              ],
              [
                -73.88621116599981,
                40.65280058399992
              ],
              [
                -73.88628975299989,
                40.6527689009999
              ],
              [
                -73.88637373700001,
                40.65273440399989
              ],
              [
                -73.88713099000002,
                40.65239391599988
              ],
              [
                -73.88794902799988,
                40.652040973999924
              ],
              [
                -73.88876540999999,
                40.65169059099987
              ],
              [
                -73.88959044299983,
                40.651337545999866
              ],
              [
                -73.89000589199988,
                40.651148696999925
              ],
              [
                -73.89004263999982,
                40.65111858599992
              ],
              [
                -73.89125147199995,
                40.65002559699993
              ],
              [
                -73.89121853499988,
                40.64998769899988
              ],
              [
                -73.89056792961276,
                40.64920863296716
              ],
              [
                -73.89053526315183,
                40.64920768228431
              ],
              [
                -73.8903473469377,
                40.649033605778065
              ],
              [
                -73.8901370101698,
                40.649170412116455
              ],
              [
                -73.89033529141231,
                40.64934776900196
              ],
              [
                -73.89032621217892,
                40.649353118740116
              ],
              [
                -73.89031665867228,
                40.64935796510478
              ],
              [
                -73.89031482009374,
                40.64935876045145
              ],
              [
                -73.89030667800193,
                40.64936228418456
              ],
              [
                -73.89029632189222,
                40.649366054417385
              ],
              [
                -73.89028564096586,
                40.64936925558052
              ],
              [
                -73.89027469001685,
                40.64937187248109
              ],
              [
                -73.89026352163408,
                40.64937389293951
              ],
              [
                -73.89025219412413,
                40.649375305619586
              ],
              [
                -73.89024076270594,
                40.64937610437529
              ],
              [
                -73.89022928523697,
                40.649376283398375
              ],
              [
                -73.89021782110412,
                40.649375843248144
              ],
              [
                -73.8901955237955,
                40.649369939202415
              ],
              [
                -73.89017347964771,
                40.64936350633941
              ],
              [
                -73.8901517102082,
                40.64935655004084
              ],
              [
                -73.89013023724263,
                40.64934907635859
              ],
              [
                -73.89010907921102,
                40.64934109519473
              ],
              [
                -73.89008826139944,
                40.6493326121019
              ],
              [
                -73.89006780073055,
                40.64932363564047
              ],
              [
                -73.89004771984301,
                40.64931417605163
              ],
              [
                -73.89002803675977,
                40.64930424139393
              ],
              [
                -73.89000877257922,
                40.64929384224216
              ],
              [
                -73.88998994466202,
                40.64928298832984
              ],
              [
                -73.88997157058684,
                40.64927169022826
              ],
              [
                -73.88995674148609,
                40.649262002448
              ],
              [
                -73.8899424709771,
                40.64925183791206
              ],
              [
                -73.88992878718162,
                40.649241216248306
              ],
              [
                -73.88991571316235,
                40.6492301570796
              ],
              [
                -73.88990327549784,
                40.64921868187561
              ],
              [
                -73.88989149505257,
                40.6492068097545
              ],
              [
                -73.88988587251853,
                40.649195406911794
              ],
              [
                -73.88987947865839,
                40.64918424222571
              ],
              [
                -73.88987233607851,
                40.649173345705364
              ],
              [
                -73.8898644592518,
                40.64916274383416
              ],
              [
                -73.88985587496023,
                40.64915246846757
              ],
              [
                -73.88984660009802,
                40.64914254542143
              ],
              [
                -73.88984125931684,
                40.649137415779734
              ],
              [
                -73.88981491121136,
                40.64911515449498
              ],
              [
                -73.8898031529832,
                40.64910689731392
              ],
              [
                -73.88978808315802,
                40.64910063356155
              ],
              [
                -73.88977341012759,
                40.64909383878753
              ],
              [
                -73.88975604953663,
                40.64908475886087
              ],
              [
                -73.88974538896761,
                40.64907871393338
              ],
              [
                -73.88973210500626,
                40.64907041909604
              ],
              [
                -73.88971934222157,
                40.64906166087086
              ],
              [
                -73.8897071329151,
                40.64905245855459
              ],
              [
                -73.88968889064772,
                40.64903001888988
              ],
              [
                -73.8896699637977,
                40.64900790792629
              ],
              [
                -73.88965036399765,
                40.64898614058453
              ],
              [
                -73.8896301004701,
                40.64896472659013
              ],
              [
                -73.88960918705214,
                40.64894367818533
              ],
              [
                -73.88958763207877,
                40.64892300961778
              ],
              [
                -73.8895752240009,
                40.64891166775867
              ],
              [
                -73.8895621837576,
                40.64890074143187
              ],
              [
                -73.88954853660488,
                40.64889025361259
              ],
              [
                -73.88953430780744,
                40.64888022258564
              ],
              [
                -73.88951952680836,
                40.64887066647199
              ],
              [
                -73.88950421689121,
                40.64886160439219
              ],
              [
                -73.88948840794062,
                40.648853053462545
              ],
              [
                -73.88947213182668,
                40.64884502745089
              ],
              [
                -73.88945541865267,
                40.648837544478845
              ],
              [
                -73.88943829765365,
                40.64883061563146
              ],
              [
                -73.88942080069698,
                40.648824256016596
              ],
              [
                -73.88939848882367,
                40.64881734026525
              ],
              [
                -73.88937648825117,
                40.64880985487727
              ],
              [
                -73.88935482843853,
                40.648801808927836
              ],
              [
                -73.88933353488541,
                40.64879321148817
              ],
              [
                -73.88931263132741,
                40.64878407430837
              ],
              [
                -73.88929214744313,
                40.648774406631034
              ],
              [
                -73.8892721038874,
                40.64876422120866
              ],
              [
                -73.8892625978012,
                40.64875799407158
              ],
              [
                -73.88925359963048,
                40.64875134190052
              ],
              [
                -73.88924677936008,
                40.64874565360671
              ],
              [
                -73.88922136766993,
                40.64871825649785
              ],
              [
                -73.88914998188316,
                40.6486412898287
              ],
              [
                -73.88911601123027,
                40.64859197658822
              ],
              [
                -73.88905476803649,
                40.648560793928375
              ],
              [
                -73.88896496571094,
                40.64847787836684
              ],
              [
                -73.88894519646605,
                40.64844674606871
              ],
              [
                -73.88893062680756,
                40.6484407527422
              ],
              [
                -73.88893058013272,
                40.64844064782439
              ],
              [
                -73.88891921009564,
                40.648414969814226
              ],
              [
                -73.88890716492976,
                40.64838777284081
              ],
              [
                -73.8888802948611,
                40.648361266837824
              ],
              [
                -73.8888130437334,
                40.648340702560574
              ],
              [
                -73.88880096709327,
                40.64832516585833
              ],
              [
                -73.88879178231977,
                40.648313348904225
              ],
              [
                -73.88879034256342,
                40.64827558694119
              ],
              [
                -73.88878963323985,
                40.64825697217248
              ],
              [
                -73.88878573742798,
                40.648229958132944
              ],
              [
                -73.88878187140558,
                40.64820315219243
              ],
              [
                -73.88877846134474,
                40.648199038752395
              ],
              [
                -73.88877842029953,
                40.648198987616404
              ],
              [
                -73.88876060719959,
                40.64817750731708
              ],
              [
                -73.88875167600808,
                40.64815358282301
              ],
              [
                -73.8887492038271,
                40.64814933090574
              ],
              [
                -73.88873825775943,
                40.6481305070422
              ],
              [
                -73.88871203763156,
                40.6481188778167
              ],
              [
                -73.88870351913667,
                40.64811509934625
              ],
              [
                -73.88868415993227,
                40.64808763107777
              ],
              [
                -73.88867219244943,
                40.64807065238251
              ],
              [
                -73.8886520498472,
                40.64804500806965
              ],
              [
                -73.88863638434091,
                40.648024492615654
              ],
              [
                -73.88863605358848,
                40.648024462140306
              ],
              [
                -73.88843465427944,
                40.64800741559469
              ],
              [
                -73.88822796911403,
                40.647906952830496
              ],
              [
                -73.88815394851169,
                40.64787097466722
              ],
              [
                -73.88812293345936,
                40.64788310555651
              ],
              [
                -73.88806961284439,
                40.64790396122253
              ],
              [
                -73.88802108784839,
                40.64790391398526
              ],
              [
                -73.88796538193867,
                40.64788069976456
              ],
              [
                -73.88796494058957,
                40.647880516561145
              ],
              [
                -73.88796131043708,
                40.647880457572825
              ],
              [
                -73.88784153520008,
                40.64787851392867
              ],
              [
                -73.8877708221422,
                40.647891998665145
              ],
              [
                -73.88771211156093,
                40.647874434636535
              ],
              [
                -73.88764072166725,
                40.64780239000726
              ],
              [
                -73.88762302100216,
                40.64769732819685
              ],
              [
                -73.88764613154626,
                40.64762343086531
              ],
              [
                -73.88764108201272,
                40.64758841181899
              ],
              [
                -73.88761566189314,
                40.64751835758165
              ],
              [
                -73.88757232935407,
                40.64746773755567
              ],
              [
                -73.88751623247252,
                40.64741516069423
              ],
              [
                -73.88753168688038,
                40.64733736554931
              ],
              [
                -73.88756755982104,
                40.64726737122376
              ],
              [
                -73.88764430451701,
                40.647191581472995
              ],
              [
                -73.8877261123322,
                40.64714302919988
              ],
              [
                -73.88771097487337,
                40.64703213555187
              ],
              [
                -73.88765531016614,
                40.64672278329653
              ],
              [
                -73.88767074460125,
                40.6466566599825
              ],
              [
                -73.88763764907316,
                40.646594379774484
              ],
              [
                -73.8876658922947,
                40.646504924553824
              ],
              [
                -73.88763668640426,
                40.646325451265014
              ],
              [
                -73.88753363787424,
                40.646179936669576
              ],
              [
                -73.88746471887154,
                40.64615847201259
              ],
              [
                -73.88746222111706,
                40.64612539928225
              ],
              [
                -73.88744693360182,
                40.646103987083414
              ],
              [
                -73.88738823781253,
                40.64607864136914
              ],
              [
                -73.88736029915223,
                40.64598718568284
              ],
              [
                -73.88740120112703,
                40.645963882990486
              ],
              [
                -73.88743444708422,
                40.64593668177379
              ],
              [
                -73.88735029808211,
                40.64586073461327
              ],
              [
                -73.88733382868713,
                40.645810281353455
              ],
              [
                -73.887329971946,
                40.64579846564517
              ],
              [
                -73.88732297933879,
                40.64579312569286
              ],
              [
                -73.88727896068909,
                40.64575951052067
              ],
              [
                -73.88727419528713,
                40.645706222414105
              ],
              [
                -73.88722035338283,
                40.64568164328973
              ],
              [
                -73.88722033765468,
                40.64568157961348
              ],
              [
                -73.88720113482746,
                40.64560217210968
              ],
              [
                -73.88679595217259,
                40.64523947390826
              ],
              [
                -73.88673134992423,
                40.64513193516143
              ],
              [
                -73.88624448913602,
                40.64474582414522
              ],
              [
                -73.88609107121216,
                40.64464296511781
              ],
              [
                -73.8860057122095,
                40.64457125810136
              ],
              [
                -73.8860058291109,
                40.64450289080447
              ],
              [
                -73.88574170709806,
                40.644181928842414
              ],
              [
                -73.88557762124441,
                40.64404095014615
              ],
              [
                -73.8848075041114,
                40.643946303680224
              ],
              [
                -73.88455063703384,
                40.644008630559426
              ],
              [
                -73.88432452601195,
                40.6441022811263
              ],
              [
                -73.88412906283655,
                40.644289838741685
              ],
              [
                -73.88399541004412,
                40.64436793646129
              ],
              [
                -73.88387223398924,
                40.64432869507526
              ],
              [
                -73.88369759560324,
                40.64435198816351
              ],
              [
                -73.88360497047857,
                40.6444614180559
              ],
              [
                -73.88341999203011,
                40.64452381564943
              ],
              [
                -73.88324554462208,
                40.64443758464192
              ],
              [
                -73.88315333117271,
                40.64431232105569
              ],
              [
                -73.88302004751286,
                40.644179192376996
              ],
              [
                -73.88270189929707,
                40.64404587513102
              ],
              [
                -73.88214832562561,
                40.64374372784058
              ],
              [
                -73.88213525034402,
                40.64370982073981
              ],
              [
                -73.88213525105553,
                40.643709791590524
              ],
              [
                -73.88213522252431,
                40.64370963291185
              ],
              [
                -73.88214875914151,
                40.6436635223294
              ],
              [
                -73.88217352293987,
                40.64361144449094
              ],
              [
                -73.88219157421167,
                40.6435879439901
              ],
              [
                -73.88220499245966,
                40.64357047630138
              ],
              [
                -73.88223870162093,
                40.64353207442054
              ],
              [
                -73.88224620098687,
                40.64348755670327
              ],
              [
                -73.88224776614891,
                40.64347827152954
              ],
              [
                -73.88224108165646,
                40.64345349240902
              ],
              [
                -73.88219967565512,
                40.643406472992375
              ],
              [
                -73.88215939005505,
                40.64335945303325
              ],
              [
                -73.88214855443789,
                40.64333873908882
              ],
              [
                -73.88213256756582,
                40.64330817655731
              ],
              [
                -73.8821012602465,
                40.64325689430421
              ],
              [
                -73.88208344797692,
                40.64324026625764
              ],
              [
                -73.88207103387181,
                40.643228675966135
              ],
              [
                -73.8820262545109,
                40.64318592232321
              ],
              [
                -73.8820093512287,
                40.643167074606794
              ],
              [
                -73.88198484754011,
                40.64313975470911
              ],
              [
                -73.88193782457262,
                40.6430978522867
              ],
              [
                -73.88190314844012,
                40.64304913020882
              ],
              [
                -73.88188874177447,
                40.64303296398694
              ],
              [
                -73.8818673371244,
                40.64300894705619
              ],
              [
                -73.8818248082783,
                40.64296277940352
              ],
              [
                -73.8818032883055,
                40.6429387080628
              ],
              [
                -73.88179347128593,
                40.642927727030965
              ],
              [
                -73.88177010894674,
                40.64291483648762
              ],
              [
                -73.88175314434555,
                40.642905476480976
              ],
              [
                -73.88175122795448,
                40.642902690688764
              ],
              [
                -73.88171847191641,
                40.64285504589389
              ],
              [
                -73.8816994556268,
                40.64282854744211
              ],
              [
                -73.88168745106204,
                40.64280362661371
              ],
              [
                -73.88168742214013,
                40.64280356510099
              ],
              [
                -73.88168381336682,
                40.64279607302995
              ],
              [
                -73.88164912697144,
                40.642753330124
              ],
              [
                -73.88163899855532,
                40.64273914008403
              ],
              [
                -73.88162899453944,
                40.64272512205548
              ],
              [
                -73.88162692441355,
                40.64271478140322
              ],
              [
                -73.88161787940886,
                40.642669590147044
              ],
              [
                -73.88161008072028,
                40.64264053953776
              ],
              [
                -73.88160453293122,
                40.64260807667849
              ],
              [
                -73.88159559157776,
                40.64257761513889
              ],
              [
                -73.88159450203496,
                40.64257389923911
              ],
              [
                -73.88159007500376,
                40.642540582299624
              ],
              [
                -73.88157720208616,
                40.642508563217895
              ],
              [
                -73.88157221057683,
                40.64249614773049
              ],
              [
                -73.88157210190602,
                40.64249603788729
              ],
              [
                -73.88156734043696,
                40.642491186215246
              ],
              [
                -73.88152855846558,
                40.64245168609164
              ],
              [
                -73.88148794066353,
                40.64242500552515
              ],
              [
                -73.88148782994816,
                40.64242493236856
              ],
              [
                -73.88147255731225,
                40.64241489992869
              ],
              [
                -73.8814379975936,
                40.642384105056124
              ],
              [
                -73.8814255353955,
                40.642372998298896
              ],
              [
                -73.88139306230616,
                40.64234733997912
              ],
              [
                -73.8813466967837,
                40.64233510746222
              ],
              [
                -73.88134663984796,
                40.642335092661
              ],
              [
                -73.88132805521832,
                40.64233018935662
              ],
              [
                -73.88127312402558,
                40.64232244560928
              ],
              [
                -73.88125228305606,
                40.64231870160611
              ],
              [
                -73.88120138217394,
                40.64230955943717
              ],
              [
                -73.88113301353455,
                40.64229154715031
              ],
              [
                -73.88113277724881,
                40.642291469005244
              ],
              [
                -73.88109379117451,
                40.64227698941454
              ],
              [
                -73.88106111804933,
                40.642256924739684
              ],
              [
                -73.88103666594084,
                40.6422419101646
              ],
              [
                -73.88101033957028,
                40.64222802228477
              ],
              [
                -73.88098961395188,
                40.64221709087568
              ],
              [
                -73.8809667366908,
                40.64221285888053
              ],
              [
                -73.8809666898604,
                40.64221285062311
              ],
              [
                -73.88092459816858,
                40.64220506504926
              ],
              [
                -73.88087946326571,
                40.64220396052931
              ],
              [
                -73.88085171526227,
                40.64220328177217
              ],
              [
                -73.880779962267,
                40.64219551868346
              ],
              [
                -73.8807332072026,
                40.64217765052438
              ],
              [
                -73.88073289819107,
                40.642177533269084
              ],
              [
                -73.88069934834354,
                40.64212795725912
              ],
              [
                -73.8806949085863,
                40.64212367296858
              ],
              [
                -73.88067807913612,
                40.6421074355601
              ],
              [
                -73.88066592641542,
                40.642107967417495
              ],
              [
                -73.88063995091653,
                40.642109104782726
              ],
              [
                -73.88060408824188,
                40.64209796225321
              ],
              [
                -73.88057274225638,
                40.6420688881259
              ],
              [
                -73.88055968307269,
                40.642065320612
              ],
              [
                -73.88054136113625,
                40.64206031425545
              ],
              [
                -73.88053544673278,
                40.642059488561706
              ],
              [
                -73.88052902991448,
                40.642058593659
              ],
              [
                -73.88050096563104,
                40.64207564638105
              ],
              [
                -73.88048425397946,
                40.64207495990482
              ],
              [
                -73.88045835809254,
                40.642073894311324
              ],
              [
                -73.88043420266764,
                40.64206795309517
              ],
              [
                -73.88039559128313,
                40.642058454950906
              ],
              [
                -73.88036553332915,
                40.64205672628525
              ],
              [
                -73.88036531935678,
                40.64205671567584
              ],
              [
                -73.88033607759517,
                40.64210537173018
              ],
              [
                -73.88033103498226,
                40.642109456189935
              ],
              [
                -73.88031923202074,
                40.642119020509234
              ],
              [
                -73.88029385334004,
                40.64212006895721
              ],
              [
                -73.880278860949,
                40.6421206862727
              ],
              [
                -73.8802534515156,
                40.64211117384709
              ],
              [
                -73.88022395348766,
                40.6421001304697
              ],
              [
                -73.88018952442866,
                40.64207892134025
              ],
              [
                -73.88018923070796,
                40.64207874043838
              ],
              [
                -73.88016682754629,
                40.642065049110194
              ],
              [
                -73.88011977666517,
                40.64204022946571
              ],
              [
                -73.88006714132425,
                40.64200344662675
              ],
              [
                -73.88006083136442,
                40.64199241800672
              ],
              [
                -73.88005931552973,
                40.641989772154886
              ],
              [
                -73.88004145754233,
                40.641943627889184
              ],
              [
                -73.88003922041295,
                40.64194020881064
              ],
              [
                -73.8800247427096,
                40.64188467390408
              ],
              [
                -73.88001923722489,
                40.641829147686664
              ],
              [
                -73.88001926794281,
                40.64181206416112
              ],
              [
                -73.88001933705613,
                40.641773627317164
              ],
              [
                -73.88002502222491,
                40.64172921640041
              ],
              [
                -73.88002504480959,
                40.64172900952614
              ],
              [
                -73.88002616426255,
                40.64171811329596
              ],
              [
                -73.88004867717538,
                40.6416703045278
              ],
              [
                -73.8800711977313,
                40.641618225292355
              ],
              [
                -73.88009708225974,
                40.641565293823795
              ],
              [
                -73.88013640918928,
                40.64152091814049
              ],
              [
                -73.88018806234929,
                40.641480826092675
              ],
              [
                -73.880190305821,
                40.641479973699255
              ],
              [
                -73.88019060023001,
                40.641479769788695
              ],
              [
                -73.88024419559154,
                40.64144244764599
              ],
              [
                -73.88029248068577,
                40.64140491573317
              ],
              [
                -73.88033286817526,
                40.64139385347232
              ],
              [
                -73.88035864367166,
                40.64140156734048
              ],
              [
                -73.88039003605539,
                40.64140416251383
              ],
              [
                -73.88040795144308,
                40.641418702188254
              ],
              [
                -73.88045727891345,
                40.641424732738955
              ],
              [
                -73.88046058804811,
                40.641455486047754
              ],
              [
                -73.88052673818183,
                40.641458970365235
              ],
              [
                -73.88054246213981,
                40.64144532038988
              ],
              [
                -73.88058841799418,
                40.641455618857755
              ],
              [
                -73.88060969919279,
                40.64146930726472
              ],
              [
                -73.88062758953829,
                40.64149751268649
              ],
              [
                -73.8806802514142,
                40.64151977475998
              ],
              [
                -73.88071493065692,
                40.641565936378235
              ],
              [
                -73.88072836247473,
                40.64157876188983
              ],
              [
                -73.88073623546329,
                40.64156595847575
              ],
              [
                -73.88072505409015,
                40.641548008590135
              ],
              [
                -73.88068925431797,
                40.641501847487504
              ],
              [
                -73.88067700349092,
                40.64145485608038
              ],
              [
                -73.88062774081736,
                40.64141295078826
              ],
              [
                -73.8805986798831,
                40.641360817389625
              ],
              [
                -73.88057522492257,
                40.6413086893027
              ],
              [
                -73.88055289293244,
                40.641255708148975
              ],
              [
                -73.88052495505906,
                40.64120272116638
              ],
              [
                -73.88049589429755,
                40.64115058723801
              ],
              [
                -73.88047801022172,
                40.64111896455321
              ],
              [
                -73.88047923238257,
                40.641062590614915
              ],
              [
                -73.88048718054162,
                40.64100793312853
              ],
              [
                -73.88048386922625,
                40.640978034547516
              ],
              [
                -73.88046932031959,
                40.64096179009468
              ],
              [
                -73.88040314772041,
                40.64097111663049
              ],
              [
                -73.88033583771679,
                40.64098812966351
              ],
              [
                -73.88028649284936,
                40.64099234978049
              ],
              [
                -73.88025849683294,
                40.64097182032763
              ],
              [
                -73.88025625666039,
                40.640970109035976
              ],
              [
                -73.88023051725692,
                40.640941896049924
              ],
              [
                -73.8802333890754,
                40.64093710169257
              ],
              [
                -73.88023614067771,
                40.64093250589892
              ],
              [
                -73.88022196606981,
                40.64092059694141
              ],
              [
                -73.88021374862782,
                40.64091369123029
              ],
              [
                -73.88020783247617,
                40.64091304058498
              ],
              [
                -73.88020590080936,
                40.64091282899449
              ],
              [
                -73.88019356367413,
                40.640914524933315
              ],
              [
                -73.88019161647189,
                40.6409137790776
              ],
              [
                -73.88019132263118,
                40.64091366786762
              ],
              [
                -73.88017455805036,
                40.64090208221642
              ],
              [
                -73.88016780438095,
                40.64089741403119
              ],
              [
                -73.88017124285744,
                40.640855563949096
              ],
              [
                -73.88017018150138,
                40.64082225095746
              ],
              [
                -73.8801694841882,
                40.64081768072128
              ],
              [
                -73.88016574828788,
                40.64079320442049
              ],
              [
                -73.88016916221731,
                40.64076502112667
              ],
              [
                -73.88016598988065,
                40.64075138116187
              ],
              [
                -73.88016359994798,
                40.64074109827981
              ],
              [
                -73.88016325038498,
                40.64074103257934
              ],
              [
                -73.88012660813733,
                40.64073422690357
              ],
              [
                -73.88012648911366,
                40.64073400664699
              ],
              [
                -73.88012484055837,
                40.640730944516264
              ],
              [
                -73.88011878577427,
                40.640719697706515
              ],
              [
                -73.8801087752126,
                40.640674417026105
              ],
              [
                -73.88010244648875,
                40.64066312653997
              ],
              [
                -73.88009536277097,
                40.64065048649355
              ],
              [
                -73.880058057092,
                40.64063241045606
              ],
              [
                -73.88004942970176,
                40.64062823049794
              ],
              [
                -73.87997993971362,
                40.640610221376214
              ],
              [
                -73.87995416764035,
                40.64059994323321
              ],
              [
                -73.87994069623016,
                40.64058450179664
              ],
              [
                -73.87992059896216,
                40.64056147126368
              ],
              [
                -73.87990718901253,
                40.640542804794514
              ],
              [
                -73.87988928035196,
                40.640517877102326
              ],
              [
                -73.87987558551809,
                40.64049169181636
              ],
              [
                -73.87987363622284,
                40.640487963944324
              ],
              [
                -73.87987403655931,
                40.64047863821355
              ],
              [
                -73.87987480747896,
                40.64046063271114
              ],
              [
                -73.8798825182456,
                40.640453177032086
              ],
              [
                -73.8798995176127,
                40.640436741972486
              ],
              [
                -73.87989951993711,
                40.64043667228295
              ],
              [
                -73.879900739591,
                40.64038122276629
              ],
              [
                -73.87989669687771,
                40.64037038815583
              ],
              [
                -73.8798940439766,
                40.64036327915716
              ],
              [
                -73.87986381281485,
                40.64033847696815
              ],
              [
                -73.87979770383454,
                40.64031278254277
              ],
              [
                -73.87975508233784,
                40.640302935214784
              ],
              [
                -73.87967008317165,
                40.64027707643711
              ],
              [
                -73.87958851835315,
                40.64024544396874
              ],
              [
                -73.879511062062,
                40.64020829927225
              ],
              [
                -73.87943835451547,
                40.64016594937152
              ],
              [
                -73.87937099667586,
                40.640118744314286
              ],
              [
                -73.87930954528376,
                40.64006707427806
              ],
              [
                -73.87925450825593,
                40.640011366345405
              ],
              [
                -73.87920634048739,
                40.639952080973
              ],
              [
                -73.87916544009131,
                40.639889708185976
              ],
              [
                -73.87913214510957,
                40.63982476352717
              ],
              [
                -73.87910673071889,
                40.63975778379561
              ],
              [
                -73.87905032924884,
                40.63972594302653
              ],
              [
                -73.87899836011283,
                40.63968999077931
              ],
              [
                -73.87895133765872,
                40.63965028288508
              ],
              [
                -73.8789097272729,
                40.63960721234485
              ],
              [
                -73.87887394077451,
                40.63956120544017
              ],
              [
                -73.87884433233984,
                40.6395127175136
              ],
              [
                -73.87883339341573,
                40.63950017827677
              ],
              [
                -73.8788200014755,
                40.63948910585881
              ],
              [
                -73.87880448993477,
                40.63947977592588
              ],
              [
                -73.87878724498002,
                40.63947242076229
              ],
              [
                -73.87876869595337,
                40.639467223486754
              ],
              [
                -73.8787493046632,
                40.63946431349415
              ],
              [
                -73.87872661892847,
                40.63945848573998
              ],
              [
                -73.87870548514465,
                40.639449916859405
              ],
              [
                -73.87868646954585,
                40.63943883643759
              ],
              [
                -73.87867008161409,
                40.639425541350796
              ],
              [
                -73.87865676042716,
                40.63941038781261
              ],
              [
                -73.878646862895,
                40.63939378182933
              ],
              [
                -73.87864065419832,
                40.63937616832225
              ],
              [
                -73.87863830068277,
                40.639358019206654
              ],
              [
                -73.87863986540178,
                40.639339820748006
              ],
              [
                -73.8786453064294,
                40.639322060533296
              ],
              [
                -73.87865447798258,
                40.639305214407216
              ],
              [
                -73.87865060839593,
                40.639253365770934
              ],
              [
                -73.87863962123944,
                40.639202112297454
              ],
              [
                -73.878621637735,
                40.639152019392206
              ],
              [
                -73.8785944125438,
                40.63909957107356
              ],
              [
                -73.87855952375331,
                40.639049825776475
              ],
              [
                -73.87851742532119,
                40.639003430741795
              ],
              [
                -73.87846866500722,
                40.638960989618525
              ],
              [
                -73.87841589406925,
                40.638912189353334
              ],
              [
                -73.87797341300002,
                40.639807117999894
              ],
              [
                -73.87752894599991,
                40.64071679999995
              ],
              [
                -73.87734248299998,
                40.641082680999894
              ],
              [
                -73.87709627099984,
                40.64160295499988
              ],
              [
                -73.87683349,
                40.64211858999989
              ],
              [
                -73.87654859999992,
                40.64263892799989
              ],
              [
                -73.87619932999985,
                40.64325717299991
              ],
              [
                -73.8761776709999,
                40.643289658999905
              ],
              [
                -73.87616087400002,
                40.64332052799995
              ],
              [
                -73.87596309099979,
                40.643644483999935
              ],
              [
                -73.87545861899982,
                40.644426930999906
              ],
              [
                -73.87530646399992,
                40.64465566899991
              ],
              [
                -73.874953578,
                40.64514158399989
              ],
              [
                -73.8745808109999,
                40.64561010599994
              ],
              [
                -73.87468212999995,
                40.645654467999904
              ],
              [
                -73.87610398000004,
                40.646214788999906
              ],
              [
                -73.87679877399998,
                40.64714224999995
              ],
              [
                -73.87798475599999,
                40.64872529299991
              ],
              [
                -73.87843582199996,
                40.64932734399993
              ],
              [
                -73.87908517399995,
                40.65020211699988
              ]
            ]
          ],
          [
            [
              [
                -73.8882853135604,
                40.64672241327609
              ],
              [
                -73.88826623150278,
                40.64673349902658
              ],
              [
                -73.88822696216229,
                40.6467454193833
              ],
              [
                -73.88818990838847,
                40.64677356952388
              ],
              [
                -73.888176393264,
                40.64680857770317
              ],
              [
                -73.88818640383646,
                40.6468572738842
              ],
              [
                -73.88819127336532,
                40.64686518361578
              ],
              [
                -73.88820429805554,
                40.64688633384464
              ],
              [
                -73.88820645247958,
                40.64690506964015
              ],
              [
                -73.88820871943206,
                40.64692477503195
              ],
              [
                -73.88819758375213,
                40.64695683920327
              ],
              [
                -73.88819745070396,
                40.646957221708945
              ],
              [
                -73.88818956342321,
                40.646979422784575
              ],
              [
                -73.88818386386546,
                40.64703408345031
              ],
              [
                -73.88818580754698,
                40.64708105679791
              ],
              [
                -73.88818602021149,
                40.64708618923855
              ],
              [
                -73.88818605874528,
                40.6470862946514
              ],
              [
                -73.88819109577638,
                40.64710017827497
              ],
              [
                -73.8881971830517,
                40.64711694993688
              ],
              [
                -73.88819787718664,
                40.64712194664504
              ],
              [
                -73.88820047292168,
                40.64714062691455
              ],
              [
                -73.88820050726648,
                40.647140869529046
              ],
              [
                -73.88826666475885,
                40.64714435056601
              ],
              [
                -73.88828631655434,
                40.64715299318355
              ],
              [
                -73.88830364435202,
                40.6471606153028
              ],
              [
                -73.88830681766217,
                40.64716972506336
              ],
              [
                -73.88830810804126,
                40.647173431208074
              ],
              [
                -73.88831285460468,
                40.647185051737445
              ],
              [
                -73.88832486176042,
                40.64721444688094
              ],
              [
                -73.88834372593608,
                40.64724413029598
              ],
              [
                -73.88834387533157,
                40.64724436213285
              ],
              [
                -73.88835250194765,
                40.647248906005395
              ],
              [
                -73.88837636776667,
                40.647261477219644
              ],
              [
                -73.88839030942329,
                40.64729295912508
              ],
              [
                -73.88839982882779,
                40.647314456733405
              ],
              [
                -73.88844461141393,
                40.647358063271305
              ],
              [
                -73.88846254740318,
                40.647363205195106
              ],
              [
                -73.88846286525167,
                40.647363190090786
              ],
              [
                -73.88851637568528,
                40.64736069152191
              ],
              [
                -73.8885500525067,
                40.64734193668585
              ],
              [
                -73.88857810718577,
                40.6473308595822
              ],
              [
                -73.88859389868011,
                40.64727620800545
              ],
              [
                -73.8886062843818,
                40.647245471330315
              ],
              [
                -73.88863572588176,
                40.647216407859695
              ],
              [
                -73.88864283734092,
                40.6471853476016
              ],
              [
                -73.88862610172086,
                40.64716275995369
              ],
              [
                -73.8886058808228,
                40.64715349145925
              ],
              [
                -73.88858810217314,
                40.64713621177189
              ],
              [
                -73.88858247895186,
                40.647110311452785
              ],
              [
                -73.88858655441034,
                40.64709366826725
              ],
              [
                -73.88861004869375,
                40.64708136093188
              ],
              [
                -73.88864137620679,
                40.647079553158
              ],
              [
                -73.88853549296564,
                40.646968149350386
              ],
              [
                -73.8884845320914,
                40.646957500218946
              ],
              [
                -73.88848084323095,
                40.64695711411304
              ],
              [
                -73.8884598643862,
                40.64695491533827
              ],
              [
                -73.88845557416435,
                40.64694726475595
              ],
              [
                -73.88845315577242,
                40.646942948989235
              ],
              [
                -73.88844980935991,
                40.64693269608774
              ],
              [
                -73.88844964285599,
                40.64693221117673
              ],
              [
                -73.88844491158294,
                40.64691844598866
              ],
              [
                -73.88844607613953,
                40.646911699198434
              ],
              [
                -73.88845010497215,
                40.646904903162415
              ],
              [
                -73.88845814272496,
                40.64690185219312
              ],
              [
                -73.88846787911639,
                40.64689973402525
              ],
              [
                -73.88829183121514,
                40.646718719035654
              ],
              [
                -73.8882853135604,
                40.64672241327609
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.00076436070058,
        "objectid": 223,
        "shape_leng": 0.166021925275,
        "location_id": 223,
        "zone": "Steinway",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.90649492923363,
                40.79018099034471
              ],
              [
                -73.90677991420698,
                40.79037510915573
              ],
              [
                -73.90985862925774,
                40.790945493781884
              ],
              [
                -73.91029611590572,
                40.790656756023886
              ],
              [
                -73.91113056873596,
                40.7901140760953
              ],
              [
                -73.91124959039128,
                40.78974393630263
              ],
              [
                -73.91161831292102,
                40.78958615443007
              ],
              [
                -73.91177445569417,
                40.78972251040847
              ],
              [
                -73.91207975024415,
                40.78952181498964
              ],
              [
                -73.91195110905574,
                40.789401797221004
              ],
              [
                -73.91206853578718,
                40.789338653812905
              ],
              [
                -73.91222952426433,
                40.78917927946362
              ],
              [
                -73.912599859115,
                40.78937632963394
              ],
              [
                -73.91423947964562,
                40.787856209563415
              ],
              [
                -73.91558710139756,
                40.78642963116581
              ],
              [
                -73.91526013936094,
                40.7861922166415
              ],
              [
                -73.91534351270901,
                40.786116348086814
              ],
              [
                -73.91541718757415,
                40.78606392941095
              ],
              [
                -73.91575172894541,
                40.7861075785781
              ],
              [
                -73.91683277016413,
                40.78519139248744
              ],
              [
                -73.91718200679615,
                40.78455206368194
              ],
              [
                -73.91717118646426,
                40.784193484371414
              ],
              [
                -73.9172451642751,
                40.784142001198354
              ],
              [
                -73.91773807440354,
                40.78407595141047
              ],
              [
                -73.91818862939184,
                40.78393296611367
              ],
              [
                -73.91847384225471,
                40.783754157697885
              ],
              [
                -73.91909034674549,
                40.783367646003924
              ],
              [
                -73.91996458796496,
                40.78263506876824
              ],
              [
                -73.91975969999989,
                40.782514177999886
              ],
              [
                -73.91796581399993,
                40.781256291999924
              ],
              [
                -73.91861823099997,
                40.78073286899994
              ],
              [
                -73.9190647659999,
                40.78035956399993
              ],
              [
                -73.91939411499995,
                40.780090356999935
              ],
              [
                -73.92012731099999,
                40.77948895299987
              ],
              [
                -73.92078680499985,
                40.778952266999944
              ],
              [
                -73.92139486599993,
                40.77845444399985
              ],
              [
                -73.92200155699993,
                40.77795459199994
              ],
              [
                -73.92264888599983,
                40.777422650999874
              ],
              [
                -73.92331041499993,
                40.776883294999934
              ],
              [
                -73.92375761299998,
                40.77654701199991
              ],
              [
                -73.92302571500001,
                40.77538430999991
              ],
              [
                -73.92320981399997,
                40.77523476899993
              ],
              [
                -73.923277837,
                40.775177622999884
              ],
              [
                -73.92334041500001,
                40.775128911999936
              ],
              [
                -73.92398142900001,
                40.77461511299994
              ],
              [
                -73.92279264499986,
                40.773962294999905
              ],
              [
                -73.92216697499995,
                40.77354687799987
              ],
              [
                -73.92146878499983,
                40.773051306999925
              ],
              [
                -73.92076339599988,
                40.77255254799996
              ],
              [
                -73.920076088,
                40.77206690899993
              ],
              [
                -73.919421785,
                40.77159517499992
              ],
              [
                -73.91880312499995,
                40.77114938899994
              ],
              [
                -73.91870527799998,
                40.77107888199988
              ],
              [
                -73.91815346699994,
                40.77070124299994
              ],
              [
                -73.91770217299991,
                40.7703931119999
              ],
              [
                -73.91811686499992,
                40.77002848599989
              ],
              [
                -73.91759120499997,
                40.769900870999926
              ],
              [
                -73.91682128799984,
                40.76979176099995
              ],
              [
                -73.915848706,
                40.76963357499988
              ],
              [
                -73.91487376500001,
                40.76947041699985
              ],
              [
                -73.91388850199984,
                40.769323929999906
              ],
              [
                -73.91291678,
                40.769161296999926
              ],
              [
                -73.91194026099988,
                40.76901015299997
              ],
              [
                -73.91094174999986,
                40.76885247299989
              ],
              [
                -73.909980063,
                40.768697234999884
              ],
              [
                -73.90900867399995,
                40.76854754699991
              ],
              [
                -73.90803079099996,
                40.768384733999916
              ],
              [
                -73.90708728999991,
                40.76823623199987
              ],
              [
                -73.90611305099988,
                40.7680677049999
              ],
              [
                -73.90577352999988,
                40.768028588999904
              ],
              [
                -73.9055484799998,
                40.76797657999988
              ],
              [
                -73.905317179,
                40.767898650999875
              ],
              [
                -73.90490866399992,
                40.76774556099988
              ],
              [
                -73.90446527099986,
                40.767583777999924
              ],
              [
                -73.90427336799988,
                40.767545104999925
              ],
              [
                -73.90418895299992,
                40.767530284999964
              ],
              [
                -73.90413637799985,
                40.7675203179999
              ],
              [
                -73.90325211599999,
                40.7675043239999
              ],
              [
                -73.90300528799979,
                40.76750966399989
              ],
              [
                -73.90275780099982,
                40.767503546999855
              ],
              [
                -73.90251104199997,
                40.76748593999987
              ],
              [
                -73.90226637199994,
                40.76745694399991
              ],
              [
                -73.90202516599992,
                40.7674167839999
              ],
              [
                -73.90065875099992,
                40.76721143399991
              ],
              [
                -73.89595958499986,
                40.766431033999865
              ],
              [
                -73.89432371999989,
                40.76614647099994
              ],
              [
                -73.89349605899986,
                40.765950929999924
              ],
              [
                -73.89325764999997,
                40.7662118549999
              ],
              [
                -73.89325108799987,
                40.76627144499989
              ],
              [
                -73.89299530699995,
                40.766248613999956
              ],
              [
                -73.89277464899999,
                40.76623007399993
              ],
              [
                -73.89232583099981,
                40.76621747199989
              ],
              [
                -73.89206947799994,
                40.76621963599993
              ],
              [
                -73.89183535799987,
                40.766227079999936
              ],
              [
                -73.89165463899995,
                40.76615653599992
              ],
              [
                -73.89144489699991,
                40.7660746539999
              ],
              [
                -73.89089043699997,
                40.765858197999925
              ],
              [
                -73.89072002699994,
                40.76588687999986
              ],
              [
                -73.89042403199987,
                40.7659620609999
              ],
              [
                -73.88994886800005,
                40.766104409999905
              ],
              [
                -73.88918520499988,
                40.766297690999934
              ],
              [
                -73.88877310599993,
                40.76651132699987
              ],
              [
                -73.88835471099995,
                40.76669771299989
              ],
              [
                -73.88777877399983,
                40.76682329599986
              ],
              [
                -73.88770661799985,
                40.766838597999936
              ],
              [
                -73.88772988199987,
                40.76691443399996
              ],
              [
                -73.88781814499994,
                40.767202077999876
              ],
              [
                -73.88817759199992,
                40.7684282569999
              ],
              [
                -73.88819008199995,
                40.768509872999935
              ],
              [
                -73.88822109399986,
                40.768592289999944
              ],
              [
                -73.88827131000001,
                40.76867187099992
              ],
              [
                -73.88833975499979,
                40.76874480599986
              ],
              [
                -73.88842375699991,
                40.76880764399987
              ],
              [
                -73.8885192579999,
                40.76885784099994
              ],
              [
                -73.88862144999993,
                40.7688940489999
              ],
              [
                -73.8887255129998,
                40.76891623899995
              ],
              [
                -73.88873647199979,
                40.76898593099992
              ],
              [
                -73.88904000199996,
                40.7709162379999
              ],
              [
                -73.88942374299985,
                40.773045928999906
              ],
              [
                -73.88945486901865,
                40.77353295106357
              ],
              [
                -73.8895235330152,
                40.77357518000311
              ],
              [
                -73.88960719597459,
                40.77362033808074
              ],
              [
                -73.88975035188997,
                40.77371739592509
              ],
              [
                -73.88985169979833,
                40.773753297181955
              ],
              [
                -73.88997672719441,
                40.773723610073965
              ],
              [
                -73.89008921683674,
                40.773626435762374
              ],
              [
                -73.89034195999996,
                40.77323760499998
              ],
              [
                -73.89183244902246,
                40.77488019994431
              ],
              [
                -73.89179251014149,
                40.77508872829882
              ],
              [
                -73.89180883475127,
                40.77521964078214
              ],
              [
                -73.89176577789306,
                40.77535718745252
              ],
              [
                -73.89166785623843,
                40.77548456204879
              ],
              [
                -73.89152987178771,
                40.77560563109897
              ],
              [
                -73.8914801662345,
                40.77589684219442
              ],
              [
                -73.89140128106186,
                40.77604486494019
              ],
              [
                -73.89141605901193,
                40.776338956117115
              ],
              [
                -73.89143415104094,
                40.776380523071715
              ],
              [
                -73.89153058411918,
                40.776476769142675
              ],
              [
                -73.89165215982452,
                40.776562323774826
              ],
              [
                -73.89177229676267,
                40.776593521076656
              ],
              [
                -73.89187053975695,
                40.7766558478132
              ],
              [
                -73.89204077552344,
                40.77696289867331
              ],
              [
                -73.89213229037036,
                40.77709093638003
              ],
              [
                -73.89225365703376,
                40.77720452353153
              ],
              [
                -73.89250271923207,
                40.777364694802536
              ],
              [
                -73.89251064542336,
                40.777392869272724
              ],
              [
                -73.89218330746341,
                40.77772181348737
              ],
              [
                -73.8911958970439,
                40.77856328788704
              ],
              [
                -73.89124137199043,
                40.77894869261773
              ],
              [
                -73.89152956328459,
                40.77925624255912
              ],
              [
                -73.89166859702843,
                40.77949253940986
              ],
              [
                -73.89181791840944,
                40.77958857362863
              ],
              [
                -73.89196204758021,
                40.779598191960815
              ],
              [
                -73.89202388223302,
                40.77958101635294
              ],
              [
                -73.89208769910182,
                40.77956872488386
              ],
              [
                -73.89215283246,
                40.77956144577587
              ],
              [
                -73.89221860284783,
                40.77955925496351
              ],
              [
                -73.89228432416179,
                40.77956217530088
              ],
              [
                -73.89231066526223,
                40.77956749381555
              ],
              [
                -73.89233779421183,
                40.779569479914876
              ],
              [
                -73.89236498644496,
                40.77956808055386
              ],
              [
                -73.89239151570528,
                40.779563333106566
              ],
              [
                -73.89241667344432,
                40.779555364369116
              ],
              [
                -73.8924397877444,
                40.77954438717206
              ],
              [
                -73.8924602412654,
                40.77953069469661
              ],
              [
                -73.8924774877329,
                40.77951465264367
              ],
              [
                -73.89253636793505,
                40.77955306588067
              ],
              [
                -73.89246024872139,
                40.779645629768694
              ],
              [
                -73.89244934075631,
                40.77965576881203
              ],
              [
                -73.89244079751904,
                40.779667143287206
              ],
              [
                -73.8924348559316,
                40.779679437757636
              ],
              [
                -73.89243168076788,
                40.77969231127317
              ],
              [
                -73.89243136008294,
                40.77970540682545
              ],
              [
                -73.89243390277157,
                40.77971836124853
              ],
              [
                -73.89243923832169,
                40.779730815289774
              ],
              [
                -73.89244721876898,
                40.77974242357326
              ],
              [
                -73.8924576228006,
                40.77975286417734
              ],
              [
                -73.89247016189191,
                40.77976184756215
              ],
              [
                -73.8924844883088,
                40.77976912459995
              ],
              [
                -73.89250020475025,
                40.779774493483075
              ],
              [
                -73.89248630305319,
                40.77977784483908
              ],
              [
                -73.89247324953169,
                40.77978278876459
              ],
              [
                -73.89246135771735,
                40.77978920651141
              ],
              [
                -73.89245091324,
                40.77979694393211
              ],
              [
                -73.89244216696588,
                40.77980581518165
              ],
              [
                -73.89243532897221,
                40.77981560718152
              ],
              [
                -73.89243056350172,
                40.779826084737785
              ],
              [
                -73.89242798501732,
                40.77983699618998
              ],
              [
                -73.89242759248253,
                40.779847082604086
              ],
              [
                -73.89242907097359,
                40.77985711064903
              ],
              [
                -73.89242035724443,
                40.77985771897532
              ],
              [
                -73.89241182994762,
                40.77985921141322
              ],
              [
                -73.8924036436042,
                40.779861560918555
              ],
              [
                -73.89239594655692,
                40.779864724916564
              ],
              [
                -73.89238844181615,
                40.7798689266614
              ],
              [
                -73.89238180475073,
                40.77987390108734
              ],
              [
                -73.89237617181554,
                40.7798795459224
              ],
              [
                -73.89237165882133,
                40.779885745111606
              ],
              [
                -73.89236849146907,
                40.77989203636961
              ],
              [
                -73.89236647919967,
                40.77989859662982
              ],
              [
                -73.89236565941067,
                40.77990530397231
              ],
              [
                -73.8923442617198,
                40.77990945654161
              ],
              [
                -73.89232199513468,
                40.77991692611601
              ],
              [
                -73.8923017631205,
                40.77992720995559
              ],
              [
                -73.89228417521181,
                40.77993999823769
              ],
              [
                -73.89226976128364,
                40.77995490568767
              ],
              [
                -73.8922589555896,
                40.77997148318574
              ],
              [
                -73.89225208367779,
                40.77998923129827
              ],
              [
                -73.8922501476234,
                40.77999868561964
              ],
              [
                -73.89224933470005,
                40.78000823382422
              ],
              [
                -73.89223897344755,
                40.780010362685786
              ],
              [
                -73.89222901793927,
                40.78001341150787
              ],
              [
                -73.89221960897954,
                40.780017337169944
              ],
              [
                -73.89220912410164,
                40.78002319297629
              ],
              [
                -73.89219985583651,
                40.78003013392448
              ],
              [
                -73.89219199680349,
                40.78003801576341
              ],
              [
                -73.8921857103347,
                40.780046674687625
              ],
              [
                -73.89216047835552,
                40.78005431745817
              ],
              [
                -73.89213712825348,
                40.78006486449858
              ],
              [
                -73.8921162341394,
                40.7800780564883
              ],
              [
                -73.89209830973958,
                40.780093569075184
              ],
              [
                -73.89208379576472,
                40.78011102085016
              ],
              [
                -73.89207304907376,
                40.7801299827249
              ],
              [
                -73.89206633390033,
                40.78014998848169
              ],
              [
                -73.89205555640483,
                40.78015169458996
              ],
              [
                -73.8920452788555,
                40.780154693459245
              ],
              [
                -73.89203576772643,
                40.78015890733587
              ],
              [
                -73.89202726961882,
                40.78016422696358
              ],
              [
                -73.8920200048692,
                40.78017051441662
              ],
              [
                -73.89201416183664,
                40.78017760667585
              ],
              [
                -73.89200989201821,
                40.780185319855185
              ],
              [
                -73.89200730612095,
                40.780193453969275
              ],
              [
                -73.89200647119179,
                40.78020179811912
              ],
              [
                -73.89198300323623,
                40.780206862136495
              ],
              [
                -73.89196093629481,
                40.780214768247106
              ],
              [
                -73.89194086148919,
                40.7802253046652
              ],
              [
                -73.89192331657638,
                40.78023818914503
              ],
              [
                -73.8919087715453,
                40.78025307654168
              ],
              [
                -73.8918976160249,
                40.78026956805684
              ],
              [
                -73.89189014884877,
                40.780287221920844
              ],
              [
                -73.8918865700483,
                40.780305565227415
              ],
              [
                -73.89187952317843,
                40.78030411870183
              ],
              [
                -73.89187225502235,
                40.780303606785225
              ],
              [
                -73.89186497847899,
                40.78030404447287
              ],
              [
                -73.89185790669332,
                40.78030541894372
              ],
              [
                -73.89185124681225,
                40.78030768993687
              ],
              [
                -73.89184519391766,
                40.78031079093021
              ],
              [
                -73.89183992531079,
                40.780314631089404
              ],
              [
                -73.89183559532059,
                40.78031909792823
              ],
              [
                -73.89183233078141,
                40.78032406060384
              ],
              [
                -73.89183022731878,
                40.780329373749076
              ],
              [
                -73.89182934654745,
                40.78033488173102
              ],
              [
                -73.89182971426752,
                40.780340423209395
              ],
              [
                -73.89183131970793,
                40.78034583586285
              ],
              [
                -73.89183411584222,
                40.78035096114323
              ],
              [
                -73.89181137407898,
                40.78035438575943
              ],
              [
                -73.89178966998287,
                40.78036058068735
              ],
              [
                -73.89176958337335,
                40.780369380431154
              ],
              [
                -73.89175165085923,
                40.780380549907875
              ],
              [
                -73.89173635150456,
                40.78039379072799
              ],
              [
                -73.89172409402968,
                40.780408749166
              ],
              [
                -73.89171520589215,
                40.78042502561067
              ],
              [
                -73.89170992453914,
                40.780442185240084
              ],
              [
                -73.89170839106461,
                40.78045976963788
              ],
              [
                -73.89169292724203,
                40.78046967380115
              ],
              [
                -73.8916795826261,
                40.78048122234703
              ],
              [
                -73.89166865651212,
                40.78049415626374
              ],
              [
                -73.89166112223361,
                40.78050668662294
              ],
              [
                -73.89165586354153,
                40.78051987112379
              ],
              [
                -73.89163480044746,
                40.78052923006731
              ],
              [
                -73.89161599772191,
                40.78054104776054
              ],
              [
                -73.89159994725462,
                40.780555015046325
              ],
              [
                -73.89158706893672,
                40.780570766533316
              ],
              [
                -73.8915776996741,
                40.78058789015378
              ],
              [
                -73.89157208457472,
                40.78060593794431
              ],
              [
                -73.89157037053627,
                40.780624437763976
              ],
              [
                -73.8915726024018,
                40.7806429056463
              ],
              [
                -73.89157872178795,
                40.78066085845988
              ],
              [
                -73.89158856861049,
                40.78067782654751
              ],
              [
                -73.89160188527265,
                40.78069336601251
              ],
              [
                -73.89161832340358,
                40.78070707033189
              ],
              [
                -73.89161533533172,
                40.780716154677954
              ],
              [
                -73.89161435039676,
                40.78072548869462
              ],
              [
                -73.89161539534275,
                40.78073481894003
              ],
              [
                -73.89161844179831,
                40.780743892074796
              ],
              [
                -73.89162340704459,
                40.78075246174044
              ],
              [
                -73.89163015626373,
                40.780760295249124
              ],
              [
                -73.89163850619785,
                40.78076717990124
              ],
              [
                -73.89164823012543,
                40.780772928760925
              ],
              [
                -73.89164282540101,
                40.78079037974238
              ],
              [
                -73.8916413177897,
                40.78080827113611
              ],
              [
                -73.89164374936645,
                40.78082610366042
              ],
              [
                -73.8916500522784,
                40.7808433796756
              ],
              [
                -73.8916600506369,
                40.78085961707212
              ],
              [
                -73.89167346542752,
                40.78087436272356
              ],
              [
                -73.8916899222946,
                40.78088720513287
              ],
              [
                -73.89170896198802,
                40.78089778591513
              ],
              [
                -73.8917300531803,
                40.7809058097989
              ],
              [
                -73.8917526072929,
                40.780911052866756
              ],
              [
                -73.89177599492218,
                40.780913368803304
              ],
              [
                -73.89179956340335,
                40.78091269297924
              ],
              [
                -73.89182265502505,
                40.78090904425438
              ],
              [
                -73.89184462538313,
                40.78090252445149
              ],
              [
                -73.89186486136488,
                40.78089331551477
              ],
              [
                -73.8919090570999,
                40.78092670225121
              ],
              [
                -73.89189866464768,
                40.78094105095678
              ],
              [
                -73.89189148820621,
                40.78095649545959
              ],
              [
                -73.8918877197505,
                40.78097262261135
              ],
              [
                -73.89188746009158,
                40.780989001002474
              ],
              [
                -73.8918907161779,
                40.78100519250198
              ],
              [
                -73.89189740090998,
                40.78102076397835
              ],
              [
                -73.8919073354694,
                40.78103529888556
              ],
              [
                -73.89192025410317,
                40.781048408406186
              ],
              [
                -73.8919358112311,
                40.78105974185245
              ],
              [
                -73.89195359069205,
                40.78106899604761
              ],
              [
                -73.8919731168745,
                40.781075923436006
              ],
              [
                -73.89199386744068,
                40.7810803387056
              ],
              [
                -73.89201528729998,
                40.781082123744895
              ],
              [
                -73.89201785991675,
                40.78108903784588
              ],
              [
                -73.89202189093805,
                40.78109553708099
              ],
              [
                -73.89202727097202,
                40.781101445077574
              ],
              [
                -73.89203385401879,
                40.78110660150759
              ],
              [
                -73.89204146143163,
                40.781110866438524
              ],
              [
                -73.89204988676438,
                40.78111412413101
              ],
              [
                -73.89205890137518,
                40.781116286179405
              ],
              [
                -73.8920682606297,
                40.781117293911265
              ],
              [
                -73.89207048531337,
                40.78113328274864
              ],
              [
                -73.89207623724933,
                40.78114875485287
              ],
              [
                -73.89208535054124,
                40.78116326397291
              ],
              [
                -73.89209756234288,
                40.781176391632386
              ],
              [
                -73.89211252043917,
                40.78118775919912
              ],
              [
                -73.89212979340492,
                40.78119703880592
              ],
              [
                -73.89214888304801,
                40.781203962807126
              ],
              [
                -73.89216923877872,
                40.78120833149817
              ],
              [
                -73.89219027348956,
                40.78121001887567
              ],
              [
                -73.89220555336303,
                40.78122724533656
              ],
              [
                -73.89222439440559,
                40.78124229663877
              ],
              [
                -73.89224627026225,
                40.78125475229789
              ],
              [
                -73.89208480450479,
                40.78137365032211
              ],
              [
                -73.89211148854682,
                40.78139517920908
              ],
              [
                -73.89216184201894,
                40.78135819165571
              ],
              [
                -73.89217832400549,
                40.78137075104873
              ],
              [
                -73.89227700450262,
                40.78129896855049
              ],
              [
                -73.89229929941422,
                40.78131781979056
              ],
              [
                -73.89232536996536,
                40.78133362663564
              ],
              [
                -73.89235450593688,
                40.781345958470794
              ],
              [
                -73.89238591359863,
                40.78135447934797
              ],
              [
                -73.89239128202318,
                40.7813627345159
              ],
              [
                -73.89239826299327,
                40.781370260567726
              ],
              [
                -73.8924066890586,
                40.781376876978456
              ],
              [
                -73.89241635810683,
                40.78138242504229
              ],
              [
                -73.89242703820901,
                40.7813867716793
              ],
              [
                -73.89243847318531,
                40.78138981262832
              ],
              [
                -73.8924481087387,
                40.781408065240086
              ],
              [
                -73.8924613085782,
                40.781424980602154
              ],
              [
                -73.89247776118444,
                40.781440159505216
              ],
              [
                -73.8924970782713,
                40.78145324372083
              ],
              [
                -73.89251880394876,
                40.781463924455814
              ],
              [
                -73.89254242548199,
                40.781471949640135
              ],
              [
                -73.89256738539288,
                40.781477129875725
              ],
              [
                -73.89257414843766,
                40.78148733722181
              ],
              [
                -73.89258280311074,
                40.78149668273412
              ],
              [
                -73.89259316320482,
                40.78150496534143
              ],
              [
                -73.89260500582098,
                40.78151200684132
              ],
              [
                -73.89261807616168,
                40.78151765573374
              ],
              [
                -73.89263209301524,
                40.78152179048137
              ],
              [
                -73.89264014938486,
                40.78153968831779
              ],
              [
                -73.89265170701735,
                40.781556441015276
              ],
              [
                -73.89266649478333,
                40.7815716555698
              ],
              [
                -73.89268416577575,
                40.78158497506022
              ],
              [
                -73.89270430544914,
                40.781596087022386
              ],
              [
                -73.8927264413444,
                40.781604730778405
              ],
              [
                -73.8927500541709,
                40.78161070355262
              ],
              [
                -73.89276292029237,
                40.78162292631542
              ],
              [
                -73.89277823731716,
                40.78163338982435
              ],
              [
                -73.89279559483698,
                40.78164181371659
              ],
              [
                -73.89281452776845,
                40.78164797227928
              ],
              [
                -73.89282341582967,
                40.7816681663864
              ],
              [
                -73.8928366204932,
                40.78168694669626
              ],
              [
                -73.89285377003881,
                40.781703784524964
              ],
              [
                -73.89287438169247,
                40.78171820587102
              ],
              [
                -73.89289787521786,
                40.781729804759316
              ],
              [
                -73.89292358924912,
                40.78173825466916
              ],
              [
                -73.89295079991076,
                40.781743317726914
              ],
              [
                -73.89295536133902,
                40.78175175136809
              ],
              [
                -73.89296169861163,
                40.781759493681285
              ],
              [
                -73.89296963642428,
                40.78176633049512
              ],
              [
                -73.89297895519823,
                40.781772072686515
              ],
              [
                -73.89298939715376,
                40.78177656141238
              ],
              [
                -73.89300067344033,
                40.781779672503525
              ],
              [
                -73.89300986180193,
                40.781798023562146
              ],
              [
                -73.89224191097428,
                40.782410799061076
              ],
              [
                -73.89214162714589,
                40.78243172650312
              ],
              [
                -73.89188955621871,
                40.78225806580526
              ],
              [
                -73.89171690105482,
                40.782142286027295
              ],
              [
                -73.89165459123714,
                40.782194781530656
              ],
              [
                -73.892145935837,
                40.78254084319402
              ],
              [
                -73.89274917876408,
                40.78296570668483
              ],
              [
                -73.89281494422083,
                40.78291321498261
              ],
              [
                -73.8922970209443,
                40.78253435048858
              ],
              [
                -73.89230750203646,
                40.782466041573734
              ],
              [
                -73.89306090692054,
                40.78184828970713
              ],
              [
                -73.8930834102953,
                40.78186037704936
              ],
              [
                -73.89310800167921,
                40.78186982622676
              ],
              [
                -73.89313414972762,
                40.78187643307113
              ],
              [
                -73.8931620256006,
                40.78190028084162
              ],
              [
                -73.89319436775718,
                40.78192061107661
              ],
              [
                -73.89319819300404,
                40.78193461250021
              ],
              [
                -73.89320486734462,
                40.78194798245131
              ],
              [
                -73.89321422468805,
                40.781960388215126
              ],
              [
                -73.89322603217543,
                40.78197152107089
              ],
              [
                -73.8932399959756,
                40.78198110397417
              ],
              [
                -73.89325576859568,
                40.78198889845172
              ],
              [
                -73.89327295752989,
                40.781994710535415
              ],
              [
                -73.8932911350265,
                40.78199839558994
              ],
              [
                -73.89330984873276,
                40.78199986191117
              ],
              [
                -73.89332863295186,
                40.78199907300968
              ],
              [
                -73.89332841862166,
                40.78200591841439
              ],
              [
                -73.89332966374293,
                40.78201270003233
              ],
              [
                -73.89333233552561,
                40.78201923926683
              ],
              [
                -73.89333636360736,
                40.78202536390441
              ],
              [
                -73.89334164190726,
                40.78203091265034
              ],
              [
                -73.89334803141958,
                40.78203573937579
              ],
              [
                -73.89335536387371,
                40.78203971696695
              ],
              [
                -73.89336344616663,
                40.78204274067226
              ],
              [
                -73.89337206544754,
                40.78204473086098
              ],
              [
                -73.8933809947239,
                40.782045635120745
              ],
              [
                -73.89338999883915,
                40.78204542963735
              ],
              [
                -73.89339714136854,
                40.782065130308666
              ],
              [
                -73.8934086436769,
                40.782083600538535
              ],
              [
                -73.89342416156191,
                40.782100287606276
              ],
              [
                -73.8934432306544,
                40.78211469215196
              ],
              [
                -73.89346528031389,
                40.78212638311992
              ],
              [
                -73.89348965070555,
                40.782135010657626
              ],
              [
                -73.89351561254605,
                40.78214031658607
              ],
              [
                -73.8935176073115,
                40.782146900497125
              ],
              [
                -73.89352096165226,
                40.782153157165986
              ],
              [
                -73.89352558921722,
                40.78215892552584
              ],
              [
                -73.89353137087876,
                40.78216405708074
              ],
              [
                -73.89353815779786,
                40.78216841972772
              ],
              [
                -73.8935457752577,
                40.7821719011583
              ],
              [
                -73.89355402716059,
                40.782174411749196
              ],
              [
                -73.89356270107572,
                40.78217588686954
              ],
              [
                -73.89357511420133,
                40.78219849559339
              ],
              [
                -73.89359238276961,
                40.78221918097234
              ],
              [
                -73.89361400842814,
                40.7822373460434
              ],
              [
                -73.89363936708129,
                40.782252466576885
              ],
              [
                -73.89366772689922,
                40.782264106206014
              ],
              [
                -73.89369826944078,
                40.782271929019544
              ],
              [
                -73.89370345701214,
                40.782280811449766
              ],
              [
                -73.89371052036864,
                40.782288912064956
              ],
              [
                -73.89371926175782,
                40.78229600407176
              ],
              [
                -73.89372943644702,
                40.78230188891488
              ],
              [
                -73.8937407595751,
                40.78230640183598
              ],
              [
                -73.89375291412806,
                40.78230941648653
              ],
              [
                -73.89375902465672,
                40.78232687381222
              ],
              [
                -73.89376875964288,
                40.78234335335012
              ],
              [
                -73.89378186414046,
                40.78235842352004
              ],
              [
                -73.89379799495987,
                40.78237168965071
              ],
              [
                -73.89381672965375,
                40.78238280431675
              ],
              [
                -73.89383757758195,
                40.78239147643709
              ],
              [
                -73.89385999276013,
                40.78239747889801
              ],
              [
                -73.89388338815877,
                40.78240065450148
              ],
              [
                -73.89390715107713,
                40.782400920081784
              ],
              [
                -73.89393065918937,
                40.78239826868375
              ],
              [
                -73.89393791682198,
                40.78240537632006
              ],
              [
                -73.89394651704815,
                40.78241155831724
              ],
              [
                -73.89395625819618,
                40.78241666970977
              ],
              [
                -73.89396691184,
                40.78242059063753
              ],
              [
                -73.89397822815634,
                40.78242322915654
              ],
              [
                -73.89398994178201,
                40.7824245233944
              ],
              [
                -73.89400177803752,
                40.782424443001794
              ],
              [
                -73.8940134593668,
                40.7824229898639
              ],
              [
                -73.89402859662968,
                40.78243805073677
              ],
              [
                -73.89404689641603,
                40.7824509244274
              ],
              [
                -73.89406782064256,
                40.782461232399115
              ],
              [
                -73.89409075405688,
                40.78246867155746
              ],
              [
                -73.89411502232694,
                40.78247302316196
              ],
              [
                -73.89413991187075,
                40.782474159258506
              ],
              [
                -73.89416469083802,
                40.78247204644135
              ],
              [
                -73.89418863062988,
                40.78246674683548
              ],
              [
                -73.89421102732304,
                40.782458416270224
              ],
              [
                -73.89423122236765,
                40.782447299696756
              ],
              [
                -73.8942486219517,
                40.782433723985655
              ],
              [
                -73.89426271446209,
                40.78241808831561
              ],
              [
                -73.89427308552676,
                40.78240085243538
              ],
              [
                -73.89427943019976,
                40.78238252314645
              ],
              [
                -73.8942895964409,
                40.78238113520514
              ],
              [
                -73.89429932841435,
                40.78237850478571
              ],
              [
                -73.89430836519104,
                40.782374702413925
              ],
              [
                -73.89431646448024,
                40.7823698300372
              ],
              [
                -73.89432340912812,
                40.78236401829177
              ],
              [
                -73.89432901293793,
                40.782357422999375
              ],
              [
                -73.89433312566331,
                40.78235022099007
              ],
              [
                -73.89433563703619,
                40.782342605360995
              ],
              [
                -73.89433647972336,
                40.78233478029873
              ],
              [
                -73.89436000109458,
                40.78233095080531
              ],
              [
                -73.89438240477216,
                40.78232429326696
              ],
              [
                -73.89440310644349,
                40.78231498131965
              ],
              [
                -73.8944215661873,
                40.782303257829085
              ],
              [
                -73.89443730255465,
                40.78228942855654
              ],
              [
                -73.89444990512632,
                40.782273854184325
              ],
              [
                -73.89445904521635,
                40.78225694090812
              ],
              [
                -73.8944644844443,
                40.78223912984373
              ],
              [
                -73.89447914479899,
                40.78223418696571
              ],
              [
                -73.89449262200559,
                40.78222757885701
              ],
              [
                -73.89450459124389,
                40.78221946478267
              ],
              [
                -73.89451476403873,
                40.782210040303525
              ],
              [
                -73.89452289521182,
                40.78219953256316
              ],
              [
                -73.89452878879166,
                40.78218819481319
              ],
              [
                -73.89455422048172,
                40.78218172361151
              ],
              [
                -73.894577890934,
                40.78217214009182
              ],
              [
                -73.89459914633292,
                40.78215970896646
              ],
              [
                -73.89461739957136,
                40.78214477360321
              ],
              [
                -73.8946321464679,
                40.782127746540574
              ],
              [
                -73.89464297969296,
                40.782109098093095
              ],
              [
                -73.89464960001914,
                40.78208934335965
              ],
              [
                -73.8946599902218,
                40.78208748222531
              ],
              [
                -73.89466981457493,
                40.7820843084561
              ],
              [
                -73.8946787883735,
                40.78207991402622
              ],
              [
                -73.89468665156055,
                40.7820744262844
              ],
              [
                -73.8946931762652,
                40.7820680042628
              ],
              [
                -73.89469817340536,
                40.78206083406868
              ],
              [
                -73.89470149816613,
                40.78205312349086
              ],
              [
                -73.89470305419827,
                40.78204509597805
              ],
              [
                -73.89472872422107,
                40.78203736318703
              ],
              [
                -73.89475258461354,
                40.78202679983583
              ],
              [
                -73.89477409893773,
                40.78201364341398
              ],
              [
                -73.8947927835019,
                40.781998189709306
              ],
              [
                -73.89480821823453,
                40.78198078615777
              ],
              [
                -73.89482005612895,
                40.78196182403243
              ],
              [
                -73.89482803104511,
                40.78194172964601
              ],
              [
                -73.89483922552756,
                40.78193942468992
              ],
              [
                -73.89484979994774,
                40.781935803653155
              ],
              [
                -73.89485948841886,
                40.78193095758459
              ],
              [
                -73.8948680473298,
                40.78192500833573
              ],
              [
                -73.89487526147207,
                40.78191810549681
              ],
              [
                -73.89488094945148,
                40.781910422635484
              ],
              [
                -73.8948849682476,
                40.781902152932496
              ],
              [
                -73.89488721681121,
                40.78189350432422
              ],
              [
                -73.89491343748593,
                40.781887245583064
              ],
              [
                -73.89493785627018,
                40.78187765946971
              ],
              [
                -73.89495974872312,
                40.78186503037893
              ],
              [
                -73.89497846535502,
                40.7818497329828
              ],
              [
                -73.89499345089537,
                40.78183222111453
              ],
              [
                -73.89500426076594,
                40.7818130143044
              ],
              [
                -73.89501057427087,
                40.7817926823669
              ],
              [
                -73.89502145943706,
                40.78179034209969
              ],
              [
                -73.89503169146163,
                40.78178667520784
              ],
              [
                -73.89504099268392,
                40.78178178119804
              ],
              [
                -73.89504911070149,
                40.78177579287629
              ],
              [
                -73.8950558252207,
                40.78176887274461
              ],
              [
                -73.89506095403343,
                40.78176120859101
              ],
              [
                -73.89506435796241,
                40.78175300839308
              ],
              [
                -73.895091177695,
                40.781745818230114
              ],
              [
                -73.89511595076091,
                40.781735201745754
              ],
              [
                -73.89513792650503,
                40.781721480633365
              ],
              [
                -73.89515643903532,
                40.781705070660436
              ],
              [
                -73.8951709274017,
                40.78168646907011
              ],
              [
                -73.89518095259164,
                40.78166623951378
              ],
              [
                -73.89518621083417,
                40.781644994971685
              ],
              [
                -73.89518654280283,
                40.781623379178896
              ],
              [
                -73.8952000494492,
                40.78161726975724
              ],
              [
                -73.8952122200433,
                40.781609710190544
              ],
              [
                -73.89522278732707,
                40.781600866481824
              ],
              [
                -73.89523151925142,
                40.78159093283309
              ],
              [
                -73.89523822406923,
                40.78158012738056
              ],
              [
                -73.89525292084325,
                40.78157279030384
              ],
              [
                -73.89526579423838,
                40.78156368650614
              ],
              [
                -73.89527648087851,
                40.78155307296019
              ],
              [
                -73.895284679113,
                40.7815412492548
              ],
              [
                -73.89534178706286,
                40.781588201555046
              ],
              [
                -73.8953532079152,
                40.78159759067088
              ],
              [
                -73.89532546202713,
                40.78162778069505
              ],
              [
                -73.89534850467375,
                40.7816492654844
              ],
              [
                -73.89534092170129,
                40.78165585474448
              ],
              [
                -73.89531667833324,
                40.78167560575161
              ],
              [
                -73.89531098163849,
                40.781671566396604
              ],
              [
                -73.8952661225133,
                40.78170811017553
              ],
              [
                -73.8952434310705,
                40.781692015317546
              ],
              [
                -73.89521264204448,
                40.7817170967075
              ],
              [
                -73.8952373986715,
                40.78173465477342
              ],
              [
                -73.89460091774595,
                40.78225315898141
              ],
              [
                -73.89462439338403,
                40.782269809190716
              ],
              [
                -73.89528796173525,
                40.781729238226504
              ],
              [
                -73.89527872466454,
                40.78172268746207
              ],
              [
                -73.89535083030216,
                40.781663945839995
              ],
              [
                -73.8953575056752,
                40.781657657133366
              ],
              [
                -73.89542197971672,
                40.78171777101631
              ],
              [
                -73.89477429053531,
                40.78225359249425
              ],
              [
                -73.89542536848079,
                40.78271997030174
              ],
              [
                -73.89551523831139,
                40.78277924139264
              ],
              [
                -73.8955250057324,
                40.78278443823798
              ],
              [
                -73.89553570949545,
                40.78278842977246
              ],
              [
                -73.89554709424156,
                40.782791120770476
              ],
              [
                -73.89555888836699,
                40.78279244703305
              ],
              [
                -73.89557081050015,
                40.78279237691987
              ],
              [
                -73.89558257621648,
                40.78279091210371
              ],
              [
                -73.89558099775664,
                40.78279342548826
              ],
              [
                -73.89558000498525,
                40.782796106383884
              ],
              [
                -73.89557962686102,
                40.78279887659094
              ],
              [
                -73.8955798744133,
                40.782801655304475
              ],
              [
                -73.89558074042151,
                40.782804361471555
              ],
              [
                -73.89558219962473,
                40.78280691615534
              ],
              [
                -73.89558420945929,
                40.78280924483766
              ],
              [
                -73.89558671130004,
                40.782811279592316
              ],
              [
                -73.8955896321699,
                40.7828129610674
              ],
              [
                -73.8955928868692,
                40.782814240215245
              ],
              [
                -73.89559638046099,
                40.78281507972422
              ],
              [
                -73.8956000110397,
                40.782815455106366
              ],
              [
                -73.89560367270391,
                40.7828153554121
              ],
              [
                -73.89560725864587,
                40.78281478354954
              ],
              [
                -73.89560485504498,
                40.78282202154761
              ],
              [
                -73.895604119439,
                40.782829465603314
              ],
              [
                -73.89560507360832,
                40.7828368953124
              ],
              [
                -73.89560768930227,
                40.78284409069575
              ],
              [
                -73.89561188907587,
                40.78285083871193
              ],
              [
                -73.8956175485824,
                40.78285693956494
              ],
              [
                -73.89562450025493,
                40.78286221262027
              ],
              [
                -73.89563253826776,
                40.78286650175287
              ],
              [
                -73.89564142463112,
                40.782869679969494
              ],
              [
                -73.89565089623643,
                40.78287165316922
              ],
              [
                -73.89566067264735,
                40.78287236292929
              ],
              [
                -73.89567046440277,
                40.78287178823513
              ],
              [
                -73.89567998158762,
                40.78286994610229
              ],
              [
                -73.8956889424155,
                40.782866891072885
              ],
              [
                -73.89568785374138,
                40.7828692501229
              ],
              [
                -73.89568729067815,
                40.78287171319539
              ],
              [
                -73.89568726858151,
                40.78287421311979
              ],
              [
                -73.89568778805355,
                40.78287668172075
              ],
              [
                -73.89568883492858,
                40.782879051676986
              ],
              [
                -73.89569038065657,
                40.78288125835709
              ],
              [
                -73.89569238308417,
                40.78288324158295
              ],
              [
                -73.89569478760359,
                40.78288494726962
              ],
              [
                -73.89569752864062,
                40.78288632890169
              ],
              [
                -73.89570053144448,
                40.7828873488002
              ],
              [
                -73.8957037141256,
                40.78288797915167
              ],
              [
                -73.89570698988923,
                40.78288820276582
              ],
              [
                -73.89571026940133,
                40.78288801354415
              ],
              [
                -73.89571346322656,
                40.78288741664731
              ],
              [
                -73.89571648426619,
                40.78288642835298
              ],
              [
                -73.89571463765114,
                40.78289409591569
              ],
              [
                -73.89571446216367,
                40.78290188975514
              ],
              [
                -73.8957159625105,
                40.78290960085943
              ],
              [
                -73.89571909845655,
                40.78291702243523
              ],
              [
                -73.89572378590373,
                40.78292395545377
              ],
              [
                -73.89572989914612,
                40.782930213987854
              ],
              [
                -73.89573727424195,
                40.78293563019876
              ],
              [
                -73.89574571340852,
                40.78294005883647
              ],
              [
                -73.89575499032769,
                40.78294338113537
              ],
              [
                -73.89576485621456,
                40.782945507999194
              ],
              [
                -73.89577504648946,
                40.7829463823906
              ],
              [
                -73.89578528787293,
                40.78294598086049
              ],
              [
                -73.89579530571558,
                40.78294431417669
              ],
              [
                -73.89579457617347,
                40.7829465894953
              ],
              [
                -73.89579436267464,
                40.78294892578488
              ],
              [
                -73.8957946713532,
                40.78295125592067
              ],
              [
                -73.89579549334081,
                40.782953512954855
              ],
              [
                -73.89579680502031,
                40.78295563203978
              ],
              [
                -73.8957985687056,
                40.78295755229137
              ],
              [
                -73.89580073372352,
                40.782959218538075
              ],
              [
                -73.89580323787052,
                40.78296058290647
              ],
              [
                -73.89580600919882,
                40.78296160619636
              ],
              [
                -73.89580896808468,
                40.782962259007235
              ],
              [
                -73.89581202951503,
                40.78296252258299
              ],
              [
                -73.89581510553091,
                40.782962389350836
              ],
              [
                -73.89581810775456,
                40.78296186313843
              ],
              [
                -73.89582094992738,
                40.78296095906491
              ],
              [
                -73.89581879410996,
                40.78296802212586
              ],
              [
                -73.89581817957287,
                40.78297525772287
              ],
              [
                -73.89581912270793,
                40.7829824728551
              ],
              [
                -73.89582159835946,
                40.78298947506698
              ],
              [
                -73.89582554049217,
                40.78299607758269
              ],
              [
                -73.89583084395485,
                40.7830021042875
              ],
              [
                -73.89583736728378,
                40.783007394425866
              ],
              [
                -73.89584493647695,
                40.78301180688939
              ],
              [
                -73.89585334963449,
                40.783015223980364
              ],
              [
                -73.895862382345,
                40.783017554551805
              ],
              [
                -73.89587179367125,
                40.78301873643823
              ],
              [
                -73.895881332576,
                40.78301873811416
              ],
              [
                -73.89589074461992,
                40.78301755953494
              ],
              [
                -73.89589977874697,
                40.78301523213775
              ],
              [
                -73.89589965741153,
                40.78301716162841
              ],
              [
                -73.89589995082204,
                40.783019080403974
              ],
              [
                -73.89590065115445,
                40.78302093729983
              ],
              [
                -73.89590173973431,
                40.783022682801054
              ],
              [
                -73.89590318753406,
                40.783024270363384
              ],
              [
                -73.895904955948,
                40.783025657653894
              ],
              [
                -73.89590699782062,
                40.78302680768017
              ],
              [
                -73.89590925870489,
                40.78302768977596
              ],
              [
                -73.89591167831321,
                40.783028280420346
              ],
              [
                -73.89591419212613,
                40.783028563863205
              ],
              [
                -73.89591673311206,
                40.78302853254676
              ],
              [
                -73.8959192335144,
                40.78302818730584
              ],
              [
                -73.89592162665929,
                40.78302753734654
              ],
              [
                -73.89591956615465,
                40.78303518928069
              ],
              [
                -73.89591915960655,
                40.783042993743734
              ],
              [
                -73.89592041770368,
                40.78305074555861
              ],
              [
                -73.89592330737152,
                40.78305824093238
              ],
              [
                -73.89592775264177,
                40.78306528281362
              ],
              [
                -73.89593363665045,
                40.78307168607322
              ],
              [
                -73.89594080470866,
                40.783077282371096
              ],
              [
                -73.8959490683703,
                40.783081924582135
              ],
              [
                -73.89595821038566,
                40.783085490663645
              ],
              [
                -73.8959679904139,
                40.783087886864166
              ],
              [
                -73.89597815134013,
                40.78308905018822
              ],
              [
                -73.89598842603613,
                40.78308895005236
              ],
              [
                -73.89599854438265,
                40.78308758908897
              ],
              [
                -73.8959990701085,
                40.783090888272085
              ],
              [
                -73.8960003252084,
                40.783094071689646
              ],
              [
                -73.89600227322916,
                40.78309704688297
              ],
              [
                -73.89600485759347,
                40.78309972744086
              ],
              [
                -73.89600800324133,
                40.78310203550976
              ],
              [
                -73.89601161881058,
                40.7831039040543
              ],
              [
                -73.89601559929172,
                40.78310527880468
              ],
              [
                -73.89601982907595,
                40.78310611983292
              ],
              [
                -73.89602418531393,
                40.78310640271232
              ],
              [
                -73.89602339553038,
                40.783114383910274
              ],
              [
                -73.89602443817536,
                40.783122348319225
              ],
              [
                -73.89602728166864,
                40.78313005470372
              ],
              [
                -73.8960318398836,
                40.78313726964353
              ],
              [
                -73.896037974756,
                40.783143774603786
              ],
              [
                -73.8960455004657,
                40.783149372554526
              ],
              [
                -73.89605418906547,
                40.78315389393809
              ],
              [
                -73.8960637773842,
                40.78315720180524
              ],
              [
                -73.89590760973432,
                40.78324268509292
              ],
              [
                -73.8959053617012,
                40.78324268306236
              ],
              [
                -73.89590199053325,
                40.78324268001725
              ],
              [
                -73.89589974382243,
                40.783242677987786
              ],
              [
                -73.8958974942327,
                40.78324353067024
              ],
              [
                -73.89589412438713,
                40.783243527626084
              ],
              [
                -73.89589187635393,
                40.783243525595275
              ],
              [
                -73.89588850385209,
                40.78324437558784
              ],
              [
                -73.89588625558453,
                40.783245228271355
              ],
              [
                -73.89588400755132,
                40.783245226240375
              ],
              [
                -73.89588063504668,
                40.78324607790804
              ],
              [
                -73.89587838612039,
                40.78324692891539
              ],
              [
                -73.89587613741166,
                40.783247781598206
              ],
              [
                -73.89587388936489,
                40.78324863377899
              ],
              [
                -73.89587051752298,
                40.783249484274315
              ],
              [
                -73.8958682688149,
                40.78325033645437
              ],
              [
                -73.89586601922448,
                40.78325118913621
              ],
              [
                -73.89586376897542,
                40.7832520401422
              ],
              [
                -73.89586151959156,
                40.78325374703613
              ],
              [
                -73.89585927154383,
                40.78325459971925
              ],
              [
                -73.89585702195573,
                40.78325545072559
              ],
              [
                -73.89585477191125,
                40.78325715694863
              ],
              [
                -73.8958536467644,
                40.78325886485849
              ],
              [
                -73.89585139739397,
                40.78325971754016
              ],
              [
                -73.89584914801137,
                40.78326142326121
              ],
              [
                -73.89584689708599,
                40.78326312898077
              ],
              [
                -73.89584577149773,
                40.783264836889984
              ],
              [
                -73.89584352190911,
                40.7832656878961
              ],
              [
                -73.89584239698291,
                40.7832673953034
              ],
              [
                -73.89584014671415,
                40.7832691037039
              ],
              [
                -73.89583901958473,
                40.78327081043895
              ],
              [
                -73.89583789399812,
                40.7832725171755
              ],
              [
                -73.89583676553248,
                40.78327507812137
              ],
              [
                -73.89583563840293,
                40.7832767848565
              ],
              [
                -73.89583338835627,
                40.78327849174919
              ],
              [
                -73.89583338568458,
                40.7832801995006
              ],
              [
                -73.89583225921363,
                40.78328190791163
              ],
              [
                -73.89583113074661,
                40.783284469359984
              ],
              [
                -73.89583000582081,
                40.78328617609706
              ],
              [
                -73.89582887869106,
                40.78328788283199
              ],
              [
                -73.89564668397335,
                40.7833859450032
              ],
              [
                -73.89564443703733,
                40.78338594296855
              ],
              [
                -73.89564218899933,
                40.78338594093286
              ],
              [
                -73.89563881980774,
                40.78338593788185
              ],
              [
                -73.89563657043185,
                40.783386789554164
              ],
              [
                -73.89563432371625,
                40.78338678751953
              ],
              [
                -73.89563207479681,
                40.78338678548284
              ],
              [
                -73.89562870294273,
                40.783387637143726
              ],
              [
                -73.89562645556609,
                40.78338763510832
              ],
              [
                -73.89562420596883,
                40.783388487115324
              ],
              [
                -73.89562195925319,
                40.783388485080444
              ],
              [
                -73.89561858673788,
                40.783389336740285
              ],
              [
                -73.89561633714222,
                40.783390187742114
              ],
              [
                -73.89561408908675,
                40.7833910404204
              ],
              [
                -73.89561184015011,
                40.7833918925952
              ],
              [
                -73.89560959077187,
                40.783392745272224
              ],
              [
                -73.89560734271886,
                40.78339359627508
              ],
              [
                -73.89560509444264,
                40.78339444895309
              ],
              [
                -73.89560284506693,
                40.783395299954684
              ],
              [
                -73.8956005956717,
                40.78339700684351
              ],
              [
                -73.89559834673393,
                40.78339785952062
              ],
              [
                -73.89559722093655,
                40.78339871154014
              ],
              [
                -73.89559497087888,
                40.783400418930725
              ],
              [
                -73.89559272282256,
                40.7834012716086
              ],
              [
                -73.89559047254707,
                40.78340297732368
              ],
              [
                -73.89558934629069,
                40.78340468405716
              ],
              [
                -73.89558709823416,
                40.78340553673493
              ],
              [
                -73.89558597109594,
                40.7834072434675
              ],
              [
                -73.89558372015728,
                40.7834089503547
              ],
              [
                -73.89558259522298,
                40.78341065708918
              ],
              [
                -73.89558146808277,
                40.78341236499439
              ],
              [
                -73.89558034226594,
                40.78341407223076
              ],
              [
                -73.89557809154897,
                40.78341577794523
              ],
              [
                -73.8955769650697,
                40.78341748585106
              ],
              [
                -73.89557584013492,
                40.78341919258555
              ],
              [
                -73.89557471233522,
                40.7834208993174
              ],
              [
                -73.89557470965504,
                40.78342260824152
              ],
              [
                -73.89557358251622,
                40.78342431497407
              ],
              [
                -73.89557245735855,
                40.783426023383505
              ],
              [
                -73.8955713288801,
                40.783428584326764
              ],
              [
                -73.89557132620169,
                40.78343029207813
              ],
              [
                -73.89539475352586,
                40.7835257958054
              ],
              [
                -73.89539138234358,
                40.78352579274535
              ],
              [
                -73.89538913428123,
                40.783526644916705
              ],
              [
                -73.89538576376032,
                40.78352664185709
              ],
              [
                -73.89538239169636,
                40.78352663879598
              ],
              [
                -73.89538014231063,
                40.7835274914685
              ],
              [
                -73.89537677178932,
                40.78352748840863
              ],
              [
                -73.89537339926646,
                40.783528338386155
              ],
              [
                -73.89537115254609,
                40.78352833634639
              ],
              [
                -73.89536777913908,
                40.78352918799816
              ],
              [
                -73.8953644072755,
                40.78353003914866
              ],
              [
                -73.89536215788942,
                40.78353089182086
              ],
              [
                -73.89535878536606,
                40.783531741797844
              ],
              [
                -73.89535653730252,
                40.7835325944711
              ],
              [
                -73.89535428769524,
                40.78353344764548
              ],
              [
                -73.89535091515073,
                40.78353515233678
              ],
              [
                -73.8953486664265,
                40.783536004506836
              ],
              [
                -73.89534641770122,
                40.783536857179215
              ],
              [
                -73.89534304229129,
                40.78353856186775
              ],
              [
                -73.89534079288619,
                40.78354026757881
              ],
              [
                -73.8953385448229,
                40.78354111974905
              ],
              [
                -73.895336293875,
                40.78354282545853
              ],
              [
                -73.89533404446858,
                40.78354453183955
              ],
              [
                -73.89533179418005,
                40.78354623855473
              ],
              [
                -73.89532954345202,
                40.783547944264264
              ],
              [
                -73.89532729404469,
                40.78354965114772
              ],
              [
                -73.89532616689736,
                40.783551357877805
              ],
              [
                -73.89532391727117,
                40.78355306358832
              ],
              [
                -73.89532166654014,
                40.78355477097293
              ],
              [
                -73.89532054159481,
                40.783556478877614
              ],
              [
                -73.89531941310351,
                40.783559040320924
              ],
              [
                -73.89531716281648,
                40.78356074552797
              ],
              [
                -73.8953160372108,
                40.783562452761956
              ],
              [
                -73.89531490871939,
                40.78356501420515
              ],
              [
                -73.89531378156964,
                40.78356672210771
              ],
              [
                -73.89531265528277,
                40.78356928305036
              ],
              [
                -73.89531152813396,
                40.7835709902828
              ],
              [
                -73.89531040096466,
                40.783573551727216
              ],
              [
                -73.89530927247077,
                40.78357611434302
              ],
              [
                -73.89487851410838,
                40.783814026747876
              ],
              [
                -73.89487514357252,
                40.78381402367334
              ],
              [
                -73.89487177237567,
                40.783814020598086
              ],
              [
                -73.89486952432344,
                40.783814018547325
              ],
              [
                -73.89486615243806,
                40.78381487018578
              ],
              [
                -73.89486278124096,
                40.78381486711027
              ],
              [
                -73.89485941004429,
                40.78381486403466
              ],
              [
                -73.89485603750012,
                40.78381571399692
              ],
              [
                -73.89485266696438,
                40.78381571092172
              ],
              [
                -73.89485041888456,
                40.78381656358516
              ],
              [
                -73.89484704479567,
                40.78381741471845
              ],
              [
                -73.8948436715876,
                40.78381826635504
              ],
              [
                -73.89484029970367,
                40.78381911682006
              ],
              [
                -73.89483805162435,
                40.78381996898053
              ],
              [
                -73.89483467907723,
                40.783820820617464
              ],
              [
                -73.89483242945676,
                40.783821671603846
              ],
              [
                -73.89482905823212,
                40.78382252324183
              ],
              [
                -73.89482680814122,
                40.78382423011485
              ],
              [
                -73.89482343559621,
                40.7838250800762
              ],
              [
                -73.89482118528399,
                40.78382678745139
              ],
              [
                -73.89481781141393,
                40.783827639086624
              ],
              [
                -73.89481556198582,
                40.78382934478727
              ],
              [
                -73.89481331101454,
                40.78383105048653
              ],
              [
                -73.89481106158604,
                40.78383275618717
              ],
              [
                -73.89480881215557,
                40.78383446306039
              ],
              [
                -73.89480656184537,
                40.78383616876004
              ],
              [
                -73.8948043117545,
                40.78383787513004
              ],
              [
                -73.89480206144246,
                40.78383958183479
              ],
              [
                -73.89479981069096,
                40.78384128753391
              ],
              [
                -73.89479868437701,
                40.78384385014661
              ],
              [
                -73.89479643340498,
                40.78384555584556
              ],
              [
                -73.89479418196466,
                40.78384811575599
              ],
              [
                -73.89479305545987,
                40.783849822481244
              ],
              [
                -73.89479192694081,
                40.78385238559446
              ],
              [
                -73.89478967751104,
                40.783854091294664
              ],
              [
                -73.8947885503174,
                40.7838566522312
              ],
              [
                -73.8947874231513,
                40.78385835895594
              ],
              [
                -73.89478629551601,
                40.78386092039464
              ],
              [
                -73.8947851669973,
                40.783863483005284
              ],
              [
                -73.8947840420339,
                40.78386519023452
              ],
              [
                -73.89478403798543,
                40.78386775219647
              ],
              [
                -73.89462095843263,
                40.78395643385942
              ],
              [
                -73.89461758656718,
                40.78395643077614
              ],
              [
                -73.89461533983261,
                40.78395642872164
              ],
              [
                -73.89461196774687,
                40.78395642563799
              ],
              [
                -73.89460859742452,
                40.78395642255584
              ],
              [
                -73.89460522464705,
                40.783957274185845
              ],
              [
                -73.89460185432445,
                40.7839572711035
              ],
              [
                -73.894599605357,
                40.783958122588416
              ],
              [
                -73.89459623349147,
                40.783958119504504
              ],
              [
                -73.89459286093424,
                40.783958971134346
              ],
              [
                -73.8945894883796,
                40.783959821088885
              ],
              [
                -73.89458724029151,
                40.78396067374698
              ],
              [
                -73.89458386619197,
                40.78396152487265
              ],
              [
                -73.89458049495687,
                40.78396237650339
              ],
              [
                -73.89457824554901,
                40.78396322748487
              ],
              [
                -73.8945748729911,
                40.78396407911418
              ],
              [
                -73.89457262333171,
                40.78396578480991
              ],
              [
                -73.89456925165608,
                40.783966635937254
              ],
              [
                -73.89456700067407,
                40.78396834163167
              ],
              [
                -73.89456475258542,
                40.78396919428935
              ],
              [
                -73.89456137779206,
                40.78397090063078
              ],
              [
                -73.89455912703013,
                40.783972606325165
              ],
              [
                -73.89455687894126,
                40.78397345898276
              ],
              [
                -73.89455462950137,
                40.783975164678274
              ],
              [
                -73.89455237851892,
                40.783976870372406
              ],
              [
                -73.89455012907696,
                40.78397857724058
              ],
              [
                -73.89454787875519,
                40.783980282935225
              ],
              [
                -73.8945456273006,
                40.783982842840544
              ],
              [
                -73.89454450012349,
                40.78398455123814
              ],
              [
                -73.8945422506829,
                40.7839862569334
              ],
              [
                -73.89453999900691,
                40.78398881734115
              ],
              [
                -73.89453887271368,
                40.78399052406414
              ],
              [
                -73.89453662327091,
                40.783992230931965
              ],
              [
                -73.89453549474221,
                40.78399479236742
              ],
              [
                -73.89453436822727,
                40.783996499760256
              ],
              [
                -73.89453323969927,
                40.7839990605256
              ],
              [
                -73.8945321133726,
                40.78400162313572
              ],
              [
                -73.89453098619725,
                40.78400332985792
              ],
              [
                -73.89452985766874,
                40.78400589079059
              ],
              [
                -73.8945287313435,
                40.784008452228
              ],
              [
                -73.89452760416795,
                40.78401015895006
              ],
              [
                -73.89452760010882,
                40.78401272141457
              ],
              [
                -73.89452647290186,
                40.784015282851065
              ],
              [
                -73.89452646884082,
                40.78401784648825
              ],
              [
                -73.89452646478244,
                40.78402040845018
              ],
              [
                -73.89452533760662,
                40.78402211517222
              ],
              [
                -73.89452533354739,
                40.78402467763672
              ],
              [
                -73.89452532948816,
                40.78402724010113
              ],
              [
                -73.89452532542893,
                40.78402980256564
              ],
              [
                -73.8945253213705,
                40.78403236452752
              ],
              [
                -73.89452644313495,
                40.784034073977935
              ],
              [
                -73.89452643907497,
                40.78403663694498
              ],
              [
                -73.89452755816399,
                40.78403920043743
              ],
              [
                -73.89452755410484,
                40.78404176290184
              ],
              [
                -73.89452867451665,
                40.78404432639538
              ],
              [
                -73.8945297940783,
                40.78404603517369
              ],
              [
                -73.89452979002003,
                40.784048597135566
              ],
              [
                -73.89453091043097,
                40.78405116129925
              ],
              [
                -73.89453203219534,
                40.78405287108471
              ],
              [
                -73.89453315040394,
                40.78405543407355
              ],
              [
                -73.89453539597996,
                40.784057143882414
              ],
              [
                -73.89453651639212,
                40.784059707375874
              ],
              [
                -73.89453763595257,
                40.78406141732677
              ],
              [
                -73.89453987995448,
                40.78406398184841
              ],
              [
                -73.89454100171801,
                40.784065692303834
              ],
              [
                -73.89443190733381,
                40.78412367263082
              ],
              [
                -73.89442966330132,
                40.78412196282154
              ],
              [
                -73.894427417726,
                40.78412025301072
              ],
              [
                -73.8944251736954,
                40.78411854202868
              ],
              [
                -73.89442180585228,
                40.78411683118976
              ],
              [
                -73.8944195611603,
                40.784115120374445
              ],
              [
                -73.89441731558674,
                40.78411340989333
              ],
              [
                -73.89441394572995,
                40.78411255209146
              ],
              [
                -73.89441170169829,
                40.78411084228176
              ],
              [
                -73.89440945612566,
                40.784109131297996
              ],
              [
                -73.89440608560533,
                40.78410827517054
              ],
              [
                -73.89440271776488,
                40.784106563325906
              ],
              [
                -73.89440047237899,
                40.784105707559114
              ],
              [
                -73.8943971023025,
                40.78410484975655
              ],
              [
                -73.89439373332566,
                40.784103993630104
              ],
              [
                -73.89439148705986,
                40.7841031368572
              ],
              [
                -73.89438811654294,
                40.784102279054046
              ],
              [
                -73.8943847466873,
                40.78410142125129
              ],
              [
                -73.89438137682909,
                40.78410056512373
              ],
              [
                -73.89437800561754,
                40.784100562034034
              ],
              [
                -73.89437463642331,
                40.784099704231586
              ],
              [
                -73.89437126521202,
                40.78409970114169
              ],
              [
                -73.89436789535593,
                40.78409884384106
              ],
              [
                -73.89436564839589,
                40.784098841781486
              ],
              [
                -73.89436227586219,
                40.78409883869012
              ],
              [
                -73.89435890531216,
                40.78409883560048
              ],
              [
                -73.89435553343955,
                40.784098832509514
              ],
              [
                -73.89435216288952,
                40.78409882941968
              ],
              [
                -73.89434879098383,
                40.784099680540386
              ],
              [
                -73.89434541911156,
                40.78409967744914
              ],
              [
                -73.89434204720494,
                40.78410052907228
              ],
              [
                -73.89433867599362,
                40.784100525981444
              ],
              [
                -73.89433530408981,
                40.78410137592907
              ],
              [
                -73.89433193064029,
                40.784102227550484
              ],
              [
                -73.89432968257816,
                40.7841022254892
              ],
              [
                -73.89432631001021,
                40.78410307711113
              ],
              [
                -73.89432293810341,
                40.78410392873366
              ],
              [
                -73.89431956418136,
                40.78410563339351
              ],
              [
                -73.89431731520486,
                40.784106485040496
              ],
              [
                -73.8943139432986,
                40.78410733599266
              ],
              [
                -73.89431169252575,
                40.78410904168213
              ],
              [
                -73.89430831995726,
                40.78410989330367
              ],
              [
                -73.89430607050454,
                40.78411160016701
              ],
              [
                -73.89430269724292,
                40.78411330482687
              ],
              [
                -73.89430044826526,
                40.78411415697602
              ],
              [
                -73.89429819660938,
                40.78411586316716
              ],
              [
                -73.89429482356759,
                40.784117567827
              ],
              [
                -73.89429257257153,
                40.78411927468861
              ],
              [
                -73.89429032311996,
                40.78412098037901
              ],
              [
                -73.89428807366826,
                40.784122686069345
              ],
              [
                -73.89428582131488,
                40.78412524764414
              ],
              [
                -73.8942835712019,
                40.78412695333366
              ],
              [
                -73.89428244401546,
                40.78412866172856
              ],
              [
                -73.89428019298647,
                40.78413122162919
              ],
              [
                -73.89427794221116,
                40.7841329278207
              ],
              [
                -73.89427681587465,
                40.78413548875294
              ],
              [
                -73.89427456620204,
                40.784137194442735
              ],
              [
                -73.89427343787925,
                40.78413975704846
              ],
              [
                -73.89427230999897,
                40.78414231848181
              ],
              [
                -73.89427118281638,
                40.78414402419625
              ],
              [
                -73.89427005647703,
                40.78414658663617
              ],
              [
                -73.89426892793547,
                40.78414914806895
              ],
              [
                -73.89426779939441,
                40.784151708999175
              ],
              [
                -73.89426667217506,
                40.78415427043308
              ],
              [
                -73.89426554498851,
                40.78415597832521
              ],
              [
                -73.89426554091929,
                40.78415854078967
              ],
              [
                -73.89426441391988,
                40.784161102223734
              ],
              [
                -73.89426440985139,
                40.78416366418563
              ],
              [
                -73.89426440578211,
                40.78416622665008
              ],
              [
                -73.89426327657955,
                40.78416878757947
              ],
              [
                -73.89426327250943,
                40.784171350546465
              ],
              [
                -73.89426326844011,
                40.78417391301083
              ],
              [
                -73.89426438950579,
                40.78417647600498
              ],
              [
                -73.89426438543651,
                40.78417903846942
              ],
              [
                -73.89426438136803,
                40.784181600431225
              ],
              [
                -73.89426550022667,
                40.78418416560106
              ],
              [
                -73.89426549616009,
                40.78418672639028
              ],
              [
                -73.89426661791961,
                40.78418843634585
              ],
              [
                -73.89426773700121,
                40.78419099984061
              ],
              [
                -73.89426773293206,
                40.78419356230508
              ],
              [
                -73.89426885333722,
                40.78419612529841
              ],
              [
                -73.89426997374065,
                40.78419868929706
              ],
              [
                -73.89427221777159,
                40.784200399276955
              ],
              [
                -73.89427234227921,
                40.78420068803741
              ],
              [
                -73.89427333530928,
                40.78420296377555
              ],
              [
                -73.89427445571475,
                40.78420552676888
              ],
              [
                -73.89427670106852,
                40.78420723658246
              ],
              [
                -73.89427782014992,
                40.78420980074725
              ],
              [
                -73.89428006418011,
                40.78421151156465
              ],
              [
                -73.89428118458582,
                40.78421407455804
              ],
              [
                -73.89428342927906,
                40.78421578437086
              ],
              [
                -73.89428567419266,
                40.78421749418378
              ],
              [
                -73.894287916866,
                40.78422005988172
              ],
              [
                -73.89429016222077,
                40.78422176969502
              ],
              [
                -73.89429240713474,
                40.7842234795078
              ],
              [
                -73.89429465182651,
                40.784225190493174
              ],
              [
                -73.89429689674014,
                40.7842269008084
              ],
              [
                -73.89429914077274,
                40.784228610620225
              ],
              [
                -73.89430251063372,
                40.78422946842544
              ],
              [
                -73.89430475620783,
                40.78423117941133
              ],
              [
                -73.89430812383343,
                40.784232890253485
              ],
              [
                -73.8943103701031,
                40.7842337465255
              ],
              [
                -73.89431373860967,
                40.78423545787088
              ],
              [
                -73.89431598465926,
                40.78423631414249
              ],
              [
                -73.89431935540516,
                40.78423717027271
              ],
              [
                -73.8943227232489,
                40.78423888279001
              ],
              [
                -73.89432496929804,
                40.78423973956408
              ],
              [
                -73.89432833850138,
                40.784240595692594
              ],
              [
                -73.89433170770215,
                40.784241453496335
              ],
              [
                -73.89433507958181,
                40.78424145658799
              ],
              [
                -73.89433845098681,
                40.78424231439359
              ],
              [
                -73.89434069637754,
                40.78424316949146
              ],
              [
                -73.89434406693489,
                40.78424317258161
              ],
              [
                -73.8943474367972,
                40.784244030385544
              ],
              [
                -73.89435080801601,
                40.784244033476135
              ],
              [
                -73.8943541792346,
                40.78424403656663
              ],
              [
                -73.89435754979199,
                40.78424403965641
              ],
              [
                -73.89436092101073,
                40.78424404274671
              ],
              [
                -73.89436429222933,
                40.7842440458369
              ],
              [
                -73.89436766410917,
                40.78424404892759
              ],
              [
                -73.8943710362094,
                40.78424405201838
              ],
              [
                -73.89437440790242,
                40.78424320039423
              ],
              [
                -73.89437777934127,
                40.78424320348423
              ],
              [
                -73.8943800274389,
                40.78424235250541
              ],
              [
                -73.89438340001398,
                40.784241500379174
              ],
              [
                -73.89438677123266,
                40.78424150346872
              ],
              [
                -73.89439014314564,
                40.78424065184441
              ],
              [
                -73.89439351571626,
                40.78423980239842
              ],
              [
                -73.89439576535914,
                40.78423894974541
              ],
              [
                -73.89439913994963,
                40.78423724340868
              ],
              [
                -73.89440251185977,
                40.784236393459246
              ],
              [
                -73.89440475995951,
                40.784235540804694
              ],
              [
                -73.89440813388596,
                40.78423383614248
              ],
              [
                -73.89441038352743,
                40.78423298399173
              ],
              [
                -73.89441375657195,
                40.784231279328516
              ],
              [
                -73.89441600690759,
                40.78422957246388
              ],
              [
                -73.89441937948078,
                40.78422872083926
              ],
              [
                -73.89442163047623,
                40.78422701464531
              ],
              [
                -73.89442387992803,
                40.784225308952486
              ],
              [
                -73.89442612938156,
                40.78422360208675
              ],
              [
                -73.8944283803758,
                40.784221896395245
              ],
              [
                -73.89443062982703,
                40.784220190702186
              ],
              [
                -73.89443288195783,
                40.7842176291245
              ],
              [
                -73.89443513229072,
                40.78421592343223
              ],
              [
                -73.89443738174157,
                40.784214217739034
              ],
              [
                -73.89443851027796,
                40.78421165630472
              ],
              [
                -73.89444076039058,
                40.78420995010942
              ],
              [
                -73.8944430120789,
                40.78420738903371
              ],
              [
                -73.89444413705601,
                40.784205682310414
              ],
              [
                -73.89444526559178,
                40.78420312087601
              ],
              [
                -73.89444626716197,
                40.78420084746884
              ],
              [
                -73.8944463941285,
                40.78420055893893
              ],
              [
                -73.89444864490147,
                40.78419885307929
              ],
              [
                -73.89444977211531,
                40.78419629114096
              ],
              [
                -73.89445089998888,
                40.784193730208536
              ],
              [
                -73.89445090405098,
                40.78419116774406
              ],
              [
                -73.8944520325863,
                40.78418860630963
              ],
              [
                -73.89445315891777,
                40.78418604487298
              ],
              [
                -73.8944531616241,
                40.78418433762435
              ],
              [
                -73.89445429016183,
                40.78418177451464
              ],
              [
                -73.894454294223,
                40.78417921255282
              ],
              [
                -73.89445429828497,
                40.78417665008838
              ],
              [
                -73.89445542593758,
                40.784174089155535
              ],
              [
                -73.8944554300003,
                40.78417152618861
              ],
              [
                -73.89445543406224,
                40.78416896372416
              ],
              [
                -73.89445431453207,
                40.78416640073344
              ],
              [
                -73.89445431859403,
                40.784163838268995
              ],
              [
                -73.89445432265519,
                40.78416127630708
              ],
              [
                -73.8944532022445,
                40.78415871281288
              ],
              [
                -73.89445320630652,
                40.78415615034843
              ],
              [
                -73.89445208810046,
                40.78415358635366
              ],
              [
                -73.89445096768834,
                40.78415102386455
              ],
              [
                -73.89444984658591,
                40.784149313911385
              ],
              [
                -73.89444872749884,
                40.78414674991569
              ],
              [
                -73.89444760797087,
                40.78414418591965
              ],
              [
                -73.89444648755892,
                40.784141623430514
              ],
              [
                -73.8944453679978,
                40.78413991465137
              ],
              [
                -73.89444424758945,
                40.784137349984306
              ],
              [
                -73.89444200402872,
                40.78413478596389
              ],
              [
                -73.89455221889905,
                40.78407765918929
              ],
              [
                -73.89455446447508,
                40.784079369500134
              ],
              [
                -73.8945567085077,
                40.784081080479766
              ],
              [
                -73.89455895254228,
                40.784082790286654
              ],
              [
                -73.89456119789922,
                40.78408450009473
              ],
              [
                -73.89456344281365,
                40.78408621107489
              ],
              [
                -73.8945656868486,
                40.784087920881575
              ],
              [
                -73.89456793242547,
                40.78408963135981
              ],
              [
                -73.89457130228129,
                40.78409049016232
              ],
              [
                -73.89457354631736,
                40.78409219946628
              ],
              [
                -73.89457691551564,
                40.78409305609026
              ],
              [
                -73.89457916087174,
                40.78409476707058
              ],
              [
                -73.89458253161125,
                40.78409562486842
              ],
              [
                -73.89458477699999,
                40.7840964799615
              ],
              [
                -73.89458814484641,
                40.78409819079579
              ],
              [
                -73.89459039243657,
                40.78409904756605
              ],
              [
                -73.89459376141407,
                40.78409990485939
              ],
              [
                -73.89459713215402,
                40.78410076265684
              ],
              [
                -73.89460050270435,
                40.78410076573953
              ],
              [
                -73.89460274809352,
                40.78410162083229
              ],
              [
                -73.89460611795215,
                40.784102478628675
              ],
              [
                -73.89460948850238,
                40.78410248171109
              ],
              [
                -73.89461285968343,
                40.784103339508356
              ],
              [
                -73.89461622957246,
                40.78410334258998
              ],
              [
                -73.89461960232684,
                40.784103345674104
              ],
              [
                -73.8946218490666,
                40.784103347728525
              ],
              [
                -73.89462522027807,
                40.78410335081109
              ],
              [
                -73.89462859148955,
                40.784103353893556
              ],
              [
                -73.89463196336244,
                40.78410335697652
              ],
              [
                -73.89463533325153,
                40.784103360057564
              ],
              [
                -73.89463870581552,
                40.784102508426585
              ],
              [
                -73.89464095409792,
                40.78410251048205
              ],
              [
                -73.8946443257803,
                40.78410165884997
              ],
              [
                -73.89464769787318,
                40.78410166193267
              ],
              [
                -73.8946510697733,
                40.78410081197591
              ],
              [
                -73.89465444233711,
                40.784099960344335
              ],
              [
                -73.89465669042808,
                40.784099108187405
              ],
              [
                -73.89466006453448,
                40.78409825655715
              ],
              [
                -73.89466343621375,
                40.784097406599756
              ],
              [
                -73.89466568518694,
                40.784096553940905
              ],
              [
                -73.89466905909967,
                40.78409484927101
              ],
              [
                -73.89467130785162,
                40.78409399711452
              ],
              [
                -73.89467468044246,
                40.78409229194049
              ],
              [
                -73.89467693073775,
                40.78409143928271
              ],
              [
                -73.89467918150143,
                40.7840897330834
              ],
              [
                -73.89468255475354,
                40.784088027742335
              ],
              [
                -73.89468480372369,
                40.78408717675838
              ],
              [
                -73.89468705316571,
                40.784085469887664
              ],
              [
                -73.89468930260671,
                40.78408376368702
              ],
              [
                -73.89469155358869,
                40.784082058492814
              ],
              [
                -73.89469380303117,
                40.784080351119364
              ],
              [
                -73.8946960551452,
                40.78407779070914
              ],
              [
                -73.89469830546662,
                40.78407608501164
              ],
              [
                -73.89469943264055,
                40.78407437711516
              ],
              [
                -73.89470168208007,
                40.78407267141685
              ],
              [
                -73.89470393353233,
                40.78407011100579
              ],
              [
                -73.89470506070602,
                40.78406840310934
              ],
              [
                -73.89470618790456,
                40.78406584334628
              ],
              [
                -73.89470843734627,
                40.78406413597259
              ],
              [
                -73.89470956586948,
                40.78406157453553
              ],
              [
                -73.89471069373161,
                40.78405901309779
              ],
              [
                -73.89471182090494,
                40.784057305201316
              ],
              [
                -73.89471294722318,
                40.784054744264665
              ],
              [
                -73.89471407574707,
                40.784052182325034
              ],
              [
                -73.89487940528232,
                40.78396179483017
              ],
              [
                -73.8948827773683,
                40.783961797905995
              ],
              [
                -73.89488614903966,
                40.78396094676937
              ],
              [
                -73.89488952046446,
                40.78396094984439
              ],
              [
                -73.89489176719918,
                40.783960951893555
              ],
              [
                -73.89489513840348,
                40.78396095496822
              ],
              [
                -73.89489851095692,
                40.78396010332961
              ],
              [
                -73.89490188284746,
                40.783959252862864
              ],
              [
                -73.89490525405182,
                40.78395925593723
              ],
              [
                -73.8949075036785,
                40.78395840327423
              ],
              [
                -73.89491087689001,
                40.783957553311076
              ],
              [
                -73.8949142487821,
                40.78395670167133
              ],
              [
                -73.89491649686505,
                40.78395584950934
              ],
              [
                -73.89491986941798,
                40.783954997869984
              ],
              [
                -73.89492324130713,
                40.78395414790521
              ],
              [
                -73.89492549206093,
                40.78395244103096
              ],
              [
                -73.8949288654932,
                40.78395159056487
              ],
              [
                -73.89493111492453,
                40.783949883689374
              ],
              [
                -73.89493336455074,
                40.78394903102586
              ],
              [
                -73.89493673844912,
                40.78394732634803
              ],
              [
                -73.89493898787828,
                40.783945620645056
              ],
              [
                -73.89494123885189,
                40.78394391377077
              ],
              [
                -73.8949434882816,
                40.783942207565005
              ],
              [
                -73.89494573903343,
                40.78394050136053
              ],
              [
                -73.8949479893448,
                40.783938794988075
              ],
              [
                -73.89495023877322,
                40.7839370892848
              ],
              [
                -73.89495248886473,
                40.78393538240947
              ],
              [
                -73.89495473917535,
                40.78393367620431
              ],
              [
                -73.8949569899512,
                40.78393111629031
              ],
              [
                -73.89495811623269,
                40.783929409563136
              ],
              [
                -73.89496036654482,
                40.78392770218534
              ],
              [
                -73.89496149505524,
                40.78392514124823
              ],
              [
                -73.89496374514425,
                40.783923435545354
              ],
              [
                -73.89496487233303,
                40.78392087410455
              ],
              [
                -73.89496599949766,
                40.783919166205436
              ],
              [
                -73.89496712712702,
                40.783916604765025
              ],
              [
                -73.89496825431539,
                40.78391404332405
              ],
              [
                -73.89496938282542,
                40.7839114823869
              ],
              [
                -73.8949705077839,
                40.783909775658486
              ],
              [
                -73.89539452003514,
                40.78367441708034
              ],
              [
                -73.89539789122516,
                40.78367442014031
              ],
              [
                -73.89540126441807,
                40.78367356899
              ],
              [
                -73.89540463560783,
                40.78367357204975
              ],
              [
                -73.89540688321463,
                40.78367357408966
              ],
              [
                -73.89541025508595,
                40.783672722435384
              ],
              [
                -73.8954136276158,
                40.783671872456864
              ],
              [
                -73.89541699880546,
                40.78367187551626
              ],
              [
                -73.8954203713376,
                40.7836710238622
              ],
              [
                -73.89542374320577,
                40.783670173882776
              ],
              [
                -73.89542711573773,
                40.78366932222859
              ],
              [
                -73.8954293653481,
                40.78366846955539
              ],
              [
                -73.8954327378791,
                40.78366761840369
              ],
              [
                -73.89543611175125,
                40.78366591320847
              ],
              [
                -73.8954394842821,
                40.78366506205653
              ],
              [
                -73.89544173234822,
                40.78366421005177
              ],
              [
                -73.89544510555919,
                40.783662504688365
              ],
              [
                -73.8954473562879,
                40.783660798976555
              ],
              [
                -73.89545072903955,
                40.78365994732183
              ],
              [
                -73.89545297844766,
                40.78365824043611
              ],
              [
                -73.89545522917604,
                40.783656534724024
              ],
              [
                -73.89545747946356,
                40.78365482901165
              ],
              [
                -73.89546085333363,
                40.783653124318384
              ],
              [
                -73.89546310428493,
                40.78365141693129
              ],
              [
                -73.8954653536906,
                40.78364971121788
              ],
              [
                -73.89546760309798,
                40.78364800433175
              ],
              [
                -73.89546985516668,
                40.78364544440882
              ],
              [
                -73.89547098099025,
                40.783643737173584
              ],
              [
                -73.89547323239827,
                40.78364117674741
              ],
              [
                -73.89547543359768,
                40.78363943195676
              ],
              [
                -73.89547661117338,
                40.78363690841718
              ],
              [
                -73.895478860578,
                40.78363520270363
              ],
              [
                -73.89547998840348,
                40.783632641258166
              ],
              [
                -73.89548111532699,
                40.78363093502905
              ],
              [
                -73.89548336629524,
                40.78362837342952
              ],
              [
                -73.89548449389872,
                40.783625812654044
              ],
              [
                -73.89566219788169,
                40.78352518471057
              ],
              [
                -73.89566556994565,
                40.78352518776349
              ],
              [
                -73.8956678166659,
                40.78352518979753
              ],
              [
                -73.89567118850914,
                40.78352519285009
              ],
              [
                -73.89567455970759,
                40.78352434169004
              ],
              [
                -73.89567680775005,
                40.783524343725084
              ],
              [
                -73.8956801811533,
                40.78352349206427
              ],
              [
                -73.89568355167422,
                40.783523495115276
              ],
              [
                -73.89568579973087,
                40.78352264411093
              ],
              [
                -73.89568917225284,
                40.783521791946505
              ],
              [
                -73.89569142185125,
                40.78352094144596
              ],
              [
                -73.89569479437245,
                40.78352008978387
              ],
              [
                -73.89569704375366,
                40.78351923710521
              ],
              [
                -73.89570041495205,
                40.783518385441795
              ],
              [
                -73.89570266455097,
                40.78351753443852
              ],
              [
                -73.89570491394781,
                40.78351582754778
              ],
              [
                -73.89570828646838,
                40.78351497588528
              ],
              [
                -73.8957105360673,
                40.78351412488181
              ],
              [
                -73.89571278612301,
                40.78351241916415
              ],
              [
                -73.8957150361806,
                40.78351071227384
              ],
              [
                -73.89571728512053,
                40.783509859594375
              ],
              [
                -73.89571953451573,
                40.78350815337352
              ],
              [
                -73.89572178391077,
                40.78350644715241
              ],
              [
                -73.89572403484864,
                40.78350474076529
              ],
              [
                -73.89572628424244,
                40.78350303504676
              ],
              [
                -73.89572853496064,
                40.783501328156774
              ],
              [
                -73.89572966209678,
                40.78349962142284
              ],
              [
                -73.89573191171073,
                40.78349791570444
              ],
              [
                -73.89573416110616,
                40.78349620881307
              ],
              [
                -73.89573528958107,
                40.78349364686308
              ],
              [
                -73.89573641583523,
                40.78349194012828
              ],
              [
                -73.89573866611182,
                40.78349023323773
              ],
              [
                -73.89573979238119,
                40.783487672290825
              ],
              [
                -73.89574091951661,
                40.78348596555678
              ],
              [
                -73.89574204733012,
                40.78348340360615
              ],
              [
                -73.89574317446478,
                40.78348169737462
              ],
              [
                -73.8959197498697,
                40.783383629474784
              ],
              [
                -73.89592199724618,
                40.78338363150446
              ],
              [
                -73.89592536842149,
                40.783383634548976
              ],
              [
                -73.89592761601847,
                40.783383636578726
              ],
              [
                -73.8959309885289,
                40.78338278541234
              ],
              [
                -73.89593435904277,
                40.78338278845601
              ],
              [
                -73.89593660841629,
                40.78338193577266
              ],
              [
                -73.89593997981197,
                40.783381938816945
              ],
              [
                -73.89594222786033,
                40.783381087807506
              ],
              [
                -73.89594559903531,
                40.78338109085143
              ],
              [
                -73.89594784708642,
                40.78338023816668
              ],
              [
                -73.89595122113704,
                40.78337938817379
              ],
              [
                -73.89595346918802,
                40.78337853548891
              ],
              [
                -73.89595684169842,
                40.78337768381917
              ],
              [
                -73.89595909107092,
                40.783376831638
              ],
              [
                -73.89596246291832,
                40.7833759811401
              ],
              [
                -73.89596471318599,
                40.78337427374253
              ],
              [
                -73.89596696123404,
                40.7833734227326
              ],
              [
                -73.89596921060682,
                40.78337257004861
              ],
              [
                -73.89597146021241,
                40.783370863152776
              ],
              [
                -73.89597370959535,
                40.78336915742947
              ],
              [
                -73.89597708342484,
                40.78336830743565
              ],
              [
                -73.8959793336913,
                40.78336660054027
              ],
              [
                -73.89598045861442,
                40.78336489380177
              ],
              [
                -73.89598271020125,
                40.78336318808019
              ],
              [
                -73.89598496024746,
                40.78336148068202
              ],
              [
                -73.89598721051144,
                40.78335977495915
              ],
              [
                -73.89598833543728,
                40.783358066545404
              ],
              [
                -73.8959905863623,
                40.783356360823056
              ],
              [
                -73.89599283640734,
                40.78335465392725
              ],
              [
                -73.8959939635342,
                40.78335294719058
              ],
              [
                -73.89599508912015,
                40.78335123927996
              ],
              [
                -73.89599734071753,
                40.783348679513715
              ],
              [
                -73.89599846784509,
                40.78334697210691
              ],
              [
                -73.89599959276781,
                40.78334526536834
              ],
              [
                -73.89600072122916,
                40.78334270391825
              ],
              [
                -73.89600184769624,
                40.78334099600828
              ],
              [
                -73.89600297461216,
                40.78333843623209
              ],
              [
                -73.89600297728212,
                40.783336726805416
              ],
              [
                -73.89618107248933,
                40.78324173011311
              ],
              [
                -73.89619676273668,
                40.783239235953666
              ],
              [
                -73.89621167207537,
                40.78323476080923
              ],
              [
                -73.89622537101242,
                40.7832284335951
              ],
              [
                -73.8962374649233,
                40.78322043657913
              ],
              [
                -73.89624760541996,
                40.78321100013102
              ],
              [
                -73.89625550038656,
                40.78320039608598
              ],
              [
                -73.89630958793612,
                40.78317035921375
              ],
              [
                -73.89631785674719,
                40.78317073642652
              ],
              [
                -73.8963260878036,
                40.78317002775907
              ],
              [
                -73.89633403613205,
                40.78316825430262
              ],
              [
                -73.89634146517315,
                40.78316546883927
              ],
              [
                -73.89634815382301,
                40.78316175427004
              ],
              [
                -73.89635390301389,
                40.783157221148436
              ],
              [
                -73.89635854163745,
                40.783152004389656
              ],
              [
                -73.89636193163939,
                40.78314625925535
              ],
              [
                -73.89636397212593,
                40.7831401567327
              ],
              [
                -73.89641917133227,
                40.783109500964706
              ],
              [
                -73.89642999260793,
                40.78310892401905
              ],
              [
                -73.89644055406723,
                40.78310704180658
              ],
              [
                -73.89645058499843,
                40.78310390257213
              ],
              [
                -73.89645982828792,
                40.78309958678076
              ],
              [
                -73.89646804701138,
                40.78309420505524
              ],
              [
                -73.89647503050642,
                40.78308789534021
              ],
              [
                -73.8964805997714,
                40.78308081936667
              ],
              [
                -73.89648461205581,
                40.7830731585063
              ],
              [
                -73.89653410328104,
                40.7830456738372
              ],
              [
                -73.89654250898245,
                40.783046333134415
              ],
              [
                -73.89655094398557,
                40.78304594639984
              ],
              [
                -73.89655918448705,
                40.783044523894795
              ],
              [
                -73.89656701184407,
                40.783042103361964
              ],
              [
                -73.89657421837578,
                40.7830387490248
              ],
              [
                -73.89658061287393,
                40.783034549882665
              ],
              [
                -73.89658602567505,
                40.78302961735036
              ],
              [
                -73.8965903131632,
                40.783024082301075
              ],
              [
                -73.8965933615806,
                40.78301809159451
              ],
              [
                -73.89659509004451,
                40.78301180418
              ],
              [
                -73.89664643144633,
                40.78298329134397
              ],
              [
                -73.89666039992538,
                40.78298110523994
              ],
              [
                -73.89667367437603,
                40.78297714250932
              ],
              [
                -73.89668586681107,
                40.7829715189751
              ],
              [
                -73.8966966208677,
                40.78296439900316
              ],
              [
                -73.89670562222592,
                40.78295599069708
              ],
              [
                -73.89671260779281,
                40.782946539815846
              ],
              [
                -73.89675571775832,
                40.78292259936251
              ],
              [
                -73.8967676287666,
                40.78292135341897
              ],
              [
                -73.89677910966594,
                40.7829186394892
              ],
              [
                -73.89678984954254,
                40.78291453106881
              ],
              [
                -73.89679955755118,
                40.78290913941771
              ],
              [
                -73.8968079707902,
                40.7829026105468
              ],
              [
                -73.89681486142186,
                40.78289512126362
              ],
              [
                -73.89682004284245,
                40.782886874384786
              ],
              [
                -73.89687100883668,
                40.78285857068651
              ],
              [
                -73.89688112690442,
                40.782859029203415
              ],
              [
                -73.89689119819457,
                40.78285815978087
              ],
              [
                -73.89690092330346,
                40.782855988265595
              ],
              [
                -73.89691001311911,
                40.782852579213234
              ],
              [
                -73.89691819741559,
                40.78284803396943
              ],
              [
                -73.89692523288718,
                40.78284248765728
              ],
              [
                -73.89693091038077,
                40.782836105159625
              ],
              [
                -73.89693506111394,
                40.782829076217936
              ],
              [
                -73.89693756169261,
                40.78282160979132
              ],
              [
                -73.89697784802637,
                40.78279923560483
              ],
              [
                -73.89699069800244,
                40.78279883506896
              ],
              [
                -73.8970032774252,
                40.782796801465636
              ],
              [
                -73.89701522967316,
                40.78279319244647
              ],
              [
                -73.89702621590553,
                40.782788110325896
              ],
              [
                -73.89703592466749,
                40.782781699179594
              ],
              [
                -73.89704408072033,
                40.782774140760715
              ],
              [
                -73.8970504528443,
                40.78276564934734
              ],
              [
                -73.89705486039331,
                40.78275646566681
              ],
              [
                -73.89711476973946,
                40.782723193674414
              ],
              [
                -73.89718604765989,
                40.78280720996354
              ],
              [
                -73.89865437844033,
                40.78199542032075
              ],
              [
                -73.8994794775269,
                40.78216988593737
              ],
              [
                -73.90034960780264,
                40.78216441052999
              ],
              [
                -73.9003457266802,
                40.7820660545113
              ],
              [
                -73.90072847022454,
                40.781979495284105
              ],
              [
                -73.90121754094692,
                40.781819112421964
              ],
              [
                -73.9012912761892,
                40.78185841927035
              ],
              [
                -73.90203565790105,
                40.78120310196288
              ],
              [
                -73.9021496741192,
                40.78078049395422
              ],
              [
                -73.90218303706564,
                40.780359367152144
              ],
              [
                -73.90225480922946,
                40.780274167814156
              ],
              [
                -73.90234303882673,
                40.78018724480452
              ],
              [
                -73.90257929672345,
                40.78028912083556
              ],
              [
                -73.9026767708108,
                40.78035257204668
              ],
              [
                -73.90267723582103,
                40.78059413174234
              ],
              [
                -73.90258049398902,
                40.780814898123595
              ],
              [
                -73.90251248041803,
                40.78110825136057
              ],
              [
                -73.90230443036802,
                40.78151989321849
              ],
              [
                -73.90218781908892,
                40.78175713879031
              ],
              [
                -73.90218359459601,
                40.7817697698042
              ],
              [
                -73.90217815315991,
                40.781780561863464
              ],
              [
                -73.90217132811875,
                40.78178969659164
              ],
              [
                -73.90211707957926,
                40.78184770612496
              ],
              [
                -73.90205256434422,
                40.78191807899362
              ],
              [
                -73.9019834514793,
                40.78199048351641
              ],
              [
                -73.9019509578695,
                40.78202128909452
              ],
              [
                -73.90191949285618,
                40.78204562135164
              ],
              [
                -73.90188611408085,
                40.7820658177787
              ],
              [
                -73.90186925588473,
                40.78207403378065
              ],
              [
                -73.90185231622019,
                40.782080998798406
              ],
              [
                -73.90179093113794,
                40.78210191951054
              ],
              [
                -73.90174935055292,
                40.7821172381066
              ],
              [
                -73.90172373164513,
                40.78212925463787
              ],
              [
                -73.90114577552107,
                40.78271110609054
              ],
              [
                -73.89874897638398,
                40.78278115576603
              ],
              [
                -73.89697315561342,
                40.78416049796698
              ],
              [
                -73.89708339167628,
                40.784251259517326
              ],
              [
                -73.89685938880385,
                40.78440412048267
              ],
              [
                -73.89680660094372,
                40.78436627012369
              ],
              [
                -73.89682807945508,
                40.78434896505369
              ],
              [
                -73.89678844985487,
                40.78432054842666
              ],
              [
                -73.89677182707408,
                40.784333941598405
              ],
              [
                -73.89675564813932,
                40.78432233937577
              ],
              [
                -73.89672052173825,
                40.78435064397939
              ],
              [
                -73.89673005421017,
                40.78435747867779
              ],
              [
                -73.89672513646194,
                40.78436144210911
              ],
              [
                -73.8967344194984,
                40.78436809934182
              ],
              [
                -73.89672269281452,
                40.78437754932582
              ],
              [
                -73.8967585725261,
                40.78440327601169
              ],
              [
                -73.89677700751366,
                40.78438842096358
              ],
              [
                -73.89683173824393,
                40.78442766370345
              ],
              [
                -73.8958291437706,
                40.78522152738345
              ],
              [
                -73.89612632632496,
                40.78559316931146
              ],
              [
                -73.89599975377486,
                40.78565575201834
              ],
              [
                -73.89597673220975,
                40.78562708775979
              ],
              [
                -73.8959296297551,
                40.78564894431375
              ],
              [
                -73.89598001322786,
                40.78571167576888
              ],
              [
                -73.8960274447233,
                40.78568928642567
              ],
              [
                -73.89600516772016,
                40.78566665023862
              ],
              [
                -73.89614015089828,
                40.7856071672883
              ],
              [
                -73.89624259793256,
                40.78574043299194
              ],
              [
                -73.89613322780025,
                40.78579140356489
              ],
              [
                -73.89611381864461,
                40.78576734216538
              ],
              [
                -73.89604376668126,
                40.78579998748045
              ],
              [
                -73.89621803522914,
                40.78601602287123
              ],
              [
                -73.89628652699834,
                40.78598371863645
              ],
              [
                -73.89626785620453,
                40.7859605719464
              ],
              [
                -73.89637855982241,
                40.7859089810944
              ],
              [
                -73.89650254833985,
                40.786057467843996
              ],
              [
                -73.8963688834431,
                40.78612023020103
              ],
              [
                -73.89635026246756,
                40.786097319612736
              ],
              [
                -73.89630366096506,
                40.78611920156253
              ],
              [
                -73.89635251322325,
                40.78617931020327
              ],
              [
                -73.89640086049117,
                40.78615791177364
              ],
              [
                -73.89637905280317,
                40.78613108158356
              ],
              [
                -73.89651204913619,
                40.78606865902595
              ],
              [
                -73.8969399356256,
                40.78662466896974
              ],
              [
                -73.89702410373322,
                40.786658156960044
              ],
              [
                -73.89725536651031,
                40.78675095694394
              ],
              [
                -73.89783180169579,
                40.78718217039415
              ],
              [
                -73.89924748492585,
                40.78814866012842
              ],
              [
                -73.89961637941789,
                40.78840049623635
              ],
              [
                -73.90010759371266,
                40.7888504900996
              ],
              [
                -73.90032629743816,
                40.789007662825696
              ],
              [
                -73.90077375891737,
                40.78914381825163
              ],
              [
                -73.9016968608998,
                40.78932032382403
              ],
              [
                -73.90203428533837,
                40.78941468642665
              ],
              [
                -73.90206327886824,
                40.78949440549526
              ],
              [
                -73.90239936084073,
                40.789551620685856
              ],
              [
                -73.9025117370087,
                40.78947208502824
              ],
              [
                -73.90649492923363,
                40.79018099034471
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000268316529534,
        "objectid": 227,
        "shape_leng": 0.0828301570789,
        "location_id": 227,
        "zone": "Sunset Park East",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.991928124,
                40.64220490399991
              ],
              [
                -73.99121277399996,
                40.642685890999935
              ],
              [
                -73.99049634100004,
                40.64316444099996
              ],
              [
                -73.98977890299996,
                40.64364488699991
              ],
              [
                -73.98905872499985,
                40.64411924099995
              ],
              [
                -73.99180823499984,
                40.64578396899985
              ],
              [
                -73.99392923899993,
                40.64706931099991
              ],
              [
                -73.99604565399993,
                40.64834065399985
              ],
              [
                -73.99830602299986,
                40.649740014999935
              ],
              [
                -73.99779466099982,
                40.65028888999994
              ],
              [
                -74.00214474699993,
                40.65295610699995
              ],
              [
                -74.00275058799997,
                40.652384832999886
              ],
              [
                -74.00317064200001,
                40.65198024599987
              ],
              [
                -74.00332715599991,
                40.65182948699989
              ],
              [
                -74.00390815899992,
                40.651265968999915
              ],
              [
                -74.00449022999992,
                40.65070538499987
              ],
              [
                -74.00507350699995,
                40.65014666199993
              ],
              [
                -74.00566250199992,
                40.649578383999895
              ],
              [
                -74.00624091999993,
                40.649022424999934
              ],
              [
                -74.00682351799996,
                40.648464881999885
              ],
              [
                -74.00740505499991,
                40.64790502999992
              ],
              [
                -74.00798795199988,
                40.64734471499993
              ],
              [
                -74.00856800399991,
                40.64678864999995
              ],
              [
                -74.009152821,
                40.646226734999885
              ],
              [
                -74.00973537099993,
                40.64566683499987
              ],
              [
                -74.01031893899994,
                40.64510666299991
              ],
              [
                -74.01090222499987,
                40.64454699899989
              ],
              [
                -74.01148370499999,
                40.64398661299994
              ],
              [
                -74.01206572999999,
                40.6434251979999
              ],
              [
                -74.01264921199999,
                40.64286578799991
              ],
              [
                -74.0132320339999,
                40.64230518499987
              ],
              [
                -74.01381389999992,
                40.641745246999896
              ],
              [
                -74.01439632399995,
                40.64118583699995
              ],
              [
                -74.01497963599998,
                40.64062546799994
              ],
              [
                -74.01556266699994,
                40.64006663699991
              ],
              [
                -74.01616711499993,
                40.63948244099991
              ],
              [
                -74.0139608859999,
                40.63815326699995
              ],
              [
                -74.01456685499988,
                40.63757090799991
              ],
              [
                -74.01514575000002,
                40.63701460999994
              ],
              [
                -74.015739469,
                40.636452236999894
              ],
              [
                -74.01591339499994,
                40.63628606999988
              ],
              [
                -74.01606790799994,
                40.636138450999894
              ],
              [
                -74.0163054819999,
                40.63589567399986
              ],
              [
                -74.0166914329999,
                40.63551966099993
              ],
              [
                -74.01678445499995,
                40.63539802299989
              ],
              [
                -74.016835401,
                40.63535542899995
              ],
              [
                -74.01667603,
                40.63518670499995
              ],
              [
                -74.01660869499997,
                40.63511883399988
              ],
              [
                -74.01650516099987,
                40.63499150699988
              ],
              [
                -74.01646522999985,
                40.63494240699987
              ],
              [
                -74.01633863299998,
                40.63475616099995
              ],
              [
                -74.01626768999998,
                40.63462840599987
              ],
              [
                -74.01623055699993,
                40.634561529999914
              ],
              [
                -74.0161423149999,
                40.634360157999915
              ],
              [
                -74.01607491199997,
                40.63415382299994
              ],
              [
                -74.01602896399994,
                40.63394443099988
              ],
              [
                -74.016004681,
                40.63373391199989
              ],
              [
                -74.01600191100002,
                40.633524190999886
              ],
              [
                -74.016020101,
                40.63331711399997
              ],
              [
                -74.01591473499991,
                40.63336554899992
              ],
              [
                -74.01560204999993,
                40.63351697799989
              ],
              [
                -74.01514630699995,
                40.63322193799993
              ],
              [
                -74.01317645999991,
                40.63201376599993
              ],
              [
                -74.0109702639999,
                40.6306818689999
              ],
              [
                -74.01000340799995,
                40.63011308899991
              ],
              [
                -74.00922358699998,
                40.630633933999924
              ],
              [
                -74.0084560119999,
                40.631150269999914
              ],
              [
                -74.00773968299988,
                40.63163008999992
              ],
              [
                -74.00702302499997,
                40.63210999499996
              ],
              [
                -74.006806779,
                40.63225039099994
              ],
              [
                -74.00661914999998,
                40.63237545799997
              ],
              [
                -74.00630615499989,
                40.63258408799993
              ],
              [
                -74.00556490399993,
                40.63308317599992
              ],
              [
                -74.00735636699996,
                40.63416362699991
              ],
              [
                -74.00675122699992,
                40.63474478699988
              ],
              [
                -74.00641784699992,
                40.635064849999885
              ],
              [
                -74.00616926899994,
                40.63530350099989
              ],
              [
                -74.00558612999998,
                40.63586622899988
              ],
              [
                -74.00500346499996,
                40.636425234999905
              ],
              [
                -74.00442048399997,
                40.63698498599989
              ],
              [
                -74.00411104099992,
                40.637282960999926
              ],
              [
                -74.00383793099998,
                40.63754593099993
              ],
              [
                -74.00325273099993,
                40.63810426199991
              ],
              [
                -74.00267214299998,
                40.63866700999993
              ],
              [
                -74.00236943499988,
                40.63895767799987
              ],
              [
                -74.00209075399987,
                40.63922525399994
              ],
              [
                -74.00150771299988,
                40.63978496199995
              ],
              [
                -74.00092541799995,
                40.64034590199991
              ],
              [
                -74.00066078199991,
                40.64059930899991
              ],
              [
                -74.00034122099999,
                40.64090531599992
              ],
              [
                -73.99975690799991,
                40.6414671499999
              ],
              [
                -73.99917564699989,
                40.642025441999934
              ],
              [
                -73.99697357399992,
                40.64069410499995
              ],
              [
                -73.9955103369999,
                40.63980966699993
              ],
              [
                -73.99479539499987,
                40.640288486999914
              ],
              [
                -73.99433124099983,
                40.64060028799991
              ],
              [
                -73.99407691999981,
                40.640770401999944
              ],
              [
                -73.99336253699994,
                40.641247798999885
              ],
              [
                -73.99264456099989,
                40.64172828999991
              ],
              [
                -73.991928124,
                40.64220490399991
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000993414783816,
        "objectid": 228,
        "shape_leng": 0.177685177706,
        "location_id": 228,
        "zone": "Sunset Park West",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.9969179149999,
                40.669153483999914
              ],
              [
                -73.99696465099986,
                40.66923454899992
              ],
              [
                -73.99757732099995,
                40.67019948399994
              ],
              [
                -73.99764875199986,
                40.670311734999906
              ],
              [
                -73.99771474299992,
                40.67041544799985
              ],
              [
                -73.99787130000004,
                40.670661499999945
              ],
              [
                -73.99798931599987,
                40.67084208199993
              ],
              [
                -73.99822509999998,
                40.67120286799997
              ],
              [
                -73.99845678099994,
                40.671568042999894
              ],
              [
                -73.9986188799999,
                40.67183017199988
              ],
              [
                -73.99868108199985,
                40.67173915799987
              ],
              [
                -73.99876941079444,
                40.67160204705256
              ],
              [
                -73.99871360475827,
                40.67153537812376
              ],
              [
                -73.9985582446838,
                40.67133550166999
              ],
              [
                -73.99871255131345,
                40.67068973626812
              ],
              [
                -73.99874991491649,
                40.67053337321991
              ],
              [
                -73.9988920731521,
                40.66993843617495
              ],
              [
                -73.99891684838755,
                40.669804563217696
              ],
              [
                -73.99890126537383,
                40.669727272915324
              ],
              [
                -73.99891160167903,
                40.6696485786396
              ],
              [
                -73.99880972556291,
                40.66959618094355
              ],
              [
                -73.998655806916,
                40.66951479554788
              ],
              [
                -73.99896950024663,
                40.669162244242756
              ],
              [
                -73.99907935327965,
                40.669038271861666
              ],
              [
                -73.99915332740866,
                40.66866988112061
              ],
              [
                -73.9992404910503,
                40.66830628655996
              ],
              [
                -73.99918971480948,
                40.66829820450028
              ],
              [
                -73.99917988320549,
                40.66829663988748
              ],
              [
                -73.99917543024283,
                40.668319625185795
              ],
              [
                -73.99909039181547,
                40.66873147779713
              ],
              [
                -73.99906368149783,
                40.66887346818924
              ],
              [
                -73.99904324792304,
                40.668880220735105
              ],
              [
                -73.99903627811007,
                40.66891715219927
              ],
              [
                -73.99905044103012,
                40.66892168779957
              ],
              [
                -73.99902633264173,
                40.66900788840064
              ],
              [
                -73.99894520195373,
                40.66897377448506
              ],
              [
                -73.9990537218269,
                40.66885179921331
              ],
              [
                -73.99902600998384,
                40.66844297212695
              ],
              [
                -73.99954541498883,
                40.66790034997115
              ],
              [
                -73.99998149339123,
                40.66745027490869
              ],
              [
                -74.00068774868765,
                40.667545951898816
              ],
              [
                -74.00146470932715,
                40.6671695687076
              ],
              [
                -74.00120611348305,
                40.66701810720285
              ],
              [
                -74.00161950021086,
                40.66676627917448
              ],
              [
                -74.00204667064895,
                40.66676977035092
              ],
              [
                -74.00203289059164,
                40.66678725866812
              ],
              [
                -74.00224030991373,
                40.66679383514518
              ],
              [
                -74.00292463516382,
                40.66646231216333
              ],
              [
                -73.99953152609916,
                40.66439748593692
              ],
              [
                -73.99971008269921,
                40.66423016695416
              ],
              [
                -73.99984301565564,
                40.66410560044322
              ],
              [
                -74.00009913667097,
                40.663856345506325
              ],
              [
                -74.00007728967002,
                40.66384525383013
              ],
              [
                -74.00003024177398,
                40.66382136938717
              ],
              [
                -74.00023141373393,
                40.663627995622825
              ],
              [
                -74.00053780692811,
                40.663333477706935
              ],
              [
                -74.00049413361143,
                40.66331422709332
              ],
              [
                -74.00074785689003,
                40.66308446140927
              ],
              [
                -74.00083401108444,
                40.66300644269171
              ],
              [
                -74.00091723430236,
                40.66305925325034
              ],
              [
                -74.0009442426561,
                40.66307639198391
              ],
              [
                -74.00113519509667,
                40.662901724055104
              ],
              [
                -74.00486193792648,
                40.66506482193818
              ],
              [
                -74.00528132177348,
                40.66465700352408
              ],
              [
                -74.00432294897372,
                40.66405231997527
              ],
              [
                -74.00459238636759,
                40.663735199832836
              ],
              [
                -74.00340348595626,
                40.66298572659833
              ],
              [
                -74.00362862508561,
                40.662734847534594
              ],
              [
                -74.00426374481543,
                40.66311378364022
              ],
              [
                -74.00426622473461,
                40.66311526298805
              ],
              [
                -74.0042950800297,
                40.6630955807249
              ],
              [
                -74.00430053267483,
                40.66309186190212
              ],
              [
                -74.00556312932866,
                40.66384904217337
              ],
              [
                -74.00564213395873,
                40.66389641993054
              ],
              [
                -74.00564311080416,
                40.66389689080374
              ],
              [
                -74.00564925172907,
                40.6638998515595
              ],
              [
                -74.0056502595958,
                40.66390033784382
              ],
              [
                -74.00564140754479,
                40.664531491895836
              ],
              [
                -74.00582211847083,
                40.66452876005957
              ],
              [
                -74.00584924770605,
                40.66450329861971
              ],
              [
                -74.00580832976841,
                40.6644782842434
              ],
              [
                -74.00583499448777,
                40.66446958031833
              ],
              [
                -74.00574463033603,
                40.664417639970026
              ],
              [
                -74.00577046133881,
                40.66439749528106
              ],
              [
                -74.00574145525125,
                40.66438182951614
              ],
              [
                -74.00574908832141,
                40.664326509889555
              ],
              [
                -74.00589272459429,
                40.66419031812317
              ],
              [
                -74.0057575379142,
                40.66411439291198
              ],
              [
                -74.00582327521114,
                40.66405456385315
              ],
              [
                -74.00574809232232,
                40.66401194231734
              ],
              [
                -74.00577974295794,
                40.66398776309299
              ],
              [
                -74.00575781045477,
                40.663922756801625
              ],
              [
                -74.00580999359873,
                40.66387400202656
              ],
              [
                -74.00589826436901,
                40.66387569107484
              ],
              [
                -74.005921387034,
                40.66384959857589
              ],
              [
                -74.00599262641796,
                40.663890763213935
              ],
              [
                -74.00604608518974,
                40.663840005635535
              ],
              [
                -74.0062030658537,
                40.66393016821056
              ],
              [
                -74.00611934710179,
                40.664010566693165
              ],
              [
                -74.00602870773491,
                40.66395913653513
              ],
              [
                -74.0058415763248,
                40.664138020674585
              ],
              [
                -74.00644979980798,
                40.664506206799054
              ],
              [
                -74.00664155766748,
                40.66432021135603
              ],
              [
                -74.00653579559389,
                40.66425740363617
              ],
              [
                -74.00662937859565,
                40.66418793012444
              ],
              [
                -74.00688215307311,
                40.664340372942945
              ],
              [
                -74.00679912430283,
                40.66441830653352
              ],
              [
                -74.00670685940631,
                40.66436298985672
              ],
              [
                -74.00652469600116,
                40.66454722766291
              ],
              [
                -74.0071253264673,
                40.66491521847425
              ],
              [
                -74.00731878365974,
                40.66472986075766
              ],
              [
                -74.00725283559042,
                40.664690323643065
              ],
              [
                -74.00726610280036,
                40.6646730488294
              ],
              [
                -74.00725742631731,
                40.66464295951081
              ],
              [
                -74.00730516126835,
                40.66459759075258
              ],
              [
                -74.00763877534806,
                40.66479598033105
              ],
              [
                -74.00767434594512,
                40.66479110926572
              ],
              [
                -74.00772545192977,
                40.664742715391505
              ],
              [
                -74.00772133506703,
                40.664718633019774
              ],
              [
                -74.00766379098464,
                40.66468352836631
              ],
              [
                -74.00743251429036,
                40.66454244712888
              ],
              [
                -74.00672835016054,
                40.66411875492339
              ],
              [
                -74.00630567322514,
                40.66386341177632
              ],
              [
                -74.00592011069159,
                40.663642546278204
              ],
              [
                -74.00591847405597,
                40.66364195230821
              ],
              [
                -74.00591778279261,
                40.663641702056914
              ],
              [
                -74.00591638354516,
                40.66364119384898
              ],
              [
                -74.0059083596534,
                40.66363828363011
              ],
              [
                -74.00561348681754,
                40.663458390254746
              ],
              [
                -74.00562053152801,
                40.66345171626562
              ],
              [
                -74.00562218007458,
                40.66345015549427
              ],
              [
                -74.00562415155503,
                40.663448288968524
              ],
              [
                -74.0056996374778,
                40.66337678866095
              ],
              [
                -74.00579501836201,
                40.66343615129344
              ],
              [
                -74.00599748682693,
                40.6632586108286
              ],
              [
                -74.00565964975691,
                40.66305585356363
              ],
              [
                -74.00538014413911,
                40.66288392645275
              ],
              [
                -74.00518629798088,
                40.663069601447944
              ],
              [
                -74.00524863034876,
                40.6631056204914
              ],
              [
                -74.00523151018425,
                40.663118469663786
              ],
              [
                -74.00524384971581,
                40.66315519090432
              ],
              [
                -74.00520105129699,
                40.66319371088481
              ],
              [
                -74.00520010976784,
                40.66319455962422
              ],
              [
                -74.00519907606538,
                40.663195487943625
              ],
              [
                -74.00519747590563,
                40.663196929594314
              ],
              [
                -74.0051928192948,
                40.66320112051759
              ],
              [
                -74.00519233775076,
                40.66320155309654
              ],
              [
                -74.00497157758612,
                40.66306821324205
              ],
              [
                -74.00497510679921,
                40.66306522790805
              ],
              [
                -74.00497796703885,
                40.66306280800986
              ],
              [
                -74.00497850424118,
                40.66306235398571
              ],
              [
                -74.00497993436086,
                40.66306114236133
              ],
              [
                -74.0049801050689,
                40.663060997442216
              ],
              [
                -74.00506160816833,
                40.662992041202074
              ],
              [
                -74.00515599719328,
                40.66304741456384
              ],
              [
                -74.00535812864251,
                40.66286912973171
              ],
              [
                -74.00523840365585,
                40.662799535903716
              ],
              [
                -74.00473298121766,
                40.66249650399671
              ],
              [
                -74.00454367850804,
                40.66268014611806
              ],
              [
                -74.00460574305602,
                40.66271888517174
              ],
              [
                -74.00458994364888,
                40.66273373280542
              ],
              [
                -74.00460158473533,
                40.66276772310288
              ],
              [
                -74.00456179244867,
                40.66280926644005
              ],
              [
                -74.00455994636002,
                40.66281119107494
              ],
              [
                -74.00455948505812,
                40.66281167474653
              ],
              [
                -74.00455892564403,
                40.66281225944144
              ],
              [
                -74.00455523060684,
                40.66281611490985
              ],
              [
                -74.00455498488702,
                40.662816372912665
              ],
              [
                -74.00446593631067,
                40.66276195241859
              ],
              [
                -74.00438799950993,
                40.66271432134197
              ],
              [
                -74.00436556756048,
                40.662735676655466
              ],
              [
                -74.00423930501728,
                40.662657963459665
              ],
              [
                -74.00389334189984,
                40.662469174469116
              ],
              [
                -74.0034880110065,
                40.662240556128495
              ],
              [
                -74.0038081547088,
                40.66190794572821
              ],
              [
                -74.00383146963537,
                40.66192521575294
              ],
              [
                -74.00389812942463,
                40.66196780583779
              ],
              [
                -74.00391334571941,
                40.66197752730737
              ],
              [
                -74.00422549172723,
                40.66217696122081
              ],
              [
                -74.0046014637945,
                40.66238266851942
              ],
              [
                -74.00545775556647,
                40.662861366658554
              ],
              [
                -74.00592360503022,
                40.66310825073907
              ],
              [
                -74.00592802062332,
                40.663103306592866
              ],
              [
                -74.00592904571474,
                40.66310215813548
              ],
              [
                -74.0060895834048,
                40.662922401919324
              ],
              [
                -74.00622501390112,
                40.6627897570525
              ],
              [
                -74.00622684103384,
                40.66278796674929
              ],
              [
                -74.00622698247925,
                40.66278782786099
              ],
              [
                -74.00620046233034,
                40.662772526112654
              ],
              [
                -74.00603815112555,
                40.66267887134558
              ],
              [
                -74.00585857414514,
                40.66257525534364
              ],
              [
                -74.00585795416232,
                40.66257489686527
              ],
              [
                -74.00585740199432,
                40.66257517029897
              ],
              [
                -74.0058271433905,
                40.66259010896831
              ],
              [
                -74.00578486636623,
                40.66258252945452
              ],
              [
                -74.00562039670646,
                40.662493926346734
              ],
              [
                -74.00526589544603,
                40.66227016556557
              ],
              [
                -74.00514101962978,
                40.66218444025922
              ],
              [
                -74.00512747767237,
                40.66215126964576
              ],
              [
                -74.00512674648978,
                40.66214947846878
              ],
              [
                -74.00512658939383,
                40.662149228691625
              ],
              [
                -74.00512364857573,
                40.662144558308924
              ],
              [
                -74.00495172594644,
                40.66204178153433
              ],
              [
                -74.00476870023624,
                40.661932364821745
              ],
              [
                -74.00476182862151,
                40.66192817874855
              ],
              [
                -74.00475221025457,
                40.661922320188744
              ],
              [
                -74.0047227489117,
                40.66190549639521
              ],
              [
                -74.00470164001472,
                40.661893441105654
              ],
              [
                -74.00451173232295,
                40.661783157976004
              ],
              [
                -74.0043958054613,
                40.66171289450889
              ],
              [
                -74.0042788006124,
                40.6616419766
              ],
              [
                -74.00426191059573,
                40.66163174629029
              ],
              [
                -74.00426187385499,
                40.661631718314474
              ],
              [
                -74.00425415353574,
                40.66162703820218
              ],
              [
                -74.00424788611218,
                40.66162323856307
              ],
              [
                -74.00414624892207,
                40.66156163568131
              ],
              [
                -74.00418545737621,
                40.66152830606739
              ],
              [
                -74.00428358250115,
                40.66144489410127
              ],
              [
                -74.00553501251856,
                40.6621838587469
              ],
              [
                -74.00568594756898,
                40.66227474036048
              ],
              [
                -74.00616141136321,
                40.661845068990566
              ],
              [
                -74.00868371591297,
                40.66337201284023
              ],
              [
                -74.00955074142026,
                40.66326769413041
              ],
              [
                -74.01046331724483,
                40.66264211979001
              ],
              [
                -74.0103720129723,
                40.66239888023248
              ],
              [
                -74.00745858163884,
                40.66055847620987
              ],
              [
                -74.00778095960338,
                40.6602653283396
              ],
              [
                -74.00857262378977,
                40.659545427765174
              ],
              [
                -74.00859956968283,
                40.65952092309828
              ],
              [
                -74.01023484431792,
                40.660461187053585
              ],
              [
                -74.012653588115,
                40.66198165403757
              ],
              [
                -74.0131555493881,
                40.66198159772593
              ],
              [
                -74.01402247678024,
                40.66152974744006
              ],
              [
                -74.011107504103,
                40.65970627458062
              ],
              [
                -74.01142219567053,
                40.659328694961694
              ],
              [
                -74.01183862056543,
                40.65891283727472
              ],
              [
                -74.01246851441822,
                40.65931636814533
              ],
              [
                -74.01366273992599,
                40.66002878063692
              ],
              [
                -74.01400428392415,
                40.6598119634401
              ],
              [
                -74.01554165388997,
                40.66075835547614
              ],
              [
                -74.01745803087898,
                40.65945019738672
              ],
              [
                -74.01442130484548,
                40.65760802195137
              ],
              [
                -74.0150473822185,
                40.65695038669341
              ],
              [
                -74.01750516410576,
                40.65827237429761
              ],
              [
                -74.01748620434715,
                40.65833740924325
              ],
              [
                -74.01768549175459,
                40.658452992761205
              ],
              [
                -74.01786573109668,
                40.65830844729852
              ],
              [
                -74.0176664440951,
                40.658185638771535
              ],
              [
                -74.01774232727499,
                40.658098916724896
              ],
              [
                -74.01543632269644,
                40.65663239675965
              ],
              [
                -74.01585370056823,
                40.65621324055535
              ],
              [
                -74.01860569460999,
                40.65784587402284
              ],
              [
                -74.01890923625237,
                40.65754956446421
              ],
              [
                -74.01910843803118,
                40.657368884303494
              ],
              [
                -74.01639437533773,
                40.65562064285072
              ],
              [
                -74.01665047959762,
                40.65533879657213
              ],
              [
                -74.01691617851066,
                40.655483274957746
              ],
              [
                -74.01726715936593,
                40.65519418798652
              ],
              [
                -74.01970596410222,
                40.65658116375104
              ],
              [
                -74.01980081651999,
                40.65648721058464
              ],
              [
                -74.01741891331395,
                40.65499184067692
              ],
              [
                -74.01746633453757,
                40.65492680139873
              ],
              [
                -74.01715319581021,
                40.65477510436213
              ],
              [
                -74.01787407223816,
                40.65406150233079
              ],
              [
                -74.02005182454292,
                40.65533594887443
              ],
              [
                -74.02025589052923,
                40.65511826288116
              ],
              [
                -74.01811897876473,
                40.653885271105345
              ],
              [
                -74.0186223830857,
                40.653491349022964
              ],
              [
                -74.01872686588689,
                40.653474310524345
              ],
              [
                -74.01877188306482,
                40.65353713731395
              ],
              [
                -74.01885441729473,
                40.65365136547353
              ],
              [
                -74.01892195088296,
                40.653765597716685
              ],
              [
                -74.01896698068877,
                40.65386840871225
              ],
              [
                -74.01904948304211,
                40.65386839507754
              ],
              [
                -74.01917698964483,
                40.65388550950905
              ],
              [
                -74.01924450754501,
                40.65394262048367
              ],
              [
                -74.01919203025426,
                40.654022598494116
              ],
              [
                -74.01925203374113,
                40.65403401221275
              ],
              [
                -74.01941702844238,
                40.653999712567035
              ],
              [
                -74.01961958177243,
                40.654165329052276
              ],
              [
                -74.02002459222682,
                40.65416525925292
              ],
              [
                -74.02024211371031,
                40.6542223434268
              ],
              [
                -74.0204746182018,
                40.654216589227104
              ],
              [
                -74.02060214479563,
                40.65429082432437
              ],
              [
                -74.02060218183952,
                40.65441077951642
              ],
              [
                -74.0206396915899,
                40.654439333329535
              ],
              [
                -74.020894697976,
                40.654433575413506
              ],
              [
                -74.02164486415387,
                40.6548789833825
              ],
              [
                -74.02182479745494,
                40.65465617578545
              ],
              [
                -74.02114963484483,
                40.65421075521156
              ],
              [
                -74.02123961069387,
                40.654130768888116
              ],
              [
                -74.02128455998411,
                40.6539651085465
              ],
              [
                -74.02162946295488,
                40.65364516521604
              ],
              [
                -74.02187693558206,
                40.65354801237162
              ],
              [
                -74.02193694561598,
                40.653576560979964
              ],
              [
                -74.02210195162446,
                40.65358795380762
              ],
              [
                -74.02306219089226,
                40.65423323629193
              ],
              [
                -74.02320464727141,
                40.654096116817925
              ],
              [
                -74.02118670027885,
                40.65283983689534
              ],
              [
                -74.02123165456221,
                40.652697024715714
              ],
              [
                -74.02162153367613,
                40.65231995129057
              ],
              [
                -74.02171151888278,
                40.65227423817064
              ],
              [
                -74.02186900073937,
                40.6522227991187
              ],
              [
                -74.02195900090592,
                40.65222278197071
              ],
              [
                -74.02285167504505,
                40.652742411591454
              ],
              [
                -74.02288920245815,
                40.652822373885705
              ],
              [
                -74.0229417228344,
                40.652879484508205
              ],
              [
                -74.0230992368153,
                40.652919438445565
              ],
              [
                -74.0233317465082,
                40.652942240183236
              ],
              [
                -74.02353430165581,
                40.653096426047235
              ],
              [
                -74.02359434497556,
                40.65321636896179
              ],
              [
                -74.02380703903121,
                40.653044728511475
              ],
              [
                -74.02089862870834,
                40.65124727521829
              ],
              [
                -74.02098261225858,
                40.65115682265769
              ],
              [
                -74.02126416505618,
                40.65132571010398
              ],
              [
                -74.02225173199704,
                40.65198160150678
              ],
              [
                -74.0223373466683,
                40.6519106053816
              ],
              [
                -74.02134474360493,
                40.65126047090845
              ],
              [
                -74.02105230392205,
                40.65109828292773
              ],
              [
                -74.02112707783823,
                40.65094321211545
              ],
              [
                -74.02118521368588,
                40.650977634170616
              ],
              [
                -74.02124332438399,
                40.65092597432825
              ],
              [
                -74.0213595992163,
                40.650997278610895
              ],
              [
                -74.02139188210663,
                40.65096775798842
              ],
              [
                -74.02127560550258,
                40.6508915349418
              ],
              [
                -74.0212756487398,
                40.65086157837139
              ],
              [
                -74.0212465237341,
                40.65083497256432
              ],
              [
                -74.02128163421048,
                40.65081734293942
              ],
              [
                -74.02134461287908,
                40.65085186118476
              ],
              [
                -74.02154138319214,
                40.650671622911226
              ],
              [
                -74.02262161936135,
                40.65131155867345
              ],
              [
                -74.02272372795771,
                40.6512973227688
              ],
              [
                -74.02522662972656,
                40.65282985695965
              ],
              [
                -74.02536890150509,
                40.65272143692734
              ],
              [
                -74.02285141795983,
                40.651169195161685
              ],
              [
                -74.02302012112835,
                40.651006388235146
              ],
              [
                -74.02302464837881,
                40.65100273789372
              ],
              [
                -74.02305991047025,
                40.65097430413476
              ],
              [
                -74.02359400295185,
                40.65131566325373
              ],
              [
                -74.02365443141966,
                40.651256181836324
              ],
              [
                -74.02382347395064,
                40.65135378491313
              ],
              [
                -74.02417752973396,
                40.65119177321221
              ],
              [
                -74.02541604028593,
                40.651933806855105
              ],
              [
                -74.02550139045734,
                40.65183985103419
              ],
              [
                -74.02424021347534,
                40.65113351631333
              ],
              [
                -74.02427173047927,
                40.65110251074393
              ],
              [
                -74.02428919364958,
                40.65111207358974
              ],
              [
                -74.02446077599458,
                40.65094460933545
              ],
              [
                -74.02448801174862,
                40.65091837716099
              ],
              [
                -74.02452328055399,
                40.65093755347269
              ],
              [
                -74.02477160495674,
                40.65068770743539
              ],
              [
                -74.0251025719023,
                40.65089330557687
              ],
              [
                -74.02507739284502,
                40.65091441292713
              ],
              [
                -74.02508999105069,
                40.65092400267911
              ],
              [
                -74.02491122849032,
                40.65110628579329
              ],
              [
                -74.02552971543649,
                40.65147855069281
              ],
              [
                -74.02569093920555,
                40.65131954691688
              ],
              [
                -74.02577633161965,
                40.651333977958664
              ],
              [
                -74.02592807625282,
                40.651196653120266
              ],
              [
                -74.02587113764648,
                40.651160535797345
              ],
              [
                -74.02605133196793,
                40.650994300743115
              ],
              [
                -74.02541553929463,
                40.65061869708251
              ],
              [
                -74.02520581187575,
                40.650818467899384
              ],
              [
                -74.02484436363444,
                40.650599638057066
              ],
              [
                -74.02553864578033,
                40.64978215934805
              ],
              [
                -74.02496792117127,
                40.64860596226747
              ],
              [
                -74.02512911955235,
                40.6483231814572
              ],
              [
                -74.02414566050564,
                40.64761510125985
              ],
              [
                -74.02435496936715,
                40.647399278153415
              ],
              [
                -74.02468390384902,
                40.64760566521819
              ],
              [
                -74.02488305491131,
                40.64741052302424
              ],
              [
                -74.02622102475573,
                40.64824842780925
              ],
              [
                -74.02624191290109,
                40.648226537818836
              ],
              [
                -74.02625506529243,
                40.64821273567891
              ],
              [
                -74.02629688858224,
                40.64816892652102
              ],
              [
                -74.02461487103105,
                40.647151125118796
              ],
              [
                -74.02467396344866,
                40.64709451887871
              ],
              [
                -74.02483957406781,
                40.64694442476538
              ],
              [
                -74.02516202606716,
                40.647138748494136
              ],
              [
                -74.02518511713897,
                40.647096327783544
              ],
              [
                -74.02552081269253,
                40.646755138147135
              ],
              [
                -74.02571758804807,
                40.64661549229094
              ],
              [
                -74.02919183069376,
                40.6486860167146
              ],
              [
                -74.02938990486619,
                40.64880405511393
              ],
              [
                -74.02974078211068,
                40.6484860262104
              ],
              [
                -74.02954714502879,
                40.64836933168592
              ],
              [
                -74.02604948400315,
                40.64626135032612
              ],
              [
                -74.02703434223355,
                40.64519782098331
              ],
              [
                -74.02775298186872,
                40.644506277021804
              ],
              [
                -74.02820569545007,
                40.64401598289035
              ],
              [
                -74.02845721095775,
                40.64415605924817
              ],
              [
                -74.0281905304931,
                40.64441433020631
              ],
              [
                -74.02823134309659,
                40.644438228837735
              ],
              [
                -74.02842543290458,
                40.644250724824936
              ],
              [
                -74.02850116250595,
                40.64417756470259
              ],
              [
                -74.02896958211079,
                40.644497461016805
              ],
              [
                -74.0290865428924,
                40.644583001799646
              ],
              [
                -74.02909142239668,
                40.64460058802813
              ],
              [
                -74.02882159751947,
                40.64484212565182
              ],
              [
                -74.02886241127031,
                40.644866023722976
              ],
              [
                -74.02915418994519,
                40.644581445419604
              ],
              [
                -74.02899762468758,
                40.64447196149297
              ],
              [
                -74.02908090885776,
                40.6444035969303
              ],
              [
                -74.03051830863413,
                40.645274425208974
              ],
              [
                -74.03159762607343,
                40.644202239849726
              ],
              [
                -74.03189708494334,
                40.64388934936981
              ],
              [
                -74.031978571573,
                40.643893380255875
              ],
              [
                -74.03220689841643,
                40.64402992889279
              ],
              [
                -74.03226870865812,
                40.64407437929251
              ],
              [
                -74.0323108423411,
                40.64403921448063
              ],
              [
                -74.03254173795665,
                40.64382548485451
              ],
              [
                -74.03246968787244,
                40.643786126334135
              ],
              [
                -74.03223524436774,
                40.64364337015536
              ],
              [
                -74.03231070598247,
                40.64357458661773
              ],
              [
                -74.03262055640175,
                40.64376540225932
              ],
              [
                -74.03266558998958,
                40.64372034489007
              ],
              [
                -74.03260644499996,
                40.64369891099996
              ],
              [
                -74.02905968899994,
                40.64172667299992
              ],
              [
                -74.02889666799992,
                40.64121402199986
              ],
              [
                -74.02890829899998,
                40.641173410999905
              ],
              [
                -74.02839277799991,
                40.64096313599989
              ],
              [
                -74.02802775299989,
                40.640793489999915
              ],
              [
                -74.02742711399993,
                40.640514336999956
              ],
              [
                -74.02703439399988,
                40.64034998599989
              ],
              [
                -74.02691981399988,
                40.640308507999926
              ],
              [
                -74.02674292499995,
                40.640244467999935
              ],
              [
                -74.02662323799991,
                40.6402011359999
              ],
              [
                -74.0262626739999,
                40.640094380999855
              ],
              [
                -74.02628611999998,
                40.64007452899989
              ],
              [
                -74.02635007599996,
                40.640018464999905
              ],
              [
                -74.02646434999998,
                40.639918292999916
              ],
              [
                -74.02659984399993,
                40.63978656099993
              ],
              [
                -74.02664251499989,
                40.63974743299988
              ],
              [
                -74.02686129399991,
                40.6395362909999
              ],
              [
                -74.02695244699993,
                40.63944606499998
              ],
              [
                -74.02475437599998,
                40.63812141799988
              ],
              [
                -74.0245384189999,
                40.63832493699994
              ],
              [
                -74.02417094699994,
                40.63866937099987
              ],
              [
                -74.02392610699991,
                40.63889885799994
              ],
              [
                -74.02354939199988,
                40.63932033399992
              ],
              [
                -74.02351787199997,
                40.63944477399987
              ],
              [
                -74.02348203699995,
                40.63953321299996
              ],
              [
                -74.02346821799988,
                40.63956733499988
              ],
              [
                -74.02340085399995,
                40.63968573199987
              ],
              [
                -74.02331682999991,
                40.63979778199991
              ],
              [
                -74.02321780199998,
                40.63990151499989
              ],
              [
                -74.02310593199999,
                40.63999532599994
              ],
              [
                -74.02259016799994,
                40.64043910599985
              ],
              [
                -74.02246253899987,
                40.64053889899986
              ],
              [
                -74.02228790899991,
                40.640638835999894
              ],
              [
                -74.02222562199997,
                40.640677822999855
              ],
              [
                -74.02180903299994,
                40.6409504089999
              ],
              [
                -74.02172675999999,
                40.641004941999945
              ],
              [
                -74.02152001099992,
                40.64115336799988
              ],
              [
                -74.02120118199991,
                40.64138693999995
              ],
              [
                -74.0211362599999,
                40.6414339679999
              ],
              [
                -74.02111136799995,
                40.64145200099991
              ],
              [
                -74.02108051399998,
                40.64149093699993
              ],
              [
                -74.0210580239999,
                40.64147598299995
              ],
              [
                -74.02108520199992,
                40.64144035399991
              ],
              [
                -74.0211051969999,
                40.6414141359999
              ],
              [
                -74.02114153299986,
                40.64136239499993
              ],
              [
                -74.02126975099995,
                40.64117350099991
              ],
              [
                -74.02147133099999,
                40.64082961299994
              ],
              [
                -74.02159214600002,
                40.64056920899986
              ],
              [
                -74.02161540399997,
                40.64050926299993
              ],
              [
                -74.02166136899989,
                40.64037438299987
              ],
              [
                -74.0217092389999,
                40.640200097999866
              ],
              [
                -74.02172617299989,
                40.64011331999995
              ],
              [
                -74.02176040899997,
                40.63982434599988
              ],
              [
                -74.02174189,
                40.639466291999945
              ],
              [
                -74.02173880499991,
                40.63944050699995
              ],
              [
                -74.02170856799994,
                40.63930090299994
              ],
              [
                -74.02170098699993,
                40.639265459999905
              ],
              [
                -74.02167777199992,
                40.63919125699989
              ],
              [
                -74.02164685099997,
                40.63909104799992
              ],
              [
                -74.0216086779999,
                40.63897677999994
              ],
              [
                -74.02154646799997,
                40.638830162999916
              ],
              [
                -74.02150876399996,
                40.638749071999925
              ],
              [
                -74.02141038399992,
                40.63858490299987
              ],
              [
                -74.02129372099995,
                40.638428241999854
              ],
              [
                -74.02116029799993,
                40.63828079799991
              ],
              [
                -74.021114445,
                40.638235535999904
              ],
              [
                -74.02107776199989,
                40.63820262199994
              ],
              [
                -74.02084657599987,
                40.63798335099993
              ],
              [
                -74.020598444,
                40.63777414199989
              ],
              [
                -74.02032883799993,
                40.63757296899992
              ],
              [
                -74.02002934999997,
                40.63737936599991
              ],
              [
                -74.01976495999995,
                40.63721743499991
              ],
              [
                -74.01962150999992,
                40.63713106399994
              ],
              [
                -74.01955410699993,
                40.63709122699992
              ],
              [
                -74.01899546799997,
                40.636754103999905
              ],
              [
                -74.01799939199995,
                40.63615792199988
              ],
              [
                -74.01755212599994,
                40.63588725099987
              ],
              [
                -74.01750323299989,
                40.635856163999904
              ],
              [
                -74.01727269499997,
                40.635695675999926
              ],
              [
                -74.01705196399993,
                40.63552755399995
              ],
              [
                -74.016835401,
                40.63535542899995
              ],
              [
                -74.01678445499995,
                40.63539802299989
              ],
              [
                -74.0166914329999,
                40.63551966099993
              ],
              [
                -74.0163054819999,
                40.63589567399986
              ],
              [
                -74.01606790799994,
                40.636138450999894
              ],
              [
                -74.01591339499994,
                40.63628606999988
              ],
              [
                -74.01573946799994,
                40.6364522369999
              ],
              [
                -74.01514575000002,
                40.63701460999994
              ],
              [
                -74.01456685499988,
                40.63757090799991
              ],
              [
                -74.0139608859999,
                40.63815326699995
              ],
              [
                -74.01616711499993,
                40.63948244099991
              ],
              [
                -74.01556266699994,
                40.64006663699991
              ],
              [
                -74.01497963599998,
                40.64062546799994
              ],
              [
                -74.01439632399995,
                40.64118583699995
              ],
              [
                -74.01381389999992,
                40.641745246999896
              ],
              [
                -74.0132320339999,
                40.64230518499987
              ],
              [
                -74.01264921199999,
                40.64286578799991
              ],
              [
                -74.01206572999999,
                40.6434251979999
              ],
              [
                -74.01148370499999,
                40.64398661299994
              ],
              [
                -74.01090222499987,
                40.64454699899989
              ],
              [
                -74.01031893899994,
                40.64510666299991
              ],
              [
                -74.00973537099993,
                40.64566683499987
              ],
              [
                -74.009152821,
                40.646226734999885
              ],
              [
                -74.00856800399991,
                40.64678864999995
              ],
              [
                -74.00798795299995,
                40.64734471499986
              ],
              [
                -74.00740505499991,
                40.64790502999992
              ],
              [
                -74.00682351799996,
                40.648464881999885
              ],
              [
                -74.00624091999991,
                40.649022425999874
              ],
              [
                -74.00566250199992,
                40.649578383999895
              ],
              [
                -74.00507350599995,
                40.65014666199991
              ],
              [
                -74.00449022899994,
                40.65070538499985
              ],
              [
                -74.00390815899992,
                40.651265968999915
              ],
              [
                -74.00332715599991,
                40.65182948699989
              ],
              [
                -74.00317064200001,
                40.65198024599987
              ],
              [
                -74.00275058799997,
                40.652384832999886
              ],
              [
                -74.00214474699993,
                40.65295610699995
              ],
              [
                -74.00159439599989,
                40.6534880839999
              ],
              [
                -74.00099519199993,
                40.65406723399986
              ],
              [
                -74.00041318199995,
                40.65462870199992
              ],
              [
                -73.99983786599982,
                40.655186157999935
              ],
              [
                -73.99925656999994,
                40.655745718999846
              ],
              [
                -73.99867041499995,
                40.65630686099993
              ],
              [
                -73.9980910029999,
                40.65686468799992
              ],
              [
                -73.99750144599993,
                40.65742847199991
              ],
              [
                -73.99691824099983,
                40.65798963299989
              ],
              [
                -73.99633560799997,
                40.658547215999896
              ],
              [
                -73.99575278599988,
                40.65910782999993
              ],
              [
                -73.99516890199999,
                40.659669521999945
              ],
              [
                -73.99296401999982,
                40.658337408999884
              ],
              [
                -73.99238339499986,
                40.65889901599994
              ],
              [
                -73.990188415,
                40.657600817999906
              ],
              [
                -73.98959238099985,
                40.658134060999956
              ],
              [
                -73.98901286599988,
                40.658690795999895
              ],
              [
                -73.98843135299977,
                40.65925121199996
              ],
              [
                -73.98784059399986,
                40.65981686399987
              ],
              [
                -73.98744982499997,
                40.660189572999926
              ],
              [
                -73.98731033299997,
                40.6603296239999
              ],
              [
                -73.98725068899996,
                40.660378955999924
              ],
              [
                -73.98729619199992,
                40.660441852999895
              ],
              [
                -73.98804398999984,
                40.661475435999925
              ],
              [
                -73.988716511,
                40.66240493799996
              ],
              [
                -73.9887680069999,
                40.66235596399985
              ],
              [
                -73.98888159799985,
                40.66224717499995
              ],
              [
                -73.98947000099999,
                40.661699040999956
              ],
              [
                -73.99005216500004,
                40.661138647999934
              ],
              [
                -73.99063545399997,
                40.6605790889999
              ],
              [
                -73.99156751399993,
                40.66114158699991
              ],
              [
                -73.99283830599987,
                40.66190848099995
              ],
              [
                -73.99502082499981,
                40.66320906699988
              ],
              [
                -73.99510907599998,
                40.66326211199989
              ],
              [
                -73.99521061899985,
                40.66332127999993
              ],
              [
                -73.99692689899985,
                40.66437491499994
              ],
              [
                -73.9963724839999,
                40.664961908999885
              ],
              [
                -73.99605311599986,
                40.665285165999876
              ],
              [
                -73.99593232999982,
                40.66540742499992
              ],
              [
                -73.99583369399984,
                40.665507261999885
              ],
              [
                -73.995810253,
                40.66553098899993
              ],
              [
                -73.99595866299985,
                40.66570454899987
              ],
              [
                -73.9958739449999,
                40.66573228499993
              ],
              [
                -73.995759574,
                40.66578388799989
              ],
              [
                -73.99530800399985,
                40.66615799899995
              ],
              [
                -73.9951247889999,
                40.6663813549999
              ],
              [
                -73.99502872600004,
                40.666515284999925
              ],
              [
                -73.99498642699994,
                40.66662582999986
              ],
              [
                -73.99496779799995,
                40.66666374099989
              ],
              [
                -73.99494918099985,
                40.66670164299991
              ],
              [
                -73.99490350099988,
                40.66678543499993
              ],
              [
                -73.99507117699983,
                40.66707573199994
              ],
              [
                -73.99585129199981,
                40.667972495999884
              ],
              [
                -73.99616566099998,
                40.668435497999944
              ],
              [
                -73.99648142699999,
                40.668925235999936
              ],
              [
                -73.99659742999994,
                40.668811927999855
              ],
              [
                -73.99667191699996,
                40.66873916799992
              ],
              [
                -73.9969179149999,
                40.669153483999914
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0000947058230931,
        "objectid": 229,
        "shape_leng": 0.0425252900116,
        "location_id": 229,
        "zone": "Sutton Place/Turtle Bay North",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.96162927534907,
                40.75515238425916
              ],
              [
                -73.96102790867172,
                40.75561058457904
              ],
              [
                -73.96079722985938,
                40.75580465585206
              ],
              [
                -73.96079709914974,
                40.75580477274058
              ],
              [
                -73.96065638180474,
                40.75592314955019
              ],
              [
                -73.96036455140813,
                40.75626941113339
              ],
              [
                -73.96003900990611,
                40.75672535023881
              ],
              [
                -73.95966400300107,
                40.75721141218687
              ],
              [
                -73.95965770876651,
                40.75726014512322
              ],
              [
                -73.95918103219987,
                40.757856111052256
              ],
              [
                -73.95917264248828,
                40.757864780393994
              ],
              [
                -73.959070030312,
                40.757939098020366
              ],
              [
                -73.95877790811335,
                40.75827092092435
              ],
              [
                -73.95893448599985,
                40.75833515499994
              ],
              [
                -73.95903681099995,
                40.75838378299988
              ],
              [
                -73.95972180399991,
                40.75866306399995
              ],
              [
                -73.96196573699997,
                40.75961946099988
              ],
              [
                -73.96308073599987,
                40.760083162999884
              ],
              [
                -73.96355618899993,
                40.76028081899991
              ],
              [
                -73.96431353599988,
                40.760599230999944
              ],
              [
                -73.9665833839999,
                40.76155093499988
              ],
              [
                -73.96657146999985,
                40.761481879999955
              ],
              [
                -73.96699471899981,
                40.76090538299993
              ],
              [
                -73.96746215199991,
                40.76022290599994
              ],
              [
                -73.96792193499995,
                40.75959308699991
              ],
              [
                -73.96802436899996,
                40.759578139999896
              ],
              [
                -73.96847979599983,
                40.75895012799988
              ],
              [
                -73.96893510299992,
                40.75833009199994
              ],
              [
                -73.96939411299984,
                40.75769952799991
              ],
              [
                -73.96984859899989,
                40.75707595799987
              ],
              [
                -73.97031393599993,
                40.75645301899989
              ],
              [
                -73.97075919399998,
                40.7558248509999
              ],
              [
                -73.9712170449999,
                40.75519332399994
              ],
              [
                -73.96897803700001,
                40.75425053499993
              ],
              [
                -73.96661581800002,
                40.75324342499995
              ],
              [
                -73.96665543199994,
                40.75318878499998
              ],
              [
                -73.96677887099986,
                40.7530187869999
              ],
              [
                -73.96694827399996,
                40.752785487999944
              ],
              [
                -73.967067339,
                40.752620674999946
              ],
              [
                -73.96701854999988,
                40.75260074199987
              ],
              [
                -73.96688157199996,
                40.75254455599992
              ],
              [
                -73.96547092299994,
                40.75193959699992
              ],
              [
                -73.96532415299987,
                40.751930897999934
              ],
              [
                -73.96530480400006,
                40.75193187399988
              ],
              [
                -73.96518524199982,
                40.75195230699995
              ],
              [
                -73.96521537099981,
                40.75180845999994
              ],
              [
                -73.96505548699992,
                40.75181258099991
              ],
              [
                -73.96495708499998,
                40.751743445999885
              ],
              [
                -73.96485451699984,
                40.75169675999992
              ],
              [
                -73.96482214210387,
                40.75159632579114
              ],
              [
                -73.96426352137193,
                40.75220690108016
              ],
              [
                -73.96373572283164,
                40.75278377577701
              ],
              [
                -73.96316074049744,
                40.753412207328125
              ],
              [
                -73.9628689676921,
                40.75374250915685
              ],
              [
                -73.96289729202535,
                40.75376551709425
              ],
              [
                -73.96268703046536,
                40.75400437328663
              ],
              [
                -73.9622312516558,
                40.75452213042181
              ],
              [
                -73.96219381838414,
                40.75450431515319
              ],
              [
                -73.96162927534907,
                40.75515238425916
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0000560914463266,
        "objectid": 230,
        "shape_leng": 0.0310283096779,
        "location_id": 230,
        "zone": "Times Sq/Theatre District",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.98362433299988,
                40.75551634599986
              ],
              [
                -73.98316795999989,
                40.75614022199993
              ],
              [
                -73.98271205299996,
                40.75677122899993
              ],
              [
                -73.98225528700006,
                40.75738736499995
              ],
              [
                -73.98179404299992,
                40.75802130299997
              ],
              [
                -73.98134106999997,
                40.75864529899995
              ],
              [
                -73.98088574599998,
                40.75927066899989
              ],
              [
                -73.98042606399994,
                40.75989908099994
              ],
              [
                -73.97997188199987,
                40.76052470099987
              ],
              [
                -73.97951319199996,
                40.761148561999924
              ],
              [
                -73.97905731199987,
                40.761770719999944
              ],
              [
                -73.97860169399989,
                40.76239607199995
              ],
              [
                -73.98143351999995,
                40.763594359999914
              ],
              [
                -73.9824697079998,
                40.76403346399993
              ],
              [
                -73.98428230099987,
                40.76479181499995
              ],
              [
                -73.98473393799985,
                40.76416910499991
              ],
              [
                -73.98519152199997,
                40.76354367499996
              ],
              [
                -73.98564677799999,
                40.76291487399988
              ],
              [
                -73.98610406099989,
                40.76228995299988
              ],
              [
                -73.98656138499996,
                40.76166012199991
              ],
              [
                -73.98701514599995,
                40.76103736699991
              ],
              [
                -73.98747524599992,
                40.7604047869999
              ],
              [
                -73.9879264059999,
                40.75979027699986
              ],
              [
                -73.98839044599993,
                40.75916418599997
              ],
              [
                -73.98884253899993,
                40.75853349899993
              ],
              [
                -73.98929727499997,
                40.757906637999916
              ],
              [
                -73.98979085399995,
                40.75723313199991
              ],
              [
                -73.98841835499996,
                40.7566549869999
              ],
              [
                -73.98694873899993,
                40.756035901999915
              ],
              [
                -73.98640436699999,
                40.755808028999866
              ],
              [
                -73.98411754799999,
                40.754842052999955
              ],
              [
                -73.98362433299988,
                40.75551634599986
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000166929752992,
        "objectid": 231,
        "shape_leng": 0.0634201165587,
        "location_id": 231,
        "zone": "TriBeCa/Civic Center",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -74.00931760399995,
                40.72470155099989
              ],
              [
                -74.01004078699994,
                40.725206162999925
              ],
              [
                -74.0108123829999,
                40.725789802999955
              ],
              [
                -74.01092277299992,
                40.72579415099991
              ],
              [
                -74.01102457999994,
                40.72579386199993
              ],
              [
                -74.01103681999996,
                40.72572537699997
              ],
              [
                -74.011176661,
                40.725169424999905
              ],
              [
                -74.01135428299995,
                40.724277182999884
              ],
              [
                -74.01143933,
                40.72428446899996
              ],
              [
                -74.01159122599992,
                40.724297603999936
              ],
              [
                -74.01196432024551,
                40.72432986411989
              ],
              [
                -74.01209463829593,
                40.72370705248107
              ],
              [
                -74.01223080840143,
                40.72305625707263
              ],
              [
                -74.01237071715381,
                40.722387580578996
              ],
              [
                -74.0124917103152,
                40.72177975782742
              ],
              [
                -74.01253875807251,
                40.721503176576356
              ],
              [
                -74.01300955803927,
                40.721560486512075
              ],
              [
                -74.01304353513854,
                40.72154472392732
              ],
              [
                -74.01307805009768,
                40.72154553033896
              ],
              [
                -74.01308010145627,
                40.721545807195234
              ],
              [
                -74.01308347695151,
                40.721528774532736
              ],
              [
                -74.01313846153448,
                40.72125131813748
              ],
              [
                -74.01315895020863,
                40.72125326078352
              ],
              [
                -74.01323937337015,
                40.72088798672807
              ],
              [
                -74.01286909071982,
                40.720833752211725
              ],
              [
                -74.01296558526825,
                40.720328675587126
              ],
              [
                -74.01350069470506,
                40.720401861823426
              ],
              [
                -74.01350724376601,
                40.720362832840436
              ],
              [
                -74.01355378315908,
                40.720368726324615
              ],
              [
                -74.01354841723077,
                40.72040685437942
              ],
              [
                -74.01363580332405,
                40.72041955013261
              ],
              [
                -74.01364192748738,
                40.720383366299956
              ],
              [
                -74.0136874483683,
                40.72039057527121
              ],
              [
                -74.013681739107,
                40.720425901487225
              ],
              [
                -74.01385921064892,
                40.72044993375784
              ],
              [
                -74.01386714357214,
                40.72040757231251
              ],
              [
                -74.01396609722485,
                40.72041363453815
              ],
              [
                -74.01396301313083,
                40.72046444645889
              ],
              [
                -74.01400416755592,
                40.720468520737754
              ],
              [
                -74.01407954419396,
                40.72047331152247
              ],
              [
                -74.01409095949832,
                40.720422747859125
              ],
              [
                -74.01415861254227,
                40.720431590837414
              ],
              [
                -74.01414732641814,
                40.720481589088564
              ],
              [
                -74.01418581466784,
                40.72048801202073
              ],
              [
                -74.01452278990024,
                40.72053205032778
              ],
              [
                -74.01452586453652,
                40.72051656945924
              ],
              [
                -74.01451968588725,
                40.72051578688329
              ],
              [
                -74.01457231438083,
                40.72027413774219
              ],
              [
                -74.01450706139606,
                40.720265906177275
              ],
              [
                -74.01452415856508,
                40.72018739744569
              ],
              [
                -74.01396546195676,
                40.72011613349159
              ],
              [
                -74.01338906903435,
                40.72004260727321
              ],
              [
                -74.01344694115667,
                40.71978202079137
              ],
              [
                -74.01302432551543,
                40.71972559759596
              ],
              [
                -74.01302616790717,
                40.71970920840729
              ],
              [
                -74.01294583743953,
                40.71969680842214
              ],
              [
                -74.01294460912554,
                40.719713197540514
              ],
              [
                -74.0129144874633,
                40.71970829322538
              ],
              [
                -74.01296083955079,
                40.71948393527159
              ],
              [
                -74.01295868239187,
                40.71947369644304
              ],
              [
                -74.01301259080437,
                40.71921511757715
              ],
              [
                -74.01303068113208,
                40.71913145590955
              ],
              [
                -74.01270167899996,
                40.71909177499987
              ],
              [
                -74.0125546879999,
                40.719069862999966
              ],
              [
                -74.01244109199989,
                40.71905767299989
              ],
              [
                -74.01281566799992,
                40.71735629799987
              ],
              [
                -74.0128666309999,
                40.717176100999886
              ],
              [
                -74.0130514639999,
                40.71645477099989
              ],
              [
                -74.01314862399995,
                40.716029349999886
              ],
              [
                -74.01323384899992,
                40.715656187999855
              ],
              [
                -74.0133512149999,
                40.71523331199993
              ],
              [
                -74.01347524299992,
                40.715110711999905
              ],
              [
                -74.01359239999988,
                40.71449431699989
              ],
              [
                -74.01375388499997,
                40.71369055499988
              ],
              [
                -74.01359729999992,
                40.7136233779999
              ],
              [
                -74.01254378499995,
                40.713179103999934
              ],
              [
                -74.01166334799997,
                40.71279805599988
              ],
              [
                -74.01012167499995,
                40.71208469599989
              ],
              [
                -74.00883879499997,
                40.71150395899991
              ],
              [
                -74.00860085399992,
                40.711392239999945
              ],
              [
                -74.00834760999993,
                40.71135854599988
              ],
              [
                -74.00796787799999,
                40.71144733199986
              ],
              [
                -74.00746749399993,
                40.71156690299989
              ],
              [
                -74.00738921499999,
                40.71164226999986
              ],
              [
                -74.00698584099997,
                40.71173749899987
              ],
              [
                -74.00607752399999,
                40.7119623529999
              ],
              [
                -74.00589174999999,
                40.71200715999993
              ],
              [
                -74.00574044699991,
                40.71208875699989
              ],
              [
                -74.00558932999998,
                40.712170053999905
              ],
              [
                -74.00543047899994,
                40.712203694999914
              ],
              [
                -74.00527561999998,
                40.71224943999993
              ],
              [
                -74.00512712599992,
                40.71230677399993
              ],
              [
                -74.00498716699994,
                40.712374856999894
              ],
              [
                -74.00485768099996,
                40.7124525409999
              ],
              [
                -74.00470652299994,
                40.71257401499987
              ],
              [
                -74.00429113899996,
                40.71290782599993
              ],
              [
                -74.00421405399993,
                40.712980742999896
              ],
              [
                -74.004059007,
                40.71313478999985
              ],
              [
                -74.00360280399998,
                40.71364542999989
              ],
              [
                -74.00321983099997,
                40.714036236999895
              ],
              [
                -74.00272286199994,
                40.71438970899991
              ],
              [
                -74.002453932,
                40.71478981999997
              ],
              [
                -74.00211455799992,
                40.715158909999936
              ],
              [
                -74.00168306199991,
                40.71574412299987
              ],
              [
                -74.00081842499998,
                40.71692970799994
              ],
              [
                -74.00027443699997,
                40.71761940199987
              ],
              [
                -73.999956209,
                40.71801709499989
              ],
              [
                -74.00053195499999,
                40.71843023899987
              ],
              [
                -74.00127273399993,
                40.71896142599997
              ],
              [
                -74.00140055699995,
                40.719052620999896
              ],
              [
                -74.00188406299992,
                40.71939757099991
              ],
              [
                -74.00260001899987,
                40.719903425999874
              ],
              [
                -74.0032595509999,
                40.72037709599996
              ],
              [
                -74.00351186399998,
                40.7205563519999
              ],
              [
                -74.00392928399991,
                40.72085443499993
              ],
              [
                -74.00463516199997,
                40.721353632999865
              ],
              [
                -74.00522684899992,
                40.72174422199989
              ],
              [
                -74.0053098609999,
                40.72182419199987
              ],
              [
                -74.00591289899992,
                40.7222529489999
              ],
              [
                -74.00629841699995,
                40.72254950399988
              ],
              [
                -74.00762904199998,
                40.72349770399995
              ],
              [
                -74.00791205999997,
                40.72369976699988
              ],
              [
                -74.00807835299996,
                40.72382686299988
              ],
              [
                -74.00830216299991,
                40.72397780899997
              ],
              [
                -74.00857090999989,
                40.724169354999894
              ],
              [
                -74.00864380599997,
                40.72422131699991
              ],
              [
                -74.00931760399995,
                40.72470155099989
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000204715440774,
        "objectid": 239,
        "shape_leng": 0.0636261152958,
        "location_id": 239,
        "zone": "Upper West Side South",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.97501417199996,
                40.78768560599987
              ],
              [
                -73.97639149199995,
                40.78826674699993
              ],
              [
                -73.97650149399988,
                40.788312676999944
              ],
              [
                -73.97660504199992,
                40.78835448099992
              ],
              [
                -73.97785649899998,
                40.78888153899993
              ],
              [
                -73.98035659399986,
                40.78994055799996
              ],
              [
                -73.98097150799998,
                40.79016087799991
              ],
              [
                -73.9815945909999,
                40.79038412399996
              ],
              [
                -73.98169328900003,
                40.79041872799998
              ],
              [
                -73.98176573099992,
                40.790455425999916
              ],
              [
                -73.98185980499981,
                40.79050307799984
              ],
              [
                -73.98188645923244,
                40.7905165804675
              ],
              [
                -73.98234664147557,
                40.78985478127526
              ],
              [
                -73.98371551265213,
                40.78788609007548
              ],
              [
                -73.98461942858404,
                40.786586016349794
              ],
              [
                -73.98513520970305,
                40.786587618895574
              ],
              [
                -73.98581237352651,
                40.786616865357146
              ],
              [
                -73.98594956155647,
                40.786487113966515
              ],
              [
                -73.98594285499509,
                40.786452847880334
              ],
              [
                -73.98589227228332,
                40.78642652901957
              ],
              [
                -73.98574378790082,
                40.78657008285482
              ],
              [
                -73.98465507883023,
                40.78653474180792
              ],
              [
                -73.98493597820348,
                40.78613072065099
              ],
              [
                -73.98501696406503,
                40.78601423719574
              ],
              [
                -73.98513163631195,
                40.786060297020015
              ],
              [
                -73.98516263994709,
                40.78602099976972
              ],
              [
                -73.98521103560738,
                40.78603955488364
              ],
              [
                -73.98520511880015,
                40.786047669212834
              ],
              [
                -73.98561523764441,
                40.78621964571528
              ],
              [
                -73.98567072256466,
                40.78624291199381
              ],
              [
                -73.9856362709302,
                40.78629015064923
              ],
              [
                -73.98568287192252,
                40.78630978621312
              ],
              [
                -73.98576566029756,
                40.78619627485871
              ],
              [
                -73.98571752900456,
                40.78617599466883
              ],
              [
                -73.98568388524194,
                40.78622212391976
              ],
              [
                -73.98524003288435,
                40.78603585813679
              ],
              [
                -73.98528177918678,
                40.78597862095007
              ],
              [
                -73.98524962933016,
                40.78596313985584
              ],
              [
                -73.98524273937646,
                40.78597257265341
              ],
              [
                -73.98525509798002,
                40.78597620551154
              ],
              [
                -73.98521621867359,
                40.78603050181638
              ],
              [
                -73.98517050238983,
                40.78601333415815
              ],
              [
                -73.98519883325459,
                40.78596655219782
              ],
              [
                -73.9850811377319,
                40.78592193511045
              ],
              [
                -73.98542932126932,
                40.78541394218458
              ],
              [
                -73.98609763784941,
                40.786058225697985
              ],
              [
                -73.98602727478905,
                40.78622896423677
              ],
              [
                -73.98607113521952,
                40.78624070602664
              ],
              [
                -73.98612842248579,
                40.7862390013311
              ],
              [
                -73.98616462880621,
                40.786121882448334
              ],
              [
                -73.98649778102364,
                40.78595120288722
              ],
              [
                -73.98711901394245,
                40.7852103190041
              ],
              [
                -73.98707234561537,
                40.78518963882012
              ],
              [
                -73.98645586240188,
                40.785919219081364
              ],
              [
                -73.98617270496534,
                40.78606845225865
              ],
              [
                -73.98546581197031,
                40.78536070057545
              ],
              [
                -73.98561580396364,
                40.78514186259512
              ],
              [
                -73.9861137272529,
                40.78443891237993
              ],
              [
                -73.98625187003549,
                40.78423876424542
              ],
              [
                -73.98647480368582,
                40.783916573718756
              ],
              [
                -73.98787728724993,
                40.78189205133479
              ],
              [
                -73.98791968459248,
                40.78183347771319
              ],
              [
                -73.98796079284213,
                40.781770987031535
              ],
              [
                -73.98801805997222,
                40.78163418881039
              ],
              [
                -73.98807644914505,
                40.78165093500165
              ],
              [
                -73.98810029382456,
                40.78160287830525
              ],
              [
                -73.98804128806722,
                40.781585961353784
              ],
              [
                -73.9881274613434,
                40.78140179672367
              ],
              [
                -73.98799363199988,
                40.78134281699988
              ],
              [
                -73.98746219899995,
                40.78110860899996
              ],
              [
                -73.98741432699994,
                40.7810875109999
              ],
              [
                -73.98736363999998,
                40.781062804999856
              ],
              [
                -73.98730772899984,
                40.78104130299991
              ],
              [
                -73.98707137499986,
                40.780906381999955
              ],
              [
                -73.98654378999994,
                40.780657980999926
              ],
              [
                -73.98567936099997,
                40.780312632999944
              ],
              [
                -73.98536952699989,
                40.78107837199991
              ],
              [
                -73.98507184299989,
                40.781779680999946
              ],
              [
                -73.98351422299999,
                40.781125389999914
              ],
              [
                -73.98162787399998,
                40.780328934999936
              ],
              [
                -73.98151911299976,
                40.78028175799991
              ],
              [
                -73.98140948699984,
                40.780235418999894
              ],
              [
                -73.980673654,
                40.779925181999914
              ],
              [
                -73.97783054399989,
                40.77872973099989
              ],
              [
                -73.97499744000005,
                40.77753254599988
              ],
              [
                -73.97453231499993,
                40.77816778499996
              ],
              [
                -73.97406668299993,
                40.77880541699987
              ],
              [
                -73.97357117499988,
                40.779477861999936
              ],
              [
                -73.97172305599985,
                40.78202147599997
              ],
              [
                -73.971222922,
                40.78270625599991
              ],
              [
                -73.97076013099995,
                40.783340137999865
              ],
              [
                -73.97030068199992,
                40.783975413999904
              ],
              [
                -73.96983225599998,
                40.784610910999916
              ],
              [
                -73.96933573299991,
                40.78529327999992
              ],
              [
                -73.97217414999986,
                40.78648646099994
              ],
              [
                -73.97501417199996,
                40.78768560599987
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000216049973456,
        "objectid": 232,
        "shape_leng": 0.0614709085331,
        "location_id": 232,
        "zone": "Two Bridges/Seward Park",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.97635525199995,
                40.71913288399984
              ],
              [
                -73.97648182399995,
                40.719159627999915
              ],
              [
                -73.97674495699998,
                40.71921523399993
              ],
              [
                -73.97722379199992,
                40.71930977499993
              ],
              [
                -73.978031227,
                40.71963834799988
              ],
              [
                -73.97875234999992,
                40.71993370699994
              ],
              [
                -73.97966021699982,
                40.72021282999992
              ],
              [
                -73.98130824199984,
                40.7207087859999
              ],
              [
                -73.98216615299982,
                40.72096885499996
              ],
              [
                -73.98300308699991,
                40.721222680999894
              ],
              [
                -73.9838238729998,
                40.72147287199987
              ],
              [
                -73.98386422799992,
                40.7213963779999
              ],
              [
                -73.98447731799986,
                40.72023423899989
              ],
              [
                -73.985073423,
                40.71908329399989
              ],
              [
                -73.9859109239999,
                40.71933897299989
              ],
              [
                -73.98675136699994,
                40.719592845999955
              ],
              [
                -73.98736015299995,
                40.71836979699993
              ],
              [
                -73.98739393199992,
                40.7183035229999
              ],
              [
                -73.98743405799996,
                40.718213079999934
              ],
              [
                -73.98751208099989,
                40.71807218599989
              ],
              [
                -73.98787935799997,
                40.7174089729999
              ],
              [
                -73.98791645999995,
                40.71733557399992
              ],
              [
                -73.98795181500003,
                40.717265627999915
              ],
              [
                -73.98836626299988,
                40.71644570199992
              ],
              [
                -73.98915463799979,
                40.716708646999905
              ],
              [
                -73.98975418199988,
                40.71555234799996
              ],
              [
                -73.9902023599999,
                40.71466442999987
              ],
              [
                -73.99022155699988,
                40.71457875599987
              ],
              [
                -73.99022750999988,
                40.714491668999926
              ],
              [
                -73.99022012699989,
                40.71440493999988
              ],
              [
                -73.9907551529999,
                40.7145540729999
              ],
              [
                -73.99122551100001,
                40.714486643999926
              ],
              [
                -73.99213486499995,
                40.7144198019999
              ],
              [
                -73.99256242199989,
                40.714388076999974
              ],
              [
                -73.99246827699984,
                40.71385682499988
              ],
              [
                -73.9924556359999,
                40.71376288199985
              ],
              [
                -73.99239157000001,
                40.71319080999998
              ],
              [
                -73.99226747499985,
                40.712467556999925
              ],
              [
                -73.99225369299995,
                40.71235432599987
              ],
              [
                -73.99224319399994,
                40.71226805499987
              ],
              [
                -73.99221855999997,
                40.71206565499991
              ],
              [
                -73.99220577799984,
                40.71196063499992
              ],
              [
                -73.99218322399993,
                40.71177531099993
              ],
              [
                -73.99216806999992,
                40.71171189899994
              ],
              [
                -73.99215484499997,
                40.71165655599985
              ],
              [
                -73.99213550299994,
                40.71157561499989
              ],
              [
                -73.99202215999982,
                40.71110129999989
              ],
              [
                -73.99200166199995,
                40.7110155229999
              ],
              [
                -73.99197674899987,
                40.71091126599987
              ],
              [
                -73.99191690599989,
                40.710597505999885
              ],
              [
                -73.99177662699996,
                40.709819039999914
              ],
              [
                -73.99183817499984,
                40.709810551999965
              ],
              [
                -73.9919070979999,
                40.70980199399987
              ],
              [
                -73.99204746299989,
                40.70977574499992
              ],
              [
                -73.99200595899985,
                40.70969647899993
              ],
              [
                -73.99197509100001,
                40.70964553499988
              ],
              [
                -73.9919434659999,
                40.70958905299992
              ],
              [
                -73.99192047499996,
                40.70955429399989
              ],
              [
                -73.99190824271031,
                40.709532926643355
              ],
              [
                -73.99176779580131,
                40.70955505602623
              ],
              [
                -73.99170260292517,
                40.709565262955984
              ],
              [
                -73.9913510923706,
                40.709620332703324
              ],
              [
                -73.9910678346299,
                40.70965723034519
              ],
              [
                -73.99078913018562,
                40.709689506086086
              ],
              [
                -73.99051420376685,
                40.709733884621315
              ],
              [
                -73.99015825373382,
                40.70979037266374
              ],
              [
                -73.98974515155741,
                40.70984876165558
              ],
              [
                -73.98957449608706,
                40.7098740816065
              ],
              [
                -73.98946067019428,
                40.70988928705126
              ],
              [
                -73.98935765696673,
                40.709900986707815
              ],
              [
                -73.98889845980878,
                40.70995473576508
              ],
              [
                -73.98879717785798,
                40.709966607472246
              ],
              [
                -73.9887368170753,
                40.709970741845105
              ],
              [
                -73.98871771111233,
                40.70994709051892
              ],
              [
                -73.98869610271325,
                40.70992844984459
              ],
              [
                -73.98866750580949,
                40.709920034830716
              ],
              [
                -73.98864533493676,
                40.709916934401114
              ],
              [
                -73.9886365807184,
                40.70991206151628
              ],
              [
                -73.98849727173877,
                40.709176305795744
              ],
              [
                -73.98847967268772,
                40.70915584730847
              ],
              [
                -73.98844653437374,
                40.709133820546924
              ],
              [
                -73.98839064023261,
                40.70912694916261
              ],
              [
                -73.9881734096786,
                40.709147518301265
              ],
              [
                -73.9881649172667,
                40.70915678150646
              ],
              [
                -73.98655035339978,
                40.709311944641996
              ],
              [
                -73.98449647076768,
                40.70950966393941
              ],
              [
                -73.98364234472628,
                40.70959187924471
              ],
              [
                -73.98306019477401,
                40.70964791174809
              ],
              [
                -73.98123734462091,
                40.70982334331847
              ],
              [
                -73.98127684210984,
                40.710217251191
              ],
              [
                -73.98050948312414,
                40.71027356987713
              ],
              [
                -73.98033016246781,
                40.71028673011878
              ],
              [
                -73.98033113415963,
                40.710323524412026
              ],
              [
                -73.98031391320167,
                40.71033259071118
              ],
              [
                -73.98029934149871,
                40.71034267301001
              ],
              [
                -73.98028344332175,
                40.710361815108435
              ],
              [
                -73.98003642292828,
                40.71037840126665
              ],
              [
                -73.9792901523712,
                40.710428144566926
              ],
              [
                -73.97920726434594,
                40.71043366689569
              ],
              [
                -73.97918894377,
                40.71043492338827
              ],
              [
                -73.97877613342088,
                40.7104633150472
              ],
              [
                -73.97861560123994,
                40.71047378191086
              ],
              [
                -73.97837148165253,
                40.71048970749159
              ],
              [
                -73.97825557749202,
                40.71049790955784
              ],
              [
                -73.9781819069428,
                40.71050686551763
              ],
              [
                -73.978079749066,
                40.710532251079854
              ],
              [
                -73.9779967479038,
                40.710561017391605
              ],
              [
                -73.9778994961945,
                40.71059986620246
              ],
              [
                -73.97782091638246,
                40.71064544490706
              ],
              [
                -73.97772119493052,
                40.7107100746607
              ],
              [
                -73.97752668432635,
                40.71084307273315
              ],
              [
                -73.97722213562548,
                40.711068731228345
              ],
              [
                -73.97705659401724,
                40.71119762696939
              ],
              [
                -73.97699076574622,
                40.71125666023055
              ],
              [
                -73.97681883540065,
                40.71141956702117
              ],
              [
                -73.97671221539647,
                40.71153576517846
              ],
              [
                -73.9765500881422,
                40.711734538728294
              ],
              [
                -73.97650540115386,
                40.711798789452466
              ],
              [
                -73.97642872514517,
                40.71190903250363
              ],
              [
                -73.9763112786719,
                40.712118647310184
              ],
              [
                -73.97616531238327,
                40.71242019791075
              ],
              [
                -73.97612368838796,
                40.71246032539839
              ],
              [
                -73.97604981455497,
                40.71260419377782
              ],
              [
                -73.97596844519683,
                40.712789573390786
              ],
              [
                -73.97579346365995,
                40.71317403724817
              ],
              [
                -73.97580133088594,
                40.713182224269396
              ],
              [
                -73.97523893090127,
                40.714394480105895
              ],
              [
                -73.97523888264773,
                40.714394578936414
              ],
              [
                -73.97521319223425,
                40.71444996434558
              ],
              [
                -73.97518850986295,
                40.71450316072387
              ],
              [
                -73.97517484286146,
                40.71452991316869
              ],
              [
                -73.97515865241793,
                40.71456160677657
              ],
              [
                -73.97513489452726,
                40.714608103940215
              ],
              [
                -73.97500029316984,
                40.71487154606127
              ],
              [
                -73.97491787352749,
                40.71506599388644
              ],
              [
                -73.97481840216984,
                40.715286372090574
              ],
              [
                -73.97473312789971,
                40.715482988621964
              ],
              [
                -73.97463933366734,
                40.71570768976437
              ],
              [
                -73.97449720984376,
                40.71604257630628
              ],
              [
                -73.97445315383058,
                40.716151681161705
              ],
              [
                -73.97425084643321,
                40.716698543367634
              ],
              [
                -73.97422340312282,
                40.716786147252094
              ],
              [
                -73.97418131138616,
                40.71692102577268
              ],
              [
                -73.97408161583928,
                40.717155325222436
              ],
              [
                -73.97399195824943,
                40.7174153452363
              ],
              [
                -73.97390596973892,
                40.717639219737386
              ],
              [
                -73.97380258807483,
                40.71794026346791
              ],
              [
                -73.97372208271211,
                40.718155790530695
              ],
              [
                -73.97365255284436,
                40.71835741732556
              ],
              [
                -73.97359492157358,
                40.71851801880885
              ],
              [
                -73.9735436873773,
                40.71866262945056
              ],
              [
                -73.97347975524508,
                40.71886142911291
              ],
              [
                -73.97464720799998,
                40.718842349999896
              ],
              [
                -73.97468687499985,
                40.71884170199993
              ],
              [
                -73.97474239999998,
                40.71884079399995
              ],
              [
                -73.97485919899994,
                40.71885547999992
              ],
              [
                -73.97491298899988,
                40.718862442999864
              ],
              [
                -73.97497644799995,
                40.71887065199991
              ],
              [
                -73.97509520199993,
                40.71888074599987
              ],
              [
                -73.97545167499995,
                40.71894655899996
              ],
              [
                -73.97551012699985,
                40.71895861299991
              ],
              [
                -73.97635525199995,
                40.71913288399984
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000116188094682,
        "objectid": 233,
        "shape_leng": 0.048035918294,
        "location_id": 233,
        "zone": "UN/Turtle Bay South",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.96547092299994,
                40.75193959699992
              ],
              [
                -73.96688157199996,
                40.75254455599992
              ],
              [
                -73.96701854999988,
                40.75260074199987
              ],
              [
                -73.96706733899993,
                40.75262067599991
              ],
              [
                -73.96694827399996,
                40.752785487999944
              ],
              [
                -73.96677887099995,
                40.75301878799995
              ],
              [
                -73.96665543199994,
                40.75318878499998
              ],
              [
                -73.96661581800002,
                40.75324342499995
              ],
              [
                -73.96897803700001,
                40.75425053499993
              ],
              [
                -73.9712170449999,
                40.75519332399994
              ],
              [
                -73.97167699199997,
                40.75456963099995
              ],
              [
                -73.97213617399994,
                40.7539432349999
              ],
              [
                -73.97258874999983,
                40.753317887999856
              ],
              [
                -73.97304502599985,
                40.7526916219999
              ],
              [
                -73.97350255899984,
                40.7520649319999
              ],
              [
                -73.97395914699999,
                40.751441021999916
              ],
              [
                -73.9744494109998,
                40.75076644499992
              ],
              [
                -73.97493471299984,
                40.75009962799991
              ],
              [
                -73.97538295899986,
                40.74948305799993
              ],
              [
                -73.9758346669999,
                40.74886314599998
              ],
              [
                -73.97497000699995,
                40.74850184899988
              ],
              [
                -73.97359187599997,
                40.74791866299991
              ],
              [
                -73.9727379199999,
                40.74755798999995
              ],
              [
                -73.97121971999988,
                40.74692154099996
              ],
              [
                -73.97165740599984,
                40.746303693999955
              ],
              [
                -73.97194428900002,
                40.745931871999886
              ],
              [
                -73.97203331099983,
                40.745804967999945
              ],
              [
                -73.97212958899986,
                40.74568090199994
              ],
              [
                -73.97257598299977,
                40.74506785499992
              ],
              [
                -73.97302670299986,
                40.74445004099993
              ],
              [
                -73.97350835000003,
                40.743782033999885
              ],
              [
                -73.97223028499984,
                40.743249026999884
              ],
              [
                -73.97210897599994,
                40.74320549099992
              ],
              [
                -73.97206165299998,
                40.74318802999993
              ],
              [
                -73.97198880299989,
                40.74316289599995
              ],
              [
                -73.97191659199993,
                40.74313739399983
              ],
              [
                -73.97167921200406,
                40.74305446402989
              ],
              [
                -73.97163772240417,
                40.74311720098464
              ],
              [
                -73.97149421270423,
                40.74333467446508
              ],
              [
                -73.97135563409158,
                40.743539649851975
              ],
              [
                -73.97131062520039,
                40.7435240231909
              ],
              [
                -73.97136080173868,
                40.74344427709271
              ],
              [
                -73.97135725327772,
                40.74343661339124
              ],
              [
                -73.97135016335174,
                40.743431659048724
              ],
              [
                -73.97135416843827,
                40.74342493319505
              ],
              [
                -73.97130663606043,
                40.743413403261854
              ],
              [
                -73.97126710092137,
                40.743389882433846
              ],
              [
                -73.97125636771857,
                40.74340155868206
              ],
              [
                -73.97124584132808,
                40.74340203417445
              ],
              [
                -73.97123301520192,
                40.743414663960074
              ],
              [
                -73.97108533439558,
                40.74366405834521
              ],
              [
                -73.97109822176313,
                40.74367849681545
              ],
              [
                -73.97118780171982,
                40.74370736326176
              ],
              [
                -73.97119990329709,
                40.74370632109215
              ],
              [
                -73.97120787330633,
                40.74369879478971
              ],
              [
                -73.97124127770057,
                40.7436377401475
              ],
              [
                -73.97128101831672,
                40.743651942051315
              ],
              [
                -73.97120546301963,
                40.7437715103013
              ],
              [
                -73.97123423981489,
                40.743784646190235
              ],
              [
                -73.97135752941857,
                40.74383845535952
              ],
              [
                -73.97127362462288,
                40.74396474842357
              ],
              [
                -73.97120616502995,
                40.744066029846614
              ],
              [
                -73.9711819357606,
                40.74410323270784
              ],
              [
                -73.97117346687045,
                40.74410753551064
              ],
              [
                -73.97096300193458,
                40.7440236727748
              ],
              [
                -73.97095964992798,
                40.744025696987066
              ],
              [
                -73.97087357851332,
                40.74398767476942
              ],
              [
                -73.97083595090118,
                40.74396970165843
              ],
              [
                -73.97083587404146,
                40.743969672238116
              ],
              [
                -73.97079892989288,
                40.743952797506395
              ],
              [
                -73.9707920969469,
                40.743956010435085
              ],
              [
                -73.97076123524698,
                40.74394210814419
              ],
              [
                -73.97075061381996,
                40.74394863411313
              ],
              [
                -73.9707425727204,
                40.743948324660025
              ],
              [
                -73.9707031746558,
                40.74399049541112
              ],
              [
                -73.97070578720823,
                40.74400256198672
              ],
              [
                -73.97071823494028,
                40.744016927848136
              ],
              [
                -73.97075084955125,
                40.74402980466477
              ],
              [
                -73.97075833953649,
                40.74403919088488
              ],
              [
                -73.97077757716099,
                40.74404662522193
              ],
              [
                -73.97078276128667,
                40.74404724738941
              ],
              [
                -73.97079622300242,
                40.74404888066652
              ],
              [
                -73.97081588183757,
                40.74406523939511
              ],
              [
                -73.97083878967527,
                40.744070873074385
              ],
              [
                -73.97084544343367,
                40.744076493254425
              ],
              [
                -73.97086171010008,
                40.7440759385033
              ],
              [
                -73.97087943942499,
                40.74409281003314
              ],
              [
                -73.97089791952085,
                40.744097866123326
              ],
              [
                -73.97090457107981,
                40.74410349618338
              ],
              [
                -73.97091343992325,
                40.744102373652225
              ],
              [
                -73.97092895951977,
                40.74411024678126
              ],
              [
                -73.97106420642824,
                40.744167068050885
              ],
              [
                -73.97107528455967,
                40.7441760664907
              ],
              [
                -73.97109154986752,
                40.744182247937445
              ],
              [
                -73.97111076626928,
                40.744183945109306
              ],
              [
                -73.9711250301855,
                40.74418974894764
              ],
              [
                -73.97101648059069,
                40.744355089523594
              ],
              [
                -73.97094062960414,
                40.74447144402548
              ],
              [
                -73.97092858032363,
                40.744463363204204
              ],
              [
                -73.9709172484543,
                40.74446336034336
              ],
              [
                -73.9709094613216,
                40.74445635209411
              ],
              [
                -73.97089742067703,
                40.7444547367797
              ],
              [
                -73.97088963376707,
                40.74444772970185
              ],
              [
                -73.97087688233857,
                40.74444557343096
              ],
              [
                -73.97085704434333,
                40.74443263739721
              ],
              [
                -73.97078834776636,
                40.744406217690354
              ],
              [
                -73.97062381510642,
                40.744656426210064
              ],
              [
                -73.9705687722909,
                40.74473186530609
              ],
              [
                -73.97050560032663,
                40.74482376425927
              ],
              [
                -73.97047240576431,
                40.744866241747694
              ],
              [
                -73.97046063703607,
                40.74486474339068
              ],
              [
                -73.97044255489001,
                40.74487037600953
              ],
              [
                -73.97037811940483,
                40.74495230608582
              ],
              [
                -73.97032571931098,
                40.7450232614002
              ],
              [
                -73.97032571321418,
                40.745036949464414
              ],
              [
                -73.97033931161238,
                40.7450496320225
              ],
              [
                -73.97021602288636,
                40.745200579032776
              ],
              [
                -73.97012962576954,
                40.74530536659431
              ],
              [
                -73.97009123175702,
                40.74535112915095
              ],
              [
                -73.970047589049,
                40.74540286129935
              ],
              [
                -73.96997377026268,
                40.7454957474306
              ],
              [
                -73.96992704959064,
                40.745467792035086
              ],
              [
                -73.96993139614943,
                40.74546300191986
              ],
              [
                -73.96991227975748,
                40.74545175943625
              ],
              [
                -73.96990575265262,
                40.74545820514513
              ],
              [
                -73.96987561403044,
                40.74544478913761
              ],
              [
                -73.96969678845915,
                40.745657000292894
              ],
              [
                -73.96969487777564,
                40.74566679689092
              ],
              [
                -73.96963204180261,
                40.74574330564597
              ],
              [
                -73.969623705834,
                40.745746716794386
              ],
              [
                -73.96959740261153,
                40.745782784111796
              ],
              [
                -73.96955059658639,
                40.745838827693575
              ],
              [
                -73.96952046125196,
                40.74586904111652
              ],
              [
                -73.96946210038307,
                40.7459445776336
              ],
              [
                -73.96943292389848,
                40.74597698923133
              ],
              [
                -73.9694175339955,
                40.74599696633198
              ],
              [
                -73.96940086652106,
                40.74601402713001
              ],
              [
                -73.96940022327856,
                40.74602084326546
              ],
              [
                -73.96938290644789,
                40.746035949024964
              ],
              [
                -73.96933924459174,
                40.746092656088365
              ],
              [
                -73.96933228026558,
                40.74610167233752
              ],
              [
                -73.96932591437381,
                40.74610408771041
              ],
              [
                -73.96925028881681,
                40.74619617144262
              ],
              [
                -73.9692470758419,
                40.746207781993604
              ],
              [
                -73.96923432172326,
                40.74622228401153
              ],
              [
                -73.96921135921266,
                40.74624451530388
              ],
              [
                -73.96913096118836,
                40.746340715306665
              ],
              [
                -73.96907193333254,
                40.74641298300629
              ],
              [
                -73.96901961115914,
                40.746472438902586
              ],
              [
                -73.96901641754057,
                40.746481152086254
              ],
              [
                -73.96900302810072,
                40.7464908088819
              ],
              [
                -73.96899981595465,
                40.74650193477237
              ],
              [
                -73.96898899231675,
                40.74650579199741
              ],
              [
                -73.96897366849464,
                40.74652900733651
              ],
              [
                -73.96896670537485,
                40.746530500799395
              ],
              [
                -73.96894301109302,
                40.7465183356965
              ],
              [
                -73.96875271240361,
                40.74674271521675
              ],
              [
                -73.9687107654442,
                40.74678826391716
              ],
              [
                -73.96870458089755,
                40.746800682796184
              ],
              [
                -73.9686922519053,
                40.74680860795763
              ],
              [
                -73.96867043421345,
                40.74683600997994
              ],
              [
                -73.96866379796626,
                40.74684682031493
              ],
              [
                -73.96863912464634,
                40.74687927464661
              ],
              [
                -73.96861730876927,
                40.746899455777815
              ],
              [
                -73.96860971997499,
                40.74691460360568
              ],
              [
                -73.96860023472239,
                40.74692109182235
              ],
              [
                -73.96859169569538,
                40.74693334755284
              ],
              [
                -73.96858885224545,
                40.74694344358069
              ],
              [
                -73.968544278662,
                40.7469881423127
              ],
              [
                -73.9685347900076,
                40.74700039777951
              ],
              [
                -73.9685148595502,
                40.747032843998056
              ],
              [
                -73.9685006403586,
                40.74704438450719
              ],
              [
                -73.96847691942916,
                40.74707250130821
              ],
              [
                -73.96846363690706,
                40.74709412848565
              ],
              [
                -73.96843139158797,
                40.74712945662025
              ],
              [
                -73.96840008314159,
                40.74716695396425
              ],
              [
                -73.96836118844753,
                40.747218145486315
              ],
              [
                -73.96834696591314,
                40.74724121830999
              ],
              [
                -73.96832610433407,
                40.747252034598795
              ],
              [
                -73.96831613715759,
                40.747274024359285
              ],
              [
                -73.96830191005883,
                40.74727329974721
              ],
              [
                -73.96829811451514,
                40.747283395002775
              ],
              [
                -73.96829622113238,
                40.7472956513671
              ],
              [
                -73.96823171715793,
                40.747368476489285
              ],
              [
                -73.96836519324144,
                40.74743349462015
              ],
              [
                -73.96823574753452,
                40.74759121839251
              ],
              [
                -73.96817376101056,
                40.7476667537417
              ],
              [
                -73.96811893399281,
                40.7477258297499
              ],
              [
                -73.96791272994389,
                40.747970935642165
              ],
              [
                -73.96532405586481,
                40.751047718272055
              ],
              [
                -73.96482214210387,
                40.75159632579114
              ],
              [
                -73.96485451699984,
                40.75169675999992
              ],
              [
                -73.96495708499998,
                40.751743445999885
              ],
              [
                -73.96505548699992,
                40.75181258099991
              ],
              [
                -73.96521537099989,
                40.751808460999904
              ],
              [
                -73.96518524199982,
                40.75195230699995
              ],
              [
                -73.96530480400006,
                40.75193187399988
              ],
              [
                -73.96532415299987,
                40.751930897999934
              ],
              [
                -73.96547092299994,
                40.75193959699992
              ]
            ]
          ],
          [
            [
              [
                -73.96421230395671,
                40.74660431847666
              ],
              [
                -73.96421192462753,
                40.74666667680293
              ],
              [
                -73.96413382933486,
                40.74681279616542
              ],
              [
                -73.96412514486158,
                40.74684576060534
              ],
              [
                -73.96415980288437,
                40.74686554735954
              ],
              [
                -73.96421616682363,
                40.74683700083099
              ],
              [
                -73.96427256202216,
                40.746751308084086
              ],
              [
                -73.96433758671738,
                40.746734848181354
              ],
              [
                -73.96437443772194,
                40.74670453064306
              ],
              [
                -73.96447543657693,
                40.74664083319782
              ],
              [
                -73.96454518192593,
                40.74656415047295
              ],
              [
                -73.96458702399427,
                40.746515898544594
              ],
              [
                -73.96458318426942,
                40.746454400673315
              ],
              [
                -73.96444522752618,
                40.746410425763166
              ],
              [
                -73.96437765404302,
                40.746495088038856
              ],
              [
                -73.96433729322388,
                40.74654572659317
              ],
              [
                -73.9642736475242,
                40.746541845055035
              ],
              [
                -73.96421230395671,
                40.74660431847666
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0000731054382894,
        "objectid": 234,
        "shape_leng": 0.0360721994984,
        "location_id": 234,
        "zone": "Union Sq",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.98996936399989,
                40.73490456699995
              ],
              [
                -73.98997766699992,
                40.73497925499992
              ],
              [
                -73.9899791279999,
                40.73505420099984
              ],
              [
                -73.98997373899982,
                40.735129042999894
              ],
              [
                -73.98996152699992,
                40.73520341999989
              ],
              [
                -73.98994254999981,
                40.73527697099994
              ],
              [
                -73.98991689999981,
                40.73534934199992
              ],
              [
                -73.989874214,
                40.73540825799992
              ],
              [
                -73.98945472099987,
                40.73598724699991
              ],
              [
                -73.98920953899987,
                40.73630712099986
              ],
              [
                -73.98901559399987,
                40.73656015399992
              ],
              [
                -73.98859298399994,
                40.737142560999935
              ],
              [
                -73.98817910299996,
                40.73773074899986
              ],
              [
                -73.98774074499983,
                40.738312187999945
              ],
              [
                -73.98730154899981,
                40.73890302599993
              ],
              [
                -73.98703592799987,
                40.7392663789999
              ],
              [
                -73.9868491859999,
                40.73952182199985
              ],
              [
                -73.98636141699991,
                40.74018480099993
              ],
              [
                -73.98587913199991,
                40.74085299999996
              ],
              [
                -73.98542767799994,
                40.74147034899988
              ],
              [
                -73.98497775500003,
                40.74208895799994
              ],
              [
                -73.98452636699996,
                40.7427070419999
              ],
              [
                -73.98407485299994,
                40.7433247179999
              ],
              [
                -73.98413729099997,
                40.74334959099991
              ],
              [
                -73.98569571299986,
                40.744007641999865
              ],
              [
                -73.98729805499983,
                40.74468149899992
              ],
              [
                -73.98872905499988,
                40.74528637399991
              ],
              [
                -73.99051761299985,
                40.746038637999895
              ],
              [
                -73.99096832799982,
                40.74542089099989
              ],
              [
                -73.99141858599994,
                40.74480268199988
              ],
              [
                -73.991871024,
                40.74418557099991
              ],
              [
                -73.99231888499999,
                40.74356832199989
              ],
              [
                -73.99280340900002,
                40.74291001999997
              ],
              [
                -73.99329166800001,
                40.74223330099994
              ],
              [
                -73.99373928799994,
                40.74161720799987
              ],
              [
                -73.99397071699987,
                40.74129843899993
              ],
              [
                -73.99416492399986,
                40.7410309369999
              ],
              [
                -73.99459264899987,
                40.74044198099993
              ],
              [
                -73.99501798599992,
                40.73986001099995
              ],
              [
                -73.99544664199995,
                40.7392721329999
              ],
              [
                -73.99587409499989,
                40.73868594599992
              ],
              [
                -73.9963368949999,
                40.73804374699991
              ],
              [
                -73.99683993899998,
                40.73736088899995
              ],
              [
                -73.99361688399992,
                40.73600449199991
              ],
              [
                -73.99171498799983,
                40.73521530099993
              ],
              [
                -73.99119899299986,
                40.73501236899992
              ],
              [
                -73.9907539679999,
                40.734839154999875
              ],
              [
                -73.98990295999991,
                40.7344347899999
              ],
              [
                -73.98996936399989,
                40.73490456699995
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000212844547337,
        "objectid": 235,
        "shape_leng": 0.0761668426519,
        "location_id": 235,
        "zone": "University Heights/Morris Heights",
        "borough": "Bronx"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.90947862999988,
                40.86180812899987
              ],
              [
                -73.90947854799991,
                40.86180822799987
              ],
              [
                -73.90947846399986,
                40.861808334999886
              ],
              [
                -73.90932135399996,
                40.86201616199989
              ],
              [
                -73.90922437099981,
                40.8621353919999
              ],
              [
                -73.909224264,
                40.86213551799989
              ],
              [
                -73.90922418100003,
                40.86213565299996
              ],
              [
                -73.90914587699984,
                40.86226418999996
              ],
              [
                -73.90914584099987,
                40.862264244999956
              ],
              [
                -73.90914581699987,
                40.86226429799993
              ],
              [
                -73.90908830799995,
                40.8623994979999
              ],
              [
                -73.90908822399997,
                40.86239969599992
              ],
              [
                -73.90908816499991,
                40.862399911999965
              ],
              [
                -73.90905273599994,
                40.862538738999966
              ],
              [
                -73.90905268799997,
                40.86253892899989
              ],
              [
                -73.90905267599997,
                40.86253910899997
              ],
              [
                -73.9090395089999,
                40.86267886399991
              ],
              [
                -73.90922113399998,
                40.86262994799992
              ],
              [
                -73.90939703499993,
                40.8625677719999
              ],
              [
                -73.90956472299985,
                40.8624930109999
              ],
              [
                -73.90972193999986,
                40.86240666799993
              ],
              [
                -73.909866682,
                40.862310067999914
              ],
              [
                -73.91075595799987,
                40.86165148399988
              ],
              [
                -73.91181229099992,
                40.86173238699993
              ],
              [
                -73.91194992599989,
                40.86175014099994
              ],
              [
                -73.91208148799987,
                40.86176800699992
              ],
              [
                -73.91245929699984,
                40.8618204329999
              ],
              [
                -73.91266095199984,
                40.861851634999894
              ],
              [
                -73.91273784299996,
                40.86186388599992
              ],
              [
                -73.91278962899986,
                40.86187247099988
              ],
              [
                -73.91295585399997,
                40.86191313599993
              ],
              [
                -73.91329746900003,
                40.86208962799993
              ],
              [
                -73.91335994700002,
                40.862112672999956
              ],
              [
                -73.91343151699984,
                40.862139066999866
              ],
              [
                -73.91422824049913,
                40.86249079035963
              ],
              [
                -73.91429082559196,
                40.86242801792629
              ],
              [
                -73.91462116377565,
                40.86209669086733
              ],
              [
                -73.91461141219102,
                40.8620725494817
              ],
              [
                -73.91461143724548,
                40.86205299399864
              ],
              [
                -73.91463437515958,
                40.86202041992011
              ],
              [
                -73.91467162914577,
                40.8619813378025
              ],
              [
                -73.91470028504796,
                40.86195311277191
              ],
              [
                -73.91474323842307,
                40.861933589990585
              ],
              [
                -73.91477109687511,
                40.86194630832581
              ],
              [
                -73.91481817352647,
                40.86189908899147
              ],
              [
                -73.91472330855278,
                40.86185318238911
              ],
              [
                -73.91480934325483,
                40.86171418964575
              ],
              [
                -73.91487470201923,
                40.86169169998622
              ],
              [
                -73.9157621314479,
                40.86064463678988
              ],
              [
                -73.91580528008534,
                40.86046973585609
              ],
              [
                -73.91691308641661,
                40.859270212209154
              ],
              [
                -73.91720664647033,
                40.85892892273458
              ],
              [
                -73.91776041251106,
                40.85870809795827
              ],
              [
                -73.91811187549494,
                40.858313729633515
              ],
              [
                -73.91829123398814,
                40.85815501495546
              ],
              [
                -73.91902369112604,
                40.857396538206935
              ],
              [
                -73.91956620889232,
                40.85684018384378
              ],
              [
                -73.92002002121407,
                40.8562707210165
              ],
              [
                -73.92030620609954,
                40.85592711981101
              ],
              [
                -73.9202939290938,
                40.85584511501976
              ],
              [
                -73.92035024788353,
                40.85578536382873
              ],
              [
                -73.92044141417742,
                40.855746136309996
              ],
              [
                -73.92052653132843,
                40.855686668401795
              ],
              [
                -73.92055014989616,
                40.8556181796815
              ],
              [
                -73.92056655954352,
                40.855513898313646
              ],
              [
                -73.92030027213424,
                40.85524637134155
              ],
              [
                -73.92037235451427,
                40.85517296513669
              ],
              [
                -73.92080070289325,
                40.85538081439453
              ],
              [
                -73.92151543705522,
                40.8545100638913
              ],
              [
                -73.92148991153954,
                40.8544902182886
              ],
              [
                -73.92134207683873,
                40.854317435657684
              ],
              [
                -73.92154374459189,
                40.854109951527306
              ],
              [
                -73.92174813841936,
                40.85421471478316
              ],
              [
                -73.92256178923255,
                40.85318891176361
              ],
              [
                -73.92331810320631,
                40.85212978798092
              ],
              [
                -73.9235176926963,
                40.85183581998547
              ],
              [
                -73.92394789467637,
                40.85122178480801
              ],
              [
                -73.92384778922904,
                40.851182608857954
              ],
              [
                -73.92408865809536,
                40.85073735008727
              ],
              [
                -73.9242240509533,
                40.85056887702688
              ],
              [
                -73.92445273224833,
                40.85009207721129
              ],
              [
                -73.92465379915153,
                40.84978504325649
              ],
              [
                -73.92521955514904,
                40.84908224686876
              ],
              [
                -73.92577127016631,
                40.84842204291624
              ],
              [
                -73.92586477100132,
                40.84836170484418
              ],
              [
                -73.9259395786478,
                40.848271193179905
              ],
              [
                -73.92597932978447,
                40.848162928815654
              ],
              [
                -73.92603310186037,
                40.84807241514932
              ],
              [
                -73.92611024113897,
                40.84802094994987
              ],
              [
                -73.92619439242104,
                40.84796061201434
              ],
              [
                -73.92630425882031,
                40.84787010122042
              ],
              [
                -73.92641413285313,
                40.8477316702337
              ],
              [
                -73.92645855280897,
                40.84764825440049
              ],
              [
                -73.92651699719853,
                40.84756484067269
              ],
              [
                -73.9265660881038,
                40.84750982309605
              ],
              [
                -73.92662687420078,
                40.84739268675017
              ],
              [
                -73.92669933483715,
                40.84735187102645
              ],
              [
                -73.92675777018512,
                40.84733590131359
              ],
              [
                -73.92677400524201,
                40.847325562831166
              ],
              [
                -73.92683023208399,
                40.847289760094306
              ],
              [
                -73.92685127601227,
                40.84722409102789
              ],
              [
                -73.92685912316018,
                40.84719293598746
              ],
              [
                -73.92688167685719,
                40.847103401772344
              ],
              [
                -73.92687934781301,
                40.847039507037174
              ],
              [
                -73.9269354594415,
                40.846924144085214
              ],
              [
                -73.92701961357912,
                40.84681765789868
              ],
              [
                -73.9271551981214,
                40.84665082984286
              ],
              [
                -73.9272247957283,
                40.84658965715396
              ],
              [
                -73.9272779808177,
                40.84654803605296
              ],
              [
                -73.92730194499312,
                40.846506867188026
              ],
              [
                -73.92734184476856,
                40.846463641219
              ],
              [
                -73.92736757047359,
                40.84640506094841
              ],
              [
                -73.92739125975535,
                40.84634148384076
              ],
              [
                -73.9274214151804,
                40.846255083713544
              ],
              [
                -73.92746013067759,
                40.846194776164275
              ],
              [
                -73.92754828423438,
                40.84608721478047
              ],
              [
                -73.92759209775825,
                40.84605233675773
              ],
              [
                -73.92763424713485,
                40.846018784098305
              ],
              [
                -73.9276707332328,
                40.84603348224206
              ],
              [
                -73.92786869805398,
                40.845597721099985
              ],
              [
                -73.92804748400904,
                40.845464595977255
              ],
              [
                -73.92808209386837,
                40.84539159691673
              ],
              [
                -73.92796538299997,
                40.845362455999904
              ],
              [
                -73.927784594,
                40.845319748999906
              ],
              [
                -73.92740093599984,
                40.8452254349999
              ],
              [
                -73.92706435800001,
                40.8451394499999
              ],
              [
                -73.92696149299991,
                40.845114494999955
              ],
              [
                -73.92676979799985,
                40.84507093399993
              ],
              [
                -73.92668910099991,
                40.84505161199993
              ],
              [
                -73.92661872999987,
                40.845035646999875
              ],
              [
                -73.92596341899984,
                40.844889743999886
              ],
              [
                -73.92526006699997,
                40.844793802999966
              ],
              [
                -73.92511859399998,
                40.84477964499992
              ],
              [
                -73.92478318799985,
                40.844752663999905
              ],
              [
                -73.92387743499995,
                40.84474050799987
              ],
              [
                -73.9235400199999,
                40.844758943999885
              ],
              [
                -73.9227346099999,
                40.844796838999876
              ],
              [
                -73.92262808499991,
                40.84480091099987
              ],
              [
                -73.92246996799992,
                40.844813311999914
              ],
              [
                -73.92150714599991,
                40.844873573999976
              ],
              [
                -73.92043297999987,
                40.84494079399986
              ],
              [
                -73.91930035199984,
                40.8449932349999
              ],
              [
                -73.91877179199986,
                40.84503446699997
              ],
              [
                -73.91838402199984,
                40.84505501299993
              ],
              [
                -73.91729224999992,
                40.84510993999991
              ],
              [
                -73.91689747599987,
                40.84511773399992
              ],
              [
                -73.9156148529999,
                40.845146555999875
              ],
              [
                -73.91479384899988,
                40.84516499699993
              ],
              [
                -73.91416184199993,
                40.845179187999925
              ],
              [
                -73.91412710499995,
                40.845228913999925
              ],
              [
                -73.9139753489999,
                40.845429430999936
              ],
              [
                -73.9137516889998,
                40.84573553999996
              ],
              [
                -73.91360819899985,
                40.84594143899992
              ],
              [
                -73.91346470799996,
                40.84614733699992
              ],
              [
                -73.91315074799996,
                40.84657700399988
              ],
              [
                -73.91305693099993,
                40.84670883799991
              ],
              [
                -73.91236345699993,
                40.84768333899996
              ],
              [
                -73.91197263799982,
                40.84822959499992
              ],
              [
                -73.91167991199987,
                40.848639518999896
              ],
              [
                -73.91112232299982,
                40.84941809799992
              ],
              [
                -73.9106946369999,
                40.85001909799993
              ],
              [
                -73.910581699,
                40.850181594999896
              ],
              [
                -73.90921545599986,
                40.85180094499991
              ],
              [
                -73.9084173709999,
                40.85264382199996
              ],
              [
                -73.90733289699989,
                40.853816414999876
              ],
              [
                -73.90557007299988,
                40.855787321999884
              ],
              [
                -73.90522169999987,
                40.85620359499993
              ],
              [
                -73.90508614099994,
                40.856410830999955
              ],
              [
                -73.90468371399987,
                40.857060301999915
              ],
              [
                -73.90565953499988,
                40.85739873399997
              ],
              [
                -73.90526389299995,
                40.858047177999886
              ],
              [
                -73.90486847199993,
                40.8586932249999
              ],
              [
                -73.90572262499991,
                40.85899298899991
              ],
              [
                -73.90679734899983,
                40.85937358899988
              ],
              [
                -73.90707003899985,
                40.85936184899989
              ],
              [
                -73.90725786199998,
                40.85935343799985
              ],
              [
                -73.90777320599983,
                40.85960753799986
              ],
              [
                -73.90956720099997,
                40.8575916239999
              ],
              [
                -73.90976165899988,
                40.85718261199991
              ],
              [
                -73.90980269899988,
                40.85760754899993
              ],
              [
                -73.91020818099982,
                40.858288788999886
              ],
              [
                -73.91030880899984,
                40.85840932699995
              ],
              [
                -73.91042952699988,
                40.85852085699992
              ],
              [
                -73.91056799299989,
                40.858620592999955
              ],
              [
                -73.91072104299988,
                40.8587062319999
              ],
              [
                -73.91088488399993,
                40.858776182999925
              ],
              [
                -73.91328437000003,
                40.85961860099992
              ],
              [
                -73.91307976699996,
                40.85976164299994
              ],
              [
                -73.9128609299999,
                40.859894146999856
              ],
              [
                -73.91262932399985,
                40.860014943999914
              ],
              [
                -73.91238663199994,
                40.86012299899992
              ],
              [
                -73.91213471699986,
                40.860217493999876
              ],
              [
                -73.91187557299992,
                40.86029780899986
              ],
              [
                -73.9116112529999,
                40.86036354999987
              ],
              [
                -73.91145048700004,
                40.86040372399992
              ],
              [
                -73.9112955739999,
                40.860457380999875
              ],
              [
                -73.91114937399995,
                40.86052367099993
              ],
              [
                -73.91101447199998,
                40.86060126199995
              ],
              [
                -73.91075688299993,
                40.860739954999865
              ],
              [
                -73.91050960899994,
                40.86089126999988
              ],
              [
                -73.91027422499997,
                40.861054534999894
              ],
              [
                -73.91005219099996,
                40.86122888599988
              ],
              [
                -73.9098448269999,
                40.861413369999916
              ],
              [
                -73.90965329599997,
                40.86160687999992
              ],
              [
                -73.90947862999988,
                40.86180812899987
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000102864345211,
        "objectid": 236,
        "shape_leng": 0.0442519223099,
        "location_id": 236,
        "zone": "Upper East Side North",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.95779380499984,
                40.77359989699994
              ],
              [
                -73.95729409999986,
                40.7742835549999
              ],
              [
                -73.95679749099989,
                40.77496631099994
              ],
              [
                -73.95633492299991,
                40.77560078299992
              ],
              [
                -73.9558693369999,
                40.77623622599986
              ],
              [
                -73.9554072979999,
                40.776868479999926
              ],
              [
                -73.9549452009999,
                40.77750394699991
              ],
              [
                -73.95448224099997,
                40.77813866499987
              ],
              [
                -73.9539837019998,
                40.77882210999991
              ],
              [
                -73.95349212400005,
                40.77949662199995
              ],
              [
                -73.95303367999988,
                40.780125454999926
              ],
              [
                -73.95257421900006,
                40.780753033999865
              ],
              [
                -73.9521220329999,
                40.78138670999995
              ],
              [
                -73.95165920699998,
                40.78200767399993
              ],
              [
                -73.95119927299987,
                40.7826333919999
              ],
              [
                -73.95073913799986,
                40.783261709999884
              ],
              [
                -73.95028254899988,
                40.783890469999946
              ],
              [
                -73.9498239039999,
                40.784515570999886
              ],
              [
                -73.94933170599992,
                40.78519312699994
              ],
              [
                -73.95092733999991,
                40.78586544699993
              ],
              [
                -73.95244615099996,
                40.786502485999925
              ],
              [
                -73.95255145499999,
                40.78654858099986
              ],
              [
                -73.95266136699996,
                40.78659501999991
              ],
              [
                -73.95416383099995,
                40.78722686099993
              ],
              [
                -73.9557773589999,
                40.78791392399995
              ],
              [
                -73.95626130899994,
                40.78722287599987
              ],
              [
                -73.95671863099989,
                40.786600792999934
              ],
              [
                -73.95716853699999,
                40.785982893999865
              ],
              [
                -73.95763599400006,
                40.78534345799993
              ],
              [
                -73.95809563799989,
                40.78471746199995
              ],
              [
                -73.9585581369999,
                40.78409759899993
              ],
              [
                -73.95900363199986,
                40.783467289999855
              ],
              [
                -73.95946905999989,
                40.78283332099994
              ],
              [
                -73.959920943,
                40.78221294499987
              ],
              [
                -73.96041638899996,
                40.78153163299988
              ],
              [
                -73.96091849500006,
                40.78085062899991
              ],
              [
                -73.961368727,
                40.78022974899989
              ],
              [
                -73.96183836899999,
                40.77958419199989
              ],
              [
                -73.96230361500002,
                40.77894704099987
              ],
              [
                -73.9627671559999,
                40.778313040999876
              ],
              [
                -73.96322991899993,
                40.7776770469999
              ],
              [
                -73.96372602699994,
                40.77700571799989
              ],
              [
                -73.9637612389999,
                40.77695096899987
              ],
              [
                -73.96422820200003,
                40.776310308999946
              ],
              [
                -73.96468735799992,
                40.77567708199995
              ],
              [
                -73.96308760299976,
                40.775002841999886
              ],
              [
                -73.96158453899987,
                40.77436878399993
              ],
              [
                -73.9614758119999,
                40.77432401099989
              ],
              [
                -73.96136792699977,
                40.77427876099991
              ],
              [
                -73.95985681799993,
                40.7736427679999
              ],
              [
                -73.95825534899998,
                40.772965336999896
              ],
              [
                -73.95779380499984,
                40.77359989699994
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0000959873206621,
        "objectid": 237,
        "shape_leng": 0.0422126003388,
        "location_id": 237,
        "zone": "Upper East Side South",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.96612747299989,
                40.76217929999994
              ],
              [
                -73.96566959999997,
                40.76280455599994
              ],
              [
                -73.96521328099986,
                40.76343135899992
              ],
              [
                -73.96475544899988,
                40.76405706999992
              ],
              [
                -73.9642979039999,
                40.76468198699991
              ],
              [
                -73.96384291099993,
                40.76530968599997
              ],
              [
                -73.96340154099988,
                40.765911190999894
              ],
              [
                -73.96292865800001,
                40.76655862799987
              ],
              [
                -73.9624699899999,
                40.76718493299991
              ],
              [
                -73.96201413099995,
                40.76781230799988
              ],
              [
                -73.96155824299983,
                40.76843761199996
              ],
              [
                -73.9611017069999,
                40.7690593189999
              ],
              [
                -73.96060180599979,
                40.769749186999924
              ],
              [
                -73.96010955199993,
                40.77043221099989
              ],
              [
                -73.959644408,
                40.771064265999925
              ],
              [
                -73.9591817999999,
                40.77169682399995
              ],
              [
                -73.9587170739999,
                40.77233167499991
              ],
              [
                -73.95825534899998,
                40.772965336999896
              ],
              [
                -73.95985681799993,
                40.7736427679999
              ],
              [
                -73.96136792699977,
                40.77427876099991
              ],
              [
                -73.9614758119999,
                40.77432401099989
              ],
              [
                -73.96158453899987,
                40.77436878399993
              ],
              [
                -73.96308760299976,
                40.775002841999886
              ],
              [
                -73.96468735799992,
                40.77567708199995
              ],
              [
                -73.96514745399985,
                40.775044231999864
              ],
              [
                -73.96560968599992,
                40.77440720099987
              ],
              [
                -73.96607448999993,
                40.7737729889999
              ],
              [
                -73.96653616999981,
                40.7731403689999
              ],
              [
                -73.96703947599987,
                40.772461218999865
              ],
              [
                -73.96753398399983,
                40.77177618399991
              ],
              [
                -73.96799327799991,
                40.77114722999993
              ],
              [
                -73.96844155899997,
                40.77051632699992
              ],
              [
                -73.96890454699994,
                40.76990083199992
              ],
              [
                -73.96936484599993,
                40.76927065699993
              ],
              [
                -73.96982455299987,
                40.76864600899992
              ],
              [
                -73.97028165499981,
                40.768025031999905
              ],
              [
                -73.97072777399985,
                40.76739372199989
              ],
              [
                -73.97118812999996,
                40.766766592999915
              ],
              [
                -73.97164774299996,
                40.76614246799993
              ],
              [
                -73.97209852400005,
                40.76551725099991
              ],
              [
                -73.97255352299985,
                40.76489773199991
              ],
              [
                -73.97301487199995,
                40.76427887899991
              ],
              [
                -73.97141141699996,
                40.763587473999955
              ],
              [
                -73.9699048929999,
                40.762958042999834
              ],
              [
                -73.96980028899992,
                40.76291351199995
              ],
              [
                -73.96968801199992,
                40.76286596199986
              ],
              [
                -73.96818687799986,
                40.76222682099993
              ],
              [
                -73.9665833839999,
                40.76155093499988
              ],
              [
                -73.96612747299989,
                40.76217929999994
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000184763693765,
        "objectid": 238,
        "shape_leng": 0.0601093114033,
        "location_id": 238,
        "zone": "Upper West Side North",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.96884378999985,
                40.785967388999964
              ],
              [
                -73.96838479399985,
                40.786595696999896
              ],
              [
                -73.9679269639999,
                40.78722157099988
              ],
              [
                -73.96744908399992,
                40.78786072099995
              ],
              [
                -73.96700977099991,
                40.788476789999905
              ],
              [
                -73.96655226699976,
                40.78910715299992
              ],
              [
                -73.96609500599993,
                40.78973438899991
              ],
              [
                -73.96563269799995,
                40.79036540399989
              ],
              [
                -73.96517705600003,
                40.79099034099997
              ],
              [
                -73.96468540699995,
                40.791664026999904
              ],
              [
                -73.96417598599989,
                40.792362044999884
              ],
              [
                -73.96525997299999,
                40.79280137099989
              ],
              [
                -73.96644741199998,
                40.793314953999904
              ],
              [
                -73.96701396999998,
                40.793559990999924
              ],
              [
                -73.96987063299996,
                40.79473520499991
              ],
              [
                -73.96941543899999,
                40.79536077899998
              ],
              [
                -73.97073269599991,
                40.79591916999989
              ],
              [
                -73.97082734199991,
                40.79595859999988
              ],
              [
                -73.97093073199993,
                40.79600104799988
              ],
              [
                -73.97225661799988,
                40.79656086499989
              ],
              [
                -73.97365145399995,
                40.797148317999934
              ],
              [
                -73.97413148099996,
                40.79734378799992
              ],
              [
                -73.97507069999986,
                40.797713349999874
              ],
              [
                -73.97521014699981,
                40.79777354799995
              ],
              [
                -73.97539813999988,
                40.7978477439999
              ],
              [
                -73.97549419399992,
                40.79788855199996
              ],
              [
                -73.97563350899993,
                40.797946205999914
              ],
              [
                -73.97582955899989,
                40.798021996999935
              ],
              [
                -73.97635598099994,
                40.798254020999956
              ],
              [
                -73.97639951965265,
                40.79827321084625
              ],
              [
                -73.97685207845178,
                40.79763134839319
              ],
              [
                -73.9777947550319,
                40.79628462977193
              ],
              [
                -73.97828937313398,
                40.795586819072305
              ],
              [
                -73.9792881710042,
                40.7941776574507
              ],
              [
                -73.98004684398002,
                40.79311352516387
              ],
              [
                -73.98013222578668,
                40.79299376538316
              ],
              [
                -73.98043434255987,
                40.792594283096705
              ],
              [
                -73.980537679464,
                40.79245681262499
              ],
              [
                -73.9809470835407,
                40.79186789378255
              ],
              [
                -73.98128937370942,
                40.79137550943299
              ],
              [
                -73.98139174468552,
                40.79122824600514
              ],
              [
                -73.98188645923244,
                40.7905165804675
              ],
              [
                -73.98185980499981,
                40.79050307799984
              ],
              [
                -73.98176573099992,
                40.790455425999916
              ],
              [
                -73.98169328900003,
                40.79041872799998
              ],
              [
                -73.9815945909999,
                40.79038412399996
              ],
              [
                -73.98097150799998,
                40.79016087799991
              ],
              [
                -73.98035659399986,
                40.78994055799996
              ],
              [
                -73.97785649899998,
                40.78888153899993
              ],
              [
                -73.97660504199992,
                40.78835448099992
              ],
              [
                -73.97650149399988,
                40.788312676999944
              ],
              [
                -73.97639149199995,
                40.78826674699993
              ],
              [
                -73.97501417199996,
                40.78768560599987
              ],
              [
                -73.97217414999986,
                40.78648646099994
              ],
              [
                -73.96933573299991,
                40.78529327999992
              ],
              [
                -73.96884378999985,
                40.785967388999964
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000722130920707,
        "objectid": 240,
        "shape_leng": 0.146069764379,
        "location_id": 240,
        "zone": "Van Cortlandt Park",
        "borough": "Bronx"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.87643743099994,
                40.89687059299995
              ],
              [
                -73.87589465400002,
                40.8975594589999
              ],
              [
                -73.87482041599985,
                40.898445437999925
              ],
              [
                -73.87388944299983,
                40.89896546299988
              ],
              [
                -73.87301813899998,
                40.89935490499995
              ],
              [
                -73.87136373199995,
                40.900039151999906
              ],
              [
                -73.87010369599989,
                40.90074194699992
              ],
              [
                -73.86899707599991,
                40.901559154999916
              ],
              [
                -73.86820270299982,
                40.90235538299993
              ],
              [
                -73.86816613899995,
                40.9024482979999
              ],
              [
                -73.86789043706806,
                40.90298695407611
              ],
              [
                -73.87095251943089,
                40.90385025285701
              ],
              [
                -73.87294860376426,
                40.9044410228341
              ],
              [
                -73.87416054892165,
                40.90478128311993
              ],
              [
                -73.87483045244237,
                40.90494378318404
              ],
              [
                -73.87563292109846,
                40.90520821290865
              ],
              [
                -73.87646714999133,
                40.90544411710721
              ],
              [
                -73.8773681710897,
                40.90571165693521
              ],
              [
                -73.87786550388269,
                40.905869209927374
              ],
              [
                -73.87803672199264,
                40.90592860716306
              ],
              [
                -73.87807189372022,
                40.90593244410284
              ],
              [
                -73.87811197786539,
                40.90594376004156
              ],
              [
                -73.8781677757751,
                40.90595550824275
              ],
              [
                -73.87836160112977,
                40.90600363600307
              ],
              [
                -73.88148094380769,
                40.906891856850486
              ],
              [
                -73.88303257421012,
                40.90733363107852
              ],
              [
                -73.8840540725523,
                40.90770269719357
              ],
              [
                -73.88469597226361,
                40.907898700147676
              ],
              [
                -73.8848002805031,
                40.9079307362925
              ],
              [
                -73.88561523020898,
                40.90818102440861
              ],
              [
                -73.88599855043816,
                40.908297124986035
              ],
              [
                -73.88605575389856,
                40.90831445280059
              ],
              [
                -73.88610703995391,
                40.908329271126796
              ],
              [
                -73.89246906581641,
                40.91016766410472
              ],
              [
                -73.89663333831646,
                40.911417374923126
              ],
              [
                -73.8966306859999,
                40.91129892699993
              ],
              [
                -73.89663068599988,
                40.91129887799991
              ],
              [
                -73.89660613599976,
                40.91020224899992
              ],
              [
                -73.89659583799997,
                40.90962765899994
              ],
              [
                -73.8965465329998,
                40.907506654999956
              ],
              [
                -73.89649229399996,
                40.90633965199994
              ],
              [
                -73.89643503499988,
                40.905092998999855
              ],
              [
                -73.896411335,
                40.904504520999915
              ],
              [
                -73.89635785999997,
                40.9032001319999
              ],
              [
                -73.89640895699986,
                40.9029611079999
              ],
              [
                -73.89661002199993,
                40.90220351299991
              ],
              [
                -73.89687844599996,
                40.90104057999989
              ],
              [
                -73.89697054199992,
                40.90061116799995
              ],
              [
                -73.89702895099995,
                40.90031520399991
              ],
              [
                -73.8970336369998,
                40.900278620999885
              ],
              [
                -73.89704651399992,
                40.90022470299987
              ],
              [
                -73.89707329199993,
                40.90008389099995
              ],
              [
                -73.89713947299998,
                40.8996194349999
              ],
              [
                -73.89715717199994,
                40.89937182699986
              ],
              [
                -73.89709149099991,
                40.89859331799995
              ],
              [
                -73.896994929,
                40.897878723999916
              ],
              [
                -73.89670747099987,
                40.89580502899987
              ],
              [
                -73.89643170799985,
                40.89365527899989
              ],
              [
                -73.89675790099987,
                40.892645443999925
              ],
              [
                -73.89705763499997,
                40.89214045799991
              ],
              [
                -73.89728112499986,
                40.89171068399993
              ],
              [
                -73.89731936199985,
                40.8916158609999
              ],
              [
                -73.89769601099988,
                40.890681823999955
              ],
              [
                -73.89776669099993,
                40.89050938999991
              ],
              [
                -73.89787887499983,
                40.89027610099993
              ],
              [
                -73.89827216899992,
                40.8894579429999
              ],
              [
                -73.89922499099997,
                40.88753080299989
              ],
              [
                -73.89936426899996,
                40.88724908999995
              ],
              [
                -73.89965185599996,
                40.88666493399991
              ],
              [
                -73.89972588499982,
                40.886514886999926
              ],
              [
                -73.89892571799993,
                40.88638350199994
              ],
              [
                -73.89738336099998,
                40.88608677199995
              ],
              [
                -73.89715645399986,
                40.88604482399989
              ],
              [
                -73.89688178399989,
                40.88599934699987
              ],
              [
                -73.89668242999996,
                40.885964589999965
              ],
              [
                -73.89661546599986,
                40.885955255999946
              ],
              [
                -73.89652069099986,
                40.88593698899992
              ],
              [
                -73.89595674999993,
                40.885828324999906
              ],
              [
                -73.89508270700003,
                40.885641511999914
              ],
              [
                -73.89467529599987,
                40.88553264999992
              ],
              [
                -73.89288644500004,
                40.88526430599993
              ],
              [
                -73.89176617899982,
                40.88504984599993
              ],
              [
                -73.89080543899989,
                40.88486784299996
              ],
              [
                -73.88984701899983,
                40.8846857469999
              ],
              [
                -73.88889642199983,
                40.88450391899991
              ],
              [
                -73.88840157099996,
                40.88441707499996
              ],
              [
                -73.88758612599987,
                40.88425561899989
              ],
              [
                -73.88705142500004,
                40.88434957599993
              ],
              [
                -73.88692889699999,
                40.88435571399992
              ],
              [
                -73.88683486599984,
                40.884342025999935
              ],
              [
                -73.88662412999999,
                40.88426730299991
              ],
              [
                -73.88516495799995,
                40.883631611999924
              ],
              [
                -73.88452615199998,
                40.88331214699986
              ],
              [
                -73.88437922299993,
                40.883247722999954
              ],
              [
                -73.88437905799985,
                40.883247650999905
              ],
              [
                -73.88437887799986,
                40.88324759799986
              ],
              [
                -73.88421696399995,
                40.883197727999914
              ],
              [
                -73.88421683399994,
                40.88319769199991
              ],
              [
                -73.88421671500001,
                40.88319766499993
              ],
              [
                -73.88404353399987,
                40.88316500199992
              ],
              [
                -73.88404341599993,
                40.88316498399992
              ],
              [
                -73.88404328600001,
                40.883164973999946
              ],
              [
                -73.88386391399983,
                40.88315139399986
              ],
              [
                -73.8838637469999,
                40.883151384999955
              ],
              [
                -73.88386358099999,
                40.883151393999874
              ],
              [
                -73.88368347599999,
                40.88315761499991
              ],
              [
                -73.883683369,
                40.883157614999966
              ],
              [
                -73.88368327399996,
                40.88315763299994
              ],
              [
                -73.88350810399999,
                40.883183146999954
              ],
              [
                -73.8835078789999,
                40.883183183999975
              ],
              [
                -73.88350764199998,
                40.883183245999874
              ],
              [
                -73.88334258599987,
                40.88322637399991
              ],
              [
                -73.88334234799999,
                40.88322643699995
              ],
              [
                -73.88334213499988,
                40.88322651799995
              ],
              [
                -73.88319088699987,
                40.88328483399989
              ],
              [
                -73.88274833399998,
                40.88339738699995
              ],
              [
                -73.88208623599999,
                40.883132220999904
              ],
              [
                -73.88124422499992,
                40.882709632999905
              ],
              [
                -73.87995798699986,
                40.884390627999885
              ],
              [
                -73.87936283199987,
                40.8852182399999
              ],
              [
                -73.87876041599995,
                40.88604019899993
              ],
              [
                -73.8781494459999,
                40.88687380699995
              ],
              [
                -73.878131292,
                40.88691279699989
              ],
              [
                -73.87803562399996,
                40.88711832199991
              ],
              [
                -73.87799008999986,
                40.8870503589999
              ],
              [
                -73.87783125799984,
                40.88677275999992
              ],
              [
                -73.87760676499983,
                40.884523043999884
              ],
              [
                -73.87772817699982,
                40.88345419499996
              ],
              [
                -73.87812594399992,
                40.8821538259999
              ],
              [
                -73.87717005999981,
                40.88199391299993
              ],
              [
                -73.87625890099994,
                40.88183203999991
              ],
              [
                -73.87533699399987,
                40.88167183199992
              ],
              [
                -73.87467601499999,
                40.88156285199994
              ],
              [
                -73.87479240699987,
                40.880075128999884
              ],
              [
                -73.87494589799991,
                40.87994766899988
              ],
              [
                -73.87406751299994,
                40.880038905999896
              ],
              [
                -73.87307334600001,
                40.88013438499995
              ],
              [
                -73.87191803300003,
                40.88023746599988
              ],
              [
                -73.87187100700007,
                40.880328119999966
              ],
              [
                -73.87084076999994,
                40.88043889399995
              ],
              [
                -73.87024100099985,
                40.880229352999955
              ],
              [
                -73.87094367500002,
                40.87851076699998
              ],
              [
                -73.87033725199989,
                40.87835140299997
              ],
              [
                -73.8701497639999,
                40.87831012099989
              ],
              [
                -73.87005983899994,
                40.87829031699993
              ],
              [
                -73.86996807399987,
                40.878264351999924
              ],
              [
                -73.86978338299986,
                40.87821297799992
              ],
              [
                -73.86950315900006,
                40.87814566499988
              ],
              [
                -73.86943471300005,
                40.87812919899994
              ],
              [
                -73.8692538419999,
                40.87843112499994
              ],
              [
                -73.869221773,
                40.87846483499988
              ],
              [
                -73.86918507299987,
                40.87850341099989
              ],
              [
                -73.86911192999993,
                40.87854672299994
              ],
              [
                -73.86902622499986,
                40.87857616999986
              ],
              [
                -73.86893508399992,
                40.87858929599985
              ],
              [
                -73.8688461339999,
                40.878586356999904
              ],
              [
                -73.86811829499993,
                40.88006228299993
              ],
              [
                -73.86705258299985,
                40.88225521599989
              ],
              [
                -73.86605231299984,
                40.88428704399994
              ],
              [
                -73.86603813199996,
                40.88435902199995
              ],
              [
                -73.86603809599988,
                40.884359192999945
              ],
              [
                -73.86603810799987,
                40.8843593819999
              ],
              [
                -73.86604065099993,
                40.88443298199994
              ],
              [
                -73.86604066399985,
                40.884433234999975
              ],
              [
                -73.86604073399987,
                40.8844334769999
              ],
              [
                -73.86606024899994,
                40.88450565599988
              ],
              [
                -73.86606028499985,
                40.884505799999914
              ],
              [
                -73.8660603429999,
                40.88450592699994
              ],
              [
                -73.86609568299987,
                40.88457362099996
              ],
              [
                -73.86616465799986,
                40.88467792299987
              ],
              [
                -73.86616476499985,
                40.88467807699996
              ],
              [
                -73.86616484799998,
                40.88467823899988
              ],
              [
                -73.86621797999994,
                40.88479001499995
              ],
              [
                -73.86625345799992,
                40.88490729999993
              ],
              [
                -73.86627000499995,
                40.885027299999884
              ],
              [
                -73.86627001699995,
                40.88502738899994
              ],
              [
                -73.86627001699986,
                40.88502747899989
              ],
              [
                -73.86626727199992,
                40.885147690999865
              ],
              [
                -73.86624574899986,
                40.88526544999991
              ],
              [
                -73.86624572499991,
                40.88526556799994
              ],
              [
                -73.86624567599982,
                40.88526569299991
              ],
              [
                -73.86620661499991,
                40.88537861399994
              ],
              [
                -73.86538049299986,
                40.88688347499992
              ],
              [
                -73.86460021999999,
                40.888299711999906
              ],
              [
                -73.86381255499994,
                40.889716780999954
              ],
              [
                -73.86304439099993,
                40.89111565099993
              ],
              [
                -73.86276234599985,
                40.89162005699992
              ],
              [
                -73.86280050100001,
                40.8917207669999
              ],
              [
                -73.86274739499996,
                40.89184357499996
              ],
              [
                -73.86225345500002,
                40.892670487999965
              ],
              [
                -73.86215575100003,
                40.89283406299997
              ],
              [
                -73.86198229599997,
                40.893161261999936
              ],
              [
                -73.86192535099993,
                40.89329121599993
              ],
              [
                -73.86149253599987,
                40.89427891699995
              ],
              [
                -73.86140513999995,
                40.894539043999906
              ],
              [
                -73.86135927899984,
                40.894662318999934
              ],
              [
                -73.86176180699982,
                40.89479118399992
              ],
              [
                -73.861825324,
                40.89481152099992
              ],
              [
                -73.86190465899992,
                40.89483740499985
              ],
              [
                -73.86209147100006,
                40.89489836099992
              ],
              [
                -73.86214409299994,
                40.89491552999991
              ],
              [
                -73.86219003099983,
                40.89493051899997
              ],
              [
                -73.86287533299995,
                40.8951540299999
              ],
              [
                -73.86330852999994,
                40.89529484799994
              ],
              [
                -73.86355915799994,
                40.89537701699987
              ],
              [
                -73.8640163769999,
                40.8955230469999
              ],
              [
                -73.86491632499997,
                40.89574949499992
              ],
              [
                -73.86691694899986,
                40.89608268499996
              ],
              [
                -73.87006981099995,
                40.89659073699991
              ],
              [
                -73.87048672599987,
                40.89664021099991
              ],
              [
                -73.87241034499989,
                40.89624128599994
              ],
              [
                -73.87480618199984,
                40.89572568799985
              ],
              [
                -73.87572387299993,
                40.89562260099997
              ],
              [
                -73.8771197409999,
                40.895468663999985
              ],
              [
                -73.87643743099994,
                40.89687059299995
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.00025467697592,
        "objectid": 241,
        "shape_leng": 0.0687645777649,
        "location_id": 241,
        "zone": "Van Cortlandt Village",
        "borough": "Bronx"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.88840157099996,
                40.88441707499996
              ],
              [
                -73.88889642199983,
                40.88450391899991
              ],
              [
                -73.88984701899993,
                40.884685745999924
              ],
              [
                -73.89080543899989,
                40.88486784299996
              ],
              [
                -73.89176617899982,
                40.88504984599993
              ],
              [
                -73.89288644599986,
                40.885264305999975
              ],
              [
                -73.8946752969999,
                40.88553264999998
              ],
              [
                -73.89508270700003,
                40.885641511999914
              ],
              [
                -73.89595674999993,
                40.885828324999906
              ],
              [
                -73.89652068999983,
                40.88593698899992
              ],
              [
                -73.89661546599986,
                40.885955255999946
              ],
              [
                -73.897112233,
                40.88556943599986
              ],
              [
                -73.89760048499983,
                40.88517729399996
              ],
              [
                -73.89781492099993,
                40.88499918299995
              ],
              [
                -73.89808000699986,
                40.884778999999874
              ],
              [
                -73.89835487899985,
                40.88454286499998
              ],
              [
                -73.89843428799998,
                40.88447464199989
              ],
              [
                -73.89855059899999,
                40.884374726999965
              ],
              [
                -73.89914458899997,
                40.88368756599993
              ],
              [
                -73.89988688799993,
                40.88282880899994
              ],
              [
                -73.9015065839999,
                40.88095490599994
              ],
              [
                -73.90226256899994,
                40.88010809899995
              ],
              [
                -73.90333605399985,
                40.87897514599996
              ],
              [
                -73.90369454799992,
                40.87850161799988
              ],
              [
                -73.903898338,
                40.87812110799993
              ],
              [
                -73.90417988499996,
                40.87733663399998
              ],
              [
                -73.90448826999989,
                40.87647735999989
              ],
              [
                -73.90465612299992,
                40.876058915999856
              ],
              [
                -73.90483848999986,
                40.875643742999905
              ],
              [
                -73.90503517999984,
                40.87523228299991
              ],
              [
                -73.90524600299995,
                40.87482494899992
              ],
              [
                -73.90526442899994,
                40.8747911099999
              ],
              [
                -73.90556943300004,
                40.87423099199992
              ],
              [
                -73.90605459699991,
                40.87329392499994
              ],
              [
                -73.90608130599988,
                40.873239628999904
              ],
              [
                -73.90601809399988,
                40.8732269889999
              ],
              [
                -73.90591126399978,
                40.87319581699988
              ],
              [
                -73.90586616799999,
                40.873182659999884
              ],
              [
                -73.905714254,
                40.87313832199988
              ],
              [
                -73.904677283,
                40.872619878999934
              ],
              [
                -73.90459203199988,
                40.872564553999894
              ],
              [
                -73.90459194899984,
                40.87256449999991
              ],
              [
                -73.90459188899993,
                40.872564445999956
              ],
              [
                -73.90452088299993,
                40.872495391999976
              ],
              [
                -73.90452070499992,
                40.87249521099987
              ],
              [
                -73.90452057499998,
                40.87249502299987
              ],
              [
                -73.90446908599996,
                40.872415627999935
              ],
              [
                -73.9044690149999,
                40.87241552099993
              ],
              [
                -73.9044689679998,
                40.872415393999944
              ],
              [
                -73.90444010799983,
                40.87233049899992
              ],
              [
                -73.90444008499996,
                40.872330418999866
              ],
              [
                -73.90444007299996,
                40.872330327999855
              ],
              [
                -73.9044342879998,
                40.87224530899994
              ],
              [
                -73.90448860599989,
                40.870578548999895
              ],
              [
                -73.90442003199995,
                40.8703612759999
              ],
              [
                -73.90403592599995,
                40.86994916299995
              ],
              [
                -73.90374975899987,
                40.869595626999924
              ],
              [
                -73.90318667499999,
                40.86914899099989
              ],
              [
                -73.90235804799984,
                40.8686961209999
              ],
              [
                -73.90106211299995,
                40.86828264799995
              ],
              [
                -73.89996501599988,
                40.86801852399987
              ],
              [
                -73.89942436199988,
                40.86788083799993
              ],
              [
                -73.89965220299995,
                40.86855457799991
              ],
              [
                -73.89960300399986,
                40.868728207999894
              ],
              [
                -73.8995340439999,
                40.86889961299995
              ],
              [
                -73.89944561599987,
                40.86906684199993
              ],
              [
                -73.89933851199986,
                40.86922802799989
              ],
              [
                -73.89921387699998,
                40.86938138699989
              ],
              [
                -73.8990732729999,
                40.86952535499991
              ],
              [
                -73.89891854299988,
                40.86965857399992
              ],
              [
                -73.89656060499982,
                40.868565199999935
              ],
              [
                -73.8974032329998,
                40.86747418999991
              ],
              [
                -73.89631315699987,
                40.86703857499991
              ],
              [
                -73.89544263399986,
                40.86660208899987
              ],
              [
                -73.89536151600007,
                40.866561330999964
              ],
              [
                -73.89453048799989,
                40.86614380899987
              ],
              [
                -73.89438130299993,
                40.86606885099997
              ],
              [
                -73.89436438800003,
                40.866091671999854
              ],
              [
                -73.89431825299994,
                40.86615392699989
              ],
              [
                -73.89408697099991,
                40.86645343299996
              ],
              [
                -73.89397201799989,
                40.8666023039999
              ],
              [
                -73.8937613899999,
                40.866875057999955
              ],
              [
                -73.89368435599988,
                40.86698082099989
              ],
              [
                -73.89300269999988,
                40.86788106799988
              ],
              [
                -73.89243509099991,
                40.86862059799995
              ],
              [
                -73.89221258899984,
                40.86891549999992
              ],
              [
                -73.8919171829999,
                40.86930210999989
              ],
              [
                -73.89174633499988,
                40.86951054799996
              ],
              [
                -73.89157400099988,
                40.86968425299996
              ],
              [
                -73.89138946999998,
                40.869850993999904
              ],
              [
                -73.89119350399992,
                40.8700100329999
              ],
              [
                -73.89098696999984,
                40.870160714999905
              ],
              [
                -73.89042763899991,
                40.87048705399993
              ],
              [
                -73.88991744500004,
                40.870780631999864
              ],
              [
                -73.88950049199994,
                40.871020555999955
              ],
              [
                -73.88884301599997,
                40.871398066999866
              ],
              [
                -73.88868449900004,
                40.871495112999895
              ],
              [
                -73.88833546999989,
                40.871709830999905
              ],
              [
                -73.8879350369998,
                40.87203023199987
              ],
              [
                -73.88785196299986,
                40.87211417599992
              ],
              [
                -73.88798774400004,
                40.87222749099988
              ],
              [
                -73.88857987899992,
                40.872721639999924
              ],
              [
                -73.88863187299991,
                40.872765030999865
              ],
              [
                -73.88937163799983,
                40.87338229799992
              ],
              [
                -73.88855699899982,
                40.87519731299991
              ],
              [
                -73.88824333699998,
                40.87589219499995
              ],
              [
                -73.88808032199985,
                40.876272745999884
              ],
              [
                -73.88773577599993,
                40.87703388199988
              ],
              [
                -73.88567517999986,
                40.87887251099988
              ],
              [
                -73.88575540599997,
                40.87931311799986
              ],
              [
                -73.88578568400006,
                40.879616873999964
              ],
              [
                -73.88582680399998,
                40.879907896999896
              ],
              [
                -73.88595071699983,
                40.88050741199993
              ],
              [
                -73.88706962799996,
                40.88247689599992
              ],
              [
                -73.88716505199984,
                40.882576510999876
              ],
              [
                -73.88694246799994,
                40.8826827669999
              ],
              [
                -73.88697007500001,
                40.8827956429999
              ],
              [
                -73.8872319399999,
                40.883054977999905
              ],
              [
                -73.88718101599989,
                40.88308394199995
              ],
              [
                -73.8871469759999,
                40.88311793799995
              ],
              [
                -73.88713206499988,
                40.88315094499994
              ],
              [
                -73.88712303599982,
                40.88320093999989
              ],
              [
                -73.88710703299991,
                40.88367398599991
              ],
              [
                -73.88710004499984,
                40.88372994399993
              ],
              [
                -73.88708705799996,
                40.88379702699996
              ],
              [
                -73.88704303999992,
                40.88404023199992
              ],
              [
                -73.88705142599984,
                40.88434957599993
              ],
              [
                -73.88758612599987,
                40.88425561899989
              ],
              [
                -73.88840157099996,
                40.88441707499996
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000366209617143,
        "objectid": 258,
        "shape_leng": 0.0890133787693,
        "location_id": 258,
        "zone": "Woodhaven",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.8450419489999,
                40.68931894699996
              ],
              [
                -73.84596733000002,
                40.69116596499992
              ],
              [
                -73.84661793899996,
                40.6924951829999
              ],
              [
                -73.84773325500002,
                40.69477527999989
              ],
              [
                -73.84894128999987,
                40.69755684799992
              ],
              [
                -73.84947763499991,
                40.69819580699987
              ],
              [
                -73.84913837299976,
                40.69828274099998
              ],
              [
                -73.85070842300001,
                40.70020312499988
              ],
              [
                -73.85410116499996,
                40.70051374799992
              ],
              [
                -73.85443455699989,
                40.70060295199994
              ],
              [
                -73.85429389599983,
                40.70044576099992
              ],
              [
                -73.85377512599996,
                40.6999095679999
              ],
              [
                -73.85328620599984,
                40.69928735699989
              ],
              [
                -73.8527113089999,
                40.698414961999966
              ],
              [
                -73.85265915099988,
                40.698266471999915
              ],
              [
                -73.85262446500002,
                40.698114106999896
              ],
              [
                -73.85260789899989,
                40.697959664999885
              ],
              [
                -73.8526096609999,
                40.69780503999987
              ],
              [
                -73.85262948599983,
                40.697652077999926
              ],
              [
                -73.85277238699999,
                40.697557111999885
              ],
              [
                -73.85283544399991,
                40.69754896199987
              ],
              [
                -73.85478796099983,
                40.697296622999886
              ],
              [
                -73.85567421900002,
                40.69717935799988
              ],
              [
                -73.85657185199995,
                40.69706469199983
              ],
              [
                -73.85744447700002,
                40.696912984999905
              ],
              [
                -73.8583307899999,
                40.69674371099995
              ],
              [
                -73.85928498399986,
                40.69655371099988
              ],
              [
                -73.86016364299988,
                40.69638465799991
              ],
              [
                -73.86236834199985,
                40.69595014299993
              ],
              [
                -73.86368656399996,
                40.69569141799994
              ],
              [
                -73.86455708899989,
                40.695520456999915
              ],
              [
                -73.86681433899994,
                40.69506535099989
              ],
              [
                -73.86842489800001,
                40.69471811999993
              ],
              [
                -73.86868454399996,
                40.694034691999924
              ],
              [
                -73.86859478099983,
                40.693884374999854
              ],
              [
                -73.86805885299988,
                40.69118395799991
              ],
              [
                -73.867909485,
                40.69047626099993
              ],
              [
                -73.86776832199985,
                40.68980328899988
              ],
              [
                -73.86762340399984,
                40.6891453419999
              ],
              [
                -73.867460889,
                40.68841501799992
              ],
              [
                -73.86728057399996,
                40.68774928599988
              ],
              [
                -73.86701802999985,
                40.686830348999884
              ],
              [
                -73.86687058599995,
                40.686330590999944
              ],
              [
                -73.86659848299999,
                40.68526955899993
              ],
              [
                -73.86650407899992,
                40.68447405199987
              ],
              [
                -73.86631496899999,
                40.683248942999924
              ],
              [
                -73.86630263900003,
                40.68318669399985
              ],
              [
                -73.86629002999987,
                40.68311500699992
              ],
              [
                -73.86602666899996,
                40.681918051999915
              ],
              [
                -73.8649940449999,
                40.68212896999992
              ],
              [
                -73.86410096700003,
                40.682372849999894
              ],
              [
                -73.86399008800004,
                40.681928575999954
              ],
              [
                -73.86378674500001,
                40.68111374699991
              ],
              [
                -73.86334943599995,
                40.6793516399999
              ],
              [
                -73.86229626199984,
                40.67963157999993
              ],
              [
                -73.86156047099999,
                40.679839967999875
              ],
              [
                -73.861423295,
                40.67987881299996
              ],
              [
                -73.86066198699996,
                40.68009753799986
              ],
              [
                -73.86053300099985,
                40.68013444799992
              ],
              [
                -73.85977462599983,
                40.6803507119999
              ],
              [
                -73.85966271799995,
                40.68038237199996
              ],
              [
                -73.85929512400006,
                40.68048636599984
              ],
              [
                -73.85878576899987,
                40.68063046299989
              ],
              [
                -73.85819836099995,
                40.67943084899987
              ],
              [
                -73.85779268399989,
                40.67945571699988
              ],
              [
                -73.85721078599984,
                40.67948586399992
              ],
              [
                -73.85683137000001,
                40.6795093379999
              ],
              [
                -73.85653410299985,
                40.67952772499996
              ],
              [
                -73.85439330799991,
                40.67966011399989
              ],
              [
                -73.85392389199987,
                40.67968615599988
              ],
              [
                -73.85267726099994,
                40.68007941499991
              ],
              [
                -73.85223417099995,
                40.68020789099992
              ],
              [
                -73.85168205499998,
                40.68036797699996
              ],
              [
                -73.85099089299987,
                40.68056836699988
              ],
              [
                -73.85131773799995,
                40.681240015999926
              ],
              [
                -73.851639288,
                40.681886271999936
              ],
              [
                -73.85197864599989,
                40.68256421099987
              ],
              [
                -73.85087162799985,
                40.68225954199994
              ],
              [
                -73.85060478199983,
                40.68219378299992
              ],
              [
                -73.8497640749999,
                40.681986613999925
              ],
              [
                -73.84921620599982,
                40.68184421099987
              ],
              [
                -73.84863383999999,
                40.68168402799994
              ],
              [
                -73.84786817599985,
                40.681486831999926
              ],
              [
                -73.84757845099998,
                40.68157986799986
              ],
              [
                -73.84671464299996,
                40.68185849299988
              ],
              [
                -73.84583237399981,
                40.68210644999987
              ],
              [
                -73.84529042399988,
                40.68223044499991
              ],
              [
                -73.84520200299993,
                40.682250667999895
              ],
              [
                -73.84513349899997,
                40.68226653299996
              ],
              [
                -73.84505837899984,
                40.682284450999866
              ],
              [
                -73.84496190700004,
                40.682307454999936
              ],
              [
                -73.84451063800005,
                40.68241508799991
              ],
              [
                -73.84408914999996,
                40.68252929899994
              ],
              [
                -73.84323090399981,
                40.682775196999884
              ],
              [
                -73.84237029099975,
                40.68301842199991
              ],
              [
                -73.84151391999985,
                40.6832616809999
              ],
              [
                -73.84134091999981,
                40.68331050299987
              ],
              [
                -73.8411960569998,
                40.683351365999904
              ],
              [
                -73.84217464599998,
                40.68535355899992
              ],
              [
                -73.84300297399979,
                40.687037915999895
              ],
              [
                -73.84356447799996,
                40.68821396099985
              ],
              [
                -73.84386364499983,
                40.68883211199997
              ],
              [
                -73.84415010299988,
                40.68939328599993
              ],
              [
                -73.84419150899994,
                40.68947440799992
              ],
              [
                -73.84430714400003,
                40.689441667999894
              ],
              [
                -73.84500533199989,
                40.68924391099989
              ],
              [
                -73.8450419489999,
                40.68931894699996
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000360072450014,
        "objectid": 242,
        "shape_leng": 0.138136446433,
        "location_id": 242,
        "zone": "Van Nest/Morris Park",
        "borough": "Bronx"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.83593362199987,
                40.84840635599993
              ],
              [
                -73.83580031399995,
                40.84967681599991
              ],
              [
                -73.83555900199993,
                40.85196401299993
              ],
              [
                -73.83545671599992,
                40.85226119199991
              ],
              [
                -73.83533589099999,
                40.852554697999935
              ],
              [
                -73.83519691899983,
                40.85284353099991
              ],
              [
                -73.83504028899982,
                40.8531267369999
              ],
              [
                -73.83480132399997,
                40.85349832599997
              ],
              [
                -73.83473123599998,
                40.85366018699991
              ],
              [
                -73.83463970199992,
                40.85381799399991
              ],
              [
                -73.83452741999996,
                40.85396932299989
              ],
              [
                -73.83439574299983,
                40.85411187099993
              ],
              [
                -73.83424656499982,
                40.85424355199993
              ],
              [
                -73.83408232099995,
                40.854362639999906
              ],
              [
                -73.83390573999993,
                40.85446777999996
              ],
              [
                -73.83350017799994,
                40.85467968399999
              ],
              [
                -73.83295928399993,
                40.854924105999956
              ],
              [
                -73.832624647,
                40.855056966999925
              ],
              [
                -73.83228148899984,
                40.85517744999994
              ],
              [
                -73.83193102199998,
                40.85528511599992
              ],
              [
                -73.83157449000001,
                40.855379596999946
              ],
              [
                -73.83129157699987,
                40.85543410499991
              ],
              [
                -73.83147565799987,
                40.85545085299986
              ],
              [
                -73.83165843199998,
                40.85548344799993
              ],
              [
                -73.83183634999988,
                40.855531711999966
              ],
              [
                -73.83200598799996,
                40.855594876999916
              ],
              [
                -73.83216420699983,
                40.85567161299986
              ],
              [
                -73.83229711899999,
                40.85574641199985
              ],
              [
                -73.83242972799992,
                40.85582103999991
              ],
              [
                -73.83281265300002,
                40.85597971299992
              ],
              [
                -73.83283502299986,
                40.85603584499989
              ],
              [
                -73.83276219999989,
                40.856079539999925
              ],
              [
                -73.83268903299997,
                40.85613238299992
              ],
              [
                -73.83238468599995,
                40.856352210999916
              ],
              [
                -73.8321371229998,
                40.85654369099994
              ],
              [
                -73.83193393799985,
                40.85669351699989
              ],
              [
                -73.83128338399985,
                40.857349961999894
              ],
              [
                -73.83111449199998,
                40.85752432999991
              ],
              [
                -73.83072266700005,
                40.857994586999915
              ],
              [
                -73.83055471299986,
                40.85819039599994
              ],
              [
                -73.82959409199992,
                40.85935976399989
              ],
              [
                -73.82872856899989,
                40.860413327999915
              ],
              [
                -73.82847346699994,
                40.86073135299991
              ],
              [
                -73.8288197809999,
                40.860449112999916
              ],
              [
                -73.82945155499975,
                40.86000056899992
              ],
              [
                -73.82960951799994,
                40.85988842099988
              ],
              [
                -73.82973719699993,
                40.859809734999885
              ],
              [
                -73.829874887,
                40.85974041999991
              ],
              [
                -73.83002075599987,
                40.85968150899989
              ],
              [
                -73.83017269399993,
                40.85963375399996
              ],
              [
                -73.83103340899994,
                40.85934303399997
              ],
              [
                -73.83190625000003,
                40.8590701989999
              ],
              [
                -73.83308051999994,
                40.85867901899998
              ],
              [
                -73.83348699499999,
                40.85852949499987
              ],
              [
                -73.83437708299991,
                40.85819084899987
              ],
              [
                -73.83533372599987,
                40.85783130099991
              ],
              [
                -73.83628873399995,
                40.85747692099994
              ],
              [
                -73.83681918499988,
                40.85727680899989
              ],
              [
                -73.83690582299991,
                40.857180695999965
              ],
              [
                -73.83691212799982,
                40.857008106999956
              ],
              [
                -73.83667557299988,
                40.85665266299989
              ],
              [
                -73.83499552599993,
                40.8565712009999
              ],
              [
                -73.8353782199999,
                40.85644896499986
              ],
              [
                -73.83562457399984,
                40.85636963099991
              ],
              [
                -73.83646667999994,
                40.856098438999894
              ],
              [
                -73.83690771399993,
                40.85599174899993
              ],
              [
                -73.83734249299997,
                40.85586686399993
              ],
              [
                -73.83776909799984,
                40.855724111999905
              ],
              [
                -73.83818561199983,
                40.8555640099999
              ],
              [
                -73.83859024199985,
                40.85538719199998
              ],
              [
                -73.83898125999988,
                40.85519446599994
              ],
              [
                -73.83935709000001,
                40.854986753999924
              ],
              [
                -73.83971628999994,
                40.85476509399998
              ],
              [
                -73.83991984599993,
                40.85462633599991
              ],
              [
                -73.84011803799997,
                40.854483171999924
              ],
              [
                -73.84031070099996,
                40.854335719999945
              ],
              [
                -73.8404976739999,
                40.854184104999916
              ],
              [
                -73.8406788009999,
                40.854028451999966
              ],
              [
                -73.84085393099993,
                40.85386889199996
              ],
              [
                -73.84102291699999,
                40.85370555799992
              ],
              [
                -73.84118561999999,
                40.85353858499998
              ],
              [
                -73.84134190199983,
                40.85336811399991
              ],
              [
                -73.84149163399988,
                40.85319428599991
              ],
              [
                -73.84194933699985,
                40.85251267999992
              ],
              [
                -73.84361735799992,
                40.85008033999989
              ],
              [
                -73.84428070699988,
                40.84912269899995
              ],
              [
                -73.84494053799982,
                40.84821822099991
              ],
              [
                -73.84500013599994,
                40.84845821899989
              ],
              [
                -73.84503845499987,
                40.84870153999991
              ],
              [
                -73.84505506699995,
                40.848946559999916
              ],
              [
                -73.84504985899981,
                40.849191585999975
              ],
              [
                -73.84502303799998,
                40.84943496099992
              ],
              [
                -73.8449695409999,
                40.850097030999926
              ],
              [
                -73.84475779899996,
                40.850468460999906
              ],
              [
                -73.84441301999993,
                40.85104299699992
              ],
              [
                -73.8443084699999,
                40.85119046399989
              ],
              [
                -73.84453077199993,
                40.8511328989999
              ],
              [
                -73.8466850059999,
                40.85111807799993
              ],
              [
                -73.84721027699993,
                40.851036838999946
              ],
              [
                -73.84729781299993,
                40.85108686899989
              ],
              [
                -73.8482145439999,
                40.85109088899993
              ],
              [
                -73.84861308099981,
                40.85107995799998
              ],
              [
                -73.84968869799998,
                40.85105890699989
              ],
              [
                -73.85049849099983,
                40.85069600599994
              ],
              [
                -73.85136789699986,
                40.85030370399994
              ],
              [
                -73.85224152599983,
                40.8499109959999
              ],
              [
                -73.85304526899994,
                40.849547331999865
              ],
              [
                -73.85457388299992,
                40.85150834599997
              ],
              [
                -73.855383516,
                40.8511437189999
              ],
              [
                -73.85619083999988,
                40.85077955999988
              ],
              [
                -73.85700079699997,
                40.85041559699992
              ],
              [
                -73.85780815,
                40.85005115699994
              ],
              [
                -73.85861723499988,
                40.849687847999895
              ],
              [
                -73.85942539999989,
                40.84932393799991
              ],
              [
                -73.86023344799996,
                40.84896024699987
              ],
              [
                -73.86045834099978,
                40.84885014599993
              ],
              [
                -73.86069456599994,
                40.848753393999935
              ],
              [
                -73.86094001199987,
                40.848670934999895
              ],
              [
                -73.86119240799994,
                40.848603467999915
              ],
              [
                -73.86160276499992,
                40.84854631199996
              ],
              [
                -73.86250923799993,
                40.848362280999886
              ],
              [
                -73.86278872899996,
                40.84923798299993
              ],
              [
                -73.86303938099996,
                40.85061130699993
              ],
              [
                -73.86473360999999,
                40.8502745589999
              ],
              [
                -73.86518787199995,
                40.85016438599992
              ],
              [
                -73.86553546999978,
                40.85028863499992
              ],
              [
                -73.86612130799993,
                40.85052722199986
              ],
              [
                -73.86680474499987,
                40.85094982699991
              ],
              [
                -73.86780004799985,
                40.85158929399995
              ],
              [
                -73.86860515599989,
                40.852068645999914
              ],
              [
                -73.86853091299994,
                40.85177675699994
              ],
              [
                -73.86844433299986,
                40.85148667199995
              ],
              [
                -73.86834552299989,
                40.851198851999875
              ],
              [
                -73.86823466099995,
                40.85091373699986
              ],
              [
                -73.86920944599994,
                40.84923837299992
              ],
              [
                -73.86837388999992,
                40.84896959799991
              ],
              [
                -73.86785992999984,
                40.84886493099988
              ],
              [
                -73.86800652899981,
                40.848301965999894
              ],
              [
                -73.86835465999991,
                40.84708023299988
              ],
              [
                -73.86843808099991,
                40.84705127899987
              ],
              [
                -73.868926528,
                40.846881740999876
              ],
              [
                -73.86883109200001,
                40.84673752699992
              ],
              [
                -73.86875399499986,
                40.8465844709999
              ],
              [
                -73.86875397099986,
                40.84658441799991
              ],
              [
                -73.86875394799989,
                40.846584363999966
              ],
              [
                -73.86869718899982,
                40.84642466899992
              ],
              [
                -73.86869712999989,
                40.846424505999934
              ],
              [
                -73.86869709499992,
                40.84642434499992
              ],
              [
                -73.8686619749999,
                40.84626032399997
              ],
              [
                -73.86866193899994,
                40.84626015399993
              ],
              [
                -73.86866192800001,
                40.846259982999946
              ],
              [
                -73.86864915199992,
                40.84609409699987
              ],
              [
                -73.86864914000002,
                40.84609392599993
              ],
              [
                -73.868649152,
                40.846093754999906
              ],
              [
                -73.8686587279999,
                40.84592863299988
              ],
              [
                -73.86865873999994,
                40.845928452999885
              ],
              [
                -73.86865877599989,
                40.84592828199989
              ],
              [
                -73.86869009299983,
                40.84576627499992
              ],
              [
                -73.86874186599975,
                40.84560972899992
              ],
              [
                -73.86863721699987,
                40.845309842999924
              ],
              [
                -73.86804200399997,
                40.84481383899988
              ],
              [
                -73.8688925879999,
                40.844409658999936
              ],
              [
                -73.86959555499995,
                40.84398144199994
              ],
              [
                -73.87030135699996,
                40.84354869599991
              ],
              [
                -73.87099518100001,
                40.843111391999926
              ],
              [
                -73.87153346199983,
                40.842514280999886
              ],
              [
                -73.87223024699995,
                40.84164317099993
              ],
              [
                -73.87229921099987,
                40.84157999599992
              ],
              [
                -73.87236365199989,
                40.84147695999991
              ],
              [
                -73.87244734699995,
                40.84134426699993
              ],
              [
                -73.87280724899995,
                40.84086864499993
              ],
              [
                -73.8732671699999,
                40.84021994899995
              ],
              [
                -73.87359713599987,
                40.83979855499992
              ],
              [
                -73.87283453899995,
                40.83975486299994
              ],
              [
                -73.87277204699981,
                40.83975128199996
              ],
              [
                -73.87272718499993,
                40.83974894499989
              ],
              [
                -73.87120605899995,
                40.839674621999905
              ],
              [
                -73.87021450700003,
                40.839617941999954
              ],
              [
                -73.86955862099985,
                40.8395956529999
              ],
              [
                -73.86920766899995,
                40.8396000459999
              ],
              [
                -73.86874073599989,
                40.83982457599993
              ],
              [
                -73.86830846899983,
                40.83999521299996
              ],
              [
                -73.86782258199997,
                40.840166498999935
              ],
              [
                -73.86740207099996,
                40.840290275999955
              ],
              [
                -73.86724819000003,
                40.840343803999886
              ],
              [
                -73.86636130899984,
                40.840592684999905
              ],
              [
                -73.865464502,
                40.84085689199993
              ],
              [
                -73.86448011799995,
                40.84108779699995
              ],
              [
                -73.86436922099999,
                40.84110901799995
              ],
              [
                -73.863639382,
                40.84126527499991
              ],
              [
                -73.86358013999997,
                40.84127795699989
              ],
              [
                -73.86347232399994,
                40.84130593299991
              ],
              [
                -73.86176166600002,
                40.84151271999993
              ],
              [
                -73.86162833399996,
                40.841527859999864
              ],
              [
                -73.86045627399983,
                40.841661664999954
              ],
              [
                -73.86018491899982,
                40.841689089999875
              ],
              [
                -73.8597245239999,
                40.84174401899985
              ],
              [
                -73.85919593699994,
                40.841804032999924
              ],
              [
                -73.85835669199989,
                40.841899285999894
              ],
              [
                -73.85719247999995,
                40.842027647999906
              ],
              [
                -73.85618402199997,
                40.84214591699994
              ],
              [
                -73.85553579799995,
                40.84221948999987
              ],
              [
                -73.85520012799999,
                40.84225761399988
              ],
              [
                -73.85420745799989,
                40.842370738999946
              ],
              [
                -73.85349746599992,
                40.84245166799994
              ],
              [
                -73.85333524999986,
                40.84247015499989
              ],
              [
                -73.85231153200002,
                40.84258699399992
              ],
              [
                -73.85179886599994,
                40.8426476329999
              ],
              [
                -73.85120896799987,
                40.84271512199991
              ],
              [
                -73.85088863399999,
                40.84275300599993
              ],
              [
                -73.85052278099987,
                40.84278948899986
              ],
              [
                -73.8489861779999,
                40.841599951999854
              ],
              [
                -73.84796242199991,
                40.84082789199989
              ],
              [
                -73.84779791999988,
                40.84069631099988
              ],
              [
                -73.84715638299993,
                40.840263675999914
              ],
              [
                -73.84647864699997,
                40.83980920599987
              ],
              [
                -73.8455308949999,
                40.83917330699989
              ],
              [
                -73.84458129199986,
                40.838535507999914
              ],
              [
                -73.84391720399984,
                40.83908632599985
              ],
              [
                -73.84361155699993,
                40.83934832599995
              ],
              [
                -73.8430625279998,
                40.839797205999915
              ],
              [
                -73.8426130889999,
                40.8401671559999
              ],
              [
                -73.84240098600006,
                40.84034851399991
              ],
              [
                -73.84204144699997,
                40.84064589699992
              ],
              [
                -73.84188659999985,
                40.84061724699993
              ],
              [
                -73.84188635099984,
                40.84061720199989
              ],
              [
                -73.84188610300001,
                40.84061717399987
              ],
              [
                -73.84172735099986,
                40.840600206999966
              ],
              [
                -73.84172710199991,
                40.840600179999925
              ],
              [
                -73.84172685399986,
                40.84060017099994
              ],
              [
                -73.84156602199995,
                40.84059525799994
              ],
              [
                -73.84140548300003,
                40.84060250299988
              ],
              [
                -73.84124785499995,
                40.84062162899994
              ],
              [
                -73.83936452799995,
                40.84087706699995
              ],
              [
                -73.83880813300001,
                40.84076703399992
              ],
              [
                -73.83849424699997,
                40.84069921999994
              ],
              [
                -73.83839056199994,
                40.840676813999934
              ],
              [
                -73.83830524899987,
                40.84064271899993
              ],
              [
                -73.83821036999994,
                40.840604797999916
              ],
              [
                -73.83812376299989,
                40.84057018199996
              ],
              [
                -73.83771374799997,
                40.840406309999885
              ],
              [
                -73.8376890109999,
                40.84081118199989
              ],
              [
                -73.83767001599998,
                40.84112190799987
              ],
              [
                -73.83736984299993,
                40.84204138399985
              ],
              [
                -73.83714579999986,
                40.84272763299991
              ],
              [
                -73.83688925199996,
                40.843374787999906
              ],
              [
                -73.83676876799983,
                40.843542820999886
              ],
              [
                -73.8365331269999,
                40.844620694999946
              ],
              [
                -73.83619907199994,
                40.84582739599996
              ],
              [
                -73.83615362999997,
                40.84613117599993
              ],
              [
                -73.83606934399998,
                40.84716484899992
              ],
              [
                -73.83593362199987,
                40.84840635599993
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000466175414294,
        "objectid": 245,
        "shape_leng": 0.0959830596604,
        "location_id": 245,
        "zone": "West Brighton",
        "borough": "Staten Island"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -74.09787969199995,
                40.64035805499989
              ],
              [
                -74.09928833799988,
                40.64020472299996
              ],
              [
                -74.09990409699999,
                40.64016295499988
              ],
              [
                -74.10042458199996,
                40.6400410299999
              ],
              [
                -74.10129653399997,
                40.63982715799995
              ],
              [
                -74.10216582799997,
                40.63961813399992
              ],
              [
                -74.10305261099988,
                40.639401245999856
              ],
              [
                -74.10393828599992,
                40.63919020399988
              ],
              [
                -74.10480990899997,
                40.638976700999876
              ],
              [
                -74.10542286599998,
                40.638898966999875
              ],
              [
                -74.10690506699991,
                40.63872593199991
              ],
              [
                -74.10696781199987,
                40.63871878699993
              ],
              [
                -74.10702926799993,
                40.63870678799988
              ],
              [
                -74.10708878899997,
                40.63869005999989
              ],
              [
                -74.10714574999999,
                40.63866877999992
              ],
              [
                -74.10719955199995,
                40.63864316999993
              ],
              [
                -74.10724963099997,
                40.638613498999874
              ],
              [
                -74.10729545999992,
                40.63858007999988
              ],
              [
                -74.1073365579999,
                40.63854326299991
              ],
              [
                -74.10746323199996,
                40.63843848799997
              ],
              [
                -74.10822759399989,
                40.638314039999926
              ],
              [
                -74.10868871399997,
                40.63823996099991
              ],
              [
                -74.10887095399988,
                40.6382205619999
              ],
              [
                -74.1088711309999,
                40.638220543999914
              ],
              [
                -74.10887129699995,
                40.63822050799991
              ],
              [
                -74.10905105199996,
                40.638184892999895
              ],
              [
                -74.10905120499997,
                40.63818486599991
              ],
              [
                -74.10905134699988,
                40.63818482099989
              ],
              [
                -74.10922508199995,
                40.638133370999896
              ],
              [
                -74.10922521199993,
                40.638133334999935
              ],
              [
                -74.109225354,
                40.63813328099991
              ],
              [
                -74.10938954799997,
                40.63806700899991
              ],
              [
                -74.10938961799994,
                40.63806698199992
              ],
              [
                -74.10938968899991,
                40.63806694599991
              ],
              [
                -74.10954135499992,
                40.637987429999896
              ],
              [
                -74.10974833299994,
                40.63786583299993
              ],
              [
                -74.11080857499996,
                40.637746199999874
              ],
              [
                -74.11174806399994,
                40.63764295499989
              ],
              [
                -74.11279390199998,
                40.6375308099999
              ],
              [
                -74.1137364029999,
                40.63742912299987
              ],
              [
                -74.11499559699989,
                40.63729222599993
              ],
              [
                -74.11519147399999,
                40.63834768099987
              ],
              [
                -74.11588274199994,
                40.63826439099995
              ],
              [
                -74.11641377299995,
                40.63820248399986
              ],
              [
                -74.11709261399987,
                40.638126946999925
              ],
              [
                -74.11765722499997,
                40.63806246599996
              ],
              [
                -74.11754902299995,
                40.63695363199987
              ],
              [
                -74.11753352399988,
                40.63674776299993
              ],
              [
                -74.11752704599998,
                40.63666172499988
              ],
              [
                -74.11751731899994,
                40.6365325329999
              ],
              [
                -74.11751136899993,
                40.636441801999915
              ],
              [
                -74.1175003539999,
                40.63630719399989
              ],
              [
                -74.11749409699998,
                40.636224090999875
              ],
              [
                -74.117485764,
                40.63611340599992
              ],
              [
                -74.11747983199996,
                40.63602690599986
              ],
              [
                -74.11747417299995,
                40.635943517999884
              ],
              [
                -74.11746820599997,
                40.63588019599997
              ],
              [
                -74.11745986599989,
                40.63576940499993
              ],
              [
                -74.11738361299989,
                40.63539539899992
              ],
              [
                -74.1171994709999,
                40.634568097999924
              ],
              [
                -74.11707785799996,
                40.63400710799992
              ],
              [
                -74.11704470999996,
                40.63381660999992
              ],
              [
                -74.11695838499996,
                40.63335140799992
              ],
              [
                -74.11683619599992,
                40.63267481799988
              ],
              [
                -74.11666642599987,
                40.631710101999914
              ],
              [
                -74.11650496399994,
                40.63075571399985
              ],
              [
                -74.11634635499993,
                40.629835003999844
              ],
              [
                -74.11618948699994,
                40.62894119699992
              ],
              [
                -74.11614321199995,
                40.62870226399988
              ],
              [
                -74.11595162399986,
                40.628230133999864
              ],
              [
                -74.11588524899992,
                40.62808893699991
              ],
              [
                -74.11560933499997,
                40.62754898299993
              ],
              [
                -74.11544777599987,
                40.62722061799992
              ],
              [
                -74.115275528,
                40.626889055999875
              ],
              [
                -74.11526026599996,
                40.62685967799991
              ],
              [
                -74.11524316499992,
                40.6268265649999
              ],
              [
                -74.11503453399992,
                40.62642257599991
              ],
              [
                -74.11475499199994,
                40.6258892879999
              ],
              [
                -74.11473772799998,
                40.6258563459999
              ],
              [
                -74.11471448499998,
                40.625811685999906
              ],
              [
                -74.11407428699995,
                40.62458176999998
              ],
              [
                -74.11393395699994,
                40.6243302039999
              ],
              [
                -74.1135847819999,
                40.62369716899987
              ],
              [
                -74.11326249599989,
                40.62305442499996
              ],
              [
                -74.11235088099993,
                40.62226529199996
              ],
              [
                -74.11153393899994,
                40.621603645999876
              ],
              [
                -74.11094392899994,
                40.621194488999876
              ],
              [
                -74.11001550699994,
                40.62068834399987
              ],
              [
                -74.1091080619999,
                40.6202962389999
              ],
              [
                -74.10770635299994,
                40.61969949699992
              ],
              [
                -74.10734089099991,
                40.61954307699993
              ],
              [
                -74.10608939599997,
                40.618984194999925
              ],
              [
                -74.10552598899993,
                40.61859232899992
              ],
              [
                -74.10518709699997,
                40.618281473999915
              ],
              [
                -74.10511622199992,
                40.618203003999945
              ],
              [
                -74.1049532439999,
                40.61802256299989
              ],
              [
                -74.10458409200001,
                40.61761290099991
              ],
              [
                -74.10350925299994,
                40.616416074999925
              ],
              [
                -74.10254940599991,
                40.6168536849999
              ],
              [
                -74.10189835099996,
                40.61745221099993
              ],
              [
                -74.10134946699999,
                40.618023670999875
              ],
              [
                -74.10104903199995,
                40.61837105999995
              ],
              [
                -74.10073089199992,
                40.61870975499989
              ],
              [
                -74.10039572599996,
                40.61903896299989
              ],
              [
                -74.10004426999996,
                40.61935795499992
              ],
              [
                -74.09967734799997,
                40.61966605399991
              ],
              [
                -74.09814034099995,
                40.62091145599988
              ],
              [
                -74.0975173439999,
                40.62141888799987
              ],
              [
                -74.09639991999997,
                40.622328996999954
              ],
              [
                -74.09533507499991,
                40.62320160699987
              ],
              [
                -74.09395327099995,
                40.62433390699991
              ],
              [
                -74.09223971399994,
                40.625742915999886
              ],
              [
                -74.09158095999993,
                40.626545922999924
              ],
              [
                -74.08941665299993,
                40.62981355199992
              ],
              [
                -74.08934045699996,
                40.62992328499994
              ],
              [
                -74.08924974899998,
                40.63002750999989
              ],
              [
                -74.08914567599997,
                40.630124621999904
              ],
              [
                -74.08902975899993,
                40.630213168999916
              ],
              [
                -74.08890387699988,
                40.63029198999987
              ],
              [
                -74.08870635899996,
                40.63042749999993
              ],
              [
                -74.08852313599994,
                40.63057561499994
              ],
              [
                -74.08835621399997,
                40.630734963999934
              ],
              [
                -74.08820728099995,
                40.630903924999906
              ],
              [
                -74.08807764899993,
                40.63108071699992
              ],
              [
                -74.08761781699995,
                40.63164751199986
              ],
              [
                -74.08714129599997,
                40.63223951599989
              ],
              [
                -74.08574928999991,
                40.63395555699988
              ],
              [
                -74.08443647599991,
                40.63540524799989
              ],
              [
                -74.08394671399999,
                40.63565410499991
              ],
              [
                -74.08373397299994,
                40.63573714399991
              ],
              [
                -74.08513463099995,
                40.63635959899995
              ],
              [
                -74.08568983299989,
                40.6366072799999
              ],
              [
                -74.08587882899995,
                40.63669158999993
              ],
              [
                -74.0869059279999,
                40.635857242999904
              ],
              [
                -74.08759775199998,
                40.636262023999926
              ],
              [
                -74.08870582299987,
                40.636845205999876
              ],
              [
                -74.08891735099989,
                40.636528383999874
              ],
              [
                -74.08910236299987,
                40.63624702699983
              ],
              [
                -74.08915919799995,
                40.63616632399988
              ],
              [
                -74.08922167799987,
                40.6360880799999
              ],
              [
                -74.089289621,
                40.636012524999956
              ],
              [
                -74.08936282699987,
                40.63593987999993
              ],
              [
                -74.08944108199995,
                40.635870355999884
              ],
              [
                -74.08952415699994,
                40.63580415699986
              ],
              [
                -74.08961180999991,
                40.63574147799994
              ],
              [
                -74.08970378299989,
                40.63568250099992
              ],
              [
                -74.09027738599993,
                40.63549902999994
              ],
              [
                -74.09090698799996,
                40.6354857289999
              ],
              [
                -74.09182025899999,
                40.63555529799987
              ],
              [
                -74.09242513499993,
                40.63558181299992
              ],
              [
                -74.09338942299995,
                40.63659955499988
              ],
              [
                -74.09362634099998,
                40.637283694999894
              ],
              [
                -74.09386276399991,
                40.63802491699991
              ],
              [
                -74.09409312599998,
                40.639266913999975
              ],
              [
                -74.09570924799992,
                40.6390986409999
              ],
              [
                -74.09637906299997,
                40.63903536999989
              ],
              [
                -74.09686242099997,
                40.639007470999914
              ],
              [
                -74.09767290799995,
                40.63896067899987
              ],
              [
                -74.09787969199995,
                40.64035805499989
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0000657697664169,
        "objectid": 263,
        "shape_leng": 0.0370166252994,
        "location_id": 263,
        "zone": "Yorkville West",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.95218621999996,
                40.77301984499992
              ],
              [
                -73.95181761599994,
                40.77352690399989
              ],
              [
                -73.95172338299997,
                40.77365653199994
              ],
              [
                -73.9512617339999,
                40.774291940999944
              ],
              [
                -73.95079834699999,
                40.774927767999884
              ],
              [
                -73.95033856500001,
                40.77556320899991
              ],
              [
                -73.94987249699994,
                40.77619615099997
              ],
              [
                -73.9493736669999,
                40.776875747999945
              ],
              [
                -73.9488804199999,
                40.77755328499992
              ],
              [
                -73.94842272599978,
                40.77818264099989
              ],
              [
                -73.94796768199993,
                40.77880646599992
              ],
              [
                -73.94750818099986,
                40.77943712299992
              ],
              [
                -73.9470492189998,
                40.78006434699994
              ],
              [
                -73.94659059199999,
                40.78069197399987
              ],
              [
                -73.94647764199985,
                40.78084530299988
              ],
              [
                -73.94642443299978,
                40.78091753399991
              ],
              [
                -73.94630631700001,
                40.781077875999884
              ],
              [
                -73.946197187,
                40.78122601799986
              ],
              [
                -73.94613091699998,
                40.78131597799995
              ],
              [
                -73.94588094499986,
                40.78165816599989
              ],
              [
                -73.94573785299986,
                40.781854041999964
              ],
              [
                -73.94566981599996,
                40.78194717599993
              ],
              [
                -73.94536565199984,
                40.78236245099987
              ],
              [
                -73.94525035499998,
                40.782519863999916
              ],
              [
                -73.94521308599991,
                40.782570746999944
              ],
              [
                -73.94472264499986,
                40.78324757199989
              ],
              [
                -73.94706938199998,
                40.78423622699995
              ],
              [
                -73.94933170599992,
                40.78519312699994
              ],
              [
                -73.9498239039999,
                40.784515570999886
              ],
              [
                -73.95028254899988,
                40.783890469999946
              ],
              [
                -73.95073913799986,
                40.783261709999884
              ],
              [
                -73.95119927299987,
                40.7826333919999
              ],
              [
                -73.95165920699998,
                40.78200767399993
              ],
              [
                -73.9521220329999,
                40.78138670999995
              ],
              [
                -73.95257421900006,
                40.780753033999865
              ],
              [
                -73.95303367999988,
                40.780125454999926
              ],
              [
                -73.95349212400005,
                40.77949662199995
              ],
              [
                -73.9539837019998,
                40.77882210999991
              ],
              [
                -73.95448224099997,
                40.77813866499987
              ],
              [
                -73.9549452009999,
                40.77750394699991
              ],
              [
                -73.9554072979999,
                40.776868479999926
              ],
              [
                -73.9558693369999,
                40.77623622599986
              ],
              [
                -73.95633492299991,
                40.77560078299992
              ],
              [
                -73.95679749099989,
                40.77496631099994
              ],
              [
                -73.95729409999986,
                40.7742835549999
              ],
              [
                -73.95505481599999,
                40.773336850999954
              ],
              [
                -73.95268514999994,
                40.772339404999926
              ],
              [
                -73.95218621999996,
                40.77301984499992
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000438377341503,
        "objectid": 243,
        "shape_leng": 0.0943314999364,
        "location_id": 243,
        "zone": "Washington Heights North",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.93156536999994,
                40.86958215799991
              ],
              [
                -73.9321985510756,
                40.8698448708717
              ],
              [
                -73.93217976024559,
                40.86982236640027
              ],
              [
                -73.93209566102911,
                40.86975965114518
              ],
              [
                -73.93206712211828,
                40.8696994277864
              ],
              [
                -73.9320500216973,
                40.869641746782705
              ],
              [
                -73.9320534384771,
                40.86960075074436
              ],
              [
                -73.93205908286352,
                40.869582816886705
              ],
              [
                -73.9320771261944,
                40.86954268415996
              ],
              [
                -73.93210997106698,
                40.869477249101706
              ],
              [
                -73.9321333660305,
                40.86941597544102
              ],
              [
                -73.93235934466009,
                40.86950736321936
              ],
              [
                -73.93226581636979,
                40.86973477164008
              ],
              [
                -73.93235370807281,
                40.869755255296546
              ],
              [
                -73.93246883069914,
                40.86947609938702
              ],
              [
                -73.93218903826057,
                40.86936697638912
              ],
              [
                -73.93229327689872,
                40.869199501942205
              ],
              [
                -73.93239016804613,
                40.869187303109975
              ],
              [
                -73.93250469803131,
                40.869129558665186
              ],
              [
                -73.93256795169869,
                40.86904439147383
              ],
              [
                -73.93256895482746,
                40.86897940371653
              ],
              [
                -73.93253729354952,
                40.86896800236226
              ],
              [
                -73.93245670800944,
                40.86896227317822
              ],
              [
                -73.9323603509378,
                40.86894960979842
              ],
              [
                -73.9323138544822,
                40.868923720343716
              ],
              [
                -73.93228732044285,
                40.86887514239039
              ],
              [
                -73.93227751053237,
                40.868711157475076
              ],
              [
                -73.93225427749094,
                40.86868339935806
              ],
              [
                -73.93225031754949,
                40.86863950937264
              ],
              [
                -73.93229830514183,
                40.86864739503352
              ],
              [
                -73.93230597278854,
                40.86862923954242
              ],
              [
                -73.9323522987469,
                40.86864702816238
              ],
              [
                -73.93249532884039,
                40.868701950391426
              ],
              [
                -73.93250619965718,
                40.86870612539119
              ],
              [
                -73.93250125163004,
                40.86871435273155
              ],
              [
                -73.93252354560028,
                40.868722078358864
              ],
              [
                -73.9325680426414,
                40.86864808335343
              ],
              [
                -73.93255050661638,
                40.86864200559115
              ],
              [
                -73.9325454652185,
                40.86864025923292
              ],
              [
                -73.93265083512807,
                40.86846503348183
              ],
              [
                -73.93262844350281,
                40.8684572743171
              ],
              [
                -73.93262525075036,
                40.86845616797417
              ],
              [
                -73.93262211514612,
                40.86846138498572
              ],
              [
                -73.932614318916,
                40.868474347379454
              ],
              [
                -73.93257970501192,
                40.868531909823226
              ],
              [
                -73.9325689181631,
                40.86854984723809
              ],
              [
                -73.93253690662306,
                40.8686030783755
              ],
              [
                -73.93253077796827,
                40.868613271913894
              ],
              [
                -73.93249377280186,
                40.868674809424526
              ],
              [
                -73.93249214298582,
                40.86867751966221
              ],
              [
                -73.93244643748234,
                40.86865976546891
              ],
              [
                -73.93231591011308,
                40.868604142896054
              ],
              [
                -73.9323228438442,
                40.868578111887054
              ],
              [
                -73.93226769826255,
                40.86856231897237
              ],
              [
                -73.9322812400666,
                40.86853253815755
              ],
              [
                -73.93227397128045,
                40.86847715197787
              ],
              [
                -73.93237341288611,
                40.86806348807323
              ],
              [
                -73.93236746809531,
                40.86803721447596
              ],
              [
                -73.93235431562957,
                40.86800719569787
              ],
              [
                -73.93235078136804,
                40.867979120295345
              ],
              [
                -73.93236431431154,
                40.86786330100779
              ],
              [
                -73.93238662235719,
                40.8676938932447
              ],
              [
                -73.93242547900691,
                40.86756827092846
              ],
              [
                -73.93251910022944,
                40.867410578207554
              ],
              [
                -73.93254851408642,
                40.86741391805599
              ],
              [
                -73.93259823617254,
                40.867311462916476
              ],
              [
                -73.93266035056361,
                40.86732966135371
              ],
              [
                -73.93264569574575,
                40.86735847764239
              ],
              [
                -73.93283958205068,
                40.867415276911366
              ],
              [
                -73.93290171891765,
                40.86729304389972
              ],
              [
                -73.93276260783125,
                40.867252297557144
              ],
              [
                -73.9327564862724,
                40.86726436016792
              ],
              [
                -73.93266332721451,
                40.867237066436985
              ],
              [
                -73.93283349428312,
                40.86698204820548
              ],
              [
                -73.93307500307841,
                40.86655741872505
              ],
              [
                -73.93313150185679,
                40.86642621378959
              ],
              [
                -73.93325688879918,
                40.86624178458094
              ],
              [
                -73.93332317557574,
                40.866238095014126
              ],
              [
                -73.93340182700808,
                40.86613564683984
              ],
              [
                -73.93343628914424,
                40.86604807580756
              ],
              [
                -73.93343635069695,
                40.86598658199528
              ],
              [
                -73.93349285424156,
                40.8659456148249
              ],
              [
                -73.9335739612204,
                40.86584129553878
              ],
              [
                -73.93357158007582,
                40.86576675094427
              ],
              [
                -73.93363055568597,
                40.86570901942328
              ],
              [
                -73.93378916436362,
                40.86542863426856
              ],
              [
                -73.93386283123468,
                40.86541004611179
              ],
              [
                -73.93395001711579,
                40.86534674620981
              ],
              [
                -73.93401648524471,
                40.86519581766668
              ],
              [
                -73.93401664426698,
                40.865035548291566
              ],
              [
                -73.93408173881329,
                40.86497781014821
              ],
              [
                -73.9341923030187,
                40.864879107531024
              ],
              [
                -73.9342316407922,
                40.864813897822636
              ],
              [
                -73.93435568513519,
                40.864732907068436
              ],
              [
                -73.93443446851097,
                40.86450373173374
              ],
              [
                -73.93461870076229,
                40.86426987104492
              ],
              [
                -73.93475540717563,
                40.86409633974318
              ],
              [
                -73.93485089173119,
                40.86396557693494
              ],
              [
                -73.93559460797813,
                40.86296505185206
              ],
              [
                -73.93596813006776,
                40.86254409289871
              ],
              [
                -73.93647561664902,
                40.861926591170764
              ],
              [
                -73.9368104544925,
                40.86136346605994
              ],
              [
                -73.93681851547501,
                40.861315818680424
              ],
              [
                -73.93680279963627,
                40.86127908212909
              ],
              [
                -73.93681096972892,
                40.86125802374978
              ],
              [
                -73.93696851748112,
                40.861143219025095
              ],
              [
                -73.93704223142178,
                40.86105753297894
              ],
              [
                -73.93709390407562,
                40.86092524299121
              ],
              [
                -73.93713577511515,
                40.860783636699566
              ],
              [
                -73.9373543613443,
                40.86051512129021
              ],
              [
                -73.93758054206488,
                40.860203206651164
              ],
              [
                -73.93758716318406,
                40.86014954032659
              ],
              [
                -73.9375521865698,
                40.86012367458296
              ],
              [
                -73.93753066305457,
                40.86011187884754
              ],
              [
                -73.93752944172567,
                40.86009664128206
              ],
              [
                -73.9375573431849,
                40.86008135632523
              ],
              [
                -73.93773556377965,
                40.85993351223935
              ],
              [
                -73.93785336586474,
                40.85983027123292
              ],
              [
                -73.93830957434292,
                40.85938052360819
              ],
              [
                -73.93841643396333,
                40.85916624546454
              ],
              [
                -73.9384372879185,
                40.8590733621211
              ],
              [
                -73.93850174725024,
                40.859011335007715
              ],
              [
                -73.93865306164695,
                40.85876881362194
              ],
              [
                -73.93865680565516,
                40.85873403041931
              ],
              [
                -73.93891471443492,
                40.858406937423176
              ],
              [
                -73.93904122641767,
                40.85820201636389
              ],
              [
                -73.93911094164396,
                40.85804091122572
              ],
              [
                -73.9392223197617,
                40.85789369423106
              ],
              [
                -73.93928303850637,
                40.85786082279131
              ],
              [
                -73.93936488888228,
                40.85774050573835
              ],
              [
                -73.93940211514372,
                40.85766529797213
              ],
              [
                -73.93961041073217,
                40.85739647639676
              ],
              [
                -73.93968482410261,
                40.857289321036085
              ],
              [
                -73.93978146211117,
                40.85723483699594
              ],
              [
                -73.9399488067714,
                40.8570666142561
              ],
              [
                -73.94001827015856,
                40.85695004614774
              ],
              [
                -73.94008523452419,
                40.85685605129765
              ],
              [
                -73.94014717618717,
                40.85683351705708
              ],
              [
                -73.94029347497097,
                40.85664177065756
              ],
              [
                -73.94035056461426,
                40.8565158030732
              ],
              [
                -73.9404051777662,
                40.856372905782024
              ],
              [
                -73.9404883126927,
                40.85620462882264
              ],
              [
                -73.94061975134593,
                40.85601100074243
              ],
              [
                -73.94069419380095,
                40.85585306606014
              ],
              [
                -73.9407462171037,
                40.85584744692032
              ],
              [
                -73.94076854842876,
                40.85580797146752
              ],
              [
                -73.94085539855477,
                40.85562183077844
              ],
              [
                -73.94095704472404,
                40.85552033439455
              ],
              [
                -73.94100914313304,
                40.855415039634785
              ],
              [
                -73.94099928999186,
                40.85535861893859
              ],
              [
                -73.94103277003084,
                40.85530503914508
              ],
              [
                -73.94123366359767,
                40.855009887976884
              ],
              [
                -73.94126592051121,
                40.854944078788556
              ],
              [
                -73.94131311131312,
                40.85479553975522
              ],
              [
                -73.9414098064236,
                40.85468463098871
              ],
              [
                -73.94149762132163,
                40.8545083843677
              ],
              [
                -73.94150213189876,
                40.85450012674853
              ],
              [
                -73.94153736742429,
                40.85443564359609
              ],
              [
                -73.9415815880044,
                40.85434714380842
              ],
              [
                -73.94173105661464,
                40.85410838854933
              ],
              [
                -73.94182774425003,
                40.853989960632596
              ],
              [
                -73.94186996426673,
                40.853867739442755
              ],
              [
                -73.94205514258485,
                40.853654107455704
              ],
              [
                -73.94247365925723,
                40.85317127831178
              ],
              [
                -73.94260996683576,
                40.85306791474677
              ],
              [
                -73.94279838505354,
                40.852851233889844
              ],
              [
                -73.94302644371187,
                40.852626177870995
              ],
              [
                -73.94305902944303,
                40.85260143014694
              ],
              [
                -73.94347978545615,
                40.85228188294709
              ],
              [
                -73.94360072252731,
                40.85223107230983
              ],
              [
                -73.94379606084516,
                40.85219807473172
              ],
              [
                -73.94388148068221,
                40.852172533479965
              ],
              [
                -73.94395986779188,
                40.85211903729921
              ],
              [
                -73.9439912652317,
                40.85204171887874
              ],
              [
                -73.94401323929921,
                40.852000090761386
              ],
              [
                -73.94407120589058,
                40.852015580285084
              ],
              [
                -73.94413386022264,
                40.85202750564373
              ],
              [
                -73.94419654622922,
                40.85201564098287
              ],
              [
                -73.94422164925084,
                40.851976391831485
              ],
              [
                -73.94421071900311,
                40.85192998456854
              ],
              [
                -73.94424520021661,
                40.85192049193891
              ],
              [
                -73.94429066589659,
                40.85188244102266
              ],
              [
                -73.94431732295752,
                40.85185627651454
              ],
              [
                -73.94432519023105,
                40.85182297062388
              ],
              [
                -73.94436748956578,
                40.85182536852248
              ],
              [
                -73.94440663561446,
                40.85185513117773
              ],
              [
                -73.94439719907635,
                40.85189319951099
              ],
              [
                -73.94442225918847,
                40.85190510658742
              ],
              [
                -73.94445360950604,
                40.85189798049819
              ],
              [
                -73.94446190429296,
                40.851899805303866
              ],
              [
                -73.94448754202686,
                40.85188922312717
              ],
              [
                -73.94450507247345,
                40.85189033001257
              ],
              [
                -73.94450307651685,
                40.8518998251177
              ],
              [
                -73.94448648497414,
                40.851924173700404
              ],
              [
                -73.94450369998015,
                40.85195749066512
              ],
              [
                -73.94457734596685,
                40.8519432540793
              ],
              [
                -73.94458832934104,
                40.8519277890699
              ],
              [
                -73.94463221068919,
                40.85190996135587
              ],
              [
                -73.94466706665953,
                40.85192169852068
              ],
              [
                -73.94468309631044,
                40.8519104913526
              ],
              [
                -73.94468965406844,
                40.8518776453314
              ],
              [
                -73.94469257824674,
                40.85186300317582
              ],
              [
                -73.94468755000356,
                40.851836237725294
              ],
              [
                -73.9446937046878,
                40.85182460198623
              ],
              [
                -73.94471435250203,
                40.85181591121898
              ],
              [
                -73.94476566471432,
                40.851801590264635
              ],
              [
                -73.94477691248362,
                40.85179683195325
              ],
              [
                -73.94487719641315,
                40.85175440837052
              ],
              [
                -73.94493240846673,
                40.85173105265653
              ],
              [
                -73.944977851949,
                40.851732272132274
              ],
              [
                -73.94500607672323,
                40.851702541806006
              ],
              [
                -73.94504370869103,
                40.851671618664454
              ],
              [
                -73.94506567411703,
                40.851639507848795
              ],
              [
                -73.94515345120476,
                40.85158720423755
              ],
              [
                -73.94519574791491,
                40.85159317711649
              ],
              [
                -73.94526627919835,
                40.85157178871038
              ],
              [
                -73.94527256188401,
                40.851546811601985
              ],
              [
                -73.9452436006128,
                40.851520025436805
              ],
              [
                -73.94525302780075,
                40.85149266412056
              ],
              [
                -73.94527966082822,
                40.85149506296039
              ],
              [
                -73.9453125651084,
                40.85148556105454
              ],
              [
                -73.94532198248487,
                40.85147009472928
              ],
              [
                -73.94538936847742,
                40.8514534679505
              ],
              [
                -73.9453784200023,
                40.85142729476892
              ],
              [
                -73.94539567234408,
                40.851416594838255
              ],
              [
                -73.94542229851784,
                40.85142731553617
              ],
              [
                -73.94544893316966,
                40.851428516042574
              ],
              [
                -73.94548187577574,
                40.85137380003347
              ],
              [
                -73.94551948943962,
                40.851363110709954
              ],
              [
                -73.94552736155029,
                40.85132147563915
              ],
              [
                -73.94555556310249,
                40.85132148895219
              ],
              [
                -73.94561203147599,
                40.85124061483366
              ],
              [
                -73.94565279045506,
                40.85121208034208
              ],
              [
                -73.9456825554423,
                40.85122756464785
              ],
              [
                -73.94572798690905,
                40.851228774441324
              ],
              [
                -73.94572488131935,
                40.85119784128443
              ],
              [
                -73.94576720433773,
                40.851170495396815
              ],
              [
                -73.94584084991659,
                40.85117052999494
              ],
              [
                -73.94589567174965,
                40.85118840282924
              ],
              [
                -73.94593014421991,
                40.851190796484325
              ],
              [
                -73.9459646207679,
                40.85117296552845
              ],
              [
                -73.9460476559076,
                40.8511896630908
              ],
              [
                -73.94611728194423,
                40.85117372143474
              ],
              [
                -73.94613637747574,
                40.85115582829458
              ],
              [
                -73.94614136091342,
                40.85109472309467
              ],
              [
                -73.94609776719916,
                40.85103455913642
              ],
              [
                -73.9460970573703,
                40.85103358549357
              ],
              [
                -73.94608704722194,
                40.851009023386204
              ],
              [
                -73.94608145021917,
                40.85098164676894
              ],
              [
                -73.94609624718471,
                40.85096724398697
              ],
              [
                -73.94611934283114,
                40.85096964315819
              ],
              [
                -73.94613353603556,
                40.850984828080854
              ],
              [
                -73.94614508385327,
                40.85100838241099
              ],
              [
                -73.94614668962957,
                40.85103223464638
              ],
              [
                -73.94615791969893,
                40.85104824156037
              ],
              [
                -73.94617185524098,
                40.85105307106365
              ],
              [
                -73.9461857520811,
                40.85103238521867
              ],
              [
                -73.94620054046909,
                40.85103239211628
              ],
              [
                -73.94619565359774,
                40.85097500953831
              ],
              [
                -73.94617267171917,
                40.850958789751104
              ],
              [
                -73.94619735733023,
                40.850906420201966
              ],
              [
                -73.9462433633095,
                40.85088523552587
              ],
              [
                -73.94627461183848,
                40.850841593471515
              ],
              [
                -73.94629678670803,
                40.850833725838775
              ],
              [
                -73.94631554216869,
                40.8508392789245
              ],
              [
                -73.94632696500435,
                40.850846615401025
              ],
              [
                -73.94633765223675,
                40.85085347928547
              ],
              [
                -73.94635191475699,
                40.850870558569106
              ],
              [
                -73.94635149986354,
                40.85089369453736
              ],
              [
                -73.94632862612754,
                40.85090502942344
              ],
              [
                -73.94632053353631,
                40.850923937715315
              ],
              [
                -73.94633856812649,
                40.85094852447611
              ],
              [
                -73.94637189400876,
                40.85097051787692
              ],
              [
                -73.9463843785491,
                40.85097694461102
              ],
              [
                -73.94639786213489,
                40.850982077295754
              ],
              [
                -73.94641211347597,
                40.85098582788765
              ],
              [
                -73.94642688811145,
                40.85098813205079
              ],
              [
                -73.94644188478783,
                40.85098895010289
              ],
              [
                -73.94645689400723,
                40.85098827764361
              ],
              [
                -73.94647165993503,
                40.85098612613527
              ],
              [
                -73.94648747390856,
                40.85098204659227
              ],
              [
                -73.94650234446286,
                40.85097627305157
              ],
              [
                -73.94651595882908,
                40.850968926946535
              ],
              [
                -73.94680219627986,
                40.85066137249108
              ],
              [
                -73.94681367391628,
                40.850649039178094
              ],
              [
                -73.94686899614918,
                40.85058959685872
              ],
              [
                -73.94692626452421,
                40.85052805583417
              ],
              [
                -73.9458970349999,
                40.85032798199989
              ],
              [
                -73.944914825,
                40.85013703799996
              ],
              [
                -73.944768533,
                40.85011040299986
              ],
              [
                -73.94472103299991,
                40.85010096199994
              ],
              [
                -73.94409966200001,
                40.849977402999855
              ],
              [
                -73.94396593999994,
                40.849950809999946
              ],
              [
                -73.94381229899996,
                40.84991964099994
              ],
              [
                -73.94317806099995,
                40.84979994299993
              ],
              [
                -73.94316977700004,
                40.84984751199991
              ],
              [
                -73.94316093899997,
                40.84993545899992
              ],
              [
                -73.94315143499992,
                40.850004033999944
              ],
              [
                -73.94311799599984,
                40.85023724299993
              ],
              [
                -73.94309647899988,
                40.850387302999884
              ],
              [
                -73.94298963999994,
                40.85081850399992
              ],
              [
                -73.94290346199983,
                40.85099716399988
              ],
              [
                -73.94279927299995,
                40.85117167299994
              ],
              [
                -73.94267766599995,
                40.85134048199993
              ],
              [
                -73.9425395099999,
                40.85150210499991
              ],
              [
                -73.94247684499997,
                40.851564590999885
              ],
              [
                -73.94238599199987,
                40.85165518499987
              ],
              [
                -73.94221850999999,
                40.85179850399986
              ],
              [
                -73.94203871799984,
                40.851931028999914
              ],
              [
                -73.94185191299992,
                40.851821335999944
              ],
              [
                -73.94126220299982,
                40.85139196199991
              ],
              [
                -73.9410889599999,
                40.85135745699994
              ],
              [
                -73.94108888899997,
                40.85135743899991
              ],
              [
                -73.94108881800004,
                40.851357429999894
              ],
              [
                -73.94088990599982,
                40.851330403999945
              ],
              [
                -73.94088972799987,
                40.8513303759999
              ],
              [
                -73.94088954899996,
                40.851330367999935
              ],
              [
                -73.94068822000003,
                40.851315432999854
              ],
              [
                -73.94068804199995,
                40.85131542399991
              ],
              [
                -73.94068785099988,
                40.85131542399991
              ],
              [
                -73.9404858699999,
                40.85131270899989
              ],
              [
                -73.94048575099985,
                40.85131270899994
              ],
              [
                -73.94048563299987,
                40.85131271699995
              ],
              [
                -73.94028476699981,
                40.851322149999916
              ],
              [
                -73.94010658899992,
                40.85132952199994
              ],
              [
                -73.94010645799983,
                40.85132953199994
              ],
              [
                -73.94010632899983,
                40.85132953199995
              ],
              [
                -73.93992663199994,
                40.851324304999906
              ],
              [
                -73.93992646699995,
                40.851324295999895
              ],
              [
                -73.939926313,
                40.85132427799989
              ],
              [
                -73.93974720899999,
                40.85130626499992
              ],
              [
                -73.9397471139998,
                40.85130625499991
              ],
              [
                -73.93974700799991,
                40.85130623699991
              ],
              [
                -73.93957073799986,
                40.85127555599993
              ],
              [
                -73.93939985299984,
                40.85123275599991
              ],
              [
                -73.93939969799992,
                40.851232718999896
              ],
              [
                -73.93939953299987,
                40.851232664999955
              ],
              [
                -73.93923603299991,
                40.851178486999906
              ],
              [
                -73.93828416699976,
                40.85090854599987
              ],
              [
                -73.93736531699994,
                40.85064452999997
              ],
              [
                -73.93666142799988,
                40.85044732599994
              ],
              [
                -73.93575394299994,
                40.8501682029999
              ],
              [
                -73.93639683100004,
                40.84954119499985
              ],
              [
                -73.93547108199992,
                40.84914438299994
              ],
              [
                -73.93423946400002,
                40.84862621199994
              ],
              [
                -73.93284984899991,
                40.84803810999989
              ],
              [
                -73.93139355399987,
                40.84742823099986
              ],
              [
                -73.93116151799987,
                40.84774060099992
              ],
              [
                -73.93088430099981,
                40.84811893799988
              ],
              [
                -73.9306210789998,
                40.84848097699991
              ],
              [
                -73.92970731599995,
                40.84812261199989
              ],
              [
                -73.92898242699984,
                40.849256121999915
              ],
              [
                -73.92886675399986,
                40.849392312999925
              ],
              [
                -73.92873382199987,
                40.84952048899989
              ],
              [
                -73.92858520000006,
                40.84963880199997
              ],
              [
                -73.92842288,
                40.84974568099992
              ],
              [
                -73.92824918899986,
                40.84983985499985
              ],
              [
                -73.92747680599985,
                40.85069436999992
              ],
              [
                -73.927079823,
                40.85143234299991
              ],
              [
                -73.92703315699981,
                40.852200774999886
              ],
              [
                -73.92769565799996,
                40.85248778199987
              ],
              [
                -73.92723935000001,
                40.853115371999905
              ],
              [
                -73.92679937899987,
                40.853717144999926
              ],
              [
                -73.92469199999985,
                40.856641996999905
              ],
              [
                -73.92467094699998,
                40.85681088899992
              ],
              [
                -73.92466977999999,
                40.8569826109999
              ],
              [
                -73.92468915299989,
                40.85715512199993
              ],
              [
                -73.9247291889999,
                40.857326286999886
              ],
              [
                -73.92478952099987,
                40.8574939969999
              ],
              [
                -73.92486929999991,
                40.85765619099987
              ],
              [
                -73.92496717399985,
                40.8578110039999
              ],
              [
                -73.92508143699999,
                40.85795678799993
              ],
              [
                -73.92518924999993,
                40.85802753699994
              ],
              [
                -73.92531748399992,
                40.858090806999954
              ],
              [
                -73.925465479,
                40.858142410999974
              ],
              [
                -73.92563075899986,
                40.85817795199993
              ],
              [
                -73.92580872999993,
                40.85819341999993
              ],
              [
                -73.92599285799992,
                40.8581858659999
              ],
              [
                -73.9261752689999,
                40.85815409599992
              ],
              [
                -73.92634775499995,
                40.85809897099994
              ],
              [
                -73.92650299499998,
                40.85802341899991
              ],
              [
                -73.92663553999984,
                40.85793192399994
              ],
              [
                -73.92674232299986,
                40.857829767999874
              ],
              [
                -73.92682273599989,
                40.85772223799992
              ],
              [
                -73.92687811100001,
                40.85761400599994
              ],
              [
                -73.92691117499983,
                40.85750876099994
              ],
              [
                -73.92712222099993,
                40.858109610999854
              ],
              [
                -73.92709951599984,
                40.85827552099992
              ],
              [
                -73.92705253299991,
                40.85844209099991
              ],
              [
                -73.92698078699978,
                40.85860624099996
              ],
              [
                -73.9268847099999,
                40.85876482899988
              ],
              [
                -73.92676563199994,
                40.85891478399991
              ],
              [
                -73.9266257519999,
                40.8590533259999
              ],
              [
                -73.92646803499991,
                40.859178123999854
              ],
              [
                -73.92629594899988,
                40.8592874139999
              ],
              [
                -73.92611328899989,
                40.85938011099994
              ],
              [
                -73.9259203249999,
                40.85950140899994
              ],
              [
                -73.92573839899995,
                40.85963767999989
              ],
              [
                -73.9255703789999,
                40.859788079999895
              ],
              [
                -73.92541898499994,
                40.85995133099994
              ],
              [
                -73.9252866269999,
                40.86012579699997
              ],
              [
                -73.92517535599984,
                40.86030947899993
              ],
              [
                -73.92508669599982,
                40.86050012699994
              ],
              [
                -73.92502162200003,
                40.86069531099992
              ],
              [
                -73.92498050399993,
                40.86089251899991
              ],
              [
                -73.92496310699987,
                40.861089282999934
              ],
              [
                -73.92496778999988,
                40.861253467999916
              ],
              [
                -73.92496864099992,
                40.86128326999995
              ],
              [
                -73.92499818399988,
                40.86146373799991
              ],
              [
                -73.92474153399992,
                40.861593637999924
              ],
              [
                -73.92537318199986,
                40.86258081399991
              ],
              [
                -73.92601092899994,
                40.86357366499991
              ],
              [
                -73.92661948999994,
                40.86452429399989
              ],
              [
                -73.92726915099985,
                40.865534559999944
              ],
              [
                -73.92805770299987,
                40.86619936399994
              ],
              [
                -73.92872203199992,
                40.8667426629999
              ],
              [
                -73.92934104099999,
                40.86725723999994
              ],
              [
                -73.92997974799981,
                40.86779614899993
              ],
              [
                -73.93091942499993,
                40.868571000999935
              ],
              [
                -73.93117877500005,
                40.86876886699991
              ],
              [
                -73.93127671599983,
                40.86884359399991
              ],
              [
                -73.93141437399996,
                40.868961927999905
              ],
              [
                -73.93156536999994,
                40.86958215799991
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000359702747951,
        "objectid": 244,
        "shape_leng": 0.0805686044599,
        "location_id": 244,
        "zone": "Washington Heights South",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.94068822000003,
                40.851315432999854
              ],
              [
                -73.94088954899996,
                40.851330367999935
              ],
              [
                -73.94088972799987,
                40.8513303759999
              ],
              [
                -73.94088990599982,
                40.851330403999945
              ],
              [
                -73.94108881800004,
                40.851357429999894
              ],
              [
                -73.94108888899997,
                40.85135743899991
              ],
              [
                -73.9410889599999,
                40.85135745699994
              ],
              [
                -73.94126220299982,
                40.85139196199991
              ],
              [
                -73.94185191299992,
                40.851821335999944
              ],
              [
                -73.94203871799984,
                40.851931028999914
              ],
              [
                -73.94221851099991,
                40.85179850399987
              ],
              [
                -73.94238599199987,
                40.85165518499987
              ],
              [
                -73.94247684499997,
                40.851564590999885
              ],
              [
                -73.9425395099999,
                40.85150210499991
              ],
              [
                -73.94267766599995,
                40.85134048199993
              ],
              [
                -73.94279927299995,
                40.85117167299994
              ],
              [
                -73.94290346199983,
                40.85099716399988
              ],
              [
                -73.94298963999994,
                40.85081850399992
              ],
              [
                -73.94309647899988,
                40.850387302999884
              ],
              [
                -73.94311799599984,
                40.85023724299993
              ],
              [
                -73.94315143499992,
                40.850004033999944
              ],
              [
                -73.94316093899997,
                40.84993545899992
              ],
              [
                -73.94316977700004,
                40.84984751199991
              ],
              [
                -73.94317806099984,
                40.849799943999905
              ],
              [
                -73.94381229899996,
                40.84991964099994
              ],
              [
                -73.94396593999994,
                40.849950809999946
              ],
              [
                -73.94409966200001,
                40.849977402999855
              ],
              [
                -73.94472103299982,
                40.850100962999875
              ],
              [
                -73.944768533,
                40.85011040299986
              ],
              [
                -73.944914825,
                40.85013703799996
              ],
              [
                -73.9458970349999,
                40.85032798199989
              ],
              [
                -73.94692626452421,
                40.85052805583417
              ],
              [
                -73.9469644175959,
                40.85046552581819
              ],
              [
                -73.9469980830223,
                40.85039803084266
              ],
              [
                -73.94700167436984,
                40.85038652011265
              ],
              [
                -73.9470111027366,
                40.85035629265677
              ],
              [
                -73.94700459608349,
                40.85034406799342
              ],
              [
                -73.94700406576864,
                40.850329328700184
              ],
              [
                -73.9470439927834,
                40.85025474598786
              ],
              [
                -73.94704980259199,
                40.85022334922832
              ],
              [
                -73.9470192100042,
                40.85019509273561
              ],
              [
                -73.94702897203565,
                40.85014476043617
              ],
              [
                -73.94701781699507,
                40.85012699598264
              ],
              [
                -73.94698645041181,
                40.85011883088543
              ],
              [
                -73.94696404495703,
                40.850111655775514
              ],
              [
                -73.94695342915061,
                40.85009773522208
              ],
              [
                -73.94697447146315,
                40.85008989508124
              ],
              [
                -73.94700527357732,
                40.85008186727115
              ],
              [
                -73.94702536171071,
                40.85006211459108
              ],
              [
                -73.94702782494728,
                40.85004183181244
              ],
              [
                -73.94698516576905,
                40.84999453307054
              ],
              [
                -73.94696706524539,
                40.84999566592067
              ],
              [
                -73.94695814474174,
                40.84998525944927
              ],
              [
                -73.94699578658114,
                40.84995592429225
              ],
              [
                -73.94695222118273,
                40.84989009836441
              ],
              [
                -73.94695108849986,
                40.84985916446555
              ],
              [
                -73.9469592294381,
                40.84984895849044
              ],
              [
                -73.94696652237195,
                40.84981517403269
              ],
              [
                -73.94690063210635,
                40.84967414925802
              ],
              [
                -73.94689505793288,
                40.849639453080734
              ],
              [
                -73.94688244417446,
                40.84959284156913
              ],
              [
                -73.9468547984756,
                40.84953947126502
              ],
              [
                -73.94685525004344,
                40.84942768019967
              ],
              [
                -73.94683872036457,
                40.849382821350986
              ],
              [
                -73.94681164673382,
                40.84935193378877
              ],
              [
                -73.94675656427756,
                40.84930266769502
              ],
              [
                -73.94673313406388,
                40.8492674972145
              ],
              [
                -73.94672051176013,
                40.84923178391935
              ],
              [
                -73.94670601762992,
                40.84909437729706
              ],
              [
                -73.9466649962483,
                40.84904569410086
              ],
              [
                -73.94665064310523,
                40.849005010411695
              ],
              [
                -73.94663550012669,
                40.848912425059694
              ],
              [
                -73.9466785376539,
                40.848837459092984
              ],
              [
                -73.94665040763155,
                40.848667604216814
              ],
              [
                -73.94664422572272,
                40.848399333885375
              ],
              [
                -73.94661949998884,
                40.8482784259119
              ],
              [
                -73.94662027748582,
                40.848214017831104
              ],
              [
                -73.94662285734358,
                40.848145519491496
              ],
              [
                -73.94664058205768,
                40.84793929215048
              ],
              [
                -73.9466560643461,
                40.847883320483525
              ],
              [
                -73.9466857685378,
                40.847728395044264
              ],
              [
                -73.94669858704907,
                40.84749739387238
              ],
              [
                -73.9467493384277,
                40.84740595933717
              ],
              [
                -73.94678220543963,
                40.84735338919667
              ],
              [
                -73.94680935266598,
                40.847288446637464
              ],
              [
                -73.94682445342639,
                40.84713426206979
              ],
              [
                -73.94678956849305,
                40.84699674934533
              ],
              [
                -73.94671767879166,
                40.846824758247124
              ],
              [
                -73.94664058210435,
                40.846684894539045
              ],
              [
                -73.94651683205107,
                40.84659313985066
              ],
              [
                -73.94647353057901,
                40.846528618163475
              ],
              [
                -73.94643643817498,
                40.84643991999421
              ],
              [
                -73.94640288265272,
                40.846361291622344
              ],
              [
                -73.9463507804993,
                40.84624033128926
              ],
              [
                -73.94634839459351,
                40.846156448606806
              ],
              [
                -73.9464258195365,
                40.84606333862832
              ],
              [
                -73.94645597912805,
                40.84605816509614
              ],
              [
                -73.9465038958519,
                40.846028199767574
              ],
              [
                -73.94651730200566,
                40.84599518440371
              ],
              [
                -73.94648387687232,
                40.845964687313604
              ],
              [
                -73.9464454448152,
                40.84592148809305
              ],
              [
                -73.94647950512845,
                40.845919778073025
              ],
              [
                -73.94646620992474,
                40.845881260909486
              ],
              [
                -73.94643878255471,
                40.8458859229234
              ],
              [
                -73.94630774814597,
                40.845740934326024
              ],
              [
                -73.94627664957966,
                40.845690659918404
              ],
              [
                -73.94623062385415,
                40.84557698074459
              ],
              [
                -73.94620682406678,
                40.84546174573894
              ],
              [
                -73.94617174983877,
                40.845392891580666
              ],
              [
                -73.94617704845383,
                40.845365915213144
              ],
              [
                -73.9462106518648,
                40.84533362513184
              ],
              [
                -73.94622271166769,
                40.84525916634131
              ],
              [
                -73.9462057455364,
                40.84523179311284
              ],
              [
                -73.94619138728324,
                40.84519797277932
              ],
              [
                -73.94616086907139,
                40.845125827057785
              ],
              [
                -73.94616018722793,
                40.845029844919516
              ],
              [
                -73.94614692398876,
                40.84493975742343
              ],
              [
                -73.94612836835266,
                40.84491180766122
              ],
              [
                -73.94612272152777,
                40.844879554715824
              ],
              [
                -73.94610929980433,
                40.84485857499072
              ],
              [
                -73.94607880557066,
                40.844597670328326
              ],
              [
                -73.94609124077483,
                40.84449880134601
              ],
              [
                -73.94608425805812,
                40.84446818196512
              ],
              [
                -73.94610537772549,
                40.8443497221328
              ],
              [
                -73.94609316413097,
                40.844291148652864
              ],
              [
                -73.94611699818816,
                40.8442249656692
              ],
              [
                -73.94611365510724,
                40.844196277526095
              ],
              [
                -73.94609415172181,
                40.84409735524896
              ],
              [
                -73.94609745833881,
                40.844055052047274
              ],
              [
                -73.94611933577549,
                40.84400473749401
              ],
              [
                -73.94612416421772,
                40.84389249655713
              ],
              [
                -73.94612625578611,
                40.84388856876929
              ],
              [
                -73.94619788899398,
                40.843754098967956
              ],
              [
                -73.9462489191819,
                40.84367120457854
              ],
              [
                -73.94631349566346,
                40.843559770438965
              ],
              [
                -73.94636919991518,
                40.84345477995481
              ],
              [
                -73.94645858066579,
                40.843254384265926
              ],
              [
                -73.94654808278268,
                40.84311998115941
              ],
              [
                -73.94661642596199,
                40.843020098338044
              ],
              [
                -73.94670984756607,
                40.8429441246515
              ],
              [
                -73.94675510074843,
                40.842888461013025
              ],
              [
                -73.94678768212576,
                40.84281512747888
              ],
              [
                -73.94686918645269,
                40.84270482339683
              ],
              [
                -73.94693502862745,
                40.84261919850936
              ],
              [
                -73.94696012382835,
                40.84253957012678
              ],
              [
                -73.9471071517441,
                40.842306787578885
              ],
              [
                -73.94712053311035,
                40.84228559744328
              ],
              [
                -73.94713367972246,
                40.84225862411276
              ],
              [
                -73.9471678822236,
                40.84215909904335
              ],
              [
                -73.94721047555672,
                40.84210112669678
              ],
              [
                -73.94727430439121,
                40.84205178297001
              ],
              [
                -73.94738322154217,
                40.8419408263045
              ],
              [
                -73.94748896929306,
                40.84169746388172
              ],
              [
                -73.94755609818566,
                40.84152866737061
              ],
              [
                -73.94760600401781,
                40.841409547682304
              ],
              [
                -73.94770934809952,
                40.84126360686615
              ],
              [
                -73.94776196087692,
                40.84112575683381
              ],
              [
                -73.94786003452222,
                40.840896013090095
              ],
              [
                -73.94796701014049,
                40.84065410819866
              ],
              [
                -73.94807484255449,
                40.8404662594779
              ],
              [
                -73.94823352622083,
                40.84011759626999
              ],
              [
                -73.948274498937,
                40.84006354116476
              ],
              [
                -73.94833417923732,
                40.839997335885975
              ],
              [
                -73.94842860791495,
                40.83986085465317
              ],
              [
                -73.94849640289038,
                40.839667593172464
              ],
              [
                -73.94856054520493,
                40.839564885664224
              ],
              [
                -73.94865775026906,
                40.8393013447156
              ],
              [
                -73.94875763601367,
                40.83902023748219
              ],
              [
                -73.94883961981111,
                40.83887563584407
              ],
              [
                -73.9489289116868,
                40.838643426286126
              ],
              [
                -73.94900459767892,
                40.83842558853353
              ],
              [
                -73.9491523412929,
                40.837933552557644
              ],
              [
                -73.94918058897858,
                40.837864010121635
              ],
              [
                -73.94954003717058,
                40.8368796315235
              ],
              [
                -73.94953674996701,
                40.83686508974796
              ],
              [
                -73.94953069616135,
                40.83685106989402
              ],
              [
                -73.94952200610777,
                40.836837873841816
              ],
              [
                -73.94951081592382,
                40.83682573729457
              ],
              [
                -73.94949739893782,
                40.836814981451575
              ],
              [
                -73.94948204647808,
                40.83680583985768
              ],
              [
                -73.94946292070983,
                40.836797746086475
              ],
              [
                -73.94944230013112,
                40.83679215701845
              ],
              [
                -73.94943994696526,
                40.83679169931688
              ],
              [
                -73.94937713355948,
                40.83678033441992
              ],
              [
                -73.9493025753354,
                40.836768961646385
              ],
              [
                -73.94929752805186,
                40.83676697343329
              ],
              [
                -73.94929300490867,
                40.836764356847915
              ],
              [
                -73.94928914011716,
                40.83676118952974
              ],
              [
                -73.94928604835373,
                40.83675756546004
              ],
              [
                -73.94928382135787,
                40.836753592172705
              ],
              [
                -73.94928252520906,
                40.83674938756381
              ],
              [
                -73.94928219836629,
                40.836745076392866
              ],
              [
                -73.94928285052791,
                40.83674078658174
              ],
              [
                -73.94928446234256,
                40.83673664541798
              ],
              [
                -73.94928698598417,
                40.836732775778955
              ],
              [
                -73.94929034657056,
                40.83672929248491
              ],
              [
                -73.94932846081275,
                40.836719250936476
              ],
              [
                -73.94938852906095,
                40.83671562194061
              ],
              [
                -73.9494570191996,
                40.836705619473804
              ],
              [
                -73.9495099176216,
                40.83666548968748
              ],
              [
                -73.94954600729368,
                40.83660437150179
              ],
              [
                -73.94958334065868,
                40.83648393783687
              ],
              [
                -73.94960149708459,
                40.83630509930473
              ],
              [
                -73.94962137643174,
                40.836232103601
              ],
              [
                -73.94964541816809,
                40.83622025475018
              ],
              [
                -73.94965628913987,
                40.83619093997075
              ],
              [
                -73.94968634744743,
                40.83610986332498
              ],
              [
                -73.94969117640214,
                40.83607427749749
              ],
              [
                -73.94968950571386,
                40.836041139876336
              ],
              [
                -73.94968349007459,
                40.83592188337068
              ],
              [
                -73.9496931383176,
                40.83586258094349
              ],
              [
                -73.94971243579302,
                40.83577499028423
              ],
              [
                -73.94973891528046,
                40.83570565447781
              ],
              [
                -73.9497425788605,
                40.835628087824865
              ],
              [
                -73.94978471338267,
                40.83552043388431
              ],
              [
                -73.94979826505212,
                40.83548984121036
              ],
              [
                -73.94988915620442,
                40.83528472075901
              ],
              [
                -73.94997968030412,
                40.83487587860572
              ],
              [
                -73.95015521048293,
                40.83439675940792
              ],
              [
                -73.94985590499991,
                40.83445928699996
              ],
              [
                -73.94977616299995,
                40.83445596499992
              ],
              [
                -73.94970807899995,
                40.834449289999945
              ],
              [
                -73.94964119799997,
                40.83443432999993
              ],
              [
                -73.94957431799986,
                40.834419361999934
              ],
              [
                -73.94941570599993,
                40.83435858999992
              ],
              [
                -73.94919448899992,
                40.8342106589999
              ],
              [
                -73.94890543299992,
                40.83406529899992
              ],
              [
                -73.94829108199994,
                40.833861180999946
              ],
              [
                -73.94592558199986,
                40.83280648999989
              ],
              [
                -73.94582395199988,
                40.83276546099989
              ],
              [
                -73.94571933699994,
                40.83272141299989
              ],
              [
                -73.942979662,
                40.8315679799999
              ],
              [
                -73.94136799599993,
                40.83089083799995
              ],
              [
                -73.94034643299983,
                40.8304578419999
              ],
              [
                -73.94037033300005,
                40.831331253999906
              ],
              [
                -73.94036830799996,
                40.831460492999945
              ],
              [
                -73.94034688799985,
                40.83159040499989
              ],
              [
                -73.94030591799994,
                40.83171845099985
              ],
              [
                -73.94024600800003,
                40.83184212099991
              ],
              [
                -73.94016848499993,
                40.8319590559999
              ],
              [
                -73.94007535699994,
                40.83206722799986
              ],
              [
                -73.93967601899988,
                40.83268537099987
              ],
              [
                -73.93962608699985,
                40.832798753999896
              ],
              [
                -73.93962606299986,
                40.83279880799989
              ],
              [
                -73.93962603899988,
                40.83279885299988
              ],
              [
                -73.93955995000002,
                40.832908696999894
              ],
              [
                -73.93955986799993,
                40.83290884099993
              ],
              [
                -73.93955977199981,
                40.83290896699988
              ],
              [
                -73.9394782749999,
                40.833013327999886
              ],
              [
                -73.93947814499981,
                40.833013488999924
              ],
              [
                -73.93947800199997,
                40.83301364299994
              ],
              [
                -73.93938222499987,
                40.83311083599996
              ],
              [
                -73.93938216599997,
                40.83311089899993
              ],
              [
                -73.93938209499981,
                40.833110962999875
              ],
              [
                -73.93927390099999,
                40.833199342999876
              ],
              [
                -73.93927371099988,
                40.83319949599997
              ],
              [
                -73.93927348599993,
                40.83319964899993
              ],
              [
                -73.9391548789999,
                40.83327799299992
              ],
              [
                -73.93849196799988,
                40.8338437999999
              ],
              [
                -73.93840360699998,
                40.83390946299992
              ],
              [
                -73.93832027799995,
                40.833978817999935
              ],
              [
                -73.93824224799982,
                40.834051641999935
              ],
              [
                -73.93816976599996,
                40.83412770199994
              ],
              [
                -73.93810306499991,
                40.8342067539999
              ],
              [
                -73.93804235799993,
                40.83428854499991
              ],
              [
                -73.93798783999988,
                40.834372813999956
              ],
              [
                -73.93793968599998,
                40.834459289999856
              ],
              [
                -73.93789804799981,
                40.83454769599993
              ],
              [
                -73.93786306199998,
                40.83463774999997
              ],
              [
                -73.93775116199998,
                40.834890575999964
              ],
              [
                -73.93738281099989,
                40.83569072899993
              ],
              [
                -73.93702353999993,
                40.8364689469999
              ],
              [
                -73.93669078700005,
                40.837193249999935
              ],
              [
                -73.93638316899992,
                40.83785012499988
              ],
              [
                -73.93623075799998,
                40.838077855999884
              ],
              [
                -73.93610200500004,
                40.83822716899989
              ],
              [
                -73.93595950299988,
                40.838369897999904
              ],
              [
                -73.93580416699986,
                40.8385049769999
              ],
              [
                -73.93563711299996,
                40.83863141999997
              ],
              [
                -73.93545962199988,
                40.83874840399992
              ],
              [
                -73.93533073299984,
                40.83887479599988
              ],
              [
                -73.93533061499988,
                40.83887491299989
              ],
              [
                -73.93533051999992,
                40.838875039999934
              ],
              [
                -73.93521776599987,
                40.839015821999936
              ],
              [
                -73.93521772999993,
                40.83901586699992
              ],
              [
                -73.93521769499985,
                40.83901592099991
              ],
              [
                -73.93512487199997,
                40.83916933099994
              ],
              [
                -73.9351247769999,
                40.83916948499991
              ],
              [
                -73.93512471799994,
                40.83916963699992
              ],
              [
                -73.93505508799984,
                40.8393332359999
              ],
              [
                -73.93501119299988,
                40.83950397199993
              ],
              [
                -73.93499463699986,
                40.83967805499989
              ],
              [
                -73.93499462399987,
                40.839678135999925
              ],
              [
                -73.93499462399996,
                40.83967821699988
              ],
              [
                -73.93500574300005,
                40.83985180199986
              ],
              [
                -73.93500575400004,
                40.83985191099992
              ],
              [
                -73.93500577799999,
                40.839852009999916
              ],
              [
                -73.93504373300003,
                40.84002151299995
              ],
              [
                -73.93504374499992,
                40.8400215669999
              ],
              [
                -73.93504376899986,
                40.84002162099989
              ],
              [
                -73.93510666600004,
                40.84018372699994
              ],
              [
                -73.93519161699993,
                40.840335399999915
              ],
              [
                -73.93519169999996,
                40.84033554399989
              ],
              [
                -73.93519180599998,
                40.84033568799992
              ],
              [
                -73.93529543300004,
                40.84047481799991
              ],
              [
                -73.93543637099998,
                40.84075133099986
              ],
              [
                -73.93559506299997,
                40.841173399999924
              ],
              [
                -73.93562818399985,
                40.841279099999944
              ],
              [
                -73.93562826699996,
                40.84127936199993
              ],
              [
                -73.93562830199987,
                40.84127961399994
              ],
              [
                -73.93564242499978,
                40.841389129999904
              ],
              [
                -73.93564244899994,
                40.84138928299992
              ],
              [
                -73.93564243699991,
                40.84138944599988
              ],
              [
                -73.93563666199992,
                40.84150013999988
              ],
              [
                -73.93563664999986,
                40.84150019499988
              ],
              [
                -73.93561111099991,
                40.84160880699991
              ],
              [
                -73.93561108599988,
                40.841608895999876
              ],
              [
                -73.93561105199986,
                40.84160899499988
              ],
              [
                -73.93556697199989,
                40.841712527999924
              ],
              [
                -73.93514017499996,
                40.84231167399989
              ],
              [
                -73.93467106399996,
                40.84295724299987
              ],
              [
                -73.93421198500002,
                40.843568902999955
              ],
              [
                -73.93371553899995,
                40.844246374999905
              ],
              [
                -73.93323248499983,
                40.84490992199988
              ],
              [
                -73.93315044200003,
                40.845021942999914
              ],
              [
                -73.93277020499988,
                40.84554110599987
              ],
              [
                -73.932325153,
                40.84615390099991
              ],
              [
                -73.93224030199994,
                40.84626460299991
              ],
              [
                -73.932186635,
                40.84634306699994
              ],
              [
                -73.93210093699993,
                40.846457729999884
              ],
              [
                -73.93207258099989,
                40.84649567899991
              ],
              [
                -73.93204002799989,
                40.84653350699988
              ],
              [
                -73.93201002999993,
                40.84657596599988
              ],
              [
                -73.93198004399996,
                40.846618414999895
              ],
              [
                -73.93191507399995,
                40.84671052399989
              ],
              [
                -73.93185010299997,
                40.846802632999925
              ],
              [
                -73.93139355399987,
                40.84742823099986
              ],
              [
                -73.93284984899991,
                40.84803810999989
              ],
              [
                -73.93423946399999,
                40.84862621099995
              ],
              [
                -73.93547108199992,
                40.84914438299994
              ],
              [
                -73.93639683100004,
                40.84954119499985
              ],
              [
                -73.93575394299994,
                40.8501682029999
              ],
              [
                -73.93666142799988,
                40.85044732599994
              ],
              [
                -73.93736531799983,
                40.850644529999926
              ],
              [
                -73.93828416699976,
                40.85090854599987
              ],
              [
                -73.93923603299991,
                40.851178486999906
              ],
              [
                -73.93939953299987,
                40.851232664999955
              ],
              [
                -73.93939969799992,
                40.851232718999896
              ],
              [
                -73.93939985299984,
                40.85123275599991
              ],
              [
                -73.93957073799986,
                40.85127555599993
              ],
              [
                -73.93974700799991,
                40.85130623699991
              ],
              [
                -73.9397471139998,
                40.85130625499991
              ],
              [
                -73.93974720899999,
                40.85130626499992
              ],
              [
                -73.939926313,
                40.85132427799989
              ],
              [
                -73.93992646699995,
                40.851324295999895
              ],
              [
                -73.93992663199994,
                40.851324304999906
              ],
              [
                -73.94010632899983,
                40.85132953199995
              ],
              [
                -73.94010645899995,
                40.85132953199995
              ],
              [
                -73.94010659000004,
                40.85132952199991
              ],
              [
                -73.94028476699981,
                40.851322149999916
              ],
              [
                -73.94048563299987,
                40.85131271699995
              ],
              [
                -73.94048575099985,
                40.85131270899994
              ],
              [
                -73.9404858699999,
                40.85131270899989
              ],
              [
                -73.94068785199995,
                40.85131542399988
              ],
              [
                -73.94068804199995,
                40.85131542399991
              ],
              [
                -73.94068822000003,
                40.851315432999854
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0000783395761191,
        "objectid": 253,
        "shape_leng": 0.0360514998192,
        "location_id": 253,
        "zone": "Willets Point",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.83908354399988,
                40.76525691299991
              ],
              [
                -73.83922344399986,
                40.76533629199986
              ],
              [
                -73.83935912299995,
                40.7654098719999
              ],
              [
                -73.8394581342306,
                40.76544576822929
              ],
              [
                -73.83957826853546,
                40.76525232297297
              ],
              [
                -73.83970053428521,
                40.76528958843764
              ],
              [
                -73.83992813236753,
                40.7653502119004
              ],
              [
                -73.84016724084617,
                40.76530854542937
              ],
              [
                -73.84046519597908,
                40.765302870000546
              ],
              [
                -73.84052635479259,
                40.76538799020267
              ],
              [
                -73.84107085269765,
                40.76520840672045
              ],
              [
                -73.84183322138846,
                40.764975053969515
              ],
              [
                -73.84211652153546,
                40.76490103419702
              ],
              [
                -73.8423114797326,
                40.764831665329005
              ],
              [
                -73.84261312263459,
                40.76468298969291
              ],
              [
                -73.84272819396486,
                40.76462854893167
              ],
              [
                -73.84298479705527,
                40.7644235941218
              ],
              [
                -73.84304240810822,
                40.764321319920285
              ],
              [
                -73.84305145175345,
                40.76409735815317
              ],
              [
                -73.84300671126373,
                40.76399462233396
              ],
              [
                -73.84298349190715,
                40.7638318513791
              ],
              [
                -73.84300450700954,
                40.76370422400581
              ],
              [
                -73.84306961269657,
                40.76359256915376
              ],
              [
                -73.8431432418535,
                40.763548094765
              ],
              [
                -73.84343628780456,
                40.7634325661937
              ],
              [
                -73.84352013946436,
                40.76353690309135
              ],
              [
                -73.8435710063455,
                40.7635151328046
              ],
              [
                -73.84365028079017,
                40.76362779479572
              ],
              [
                -73.84368787664133,
                40.76361272608367
              ],
              [
                -73.84361523822149,
                40.763496714061574
              ],
              [
                -73.8436683263364,
                40.76346990739617
              ],
              [
                -73.8435936131629,
                40.763370542788365
              ],
              [
                -73.84377426607954,
                40.76329932296638
              ],
              [
                -73.84411566722947,
                40.76313619957922
              ],
              [
                -73.84428576361255,
                40.76299855215025
              ],
              [
                -73.84467648051529,
                40.762752180857646
              ],
              [
                -73.8447450720674,
                40.76275563070321
              ],
              [
                -73.84523206915873,
                40.762434607618225
              ],
              [
                -73.8456278805877,
                40.762142741359575
              ],
              [
                -73.84670933714986,
                40.761345269319776
              ],
              [
                -73.84663046699997,
                40.76129165899989
              ],
              [
                -73.84606731299985,
                40.76090886399988
              ],
              [
                -73.84601517799996,
                40.7608024889999
              ],
              [
                -73.84597463499995,
                40.76071730199991
              ],
              [
                -73.8459114399999,
                40.76058469999988
              ],
              [
                -73.84585235799983,
                40.76045539799988
              ],
              [
                -73.8454898629999,
                40.75972867199994
              ],
              [
                -73.84513404100001,
                40.75906847499993
              ],
              [
                -73.84479027099985,
                40.75840315899996
              ],
              [
                -73.84444970399977,
                40.7577434839999
              ],
              [
                -73.84404431200002,
                40.756944772999944
              ],
              [
                -73.84363442799987,
                40.75615699399989
              ],
              [
                -73.84324381399976,
                40.755336803999974
              ],
              [
                -73.84253360699991,
                40.75554469899987
              ],
              [
                -73.84183146799998,
                40.75576926799993
              ],
              [
                -73.84113839099993,
                40.756010244999864
              ],
              [
                -73.84045537199984,
                40.75626729599989
              ],
              [
                -73.83978335899994,
                40.75654003599988
              ],
              [
                -73.83912329999988,
                40.75682806199995
              ],
              [
                -73.83875129699992,
                40.75699499099992
              ],
              [
                -73.83867321899987,
                40.75701997899995
              ],
              [
                -73.83861811099983,
                40.75709081599995
              ],
              [
                -73.83679107299986,
                40.761166670999934
              ],
              [
                -73.83689455800001,
                40.762602683999944
              ],
              [
                -73.83689970199995,
                40.7626981179999
              ],
              [
                -73.83691694399995,
                40.7627666799999
              ],
              [
                -73.8369514729998,
                40.76283036999996
              ],
              [
                -73.83790429599993,
                40.764587796999876
              ],
              [
                -73.83908354399988,
                40.76525691299991
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000281298327076,
        "objectid": 246,
        "shape_leng": 0.0694671937437,
        "location_id": 246,
        "zone": "West Chelsea/Hudson Yards",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -74.00439976203513,
                40.76267135909888
              ],
              [
                -74.00442709659245,
                40.76267570735635
              ],
              [
                -74.00444951527267,
                40.76266456720002
              ],
              [
                -74.00455641560922,
                40.762524265025135
              ],
              [
                -74.00455814361395,
                40.762507876075404
              ],
              [
                -74.00454520983433,
                40.76249280194478
              ],
              [
                -74.00233528130057,
                40.76154372700158
              ],
              [
                -74.00278691552613,
                40.76092896970333
              ],
              [
                -74.0031333899139,
                40.76107834509638
              ],
              [
                -74.0033848822989,
                40.76072887070633
              ],
              [
                -74.00358800911194,
                40.76045170695133
              ],
              [
                -74.00382559595194,
                40.760133136666866
              ],
              [
                -74.00392854933564,
                40.760003019720415
              ],
              [
                -74.00357251040072,
                40.75984655897313
              ],
              [
                -74.0036689145353,
                40.75970596957665
              ],
              [
                -74.00411162007795,
                40.7598930073072
              ],
              [
                -74.00413171622804,
                40.759861642395975
              ],
              [
                -74.00422266066995,
                40.75991310304368
              ],
              [
                -74.00421059345649,
                40.75992456661
              ],
              [
                -74.00426869650676,
                40.75995927901335
              ],
              [
                -74.0043188942507,
                40.759910685763465
              ],
              [
                -74.00425967856711,
                40.759875307678534
              ],
              [
                -74.00424487588268,
                40.75988657316653
              ],
              [
                -74.00414863814689,
                40.75983591417084
              ],
              [
                -74.00420247432244,
                40.759761071881755
              ],
              [
                -74.00424697128052,
                40.75969921417134
              ],
              [
                -74.0040798895684,
                40.759626044742674
              ],
              [
                -74.00380657835007,
                40.75951073146595
              ],
              [
                -74.00390742032765,
                40.75937569218353
              ],
              [
                -74.0042062912538,
                40.759501707688415
              ],
              [
                -74.00450483093914,
                40.75908499655266
              ],
              [
                -74.00631515935251,
                40.759842822044675
              ],
              [
                -74.00644352901304,
                40.75989046096714
              ],
              [
                -74.00647588371949,
                40.759893601268054
              ],
              [
                -74.00650687640179,
                40.75989203231714
              ],
              [
                -74.0065295998783,
                40.7598747512412
              ],
              [
                -74.00700358904015,
                40.75923187034106
              ],
              [
                -74.00701038304956,
                40.75920372038661
              ],
              [
                -74.00699375268461,
                40.75917614764263
              ],
              [
                -74.00696428749305,
                40.7591600575616
              ],
              [
                -74.00503105136634,
                40.75835701647308
              ],
              [
                -74.00527894797887,
                40.758028554928195
              ],
              [
                -74.00496777078648,
                40.757884164447944
              ],
              [
                -74.00480392560178,
                40.757809843164665
              ],
              [
                -74.00521187705773,
                40.7572319985399
              ],
              [
                -74.00541885841004,
                40.757323140851746
              ],
              [
                -74.00580062242977,
                40.75680169427738
              ],
              [
                -74.00591251611402,
                40.756636499969474
              ],
              [
                -74.00595462291497,
                40.75657584218473
              ],
              [
                -74.00609900474532,
                40.75636785049746
              ],
              [
                -74.00620548925966,
                40.75622084397098
              ],
              [
                -74.00656620007939,
                40.75572286294469
              ],
              [
                -74.00670291840642,
                40.7555323674909
              ],
              [
                -74.00680635763676,
                40.75539177564034
              ],
              [
                -74.00695917416672,
                40.75518079484938
              ],
              [
                -74.00714468360961,
                40.75493248117337
              ],
              [
                -74.00727181174757,
                40.75498559453522
              ],
              [
                -74.00741362036896,
                40.75478930266942
              ],
              [
                -74.00728499944329,
                40.75473556820319
              ],
              [
                -74.00729394650305,
                40.75472318445086
              ],
              [
                -74.00747193016366,
                40.75447681451167
              ],
              [
                -74.00759664325838,
                40.75453044968608
              ],
              [
                -74.00774478605632,
                40.75433654271943
              ],
              [
                -74.00762013296321,
                40.754281475520145
              ],
              [
                -74.00773968687764,
                40.7539714956911
              ],
              [
                -74.0079038694304,
                40.75354153874585
              ],
              [
                -74.00801152240543,
                40.75325645261208
              ],
              [
                -74.00835352838843,
                40.752350739342255
              ],
              [
                -74.0086879221956,
                40.75249399862839
              ],
              [
                -74.00879210090841,
                40.7523521383795
              ],
              [
                -74.00883632714992,
                40.752371521109524
              ],
              [
                -74.00882994505388,
                40.75238001600714
              ],
              [
                -74.00983056913974,
                40.752803464244515
              ],
              [
                -74.00983854674402,
                40.752794967983014
              ],
              [
                -74.00988774868313,
                40.752814077653284
              ],
              [
                -74.00978913116431,
                40.75294837008777
              ],
              [
                -74.00991916275481,
                40.753003591459176
              ],
              [
                -74.00997058797012,
                40.752933564153146
              ],
              [
                -74.00992010631788,
                40.75291212601697
              ],
              [
                -74.0099905581974,
                40.75281618714907
              ],
              [
                -74.00990631519593,
                40.752780411198394
              ],
              [
                -74.00988922619945,
                40.75280368052555
              ],
              [
                -74.00984627922618,
                40.752785441959404
              ],
              [
                -74.00986566961524,
                40.752763413139334
              ],
              [
                -74.00885173565295,
                40.75234228177297
              ],
              [
                -74.0088394166214,
                40.752358816534645
              ],
              [
                -74.00880273703069,
                40.75234485403722
              ],
              [
                -74.00881779819426,
                40.752324345854184
              ],
              [
                -74.00842636447226,
                40.75215785023921
              ],
              [
                -74.0084789557279,
                40.75201857191993
              ],
              [
                -74.00932795206235,
                40.75237350304618
              ],
              [
                -74.00934089245268,
                40.75235560954558
              ],
              [
                -74.00866456561988,
                40.75207286737424
              ],
              [
                -74.00858095760466,
                40.7520438824116
              ],
              [
                -74.00859053101694,
                40.75202810543436
              ],
              [
                -74.00867223768384,
                40.75206225000071
              ],
              [
                -74.00891472422606,
                40.752163628475834
              ],
              [
                -74.00892887650465,
                40.75214400187013
              ],
              [
                -74.00900762808337,
                40.75217684273936
              ],
              [
                -74.00899734911651,
                40.75218910594712
              ],
              [
                -74.00905774009662,
                40.75221435124321
              ],
              [
                -74.00910596820003,
                40.752142124949444
              ],
              [
                -74.00910757975154,
                40.752139712111855
              ],
              [
                -74.00904909796253,
                40.75211834944301
              ],
              [
                -74.00903895969972,
                40.75212515615248
              ],
              [
                -74.008957422948,
                40.75209022513094
              ],
              [
                -74.00896912527908,
                40.75207679147874
              ],
              [
                -74.00864318237626,
                40.75195650203189
              ],
              [
                -74.00865115936809,
                40.75193951211308
              ],
              [
                -74.00874567715042,
                40.751964395327015
              ],
              [
                -74.00912661123239,
                40.752116895734865
              ],
              [
                -74.0091408211999,
                40.752097594099006
              ],
              [
                -74.00876004993509,
                40.75194801470976
              ],
              [
                -74.00853256925012,
                40.75188360930431
              ],
              [
                -74.00862089839437,
                40.75165257792243
              ],
              [
                -74.00866729071824,
                40.751628197220754
              ],
              [
                -74.00873137829987,
                40.75146589496972
              ],
              [
                -74.00870618650575,
                40.751426805003405
              ],
              [
                -74.00900098259459,
                40.75064818378257
              ],
              [
                -74.00911896644935,
                40.75033244243163
              ],
              [
                -74.00925340917918,
                40.74998520622449
              ],
              [
                -74.00947559600826,
                40.75007515771968
              ],
              [
                -74.00947001922505,
                40.7500811641566
              ],
              [
                -74.00951808686504,
                40.75010700478141
              ],
              [
                -74.0095424804557,
                40.750000949023416
              ],
              [
                -74.00969350260826,
                40.750043936175544
              ],
              [
                -74.00968282368922,
                40.75006275779379
              ],
              [
                -74.01048679093364,
                40.75032668876917
              ],
              [
                -74.01069531261547,
                40.750394514816875
              ],
              [
                -74.01075658033923,
                40.75041085295746
              ],
              [
                -74.01080815800898,
                40.75031931136798
              ],
              [
                -74.01075118603501,
                40.750293977204855
              ],
              [
                -74.01050182205222,
                40.75020981164461
              ],
              [
                -74.01006544547259,
                40.75006803825169
              ],
              [
                -74.00972771074939,
                40.74996378830078
              ],
              [
                -74.00972257401406,
                40.74997202900862
              ],
              [
                -74.00956004978684,
                40.74993504179153
              ],
              [
                -74.00994817938025,
                40.748931988005765
              ],
              [
                -74.01107797059221,
                40.74905164311724
              ],
              [
                -74.01110891182984,
                40.74904987642213
              ],
              [
                -74.01113289942974,
                40.749041633830956
              ],
              [
                -74.01114914281891,
                40.749025747264824
              ],
              [
                -74.01121562130405,
                40.74869682117584
              ],
              [
                -74.01121561813409,
                40.74867799224354
              ],
              [
                -74.01119472751145,
                40.74865681411394
              ],
              [
                -74.01116687561544,
                40.74864504676615
              ],
              [
                -74.00951882360114,
                40.748473540435974
              ],
              [
                -74.00952336741506,
                40.7484479568082
              ],
              [
                -74.00941607672524,
                40.74843693503788
              ],
              [
                -74.00941511636573,
                40.748429586917254
              ],
              [
                -74.00907390770347,
                40.74839506170079
              ],
              [
                -74.00907100927544,
                40.74840976720644
              ],
              [
                -74.00897918237624,
                40.74840021938044
              ],
              [
                -74.00897918405973,
                40.748412709626976
              ],
              [
                -74.00894606502311,
                40.74841189316866
              ],
              [
                -74.0090584790135,
                40.74778564533286
              ],
              [
                -74.0112661125396,
                40.74801371918114
              ],
              [
                -74.0113035299374,
                40.74801457190888
              ],
              [
                -74.01133174441699,
                40.748000575341926
              ],
              [
                -74.01134693158455,
                40.747976205492634
              ],
              [
                -74.01140673935517,
                40.747630550570705
              ],
              [
                -74.01139477623487,
                40.74761090210864
              ],
              [
                -74.0113567533772,
                40.747592418346755
              ],
              [
                -74.00990158912954,
                40.7474384843489
              ],
              [
                -74.00990669970031,
                40.74740684960846
              ],
              [
                -74.0099583844545,
                40.74741167208279
              ],
              [
                -74.00996384996868,
                40.74737780301256
              ],
              [
                -74.0095515541536,
                40.74733934176788
              ],
              [
                -74.0095503986723,
                40.74737070611278
              ],
              [
                -74.00956826626552,
                40.74737108340657
              ],
              [
                -74.00956765300351,
                40.74738787745328
              ],
              [
                -74.00956103476226,
                40.747387733595254
              ],
              [
                -74.00956047962374,
                40.74740279960449
              ],
              [
                -74.0091373625992,
                40.74735976280298
              ],
              [
                -74.00924314592588,
                40.74676279075376
              ],
              [
                -74.01143737944645,
                40.74699227651282
              ],
              [
                -74.01147606280952,
                40.74699668563959
              ],
              [
                -74.01150411235528,
                40.74699080250515
              ],
              [
                -74.01152538638327,
                40.74697314077381
              ],
              [
                -74.01158238743089,
                40.74663553579254
              ],
              [
                -74.0115852836195,
                40.74660905095146
              ],
              [
                -74.01156206111087,
                40.74658699142648
              ],
              [
                -74.0115282185556,
                40.74657522476394
              ],
              [
                -74.00936550495776,
                40.746337200862094
              ],
              [
                -74.00937801611612,
                40.74626255972148
              ],
              [
                -74.00938198694907,
                40.74623886784373
              ],
              [
                -74.00938671069996,
                40.74621068100902
              ],
              [
                -74.0093920495925,
                40.74617882395999
              ],
              [
                -74.00940272604319,
                40.7461151306341
              ],
              [
                -74.00940851345764,
                40.746080594635856
              ],
              [
                -74.00941984903601,
                40.74601296304771
              ],
              [
                -74.00942517623243,
                40.7459811773634
              ],
              [
                -74.00943615127545,
                40.745915693320605
              ],
              [
                -74.00944206850693,
                40.745880389871516
              ],
              [
                -74.00945258331889,
                40.745817656644206
              ],
              [
                -74.00946389855962,
                40.74575014365948
              ],
              [
                -74.00994807913845,
                40.74580017091194
              ],
              [
                -74.00994668462272,
                40.74581951444566
              ],
              [
                -74.01012821162075,
                40.745842335463074
              ],
              [
                -74.01012821388646,
                40.745857238928934
              ],
              [
                -74.01055133475721,
                40.745902883999676
              ],
              [
                -74.01055524902252,
                40.74587110492542
              ],
              [
                -74.01163451251718,
                40.74597839430354
              ],
              [
                -74.01167885505592,
                40.745979461815956
              ],
              [
                -74.0117112349654,
                40.74596125915166
              ],
              [
                -74.01171897530907,
                40.74593984499781
              ],
              [
                -74.0117827582808,
                40.7456015722554
              ],
              [
                -74.0117663544952,
                40.74557798004347
              ],
              [
                -74.01177025765051,
                40.745552784540145
              ],
              [
                -74.01183642872407,
                40.74512596367872
              ],
              [
                -74.01167067583802,
                40.74511397784239
              ],
              [
                -74.01166900853472,
                40.745127331191426
              ],
              [
                -74.01178273500808,
                40.74513495663101
              ],
              [
                -74.01179527645724,
                40.745154666984945
              ],
              [
                -74.01178757177937,
                40.74520520356582
              ],
              [
                -74.01177404681451,
                40.74529390321336
              ],
              [
                -74.01177255159179,
                40.74530370649789
              ],
              [
                -74.01176281151672,
                40.74536759428581
              ],
              [
                -74.01175495761667,
                40.74541909767192
              ],
              [
                -74.01175140766141,
                40.74544238769607
              ],
              [
                -74.01173958552815,
                40.74551991819018
              ],
              [
                -74.01116709976122,
                40.745460352539965
              ],
              [
                -74.01115419176905,
                40.745446378083884
              ],
              [
                -74.01116675082783,
                40.74538529798192
              ],
              [
                -74.01118102964979,
                40.74531584944141
              ],
              [
                -74.01118816871931,
                40.74528112609168
              ],
              [
                -74.01120592447445,
                40.74519477063079
              ],
              [
                -74.01121085294035,
                40.74517080368261
              ],
              [
                -74.01123038057233,
                40.74507583116526
              ],
              [
                -74.01138567845328,
                40.74508800864403
              ],
              [
                -74.01138769974187,
                40.745073112682675
              ],
              [
                -74.0110445915082,
                40.745046212402045
              ],
              [
                -74.0110428174822,
                40.74506096342358
              ],
              [
                -74.01115328709844,
                40.74506864357284
              ],
              [
                -74.01117183119814,
                40.74507825828334
              ],
              [
                -74.0111611341844,
                40.745146176461084
              ],
              [
                -74.01115627315944,
                40.745177047156155
              ],
              [
                -74.01114545795524,
                40.74524572071857
              ],
              [
                -74.01113887006922,
                40.7452875486318
              ],
              [
                -74.01113010029034,
                40.745343228325524
              ],
              [
                -74.01112466229964,
                40.74537776220244
              ],
              [
                -74.01111624163278,
                40.74543612462539
              ],
              [
                -74.01110359804878,
                40.745447670243976
              ],
              [
                -74.01054520392145,
                40.74539213501166
              ],
              [
                -74.010531065377,
                40.745382050389416
              ],
              [
                -74.01053373534732,
                40.74536440745704
              ],
              [
                -74.01053978205333,
                40.745324455419386
              ],
              [
                -74.01055056928816,
                40.745253182077825
              ],
              [
                -74.01056370849734,
                40.74516636225321
              ],
              [
                -74.01056801091896,
                40.74513793282639
              ],
              [
                -74.01058579200937,
                40.74502044656155
              ],
              [
                -74.0106062154018,
                40.74501101647424
              ],
              [
                -74.01076156046132,
                40.74502372618347
              ],
              [
                -74.01076419780681,
                40.74500503202083
              ],
              [
                -74.01041937209395,
                40.744976824673586
              ],
              [
                -74.01041700660251,
                40.744993582703295
              ],
              [
                -74.01053188168753,
                40.74500298186925
              ],
              [
                -74.01055044755887,
                40.74501305771002
              ],
              [
                -74.01052968455852,
                40.745134332512635
              ],
              [
                -74.01052681665274,
                40.745151080706044
              ],
              [
                -74.01052083436335,
                40.745186021358805
              ],
              [
                -74.01051171655337,
                40.74523927525828
              ],
              [
                -74.01050332781186,
                40.74528827090891
              ],
              [
                -74.0104969344119,
                40.74532561543083
              ],
              [
                -74.0104918741746,
                40.74535516701374
              ],
              [
                -74.0104883559496,
                40.74537571509469
              ],
              [
                -74.01046920011856,
                40.74538676602894
              ],
              [
                -74.0099909538001,
                40.74533668667391
              ],
              [
                -74.0099739502191,
                40.74532313510926
              ],
              [
                -74.00997743638763,
                40.74530370360894
              ],
              [
                -74.00998320132649,
                40.74527157308871
              ],
              [
                -74.00999250524056,
                40.7452197190643
              ],
              [
                -74.00999930170602,
                40.74518183979109
              ],
              [
                -74.01000814033448,
                40.745132576765556
              ],
              [
                -74.01001404323704,
                40.745099676615546
              ],
              [
                -74.01002361252539,
                40.745046345318
              ],
              [
                -74.01003848384612,
                40.744963458078104
              ],
              [
                -74.01005375656213,
                40.74495423492994
              ],
              [
                -74.01016465197364,
                40.74496392462975
              ],
              [
                -74.01016790535392,
                40.74494232970965
              ],
              [
                -74.00987211085476,
                40.74491650212583
              ],
              [
                -74.00986948474724,
                40.74493391770714
              ],
              [
                -74.00998685784812,
                40.74494416421518
              ],
              [
                -74.0099982260172,
                40.74495505930206
              ],
              [
                -74.0099891727315,
                40.74500638476202
              ],
              [
                -74.00998135739336,
                40.74505068238846
              ],
              [
                -74.0099735068339,
                40.7450951941157
              ],
              [
                -74.00996759959413,
                40.74512868110846
              ],
              [
                -74.00995852601345,
                40.74518011964658
              ],
              [
                -74.00995048769146,
                40.7452256903209
              ],
              [
                -74.00994341816717,
                40.745265769736356
              ],
              [
                -74.00993796184929,
                40.74529670191295
              ],
              [
                -74.0099343667769,
                40.74531707810188
              ],
              [
                -74.00991660813445,
                40.74532725500702
              ],
              [
                -74.00955832929739,
                40.745288825298694
              ],
              [
                -74.00961227924408,
                40.74499790315053
              ],
              [
                -74.00957440612913,
                40.74499290866286
              ],
              [
                -74.00893975844279,
                40.744909217332385
              ],
              [
                -74.00872986359411,
                40.74488153782969
              ],
              [
                -74.0087278005888,
                40.74485823290996
              ],
              [
                -74.00880283523892,
                40.74443934762423
              ],
              [
                -74.00890032962846,
                40.74391792709609
              ],
              [
                -74.00904207504226,
                40.7439338712551
              ],
              [
                -74.0093942693211,
                40.743968716838175
              ],
              [
                -74.00943800870866,
                40.7439712559757
              ],
              [
                -74.0094886836422,
                40.74369568117951
              ],
              [
                -74.00951201767357,
                40.74367609376027
              ],
              [
                -74.01197763342863,
                40.74393556042097
              ],
              [
                -74.01202085006403,
                40.74393977020892
              ],
              [
                -74.01206903486668,
                40.74392249354837
              ],
              [
                -74.01209008289018,
                40.743897637648885
              ],
              [
                -74.01214813183589,
                40.74355930154757
              ],
              [
                -74.01213726936172,
                40.74353192705927
              ],
              [
                -74.01211940028666,
                40.7435166121826
              ],
              [
                -74.00958464345709,
                40.743263520483104
              ],
              [
                -74.00956843411761,
                40.743257784726346
              ],
              [
                -74.00962015348914,
                40.742951248892375
              ],
              [
                -74.00924074160105,
                40.74291316086339
              ],
              [
                -74.0092401357503,
                40.742899518409985
              ],
              [
                -74.0090915684619,
                40.74288614957498
              ],
              [
                -74.00916608208486,
                40.74241659123163
              ],
              [
                -74.00902439199994,
                40.742399742999964
              ],
              [
                -74.00889339799991,
                40.742384166999926
              ],
              [
                -74.00879080599995,
                40.742375143999915
              ],
              [
                -74.00869902299993,
                40.74236172299993
              ],
              [
                -74.00820401899988,
                40.74214751799986
              ],
              [
                -74.00789568799995,
                40.74257005099985
              ],
              [
                -74.00770461499994,
                40.74283381299992
              ],
              [
                -74.00723774599994,
                40.743470231999865
              ],
              [
                -74.00696604399992,
                40.74384701399987
              ],
              [
                -74.00681149999996,
                40.74406132399992
              ],
              [
                -74.00638477199996,
                40.74464590299987
              ],
              [
                -74.00595977599997,
                40.745230984999935
              ],
              [
                -74.00553403899988,
                40.74581556899989
              ],
              [
                -74.0051071349999,
                40.74640277299988
              ],
              [
                -74.00465274299988,
                40.74702295299991
              ],
              [
                -74.00416830599991,
                40.7476892919999
              ],
              [
                -74.00368344599993,
                40.74835499999994
              ],
              [
                -74.00323561099998,
                40.748974254999936
              ],
              [
                -74.00316116799988,
                40.749076422999906
              ],
              [
                -74.00312806199996,
                40.74912185999996
              ],
              [
                -74.00278526999995,
                40.74959231999986
              ],
              [
                -74.002458683,
                40.75003580599991
              ],
              [
                -74.002431475,
                40.7500727519999
              ],
              [
                -74.00233168699992,
                40.7502082579999
              ],
              [
                -74.00188266699993,
                40.7508279309999
              ],
              [
                -74.0014297059999,
                40.7514457209999
              ],
              [
                -74.000974548,
                40.75205466499991
              ],
              [
                -74.00086139300001,
                40.75221483899993
              ],
              [
                -74.00052667499992,
                40.75268862399987
              ],
              [
                -74.00001529900001,
                40.75339158699997
              ],
              [
                -73.99992459199981,
                40.75351627999993
              ],
              [
                -73.99963228999997,
                40.753918084999945
              ],
              [
                -73.99914292099987,
                40.754584933999865
              ],
              [
                -73.9986606719999,
                40.75525176299991
              ],
              [
                -73.99820870599997,
                40.75586865599991
              ],
              [
                -73.99775836599989,
                40.75648752899987
              ],
              [
                -73.99730671399993,
                40.75711050699996
              ],
              [
                -73.997233738,
                40.7572095429999
              ],
              [
                -73.99692177899989,
                40.757637581999894
              ],
              [
                -73.99685244899985,
                40.75773270899988
              ],
              [
                -73.996800897,
                40.757789159999945
              ],
              [
                -73.99663529299984,
                40.75801292199992
              ],
              [
                -73.99640941599995,
                40.75833915899992
              ],
              [
                -73.99594236599988,
                40.75898097899996
              ],
              [
                -73.99547450199992,
                40.7596268569999
              ],
              [
                -73.99623223099987,
                40.7599460319999
              ],
              [
                -73.99831474500002,
                40.76082320899992
              ],
              [
                -74.00103587499999,
                40.76196835199992
              ],
              [
                -74.0011505409999,
                40.76202581199986
              ],
              [
                -74.00127796299991,
                40.76207582499988
              ],
              [
                -74.00133748499991,
                40.76220852199986
              ],
              [
                -74.00153309083484,
                40.76264459806374
              ],
              [
                -74.00178824022305,
                40.762294520644296
              ],
              [
                -74.00220082073264,
                40.761728432226434
              ],
              [
                -74.00439976203513,
                40.76267135909888
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000205617157843,
        "objectid": 247,
        "shape_leng": 0.0929678582271,
        "location_id": 247,
        "zone": "West Concourse",
        "borough": "Bronx"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.91222180499983,
                40.8423565909999
              ],
              [
                -73.91197045599988,
                40.84310439399994
              ],
              [
                -73.91193751499992,
                40.84320238799992
              ],
              [
                -73.91211222199998,
                40.843260341999915
              ],
              [
                -73.91293803199989,
                40.84359755499991
              ],
              [
                -73.91302876899995,
                40.8436416209999
              ],
              [
                -73.91385799399991,
                40.843978793999945
              ],
              [
                -73.91474419399994,
                40.8443425039999
              ],
              [
                -73.915866478,
                40.84479392499991
              ],
              [
                -73.91611927099989,
                40.84485579299996
              ],
              [
                -73.91684309499995,
                40.8450707789999
              ],
              [
                -73.91689747599987,
                40.84511773399992
              ],
              [
                -73.91729224999992,
                40.84510993999991
              ],
              [
                -73.91732494799987,
                40.845057482999955
              ],
              [
                -73.91740919699994,
                40.84492513499992
              ],
              [
                -73.91746161499991,
                40.844805766999926
              ],
              [
                -73.91749679799989,
                40.84468119099992
              ],
              [
                -73.91751358199987,
                40.844553629999936
              ],
              [
                -73.9175114879999,
                40.8444254339999
              ],
              [
                -73.91749072699996,
                40.844299016999884
              ],
              [
                -73.91745219900001,
                40.844176629999886
              ],
              [
                -73.917444646,
                40.844032681999906
              ],
              [
                -73.91745769299983,
                40.84388636899993
              ],
              [
                -73.91749203599986,
                40.84374026199992
              ],
              [
                -73.91754763700003,
                40.843597024999895
              ],
              [
                -73.91762366200004,
                40.84345932099992
              ],
              [
                -73.91771856499989,
                40.843329573999945
              ],
              [
                -73.91783013899986,
                40.84320987999994
              ],
              [
                -73.91795571399985,
                40.843101899999944
              ],
              [
                -73.91794965199988,
                40.84222957099999
              ],
              [
                -73.91768745899992,
                40.84223933299993
              ],
              [
                -73.91618344399991,
                40.84233489999991
              ],
              [
                -73.91689176399984,
                40.84134725699992
              ],
              [
                -73.91729851599982,
                40.84076611799986
              ],
              [
                -73.91768361999983,
                40.84022549599997
              ],
              [
                -73.91813727499989,
                40.8396068539999
              ],
              [
                -73.91859196099995,
                40.83898208999996
              ],
              [
                -73.91901668400004,
                40.83838109299989
              ],
              [
                -73.919162653,
                40.83825371199993
              ],
              [
                -73.919308634,
                40.83812632299987
              ],
              [
                -73.91938436399987,
                40.838076523999845
              ],
              [
                -73.91969564899983,
                40.83787180699989
              ],
              [
                -73.92001799499995,
                40.83765612799991
              ],
              [
                -73.92036502999981,
                40.83742570699994
              ],
              [
                -73.921882894,
                40.83642570099991
              ],
              [
                -73.92237223199994,
                40.83610874299993
              ],
              [
                -73.92340016399986,
                40.83543276299991
              ],
              [
                -73.92399049499991,
                40.835052297999894
              ],
              [
                -73.92414918399993,
                40.834828566999946
              ],
              [
                -73.92439355099988,
                40.8344840269999
              ],
              [
                -73.92502907499991,
                40.83327258399989
              ],
              [
                -73.92537540099983,
                40.832628095999944
              ],
              [
                -73.92642918799987,
                40.83142328399987
              ],
              [
                -73.9268058709999,
                40.83103556699992
              ],
              [
                -73.92726316799988,
                40.83056486699993
              ],
              [
                -73.92742893599986,
                40.83034231499991
              ],
              [
                -73.92755002799989,
                40.830199590999946
              ],
              [
                -73.92761206299998,
                40.830126482999916
              ],
              [
                -73.92781170699992,
                40.82991861999991
              ],
              [
                -73.92781180199998,
                40.829918520999854
              ],
              [
                -73.92781189699987,
                40.829918431999936
              ],
              [
                -73.92802724899997,
                40.82971953899989
              ],
              [
                -73.92825730199993,
                40.829530513999906
              ],
              [
                -73.92850073899987,
                40.829352349999986
              ],
              [
                -73.92861034599994,
                40.82928090899994
              ],
              [
                -73.92875620599996,
                40.829185834999855
              ],
              [
                -73.928894371,
                40.82910412899993
              ],
              [
                -73.9291719679999,
                40.828939958999854
              ],
              [
                -73.9294498339999,
                40.8288104039999
              ],
              [
                -73.929737931,
                40.828692722999904
              ],
              [
                -73.93003491599993,
                40.828587589999906
              ],
              [
                -73.93033933099994,
                40.82849555499997
              ],
              [
                -73.93064968200004,
                40.82841702999989
              ],
              [
                -73.93095939299991,
                40.82833708899993
              ],
              [
                -73.9311413199999,
                40.82830380799988
              ],
              [
                -73.93119978199985,
                40.82829838699998
              ],
              [
                -73.93126199199995,
                40.82829117399996
              ],
              [
                -73.93146631099984,
                40.82828175999986
              ],
              [
                -73.93208873599987,
                40.82823274499994
              ],
              [
                -73.93250790099984,
                40.82820039299993
              ],
              [
                -73.93294190900001,
                40.82816770599991
              ],
              [
                -73.9330372189999,
                40.8281598459999
              ],
              [
                -73.93307251084069,
                40.828156152018096
              ],
              [
                -73.93305881683789,
                40.82808624381175
              ],
              [
                -73.93300760566254,
                40.82753571370693
              ],
              [
                -73.93298275135783,
                40.82722440674659
              ],
              [
                -73.93295940307415,
                40.826923447769275
              ],
              [
                -73.93281954048418,
                40.82560989294258
              ],
              [
                -73.93276594581108,
                40.82545822768752
              ],
              [
                -73.93275761662524,
                40.82532227041266
              ],
              [
                -73.93274693841826,
                40.82521678307599
              ],
              [
                -73.93273843975051,
                40.82512551193916
              ],
              [
                -73.932730540894,
                40.82498434875589
              ],
              [
                -73.93272841961661,
                40.824912542957264
              ],
              [
                -73.93270505627336,
                40.824559724000416
              ],
              [
                -73.93266867562046,
                40.8240783518877
              ],
              [
                -73.93261139002465,
                40.82348761957523
              ],
              [
                -73.93257862720847,
                40.8230407110455
              ],
              [
                -73.93254883578302,
                40.82259921675289
              ],
              [
                -73.93251180889193,
                40.822138815917796
              ],
              [
                -73.93246603518885,
                40.82157743497579
              ],
              [
                -73.93245923880322,
                40.82112061214108
              ],
              [
                -73.93242874737304,
                40.82044714029526
              ],
              [
                -73.93239451747947,
                40.819591336184715
              ],
              [
                -73.93239300371323,
                40.819553493219956
              ],
              [
                -73.93238971325029,
                40.8195028473368
              ],
              [
                -73.9323884755583,
                40.81946002788708
              ],
              [
                -73.93238745042831,
                40.81942438714828
              ],
              [
                -73.93233582202046,
                40.81763040488161
              ],
              [
                -73.9323406284524,
                40.817211819802026
              ],
              [
                -73.93236209199594,
                40.81534211832778
              ],
              [
                -73.9323656418262,
                40.81503296487466
              ],
              [
                -73.93237568818476,
                40.814651575305035
              ],
              [
                -73.93241760768083,
                40.814209394510854
              ],
              [
                -73.93242159978016,
                40.814167286885926
              ],
              [
                -73.93242632000944,
                40.81411751897961
              ],
              [
                -73.93236752499996,
                40.81411173099992
              ],
              [
                -73.93222960499986,
                40.81409433299993
              ],
              [
                -73.93139319399977,
                40.81374459799993
              ],
              [
                -73.93130722899988,
                40.81369873899998
              ],
              [
                -73.9312360049999,
                40.813666728999884
              ],
              [
                -73.93115852299998,
                40.813635488999914
              ],
              [
                -73.93104478900003,
                40.81358844199988
              ],
              [
                -73.93056964999984,
                40.81337329799991
              ],
              [
                -73.93039605099992,
                40.81330374699994
              ],
              [
                -73.93027812699984,
                40.813248024999965
              ],
              [
                -73.92921297999986,
                40.81438195799991
              ],
              [
                -73.92919204499995,
                40.814496010999946
              ],
              [
                -73.92896567499989,
                40.81482825499992
              ],
              [
                -73.92885642099992,
                40.81487986599993
              ],
              [
                -73.92821699000002,
                40.81653564699993
              ],
              [
                -73.92775576099996,
                40.817572380999884
              ],
              [
                -73.92731653099999,
                40.81855963399993
              ],
              [
                -73.92687781699988,
                40.81947887199991
              ],
              [
                -73.926624664,
                40.82002639399996
              ],
              [
                -73.92634345699997,
                40.8205785519999
              ],
              [
                -73.92628127099994,
                40.82053715999995
              ],
              [
                -73.92610404099997,
                40.82074597099995
              ],
              [
                -73.92565452999989,
                40.82112523299986
              ],
              [
                -73.92573567299983,
                40.82117423599991
              ],
              [
                -73.92537221999993,
                40.82160251999988
              ],
              [
                -73.92517593199985,
                40.82189919699996
              ],
              [
                -73.92499654899987,
                40.82220270099993
              ],
              [
                -73.9248346779998,
                40.82251216799988
              ],
              [
                -73.92469079500003,
                40.82282671599991
              ],
              [
                -73.92456530199985,
                40.82314545499991
              ],
              [
                -73.92445847599993,
                40.823467428999955
              ],
              [
                -73.92442266399996,
                40.823644865999924
              ],
              [
                -73.92437413499995,
                40.823820682999916
              ],
              [
                -73.924313102,
                40.82399409699992
              ],
              [
                -73.92347379199991,
                40.825413219999916
              ],
              [
                -73.92306184099985,
                40.82609561099993
              ],
              [
                -73.9229413879998,
                40.82629934999995
              ],
              [
                -73.92283308099988,
                40.826507101999944
              ],
              [
                -73.92273725099987,
                40.82671824999995
              ],
              [
                -73.92267670999986,
                40.826849995999936
              ],
              [
                -73.92264951599996,
                40.82697240999992
              ],
              [
                -73.922233014,
                40.827720809999924
              ],
              [
                -73.92211960699996,
                40.82792587599993
              ],
              [
                -73.92183738200004,
                40.82843621099994
              ],
              [
                -73.92174660200004,
                40.82860240899991
              ],
              [
                -73.9213893,
                40.82923701399988
              ],
              [
                -73.92117904700004,
                40.82962895599993
              ],
              [
                -73.9204611219999,
                40.83091482099995
              ],
              [
                -73.91986089399985,
                40.83194722699992
              ],
              [
                -73.919499471,
                40.832479491999905
              ],
              [
                -73.91903136599996,
                40.83308734899991
              ],
              [
                -73.91839346699996,
                40.83380941199988
              ],
              [
                -73.9177866579999,
                40.834429511999886
              ],
              [
                -73.91773115000001,
                40.834486230999886
              ],
              [
                -73.91770299099984,
                40.83451500899989
              ],
              [
                -73.91767974800003,
                40.834538755999894
              ],
              [
                -73.9176438849999,
                40.834575406999946
              ],
              [
                -73.91720735700002,
                40.835021513999884
              ],
              [
                -73.91667316899981,
                40.835567404999956
              ],
              [
                -73.91647433399987,
                40.83577903099991
              ],
              [
                -73.91587067499994,
                40.83639359399992
              ],
              [
                -73.9153159669999,
                40.83694742399997
              ],
              [
                -73.91477300600005,
                40.8375141009999
              ],
              [
                -73.91423754099988,
                40.83810879599992
              ],
              [
                -73.91383125899985,
                40.838639044999866
              ],
              [
                -73.91377104999991,
                40.83872164799992
              ],
              [
                -73.91371372699993,
                40.83880893499992
              ],
              [
                -73.91313156299994,
                40.83981053999986
              ],
              [
                -73.91296000899999,
                40.84019622899994
              ],
              [
                -73.9127782319999,
                40.840691064999945
              ],
              [
                -73.91269769199985,
                40.840930128999894
              ],
              [
                -73.9126225619999,
                40.84115715899992
              ],
              [
                -73.91246930299995,
                40.841620256999924
              ],
              [
                -73.91240381699996,
                40.84181649699989
              ],
              [
                -73.91222180499983,
                40.8423565909999
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000149592769799,
        "objectid": 248,
        "shape_leng": 0.0569191167671,
        "location_id": 248,
        "zone": "West Farms/Bronx River",
        "borough": "Bronx"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.8639374809998,
                40.84004456599995
              ],
              [
                -73.86448011799995,
                40.84108779699995
              ],
              [
                -73.865464502,
                40.84085689199993
              ],
              [
                -73.86636130899984,
                40.840592684999905
              ],
              [
                -73.86724819000003,
                40.840343803999886
              ],
              [
                -73.86740207099996,
                40.840290275999955
              ],
              [
                -73.86782258199997,
                40.840166498999935
              ],
              [
                -73.86830846899983,
                40.83999521299996
              ],
              [
                -73.86874073599989,
                40.83982457599993
              ],
              [
                -73.86920766899992,
                40.839600046999905
              ],
              [
                -73.86955862099985,
                40.8395956529999
              ],
              [
                -73.87021450700003,
                40.839617941999954
              ],
              [
                -73.87120605899995,
                40.839674621999905
              ],
              [
                -73.87272718499993,
                40.83974894499989
              ],
              [
                -73.87277204699981,
                40.83975128199996
              ],
              [
                -73.87298043000007,
                40.83895248499995
              ],
              [
                -73.87312728200004,
                40.838335096999934
              ],
              [
                -73.87314714699997,
                40.83826389899994
              ],
              [
                -73.87322928299983,
                40.83815590199992
              ],
              [
                -73.87347510499988,
                40.8377463399999
              ],
              [
                -73.87353901799992,
                40.83766841799995
              ],
              [
                -73.87367306200004,
                40.837541539999926
              ],
              [
                -73.87379590199993,
                40.83744513299991
              ],
              [
                -73.87401051400002,
                40.83731000499993
              ],
              [
                -73.87407495699992,
                40.8372896169999
              ],
              [
                -73.87419768699993,
                40.83725079499988
              ],
              [
                -73.87430028599981,
                40.83724244299989
              ],
              [
                -73.87437386799988,
                40.8372425229999
              ],
              [
                -73.87454332199997,
                40.83726133799989
              ],
              [
                -73.87471801599999,
                40.83731259599995
              ],
              [
                -73.874908511,
                40.837359609999986
              ],
              [
                -73.87472739999993,
                40.83723397399998
              ],
              [
                -73.87470145099985,
                40.83716231099988
              ],
              [
                -73.87469588499981,
                40.83708331499988
              ],
              [
                -73.87471452199992,
                40.837002253999934
              ],
              [
                -73.87475779999978,
                40.83692560499988
              ],
              [
                -73.87482228199985,
                40.836859479999866
              ],
              [
                -73.87490147299992,
                40.83680809399989
              ],
              [
                -73.87498772399994,
                40.83677297799987
              ],
              [
                -73.87525928600002,
                40.83670942699994
              ],
              [
                -73.87545635000001,
                40.83669198199993
              ],
              [
                -73.87565111799996,
                40.836659548999904
              ],
              [
                -73.87584064899983,
                40.836612485999915
              ],
              [
                -73.87609408699988,
                40.83652621199991
              ],
              [
                -73.87615142899986,
                40.83649857399996
              ],
              [
                -73.87628029499993,
                40.836461531999895
              ],
              [
                -73.8764548759999,
                40.83638732799987
              ],
              [
                -73.87655099199999,
                40.83634482899989
              ],
              [
                -73.87673323499986,
                40.836205617999944
              ],
              [
                -73.87698921499998,
                40.83610249599993
              ],
              [
                -73.87728333599982,
                40.83596804299989
              ],
              [
                -73.87734872299994,
                40.83593341499995
              ],
              [
                -73.87746425099982,
                40.835872232999876
              ],
              [
                -73.87747843299982,
                40.83586472899987
              ],
              [
                -73.87756524599996,
                40.8358187519999
              ],
              [
                -73.87783304799999,
                40.83565562199993
              ],
              [
                -73.87794605999989,
                40.83557678399991
              ],
              [
                -73.87801301899998,
                40.83553007199991
              ],
              [
                -73.87808502000001,
                40.835479842999916
              ],
              [
                -73.87838370399994,
                40.83525903199989
              ],
              [
                -73.87866481499992,
                40.83502565899989
              ],
              [
                -73.87892705599994,
                40.83478096399988
              ],
              [
                -73.87906181199985,
                40.834639270999894
              ],
              [
                -73.87916928999984,
                40.83452626099989
              ],
              [
                -73.87925239499992,
                40.834424789999915
              ],
              [
                -73.87940077099982,
                40.83447236499995
              ],
              [
                -73.88005188499987,
                40.8347020589999
              ],
              [
                -73.88007189299988,
                40.83467187699994
              ],
              [
                -73.88057338999994,
                40.83403109299997
              ],
              [
                -73.88251168699992,
                40.8315543069999
              ],
              [
                -73.88384720899988,
                40.82987251899992
              ],
              [
                -73.88390094800003,
                40.829802815999884
              ],
              [
                -73.88357411800004,
                40.829478645999934
              ],
              [
                -73.8835201879999,
                40.82926018099995
              ],
              [
                -73.88378414099992,
                40.828274291999946
              ],
              [
                -73.88365759199986,
                40.828278785999885
              ],
              [
                -73.88295104599977,
                40.82830386599987
              ],
              [
                -73.88198096699992,
                40.82835597999991
              ],
              [
                -73.88104510399991,
                40.828431812999966
              ],
              [
                -73.88011211299997,
                40.82851166599995
              ],
              [
                -73.8791706709999,
                40.82858218299989
              ],
              [
                -73.87870283599995,
                40.82862110599992
              ],
              [
                -73.8782350109998,
                40.82866001899995
              ],
              [
                -73.87729930499995,
                40.82874277399991
              ],
              [
                -73.87640134499988,
                40.82897729499997
              ],
              [
                -73.87550702499989,
                40.829234134999886
              ],
              [
                -73.87460867899986,
                40.8295029049999
              ],
              [
                -73.87372870299995,
                40.829752885999945
              ],
              [
                -73.87332596899981,
                40.82987665899986
              ],
              [
                -73.8732728849999,
                40.8298937199999
              ],
              [
                -73.87322660099987,
                40.8299061669999
              ],
              [
                -73.87275546499988,
                40.830027881999854
              ],
              [
                -73.87179422399991,
                40.830305438999936
              ],
              [
                -73.87090345699994,
                40.830563653999874
              ],
              [
                -73.87000670000005,
                40.83082034099988
              ],
              [
                -73.86907935400002,
                40.83108592799988
              ],
              [
                -73.86814994399988,
                40.8313603849999
              ],
              [
                -73.86725770399997,
                40.83162391799994
              ],
              [
                -73.86635554699981,
                40.83187448399987
              ],
              [
                -73.86546044899993,
                40.83213164199987
              ],
              [
                -73.86456547299998,
                40.83238923499991
              ],
              [
                -73.86366561299998,
                40.832695324999904
              ],
              [
                -73.86271114399993,
                40.83299150899998
              ],
              [
                -73.86272700700006,
                40.833072220999945
              ],
              [
                -73.86284851199984,
                40.8336902309999
              ],
              [
                -73.86288113299992,
                40.833889072999916
              ],
              [
                -73.86289361599997,
                40.8339642609999
              ],
              [
                -73.86289761399996,
                40.83400338399993
              ],
              [
                -73.862942603,
                40.83429960099994
              ],
              [
                -73.86295952099994,
                40.83442032299993
              ],
              [
                -73.863156384,
                40.83553082199993
              ],
              [
                -73.86355551599988,
                40.83786561999994
              ],
              [
                -73.8639374809998,
                40.84004456599995
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0000722155957729,
        "objectid": 249,
        "shape_leng": 0.03638434365,
        "location_id": 249,
        "zone": "West Village",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -74.00250642399995,
                40.72901638499997
              ],
              [
                -74.00220337299997,
                40.72965325599995
              ],
              [
                -74.00213899299997,
                40.72979574399988
              ],
              [
                -74.0016846549999,
                40.73058156199995
              ],
              [
                -74.00141242499994,
                40.731065255999916
              ],
              [
                -74.00095442299988,
                40.731706097999954
              ],
              [
                -74.00051066399996,
                40.73231522299996
              ],
              [
                -74.000066577,
                40.7329292359999
              ],
              [
                -73.99975771599985,
                40.73334132199989
              ],
              [
                -73.99955589199998,
                40.73361059599991
              ],
              [
                -73.999187803,
                40.734134833999896
              ],
              [
                -73.99871443800002,
                40.73478386099987
              ],
              [
                -73.99853021599989,
                40.73503789199989
              ],
              [
                -73.99827418999986,
                40.73539113999988
              ],
              [
                -73.99780443099984,
                40.73603215799991
              ],
              [
                -73.99734132799996,
                40.736667888999925
              ],
              [
                -73.99683993899998,
                40.73736088899995
              ],
              [
                -73.99968392199985,
                40.73855224899989
              ],
              [
                -74.00252381499989,
                40.73975264399995
              ],
              [
                -74.00515808199998,
                40.74085808099991
              ],
              [
                -74.00529178799992,
                40.74002322299992
              ],
              [
                -74.00539035999991,
                40.73941197299994
              ],
              [
                -74.00549494099988,
                40.73875392299989
              ],
              [
                -74.00559149599995,
                40.73813833999992
              ],
              [
                -74.0056870449999,
                40.73755187899992
              ],
              [
                -74.00579620299999,
                40.73692169799991
              ],
              [
                -74.00585387099987,
                40.73653322599989
              ],
              [
                -74.00596018000002,
                40.7358450069999
              ],
              [
                -74.00606869899997,
                40.735133213999916
              ],
              [
                -74.00618179699998,
                40.734439131999885
              ],
              [
                -74.0062942419999,
                40.733742041999925
              ],
              [
                -74.00640707699989,
                40.733053939999884
              ],
              [
                -74.00650135299995,
                40.73242587499988
              ],
              [
                -74.00662407199995,
                40.73166937899993
              ],
              [
                -74.00673713399992,
                40.7309470659999
              ],
              [
                -74.00685682099994,
                40.7302282359999
              ],
              [
                -74.00697298799992,
                40.72949498999988
              ],
              [
                -74.0070902079999,
                40.72877225299994
              ],
              [
                -74.00531369199996,
                40.72860449399992
              ],
              [
                -74.00312767499993,
                40.72839380099989
              ],
              [
                -74.00281726599992,
                40.72836312899994
              ],
              [
                -74.00250642399995,
                40.72901638499997
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.00013890947321,
        "objectid": 257,
        "shape_leng": 0.0586690259793,
        "location_id": 257,
        "zone": "Windsor Terrace",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.97984261899994,
                40.660727440999956
              ],
              [
                -73.9799265629999,
                40.66073468699993
              ],
              [
                -73.9799267049999,
                40.66073469699994
              ],
              [
                -73.97992682399993,
                40.660734731999916
              ],
              [
                -73.98000088999997,
                40.660757491999924
              ],
              [
                -73.98000113899982,
                40.66075756399992
              ],
              [
                -73.98000138699987,
                40.66075769899989
              ],
              [
                -73.98006025399978,
                40.66079075799991
              ],
              [
                -73.98011964299995,
                40.6608227909999
              ],
              [
                -73.98011991500002,
                40.660822935999896
              ],
              [
                -73.98012012799995,
                40.66082312499993
              ],
              [
                -73.98017186199998,
                40.66086761899989
              ],
              [
                -73.98017201599991,
                40.66086775399986
              ],
              [
                -73.98017209799995,
                40.66086787999992
              ],
              [
                -73.98020992299999,
                40.660923132999876
              ],
              [
                -73.98021005299988,
                40.66092332199989
              ],
              [
                -73.98021012299994,
                40.66092355599994
              ],
              [
                -73.980228943,
                40.660985055999895
              ],
              [
                -73.98022902599985,
                40.660985325999896
              ],
              [
                -73.980229014,
                40.660985577999966
              ],
              [
                -73.98022716299992,
                40.661047847999924
              ],
              [
                -73.98022716299988,
                40.66104797499988
              ],
              [
                -73.98022710399982,
                40.661048127999905
              ],
              [
                -73.98020654499996,
                40.661105459999945
              ],
              [
                -73.98020645099992,
                40.66110571199989
              ],
              [
                -73.98020628599996,
                40.66110595499992
              ],
              [
                -73.98017161099993,
                40.66115465799992
              ],
              [
                -73.982230342,
                40.66214149199994
              ],
              [
                -73.98273252399984,
                40.66153948399988
              ],
              [
                -73.98323712099993,
                40.66093622099991
              ],
              [
                -73.98391792699988,
                40.660137487999876
              ],
              [
                -73.98448024099987,
                40.659598336999935
              ],
              [
                -73.98508679399998,
                40.65901328799989
              ],
              [
                -73.98648200899994,
                40.65990987999993
              ],
              [
                -73.98660379699987,
                40.66001202199988
              ],
              [
                -73.98671251899984,
                40.6601232029999
              ],
              [
                -73.9868065409999,
                40.66024190199991
              ],
              [
                -73.98688464499989,
                40.6603663889999
              ],
              [
                -73.98713178899986,
                40.66037436799995
              ],
              [
                -73.98725068899996,
                40.660378955999924
              ],
              [
                -73.98731033299997,
                40.6603296239999
              ],
              [
                -73.98744982499997,
                40.660189572999926
              ],
              [
                -73.98784059399986,
                40.65981686399987
              ],
              [
                -73.98843135299977,
                40.65925121199996
              ],
              [
                -73.98622761299997,
                40.65792048299988
              ],
              [
                -73.98402397199989,
                40.65659116999997
              ],
              [
                -73.98179568699996,
                40.655246096999925
              ],
              [
                -73.9814529379999,
                40.65341633599998
              ],
              [
                -73.98129697399993,
                40.652637506999895
              ],
              [
                -73.98110911899991,
                40.65159173199992
              ],
              [
                -73.98105378299987,
                40.6513038189999
              ],
              [
                -73.98071174699993,
                40.64952408699987
              ],
              [
                -73.9803633469999,
                40.64769374399988
              ],
              [
                -73.98029115499993,
                40.64729716099987
              ],
              [
                -73.97911821899996,
                40.64742806099992
              ],
              [
                -73.97819074399992,
                40.64752599199992
              ],
              [
                -73.97777380600002,
                40.64534187699998
              ],
              [
                -73.97684760999987,
                40.645442020999944
              ],
              [
                -73.97591918799998,
                40.645544272999935
              ],
              [
                -73.97482741699987,
                40.64566342099995
              ],
              [
                -73.97474803899986,
                40.64526359099989
              ],
              [
                -73.97467086799998,
                40.64493508299989
              ],
              [
                -73.974642245,
                40.64481321899993
              ],
              [
                -73.97442369399981,
                40.64491306499989
              ],
              [
                -73.9741399689999,
                40.64502975399993
              ],
              [
                -73.97310135399987,
                40.6454509759999
              ],
              [
                -73.972085748,
                40.645862324999925
              ],
              [
                -73.97174398199986,
                40.64600110999986
              ],
              [
                -73.97084113699994,
                40.64637857099993
              ],
              [
                -73.97121038999984,
                40.64762015399986
              ],
              [
                -73.97139631700004,
                40.64825778599986
              ],
              [
                -73.97155930399988,
                40.648822827999915
              ],
              [
                -73.9719089039999,
                40.650051409999946
              ],
              [
                -73.9721097859998,
                40.650664142999894
              ],
              [
                -73.97234114899992,
                40.65065904799994
              ],
              [
                -73.97252930399988,
                40.650753079999944
              ],
              [
                -73.9726347479999,
                40.65086201399992
              ],
              [
                -73.97265769799989,
                40.650912039999874
              ],
              [
                -73.97267303599992,
                40.65096372799994
              ],
              [
                -73.97268056299997,
                40.65101640799987
              ],
              [
                -73.97268018299987,
                40.65106939899991
              ],
              [
                -73.97267189899989,
                40.65112201399995
              ],
              [
                -73.97265581899993,
                40.651173570999944
              ],
              [
                -73.9725172119999,
                40.65131415399986
              ],
              [
                -73.97232846299997,
                40.65139792899988
              ],
              [
                -73.97268288599984,
                40.65263927799989
              ],
              [
                -73.97305103699992,
                40.65391250599987
              ],
              [
                -73.97326628099982,
                40.654649260999854
              ],
              [
                -73.97355493999991,
                40.6556439389999
              ],
              [
                -73.97379189999987,
                40.656392322999885
              ],
              [
                -73.97404458299991,
                40.65734725299994
              ],
              [
                -73.97407278699997,
                40.65752097799993
              ],
              [
                -73.97407281199997,
                40.657521085999896
              ],
              [
                -73.97407283499992,
                40.657521192999916
              ],
              [
                -73.97411953499982,
                40.6576937519999
              ],
              [
                -73.97418455199994,
                40.65786350499992
              ],
              [
                -73.97418457500004,
                40.657863576999915
              ],
              [
                -73.97426735599991,
                40.65802878699991
              ],
              [
                -73.97436701299992,
                40.65818790399992
              ],
              [
                -73.97448233999991,
                40.65833944999988
              ],
              [
                -73.97515738099997,
                40.65872279399991
              ],
              [
                -73.97751171399987,
                40.65986275599987
              ],
              [
                -73.97957870099991,
                40.6608532419999
              ],
              [
                -73.97961986699994,
                40.66080721499988
              ],
              [
                -73.9796200439999,
                40.66080700799995
              ],
              [
                -73.97962025699994,
                40.66080686399989
              ],
              [
                -73.97968019899987,
                40.660766737999964
              ],
              [
                -73.97968033000001,
                40.6607666569999
              ],
              [
                -73.97968049599983,
                40.660766593999895
              ],
              [
                -73.97975657599989,
                40.660738601999924
              ],
              [
                -73.97975683499992,
                40.660738501999894
              ],
              [
                -73.97975708399997,
                40.66073846699988
              ],
              [
                -73.9798419799999,
                40.66072744999987
              ],
              [
                -73.97984227599983,
                40.66072741399996
              ],
              [
                -73.97984261899994,
                40.660727440999956
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000240975845956,
        "objectid": 250,
        "shape_leng": 0.0796263465454,
        "location_id": 250,
        "zone": "Westchester Village/Unionport",
        "borough": "Bronx"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.8455308949999,
                40.83917330699989
              ],
              [
                -73.84647864699997,
                40.83980920599987
              ],
              [
                -73.84715638299993,
                40.840263675999914
              ],
              [
                -73.84779791999988,
                40.84069631099988
              ],
              [
                -73.84796242199991,
                40.84082789199989
              ],
              [
                -73.8489861779999,
                40.841599951999854
              ],
              [
                -73.85052278099987,
                40.84278948899986
              ],
              [
                -73.85088863399999,
                40.84275300599993
              ],
              [
                -73.85120896799987,
                40.84271512199991
              ],
              [
                -73.85179886599994,
                40.8426476329999
              ],
              [
                -73.85231153200002,
                40.84258699399992
              ],
              [
                -73.85333524999986,
                40.84247015499989
              ],
              [
                -73.85309460199986,
                40.84104695999991
              ],
              [
                -73.85303510299988,
                40.84073545799989
              ],
              [
                -73.85286997899986,
                40.839851303999936
              ],
              [
                -73.85266480499992,
                40.83878008399992
              ],
              [
                -73.85247938999986,
                40.83778428699986
              ],
              [
                -73.85233148699994,
                40.83701052699998
              ],
              [
                -73.85220720999986,
                40.83634749499992
              ],
              [
                -73.85207005999993,
                40.83562530299994
              ],
              [
                -73.85185868799985,
                40.83477774699989
              ],
              [
                -73.85168459699997,
                40.8342010519999
              ],
              [
                -73.85188599699991,
                40.83417942099992
              ],
              [
                -73.85281379799993,
                40.83407976199991
              ],
              [
                -73.85347099099992,
                40.83401491699993
              ],
              [
                -73.85465135700002,
                40.83388802699988
              ],
              [
                -73.85773226199993,
                40.83355730699993
              ],
              [
                -73.8590567219999,
                40.8334132249999
              ],
              [
                -73.85983937599991,
                40.83334089599988
              ],
              [
                -73.86033845899995,
                40.83325316599994
              ],
              [
                -73.86032508999983,
                40.83319863999986
              ],
              [
                -73.86008270300002,
                40.833111456999866
              ],
              [
                -73.85934693599994,
                40.83288902199995
              ],
              [
                -73.859119143,
                40.83282022499991
              ],
              [
                -73.85847699499992,
                40.832625921999934
              ],
              [
                -73.85766180999998,
                40.83237925499987
              ],
              [
                -73.85739121299999,
                40.83229737199986
              ],
              [
                -73.85738951499985,
                40.83225419999995
              ],
              [
                -73.85738092499992,
                40.832135234999924
              ],
              [
                -73.85737529499985,
                40.83204654599996
              ],
              [
                -73.85732320899999,
                40.83182148399993
              ],
              [
                -73.85715675899989,
                40.83110226799995
              ],
              [
                -73.85698995199976,
                40.83038523999988
              ],
              [
                -73.85682187599986,
                40.829664768999876
              ],
              [
                -73.85665560399987,
                40.828944912999894
              ],
              [
                -73.85649133899996,
                40.82822507799992
              ],
              [
                -73.85641767299995,
                40.82790051999994
              ],
              [
                -73.85638435699991,
                40.82775374199989
              ],
              [
                -73.85632851899997,
                40.82750768399989
              ],
              [
                -73.85615112900003,
                40.82677004599987
              ],
              [
                -73.85635677499992,
                40.82675208499994
              ],
              [
                -73.85632684699985,
                40.82662383499993
              ],
              [
                -73.85629023899993,
                40.82648152999993
              ],
              [
                -73.85626925499986,
                40.826393776999936
              ],
              [
                -73.856260876,
                40.826329366999865
              ],
              [
                -73.85621834399984,
                40.82616062899997
              ],
              [
                -73.85619656499995,
                40.826040994999865
              ],
              [
                -73.855971624,
                40.82606387799991
              ],
              [
                -73.85588881599983,
                40.825673717999955
              ],
              [
                -73.85564695999977,
                40.8246328619999
              ],
              [
                -73.85548601399985,
                40.823912145999934
              ],
              [
                -73.855312208,
                40.823192430999896
              ],
              [
                -73.85513639799981,
                40.822436188999866
              ],
              [
                -73.85207828999987,
                40.82284723799996
              ],
              [
                -73.849017675,
                40.823259957999916
              ],
              [
                -73.84595550900002,
                40.823670982999886
              ],
              [
                -73.84289858800003,
                40.82408305899986
              ],
              [
                -73.84306938499985,
                40.82481661099991
              ],
              [
                -73.84324615099987,
                40.82555394499989
              ],
              [
                -73.84341053499996,
                40.82628047299993
              ],
              [
                -73.84358503199984,
                40.82700294399992
              ],
              [
                -73.843728169,
                40.82766817299994
              ],
              [
                -73.84374214699994,
                40.82773315299989
              ],
              [
                -73.84375757299995,
                40.827782782999876
              ],
              [
                -73.84379428399991,
                40.82789034299988
              ],
              [
                -73.84381208799992,
                40.827962324999916
              ],
              [
                -73.84383805299989,
                40.82810246899989
              ],
              [
                -73.843851037,
                40.82817200499993
              ],
              [
                -73.84385743299994,
                40.82819934399995
              ],
              [
                -73.84389248199977,
                40.828349044999904
              ],
              [
                -73.84390624999996,
                40.82840782999994
              ],
              [
                -73.84370821,
                40.82843879899991
              ],
              [
                -73.84310437699999,
                40.82853286499993
              ],
              [
                -73.84289527503903,
                40.82856917656084
              ],
              [
                -73.84291548074185,
                40.82862246867612
              ],
              [
                -73.84294598405269,
                40.82870291620165
              ],
              [
                -73.84299484136855,
                40.82882746670146
              ],
              [
                -73.84303394542374,
                40.82893498494016
              ],
              [
                -73.84303612708139,
                40.8293069823434
              ],
              [
                -73.8431069159989,
                40.82979413931985
              ],
              [
                -73.84305345327975,
                40.82976209003937
              ],
              [
                -73.84302116297862,
                40.82996158712057
              ],
              [
                -73.84309950959586,
                40.82996001179511
              ],
              [
                -73.84309408652338,
                40.83002097002271
              ],
              [
                -73.84305256297348,
                40.83010156708151
              ],
              [
                -73.84293731237476,
                40.83012140219001
              ],
              [
                -73.84270324854322,
                40.83074502666989
              ],
              [
                -73.84275884293768,
                40.83081848414282
              ],
              [
                -73.8426274568155,
                40.83100012051873
              ],
              [
                -73.84252890878093,
                40.831123150919304
              ],
              [
                -73.84234432773579,
                40.83130200977934
              ],
              [
                -73.84221334682346,
                40.83155050713855
              ],
              [
                -73.84181725906055,
                40.831726193827265
              ],
              [
                -73.84112675314104,
                40.832286983121335
              ],
              [
                -73.84074229145547,
                40.832600779008544
              ],
              [
                -73.84018151749495,
                40.83304342523689
              ],
              [
                -73.83992048489266,
                40.83332079515101
              ],
              [
                -73.83978358795973,
                40.83366724279683
              ],
              [
                -73.83975991139047,
                40.8337178972479
              ],
              [
                -73.83959108476135,
                40.834041688045254
              ],
              [
                -73.8395688621081,
                40.83423049122159
              ],
              [
                -73.8395308518402,
                40.834368918117875
              ],
              [
                -73.8395802779145,
                40.8344917237903
              ],
              [
                -73.83959266810578,
                40.83456047582328
              ],
              [
                -73.83964780499996,
                40.834841145999924
              ],
              [
                -73.83964694899996,
                40.83484286999988
              ],
              [
                -73.83962906799987,
                40.83487888599994
              ],
              [
                -73.83961961899993,
                40.83493079999994
              ],
              [
                -73.83963502599988,
                40.83498510899992
              ],
              [
                -73.83963165699996,
                40.83509367899995
              ],
              [
                -73.83964701399998,
                40.835169230999966
              ],
              [
                -73.83967775499995,
                40.83530853299991
              ],
              [
                -73.83970551900002,
                40.83539354299991
              ],
              [
                -73.83972396100002,
                40.83547854199989
              ],
              [
                -73.83974862799982,
                40.83555882699994
              ],
              [
                -73.83979488581072,
                40.83561920724633
              ],
              [
                -73.83977542353502,
                40.835643079786024
              ],
              [
                -73.83977669622621,
                40.83565901410464
              ],
              [
                -73.83968226014206,
                40.835675810016035
              ],
              [
                -73.83968615907075,
                40.83568975696178
              ],
              [
                -73.8397792782457,
                40.83567594483307
              ],
              [
                -73.83979226941015,
                40.835725751052856
              ],
              [
                -73.83965849740537,
                40.83574348973624
              ],
              [
                -73.83966108593472,
                40.83575743368833
              ],
              [
                -73.83978960150326,
                40.835744667690314
              ],
              [
                -73.83979605087632,
                40.83578848940348
              ],
              [
                -73.83965573346724,
                40.835802235937734
              ],
              [
                -73.83962599488439,
                40.83569971218478
              ],
              [
                -73.83960850400152,
                40.83570805084221
              ],
              [
                -73.83968001567382,
                40.83606315937752
              ],
              [
                -73.83969574806115,
                40.83606318124509
              ],
              [
                -73.83965173481587,
                40.83582911599167
              ],
              [
                -73.83979992825567,
                40.83581139835366
              ],
              [
                -73.83981026660724,
                40.83587414480501
              ],
              [
                -73.83975779019478,
                40.83588801343359
              ],
              [
                -73.83975907248129,
                40.83589996404146
              ],
              [
                -73.8398102282455,
                40.83589007730178
              ],
              [
                -73.83981403197603,
                40.83594385431263
              ],
              [
                -73.83972485456955,
                40.83595368822329
              ],
              [
                -73.83972482818312,
                40.83596464124923
              ],
              [
                -73.83981792943698,
                40.83595879533938
              ],
              [
                -73.83982175649022,
                40.83600261458011
              ],
              [
                -73.83971159080296,
                40.83601739881694
              ],
              [
                -73.83971549108531,
                40.836031344087495
              ],
              [
                -73.83982172292755,
                40.836016554886164
              ],
              [
                -73.83982556569191,
                40.83605440057278
              ],
              [
                -73.83967473692057,
                40.83607709289496
              ],
              [
                -73.8396721387774,
                40.83606713198431
              ],
              [
                -73.83965902852728,
                40.836067113758915
              ],
              [
                -73.83966936372369,
                40.8361308561467
              ],
              [
                -73.83968247158568,
                40.836131870633594
              ],
              [
                -73.83968253637161,
                40.8361049851119
              ],
              [
                -73.83969302493071,
                40.8361049996907
              ],
              [
                -73.8396995054642,
                40.83613587686081
              ],
              [
                -73.83972573144956,
                40.83613392228422
              ],
              [
                -73.83975942901569,
                40.83629627837044
              ],
              [
                -73.83977909556175,
                40.836296305692386
              ],
              [
                -73.83975458111978,
                40.83613197084447
              ],
              [
                -73.83984235556295,
                40.8361162414639
              ],
              [
                -73.83983837999635,
                40.83641969717587
              ],
              [
                -73.83985909371548,
                40.836678286841185
              ],
              [
                -73.83983441084682,
                40.83676600822797
              ],
              [
                -73.83984192033121,
                40.8368852765531
              ],
              [
                -73.83988714189624,
                40.836991133824895
              ],
              [
                -73.83995029818121,
                40.83758389658748
              ],
              [
                -73.83991581404842,
                40.83778279647398
              ],
              [
                -73.83984907065555,
                40.83778811149494
              ],
              [
                -73.83984697847082,
                40.83768897554632
              ],
              [
                -73.83982833571835,
                40.8376871787612
              ],
              [
                -73.83984179647015,
                40.837904938380106
              ],
              [
                -73.83985578100562,
                40.837904957798145
              ],
              [
                -73.83985132007375,
                40.837821749432266
              ],
              [
                -73.83992026416507,
                40.83782066325925
              ],
              [
                -73.83993341062431,
                40.83790249607465
              ],
              [
                -73.84000264348624,
                40.838251975473874
              ],
              [
                -73.83995595134354,
                40.838286517144105
              ],
              [
                -73.84013531533367,
                40.83861417742006
              ],
              [
                -73.84010742962782,
                40.83862842940529
              ],
              [
                -73.8400687997399,
                40.83875204009087
              ],
              [
                -73.84009889636495,
                40.838827455411284
              ],
              [
                -73.84000970504759,
                40.83883809045587
              ],
              [
                -73.84002808869018,
                40.83894787100791
              ],
              [
                -73.84004441282266,
                40.83894435352963
              ],
              [
                -73.84003063598395,
                40.83885759214779
              ],
              [
                -73.84010873870932,
                40.838852118208884
              ],
              [
                -73.84014540732095,
                40.838943997340245
              ],
              [
                -73.84016698511662,
                40.83900415346526
              ],
              [
                -73.84016248431055,
                40.83906606578865
              ],
              [
                -73.84022375735451,
                40.83913683912731
              ],
              [
                -73.84027376887582,
                40.83920627339779
              ],
              [
                -73.84030282537191,
                40.839524313944956
              ],
              [
                -73.83972014457761,
                40.839565405993035
              ],
              [
                -73.83956700299987,
                40.84054722799988
              ],
              [
                -73.83936452799995,
                40.84087706699995
              ],
              [
                -73.84124785499995,
                40.84062162899994
              ],
              [
                -73.84140548300003,
                40.84060250299988
              ],
              [
                -73.84156602199994,
                40.84059525899988
              ],
              [
                -73.84172685399986,
                40.84060017099994
              ],
              [
                -73.84172710199991,
                40.840600179999925
              ],
              [
                -73.84172735199984,
                40.84060020699995
              ],
              [
                -73.84188610300001,
                40.84061717399987
              ],
              [
                -73.84188635200002,
                40.84061720199987
              ],
              [
                -73.84188659999985,
                40.84061724699993
              ],
              [
                -73.84204144699997,
                40.84064589699992
              ],
              [
                -73.84240098600006,
                40.84034851399991
              ],
              [
                -73.8426130889999,
                40.8401671559999
              ],
              [
                -73.8430625279998,
                40.839797205999915
              ],
              [
                -73.84361155600004,
                40.839348325999886
              ],
              [
                -73.84391720399984,
                40.83908632599985
              ],
              [
                -73.84458129199986,
                40.838535507999914
              ],
              [
                -73.8455308949999,
                40.83917330699989
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000625754983157,
        "objectid": 251,
        "shape_leng": 0.1377111611,
        "location_id": 251,
        "zone": "Westerleigh",
        "borough": "Staten Island"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -74.13107460299995,
                40.631147728999956
              ],
              [
                -74.13161834499988,
                40.630583688999884
              ],
              [
                -74.13228531899989,
                40.630018989999925
              ],
              [
                -74.13343731299992,
                40.62907434599989
              ],
              [
                -74.13408698299992,
                40.62954168099987
              ],
              [
                -74.13586108399996,
                40.62806152799991
              ],
              [
                -74.13604609099994,
                40.628188012999956
              ],
              [
                -74.13674237799992,
                40.62866253699986
              ],
              [
                -74.13730784599991,
                40.62819029599989
              ],
              [
                -74.13785800099993,
                40.62769048799991
              ],
              [
                -74.13806393799992,
                40.62756847099992
              ],
              [
                -74.13789126699994,
                40.62745287999996
              ],
              [
                -74.13805849499992,
                40.627068517999874
              ],
              [
                -74.13793305099995,
                40.62695669699984
              ],
              [
                -74.13581008299997,
                40.62506433999991
              ],
              [
                -74.13618173899991,
                40.62483789599987
              ],
              [
                -74.13629823599996,
                40.62475557699985
              ],
              [
                -74.13641924499991,
                40.62467712699986
              ],
              [
                -74.13654454499994,
                40.6246026899999
              ],
              [
                -74.13667390599991,
                40.62453240299991
              ],
              [
                -74.13680709299996,
                40.624466392999864
              ],
              [
                -74.13694386099996,
                40.62440478199993
              ],
              [
                -74.13708396099996,
                40.624347681999915
              ],
              [
                -74.137227136,
                40.62429519699986
              ],
              [
                -74.13742903499994,
                40.624255017999886
              ],
              [
                -74.13763642599997,
                40.62422942999996
              ],
              [
                -74.13784655299993,
                40.62421900999986
              ],
              [
                -74.13805654699992,
                40.62422387299996
              ],
              [
                -74.13826358199992,
                40.62424376999996
              ],
              [
                -74.13850455799997,
                40.622633796999935
              ],
              [
                -74.13861274099997,
                40.62177287799988
              ],
              [
                -74.13836666899986,
                40.6217371169999
              ],
              [
                -74.13853418199996,
                40.62112823399991
              ],
              [
                -74.13873783699994,
                40.62037370299989
              ],
              [
                -74.13900214099995,
                40.61939464299991
              ],
              [
                -74.13923849299987,
                40.61851915699989
              ],
              [
                -74.13944209899994,
                40.617765813999895
              ],
              [
                -74.1396460239999,
                40.61701454899986
              ],
              [
                -74.13982184799993,
                40.61629982499988
              ],
              [
                -74.14072587999992,
                40.61641455499993
              ],
              [
                -74.14107167799995,
                40.61645643799994
              ],
              [
                -74.14161689099993,
                40.61652246999996
              ],
              [
                -74.14173426799988,
                40.61653721899988
              ],
              [
                -74.14233611399992,
                40.61660864399994
              ],
              [
                -74.14326214899995,
                40.616740238999924
              ],
              [
                -74.14419491899997,
                40.61687026199994
              ],
              [
                -74.14512419099998,
                40.6169659789999
              ],
              [
                -74.14603562099998,
                40.617012776999964
              ],
              [
                -74.1464070389999,
                40.61427315099993
              ],
              [
                -74.14645944899996,
                40.61389142499991
              ],
              [
                -74.1465434079999,
                40.61326141199989
              ],
              [
                -74.14670113499992,
                40.612071520999926
              ],
              [
                -74.14680105499993,
                40.6113349189999
              ],
              [
                -74.14689344899988,
                40.610631709999964
              ],
              [
                -74.14696864199988,
                40.61015135599992
              ],
              [
                -74.14774036799996,
                40.60999924999987
              ],
              [
                -74.14893620799992,
                40.609750836999936
              ],
              [
                -74.14954343599996,
                40.60962717299988
              ],
              [
                -74.148783295,
                40.609199986999904
              ],
              [
                -74.14852820199994,
                40.60908079499994
              ],
              [
                -74.14826911999995,
                40.60896670999988
              ],
              [
                -74.14800622599991,
                40.608857808999865
              ],
              [
                -74.14773969799992,
                40.60875416499994
              ],
              [
                -74.147469717,
                40.60865584999992
              ],
              [
                -74.14719646699989,
                40.6085629299999
              ],
              [
                -74.14690609299997,
                40.608468339999895
              ],
              [
                -74.14661053099996,
                40.60838297899991
              ],
              [
                -74.14631056999997,
                40.60830708799991
              ],
              [
                -74.14600697999992,
                40.6082408349999
              ],
              [
                -74.14575166799996,
                40.60818646199987
              ],
              [
                -74.14549412999996,
                40.60813857199988
              ],
              [
                -74.14523464999989,
                40.608097218999895
              ],
              [
                -74.14497351299995,
                40.608062447999934
              ],
              [
                -74.14471100599997,
                40.60803429699994
              ],
              [
                -74.14444741899989,
                40.60801279699993
              ],
              [
                -74.1441830409999,
                40.60799797199993
              ],
              [
                -74.14391816399994,
                40.6079898369999
              ],
              [
                -74.14283290599998,
                40.607992594999885
              ],
              [
                -74.14188196399996,
                40.608042940999894
              ],
              [
                -74.14092528299996,
                40.60808963199984
              ],
              [
                -74.13997406999988,
                40.60814639199986
              ],
              [
                -74.13903904999992,
                40.608195812999924
              ],
              [
                -74.13810528799995,
                40.60824232499986
              ],
              [
                -74.1371740599999,
                40.608292697999914
              ],
              [
                -74.1359058139999,
                40.6083589069999
              ],
              [
                -74.13497712799997,
                40.60841117899987
              ],
              [
                -74.13404435499994,
                40.60845319899988
              ],
              [
                -74.13311710799991,
                40.608505831999864
              ],
              [
                -74.13215051199998,
                40.608551963999965
              ],
              [
                -74.13211741399986,
                40.608264348999946
              ],
              [
                -74.13210459699991,
                40.60815292399991
              ],
              [
                -74.131568734,
                40.60818117199995
              ],
              [
                -74.13094002299995,
                40.60821431199986
              ],
              [
                -74.12983323799993,
                40.60830394299991
              ],
              [
                -74.12874897499987,
                40.608391740999934
              ],
              [
                -74.12784267099991,
                40.60850028899994
              ],
              [
                -74.12742960799994,
                40.608549759999946
              ],
              [
                -74.12674361599996,
                40.60863191499993
              ],
              [
                -74.12650911499995,
                40.60866290799987
              ],
              [
                -74.12630497799988,
                40.608699642999966
              ],
              [
                -74.12520040399997,
                40.60889840499993
              ],
              [
                -74.1251456319999,
                40.608908470999914
              ],
              [
                -74.12452618799996,
                40.609022318999905
              ],
              [
                -74.12398126100001,
                40.609135218999896
              ],
              [
                -74.1235063919999,
                40.60923360199991
              ],
              [
                -74.12326521999991,
                40.60929134799988
              ],
              [
                -74.12252324799988,
                40.60946899999992
              ],
              [
                -74.12247214699987,
                40.60948123499992
              ],
              [
                -74.12135179599994,
                40.609765267999926
              ],
              [
                -74.1204407989999,
                40.61001806699993
              ],
              [
                -74.11993967899996,
                40.61014626799987
              ],
              [
                -74.118326509,
                40.610580774999946
              ],
              [
                -74.11738923899999,
                40.610833214999914
              ],
              [
                -74.11687458199991,
                40.6109718259999
              ],
              [
                -74.11677996799996,
                40.61099204899989
              ],
              [
                -74.11651105799996,
                40.61105092799989
              ],
              [
                -74.1156683379999,
                40.611175384999896
              ],
              [
                -74.11508745399988,
                40.61120943099993
              ],
              [
                -74.11458547799997,
                40.61120229599992
              ],
              [
                -74.11425629899993,
                40.611196149999955
              ],
              [
                -74.11392707099994,
                40.6111766829999
              ],
              [
                -74.11359920399994,
                40.61114391299992
              ],
              [
                -74.11327407999993,
                40.61109793799992
              ],
              [
                -74.11295306899993,
                40.611038972999886
              ],
              [
                -74.11263747399992,
                40.610967366999944
              ],
              [
                -74.11113638099991,
                40.61058605999994
              ],
              [
                -74.11082654399989,
                40.61050691499993
              ],
              [
                -74.11047956499992,
                40.6104186559999
              ],
              [
                -74.11000934099998,
                40.610336205999936
              ],
              [
                -74.10945696499992,
                40.610285438999924
              ],
              [
                -74.10890146199993,
                40.61025158499995
              ],
              [
                -74.10885268299994,
                40.610250109999896
              ],
              [
                -74.1083441789999,
                40.610234767999906
              ],
              [
                -74.10778645199997,
                40.61023505699993
              ],
              [
                -74.10722963899997,
                40.610252444999915
              ],
              [
                -74.10667509699992,
                40.61028680299993
              ],
              [
                -74.10549388999988,
                40.6103436179999
              ],
              [
                -74.10429401399988,
                40.610383201999944
              ],
              [
                -74.10337596999989,
                40.6104134719999
              ],
              [
                -74.10290182399991,
                40.610429102999895
              ],
              [
                -74.10239596999999,
                40.6104480279999
              ],
              [
                -74.10181225499998,
                40.61046986299992
              ],
              [
                -74.101654781,
                40.61047575399987
              ],
              [
                -74.10138596799993,
                40.610485807999915
              ],
              [
                -74.1008280689999,
                40.6105066719999
              ],
              [
                -74.10003616599987,
                40.61053628199991
              ],
              [
                -74.09946989799992,
                40.61055810099989
              ],
              [
                -74.0993845299999,
                40.610561389999916
              ],
              [
                -74.0977696879999,
                40.61062359299991
              ],
              [
                -74.09787758299997,
                40.61072932199992
              ],
              [
                -74.09810312099995,
                40.61096151899993
              ],
              [
                -74.09847060999988,
                40.61135390299989
              ],
              [
                -74.0986215139999,
                40.611518911999894
              ],
              [
                -74.09935042999992,
                40.61241907699984
              ],
              [
                -74.09987733699994,
                40.61291865299989
              ],
              [
                -74.10055168499993,
                40.61346054699993
              ],
              [
                -74.10068375899988,
                40.61355314999987
              ],
              [
                -74.10127614399993,
                40.6139854019999
              ],
              [
                -74.10209434299992,
                40.61460049099991
              ],
              [
                -74.10256802199994,
                40.615187442999854
              ],
              [
                -74.10302593099988,
                40.615781107999936
              ],
              [
                -74.10350925299994,
                40.616416074999925
              ],
              [
                -74.10458409200001,
                40.61761290099991
              ],
              [
                -74.1049532429999,
                40.618022562999904
              ],
              [
                -74.10511622199992,
                40.618203003999945
              ],
              [
                -74.10518709699997,
                40.618281473999915
              ],
              [
                -74.10552598799994,
                40.618592329999906
              ],
              [
                -74.10608939599997,
                40.618984194999925
              ],
              [
                -74.10734089099991,
                40.61954307699993
              ],
              [
                -74.10770635299994,
                40.61969949699992
              ],
              [
                -74.1091080619999,
                40.6202962389999
              ],
              [
                -74.11001550699994,
                40.62068834399987
              ],
              [
                -74.11094392899994,
                40.621194488999876
              ],
              [
                -74.111533938,
                40.621603645999855
              ],
              [
                -74.11235088099993,
                40.62226529199996
              ],
              [
                -74.11326249599989,
                40.62305442499996
              ],
              [
                -74.11541921799997,
                40.623910962999965
              ],
              [
                -74.11627412799992,
                40.62423942099988
              ],
              [
                -74.11701375799994,
                40.624523576999884
              ],
              [
                -74.11724781899987,
                40.62459150899988
              ],
              [
                -74.11747606599991,
                40.62467350199995
              ],
              [
                -74.11769631299995,
                40.62476906499989
              ],
              [
                -74.11790654899988,
                40.624877460999876
              ],
              [
                -74.11810485699996,
                40.62499772999993
              ],
              [
                -74.11828960499999,
                40.62512868299991
              ],
              [
                -74.11845939699991,
                40.62526898999989
              ],
              [
                -74.11916467799988,
                40.62582382799994
              ],
              [
                -74.11967435799991,
                40.62619142199989
              ],
              [
                -74.12012887999997,
                40.6265361529999
              ],
              [
                -74.12056862499992,
                40.626988699999906
              ],
              [
                -74.12125677899994,
                40.62782274999989
              ],
              [
                -74.12216225499999,
                40.627476891999876
              ],
              [
                -74.12270654899999,
                40.62740739599991
              ],
              [
                -74.12365042899994,
                40.627286876999904
              ],
              [
                -74.12424800199987,
                40.627211641999914
              ],
              [
                -74.12513287199992,
                40.627102600999876
              ],
              [
                -74.12666303799995,
                40.626889617999915
              ],
              [
                -74.12826227099993,
                40.62667350399991
              ],
              [
                -74.1292025009999,
                40.626540605999864
              ],
              [
                -74.13007946899988,
                40.62641859599995
              ],
              [
                -74.13069767299993,
                40.62634095599989
              ],
              [
                -74.1313098529999,
                40.62632834299997
              ],
              [
                -74.13099290799993,
                40.6273741099999
              ],
              [
                -74.13069219599991,
                40.628336901999916
              ],
              [
                -74.13056374599995,
                40.628707729999896
              ],
              [
                -74.13033008299993,
                40.62938433899989
              ],
              [
                -74.13024789099997,
                40.62960912999993
              ],
              [
                -74.13014141499997,
                40.629965208999906
              ],
              [
                -74.13047428999985,
                40.63088550899992
              ],
              [
                -74.13059499799988,
                40.630880282999904
              ],
              [
                -74.13107460299995,
                40.631147728999956
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.00102526406057,
        "objectid": 252,
        "shape_leng": 0.158004373564,
        "location_id": 252,
        "zone": "Whitestone",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.82049919995305,
                40.80101146781907
              ],
              [
                -73.82052810788423,
                40.80100602386804
              ],
              [
                -73.82043245966628,
                40.80062786442104
              ],
              [
                -73.82044951258345,
                40.80062346915729
              ],
              [
                -73.82046903023078,
                40.800619050925526
              ],
              [
                -73.82049338047337,
                40.80061494645224
              ],
              [
                -73.82051871637849,
                40.800610753547446
              ],
              [
                -73.82053823274919,
                40.80060656045581
              ],
              [
                -73.8205557928929,
                40.80060233784681
              ],
              [
                -73.82057433750882,
                40.80059846857027
              ],
              [
                -73.8205940665489,
                40.80059470717764
              ],
              [
                -73.82060479533888,
                40.80059283268332
              ],
              [
                -73.82062521884053,
                40.80059077576217
              ],
              [
                -73.82064133007555,
                40.800587891421806
              ],
              [
                -73.82071455654156,
                40.80057708085977
              ],
              [
                -73.82078588271298,
                40.80056048288758
              ],
              [
                -73.82085451882595,
                40.800538281288105
              ],
              [
                -73.82091970490475,
                40.8005107218901
              ],
              [
                -73.82098071917677,
                40.800478109846985
              ],
              [
                -73.82103688606428,
                40.80044080625728
              ],
              [
                -73.82108758366641,
                40.800399224166185
              ],
              [
                -73.82116590644789,
                40.800325719369305
              ],
              [
                -73.8212493571626,
                40.800255548872805
              ],
              [
                -73.82133769042089,
                40.800188919002146
              ],
              [
                -73.82143064647926,
                40.800126025671275
              ],
              [
                -73.82144779783906,
                40.8001157594921
              ],
              [
                -73.8214635046579,
                40.8001049683945
              ],
              [
                -73.82147972283319,
                40.80009330528056
              ],
              [
                -73.82149078825876,
                40.80008547034405
              ],
              [
                -73.82150642539645,
                40.80007394084927
              ],
              [
                -73.82152607223833,
                40.80006063458997
              ],
              [
                -73.82153660313013,
                40.800053348304054
              ],
              [
                -73.82154817904052,
                40.80004504524934
              ],
              [
                -73.8215597305343,
                40.800036967812204
              ],
              [
                -73.8215710235983,
                40.800028007283444
              ],
              [
                -73.82158353845654,
                40.80001869734453
              ],
              [
                -73.82159516299573,
                40.80001025933509
              ],
              [
                -73.82160695410658,
                40.80000094877601
              ],
              [
                -73.82161789179172,
                40.79999198769279
              ],
              [
                -73.82163578469607,
                40.79997480702593
              ],
              [
                -73.82165135315044,
                40.79995795596751
              ],
              [
                -73.82166696739415,
                40.799941455272865
              ],
              [
                -73.82168293714544,
                40.79992572239155
              ],
              [
                -73.8216956852832,
                40.79991292693704
              ],
              [
                -73.82170694796247,
                40.799902291598734
              ],
              [
                -73.82172024104517,
                40.79989023744569
              ],
              [
                -73.82173446113313,
                40.799876696598496
              ],
              [
                -73.82174443958424,
                40.799863295272296
              ],
              [
                -73.82175519183347,
                40.799852685943875
              ],
              [
                -73.82176213888194,
                40.79984701389647
              ],
              [
                -73.82177979890135,
                40.799832390454014
              ],
              [
                -73.82179207914507,
                40.799822170467614
              ],
              [
                -73.82180068691181,
                40.79981597897685
              ],
              [
                -73.8218124882622,
                40.79980759616689
              ],
              [
                -73.82182869117914,
                40.79979730099058
              ],
              [
                -73.82184193888526,
                40.799788676664015
              ],
              [
                -73.82185814664778,
                40.799776320261124
              ],
              [
                -73.8218714089457,
                40.79976215757086
              ],
              [
                -73.82187484687593,
                40.79975754354379
              ],
              [
                -73.82188235110192,
                40.79974746057427
              ],
              [
                -73.82189354604726,
                40.79973517649737
              ],
              [
                -73.82190473794974,
                40.799724028235566
              ],
              [
                -73.82191596602847,
                40.79971287014451
              ],
              [
                -73.8219249157312,
                40.79970278940517
              ],
              [
                -73.8219360760507,
                40.799690505270625
              ],
              [
                -73.82194910988227,
                40.79967751372212
              ],
              [
                -73.8219595790757,
                40.799666715136304
              ],
              [
                -73.82196943174213,
                40.79965588812312
              ],
              [
                -73.82197884761408,
                40.799644348955134
              ],
              [
                -73.82199062698199,
                40.79963097668998
              ],
              [
                -73.82200193371683,
                40.79961685602886
              ],
              [
                -73.82201226958716,
                40.799602734370126
              ],
              [
                -73.82202061198767,
                40.79958933049335
              ],
              [
                -73.82202989337489,
                40.799574819719034
              ],
              [
                -73.82203928318566,
                40.79955957953919
              ],
              [
                -73.82204868514184,
                40.79954432999572
              ],
              [
                -73.82204920945453,
                40.79954355683873
              ],
              [
                -73.82206010615744,
                40.799527445004365
              ],
              [
                -73.82207037323403,
                40.799512576239174
              ],
              [
                -73.82208117471227,
                40.799496951754186
              ],
              [
                -73.82209190587866,
                40.79948107352625
              ],
              [
                -73.82209909838669,
                40.79946766786897
              ],
              [
                -73.82210659374235,
                40.799456837205675
              ],
              [
                -73.8221138515337,
                40.79944564549363
              ],
              [
                -73.82212114273379,
                40.7994355626819
              ],
              [
                -73.82212930449958,
                40.79942324759963
              ],
              [
                -73.82214658872353,
                40.79939829391659
              ],
              [
                -73.82215564923526,
                40.79938672788399
              ],
              [
                -73.82216442676987,
                40.79937480073236
              ],
              [
                -73.82217323847071,
                40.7993628736327
              ],
              [
                -73.82218186146437,
                40.799350946241255
              ],
              [
                -73.82219005802658,
                40.799339378873235
              ],
              [
                -73.82220488665996,
                40.799319941013586
              ],
              [
                -73.8222182547426,
                40.799301601037406
              ],
              [
                -73.82222579350075,
                40.799291508716735
              ],
              [
                -73.82223391759052,
                40.799280327714314
              ],
              [
                -73.8222429060785,
                40.79926913916538
              ],
              [
                -73.82225214226386,
                40.79925831234973
              ],
              [
                -73.82226257672107,
                40.79924677472853
              ],
              [
                -73.82227340628576,
                40.79923413875037
              ],
              [
                -73.82228583694548,
                40.79922076745852
              ],
              [
                -73.82229692766207,
                40.79920774540008
              ],
              [
                -73.82230779182088,
                40.79919511064456
              ],
              [
                -73.82231764347804,
                40.7991842841021
              ],
              [
                -73.82232800460899,
                40.799173862917414
              ],
              [
                -73.82234452634404,
                40.79915478833083
              ],
              [
                -73.82235317818854,
                40.79914582365408
              ],
              [
                -73.82237077314443,
                40.79912861493817
              ],
              [
                -73.82238754736628,
                40.79911289157357
              ],
              [
                -73.82259282686805,
                40.799417369630845
              ],
              [
                -73.82262174556764,
                40.79940780807213
              ],
              [
                -73.82241227004737,
                40.79908891375365
              ],
              [
                -73.82243106099395,
                40.79907208514291
              ],
              [
                -73.82244095680251,
                40.799063121873054
              ],
              [
                -73.822450611425,
                40.79905490740364
              ],
              [
                -73.82246038700627,
                40.79904630395868
              ],
              [
                -73.82247961996698,
                40.799028385766775
              ],
              [
                -73.82249659895142,
                40.79901116787401
              ],
              [
                -73.82250330031033,
                40.79900440581912
              ],
              [
                -73.82251916455868,
                40.79898773568924
              ],
              [
                -73.822742935804,
                40.799332518865896
              ],
              [
                -73.8227772788283,
                40.799320221379006
              ],
              [
                -73.822540564193,
                40.7989572773729
              ],
              [
                -73.82255326774539,
                40.798943709462364
              ],
              [
                -73.82256912856869,
                40.79892798349232
              ],
              [
                -73.82257800073869,
                40.79892011760028
              ],
              [
                -73.82258745707641,
                40.798911513653074
              ],
              [
                -73.82259935617279,
                40.79890184247571
              ],
              [
                -73.82261101579184,
                40.798893189314455
              ],
              [
                -73.82263553621992,
                40.798878621193325
              ],
              [
                -73.82265858258556,
                40.798865842648894
              ],
              [
                -73.82268060684444,
                40.798854178412874
              ],
              [
                -73.82269168154077,
                40.79884703609111
              ],
              [
                -73.8227046664776,
                40.79883904199288
              ],
              [
                -73.82271764914078,
                40.798831569395524
              ],
              [
                -73.82273064539429,
                40.7988241247944
              ],
              [
                -73.82274269160244,
                40.79881681325353
              ],
              [
                -73.82275520220757,
                40.79880895344544
              ],
              [
                -73.82276780065699,
                40.79879956225548
              ],
              [
                -73.822779308267,
                40.7987898629835
              ],
              [
                -73.82279111529684,
                40.79877906637787
              ],
              [
                -73.8228029953688,
                40.798767909201544
              ],
              [
                -73.82281323701933,
                40.79875784847092
              ],
              [
                -73.82282341708411,
                40.798748148326524
              ],
              [
                -73.82283416766423,
                40.798738447885405
              ],
              [
                -73.82285414291074,
                40.79872201738985
              ],
              [
                -73.82287477601989,
                40.798707470717694
              ],
              [
                -73.82289590533946,
                40.79869358954433
              ],
              [
                -73.82292700919174,
                40.79867632142047
              ],
              [
                -73.8229480961598,
                40.798665260124245
              ],
              [
                -73.82295909405569,
                40.79866011640213
              ],
              [
                -73.82298307296713,
                40.79864892334323
              ],
              [
                -73.8230026297057,
                40.79864228972398
              ],
              [
                -73.82302103408746,
                40.798646298381755
              ],
              [
                -73.8230372416952,
                40.79865180787303
              ],
              [
                -73.82305360689693,
                40.79866039654989
              ],
              [
                -73.82327118223391,
                40.79897516359795
              ],
              [
                -73.82330552497581,
                40.79896286612054
              ],
              [
                -73.8230960275046,
                40.79868329836289
              ],
              [
                -73.8231085737902,
                40.798688864711885
              ],
              [
                -73.82311489512173,
                40.798691018065
              ],
              [
                -73.82312841093474,
                40.79868845439326
              ],
              [
                -73.82313673921936,
                40.798680235653045
              ],
              [
                -73.82313766008336,
                40.79867266392122
              ],
              [
                -73.82313573115682,
                40.798667276198465
              ],
              [
                -73.82312337819698,
                40.79865150803102
              ],
              [
                -73.82311590493838,
                40.7986364482758
              ],
              [
                -73.82310839122141,
                40.79862383951942
              ],
              [
                -73.82310769668375,
                40.79861310377281
              ],
              [
                -73.82311396976114,
                40.79860246934969
              ],
              [
                -73.8231240065516,
                40.79859331844132
              ],
              [
                -73.82314020765315,
                40.79858351878379
              ],
              [
                -73.82315027235262,
                40.798577302622746
              ],
              [
                -73.82316181173549,
                40.79856954883153
              ],
              [
                -73.82317382658194,
                40.79856089615797
              ],
              [
                -73.82319351549555,
                40.79854473571746
              ],
              [
                -73.82320630261005,
                40.7985311661872
              ],
              [
                -73.82321139334279,
                40.798519394626844
              ],
              [
                -73.82321205420408,
                40.79851127401778
              ],
              [
                -73.8232197511897,
                40.798500281090796
              ],
              [
                -73.82323365195495,
                40.79848597598692
              ],
              [
                -73.82324241802799,
                40.798478496864064
              ],
              [
                -73.82325371060094,
                40.7984695361684
              ],
              [
                -73.82327725565159,
                40.7984560648775
              ],
              [
                -73.82328823838274,
                40.79845254207465
              ],
              [
                -73.8233143755997,
                40.79844482002742
              ],
              [
                -73.82333338435068,
                40.79843946456705
              ],
              [
                -73.82334751413694,
                40.79843794700047
              ],
              [
                -73.82336058757198,
                40.79844115420241
              ],
              [
                -73.82337529959138,
                40.79844751657073
              ],
              [
                -73.82338938032764,
                40.79845086033818
              ],
              [
                -73.8234030406431,
                40.798446820120795
              ],
              [
                -73.82340970253648,
                40.79844183221197
              ],
              [
                -73.82341152585504,
                40.798433353203414
              ],
              [
                -73.82340872309875,
                40.79841781429596
              ],
              [
                -73.82364368780398,
                40.79878224199502
              ],
              [
                -73.82367983693867,
                40.79876994649953
              ],
              [
                -73.823433234508,
                40.79840651440786
              ],
              [
                -73.82345033382505,
                40.798402038693744
              ],
              [
                -73.823460731983,
                40.798404225746246
              ],
              [
                -73.82347251264153,
                40.79841228416122
              ],
              [
                -73.82348385821915,
                40.79842363897253
              ],
              [
                -73.82349304805373,
                40.798434828484815
              ],
              [
                -73.82350148440605,
                40.798439200073936
              ],
              [
                -73.82350600015731,
                40.79843881883237
              ],
              [
                -73.82351365730578,
                40.79843817402497
              ],
              [
                -73.82351315246025,
                40.798423216097895
              ],
              [
                -73.82350293523476,
                40.79841073775033
              ],
              [
                -73.8234954114755,
                40.798401648875725
              ],
              [
                -73.82349460019587,
                40.79838994030103
              ],
              [
                -73.8234944639465,
                40.798387950060246
              ],
              [
                -73.8234985809342,
                40.798376916452305
              ],
              [
                -73.82350720893336,
                40.79836296342065
              ],
              [
                -73.82351837317447,
                40.798349004890106
              ],
              [
                -73.82352815484006,
                40.79833801513396
              ],
              [
                -73.82353841606222,
                40.79832482918549
              ],
              [
                -73.82354913262645,
                40.79831384152882
              ],
              [
                -73.82356356974728,
                40.79830373270763
              ],
              [
                -73.82357362384315,
                40.79829699498726
              ],
              [
                -73.82358959241631,
                40.798280638810766
              ],
              [
                -73.82361040829848,
                40.79826439006236
              ],
              [
                -73.82362922407796,
                40.798251631653294
              ],
              [
                -73.82364333992571,
                40.79824171129888
              ],
              [
                -73.82366001431268,
                40.79823202101609
              ],
              [
                -73.82366956653428,
                40.79822699295204
              ],
              [
                -73.82368203516226,
                40.7982212486548
              ],
              [
                -73.82371042208516,
                40.798209873385844
              ],
              [
                -73.82372433799061,
                40.79820369053848
              ],
              [
                -73.82373778979141,
                40.798197948241345
              ],
              [
                -73.82375221660752,
                40.79819171122362
              ],
              [
                -73.8237776714884,
                40.798181953099125
              ],
              [
                -73.82379816566444,
                40.79817503196445
              ],
              [
                -73.82381501957484,
                40.798169294356164
              ],
              [
                -73.82382993975929,
                40.798164130746834
              ],
              [
                -73.82384776617477,
                40.79815784563987
              ],
              [
                -73.82385948384697,
                40.79815413510466
              ],
              [
                -73.82386122910178,
                40.798157388598554
              ],
              [
                -73.8238676785514,
                40.79816934853053
              ],
              [
                -73.82387313549054,
                40.79818066733424
              ],
              [
                -73.82387813415245,
                40.798190309515164
              ],
              [
                -73.8238842393205,
                40.798202917745186
              ],
              [
                -73.82388991022779,
                40.798213687392284
              ],
              [
                -73.82389948153417,
                40.79822835343624
              ],
              [
                -73.82390027314939,
                40.79822927201367
              ],
              [
                -73.8239076208587,
                40.798237758339866
              ],
              [
                -73.8239173778405,
                40.798236069848386
              ],
              [
                -73.82393176109085,
                40.79823238214063
              ],
              [
                -73.82400662435127,
                40.798366905972465
              ],
              [
                -73.82402784484827,
                40.79836118320873
              ],
              [
                -73.82394787857629,
                40.79822736408154
              ],
              [
                -73.82395047568913,
                40.798226430912024
              ],
              [
                -73.82396863489204,
                40.79821993001967
              ],
              [
                -73.82398402759729,
                40.798215559841395
              ],
              [
                -73.82399574244394,
                40.798212668821435
              ],
              [
                -73.82401551273695,
                40.79820605344903
              ],
              [
                -73.8240333795598,
                40.79819782290469
              ],
              [
                -73.82403494232726,
                40.79819414241588
              ],
              [
                -73.82402851212223,
                40.79817930996775
              ],
              [
                -73.82402219214279,
                40.79816706260177
              ],
              [
                -73.82401763990725,
                40.79815448539659
              ],
              [
                -73.8240137819841,
                40.798139107894485
              ],
              [
                -73.82401199915849,
                40.798127632710006
              ],
              [
                -73.82401315587505,
                40.79811602698823
              ],
              [
                -73.8240172265261,
                40.7981048039871
              ],
              [
                -73.82402524666442,
                40.79809174909629
              ],
              [
                -73.82403593250702,
                40.7980796627168
              ],
              [
                -73.82404823572678,
                40.7980692537225
              ],
              [
                -73.82406589617567,
                40.79805851969603
              ],
              [
                -73.82408766834955,
                40.79804820639922
              ],
              [
                -73.82409895036021,
                40.79804317222819
              ],
              [
                -73.82411261469552,
                40.79803764598401
              ],
              [
                -73.82412631945525,
                40.79803100274163
              ],
              [
                -73.82414299162568,
                40.798022104947194
              ],
              [
                -73.82415594961215,
                40.798015173423764
              ],
              [
                -73.82416407743435,
                40.79801082664873
              ],
              [
                -73.82417660072626,
                40.798002966705134
              ],
              [
                -73.82418861460364,
                40.79799459218336
              ],
              [
                -73.82420077313752,
                40.79798528141499
              ],
              [
                -73.82421314703053,
                40.79797522381104
              ],
              [
                -73.8242263988437,
                40.79796445556239
              ],
              [
                -73.82423891686308,
                40.7979536603945
              ],
              [
                -73.82425220489337,
                40.797942864723915
              ],
              [
                -73.8242653021729,
                40.79793284390029
              ],
              [
                -73.8242758723422,
                40.7979238820027
              ],
              [
                -73.82428655665724,
                40.797915937993935
              ],
              [
                -73.82429809724164,
                40.797907635277596
              ],
              [
                -73.82430916152028,
                40.79789997127787
              ],
              [
                -73.82433169850215,
                40.79788641698487
              ],
              [
                -73.8243532973943,
                40.797874400479785
              ],
              [
                -73.82437142036838,
                40.79786353208588
              ],
              [
                -73.82439009015606,
                40.797852034292056
              ],
              [
                -73.82440210139622,
                40.79784472252401
              ],
              [
                -73.82441070721514,
                40.79783908031688
              ],
              [
                -73.82441508643042,
                40.79783620505124
              ],
              [
                -73.82442909325388,
                40.79782700630042
              ],
              [
                -73.82444160729729,
                40.79781769655053
              ],
              [
                -73.82445416824199,
                40.79780865641873
              ],
              [
                -73.8244789594347,
                40.797789998690654
              ],
              [
                -73.82449075041026,
                40.79778068783432
              ],
              [
                -73.82450227059607,
                40.79777098958116
              ],
              [
                -73.82451478244087,
                40.797762255101546
              ],
              [
                -73.8245267976518,
                40.797753592905536
              ],
              [
                -73.82453884555974,
                40.79774548794705
              ],
              [
                -73.82455039686182,
                40.797737193932896
              ],
              [
                -73.82457080784843,
                40.797721772638674
              ],
              [
                -73.8245899128831,
                40.79770678207114
              ],
              [
                -73.8246055570433,
                40.79769250575524
              ],
              [
                -73.82461427369883,
                40.79768502645232
              ],
              [
                -73.82462408362619,
                40.79767642404762
              ],
              [
                -73.8246355677732,
                40.797666752027794
              ],
              [
                -73.82464801455137,
                40.7976563431874
              ],
              [
                -73.82466162794213,
                40.79764316173478
              ],
              [
                -73.82467474034358,
                40.79763153850843
              ],
              [
                -73.82468899079853,
                40.79762003394401
              ],
              [
                -73.82470444905407,
                40.79760853121472
              ],
              [
                -73.82471992705914,
                40.79759856773515
              ],
              [
                -73.82473096752513,
                40.797590867473154
              ],
              [
                -73.82474250297047,
                40.79758388815373
              ],
              [
                -73.82475599888767,
                40.797575290839355
              ],
              [
                -73.82476949624709,
                40.797566144377896
              ],
              [
                -73.82478400216341,
                40.79755631561002
              ],
              [
                -73.82479945953686,
                40.797545136190216
              ],
              [
                -73.82481787170418,
                40.79753245772012
              ],
              [
                -73.82483827649422,
                40.797519008810845
              ],
              [
                -73.82487655546714,
                40.797494501072805
              ],
              [
                -73.82489251068328,
                40.79748327900772
              ],
              [
                -73.82490897841184,
                40.79747105591801
              ],
              [
                -73.82492540431831,
                40.797460986299434
              ],
              [
                -73.82494233868213,
                40.79745145755221
              ],
              [
                -73.82495973803958,
                40.79744072299161
              ],
              [
                -73.82497670188856,
                40.797429114960714
              ],
              [
                -73.82499364106665,
                40.79741749750836
              ],
              [
                -73.82501009483249,
                40.79740621202173
              ],
              [
                -73.825025037248,
                40.7973971029044
              ],
              [
                -73.82504612538247,
                40.79738480723419
              ],
              [
                -73.82506496699443,
                40.79737577656828
              ],
              [
                -73.82507990262411,
                40.79736924430662
              ],
              [
                -73.82510243068933,
                40.797359039515946
              ],
              [
                -73.82511588636221,
                40.79735246814865
              ],
              [
                -73.82514036020439,
                40.79734144676908
              ],
              [
                -73.82516577770203,
                40.797332039581306
              ],
              [
                -73.82519068280165,
                40.79732434271366
              ],
              [
                -73.82520825573873,
                40.79731975823043
              ],
              [
                -73.8252267520703,
                40.797315607694834
              ],
              [
                -73.82526060781885,
                40.797310417661286
              ],
              [
                -73.82527960816476,
                40.797308249034664
              ],
              [
                -73.82529854222267,
                40.797308827215936
              ],
              [
                -73.82531554049231,
                40.79731078085816
              ],
              [
                -73.82533369628057,
                40.797314508836465
              ],
              [
                -73.8253554600605,
                40.79732050336674
              ],
              [
                -73.82536830573423,
                40.79732477277685
              ],
              [
                -73.82539243858433,
                40.79733531836187
              ],
              [
                -73.8254092850061,
                40.79734583481297
              ],
              [
                -73.82542459167145,
                40.797360374393556
              ],
              [
                -73.8254396246873,
                40.797370743686855
              ],
              [
                -73.82545531877895,
                40.79737801627009
              ],
              [
                -73.82546689282017,
                40.797383850942026
              ],
              [
                -73.82547457149775,
                40.79739234604668
              ],
              [
                -73.82547591149512,
                40.79740582799393
              ],
              [
                -73.82548346181531,
                40.797418383380375
              ],
              [
                -73.8254937244918,
                40.797427313940936
              ],
              [
                -73.82550843644334,
                40.79743815147462
              ],
              [
                -73.82552009620126,
                40.79743803409919
              ],
              [
                -73.82553181122282,
                40.7974349985167
              ],
              [
                -73.82553800097944,
                40.7974292182131
              ],
              [
                -73.82554212608748,
                40.79741468175813
              ],
              [
                -73.8255352506272,
                40.79740234249946
              ],
              [
                -73.82553768829824,
                40.7973991409285
              ],
              [
                -73.82555525141953,
                40.79739392614762
              ],
              [
                -73.82557158799422,
                40.79739089872796
              ],
              [
                -73.82558961698389,
                40.79738843105546
              ],
              [
                -73.82560666434136,
                40.79738571043985
              ],
              [
                -73.82563123724267,
                40.79738229039635
              ],
              [
                -73.82564879294486,
                40.79737965129051
              ],
              [
                -73.82566682367386,
                40.79737676965396
              ],
              [
                -73.82568727081356,
                40.797373919142736
              ],
              [
                -73.8257125643848,
                40.79737148991092
              ],
              [
                -73.82573406442111,
                40.7973697932222
              ],
              [
                -73.82573789275413,
                40.79736949327641
              ],
              [
                -73.82576219954422,
                40.79736818780887
              ],
              [
                -73.82578553239327,
                40.79736756553998
              ],
              [
                -73.82580789360196,
                40.79736691583144
              ],
              [
                -73.82583024288262,
                40.797366696473
              ],
              [
                -73.82585311222957,
                40.79736646097577
              ],
              [
                -73.82587402789434,
                40.79736643930057
              ],
              [
                -73.82589392212995,
                40.79736663134859
              ],
              [
                -73.82591335337773,
                40.79736718270567
              ],
              [
                -73.82593519870257,
                40.79736907991722
              ],
              [
                -73.82595263113721,
                40.797373195364095
              ],
              [
                -73.82596861990481,
                40.797376379198724
              ],
              [
                -73.82598606248501,
                40.7973804941534
              ],
              [
                -73.82601537230752,
                40.7973877597403
              ],
              [
                -73.82603619215142,
                40.79739201531282
              ],
              [
                -73.82605846825301,
                40.79739660461154
              ],
              [
                -73.82608027172392,
                40.797400942407705
              ],
              [
                -73.82610304625223,
                40.79740578507792
              ],
              [
                -73.82612773868074,
                40.79741111746488
              ],
              [
                -73.82614856928551,
                40.79741598500308
              ],
              [
                -73.82616700235472,
                40.79742254142913
              ],
              [
                -73.82617132764508,
                40.79742702355622
              ],
              [
                -73.82618023649592,
                40.79743222340459
              ],
              [
                -73.82619545967715,
                40.79743819853134
              ],
              [
                -73.82621357170845,
                40.79744487139862
              ],
              [
                -73.82623312581936,
                40.79745283487339
              ],
              [
                -73.82625030331072,
                40.79745911984532
              ],
              [
                -73.82626841794904,
                40.797464882543224
              ],
              [
                -73.82628606681082,
                40.79747190600121
              ],
              [
                -73.82630125088053,
                40.79747952900074
              ],
              [
                -73.8263144770076,
                40.7974875630029
              ],
              [
                -73.82633117140915,
                40.79750183500533
              ],
              [
                -73.82634395738273,
                40.79751525293691
              ],
              [
                -73.82635936342011,
                40.79753311540533
              ],
              [
                -73.82636659926142,
                40.79754333780579
              ],
              [
                -73.82638690818652,
                40.79756678138774
              ],
              [
                -73.82640779152851,
                40.797592450901156
              ],
              [
                -73.82641642075748,
                40.797604890415464
              ],
              [
                -73.826457818813,
                40.797660837658896
              ],
              [
                -73.82647621310204,
                40.79768205528221
              ],
              [
                -73.82650832844257,
                40.79770845081929
              ],
              [
                -73.82654264563084,
                40.79773542779016
              ],
              [
                -73.82657894654045,
                40.797760490241515
              ],
              [
                -73.82661605440353,
                40.79778068963267
              ],
              [
                -73.82663395331255,
                40.79778722708738
              ],
              [
                -73.82665286668373,
                40.79779106522315
              ],
              [
                -73.82667174584928,
                40.79779474901365
              ],
              [
                -73.82669457238099,
                40.7977974665872
              ],
              [
                -73.82671545112177,
                40.79779804695671
              ],
              [
                -73.82673856166687,
                40.797796245452965
              ],
              [
                -73.82675807820914,
                40.79779177187656
              ],
              [
                -73.82677636279003,
                40.797786919681116
              ],
              [
                -73.82679270318214,
                40.79778244065561
              ],
              [
                -73.82681001426134,
                40.797778018034585
              ],
              [
                -73.82683076310852,
                40.79777327775432
              ],
              [
                -73.8268473410131,
                40.79776884464474
              ],
              [
                -73.82685100446659,
                40.797767868617655
              ],
              [
                -73.8268666088378,
                40.79776421739078
              ],
              [
                -73.8268684709334,
                40.7977637070579
              ],
              [
                -73.82688418235936,
                40.797759408169355
              ],
              [
                -73.82689978813906,
                40.79775487475238
              ],
              [
                -73.82691928311844,
                40.79774952881238
              ],
              [
                -73.82697931778351,
                40.79773814647965
              ],
              [
                -73.8270305591211,
                40.797718456914396
              ],
              [
                -73.82703818958812,
                40.79771446099343
              ],
              [
                -73.82706071893232,
                40.79770420204715
              ],
              [
                -73.82706744562513,
                40.79770078122262
              ],
              [
                -73.82707221507523,
                40.79769834837304
              ],
              [
                -73.82709623419142,
                40.79768494017031
              ],
              [
                -73.82711439259286,
                40.79767381877065
              ],
              [
                -73.82712912435535,
                40.79766373758642
              ],
              [
                -73.82714947039155,
                40.79764524449893
              ],
              [
                -73.82716326243056,
                40.79763209149887
              ],
              [
                -73.82717365601191,
                40.79762220131633
              ],
              [
                -73.8271821380805,
                40.797609525672776
              ],
              [
                -73.82718643262824,
                40.79759811342437
              ],
              [
                -73.82719101670101,
                40.79758522504255
              ],
              [
                -73.82719495057225,
                40.797571615494704
              ],
              [
                -73.82719705421918,
                40.797555752334056
              ],
              [
                -73.82719785571354,
                40.79753912213346
              ],
              [
                -73.8271961600784,
                40.797521198077405
              ],
              [
                -73.82719240016688,
                40.797508981694264
              ],
              [
                -73.8271866948234,
                40.79749711369461
              ],
              [
                -73.82718039545175,
                40.797486154968716
              ],
              [
                -73.82717235863593,
                40.797469474158376
              ],
              [
                -73.827161276906,
                40.797452005433705
              ],
              [
                -73.82714904596592,
                40.79743455475272
              ],
              [
                -73.82713648523875,
                40.79741634435145
              ],
              [
                -73.82712728725102,
                40.797399284697065
              ],
              [
                -73.82711969691147,
                40.797383685927755
              ],
              [
                -73.82711225531149,
                40.79736997857355
              ],
              [
                -73.8271015684704,
                40.797355463737546
              ],
              [
                -73.82708843152089,
                40.79734056812578
              ],
              [
                -73.82707441225828,
                40.79732715663516
              ],
              [
                -73.82705837493968,
                40.79731540765081
              ],
              [
                -73.82704654320895,
                40.797308672471516
              ],
              [
                -73.82703330269894,
                40.797301402614245
              ],
              [
                -73.82701214322078,
                40.79728633413084
              ],
              [
                -73.82699897185923,
                40.79727052728729
              ],
              [
                -73.8269934694796,
                40.797258111104554
              ],
              [
                -73.82699079751283,
                40.79724699363738
              ],
              [
                -73.82698554335774,
                40.797226094516255
              ],
              [
                -73.82698279311242,
                40.79721278117811
              ],
              [
                -73.82697978921613,
                40.79720130420573
              ],
              [
                -73.82697520517927,
                40.79718686335029
              ],
              [
                -73.82697204859538,
                40.79717518746346
              ],
              [
                -73.82697037969984,
                40.79716557621308
              ],
              [
                -73.82696958332555,
                40.79715265329329
              ],
              [
                -73.82697001638891,
                40.797140820295475
              ],
              [
                -73.82697091510649,
                40.797128277689595
              ],
              [
                -73.82697509656563,
                40.79711500223019
              ],
              [
                -73.82697613317659,
                40.797112761293356
              ],
              [
                -73.82697942175973,
                40.797105624963805
              ],
              [
                -73.82698435541113,
                40.79709479080585
              ],
              [
                -73.82698898205707,
                40.797083929047595
              ],
              [
                -73.82700062491989,
                40.79706297403751
              ],
              [
                -73.8270144299609,
                40.79704427347521
              ],
              [
                -73.82703059174072,
                40.79702667373822
              ],
              [
                -73.82704565175071,
                40.79701314454931
              ],
              [
                -73.82706244374643,
                40.79699911286831
              ],
              [
                -73.82707642035966,
                40.79698741762403
              ],
              [
                -73.82709508032535,
                40.79697475842694
              ],
              [
                -73.82711175491708,
                40.79696501269606
              ],
              [
                -73.8271274160478,
                40.7969569133887
              ],
              [
                -73.82714408833661,
                40.79694805101017
              ],
              [
                -73.82715779620057,
                40.7969396693373
              ],
              [
                -73.82717201225188,
                40.79693151408134
              ],
              [
                -73.82718839026421,
                40.79692637852444
              ],
              [
                -73.82720180343523,
                40.79692213997383
              ],
              [
                -73.8272074346915,
                40.79692083367755
              ],
              [
                -73.82721191019694,
                40.79692223637446
              ],
              [
                -73.82721950476653,
                40.79693152212381
              ],
              [
                -73.82722952070569,
                40.796944360021755
              ],
              [
                -73.82723696869755,
                40.79695551044121
              ],
              [
                -73.82724276836349,
                40.796962192669326
              ],
              [
                -73.82725849390543,
                40.79696630368565
              ],
              [
                -73.82727261992385,
                40.79696608278046
              ],
              [
                -73.8272790382898,
                40.79696311162145
              ],
              [
                -73.8272805093019,
                40.79696242931758
              ],
              [
                -73.82728314189592,
                40.79696120798269
              ],
              [
                -73.82728467788968,
                40.79695859018939
              ],
              [
                -73.82728748729232,
                40.7969537944657
              ],
              [
                -73.82728873655539,
                40.79695166189838
              ],
              [
                -73.8272889449065,
                40.796939838446335
              ],
              [
                -73.8272824009897,
                40.79692739340958
              ],
              [
                -73.82727725896208,
                40.79691813814352
              ],
              [
                -73.82727942289542,
                40.796905956939895
              ],
              [
                -73.82728527175662,
                40.79689418750659
              ],
              [
                -73.82729508449476,
                40.79688432341333
              ],
              [
                -73.8273053898948,
                40.796876846789566
              ],
              [
                -73.82731470177303,
                40.79686843221023
              ],
              [
                -73.82732794744197,
                40.796855079685535
              ],
              [
                -73.82733950746093,
                40.79684374096838
              ],
              [
                -73.82734790341695,
                40.79683642397996
              ],
              [
                -73.8273535426513,
                40.79683203756921
              ],
              [
                -73.82737218442077,
                40.79681743232667
              ],
              [
                -73.82739957276964,
                40.796797581058534
              ],
              [
                -73.82741768831188,
                40.796789018829
              ],
              [
                -73.82743330638438,
                40.7967844036054
              ],
              [
                -73.8274508283503,
                40.796780998919445
              ],
              [
                -73.82747038295471,
                40.79677539984456
              ],
              [
                -73.82748697197574,
                40.79677073279408
              ],
              [
                -73.82750500819391,
                40.79676597844844
              ],
              [
                -73.82752596323047,
                40.796763704825736
              ],
              [
                -73.82754735503714,
                40.796762422094474
              ],
              [
                -73.82756429760532,
                40.79675827773812
              ],
              [
                -73.82757380520766,
                40.796756833318476
              ],
              [
                -73.82759009008097,
                40.79675554293965
              ],
              [
                -73.82761877332662,
                40.79675692805161
              ],
              [
                -73.82763697115004,
                40.7967578642571
              ],
              [
                -73.82765520698014,
                40.7967579992433
              ],
              [
                -73.82767536606408,
                40.796757147697896
              ],
              [
                -73.82769859391973,
                40.7967559489332
              ],
              [
                -73.82772607638644,
                40.79675436836669
              ],
              [
                -73.82775695778432,
                40.79675331555155
              ],
              [
                -73.82781020270696,
                40.796751396359866
              ],
              [
                -73.82783938997113,
                40.79675070167381
              ],
              [
                -73.82787023505595,
                40.79675030647816
              ],
              [
                -73.82790028871491,
                40.79674864896262
              ],
              [
                -73.82793030431735,
                40.79674798346688
              ],
              [
                -73.82796201029873,
                40.79674926428172
              ],
              [
                -73.82799281466637,
                40.796751147000904
              ],
              [
                -73.82801769208149,
                40.796753101615735
              ],
              [
                -73.82803834286437,
                40.796754592073334
              ],
              [
                -73.82805654056614,
                40.79675549202701
              ],
              [
                -73.82808178967328,
                40.7967564123791
              ],
              [
                -73.82810125995778,
                40.79675578438318
              ],
              [
                -73.8281231471678,
                40.7967553218173
              ],
              [
                -73.8281445656651,
                40.79675266061725
              ],
              [
                -73.82816968869709,
                40.79674682765192
              ],
              [
                -73.82819115157852,
                40.796741231299535
              ],
              [
                -73.82821848353318,
                40.7967336449464
              ],
              [
                -73.82824143571027,
                40.79672445738756
              ],
              [
                -73.8282625192494,
                40.79671414227563
              ],
              [
                -73.8282809776311,
                40.79670131958413
              ],
              [
                -73.8282897295235,
                40.796693813586366
              ],
              [
                -73.82829961184392,
                40.796684850294454
              ],
              [
                -73.82832049419265,
                40.79666962671739
              ],
              [
                -73.8283310592047,
                40.796662032953336
              ],
              [
                -73.82835215055864,
                40.79664867390282
              ],
              [
                -73.82836830991194,
                40.796640818415256
              ],
              [
                -73.82838736715539,
                40.79663450763465
              ],
              [
                -73.8284024732032,
                40.79663068474958
              ],
              [
                -73.82842248760095,
                40.79662599569235
              ],
              [
                -73.82844200326554,
                40.79662168533399
              ],
              [
                -73.82845443980872,
                40.7966188761848
              ],
              [
                -73.82846831113262,
                40.79661566409342
              ],
              [
                -73.82848417580745,
                40.79661205860174
              ],
              [
                -73.8285005120372,
                40.796609038472496
              ],
              [
                -73.82851684884285,
                40.79660579436069
              ],
              [
                -73.82853317480047,
                40.79660222523138
              ],
              [
                -73.82857618436267,
                40.7965943826818
              ],
              [
                -73.82859349157125,
                40.7965915539572
              ],
              [
                -73.82860833140212,
                40.79658957728009
              ],
              [
                -73.82862365934415,
                40.79658732139212
              ],
              [
                -73.82863803621638,
                40.796585614241266
              ],
              [
                -73.82866675586334,
                40.796582171571494
              ],
              [
                -73.82868016371481,
                40.79658002204835
              ],
              [
                -73.82869454285871,
                40.79657760224124
              ],
              [
                -73.82870939560682,
                40.79657540058397
              ],
              [
                -73.82872716532218,
                40.796572815605785
              ],
              [
                -73.82874447314434,
                40.79656974361264
              ],
              [
                -73.82877370654465,
                40.79656478942915
              ],
              [
                -73.82879508820055,
                40.79656270501048
              ],
              [
                -73.82881553981144,
                40.79656268060484
              ],
              [
                -73.82883446085646,
                40.79656344748984
              ],
              [
                -73.8288582530758,
                40.79656408607646
              ],
              [
                -73.82888595450925,
                40.79656500939099
              ],
              [
                -73.82891120174483,
                40.79656611953234
              ],
              [
                -73.82892358442233,
                40.7965662454626
              ],
              [
                -73.82896378516148,
                40.79656806179086
              ],
              [
                -73.8289846857334,
                40.79656866808387
              ],
              [
                -73.82900267331648,
                40.79656845269594
              ],
              [
                -73.82902309341942,
                40.7965672215223
              ],
              [
                -73.82904158374792,
                40.796564718814324
              ],
              [
                -73.82905864191761,
                40.79656211413424
              ],
              [
                -73.82907780805219,
                40.79656452878604
              ],
              [
                -73.8290932565701,
                40.79657042328531
              ],
              [
                -73.82910266443133,
                40.79657543534991
              ],
              [
                -73.82912052352859,
                40.79658388214869
              ],
              [
                -73.82913307919159,
                40.7965901306904
              ],
              [
                -73.82914801503854,
                40.796597375345904
              ],
              [
                -73.82916539769322,
                40.79660654162239
              ],
              [
                -73.82918321758687,
                40.79661652757519
              ],
              [
                -73.82920390880294,
                40.79662948867257
              ],
              [
                -73.82921483560507,
                40.79663798834138
              ],
              [
                -73.82922415029546,
                40.79664667324893
              ],
              [
                -73.8292332415733,
                40.796655142722315
              ],
              [
                -73.82924388345312,
                40.796672971279484
              ],
              [
                -73.8292457107191,
                40.796681293792616
              ],
              [
                -73.82924388162897,
                40.79668726084507
              ],
              [
                -73.8292402495708,
                40.7966991517671
              ],
              [
                -73.82923739382846,
                40.79671279045723
              ],
              [
                -73.82923964136586,
                40.796723905568705
              ],
              [
                -73.829246256631,
                40.796736514272055
              ],
              [
                -73.82925144264567,
                40.796742644331914
              ],
              [
                -73.82926066599057,
                40.79674973610358
              ],
              [
                -73.8292707527818,
                40.79675812579862
              ],
              [
                -73.82929094280401,
                40.796772537576395
              ],
              [
                -73.8293003607649,
                40.79677792623625
              ],
              [
                -73.82932152634481,
                40.79679075349179
              ],
              [
                -73.82934623333284,
                40.79680426178348
              ],
              [
                -73.82936696838196,
                40.7968142614047
              ],
              [
                -73.82938338468514,
                40.796821201314614
              ],
              [
                -73.82939934401158,
                40.79682685328194
              ],
              [
                -73.82941825785156,
                40.796830807898026
              ],
              [
                -73.82943548347617,
                40.79683186869486
              ],
              [
                -73.82944790883194,
                40.796828950865624
              ],
              [
                -73.82945546638351,
                40.79682544100242
              ],
              [
                -73.82945583429438,
                40.79682527083851
              ],
              [
                -73.82945991534359,
                40.79682336759143
              ],
              [
                -73.82946637122622,
                40.79681457101128
              ],
              [
                -73.82946361428056,
                40.796804030432405
              ],
              [
                -73.82945737123772,
                40.79678954986281
              ],
              [
                -73.82944761505512,
                40.79677729770874
              ],
              [
                -73.82943556043782,
                40.79676948157161
              ],
              [
                -73.8294265150935,
                40.796761652296354
              ],
              [
                -73.82941556721865,
                40.79675232487442
              ],
              [
                -73.82940857757869,
                40.79674712694351
              ],
              [
                -73.82940234219689,
                40.7967432547505
              ],
              [
                -73.82939727945084,
                40.796740113362695
              ],
              [
                -73.82938545954309,
                40.796733099511044
              ],
              [
                -73.82937273079625,
                40.796724867911976
              ],
              [
                -73.82934864305284,
                40.796710154528434
              ],
              [
                -73.82933274065235,
                40.796700883422915
              ],
              [
                -73.82931538346615,
                40.796691050794045
              ],
              [
                -73.82930334030262,
                40.79668359416922
              ],
              [
                -73.82929449211723,
                40.79667299129231
              ],
              [
                -73.8292909774409,
                40.79666189189536
              ],
              [
                -73.82928856731736,
                40.796645148198316
              ],
              [
                -73.82928647195322,
                40.7966307374214
              ],
              [
                -73.82928333380296,
                40.79661593761409
              ],
              [
                -73.82928113630845,
                40.79659966246472
              ],
              [
                -73.82927990172637,
                40.796586728524716
              ],
              [
                -73.82927759304934,
                40.79657211874665
              ],
              [
                -73.82927444778716,
                40.7965600938165
              ],
              [
                -73.8292712558008,
                40.79654823785015
              ],
              [
                -73.82926604809887,
                40.79653655898751
              ],
              [
                -73.82926196332829,
                40.79652608009616
              ],
              [
                -73.8292606322496,
                40.79652266647284
              ],
              [
                -73.82925727015667,
                40.79651267447485
              ],
              [
                -73.82925200048692,
                40.796497483352866
              ],
              [
                -73.82924703839497,
                40.79648699495259
              ],
              [
                -73.82923352421639,
                40.79647543880834
              ],
              [
                -73.82922393581754,
                40.79646724803031
              ],
              [
                -73.82921580381061,
                40.796454610776216
              ],
              [
                -73.82920965518458,
                40.79644477714766
              ],
              [
                -73.82920448083085,
                40.79643419846976
              ],
              [
                -73.82919891573279,
                40.79642323976795
              ],
              [
                -73.82918736837662,
                40.79640247582674
              ],
              [
                -73.82917971956068,
                40.79638615523828
              ],
              [
                -73.82917114555754,
                40.796366143366875
              ],
              [
                -73.82916737925088,
                40.79635630338245
              ],
              [
                -73.82915588121533,
                40.796330072994465
              ],
              [
                -73.8291476483809,
                40.796315248041054
              ],
              [
                -73.82913875856414,
                40.796302600276555
              ],
              [
                -73.82913126948526,
                40.796293620543096
              ],
              [
                -73.82908611870918,
                40.79626734025606
              ],
              [
                -73.82906100849343,
                40.79625429083098
              ],
              [
                -73.82904883248631,
                40.796247960421574
              ],
              [
                -73.82904143606983,
                40.79624419353538
              ],
              [
                -73.82902261611012,
                40.796231568391306
              ],
              [
                -73.82900266573404,
                40.79622075033737
              ],
              [
                -73.8289799172474,
                40.79621090965887
              ],
              [
                -73.82895951707333,
                40.79620468397007
              ],
              [
                -73.82893972601295,
                40.79620101729228
              ],
              [
                -73.828930900435,
                40.796200193371924
              ],
              [
                -73.82892258328465,
                40.796200018528324
              ],
              [
                -73.82887840472593,
                40.79620137641179
              ],
              [
                -73.82885466653137,
                40.796203096664996
              ],
              [
                -73.82885169200794,
                40.796203065946976
              ],
              [
                -73.82884862349995,
                40.79620280826007
              ],
              [
                -73.82884211049873,
                40.796201574481984
              ],
              [
                -73.82883505366314,
                40.796199402256995
              ],
              [
                -73.82882623513585,
                40.796195922561346
              ],
              [
                -73.82882303973867,
                40.79619443236953
              ],
              [
                -73.82881996270321,
                40.796192690395365
              ],
              [
                -73.82881432169924,
                40.79618856541965
              ],
              [
                -73.82880962942951,
                40.79618376840102
              ],
              [
                -73.82880772835877,
                40.796181199258115
              ],
              [
                -73.82880615987096,
                40.79617856745212
              ],
              [
                -73.82878634962549,
                40.79614096864565
              ],
              [
                -73.82876324874735,
                40.79609717941176
              ],
              [
                -73.82875659178978,
                40.79608250000495
              ],
              [
                -73.82875464160588,
                40.7960760591119
              ],
              [
                -73.8287535319323,
                40.79606996959524
              ],
              [
                -73.82875327145067,
                40.796060513579825
              ],
              [
                -73.82875467221558,
                40.79605010597627
              ],
              [
                -73.82876332470153,
                40.796021888143386
              ],
              [
                -73.82878342625989,
                40.79597337253346
              ],
              [
                -73.82878736459838,
                40.79595786236098
              ],
              [
                -73.82878811739302,
                40.79595109177292
              ],
              [
                -73.82878808676787,
                40.79594474302249
              ],
              [
                -73.82878674776182,
                40.79593534035324
              ],
              [
                -73.82878406885135,
                40.79592631279303
              ],
              [
                -73.82878010953803,
                40.795917923947414
              ],
              [
                -73.82877495075456,
                40.79591033308548
              ],
              [
                -73.82877290726593,
                40.79590811620352
              ],
              [
                -73.82877045961781,
                40.795906022689735
              ],
              [
                -73.82876778660132,
                40.79590421765475
              ],
              [
                -73.82876504033172,
                40.79590278190396
              ],
              [
                -73.82876235352465,
                40.79590179638196
              ],
              [
                -73.82876105137812,
                40.79590146123973
              ],
              [
                -73.82875975973262,
                40.79590124304575
              ],
              [
                -73.82875850462355,
                40.795901132457175
              ],
              [
                -73.8287572831818,
                40.79590113064255
              ],
              [
                -73.82875611037336,
                40.79590124700556
              ],
              [
                -73.82875497212024,
                40.795901469798274
              ],
              [
                -73.82874087242854,
                40.79590594254772
              ],
              [
                -73.82872600720091,
                40.79591253904839
              ],
              [
                -73.82872444183316,
                40.79591341070165
              ],
              [
                -73.82872286299764,
                40.79591446175446
              ],
              [
                -73.82871437187114,
                40.795921517362764
              ],
              [
                -73.82871204621735,
                40.79592307239428
              ],
              [
                -73.82870973258431,
                40.79592423928765
              ],
              [
                -73.82870849922915,
                40.795924669502476
              ],
              [
                -73.82870721909322,
                40.79592494703225
              ],
              [
                -73.82870587897406,
                40.795925062475874
              ],
              [
                -73.82870449295862,
                40.795925024230776
              ],
              [
                -73.82870304740106,
                40.79592482390004
              ],
              [
                -73.82870155594443,
                40.79592447088571
              ],
              [
                -73.82869843051247,
                40.79592329590934
              ],
              [
                -73.8286951052269,
                40.79592148873051
              ],
              [
                -73.82869161684445,
                40.79591907151725
              ],
              [
                -73.82868433460277,
                40.795912504592756
              ],
              [
                -73.82866925976046,
                40.79589502186542
              ],
              [
                -73.82864862033784,
                40.79586677808698
              ],
              [
                -73.82863190221398,
                40.79584310570086
              ],
              [
                -73.82862725470012,
                40.79583472403551
              ],
              [
                -73.82862402869226,
                40.795826893966314
              ],
              [
                -73.82862189637105,
                40.7958175713651
              ],
              [
                -73.82862144922055,
                40.795807250473565
              ],
              [
                -73.82862557729027,
                40.795782014908724
              ],
              [
                -73.8286343147076,
                40.79575729128531
              ],
              [
                -73.82863657631914,
                40.79574909998143
              ],
              [
                -73.8286395783204,
                40.79572894227562
              ],
              [
                -73.82863931372879,
                40.79571218349166
              ],
              [
                -73.82863787466573,
                40.79570492331978
              ],
              [
                -73.82863534447925,
                40.79569790443742
              ],
              [
                -73.82863180743121,
                40.79569136234261
              ],
              [
                -73.82862735510265,
                40.79568542985156
              ],
              [
                -73.82862697694696,
                40.79568504280856
              ],
              [
                -73.82862545174703,
                40.79568349964344
              ],
              [
                -73.828623300956,
                40.79568169588396
              ],
              [
                -73.82862098271494,
                40.79568011702951
              ],
              [
                -73.82861855689164,
                40.795678798684406
              ],
              [
                -73.82861613028092,
                40.795677786071565
              ],
              [
                -73.82861369144146,
                40.79567707113294
              ],
              [
                -73.82861129852257,
                40.7956766717127
              ],
              [
                -73.82861011408166,
                40.795676598585786
              ],
              [
                -73.82860895260286,
                40.795676596858755
              ],
              [
                -73.82860453183888,
                40.79567698497475
              ],
              [
                -73.82859974191038,
                40.79567786054279
              ],
              [
                -73.8285808521636,
                40.7956832719974
              ],
              [
                -73.82857644262674,
                40.795684265062825
              ],
              [
                -73.82857242430367,
                40.79568485413638
              ],
              [
                -73.82855782430934,
                40.7956854532701
              ],
              [
                -73.82854017146602,
                40.79568434613912
              ],
              [
                -73.82852965393732,
                40.79568281757276
              ],
              [
                -73.82851832007442,
                40.79568019820653
              ],
              [
                -73.8284877636875,
                40.79567029254027
              ],
              [
                -73.82847351197225,
                40.79566448048679
              ],
              [
                -73.82845983360038,
                40.79565704931539
              ],
              [
                -73.82844701207814,
                40.79564816094263
              ],
              [
                -73.82843540215093,
                40.79563804791931
              ],
              [
                -73.82841279822466,
                40.795613368289025
              ],
              [
                -73.82838528126483,
                40.79557781095901
              ],
              [
                -73.82835875024011,
                40.79554135497714
              ],
              [
                -73.82834276079831,
                40.79551530661653
              ],
              [
                -73.82832651007614,
                40.795481081458206
              ],
              [
                -73.82831145744987,
                40.795441041269136
              ],
              [
                -73.82828572162478,
                40.795362884050505
              ],
              [
                -73.82826262833551,
                40.79529818471794
              ],
              [
                -73.82824392764013,
                40.7952395797946
              ],
              [
                -73.8282301195962,
                40.79520461930682
              ],
              [
                -73.82821393552643,
                40.79516247011635
              ],
              [
                -73.82820799674265,
                40.795149584098795
              ],
              [
                -73.82819916200813,
                40.795134171838825
              ],
              [
                -73.82817505476635,
                40.79509966489551
              ],
              [
                -73.82816011502915,
                40.79508060555218
              ],
              [
                -73.82814690119959,
                40.79506756545101
              ],
              [
                -73.8281427741178,
                40.795064442992185
              ],
              [
                -73.82813785302835,
                40.795061320856966
              ],
              [
                -73.8281159857097,
                40.79504977021587
              ],
              [
                -73.8281070896511,
                40.79504412039351
              ],
              [
                -73.82809360846584,
                40.79503363565327
              ],
              [
                -73.82808388029115,
                40.79502473375979
              ],
              [
                -73.8280522646091,
                40.794988809551064
              ],
              [
                -73.82802487159837,
                40.794951524292365
              ],
              [
                -73.82801372355921,
                40.794932408770045
              ],
              [
                -73.82800645038532,
                40.79491309967332
              ],
              [
                -73.82800565470578,
                40.79490905492801
              ],
              [
                -73.8280054641173,
                40.79490484037725
              ],
              [
                -73.82800683694197,
                40.794896324434575
              ],
              [
                -73.82800837665492,
                40.79489223760719
              ],
              [
                -73.82801043644797,
                40.79488837771489
              ],
              [
                -73.82801298038208,
                40.79488483315758
              ],
              [
                -73.82801595078091,
                40.79488165913233
              ],
              [
                -73.82802255294415,
                40.794876310854136
              ],
              [
                -73.82804185283631,
                40.79486360672115
              ],
              [
                -73.82804996174586,
                40.79485711766421
              ],
              [
                -73.82806416917738,
                40.794843379502915
              ],
              [
                -73.82806792658883,
                40.79483838749305
              ],
              [
                -73.82807035586674,
                40.79483357241907
              ],
              [
                -73.82807108549216,
                40.794831016568786
              ],
              [
                -73.82807142397816,
                40.79482832443963
              ],
              [
                -73.82807133624974,
                40.794825676738924
              ],
              [
                -73.82807084415114,
                40.79482323599893
              ],
              [
                -73.82806997268547,
                40.79482113661225
              ],
              [
                -73.8280694065837,
                40.79482020801278
              ],
              [
                -73.82806904143246,
                40.79481973923481
              ],
              [
                -73.82806875780551,
                40.794819369921065
              ],
              [
                -73.82806802525225,
                40.79481862116291
              ],
              [
                -73.82806720933907,
                40.79481797162295
              ],
              [
                -73.82806630987092,
                40.79481741141685
              ],
              [
                -73.82806533892197,
                40.79481695982811
              ],
              [
                -73.82806165747047,
                40.79481575653056
              ],
              [
                -73.82805741824836,
                40.79481492246426
              ],
              [
                -73.82804679082172,
                40.794814266496225
              ],
              [
                -73.82800604847579,
                40.79481653816272
              ],
              [
                -73.82799813388105,
                40.79481632934268
              ],
              [
                -73.82799146422416,
                40.79481538175049
              ],
              [
                -73.82798858771062,
                40.79481463967634
              ],
              [
                -73.82798601853261,
                40.79481370758458
              ],
              [
                -73.82798375778684,
                40.79481258765455
              ],
              [
                -73.82798181867787,
                40.794811288114936
              ],
              [
                -73.82797948961695,
                40.79480922223502
              ],
              [
                -73.82797743419616,
                40.79480686912258
              ],
              [
                -73.82797409504168,
                40.79480124517305
              ],
              [
                -73.82797171977278,
                40.79479437041037
              ],
              [
                -73.82796997734857,
                40.7947852009929
              ],
              [
                -73.82796673849063,
                40.794749978487914
              ],
              [
                -73.82796724490336,
                40.794710283788696
              ],
              [
                -73.82796950868526,
                40.79467337648528
              ],
              [
                -73.82796885705426,
                40.794632015693416
              ],
              [
                -73.82797293455938,
                40.794590075620945
              ],
              [
                -73.82797916037191,
                40.79455591075575
              ],
              [
                -73.82798037396266,
                40.79454051464601
              ],
              [
                -73.82797996235428,
                40.79452509684537
              ],
              [
                -73.8279776450364,
                40.79450970367483
              ],
              [
                -73.82797321529853,
                40.794495081988266
              ],
              [
                -73.82795977829309,
                40.79446789385134
              ],
              [
                -73.82793384220028,
                40.7944259913994
              ],
              [
                -73.82791556374134,
                40.794401235700484
              ],
              [
                -73.82789894642588,
                40.79437598844688
              ],
              [
                -73.82789413592968,
                40.79436643500177
              ],
              [
                -73.8278887132347,
                40.79435119148287
              ],
              [
                -73.82788722287647,
                40.794345398409924
              ],
              [
                -73.82788650586653,
                40.79433889618451
              ],
              [
                -73.82788720393613,
                40.794320580512036
              ],
              [
                -73.82789394577956,
                40.794247712879944
              ],
              [
                -73.82790638647181,
                40.79415181014585
              ],
              [
                -73.82791783454614,
                40.7940821641805
              ],
              [
                -73.82792031712073,
                40.7940564678279
              ],
              [
                -73.82792022633377,
                40.79403709757745
              ],
              [
                -73.82791705183374,
                40.79401332953896
              ],
              [
                -73.82790968658152,
                40.79397972016699
              ],
              [
                -73.82789752025388,
                40.79392823822285
              ],
              [
                -73.82788750813612,
                40.79389588703249
              ],
              [
                -73.82786390498843,
                40.793831960977094
              ],
              [
                -73.82782926370653,
                40.79373849979562
              ],
              [
                -73.82782028396574,
                40.79370553699783
              ],
              [
                -73.82781379322529,
                40.79365915109742
              ],
              [
                -73.82780452240922,
                40.793573996183966
              ],
              [
                -73.82779720735266,
                40.793497928084285
              ],
              [
                -73.82778457368785,
                40.79342078992601
              ],
              [
                -73.82778378411446,
                40.79339207155309
              ],
              [
                -73.82778571374462,
                40.79336037663223
              ],
              [
                -73.82779277842641,
                40.79329538919933
              ],
              [
                -73.82780227405307,
                40.79322492179026
              ],
              [
                -73.82780895581442,
                40.79319734939528
              ],
              [
                -73.82782132489062,
                40.793161410736445
              ],
              [
                -73.8278487557192,
                40.79308767321418
              ],
              [
                -73.82787507470991,
                40.79301766246832
              ],
              [
                -73.82789820942337,
                40.79295580544549
              ],
              [
                -73.82792932227444,
                40.792878507455086
              ],
              [
                -73.82795357705758,
                40.792827926883305
              ],
              [
                -73.82798633399265,
                40.792770064606145
              ],
              [
                -73.8280040387698,
                40.79274607459317
              ],
              [
                -73.82802835772806,
                40.792721211755
              ],
              [
                -73.82803976792373,
                40.79271165621282
              ],
              [
                -73.82805258813137,
                40.79270263533602
              ],
              [
                -73.82808189662184,
                40.79268648840044
              ],
              [
                -73.82814646574899,
                40.792661198570514
              ],
              [
                -73.82817584627136,
                40.79264923918227
              ],
              [
                -73.82819476870971,
                40.79263989300582
              ],
              [
                -73.82820348065087,
                40.79263416942087
              ],
              [
                -73.82821200657723,
                40.79262689762364
              ],
              [
                -73.82823426229459,
                40.792602346781884
              ],
              [
                -73.82825526169445,
                40.792578000957484
              ],
              [
                -73.82826111144131,
                40.79257020265734
              ],
              [
                -73.82827013504226,
                40.79255815896752
              ],
              [
                -73.828283928156,
                40.7925348651814
              ],
              [
                -73.82828845797178,
                40.792524335422684
              ],
              [
                -73.82829155299557,
                40.7925142459606
              ],
              [
                -73.8284613034007,
                40.79260798825906
              ],
              [
                -73.82919758430083,
                40.793014582336774
              ],
              [
                -73.82921203169037,
                40.7930224575009
              ],
              [
                -73.82922857533939,
                40.79301934041088
              ],
              [
                -73.82930732843701,
                40.79293620434295
              ],
              [
                -73.8293032551809,
                40.79291263536865
              ],
              [
                -73.8292785099931,
                40.792890607834586
              ],
              [
                -73.828605761492,
                40.79252530005948
              ],
              [
                -73.82836657648583,
                40.79239541955735
              ],
              [
                -73.828409846356,
                40.79238217476346
              ],
              [
                -73.82846554640045,
                40.79236853399551
              ],
              [
                -73.82848447116561,
                40.79236294369336
              ],
              [
                -73.82850117052847,
                40.79235623184516
              ],
              [
                -73.8285183028931,
                40.79234743193242
              ],
              [
                -73.8285362694082,
                40.792336382554986
              ],
              [
                -73.82855854927205,
                40.79232094523281
              ],
              [
                -73.82863315730178,
                40.79226665718169
              ],
              [
                -73.82872574289628,
                40.792197293721806
              ],
              [
                -73.8288276159301,
                40.792119525328076
              ],
              [
                -73.82889011027119,
                40.79206686589296
              ],
              [
                -73.82896119308748,
                40.792010285505796
              ],
              [
                -73.82905874229748,
                40.791936407967725
              ],
              [
                -73.82909089929302,
                40.7919132681345
              ],
              [
                -73.8291183969597,
                40.79189578491469
              ],
              [
                -73.82914479588072,
                40.791881704007466
              ],
              [
                -73.8291706896936,
                40.79187075641018
              ],
              [
                -73.82929535710204,
                40.791825483779796
              ],
              [
                -73.82943290570364,
                40.791775052538505
              ],
              [
                -73.82948296063849,
                40.79175392023617
              ],
              [
                -73.82956542120624,
                40.79171409644761
              ],
              [
                -73.82968966519786,
                40.79165343204063
              ],
              [
                -73.82974037698273,
                40.791630426993144
              ],
              [
                -73.82977794576149,
                40.79161724580165
              ],
              [
                -73.82988506125005,
                40.791587597164956
              ],
              [
                -73.82997422391135,
                40.791563432860954
              ],
              [
                -73.83000804438622,
                40.791556521565695
              ],
              [
                -73.8300521107447,
                40.79155144452654
              ],
              [
                -73.83007470930406,
                40.79154985737328
              ],
              [
                -73.83009369185004,
                40.791549552150094
              ],
              [
                -73.8301107627126,
                40.79155052316211
              ],
              [
                -73.83012625490682,
                40.791552788491316
              ],
              [
                -73.83015912679087,
                40.79156042717525
              ],
              [
                -73.83019817362353,
                40.79157169701324
              ],
              [
                -73.83028200474374,
                40.79159855636367
              ],
              [
                -73.8302865399306,
                40.79159977843827
              ],
              [
                -73.83038005411207,
                40.79162499531639
              ],
              [
                -73.83046245286289,
                40.79164630532702
              ],
              [
                -73.83049016506872,
                40.791651811937335
              ],
              [
                -73.83052501206322,
                40.791655978107556
              ],
              [
                -73.83056779313932,
                40.791657923320294
              ],
              [
                -73.83061230109162,
                40.79165585446683
              ],
              [
                -73.83065972132633,
                40.79165021454856
              ],
              [
                -73.83070547729544,
                40.79164199713385
              ],
              [
                -73.83078035632461,
                40.79162440401887
              ],
              [
                -73.83088186308554,
                40.79159581721124
              ],
              [
                -73.8309626970729,
                40.79157601668993
              ],
              [
                -73.83099216040134,
                40.79156786522635
              ],
              [
                -73.83103001627165,
                40.791554098379464
              ],
              [
                -73.8310761015808,
                40.79153296877975
              ],
              [
                -73.83115486096933,
                40.791494245102456
              ],
              [
                -73.8312331137428,
                40.79145464832484
              ],
              [
                -73.8312710216949,
                40.79143428901628
              ],
              [
                -73.83131810478132,
                40.791407037392815
              ],
              [
                -73.83134155443767,
                40.79139269803261
              ],
              [
                -73.83135904834073,
                40.791380675373155
              ],
              [
                -73.8313735338278,
                40.79136894466195
              ],
              [
                -73.83138529261699,
                40.79135723860686
              ],
              [
                -73.83143773019351,
                40.79129791700838
              ],
              [
                -73.83148597421784,
                40.791243092839125
              ],
              [
                -73.8315088724828,
                40.791221532964684
              ],
              [
                -73.83155495695918,
                40.79118638096244
              ],
              [
                -73.83163531132125,
                40.791126030754974
              ],
              [
                -73.83166080927866,
                40.79110259115967
              ],
              [
                -73.83169117649335,
                40.791069847943795
              ],
              [
                -73.83172443576608,
                40.791031985681045
              ],
              [
                -73.83174331571016,
                40.791006106281586
              ],
              [
                -73.83175371702877,
                40.79098820161013
              ],
              [
                -73.83175692999276,
                40.79098266740851
              ],
              [
                -73.8317704456496,
                40.79095586129706
              ],
              [
                -73.83179395262196,
                40.79090399088055
              ],
              [
                -73.83180369612916,
                40.79087912406203
              ],
              [
                -73.83180861574978,
                40.79085930276894
              ],
              [
                -73.83180901533764,
                40.79085608870737
              ],
              [
                -73.83181353814662,
                40.790819534901765
              ],
              [
                -73.83181918328192,
                40.790752113164835
              ],
              [
                -73.83182483351099,
                40.790672984592376
              ],
              [
                -73.83183057998171,
                40.79063978291101
              ],
              [
                -73.8318419080428,
                40.79060208612256
              ],
              [
                -73.83185670648172,
                40.79056470079969
              ],
              [
                -73.83187554905086,
                40.79052553019267
              ],
              [
                -73.83189574731281,
                40.79048918804797
              ],
              [
                -73.83191507342448,
                40.79046023785869
              ],
              [
                -73.83195529683569,
                40.79041409216991
              ],
              [
                -73.8319663976777,
                40.79040000589913
              ],
              [
                -73.83201384400635,
                40.7903322581006
              ],
              [
                -73.83205224652362,
                40.79027496174553
              ],
              [
                -73.83205476749029,
                40.79027119190935
              ],
              [
                -73.83207110810395,
                40.79024250739354
              ],
              [
                -73.83209261415041,
                40.790199693434374
              ],
              [
                -73.83212057206087,
                40.79014000431848
              ],
              [
                -73.83213206576016,
                40.79011125895777
              ],
              [
                -73.83214207124183,
                40.79008091675398
              ],
              [
                -73.83214820430491,
                40.79005445985478
              ],
              [
                -73.8321491450598,
                40.79004344056605
              ],
              [
                -73.8321487200091,
                40.79003324485319
              ],
              [
                -73.8321411593998,
                40.78998194191762
              ],
              [
                -73.83213625756267,
                40.78996181748948
              ],
              [
                -73.83211841977344,
                40.78989416334256
              ],
              [
                -73.83210275748144,
                40.789842091554426
              ],
              [
                -73.83209381087113,
                40.789819134240446
              ],
              [
                -73.83206986421519,
                40.789767851819555
              ],
              [
                -73.83204000858483,
                40.78969684806643
              ],
              [
                -73.83203429590175,
                40.789683269177225
              ],
              [
                -73.83201458258128,
                40.78964058344902
              ],
              [
                -73.83199969267065,
                40.78960610880295
              ],
              [
                -73.83199196951847,
                40.78958181088227
              ],
              [
                -73.83198921420795,
                40.789561149473634
              ],
              [
                -73.8319896071385,
                40.78953680927289
              ],
              [
                -73.83198967695232,
                40.78953290184421
              ],
              [
                -73.8319923460442,
                40.78951243714399
              ],
              [
                -73.83199270968751,
                40.78950485564094
              ],
              [
                -73.83199053527478,
                40.78948843564992
              ],
              [
                -73.83198460174005,
                40.78946875164195
              ],
              [
                -73.83197646026684,
                40.789450476821436
              ],
              [
                -73.83196548578324,
                40.78943290164429
              ],
              [
                -73.83195118463726,
                40.78941477213221
              ],
              [
                -73.83193340194333,
                40.789395277234945
              ],
              [
                -73.83191627640754,
                40.78937862904752
              ],
              [
                -73.83189837711858,
                40.78936333015152
              ],
              [
                -73.83189154048269,
                40.78935834886287
              ],
              [
                -73.8318836035023,
                40.789353465981264
              ],
              [
                -73.83186317859901,
                40.78934326906808
              ],
              [
                -73.83183317302232,
                40.78933214097066
              ],
              [
                -73.83180053881553,
                40.78932457330036
              ],
              [
                -73.83171973215707,
                40.789316080579624
              ],
              [
                -73.83168820514707,
                40.78931111817426
              ],
              [
                -73.83167776142388,
                40.78930875237277
              ],
              [
                -73.83166863318911,
                40.789306020103254
              ],
              [
                -73.83159563330902,
                40.78927964580881
              ],
              [
                -73.83157593804363,
                40.78927162924895
              ],
              [
                -73.83149585179967,
                40.78924110213386
              ],
              [
                -73.8314871408735,
                40.789237307176236
              ],
              [
                -73.83147868152108,
                40.78923278468775
              ],
              [
                -73.83146277309501,
                40.789221693522826
              ],
              [
                -73.83144863809257,
                40.789208183034084
              ],
              [
                -73.8314369940182,
                40.78919292939909
              ],
              [
                -73.83143628604451,
                40.78919162300548
              ],
              [
                -73.83143576810112,
                40.7891902168771
              ],
              [
                -73.83143531293646,
                40.78918743361404
              ],
              [
                -73.83143548738316,
                40.78918443499641
              ],
              [
                -73.83143628889387,
                40.789181356548596
              ],
              [
                -73.83143767101389,
                40.78917835148847
              ],
              [
                -73.83143957313953,
                40.78917558976566
              ],
              [
                -73.83144187749328,
                40.78917318814078
              ],
              [
                -73.8314445011178,
                40.78917126577036
              ],
              [
                -73.83144590068468,
                40.78917051076974
              ],
              [
                -73.83144734754092,
                40.78916988215248
              ],
              [
                -73.83145205188637,
                40.78917919205464
              ],
              [
                -73.83145804844237,
                40.78918818822838
              ],
              [
                -73.83146520678658,
                40.78919667163012
              ],
              [
                -73.8314733739143,
                40.78920448255158
              ],
              [
                -73.8314980541266,
                40.78922301428249
              ],
              [
                -73.83152669521067,
                40.78923963547457
              ],
              [
                -73.83155137266961,
                40.78925020638138
              ],
              [
                -73.83156328224034,
                40.78925375405068
              ],
              [
                -73.83157493485916,
                40.78925614860075
              ],
              [
                -73.83161005472476,
                40.789259847114046
              ],
              [
                -73.83162642848136,
                40.7892601312027
              ],
              [
                -73.83164202240388,
                40.789259370633935
              ],
              [
                -73.83166578492866,
                40.78925643444713
              ],
              [
                -73.83168931605502,
                40.78925165128461
              ],
              [
                -73.8317185418299,
                40.78924347181294
              ],
              [
                -73.83174255613113,
                40.78923440253687
              ],
              [
                -73.83175433638331,
                40.789228594372375
              ],
              [
                -73.8317646012586,
                40.78922226952416
              ],
              [
                -73.83177319645581,
                40.78921551906871
              ],
              [
                -73.83178006235026,
                40.78920838781622
              ],
              [
                -73.83179240131156,
                40.78919212520118
              ],
              [
                -73.83180112232989,
                40.78917762151521
              ],
              [
                -73.83181680664136,
                40.789145695125974
              ],
              [
                -73.83183792154614,
                40.78910774205707
              ],
              [
                -73.83186571165793,
                40.789058372985224
              ],
              [
                -73.83190176756774,
                40.78899169718288
              ],
              [
                -73.8319189243068,
                40.788962942507844
              ],
              [
                -73.83193642535558,
                40.78893888842596
              ],
              [
                -73.83195418739795,
                40.788919535988036
              ],
              [
                -73.83198201145302,
                40.78889385838392
              ],
              [
                -73.83205805125756,
                40.78882856543776
              ],
              [
                -73.83206704304985,
                40.78881948358381
              ],
              [
                -73.83208628075752,
                40.788797485033406
              ],
              [
                -73.8320967198753,
                40.78878766700122
              ],
              [
                -73.83211813285888,
                40.78877203892129
              ],
              [
                -73.83214084292744,
                40.78875905041471
              ],
              [
                -73.83216100632328,
                40.7887500831612
              ],
              [
                -73.8321831471977,
                40.78874228124257
              ],
              [
                -73.83219614055196,
                40.78873880725586
              ],
              [
                -73.83223198697084,
                40.788731537574776
              ],
              [
                -73.83224825358656,
                40.788727230049524
              ],
              [
                -73.83228544136185,
                40.7887143889003
              ],
              [
                -73.83232539607748,
                40.78869879597109
              ],
              [
                -73.83240767132301,
                40.78866474145495
              ],
              [
                -73.83248459394099,
                40.78863375872056
              ],
              [
                -73.83251063648018,
                40.788624476626765
              ],
              [
                -73.83253872405466,
                40.788616403849254
              ],
              [
                -73.83256885862595,
                40.788609549603336
              ],
              [
                -73.83260106426339,
                40.78860389666713
              ],
              [
                -73.8326597673408,
                40.78859588658422
              ],
              [
                -73.83272615785674,
                40.788588544161385
              ],
              [
                -73.83276866011296,
                40.78858683224852
              ],
              [
                -73.83277829537045,
                40.78858582901447
              ],
              [
                -73.83278675905991,
                40.788584238913394
              ],
              [
                -73.83279684900029,
                40.78858131666342
              ],
              [
                -73.83280758193703,
                40.788577145438
              ],
              [
                -73.83284200543767,
                40.788560392065534
              ],
              [
                -73.83285624257194,
                40.78855285698122
              ],
              [
                -73.83289140098701,
                40.78853159286456
              ],
              [
                -73.83290227987413,
                40.7885262597178
              ],
              [
                -73.83291266867468,
                40.78852228616637
              ],
              [
                -73.83292072972804,
                40.7885199653958
              ],
              [
                -73.8329293717141,
                40.78851811420316
              ],
              [
                -73.83294877370874,
                40.78851570114621
              ],
              [
                -73.83296901193475,
                40.78851487576385
              ],
              [
                -73.83301120153718,
                40.78851485514789
              ],
              [
                -73.8330617942644,
                40.78851436136726
              ],
              [
                -73.8330984850652,
                40.788515279105695
              ],
              [
                -73.83311955649597,
                40.78851742579978
              ],
              [
                -73.83316390709939,
                40.788525207068936
              ],
              [
                -73.83318342623784,
                40.78852767600099
              ],
              [
                -73.83321371725937,
                40.78852968290374
              ],
              [
                -73.83323709224194,
                40.78852971672844
              ],
              [
                -73.83325759535903,
                40.788527765740206
              ],
              [
                -73.83326688772705,
                40.78852602251193
              ],
              [
                -73.83327559007824,
                40.78852373899743
              ],
              [
                -73.83330930201302,
                40.788512414123275
              ],
              [
                -73.83335417722043,
                40.78849500980026
              ],
              [
                -73.83340396992567,
                40.788473072796116
              ],
              [
                -73.83342600487724,
                40.78846478482935
              ],
              [
                -73.8334409313363,
                40.78846079031425
              ],
              [
                -73.83345664820672,
                40.78845820113765
              ],
              [
                -73.83347264458874,
                40.78845709831349
              ],
              [
                -73.83348846083359,
                40.78845751753059
              ],
              [
                -73.83350311185124,
                40.78845948416995
              ],
              [
                -73.83351863585291,
                40.78846324325046
              ],
              [
                -73.83353782460622,
                40.788469583325856
              ],
              [
                -73.83358017726817,
                40.78848552992224
              ],
              [
                -73.83359974634502,
                40.78849165426109
              ],
              [
                -73.83362430603371,
                40.788496804771995
              ],
              [
                -73.83365829867995,
                40.788501103791305
              ],
              [
                -73.83373021417556,
                40.78850626013134
              ],
              [
                -73.8337544480719,
                40.788509042004875
              ],
              [
                -73.83377597877146,
                40.7885126380013
              ],
              [
                -73.83379548109895,
                40.78851716904366
              ],
              [
                -73.83381316764698,
                40.78852267799083
              ],
              [
                -73.83382786803367,
                40.78852889461294
              ],
              [
                -73.83384261055957,
                40.78853703917674
              ],
              [
                -73.83385767920349,
                40.78854725432057
              ],
              [
                -73.83387417482535,
                40.788560289799754
              ],
              [
                -73.83390129515621,
                40.78858379635434
              ],
              [
                -73.83391732792282,
                40.78860160479767
              ],
              [
                -73.8339602331381,
                40.788661909770376
              ],
              [
                -73.83399530269571,
                40.7887144413171
              ],
              [
                -73.83400091286155,
                40.788725858717456
              ],
              [
                -73.83400506304766,
                40.78873885059656
              ],
              [
                -73.83401007226306,
                40.788773472753945
              ],
              [
                -73.83401331908667,
                40.78883504520462
              ],
              [
                -73.8340119530433,
                40.788902643434355
              ],
              [
                -73.83401305866653,
                40.7889107676647
              ],
              [
                -73.83401535853562,
                40.78891940708046
              ],
              [
                -73.83402388778488,
                40.78893935656515
              ],
              [
                -73.83403866408011,
                40.78896671648607
              ],
              [
                -73.83405560943376,
                40.788994630184064
              ],
              [
                -73.83407004851237,
                40.789014740789796
              ],
              [
                -73.83408663721427,
                40.789033297171855
              ],
              [
                -73.83409441601134,
                40.789040233870864
              ],
              [
                -73.83410403110443,
                40.78904725278701
              ],
              [
                -73.83413566521263,
                40.789066136551355
              ],
              [
                -73.83414953337565,
                40.78907336093968
              ],
              [
                -73.83416564274562,
                40.78907995714785
              ],
              [
                -73.83420808568339,
                40.78909261712729
              ],
              [
                -73.83424292462092,
                40.78910010503191
              ],
              [
                -73.83428255364467,
                40.78910581717561
              ],
              [
                -73.83432302797362,
                40.78910966696789
              ],
              [
                -73.83440941982468,
                40.789115851524414
              ],
              [
                -73.83457184016791,
                40.789127476498
              ],
              [
                -73.83477833485269,
                40.789138920182126
              ],
              [
                -73.83478381869124,
                40.78913931502713
              ],
              [
                -73.83483050344489,
                40.78914274099314
              ],
              [
                -73.83497802698217,
                40.78915567587134
              ],
              [
                -73.83512500940951,
                40.78917174468976
              ],
              [
                -73.83515382566965,
                40.789175657589254
              ],
              [
                -73.83518580449777,
                40.78917999884348
              ],
              [
                -73.83523905080068,
                40.78918932422048
              ],
              [
                -73.83528735229477,
                40.7892001718458
              ],
              [
                -73.83533150242093,
                40.78921274271692
              ],
              [
                -73.83536640185693,
                40.78922445385129
              ],
              [
                -73.83539490470189,
                40.789235418057515
              ],
              [
                -73.83541948454024,
                40.78924658304619
              ],
              [
                -73.83544041606656,
                40.789258103502654
              ],
              [
                -73.83545814552562,
                40.789270285800015
              ],
              [
                -73.8354724855882,
                40.78928303032971
              ],
              [
                -73.83548346034148,
                40.78929640078708
              ],
              [
                -73.83549110346662,
                40.7893104136386
              ],
              [
                -73.83549451291069,
                40.78932109841778
              ],
              [
                -73.83549634275938,
                40.78933315967643
              ],
              [
                -73.83549597285105,
                40.78938206484244
              ],
              [
                -73.83549690623326,
                40.789393069913835
              ],
              [
                -73.83549889589764,
                40.7894028071528
              ],
              [
                -73.83550928485678,
                40.78943730189322
              ],
              [
                -73.83552040718325,
                40.78946732324287
              ],
              [
                -73.83553285102528,
                40.789494445774736
              ],
              [
                -73.83554685397552,
                40.78951912231342
              ],
              [
                -73.83555067957171,
                40.78952454805664
              ],
              [
                -73.83555521539449,
                40.78952977780326
              ],
              [
                -73.83556638760149,
                40.789539617768334
              ],
              [
                -73.83558026327755,
                40.78954854354983
              ],
              [
                -73.83559665355094,
                40.789556455869935
              ],
              [
                -73.83561551147777,
                40.78956326335916
              ],
              [
                -73.8356361852034,
                40.78956873138879
              ],
              [
                -73.83565808161073,
                40.789572670981116
              ],
              [
                -73.83568022069021,
                40.789574917568295
              ],
              [
                -73.83573311865497,
                40.78957656955579
              ],
              [
                -73.83578722039788,
                40.789575061862074
              ],
              [
                -73.83584206544015,
                40.78957040254113
              ],
              [
                -73.83589707105418,
                40.789562655761884
              ],
              [
                -73.83601155152638,
                40.789541926781446
              ],
              [
                -73.83613203529262,
                40.78951739770043
              ],
              [
                -73.83617393310658,
                40.78950677751861
              ],
              [
                -73.8362546207189,
                40.789481993552066
              ],
              [
                -73.8362883747538,
                40.78947263093072
              ],
              [
                -73.83632428085821,
                40.789464909921705
              ],
              [
                -73.8363573014982,
                40.7894606793846
              ],
              [
                -73.83637809572132,
                40.78946017868963
              ],
              [
                -73.83640120618942,
                40.78946187636406
              ],
              [
                -73.83647469417721,
                40.78947389506173
              ],
              [
                -73.83650023157024,
                40.789476975228794
              ],
              [
                -73.83655947459667,
                40.7894809666191
              ],
              [
                -73.83670469134006,
                40.789487836117225
              ],
              [
                -73.83677086474431,
                40.789492090348666
              ],
              [
                -73.83680302130759,
                40.78949641381511
              ],
              [
                -73.83683725484383,
                40.7895045219314
              ],
              [
                -73.83687511481787,
                40.78951671340881
              ],
              [
                -73.83695332278464,
                40.7895461723474
              ],
              [
                -73.8369875229482,
                40.78955784581486
              ],
              [
                -73.83702441777633,
                40.78956769554796
              ],
              [
                -73.83705851436486,
                40.789573335923095
              ],
              [
                -73.83706743412114,
                40.7895739968566
              ],
              [
                -73.83707698362737,
                40.78957410969868
              ],
              [
                -73.83709699937467,
                40.789572705314086
              ],
              [
                -73.83711662821919,
                40.78956925757452
              ],
              [
                -73.83713432989512,
                40.789564033014294
              ],
              [
                -73.83714862250145,
                40.78955758773501
              ],
              [
                -73.83715450863986,
                40.78955393126615
              ],
              [
                -73.83715950535763,
                40.78955001990688
              ],
              [
                -73.83716356735177,
                40.789545902008236
              ],
              [
                -73.83716668296842,
                40.78954156582692
              ],
              [
                -73.83716883843061,
                40.78953705707807
              ],
              [
                -73.8371700220552,
                40.78953237624781
              ],
              [
                -73.83717067065936,
                40.789514655489356
              ],
              [
                -73.83716755539365,
                40.78949487638945
              ],
              [
                -73.83716208040494,
                40.78947606610536
              ],
              [
                -73.83714658267745,
                40.78943218991013
              ],
              [
                -73.8371412213885,
                40.78941087544821
              ],
              [
                -73.83713962153668,
                40.789391441078024
              ],
              [
                -73.83714035500097,
                40.78938246359029
              ],
              [
                -73.83714210587829,
                40.7893738579388
              ],
              [
                -73.83716311195373,
                40.7893012350331
              ],
              [
                -73.83718350813176,
                40.789245540709
              ],
              [
                -73.83724431967146,
                40.78910221194871
              ],
              [
                -73.83726600112918,
                40.789039234915805
              ],
              [
                -73.83727758270638,
                40.78901250600304
              ],
              [
                -73.83727858238785,
                40.78901075191565
              ],
              [
                -73.8372790108047,
                40.78901014926153
              ],
              [
                -73.83727940241451,
                40.78900972597511
              ],
              [
                -73.83727958041864,
                40.7890095829922
              ],
              [
                -73.83727966298792,
                40.78900952816039
              ],
              [
                -73.8372797461712,
                40.78900949259492
              ],
              [
                -73.83727981701145,
                40.78900945701215
              ],
              [
                -73.8372799001519,
                40.78900943903671
              ],
              [
                -73.83727997092765,
                40.789009429755176
              ],
              [
                -73.8372800429803,
                40.78900943923836
              ],
              [
                -73.83728010158883,
                40.78900944819991
              ],
              [
                -73.83728017293761,
                40.78900947510484
              ],
              [
                -73.83728024446076,
                40.78900952077265
              ],
              [
                -73.83728029129763,
                40.7890095664057
              ],
              [
                -73.83728035021717,
                40.78900962847329
              ],
              [
                -73.83728039786988,
                40.78900970107915
              ],
              [
                -73.83728049134774,
                40.78900987242201
              ],
              [
                -73.83728065697098,
                40.78901037606973
              ],
              [
                -73.83728076133985,
                40.78901105151202
              ],
              [
                -73.83728085145214,
                40.78901296075894
              ],
              [
                -73.8372804584517,
                40.78901891305222
              ],
              [
                -73.83727225675233,
                40.789063664765806
              ],
              [
                -73.83726072517644,
                40.78910392862914
              ],
              [
                -73.83724215608919,
                40.78916027102371
              ],
              [
                -73.83723934588721,
                40.789168797113966
              ],
              [
                -73.83722674841863,
                40.7892038911416
              ],
              [
                -73.83718766733921,
                40.78930360218019
              ],
              [
                -73.8371791828275,
                40.78933318003952
              ],
              [
                -73.83717427271856,
                40.78936396192733
              ],
              [
                -73.8371730234024,
                40.7893955043588
              ],
              [
                -73.837175445348,
                40.789427314321536
              ],
              [
                -73.83717640705441,
                40.78943153815802
              ],
              [
                -73.83717815048219,
                40.789435710998454
              ],
              [
                -73.8371806869702,
                40.78943988328401
              ],
              [
                -73.83718405411784,
                40.78944409242582
              ],
              [
                -73.83719323890342,
                40.789452732623914
              ],
              [
                -73.83722467003669,
                40.78947763058566
              ],
              [
                -73.83722878188219,
                40.78948205872851
              ],
              [
                -73.83723199401555,
                40.78948638525277
              ],
              [
                -73.83723436552562,
                40.78949069333481
              ],
              [
                -73.83723591790589,
                40.789495026728886
              ],
              [
                -73.83723862933162,
                40.78951068161876
              ],
              [
                -73.83723892974659,
                40.78952835865341
              ],
              [
                -73.83723210980034,
                40.78958462985101
              ],
              [
                -73.83723098502493,
                40.78960337753789
              ],
              [
                -73.83723208215262,
                40.789619920545405
              ],
              [
                -73.83723554145408,
                40.78963476566826
              ],
              [
                -73.83724908465298,
                40.78967763946595
              ],
              [
                -73.8372589211474,
                40.78970129152464
              ],
              [
                -73.83727167793585,
                40.78972188684896
              ],
              [
                -73.83727913342925,
                40.78973059546136
              ],
              [
                -73.83728739529073,
                40.78973836974985
              ],
              [
                -73.8373396629284,
                40.78977993906626
              ],
              [
                -73.83734725697254,
                40.789784902336976
              ],
              [
                -73.8373695962358,
                40.789797469116465
              ],
              [
                -73.83737555701477,
                40.789801880597295
              ],
              [
                -73.83738008413603,
                40.789806407657814
              ],
              [
                -73.83738447564578,
                40.789812852357485
              ],
              [
                -73.83738742040757,
                40.78981989894496
              ],
              [
                -73.83738894139299,
                40.78982761764552
              ],
              [
                -73.83738906354782,
                40.78983617317226
              ],
              [
                -73.83738634556121,
                40.78985242315374
              ],
              [
                -73.83737506283465,
                40.789892479810796
              ],
              [
                -73.83737352065216,
                40.78990291362907
              ],
              [
                -73.8373734632574,
                40.789912152908606
              ],
              [
                -73.83737790012118,
                40.78996780155506
              ],
              [
                -73.8373822323697,
                40.79000383668329
              ],
              [
                -73.83739734462176,
                40.79010277772621
              ],
              [
                -73.83741000655037,
                40.79018212120484
              ],
              [
                -73.8374107295372,
                40.79019691806674
              ],
              [
                -73.83740848436261,
                40.79021360997666
              ],
              [
                -73.83739143934415,
                40.79027011050179
              ],
              [
                -73.83738726435584,
                40.790290968390174
              ],
              [
                -73.83738681297147,
                40.79030160310207
              ],
              [
                -73.83738781967513,
                40.790311419667155
              ],
              [
                -73.83739029705,
                40.79032059115643
              ],
              [
                -73.83739429181087,
                40.79032921362793
              ],
              [
                -73.83741435117264,
                40.79035859907439
              ],
              [
                -73.83745089190637,
                40.7904016491869
              ],
              [
                -73.83748191403235,
                40.790434497921844
              ],
              [
                -73.83737212998825,
                40.790436132069345
              ],
              [
                -73.8373722220789,
                40.79039843212046
              ],
              [
                -73.83701257693828,
                40.79040892012138
              ],
              [
                -73.83701872622908,
                40.79042934965848
              ],
              [
                -73.83681410438504,
                40.79043377227646
              ],
              [
                -73.83681814957055,
                40.790469906359775
              ],
              [
                -73.83702688559562,
                40.79047334448211
              ],
              [
                -73.83702682792106,
                40.79049690516987
              ],
              [
                -73.83737820331633,
                40.790487977178934
              ],
              [
                -73.83738034396792,
                40.790458135050606
              ],
              [
                -73.83750358883579,
                40.79046166072542
              ],
              [
                -73.83750559139764,
                40.790466517931634
              ],
              [
                -73.83750762598892,
                40.79047748818398
              ],
              [
                -73.83750739724938,
                40.79048893453552
              ],
              [
                -73.83750394246856,
                40.79052041890715
              ],
              [
                -73.83750435831055,
                40.790529667564236
              ],
              [
                -73.83750614052263,
                40.79053774781548
              ],
              [
                -73.83751302747666,
                40.79055583987895
              ],
              [
                -73.83753119804764,
                40.79059647883831
              ],
              [
                -73.83753790568913,
                40.790615524704336
              ],
              [
                -73.83754750568994,
                40.7906529989196
              ],
              [
                -73.83755720396665,
                40.79069907251719
              ],
              [
                -73.8375614571039,
                40.7907331714321
              ],
              [
                -73.83756129773764,
                40.79076948903237
              ],
              [
                -73.83756110417633,
                40.790771037866655
              ],
              [
                -73.83756074492716,
                40.79077255849074
              ],
              [
                -73.83755948101533,
                40.79077554737224
              ],
              [
                -73.83755749560596,
                40.79077847040466
              ],
              [
                -73.83755477557729,
                40.79078137548136
              ],
              [
                -73.83754690552153,
                40.79078730785384
              ],
              [
                -73.83752688826179,
                40.7907989409111
              ],
              [
                -73.83751906737257,
                40.790804324370356
              ],
              [
                -73.83751555297322,
                40.79080748849326
              ],
              [
                -73.83751291473975,
                40.790810655526556
              ],
              [
                -73.83751113004466,
                40.79081388474235
              ],
              [
                -73.83751053377519,
                40.79081552296995
              ],
              [
                -73.83751015056319,
                40.79081719785083
              ],
              [
                -73.83750940657339,
                40.79082571529942
              ],
              [
                -73.83751003589187,
                40.7908345591807
              ],
              [
                -73.83751540542212,
                40.79085340497823
              ],
              [
                -73.83752623224662,
                40.79087392450832
              ],
              [
                -73.837543531026,
                40.79089808226201
              ],
              [
                -73.8375519436678,
                40.79090773034616
              ],
              [
                -73.8375624755526,
                40.790917875448685
              ],
              [
                -73.83761057596885,
                40.79095833091476
              ],
              [
                -73.83762000783256,
                40.790967898677394
              ],
              [
                -73.83762759160071,
                40.79097713933916
              ],
              [
                -73.83763931193995,
                40.79099590411601
              ],
              [
                -73.837639582358,
                40.790996643283805
              ],
              [
                -73.8376458451299,
                40.79101321165314
              ],
              [
                -73.83764711720666,
                40.79102115615742
              ],
              [
                -73.83764705075262,
                40.79102861194759
              ],
              [
                -73.83764565993727,
                40.7910355530771
              ],
              [
                -73.83764293158194,
                40.79104196026183
              ],
              [
                -73.83764043314314,
                40.79104582959373
              ],
              [
                -73.83763726122932,
                40.79104955356978
              ],
              [
                -73.83762879578263,
                40.79105675428099
              ],
              [
                -73.83761861408517,
                40.79106315984361
              ],
              [
                -73.8375887630135,
                40.79107951650588
              ],
              [
                -73.83758076461456,
                40.791085097901565
              ],
              [
                -73.83757458989415,
                40.79109071654428
              ],
              [
                -73.83755939058666,
                40.79110892171535
              ],
              [
                -73.83754577477379,
                40.791129388698174
              ],
              [
                -73.8375336338759,
                40.79115226208251
              ],
              [
                -73.83752274044276,
                40.791177983144856
              ],
              [
                -73.83752056797638,
                40.7911850218432
              ],
              [
                -73.8375191987064,
                40.79119261132452
              ],
              [
                -73.8375188131358,
                40.79120979285176
              ],
              [
                -73.83752091447616,
                40.79122738245855
              ],
              [
                -73.83752906416629,
                40.79127199589806
              ],
              [
                -73.83752990905938,
                40.791294842019205
              ],
              [
                -73.8375278430181,
                40.791306230831026
              ],
              [
                -73.83752373147357,
                40.79131616649232
              ],
              [
                -73.83752089932669,
                40.791320656199865
              ],
              [
                -73.83751751246105,
                40.791324839894266
              ],
              [
                -73.83751359396337,
                40.79132874122887
              ],
              [
                -73.83750910549357,
                40.79133235462143
              ],
              [
                -73.8374525315691,
                40.79137045689218
              ],
              [
                -73.83742329381235,
                40.79139283791764
              ],
              [
                -73.83730267089945,
                40.791410155512565
              ],
              [
                -73.83722288087088,
                40.79143034893579
              ],
              [
                -73.83706020618943,
                40.791438386284135
              ],
              [
                -73.83694476868976,
                40.79147148753525
              ],
              [
                -73.83690586384843,
                40.791493503452216
              ],
              [
                -73.83682042117202,
                40.79153361707885
              ],
              [
                -73.83671513353327,
                40.79158295059873
              ],
              [
                -73.83660316329727,
                40.791661144192254
              ],
              [
                -73.83653187324715,
                40.79174343240566
              ],
              [
                -73.83653932567061,
                40.79186763839276
              ],
              [
                -73.83654148605072,
                40.792073496093494
              ],
              [
                -73.8366080165945,
                40.79231938192448
              ],
              [
                -73.83663288911686,
                40.79251637576928
              ],
              [
                -73.83818069199988,
                40.79274850199993
              ],
              [
                -73.83887362400004,
                40.792949805999854
              ],
              [
                -73.83905064799991,
                40.79298782099989
              ],
              [
                -73.83905076599997,
                40.79298784899993
              ],
              [
                -73.83905088399997,
                40.79298788499989
              ],
              [
                -73.83922303099996,
                40.793043045999916
              ],
              [
                -73.83938616999993,
                40.79311469299992
              ],
              [
                -73.8395361909999,
                40.793201277999934
              ],
              [
                -73.83953626099989,
                40.79320132299989
              ],
              [
                -73.8396698189999,
                40.79330063699999
              ],
              [
                -73.83966987799987,
                40.79330068999995
              ],
              [
                -73.83978455899991,
                40.793410080999934
              ],
              [
                -73.83990622699984,
                40.79351658899994
              ],
              [
                -73.84004094899993,
                40.79361525499989
              ],
              [
                -73.84018725699998,
                40.79370467999993
              ],
              [
                -73.84018731600003,
                40.79370471599992
              ],
              [
                -73.84018738599987,
                40.79370475199994
              ],
              [
                -73.84034364899992,
                40.79378386199988
              ],
              [
                -73.84050794799997,
                40.79385176199988
              ],
              [
                -73.84050805399978,
                40.793851798999874
              ],
              [
                -73.84067828300006,
                40.79390789199993
              ],
              [
                -73.8407608009999,
                40.79250035099991
              ],
              [
                -73.84081532599994,
                40.79150488499992
              ],
              [
                -73.83987372999988,
                40.791470541999914
              ],
              [
                -73.83895676099984,
                40.791438430999946
              ],
              [
                -73.83900524099994,
                40.79061732199994
              ],
              [
                -73.83992306199994,
                40.79064990199992
              ],
              [
                -73.83999984499995,
                40.78925199699993
              ],
              [
                -73.84008157499997,
                40.78785153099995
              ],
              [
                -73.83916328899986,
                40.78782010599988
              ],
              [
                -73.83824429099994,
                40.78778591599996
              ],
              [
                -73.83834963799995,
                40.785850305999936
              ],
              [
                -73.83845605499975,
                40.78582446799989
              ],
              [
                -73.83853062500003,
                40.78470030299992
              ],
              [
                -73.83862028899992,
                40.78322714299991
              ],
              [
                -73.83869921299996,
                40.78167695699988
              ],
              [
                -73.83777640299981,
                40.78165237399991
              ],
              [
                -73.83669385900005,
                40.78164957499991
              ],
              [
                -73.83218249399988,
                40.78165887099988
              ],
              [
                -73.82770726099993,
                40.781667916999886
              ],
              [
                -73.82678475899996,
                40.78166766899991
              ],
              [
                -73.82569679299998,
                40.781690530999875
              ],
              [
                -73.82528484199993,
                40.78169729199988
              ],
              [
                -73.82513740299993,
                40.78169970599994
              ],
              [
                -73.82498993100002,
                40.7817014639999
              ],
              [
                -73.82462418799997,
                40.781705824999925
              ],
              [
                -73.82384132799994,
                40.78171688599984
              ],
              [
                -73.82224127699999,
                40.78169387899992
              ],
              [
                -73.82243697299995,
                40.78141383699989
              ],
              [
                -73.8226154139999,
                40.78112682399995
              ],
              [
                -73.82277596999982,
                40.780833776999906
              ],
              [
                -73.82291816299993,
                40.78053566699986
              ],
              [
                -73.82331286899986,
                40.77957865999988
              ],
              [
                -73.82223999299985,
                40.77949023699989
              ],
              [
                -73.82132278499986,
                40.77940925899987
              ],
              [
                -73.82032141599986,
                40.779334680999916
              ],
              [
                -73.8198104219999,
                40.779296988999874
              ],
              [
                -73.81930985099999,
                40.77926005999989
              ],
              [
                -73.81728146999997,
                40.77910551299991
              ],
              [
                -73.81452997799975,
                40.77888817499994
              ],
              [
                -73.8146630589999,
                40.77790468699987
              ],
              [
                -73.81475882999987,
                40.77720350099992
              ],
              [
                -73.81478821799982,
                40.776995738999865
              ],
              [
                -73.81485850699984,
                40.77648592299988
              ],
              [
                -73.812686263,
                40.776312793999914
              ],
              [
                -73.8094525209999,
                40.77605807899988
              ],
              [
                -73.80659228499994,
                40.77583186199997
              ],
              [
                -73.80379022899989,
                40.775610111999924
              ],
              [
                -73.80369287499998,
                40.7763196879999
              ],
              [
                -73.8035964729998,
                40.77703047199988
              ],
              [
                -73.80346278599977,
                40.77800817399988
              ],
              [
                -73.80342253199996,
                40.77833770799986
              ],
              [
                -73.80332690999992,
                40.77899547999995
              ],
              [
                -73.8032260339999,
                40.77970292499986
              ],
              [
                -73.80320064599984,
                40.7798836309999
              ],
              [
                -73.80313481699993,
                40.78041580599991
              ],
              [
                -73.803066233,
                40.78077013599989
              ],
              [
                -73.8030331849998,
                40.78094086999989
              ],
              [
                -73.80324974699994,
                40.7812754799999
              ],
              [
                -73.80314563299987,
                40.78132556799989
              ],
              [
                -73.80299781999996,
                40.78141047599992
              ],
              [
                -73.80293830099997,
                40.781835033999926
              ],
              [
                -73.8028401069999,
                40.78255003699992
              ],
              [
                -73.80274225299985,
                40.783253667999894
              ],
              [
                -73.80254724699994,
                40.78467334699992
              ],
              [
                -73.80241070199989,
                40.78581721099992
              ],
              [
                -73.80242243499985,
                40.78601897999991
              ],
              [
                -73.80242791599977,
                40.786068273999945
              ],
              [
                -73.80215169199994,
                40.78605649899994
              ],
              [
                -73.80157320499991,
                40.78603473299989
              ],
              [
                -73.80067959399986,
                40.7860011019999
              ],
              [
                -73.79973844499978,
                40.78595286999995
              ],
              [
                -73.7992594699999,
                40.785934836999935
              ],
              [
                -73.79880021900001,
                40.7859175479999
              ],
              [
                -73.7978420549998,
                40.78589425899988
              ],
              [
                -73.79685935799988,
                40.78599452199993
              ],
              [
                -73.79582463099997,
                40.786130587999914
              ],
              [
                -73.79491977600001,
                40.78633880799987
              ],
              [
                -73.79454742299993,
                40.78646433199992
              ],
              [
                -73.79392031799995,
                40.78667573999992
              ],
              [
                -73.7933258969999,
                40.78693204899985
              ],
              [
                -73.79153624300002,
                40.78775191299995
              ],
              [
                -73.79063559999989,
                40.78816951899994
              ],
              [
                -73.79058298999988,
                40.78819290999991
              ],
              [
                -73.790653771,
                40.78824945299988
              ],
              [
                -73.79084786600005,
                40.78840689799993
              ],
              [
                -73.79133754899989,
                40.7888577409999
              ],
              [
                -73.79156371699992,
                40.789103095999884
              ],
              [
                -73.79179280401884,
                40.789351616549254
              ],
              [
                -73.79186140055127,
                40.78929991645493
              ],
              [
                -73.79191510216272,
                40.78926012139201
              ],
              [
                -73.79194212953739,
                40.78923291109135
              ],
              [
                -73.79196317855998,
                40.78920767232353
              ],
              [
                -73.79200172966996,
                40.789159438746445
              ],
              [
                -73.79205591912397,
                40.78909217901133
              ],
              [
                -73.7921025310119,
                40.78906127723174
              ],
              [
                -73.79215684849902,
                40.78903675479969
              ],
              [
                -73.79220477113718,
                40.78901474291051
              ],
              [
                -73.79223970763987,
                40.78899679588733
              ],
              [
                -73.7923529115416,
                40.78890684180988
              ],
              [
                -73.79246425194015,
                40.78885974786807
              ],
              [
                -73.79249623443704,
                40.78884295707004
              ],
              [
                -73.79251505089977,
                40.78883450862251
              ],
              [
                -73.79253973216706,
                40.788822801131914
              ],
              [
                -73.79258763290983,
                40.78879612395847
              ],
              [
                -73.79263216028738,
                40.78878412883775
              ],
              [
                -73.79268790112779,
                40.78877761872355
              ],
              [
                -73.79275907929781,
                40.788779196501466
              ],
              [
                -73.79280669580459,
                40.788783046246145
              ],
              [
                -73.79286156729569,
                40.78878964771914
              ],
              [
                -73.79292049233945,
                40.78880336858099
              ],
              [
                -73.79299837220297,
                40.788821265864115
              ],
              [
                -73.79301554754561,
                40.78882659186026
              ],
              [
                -73.79302867353495,
                40.788830821498095
              ],
              [
                -73.793045915972,
                40.78883732615271
              ],
              [
                -73.79306030443946,
                40.78884327736913
              ],
              [
                -73.79306290763347,
                40.78884438101108
              ],
              [
                -73.79307470450006,
                40.788849399815604
              ],
              [
                -73.79308764837894,
                40.78885485322936
              ],
              [
                -73.79310747838457,
                40.78886394760762
              ],
              [
                -73.79311876623373,
                40.78886849776241
              ],
              [
                -73.79313432289509,
                40.78887239753759
              ],
              [
                -73.79315207264622,
                40.78887978628183
              ],
              [
                -73.79316692371502,
                40.788885333740765
              ],
              [
                -73.79318268826286,
                40.78889092723132
              ],
              [
                -73.7932005858231,
                40.78889629017865
              ],
              [
                -73.79321728564682,
                40.788901938615744
              ],
              [
                -73.79323111189646,
                40.7889060344611
              ],
              [
                -73.79324618916168,
                40.78891135665864
              ],
              [
                -73.7932628102149,
                40.788919589365136
              ],
              [
                -73.79328112097696,
                40.788929311722214
              ],
              [
                -73.79329508130503,
                40.788935874112155
              ],
              [
                -73.79330846591378,
                40.78894059000592
              ],
              [
                -73.79332492097404,
                40.788945283930204
              ],
              [
                -73.79334490202703,
                40.7889511106165
              ],
              [
                -73.7933596983348,
                40.788955279551644
              ],
              [
                -73.7933843509896,
                40.7889604289247
              ],
              [
                -73.79338830648537,
                40.78896094981673
              ],
              [
                -73.7934008839739,
                40.7889626022201
              ],
              [
                -73.7934185672628,
                40.78896436311668
              ],
              [
                -73.79343369196052,
                40.78896619950195
              ],
              [
                -73.79345335941076,
                40.78897002635693
              ],
              [
                -73.79346997126022,
                40.78897335202481
              ],
              [
                -73.7934855569334,
                40.78897565863619
              ],
              [
                -73.79350346628591,
                40.788976950518006
              ],
              [
                -73.7935227863599,
                40.7889784348985
              ],
              [
                -73.79353856440639,
                40.7889792657792
              ],
              [
                -73.79355631645792,
                40.78898209776892
              ],
              [
                -73.79357580273829,
                40.78898734657069
              ],
              [
                -73.79359204027723,
                40.788992752050866
              ],
              [
                -73.79361070599788,
                40.788995143735875
              ],
              [
                -73.79362007276016,
                40.788996637749825
              ],
              [
                -73.79331449772687,
                40.78946956370559
              ],
              [
                -73.79324728647568,
                40.78948645722061
              ],
              [
                -73.79322229668836,
                40.789524221739654
              ],
              [
                -73.79338628565984,
                40.789581230952585
              ],
              [
                -73.7934087886445,
                40.789543461940895
              ],
              [
                -73.7933815857415,
                40.78949236880248
              ],
              [
                -73.7936446964654,
                40.78906056286634
              ],
              [
                -73.7936603775312,
                40.7890584127739
              ],
              [
                -73.79366598465121,
                40.78905763946272
              ],
              [
                -73.79368586744265,
                40.78904931787508
              ],
              [
                -73.79370362194145,
                40.78904368561593
              ],
              [
                -73.79372207428246,
                40.78903833453836
              ],
              [
                -73.79372761531054,
                40.78903534440716
              ],
              [
                -73.79374011158927,
                40.78904799200271
              ],
              [
                -73.79375126484103,
                40.78906193333894
              ],
              [
                -73.79375837416896,
                40.78907330211838
              ],
              [
                -73.79376412048383,
                40.78908473111283
              ],
              [
                -73.79377152013572,
                40.78909822430017
              ],
              [
                -73.79377783352228,
                40.78910981563573
              ],
              [
                -73.79378268703296,
                40.789118937377
              ],
              [
                -73.79378864029793,
                40.78913251039784
              ],
              [
                -73.79379175293722,
                40.789141286434074
              ],
              [
                -73.7937928927188,
                40.789144485029084
              ],
              [
                -73.79379751466355,
                40.78915934343663
              ],
              [
                -73.79380123117537,
                40.78917205606402
              ],
              [
                -73.79380595365934,
                40.78918477099362
              ],
              [
                -73.7938116590685,
                40.78919795541303
              ],
              [
                -73.79381717578386,
                40.789211003966116
              ],
              [
                -73.79382453606576,
                40.78922575904967
              ],
              [
                -73.79383529787947,
                40.78924718287743
              ],
              [
                -73.79384121752716,
                40.78926001687977
              ],
              [
                -73.79385277898098,
                40.78928394530344
              ],
              [
                -73.79385794851758,
                40.78929430109733
              ],
              [
                -73.7938618873089,
                40.78930399765446
              ],
              [
                -73.79386941356547,
                40.78932231847865
              ],
              [
                -73.79387650299029,
                40.7893399178261
              ],
              [
                -73.79388490062964,
                40.789359996378295
              ],
              [
                -73.79388945027503,
                40.78937144952725
              ],
              [
                -73.79389479238411,
                40.78938323780427
              ],
              [
                -73.79390031635003,
                40.78939395440987
              ],
              [
                -73.7939074378363,
                40.78940533140986
              ],
              [
                -73.7939159522165,
                40.78941810705818
              ],
              [
                -73.7939245140734,
                40.78943085565157
              ],
              [
                -73.79393368216904,
                40.78944329038014
              ],
              [
                -73.79393468415904,
                40.78944465130666
              ],
              [
                -73.79394377663391,
                40.78945844620684
              ],
              [
                -73.79395254962347,
                40.78947227504538
              ],
              [
                -73.79396070185861,
                40.789487193029956
              ],
              [
                -73.79396773256312,
                40.789500703637074
              ],
              [
                -73.79397375798852,
                40.789513916596384
              ],
              [
                -73.79397943437093,
                40.789525317980015
              ],
              [
                -73.7939862116625,
                40.7895403767393
              ],
              [
                -73.79399525153924,
                40.78955970926655
              ],
              [
                -73.79400519274277,
                40.78957867401022
              ],
              [
                -73.79401445433064,
                40.78959915917305
              ],
              [
                -73.79402261261383,
                40.78961213168933
              ],
              [
                -73.79402867744963,
                40.78962408324828
              ],
              [
                -73.79403473841357,
                40.78963742961802
              ],
              [
                -73.79404284597742,
                40.78965540016756
              ],
              [
                -73.79405029959906,
                40.789674072820254
              ],
              [
                -73.79405742236868,
                40.789692392407396
              ],
              [
                -73.79406534369772,
                40.78971356788913
              ],
              [
                -73.79407276315243,
                40.789731889177986
              ],
              [
                -73.79407939586396,
                40.789747740906535
              ],
              [
                -73.79408659735556,
                40.78976391697505
              ],
              [
                -73.79409421254188,
                40.78978010366625
              ],
              [
                -73.79409987294363,
                40.789792766482265
              ],
              [
                -73.7941050313679,
                40.7898065268665
              ],
              [
                -73.79411023037532,
                40.78981885397413
              ],
              [
                -73.79411902284379,
                40.78983767224789
              ],
              [
                -73.79412817672484,
                40.789854519893105
              ],
              [
                -73.79413668563573,
                40.789869239146036
              ],
              [
                -73.79414575519934,
                40.789882349478745
              ],
              [
                -73.79415434690665,
                40.78989367531262
              ],
              [
                -73.79415995022116,
                40.789905346607256
              ],
              [
                -73.79416446201712,
                40.78991751986916
              ],
              [
                -73.79417158807009,
                40.78993479593757
              ],
              [
                -73.79417832287687,
                40.789952430816655
              ],
              [
                -73.7941853185527,
                40.78997002997572
              ],
              [
                -73.7941908351834,
                40.7899830790116
              ],
              [
                -73.79419610627916,
                40.78999505544521
              ],
              [
                -73.79420166179669,
                40.790007006084835
              ],
              [
                -73.79420819934748,
                40.79001929168605
              ],
              [
                -73.79421505675202,
                40.79003156964916
              ],
              [
                -73.79422148169029,
                40.79004598932418
              ],
              [
                -73.7942275357841,
                40.79006115550073
              ],
              [
                -73.7942336926241,
                40.79007809545341
              ],
              [
                -73.79424428641408,
                40.79011217985136
              ],
              [
                -73.79424756861381,
                40.79012332498793
              ],
              [
                -73.79425313714486,
                40.79014276822874
              ],
              [
                -73.7942554627349,
                40.790149346585785
              ],
              [
                -73.79425926772764,
                40.790160078196955
              ],
              [
                -73.79426578020373,
                40.7901769913083
              ],
              [
                -73.79427418291424,
                40.790195286863614
              ],
              [
                -73.79428311094897,
                40.79021605955127
              ],
              [
                -73.79428754610856,
                40.79022644098505
              ],
              [
                -73.79429666637722,
                40.79024649382216
              ],
              [
                -73.79430570458467,
                40.790266212801164
              ],
              [
                -73.79431395015501,
                40.790285579566365
              ],
              [
                -73.79432154783133,
                40.790304260665216
              ],
              [
                -73.79432846068629,
                40.79032150886808
              ],
              [
                -73.7943353520762,
                40.790338072020205
              ],
              [
                -73.79434137053013,
                40.79035358992997
              ],
              [
                -73.79434792535335,
                40.79036800982879
              ],
              [
                -73.79436018129554,
                40.79038957950346
              ],
              [
                -73.79436196045286,
                40.79039259981313
              ],
              [
                -73.79436821926048,
                40.79040323746078
              ],
              [
                -73.79437655855733,
                40.79041938856769
              ],
              [
                -73.79438420233413,
                40.79043414428552
              ],
              [
                -73.7943889673392,
                40.790445075117205
              ],
              [
                -73.79439337271718,
                40.79045740196747
              ],
              [
                -73.79439784323336,
                40.790471548093734
              ],
              [
                -73.79440178712217,
                40.79048356771623
              ],
              [
                -73.79440224434303,
                40.790484972731
              ],
              [
                -73.7944083356295,
                40.79050011266167
              ],
              [
                -73.7944129564774,
                40.79051140391755
              ],
              [
                -73.79441852002448,
                40.79052477802459
              ],
              [
                -73.79442526177719,
                40.79053991844437
              ],
              [
                -73.79443444762005,
                40.790558178528066
              ],
              [
                -73.79444215932591,
                40.79057364467115
              ],
              [
                -73.79444910197826,
                40.79058554449856
              ],
              [
                -73.79445599717465,
                40.79059710935714
              ],
              [
                -73.7944618882688,
                40.79060798908812
              ],
              [
                -73.79446716657733,
                40.79061766103935
              ],
              [
                -73.79447563763632,
                40.79063327344379
              ],
              [
                -73.7944823842861,
                40.790646621010275
              ],
              [
                -73.79449149878917,
                40.79066508031729
              ],
              [
                -73.79449922169232,
                40.790681068988356
              ],
              [
                -73.79450545895085,
                40.79069477632772
              ],
              [
                -73.79451292468137,
                40.79070989122861
              ],
              [
                -73.79451840553797,
                40.79072293080191
              ],
              [
                -73.79452400304338,
                40.790736313172644
              ],
              [
                -73.7945301039987,
                40.79074826526983
              ],
              [
                -73.79453505363188,
                40.790760952594184
              ],
              [
                -73.79454128187366,
                40.7907775150531
              ],
              [
                -73.79454801070054,
                40.79079693287163
              ],
              [
                -73.79455741237942,
                40.79081805835119
              ],
              [
                -73.7945619296379,
                40.79082879071814
              ],
              [
                -73.79457078150446,
                40.79085137419643
              ],
              [
                -73.79457479024903,
                40.79086139417535
              ],
              [
                -73.79458361633066,
                40.79088112146363
              ],
              [
                -73.79458772975563,
                40.79089186349624
              ],
              [
                -73.7945964489682,
                40.79091158188168
              ],
              [
                -73.79460316376611,
                40.790927983206615
              ],
              [
                -73.7946079146054,
                40.79093959918247
              ],
              [
                -73.79461594545715,
                40.790959316339496
              ],
              [
                -73.79462505620809,
                40.790979017829955
              ],
              [
                -73.79462835064085,
                40.7909901618031
              ],
              [
                -73.79463460293387,
                40.791010732180034
              ],
              [
                -73.7946391638494,
                40.79101853862039
              ],
              [
                -73.79464703032944,
                40.7910341680266
              ],
              [
                -73.79464998480044,
                40.79104062067829
              ],
              [
                -73.79465265566238,
                40.791046477773875
              ],
              [
                -73.79465925836791,
                40.791060925212825
              ],
              [
                -73.79466435072615,
                40.79107323451246
              ],
              [
                -73.79467220456496,
                40.791089250375684
              ],
              [
                -73.79468144277753,
                40.79110572805948
              ],
              [
                -73.79468995269066,
                40.79112007083999
              ],
              [
                -73.79469592947264,
                40.79112992411411
              ],
              [
                -73.79469717838538,
                40.79113199695434
              ],
              [
                -73.79470454502517,
                40.791144607828514
              ],
              [
                -73.79471222949653,
                40.79115794013066
              ],
              [
                -73.79471981170987,
                40.79116948039709
              ],
              [
                -73.79472945263014,
                40.791182392343096
              ],
              [
                -73.79474673672595,
                40.79119946970774
              ],
              [
                -73.79476202145314,
                40.791217614957176
              ],
              [
                -73.7947816666935,
                40.79124783703308
              ],
              [
                -73.79479635971158,
                40.7912814922681
              ],
              [
                -73.79482960325622,
                40.7913726145439
              ],
              [
                -73.79485406236525,
                40.791465329489405
              ],
              [
                -73.79486960813615,
                40.79155914862219
              ],
              [
                -73.79486633368519,
                40.79160023282999
              ],
              [
                -73.79487243177032,
                40.79164113057496
              ],
              [
                -73.79488771963608,
                40.79168061606773
              ],
              [
                -73.79491173908899,
                40.79171750584473
              ],
              [
                -73.79494377022742,
                40.79175069424006
              ],
              [
                -73.79495077102447,
                40.791774200640674
              ],
              [
                -73.794954878743,
                40.79179084038407
              ],
              [
                -73.79495984336572,
                40.791809948799965
              ],
              [
                -73.79496498146193,
                40.79183048969968
              ],
              [
                -73.79497015548864,
                40.79185103116549
              ],
              [
                -73.79497299639054,
                40.79186360599338
              ],
              [
                -73.79497523156434,
                40.79187618141886
              ],
              [
                -73.7949762125005,
                40.791888835360425
              ],
              [
                -73.79497562812634,
                40.79190151332163
              ],
              [
                -73.79497472147854,
                40.791914911069206
              ],
              [
                -73.79497312335535,
                40.79193002723615
              ],
              [
                -73.79496993708128,
                40.791944924972576
              ],
              [
                -73.7949661359609,
                40.79195984841916
              ],
              [
                -73.79496327179088,
                40.79197400894605
              ],
              [
                -73.79496109819607,
                40.791987458717806
              ],
              [
                -73.79496019091042,
                40.79200084758516
              ],
              [
                -73.7949601902037,
                40.79201281460102
              ],
              [
                -73.79495990501024,
                40.792024754809205
              ],
              [
                -73.79495909296607,
                40.79205359673375
              ],
              [
                -73.79495950882443,
                40.79206872536182
              ],
              [
                -73.7949589280257,
                40.792084177216225
              ],
              [
                -73.7949589962517,
                40.79209630685428
              ],
              [
                -73.79495885389295,
                40.7921082473165
              ],
              [
                -73.79495775348727,
                40.7921229324874
              ],
              [
                -73.79495576121096,
                40.7921392013655
              ],
              [
                -73.79495314924843,
                40.79215212885006
              ],
              [
                -73.79494974481752,
                40.79216864667155
              ],
              [
                -73.79494587131747,
                40.792184091495116
              ],
              [
                -73.79494203241866,
                40.792199537552754
              ],
              [
                -73.79494075819504,
                40.79221311574069
              ],
              [
                -73.79493930906199,
                40.79222565663437
              ],
              [
                -73.79493778411938,
                40.7922399808698
              ],
              [
                -73.79493673317057,
                40.79225427964681
              ],
              [
                -73.7949366538639,
                40.792268362201916
              ],
              [
                -73.79493610870844,
                40.79227996873894
              ],
              [
                -73.79493520498374,
                40.79229248480394
              ],
              [
                -73.79493267385875,
                40.79230664474913
              ],
              [
                -73.79493082375255,
                40.792318474621105
              ],
              [
                -73.79492786300479,
                40.79234122484441
              ],
              [
                -73.79492773912166,
                40.792346736045346
              ],
              [
                -73.79492892588645,
                40.79236169533602
              ],
              [
                -73.7949318062081,
                40.79237657639003
              ],
              [
                -73.79493526379345,
                40.79238896401615
              ],
              [
                -73.79493821817327,
                40.7924027742111
              ],
              [
                -73.79493883421397,
                40.79241452722073
              ],
              [
                -73.79493836447318,
                40.79242862884887
              ],
              [
                -73.79493767315564,
                40.79244131482741
              ],
              [
                -73.79493816409331,
                40.792455039389495
              ],
              [
                -73.79493729075463,
                40.79246913979684
              ],
              [
                -73.79493585234243,
                40.79248256340104
              ],
              [
                -73.79493227027359,
                40.7924953447515
              ],
              [
                -73.7949274201559,
                40.79250796084333
              ],
              [
                -73.79492147125467,
                40.79251991794494
              ],
              [
                -73.794914609934,
                40.792531540046944
              ],
              [
                -73.79490752948263,
                40.792541738964594
              ],
              [
                -73.79489808029669,
                40.79255094392609
              ],
              [
                -73.79488652534009,
                40.792559514405944
              ],
              [
                -73.79487339339393,
                40.79256838781206
              ],
              [
                -73.79485899868224,
                40.79257588107198
              ],
              [
                -73.79484506634651,
                40.79258297007648
              ],
              [
                -73.79483110959207,
                40.79259018535003
              ],
              [
                -73.7948164560528,
                40.79259683230774
              ],
              [
                -73.7948014846438,
                40.79260283908525
              ],
              [
                -73.7947817100901,
                40.79261035860793
              ],
              [
                -73.79476883844725,
                40.79261544136373
              ],
              [
                -73.794747854367,
                40.79262690445479
              ],
              [
                -73.79473316431998,
                40.792637665595414
              ],
              [
                -73.79471954908433,
                40.7926498522801
              ],
              [
                -73.79471408530152,
                40.79265787522853
              ],
              [
                -73.79471124038172,
                40.79266204874978
              ],
              [
                -73.79470737521757,
                40.79267447711513
              ],
              [
                -73.79470613603486,
                40.792688253544604
              ],
              [
                -73.79470801660072,
                40.79270101664061
              ],
              [
                -73.79471053660359,
                40.792713430077235
              ],
              [
                -73.79471310199348,
                40.792723348637395
              ],
              [
                -73.79471718788803,
                40.79273534219599
              ],
              [
                -73.79472270985227,
                40.79274676956305
              ],
              [
                -73.79472946306416,
                40.79275814584947
              ],
              [
                -73.79473679247478,
                40.79277166748784
              ],
              [
                -73.7947449812176,
                40.79278658597585
              ],
              [
                -73.79475046940583,
                40.792797103115255
              ],
              [
                -73.79475609852271,
                40.79281245847398
              ],
              [
                -73.79476020271436,
                40.79282624324599
              ],
              [
                -73.7947660398668,
                40.792842831291956
              ],
              [
                -73.7947699898107,
                40.79285323981521
              ],
              [
                -73.79477366900427,
                40.792866673031426
              ],
              [
                -73.79477686526542,
                40.79288281561497
              ],
              [
                -73.79478041565106,
                40.79290356879514
              ],
              [
                -73.79478137864442,
                40.792917817379596
              ],
              [
                -73.79478009224506,
                40.792935330381134
              ],
              [
                -73.79477802260726,
                40.79295321908797
              ],
              [
                -73.79477479087163,
                40.79296760343762
              ],
              [
                -73.79477056371599,
                40.79298197613085
              ],
              [
                -73.79476574764517,
                40.792999257190296
              ],
              [
                -73.79476125302656,
                40.79301580053716
              ],
              [
                -73.79475661908518,
                40.79303163215481
              ],
              [
                -73.794752545796,
                40.79304993225363
              ],
              [
                -73.7947488060191,
                40.79306787293383
              ],
              [
                -73.79474581791324,
                40.79308759742348
              ],
              [
                -73.7947417941934,
                40.793105546811645
              ],
              [
                -73.79473722550095,
                40.793123161518295
              ],
              [
                -73.79473214776363,
                40.79314044210998
              ],
              [
                -73.79472704424772,
                40.793158432963544
              ],
              [
                -73.7947201089581,
                40.793182716496936
              ],
              [
                -73.7947162688371,
                40.79319852322497
              ],
              [
                -73.79471264255977,
                40.793214320449735
              ],
              [
                -73.79470847677955,
                40.79322800924298
              ],
              [
                -73.79470464656971,
                40.79324060016918
              ],
              [
                -73.79470190954045,
                40.793252095247915
              ],
              [
                -73.79470050095907,
                40.793263186946106
              ],
              [
                -73.79469712351771,
                40.79327863381512
              ],
              [
                -73.79469042215402,
                40.79329989141527
              ],
              [
                -73.79468586834619,
                40.793312671025674
              ],
              [
                -73.79468109422703,
                40.793324053751064
              ],
              [
                -73.79467640385884,
                40.79333472514483
              ],
              [
                -73.79467244034359,
                40.79334465016687
              ],
              [
                -73.79466374411744,
                40.793363464705564
              ],
              [
                -73.7946555077745,
                40.79337939745217
              ],
              [
                -73.7946479798946,
                40.79339604294104
              ],
              [
                -73.79464018582509,
                40.79341444462707
              ],
              [
                -73.7946349442311,
                40.793427447493954
              ],
              [
                -73.79462883150332,
                40.793442448727504
              ],
              [
                -73.79462121486351,
                40.79346442488427
              ],
              [
                -73.79461841616039,
                40.79347325351537
              ],
              [
                -73.79461362299521,
                40.793490516515334
              ],
              [
                -73.79460747207493,
                40.79350639048729
              ],
              [
                -73.79460379310005,
                40.79351560570578
              ],
              [
                -73.7945995750269,
                40.793527511427776
              ],
              [
                -73.79459468221486,
                40.79354263226862
              ],
              [
                -73.79459035040817,
                40.79355632025848
              ],
              [
                -73.79458621884704,
                40.79357033410699
              ],
              [
                -73.7945843488073,
                40.79358109177271
              ],
              [
                -73.79458448640392,
                40.79359429252217
              ],
              [
                -73.79458564641244,
                40.79360604650112
              ],
              [
                -73.79458888336598,
                40.793620928196354
              ],
              [
                -73.7945921592655,
                40.79363418998951
              ],
              [
                -73.794596452371,
                40.7936483357845
              ],
              [
                -73.79459934002439,
                40.79366126317494
              ],
              [
                -73.79460179039852,
                40.79367331580555
              ],
              [
                -73.79460494180181,
                40.79368499208291
              ],
              [
                -73.79460739239804,
                40.79369704471379
              ],
              [
                -73.79461013968987,
                40.79370909904596
              ],
              [
                -73.79461196720068,
                40.793723843705735
              ],
              [
                -73.79461371268458,
                40.79374195548111
              ],
              [
                -73.79461462119819,
                40.79375442099452
              ],
              [
                -73.79461527027149,
                40.793766884370356
              ],
              [
                -73.7946156265293,
                40.793778303038145
              ],
              [
                -73.79461619706902,
                40.793793072429445
              ],
              [
                -73.79461611932695,
                40.793806625268374
              ],
              [
                -73.7946162863885,
                40.79382178092445
              ],
              [
                -73.79461643102601,
                40.79383248723184
              ],
              [
                -73.79461567270313,
                40.79384393205768
              ],
              [
                -73.79461459323521,
                40.793855726439546
              ],
              [
                -73.79461271327459,
                40.79386969873356
              ],
              [
                -73.79461057042656,
                40.79388457134357
              ],
              [
                -73.79460973137644,
                40.79389903248894
              ],
              [
                -73.79460907123847,
                40.79391330448174
              ],
              [
                -73.7946043709085,
                40.79392790198053
              ],
              [
                -73.79459407145329,
                40.79394033696631
              ],
              [
                -73.79458306861207,
                40.79395012379547
              ],
              [
                -73.79457064406509,
                40.79396016993204
              ],
              [
                -73.79455656132107,
                40.793969536141184
              ],
              [
                -73.79454360972017,
                40.7939774376796
              ],
              [
                -73.79453257299878,
                40.793987198644544
              ],
              [
                -73.79448712741278,
                40.794017014075806
              ],
              [
                -73.79444832276786,
                40.794051829561404
              ],
              [
                -73.794417099873,
                40.794090801017624
              ],
              [
                -73.79439421572687,
                40.79413298360021
              ],
              [
                -73.79438022516155,
                40.794177354610675
              ],
              [
                -73.79437546739086,
                40.794222838290764
              ],
              [
                -73.79438005778432,
                40.794268331904256
              ],
              [
                -73.79437606190031,
                40.7943244710331
              ],
              [
                -73.79438034757158,
                40.794380597814914
              ],
              [
                -73.79439286110842,
                40.79443600884565
              ],
              [
                -73.79441344570641,
                40.79449000968993
              ],
              [
                -73.7944418434089,
                40.794541923583644
              ],
              [
                -73.79447769833916,
                40.79459109991582
              ],
              [
                -73.79451948101098,
                40.79464261424753
              ],
              [
                -73.79455425618643,
                40.79469705303749
              ],
              [
                -73.79458167330685,
                40.79475386753139
              ],
              [
                -73.7945829818924,
                40.79476311162301
              ],
              [
                -73.79458245603871,
                40.794772400462705
              ],
              [
                -73.79458010758086,
                40.794781524980266
              ],
              [
                -73.79457598937672,
                40.794790279803735
              ],
              [
                -73.79457019411687,
                40.794798467882195
              ],
              [
                -73.79456285223858,
                40.79480590492038
              ],
              [
                -73.79455412899081,
                40.79481242352768
              ],
              [
                -73.79453345509013,
                40.79480360813727
              ],
              [
                -73.79451135093834,
                40.79479709864146
              ],
              [
                -73.7944882591118,
                40.79479302537539
              ],
              [
                -73.79446464196207,
                40.79479146989507
              ],
              [
                -73.79446239884044,
                40.79478942994568
              ],
              [
                -73.79445973261227,
                40.79478770899212
              ],
              [
                -73.79445672067227,
                40.79478635698973
              ],
              [
                -73.79445345045039,
                40.794785413184464
              ],
              [
                -73.7944500168751,
                40.79478490497301
              ],
              [
                -73.79444651961522,
                40.79478484710755
              ],
              [
                -73.79444306018883,
                40.79478524126793
              ],
              [
                -73.79443973901621,
                40.79478607601239
              ],
              [
                -73.79443665250339,
                40.79478732711004
              ],
              [
                -73.7944338902459,
                40.794788958244276
              ],
              [
                -73.79443153242586,
                40.794790922066674
              ],
              [
                -73.79442964748594,
                40.794793161571555
              ],
              [
                -73.79442829014224,
                40.7947956117509
              ],
              [
                -73.79442749979546,
                40.79479820148108
              ],
              [
                -73.79442729938786,
                40.794800855587646
              ],
              [
                -73.79442844782817,
                40.79480681049854
              ],
              [
                -73.79443085877568,
                40.79481254334406
              ],
              [
                -73.79443446767492,
                40.794817900619734
              ],
              [
                -73.7944391778926,
                40.79482273887716
              ],
              [
                -73.79444486330684,
                40.79482692856545
              ],
              [
                -73.79445137168297,
                40.79483035750004
              ],
              [
                -73.79445852875008,
                40.79483293386657
              ],
              [
                -73.79446614286849,
                40.79483458867915
              ],
              [
                -73.79447401015962,
                40.79483527762807
              ],
              [
                -73.79448191996576,
                40.794834982265705
              ],
              [
                -73.79461098148356,
                40.79492973552614
              ],
              [
                -73.79462988817102,
                40.794928401178296
              ],
              [
                -73.79464823457407,
                40.79492468155519
              ],
              [
                -73.79466550345411,
                40.79491868152382
              ],
              [
                -73.79468120795204,
                40.7949105702425
              ],
              [
                -73.79469490531206,
                40.79490057639184
              ],
              [
                -73.79470620936787,
                40.79488898172743
              ],
              [
                -73.79476806561526,
                40.79488451644345
              ],
              [
                -73.79483017402438,
                40.79488588920492
              ],
              [
                -73.79489158425001,
                40.79489307900666
              ],
              [
                -73.79495135663082,
                40.79490597583461
              ],
              [
                -73.7950085765647,
                40.79492438234955
              ],
              [
                -73.79506236850438,
                40.79494801690581
              ],
              [
                -73.79507043234777,
                40.79497635861886
              ],
              [
                -73.79507450159595,
                40.79500518994561
              ],
              [
                -73.79506087255106,
                40.79504585646749
              ],
              [
                -73.79506221992759,
                40.795049786180414
              ],
              [
                -73.7950643833914,
                40.79505349937833
              ],
              [
                -73.79506730632943,
                40.79505689889563
              ],
              [
                -73.79507091225572,
                40.7950598957751
              ],
              [
                -73.79507510681178,
                40.79506241159527
              ],
              [
                -73.7950797802359,
                40.795064380523314
              ],
              [
                -73.79508481023595,
                40.79506575103707
              ],
              [
                -73.79509006518833,
                40.7950664872733
              ],
              [
                -73.79509540758374,
                40.79506656996648
              ],
              [
                -73.79510069762432,
                40.795065996952715
              ],
              [
                -73.79510579688188,
                40.795064783226564
              ],
              [
                -73.79511057192093,
                40.795062960548115
              ],
              [
                -73.7951514290729,
                40.795037425474455
              ],
              [
                -73.79518785394659,
                40.795008272855874
              ],
              [
                -73.7952368915007,
                40.79499229583413
              ],
              [
                -73.79528852796409,
                40.79498209047894
              ],
              [
                -73.79534159459615,
                40.7949778877787
              ],
              [
                -73.79542480058724,
                40.794980563948776
              ],
              [
                -73.7954543553932,
                40.794981646179366
              ],
              [
                -73.79567528495986,
                40.794991954928264
              ],
              [
                -73.79589584128126,
                40.79500614834731
              ],
              [
                -73.79611590612106,
                40.795024218827606
              ],
              [
                -73.79613453860351,
                40.79502609674189
              ],
              [
                -73.79615443364217,
                40.79502959032339
              ],
              [
                -73.79616770775884,
                40.79503241398565
              ],
              [
                -73.79618698373636,
                40.79503730245606
              ],
              [
                -73.79620599982903,
                40.795041757243
              ],
              [
                -73.79622483477702,
                40.79504727566059
              ],
              [
                -73.79624648828744,
                40.79505349009978
              ],
              [
                -73.79626532398865,
                40.795058548487205
              ],
              [
                -73.796278677112,
                40.795062714489944
              ],
              [
                -73.79629299992664,
                40.79506745849247
              ],
              [
                -73.79631349062726,
                40.795073897693456
              ],
              [
                -73.79633114341058,
                40.795078619598925
              ],
              [
                -73.79634586000752,
                40.79508240152203
              ],
              [
                -73.79635845980678,
                40.79508508894538
              ],
              [
                -73.79637599774738,
                40.795088658401184
              ],
              [
                -73.79638928364724,
                40.7950914562598
              ],
              [
                -73.79640621291948,
                40.79509612310005
              ],
              [
                -73.79642475475168,
                40.79510091038863
              ],
              [
                -73.79644420978643,
                40.79510492565462
              ],
              [
                -73.79646413823691,
                40.79510954450978
              ],
              [
                -73.79648271545763,
                40.79511429549851
              ],
              [
                -73.79649931607187,
                40.79511802527764
              ],
              [
                -73.79652623685229,
                40.795125926060585
              ],
              [
                -73.79654395497091,
                40.79513221591909
              ],
              [
                -73.79655875114958,
                40.79513737467931
              ],
              [
                -73.79657173309991,
                40.79514269273538
              ],
              [
                -73.79658446392915,
                40.795148892032984
              ],
              [
                -73.79659841538498,
                40.79515506751681
              ],
              [
                -73.79661779069339,
                40.795162314848135
              ],
              [
                -73.79663578997548,
                40.79516972995422
              ],
              [
                -73.79664707776274,
                40.7951751619454
              ],
              [
                -73.79666194262768,
                40.795181005322284
              ],
              [
                -73.79667705374752,
                40.79518734550945
              ],
              [
                -73.79669238103237,
                40.79519291964716
              ],
              [
                -73.79670587562217,
                40.79519762595181
              ],
              [
                -73.79649206440247,
                40.79558529228155
              ],
              [
                -73.79646720043996,
                40.79558146783361
              ],
              [
                -73.79644218574505,
                40.79562679523604
              ],
              [
                -73.7964670270416,
                40.795638182583744
              ],
              [
                -73.7964095342633,
                40.79572882419962
              ],
              [
                -73.7964343873751,
                40.79573643035909
              ],
              [
                -73.79648690334501,
                40.79564577893871
              ],
              [
                -73.79651175643305,
                40.79565338558407
              ],
              [
                -73.79654175232895,
                40.79560617576533
              ],
              [
                -73.79651939930963,
                40.7955947934965
              ],
              [
                -73.79673567660085,
                40.795208943420675
              ],
              [
                -73.79675010102949,
                40.7952156599895
              ],
              [
                -73.79676409743335,
                40.79522257398446
              ],
              [
                -73.79678926844697,
                40.79523293692441
              ],
              [
                -73.79679807499622,
                40.79523578061414
              ],
              [
                -73.79681863521287,
                40.79524287688226
              ],
              [
                -73.79683487523477,
                40.79524856016119
              ],
              [
                -73.79685256100464,
                40.795254129723254
              ],
              [
                -73.79687221836762,
                40.7952619352008
              ],
              [
                -73.796890840059,
                40.79526781214025
              ],
              [
                -73.79691508645404,
                40.79527439337987
              ],
              [
                -73.79693984258415,
                40.79528080312872
              ],
              [
                -73.79696684355919,
                40.79528586590168
              ],
              [
                -73.79698789062624,
                40.79528895562162
              ],
              [
                -73.7970099856453,
                40.79528994976508
              ],
              [
                -73.79702796654011,
                40.79529207013656
              ],
              [
                -73.79705038771282,
                40.79529483022955
              ],
              [
                -73.7970638232024,
                40.79529572669264
              ],
              [
                -73.79708053766431,
                40.79529713401588
              ],
              [
                -73.79709866318268,
                40.79529823741664
              ],
              [
                -73.79711357425079,
                40.795300370631104
              ],
              [
                -73.79712783133192,
                40.795303718424584
              ],
              [
                -73.79714308909722,
                40.79530868962337
              ],
              [
                -73.7971595455066,
                40.79531354549418
              ],
              [
                -73.79717408258826,
                40.795317964932025
              ],
              [
                -73.79718859775392,
                40.79532169194696
              ],
              [
                -73.79720454504239,
                40.79532608739329
              ],
              [
                -73.79722012032018,
                40.79533226465341
              ],
              [
                -73.79723849400851,
                40.79533751086957
              ],
              [
                -73.79725115660972,
                40.795342476986136
              ],
              [
                -73.79725820028814,
                40.7953525839544
              ],
              [
                -73.79725814691703,
                40.7953546913302
              ],
              [
                -73.79725795937395,
                40.79536169657787
              ],
              [
                -73.79725643301913,
                40.79537662476282
              ],
              [
                -73.79725209088424,
                40.795389791323196
              ],
              [
                -73.79724685379011,
                40.79540172281963
              ],
              [
                -73.79724211223676,
                40.79541010733434
              ],
              [
                -73.79723956239108,
                40.7954146134705
              ],
              [
                -73.79723508078402,
                40.79542703329173
              ],
              [
                -73.79723144562945,
                40.7954380854166
              ],
              [
                -73.79723241146328,
                40.795447532868266
              ],
              [
                -73.79724128273867,
                40.795452879802475
              ],
              [
                -73.79725679210726,
                40.795449223702555
              ],
              [
                -73.79727119608756,
                40.79543923621154
              ],
              [
                -73.79728820652683,
                40.79543698744807
              ],
              [
                -73.79730490010195,
                40.79543744885364
              ],
              [
                -73.7973219980645,
                40.79544135059937
              ],
              [
                -73.79733705524903,
                40.795442638540955
              ],
              [
                -73.79735897134292,
                40.79544435249453
              ],
              [
                -73.79737732260054,
                40.79544510444496
              ],
              [
                -73.79739382518174,
                40.79544639492012
              ],
              [
                -73.79741173665438,
                40.79544787616775
              ],
              [
                -73.79742732477537,
                40.79544963108647
              ],
              [
                -73.7974443322619,
                40.795452191614096
              ],
              [
                -73.79746137620008,
                40.7954545814949
              ],
              [
                -73.79747981658083,
                40.795457414083636
              ],
              [
                -73.79749920579077,
                40.79545986185521
              ],
              [
                -73.79751815428708,
                40.795463272287996
              ],
              [
                -73.79753118113057,
                40.79546576215034
              ],
              [
                -73.79754779349089,
                40.795469446734266
              ],
              [
                -73.7975646779006,
                40.795473484267085
              ],
              [
                -73.79758077321341,
                40.795476195788225
              ],
              [
                -73.79759932647495,
                40.79548083900297
              ],
              [
                -73.7976140897327,
                40.795485204839196
              ],
              [
                -73.79763313756139,
                40.79549067783259
              ],
              [
                -73.79764980808868,
                40.795495173326856
              ],
              [
                -73.79767124818858,
                40.795501415769685
              ],
              [
                -73.79769315057041,
                40.79550736082477
              ],
              [
                -73.79771318205658,
                40.79551310558236
              ],
              [
                -73.7977279329275,
                40.79551749014499
              ],
              [
                -73.79774121880916,
                40.79552034061781
              ],
              [
                -73.79775789033545,
                40.795524512104016
              ],
              [
                -73.79777449092573,
                40.79552808908323
              ],
              [
                -73.797791128113,
                40.795531955440616
              ],
              [
                -73.79780704134033,
                40.79553590998417
              ],
              [
                -73.79782444503947,
                40.79554063122325
              ],
              [
                -73.79784464434061,
                40.79554585424316
              ],
              [
                -73.79787014914405,
                40.7955520131465
              ],
              [
                -73.79763779690308,
                40.79592948863961
              ],
              [
                -73.79761542612654,
                40.79592377762284
              ],
              [
                -73.79758295471345,
                40.79596720215479
              ],
              [
                -73.79766994008877,
                40.79599382238177
              ],
              [
                -73.79769744193771,
                40.795948499081994
              ],
              [
                -73.79767259943341,
                40.795937111998605
              ],
              [
                -73.79789832686915,
                40.795559221921835
              ],
              [
                -73.79790740890826,
                40.7955615329438
              ],
              [
                -73.79792332365892,
                40.795565208041516
              ],
              [
                -73.79793782652314,
                40.79556902506901
              ],
              [
                -73.79795974255607,
                40.79557477258462
              ],
              [
                -73.79797301677294,
                40.79557762401542
              ],
              [
                -73.79798771425926,
                40.79558014305576
              ],
              [
                -73.79800615379435,
                40.7955832730837
              ],
              [
                -73.79802157304502,
                40.795585993242504
              ],
              [
                -73.79804268637255,
                40.795590613397565
              ],
              [
                -73.79806025837736,
                40.79559443578703
              ],
              [
                -73.79808137211995,
                40.795598920910244
              ],
              [
                -73.7980927750326,
                40.79560148961673
              ],
              [
                -73.79811108045207,
                40.79560588856536
              ],
              [
                -73.79812897914934,
                40.7956116020406
              ],
              [
                -73.79814210642174,
                40.79561595623244
              ],
              [
                -73.79815527967298,
                40.7956209231435
              ],
              [
                -73.79817107928987,
                40.79562710116999
              ],
              [
                -73.79818525669025,
                40.79563321386687
              ],
              [
                -73.79819816917238,
                40.79563818031648
              ],
              [
                -73.79821446551013,
                40.795644845030154
              ],
              [
                -73.7982303796071,
                40.795652311686666
              ],
              [
                -73.79825133255324,
                40.79566273058179
              ],
              [
                -73.79826932036622,
                40.79567047041085
              ],
              [
                -73.79828667622117,
                40.79567914559812
              ],
              [
                -73.79829932203045,
                40.795686281526145
              ],
              [
                -73.79831880512701,
                40.79569306052551
              ],
              [
                -73.79833298318367,
                40.79569896564129
              ],
              [
                -73.79834543315964,
                40.79570394935792
              ],
              [
                -73.79836274355313,
                40.7957121381279
              ],
              [
                -73.79837918620188,
                40.79572116300438
              ],
              [
                -73.79839399157177,
                40.79573123791189
              ],
              [
                -73.79840984754314,
                40.79574218913754
              ],
              [
                -73.79842203844572,
                40.79575090971659
              ],
              [
                -73.79843860996212,
                40.795764337879866
              ],
              [
                -73.79845270192942,
                40.795775096208395
              ],
              [
                -73.79846556486665,
                40.79578450229932
              ],
              [
                -73.79847994076829,
                40.79579547840143
              ],
              [
                -73.79849554338615,
                40.79580821046961
              ],
              [
                -73.79850627113808,
                40.795818575759
              ],
              [
                -73.79851657310793,
                40.795828616476584
              ],
              [
                -73.79852481785417,
                40.79583694249296
              ],
              [
                -73.79853413510928,
                40.795847719772055
              ],
              [
                -73.79854479080151,
                40.79585844561403
              ],
              [
                -73.79855920869174,
                40.795870853451476
              ],
              [
                -73.79856759450402,
                40.79588006189988
              ],
              [
                -73.79857655365221,
                40.79589102735077
              ],
              [
                -73.79858655954654,
                40.795901230044215
              ],
              [
                -73.79859780932355,
                40.79590758835969
              ],
              [
                -73.79860930325653,
                40.79591543422359
              ],
              [
                -73.7986259659863,
                40.79592212699287
              ],
              [
                -73.79864166023145,
                40.79592805230954
              ],
              [
                -73.79865039393185,
                40.79593164289307
              ],
              [
                -73.7986603114518,
                40.79593572187007
              ],
              [
                -73.79866788360786,
                40.79593976877286
              ],
              [
                -73.7986767201006,
                40.79594450406742
              ],
              [
                -73.79869265794949,
                40.79595224845813
              ],
              [
                -73.79871080239967,
                40.79595911451137
              ],
              [
                -73.79872429345869,
                40.79596464948794
              ],
              [
                -73.79873706372602,
                40.7959695881544
              ],
              [
                -73.79874404894636,
                40.79597142017925
              ],
              [
                -73.79875792623176,
                40.795975054748226
              ],
              [
                -73.79877535333848,
                40.79597974958247
              ],
              [
                -73.79879228361186,
                40.79598450670394
              ],
              [
                -73.7988094980293,
                40.79598933786237
              ],
              [
                -73.79882616964309,
                40.79599310361467
              ],
              [
                -73.79884236755629,
                40.795997300585746
              ],
              [
                -73.79886250737023,
                40.79600265834815
              ],
              [
                -73.79888412419602,
                40.79600923290809
              ],
              [
                -73.7988936889727,
                40.79601223975712
              ],
              [
                -73.79891628202127,
                40.79601702483289
              ],
              [
                -73.79893103859114,
                40.79601987756618
              ],
              [
                -73.79894797183704,
                40.79602336549755
              ],
              [
                -73.7989617239453,
                40.796025171954206
              ],
              [
                -73.79897526045968,
                40.79602756420446
              ],
              [
                -73.79899540637172,
                40.79603061697241
              ],
              [
                -73.79901605331288,
                40.79603287620509
              ],
              [
                -73.79903175979597,
                40.7960347678135
              ],
              [
                -73.7990480121733,
                40.796036192138025
              ],
              [
                -73.79906743699222,
                40.796038675390086
              ],
              [
                -73.79908216240186,
                40.79604009704793
              ],
              [
                -73.7991023460217,
                40.79604249098557
              ],
              [
                -73.79912224589431,
                40.79604453429711
              ],
              [
                -73.79913650747572,
                40.796046476646225
              ],
              [
                -73.7991441223516,
                40.79604792209042
              ],
              [
                -73.79917089630341,
                40.79605342588177
              ],
              [
                -73.79919028118897,
                40.7960573851098
              ],
              [
                -73.79920945494361,
                40.796061182639825
              ],
              [
                -73.79922519281368,
                40.796064146438916
              ],
              [
                -73.79924236198163,
                40.79606821052252
              ],
              [
                -73.79926052236367,
                40.796073843027315
              ],
              [
                -73.79927671742351,
                40.79607856311405
              ],
              [
                -73.79929245187914,
                40.796083173672514
              ],
              [
                -73.79930839755895,
                40.79608813589731
              ],
              [
                -73.79932384219168,
                40.79609448502781
              ],
              [
                -73.7993392679995,
                40.79610298498183
              ],
              [
                -73.7993530051208,
                40.796113356435676
              ],
              [
                -73.79936036945237,
                40.796123112531
              ],
              [
                -73.79936493097021,
                40.79613511495325
              ],
              [
                -73.79936903390198,
                40.7961497021637
              ],
              [
                -73.7993739088909,
                40.79616411716154
              ],
              [
                -73.79938044493386,
                40.7961733240058
              ],
              [
                -73.79939191142736,
                40.79618234778178
              ],
              [
                -73.79940489053641,
                40.796189043911006
              ],
              [
                -73.79941862884057,
                40.796195154518315
              ],
              [
                -73.79943604799753,
                40.79620259564957
              ],
              [
                -73.79945221348257,
                40.79620968379879
              ],
              [
                -73.79946988100619,
                40.79621736960891
              ],
              [
                -73.79948485160043,
                40.79622354550941
              ],
              [
                -73.79949978243133,
                40.796231360574424
              ],
              [
                -73.79951229230872,
                40.79623986565193
              ],
              [
                -73.79952616090942,
                40.79625051574062
              ],
              [
                -73.79953894969762,
                40.7962611277643
              ],
              [
                -73.79954855941892,
                40.79627294965463
              ],
              [
                -73.7995518868553,
                40.796285499908755
              ],
              [
                -73.79955567949882,
                40.7962969257038
              ],
              [
                -73.79956502217931,
                40.79631061017801
              ],
              [
                -73.79956913507226,
                40.796314219460406
              ],
              [
                -73.79957360324309,
                40.79631814514583
              ],
              [
                -73.7995868313636,
                40.79632437177866
              ],
              [
                -73.79960548292512,
                40.79633222060567
              ],
              [
                -73.79962511332712,
                40.796337478006066
              ],
              [
                -73.79963988478306,
                40.79633900722375
              ],
              [
                -73.7996566146603,
                40.7963354703582
              ],
              [
                -73.79966872949248,
                40.79632978348994
              ],
              [
                -73.79967362498456,
                40.7963253240965
              ],
              [
                -73.79967688098031,
                40.79632235886029
              ],
              [
                -73.79967980703287,
                40.79631058473401
              ],
              [
                -73.79967547696066,
                40.79629636703632
              ],
              [
                -73.79966916852729,
                40.7962824891595
              ],
              [
                -73.7996625990561,
                40.796268258355326
              ],
              [
                -73.79965740033593,
                40.79625532708225
              ],
              [
                -73.79965288305964,
                40.7962442788058
              ],
              [
                -73.79964913389605,
                40.79623041358494
              ],
              [
                -73.79964718804288,
                40.796215264233474
              ],
              [
                -73.79964743054035,
                40.79620573765431
              ],
              [
                -73.7996540165911,
                40.796190431251624
              ],
              [
                -73.79966586398123,
                40.7961792583002
              ],
              [
                -73.79968067900413,
                40.796174502706435
              ],
              [
                -73.79969419075046,
                40.79617313019885
              ],
              [
                -73.79971142246335,
                40.79617225064719
              ],
              [
                -73.79973334755275,
                40.796171000807895
              ],
              [
                -73.7997495701958,
                40.79617089396722
              ],
              [
                -73.79976975753038,
                40.79617200052937
              ],
              [
                -73.79979040656494,
                40.79617351363639
              ],
              [
                -73.79980958312706,
                40.79617596014664
              ],
              [
                -73.79982777405681,
                40.79617893683496
              ],
              [
                -73.79984300442064,
                40.79618179094423
              ],
              [
                -73.79985722418023,
                40.79618548913234
              ],
              [
                -73.79987296005098,
                40.79618913734801
              ],
              [
                -73.7998920964848,
                40.7961933491572
              ],
              [
                -73.79991028638486,
                40.796196891228966
              ],
              [
                -73.79993656052181,
                40.79620319591864
              ],
              [
                -73.79995275855609,
                40.79620739273354
              ],
              [
                -73.79997044383866,
                40.79621329552516
              ],
              [
                -73.79999006404911,
                40.79622164938487
              ],
              [
                -73.80000892416695,
                40.7962310384019
              ],
              [
                -73.80002190146452,
                40.796238067666295
              ],
              [
                -73.8000371189887,
                40.79624498338018
              ],
              [
                -73.80005184517341,
                40.796249618387094
              ],
              [
                -73.80006953790154,
                40.796253268965174
              ],
              [
                -73.80009214191585,
                40.79625467769023
              ],
              [
                -73.8001042080742,
                40.796253437150256
              ],
              [
                -73.80011007447156,
                40.79625715817823
              ],
              [
                -73.80012820994482,
                40.79625916089804
              ],
              [
                -73.80014788579977,
                40.79626103293552
              ],
              [
                -73.80018235388113,
                40.79626691369683
              ],
              [
                -73.80015898192978,
                40.7963441243202
              ],
              [
                -73.80014405015416,
                40.7963459884526
              ],
              [
                -73.80013145889008,
                40.796397009925165
              ],
              [
                -73.80014389123812,
                40.79639892200583
              ],
              [
                -73.80013384459131,
                40.796431043825166
              ],
              [
                -73.80007912196696,
                40.79642905840125
              ],
              [
                -73.80006403229244,
                40.79648385607758
              ],
              [
                -73.80012619978814,
                40.79649152565467
              ],
              [
                -73.80012111139985,
                40.79652932663622
              ],
              [
                -73.80010619660878,
                40.79652552022696
              ],
              [
                -73.80008350203387,
                40.79662756747464
              ],
              [
                -73.80012080488287,
                40.79663141271139
              ],
              [
                -73.80020438000442,
                40.796270053945406
              ],
              [
                -73.80021669617476,
                40.79626850859577
              ],
              [
                -73.80023494299957,
                40.79626492049996
              ],
              [
                -73.80025662935842,
                40.79626014926661
              ],
              [
                -73.80027196375512,
                40.79625780384678
              ],
              [
                -73.80027683020073,
                40.79625705925264
              ],
              [
                -73.80029381644955,
                40.79625497350855
              ],
              [
                -73.80032163038217,
                40.796253571930464
              ],
              [
                -73.80028591188064,
                40.79632354905573
              ],
              [
                -73.80036547554788,
                40.79633692038319
              ],
              [
                -73.80039069456676,
                40.796263155395856
              ],
              [
                -73.80040541403451,
                40.79626614357133
              ],
              [
                -73.80042458719116,
                40.79627018347459
              ],
              [
                -73.80044053586028,
                40.7962741914906
              ],
              [
                -73.80045652197957,
                40.796277767018616
              ],
              [
                -73.80047518250998,
                40.79628221177243
              ],
              [
                -73.80049160101078,
                40.79628781476605
              ],
              [
                -73.80050483037087,
                40.79629365431186
              ],
              [
                -73.80051806622043,
                40.79630188998289
              ],
              [
                -73.80052713996201,
                40.79631071249458
              ],
              [
                -73.80053630920082,
                40.79632327232663
              ],
              [
                -73.80054635361815,
                40.79633641062984
              ],
              [
                -73.80055487781824,
                40.79634655563873
              ],
              [
                -73.80056648026333,
                40.79635779622812
              ],
              [
                -73.80057920611054,
                40.796365859798634
              ],
              [
                -73.80059411459064,
                40.79637714229044
              ],
              [
                -73.80060009976283,
                40.79638464354133
              ],
              [
                -73.80060127994771,
                40.79638613170049
              ],
              [
                -73.80060596182751,
                40.796401673254266
              ],
              [
                -73.80060744918362,
                40.796415345729656
              ],
              [
                -73.80060754119205,
                40.79641627414679
              ],
              [
                -73.80060782494134,
                40.796416554070035
              ],
              [
                -73.80061622405263,
                40.79642482525409
              ],
              [
                -73.80062184740085,
                40.7964267623605
              ],
              [
                -73.80062752602298,
                40.79642570069056
              ],
              [
                -73.8006333403199,
                40.796415255991754
              ],
              [
                -73.80063576415175,
                40.79640087009008
              ],
              [
                -73.8006369837156,
                40.796389651182835
              ],
              [
                -73.80063995811872,
                40.79637360069606
              ],
              [
                -73.80064068730506,
                40.79637154893389
              ],
              [
                -73.80064461930634,
                40.7963603533131
              ],
              [
                -73.80065920162392,
                40.796353868162015
              ],
              [
                -73.80067173268917,
                40.79635174668059
              ],
              [
                -73.80069710049588,
                40.79634811638006
              ],
              [
                -73.80071238154792,
                40.79634591906943
              ],
              [
                -73.80072839626222,
                40.79634356102807
              ],
              [
                -73.80074440101508,
                40.79634092185982
              ],
              [
                -73.80076143013368,
                40.79633659379395
              ],
              [
                -73.80077548151479,
                40.7963330152806
              ],
              [
                -73.80079149908737,
                40.79633021597372
              ],
              [
                -73.80080944610103,
                40.796327806312384
              ],
              [
                -73.80082718668105,
                40.79632712498583
              ],
              [
                -73.80084564154882,
                40.79632907487144
              ],
              [
                -73.80086307346713,
                40.79633253590837
              ],
              [
                -73.80087826048806,
                40.79633789414244
              ],
              [
                -73.80089051983508,
                40.79634348001005
              ],
              [
                -73.80090645704925,
                40.79635133331902
              ],
              [
                -73.80092312792937,
                40.79635976317486
              ],
              [
                -73.80093513210224,
                40.79636738403015
              ],
              [
                -73.80094885668566,
                40.79637814242236
              ],
              [
                -73.80095706562146,
                40.79638677343277
              ],
              [
                -73.80096377690244,
                40.796401004407315
              ],
              [
                -73.80096720441102,
                40.796411878868895
              ],
              [
                -73.8009700623576,
                40.79642310431765
              ],
              [
                -73.8009717940961,
                40.796438452631314
              ],
              [
                -73.80097149820027,
                40.796450419127275
              ],
              [
                -73.80097218192778,
                40.796463126771684
              ],
              [
                -73.80097496351827,
                40.796476224519374
              ],
              [
                -73.80097937436197,
                40.79648726435072
              ],
              [
                -73.80098709475455,
                40.79650078391048
              ],
              [
                -73.80099724908189,
                40.79651295842262
              ],
              [
                -73.80101126230589,
                40.79652237458959
              ],
              [
                -73.80102604355339,
                40.796520725857825
              ],
              [
                -73.8010390964323,
                40.79651454358488
              ],
              [
                -73.80105234940854,
                40.7965048407752
              ],
              [
                -73.80105270487208,
                40.79650458122183
              ],
              [
                -73.80105697876785,
                40.79650221928735
              ],
              [
                -73.8010742560219,
                40.796497998652264
              ],
              [
                -73.80109174499097,
                40.7964939676837
              ],
              [
                -73.80111021893406,
                40.796489550925784
              ],
              [
                -73.80113832991796,
                40.796484520561144
              ],
              [
                -73.80116053429654,
                40.796476877996845
              ],
              [
                -73.8011758275835,
                40.79647036753788
              ],
              [
                -73.80119112074898,
                40.79646367531168
              ],
              [
                -73.8012057027607,
                40.796457577577264
              ],
              [
                -73.80122150671556,
                40.796450356512814
              ],
              [
                -73.80123853711241,
                40.79644168612127
              ],
              [
                -73.80125286385423,
                40.796433444286116
              ],
              [
                -73.8012635401182,
                40.79642542114169
              ],
              [
                -73.80127525576586,
                40.79642278438853
              ],
              [
                -73.80128784249827,
                40.79641788921872
              ],
              [
                -73.80130486581014,
                40.79641092220312
              ],
              [
                -73.80131994336858,
                40.7964054823428
              ],
              [
                -73.80133376247049,
                40.796400399821025
              ],
              [
                -73.8013497738193,
                40.79639554673248
              ],
              [
                -73.80136578377727,
                40.796390937388566
              ],
              [
                -73.80138133427633,
                40.796385831040695
              ],
              [
                -73.80139637317968,
                40.79638130009642
              ],
              [
                -73.80141216948559,
                40.796376851704906
              ],
              [
                -73.80142770205491,
                40.796373393769514
              ],
              [
                -73.80143779242482,
                40.796371187420405
              ],
              [
                -73.80145799689465,
                40.796366799889405
              ],
              [
                -73.80147573299986,
                40.7963634807684
              ],
              [
                -73.80149792583289,
                40.796359683496355
              ],
              [
                -73.8015146919568,
                40.79635608326664
              ],
              [
                -73.80153413694384,
                40.796352307054654
              ],
              [
                -73.80154742752093,
                40.79634977350082
              ],
              [
                -73.80156322101915,
                40.79634626104799
              ],
              [
                -73.80157800485,
                40.796343432033694
              ],
              [
                -73.80159820789405,
                40.796339738868284
              ],
              [
                -73.80161742295034,
                40.79633729507837
              ],
              [
                -73.80163169501581,
                40.796335320393375
              ],
              [
                -73.8016533732407,
                40.7963335489276
              ],
              [
                -73.80166959318859,
                40.79633406266106
              ],
              [
                -73.80168461858926,
                40.79633397978947
              ],
              [
                -73.8017037946004,
                40.79633228358363
              ],
              [
                -73.80171489307945,
                40.79633013272033
              ],
              [
                -73.80173361368551,
                40.79632635573126
              ],
              [
                -73.80174842418782,
                40.79632300523445
              ],
              [
                -73.80175550251785,
                40.796321295918034
              ],
              [
                -73.80172106831401,
                40.7965072183207
              ],
              [
                -73.80174543088519,
                40.79651240660561
              ],
              [
                -73.80175501879768,
                40.796476400118124
              ],
              [
                -73.80176584941816,
                40.79647744802096
              ],
              [
                -73.80177549860801,
                40.79642085712563
              ],
              [
                -73.80176331229937,
                40.79641980689219
              ],
              [
                -73.80177947698411,
                40.79631614185259
              ],
              [
                -73.80179620718019,
                40.796312901699785
              ],
              [
                -73.80181347984326,
                40.79630996871343
              ],
              [
                -73.8018216132067,
                40.79630855102591
              ],
              [
                -73.80184892695604,
                40.79630418553339
              ],
              [
                -73.80186594983456,
                40.79630163038208
              ],
              [
                -73.80188195549098,
                40.79629866890624
              ],
              [
                -73.80189745097366,
                40.79629573285276
              ],
              [
                -73.8019120260175,
                40.796291498066516
              ],
              [
                -73.8019302375867,
                40.79628809844296
              ],
              [
                -73.80194750952698,
                40.79628555241896
              ],
              [
                -73.80196868129457,
                40.796282401122504
              ],
              [
                -73.80198985243008,
                40.79627960983293
              ],
              [
                -73.80201128804933,
                40.796275424177736
              ],
              [
                -73.80202829757927,
                40.79627349853986
              ],
              [
                -73.80204134721818,
                40.796272286960516
              ],
              [
                -73.80206005228753,
                40.796270239088535
              ],
              [
                -73.80207924242715,
                40.796268155861
              ],
              [
                -73.80209452071918,
                40.796266642871146
              ],
              [
                -73.80211027052047,
                40.7962656521952
              ],
              [
                -73.80213046639899,
                40.79626442590275
              ],
              [
                -73.8021481721449,
                40.79626343908189
              ],
              [
                -73.80216835248211,
                40.79626284298654
              ],
              [
                -73.80218656535925,
                40.796262631164865
              ],
              [
                -73.80220302420824,
                40.796262713687966
              ],
              [
                -73.80222445003956,
                40.79626203897285
              ],
              [
                -73.80224143004783,
                40.796261959382555
              ],
              [
                -73.80226038840061,
                40.7962622093554
              ],
              [
                -73.80227659618754,
                40.79626273119138
              ],
              [
                -73.80229602831757,
                40.796263260230724
              ],
              [
                -73.80231448761299,
                40.796263950097966
              ],
              [
                -73.80233538569176,
                40.79626513817683
              ],
              [
                -73.8023535941851,
                40.79626640288978
              ],
              [
                -73.80236872599959,
                40.79626648261113
              ],
              [
                -73.80239061099638,
                40.7962666276839
              ],
              [
                -73.80240609420619,
                40.79626811337311
              ],
              [
                -73.80242602952,
                40.79627045252707
              ],
              [
                -73.80243877245933,
                40.79627275254617
              ],
              [
                -73.80245599232745,
                40.796275637696525
              ],
              [
                -73.80247562580497,
                40.796279705521485
              ],
              [
                -73.8024952957972,
                40.796283800377076
              ],
              [
                -73.80251298962955,
                40.79628712658777
              ],
              [
                -73.80252994857635,
                40.79628983889648
              ],
              [
                -73.80254565454585,
                40.79629218016549
              ],
              [
                -73.80256042483781,
                40.796293897807836
              ],
              [
                -73.80257934475026,
                40.79629544313612
              ],
              [
                -73.80259127882732,
                40.7963023791901
              ],
              [
                -73.80259458984929,
                40.796316793046735
              ],
              [
                -73.80259968008895,
                40.796330669848665
              ],
              [
                -73.80260474280807,
                40.79634171073393
              ],
              [
                -73.80261322240626,
                40.79635503447497
              ],
              [
                -73.80262272892377,
                40.796366091177894
              ],
              [
                -73.80262670946287,
                40.796362278415785
              ],
              [
                -73.8026308623715,
                40.79634828409239
              ],
              [
                -73.80263479739018,
                40.79633989797722
              ],
              [
                -73.80264036022909,
                40.796338097055646
              ],
              [
                -73.802660748789,
                40.79634356162805
              ],
              [
                -73.80267352024784,
                40.79634822092957
              ],
              [
                -73.80269289795285,
                40.796354701482315
              ],
              [
                -73.80271057416486,
                40.79635959432951
              ],
              [
                -73.8027277563663,
                40.79636352289023
              ],
              [
                -73.80274520035988,
                40.79636681295466
              ],
              [
                -73.8027626748621,
                40.796368139503954
              ],
              [
                -73.80277867985394,
                40.79636539417764
              ],
              [
                -73.80279125662157,
                40.79636382152483
              ],
              [
                -73.80280674002948,
                40.79636502772807
              ],
              [
                -73.80282883359777,
                40.79637082839545
              ],
              [
                -73.80284305054556,
                40.7963752475741
              ],
              [
                -73.80285483860483,
                40.796380291654515
              ],
              [
                -73.80286932091553,
                40.79638338783343
              ],
              [
                -73.80288996006087,
                40.796388286223
              ],
              [
                -73.8029054106603,
                40.796392535119985
              ],
              [
                -73.80291966493796,
                40.79639678314213
              ],
              [
                -73.80293438234197,
                40.796400292999884
              ],
              [
                -73.80294925160216,
                40.796404929555884
              ],
              [
                -73.80295830316544,
                40.79640959134531
              ],
              [
                -73.8029649323685,
                40.796595663874754
              ],
              [
                -73.8029716822265,
                40.79668512306075
              ],
              [
                -73.8029997067683,
                40.79670883609422
              ],
              [
                -73.80305684072003,
                40.79671035601753
              ],
              [
                -73.8030776116352,
                40.796710616658935
              ],
              [
                -73.80309631998239,
                40.79671144118146
              ],
              [
                -73.80311476899284,
                40.79671155394543
              ],
              [
                -73.80313149084127,
                40.7967109242997
              ],
              [
                -73.8031452609785,
                40.79671037253552
              ],
              [
                -73.80316298729149,
                40.79671059110623
              ],
              [
                -73.80318267007644,
                40.79671019266841
              ],
              [
                -73.80319964931766,
                40.79671041062798
              ],
              [
                -73.80321980170481,
                40.79671170582938
              ],
              [
                -73.80323948126862,
                40.796712397128815
              ],
              [
                -73.80325865101189,
                40.79671306879196
              ],
              [
                -73.80327956562543,
                40.796713041331024
              ],
              [
                -73.8032975542383,
                40.79671252254703
              ],
              [
                -73.80331622699072,
                40.79671338332618
              ],
              [
                -73.80333421283683,
                40.79671380099854
              ],
              [
                -73.80334996362961,
                40.79671302642994
              ],
              [
                -73.80335968645421,
                40.79671076500758
              ],
              [
                -73.80337425529169,
                40.796704675018
              ],
              [
                -73.80338930348425,
                40.79669690238192
              ],
              [
                -73.8034012144535,
                40.796688053906195
              ],
              [
                -73.80341320056758,
                40.79667764539373
              ],
              [
                -73.80342210240818,
                40.79666890821379
              ],
              [
                -73.80343133110553,
                40.79665324659523
              ],
              [
                -73.80343461451515,
                40.79664109521493
              ],
              [
                -73.80343746756938,
                40.79662970885926
              ],
              [
                -73.80343933812479,
                40.796618320827854
              ],
              [
                -73.80344024312643,
                40.79660485065267
              ],
              [
                -73.80344123052014,
                40.796591398208086
              ],
              [
                -73.80344369578071,
                40.79657532048094
              ],
              [
                -73.80344553835042,
                40.79656094173905
              ],
              [
                -73.80344742272665,
                40.796548977946415
              ],
              [
                -73.80344894059498,
                40.79653646287301
              ],
              [
                -73.8034504973114,
                40.79652318378255
              ],
              [
                -73.80345417357385,
                40.79651012340886
              ],
              [
                -73.80346385689631,
                40.79649726274882
              ],
              [
                -73.80347562877238,
                40.79648705321946
              ],
              [
                -73.80348822878426,
                40.79647790721151
              ],
              [
                -73.80350403526204,
                40.79646974886958
              ],
              [
                -73.8035168721132,
                40.796464529386455
              ],
              [
                -73.80352920378782,
                40.79645794052621
              ],
              [
                -73.80354081032111,
                40.79645183792744
              ],
              [
                -73.80355708604628,
                40.79644519262939
              ],
              [
                -73.80357165469471,
                40.79643958910802
              ],
              [
                -73.80358525110181,
                40.796433795127015
              ],
              [
                -73.80359559638315,
                40.79642914834672
              ],
              [
                -73.80361288546553,
                40.796420856820504
              ],
              [
                -73.80359378518052,
                40.7966040826035
              ],
              [
                -73.80358565890843,
                40.79660406875651
              ],
              [
                -73.80357594508133,
                40.79668330258233
              ],
              [
                -73.80361250744673,
                40.79668439415803
              ],
              [
                -73.80361815268297,
                40.79660721194895
              ],
              [
                -73.80360867810485,
                40.79660513725489
              ],
              [
                -73.8036430150915,
                40.796406085507584
              ],
              [
                -73.80365958021285,
                40.79639832580685
              ],
              [
                -73.80367585440008,
                40.79639241776835
              ],
              [
                -73.80369410956779,
                40.79638594820594
              ],
              [
                -73.803713847762,
                40.796378984956064
              ],
              [
                -73.80373406114016,
                40.79637130114762
              ],
              [
                -73.80375800294107,
                40.796361328586066
              ],
              [
                -73.80377084078584,
                40.79635553379427
              ],
              [
                -73.80378346523015,
                40.79634981821225
              ],
              [
                -73.80379875661363,
                40.796343856383096
              ],
              [
                -73.80381452007485,
                40.796338227893145
              ],
              [
                -73.80382294195988,
                40.79633501284092
              ],
              [
                -73.80380261038667,
                40.79651489728155
              ],
              [
                -73.80379312974867,
                40.79651488114385
              ],
              [
                -73.80377383371204,
                40.79662909019768
              ],
              [
                -73.80381716794693,
                40.79663019323661
              ],
              [
                -73.80382563258883,
                40.7965149364661
              ],
              [
                -73.80381886202727,
                40.79651492494286
              ],
              [
                -73.80384612412581,
                40.796325854649226
              ],
              [
                -73.80386141775243,
                40.79631912755979
              ],
              [
                -73.8038740040376,
                40.79631431268667
              ],
              [
                -73.80388881964235,
                40.79630904275337
              ],
              [
                -73.80390708639229,
                40.796302293242064
              ],
              [
                -73.80392534169847,
                40.79629598513761
              ],
              [
                -73.80394308744964,
                40.79628934228563
              ],
              [
                -73.80395838852573,
                40.79628427135159
              ],
              [
                -73.80397418925072,
                40.79627825591965
              ],
              [
                -73.80393114463229,
                40.79655731294737
              ],
              [
                -73.80395823064364,
                40.79655735901795
              ],
              [
                -73.80400524331003,
                40.79626735893536
              ],
              [
                -73.80402201336017,
                40.7962621460838
              ],
              [
                -73.80403683589735,
                40.79625840715736
              ],
              [
                -73.80404987656715,
                40.79625625987385
              ],
              [
                -73.80406711935908,
                40.7962516780498
              ],
              [
                -73.80407969967965,
                40.79624843101396
              ],
              [
                -73.80410066074184,
                40.79624462997462
              ],
              [
                -73.80411471611377,
                40.7962399269662
              ],
              [
                -73.80412813146489,
                40.7962354022881
              ],
              [
                -73.8041364476697,
                40.79623423117777
              ],
              [
                -73.8040923878819,
                40.796529798342384
              ],
              [
                -73.80412218500379,
                40.796528819706644
              ],
              [
                -73.80416535642863,
                40.79622900877676
              ],
              [
                -73.80417716911634,
                40.796225705477816
              ],
              [
                -73.80419839289532,
                40.796220941596296
              ],
              [
                -73.80421143335055,
                40.796218713044354
              ],
              [
                -73.80423480566085,
                40.79621705101944
              ],
              [
                -73.80425351934029,
                40.79621556987958
              ],
              [
                -73.80426782617938,
                40.7962142531345
              ],
              [
                -73.8042899654997,
                40.79621261530619
              ],
              [
                -73.80431138699923,
                40.79621287616002
              ],
              [
                -73.8042673271141,
                40.796449816387295
              ],
              [
                -73.8042429505587,
                40.79644977499081
              ],
              [
                -73.80424147814796,
                40.79648991206664
              ],
              [
                -73.80425907810624,
                40.79649200050714
              ],
              [
                -73.8042356338882,
                40.79663502181065
              ],
              [
                -73.80427353614904,
                40.79664126132841
              ],
              [
                -73.80429293553246,
                40.79649205799797
              ],
              [
                -73.80431189289473,
                40.79649311945955
              ],
              [
                -73.80432012674352,
                40.79645608118632
              ],
              [
                -73.80429711363742,
                40.79645295479133
              ],
              [
                -73.80433026988077,
                40.796230700749454
              ],
              [
                -73.80434380665973,
                40.796232782279766
              ],
              [
                -73.804348259354,
                40.79621409099278
              ],
              [
                -73.80436129307107,
                40.796214303089265
              ],
              [
                -73.80438593705851,
                40.79621107599252
              ],
              [
                -73.80440169427989,
                40.79620761854028
              ],
              [
                -73.804416012096,
                40.7962025639697
              ],
              [
                -73.80443231494576,
                40.79619496403454
              ],
              [
                -73.80444884031962,
                40.79618845405958
              ],
              [
                -73.80446363544613,
                40.79618195052848
              ],
              [
                -73.80447943665723,
                40.796175745724426
              ],
              [
                -73.80449870742996,
                40.7961662870946
              ],
              [
                -73.80451994706743,
                40.79615640863171
              ],
              [
                -73.80453769015642,
                40.79615086464993
              ],
              [
                -73.80455768026067,
                40.79614634286223
              ],
              [
                -73.80457614516594,
                40.79614121355099
              ],
              [
                -73.80459327389869,
                40.796138518125574
              ],
              [
                -73.80460953451075,
                40.796135457863315
              ],
              [
                -73.80462441010651,
                40.79614268700406
              ],
              [
                -73.80464873976852,
                40.79615919598311
              ],
              [
                -73.80462921073267,
                40.7963526563424
              ],
              [
                -73.80459395457137,
                40.79636803404015
              ],
              [
                -73.8046343775328,
                40.796438088956265
              ],
              [
                -73.80466149435813,
                40.79642784265905
              ],
              [
                -73.80462782077647,
                40.79636500362028
              ],
              [
                -73.80464949613757,
                40.796362981806
              ],
              [
                -73.8046717376879,
                40.79616746916408
              ],
              [
                -73.8047264413334,
                40.79617370613077
              ],
              [
                -73.8047817239482,
                40.79617516310488
              ],
              [
                -73.80483686471369,
                40.79617182108918
              ],
              [
                -73.80489114466053,
                40.796163723659596
              ],
              [
                -73.80492191157646,
                40.796153278801825
              ],
              [
                -73.80495432127336,
                40.7961462677861
              ],
              [
                -73.80498771399782,
                40.79614283333299
              ],
              [
                -73.80501360845618,
                40.7961426707588
              ],
              [
                -73.80503937067661,
                40.79614466365112
              ],
              [
                -73.80505068945472,
                40.796152147892634
              ],
              [
                -73.80506295214927,
                40.79615669801512
              ],
              [
                -73.80507987209377,
                40.79616484943091
              ],
              [
                -73.80509556517517,
                40.796171387008144
              ],
              [
                -73.80511571013656,
                40.79617517531318
              ],
              [
                -73.80513069275192,
                40.796177344298535
              ],
              [
                -73.80514545931521,
                40.796176433298086
              ],
              [
                -73.80516468832593,
                40.79617352891593
              ],
              [
                -73.80518217470588,
                40.79617056948503
              ],
              [
                -73.80520139929341,
                40.79616450489099
              ],
              [
                -73.8052225881337,
                40.796159821844114
              ],
              [
                -73.80523689628167,
                40.79615782097504
              ],
              [
                -73.80525954163315,
                40.79615703032125
              ],
              [
                -73.80528046127395,
                40.79615552526747
              ],
              [
                -73.80529962989374,
                40.796156683585735
              ],
              [
                -73.80531364094746,
                40.79615895845738
              ],
              [
                -73.80533377312027,
                40.796162675336944
              ],
              [
                -73.8053394448758,
                40.79616392828877
              ],
              [
                -73.80532433939833,
                40.7962251795309
              ],
              [
                -73.80532975387251,
                40.796226217951194
              ],
              [
                -73.805324261141,
                40.79625194006578
              ],
              [
                -73.80531071962977,
                40.7962519171942
              ],
              [
                -73.8053010493916,
                40.79631674087687
              ],
              [
                -73.80534980160489,
                40.79631785131889
              ],
              [
                -73.80535269325873,
                40.79625507423447
              ],
              [
                -73.80534456769023,
                40.7962550605126
              ],
              [
                -73.80535141713,
                40.79622831258501
              ],
              [
                -73.805358191995,
                40.796227294749826
              ],
              [
                -73.80536638630139,
                40.796168134276705
              ],
              [
                -73.80537703025449,
                40.796168565701926
              ],
              [
                -73.80539573274972,
                40.7961708215133
              ],
              [
                -73.80540153443536,
                40.79617656838161
              ],
              [
                -73.8054090101802,
                40.79618931326416
              ],
              [
                -73.80541756419926,
                40.796201871668075
              ],
              [
                -73.80542724845094,
                40.79621275773087
              ],
              [
                -73.80544014211031,
                40.79622438699183
              ],
              [
                -73.80545166035743,
                40.7962360496127
              ],
              [
                -73.80546459835206,
                40.796248804213405
              ],
              [
                -73.80547629446686,
                40.79626062963154
              ],
              [
                -73.80548783217695,
                40.796270086642885
              ],
              [
                -73.80550072914782,
                40.79628058996453
              ],
              [
                -73.80551279570177,
                40.796291479537345
              ],
              [
                -73.80552400234379,
                40.79630047646424
              ],
              [
                -73.80553718556862,
                40.79631041319146
              ],
              [
                -73.80555059305485,
                40.79632047660956
              ],
              [
                -73.80556324373815,
                40.796330024949285
              ],
              [
                -73.80557448693745,
                40.79633866125079
              ],
              [
                -73.80558573126643,
                40.796346911742305
              ],
              [
                -73.80560188750287,
                40.796357402457
              ],
              [
                -73.80561731327747,
                40.796366208307894
              ],
              [
                -73.80563127343524,
                40.796373778196134
              ],
              [
                -73.80564280467912,
                40.79638123593327
              ],
              [
                -73.80565946003267,
                40.79639073656898
              ],
              [
                -73.80567684032862,
                40.79640004091207
              ],
              [
                -73.80569155923298,
                40.7964073157198
              ],
              [
                -73.80570700476176,
                40.79641359095395
              ],
              [
                -73.80572099337196,
                40.79641957608947
              ],
              [
                -73.80573520356035,
                40.79642638062804
              ],
              [
                -73.80575309750407,
                40.796434172903886
              ],
              [
                -73.80577123952722,
                40.79644216427995
              ],
              [
                -73.80578794170157,
                40.79644779279519
              ],
              [
                -73.80580191197902,
                40.796452661836106
              ],
              [
                -73.80581764463896,
                40.79645731656383
              ],
              [
                -73.80582991150861,
                40.796460245636155
              ],
              [
                -73.80585101869465,
                40.796467251082035
              ],
              [
                -73.80586819986424,
                40.79647153047322
              ],
              [
                -73.80589130613835,
                40.79647569369315
              ],
              [
                -73.80591588252298,
                40.79647910283949
              ],
              [
                -73.80593700219839,
                40.79648205602015
              ],
              [
                -73.80595714687374,
                40.79648576410019
              ],
              [
                -73.8059782594834,
                40.79649068903468
              ],
              [
                -73.80600089255304,
                40.79649437549669
              ],
              [
                -73.80602544709858,
                40.79649756730248
              ],
              [
                -73.80606112922905,
                40.79650076174372
              ],
              [
                -73.80608423063069,
                40.796502367307426
              ],
              [
                -73.80610831639821,
                40.796503560064664
              ],
              [
                -73.806127995381,
                40.796504439671104
              ],
              [
                -73.80614472276086,
                40.79650581872914
              ],
              [
                -73.8061661323947,
                40.79650678248944
              ],
              [
                -73.80617916661214,
                40.79650638994971
              ],
              [
                -73.80619840214536,
                40.79650126217743
              ],
              [
                -73.80621099652869,
                40.79649346745747
              ],
              [
                -73.80622009174196,
                40.79648321629293
              ],
              [
                -73.80622875229942,
                40.79647112882171
              ],
              [
                -73.80623626670251,
                40.796458237311825
              ],
              [
                -73.8062423354427,
                40.79644572985238
              ],
              [
                -73.8062487657038,
                40.79643473659162
              ],
              [
                -73.80625797464138,
                40.796421685931605
              ],
              [
                -73.8062674235055,
                40.79641201231491
              ],
              [
                -73.80628026898333,
                40.79640374977601
              ],
              [
                -73.80629654242198,
                40.79639809500014
              ],
              [
                -73.8063152984311,
                40.796394416166784
              ],
              [
                -73.80633076714673,
                40.796392740435934
              ],
              [
                -73.80629034788133,
                40.79655512619507
              ],
              [
                -73.80629846516301,
                40.796558227663326
              ],
              [
                -73.8062833766485,
                40.79662407326925
              ],
              [
                -73.80627525037363,
                40.79662405961188
              ],
              [
                -73.80623135012367,
                40.79681747758278
              ],
              [
                -73.80627331882216,
                40.79682269333237
              ],
              [
                -73.80631451940101,
                40.796626182479905
              ],
              [
                -73.80630503940787,
                40.79662616654992
              ],
              [
                -73.80632013155257,
                40.79655929334696
              ],
              [
                -73.80632825394339,
                40.796560336270296
              ],
              [
                -73.8063633145256,
                40.796393605437714
              ],
              [
                -73.8064194096874,
                40.79640630342859
              ],
              [
                -73.80642626151342,
                40.79640785415845
              ],
              [
                -73.8070431672326,
                40.7965474877634
              ],
              [
                -73.80739395120523,
                40.796608437176744
              ],
              [
                -73.8074116102241,
                40.796599451307415
              ],
              [
                -73.80742760562904,
                40.79659966750282
              ],
              [
                -73.80744754051358,
                40.79660224953637
              ],
              [
                -73.8074654442394,
                40.79660659255057
              ],
              [
                -73.80747196889736,
                40.79660817064827
              ],
              [
                -73.80767740565277,
                40.79667217825706
              ],
              [
                -73.8076775124608,
                40.79667221478829
              ],
              [
                -73.80778748187672,
                40.79668633750116
              ],
              [
                -73.80810995368086,
                40.79673044142706
              ],
              [
                -73.80812163219092,
                40.796711550777054
              ],
              [
                -73.8081455680239,
                40.796715624294364
              ],
              [
                -73.80816130936734,
                40.79671764974123
              ],
              [
                -73.80817557123811,
                40.796719402672906
              ],
              [
                -73.8081917791541,
                40.79672039258044
              ],
              [
                -73.80820630829253,
                40.79671989458029
              ],
              [
                -73.80822205447603,
                40.796720164529006
              ],
              [
                -73.80823779769253,
                40.796722000162326
              ],
              [
                -73.80825500891919,
                40.79672395600586
              ],
              [
                -73.80827762062165,
                40.796727172076245
              ],
              [
                -73.80829626784501,
                40.796732174907355
              ],
              [
                -73.80831687898839,
                40.79673893482402
              ],
              [
                -73.80833282483468,
                40.796744202705135
              ],
              [
                -73.80834682797352,
                40.796748999120624
              ],
              [
                -73.80836180477306,
                40.79675380536189
              ],
              [
                -73.8083802093966,
                40.79675701492001
              ],
              [
                -73.80839646373336,
                40.79675773465751
              ],
              [
                -73.80841169801577,
                40.79675939971804
              ],
              [
                -73.8084279398142,
                40.796760723358936
              ],
              [
                -73.80844759279134,
                40.79676240346453
              ],
              [
                -73.80845913952308,
                40.79676461120349
              ],
              [
                -73.80847733110448,
                40.79676755034129
              ],
              [
                -73.80850091691467,
                40.79677009269178
              ],
              [
                -73.80851716669386,
                40.79676833671538
              ],
              [
                -73.80853392846265,
                40.79676638340486
              ],
              [
                -73.80855019494895,
                40.79676303328068
              ],
              [
                -73.80856891135323,
                40.79676062385701
              ],
              [
                -73.80858291373146,
                40.796761935380665
              ],
              [
                -73.80860184495185,
                40.79676339848872
              ],
              [
                -73.80861440179191,
                40.79676451780218
              ],
              [
                -73.80863317201425,
                40.79676869186755
              ],
              [
                -73.80864789968977,
                40.79677305572622
              ],
              [
                -73.80866756808085,
                40.796777889484176
              ],
              [
                -73.80868351883343,
                40.79678161642689
              ],
              [
                -73.80869968005003,
                40.79679035004336
              ],
              [
                -73.8087136659147,
                40.796797181315696
              ],
              [
                -73.80872936734276,
                40.79680098959069
              ],
              [
                -73.80874548028396,
                40.79680159328424
              ],
              [
                -73.80875743667507,
                40.79680178383053
              ],
              [
                -73.80876898946093,
                40.79680165976346
              ],
              [
                -73.80878378171906,
                40.79679605478256
              ],
              [
                -73.80879338042196,
                40.79679596315428
              ],
              [
                -73.80880688040418,
                40.79679889445736
              ],
              [
                -73.80882409683717,
                40.79680342542364
              ],
              [
                -73.80884103043104,
                40.796807270907344
              ],
              [
                -73.80886090939916,
                40.79681270773756
              ],
              [
                -73.80887516404648,
                40.796816990035474
              ],
              [
                -73.80889110948904,
                40.796822420337996
              ],
              [
                -73.80890997785617,
                40.79682912348328
              ],
              [
                -73.80892546162436,
                40.7968345804899
              ],
              [
                -73.80894310831127,
                40.79684166858798
              ],
              [
                -73.80895904858392,
                40.796848827566436
              ],
              [
                -73.80897401932314,
                40.79685530796596
              ],
              [
                -73.80898577195752,
                40.79686028937491
              ],
              [
                -73.80900391810268,
                40.79686701928463
              ],
              [
                -73.80901965126861,
                40.79687207044294
              ],
              [
                -73.80903341023478,
                40.796875714131126
              ],
              [
                -73.80905354429485,
                40.79687915093073
              ],
              [
                -73.80907072097281,
                40.796885104754175
              ],
              [
                -73.80907143110211,
                40.79688538150973
              ],
              [
                -73.8090861670189,
                40.796891118195774
              ],
              [
                -73.80909745606357,
                40.79689656655615
              ],
              [
                -73.80911535048463,
                40.796904142536754
              ],
              [
                -73.8091322623125,
                40.7969113655869
              ],
              [
                -73.80914110119411,
                40.79691580205357
              ],
              [
                -73.80915996584244,
                40.79692382039277
              ],
              [
                -73.80917912794055,
                40.79693150668355
              ],
              [
                -73.80919456028478,
                40.79693823156787
              ],
              [
                -73.80921025340808,
                40.79694495855694
              ],
              [
                -73.80922767374307,
                40.796952668258186
              ],
              [
                -73.80924387840999,
                40.79695881745693
              ],
              [
                -73.80925513734745,
                40.79696193379955
              ],
              [
                -73.80927281774164,
                40.79696605218141
              ],
              [
                -73.8092900334429,
                40.796970556775186
              ],
              [
                -73.8093123774852,
                40.79697622041442
              ],
              [
                -73.80932760703666,
                40.79697910074196
              ],
              [
                -73.80934627316799,
                40.79698209480544
              ],
              [
                -73.8093693804077,
                40.796986057973754
              ],
              [
                -73.80939391855173,
                40.79699101659363
              ],
              [
                -73.80941753243617,
                40.79699588137356
              ],
              [
                -73.80943669452826,
                40.79699950597026
              ],
              [
                -73.80945387858829,
                40.797002749524175
              ],
              [
                -73.80947254416617,
                40.79700594124622
              ],
              [
                -73.80949441860659,
                40.7970097870942
              ],
              [
                -73.80951409062794,
                40.797013475007546
              ],
              [
                -73.80952268958546,
                40.79701464145677
              ],
              [
                -73.80954753413428,
                40.79701583541887
              ],
              [
                -73.80956558817455,
                40.7970174230656
              ],
              [
                -73.80958622616353,
                40.797022734207665
              ],
              [
                -73.80960244412228,
                40.79702402246318
              ],
              [
                -73.80962038294969,
                40.79702473609908
              ],
              [
                -73.80963736095772,
                40.79702572808021
              ],
              [
                -73.80965361506765,
                40.797026708979345
              ],
              [
                -73.80966905877175,
                40.79702956214117
              ],
              [
                -73.80968479427642,
                40.797033594825805
              ],
              [
                -73.80969905185482,
                40.797036886784404
              ],
              [
                -73.8097130241487,
                40.7970404220194
              ],
              [
                -73.80972973014313,
                40.797045114004426
              ],
              [
                -73.80974398905943,
                40.797047939401544
              ],
              [
                -73.80976459797381,
                40.79705132207974
              ],
              [
                -73.80978340759057,
                40.79705387487999
              ],
              [
                -73.80980455944795,
                40.79705764643555
              ],
              [
                -73.80982174290129,
                40.79706126820573
              ],
              [
                -73.80983795394502,
                40.79706505907744
              ],
              [
                -73.80985762672962,
                40.79706841489821
              ],
              [
                -73.80987113560074,
                40.797067806448744
              ],
              [
                -73.80988984361326,
                40.797069043320185
              ],
              [
                -73.80990138968068,
                40.797071287266284
              ],
              [
                -73.80992174394906,
                40.79707630906659
              ],
              [
                -73.80993600151355,
                40.79707984474497
              ],
              [
                -73.80994974455959,
                40.797084541696364
              ],
              [
                -73.80996298971401,
                40.79708974944757
              ],
              [
                -73.80998041307761,
                40.79709644182592
              ],
              [
                -73.80999759495545,
                40.79710062243163
              ],
              [
                -73.81001236523934,
                40.79710278105009
              ],
              [
                -73.81003051943543,
                40.79710644007199
              ],
              [
                -73.8100462543706,
                40.79711099538527
              ],
              [
                -73.81006343385967,
                40.79711609317825
              ],
              [
                -73.81007743107779,
                40.79711924963545
              ],
              [
                -73.81009215859119,
                40.79712388503484
              ],
              [
                -73.81010933372707,
                40.79713027945967
              ],
              [
                -73.81012747528214,
                40.79713868410242
              ],
              [
                -73.81014464461191,
                40.79714680770858
              ],
              [
                -73.81015958378141,
                40.7971522087035
              ],
              [
                -73.8101797102143,
                40.79715844715426
              ],
              [
                -73.81019887882915,
                40.797163835341074
              ],
              [
                -73.8102190260215,
                40.797167047474325
              ],
              [
                -73.81023523139702,
                40.7971686678228
              ],
              [
                -73.81025271421358,
                40.79717076001287
              ],
              [
                -73.8102723533939,
                40.79717378132689
              ],
              [
                -73.8102841476247,
                40.79717613269167
              ],
              [
                -73.810302809205,
                40.79718099031156
              ],
              [
                -73.81032099957967,
                40.79718473059691
              ],
              [
                -73.81033966453865,
                40.79718793976743
              ],
              [
                -73.81035735861853,
                40.79719139979826
              ],
              [
                -73.81037948239039,
                40.79719528090105
              ],
              [
                -73.81039814806662,
                40.797198472473106
              ],
              [
                -73.81041629934923,
                40.797203248488
              ],
              [
                -73.8104354777112,
                40.79720522499854
              ],
              [
                -73.81045391994175,
                40.797208002239095
              ],
              [
                -73.81047334442172,
                40.797211104415624
              ],
              [
                -73.81048756667118,
                40.79721390168358
              ],
              [
                -73.81050672905847,
                40.79721769731316
              ],
              [
                -73.81052518125497,
                40.79722077040845
              ],
              [
                -73.81054262321187,
                40.7972209615776
              ],
              [
                -73.81056081979685,
                40.79722245063018
              ],
              [
                -73.81058024354648,
                40.79722558026155
              ],
              [
                -73.81059809021171,
                40.79722539281021
              ],
              [
                -73.8106126016818,
                40.79722687563175
              ],
              [
                -73.81063275189202,
                40.79722896293279
              ],
              [
                -73.81065535249127,
                40.79723161027191
              ],
              [
                -73.81066347858139,
                40.797232722586074
              ],
              [
                -73.81067802496406,
                40.797234332440965
              ],
              [
                -73.81068632861088,
                40.79723525456882
              ],
              [
                -73.8107045214169,
                40.79723784255846
              ],
              [
                -73.81072835591264,
                40.79724061872637
              ],
              [
                -73.81074752214538,
                40.797242603876306
              ],
              [
                -73.81076326452711,
                40.79724480085002
              ],
              [
                -73.81077875780143,
                40.79724669050631
              ],
              [
                -73.8108001282021,
                40.797248534356505
              ],
              [
                -73.81081587345116,
                40.79724995618817
              ],
              [
                -73.81083136757358,
                40.79725154831375
              ],
              [
                -73.81084834187374,
                40.797253639076125
              ],
              [
                -73.8108694624798,
                40.79725619448615
              ],
              [
                -73.81088570261545,
                40.79725808535347
              ],
              [
                -73.81090487891294,
                40.7972605662012
              ],
              [
                -73.81091914145856,
                40.79726214758326
              ],
              [
                -73.81093779801479,
                40.79726476259825
              ],
              [
                -73.81095993501873,
                40.79726804102266
              ],
              [
                -73.8109844753117,
                40.797272087969205
              ],
              [
                -73.81100906400286,
                40.79727593798021
              ],
              [
                -73.81102256650476,
                40.79727804999617
              ],
              [
                -73.81103806106402,
                40.797279802919014
              ],
              [
                -73.81105405315587,
                40.79728155833045
              ],
              [
                -73.81109705208625,
                40.79728642823878
              ],
              [
                -73.81112261529012,
                40.797289217041985
              ],
              [
                -73.81114817783076,
                40.797292473569144
              ],
              [
                -73.81117128896017,
                40.797295140908886
              ],
              [
                -73.81119587931884,
                40.79729809881049
              ],
              [
                -73.8112180055982,
                40.797301125209614
              ],
              [
                -73.81123815426196,
                40.797303850170955
              ],
              [
                -73.81125930899427,
                40.79730682002288
              ],
              [
                -73.81127847342105,
                40.7973094545772
              ],
              [
                -73.81129417812912,
                40.79731219972686
              ],
              [
                -73.81130991860967,
                40.79731507945582
              ],
              [
                -73.81132710244584,
                40.79731815151888
              ],
              [
                -73.81134724983879,
                40.797321561637474
              ],
              [
                -73.8113602775976,
                40.79732372661244
              ],
              [
                -73.81137601742242,
                40.797326606331275
              ],
              [
                -73.81139493127712,
                40.79733023069815
              ],
              [
                -73.81141359783149,
                40.79733314267484
              ],
              [
                -73.81142258808796,
                40.7973341750994
              ],
              [
                -73.81149731828836,
                40.79734417563081
              ],
              [
                -73.81160938202306,
                40.7973724094435
              ],
              [
                -73.81162840215558,
                40.797392783459706
              ],
              [
                -73.81164514802273,
                40.797433791867306
              ],
              [
                -73.811710363145,
                40.79757777248558
              ],
              [
                -73.81175853817825,
                40.797709765271314
              ],
              [
                -73.81175823993703,
                40.797714591690344
              ],
              [
                -73.81175703225773,
                40.79773068152684
              ],
              [
                -73.81175668823023,
                40.79773946029513
              ],
              [
                -73.81175563627973,
                40.79775122856859
              ],
              [
                -73.81175556681939,
                40.79776300715793
              ],
              [
                -73.8117570109539,
                40.797788808912664
              ],
              [
                -73.8117592958143,
                40.79781049661405
              ],
              [
                -73.81175995900935,
                40.79781911503005
              ],
              [
                -73.81176055294284,
                40.79782696874768
              ],
              [
                -73.81176151918584,
                40.797841378505
              ],
              [
                -73.81176123787368,
                40.79785276926218
              ],
              [
                -73.81176160152847,
                40.797866439751466
              ],
              [
                -73.81176303610033,
                40.79788307350154
              ],
              [
                -73.81176461603962,
                40.79789858272427
              ],
              [
                -73.81176638468267,
                40.79791019310403
              ],
              [
                -73.81176826706177,
                40.797923289618794
              ],
              [
                -73.8117696414228,
                40.79793564701999
              ],
              [
                -73.81177101400081,
                40.797949102881496
              ],
              [
                -73.81177191147192,
                40.79796257605991
              ],
              [
                -73.81177201960828,
                40.79797493022063
              ],
              [
                -73.81177216453939,
                40.79798651164723
              ],
              [
                -73.81177278320803,
                40.797998092674874
              ],
              [
                -73.81177454305899,
                40.798012891883666
              ],
              [
                -73.81177838321304,
                40.798025053453344
              ],
              [
                -73.81178616019908,
                40.79803218162078
              ],
              [
                -73.8117996525634,
                40.79803802029594
              ],
              [
                -73.81181683312202,
                40.79804232576845
              ],
              [
                -73.81183405253485,
                40.79804614430591
              ],
              [
                -73.81185173546726,
                40.798049054605336
              ],
              [
                -73.81187091028694,
                40.798052463546654
              ],
              [
                -73.81189104345013,
                40.798056395052456
              ],
              [
                -73.8119111878113,
                40.79806073332424
              ],
              [
                -73.81193328615508,
                40.79806524381367
              ],
              [
                -73.81195736238278,
                40.79807036195667
              ],
              [
                -73.81198045124523,
                40.798076414950366
              ],
              [
                -73.81199958946941,
                40.79808045404074
              ],
              [
                -73.81201828860804,
                40.79808440127799
              ],
              [
                -73.8120369507112,
                40.798089052225535
              ],
              [
                -73.81205366097402,
                40.79809261743933
              ],
              [
                -73.81206889293433,
                40.79809477654628
              ],
              [
                -73.81208560683427,
                40.798097054497056
              ],
              [
                -73.81210600578792,
                40.798098988157044
              ],
              [
                -73.81212492730536,
                40.798099676702854
              ],
              [
                -73.81214635499956,
                40.79809874818787
              ],
              [
                -73.81216552179895,
                40.79810086930032
              ],
              [
                -73.81218075829688,
                40.798105719358254
              ],
              [
                -73.81219425065645,
                40.7981113428845
              ],
              [
                -73.81221163774894,
                40.79811848550763
              ],
              [
                -73.81222614069394,
                40.79812275958522
              ],
              [
                -73.8122418447208,
                40.7981258279278
              ],
              [
                -73.81225365567187,
                40.798126946801766
              ],
              [
                -73.81227088457793,
                40.79812741562136
              ],
              [
                -73.8122932729213,
                40.79812580413541
              ],
              [
                -73.81231000662297,
                40.7981248954201
              ],
              [
                -73.81233166889061,
                40.798124821966624
              ],
              [
                -73.81234840845782,
                40.79812183225637
              ],
              [
                -73.81236614994538,
                40.79811680974788
              ],
              [
                -73.81238097565138,
                40.798111925724726
              ],
              [
                -73.81240168387897,
                40.79810534134322
              ],
              [
                -73.8124216396584,
                40.798100735714634
              ],
              [
                -73.81244209193719,
                40.7980967348191
              ],
              [
                -73.81245763606678,
                40.79809302345943
              ],
              [
                -73.81247216146885,
                40.798090111881145
              ],
              [
                -73.8124871944264,
                40.79808701115291
              ],
              [
                -73.8125044728914,
                40.79808248240446
              ],
              [
                -73.81251976853277,
                40.79807487148138
              ],
              [
                -73.81253971935718,
                40.79805918101208
              ],
              [
                -73.81254798974665,
                40.79804706445951
              ],
              [
                -73.81255231385722,
                40.79803474481425
              ],
              [
                -73.81255851915725,
                40.798023551987356
              ],
              [
                -73.81256509432681,
                40.798011432006646
              ],
              [
                -73.81256990139292,
                40.798000220488106
              ],
              [
                -73.81257581468292,
                40.79798735293792
              ],
              [
                -73.81258098164079,
                40.797974294702854
              ],
              [
                -73.81258390689372,
                40.797962332458596
              ],
              [
                -73.81258835898772,
                40.79794683188243
              ],
              [
                -73.81259374717058,
                40.79793114519963
              ],
              [
                -73.81259847522662,
                40.7979184744061
              ],
              [
                -73.8126028416929,
                40.797908017038665
              ],
              [
                -73.81260707611486,
                40.79789364305031
              ],
              [
                -73.81261238475768,
                40.79788130640776
              ],
              [
                -73.81261968125939,
                40.79786564041625
              ],
              [
                -73.81262644389636,
                40.79784995479338
              ],
              [
                -73.81263062884526,
                40.79784062340207
              ],
              [
                -73.81263727248698,
                40.79782515503303
              ],
              [
                -73.81263965611126,
                40.79782054827667
              ],
              [
                -73.81264333650182,
                40.79781339489852
              ],
              [
                -73.81264741968197,
                40.79780293807389
              ],
              [
                -73.81265367026252,
                40.79778801854782
              ],
              [
                -73.81265900839935,
                40.79777757197113
              ],
              [
                -73.81266528833807,
                40.797765065191136
              ],
              [
                -73.81267265880527,
                40.79775256051824
              ],
              [
                -73.81267734672261,
                40.797741374599944
              ],
              [
                -73.81268298891642,
                40.797728317132126
              ],
              [
                -73.81268833315593,
                40.79771562002929
              ],
              [
                -73.8126923703309,
                40.797704037693315
              ],
              [
                -73.81269573423664,
                40.79769207498587
              ],
              [
                -73.81269912076951,
                40.797680139789286
              ],
              [
                -73.81270208188423,
                40.797667798825074
              ],
              [
                -73.81270576732189,
                40.79765547713053
              ],
              [
                -73.81271176323354,
                40.797629911960534
              ],
              [
                -73.8127151199385,
                40.79761627499303
              ],
              [
                -73.81271721148526,
                40.79760562663118
              ],
              [
                -73.81272035923969,
                40.79759009762841
              ],
              [
                -73.81272266986312,
                40.79757758372581
              ],
              [
                -73.81272411198792,
                40.797566384323105
              ],
              [
                -73.81272522534644,
                40.797554039686005
              ],
              [
                -73.81272693129098,
                40.79754152597348
              ],
              [
                -73.81273007863845,
                40.797525826261285
              ],
              [
                -73.81273325798236,
                40.797512387187325
              ],
              [
                -73.81273820425042,
                40.797497825046264
              ],
              [
                -73.81274192974571,
                40.79748363919389
              ],
              [
                -73.81274572828687,
                40.797468327522594
              ],
              [
                -73.81274916904509,
                40.797454113733224
              ],
              [
                -73.81275271305223,
                40.79743712740036
              ],
              [
                -73.81275582015948,
                40.797423660734204
              ],
              [
                -73.81275869851312,
                40.797415264351635
              ],
              [
                -73.81276257472183,
                40.79739827855797
              ],
              [
                -73.81276405096054,
                40.79738732999527
              ],
              [
                -73.81276578266439,
                40.79737819396863
              ],
              [
                -73.81276881553268,
                40.79736549126415
              ],
              [
                -73.81277230577533,
                40.797354763420444
              ],
              [
                -73.81277515293642,
                40.79734538545605
              ],
              [
                -73.81278370953858,
                40.79733233221377
              ],
              [
                -73.81279234462082,
                40.797320766220274
              ],
              [
                -73.81280166284506,
                40.79730661575021
              ],
              [
                -73.81280889302121,
                40.79729317504367
              ],
              [
                -73.8128128585721,
                40.79728196097442
              ],
              [
                -73.81285643855088,
                40.79728728181292
              ],
              [
                -73.81286187927007,
                40.79727905644297
              ],
              [
                -73.8128748386123,
                40.797203390894595
              ],
              [
                -73.81288386452479,
                40.79716656522699
              ],
              [
                -73.81292025165689,
                40.797171796465584
              ],
              [
                -73.81292336146136,
                40.79716998285826
              ],
              [
                -73.8129291648328,
                40.797166605753
              ],
              [
                -73.81293284475183,
                40.797164459862834
              ],
              [
                -73.81293494789331,
                40.79716245481534
              ],
              [
                -73.81293578008048,
                40.79716166393873
              ],
              [
                -73.81294054438418,
                40.797161311658634
              ],
              [
                -73.81304287555434,
                40.797178206756236
              ],
              [
                -73.81293369674125,
                40.79742600809672
              ],
              [
                -73.81305279469925,
                40.797455018850826
              ],
              [
                -73.813159963843,
                40.797197536205594
              ],
              [
                -73.81324649695341,
                40.79721182110076
              ],
              [
                -73.81325099657049,
                40.79719635910537
              ],
              [
                -73.81327739074666,
                40.79710575644099
              ],
              [
                -73.81336974991873,
                40.797074559522805
              ],
              [
                -73.8134935036856,
                40.79705838037774
              ],
              [
                -73.81337822710192,
                40.79731420176152
              ],
              [
                -73.81348648309174,
                40.79734593975082
              ],
              [
                -73.81335013720098,
                40.7976723226576
              ],
              [
                -73.81342050415856,
                40.79769302041066
              ],
              [
                -73.81369963725258,
                40.79707941566509
              ],
              [
                -73.813716577534,
                40.79710210837837
              ],
              [
                -73.81374596947316,
                40.79714324513879
              ],
              [
                -73.8137491075235,
                40.79714763685915
              ],
              [
                -73.81374391110171,
                40.797158173174516
              ],
              [
                -73.8137349711503,
                40.79717681685778
              ],
              [
                -73.81372970392076,
                40.79718784055657
              ],
              [
                -73.81371743898944,
                40.79720909845813
              ],
              [
                -73.81370919053616,
                40.79722664479341
              ],
              [
                -73.81370591633107,
                40.79723616716758
              ],
              [
                -73.81370172690912,
                40.79724716446272
              ],
              [
                -73.81369616816892,
                40.79726042029839
              ],
              [
                -73.81369100029453,
                40.79727366788662
              ],
              [
                -73.81368666413815,
                40.797285816177734
              ],
              [
                -73.81367688799823,
                40.797307458547394
              ],
              [
                -73.81367253519188,
                40.797316969125404
              ],
              [
                -73.81366765918304,
                40.79732761467891
              ],
              [
                -73.8136585037273,
                40.7973473921536
              ],
              [
                -73.81365275799477,
                40.797359323564805
              ],
              [
                -73.81364730879639,
                40.79737163422971
              ],
              [
                -73.81364167178127,
                40.797387329941664
              ],
              [
                -73.81363549060623,
                40.79740282659118
              ],
              [
                -73.81363097237592,
                40.79741701119057
              ],
              [
                -73.81362905346168,
                40.797429515860784
              ],
              [
                -73.81362796930762,
                40.797443930496264
              ],
              [
                -73.81362807557187,
                40.797456997305225
              ],
              [
                -73.81362880095362,
                40.797468606472634
              ],
              [
                -73.81362930956556,
                40.797481502051106
              ],
              [
                -73.81363223727841,
                40.79749386194196
              ],
              [
                -73.81363735676354,
                40.79750641367473
              ],
              [
                -73.81364448203017,
                40.79751803268644
              ],
              [
                -73.81365672554256,
                40.79752949797496
              ],
              [
                -73.81366682246971,
                40.79753778150252
              ],
              [
                -73.81367907633198,
                40.79754578791093
              ],
              [
                -73.813694767553,
                40.797553170816364
              ],
              [
                -73.81370898608684,
                40.797557606245086
              ],
              [
                -73.81372396579235,
                40.797561340301115
              ],
              [
                -73.8137463118747,
                40.797566400829254
              ],
              [
                -73.81376474742939,
                40.79757117627287
              ],
              [
                -73.81377701242235,
                40.797575446539916
              ],
              [
                -73.81379589098282,
                40.7975832490384
              ],
              [
                -73.81380814896724,
                40.797589553717785
              ],
              [
                -73.8138213744634,
                40.79759728342091
              ],
              [
                -73.8138382947005,
                40.79760631623802
              ],
              [
                -73.81385026362024,
                40.79761453844295
              ],
              [
                -73.81386498155203,
                40.79762249671035
              ],
              [
                -73.81388164701266,
                40.797629384953574
              ],
              [
                -73.8138993159446,
                40.79763737428281
              ],
              [
                -73.81391648627456,
                40.797645586785784
              ],
              [
                -73.81393193039827,
                40.79765274360329
              ],
              [
                -73.81394981695435,
                40.79765930110248
              ],
              [
                -73.81396402744772,
                40.79766662780541
              ],
              [
                -73.8139747714611,
                40.79767655912354
              ],
              [
                -73.81399291191686,
                40.797685584505636
              ],
              [
                -73.81401498287853,
                40.79769556157188
              ],
              [
                -73.81403900802484,
                40.79770619061197
              ],
              [
                -73.81405050911253,
                40.7977119995097
              ],
              [
                -73.81406450648764,
                40.79771963158993
              ],
              [
                -73.81407795712317,
                40.79772719962961
              ],
              [
                -73.81410248867043,
                40.79773892676372
              ],
              [
                -73.81412503053268,
                40.79775024678074
              ],
              [
                -73.81414981480735,
                40.797760444471386
              ],
              [
                -73.81416377118336,
                40.797765950912826
              ],
              [
                -73.81417853323323,
                40.79777108104925
              ],
              [
                -73.81419791453479,
                40.797776820715626
              ],
              [
                -73.81421462435387,
                40.79778055799901
              ],
              [
                -73.81423574212853,
                40.79778399665023
              ],
              [
                -73.81425245956895,
                40.79778500394859
              ],
              [
                -73.81427163737345,
                40.79778704347901
              ],
              [
                -73.81429228199,
                40.79779075140813
              ],
              [
                -73.81430822758301,
                40.79779605297043
              ],
              [
                -73.81432320486326,
                40.79780068772772
              ],
              [
                -73.81433914923454,
                40.79780666625331
              ],
              [
                -73.81435311656462,
                40.797812127122455
              ],
              [
                -73.81436736561623,
                40.7978183816757
              ],
              [
                -73.81437772033159,
                40.79782336028192
              ],
              [
                -73.81438377926207,
                40.79782627894766
              ],
              [
                -73.81439751561498,
                40.7978340181182
              ],
              [
                -73.81441367476789,
                40.797843535447996
              ],
              [
                -73.81443083161342,
                40.79785251478283
              ],
              [
                -73.81444602800991,
                40.79785937360732
              ],
              [
                -73.81446172180917,
                40.79786612567932
              ],
              [
                -73.81447838702681,
                40.79787298686373
              ],
              [
                -73.81449656725862,
                40.797880201952
              ],
              [
                -73.81450613017519,
                40.79788422454591
              ],
              [
                -73.81453531095558,
                40.79789487115986
              ],
              [
                -73.81455397100835,
                40.7979002226223
              ],
              [
                -73.81457115166882,
                40.79790477964247
              ],
              [
                -73.8145915414399,
                40.79791025198665
              ],
              [
                -73.81460774504265,
                40.797916806676135
              ],
              [
                -73.81462488214582,
                40.79792465884025
              ],
              [
                -73.81463789535138,
                40.797931893070455
              ],
              [
                -73.81465180058059,
                40.79794281194398
              ],
              [
                -73.8146642355569,
                40.79795408762621
              ],
              [
                -73.81467140259664,
                40.797959097768526
              ],
              [
                -73.81467977553595,
                40.79796495534921
              ],
              [
                -73.8146927142876,
                40.79797383723192
              ],
              [
                -73.81470052927918,
                40.797979855240904
              ],
              [
                -73.81470446768195,
                40.797982887916085
              ],
              [
                -73.81471715688336,
                40.79799161627675
              ],
              [
                -73.8147323804279,
                40.79799737614111
              ],
              [
                -73.8147512901144,
                40.79800231519047
              ],
              [
                -73.81476748640418,
                40.79800739210531
              ],
              [
                -73.81478640546105,
                40.79801340215292
              ],
              [
                -73.81480358514975,
                40.7980185623952
              ],
              [
                -73.81482613576495,
                40.79802681255522
              ],
              [
                -73.81484625902685,
                40.798034363247105
              ],
              [
                -73.8148666073559,
                40.79804207679633
              ],
              [
                -73.81488208804964,
                40.79804836073789
              ],
              [
                -73.81489753789192,
                40.798053769643346
              ],
              [
                -73.81490731008795,
                40.79805889217561
              ],
              [
                -73.81492346275003,
                40.79806690471118
              ],
              [
                -73.81494038313099,
                40.79807598242985
              ],
              [
                -73.81495387530495,
                40.79808199194578
              ],
              [
                -73.81496689411836,
                40.79808747048276
              ],
              [
                -73.81498331023157,
                40.79809452937635
              ],
              [
                -73.8149992514518,
                40.79810176759513
              ],
              [
                -73.81501468082486,
                40.79810987009057
              ],
              [
                -73.81502914989186,
                40.798118069882904
              ],
              [
                -73.81504263884548,
                40.7981249270611
              ],
              [
                -73.81505659024279,
                40.79813227951414
              ],
              [
                -73.8150700798322,
                40.798138909860896
              ],
              [
                -73.81508404248532,
                40.798146101001905
              ],
              [
                -73.81510954599655,
                40.79815801945321
              ],
              [
                -73.81513309967957,
                40.79816747617102
              ],
              [
                -73.81515662049448,
                40.798176546183115
              ],
              [
                -73.8151782349332,
                40.79818481119019
              ],
              [
                -73.81519884414837,
                40.79819220177504
              ],
              [
                -73.81521528508323,
                40.798198675509454
              ],
              [
                -73.81523268635723,
                40.79820504272707
              ],
              [
                -73.81524985299129,
                40.798210806642615
              ],
              [
                -73.81526948886314,
                40.79821910414416
              ],
              [
                -73.81528491788298,
                40.79822711714417
              ],
              [
                -73.81530060856933,
                40.79823529138568
              ],
              [
                -73.8153121481771,
                40.79824000175539
              ],
              [
                -73.81533055467733,
                40.798247252589164
              ],
              [
                -73.815346974238,
                40.79825309671058
              ],
              [
                -73.81536316791401,
                40.798259074823115
              ],
              [
                -73.81537962061495,
                40.79826553046002
              ],
              [
                -73.81539459540231,
                40.79827112901382
              ],
              [
                -73.8154095276851,
                40.79827878051967
              ],
              [
                -73.81542644145912,
                40.79828591151251
              ],
              [
                -73.81544387674795,
                40.798292747488226
              ],
              [
                -73.81546447400227,
                40.79830057876513
              ],
              [
                -73.81548164791276,
                40.79830763059179
              ],
              [
                -73.8154981006991,
                40.798314302487
              ],
              [
                -73.81551425337895,
                40.7983221179295
              ],
              [
                -73.81552626607565,
                40.79832763047564
              ],
              [
                -73.81554002070406,
                40.798332757652055
              ],
              [
                -73.8155557046396,
                40.79833893310396
              ],
              [
                -73.81557190932354,
                40.79834548765887
              ],
              [
                -73.81558709335448,
                40.79835195932906
              ],
              [
                -73.81560033524546,
                40.79835835602178
              ],
              [
                -73.8156133537509,
                40.79836430271799
              ],
              [
                -73.81562608702995,
                40.79837071668705
              ],
              [
                -73.81563982694729,
                40.79837694296106
              ],
              [
                -73.81565772099715,
                40.79838490540769
              ],
              [
                -73.81567586906978,
                40.79839157060708
              ],
              [
                -73.81569232334162,
                40.798397720468145
              ],
              [
                -73.81570199213954,
                40.798401851352914
              ],
              [
                -73.81572039127308,
                40.79841179474572
              ],
              [
                -73.81574000979177,
                40.79842170507211
              ],
              [
                -73.81575616360165,
                40.79842960977321
              ],
              [
                -73.81577041609565,
                40.79843495466293
              ],
              [
                -73.8157841257758,
                40.798439371431094
              ],
              [
                -73.81579985674536,
                40.798445376216655
              ],
              [
                -73.81581775579993,
                40.79845194165315
              ],
              [
                -73.81583248197221,
                40.798457152769366
              ],
              [
                -73.81584770601887,
                40.79846224657364
              ],
              [
                -73.8158673357198,
                40.79846892349122
              ],
              [
                -73.81588574534095,
                40.798474851461556
              ],
              [
                -73.81590367015849,
                40.79848023771561
              ],
              [
                -73.81592059964356,
                40.79848610937337
              ],
              [
                -73.81593629589116,
                40.79849184403399
              ],
              [
                -73.81595372067311,
                40.798498372506586
              ],
              [
                -73.81596793344923,
                40.79850525703296
              ],
              [
                -73.8159833646266,
                40.79851278311398
              ],
              [
                -73.81599613439197,
                40.7985186211483
              ],
              [
                -73.81601082087283,
                40.798525362018665
              ],
              [
                -73.81602603866598,
                40.79853296833297
              ],
              [
                -73.81603876983723,
                40.798539931735505
              ],
              [
                -73.81605394465207,
                40.79855032945067
              ],
              [
                -73.81606735188909,
                40.798561220553154
              ],
              [
                -73.81607935134153,
                40.79857155875241
              ],
              [
                -73.8160858687864,
                40.7985798006717
              ],
              [
                -73.81609413376435,
                40.79859480720014
              ],
              [
                -73.81609927442656,
                40.798604360487616
              ],
              [
                -73.8161085807101,
                40.798619719474154
              ],
              [
                -73.81611754243514,
                40.79863172824301
              ],
              [
                -73.81613079419463,
                40.79864712099566
              ],
              [
                -73.81614299803788,
                40.798660647854526
              ],
              [
                -73.81615459893241,
                40.7986737867671
              ],
              [
                -73.81616589978309,
                40.79868805046693
              ],
              [
                -73.81617933119495,
                40.798703056517105
              ],
              [
                -73.81619048750619,
                40.798713944015326
              ],
              [
                -73.81620262970857,
                40.79872784837126
              ],
              [
                -73.8162133520338,
                40.7987413465586
              ],
              [
                -73.81622293670387,
                40.79875090692566
              ],
              [
                -73.8162345633379,
                40.79876311811627
              ],
              [
                -73.81624420954162,
                40.79877570492302
              ],
              [
                -73.81625472374584,
                40.798787139198794
              ],
              [
                -73.81626787833002,
                40.79879934461277
              ],
              [
                -73.81627594436371,
                40.798805067064364
              ],
              [
                -73.81628288763298,
                40.79881838734076
              ],
              [
                -73.81628854195914,
                40.79882171854549
              ],
              [
                -73.81630051488024,
                40.79882859946052
              ],
              [
                -73.81631349165232,
                40.79883710361824
              ],
              [
                -73.81632652026309,
                40.79884367930653
              ],
              [
                -73.81634242337212,
                40.79885166494254
              ],
              [
                -73.81636080309183,
                40.798864490137866
              ],
              [
                -73.81637620047697,
                40.798875681899005
              ],
              [
                -73.81638772645348,
                40.798885641709354
              ],
              [
                -73.81640659548852,
                40.79890553590131
              ],
              [
                -73.8164235301729,
                40.79892244740575
              ],
              [
                -73.81643386411535,
                40.7989348371092
              ],
              [
                -73.81644430357927,
                40.79894797414222
              ],
              [
                -73.81645246797443,
                40.79896072927969
              ],
              [
                -73.81646163371575,
                40.798975538530165
              ],
              [
                -73.81646965727438,
                40.79898828523332
              ],
              [
                -73.81647735191558,
                40.79899951681668
              ],
              [
                -73.8164913280699,
                40.79901032752483
              ],
              [
                -73.81650243611863,
                40.799017567723524
              ],
              [
                -73.81651639859037,
                40.79902494749008
              ],
              [
                -73.81653529932524,
                40.79903328816587
              ],
              [
                -73.8165485476371,
                40.799037677136596
              ],
              [
                -73.81656673346951,
                40.79904313624059
              ],
              [
                -73.81658361651031,
                40.79904865542063
              ],
              [
                -73.81659693828932,
                40.799051585357944
              ],
              [
                -73.81661874132057,
                40.79905661765868
              ],
              [
                -73.8166448773395,
                40.7990625846025
              ],
              [
                -73.81666133734032,
                40.79906618660962
              ],
              [
                -73.816675370543,
                40.799069666146885
              ],
              [
                -73.81669475441785,
                40.7990746863771
              ],
              [
                -73.81670002505695,
                40.799075910154045
              ],
              [
                -73.81670853904163,
                40.79907789546443
              ],
              [
                -73.81672427696331,
                40.799081460128825
              ],
              [
                -73.81674001687544,
                40.79908502311905
              ],
              [
                -73.81675529326716,
                40.79908823624761
              ],
              [
                -73.81678243562176,
                40.799094456717846
              ],
              [
                -73.81679622205118,
                40.7990973330925
              ],
              [
                -73.8168109899126,
                40.799100291772184
              ],
              [
                -73.81682578072974,
                40.79910380114201
              ],
              [
                -73.81684151847931,
                40.79910791359762
              ],
              [
                -73.81685722059562,
                40.79911183769611
              ],
              [
                -73.81687248379598,
                40.79911592359461
              ],
              [
                -73.81688869448094,
                40.79912009174333
              ],
              [
                -73.81690540209898,
                40.799124565910155
              ],
              [
                -73.81692110001387,
                40.79912994025912
              ],
              [
                -73.81693586346815,
                40.799134826796134
              ],
              [
                -73.81695205938497,
                40.79914011967692
              ],
              [
                -73.81696877861198,
                40.79914486490873
              ],
              [
                -73.8169878664468,
                40.799149812751224
              ],
              [
                -73.81700508481417,
                40.7991540625605
              ],
              [
                -73.8170222676227,
                40.79915801646116
              ],
              [
                -73.81705085723912,
                40.79916360893549
              ],
              [
                -73.81707604931336,
                40.799168475820224
              ],
              [
                -73.81709882474345,
                40.79917249235997
              ],
              [
                -73.81712207277631,
                40.799177166176314
              ],
              [
                -73.8171424303444,
                40.79918197980955
              ],
              [
                -73.81715983426098,
                40.79918746505354
              ],
              [
                -73.81717578003556,
                40.799193226241535
              ],
              [
                -73.81718328428884,
                40.7991965340347
              ],
              [
                -73.81720813882664,
                40.799207280155436
              ],
              [
                -73.81722069543777,
                40.79921319846219
              ],
              [
                -73.81723566878601,
                40.799219454979735
              ],
              [
                -73.81725301720094,
                40.799227577806256
              ],
              [
                -73.81727523443656,
                40.79923675186273
              ],
              [
                -73.81728706976284,
                40.79924226394283
              ],
              [
                -73.81730031209244,
                40.79924862475675
              ],
              [
                -73.81731311724012,
                40.79925451597327
              ],
              [
                -73.81732588575856,
                40.799260822257715
              ],
              [
                -73.81733915474054,
                40.799266390380026
              ],
              [
                -73.81736282283454,
                40.79927787337336
              ],
              [
                -73.81738598276688,
                40.79928888682118
              ],
              [
                -73.81740429876693,
                40.79929901978502
              ],
              [
                -73.81742474018179,
                40.79931170366827
              ],
              [
                -73.8174398004204,
                40.799325036729655
              ],
              [
                -73.81745613456414,
                40.79933606682686
              ],
              [
                -73.81747076259346,
                40.79934687844765
              ],
              [
                -73.81748523333897,
                40.79935491543236
              ],
              [
                -73.8175040189219,
                40.79936641813983
              ],
              [
                -73.81752322782053,
                40.79937919068526
              ],
              [
                -73.81754175802995,
                40.79939362768893
              ],
              [
                -73.81756194646383,
                40.799407986064274
              ],
              [
                -73.81758502009886,
                40.799424213725096
              ],
              [
                -73.81759921230812,
                40.79943431265747
              ],
              [
                -73.81761240936784,
                40.7994442667795
              ],
              [
                -73.81762661459345,
                40.799454365226545
              ],
              [
                -73.817648705424,
                40.79947069937331
              ],
              [
                -73.81766600984703,
                40.79948228933684
              ],
              [
                -73.8176799667018,
                40.799491802553874
              ],
              [
                -73.81769489335721,
                40.79950170445363
              ],
              [
                -73.81770570287786,
                40.79950935852076
              ],
              [
                -73.81771745657635,
                40.79951886841642
              ],
              [
                -73.81772873900863,
                40.79952707103667
              ],
              [
                -73.81774099217424,
                40.799535402007045
              ],
              [
                -73.81775278355012,
                40.79954379540315
              ],
              [
                -73.8177643143817,
                40.79955217967477
              ],
              [
                -73.81777654228932,
                40.7995612850706
              ],
              [
                -73.81779069898937,
                40.79957141139709
              ],
              [
                -73.81780199223385,
                40.79957993902603
              ],
              [
                -73.81781377322392,
                40.79958794424356
              ],
              [
                -73.81783493339428,
                40.79960202634948
              ],
              [
                -73.81785360240538,
                40.79961734621198
              ],
              [
                -73.81787151795258,
                40.79963104491212
              ],
              [
                -73.81788149792246,
                40.79963855156978
              ],
              [
                -73.81789422378188,
                40.799647604634146
              ],
              [
                -73.81790694964546,
                40.79965689926843
              ],
              [
                -73.81791925106877,
                40.7996647712769
              ],
              [
                -73.81793053512146,
                40.79967272192179
              ],
              [
                -73.81794329860905,
                40.799680676577225
              ],
              [
                -73.817971206244,
                40.799697792977035
              ],
              [
                -73.817985921389,
                40.79970711855302
              ],
              [
                -73.81800152304712,
                40.79971770598647
              ],
              [
                -73.81801717178567,
                40.7997283303476
              ],
              [
                -73.81803185167449,
                40.799737654856806
              ],
              [
                -73.81804605705439,
                40.79974717847282
              ],
              [
                -73.81805975418524,
                40.799756340602926
              ],
              [
                -73.81807345282117,
                40.79976519532511
              ],
              [
                -73.81808816961643,
                40.7997742503373
              ],
              [
                -73.81810234193345,
                40.79978297128073
              ],
              [
                -73.8181189593013,
                40.799794586895366
              ],
              [
                -73.81813311642097,
                40.79980467749718
              ],
              [
                -73.81814635048075,
                40.79981405516127
              ],
              [
                -73.81815907806109,
                40.799822503767864
              ],
              [
                -73.81817328581903,
                40.79983116997769
              ],
              [
                -73.81818652301168,
                40.7998394853649
              ],
              [
                -73.81819928744804,
                40.79984743831797
              ],
              [
                -73.818212512374,
                40.79985596945524
              ],
              [
                -73.81823609959068,
                40.799871207050074
              ],
              [
                -73.81824838872055,
                40.7998794117086
              ],
              [
                -73.81826018390831,
                40.79988669671144
              ],
              [
                -73.81827294836097,
                40.799894650326394
              ],
              [
                -73.81828715680925,
                40.79990307478433
              ],
              [
                -73.81830229964709,
                40.79991218438358
              ],
              [
                -73.81831552481894,
                40.79992095808581
              ],
              [
                -73.81832923041566,
                40.79993100288715
              ],
              [
                -73.81835568671484,
                40.79995033292884
              ],
              [
                -73.8183684116438,
                40.79995969167219
              ],
              [
                -73.81838066494184,
                40.799968355781694
              ],
              [
                -73.81840229615311,
                40.799983761470955
              ],
              [
                -73.81841227645265,
                40.79999151183172
              ],
              [
                -73.81842269328845,
                40.79999965153789
              ],
              [
                -73.81843350228677,
                40.800008042310765
              ],
              [
                -73.81845419847089,
                40.800023086840746
              ],
              [
                -73.81847391511279,
                40.8000368690298
              ],
              [
                -73.81849601989704,
                40.800052436773754
              ],
              [
                -73.81851635016453,
                40.800067155884875
              ],
              [
                -73.8185323465083,
                40.80008060720768
              ],
              [
                -73.81854740999043,
                40.80009265286358
              ],
              [
                -73.81855162505244,
                40.80009822468545
              ],
              [
                -73.8185614263993,
                40.80010661486533
              ],
              [
                -73.81857381686241,
                40.80011689964107
              ],
              [
                -73.81858913957856,
                40.800129766405284
              ],
              [
                -73.81860502724125,
                40.80014411782715
              ],
              [
                -73.8186176007066,
                40.800156986794754
              ],
              [
                -73.81862984578223,
                40.800168370300874
              ],
              [
                -73.81863982515077,
                40.80017640124491
              ],
              [
                -73.81865013772534,
                40.80018415261021
              ],
              [
                -73.81866162931662,
                40.8001936708746
              ],
              [
                -73.81867630461416,
                40.800204751795285
              ],
              [
                -73.81869195492315,
                40.80021504285851
              ],
              [
                -73.81870899877953,
                40.80022701035811
              ],
              [
                -73.81874415771605,
                40.80024866835632
              ],
              [
                -73.8187622247354,
                40.80025868327534
              ],
              [
                -73.8187793618741,
                40.80026684222586
              ],
              [
                -73.8187955277619,
                40.80027457480313
              ],
              [
                -73.81880973978633,
                40.80028206340575
              ],
              [
                -73.81882440672894,
                40.80029193727516
              ],
              [
                -73.81883908295644,
                40.80030245127109
              ],
              [
                -73.81885277800716,
                40.800312459807266
              ],
              [
                -73.81886344217126,
                40.80032161707463
              ],
              [
                -73.81887360958905,
                40.80033039478576
              ],
              [
                -73.81888434357268,
                40.80033990178583
              ],
              [
                -73.81889555120752,
                40.80034977138347
              ],
              [
                -73.81890761748033,
                40.800361515260406
              ],
              [
                -73.8189203355856,
                40.80037327775018
              ],
              [
                -73.81893319547233,
                40.800385760149396
              ],
              [
                -73.81894721538023,
                40.80039824487187
              ],
              [
                -73.81896248963965,
                40.800411146187194
              ],
              [
                -73.8189779170938,
                40.80042511085601
              ],
              [
                -73.81899157094638,
                40.80043723431635
              ],
              [
                -73.81900550896377,
                40.80044935939382
              ],
              [
                -73.8190183387,
                40.80045964481082
              ],
              [
                -73.81903113213716,
                40.80046990386784
              ],
              [
                -73.81904317956817,
                40.80048016175238
              ],
              [
                -73.81905502678212,
                40.80048970784191
              ],
              [
                -73.81906605879294,
                40.800498837690775
              ],
              [
                -73.81908501071571,
                40.800514896078404
              ],
              [
                -73.81911106847645,
                40.800536729652556
              ],
              [
                -73.81912616697637,
                40.800549432320324
              ],
              [
                -73.8191424947756,
                40.800562848394264
              ],
              [
                -73.81915817148864,
                40.80057629912724
              ],
              [
                -73.81917514052282,
                40.80058974367653
              ],
              [
                -73.81919002444997,
                40.80060280617826
              ],
              [
                -73.81920130450621,
                40.8006117744062
              ],
              [
                -73.81921327193635,
                40.80062090621045
              ],
              [
                -73.81922719307303,
                40.800630752556756
              ],
              [
                -73.8192444936212,
                40.80064373809398
              ],
              [
                -73.81925385973489,
                40.800650749636894
              ],
              [
                -73.819264682139,
                40.800658583665566
              ],
              [
                -73.81927646012072,
                40.8006677508489
              ],
              [
                -73.81928774396401,
                40.800675169968876
              ],
              [
                -73.8192990644269,
                40.800682905935254
              ],
              [
                -73.81932120799769,
                40.800697654046466
              ],
              [
                -73.81933948214659,
                40.8007098934303
              ],
              [
                -73.81935632707153,
                40.800720924557936
              ],
              [
                -73.81937413652587,
                40.80073031695543
              ],
              [
                -73.81939199504005,
                40.800738935963246
              ],
              [
                -73.81940696695627,
                40.800745596767406
              ],
              [
                -73.81942096509312,
                40.800752921286666
              ],
              [
                -73.81943678901052,
                40.80076488676611
              ],
              [
                -73.81944528658519,
                40.80077327532988
              ],
              [
                -73.81946040604019,
                40.80079113759381
              ],
              [
                -73.81948584786856,
                40.800812968944676
              ],
              [
                -73.81950121227939,
                40.800823835232876
              ],
              [
                -73.81951808458177,
                40.80083308290654
              ],
              [
                -73.81953449948413,
                40.80084101562644
              ],
              [
                -73.8195499324419,
                40.80084847689894
              ],
              [
                -73.81956245126247,
                40.80085494554435
              ],
              [
                -73.81957962669078,
                40.800862409540244
              ],
              [
                -73.81959897832714,
                40.80086625772545
              ],
              [
                -73.81961619208606,
                40.80086812140952
              ],
              [
                -73.81963489972662,
                40.80086938534288
              ],
              [
                -73.81965043002965,
                40.80087111136242
              ],
              [
                -73.8196683781757,
                40.800873363170744
              ],
              [
                -73.81968729409633,
                40.80087643669323
              ],
              [
                -73.81970375559746,
                40.8008801990904
              ],
              [
                -73.81972211480347,
                40.800887531566396
              ],
              [
                -73.8197428603402,
                40.80089791053083
              ],
              [
                -73.81976160908972,
                40.80091045699297
              ],
              [
                -73.81977576989408,
                40.80092351879012
              ],
              [
                -73.81978656757732,
                40.800935800188846
              ],
              [
                -73.81979545394888,
                40.800949537742696
              ],
              [
                -73.81980000632639,
                40.800961594274476
              ],
              [
                -73.81980355412048,
                40.80097323360556
              ],
              [
                -73.81980890338927,
                40.80098474072735
              ],
              [
                -73.81981538958014,
                40.80099199984767
              ],
              [
                -73.81982823806005,
                40.800994874894336
              ],
              [
                -73.81983965044095,
                40.800994838955624
              ],
              [
                -73.81985721886358,
                40.800992173277336
              ],
              [
                -73.81985865416124,
                40.800991959748046
              ],
              [
                -73.81987037642868,
                40.80098681795599
              ],
              [
                -73.81988336287415,
                40.800978464670344
              ],
              [
                -73.81988947103542,
                40.800976501270405
              ],
              [
                -73.81990182068905,
                40.80098008650629
              ],
              [
                -73.81990803413413,
                40.800978646450965
              ],
              [
                -73.8199121196076,
                40.800975303167995
              ],
              [
                -73.81991364984894,
                40.80097014544847
              ],
              [
                -73.81990996957263,
                40.80095531707221
              ],
              [
                -73.81990606543906,
                40.80094402848774
              ],
              [
                -73.81990574325435,
                40.800932006043396
              ],
              [
                -73.81990613592077,
                40.800917977261655
              ],
              [
                -73.81990569452488,
                40.80090617911473
              ],
              [
                -73.81990706461919,
                40.800894538088556
              ],
              [
                -73.81990965833285,
                40.80088644631944
              ],
              [
                -73.81991954856151,
                40.80087488176069
              ],
              [
                -73.81993109004819,
                40.80086614794038
              ],
              [
                -73.81994256448044,
                40.80085644839917
              ],
              [
                -73.8199533214342,
                40.80084874815422
              ],
              [
                -73.81997185610044,
                40.80084403144924
              ],
              [
                -73.81999040217745,
                40.80083917035242
              ],
              [
                -73.82000745456675,
                40.80083491955988
              ],
              [
                -73.8200276980661,
                40.80082926904845
              ],
              [
                -73.82004118635471,
                40.800823473465144
              ],
              [
                -73.82005782160546,
                40.800815646189186
              ],
              [
                -73.82006933325167,
                40.800809622530736
              ],
              [
                -73.82008087032678,
                40.80080242790971
              ],
              [
                -73.82010066719741,
                40.80078697847634
              ],
              [
                -73.82011500217105,
                40.80077492513015
              ],
              [
                -73.82012926148793,
                40.80076492468523
              ],
              [
                -73.82014789303918,
                40.80075460723408
              ],
              [
                -73.8201618111261,
                40.800748181574924
              ],
              [
                -73.82017602269929,
                40.80074254792833
              ],
              [
                -73.82019261876997,
                40.80073615391711
              ],
              [
                -73.82020827859925,
                40.80072966730965
              ],
              [
                -73.8202234279822,
                40.800723459838366
              ],
              [
                -73.82024566641648,
                40.8007159761565
              ],
              [
                -73.82026229996207,
                40.80070876148789
              ],
              [
                -73.82028142890316,
                40.80069882188981
              ],
              [
                -73.82029661962055,
                40.80069036377281
              ],
              [
                -73.82031007436557,
                40.800684071896114
              ],
              [
                -73.82032573273936,
                40.80067819740731
              ],
              [
                -73.82034016050163,
                40.80067160114268
              ],
              [
                -73.82035900219094,
                40.800662878657064
              ],
              [
                -73.82037687199812,
                40.80065395479627
              ],
              [
                -73.8203923130523,
                40.800645361528915
              ],
              [
                -73.82040454927233,
                40.8006395002925
              ],
              [
                -73.82049919995305,
                40.80101146781907
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000360040216032,
        "objectid": 254,
        "shape_leng": 0.0858863754861,
        "location_id": 254,
        "zone": "Williamsbridge/Olinville",
        "borough": "Bronx"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.85186563799998,
                40.879058864999905
              ],
              [
                -73.85207011699983,
                40.87929765699988
              ],
              [
                -73.85248305800003,
                40.87946370499989
              ],
              [
                -73.852032805,
                40.88013997199988
              ],
              [
                -73.85156600599989,
                40.88082536399993
              ],
              [
                -73.851106036,
                40.88150897099993
              ],
              [
                -73.8506512309999,
                40.88219178999987
              ],
              [
                -73.85020149399993,
                40.88286986999993
              ],
              [
                -73.84973488199985,
                40.8835558029999
              ],
              [
                -73.84927742099983,
                40.88424150399993
              ],
              [
                -73.84882044099987,
                40.88491966699994
              ],
              [
                -73.84876385000003,
                40.885005316999894
              ],
              [
                -73.84846274099996,
                40.88546103199993
              ],
              [
                -73.84836417199985,
                40.885610210999886
              ],
              [
                -73.84832190799986,
                40.8856731779999
              ],
              [
                -73.84828534100001,
                40.88572765799992
              ],
              [
                -73.84824941299993,
                40.88578118699991
              ],
              [
                -73.84820835300003,
                40.8858423609999
              ],
              [
                -73.84817178599984,
                40.88589684199993
              ],
              [
                -73.84813585799989,
                40.88595036999992
              ],
              [
                -73.84809415299982,
                40.88601250599988
              ],
              [
                -73.84804918100001,
                40.88607950799993
              ],
              [
                -73.84802165699983,
                40.88612051499994
              ],
              [
                -73.84798702099991,
                40.88617211799988
              ],
              [
                -73.84790388199997,
                40.88629598399993
              ],
              [
                -73.84785777299996,
                40.88636441199988
              ],
              [
                -73.84748708800002,
                40.88691453799988
              ],
              [
                -73.84744396900003,
                40.88697853099993
              ],
              [
                -73.84704957999995,
                40.8876718159999
              ],
              [
                -73.84665457799996,
                40.88839061999993
              ],
              [
                -73.84645600899995,
                40.888748434999854
              ],
              [
                -73.84626182299986,
                40.88909833799986
              ],
              [
                -73.84590568599998,
                40.88974136899987
              ],
              [
                -73.84632533099996,
                40.889877226999886
              ],
              [
                -73.84741355299995,
                40.89022219799991
              ],
              [
                -73.84874153399994,
                40.89064771499994
              ],
              [
                -73.85040125099985,
                40.891205458999906
              ],
              [
                -73.85069084099977,
                40.891302770999886
              ],
              [
                -73.85149873299994,
                40.891534796999935
              ],
              [
                -73.85182948399994,
                40.89162978199994
              ],
              [
                -73.85340274699983,
                40.89213229999997
              ],
              [
                -73.85449169199994,
                40.89247851799996
              ],
              [
                -73.85525378499982,
                40.89272062599986
              ],
              [
                -73.85566327299986,
                40.89285239399991
              ],
              [
                -73.85736281700004,
                40.89341143899987
              ],
              [
                -73.85695619399986,
                40.89416793799994
              ],
              [
                -73.859833381,
                40.895085226999875
              ],
              [
                -73.860710982,
                40.89537054399996
              ],
              [
                -73.86135927899984,
                40.894662318999934
              ],
              [
                -73.86140513999995,
                40.894539043999906
              ],
              [
                -73.86149253599987,
                40.89427891699995
              ],
              [
                -73.86192535099993,
                40.89329121599993
              ],
              [
                -73.86198229599997,
                40.893161261999936
              ],
              [
                -73.86215575199998,
                40.89283406299989
              ],
              [
                -73.86225345500002,
                40.892670487999965
              ],
              [
                -73.86274739599986,
                40.89184357499996
              ],
              [
                -73.86280050199993,
                40.89172076699992
              ],
              [
                -73.86276234599985,
                40.89162005699992
              ],
              [
                -73.86304439099993,
                40.89111565099993
              ],
              [
                -73.86381255599989,
                40.88971678099993
              ],
              [
                -73.86460021999999,
                40.888299711999906
              ],
              [
                -73.86538049299986,
                40.88688347499992
              ],
              [
                -73.86620661499991,
                40.88537861399994
              ],
              [
                -73.86624567699987,
                40.88526569299996
              ],
              [
                -73.86624572499991,
                40.88526556799994
              ],
              [
                -73.86624574899986,
                40.88526544999991
              ],
              [
                -73.86626727199992,
                40.885147690999865
              ],
              [
                -73.86627001699986,
                40.88502747899989
              ],
              [
                -73.86627001699988,
                40.88502738999991
              ],
              [
                -73.866270006,
                40.88502729999993
              ],
              [
                -73.86625345799992,
                40.88490729999993
              ],
              [
                -73.86621797999994,
                40.88479001499995
              ],
              [
                -73.86616484799998,
                40.88467823899988
              ],
              [
                -73.86616476499985,
                40.88467807699996
              ],
              [
                -73.86616465799986,
                40.88467792299987
              ],
              [
                -73.8660956839999,
                40.88457362099992
              ],
              [
                -73.86606034399993,
                40.88450592699993
              ],
              [
                -73.86606028499985,
                40.884505799999914
              ],
              [
                -73.86606024899994,
                40.88450565599988
              ],
              [
                -73.86604073399987,
                40.8844334769999
              ],
              [
                -73.86604066399985,
                40.884433234999975
              ],
              [
                -73.86604065199995,
                40.88443298199991
              ],
              [
                -73.86603810799987,
                40.8843593819999
              ],
              [
                -73.86603809599988,
                40.884359192999945
              ],
              [
                -73.86603813199996,
                40.88435902199995
              ],
              [
                -73.86605231299984,
                40.88428704399994
              ],
              [
                -73.86705258299985,
                40.88225521599989
              ],
              [
                -73.86811829499993,
                40.88006228299993
              ],
              [
                -73.86884613499979,
                40.878586356999946
              ],
              [
                -73.86893508399992,
                40.87858929599985
              ],
              [
                -73.86902622499986,
                40.87857616999986
              ],
              [
                -73.86911192999993,
                40.87854672299994
              ],
              [
                -73.86918507299987,
                40.87850341099989
              ],
              [
                -73.869221773,
                40.87846483499988
              ],
              [
                -73.8692538419999,
                40.87843112499994
              ],
              [
                -73.86943471300005,
                40.87812919899994
              ],
              [
                -73.86948896199988,
                40.87798946799995
              ],
              [
                -73.87004672699982,
                40.87667053099995
              ],
              [
                -73.8701541849999,
                40.876416957999886
              ],
              [
                -73.87018094199979,
                40.87635452999994
              ],
              [
                -73.87019944899986,
                40.87631135499987
              ],
              [
                -73.87023414999992,
                40.876217573999924
              ],
              [
                -73.87026287199993,
                40.87612263599991
              ],
              [
                -73.87028554699997,
                40.87602675899994
              ],
              [
                -73.87030212499997,
                40.875930164999936
              ],
              [
                -73.87031256599984,
                40.87583307799987
              ],
              [
                -73.8703168469999,
                40.87573572299988
              ],
              [
                -73.87031508399987,
                40.875617522999924
              ],
              [
                -73.8703131089999,
                40.87534062099997
              ],
              [
                -73.87037667199995,
                40.8732843719999
              ],
              [
                -73.87056192499988,
                40.87151077799995
              ],
              [
                -73.86935122399996,
                40.871483353999906
              ],
              [
                -73.86825887799992,
                40.87146232799989
              ],
              [
                -73.86715864199988,
                40.87144191299995
              ],
              [
                -73.86614489499986,
                40.87142298499991
              ],
              [
                -73.865208901,
                40.871407649999874
              ],
              [
                -73.86426604499991,
                40.87138964999993
              ],
              [
                -73.86333198300004,
                40.8713751569999
              ],
              [
                -73.86239212899994,
                40.87135673399991
              ],
              [
                -73.86137924099984,
                40.871336511999914
              ],
              [
                -73.86036598199989,
                40.87131862199991
              ],
              [
                -73.85942680499996,
                40.87130069699991
              ],
              [
                -73.85848731699983,
                40.87128405999989
              ],
              [
                -73.85754854399994,
                40.87126631799993
              ],
              [
                -73.85661382499993,
                40.87125016699991
              ],
              [
                -73.85655118199992,
                40.87303042899989
              ],
              [
                -73.85651292399993,
                40.87421779299995
              ],
              [
                -73.85577052399987,
                40.87398371299992
              ],
              [
                -73.85551758400004,
                40.873903955999886
              ],
              [
                -73.854508051,
                40.87358094199987
              ],
              [
                -73.85363638799987,
                40.87330059899996
              ],
              [
                -73.85362131899993,
                40.873312086999874
              ],
              [
                -73.85278034299986,
                40.87395322899988
              ],
              [
                -73.85215435899995,
                40.87443419899992
              ],
              [
                -73.85206424599991,
                40.87450364699991
              ],
              [
                -73.85143247999994,
                40.874987270999945
              ],
              [
                -73.85068955699988,
                40.87548667799994
              ],
              [
                -73.85093186799989,
                40.875830013999874
              ],
              [
                -73.85172236099986,
                40.87684337099996
              ],
              [
                -73.85254854099988,
                40.87709158699993
              ],
              [
                -73.85211217399988,
                40.87729787999995
              ],
              [
                -73.85091896099982,
                40.87789344099987
              ],
              [
                -73.85138374099995,
                40.878476222999886
              ],
              [
                -73.85186563799998,
                40.879058864999905
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000172309184842,
        "objectid": 255,
        "shape_leng": 0.0623841997664,
        "location_id": 255,
        "zone": "Williamsburg (North Side)",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.96176070375392,
                40.72522879205536
              ],
              [
                -73.96190239621568,
                40.72532050090551
              ],
              [
                -73.96211461583417,
                40.72514806319948
              ],
              [
                -73.96165984300669,
                40.724860454231504
              ],
              [
                -73.96189426706076,
                40.7241067020505
              ],
              [
                -73.96190256156959,
                40.72409829024044
              ],
              [
                -73.96200744799489,
                40.723991901303876
              ],
              [
                -73.9620727192193,
                40.72388030070139
              ],
              [
                -73.96223504982149,
                40.72357025913257
              ],
              [
                -73.96225346277055,
                40.72353229777296
              ],
              [
                -73.9627566541133,
                40.72239901506302
              ],
              [
                -73.96298124491233,
                40.72203167639525
              ],
              [
                -73.96345365315541,
                40.72157889003159
              ],
              [
                -73.96347715752879,
                40.72155636128484
              ],
              [
                -73.96347810162712,
                40.721556513028574
              ],
              [
                -73.96357950967109,
                40.721572788715775
              ],
              [
                -73.96360201823921,
                40.72153866694273
              ],
              [
                -73.96399793724473,
                40.720938438072736
              ],
              [
                -73.9648942203191,
                40.72147512088203
              ],
              [
                -73.96504914549352,
                40.721357344251814
              ],
              [
                -73.96408769147868,
                40.72075552952228
              ],
              [
                -73.96414771830368,
                40.72070627945257
              ],
              [
                -73.96420274484129,
                40.72065375718892
              ],
              [
                -73.96425246396787,
                40.720598255889065
              ],
              [
                -73.96429659818648,
                40.72054008533749
              ],
              [
                -73.96432509603716,
                40.72047236934721
              ],
              [
                -73.9643509064015,
                40.72040403237185
              ],
              [
                -73.96459313964766,
                40.72054484421153
              ],
              [
                -73.96468283641283,
                40.72047045735994
              ],
              [
                -73.96439765915947,
                40.7202967350292
              ],
              [
                -73.96447993393507,
                40.72023272348612
              ],
              [
                -73.96446581250262,
                40.72022477634749
              ],
              [
                -73.96435606131355,
                40.720163003598714
              ],
              [
                -73.96438190823226,
                40.72013942466023
              ],
              [
                -73.96446386180361,
                40.720064662999896
              ],
              [
                -73.96456213748016,
                40.720123070522234
              ],
              [
                -73.96490548671237,
                40.72032712936547
              ],
              [
                -73.96501325438103,
                40.72029027209133
              ],
              [
                -73.96513713562288,
                40.720343594754176
              ],
              [
                -73.9653743106172,
                40.72009363306692
              ],
              [
                -73.96538515805561,
                40.71995427278238
              ],
              [
                -73.9655469308472,
                40.71965919869085
              ],
              [
                -73.9653961343865,
                40.71956487925423
              ],
              [
                -73.96528849280119,
                40.7193639996086
              ],
              [
                -73.96528401324461,
                40.71936086098301
              ],
              [
                -73.96515385976045,
                40.71926968304745
              ],
              [
                -73.96552577399207,
                40.718900892712554
              ],
              [
                -73.96563350220046,
                40.71893371662328
              ],
              [
                -73.96570332142306,
                40.718918114225396
              ],
              [
                -73.96570636269995,
                40.71891490263662
              ],
              [
                -73.96585847175166,
                40.718773228346436
              ],
              [
                -73.96636503305272,
                40.719056330784156
              ],
              [
                -73.96631897740717,
                40.71910842000827
              ],
              [
                -73.96654344630538,
                40.719222942469436
              ],
              [
                -73.96662993360903,
                40.71913242786092
              ],
              [
                -73.96637067848121,
                40.718989708350925
              ],
              [
                -73.96634259840096,
                40.7190187424301
              ],
              [
                -73.9658789353266,
                40.71875507768636
              ],
              [
                -73.96611826905765,
                40.71850526164153
              ],
              [
                -73.96610196657393,
                40.71845076521724
              ],
              [
                -73.96607655887611,
                40.718431918166665
              ],
              [
                -73.96604482029281,
                40.71840837581112
              ],
              [
                -73.96615035842787,
                40.71831123846767
              ],
              [
                -73.96620531446817,
                40.718261301623095
              ],
              [
                -73.9662235827445,
                40.718270653474725
              ],
              [
                -73.966322244646,
                40.7183211568781
              ],
              [
                -73.96651367937793,
                40.71805251673066
              ],
              [
                -73.96652792581678,
                40.71803252419761
              ],
              [
                -73.96653720436414,
                40.71803482687111
              ],
              [
                -73.96655263001192,
                40.71803865314392
              ],
              [
                -73.96656241897492,
                40.7180158668062
              ],
              [
                -73.96714225214744,
                40.718317715272946
              ],
              [
                -73.96708162217523,
                40.71840615665343
              ],
              [
                -73.96705912061108,
                40.71843898028647
              ],
              [
                -73.96717135968257,
                40.718483428068744
              ],
              [
                -73.96721948180506,
                40.718415969504754
              ],
              [
                -73.96729652521331,
                40.71831181417356
              ],
              [
                -73.96718435668602,
                40.71826224255602
              ],
              [
                -73.96717386981618,
                40.71827675878373
              ],
              [
                -73.9665969033269,
                40.71796798088764
              ],
              [
                -73.96676841729843,
                40.71770540351883
              ],
              [
                -73.96726798356039,
                40.7169857409556
              ],
              [
                -73.9671515999894,
                40.71691927317972
              ],
              [
                -73.96711354289924,
                40.716897539103954
              ],
              [
                -73.9672633131556,
                40.71666616768813
              ],
              [
                -73.96724404348966,
                40.716585349916386
              ],
              [
                -73.96729235333521,
                40.71651557312143
              ],
              [
                -73.96736391666703,
                40.71648367952745
              ],
              [
                -73.96663975699981,
                40.71636484999993
              ],
              [
                -73.96609959099992,
                40.71620611299992
              ],
              [
                -73.96447229899992,
                40.715733105999924
              ],
              [
                -73.96277758699983,
                40.71510663499988
              ],
              [
                -73.96128719699986,
                40.71450229699995
              ],
              [
                -73.95979763199988,
                40.713887133999926
              ],
              [
                -73.95834207500002,
                40.71330630099992
              ],
              [
                -73.95680951699988,
                40.71275638799993
              ],
              [
                -73.95547596099992,
                40.7122654259999
              ],
              [
                -73.95503638699985,
                40.71291373099997
              ],
              [
                -73.95433904200002,
                40.714071701999906
              ],
              [
                -73.95348472199983,
                40.7140702079999
              ],
              [
                -73.95335294599992,
                40.71418365499992
              ],
              [
                -73.952753813,
                40.71473599099993
              ],
              [
                -73.95210878499995,
                40.71525889399989
              ],
              [
                -73.95182429600005,
                40.71547596899994
              ],
              [
                -73.95114433699997,
                40.71603452099991
              ],
              [
                -73.95004619199985,
                40.71680522799992
              ],
              [
                -73.9498379229999,
                40.716938665999876
              ],
              [
                -73.94954034299991,
                40.717121995999946
              ],
              [
                -73.94904281699998,
                40.717428518999924
              ],
              [
                -73.94866397500004,
                40.717682770999915
              ],
              [
                -73.94832167599995,
                40.71786451199997
              ],
              [
                -73.94721341799986,
                40.718560985999865
              ],
              [
                -73.94841638599995,
                40.71845553299995
              ],
              [
                -73.94853260499984,
                40.71916717599987
              ],
              [
                -73.94887459699996,
                40.72129240699989
              ],
              [
                -73.94907816799986,
                40.72254028699993
              ],
              [
                -73.94986599500001,
                40.72217948399987
              ],
              [
                -73.95064594299997,
                40.72170569299989
              ],
              [
                -73.95164632299998,
                40.723485856999936
              ],
              [
                -73.95249594800003,
                40.72321773399992
              ],
              [
                -73.95332036799981,
                40.72293269999991
              ],
              [
                -73.95382461699991,
                40.722759734999904
              ],
              [
                -73.95418330299992,
                40.72263642799991
              ],
              [
                -73.95477662900004,
                40.72245950499994
              ],
              [
                -73.95627311499997,
                40.723389875999914
              ],
              [
                -73.95771989199994,
                40.7242849569999
              ],
              [
                -73.95814292503528,
                40.72440428420136
              ],
              [
                -73.95833078213445,
                40.724411700883
              ],
              [
                -73.95875928184918,
                40.72454135418478
              ],
              [
                -73.96036651068175,
                40.72504730919287
              ],
              [
                -73.9604323022029,
                40.725114108830184
              ],
              [
                -73.96116366334205,
                40.72526460591537
              ],
              [
                -73.9614126353891,
                40.72517721924634
              ],
              [
                -73.96153679379327,
                40.725108918677314
              ],
              [
                -73.96176070375392,
                40.72522879205536
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000168611097013,
        "objectid": 256,
        "shape_leng": 0.0679149669603,
        "location_id": 256,
        "zone": "Williamsburg (South Side)",
        "borough": "Brooklyn"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.95834207500002,
                40.71330630099992
              ],
              [
                -73.95979763199988,
                40.713887133999926
              ],
              [
                -73.96128719699986,
                40.71450229699995
              ],
              [
                -73.96277758699983,
                40.71510663499988
              ],
              [
                -73.96447229899992,
                40.715733105999924
              ],
              [
                -73.96609959099992,
                40.71620611299992
              ],
              [
                -73.96663975699981,
                40.71636484999993
              ],
              [
                -73.96736391666705,
                40.71648367902753
              ],
              [
                -73.96765448249123,
                40.71586910972532
              ],
              [
                -73.96800830687327,
                40.71512072380612
              ],
              [
                -73.96839125147874,
                40.71426191817128
              ],
              [
                -73.9688422905027,
                40.71322392502161
              ],
              [
                -73.96849927445857,
                40.71310284084998
              ],
              [
                -73.96856767224719,
                40.71294926378505
              ],
              [
                -73.96846827481582,
                40.712926793852084
              ],
              [
                -73.96845231624204,
                40.71292318664481
              ],
              [
                -73.96847298005669,
                40.712853427708865
              ],
              [
                -73.96846973347022,
                40.712824709346144
              ],
              [
                -73.96849857488866,
                40.712804464738696
              ],
              [
                -73.96854773954304,
                40.7128167857182
              ],
              [
                -73.96866711839206,
                40.71284670324133
              ],
              [
                -73.96872432113675,
                40.712730240977436
              ],
              [
                -73.9687292790881,
                40.712720143798244
              ],
              [
                -73.96873958212066,
                40.71272276068742
              ],
              [
                -73.96879580997484,
                40.71273703885378
              ],
              [
                -73.96881607240603,
                40.71268527097035
              ],
              [
                -73.96883197798022,
                40.71264017567564
              ],
              [
                -73.96885323825141,
                40.712607931984145
              ],
              [
                -73.96887248523522,
                40.71257874444157
              ],
              [
                -73.9688977507757,
                40.71252500074196
              ],
              [
                -73.96891951556479,
                40.712476754661935
              ],
              [
                -73.96894455591715,
                40.712426547720256
              ],
              [
                -73.96892343767315,
                40.71242023948871
              ],
              [
                -73.96883145652384,
                40.712392763147676
              ],
              [
                -73.96886922090012,
                40.7122661975163
              ],
              [
                -73.96880934254595,
                40.71225605597726
              ],
              [
                -73.9688670183243,
                40.71223244408627
              ],
              [
                -73.96893565827621,
                40.71201705475273
              ],
              [
                -73.96911535361018,
                40.7115118989782
              ],
              [
                -73.96918658210186,
                40.711467636156115
              ],
              [
                -73.9692894441982,
                40.71150686060597
              ],
              [
                -73.96930299630868,
                40.711512029245455
              ],
              [
                -73.96942598248582,
                40.71147272512735
              ],
              [
                -73.96945195784745,
                40.711393965517324
              ],
              [
                -73.96939142661579,
                40.71135201441385
              ],
              [
                -73.9692386101985,
                40.711246109344295
              ],
              [
                -73.96925812194722,
                40.711162421749044
              ],
              [
                -73.96933582617378,
                40.711113238785835
              ],
              [
                -73.9694522407415,
                40.711157632229146
              ],
              [
                -73.96950659655987,
                40.711177142260965
              ],
              [
                -73.96956219103225,
                40.711197097043694
              ],
              [
                -73.96970449314463,
                40.71123658451135
              ],
              [
                -73.96976280357137,
                40.71117261775995
              ],
              [
                -73.96969388597714,
                40.71113727505959
              ],
              [
                -73.96958170746989,
                40.711108484770314
              ],
              [
                -73.96955914381311,
                40.71109735316974
              ],
              [
                -73.96936184310228,
                40.71100001269858
              ],
              [
                -73.96934894946878,
                40.71096061478666
              ],
              [
                -73.96972883126466,
                40.7108397014988
              ],
              [
                -73.96975026678481,
                40.71083287772713
              ],
              [
                -73.96979416000536,
                40.71074050647946
              ],
              [
                -73.96971161435603,
                40.7106900673666
              ],
              [
                -73.96963399936386,
                40.710665396155825
              ],
              [
                -73.96973123629752,
                40.71051282944215
              ],
              [
                -73.96981825475457,
                40.71051001036169
              ],
              [
                -73.96988006133613,
                40.71050800918952
              ],
              [
                -73.96997064587534,
                40.71050807121897
              ],
              [
                -73.9700095322252,
                40.7104539376276
              ],
              [
                -73.9699448936496,
                40.71039973365078
              ],
              [
                -73.96983077701677,
                40.71039021573928
              ],
              [
                -73.96964728659579,
                40.710374910985514
              ],
              [
                -73.9695567958969,
                40.710296071319796
              ],
              [
                -73.96960817478119,
                40.71016491759815
              ],
              [
                -73.96994088125628,
                40.7093102314059
              ],
              [
                -73.96984864823531,
                40.70800304168769
              ],
              [
                -73.96962252142447,
                40.707627588777555
              ],
              [
                -73.96929296374243,
                40.707093331047766
              ],
              [
                -73.96838933699995,
                40.70682918699989
              ],
              [
                -73.96728890199985,
                40.70700200299995
              ],
              [
                -73.9659321969999,
                40.70724387399991
              ],
              [
                -73.96484837799986,
                40.70745361099989
              ],
              [
                -73.96442087299985,
                40.7075337239999
              ],
              [
                -73.963278738,
                40.70772929899995
              ],
              [
                -73.96274332999992,
                40.70768508199993
              ],
              [
                -73.96213128899981,
                40.707622692999955
              ],
              [
                -73.96186019899987,
                40.70759505699994
              ],
              [
                -73.96114422899987,
                40.707523595999874
              ],
              [
                -73.96050333599985,
                40.70746152299994
              ],
              [
                -73.960403832,
                40.70745188599992
              ],
              [
                -73.95993835900006,
                40.70740680099994
              ],
              [
                -73.95939542499995,
                40.707352407999984
              ],
              [
                -73.95920084799998,
                40.70733158499992
              ],
              [
                -73.958455768,
                40.707251846999895
              ],
              [
                -73.95838432400002,
                40.70767669099991
              ],
              [
                -73.95815250199995,
                40.70804091799986
              ],
              [
                -73.95790430799988,
                40.70842717299994
              ],
              [
                -73.95735981099993,
                40.70823462399987
              ],
              [
                -73.95728255399992,
                40.708207309999906
              ],
              [
                -73.95720321600001,
                40.70817924699989
              ],
              [
                -73.95672449099987,
                40.70800995199991
              ],
              [
                -73.95626945199986,
                40.70784956199987
              ],
              [
                -73.95554165799997,
                40.70759344799989
              ],
              [
                -73.95401701399999,
                40.7070802409999
              ],
              [
                -73.95375309099992,
                40.70698188099989
              ],
              [
                -73.95291388700002,
                40.70670031099993
              ],
              [
                -73.95251503799986,
                40.70655858699993
              ],
              [
                -73.95229955499995,
                40.706490292999945
              ],
              [
                -73.95191055699979,
                40.70636007899991
              ],
              [
                -73.95108529899998,
                40.705912951999906
              ],
              [
                -73.95095606199983,
                40.70584293599995
              ],
              [
                -73.95023693799996,
                40.70547324699994
              ],
              [
                -73.95028918399986,
                40.70591343899986
              ],
              [
                -73.95040588999977,
                40.70665274599989
              ],
              [
                -73.95053010000007,
                40.707390984999954
              ],
              [
                -73.95065919499986,
                40.70816998299993
              ],
              [
                -73.95076027199995,
                40.70880285999993
              ],
              [
                -73.95082963899989,
                40.70914359799985
              ],
              [
                -73.95087492699996,
                40.7095131149999
              ],
              [
                -73.94865533499987,
                40.709724982999894
              ],
              [
                -73.94877149699997,
                40.71043225899995
              ],
              [
                -73.94889233899987,
                40.71116753499994
              ],
              [
                -73.94901358399997,
                40.71190375599989
              ],
              [
                -73.94913082999982,
                40.71261242099997
              ],
              [
                -73.94924526199999,
                40.7133203089999
              ],
              [
                -73.94937231899985,
                40.71406687799987
              ],
              [
                -73.95162366299994,
                40.714066360999894
              ],
              [
                -73.9529104979999,
                40.714060986999876
              ],
              [
                -73.9530314939999,
                40.714062846999916
              ],
              [
                -73.95316497599985,
                40.71406509799991
              ],
              [
                -73.95325272999993,
                40.71406562999987
              ],
              [
                -73.95348472199983,
                40.7140702079999
              ],
              [
                -73.95433904200002,
                40.714071701999906
              ],
              [
                -73.95503638699985,
                40.71291373099997
              ],
              [
                -73.95547596099992,
                40.7122654259999
              ],
              [
                -73.95680951699988,
                40.71275638799993
              ],
              [
                -73.95834207500002,
                40.71330630099992
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000394552487366,
        "objectid": 259,
        "shape_leng": 0.126750305191,
        "location_id": 259,
        "zone": "Woodlawn/Wakefield",
        "borough": "Bronx"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.85107116191898,
                40.91037152011096
              ],
              [
                -73.85144753984513,
                40.9104496711014
              ],
              [
                -73.85149242468167,
                40.91027164024944
              ],
              [
                -73.85178316975063,
                40.91016063082594
              ],
              [
                -73.85227451608235,
                40.909854099321514
              ],
              [
                -73.85245653391823,
                40.9098605090357
              ],
              [
                -73.85266035896096,
                40.910015193079055
              ],
              [
                -73.85258474775196,
                40.910310617032046
              ],
              [
                -73.8527699159019,
                40.91040600803299
              ],
              [
                -73.85295618460705,
                40.91035304408958
              ],
              [
                -73.85335351915529,
                40.91004314037642
              ],
              [
                -73.85360061710122,
                40.90960674122533
              ],
              [
                -73.8537750789652,
                40.90953155512055
              ],
              [
                -73.853916460476,
                40.909465603221356
              ],
              [
                -73.85458940544117,
                40.908939499149085
              ],
              [
                -73.85491109686753,
                40.90835486966836
              ],
              [
                -73.85479372768647,
                40.90815833184228
              ],
              [
                -73.85475581976394,
                40.90808399311854
              ],
              [
                -73.8547107831982,
                40.90800630947143
              ],
              [
                -73.85422905350244,
                40.90801858927425
              ],
              [
                -73.85383360999784,
                40.907974349909786
              ],
              [
                -73.85374103615364,
                40.90778791636777
              ],
              [
                -73.85423776693442,
                40.907032868127104
              ],
              [
                -73.85513792091523,
                40.906866889070514
              ],
              [
                -73.85552697719521,
                40.90638025122957
              ],
              [
                -73.85580463797965,
                40.90626436406324
              ],
              [
                -73.85588044197486,
                40.90624143018391
              ],
              [
                -73.85595622622013,
                40.906218503620494
              ],
              [
                -73.85655732522444,
                40.90626203242297
              ],
              [
                -73.85681013619275,
                40.90615582816297
              ],
              [
                -73.85692398190014,
                40.905974170055444
              ],
              [
                -73.85650251730299,
                40.90556384889823
              ],
              [
                -73.85642386921508,
                40.90554474089732
              ],
              [
                -73.85635545169798,
                40.90552811796204
              ],
              [
                -73.85601160852788,
                40.90548208322071
              ],
              [
                -73.8560102334462,
                40.9053059838135
              ],
              [
                -73.85636081350269,
                40.90510215402868
              ],
              [
                -73.85665436861377,
                40.905055000139896
              ],
              [
                -73.85673146602299,
                40.905101069184795
              ],
              [
                -73.85680038324311,
                40.90514224808929
              ],
              [
                -73.85705022716802,
                40.905291535878256
              ],
              [
                -73.85717766087977,
                40.905286541830726
              ],
              [
                -73.85743457609159,
                40.90484154309892
              ],
              [
                -73.85735935217048,
                40.90473096099366
              ],
              [
                -73.85718621345484,
                40.90469201710322
              ],
              [
                -73.85710151389124,
                40.90470845794119
              ],
              [
                -73.85704144142174,
                40.90464056721392
              ],
              [
                -73.85699157373413,
                40.90458420903161
              ],
              [
                -73.85717176301259,
                40.90408206521806
              ],
              [
                -73.85711090489973,
                40.90364953284796
              ],
              [
                -73.85755967678821,
                40.90291779547927
              ],
              [
                -73.85774747446301,
                40.902787546959594
              ],
              [
                -73.85796932314177,
                40.902850907843764
              ],
              [
                -73.85816460299762,
                40.902595860779456
              ],
              [
                -73.85816000002418,
                40.902305710101295
              ],
              [
                -73.85840075376072,
                40.90221325589944
              ],
              [
                -73.85872824816724,
                40.9022889707851
              ],
              [
                -73.85896181324718,
                40.902517844842365
              ],
              [
                -73.85896189921327,
                40.90251792785658
              ],
              [
                -73.85903604846267,
                40.90258349597223
              ],
              [
                -73.85910498785931,
                40.902644455217434
              ],
              [
                -73.85917899583926,
                40.902656078476184
              ],
              [
                -73.85926432520931,
                40.902669479448235
              ],
              [
                -73.85957882974114,
                40.902440842885724
              ],
              [
                -73.85953201388178,
                40.90224311106178
              ],
              [
                -73.85949851182689,
                40.90210160475055
              ],
              [
                -73.85946389979054,
                40.90193387781683
              ],
              [
                -73.85938576811449,
                40.901904940946395
              ],
              [
                -73.85928903113773,
                40.901869112257906
              ],
              [
                -73.8588640130816,
                40.90171169502484
              ],
              [
                -73.8590270417611,
                40.90144490987671
              ],
              [
                -73.85920552011947,
                40.9015478638293
              ],
              [
                -73.85928099691559,
                40.901555501188646
              ],
              [
                -73.85937696512505,
                40.901565218082155
              ],
              [
                -73.85938315290944,
                40.9015403908592
              ],
              [
                -73.85941984095663,
                40.90139312500629
              ],
              [
                -73.8592357618419,
                40.9010986128155
              ],
              [
                -73.85907692320508,
                40.901007993050335
              ],
              [
                -73.85932418498184,
                40.90069752719921
              ],
              [
                -73.85939698623689,
                40.900606109236435
              ],
              [
                -73.85946778750369,
                40.90051720913338
              ],
              [
                -73.8601070690549,
                40.900721982278384
              ],
              [
                -73.86019317316996,
                40.900750257856956
              ],
              [
                -73.86027030983603,
                40.90077469545771
              ],
              [
                -73.86050354630507,
                40.90084609257246
              ],
              [
                -73.86068622789415,
                40.90090201390131
              ],
              [
                -73.8607838009884,
                40.9009330449307
              ],
              [
                -73.86088138470656,
                40.90096407592495
              ],
              [
                -73.86109329963253,
                40.90103207514061
              ],
              [
                -73.86134100817148,
                40.90111157015066
              ],
              [
                -73.8615643835939,
                40.90106212291692
              ],
              [
                -73.8617962597029,
                40.90111912264373
              ],
              [
                -73.86202342509037,
                40.901187776596416
              ],
              [
                -73.86224439564484,
                40.9012677067675
              ],
              [
                -73.86245772613617,
                40.901358396757225
              ],
              [
                -73.86266208683458,
                40.90145918769716
              ],
              [
                -73.8627985062273,
                40.90153552211173
              ],
              [
                -73.86305636216943,
                40.901507381475604
              ],
              [
                -73.86452318784423,
                40.901924262026625
              ],
              [
                -73.86452327049332,
                40.9019242898117
              ],
              [
                -73.86477258258628,
                40.902012441936456
              ],
              [
                -73.86565794312916,
                40.90224320777017
              ],
              [
                -73.86652124506384,
                40.90251593006561
              ],
              [
                -73.86789043706806,
                40.90298695407611
              ],
              [
                -73.86816613899995,
                40.9024482979999
              ],
              [
                -73.86820270299982,
                40.90235538299993
              ],
              [
                -73.86899707599991,
                40.901559154999916
              ],
              [
                -73.87010369599989,
                40.90074194699992
              ],
              [
                -73.87136373199995,
                40.900039151999906
              ],
              [
                -73.87301813899998,
                40.89935490499995
              ],
              [
                -73.87388944299983,
                40.89896546299988
              ],
              [
                -73.87482041599985,
                40.898445437999925
              ],
              [
                -73.87589465499987,
                40.89755945899988
              ],
              [
                -73.87643743099987,
                40.89687059399993
              ],
              [
                -73.8771197409999,
                40.895468663999985
              ],
              [
                -73.87572387299993,
                40.89562260099997
              ],
              [
                -73.87480618199984,
                40.89572568799985
              ],
              [
                -73.87241034499989,
                40.89624128599994
              ],
              [
                -73.87048672599987,
                40.89664021099991
              ],
              [
                -73.87006981099995,
                40.89659073699991
              ],
              [
                -73.86691694899986,
                40.89608268499996
              ],
              [
                -73.86491632499997,
                40.89574949499992
              ],
              [
                -73.8640163769999,
                40.8955230469999
              ],
              [
                -73.86355915799994,
                40.89537701699987
              ],
              [
                -73.86330852999983,
                40.895294848999875
              ],
              [
                -73.86287533299995,
                40.8951540299999
              ],
              [
                -73.86219003099983,
                40.89493051899997
              ],
              [
                -73.86214409299994,
                40.89491552999991
              ],
              [
                -73.86209147100006,
                40.89489836099992
              ],
              [
                -73.86190465899992,
                40.89483740499985
              ],
              [
                -73.861825324,
                40.89481152099992
              ],
              [
                -73.86176180699982,
                40.89479118399992
              ],
              [
                -73.86135927899984,
                40.894662318999934
              ],
              [
                -73.860710982,
                40.89537054399996
              ],
              [
                -73.859833381,
                40.895085226999875
              ],
              [
                -73.85695619399986,
                40.89416793799994
              ],
              [
                -73.85736281700004,
                40.89341143899987
              ],
              [
                -73.85566327299986,
                40.89285239399991
              ],
              [
                -73.85525378499982,
                40.89272062599986
              ],
              [
                -73.85449169199994,
                40.89247851799996
              ],
              [
                -73.85340274699983,
                40.89213229999997
              ],
              [
                -73.85182948399994,
                40.89162978199994
              ],
              [
                -73.85149873299994,
                40.891534796999935
              ],
              [
                -73.85069084099977,
                40.891302770999886
              ],
              [
                -73.85040125099985,
                40.891205458999906
              ],
              [
                -73.84874153399994,
                40.89064771499994
              ],
              [
                -73.84741355299995,
                40.89022219799991
              ],
              [
                -73.84632533099996,
                40.889877226999886
              ],
              [
                -73.84590568599998,
                40.88974136899987
              ],
              [
                -73.84626182299986,
                40.88909833799986
              ],
              [
                -73.84645600899995,
                40.888748434999854
              ],
              [
                -73.84665457799996,
                40.88839061999993
              ],
              [
                -73.84704957999995,
                40.8876718159999
              ],
              [
                -73.84572953199992,
                40.887871705999935
              ],
              [
                -73.8452555469999,
                40.887943469999875
              ],
              [
                -73.84465398699977,
                40.88803308899988
              ],
              [
                -73.84437965699982,
                40.88807478899986
              ],
              [
                -73.84432835199985,
                40.88808258799987
              ],
              [
                -73.84407903899991,
                40.88812048499994
              ],
              [
                -73.844021975,
                40.8881291589999
              ],
              [
                -73.84376573899986,
                40.88816810699991
              ],
              [
                -73.84367707000004,
                40.88818167399991
              ],
              [
                -73.84362775199982,
                40.88818922099995
              ],
              [
                -73.8431922509999,
                40.88825585499997
              ],
              [
                -73.84313196799998,
                40.888265078999886
              ],
              [
                -73.84285880200002,
                40.88830687399993
              ],
              [
                -73.84196149599987,
                40.88845596699989
              ],
              [
                -73.84105278699985,
                40.888553466999895
              ],
              [
                -73.84017616899983,
                40.88868679899992
              ],
              [
                -73.83998563399987,
                40.8887186719999
              ],
              [
                -73.83979050699996,
                40.8887364029999
              ],
              [
                -73.839593563,
                40.888739532999914
              ],
              [
                -73.83939762699993,
                40.88872801299994
              ],
              [
                -73.83920549899996,
                40.88870221699992
              ],
              [
                -73.83822700999995,
                40.88864452499991
              ],
              [
                -73.83732165099995,
                40.88874196399991
              ],
              [
                -73.83643359099989,
                40.888935663999916
              ],
              [
                -73.83554853999995,
                40.88913350399991
              ],
              [
                -73.83645878199995,
                40.89151612599991
              ],
              [
                -73.83737956161106,
                40.893924741304346
              ],
              [
                -73.83804277292896,
                40.89414233144517
              ],
              [
                -73.83839199161751,
                40.89406700802401
              ],
              [
                -73.83896625889744,
                40.89559320648411
              ],
              [
                -73.83964812806491,
                40.89733053517026
              ],
              [
                -73.8396643320591,
                40.897885515052764
              ],
              [
                -73.83923604304178,
                40.89873561981261
              ],
              [
                -73.83920940088679,
                40.898810677061086
              ],
              [
                -73.83918880498874,
                40.898886822108956
              ],
              [
                -73.83917433174886,
                40.898963774370074
              ],
              [
                -73.8391660338051,
                40.8990412512721
              ],
              [
                -73.8391639419338,
                40.89911896727798
              ],
              [
                -73.83916806407632,
                40.89919663628615
              ],
              [
                -73.83917838505819,
                40.8992739730252
              ],
              [
                -73.83919486690272,
                40.899350692215506
              ],
              [
                -73.83921744879804,
                40.89942651221206
              ],
              [
                -73.83924604720931,
                40.89950115402461
              ],
              [
                -73.84004474667755,
                40.90057495709412
              ],
              [
                -73.84023030117935,
                40.900797130297505
              ],
              [
                -73.84039877912073,
                40.901028994013096
              ],
              [
                -73.84054892587938,
                40.90126937918314
              ],
              [
                -73.84067966362085,
                40.90151703123143
              ],
              [
                -73.84079014185733,
                40.901770561227885
              ],
              [
                -73.84087973380167,
                40.90202853878417
              ],
              [
                -73.84094803906034,
                40.902289458142306
              ],
              [
                -73.84099491992694,
                40.90255182499083
              ],
              [
                -73.84102047404488,
                40.902814135238735
              ],
              [
                -73.84106272893486,
                40.90321411616408
              ],
              [
                -73.84138689269206,
                40.90417274176649
              ],
              [
                -73.84237543789729,
                40.90416640488811
              ],
              [
                -73.84339559917244,
                40.90417112477924
              ],
              [
                -73.84441319516424,
                40.9041713300245
              ],
              [
                -73.84489264904629,
                40.90419086689807
              ],
              [
                -73.84492921909838,
                40.90466300388436
              ],
              [
                -73.84481886992495,
                40.90552994983734
              ],
              [
                -73.84538061108482,
                40.90565580829525
              ],
              [
                -73.84623270413468,
                40.90589867238338
              ],
              [
                -73.84708032907056,
                40.90615673326767
              ],
              [
                -73.84863913199048,
                40.90680652208311
              ],
              [
                -73.84933641428515,
                40.90712531413304
              ],
              [
                -73.85017317542919,
                40.90750786582528
              ],
              [
                -73.85040148913299,
                40.9072181700019
              ],
              [
                -73.85092059381415,
                40.9066164942448
              ],
              [
                -73.85142507791112,
                40.90678017523082
              ],
              [
                -73.85228059672554,
                40.90708126681254
              ],
              [
                -73.85257767308545,
                40.9072001911051
              ],
              [
                -73.85347522366716,
                40.90753029351422
              ],
              [
                -73.85304970791424,
                40.90803592695065
              ],
              [
                -73.85234744980255,
                40.908862842299236
              ],
              [
                -73.85207287919152,
                40.90919708288196
              ],
              [
                -73.85107116191898,
                40.91037152011096
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000422345326907,
        "objectid": 260,
        "shape_leng": 0.133514154636,
        "location_id": 260,
        "zone": "Woodside",
        "borough": "Queens"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.90175373399988,
                40.76077547499996
              ],
              [
                -73.90225241299993,
                40.76264382599989
              ],
              [
                -73.90276793600005,
                40.7638794569999
              ],
              [
                -73.90299205599985,
                40.7644118389999
              ],
              [
                -73.90331045299985,
                40.765168148999905
              ],
              [
                -73.90356915599997,
                40.76578178899994
              ],
              [
                -73.90361058199981,
                40.76572657799993
              ],
              [
                -73.90370771199999,
                40.765597148999916
              ],
              [
                -73.90451229800003,
                40.76458906399993
              ],
              [
                -73.90519286699994,
                40.76375094099996
              ],
              [
                -73.90566075299986,
                40.763167987999914
              ],
              [
                -73.90606811099998,
                40.762660444999916
              ],
              [
                -73.90625691699982,
                40.76242520699992
              ],
              [
                -73.90651032099997,
                40.76210946999992
              ],
              [
                -73.90677808699982,
                40.761777481999886
              ],
              [
                -73.90703976299993,
                40.76145304399993
              ],
              [
                -73.907419729,
                40.76098192599994
              ],
              [
                -73.90753042700004,
                40.76084467799991
              ],
              [
                -73.90779405499987,
                40.76051779999991
              ],
              [
                -73.90699741899994,
                40.760142990999945
              ],
              [
                -73.90631505099988,
                40.75984387599992
              ],
              [
                -73.90649338799986,
                40.75956398999992
              ],
              [
                -73.90705781899987,
                40.75867814499987
              ],
              [
                -73.9074261499998,
                40.75810005299993
              ],
              [
                -73.90782817499984,
                40.75699926499987
              ],
              [
                -73.9079488889999,
                40.75667581499994
              ],
              [
                -73.90798755699986,
                40.756572205999916
              ],
              [
                -73.90802408399996,
                40.75647433199986
              ],
              [
                -73.90806081699996,
                40.75637590799997
              ],
              [
                -73.90810013899984,
                40.75627054199988
              ],
              [
                -73.90813601099995,
                40.75617442399993
              ],
              [
                -73.90817188199993,
                40.75607830499997
              ],
              [
                -73.90820623799995,
                40.75598624799987
              ],
              [
                -73.90824210899991,
                40.75589012999991
              ],
              [
                -73.90827537899996,
                40.7558009829999
              ],
              [
                -73.90830736999996,
                40.75571526199991
              ],
              [
                -73.90835663299995,
                40.75558325799991
              ],
              [
                -73.90840546799983,
                40.75545239999991
              ],
              [
                -73.90846544599992,
                40.75529168599991
              ],
              [
                -73.908509019,
                40.75521110499989
              ],
              [
                -73.90853125399995,
                40.75516998399991
              ],
              [
                -73.90854932099995,
                40.755136570999916
              ],
              [
                -73.90860265599997,
                40.7550379379999
              ],
              [
                -73.90862474299999,
                40.7549970909999
              ],
              [
                -73.90865671500002,
                40.75493796099992
              ],
              [
                -73.90868067299994,
                40.75489365399992
              ],
              [
                -73.90871371100003,
                40.75483255499992
              ],
              [
                -73.908736451,
                40.754790499999935
              ],
              [
                -73.90876818500003,
                40.75473181499993
              ],
              [
                -73.90879316499999,
                40.754685615999946
              ],
              [
                -73.90901259099992,
                40.75427981499996
              ],
              [
                -73.90917451199998,
                40.75406416699993
              ],
              [
                -73.90922139799983,
                40.75400172299991
              ],
              [
                -73.90922902699994,
                40.75399264199997
              ],
              [
                -73.90944851199998,
                40.75373135399992
              ],
              [
                -73.9096931489999,
                40.75346973299989
              ],
              [
                -73.90995439699994,
                40.75321782299993
              ],
              [
                -73.91023124699991,
                40.75297652299987
              ],
              [
                -73.91067474899997,
                40.752995076999895
              ],
              [
                -73.91035493000007,
                40.75274990799994
              ],
              [
                -73.90984318599997,
                40.75163867999989
              ],
              [
                -73.90955575999992,
                40.75103731299989
              ],
              [
                -73.90949713399976,
                40.75095068299987
              ],
              [
                -73.90949709799997,
                40.75095063899988
              ],
              [
                -73.90949707399992,
                40.750950583999895
              ],
              [
                -73.90945867299992,
                40.75085521699992
              ],
              [
                -73.9094586489999,
                40.75085514499993
              ],
              [
                -73.9094586379999,
                40.75085507399991
              ],
              [
                -73.90944331999988,
                40.750755231999925
              ],
              [
                -73.90944330899997,
                40.75075513199992
              ],
              [
                -73.90944332099993,
                40.7507550329999
              ],
              [
                -73.90945182799983,
                40.75065550699994
              ],
              [
                -73.90945183999995,
                40.75065545399988
              ],
              [
                -73.90948267299984,
                40.75056086099988
              ],
              [
                -73.90951622599984,
                40.75044165999988
              ],
              [
                -73.90971148199992,
                40.74975470899995
              ],
              [
                -73.90989961499994,
                40.749087274999944
              ],
              [
                -73.91015860699994,
                40.748501967999914
              ],
              [
                -73.91164202799983,
                40.74868457599992
              ],
              [
                -73.91176417899987,
                40.748441596999946
              ],
              [
                -73.91182081399998,
                40.74834287299994
              ],
              [
                -73.91182610699991,
                40.74833364499993
              ],
              [
                -73.9118262499998,
                40.74833339299994
              ],
              [
                -73.91182635699991,
                40.748333131999914
              ],
              [
                -73.91187504099992,
                40.74822079599992
              ],
              [
                -73.91190979099993,
                40.748105394999875
              ],
              [
                -73.91190983999999,
                40.74810522499994
              ],
              [
                -73.91190987499982,
                40.74810505399993
              ],
              [
                -73.91193027699991,
                40.747988209999924
              ],
              [
                -73.91212028799991,
                40.74729474899992
              ],
              [
                -73.91232274099995,
                40.74644708599989
              ],
              [
                -73.91235352599999,
                40.74631819599992
              ],
              [
                -73.9124485379999,
                40.74592040199985
              ],
              [
                -73.91213076499986,
                40.74588664299989
              ],
              [
                -73.9112472229998,
                40.745783992999876
              ],
              [
                -73.91150870799994,
                40.74447070499985
              ],
              [
                -73.91190626499984,
                40.74255201199992
              ],
              [
                -73.91193129999984,
                40.74243121799988
              ],
              [
                -73.9119412979999,
                40.74238294899994
              ],
              [
                -73.9126012949999,
                40.74244224599993
              ],
              [
                -73.91289084199995,
                40.74248224999995
              ],
              [
                -73.9137938869998,
                40.742514491999955
              ],
              [
                -73.91382781999981,
                40.742413687999935
              ],
              [
                -73.91443483199988,
                40.74053105299989
              ],
              [
                -73.91538439399999,
                40.74072944299994
              ],
              [
                -73.91567469599995,
                40.74078492899987
              ],
              [
                -73.916301085,
                40.73906911799992
              ],
              [
                -73.91651190599994,
                40.738944757999896
              ],
              [
                -73.91709074600003,
                40.73714468199994
              ],
              [
                -73.91717737999979,
                40.73689786999995
              ],
              [
                -73.91651522799987,
                40.73675734599987
              ],
              [
                -73.91687214200005,
                40.73554135899994
              ],
              [
                -73.91689741899991,
                40.735444707999896
              ],
              [
                -73.91751347899988,
                40.73535098499991
              ],
              [
                -73.91755768599998,
                40.735291762999964
              ],
              [
                -73.91759250800001,
                40.73523944099985
              ],
              [
                -73.9183510909999,
                40.73315255799992
              ],
              [
                -73.91879292099999,
                40.731969448999905
              ],
              [
                -73.91902180399987,
                40.73208783799994
              ],
              [
                -73.91903825199986,
                40.73200596599987
              ],
              [
                -73.91881334600005,
                40.73191752199989
              ],
              [
                -73.917563071,
                40.731446009999885
              ],
              [
                -73.917342506,
                40.73138282599992
              ],
              [
                -73.91736640299979,
                40.731327577999906
              ],
              [
                -73.91752107599989,
                40.73100270499989
              ],
              [
                -73.91760690999989,
                40.73057198099986
              ],
              [
                -73.9177303909999,
                40.72987934399989
              ],
              [
                -73.91796696699996,
                40.72850077099989
              ],
              [
                -73.91507233799989,
                40.7279708199999
              ],
              [
                -73.91505218099985,
                40.72860117999992
              ],
              [
                -73.91205021299994,
                40.72850636299993
              ],
              [
                -73.91200974700003,
                40.72922886399991
              ],
              [
                -73.91189388099994,
                40.72956051399995
              ],
              [
                -73.91177014899996,
                40.72985279599989
              ],
              [
                -73.91174734199988,
                40.72991203299991
              ],
              [
                -73.91148836199982,
                40.72983170699989
              ],
              [
                -73.91096208699999,
                40.72964075299994
              ],
              [
                -73.9105504049999,
                40.72946621999993
              ],
              [
                -73.91017627699985,
                40.72927572499985
              ],
              [
                -73.90981313899988,
                40.72907325099989
              ],
              [
                -73.90934522699985,
                40.72880316199991
              ],
              [
                -73.9071225309999,
                40.72742472799994
              ],
              [
                -73.90706867299988,
                40.7274665229999
              ],
              [
                -73.90693049899986,
                40.72757602999992
              ],
              [
                -73.90673028099992,
                40.72773268299987
              ],
              [
                -73.90573360899982,
                40.728510757999906
              ],
              [
                -73.90518057899999,
                40.72895657699995
              ],
              [
                -73.904218336,
                40.730125830999945
              ],
              [
                -73.90259650799989,
                40.73210442799993
              ],
              [
                -73.90107970900003,
                40.73391463399987
              ],
              [
                -73.90194324799982,
                40.73434002499995
              ],
              [
                -73.90244611599991,
                40.7345728379999
              ],
              [
                -73.90286649499984,
                40.734767459999944
              ],
              [
                -73.90333476000002,
                40.73499640299988
              ],
              [
                -73.903367991,
                40.73501264999993
              ],
              [
                -73.90378660899988,
                40.735217315999876
              ],
              [
                -73.90455512499977,
                40.735605272999884
              ],
              [
                -73.90470297599984,
                40.73567990299988
              ],
              [
                -73.90516948899983,
                40.73585964299986
              ],
              [
                -73.90573589799986,
                40.73463343599989
              ],
              [
                -73.9060183039999,
                40.734010738999935
              ],
              [
                -73.90568645899998,
                40.7339158249999
              ],
              [
                -73.90598353299988,
                40.73326568399993
              ],
              [
                -73.90628903499987,
                40.73240075199989
              ],
              [
                -73.91068876399997,
                40.733471080999955
              ],
              [
                -73.91023874099996,
                40.73433921699993
              ],
              [
                -73.90991066500006,
                40.73499734099988
              ],
              [
                -73.90959716499998,
                40.7356564569999
              ],
              [
                -73.90932941199996,
                40.73623244499991
              ],
              [
                -73.90930551799991,
                40.736287284999875
              ],
              [
                -73.90928542399993,
                40.73633405899991
              ],
              [
                -73.90926002999988,
                40.73640317999986
              ],
              [
                -73.90890220399996,
                40.736440710999894
              ],
              [
                -73.90789283699988,
                40.736597387999936
              ],
              [
                -73.90761906599998,
                40.73665029899994
              ],
              [
                -73.90739546899984,
                40.736709318999914
              ],
              [
                -73.90672950499994,
                40.7369134849999
              ],
              [
                -73.90646283399997,
                40.73702598499989
              ],
              [
                -73.90598452499982,
                40.73721864799991
              ],
              [
                -73.90556671799989,
                40.7374143989999
              ],
              [
                -73.90486204399993,
                40.73772361199985
              ],
              [
                -73.90463288899997,
                40.737824162999914
              ],
              [
                -73.90410900599981,
                40.738033214999895
              ],
              [
                -73.90369365299995,
                40.73819895599997
              ],
              [
                -73.90364752599992,
                40.73821736899992
              ],
              [
                -73.903575259,
                40.73824323399991
              ],
              [
                -73.90316328499986,
                40.738390687999924
              ],
              [
                -73.90268254099986,
                40.73856750299995
              ],
              [
                -73.90174321599993,
                40.73890039799998
              ],
              [
                -73.9010230649999,
                40.73926666799989
              ],
              [
                -73.90057825399985,
                40.73949289599989
              ],
              [
                -73.89974871399977,
                40.74013220399993
              ],
              [
                -73.89920950799993,
                40.74054548099991
              ],
              [
                -73.89916350300004,
                40.740598569999854
              ],
              [
                -73.89908954799988,
                40.740657154999944
              ],
              [
                -73.89896997799984,
                40.74075331499988
              ],
              [
                -73.89889421999989,
                40.740814239999935
              ],
              [
                -73.89883324999978,
                40.74085897699991
              ],
              [
                -73.89868042099991,
                40.740979339999946
              ],
              [
                -73.89818354199996,
                40.74139090199992
              ],
              [
                -73.89762150399993,
                40.74184176199987
              ],
              [
                -73.89732904499989,
                40.74207636599991
              ],
              [
                -73.897207419,
                40.74217393599989
              ],
              [
                -73.89706891399995,
                40.74228451999994
              ],
              [
                -73.8963812929998,
                40.74284491999988
              ],
              [
                -73.89576162799989,
                40.74335656899993
              ],
              [
                -73.89557809299986,
                40.74350275399994
              ],
              [
                -73.89540662199998,
                40.74365743999987
              ],
              [
                -73.89524819599988,
                40.74381972999992
              ],
              [
                -73.89499026399977,
                40.74413421299992
              ],
              [
                -73.8949120899999,
                40.74428123099997
              ],
              [
                -73.89484503999992,
                40.74443166199988
              ],
              [
                -73.89478949299988,
                40.74458478799992
              ],
              [
                -73.89474567599989,
                40.74473983299987
              ],
              [
                -73.894693961,
                40.74495583499989
              ],
              [
                -73.89466630799988,
                40.74517683999989
              ],
              [
                -73.89466369299986,
                40.74540049699995
              ],
              [
                -73.8946865809999,
                40.74562431999988
              ],
              [
                -73.89473484699982,
                40.745845798999945
              ],
              [
                -73.89480782,
                40.746062464999966
              ],
              [
                -73.89490427299994,
                40.74627199299995
              ],
              [
                -73.89496338799981,
                40.746372146999896
              ],
              [
                -73.89502251499994,
                40.74647229299989
              ],
              [
                -73.89535589999988,
                40.747108412999864
              ],
              [
                -73.89566359199998,
                40.747695496999896
              ],
              [
                -73.89583033399997,
                40.74801363999996
              ],
              [
                -73.89610755799994,
                40.748500847999885
              ],
              [
                -73.89624245599988,
                40.74871954499985
              ],
              [
                -73.89648281799981,
                40.74907123399993
              ],
              [
                -73.8970765,
                40.75011887599991
              ],
              [
                -73.89721143799989,
                40.75046637499993
              ],
              [
                -73.89728972099992,
                40.750872780999906
              ],
              [
                -73.89733942799997,
                40.751419567999896
              ],
              [
                -73.89748654099999,
                40.7520143479999
              ],
              [
                -73.89757880899995,
                40.75238393499995
              ],
              [
                -73.89762297800004,
                40.75252318399989
              ],
              [
                -73.89770744899982,
                40.752734858999894
              ],
              [
                -73.89790235299994,
                40.753087714999864
              ],
              [
                -73.89826519799995,
                40.75366541399989
              ],
              [
                -73.89855812999998,
                40.754137095999916
              ],
              [
                -73.89897117099991,
                40.7547153049999
              ],
              [
                -73.89905512900002,
                40.75494982499991
              ],
              [
                -73.89912259300003,
                40.7551692929999
              ],
              [
                -73.89923583599995,
                40.75553001699992
              ],
              [
                -73.89959808699993,
                40.756235437999926
              ],
              [
                -73.89969372599982,
                40.75644660899991
              ],
              [
                -73.89972650399997,
                40.75654186699989
              ],
              [
                -73.89976862099998,
                40.75666423699994
              ],
              [
                -73.89979518199993,
                40.75677539099996
              ],
              [
                -73.89982174199989,
                40.75688653699989
              ],
              [
                -73.89985245499989,
                40.757111608999885
              ],
              [
                -73.89986047499984,
                40.757337462999914
              ],
              [
                -73.89984592699983,
                40.75756215299987
              ],
              [
                -73.89980933199992,
                40.75778375299994
              ],
              [
                -73.89978921699989,
                40.75789847899993
              ],
              [
                -73.89976111400001,
                40.75798575899993
              ],
              [
                -73.89971668599982,
                40.75816257899988
              ],
              [
                -73.89967747499992,
                40.758296863999966
              ],
              [
                -73.89964864399998,
                40.75837966699988
              ],
              [
                -73.89960731899997,
                40.75845463499993
              ],
              [
                -73.89956743900002,
                40.758545262999846
              ],
              [
                -73.89948479499999,
                40.75868286799992
              ],
              [
                -73.89941035199989,
                40.75880883899992
              ],
              [
                -73.89906072899988,
                40.75932801599991
              ],
              [
                -73.89866137799999,
                40.759921016999904
              ],
              [
                -73.89877250699988,
                40.75991557699992
              ],
              [
                -73.89886718099987,
                40.75990420699985
              ],
              [
                -73.89901804799997,
                40.7598912129999
              ],
              [
                -73.90127448199993,
                40.7596514789999
              ],
              [
                -73.90137640199998,
                40.75964203799993
              ],
              [
                -73.90147053099994,
                40.759633049999884
              ],
              [
                -73.90175373399988,
                40.76077547499996
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.0000343423231652,
        "objectid": 261,
        "shape_leng": 0.0271204563616,
        "location_id": 261,
        "zone": "World Trade Center",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -74.01332610899988,
                40.7050307879999
              ],
              [
                -74.01335309799998,
                40.70519857699985
              ],
              [
                -74.01335946199991,
                40.70536865599994
              ],
              [
                -74.01334484799995,
                40.70553882699991
              ],
              [
                -74.01330942899993,
                40.70570683099988
              ],
              [
                -74.01325396399993,
                40.70587051399992
              ],
              [
                -74.01250773999996,
                40.706767066999916
              ],
              [
                -74.01194691299999,
                40.70745153199994
              ],
              [
                -74.01170249899995,
                40.70775571299986
              ],
              [
                -74.01124834999997,
                40.708304700999854
              ],
              [
                -74.01118228399991,
                40.7083855739999
              ],
              [
                -74.0110119849999,
                40.70859402199989
              ],
              [
                -74.01084638199993,
                40.708797544999925
              ],
              [
                -74.0105424639999,
                40.70915414899993
              ],
              [
                -74.00998739099994,
                40.70980567299989
              ],
              [
                -74.00954277299994,
                40.71032749299993
              ],
              [
                -74.00947357899994,
                40.71041254299995
              ],
              [
                -74.00906264699988,
                40.71088670399995
              ],
              [
                -74.00860085399992,
                40.711392239999945
              ],
              [
                -74.00883879499997,
                40.71150395899991
              ],
              [
                -74.01012167499995,
                40.71208469599989
              ],
              [
                -74.01166334799997,
                40.71279805599988
              ],
              [
                -74.01254378499995,
                40.713179103999934
              ],
              [
                -74.01359729999992,
                40.7136233779999
              ],
              [
                -74.01375388499997,
                40.71369055499988
              ],
              [
                -74.01404744599986,
                40.71243781499994
              ],
              [
                -74.01417753399997,
                40.71188266599997
              ],
              [
                -74.01426893599994,
                40.711467815999924
              ],
              [
                -74.01445559399995,
                40.71090715299987
              ],
              [
                -74.01466507099994,
                40.71042693799993
              ],
              [
                -74.01482432799993,
                40.70982336899991
              ],
              [
                -74.01487257099997,
                40.70968487399991
              ],
              [
                -74.01495415399995,
                40.70943269199994
              ],
              [
                -74.01534330799996,
                40.70849681699987
              ],
              [
                -74.01559220199994,
                40.70788324299987
              ],
              [
                -74.015643777,
                40.70775609899987
              ],
              [
                -74.01568829399997,
                40.70766382499988
              ],
              [
                -74.01571815199996,
                40.707585310999924
              ],
              [
                -74.01584985499994,
                40.70724726799988
              ],
              [
                -74.01586395999995,
                40.70720018299993
              ],
              [
                -74.01588574099995,
                40.707142175999934
              ],
              [
                -74.01590498299998,
                40.70709269499987
              ],
              [
                -74.01593869399987,
                40.70700757399988
              ],
              [
                -74.0160596679999,
                40.70670512999994
              ],
              [
                -74.01625159599989,
                40.706072613999886
              ],
              [
                -74.01640305499988,
                40.70566620199991
              ],
              [
                -74.016622178,
                40.7050944829999
              ],
              [
                -74.01665417299996,
                40.7049638209999
              ],
              [
                -74.01668613099999,
                40.70479933799992
              ],
              [
                -74.01662761199991,
                40.704799430999856
              ],
              [
                -74.01652040399995,
                40.7047997179999
              ],
              [
                -74.01637519799986,
                40.704838484999925
              ],
              [
                -74.01629888499991,
                40.70484641499997
              ],
              [
                -74.01621736899986,
                40.70485302699991
              ],
              [
                -74.0158739609999,
                40.704882112999876
              ],
              [
                -74.01565582200001,
                40.70488263499986
              ],
              [
                -74.01565756599994,
                40.704833087999944
              ],
              [
                -74.01539758699995,
                40.704800148999894
              ],
              [
                -74.01527219799995,
                40.70483003699998
              ],
              [
                -74.01516257,
                40.704805094999884
              ],
              [
                -74.01496125199999,
                40.704759797999884
              ],
              [
                -74.01489542099996,
                40.70469751799991
              ],
              [
                -74.01478059499995,
                40.70467411299992
              ],
              [
                -74.01466709799998,
                40.70464721299986
              ],
              [
                -74.01451712299998,
                40.704605674999854
              ],
              [
                -74.014370312,
                40.70455803799994
              ],
              [
                -74.01428922899998,
                40.70454907499989
              ],
              [
                -74.01419226999991,
                40.7045704039999
              ],
              [
                -74.01326979499997,
                40.704553495999974
              ],
              [
                -74.01332610899988,
                40.7050307879999
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "shape_area": 0.000122330270966,
        "objectid": 262,
        "shape_leng": 0.0490636231541,
        "location_id": 262,
        "zone": "Yorkville East",
        "borough": "Manhattan"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -73.94383256699986,
                40.78285908899991
              ],
              [
                -73.94389324599985,
                40.782887726999896
              ],
              [
                -73.94395460199989,
                40.782915519999925
              ],
              [
                -73.94405439399999,
                40.78296435799991
              ],
              [
                -73.94464666000002,
                40.78321787299997
              ],
              [
                -73.94472264499986,
                40.78324757199989
              ],
              [
                -73.94521308599991,
                40.782570746999944
              ],
              [
                -73.94525035499998,
                40.782519863999916
              ],
              [
                -73.94536565199984,
                40.78236245099987
              ],
              [
                -73.94566981599996,
                40.78194717599993
              ],
              [
                -73.94573785299986,
                40.781854041999964
              ],
              [
                -73.94588094499986,
                40.78165816599989
              ],
              [
                -73.94613091699998,
                40.78131597799995
              ],
              [
                -73.946197187,
                40.78122601799986
              ],
              [
                -73.94630631700001,
                40.781077875999884
              ],
              [
                -73.94642443299978,
                40.78091753399991
              ],
              [
                -73.94647764199985,
                40.78084530299988
              ],
              [
                -73.94659059199999,
                40.78069197399987
              ],
              [
                -73.9470492189998,
                40.78006434699994
              ],
              [
                -73.94750818099986,
                40.77943712299992
              ],
              [
                -73.94796768199993,
                40.77880646599992
              ],
              [
                -73.94842272599978,
                40.77818264099989
              ],
              [
                -73.9488804199999,
                40.77755328499992
              ],
              [
                -73.9493736669999,
                40.776875747999945
              ],
              [
                -73.94987249699994,
                40.77619615099997
              ],
              [
                -73.95033856500001,
                40.77556320899991
              ],
              [
                -73.95079834699999,
                40.774927767999884
              ],
              [
                -73.9512617339999,
                40.774291940999944
              ],
              [
                -73.95172338299997,
                40.77365653199994
              ],
              [
                -73.95181761599994,
                40.77352690399989
              ],
              [
                -73.95218621999996,
                40.77301984499992
              ],
              [
                -73.95268514999994,
                40.772339404999926
              ],
              [
                -73.95043560599999,
                40.7713911029999
              ],
              [
                -73.94807387899996,
                40.770394394999876
              ],
              [
                -73.94779385500001,
                40.770249151999934
              ],
              [
                -73.94771625099995,
                40.77021606799992
              ],
              [
                -73.94766538399982,
                40.77019325299993
              ],
              [
                -73.94761452999984,
                40.77017042899995
              ],
              [
                -73.94752257899997,
                40.770129694999895
              ],
              [
                -73.9474897547817,
                40.77011515400953
              ],
              [
                -73.94690764133767,
                40.7707339117235
              ],
              [
                -73.94640418919063,
                40.77126904561923
              ],
              [
                -73.94561514012511,
                40.77194302658768
              ],
              [
                -73.94487889596702,
                40.7725695620073
              ],
              [
                -73.94442433595846,
                40.77304532251586
              ],
              [
                -73.94396084370824,
                40.773530420594504
              ],
              [
                -73.94366431814754,
                40.77384076467938
              ],
              [
                -73.94333894750484,
                40.77421119259064
              ],
              [
                -73.94293043781381,
                40.774676268035776
              ],
              [
                -73.9425876964068,
                40.7752189565281
              ],
              [
                -73.94240100949044,
                40.77572692752608
              ],
              [
                -73.94208160457381,
                40.77595701631705
              ],
              [
                -73.94200266722274,
                40.776185317382705
              ],
              [
                -73.94207418803877,
                40.77691784697707
              ],
              [
                -73.94209312757077,
                40.77696471695306
              ],
              [
                -73.9421206009128,
                40.7770090780741
              ],
              [
                -73.94215605521731,
                40.77705003763205
              ],
              [
                -73.94219877702626,
                40.777086771368786
              ],
              [
                -73.94224790662773,
                40.77711854006399
              ],
              [
                -73.942302455358,
                40.777144704412
              ],
              [
                -73.9423613254944,
                40.777164737886814
              ],
              [
                -73.94232881653723,
                40.77724265564125
              ],
              [
                -73.94243848174499,
                40.777315235765926
              ],
              [
                -73.94245740990823,
                40.777407473347616
              ],
              [
                -73.94265530676857,
                40.778124965573824
              ],
              [
                -73.94289340818789,
                40.7786140932462
              ],
              [
                -73.94253556320854,
                40.77909095606248
              ],
              [
                -73.94271237476224,
                40.77921485693976
              ],
              [
                -73.94262799584376,
                40.779323118968634
              ],
              [
                -73.94271600545085,
                40.77954416947615
              ],
              [
                -73.9427806664196,
                40.77950615406811
              ],
              [
                -73.94300423950462,
                40.77963949547422
              ],
              [
                -73.94321386265217,
                40.779317588660014
              ],
              [
                -73.94360047475412,
                40.78015909946613
              ],
              [
                -73.94371468850554,
                40.780629815528805
              ],
              [
                -73.943870759887,
                40.781273026571576
              ],
              [
                -73.94388068102414,
                40.78153204386692
              ],
              [
                -73.94364823539011,
                40.78265616133327
              ],
              [
                -73.94354420706223,
                40.78288052416259
              ],
              [
                -73.94360760400001,
                40.78286536999992
              ],
              [
                -73.9437600529999,
                40.782828931999894
              ],
              [
                -73.94383256699986,
                40.78285908899991
              ]
            ]
          ],
          [
            [
              [
                -73.93804640603422,
                40.78082954427547
              ],
              [
                -73.93806723089875,
                40.78094470320065
              ],
              [
                -73.93804948921756,
                40.78105580604241
              ],
              [
                -73.93796757721641,
                40.78128933406028
              ],
              [
                -73.9380821326871,
                40.78141843777721
              ],
              [
                -73.93830097951646,
                40.781362084364964
              ],
              [
                -73.93874423089268,
                40.78104387604228
              ],
              [
                -73.93882863410155,
                40.78076345072766
              ],
              [
                -73.93883951845037,
                40.78059924979985
              ],
              [
                -73.93872802926589,
                40.78051074370461
              ],
              [
                -73.93921789625274,
                40.78001352022149
              ],
              [
                -73.9393052849678,
                40.7799512245521
              ],
              [
                -73.93937515379653,
                40.77997877184918
              ],
              [
                -73.93949329752046,
                40.77987369131538
              ],
              [
                -73.93943238100762,
                40.7798122002746
              ],
              [
                -73.93958378972465,
                40.77957647400715
              ],
              [
                -73.93953015793254,
                40.77952762026044
              ],
              [
                -73.939318219876,
                40.77971800182687
              ],
              [
                -73.93923746178228,
                40.77973339405837
              ],
              [
                -73.93861200821945,
                40.78033895586891
              ],
              [
                -73.9383962259169,
                40.78026243389376
              ],
              [
                -73.9382377350939,
                40.7802590794654
              ],
              [
                -73.93779269036342,
                40.78035790421227
              ],
              [
                -73.93759894622616,
                40.78046784086143
              ],
              [
                -73.93765365963804,
                40.78079000755279
              ],
              [
                -73.93764631821479,
                40.781001215892864
              ],
              [
                -73.93779944179558,
                40.78103133979666
              ],
              [
                -73.93779448726416,
                40.78078125875544
              ],
              [
                -73.9379013362395,
                40.78074999617256
              ],
              [
                -73.93804640603422,
                40.78082954427547
              ]
            ]
          ]
        ]
      }
    }
  ]
}

// console.log('Exporting geoLocation:', geoLocation);
export default geoLocation;
